import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import {
  encrypt,
  encryptingText,
  lengthValidate,
  mobileNumberValidate,
} from "../../utils/helper";
import { AuthButton } from "./components/authButton";
import { AuthContainer } from "./components/authContainer";
import { ContainerMsg } from "./components/authContainerMsg";
import { AuthInput, AuthInputPassword } from "./components/authInputs";
import { MessagePopUp } from "./components/messagePopUp";
import Cookies from "js-cookie";
import { right } from "@popperjs/core";
import { validateEmail } from "../../rentals-src/utils/helper";
import { routesConstants } from "../../rentals-src/utils/RoutesConstants";

export interface LoginFields {
  userName: string;
  password: string;
  otp: string;
  rememberMe?: boolean;
}

function LoginV3() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // reducer data
  const {
    isHigherAuth,
    loginLoader,
    otpId,
    isAuthenticated,
    isLoggedIn,
    userData,
    linkVerifyData,
  } = useSelector((state: RootState) => state.newAuthReducer);

  if (isAuthenticated) navigate(routesConstants.DASHBOARD);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(15);
  const [fields, setFields] = useState<LoginFields>({
    userName: "",
    password: "",
    otp: "",
    rememberMe: false,
  });

  const [errFields, setErrFields] = useState<LoginFields>({
    userName: "",
    password: "",
    otp: "",
  });

  const onChange = (e: any) => {
    const { id, value } = e.target;
    if (id === "otp") {
      setFields({
        ...fields,
        otp: lengthValidate(mobileNumberValidate(value), 6),
      });
    } else {
      setFields({ ...fields, [id]: value });
    }

    // validate email id
    /*  if (id === "userName") {     // -------------------> need to uncomment when login through email id only 
      const validate = validateEmail(value);
      if (validate?.status || !value) {
        setErrFields((prev: LoginFields) => ({
          ...prev,
          userName: "",
        }));
      } else {
        setErrFields((prev: LoginFields) => ({
          ...prev,
          userName: validate.error,
        }));
      }
    } */
  };

  const setCookie = () => {
    const credentials = Cookies.get("credentials");
    return credentials;
  };

  useEffect(() => {
    const credentials = setCookie();
    if (credentials) {
      const parseData = JSON.parse(credentials || "");
      if (parseData) {
        setFields({
          ...fields,
          userName: parseData?.userName,
          password: parseData?.password,
          rememberMe: true,
        });
      } else {
        setFields({
          ...fields,
          userName: "",
          password: "",
        });
      }
    }

    // action for setting initial data to reducer keys
    dispatch(
      actions.setAuthReducerKeys({
        isAccountUnlockLinkSent: false,
        isForgotPasswordLinkSent: false,
      })
    );
  }, []);

  useEffect(() => {
    if (isHigherAuth) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, isHigherAuth]);

  const checkLineOfBusiness = async (key: string, fromLoginPage: boolean) => {
    try {
      const isFreedoXEnabled = await localStorage.getItem(`${key}`);
      if (isFreedoXEnabled === "true") {
        dispatch(actions.setUserFreedoDomain(true));
        navigate("/booking-list", { replace: true });
      } else {
        dispatch(actions.setUserFreedoDomain(false));
        navigate("/dashboard", { replace: true });
      }
    } catch (ex) {
      throw new Error("failed");
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      checkLineOfBusiness("isFreedoX", isLoggedIn);
    }
  }, [isAuthenticated]);

  return (
    <>
      {isHigherAuth ? (
        <>
          <AuthContainer
            heading={locale?.newAuth?.multifactorAuth}
            componentFields={
              <>
                <ContainerMsg message={locale?.newAuth?.otpContMsg} />
                <AuthInput
                  label={locale?.global?.EmailId}
                  value={userData?.mail}
                  disabled={true}
                  className={"form-control"}
                />
                <AuthInput
                  id={"otp"}
                  value={fields?.otp}
                  label={locale?.newAuth?.otp}
                  placeholder={locale?.newAuth?.enterOtp}
                  className={"form-control"}
                  onChange={onChange}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <div>
                    {seconds ? (
                      <>
                        <span className="forgot-password">
                          {`Resend OTP in : ` + seconds}
                        </span>
                      </>
                    ) : (
                      <Link
                        to={""}
                        className="forgot-password"
                        onClick={(e: any) => {
                          e.preventDefault();
                          setSeconds(15);
                          dispatch(
                            actions?.resendOtp({
                              data: {
                                referenceId: otpId,
                              },
                            })
                          );
                        }}
                        style={{}}
                      >
                        {locale?.newAuth?.resendOtp}
                      </Link>
                    )}
                  </div>
                </div>
                <AuthButton
                  type="submit"
                  title={locale?.newAuth?.submitOtp}
                  loading={loginLoader}
                  disabled={!fields?.otp || fields?.otp?.length < 6}
                  onClick={() => {
                    dispatch(
                      actions.multifactorAuthentication({
                        referenceId: otpId,
                        otp: fields?.otp,
                      })
                    );
                  }}
                />
              </>
            }
            // messagePopUp={<MessagePopUp className={"pw-sucess"} message={""} />}
          />
        </>
      ) : (
        <AuthContainer
          heading={locale.newAuth.loginHeading}
          componentFields={
            <>
              <AuthInput
                label={locale?.global?.EmailId}
                value={fields.userName}
                placeholder={locale?.newAuth?.enterUsername}
                className={"form-control"}
                errorMessage={errFields.userName}
                id={"userName"}
                onChange={onChange}
              />
              <AuthInputPassword
                label={locale?.Auth?.password}
                value={fields?.password}
                placeholder={locale?.newAuth?.enterPassword}
                inputClassName={"form-control pw-input"}
                viewPassEye={"true"}
                eyeClassName={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                type={showPassword ? "text" : "password"}
                ariaHidden={!showPassword ? "true" : "false"}
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
                id={"password"}
                onChange={onChange}
              />
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={fields.rememberMe}
                    onChange={(e) =>
                      setFields({ ...fields, rememberMe: e.target.checked })
                    }
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    {locale?.newAuth?.rememberMe}
                  </label>
                </div>

                <Link to={`/user-forgot-password`} className="forgot-password">
                  {locale?.newAuth?.forgotPassword}?
                </Link>
              </div>
              <AuthButton
                type="submit"
                title={locale?.Auth?.Login}
                loading={loginLoader}
                /*className={`btn w-100 login-btn mt-3 ${(!fields?.userName || !fields?.password || loginLoader) ? "disable" : ""}`}
                 */ onClick={() => {
                  if (fields.rememberMe) {
                    Cookies.set(
                      "credentials",
                      JSON.stringify({
                        userName: fields.userName,
                        password: fields.password,
                      }),
                      { expires: 7 }
                    );
                  } else {
                    Cookies.remove("credentials");
                  }
                  dispatch(
                    actions.loginV3({
                      data: {
                        userName: fields.userName?.trim(),
                        password: encryptingText(fields.password),
                      },
                    })
                  );
                  // if(isAuthenticated){
                  //   navigate("/dashboard")
                  // }
                }}
                disabled={
                  !fields?.userName || !fields?.password || errFields.userName
                }
              />

              {/* <p className="login-term">
                By clicking on Login, I accept the{" "}
                <a href="https://freedo.rentals/term-condition" target="_blank">
                  Terms & Conditions{" "}
                </a>
                &{" "}
                <a href="https://freedo.rentals/privacy-policy" target="_blank">
                  Privacy Policy.
                </a>{" "}
              </p> */}

              <div className="unlock-account">
                {" "}
                <Link to={`/user-unlock-account`}>
                  {locale?.newAuth?.unlockAccount}
                </Link>{" "}
              </div>
            </>
          }
          // messagePopUp={<MessagePopUp className={"pw-sucess"} message={""} />}
        />
      )}
    </>
  );
}
export default LoginV3;
