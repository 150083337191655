import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { get } from "lodash";
import * as React from "react";
import { colors } from "../../../themes/colors";

// this component is in progress need to be updated

type Order = "asc" | "desc";

interface EnhancedTableProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  columns: any;
  showCheckBox: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, columns } = props;
  return (
    <TableHead style={{ backgroundColor: colors.THEME_BLUE }}>
      <TableRow>
        {columns?.map((headCell: any) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell?.align}
              //padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell?.id ? order : false}
              style={{ color: colors.white, cursor: "default" }}
            >
              {headCell.headerName}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function MUITable({
  columns,
  rows,
  extraData,
  loading,
  showCheckBox = false,
  page,
  size = 10,
  localPagination = false,
  dense,
  rowStyle,
  containerStyle,
}: any) {
  const [order, setOrder] = React.useState<any>("desc");
  const [orderBy, setOrderBy] = React.useState<any>("bookingDate");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [pageNo, setPageNo] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(size);

  React.useEffect(() => {
    setPageNo(page);
  }, [page]);

  React.useEffect(() => {
    setRowsPerPage(size);
  }, [size]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
        {/* <EnhancedTableToolbar rowsPerPage={rowsPerPage}/> */}
        <TableContainer sx={containerStyle}>
          <Table
            sx={{
             
              "& .MuiTableRow-root:hover": {
                backgroundColor: "rgba(25, 189, 189,0.2)",
                color: "white",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows?.length}
              columns={columns}
              showCheckBox={showCheckBox}
            />
            {loading ? (
              <TableBody style={{ minWidth: 200 }}>
                {new Array(6).fill(1).map((eachData) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={Math.random()}
                    style={{ backgroundColor: "white" }}
                  >
                    {columns?.map((column: any) => (
                      <TableCell
                        size="small"
                        key={Math.random()}
                        // align={column.align}
                      >
                        <Skeleton
                          variant={"rounded"}
                          style={{
                            height: 30,
                            //width: screenSize.dynamicWidth * 0.78,
                          }}
                          animation="wave"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : rows && rows?.length === 0 ? (
              <TableCell colSpan={columns?.length} align="center">
                No Data Found
              </TableCell>
            ) : (
              <>
                <TableRow sx={{ display: "flex", flexDirection: "column" }}>
                  {/* {columns.map((item: any) => {
                      return <TableCell align="right">{item.label}</TableCell>;
                    })} */}
                </TableRow>
                <TableBody>
                  {rows?.length
                    ? (localPagination
                        ? rows.slice(
                            pageNo * rowsPerPage,
                            pageNo * rowsPerPage + rowsPerPage
                          )
                        : rows
                      )?.map((row: any, index: number) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                            hover={!row?.bgColor}
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            sx={{
                              cursor: "pointer",
                              fontSize: "13px",
                              align: "left",
                              backgroundColor: row?.bgColor ? row?.bgColor : "",
                              height: "25px",
                              ...rowStyle,
                            }}
                            onClick={() => {}}
                          >
                            {columns?.map((column: any) => {
                              const value = get(row, column.accessorKey);

                              return (
                                <TableCell
                                  component="th"
                                  //id={labelId}
                                  size="small"
                                  scope="row"
                                  padding="normal"
                                  align={column.align}
                                  sx={{
                                    ...column?.cellStyle,
                                  }}
                                  key={column.id}
                                >
                                  {column.customJsx
                                    ? column?.customJsx({
                                        row,
                                        value,
                                        extraData,
                                        rows,
                                        accessorKey: column.accessorKey,
                                      })
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
