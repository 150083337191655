import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography
} from "@mui/material";
import CustomHoverPopover from "../../../../sharedComponents/molecules/Popover/HoverPopover";
import { colors } from "../../../../themes/colors";
import {
  BookingChargesSubCategoriesDisplayName,
  BookingRefundTypes,
  statusColorCode,
  typographyConstants,
} from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
import {
  getFormattedINR,
  toPascalCase
} from "../../../utils/helper";

const RefundDetailsDrawer = ({ refundDetails }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "15px",
      }}
    >
      {refundDetails?.length ? (
        refundDetails?.map((item: any) => {
          return (
            <Card sx={{ border: `1px solid ${colors.card_border}` }}>
              <CardHeader
                sx={{
                  height: "45px",
                  padding: "15px",
                  borderBottom: `1px solid ${colors.card_border_bottom}`,
                }}
                titleTypographyProps={{
                  variant: typographyConstants.SUBHEADING,
                }}
                title={
                  BookingRefundTypes[
                    item?.type as keyof typeof BookingRefundTypes
                  ]
                }
                action={
                  <Chip
                    label={toPascalCase(item?.status)}
                    sx={{
                      padding: "4px",
                      height: "25px",
                      color: colors.white,
                      backgroundColor:
                        statusColorCode[
                          item?.status as keyof typeof statusColorCode
                        ],
                    }}
                  />
                }
              />
              <CardContent sx={{ padding: "10px" }}>
                {item?.adjustments?.length ? (
                  <Box
                    sx={{
                      //borderBottom: `1px solid ${colors.card_border_bottom}`,
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: `1px solid ${colors.card_border_bottom}`,
                        marginBottom: "5px",
                      }}
                    >
                      <Typography variant={typographyConstants.SUBHEADING}>
                        Adjustments
                      </Typography>
                    </Box>
                    {item?.adjustments?.map((item: any) => {
                      const amount = item?.amount;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>
                            {" "}
                            {
                              BookingChargesSubCategoriesDisplayName[
                                item.subCategory as keyof typeof BookingChargesSubCategoriesDisplayName
                              ]
                            }
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              justifyContent: "space-between",
                              marginRight:
                                item.subCategory == "SECURITY_CHARGES"
                                  ? "19px"
                                  : "0px",
                            }}
                          >
                            <Typography>{getFormattedINR(amount)}</Typography>
                            <CustomHoverPopover
                              popupContent={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "300px",
                                    padding: "10px 20px",
                                    gap: "5px",
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant={typographyConstants.BODYSMALL}
                                      >
                                        Tax Details:{" "}
                                      </Typography>
                                      {!item?.taxDetails?.length ? (
                                        <Typography
                                          variant={
                                            typographyConstants.BODYSMALL
                                          }
                                        >
                                          {en.global.NA}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    {item?.taxDetails?.map((item: any) => {
                                      let amount = item?.amount;
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {item?.type}
                                          </Typography>
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {getFormattedINR(amount) ??
                                              en.global.NA}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                  {/* <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      variant={typographyConstants.BODYSMALL}
                                    >
                                      Split Details:{" "}
                                    </Typography>
                                    {!item?.splitDetails?.length ? (
                                      <Typography
                                        variant={
                                          typographyConstants.BODYSMALL
                                        }
                                      >
                                        {en.global.NA}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  {item?.splitDetails?.map((item: any) => {
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          variant={
                                            typographyConstants.SUBHEADING
                                          }
                                        >
                                          {item?.operatorId}
                                        </Typography>
                                        <Typography
                                          variant={
                                            typographyConstants.SUBHEADING
                                          }
                                        >
                                          {getFormattedINR(item?.amount)}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box> */}
                                </Box>
                              }
                              hoverContent={
                                item.subCategory !== "SECURITY_CHARGES" ? (
                                  <InfoIcon fontSize="small" color="primary" />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
                {item?.refundedCharges?.length && (
                  <Box
                    sx={{
                      //borderBottom: `1px solid ${colors.card_border_bottom}`,
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: `1px solid ${colors.card_border_bottom}`,
                        marginBottom: "5px",
                      }}
                    >
                      <Typography variant={typographyConstants.SUBHEADING}>
                        Refund Charges
                      </Typography>
                    </Box>
                    {item?.refundedCharges?.map((item: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>
                            {" "}
                            {
                              BookingChargesSubCategoriesDisplayName[
                                item.subCategory as keyof typeof BookingChargesSubCategoriesDisplayName
                              ]
                            }
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5px",
                              marginRight:
                                item.subCategory == "SECURITY_CHARGES"
                                  ? "19px"
                                  : "0px",
                            }}
                          >
                            <Typography>
                              {getFormattedINR(item?.amount)}
                            </Typography>
                            <CustomHoverPopover
                              popupContent={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "300px",
                                    padding: "10px 20px",
                                    gap: "5px",
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant={typographyConstants.BODYSMALL}
                                      >
                                        Tax Details:{" "}
                                      </Typography>
                                      {!item?.taxDetails?.length ? (
                                        <Typography
                                          variant={
                                            typographyConstants.BODYSMALL
                                          }
                                        >
                                          {en.global.NA}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    {item?.taxDetails?.map((item: any) => {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {item?.type}
                                          </Typography>
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {getFormattedINR(item?.amount) ??
                                              en.global.NA}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                  {/* <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant={typographyConstants.BODYSMALL}
                                      >
                                        Split Details:{" "}
                                      </Typography>
                                      {!item?.splitDetails?.length ? (
                                        <Typography
                                          variant={
                                            typographyConstants.BODYSMALL
                                          }
                                        >
                                          {en.global.NA}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                    {item?.splitDetails?.map((item: any) => {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {item?.operatorId}
                                          </Typography>
                                          <Typography
                                            variant={
                                              typographyConstants.SUBHEADING
                                            }
                                          >
                                            {getFormattedINR(item?.amount)}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </Box> */}
                                </Box>
                              }
                              hoverContent={
                                item.subCategory !== "SECURITY_CHARGES" ? (
                                  <InfoIcon fontSize="small" color="primary" />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: item?.refundDetails?.refNumber
                      ? "0px"
                      : "25px",
                    //padding: "5px 20px",
                  }}
                >
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    sx={{ fontSize: "13px" }}
                  >
                    {" "}
                    {en.paymentManagement.finalRefundAmount}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant={typographyConstants.SUBHEADING}
                      sx={{
                        fontSize: "13px",
                        flexDirection: "row",
                        marginRight: "4px",
                      }}
                    >
                      {getFormattedINR(item?.finalRefundAmount)}
                    </Typography>
                    {item?.refundDetails?.refNumber ? (
                      <CustomHoverPopover
                        popupContent={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "300px",
                              padding: "10px 20px",
                              gap: "5px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant={typographyConstants.SUBHEADING}
                              >
                                {en.paymentManagement.refARNNo} :
                              </Typography>
                              <Typography
                                variant={typographyConstants.SUBHEADING}
                              >
                                {item?.refundDetails?.refNumber ?? en.global.NA}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        hoverContent={
                          item.subCategory !== "SECURITY_CHARGES" ? (
                            <InfoIcon fontSize="small" color="primary" />
                          ) : (
                            <></>
                          )
                        }
                      />
                    ) : null}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Typography
          variant={typographyConstants.SUBHEADING}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {en.paymentManagement.refundDetailsNotFound}
        </Typography>
      )}
    </Box>
  );
};
export default RefundDetailsDrawer;
