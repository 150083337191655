import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridEditBooleanCellProps,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { storeFilterDataInLocalStorage } from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import { RootState } from "../../../../redux/store";
import CustomMultiSelect from "../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { CustomResetIcon } from "../../../../sharedComponents/atoms/reset";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../themes/colors";
import {
  GenericObject,
  OperatorKycStatus,
  OperatorKycStatusColor,
  OperatorKycStatusDisplayValue,
  filterStorageKeys,
} from "../../../constants/constants";
import {
  CityInputIcon,
  SearchIcon,
  StatusIcon,
  fadedCalenderIcon,
} from "../../../constants/exportImages";
import en from "../../../locale/rental-en.json";
import { operationActions } from "../../../redux/actions";
import { routesConstants } from "../../../utils/RoutesConstants";
import {
  dateFormat,
  formatString,
  getLocalData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  toPascalCase,
} from "../../../utils/helper";
import RotateRightSharp from "@mui/icons-material/RotateRightSharp";
import { checkWhiteSpace } from "../../../utils/regex";

//operator kyc status
export const operatorKycStatusArray: GenericObject[] = [
  {
    name: OperatorKycStatus?.PENDING,
    displayName: toPascalCase(OperatorKycStatus?.PENDING),
  },
  {
    name: OperatorKycStatus?.UNVERIFIED,
    displayName: toPascalCase(OperatorKycStatusDisplayValue?.UNVERIFIED),
  },
  {
    name: OperatorKycStatus?.VERIFIED,
    displayName: toPascalCase(OperatorKycStatus?.VERIFIED),
  },
  {
    name: OperatorKycStatus?.REJECTED,
    displayName: toPascalCase(OperatorKycStatus?.REJECTED),
  },
  {
    name: OperatorKycStatus?.DELETED,
    displayName: toPascalCase(OperatorKycStatus?.DELETED),
  },
];

interface InitialState {
  leadInfo: string;
  city: any[];
  status: { name: string; displayName: string };
  startDate: string | any;
  endDate: string | any;
  paginationModelValue: {
    page: number;
    pageSize: number;
  };
}

const initialState: InitialState = {
  leadInfo: "",
  city: [],
  status: { name: "", displayName: "" },
  startDate: "",
  endDate: "",
  paginationModelValue: {
    page: 0,
    pageSize: 10,
  },
};

const ApprovedLeadListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allCitiesDetails = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { operatorList, loading } = useSelector(
    (state: RootState) => state.operatorManagementReducer
  );

  const [fields, setFields] = useState({ ...initialState });

  //handle ranges
  const handleDateRange = (value: any) => {
    let [startDate, endDate] = value;
    setFields((prevState: any) => ({
      ...prevState,
      startDate,
      endDate,
      paginationModelValue: {
        page: 0,
        pageSize: 10,
      },
    }));

    getOperatorList({
      name: fields.leadInfo,
      city:
        allCitiesDetails?.length === fields?.city.length
          ? undefined
          : fields.city,
      status: fields.status,
      startDate: startDate,
      endDate: endDate,
      page: 0,
      pageSize: 10,
    });
  };

  //handle change fields
  const handleChange = (type: string, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [type]: value,
      paginationModelValue: {
        page: 0,
        pageSize: 10,
      },
    }));
  };

  //handle pagination model change
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModelValue: { page: page, pageSize: pageSize },
    }));

    getOperatorList({
      name: fields.leadInfo,
      city: fields.city,
      status: fields.status,
      startDate: fields?.startDate,
      endDate: fields?.endDate,
      page: page,
      pageSize: pageSize,
    });
  };

  //get row id for table
  const getRowId = (row: any) => {
    return row?.operatorId;
  };

  // on table row click
  const handleRowClick = (params: GridRowParams) => {
    if (params?.row?.operatorId) {
      navigate(
        `${routesConstants.APPROVED_OPERATOR_DETAILS}/${params.row.operatorId}`
      );
    }
  };

  //booking list data list
  const columns = [
    {
      field: "name",
      headerName: en.tableHeaders.name,
      headerAlign: "center",
      flex: 0.7,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip title={toPascalCase(params.row.name)}>
            <span>{toPascalCase(params.row.name)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "city",
      headerName: en.tableHeaders.city,
      display: "flex",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={
              <>
                {params.row.operationalCity
                  ?.slice(0, 2)
                  .map((city: any, index: number) => (
                    <span key={index}>
                      {city}
                      {index < params.row.operationalCity.length - 1
                        ? ", "
                        : ""}
                    </span>
                  ))}
              </>
            }
          >
            <Box
              style={{
                width: "140px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.operationalCity
                ?.slice(0, 2)
                .map((city: any, index: number) => (
                  <span key={index}>
                    {city}
                    {index < params.row.operationalCity.length - 1 ? ", " : ""}
                  </span>
                ))}
              {params.row.operationalCity?.length > 2 && <span>...</span>}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: en.tableHeaders.mobileNo,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: any): any => {
        return <span>{params.row.phone}</span>;
      },
    },
    {
      field: "email",
      headerName: en.tableHeaders.emailAddress,
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params: any): any => {
        return (
          <Tooltip title={params.row.email} arrow>
            <span>{params.row.email}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "businessType",
      headerName: "Business Type",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: any): any => {
        return <span>{formatString(params.row.businessType)}</span>;
      },
    },

    {
      field: "revenueShare",
      headerName: "Revenue Share",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        return <span>{`${params.row.revenueShare}%`}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        return (
          <span>
            {params.row.createdAt ? dateFormat(params.row.createdAt) : "N/A"}
          </span>
        );
      },
    },
    {
      field: "kycStatus",
      headerName: "KYC Status",
      cellClassName: "booking-status-cell",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        return (
          <Typography
            style={{
              color: colors.white,
              fontWeight: "500",
              alignSelf: "center",
              width: "88px",
              backgroundColor:
                OperatorKycStatusColor[
                  params?.row?.kycStatus as keyof typeof OperatorKycStatusColor
                ],
              borderRadius: "30px",
              padding: "6px 13px 6px 13px",
            }}
          >
            {params?.row?.kycStatus
              ? OperatorKycStatusDisplayValue[
                  params.row
                    .kycStatus as keyof typeof OperatorKycStatusDisplayValue
                ]
              : en.global.NA}
          </Typography>
        );
      },
    },
  ];

  //get operator list
  const getOperatorList = (data: any) => {
    // Store filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.APPROVED_OPERATOR_MANAGEMENT_FILTER_DATA]: {
        ...initialState,
        ...data,
      },
    });

    //check if all city selected
    const isAllCitiesSelected = allCitiesDetails?.length === data?.city?.length;

    const payload: any = {
      page: data.page + 1,
      pageSize: data.pageSize,
      operatorInfo: data.name ? `"${data.name}"` : undefined,
      kycStatus: data?.status?.name ? [data.status?.name] : undefined,
      from: data?.startDate
        ? getTimestampAtStartOfDay(data.startDate)
        : undefined,
      till: data?.endDate ? getTimestampAtEndOfDay(data?.endDate) : undefined,
    };

    if (!isAllCitiesSelected && data?.city?.length > 0) {
      payload["city"] = data?.city;
    }

    if (data.startDate && !data.endDate) return;

    dispatch(operationActions.getOperatorList(payload));
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getOperatorList(payload);
    }, 1000),
    []
  );

  // handle initial cities
  const handleInitialCities = async () => {
    const tempData: InitialState = await getLocalData(
      filterStorageKeys.APPROVED_OPERATOR_MANAGEMENT_FILTER_DATA
    );
    if (allCitiesDetails?.length) {
      const arr: string[] = allCitiesDetails.map((e: any) => e.name);

      setFields((prev: any) => ({
        ...prev,
        city: tempData.city?.length ? tempData.city : arr,
      }));
    }
  };

  const handleReset = () => {
    setFields(initialState);
    // clear filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.APPROVED_OPERATOR_MANAGEMENT_FILTER_DATA]: {
        ...initialState,
      },
    });
    handleInitialCities();
    getOperatorList({
      name: "",
      city: [],
      status: { name: "", displayName: "" },
      startDate: "",
      endDate: "",
      page: 0,
      pageSize: 10,
    });
  };

  //initail call
  useEffect(() => {
    if (allCitiesDetails?.length) {
      const fetchData = async () => {
        // Fetch filters from local storage
        const tempData: InitialState = await getLocalData(
          filterStorageKeys.APPROVED_OPERATOR_MANAGEMENT_FILTER_DATA
        );

        handleInitialCities();
        const payload = {
          page: operatorList?.pagination?.page
            ? operatorList?.pagination?.page
            : initialState?.paginationModelValue?.page,
          pageSize: operatorList?.pagination?.pageSize
            ? operatorList?.pagination?.pageSize
            : initialState?.paginationModelValue?.pageSize,
          operatorInfo: tempData?.leadInfo ?? initialState?.leadInfo,
          city:
            tempData?.city?.length === allCitiesDetails?.length
              ? undefined
              : tempData?.city,
          kycStatus: tempData?.status?.name
            ? [tempData?.status?.name]
            : undefined,
          from:
            getTimestampAtStartOfDay(tempData?.startDate) ??
            initialState?.startDate,
          till:
            getTimestampAtEndOfDay(tempData?.endDate) ?? initialState?.endDate,
        };
        dispatch(operationActions.getOperatorList(payload));

        //handle inital cities arr
        const arr: string[] = allCitiesDetails.map((e: any) => e.name);

        setFields((prev: GenericObject) => ({
          ...prev,
          ...tempData,
          city: tempData.city?.length ? tempData.city : arr,
          paginationModelValue: {
            page: operatorList?.pagination?.page
              ? operatorList?.pagination?.page - 1
              : initialState?.paginationModelValue?.page,
            pageSize: operatorList?.pagination?.pageSize
              ? operatorList?.pagination?.pageSize
              : initialState?.paginationModelValue?.pageSize,
          },
        }));
      };

      fetchData();
    }
  }, [allCitiesDetails]);

  return (
    <Stack gap="10px">
      <Grid container spacing={2}>
        <Grid item sm={12} md={4} lg={3}>
          <Input
            onChange={(e: any) => {
              if (checkWhiteSpace(e.target.value))
                handleChange("leadInfo", e.target.value);
              if (e.target.value.length > 2 || e.target.value.length === 0) {
                debouncedSearch({
                  name: e.target.value,
                  city: fields.city,
                  status: fields.status,
                  startDate: fields?.startDate,
                  endDate: fields?.endDate,
                  page: 0,
                  pageSize: 10,
                });
              }
            }}
            iconStart={SearchIcon}
            value={fields.leadInfo}
            placeholder={en.operatorManagement.enterNumberEmailAddress}
            muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <RangePicker
            icon={fadedCalenderIcon}
            placeholderText={en.global.selectDateRange}
            startDate={fields?.startDate ?? ""}
            endDate={fields?.endDate ?? ""}
            handleDateChange={(update: any) => {
              handleDateRange(update);
            }}
            isClearable={fields?.startDate && fields?.endDate}
            onClickOutside={(e: any) => {
              // handling if user clicks outside after selecing start date
              if (fields?.startDate && !fields.endDate) {
                setFields((prev: any) => ({
                  ...prev,
                  startDate: initialState.startDate,
                  endDate: initialState.endDate,
                }));
              }
            }}
            maxDate={new Date()}
          />
        </Grid>

        <Grid item sm={12} md={4} lg={3}>
          <CustomMultiSelect
            icon={CityInputIcon}
            placeholder={en.global.allCities}
            selectAllText={en.global.allCities}
            value={fields?.city}
            handleChange={(values: any) => {
              handleChange("city", values);
              getOperatorList({
                name: fields.leadInfo,
                city: values,
                status: fields.status,
                startDate: fields?.startDate,
                endDate: fields?.endDate,
                page: 0,
                pageSize: 10,
              });
            }}
            choice={allCitiesDetails}
            defaultKey="name"
            displayNameKey="displayName"
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <CustomSelect
            required
            icon={StatusIcon}
            placeholder={en.global.selectStatus}
            value={fields.status?.displayName}
            choice={operatorKycStatusArray}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("status", value);
              getOperatorList({
                name: fields.leadInfo,
                city: fields?.city,
                status: value,
                startDate: fields?.startDate,
                endDate: fields?.endDate,
                page: 0,
                pageSize: 10,
              });
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <Tooltip title={en?.global?.resetFilters} arrow>
          <RotateRightSharp
            fontSize="large"
            color="primary"
            sx={{ cursor: "pointer", fontSize: "25px" }}
            onClick={handleReset}
          />
        </Tooltip>
      </Box>
      <DataTable
        autoHeight
        rows={operatorList.operatorListData}
        disableColumnMenu
        disableColumnSorting
        disableRowSelectionOnClick
        checkboxSelection={false}
        columns={columns}
        headerAlign={"center"}
        getRowId={getRowId}
        loading={loading}
        hideColumnSeperator
        rowCount={operatorList?.pagination?.total || 0}
        paginationModel={fields?.paginationModelValue}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        sx={{
          width: "100%",
          "& .booking-status-cell ": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
    </Stack>
  );
};

export default ApprovedLeadListing;
