import { Box, Button, ButtonGroup, Typography, Stack } from "@mui/material";
import styles from "../components.module.css";
import { colors } from "../../themes/colors";
import { HELMETS_ACTIONS_X } from "../../constantsX/booking";
import { OutlinedButtonX } from "../muiButtonsX";


function HelmetBadge(props: any) {
  const hoverStyle = `border: "1px solid #1bd8da"`;
  return (
    <>
      {props.helmetTypeCount === 0 ? (
        <Box
          sx={{ position: "relative", display: "inline-block" }}
          onClick={(e: any) =>
            props.clickHandler(e, props.type, HELMETS_ACTIONS_X.INCREMENT)
          }
        >
          <OutlinedButtonX
            label={props.title}
            className={styles.helmetSelectButton}
            hoverStyle={hoverStyle}
          />
          
          <Box
            className={styles.badge}
            sx={[
              {
                "&:hover": {
                  border: "1px solid #1bd8da",
                },
              },
            ]}
          >
            <Typography
              sx={{
                color: colors.text_quatenary_black,
                cursor: "pointer",
                fontSize: "0.8rem",
              }}
            >
              +
            </Typography>
          </Box>
        </Box>
      ) : (
        <Stack alignItems="center" gap="5px">
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{ height: "20px" }}
          >
            <Button
              sx={{ borderRadius: "8px" }}
              onClick={(e: any) =>
                props.clickHandler(e, props.type, HELMETS_ACTIONS_X.DECREMENT)
              }
            >
              <Typography color="#F05042">-</Typography>
            </Button>
            <Box className={styles.IncreasedSelectButton}>
              <Typography sx={{ color: "#FFFFFF", fontSize: "12px" }}>
                {props.helmetTypeCount}
              </Typography>
            </Box>
            <Button
              sx={{ borderRadius: "8px" }}
              onClick={(e: any) =>
                props.clickHandler(e, props.type, HELMETS_ACTIONS_X.INCREMENT)
              }
            >
              <Typography sx={{ color: colors.primary_aqua_blue }}>
                +
              </Typography>
            </Button>
          </ButtonGroup>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <img src={props.icon} />
            <Typography
              sx={{ fontSize: "12px", color: colors.text_quatenary_black }}
            >
              {props.title}
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  );
}

export default HelmetBadge;
