import LinkIcon from "@mui/icons-material/Link";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Skeleton, Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { OrderPaymentType } from "../../../../constantsX/YOR_BookingDetails";
import { colors } from "../../../../themes/colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { useState } from "react";

const PaymentDetailsSideModalUI = ({
  paymentLinkQRData,
  paymentLinkQRLoader,
}: any) => {
  const [isCopied, setIsCopied] = useState(false);
  const redirectLinkHandler = (link: string) => {
    if (link) {
      window.open(paymentLinkQRData?.pgDetails?.link, "_blank");
    }
  };

  return (
    <>
      <Stack
        className="PaymentLinkQrWrapper"
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "10vh" }}
      >
        {paymentLinkQRLoader ? (
          <Skeleton />
        ) : (
          <Stack alignItems="center" gap="4vh">
            {paymentLinkQRData?.paymentFlow ===
              OrderPaymentType.PAYMENT_LINK && (
              /* PAYMENT LINK */
              <>
                <Typography
                  variant="caption"
                  color={colors.text_secondary_gray}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      paymentLinkQRData?.pgDetails?.link
                    )
                  }
                  sx={{ cursor: "pointer" }}
                  title="Click to Copy"
                >
                  <LinkIcon color="primary" /> Click to Pay Now
                </Typography>

                <Stack direction="row" gap="1vw">
                  <Typography
                    sx={{ cursor: "pointer", color: "#09CACC" }}
                    onClick={(e) =>
                      redirectLinkHandler(paymentLinkQRData?.pgDetails?.link)
                    }
                  >
                    {paymentLinkQRData?.pgDetails?.link}{" "}
                  </Typography>
                  <Typography
                    title={!isCopied ? "Click to copy" : "copied"}
                    sx={{ cursor: "pointer" }}
                  >
                    {!isCopied ? (
                      <ContentCopyIcon
                        sx={{ fontSize: "1rem" }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            paymentLinkQRData?.pgDetails?.link
                          );
                          setIsCopied(true);
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 2000);
                        }}
                      />
                    ) : (
                      <ContentCopyTwoToneIcon sx={{ fontSize: "1rem" }} />
                    )}
                  </Typography>
                </Stack>
              </>
            )}
            {paymentLinkQRData?.paymentFlow === OrderPaymentType.QR_CODE && (
              <>
                <Typography
                  variant="caption"
                  color={colors.text_secondary_gray}
                >
                  <QrCodeScannerIcon color="primary" /> Scan to Pay Now
                </Typography>
                {paymentLinkQRData?.pgDetails?.imageContent ? (
                  /* ONLY QR CODE */
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      //  maxWidth: "100%", width: "100%"
                    }}
                    value={paymentLinkQRData?.pgDetails?.imageContent}
                    viewBox={`0 0 256 256`}
                  />
                ) : (
                  /* FULL QR SECTION */
                  <img
                    src={paymentLinkQRData?.pgDetails?.link}
                    alt={paymentLinkQRData?.pgDetails?.id}
                  />
                )}
              </>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default PaymentDetailsSideModalUI;
