import { Box } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { HELMETS_DISPLAY_NAME } from "../../rentals-src/constants/constants";
import { getFormattedINR } from "../../rentals-src/utils/helper";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const CustomizedTooltips = (props: any) => {
  const { content, helmetCharges, icon } = props;

  // Convert helmetCharges array to a lookup object for easier access
  const helmetChargesLookup = helmetCharges.reduce((acc: any, charge: any) => {
    acc[charge.type] = charge.price;
    return acc;
  }, {});

  return (
    <div>
      <HtmlTooltip
        title={
          <>
            <Box sx={{ width: 200 }}>
              <Box color="inherit">
                <b>Addons</b>
              </Box>
              {Object.entries(content)
                .filter(([key, value]: any) => value > 0)
                .map(([key, value]: any) => (
                  <li key={key}>
                    {HELMETS_DISPLAY_NAME[key]}: {value} X{" "}
                    {getFormattedINR(helmetChargesLookup[key])}
                  </li>
                ))}
            </Box>
          </>
        }
      >
        <img src={icon} alt="" />
      </HtmlTooltip>
    </div>
  );
};

export default CustomizedTooltips;
