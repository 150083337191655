export enum LocalStorage {
  Location_Management = "Location_Management",
  Dashboard_Management = "Dashboard_Management",
  Rental_Plans = "Rental_Plans",
  Booking_Management = "Booking_Management",
  Feedback_Management = "Feedback_Management",
  Payment_Management = "Payment_Management",
  Role_Management = "Role_Management",
  Warehouse_Management = "Warehouse_Management",
  User_Management = "User_Management",
  Inentory_Management = "Inentory_Management",
  InentoryDetailed_Management = "InentoryDetailed_Management",
  Vehicle_Management = "Vehicle_Management",
  Iot_Management = "Iot_Management",
  User_Reports = "User_Reports",
  Booking_Reports = "Booking_Reports",
  Payment_Reports = "Payment_Reports",
  Payment_Reconciliation_Reports = "Payment_Reconciliation_Reports",
  Package_Management = "Package_Management",
  Cancel_Reasons = "Cancel_Reasons",
  Promotion = "Promotion",
  User_Role = "user_role",
  token = "token",
  index = "index",
  Task_ManagementData = "Task_ManagementData",
  Task_Management_Details = "Task_Management_Details",
  User_Location = "User_Location",
  NewFeedback_Management = "NewFeedback_Management",
  Filter_Data = "filterData",
  FreedoX = "isFreedoX",
}

export enum CookieTypes {
  CREDENTIALS = "credentials",
  TOKEN = "token",
}

export enum UserTypes {
  MASTER = "MASTER",
  COMPANY = "COMPANY",
  SUB_COMPANY = "SUB_COMPANY",
  EMPLOYEE = "EMPLOYEE",
}

export enum BookingTypes {
  booking_info = "booking_info",
  vehicle_details = "vehicle_details",
  extra_charges = "extra_charges",
  user_details = "user_details",
  other_info = "other_info",
  iot_details = "iot_details",
  mitc_details = "mitc_details",
}

export enum CHARGE_NAME {
  package_charges = "Rental Charge",
  additonal_helmet_charges = "Helmet Charge",
  pick_up_delivery_charges = "Delivery Charge",
  security_deposit = "Security Deposit",
  ride_extend = "Ride Extend",
  extra_km = "Extra KM",
  drop_off_delivery_charge = "Drop Off Delivery Charge",
}

export const extraChargeStatusMap = new Map();
extraChargeStatusMap.set(1, "Pending");
extraChargeStatusMap.set(2, "Success");
extraChargeStatusMap.set(3, "Waived Off");
extraChargeStatusMap.set(4, "Failed");
extraChargeStatusMap.set(5, "Refunded");
extraChargeStatusMap.set(6, "Partial Pay");
extraChargeStatusMap.set(7, "Deleted");

export enum QR_PAYMENT_TYPE {
  MANUAL_BOOKING = "MANUAL_BOOKING",
  additonal_helmet_charges = "Helmet Charge",
  pick_up_delivery_charges = "Delivery Charge",
  security_deposit = "Security Deposit",
  ride_extend = "Ride Extend",
  extra_km = "Extra KM",
  drop_off_delivery_charge = "Drop Off Delivery Charge",
}

export enum PasswordComplexity {
  WEEK_PASSWORD = "WEEK",
  MEDIUM_PASSWORD = "MEDIUM",
  STRONG_PASSWORD = "STRONG",
}

export enum AuthUsecases {
  ForgotPassword = "forgot-password",
  UnlockAccount = "unlock-account",
  PasswordExpire = "password-expire",
  FirstLogin = "first-login",
}

export enum GoaCoordinates {
  latitude = 15.2993,
  longitude = 74.124,
}

// days of week
export enum weekDays {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

// enum for user logout session type
export enum logoutSessionType {
  CURRENT = "CURRENT",
  ALL = "ALL",
  ALL_EXCEPT_CURRENT = "ALL_EXCEPT_CURRENT",
}
