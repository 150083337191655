import { Box, Skeleton, Stack } from "@mui/material";

const PaymentLinkQRLoader = () => {
  return (
    <Stack
      justifyContent={"center"}
      gap="0.5vw"
    //   direction="row"
      alignItems={"center"}
      height={"-webkit-fill-available"}
      padding={"50px"}
    >
        {/* price */}
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="10%"
        height="10vh"
      />
      {/* QR */}
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="80vh"
      />
    </Stack>
  );
};

export default PaymentLinkQRLoader;
