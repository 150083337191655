import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";

import { handleMessage } from "../../../utils/helper";
import { GenericObject } from "../../constants/constants";
import rentalEn from "../../locale/rental-en.json";
import { routesConstants } from "../../utils/RoutesConstants";
import * as actionTypes from "../actions/actionTypes";
import surgeServices from "../services/surgeServices";

/**
 * Handles failure scenario by dispatching a failure action and displaying an info toastr.
 * @param {any} failMsg - Optional failure message.
 * @param {GenericObject} failPayload - Optional payload for failure action.
 */
function* failSaga(failMsg?: any, failPayload?: GenericObject) {
  yield put({
    type: actionTypes.SURGE_ACTIONS.SURGE_MANAGEMENT_FAIL,
    payload: failPayload || {},
  });
  toastr.info(
    rentalEn?.toastTypes?.info,
    handleMessage(failMsg) || rentalEn?.errorMessages?.unknownFailure
  );
}

/**
 * Handles error scenario by dispatching an error action and displaying an error toastr.
 * @param {any} errorMsg - Optional error message.
 * @param {GenericObject} errorPayload - Optional payload for error action.
 */
function* errorSaga(errorMsg?: any, errorPayload?: GenericObject) {
  yield put({
    type: actionTypes.SURGE_ACTIONS.SURGE_MANAGEMENT_ERROR,
    payload: errorPayload || {},
  });
  toastr.error(
    rentalEn?.toastTypes?.error,
    handleMessage(errorMsg) || rentalEn?.errorMessages?.unknownError
  );
}

// Saga for creating a new surge.
function* createSurgeApiSaga(action: any): any {
  try {
    const data = yield call(
      surgeServices.createSurge,
      action?.payload?.payloadObject
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.SURGE_ACTIONS.CREATE_SURGE_SUCCESS,
        payload: {
          createSurgeSuccessData: {},
        },
      });
      action?.payload?.navigate(routesConstants.SURGE_LIST, { replace: true }); // navigate to surge list screen
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        createSurgeSuccessData: {},
        addEditSurgeLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      createSurgeSuccessData: {},
      addEditSurgeLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga for retrieving surge list data.
function* getSurgeListSaga(action: any): any {
  try {
    const data = yield call(surgeServices.getSurgeList, action.payload);
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_SUCCESS,
        payload: {
          surgeListApiResponseData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        surgeListData: [],
        surgeListApiResponseData: {},
        pageNumber: 1,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      surgeListData: [],
      surgeListApiResponseData: {},
      pageNumber: 1,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga for updating surge status.
function* updateSurgeStatusSaga(action: any): any {
  try {
    const data = yield call(surgeServices.updateSurgeStatus, action.payload);
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.SURGE_ACTIONS.UPDATE_SURGE_STATUS_SUCCESS,
        payload: {
          updatedSurgeStatus: data?.data?.data || {},
          fromSurgeStatusUpdate: true,
        },
      });
      yield put({
        type: actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_SUCCESS,
        payload: {
          updatedSurgeStatus: data?.data?.data || {},
          fromSurgeStatusUpdate: true,
        },
      });
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        updatedSurgeStatus: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      updatedSurgeStatus: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga for retrieving surge details by ID.
function* getSurgeByIdSaga(action: any): any {
  try {
    const data = yield call(surgeServices.getSurgeById, action.payload);
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.SURGE_ACTIONS.GET_SURGE_BY_ID_SUCCESS,
        payload: {
          surgeByIdData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        surgeByIdData: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      surgeByIdData: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga for updating/editing surge details.
function* updateSurgeSage(action: any): any {
  try {
    const data = yield call(
      surgeServices.updateSurge,
      action?.payload?.payloadObject
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.SURGE_ACTIONS.UPDATE_SURGE_SUCCESS,
        payload: {
          updatedSurgeData: {},
          surgeByIdData: {},
        },
      });
      action?.payload?.navigate(routesConstants.SURGE_LIST, { replace: true }); // navigate to surge list screen
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        updatedSurgeData: {},
        addEditSurgeLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      updatedSurgeData: {},
      addEditSurgeLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* surgeSaga() {
  yield takeLatest(
    actionTypes.SURGE_ACTIONS.CREATE_SURGE_REQUESTED,
    createSurgeApiSaga
  );
  yield takeLatest(
    actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_REQUESTED,
    getSurgeListSaga
  );
  yield takeLatest(
    actionTypes.SURGE_ACTIONS.UPDATE_SURGE_STATUS_REQUESTED,
    updateSurgeStatusSaga
  );
  yield takeLatest(
    actionTypes.SURGE_ACTIONS.GET_SURGE_BY_ID_REQUESTED,
    getSurgeByIdSaga
  );
  yield takeLatest(
    actionTypes.SURGE_ACTIONS.UPDATE_SURGE_REQUESTED,
    updateSurgeSage
  );
}

export default surgeSaga;
