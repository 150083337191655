import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import yoren from "../../../../locale/yor-en.json";
import { RootState } from "../../../../redux/store";
import BikeIcon from "../../../assets/images/BikeIcon.svg";
import Tick from "../../../assets/images/Tick.svg";
import InputDatePicker from "../../../components/InputFields/InputDatePicker";
import SelectX from "../../../components/InputFields/InputSelectX";
import PlanCard from "../../../components/MultiStepForm/plansCard";
import { PlanDetailsProps } from "../../../interfaces/manualBookingInterfaces";
import { actionsX } from "../../../redux";
import { colors } from "../../../themes/colors";
import { dateFormat, getValueByKey } from "../../../utils/helper";
import styles from "../ManualBooking.module.css";
import { Heading } from "../../../../components/typography";

const PlanDetails = (props: PlanDetailsProps) => {
  const dispatch = useDispatch();

  const {
    fields,
    setFields,
    errorFields,
    setErrorFields,
    initialState,
    setDate,
    date,
    filteredPlan
  } = props;
  
  const { modelList, modelMapData } = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { rentalPlans, durationInMonths } = useSelector(
    (state: RootState) => state.rentalPlansReducerX
  );

  const handleSelectPlan = (field: any, plan: {}, key = "") => {
    if (key !== fields?.selectedPlan?.id) {
      setFields((prevState: any) => ({
        ...prevState,
        [field]: plan,
      }));
    }
    setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
  };

  //CHANGE VEHICLE MODAL
  const handleChange = (field: any, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [field]: value,
      helmets: { ...initialState.helmets },
    }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      [field]: "",
      selectedHelmet: "",
    }));
  };

  const onChangeDate = (date: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      ["startDate"]: dateFormat(date),
    }));
    setDate(date);
  };

  //SHOW THE DISPLAY NAME OF MODAL
  const displayNameModal: any[] = [];
  modelList?.models?.map((key: any) => {
    if (modelMapData?.has(key)) {
      displayNameModal?.push(modelMapData?.get(key));
    } else {
      displayNameModal?.push(key);
    }
  });

  return (
    <div>
      <Stack gap={2}>
        {/* Select Ride Details */}
        <Box>
          <Heading
            title={yoren.manualBookingFreedoX.SelectRideDetails}
            className="w-auto"
            fontSize={15}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectX
                placeholder={yoren.manualBookingFreedoX.SelectVehicle}
                value={fields?.selectedVehicle}
                choice={displayNameModal}
                icon={BikeIcon}
                required
                handleChange={(vehicle: any) => {
                  const keyVehicle = getValueByKey(modelMapData, vehicle);
                  handleChange("selectedVehicle", vehicle);
                  dispatch(
                    actionsX.getRentalPlansX({
                      name: fields?.selectedBranch?.name,
                      modal: keyVehicle,
                    })
                  );
                }}
                errormessage={errorFields?.selectedVehicle}
              />
            </Grid>
            <Grid item xs={6}>
              {rentalPlans?.plans && (
                <InputDatePicker
                  placeholder={yoren.manualBookingFreedoX.RideStartDate}
                  value={date}
                  onChange={onChangeDate}
                  shouldDisableDate={(day: any) => {
                    //SHOULD DISABLE BEFORE DATE
                    let disabledDate = new Date(rentalPlans?.availableOn);
                    disabledDate.setDate(disabledDate.getDate() - 1);

                    //SHOULD DISABLE AFTER 6 MONTHS DATE
                    let maxDate = new Date(rentalPlans?.availableTill);

                    return (
                      day.valueOf() < disabledDate.valueOf() ||
                      day.valueOf() > maxDate.valueOf()
                    );
                  }}
                ></InputDatePicker>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* Rental Period */}
        <Box>
          {rentalPlans?.plans ? (
            <>
              <Heading
                title={yoren.manualBookingFreedoX.RentalPeriod}
                fontSize={15}
              ></Heading>
              <Box className={styles.BoxRow} gap={3}>
                {Array.isArray(durationInMonths) &&
                  durationInMonths?.map(
                    (durationInMonth: any, index: number) => (
                      <Fade>
                        <Box
                          key={durationInMonth}
                          onClick={() =>
                            handleChange(
                              "selectedDuration",
                              durationInMonths[index]
                            )
                          }
                          className={styles.durationButton}
                        >
                          <Typography
                            color={colors.text_secondary_gray}
                            fontSize={13}
                          >
                            {durationInMonth} {yoren.manualBookingFreedoX.Months}
                          </Typography>

                          {durationInMonth === fields?.selectedDuration && (
                            <img
                              src={Tick}
                              alt="tick"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: "-1px",
                              }}
                            />
                          )}
                        </Box>
                      </Fade>
                    )
                  )}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>

        {/* Rentals plans card */}

        <Box>
          {rentalPlans?.plans && (
            <Heading
              title={yoren.manualBookingFreedoX.AvailablePlans}
              className="w-auto"
              fontSize={15}
            ></Heading>
          )}
          <Box className={styles.BoxColumn} gap={1}>
            <Box className={styles.BoxRow} gap={4}>
              {filteredPlan?.length > 0 &&
                filteredPlan?.map((plan: any) => {
                  return (
                    <PlanCard
                      key={plan?.id}
                      plan={plan}
                      onClick={() =>
                        handleSelectPlan("selectedPlan", plan, plan?.id)
                      }
                      selectedPlan={fields?.selectedPlan?.id === plan?.id}
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Stack>
    </div>
  );
};

export default PlanDetails;
