export enum CLEAR_DATA_ACTIONS_TYPE {
  CLEAR_CHECK_USER_DETAILS = "CLEAR_DATA_USER",
  CLEAR_VERIFY_OTP = "CLEAR_VERIFY_OTP",
  CLEAR_BRANCHES_AND_MODELS = "CLEAR_BRANCHES_AND_MODELS",
  CLEAR_STATE_BASED_CITIES = "CLEAR_STATE_BASED_CITIES",
  CLEAR_REDUCER_DATA = "CLEAR_REDUCER_DATA",
  CLEAR_ADD_NEW_VEHICLE_DETAILS_DATA = "CLEAR_ADD_NEW_VEHICLE_DETAILS_DATA",
  CLEAR_HUBS_LIST_DATA = "CLEAR_HUBS_LIST_DATA",
}

export enum MANUAL_BOOKING_ACTIONS {
  SEARCH_USER_REQUESTED = "SEARCH_USER_REQUESTED",
  SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS",
  SEARCH_USER_FAILED = "SEARCH_USER_FAILED",

  GET_AVAILABLE_MODELS_REQUESTED = "GET_AVAILABLE_MODELS_REQUESTED",
  GET_AVAILABLE_MODELS_SUCCESS = "GET_AVAILABLE_MODELS_SUCCESS",
  GET_AVAILABLE_MODELS_FAILED = "GET_AVAILABLE_MODELS_FAILED",

  CALCULATE_CHARGES_REQUESTED = "CALCULATE_CHARGES_REQUESTED",
  CALCULATE_CHARGES_SUCCESS = "CALCULATE_CHARGES_SUCCESS",
  CALCULATE_CHARGES_FAILED = "CALCULATE_CHARGES_FAILED",

  GET_SLOT_PAUSED_REQUESTED = "GET_SLOT_PAUSED_REQUESTED",
  GET_SLOT_PAUSED_SUCCESS = "GET_SLOT_PAUSED_SUCCESS",
  GET_SLOT_PAUSED_FAILED = "GET_SLOT_PAUSED_FAILED",

  GET_MODEL_DETAILS_REQUESTED = "GET_MODEL_DETAILS_REQUESTED",
  GET_MODEL_DETAILS_SUCCESS = "GET_MODEL_DETAILS_SUCCESS",
  GET_MODEL_DETAILS_FAILED = "GET_MODEL_DETAILS_FAILED",

  GET_DELIVERY_SLOTS_REQUESTED = "GET_DELIVERY_SLOTS_REQUESTED",
  GET_DELIVERY_SLOTS_FAILED = "GET_DELIVERY_SLOTS_FAILED",
  GET_DELIVERY_SLOTS_SUCCESS = "GET_DELIVERY_SLOTS_SUCCESS",

  SEARCH_CUSTOMER_REQUESTED = "SEARCH_CUSTOMER_REQUESTED",
  SEARCH_CUSTOMER_FAILED = "SEARCH_CUSTOMER_FAILED",
  SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS",

  REGISTER_USER_REQUESTED = "REGISTER_USER_REQUESTED",
  REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILED = "REGISTER_USER_FAILED",

  VERIFY_OTP_REQUESTED = "VERIFY_OTP_REQUESTED",
  VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED",

  RESEND_OTP_RENTAL_REQUESTED = "RESEND_OTP_RENTAL_REQUESTED",
  RESEND_OTP_RENTAL_SUCCESS = "RESEND_OTP_RENTAL_SUCCESS",
  RESEND_OTP_RENTAL_FAILED = "RESEND_OTP_RENTAL_FAILED",

  GET_CUSTOMER_PROFILE_REQUESTED = "GET_CUSTOMER_PROFILE_REQUESTED",
  GET_CUSTOMER_PROFILE_SUCCESS = "GET_CUSTOMER_PROFILE_SUCCESS",
  GET_CUSTOMER_PROFILE_FAILED = "GET_CUSTOMER_PROFILE_FAILED",

  ADD_CUSTOMER_ADDRESS_REQUESTED = "ADD_CUSTOMER_ADDRESS_REQUESTED",
  ADD_CUSTOMER_ADDRESS_SUCCESS = "ADD_CUSTOMER_ADDRESS_SUCCESS",
  ADD_CUSTOMER_ADDRESS_FAILED = "ADD_CUSTOMER_ADDRESS_FAILED",

  GET_CUSTOMER_ADDRESS_LIST_REQUESTED = "GET_CUSTOMER_ADDRESS_LIST_REQUESTED",
  GET_CUSTOMER_ADDRESS_LIST_SUCCESS = "GET_CUSTOMER_ADDRESS_LIST_SUCCESS",
  GET_CUSTOMER_ADDRESS_LIST_FAILED = "GET_CUSTOMER_ADDRESS_LIST_FAILED",

  CREATE_BOOKING_REQUESTED = "CREATE_BOOKING_REQUESTED",
  CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS",
  CREATE_BOOKING_FAILED = "CREATE_BOOKING_FAILED",

  RENTAL_CLEAR_REDUCER_DATA = "RENTAL_CLEAR_REDUCER_DATA",

  GET_SIGNED_URLS_ATTACHMENT = "GET_SIGNED_URLS_ATTACHMENT",
}

export enum COMMON_ACTIONS {
  GET_ALL_CITIES_SUCCESS = "GET_ALL_CITIES_SUCCESS",
  GET_ALL_CITIES_FAILED = "GET_ALL_CITIES_FAILED",
  GET_ALL_CITIES_REQUESTED = "GET_ALL_CITIES_REQUESTED",

  GET_BRANCH_DATA_SUCCESS = "GET_BRANCH_DATA_SUCCESS",
  GET_BRANCH_DATA_FAILED = "GET_BRANCH_DATA_FAILED",
  GET_BRANCH_DATA_REQUESTED = "GET_BRANCH_DATA_REQUESTED",

  GET_BRANCH_DATA_BY_CITIES_REQUESTED = "GET_BRANCH_DATA_BY_CITIES_REQUESTED",
  GET_BRANCH_DATA_BY_CITIES_SUCCESS = "GET_BRANCH_DATA_BY_CITIES_SUCCESS",
  GET_BRANCH_DATA_BY_CITIES_FAILED = "GET_BRANCH_DATA_BY_CITIES_FAILED",

  GET_MODEL_DATA_REQUESTED = "GET_VEHICLE_MODEL_DATA_REQUESTED",
  GET_MODEL_DATA_SUCCESS = "GET_VEHICLE_MODEL_DATA_SUCCESS",
  GET_MODEL_DATA_FAILED = "GET_VEHICLE_MODEL_DATA_FAILED",

  GET_USER_ACCESS_CITIES = "GET_USER_ACCESS_CITIES",

  GET_MASTER_PLAN_DURATIONS_REQUESTED = "GET_MASTER_PLAN_DURATIONS_REQUESTED",
  GET_MASTER_PLAN_DURATIONS_SUCCESS = "GET_MASTER_PLAN_DURATIONS_SUCCESS",
  GET_MASTER_PLAN_DURATIONS_FAILED = "GET_MASTER_PLAN_DURATIONS_FAILED",

  GET_MODEL_LIST_BY_BRANCH_REQUESTED = "GET_MODEL_LIST_BY_BRANCH_REQUESTED",
  GET_MODEL_LIST_BY_BRANCH_SUCCESS = "GET_MODEL_LIST_BY_BRANCH_SUCCESS",
  GET_MODEL_LIST_BY_BRANCH_FAILED = "GET_MODEL_LIST_BY_BRANCH_FAILED",

  GET_ALL_STATES_REQUESTED = "GET_ALL_STATES_REQUESTED",
  GET_ALL_STATES_SUCCESS = "GET_ALL_STATES_SUCCESS",
  GET_ALL_STATES_FAILED = "GET_ALL_STATES_FAILED",

  GET_MASTER_PAYMENT_MODE_REQUESTED = "GET_MASTER_PAYMENT_MODE_REQUESTED",
  GET_MASTER_PAYMENT_MODE_SUCCESS = "GET_MASTER_PAYMENT_MODE_SUCCESS",
  GET_MASTER_PAYMENT_MODE_FAILED = "GET_MASTER_PAYMENT_MODE_FAILED",

  GET_CITY_BY_STATE_REQUESTED = "GET_CITY_BY_STATE_REQUESTED",
  GET_CITY_BY_STATE_SUCCESS = "GET_CITY_BY_STATE_SUCCESS",
  GET_CITY_BY_STATE_FAILED = "GET_CITY_BY_STATE_FAILED",

  RENTALS_GET_CITY_DETAILS_REQUESTED = "RENTALS_GET_CITY_DETAILS_REQUESTED",
  RENTALS_GET_CITY_DETAILS_SUCCESS = "RENTALS_GET_CITY_DETAILS_SUCCESS",
  RENTALS_GET_CITY_DETAILS_FAILED = "RENTALS_GET_CITY_DETAILS_FAILED",

  GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED = "GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED",
  GET_OPERATOR_CONFIG_BY_BRANCH_SUCCESS = "GET_OPERATOR_CONFIG_BY_BRANCH_SUCCESS",
  GET_OPERATOR_CONFIG_BY_BRANCH_FAILED = "GET_OPERATOR_CONFIG_BY_BRANCH_FAILED",

  GET_PAYMENT_STATUS_REQUESTED = "GET_PAYMENT_STATUS_REQUESTED",
  GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS",
  GET_PAYMENT_STATUS_FAILED = "GET_PAYMENT_STATUS_FAILED",

  // Set screen header name
  SET_SCREEN_HEADER_NAME = "SET_SCREEN_HEADER_NAME",

  // set breadcrumbs
  ADD_ACTIVE_MODULE = "ADD_ACTIVE_MODULE",
  CLEAR_ACTIVE_MODULE = "CLEAR_ACTIVE_MODULE",

  GET_BOOKING_CANCELLATION_REASONS_REQUESTED = "GET_LTR_BOOKING_CANCELLATION_REASONS_REQUESTED",
  GET_BOOKING_CANCELLATION_REASONS_SUCCESS = "GET_LTR_BOOKING_CANCELLATION_REASONS_SUCCESS",
  GET_BOOKING_CANCELLATION_REASONS_FAILED = "GET_LTR_BOOKING_CANCELLATION_REASONS_FAILED",

  GET_CANCELLATION_CHARGES_REQUESTED = "GET_LTR_CANCELLATION_CHARGES_REQUESTED",
  GET_CANCELLATION_CHARGES_SUCCESS = "GET_LTR_CANCELLATION_CHARGES_SUCCESS",
  GET_CANCELLATION_CHARGES_FAILED = "GET_LTR_CANCELLATION_CHARGES_FAILED",

  CLEAR_CANCELLATION_CHARGES = "CLEAR_CANCELLATION_CHARGES",

  GET_MASTER_CHARGES_LIST_REQUESTED = "GET_MASTER_CHARGES_LIST_REQUESTED",
  GET_MASTER_CHARGES_LIST_SUCCESS = "GET_MASTER_CHARGES_LIST_SUCCESS",
  GET_MASTER_CHARGES_LIST_FAILED = "GET_MASTER_CHARGES_LIST_FAILED",

  PAYMENT_LINK_GENERATED_REQUESTED = "PAYMENT_LINK_GENERATED_REQUESTED",
  PAYMENT_LINK_GENERATED_SUCCESS = "PAYMENT_LINK_GENERATED_SUCCESS",
  PAYMENT_LINK_GENERATED_FAILED = "PAYMENT_LINK_GENERATED_FAILED",

  TOOGLE_PAYMENT_VIEW = "TOOGLE_PAYMENT_VIEW",

  RENTAL_COMMOM_CLEAR_REDUCER_DATA = "RENTAL_COMMOM_CLEAR_REDUCER_DATA",

  GET_UPDATED_PAYMENT_STATUS = "GET_UPDATED_PAYMENT_STATUS",

  CANCEL_PAYMENT_STATUS_SAGA = "CANCEL_PAYMENT_STATUS_SAGA",

  GET_BRANCH_DEFAULT_CONFIG_REQUESTED = "GET_BRANCH_DEFAULT_CONFIG_REQUESTED",
  GET_BRANCH_DEFAULT_CONFIG_SUCCESS = "GET_BRANCH_DEFAULT_CONFIG_SUCCESS",
  GET_BRANCH_DEFAULT_CONFIG_FAILED = "GET_BRANCH_DEFAULT_CONFIG_FAILED",

  CLEAR_BRANCH_DEFAULT_CONFIG = "CLEAR_BRANCH_DEFAULT_CONFIG",

  // Get cities based on multiple states
  GET_CITIES_BY_MULTIPLE_STATES_REQUESTED = "GET_CITIES_BY_MULTIPLE_STATES_REQUESTED",
  GET_CITIES_BY_MULTIPLE_STATES_SUCCESS = "GET_CITIES_BY_MULTIPLE_STATES_SUCCESS",
  GET_CITIES_BY_MULTIPLE_STATES_FAILED = "GET_CITIES_BY_MULTIPLE_STATES_FAILED",

  // Get cities based on multiple states
  GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED = "GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED",
  GET_STATES_AND_DISTRICT_BY_PINCODE_SUCCESS = "GET_STATES_AND_DISTRICT_BY_PINCODE_SUCCESS",
  GET_STATES_AND_DISTRICT_BY_PINCODE_FAILED = "GET_STATES_AND_DISTRICT_BY_PINCODE_FAILED",
  GET_STATES_AND_DISTRICT_BY_PINCODE_CLEAR = "GET_STATES_AND_DISTRICT_BY_PINCODE_CLEAR",


  SET_BOOKING_REDUCER_DATA = "SET_BOOKING_REDUCER_DATA",
  SAVE_OPERATOR_CONFIG_RESPONSE = "SAVE_OPERATOR_CONFIG_RESPONSE",
}

export enum PLAN_MANAGEMENT_ACTIONS {
  // Get Rental Plan List
  API_RENTAL_PLAN_LOAD = "API_RENTAL_PLAN_LOAD",
  API_RENTAL_PLAN_SUCCESS = "API_RENTAL_PLAN_SUCCESS",
  API_RENTAL_PLAN_FAIL = "API_RENTAL_PLAN_FAIL",
  EDIT_CURRENT_RENTAL_PLAN = "EDIT_CURRENT_RENTAL_PLAN",
  CLEAR_CURRENT_RENTAL_PLAN_LIST = "CLEAR_CURRENT_RENTAL_PLAN_LIST",

  // Get Rental Plan By Id
  API_RENTAL_PLAN_BY_ID_LOAD = "API_RENTAL_PLAN_BY_ID_LOAD",
  API_RENTAL_PLAN_BY_ID_SUCCESS = "API_RENTAL_PLAN_BY_ID_SUCCESS",
  API_RENTAL_PLAN_BY_ID_FAIL = "API_RENTAL_PLAN_BY_ID_FAIL",

  // Update Rental Plan Status
  UPDATE_RENTAL_PLAN_STATUS_LOAD = "UPDATE_RENTAL_PLAN_STATUS_LOAD",
  UPDATE_RENTAL_PLAN_STATUS_SUCCESS = "UPDATE_RENTAL_PLAN_STATUS_SUCCESS",
  UPDATE_RENTAL_PLAN_STATUS_FAIL = "UPDATE_RENTAL_PLAN_STATUS_FAIL",

  // Update Rental Plan
  UPDATE_RENTAL_PLAN_LOAD = "UPDATE_RENTAL_PLAN_LOAD",
  UPDATE_RENTAL_PLAN_SUCCESS = "UPDATE_RENTAL_PLAN_SUCCESS",
  UPDATE_RENTAL_PLAN_FAIL = "UPDATE_RENTAL_PLAN_FAIL",
  CLEAR_RENTAL_PLAN_ERROR_DATA = "CLEAR_RENTAL_PLAN_ERROR_DATA",

  // Create Rental Plan
  CREATE_RENTAL_PLAN_LOAD = "CREATE_RENTAL_PLAN_LOAD",
  CREATE_RENTAL_PLAN_SUCCESS = "CREATE_RENTAL_PLAN_SUCCESS",
  CREATE_RENTAL_PLAN_FAIL = "CREATE_RENTAL_PLAN_FAIL",

  // Get Package Modal List
  API_DEFAULT_PACKAGE_LIST_LOAD = "API_DEFAULT_PACKAGE_LIST_LOAD",
  API_DEFAULT_PACKAGE_LIST_SUCCESS = "API_DEFAULT_PACKAGE_LIST_SUCCESS",
  API_DEFAULT_PACKAGE_LIST_FAIL = "API_DEFAULT_PACKAGE_LIST_FAIL",
}

export enum VEHICLE_MANAGEMENT_ACTIONS {
  GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED = "GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED",
  GET_INVENTORY_CALENDAR_VIEW_DATA_SUCCESS = "GET_INVENTORY_CALENDAR_VIEW_DATA_SUCCESS",
  GET_INVENTORY_CALENDAR_VIEW_DATA_FAILDED = "GET_INVENTORY_CALENDAR_VIEW_DATA_FAILDED",

  GET_ONGOING_BOOKINGS_DETAILS_REQUESTED = "GET_ONGOING_BOOKINGS_DETAILS_REQUESTED",
  GET_ONGOING_BOOKINGS_DETAILS_SUCCESS = "GET_ONGOING_BOOKINGS_DETAILS_SUCCESS",
  GET_ONGOING_BOOKINGS_DETAILS_FAILED = "GET_ONGOING_BOOKINGS_DETAILS_FAILED",

  GET_BLOCKED_VEHICLES_DETAILS_REQUESTED = "GET_BLOCKED_VEHICLES_DETAILS_REQUESTED",
  GET_BLOCKED_VEHICLES_DETAILS_SUCCESS = "GET_BLOCKED_VEHICLES_DETAILS_SUCCESS",
  GET_BLOCKED_VEHICLES_DETAILS_FAILED = "GET_BLOCKED_VEHICLES_DETAILS_FAILED",

  GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED = "GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED",
  GET_UPCOMING_BOOKINGS_DETAILS_SUCCESS = "GET_UPCOMING_BOOKINGS_DETAILS_SUCCESS",
  GET_UPCOMING_BOOKINGS_DETAILS_FAILED = "GET_UPCOMING_BOOKINGS_DETAILS_FAILED",

  VEHICLE_MANAGEMENT_ERROR = "VEHICLE_MANAGEMENT_ERROR",
  VEHICLE_MANAGEMENT_FAIL = "VEHICLE_MANAGEMENT_FAIL",

  GET_VEHICLES_LIST_REQUESTED = "GET_VEHICLES_LIST_REQUESTED",
  GET_VEHICLES_LIST_SUCCESS = "GET_VEHICLES_LIST_SUCCESS",
  GET_VEHICLES_LIST_FAILED = "GET_VEHICLES_LIST_FAILED",

  UPDATE_VEHICLE_STATUS_REQUESTED = "UPDATE_VEHICLE_STATUS_REQUESTED",
  UPDATE_VEHICLE_STATUS_SUCCESS = "UPDATE_VEHICLE_STATUS_SUCCESS",
  UPDATE_VEHICLE_STATUS_FAILED = "UPDATE_VEHICLE_STATUS_FAILED",

  ADD_NEW_VEHICLE_DETAILS_REQUESTED = "ADD_NEW_VEHICLE_DETAILS_REQUESTED",
  ADD_NEW_VEHICLE_DETAILS_SUCCESS = "ADD_NEW_VEHICLE_DETAILS_SUCCESS",
  ADD_NEW_VEHICLE_DETAILS_FAILED = "ADD_NEW_VEHICLE_DETAILS_FAILED",

  GET_LIVE_CHALLAN_LIST_REQUESTED = "GET_LIVE_CHALLAN_LIST_REQUESTED",
  GET_LIVE_CHALLAN_LIST_SUCCESS = "GET_LIVE_CHALLAN_LIST_SUCCESS",
  GET_LIVE_CHALLAN_LIST_FAILED = "GET_LIVE_CHALLAN_LIST_FAILED",

  GET_RENTALS_CHALLAN_LIST_REQUESTED = "GET_RENTALS_CHALLAN_LIST_REQUESTED",
  GET_RENTALS_CHALLAN_LIST_SUCCESS = "GET_RENTALS_CHALLAN_LIST_SUCCESS",
  GET_RENTALS_CHALLAN_LIST_FAILED = "GET_RENTALS_CHALLAN_LIST_FAILED",

  GET_ASSIGNED_IOT_DEVICES_REQUESTED = "GET_ASSIGNED_IOT_DEVICES_REQUESTED",
  GET_ASSIGNED_IOT_DEVICES_SUCCESS = "GET_ASSIGNED_IOT_DEVICES_SUCCESS",
  GET_ASSIGNED_IOT_DEVICES_FAILED = "GET_ASSIGNED_IOT_DEVICES_FAILED",

  RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED = "RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED",
  RENTALS_UNASSIGN_IOT_DEVICE_SUCCESS = "RENTALS_UNASSIGN_IOT_DEVICE_SUCCESS",
  RENTALS_UNASSIGN_IOT_DEVICE_FAILED = "RENTALS_UNASSIGN_IOT_DEVICE_FAILED",

  UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED = "UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED",
  UPDATE_APPROVE_REJECT_VEHICLE_SUCCESS = "UPDATE_APPROVE_REJECT_VEHICLE_SUCCESS",
  UPDATE_APPROVE_REJECT_VEHICLE_FAILED = "UPDATE_APPROVE_REJECT_VEHICLE_FAILED",

  LINK_CHALLANS_REQUESTED = "LINK_CHALLANS_REQUESTED",
  LINK_CHALLANS_SUCCESS = "LINK_CHALLANS_SUCCESS",
  LINK_CHALLANS_FAILED = "LINK_CHALLANS_FAILED",

  UNLINK_CHALLANS_REQUESTED = "UNLINK_CHALLANS_REQUESTED",
  UNLINK_CHALLANS_SUCCESS = "UNLINK_CHALLANS_SUCCESS",
  UNLINK_CHALLANS_FAILED = "UNLINK_CHALLANS_FAILED",

  GET_PING_HISTORY_REQUESTED = "GET_PING_HISTORY_REQUESTED",
  GET_PING_HISTORY_SUCCESS = "GET_PING_HISTORY_SUCCESS",
  GET_PING_HISTORY_FAILED = "GET_PING_HISTORY_FAILED",

  GET_IOT_DEVICES_REQUESTED = "GET_IOT_DEVICES_REQUESTED",
  GET_IOT_DEVICES_SUCCESS = "GET_IOT_DEVICES_SUCCESS",
  GET_IOT_DEVICES_FAILED = "GET_IOT_DEVICES_FAILED",

  ASSIGN_IOT_DEVICE_REQUESTED = "ASSIGN_IOT_DEVICE_REQUESTED",
  ASSIGN_IOT_DEVICE_SUCCESS = "ASSIGN_IOT_DEVICE_SUCCESS",
  ASSIGN_IOT_DEVICE_FAILED = "ASSIGN_IOT_DEVICE_FAILED",

  GET_ALL_TRANSFER_VEHICLE_REQUESTED = "GET_ALL_TRANSFER_VEHICLE_REQUESTED",
  GET_ALL_TRANFER_VEHICLE_SUCCESS = "GET_ALL_TRANFER_VEHICLE_SUCCESS",
  GET_ALL_TRANFER_VEHICLE_FAILED = "GET_ALL_TRANFER_VEHICLE_FAILED",

  GET_VEHICLE_TRANSFER_REQUEST_REQUESTED = "GET_VEHICLE_TRANSFER_REQUEST_REQUESTED",
  GET_VEHICLE_TRANSFER_REQUEST_SUCCESS = "GET_VEHICLE_TRANSFER_REQUEST_SUCCESS",
  GET_VEHICLE_TRANSFER_REQUEST_FAILED = "GET_VEHICLE_TRANSFER_REQUEST_FAILED",

  FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED = "FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED",
  FETCH_VEHICLE_MODEL_LIST_DATA_SUCCESS = "FETCH_VEHICLE_MODEL_LIST_DATA_SUCCESS",
  FETCH_VEHICLE_MODEL_LIST_DATA_FAILED = "FETCH_VEHICLE_MODEL_LIST_DATA_FAILED",

  TRANFER_VEHICLE_REQUEST_REQUESTED = "TRANSFER_VEHICLE_REQUESTED",
  TRANFER_VEHICLE_REQUEST_FAILED = "TRANSFER_VEHICLE_REQUEST_FAILED",
  TRANSFER_VEHICLE_REQUEST_SUCCESS = "TRANSFER_VEHICLE_REQUEST_SUCCESS",

  GET_VEHICLE_COLOURS_REQUESTED = "GET_VEHICLE_COLOURS_REQUESTED",
  GET_VEHICLE_COLOURS_SUCCESS = "GET_VEHICLE_COLOURS_SUCCESS",
  GET_VEHICLE_COLOURS_FAILED = "GET_VEHICLE_COLOURS_FAILED",

  GET_VEHICLE_DETAILS_BY_ID_REQUESTED = "GET_VEHICLE_DETAILS_BY_ID_REQUESTED",
  GET_VEHICLE_DETAILS_BY_ID_SUCCESS = "GET_VEHICLE_DETAILS_BY_ID_SUCCESS",
  GET_VEHICLE_DETAILS_BY_ID_FAILED = "GET_VEHICLE_DETAILS_BY_ID_FAILED",

  EDIT_VEHICLE_DETAILS_REQUESTED = "EDIT_VEHICLE_DETAILS_REQUESTED",
  EDIT_VEHICLE_DETAILS_SUCCESS = "EDIT_VEHICLE_DETAILS_SUCCESS",
  EDIT_VEHICLE_DETAILS_FAILED = "EDIT_VEHICLE_DETAILS_FAILED",

  DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED = "DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED",
  DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_SUCCESS = "DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_SUCCESS",
  DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_FAILED = "DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_FAILED",

  GET_SIGNED_URLS_VEHICLE_REQUESTED = "GET_SIGNED_URLS_VEHICLE_REQUESTED",
  GET_SIGNED_URLS_VEHICLE_SUCCESS = " GET_SIGNED_URLS_VEHICLE_SUCCESS",
  GET_SIGNED_URLS_VEHICLE_FAILED = " GET_SIGNED_URLS_VEHICLE_FAILED",

  POST_DOCUMENT_IN_S3_VEHICLE_REQUESTED = "POST_DOCUMENT_IN_S3_VEHICLE_REQUESTED",
  POST_DOCUMENT_IN_S3_VEHICLE_SUCCESS = "POST_DOCUMENT_IN_S3_VEHICLE",
  POST_DOCUMENT_IN_S3_VEHICLE_FAILED = "POST_DOCUMENT_IN_S3_VEHICLE_FAILED",

  PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED = "PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED",
  PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_SUCCESS = "PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_SUCCESS",
  PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_FAILED = "PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_FAILED",

  VEHICLE_DOCUMENT_UPLOAD_SUCCESS = "VEHICLE_DOCUMENT_UPLOAD_SUCCESS",
  VEHICLE_DOCUMENT_UPLOAD_FAILED = "VEHICLE_DOCUMENT_UPLOAD_FAILED",
  VEHICLE_DOCUMENT_UPLOAD_REQUESTED = "VEHICLE_DOCUMENT_UPLOAD_REQUESTED",

  DELETE_VEHICLE_REQUESTED = "DELETE_VEHICLE_REQUESTED",
  DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS",
  DELETE_VEHICLE_FAILED = "DELETE_VEHICLE_FAILED",

  GET_VEHICLE_DOCUMENT_REQUESTED = "GET_VEHICLE_DOCUMENT_REQUESTED",
  GET_VEHICLE_DOCUMENT_SUCCESS = "GET_VEHICLE_DOCUMENT_SUCCESS",
  GET_VEHICLE_DOCUMENT_FAILED = "GET_VEHICLE_DOCUMENT_FAILED",

  GET_VEHICLE_IMAGES_REQUESTED = "GET_VEHICLE_IMAGES_REQUESTED",
  GET_VEHICLE_IMAGES_SUCCESS = "GET_VEHICLE_IMAGES_SUCCESS",
  GET_VEHICLE_IMAGES_FAILED = "GET_VEHICLE_IMAGES_FAILED",

  GET_VEHICLE_END_RIDE_IMAGES_REQUESTED = "GET_VEHICLE_END_RIDE_IMAGES_REQUESTED",
  GET_VEHICLE_END_RIDE_IMAGES_SUCCESS = "GET_VEHICLE_END_RIDE_IMAGES_SUCCESS",
  GET_VEHICLE_END_RIDE_IMAGES_FAILED = "GET_VEHICLE_END_RIDE_IMAGES_FAILED",

  GET_VEHICLE_START_ODOMETER_READING_REQUESTED = "GET_VEHICLE_START_ODOMETER_READING_REQUESTED",
  GET_VEHICLE_START_ODOMETER_READING_SUCCESS = "GET_VEHICLE_START_ODOMETER_READING_SUCCESS",
  GET_VEHICLE_START_ODOMETER_READING_FAILED = "GET_VEHICLE_START_ODOMETER_READING_FAILED",

  GET_VEHICLE_END_ODOMETER_READING_REQUESTED = "GET_VEHICLE_END_ODOMETER_READING_REQUESTED",
  GET_VEHICLE_END_ODOMETER_READING_SUCCESS = "GET_VEHICLE_END_ODOMETER_READING_SUCCESS",
  GET_VEHICLE_END_ODOMETER_READING_FAILED = "GET_VEHICLE_END_ODOMETER_READING_FAILED",

  DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED = "DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED",
  DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_SUCCESS = "DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_SUCCESS",
  DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_FAILED = "DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_FAILED",

  UPLOAD_BULK_UPLOAD_SHEET_REQUESTED = "UPLOAD_BULK_UPLOAD_SHEET_REQUESTED",
  UPLOAD_BULK_UPLOAD_SHEET_SUCCESS = "UPLOAD_BULK_UPLOAD_SHEET_SUCCESS",
  UPLOAD_BULK_UPLOAD_SHEET_FAILED = "UPLOAD_BULK_UPLOAD_SHEET_FAILED",

  BULK_REQUEST_INITIATE_REQUESTED = "BULK_REQUEST_INITIATE_REQUESTED",
  BULK_REQUEST_INITIATE_SUCCESS = "BULK_REQUEST_INITIATE_SUCCESS",
  BULK_REQUEST_INITIATE_FAILED = "BULK_REQUEST_INITIATE_FAILED",

  CLEAR_ATTACHMENT_ID_BULK_UPLOAD = "CLEAR_ATTACHMENT_ID_BULK_UPLOAD",
  ADD_VEHICLE_DOCUMENTS_REQUESTED = "ADD_VEHICLE_DOCUMENTS_REQUESTED",
  ADD_VEHICLE_DOCUMENTS_SUCCESS = "ADD_VEHICLE_DOCUMENTS_SUCCESS",
  ADD_VEHICLE_DOCUMENTS_FAILED = "ADD_VEHICLE_DOCUMENTS_FAILED",

  DELETE_VEHICLE_DOCUMENTS_REQUESTED = "DELETE_VEHICLE_DOCUMENTS_REQUESTED",
  DELETE_VEHICLE_DOCUMENTS_SUCCESS = "DELETE_VEHICLE_DOCUMENTS_SUCCESS",
  DELETE_VEHICLE_DOCUMENTS_FAILED = "DELETE_VEHICLE_DOCUMENTS_FAILED",

  DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED = "DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED",
  DOWNLOAD_VEHICLE_DOCUMENTS_SUCCESS = "DOWNLOAD_VEHICLE_DOCUMENTS_SUCCESS",
  DOWNLOAD_VEHICLE_DOCUMENTS_FAILED = "DOWNLOAD_VEHICLE_DOCUMENTS_FAILED",

  GET_VEHICLE_STATS_REQUESTED = "GET_VEHICLE_STATS_REQUESTED",
  GET_VEHICLE_STATS_SUCCESS = "GET_VEHICLE_STATS_SUCCESS",
  GET_VEHICLE_STATS_FAILED = "GET_VEHICLE_STATS_FAILED",

  CLEAR_VEHICLE_ATTACHMENT_DATA = "CLEAR_VEHICLE_ATTACHMENT_DATA",
  REMOVE_ATTACHMENT_DATA = "REMOVE_ATTACHMENT_DATA",

  DELETE_DOC_UPLODED_SIGNED_URL = "DELETE_DOC_UPLODED_SIGNED_URL",

  CLEAR_VEHICLE_IMAGES_DATA = "CLEAR_VEHICLE_IMAGES_DATA",

  CLEAR_VEHICLE_REDUCER_KEY = "CLEAR_VEHICLE_REDUCER_KEY",

  CLEAR_IOT_SECTION = "CLEAR_IOT_SECTION",
}

export enum SURGE_ACTIONS {
  GET_VEHICLE_MODEL_LIST_BY_CITY_REQUESTED = "GET_VEHICLE_MODEL_LIST_BY_CITY_REQUESTED",
  GET_VEHICLE_MODEL_LIST_BY_CITY_SUCCESS = "GET_VEHICLE_MODEL_LIST_BY_CITY_SUCCESS",
  GET_VEHICLE_MODEL_LIST_BY_CITY_FAILED = "GET_VEHICLE_MODEL_LIST_BY_CITY_FAILED",

  CREATE_SURGE_REQUESTED = "CREATE_SURGE_REQUESTED",
  CREATE_SURGE_SUCCESS = "CREATE_SURGE_SUCCESS",
  CREATE_SURGE_FAILED = "CREATE_SURGE_FAILED",

  GET_SURGE_LIST_REQUESTED = "GET_SURGE_LIST_REQUESTED",
  GET_SURGE_LIST_SUCCESS = "GET_SURGE_LIST_SUCCESS",
  GET_SURGE_LIST_FAILED = "GET_SURGE_LIST_FAILED",

  UPDATE_SURGE_STATUS_REQUESTED = "UPDATE_SURGE_STATUS_REQUESTED",
  UPDATE_SURGE_STATUS_SUCCESS = "UPDATE_SURGE_STATUS_SUCCESS",
  UPDATE_SURGE_STATUS_FAILED = "UPDATE_SURGE_STATUS_FAILED",

  SURGE_MANAGEMENT_ERROR = "SURGE_MANAGEMENT_ERROR",
  SURGE_MANAGEMENT_FAIL = "SURGE_MANAGEMENT_FAIL",

  GET_SURGE_BY_ID_REQUESTED = "GET_SURGE_BY_ID_REQUESTED",
  GET_SURGE_BY_ID_SUCCESS = "GET_SURGE_BY_ID_SUCCESS",
  GET_SURGE_BY_ID_FAILED = "GET_SURGE_BY_ID_FAILED",

  UPDATE_SURGE_REQUESTED = "UPDATE_SURGE_REQUESTED",
  UPDATE_SURGE_SUCCESS = "UPDATE_SURGE_SUCCESS",
  UPDATE_SURGE_FAILED = "UPDATE_SURGE_FAILED",

  RESET_REDUCER_STATE = "RESET_REDUCER_STATE",
}

export enum BUSINESS_MANAGEMENT_ACTIONS {
  GET_CITY_OVERVIEW_REQUESTED = "GET_CITY_OVERVIEW_REQUESTED",
  GET_CITY_OVERVIEW_SUCCESS = "GET_CITY_OVERVIEW_SUCCESS",
  GET_CITY_OVERVIEW_FAILED = "GET_CITY_OVERVIEW_FAILED",

  GET_WAREHOUSE_OVERVIEW_REQUESTED = "GET_WAREHOUSE_OVERVIEW_REQUESTED",
  GET_WAREHOUSE_OVERVIEW_SUCCESS = "GET_WAREHOUSE_OVERVIEW_SUCCESS",
  GET_WAREHOUSE_OVERVIEW_FAILED = "GET_WAREHOUSE_OVERVIEW_FAILED",

  GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_REQUESTED = "GET_CITY_LIST_REQUESTED",
  GET_CITY_LIST_FAILED = "GET_CITY_LIST_FAILED",

  SAVE_NEW_CITY_SUCCESS = "SAVE_NEW_CITY_SUCCESS",
  SAVE_NEW_CITY_REQUESTED = "SAVE_NEW_CITY_REQUESTED",
  SAVE_NEW_CITY_FAILED = "SAVE_NEW_CITY_FAILED",

  UPDATE_CITY_REQUESTED = "UPDATE_CITY_REQUESTED",
  UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS",
  UPDATE_CITY_FAILED = "UPDATE_CITY_FAILED",

  GET_SIGNED_URL_REQUESTED = "GET_SIGNED_URL_REQUESTED",
  GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS",
  GET_SIGNED_URL_FAILED = "GET_SIGNED_URL_FAILED",

  IMAGE_UPLOADED_S3_SUCCESS = "IMAGE_UPLOADED_S3_SUCCESS",
  IMAGE_UPLOADED_S3_FAILED = "IMAGE_UPLOADED_S3_FAILED",

  RENTALS_ADD_POLYGON_REQUESTED = "RENTALS_ADD_POLYGON_REQUESTED",
  RENTALS_ADD_POLYGON_SUCCESS = "RENTALS_ADD_POLYGON_SUCCESS",
  RENTALS_ADD_POLYGON_FAILED = "RENTALS_ADD_POLYGON_FAILED",

  RENTALS_EDIT_POLYGON_REQUESTED = "RENTALS_EDIT_POLYGON_REQUESTED",
  RENTALS_EDIT_POLYGON_SUCCESS = "RENTALS_EDIT_POLYGON_SUCCESS",
  RENTALS_EDIT_POLYGON_FAILED = "RENTALS_EDIT_POLYGON_FAILED",

  RENTALS_DELETE_POLYGON_REQUESTED = "RENTALS_DELETE_POLYGON_REQUESTED",
  RENTALS_DELETE_POLYGON_SUCCESS = "RENTALS_DELETE_POLYGON_SUCCESS",
  RENTALS_DELETE_POLYGON_FAILED = "RENTALS_DELETE_POLYGON_FAILED",

  RENTALS_GET_POLYGONS_REQUESTED = "RENTALS_GET_POLYGONS_REQUESTED",
  RENTALS_GET_POLYGONS_SUCCESS = "RENTALS_GET_POLYGONS_SUCCESS",
  RENTALS_GET_POLYGONS_FAILED = "RENTALS_GET_POLYGONS_FAILED",

  RENTALS_ADD_LOCAL_POLYGON = "RENTALS_ADD_LOCAL_POLYGON",
  RENTALS_EDIT_LOCAL_POLYGON = "RENTALS_EDIT_LOCAL_POLYGON",
  RENTALS_DELETE_LOCAL_POLYGON = "RENTALS_DELETE_LOCAL_POLYGON",
  //warehouse
  GET_WAREHOUSE_LIST_REQUESTED = "GET_WAREHOUSE_LIST_REQUESTED",
  GET_WAREHOUSE_LIST_SUCCESS = "GET_WAREHOUSE_LIST_SUCCESS",
  GET_WAREHOUSE_LIST_FAILED = "GET_WAREHOUSE_LIST_FAILED",

  CREATE_WAREHOUSE_REQUESTED = "CREATE_WAREHOUSE_REQUESTED",
  CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS",
  CREATE_WAREHOUSE_FAILED = "CREATE_WAREHOUSE_FAILED",

  VIEW_WAREHOUSE_REQUESTED = "VIEW_WAREHOUSE_REQUESTED",
  VIEW_WAREHOUSE_SUCCESS = "VIEW_WAREHOUSE_SUCCESS",
  VIEW_WAREHOUSE_FAILED = "VIEW_WAREHOUSE_FAILED",

  UPDATE_BRANCH_REQUESTED = "UPDATE_BRANCH_REQUESTED",
  UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_FAILED = "UPDATE_BRANCH_FAILED",

  GET_CITY_DETAILS_REQUESTED = "GET_CITY_DETAILS_REQUESTED",
  GET_CITY_DETAILS_SUCCESS = "GET_CITY_DETAILS_SUCCESS",
  GET_CITY_DETAILS_FAILED = "GET_CITY_DETAILS_FAILED",

  CLEAR_CITY_DETAILS = "CLEAR_CITY_DETAILS",

  // Add New User - Role Management
  ADD_NEW_ROLE_USER_REQUESTED = "ADD_NEW_ROLE_USER_REQUESTED",
  ADD_NEW_ROLE_USER_SUCCESS = "ADD_NEW_ROLE_USER_SUCCESS",
  ADD_NEW_ROLE_USER_FAILED = "ADD_NEW_ROLE_USER_FAILED",

  // Get Roles List - Role Management
  GET_ROLES_LIST_REQUESTED = "GET_ROLES_LIST_REQUESTED",
  GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS",
  GET_ROLES_LIST_FAILED = "GET_ROLES_LIST_FAILED",

  // handleChange - Role Management
  HANDLE_CHANGE_REQUESTED = "HANDLE_CHANGE_REQUESTED",
  HANDLE_CHANGE_SUCCESS = "HANDLE_CHANGE_SUCCESS",
  HANDLE_CHANGE_FAILED = "HANDLE_CHANGE_FAILED",

  // Add New Role Row - Role Management
  ADD_NEW_ROLE_ROW_REQUESTED = "ADD_NEW_ROLE_ROW_REQUESTED",
  ADD_NEW_ROLE_ROW_SUCCESS = "ADD_NEW_ROLE_ROW_SUCCESS",
  ADD_NEW_ROLE_ROW_FAILED = "ADD_NEW_ROLE_ROW_FAILED",

  // Delete Role Row - Role Management
  DELETE_ROLE_ROW_REQUESTED = "DELETE_ROLE_ROW_REQUESTED",
  DELETE_ROLE_ROW_SUCCESS = "DELETE_ROLE_ROW_SUCCESS",
  DELETE_ROLE_ROW_FAILED = "DELETE_ROLE_ROW_FAILED",

  // Save Role Data - Role Management
  SAVE_ROLE_DATA_REQUESTED = "SAVE_ROLE_DATA_REQUESTED",
  SAVE_ROLE_DATA_SUCCESS = "SAVE_ROLE_DATA_SUCCESS",
  SAVE_ROLE_DATA_FAILED = "SAVE_ROLE_DATA_FAILED",

  // Fetch Role List - Role Management
  FETCH_ALL_USERS_LIST_REQUESTED = "FETCH_ALL_USERS_LIST_REQUESTED",
  FETCH_ALL_USERS_LIST_SUCCESS = "FETCH_ALL_USERS_LIST_SUCCESS",
  FETCH_ALL_USERS_LIST_FAILED = "FETCH_ALL_USERS_LIST_FAILED",

  //helmetConfiguration
  GET_HELMET_CONFIG_LIST_REQUESTED = "GET_LTR_HELMET_CONFIG_LIST_REQUESTED",
  GET_HELMET_CONFIG_LIST_SUCCESS = "GET_LTR_HELMET_CONFIG_LIST_SUCCESS",
  GET_HELMET_CONFIG_LIST_FAILED = "GET_LTR_HELMET_CONFIG_LIST_FAILED",

  ADD_HELMET_CONFIG_REQUESTED = "ADD_HELMET_CONFIG_REQUESTED",
  ADD_HELMET_CONFIG_SUCCESS = "ADD_HELMET_CONFIG_SUCCESS",
  ADD_HELMET_CONFIG_FAILED = "ADD_HELMET_CONFIG_FAILED",

  UPDATE_HELMET_CONFIG_REQUESTED = "UPDATE_HELMET_CONFIG_REQUESTED",
  UPDATE_HELMET_CONFIG_SUCCESS = "UPDATE_HELMET_CONFIG_SUCCESS",
  UPDATE_HELMET_CONFIG_FAILED = "UPDATE_HELMET_CONFIG_FAILED",

  GET_HELMET_CONFIG_REQUESTED = "GET_LTR_HELMET_CONFIG_REQUESTED",
  GET_HELMET_CONFIG_SUCCESS = "GET_LTR_HELMET_CONFIG_SUCCESS",
  GET_HELMET_CONFIG_FAILED = "GET_LTR_HELMET_CONFIG_FAILED",

  EDIT_CURRENT_HELMET_CONFIG = "EDIT_CURRENT_HELMET_CONFIG",

  // Fetch User Details by UserId - Role Management
  FETCH_USER_DETAILS_BY_USER_ID_REQUESTED = "FETCH_USER_DETAILS_BY_USER_ID_REQUESTED",
  FETCH_USER_DETAILS_BY_USER_ID_SUCCESS = "FETCH_USER_DETAILS_BY_USER_ID_SUCCESS",
  FETCH_USER_DETAILS_BY_USER_ID_FAILED = "FETCH_USER_DETAILS_BY_USER_ID_FAILED",

  // Block User - Role Management
  BLOCK_USER_REQUESTED = "BLOCK_USER_REQUESTED",
  BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILED = "BLOCK_USER_FAILED",

  // Unblock User - Role Management
  UNBLOCK_USER_REQUESTED = "UNBLOCK_USER_REQUESTED",
  UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS",
  UNBLOCK_USER_FAILED = "UNBLOCK_USER_FAILED",

  // Delete User - Role Management
  DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED = "DELETE_USER_FAILED",

  // Update User - Role Management
  UPDATE_USER_REQUESTED = "UPDATE_USER_REQUESTED",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED = "UPDATE_USER_FAILED",

  // Clear Data - Role Management
  CLEAR_DATA_REQUESTED = "CLEAR_DATA_REQUESTED",
  CLEAR_DATA_SUCCESS = "CLEAR_DATA_SUCCESS",
  CLEAR_DATA_FAILED = "CLEAR_DATA_FAILED",

  // Update Existing User Roles - Role Management
  UPDATE_EXISTING_USER_ROLES = "UPDATE_EXISTING_USER_ROLES",

  // Get Block Reasons - Role Management
  GET_BLOCK_REASONS_REQUESTED = "GET_BLOCK_REASONS_REQUESTED",
  GET_BLOCK_REASONS_SUCCESS = "GET_BLOCK_REASONS_SUCCESS",
  GET_BLOCK_REASONS_FAILED = "GET_BLOCK_REASONS_FAILED",
}

export enum CUSTOMER_MANAGEMENT_ACTIONS {
  REGISTER_CUSTOMER_REQUESTED = "REGISTER_CUSTOMER_REQUESTED",
  REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS",
  REGISTER_CUSTOMER_FAILED = "REGISTER_CUSTOMER_FAILED",

  VERIFY_CUSTOMER_OTP_REQUESTED = "VERIFY_CUSTOMER_OTP_REQUESTED",
  VERIFY_CUSTOMER_OTP_SUCCESS = "VERIFY_CUSTOMER_OTP_SUCCESS",
  VERIFY_CUSTOMER_OTP_FAILED = "VERIFY_CUSTOMER_OTP_FAILED",

  RESEND_CUSTOMER_OTP_REQUESTED = "RESEND_CUSTOMER_OTP_REQUESTED",
  RESEND_CUSTOMER_OTP_SUCCESS = "RESEND_CUSTOMER_OTP_SUCCESS",
  RESEND_CUSTOMER_OTP_FAILED = "RESEND_CUSTOMER_OTP_FAILED",

  RESET_INVALID_OTP_FLAG = "RESET_INVALID_OTP_FLAG",

  REVERT_IS_RESENT_FLAG_REQUESTED = "REVERT_IS_RESENT_FLAG_REQUESTED",

  SEARCH_CUSTOMER_DETAILS_REQUESTED = "SEARCH_CUSTOMER_DETAILS_REQUESTED",
  SEARCH_CUSTOMER_DETAILS_FAILED = "SEARCH_CUSTOMER_DETAILS_FAILED",
  SEARCH_CUSTOMER_DETAILS_SUCCESS = "SEARCH_CUSTOMER_DETAILS_SUCCESS",

  GET_CUSTOMER_DASHBOARD_STATS_REQUESTED = "GET_CUSTOMER_DASHBOARD_STATS_REQUESTED",
  GET_CUSTOMER_DASHBOARD_STATS_SUCCESS = "GET_CUSTOMER_DASHBOARD_STATS_SUCCESS",
  GET_CUSTOMER_DASHBOARD_STATS_FAILED = "GET_CUSTOMER_DASHBOARD_STATS_FAILED",

  GET_CUSTOMER_PROFILE_DATA_REQUESTED = "GET_CUSTOMER_PROFILE_DATA_REQUEST",
  GET_CUSTOMER_PROFILE_DATA_SUCCESS = "GET_CUSTOMER_PROFILE_DATA_SUCCESS",
  GET_CUSTOMER_PROFILE_DATA_FAILED = "GET_CUSTOMER_PROFILE_DATA_FAILED",

  GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED = "GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED",
  GET_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS = "GET_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS",
  GET_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED = "GET_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED",

  RESET_EMERGENCY_CONTACT_MODAL = "RESET_EMERGENCY_CONTACT_MODAL",

  GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED = "GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED",
  GET_CUSTOMER_KYC_TRUST_SCORE_SUCCESS = "GET_CUSTOMER_KYC_TRUST_SCORE_SUCCESS",
  GET_CUSTOMER_KYC_TRUST_SCORE_FAILED = "GET_CUSTOMER_KYC_TRUST_SCORE_FAILED",

  ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED = "ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED",
  ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS = "ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS",
  ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED = "ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED",

  BLOCK_CUSTOMER_REQUESTED = "BLOCK_CUSTOMER_REQUESTED",
  BLOCK_CUSTOMER_SUCCESS = "BLOCK_CUSTOMER_SUCCESS",
  BLOCK_CUSTOMER_FAILED = "BLOCK_CUSTOMER_FAILED",

  UNBLOCK_CUSTOMER_REQUESTED = "UNBLOCK_CUSTOMER_REQUESTED",
  UNBLOCK_CUSTOMER_SUCCESS = " UNBLOCK_CUSTOMER_SUCCESS",
  UNBLOCK_CUSTOMER_FAILED = " UNBLOCK_CUSTOMER_FAILED",

  DELETE_CUSTOMER_REQUESTED = "DELETE_CUSTOMER_REQUESTED",
  DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILED = "DELETE_CUSTOMER_FAILED",

  SEND_EMERGENCY_OTP_REQUESTED = "SEND_EMERGENCY_OTP_REQUESTED",
  SEND_EMERGENCY_OTP_SUCCESS = "SEND_EMERGENCY_OTP_SUCCESS",
  SEND_EMERGENCY_OTP_FAILED = "SEND_EMERGENCY_OTP_FAILED",

  VERIFY_EMERGENCY_OTP_REQUESTED = "VERIFY_EMERGENCY_OTP_REQUESTED",
  VERIFY_EMERGENCY_OTP_SUCCESS = "VERIFY_EMERGENCY_OTP_SUCCESS",
  VERIFY_EMERGENCY_OTP_FAILED = "VERIFY_EMERGENCY_OTP_FAILED",

  RESEND_EMERGENCY_OTP_REQUESTED = "RESEND_EMERGENCY_OTP_REQUESTED",
  RESEND_EMERGENCY_OTP_SUCCESS = "RESEND_EMERGENCY_OTP_SUCCESS",
  RESEND_EMERGENCY_OTP_FAILED = "RESEND_EMERGENCY_OTP_FAILED",
  GET_CUSTOMER_BOOKING_STATS_REQUESTED = "GET_CUSTOMER_BOOKING_STATS_REQUESTED",
  GET_CUSTOMER_BOOKING_STATS_SUCCESS = "GET_CUSTOMER_BOOKING_STATS_SUCCESS",
  GET_CUSTOMER_BOOKING_STATS_FAILED = "GET_CUSTOMER_BOOKING_STATS_FAILED",

  GET_CUSTOMER_BOOKING_LIST_REQUESTED = "GET_CUSTOMER_BOOKING_LIST_REQUESTED",
  GET_CUSTOMER_BOOKING_LIST_SUCCESS = "GET_CUSTOMER_BOOKING_LIST_SUCCESS",
  GET_CUSTOMER_BOOKING_LIST_FAILED = "GET_CUSTOMER_BOOKING_LIST_FAILED",

  RESET_EMERGENCY_OTP_FLAG_REQUESTED = "RESET_EMERGENCY_OTP_FLAG_REQUESTED",
  RESET_EMERGENCY_DETAILS_REQUESTED = "RESET_EMERGENCY_DETAILS_REQUESTED",

  GET_INIDIVIDUAL_KYC_DETAILS_REQUESTED = "GET_INIDIVIDUAL_KYC_DETAILS_REQUESTED",
  GET_INIDIVIDUAL_KYC_DETAILS_SUCCESS = "GET_INIDIVIDUAL_KYC_DETAILS_SUCCESS",
  GET_INIDIVIDUAL_KYC_DETAILS_FAILED = "GET_INIDIVIDUAL_KYC_DETAILS_FAILED",

  CLEAR_INIDIVIDUAL_KYC_DETAILS = "CLEAR_INIDIVIDUAL_KYC_DETAILS",
  RESET_KYC_DETAILS_PAYLOAD_OBJECT = "RESET_KYC_DETAILS_PAYLOAD_OBJECT",

  /* ---------- KYC Actions ------------------ */
  GET_KYC_SIGNED_URLS_REQUESTED = "GET_KYC_SIGNED_URLS_REQUESTED",
  GET_KYC_SIGNED_URLS_SUCCESS = "GET_KYC_SIGNED_URLS_SUCCESS",
  GET_KYC_SIGNED_URLS_FAILED = "GET_KYC_SIGNED_URLS_FAILED",

  PUT_KYC_ATTACHMENT_ON_SIGNED_URL_REQUESTED = "PUT_KYC_ATTACHMENT_ON_SIGNED_URL_REQUESTED",
  PUT_KYC_ATTACHMENT_ON_SIGNED_URL_SUCCESS = "PUT_KYC_ATTACHMENT_ON_SIGNED_URL_SUCCESS",
  PUT_KYC_ATTACHMENT_ON_SIGNED_URL_FAILED = "PUT_KYC_ATTACHMENT_ON_SIGNED_URL_FAILED",

  POST_KYC_DOCUMENT_IN_S3_REQUESTED = "POST_KYC_DOCUMENT_IN_S3_REQUESTED",
  POST_KYC_DOCUMENT_IN_S3_SUCCESS = "POST_KYC_DOCUMENT_IN_S3_SUCCESS",
  POST_KYC_DOCUMENT_IN_S3_FAILED = "POST_KYC_DOCUMENT_IN_S3_FAILED",

  KYC_DOCUMENT_UPLOAD_SUCCESS = "KYC_DOCUMENT_UPLOAD_SUCCESS",
  KYC_DOCUMENT_UPLOAD_FAILED = "KYC_DOCUMENT_UPLOAD_FAILED",
  KYC_DOCUMENT_UPLOAD_REQUESTED = "KYC_DOCUMENT_UPLOAD_REQUESTED",

  CLEAR_KYC_DOCUMENT_UPLOAD_REDUX = "CLEAR_KYC_DOCUMENT_UPLOAD_REDUX",

  RESET_SELFIE_ATTACHMENT_ID = "RESET_SELFIE_ATTACHMENT_ID",
  RESET_DOC_UPLOAD_SUCCESS_FLAG = "RESET_DOC_UPLOAD_SUCCESS_FLAG",
  RESET_OTHER_DOCS_ATTACHMENT_ID = "RESET_OTHER_DOCS_ATTACHMENT_ID",

  MANUALLY_VERIFY_KYC_REQUESTED = "MANUALLY_VERIFY_KYC_REQUESTED",
  MANUALLY_VERIFY_KYC_SUCCESS = "MANUALLY_VERIFY_KYC_SUCCESS",
  MANUALLY_VERIFY_KYC_FAILED = "MANUALLY_VERIFY_KYC_FAILED",

  DELETE_CUSTOMER_KYC_REQUESTED = "DELETE_CUSTOMER_KYC_REQUESTED",
  DELETE_CUSTOMER_KYC_SUCCESS = "DELETE_CUSTOMER_KYC_SUCCESS",
  DELETE_CUSTOMER_KYC_FAILED = "DELETE_CUSTOMER_KYC_FAILED",

  UNVERIFY_CUSTOMER_KYC_REQUESTED = "UNVERIFY_CUSTOMER_KYC_REQUESTED",
  UNVERIFY_CUSTOMER_KYC_SUCCESS = "UNVERIFY_CUSTOMER_KYC_SUCCESS",
  UNVERIFY_CUSTOMER_KYC_FAILED = "UNVERIFY_CUSTOMER_KYC_FAILED",

  DOWNLOAD_CUSTOMER_BOOKINGS_LIST_REQUESTED = "DOWNLOAD_CUSTOMER_BOOKINGS_LIST_REQUESTED",
  DOWNLOAD_CUSTOMER_BOOKINGS_LIST_SUCCESS = "DOWNLOAD_CUSTOMER_BOOKINGS_LIST_SUCCESS",
  DOWNLOAD_CUSTOMER_BOOKINGS_LIST_FAILED = "DOWNLOAD_CUSTOMER_BOOKINGS_LIST_FAILED",
}

export enum RENTALS_IOT_MANAGEMENT_ACTION {
  RENTALS_ADD_IOT_DEVICE_REQUESTED = "RENTALS_ADD_IOT_DEVICE_REQUESTED",
  RENTALS_ADD_IOT_DEVICE_SUCCEEDED = "RENTALS_ADD_IOT_DEVICE_SUCCEEDED",
  RENTALS_ADD_IOT_DEVICE_FAILED = "RENTALS_ADD_IOT_DEVICE_FAILED",

  RENTALS_GET_ALL_IOT_REQUESTED = "RENTALS_GET_ALL_IOT_REQUESTED",
  RENTALS_GET_ALL_IOT_SUCCESS = "RENTALS_GET_ALL_IOT_SUCCESS",
  RENTALS_GET_ALL_IOT_FAILED = "RENTALS_GET_ALL_IOT_FAILED",
  RENTALS_CLEAR_ALL_IOT_LIST = "RENTALS_CLEAR_ALL_IOT_LIST",

  RENTALS_GET_ALL_IOT_VENDORS_REQUESTED = "RENTALS_GET_ALL_IOT_VENDORS_REQUESTED",
  RENTALS_GET_ALL_IOT_VENDORS_SUCCESS = "RENTALS_GET_ALL_IOT_VENDORS_SUCCESS",
  RENTALS_GET_ALL_IOT_VENDORS_FAILED = "RENTALS_GET_ALL_IOT_VENDORS_FAILED",

  RENTALS_GET_IOT_DEVICE_DETAILS_REQUESTED = "RENTALS_GET_IOT_DEVICE_DETAILS_REQUESTED",
  RENTALS_GET_IOT_DEVICE_DETAILS_SUCCESS = "RENTALS_GET_IOT_DEVICE_DETAILS_SUCCESS",
  RENTALS_GET_IOT_DEVICE_DETAILS_FAILED = "RENTALS_GET_IOT_DEVICE_DETAILS_FAILED",

  RENTALS_DEVICE_PING_REQUESTED = "RENTALS_DEVICE_PING_REQUESTED",
  RENTALS_DEVICE_PING_SUCCESS = "RENTALS_DEVICE_PING_SUCCESS",
  RENTALS_DEVICE_PING_FAILED = "RENTALS_DEVICE_PING_FAILED",
  RENTALS_CLEAR_DEVICE_PING = "RENTALS_CLEAR_DEVICE_PING",

  RENTALS_DEVICE_PING_HISTORY_REQUESTED = "RENTALS_DEVICE_PING_HISTORY_REQUESTED",
  RENTALS_DEVICE_PING_HISTORY_SUCCESS = "RENTALS_DEVICE_PING_HISTORY_SUCCESS",
  RENTALS_DEVICE_PING_HISTORY_FAILED = "RENTALS_DEVICE_PING_HISTORY_FAILED",

  RENTALS_UPADTE_IOT_DETAILS_REQUESTED = "RENTALS_UPADTE_IOT_DETAILS_REQUESTED",
  RENTALS_UPADTE_IOT_DETAILS_SUCCESS = "RENTALS_UPADTE_IOT_DETAILS_SUCCESS",
  RENTALS_UPADTE_IOT_DETAILS_FAILED = "RENTALS_UPADTE_IOT_DETAILS_FAILED",

  RENTALS_IOT_BULK_UPLOAD_REQUESTED = "RENTALS_IOT_BULK_UPLOAD_REQUESTED",
  RENTALS_IOT_BULK_UPLOAD_SUCCESS = "RENTALS_IOT_BULK_UPLOAD_SUCCESS",
  RENTALS_IOT_BULK_UPLOAD_FAILED = "RENTALS_IOT_BULK_UPLOAD_FAILED",
  RENTALS_IOT_BULK_UPLOAD_RESET = "RENTALS_IOT_BULK_UPLOAD_RESET",

  RENTALS_GET_IOT_UPLOAD_FORMAT_REQUESTED = "RENTALS_GET_IOT_UPLOAD_FORMAT_REQUESTED",
  RENTALS_GET_IOT_UPLOAD_FORMAT_SUCCESS = "RENTALS_GET_IOT_UPLOAD_FORMAT_SUCCESS",
  RENTALS_GET_IOT_UPLOAD_FORMAT_FAILED = "RENTALS_GET_IOT_UPLOAD_FORMAT_FAILED",

  RENTALS_GET_IOT_ODOMETER_DETAILS_REQUESTED = "RENTALS_GET_IOT_ODOMETER_DETAILS_REQUESTED",
  RENTALS_GET_IOT_ODOMETER_DETAILS_SUCCESS = "RENTALS_GET_IOT_ODOMETER_DETAILS_SUCCESS",
  RENTALS_GET_IOT_ODOMETER_DETAILS_FAILED = "RENTALS_GET_IOT_ODOMETER_DETAILS_FAILED",
}

export enum BOOKING_MANAGEMENT_ACTIONS {
  BOOKING_MANAGEMENT_FAILED = "BOOKING_MANAGEMENT_FAILED",
  BOOKING_MANAGEMENT_ERROR = "BOOKING_MANAGEMENT_ERROR",

  RENTALS_GET_BOOKING_DETAILS_REQUESTED = "RENTALS_GET_BOOKING_DETAILS_REQUESTED",
  RENTALS_GET_BOOKING_DETAILS_SUCCESS = "RENTALS_GET_BOOKING_DETAILS_SUCCESS",
  RENTALS_GET_BOOKING_DETAILS_FAILED = "RENTALS_GET_BOOKING_DETAILS_FAILED",

  GET_BOOKING_DETAILS_REQUESTED = "GET_LTR_BOOKING_DETAILS_REQUESTED",
  GET_BOOKING_DETAILS_SUCCESS = "GET_LTR_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_FAILED = "GET_LTR_BOOKING_DETAILS_FAILED",

  GET_VEHICLE_ODOMETER_HISTORY_REQUESTED = "GET_LTR_VEHICLE_ODOMETER_HISTORY_REQUESTED",
  GET_VEHICLE_ODOMETER_HISTORY_SUCCESS = "GET_LTR_VEHICLE_ODOMETER_HISTORY_SUCCESS",
  GET_VEHICLE_ODOMETER_HISTORY_FAILED = "GET_LTR_VEHICLE_ODOMETER_HISTORY_FAILED",

  GET_BOOKING_STATS_REQUESTED = "GET_BOOKING_STATS_REQUESTED",
  GET_BOOKING_STATS_SUCCESS = "GET_BOOKING_STATS_SUCCESS",
  GET_BOOKING_STATS_FAILED = "GET_BOOKING_STATS_FAILED",

  GET_BOOKING_DOC_SIGNED_URLS_REQUESTED = "GET_LTR_BOOKING_DOC_SIGNED_URLS_REQUESTED",
  GET_BOOKING_DOC_SIGNED_URLS_SUCCESS = "GET_LTR_BOOKING_DOC_SIGNED_URLS_SUCCESS",
  GET_BOOKING_DOC_SIGNED_URLS_FAILED = "GET_LTR_BOOKING_DOC_SIGNED_URLS_FAILED",

  PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_REQUESTED = "PUT_LTR_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_REQUESTED",
  PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_SUCCESS = "PUT_LTR_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_SUCCESS",
  PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_FAILED = "PUT_LTR_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_FAILED",

  BOOKING_DOCUMENT_UPLOAD_REQUESTED = "LTR_BOOKING_DOCUMENT_UPLOAD_REQUESTED",
  BOOKING_DOCUMENT_UPLOAD_SUCCESS = "LTR_BOOKING_DOCUMENT_UPLOAD_SUCCESS",
  BOOKING_DOCUMENT_UPLOAD_FAILED = "LTR_BOOKING_DOCUMENT_UPLOAD_FAILED",

  GET_BOOKING_DOCUMENTS_REQUESTED = "GET_LTR_BOOKING_DOCUMENTS_REQUESTED",
  GET_BOOKING_DOCUMENTS_SUCCESS = "GET_LTR_BOOKING_DOCUMENTS_SUCCESS",
  GET_BOOKING_DOCUMENTS_FAILED = "GET_LTR_BOOKING_DOCUMENTS_FAILED",

  DELETE_BOOKING_DOCUMENTS_REQUESTED = "DELETE_LTR_BOOKING_DOCUMENTS_REQUESTED",
  DELETE_BOOKING_DOCUMENTS_FAILED = "DELETE_LTR_BOOKING_DOCUMENTS_FAILED",
  DELETE_BOOKING_DOCUMENTS_SUCCESS = "DELETE_LTR_BOOKING_DOCUMENTS_SUCCESS",

  CANCEL_BOOKING_REQUESTED = "CANCEL_LTR_BOOKING_REQUESTED",
  CANCEL_BOOKING_SUCCESS = "CANCEL_LTR_BOOKING_SUCCESS",
  CANCEL_BOOKING_FAILED = "CANCEL_LTR_BOOKING_FAILED",

  GET_BOOKING_LIST_REQUESTED = "GET_BOOKING_LIST_REQUESTED",
  GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS",
  GET_BOOKING_LIST_FAILED = "GET_BOOKING_LIST_FAILED",

  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED",
  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS",
  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED",

  GET_ADDITIONAL_CHARGES_REQUESTED = "GET_ADDITIONAL_CHARGES_REQUESTED",
  GET_ADDITIONAL_CHARGES_SUCCESS = "GET_ADDITIONAL_CHARGES_SUCCESS",
  GET_ADDITIONAL_CHARGES_FAILED = "GET_ADDITIONAL_CHARGES_FAILED",
  GET_VEHICLE_SIGNED_URLS_REQUESTED = "GET_LTR_VEHICLE_SIGNED_URLS_REQUESTED",
  GET_VEHICLE_SIGNED_URLS_SUCCESS = "GET_LTR_VEHICLE_SIGNED_URLS_SUCCESS",
  GET_VEHICLE_SIGNED_URLS_FAILED = "GET_LTR_VEHICLE_SIGNED_URLS_FAILED",

  PUT_VEHICLE_IMAGES_ON_SIGNED_URLS_REQUESTED = "PUT_LTR_VEHICLE_IMAGES_ON_SIGNED_URLS_REQUESTED",
  PUT_VEHICLE_IMAGES_ON_SIGNED_URLS_SUCCESS = "PUT_LTR_VEHICLE_IMAGES_ON_SIGNED_URLS_SUCCESS",
  PUT_VEHICLE_IMAGES_ON_SIGNED_URLS_FAILED = "PUT_LTR_VEHICLE_IMAGES_ON_SIGNED_URLS_FAILED",

  VEHICLE_IMAGES_UPLOAD_REQUESRED = "LTR_VEHICLE_IMAGES_UPLOAD_REQUESTED",
  VEHICLE_IMAGES_UPLOAD_SUCCESS = "LTR_VEHICLE_IMAGES_UPLOAD_SUCCESS",
  VEHICLE_IMAGES_UPLOAD_FAILED = "LTR_VEHICLE_IMAGES_UPLOAD_FAILED",

  GET_BOOKING_VEHICLE_IMAGES_REQUESTED = "GET_LTR_VEHICLE_IMAGES_REQUESTED",
  GET_BOOKING_VEHICLE_IMAGES_SUCCESS = "GET_LTR_BOOKING_VEHICLE_IMAGES_SUCCESS",
  GET_BOOKING_VEHICLE_IMAGES_FAILED = "GET_LTR_BOOKING_VEHICLE_IMAGES_FAILED",

  GET_BOOKING_LIST_USING_PHONE_REQUESTED = "GET_BOOKING_LIST_USING_PHONE_REQUESTED",
  GET_BOOKING_LIST_USING_PHONE_SUCCESS = "GET_BOOKING_LIST_USING_PHONE_SUCCESS",
  GET_BOOKING_LIST_USING_PHONE_FAILED = "GET_BOOKING_LIST_USING_PHONE_FAILED",

  GET_PLAN_ADDON_DETAILS_REQUESTED = "GET_PLAN_ADDON_DETAILS_REQUESTED",
  GET_PLAN_ADDON_DETAILS_SUCCESS = "GET_PLAN_ADDON_DETAILS_SUCCESS",
  GET_PLAN_ADDON_DETAILS_FAILED = "GET_PLAN_ADDON_DETAILS_FAILED",

  GET_MODIFICATION_HISTORY_REQUESTED = "GET_MODIFICATION_HISTORY_REQUESTED",
  GET_MODIFICATION_HISTORY_SUCCESS = "GET_MODIFICATION_HISTORY_SUCCESS",
  GET_MODIFICATION_HISTORY_FAILED = "GET_MODIFICATION_HISTORY_FAILED",

  FETCH_AVAILABLE_VEHICLE_LIST_REQUESTED = "FETCH_AVAILABLE_VEHICLE_LIST_REQUESTED",
  FETCH_AVAILABLE_VEHICLE_LIST_SUCCESS = "FETCH_AVAILABLE_VEHICLE_LIST_SUCCESS",
  FETCH_AVAILABLE_VEHICLE_LIST_FAILED = "FETCH_AVAILABLE_VEHICLE_LIST_FAILED",

  ASSIGN_VEHICLE_REQUESTED = "ASSIGN_VEHICLE_REQUESTED",
  ASSIGN_VEHICLE_SUCCESS = "ASSIGN_VEHICLE_SUCCESS",
  ASSIGN_VEHICLE_FAILED = "ASSIGN_VEHICLE_FAILED",

  CHECK_START_RIDE_DELAY_REQUESTED = "CHECK_START_RIDE_DELAY_REQUESTED",
  CHECK_START_RIDE_DELAY_SUCCESS = "CHECK_START_RIDE_DELAY_SUCCESS",
  CHECK_START_RIDE_DELAY_FAILED = "CHECK_START_RIDE_DELAY_FAILED",

  START_RIDE_REQUESTED = "START_RIDE_LTR_REQUESTED",
  START_RIDE_SUCCESS = "START_RIDE_LTR_SUCCESS",
  START_RIDE_FAILED = "START_RIDE_LTR_FAILED",

  SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_REQUESTED = "SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_REQUESTED",
  SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_SUCCESS = "SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_SUCCESS",
  SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_FAILED = "SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_FAILED",

  CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED = "CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED",
  CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS = "CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS",
  CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_FAILED = "CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_FAILED",

  GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED = "GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED",
  GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_SUCCESS = "GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_SUCCESS",
  GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_FAILED = "GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_FAILED",

  CLEANUP_REVAMP_ORDER_AND_PAYMENT_DATA = "CLEANUP_REVAMP_ORDER_AND_PAYMENT_DATA",

  WAIVE_OFF_BOOKING_CHARGES_REQUESTED = "WAIVE_OFF_BOOKING_CHARGES_REQUESTED",
  WAIVE_OFF_BOOKING_CHARGES_SUCCESS = "WAIVE_OFF_BOOKING_CHARGES_SUCCESS",
  WAIVE_OFF_BOOKING_CHARGES_FAILED = "WAIVE_OFF_BOOKING_CHARGES_FAILED",

  GET_WAIVE_OFF_REASONS_LIST_REVAMP_REQUESTED = "GET_WAIVE_OFF_REASONS_LIST_REVAMP_REQUESTED",
  GET_WAIVE_OFF_REASONS_LIST_REVAMP_SUCCESS = "GET_WAIVE_OFF_REASONS_LIST_REVAMP_SUCCESS",
  GET_WAIVE_OFF_REASONS_LIST_REVAMP_FAILED = "GET_WAIVE_OFF_REASONS_LIST_REVAMP_FAILED",
  GET_PAYMENTS_OVERVIEW_LIST_REQUESTED = "GET_PAYMENTS_OVERVIEW_LIST_REQUESTED",
  GET_PAYMENTS_OVERVIEW_LIST_SUCCESS = "GET_PAYMENTS_OVERVIEW_LIST_SUCCESS",
  GET_PAYMENTS_OVERVIEW_LIST_FAILED = "GET_PAYMENTS_OVERVIEW_LIST_FAILED",

  GET_EXTENSION_AVAILABILITY_DETAILS_REQUESTED = "GET_EXTENSION_AVAILABILITY_DETAILS_REQUESTED",
  GET_EXTENSION_AVAILABILITY_DETAILS_SUCCESS = "GET_EXTENSION_AVAILABILITY_DETAILS_SUCCESS",
  GET_EXTENSION_AVAILABILITY_DETAILS_FAILED = "GET_EXTENSION_AVAILABILITY_DETAILS_FAILED",

  GET_PENDING_CHARGES_REQUESTED = "GET_PENDING_CHARGES_REQUESTED",
  GET_PENDING_CHARGES_SUCCESS = "GET_PENDING_CHARGES_SUCCESS",
  GET_PENDING_CHARGES_FAILED = "GET_PENDING_CHARGES_FAILED",

  END_RIDE_REQUESTED = "END_RIDE_REQUESTED",
  END_RIDE_SUCCESS = "END_RIDE_SUCCESS",
  END_RIDE_FAILED = "END_RIDE_FAILED",

  COMPLETE_RIDE_REQUESTED = "COMPLETE_RIDE_REQUESTED",
  COMPLETE_RIDE_SUCCESS = "COMPLETE_RIDE_SUCCESS",
  COMPLETE_RIDE_FAILED = "COMPLETE_RIDE_FAILED",

  COMPLETE_RIDE_PAYMENT_REQUESTED = "COMPLETE_RIDE_PAYMENT_REQUESTED",
  COMPLETE_RIDE_PAYMENT_SUCCESS = "COMPLETE_RIDE_PAYMENT_SUCCESS",
  COMPLETE_RIDE_PAYMENT_FAILED = "COMPLETE_RIDE_PAYMENT_FAILED",

  CLEAR_ATTACHMENT_ID_IMG_UPLOAD = "CLEAR_ATTACHMENT_ID_IMG_UPLOAD",
  CLEAR_BOOKING_REDUCER_DATA = "CLEAR_BOOKING_REDUCER_DATA",

  GET_EXTENSION_PLANS_DETAILS_REQUESTED = "GET_EXTENSION_PLANS_DETAILS_REQUESTED",
  GET_EXTENSION_PLANS_DETAILS_SUCCESS = "GET_EXTENSION_PLANS_DETAILS_SUCCESS",
  GET_EXTENSION_PLANS_DETAILS_FAILED = "GET_EXTENSION_PLANS_DETAILS_FAILED",

  GET_CALCULATED_EXTENSION_CHARGES_REQUESTED = "GET_CALCULATED_EXTENSION_CHARGES_REQUESTED",
  GET_CALCULATED_EXTENSION_CHARGES_SUCCESS = "GET_CALCULATED_EXTENSION_CHARGES_SUCCESS",
  GET_CALCULATED_EXTENSION_CHARGES_FAILED = "GET_CALCULATED_EXTENSION_CHARGES_FAILED",

  COUPON_APPLY_FAILED_EXTENSION = "COUPON_APPLY_FAILED_EXTENSION",

  SET_PAYMENT_SUCCESS_LTR = "SET_PAYMENT_SUCCESS_LTR",

  GET_RECENT_MODIFICATION_HISTORY_REQUESTED = "GET_RECENT_MODIFICATION_HISTORY_REQUESTED",
  GET_RECENT_MODIFICATION_HISTORY_SUCCESS = "GET_RECENT_MODIFICATION_HISTORY_SUCCESS",
  GET_RECENT_MODIFICATION_HISTORY_FAILED = "GET_RECENT_MODIFICATION_HISTORY_FAILED",

  CREATE_BOOKING_EXTENSION_REQUESTED = "CREATE_BOOKING_EXTENSION_REQUESTED",
  CREATE_BOOKING_EXTENSION_SUCCESS = "CREATE_BOOKING_EXTENSION_SUCCESS",
  CREATE_BOOKING_EXTENSION_FAILED = "CREATE_BOOKING_EXTENSION_FAILED",
  GET_VEHICLE_EXTENSION_REQUESTED = "GET_VEHICLE_EXTENSION_REQUESTED",
  GET_VEHICLE_EXTENSION_SUCCESS = "GET_VEHICLE_EXTENSION_SUCCESS",
  GET_VEHICLE_EXTENSION_FAILED = "GET_VEHICLE_EXTENSION_FAILED",

  // modifyBooking Actions
  CALCULATE_ADDRESS_CHARGES_REQUESTED = "CALCULATE_ADDRESS_CHARGES_REQUESTED",
  CALCULATE_ADDRESS_CHARGES_SUCCESS = "CALCULATE_ADDRESS_CHARGES_SUCCESS",
  CALCULATE_ADDRESS_CHARGES_FAILED = "CALCULATE_ADDRESS_CHARGES_FAILED",
  CLEAR_MODIFY_CHARGES = "CLEAR_MODIFY_CHARGES",

  CALCULATE_MODEL_MODIFICATION_CHARGES_REQUESTED = "CALCULATE_MODEL_MODIFICATION_CHARGES_REQUESTED",
  CALCULATE_MODEL_MODIFICATION_CHARGES_SUCCESS = "CALCULATE_MODEL_MODIFICATION_CHARGES_SUCCESS",
  CALCULATE_MODEL_MODIFICATION_CHARGES_FAILED = "CALCULATE_MODEL_MODIFICATION_CHARGES_FAILED",

  CALCULATE_PLAN_MODIFICATION_REQUESTED = "CALCULATE_PLAN_MODIFICATION_REQUESTED",
  CALCULATE_PLAN_MODIFICATION_SUCCESS = "CALCULATE_PLAN_MODIFICATION_SUCCESS",
  CALCULATE_PLAN_MODIFICATION_FAILED = "CALCULATE_PLAN_MODIFICATION_FAILED",

  MODIFY_BOOKING_PLAN_REQUESTED = "MODIFY_BOOKING_PLAN_REQUESTED",
  MODIFY_BOOKING_PLAN_SUCCESS = "MODIFY_BOOKING_PLAN_SUCCESS",
  MODIFY_BOOKING_PLAN_FAILED = "MODIFY_BOOKING_PLAN_FAILED",

  MODIFY_ADDRESS_ADDON_REQUESTED = "MODIFY_ADDRESS_ADDON_REQUESTED",
  MODIFY_ADDRESS_ADDON_SUCCESS = "MODIFY_ADDRESS_ADDON_SUCCESS",
  MODIFY_ADDRESS_ADDON_FAILED = "MODIFY_ADDRESS_ADDON_FAILED",

  MODIFY_BOOKING_MODEL_REQUESTED = "MODIFY_BOOKING_MODEL_REQUESTED",
  MODIFY_BOOKING_MODEL_SUCCESS = "MODIFY_BOOKING_MODEL_SUCCESS",
  MODIFY_BOOKING_MODEL_FAILED = "MODIFY_BOOKING_MODEL_FAILED",

  AVIALABLE_BOOKING_MODEL_REQUESTED = "AVIALABLE_BOOKING_MODEL_REQUESTED",
  AVIALABLE_BOOKING_MODEL_SUCCESS = "AVIALABLE_BOOKING_MODEL_SUCCESS",
  AVIALABLE_BOOKING_MODEL_FAILED = "AVIALABLE_MODEL_FAILED",

  GET_MODIFICATION_REASONS_REQUESTED = "GET_MODIFICATION_REASONS_REQUESTED",
  GET_MODIFICATION_REASONS_SUCCESS = "GET_MODIFICATION_REASONS_SUCCESS",
  GET_MODIFICATION_REASONS_FAILED = "GET_MODIFICATION_REASONS_FAILED",

  CLEAR_MODIFICATION_DATA = "CLEAR_MODIFICATION_DATA",

  UNASSIGN_VEHICLE_REQUESTED = "UNASSIGN_VEHICLE_REQUESTED",
  UNASSIGN_VEHICLE_SUCCESS = "UNASSIGN_VEHICLE_SUCCESS",
  UNASSIGN_VEHICLE_FAILED = "UNASSIGN_VEHICLE_FAILED",

  DELETE_BOOKING_EXTENSION_REQUESTED = "DELETE_BOOKING_EXTENSION_REQUESTED",
  DELETE_BOOKING_EXTENSION_SUCCESS = "DELETE_BOOKING_EXTENSION_SUCCESS",
  DELETE_BOOKING_EXTENSION_FAILED = "DELETE_BOOKING_EXTENSION_FAILED",

  // booking stats v3 for counts in dashboard quick filters
  GET_CUSTOMER_BOOKINGS_STATS_V3_REQUESTED = "GET_CUSTOMER_BOOKINGS_STATS_V3_REQUESTED",
  GET_CUSTOMER_BOOKINGS_STATS_V3_SUCCESS = "GET_CUSTOMER_BOOKINGS_STATS_V3_SUCCESS",
  GET_CUSTOMER_BOOKINGS_STATS_V3_FAILED = "GET_CUSTOMER_BOOKINGS_STATS_V3_FAILED",

  SET_PENDING_CHARGES_BOOKINGS_START_RIDE = "SET_PENDING_CHARGES_BOOKINGS_START_RIDE",

  DELETE_BOOKING_MODIFICATION_REQUESTED = "DELETE_BOOKING_MODIFICATION_REQUESTED",
  DELETE_BOOKING_MODIFICATION_SUCCESS = "DELETE_BOOKING_MODIFICATION_SUCCESS",
  DELETE_BOOKING_MODIFICATION_FAILED = "DELETE_BOOKING_MODIFICATION_FAILED",
}

export enum PAYMENT_MANAGEMENT_ACTIONS {
  GET_PAYMENT_LIST_REQUESTED = "GET_PAYMENT_LIST_REQUESTED",
  GET_PAYMENT_LIST_SUCCESS = "GET_PAYMENT_LIST_SUCCESS",
  GET_PAYMENT_LIST_FAILED = "GET_PAYMENT_LIST_FAILED",

  GET_PAYMENT_LIST_USING_PHONE_NUMBER_REQUESTED = "GET_PAYMENT_LIST_USING_PHONE_NUMBER_REQUESTED",
  GET_PAYMENT_LIST_USING_PHONE_NUMBER_SUCCESS = "GET_PAYMENT_LIST_USING_PHONE_NUMBER_SUCCESS",
  GET_PAYMENT_LIST_USING_PHONE_NUMBER_FAILED = "GET_PAYMENT_LIST_USING_PHONE_NUMBER_FAILED",

  GET_REFUND_DETAILS_REQUESTED = "GET_REFUND_DETAILS_REQUEST",
  GET_REFUND_DETAILS_SUCCESS = "GET_REFUND_DETAILS_SUCCESS",
  GET_REFUND_DETAILS_FAILED = "GET_REFUND_DETAILS_FAILED",
}

export enum REPORT_ACTIONS {
  GENERTE_REPORT_REQUESTED = "GENERTE_REPORT_REQUESTED",
  GENERATE_REPOST_SUCCESS = "GENERATE_REPOST_SUCCESS",
  GENERATE_PAYMENT_FAILED = "GENERATE_PAYMENT_FAILED",
}

export enum OPERATOR_MANAGEMENT_ACTIONS {
  GET_OPERATOR_LEAD_LIST_REQUESTED = "GET_OPERATOR_LEAD_LIST_REQUESTED",
  GET_OPERATOR_LEAD_LIST_SUCCESS = "GET_OPERATOR_LEAD_LIST_SUCCESS",
  GET_OPERATOR_LEAD_LIST_FAILED = "GET_OPERATOR_LEAD_LIST_FAILED",

  GET_OPERATOR_LEAD_DETAILS_REQUESTED = "GET_OPERATOR_LEAD_DETAILS_REQUESTED",
  GET_OPERATOR_LEAD_DETAILS_SUCCESS = "GET_OPERATOR_LEAD_DETAILS_SUCCESS",
  GET_OPERATOR_LEAD_DETAILS_FAILED = "GET_OPERATOR_LEAD_DETAILS_FAILED",

  SAVE_OPERATOR_LEAD_DETAILS_REQUESTED = "SAVE_OPERATOR_LEAD_DETAILS_REQUESTED",
  SAVE_OPERATOR_LEAD_DETAILS_SUCCESS = "SAVE_OPERATOR_LEAD_DETAILS_SUCCESS",
  SAVE_OPERATOR_LEAD_DETAILS_FAILED = "SAVE_OPERATOR_LEAD_DETAILS_FAILED",

  INITIATE_ESIGN_OPERATOR_REQUESTED = "INITIATE_ESIGN_OPERATOR_REQUESTED",
  INITIATE_ESIGN_OPERATOR_SUCCESS = "INITIATE_ESIGN_OPERATOR_SUCCESS",
  INITIATE_ESIGN_OPERATOR_FAILED = "INITIATE_ESIGN_OPERATOR_FAILED",

  VERIFY_EMAIL_OPERATOR_REQUESTED = "VERIFY_EMAIL_OPERATOR_REQUESTED",
  VERIFY_EMAIL_OPERATOR_SUCCESS = "VERIFY_EMAIL_OPERATOR_SUCCESS",
  VERIFY_EMAIL_OPERATOR_FAILED = "VERIFY_EMAIL_OPERATOR_FAILED",

  APPROVE_LEAD_OPERATOR_REQUESTED = "APPROVE_LEAD_OPERATOR_REQUESTED",
  APPROVE_LEAD_OPERATOR_SUCCESS = "APPROVE_LEAD_OPERATOR_SUCCESS",
  APPROVE_LEAD_OPERATOR_FAILED = "APPROVE_LEAD_OPERATOR_FAILED",

  GET_OPEARATOR_LIST_REQUESTED = "GET_OPEARATOR_LIST_REQUESTED",
  GET_OPEARATOR_LIST_SUCCESS = "GET_OPEARATOR_LIST_SUCCESS",
  GET_OPEARATOR_LIST_FAILED = "GET_OPEARATOR_LIST_FAILED",

  GET_OPERATOR_DETAILS_DETAILS_REQUESTED = "GET_OPERATOR_DETAILS_DETAILS_REQUESTED",
  GET_OPERATOR_DETAILS_DETAILS_SUCCESS = "GET_OPERATOR_DETAILS_DETAILS_SUCCESS",
  GET_OPERATOR_DETAILS_DETAILS_FAILED = "GET_OPERATOR_DETAILS_DETAILS_FAILED",

  CLEAR_OPERATOR_DETAILS = "CLEAR_OPERATOR_DETAILS",

  APPROVE_REJECT_OPERATOR_DOCUMENTS_REQUESTED = "APPROVE_REJECT_OPERATOR_DOCUMENTS_REQUESTED",
  APPROVE_REJECT_OPERATOR_DOCUMENTS_SUCCESS = "APPROVE_REJECT_OPERATOR_DOCUMENTS_SUCCESS",
  APPROVE_REJECT_OPERATOR_DOCUMENTS_FAILED = "APPROVE_OPERATOR_DOCUMENTS_FAILED",

  SAVE_OPERATOR_DOCUMENTS_REQUESTED = "SAVE_OPERATOR_DOCUMENTS_REQUESTED",
  SAVE_OPERATOR_DOCUMENTS_SUCCESS = "SAVE_OPERATOR_DOCUMENTS_SUCCESS",
  SAVE_OPERATOR_DOCUMENTS_FAILED = "SAVE_OPERATOR_DOCUMENTS_FAILED",

  SUBMIT_OPERATOR_DOCUMENTS_REQUESTED = "SUBMIT_OPERATOR_DOCUMENTS_REQUESTED",
  SUBMIT_OPERATOR_DOCUMENTS_SUCCESS = "SUBMIT_OPERATOR_DOCUMENTS_SUCCESS",
  SUBMIT_OPERATOR_DOCUMENTS_FAILED = "SUBMIT_OPERATOR_DOCUMENTS_FAILED",

  MODIFY_OPERATOR_CONFIG_REQUESTED = "MODIFY_OPERATOR_CONFIG_REQUESTED",
  MODIFY_OPERATOR_CONFIG_SUCCESS = "MODIFY_OPERATOR_CONFIG_SUCCESS",
  MODIFY_OPERATOR_CONFIG_FAILED = "MODIFY_OPERATOR_CONFIG_FAILED",

  GET_VIEW_AGREEMENT_REQUESTED = "GET_VIEW_AGREEMENT_REQUESTED",
  GET_VIEW_AGREEMENT_SUCCESS = "GET_VIEW_AGREEMENT_SUCCESS",
  GET_VIEW_AGREEMENT_FAILED = "GET_VIEW_AGREEMENT_FAILED",
}
