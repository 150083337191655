// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import dayjs from "dayjs";
import moment from "moment";
import en from "../../locale/en.json";
import {
  BookingStatusEnum,
  GenericObject,
  OrderPaymentType,
} from "../constants/constants";
import { MITC_FORM_URL } from "../../config";
import rentalsEn from "../locale/rental-en.json";
const ExcelJS = require("exceljs");
import { saveAs } from "file-saver";
import { getFilterDataFromLocalStorage } from "../../config/filterStorage";

const { errorMessages } = en;
const { bookingDetails, manualBooking } = rentalsEn;
// Each breakpoint (a key) matches with a fixed screen width (a value):
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

export const REGEX = {
  alfa: /^[a-zA-Z ]*$/,
  alphaNumeric: /^\s*[a-zA-Z0-9]+\s*$/,
  alphaNumericAndWhitespace: /^[a-zA-Z0-9\-\s]+$/,
  alphaAndWhitespace: /^[a-zA-Z\-\s]+$/,
  strictAlphaNumeric: /^[a-zA-Z0-9]*$/,
  phone: new RegExp("^[6-9]([0-9]*)$"),
  numeric: /^[0-9]*$/,
  numericTruthyUptoTwoDecimal: /^\d+(\.\d{1,2})?$/, // Regular expression for strict numerical values greater than or equal to 1, upto two decimal places.
  float: /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/,
  pin: /^[0-9]*$/,
  strictAlphaNumericWithUnderscore: /^[a-zA-Z0-9_]*$/,
  ifscCode: /^[A-Za-z]{4}\d{7}$/,
  panNumber: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  gstNumber: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
};

// convert snake_case to Title Case
export const snakeCaseToTitleCase = (str: string): string => {
  str = str.toLocaleLowerCase();
  return str.replace(/^_*(.)|_+(.)/g, (str, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
};

// Validate strict numerical values greater than or equal to 1, upto two decimal places
export const isNumericTruthyUptoTwoDecimal = (val: any): boolean => {
  if (REGEX?.numericTruthyUptoTwoDecimal.test(val)) {
    return true;
  } else {
    return false;
  }
};

// Validate an alphanumeric string
export const isAlphaNumericString = (val: any): boolean => {
  if (REGEX?.alphaNumeric.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const isAlphabetWithSpacesString = (val: any): boolean => {
  if (REGEX?.alphaAndWhitespace.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const isAlphaNumericWithSpacesString = (val: any): boolean => {
  if (REGEX?.alphaNumericAndWhitespace.test(val)) {
    return true;
  } else {
    return false;
  }
};

// remove multiple spaces from a string
export const removeTrailingSpaces = (str: string) => {
  if (!str) return "";
  return str.replace(/\s+/g, " ");
};

// Validate an numeric string
export const isNumericString = (val: any): boolean => {
  if (REGEX?.numeric.test(val)) {
    return true;
  } else {
    return false;
  }
};

// Validate an numeric string
export const isValidPan = (val: any): boolean => {
  if (REGEX?.panNumber.test(val)) {
    return true;
  } else {
    return false;
  }
};

// Validate an numeric string
export const isValidGSTNo = (val: any): boolean => {
  if (REGEX?.gstNumber.test(val)) {
    return true;
  } else {
    return false;
  }
};

/* export const getInitials = (fullName: string = "") => {
  if (!fullName) return "";
  fullName = fullName.trim();
  let nameArr = fullName.split(" ");
  let i = 0;
  let initialChars = "";
  for (i; i < nameArr.length; i++) {
    initialChars += nameArr[i][0];
  }
  return initialChars;
};
*/
export const getFullName = (first: string = "", last: string = "") => {
  let fullName = `${first ? first : " "}` + " " + `${last ? last : " "}`;
  fullName = fullName.trim();
  return fullName;
};

// Return the first two initials
export const getInitials = (name) => {
  const nameParts = name.split(" ").filter(Boolean);
  const initials = nameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials.slice(0, 2);
};

export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const getGenderLabel = (genderId: Number) => {
  if (!genderId) {
    return "";
  }
  switch (genderId) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Others";
    default:
      return "";
  }
};

// Get Gender Label from ENUM
export const getGenderLabelByEnum = (gender: string) => {
  if (!gender) return "";

  switch (gender) {
    case "MALE":
      return "Male";
    case "FEMALE":
      return "Female";
    case "OTHERS":
      return "Others";
    default:
      return "";
  }
};

export const validateStatus: valiadate = {
  required: "The field is required",
  validateRegEx: "Invalid Email Address",
  minLength: "minLength",
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: validateStatus.required };
  else if (!emailRegex.test(email))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const validate = (
  fields: any = {},
  errorFields: object = {},
  optionalField: array[string] = [],
  validateOnly: array[string] = []
) => {
  const validateFields = Object.keys(fields);
  let obj = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined || optionalField.includes(each)) return;

    if (typeof fields[each] === "string" || typeof fields[each] === "number") {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.includes("firstName"):
            if (fields[each]?.length < 3) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.includes("lastName"):
            if (fields[each]?.length < 3) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("firstName"):
            if (fields[each]?.length < 2) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("lastName"):
            if (fields[each]?.length < 2) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;
              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;

              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("imei"):
            if (fields[each]?.length < 15) {
              error = true;

              obj[each] = en.errorMessages.imeiError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("secoundary") ||
            each?.toLocaleLowerCase()?.includes("phone") ||
            each?.toLocaleLowerCase()?.includes("mobileNumber"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;

          default:
            break;
        }
      }
    } else if (typeof fields[each] === "object") {
      // @ts-ignore: Unreachable code error
      if (!Object.keys(fields[each]).length) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }
      if (fields[each].name == "") {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

export const getFormattedINR = (amount: number) => {
  let formattedAmount = "";
  amount = amountConvertToRupee(amount);
  // Format with up to 2 decimal places
  formattedAmount = amount.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
  // Remove unnecessary  zeros
  /* if (formattedAmount.includes(".")) {
    formattedAmount = formattedAmount.replace(/(\.0+|(?<=\.\d)0+)$/, "");
  } */
  if (formattedAmount.includes(".")) {
    formattedAmount = formattedAmount.replace(/\.0+$/, ""); // Remove if only zeros after decimal
  }
  return formattedAmount;
};

export const amountConvertToRupee = (num: number) => {
  let result = 0;
  if (num > 0) {
    result = num / 100;
  }
  return result;
};

export const amountConvertToPaise = (num: number) => {
  let result = 0;
  if (num > 0) {
    result = num * 100;
  }
  return result;
};

export const dateFormat = (date: any, dateTimeDisplay: boolean = false) => {
  if (!date) return;
  const dayjs = require("dayjs");
  const modifiedDate = dayjs(date);
  const formattedDate = dateTimeDisplay
    ? modifiedDate.format("DD/MM/YYYY hh:mm")
    : modifiedDate.format("DD/MM/YYYY");
  return formattedDate;
};

export const hoursToDays = (totalHours: number) => {
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  if (days == 0) return `${hours} hour(s)`;
  else if (hours == 0) return `${days} day(s)`;
  else return `${days} day(s) and ${hours} hour(s)`;
};

export const getArraySum = (arr: any = []) => {
  let sum = 0;
  if (arr.length > 0) {
    sum = arr.reduce((partialSum, a) => partialSum + a, 0);
  }
  return sum;
};

// pass the fields you want to validate
export const validateOnly = (
  fields: any = {},
  errorFields: object = {},
  validateOnly: any = []
) => {
  const validateFields =
    validateOnly?.length > 0 ? validateOnly : Object?.keys(fields);
  let obj = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined) return;

    if (typeof fields[each] === "string" || typeof fields[each] === "number") {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;

              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;

              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("imei"):
            if (fields[each]?.length < 15) {
              error = true;

              obj[each] = en.errorMessages.imeiError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("secoundary") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;

          default:
            break;
        }
      }
    } else if (typeof fields[each] === "object") {
      let fieldArr = Object.keys(fields[each]);
      // @ts-ignore: Unreachable code error
      if (!Object.keys(fields[each]).length) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }

      if (fieldArr.includes("name") && !fields[each].name) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

// converting Date to Timestamp
export function dateToTimestamp(date: any) {
  if (!date) return 0;
  const dayjs = require("dayjs");
  const timestamp = dayjs(date, "DD/MM/YYYY").valueOf();
  return timestamp;
}

// Date String to Timestamp
export const convertToTimeStamp = (date: string): number => {
  let timeStamp = new Date().getTime();
  if (date) {
    timeStamp = new Date(`${date}`).getTime();
  }
  return timeStamp;
};

// IST to Timestamp
export const istToTimestamp = (date: any) => {
  if (!date) return;
  let timeStamp = new Date().getTime();
  if (date) {
    timeStamp = new Date(date).getTime();
  }
  return timeStamp;
};

// IST to DD/MM/YYYY
export const istToNormalDate = (date: any) => {
  // the input date is in IST timezone
  const normalDate = dayjs(date).format("DD/MM/YYYY");
  return normalDate;
};

//convert seriel date format of excel sheet to dd-mm-yyyy
export const serialDateToDD_MM_YYYY = (serialDate: any) => {
  const epoch = new Date(1899, 11, 30);
  const date = new Date(epoch.getTime() + serialDate * 24 * 60 * 60 * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// convert seconds to 00:00 time format
export const formatSecondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds >= 10 ? remainingSeconds : "0" + remainingSeconds
  }`;
};

// appending ", " after each element of the given array
export const appendCommaSpace = (data: any) => {
  return data?.map((item: any) => `${item}`).join(", ");
};

// Function to convert the given string to pascal case (capitalizing the first letter of each word)
export const toPascalCase = (str: string) => {
  // This function converts the input string to lowercase using toLowerCase(), then uses a regular expression /\b\w/g to match each word boundary (\b) followed by a word character (\w). The replace() method is used to replace each matched character with its uppercase equivalent using the toUpperCase() function.
  return str?.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

// Diffrentiate slots into Morning,Evening and Afternoon
export const categorizeSlots = (slots: any) => {
  const categorized = {
    morning: [],
    afternoon: [],
    evening: [],
  };
  if (!slots) return categorized;
  slots?.forEach((slot: any) => {
    const startHour = parseInt(slot.start.split(":")[0]);
    if (startHour >= 0 && startHour < 12) {
      categorized.morning.push(slot);
    } else if (startHour >= 12 && startHour < 17) {
      categorized.afternoon.push(slot);
    } else if (startHour >= 17 && startHour < 24) {
      categorized.evening.push(slot);
    }
  });
  return categorized;
};

// return morning/afternoon/evening on the basis of slot (start: hour, end:hour)
export const checkDaySlotFromTimeSlot = (slot) => {
  if (!slot) return "";
  const startHour = parseInt(slot.start.split(":")[0]);
  if (startHour >= 0 && startHour < 12) {
    return "Morning";
  } else if (startHour >= 12 && startHour < 17) {
    return "Afternoon";
  } else if (startHour >= 17 && startHour < 24) {
    return "Evening";
  }
};

// Format Time Slot
export const formatTime = (time) => {
  if (!time) return "";
  const [hour, minute] = time.split(":").map(Number);
  const ampm = hour >= 12 ? "pm" : "am";
  const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
  return `${formattedHour}:${minute < 10 ? `0${minute}` : minute} ${ampm}`;
};

export const convertTo12hrs = (time: any) => {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const timeToDateObject = (timeString: any) => {
  const time = new Date();
  let timeParts = timeString.split(":");
  time.setHours(timeParts[0]);
  time.setMinutes(timeParts[1]);
  return time;
};

// Reset the data to initial state
// Fields are our current Fields , exceptions are the value which we dont want to Reset
// useCase:  setFields((prev: any) => resetFieldsExcept(prev, initialState, ["expectionState","expectionState"]));
export const resetFieldsExcept = (
  fields: any,
  initialState: any,
  exceptions: string[]
) => {
  const newFields = { ...fields };
  for (const key in initialState) {
    if (!exceptions.includes(key)) {
      newFields[key] = initialState[key];
    }
  }
  return newFields;
};

/**
 * Constructs a URL with query parameters from the given payload object.
 * Each key in the payload object represents a query parameter name, and its corresponding value is the parameter value.
 * @url The base URL to which query parameters will be appended.
 * @payload An object where keys represent query parameter names and values represent parameter values.
 * @returns The constructed URL with properly encoded query parameters.
 */
// Generic function to construct URL with query parameters
export const constructUrl = (url: string, payload: any): string => {
  let apiUrl: string = `${url}`;
  let queryParams: string[] = [];

  // Loop through the payload object and construct query parameters
  for (const [key, value] of Object.entries(payload)) {
    if (value) {
      if (Array.isArray(value)) {
        // Handle array values by formatting them correctly
        const formattedArray = value.map((item) => JSON.stringify(item));
        queryParams.push(`${key}=[${formattedArray.join(", ")}]`);
      } else {
        // Handle other types of values (i.e., string, number, boolean)
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }

  // Join query parameters with '&' and append to apiUrl if there are any
  if (queryParams.length > 0) {
    apiUrl += `?${queryParams.join("&")}`;
  }

  return apiUrl;
};

// get timestamp of start of the day // i.e., Tue Jun 04 2024 00:00:00 GMT+0530 (India Standard Time)
export const getTimestampAtStartOfDay = (dateVal: any): string => {
  return moment(dateFormat(dateVal), "DD/MM/YYYY").startOf("day").valueOf();
};

// get timestamp of end of the day // i.e, Tue Jun 04 2024 23:59:59 GMT+0530 (India Standard Time)
export const getTimestampAtEndOfDay = (dateVal: any): string => {
  return moment(dateFormat(dateVal), "DD/MM/YYYY").endOf("day").valueOf();
};

export const getDateTimeFromTimeStamp = (timestamp: any, type: string) => {
  let resultDate = "N/A";
  let lastLoggedIn;
  if (timestamp) {
    lastLoggedIn = new Date(Number(timestamp));
    if (lastLoggedIn) {
      let dateOfMonth = lastLoggedIn.getDate();
      dateOfMonth =
        dateOfMonth < 10
          ? dateOfMonth.toString().padStart(2, "0")
          : dateOfMonth;
      let month = lastLoggedIn.getMonth() + 1;
      month = month < 10 ? month.toString().padStart(2, "0") : month;
      let fullYear = lastLoggedIn.getFullYear();
      let hours = (lastLoggedIn.getHours() + 24) % 12 || 12;
      let minutes =
        (lastLoggedIn.getMinutes() < 10 ? "0" : "") + lastLoggedIn.getMinutes();
      let seconds =
        (lastLoggedIn.getSeconds() < 10 ? "0" : "") + lastLoggedIn.getSeconds();
      let timeFormat = lastLoggedIn.getHours() >= 12 ? "PM" : "AM";

      if (type === "date") {
        resultDate = `${dateOfMonth}/${month}/${fullYear}`;
      }
      if (type === "time") {
        resultDate = `${hours}:${minutes}:${seconds} ${timeFormat}`;
      }
      if (type === "dateTime") {
        resultDate = `${dateOfMonth}/${month}/${fullYear} ${hours}:${minutes} ${timeFormat}`;
      }
      if (type === "hours") {
        resultDate = `${hours}:${minutes} ${timeFormat}`;
      }
    }
  }
  return resultDate;
};

export const encrypt = (data: any): any => {
  let string = JSON.stringify(data || "");
  let encrypted = AES.encrypt(string, COUPON_CODE_WORKING_KEY).toString();
  return encrypted;
};

export const decrypt = (encrypted) => {
  const CryptoJS = require("crypto-js");
  const decryptFunction = function (encText, workingKey) {
    let decrypted = CryptoJS.AES.decrypt(encText, workingKey);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  };
  return decryptFunction(encrypted, COUPON_CODE_WORKING_KEY);
};
// calculate future date from the give date ( fromTo : pass date , no of days : pass the number of days from date)
export const calculateFutureDate = (fromTo: any, noOfDays: any) =>
  new Date(moment(fromTo).add(noOfDays, "days").toDate());

// check if the image exists
export const checkIfImageExists = (url: string) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    return true;
  } else {
    img.onload = () => {
      return true;
    };
    img.onerror = () => {
      return false;
    };
  }
};

// function to convert amount coming from backend to display amount i.e paisa to rupee
export function paisaToRupee(amount: number): number {
  if (!amount) return "";
  // we are multiplying amount to 100 to pinto rupee
  return Number(parseFloat((Number(amount) / 100).toString()).toFixed(2));
}
// function to convert amount coming from Display to backend amount i.e  rupee to paisa
export function rupeeToPaisa(amount: number): number {
  if (!amount) return "";
  // we are multiplying amount to 100 to convert into paisa
  return Number(amount) * 100;
}

// return hour/minute/second ago if time is less than an hour else return in DD/MM/YYYY format
export const fromNowDateAndTime = (value) => {
  let date = moment(value).fromNow();

  if (
    !date.includes("hours") &&
    !date.includes("seconds") &&
    !date.includes("minutes")
  ) {
    date = moment(value).format("DD/MM/YYYY");
  }
  return date;
};

/**
 * Checks if a value is valid number
 * @param {number} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is neither null nor undefined; otherwise, returns false.
 */
export const isValidNumber = (value: number): boolean => {
  if (!value) return false;
  return !isNaN(value);
};

// pass timestamp and no of days want to cal
export const CalDaysWithTimeStamp = (timestamp: any, days) => {
  const date = new Date(timestamp);
  date.setDate(date.getDate() + days);
  return date.getTime();
};

// generate random number
export const generateRandomString = (length, chars) => {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

/**
 * Checks if the given data object has a status property equal to 200 or 201.
 * @param data The data object to check. Should confirm to the Data interface.
 * @returns true if data is defined and has status equal to 200 or 201, false otherwise.
 */
export const isApiStatusSuccess = <T extends GenericObject>(
  data: T | undefined | null
): boolean => !!data && (data?.status === 200 || data?.status === 201);

/**
 * Flattens a nested object into a flat object where keys are concatenated using dot notation.
 * @param obj The object to flatten.
 * @param parentKey Optional prefix to prepend to keys.
 * @returns The flattened object.
 */
export const flattenObject = (
  obj: GenericObject,
  parentKey: string = ""
): GenericObject => {
  let flattened: GenericObject = {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = parentKey ? `${parentKey}.${key}` : key;

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        // Recursively flatten nested objects
        Object.assign(flattened, flattenObject(obj[key], propName));
      } else {
        // Assign non-object values directly
        flattened[propName] = obj[key];
      }
    }
  }

  return flattened;
};

// helper to check excel file format
export const isExcelExtention = (fileType: string) => {
  let allowedExtention = ["xlsx", "xls"];
  if (allowedExtention.includes(fileType)) {
    return true;
  } else {
    return false;
  }
};

/** Validate file or doc 
 * @param {string[]} acceptFile - acceptable file formats array of string
 * @param {number} maxSize - acceptable file size in bytes
 * @param {any} file - actual file which has been selected 

*/
export const validateFile = (
  file,
  acceptFile: string[],
  maxSize: number
): any => {
  if (!file || file === undefined) return { error: true };

  const size = file.size;
  const type = file.type;

  return acceptFile.includes(type) && size <= maxSize
    ? { error: false }
    : {
        error: true,
        reason: `Please select file less than ${
          maxSize / 1000000
        } MB and file should be as per acceptable file format`,
      };
};

export const formatString = (input: string): string => {
  // Convert the input to lowercase to handle case insensitivity
  const lowerCaseInput = input?.toLowerCase();

  // Replace underscores with spaces
  const spacedInput = lowerCaseInput?.replace(/_/g, " ");

  // Capitalize the first letter of each word
  const formattedString = spacedInput?.replace(/\b\w/g, (char) =>
    char?.toUpperCase()
  );

  return formattedString;
};

//convert bytes to megabytes
export const convertBytesToMB = (value: number) => {
  const MBValue = value / 1000000;
  return MBValue;
};

//convert bytes to megabytes
export const convertBytesToKB = (value: number) => {
  const KBValue = value / 10000;
  return KBValue.toFixed(2);
};

//from string to DD-MM-YYYY format
export const formatISODateToddmmyyyy = (isoDateString: any) => {
  const date = new Date(isoDateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

// generate vehicle image src
export const generateVehicleSrc = (imagePath = "", images: any[] = []) => {
  if (!imagePath) return "";
  const bikeImageInstance = images[0];
  return `${MITC_FORM_URL}/${imagePath}/${bikeImageInstance.type}-${bikeImageInstance.fileName}`;
};

// helper to get booking type
export const getBookingType = (
  unlimited: boolean = false,
  premium: boolean = false
): string => {
  if (!unlimited && !premium) {
    return bookingDetails.regular;
  } else if (unlimited && !premium) {
    return bookingDetails.unlimited;
  } else if (!unlimited && premium) {
    return bookingDetails.premium;
  }
};

// helper to generate and download excel from json data
// 1. columns -> array of objects containing [{header : "name", key : "username"}]
// header -> column name
// key -> used to pick data from rows for tha column

// 2. rows -> row data in form json

// 3. worksheetName -> name of the worksheet

// 4. fileName -> name of the file
export interface DownloadExcelSheetColumns {
  header: string;
  key: string;
  width?: number;
}

export const downloadExcelSheet = (
  columns: DownloadExcelSheetColumns[],
  rows: GenericObject[],
  worksheetName: string,
  fileName: string
) => {
  // Create a new Excel workbook instance
  const workBook = new ExcelJS.Workbook();

  // Add a worksheet named "VehicleDetails" to the workbook
  const workSheet = workBook?.addWorksheet(worksheetName);

  // Define columns for the worksheet
  workSheet.columns = columns;

  // Add rows of vehicle data to the worksheet
  workSheet?.addRows(rows);

  // Define columns for the worksheet with column widths
  workSheet.columns = columns.map((column) => ({
    ...column,
    width: column.width || 20,
  }));

  // Define header style
  const headerStyle = {
    font: { bold: true, color: { argb: "FF000000" } },
    alignment: { horizontal: "center" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD9EAD3" },
    },
  };
  // Apply header style to each header cell
  const headerRow = workSheet.getRow(1);
  headerRow.eachCell({ includeEmpty: true }, (cell) => {
    cell.style = headerStyle;
  });

  // Write the workbook to a buffer and then create a Blob from the buffer
  workBook.xlsx.writeBuffer().then((buffer: any) =>
    // Save the Blob as a file named "VehicleManagementReport.xlsx"
    saveAs(new Blob([buffer]), `${fileName}.xlsx`)
  );
};

// get booking bike delivery type need to pass booking object from api
export const getDeliveryPickup = (booking: GenericObject): string => {
  const userDeliveryType = booking.deliveryDetails.type;

  const deliveryType: GenericObject = {
    onPickup: {
      BRANCH: "Hub Pickup",
      CUSTOMER_LOCATION: "Home Delivery",
    },
    onDelivery: {
      BRANCH: "Hub dropoff",
      CUSTOMER_LOCATION: "Home Pickup",
    },
  };

  if (
    booking.status === BookingStatusEnum.PENDING ||
    booking.status === BookingStatusEnum.UPCOMING ||
    booking.status === BookingStatusEnum.ONGOING
  ) {
    return deliveryType?.onPickup[userDeliveryType] || "";
  } else {
    return deliveryType.onDelivery[userDeliveryType] || "";
  }
};

//convert hex color to rgba
export function hexToRgb(hex) {
  // Remove the '#' character if present
  hex = hex.replace("#", "");

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r},${g},${b}`;
}

//get payment type button text for payments
export const getPaymentTypeText = (paymentType: String) => {
  if (paymentType === OrderPaymentType.PAYMENT_LINK) {
    return manualBooking.GeneratePaymentLink;
  }
  if (paymentType === OrderPaymentType.QR_CODE) {
    return manualBooking.GenerateQRCode;
  }
  if (paymentType === OrderPaymentType.CASH) {
    return "Collect Cash";
  }
  return manualBooking.SelectPaymentMethod;
};

// helper to calculate date and time for ride start date time and end date time and it returns timestamp
export const combineDateAndTimeSlot = (date: number, slot: string): Date => {
  const startDate = new Date(date);
  const year = startDate.getFullYear();
  const month = startDate.getMonth();
  const day = startDate.getDate();
  const [hours, minutes] = slot?.split(":").map(Number);
  const combinedDate = new Date(year, month, day, hours, minutes);
  return combinedDate.getTime();
};

// Extracts the initials from a given name by taking the first letter of first & last word.
export const getNameInitials = (name: string): string => {
  const nameParts = name.split(" ").filter(Boolean);
  const tempNameParts = [nameParts[0], nameParts[nameParts?.length - 1]];
  const initials = tempNameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials;
};

// get  data from local storage
export const getLocalData = async (localStorageKey: string) => {
  const data = await getFilterDataFromLocalStorage(localStorageKey);
  return data || {};
};

// Task Management -> sort task sequence by priority (array of objects on basis of property)
export const getSortedArrayOfObjects = (a, b) => {
  if (a.priority < b.priority) {
    return -1;
  }
  if (a.priority > b.priority) {
    return 1;
  }
  return 0;
};

//restrict zero to enter in input feild
export const restrictZeroInput = (value: string | number) =>
  value.toString().replace(/^0+/, "");

// remove undefined , null and empty values from JSON
export const filterJson = (object): GenericObject => {
  if (!object) return {};
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      filterJson(v);
    }
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v === ""
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};

// check if object has empty/null/undefined values in it
export const hasObjectEmptyValues = (value) => {
  if (typeof value === "object" && value !== null) {
    for (const v of Object.values(value)) {
      if (hasObjectEmptyValues(v)) return true;
    }
  }

  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    // Also these lines aren't needed because Object.keys() always returns an array
    // Object.keys(value) === undefined ||
    // Object.keys(value) === null ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

/* function to validate given no is decimal upto given by default 2 */
export const isValidDecimalNumber = (
  value: number,
  decimalUpto: number = 2
): boolean => {
  // If it's an integer, return true
  if (Number.isInteger(value)) {
    return true;
  }
  // Convert the number to a string
  const valueStr = value.toString();
  // Split the string by the decimal point
  const [integerPart, decimalPart] = valueStr.split(".");
  // If there's no decimal part, it's effectively an integer

  if (!decimalPart) {
    return true;
  }

  // Check if the decimal part exceeds the allowed number of decimal places
  return decimalPart.length <= decimalUpto;
};

/* function to check given no have leading zeros upto given count */
export const checkLeadingZero = (
  value: number | float,
  count: number
): boolean => {
  const valueString = value.toString();

  let zeroCount: number = 0;
  for (let i = 0; i < valueString.length; i++) {
    if (parseInt(valueString[i]) > 0) break;
    else if (valueString[i] === "0") {
      zeroCount++;
    }
  }

  return zeroCount <= count;
};

// download image or pdf
export const downloadFile = async (fileData) => {
  const image = await fetch(fileData?.url);
  const imageBlog = await image.blob();

  saveAs(imageBlog, fileData?.name || "image.jpg");

  // const imageURL = URL.createObjectURL(imageBlog);
  // const link = document.createElement("a");
  // link.href = imageURL;
  // link.download = fileData?.name;
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
};
