import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";
import {
  excelFileFormatAcceptable,
  typographyConstants,
} from "../../../../constants/constants";
import en from "../../../../locale/rental-en.json";
import { iotActions } from "../../../../redux/actions";
import { isExcelExtention } from "../../../../utils/helper";
import { StyledObject } from "../styleObject";
import IotBulkUploadErrorModal from "./sections/bulkUploadErrorModal";
import { clearBreadcrumbs, updateBreadcrumbs } from "../../../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";

const excel = require("exceljs");

const { IotManagement, buttonLabels, errorMessages } = en;

const BulkUploadIot = () => {
  //references
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // data from iot managment reducer
  const { iotBulkUploadData, excelFormatRequestedLoader } = useSelector(
    (state: RootState) => state.rentalsIotManagementReducer
  );

  // state to open close error modal
  const [open, setOpen] = useState(false);

  // temp store excel file
  const [excel, setExcel] = useState<any>({});

  // side effect to reset bulk upload data on unmount
  useEffect(() => {
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.IOT_BULK_UPLOAD));

    return () => {
      dispatch(iotActions.resetIotBulkUpload());
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // side effect to show upload error modal or on success to navigate back
  useEffect(() => {
    if (
      (iotBulkUploadData?.invalidEntries &&
        iotBulkUploadData?.invalidEntries.length) ||
      (iotBulkUploadData?.errors && iotBulkUploadData?.errors.length)
    ) {
      setOpen(true);
    } else if (
      !iotBulkUploadData?.invalidEntries?.length &&
      iotBulkUploadData.validEntries
    ) {
      navigate(-1);
    }
  }, [iotBulkUploadData]);

  //get iot bulk upload excel sheet format
  const getUploadSheetFormat = () => {
    dispatch(iotActions.getIotUploadFormat());
  };

  // handle excel file while upload
  const handleFileChangeExcel = async (e: any) => {
    try {
      let files = e.target.files,
        f = files[0];
      let fileType = e.target.files[0].name.split(".")[1];
      if (!isExcelExtention(fileType)) {
        toastr.warning("Alert", errorMessages.correctFormat);
        e.target.value = null;
        setExcel({});
        return;
      }
      let reader = new FileReader();
      reader.onload = function (e: any) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const ws = readedData.Sheets["Sheet 1"];
        /* Convert array to json*/
        const dataParse1 = XLSX.utils.sheet_to_json(ws);

        setExcel({ sheet1: dataParse1 });
      };
      reader.readAsBinaryString(f);
    } catch (err) {
      console.error(err);
    }
  };

  // submit excel file api call
  const submitExcel = () => {
    let error = false;

    if (excel?.sheet1?.length === 0) {
      toastr.warning("Alert", errorMessages.blankExcelFileError);
      return;
    }
    const sheet1 = excel.sheet1;
    for (const i in sheet1) {
      if (
        !sheet1?.[i]["imeiNumber"] ||
        !sheet1?.[i]["type"] ||
        !sheet1?.[i]["phoneNumber"]
      ) {
        error = true;
      }
      if (sheet1[i]?.imeiNumber)
        sheet1[i].imeiNumber = sheet1[i].imeiNumber.toString();
      if (sheet1[i]?.phoneNumber)
        sheet1[i].phoneNumber = sheet1[i].phoneNumber.toString();
    }
    if (error) {
      toastr.warning("Alert", errorMessages.excelValuesAreNotCorrectError);
      return;
    }

    setTimeout(() => {
      dispatch(
        iotActions.bulkUploadIot({
          data: sheet1,
        })
      );
    }, 1000);
  };

  // handle close errors modal
  const handleModalClose = () => {
    setOpen(!open);
  };

  return (
    <Stack gap={"30px"}>
      <Typography variant={typographyConstants.HEADINGXL}>
        {IotManagement.UploadIotDevicesData}
      </Typography>
      <Box sx={StyledObject.bulkUploadOuterBox}>
        <Box sx={StyledObject.bulkUploadButtonAlign}>
          <CustomButton
            label={buttonLabels.getExcelFormat}
            onClick={getUploadSheetFormat}
            variant={"outlined"}
            disabled={excelFormatRequestedLoader}
            loading={excelFormatRequestedLoader}
          />
          <Stack>
            <Typography sx={{ fontSize: 12, color: colors.dark_gray }}>
              {IotManagement.UploadHere}
            </Typography>
            <input
              name="upload-file"
              type="file"
              onClick={(e: any) => {
                if (e.target.value) e.target.value = null;
                setExcel({});
              }}
              style={{ cursor: "pointer" }}
              onChange={(e: any) => handleFileChangeExcel(e)}
              accept={excelFileFormatAcceptable.EXCEL_FORMAT}
            />
          </Stack>
        </Box>
        <Box sx={StyledObject.bulkUploadIotButtons}>
          <CustomButton
            label={buttonLabels.submitFile}
            onClick={submitExcel}
            variant={"outlined"}
            disabled={!Object.keys(excel).length}
            loading={iotBulkUploadData.loading}
          />
          <CustomButton
            label={buttonLabels.goBack}
            onClick={() => navigate(-1)}
            variant={"outlined"}
          />
        </Box>
      </Box>

      <IotBulkUploadErrorModal
        handleModalClose={handleModalClose}
        openModal={open}
        iotBulkUploadData={iotBulkUploadData}
      />
    </Stack>
  );
};

export default BulkUploadIot;
