import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";
import en from "../../../locale/en.json";
import SideDrawer from "../../components/MultiStepForm/SideDrawer";
import SubscriptionItem from "../../components/MultiStepForm/subscriptionItem";
import {
  DisplayChargeTypes,
  DisplayHelmetTypes,
  DisplayPaymentModes,
  DisplayPaymentStatusColorCode,
  DisplayPaymentStatusModes,
  OrderPaymentType,
  PLAN_JOURNEY_DATAGRID_ROW_BACKGROUND,
  PaymentJourneyStatus,
} from "../../constantsX/YOR_BookingDetails";
import {
  BOOKING_STATUS_ENUM,
  BenefitTypes,
  HELMETS_X,
} from "../../constantsX/booking";
import {
  BGImg,
  BenefitHelmetIcon,
  BenefitInsuranceIcon,
  BenefitServiceIcon,
  BikeIcon,
  Clock,
  FullFaceHelmet,
  HalfFaceHelmet,
  KidsHelmet,
  PremiumHelmet,
  SuccessTick,
  planCardBackground,
} from "../../constantsX/exportImagesX";
import {
  cleanUpOrderAndPaymentData,
  createOrderForBulkPaymentYOR,
  getBookingDetailsYOR,
} from "../../redux/actions/YOR_BookingDetailsActions";
import { colors } from "../../themes/colors";
import {
  addDaysToDate,
  dateFormat,
  dateToTimestamp,
  getFormattedINR,
  getNumberOrdinal,
} from "../../utils/helper";
import styles from "./bookingDetails.module.css";
import PaymentDetailsSideModalUI from "./sections/SideDrawerModalUI/PaymentDetailsSideModalUI";
import StyledDataGrid from "../../components/Molecule/DataGridMUI/StyledDataGrid";
import { StartIconLabelButtonX } from "../../components/muiButtonsX";

const PlanDetails = ({
  planDetailsSection,
  bookingDetailsloader,
  modelMapData,
  paymentModes,
  orderDataloader,
  orderData,
  paymentLinkQRData,
  paymentLinkQRLoader,
  generatePaymentLinkQRHandler,
  bookingPayloadKeys,
  bookingStatus,
  bookingStartDate,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const [selectedInstallmentIds, setSelectedInstallmentIds] = useState<any>([]);
  const [paymentMethod, setPaymentMethod] = useState<any>(
    OrderPaymentType.PAYMENT_LINK
  );
  const dispatch = useDispatch();
  const param = useParams();

  /* ADD ONS EXCEPT COMPLIMENTARY */
  const AddOnsSectionData = planDetailsSection?.addOns?.filter(
    (item: any, index: number) => item?.isComplimentary !== true
  );

  const handlePaymentModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(event.target.value);
  };

  const toggleDrawer = () => {
    setOpen((prev) => {
      if (prev === true) {
        const allBookingSectionsParam = {
          ...param,
          keys: bookingPayloadKeys,
        };
        dispatch(getBookingDetailsYOR(allBookingSectionsParam));
        dispatch(cleanUpOrderAndPaymentData());
      }
      return !prev;
    });
    // Dispatch cleanup order & Payment Data from reducer
  };
  const isOngoingBooking = bookingStatus === BOOKING_STATUS_ENUM.ACTIVE;
  const handleOrderGenerationAndPayment = () => {
    if (selectedInstallmentIds.length > 0) {
      let orderPayload = {
        fetchBookingPayload: {
          ...param,
          keys: bookingPayloadKeys,
        },
        bookingId: param.id,
        selectedPaymentMode: paymentMethod,
        schedules: selectedInstallmentIds,
      };
      dispatch(createOrderForBulkPaymentYOR(orderPayload));
    }
  };

  // Pay Now Drawer UI
  const PlanDetailsPayNowPopUp = () => {
    const PlanDetailsSelectedSchedules =
      selectedInstallmentIds.length > 0
        ? planDetailsSection?.schedules?.filter((item: any) => {
            return selectedInstallmentIds.includes(item?.installmentNumber);
          })
        : [];
    const PlanDetailsPayNowPopUpArr =
      PlanDetailsSelectedSchedules.length > 0
        ? PlanDetailsSelectedSchedules.map((item: any) => {
            let title = item?.charges[0]?.type ? item?.charges[0]?.type : "";
            return {
              title: `${item.installmentNumber}${getNumberOrdinal(
                item.installmentNumber
              )} ${
                DisplayChargeTypes[title as keyof typeof DisplayChargeTypes]
              }`,
              amount: getFormattedINR(item?.charges[0]?.amount),
              date: dateFormat(item?.dueDate),
            };
          })
        : [];
    const getTotalAmount = (sum: any, item: any) => {
      return sum + item?.charges[0]?.amount;
    };
    const getTotalPayableAmount =
      PlanDetailsSelectedSchedules.length > 0
        ? PlanDetailsSelectedSchedules.reduce(getTotalAmount, 0)
        : 0;
    return (
      <Stack gap="2vh">
        {/* PAYMENT DETAILS */}
        <Box className="PaymentDetails">
          {/* TITLE */}
          <Typography
            variant="caption"
            color={colors.text_secondary_gray}
            fontSize="12px"
            fontWeight="500"
            marginBottom="5px"
          >
            {en.BookingDetailsFreedoX.paymentDetails}:
          </Typography>

          {/* BREAKUP */}
          <Box className={styles.drawerHighlightBox}>
            {PlanDetailsPayNowPopUpArr?.map((item: any, index: any) => (
              <>
                <SubscriptionItem
                  key={index}
                  dashedBorder={false}
                  title={item?.title}
                  amount={item?.amount}
                  fontSize="10px"
                  showBorder={
                    index === PlanDetailsPayNowPopUpArr?.length - 1
                      ? false
                      : true
                  }
                  // date={item?.date}
                  titleColor={item?.titleColor}
                  description={item?.date}
                />

                {index > 0 && <Divider />}
              </>
            ))}

            {/* PAYABLE AMOUNT */}
            {getTotalPayableAmount > 0 && (
              <Box className={styles.paymentDetailsPopUp}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    fontFamily: "Euclid Circular B",
                  }}
                >
                  {en.manualBookingFreedoX.PayableAmount}
                </Typography>
                <Box>
                  <Typography variant="caption" sx={{ fontSize: "14px" }}>
                    {getFormattedINR(getTotalPayableAmount)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Divider />

        {/* PAYMENT MODE */}
        <Box className="PaymentMode">
          <Typography variant="caption" color={colors.text_secondary_gray}>
            Select Payment Method:
          </Typography>
          <Box className={styles.drawerHighlightBox}>
            <FormControl>
              {/* <FormLabel id="demo-controlled-radio-buttons-group">
                Select payment method
              </FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={paymentMethod}
                onChange={handlePaymentModeChange}
              >
                {paymentModes?.map((mode: any) => (
                  <FormControlLabel
                    sx={{ fontSize: "" }}
                    value={mode}
                    control={<Radio />}
                    label={
                      DisplayPaymentModes[
                        mode as keyof typeof DisplayPaymentModes
                      ]
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {/* SUBMIT BUTTON */}
        <StartIconLabelButtonX
          label={`Generate ${
            DisplayPaymentModes[
              paymentMethod as keyof typeof DisplayPaymentModes
            ]
          }`}
          variant="contained"
          minWidth={"100%"}
          minHeight="26px"
          backgroundColor={colors.primary_aqua_blue}
          onClick={orderDataloader ? null : handleOrderGenerationAndPayment}
          disabled={orderDataloader}
        />
      </Stack>
    );
  };
  interface PlanBenefitsObjInterface {
    [key: string]: any;
  }
  const PlanBenefitsObj: PlanBenefitsObjInterface = {
    FREE_MAINTAINANCE: {
      icon: BenefitServiceIcon,
      type: BenefitTypes.FREE_MAINTAINANCE,
      text: "Free first 3 servicing ",
    },
    FREE_HELMET: {
      icon: BenefitHelmetIcon,
      type: BenefitTypes.FREE_HELMET,
      text: "Complementary 1st helmet",
    },
    FREE_INSURANCE: {
      icon: BenefitInsuranceIcon,
      type: BenefitTypes.FREE_INSURANCE,
      text: "Insurance covered",
    },
  };
  const PlanBenefitsArr = planDetailsSection?.plan?.benefits;

  const renderStatusButton = (record: any) => {
    let statusBlock;
    let pastDueDate = false;

    // let dueDateTimeStamp = addDaysToDate(record?.row?.dueDate, 1);
    // dueDateTimeStamp = dateToTimestamp(dueDateTimeStamp);

    if (record?.row?.status === PaymentJourneyStatus.PENDING) {
      const dayjs = require("dayjs");
      let formattedDueDate = dayjs(record?.row?.dueDate, "DD/MM/YYYY");
      formattedDueDate = formattedDueDate.add(1, "day");
      const dueDateTimeStamp = dayjs(formattedDueDate).valueOf();
      const currentTimestamp = new Date().valueOf();
      pastDueDate = currentTimestamp > dueDateTimeStamp;
    }
    statusBlock = (
      <>
        {record?.row?.status === PaymentJourneyStatus.PENDING && pastDueDate
          ? (statusBlock = (
              <Typography color={colors.primary_warning} fontSize={"14px"}>
                Overdue !
              </Typography>
            ))
          : record?.row?.status === PaymentJourneyStatus.COLLECTED
          ? (statusBlock = (
              <>
                <img src={SuccessTick} />{" "}
                <Typography color={colors.secondary_green} fontSize={"14px"}>
                  Paid
                </Typography>
              </>
            ))
          : (statusBlock = (
              <>
                <Typography
                  color={
                    DisplayPaymentStatusColorCode[
                      record?.row
                        ?.status as keyof typeof DisplayPaymentStatusColorCode
                    ]
                  }
                  fontSize={"14px"}
                >
                  {
                    DisplayPaymentStatusModes[
                      record?.row
                        ?.status as keyof typeof DisplayPaymentStatusModes
                    ]
                  }
                </Typography>
              </>
            ))}
      </>
    );
    return statusBlock;
  };
  // DATATABLE
  const PlanJourneyColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Month",
      width: 200,
      align: "center",
      sortable: false,
    },
    {
      field: "dueDate",
      headerName: "Due date",
      width: 250,
      align: "center",
      sortable: false,
    },
    {
      field: "subscriptionAmount",
      headerName: "Subscription Amount",
      description:
        "This column shows recurring monthly amount and is not sortable.",
      width: 300,
      align: "center",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      description: "This column shows payment status and is not sortable.",
      sortable: false,
      renderCell: renderStatusButton,
      width: 150,
      align: "center",
      // headerClassName: `${styles.dataTableStatusHeader}`
      cellClassName: `${styles.dataTableStatusHeader}`,
    },
  ];
  // DATA TABLE ROWS
  const PlanJourneyRows = planDetailsSection?.schedules?.map(
    (record: any, index: number) => {
      let pastDueDate = false;
      if (record?.status !== PaymentJourneyStatus.COLLECTED) {
        pastDueDate = new Date() > new Date(record?.dueDate);
      }
      return {
        // id: record?.installmentNumber,
        id: record?.installmentNumber,
        dueDate: dateFormat(record?.dueDate),
        subscriptionAmount: getFormattedINR(record?.charges[0]?.amount),
        status: record?.status,
        rowColor:
          PLAN_JOURNEY_DATAGRID_ROW_BACKGROUND[
            record?.status as keyof typeof PLAN_JOURNEY_DATAGRID_ROW_BACKGROUND
          ],
      };
    }
  );

  const handlePlanRowSelection = (IDs: any) => {
    setSelectedInstallmentIds(IDs);
  };

  const handleSchedulesPayNow = () => {
    if (selectedInstallmentIds.length === 0)
      return toastr.warning("Alert", en?.errorMessages?.PayNowError);
    toggleDrawer();
  };

  return (
    <Fade>
      {/* PAYMENT DRAWER */}
      <SideDrawer
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        renderUI={
          paymentLinkQRLoader ||
          (paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0) ? (
            <PaymentDetailsSideModalUI
              paymentLinkQRData={paymentLinkQRData}
              paymentLinkQRLoader={paymentLinkQRLoader}
            />
          ) : (
            <PlanDetailsPayNowPopUp />
          )
        }
      ></SideDrawer>

      <Box>
        {/* TOP SECTION */}
        <Box padding={"24px 24px 32px 24px"} borderBottom={"1px solid #DCDCDC"}>
          <Box className={styles.BoxColumn} gap="20px">
            {/* TOP CARDS */}
            <Box className={styles.PlanDetailsCards}>
              <ChangePlanBox
                plan={planDetailsSection?.plan}
                modelMapData={modelMapData}
                bookingStartDate={bookingStartDate}
              />
              <PlanDetailsBox
                planCharges={planDetailsSection?.plan?.planCharges}
                planDuration={planDetailsSection?.plan?.durationInMonths}
                isSubcriptionFeePaid={planDetailsSection?.isSubcriptionFeePaid}
              />
              {AddOnsSectionData && AddOnsSectionData?.length > 0 && (
                <AddOnsBox addOns={AddOnsSectionData} />
              )}
            </Box>

            {/* BENEFITS */}
            <Stack gap="2vh">
              {PlanBenefitsArr?.length > 0 && (
                <Typography
                  fontSize={"14px"}
                  color={colors.text_secondary_gray}
                  fontWeight={"500"}
                >
                  {en.manualBookingFreedoX.BenefitsYouGet} :
                </Typography>
              )}

              {/* Benefits name and image from API */}
              <Stack gap="2vh" direction="row">
                {PlanBenefitsArr?.map((item: any) => (
                  <Stack
                    direction="row"
                    gap="1vw"
                    key={PlanBenefitsObj[item?.type]["type"]}
                  >
                    <img
                      src={PlanBenefitsObj[item?.type]["icon"]}
                      style={{ width: "2vw" }}
                    />
                    <Typography
                      width="6vw"
                      fontSize={10}
                      color={colors.primary_aqua_blue}
                      fontFamily="Euclid Circular B"
                    >
                      {PlanBenefitsObj[item?.type]["text"]}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Box>

        {/* BOTTOM SECTION */}
        <Box padding={"20px 24px 32px 24px"}>
          <Stack gap="4vh">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap="1vw">
                <Typography
                  fontSize={"14px"}
                  color={colors.text_secondary_gray}
                  fontWeight={"500"}
                >
                  Plan Journey :
                </Typography>
                <Typography variant="caption">
                  {PlanJourneyRows.length > 0 &&
                    `${PlanJourneyRows.length} scheduled payments`}
                </Typography>
              </Stack>

              {isOngoingBooking && (
                <StartIconLabelButtonX
                  label="Pay Now"
                  variant="contained"
                  minWidth={"130px"}
                  minHeight="26px"
                  backgroundColor={colors.primary_aqua_blue}
                  onClick={handleSchedulesPayNow}
                  // onClick={toggleDrawer}
                />
              )}
            </Stack>

            {/* PLAN JOURNEY TABLE */}
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={PlanJourneyRows}
                columns={PlanJourneyColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                    rowCount: 100,
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(ids: any) =>
                  handlePlanRowSelection(ids)
                }
                isRowSelectable={(params: GridRowParams) =>
                  isOngoingBooking && params.row.status === "PENDING"
                }
                disableColumnResize={true}
                disableColumnMenu={true}
                sx={{
                  "& .MuiDataGrid-scrollbar MuiDataGrid-scrollbar--vertical": {
                    display: "none",
                  },
                  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    display: "none",
                  },
                  "& .MuiDataGrid-scrollbarContent": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "center",
                  },
                }}
                hideFooterPagination={true}
                hideFooter={true}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Fade>
  );
};

const ChangePlanBox = ({ plan, modelMapData, bookingStartDate }: any) => {
  const modalName = modelMapData.has(plan?.modelName)
    ? modelMapData.get(plan?.modelName)
    : plan?.modelName;
  return (
    <Stack
      className={styles.changePlanBox}
      justifyContent="space-between"
      alignItems="center"
      sx={{ backgroundImage: `url(${planCardBackground})` }}
    >
      <Box className={styles.changePlanBoxIn}>
        <Typography className={styles.changePlanText} color={colors.text_black}>
          {plan?.displayName}
        </Typography>
      </Box>
      <Typography
        className={styles.changePlanText}
        color={colors.text_secondary_gray}
        fontSize={"12px"}
      >
        {en.manualBookingFreedoX.StartDate}-{" "}
        {/* {plan?.activeFrom ? dateFormat(plan?.activeFrom) : ""} */}
        {bookingStartDate ? dateFormat(bookingStartDate) : ""}
      </Typography>
      <Box
        className={`${styles.BoxColumn}`}
        gap={"4px"}
        alignItems={"center"}
        padding={"0px 10px 10px"}
        sx={{ width: "100%" }}
      >
        <Box className={styles.changePlanMonthsBox}>
          <Box className={`${styles.BoxRow}`} gap={"4px"}>
            <img src={Clock} />
            <Typography fontSize={"12px"}>
              {plan?.durationInMonths} months
            </Typography>
          </Box>
          <Box className={`${styles.BoxRow}`} gap={"4px"}>
            <img src={BikeIcon} />
            <Typography fontSize={"12px"}>{modalName}</Typography>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

const PlanDetailsBox = ({
  planCharges,
  planDuration,
  isSubcriptionFeePaid,
}: any) => {
  return (
    <Box>
      <Box className={styles.planDetailsBox}>
        <Box className={styles.headingPlanDetails}>
          <Typography
            color={colors.text_secondary_gray}
            fontSize="12px"
            fontWeight="500"
            marginBottom="5px"
          >
            {en.manualBookingFreedoX.PlanDetails}
          </Typography>
        </Box>
        <SubscriptionItem
          dashedBorder={false}
          title={en.manualBookingFreedoX.FreedoXSubscription}
          description={`One time Payment`}
          descriptionStatusChip={
            isSubcriptionFeePaid && (
              <Chip
                label="Paid"
                color="success"
                size="small"
                sx={{ fontSize: "0.7rem", height: "unset" }}
              />
            )
          }
          amount={getFormattedINR(planCharges?.depositAmount)}
          fontSize="10px"
          showBorder={true}
          amountColor={colors.secondary_green}
        />

        <SubscriptionItem
          dashedBorder={false}
          title={en.manualBookingFreedoX.MonthySubscription}
          description={`${getFormattedINR(planCharges?.monthlyCharges)} for ${
            planDuration ? planDuration : 0
          } months`}
          amount={getFormattedINR(planCharges?.monthlyCharges)}
          fontSize="10px"
          showBorder={true}
          amountColor={colors.secondary_green}
        />

        <SubscriptionItem
          dashedBorder={false}
          title={en.manualBookingFreedoX.UpgradationFee}
          amount={getFormattedINR(planCharges?.closureAmount)}
          fontSize="10px"
          showBorder={false}
          amountColor={colors.secondary_green}
        />
      </Box>
    </Box>
  );
};

const AddOnsBox = ({ addOns }: any) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const PlanDetailsPaymentDetailsPopUp = () => {
    return (
      <Box>
        <Typography
          variant="caption"
          color={colors.text_secondary_gray}
          fontSize="12px"
          fontWeight="500"
          marginBottom="5px"
        >
          {en.BookingDetailsFreedoX.paymentDetails}:
        </Typography>
        <Box
          sx={{
            borderWidth: "1px solid",
            border: colors.text_secondary_gray,
            borderRadius: "12px",
            padding: "8px 0px",
            backgroundColor: "#fff",
          }}
        >
          <SubscriptionItem
            dashedBorder={false}
            title={"Premium Helmet"}
            amount={getFormattedINR(30000)}
            fontSize="10px"
            showBorder={true}
          />
          <SubscriptionItem
            dashedBorder={false}
            title={"Full Face Helmet"}
            amount={getFormattedINR(100000)}
            fontSize="10px"
            showBorder={true}
          />
          <SubscriptionItem
            dashedBorder={false}
            title={"Half Face Helmet"}
            amount={getFormattedINR(100000)}
            fontSize="10px"
            showBorder={true}
          />
          {/* Payable amount */}
          <Box sx={{ padding: "10px" }}>
            <Box className={styles.paymentDetailsPopUp}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Euclid Circular B",
                }}
              >
                {en.manualBookingFreedoX.PayableAmount}
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                >
                  {getFormattedINR(50000)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const AddOnesArrPopUp = [
    {
      icon: PremiumHelmet,
      type: HELMETS_X.premium,
      title: "Premium Helmet",
      amount: getFormattedINR(30000),
    },
    {
      icon: FullFaceHelmet,
      type: HELMETS_X.fullFace,
      title: "Full Face Helmet",
      amount: getFormattedINR(30000),
    },
    {
      icon: HalfFaceHelmet,
      type: HELMETS_X.halfFace,
      title: "Half Face Helmet",
      amount: getFormattedINR(30000),
    },
    {
      icon: KidsHelmet,
      type: HELMETS_X.kids,
      title: "Kids",
      amount: getFormattedINR(30000),
    },
  ];

  const PlanDetailsAddOnes = () => {
    return (
      <Box>
        <Typography
          variant="caption"
          color={colors.text_secondary_gray}
          fontSize="12px"
          fontWeight="500"
          marginBottom="5px"
        >
          {en.manualBookingFreedoX.Addons}:
        </Typography>
        <Box
          sx={{
            borderWidth: "1px solid",
            border: colors.text_secondary_gray,
            borderRadius: "12px",
            padding: "8px 0px",
            backgroundColor: "#fff",
          }}
        >
          {AddOnesArrPopUp.map((item: any, index: any) => (
            <Box
              key={index}
              className={styles.AddOnesBox}
              sx={{
                borderBottom:
                  index === AddOnesArrPopUp?.length - 1
                    ? ""
                    : "1px solid #D4E4E452",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box
                  sx={{ display: "flex", width: "115px", alignItems: "center" }}
                >
                  <img src={item.icon} />
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: colors.text_quatenary_black,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "10px", color: colors.text_black }}>
                  {getFormattedINR(item.amount)}
                </Typography>
              </Box>
              <StartIconLabelButtonX
                label="Add"
                variant="outlined"
                minWidth={"65px"}
                minHeight="26px"
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  const AddOnesArr = [
    {
      title: "Premium Helmet",
      value: "01",
    },
    {
      title: "Full Face Helmet",
      value: "01",
    },
    {
      title: "Half Face Helmet",
      value: "01",
    },
  ];
  return (
    <>
      <Box className={styles.planDetailsBox}>
        <Box className={styles.headingPlanDetails}>
          <Typography
            color={colors.text_secondary_gray}
            fontSize="12px"
            fontWeight="500"
            marginBottom="5px"
          >
            {en.manualBookingFreedoX.Addons}
          </Typography>
          {/* <StartIconLabelButtonX
            label="Add"
            minWidth="100px"
            fontSize="12px"
            icon={PlusIcon}
            minHeight="25px"
            onClick={toggleDrawer}
          /> */}
        </Box>

        {addOns?.map((item: any, index: any) => (
          <Box
            key={index}
            className={styles.AddOnesBox}
            sx={{
              borderBottom:
                index === AddOnesArr?.length - 1 ? "" : "1px solid #D4E4E452",
            }}
          >
            <Typography
              fontSize={"14px"}
              fontFamily={"Euclid Circular B"}
              color={colors.text_secondary_gray}
            >
              {
                DisplayHelmetTypes[
                  item?.subType as keyof typeof DisplayHelmetTypes
                ]
              }
            </Typography>
            <Typography
              fontSize={"14px"}
              fontFamily={"Euclid Circular B"}
              fontWeight={"500"}
              color={colors.text_black}
            >
              {item?.quantity}
            </Typography>
          </Box>
        ))}
      </Box>
      {/* <SideDrawer
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        renderUI={<PlanDetailsPaymentDetailsPopUp />}
        AddOnes={<PlanDetailsAddOnes />}
      ></SideDrawer> */}
    </>
  );
};

export default PlanDetails;
