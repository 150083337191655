import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { typographyConstants } from "../../../../rentals-src/constants/constants";
import "./InputRangePicker.css";
import rentalEn from "../../../../rentals-src/locale/rental-en.json";

const { global } = rentalEn;

const RangePicker = ({
  fields,
  slotPauseDates,
  handleDateChange,
  heading,
  icon,
  disabled,
  placeholder,
  ...rest
}: any) => {
  // whereas SlotPauseDates is array (dates which we want to disable )
  const formatSlotPauseDates = formatDatesArray(slotPauseDates);

  return (
    <Stack width="100%">
      {heading ? (
        <Typography variant={typographyConstants.HEADING}>{heading}</Typography>
      ) : null}
      <DatePicker
        className="input-range-picker"
        customInput={
          <CustomInput
            icon={icon}
            disabled={disabled}
            placeholder={placeholder ?? global?.selectDate}
          />
        }
        dateFormat={"dd/MM/yyyy"}
        placeholderText={global?.selectDate}
        selectsRange={true}
        startDate={fields?.startDate}
        endDate={fields?.endDate}
        onChange={handleDateChange}
        disabled={disabled}
        isClearable={true}
        closeOnScroll={true}
        excludeDates={formatSlotPauseDates}
        PopperProps={[
          {
            name: "zIndex",
            options: {
              zIndex: 2, // Set your custom z-index here
            },
          },
        ]}
        {...rest}
      />
    </Stack>
  );
};

// Give style to the textfeild of range Picker
const CustomInput = React.forwardRef(
  ({ value, onClick, icon, disabled, placeholder }: any, ref) => (
    <TextField
      variant="outlined"
      fullWidth
      onClick={onClick}
      value={value}
      // ref={ref}
      placeholder={placeholder ?? global?.selectDate}
      InputProps={{
        sx: {
          borderRadius: "8px",
          fontSize: "12px",
          minHeight: "42px",
          height: "6vh",
          maxHeight: "40px",
          boxShadow:
            "11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A,1px 1px 2px 0px #00000021 inset",
        },
        readOnly: true,
        disabled: disabled,
        startAdornment: (
          <InputAdornment position="start">
            {icon ? <img src={icon} /> : ""}
          </InputAdornment>
        ),
      }}
    />
  )
);

export default RangePicker;

// change the format of slot pause into which the datePicker accepted
const formatDatesArray = (dates: any) => {
  let processedDates: any[] = [];
  dates?.forEach((date: Date) => {
    if (Array.isArray(date)) {
      // Flatten nested arrays
      processedDates = [...processedDates, ...date];
    } else {
      processedDates.push(date);
    }
  });

  return processedDates.map((date) => new Date(date));
};
