import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

function BasicRating(props: any) {
  const [value, setValue] = React.useState<number | null>(2);

  return (
    <Box>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event: any, newValue: any) => {
          setValue(newValue);
        }}
      />
    </Box>
  );
}

function ViewOnlyRating(props: any) {
  return (
    <Box>
      {props?.value > 0 ? (
        <Rating
          name="read-only"
          value={props.value}
          precision={0.5}
         sx = {{color  :  props.color && "#2EC6C8"}}
          readOnly={props.readOnly}
        />
      ) : (
        <div>&nbsp;&nbsp;N/A</div>
      )}
    </Box>
  );
}
export { BasicRating, ViewOnlyRating };
