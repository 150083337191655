import * as actionTypes from "../../actions/actionTypes";
import { manualBookingInterface } from "../actionInterfaces";

const initialState = {
  checkUserDetails: null,
  checkUserLoader: false,
  vehicleDetails: null,
  createManualBookingUser: "",
  createManualBookingLoader: false,
  loading: false,
  selectedFields: {},
  checkIsUserExist: false,
  bookingCharges: {},
  paymentloading: false,
  isManualBookingCreated: false,
  mandateData: "",
  duplicateBookingModal: {
    show: false,
    duplicateLoader: false,
    bookingId: "",
    status: "",
  },
  loadingCharges: false,
  showUserData: false,
};

const manualBookingReducerX = (
  state = initialState,
  action: manualBookingInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_REQUESTED:
      return {
        ...state,
        checkUserLoader: true,
        consentFormFile: "",
        showUserData: false,
        checkIsUserExist: false,
      };
    case actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_SUCCESS:
      return {
        ...state,
        checkUserLoader: false,
        checkUserDetails: payload?.checkUserDetails,
        checkIsUserExist: true,
        showUserData: true,
      };
    case actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_FAILED:
      return {
        ...state,
        checkUserLoader: false,
        checkUserDetails: payload?.checkUserDetails,
        checkIsUserExist: false,
        showUserData: true,
      };
    case actionTypes.MANUAL_BOOKING.CHANGE_USER_EXIST_STATUS:
      return {
        ...state,
        checkUserDetails: payload?.checkUserDetails,
      };
    case actionTypes.MANUAL_BOOKING.ADD_USER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MANUAL_BOOKING.ADD_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.MANUAL_BOOKING.ADD_USER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.MANUAL_BOOKING.SET_SELECTED_FIELDS_X:
      return {
        ...state,
        selectedFields: { ...state.selectedFields, ...payload },
      };
    case actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_REQUESTED:
      return {
        ...state,
        loadingCharges: true,
      };
    case actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_SUCCESS:
      return {
        ...state,
        loadingCharges: false,
        bookingCharges: payload?.bookingCharges?.data,
      };
    case actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_FAILED:
      return {
        ...state,
        loadingCharges: false,
      };
    case actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_REQUESTED_X:
      return {
        ...state,
        createManualBookingLoader: true,
      };
    case actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_FAILED_X:
      return {
        ...state,
        createManualBookingLoader: false,
        isManualBookingCreated: false,
      };

    case actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_SUCCESS_X:
      return {
        ...state,
        isManualBookingCreated: true,
        createManualBookingUser: payload?.createManualBookingUser,
        createManualBookingLoader: false,
      };
    case actionTypes.CLEAR_DATA_ACTIONS_TYPE_X.ClEAR_CHECK_USER_DEATILS_X:
      return {
        ...state,
        checkUserDetails: null,
        checkIsUserExist: false,
        isManualBookingCreated: false,
        showUserData: false,
      };

    case actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_REQUESTED:
      return {
        ...state,
        paymentloading: true,
      };
    case actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_SUCCESS:
      return {
        ...state,
        paymentloading: false,
        mandateData: payload.mandateData,
      };
    case actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_FAILED:
      return {
        ...state,
        paymentloading: false,
        mandateData: "",
      };
    case actionTypes.MANUAL_BOOKING.SET_DUPLICATE_BOOKING_MODAL_X:
      return {
        ...state,
        duplicateBookingModal: { ...payload },
      };
    case actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_REQUESTED_X:
      return {
        ...state,
        duplicateBookingModal: {
          ...state.duplicateBookingModal,
          duplicateLoader: true,
        },
      };
    case actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_X:
      return {
        ...state,
        duplicateBookingModal: { ...initialState.duplicateBookingModal },
        createManualBookingLoader: false,
      };
    default:
      return { ...prevState };
  }
};

export default manualBookingReducerX;
