import { bookingInterface } from "../../../../redux/reducers/actionInterfaces";
import {
  BOOKING_MANAGEMENT_ACTIONS_X,
  BOOKING_TABLE_FILTERS_X_ACTIONS,
} from "../../actions/actionTypes";

const initialState: any = {
    page:"0",
    pageSize: {
        name: "10 rows/page",
        value: "10",
      },
      search: "",
      sorting: "",
      status: {
        name: "All",
        value: "ALL",
      },
};
const bookingListFiltersReducer = (state = initialState, action: any) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_PAGE_X:
      return {
        ...state,
        page:payload,
      };
    case BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_PAGE_SIZE_X:
      return {
        ...state,
        pageSize:{
            ...state.pageSize,
            name:payload.name,
            value: payload.value
        },
      };
    case BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_STATUS_X:
      return {
        ...state,
        status:{
            ...state.status,
            name:payload.name,
            value: payload.value
        }
      };
    case BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_SEARCH_X:
      return {
        ...state,
        search: payload,
      };
    case BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_SORTING_X:
      return {
        ...state,
        sorting: payload,
      };
    default:
      return { ...state };
  }
};
export default bookingListFiltersReducer;
