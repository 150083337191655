import {
  API_URLS,
  customAxios,
  FREEDO_BIZ_X_URL,
  FREEDO_X_URL,
} from "../../config";

const calculateChargesX = (payload: any) => {
  try {
    let calculateChargesUrl = FREEDO_X_URL + API_URLS.calculateChargesX;
    return fetchCharges(calculateChargesUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const fetchCharges = (route: any, payload: any) =>
  customAxios.post(route, payload).catch((ex) => ex);

export default {
  calculateChargesX,
};
