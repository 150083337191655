import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import SideDrawer from "../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesSubCategories,
  BookingExtensionStatus,
  BookingPaymentStatus,
  BookingStatusEnum,
  GenericObject,
  TAGS,
} from "../../../../constants/constants";
import {
  GreenFlagIcon,
  RedFlagIcon,
  addCharges,
} from "../../../../constants/exportImages";
import en from "../../../../locale/rental-en.json";
import { clearCancellationCharges } from "../../../../redux/actions/rentalCommonAction";
import { getFormattedINR, getInitials } from "../../../../utils/helper";
import styles from "../bookingDetails.module.css";
import StartRideModal from "./StartRideModal";
import CancelBookingDrawer from "./cancelBookingDrawer";
// import { BookingStatus } from "../../../../constants/constants";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { UserDetailsProps } from "../../../../interfaces/bookingManagement";
import {
  bookingManagementActions,
  rentalActions,
} from "../../../../redux/actions";
import { CompleteRideDrawerContent } from "./CompleteRide";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { EndRideDrawerContent } from "./EndRideModal";
import BookingExtension from "./bookingExtension";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  bookingFunctionalities,
  CustomerFunctionalities,
  modules,
} from "../../../../../constants/RBACModuleEnums";
import { getBookingDetails } from "../../../../redux/actions/bookingManagementActions";
import { CustomResetIcon } from "../../../../../sharedComponents/atoms/reset";
import { actions } from "../../../../../redux";
import rentalEn from "../../../../locale/rental-en.json";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";

const uploadError: GenericObject = {
  "file-invalid-type": "Select valid file format",
  "file-too-large": "Select file less than 5MB",
  "too-many-files": "To many Files ",
};

interface InitialState {
  odometerReading: string;
  isImgUploaded: boolean;
  fileName: string;
  selectedPaymentMode: string;
}

const initialState: InitialState = {
  odometerReading: "",
  isImgUploaded: false,
  fileName: "",
  selectedPaymentMode: "",
};

const errorInitailState = {
  odometerReading: "",
};
const UserDetails: any = ({
  bookingDetails,
  bookingId,
  toggleAddChargeDrawer,
  navigateToStepHandler,
  navigateToCustomerManagement = () => {},
  handleNavigateTaskAssignment,
  refreshBookingDetails,
}: UserDetailsProps) => {
  const RbacHasAccess = {
    cancelBooking: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.CANCEL_BOOKINGS
    ),
    modifyBooking: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.MODIFY_BOOKING
    ),
    startRide: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.START_RIDE
    ),
    extendRide: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.EXTEND_RIDE
    ),
    endRide: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.END_RIDE
    ),
    addCharges: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.ADD_CHARGES
    ),
    completeRide: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.COMPLETE_RIDE
    ),
    viewCustomerDetails: RbacHelper.isAccessRightsProvided(
      modules.CUSTOMER_MANAGEMENT,
      CustomerFunctionalities.VIEW_CUSTOMER_DETAILS
    ),
  };

  const drawerRef = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const [isStartRideModalOpen, setStartRideModal] = useState(false);

  // state to handle extend ride drawer
  const [extendRideDrawer, setExtendRideDrawer] = useState(false);

  // state to handle end ride side drawer
  const [endRideDrawer, setEndRideDrawer] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State to manage the anchor element for the menu
  const {
    isBookingCancelled,
    loading,
    bookingVehicleDetails,
    attachmentIds,
    pendingChargesLoader,
    pendingCharges,
    isPendingChargeSucess,
    extensionAvailabilityLoader,
    orderData,
    vehicleExtensions,
    bookingDocsLoader,
    docUploadLinearProgress,
    cancelBookingLoader,
    additionalChargesLoader,
    pendingAndProcessingCharges,
    completeRidePaymentLoader,
    planSummary,
    onLoad,
    isPaymentLinkGenerated,
  } = useSelector((state: RootState) => state.bookingManagementReducer);

  const { operatorConfigData }: any = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { emergencyContactVerificationOnStartRide, kycOnStartRideEnabled } =
    operatorConfigData;

  const { extensionWithPending, pendingModification } = planSummary;

  const [fields, setFields] = useState({ ...initialState });

  const [errorFields, setErrorFields] = useState({ ...errorInitailState });
  // state to handle end ride side drawer
  const [completeRideDrawer, setCompleteRideDrawer] = useState<boolean>(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const bookingStatus = bookingDetails?.status;

  useEffect(() => {
    if (isBookingCancelled) {
      setOpen(false);
    }
  }, [isBookingCancelled]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /* Expand User Detail */
  const handleChange = (e: any) => {
    setExpand((prevExpand) => !prevExpand);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setOpen(!open);
    handleClose();
  };
  const handleClearCancellationCharges = () => {
    dispatch(clearCancellationCharges());
  };

  const toggleStartRideModal = (value: boolean) => {
    const skipEmergencyNumber = !emergencyContactVerificationOnStartRide;
    const skipKyc = !kycOnStartRideEnabled;

    // return if operator skip KYC and emergency number
    if (skipEmergencyNumber && skipKyc) {
      if (bookingDetails?.status === "UPCOMING") {
        setStartRideModal(value);
      }
      return;
    }

    if (
      (!skipKyc
        ? !bookingDetails?.customerDetails?.tags?.includes(TAGS.KYC_VERIFIED)
        : false) &&
      (!skipEmergencyNumber
        ? !bookingDetails?.customerEmergencyContact?.length
        : false)
    ) {
      return toastr.error(
        "",
        rentalEn.errorMessages.addEmergengyContactNumberAndKyc
      );
    }
    if (
      (!skipKyc
        ? !bookingDetails?.customerDetails?.tags?.includes(TAGS.KYC_VERIFIED)
        : false) &&
      (!skipEmergencyNumber
        ? bookingDetails?.customerEmergencyContact?.[0]?.status !== "VERIFIED"
        : false)
    ) {
      return toastr.error(
        "",
        rentalEn.errorMessages.emergengyContactNotVerified
      );
    } else if (
      !skipKyc
        ? !bookingDetails?.customerDetails?.tags?.includes(TAGS.KYC_VERIFIED)
        : false
    ) {
      return toastr.error("", rentalEn.errorMessages.KYCNotVerified);
    } else if (
      !skipEmergencyNumber
        ? !bookingDetails?.customerEmergencyContact?.length
        : false
    ) {
      return toastr.error(
        "",
        rentalEn.errorMessages.pleaseAddEmergencyContactNumber
      );
    } else if (
      !skipEmergencyNumber
        ? bookingDetails?.customerEmergencyContact?.length &&
          bookingDetails?.customerEmergencyContact?.[0]?.status !== "VERIFIED"
        : false
    ) {
      return toastr.error(
        "",
        rentalEn.errorMessages.emergengyContactNotVerified
      );
    } else {
      if (bookingDetails?.status === "UPCOMING") {
        setStartRideModal(value);
      }
    }
  };

  // toggle ride side drawer for extend ride
  const toggleExtendRideDrawer = () => {
    if (extendRideDrawer) {
      // refresh booking details data if payment is done/ generated
      if (isPaymentLinkGenerated) {
        dispatch(getBookingDetails({ bookingId: bookingId, getConfig: true }));
      }

      const payload: GenericObject = {
        extensionPlansDetails: {},
        calculatedExtensionCharge: {},
        createExtensionData: {},
        attachmentIds: [],
      };
      dispatch(bookingManagementActions.clearReducerKeys(payload));
      dispatch(
        rentalActions.clearReducerDataRentalCommon({
          isPaymentLinkGenerated: false,
          paymentLink: {},
          updatedStatus: "",
        })
      );
      dispatch(bookingManagementActions.cleanUpOrderAndPaymentData());
      dispatch(rentalActions.cancelPaymentStatus());
    }
    setExtendRideDrawer(!extendRideDrawer);
  };

  // success callback for availability of booking extension
  const successExtensionAvailable = () => {
    setExtendRideDrawer(true);
    // get booking details
    dispatch(getBookingDetails({ bookingId }));
  };

  // handles on click of extend ride button
  const onClickExtendRide = () => {
    dispatch(
      bookingManagementActions.getBookingExtensionDetails({
        bookingId,
        onSuccess: successExtensionAvailable,
      })
    );
  };

  // toggle end ride side drawer
  const toggleEndRideDrawer = () => {
    if (endRideDrawer) {
      setFields((prev: any) => ({ ...prev, ...initialState }));

      const payload = {
        attachmentIds: [],
        isPendingChargeSucess: false,
        pendingCharges: [],
      };
      dispatch(bookingManagementActions.clearReducerKeys(payload));
    }
    setEndRideDrawer(!endRideDrawer);
  };

  //handle reading field in end ride drawer
  const handleOdometerReading = (value: Number) => {
    setFields((prev: any) => ({ ...prev, odometerReading: value }));
    setErrorFields({ ...errorFields, odometerReading: "" });
    dispatch(
      bookingManagementActions.clearReducerKeys({
        isPendingChargeSucess: false,
      })
    );

    if (value <= bookingVehicleDetails?.currentReading) {
      setErrorFields((prevErrorFields) => ({
        ...prevErrorFields,
        odometerReading: `Reading must be greater than ${bookingVehicleDetails?.currentReading} `,
      }));
      return;
    }
  };

  // on drop image in dropzone in end ride drawrr
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    let files: GenericObject = acceptedFiles[0];
    let fileNames: string = acceptedFiles[0]?.name;
    // let rejectedFiles: object[] = [];

    let error: boolean = false;
    fileRejections.map((item: any) => {
      if (item?.errors[0]?.code) {
        error = true;
        toastr.warning(
          "",
          uploadError[item.errors[0]?.code] ??
            en?.errorMessages?.SomethingWentWrong
        );

        return;
      }
    });

    if (error) return;
    getSignedUrl(files, fileNames);
  }, []);

  //sucess callback function when image upload is successful end ride drawer
  const sucessCallbackSignedUrl = (filesData: GenericObject) => {
    setFields((prev: any) => ({
      ...prev,
      isImgUploaded: true,
      fileName: filesData?.fileNames[0],
    }));
  };

  //get signed url for end ride drawer
  const getSignedUrl = (file: any, fileName: string) => {
    const payload: any = {
      requestdata: {
        data: {
          type: "ODOMETER_IMAGES",
          attachments: [fileName],
        },
      },
      filesData: {
        bookingId: bookingId,
        files: [file],
        fileNames: [fileName],
      },
      isUploadDocumentBreak: true,
      sucessCallback: sucessCallbackSignedUrl,
    };
    dispatch(bookingManagementActions.generateVehicleSignedUrl(payload));
  };

  // on Imgae click delete in end ride drawer
  const onClickImageDelete = () => {
    setFields((prev: any) => ({ ...prev, isImgUploaded: false, fileName: "" }));
    dispatch(bookingManagementActions.clearReducerKeys({ attachmentIds: [] }));
  };

  // for Image  dropzone in end ride drawer
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  // on sucess end ride
  const onSuccessEndRide = () => {
    toggleEndRideDrawer();
    setFields((prev: any) => ({ ...prev, ...initialState }));
    const payload = {
      attachmentIds: [],
      isPendingChargeSucess: false,
      pendingCharges: [],
    };
    dispatch(bookingManagementActions.clearReducerKeys(payload));
    // get booking details on suceesfull end ride
    dispatch(bookingManagementActions.getBookingDetails({ bookingId }));
  };

  //handle button click in end ride drawer
  const handleButtonClick = () => {
    if (isPendingChargeSucess) {
      if (pendingModification || extensionWithPending) {
        toggleConfirmationModal();
      } else {
        handleEndRideApi();
      }
    } else {
      const payload = {
        bookingId: bookingId,
        queryParamList: {
          reading: fields.odometerReading,
        },
      };
      dispatch(bookingManagementActions.getPendingCharges(payload));
    }
  };

  //handle ConfirmationModel Yes Click
  const handleYesClick = () => {
    handleEndRideApi();
    toggleConfirmationModal();
  };

  //toggle confirmation modal
  const toggleConfirmationModal = () => {
    setConfirmationModal((showConfirmationModal) => !showConfirmationModal);
  };

  //handle End Ride Api
  const handleEndRideApi = () => {
    const payload = {
      bookingId: bookingId,
      data: {
        data: {
          reading: parseInt(fields?.odometerReading),
          imageIds: attachmentIds.length ? attachmentIds : undefined,
        },
      },
      sucessCallback: onSuccessEndRide,
    };
    dispatch(bookingManagementActions.endRide(payload));
  };

  // toggle complete ride side drawer
  const toggleCompleteRideDrawer = () => {
    setCompleteRideDrawer(!completeRideDrawer);
    setFields((prev: any) => ({ ...prev, selectedPaymentMode: "" }));
    dispatch(
      rentalActions.clearReducerDataRentalCommon({
        isPaymentLinkGenerated: false,
        paymentLink: {},
        updatedStatus: "",
      })
    );
    dispatch(bookingManagementActions.cleanUpOrderAndPaymentData());
    dispatch(rentalActions.cancelPaymentStatus());
  };

  // handle modify Booking
  const handleModifyBooking = () => {
    navigate(`${routesConstants.MODIFY_BOOKING_DETAILS}/${bookingId}`);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* USER DETAIL */}
        <Box
          sx={{
            backgroundColor: colors.THEME_BLUE,
            borderRadius: "40px",
            height: "52px",
            padding: expand ? "5px 10px 5px 10px" : "5px 50px 5px 10px",
            transition: "width 1s ",
            transitionTimingFunction: "linear",

            display: "inline-block",
            "&:hover": { width: "auto" },
          }}
          onMouseOver={handleChange}
          onMouseOut={handleChange}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",

              cursor: "pointer",
            }}
            onClick={() => {
              RbacHasAccess.viewCustomerDetails &&
                navigateToCustomerManagement();
            }}
          >
            <Fade>
              <Box>
                <Avatar
                  sx={{
                    backgroundColor: "white",
                    color: colors.THEME_BLUE,
                    width: "38px",
                    height: "38px",
                    fontSize: "12px",
                    position: "unset",
                  }}
                >
                  {onLoad ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <>
                      {" "}
                      {getInitials(
                        `${bookingDetails?.customerDetails?.firstName} ${bookingDetails?.customerDetails?.lastName}`
                      )}
                    </>
                  )}
                </Avatar>
              </Box>
              {onLoad ? (
                <Skeleton
                  variant="rectangular"
                  width="70px"
                  height="20px"
                  sx={{ borderRadius: "8px" }}
                />
              ) : (
                <Stack>
                  <Typography
                    color={colors.white}
                    variant="caption"
                    fontSize={"12px"}
                  >
                    {bookingDetails?.customerDetails?.firstName}
                  </Typography>
                  <Typography
                    color={colors.white}
                    variant="caption"
                    fontSize={"12px"}
                  >
                    {bookingDetails?.customerDetails?.lastName}
                  </Typography>
                </Stack>
              )}
              {expand && (
                <Fade>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <Typography
                        className={styles.bookingDetailsValue}
                        color={colors.white}
                      >
                        {bookingDetails?.customerDetails?.mobileNumber}
                      </Typography>
                      <Typography
                        className={styles.bookingDetailsValue}
                        color={colors.white}
                        sx={{ width: "auto" }}
                      >
                        {bookingDetails?.customerDetails?.email}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={styles.bookingDetailsValue}
                        color={colors.white}
                      >
                        KYC
                      </Typography>
                      <Typography
                        className={styles.bookingDetailsValue}
                        color={
                          bookingDetails?.customerDetails?.tags?.length &&
                          bookingDetails?.customerDetails?.tags.includes(
                            TAGS.KYC_VERIFIED
                          )
                            ? colors.secondary_green
                            : colors.error
                        }
                      >
                        {bookingDetails?.customerDetails?.tags?.length &&
                        bookingDetails?.customerDetails?.tags.includes(
                          TAGS.KYC_VERIFIED
                        )
                          ? en.bookingDetails.verified
                          : en.bookingDetails.notVerified}
                      </Typography>
                    </Box>
                  </Box>
                </Fade>
              )}
            </Fade>
          </Box>
        </Box>

        {/* Buttons */}
        <Box>
          {bookingStatus &&
          bookingDetails.status !== BookingStatusEnum.PENDING ? (
            <Stack
              direction="row"
              gap="1vw"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {RbacHasAccess.startRide &&
              bookingDetails?.status === BookingStatusEnum.UPCOMING ? (
                <CustomButton
                  variant="outlined"
                  label={en.bookingDetails.startRide}
                  onClick={() => toggleStartRideModal(true)}
                />
              ) : (
                <></>
              )}
              {RbacHasAccess.modifyBooking &&
                (bookingDetails?.status === BookingStatusEnum.UPCOMING ||
                  bookingDetails?.status === BookingStatusEnum.ONGOING) && (
                  <CustomButton
                    variant="outlined"
                    label={en.bookingDetails.modifyBooking}
                    onClick={handleModifyBooking}
                    //style={{ height: "40px", borderRadius: "30px" }}
                  />
                )}

              {RbacHasAccess.extendRide &&
                bookingDetails?.status === BookingStatusEnum.ONGOING &&
                !planSummary?.extensionWithPending && (
                  <CustomButton
                    variant="outlined"
                    label={en.bookingDetails.extendRide}
                    onClick={onClickExtendRide}
                    loading={extensionAvailabilityLoader}
                    style={{ minWidth: "180px" }}
                  />
                )}

              <CustomResetIcon
                loading={loading || additionalChargesLoader}
                customStyle={{ fontSize: "30px" }}
                onClick={refreshBookingDetails}
              />

              <CustomIconButton
                children={<MoreVertIcon />}
                sx={{ backgroundColor: "#cdd6d8" }}
                aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleClick}
              />

              {/* Menu component that appears when the button is clicked */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)} // Boolean state to determine if the menu is open based on anchorEl}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* Menu items that appear inside the Menu */}

                {/* Task Assigment */}
                {bookingStatus !== BookingStatusEnum.PENDING ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleNavigateTaskAssignment();
                      }}
                      sx={{ gap: "5px" }}
                    >
                      <img src={addCharges} alt="Task Assignment Icon" />
                      {en.bookingDetails.taskAssignment}
                    </MenuItem>
                  </>
                ) : null}
                {RbacHasAccess.cancelBooking &&
                  (bookingDetails?.status === "PENDING" ||
                    bookingDetails?.status === "UPCOMING") && (
                    <MenuItem
                      onClick={() => {
                        handleClearCancellationCharges();
                        toggleDrawer();
                      }}
                      sx={{ color: colors.error, gap: "5px" }}
                    >
                      <CloseIcon fontSize="small" /> Cancel Booking
                    </MenuItem>
                  )}
                {/* Add charges */}
                {/* ONGOING ,ENDED */}
                {RbacHasAccess.addCharges &&
                (bookingStatus === BookingStatusEnum.ONGOING ||
                  bookingStatus === BookingStatusEnum.ENDED ||
                  bookingStatus === BookingStatusEnum.COMPLETED) ? (
                  <MenuItem
                    onClick={() => {
                      toggleAddChargeDrawer();
                      handleClose();
                    }}
                    sx={{ gap: "5px" }}
                  >
                    <img src={addCharges} alt="Add charges" /> Add Charges
                  </MenuItem>
                ) : null}
                {/* <MenuItem onClick={handleClose}>Block & Unblock</MenuItem> */}
                {RbacHasAccess.endRide &&
                bookingStatus === BookingStatusEnum.ONGOING ? (
                  <MenuItem
                    onClick={() => {
                      toggleEndRideDrawer();
                      handleClose();
                      dispatch(
                        actions.getTasKSummary({ bookingId: bookingId })
                      );
                    }}
                    sx={{ gap: "5px" }}
                  >
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <img src={RedFlagIcon} alt="Add charges" />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          color: colors.primary_danger,
                        }}
                      >
                        {en.bookingDetails.endRide}
                      </Typography>
                    </Box>
                  </MenuItem>
                ) : RbacHasAccess.completeRide &&
                  bookingStatus === BookingStatusEnum.ENDED ? (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      dispatch(
                        bookingManagementActions.getPendingCharges({
                          bookingId,
                          onSuccess: () => {
                            setCompleteRideDrawer(true);
                          },
                        })
                      );
                    }}
                    sx={{ gap: "5px" }}
                  >
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <img src={GreenFlagIcon} alt="Add charges" />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "green",
                        }}
                      >
                        {en.bookingDetails.completeRide}
                      </Typography>
                    </Box>
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Menu>

              {/* 
         RULE -
           1. Close booking - ACTIVE  
           2. Cancel booking - not CANCELLED or CLOSED
         CASE  -
           1. If production - Close booking not allowed
           2. not production - Cancel & Close allowed
       */}
            </Stack>
          ) : (
            <>
              <CustomIconButton
                children={<MoreVertIcon />}
                sx={{ backgroundColor: "#cdd6d8" }}
                aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)} // Boolean state to determine if the menu is open based on anchorEl}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClearCancellationCharges();
                    toggleDrawer();
                  }}
                  sx={{ color: colors.error, gap: "5px" }}
                >
                  <CloseIcon fontSize="small" /> Cancel Booking
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>

      <SideDrawer
        open={open}
        heading={en.bookingDetails.cancelBooking}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <CancelBookingDrawer
            bookingId={bookingId}
            cancelBookingLoader={cancelBookingLoader}
          />
        }
      />

      {/* end ride drawer */}
      <SideDrawer
        open={endRideDrawer}
        heading={en.bookingDetails.endRide}
        toggleDrawer={toggleEndRideDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <EndRideDrawerContent
            bookingVehicleDetails={bookingDetails}
            loading={loading}
            fields={fields}
            errorFields={errorFields}
            handleOdometerReading={handleOdometerReading}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            onClickImageDelete={onClickImageDelete}
            handleButtonClick={handleButtonClick}
            isPendingChargeSucess={isPendingChargeSucess}
            pendingChargesLoader={pendingChargesLoader}
            totalPendingCharges={getFormattedINR(pendingCharges?.totalAmount)}
            bookingDocsLoader={bookingDocsLoader}
            docUploadLinearProgress={docUploadLinearProgress}
            handleNavigateTaskAssignment={handleNavigateTaskAssignment}
            pendingAndProcessingCharges={pendingAndProcessingCharges}
          />
        }
      />

      {/* complete ride drawer */}
      <SideDrawer
        open={completeRideDrawer}
        heading={en.bookingDetails.completeRide}
        toggleDrawer={toggleCompleteRideDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <CompleteRideDrawerContent
            bookingVehicleDetails={bookingDetails}
            pendingCharges={pendingCharges}
            fields={fields}
            setFields={setFields}
            pendingChargesLoader={pendingChargesLoader}
            bookingId={bookingId}
            orderData={orderData}
            pendingAndProcessingCharges={pendingAndProcessingCharges}
            toggleCompleteRideDrawer={toggleCompleteRideDrawer}
            completeRidePaymentLoader={completeRidePaymentLoader}
          />
        }
      />

      {/* Start ride modal */}
      <StartRideModal
        isOpen={isStartRideModalOpen}
        bookingDetails={{ ...bookingDetails, bookingId }}
        handleClose={() => {
          toggleStartRideModal(false);
          dispatch(
            bookingManagementActions.clearReducerKeys({
              isPendingChargesInPreviousBookings: false,
              pendingChargesBookingsList: [],
            })
          );
        }}
      />

      {/* Extend ride side drawer */}
      <SideDrawer
        open={extendRideDrawer}
        toggleDrawer={toggleExtendRideDrawer}
        heading={en.bookingDetails.extendRide}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        renderUI={
          <BookingExtension
            bookingId={bookingId}
            toggleExtendRideDrawer={toggleExtendRideDrawer}
          />
        }
      />
      <ResponsiveDialog
        title={`There is a pending ${
          extensionWithPending
            ? `extension ${pendingModification ? "&" : ""} `
            : ""
        } ${
          pendingModification ? "modification" : ""
        } charge in this booking. Are you sure you want to end this booking?`}
        open={showConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleYes={handleYesClick}
      />
    </>
  );
};

export default UserDetails;
