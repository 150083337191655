// @ts-ignore
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../themes/colors";
import styles from "../components.module.css";

const InputX = (props: any) => {
  const [focused, setFocused] = React.useState(false);
  const { onIconClick } = props || {};

  return (
    <Box className={styles.BoxColumn} width="100%">
      <Box
        sx={{
          "& .MuiTextField-root": {
            minWidth: props.width ? props.width : "350px",
            width: props.width ? props.width : "400px",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: colors.primary_aqua_blue,
            fontSize: "12px",
            top: "1px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "1px",
            margin: "1px",
          },
        }}
      >
        <TextField
          id="outlined-search"
          label={props.label}
          type="text"
          InputProps={{
            sx: {
              borderRadius: "8px",
              fontSize: "12px",
              minHeight: "40px",
              height: "6vh",
              maxHeight: "50px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <img src={props.iconStart} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <img src={props.icon} onClick={onIconClick} />
              </InputAdornment>
            ),
          }}
          sx={{ color: "rgba(60, 60, 67, 0.4)", borderRadius: "8px" }}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          focused={focused}
          {...props}
        >
          {" "}
        </TextField>
      </Box>
      <Box>
        {props.errormessage && (
          <Typography className="error-message" style={{ fontSize: "10px" }}>
            {props.errormessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InputX;
