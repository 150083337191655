import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "..";
import { getData, storeData } from "../../config/filterStorage";
import TokenService from "../../config/tokenService";
import { LocalStorage } from "../../constants/enum";
import { COUPON_CODE_WORKING_KEY } from "../../constants/others";
import { ToastType } from "../../constants/userActions";
import commonServices from "../../services/commonServices";

import feedbackServices from "../../services/feedbackServices";
import { decrypt, handleMessage } from "../../utils/helper";
import * as actionTypes from "../actions/actionTypes";
import { CommonInterface } from "../reducers/actionInterfaces";

function* fetchCountryData(action: any): any {
  // alert();
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUNTRYDATA_ACTIONS.COUNTRYDATA_SUCCESS,
      payload: {
        countryData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUNTRYDATA_ACTIONS.COUNTRYDATA_FAILED,
    });
  }
}

function* fetchCities(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.CITY_ACTIONS.CITYDATA_SUCCESS,
      payload: {
        allCitiesData: data?.data?.Result,
        current_city: action.payload.current_city,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.CITY_ACTIONS.CITYDATA_FAILED,
    });
  }
}

function* fetchAdvertisements(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_SUCCESS,
      payload: {
        advertisementData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_FAILED,
    });
  }
}
function* fetchbrand(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.BRAND_ACTIONS.BRANDDATA_SUCCESS,
      payload: {
        allBrandData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.BRAND_ACTIONS.BRANDDATA_FAILED,
    });
  }
}

function* fetchStateData(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.STATEDATA_ACTIONS.STATEDATA_SUCCESS,
      payload: {
        stateData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.STATEDATA_ACTIONS.STATEDATA_FAILED,
    });
  }
}

function* fetchVehicleCategories(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_SUCCESS,
      payload: {
        vehicleCategoryList: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_FAILED,
    });
  }
}

function* fetchLocationByCity(action: any): any {
  const data = yield call(commonServices.getCitiesByLocation, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.LOCATION_ACTIONS.LOCATION_DATA_SUCCESS,
      payload: {
        availableLocation: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.LOCATION_ACTIONS.LOCATION_DATA_FAILED,
    });
  }
}

function* fetchVehicles(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_ACTIONS.VEHICLEDATA_SUCCESS,
      payload: {
        ...data.data,
        current_vehicle: action.payload.current_vehicle,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_ACTIONS.VEHICLEDATA_FAILED,
    });
  }
}

function* fetchVehiclesTypes(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_SUCCESS,
      payload: {
        vehicleTypes: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_FAILED,
    });
  }
}

function* fetchPackages(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.PACKAGES_ACTIONS.PACKAGES_DATA_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.PACKAGES_ACTIONS.PACKAGES_DATA_FAILED,
    });
  }
}

function* fetchActiveCoupons(action: any): any {
  const data = yield call(commonServices.getActiveCoupons, action.payload);
  if (data?.status === 200) {
    let obj: any = {};
    if (action?.payload?.allCoupons) {
      obj.allCoupons = decrypt(data?.data?.Result);
    } else {
      obj.couponCodes = [
        ...(decrypt(data?.data?.Result) || []),
        ...(decrypt(data?.data?.Result_2) || []),
      ];
    }
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPONS_SUCCESS,
      payload: {
        ...obj,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPONS_FAILED,
    });
  }
}

function* fetchCoupons(action: any): any {
  const data = yield call(commonServices.getCoupons, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPONS_SUCCESS,
      payload: {
        couponData: [
          ...(decrypt(data?.data?.Result) || []),
          ...(decrypt(data?.data?.Result_2) || []),
        ],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPONS_FAILED,
    });
  }
}



function* fetchCouponCodeType(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_FAILED,
    });
  }
}

function* fetchVehicleColors(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_SUCCESS,
      payload: {
        vehicleColors: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_FAILED,
    });
  }
}

function* getModelListById(action: any): any {
  const data = yield call(commonServices.getModelListById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_SUCCESS,
      payload: {
        vehicleModelList: data?.data?.Result?.rows || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));

    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_FAILED,
    });
  }
}

function* fetchEmissionStandards(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_EMISSION_STANDARDS_SUCCESS,
      payload: {
        emissionStandards: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_EMISSION_STANDARDS_FAILED,
    });
  }
}

function* fetchAllBranches(action: any): any {
  const data = yield call(commonServices.getAllBranches, action.payload);
  if (data?.status === 200) {
    let obj: any = {};
    if (action?.payload?.common) {
      obj.branchesList = data?.data?.Result;
    } else {
      obj.branches = data?.data?.Result;
    }
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_SUCCESS,
      payload: {
        ...obj,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_FAILED,
    });
  }
}

function* fetchAllVendorCodes(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_SUCCESS,
      payload: {
        vendorCodes: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_FAILED,
    });
  }
}

function* fetchAllIotTypes(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_SUCCESS,
      payload: {
        iotTypes: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_FAILED,
    });
  }
}

function* storeDocuments(action: CommonInterface): any {
  const data = yield call(commonServices.storeDocuments, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.STORE_DOCUMENT_SUCCESS,
      payload: {
        docData: data?.data?.Result,
      },
    });
    toastr.success("", handleMessage(data));
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.STORE_DOCUMENT_FAILED,
    });
  }
}

function* fetchVehicleStatus(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_SUCCESS,
      payload: {
        vehicleStatus: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_FAILED,
    });
  }
}

function* fetchVehicleCategoriesById(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_VEHICLE_CATEGORIES_SUCCESS,
      payload: {
        vehicleCategories: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_VEHICLE_CATEGORIES_FAILED,
    });
  }
}

function* getStatusList(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.STATUS_LIST_ACTIONS.GET_STATUS_LIST_SUCCESS,
      payload: {
        ...data.data,
        statusList: data?.data.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.STATUS_LIST_ACTIONS.GET_STATUS_LIST_FAILED,
    });
  }
}


function* getAllTimeSlots(action: any): any {
  const data = yield call(commonServices.getBranchSlots, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_SUCCESS,
      payload: {
        data: data?.data.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_FAILED,
    });
  }
}

function* getCitiesByBranch(action: any): any{
  try {
    const data = yield call(commonServices.commonService, action.payload);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.CITY_ACTIONS.GET_CITIES_BY_STATE_SUCCESS,
        payload: {
          citydata: data?.data?.Result,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.CITY_ACTIONS.GET_CITIES_BY_STATE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CITY_ACTIONS.GET_CITIES_BY_STATE_FAILED,
    });
  }
}

function* commonSaga() {
  yield takeLatest(actionTypes.CITY_ACTIONS.CITYDATA_REQUESTED, fetchCities);
  yield takeLatest(actionTypes.BRAND_ACTIONS.BRANDDATA_REQUESTED, fetchbrand);
  yield takeLatest(
    actionTypes.LOCATION_ACTIONS.LOCATION_DATA_REQUESTED,
    fetchLocationByCity
  );
  yield takeLatest(
    actionTypes.VEHICLE_ACTIONS.VEHICLEDATA_REQUESTED,
    fetchVehicles
  );
  yield takeLatest(
    actionTypes.PACKAGES_ACTIONS.PACKAGES_DATA_REQUESTED,
    fetchPackages
  );

  yield takeLatest(
    actionTypes.COUNTRYDATA_ACTIONS.COUNTRYDATA_REQUESTED,
    fetchCountryData
  );
  yield takeLatest(
    actionTypes.STATEDATA_ACTIONS.STATEDATA_REQUESTED,
    fetchStateData
  );
  yield takeLatest(
    actionTypes.VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_REQUESTED,
    fetchVehicleCategories
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_REQUESTED,
    fetchVehiclesTypes
  );
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPONS_REQUESTED,
    fetchActiveCoupons
  );

  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_REQUESTED,
    fetchCouponCodeType
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_REQUESTED,
    getModelListById
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_REQUESTED,
    fetchVehicleColors
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_EMISSION_STANDARDS_REQUESTED,
    fetchEmissionStandards
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_REQUESTED,
    fetchAllBranches
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_REQUESTED,
    fetchAllIotTypes
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_REQUESTED,
    fetchAllVendorCodes
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.STORE_DOCUMENT_REQUESTED,
    storeDocuments
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_REQUESTED,
    fetchVehicleStatus
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_CATEGORIES_REQUESTED,
    fetchVehicleCategoriesById
  );
  yield takeLatest(
    actionTypes.STATUS_LIST_ACTIONS.GET_STATUS_LIST_REQUESTED,
    getStatusList
  );
  yield takeLatest(
    actionTypes.SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_REQUESTED,
    getAllTimeSlots
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_REQUESTED,
    fetchAdvertisements
  );
  yield takeLatest(
    actionTypes.CITY_ACTIONS.GET_CITIES_BY_STATE_REQUESTED,
    getCitiesByBranch
  );
}

export default commonSaga;
