import { Box, Skeleton, Stack } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import HeadingValueTypography from "../../components/MultiStepForm/headingValueTypography";
import styles from "./bookingDetails.module.css";
import { bookingDetailsStepProps } from "../../interfaces/bookingDetailsInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { dateFormat, getFormattedINR } from "../../utils/helper";
import {
  DISPLAY_DELIVERY_MODES,
  DisplayBookingType,
  DisplayBookingTypeKey,
  DisplayPaymentStatusColorCode,
} from "../../constantsX/YOR_BookingDetails";
import {
  getDisplayAddress,
  getFormattedAddress,
} from "../../utils/Helper/BookingDetails";
import BookingDetailLoader from "../../../sharedComponents/molecules/SkeletonLoader/BookingDetailLoader";
import { colors } from "../../themes/colors";
import { BOOKING_STATUS_ENUM } from "../../constantsX/booking";
import { getDeliveryAddress } from "../../../utils/helper";

const BookingDetailStep = ({
  bookingDetailsSection,
  bookingDetailsloader,
  plan,
  bookingStatus,
  pendingPaymentStatus,
  resetMultistepFormRef,
  cityMasterMap,
}: any) => {
  let key: DisplayBookingTypeKey = bookingDetailsSection?.bookingType;
  let bookingType = DisplayBookingType[key];
  let branchAddress = bookingDetailsSection?.branchDetails?.address;
  let city = bookingDetailsSection?.deliveryDetails?.address?.city;
  /* let warehouseAdd = getFormattedAddress(branchAddress);
  let pickupAdd = getFormattedAddress(branchAddress); */
  let warehouseAdd = bookingDetailsSection?.branchDetails?.displaName;
  let pickupAdd = cityMasterMap.has(city) ? cityMasterMap.get(city) : city;
  let pendingPaymentLink =
    bookingStatus === BOOKING_STATUS_ENUM.CREATED
      ? pendingPaymentStatus?.PendingPayments?.[0]?.paymentFlowDetails?.link
      : "";

  let pastDueDate = false;
  const dueDateTimeStamp = bookingDetailsSection?.nextPaymentDate;
  const currentTimestamp = new Date().valueOf();
  pastDueDate = currentTimestamp > dueDateTimeStamp;

  const linkClickHandler = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const navigateToStepHandler = (step: string | number) => {
    if (step)
      resetMultistepFormRef.current &&
        resetMultistepFormRef.current.visitStep("planDetails");
  };
  // Dashboard Details
  let bookingDashboardArr = [
    {
      displayKey: "Plan Selected :",
      visitStepData: {
        step: 2,
        value: plan?.displayName,
      },
      clickHandler: navigateToStepHandler,
    },
    {
      displayKey: "Last Payment date :",
      value: bookingDetailsSection?.lastPaymentDate
        ? dateFormat(bookingDetailsSection?.lastPaymentDate)
        : "N/A",
    },
    {
      displayKey: "Next Payment date :",
      value: bookingDetailsSection?.nextPaymentDate
        ? dateFormat(bookingDetailsSection?.nextPaymentDate)
        : "N/A",
    },
    {
      displayKey: "Payment Status :",
      // value: bookingStatus,
      color: bookingDetailsSection?.nextPaymentDate
        ? pastDueDate
          ? DisplayPaymentStatusColorCode.PENDING
          : DisplayPaymentStatusColorCode.PENDING
        : colors.text_black,
      visitStepData: {
        step: 2,
        value: bookingDetailsSection?.nextPaymentDate
          ? pastDueDate
            ? "Overdue"
            : "Pending"
          : "N/A",
      },
      clickHandler: navigateToStepHandler,
    },
  ];
  // Secondary Details
  let bookingdescriptionArr = [
    {
      displayKey: "Booking ID :",
      value: bookingDetailsSection?.id,
    },
    {
      displayKey: "Total Monthly Subscription fee :",
      value: getFormattedINR(bookingDetailsSection?.totalFee),
    },
    {
      displayKey: "Paid Monthly Subscription Amt :",
      value: getFormattedINR(bookingDetailsSection?.paidFee),
    },
    {
      displayKey: "Remaining Amount :",
      value: getFormattedINR(bookingDetailsSection?.remainingFee),
    },
    {
      displayKey: "Remaining Duration :",
      value: bookingDetailsSection?.remainingDuration + " months",
    },
    {
      displayKey: "Date Of Booking :",
      value: dateFormat(bookingDetailsSection?.bookingDate),
    },
    {
      displayKey: "Booking Type :",
      value: bookingType,
    },
    {
      displayKey: "Subscription Start Date :",
      value: dateFormat(bookingDetailsSection?.startDate),
    },
    {
      displayKey: "Subscription End Date :",
      value: dateFormat(bookingDetailsSection?.endDate),
    },
    {
      displayKey: "Mode Of Delivery :",
      value: bookingDetailsSection?.deliveryDetails?.mode
        ? DISPLAY_DELIVERY_MODES[
            bookingDetailsSection?.deliveryDetails
              ?.mode as keyof typeof DISPLAY_DELIVERY_MODES
          ]
        : "",
    },
    {
      displayKey: "Warehouse :",
      value: warehouseAdd,
    },
    {
      displayKey: "Pickup Address :",
      value:
        getDeliveryAddress(bookingDetailsSection?.deliveryDetails?.address) ??
        "N/A",
    },
    // Show only in Booking CREATED State
    pendingPaymentLink
      ? {
          displayKey: "Payment Link :",
          link: pendingPaymentLink,
          clickHandler: linkClickHandler,
        }
      : null,
  ];

  return (
    <Fade>
      {bookingDetailsloader ? (
        <BookingDetailLoader />
      ) : (
        <Box padding={"24px 24px 32px 24px"}>
          {/* BRIEF SUMMARY */}
          <Box className={styles.bookingDetailsBox}>
            {bookingDashboardArr?.length > 0 &&
              bookingDashboardArr?.map((data: any) => {
                return (
                  <HeadingValueTypography
                    heading={data?.displayKey}
                    value={data?.value}
                    link={data?.link}
                    visitStepData={data?.visitStepData}
                    clickHandler={data?.clickHandler}
                    color={data?.color}
                  />
                );
              })}
          </Box>

          {/* DESCRIPTION */}
          <Stack
            direction="row"
            flexWrap="wrap"
            gap="20px"
            padding="20px 0"
            className="detailDescription"
          >
            {bookingdescriptionArr?.length > 0 &&
              bookingdescriptionArr?.map((data: any) => {
                return (
                  <HeadingValueTypography
                    heading={data?.displayKey}
                    value={data?.value}
                    link={data?.link}
                    elementClassList={styles.flexedItems}
                    clickHandler={data?.clickHandler}
                  />
                );
              })}
          </Stack>
        </Box>
      )}
    </Fade>
  );
};

export default BookingDetailStep;
