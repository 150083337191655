import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../../../../assets/exportImages";
import { SelectTabs } from "../../../../components/SelectTab";
import { RootState } from "../../../../redux/reducers";
import { colors } from "../../../../themes/colors";
import "../data/style.css";
import "./styles.css";

interface IObjectKeys {
  [key: string]: string | number | any;
}
interface CreateTaskFields extends IObjectKeys {
  imgIcon: string;
  taskName: string;
  OnEdit: () => void;
  itemList: any;
}

const TableView = ({
  imgIcon,
  taskName,
  OnEdit,
  onCreate,
  itemList,
}: CreateTaskFields) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { roleManagement, global, taskManagement } = locale;
  const [selectStartRide, setSelectStartRide] = useState<any>({
    activeTab: 0,
  });

  const calculateTotalSLA = (tasks: any) => {
    return tasks.reduce((totalSLA: any, task: any) => totalSLA + task.sla, 0);
  };

  const totalSLA = useMemo(() => calculateTotalSLA(itemList), [itemList]);
  //   const memoizedCalculateTotalSLA = useCallback(() => calculateTotalSLA(itemList), [itemList]);

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        style={{
          width: "100%",
          padding: "20px 20px 20px",
          boxShadow: `0px 6px 12px ${colors.card_box_shadow}`,
          border: `1px solid ${colors.card_border_color}`,
          borderRadius: "8px",
        }}
      >
        <Grid
          style={{
            padding: "0px 20px 0px",
            // alignItems: "center",
            // justifyContent: "flex-start",
            gap: "10px",
            display: "flex",
          }}
        >
          <Grid
            item
            xs={1}
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <img src={imgIcon} style={{ height: "14px", width: "14px" }} />
          </Grid>
          <Grid
            item
            xs={9}
            // style = {{whiteSpace: "nowrap"}}
            style={{
              alignItems: "center",
              justifyContent: "start",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Typography
              style={{
                color: colors.card_text,
                fontSize: "1em",
                fontFamily: "EuclidCircularB-SemiBold",
                textTransform: "uppercase",
                letterSpacing: "0px",
              }}
            >
              {taskName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            // style={{
            //   alignItems: "center",
            //   justifyContent: "flex-end",
            //   display: "flex",
            // }}
          >
            {/* {itemList && itemList.length> 0 ?    */}
            <img
              src={EditIcon}
              style={{ height: "14px", width: "14px", cursor: "pointer" }}
              onClick={OnEdit}
            />
            {/* :   <FontAwesomeIcon
     onClick={onCreate}
      icon={faPlusCircle}
      color={colors.chip_text_colorG}
      style={{ width: 20, height: 20 , cursor: "pointer" }}
     /> } */}
          </Grid>
        </Grid>
        <SelectTabs
          width={"100%"}
          setActiveTab={(i: any) =>
            setSelectStartRide((prev: any) => ({ ...prev, activeTab: i }))
          }
          tabsList={itemList}
          defaultActive={selectStartRide.activeTab}
        />
        <Typography
          style={{
            color: colors.card_text,
            fontSize: "14px",
            fontFamily: "EuclidCircularB-SemiBold",
            textTransform: "uppercase",
            letterSpacing: "0px",
            textAlign: "center",
          }}
        >
          SLA {totalSLA} mins
        </Typography>
      </Box>
    </Grid>
  );
};

export default TableView;
