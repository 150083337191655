import { BASE_URL, customAxios, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

// Creates a new surge using the provided payload.
const createSurge = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().createSurge}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch surge list based on the provided payload.
const getSurgeList = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getSurgeList}`;
    const urlWithParams: string = constructUrl(url, payload);
    return customAxios.get(urlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Updates the active/inactive status of a surge based on the provided payload.
const updateSurgeStatus = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().updateSurgeStatus}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch the vehicle model list by branches based on the provided payload.
const getModelListByBranches = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getModelListByBranches}`;
    const urlWithParams: string = constructUrl(url, payload);
    return customAxios.get(urlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch the surge details based on the provided surgeId.
const getSurgeById = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(payload).getSurgeById}`;
    return customAxios.get(url).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Updates/edit the details of a surge based on the provided payload.
const updateSurge = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().updateSurge}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

export default {
  createSurge,
  getSurgeList,
  updateSurgeStatus,
  getModelListByBranches,
  getSurgeById,
  updateSurge,
};
