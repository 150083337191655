import React, { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  BasicSelect,
  TaskManagementSelect,
} from "../../../components/dropDown";
import { Input1, Input2 } from "../../../components/input";
import StickyHeaderTable from "../../../components/stickeyHeader";
import { Heading } from "../../../components/typography";
import { RootState } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Input3, Input5 } from "../../../components/input";
import { colors } from "../../../themes/colors";
import { SelectDropDown } from "../../../components/SelectDropDown";
import { Button1, Button3 } from "../../../components/button";
import { DragDataJson } from "./data/dragData";
import CardView from "./component/CardView";
import { actions } from "../../../redux";
import {
  faBars,
  faEdit,
  faHamburger,
  faTrash,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadCrumbs from "../../../components/breadCumbs";
import {
  GreenIcon,
  FinishIcon,
  ServicesIcon,
  OtherIcon,
  RemoveIcon,
} from "../../../assets/exportImages";
import RideCard from "./component/RideCard";
import { LocalStorage, UserTypes } from "../../../constants/enum";
import { getData, storeData } from "../../../config/filterStorage";
import "../../../components/components.css";
import { Translate } from "@mui/icons-material";
import { rentalActions } from "../../redux/actions";
import CustomSelect from "../../../sharedComponents/atoms/InputFields/InputSelect";
import { getSortedArrayOfObjects } from "../../utils/helper";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import { updateBreadcrumbs } from "../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../constants/activeModule";
// import "./data/style.css";

interface IObjectKeys {
  [key: string]: string | number;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
}

const DragDropTaskSequence = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const params: any = useParams();

  const scrollableDivRef = useRef<any>(null);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  /* const { allCitiesData, branches } = useSelector(
    (state: RootState) => state.commonReducer
  ); */
  const { allCitiesDetails, branches } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const {
    taskList,
    taskloader,
    alltaskList,
    totalCount,
    tasklistById,
    loading,
    subtaskLoading,
    isWarehousetaskAdded,
    taskSuggestion,
  } = useSelector((state: RootState) => state.taskReducer);

  const hasMore = taskList.length > 0;

  const [taskSearchValue, setTaskSearch] = useState<String>("");

  const [currentPage, setCurrentPage] = useState(1);

  const { global, taskManagement } = locale;

  const [masterTaskIds, setmasterTaskIds] = useState<any>([]);

  const [cardDragSuggestData, setCardDragSuggestData] =
    useState<any>(DragDataJson);
  // const [selectStartRide, setSelectStartRide] = useState<any>({
  //   id: null,
  //   name: "Select Warehouse",
  // });

  const [warehouseSelect, setWarehouseSelect] = useState<any>({
    name: "",
    displayName: "Select City",
  });

  const [warehouseSelectCity, setWarehouseSelectCity] = useState<any>({
    name: "",
    displayName: "Select Hub",
  });

  const [elements, setElements] = useState<any>({
    verticalArray: [],
    horizontalArray: [],
    suggestedArray: [],
  });

  // const [active, setActive] = useState<number>(selectStartRide.name || 0);

  // useEffect(() => {
  //   setActive(selectStartRide.name || 0);
  // }, [selectStartRide.name]);

  // const handleTabChange = (i: number) => {
  //   setActive(i);
  //   // setActiveTab(i);
  //   setSelectStartRide(i);
  // };

  const onChange = (e: any) => {
    const value = e.target.value;
    setTaskSearch(value);
  };

  const handleBranch = (branch: any) => {
    setWarehouseSelect({
      displayName: branch?.displayName,
      name: branch?.name,
    });
  };

  const handleCity = (city: any) => {
    setWarehouseSelectCity({
      name: city.name,
      displayName: city?.displayName,
    });
    setWarehouseSelect({
      name: "",
      displayName: "Select Hub",
    });
    if (city.name) {
      dispatch(rentalActions.getAllBranches({ name: city.name }));
    }
  };

  useEffect(() => {
    if (warehouseSelect?.name && params.id) {
      dispatch(
        actions.getTaskWarehouseList({
          warehouseId: warehouseSelect?.name,
          taskType:
            params.id === "1"
              ? "startRide"
              : params?.id === "2"
              ? "completeRide"
              : params?.id === "3"
              ? "service"
              : params?.id === "4"
              ? "others"
              : "",
        })
      );
    }
  }, [warehouseSelect?.name]);

  useEffect(() => {
    const masterTaskIds = elements.verticalArray.map((item: any) => item?._id);

    setmasterTaskIds(masterTaskIds);
  }, [elements.verticalArray]);

  const onSubmit = () => {
    dispatch(
      actions.editTaskSequence(
        { masterTaskIds: masterTaskIds },
        warehouseSelect?.name,
        params.id === "1"
          ? "startRide"
          : params?.id === "2"
          ? "completeRide"
          : params?.id === "3"
          ? "service"
          : "others"
      )
    );
  };

  useEffect(() => {
    if (isWarehousetaskAdded) {
      navigate(-1);
    }
  }, [isWarehousetaskAdded]);

  useEffect(() => {
    /*  dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    ); */
  }, []);

  useEffect(() => {
    getData(LocalStorage.Task_Management_Details).then((data: any) => {
      const taskData = JSON.parse(data ?? {});

      if (taskData) {
        let obj: any = taskData?.city;

        if (location?.state?.warehouseSelectCity) {
          obj = location?.state?.warehouseSelectCity;
          dispatch(
            rentalActions.getAllBranches({
              name: location?.state?.warehouseSelectCity?.name,
            })
          );

          /*  dispatch(
            actions.getAllBranches({
              cityId: location?.state?.warehouseSelectCity?.name,
            })
          ); */
        }
        if (location?.state?.warehouseSelect) {
          setWarehouseSelect(location?.state?.warehouseSelect);
        } else {
          setWarehouseSelect({
            name: "",
            displayName: "Select Hub",
          });
        }

        setWarehouseSelectCity(obj);
      }
    });
  }, []);

  useEffect(() => {
    let data = {
      // branch : warehouseSelect,
      city: location?.state?.warehouseSelectCity
        ? location?.state?.warehouseSelectCity
        : warehouseSelectCity,
      branch: location?.state?.warehouseSelect
        ? location?.state?.warehouseSelect
        : warehouseSelect,
    };
    storeData(LocalStorage.Task_Management_Details, data);
  }, [
    // warehouseSelect.name,
    warehouseSelectCity.name,
  ]);

  useEffect(() => {
    if (tasklistById) {
      const sortedVerticalArray = tasklistById.sort(getSortedArrayOfObjects);

      setElements((prevElements: any) => ({
        ...prevElements,
        verticalArray: sortedVerticalArray,
      }));
    }
  }, [tasklistById]);

  function filterItemsNotInArrayA(arrayA: any, arrayB: any) {
    // Create a set from arrayA for faster lookup
    const setA = new Set(arrayA);

    // Create an empty array to store the filtered result
    const filteredResult = [];

    // Iterate through the elements of Array B
    for (const item of arrayB) {
      // Check if the item is present in Set A (which represents arrayA)
      if (!setA.has(item)) {
        // If the item is not in Set A, add it to the filtered result
        filteredResult.push(item);
      }
    }

    // Return the filteredResult array containing items that are in Array B but not in Array A
    return filteredResult;
  }

  // useEffect(() => {
  //   if (alltaskList || taskList || tasklistById) {

  //     // const newVerticalArray = taskList.filter(
  //     //   (item2: any) =>
  //     //     !tasklistById.some((item1: any) => item1?._id === item2?._id)
  //     // );
  //     const newHorizontalArray = alltaskList.filter(
  //       (item2: any) =>
  //         !elements?.verticalArray.some((item1: any) => item1?._id === item2?._id)
  //     );
  //     let newSuggestedArray = alltaskList?.filter(
  //       (item2: any) =>
  //         !elements?.verticalArray.some((item1: any) => item1?._id === item2?._id)
  //     );
  //     newSuggestedArray=   newSuggestedArray.slice(0,2)

  //     setElements((prevElements: any) => ({
  //       ...prevElements,
  //       // horizontalArray: taskSearchValue
  //       //   ? newVerticalArray
  //       //   : newHorizontalArray,
  //       horizontalArray:newHorizontalArray,
  //       suggestedArray : newSuggestedArray
  //     }));
  //   }
  // }, [alltaskList, taskList, tasklistById, elements?.verticalArray]);

  useEffect(() => {
    if (alltaskList && (taskList || tasklistById)) {
      const existingIds = new Set(
        elements?.verticalArray.map((item1: any) => item1._id)
      );

      const newHorizontalArray = alltaskList.filter(
        (item2: any) => !existingIds.has(item2._id)
      );

      // Slice the newHorizontalArray to create the suggestedArray (first 2 items)
      const newSuggestedArray = taskSuggestion.filter(
        (item2: any) => !existingIds.has(item2._id)
      );

      setElements((prevElements: any) => ({
        ...prevElements,
        horizontalArray: newHorizontalArray,
        suggestedArray: newSuggestedArray,
      }));
    }
  }, [alltaskList, taskList, tasklistById, elements?.verticalArray]);

  useEffect(() => {
    setWarehouseSelectCity(location?.state?.warehouseSelectCity);
    setWarehouseSelect(location?.state?.warehouseSelect);
  }, [location?.state?.warehouseSelectCity, location?.state?.warehouseSelect]);

  // const SearchValue = () => {
  //   if (taskSearchValue.length > 1) {
  //     const filtered = horizontalItems.filter((obj: any) => {
  //       return obj.taskName === taskSearchValue;
  //     });
  // };

  const removeFromList = (list: any, index: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list: any, index: any, element: any) => {
    const result = Array.from(list);
    if (!result.includes(element)) {
      result.splice(index, 0, element);
    }
    return result;
  };
  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // Check if there is a valid drop destination
    if (!destination) {
      return;
    }
    if (
      (destination?.droppableId == "horizontalArray" &&
        source?.droppableId == "suggestedArray") ||
      (destination?.droppableId == "suggestedArray" &&
        source?.droppableId == "horizontalArray")
    ) {
      return;
    }

    const listCopy = { ...elements };

    const sourceList = listCopy[source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      source.index
    );

    listCopy[source.droppableId] = newSourceList;

    const destinationList = listCopy[destination.droppableId];

    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      destination.index,
      removedElement
    );

    setElements(listCopy);

    // setElements((prevElements:any) => ({
    //   ...prevElements,
    //   horizontalArray: listCopy?.horizontalArray,
    //   verticalArray: listCopy?.verticalArray
    // }));

    // setElements((prev:any)=>{
    //  ...prev,
    // });
    // const items = Array.from(verticalItems);
    // const [reorderedItem] = items.splice(source.index, 1);
    // items.splice(destination.index, 0, reorderedItem);
    // setVerticalItems(items);
  };

  const OnDelete = (itemId: any) => {
    setElements((prevElements: any) => {
      // Find the item to delete in verticalArray
      const itemToDelete = prevElements.verticalArray.find(
        (item: any) => item._id === itemId
      );

      if (!itemToDelete) {
        return prevElements; // Item not found, return the current state
      }

      // Filter out the itemToDelete from verticalArray
      const updatedVerticalArray = prevElements.verticalArray.filter(
        (item: any) => item._id !== itemId
      );
      const sortedUpdatedVerticalArray = updatedVerticalArray.sort(
        getSortedArrayOfObjects
      );

      // Add the itemToDelete to the end of horizontalArray
      const updatedHorizontalArray = [
        ...prevElements.horizontalArray,
        itemToDelete,
      ];
      const updatedSuggestedArray = [
        ...prevElements.suggestedArray,
        itemToDelete,
      ];

      // Return the updated state
      return {
        verticalArray: sortedUpdatedVerticalArray,
        horizontalArray: updatedHorizontalArray,
        suggestedArray: updatedSuggestedArray,
      };
    });
  };

  const addItemVerticalList = (itemId: any) => {
    setElements((prevElements: any) => {
      const itemToAdd = prevElements.horizontalArray.find(
        (item: any) => item._id === itemId
      );

      if (!itemToAdd) {
        return prevElements;
      }
      const updatedHorizontalArray = prevElements.horizontalArray.filter(
        (item: any) => item._id !== itemId
      );
      const updatedSuggestedArray = prevElements.suggestedArray.filter(
        (item: any) => item._id !== itemId
      );

      const updatedVerticalArray = [...prevElements.verticalArray, itemToAdd];
      const sortedUpdatedVerticalArray = updatedVerticalArray.sort(
        getSortedArrayOfObjects
      );
      return {
        verticalArray: sortedUpdatedVerticalArray,
        horizontalArray: updatedHorizontalArray,
        suggestedArray: updatedSuggestedArray,
      };
    });
  };

  useEffect(() => {
    if (taskSearchValue === "") {
      setCurrentPage(1);
    }
  }, [taskSearchValue]);

  useEffect(() => {
    return;
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollTop, clientHeight, scrollHeight } =
          scrollableDivRef.current;

        // Check if the user has scrolled to the bottom
        const tolerance = 1;

        if (
          scrollTop + clientHeight + tolerance >= scrollHeight &&
          !taskloader
        ) {
          // SetCurrent Page
          if (hasMore) setCurrentPage((prev) => prev + 1);
        }
      }
    };

    // const delayedHandleScroll = () => {
    //   setTimeout(handleScroll, 0); // Delay execution by 0ms
    // };

    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [alltaskList]);

  useEffect(() => {
    if (taskSearchValue) {
      dispatch(actions.getTaskList({ search: taskSearchValue }));
    } else {
      dispatch(actions.getTaskList({ page: currentPage, size: 1000 }));
    }
  }, [currentPage, taskSearchValue]);

  useEffect(() => {
    dispatch(actions.getTaskSuggestion());

    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.DRAG_TASK_SEQUENCE(params.id)));

  }, []);

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          {/* Left part */}
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            style={{ backgroundColor: colors.task_background_color }}
          >
            <Box style={{ width: "100%", padding: "10px 20px 40px 10px" }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                style={{
                  fontSize: isDesktopOrTablet ? "18px" : "14px",
                  fontFamily: "EuclidCircularB-SemiBold",
                  color: colors.heading_main_text,
                  marginBottom: 10,
                }}
              >
                {taskManagement.TaskSequence}
              </Typography>
              {/* <BreadCrumbs
                path={`/warehouse-management`}
                firstLabel={taskManagement.Warehouse}
                secondLabel={taskManagement.TaskSequenceSmall}
              /> */}
              <Typography
                variant="h5"
                component="p"
                style={{
                  fontSize: "13px",
                  fontFamily: "EuclidCircularB-Regular",
                  color: colors.card_body_text,
                  marginBottom: 10,
                  marginTop: 10,
                  width: "90%",
                }}
              >
                {taskManagement.TaskDropSubHeadingPara}
              </Typography>

              {/* Select city & warehouse */}
              <Box
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  width: "90%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: isDesktopOrTablet ? "column" : "column",
                }}
              >
                {/* Select City */}
                <CustomSelect
                  required
                  heading={"Select City"}
                  placeholder={"Select City"}
                  value={warehouseSelectCity?.displayName}
                  choice={allCitiesDetails?.length > 0 ? allCitiesDetails : []}
                  defaultKey={"displayName"}
                  sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
                  handleChange={(city: any) => {
                    handleCity(city);
                  }}
                />

                {/* Select Warehouse */}
                <CustomSelect
                  required
                  heading={"Select Hub"}
                  placeholder={"Select Hub"}
                  value={warehouseSelect?.displayName}
                  choice={branches?.length > 0 ? branches : []}
                  defaultKey={"displayName"}
                  sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
                  handleChange={(branch: any) => {
                    handleBranch(branch);
                  }}
                />
              </Box>

              {/* Task sequence Area */}
              <Box
                style={{
                  marginTop: 35,
                  width: "93%",
                }}
              >
                <Box
                  style={{
                    width: "85%",
                  }}
                >
                  <Grid
                    container
                    style={{ paddingLeft: "7%", marginBottom: 24 }}
                  >
                    {/* Booking Ation Title */}
                    <Grid item xs={9}>
                      <Typography
                        style={{
                          color: colors.card_text,
                          fontSize: "18px",
                          fontFamily: "EuclidCircularB-SemiBold",
                        }}
                      >
                        <img
                          src={GreenIcon}
                          style={{ height: "14px", width: "14px" }}
                        />{" "}
                        {params.id === "1"
                          ? "Start Ride"
                          : params?.id === "2"
                          ? "End Ride"
                          : params?.id === "3"
                          ? "Service"
                          : "Others"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      overflow: "hidden",
                      padding: isDesktopOrTablet ? "0px 15px" : "0px 0px",
                    }}
                  >
                    <div>
                      <Droppable droppableId="verticalArray">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {subtaskLoading ? (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  position: "relative",
                                  left: "40%",
                                }}
                              >
                                <CircularProgress
                                  color="inherit"
                                  size={30}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                {elements.verticalArray.length > 0 ? (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {elements?.verticalArray?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <Draggable
                                            key={item._id}
                                            draggableId={item._id}
                                            index={index}
                                            isDragDisabled={
                                              item?.isCompulsory ||
                                              item?.priority !== 0
                                            }
                                          >
                                            {(provided) => (
                                              <div
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                key={index}
                                                onMouseDown={(event) =>
                                                  event.stopPropagation()
                                                }
                                              >
                                                <RideCard
                                                  item={item}
                                                  // handleTabChange={() =>
                                                  //   handleTabChange(item?._id)
                                                  // }
                                                  subtaskLoading={false}
                                                  OnDelete={() =>
                                                    OnDelete(item?._id)
                                                  }
                                                  disableDeleteOperation={
                                                    item?.isCompulsory ||
                                                    item?.priority !== 0
                                                  }
                                                />
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    )}
                                    {provided.placeholder}
                                  </div>
                                ) : (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    Please Add Tasks
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </Box>

                  <Box
                    style={{
                      width: "80%",
                      marginTop: 25,
                      marginLeft: 20,
                      zIndex: -1,
                    }}
                  >
                    <CustomButton
                      label={taskManagement.TaskDone}
                      onClick={onSubmit}
                      disabled={elements?.verticalArray?.length === 0}
                      loading={loading}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right part */}
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            style={{
              boxShadow: "-2px 0px 8px #2BC7C91A",
              paddingTop: "52px",
              paddingLeft: "64px",
            }}
          >
            <div>
              <Box style={{ width: "100%" }}>
                <Typography
                  style={{
                    color: colors.heading_main_text,
                    fontSize: "16px",
                    fontFamily: "EuclidCircularB-Medium",
                  }}
                >
                  Drag and drop or Select To add to the tasklist
                </Typography>
                <div
                  style={{
                    marginBottom: 32,
                    marginTop: 30,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Input5
                    onChange={onChange}
                    value={taskSearchValue}
                    placeholder={global.SearchTaskName}
                    width={280}
                  />
                </div>
                <div>
                  <div
                    className="dragDropRightSideScroll"
                    id="dragDropRightSideScroll"
                    ref={scrollableDivRef}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginBottom: 15,
                      flexWrap: "wrap",
                      width: "100%",
                      // justifyContent: "center",
                      //  alignItems: "center"
                    }}
                  >
                    {elements?.horizontalArray.length > 0 ? (
                      <Droppable droppableId="horizontalArray">
                        {(provided) => (
                          <div
                            // className="horizontal-list"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row",
                              gap: "12px",
                              width: "100%",
                            }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {taskloader ? (
                              <div
                                style={{
                                  position: "relative",
                                  top: "50%",
                                  left: "50%",
                                  height: "0px",
                                }}
                              >
                                <CircularProgress
                                  color="inherit"
                                  size={50}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              </div>
                            ) : (
                              elements?.horizontalArray &&
                              elements?.horizontalArray?.map(
                                (item: any, index: any) => (
                                  <Draggable
                                    key={item._id + "c"}
                                    draggableId={item._id + "c"}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        // className="item horizontal-item"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        key={index}
                                      >
                                        <CardView
                                          id={item._id}
                                          taskName={item.taskName}
                                          reading={item.taskDescription}
                                          sla={item.sla}
                                          imageRequired={item.imageRequired}
                                          remarkRequired={item.remarkRequired}
                                          doubleClick={() =>
                                            addItemVerticalList(item?._id)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ) : (
                      <Droppable droppableId="horizontalArray">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            No Subtask Found
                          </div>
                        )}
                      </Droppable>
                    )}
                  </div>
                </div>
                <Typography
                  style={{
                    color: colors.card_body_text,
                    fontSize: "15px",
                    fontFamily: "EuclidCircularB-Medium",
                    marginBottom: 32,
                  }}
                >
                  SUGGESTED TASKS TO ADD
                </Typography>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 15,
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {cardDragSuggestData
                    .slice(0, 2)
                    .map((item: any, index: number) => (
                      <div>
                        <CardView
                          id={item.id}
                          taskName={item.taskName}
                          reading={item.reading}
                          sla={item.sla}
                          imageRequired={item.imageRequired}
                          remarkRequired={item.reviewRequired}
                        />
                      </div>
                    ))}
                </div> */}

                <div>
                  <div
                    className="dragDropRightSideScroll"
                    id="dragDropRightSideScroll"
                    ref={scrollableDivRef}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginBottom: 15,
                      flexWrap: "wrap",
                      width: "100%",
                      // justifyContent: "center",
                      //  alignItems: "center"
                    }}
                  >
                    {elements?.suggestedArray.length > 0 ? (
                      <Droppable droppableId="suggestedArray">
                        {(provided) => (
                          <div
                            // className="horizontal-list"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row",
                              gap: "12px",
                              width: "100%",
                            }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {taskloader ? (
                              <div
                                style={{
                                  position: "relative",
                                  top: "50%",
                                  left: "50%",
                                  height: "0px",
                                }}
                              >
                                <CircularProgress
                                  color="inherit"
                                  size={50}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              </div>
                            ) : (
                              elements?.suggestedArray &&
                              elements?.suggestedArray?.map(
                                (item: any, index: any) => (
                                  <Draggable
                                    key={item._id + "s"}
                                    draggableId={item._id + "s"}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        // className="item horizontal-item"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        key={index}
                                      >
                                        <CardView
                                          id={item._id}
                                          taskName={item.taskName}
                                          reading={item.taskDescription}
                                          sla={item.sla}
                                          imageRequired={item.imageRequired}
                                          remarkRequired={item.remarkRequired}
                                          doubleClick={() =>
                                            addItemVerticalList(item?._id)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        No Suggested Subtask Found{" "}
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </DragDropContext>
    </Box>
  );
};

export default DragDropTaskSequence;
