import { useEffect, useState } from "react";
import "../../css/customcss/font.css";
import { AuthButton } from "./components/authButton";
import { AuthContainer } from "./components/authContainer";
import { ContainerMsg } from "./components/authContainerMsg";
import { AuthInput } from "./components/authInputs";
import "./newAuthCss/global.css";
import "./newAuthCss/layout.css";

import { useDispatch, useSelector } from "react-redux";
import { AuthUsecases } from "../../constants/enum";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../rentals-src/utils/helper";

function UnlockAccount() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { unlockAccountLoader, isAccountUnlockLinkSent } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  const navigate = useNavigate();

  // navigate to login page if unlock account success
  useEffect(() => {
    if (isAccountUnlockLinkSent) setTimeout(() => navigate("/"), 3000);
  }, [isAccountUnlockLinkSent]);

  const [field, setField] = useState<string>("");
  const [errField, setErrField] = useState<string>("");
  const dispatch = useDispatch();

  // set email address
  const onChange = (e: any) => {
    const { value } = e.target;
    const validate = validateEmail(value);
    if (validate?.status || !value) {
      setErrField("");
    } else {
      setErrField(validate.error);
    }
    setField(value);
  };

  // validate and api call for unlock account
  const validate = () => {
    if (field) {
      dispatch(
        actions.accountUnlock({
          data: {
            userName: field,
          },
        })
      );
    }
  };

  return (
    <AuthContainer
      heading={locale?.newAuth?.unlockAccount}
      componentFields={
        <>
          {isAccountUnlockLinkSent ? (
            <ContainerMsg message={locale?.newAuth?.unlockAccountContMsg} />
          ) : null}
          <AuthInput
            label={locale?.newAuth?.emailUsername}
            placeholder={locale?.newAuth?.enterUsername}
            className={"form-control"}
            value={field}
            errorMessage={errField}
            onChange={onChange}
          />
          <AuthButton
            type="submit"
            title={locale?.newAuth?.unlockAccount}
            loading={unlockAccountLoader}
            /*  className={
              field !== ""
                ? "btn w-100 login-btn mt-3"
                : "btn w-100 login-btn disable mt-3"
            } */
            onClick={validate}
            disabled={!field || errField}
          />
          {/* {showMessage ? (
            <MessagePopUp
              className={success && !error ? "pw-sucess" : "pw-error"}
              message={message}
              onClick={() => {
                dispatch(actions.showMessagePopUp({ showMessage: false }));
              }}
            />
          ) : null} */}
        </>
      }
    />
  );
}
export default UnlockAccount;
