import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomMultiSelect from "../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomModal from "../../../../sharedComponents/molecules/Modals/CustomModal";
import {
  GenericObject,
  ReportType,
  StatusArray,
  TotalBookingsOpt,
  paymentStatusArray,
  typographyConstants,
} from "../../../constants/constants";
import {
  CityInputIcon,
  MobileIcon,
  WarehouseIcon,
  changeStatusIcon,
  fadedCalenderIcon,
} from "../../../constants/exportImages";
import { getAllBranchesByCity } from "../../../redux/actions/rentalCommonAction";
import styles from "../section.module.css";
import { generateReport } from "../../../redux/actions/reportsActions";
import {
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  restrictZeroInput,
  validateOnly,
} from "../../../utils/helper";
import rentalEn from "../../../locale/rental-en.json";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import { checkAlfaNumeric, checkNumeric } from "../../../utils/regex";
import SearchBar from "../../../../sharedComponents/atoms/InputFields/searchBar";
import { debounce, isArrayNotEmpty } from "../../../../utils/helper";
import { vehicleActions } from "../../../redux/actions";
import {
  modules,
  reportsFunctionality,
} from "../../../../constants/RBACModuleEnums";
import RbacHelper from "../../../../utils/helperRBAC";

const trustScoreArr = [
  {
    name: 10,
    displayName: "Between 0 - 10",
  },
  {
    name: 20,
    displayName: "Between 0 - 20",
  },
  {
    name: 30,
    displayName: "Between 0 - 30",
  },
  {
    name: 40,
    displayName: "Between 0 - 40",
  },
  {
    name: 50,
    displayName: "Between 0 - 50",
  },
  {
    name: 60,
    displayName: "Between 0 - 60",
  },
  {
    name: 70,
    displayName: "Between 0 - 70",
  },
  {
    name: 80,
    displayName: "Between 0 - 80",
  },
  {
    name: 90,
    displayName: "Between 0 - 90",
  },
  {
    name: 100,
    displayName: "Between 0 - 100",
  },
];

const totalBookingOperationsArr = [
  {
    name: TotalBookingsOpt.GT,
    displayName: "Greater than",
  },
  {
    name: TotalBookingsOpt.LT,
    displayName: "Less than",
  },
  {
    name: TotalBookingsOpt.LTEQ,
    displayName: "Less than Equal To",
  },
  {
    name: TotalBookingsOpt.GTEQ,
    displayName: "Greater than Equal To",
  },
  {
    name: TotalBookingsOpt.EQ,
    displayName: "Equal To",
  },
];

const totalBookingOperationsArrForZero = [
  {
    name: TotalBookingsOpt.GT,
    displayName: "Greater than",
  },

  {
    name: TotalBookingsOpt.GTEQ,
    displayName: "Greater than Equal To",
  },
  {
    name: TotalBookingsOpt.EQ,
    displayName: "Equal To",
  },
];

const bookingPaymentTypeArr = [
  {
    name: "MAIN",
    displayName: "Main",
  },
  {
    name: "EXTENSION",
    displayName: "Extension",
  },
];

//work in progress
const { global, reports } = rentalEn;

interface GenerateReport {
  status: GenericObject;
  hubs: GenericObject;
  cities: GenericObject;
  totalBookings: number | string;
  trustScoreRange: { name: string; displayName: string };
  bookingStartDateRange: {
    startDate: string | number;
    endDate: string | number;
  };
  bookingEndDateRange: { startDate: string | number; endDate: string | number };
  actualStartDateRange: {
    startDate: string | number;
    endDate: string | number;
  };
  actualEndDateRange: {
    startDate: string | number;
    endDate: string | number;
  };
  bookingCreated: { startDate: string | number; endDate: string | number };
  extensionDoneDate: { startDate: string | number; endDate: string | number };
  extentionStartDateRange: {
    startDate: string | number;
    endDate: string | number;
  };
  extentionEndDateRange: {
    startDate: string | number;
    endDate: string | number;
  };
  createdAtRange: { startDate: string | number; endDate: string | number };
  isDLVerified: string;
  isAadhaarVerified: boolean | any;
  aadhharPincode: GenericObject;
  dlPincode: GenericObject;
  chargesCreatedAt: { startDate: string | number; endDate: string | number };
  bookingID: string | number;
  bookingCreatedPayment: {
    startDate: string | number;
    endDate: string | number;
  };
  bookingPaymentType: GenericObject;
  bookingCreatedCancel: GenericObject;
  challanCreatedAtDate: GenericObject;
  vehicleChangeCreatedAtDate: GenericObject;
  selectedField: any;
  searchField: any;
  vehicleIdArray: any[];
  totalBookingsOp: {
    name: string;
    displayName: string;
  };
  challanCreatedAt: any;
  paymentStatus: GenericObject;
}

const initialState: GenerateReport = {
  bookingStartDateRange: {
    startDate: "",
    endDate: "",
  },
  bookingEndDateRange: {
    startDate: "",
    endDate: "",
  },
  actualStartDateRange: {
    startDate: "",
    endDate: "",
  },
  actualEndDateRange: {
    startDate: "",
    endDate: "",
  },
  bookingCreated: {
    startDate: "",
    endDate: "",
  },
  extensionDoneDate: {
    startDate: "",
    endDate: "",
  },
  extentionStartDateRange: {
    startDate: "",
    endDate: "",
  },
  extentionEndDateRange: {
    startDate: "",
    endDate: "",
  },
  createdAtRange: {
    startDate: "",
    endDate: "",
  },
  bookingCreatedPayment: {
    startDate: "",
    endDate: "",
  },
  totalBookings: "",
  trustScoreRange: {
    name: "",
    displayName: "",
  },
  isDLVerified: "",
  isAadhaarVerified: "",
  hubs: [],
  cities: [],
  status: [],
  paymentStatus: [],
  dlPincode: { start: "", end: "" },
  aadhharPincode: { start: "", end: "" },
  chargesCreatedAt: {
    startDate: "",
    endDate: "",
  },
  bookingID: "",
  bookingPaymentType: [],
  bookingCreatedCancel: {
    startDate: "",
    endDate: "",
  },
  challanCreatedAtDate: {
    startDate: "",
    endDate: "",
  },
  vehicleChangeCreatedAtDate: {
    startDate: "",
    endDate: "",
  },
  selectedField: "",
  searchField: "",
  vehicleIdArray: [],
  totalBookingsOp: {
    name: "",
    displayName: "",
  },
  challanCreatedAt: {
    startDate: "",
    endDate: "",
  },
};

const errorInitialState = {
  totalBookings: "",
};

export const GenerateReport = () => {
  const dispatch = useDispatch();

  const { allCitiesDetails, branchesByCities } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { reportsDownloaded, loading } = useSelector(
    (state: RootState) => state.reportsReducer
  );

  const { onLoad, vehiclesListData } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  const [fields, setFields] = useState({
    ...initialState,
  });

  const [errorFields, setErrorFields] = useState({
    ...errorInitialState,
  });
  const [openReportModal, setOpenReportModal] = useState({
    generateBookingReport: false,
    generateUserReport: false,
    generateExtentionReport: false,
    generateChallanReport: false,
    generateCalRefReport: false,
    generateExtraChargesReport: false,
    generatePaymentLTRReport: false,
    generateVehicleChangeReport: false,
  });

  // report type for modal array
  const reportTypeArr = [
    {
      heading: reports.generateBookingReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateBookingReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_BOOKING_REPORT
      ),
    },
    {
      heading: reports.generateExtentionReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateExtentionReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_EXTENSION_REPORT
      ),
    },
    {
      heading: reports.generateChallanReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateChallanReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_CHALLAN_REPORT
      ),
    },
    {
      heading: reports.generateCalRefReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateCalRefReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_CANCELLATION_AND_REFUND_REPORT
      ),
    },
    {
      heading: reports.generateUserReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateUserReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_USER_REPORT
      ),
    },
    {
      heading: reports.generateExtraChargesReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateExtraChargesReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_EXTRA_CHARGES_REPORT
      ),
    },
    {
      heading: reports.generatePaymentReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generatePaymentLTRReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_PAYMENT_REPORT
      ),
    },
    {
      heading: reports.generateVehicleChangeReport,
      onClick: () =>
        setOpenReportModal((prev: any) => ({
          ...prev,
          generateVehicleChangeReport: true,
        })),
      isRBACAccess: RbacHelper.isAccessRightsProvided(
        modules.REPORTS,
        reportsFunctionality.GENERATE_VEHICLE_MODEL_CHANGE_REPORT
      ),
    },
  ];

  //close modal
  const handleCloseReportModal = (field: string) => {
    setOpenReportModal((prevState: any) => ({ ...prevState, [field]: false }));
    setFields((prev: any) => ({ ...prev, ...initialState }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      ...errorInitialState,
    }));
  };

  const validateUserReport = (): boolean => {
    let error = false;

    if (!fields.totalBookingsOp?.name) {
      return error;
    }
    const validatedFields = validateOnly(fields, errorFields, [
      "totalBookings",
    ]);

    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });
      return error;
    }
    return error;
  };

  //generate report api
  const generateReportApi = (type: string) => {
    if (type === ReportType.USER) {
      const validateReport: boolean = validateUserReport();
      if (validateReport) {
        return;
      }
    }
    const payload = {
      type,
      data: {
        data: {
          ...(fields?.status?.length > 0 && { bookingStatus: fields.status }),
          ...(fields?.bookingStartDateRange?.endDate && {
            bookingStartDateTill: getTimestampAtEndOfDay(
              fields.bookingStartDateRange.endDate
            ),
          }),
          ...(fields?.bookingStartDateRange?.startDate && {
            bookingStartDateFrom: getTimestampAtStartOfDay(
              fields.bookingStartDateRange.startDate
            ),
          }),
          ...(fields?.hubs?.length > 0 && { branches: fields.hubs }),
          ...(fields?.cities?.length > 0 && { cities: fields.cities }),
          ...(fields?.actualStartDateRange?.endDate && {
            actualStartDateTill: getTimestampAtEndOfDay(
              fields.actualStartDateRange.endDate
            ),
          }),
          ...(fields?.actualStartDateRange?.startDate && {
            actualStartDateFrom: getTimestampAtStartOfDay(
              fields.actualStartDateRange.startDate
            ),
          }),
          ...(fields?.actualEndDateRange?.endDate && {
            actualEndDateTill: getTimestampAtEndOfDay(
              fields.actualEndDateRange.endDate
            ),
          }),
          ...(fields?.actualEndDateRange?.startDate && {
            actualEndDateFrom: getTimestampAtStartOfDay(
              fields.actualEndDateRange.startDate
            ),
          }),
          ...(fields?.bookingEndDateRange?.endDate && {
            bookingEndDateTill: getTimestampAtEndOfDay(
              fields.bookingEndDateRange.endDate
            ),
          }),
          ...(fields?.bookingEndDateRange?.startDate && {
            bookingEndDateFrom: getTimestampAtStartOfDay(
              fields.bookingEndDateRange.startDate
            ),
          }),

          ...(fields?.bookingCreated?.startDate && {
            bookingCreatedDateFrom: getTimestampAtStartOfDay(
              fields.bookingCreated?.startDate
            ),
          }),
          ...(fields?.bookingCreated?.endDate && {
            bookingCreatedDateTill: getTimestampAtEndOfDay(
              fields.bookingCreated?.endDate
            ),
          }),
          ...(fields?.extensionDoneDate?.startDate && {
            extensionDoneDateFrom: getTimestampAtStartOfDay(
              fields.extensionDoneDate?.startDate
            ),
          }),
          ...(fields?.extensionDoneDate?.endDate && {
            extensionDoneDateTill: getTimestampAtEndOfDay(
              fields.extensionDoneDate?.endDate
            ),
          }),
          ...(fields?.extentionStartDateRange?.startDate && {
            extensionStartDateFrom: getTimestampAtStartOfDay(
              fields.extentionStartDateRange?.startDate
            ),
          }),
          ...(fields?.extentionStartDateRange?.endDate && {
            extensionStartDateTill: getTimestampAtEndOfDay(
              fields.extentionStartDateRange?.endDate
            ),
          }),
          ...(fields?.extentionEndDateRange?.startDate && {
            extensionEndDateFrom: getTimestampAtStartOfDay(
              fields.extentionEndDateRange?.startDate
            ),
          }),
          ...(fields?.extentionEndDateRange?.endDate && {
            extensionEndDateTill: getTimestampAtEndOfDay(
              fields.extentionEndDateRange?.endDate
            ),
          }),
          ...(fields?.bookingCreatedPayment?.startDate && {
            createdAtFrom: getTimestampAtStartOfDay(
              fields.bookingCreatedPayment?.startDate
            ),
          }),
          ...(fields?.bookingCreatedPayment?.endDate && {
            createdAtTill: getTimestampAtEndOfDay(
              fields.bookingCreatedPayment?.endDate
            ),
          }),
          ...(fields?.bookingPaymentType?.length > 0 && {
            bookingPaymentType: fields.bookingPaymentType,
          }),

          ...(fields?.bookingID && {
            bookingId: fields?.bookingID,
          }),

          ...(fields?.chargesCreatedAt?.startDate && {
            bookingChargeCreatedDateFrom: getTimestampAtStartOfDay(
              fields.chargesCreatedAt.startDate
            ),
          }),

          ...(fields?.chargesCreatedAt?.endDate && {
            bookingChargeCreatedDateTill: getTimestampAtEndOfDay(
              fields.chargesCreatedAt.endDate
            ),
          }),
          ...(fields?.createdAtRange?.startDate && {
            createdAtFrom: getTimestampAtStartOfDay(
              fields.createdAtRange.startDate
            ),
          }),
          ...(fields?.createdAtRange?.endDate && {
            createdAtTill: getTimestampAtEndOfDay(
              fields.createdAtRange.endDate
            ),
          }),
          ...(openReportModal.generateUserReport && {
            adhaarDigitalverified:
              fields.isAadhaarVerified === global.yes
                ? true
                : fields.isAadhaarVerified === global.no
                ? false
                : undefined,
          }),

          ...(openReportModal.generateUserReport && {
            dlDigitalVerified:
              fields.isDLVerified === global.yes
                ? true
                : fields.isDLVerified === global.no
                ? false
                : undefined,
          }),

          ...(fields?.dlPincode?.start && !fields?.dlPincode?.end
            ? {
                dLPincodeFrom: parseInt(fields?.dlPincode?.start),
                dLPincodeTill: parseInt(fields?.dlPincode?.start),
              }
            : fields?.dlPincode?.start && fields?.dlPincode?.end
            ? {
                dLPincodeFrom: parseInt(fields?.dlPincode?.start),
                dLPincodeTill: parseInt(fields?.dlPincode?.end),
              }
            : !fields?.dlPincode?.start && fields?.dlPincode?.end
            ? {
                dLPincodeFrom: parseInt(fields?.dlPincode?.end),
                dLPincodeTill: parseInt(fields?.dlPincode?.end),
              }
            : {}),

          ...(fields?.aadhharPincode?.start && !fields?.aadhharPincode?.end
            ? {
                adhaarPincodeFrom: parseInt(fields?.aadhharPincode?.start),
                adhaarPincodeTill: parseInt(fields?.aadhharPincode?.start),
              }
            : fields?.aadhharPincode?.start && fields?.aadhharPincode?.end
            ? {
                adhaarPincodeFrom: parseInt(fields?.aadhharPincode?.start),
                adhaarPincodeTill: parseInt(fields?.aadhharPincode?.end),
              }
            : !fields?.aadhharPincode?.start && fields?.aadhharPincode?.end
            ? {
                adhaarPincodeFrom: parseInt(fields?.aadhharPincode?.end),
                adhaarPincodeTill: parseInt(fields?.aadhharPincode?.end),
              }
            : {}),

          ...(fields?.trustScoreRange?.name && {
            trustScore: fields?.trustScoreRange?.name,
          }),
          ...(fields?.totalBookings && {
            totalBookings: fields?.totalBookings,
          }),
          ...(fields?.totalBookingsOp?.name && {
            totalBookingOpt: fields?.totalBookingsOp?.name,
          }),

          ...(fields?.bookingCreatedCancel?.startDate && {
            createdAtFrom: getTimestampAtStartOfDay(
              fields.bookingCreatedCancel?.startDate
            ),
          }),
          ...(fields?.bookingCreatedCancel?.endDate && {
            createdAtTill: getTimestampAtEndOfDay(
              fields.bookingCreatedCancel?.endDate
            ),
          }),
          ...(fields?.challanCreatedAtDate?.startDate && {
            createdAtFrom: getTimestampAtStartOfDay(
              fields?.challanCreatedAtDate?.startDate
            ),
          }),
          ...(fields?.challanCreatedAtDate?.endDate && {
            createdAtTill: getTimestampAtEndOfDay(
              fields?.challanCreatedAtDate?.endDate
            ),
          }),
          ...(fields?.vehicleChangeCreatedAtDate?.startDate && {
            createdAtFrom: getTimestampAtStartOfDay(
              fields?.vehicleChangeCreatedAtDate?.startDate
            ),
          }),
          ...(fields?.vehicleChangeCreatedAtDate?.endDate && {
            createdAtTill: getTimestampAtEndOfDay(
              fields?.vehicleChangeCreatedAtDate?.endDate
            ),
          }),

          ...(fields?.vehicleIdArray?.length > 0 && {
            vehicleNumbers: fields?.vehicleIdArray,
          }),
          ...(fields?.challanCreatedAt?.startDate && {
            challanCreatedAtFrom: getTimestampAtStartOfDay(
              fields?.challanCreatedAt?.startDate
            ),
          }),
          ...(fields?.challanCreatedAt?.endDate && {
            challanCreatedAtTill: getTimestampAtEndOfDay(
              fields?.challanCreatedAt?.endDate
            ),
          }),
          ...(fields?.paymentStatus?.length > 0 && {
            bookingPaymentStatus: fields.paymentStatus,
          }),
        },
      },
    };

    dispatch(generateReport(payload));
  };

  //handle ranges
  const handleDateRange = (type: string, value: any) => {
    let [startDate, endDate] = value;
    setFields((prevState: any) => ({
      ...prevState,
      [type]: {
        startDate,
        endDate,
      },
    }));
  };

  // remove all from status arr
  const statusWithoutAll = StatusArray.filter((status: any) => {
    return status?.name !== "ALL";
  });

  useEffect(() => {
    if (reportsDownloaded) {
      setFields((prev: any) => ({ ...prev, ...initialState }));
      setOpenReportModal((prev: any) => ({
        ...prev,
        generateBookingReport: false,
        generateUserReport: false,
        generateExtentionReport: false,
        generateChallanReport: false,
        generateCalRefReport: false,
        generateExtraChargesReport: false,
        generatePaymentLTRReport: false,
        generateVehicleChangeReport: false,
      }));
    }
  }, [reportsDownloaded]);

  //calls on select of option in search of challan side drawer
  const onSelect = (value: any) => {
    setFields((prev: any) => {
      if (
        !value ||
        prev.vehicleIdArray?.includes(value?.vehicleDetails?.registration)
      ) {
        return prev;
      }
      return {
        ...prev,
        selectedField: value,
        vehicleIdArray: [
          ...prev.vehicleIdArray,
          value?.vehicleDetails?.registration,
        ],
      };
    });
  };

  // search for vehicle id
  const getVehicleIDs = (search: string) => {
    const payload = {
      search: search ? JSON.stringify(search) : "",
    };

    dispatch(vehicleActions.getVehiclesList(payload));
  };

  // get vehicle id with debounce
  const getSearchKey = useCallback((data: any) => {
    return data?.vehicleDetails?.registration || "";
  }, []);

  // calls when modal search box changes value
  const onInputChange = (newInputValue: string) => {
    if (!newInputValue || newInputValue === "undefined")
      setFields((prev: any) => ({ ...prev, searchField: "" }));
    else {
      setFields((prev: any) => ({
        ...prev,
        searchField: newInputValue,
      }));

      debounceLoadData(newInputValue);
    }
  };

  // get vehicle id with debounce
  const debounceLoadData = useCallback(
    debounce((searchValue: string) => {
      if (searchValue?.length >= 5) getVehicleIDs(searchValue);
    }, 1000),
    []
  );

  //handle vehicle id chip delete
  const handleChipDelete = (item: any) => {
    const newArray = fields?.vehicleIdArray.filter((data: any) => {
      return data !== item;
    });
    setFields((prev: any) => ({
      ...prev,
      selectedField: "",
      vehicleIdArray: newArray,
    }));
  };

  return (
    <Stack>
      <Typography variant={typographyConstants.HEADINGXL}>
        {reports.generateReports}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {reportTypeArr?.map((card: any) => {
          if (!card?.isRBACAccess) {
            return <></>;
          }
          return (
            <Box className={styles.ReportCard}>
              <Typography
                sx={{
                  padding: "0px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "250px",
                }}
                variant={typographyConstants.HEADING}
              >
                {card.heading}
              </Typography>
              <CustomButton
                label={reports.generateReports}
                variant="contained"
                sx={{ padding: "0px 40px" }}
                onClick={card.onClick}
                disabled={card?.disabled ?? false}
              />
            </Box>
          );
        })}
      </Box>
      {openReportModal.generateBookingReport && (
        <CustomModal
          isOpen={openReportModal.generateBookingReport}
          handleClose={() => handleCloseReportModal("generateBookingReport")}
          title={reports.generateBookingReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading="Cities"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                  selectAllText={global.allCities}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder="Choose Hubs"
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                  selectAllText={global.allHubs}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={changeStatusIcon}
                  heading={global.selectStatus}
                  placeholder="Choose Status"
                  value={fields?.status}
                  topHeading="Status"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      status: values,
                    }));
                  }}
                  choice={statusWithoutAll}
                  defaultKey="name"
                  displayNameKey="displayName"
                  selectAllText={global.allStatus}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.bookingCreatedRange}
                  fields={fields.bookingCreated}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("bookingCreated", update);
                  }}
                  maxDate={new Date()}
                />
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.bookingStartDateRange}
                  fields={fields.bookingStartDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("bookingStartDateRange", update);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.bookingEndDateRange}
                  fields={fields?.bookingEndDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("bookingEndDateRange", update);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.actualStartDateRange}
                  fields={fields.actualStartDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("actualStartDateRange", update);
                  }}
                  // minDate={getMinDate()}
                  // maxDate={getMaxDate()}
                />
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.actualEndDateRange}
                  fields={fields.actualEndDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("actualEndDateRange", update);
                  }}
                  // minDate={getMinDate()}
                  // maxDate={getMaxDate()}
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.BOOKING)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateExtentionReport && (
        <CustomModal
          isOpen={openReportModal.generateExtentionReport}
          handleClose={() => handleCloseReportModal("generateExtentionReport")}
          title={reports.generateExtentionReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading="Cities"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                  selectAllText={global.allCities}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder={global.selectHubs}
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                  selectAllText={global.allHubs}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.extensionDoneDate}
                  fields={fields.extensionDoneDate}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("extensionDoneDate", update);
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.extentionStartDateRange}
                  fields={fields.extentionStartDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("extentionStartDateRange", update);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.extentionEndDateRange}
                  fields={fields?.extentionEndDateRange}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("extentionEndDateRange", update);
                  }}
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.EXTENSION)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateChallanReport && (
        <CustomModal
          isOpen={openReportModal.generateChallanReport}
          handleClose={() => handleCloseReportModal("generateChallanReport")}
          title={reports.generateChallanReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Stack
              gap={fields?.vehicleIdArray.length > 0 ? "10px" : "40px"}
              width="100%"
            >
              <Grid container spacing={2}>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.vehicleID}
                    </Typography>

                    <SearchBar
                      searchLabel={reports.enterVehicleID}
                      selectedValue={fields?.selectedField}
                      onSelect={(val: any) => onSelect(val)}
                      data={
                        isArrayNotEmpty(vehiclesListData)
                          ? vehiclesListData
                          : []
                      }
                      onInputChange={(val: any) => onInputChange(val)}
                      searchValue={fields?.searchField}
                      getSearchKey={getSearchKey}
                      loading={onLoad}
                      onBlur={() => {
                        setFields((prevState: any) => ({
                          ...prevState,
                          selectedField: null,
                          searchField: "",
                        }));
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <CustomMultiSelect
                    icon={CityInputIcon}
                    heading={global.selectCities}
                    placeholder={global.selectCities}
                    value={fields?.cities}
                    topHeading={global.cities}
                    handleChange={(values: any) => {
                      setFields((prevState: any) => ({
                        ...prevState,
                        cities: values,
                      }));
                      {
                        values?.length === 0
                          ? setFields((prevState: any) => ({
                              ...prevState,
                              hubs: [],
                            }))
                          : dispatch(
                              getAllBranchesByCity({
                                cities: values,
                              })
                            );
                      }
                    }}
                    choice={allCitiesDetails}
                    defaultKey="name"
                    displayNameKey="displayName"
                    selectAllText={global.allCities}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomMultiSelect
                    icon={WarehouseIcon}
                    heading={global.selectHubs}
                    placeholder="Choose Hubs"
                    value={fields?.hubs}
                    topHeading="Hubs"
                    handleChange={(values: any) => {
                      setFields((prevState: any) => ({
                        ...prevState,
                        hubs: values,
                      }));
                    }}
                    choice={branchesByCities}
                    defaultKey="name"
                    displayNameKey="displayName"
                    disabled={fields?.cities?.length === 0}
                    selectAllText={global.allHubs}
                  />
                </Grid>
              </Grid>
              {fields?.vehicleIdArray.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    marginTop: "20px",
                    gap: "15px 10px",
                  }}
                >
                  {fields?.vehicleIdArray.map((item: any) => {
                    return (
                      <Chip
                        label={item}
                        onDelete={() => handleChipDelete(item)}
                      />
                    );
                  })}
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={2} sm={4} md={4}>
                  <RangePicker
                    icon={fadedCalenderIcon}
                    heading={reports.craetedOnApplicationDate}
                    fields={fields.challanCreatedAtDate}
                    maxDate={new Date()}
                    isClearable={false}
                    handleDateChange={(update: any) => {
                      handleDateRange("challanCreatedAtDate", update);
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <RangePicker
                    icon={fadedCalenderIcon}
                    heading={reports.challanCreate}
                    fields={fields.challanCreatedAt}
                    maxDate={new Date()}
                    isClearable={false}
                    handleDateChange={(update: any) => {
                      handleDateRange("challanCreatedAt", update);
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.CHALLAN)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateCalRefReport && (
        <CustomModal
          isOpen={openReportModal.generateCalRefReport}
          handleClose={() => handleCloseReportModal("generateCalRefReport")}
          title={reports.generateCalRefReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading={global.cities}
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                  selectAllText={global.allCities}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder="Choose Hubs"
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                  selectAllText={global.allHubs}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.createdDate}
                  fields={fields.bookingCreatedCancel}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("bookingCreatedCancel", update);
                  }}
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi("CANCELLATION")}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateUserReport && (
        <CustomModal
          isOpen={openReportModal.generateUserReport}
          handleClose={() => handleCloseReportModal("generateUserReport")}
          title={reports.generateUserReport}
          containerStyle={{ width: "850px", top: "55%", paddingBottom: "15px" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="10px" alignItems="center">
            <Stack gap="30px">
              <Grid container spacing={2}>
                <Grid item xs={2} sm={4} md={4}>
                  <RangePicker
                    icon={fadedCalenderIcon}
                    heading={reports.createdAt}
                    fields={fields?.createdAtRange}
                    isClearable={false}
                    maxDate={new Date()}
                    handleDateChange={(update: any) => {
                      handleDateRange("createdAtRange", update);
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CustomSelect
                    required
                    icon={fadedCalenderIcon}
                    topHeading={reports.totalBookingOperations}
                    placeholder={reports.selecttotalBookingOperations}
                    value={fields?.totalBookingsOp?.displayName}
                    choice={
                      fields?.totalBookings === "0"
                        ? totalBookingOperationsArrForZero
                        : totalBookingOperationsArr
                    }
                    defaultKey={"displayName"}
                    handleChange={(value: any) => {
                      setFields((prevState: any) => ({
                        ...prevState,
                        totalBookingsOp: value,
                        totalBookings: initialState.totalBookings,
                      }));
                      setErrorFields((prevState: any) => ({
                        ...prevState,
                        totalBookings: "",
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.totalBookings}
                    </Typography>
                    <Input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        const isNumeric = checkNumeric(value);
                        const isValidLength = value.length < 4;
                        if (isNumeric && isValidLength) {
                          const adjustedValue =
                            fields.totalBookingsOp?.name ===
                              TotalBookingsOpt.LT ||
                            fields.totalBookingsOp?.name ===
                              TotalBookingsOpt.LTEQ
                              ? restrictZeroInput(value)
                              : value;
                          setFields((prevState) => ({
                            ...prevState,
                            totalBookings: adjustedValue,
                          }));
                        }
                        setErrorFields((prevState) => ({
                          ...prevState,
                          totalBookings: "",
                        }));
                      }}
                      iconStart={MobileIcon}
                      value={fields.totalBookings}
                      placeholder={reports.enterTotalBookings}
                      disabled={!fields?.totalBookingsOp?.name}
                      width="260px"
                      errormessage={errorFields?.totalBookings}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <CustomSelect
                    required
                    icon={fadedCalenderIcon}
                    topHeading={reports.selectTrustScoreRange}
                    placeholder={reports.selectTrustScoreRange}
                    value={fields?.trustScoreRange?.displayName}
                    choice={trustScoreArr}
                    defaultKey={"displayName"}
                    handleChange={(range: any) => {
                      setFields((prevState: any) => ({
                        ...prevState,
                        trustScoreRange: range,
                      }));
                    }}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.enterDLPincodeStart}
                    </Typography>
                    <Input
                      onChange={(e: any) => {
                        e.preventDefault();
                        const value: any = restrictZeroInput(e.target.value);
                        checkNumeric(value) &&
                          e.target.value.length <= 6 &&
                          setFields((prevState: any) => ({
                            ...prevState,
                            dlPincode: {
                              ...fields?.dlPincode,
                              start: value,
                            },
                          }));
                      }}
                      iconStart={MobileIcon}
                      value={fields.dlPincode?.start}
                      placeholder={reports.enterDLPincodeStart}
                      width="260px"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.enterDLPincodeEnd}
                    </Typography>
                    <Input
                      onChange={(e: any) => {
                        e.preventDefault();
                        const value: any = restrictZeroInput(e.target.value);
                        checkNumeric(value) &&
                          e.target.value.length <= 6 &&
                          setFields((prevState: any) => ({
                            ...prevState,
                            dlPincode: {
                              ...fields?.dlPincode,
                              end: value,
                            },
                          }));
                      }}
                      iconStart={MobileIcon}
                      value={fields.dlPincode?.end}
                      placeholder={reports.enterDLPincodeEnd}
                      width="260px"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.enterAadhaarPincodeStart}
                    </Typography>
                    <Input
                      onChange={(e: any) => {
                        e.preventDefault();
                        const value: any = restrictZeroInput(e.target.value);
                        checkNumeric(value) &&
                          e.target.value.length <= 6 &&
                          setFields((prevState: any) => ({
                            ...prevState,
                            aadhharPincode: {
                              ...fields?.aadhharPincode,
                              start: value,
                            },
                          }));
                      }}
                      iconStart={MobileIcon}
                      value={fields.aadhharPincode?.start}
                      placeholder={reports.enterAadhaarPincodeStart}
                      width="260px"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.enterAadhaarPincodeEnd}
                    </Typography>
                    <Input
                      onChange={(e: any) => {
                        e.preventDefault();
                        const value: any = restrictZeroInput(e.target.value);
                        checkNumeric(value) &&
                          e.target.value.length <= 6 &&
                          setFields((prevState: any) => ({
                            ...prevState,
                            aadhharPincode: {
                              ...fields?.aadhharPincode,
                              end: value,
                            },
                          }));
                      }}
                      iconStart={MobileIcon}
                      value={fields.aadhharPincode?.end}
                      placeholder={reports.enterAadhaarPincodeEnd}
                      width="260px"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.dLDigitalVerified}
                    </Typography>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            sx={{ padding: "0px 10px" }}
                            checked={fields.isDLVerified === global.yes}
                            onChange={(e: any) =>
                              setFields((prevState: any) => ({
                                ...prevState,
                                isDLVerified: e.target.checked
                                  ? global.yes
                                  : "",
                              }))
                            }
                          />
                        }
                        label={global.yes}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            sx={{ padding: "0px 10px" }}
                            checked={fields.isDLVerified === global.no}
                            onChange={(e: any) =>
                              setFields((prevState: any) => ({
                                ...prevState,
                                isDLVerified: e.target.checked ? global.no : "",
                              }))
                            }
                          />
                        }
                        label={global.no}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Stack>
                    <Typography variant={typographyConstants.HEADING}>
                      {reports.aadhaarDigitalVerified}
                    </Typography>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            sx={{ padding: "0px 10px" }}
                            checked={fields.isAadhaarVerified === global.yes}
                            onChange={(e: any) =>
                              setFields((prevState: any) => ({
                                ...prevState,
                                isAadhaarVerified: e.target.checked
                                  ? global.yes
                                  : "",
                              }))
                            }
                          />
                        }
                        label={global.yes}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            sx={{ padding: "0px 10px" }}
                            checked={fields.isAadhaarVerified === global.no}
                            onChange={(e: any) =>
                              setFields((prevState: any) => ({
                                ...prevState,
                                isAadhaarVerified: e.target.checked
                                  ? global.no
                                  : "",
                              }))
                            }
                          />
                        }
                        label={global.no}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.USER)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateExtraChargesReport && (
        <CustomModal
          isOpen={openReportModal.generateExtraChargesReport}
          handleClose={() =>
            handleCloseReportModal("generateExtraChargesReport")
          }
          title={reports.generateExtraChargesReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading="Cities"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                  selectAllText={global.allCities}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder="Choose Hubs"
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                  selectAllText={global.allHubs}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Stack>
                  <Typography variant={typographyConstants.HEADING}>
                    {reports.enterBookingID}
                  </Typography>
                  <Input
                    onChange={(e: any) => {
                      checkAlfaNumeric(e.target.value) &&
                        setFields((prevState: any) => ({
                          ...prevState,
                          bookingID: e.target.value,
                        }));
                    }}
                    iconStart={MobileIcon}
                    value={fields.bookingID}
                    placeholder={reports.enterBookingID}
                    width="260px"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.chargesCreatedAt}
                  fields={fields.chargesCreatedAt}
                  isClearable={false}
                  maxDate={new Date()}
                  handleDateChange={(update: any) => {
                    handleDateRange("chargesCreatedAt", update);
                  }}
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.EXTRA_CHARGES)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generatePaymentLTRReport && (
        <CustomModal
          isOpen={openReportModal.generatePaymentLTRReport}
          handleClose={() => handleCloseReportModal("generatePaymentLTRReport")}
          title={reports.generatePaymentReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading="Cities"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  selectAllText={global.allCities}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder={global.selectHubs}
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  selectAllText={global.allHubs}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={changeStatusIcon}
                  heading={global.selectStatus}
                  placeholder={global.selectStatus}
                  value={fields?.paymentStatus}
                  topHeading="Status"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      paymentStatus: values,
                    }));
                  }}
                  selectAllText={global.allStatus}
                  choice={paymentStatusArray}
                  defaultKey="name"
                  displayNameKey="displayName"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Stack>
                  <Typography variant={typographyConstants.HEADING}>
                    {reports.enterBookingID}
                  </Typography>
                  <Input
                    onChange={(e: any) => {
                      checkAlfaNumeric(e.target.value) &&
                        setFields((prevState: any) => ({
                          ...prevState,
                          bookingID: e.target.value,
                        }));
                    }}
                    iconStart={MobileIcon}
                    value={fields.bookingID}
                    placeholder={reports.enterBookingID}
                    width="260px"
                  />
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.paymentDateRange}
                  fields={fields.bookingCreatedPayment}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("bookingCreatedPayment", update);
                  }}
                  maxDate={new Date()}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={reports.bookingPaymentType}
                  placeholder={reports.bookingPaymentType}
                  value={fields?.bookingPaymentType}
                  topHeading={reports.bookingPaymentType}
                  handleChange={(value: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      bookingPaymentType: value,
                    }));
                  }}
                  choice={bookingPaymentTypeArr}
                  selectAllText="All type"
                  defaultKey="name"
                  displayNameKey="displayName"
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.PAYMENT_LTR)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
      {openReportModal.generateVehicleChangeReport && (
        <CustomModal
          isOpen={openReportModal.generateVehicleChangeReport}
          handleClose={() =>
            handleCloseReportModal("generateVehicleChangeReport")
          }
          title={reports.generateVehicleChangeReport}
          containerStyle={{ width: "850px", top: "50%" }}
          onOutsideClickClose={false}
        >
          <Stack padding="0px 20px" gap="40px" alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  heading={global.selectCities}
                  placeholder={global.selectCities}
                  value={fields?.cities}
                  topHeading="Cities"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      cities: values,
                    }));
                    {
                      values?.length === 0
                        ? setFields((prevState: any) => ({
                            ...prevState,
                            hubs: [],
                          }))
                        : dispatch(
                            getAllBranchesByCity({
                              cities: values,
                            })
                          );
                    }
                  }}
                  selectAllText={global.allCities}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  heading={global.selectHubs}
                  placeholder={global.selectHubs}
                  value={fields?.hubs}
                  topHeading="Hubs"
                  handleChange={(values: any) => {
                    setFields((prevState: any) => ({
                      ...prevState,
                      hubs: values,
                    }));
                  }}
                  selectAllText={global.allHubs}
                  choice={branchesByCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={fields?.cities?.length === 0}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <RangePicker
                  icon={fadedCalenderIcon}
                  heading={reports.createdAt}
                  fields={fields.vehicleChangeCreatedAtDate}
                  isClearable={false}
                  handleDateChange={(update: any) => {
                    handleDateRange("vehicleChangeCreatedAtDate", update);
                  }}
                />
              </Grid>
            </Grid>

            <CustomButton
              label={reports.generateReports}
              variant="contained"
              sx={{ padding: "0px 40px", minWidth: "200px" }}
              onClick={() => generateReportApi(ReportType.VEHICLE_CHANGE)}
              loading={loading}
            />
          </Stack>
        </CustomModal>
      )}
    </Stack>
  );
};
