import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useEffect, useState } from "react";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useDispatch } from "react-redux";
import {
  clearBreadcrumbs,
  getAllCities,
  getAllStates,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import moment from "moment";
import en from "../../../locale/rental-en.json";
import AddNewCity from "./sections/addNewCity";
import {
  clearCityDetails,
  getCityDetails,
  getCityList,
  updateCity,
} from "../../../redux/actions/businessManagementActions";
import {
  changeStatusIcon,
  CityInputIcon,
  defaultCityImage,
  NewRibbon,
  stateIcon,
  upcomingRibbon,
} from "../../../constants/exportImages";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { MITC_FORM_URL } from "../../../../config";
import {
  DISPLAY_STATUS,
  STATUS,
  cityStatus,
} from "../../../constants/constants";
import SideDrawer from "../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { AntSwitch } from "../../../../sharedComponents/molecules/MuiSwitch";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { toPascalCase } from "../../../utils/helper";
import { colors } from "../../../../themes/colors";
import {
  cityManagementFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
import RbacHelper from "../../../../utils/helperRBAC";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import { getFilterDataFromLocalStorage } from "../../../../config/filterStorage";
const BusinessCity = () => {
  const status = [
    {
      name: "ACTIVE",
      displayName: "Active",
    },
    {
      name: "INACTIVE",
      displayName: "Inactive",
    },
  ];

  // rbac implementation
  const RbacHasAccess = {
    listCities: RbacHelper.isAccessRightsProvided(
      modules.CITY_MANAGEMENT,
      cityManagementFunctionalities.LIST_CITIES
    ),
    addCity: RbacHelper.isAccessRightsProvided(
      modules.CITY_MANAGEMENT,
      cityManagementFunctionalities.ADD_CITY
    ),
    viewCity: RbacHelper.isAccessRightsProvided(
      modules.CITY_MANAGEMENT,
      cityManagementFunctionalities.VIEW_CITY
    ),
    editCity: RbacHelper.isAccessRightsProvided(
      modules.CITY_MANAGEMENT,
      cityManagementFunctionalities.EDIT_CITY
    ),
  };

  const [filters, setFilters] = useState<any>({
    paginationModel: { page: 0, pageSize: 10 },
    states: {
      name: "",
      displayName: en.global.allState,
    },
    status: {
      name: "",
      displayName: en.global.allStatus,
    },
  });

  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    openDialog: false,
    status: "",
    name: "",
    body: "",
  });
  const {
    cityList,
    closeSideDrawer,
    signedUrl,
    cityDetails,
    saveCityLoader,
    imageLoader,
    cityListLoader,
    cityDetailsLoader,
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);
  const { allStatesList = [], stateBasedCities } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { shouldScreenRefresh } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setFilters((prevFields: any) => ({
      ...prevFields,
      paginationModel: { page: 0, pageSize: 10 },
    }));
    dispatch(getAllStates());
    if (location?.state === "OVERVIEW_CITY") {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OVERVIEW_CITY));
    }
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, [shouldScreenRefresh]);
  const handleFilterChange = (fields: string, value: string) => {
    setFilters((prevFields: any) => ({
      ...prevFields,
      [fields]: value,
      paginationModel: { page: 0, pageSize: filters.paginationModel.pageSize },
    }));
  };
  //toggle add city drawer
  const toggleDrawer = () => {
    setOpen(!open);
    if (!open) {
      dispatch(clearCityDetails());
    }
  };
  useEffect(() => {
    if (closeSideDrawer) {
      setOpen(false);
    }
  }, [closeSideDrawer]);
  const getRowId = (row: any) => {
    return row?.name;
  };
  const columns: any = [
    {
      field: "images",
      headerName: "Image",
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <Box sx={{ position: "relative" }}>
            <Box>
              <img
                style={{ width: "100px", height: "60px" }}
                src={
                  data.row.images?.length
                    ? `${MITC_FORM_URL}/${data?.row.images[0].filePath}`
                    : defaultCityImage
                }
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "21%",
                left: data?.row.isNew ? "26%" : "41%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {data?.row.isUpcoming ? (
                <img src={upcomingRibbon} style={{ height: "17px" }} />
              ) : data?.row.isNew ? (
                <img src={NewRibbon} style={{ height: "17px" }} />
              ) : (
                ""
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "displayName",
      headerName: "City Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
    },
    {
      field: "stateDisplayname",
      headerName: "State",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
    },
    {
      field: "activeWareHouseCount",
      headerAlign: "center",
      align: "center",
      headerName: "Hub",
      headerClassName: "hideRightSeparator",
      flex: 0.85,
    },
    {
      field: "selectableCities",
      headerName: "Deliverable Cities",
      headerAlign: "center",
      align: "center",
      flex: 1.4,
      headerClassName: "hideRightSeparator",
      renderCell: (data: any) => {
        const displayNames = data?.row?.selectableCities?.map(
          (item: any) => item.displayName
        );
        return (
          <>
            <Tooltip
              title={
                data?.row?.selectableCities?.length > 1
                  ? displayNames.join(", ")
                  : ""
              }
            >
              <span>
                {displayNames?.length ? displayNames.join(", ") : "N/A"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      valueFormatter: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      field: "launchDate",
      headerName: "Launch Date",
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1.2,
      valueFormatter: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      field: "status",
      headerName: "City Status",
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      type: "actions",
      headerClassName: "hideRightSeparator",
      getActions: (data: GridRenderCellParams<any>) => {
        return [
          <FormControlLabel
            value={data?.row.status}
            control={
              <AntSwitch
                color="secondary"
                checked={
                  data?.row.status == cityStatus["active"] ? true : false
                }
                onChange={(e) => {
                  e.stopPropagation();
                  toggleUpdateStatusDialog({
                    ...data?.row,
                    body:
                      data?.row.status === STATUS.ACTIVE
                        ? en.NewBusinessManagement.inactivateCityStatus
                        : en.NewBusinessManagement.activateCityStatus,
                  });
                }}
              />
            }
            label={
              <Typography
                sx={{
                  color:
                    data?.row.status === STATUS.ACTIVE
                      ? colors.secondary_green
                      : colors.primary_danger,
                  padding: "5px",
                }}
              >
                {DISPLAY_STATUS[
                  data?.row?.status as keyof typeof DISPLAY_STATUS
                ] ?? ""}
              </Typography>
            }
            labelPlacement="start"
            disabled={!RbacHasAccess.editCity}
          />,
        ];
      },
    },
  ];

  //get City List With Filters
  useEffect(() => {
    {
      RbacHasAccess.listCities &&
        dispatch(
          getCityList({
            page: filters.paginationModel.page + 1,
            limit: filters.paginationModel.pageSize,
            state: filters.states.name,
            status: filters.status.name,
          })
        );
    }
  }, [filters.status, filters.states, filters.paginationModel]);

  //handle pagination
  const handlePaginationModelChange = (paginationModel: any) => {
    const { page, pageSize } = paginationModel;

    setFilters((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));
  };

  const handleStatusChange = () => {
    dispatch(
      updateCity({
        data: {
          data: {
            data: {
              status:
                dialogData.status == STATUS.ACTIVE
                  ? STATUS.INACTIVE
                  : STATUS.ACTIVE,
            },
          },
          filters: {
            page: filters.paginationModel.page + 1,
            limit: filters.paginationModel.pageSize,
            state: filters.states.name,
            status: filters.status.name,
          },
        },
        name: dialogData.name,
      })
    );
  };

  const toggleUpdateStatusDialog = (
    planData: any = {},
    updateStatus: boolean = false
  ) => {
    if (updateStatus) {
      handleStatusChange();
    }
    setDialogData((prev) => {
      return { ...prev, openDialog: !prev.openDialog, ...planData };
    });
  };

  const handleRowClick = (params: any, event: any) => {
    if (RbacHasAccess.viewCity) {
      dispatch(getCityDetails({ cityName: params?.id }));
      dispatch(getAllStates());
      toggleDrawer();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <SideDrawer
          open={open}
          heading={
            !Object.keys(cityDetails).length || cityDetailsLoader
              ? en.NewBusinessManagement.addNewCity
              : RbacHasAccess?.editCity
              ? en.NewBusinessManagement.updateCity
              : en.NewBusinessManagement.viewCity
          }
          toggleDrawer={toggleDrawer}
          disablePadding={true}
          headerPadding={"1vw 1.5vw"}
          renderUI={
            <AddNewCity
              cityData={cityDetails}
              allStatesList={allStatesList}
              signedUrl={signedUrl}
              stateBasedCities={stateBasedCities}
              saveCityLoader={saveCityLoader}
              imageLoader={imageLoader}
              filters={filters}
              cityDetailsLoader={cityDetailsLoader}
              editAccess={RbacHasAccess?.editCity}
            />
          }
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            {RbacHasAccess.addCity && (
              <CustomButton
                variant="outlined"
                label={en.NewBusinessManagement.addNewCity}
                onClick={() => {
                  toggleDrawer();
                }}
                style={{ height: "40px", borderRadius: "10px" }}
              />
            )}
          </Box>
          <Box>
            {RbacHasAccess.listCities && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ alignSelf: "center" }}>
                  {cityList?.pagination?.totalRecords ?? 0} Results
                </Typography>
                <Box>
                  <CustomSelect
                    icon={stateIcon}
                    width="160px"
                    placeholder={en.NewBusinessManagement.selectState}
                    value={filters.states.displayName}
                    choice={
                      allStatesList?.length
                        ? [
                            { name: "", displayName: en.global.allState },
                            ...allStatesList,
                          ]
                        : allStatesList
                    }
                    defaultKey={"displayName"}
                    required
                    handleChange={(state: any) => {
                      handleFilterChange("states", state);
                    }}
                  />
                </Box>
                <Box>
                  <CustomSelect
                    icon={changeStatusIcon}
                    width="160px"
                    placeholder={en.NewBusinessManagement.selectStatus}
                    value={filters.status.displayName}
                    choice={
                      status?.length
                        ? [
                            { name: "", displayName: en.global.allStatus },
                            ...status,
                          ]
                        : status
                    }
                    defaultKey={"displayName"}
                    required
                    handleChange={(status: any) => {
                      handleFilterChange("status", status);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <DataTable
            columns={columns}
            rows={cityList?.cities}
            disableColumnMenu
            disableColumnSorting
            getRowId={getRowId}
            rowHeight={75}
            paginationMode="server"
            paginationModel={filters.paginationModel}
            onPaginationModelChange={(val: any) => {
              handlePaginationModelChange(val);
            }}
            rowCount={cityList?.pagination?.totalRecords}
            disableColumnResize={true}
            disableColumnSelector={true}
            loading={cityListLoader}
            onRowClick={handleRowClick}
            hasAccess={RbacHasAccess.listCities}
          />
        </Box>
      </Box>
      <ResponsiveDialog
        title={en.NewBusinessManagement.updateCityStatus}
        body={dialogData.body}
        open={dialogData.openDialog}
        handleClose={() => toggleUpdateStatusDialog({}, false)}
        handleYes={() => toggleUpdateStatusDialog({}, true)}
      />
    </>
  );
};
export default BusinessCity;
