import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
// import { CustomRoutes, DrawerRoutes } from "../../../utils/Routes";
// import { CustomRoutesFreedoX, DrawerRoutesFreedoX } from "../../utils/Routes";
import { useDispatch } from "react-redux";
import SideDrawer from "../../sharedComponents/molecules/SideDrawer";
import {
  CustomRoutesYOR,
  DrawerRoutesYOR,
} from "../../freedo-x-src/utils/Routes";
import { appBackgroundImage } from "../../rentals-src/constants/constants";
import { useThemeMode } from "../../rentals-src/customHooks/useThemeMode";
import { CustomRoutesLTR, DrawerRoutesLTR } from "../../utils/Routes";

function FreedoXLayout(props: any) {
  const initialHeaderModuleData = {
    moduleName: "dashboard",
  };
  const [headerData, setHeaderData] = useState(initialHeaderModuleData);
  const { isFreedoX } = useSelector((state: RootState) => state.newAuthReducer);
  const dispatch = useDispatch();
  const appBackground = isFreedoX
    ? appBackgroundImage["freedoX"]
    : appBackgroundImage["freedoRentals"];

  return (
    <ThemeProvider theme={useThemeMode()}>
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${appBackground})`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `cover`,
          backgroundAttachment: `fixed`,
        }}
      >
        <CssBaseline />
        <SideDrawer
          drawerRoutes={isFreedoX ? DrawerRoutesYOR : DrawerRoutesLTR}
          appRoutes={isFreedoX ? CustomRoutesYOR : CustomRoutesLTR}
        />
      </Box>
    </ThemeProvider>
  );
}

export default FreedoXLayout;
