import { API_URLS, BASE_URL_UAT, customAxios, versioning } from "../config";

const getOperatorList = (payload: any) => {
  try {
    let url = API_URLS.getAllOperator;
    if (payload?.search) url += `search=${payload?.search}&`;
    if (payload?.page) url += `page=${payload?.page}&`;
    if (payload?.size) url += `size=${payload?.size}`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getBusinessTypeList = (payload: any) => {
  try {
    let url = API_URLS.findAllOperatorBuisnessType;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getAllDocumentTypes = (payload: any) => {
  try {
    let url = API_URLS.getAllDocumentTypes;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const addOperator = (payload: any) => {
  try {
    let url = API_URLS.StoreOperator;
    // if (payload?.branch_id) url += `branch_id=${payload?.branch_id}&`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const deleteOperator = (payload: any) => {
  try {
    let url = API_URLS.deleteOperator;
    if (payload?.operatorId) url += `/${payload?.operatorId}`;

    return customAxios.post(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const operatorActionClickService = (payload: any) =>
  customAxios
    .post(versioning + API_URLS.approveOperator, payload)
    .catch((ex) => ex);

const operatorstoreDocuments = (payload: any) => {
  try {
    let url = API_URLS.operatorStoredocument;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return customAxios.post(url, payload, config).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const editOperator = (payload: any) => {
  try {
    let url = API_URLS.saveOperator;
    return fetchEditOperator(url, payload);
  } catch (ex) {
    return ex;
  }
};
const saveOperatorService = (payload: any) => {
  try {
    let url = API_URLS.saveOperator;
    return fetchSaveOperator(url, payload);
  } catch (ex) {
    return ex;
  }
};

const fetchEditOperator = (route: any, payload: any) =>
  customAxios.put(route, payload).catch((ex) => ex);

const fetchSaveOperator = (route: any, payload: any) =>
  customAxios.put(route, payload).catch((ex) => ex);

const getOperatorDetails = (payload: any) => {
  try {
    let url = API_URLS.getOperatorById;
    url += `/${payload?.operatorId}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const linkedAccount = (payload: any) => {
  try {
    let url = API_URLS.linkedAccount;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const stakeholder = (payload: any) => {
  try {
    let url = API_URLS.stakeholder;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const productConfiguration = (payload: any) => {
  try {
    let url = API_URLS.productConfiguration;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getOperatorList,
  addOperator,
  deleteOperator,
  operatorActionClickService,
  getBusinessTypeList,
  getAllDocumentTypes,
  operatorstoreDocuments,
  editOperator,
  getOperatorDetails,
  saveOperatorService,
  linkedAccount,
  stakeholder,
  productConfiguration,
};
