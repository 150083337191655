import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import { typographyConstants } from "../../../../constants/constants";
import {
  BikeIcon,
  LocationIcon,
  SurgeIcon,
  WarehouseIcon,
  fadedCalenderIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { appendCommaSpace, paisaToRupee } from "../../../../utils/helper";
import { StyleObject } from "../StyleObject";
import RbacHelper from "../../../../../utils/helperRBAC";
import { modules, surgeFunctionalities } from "../../../../../constants/RBACModuleEnums";

const ViewSurge = (props: any) => {
  // rbac implementation
  const RbacHasAccess = {
    editSurge: RbacHelper.isAccessRightsProvided(modules.SURGE_MANAGEMENT, surgeFunctionalities.EDIT_SURGE),
}

  // props destructuring
  const {
    surgeByIdData,
    handleEditButtonClick,
    branches,
    modelListByBranch,
    masterPlanDurationsData,
  } = props;
  const navigate = useNavigate();

  const branchesDisplayNameArray: string[] = isArrayNotEmpty(branches)
    ? branches?.map((branch: any) => branch?.displayName ?? "")
    : [];

  const modelsDisplayNameArray: string[] = isArrayNotEmpty(modelListByBranch)
    ? modelListByBranch?.map((model: any) => model?.displayName ?? "")
    : [];

  const surgeDateTypeHandler = (val: string) => {
    let tempArr: string[] = [];
    if (val === rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS) {
      tempArr = surgeByIdData?.daysOfWeek.map((i: any) => {
        return i.charAt(0).toUpperCase() + i.slice(1).toLowerCase(); // Capitalize first letter and convert rest to lowercase
      });
      appendCommaSpace(tempArr);
      return tempArr;
    } else if (val === rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC) {
      tempArr = [
        `${moment(surgeByIdData?.startDate).format(
          rentalEn?.global?.DDMMYYYY
        )} - ${moment(surgeByIdData?.endDate).format(
          rentalEn?.global?.DDMMYYYY
        )}`,
      ];
      return tempArr;
    }
  };

  return (
    <Box sx={StyleObject?.viewSurge_wrapperBox}>
      {/* top buttons view */}
      <Box sx={StyleObject?.viewSurge_topViewBox}>
        <Box sx={StyleObject?.viewSurge_topLeftViewBox}>
          <CustomIconButton
            sx={StyleObject?.viewSurge_iconButton}
            onClick={() => {
              navigate(-1);
            }}
            children={<ArrowBackTwoToneIcon />}
          />
          <Typography variant={typographyConstants.HEADING}>
            {surgeByIdData?.surgeName}
          </Typography>
          <Chip
            size="small"
            color="primary"
            // If the 'isEditable' key is false, the surgeStatus is always 'Inactive';
            // otherwise, it reflects the actual status of the surge.
            label={
              surgeByIdData?.isEditable
                ? surgeByIdData?.surgeStatus ===
                  rentalEn?.surgeManagement?.surgeStatus?.ACTIVE
                  ? rentalEn?.surgeManagement?.surgeStatus?.Active
                  : rentalEn?.surgeManagement?.surgeStatus?.Inactive
                : rentalEn?.surgeManagement?.surgeStatus?.Inactive
            }
            sx={StyleObject?.viewSurge_chip}
            disabled={
              surgeByIdData?.isEditable &&
              surgeByIdData?.surgeStatus ===
                rentalEn?.surgeManagement?.surgeStatus?.ACTIVE
                ? false
                : true
            }
          />
        </Box>
        {(RbacHasAccess.editSurge && surgeByIdData?.isEditable) && (
          <CustomButton
            variant="contained"
            label={rentalEn?.buttonLabels?.edit}
            onClick={() =>
              handleEditButtonClick(
                surgeByIdData,
                branches,
                modelListByBranch,
                masterPlanDurationsData
              )
            }
          />
        )}
      </Box>
      {/* 1st card view */}
      <Card sx={StyleObject?.viewSurge_card}>
        <CardContent>
          <Grid container spacing={2} sx={StyleObject?.viewSurge_gridStyle1}>
            {/* Box 1 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={LocationIcon} />
              </Box>
              <Box>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.global?.city} :`}
                </Typography>
                <Typography variant={typographyConstants.SUBHEADING}>
                  {surgeByIdData?.cityDisplayName}
                </Typography>
              </Box>
            </Grid>
            {/* Box 2 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={WarehouseIcon} />
              </Box>
              <Box sx={StyleObject?.viewSurge_truncate}>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.surgeManagement?.SurgeList?.Hub} :`}
                </Typography>
                <Tooltip
                  title={
                    isArrayNotEmpty(surgeByIdData?.branchesDisplayName)
                      ? appendCommaSpace(surgeByIdData?.branchesDisplayName)
                      : appendCommaSpace(branchesDisplayNameArray)
                  }
                  arrow
                >
                  <Typography variant={typographyConstants.SUBHEADING}>
                    {isArrayNotEmpty(surgeByIdData?.branchesDisplayName)
                      ? appendCommaSpace(surgeByIdData?.branchesDisplayName)
                          ?.length >= 40
                        ? `${appendCommaSpace(
                            surgeByIdData?.branchesDisplayName
                          )?.substring(0, 40)} ${"..."}`
                        : appendCommaSpace(surgeByIdData?.branchesDisplayName)
                      : appendCommaSpace(branchesDisplayNameArray)?.length >= 40
                      ? `${appendCommaSpace(
                          branchesDisplayNameArray
                        )?.substring(0, 40)} ${"..."}`
                      : appendCommaSpace(branchesDisplayNameArray)}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
            {/* Box 3 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={BikeIcon} />
              </Box>
              <Box sx={StyleObject?.viewSurge_truncate}>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.global?.vehicleModel} :`}
                </Typography>
                <Tooltip
                  title={
                    isArrayNotEmpty(surgeByIdData?.modelsDisplayName)
                      ? appendCommaSpace(surgeByIdData?.modelsDisplayName)
                      : appendCommaSpace(modelsDisplayNameArray)
                  }
                  arrow
                >
                  <Typography variant={typographyConstants.SUBHEADING}>
                    {isArrayNotEmpty(surgeByIdData?.modelsDisplayName)
                      ? appendCommaSpace(surgeByIdData?.modelsDisplayName)
                          ?.length >= 40
                        ? `${appendCommaSpace(
                            surgeByIdData?.modelsDisplayName
                          )?.substring(0, 40)} ${"..."}`
                        : appendCommaSpace(surgeByIdData?.modelsDisplayName)
                      : appendCommaSpace(modelsDisplayNameArray)?.length >= 40
                      ? `${appendCommaSpace(modelsDisplayNameArray)?.substring(
                          0,
                          40
                        )} ${"..."}`
                      : appendCommaSpace(modelsDisplayNameArray)}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* 2nd card view */}
      <Card sx={StyleObject?.viewSurge_card}>
        <CardContent>
          <Grid container spacing={2} sx={StyleObject?.viewSurge_gridStyle2}>
            {/* Box 1 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={SurgeIcon} />
              </Box>
              <Box sx={StyleObject?.viewSurge_truncate}>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.surgeManagement?.PackageSelected} :`}
                </Typography>
                <Tooltip
                  title={
                    isArrayNotEmpty(surgeByIdData?.durations)
                      ? surgeByIdData?.durations?.length === 1 &&
                        surgeByIdData?.durations[0] === 1
                        ? `${appendCommaSpace(surgeByIdData?.durations)} ${
                            rentalEn?.global?.day
                          }`
                        : `${appendCommaSpace(surgeByIdData?.durations)} ${
                            rentalEn?.global?.days
                          }`
                      : `${appendCommaSpace(masterPlanDurationsData)} ${
                          rentalEn?.global?.days
                        }`
                  }
                  arrow
                >
                  <Typography variant={typographyConstants.SUBHEADING}>
                    {isArrayNotEmpty(surgeByIdData?.durations)
                      ? surgeByIdData?.durations?.length === 1 &&
                        surgeByIdData?.durations[0] === 1
                        ? `${appendCommaSpace(surgeByIdData?.durations)} ${
                            rentalEn?.global?.day
                          }`
                        : `${appendCommaSpace(surgeByIdData?.durations)} ${
                            rentalEn?.global?.days
                          }`
                      : `${appendCommaSpace(masterPlanDurationsData)} ${
                          rentalEn?.global?.days
                        }`}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
            {/* Box 2 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={SurgeIcon} />
              </Box>
              <Box>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.surgeManagement?.SurgeList?.SurgeType} :`}
                </Typography>
                <Box sx={StyleObject?.viewSurge_cardContent_box}>
                  <Typography variant={typographyConstants.SUBHEADING}>
                    {surgeByIdData?.surgeType ===
                    rentalEn?.surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
                      ? rentalEn?.surgeManagement?.surgeTypes?.Increment
                      : rentalEn?.surgeManagement?.surgeTypes?.Multiplier}
                  </Typography>
                  <Chip
                    size="small"
                    color="primary"
                    label={
                      surgeByIdData?.surgeType ===
                      rentalEn?.surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
                        ? `₹ ${paisaToRupee(surgeByIdData?.surgeValue)}`
                        : `${surgeByIdData?.surgeValue}x`
                    }
                    sx={StyleObject?.viewSurge_chip}
                  />
                </Box>
              </Box>
            </Grid>
            {/* Box 3 */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={StyleObject?.viewSurge_cardContent_box}
            >
              <Box>
                <img src={fadedCalenderIcon} />
              </Box>
              <Box sx={StyleObject?.viewSurge_truncate}>
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                >
                  {`${rentalEn?.surgeManagement?.SurgeDateType} :`}
                </Typography>
                <Tooltip
                  title={appendCommaSpace(
                    surgeDateTypeHandler(surgeByIdData?.dateType)
                  )}
                  arrow
                >
                  <Typography variant={typographyConstants.SUBHEADING}>
                    {surgeByIdData?.dateType ===
                    rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS
                      ? rentalEn?.surgeManagement?.SurgeDateTypes
                          ?.SpecificDays + ": "
                      : rentalEn?.surgeManagement?.SurgeDateTypes?.Specific +
                        ": "}{" "}
                    {appendCommaSpace(
                      surgeDateTypeHandler(surgeByIdData?.dateType)
                    )?.length >= 40
                      ? `${appendCommaSpace(
                          surgeDateTypeHandler(surgeByIdData?.dateType)
                        )?.substring(0, 40)} ${"..."}`
                      : appendCommaSpace(
                          surgeDateTypeHandler(surgeByIdData?.dateType)
                        )}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewSurge;
