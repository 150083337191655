import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { docUploadDates } from "..";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import InputDatePicker from "../../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { colors } from "../../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import {
  filetypesFormat,
  GenericObject,
  VehicleDocumentType,
  typographyConstants,
  vehicleDocumentTypeArray,
  documentStatus,
} from "../../../../../constants/constants";
import {
  CheckMark,
  CrossIcon,
  DownloadIcon,
} from "../../../../../constants/exportImages";
import {
  default as en,
  default as rentalEn,
} from "../../../../../locale/rental-en.json";
import {
  calculateFutureDate,
  dateFormat,
  istToTimestamp,
} from "../../../../../utils/helper";
import { VehicleDocumentProps } from "../../../../../interfaces/vehicleInterface";
import { useDispatch } from "react-redux";
import { vehicleActions } from "../../../../../redux/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const { VehicleManagement, global, errorMessages, toastTypes } = en;

const {
  VehicleManagement: { vehicleDocumentType },
} = rentalEn;

const initialState: GenericObject = {
  selectedDocType: { name: "", displayName: "" },
  validFrom: "",
  validTo: "",
  uploadedDoc: "",
  type: VehicleDocumentType.OTHERS,
};

const VehicleDocuments = (props: VehicleDocumentProps) => {
  // ref of doc input file
  let docRef = useRef<any>([]);
  const dispatch = useDispatch();
  const {
    vehicleIds,
    documentsData = [],
    docHandleDocumentUpload = () => {},
    docHandleDateChange = () => {},
    addDocButtonHandler,
    docHandleDocumentTypeChange,
    deleteDocRow = () => {},
    downloadVehicleDocument = () => {},
    getSignedUrlLoader = false,
    deleteDocumentSignedUrl = () => {},
    downloadDocumentsSignedUrl = () => {},
    docUploadS3Loader,
    initialDocUploadArray,
    setDocumentsData,
  } = props;

  // creating ref for all documents in array
  docRef.current = useCallback(
    documentsData?.map((ref: any, index: number) => {
      docRef.current[index] = React.createRef();
    }),
    []
  );

  // Redux state selector
  const {
    vehicleAttachmentsData = [],
    vehicleDocumentsData: { documentDetails = [], pageData: docPageData = {} },
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  const [uploadIndex, setUploadIndex] = useState<any>();

  const handleDocumentUploadClick = (index: number) => {
    if (
      documentsData[index].type === VehicleDocumentType.OTHERS &&
      !documentsData[index].selectedDocType.name
    ) {
      toastr.warning(toastTypes.alert, errorMessages.selectDocType);
      return;
    }

    if (!documentsData[index].validFrom || !documentsData[index].validTo) {
      toastr.warning(toastTypes.alert, errorMessages.selectDateFirst);
      return;
    }

    docRef.current[index].current.click();
    setUploadIndex(index);
  };

  useEffect(() => {
    if (documentDetails?.length) {
      let array: any = documentsData;
      let docArray: any = documentDetails;

      for (let i = 0; i < 3; i++) {
        let index: number = documentDetails.findIndex(function (
          item: any,
          index: number
        ) {
          return item?.type === initialDocUploadArray[i]?.type;
        });

        if (index >= 0) {
          let obj = {
            selectedDocType: {
              name: documentDetails[index]?.type,
              displayName:
                vehicleDocumentType[
                  documentDetails[index]
                    ?.type as keyof typeof vehicleDocumentType
                ],
            },
            validFrom: dateFormat(documentDetails[index]?.startDate),
            validTo: dateFormat(documentDetails[index]?.expiryDate),
            id: documentDetails[index]?.id,
            uploadedDoc: "",
            isExpired: documentDetails[index]?.isExpired,
            status: documentDetails[index]?.status,
            document: documentDetails[index]?.document,
          };
          array[i] = obj;
          docArray.splice(index, 1);
        }
      }

      let modifiedArray = docArray?.map((item: any) => {
        return {
          selectedDocType: {
            name: item?.type,
            displayName:
              vehicleDocumentType[
                item?.type as keyof typeof vehicleDocumentType
              ],
          },
          validFrom: dateFormat(item?.startDate),
          validTo: dateFormat(item?.expiryDate),
          id: item?.id,
          uploadedDoc: "",
          isExpired: item?.isExpired,
          status: item?.status,
          document: item?.document,
        };
      });
      setDocumentsData([...array, ...modifiedArray]);
    }
  }, [documentDetails]);

  return (
    <Box sx={{ padding: "20px 10%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Top Button Section */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography variant={typographyConstants?.HEADING}>
            {`${rentalEn?.VehicleManagement?.uploadDocuments}`}
          </Typography>
          <Typography
            color={colors.text_black}
            variant={typographyConstants?.BODYITALIC}
          >
            {`${rentalEn?.VehicleManagement?.pdfOnly}`}
          </Typography>
        </Box>
        <CustomButton
          label={rentalEn?.VehicleManagement?.addDocument}
          variant="text"
          onClick={addDocButtonHandler}
          startIcon={<AddBoxOutlinedIcon />}
          disabled={
            (documentsData[documentsData?.length - 1]?.id
              ? true
              : documentsData?.length > 3) &&
            !(
              documentsData[documentsData?.length - 1]?.uploadedDoc ||
              documentsData[documentsData?.length - 1]?.id
            )
          }
        />
      </Box>

      {/* Document Upload Section */}
      <Stack
        spacing={2}
        sx={{ justifyContent: "center", marginBottom: "50px" }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            boxShadow: 1,
            borderRadius: "8px",
            backgroundColor: colors.secondary_gray2,
            padding: "5px 0px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={typographyConstants?.HEADING}
                sx={{ padding: "0px", marginLeft: "0px" }}
              >
                {rentalEn?.VehicleManagement?.documentType}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={typographyConstants?.HEADING}
                sx={{ padding: "0px" }}
              >
                {rentalEn?.VehicleManagement?.validFrom}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={typographyConstants?.HEADING}
                sx={{ padding: "0px" }}
              >
                {rentalEn?.VehicleManagement?.validTo}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={typographyConstants?.HEADING}
                sx={{ padding: "0px" }}
              >
                {rentalEn?.VehicleManagement?.upload}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Document Section */}
        {isArrayNotEmpty(documentsData) &&
          documentsData?.map((item: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-around",
                  boxShadow: 1,
                  py: 1,
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "0px 8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      {item.type === VehicleDocumentType.OTHERS ? (
                        <Box>
                          <CustomSelect
                            required
                            placeholder={VehicleManagement?.selectDocument}
                            value={item?.selectedDocType?.displayName}
                            choice={vehicleDocumentTypeArray}
                            defaultKey={global.displayName}
                            handleChange={(value: any) => {
                              docHandleDocumentTypeChange(value, index);
                            }}
                            sx={{
                              minHeight: "42px",
                              height: "42px",
                              width: "170px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color: colors.dark_gray,
                            // maxWidth: "150px",
                          }}
                          variant={typographyConstants.BODY}
                        >
                          {item?.selectedDocType?.displayName}
                        </Typography>
                      )}
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      {item?.id ? (
                        <Input
                          value={item.validFrom}
                          width={"150px"}
                          customInputProps={{ readOnly: true }}
                        />
                      ) : (
                        <InputDatePicker
                          key={index}
                          required
                          value={item.validFrom ? item.validFrom : null}
                          onChange={(date: any) => {
                            docHandleDateChange(
                              docUploadDates.VALID_FORM,
                              date,
                              index
                            );
                          }}
                          placeholder={VehicleManagement?.validFrom}
                          textFieldStyle={{
                            maxWidth: 150,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                            },
                          }}
                          placement={"right-start"}
                        />
                      )}
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      {item?.id ? (
                        <Input
                          value={item?.validTo}
                          width={"150px"}
                          customInputProps={{ readOnly: true }}
                        />
                      ) : (
                        <InputDatePicker
                          key={index}
                          required
                          value={item.validTo ? item.validTo : null}
                          onChange={(date: any) => {
                            docHandleDateChange(
                              docUploadDates.VALID_TO,
                              date,
                              index
                            );
                          }}
                          placeholder={VehicleManagement?.validTo}
                          textFieldStyle={{
                            maxWidth: 150,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                            },
                          }}
                          minDate={
                            item.validFrom
                              ? calculateFutureDate(
                                  new Date(
                                    istToTimestamp(item.validFrom) || ""
                                  ),
                                  1
                                )
                              : calculateFutureDate(new Date(), 1)
                          }
                          placement={"right-start"}
                        />
                      )}
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      {item?.id ? (
                        <Box
                          sx={{ display: "flex", gap: "5px", width: "120px" }}
                        >
                          <img src={CheckMark} />
                          <Tooltip
                            title={
                              item.document?.length > 12 ? item.document : ""
                            }
                          >
                            <Typography
                              sx={{
                                color: colors?.primary_dark_blue2,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                fontWeight: 500,
                              }}
                              variant={typographyConstants.BODY}
                              onClick={() => downloadVehicleDocument(item?.id)}
                            >
                              {item.document}
                            </Typography>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex" }}>
                          {item?.uploadedDoc ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Tooltip
                                title={
                                  item.uploadedDoc?.name?.length > 7
                                    ? item.uploadedDoc?.name
                                    : ""
                                }
                              >
                                <Typography
                                  sx={{
                                    color: colors?.primary_dark_blue2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                    fontWeight: 500,
                                    width: "70px",
                                  }}
                                  variant={typographyConstants.BODY}
                                  // onClick={() =>
                                  //   downloadVehicleDocument(item?.id)
                                  // }
                                >
                                  {item.uploadedDoc?.name}
                                </Typography>
                              </Tooltip>
                              <CustomIconButton>
                                <CloseIcon
                                  color="error"
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                  }}
                                  onClick={() =>
                                    deleteDocumentSignedUrl(item, index)
                                  }
                                />
                              </CustomIconButton>
                              <CustomIconButton
                                children={<img src={DownloadIcon} />}
                                // sx={{ marginLeft: "20px" }}
                                onClick={() => downloadDocumentsSignedUrl(item)}
                              />
                            </Box>
                          ) : (
                            <Box>
                              <input
                                type="file"
                                ref={docRef.current[index]}
                                style={{ display: "none" }}
                                accept={filetypesFormat.PDF}
                                onChange={(e: any) => {
                                  let data = {
                                    file: e.target.files[0],
                                    uploadIndex,
                                    rowData: documentsData[uploadIndex],
                                  };
                                  docHandleDocumentUpload(data);
                                }}
                              />
                              <CustomButton
                                key={index}
                                label={VehicleManagement?.upload}
                                variant="outlined"
                                onClick={() => {
                                  handleDocumentUploadClick(index);
                                }}
                                startIcon={<CloudUploadIcon />}
                                sx={{
                                  padding: "10px 28px",
                                  minHeight: "42px",
                                  height: "42px",
                                }}
                                disabled={item?.uploadedDoc}
                                loading={
                                  uploadIndex === index
                                    ? getSignedUrlLoader
                                    : false
                                }
                              />
                            </Box>
                          )}
                          <Box
                            sx={{
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            {item?.type === VehicleDocumentType.OTHERS && (
                              <IconButton
                                children={
                                  <img
                                    src={CrossIcon}
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                }
                                onClick={() => deleteDocRow(item, index)}
                              />
                            )}
                          </Box>
                        </Box>
                      )}
                    </>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Stack>
    </Box>
  );
};

export default VehicleDocuments;
