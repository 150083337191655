import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import en from "../../../../locale/rental-en.json";
import { getCityByState } from "../../../../redux/actions/rentalCommonAction";
import { useDispatch } from "react-redux";
import { plus } from "../../../../constants/exportImages";
import { preventContextMenu } from "@fullcalendar/react";
import { typographyConstants } from "../../../../constants/constants";
import { clearStateByCities } from "../../../../redux/actions/businessManagementActions";
import { useState } from "react";

const AddServiceableCities = ({
  stateBasedCities,
  states,
  rows,
  setRows,
  editAccess,
}: any) => {
  const dispatch = useDispatch();
  const handleAddRow = () => {
    dispatch(clearStateByCities());
    setRows([
      {
        name: "",
        stateName: "",
        icon: "",
        errorState: "",
        errorName: "",
        cities: [],
      },
      ...rows,
    ]);
  };
  const handleDeleteRow = (index: number) => {
    setRows(rows.filter((row: any, i: number) => i !== index));
  };
  const handleStateChange = (state: string) => {
    dispatch(getCityByState(state));
  };

  return (
    <>
      <CustomButton
        startImageSrc={plus}
        variant="contained"
        label={en.NewBusinessManagement.addServiceableCities}
        style={{ width: "100%", height: "50px" }}
        onClick={handleAddRow}
        disabled={
          ((rows?.length && rows[0]?.name) || !rows.length) && editAccess
            ? false
            : true
        }
      />
      <div>
        <Table>
          <TableHead sx={{ padding: "0", borderBottom: "none", margin: "0" }}>
            {rows?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ padding: "0", borderBottom: "none", margin: "0" }}
                >
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 115 }}>
                    <InputLabel>
                      {row?.stateName === ""
                        ? en.NewBusinessManagement.selectState
                        : ""}
                    </InputLabel>
                    <Select
                      value={row.stateName}
                      onChange={(e) => {
                        const newRows = [...rows];
                        newRows[index].stateName = e.target.value;
                        newRows[index].name = "";
                        newRows[index].errorState = "";
                        newRows[index].cities = [];
                        rows[index].displayName = "";
                        setRows(newRows);
                        handleStateChange(e.target.value);
                      }}
                      readOnly={!editAccess}
                    >
                      <MenuItem value="">
                        <em>{en.NewBusinessManagement.selectState}</em>
                      </MenuItem>
                      {states?.map((state: any) => {
                        return (
                          <MenuItem value={state.name}>
                            {state.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {rows[index]?.errorState && (
                      <Typography
                        variant={typographyConstants.ERRORMESSAGE}
                        marginLeft="8px"
                      >
                        {en.errorMessages.requiredField}
                      </Typography>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell
                  sx={{ padding: "0", borderBottom: "none", margin: "0" }}
                >
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 115 }}>
                    <InputLabel>
                      {row?.name === ""
                        ? en.NewBusinessManagement.selectCity
                        : ""}
                    </InputLabel>
                    <Select
                      value={row.name}
                      onChange={(e) => {
                        const newRows = [...rows];
                        newRows[index].cities = rows[index].cities.length
                          ? rows[index].cities
                          : stateBasedCities;
                        newRows[index].name = e.target.value;
                        newRows[index].errorName = "";
                        setRows(newRows);
                      }}
                      readOnly={!editAccess}
                    >
                      <MenuItem value="">
                        <em>{en.NewBusinessManagement.selectCity}</em>
                      </MenuItem>
                      {rows[index]?.cities?.length
                        ? rows[index].cities
                            ?.filter((city: any) => {
                              return !rows.some(
                                (row: any, rowIndex: any) =>
                                  rowIndex !== index && row.name === city.name
                              );
                            })
                            ?.map((city: any) => {
                              return (
                                <MenuItem value={city.name}>
                                  {city.displayName}
                                </MenuItem>
                              );
                            })
                        : Array.isArray(stateBasedCities[index])
                        ? stateBasedCities
                            .find(
                              (item: any) =>
                                rows[index].stateName == item[0].state
                            )
                            ?.filter((city: any) => {
                              return !rows.some(
                                (row: any, rowIndex: any) =>
                                  rowIndex !== index && row.name === city.name
                              );
                            })
                            .map((city: any) => {
                              return (
                                <MenuItem value={city.name}>
                                  {city.displayName}
                                </MenuItem>
                              );
                            })
                        : stateBasedCities
                            ?.filter((city: any) => {
                              return !rows.some(
                                (row: any) => row.name === city.name
                              );
                            })
                            .map((city: any) => {
                              return (
                                <MenuItem value={city.name}>
                                  {city.displayName}
                                </MenuItem>
                              );
                            })}
                    </Select>
                    {rows[index]?.errorName && (
                      <Typography
                        variant={typographyConstants.ERRORMESSAGE}
                        marginLeft="8px"
                      >
                        {en.errorMessages.requiredField}
                      </Typography>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell
                  sx={{ padding: "0", borderBottom: "none", margin: "0" }}
                >
                  {editAccess && (
                    <RemoveCircleOutlineIcon
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </div>
    </>
  );
};
export default AddServiceableCities;
