export const StyledObject: any = {
  iotListField: {
    display: "flex",
    flexDirection: "row",
    gap: "5px 10px",
    flexWrap: "wrap",
    alignItems: "center",
  },
  editIotTableOuterBox: {
    border: " 1px solid #d3d3d3",
    borderRadius: "10px",
    boxShadow: "0 -3px 3px 1px #bcbcbc",
    width: "100%",
    padding: "10px 20px 20px 20px",
  },
  editIotFieldsOuterBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "flex-start",
  },
  editIotButtons: { display: "flex", gap: "20px", justifyContent: "flex-end" },
  bulkUploadOuterBox: {
    border: " 1px solid #d3d3d3",
    borderRadius: "10px",
    boxShadow: "0 -3px 3px 1px #bcbcbc",
    width: "100%",
    padding: "10px",
    gap: "10px",
  },
  bulkUploadButtonAlign: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    alignItems: "center",
    margin: "10px",
    alignContent: "center",
  },
  bulkUploadIotButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    margin: "10px",
  },
  bulkUploadModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "10px 30px 30px 30px",
  },
};
