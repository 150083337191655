import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Drawer,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import {
  PaymentFailedUI,
  PaymentSuccessUI,
  RenderCashUI,
  RenderPaymentLink,
  RenderQRCode,
} from "../../../../sharedComponents/templates/RenderPaymentMethods";
import { colors } from "../../../../themes/colors";
import {
  BookingPaymentStatus,
  DisplayPaymentModes,
  OrderPaymentType,
  typographyConstants,
} from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
import { rentalActions } from "../../../redux/actions";
import { getPaymentTypeText } from "../../../utils/helper";
import styles from "./section.module.css";
// selectedPaymentMode: String; ---send the payment mode you selected
// handlePaymentModalClose?: () => void;  --- optional field whatto do  when the drawer closes on click (when payment lik generated)
// handlePaymentButtonClick: () => void; --- on Click payment button what action to perform  mandatory (payment link generation api)
// totalCharges: number;-- total charges  in case of qr
// handlePaymentModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void; ---mode change event
// renderUI: JSX.Element; --extra ui
// toggleDrawer: () => void; --toggle drawer
// open: boolean; --- open drawer
interface PaymentSideDrawer {
  selectedPaymentMode: String;
  handlePaymentModalClose?: any;
  handlePaymentButtonClick: () => void;
  totalCharges?: any;
  handlePaymentModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderUI?: JSX.Element;
  toggleDrawer: () => void;
  open: boolean;
  isManualBookingCreated: boolean;
}
const PaymentSideDrawer = (props: PaymentSideDrawer) => {
  const {
    selectedPaymentMode,
    handlePaymentModalClose,
    handlePaymentButtonClick,
    handlePaymentModeChange,
    renderUI,
    toggleDrawer,
    open,
    isManualBookingCreated,
  } = props;
  const dispatch = useDispatch();
  const {
    operatorConfigData,
    isPaymentLinkGenerated,
    paymentLink,
    paymentLoader,
    updatedStatus,
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const { paymentLoader: bookingCreateLoader } = useSelector(
    (state: RootState) => state.newManualBookingReducer
  );

  const tryAnotherPaymentMethod = () => {
    dispatch(rentalActions?.togglePaymentView());
    dispatch(rentalActions.cancelPaymentStatus());
  };
  const renderPaymentOptions = () => (
    <Stack gap="15px">
      {renderUI}
      <Box>
        <Typography
          variant={typographyConstants.SUBHEADING}
          fontSize={"12px"}
          color={colors.text_secondary_gray}
          fontWeight="500"
          marginBottom="5px"
        >
          {en.manualBooking.SelectPaymentMethod}:
        </Typography>
        <Box
          className={styles.selectPaymentMethod}
          border={colors.text_secondary_gray}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedPaymentMode}
            onChange={(e: any) => handlePaymentModeChange(e)}
          >
            {operatorConfigData?.allowedPaymentFlows?.map(
              (mode: any, index: any) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: index < 2 ? "1px solid #D4E4E452" : "",
                    padding: "2px 18px",
                  }}
                >
                  <FormControlLabel
                    value={mode}
                    control={<Radio />}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: colors.text_secondary_gray,
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                    }}
                    label={
                      DisplayPaymentModes[
                        mode as keyof typeof DisplayPaymentModes
                      ]
                    }
                  />
                </Box>
              )
            )}
          </RadioGroup>
        </Box>
      </Box>
    </Stack>
  );

  //render the ui for payment types selected
  const renderContent = () => {
    if (updatedStatus === BookingPaymentStatus.SUCCESS) {
      return <PaymentSuccessUI paymentLink={paymentLink} />;
    } else if (updatedStatus === BookingPaymentStatus.FAILURE) {
      return <PaymentFailedUI paymentLink={paymentLink} />;
    } else {
      if (isPaymentLinkGenerated && paymentLink) {
        if (paymentLink.paymentFlow === OrderPaymentType.QR_CODE) {
          return paymentLink.pgDetails?.imageContent ? (
            <RenderQRCode
              paymentLink={paymentLink}
              totalCharges={paymentLink?.amount}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              isPaymentLinkGenerated={isPaymentLinkGenerated}
              onSuccesTimerCompletion={handlePaymentModalClose}
            />
          ) : (
            <Stack gap="20px" alignItems="center">
              <RenderPaymentLink
                paymentLink={paymentLink}
                tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              />
            </Stack>
          );
        }
        if (paymentLink.paymentFlow === OrderPaymentType.PAYMENT_LINK) {
          return (
            <RenderPaymentLink
              paymentLink={paymentLink}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
        if (paymentLink.paymentFlow === OrderPaymentType.CASH) {
          return (
            <RenderCashUI
              paymentLink={paymentLink}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
      }
    }
    return renderPaymentOptions();
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          width: "380px",
          background: "#f6fbf9",
          padding: "8px 26px 23px 26px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      }}
    >
      <Box className={styles.BoxColumn} gap="3px" width="100%">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "-4px",
          }}
        >
          <IconButton
            onClick={
              !isManualBookingCreated
                ? toggleDrawer
                : () => {
                    handlePaymentModalClose();
                  }
            }
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {renderContent()}
      </Box>
      <Box sx={{ width: "100%" }}>
        {!isPaymentLinkGenerated && (
          <CustomButton
            variant={!selectedPaymentMode ? "outlined" : "contained"}
            label={getPaymentTypeText(selectedPaymentMode)}
            sx={{ width: "100%" }}
            onClick={handlePaymentButtonClick}
            disabled={!selectedPaymentMode}
            loading={paymentLoader || bookingCreateLoader}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default PaymentSideDrawer;
