import { theme } from "../../../../../themes";

export const StyleObject: any = {
  muiTextFieldRootStyle: {
    width: 1,
    minWidth: "unset",
    minHeight: "40px",
    height: "40px",
    maxHeight: "40px",
  },
  wrapperView: {
    minHeight: "85dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tabStyle1: {
    padding: "5px 30px",
    borderRadius: "20px",
  },
  bottomButtonView_wrapperBox: {
    display: "flex",
    width: "100%",
    height: "40px",
    backgroundColor: "#fbfbfb",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  assignIotStackStyle: {
    direction: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  vehicleDetailsWrapperBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    rowGap: 2,
    paddingBottom: "40px",
  },
  customIconButtonStyle: {
    background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
    height: "25px",
    width: "40px",
    borderRadius: "20px",
    color: "white",
    "&:hover": {
      background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      color: "white",
      border: "none",
    },
    "&:active": {
      background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
      color: "white",
    },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 10,
    padding: "0px",
  },

  containerPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
};
