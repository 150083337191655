import React from "react";

function OtherIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        stroke={props?.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.75 3v12c0 .707 0 1.06.22 1.28.22.22.573.22 1.28.22h10.5c.707 0 1.06 0 1.28-.22.22-.22.22-.573.22-1.28V3M8.375 6v1.125m0 0V8.25m0-1.125h2.25m0-1.125v1.125m0 0V8.25"
      ></path>
      <path
        stroke={props?.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11 16.5v-3a1.5 1.5 0 00-3 0v3M2 3h4.5c.48-.88 1.642-1.5 3-1.5s2.52.62 3 1.5H17M5 6h.75M5 9h.75M5 12h.75M13.25 6H14m-.75 3H14m-.75 3H14"
      ></path>
    </svg>
  );
}

export default OtherIcon;
