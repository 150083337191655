import { Box, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import en from "../../../../../../locale/en.json";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { typographyConstants } from "../../../../../constants/constants";
import { fadedCalenderIcon } from "../../../../../constants/exportImages";
import rentalEn from "../../../../../locale/rental-en.json";
import { manualBookingActions } from "../../../../../redux/actions";
import {
  CalDaysWithTimeStamp,
  getTimestampAtStartOfDay,
  istToTimestamp,
} from "../../../../../utils/helper";
import CardVehicleModal from "./CardVehicleModal";

const ModalAvailable = (props: any) => {
  const dispatch = useDispatch();

  const {
    handleChange,
    fields,
    masterPlanDurationsData,
    handleBookingVehicle,
    getFilteredModal,
  } = props;
 
  return (
    <>
      {getFilteredModal()?.length > 0 && (
        <Stack
          gap="30px"
          width="100%"
          justifyContent="space-between"
          padding="0px 20px 0px 20px"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack>
              <Typography
                variant={typographyConstants.HEADINGXL}
                padding="5px 0px"
              >
                {rentalEn?.VehicleManagement?.OtherAvailableModels}
              </Typography>
              <Typography
                variant={typographyConstants.SUBHEADING}
                color="#8A8A8E"
                padding="0px"
              >
                {`Displaying ${getFilteredModal().length} results`}
              </Typography>
            </Stack>
            <CustomSelect
              required
              icon={fadedCalenderIcon}
              placeholder={en.manualBooking.SelectPackage}
              value={fields.selectedPackage}
              customLabel={(data: any) => {
                return (
                  <div>
                    {data} {data === 1 ? ` day` : ` days`}
                  </div>
                );
              }}
              choice={masterPlanDurationsData}
              // errormessage={errorFields.selectedPackage}
              handleChange={(days: any) => {
                handleChange("selectedPackage", days);

                const payloadModels = {
                  payload: {
                    startDate: getTimestampAtStartOfDay(fields.calendarDate),
                    endDate: getTimestampAtStartOfDay(
                      istToTimestamp(
                        CalDaysWithTimeStamp(fields?.calendarDate, days)
                      )
                    ),
                    branches: [fields?.selectedBranch?.name],
                    useCase: fields?.selectedLob?.name,
                  },
                  showError: false,
                };

                dispatch(
                  manualBookingActions.getAvailableModels(payloadModels)
                );
              }}
              width="300px"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "24px 15px",
            }}
          >
            {getFilteredModal()?.map((item: any, index: any) => (
              <CardVehicleModal
                key={index}
                item={item}
                handleBookingVehicle={handleBookingVehicle}
              />
            ))}
          </Box>

          {/* <CustomButton
            label={showAll ? "Show Less" : "Show More"}
            variant="outlined"
            onClick={handleToggleShow}
          /> */}
        </Stack>
      )}
    </>
  );
};

export default ModalAvailable;
