import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomIconButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { AntSwitch } from "../../../../../sharedComponents/molecules/MuiSwitch";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import {
  HELMET_DISPLAY_NAME,
  modificationType,
  statusColorCode,
  typographyConstants,
} from "../../../../constants/constants";
import en from "../../../../locale/rental-en.json";
import { bookingManagementActions } from "../../../../redux/actions";
import {
  getBookingType,
  getDateTimeFromTimeStamp,
} from "../../../../utils/helper";
import { StyleObject } from "./styleObject";
import { ExtendRideDialog } from "../../../../interfaces/bookingManagement";
import {
  deleteModification,
  getBookingDetails,
  getModificationHitory,
} from "../../../../redux/actions/bookingManagementActions";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";

const { bookingDetails, global } = en;
const {
  modificationType: modificationTypeLocale,
  paymentStatusDisplay,
  paymentFlowDisplay,
} = bookingDetails;

// cards component
const ModifyBookingCards = (props: any) => {
  const {
    heading = "",
    changedOn = "",
    cardContent = <></>,
    maxHeight = "auto",
    minHeight = "auto",
    loading = false,
  } = props;
  return (
    <Box
      sx={{
        width: "300px",
        backgroundColor: colors.white,
        borderRadius: "8px",
        boxShadow: "0px 4px 10px 0px #0000000D",
        border: "1px solid #F0F0F0",
        padding: "16px",
        maxHeight: maxHeight,
        minHeight: minHeight,
      }}
    >
      <Stack gap={"8px"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* card header */}

          {loading ? (
            <Skeleton
              sx={{ backgroundColor: colors.sherpa_blue }}
              width={"60px"}
            />
          ) : (
            <Box
              sx={{
                padding: "2px 4px 2px 4px",
                backgroundColor: colors.sherpa_blue,
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{ color: colors.white, fontSize: "13px" }}
                variant={typographyConstants.BODY}
              >
                {heading}
              </Typography>{" "}
            </Box>
          )}

          <Box sx={{ display: "flex", gap: "5px" }}>
            <Typography
              sx={{
                fontWeight: 500,
                color: colors.tertiary_black,
                fontSize: "12px",
              }}
            >
              {bookingDetails.changedOn}
            </Typography>
            {loading ? (
              <Skeleton width={"90px"} />
            ) : (
              <Typography
                sx={{
                  fontWeight: 400,
                  color: colors.text_black,
                  fontSize: "12px",
                }}
              >
                {changedOn}
              </Typography>
            )}
          </Box>
        </Box>

        <Box>{cardContent}</Box>
      </Stack>
    </Box>
  );
};

// main component
const ModifyBooking = (props: any) => {
  const {
    toggleModificationSwitch = () => {},
    modifyHistorySwitch = false,
    modificationHistoryLoader = false,
    modificationHistoryData = [],
    bookingId,
    recentModificationHistoryData,
    recentModificationHistoryLoader,
    setModifyHistorySwitch
  } = props;

  const modificationdata = {
    vehileModification: recentModificationHistoryData.get(
      "VEHICLE_MODIFICATION"
    ),
    helmetModification: recentModificationHistoryData.get(
      "HELMET_MODIFICATION"
    ),
    planModification: recentModificationHistoryData.get("PLAN_MODIFICATION"),
    planVariantModification: recentModificationHistoryData.get(
      "PLAN_VARIANT_MODIFICATION"
    ),
    slotsModification: recentModificationHistoryData.get("SLOTS_MODIFICATION"),
    modelModification: recentModificationHistoryData.get("MODEL_MODIFICATION"),
    returnDeliveryModification: recentModificationHistoryData.get(
      "RETURN_DELIVERY_MODIFICATION"
    ),
    deliveryModification: recentModificationHistoryData.get(
      "DELIVERY_MODIFICATION"
    ),
  };

  const dispatch = useDispatch();

  // state to handle the responsive dialog
  const [dialogData, setDialogData] = useState<ExtendRideDialog>({
    openDialog: false,
    key: "",
  });

  useEffect(() => {
    dispatch(
      bookingManagementActions.getRecentModificationHistory({
        bookingId,
      })
    );
    setModifyHistorySwitch(false);
  }, []);

  // generate unique row id for data grid
  const getRowsId = (row: any) => row.id;

  // delete extension success callback
  const successCallback = () => {
    setDialogData((prev: ExtendRideDialog) => ({
      ...prev,
      key: "",
      openDialog: false,
    }));
    //getExtension
    dispatch(getModificationHitory(bookingId));
  };

  //handle yes of delete extension
  const handleConfirmation = () => {
    if (dialogData.key) {
      dispatch(
        deleteModification({
          bookingId,
          successCallback,
        })
      );
    }
  };
  // Columns for data grid
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: bookingDetails.createdOn,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.createdAt ? params?.row?.createdAt : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "modifiedOn",
      headerName: bookingDetails.modifiedOn,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.modifiedOn ? params?.row?.modifiedOn : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "category",
      headerName: bookingDetails.category,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={
            params?.row?.category
              ? modificationTypeLocale[
                  params?.row?.category as keyof typeof modificationTypeLocale
                ]
              : ""
          }
        >
          <Box
            sx={{
              fontWeight: 500,
              color: colors.sherpa_blue,
            }}
          >
            {params?.row?.category
              ? modificationTypeLocale[
                  params?.row?.category as keyof typeof modificationTypeLocale
                ]
              : global.NA}
          </Box>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "modifiedFrom",
      headerName: bookingDetails.modifiedFrom,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.modifiedFrom}>
          <span>
            {params?.row?.modifiedFrom ? params?.row?.modifiedFrom : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "modifiedTo",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.modifiedTo,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.modifiedTo}>
          <span>
            {params?.row?.modifiedTo ? params?.row?.modifiedTo : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "amount",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.amount,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.amount ? `${params?.row?.amount}` : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "paymentType",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.paymentMode,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.paymentType !== "NA"
            ? paymentFlowDisplay[
                params?.row?.paymentType as keyof typeof paymentFlowDisplay
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "paymentStatus",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.modificationStatus,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box
          sx={{
            fontWeight: 500,
            color:
              statusColorCode[
                params?.row?.status as keyof typeof statusColorCode
              ],
          }}
        >
          {params?.row?.status
            ? paymentStatusDisplay[
                params?.row?.status as keyof typeof paymentStatusDisplay
              ]
            : global.NA}
        </Box>
      ),
      flex: 1,
    },
    {
      type: "actions",
      field: "action",
      headerName: en.global.action,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      getActions: (params: any) => {
        const status: string = params.row.status;
        return [
          <CustomIconButton
            onClick={() =>
              setDialogData((prev: ExtendRideDialog) => ({
                ...prev,
                openDialog: true,
                key: bookingId,
              }))
            }
            children={
              <DeleteIcon color={status === "PENDING" ? "error" : "disabled"} />
            }
            disabled={status !== "PENDING"}
          />,
        ];
      },
      flex: 1,
    },
  ];

  return (
    <Stack gap={"20px"} sx={{ padding: "22px 10px 20px 10px" }}>
      <Box
        sx={{
          display: "flex",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        {recentModificationHistoryLoader ? (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <ModifyBookingCards
              loading={true}
              cardContent={
                <Stack gap={"16px"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                </Stack>
              }
            />
            <ModifyBookingCards
              loading={true}
              cardContent={
                <Stack gap={"10px"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                </Stack>
              }
            />
            <ModifyBookingCards
              loading={true}
              cardContent={
                <Stack gap={"10px"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={"80px"} />

                    <Skeleton width={"80px"} />
                  </Box>
                </Stack>
              }
            />
          </Box>
        ) : (
          <>
            {" "}
            {modificationdata.helmetModification ||
            modificationdata.returnDeliveryModification ||
            modificationdata.slotsModification ||
            modificationdata.vehileModification ||
            modificationdata.planVariantModification ||
            modificationdata.modelModification ||
            modificationdata.planModification ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                {/* Plan change card */}
                {modificationdata.planModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[modificationType.PLAN_MODIFICATION]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.planModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack sx={{ gap: "6px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.startDate} :`}
                            </Typography>
                            <Typography
                              sx={{
                                ...StyleObject.cardContentValue,
                                overflow: "hidden",
                                width: "100px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {`${getDateTimeFromTimeStamp(
                                modificationdata.planModification?.modifiedTo
                                  ?.startDate,
                                "date"
                              )}`}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.endDate} :`}
                            </Typography>
                            <Typography
                              sx={{
                                ...StyleObject.cardContentValue,
                                overflow: "hidden",
                                width: "100px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >{`${getDateTimeFromTimeStamp(
                              modificationdata.planModification?.modifiedTo
                                ?.endDate,
                              "date"
                            )}`}</Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${bookingDetails.paymentStatus} :`}
                            </Typography>
                            <Typography
                              sx={{
                                ...StyleObject.cardContentValue,
                                fontWeight: 500,
                                color:
                                  statusColorCode[
                                    modificationdata.planModification
                                      ?.status as keyof typeof statusColorCode
                                  ],
                              }}
                            >
                              {
                                paymentStatusDisplay[
                                  modificationdata.planModification
                                    ?.status as keyof typeof paymentStatusDisplay
                                ]
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    }
                    minHeight={"116px"}
                  />
                )}

                {/* model change card */}
                {modificationdata.deliveryModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.DELIVERY_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.deliveryModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack
                        sx={{
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${global.address} :`}
                          </Typography>
                          <Tooltip
                            title={
                              modificationdata.deliveryModification?.modifiedTo
                            }
                          >
                            <Typography
                              sx={{
                                ...StyleObject.cardContentValue,
                                overflow: "hidden",
                                width: "200px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {
                                modificationdata.deliveryModification
                                  ?.modifiedTo
                              }
                            </Typography>
                          </Tooltip>
                        </Box>

                        <Box>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.deliveryModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.deliveryModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}

                {/* model change card */}
                {modificationdata.modelModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.MODEL_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.modelModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack
                        sx={{
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${global.vehicleModel} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              overflow: "hidden",
                              width: "100px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {modificationdata.modelModification?.modifiedTo}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.modelModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.modelModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}

                {/* plan variant change card */}
                {modificationdata.planVariantModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.PLAN_VARIANT_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.planVariantModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack sx={{ gap: "6px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${bookingDetails.modifiedFrom} :`}
                            </Typography>
                            <Typography
                              sx={{
                                ...StyleObject.cardContentValue,
                                overflow: "hidden",
                                width: "100px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {getBookingType(
                                modificationdata.modelModification?.modifiedTo
                                  ?.unlimited,
                                modificationdata.modelModification?.modifiedTo
                                  ?.premium
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.planVariantModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.planVariantModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}

                {/* vehicle model change card */}
                {modificationdata.vehileModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.VEHICLE_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.vehileModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack gap={"10px"}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.registrationNumber} :`}
                            </Typography>
                            <Typography sx={StyleObject.cardContentValue}>
                              {modificationdata.vehileModification?.modifiedTo
                                ? modificationdata.vehileModification
                                    ?.modifiedTo
                                : global.NA}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box sx={{ display: "flex", gap: "6px" }}>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.vehileModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.vehileModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}

                {/* slots change card */}
                {modificationdata.slotsModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.SLOTS_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.slotsModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack gap={"10px"}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.start} :`}
                            </Typography>
                            <Typography sx={StyleObject.cardContentValue}>
                              {
                                modificationdata.slotsModification?.modifiedTo
                                  ?.start
                              }
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.end} :`}
                            </Typography>
                            <Typography sx={StyleObject.cardContentValue}>
                              {
                                modificationdata.slotsModification?.modifiedTo
                                  ?.end
                              }
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.slotsModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.slotsModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Stack>
                      </Stack>
                    }
                  />
                )}

                {/* return delivery change card */}
                {modificationdata.returnDeliveryModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.RETURN_DELIVERY_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.returnDeliveryModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack gap={"10px"}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.address} :`}
                            </Typography>
                            <Typography sx={StyleObject.cardContentValue}>
                              {modificationdata.returnDeliveryModification
                                ?.modifiedTo
                                ? modificationdata.returnDeliveryModification
                                    ?.modifiedTo
                                : global.NA}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box sx={{ display: "flex", gap: "6px" }}>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.returnDeliveryModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.returnDeliveryModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}

                {/* helmet change card */}
                {modificationdata.helmetModification && (
                  <ModifyBookingCards
                    heading={
                      modificationTypeLocale[
                        modificationType.HELMET_MODIFICATION
                      ]
                    }
                    changedOn={getDateTimeFromTimeStamp(
                      modificationdata.helmetModification?.createdAt,
                      "date"
                    )}
                    cardContent={
                      <Stack gap={"10px"}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack>
                            <Typography sx={StyleObject.cardContentKey}>
                              {`${global.helmet} :`}
                            </Typography>
                            {modificationdata.helmetModification?.modifiedTo?.map(
                              (item: any, index: number) => (
                                <Typography sx={StyleObject.cardContentValue}>
                                  {`${HELMET_DISPLAY_NAME[item?.type]} : ${
                                    item?.count
                                  }`}
                                </Typography>
                              )
                            )}
                          </Stack>
                        </Box>
                        <Box sx={{ gap: "6px" }}>
                          <Typography sx={StyleObject.cardContentKey}>
                            {`${bookingDetails.paymentStatus} :`}
                          </Typography>
                          <Typography
                            sx={{
                              ...StyleObject.cardContentValue,
                              fontWeight: 500,
                              color:
                                statusColorCode[
                                  modificationdata.helmetModification
                                    ?.status as keyof typeof statusColorCode
                                ],
                            }}
                          >
                            {
                              paymentStatusDisplay[
                                modificationdata.helmetModification
                                  ?.status as keyof typeof paymentStatusDisplay
                              ]
                            }
                          </Typography>
                        </Box>
                      </Stack>
                    }
                  />
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant={typographyConstants.SUBHEADING}
                  sx={{
                    color: colors.text_black,
                    fontWeight: 500,
                  }}
                >
                  {bookingDetails.modificationNotFound}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      <Stack padding={"10px"} gap={"10px"}>
        {" "}
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: colors.text_black }}
          >
            {bookingDetails.modifyHistory}
          </Typography>
          <AntSwitch
            checked={modifyHistorySwitch}
            onChange={() => toggleModificationSwitch()}
          />
        </Box>
        {modifyHistorySwitch ? (
          <DataTable
            columns={columns}
            rows={modificationHistoryData}
            hideFooter={true}
            disableColumnMenu
            disableColumnSorting
            headerAlign={"center"}
            getRowId={getRowsId}
            loading={modificationHistoryLoader}
            hideColumnSeperator
            disableRowSelectionOnClick
            disableColumnResize
            paginationMode="server"
          />
        ) : null}
      </Stack>

      {/* Confirnmation Dialog For Deleteing The extension  */}
      <ResponsiveDialog
        title={en.bookingDetails.deleteModification}
        body={en.alertMessages.deleteModificationConfirmation}
        open={dialogData?.openDialog}
        handleClose={() => setDialogData({ openDialog: false, key: "" })}
        handleYes={handleConfirmation}
      />
    </Stack>
  );
};
export default ModifyBooking;
