import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Avatar, CardHeader, Grid, Typography } from "@mui/material";
import { colors } from "../../../../../themes/colors";
import { CardBackGround } from "../../../../constants/exportImages";
import { typographyConstants } from "../../../../constants/constants";
const DetailsCard = (props: any) => {
  const handleClick = (data: any) => {
    props.handleClick(data);
  };
  return (
    <Grid item xs={4}>
      <Card onClick={handleClick}>
        <CardHeader
          sx={{
            backgroundColor: colors.side_drawer_heading_bg_color,
            height: "52px",
            padding: "10px",
            color: colors.white,
          }}
          titleTypographyProps={{ variant: "subtitle2" }}
          title={props?.title}
          avatar={
            <Avatar
              alt="img not found"
              src={props.icon}
              sx={{ width: "25px", height: "25px" }}
            />
          }
        />
        <CardContent sx={{ backgroundImage: `url(${CardBackGround})` }}>
          {props?.keys.map((item: any, index: any) => (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={typographyConstants.SUBHEADING}
                  sx={{ color: colors.text_secondary_gray }}
                >
                  {item.displayName}{" "}
                </Typography>
                <Typography variant={typographyConstants.SUBHEADING}>
                  {props.values[item.name] ?? "0"}
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};
export default DetailsCard;
