import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  OperatorKycStatus,
  typographyConstants,
} from "../../../../constants/constants";
import Style from "../index.module.css";
import SendAggrementModal from "../../leads/section/sendAggrementModal";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import rentalEn from "../../../../locale/rental-en.json";
import { colors } from "../../../../../themes/colors";
import { aggreementPreview } from "../../../../constants/exportImages";
import { formatString } from "../../../../utils/helper";

const PreviewDigitalAgreement = ({
  aggreementData,
  fields,
  handleChange,
  handleOnView,
}: any) => {
  // data from operator management reducer
  const { operatorDetails, loading } = useSelector(
    (state: RootState) => state.operatorManagementReducer
  );

  // hit api to get argeement data then open modal
  const viewAgreement = () => {
    if (!handleOnView) {
      handleChange("openAgreementModal", true);
    } else {
      handleOnView();
    }
  };
  // toggle agreement modal
  const toggleAggrementModal = () => {
    handleChange("openAgreementModal", !fields.openAgreementModal);
  };

  return (
    <>
      <Stack gap={"18px"}>
        <Box>
          <Typography variant={typographyConstants.HEADING}>
            {rentalEn.operatorManagement.digitalAgreement}
          </Typography>
          <Typography
            variant={typographyConstants.HEADING}
            sx={{ color: colors.light_green, ml: 2 }}
          >
            {aggreementData?.operatorAgreementStatus ===
            OperatorKycStatus.VERIFIED
              ? rentalEn.operatorManagement.signed
              : rentalEn.operatorManagement.notSigned}
          </Typography>
        </Box>
        <Box
          className={Style.aggreementContainer}
          sx={{ position: "relative" }}
          onClick={viewAgreement}
        >
          <img
            className={Style.aggreementThumbnail}
            style={{ width: "180px", height: "100px" }}
            src={aggreementPreview}
          />
          <Typography className={Style.imageText}>
            {rentalEn.global.view}
          </Typography>
        </Box>
      </Stack>
      <SendAggrementModal
        isOpen={fields.openAgreementModal}
        handleClose={toggleAggrementModal}
        loading={loading}
        aggreementData={aggreementData}
      />
    </>
  );
};

export default PreviewDigitalAgreement;
