import { Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  ButtonActions,
  UserActions,
  SlotPauseRoutes,
} from "../../constants/userActions";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import StickyHeaderTable from "../../components/stickeyHeader";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux";
import { toastr } from "react-redux-toastr";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import { DefaultProps } from "../../interfaces/commonInterface";
import { UserStatus } from "../../constants/bookingContants";
import { Status } from "../../components/status";

function SlotPause(props: DefaultProps) {
  const dispatch = useDispatch();
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { slotPauseData, loading, totalItems, deleted, deleteSlotRefresh } = useSelector(
    (state: RootState) => state.slotPauseReducer
  );


  const { promotions, slotPause, global, alertMessages } = locale;
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const [modifiers, setModifiers] = useState<any>({
    birthday: [],
  });

  const [type, setType] = useState<any>("List");
  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
  };
  const [orderBy, setOrderBy] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const handlePage = (page: any) => {
    setPage(page);
  };
  const handleRows = (row: any) => {
    setSize(row);
  };
  useEffect(() => {
    getSlotPause();
  }, [page, size, orderBy]);

  useEffect(() => {
    deleteSlotRefresh && getSlotPause();
  }, [deleteSlotRefresh])

  const getSlotPause = () => {
    // dispatch(
    //   actions.getSlotPause({
    //     page: page + 1,
    //     size: size,
    //     orderBy: orderBy,
    //   })
    // );

    // api issue
    dispatch(
      actions.getSlotPause({
        version2: true,
        page: page + 1,
        size: size,
        orderBy: orderBy,
      })
    );
  };
  // const columns = [
  //   {
  //     id: "date_to_pause",
  //     label: slotPause.Date,
  //     sortable: true,
  //     sortKey: "date_to_pause",
  //     direction: "asc",
  //     minWidth: 120,
  //     customJson: true,
  //     render: (item: any) => {
  //       return (
  //         <div>
  //           {moment(item?.date_to_pause, "YYYY-MM-DD").format("DD-MM-YYYY") ||
  //             null}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     id: "timeSlots",
  //     label: slotPause.TimeSlots,
  //     minWidth: 120,
  //     sortable: true,
  //     sortKey: "option_name",
  //     direction: "asc",
  //     align: "center",
  //   },
  //   {
  //     id: "cities",
  //     label: slotPause.ApplicableBranches,
  //     minWidth: 120,
  //     sortable: true,
  //     sortKey: "city_name",
  //     direction: "asc",
  //     align: "center",
  //   },
  //   {
  //     id: "status",
  //     label: slotPause.Status,
  //     minWidth: 100,
  //     align: "center",
  //   },

  //   {
  //     id: "action",
  //     label: slotPause.Action,
  //     minWidth: 170,
  //     align: "center",
  //   },
  // ];
  //used this for sorting(Overriding columns)
  const columns = [
    {
      id: "date_to_pause",
      label: slotPause.Date,
      sortable: true,
      sortKey: "date_to_pause",
      direction: "asc",
      minWidth: 120,
      customJson: true,
      render: (item: any) => {
        return (
          <div>
            {moment(item?.date_to_pause, "YYYY-MM-DD").format("DD-MM-YYYY") ||
              null}
          </div>
        );
      },
    },
    {
      id: "slot_pause_timeslots",
      label: global.TimeSlots,
      minWidth: 120,
      align: "center",
      sortable: true,
      sortKey: "option_name",
      direction: "asc",
      customJson: true,
      render: (items: any) => {
        const _map: any = {
          "1": "Full Day Pause",
          "2": "Home Delivery",
          "3": "Pickup pause",
        };
        const _id = items?.slot_pause_option?.id ?? 0;
        return (
          <text style={{ wordBreak: "break-word" }}>
            <text>
              {_map[_id]} {_id !== 1 ? "(" : ""}
              {items?.slot_pause_timeslots?.map((each: any, i: number) => {
                let str =
                  each?.timeSlot?.fromTime + "-" + each?.timeSlot?.toTime ?? "";
                if (items?.slot_pause_timeslots?.length - 1 !== i) {
                  str += ", ";
                }
                return str;
              })}
              {_id !== 1 ? ")" : ""}
            </text>
          </text>
        );
      },
    },
    {
      id: "applicable_branch",
      label: global.ApplicableBranch,
      sortOrder: 0,
      minWidth: 100,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return <div>{item?.branch?.branch_name || "N/A"}</div>;
      },
    },
    {
      id: "status_name",
      label: global.Status,
      sortOrder: 0,
      minWidth: 100,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return (
          <Status
            status={
              item?.status_master?.id === 1
                ? UserStatus.Active
                : UserStatus.InActive
            }
          />
        );
      },
    },

    {
      id: "action",
      label: "Actions",
      minWidth: 140,
      align: "center",
      format: (value: any) => value.toFixed(2),
    },
  ];
  const [mainColumns, setMainColumns] = useState<any>(columns);

  //Sorting Table based on column
  const sortData = async (param: any) => {
    let headerDetails: any = mainColumns;
    headerDetails[param.item].direction =
      headerDetails[param.item]?.direction === "asc" ? "desc" : "asc";
    setMainColumns(headerDetails);
    setOrderBy([
      {
        name: param.key,
        value: param.direction,
      },
    ]);
  };
  const deleteSlotPause = (data: any) => {
    const toastrConfirmOptions = {
      onOk: async () => {
        dispatch(
          actions.deleteSlotPause({
            date_to_pause: data?.date_to_pause,
            branch_id: data?.branch_id,
            // city_id: 1,
            // location_id: 1,
            option_data: [
              {
                option_id: data?.slot_pause_option?.id,
              },
            ],
          })
        );
      },
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(alertMessages.deleteItem, toastrConfirmOptions);
  };

  useEffect(() => {
    for (var i = 0; i < slotPauseData?.length; i++) {
      modifiers.birthday.push(new Date(slotPauseData[i]?.date_to_pause));
    }
  }, [slotPauseData]);

  const changeType = () => {
    if (type == "List") {
      setType("Calendar");
    } else {
      setType("List");
    }
  };

  useEffect(() => {
    getSlotPause();
  }, [deleted]);

  const handleDayClick = (day: any) => {
    const findDate = modifiers.birthday.findIndex(
      (data: any) =>
        moment(data).format("YYYY-MM-DD") == moment(day).format("YYYY-MM-DD")
    );
    if (findDate != -1) {
      navigate(SlotPauseRoutes.view + moment(day).format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="container pd-20">
      <Heading
        title={slotPause.SlotPause}
        actionButtons={[
          ButtonActions.refresh,
          type == "List" ? ButtonActions.calendarView : ButtonActions.listView,
          ButtonActions.addSlots,
        ]}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.addSlots) {
            navigate(SlotPauseRoutes.add);
          }
          if (type === ButtonActions.refresh) {
            getSlotPause();
            return;
          }
          if (type === ButtonActions.calendarView || ButtonActions.listView) {
            changeType();
          }
        }}
      />
      {type == "List" ? (
        <StickyHeaderTable
          loading={loading}
          paddingAction="4px"
          rows={slotPauseData}
          totalItems={totalItems}
          page={page}
          size={size}
          handleSort={sortData}
          pageSetter={handlePage}
          rowsPerPageSetter={handleRows}
          actions={[UserActions.view, UserActions.update, UserActions.delete]}
          columns={mainColumns}
          onClickAction={(
            type: string,
            id: number | string,
            row: any,
            allData: any
          ) => {
            if (type === UserActions.update) {
              navigate(SlotPauseRoutes.edit + allData.date_to_pause, {
                state: { ...allData?.branch, option_id: allData.option_id },
              });
            } else if (type === UserActions.view) {
              navigate(SlotPauseRoutes.view + allData.date_to_pause, {
                state: { ...allData?.branch, option_id: allData.option_id },
              });
            } else if (type === UserActions.delete) {
              deleteSlotPause(allData);
            }
          }}
        />
      ) : (
        <Grid>
          <DayPicker
            mode="multiple"
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            onDayClick={(day: any) => handleDayClick(day)}
            defaultMonth={new Date()}
            fromMonth={new Date()}
            showOutsideDays
            pagedNavigation
            numberOfMonths={3}
          />
        </Grid>
      )}
    </div>
  );
}

export default SlotPause;
