import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "..";
import en from "../../locale/en.json";
import * as actionTypes from "../actions/actionTypes";
import { ToastType } from "../../constants/userActions";
import reportServices from "../../services/reportServices";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utils/helper";
import { reportsGQLService } from "../../services";

function* fetchUserReports(action: any): any {
  try {
    const result = yield call(
      reportsGQLService.getGqlReportsService,
      action.payload
    );
    if (result) {
      yield put({
        type: actionTypes.GET_REPORTS.GET_USER_REPORTS_SUCCESS,
        payload: {
          reportData: result?.data?.getReports ?? {},
        },
      });
    } else {
      toastr.error("", handleMessage(result));
      yield put({
        type: actionTypes.GET_REPORTS.GET_USER_REPORTS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(""));
    yield put({
      type: actionTypes.GET_REPORTS.GET_USER_REPORTS_FAILED,
    });
  }
}

function* getGenericReportList(): any {
  try {
    const result = yield call(reportsGQLService.getGqlReportsListService);
    if (result) {
      yield put({
        type: actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_SUCCESS,
        payload: {
          genericReportList: result?.data?.getGenericReportsDescription ?? [],
        },
      });
    } else {
      toastr.error("", handleMessage(result));
      yield put({
        type: actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(""));
    yield put({
      type: actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_FAILED,
    });
  }
}

function* fetchBookingReports(action: any): any {
  const data = yield call(reportServices.getBookingReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.GET_BOOKING_REPORTS_SUCCESS,
      payload: {
        reportData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.GET_BOOKING_REPORTS_FAILED,
    });
  }
}

function* fetchPaymentReports(action: any): any {
  const data = yield call(reportServices.getPaymentReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.GET_PAYMENT_REPORTS_SUCCESS,
      payload: {
        reportData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.GET_PAYMENT_REPORTS_SUCCESS,
    });
  }
}

function* fetchGenericReports(action: any): any {
  const data = yield call(reportServices.getGenericReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.GET_GENERIC_REPORTS_SUCCESS,
      payload: {
        reportData: data?.data?.result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.GET_GENERIC_REPORTS_FAILED,
    });
  }
}

function* fetchPaymentReconReports(action: any): any {
  const data = yield call(
    reportServices.getPaymentReconciliationReports,
    action.payload
  );
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_SUCCESS,
      payload: {
        reportData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_FAILED,
    });
  }
}

function* reportSaga() {
  yield takeLatest(
    actionTypes.GET_REPORTS.GET_USER_REPORTS_REQUESTED,
    fetchUserReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.GET_BOOKING_REPORTS_REQUESTED,
    fetchBookingReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.GET_PAYMENT_REPORTS_REQUESTED,
    fetchPaymentReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_REQUESTED,
    fetchPaymentReconReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.GET_GENERIC_REPORTS_REQUESTED,
    fetchGenericReports
  );

  yield takeLatest(
    actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_REQUESTED,
    getGenericReportList
  );
}

export default reportSaga;
