export const REGEX = {
  alfa: /^[a-zA-Z ]*$/,
  alfaNumeric: /^[0-9a-zA-Z ]*$/,
  phone: new RegExp("^[6-9]([0-9]*)$"),
  numeric: /^[0-9]*$/,
  float: /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/,
  pin: /^[0-9]*$/,
  space: /^\S*$/,
  zipCode: /^\d{5}(-\d{4})?$/,
  regpan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
};

// validation for pan no.
export const checkPanNo = (panNo: string) => {
  if (REGEX.regpan.test(panNo)) {
    return true;
  } else {
    return false;
  }
};

export const checkWhiteSpace = (value: string) => {
  let valid = true;
  if (!REGEX.space.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkAlfaNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.alfaNumeric.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkAlfa = (value: string) => {
  let valid = true;
  if (!REGEX.alfa.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkFloat = (value: string) => {
  let valid = true;
  if (!REGEX.float.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkPhone = (value: string) => {
  let valid = true;
  if (!REGEX.phone.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkImeiPhone = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "" && value.length < 14) {
    valid = false;
  }
  return valid;
};

export const checkPin = (value: string) => {
  let valid = true;
  if (!REGEX.pin.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkZipCode = (value: string) => {
  let valid = true;
  if (!REGEX.zipCode.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

// regex to remove leading zero (using in input field having type no.)
export const removeLeadingZero = (value: string) => {
  if (value === "0") return value;
  return value.toString().replace(/^0+/, "");
};
