import React, { useState } from "react";
import {
 Box,
 Button,
 Chip,
 Card,
 Divider,
 TextField,
 FormControl,
 FormControlLabel,
 Grid,
 Radio,
 RadioGroup,
 Typography,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 CircularProgress,
 useMediaQuery,
 Tooltip,
 Paper,
} from "@mui/material";
import { colors } from "../../../../themes/colors";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../../redux";
import { RootState } from "../../../../redux/reducers";
const TabButton = ({ tabName, displayName, onClick, CurrentTab }: any) => {

    const dispatch = useDispatch();
    const {taskstatusById} = useSelector((state: RootState) => state.taskReducer);

 const [currentMenu, setCurrentMenu] = useState<string>("");


 return (
  <Grid
   xs={6}
   md={4}
   lg={3}
   sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
   }}
  >
   <div
    onClick={onClick}
    style={{
     backgroundColor: tabName == CurrentTab ? "#2bc7c9" : "#fff",
     borderRadius: "18px",
     width: "70%",
     height: 30,
     padding:"10px",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     border: `1px solid ${tabName == CurrentTab ? "#2bc7c9" : "#2bc7c9"}`,
     cursor:"pointer"
    }}
   >
    <Typography
     marginLeft={"0"}
     fontFamily="EuclidCircularB-Regular"
     fontSize={14}
     color={tabName == CurrentTab ? "#fff" : "#2bc7c9"}
     variant="body2"
     style= {{whiteSpace:"nowrap"}}
    >
     {displayName}
    </Typography>
   </div>
  </Grid>
 );
};

export default TabButton;
