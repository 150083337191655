import { colors } from "../../../../../themes/colors";

export const StyleObject: any = {
  urs_heading_role: {
    textAlign: "center",
    py: 1,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    backgroundColor: colors?.dark_green_bg,
  },
  urs_heading_state: {
    textAlign: "center",
    py: 1,
    backgroundColor: colors?.dark_green_bg,
  },
  urs_heading_city: {
    textAlign: "center",
    py: 1,
    backgroundColor: colors?.dark_green_bg,
  },
  urs_heading_hub: {
    textAlign: "center",
    py: 1,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundColor: colors?.dark_green_bg,
  },
  //
  urs_data_role: {
    textAlign: "center",
    py: 1,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    border: "0.5px solid black",
  },
  urs_data_state: {
    textAlign: "center",
    py: 1,
    border: "0.5px solid black",
  },
  urs_data_city: {
    textAlign: "center",
    py: 1,
    border: "0.5px solid black",
  },
  urs_data_hub: {
    textAlign: "center",
    py: 1,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    border: "0.5px solid black",
  },
  urs_noDataAvailable: {
    textAlign: "center",
    py: 1,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
};
