import { Co2Sharp } from "@mui/icons-material";
import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "..";
import TokenService from "../../config/tokenService";
import { ToastType } from "../../constants/userActions";
import couponServices from "../../services/couponServices";
import { handleMessage } from "../../utils/helper";
import * as actionTypes from "../actions/actionTypes";

function* fetchCouponById(action: any): any {
  const data = yield call(couponServices.getCouponById, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_BY_ID_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_BY_ID_FAILED,
    });
  }
}

function* deleteCoupon(action: any): any {
  const data = yield call(couponServices.deleteCoupon, action.payload);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.DELETEDATA_ACTIONS.DELETEDATA_SUCCESS,
      payload: {
        deletedDataId: action.payload.id,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.DELETEDATA_ACTIONS.DELETEDATA_FAILED,
    });
  }
}

function* editCoupon(action: any): any {
  const data = yield call(couponServices.editCoupon, action.payload);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.EDIT_COUPON_SUCCESS,
      payload: {
        addCouponsuccess: true,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.EDIT_COUPON_FAILED,
    });
  }
}

function* addCoupon(action: any): any {
  const data = yield call(couponServices.addCoupon, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.ADD_COUPON_SUCCESS,
      payload: {
        addCouponsuccess: true,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.ADD_COUPON_FAILED,
    });
  }
}

function* fetchCouponUsesById(action: any): any {
  const data = yield call(couponServices.getCouponUsesById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_USES_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_USES_FAILED,
    });
  }
}
function* fetchCouponRevenueById(action: any): any {
  const data = yield call(couponServices.getCouponRevenueById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_FAILED,
    });
  }
}
function* fetchCouponReveueDownloadById(action: any): any {
  const data = yield call(
    couponServices.getCouponRevenueDownloadById,
    action.payload
  );
  if (data?.status === 200) {
    if (action?.payload?.download_type) {
      if (data?.data?.Result?.length === 0) {
        //for blank csv
        toastr.warning("", handleMessage(data));
        yield put({
          type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_FAILED,
        });
      } else {
        yield put({
          type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_SUCCESS,
          payload: data.data,
        });
      }
    } else {
      if (data?.data?.Result?.length === 0) {
        toastr.warning("", handleMessage(data));
      } else {
        yield put({
          type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_SUCCESS,
          payload: data.data.Result,
        });
      }
    }
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_FAILED,
    });
  }
}

function* couponSaga() {
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPON_BY_ID_REQUESTED,
    fetchCouponById
  );
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.EDIT_COUPON_REQUESTED,
    editCoupon
  );
  yield takeLatest(actionTypes.COUPONS_ACTIONS.ADD_COUPON_REQUESTED, addCoupon);
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.DELETE_COUPON_REQUESTED,
    deleteCoupon
  );
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPON_USES_REQUESTED,
    fetchCouponUsesById
  );
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_REQUESTED,
    fetchCouponRevenueById
  );
  yield takeLatest(
    actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_REQUESTED,
    fetchCouponReveueDownloadById
  );
}

export default couponSaga;
