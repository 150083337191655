import * as actionTypes from "../actionTypes";

interface DashboardInterface {
  from_date: string | Date | null;
  end_date: string | Date | null;
}
interface DashboardDisplayTableInterface {
  page: number | null;
  size: number | null;
  date: Date | string | null;
  flag: string | null;
  is_download: number | null;
  orderBy?: any | null;
  // payment_mode?: any | null;
  // model_id?: any | null;
}

interface DashboardActionClickInterface {
  booking_id: number | null;
  accepted: boolean | null;
  rejected: boolean | null;
  reason: string | null;
}
interface DashboardViewCalendarInterface {
  vehicle_id: number | null;
}

interface Statistics {
  download_type?: any;
  start_date?: any;
  end_date?: any;
  model_id?: any;
  city_id?: any;
  branch_id?:any;
  is_download?: any;
  statDetailType?: string;
  vehicle_model_name?: string;
  city?: string;
  endDate?: string;
  startDate?: string;
  filters?: any;
}

export const dashboardStatic = (payload: any) => ({
  type: actionTypes.AUTH_ACTIONS.DASHBOARD_STATIC_REQUESTED,
  payload,
});

export const dashboardDisplayTable = (
  payload: DashboardDisplayTableInterface
) => {
  return {
    type: actionTypes.AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_REQUESTED,
    payload,
  };
};

export const dashboardCsvDowload = (
  payload: DashboardDisplayTableInterface
) => {
  return {
    type: actionTypes.CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_REQUESTED,
    payload,
  };
};

export const dashboardActionClick = (
  payload: DashboardActionClickInterface
) => {
  return {
    type: actionTypes.AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_REQUESTED,
    payload,
  };
};
export const dashboardViewCalendarClick = (
  payload: DashboardViewCalendarInterface
) => {
  return {
    type: actionTypes.AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_REQUESTED,
    payload,
  };
};

export const getVehicleUtilizationDates = (payload: Statistics) => {
  return {
    type: actionTypes.STATISTICS.VEHICLE_UTILIZATION_DATES_REQUESTED,
    payload,
  };
};

export const getVehicleUtilization = (payload: Statistics) => {
  return {
    type: actionTypes.STATISTICS.GET_VEHICLE_UTILIZATION_REQUESTED,
    payload,
  };
};
export const getPaymentGraph = (payload: Statistics) => {
  if (payload.is_download === 1) {
    return {
      type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_REQUESTED,
      payload,
    };
  } else {
    return {
      type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_REQUESTED,
      payload,
    };
  }
};
export const getPaymentGraphDateWise = (payload: Statistics) => {
  return {
    type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_REQUESTED,
    payload,
  };
};
export const getRevenueGraph = (payload: Statistics) => {
  if (payload.is_download === 1) {
    return {
      type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_REQUESTED,
      payload,
    };
  } else {
    return {
      type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_REQUESTED,
      payload,
    };
  }
};

export const getAllStatisticsData = (payload: Statistics) => {
  return {
    type: actionTypes.STATISTICS.GET_ALL_STATISTICS_DATA_REQUESTED,
    payload,
  };
};

