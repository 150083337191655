import { Box, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import SideDrawer from "../../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../../../themes/colors";
import {
  GenericObject,
  typographyConstants,
} from "../../../../../constants/constants";
import rentalEn from "../../../../../locale/rental-en.json";
import { blockUserAction } from "../../../../../redux/actions/businessManagementActions";

const { roleManagement, global } = rentalEn;

const initialState: GenericObject = {
  _id: "",
  description: "",
  remarks: "", // Optional
};

const StatusChangeDrawer = (props: any) => {
  const dispatch = useDispatch();

  // Destructuring props with default values
  const {
    toggleDrawer, // Toggles the drawer open or closed // i.e., toggleDrawer={(newOpen: boolean) => () => setOpenDrawer(newOpen)}
    openDrawer = false, // State variable for drawer open/close
    drawerHeading = roleManagement?.blockRole, // Heading of the drawer
    cardHeading = roleManagement?.userDetails, // Heading of the card inside the drawer
    bottomButtonLabel = roleManagement?.blockUser, // Label for the bottom button
    width = 1, // Width of the dropdown
    drawerData, // Data to display in card
    handleStatusUpdateSuccessCallback, // Callback function on API success
    loading = false, // Loader for button
  } = props;

  const { userBlockReasonData } = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );

  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [cardData, setCardData] = useState<GenericObject>({
    ...drawerData?.rowData,
  });
  const [blockReasonArray, setBlockReasonArray] = useState<GenericObject[]>([]);

  useEffect(() => {
    setFields({ ...initialState }); // Reset pre-selected data if any
    if (Object.keys(drawerData?.rowData)?.length) {
      let tempObj: GenericObject = {};
      tempObj[global?.name] = drawerData?.rowData?.name ?? global?.NA;
      tempObj[global?.mobileNo] = drawerData?.rowData?.phone ?? global?.NA;
      tempObj[global?.emailId] = drawerData?.rowData?.emailId ?? global?.NA;
      setCardData({ ...tempObj });
    }
  }, [JSON.stringify(drawerData?.rowData)]);

  useEffect(() => {
    if (userBlockReasonData?.length) {
      let tempArr: any = [];
      tempArr = userBlockReasonData?.filter((item: any) => {
        return item?.applicableFor?.includes("ADMIN");
      });
      setBlockReasonArray([...tempArr]);
    }
  }, [JSON.stringify(userBlockReasonData)]);

  // Handler for dropdown change
  const handleDropdownChange = (value: any) => setFields({ ...value });

  // Handler for user status change
  const statusChangeHandler = () => {
    // Construct payload & make API request for blocking user
    const payload: GenericObject = {
      data: {
        blockReason: fields?._id,
        remarks: fields?.remarks,
      },
    };
    dispatch(
      blockUserAction(
        payload,
        drawerData?.userId,
        handleStatusUpdateSuccessCallback
      )
    );
  };

  return (
    <SideDrawer
      open={openDrawer}
      heading={drawerHeading}
      toggleDrawer={toggleDrawer(false)}
      disablePadding={true}
      headerPadding={"13px 15px"}
      headingVariant={typographyConstants?.HEADINGXL}
      width={400}
      renderUI={
        <Stack sx={StyleObj?.topStack} role="presentation">
          <Stack spacing={3} width={1}>
            <Stack sx={StyleObj?.mainContent_topBox}>
              {/* Blocking Reason Dropdown */}
              <CustomSelect
                required
                placeholder={roleManagement?.selectBlockingReason}
                value={fields?.description || ""}
                choice={blockReasonArray}
                defaultKey={"description"}
                handleChange={(val: any) => handleDropdownChange(val)}
                width={width}
              />

              {/* Block Remarks Field */}
              <Input
                placeholder={roleManagement?.enterRemarks}
                variant="outlined"
                inputProps={{ maxLength: 150 }}
                value={fields?.remarks}
                onChange={(e: any) =>
                  setFields({ ...fields, remarks: e.target.value })
                }
                disabled={false}
                muiTextFieldRootStyle={{
                  width: 360,
                  minWidth: "unset",
                  minHeight: "40px",
                  height: "40px",
                  maxHeight: "40px",
                }}
              />

              {/* User Details Card */}
              <Box sx={StyleObj?.vehicleDetailsCard_topBox}>
                <Box sx={StyleObj?.vehicleDetailsCard_box2}>
                  <Typography variant={typographyConstants?.HEADING}>
                    {cardHeading}
                  </Typography>
                </Box>
                <Divider />

                {/* Mapping through cardContentArray to display user details */}
                <Box sx={{ px: 2 }}>
                  {Object.entries(cardData)?.map(([key, value]) => (
                    <Stack
                      key={key}
                      direction={"row"}
                      justifyContent={"space-between"}
                      py={1}
                    >
                      <Typography variant={typographyConstants?.SUBHEADING}>
                        {key}
                      </Typography>
                      <Typography variant={typographyConstants?.SUBHEADING}>
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Stack>
          </Stack>

          {/* Bottom button for confirming status change */}
          <CustomButton
            label={bottomButtonLabel}
            variant="outlined"
            sx={StyleObj?.customButtonStyle}
            onClick={statusChangeHandler}
            disabled={!fields?._id} // Button disabled if dropdownValue is empty
            loading={loading}
            wrapperStyles={{ width: 1 }}
          />
        </Stack>
      }
    />
  );
};

export default StatusChangeDrawer;

// Style (css) object for 'sx' prop of the MUi
const StyleObj: GenericObject = {
  topStack: {
    justifyContent: "space-between",
    alignItems: "center",
    height: 1, // Full height of the drawer
    padding: "12px 10px",
  },
  mainContent_topBox: {
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
    padding: "12px 10px",
  },
  vehicleDetailsCard_topBox: {
    width: "100%",
    borderRadius: 1,
    border: `1px solid ${colors?.gray_cccccc}`,
    boxShadow: 1,
  },
  vehicleDetailsCard_box2: {
    p: 2,
    borderRadius: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: colors?.grayE6,
    backgroundColor: colors?.grayE4,
  },
  cardContent_topStack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customButtonStyle: { width: "100%", alignSelf: "center" },
};
