import { Cross } from "../../../assets/exportImages";
function MessagePopUp(props: any) {
  return (
    <>
      {/* pw-sucess pw-error */}
      <div className={props.className}>
        <p>{props.message}</p>
        <a>
          <img
            src={Cross}
            onClick={props.onClick}
            alt=""
            className="pwsucess-icon-pw"
          />
        </a>
      </div>
    </>
  );
}

export { MessagePopUp };
