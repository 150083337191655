import { Box, Grid, Typography } from "@mui/material";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import {
  cityIcon,
  CityInputIcon,
  stateIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import en from "../../../../locale/rental-en.json";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import ListTable from "../../../../../sharedComponents/templates/Tables/MUITable";
import { env } from "process";
import { colors } from "../../../../../themes/colors";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addHelmetConfig,
  editCurrentHelmetConfig,
  getHelmetConfig,
  getHelmetConfigList,
  updateHelmetConfig,
} from "../../../../redux/actions/businessManagementActions";
import { removeLeadingZero } from "../../../../utils/regex";
import {
  isNumericString,
  paisaToRupee,
  rupeeToPaisa,
  toPascalCase,
} from "../../../../utils/helper";
import { rentalActions } from "../../../../redux/actions";
import {
  clearBreadcrumbs,
  getAllStates,
  getBranchDefaultConfig,
  getCityByState,
  updateBreadcrumbs,
} from "../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../utils/RoutesConstants";
import FullScreenLoader from "../../../../../sharedComponents/molecules/FullScreenLoader";
import {
  HELMETS_DISPLAY_NAME,
  HELMETS_NAME,
  typographyConstants,
} from "../../../../constants/constants";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  helmetConfigurationFunctionality,
  modules,
} from "../../../../../constants/RBACModuleEnums";

const HelemetPriceConfiguration = ({ props }: any) => {
  const RbacHasAccess = {
    editHelmetConfiguration: RbacHelper.isAccessRightsProvided(
      modules.HELMET_CONFIGURATION,
      helmetConfigurationFunctionality.EDIT_HELMET_CONFIGURATION
    ),
  };
  const StyleObject = {
    tableRowStyle: { height: "36px" },
    tableContainerStyle: { overflowX: "unset" },
    cellStyle: { padding: 0, border: `1px solid ${colors.card_border_color}` },
    customInputStyle: {
      height: "26px",
      minHeight: "unset",
      borderRadius: "6px",
    },
    inputContainerStyle: {
      "&.MuiTextField-root": {
        "& .MuiInputLabel-outlined": {
          color: "primary.main",
        },
        "& .MuiInputBase-inputSizeSmall": {
          color: "primary.main",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiFormHelperText-contained": {
          color: "error.main",
          margin: 0,
        },
        "&:hover fieldset": {
          borderColor: "grey",
        },
        "& .MuiInput-underline:hover:before": {
          border: "none !important",
        },
      },
    },
  };
  const cellStyle = StyleObject.cellStyle;
  const renderCellInput = ({ value, row, accessorKey }: any) => {
    const updatedRow = { ...row };
    let helmetprice = updatedRow?.pricing?.find(
      (item: any) => item?.type === accessorKey
    )?.price;
    return (
      <Input
        value={paisaToRupee(helmetprice) ?? ""}
        width="90px"
        customStyle={StyleObject.customInputStyle}
        inputContainerStyle={StyleObject.inputContainerStyle}
        onChange={(e: any) => {
          e.preventDefault();
          setPricingExist(false);
          let value = removeLeadingZero(e.target.value);
          value = value.toString().replace(/^0+/, "");
          if (!helmetprice) {
            updatedRow?.pricing?.push({ type: accessorKey, price: value });
            helmetprice = updatedRow?.pricing?.find(
              (item: any) => item?.type === accessorKey
            )?.price;
          }
          if (value.length <= 7 && isNumericString(value)) {
            let updatedHelmetPrice = updateHelmetPrice(
              value ?? "",
              accessorKey,
              updatedRow
            );
            handleChange("configuration", updatedHelmetPrice);
          }
        }}
        inputProps={{
          readOnly:
            (params?.id || exstingBranchId) &&
            !RbacHasAccess.editHelmetConfiguration,
        }}
      />
    );
  };

  const {
    allStatesList,
    stateBasedCities,
    branches,
    branchDefaultConfig,
    masterPlanDurationsData,
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const helmetTypes = branchDefaultConfig?.helmetTypes ?? [];

  const dynamicColumns = helmetTypes?.map((type: any) => {
    return {
      accessorKey: type,
      numeric: true,
      disablePadding: false,
      headerName: HELMETS_DISPLAY_NAME[type],
      align: "center",
      cellStyle,
      customJsx: renderCellInput,
    };
  });

  let columns = [
    {
      accessorKey: "duration",
      numeric: false,
      disablePadding: false,
      headerName: en.tableHeaders.planDuration,
      align: "center",
      cellStyle,
      customJsx: ({ value }: any) => {
        return (
          <div>
            {value} {value === 1 ? "Day" : "Days"}
          </div>
        );
      },
    },
    ...dynamicColumns,
  ];

  const [fields, setFields] = useState({
    states: {
      name: "",
      displayName: "",
    },
    city: {
      name: "",
      displayName: "",
    },
    hub: {
      name: "",
      displayName: "",
    },
  });
  const [enableButton, setEnableButton] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [exstingBranchId, setExstingBranchId] = useState("");
  const [pricingExist, setPricingExist] = useState(false);

  const {
    helmetConfig,
    helmetSaveUpdateLoader,
    isHelmetConfigSaveOrUpdate,
    screenLoader,
    helmetConfigList,
  }: any = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );

  const packageData = masterPlanDurationsData;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //get all states, master plans duration and helmet config
  useEffect(() => {
    dispatch(getAllStates());
    dispatch(rentalActions.getMasterPlanDurations());
    if (params?.id) {
      dispatch(getHelmetConfig({ id: params?.id }));
      dispatch(
        updateBreadcrumbs(BREADCRUMBS_RENTALS.EDIT_HELMET_CONFIGURATION)
      );
    } else {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.ADD_HELMET_CONFIGURATION));
    }
    return () => {
      dispatch(editCurrentHelmetConfig({}));
      dispatch(clearBreadcrumbs());
    };
  }, []);

  //creating payload structure
  useEffect(() => {
    if (!Object.keys(helmetConfig)?.length && !params?.id) {
      if (
        masterPlanDurationsData?.length &&
        Object?.keys(branchDefaultConfig)?.length
      ) {
        const configuration = packageData?.map((duration: any) => {
          const pricing: any = [];
          return { duration, pricing };
        });
        const payload = {
          branch: fields?.hub?.name,
          branchDisplayName: fields?.hub?.displayName,
          states: fields?.states?.name,
          stateDisplayName: fields?.states?.displayName,
          city: fields?.city?.name,
          cityDisplayName: fields?.city?.displayName,
          configuration: configuration,
        };
        dispatch(editCurrentHelmetConfig(payload));
      }
    }
  }, [branchDefaultConfig]);

  //in add case find branchId, if branch config is already exist
  useEffect(() => {
    if (Object.keys(helmetConfigList)?.length) {
      const branchId = helmetConfigList?.helmetConfigData?.find(
        (obj: any) => obj.branch === fields?.hub?.name
      )?.id;
      if (branchId) {
        setExstingBranchId(branchId);
      }
    }
  }, [helmetConfigList]);

  useEffect(() => {
    if (Object.keys(helmetConfig)?.length) {
      setFields((prev: any) => ({
        ...prev,
        states: {
          name: helmetConfig?.state ? helmetConfig?.state : fields?.states,
          displayName: helmetConfig?.stateDisplayName
            ? helmetConfig?.stateDisplayName
            : fields?.states?.displayName,
        },
        city: {
          name: helmetConfig?.city ? helmetConfig?.city : fields?.city,
          displayName: helmetConfig?.cityDisplayName
            ? helmetConfig?.cityDisplayName
            : fields?.city?.displayName,
        },
        hub: {
          name: helmetConfig?.branch ? helmetConfig?.branch : fields?.hub?.name,
          displayName: helmetConfig?.branchDisplayName
            ? helmetConfig?.branchDisplayName
            : fields?.hub?.displayName,
        },
      }));
      if (helmetConfig?.branch && helmetConfig?.branch !== fields?.hub?.name) {
        dispatch(getBranchDefaultConfig(helmetConfig?.branch));
      }
    }
    if (params?.id || exstingBranchId) {
      setEnableButton(true);
    }
  }, [helmetConfig]);

  //on update and save helmet price navigating on helmet list
  useEffect(() => {
    if (isHelmetConfigSaveOrUpdate) {
      navigate(routesConstants.HELMET_CONFIGURATION);
    }
  }, [isHelmetConfigSaveOrUpdate]);

  //handle fields
  const handleFields = (fields: string, value: any) => {
    setFields((prevFields: any) => ({
      ...prevFields,
      [fields]: value,
    }));
    setEnableButton(false);
    setExstingBranchId("");
    setPricingExist(false);
    if (fields === "states") {
      dispatch(getCityByState(value?.name));
      setFields((prev: any) => ({
        ...prev,
        city: {
          name: "",
          displayName: "",
        },
        hub: {
          name: "",
          displayName: "",
        },
      }));
      if (exstingBranchId) {
        dispatch(editCurrentHelmetConfig({}));
      }
    }
    if (fields === "city") {
      dispatch(rentalActions.getAllBranches({ name: value.name }));
      setFields((prev: any) => ({
        ...prev,
        hub: {
          name: "",
          displayName: "",
        },
      }));
      if (exstingBranchId) {
        dispatch(editCurrentHelmetConfig({}));
      }
    }
    if (fields === "hub") {
      dispatch(getBranchDefaultConfig(value?.name));
      dispatch(getHelmetConfigList({}));
    }
  };

  //change current helmet price data
  const handleChange = (field: string, value: any) => {
    let updatedHelmetConfig = { ...helmetConfig };
    updatedHelmetConfig[field] = value;
    dispatch(editCurrentHelmetConfig(updatedHelmetConfig));
  };

  //update helmet price
  const updateHelmetPrice = (
    value: string,
    key: string,
    singleHelmetData: any
  ) => {
    let updatedHelmetPrice = [...helmetConfig?.configuration];
    updatedHelmetPrice.forEach((item: any) => {
      if (item.duration === singleHelmetData.duration) {
        item?.pricing?.forEach((pricing: any) => {
          if (pricing.type === key) {
            pricing.price = rupeeToPaisa(Number(value));
          }
        });
      }
    });
    let filteredConfiguration = updatedHelmetPrice?.map((item: any) => {
      item.pricing = item.pricing.filter(
        (pricing: any) => pricing.price !== ""
      );
      return item;
    });
    return filteredConfiguration;
  };

  //save/update configuration
  const handleSaveUpatePrice = () => {
    const allValueExist = checkPricing(helmetConfig?.configuration);
    if (!allValueExist) {
      if (params?.id || exstingBranchId) {
        let filteredConfiguration = helmetConfig?.configuration?.filter(
          (item: any) => item.pricing.length > 0
        );
        const payload = {
          data: {
            data: {
              configuration: helmetConfig?.configuration,
            },
          },
          id: helmetConfig?.id,
        };
        dispatch(updateHelmetConfig(payload));
      } else {
        let filteredConfiguration = helmetConfig?.configuration.filter(
          (item: any) => item.pricing.length > 0
        );
        const payload = {
          data: {
            branch: fields?.hub?.name,
            isDefault: false,
            configuration: helmetConfig?.configuration,
          },
        };
        dispatch(addHelmetConfig(payload));
      }
    } else {
      setPricingExist(true);
    }
  };

  //check each pricing array has all helmet price
  function checkPricing(config: any) {
    for (const duration of config) {
      const types = duration?.pricing?.map((p: any) => p.type);
      const allTypesPresent = helmetTypes?.every((helmetType: any) =>
        types.includes(helmetType)
      );
      if (!allTypesPresent) {
        return true;
      }
    }
    return false;
  }
  const toggleConfirmationModal = () => {
    setConfirmationModal((showConfirmationModal) => !showConfirmationModal);
  };

  const handleYesClick = () => {
    dispatch(getHelmetConfig({ id: exstingBranchId }));
    toggleConfirmationModal();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginBottom: "50px",
      }}
    >
      {!params?.id ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomSelect
              icon={stateIcon}
              placeholder={en.NewBusinessManagement.selectState}
              value={fields?.states?.displayName}
              choice={
                allStatesList?.length
                  ? allStatesList
                  : fields?.states?.displayName
                  ? [fields?.states]
                  : null
              }
              defaultKey={"displayName"}
              required
              handleChange={(state: any) => {
                handleFields("states", state);
              }}
              readOnly={params?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              icon={CityInputIcon}
              placeholder={en.global.selectCity}
              value={fields?.city?.displayName}
              choice={
                stateBasedCities?.length
                  ? stateBasedCities
                  : fields?.city.displayName
                  ? [fields?.city]
                  : null
              }
              defaultKey={"displayName"}
              required
              handleChange={(city: any) => {
                handleFields("city", city);
              }}
              readOnly={params?.id}
              disabled={!stateBasedCities?.length && !params?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              icon={WarehouseIcon}
              placeholder={en.global.selectHub}
              value={fields?.hub?.displayName}
              choice={
                branches?.length
                  ? branches
                  : fields?.hub?.displayName
                  ? [fields?.hub]
                  : null
              }
              defaultKey={"displayName"}
              required
              handleChange={(hub: any) => {
                handleFields("hub", hub);
              }}
              readOnly={params?.id}
              disabled={
                !params?.id && (!branches?.length || !fields.city.displayName)
              }
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ margin: "8px 0px" }}>
          <Grid
            item
            xs={6}
            sx={{
              padding: "20px",
              backgroundColor: "#FFF",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box>
                  <img src={stateIcon} />
                </Box>
                <Box>{helmetConfig?.stateDisplayName}</Box>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box>
                  <img src={cityIcon} />
                </Box>
                <Box>{helmetConfig?.cityDisplayName}</Box>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box>
                  <img src={WarehouseIcon} />
                </Box>
                <Box>{helmetConfig?.branchDisplayName}</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {enableButton && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {pricingExist && (
              <Typography
                variant={typographyConstants.ERRORMESSAGE}
                marginLeft="8px"
              >
                {en.errorMessages.allFieldsRequired}
              </Typography>
            )}
            <Box sx={{ marginTop: "8px" }}>
              <ListTable
                columns={columns}
                rows={helmetConfig?.configuration}
                rowStyle={StyleObject.tableRowStyle}
                containerStyle={StyleObject.tableContainerStyle}
                //loading={onLoad}
                hidePagination
                dense
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <CustomButton
        variant="outlined"
        label={
          enableButton
            ? en.NewBusinessManagement.savePrice
            : en.NewBusinessManagement.configurePrice
        }
        onClick={() => {
          if (!enableButton) {
            if (exstingBranchId) {
              toggleConfirmationModal();
            } else {
              setEnableButton(true);
            }
          } else {
            handleSaveUpatePrice();
          }
        }}
        loading={helmetSaveUpdateLoader}
        style={{ height: "40px", borderRadius: "10px" }}
        disabled={
          !fields?.hub?.name ||
          (enableButton &&
            (params?.id || exstingBranchId) &&
            !RbacHasAccess.editHelmetConfiguration)
        }
      />
      <FullScreenLoader loading={screenLoader} loaderSize={40} />
      <ResponsiveDialog
        title={en.NewBusinessManagement.helmetConfigurationExist}
        body={en.NewBusinessManagement.updateExistingConfiguration}
        open={showConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleYes={handleYesClick}
      />
    </Box>
  );
};
export default HelemetPriceConfiguration;
