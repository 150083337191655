import { Box, Skeleton, Stack } from "@mui/material";

const CustomerDetailsLoader = () => {
  return (
    <Stack>
      {/* customer details */}
      <Stack justifyContent={"space-between"} gap="0.5vw" direction="row">
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="30%"
          height="30vh"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="70%"
          height="30vh"
        />
      </Stack>

      {/* booking details */}
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="50vh"
      />
    </Stack>
  );
};

export default CustomerDetailsLoader;
