import { AttachFile, Info } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomUpload from "../../../../../sharedComponents/molecules/customUpload";
import ImagePreviewModal from "../../../../../sharedComponents/molecules/Modals/ImagePreviewModal";
import { colors } from "../../../../../themes/colors";
import {
  AccountMIDStatus,
  accountNumberLength,
  ApprovalOptions,
  BusinessType,
  gstNumberLength,
  ifscLength,
  KycVerificationStatus,
  operatorDocumentUploadTypes,
  OperatorKycStatus,
  OperatorKycStatusColor,
  OperatorKycStatusDisplayValue,
  panNumberLength,
  statusColorCode,
  typographyConstants,
  userNameLength,
} from "../../../../constants/constants";
import { cloudIcon, PlusIcon } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { getSignedUrl } from "../../../../redux/actions/businessManagementActions";
import {
  approveRejectOperatorDocument,
  saveOperatorDocument,
  sumbitOperatorDocument,
} from "../../../../redux/actions/operatorManagementActions";
import {
  downloadFile,
  filterJson,
  formatString,
  isAlphaNumericString,
  isAlphaNumericWithSpacesString,
  isNumericString,
  toPascalCase,
} from "../../../../utils/helper";
import HomeDeliveryAddressCard from "../../../manualBooking/section/HomeDeliveryAddressCard";
import OperatorAddress from "../../leads/section/operatorAddress";
import RejectReasonModal from "./RejectReasonModal";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

// get input fields for kyc verification
const getInputFieldData = (type: string, isMyAccount: boolean) => {
  let kycFields: any = [
    {
      id: "bankAccountDetails",
      label: rentalEn.operatorManagement.bankDetails,
      docRequired: true,
      inputData: [
        {
          key: "beneficiaryName",
          inputLabel: rentalEn.operatorManagement.beneficiaryName,
          isUppercase: false,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isAlphaNumericWithSpacesString(text);
          },
          inputProps: { maxLength: userNameLength },
        },
        {
          key: "value",
          inputLabel: rentalEn.operatorManagement.accountNumber,
          isUppercase: false,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isNumericString(text);
          },
          inputProps: { maxLength: accountNumberLength },
        },
        {
          key: "ifscCode",
          inputLabel: rentalEn.operatorManagement.IFSCCode,
          isUppercase: true,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isAlphaNumericString(text);
          },
          inputProps: { maxLength: ifscLength },
        },
      ],
    },
    {
      id: "businessGstDetails",
      label: rentalEn.operatorManagement.GSTNumber,
      docRequired: true,
      inputData: [
        {
          key: "value",
          inputLabel: rentalEn.operatorManagement.enterGSTNumber,
          isUppercase: true,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isAlphaNumericWithSpacesString(text);
          },
          inputProps: { maxLength: gstNumberLength },
        },
      ],
    },
    {
      id: "businessPanDetails",
      label: rentalEn.operatorManagement.businessPAN,
      docRequired: true,
      inputData: [
        {
          key: "companyName",
          inputLabel: rentalEn.operatorManagement.enterCompanyName,
          isUppercase: false,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isAlphaNumericWithSpacesString(text);
          },
          inputProps: { maxLength: userNameLength },
        },
        {
          key: "value",
          inputLabel: rentalEn.operatorManagement.businessPANNumber,
          isUppercase: true,
          isValidValue: (text: string) => {
            if (!text) return true;
            return isAlphaNumericString(text);
          },
          inputProps: { maxLength: 15 },
        },
      ],
    },
    {
      id: "operatorAddress",
      label: isMyAccount
        ? rentalEn.operatorManagement.myAddress
        : rentalEn.operatorManagement.OperatorAddress,
      docRequired: false,
      labelTooltipText: rentalEn.operatorManagement.addressAsPerBankDetails,
    },
  ];

  if (
    type === BusinessType.INDIVIDUAL ||
    type === BusinessType.NOT_YET_REGISTERED
  ) {
    const updatedKycFields = kycFields.filter(
      (field: any) =>
        field.id !== "businessPanDetails" && field.id !== "businessGstDetails"
    );

    //  append stake holder Pan for Pan Details
    kycFields = [
      {
        id: "stakeHoldersPanDetails",
        label: rentalEn.operatorManagement.panDetails,
        docRequired: true,
        inputData: [
          {
            key: "name",
            inputLabel: rentalEn.global.enterName,
            isUppercase: false,
            isValidValue: (text: string) => {
              if (!text) return true;
              return isAlphaNumericWithSpacesString(text);
            },
            inputProps: { maxLength: userNameLength },
          },
          {
            key: "value",
            inputLabel: rentalEn.operatorManagement.enterPanNumber,
            isUppercase: true,
            isValidValue: (text: string) => {
              if (!text) return true;
              return isAlphaNumericString(text);
            },
            inputProps: { maxLength: panNumberLength },
          },
        ],
      },
      ,
      ...updatedKycFields,
    ];
  }

  return kycFields;
};

const OperatorDocumentUpload = (props: any) => {
  const {
    fields = {},
    handleChange = () => {},
    isDisabled = false,
    isMyAccount,
  } = props;
  const {
    kycValues = {},
    uploadedDoc = {},
    imageLoader,
    isAddressModalOpen,
  } = fields;
  const {
    operatorDetails,
    isSavingDocument: isSavingDoc,
    isSubmitingDocument,
    isCompletingKycAction,
    loading,
  } = useSelector((state: RootState) => state.operatorManagementReducer);
  const dispatch = useDispatch();
  const [previewImageData, setImageData] = useState<any>({ isOpen: false });
  const [imgError, setImgError] = useState<any>(false);
  const [isSubmitDialog, setSubmitDialog] = useState("");
  const [rejectReasonModalData, setRejectReasonModalData] = useState({
    isOpen: false,
    documentId: "",
  });

  const {
    id: operatorId,
    kycStatus,
    operatorKycDetails,
    businessDetails = {},
    accountDetails,
    status,
    address,
  } = operatorDetails;

  const { accountStatus }: { accountStatus: keyof typeof AccountMIDStatus } =
    accountDetails || {};
  const disableAllInput =
    isSavingDoc ||
    isDisabled ||
    kycStatus === OperatorKycStatus.VERIFIED ||
    kycStatus === OperatorKycStatus.DELETED;

  const InputFieldData = getInputFieldData(businessDetails?.type, isMyAccount);

  // map operator details data into local state
  useEffect(() => {
    mapOperatorDataIntoState(operatorKycDetails);
  }, [JSON.stringify(operatorKycDetails)]);

  // check if the submit kyc enabled or not
  const isSubmitEnabled = useCallback(() => {
    // check if all fields are filled
    const isAllKycFieldsFilled = isAllfieldFilled();

    const pendingStatusDoc = operatorKycDetails?.find((doc: any) => {
      return doc.status === OperatorKycStatus.PENDING;
    });

    const rejectedStatusDoc = operatorKycDetails?.find((doc: any) => {
      return doc.status === OperatorKycStatus.REJECTED;
    });

    const isDocSaved = compareStateAndOperatorData(true);

    return !(
      isDocSaved &&
      isAllKycFieldsFilled &&
      pendingStatusDoc &&
      !isSavingDoc &&
      !rejectedStatusDoc &&
      kycStatus !== OperatorKycStatus.UNVERIFIED &&
      kycStatus !== OperatorKycStatus.VERIFIED
    );
  }, [JSON.stringify(fields.kycValues), JSON.stringify(operatorKycDetails)]);

  // compare state and operator data from ApiResponse
  const compareStateAndOperatorData = useCallback(
    (fromSubmit = false) => {
      const ApiOperatorData = getOperatorMappedData(operatorKycDetails);
      const { kycValues } = fields;
      // remove empty object then compare
      const isDocValuesUpdated = isEqual(
        filterJson(kycValues),
        filterJson(ApiOperatorData)
      );

      // check if uploaded new file is uploaded or not
      const isDocUploaded = Object.keys(uploadedDoc).find((item: any, key) => {
        if (uploadedDoc[item]?.file?.name !== kycValues[item]?.fileName)
          return true;
      });

      if (isDocUploaded) {
        return false;
      }

      return isDocValuesUpdated;
    },
    [JSON.stringify(fields.kycValues), JSON.stringify(operatorKycDetails)]
  );

  // check if complete kyc button enabled or not
  const isCompleteKycActionEnabled = useCallback(() => {
    const isPendingDocApproval = Object.keys(kycValues)?.find((id: any) => {
      if (
        (businessDetails?.type !== BusinessType.INDIVIDUAL ||
          businessDetails?.type !== BusinessType.NOT_YET_REGISTERED) &&
        id === "stakeHoldersPanDetails"
      )
        return false;

      if (
        kycValues[id].id !== undefined &&
        kycValues[id].status !== OperatorKycStatus.VERIFIED
      ) {
        return !kycValues[id].operatorResponse;
      }

      return false;
    });

    return isPendingDocApproval;
  }, [JSON.stringify(kycValues)]);

  // generate signed URL
  const generateSignedUrl = (file: any, key: string) => {
    const payload: any = {
      data: {
        data: {
          module: "OPERATOR",
          files: [
            {
              type: operatorDocumentUploadTypes[
                key as keyof typeof operatorDocumentUploadTypes
              ],
              fileName: file?.name,
            },
          ],
        },
      },
      file,
      onSuccess: (apiResponse: any, file: any) => {
        const { uploadedDoc = {} } = fields as any;
        uploadedDoc[key] = {
          file,
          name: apiResponse?.fileName,
          attachmentId: apiResponse?.id,
        };
        const updatedData = {
          uploadedDoc,
          imageLoader: { ...fields.imageLoader, [key]: false },
        };

        handleChange("", updatedData);
      },
    };
    //dispatch(generateDocsSignedUrls(payload));
    dispatch(getSignedUrl(payload));
  };

  // Hit signed url api on doc upload click
  const onDocUpload = (files: any, key: string) => {
    if (files.length) {
      const file = files[0];
      handleChange("imageLoader", { ...imageLoader, [key]: true });
      generateSignedUrl(file, key);
    }
  };

  // clear doc attachment
  const clearDocAttachment = (key: string) => {
    const { uploadedDoc = {}, kycValues } = fields as any;
    uploadedDoc[key] = {};
    handleChange("uploadedDoc", uploadedDoc);
    handleChange("kycValues", {
      ...kycValues,
      [key]: {
        ...kycValues[key],
        signedUrl: "",
        fileName: "",
      },
    });
  };

  // handlechange for document input
  const handleDocumentInputChange = (
    id: string,
    value: any,
    nestedKey?: string,
    isUppercase?: boolean
  ): any => {
    const { kycValues = {} } = fields as any;

    value = isUppercase ? value?.toUpperCase() : value;
    kycValues[id] = {
      ...kycValues[id],
      [nestedKey ? nestedKey : "value"]: value,
    };
    handleChange("kycValues", kycValues);
  };

  // preview doc
  const handlePreviewImageModal = (data: any = {}) => {
    setImageData((prev: any) => ({ isOpen: !prev?.isOpen, ...data }));
  };

  // approve/reject all documents
  const handleApproveRejectDoc = () => {
    const operatorVerificationDoc = Object.keys(kycValues).reduce(
      (accum: any, doc: any) => {
        if (kycValues[doc].id && kycValues[doc]?.operatorResponse) {
          accum.push({
            id: kycValues[doc].id,
            response: kycValues[doc].operatorResponse,
            disApprovedReason: kycValues[doc]?.disApprovedReason,
          });
        }
        return accum;
      },
      []
    );

    const payload = {
      data: {
        kycVerification: operatorVerificationDoc,
      },
      operatorId,
    };

    //  remove undefined values
    const filteredPayload = filterJson(payload);
    // dispatch API
    dispatch(approveRejectOperatorDocument(filteredPayload));
    toggleSubmitDialog("");
  };

  // get save document payload
  const getSavePayload = () => {
    return {
      data: {
        stakeHoldersPanDetails: {
          name: kycValues["stakeHoldersPanDetails"]?.name?.trim(),
          pan: kycValues["stakeHoldersPanDetails"]?.value?.trim(),
          attachmentId:
            uploadedDoc["stakeHoldersPanDetails"]?.attachmentId?.trim(),
        },
        bankAccountDetails: {
          beneficiaryName:
            kycValues["bankAccountDetails"]?.beneficiaryName?.trim(),
          accountNumber: kycValues["bankAccountDetails"]?.value?.trim(),
          ifscCode: kycValues?.bankAccountDetails?.ifscCode?.trim(),
          attachmentId: uploadedDoc["bankAccountDetails"]?.attachmentId?.trim(),
        },
        businessPanDetails: {
          companyName: kycValues["businessPanDetails"]?.companyName?.trim(),
          pan: kycValues["businessPanDetails"]?.value?.trim(),
          attachmentId: uploadedDoc["businessPanDetails"]?.attachmentId?.trim(),
        },
        businessGstDetails: {
          gst: kycValues["businessGstDetails"]?.value?.trim(),
          attachmentId: uploadedDoc["businessGstDetails"]?.attachmentId?.trim(),
        },
        address: {
          addressLine1: kycValues["operatorAddress"]?.addressLine1?.trim(),
          addressLine2: kycValues["operatorAddress"]?.addressLine2?.trim(),
          pinCode: kycValues["operatorAddress"]?.pinCode?.trim(),
        },
      },
      id: operatorId,
    };
  };

  // handle save document
  const handleSaveDoc = () => {
    const payload = getSavePayload();
    //  remove undefined values
    const filteredPayload = filterJson(payload);
    // dispatch Save API
    dispatch(saveOperatorDocument(filteredPayload));
    // }
  };

  // handle submit document
  const handleSubmitDoc = () => {
    const payload = getSavePayload();
    //  remove undefined values
    const filteredPayload = filterJson(payload);

    dispatch(
      sumbitOperatorDocument({
        id: operatorId,
        savePayload: filteredPayload,
      })
    );
    handleChange("uploadedDoc", {});
    toggleSubmitDialog("");
  };

  // map fields data for save payload
  const getOperatorMappedData = (operatorData: any = []) => {
    const updatedKycValues = {
      stakeHoldersPanDetails: {},
      bankAccountDetails: {},
      businessPanDetails: {},
      businessGstDetails: {},
      operatorAddress: {},
    };

    operatorData?.map((item: any) => {
      const { kycDetails = {} } = item;
      if (item.type === "BANK_ACCOUNT") {
        updatedKycValues.bankAccountDetails = {
          id: item?.id,
          fileName: kycDetails?.fileName,
          value: kycDetails.accountNumber,
          beneficiaryName: kycDetails.beneficiaryName,
          ifscCode: kycDetails.ifscCode,
          status: item.status,
          signedUrl: kycDetails.signedUrl,
          disApprovedReason: item?.disApprovedReason,
        };
      } else if (item.type === "STAKEHOLDER_PAN") {
        updatedKycValues.stakeHoldersPanDetails = {
          id: item?.id,
          value: kycDetails.pan,
          fileName: kycDetails?.fileName,
          name: kycDetails.name,
          status: item.status,
          signedUrl: kycDetails.signedUrl,
          disApprovedReason: item?.disApprovedReason,
        };
      } else if (item.type === "BUSINESS_PAN") {
        updatedKycValues.businessPanDetails = {
          id: item?.id,
          value: kycDetails.pan,
          companyName: kycDetails.companyName,
          status: item.status,
          signedUrl: kycDetails.signedUrl,
          fileName: kycDetails?.fileName,
          disApprovedReason: item?.disApprovedReason,
        };
      } else if (item.type === "BUSINESS_GST") {
        updatedKycValues.businessGstDetails = {
          id: item?.id,
          value: kycDetails.gst,
          status: item.status,
          fileName: kycDetails?.fileName,
          signedUrl: kycDetails.signedUrl,
          disApprovedReason: item?.disApprovedReason,
        };
      } else if (item.type === "ADDRESS") {
        updatedKycValues.operatorAddress = {
          id: item?.id,
          status: item.status,
          addressLine1: kycDetails.addressLine1,
          addressLine2: kycDetails.addressLine2,
          city: kycDetails.city,
          state: kycDetails.state,
          country: kycDetails.country,
          pinCode: kycDetails.pinCode,
          disApprovedReason: item?.disApprovedReason,
        };
      }
    });

    return updatedKycValues;
  };

  // map operator data from API to state
  const mapOperatorDataIntoState = (operatorData: any) => {
    const updatedKycValues = getOperatorMappedData(operatorData);
    handleChange("kycValues", updatedKycValues);
  };

  // toggle reject reason modal
  const toggleRejectReasonModal = (id: string) => {
    setRejectReasonModalData({
      isOpen: !rejectReasonModalData?.isOpen,
      documentId: id,
    });
  };

  // handle submit reject reason
  const handleApproveReject = (docId: string, response: string) => {
    // using id update state
    const updatedKycValues = {
      ...fields.kycValues,
      [docId]: {
        ...fields?.kycValues[docId],
        disApprovedReason: fields?.rejectReason?.trim() || "",
        operatorResponse: response,
      },
    };

    handleChange("kycValues", updatedKycValues);
    handleChange("rejectReason", "");
    if (response === ApprovalOptions.REJECTED) toggleRejectReasonModal(docId);
  };

  // check if all fields are filled
  const isAllfieldFilled = () => {
    const {
      stakeHoldersPanDetails = {},
      bankAccountDetails = {},
      businessPanDetails = {},
      businessGstDetails = {},
      operatorAddress = {},
    } = kycValues;

    if (
      !bankAccountDetails?.beneficiaryName ||
      !bankAccountDetails?.value ||
      !bankAccountDetails?.ifscCode ||
      (!bankAccountDetails?.signedUrl &&
        !uploadedDoc["bankAccountDetails"]?.attachmentId)
    ) {
      return false;
    }

    // check for address if it is filled or not
    if (
      !operatorAddress?.pinCode ||
      !operatorAddress?.addressLine1 ||
      !operatorAddress?.addressLine2
    ) {
      return false;
    }

    // check stakeholder validation only for individuals and not registered business types
    if (
      businessDetails?.type === BusinessType.INDIVIDUAL ||
      businessDetails?.type === BusinessType.NOT_YET_REGISTERED
    ) {
      if (
        !stakeHoldersPanDetails?.value ||
        !stakeHoldersPanDetails?.name ||
        (!stakeHoldersPanDetails?.signedUrl &&
          !uploadedDoc["stakeHoldersPanDetails"]?.attachmentId)
      )
        return false;
      else return true;
    }

    if (
      !businessPanDetails?.value ||
      !businessPanDetails?.companyName ||
      (!businessPanDetails?.signedUrl &&
        !uploadedDoc["businessPanDetails"]?.attachmentId) ||
      !businessGstDetails?.value ||
      (!businessGstDetails?.signedUrl &&
        !uploadedDoc["businessGstDetails"]?.attachmentId)
    ) {
      return false;
    }
    return true;
  };

  // toggle submit dialog
  const toggleSubmitDialog = (value = "") => {
    setSubmitDialog(value);
  };

  // toggle address modal
  const toggleAddAddressModal = () =>
    handleChange("isAddressModalOpen", !isAddressModalOpen);

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_secondary_blue,
        padding: "20px",
        borderRadius: "16px",
      }}
    >
      <Box sx={{ marginBottom: "10px" }}>
        <Typography
          variant={typographyConstants.SUBHEADING}
          color={colors.THEME_BLUE}
        >
          {rentalEn.operatorManagement.KycStatus} :{"  "}
        </Typography>
        <Typography
          variant={typographyConstants.SUBHEADING}
          color={
            OperatorKycStatusColor[
              !Object.keys(fields).length
                ? OperatorKycStatus.PENDING
                : (kycStatus as keyof typeof OperatorKycStatusColor)
            ]
          }
        >
          {!Object.keys(fields).length
            ? toPascalCase(OperatorKycStatus.PENDING)
            : OperatorKycStatusDisplayValue[
                kycStatus as keyof typeof OperatorKycStatusDisplayValue
              ] || rentalEn.global.NA}
        </Typography>
        <Typography
          variant={typographyConstants.SUBHEADING}
          color={colors.THEME_BLUE}
          sx={{ marginLeft: "30px" }}
        >
          {rentalEn.operatorManagement.LinkedAccount} :{" "}
        </Typography>
        <Typography
          variant={typographyConstants.SUBHEADING}
          sx={{
            color: accountStatus
              ? statusColorCode[accountStatus]
              : colors.dark_gray,
          }}
        >
          {accountStatus
            ? formatString(AccountMIDStatus[accountStatus])
            : ` ${rentalEn.global.NA}`}
        </Typography>
      </Box>
      {!Object.keys(fields).length ? (
        <Typography variant={typographyConstants.BODY}>
          {rentalEn.operatorManagement?.agreementSuccessfullVerified}
        </Typography>
      ) : (
        <>
          <Box>
            {InputFieldData.map((item: any, key: number) => {
              const {
                id,
                docRequired,
                inputData = [],
                labelTooltipText,
              } = item;
              const imageUrl = kycValues[id]?.signedUrl;
              const fileName = uploadedDoc[id]?.name || kycValues[id]?.fileName;
              const isDocEditable =
                kycValues[id]?.status === OperatorKycStatus.REJECTED ||
                kycValues[id]?.status === OperatorKycStatus.PENDING;
              const isValuesVisible = !(
                !isMyAccount &&
                kycValues[id]?.status === OperatorKycStatus.PENDING
              );

              const disApprovedReason = kycValues[id]?.disApprovedReason;
              return (
                <Box
                  key={id}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: colors.white,
                    alignItems: "center",
                    marginBottom: "20px",
                    padding: "6px 15px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      marginRight: "20px",
                      width: "115px",
                      cursor: labelTooltipText ? "pointer" : "default",
                    }}
                  >
                    {labelTooltipText ? (
                      <Tooltip title={labelTooltipText}>{item.label}</Tooltip>
                    ) : (
                      item?.label
                    )}

                    {disApprovedReason &&
                    kycValues[id]?.status === OperatorKycStatus.REJECTED ? (
                      <Tooltip
                        title={`${rentalEn.operatorManagement.rejectReason}: ${disApprovedReason}`}
                      >
                        <Info
                          fontSize="small"
                          sx={{
                            color: colors.primary_warning,
                            marginLeft: "10px",
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      flex: 1,
                      width: isDisabled ? "94%" : "unset",
                    }}
                  >
                    {inputData?.length ? (
                      inputData.map((item: any, index: number) => {
                        const {
                          key,
                          inputLabel,
                          isValidValue,
                          inputProps = {},
                          isUppercase = false,
                        } = item;

                        return (
                          <Input
                            value={
                              isValuesVisible ? kycValues[id]?.[key] || "" : ""
                            }
                            label={inputLabel}
                            placeholder={inputLabel}
                            disabled={disableAllInput || !isDocEditable}
                            // placeholder={inputLabel}
                            width="200px"
                            inputProps={inputProps}
                            isRemoveTrailingSpaces
                            sx={{
                              marginRight: "20px",
                              my: "12px",
                            }}
                            onChange={(e: any) =>
                              isValidValue(e.target.value) &&
                              handleDocumentInputChange(
                                id,
                                e.target.value,
                                key,
                                isUppercase
                              )
                            }
                          />
                        );
                      })
                    ) : fields?.kycValues?.operatorAddress?.pinCode?.length &&
                      isValuesVisible ? (
                      <Box sx={{ marginBottom: "16px" }}>
                        <HomeDeliveryAddressCard
                          address={fields?.kycValues?.operatorAddress}
                          onClick={
                            kycStatus === OperatorKycStatus.VERIFIED ||
                            kycStatus === OperatorKycStatus.UNVERIFIED ||
                            isSubmitingDocument ||
                            !isMyAccount
                              ? null
                              : toggleAddAddressModal
                          }
                        />
                      </Box>
                    ) : isMyAccount ? (
                      <CustomButton
                        startImageSrc={PlusIcon}
                        label={rentalEn.operatorManagement.addAddress}
                        variant="outlined"
                        sx={{
                          display: "flex",
                          my: "10px",
                        }}
                        onClick={toggleAddAddressModal}
                      />
                    ) : (
                      <Typography>
                        {rentalEn.errorMessages.addressHasNotBeenAdded}
                      </Typography>
                    )}
                  </Box>
                  {docRequired ? (
                    !fileName && !imageUrl ? (
                      !isDisabled ? (
                        <CustomUpload
                          id={id}
                          customStyle={{
                            padding: "10px",
                            backgroundColor: colors.bg_secondary_blue,
                            borderRadius: "10px",
                            cursor: "pointer",
                            marginRight: "20px",
                          }}
                          onUpload={onDocUpload}
                          disabled={
                            (imageLoader?.[id] ||
                              disableAllInput ||
                              !isDocEditable) ??
                            false
                          }
                          acceptedDocTypes={{ "application/pdf": [".pdf"] }}
                        >
                          {imageLoader?.[id] ? (
                            <CircularProgress color="inherit" size="15px" />
                          ) : (
                            <img src={cloudIcon} width={"20px"} height="20px" />
                          )}
                        </CustomUpload>
                      ) : null
                    ) : isValuesVisible ? (
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          const imageData = {
                            file: uploadedDoc[id]?.file,
                            name: fileName ?? "Image",
                            url: imageUrl || "",
                          };
                          if (fileName?.includes(".pdf")) {
                            imageData.url && downloadFile(imageData);
                            return;
                          }
                          handlePreviewImageModal(imageData);
                        }}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          maxWidth: "130px",
                          borderRadius: "5px",
                          padding: "8px",
                          backgroundColor: colors.bg_secondary_blue,
                          marginLeft: "10px",
                          marginRight: "10px",
                          cursor:
                            !imageUrl && fileName?.includes(".pdf")
                              ? "default"
                              : "pointer",
                        }}
                      >
                        {uploadedDoc[id]?.name ? (
                          <Typography
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {uploadedDoc[id]?.name}
                          </Typography>
                        ) : (
                          <AttachFile
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                        {isDisabled || !isDocEditable ? null : (
                          <ClearIcon
                            fontSize="small"
                            sx={{ color: "red", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearDocAttachment(id);
                            }}
                          />
                        )}
                      </Box>
                    ) : null
                  ) : null}

                  {isDisabled &&
                  kycValues[id]?.status === OperatorKycStatus.UNVERIFIED ? (
                    kycValues[id]?.operatorResponse ? (
                      <Box sx={{ display: "flex", width: "20%" }}>
                        <Typography>
                          {kycValues[id]?.operatorResponse ===
                          ApprovalOptions.APPROVED
                            ? rentalEn.operatorManagement.approvalInProgress
                            : rentalEn.operatorManagement.rejectionInProgress}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <CustomButton
                          label={rentalEn.global.approve}
                          variant="outlined"
                          sx={{
                            marginRight: "20px",
                          }}
                          onClick={() =>
                            handleApproveReject(id, ApprovalOptions.APPROVED)
                          }
                        />
                        <CustomButton
                          label={rentalEn.global.reject}
                          variant="outlined"
                          color="error"
                          sx={{
                            marginRight: "20px",
                          }}
                          onClick={() => {
                            toggleRejectReasonModal(id);
                          }}
                        />
                      </Box>
                    )
                  ) : (
                    <Chip
                      sx={{
                        color: colors.white,
                        backgroundColor:
                          OperatorKycStatusColor[
                            kycValues[id]
                              ?.status as keyof typeof OperatorKycStatusColor
                          ],
                      }}
                      label={formatString(
                        loading
                          ? " "
                          : kycValues[id]?.status
                          ? OperatorKycStatusDisplayValue[
                              kycValues[id]
                                ?.status as keyof typeof OperatorKycStatusDisplayValue
                            ]
                          : rentalEn.global.NA
                      )}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          {!isDisabled ? (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <CustomButton
                variant="outlined"
                label={rentalEn.buttonLabels.saveDocument}
                sx={{ marginRight: "24px" }}
                loading={isSavingDoc}
                disabled={compareStateAndOperatorData()}
                onClick={handleSaveDoc}
              />
              <CustomButton
                variant="outlined"
                label={rentalEn.buttonLabels.submitDocument}
                disabled={isSubmitEnabled()}
                loading={isSubmitingDocument}
                onClick={() => toggleSubmitDialog("submit")}
              />
            </Box>
          ) : kycStatus !== OperatorKycStatus.VERIFIED &&
            kycStatus !== KycVerificationStatus.PENDING ? (
            <CustomButton
              variant="outlined"
              label={rentalEn.buttonLabels.initiatKycFeedback}
              disabled={isCompleteKycActionEnabled()}
              loading={isCompletingKycAction}
              onClick={() => toggleSubmitDialog("completekyc")}
            />
          ) : null}

          {isMyAccount ? (
            <Box sx={{ display: "flex", marginTop: "15px" }}>
              <Typography
                variant={typographyConstants.BODY}
                fontWeight="500"
                marginRight="5px"
              >
                {rentalEn.operatorManagement.note}
              </Typography>
              <Typography variant={typographyConstants.BODY}>
                {rentalEn.operatorManagement.noteMyAccount}
              </Typography>
            </Box>
          ) : null}

          <ImagePreviewModal
            openModal={!!previewImageData?.isOpen}
            handleModalClose={handlePreviewImageModal}
            modalData={previewImageData}
            imgError={imgError}
            setImgError={setImgError}
            showDownloadButton
          />

          <RejectReasonModal
            modalData={rejectReasonModalData}
            handleModalClose={toggleRejectReasonModal}
            onSubmit={handleApproveReject}
            handleChange={handleChange}
            rejectReason={fields?.rejectReason}
          />

          <OperatorAddress
            openModal={fields?.isAddressModalOpen}
            modalAddress={kycValues?.operatorAddress}
            handleModalClose={toggleAddAddressModal}
            handleCustomerSaveAddress={(address: any) => {
              const updatedKycValues = {
                ...kycValues,
                operatorAddress: address,
              };
              handleChange("kycValues", updatedKycValues);
            }}
          />

          <SubmitConfirmationDialog
            isOpen={isSubmitDialog}
            toggleSubmitDialog={toggleSubmitDialog}
            handleSubmitDoc={handleSubmitDoc}
            handleApproveRejectDoc={handleApproveRejectDoc}
          />
        </>
      )}
    </Box>
  );
};

export default OperatorDocumentUpload;
