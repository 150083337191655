import { Box, Drawer, Stack, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../../themes/colors";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import { useRef } from "react";
interface sideDrawerInterface {
  open: boolean;
  toggleDrawer?: Function;
  disablePadding?: boolean;
  heading?: string;
  headerPadding?: string;
  renderUI: any;
  setOpen?: any;
  headingVariant?: any;
  hideBackdrop?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  width?: string | number;
  ref?: any;
}
const SideDrawer = ({
  open,
  disablePadding,
  headerPadding,
  heading = "Action Drawer",
  renderUI,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  headingVariant,
  width,
  toggleDrawer = () => {},
  ...rest
}: sideDrawerInterface) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason && reason === "backdropClick" && disableEscapeKeyDown)
          return false;
        if (reason && reason === "escapeKeyDown" && disableBackdropClick)
          return false;
        toggleDrawer();
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: width ?? "380px",
          background: colors.whiteF9,
          padding: !disablePadding ? "12px 26px 34px 26px" : "unset",
          display: "flex",
          overflowY: "auto",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: colors.gray,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: colors.grayE6,
          },
        },
      }}
      {...rest}
    >
      <Stack
        direction="row"
        sx={{
          background: colors.side_drawer_heading_bg_color,
          color: colors.side_drawer_heading_text_color,
          justifyContent: "space-between",
          alignItems: "center",
          padding: !!!headerPadding ? "1vw 0.5vw" : headerPadding,
        }}
      >
        <Typography
          variant={typographyConstants.HEADING}
          color={colors.side_drawer_heading_text_color}
          sx={{ padding: 0 }}
        >
          {heading}
        </Typography>
        <Box onClick={() => toggleDrawer()} sx={{ cursor: "pointer" }}>
          <CloseIcon />
        </Box>
      </Stack>

      {renderUI}
    </Drawer>
  );
};
export default SideDrawer;
