import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import {
  DISPLAY_STATUS,
  iotDeviceTypesArray,
  iotInactiveReasonsArray,
  iotStatus,
  iotStatusArray,
  typographyConstants,
} from "../../../../constants/constants";
import {
  AllVendorsIcon,
  CityInputIcon,
  DeviceTypeIcon,
  LocationIcon,
  MobileIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import {
  AddIotDevicePayload,
  ErrorFields,
  FieldsIOT,
  PingDevicePayload,
  UpdateIotDetailsPayload,
} from "../../../../interfaces/iotManagementInterface";
import en from "../../../../locale/rental-en.json";
import { iotActions, rentalActions } from "../../../../redux/actions";
import {
  dateFormat,
  getDateTimeFromTimeStamp,
  validate,
} from "../../../../utils/helper";
import {
  checkAlfaNumeric,
  checkImeiPhone,
  checkWhiteSpace,
} from "../../../../utils/regex";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { StyledObject } from "../styleObject";
import { routesConstants } from "../../../../utils/RoutesConstants";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  modules,
  iotManagementFunctionality,
} from "../../../../../constants/RBACModuleEnums";
import { toastr } from "react-redux-toastr";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";

const { isAccessRightsProvided } = RbackHelper;

// locale data
const { IotManagement, global, buttonLabels } = en;

const initialState: FieldsIOT = {
  type: { name: "", displayName: "" },
  modelName: { name: "", displayName: "" },
  vendorName: {
    id: 0,
    name: "",
    models: [],
    displayName: "",
  },
  phoneNumber: "",
  imeiNumber: "",
  branchName: { name: "", displayName: "" },
  city: { name: "", displayName: "" },
  status: { name: "", displayName: "" },
  inactiveReason: { name: "", displayName: "" },
};

const errInitialState: ErrorFields = {
  type: "",
  modelName: "",
  vendorName: "",
  phoneNumber: "",
  imeiNumber: "",
  branchName: "",
  city: "",
  status: "",
  inactiveReason: "",
};

const IotViewEditDevice = (props: any) => {
  // references
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isEditDevice: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.EDIT_DEVICE
    ),
    isDevicePing: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.DEVICE_PING
    ),
    isAddIot: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.ADD_DEVICE
    ),
  };

  //destructuring of params for getting device id
  const deviceId: string = location?.pathname?.split("/")[3];

  // data from iot managment reducer
  const {
    vendorsList,
    deviceDetailsData,
    addEditDeviceLoader,
    pingDeviceLoader,
    pingDeviceData,
    isDeviceUpdated: isIotDeviceUpdated,
    isDeviceAdded,
  } = useSelector((state: RootState) => state.rentalsIotManagementReducer);

  // master data from common reducer
  const { allCitiesDetails, branches = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // local state for storing iot fields data
  const [fields, setFields] = useState<FieldsIOT>({ ...initialState });

  // local state, copy of fields for comparision to fields state
  const [fieldsCopy, setFieldsCopy] = useState<FieldsIOT>({
    ...initialState,
  });

  // flag to check whether fields changes or not
  const [isDeviceUpdated, setIsDeviceUpdated] = useState<boolean>(false);

  // state to store error messages according to fields
  const [errFields, setErrFields] = useState({ ...errInitialState });

  // side effects on component mounting
  useEffect(() => {
    if (props.editRole) {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.IOT_EDIT_DEVICE));
    } else dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.IOT_ADD_DEVICE));

    dispatch(iotActions.getAllVendors());
    dispatch(rentalActions.getAllCities());
    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // side effects call on getting device id from params
  useEffect(() => {
    if (!!deviceId) {
      dispatch(iotActions.getIotDeviceDetails({ deviceId }));
      handleDevicePing();
    }
  }, [deviceId]);

  // side effects on getting device details, vendor list , all cities data from api
  useEffect(() => {
    if (props?.editRole && Object.keys(deviceDetailsData)?.length)
      setApiDetailsData();
  }, [deviceDetailsData, vendorsList, allCitiesDetails]);

  // side effect to navigate to iot listing after successfully updating and adding device details
  useEffect(() => {
    if (isDeviceAdded || isIotDeviceUpdated)
      setTimeout(() => {
        navigate(routesConstants.IOT_LISTING);
      }, 1000);
  }, [isDeviceAdded, isIotDeviceUpdated]);

  // side effects on getting data from branches
  useEffect(() => {
    if (isArrayNotEmpty(branches)) {
      let branch: any = fields?.branchName;
      branch = branches?.filter(
        (branch: any) =>
          branch.name === deviceDetailsData?.deviceDetails?.branchName
      )[0];
      setFields((prev: FieldsIOT) => ({
        ...prev,
        branchName: branch ? branch : initialState.branchName,
      }));
      setFieldsCopy((prev: FieldsIOT) => ({ ...prev, branchName: branch }));
    }
  }, [branches]);

  // handle change fields data
  const handleChange = (field: string, value: any) => {
    let data: FieldsIOT = { ...fields };
    data[field as keyof typeof data] = value;

    if (field === "city") data["branchName"] = initialState.branchName;
    if (field === "status")
      data["inactiveReason"] = initialState.inactiveReason;
    setFields((prev: FieldsIOT) => ({ ...prev, ...data }));
    setErrFields((prev: ErrorFields) => ({ ...prev, [field]: "" }));

    // check changes in fields, on change of fields in edit mode
    if (props?.editRole) setIsDeviceUpdated(!compareData(data));
  };

  // set api response data
  const setApiDetailsData = () => {
    let previousState = { ...fields };
    const {
      deviceDetails: {
        imeiNumber,
        phoneNumber,
        type,
        vendorName,
        modelName,
        status,
        inactiveReason,
        cityName,
      },
    } = deviceDetailsData;

    previousState.imeiNumber = imeiNumber;
    previousState.phoneNumber = phoneNumber;

    previousState.type = iotDeviceTypesArray.filter(
      (data: any) => data.name === type
    )[0];

    previousState.vendorName = vendorsList?.filter(
      (vendor: any) => vendor.name === vendorName
    )[0];

    previousState.modelName = previousState.vendorName?.models?.filter(
      (model: any) => model.name === modelName
    )[0];

    previousState.status = iotStatusArray.filter(
      (data: any) => data.name === status
    )[0];

    if (status === iotStatus.INACTIVE) {
      previousState.inactiveReason = iotInactiveReasonsArray?.filter(
        (reason: any) => reason.name === inactiveReason
      )[0];
    }

    previousState.city = allCitiesDetails.filter(
      (city: any) => city.name === cityName
    )[0];

    // call to branch api on city selection
    if (previousState.city?.name) getBranches(previousState.city?.name);

    setFields((prev: FieldsIOT) => ({ ...prev, ...previousState }));
    setFieldsCopy((prev: FieldsIOT) => ({ ...prev, ...previousState }));
    setErrFields({ ...errInitialState });
  };

  // validate fields in case of add well as edit device
  const validateFields = () => {
    let optionalFields: string[] =
      fields.status?.name === iotStatus.INACTIVE ? [] : ["inactiveReason"];

    let validation: any = props?.addRole
      ? validate(fields, errFields, ["status", "inactiveReason"])
      : validate(fields, errFields, optionalFields);
    if (validation?.error) {
      setErrFields((prev: ErrorFields) => ({
        ...errInitialState,
        ...validation?.errorFields,
      }));

      return;
    }

    props?.addRole ? addIotDevice() : UpdateIotdevice();
  };

  // add iot device api call
  const addIotDevice = () => {
    let payload: AddIotDevicePayload = {
      data: {
        imeiNumber: fields.imeiNumber,
        branchName: fields?.branchName.name,
        modelName: fields?.modelName.name,
        phoneNumber: fields?.phoneNumber,
        type: fields?.type.name,
        vendorName: fields?.vendorName.name,
      },
    };
    dispatch(iotActions.addIotDevice(payload));
  };

  //get branches on basic of city
  const getBranches = (cityName: string) => {
    dispatch(rentalActions.getAllBranches({ name: cityName }));
  };

  // update iot device api call
  const UpdateIotdevice = () => {
    let payload: UpdateIotDetailsPayload = {
      data: {
        id: deviceDetailsData?.deviceDetails?.id,
        update: {
          type: fields?.type?.name,
          modelName: fields?.modelName?.name,
          vendorName: fields?.vendorName?.name,
          phoneNumber: fields?.phoneNumber,
          imeiNumber: fields?.imeiNumber,
          branchName: fields?.branchName?.name,
          status: fields?.status?.name,
        },
      },
    };
    if (fields?.status?.name === iotStatus.INACTIVE) {
      payload.data.update = {
        ...payload.data.update,
        inactiveReason: fields?.inactiveReason?.name,
      };
    }
    if (functionalitiesAccess.isEditDevice)
      dispatch(iotActions.updateIotDeatils(payload));
  };

  // reset fields in case of add well as edit device
  const resetData = () => {
    if (props?.addRole) setFields({ ...initialState });
    else {
      setApiDetailsData();
      setIsDeviceUpdated(false);
    }
  };

  // compare fields updated or not in case of edit device
  const compareData = (data: FieldsIOT) =>
    JSON.stringify(data) === JSON.stringify(fieldsCopy);

  // get unique row id for vehicle details table
  const getRowsIdVehicle = (row: any) => row?.registrationNumber;

  // get unique row id for vehicle details table
  const getRowsIdPing = (row: any) => row.deviceId;

  // dispatch action for ping data
  const handleDevicePing = () => {
    let payload: PingDevicePayload = {
      data: {
        data: {
          deviceId: [deviceId],
        },
      },
    };
    if (functionalitiesAccess.isDevicePing)
      dispatch(iotActions.getPingData(payload));
  };

  //get last active for ping details table
  const getLastActive = (lastActive: string) => {
    return `${getDateTimeFromTimeStamp(
      lastActive,
      "date"
    )} ${getDateTimeFromTimeStamp(lastActive, "time")}`;
  };

  // columns for vehicle details table
  const vehicleColumns: GridColDef[] = [
    {
      field: "registrationNo",
      headerName: IotManagement.RegistrationNo,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.registrationNumber
            ? params?.row?.registrationNumber
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "brandName",
      headerName: IotManagement.BrandName,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.brandName ? params?.row?.brandName : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "vehicleModel",
      headerName: IotManagement.Model,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.modelName ? params?.row?.modelName : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "assignDate",
      headerName: IotManagement.AssignDate,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.assignedDate
            ? dateFormat(params?.row?.assignedDate)
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
  ];

  // columns for ping details table
  const pingColumns: GridColDef[] = [
    {
      field: "status",
      headerName: global.status,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.status
            ? DISPLAY_STATUS[params?.row?.status as keyof typeof DISPLAY_STATUS]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "pingTime",
      headerName: IotManagement.PingTime,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{getDateTimeFromTimeStamp(new Date(), "time")}</span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "lastActive",
      headerName: IotManagement.LastActive,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        let lastActive = getLastActive(params?.row?.lastActive);
        return (
          <Tooltip title={lastActive}>
            <span>{params?.row?.lastActive ? lastActive : global.NA}</span>
          </Tooltip>
        );
      },
      flex: 1,
      resizable: false,
    },
    {
      field: "odometerReading",
      headerName: IotManagement.OdometerReading,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.odometerReading
            ? params.row.odometerReading
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "coordinates",
      headerName: IotManagement.Coordinates,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={`${
            params?.row?.location?.latitude +
            " , " +
            params?.row?.location?.longitude
          }`}
        >
          <span>
            {params?.row?.location?.latitude
              ? params?.row?.location?.latitude +
                " , " +
                params?.row?.location?.longitude
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "address",
      headerName: global.address,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.address}>
          <span>{params?.row?.address ? params?.row?.address : global.NA}</span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
  ];

  return (
    <Stack sx={{ gap: "20px" }}>
      <Typography sx={{ color: "red" }}>{global.allRequiredFields}</Typography>
      <Box sx={StyledObject.editIotFieldsOuterBox}>
        <CustomSelect
          icon={DeviceTypeIcon}
          width={"310px"}
          required
          placeholder={`* ${IotManagement.SelectDeviceType}`}
          choice={
            isArrayNotEmpty(iotDeviceTypesArray) ? iotDeviceTypesArray : []
          }
          value={fields?.type?.displayName}
          defaultKey={global.displayName}
          handleChange={(value: any) => handleChange("type", value)}
          errormessage={errFields.type}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />
        <CustomSelect
          width={"310px"}
          icon={AllVendorsIcon}
          required
          placeholder={`* ${IotManagement.SelectVendorName}`}
          choice={isArrayNotEmpty(vendorsList) ? vendorsList : []}
          value={fields?.vendorName?.displayName}
          defaultKey={global.displayName}
          handleChange={(value: any) => {
            handleChange("vendorName", value);
            setFields((prev: FieldsIOT) => ({
              ...prev,
              modelName: initialState.modelName,
            }));
          }}
          errormessage={errFields.vendorName}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />
        <CustomSelect
          width={"310px"}
          required
          placeholder={`* ${IotManagement.SelectModelName}`}
          choice={
            isArrayNotEmpty(fields?.vendorName?.models)
              ? fields?.vendorName?.models
              : []
          }
          value={fields?.modelName?.displayName}
          defaultKey={global.displayName}
          handleChange={(value: any) => {
            handleChange("modelName", value);
          }}
          errormessage={errFields.modelName}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />
        <Input
          width={"310px"}
          iconStart={MobileIcon}
          required
          placeholder={`* ${IotManagement.EnterPhoneNumber}`}
          value={fields?.phoneNumber}
          onChange={(e: any) => {
            checkImeiPhone(e.target.value) &&
              e.target.value.length <= 13 &&
              handleChange("phoneNumber", e.target.value);
          }}
          errormessage={errFields.phoneNumber}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />
        <Input
          width={"310px"}
          required
          placeholder={`* ${IotManagement.EnterImeiNumber}`}
          value={fields?.imeiNumber}
          onChange={(e: any) => {
            checkWhiteSpace(e.target.value) &&
              checkAlfaNumeric(e.target.value) &&
              e.target.value.length <= 16 &&
              handleChange("imeiNumber", e.target.value);
          }}
          errormessage={errFields.imeiNumber}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />

        <CustomSelect
          width={"310px"}
          required
          icon={CityInputIcon}
          placeholder={`* ${IotManagement.SelectCity}`}
          choice={isArrayNotEmpty(allCitiesDetails) ? allCitiesDetails : []}
          value={fields?.city?.displayName}
          defaultKey={global.displayName}
          handleChange={(value: any) => {
            handleChange("city", value);
            value?.name && getBranches(value.name);
          }}
          errormessage={errFields.city}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />
        <CustomSelect
          width={"310px"}
          required
          icon={WarehouseIcon}
          placeholder={`* ${IotManagement.SelectBranch}`}
          choice={isArrayNotEmpty(branches) ? branches : []}
          value={fields?.branchName?.displayName}
          handleChange={(value: any) => handleChange("branchName", value)}
          errormessage={errFields.branchName}
          defaultKey={global.displayName}
          disabled={
            props?.addRole && functionalitiesAccess.isAddIot
              ? false
              : props?.editRole && functionalitiesAccess.isEditDevice
              ? false
              : true
          }
        />

        {props?.editRole && (
          <>
            <CustomSelect
              width={"310px"}
              required
              placeholder={`* ${IotManagement.selectStatus}`}
              value={fields?.status?.displayName}
              choice={isArrayNotEmpty(iotStatusArray) ? iotStatusArray : []}
              defaultKey={global.displayName}
              handleChange={(value: any) => handleChange("status", value)}
              errormessage={errFields.status}
              disabled={
                props?.addRole && functionalitiesAccess.isAddIot
                  ? false
                  : props?.editRole && functionalitiesAccess.isEditDevice
                  ? false
                  : true
              }
            />
            {fields?.status?.name === iotStatus.INACTIVE && (
              <CustomSelect
                width={"310px"}
                required
                placeholder={`* ${IotManagement.SelectInactiveReasons}`}
                choice={
                  isArrayNotEmpty(iotInactiveReasonsArray)
                    ? iotInactiveReasonsArray
                    : []
                }
                defaultKey={global.displayName}
                value={fields?.inactiveReason?.displayName}
                handleChange={(value: any) =>
                  handleChange("inactiveReason", value)
                }
                errormessage={errFields.inactiveReason}
                disabled={
                  props?.addRole && functionalitiesAccess.isAddIot
                    ? false
                    : props?.editRole && functionalitiesAccess.isEditDevice
                    ? false
                    : true
                }
              />
            )}
          </>
        )}
      </Box>
      <Box sx={StyledObject.editIotButtons}>
        <CustomButton
          label={buttonLabels?.reset}
          variant={"outlined"}
          onClick={resetData}
          disabled={
            props?.addRole
              ? JSON.stringify(fields) === JSON.stringify(initialState)
              : !isDeviceUpdated
          }
        />
        <CustomButton
          label={
            props.addRole ? buttonLabels?.addDevice : buttonLabels?.updateDevice
          }
          variant={"outlined"}
          onClick={validateFields}
          disabled={
            props?.addRole
              ? false
              : functionalitiesAccess.isEditDevice
              ? !isDeviceUpdated
              : true
          }
          loading={addEditDeviceLoader}
        />
      </Box>
      {props.editRole ? (
        <Stack gap={"30px"}>
          {functionalitiesAccess.isDevicePing ? (
            <Stack sx={StyledObject.editIotTableOuterBox}>
              <Stack gap={"5px"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant={typographyConstants.HEADINGXL}>
                    {IotManagement.PingDetails}
                  </Typography>
                  <CustomButton
                    variant={"outlined"}
                    label={buttonLabels.ping}
                    loading={pingDeviceLoader}
                    disabled={pingDeviceLoader}
                    onClick={handleDevicePing}
                  />
                </Box>
                <DataTable
                  columns={pingColumns}
                  rows={pingDeviceData}
                  hideFooter={true}
                  disableColumnMenu
                  disableColumnSorting
                  headerAlign={"center"}
                  getRowId={getRowsIdPing}
                  disableRowSelectionOnClick
                  hideColumnSeperator
                  rowHeight={45}
                  loading={pingDeviceLoader}
                />
              </Stack>
            </Stack>
          ) : null}
          {isArrayNotEmpty(deviceDetailsData?.allocationDetails) && (
            <Box sx={StyledObject.editIotTableOuterBox}>
              <Stack>
                <Typography variant={typographyConstants.HEADINGXL}>
                  {IotManagement.VehicleDetails}
                </Typography>
                <DataTable
                  columns={vehicleColumns}
                  rows={deviceDetailsData?.allocationDetails}
                  hideFooter={true}
                  disableColumnMenu
                  disableColumnSorting
                  headerAlign={"center"}
                  getRowId={() => crypto.randomUUID()}
                  disableRowSelectionOnClick
                  hideColumnSeperator
                  rowHeight={45}
                  // loading={getDeliveryChargesLoader}
                />
              </Stack>
            </Box>
          )}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default IotViewEditDevice;
