import { Box, Chip, Container, Step, StepButton, Stepper } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  LeftBlueIcon,
  Right,
} from "../../../freedo-x-src/constantsX/exportImagesX";
import { theme } from "../../../freedo-x-src/themes";
import { CustomButton } from "../../atoms/Buttons/CustomButtons";
import styles from "./multistepForm.module.css";
import { BookingStatusColorCodes } from "../../../rentals-src/constants/constants";

//Style CSS

/* const Styles = {
  stepperStyles: {
    "& .MuiStepConnector-line": { display: "none" },
    "& .MuiStepLabel-iconContainer": { display: "none" },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 0,
    padding:"0px" ,
  },

  renderUI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  renderUIBox: {
    width: "100%",
    background: theme.palette.background.default,
    borderRadius: "8px",
    padding: "5px 32px 32px 40px",
  },
}; */

// steps interface
export interface StepsProps {
  key?: string;
  step?: string;
  validateFunction?: Function;
  render?: JSX.Element;
  renderFooter?: JSX.Element;
  stepAlert?: boolean;
  outsideRender?: JSX.Element;
  onClick?: Function;
  // showStatus: boolean;
}

interface MultistepProps {
  footer?: boolean;
  steps?: any;
  viewMode?: boolean;
  onClickStep?: Function;
  stepAlert?: boolean;
  Styles?: any;
  getStepWiseDataList?: any;
  width?: any;
  showStatus?: boolean;
  bookingStatus?: String;
  bookingStatusColor?: String;
}

const MultiStepForm = forwardRef(
  (props: MultistepProps, ref: any): JSX.Element => {
    const {
      steps,
      footer,
      viewMode,
      Styles,
      width,
      getStepWiseDataList = () => {},
    } = props;
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [stepDisabled, setStepDisabled] = useState<boolean[]>(
      Array(steps?.length).fill(true)
    );

    if (!viewMode) stepDisabled[0] = false;

    // Styles
    const stepStyles = (isActive: any, index: number) => ({
      background: isActive ? "#F2F4F4" : "",
      borderBottom: isActive ? "none" : "",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      padding: "9px 7px 9px 7px",
      display: "flex",
      justifyContent: "center",
      color: !stepDisabled[index] ? "#053C3D" : "",
      cursor: stepDisabled[index] ? "not-allowed" : "cursor",
    });
    const stepButtonStyles = (index: number) => ({
      "& .MuiStepLabel-label": {
        fontSize: "14px",
        color: !stepDisabled[index] ? "#053C3D" : "",
      },
      "& .MuiStepLabel-label.Mui-active": {
        color: viewMode ? "#053C3D" : !stepDisabled[index] ? "#053C3D" : "",
        fontSize: "14px",
        lineHeight: "24px",
      },
    });

    // Handle current step
    const handleStep = (step: any) => () => {
      setActiveStep(step);
      getStepWiseDataList(step);
      onClickStep(step);
    };

    const onClickStep = (index: number) => {
      if (steps[index]?.onClick) steps[index]?.onClick();
    };

    const handleNext = () => {
      let error = steps[activeStep]?.validateFunction();
      if (activeStep === steps?.length - 1) {
        return;
      }
      if (!error) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let arrDisabled = [...stepDisabled];
        arrDisabled[activeStep + 1] = false;
        setStepDisabled(arrDisabled);
      }
    };

    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    const nextStepsDisabled = () => {
      if (activeStep <= steps.length - 1) {
        let disabledArray = Array(steps.length).fill(false);
        for (let i = activeStep + 1; i < steps.length; i++) {
          disabledArray[i] = true;
        }
        setStepDisabled(disabledArray);
      }
    };

    const visitStep = (step: string) => {
      let activeStep: number = steps?.findIndex(
        (steps: any) => steps?.key === step
      );
      setActiveStep(activeStep);
    };

    // returns active step of multistep form
    const getActiveStep = () => steps?.[activeStep]?.key;

    useImperativeHandle(ref, () => {
      return { nextStepsDisabled, visitStep, getActiveStep };
    });

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent:
              "space-between" /* width: width ? width : "500px" */,
          }}
        >
          {/* Heading */}
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{
              "& .MuiStepConnector-line": { display: "none" },
              "& .MuiStepLabel-iconContainer": { display: "none" },
            }}
          >
            {steps?.map((item: any, index: number) => (
              <Step key={item.key} sx={stepStyles(activeStep === index, index)}>
                <StepButton
                  sx={stepButtonStyles(index)}
                  onClick={handleStep(index)}
                  disabled={viewMode ? false : stepDisabled[index]}
                >
                  <Box
                    className={styles?.BoxRow}
                    alignItems="center"
                    gap="5px"
                    margin="5px"
                  >
                    {item?.stepAlert ? (
                      <Box className={styles?.badgeBlink}> </Box>
                    ) : (
                      <></>
                    )}
                    {item.step}
                  </Box>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {props.showStatus && (
            <Chip
              label={props?.bookingStatus}
              sx={{
                background:
                  BookingStatusColorCodes[
                    props?.bookingStatus?.toUpperCase() as keyof typeof BookingStatusColorCodes
                  ],
                color: "#FFF",
              }}
            />
          )}
        </Box>

        {/* UI component */}
        <React.Fragment>
          <Box sx={Styles.renderUI}>
            <Box sx={Styles.renderUIBox}>
              <>{steps[activeStep]?.render}</>
            </Box>
          </Box>
        </React.Fragment>
        <Box sx={Styles.outsideRender}>{steps[activeStep]?.outsideRender}</Box>
        {/* footer */}

        {footer ? (
          <Container
            sx={Styles.containerMultistep}
            className={styles.containerPadding}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                width: "100%",
                height: "40px",
                backgroundColor: "#fbfbfb",
                marginTop: "10px",
              }}
            >
              {/* PREVIOUS BTN */}
              {activeStep === 0 ? null : (
                <CustomButton
                  variant="outlined"
                  label="Previous"
                  sx={{ minWidth: "200px" }}
                  // icon={LeftBlueIcon}
                  onClick={handleBack}
                />
              )}
              {steps[activeStep]?.renderFooter ? (
                steps[activeStep]?.renderFooter
              ) : (
                <CustomButton
                  variant="contained"
                  label="Next"
                  // icon={Right}
                  onClick={handleNext}
                  style={{
                    position: "fixed",
                    right: "37px",
                    minWidth: "200px",
                  }}
                />
              )}
            </Box>
          </Container>
        ) : null}
      </Box>
    );
  }
);
export default MultiStepForm;
