import Cookies from "js-cookie";
import { LocalStorage } from "../constants/enum";

const storeData = async (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Error in storing ${key} to localstotage.`);
  }
};

const getData = async (key: string) => {
  try {
    const value = await localStorage.getItem(`${key}`);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.error(`Error in getting ${key} from localstotage.`);
  }
};

// Generic function to store filter data in local storage
const storeFilterDataInLocalStorage = async (key: string, data: any) => {
  try {
    // Fetch existing data from local storage
    const storedDataString: any = localStorage.getItem(key);
    const storedData: any = storedDataString
      ? JSON.parse(storedDataString)
      : {};

    // Merge new data into existing data
    const newData: any = {
      ...storedData,
      ...data,
    };

    // Store updated data back into local storage
    localStorage.setItem(key, JSON.stringify(newData));
  } catch (e) {
    console.error(`Error in storing ${key} to localstotage.`);
  }
};

// Generic function to fetch nested values from local storage
const getFilterDataFromLocalStorage = async (
  nestedKey: string,
  parentKey: string | undefined = LocalStorage?.Filter_Data
) => {
  try {
    // Fetch data from local storage
    const storedDataString = await localStorage.getItem(parentKey);
    if (!storedDataString) {
      return null; // Return null if parentKey does not exist in local storage
    }

    const storedData = JSON.parse(storedDataString);

    // Return the value corresponding to nestedKey if it exists
    if (storedData && storedData[nestedKey] !== undefined) {
      return storedData[nestedKey];
    } else {
      return null; // Return null if nestedKey does not exist
    }
  } catch (e) {
    console.error("Error fetching data from local storage:", e);
    return null; // Return null in case of any error
  }
};

const clearFilterDataInLocalStorage = (key: string) => {
  try {
    // Fetch existing data from local storage
    const storedDataString: any = localStorage.getItem(key);
    const storedData: any = storedDataString
      ? JSON.parse(storedDataString)
      : {};

    // Merge new data into existing data
    storedData[key] = {};

    // Store updated data back into local storage
    localStorage.setItem(key, JSON.stringify(storedData));
  } catch (e) {
    console.error(`Error in storing ${key} to localstotage.`);
  }
};

const storeCookieData = async (key: string, value: any) => {
  try {
    Cookies.set(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Error in storing value - ${value} in ${key}`);
  }
};

const getCookieData = async (key: string) => {
  try {
    const value = await Cookies.get(`${key}`);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.error(`Error in getting data from Cookie - ${key}`);
  }
};

export {
  getCookieData,
  getData,
  getFilterDataFromLocalStorage,
  storeCookieData,
  storeData,
  storeFilterDataInLocalStorage,
  clearFilterDataInLocalStorage,
};
