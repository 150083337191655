import { API_URLS, BASE_URL, customAxios, getApiUrl } from "../../../config";
import {
  AddIotDevicePayload,
  GetIOTDevicePayload,
  GetIotDetailsPayload,
  GetIotPingHistoryPayload,
  PingDevicePayload,
  UpdateIotDetailsPayload
} from "../../interfaces/iotManagementInterface";
import { constructUrl } from "../../utils/helper";


// service to add iot device
const addIotDevice = (payload: AddIotDevicePayload) => {
  try {
    let url = BASE_URL + API_URLS.addIotDeviceRentals;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get iot device
const getIotDevice = (payload: GetIOTDevicePayload) => {
  try {
    let url = BASE_URL + getApiUrl().getIotDevices;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get all vendor of iot device
const getAllIotVendors = () => {
  try {
    let url = BASE_URL + getApiUrl().getIotVendors;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get details of iot device
const getIotDeviceDetails = (payload: GetIotDetailsPayload) => {
  try {
    let url = BASE_URL + getApiUrl(payload.deviceId).getIotDeviceDetails;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get ping details of iot device
const devicePing = (payload: PingDevicePayload) => {
  try {
    let url = BASE_URL + API_URLS.pingDevice;
    return customAxios.post(url, payload.data).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to update fields of iot device
const updateIotDeviceData = (payload: UpdateIotDetailsPayload) => {
  try {
    let url = BASE_URL + API_URLS.updateIotDeviceData;
    return customAxios.put(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

/// ----------------change from vms to iot-------------------->>>>>>>>>>>

// const getVehicleIOTDevices = (payload: GetVehicleIOTDevicePayload) => {
//   try {
//     let url = BASE_URL + VMS.iotManagement + VMS.vehicleIotDevices;
//     url += payload.VehicleId ? `legacyVehicleId=${payload.VehicleId}` : "";
//     return customAxios.get(url).catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

// const getIotPingHistory = (payload: GetIotPingHistoryPayload) => {    
//   try {
//     let url = BASE_URL + VMS.iotManagement + VMS.vehicleIotDevicePing;
//     url += payload.deviceId ? `deviceIds=["${payload.deviceId}"]` : "";
//     return customAxios.get(url).catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };
// -----------------------------------<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>---------------

// service to bulk upload iot devices
const bulkUploadIotDevices = (payload: any) => {
  try {
    let url = BASE_URL + API_URLS.iotBulkUpload;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get bulk upload excel sheet format of iot device
const getVehicleUploadFormat = () => {
  try {
    let url = BASE_URL + API_URLS.iotBulkSampleSheetDownload;
    return customAxios.get(url, { responseType: "blob" }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// service to get vehicle odometer details (need to remove if not used)
const getVehicleIotOdometerDetails = (payload: GetIotPingHistoryPayload) => {
  try {
    let url = BASE_URL + API_URLS.vehicleIotDevicesOdometer;
    url += payload.deviceId ? `legacyVehicleId=${payload.deviceId}` : "";
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  addIotDevice,
  getIotDevice,
  getAllIotVendors,
  getIotDeviceDetails,
  devicePing,
  updateIotDeviceData,
  //   assignIOTDevice,
  //   getVehicleIOTDevices,
  //   unAssignIOTDevice,
  //   getIotPingHistory,
  bulkUploadIotDevices,
  getVehicleUploadFormat,
  getVehicleIotOdometerDetails,
};
