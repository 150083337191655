const COUPON_CODE_WORKING_KEY = "53A999C4D43A535AGE6E3EE5A54G517C";

const sourceStatic = [
  {
    source_ids: 1,
    source_name: "1 - Freedo",
  },
  {
    source_ids: 2,
    source_name: "2 - Vida",
  },
  {
    source_ids: 3,
    source_name: "3 - Operator",
  },
];

export { COUPON_CODE_WORKING_KEY, sourceStatic };
