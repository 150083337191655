import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import RangePicker from "../../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import {
  dateFormat,
  typographyConstants,
} from "../../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  WarehouseIcon,
  fadedCalenderIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  clearReducerDataRentalCommon,
  getAllBranchesByCity,
  getModelListByBranch,
  setBookingReducerData,
} from "../../../../redux/actions/rentalCommonAction";
import { StyleObject } from "./StyleObject";

enum checkBoxKeys {
  CITY = "cities",
  HUBS = "branches",
  VEHICLE_MODEL = "model",
}

// file is in progress
const AdvanceFilter = (props: any) => {
  const {
    fields,
    advanceFilterFields,
    setAdvanceFilterFields,
    handleAdvanceFilterBookingDateRange,
    onApplyFilter,
    initialState,
    getAllModels,
  } = props;

  const dispatch = useDispatch();

  const {
    allCitiesDetails = [],
    advanceFiltersHubs = [],
    advanceFiltersModel = [],
    modelListByBranch = [],
    authoriseBranches = [],
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const [seeMore, setSeeMore] = useState<any>({
    city: true,
    warehouse: true,
    vehicleModel: true,
  });

  const isAllCitesSelected =
    advanceFilterFields?.cities?.length === allCitiesDetails.length;

  const isAllWarehouseSelected =
    advanceFilterFields?.branches?.length === advanceFiltersHubs?.length;

  const isAllVehiclesSelected =
    advanceFilterFields?.model?.length === advanceFiltersModel.length;

  useEffect(() => {
    const obj = {
      bookingDateFrom: fields?.bookingDateFrom || "",
      bookingDateTill: fields?.bookingDateTill || "",
      cities: fields?.cities || [],
      branches: fields?.branches || [],
      model: fields?.model || [],
      status: fields?.status || "",
      search: fields?.search || "",
      userPhoneNumber: fields?.userPhoneNumber || "",
      advanceFilter: fields?.advanceFilter,
    };

    setAdvanceFilterFields((prev: any) => ({
      ...prev,
      ...obj,
    }));
  }, []);

  //for check and uncheck the advance filter

  useEffect(() => {
    if (advanceFiltersHubs?.length && advanceFilterFields.branches?.length) {
      const branches = advanceFiltersHubs.filter((item: any) =>
        advanceFilterFields.branches.includes(item.name)
      );

      setAdvanceFilterFields((prev: any) => ({
        ...prev,
        branches: branches?.map((data: any) => data?.name),
      }));
    }
  }, [advanceFiltersHubs]);

  //for check and uncheck the advance filter
  useEffect(() => {
    if (advanceFiltersModel?.length && advanceFilterFields.model?.length) {
      const models = advanceFiltersModel.filter((item: any) =>
        advanceFilterFields.model.includes(item.name)
      );

      setAdvanceFilterFields((prev: any) => ({
        ...prev,
        model: models?.map((data: any) => data?.name),
      }));
    }
  }, [advanceFiltersModel]);

  // handle view more of advance filter
  const handleViewMore = (key: string) => {
    setSeeMore({ ...seeMore, [key]: !seeMore[key] });
  };

  const handleCityChange = (data: any = []) => {
    if (!data.length) {
      setAdvanceFilterFields((prev: any) => ({
        ...prev,
        branches: [],
      }));

      // get all branches when all cites unselected
      const arrData = allCitiesDetails.map((item: any) => item.name);

      dispatch(
        getAllBranchesByCity(
          {
            cities: arrData,
          },
          true
        )
      );

      getAllModels();
    } else {
      dispatch(
        getAllBranchesByCity(
          {
            cities: data,
          },
          true
        )
      );
    }
  };

  const handleBranchChange = (data: string[] = []) => {
    if (!data.length) {
      getAllModels();

      // get all models when all cites unselected
      const arrData = advanceFiltersHubs.map((item: any) => item.name);

      dispatch(
        getModelListByBranch(
          {
            branches: arrData,
          },
          true
        )
      );
    } else {
      dispatch(
        getModelListByBranch(
          {
            branches: data,
          },
          true
        )
      );
    }
  };

  // handle checkbox change
  const handleCheckboxChange = (key: string, value: string) => {
    let data: string[];
    if (advanceFilterFields[key]?.includes(value)) {
      data = advanceFilterFields[key].filter((e: string) => e !== value);
    } else {
      data = [...advanceFilterFields[key], value];
    }

    if (key === "cities") {
      handleCityChange(data);
    } else if (key === "branches") {
      handleBranchChange(data);
    }

    setAdvanceFilterFields((prev: any) => ({
      ...prev,
      [key]: [...data],
    }));
  };

  const handleSelectAll = (key: string) => {
    let arrData: any;
    if (key === "cities") {
      arrData = isAllCitesSelected
        ? []
        : allCitiesDetails.map((item: any) => item.name);
      handleCityChange(arrData);
    } else if (key === "branches") {
      arrData = isAllWarehouseSelected
        ? []
        : advanceFiltersHubs.map((item: any) => item.name);
      handleBranchChange(arrData);
    } else if (key === "model") {
      arrData = isAllVehiclesSelected
        ? []
        : advanceFiltersModel.map((item: any) => item.name);
    }
    setAdvanceFilterFields((prev: any) => ({ ...prev, [key]: arrData }));
  };

  //on reset adv filter
  const onResetAdvanceFilters = () => {
    setAdvanceFilterFields({ ...initialState });
    handleCityChange();
    handleBranchChange();
  };

  // handle ride dates
  const handleRideDateRange = (key: string, data: any) => {
    const [startDate, endDate] = data;
    setAdvanceFilterFields((prev: any) => ({
      ...prev,
      advanceFilter: {
        ...prev.advanceFilter,
        [key]: {
          startDate,
          endDate,
        },
      },
    }));
  };

  const handleDateCheckboxChange = (key: string, checked: boolean) => {
    setAdvanceFilterFields((prev: any) => ({
      ...prev,
      advanceFilter: {
        ...prev.advanceFilter,
        checkBoxCheck: {
          ...prev.advanceFilter.checkBoxCheck,
          [key]: checked,
        },
        [key]: {
          startDate: checked
            ? ""
            : advanceFilterFields?.advanceFilter[key]?.startDate,
          endDate: checked
            ? ""
            : advanceFilterFields?.advanceFilter[key]?.endDate,
        },
      },
    }));
  };

  return (
    <Stack>
      <Stack
        style={{
          // display: "flex",
          paddingBottom: "75px",
          paddingTop: "14px",
          gap: "10px",
          paddingLeft: "14px",
          paddingRight: "10px",
        }}
      >
        <Box>
          <FormLabel style={{ marginBottom: "12px" }}>
            <img
              src={fadedCalenderIcon}
              style={{ marginRight: "8px", marginLeft: "-3px" }}
            />
            {rentalEn.tableHeaders.bookingDate}
          </FormLabel>
          <RangePicker
            dateFormat={dateFormat}
            placeholderText={
              rentalEn?.bookingManagement?.selectBookingStartAndEndDate
            }
            selectsRange
            startDate={advanceFilterFields?.bookingDateFrom}
            endDate={advanceFilterFields?.bookingDateTill}
            onChange={(update: any) =>
              handleAdvanceFilterBookingDateRange(update)
            }
            isClearable={
              advanceFilterFields?.bookingDateFrom &&
              advanceFilterFields?.bookingDateTill
            }
            onClickOutside={(e: any) => {
              // handling if user clicks outside after selecing start date
              if (
                advanceFilterFields.bookingDateFrom &&
                !advanceFilterFields.bookingDateTill
              ) {
                setAdvanceFilterFields((prev: any) => ({
                  ...prev,
                  bookingDateFrom: initialState.bookingDateFrom,
                  bookingDateTill: initialState.bookingDateTill,
                }));
              }
            }}
            icon={fadedCalenderIcon}
          />
        </Box>
        <Divider />
        <FormLabel>
          <img
            src={fadedCalenderIcon}
            style={{ marginRight: "8px", marginLeft: "-3px" }}
          />
          {rentalEn.bookingManagement.rideStartRideEnd}
        </FormLabel>
        <Grid
          container
          spacing={2}
          sx={{ marginRight: "8px", marginLeft: "-3px", marginTop: "0px" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  advanceFilterFields?.advanceFilter?.checkBoxCheck
                    ?.rideDatesStart
                }
                onChange={(e) => {
                  const { checked } = e.target;
                  handleDateCheckboxChange("rideDatesStart", checked);
                }}
              />
            }
            label="Ride Start Date"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  advanceFilterFields?.advanceFilter.checkBoxCheck?.rideDatesEnd
                }
                onChange={(e) => {
                  const { checked } = e.target;
                  handleDateCheckboxChange("rideDatesEnd", checked);
                }}
              />
            }
            label="Ride End Date"
          />
        </Grid>
        {advanceFilterFields?.advanceFilter?.checkBoxCheck?.rideDatesStart ? (
          <Grid item sm={12} md={4}>
            <RangePicker
              dateFormat={dateFormat}
              placeholderText={
                rentalEn?.bookingManagement?.selectRideStartRange
              }
              selectsRange
              startDate={
                advanceFilterFields?.advanceFilter?.rideDatesStart?.startDate
              }
              endDate={
                advanceFilterFields?.advanceFilter?.rideDatesStart?.endDate
              }
              isClearable={
                advanceFilterFields?.advanceFilter?.rideDatesStart?.startDate &&
                advanceFilterFields?.advanceFilter?.rideDatesStart?.endDate
              }
              onClickOutside={(e: any) => {
                if (
                  advanceFilterFields.advanceFilter?.rideDatesStart
                    ?.startDate &&
                  !advanceFilterFields?.advanceFilter?.rideDatesStart?.endDate
                ) {
                  setAdvanceFilterFields((prev: any) => ({
                    ...prev,
                    advanceFilter: {
                      ...prev.advanceFilter,
                      rideDatesStart: initialState.rideDatesStart,
                    },
                  }));
                }
              }}
              onChange={(update: any) =>
                handleRideDateRange("rideDatesStart", update)
              }
              icon={fadedCalenderIcon}
              popperPlacement={"bottom"}
            />
          </Grid>
        ) : null}
        {advanceFilterFields?.advanceFilter?.checkBoxCheck?.rideDatesEnd ? (
          <Grid item sm={12} md={4}>
            <RangePicker
              dateFormat={dateFormat}
              placeholderText={rentalEn.bookingManagement.selectRideEndRange}
              selectsRange
              startDate={
                advanceFilterFields?.advanceFilter?.rideDatesEnd?.startDate
              }
              endDate={
                advanceFilterFields?.advanceFilter?.rideDatesEnd?.endDate
              }
              isClearable={
                advanceFilterFields?.advanceFilter?.rideDatesEnd?.startDate &&
                advanceFilterFields?.advanceFilter?.rideDatesEnd?.endDate
              }
              onClickOutside={(e: any) => {
                if (
                  advanceFilterFields.advanceFilter?.rideDatesEnd?.startDate &&
                  !advanceFilterFields?.advanceFilter?.rideDatesEnd?.endDate
                ) {
                  setAdvanceFilterFields((prev: any) => ({
                    ...prev,
                    advanceFilter: {
                      ...prev.advanceFilter,
                      rideDatesEnd: initialState.rideDatesEnd,
                    },
                  }));
                }
              }}
              onChange={(update: any) =>
                handleRideDateRange("rideDatesEnd", update)
              }
              icon={fadedCalenderIcon}
              popperPlacement={"bottom"}
            />
          </Grid>
        ) : null}

        <Divider sx={{ marginBottom: "4px" }} />
        <FormLabel
          style={{
            marginRight: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <img
              src={CityInputIcon}
              style={{ marginRight: "4px", marginLeft: "-3px" }}
            />
            <Box>{rentalEn.global.city}</Box>
            {advanceFilterFields?.cities?.length ? (
              <Box
                sx={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: colors.primary_dark_tone1,
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: colors.white,
                  fontSize: "8px",
                  opacity: "0.7",
                  marginLeft: "4px",
                }}
              >
                {advanceFilterFields?.cities?.length}
              </Box>
            ) : null}
          </Box>
          <Typography
            onClick={() => {
              handleSelectAll("cities");
            }}
            sx={{
              color: colors.theme_blue,
              fontSize: 13,
              fontWeight: "500",
              cursor: "pointer",
              float: "right",
            }}
          >
            {isAllCitesSelected
              ? rentalEn?.global?.unselectAll
              : rentalEn?.global?.selectAll}
          </Typography>
        </FormLabel>
        <FormGroup
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "7px",
          }}
          row={true}
        >
          {allCitiesDetails
            .slice(0, seeMore.city ? 6 : allCitiesDetails.length)
            .map((city: any) => {
              const isSelected = (advanceFilterFields.cities || []).includes(
                city?.name
              );
              return (
                <FormControlLabel
                  key={city?.id}
                  value={city?.name}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={() =>
                        handleCheckboxChange(checkBoxKeys.CITY, city?.name)
                      }
                      sx={{
                        "&.MuiCheckbox-root": {
                          padding: "0px 9px",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  label={
                    <Tooltip title={city.displayName}>
                      {city.displayName}
                    </Tooltip>
                  }
                  style={{ width: "30%", zIndex: 0 }}
                />
              );
            })}
        </FormGroup>
        {isArrayNotEmpty(allCitiesDetails) && allCitiesDetails?.length > 6 && (
          <Typography
            variant={typographyConstants.BODY}
            sx={StyleObject?.viewMoreText}
            onClick={() => handleViewMore("city")}
          >
            {!seeMore.city
              ? rentalEn?.global?.showLess
              : rentalEn?.global?.viewMore}
          </Typography>
        )}

        {advanceFiltersHubs?.length ? (
          <Fade>
            <Divider />
            <FormLabel
              style={{
                marginRight: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={WarehouseIcon}
                  style={{ marginRight: "4px", marginLeft: "-3px" }}
                />
                <Box>{rentalEn.global.hub}</Box>
                {advanceFilterFields?.branches?.length ? (
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: colors.primary_dark_tone1,
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: colors.white,
                      fontSize: "8px",
                      opacity: "0.7",
                      marginLeft: "4px",
                    }}
                  >
                    {advanceFilterFields?.branches?.length}
                  </Box>
                ) : null}
              </Box>
              <Typography
                onClick={() => {
                  handleSelectAll("branches"); /// pending
                }}
                sx={{
                  color: colors.theme_blue,
                  fontSize: 13,
                  fontWeight: "500",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                {isAllWarehouseSelected
                  ? rentalEn?.global?.unselectAll
                  : rentalEn?.global?.selectAll}
              </Typography>
            </FormLabel>
            <FormGroup
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "7px",
              }}
              row={true}
            >
              {advanceFiltersHubs
                .slice(0, seeMore.warehouse ? 6 : advanceFiltersHubs.length)
                .map((branch: any) => {
                  const isSelected = (
                    advanceFilterFields.branches || []
                  ).includes(branch?.name);
                  return (
                    <FormControlLabel
                      key={branch?.name}
                      value={branch?.name}
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={() =>
                            handleCheckboxChange(
                              checkBoxKeys.HUBS,
                              branch?.name
                            )
                          }
                          sx={{
                            "&.MuiCheckbox-root": {
                              padding: "0px 9px",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      label={
                        <Tooltip title={branch.displayName}>
                          {branch.displayName}
                        </Tooltip>
                      }
                      style={{ width: "30%", zIndex: 0 }}
                    />
                  );
                })}
            </FormGroup>
            {isArrayNotEmpty(advanceFiltersHubs) &&
              advanceFiltersHubs?.length > 6 && (
                <Typography
                  variant={typographyConstants.BODY}
                  sx={StyleObject?.viewMoreText}
                  onClick={() => handleViewMore("warehouse")}
                >
                  {!seeMore.warehouse
                    ? rentalEn?.global?.showLess
                    : rentalEn?.global?.viewMore}
                </Typography>
              )}
          </Fade>
        ) : null}

        <Divider sx={{ marginBottom: "4px" }} />

        {advanceFiltersModel?.length ? (
          <Fade>
            <FormLabel
              style={{
                marginRight: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={BikeIconFaded}
                  style={{ marginRight: "4px", marginLeft: "-3px" }}
                />
                <Box>{rentalEn.global.vehicleModel}</Box>
                {advanceFilterFields?.model?.length ? (
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: colors.primary_dark_tone1,
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: colors.white,
                      fontSize: "8px",
                      opacity: "0.7",
                      marginLeft: "4px",
                    }}
                  >
                    {advanceFilterFields?.model?.length}
                  </Box>
                ) : null}
              </Box>
              <Typography
                onClick={() => {
                  handleSelectAll("model");
                }}
                sx={{
                  color: colors.theme_blue,
                  fontSize: 13,
                  fontWeight: "500",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                {isAllVehiclesSelected
                  ? rentalEn?.global?.unselectAll
                  : rentalEn?.global?.selectAll}
              </Typography>
            </FormLabel>
            <FormGroup
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "7px",
              }}
              row={true}
            >
              {advanceFiltersModel
                .slice(
                  0,
                  seeMore.vehicleModel ? 6 : advanceFiltersModel?.length
                )
                .map((model: any) => {
                  const isSelected = (advanceFilterFields.model || []).includes(
                    model?.name
                  );
                  return (
                    <FormControlLabel
                      key={model?.id}
                      value={model?.name}
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={() =>
                            handleCheckboxChange(
                              checkBoxKeys.VEHICLE_MODEL,
                              model?.name
                            )
                          }
                          sx={{
                            "&.MuiCheckbox-root": {
                              padding: "0px 9px",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      label={
                        <Tooltip title={model.displayName}>
                          {model.displayName}
                        </Tooltip>
                      }
                      style={{ width: "30%", zIndex: 0 }}
                    />
                  );
                })}
            </FormGroup>

            {isArrayNotEmpty(advanceFiltersModel) &&
              advanceFiltersModel?.length > 6 && (
                <Typography
                  variant={typographyConstants.BODY}
                  sx={StyleObject?.viewMoreText}
                  onClick={() => handleViewMore("vehicleModel")}
                >
                  {!seeMore.vehicleModel
                    ? rentalEn?.global?.showLess
                    : rentalEn?.global?.viewMore}
                </Typography>
              )}
          </Fade>
        ) : null}
      </Stack>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          paddingBottom: "24px",
          paddingTop: "12px",
          paddingLeft: "12px",
          gap: "10px",
          right: "10px",
          backgroundColor: colors.whiteF9,
        }}
      >
        <CustomButton
          label={rentalEn.global.clearFilters}
          variant="outlined"
          onClick={onResetAdvanceFilters}
          disabled={
            JSON.stringify(advanceFilterFields) === JSON.stringify(initialState)
          }
        />
        <CustomButton
          label={rentalEn.global.applyFilters}
          variant="contained"
          onClick={onApplyFilter}
        />
      </Box>
    </Stack>
  );
};

export default AdvanceFilter;
