import * as actionTypes from "../actionTypes";

interface LoginInterface {
  userName: string;
  password: string;
}

export const login = (payload: LoginInterface) => {
  return { type: actionTypes.AUTH_ACTIONS.USER_LOGIN_REQUESTED, payload };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_ACTIONS.LOGOUT,
  };
};

export const setUserData = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.SET_USER_DATA, payload };
};
export const setUserToken = (payload: any) => {
  return { type: actionTypes.AUTH_ACTIONS.SET_USER_TOKEN, payload };
};
