import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { typographyConstants } from "../../../rentals-src/constants/constants";

const MuiAccordion = (props: any) => {
  const {
    bodyBackground = "#E2F5ED",
    isExpanded,
    onExpand,
    chipTitle = "",
    customSummaryStyle = {},
    customStyle = {},
    titleStyle = {},
    isDisabled,
    accordianTitle,
    containerStyle,
    ...rest
  } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...containerStyle,
      }}
    >
      <Accordion
        sx={{
          position: "relative",
          background: { bodyBackground },
          boxShadow: "none",
          border: "1px solid lightgray",
          ...customStyle,
        }}
        expanded={isExpanded}
        onChange={onExpand}
        disabled={!!isDisabled}
        {...rest}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            borderBottomLeftRadius: !isExpanded ? "16px" : "",
            borderBottomRightRadius: !isExpanded ? "16px" : "",
            background: "#fff",
            borderBottom:
              "1px solid lightgray" /* border: "1px solid lightgray" */,
            ...customSummaryStyle,
          }}
        >
          {chipTitle ? (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                padding: "10px 40px",
                height: "21px",
                borderRadius: "16px 0px",
                backgroundColor: "#053C3D",
                left: "0",
                display: "flex",
                justifyContent: "center",
                color: "#fff",
                fontSize: "12px",
                alignItems: "center",
                width: "190px",
              }}
            >
              {chipTitle}
            </Box>
          ) : null}
          <Typography
            variant={typographyConstants.HEADING_NO_PADDING}
            sx={{ width: "100%", marginLeft: "6px", ...titleStyle }}
          >
            {accordianTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default MuiAccordion;
