import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link, Stack, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import {
  CustomButton,
  CustomChip,
} from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomAccordion from "../../../../../../sharedComponents/molecules/Accordion/customAccordian";
import SideDrawer from "../../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import {
  IotDeviceTypesDisplay,
  typographyConstants,
} from "../../../../../constants/constants";
import { VmsIotDetailsProps } from "../../../../../interfaces/vehicleInterface";
import en from "../../../../../locale/rental-en.json";
import { dateFormat } from "../../../../../utils/helper";
import { PingHistoryCards } from "./iotCards";
import { SkeltonLoader } from "./iotSkeltonLoader";
import { colors } from "../../../../../../themes/colors";
import { useEffect, useRef, useState } from "react";

export const IotDetails = (props: VmsIotDetailsProps) => {
  const { global, buttonLabels, VehicleManagement, errorMessages } = en;

  const {
    onclick,
    expand,
    loading,
    iotData,
    unassignIotDevice,
    pingHistoryLoader,
    pingHistoryData,
    sideDrawerIot,
    toggleDrawer,
    onClickShowMore,
    pagination,
  } = props;

  // side drawer ref
  const sideDrawerRef = useRef<any>(null);

  const [scroll, setScroll] = useState<any>(null);

  // returns unique row id to row
  const getRowId = (row: any) => {
    return row?.deviceId;
  };

  // columns for iot details table
  const columnsIotDetails = [
    {
      field: "deviceType",
      headerName: VehicleManagement.deviceType,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.deviceType
            ? IotDeviceTypesDisplay[
                params?.row?.deviceType as keyof typeof IotDeviceTypesDisplay
              ]
            : en.global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "imeiNumber",
      headerName: VehicleManagement.imeiNumber,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.imeiNumber ? params?.row?.imeiNumber : en.global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "phoneNumber",
      headerName: VehicleManagement.phoneNumber,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.phoneNumber ? params?.row?.phoneNumber : en.global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "assignedDate",
      headerName: VehicleManagement.assignedDate,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.assignedDate
            ? dateFormat(params?.row?.assignedDate)
            : en.global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      type: "actions",
      field: "pingHistory",
      headerName: VehicleManagement.pingHistory,
      headerAlign: "center",
      align: "center",
      getActions: (params: GridRenderCellParams<any>) => {
        return [
          <Link
            underline="always"
            sx={{ fontWeight: "400", color: "#09CACC" }}
            onClick={() => toggleDrawer(params?.row)}
          >
            {VehicleManagement.pingHistory}
          </Link>,
        ];
      },

      flex: 1,
    },
    {
      type: "actions",
      field: "action",
      headerName: global.action,
      headerAlign: "center",
      align: "center",
      getActions: (params: GridRenderCellParams<any>) => {
        return [
          <Box color={"red"}>
            <CustomButton
              label={buttonLabels.unassign}
              width={"120px"}
              variant="outlined"
              sx={{
                padding: "0px 6px",
                height: "10px",
                minHeight: "30px",
                fontSize: "11px",
              }}
              loading={loading}
              disabled={loading}
              onClick={() => unassignIotDevice(params?.row)}
            />
          </Box>,
        ];
      },
      flex: 1,
    },
  ];

  return (
    <>
      <CustomAccordion
        expand={expand}
        renderAccordianTab={
          <Typography variant={typographyConstants.HEADING}>
            {VehicleManagement.iotDetails}
          </Typography>
        }
        renderAccordionBody={
          <Box>
            <DataTable
              columns={columnsIotDetails}
              rows={iotData}
              hideFooter={true}
              disableColumnMenu
              disableColumnSorting
              headerAlign={"center"}
              getRowId={getRowId}
              rowHeight={45}
              // onRowClick={handleRowClick}
              loading={loading}
              paginationMode="server"
              hideColumnSeperator
            />
          </Box>
        }
        onClick={onclick}
        customBodyStyle={{ padding: "12px" }}
      />
      <SideDrawer
        open={sideDrawerIot}
        heading={VehicleManagement.pingHistory}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <>
            <>
              {pingHistoryData?.pingHistoryDetails?.length ? (
                <Stack
                  gap={"20px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box ref={sideDrawerRef}>
                    {" "}
                    {pingHistoryData?.pingHistoryDetails?.map((data: any) => (
                      <PingHistoryCards data={data} />
                    ))}
                  </Box>

                  <Box sx={{ margin: "0px 0px 20px 0px" }}>
                    {pagination?.pingHistoryPagination?.pageSize <
                      pingHistoryData?.pagination?.totalCount && (
                      <CustomButton
                        startIcon={<ExpandMoreIcon />}
                        variant={"outlined"}
                        label={buttonLabels.showMore}
                        onClick={() => {
                          onClickShowMore();
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "40px",
                    padding: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    sx={{ color: colors.tertiary_black }}
                    variant={typographyConstants.SUBHEADING}
                  >
                    {errorMessages.noDataFound}
                  </Typography>
                </Box>
              )}
            </>
          </>
        }
      />
    </>
  );
};
