//@ts-ignore
import { Box } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import WarningComponent from "../components/Warning";
import PersistentDrawerLeft from "../components/drawer";
import Snackbars from "../components/snackBar";
import { setAuthToken } from "../config";
import TokenService from "../config/tokenService";
import { BookingTypes, CookieTypes } from "../constants/enum";
import FreedoXLayout from "./AppLayout";
import { AccessTypes } from "../interfaces/commonInterface";
import CONSTANTS from "../locale/en.json";
import { actions } from "../redux";
import { RootState } from "../redux/reducers";
import {
  AccessDenied,
  ForgotPasswordScreen,
  LinkVerificationScreen,
  LoginV3,
  MultifactorAuthenticationScreen,
  SetPasswordScreen,
  UnlockAccountScreen,
  WrongUrl,
} from "../screens";
import { CustomRoutesLTR } from "../utils/Routes";
import { checkAccess } from "../utils/helper";

export default function MainRouter() {
  const data = useSelector((state: RootState) => state.toastReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData, isFreedoX } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const { isAuthenticated, isLoggedIn } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  const [showUI, setShowUI] = useState(false);

  useEffect(() => {
    const data: string | undefined | any = Cookies.get(CookieTypes.TOKEN); // get token details from cookies
    if (data) {
      // if 'token' is present in the cookies
      let parsedData: any = JSON.parse(data);
      if (TokenService.isAccessTokenExpired()) {
        // if accessToken has expired, then get new accessToken
        TokenService.getAccessToken()
          .then((newAccessToken) => {
            // Set the new access token in the request headers
            setAuthToken(newAccessToken);
            const updatedData: any = Cookies.get(CookieTypes.TOKEN); // get updated token details from the cookies
            let updatedParsedData: any = JSON.parse(updatedData);
            // set updated token details
            dispatch(
              actions.setUserToken({
                ...updatedParsedData,
              })
            );
            dispatch(actions.setProfileData(navigate));
          })
          .catch((error) => {
            // Log if there's an error in refreshing the token
            console.error("Error refreshing token:", error);
            toastr.error("Oops", CONSTANTS?.RefreshTokenError);
          });
      } else {
        // accessToken has not yet expired
        setAuthToken(parsedData?.token); // Set the existing access token from cookies in the request headers
        dispatch(
          actions.setUserToken({
            ...parsedData,
          })
        );
        dispatch(actions.setProfileData(navigate));
      }
    }

    setTimeout(() => {
      setShowUI(true);
    }, 750);
  }, []);

  const location = useLocation();
  const bookingPages = [
    "upgrade-vehicle",
    "add-ongoing-charges",
    "add-charges",
    "pay-remaining-amount",
    "extend-ride",
    "premature-endride",
    "premature-end-ride",
  ];

  const checkLineOfBusiness = async (key: string, fromLoginPage: boolean) => {
    try {
      const isFreedoXEnabled = await localStorage.getItem(`${key}`);
      if (isFreedoXEnabled === "true") {
        dispatch(actions.setUserFreedoDomain(true));
        if (fromLoginPage) navigate("/booking-list", { replace: true });
      } else {
        const locArr: any = location?.pathname?.split("/");
        if (bookingPages?.includes(locArr[2])) {
          // dispatch(
          //   actions.bookingDetailDataAction({
          //     id: locArr[locArr?.length - 1],
          //   })
          // );
          // dispatch(
          //   actions.getBookingDetails({
          //     bookingId: locArr[locArr?.length - 1],
          //     detailsType: BookingTypes.booking_info,
          //   })
          // );
        }
        if (fromLoginPage) navigate("/dashboard", { replace: true });
      }
    } catch (ex) {
      throw new Error("failed");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkLineOfBusiness("isFreedoX", isLoggedIn);
    }
  }, [isAuthenticated]);

  return (
    <Box className="root-container">
      {showUI ? (
        <>
          <Snackbars
            open={data.showToast}
            messageText={data.toastMessage}
            toastType={data.toastType}
          />
          {!isAuthenticated ? (
            <Routes>
              <Route path="/" element={<LoginV3 />} />
              <Route path="/set-password" element={<SetPasswordScreen />} />
              <Route
                path="/user-unlock-account"
                element={<UnlockAccountScreen />}
              />
              <Route
                path="/user-forgot-password"
                element={<ForgotPasswordScreen />}
              />

              <Route
                path="/multifactor-auth"
                element={<MultifactorAuthenticationScreen />}
              />
              <Route path="/first-login" element={<LinkVerificationScreen />} />
              <Route
                path="/password-expire"
                element={<LinkVerificationScreen />}
              />
              <Route
                path="/unlock-account"
                element={<LinkVerificationScreen />}
              />
              <Route
                path="/forgot-password"
                element={<LinkVerificationScreen />}
              />

              <Route path="*" element={<WrongUrl />} />
            </Routes>
          ) : (
            <FreedoXLayout props="" />
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
