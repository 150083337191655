import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonActions,
  UserActions,
  PackageManagementRoutes,
} from "../../constants/userActions";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import StickyHeaderTable from "../../components/stickeyHeader";
import { UserStatus } from "../../constants/bookingContants";
import { Status } from "../../components/status";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux";

//

function AdvertisementManagement() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { advertisementManagement, global } = locale;
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const { advertisementData, advertisementloading, totalItems } = useSelector(
    (state: RootState) => state.masterReducer
  );

  const handlePage = (page: any) => {
    // alert(page);
    setPage(page);
  };

  const handleRows = (row: any) => {
    setSize(row);
  };
  const columns = [
    { id: "adv_txt", label: global.Advertisement, minWidth: 70 },
    {
      id: "status",
      label: global.Status,
      minWidth: 170,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return (
          <Status
            status={
              item?.status === 3
                ? "Deleted"
                : item?.status === 1
                  ? "Active"
                  : "InActive"
            }
          />
        );
      },
    },

    {
      id: "action",
      label: global.Action,
      minWidth: 170,
      align: "center",
    },
  ];

  useEffect(() => {
    dispatch(
      actions.getAdvertisementData({
        type: "Advertisement",
        id: 0,
        page: page + 1,
        size: size,
      })
    );
  }, [refresh, reset, page, size]);

  return (
    <div className="container pd-20">
      <Heading
        title={advertisementManagement.AdvertisementManagement}
        actionButtons={[
          ButtonActions.refresh,
          ButtonActions.reset,
          ButtonActions.AddAdvertisement,
        ]}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.AddAdvertisement) {
            navigate("add-advertisement");
          } else if (type === ButtonActions.reset) {
            setReset(!reset);
            setPage(0);
            setSize(10);
          } else if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          }
        }}
      />
      <StickyHeaderTable
        pageSetter={handlePage}
        rowsPerPageSetter={handleRows}
        loading={advertisementloading}
        paddingAction="4px"
        page={page}
        size={size}
        totalItems={totalItems}
        rows={advertisementData}
        actions={[UserActions.update]}
        columns={columns}
        onClickAction={(
          type: string,
          id: number | string,
          vehicleId,
          allData
        ) => {
          if (type === UserActions.update) {
            navigate("edit-advertisement/" + allData?.id);
          }
        }}
      />
    </div>
  );
}

export default AdvertisementManagement;
