import React from "react";

const YesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        stroke={props?.color ? props?.color : "#01353D"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M2 9l3.5 3.5L16 1.5"
      ></path>
    </svg>
  );
};

export default YesIcon;
