import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barOptions = {
  //indexAxis: 'y',
  maintainAspectRatio: false,

  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,

      ticks: {
        min: 0,
        max: 100,
        stepSize: 20,
        callback: function (value: any) {
          return ((value / this.max) * 100).toFixed(0) + "%"; // convert it to percentage
        },
      },
    },
  },
};

const labels = [
  "Pleasure Plus",
  "Glamour",
  "Splendor +",
  "Maestro Edge 125",
  "Destini 125",
  "Xtream 200",
  "Xpulse 200 ",
  "Electric Scooter",
  "Classic 350",
  "HF Deluxe",
  "Xtream 200 S",
  "Maestro Edge 110",
  "Paasion Pro",
  "Splendor Pro",
  "Super Splendor",
  "Xpulse 200 T",
];

export function BarChart(props: any) {
  const data = {
    labels: props.labels || labels,
    datasets: [
      {
        data: props.data || [
          10, 40, 30, 95, 50, 5, 15, 34, 90, 35, 50, 22, 46, 78, 80, 99,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
        // label: '# of Vehicles',
      },
    ],
  };
  return <Bar options={barOptions} data={data} />;
}
