import * as actionTypes from "../actions/actionTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import rentalPlansXService from "../../services/rentalPlansXService";
import { toastr } from "react-redux-toastr";

function* getRentalPlansX(action: any): any {
  try {
    const data = yield call(
      rentalPlansXService.getAvailableRentalPlansX,
      action.payload
    );

    if (data?.status === 200) {
      const responseObj = data.data?.data;
      const plansArr = responseObj.plans;
      const durationInMonthsArr = plansArr.map((plan: any) => {
        return plan.durationInMonths;
      });
      const uniqueDurationInMonths = durationInMonthsArr.filter(
        (value: any, index: any, self: string | any[]) => {
          return self.indexOf(value) === index;
        }
      );
      yield put({
        type: actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_SUCCESS,
        payload: {
          rentalPlans: responseObj,
          durationInMonths: [...uniqueDurationInMonths],
        },
      });
    } else {
      toastr.error("", "No Rental Plans Available");
      yield put({
        type: actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

function* rentalPlansSagaX() {
  yield takeLatest(
    actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_REQUESTED,
    getRentalPlansX
  );
}

export default rentalPlansSagaX;
