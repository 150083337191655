import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import iotManagementServices from "../services/iotManagementServices";
import { handleMessage } from "../../../utils/helper";
import * as actionTypes from "../actions/actionTypes";
import { actionInterface } from "../../interfaces/commonInterface";
import { saveAs } from "file-saver";
// import { iotDetailsModel } from "../models/iotManagementModals";
import en from "../../locale/rental-en.json";
import { fileFormats } from "../../constants/constants";
const { successMessage } = en;

const vehicleDetailsArray = (allocationDetails: any, vehicleDetails: any) => {
  const concatenatedArray = allocationDetails?.map((obj1: any) => {
    const obj2 = vehicleDetails.find((obj: any) => obj.vehicleId === obj1.id);
    return { ...obj1, ...obj2 };
  });

  return concatenatedArray;
};

// api call to add new iot device
function* addIotDeviceRequest(action: actionInterface): any {
  try {
    const data = yield call(iotManagementServices.addIotDevice, action.payload);
    if (data.status === 200 || data.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_ADD_IOT_DEVICE_SUCCEEDED,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_ADD_IOT_DEVICE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_ADD_IOT_DEVICE_FAILED,
    });
  }
}

// api call to get list of all iot devices and filters may apply
function* getIotDeviceList(action: actionInterface): any {
  try {
    const data = yield call(iotManagementServices.getIotDevice, action.payload);
    if (data?.status === 200 || data.status === 201) {
      const { branches } = data?.data?.data?.filter || {};

      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_ALL_IOT_SUCCESS,
        payload: {
          iotListData: { ...(data?.data?.data || {}), branches } || [],
        },
      });
    } else {
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_ALL_IOT_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (ex) {
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_ALL_IOT_FAILED,
    });
    toastr.error("", handleMessage(ex));
  }
}

// api call to get all vendors list of iot devices
function* getAllVendors(): any {
  try {
    const data = yield call(iotManagementServices.getAllIotVendors);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_ALL_IOT_VENDORS_SUCCESS,
        payload: {
          vendorsList: data?.data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_ALL_IOT_VENDORS_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));

    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_ALL_IOT_VENDORS_FAILED,
    });
  }
}

// api call to get Iot details of particular iot device (passing device id)
function* getIotDeviceDetails(action: actionInterface): any {
  try {
    const data = yield call(
      iotManagementServices.getIotDeviceDetails,
      action.payload
    );

    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_IOT_DEVICE_DETAILS_SUCCESS,
        payload: {
          deviceDetailsData: data?.data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_IOT_DEVICE_DETAILS_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_IOT_DEVICE_DETAILS_FAILED,
    });
  }
}

// api call to get current ping status of iot
function* devicePing(action: actionInterface): any {
  try {
    const data = yield call(iotManagementServices.devicePing, action.payload);
    if (data.status === 200 || data.status === 201) {
      if ((data?.data?.data?.length && data?.data?.data[0]?.errorDetails) && !action.payload.showToastr) {
        toastr.warning("", data?.data?.data[0]?.errorDetails);
      }
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_DEVICE_PING_SUCCESS,
        payload: {
          pingDeviceData: data?.data?.data,
        },
      });
    } else {
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_DEVICE_PING_FAILED,
      });
      if(!action.payload.showToastr){
        toastr.error("", handleMessage(data))
      }
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_DEVICE_PING_FAILED,
    });
    if(!action.payload.showToastr){
      toastr.error("", handleMessage(ex))
    }
  }
}

// api call to update iot details
function* updateIotDeatils(action: actionInterface): any {
  try {
    const data = yield call(
      iotManagementServices.updateIotDeviceData,
      action.payload
    );

    if (data?.status === 200 || data.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_UPADTE_IOT_DETAILS_SUCCESS,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_UPADTE_IOT_DETAILS_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_UPADTE_IOT_DETAILS_FAILED,
    });
  }
}

// api call to bulk uplaod iot devices
function* bulkUploadiotdevices(action: actionInterface): any {
  const data = yield call(
    iotManagementServices.bulkUploadIotDevices,
    action.payload
  );

  if (data?.status === 200) {
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_IOT_BULK_UPLOAD_SUCCESS,
      payload: {
        ...(data?.data?.data || []),
      },
    });
    toastr.success("", handleMessage(data));
  } else {
    if (!data.errors) {
      toastr.error("", handleMessage(data));
    }
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_IOT_BULK_UPLOAD_FAILED,
      payload: {
        ...(data?.error?.data || []),
      },
    });
  }
}

// api call to get devices bulk upload file format (excel format)
function* getVehicleUploadFormat(action: actionInterface): any {
  const data = yield call(iotManagementServices.getVehicleUploadFormat);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_IOT_UPLOAD_FORMAT_SUCCESS,
      payload: {
        excelData: data?.data,
      },
    });
    const url = URL.createObjectURL(new Blob([data.data]));

    data?.data && saveAs(url, fileFormats.IOT_BULK_UPLOAD_FORMAT);
    toastr.success(
      "",
      handleMessage(successMessage.sampleFormatDownloadSuccess)
    );
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_IOT_UPLOAD_FORMAT_FAILED,
    });
  }
}

function* iotOdometerReadingRequest(action: actionInterface): any {
  try {
    const data = yield call(
      iotManagementServices.getVehicleIotOdometerDetails,
      action.payload
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_IOT_ODOMETER_DETAILS_SUCCESS,
      });
      toastr.success("", handleMessage(data));
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
          .RENTALS_GET_IOT_ODOMETER_DETAILS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
        .RENTALS_GET_IOT_ODOMETER_DETAILS_FAILED,
    });
  }
}

function* rentalsIotManagementSaga() {
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ADD_IOT_DEVICE_REQUESTED,
    addIotDeviceRequest
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_REQUESTED,
    getIotDeviceList
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_ALL_IOT_VENDORS_REQUESTED,
    getAllVendors
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_DEVICE_DETAILS_REQUESTED,
    getIotDeviceDetails
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_REQUESTED,
    devicePing
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_UPADTE_IOT_DETAILS_REQUESTED,
    updateIotDeatils
  );

  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_IOT_BULK_UPLOAD_REQUESTED,
    bulkUploadiotdevices
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_UPLOAD_FORMAT_REQUESTED,
    getVehicleUploadFormat
  );
  yield takeLatest(
    actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_ODOMETER_DETAILS_REQUESTED,
    iotOdometerReadingRequest
  );
}

export default rentalsIotManagementSaga;
