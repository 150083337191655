import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import en from "../../../../../locale/en.json";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import { colors } from "../../../../../themes/colors";
import { checkNumeric } from "../../../../../utils/regex";
import { typographyConstants } from "../../../../constants/constants";
import { CityInputIcon, stateIcon } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  clearStatesAndDistrictFromPincode,
  getStatesAndDistrictFromPincode,
} from "../../../../redux/actions/rentalCommonAction";
import { formatString } from "../../../../utils/helper";

const OperatorAddress = ({
  openModal,
  modalAddress = {},
  handleModalClose,
  handleCustomerSaveAddress,
}: any): JSX.Element => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
  };
  const dispatch = useDispatch();
  const [address, setAddress] = useState<any>(modalAddress);
  const [errorFields, setErrorFields] = useState<any>({});
  const { dictrictBasedOnPincode = [], pincodeDistrictLoader } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // set the address from the operator detail into state
  useEffect(() => {
    if (openModal) setAddress(modalAddress);
    return () => {
      setAddress({});
    };
  }, [openModal]);

  // handle change in input fields
  useEffect(() => {
    dictrictBasedOnPincode.length &&
      setAddress((prev: any) => ({
        ...prev,
        state: dictrictBasedOnPincode[0]?.stateName,
        city: dictrictBasedOnPincode[0]?.district,
      }));
  }, [dictrictBasedOnPincode.length]);

  const handleChange = (type: string, value: any) => {
    setAddress((prevState: any) => ({
      ...prevState,
      [type]: value,
    }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      [type]: "",
    }));
  };

  // get verify text background color
  const getVerifyBackgroundColor = () => {
    if (address?.state && address?.city) return colors.secondary_green;
    else if (!address?.pinCode || address?.pinCode?.length < 6)
      return colors.text_gray;
    return colors.primary_warning;
  };

  // check if save button is disabled
  const isSaveDisabled = () => {
    const isAddressUpdated = isEqual(address, modalAddress);
    if (isAddressUpdated) return true;
    if (
      !address.addressLine1 ||
      !address.addressLine2 ||
      !address.pinCode ||
      !address.state ||
      !address.city
    )
      return true;
    return false;
  };

  // on pincode change
  const onPincodeChange = (e: any) => {
    checkNumeric(e.target.value) &&
      e.target.value.length <= 6 &&
      setAddress({
        ...address,
        state: "",
        city: "",
        pinCode: e.target.value,
      });
    dispatch(clearStatesAndDistrictFromPincode());
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <>
        <Box sx={{ ...style }}>
          <Box
            sx={{
              overflowY: "scroll",
              maxHeight: "68vh",
              scrollBehavior: "smooth",
              "::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            <Stack
              sx={{
                padding: "20px 20px 28px 23px",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={typographyConstants.HEADING}
                  padding={"16px 8px 0px 0px"}
                >
                  {en.NewManualBooking.AddAddress}
                </Typography>
                <IconButton onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Input
                  value={address?.addressLine1}
                  placeholder={rentalEn.operatorManagement.addressLine1}
                  errormessage={errorFields.addressLine1}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e: any) => {
                    handleChange("addressLine1", e.target.value);
                  }}
                  width="100%"
                />
                <Input
                  value={address?.addressLine2}
                  placeholder={rentalEn.operatorManagement.addressLine2}
                  errormessage={errorFields.addressLine2}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e: any) => {
                    handleChange("addressLine2", e.target.value);
                  }}
                  width="100%"
                />
                <Input
                  value={address?.pinCode}
                  placeholder={rentalEn.manualBooking.enterPincode}
                  errormessage={errorFields.pinCode}
                  onChange={onPincodeChange}
                  width="100%"
                  endAdornmentComponent={
                    <Typography
                      sx={{
                        minWidth: "65px",
                        color: colors.white,
                        fontWeight: "500",
                        alignSelf: "center",
                        textAlign: "center",
                        backgroundColor: getVerifyBackgroundColor(),
                        borderRadius: "30px",
                        padding: "6px 16px 6px 16px",
                        cursor:
                          (address?.state && address?.city) ||
                          !address?.pinCode ||
                          address?.pinCode?.length < 6
                            ? "default"
                            : "pointer",
                      }}
                      onClick={() => {
                        address?.pinCode?.length == 6 &&
                          !address?.state &&
                          !address?.city &&
                          dispatch(
                            getStatesAndDistrictFromPincode({
                              pincodes: [address?.pinCode],
                            })
                          );
                      }}
                    >
                      {pincodeDistrictLoader ? (
                        <CircularProgress color="inherit" size="15px" />
                      ) : address?.state && address?.city ? (
                        rentalEn.operatorManagement.verified
                      ) : (
                        rentalEn.operatorManagement.verify
                      )}
                    </Typography>
                  }
                />
                {(dictrictBasedOnPincode?.length > 0 ||
                  (address?.state && address?.city)) && (
                  <>
                    <Input
                      required
                      iconStart={stateIcon}
                      customInputProps={{ readOnly: true }}
                      placeholder={rentalEn.operatorManagement.selectState}
                      value={formatString(address?.state) ?? ""}
                    />
                    <Input
                      required
                      iconStart={CityInputIcon}
                      customInputProps={{ readOnly: true }}
                      value={formatString(address?.city) ?? ""}
                      placeholder={rentalEn.operatorManagement.selectCity}
                    />
                  </>
                )}
              </Box>

              <CustomButton
                label={en.NewManualBooking.SaveAndProceed}
                variant="outlined"
                sx={{ width: "100%" }}
                disabled={isSaveDisabled()}
                onClick={() => {
                  handleCustomerSaveAddress(address);
                  handleModalClose();
                  dispatch(clearStatesAndDistrictFromPincode());
                  setAddress({});
                }}
              />
            </Stack>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default OperatorAddress;
