import { Box, Chip, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import FaceIcon from "@mui/icons-material/Face";
// import { BootstrapTooltip } from "../../../../sharedComponents/atoms/Tooltips/customizedTooltips";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { KycVerificationModeDisplayName, KycVerificationModeName } from "../../../constants/constants";
import RbackHelper from "../../../../utils/helperRBAC";
import { CustomerFunctionalities, modules } from "../../../../constants/RBACModuleEnums";
interface KycTrustScoreChipsInterface {
  trustScore: any;
  chipIcon?: any;
  initiateClickHandler?: any;
  label: string;
  showChipLabel?: boolean;
  isEditable?: boolean;
  cursorStyle?: string;
}

const KycTrustScoreChips = ({
  trustScore = {},
  chipIcon = null,
  initiateClickHandler,
  label,
  showChipLabel = true,
  isEditable = true,
  cursorStyle = "pointer",
}: KycTrustScoreChipsInterface) => {
  const isKycDetailViewEnabled = RbackHelper.isAccessRightsProvided(
    modules.CUSTOMER_MANAGEMENT,
    CustomerFunctionalities.VIEW_KYC_DETAILS
  )
  return (
    <Stack width="25%" gap="5px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>{label}</Typography>
        {showChipLabel && (
          <>
            {trustScore?.isVerified ? (
              <BootstrapTooltip
                title={
                  KycVerificationModeName[
                    trustScore?.verificationMode as keyof typeof KycVerificationModeName
                  ]
                }
              >
                <CheckCircleIcon sx={{ fontSize: "1.2rem", color: "#1BC47D" }} />
              </BootstrapTooltip>
            ) : (
              <ErrorIcon sx={{ fontSize: "1.2rem", color: "#FF3B30" }} />
            )}
          </>
        )}
      </Stack>
      <Chip
        variant="outlined"
        icon={chipIcon}
        label={
          showChipLabel
            ? trustScore?.isPresent || !isEditable
              ? `${trustScore?.score}%`
              : "Initiate"
            : "View"
        }
        sx={{
          justifyContent: "flex-start",
          color: !trustScore?.isPresent ? "#0A555C" : "darkgray",
          "& .MuiChip-icon": {
            marginLeft: "2px !important",
            height: "90%",
          },
          cursor: isKycDetailViewEnabled ? cursorStyle : "not-allowed !important",
        }}
        onClick={isKycDetailViewEnabled ? initiateClickHandler: null}
        disabled={trustScore?.isPresent === 0 || !isKycDetailViewEnabled}
      />
    </Stack>
  );
};

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default KycTrustScoreChips;
