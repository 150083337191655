import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BasicSelect } from "../../../components/dropDown";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";

import {
  faAngleRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@mui/material/Modal";
import { Button1 } from "../../../components/button";
import { Input3 } from "../../../components/input";
import { colors } from "../../../themes/colors";
import { getDeliveryAddress, validate } from "../../../utils/helper";
import { checkNumeric } from "../../../utils/regex";
import RowGrid from "./component/RowGrid";
import GoogleImageIcon from "./data/image/googleMap.png";
import "./data/style.css";
import { getData } from "../../../config/filterStorage";
import { LocalStorage } from "../../../constants/enum";
import {
  BookingStatusEnum,
  tyesOfModeOfDelivery,
} from "../../constants/constants";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";

const TabAssignment = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const { taskUserListById, taskData, taskSequence, tasksummaryById, loading } =
    useSelector((state: RootState) => state.taskReducer);
  const { locale } = useSelector((state: RootState) => state.localeReducer);

  const { global } = locale;

  const [timeCounter, setTimeCounter] = useState<number>(10);
  const [agentUpdatedData, setAgentupdatedData] = useState([]);

  const [tabName, setTabName] = useState<any>();
  const initialState = {
    homeAddress: "",

    remarkData: "",
    // category: {
    //   id: 0,
    //   name: "Select Category",
    // },
    primaryAgent: {
      id: 0,
      name: "Primary Agent",
    },
    secondaryAgent: {
      id: 0,
      name: "Secondary Agent",
    },
  };
  const [fields, setFields] = useState<any>({ ...initialState });

  const [errorFields, setErrorFields] = useState<any>({
    // category: "",
    primaryAgent: "",
    secondaryAgent: "",
  });

  useEffect(() => {
    setFields({ ...initialState });
  }, []);

  useEffect(() => {
    const modifiedAgentData: any =
      taskUserListById &&
      taskUserListById?.map((obj: any) => {
        const { _id, ...rest } = obj;
        return { id: _id, ...rest };
      });
    setAgentupdatedData(modifiedAgentData);
  }, [taskUserListById, params?.id]);

  useEffect(() => {
    // get data from localstorage the dispatch below
    getData(LocalStorage.Task_ManagementData).then((data: any) => {
      const taskData = JSON.parse(data ?? {});

      if (taskData) {
        let obj: any = taskData?.branch;
        dispatch(
          actions.getTaskSequenceList({
            warehouseId: obj?.name,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (props?.bookingId) {
      dispatch(actions.getTasKSummary({ bookingId: props?.bookingId }));
    }
  }, [props?.bookingId, params?.id]);

  // useEffect(()=>{

  //   if(tasksummaryById && tasksummaryById.length>0 && agentUpdatedData.length>0){

  //     const length = tasksummaryById.length
  //     const primaryAgentObject =  agentUpdatedData?.find((item:any) => item.id === tasksummaryById[length-1]?.primaryUser?._id
  //     );

  //     const secondaryAgentObject =  agentUpdatedData?.find((item:any) => item.id === tasksummaryById[length-1]?.secondaryUser?._id
  //     );
  //    if(primaryAgentObject && Object.keys(primaryAgentObject) && secondaryAgentObject && Object.keys(secondaryAgentObject) ){
  //     setFields((prev: any) => ({
  //       ...prev,
  //       primaryAgent : primaryAgentObject,
  //       secondaryAgent : secondaryAgentObject,
  //       remarkData : tasksummaryById[length-1]?.remark
  //     }));
  //    }
  //   }

  // },[tasksummaryById,params?.id , agentUpdatedData])

  useEffect(() => {
    if (
      tasksummaryById &&
      tasksummaryById?.length > 0 &&
      agentUpdatedData &&
      agentUpdatedData?.length > 0
    ) {
      const length = tasksummaryById?.length;
      const primaryUserId = tasksummaryById[length - 1]?.primaryUser?._id;
      const secondaryUserId = tasksummaryById[length - 1]?.secondaryUser?._id;

      const primaryAgentObject = agentUpdatedData?.find(
        (item: any) => item.id === primaryUserId
      );
      const secondaryAgentObject = agentUpdatedData?.find(
        (item: any) => item.id === secondaryUserId
      );

      if (primaryAgentObject && secondaryAgentObject) {
        setFields((prev: any) => ({
          ...prev,
          primaryAgent: primaryAgentObject,
          secondaryAgent: secondaryAgentObject,
          remarkData: tasksummaryById[length - 1]?.remark,
        }));
      }
    }
  }, [tasksummaryById, params?.id, agentUpdatedData]);

  useEffect(() => {
    // if (props?.bookingData?.deliveryDetails?.type == tyesOfModeOfDelivery.CUSTOMER_LOCATION ) {
    if (
      props?.bookingData?.deliveryDetails?.type ==
      tyesOfModeOfDelivery.CUSTOMER_LOCATION
    ) {
      setFields((prevState: any) => ({
        ...prevState,
        homeAddress: "Home Delivery",
      }));
    } else {
      setFields((prevState: any) => ({ ...prevState, homeAddress: "Pick Up" }));
    }
  }, [props?.bookingData?.deliveryDetails?.type]);

  const inputAddressObj =
    props?.bookingData?.status === BookingStatusEnum.UPCOMING
      ? props?.bookingData?.deliveryDetails?.address
      : props?.bookingData?.returnDetails?.address;
  const displayFormattedAddress = getDeliveryAddress(inputAddressObj);

  const validateTaskAssignment = () => {
    const validationFields = {
      // category: fields?.category?.id,
      primaryAgent: fields?.primaryAgent?.id,
      secondaryAgent: fields?.secondaryAgent?.id,
    };

    let validation = validate(validationFields, errorFields);
    if (validation.error) {
      setErrorFields({ ...errorFields, ...validation.errorFields });
    } else submitTask();
  };

  const submitTask = () => {
    const paylaod: any = {
      // taskType: fields?.category?.name,
      // bookingId: Number(props?.bookingId),
      bookingId: props?.bookingId,
      warehouseId: props?.bookingData?.branchDetails?.branchName,
      primaryExecutiveId: fields?.primaryAgent?.id,
      secondaryExecutiveId: fields?.secondaryAgent?.id
        ? fields?.secondaryAgent?.id
        : null,
      vehicleModelName: props?.bookingData?.modelDetails?.modelName,
      deliveryAddress:
        // "Default Address",
        fields?.homeAddress == "Home Delivery"
          ? props?.bookingData?.deliveryDetails?.address?.address +
            props?.bookingData?.deliveryDetails?.address?.landmark +
            /*  props?.bookingData?.deliveryDetails?.address?.cities_delivery_charge?.city_name +
            props?.bookingData?.pick_up_location?.address?.state?.state_name + */
            props?.bookingData?.deliveryDetails?.address?.locationData
              ?.formattedAddress +
            props?.bookingData?.deliveryDetails?.address?.pinCode
          : props?.bookingData?.deliveryDetails?.address?.locationData
              ?.formattedAddress +
            /* props?.bookingData?.pick_up_location?.address?.address2 +
            props?.bookingData?.pick_up_location?.address?.city?.city_name +
            props?.bookingData?.pick_up_location?.address?.state?.state_name + */
            props?.bookingData?.deliveryDetails?.address?.pinCode,
      // Above - check keys for pickup booking
      remark: fields?.remarkData ? fields?.remarkData : null,
    };
    if (fields?.homeAddress == "Home Delivery") {
      paylaod.sla = timeCounter;
    }
    dispatch(actions.postTaskAsignment(paylaod));
  };

  useEffect(() => {
    if (props?.bookingData?.branchDetails?.branchName)
      dispatch(
        actions.getTaskUserList({
          branchId: props?.bookingData?.branchDetails?.branchName,
        })
      );
  }, [props?.bookingData?.branchDetails?.branchName, taskData]);

  const taskType = [
    {
      id: 1,
      name: "Start Ride",
    },
    {
      id: 2,
      name: "Complete Ride",
    },
    {
      id: 3,
      name: "Service",
    },
    {
      id: 4,
      name: "Others",
    },
  ];

  const handleChange = (field: string, value: any) => {
    setFields((prevState: any) => ({ ...prevState, [field]: value }));
    setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
  };

  // const handlePrimaryAgent = (selectcategory: string) => {

  //   setPrimaryAgent(selectcategory);
  //   dispatch(
  //     actions.getTaskUserList({ branchId: props?.bookingData?.branch_id })
  //   );
  // };

  const handleCounter = (type: string) => {
    if (type === "decrement") {
      if (timeCounter > 1) {
        setTimeCounter((prev): any => prev - 1);
        setErrorFields((prevState: any) => ({ ...prevState, sla: "" }));
      }
    }
    if (type === "increment") {
      if (timeCounter < 600) {
        setTimeCounter((prev): any => prev + 1);
        setErrorFields((prevState: any) => ({ ...prevState, sla: "" }));
      }
    }
  };

  const handleClose = () => {};
  const openGoogleMaps = () => {
    // Construct the Google Maps URL with the destination coordinates
    const userLat =
      /* fields?.homeAddress == "Home Delivery"
        ? props?.bookingData?.homeDelPick?.latitude
        : props?.bookingData?.pick_up_location?.latitude; */
      fields?.homeAddress == "Home Delivery"
        ? props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lat
        : props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lat;
    const userLng =
      /*  fields?.homeAddress == "Home Delivery"
        ? props?.bookingData?.homeDelPick?.longitude
        : props?.bookingData?.pick_up_location?.longitude; */
      fields?.homeAddress == "Home Delivery"
        ? props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lng
        : props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lng;
    const mapsUrl = `https://www.google.com/maps/dir//${userLat},${userLng}`;

    // Open the URL in a new tab
    window.open(mapsUrl, "_blank");
  };

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        marginTop: "28px",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: "100%",
          padding: "25px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          paddingTop: "3%",
          paddingBottom: "2%",
        }}
        variant="outlined"
      >
        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Booking Number
              </Typography>
            </div>
          }
          colTwo={
            <div style={{ marginLeft: "4px", marginTop: "5px" }}>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-SemiBold"
                fontSize={14}
                color={colors.heading_black}
                variant="body2"
                style={{ marginBottom: "10px" }}
              >
                {props?.bookingData?.bookingNumber}
              </Typography>
            </div>
          }
        />
        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Delivery type
              </Typography>
            </div>
          }
          colTwo={
            <div style={{ marginLeft: "3px", marginTop: "5px" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Cash"
                name="radio-buttons-group"
                value={fields?.homeAddress}
                style={{
                  fontSize: "10px",
                }}
                row
                onChange={(e: any) => {
                  setFields({
                    ...fields,
                    homeAddress: e.target.value,
                  });
                }}
              >
                {props?.bookingData?.deliveryDetails?.type ==
                  tyesOfModeOfDelivery.CUSTOMER_LOCATION &&
                fields?.homeAddress == "Home Delivery" ? (
                  <FormControlLabel
                    value={"Home Delivery"}
                    control={<Radio />}
                    label="Home Delivery"
                  />
                ) : (
                  <FormControlLabel
                    value={"Pick Up"}
                    control={<Radio />}
                    label="Pick Up"
                  />
                )}
              </RadioGroup>
            </div>
          }
        />

        {props?.bookingData?.deliveryDetails?.type ==
        tyesOfModeOfDelivery.CUSTOMER_LOCATION ? (
          <RowGrid
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Home address
                </Typography>
              </div>
            }
            colTwo={
              <div>
                {props?.bookingData?.deliveryDetails?.type ==
                  tyesOfModeOfDelivery.CUSTOMER_LOCATION &&
                fields?.homeAddress == "Home Delivery" ? (
                  <Grid
                    // container
                    // direction="row"
                    // justifyContent="space-between"
                    direction={isDesktopOrTablet ? "row" : "column"}
                    style={{ display: "flex", gap: "20px" }}
                  >
                    <Grid>
                      <div>
                        <img
                          src={GoogleImageIcon}
                          alt="Google Maps"
                          style={{
                            width: 90,
                            height: 75,
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid>
                      <div
                        style={{
                          marginLeft: 18,
                          marginTop: 2,
                          width: 200,
                          height: "auto",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          marginLeft={"0"}
                          fontFamily="EuclidCircularB-Regular"
                          fontSize={12}
                          color={colors.dark_gray}
                          variant="body2"
                          marginBottom={0.5}
                        >
                          {displayFormattedAddress ?? "N/A"}
                        </Typography>
                        <Typography
                          marginLeft={"0"}
                          fontFamily="EuclidCircularB-Regular"
                          fontSize={10}
                          color={colors.primary}
                          variant="body2"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          //         onClick={() => navigate("/map", {
                          //         state :{
                          //           lat: 28.4926675, lng: 77.0819832
                          //       }
                          // })}
                          // onClick={ ()=> window.open("/map")
                          //   }
                          onClick={openGoogleMaps}
                        >
                          View in Maps{" "}
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            color={colors.primary}
                            style={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : props?.bookingData?.deliveryDetails?.type ==
                    tyesOfModeOfDelivery.BRANCH &&
                  fields?.homeAddress == "Pick Up" ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12}>
                      <div>
                        <img
                          src={GoogleImageIcon}
                          alt="Google Maps"
                          style={{
                            width: 90,
                            height: 75,
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginLeft: 25,
                          marginTop: 2,
                          width: 200,
                          height: "auto",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          marginLeft={"0"}
                          fontFamily="EuclidCircularB-Regular"
                          fontSize={12}
                          color={colors.dark_gray}
                          variant="body2"
                          marginBottom={0.5}
                        >
                          {displayFormattedAddress ?? "N/A"}
                        </Typography>
                        <Typography
                          marginLeft={"0"}
                          fontFamily="EuclidCircularB-Regular"
                          fontSize={10}
                          color={colors.primary}
                          variant="body2"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={openGoogleMaps}
                        >
                          View in Maps
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            color={colors.primary}
                            style={{ cursor: "pointer" }}
                          />
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            }
          />
        ) : null}

        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Vehicle model
              </Typography>
            </div>
          }
          colTwo={
            <div>
              <p>{props?.bookingData?.modelDetails?.modelDisplayName}</p>
            </div>
          }
        />

        {props?.bookingData?.deliveryDetails?.type ==
        tyesOfModeOfDelivery.CUSTOMER_LOCATION ? (
          <RowGrid
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  SLA
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 5,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        color={colors.heading}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCounter("decrement")}
                      />
                      <Input3
                        value={timeCounter}
                        placeholder={"10"}
                        disabled={false}
                        width={80}
                        errormessage={errorFields.sla}
                        required={true}
                        style={{
                          fontSize: "14px",
                          marginLeft: 5,
                          marginRight: 5,
                          fontFamily: "EuclidCircularB-Medium",
                        }}
                        onChange={(e: any) => {
                          checkNumeric(e.target.value) &&
                            e.target.value <= 600 &&
                            setTimeCounter(Number(e.target.value));
                          setErrorFields((prevState: any) => ({
                            ...prevState,
                            sla: "",
                          }));
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={colors.heading}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCounter("increment")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          />
        ) : null}

        {/* <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Category
              </Typography>
            </div>
          }
          colTwo={
            <div>
              <BasicSelect
                selected2={fields?.category["name"]}
                defaultKey={"name"}
                handleChange={(category: any) => {
                  handleChange("category", category);
                }}
                placeholderMargin="0 0 0 3%"
                placeholder="Category"
                variant="filled"
                style={{ marginLeft: "3px" }}
                width={230}
                heading={"Select Category"}
                data={taskType || []}
              />
              <p>
                {" "}
                {Boolean(errorFields?.category) ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      display: "flex",
                    }}
                  >
                    {errorFields?.category}
                  </div>
                ) : null}{" "}
              </p>
            </div>
          }
        /> */}

        {/* Primary Agent */}
        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Primary Agent
              </Typography>
            </div>
          }
          colTwo={
            <div>
              <BasicSelect
                selected2={fields?.primaryAgent["name"]}
                defaultKey={"name"}
                handleChange={(e: any) => {
                  handleChange("primaryAgent", e);
                }}
                placeholderMargin="0 0 0 3%"
                placeholder="Primary Agent"
                variant="filled"
                style={{ marginLeft: "3px" }}
                width={230}
                heading={"Primary Agent"}
                data={agentUpdatedData || []}
              />
              <p>
                {" "}
                {Boolean(errorFields?.primaryAgent) ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      display: "flex",
                    }}
                  >
                    {errorFields?.primaryAgent}
                  </div>
                ) : null}{" "}
              </p>
            </div>
          }
        />

        {/* Primary Agent */}
        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Secondary Agent
              </Typography>
            </div>
          }
          colTwo={
            <div>
              <BasicSelect
                selected2={fields?.secondaryAgent["name"]}
                defaultKey={"name"}
                handleChange={(e: any) => {
                  handleChange("secondaryAgent", e);
                }}
                placeholderMargin="0 0 0 3%"
                placeholder="Secondary Agent"
                style={{ marginLeft: "3px", marginTop: "5px" }}
                variant="filled"
                width={230}
                heading={"Secondary Agent"}
                data={agentUpdatedData || []}
              />
              <p>
                {" "}
                {Boolean(errorFields?.secondaryAgent) ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      display: "flex",
                    }}
                  >
                    {errorFields?.secondaryAgent}
                  </div>
                ) : null}{" "}
              </p>
            </div>
          }
        />

        {/* Remarks */}
        <RowGrid
          colOne={
            <div>
              <Typography
                marginLeft={"0"}
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Remarks
              </Typography>
            </div>
          }
          colTwo={
            <div>
              <Input3
                onChange={(e: any) => {
                  handleChange("remarkData", e.target.value);
                }}
                style={{ marginLeft: "3px", marginTop: "5px" }}
                value={fields?.remarkData}
                placeholder={"Remarks"}
                width={280}
                required={true}
              />
            </div>
          }
        />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 8 }}>
          <div style={{ width: "40%" }}>
            <CustomButton
              label={global.Submit}
              variant={"contained"}
              onClick={validateTaskAssignment}
              disabled={loading} 
              loading={loading}
              sx={{ width: "-webkit-fill-available" }}
            />
            {/*   <Button1
              // className=""
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
              borderRadius={"20px"}
              title={global.Submit}
              // onClick={() => submitTask()}
              onClick={validateTaskAssignment}
              disabled={false}
            /> */}
          </div>
        </Box>
      </Card>

      {/* Model - Task list priority */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isDesktopOrTablet ? "40%" : "90%",
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              borderRadius: 10,
            }}
            style={{
              paddingTop: "3%",
              paddingBottom: "3%",
              paddingLeft: "8%",
              paddingRight: "8%",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h4"
              style={{
                fontFamily: "EuclidCircularB-Medium",
                fontSize: "16px",
              }}
              color={colors.heading_main_text}
              key="2"
            >
              Task List{" "}
              <span style={{ color: "#C6C6C6" }}> (as per priority)</span>
            </Typography>
            <Typography
              style={{
                fontFamily: "EuclidCircularB-Regular",
                fontSize: "14px",
              }}
              color={colors.heading_main_text}
              sx={{
                marginBottom: 2,
              }}
            >
              {fields?.primaryAgent}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <TableContainer component={Paper} className="tableBoxShadowRm">
                <Table
                  size="small"
                  aria-label="simple table"
                  className="tableBoxShadowRm"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableBorderRm">
                        <Typography className="tableHeadingText" />
                      </TableCell>
                      <TableCell className="tableBorderRm">
                        <Typography className="tableHeadingText">
                          Task name
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className="tableBorderRm">
                        <Typography className="tableHeadingText">
                          SLA (in mins)
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskSequence?.startList?.map(
                      (item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            className="tableBorderRm"
                            component="th"
                          >
                            <Typography className="tableSerialNumber">
                              {item.id}
                            </Typography>
                          </TableCell>
                          <TableCell className="tableBorderRm" component="th">
                            <Typography className="tableContentText">
                              {item.taskName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="tableBorderRm"
                            component="th"
                          >
                            <Typography className="tableContentText">
                              {item.sla}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography className="totalSlaMins">
                Total SLA (mins) -{" "}
                {taskSequence?.startList?.reduce(
                  (acc: any, curr: any) => acc + curr.sla,
                  0
                )}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button1
                  style={{
                    marginBottom: 10,
                    marginTop: 10,
                    width: "40%",
                  }}
                  title={"Done"}
                  onClick={() => setOpen((value) => !value)}
                  disabled={false}
                />
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    </Grid>
  );
};

export default TabAssignment;
