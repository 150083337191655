import {
  API_URLS,
  BASE_URL,
  FREEDO_BIZ_X_URL,
  FREEDO_X_URL,
  L_O_B,
} from "../../config";
import {
  FreedoX,
  Global,
  YORMasterEndpoints,
} from "../constantsX/apiConstantsX";
import { customAxios } from "../../config/axiosConfig";

const getAllCitiesX = (payload: any) => {
  try {
    let cityUrl = FREEDO_BIZ_X_URL + API_URLS.getAllCitiesX;
    if (payload?.page) cityUrl += `page=${payload?.page}`;
    if (payload?.size && !isNaN(payload?.size))
      cityUrl += `&size=${payload?.size}`;
    return fetchAllCities(cityUrl);
  } catch (ex) {
    return ex;
  }
};

const fetchAllCities = (route: any) => customAxios.get(route).catch((ex) => ex);

//get All branches on the  basis of City
const getBranchX = (payload: any) => {
  try {
    let branchUrl = "";
    if (payload.name)
      branchUrl =
        FREEDO_BIZ_X_URL + API_URLS.getBranchX + `?city=${payload.name}`;
    else branchUrl = FREEDO_BIZ_X_URL + API_URLS.getBranchX;
    return customAxios.get(branchUrl, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getModelX = (payload: any) => {
  try {
    let ModalUrl =
      FREEDO_X_URL + API_URLS.getModel + `?branchName=${payload.name}`;
    return customAxios.get(ModalUrl, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getAllModels = (payload: any) => {
  try {
    let url = BASE_URL + API_URLS.getAllModelsX;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

const getAllWarehouse = () => {
  try {
    let url = FREEDO_BIZ_X_URL + API_URLS.getWarehouseX;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

const getChargesCategoriesYOR = () => {
  try {
    let url =
      FREEDO_X_URL +
      Global.master +
      Global.versioningV1 +
      YORMasterEndpoints.YORChargeCategories;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

export default {
  getModelX,
  getAllCitiesX,
  getBranchX,
  getAllModels,
  getAllWarehouse,
  getChargesCategoriesYOR,
};
