import { OPERATOR_MANAGEMENT_ACTIONS } from "../../actions/actionTypes";
import { CommonInterface } from "../actionInterfaces";

const initialState: any = {
  operatorLoader: false,
  operatorList: { currentPage: 0, data: [], totalItems: 0, totalPages: 0 },
  OperatorActionClickData: null,
  OperatorActionClickError: null,
  documentTypeList: [],
  businessTypeList: [],
  loading1: false,
  editOperator: false,
  getOperatorDetails: {},
  isStatusUpdated: false,
  saveOperator: false,
  currentStep: 0,
  refresh: false,
};

const feedbackReducer = (state = initialState, action: CommonInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_REQUESTED:
      return {
        ...prevState,
        operatorLoader: true,
        editOperator: false,
        isStatusUpdated: false,
      };

    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_SUCCESS:
      return {
        ...prevState,
        operatorLoader: false,
        ...payload,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_FAILED:
      return {
        ...prevState,
        operatorLoader: false,
      };

    case OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_ACTION_CLICK_REQUESTED:
      return {
        ...state,
        OperatorActionClickError: null,
        loading: true,
        isStatusUpdated: false,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_ACTION_CLICK_SUCCESS:
      return {
        ...state,
        OperatorActionClickError: null,
        OperatorActionClickData: payload?.OperatorStatusData,
        loading: false,
        isStatusUpdated: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_ACTION_CLICK_FAILED:
      return {
        ...state,
        OperatorActionClickData: {},
        // DashboardActionClickError: payload.error,
        loading: false,
        isStatusUpdated: false,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_ALL_DOCUMENT_TYPES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_ALL_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        documentTypeList: payload?.getAllDocumentTypeList ?? [],
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_ALL_DOCUMENT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        documentTypeList: [],
      };

    case OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_SUCCCESS:
      return {
        ...state,
        loading: false,
        businessTypeList: payload?.businessTypeList ?? [],
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_FAILED:
      return {
        ...state,
        loading: false,
        businessTypeList: [],
      };

    case OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_REQUESTED:
      return {
        ...state,
        loading1: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_SUCCESS:
      return {
        ...state,
        loading1: false,
        editOperator: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_FAILED:
      return {
        ...state,
        loading1: false,
      };

    case OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_REQUESTED:
      return {
        ...state,
        loading1: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_SUCCESS:
      return {
        ...state,
        loading1: false,
        saveOperator: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_FAILED:
      return {
        ...state,
        loading1: false,
      };

    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getOperatorDetails: payload?.Result ?? {},
        currentStep: payload?.Result?.linkedStatus - 1
      };
    case OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        getOperatorDetails: {},
      };
    case OPERATOR_MANAGEMENT_ACTIONS.PRODUCT_CONFIGURATION_OPERATOR_REQUESTED:
    case OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_REQUESTED:
    case OPERATOR_MANAGEMENT_ACTIONS.LINKED_ACCOUNT_OPERATOR_REQUESTED:
      return {
        ...state,
        updateOperatorLoader: true,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.LINKED_ACCOUNT_OPERATOR_SUCCESS:
      return {
        ...state,
        updateOperatorLoader: false,
        currentStep: 1,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_SUCCESS:
      return {
        ...state,
        updateOperatorLoader: false,
        currentStep: 2,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.PRODUCT_CONFIGURATION_OPERATOR_SUCCESS:
      return {
        ...state,
        updateOperatorLoader: false,
        currentStep: 3,
        productConfigure: payload?.productConfigure
      };

    case OPERATOR_MANAGEMENT_ACTIONS.PRODUCT_CONFIGURATION_OPERATOR_FAILED:
    case OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_FAILED:
    case OPERATOR_MANAGEMENT_ACTIONS.LINKED_ACCOUNT_OPERATOR_FAILED:
      return {
        ...state,
        updateOperatorLoader: false,
      };
    case OPERATOR_MANAGEMENT_ACTIONS.DELETE_OPERATOR_SUCCESS:
      return {
        ...state,
        refresh: !state?.refresh
      }
    default:
      return { ...prevState };
  }
};

//return feedback table data

export default feedbackReducer;
