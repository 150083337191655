// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { UserStatus, VehicleStatus } from "../constants/bookingContants";
import en from "../locale/en.json";
// import { AccessTypes } from "../interfaces/commonInterface";
import { UserTypes } from "../constants/enum";
// import { checkPin } from "./regex";
import { AES } from "crypto-js";
import * as forge from "node-forge";
import { PasswordComplexity } from "../constants/enum";
import { COUPON_CODE_WORKING_KEY } from "../constants/others";

export const throttle = (fun: any, threshold: number = 2000) => {
  let execute = true,
    timeout: any = null;
  return (...args: any) => {
    if (execute) {
      fun(...args);
      execute = false;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        execute = true;
        timeout = null;
      }, threshold);
    }
  };
};

export const deepCopy = (data: any) => {
  if (data) {
    return JSON.parse(JSON.stringify(data));
  } else return data;
};

export const debounce = (func: any, threshold: number = 500) => {
  let debounceHandler: any = null;
  return (...args: any) => {
    if (debounceHandler) clearTimeout(debounceHandler);
    debounceHandler = setTimeout(() => func(...args), threshold);
  };
};

export const callFunctionWithDelay = (func: any, delay: number = 500) => {
  let timeout = null;
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    timeout = null;
    func();
  }, delay);
};

export const textValidate2 = (text: any) => {
  text = text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  return text;
};

export const mobileNumberValidate = (text: any) => {
  text = text.replace(/[a-zA-Z`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  return text;
};

export const textValidate = (text: any) => {
  text = text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  return text?.length > 50
    ? text.substring(0, 50).trimStart()
    : text.trimStart();
  // return text;
};

export const lengthValidate = (text: any, length: any) => {
  return text?.length > length
    ? text.substring(0, length).trimStart()
    : text.trimStart();
  // return text;
};

export const lattitudeValidate = (text: any) => {
  text = Number(text);
  let reg = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");
  if (reg.exec(text)) {
    return true;
  }
  return false;
};

export const longitudeValidate = (text: any) => {
  text = Number(text);
  let reg = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");
  if (reg.exec(text)) {
    return true;
  }
  return false;
};

export const zipValidate = (text: any) => {
  text = Number(text);
  let reg = new RegExp("^[1-9]{1}[0-9]{2}s{0,1}[0-9]{3}$");

  if (reg.exec(text)) {
    return true;
  }
  return false;
};

// return true if value is Array
export const isArrayNotEmpty = (array) =>
  array !== null && Array.isArray(array) && array.length > 0;

// Returns true is value is non-empty object
export const isObjectNotEmpty = (value: any): boolean => {
  return (
    value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value)?.length > 0
  );
};

export const isNotNullOrEmptyString = (word) =>
  word !== null && word !== undefined && word.length > 0;

export const isNotNullAndUndefined = (value) => {
  return value !== undefined && value !== null;
};

export const handleMessage = (response) => {
  if (response === "") {
    return en.errorMessages.SomethingWentWrong;
  } else if (typeof response === "string") {
    return response;
  } else if (isArrayNotEmpty(response?.Message)) {
    return response?.Message[0];
  } else if (response?.Message) {
    return response?.Message;
  } else if (isArrayNotEmpty(response?.data?.Message)) {
    return response?.data?.Message[0];
  } else if (response?.data?.Message) {
    return response?.data?.Message;
  } else if (response?.data?.message) {
    return response?.data?.message;
  } else if (response?.data?.result?.Message) {
    return response?.data?.result?.Message;
  } else if (response?.response?.data?.Message) {
    return response?.response?.data?.Message;
  } else if (response?.result?.Message) {
    return response?.result?.Message;
  } else if (response?.response?.data?.message) {
    return response?.response?.data?.message;
  } else if (response?.data?.meta?.message) {
    return response?.data?.meta?.message;
  } else if (response?.error?.errorMessage) {
    return response?.error?.errorMessage;
  } else if (response?.data?.error?.errorMessage) {
    return response?.data?.error?.errorMessage;
  } else if (response?.errors && isArrayNotEmpty(response?.errors)) {
    // concat error message if array of errors is there
    let errorMessage = response?.errors.reduce((acc, value) => {
      return acc + value.message + "\n";
    }, "");
    return errorMessage;
  } else {
    return en.errorMessages.SomethingWentWrong;
  }
};

export const BookingStatus = (status: number) => {
  return status === 1
    ? VehicleStatus.Pending
    : status === 2
    ? VehicleStatus.Upcoming
    : status === 3
    ? VehicleStatus.Ongoing
    : status === 4
    ? VehicleStatus.Ended
    : status === 5
    ? VehicleStatus.Completed
    : VehicleStatus.Cancelled;
};
export const UserStatusFunc = (status: number) => {
  return status === 1
    ? UserStatus.Active
    : status === 2
    ? UserStatus.InActive
    : status === 3
    ? UserStatus.Deleted
    : status === 4
    ? UserStatus.Blocked
    : UserStatus.All;
};
export const exportXl = async (Data: any, fileName: any) => {
  const ws = XLSX.utils.json_to_sheet(Data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = await new Blob([excelBuffer]);

  saveAs(data, fileName + ".xlsx");
};

export const checkAccess = (
  accessKey: string,
  accessType: string,
  returnData?: any
) => {
  return true;
  // const {
  //   newAuthReducer: { userData },
  // } = store.getState();

  // if (userData?.userType === UserTypes.MASTER) {
  //   return returnData;
  // }
  // if (!accessKey) {
  //   return returnData;
  // }
  // const access = userData?.accessRight?.[accessKey];

  // if (!access) return null;
  // return access[accessType] ? returnData : null;
};

export const checkActionAccess = (
  accessKey: string,
  data: Array<any> | string
) => {
  const {
    newAuthReducer: { userData },
  } = store.getState();
  if (userData?.userType === UserTypes.MASTER) {
    return data;
  }
  const access = userData?.accessRight?.[accessKey];
  if (!access) return [];
  const finalData = data?.map((each) => {
    if (access[each?.toLowerCase()] === false) {
      return null;
    }
    return each;
  });
  return finalData;
};

export const checkSubsidiary = (master: string, slave: string) => {
  let output: boolean = false;
  if (master === UserTypes.MASTER && slave === UserTypes.COMPANY) {
    output = true;
  }

  if (master === UserTypes.COMPANY && slave === UserTypes.SUB_COMPANY) {
    output = true;
  }
  if (master === UserTypes.SUB_COMPANY && slave === UserTypes.EMPLOYEE) {
    output = true;
  }
  return output;
};

export const validate = (fields: object = {}, errorFields: object = {}) => {
  const validateFields = Object.keys(fields);
  let obj = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined) return;

    if (
      typeof fields[each] === "string" ||
      typeof fields[each] === "number" ||
      typeof fields[each] === "date"
      // typeof fields[each] === "object"
    ) {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;

              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;

              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("imei"):
            if (fields[each]?.length < 15) {
              error = true;

              obj[each] = en.errorMessages.imeiError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("secoundary") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;

          default:
            break;
        }
      }
    }

    // else if( fields["inputFields"].category_name==""){
    //   error = true;
    //     obj[each] = en.errorMessages.requiredField;

    // }
    else if (typeof fields[each] === "object") {
      // @ts-ignore: Unreachable code error
      if (!fields[each]?.id) {
        error = true;
        // @ts-ignore: Unreachable code error
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

interface valiadate {
  required: string;
  validateRegEx: string;
  minLength: string;
}

export const validateStatus: valiadate = {
  required: "The field is required",
  validateRegEx: "Invalid Email Address",
  minLength: "minLength",
};

export const requireEmail = (username: string) => {
  username = username?.toString()?.trim();
  if (username == "" || username == undefined || username == null)
    return { status: false, error: validateStatus.required };
  else return { status: true, error: "" };
};

export const requirePassword = (password: string) => {
  password = password?.toString()?.trim();
  if (password == "" || password == undefined || password == null)
    return { status: false, error: validateStatus.required };
  else return { status: true, error: "" };
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: validateStatus.required };
  else if (!emailRegex.test(email))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const validatePassword = (password: string) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  password = password.trim();
  if (password == "" || password == undefined || password == null)
    return { status: false, error: validateStatus.required };
  else if (!passwordRegex.test(password))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const matchOldPassword = (oldPassword: string, newPassword: string) => {
  if (oldPassword === newPassword) {
    return { status: false, error: validateStatus.validateRegEx };
  } else return { status: true, error: "" };
};

export const matchSamePassword = (oldPassword: string, newPassword: string) => {
  if (oldPassword !== newPassword) {
    return { status: false, error: validateStatus.validateRegEx };
  } else return { status: true, error: "" };
};

export const validateName = (name: any) => {
  const nameRegex = /^[a-zA-Z ]{1,30}$/;
  name = name.trim() ? name.trim() : null;
  if (name == "" || name == undefined || name == null)
    return { status: false, error: validateStatus.required };
  else if (!nameRegex.test(name))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const validateMobileNo = (mobileNo: string) => {
  // const numRegExWithCode = /^\+(?:[0-9] ?){10,12}[0-9]$/
  const numRegExCode = /^[1-9][0-9]{7,12}$/;
  mobileNo = mobileNo.trim();
  if (mobileNo == "" || mobileNo == undefined || mobileNo == null)
    return { status: false, error: validateStatus.required };
  else if (!numRegExCode.test(mobileNo))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const isObjectEmpty = (objectName = {}) => {
  return Object.keys(objectName).length === 0;
};

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const decrypt = (encrypted) => {
  const CryptoJS = require("crypto-js");
  const decryptFunction = function (encText, workingKey) {
    let decrypted = CryptoJS.AES.decrypt(encText, workingKey);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  };
  return decryptFunction(encrypted, COUPON_CODE_WORKING_KEY);
};

export const addComma = (data: string) => {
  if (data) {
    return data + ", ";
  } else {
    return "";
  }
};

export const encrypt = (data: any): any => {
  let string = JSON.stringify(data);
  let encrypted = AES.encrypt(string, COUPON_CODE_WORKING_KEY).toString();
  return encrypted;
};

interface IObjectKeys {
  [key: string]: boolean;
}

export interface IpolicyChecks extends IObjectKeys {
  hasMinLength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  hasRepeatedSequence: boolean;
}

export const validatePasswordPolicies = (password: string) => {
  const policyChecks: IpolicyChecks = {
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasRepeatedSequence: false,
  };

  let status: boolean = false;
  let passwordComplexity: string = "";
  policyChecks.hasMinLength = password.length >= 8 ? true : false;
  for (let i = 0; i < password.length; i++) {
    let char = password.charAt(i);
    if (/^[A-Z]+$/.test(char) && !policyChecks.hasUppercase) {
      policyChecks.hasUppercase = true;
    } else if (/^[a-z]+$/.test(char) && !policyChecks.hasLowercase) {
      policyChecks.hasLowercase = true;
    } else if (
      /^[0-9]+$/.test(char) &&
      !isNaN(char) &&
      !policyChecks.hasNumber
    ) {
      policyChecks.hasNumber = true;
      if (
        /^[0-9]+$/.test(password[i + 1]) &&
        password[i] == password[i + 1] - 1 &&
        /^[0-9]+$/.test(password[i + 2]) &&
        password[i + 1] == password[i + 2] - 1
      ) {
        policyChecks.hasRepeatedSequence = true;
      }
    } else if (
      /^[^a-zA-Z0-9]+$/.test(char) &&
      !policyChecks.hasSpecialCharacter
    ) {
      policyChecks.hasSpecialCharacter = true;
    }

    if (password[i] === password[i + 1] && password[i + 1] === password[i + 2])
      policyChecks.hasRepeatedSequence = true;
  }

  if (
    policyChecks.hasLowercase &&
    policyChecks.hasUppercase &&
    policyChecks.hasMinLength &&
    policyChecks.hasNumber &&
    policyChecks.hasSpecialCharacter &&
    !policyChecks.hasRepeatedSequence
  )
    status = true;

  const passLength: number = password.length;

  if (status) {
    if (passLength === 8) passwordComplexity = PasswordComplexity.WEEK_PASSWORD;
    else if (passLength >= 13)
      passwordComplexity = PasswordComplexity.STRONG_PASSWORD;
    else if (passLength <= 12 && passLength > 8)
      passwordComplexity = PasswordComplexity.MEDIUM_PASSWORD;
  }

  return {
    status,
    policyChecks,
    passwordComplexity,
  };
};

export const mobileValidation = (mobile: string) => {
  let regex = /^[6-9]\d{9}$/;
  return regex.test(mobile);
};

export const validateFields = (
  fields: any = {},
  errorFields: object = {},
  optionalField: array[string] = []
) => {
  const validateFields = Object.keys(fields);
  let obj: any = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined || optionalField.includes(each)) return;

    if (typeof fields[each] === "string" || typeof fields[each] === "number") {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;
              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.includes("iotNumber"):
            if (fields[each]?.length !== 13) {
              error = true;
              obj[each] = en.errorMessages.imeiPhoneError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;
              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;

          // need to change each key for imei
          case each?.includes("imei"):
            if (fields[each]?.length !== 16) {
              error = true;
              obj[each] = en.errorMessages.imeiError;
            }
            break;

          default:
            break;
        }
      }
    }

    // else if( fields["inputFields"].category_name==""){
    //   error = true;
    //     obj[each] = en.errorMessages.requiredField;

    // }

    // @ts-ignore: Unreachable code error
    if (fields[each]?.id === 0 && typeof fields[each] === "object") {
      error = true;
      // @ts-ignore: Unreachable code error
      obj[each] = en.errorMessages.requiredField;
    }

    if (fields[each]?.name === "" && typeof fields[each] === "object") {
      error = true;
      obj[each] = en.errorMessages.requiredField;
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

export const isExcelExtention = (fileType: string) => {
  let allowedExtention = ["xlsx", "xls"];
  if (allowedExtention.includes(fileType)) {
    return true;
  } else {
    return false;
  }
};

// for encrypting  the login password in revamp - RBAC
export const encryptingText = (data: any) => {
  const key: string = process.env.REACT_APP_ENCRYPTION_KEY || "";
  try {
    const decodedPublicKey = forge.util.decode64(key);
    const publicKey = forge.pki.publicKeyFromPem(decodedPublicKey);
    try {
      const encryptedPassword = publicKey.encrypt(data, "RSA-OAEP");
      const base64EncodedPassword = forge.util.encode64(encryptedPassword);
      return base64EncodedPassword;
    } catch (error) {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

// function to decrypt text
export const decryptingText = (encryptedData: string) => {
  const privateKeyPem: string = process.env.REACT_APP_PRIVATE_KEY || "";
  try {
    const decodedPrivateKey = forge.util.decode64(privateKeyPem);
    const privateKey = forge.pki.privateKeyFromPem(decodedPrivateKey);
    try {
      const decodedData = forge.util.decode64(encryptedData);
      const decryptedPassword = privateKey.decrypt(decodedData, "RSA-OAEP");
      return decryptedPassword;
    } catch (error) {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

// generate random number
export const generateRandomString = (length, chars) => {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

// task management - format address
export const getDeliveryAddress = (address: any) => {
  let formatteDeliveryAddress = "";
  formatteDeliveryAddress = address?.addressLine1
    ? `${address?.addressLine1}`
    : "";
  formatteDeliveryAddress += address?.addressLine2
    ? `, ${address?.addressLine2}`
    : "";
  formatteDeliveryAddress += address?.landmark ? `, ${address?.landmark}` : "";
  formatteDeliveryAddress += address?.pinCode ? `, ${address?.pinCode}` : "";
  formatteDeliveryAddress = formatteDeliveryAddress
    ? formatteDeliveryAddress
    : "N/A";
  return formatteDeliveryAddress;
};
