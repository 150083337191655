import { ACTIONS } from "../actionTypes";
import * as actionTypes from "../actionTypes";

export const indicatorAction = (payload: boolean) => ({
  type: ACTIONS.LOCALE,
  payload,
});

export const cityData = (payload: any) => ({
  type: actionTypes.CITY_ACTIONS.CITYDATA_REQUESTED,
  payload,
});
export const BrandData = (payload: any) => ({
  type: actionTypes.BRAND_ACTIONS.BRANDDATA_REQUESTED,
  payload,
});


export const packagesData = (payload: any) => ({
  type: actionTypes.PACKAGES_ACTIONS.PACKAGES_DATA_REQUESTED,
  payload,
});

export const vehicleData = (payload: any) => ({
  type: actionTypes.VEHICLE_ACTIONS.VEHICLEDATA_REQUESTED,
  payload,
});

export const getCoupons = (payload: any) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPONS_REQUESTED,
  payload,
});

export const getCouponCodeType = (payload: any) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_REQUESTED,
  payload,
});

export const countryData = (payload: any) => ({
  type: actionTypes.COUNTRYDATA_ACTIONS.COUNTRYDATA_REQUESTED,
  payload,
});

export const stateData = (payload: any) => ({
  type: actionTypes.STATEDATA_ACTIONS.STATEDATA_REQUESTED,
  payload,
});
export const showToast = (payload: any) => ({
  type: actionTypes.ACTIONS.SHOW_TOAST,
  payload,
});
export const hideToast = (payload: any) => ({
  type: actionTypes.ACTIONS.HIDE_TOAST,
  payload,
});

export const vehicleTypes = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_REQUESTED,
  payload,
});

export const vehicleStatus = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_REQUESTED,
  payload,
});

export const vehicleCategories = (payload: any) => ({
  type: actionTypes.VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_REQUESTED,
  payload,
});

export const getVehicleColors = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_REQUESTED,
  payload,
});

export const getEmissionStandards = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_EMISSION_STANDARDS_REQUESTED,
  payload,
});


export const getAllBranches = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_REQUESTED,
  payload,
});

export const resetAllBranches = () => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_RESET,
});

export const getAllIotTypes = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_REQUESTED,
  payload,
});
export const getAllVendorCodes = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_REQUESTED,
  payload,
});

export const storeDocuments = (payload: any) => ({
  type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.STORE_DOCUMENT_REQUESTED,
  payload,
});

export interface GetModelListById {
  CategoryId?: number;
  TypeId?: number;
}

export const getModelListById = (payload: any) => {
  return {
    type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_REQUESTED,
    payload,
  };
};

export const getVehicleCategories = (payload: any) => {
  return {
    type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_CATEGORIES_REQUESTED,
    payload,
  };
};

export const getStatus = (payload: any) => ({
  type: actionTypes.STATUS_LIST_ACTIONS.GET_STATUS_LIST_REQUESTED,
  payload,
});

export const timeSlots = (payload: any) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_REQUESTED,
  payload,
});

export const getCitiesByState = (payload: any) => ({
  type: actionTypes.CITY_ACTIONS.GET_CITIES_BY_STATE_REQUESTED,
  payload,
});

// page data refresh logic
export const refreshDrawerScreenData = () => ({
  type: actionTypes.MASTER_ACTIONS.REFRESH_DRAWER_SCREEN_DATA,
});