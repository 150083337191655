import {
  Card,
  CardProps,
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { colors } from "../themes/colors";

export default function FilePicker(props: any) {
  return (
    <div style={{ width: props.width }}>
      <Grid
        sx={{
          padding: props.padding || "10px",
          marginTop: props.marginTop || "5px",
        }}
        container
        direction="row"
        justifyContent={props.justifyContent || "flex-end"}
      >
        <Typography
          style={{ fontSize: "12px" }}
          color="rgb(123, 130, 137)"
          variant="body2"
        >
          {props.label}
          {props.required && (
            <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
          )}
          {/* Odometer Reading(Upload New Image only) */}
        </Typography>
      </Grid>
      <Grid
        sx={{ padding: props.padding || "1px" }}
        container
        direction="row"
        justifyContent={props.justifyContent || "flex-end"}
      >
        <Typography color={colors.dark_gray} variant="body2">
          <input
            type="file"
            onChange={(e) => props.onChange(e)}
            accept={props.accept}
            multiple={props.multiple}
            disabled={props.disabled}
          />
        </Typography>
      </Grid>
      {props.errormessage && (
        <Typography className="error-message" style={{ fontSize: 14 }}>
          {props.errormessage}
        </Typography>
      )}
    </div>
  );
}
