import { Chart as ChartJS, registerables } from "chart.js";
import { Chart, Line, Pie } from "react-chartjs-2";
ChartJS.register(...registerables);

const lineOptions = {
  //indexAxis: 'y',
  maintainAspectRatio: true,

  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },

  scales: {
    y: {
      title: { display: true, text: "Frequency" },
    },
    x: {
      title: { display: true, text: "Dates" },
    },
  },
};
var Data = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

function LineChart(props: any) {
  return (
    <Chart
      type="line"
      options={lineOptions}
      data={props.data ? props.data : Data}
    />
  );
}

export default LineChart;
