import { SearchOutlined } from "@mui/icons-material";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BGImg,
  CardIcon,
  DownloadIcon,
  SearchIcon,
} from "../../constantsX/exportImagesX";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { debounce, handleMessage } from "../../../utils/helper";
import { Heading } from "../../../sharedComponents/typography";
import {
  StatDisplayStatus,
  bookingStatusDisplayName,
  createdByDisplayName,
  statusButtonColor,
} from "../../constantsX/booking";
import { actionsX } from "../../redux";
import {
  fetchBookingList,
  getBookingStatistics,
  updatePageSizeX,
  updatePageX,
  updateSearchX,
  updateSortingX,
  updateStatusX,
} from "../../redux/actions";
import BookingsCard from "./BookingsCard";
import EnhancedTable from "./ListTable";
// import EditNoteIcon from '@mui/icons-material/EditNote';
import { BREADCRUMBS_YOR } from "../../../rentals-src/constants/activeModule";
import moment from "moment";

import { Fade } from "react-awesome-reveal";
import styles from "./bookingList.module.css";
import SelectX from "../../components/InputFields/InputSelectX";
import InputX from "../../components/InputFields/InputX";
import { FREEDO_X_URL } from "../../../config";
import { BookingX, Global } from "../../constantsX/apiConstantsX";
import { customAxios } from "../../../config/axiosConfig";
import { toastr } from "react-redux-toastr";
import { StartIconLabelButtonX } from "../../components/muiButtonsX";

const Ride = [
  "Ongoing Rides",
  "Completed Rides",
  "Cancelled Rides",
  "Upcoming Rides",
];

const Rides = [
  { status: "BOOKED", displayName: "Upcoming Rides" },
  { status: "ACTIVE", displayName: "Ongoing Rides" },
  { status: "CLOSED", displayName: "Completed Rides" },
  { status: "CANCELLED", displayName: "Cancelled Rides" },
];
const statusChoice = [
  { name: "All", value: "ALL" },
  { name: "Pending", value: "CREATED" },
  { name: "Upcoming", value: "BOOKED" },
  { name: "Ongoing", value: "ACTIVE" },
  { name: "Cancelled", value: "CANCELLED" },
  { name: "Completed", value: "CLOSED" },
];
const pageChoice = [
  { name: "5 rows/page", value: "5" },
  { name: "10 rows/page", value: "10" },
  { name: "25 rows/page", value: "25" },
  { name: "50 rows/page", value: "50" },
];
function BookingList() {
  const { branchMapData, modelMapData }: any = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { page, pageSize, search, sorting, status }: any = useSelector(
    (state: RootState) => state.bookingListFiltersReducer
  );

  const [flipped, setFlipped] = useState<boolean>(false);

  const extraData: any = {
    modelData: modelMapData,
    branchData: branchMapData,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFlipped((prevFlipped) => !prevFlipped);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const columns = [
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "Customer's Name",
    },
    {
      id: "bookingDate",
      numeric: true,
      disablePadding: false,
      label: "Booking Date",
      customjs: true,
      format: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      id: "modelName",
      numeric: true,
      disablePadding: false,
      label: "Vehicle Name",
      customjs: true,
      format: (item: any, data: any) => {
        return data?.modelData?.get(item) ? data?.modelData?.get(item) : item;
      },
    },
    {
      id: "branchName",
      numeric: false,
      disablePadding: false,
      label: "Warehouse Name",
      customjs: true,
      format: (item: any, data: any) => {
        return (
          <Tooltip
            title={
              data?.branchData?.get(item)?.length > 11
                ? data?.branchData?.get(item)
                : ""
            }
          >
            <Typography className={styles.textOverflow}>
              {data?.branchData?.get(item) ? data?.branchData?.get(item) : item}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "startDate",
      numeric: true,
      disablePadding: false,
      label: "Start Date",
      customjs: true,
      format: (date: any) => moment(date).format("DD-MM-YYYY"),
    },

    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      customjs: true,
      render: (item: any) => {
        return item?.status === "BOOKED" &&
          moment(item?.startDate).format("DD/MM/YYYY") ==
            new Date().toLocaleDateString() ? (
          <Box>
            {flipped ? (
              <Chip
                className="front"
                label={
                  <Fade direction="down" triggerOnce={false}>
                    {bookingStatusDisplayName[item?.status]}
                  </Fade>
                }
                sx={{
                  backgroundColor: statusButtonColor[item?.status],
                  color: "#FFF",
                  height: "23px",
                  width: "100px",
                  padding: "8px, 12px, 8px, 12px",
                }}
              />
            ) : (
              <Chip
                className="back"
                label={
                  <Fade direction="up" triggerOnce={false}>
                    Starts Today
                  </Fade>
                }
                sx={{
                  backgroundColor: statusButtonColor[item?.status],
                  color: "#FFF",
                  height: "23px",
                  width: "100px",
                  padding: "8px, 12px, 8px, 12px",
                }}
              />
            )}
          </Box>
        ) : (
          <Chip
            label={bookingStatusDisplayName[item?.status]}
            sx={{
              backgroundColor: statusButtonColor[item?.status],
              color: "#FFF",
              height: "23px",
              width: "100px",
              padding: "8px, 12px, 8px, 12px",
            }}
          />
        );
      },
    },
    {
      id: "createdBy",
      numeric: true,
      disablePadding: false,
      label: "Created By",
      customjs: true,
      format: (item: any) => {
        return createdByDisplayName[item];
        //
      },
    },
  ];
  const { statistics, bookings, bookingListLoader }: any = useSelector(
    (state: RootState) => state.bookingManagementReducersX
  );
  const [pageNo, setPageNo] = useState("0");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getBookingStatistics());
    /*  if(page>pageNo){
      setPageNo(page)
    } */
  }, []);
  useEffect(() => {
    if (Number(page + 1) > bookings?.pageData?.page) {
      dispatch(updatePageX("0"));
      //setPageNo("0")
    }
  }, [bookings?.bookings]);

  const handlePageSizeChange = (value: any) => {
    dispatch(updatePageSizeX(value));
  };

  const handleStatusChange = (status: any) => {
    dispatch(updateStatusX(status));
  };

  const handleSearchChange = (e: any) => {
    dispatch(updateSearchX(e.target.value));
  };

  /* useEffect(()=>{
    if(bookings?.pageData?.page=="1" && bookings?.pageData?.page< Number(page+1)){
      dispatch(updatePageX("0"))
    }
  },[]) */

  useEffect(() => {
    dispatch(
      fetchBookingList({
        pageSize: pageSize?.value,
        search: search?.length != 1 ? search : null,
        sorting: sorting,
        status: status?.value,
      })
    );
  }, [pageSize, status, sorting]);

  useEffect(() => {
    dispatch(
      fetchBookingList({
        page: Number(page) + 1,
        pageSize: pageSize?.value,
        search: search?.length != 1 ? search : null,
        sorting: sorting,
        status: status?.value,
      })
    );
  }, [page]);

  const handleSearch = (value: string) => {
    if (value?.length != 1) {
      dispatch(
        fetchBookingList({
          pageSize: pageSize?.value,
          search: value,
          sorting: sorting,
          status: status?.value,
        })
      );
    }
  };

  /* const handleSearchText = (e: any) => {
    handleSearchChange(e.target.value);
    //setField({ ...field, search: e.target.value });
  }; */
  const debounceSearch = useCallback(debounce(handleSearch, 1000), [
    page,
    pageSize,
    sorting,
    status,
  ]);
  /* const handleCreateBookingNav = (e: any) => {
    navigate("/new-booking", { replace: true });
  }; */

  // ACTIVE SIDEBAR, BREADCRUMS & TITLE
  useEffect(() => {
    dispatch(actionsX.addActiveModuleX(BREADCRUMBS_YOR.BOOKING_LIST));
  }, []);
  const handleDownloadRecords = (e: any) => {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      BookingX.bookingList;
    url += `?pageSize=${bookings?.pageData?.total}`;
    url += `${search?.length <= "1" ? "" : `&search=${search}`}`;
    url += `${status?.value == "ALL" ? "" : `&status=${status?.value}`}`;
    return customAxios
      .get(url)
      .then((response) => {
        exportToCvc(e, response?.data?.data?.bookings);
      })
      .catch((error) => {
        toastr.error("", handleMessage(error));
      });
  };
  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCvc = (e: any, data: any) => {
    e.preventDefault();
    // Headers for each column
    let headers = [
      "customer Name,booking Date,model Name,branch Name,start Date,status,created By",
    ];
    // Convert users data to a csv
    let usersCsv = data.reduce((acc: any, user: any) => {
      const {
        customerName,
        bookingDate,
        modelName,
        branchName,
        startDate,
        status,
        createdBy,
      } = user;
      acc.push(
        [
          customerName,
          moment(bookingDate).format("DD-MM-YYYY"),
          modelMapData?.get(modelName)
            ? modelMapData?.get(modelName)
            : modelName,
          branchMapData?.get(branchName)
            ? branchMapData?.get(branchName)
            : branchName,
          moment(startDate).format("DD-MM-YYYY"),
          bookingStatusDisplayName[status],
          createdByDisplayName[createdBy],
        ].join(",")
      );
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "bookingList.csv",
      fileType: "text/csv",
    });
  };
  return (
    <Box>
      <Box className={styles.BoxColumn}>
        <Box>
          <StartIconLabelButtonX
            variant="outlined"
            label="+ Create Booking"
            onClick={() => navigate("/new-booking", { replace: true })}
          />
        </Box>
        <Grid container spacing={2}>
          <>
            {Rides?.map((item: any, index: number) => {
              let ind = statistics?.findIndex(
                (data: any) => data?.status === item?.status
              );
              return (
                <BookingsCard
                  icon={CardIcon}
                  text={item?.displayName}
                  subText={statistics[ind]?.total || 0}
                  bgImg={BGImg}
                />
              );
            })}
          </>
        </Grid>
        <Box className={styles.tableHeader}>
          <Box>
            <Heading title="List Of Bookings" className="w-auto" />
          </Box>
          <Box className={styles.boxRow}>
            <Box>
              <InputX
                onChange={(e: any) => {
                  //handleSearchText(e);
                  handleSearchChange(e);
                  debounceSearch(e.target.value);
                }}
                icon={SearchIcon}
                value={search}
                placeholder={"Search Customer"}
                label={"Search Customer"}
                width="200px"
              />
            </Box>
            <Box>
              <SelectX
                width="160px"
                placeholder={"Select PageSize"}
                value={pageSize?.name}
                choice={pageChoice}
                defaultKey={"name"}
                required
                handleChange={handlePageSizeChange}
              ></SelectX>
            </Box>
            <Box>
              <SelectX
                heading="Status"
                width="150px"
                placeholder={"Select Status"}
                value={status?.name}
                choice={statusChoice}
                defaultKey={"name"}
                required
                handleChange={handleStatusChange}
              ></SelectX>
            </Box>
            <Box>
              {/* <Button onClick={exportToCvc}> */}
              <Button
                onClick={(e) => {
                  handleDownloadRecords(e);
                }}
              >
                <img src={DownloadIcon} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <EnhancedTable
        columns={columns}
        rows={bookings?.bookings}
        extraData={extraData}
        // setFields={setField}
        //fields={field}
        count={bookings?.pageData?.total}
        loading={bookingListLoader}
        pages={bookings?.pageData?.page}
        pageSize={pageSize}
        //setPageNo={setPageNo}
        pageNo={page}
      />
    </Box>
  );
}

export default BookingList;
