import { colors } from "../../../../../themes/colors";

export const StyleObject: any = {
  cardContentKey: {
    fontWeight: 500,
    fontSize: "11px",
    color: colors.tertiary_black,
  },
  cardContentValue: {
    fontWeight: 400,
    fontSize: "11px",
    color: colors.text_black,
  },
  plansCardcontainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px 12px 12px 12px",
    backgroundColor: colors.white,
    maxWidth: "300px",

    minHeight: "178px",
    boxShadow: "0px 4px 16px 0px #00000014",
  },
  plansAndPaymentsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
    alignItems: "center",
  },
  imageUploadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    width: "80px",
    border: "2px dotted #B1B1B4",
    borderRadius: "16px",
    cursor: "pointer",
  },
  excessChargeHrKmContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "12px 16px 12px 16px",
    alignItems: "center",
    justifyContent: "center",
  },
  orderSummaryContainer: {
    width: "359px",
    display: "flex",
    color: colors.text_black,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 40px 12px 24px",
    border: "1px solid #00000029",
    backgroundColor: "#FFFFFFA3",
    boxShadow: "0px 0px 1.5px 0px #00000040",
    cursor: "pointer",
  },
  orderSummaryTotalContainer: {
    width: "359px",
    display: "flex",
    color: colors.text_black,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 40px 12px 24px",
    border: "1px solid #00000029",
    backgroundColor: "#FFFFFFA3",
    boxShadow: "0px 0px 1.5px 0px #00000040",
    borderRadius: "0px 0px 10px 10px",
    cursor: "pointer",
  },
  viewMoreContainer: {
    width: "359px",
    display: "flex",
    color: colors.text_black,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 40px 12px 24px",
    border: "1px solid #00000029",
    backgroundColor: "#F5F5F5",
    boxShadow: "0px 0px 1.5px 0px #00000040",
    cursor: "pointer",
  },
  imageUplodedContainer: {
    backgroundColor: "#00000014",
    borderRadius: "17.48px",
    padding: "24px",
    display: "flex",
    alignItems: "flex-start",
    gap: "22px",
    justifyContent: "space-between",
  },
  odometerReadingUiContainer: {
    display: "flex",
    padding: "16px 24px 16px 24px",
    gap: "20px",
    background:
      "linear-gradient(90deg, rgba(1, 53, 61, 0.04) 0%, rgba(43, 199, 201, 0.04) 100%)",
  },
};
