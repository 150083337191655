import { Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { faAdd, faFileCsv, faRefresh } from "@fortawesome/free-solid-svg-icons";

import { Button2, Button5 } from "./button";
import "./components.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../themes/colors";
import { ButtonActions } from "../constants/userActions";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { useState } from "react";

interface heading {
  title: string;
  actionButtons?: Array<any>;
  onClickButton?: Function;
  className?: string;
  fontSize?: number | string;
  hideActionButtons?: boolean;
  topButton?: boolean;
  buttonTitle?: string;
  buttonOnClick?: any;
  buttonLoader?: boolean;
}
function Heading({
  title,
  actionButtons,
  onClickButton,
  className,
  fontSize,
  hideActionButtons = false,
  topButton = false,
  buttonTitle,
  buttonOnClick,
  buttonLoader,
}: heading) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { global } = locale;

  const [rotateActive, setRotateActive] = useState(false);

  return (
    <Grid
      className={`heading-container ${className}`}
      container
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        color="primary"
        style={{
          fontSize: fontSize ? fontSize : isDesktopOrTablet ? "21px" : "16px",
          fontWeight: "bold",
          fontFamily: "EuclidCircularB-SemiBold",
          // color: "rgb(61, 56, 85)",
          fontStretch: "normal",
        }}
      >
        {title}
      </Typography>

      {!hideActionButtons && (
        <Grid className="heading-buttons">
          {actionButtons?.map((data, i) => {
            if (!data) return;
            if (data === ButtonActions.refresh) {
              return (
                <Tooltip key={i} title={data} placement="top-start">
                  <div
                    style={{
                      cursor: "pointer",
                      marginRight: isDesktopOrTablet ? "20px" : "10px",
                      marginTop: "5px",
                      marginLeft: "3px",
                    }}
                    onClick={() => {
                      onClickButton && onClickButton(data);
                      setRotateActive(true);
                      setTimeout(() => {
                        setRotateActive(false);
                      }, 1000);
                    }}
                    className={rotateActive ? "spin" : ""}
                  >
                    <FontAwesomeIcon
                      icon={faRefresh}
                      color={colors.THEME_BLUE}
                      fontSize={isDesktopOrTablet ? 24 : 20}
                    />
                  </div>
                </Tooltip>
              );
            } else if (data === ButtonActions.CSVDownload) {
              return (
                <Button5
                  key={i}
                  onClick={() => onClickButton && onClickButton(data)}
                >
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    color={colors.white}
                    fontSize={20}
                  />
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "EuclidCircularB-SemiBold",
                      color: colors.white,
                      marginLeft: "10px",
                    }}
                  >
                    {global.CSVDownload}
                  </Typography>
                </Button5>
              );
            } else if (data === ButtonActions.AddOperator) {
              return (
                <Button5
                  key={i}
                  onClick={() => onClickButton && onClickButton(data)}
                >
                  <FontAwesomeIcon
                    icon={faAdd}
                    color={colors.white}
                    fontSize={20}
                  />
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontFamily: "EuclidCircularB-SemiBold",
                      color: colors.white,
                      marginLeft: "10px",
                    }}
                  >
                    {data}
                  </Typography>
                </Button5>
              );
            }
            return (
              <div
                key={i}
                style={{
                  marginRight: actionButtons.length === i + 1 ? "0px" : "10px",
                }}
              >
                <Button2
                  title={data}
                  onClick={(e: any) => onClickButton && onClickButton(data, e)}
                />
              </div>
            );
          })}
        </Grid>
      )}
      {topButton && (
        <Button2
          title={buttonTitle}
          onClick={buttonOnClick}
          size="large"
          loading={buttonLoader}
          style={{}}
        ></Button2>
      )}
    </Grid>
  );
}

function SubHeading({ title, className }: heading) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);

  return (
    <Grid
      className={`heading-container ${className}`}
      container
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        style={{
          fontSize: isDesktopOrTablet ? "17px" : "14px",
          fontWeight: "bold",
          fontFamily: "EuclidCircularB-SemiBold",
          color: "rgb(61, 56, 85)",
          fontStretch: "normal",
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
}

export { Heading, SubHeading };
