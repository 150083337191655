// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import yoren from "../../locale/yor-en.json";
export const REGEX = {
  alfa: /^[a-zA-Z ]*$/,
  alfaNumeric: /^[0-9a-zA-Z ]*$/,
  phone: new RegExp("^[6-9]([0-9]*)$"),
  numeric: /^[0-9]*$/,
  float: /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/,
  pin: /^[0-9]*$/,
};

export const getInitials = (fullName: string = "") => {
  if (!fullName) return "";
  fullName = fullName.trim();
  let nameArr = fullName.split(" ");
  let i = 0;
  let initialChars = "";
  for (i; i < nameArr.length; i++) {
    initialChars += nameArr[i][0];
  }
  return initialChars;
};

export const getFullName = (first: string = "", last: string = "") => {
  let fullName = `${first ? first : " "}` + " " + `${last ? last : " "}`;
  fullName = fullName.trim();
  return fullName;
};

export const getGenderLabel = (genderId: Number) => {
  if (!genderId) {
    return "";
  }
  switch (genderId) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Others";
    default:
      return "";
  }
};

export const validate = (
  fields: any = {},
  errorFields: object = {},
  optionalField: array[string] = []
) => {
  const validateFields = Object.keys(fields);
  let obj = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined || optionalField.includes(each)) return;

    if (typeof fields[each] === "string" || typeof fields[each] === "number") {
      if (!fields[each]) {
       
        error = true;
        obj[each] = yoren.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;

              obj[each] = yoren.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;

              obj[each] = yoren.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("imei"):
            if (fields[each]?.length < 15) {
              error = true;

              obj[each] = yoren.errorMessages.imeiError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("secoundary") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = yoren.errorMessages.mobileError;
            }
            break;

          default:
            break;
        }
      }
    } else if (typeof fields[each] === "object") {
      // @ts-ignore: Unreachable code error
      if (!Object.keys(fields[each]).length) {
        error = true;
        obj[each] = yoren.errorMessages.requiredField;
      }
      if (fields[each].name == "") {
        error = true;
        obj[each] = yoren.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

export const commmaSeparator = (num: number) => {
  if (num) {
    var result = num.toString().split(".");
    result[0] = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result.join(".");
  } else return num;
};

export const getFormattedINR = (amount: number) => {
  let formattedAmount = "";
  // if (amount > 0) {
  amount = amountConvertToRupee(amount);
  formattedAmount = amount.toLocaleString("yoren-IN", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
  // }
  return formattedAmount;
};

export const amountConvertToRupee = (num: number) => {
  let result = 0;
  if (num > 0) {
    result = num / 100;
  }

  return result;
};

export const dateFormat = (date: any) => {
  if (!date) return;
  const dayjs = require("dayjs");
  const datee = dayjs(date);
  const formattedDate = datee.format("DD/MM/YYYY");
  return formattedDate;
};

// export const tempFormatDateString = (str: any) => {
//   // let text = "2024-03-21T18:56:37+05:30";
//   const myArray = str.split("T");
//   return myArray[0];
// };
export const addDaysToDate = (date: any, days: any) => {
  const dayjs = require("dayjs");

  return dayjs(date, "DD/MM/YYYY").add(days, 'day');
}
export function dateToTimestamp(selectedDate: any) {
  if (!selectedDate) return 0;
  const dayjs = require("dayjs");
  const timestamp = dayjs(selectedDate, "DD/MM/YYYY").valueOf();
  return timestamp;
}

// Date String to Timestamp
export const convertToTimeStamp = (date: string): number => {
  let timeStamp = new Date().getTime();
  if (date) {
    timeStamp = new Date(`${date}`).getTime();
  }
  return timeStamp;
};

export const helmets = {
  premium: 1,
  fullFace: 3,
  halfFace: 1,
  kids: 0,
};

export const getArraySum = (arr: any = []) => {
  let sum = 0;
  if (arr.length > 0) {
    sum = arr.reduce((partialSum, a) => partialSum + a, 0);
  }
  return sum;
};

export const checkNumericX = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};
export function timestampToIST(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formatedDate = `${year}-${month}-${day}`;
  const ISTFormat = new Date(formatedDate);
  return ISTFormat;
}

export function getValueByKey(map: object, searchKey: string) {
  for (const [key, value] of map.entries()) {
    if (value === searchKey) return key;
  }
}

export const getLoggedInDateTime = (date: any, type: string="date") => {
  let resultDate;
  let lastLoggedIn;
  if (date) {
    lastLoggedIn = new Date(`${date}`);
    if (lastLoggedIn) {
      let date = lastLoggedIn.getDate();
      let month = lastLoggedIn.getMonth() + 1;
      let fullYear = lastLoggedIn.getFullYear();
      let hours = (lastLoggedIn.getHours() + 24) % 12 || 12;
      let minutes =
        (lastLoggedIn.getMinutes() < 10 ? "0" : "") + lastLoggedIn.getMinutes();
      let seconds = lastLoggedIn.getSeconds();
      let timeFormat = lastLoggedIn.getHours() >= 12 ? "PM" : "AM";

      if (type === "date") {
        resultDate = `${date}/${month}/${fullYear}`;
      }
      if (type === "time") {
        resultDate = `${hours}:${minutes}:${seconds}s ${timeFormat}`;
      }
      if (type === "dateTime") {
        resultDate = `${date}/${month}/${fullYear} - ${hours}:${minutes}:${seconds}s ${timeFormat}`;
      }
      if (type === "hours") {
        resultDate = `${hours}:${minutes} ${timeFormat}`;
      }
    }
  }
  return resultDate;
};

export const getLastLoggedInTime = (date: Date) => {
  let lastLoggedIn = new Date();
  if (date) lastLoggedIn = new Date(date);
  let resultDate = "";
  if (lastLoggedIn)
    resultDate = `${lastLoggedIn.getDate()} / ${lastLoggedIn.getMonth()} / ${lastLoggedIn.getFullYear()}}`;

  return resultDate;
};

export const checkIfImageExists = (url: string) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    return true;
  } else {
    img.onload = () => {
      return true;
    };
    img.onerror = () => {
      return false;
    };
  }
};

export const getNumberOrdinal = (n: number): string => {
  let ord = "th";

  if (n % 10 == 1 && n % 100 != 11) {
    ord = "st";
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = "nd";
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = "rd";
  }

  return ord;
};
