import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";

import { handleMessage } from "../../../utils/helper";
import rentalEn from "../../locale/rental-en.json";
import {
  BOOKING_MANAGEMENT_ACTIONS,
  PAYMENT_MANAGEMENT_ACTIONS,
} from "../actions/actionTypes";
import paymentManagementServices from "../services/paymentManagementServices";
import customerManagement from "../services/customerManagement";

// fetch payment list
function* getPaymentListSaga(action: any): any {
  try {
    const result: any = yield call(
      paymentManagementServices.getPaymentList,
      action.payload
    );
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_SUCCESS,
        payload: {
          paymentListData: result?.data?.data,
          key: action?.key,
        },
      });
    } else {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_FAILED,
      });
      toastr.info(rentalEn?.toastTypes?.info, handleMessage(result));
    }
  } catch (error: any) {
    yield put({
      type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_FAILED,
      payload: {
        key: action?.key,
      },
    });
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
  }
}

// get payment list using phone number
function* paymentListWithPhoneNumber(action: any): any {
  try {
    const { userPhoneNumber, ...restPayload } = action.payload;
    const response = yield call(customerManagement.searchCustomerService, {
      mobile_number: userPhoneNumber,
    });
    const userDetails = response?.data?.data.searchResult;
    if (
      (response.status === 200 || response.status === 201) &&
      userDetails.length > 0
    ) {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_REQUESTED,
        payload: { customerId: userDetails[0].id, ...restPayload },
        key: action?.key,
      });
    } else {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_FAILED,
      });
      toastr.info(
        rentalEn?.toastTypes?.info,
        rentalEn.errorMessages.userNotRegistered
      );
    }
  } catch (err) {
    yield put({
      type: PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_FAILED,
    });
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(err));
  }
}

//get Refund details
function* getRefundDetails(action: any): any {
  try {
    const result: any = yield call(
      paymentManagementServices.getRefundDetails,
      action.payload
    );
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_SUCCESS,
        payload: result?.data?.data.details,
      });
    } else {
      yield put({
        type: PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(result));
    }
  } catch (error: any) {
    yield put({
      type: PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_FAILED,
      payload: {
        key: action?.key,
      },
    });
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
  }
}

export default function* paymentManagementSaga() {
  yield takeLatest(
    PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_REQUESTED,
    getPaymentListSaga
  );
  yield takeLatest(
    PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_USING_PHONE_NUMBER_REQUESTED,
    paymentListWithPhoneNumber
  );
  yield takeLatest(
    PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_REQUESTED,
    getRefundDetails
  );
}
