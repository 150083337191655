import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Snackbar, useMediaQuery } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootState } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS, actions } from "../redux";
import { ToastType } from "../constants/userActions";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface snack {
  messageText?: string,
  open: boolean,
  toastType?: string
}




const Snackbars = ({ open }: snack) => {
  const isMobile = useMediaQuery("min-width:767px");
  const data = useSelector((state: RootState) => state.toastReducer);

  const dispatch = useDispatch();


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      
        dispatch(actions.hideToast({
            toastMessage : ''
        }))
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical : 'top', horizontal : 'right' }} style={{ marginTop : isMobile ? '40px'  : '60px' }}>
        <Alert onClose={handleClose} severity={data.toastType ||  ToastType.success} sx={{ width: '100%' }}>
          {data.toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Snackbars;
