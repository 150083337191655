import React, { useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ButtonActions } from "../../constants/userActions";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import StickyHeaderTable from "../../components/stickeyHeader";
import { ViewOnlyRating } from "../../components/ratings";
import { BasicSelect } from "../../components/dropDown";
import { actions } from "../../redux";
import { LocalStorage } from "../../constants/enum";
import { getData, storeData } from "../../config/filterStorage";
import { BasicDatePicker } from "../../components/datePicker";
import moment from "moment";
import { Input3 } from "../../components/input";
import { toastr } from "react-redux-toastr";
import DynamicTable from "./DynamicTable";
import CircularProgress from "@mui/material/CircularProgress";


function NewFeedbackManagement() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  const LobType =
    userData !== undefined &&
    userData !== null &&
    Object.keys(userData)?.length > 0 &&
    userData?.businessType?.map((item: string, index: number) => {
      return {
        name: item,
        id: index + 1,
      };
    });
  //   const {
  //     availableLocation,
  //     allCitiesData,
  //     vehicleTypes,
  //     vehicleStatus,
  //     vehicleModelList,
  //     branches,
  //     vehicleCategoryList,
  //   } = useSelector((state: RootState) => state.commonReducer);

  const { global, feedbackManagement } = locale;
  const {
    feedbackData,
    totalItems,
    loading,
    newFeedBackArray,
    loading2,
    loading3,
    userFeedBack,
  } = useSelector((state: RootState) => state.feedbackReducer);
  const { allCitiesData, availableLocation, branches } = useSelector(
    (state: RootState) => state.commonReducer
  );

  const [searchedBookingId, setSearchBookingId] = useState<string>("");
  const [feedBackId, setFeedBackId] = useState<any>();

  useEffect(() => {
    if (feedBackId)
      dispatch(actions.getFeedbackById({ feedBackId: feedBackId }));
  }, [feedBackId]);

  const initialState = {
    selectedCity: {
      city_name: global.SelectCity,
      id: 0,
    },
    LobType: {
      name: "Select LOB Type",
      id: 0,
    },
    selectedBranch: {
      branch_name: global.SearchByBranch,
      id: 0,
    },
    createdFrom: moment().subtract(7, "days").toISOString(),
    createdTo: new Date(),
  };

  const [fields, setFields] = useState({ ...initialState });
  const [expandedRow, setExpandedRow] = useState(null)

  const columns: any = [
    {
      id: "legacyCustomerName",
      label: global.UserName,
      minWidth: 150,
      align: "left",
      customJson: true,
      render: (item: any) => {
        return <div>{item?.lobMeta?.legacyCustomerName ?? "N/A"}</div>;
      },
    },
    {
      id: "overallRating",
      label: global.Ratings,
      minWidth: 150,
      align: "left",
      customJson: true,
      render: (item: any) => {
        return (
          <ViewOnlyRating
            value={
              !isNaN(item?.basicAnswers?.overallRating)
                ? item?.basicAnswers?.overallRating
                : 0
            }
            readOnly={true}
            color={true}
          />
        );
      },
    },
    {
      id: "happinessScore",
      label: global.HappinessScore,
      minWidth: 150,
      align: "center",
      customJson: true,
      render: (item: any) => {
        let color;
        if (item?.happinessScore >= 10 && item?.happinessScore < 30) {
          color = "red";
        } else if (item?.happinessScore >= 30 && item?.happinessScore < 60) {
          color = "orange";
        } else {
          color = "#2EC6C8";
        }

        return (
          <>
            {item?.happinessScore ? (
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={item?.happinessScore}
                  sx={{ color: color }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "0.65rem" }}
                    variant="caption"
                    component="div"
                    color={color}
                  >
                    {`${Math.round(item?.happinessScore)}%`}
                  </Typography>
                </Box>
              </Box>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "customerReview",
      label: global.Comments,
      minWidth: 150,
      align: "left",
      customJson: true,
      render: (item: any) => {
        return <div style ={{whiteSpace:"break-spaces", 
        overflow :"hidden",
        display: "-webkit-box",
         WebkitLineClamp :2,
         WebkitBoxOrient : "vertical",
         textOverflow:"ellipsis"



        }}>{item?.basicAnswers?.customerReview ?? "N/A"}</div>;
      },
    },
    {
      id: "legacyModelName",
      label: global.Model,
      minWidth: 150,
      align: "left",
      customJson: true,
      render: (item: any) => {
        return <div>{item?.lobMeta?.legacyModelName ?? "N/A"}</div>;
      },
    },
    {
      id: "legacyCityName",
      label: global.City,
      minWidth: 150,
      align: "left",
      customJson: true,
      render: (item: any) => {
        return <div>{item?.lobMeta?.legacyCityName ?? "N/A"}</div>;
      },
    },
    {
      id: "feedbackType",
      label: global.Type,
      minWidth: 150,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return (
          <Chip
            label={item?.feedbackType}
            sx={{
              backgroundColor: "#C7EDEF",
              color: "#2BC7C9",
              fontWeight: 500,
            }}
          />
        );
      },
    },
    {
      id: "expandableColumn",
      label: "",
      minWidth: 50,
      align: "center",
      expandable: true,
      render: (item: any) => {
        return null; // We render the expand/collapse button directly in DynamicTable component
      },
    },
  ];

  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const handlePage = (page: any) => {
    setPage(page);
  };
  const handleRows = (row: any) => {
    setSize(row);
  };
  const [resett, setResett] = useState(false);

  const dispatch = useDispatch();

  //call to load the data and reset the data(Dependency:reset)
  useEffect(() => {
    dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    );
    setResett(false);
    getData(LocalStorage.NewFeedback_Management).then((data: any) => {
      const feedbackData = JSON.parse(data || "");
      if (feedbackData) {
        setFields((prevState) => ({
          ...prevState,
          selectedCity: feedbackData.city,
          selectedBranch: feedbackData.branch,
          createdFrom: feedbackData.startDate,
          createdTo: feedbackData.endDate,
          LobType: feedbackData.LobType,
        }));

        dispatch(
          actions.getAllBranches({
            city_id: feedbackData.city?.id,
          })
        );
        setPage(feedbackData.page);
        setSize(feedbackData.size);
      }
    });
  }, []);

  const handleChange = (field: string, value: any) => {
    setFields((prevState) => ({ ...prevState, [field]: value }));
  };

  const onChangeCity = (city: any) => {
    // dispatch(
    //   actions.feedback({
    //     page: page + 1,
    //     size: size,
    //     city_id: city?.id,

    //   })
    // );

    if (city.id) {
      dispatch(
        actions.getAllBranches({
          cityId: city.id,
        })
      );
    }
    setFields((prevState) => ({
      ...prevState,
      selectedBranch: {
        branch_name: global.SearchByBranch,
        id: 0,
      },
    }));
  };

  useEffect(() => {
    let data = {
      city: fields?.selectedCity,
      branch: fields?.selectedBranch,
      startDate: fields?.createdFrom,
      endDate: fields?.createdTo,
      LobType: fields?.LobType,
      page: page,
      size: size,
    };
    storeData(LocalStorage.NewFeedback_Management, data);
  }, [
    fields.selectedCity.id,
    fields.selectedBranch.id,
    fields.createdFrom,
    fields.createdTo,
    refresh,
    page,
    fields?.LobType,
    size,
  ]);

  const reset = () => {
    setResett(true);
    setPage(0);
    setSize(10);
    setExpandedRow(null)
    setFields((prevState) => ({
      ...prevState,
      selectedBranch: {
        branch_name: global.SearchByBranch,
        id: 0,
      },
      selectedCity: {
        city_name: global.SelectCity,
        id: 0,
      },
      LobType: {
        name: "Select LOB Type",
        id: 0,
      },
      createdFrom: moment().subtract(7, "days").toISOString(),
      createdTo: new Date(),
    }));

    let data = {
      city: { city_name: global.SelectCity, id: 0 },
      branch: {
        branch_name: global.SearchByBranch,
        id: 0,
      },
      LobType: {
        name: "Select LOB Type",
        id: 0,
      },
      page: 0,
      size: 10,
      startDate: moment().subtract(7, "days").toISOString(),
      endDate: new Date(),
    };
    storeData(LocalStorage.NewFeedback_Management, data);
  };

  const onBookingIdChange = (e: any) => {
    const { value } = e.target;
    setSearchBookingId(value);
  };

  const handleSearchClick = () => {
    if (searchedBookingId.length >= 1) {
      // alert(searchedBookingId);
      reset();
      dispatch(
        actions.getFeedbacks({
          page: page + 1,
          size: size,
          bookingId: searchedBookingId,
          status: "",
          lob: fields?.LobType,
        })
      );
    } else {
      toastr.error("", "Please Enter Complete Booking ID");
    }
  };

  useEffect(() => {
    if (searchedBookingId?.length === 0) {
      dispatch(
        actions.getFeedbacks({
          page: page + 1,
          size: size,
          branchId: fields?.selectedBranch?.id,
          createdFrom: fields?.createdFrom,
          createdTo: fields?.createdTo,
          cityId: fields?.selectedCity?.id,
          bookingId: searchedBookingId,
          status: "",
          lob: fields?.LobType,
        })
      );
    }
  }, [searchedBookingId]);
  useEffect(() => {
    dispatch(
      actions.getFeedbacks({
        page: page + 1,
        size: size,
        branchId: fields?.selectedBranch?.id,
        createdFrom: fields?.createdFrom,
        createdTo: fields?.createdTo,
        cityId: fields?.selectedCity?.id,
        bookingId: searchedBookingId,
        status: "",
        lob: fields?.LobType,
      })
    );
  }, [
    fields.selectedCity.id,
    fields.selectedBranch.id,
    fields.createdFrom,
    fields.createdTo,
    fields?.LobType?.name,
    refresh,
    page,
    size,
  ]);

  return (
    <div className="container pd-20">
      <Heading
        title={feedbackManagement.FeedbackManagement}
        actionButtons={[ButtonActions.refresh, ButtonActions.reset]}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          }
          if (type === ButtonActions.reset) {
            reset();
          }
        }}
      />

      <Box
        sx={{
          display: "flex",
          marginLeft: "-4px",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          bgcolor: "background.paper",
          alignItems: "center",
        }}
      >
        {/* {cityloading === true || locationLoading === true ? (
          ""
        ) : ( */}
        <BasicDatePicker
          dateValue={fields.createdFrom}
          setDateValueFunc={
            (val: any) => {
              handleChange("createdFrom", val);
            }

            // setFields({ ...fields, from_date: val })
          }
          margin="5px"
        />
        <BasicDatePicker
          dateValue={fields.createdTo}
          setDateValueFunc={(val: any) => {
            handleChange("createdTo", val);
          }}
          margin="5px"
        />

        <Input3
          onChange={onBookingIdChange}
          value={searchedBookingId}
          placeholder={"Search Booking ID"}
          width={320}
          searchIcon={true}
          handleSearchClick={handleSearchClick}
        />
        <>
          <BasicSelect
            selected2={fields.selectedCity["city_name"]}
            // handleChange={(city: any) => {
            //   setSelectedCity(city);
            //   onChangeCity(city);
            // }}

            handleChange={(data: any) => {
              handleChange("selectedCity", data);
              onChangeCity(data);
            }}
            margin="5px"
            padding="1px"
            width={320}
            allOption={{
              city_name: global.All,
              id: 0,
            }}
            defaultKey={"city_name"}
            heading={global.SelectCity}
            data={allCitiesData}
          />

          <BasicSelect
            heading={global.SearchByBranch}
            selected2={fields.selectedBranch["branch_name"]}
            defaultKey={"branch_name"}
            handleChange={(data: any) => handleChange("selectedBranch", data)}
            margin="5px"
            padding="1px"
            width={320}
            data={branches?.data ?? []}
          />

          <BasicSelect
            heading={"Select LOB Type"}
            selected2={fields.LobType["name"]}
            defaultKey={"name"}
            handleChange={(data: any) => handleChange("LobType", data)}
            margin="5px"
            padding="1px"
            width={320}
            data={LobType ?? []}
          />
        </>
      </Box>

      <DynamicTable
        loading={loading}
        pageSetter={handlePage}
        rowsPerPageSetter={handleRows}
        size={size}
        page={page}
        resetSize={resett}
        paddingAction="4px"
        rows={newFeedBackArray}
        totalItems={totalItems}
        columns={columns}
        setFeedBackId={setFeedBackId}
        loading3={loading3}
        userFeedBack={userFeedBack}
        expandedRow = {expandedRow}
        setExpandedRow = {setExpandedRow}
      />
    </div>
  );
}

export default NewFeedbackManagement;
