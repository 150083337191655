import { bookingInterface } from "../../../../redux/reducers/actionInterfaces";
import { BOOKING_DETAILS_ACTIONS_X } from "../../actions/actionTypes";

interface bookingDeatilsI {
  bookingDetails: details[];
  bookingDetailsTop: details[];
  startRideLoader: false;
  availableVehicles: [];
  isRideStarted: boolean;
  bookingStatus: string;
  pendingPaymentStatus: any;
  bookingDetailsLoader: boolean;
}
export interface details {
  displayKey: string;
  value: string | number | Date;
}

const initialState: bookingDeatilsI = {
  bookingDetails: [],
  bookingDetailsTop: [],
  startRideLoader: false,
  availableVehicles: [],
  isRideStarted: false,
  bookingStatus: "",
  pendingPaymentStatus: "",
  bookingDetailsLoader: false,
};

const bookingDetailsReducerX = (
  state = initialState,
  action: bookingInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_REQUESTED:
      return {
        ...state,
        bookingDetailsLoader: true,
        bookingDetails: [],
        bookingDetailsTop: [],
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_SUCCESS:
      return {
        ...state,
        bookingDetails: payload?.bookingDetails,
        bookingStatus: payload?.status,
        bookingDetailsLoader: false,
        bookingDetailsTop: payload?.bookingDetailsTop,
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_FAILED:
      return {
        ...state,
        bookingDetailsLoader: false,
        bookingDetails: [],
        bookingDetailsTop: [],
      };

    case BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_STATUS_X_REQUESTED:
      return {
        ...state,
        isRideStarted: false,
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_STATUS_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    case BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_REQUESTED:
      return {
        ...state,
        startRideLoader: true,
        isRideStarted: false,
      };

    case BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_SUCCESS:
      return {
        ...state,
        startRideLoader: false,
        isRideStarted: true,
      };

    case BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_FAILED:
      return {
        ...state,
        startRideLoader: false,
        isRideStarted: false,
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_AVAILABLE_VEHICLE_LIST_REQUESTED:
      return { ...state, availableVehicles: [] };
    case BOOKING_DETAILS_ACTIONS_X.GET_AVAILABLE_VEHICLE_LIST_SUCCESS:
      return { ...state, availableVehicles: payload.vehicles };
    case BOOKING_DETAILS_ACTIONS_X.GET_AVAILABLE_VEHICLE_LIST_FAILED:
      return { ...state, availableVehicles: [] };

    case BOOKING_DETAILS_ACTIONS_X.GET_PENDING_PAYMENT_STATUS_X_REQUESTED:
      return {
        ...state,
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_PENDING_PAYMENT_STATUS_X_SUCCESS:
      return {
        ...state,
        pendingPaymentStatus: payload.paymentPending,
      };
    case BOOKING_DETAILS_ACTIONS_X.GET_PENDING_PAYMENT_STATUS_X_FAILED:
      return {
        ...state,
      };

    case BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_REQUESTED:
      return {
        ...state,
      };
    case BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    case BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_FAILED:
      return {
        ...state,
      };
    case BOOKING_DETAILS_ACTIONS_X.CLOSE_BOOKING_X_REQUESTED:
      return {
        ...state,
      };
    case BOOKING_DETAILS_ACTIONS_X.CLOSE_BOOKING_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    default:
      return { ...state };
  }
};
export default bookingDetailsReducerX;
