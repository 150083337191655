import Cookies from "js-cookie";
import { getCookieData } from "../../../config/filterStorage";
import { CookieTypes } from "../../../constants/enum";
import { NEW_AUTH_ACTIONS } from "../../actions/actionTypes";
import { CommonInterface } from "../actionInterfaces";
import { GenericObject } from "../../../rentals-src/constants/constants";

interface initialStateInterface {
  loginLoader: boolean;
  userData: any;
  isAuthenticated: boolean;
  isHigherAuth: boolean;
  higherAuthDetails: any;
  isFreedoX: boolean;
  isUserDataSet: boolean;

  forgotPasswordLoader: boolean;
  isForgotPasswordLinkSent: boolean;
  setPasswordLoader: boolean;
  unlockAccountLoader: boolean;
  token: string;
  refreshToken: string;
  ExpireTime: string;
  xKey: string;

  changePasswordLoader: boolean;
  isPasswordChanged: boolean;

  linkVerifyLoader: boolean;
  isLinkVerified: boolean;
  linkVerificationFailed: boolean;
  linkVerifyData: GenericObject;
  isAccountUnlockLinkSent: boolean;
  isNewPasswordSet: boolean;
  isLoggedIn: boolean;

  resendOtp: boolean;
  userName: any;
  otpId: any;
  authorizeModel: any;
  unlockData: GenericObject;
}

const initialState: initialStateInterface = {
  loginLoader: false,
  userData: { authorizedBranches: [] },
  isAuthenticated: false,
  isHigherAuth: false,
  higherAuthDetails: null,
  isFreedoX: false,
  isUserDataSet: false,

  forgotPasswordLoader: false,
  setPasswordLoader: false,
  unlockAccountLoader: false,
  isAccountUnlockLinkSent: false,
  token: "",
  refreshToken: "",
  ExpireTime: "",
  xKey: "",

  changePasswordLoader: false,
  isPasswordChanged: false,
  linkVerifyLoader: false,
  isLinkVerified: false,
  linkVerifyData: {},
  isNewPasswordSet: false,
  isForgotPasswordLinkSent: false,
  linkVerificationFailed: false,
  isLoggedIn: false,

  resendOtp: false,

  userName: "",
  otpId: "",
  authorizeModel: [],
  unlockData: {},
};

const getManipulatedUserData = (userData: any) => {
  const updatedUserData = { ...userData };
  let authorizedDrawersArray: any[] = [];

  updatedUserData.authorizedModules = updatedUserData.authorizedModules.filter(
    (item: any) => {
      if (item?.module?.includes("DRAWER_TABS"))
        authorizedDrawersArray = item?.functionalities;
      return item?.module !== "DRAWER_TABS";
    }
  );

  const authorizedDrawersObj = authorizedDrawersArray.reduce(
    (accum, moduleName) => ({ ...accum, [moduleName]: true }),
    {}
  );

  return { ...updatedUserData, authorizedDrawers: authorizedDrawersObj };
};

const newAuthReducer = (state = initialState, action: CommonInterface) => {
  const { type, payload } = action;
  switch (type) {
    case NEW_AUTH_ACTIONS.NEW_USER_LOGIN_REQUESTED:
      return {
        ...state,
        loginLoader: true,
        userData: {},
        isAuthenticated: false,
        isAccountUnlockLinkSent: false,
      };
    case NEW_AUTH_ACTIONS.NEW_USER_LOGIN_SUCCEEDED:
      let obj = { ...state };
      obj["isHigherAuth"] = payload?.userData?.highPriority;
      obj["userName"] = payload?.userData?.userName;

      // populate in cookies
      Cookies.set(
        CookieTypes.TOKEN,
        JSON.stringify({
          token: payload?.userData?.accessToken,
          refreshToken: payload?.userData?.refreshToken,
          ExpireTime: payload?.userData?.expiry,
          xKey: payload?.userData?.["xKey"],
        }),
        { expires: 365 }
      );

      const cookieToken = getCookieData(CookieTypes.TOKEN).then((data: any) => {
        const parsedTokens = JSON.parse(data || "");
      });

      if (!payload?.userData?.highPriority) {
        let obj1 = {
          loginLoader: false,
          userData: payload?.userData ?? {},
          token: payload?.userData?.accessToken,
          refreshToken: payload?.userData?.refreshToken,
          ExpireTime: payload?.userData?.expiry,
          xKey: payload?.userData?.["xKey"],
          isAuthenticated: true,
          isLoggedIn: true,
        };
        obj = { ...obj, ...obj1 };
      } else {
        let obj1 = {
          loginLoader: false,
          userData: payload?.userData,
          otpId: payload?.userData?.otpId,
        };

        obj = { ...obj, ...obj1 };
      }
      return obj;

    case NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_REQUESTED:
      return {
        ...state,
        loginLoader: true,
        userData: {},
        isAuthenticated: false,
        isLoggedIn: false,
      };
    case NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_SUCCEEDED:
      // populate in cookies
      Cookies.set(
        CookieTypes.TOKEN,
        JSON.stringify({
          token: payload?.userData?.accessToken,
          refreshToken: payload?.userData?.refreshToken,
          ExpireTime: payload?.userData?.expiry,
          xKey: payload?.userData?.["xKey"],
        }),
        { expires: 365 }
      );

      return {
        ...state,
        loginLoader: false,
        userData: payload?.userData ?? {},
        token: payload?.userData?.accessToken,
        refreshToken: payload?.userData?.refreshToken,
        ExpireTime: payload?.userData?.expiry,
        xKey: payload?.userData?.["xKey"],
        isAuthenticated: true,
        higherAuthDetails: null,
        isLoggedIn: true,
      };

    case NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_FAILED:
      return {
        ...state,
        loginLoader: false,
      };

    case NEW_AUTH_ACTIONS.SET_USER_NEW_DATA:
      return {
        ...state,
        userData: payload?.userData ?? {},
        isAuthenticated: true,
        isUserDataSet: true,
      };

    case NEW_AUTH_ACTIONS.SET_USER_PROFILE_DATA:
      let tokenData = Cookies.get(CookieTypes.TOKEN);
      let parsedToken = {};
      if (tokenData) {
        parsedToken = JSON.parse(tokenData);
      }
      const manipulatedUserData = getManipulatedUserData(payload?.userData);
      return {
        ...state,
        userData: { ...state.userData, ...manipulatedUserData },
        isAuthenticated: true,
        isUserDataSet: true,
        ...parsedToken,
      };

    case NEW_AUTH_ACTIONS.USER_PROFILE_FAILED:
      let tokenInfo = Cookies.get(CookieTypes.TOKEN);
      let parsedTokenInfo = {};
      if (tokenInfo) {
        parsedTokenInfo = JSON.parse(tokenInfo);
      }
      return {
        ...initialState,
      };

    case NEW_AUTH_ACTIONS.SET_USER_FREEDO_X_DATA:
      return {
        ...state,
        // userData: payload?.userData ?? {},
        isAuthenticated: true,
        isFreedoX: true,
      };
    case NEW_AUTH_ACTIONS.SET_USER_FREEDO_RENTALS_DATA:
      return {
        ...state,
        // userData: payload?.userData ?? {},
        isAuthenticated: true,
        isFreedoX: false,
      };
    case NEW_AUTH_ACTIONS.CHECK_FREEDO_DOMAIN: {
      return {
        ...state,
        // userData: payload?.userData ?? {},
        // isAuthenticated: true,
        isFreedoX: payload,
      };
    }
    case NEW_AUTH_ACTIONS.NEW_USER_LOGIN_FAILED:
      return {
        ...state,
        loginLoader: false,
        isAuthenticated: false,
      };

    case NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_REQUESTED:
      return {
        ...state,
        ...initialState,
      };
    case NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    case NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_FAILED:
      return {
        ...state,
      };
    case NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        forgotPasswordLoader: true,
      };
    case NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        forgotPasswordLoader: false,
        isForgotPasswordLinkSent: true,
      };

    case NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordLoader: false,
      };
    case NEW_AUTH_ACTIONS.USER_SET_PASSWORD_REQUESTED:
      return {
        ...state,
        setPasswordLoader: true,
        isNewPasswordSet: false,
      };
    case NEW_AUTH_ACTIONS.USER_SET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        setPasswordLoader: false,
        isNewPasswordSet: true,
      };
    case NEW_AUTH_ACTIONS.USER_SET_PASSWORD_FAILED:
      return {
        ...state,
        setPasswordLoader: false,
        isNewPasswordSet: false,
      };
    case NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_REQUESTED:
      return {
        ...state,
        unlockAccountLoader: true,

        isAccountUnlockLinkSent: false,
      };
    case NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_SUCCEEDEED:
      return {
        ...state,
        unlockAccountLoader: false,
        isAccountUnlockLinkSent: true,
        unlockData: payload,
      };
    case NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_FAILED:
      return {
        ...state,
        unlockAccountLoader: false,
        unlockData: {},
        isAccountUnlockLinkSent: false,
      };

    case NEW_AUTH_ACTIONS.CHANGE_PASSWORD_REQUESTED:
      return {
        ...state,
        changePasswordLoader: true,
        isPasswordChanged: false,
      };
    case NEW_AUTH_ACTIONS.CHANGE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        changePasswordLoader: false,
        isPasswordChanged: true,
      };
    case NEW_AUTH_ACTIONS.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        changePasswordLoader: false,
        isPasswordChanged: false,
      };
    case NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_REQUESTED:
      return {
        ...state,
        linkVerifyLoader: true,
        isLinkVerified: false,
        linkVerifyData: {},
        isNewPasswordSet: false,
      };

    case NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_SUCCEEDED:
      return {
        ...state,
        linkVerifyLoader: false,
        isLinkVerified: true,
        linkVerifyData: payload,
      };
    case NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_FAILED:
      return {
        ...state,
        linkVerifyLoader: false,
        isLinkVerified: false,
        linkVerificationFailed: true,
        linkVerifyData: {},
      };
    case NEW_AUTH_ACTIONS.IS_USER_CHANGED_PASSWORD:
      return {
        ...state,
        isPasswordChanged: false,
      };

    case NEW_AUTH_ACTIONS.CHECK_ISLOGIN:
      return {
        ...state,
        isLoggedIn: false,
      };

    case NEW_AUTH_ACTIONS.RESEND_OTP_REQUESTED:
      return {
        ...state,
        resendOtp: false,
      };
    case NEW_AUTH_ACTIONS.RESEND_OTP_FAILED:
      return {
        ...state,
        resendOtp: false,
      };
    case NEW_AUTH_ACTIONS.RESEND_OTP_SUCCEEDED:
      return {
        ...state,
        resendOtp: true,
        otpId: payload,
      };
    case NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_REQUESTED:
      return {
        ...state,
      };
    case NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_SUCCESS:
      return {
        ...state,
        authorizeModel: payload,
      };
    case NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_FAILED:
      return {
        ...state,
      };
    case NEW_AUTH_ACTIONS.SET_AUTH_REDUCER_KEY:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

export default newAuthReducer;
