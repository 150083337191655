import { put, call, delay, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import customerManagementService from "../services/customerManagement";

import * as actionTypes from "../actions/actionTypes";
import { handleMessage } from "../../../utils/helper";
import {
  BlockUnblockCustomerPayload,
  BookingStatusEnum,
  GenericObject,
} from "../../constants/constants";
import {
  downloadExcelSheet,
  DownloadExcelSheetColumns,
  generateRandomString,
  getDateTimeFromTimeStamp,
  getDeliveryPickup,
  toPascalCase,
} from "../../utils/helper";
import { actionInterface } from "../../../redux/reducers/actionInterfaces";
import rentalEn from "../../locale/rental-en.json";
const { bookingDetails, errorMessages, successMessage } = rentalEn;

// function to download excel sheet
const bookingListDownload = (bookings: GenericObject[]) => {
  // modify data from api for download excel
  const modifiedArray: GenericObject[] = bookings?.map(
    (booking: GenericObject) => {
      let modifiedObject: GenericObject = {
        customerName: `${booking?.customer?.firstName} ${booking?.customer?.lastName}`,
        bookingId: booking?.id,
        bookingNumber: booking?.bookingNumber,
        vehicleModel: booking?.modelDisplayName,
        city: booking?.cityDisplayName,
        hub: booking?.branchDisplayName,
        startDate: getDateTimeFromTimeStamp(booking?.startDate, "date"),
        endDate: getDateTimeFromTimeStamp(booking?.endDate, "date"),
        deliveryPickup: getDeliveryPickup(booking),
        status: toPascalCase(booking?.status),
      };
      return modifiedObject;
    }
  );

  // columns for excel headers
  const excelColumn: DownloadExcelSheetColumns[] = [
    {
      header: bookingDetails?.customerName,
      key: "customerName",
      width: 15,
    },
    {
      header: bookingDetails?.bookingId,
      key: "bookingId",
      width: 18,
    },
    {
      header: bookingDetails?.bookingNumber,
      key: "bookingNumber",
      width: 18,
    },
    {
      header: bookingDetails?.vehicleModel,
      key: "vehicleModel",
      width :18
    },
    {
      header: bookingDetails?.city,
      key: "city",
      width: 15,
    },
    {
      header: bookingDetails?.hub,
      key: "hub",
      width: 15,
    },
    {
      header: bookingDetails?.startDate,
      key: "startDate",
      width: 15,
    },
    {
      header: bookingDetails?.endDate,
      key: "endDate",
      width: 15,
    },
    {
      header: bookingDetails?.deliveryPickup,
      key: "deliveryPickup",
      width: 15,
    },
    {
      header: bookingDetails?.status,
      key: "status",
      width: 13,
    },
  ];

  // function to download excel sheet
  downloadExcelSheet(excelColumn, modifiedArray, "Booking list", "BookingList");
};

// create user
function* registerCustomer(action: any): any {
  try {
    const response = yield call(
      customerManagementService.registerCustomer,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_SUCCESS,
        payload: response?.data?.data,
      });
    } else if (
      response.status === 422 ||
      response.errorCode === "USER_EXISTS"
    ) {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_FAILED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_FAILED,
    });
  }
}

// Verify create customer Otp
function* verifyCustomerOTP(action: any): any {
  try {
    const verifyPayload = {
      data: action.payload.data,
    };
    const customerId = action.payload.data?.customerId;
    // const navigate = useNavigate();
    const response = yield call(
      customerManagementService.verifyCustomerOTP,
      // action.payload
      verifyPayload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_SUCCESS,
        payload: response?.data?.data,
      });
      yield delay(1000);
      const randomString = generateRandomString(32, "#aA");
      action.payload.navigate(`/customer-management/${customerId}`, {
        replace: true,
      });

      yield delay(3000);
      // this api does not give success message, so adding below line

      toastr.success("", "Customer added successfully");
      // toastr.success("", handleMessage(response));
    } else if (response.status === 422) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .VERIFY_CUSTOMER_OTP_FAILED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .VERIFY_CUSTOMER_OTP_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_FAILED,
    });
  }
}

// resend customer register otp
function* resendCustomerOTP(action: any): any {
  try {
    const response = yield call(
      customerManagementService.resendCustomerOTP,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .RESEND_CUSTOMER_OTP_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .RESEND_CUSTOMER_OTP_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_FAILED,
    });
  }
}

// search if the user exists or not
function* searchCustomerDetails(action: any): any {
  try { 
    let searchCustomerService = {
      mobile_number: action.payload.mobile_number,
    };
    const response = yield call(
      customerManagementService.searchCustomerService,
      searchCustomerService
    ); 

    const userDetails = response?.data?.data.searchResult;
    if (
      (response.status === 200 || response.status === 201) &&
      userDetails.length > 0
    ) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .SEARCH_CUSTOMER_DETAILS_SUCCESS,
        payload: userDetails?.[0],
      });
      const randomString = generateRandomString(32, "#aA");

      action.payload.navigate(`/customer-management/${userDetails[0]?.id}`, {
        replace: true,
        state: { randomString: randomString },
      });
    } else {
      toastr.error("", "User not registered yet");
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .SEARCH_CUSTOMER_DETAILS_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// customer dashboard Stats
function* getDashboardCustomerStats(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getCustomerDashBoardBookingStats,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_DASHBOARD_STATS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_DASHBOARD_STATS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
        .GET_CUSTOMER_DASHBOARD_STATS_FAILED,
    });
    return error;
  }
}

// get customer profile data
function* getCustomerProfileData(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getProfileDataService,
      action.payload
    );

    const customerProfileData = response?.data?.data;
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_PROFILE_DATA_SUCCESS,
        payload: customerProfileData,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_PROFILE_DATA_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// get customer profile data
function* getEmergencyContactForCustomer(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getCustomerEmergencyContactService,
      action.payload
    );

    const emergencyContact = response?.data?.data.emergencyContacts;
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS,
        payload: emergencyContact,
      });
      // send OTP with updated/ new emergency id
      if (action.payload?.nextAction === "SEND_OTP") {
        let sendOtpPayload = {
          id: emergencyContact[0].id,
          customerId: emergencyContact[0].customerId,
        };
        // send otp for add emergency contact
        yield put({
          type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
            .SEND_EMERGENCY_OTP_REQUESTED,
          payload: sendOtpPayload,
        });

        /*  let emergencyContactData =
          emergencyContact.length > 0 ? emergencyContact[0] : {};
        // Send OTP
        yield put({
          type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
            .SEND_EMERGENCY_OTP_REQUESTED,
          payload: emergencyContactData,
        }); */
      }
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// get customer KYC trust score
function* getCustomerKyctrustScore(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getCustomerKycTrustScoresService,
      action.payload
    );

    const trustScoreData = response?.data?.data;
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_KYC_TRUST_SCORE_SUCCESS,
        payload: trustScoreData,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_KYC_TRUST_SCORE_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// add emergency contact
function* addEmergencyContactForCustomer(action: any): any {
  try {
    let emergencyService = action.payload.emergencyId
      ? customerManagementService.updateEmergencyContactService
      : customerManagementService.addEmergencyContactService;

    const response = yield call(emergencyService, action.payload);

    // success
    if (response.status === 200 || response.status === 201) {
      const emergencyContactData = response?.data?.data;

      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS,
        payload: emergencyContactData,
      });

      /* SAVE / UPDATE EMERGENCY USER LOGIC */
      if (action.payload.emergencyId) {
        // send otp for add emergency contact

        // refresh emergency detail for update success
        // closeEmergencyModal
        let getEmergencyPayload = {};
        if (
          action.payload.existingMobileNumber ==
            action.payload.data.mobileNumber &&
          action.payload.emergencyStatus !== "UNVERIFIED"
        ) {
          // only update & no otp
          getEmergencyPayload = {
            id: action.payload.customerId,
            nextAction: "",
          };
          yield put({
            type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
              .RESET_EMERGENCY_CONTACT_MODAL,
          });
        } else {
          // send otp as phone number did not match
          getEmergencyPayload = {
            id: action.payload.customerId,
            nextAction: "SEND_OTP",
          };
        }

        yield put({
          type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
            .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
          payload: getEmergencyPayload,
        });
      } else {
        // After save emergency user
        let getEmergencyPayloadOnAdd = {
          id: action.payload.customerId,
        };
        yield put({
          type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
            .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
          payload: getEmergencyPayloadOnAdd,
        });
        let sendOtpPayload = {
          id: emergencyContactData.id,
          customerId: emergencyContactData.customerId,
        };
        yield put({
          type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
            .SEND_EMERGENCY_OTP_REQUESTED,
          payload: sendOtpPayload,
        });
      }
    } else {
      toastr.error("", handleMessage(response));
      
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// Verify Emergency contact Otp
function* verifyEmergencyCustomerOTP(action: any): any {
  try {
    const verifyPayload = {
      data: action.payload.data,
    };
    // const navigate = useNavigate();
    const response = yield call(
      customerManagementService.verifyEmergencyOTPService,
      // action.payload
      verifyPayload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .VERIFY_EMERGENCY_OTP_SUCCESS,
        payload: response?.data?.data,
      });
      toastr.success("", handleMessage(response));

      // refresh emergency details value
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
        payload: { id: action.payload.customerId },
      });
    } else if (response.status === 422) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .VERIFY_EMERGENCY_OTP_FAILED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .VERIFY_EMERGENCY_OTP_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_EMERGENCY_OTP_FAILED,
    });
  }
}

// send emergency contact otp
function* sendEmergencyOtp(action: any): any {
  try {
    const response = yield call(
      customerManagementService.sentEmergencyOtpService,
      action.payload
    );

    const emergencyOtpData = response?.data?.data;
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .SEND_EMERGENCY_OTP_SUCCESS,
        payload: emergencyOtpData,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEND_EMERGENCY_OTP_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// resend customer emergency contact otp
function* resendEmergencyContactOTP(action: any): any {
  try {
    const response = yield call(
      customerManagementService.resendEmergencyContactOtpService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .RESEND_EMERGENCY_OTP_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .RESEND_EMERGENCY_OTP_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_EMERGENCY_OTP_FAILED,
    });
  }
}

// block customer
function* blockCustomerSaga(action: any): any {
  try {
    action.payload.type = BlockUnblockCustomerPayload.BLOCK;

    const response = yield call(
      customerManagementService.blockUnblockCustomerService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_SUCCESS,
      });

      toastr.success("", handleMessage(response));
      // refresh customer details
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_PROFILE_DATA_REQUESTED,
        payload: action.payload,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (ex) {
    return ex;
  }
}

// unblock customer
function* unblockCustomerSaga(action: any): any {
  try {
    action.payload.type = BlockUnblockCustomerPayload.UNBLOCK;
    const response = yield call(
      customerManagementService.blockUnblockCustomerService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_SUCCESS,
      });
      toastr.success("", handleMessage(response));
      // refresh customer details
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_PROFILE_DATA_REQUESTED,
        payload: action.payload,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (ex) {
    return ex;
  }
}

// delete customer
function* deleteCustomerSaga(action: any): any {
  try {
    const response = yield call(
      customerManagementService.deleteCustomerService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_SUCCESS,
      });
      toastr.success("", handleMessage(response));

      // navigate to customer Overview page
      action.payload.navigate("/customer-management", { replace: true });

      // refresh customer details
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_PROFILE_DATA_REQUESTED,
        payload: action.payload,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (ex) {
    return ex;
  }
}

// customer dashboard Stats
function* getCustomerBookingStats(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getCustomerBookingStats,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKING_STATS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKING_STATS_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

// customer booking list
function* getCustomerBookingList(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getBookingList,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKING_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKING_LIST_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

// get individual kyc details
function* getIndividualKycDetails(action: any): any {
  try {
    const response = yield call(
      customerManagementService.getIndividualKycDetailService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_INIDIVIDUAL_KYC_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_INIDIVIDUAL_KYC_DETAILS_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

// verify customer kyc manually by admin
function* manualVerifyCustomerKyc(action: any): any {
  try {
    const response = yield call(
      customerManagementService.verifyCustomerKycManualService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      const trustScorePayload = {
        id: action.payload.customerId,
      };
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .MANUALLY_VERIFY_KYC_SUCCESS,
      });

      // refresh trust score
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED,
        payload: trustScorePayload,
      });
    } else {
      toastr.warning("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .MANUALLY_VERIFY_KYC_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_FAILED,
    });
    return error;
  }
}

// delete customer kyc by admin
function* deleteCustomerKyc(action: any): any {
  try {
    const response = yield call(
      customerManagementService.deleteCustomerKycService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      const trustScorePayload = {
        id: action.payload.customerId,
      };

      // reset delete loader
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .DELETE_CUSTOMER_KYC_SUCCESS,
      });

      // refresh trust score
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED,
        payload: trustScorePayload,
      });
    } else {
      toastr.warning("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .DELETE_CUSTOMER_KYC_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

// unverify customer kyc by admin
function* unverifyCustomerKyc(action: any): any {
  try {
    const response = yield call(
      customerManagementService.unverifyCustomerKycService,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      const trustScorePayload = {
        id: action.payload.customerId,
      };
      // reset loader
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .UNVERIFY_CUSTOMER_KYC_SUCCESS,
      });
      // re-fetch kyc detail

      // refresh trust score
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED,
        payload: trustScorePayload,
      });
    } else {
      toastr.warning("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .UNVERIFY_CUSTOMER_KYC_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

// function to get bookings list data for download
function* downloadCustomerBookingList(action: actionInterface): any {
  try {
    const response = yield call(
      customerManagementService.getBookingListForDownload,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      const { bookings = [] } = response?.data?.data;

      if (bookings.length) {
        bookingListDownload(bookings);
        toastr.success("", successMessage.excelDownloadSuccess);
      } else {
        toastr.warning("", errorMessages.emptyExcelData);
      }

      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_SUCCESS,
      });
    } else {
      toastr.warning("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_FAILED,
      });
    }
  } catch (error) {
    return error;
  }
}

function* customerManagementSaga() {
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_REQUESTED,
    registerCustomer
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_REQUESTED,
    verifyCustomerOTP
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_REQUESTED,
    resendCustomerOTP
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEARCH_CUSTOMER_DETAILS_REQUESTED,
    searchCustomerDetails
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.GET_CUSTOMER_PROFILE_DATA_REQUESTED,
    getCustomerProfileData
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_DASHBOARD_STATS_REQUESTED,
    getDashboardCustomerStats
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
    getEmergencyContactForCustomer
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED,
    getCustomerKyctrustScore
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
    addEmergencyContactForCustomer
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_REQUESTED,
    blockCustomerSaga
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_REQUESTED,
    unblockCustomerSaga
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_REQUESTED,
    deleteCustomerSaga
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEND_EMERGENCY_OTP_REQUESTED,
    sendEmergencyOtp
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_EMERGENCY_OTP_REQUESTED,
    verifyEmergencyCustomerOTP
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_EMERGENCY_OTP_REQUESTED,
    resendEmergencyContactOTP
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_STATS_REQUESTED,
    getCustomerBookingStats
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.GET_CUSTOMER_BOOKING_LIST_REQUESTED,
    getCustomerBookingList
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_INIDIVIDUAL_KYC_DETAILS_REQUESTED,
    getIndividualKycDetails
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_REQUESTED,
    manualVerifyCustomerKyc
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_KYC_REQUESTED,
    deleteCustomerKyc
  );
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNVERIFY_CUSTOMER_KYC_REQUESTED,
    unverifyCustomerKyc
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_REQUESTED,
    downloadCustomerBookingList
  );
}

export default customerManagementSaga;
