import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "..";
import en from "../../locale/en.json";
import * as actionTypes from "../actions/actionTypes";
import { ToastType } from "../../constants/userActions";
import reportServices from "../../services/reportServices";

import paymentServices from "../../services/paymentServices";

import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utils/helper";
import { reportsGQLService } from "../../services";

function* downloadUserReports(action: any): any {
  try {
    const data = yield call(
      reportsGQLService.getGqlReportsService,
      action.payload
    );
    if (data) {
      yield put({
        type: actionTypes.GET_REPORTS.DOWNLOAD_USER_REPORTS_SUCCESS,
        payload: {
          allData: data?.data?.getReports?.data?.[0] ?? "",
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.GET_REPORTS.DOWNLOAD_USER_REPORTS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(""));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_USER_REPORTS_FAILED,
    });
  }
}

function* downloadBookingReports(action: any): any {
  const data = yield call(reportServices.getBookingReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_SUCCESS,
      payload: {
        allData: data?.data?.replace(/"/g, ""),
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_FAILED,
    });
  }
}

function* downloadPaymentReports(action: any): any {
  const data = yield call(reportServices.getPaymentReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_SUCCESS,
      payload: {
        allData: data?.data?.replace(/"/g, ""),
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_FAILED,
    });
  }
}

function* downloadPaymentReconciliationReports(action: any): any {
  const data = yield call(
    reportServices.getPaymentReconciliationReports,
    action.payload
  );
  if (data) {
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_SUCCESS,
      payload: {
        // allData: data?.data?.replace(/"/g, ""),
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_FAILED,
    });
  }
}
function* downloadGenericReports(action: any): any {
  const request =
    action.type ===
    actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_KEYS_REQUESTED;
  const data = yield call(reportsGQLService.downloadGqlGenericReportsService, {
    payload: action.payload,
    type: request ? "request" : "receive",
  });
  if (data) {
    const obj = { ...data };

    if (request) {
      obj["genericReportKeys"] = data?.data?.getGenericReports ?? {};
    } else {
      obj["genericReportDownloadData"] =
        data?.data?.getGenericReports?.data ?? [];
    }

    yield put({
      type: request
        ? actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_KEYS_SUCCESS
        : actionTypes.GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_SUCCESS,
      payload: {
        ...obj,
      },
    });
    !request &&
      toastr.success(
        "Success",
        "Report generated successfully, Please click on Download"
      );
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_FAILED,
    });
  }
}

function* downloadRevenueReports(action: any): any {
  const data = yield call(reportServices.getPendingReports, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_SUCCESS,
      payload: {
        allData: data?.data?.replace(/"/g, ""),
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_FAILED,
    });
  }
}

function* reportDownloadSaga() {
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_USER_REPORTS_REQUESTED,
    downloadUserReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_REQUESTED,
    downloadBookingReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_REQUESTED,
    downloadPaymentReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_REQUESTED,
    downloadPaymentReconciliationReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_REQUESTED,
    downloadRevenueReports
  );
  yield takeLatest(
    actionTypes.GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_REQUESTED,
    downloadGenericReports
  );

  yield takeLatest(
    actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_KEYS_REQUESTED,
    downloadGenericReports
  );

}

export default reportDownloadSaga;
