// This is the css style objects that will be provided to "sx" prop of the mui components.

import { colors } from "../../../../themes/colors";

export const StyleObject: any = {
  // create surge
  createSurge_wrapperBox: {
    minHeight: "85dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  createSurge_tabView_wrapperBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  createSurge_bottomButtonView_wrapperBox: {
    display: "flex",
    flexDirection: "row",
    px: 1,
    py: 2,
    m: 1,
  },
  // surge settings
  surgeSettings_wrapperBox: {
    m: 1,
  },
  surgeSettings_city_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    px: 2,
    py: 2,
    m: 1,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeSettings_warehouse_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    my: 3,
  },
  surgeSettings_warehouse_box1: {
    display: "flex",
    // flexWrap: "wrap",
    flexDirection: "row",
    gap: "40px",
    alignItems: "center",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeSettings_warehouse_box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  surgeSettings_wareHouse_box2: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    px: 2,
  },
  surgeSettings_wareHouse_box2_In: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "5px",
  },
  surgeSettings_vehicleModel_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    my: 3,
  },
  surgeSettings_vehicleModel_box1: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: "40px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeSettings_vehicleModel_box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  surgeSettings_vehicleModel_box2: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    px: 2,
  },

  surgeSettings_vehicleModel_box2_In: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "5px",
  },

  // // surge configuration
  surgeConfiguration_wrapperBox: {
    m: 1,
  },
  surgeConfiguration_surgePackage_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    px: 2,
    py: 2,
    mx: 1,
    my: 3,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeConfiguration_surgePackage_box1: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: "40px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeConfiguration_surgePackage_box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  surgeConfiguration_surgePackage_box2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "5px",
    px: 2,
  },
  surgeConfiguration_surgeDateType_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    px: 2,
    py: 2,
    mx: 1,
    my: 3,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeConfiguration_surgeDateType_box1: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
    px: 2,
  },
  surgeConfiguration_surgeDateType_box2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeConfiguration_surgeDateType_weekdaysBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "15px",
    px: 2,
    pt: 2,
  },
  surgeConfiguration_surgeDateType_specificBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "350px",
    zIndex: 99,
    px: 2,
  },
  surgeConfiguration_surgeType_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    px: 2,
    py: 2,
    mx: 1,
    my: 3,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeConfiguration_surgeType_box1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeConfiguration_surgeType_incrementBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
    px: 2,
  },
  surgeConfiguration_surgeStatus_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    px: 2,
    py: 2,
    mx: 1,
    my: 3,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeConfiguration_surgeStatus_box2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
    px: 2,
  },
  surgeConfiguration_surgeStatus_box1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  surgeConfiguration_surgeName_wrapperCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "15px",
    px: 2,
    py: 2,
    mx: 1,
    my: 3,
    borderRadius: "8px",
    boxShadow: 1,
    backgroundColor: "#f6f6f6",
  },
  surgeConfiguration_surgeName_box1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#01353D",
    padding: "10px 5px",
    borderRadius: "8px",
  },
  truncateText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  viewSurge_chip: {
    background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
    "&:hover": {
      background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      color: "white",
      border: "none",
    },
    "&:active": {
      background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
      color: "white",
    },
    "&.Mui-disabled": {
      background: "#C6C6C6",
      color: "#000",
    },
  },
  viewSurge_iconButton: {
    background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
    height: "25px",
    width: "40px",
    borderRadius: "20px",
    color: "white",
    "&:hover": {
      background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      color: "white",
      border: "none",
    },
    "&:active": {
      background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
      color: "white",
    },
  },
  viewSurge_wrapperBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  viewSurge_topViewBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    py: 1,
  },
  viewSurge_topLeftViewBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  viewSurge_cardContent_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  viewSurge_truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  viewSurge_gridStyle1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  viewSurge_gridStyle2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  viewSurge_card: {
    borderRadius: "5px",
    my: 1,
    py: 1,
  },
  createSurge_tabStyle1: {
    minWidth: "150px",
    padding: "5px 30px",
    borderRadius: "12px 0 0 12px",
  },
  createSurge_tabStyle2: {
    minWidth: "150px",
    padding: "5px 30px",
    borderRadius: "0 12px 12px 0",
  },
  createSurge_loaderStyle: {
    my: 25,
    alignSelf: "center",
  },
  selectAllText: {
    color: colors.theme_blue,
    fontSize: 13,
    fontWeight: "500",
    cursor: "pointer",
  },
  viewMoreText: {
    color: colors.theme_blue,
    fontSize: 13,
    fontWeight: "300",
    cursor: "pointer",
    textDecoration: "underline",
  },
};
