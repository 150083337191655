import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import en from "../../../../locale/en.json";
import { RootState } from "../../../../redux/reducers";
import HelmetBadge from "../../../../sharedComponents/templates/MultiStepForm/HelmetBadge";
import TotalCharge from "../../../../sharedComponents/templates/MultiStepForm/TotalCharge";
import SubscriptionItem from "../../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import { colors } from "../../../../themes/colors";
import {
  HELMETS_ACTIONS,
  HELMET_DISPLAY_NAME,
  HELMET_ICONS,
  complementaryHelmet,
  planTypeKeys,
  tyesOfModeOfDelivery,
  typographyConstants,
} from "../../../constants/constants";
import { BenefitsCard, NoIcon, YesIcon } from "../../../constants/exportImages";
import { CheckoutInterface } from "../../../interfaces/manualBookingInterfaces";
import rentalEn from "../../../locale/rental-en.json";
import {
  getArraySum,
  getFormattedINR,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../utils/helper";
import { PlanType } from "./PlansCard";
import styles from "./section.module.css";

const Checkout = ({
  fields,
  setFields,
  errorFields,
  setErrorFields,
  planType,
  callCalculateCharges,
  getDiscountTooltip,
  getPaymentBreakUp,
}: CheckoutInterface) => {
  const { modelDetails, charges, userDetails, loadingCalculateCharges } =
    useSelector((state: RootState) => state.newManualBookingReducer);

  const { availability } = modelDetails;
  const pricing = availability?.[0]?.pricing || {};

  const config = availability?.[0]?.config || {};
  let planBenefits: any = [];

  planBenefits = availability?.[0]?.pricing?.benefits?.map((benefit: any) => {
    return benefit?.type;
  });

  const handleChange = (field: any, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const extraHelmetHandler = (e: any, type: string, action: string) => {
    let helmetCountArray = Object.values(fields.helmets);
    let helmetCount = getArraySum(helmetCountArray);
    if (action === HELMETS_ACTIONS.INCREMENT) {
      if (helmetCount < 3) {
        setFields((prevState: any) => ({
          ...prevState,
          helmets: {
            ...prevState.helmets,
            [type]: prevState.helmets[type] + 1,
          },
        }));
        if (errorFields?.selectedHelmet) {
          setErrorFields((prevState: any) => ({
            ...prevState,
            selectedHelmet: "",
          }));
        }
      } else {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmet: "* Max 3 helmets can be added",
        }));
      }
    }
    if (action === HELMETS_ACTIONS.DECREMENT) {
      setFields((prevState: any) => ({
        ...prevState,
        helmets: {
          ...prevState.helmets,
          [type]: prevState.helmets[type]
            ? prevState.helmets[type] - 1
            : prevState.helmets[type],
        },
      }));
      if (errorFields?.selectedHelmet) {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmet: "",
        }));
      }
    }
  };

  const ExtraCharges = [
    {
      heading: rentalEn.manualBooking.excessKmCharges,
      value: pricing?.excessKmCharges,
      type: "km",
    },
    {
      heading: rentalEn.manualBooking.excessHourCharges,
      value: pricing?.excessHourCharges,
      type: "Hour",
    },
  ];

  //call calulate charges api
  useEffect(() => {
    const deliveryDetails: any = {
      type: fields?.modeOfDelivery,
      slot: fields?.selectedTimeSlot,
      ...(fields?.modeOfDelivery === tyesOfModeOfDelivery.CUSTOMER_LOCATION && {
        addressId: fields?.addressId,
      }),
    };

    const chargesProps: any = {
      modelName: fields?.selectedVehicle?.modelName,
      branchName: fields?.selectedBranch?.name,
      useCase: fields?.bookingType?.name,
      customerId: userDetails?.id,
      startDate: getTimestampAtStartOfDay(fields?.startDate),
      endDate: getTimestampAtEndOfDay(fields?.endDate),
      complementaryHelmet: fields?.complementaryHelmet,
      unlimitedSelected:
        fields?.selectedPlan?.key === planTypeKeys.UnlimitedPlan ? true : false,
      premiumSelected:
        fields?.selectedPlan?.key === planTypeKeys.PremiumPlan ? true : false,
      deliveryDetails: deliveryDetails,
      helmetSelection: fields?.helmets,
      couponCode: fields?.couponCode ? fields?.couponCode : undefined,
    };
    callCalculateCharges(chargesProps);
  }, [fields?.helmets, fields?.selectedPlan]);

  return (
    <Box className={styles.CheckoutBox}>
      <Stack gap="20px">
        {/* Complementary Helmet */}
        <Stack gap="2px">
          {planBenefits?.includes(complementaryHelmet.COMPLEMENTARY_HELMET) ? (
            <Box>
              <Typography variant={typographyConstants.HEADING}>
                {en.NewManualBooking.complemantaryHelmat}
              </Typography>

              <Box sx={{ display: "flex", gap: "12px", marginTop: "10px" }}>
                <Box
                  className={`${
                    fields?.complementaryHelmet === true
                      ? styles.yesNoIconsSelected
                      : styles.yesNoIcons
                  } `}
                  onClick={() => handleChange("complementaryHelmet", true)}
                >
                  <YesIcon
                    color={
                      fields?.complementaryHelmet === true ? colors.white : ""
                    }
                  />
                </Box>
                <Box
                  className={`${
                    fields?.complementaryHelmet === false
                      ? styles.yesNoIconsSelected
                      : styles.yesNoIcons
                  } `}
                  onClick={() => handleChange("complementaryHelmet", false)}
                >
                  <NoIcon
                    color={
                      fields?.complementaryHelmet === false ? colors.white : ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Stack>

        {/* Select Plan Type */}
        <Stack gap="2px">
          <Typography variant={typographyConstants.HEADING}>
            {en.NewManualBooking.SelectPlanType}
          </Typography>
          <Box sx={{ display: "flex", gap: "8px" }}>
            {planType?.map((plan: any, index: number) => {
              return (
                <PlanType
                  key={index}
                  plan={plan}
                  selectedPlan={fields?.selectedPlan}
                  onPlanSelect={() => handleChange("selectedPlan", plan)}
                />
              );
            })}
          </Box>
        </Stack>

        {/* Extra charge Card */}
        <Box className={styles.extraChargesCardMain}>
          <Box className={styles.extraCardChargesDetails}>
            {ExtraCharges?.map((extraCharges: any, index: number) => {
              return (
                <Stack>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={colors.tertiary_black}
                  >
                    {extraCharges?.heading}
                  </Typography>
                  <Typography
                    variant={typographyConstants.HEADING}
                    padding={"0px"}
                    color={colors.text_black}
                  >
                    {getFormattedINR(extraCharges?.value)}/{extraCharges?.type}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
          <img src={BenefitsCard} />
        </Box>

        {/* Additional helmet */}
        {config?.helmets?.length ? (
          <Stack gap="2px">
            <Typography variant={typographyConstants.HEADING}>
              {en.NewManualBooking.additionalHelmet}
            </Typography>
            <Box sx={{ display: "flex", gap: "15px" }}>
              {config?.helmets?.map((item: any) => {
                return (
                  <HelmetBadge
                    title={HELMET_DISPLAY_NAME[item?.type]}
                    icon={HELMET_ICONS[item.type]}
                    type={item.type}
                    clickHandler={extraHelmetHandler}
                    helmetTypeCount={fields.helmets[item.type]}
                  />
                );
              })}
            </Box>
            {errorFields?.selectedHelmet && (
              <Typography variant={typographyConstants.ERRORMESSAGE}>
                {errorFields?.selectedHelmet}
              </Typography>
            )}
          </Stack>
        ) : (
          <></>
        )}
      </Stack>

      {/* PAYMENT BREAKUP */}
      <Box>
        <Box sx={{ width: "46%" }}>
          <Typography variant={typographyConstants.HEADING}>
            {en.NewManualBooking.PaymentBreakup}
          </Typography>
          <Box className={`${styles.BoxColumn} ${styles.paymentBreakup}`}>
            {getPaymentBreakUp()?.paymentBreakup}
            {charges?.totalDiscount !== 0 && (
              <SubscriptionItem
                title={rentalEn?.global?.discount}
                showBorder={true}
                amount={`- ${getFormattedINR(charges?.totalDiscount)}`}
                loadingCharges={loadingCalculateCharges}
                amountColor={colors?.secondary_green}
                titleTooltip={getDiscountTooltip(
                  getPaymentBreakUp()?.discounts
                )}
              />
            )}

            <TotalCharge
              title={en.NewManualBooking.payableAmount}
              amount={getFormattedINR(
                charges?.totalAmount - charges?.totalDiscount
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Checkout;
