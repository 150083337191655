import { BASE_URL, customAxios, getApiUrl } from "../../../config";

// Creates a new surge using the provided payload.
const generateReport = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(payload?.type).generateReport}`;
    return customAxios.post(url, payload?.data).catch((e) => e);
  } catch (err) {
    return err;
  }
};

export default { generateReport };
