import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Navigate, useLocation } from "react-router-dom";
import { routesConstants } from "../../rentals-src/utils/RoutesConstants";
import { modules } from "../../constants/RBACModuleEnums";

interface Props {
  moduleKey?: string;
}
const AccessDenied = (props: Props) => {
  const location = useLocation();
  const { moduleKey } = props;

  // navigate to my account if dashboard access is not there
  if (
    location.pathname.includes("dashboard") &&
    moduleKey === modules.DASHBOARD
  ) {
    return <Navigate to={routesConstants.ACCOUNT_DETAILS} />;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ marginTop: "15%" }}
      flexDirection={"column"}
    >
      <NotInterestedIcon />
      <Typography textAlign={"center"}>Access Denied</Typography>
    </Box>
  );
};

export default AccessDenied;
