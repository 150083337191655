import {
  BRAND_ACTIONS,
  CITY_ACTIONS,
  COUNTRYDATA_ACTIONS,
  COUPONS_ACTIONS,
  LOCATION_ACTIONS,
  MASTER_ACTIONS,
  PACKAGES_ACTIONS,
  SLOT_PAUSE_ACTIONS,
  STATEDATA_ACTIONS,
  STATUS_LIST_ACTIONS,
  VEHICLE_ACTIONS,
  VEHICLE_CATEGORY_LIST,
  VEHICLE_MANAGEMENT_ACTIONS,
} from "../../actions/actionTypes";
import { cityInterface } from "../actionInterfaces";

const initialCityState = {
  payload: {},
  allCitiesData: [],
  allCityData: [],
  allBusinessData: [],
  allVehicleData: [],
  allPackagesData: null,
  cityloading: true,
  loading3: true,
  cityData: null,
  businessloading: true,
  errorMessage: "",
  locationLoading: true,
  bookingStatusLoading: true,
  paymentStatusLoading: true,
  locationData: [],
  current_location: "Search By Location",
  vehicleManagementLoader: false,
  vehicleCategoryList: [],
  availableLocation: [],
  vehicleTypes: [],
  vehicleStatus: [],
  vehicleModelList: [],
  BrandList: [],
  vehicleColors: [],
  emissionStandards: [],
  documentTypes: [],
  couponData: [],
  allCoupons: [],
  statusTypes: [],
  paymentTypes: [],
  branches: [],
  branchesList: [],
  iotTypes: [],
  vendorCodes: [],
  stateloading: true,
  aggregator: [],
  docs: null,
  nonOcrSuccess: false,
  nonOcrSuccessLoader: false,
  navigateBack: false,
  showQr: false,
  qrPaymentSuccess: false,
  tabIndex: 10,
  citiesByState: [],
  cityByStateLoader: false,
  shouldScreenRefresh: false,
};

const commonReducer = (state = initialCityState, action: cityInterface) => {
  const prevState = { ...state };

  const { type, payload } = action;

  switch (type) {
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_REQUESTED:
      return {
        ...state,
        docs: null,
      };
    case CITY_ACTIONS.CITYDATA_REQUESTED:
      return {
        ...state,
        commonloading: true,
        cityloading: true,
        vehicleManagementLoader: true,
      };
    case CITY_ACTIONS.CITYDATA_SUCCESS:
      return {
        ...state,
        allCitiesData: payload?.allCitiesData,
        allCitiesData1: cityDataExists(payload),
        citiesData: payload?.allCitiesData,
        cityloading: false,
        commonloading: false,
        vehicleManagementLoader: false,
      };
    case CITY_ACTIONS.CITYDATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        cityloading: false,
        commonloading: false,
        vehicleManagementLoader: false,
      };
    case LOCATION_ACTIONS.LOCATION_DATA_REQUESTED:
      return {
        ...state,
        locationLoading: true,
      };
    case LOCATION_ACTIONS.LOCATION_DATA_SUCCESS:
      return {
        ...state,
        availableLocation: payload.availableLocation || [],
        current_location: payload.current_location,
        locationLoading: false,
      };
    case LOCATION_ACTIONS.LOCATION_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        locationLoading: false,
      };

    case VEHICLE_ACTIONS.VEHICLEDATA_REQUESTED:
      return {
        ...state,
        cityloading: true,
      };
    case VEHICLE_ACTIONS.VEHICLEDATA_SUCCESS:
      return {
        ...state,
        ...payload,
        allVehicleData: payload?.Result,
        current_vehicle: payload.current_vehicle,
        cityloading: false,
      };
    case VEHICLE_ACTIONS.VEHICLEDATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        cityloading: false,
      };
    case BRAND_ACTIONS.BRANDDATA_REQUESTED:
      return {
        ...state,
      };
    case BRAND_ACTIONS.BRANDDATA_SUCCESS:
      return {
        ...state,
        ...payload,
        BrandList: payload?.allBrandData,
      };
    case BRAND_ACTIONS.BRANDDATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
      };

    case COUPONS_ACTIONS.GET_COUPONS_REQUESTED:
      return {
        ...state,
        cityloading: true,
      };
    case COUPONS_ACTIONS.GET_COUPONS_SUCCESS:
      return {
        ...state,
        ...payload,
        // couponCodes: payload?.couponData,
        cityloading: false,
      };
    case COUPONS_ACTIONS.GET_COUPONS_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        cityloading: false,
      };

    case PACKAGES_ACTIONS.PACKAGES_DATA_REQUESTED:
      return {
        ...state,
        cityloading: true,
      };
    case PACKAGES_ACTIONS.PACKAGES_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        allPackagesData: payload?.Result,
        commonloading: false,
      };
    case PACKAGES_ACTIONS.PACKAGES_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        cityloading: false,
      };
    case COUNTRYDATA_ACTIONS.COUNTRYDATA_REQUESTED:
      return {
        ...state,
        countryloading: true,
      };
    case COUNTRYDATA_ACTIONS.COUNTRYDATA_SUCCESS:
      return {
        ...state,
        countryData: payload?.countryData,
        countryloading: false,
      };
    case COUNTRYDATA_ACTIONS.COUNTRYDATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        countryloading: false,
      };
    case STATEDATA_ACTIONS.STATEDATA_REQUESTED:
      return {
        ...state,
        stateloading: true,
      };
    case STATEDATA_ACTIONS.STATEDATA_SUCCESS:
      return {
        ...state,
        stateData: payload?.stateData,
        stateloading: false,
      };
    case STATEDATA_ACTIONS.STATEDATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        stateloading: false,
      };
    case VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_REQUESTED:
      return {
        ...state,
        vehicleManagementLoader: true,
      };
    case VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        vehicleCategoryList: payload?.vehicleCategoryList,
        vehicleManagementLoader: false,
      };
    case VEHICLE_CATEGORY_LIST.VEHICLE_CATEGORY_LIST_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        vehicleManagementLoader: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_REQUESTED:
      return {
        ...state,
        vehicleManagementLoader: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        vehicleManagementLoader: true,
        vehicleTypes: payload?.vehicleTypes,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_TYPES_FAILED:
      return {
        ...state,
        vehicleManagementLoader: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_REQUESTED:
      return {
        ...state,
        vehicleManagementLoader: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        vehicleManagementLoader: true,
        vehicleStatus: payload?.vehicleStatus,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATUS_FAILED:
      return {
        ...state,
        vehicleManagementLoader: false,
      };

    case COUPONS_ACTIONS.GET_COUPONS_REQUESTED:
      return {
        ...state,
        commonloading: true,
      };
    case COUPONS_ACTIONS.GET_COUPONS_SUCCESS:
      return {
        ...state,
        ...payload,
        // couponData: payload?.couponData,
        commonloading: false,
      };
    case COUPONS_ACTIONS.GET_COUPONS_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        commonloading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_REQUESTED:
      return {
        ...state,
        commonloading: true,
      };
    case COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_SUCCESS:
      return {
        ...state,
        ...payload,
        couponCodeTypeData: payload?.Result,
        commonloading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_CODE_TYPE_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        commonloading: false,
      };

    case VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        vehicleModelList: payload?.vehicleModelList,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_MODEL_LIST_BY_ID_FAILED:
      return {
        ...state,
        loading3: false,
      };

    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_SUCCESS:
      return {
        ...state,
        vehicleColors: payload?.vehicleColors,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLORS_FAILED:
      return {
        ...state,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_EMISSION_STANDARDS_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_EMISSION_STANDARDS_SUCCESS:
      return {
        ...state,
        emissionStandards: payload?.emissionStandards,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_EMISSION_STANDARDS_FAILED:
      return {
        ...state,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_REQUESTED:
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_CATEGORIES_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_RESET:
      return {
        ...state,
        branches: [],
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        modelCategories: payload?.vehicleCategories,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_BRANCHES_FAILED:
    case VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        loading3: false,
      };

    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_REQUESTED:
    case STATUS_LIST_ACTIONS.GET_STATUS_LIST_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_SUCCESS:
      return {
        ...state,
        iotTypes: payload?.iotTypes,
        loading3: false,
      };
    case STATUS_LIST_ACTIONS.GET_STATUS_LIST_SUCCESS:
      return {
        ...state,
        statusList: payload?.statusList,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_IOT_TYPE_FAILED:
    case STATUS_LIST_ACTIONS.GET_STATUS_LIST_FAILED:
      return {
        ...state,
        loading3: false,
      };

    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_SUCCESS:
      return {
        ...state,
        vendorCodes: payload?.vendorCodes,
        loading3: false,
      };
    case VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_VENDOR_CODE_FAILED:
      return {
        ...state,
        loading3: false,
      };

    case SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_REQUESTED:
      return {
        ...state,
        loading3: true,
      };
    case SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_SUCCESS:
      return {
        ...state,
        ...payload,
        slotsData: payload?.data,
        loading3: false,
      };
    case SLOT_PAUSE_ACTIONS.GET_ALL_SLOT_PAUSE_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading3: false,
      };

    case VEHICLE_MANAGEMENT_ACTIONS.STORE_DOCUMENT_SUCCESS:
      return {
        ...state,
        docs: payload?.docData,
      };

    case CITY_ACTIONS.GET_CITIES_BY_STATE_REQUESTED:
      return {
        ...state,
        cityByStateLoader: true,
        citiesByState: [],
      };
    case CITY_ACTIONS.GET_CITIES_BY_STATE_SUCCESS:
      return {
        ...state,
        cityByStateLoader: false,
        citiesByState: payload?.citydata,
      };
    case CITY_ACTIONS.GET_CITIES_BY_STATE_FAILED:
      return {
        ...state,
        cityByStateLoader: false,
        citiesByState: [],
      };
    case MASTER_ACTIONS.REFRESH_DRAWER_SCREEN_DATA:
      return {
        ...state,
        shouldScreenRefresh: !state.shouldScreenRefresh,
      };
    default:
      return { ...prevState };
  }
};
function cityDataExists(allCitiesData: any) {
  let cityFilteredData: any = [];
  let i = 1;

  if (allCitiesData?.Result?.data?.length > 0) {
    allCitiesData.Result.data.map((f: any) => {
      let res = {
        serialNumber: i++,
        cityId: f?.id,
        city_name: f?.city_name,
      };
      cityFilteredData.push(res);
    });

    return cityFilteredData;
  }
  return [];
}

export default commonReducer;
