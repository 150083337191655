import { Box, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import {
  ImagePreviewNotAvailable,
  LeftScrollIcon,
  RightScrollIcon,
} from "../../../../constants/exportImages";
import { typographyConstants } from "../../../../constants/constants";
import Styles from "./section.module.css";
import { colors } from "../../../../../themes/colors";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { generateVehicleSignedUrl } from "../../../../redux/actions/bookingManagementActions";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import en from "../../../../locale/rental-en.json";

interface VehicleImagesCarouselInterface {
  imagesData: any;
  bookingData: any;
  status: any;
  imagePreviewData: any;
  // openModal: any;
  imageLoader: any;
  onImageUpload?: any;
  title: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2rem",
};

const UploadVehicleImages = ({
  imagesData,
  bookingData,
  imageLoader,
  onImageUpload,
  title = "",
}: VehicleImagesCarouselInterface) => {
  const {
    id: bookingId,
    bookingVehicleDetails: { startRideImageRefId = "" } = {},
  } = bookingData;
  const containerRef = useRef<any>(null);
  const dispatch = useDispatch();
  const scrollHomeDeliveryCards = (value: any) => {
    containerRef.current.scrollBy({ left: value, behavior: "smooth" });
  };
  const generateSignedUrl = (fileNames: string[], files: any) => {
    const payload: any = {
      requestdata: {
        data: {
          type: "VEHICLE_IMAGES",
          attachments: fileNames,
        },
      },
      filesData: {
        bookingId: bookingId,
        files,
        fileNames,
        startRideImageRefId: startRideImageRefId,
      },
      uploadOnly: true,
      sucessCallback: (signedUrl: any) => {
        onImageUpload(signedUrl, files);
      },
    };
    //dispatch(generateDocsSignedUrls(payload));
    dispatch(generateVehicleSignedUrl(payload));
  };

  const onDrop = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
      });

      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code === "too-many-files") {
          toastr.warning(
            "",
            `Maximum ${10 - imagesData?.length} files can be uploded`
          );
          return;
        } else if (item?.errors[0]?.code === "file-too-large") {
          toastr.warning("", en.errorMessages.imageSize);
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files);
      }
    },
    [bookingId, imagesData]
  );

  const maxFileUpload = (): number => {
    return imagesData?.length ? 10 - imagesData?.length : 10;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: maxFileUpload(),
    maxSize: 5000000,
    multiple: true,
    disabled: imagesData?.length >= 10,
  });

  return (
    <Box
      sx={{
        padding: "15px 8px 15px 11px",
        borderRadius: "5px",
        marginTop: "20px",
        background: colors.bg_light_gray,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant={typographyConstants.HEADING_NO_PADDING}>
            {title || "Vehicle Images"}
          </Typography>
          {imagesData?.length > 9 ? (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <img
                  src={LeftScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(-200)}
                  width="30px"
                  height="30px"
                />
              </Box>
              <Box>
                <img
                  src={RightScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(200)}
                  width="30px"
                  height="30px"
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            //marginBottom: "10px",
            cursor: "pointer",
            //marginRight: "12px",
          }}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {imagesData?.length ? (
            <Grid
              container
              spacing={2}
              sx={{ width: "100%", paddingTop: "10px" }}
            >
              <Grid
                xs={10.5}
                className={Styles.CardListContainer}
                ref={containerRef}
                sx={{ padding: "20px", margin: "auto" }}
              >
                {imageLoader ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  imagesData?.map((imageData: any) => {
                    const imgSrc = URL.createObjectURL(imageData);
                    return (
                      <Box
                        className={Styles.imageContainer}
                        onClick={() => {
                          // openModal && openModal(imageData?.signedUrl);
                        }}
                      >
                        <img
                          className={Styles.vehicleImage}
                          src={imgSrc}
                          onError={(current: any) => {
                            current.onerror = null;
                            current.src = ImagePreviewNotAvailable;
                          }}
                          style={{ height: "50px", width: "50px" }}
                        />
                        <Box className={Styles.overlay}>
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.white}
                          >
                            {/* Preview */}
                            {/* {VehicleManagement.preview} */}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                height: "90px",
                display: "flex",
                padding: "10px",
                margin: "auto",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant={typographyConstants.SUBHEADING}>
                No images Uploaded
              </Typography>
            </Box>
          )}
          {imagesData?.length < 10 && (
            <Box
              sx={{
                border: "1px solid",
                height: imagesData?.length > 0 ? "55px" : "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                width: imagesData?.length > 0 ? "55px" : "90px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Box>
                <AddIcon color="secondary" fontSize="medium" />
              </Box>
            </Box>
          )}
        </Box>
        <Typography variant={typographyConstants.BODY}>
          {imagesData?.length ?? 0}/10 images
        </Typography>
      </Box>
    </Box>
  );
};
export default UploadVehicleImages;
