import { BOOKING_MANAGEMENT_ACTIONS_X } from "../../actions/actionTypes";

interface bookingMgtInterface {
  bookings: any;
  statistics: any[];
  bookingDetails: any;
  startRideLoader: false;
  availableVehicles: [];
  isRideStarted: boolean;
  bookingStatus: string;
  pendingPaymentStatus: any;
  bookingListLoader:boolean;
}
const initialState: bookingMgtInterface = {
  bookings: {},
  statistics: [],
  bookingDetails: {},
  startRideLoader: false,
  availableVehicles: [],
  isRideStarted: false,
  bookingStatus: "",
  pendingPaymentStatus: "",
  bookingListLoader:false,
};
const bookingManagementReducersX = (
  state = initialState,
  action: any
) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case BOOKING_MANAGEMENT_ACTIONS_X.FETCH_BOOKING_LIST_X_REQUESTED:
      return {
        ...state,
        bookingListLoader:true,
      };
    case BOOKING_MANAGEMENT_ACTIONS_X.FETCH_BOOKING_LIST_X_SUCCESS:
      return {
        ...state,
        bookings: payload,
        bookingListLoader:false,
      };
    case BOOKING_MANAGEMENT_ACTIONS_X.FETCH_BOOKING_LIST_X_FAILED:
      return {
        ...state,
        bookingListLoader:false,
      };
    case BOOKING_MANAGEMENT_ACTIONS_X.GET_BOOKING_STATISTICS_X_REQUESTED:
      return {
        ...state,
        statData: false,
      };
    case BOOKING_MANAGEMENT_ACTIONS_X.GET_BOOKING_STATISTICS_X_SUCCESS:
      return {
        ...state,
        statistics: payload,
      };
    case BOOKING_MANAGEMENT_ACTIONS_X.GET_BOOKING_STATISTICS_X_FAILED:
      return {
        ...state,
        statdata: false,
      };

    default:
      return { ...state };
  }
};
export default bookingManagementReducersX;
