import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import yoren from "../../../locale/yor-en.json";
import { RootState } from "../../../redux/reducers";

import {
  ManualBookingfeildsProps,
  createBookingXPayload,
} from "../../interfaces/manualBookingInterfaces";
import { actionsX } from "../../redux";
import { colors } from "../../themes/colors";
import {
  convertToTimeStamp,
  dateFormat,
  dateToTimestamp,
  getFormattedINR,
  getFullName,
  getGenderLabel,
  getInitials,
  validate,
} from "../../utils/helper";

import { useNavigate } from "react-router-dom";
import MultiStepForm from "../../components/MultiStepForm/multiStepForm";
import { BREADCRUMBS_YOR } from "../../../rentals-src/constants/activeModule";
import {
  BOOKING_STATUS_ENUM,
  CANCEL_BOOKING_X_TYPES,
  DUPLICATE_BOOKING_MODAL_X,
} from "../../constantsX/booking";
import {
  cancelBookingYOR,
  cancelDuplicateManualBookingX,
  closeDuplicateBookingModalX,
} from "../../redux/actions";
import styles from "./ManualBooking.module.css";
import CheckOut from "./MultiStepForm/CheckOut";
import PlanDetails from "./MultiStepForm/PlanDetails";
import UserLocationDetails from "./MultiStepForm/User&LocationDetails";
import SendPaymentLinkModal from "./SendPaymentLinkModal";
import TopCards from "./TopCards/TopCards";
import { CreateBookingStyles } from "../../components/MultiStepForm/StyleObjects";
import { StepsProps } from "../../interfaces/componentsInterfaces";
import { AlreadyExistImg } from "../../constantsX/exportImagesX";
import {
  EndIconLabelButtonX,
  OutlinedButtonX,
  TextButtonX,
} from "../../components/muiButtonsX";
import ResponsiveDialog from "../../../components/responsiveDialog";
import {
  HelmetMapForCreateBooking,
  HelmetRevertMapForCreateBooking,
  HelmetTypesArray,
  displayGenderEnums,
} from "../../constantsX/YOR_BookingDetails";
import { MITC_FORM_URL } from "../../../config";

const initialState: ManualBookingfeildsProps = {
  mobileNumber: "",
  selectedBranch: {
    name: "",
    displayName: "",
  },
  selectedCity: {
    name: "",
    displayName: "",
  },
  selectedVehicle: "",
  selectedDuration: "",
  startDate: "",
  selectedPlan: {},
  helmets: {
    premium: 0,
    fullFace: 0,
    halfFace: 0,
    kids: 0,
    /* PREMIUM: 0,
    FULL_FACE: 0,
    HALF_FACE: 0,
    KIDS: 0, */
  },
  complementaryHelmet: true,
};

const errorInitialState = {
  mobileNumber: "",
  selectedBranch: "",
  selectedCity: "",
  selectedVehicle: "",
  startDate: "",
  selectedDuration: "",
  selectedPlan: "",
  selectedHelmet: "",
  complementaryHelmet: "",
};

function ManualBooking(_props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    checkUserDetails,
    createManualBookingUser,
    isManualBookingCreated,
    createManualBookingLoader,
  } = useSelector((state: RootState) => state.manualBookingX);
  const { modelMapData, modelsMapImages }: any = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { rentalPlans, durationInMonths } = useSelector(
    (state: RootState) => state.rentalPlansReducerX
  );
  const { bookingCharges, duplicateBookingModal } = useSelector(
    (state: RootState) => state.manualBookingX
  );
  const { cityData, modelList } = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);
  
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [showVehicleCard, setshowVehicleCard] = useState<boolean>(false);
  const [fields, setFields] = useState<ManualBookingfeildsProps>({
    ...initialState,
  });
  const [date, setDate] = useState<any>("DD/MM/YYYY");
  const [filteredPlan, setfilteredPlan] = useState<any>();

  const [openSendPaymentLinkModal, setOpenSendPaymentLinkModal] =
    useState<Boolean>(false);
  const [errorFields, setErrorFields] = useState({
    ...errorInitialState,
  });

  const resetMultistepFormRef = useRef<any>(null);

  //Clear data from state and redux
  const resetAllData = () => {
    let obj = {
      ...fields,
    };
    if (rentalPlans?.plans?.length) {
      obj.selectedVehicle = initialState.selectedVehicle;
      obj.selectedDuration = initialState.selectedDuration;
      obj.selectedPlan = initialState.selectedPlan;
      obj.helmets = initialState.helmets;
      obj.complementaryHelmet = initialState.complementaryHelmet;
      setFields({ ...fields, ...obj });
      dispatch(actionsX.clearRentalPlansX());
      setshowVehicleCard(false);
    }
  };

  // clear all fields data from state and redux
  const clearFieldsData = () => {
    setFields({ ...initialState });
    setShowUserDetails(false);
    setshowVehicleCard(false);
    setErrorFields({ ...errorInitialState });
    dispatch(actionsX.clearCheckUserDetailsX());
    dispatch(actionsX.clearRentalPlansX());
    resetMultistepFormRef.current &&
      resetMultistepFormRef.current.nextStepsDisabled();
  };

  //Show only city data which has access to the user
  // const UserDataCityID = userData?.cities?.map((e: any) => e.city_id);
  const UserDataCityID = userData?.authorizedCities?.map((e: any) => e.name);

  /* let AccessCity = cityData?.filter((e: any) =>
    UserDataCityID?.includes(e.legacyCityId)
  ); */
  let AccessCity = cityData?.filter((e: any) =>
    // UserDataCityID?.includes(e.legacyCityId)
    userData?.authorizedCities?.includes(e.name)
  );
 /*  let AccessCity = allCitiesDetails?.filter((e: any) =>
    userData?.authorizedCities?.includes(e.name)
  ); */
 

  const validateUserAndLocationDetails = () => {
    let error = false;
    const validatedFields = validate(fields, errorFields, [
      "selectedVehicle",
      "selectedDuration",
      "selectedPlan",
      "startDate",
    ]);

    if (validatedFields.error || modelList?.models?.length === 0) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });
      return error;
    }
    return error;
  };

  const validatePlanDetails = () => {
    let error = false;
    const validatedFields = validate(fields, errorFields, [
      "selectedBranch",
      "selectedCity",
      "mobileNumber",
    ]);
    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });
      return error;
    }
    setshowVehicleCard(true);
    // CALCULATE CHARGE
    const payload = {
      data: {
        planId: fields?.selectedPlan?.id,
      },
    };
    dispatch(actionsX.getCalculatedCharges(payload));
    return error;
  };

  // Handle send paymentLink Button
  const sendPaymentLink = () => {
    const selectedHelmets = fields?.helmets;
    let payload: createBookingXPayload = {
      data: {
        planId: fields.selectedPlan?.id,
        // customerId: JSON.stringify(checkUserDetails?.id),
        customerId: checkUserDetails?.id,
        startDate: dateToTimestamp(fields?.startDate),
        accessories: {
          helmets: selectedHelmets,
        },
        complementaryHelmet: fields.complementaryHelmet,
      },
    };

    dispatch(actionsX.createManualBookingX(payload));
  };

  //  Close Payment Modal
  const handleCloseSendPaymentLinkModal = (): any => {
    setOpenSendPaymentLinkModal(false);
  };

  // Checkout Footer & Send Payment link button
  const CheckoutFooter = () => {
    return (
      <Stack
        direction="row"
        spacing={4}
        style={{ alignItems: "center", position: "fixed", right: "37px" }}
      >
        {/* PAYMENT BRIEF */}
        <Stack direction="row" gap={2} alignItems="end">
          <Stack alignItems="end">
            <Typography sx={{ fontSize: "11px", fontWeight: "600" }}>
              {fields?.selectedPlan?.displayName}
            </Typography>
            <Typography fontSize={10}>
              {yoren.manualBookingFreedoX.For}{" "}
              {bookingCharges?.durationInMonths}{" "}
              {yoren.manualBookingFreedoX.Months}
            </Typography>
          </Stack>
          <Stack alignItems="end">
            <Typography
              sx={{ color: colors.primary_aqua_blue, fontSize: "11px" }}
            >
              {getFormattedINR(bookingCharges?.totalFee)}
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                color: colors.tertiary_black,
                fontStyle: "italic",
              }}
            >
              {yoren.manualBookingFreedoX.InclusiveAllTaxes}
            </Typography>
          </Stack>
        </Stack>

        {/* Send Payment link button */}
        <EndIconLabelButtonX
          variant="contained"
          label={yoren.manualBookingFreedoX.SendPaymentLink}
          onClick={sendPaymentLink}
          disabled={createManualBookingLoader}
        ></EndIconLabelButtonX>
      </Stack>
    );
  };

  // Fetch All cities
  useEffect(() => {
    // dispatch(actionsX.fetchAllCitiesX());
    return () => {
      clearFieldsData();
    };
  }, []);

  // Check User Exist
  useEffect(() => {
    checkUserDetails && setShowUserDetails(true);
  }, [checkUserDetails]);

  //Navigate to Booking details
  useEffect(() => {
    if (isManualBookingCreated) {
      setOpenSendPaymentLinkModal(true);
      setTimeout(() => {
        handleCloseSendPaymentLinkModal();
        navigate(
          `/${BREADCRUMBS_YOR?.BOOKING_DETAILS?.selfPath}/${createManualBookingUser?.id}?type=created`
        );
      }, 5000);
    }
    dispatch(actionsX.clearCheckUserDetailsX());
  }, [isManualBookingCreated]);

  // ACTIVE SIDEBAR, BREADCRUMS & TITLE
  useEffect(() => {
    dispatch(actionsX.addActiveModuleX(BREADCRUMBS_YOR.BOOKING_CREATE));
  }, []);

  // set index 0 of city
  useEffect(() => {
    if (AccessCity.length > 0) {
      setFields((prevState: any) => ({
        ...prevState,
        ["selectedCity"]: AccessCity[0],
      }));
    }
  }, [AccessCity.length, checkUserDetails]);

  // set city
  useEffect(() => {
    if (fields?.selectedCity) {
      dispatch(actionsX.getBranchX(fields?.selectedCity));
      setshowVehicleCard(false);
      // RESET BRANCH
      setFields({ ...fields, selectedBranch: initialState.selectedBranch });
      resetMultistepFormRef.current &&
        resetMultistepFormRef.current.nextStepsDisabled();
    }
  }, [fields?.selectedCity]);

  // SET BRANCH
  useEffect(() => {
    if (fields?.selectedBranch) {
      resetAllData();
      resetMultistepFormRef.current &&
        resetMultistepFormRef.current.nextStepsDisabled();
    }
  }, [fields?.selectedBranch]);

  // filter the plans on basis of duration
  const filteredPlans = () => {
    let plans = rentalPlans?.plans?.filter(
      (plans: any) => plans?.durationInMonths === fields?.selectedDuration
    );
    return plans;
  };

  //  Set first index of selected plan (Plan Details)
  useEffect(() => {
    setDate(rentalPlans?.availableOn);
    if (rentalPlans?.plans?.length > 0) {
      let plans = filteredPlans();
      setfilteredPlan(plans);
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: plans[0],
        selectedDuration: durationInMonths[0],
      }));
      setFields((prevState: any) => ({
        ...prevState,
        startDate: dateFormat(rentalPlans?.availableOn),
      }));
    }
    if (!rentalPlans?.plans?.length) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: "",
        selectedDuration: "",
      }));
      setfilteredPlan([]);
      resetMultistepFormRef.current &&
        resetMultistepFormRef.current.nextStepsDisabled();
    }
  }, [rentalPlans]);

  // Select the first Filtered plan
  useEffect(() => {
    let plans = filteredPlans();
    setfilteredPlan(plans);
    if (fields?.selectedDuration && plans?.length) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: plans[0],
      }));
    }
  }, [fields?.selectedDuration, rentalPlans?.plans]);

  // Render loader SideEffect
  // useEffect(() => {}, [createManualBookingLoader]);

  //MULTISTEP FORM STEPS
  const ManualBookingsteps: StepsProps[] = [
    {
      key: "UserAndLocationDetails",
      step: yoren.manualBookingFreedoX.UserandLocationDetails,
      render: (
        <UserLocationDetails
          fields={fields}
          setFields={setFields}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          clearFieldsData={clearFieldsData}
          AccessCity={AccessCity}
        />
      ),
      validateFunction: validateUserAndLocationDetails,
      // validateFunction: () => true,
    },
    {
      key: "PlanDetails",
      step: yoren.manualBookingFreedoX.PlanDetails,

      render: (
        <PlanDetails
          fields={fields}
          setFields={setFields}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          initialState={initialState}
          setDate={setDate}
          date={date}
          filteredPlan={filteredPlan}
        />
      ),
      validateFunction: validatePlanDetails,
    },
    {
      key: "Checkout",
      step: yoren.manualBookingFreedoX.Checkout,

      render: (
        <CheckOut
          selectedFields={fields}
          bookingCharges={bookingCharges}
          setFields={setFields}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
        />
      ),
      renderFooter: <CheckoutFooter />,
    },
  ];

  // DUPLICATE BOOKING MODAL CLOSE
  const handleDuplicateBookingModalClose = () => {
    const duplicateBookingModal = {
      show: false,
      bookingId: "",
    };
    dispatch(closeDuplicateBookingModalX());
  };

  // CANCEL EXISTING & CREATE NEW
  const handleCancelThenCreateBooking = (bookingId: string) => {
    if (!bookingId) return;
    const selectedHelmets = fields?.helmets;

    const cancelThenCreatePayload = {
      bookingId: bookingId,
      cancelActionType: CANCEL_BOOKING_X_TYPES.CANCEL_THEN_CREATE_BOOKING,
      createBookingPayload: {
        data: {
          planId: fields.selectedPlan?.id,
          // customerId: JSON.stringify(checkUserDetails?.id),
          customerId: checkUserDetails?.id,
          startDate: dateToTimestamp(fields?.startDate),
          complementaryHelmet: fields.complementaryHelmet,
          accessories: {
            helmets: selectedHelmets,
          },
        },
      },
    };
    // dispatch(cancelDuplicateManualBookingX(cancelThenCreatePayload));
    dispatch(cancelBookingYOR(cancelThenCreatePayload));
  };

  // Get modal image
  const selectedModalName = fields?.selectedPlan?.modelName;
  const modelImagesArray = modelsMapImages.has(selectedModalName)
    ? modelsMapImages.get(selectedModalName)
    : [];
  let modelImageFilename = "";
  modelImagesArray.length > 0 &&
    modelImagesArray?.map((item: any, index: number) => {
      if (item?.type === "right") {
        modelImageFilename = item?.fileName;
      }
      if (!modelImageFilename) {
        modelImageFilename = item?.fileName;
      }
    });
  const imagePathUrl = modelImageFilename
    ? `${MITC_FORM_URL}/images/model/${selectedModalName}/${modelImageFilename}`
    : "";
  let userGender = showUserDetails ? checkUserDetails?.gender : "";
  let userTags =
    checkUserDetails &&
    checkUserDetails?.tags &&
    checkUserDetails?.tags?.length > 0
      ? checkUserDetails?.tags
      : [];
  return (
    <Box sx={{ backgroundColor: "unset" }}>
      <Box className={`${styles.BoxColumn} ${styles.manualBookingForm}`}>
        {/* Top Cards */}
        <Box className={styles.BoxRow} gap={3}>
          {/* User Details Card */}
          {showUserDetails ? (
            <TopCards
              avatar={getInitials(
                getFullName(
                  checkUserDetails?.firstName,
                  checkUserDetails?.lastName
                )
              )}
              label={getFullName(
                checkUserDetails?.firstName,
                checkUserDetails?.lastName
              )}
              genderSection={
                displayGenderEnums[
                  userGender as keyof typeof displayGenderEnums
                ]
              }
              phone={checkUserDetails?.mobileNumber}
              email={checkUserDetails?.email}
              isKycVerified={
                userTags
                  ? userTags.includes(
                      checkUserDetails?.tagscheckUserDetails?.is_kyc_verified
                    )
                  : false
              }
            />
          ) : (
            <></>
          )}
          {/* Vehicle Details Card  */}

          {showVehicleCard ? (
            <TopCards
              img="vehicle"
              label={fields.selectedVehicle}
              fields={fields}
              costPM={fields?.selectedPlan?.planCharges?.monthlyCharges}
              imagePathUrl={imagePathUrl}
            />
          ) : (
            <></>
          )}
        </Box>

        <MultiStepForm
          steps={ManualBookingsteps}
          footer={true}
          ref={resetMultistepFormRef}
          Styles={CreateBookingStyles}
        />
      </Box>

      {/* Payment Modal */}
      {openSendPaymentLinkModal ? (
        <SendPaymentLinkModal
          openSendPaymentLinkModal={openSendPaymentLinkModal}
          handleCloseSendPaymentLinkModal={handleCloseSendPaymentLinkModal}
        />
      ) : (
        <></>
      )}

      {/* Duplicate Booking Modal */}
      {duplicateBookingModal?.show ? (
        <ResponsiveDialog
          fullScreen={false}
          title={DUPLICATE_BOOKING_MODAL_X?.TITLE}
          titleColor="text-warning"
          maxWidth="md"
          handleClose={handleDuplicateBookingModalClose}
          open={duplicateBookingModal?.show}
          fullWidth="true"
          titleFontSize="19px"
        >
          <Stack>
            <Stack flexDirection="row" alignItems="baseline">
              <Typography>
                {DUPLICATE_BOOKING_MODAL_X?.CHECK_BOOKING_MSG}
              </Typography>
              <TextButtonX
                onclick={() =>
                  window.open(
                    `${window.location.origin}/${BREADCRUMBS_YOR?.BOOKING_DETAILS?.selfPath}/${duplicateBookingModal?.bookingId}`
                  )
                }
                label={duplicateBookingModal?.bookingId}
              />
            </Stack>
            {duplicateBookingModal?.status &&
            (duplicateBookingModal?.status === BOOKING_STATUS_ENUM.CREATED ||
              duplicateBookingModal?.status === BOOKING_STATUS_ENUM.BOOKED) ? (
              <Stack gap="2vh">
                <Typography>
                  {DUPLICATE_BOOKING_MODAL_X?.CONFIRMATION}
                </Typography>
                <Stack direction="row" justifyContent="flex-end" gap="1vw">
                  <OutlinedButtonX
                    label={"No"}
                    onClick={handleDuplicateBookingModalClose}
                    color="secondary"
                  />
                  <OutlinedButtonX
                    label={"Yes"}
                    onClick={() =>
                      handleCancelThenCreateBooking(
                        duplicateBookingModal?.bookingId
                      )
                    }
                    disabled={duplicateBookingModal?.duplicateLoader}
                  />
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </ResponsiveDialog>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default ManualBooking;
