import {
  BookingManagementIcon,
  BusinessManagementIcon,
  CustomerManagementIcon,
  DashboardIcon,
  FeedbackManagementIcon,
  PaymentManagementIcon,
  PlanManagementIcon,
  ReportsIcon,
  TaskManagementIcon,
  VehicleAndInventoryIcon,
} from "../rentals-src/assests/svgComponents/sideDrawerIcons";

import { BookingModuleIcon } from "../freedo-x-src/constantsX/exportImagesX";
import RegisterUserComponent from "../rentals-src/screens/CustomerManagement/CustomerCreate";
import CustomerDetails from "../rentals-src/screens/CustomerManagement/CustomerDetails";
import CustomerManagement from "../rentals-src/screens/CustomerManagement/Overview";
import NewManualBooking from "../rentals-src/screens/manualBooking";
import AddEditRentalPlan from "../rentals-src/screens/planManagement/addEditPlan";
import SelectPLanCategory from "../rentals-src/screens/planManagement/addEditPlan/sections/SelectPlanCategory";
import PlanManagement from "../rentals-src/screens/planManagement/planList";
import {
  AddEditModelManagementScreen,
  AddEditPromotionsScreen,
  AddEditSlotPause,
  ChangePasswordScreen,
  LoginV3,
  ModelManagementScreen,
  PromotionsScreen,
  SlotPauseScreen,
  ViewModelManagementScreen,
  ViewStatsNewScreen
} from "../screens";
import AddEditAdvertisement from "../screens/masterManagement/addEditAdvertisement";
import AdvertisementManagement from "../screens/masterManagement/advertisementManagement";
import NewFeedbackManagement from "../screens/newFeedbackManagement";
import CouponStats from "../screens/promotions/couponStats";

import BookingManagement from "../rentals-src/screens/bookingManagement/allBookings";
import BookingDetails from "../rentals-src/screens/bookingManagement/bookingDetails";
import BusinessCity from "../rentals-src/screens/businessManagement/City";
import BusinessOverview from "../rentals-src/screens/businessManagement/Overview";
import WarehouseManagement from "../rentals-src/screens/businessManagement/Warehouse";
import AddEditViewWarehouse from "../rentals-src/screens/businessManagement/Warehouse/addEditViewWarehouse";
import CreateSurge from "../rentals-src/screens/surgeManagement/createSurge";
import SurgeList from "../rentals-src/screens/surgeManagement/surgeList";
import InventoryDashboard from "../rentals-src/screens/vehicleManagement/inventory/InventoryDasboard";
import InventoryDetails from "../rentals-src/screens/vehicleManagement/inventory/InventoryDetails";
import IotViewEditDevice from "../rentals-src/screens/vehicleManagement/iot/AddEditIot";
import BulkUploadIot from "../rentals-src/screens/vehicleManagement/iot/BulkUploadIot";
import IotDashboardRentals from "../rentals-src/screens/vehicleManagement/iot/IotList";
// import InventoryManagement from "../rentals-src/screens/vehicleManagement/Inventory";
import {
  inventoryFunctionality,
  iotManagementFunctionality,
  modules,
  myProfileFunctionality,
  operatorFunctionality,
  roleManagementFunctionality,
  vehicleManagementFunctionalities,
} from "../constants/RBACModuleEnums";
import { Dashboard } from "../rentals-src/screens/DashboardManagement";
import { ModifyBookingDetails } from "../rentals-src/screens/bookingManagement/modifyBookingDetails";
import HelmetConfiguration from "../rentals-src/screens/businessManagement/HelmetConfiguration";
import HelemetPriceConfiguration from "../rentals-src/screens/businessManagement/HelmetConfiguration/sections/helmetPriceConfiguration";
import AddEditRole from "../rentals-src/screens/businessManagement/Role/addEditRole";
import RoleDashboard from "../rentals-src/screens/businessManagement/Role/roleDashboard";
import ViewRoleUserDetails from "../rentals-src/screens/businessManagement/Role/viewRoleUserDetails";
import ApprovedOpreators from "../rentals-src/screens/operatorManagement/approvedLeads/index";
import { ApprovedLeadDetails } from "../rentals-src/screens/operatorManagement/approvedLeads/section/ApprovedLeadDetails";
import OperatorLeadListing from "../rentals-src/screens/operatorManagement/leads/index";
import { LeadDetails } from "../rentals-src/screens/operatorManagement/leads/section/LeadDetails";
import OperatorLeadDetails from "../rentals-src/screens/operatorManagement/leads/section/operatorLeadDetails";
import PaymentManagement from "../rentals-src/screens/paymentManagement";
import { Reports } from "../rentals-src/screens/reports/Index";
import TaskManagementScreen from "../rentals-src/screens/taskManagement";
import DragDropTaskSequence from "../rentals-src/screens/taskManagement/DragDropTaskSequence";
import TaskAssignment from "../rentals-src/screens/taskManagement/TaskAssignment";
import TaskSequence from "../rentals-src/screens/taskManagement/TaskSequence";
import VehicleDetails from "../rentals-src/screens/vehicleManagement/vehicle/ViewVehicle";
import AddNewVehicle from "../rentals-src/screens/vehicleManagement/vehicle/addNewVehicle";
import AllVehicles from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles";
import { ApproveRejectVehicle } from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles/sections/ApproveRejectVehicle";
import { VehicleStatusUpdate } from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles/sections/VehicleStatusUpdate";
import { VehicleTranfer } from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles/sections/VehicleTranfer/VehicleTranfer";
import { VehicleTransferhistory } from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles/sections/VehicleTranfer/VehicleTransferhistory";
import { BulkUpload } from "../rentals-src/screens/vehicleManagement/vehicle/allVehicles/sections/bulkUpload/BulkUpload";
import { routesConstants } from "../rentals-src/utils/RoutesConstants";

export const DrawerRoutesLTR = [
  {
    path: "/dashboard",
    key: "Dashboard",
    imgComp: DashboardIcon,
    moduleKey: "DASHBOARD",
  },
  {
    path: routesConstants?.CUSTOMER_MANAGEMENT,
    imgComp: CustomerManagementIcon,
    key: "Customer Management",
    moduleKey: "CUSTOMER_MANAGEMENT",
  },
  {
    path: routesConstants?.VEHICLE_MANAGEMENT,
    imgComp: VehicleAndInventoryIcon,
    key: "Vehicle and Inventory",
    moduleKey: modules?.VEHICLE_MANAGEMENT,
    types: [
      {
        key: "Vehicle Management",
        moduleKey: modules?.VEHICLE_MANAGEMENT,
        path: routesConstants.ALL_VEHICLES,
      },
      {
        key: "IOT Management",
        path: routesConstants.IOT_LISTING,
        moduleKey: "IOT_MANAGEMENT",
      },
      {
        path: "/inventory-management",
        key: "Inventory",
        moduleKey: modules?.INVENTORY_MANAGEMENT,
      },
    ],
  },
  {
    path: "/booking-management",
    imgComp: BookingManagementIcon,
    key: "Booking Management",
    moduleKey: "BOOKING_MANAGEMENT",
    types: [
      {
        key: "Create New Booking",
        path: routesConstants.MANUAL_BOOKING,
        moduleKey: "BOOKING_MANAGEMENT",
      },
      {
        key: "All Bookings",
        path: routesConstants.ALL_BOOKINGS,
        moduleKey: "BOOKING_MANAGEMENT",
      },
    ],
  },
  {
    path: "/feedback-management",
    imgComp: FeedbackManagementIcon,
    key: "Feedback Management",
    moduleKey: "FEEDBACK_MANAGEMENT",
  },
  {
    path: routesConstants.PAYMENT_MANAGEMENT,
    imgComp: PaymentManagementIcon,
    key: "Payment Management",
    moduleKey: modules?.PAYMENT_MANAGEMENT,
  },
  {
    path: routesConstants?.REPORTS,
    imgComp: ReportsIcon,
    key: "Reports",
    moduleKey: modules?.REPORTS,
  },
  {
    path: "/business-management",
    imgComp: BusinessManagementIcon,
    key: "Business Management",
    types: [
      {
        path: routesConstants.BUSINESS_OVERVIEW,
        key: "Overview",
        moduleKey: "BUSINESS_MANAGEMENT",
      },
      {
        path: routesConstants.ROLE_MANAGEMENT,
        key: "Role Management",
        moduleKey: modules?.ROLE_MANAGEMENT,
      },
      // need to add module key
      {
        path: routesConstants.BUSINESS_CITY_MANAGEMENT,
        key: "City",
        moduleKey: "CITY_MANAGEMENT",
      },
      {
        path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
        key: "Hub",
        moduleKey: "WAREHOUSE_MANAGEMENT",
      },
      {
        path: "/master-management/model-module",
        key: "Vehicle Models",
        moduleKey: "MODEL_MANAGEMENT",
      },
      {
        path: "/master-management/advertisements",
        key: "Advertisement",
        moduleKey: "ADVERTISEMENT",
      },
      {
        path: "/slotPause",
        moduleKey: "SLOT_PAUSE",
        key: "Slot Pause",
      },
      {
        path: routesConstants.HELMET_CONFIGURATION,
        key: "Helmet Configuration",
        moduleKey: "WAREHOUSE_MANAGEMENT",
      },
    ],
  },
  {
    path: "/task-list-management",
    imgComp: TaskManagementIcon,
    key: "Task Management",
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: routesConstants.PLAN,
    imgComp: PlanManagementIcon,
    key: "Plan Management",
    types: [
      {
        path: routesConstants.PLAN_MANAGEMENT,
        img: BookingModuleIcon,
        key: "Rental Plan",
        moduleKey: "PLAN_MANAGEMENT",
      },
      {
        path: routesConstants.SURGE_LIST,
        img: BookingModuleIcon,
        key: "Surge",
        moduleKey: modules?.SURGE_MANAGEMENT,
      },
    ],
  },
  {
    path: "/promotions",
    img: BookingModuleIcon,
    moduleKey: "PROMOTIONS",
    key: "Promotions & Coupons",
  },
  {
    path: routesConstants?.OPERATOR_MANAGEMENT,
    imgComp: CustomerManagementIcon,
    key: "Operator Management",
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    types: [
      {
        key: "Operator Leads",
        moduleKey: modules?.OPERATOR_MANAGEMENT,
        path: routesConstants.OPERATOR_MANAGEMENT_LEADS,
      },
      {
        key: "Approved Operators",
        path: routesConstants.APPROVED_OPERATOR,
        moduleKey: modules?.OPERATOR_MANAGEMENT,
      },
    ],
  },
  {
    path: routesConstants.ACCOUNT_DETAILS,
    imgComp: CustomerManagementIcon,
    moduleKey: modules?.OPERATOR_MY_ACCOUNT,
    key: "My Account",
  },
  // -----
];

// moduleKey - used for RBACK to show component
// functionalityKey key (optional) - used for RBACK to show component
export const CustomRoutesLTR = [
  {
    path: "/",
    component: <LoginV3 />,
    moduleKey: "DASHBOARD",
  },
  {
    path: "/change-password",
    component: <ChangePasswordScreen />,
    moduleKey: modules?.MY_PROFILE,
    functionKey: myProfileFunctionality.CHANGE_PASSWORD,
    key: "Change Password",
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
    moduleKey: "DASHBOARD",
    // functionalityKey: "",
  },
  {
    path: "/customer-management",
    component: <CustomerManagement />,
    key: "Overview",
    moduleKey: "CUSTOMER_MANAGEMENT",
    // functionalityKey: "",
  },
  {
    path: "/customer-management/add-customer",
    component: <RegisterUserComponent />,
    moduleKey: "CUSTOMER_MANAGEMENT",
    // functionalityKey: "EDIT_CUSTOMER",
    key: "Add Customer",
  },
  {
    path: "/customer-management/:id",
    component: <CustomerDetails />,
    accessKey: "customerDetails",
    moduleKey: "CUSTOMER_MANAGEMENT",
    key: "Customer Details",
  },
  {
    path: "/customer-management/add-customer",
    component: <RegisterUserComponent />,
    accessKey: "addCustomer",
    moduleKey: "CUSTOMER_MANAGEMENT",
    // functionalityKey: "VIEW_CUSTOMER",
  },
  {
    path: "/view-stats",
    component: <ViewStatsNewScreen />,
    moduleKey: "STATISTICS",
  },

  {
    path: routesConstants.PLAN_MANAGEMENT,
    component: <PlanManagement />,
    moduleKey: "PLAN_MANAGEMENT",
  },
  {
    path: routesConstants.ADD_PLAN_MANAGEMENT,
    component: <SelectPLanCategory />,
    moduleKey: "PLAN_MANAGEMENT",
    // functionalityKey: "ADD_PLAN",
  },
  {
    path: routesConstants.ADD_EDIT_PLAN_DETAIL,
    component: <AddEditRentalPlan />,
    moduleKey: "PLAN_MANAGEMENT",
    // functionalityKey: "ADD_PLAN",
  },
  {
    path: routesConstants.ADD_EDIT_PLAN_WITH_ID_DETAIL,
    component: (
      <AddEditRentalPlan
      // accessKey={"planManagement"}
      // editRole={true}
      // addRole={false}
      // viewRole={false}
      />
    ),
    moduleKey: "PLAN_MANAGEMENT",
    // functionalityKey: "ADD_PLAN",
  },
  {
    path: routesConstants.CREATE_SURGE,
    component: <CreateSurge />,
    moduleKey: modules?.SURGE_MANAGEMENT,
    // functionalityKey: "CREATE_SURGE",
  },
  {
    path: routesConstants.CREATE_SURGE_WITH_ID_DETAIL,
    component: <CreateSurge />,
    moduleKey: modules?.SURGE_MANAGEMENT,
    // functionalityKey: "CREATE_SURGE",
  },
  {
    path: routesConstants.SURGE_LIST,
    component: <SurgeList />,
    moduleKey: modules?.SURGE_MANAGEMENT,
    // functionalityKey: "LIST_SURGE",
  },
  {
    path: routesConstants.INVENTORY_DETAILS,
    component: <InventoryDetails />,
    moduleKey: modules.INVENTORY_MANAGEMENT,
    functionalityKey: inventoryFunctionality.VIEW_INVENTORY_DETAILS,
  },
  {
    path: "/inventory-management",
    component: <InventoryDashboard accessKey={"inventory"} />,
    moduleKey: modules.INVENTORY_MANAGEMENT,
    functionalityKey: inventoryFunctionality.LIST_ALL_VEHICLE_INVENTORY,
  },
  {
    path: routesConstants.ALL_VEHICLES,
    component: <AllVehicles />,
    moduleKey: modules?.VEHICLE_MANAGEMENT,
    // functionalityKey: "LIST_VEHICLES",
  },
  {
    path: routesConstants.ADD_EDIT_VEHICLE,
    component: <AddNewVehicle />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.ADD_VEHICLE,
  },
  {
    path: routesConstants.ADD_EDIT_VEHICLE_WITH_ID_DETAILS,
    component: <AddNewVehicle />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.EDIT_VEHICLE,
  },

  {
    path: routesConstants.VEHICLE_STATUS_UPDATE,
    component: <VehicleStatusUpdate />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey:
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_STATUS_CHANGE,
  },
  {
    path: routesConstants.APPROVE_REJECT_VEHICLE,
    component: <ApproveRejectVehicle />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey:
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_APPROVE_REJECT,
  },
  {
    path: routesConstants.BULK_UPLOAD,
    component: <BulkUpload />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.VEHICLE_BULK_UPLOAD,
  },
  {
    path: routesConstants.VEHICLE_TRANSFER_HISTORY,
    component: <VehicleTransferhistory />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.VEHICLE_TRANSFER,
  },
  {
    path: routesConstants.VEHICLE_TRANSFER,
    component: <VehicleTranfer />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.VEHICLE_TRANSFER,
  },
  {
    path: `${routesConstants.VIEW_VEHICLE}:id`,
    component: <VehicleDetails />,
    moduleKey: modules.VEHICLE_MANAGEMENT,
    functionalityKey: vehicleManagementFunctionalities.VIEW_VEHICLE,
  },
  {
    path: routesConstants.MANUAL_BOOKING,
    component: <NewManualBooking accessKey={"manualBooking"} />,
    moduleKey: "BOOKING_MANAGEMENT",
    // functionalityKey: "CREATE_BOOKING",
  },

  {
    path: routesConstants.ALL_BOOKINGS,
    component: <BookingManagement accessKey={"bookingManagement"} />,
    moduleKey: "BOOKING_MANAGEMENT",
    // functionalityKey: "LIST_BOOKINGS",
  },
  {
    path: `${routesConstants.BOOKING_DETAILS}/:id`,
    component: <BookingDetails />,
    moduleKey: "BOOKING_MANAGEMENT",
    // functionalityKey: "VIEW_BOOKING",
  },
  {
    path: `${routesConstants.MODIFY_BOOKING_DETAILS}/:id`,
    component: <ModifyBookingDetails />,
    moduleKey: "BOOKING_MANAGEMENT",
    functionalityKey: "MODIFY_BOOKING",
  },
  {
    path: routesConstants.PAYMENT_MANAGEMENT,
    component: <PaymentManagement />,
    moduleKey: modules?.PAYMENT_MANAGEMENT,
    // functionalityKey: "PAYMENT_MANAGEMENT_LIST",
  },
  {
    path: routesConstants.ADD_BUSINESS_WAREHOUSE_MANAGEMENT,
    component: <AddEditViewWarehouse addRole={true} />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
    // functionalityKey: "ADD_WAREHOUSE",
  },
  {
    path: `${routesConstants.EDIT_BUSINESS_WAREHOUSE_MANAGEMENT}:id`,
    component: <AddEditViewWarehouse editRole={true} />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
    // functionalityKey: "EDIT_WAREHOUSE",
  },

  {
    path: "/business-management/warehouse-management/view-warehouse",
    component: <AddEditViewWarehouse viewRole={true} />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
    // functionalityKey: "VIEW_WAREHOUSE",
  },
  {
    path: `${routesConstants.TASK_SEQUENCE}`,
    component: <TaskSequence />,
    key: "Task Management",
    // moduleKey: "TASK_MANAGEMENT",
    accessKey: "taskManagement",
    moduleKey: "TASK_MANAGEMENT",
    // functionalityKey: "LIST_TASK_SEQUENCE",
  },
  {
    path: `${routesConstants.TASK_ASSIGNMENT}`,
    component: <TaskAssignment />,
    // moduleKey: "TASK_MANAGEMENT",
    key: "Task Management",
    accessKey: "taskManagement",
    moduleKey: "TASK_MANAGEMENT",
    // functionalityKey: "LIST_TASK_SEQUENCE",
  },
  {
    path: `${routesConstants.DRAG_TASK_SEQUENCE}`,
    component: <DragDropTaskSequence />,
    key: "Task Management",
    accessKey: "taskManagement",
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: routesConstants?.REPORTS,
    component: <Reports />,
    moduleKey: modules?.REPORTS,
  },
  {
    path: "/promotions",
    component: <PromotionsScreen accessKey={"promotions"} />,
    moduleKey: "PROMOTIONS",
    // functionalityKey: "LIST_PROMOTIONS",
  },
  {
    path: "/promotions/add-promotions",
    component: <AddEditPromotionsScreen addRole={true} />,
    moduleKey: "PROMOTIONS",
    // functionalityKey: "EDIT_PROMOTION",
  },
  {
    path: "/promotions/view-promotions/:id",
    component: <AddEditPromotionsScreen viewRole={true} />,
    moduleKey: "PROMOTIONS",
    // functionalityKey: "VIEW_PROMOTION",
  },
  {
    path: "/promotions/edit-promotions/:id",
    component: <AddEditPromotionsScreen editRole={true} />,
    moduleKey: "PROMOTIONS",
    // functionalityKey: "EDIT_PROMOTION",
  },
  {
    path: "/promotions/view-promotions-stats/:id",
    component: <CouponStats />,
    moduleKey: "PROMOTIONS",
    // functionalityKey: "VIEW_PROMOTION",
  },
  {
    path: "/slotPause",
    component: <SlotPauseScreen accessKey={"slotPause"} />,
    accessKey: "slotPause",
    moduleKey: "SLOT_PAUSE",
  },
  {
    path: "/slotPause/addSlotPause",
    component: <AddEditSlotPause addRole={true} />,
    moduleKey: "SLOT_PAUSE",
    // functionalityKey: "ADD_SLOT_PAUSE",
  },
  {
    path: "/slotPause/editSlotPause/:id",
    component: <AddEditSlotPause editRole={true} />,
    moduleKey: "SLOT_PAUSE",
    // functionalityKey: "ADD_SLOT_PAUSE",
  },
  {
    path: "/slotPause/viewSlotPause/:id",
    component: <AddEditSlotPause viewRole={true} />,
    moduleKey: "SLOT_PAUSE",
    // functionalityKey: "LIST_SLOT_PAUSE",
  },

  {
    path: "/task-list-management",
    component: <TaskManagementScreen accessKey={"taskManagement"} />,
    accessKey: "taskManagement",
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: "/task-sequence",
    component: <TaskSequence />,
    moduleKey: "TASK_MANAGEMENT",
    key: "Task Sequence",
  },
  {
    path: "/drag-task-sequence/:id",
    component: <DragDropTaskSequence />,
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: "/drag-task-sequence",
    component: <DragDropTaskSequence />,
    moduleKey: "TASK_MANAGEMENT",
  },

  {
    path: "/feedback-management",
    component: <NewFeedbackManagement />,
    moduleKey: "FEEDBACK_MANAGEMENT",
  },
  {
    path: routesConstants.BUSINESS_OVERVIEW,
    component: <BusinessOverview />,
    moduleKey: "BUSINESS_MANAGEMENT",
  },
  //
  {
    path: routesConstants.ROLE_MANAGEMENT,
    component: <RoleDashboard />,
    moduleKey: modules?.ROLE_MANAGEMENT,
    functionalityKey:
      roleManagementFunctionality.ROLE_MANAGEMENT_LIST ||
      roleManagementFunctionality.ADD_NEW_USER,
  },
  {
    path: routesConstants.ADD_ROLE,
    component: <AddEditRole />,
    moduleKey: modules?.ROLE_MANAGEMENT,
    functionalityKey: roleManagementFunctionality.ADD_NEW_USER,
  },
  {
    path: routesConstants.ADD_ROLE_BY_ID,
    component: <AddEditRole />,
    moduleKey: modules?.ROLE_MANAGEMENT,
    functionalityKey: roleManagementFunctionality.UPDATE_USER,
  },
  {
    path: routesConstants.VIEW_ROLE_USER_DETAILS,
    component: <ViewRoleUserDetails />,
    moduleKey: modules?.ROLE_MANAGEMENT,
    functionalityKey:
      roleManagementFunctionality.ROLE_MANAGEMENT_LIST ||
      roleManagementFunctionality.UPDATE_USER,
  },
  {
    path: routesConstants.VIEW_ROLE_USER_DETAILS_BY_ID,
    component: <ViewRoleUserDetails />,
    moduleKey: modules?.ROLE_MANAGEMENT,
    functionalityKey:
      roleManagementFunctionality.ROLE_MANAGEMENT_LIST ||
      roleManagementFunctionality.UPDATE_USER,
  },
  //
  {
    path: routesConstants.BUSINESS_CITY_MANAGEMENT,
    component: <BusinessCity />,
    moduleKey: "CITY_MANAGEMENT",
  },
  {
    path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    component: <WarehouseManagement />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
  },
  {
    path: routesConstants.HELMET_CONFIGURATION,
    component: <HelmetConfiguration />,
    moduleKey: "HELMET_CONFIGURATION",
  },
  {
    path: "/master-management/model-module",
    component: <ModelManagementScreen />,
    moduleKey: "MODEL_MANAGEMENT",
  },

  {
    path: "/master-management/model-module/add-model",
    component: <AddEditModelManagementScreen addRole={true} />,
    moduleKey: "MODEL_MANAGEMENT",
  },
  {
    path: "/master-management/model-module/edit-model/:id",
    component: <AddEditModelManagementScreen editRole={true} />,
    moduleKey: "MODEL_MANAGEMENT",
  },
  {
    path: "/master-management/model-module/view-model/:id",
    component: <ViewModelManagementScreen viewRole={true} />,
    moduleKey: "MODEL_MANAGEMENT",
  },
  {
    path: "/master-management/advertisements",
    component: <AdvertisementManagement />,
    moduleKey: "ADVERTISEMENT",
  },
  {
    path: "/master-management/advertisements/add-advertisement",
    component: <AddEditAdvertisement addRole={true} />,
    moduleKey: "ADVERTISEMENT",
  },
  {
    path: "/task-sequence",
    component: <TaskSequence />,
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: "/drag-task-sequence/:id",
    component: <DragDropTaskSequence />,
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: "/task-assignment/:id",
    component: <TaskAssignment />,
    moduleKey: "TASK_MANAGEMENT",
  },
  {
    path: "/master-management/advertisements/edit-advertisement/:id",
    component: <AddEditAdvertisement editRole={true} />,
    moduleKey: "ADVERTISEMENT",
  },
  {
    path: routesConstants.IOT_LISTING,
    component: <IotDashboardRentals />,
    moduleKey: modules.IOT_MANAGEMENT,
  },
  {
    path: routesConstants.ADD_IOT,
    component: <IotViewEditDevice addRole={true} />,
    moduleKey: modules.IOT_MANAGEMENT,
    functionalityKey: iotManagementFunctionality.ADD_DEVICE,
  },
  {
    path: `${routesConstants.EDIT_IOT}/:deviceId`,
    component: <IotViewEditDevice editRole={true} />,
    moduleKey: modules.IOT_MANAGEMENT,
    functionalityKey: iotManagementFunctionality.VIEW_DEVICE, // iot edit and view has same screen
  },
  {
    path: routesConstants.BULK_UPLOAD_IOT,
    component: <BulkUploadIot />,
    moduleKey: modules.IOT_MANAGEMENT,
    functionalityKey: iotManagementFunctionality.DEVICE_BULK_ACTIONS,
  },
  {
    path: routesConstants.ADD_HELMET_CONFIGURATION,
    component: <HelemetPriceConfiguration />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
  },
  {
    path: `${routesConstants.EDIT_HELMET_CONFIGURATION}:id`,
    component: <HelemetPriceConfiguration />,
    moduleKey: "WAREHOUSE_MANAGEMENT",
  },
  {
    path: routesConstants.OPERATOR_MANAGEMENT_LEADS,
    component: <OperatorLeadListing />,
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    // // functionalityKey: "ADD_WAREHOUSE",
  },
  {
    path: `${routesConstants.OPERATOR_MANAGEMENT_LEAD_DETAILS}/:id`,
    component: <OperatorLeadDetails />,
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    // functionalityKey: "VIEW_BOOKING",
  },
  {
    path: `${routesConstants.APPROVED_OPERATOR}`,
    component: <ApprovedOpreators />,
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    // functionalityKey: "VIEW_BOOKING",
  },
  {
    path: `${routesConstants.APPROVED_OPERATOR_DETAILS}/:id`,
    component: <ApprovedLeadDetails />,
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    // functionalityKey: "VIEW_BOOKING",
  },
  {
    path: `${routesConstants.ACCOUNT_DETAILS}`,
    component: <ApprovedLeadDetails />,
    moduleKey: modules?.OPERATOR_MY_ACCOUNT,
    functionalityKey: operatorFunctionality.OPERATOR_MY_ACCOUNT_INFO,
  },
  {
    path: `${routesConstants.LEAD_DETAILS}/:id`,
    component: <LeadDetails />,
    moduleKey: modules?.OPERATOR_MANAGEMENT,
    // functionalityKey: "VIEW_BOOKING",
  },
];
