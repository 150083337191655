import React from "react";
import CustomModal from "../../../../../sharedComponents/molecules/Modals/CustomModal";
import rentalEn from "../../../../locale/rental-en.json";
import TextAreaInput from "../../../../../sharedComponents/atoms/InputFields/TextAreaInput";
import { Box } from "@mui/material";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { ApprovalOptions } from "../../../../constants/constants";

const RejectReasonModal = (props: any) => {
  const {
    modalData,
    handleModalClose,
    onSubmit,
    handleChange,
    rejectReason = "",
  } = props;
  const { documentId = "", isOpen = false } = modalData;
  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleModalClose}
      title={rentalEn.operatorManagement.rejectReason}
      containerStyle={{ pb: 0 }}
    >
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextAreaInput
          value={rejectReason || ""}
          placeholder={rentalEn?.operatorManagement?.rejectReason}
          multiline
          minRows={3}
          autoFocus
          sx={{ width: "100%", marginBottom: "14px" }}
          onChange={(e: any) => {
            handleChange("rejectReason", e.target.value);
          }}
          inputProps={{ maxLength: 300 }}
        />
        <CustomButton
          label={rentalEn.global.reject}
          variant="contained"
          disabled={rejectReason.trim().length < 2}
          onClick={() => onSubmit(documentId, ApprovalOptions.REJECTED)}
        />
      </Box>
    </CustomModal>
  );
};

export default RejectReasonModal;
