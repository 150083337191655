import { GET_REPORTS } from "../../actions/actionTypes";
import { reportDownloadInterface } from "../actionInterfaces";

const initialState = {
  reportData: [],
  allDataloading: true,
  allData: "",
  resetGenericReportData: false,
  resetUserReportData: false,
  resetPaymentReportData: false,
  resetBookingReportData: false,
  genericDownloadLoader: false,
  isReportDownload: false,
  genericReportKeys: null,
  genericReportDownloadData: [],
};

const reportDownloadReducer = (
  state = initialState,
  action: reportDownloadInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    //  actionTypes.GET_REPORTS.RESET_DOWNLOAD_STATUS
    case GET_REPORTS.RESET_DOWNLOAD_STATUS:
      return {
        ...state,
        isReportDownload: false,
      };
    case GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
      };
    case GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_SUCCESS:
      return {
        ...state,
        allDataloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
      };

    case GET_REPORTS.DOWNLOAD_USER_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
        isReportDownload: false,
      };
    case GET_REPORTS.DOWNLOAD_USER_REPORTS_SUCCESS:
      return {
        ...state,
        allDataloading: false,
        allData: payload.allData,
        resetUserReportData: true,
        isReportDownload: true,
      };
    case GET_REPORTS.DOWNLOAD_USER_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
        resetUserReportData: true,
      };

    case GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
      };
    case GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_SUCCESS:
      return {
        ...state,
        allDataloading: false,
        allData: payload.allData,
        resetBookingReportData: true,
      };
    case GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
        resetBookingReportData: true,
      };

    case GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
      };
    case GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_SUCCESS:
      return {
        ...state,
        allDataloading: false,
        allData: payload.allData,
        resetPaymentReportData: true,
      };
    case GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
        resetPaymentReportData: true,
      };
    case GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
      };
    case GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_SUCCESS:
      return {
        ...state,
        allDataloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
      };

    case GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_REQUESTED:
      return {
        ...state,
        allDataloading: true,
        genericDownloadLoader: true,
      };

    case GET_REPORTS.GET_ALL_GENERIC_REPORT_KEYS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_SUCCESS:
      return {
        ...state,
        genericDownloadLoader: false,
        resetGenericReportData: true,
        ...payload,
      };
    case GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_SUCCESS:
      return {
        ...state,
        genericDownloadLoader: false,
        resetGenericReportData: false,
        ...payload,
      };
    case GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_FAILED:
      return {
        ...state,
        allDataloading: false,
        resetGenericReportData: false,
        genericDownloadLoader: false,
      };
    case GET_REPORTS.RESET_ALLDATA:
      // alert()
      return {
        ...state,
        resetGenericReportData: false,
      };
    case GET_REPORTS.RESET_USER_REPORT_DATA:
      // alert()
      return {
        ...state,
        resetUserReportData: false,
      };

    case GET_REPORTS.RESET_PAYMENT_REPORT_DATA:
      // alert()
      return {
        ...state,
        resetPaymentReportData: false,
      };

    case GET_REPORTS.RESET_BOOKING_REPORT_DATA:
      // alert()
      return {
        ...state,
        resetBookingReportData: false,
      };

    default:
      return { ...prevState };
  }
};

export default reportDownloadReducer;
