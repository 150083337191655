//ONlY UI IGNORE THIS FILE
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { isArray } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { RootState } from "../../../../../../../redux/store";
import { CustomButton } from "../../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../../../themes/colors";
import { BREADCRUMBS_RENTALS } from "../../../../../../constants/activeModule";
import {
  GenericObject,
  TypesOfFile,
  VehicleDocumentType,
  typographyConstants,
} from "../../../../../../constants/constants";
import {
  DeleteIcon,
  GreenTickIcon,
  UploadFileIcon,
} from "../../../../../../constants/exportImages";
import rentalEn from "../../../../../../locale/rental-en.json";
import { vehicleActions } from "../../../../../../redux/actions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../../../redux/actions/rentalCommonAction";
import {
  convertBytesToKB,
  serialDateToDD_MM_YYYY,
} from "../../../../../../utils/helper";
import styles from "../section.module.css";
import BulkUploadZipModal from "./BulkUploadZipModal";
import BulkUploadErrorModal from "./bulkUploadErrorModal";

const uploadError: GenericObject = {
  "file-invalid-type": "Select valid file format",
  "file-too-large": "Select file less than 5MB",
  "too-many-files": "To many Files ",
};

const sheet1Col = [
  "chassisNumber",
  "engineNumber",
  "colour",
  "manufacturingYear",
  "lob",
  "branchName",
  "modelName",
  "useCase",
  "purchaseDate(DD-MM-YYYY)",
  "registrationDate(DD-MM-YYYY)",
];

const sheet2Col = [
  "documentName",
  "startDate(DD-MM-YYYY)",
  "expiryDate(DD-MM-YYYY)",
  "chassisNumber",
  "engineNumber",
  "registrationNumber",
  "type",
];

// locale data destructure
const { VehicleManagement } = rentalEn;
const { vehicleUploadSheets } = VehicleManagement;

export const BulkUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // vehicle managemnet api bulk upload data
  const {
    downloadBulkUploadLoader,
    uploadBulkUploadSheetData: { requestId, status },
    uploadBulkUploadLoader,
    isUploadBulkSheetUploaded,
    vehicleAttachmentsData,
    failedUploadBulksheetData,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  // excel sheet upload handle
  const [uploaded, setIsUploaded] = useState({
    excelUploaded: false,
    acceptedExcelFiledata: { name: "", size: 0 },
  });

  // zip sheet upload handle
  const [uploadedZip, setIsUploadedZip] = useState({
    zipUploaded: false,
    acceptedZipFileData: { name: "", size: 0 },
    zipFile: "",
  });

  //state to temp store excel file
  const [excel, setExcel] = useState<GenericObject>({ sheet1: [], sheet2: [] });

  //state to toggle zip upload model
  const [openZipModal, setOpenZipModal] = useState<boolean>(false);

  // state for error modal
  const [open, setOpen] = useState(false);

  // state to check is zip to be ulpoaded
  const [isUploadZip, setIsUploladZip] = useState<boolean>(false);

  // side effect of on mounting
  useEffect(() => {
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.BULK_ACTIONS_BULK_UPLOAD));

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
      //clear data
      const payload = {
        isUploadBulkSheetUploaded: false,
        vehicleAttachmentsData: [],
        uploadBulkUploadLoader: false,
        downloadBulkUploadLoader: false,
        uploadBulkUploadSheetData: { requestId: "", status: "" },
      };
      dispatch(vehicleActions.clearAttachmentIdBulkUpload(payload));
    };
  }, []);

  const Bull = (
    <Box
      component="span"
      sx={{
        mx: "2px",
        transform: "scale(1)",
        color: colors.text_secondary_gray,
        fontSize: "16px",
      }}
    >
      •
    </Box>
  );

  // close the error modal
  const handleCloseZipModal = () => {
    const payload = {
      isUploadBulkSheetUploaded: false,
    };
    dispatch(vehicleActions.clearAttachmentIdBulkUpload(payload));
    setOpenZipModal(false);
  };

  const onDropExcel = useCallback(
    (acceptedFiles: object[], fileRejections: object[], e: any) => {
      const file: any = acceptedFiles[0];

      //error if file does not exist
      let error: boolean = false;
      fileRejections.forEach((item: any) => {
        if (item?.errors[0]?.code) {
          error = true;
          toastr.warning(
            "",
            uploadError[item.errors[0]?.code] ??
              rentalEn?.errorMessages?.SomethingWentWrong
          );
          return;
        }
      });
      if (error) return;
      setIsUploaded((prev: any) => ({
        ...prev,
        acceptedExcelFiledata: acceptedFiles[0],
      }));
      handleFileChangeExcel(e);
    },

    []
  );

  // on drop zip
  const onDropZip = useCallback(
    (acceptedFiles: any, fileRejections: any, e: any) => {
      let files: GenericObject = acceptedFiles[0];
      let fileNames: string = acceptedFiles[0]?.name;
      // let rejectedFiles: object[] = [];

      let error: boolean = false;
      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code) {
          error = true;
          toastr.warning(
            "",
            uploadError[item.errors[0]?.code] ??
              rentalEn?.errorMessages?.SomethingWentWrong
          );
          return;
        }
      });
      if (error) return;

      setIsUploadedZip((prev: any) => ({
        ...prev,
        zipUploaded: true,
        acceptedZipFileData: acceptedFiles[0],
      }));

      //get signed url
      let payload = {
        requestdata: {
          data: {
            attachments: [fileNames],
            type: VehicleDocumentType.VEHICLE_DOCUMENT,
          },
        },
        filesData: {
          files: files,
          fileName: fileNames,
        },
      };
      dispatch(vehicleActions.getKYCSignedUrl(payload));
    },
    []
  );

  // for excel  dropzone
  const {
    getRootProps: getRootPropsExcel,
    getInputProps: getInputPropsExcel,
    isDragActive: isDragActiveExcel,
  } = useDropzone({
    onDrop: onDropExcel,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  //for zip upload
  const {
    getRootProps: getRootPropsZip,
    getInputProps: getInputPropsZip,
    isDragActive: isDragActiveZip,
  } = useDropzone({
    onDrop: onDropZip,
    accept: {
      "application/zip": [".zip"],
      "application/x-zip-compressed": [".zip"],
      "multipart/x-zip": [".zip"],
      "application/octet-stream": [".zip"],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  //handle when both uploaded excel files
  const handleFileChangeExcel = async (e: any) => {
    try {
      let files = e.target.files;
      // file = files[0];

      let file: any = files[0];
      let json: any;

      if (file) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const data = e.target?.result;

          if (typeof data === "string" || data instanceof ArrayBuffer) {
            const workbook = XLSX.read(data, { type: "binary" });

            // Get the first sheet
            const ws = workbook.Sheets[vehicleUploadSheets.VehicleUploadData];
            const ws2 = workbook.Sheets[vehicleUploadSheets.DocumentUploadData];
            const sheet1 = convertExcelToJson(ws, "__EMPTY_1");
            const sheet2 = convertExcelToJson(ws2, "__EMPTY_8");

            if (!sheet1?.length && !sheet2.length) {
              toastr.error("", rentalEn?.errorMessages?.blankExcelFileError);
              return;
            }

            setExcel((prev: any) => ({
              ...prev,
              sheet1: sheet1,
              sheet2: sheet2,
            }));
          }
        };

        reader.readAsBinaryString(file);
        setIsUploaded((prev: any) => ({
          ...prev,
          excelUploaded: true,
        }));
      }
      return json;
    } catch (err) {
      console.error(err);
    }
  };

  //convert excel to json format .filter out empty rows
  const convertExcelToJson = (worksheet: any, empty: any) => {
    let json: any;
    json = XLSX.utils.sheet_to_json(worksheet, {
      defval: null,
      raw: true,
    });

    json = json.filter((row: any) => {
      const empty1HasValue: any =
        Object.prototype.hasOwnProperty.call(row, empty) && row[empty];

      if (empty1HasValue) {
        return false;
      }

      const hasNonEmptyCell = Object.values(row).some(
        (cell) => cell !== null && cell !== undefined && cell !== ""
      );

      return hasNonEmptyCell;
    });

    return json;
  };

  //add bulk request Sucess callback
  const successCallback = () => {
    setOpenZipModal(true);
  };

  //on sucess redirect to vehicle managemnt screen
  const bulkInitiateSucessCallback = () => {
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  // call on submit button  if error show on modal and then call bulk request initiate

  //add bulk request api
  const addBulkRequestApi = () => {
    const { sheet1, sheet2 } = excel;

    // check if all fields are filled
    const vehicleDataSheet = sheet1?.map((data: any) => {
      return {
        modelName: data?.modelName,
        branchName: data?.branchName,
        lob: data?.lob,
        useCase: data?.useCase,
        colour: data?.colour,
        purchaseDate: serialDateToDD_MM_YYYY(data["purchaseDate(DD-MM-YYYY)"]),
        registrationNumber: `${data?.registrationNumber}`,
        engineNumber: `${data.engineNumber}`,
        chassisNumber: `${data.chassisNumber}`,
        manufacturingYear: data?.manufacturingYear,
        registrationDate: serialDateToDD_MM_YYYY(
          data["registrationDate(DD-MM-YYYY)"]
        ),
      };
    });

    const vehicleDocumentData = sheet2?.map((data: any) => {
      return {
        type: data.type,
        documentName: data.documentName,
        startDate: serialDateToDD_MM_YYYY(data["startDate(DD-MM-YYYY)"]),
        expiryDate: serialDateToDD_MM_YYYY(data["expiryDate(DD-MM-YYYY)"]),
        engineNumber: `${data.engineNumber}`,
        chassisNumber: `${data.chassisNumber}`,
      };
    });

    const payload = {
      data: {
        vehicleData: vehicleDataSheet,
        vehicleDocumentData: vehicleDocumentData,
      },
      successCallback,
      bulkInitiateSucessCallback: bulkInitiateSucessCallback,
    };

    dispatch(vehicleActions.uploadBulkUploadSheet(payload));
  };

  //bulk request initiate
  const bulkRequestInitiate = () => {
    const payload = {
      data: {
        requestId: requestId,
        attachmentId: vehicleAttachmentsData[0]?.attachmentId, // Optional use when upload document
      },
      successCallback: bulkInitiateSucessCallback,
    };
    dispatch(vehicleActions.bulkRequestInitiate(payload));
  };

  //zip upload on click
  const onClickZipUpload = () => {
    setIsUploladZip(true);
    setOpenZipModal(false);
  };

  //file upload for zip and excel
  const fileUpload = (
    uploaded: boolean,
    fileName: string,
    fileSize: number,
    onClickDelete: Function,
    getRootProps: Function,
    getInputProps: Function,
    isDragActive: boolean
  ) => {
    return (
      <Box sx={{ width: "100%" }}>
        {uploaded ? (
          <Fade>
            <Box {...getRootProps()} onClick={(e: any) => e.stopPropagation()}>
              <Box component="label" className={styles.fileUploadUI}>
                <input {...getInputProps()} />
                <img src={UploadFileIcon} alt="" />
                {isDragActive ? (
                  <Typography>{rentalEn?.global?.dropHere}</Typography>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Fade>
        ) : (
          <Fade>
            <Box className={styles.fileUploadIN}>
              <Box display={"flex"} gap="20px" alignItems={"center"}>
                <img src={UploadFileIcon} alt="" />
                <Stack gap="10px">
                  <Typography
                    variant={typographyConstants?.SUBHEADING}
                    color={colors.text_secondary_gray}
                  >
                    {fileName}
                  </Typography>
                  <Box display="flex" gap="20px">
                    <Typography
                      variant={typographyConstants?.BODY}
                      color={colors.text_secondary_gray}
                    >
                      {convertBytesToKB(fileSize)} KB
                    </Typography>
                    <Typography
                      variant={typographyConstants?.BODY}
                      color={colors.text_secondary_gray}
                    >
                      <img src={GreenTickIcon} /> Completed
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <img
                src={DeleteIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => onClickDelete()}
              />
            </Box>
          </Fade>
        )}
      </Box>
    );
  };

  //handle submikt sheet
  const handleSubmitSheet = () => {
    if (!excel?.sheet1?.length && !excel?.sheet2?.length) {
      toastr.warning("Alert", rentalEn?.errorMessages.blankExcelFileError);
      return;
    }

    let validateSheet1 = validateKeys(sheet1Col, excel?.sheet1);
    if (!validateSheet1) return;

    if (excel.sheet2?.length) {
      let validateSheet2 = validateKeys(sheet2Col, excel?.sheet2);
      if (!validateSheet2) return;
    }

    if (isUploadBulkSheetUploaded) {
      bulkRequestInitiate();
    } else addBulkRequestApi();
  };

  //validate mandatory keys
  const validateKeys = (keysArr: string[], sheet: any = []): boolean => {
    for (let i = 0; i < sheet.length; i++) {
      const data: any = sheet[i];
      for (let j = 0; j < keysArr?.length; j++) {
        if (!data[keysArr[j]]) {
          toastr.warning(
            "Alert",
            rentalEn?.errorMessages.excelValuesAreNotCorrectError
          );
          return false;
        }
      }
    }
    return true;
  };

  //disable submit button
  const disableSubmitButton = (): boolean => {
    if (isUploadZip) {
      if (uploadedZip?.zipUploaded) return true;
    } else {
      if (uploaded?.excelUploaded) return true;
    }

    return false;
  };

  //delete file
  const deleteUplodeFile = (key: string = "") => {
    if (key === TypesOfFile.excel) {
      setExcel({ sheet1: [], sheet2: [] });

      setIsUploaded((prev: any) => ({
        ...prev,
        excelUploaded: false,
        acceptedExcelFiledata: { name: "", size: 0 },
      }));

      setIsUploadedZip((prev: any) => ({
        ...prev,
        zipUploaded: false,
        zipFile: "",
        acceptedZipFileData: { name: "", size: 0 },
      }));

      setIsUploladZip(false);

      const payload = {
        vehicleAttachmentsData: [],
        isUploadBulkSheetUploaded: false,
      };
      dispatch(vehicleActions.clearAttachmentIdBulkUpload(payload));
    } else if (key === TypesOfFile.zip) {
      setIsUploadedZip((prev: any) => ({
        ...prev,
        zipUploaded: false,
        zipFile: "",
        acceptedZipFileData: { name: "", size: 0 },
      }));

      // setIsUploladZip(false);

      if (vehicleAttachmentsData?.length) {
        const payload = {
          vehicleAttachmentsData: [],
        };
        dispatch(vehicleActions.clearAttachmentIdBulkUpload(payload));
      }
    }
  };

  //steps in bulk upload
  const steps = [
    {
      label: VehicleManagement?.downloadExcelFormat,
      description: (
        <>
          {/* download button */}
          <CustomButton
            label={rentalEn?.global?.download}
            variant="outlined"
            onClick={() => {
              dispatch(vehicleActions.downloadExcelSheetBulkUpload());
            }}
            sx={{ minWidth: 150 }}
            loading={downloadBulkUploadLoader}
            //   startIcon={<FileDownloadIcon />}
          />
        </>
      ),
    },
    {
      label: VehicleManagement?.UploadFilledExcelFormat,
      description: fileUpload(
        !uploaded?.excelUploaded,
        uploaded?.acceptedExcelFiledata?.name,
        uploaded?.acceptedExcelFiledata?.size,
        () => deleteUplodeFile("excel"),
        getRootPropsExcel,
        getInputPropsExcel,
        isDragActiveExcel
      ),
    },
    {
      label: VehicleManagement?.uploadZipFileForDocuments,
      description: fileUpload(
        !uploadedZip?.zipUploaded,
        uploadedZip?.acceptedZipFileData?.name,
        uploadedZip?.acceptedZipFileData?.size,
        () => deleteUplodeFile("zip"),
        getRootPropsZip,
        getInputPropsZip,
        isDragActiveZip
      ),
      isShow: isUploadZip,
      key: "zipUpload",
    },
    {
      label: (
        <CustomButton
          label={rentalEn?.buttonLabels?.submitFile}
          variant="contained"
          onClick={handleSubmitSheet}
          disabled={!disableSubmitButton()}
          loading={uploadBulkUploadLoader}
          sx={{ minWidth: 200 }}
        />
      ),
      // description: ,
    },
  ];

  // side effect to show upload error modal or on success to navigate back
  useEffect(() => {
    if (
      (failedUploadBulksheetData?.invalidEntries &&
        isArray(failedUploadBulksheetData?.invalidEntries)) ||
      (failedUploadBulksheetData?.errors &&
        isArray(failedUploadBulksheetData?.errors))
    ) {
      setOpen(true);
    } else if (
      !failedUploadBulksheetData?.invalidEntries?.length &&
      failedUploadBulksheetData?.validEntries
    ) {
      navigate(-1);
    }
  }, [failedUploadBulksheetData]);

  // handle close errors modal
  const handleModalClose = () => {
    setOpen(!open);
  };

  return (
    <Box className={styles.bulkUploadWrapper}>
      <Stepper orientation="vertical">
        {steps.map((step, index) => {
          if (step?.key === "zipUpload" && !step?.isShow) return null;
          return (
            <Step active={true}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Box>
        <Typography
          variant={typographyConstants?.BODY}
          color={colors.text_secondary_gray}
        >
          {Bull}
          {VehicleManagement?.downloadTheProvidedExcelFormat}
        </Typography>
        <Typography
          variant={typographyConstants?.BODY}
          color={colors.text_secondary_gray}
        >
          {Bull} {VehicleManagement?.fillTheFormatAsRequired}
        </Typography>
        <Typography
          variant={typographyConstants?.BODY}
          color={colors.text_secondary_gray}
        >
          {Bull}{" "}
          {
            VehicleManagement?.uploadFilledExcelFormattoSuccessfullyUploadVehicle
          }
        </Typography>
      </Box>
      {openZipModal && (
        <BulkUploadZipModal
          setOpenZipModal={setOpenZipModal}
          openZipModal={openZipModal}
          handleCloseZipModal={handleCloseZipModal}
          onClickZipUpload={onClickZipUpload}
        />
      )}

      <BulkUploadErrorModal
        handleModalClose={handleModalClose}
        openModal={open}
        failedUploadBulksheetData={failedUploadBulksheetData}
      />
    </Box>
  );
};
