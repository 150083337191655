import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import { CookieTypes } from "../constants/enum";
import { IDefaultRequestHeaders } from "../interfaces/commonInterface";
import CONSTANTS from "../locale/en.json";
import { ACTIONS } from "../redux";
import store from "../redux/store/index";
import { BASE_URL_UAT } from "./apiUrls";
import TokenService from "./tokenService";

export const customAxios = axios.create({});

export const defaultHeaders: IDefaultRequestHeaders | any = {
  "x-platform": CONSTANTS?.requestHeaders?.["x-platform"],
  "x-channel": CONSTANTS?.requestHeaders?.["x-channel"],
  "x-client-id": CONSTANTS?.requestHeaders?.["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent ?? "",
  requestfrom: CONSTANTS?.requestHeaders?.requestfrom,
  platform: CONSTANTS?.requestHeaders?.platform,
};

customAxios.defaults.baseURL = BASE_URL_UAT;
customAxios.defaults.headers.common = { ...defaultHeaders };

let isRefreshing: boolean = false;
let refreshPromise: any = null;
let updatedAccessToken: any = null;

// This intercepts requests made using a customAxios instance. It checks if the access token is expired
// or about to expire. If so, it initiates a token refresh process if one isn't already ongoing. It then waits
// for the refresh to complete before updating the request headers with the new access token.
customAxios.interceptors.request.use(
  async (config: any) => {
    // Check if the access token is expired or about to expire
    if (TokenService.isAccessTokenExpired()) {
      // If not already refreshing, initiate token refresh
      if (!isRefreshing) {
        isRefreshing = true;
        // Create a promise to handle token refresh
        refreshPromise = new Promise((resolve, reject) => {
          // Get a new access token
          TokenService.getAccessToken()
            .then((newAccessToken) => {
              // Update the access token
              updatedAccessToken = newAccessToken;
              // Set the new access token in the request headers
              // setAuthToken(newAccessToken);
              config.headers["Authorization"] = `Bearer ${newAccessToken}`;
              // Resolve the promise with the updated config
              resolve(config);
            })
            .catch((error) => {
              // Log and reject if there's an error refreshing the token
              console.error("Error refreshing token:", error);
              toastr.error("Oops", CONSTANTS?.RefreshTokenError);
              reject(error);
              window.location.href = "/";
            })
            .finally(() => {
              // Reset the refreshing state and promise after completion
              isRefreshing = false;
              refreshPromise = null;
            });
        });
      }
      // Wait for token refresh completion
      await refreshPromise;
      // Set the updated access token in the request headers
      setAuthToken(updatedAccessToken);
      config.headers["Authorization"] = `Bearer ${updatedAccessToken}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// To store queued requests
let isTokenRefreshing: boolean = false;
let failedRequests: any = [];

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;

    if (error?.response?.status === 500) {
      store.dispatch({ type: ACTIONS.SERVER_ERROR });
      return;
    } else if (
      error?.response?.status === 401 ||
      error?.response?.data?.responseCode === 401
    ) {
      if (isTokenRefreshing) {
        // If we are already refreshing, wait for the new token
        return new Promise((resolve, reject) => {
          failedRequests.push({ resolve, reject });
        })
          .then((newToken) => {
            // Set the new token in the original request and retry it
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            // If the refresh fails, log out
            localStorage.clear();
            Cookies.remove(CookieTypes.TOKEN, { path: "/" });
            toastr.error(
              "Error",
              "Your session has timed out. Please login again."
            );
            setTimeout(() => {
              window.location.href = "/";
              setTimeout(() => {
                store.dispatch({ type: ACTIONS.AUTH_ISSUE });
              }, 1000);
            }, 1500);

            return Promise.reject(err);
          });
      }

      isTokenRefreshing = true;

      try {
        const newToken = await TokenService.getAccessToken();

        // Retry the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        const response = await axios(originalRequest);

        // Process all failed requests with the new token
        // @ts-ignore
        failedRequests?.forEach(({ resolve }) => resolve(newToken));
        failedRequests = [];

        return response;
      } catch (err) {
        // If the refresh fails, log out
        localStorage.clear();
        Cookies.remove(CookieTypes.TOKEN, { path: "/" });
        toastr.error(
          "Error",
          "Your session has timed out. Please login again."
        );
        setTimeout(() => {
          window.location.href = "/";
          setTimeout(() => {
            store.dispatch({ type: ACTIONS.AUTH_ISSUE });
          }, 1000);
        }, 1500);

        return Promise.reject(err);
      } finally {
        isTokenRefreshing = false;
      }
    } else if (error?.response && error?.response?.data) {
      // handling error in saga
      return error.response.data;
    }
    return error.message;
  }
);

export const setAuthToken = (token: string) =>
  (customAxios.defaults.headers.common = {
    ...defaultHeaders,
    Authorization: `Bearer ${token}`,
  });
