import { isArrayNotEmpty } from "../../../../utils/helper";
import {
  blockedReasonArray,
  GenericObject,
  iotDeviceTypes,
  USE_CASE_TYPES,
  vehicleCategoryName,
  VehicleStatus,
} from "../../../constants/constants";
import rentalEn from "../../../locale/rental-en.json";
import { formatString } from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";

interface initialState {
  onLoad: boolean;
  calendarData: GenericObject[];
  calenderDataLoader: boolean;
  ongoingBookingsApiResponseData: GenericObject;
  ongoingBookingsData: GenericObject[];
  upcomingBookingsApiResponseData: GenericObject;
  upcomingBookingsData: GenericObject[];
  blockedVehiclesApiResponseData: GenericObject;
  blockedVehiclesData: GenericObject[];
  vehiclesListData: GenericObject[];
  vehiclesListApiResponseData: GenericObject;
  vehicleStatusUpdateData: GenericObject;
  addNewVehicleDetailsData: GenericObject;
  vehicleDetailsByIdData: GenericObject;
  vehicleDetailsLoader: boolean;
  challanListLoader: boolean;
  challanData: GenericObject;
  getAssignedIotLoader: boolean;
  liveChallanLoader: boolean;
  pingHistoryLoader: boolean;
  pingHistoryData: GenericObject;
  iotDetails: GenericObject[];
  assignedIotVehicleData: GenericObject;
  iotDetailsPrimary: GenericObject[];
  iotDetailsSecondary: GenericObject[];
  assignedIotData: GenericObject;
  assignedIotDataPrimary: GenericObject;
  vehicleApproveRejectStatusUpdateData: any[];
  isVehicleUpdatedSucess: boolean;
  assignedIotDataSecondary: GenericObject;
  isVehicleApproveRejectStatus: boolean;
  allTransferVehicleData: any;
  vehicleTransferRequestData: any;
  isTransferVehicleSuccess: boolean;
  vehicleModelListData: GenericObject[];
  vehicleColourListData: GenericObject[];
  VehicleListDownloadableData: GenericObject[];
  vehicleStatusUpdateLoader: boolean;
  vehicleDataDownloadLoader: boolean;
  iotDetailsObject: any;
  vehicleImagesData: GenericObject[];
  vehicleEndRideImagesData: GenericObject[];
  startOdometerReadingImagesData: GenericObject[];
  endOdometerReadingImagesData: GenericObject[];
  vehicleImagesDataLoader: boolean;
  vehicleLinkChallanLoader: boolean;
  getSignedUrlLoader: boolean;
  vehicleAttachmentsData: GenericObject[];
  vehicleDocumentsData: GenericObject;
  onLoadTransferVehicleRequest: boolean;
  downloadBulkUploadLoader: boolean;
  deleteDocLoader: boolean;
  uploadBulkUploadLoader: boolean;
  uploadBulkUploadSheetData: GenericObject[];
  isUploadBulkSheetUploaded: boolean;
  docUploadS3Loader: boolean;
  failedUploadBulksheetData: GenericObject[];
  vehicleStats: GenericObject;
  addEditVehicleDetailsLoader: boolean;
}

const initialState: initialState = {
  onLoad: false,
  calendarData: [],
  calenderDataLoader: false,
  ongoingBookingsApiResponseData: {}, // Entire API response for ongoing bookings
  ongoingBookingsData: [], // Bookings data array for ongoing bookings
  upcomingBookingsApiResponseData: {}, // Entire API response for upcoming bookings
  upcomingBookingsData: [], // Bookings data array for upcoming bookings
  blockedVehiclesApiResponseData: {}, // Entire API response for blocked vehicles
  blockedVehiclesData: [], // Blocked vehicles data array
  vehiclesListData: [], // Vehicle list data for vehicle management dashboard
  vehiclesListApiResponseData: {}, // Entire API response for vehicle list
  vehicleModelListData: [], // Vehicle model list data // Based on various params such as cities, branches, and types (scooter/motorcycle)
  vehicleStatusUpdateData: {}, // Vehicle status update response data
  vehicleApproveRejectStatusUpdateData: [], // vehicle approve or reject updated data
  isVehicleApproveRejectStatus: false, // check status of vehicle
  vehicleTransferRequestData: [], // vehicle tranfer reuqest list history
  allTransferVehicleData: [], // vehicle tranfer list for particular request id
  isTransferVehicleSuccess: false, // is tranfer vehicle Sucess
  isVehicleUpdatedSucess: false, // is vehicle updated sucess
  vehicleDetailsByIdData: {}, // Vehicle details by id data object
  vehicleDetailsLoader: false,
  challanListLoader: false,
  challanData: {},
  getAssignedIotLoader: false,
  liveChallanLoader: false,
  pingHistoryLoader: false,
  pingHistoryData: {},
  assignedIotVehicleData: {},
  addNewVehicleDetailsData: {}, // Add new vehicle (vehicle details section) API response data
  iotDetails: [], // Entire IoT details API data
  iotDetailsPrimary: [], // Filtered primary IoT data
  iotDetailsSecondary: [], // Filtered secondary IoT data
  assignedIotData: {}, // Assign IoT API response data
  assignedIotDataPrimary: {}, // Primary assigned IoT data
  assignedIotDataSecondary: {}, // Secondary assigned IoT data
  vehicleColourListData: [], // Vehicle colour data array
  VehicleListDownloadableData: [], // Vehicle list data for downonLoad on vehicle management dashboard
  vehicleStatusUpdateLoader: false, // Loader for vehicle (available/blocked) status update
  vehicleDataDownloadLoader: false, // Loader for vehicle data download on vehicle management dashboard
  iotDetailsObject: {
    [iotDeviceTypes?.PRIMARY]: [],
    [iotDeviceTypes?.SECONDARY]: [],
  }, // IoT details API data
  vehicleImagesData: [], // key to store vehicle images data
  vehicleEndRideImagesData: [], // key to store end ride images
  startOdometerReadingImagesData: [], // key to store start odometer images
  endOdometerReadingImagesData: [], // key to store end odometer images
  vehicleImagesDataLoader: false, // Loader for get vehicle images data
  vehicleLinkChallanLoader: false, /// Loader for link challan
  getSignedUrlLoader: false, /// Loader for get signed url and put attachment on url
  vehicleAttachmentsData: [], // vehicle doc upload attachments data
  vehicleDocumentsData: {}, // vehicle doc uploded data
  onLoadTransferVehicleRequest: false,
  downloadBulkUploadLoader: false, // download sheet upload
  deleteDocLoader: false, // delete doc loader
  uploadBulkUploadSheetData: [],
  uploadBulkUploadLoader: false,
  isUploadBulkSheetUploaded: false,
  docUploadS3Loader: false,
  failedUploadBulksheetData: [],
  vehicleStats: [],
  addEditVehicleDetailsLoader: false, // Loader for adding or editing vehicle details
};

// Function to map the blocked reason
const getBlockedReasonLabel = (blockedReason: any) => {
  const reasonObject = blockedReasonArray.find(
    (reason) => reason.name === blockedReason
  );
  return reasonObject ? reasonObject.displayName : rentalEn?.global?.NA; // Fallback if not found
};

/**
 * Modifies the provided vehicle data list for display purposes.
 * Ensures each vehicle item conforms to specific display requirements.
 * @param data - The original array of vehicle data to be modified.
 * @returns An array of modified vehicle data suitable for display.
 */
const modifyVehicleListData = (data: any): any[] => {
  // Initialize a temporary array to hold modified vehicle data
  let tempVehiclesListData: GenericObject[] = [];

  // Check if the input data array is not empty
  if (isArrayNotEmpty(data)) {
    // Map through the data array to transform each item
    tempVehiclesListData = data?.map((item: any) => {
      // Ensure each item has a unique `id` property required by MUI data grid
      item.id = item?.id;

      // Set default values or fallbacks for display purposes
      item.modelDisplayName = item?.modelDisplayName ?? rentalEn?.global?.NA;
      item.tempRegistration =
        item?.vehicleDetails?.registration ?? rentalEn?.global?.NA;
      // @ts-ignore
      item.utilization = `${item?.utilization}%` ?? rentalEn?.global?.NA;
      item.tempLobStatus =
        item?.status === VehicleStatus?.ACTIVE
          ? item?.lobStatus
          : item?.status ?? rentalEn?.global?.NA;
      item.tempUseCase =
        item?.useCase === USE_CASE_TYPES?.RENTALS
          ? formatString(item?.useCase)
          : item?.useCase ?? rentalEn?.global?.NA;
      item.tempOperatorId =
        formatString(item?.operatorId) ?? rentalEn?.global?.NA;
      item.tempBlockedReason = getBlockedReasonLabel(item?.blockedReason);

      // Return the modified item
      return item;
    });
  }

  // Return the array of modified vehicle data
  return tempVehiclesListData;
};

/**
 * Processes payload data to extract and modify vehicle details from blockedVehiclesData.
 * @param payload - The payload object containing blockedVehiclesData.
 * @returns An array of modified vehicle details extracted from blockedVehiclesData.
 */
const processBlockedVehiclesData = (payload: any): any[] => {
  // Initialize an empty array to store temporary modified vehicle data
  let tempBlockedVehiclesData: any[] = [];

  // Check if payload contains non-empty blockedVehiclesData array
  if (isArrayNotEmpty(payload?.blockedVehiclesData)) {
    // Map over each item in blockedVehiclesData array
    tempBlockedVehiclesData = payload?.blockedVehiclesData?.map((item: any) => {
      // Extract vehicleDetails from the current item
      // const tempItem: any = item?.vehicleDetails;

      // Set default values or fallbacks for display purposes
      item.id = item?.vehicleDetails?.id;
      item.tempVehicleNumber =
        item?.vehicleDetails?.vehicleDetails?.registration ??
        rentalEn?.global?.NA;
      item.tempBlockedReason =
        formatString(item?.vehicleDetails?.blockedReason) ??
        rentalEn?.global?.NA;
      item.tempModelDisplayName =
        item?.vehicleDetails?.modelDisplayName ?? rentalEn?.global?.NA;
      item.tempCategory =
        item?.vehicleDetails?.category ===
        rentalEn?.VehicleManagement?.motorcycle
          ? vehicleCategoryName?.MOTORCYCLE
          : vehicleCategoryName?.SCOOTER ?? rentalEn?.global?.NA;
      item.tempCityDisplayName =
        item?.vehicleDetails?.cityDisplayName ?? rentalEn?.global?.NA;
      item.tempHubDisplayName =
        item?.vehicleDetails?.branchDisplayName ?? rentalEn?.global?.NA;

      // Return the modified item to be added to tempBlockedVehiclesData
      return item;
    });
  }

  // Return the array of modified vehicle details
  return tempBlockedVehiclesData;
};

// ***
const rentalsVehicleManagementReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    // Handle vehicle management failures and errors
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.VEHICLE_MANAGEMENT_FAIL:
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.VEHICLE_MANAGEMENT_ERROR:
      return {
        ...state,
        ...payload,
        onLoad: false,
      };

    // Get inventory calendar view data
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED:
      return {
        ...state,
        calendarData: [],
        calenderDataLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_INVENTORY_CALENDAR_VIEW_DATA_SUCCESS:
      return {
        ...state,
        calendarData: payload,
        calenderDataLoader: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_INVENTORY_CALENDAR_VIEW_DATA_FAILDED:
      return {
        ...state,
        calenderDataLoader: false,
      };

    // Get ongoing bookings details
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ONGOING_BOOKINGS_DETAILS_REQUESTED:
      return {
        ...state,
        ongoingBookingsData: [],
        ongoingBookingsApiResponseData: {},
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ONGOING_BOOKINGS_DETAILS_SUCCESS:
      return {
        ...state,
        ongoingBookingsData: payload?.ongoingBookingsData,
        ongoingBookingsApiResponseData: payload?.ongoingBookingsApiResponseData,
        onLoad: false,
      };

    // Get upcoming bookings details
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED:
      return {
        ...state,
        upcomingBookingsData: [],
        upcomingBookingsApiResponseData: {},
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_UPCOMING_BOOKINGS_DETAILS_SUCCESS:
      return {
        ...state,
        upcomingBookingsData: payload?.upcomingBookingsData,
        upcomingBookingsApiResponseData:
          payload?.upcomingBookingsApiResponseData,
        onLoad: false,
      };

    // Get blocked vehicles details
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_BLOCKED_VEHICLES_DETAILS_REQUESTED:
      return {
        ...state,
        blockedVehiclesData: [],
        blockedVehiclesApiResponseData: {},
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_BLOCKED_VEHICLES_DETAILS_SUCCESS:
      // Modify blocked vehicle list data coming from API for MUi data grid table
      const tempBlockedVehiclesData = processBlockedVehiclesData(payload);
      return {
        ...state,
        blockedVehiclesData: [...tempBlockedVehiclesData],
        blockedVehiclesApiResponseData: payload?.blockedVehiclesApiResponseData,
        onLoad: false,
      };

    // Get vehicles list (for vehicle management dashboard)
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLES_LIST_REQUESTED:
      return {
        ...state,
        vehiclesListData: [],
        vehiclesListApiResponseData: {},
        onLoad: true,
        isVehicleApproveRejectStatus: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLES_LIST_SUCCESS:
      // Modify vehicle list data coming from API for MUi data grid table
      const modifiedVehicleListData = modifyVehicleListData(
        payload?.vehiclesListData
      );
      return {
        ...state,
        vehiclesListData: [...modifiedVehicleListData],
        vehiclesListApiResponseData: payload?.vehiclesListApiResponseData,
        onLoad: false,
      };

    // Update vehicle status (AVAILABLE/BLOCKED)
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPDATE_VEHICLE_STATUS_REQUESTED:
      return {
        ...state,
        vehicleStatusUpdateLoader: true,
        isVehicleUpdatedSucess: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPDATE_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        vehicleStatusUpdateData: payload?.vehicleStatusUpdateData,
        vehicleStatusUpdateLoader: false,
        isVehicleUpdatedSucess: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPDATE_VEHICLE_STATUS_FAILED:
      return {
        ...state,
        vehicleStatusUpdateLoader: false,
        isVehicleUpdatedSucess: false,
      };

    // Add new vehicle details
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .ADD_NEW_VEHICLE_DETAILS_REQUESTED:
      return {
        ...state,
        onLoad: true,
        isVehicleUpdatedSucess: false,
        addEditVehicleDetailsLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_NEW_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        addNewVehicleDetailsData: payload?.addNewVehicleDetailsData,
        onLoad: false,
        addEditVehicleDetailsLoader: false,
      };

    // Update or reject vehicle approval status
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED:
      return {
        ...state,
        onLoad: true,
        vehicleApproveRejectStatusUpdateData: [],
        isVehicleApproveRejectStatus: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPDATE_APPROVE_REJECT_VEHICLE_SUCCESS:
      return {
        ...state,
        isVehicleApproveRejectStatus: true,
        // vehicleApproveRejectStatusUpdateData:
        //   payload?.vehicleApproveRejectStatusUpdateData,
        onLoad: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPDATE_APPROVE_REJECT_VEHICLE_FAILED:
      return { ...state, onLoad: false, isVehicleApproveRejectStatus: false };

    // Get vehicle details by id
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_DETAILS_BY_ID_REQUESTED:
      return {
        ...state,
        vehicleDetailsByIdData: {},
        vehicleDetailsLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        vehicleDetailsByIdData: payload,
        vehicleDetailsLoader: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_DETAILS_BY_ID_FAILED:
      return { ...state, vehicleDetailsLoader: false };

    // Get live challan list
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_LIVE_CHALLAN_LIST_REQUESTED:
      return { ...state, liveChallanLoader: true };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_LIVE_CHALLAN_LIST_SUCCESS:
      return { ...state, challanData: payload, liveChallanLoader: false };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_LIVE_CHALLAN_LIST_FAILED:
      return { ...state, liveChallanLoader: false };

    // Get rental challan list
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_RENTALS_CHALLAN_LIST_REQUESTED:
      return { ...state, challanListLoader: true, challanData: {} };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_RENTALS_CHALLAN_LIST_SUCCESS:
      return { ...state, challanListLoader: false, challanData: payload };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_RENTALS_CHALLAN_LIST_FAILED:
      return { ...state, challanListLoader: false };

    // Get assigned IoT devices
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ASSIGNED_IOT_DEVICES_REQUESTED:
      return {
        ...state,
        assignedIotVehicleData: {},
        assignedIotDataPrimary: {}, // Primary assigned IoT data
        assignedIotDataSecondary: {}, // Secondary assigned IoT data
        getAssignedIotLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ASSIGNED_IOT_DEVICES_SUCCESS:
      return {
        ...state,
        assignedIotVehicleData: payload?.assignedDevices,
        getAssignedIotLoader: false,
        //
        assignedIotDataPrimary: isArrayNotEmpty(payload?.assignedDevices)
          ? payload?.assignedDevices?.filter(
              (item: any) => item?.deviceType === iotDeviceTypes?.PRIMARY
            )[0]
          : {},
        assignedIotDataSecondary: isArrayNotEmpty(payload?.assignedDevices)
          ? payload?.assignedDevices?.filter(
              (item: any) => item?.deviceType === iotDeviceTypes?.SECONDARY
            )[0]
          : {},
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ASSIGNED_IOT_DEVICES_FAILED:
      return {
        ...state,
        assignedIotVehicleData: {},
        getAssignedIotLoader: false,
      };

    // Unassign IoT devices
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED:
      return { ...state, unassignIotLoader: true };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .RENTALS_UNASSIGN_IOT_DEVICE_SUCCESS:
      return { ...state, unassignIotLoader: false };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .RENTALS_UNASSIGN_IOT_DEVICE_FAILED:
      return { ...state, unassignIotLoader: false };

    // Link challan
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_REQUESTED:
      return { ...state, vehicleLinkChallanLoader: true };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_SUCCESS:
      return { ...state, vehicleLinkChallanLoader: false };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_FAILED:
      return { ...state, vehicleLinkChallanLoader: false };

    // Unlink challan
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_REQUESTED:
      return { ...state };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_SUCCESS:
      return { ...state };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_FAILED:
      return { ...state };

    // Get ping history
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_REQUESTED:
      return {
        ...state,
        pingHistoryLoader: action?.payload?.showMoreClick ? false : true,
        pingHistoryData: action?.payload?.showMoreClick
          ? state.pingHistoryData
          : {},
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_SUCCESS:
      return { ...state, pingHistoryLoader: false, pingHistoryData: payload };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_FAILED:
      return { ...state, pingHistoryLoader: false, pingHistoryData: {} };

    // Get IoT devices
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_IOT_DEVICES_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_IOT_DEVICES_SUCCESS:
      return {
        ...state,
        onLoad: false,
        iotDetails: payload?.iotDetails,
        iotDetailsObject: {
          ...state.iotDetailsObject,
          [payload.iotType]: payload.iotDetails,
        },
      };

    // Assign IoT device
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ASSIGN_IOT_DEVICE_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ASSIGN_IOT_DEVICE_SUCCESS:
      return {
        ...state,
        assignedIotData: payload?.assignedIotData,
        // Primary & Secondary assigned IoT data
        assignedIotDataPrimary:
          payload?.assignedIotData?.deviceType === iotDeviceTypes?.PRIMARY
            ? payload?.assignedIotData
            : state?.assignedIotData,
        assignedIotDataSecondary:
          payload?.assignedIotData?.deviceType === iotDeviceTypes?.SECONDARY
            ? payload?.assignedIotData
            : state?.assignedIotData,
        onLoad: false,
      };

    // Fetch vehicle model list data
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED:
      return {
        ...state,
        vehicleModelListData: [],
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .FETCH_VEHICLE_MODEL_LIST_DATA_SUCCESS:
      return {
        ...state,
        vehicleModelListData: payload?.vehicleModelListData,
        onLoad: false,
      };

    // Clear fetch vehicle model list data
    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_REDUCER_DATA:
      return {
        ...state,
        onLoad: false,
        vehicleModelListData: [],
        addNewVehicleDetailsData: {},
        assignedIotDataPrimary: {},
        assignedIotDataSecondary: {},
      };

    // Get all transfer vehicle
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ALL_TRANSFER_VEHICLE_REQUESTED:
      return {
        ...state,
        allTransferVehicleData: [],
        isTransferVehicleSuccess: false,
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_TRANFER_VEHICLE_FAILED:
      return {
        ...state,
        onLoad: false,
        isTransferVehicleSuccess: false,
        allTransferVehicleData: [],
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_TRANFER_VEHICLE_SUCCESS:
      return {
        ...state,
        allTransferVehicleData: payload?.allTransferVehicleData,
        isTransferVehicleSuccess: false,
        onLoad: false,
      };

    // Get vehicle transfer request
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_TRANSFER_REQUEST_REQUESTED:
      return {
        ...state,
        isTransferVehicleSuccess: false,
        onLoadTransferVehicleRequest: true,
        vehicleTransferRequestData: {},
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_TRANSFER_REQUEST_FAILED:
      return {
        ...state,
        onLoadTransferVehicleRequest: false,
        isTransferVehicleSuccess: false,
        vehicleTransferRequestData: {},
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        vehicleTransferRequestData: payload?.vehicleTransferRequestData,
        isTransferVehicleSuccess: false,
        onLoadTransferVehicleRequest: false,
      };

    // Transfer vehicle request
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .TRANFER_VEHICLE_REQUEST_REQUESTED:
      return {
        ...state,
        isTransferVehicleSuccess: false,
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.TRANFER_VEHICLE_REQUEST_FAILED:
      return {
        ...state,
        isTransferVehicleSuccess: false,
        onLoad: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .TRANSFER_VEHICLE_REQUEST_SUCCESS:
      return {
        ...state,
        isTransferVehicleSuccess: true,
        onLoad: false,
      };

    // Fetch vehicle colour list data
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLOURS_REQUESTED:
      return {
        ...state,
        vehicleColourListData: [],
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLOURS_SUCCESS:
      return {
        ...state,
        vehicleColourListData: payload?.vehicleColourListData,
        onLoad: false,
      };

    // Edit vehicle details
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.EDIT_VEHICLE_DETAILS_REQUESTED:
      return {
        ...state,
        onLoad: true,
        addEditVehicleDetailsLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.EDIT_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        onLoad: false,
        addEditVehicleDetailsLoader: false,
      };

    // Get vehicles list (for download button on vehicle management dashboard)
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED:
      return {
        ...state,
        VehicleListDownloadableData: [],
        vehicleDataDownloadLoader: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_SUCCESS:
      // Modify vehicle list data coming from API for MUi data grid table
      const modified1000VehicleListData = modifyVehicleListData(
        payload?.VehicleListDownloadableData
      );
      return {
        ...state,
        VehicleListDownloadableData: [...modified1000VehicleListData],
        vehicleDataDownloadLoader: false,
      };

    // Delete vehicle
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        onLoad: false,
      };

    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_ADD_NEW_VEHICLE_DETAILS_DATA: // Clear add new vehicle details data
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_REQUESTED: // Delete vehicle
      return {
        ...state,
        onLoad: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        onLoad: false,
        addNewVehicleDetailsData: {},
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_IMAGES_REQUESTED:
      return {
        ...state,
        vehicleImagesDataLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_IMAGES_SUCCESS:
      return {
        ...state,
        vehicleImagesDataLoader: false,
        vehicleImagesData: payload.data.vehicleImages,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.CLEAR_VEHICLE_IMAGES_DATA:
      return {
        ...state,
        vehicleImagesData: [],
      };

    // end ride images
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_END_RIDE_IMAGES_REQUESTED:
      return {
        ...state,
        vehicleImagesDataLoader: true,
        vehicleEndRideImagesData: [],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_END_RIDE_IMAGES_SUCCESS:
      return {
        ...state,
        vehicleImagesDataLoader: false,
        vehicleEndRideImagesData: payload.data.vehicleImages,
      };

    // start ride odometer reading
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_START_ODOMETER_READING_REQUESTED:
      return {
        ...state,
        vehicleImagesDataLoader: true,
        startOdometerReadingImagesData: [],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_START_ODOMETER_READING_SUCCESS:
      return {
        ...state,
        vehicleImagesDataLoader: false,
        startOdometerReadingImagesData: payload.data.vehicleImages,
      };

    // end ride odometer reading
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_END_ODOMETER_READING_REQUESTED:
      return {
        ...state,
        vehicleImagesDataLoader: true,
        endOdometerReadingImagesData: [],
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_END_ODOMETER_READING_SUCCESS:
      return {
        ...state,
        vehicleImagesDataLoader: false,
        endOdometerReadingImagesData: payload.data.vehicleImages,
      };

    // doc upload actions
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_SIGNED_URLS_VEHICLE_REQUESTED:
      return {
        ...state,
        getSignedUrlLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_SIGNED_URLS_VEHICLE_SUCCESS:
      return {
        ...state,
        getSignedUrlLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_SIGNED_URLS_VEHICLE_FAILED:
      return {
        ...state,
        getSignedUrlLoader: false,
        signedUrlResponse: payload.signedUrlResponse,
        fileData: payload.fileData,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED:
      return {
        ...state,
        getSignedUrlLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_SUCCESS:
      return {
        ...state,
        getSignedUrlLoader: false,
        vehicleAttachmentsData: state?.vehicleAttachmentsData?.length
          ? [...state?.vehicleAttachmentsData, { ...payload }]
          : [{ ...payload }],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_FAILED:
      return {
        ...state,
        getSignedUrlLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED:
      return {
        ...state,
        downloadBulkUploadSheet: [],
        downloadBulkUploadLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        downloadBulkUploadLoader: false,
        downloadBulkUploadSheet: action.payload.bulkUploadDownload,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_FAILED:
      return {
        ...state,
        downloadBulkUploadLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPLOAD_BULK_UPLOAD_SHEET_REQUESTED:
      return {
        ...state,
        uploadBulkUploadLoader: true,
        isUploadBulkSheetUploaded: false,
        failedUploadBulksheetData: [],
        uploadBulkUploadSheetData: [],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPLOAD_BULK_UPLOAD_SHEET_SUCCESS:
      return {
        ...state,
        uploadBulkUploadLoader: false,
        isUploadBulkSheetUploaded: true,
        uploadBulkUploadSheetData: action.payload.bulkUploadSheet,
        failedUploadBulksheetData: [],
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPLOAD_BULK_UPLOAD_SHEET_FAILED:
      return {
        ...state,
        uploadBulkUploadLoader: false,
        isUploadBulkSheetUploaded: false,
        failedUploadBulksheetData: payload.uploadSheetError,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.BULK_REQUEST_INITIATE_REQUESTED:
      return {
        ...state,
        uploadBulkUploadLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.BULK_REQUEST_INITIATE_SUCCESS:
      return {
        ...state,
        uploadBulkUploadLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.BULK_REQUEST_INITIATE_FAILED:
      return {
        ...state,
        uploadBulkUploadLoader: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.CLEAR_ATTACHMENT_ID_BULK_UPLOAD:
      return {
        ...state,
        ...payload,
      };

    // doc upload actions
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_SIGNED_URLS_VEHICLE_REQUESTED:
      return {
        ...state,
        getSignedUrlLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_SIGNED_URLS_VEHICLE_SUCCESS:
      return {
        ...state,
        getSignedUrlLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_SIGNED_URLS_VEHICLE_FAILED:
      return {
        ...state,
        getSignedUrlLoader: false,
        signedUrlResponse: payload.signedUrlResponse,
        fileData: payload.fileData,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED:
      return {
        ...state,
        getSignedUrlLoader: true,
        vehicleAttachmentsData: [],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_SUCCESS:
      let data = state?.vehicleAttachmentsData;
      if (data?.length > payload?.index) {
        data[payload?.index] = payload;
      } else {
        data?.push(payload);
      }

      return {
        ...state,
        getSignedUrlLoader: false,
        vehicleAttachmentsData: data,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_FAILED:
      return {
        ...state,
        getSignedUrlLoader: false,
        vehicleAttachmentsData: [],
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_VEHICLE_DOCUMENTS_REQUESTED:
      return {
        ...state,
        docUploadS3Loader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_VEHICLE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        docUploadS3Loader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_VEHICLE_DOCUMENTS_FAILED:
      return {
        ...state,
        docUploadS3Loader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DELETE_VEHICLE_DOCUMENTS_REQUESTED:
      return {
        ...state,
        deleteDocLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DELETE_VEHICLE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deleteDocLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_DOCUMENTS_FAILED:
      return {
        ...state,
        deleteDocLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED:
      return {
        ...state,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_DOCUMENTS_SUCCESS:
      return {
        ...state,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_DOCUMENTS_FAILED:
      return {
        ...state,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_DOCUMENT_REQUESTED:
      return {
        ...state,
        vehicleDocumentsData: {},
        vehicleDocumentsDataLoader: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_DOCUMENT_SUCCESS:
      return {
        ...state,
        vehicleDocumentsData: payload,
        vehicleDocumentsDataLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_DOCUMENT_FAILED:
      return {
        ...state,
        vehicleDocumentsData: {},
        vehicleDocumentsDataLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_SUCCESS:
      return {
        ...state,
        vehicleStatsData: payload,
        onLoad: false,
      };
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_FAILED:
      return {
        ...state,
        onLoad: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.CLEAR_VEHICLE_ATTACHMENT_DATA:
      return { ...state, vehicleAttachmentsData: [] };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_DOC_UPLODED_SIGNED_URL:
      return { ...state, ...payload };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.CLEAR_VEHICLE_REDUCER_KEY:
      return { ...state, ...payload };

    // Clear iot section
    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.CLEAR_IOT_SECTION:
      return { ...state, ...payload };

    // Default case: return current state
    default:
      return {
        ...state,
      };
  }
};

export default rentalsVehicleManagementReducer;
