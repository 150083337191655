import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../themes/colors";

function StatusActive(props: any) {
  if (props.kycStatus === "Done" || props.kycStatus === 1) {
    return (
      <FontAwesomeIcon
        style={{ fontSize: "25px", fontStyle: "normal", fontWeight: "normal" }}
        size="lg"
        color={colors.light_green}
        icon={faCheckCircle}
      />
      //   <Chip style={{minWidth:'75px'}} color={props.status === "Active" ? "success" : props.status === "InActive" ? "error" : "default"} label={props.status} size="small" />
    );
  } else {
    return (
      <FontAwesomeIcon
        style={{ fontSize: "25px", fontStyle: "normal", fontWeight: "normal" }}
        size="lg"
        color="rgb(219, 6, 6)"
        icon={faTimesCircle}
      />
      //   <Chip style={{minWidth:'75px'}} color={props.status === "Active" ? "success" : props.status === "InActive" ? "error" : "default"} label={props.status} size="small" />
    );
  }
}
export { StatusActive };
