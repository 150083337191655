import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation, useNavigate } from "react-router-dom";
import { MITC_FORM_URL } from "../../../config";
import { ApplyCouponIcon } from "../../../freedo-x-src/constantsX/exportImagesX";
import en from "../../../locale/en.json";
import { RootState } from "../../../redux/reducers";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../sharedComponents/atoms/InputFields/Input";
import HomeDeliveryModal from "../../../sharedComponents/templates/HomeDeliveryModal";
import { ManualBookingAdminStyles } from "../../../sharedComponents/templates/MultiStepForm/StyleObjects";
import TotalCharge from "../../../sharedComponents/templates/MultiStepForm/TotalCharge";
import MultiStepForm from "../../../sharedComponents/templates/MultiStepForm/multiStepForm";
import SubscriptionItem from "../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import TopCards from "../../../sharedComponents/templates/TopCards/TopCards";
import { colors } from "../../../themes/colors";
import {
  ChargeIcons,
  ChargesType,
  ChargesTypeKeys,
  GenericObject,
  LOB_TYPES,
  STATUS,
  SurgefactorType,
  TAGS,
  discountsTypes,
  multiStepFormStepKeys,
  planTypeKeys,
  tyesOfModeOfDelivery,
  typographyConstants,
  useCaseArray,
} from "../../constants/constants";
import { EditIconBlack } from "../../constants/exportImages";
import { PlanTypes } from "../../interfaces/manualBookingInterfaces";
import rentalEn from "../../locale/rental-en.json";
import { manualBookingActions, rentalActions } from "../../redux/actions";
import { routesConstants } from "../../utils/RoutesConstants";
import {
  calculateFutureDate,
  getFormattedINR,
  getFullName,
  getGenderLabelByEnum,
  getInitials,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  resetFieldsExcept,
  validate,
  validateOnly,
} from "../../utils/helper";
import styles from "./ManualBooking.module.css";
import BookingDetails from "./section/BookingDetails";
import Checkout from "./section/Checkout";
import { CheckoutFooter } from "./section/CheckoutFooter";
import SideDrawer from "./section/PaymentSideDrawer";
import SendOTPModal from "./section/SendOTPModal";
import UserDetails from "./section/UserDetails";

const {
  bookingDetails: { discountTypesDisplay },
} = rentalEn;

interface displayNameObject {
  name: string;
  displayName: string;
}

interface initialStateInterface {
  mobileNumber: String;
  firstName: String;
  lastName: String;
  email: String;
  gender: String;
  selectedCity: displayNameObject;
  selectedBranch: any;
  selectedPackage: String | number;
  selectedSource: displayNameObject;
  selectedVehicle: { modelName: String; displayName: String };
  selectedLocation: displayNameObject;
  helmets: {
    PREMIUM: number;
    HALF_FACE: number;
    FULL_FACE: number;
    KIDS: number;
  };
  bookingType: displayNameObject;
  startDate: Date;
  endDate: Date;
  modeOfDelivery: String;
  selectedTimeSlot: any;
  deliveryTime: String;
  otpass: String;
  addressId: String;
  selectedPlan: any;
  complementaryHelmet: boolean;
  selectedPaymentMode: String;
  selectedDaysCustom: String | number;
  couponCode: String;
}
const initialState: initialStateInterface = {
  mobileNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  selectedCity: { name: "", displayName: "" },
  selectedBranch: { name: "", displayName: "" },
  selectedPackage: "1",
  selectedSource: { name: "", displayName: "" },
  selectedVehicle: { modelName: "", displayName: "" },
  selectedLocation: { name: "", displayName: "" },
  helmets: {
    PREMIUM: 0,
    HALF_FACE: 0,
    FULL_FACE: 0,
    KIDS: 0,
  },
  bookingType: useCaseArray[0],
  startDate: new Date(),
  endDate: calculateFutureDate(new Date(), 1),
  modeOfDelivery: tyesOfModeOfDelivery.BRANCH,
  selectedTimeSlot: {},
  deliveryTime: "",
  otpass: "",
  addressId: "",
  selectedPlan: "",
  complementaryHelmet: true,
  selectedPaymentMode: "",
  selectedDaysCustom: "",
  couponCode: "",
};

const errorInitialState = {
  mobileNumber: "",
  selectedCity: "",
  selectedBranch: "",
  selectedPackage: "",
  selectedSource: "",
  selectedVehicle: "",
  selectedLocation: "",
  selectedDeliveryLocation: "",
  otpass: "",
  selectedTimeSlot: "",
  selectedHemlet: "",
};

interface StateType {
  InventoryFeilds: any;
  InventoryDetails: any;
}
const discountsTypeArray: string[] = [
  discountsTypes.PROMOTION,
  discountsTypes.COUPON_CODE,
];

const NewManualBooking = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const state = location.state as StateType;

  const Bull = (
    <Box
      component="span"
      sx={{
        mx: "2px",
        transform: "scale(1)",
        color: colors.white,
        fontSize: "16px",
      }}
    >
      •
    </Box>
  );

  const {
    checkIsUserExist,
    userDetails,
    checkUserLoader,
    showUserData,
    registerUserDetails,
    isOTPMatch,
    userRegistered,
    deliverySlots,
    modelDetails,
    customerAddressList,
    charges,
    isManualBookingCreated,
    bookingCreatedDeatils,
    loadingCalculateCharges,
    availableModels,
    slotPauseDates,
  } = useSelector((state: RootState) => state.newManualBookingReducer);

  const {
    allCitiesDetails,
    branches,
    masterPlanDurationsData = [],
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const [fields, setFields] = useState({
    ...initialState,
  });
  const [errorFields, setErrorFields] = useState({
    ...errorInitialState,
  });
  const [clearFields, setClearFields] = useState(false);
  const [sendOTPModalOpen, setSendOTPModalOpen] = useState<Boolean>(false);
  const [homeDeliveryModalOpen, setHomeDeliveryModalOpen] =
    useState<Boolean>(false);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [showVehicleCard, setshowVehicleCard] = useState<boolean>(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState<number>(120); // Set initial timer to 120 seconds
  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);

  const [slot, setSlot] = useState<any>("");
  const [isInventoryBooking, setIsInventoryBooking] = useState(false);

  const resetMultistepFormRef = useRef<any>(null);

  //get only active branches
  const ActiveBranches = branches?.filter(
    (e: any) => e.status === STATUS.ACTIVE
  );

  const currentDate: any = getTimestampAtStartOfDay(new Date());
  const launchDate: any = new Date(fields?.selectedBranch?.launchDate);

  const { availability } = modelDetails || {};
  const config = availability?.[0]?.config || {};
  const pricing = availability?.[0]?.pricing || {};

  // Whether the Slot is available or not
  const availablePeriods = [
    { label: en.global.Morning, slots: deliverySlots.morning },
    { label: en.global.AfterNoon, slots: deliverySlots.afternoon },
    { label: en.global.Evening, slots: deliverySlots.evening },
  ].filter((period) => period.slots?.length > 0);

  // Render Time Slots
  const renderTimeSlots = (slot: any) => {
    if (slot === en?.global.Morning) return deliverySlots.morning;
    if (slot === en?.global.AfterNoon) return deliverySlots.afternoon;
    if (slot === en?.global.Evening) return deliverySlots.evening;
    return [];
  };

  // when we redirect from inventory details this will call and set the values
  useEffect(() => {
    if (
      state?.InventoryDetails &&
      Object?.keys(state?.InventoryFeilds)?.length
    ) {
      const {
        selectedVehicle,
        selectedCity,
        selectedBranch,
        selectedLob: bookingType,
        selectedPackage,
      } = state?.InventoryFeilds;

      let setModalName = {
        modelName: selectedVehicle?.modelName
          ? selectedVehicle?.modelName
          : selectedVehicle?.name,
        displayName: selectedVehicle?.displayName,
      };

      if (
        selectedCity?.name &&
        selectedBranch?.name &&
        setModalName?.modelName
      ) {
        setFields((prevState: any) => ({
          ...prevState,
          selectedCity,
          selectedBranch,
          selectedVehicle: setModalName,
        }));
        const payload = {
          startDate: getTimestampAtStartOfDay(fields?.startDate),
          endDate: getTimestampAtStartOfDay(fields?.endDate),
          branchName: selectedBranch?.name,
          useCase: bookingType?.name,
          modelName: setModalName?.modelName,
        };
        dispatch(manualBookingActions?.getModelDetails(payload));
      } else if (selectedCity?.name && selectedBranch?.name) {
        setFields((prevState: any) => ({
          ...prevState,
          selectedCity,
          selectedBranch,
        }));
        handleGetAvailableModels({
          startDate: fields?.startDate,
          endDate: fields?.endDate,
          branchName: selectedBranch?.name,
          useCase: bookingType?.name,
        });
      } else {
        return;
      }

      setIsInventoryBooking(true);
    }
  }, [location?.state]);

  // Dispatch Master API
  useEffect(() => {
    dispatch(rentalActions.getMasterPlanDurations("AddCustomField"));
    // On Unmount Reset Data
    return () => {
      clearFieldsData();
    };
  }, []);

  // set initial state of otp on change OTP Feild
  useEffect(() => {
    setFields((prevState: any) => ({ ...prevState, otpass: otp }));
    setErrorFields((prevState: any) => ({ ...prevState, otpass: "" }));
  }, [otp]);

  // check is otp match or not while registering user details
  useEffect(() => {
    if (isOTPMatch !== null) {
      if (isOTPMatch) {
        setSendOTPModalOpen(false);
        dispatch(
          manualBookingActions.getCustomerProfile({
            customerId: registerUserDetails.customerId,
          })
        );
      } else {
        setErrorFields((prevState: any) => ({
          ...prevState,
          otpass: rentalEn.errorMessages.invalidOTP,
        }));
        // setOtp("");
      }
      dispatch(manualBookingActions?.clearVerifyOtp());
    }
  }, [isOTPMatch]);

  // set index 0 of city,package , booking Type
  useEffect(() => {
    if (allCitiesDetails.length > 0) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedCity: isInventoryBooking
          ? state?.InventoryFeilds?.selectedCity
          : allCitiesDetails[0],
        selectedPackage:
          isInventoryBooking && state?.InventoryFeilds?.selectedPackage
            ? state?.InventoryFeilds?.selectedPackage
            : masterPlanDurationsData?.length
            ? masterPlanDurationsData?.[0]
            : "",
        bookingType: isInventoryBooking
          ? state?.InventoryFeilds?.selectedLob
          : useCaseArray[0],
      }));
    }
  }, [
    allCitiesDetails.length,
    // state?.InventoryFeilds?.selectedPackage,
    isInventoryBooking,
  ]);

  // // Get List of Models
  useEffect(() => {
    if (fields?.selectedBranch?.name && fields.endDate) {
      handleGetAvailableModels({
        startDate: fields?.startDate,
        endDate: fields?.endDate,
        branchName: fields?.selectedBranch?.name,
        useCase: fields?.bookingType?.name,
      });
    }
  }, [fields.endDate]);

  // get delivery slots for Pickup and Home delivery
  useEffect(() => {
    const { modeOfDelivery, startDate, selectedBranch, addressId } = fields;
    if (
      modeOfDelivery === tyesOfModeOfDelivery.BRANCH &&
      availableModels?.length > 0 &&
      selectedBranch?.name &&
      startDate
    ) {
      dispatch(
        manualBookingActions.getDeliverySlots({
          deliveryType: modeOfDelivery,
          branchName: selectedBranch.name,
          bookingStartDate: getTimestampAtStartOfDay(startDate),
        })
      );
    } else if (
      modeOfDelivery === tyesOfModeOfDelivery.CUSTOMER_LOCATION &&
      addressId &&
      startDate
    ) {
      dispatch(
        manualBookingActions.getDeliverySlots({
          deliveryType: modeOfDelivery,
          branchName: selectedBranch?.name,
          bookingStartDate: getTimestampAtStartOfDay(startDate),
          addressId: addressId,
        })
      );
    }
  }, [
    fields.modeOfDelivery,
    fields.startDate,
    availableModels,
    fields.selectedBranch,
    fields.addressId,
  ]);

  // Show User Details card
  useEffect(() => {
    userDetails && setShowUserDetails(true);
  }, [userDetails]);

  // handle Plan Type Selected Initially
  useEffect(() => {
    if (modelDetails) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: planType[0],
      }));
    }
  }, [fields?.selectedVehicle, modelDetails]);

  // get first Available Period initially [Morning ,Evening,AfterNoon]
  useEffect(() => {
    if (availablePeriods?.length > 0 && !slot) {
      setSlot(availablePeriods[0].label);
    }
  }, [availablePeriods]);

  //Set the Time Slot 0th index initially on the basis of available period
  useEffect(() => {
    if (slot) {
      const timeSlots = renderTimeSlots(slot);
      if (timeSlots?.length > 0) {
        setFields((prevState: any) => ({
          ...prevState,
          selectedTimeSlot: {
            start: timeSlots[0].start,
            end: timeSlots[0].end,
          },
        }));
      }
    }
  }, [slot]);

  //set range date on basis of launchDate
  useEffect(() => {
    if (fields?.selectedBranch?.name) {
      if (launchDate > currentDate) {
        setFields((prevState: any) => ({
          ...prevState,
          startDate: launchDate,
          endDate: calculateFutureDate(launchDate, 1),
          selectedPackage: "1",
        }));
      }

      //GET BRANCHN CONFIG API
      const payload = {
        branch: fields?.selectedBranch?.name,
        data: {
          lob: LOB_TYPES.LTR,
        },
      };

      dispatch(rentalActions.getOperatorConfigByBranch(payload));
    }
  }, [fields?.selectedBranch?.name]);

  // Close the send otp modal when OTP Verified
  const handleCloseSendOTPModal = () => {
    if (fields.otpass.length !== 6) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        otpass: rentalEn.errorMessages.pleaseEnterValidOTP,
      }));
    } else {
      const payload = {
        data: {
          token: registerUserDetails?.token,
          otp: fields?.otpass,
        },
      };
      dispatch(manualBookingActions.verifyOTP(payload));
    }
  };

  // Close the Home Delivery Modal onClick
  const handleCloseHomeDeliveryModal = () => {
    setFields((prevState: any) => ({
      ...prevState,
      selectedDeliveryLocation: "",
    }));
    setHomeDeliveryModalOpen(false);
  };

  // Resend otp onClick button
  const onClickResendOtp = (e: SyntheticEvent) => {
    e.preventDefault();
    setSeconds(120); // Reset timer to 120 seconds when resending OTP
    dispatch(
      manualBookingActions.resendOTP({
        token: registerUserDetails?.token,
      })
    );
    setFields((prevState: any) => ({ ...prevState, otpass: "" }));
    setOtp("");
  };

  // toggle side Drawer(Payment modal)
  const toggleDrawer = () => {
    setOpenSideDrawer(!openSideDrawer);
  };

  // clear all fields data from state and redux
  const clearFieldsData = () => {
    setFields({ ...initialState });
    setIsInventoryBooking(false);
    setshowVehicleCard(false);
    setShowUserDetails(false);
    setOpenSideDrawer(false);
    setIsCouponApplied(false);
    setErrorFields({ ...errorInitialState });
    dispatch(manualBookingActions?.clearCheckUserDetails());
    resetMultistepFormRef.current &&
      resetMultistepFormRef.current.nextStepsDisabled();
  };

  const checkUserExist = () => {
    dispatch(
      manualBookingActions?.searchCustomer({
        mobile_number: fields.mobileNumber,
      })
    );
  };

  // search if the user exist
  const SearchUser = (event: SyntheticEvent) => {
    event.preventDefault();
    if (fields.mobileNumber.length < 10) {
      setErrorFields({
        ...errorFields,
        mobileNumber: en?.errorMessages?.mobileError,
      });
      return;
    }
    if (clearFields) {
      setClearFields(false);
      clearFieldsData();
    } else {
      checkUserExist();

      setFields((prevState: any) => ({
        ...prevState,
      }));
    }
  };

  // Plan Types on Checkout Form Unlimited , Regular ,Premium
  const planType: PlanTypes[] = [
    {
      key: planTypeKeys.RegularPlan,
      heading: en.NewManualBooking.RegularPlan,
      amount: pricing?.amount - pricing.discount || 0,
      kmLimit: pricing.kmLimit || 0,
      isAvailable: true,
    },
    {
      key: planTypeKeys.PremiumPlan,
      heading: en.NewManualBooking.PremiumPlan,
      amount: pricing?.premium?.price || 0,
      kmLimit: pricing.premium?.kmLimit || 0,
      isAvailable: pricing?.premium?.available ?? false,
    },
    {
      key: planTypeKeys?.UnlimitedPlan,
      heading: en.NewManualBooking.UnlimitedPlan,
      amount: pricing?.unlimited?.price || 0,
      kmLimit: pricing?.unlimited?.kmLimit || 0,
      isAvailable: pricing?.unlimited?.available ?? false,
    },
  ];

  // Validate Booking Details
  const validateBookingDetails = () => {
    let error = false;
    const validatedFields: any = validateOnly(fields, errorFields, [
      "selectedBranch",
      "selectedCity",
      "selectedPackage",
      "startDate",
      "endDate",
      "selectedTimeSlot",
    ]);

    if (!fields?.selectedVehicle?.modelName) {
      error = true;
      setErrorFields((prevState: any) => ({
        ...prevState,
        selectedVehicle: en.errorMessages.requiredField,
      }));
      return error;
    }

    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });
      return error;
    }

    if (fields?.modeOfDelivery === tyesOfModeOfDelivery.CUSTOMER_LOCATION) {
      if (customerAddressList?.addresses?.length === 0) {
        error = true;
        toastr.warning(
          "",
          rentalEn.errorMessages.pleaseAddAddressBeforeProceeding
        );
        return error;
      }
    }

    setshowVehicleCard(true);
    return error;
  };

  // validate feilds while user is registering
  const validateRegisterUserDetails = () => {
    let obj = {
      email: fields.email,
      firstName: fields.firstName,
      lastName: fields.lastName,
      gender: fields.gender,
    };
    let objErr = {
      email: "",
      firstName: "",
      lastName: "",
      gender: "",
    };
    let error = false;

    const validatedFields = validate(obj, objErr);

    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });

      return error;
    }

    // register new  User
    const payload = {
      data: {
        firstName: fields.firstName,
        lastName: fields.lastName,
        gender: fields?.gender,
        email: fields?.email,
        mobileNumber: fields?.mobileNumber,
      },
    };
    dispatch(manualBookingActions.registerUser(payload));

    setSendOTPModalOpen(true);
    return error;
  };

  const isDateUnavailable = (startDate: any, endDate: any) => {
    if (
      slotPauseDates?.includes(getTimestampAtStartOfDay(startDate)) ||
      slotPauseDates?.includes(getTimestampAtStartOfDay(endDate))
    )
      return true;

    return false;
  };

  // Handle Date Change in RangePicker
  const handleDateChange = (date: any) => {
    let [startDate, endDate] = date;
    const dateObj: any = {
      startDate,
      endDate,
      selectedVehicle: initialState.selectedVehicle,
    };

    if (
      isDateUnavailable(
        startDate,
        calculateFutureDate(startDate, fields?.selectedPackage)
      )
    ) {
      toastr.error("", rentalEn.errorMessages.slotpauseDateAvailableMessage);
      setFields((prev: any) => ({
        ...prev,
        startDate: null,
        endDate: null,
      }));
      return;
    }

    if (fields?.selectedPackage === rentalEn.manualBooking.custom) {
      if (endDate && endDate < currentDate) {
        toastr.warning(
          "",
          rentalEn.errorMessages.EndDateGreaterThanCurrentDate
        );
        setFields((prev: any) => ({
          ...prev,
          startDate: null,
          endDate: null,
        }));
        return;
      } else {
        const diffDays = dayjs(endDate).diff(dayjs(startDate), "days");
        const isInMasterPlans = masterPlanDurationsData.includes(diffDays);
        dateObj["selectedPackage"] = isInMasterPlans
          ? diffDays
          : rentalEn.manualBooking.custom;
        dateObj["selectedDaysCustom"] = diffDays;
      }
    } else if (
      startDate &&
      fields?.selectedPackage === rentalEn.manualBooking.custom &&
      !endDate
    ) {
      dateObj["endDate"] = calculateFutureDate(startDate, 1);
    } else if (
      startDate &&
      fields?.selectedPackage === rentalEn.manualBooking.custom &&
      !endDate
    ) {
      dateObj["endDate"] = calculateFutureDate(startDate, 1);
    } else if (
      startDate &&
      fields?.selectedPackage !== rentalEn.manualBooking.custom &&
      !endDate
    ) {
      const endDateCal = calculateFutureDate(
        startDate,
        fields?.selectedPackage
      );
      if (endDateCal && endDateCal < currentDate) {
        toastr.warning(
          "",
          rentalEn.errorMessages.EndDateGreaterThanCurrentDate
        );
        setFields((prev: any) => ({
          ...prev,
          startDate: currentDate,
          endDate: calculateFutureDate(currentDate, fields?.selectedPackage),
        }));
        return;
      } else {
        dateObj["endDate"] = endDateCal;
      }
    }
    setFields((prev: any) => ({
      ...prev,
      ...dateObj,
    }));
    setshowVehicleCard(false);
  };

  const handlePackagesSelection = (data: any) => {
    if (data === rentalEn.manualBooking.custom) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPackage: rentalEn.manualBooking.custom,
        startDate: "",
        endDate: "",
      }));
    } else if (fields?.startDate === null) {
      if (
        isDateUnavailable(currentDate, calculateFutureDate(currentDate, data))
      ) {
        toastr.error("", rentalEn.errorMessages.slotpauseDateAvailableMessage);
        setFields((prev: any) => ({
          ...prev,
          startDate: null,
          endDate: null,
        }));
        return;
      }

      setFields((prev: any) => ({
        ...prev,
        startDate: currentDate,
        endDate: calculateFutureDate(currentDate, data),
      }));
    } else if (
      isDateUnavailable(
        fields?.startDate,
        calculateFutureDate(fields?.startDate, data)
      )
    ) {
      toastr.error("", rentalEn.errorMessages.slotpauseDateAvailableMessage);
      setFields((prev: any) => ({
        ...prev,
        startDate: null,
        endDate: null,
      }));
      return;
    } else if (
      calculateFutureDate(fields?.startDate, data) &&
      calculateFutureDate(fields?.startDate, data) < currentDate
    ) {
      toastr.warning("", rentalEn.errorMessages.EndDateGreaterThanCurrentDate);
      setFields((prev: any) => ({
        ...prev,
        startDate: currentDate,
        endDate: calculateFutureDate(currentDate, data),
      }));
      return;
    } else {
      setFields((prev: any) => ({
        ...prev,
        endDate: calculateFutureDate(fields?.startDate, data),
      }));
    }
  };

  //  reset All data of manual booking form
  const resetData = (exceptions: string[]) => {
    setFields((prev: any) => resetFieldsExcept(prev, initialState, exceptions));
    setSlot(false);
    setshowVehicleCard(false);
    setIsCouponApplied(false);
    resetMultistepFormRef.current &&
      resetMultistepFormRef.current.nextStepsDisabled();
  };

  // handle selected vehicle from dropdown
  const handleGetAvailableModels = (data: any) => {
    const payloadModels = {
      payload: {
        startDate: getTimestampAtStartOfDay(data?.startDate),
        endDate: getTimestampAtEndOfDay(data?.endDate),
        branches: [data?.branchName],
        useCase: data?.useCase, // use RENTALS for now - RENTALS, ETR
      },
      showError: true,
    };

    dispatch(manualBookingActions.getAvailableModels(payloadModels));
  };

  //success call for coupon applied
  const couponAppliedSucess = () => {
    setIsCouponApplied(true);
    toastr.success("", rentalEn?.errorMessages?.couponAppliedSuccessfully);
  };

  //on click Apply button for coupon in side drawer
  const handleApplyCouponClick = () => {
    const deliveryDetails: any = {
      type: fields?.modeOfDelivery,
      slot: fields?.selectedTimeSlot,
      ...(fields?.modeOfDelivery === tyesOfModeOfDelivery.CUSTOMER_LOCATION && {
        addressId: fields?.addressId,
      }),
    };

    let chargesProps: any = {
      modelName: fields?.selectedVehicle?.modelName,
      branchName: fields?.selectedBranch?.name,
      useCase: fields?.bookingType?.name,
      customerId: userDetails?.id,
      startDate: getTimestampAtStartOfDay(fields?.startDate),
      endDate: getTimestampAtEndOfDay(fields?.endDate),
      complementaryHelmet: fields?.complementaryHelmet,
      unlimitedSelected:
        fields?.selectedPlan?.key === planTypeKeys.UnlimitedPlan ? true : false,
      premiumSelected:
        fields?.selectedPlan?.key === planTypeKeys.PremiumPlan ? true : false,
      deliveryDetails: deliveryDetails,
      helmetSelection: fields?.helmets,
      couponCode: fields?.couponCode ? fields?.couponCode : undefined,
    };

    if (isCouponApplied) {
      setIsCouponApplied(false);
      setFields((prev: any) => ({
        ...prev,
        couponCode: "",
      }));
      chargesProps.couponCode = undefined;
      callCalculateCharges(chargesProps);
    } else {
      callCalculateCharges(chargesProps, couponAppliedSucess);
    }
  };

  // returns payment breakup
  const getPaymentBreakUp = () => {
    // handle discount
    let discounts = new Map<string, GenericObject>();
    discounts.set(discountsTypes.PROMOTION, { amount: 0, code: "" });
    discounts.set(discountsTypes.COUPON_CODE, { amount: 0, code: "" });

    const paymentBreakup = charges?.charges?.map(
      (charge: any, index: number) => {
        // Calculate the total count of helmets
        const totalCount = Object.values(charges?.helmetSelection).reduce(
          (acc: any, value: any) => acc + value,
          0
        );
        // Check if the subCategory is "addons"
        const isAddOns = charge?.subCategory === ChargesTypeKeys.HELMET_CHARGES;
        // Determine surge related values

        const surgeMultiplier =
          charge?.subCategory === ChargesTypeKeys.SURGE_CHARGES &&
          charge?.details.type === SurgefactorType.MULTIPLIER
            ? `(x${charge?.details?.factor})`
            : "";

        const surgeIncrement =
          charge?.subCategory === ChargesTypeKeys.SURGE_CHARGES &&
          charge?.details.type === SurgefactorType.INCREASE_BY_AMOUNT
            ? `(+${getFormattedINR(charge?.details?.factor)})`
            : "";

        const getSurgeType = () => {
          if (charge?.subCategory === ChargesTypeKeys.SURGE_CHARGES) {
            if (charge?.details.type === SurgefactorType.MULTIPLIER) {
              return en?.NewManualBooking?.Multiplier;
            } else {
              return en?.NewManualBooking?.IncreaseByAmount;
            }
          }
          return "";
        };

        if (charge?.couponCode?.length) {
          for (let i = 0; i < charge.couponCode?.length; i++) {
            discounts.set(charge?.couponCode[i]?.type, {
              amount:
                charge.couponCode[i]?.discount +
                discounts.get(charge?.couponCode[i]?.type)?.amount,
              code: charge.couponCode[i]?.code,
            });
          }
        }
        return (
          <SubscriptionItem
            surgeMultiplier={surgeMultiplier ? surgeMultiplier : surgeIncrement}
            title={ChargesType[charge?.subCategory]}
            showBorder={true}
            icon={ChargeIcons[charge?.subCategory]}
            key={index}
            amount={getFormattedINR(charge?.amount)}
            addOns={isAddOns ? charges?.helmetSelection : undefined}
            totalHelmetCount={isAddOns ? totalCount : undefined}
            helmetCharges={config?.helmets}
            surgeType={getSurgeType()}
            loadingCharges={loadingCalculateCharges}
          />
        );
      }
    );
    return { paymentBreakup, discounts };
  };

  //get tooltip
  const getDiscountTooltip = (data: any) => (
    <Stack sx={{ padding: "16px", gap: "3px" }}>
      {discountsTypeArray?.map((type: string, index: number) => {
        let discount = data?.get(type);

        // return coupon code string
        const getCoupon = (): string => `(${discount?.code})`;

        if (discount?.amount)
          return (
            <Box
              sx={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
              }}
            >
              {Bull}
              <Typography
                variant={typographyConstants.BODY}
                color={colors.white}
                sx={{ fontWeight: 500 }}
              >
                {`${
                  discountTypesDisplay[
                    type as keyof typeof discountTypesDisplay
                  ]
                } ${type === discountsTypes.COUPON_CODE ? getCoupon() : ""} :`}
              </Typography>
              <Typography
                variant={typographyConstants.SUBHEADING}
                color={colors.white}
                sx={{ padding: "0px" }}
              >
                {getFormattedINR(discount?.amount)}
              </Typography>
            </Box>
          );
      })}
    </Stack>
  );

  //calculate charges api
  const callCalculateCharges = (
    props: any,
    couponAppliedSucessCallback: Function = () => {}
  ) => {
    const apiPayload = {
      payload: {
        data: {
          modelName: props?.modelName,
          branchName: props?.branchName,
          useCase: props?.useCase,
          customerId: props?.customerId,
          startDate: props?.startDate,
          endDate: props?.endDate,
          complementaryHelmet: props?.complementaryHelmet,
          unlimitedSelected: props?.unlimitedSelected,
          premiumSelected: props?.premiumSelected,
          deliveryDetails: props?.deliveryDetails,
          helmetSelection: props?.helmetSelection,
          couponCode: props?.couponCode,
        },
      },
      couponAppliedSucess: couponAppliedSucessCallback,
    };
    if (fields.helmets || fields.selectedPlan)
      dispatch(manualBookingActions?.calculateCharges(apiPayload));
  };

  // MultiStep Form steps
  const steps = [
    {
      key: multiStepFormStepKeys[0].stepKey,
      step: multiStepFormStepKeys[0].displayName,
      isEnabledUser: true,
      render: (
        <BookingDetails
          fields={fields}
          setFields={setFields}
          setErrorFields={setErrorFields}
          errorFields={errorFields}
          masterPlanDurationsData={masterPlanDurationsData}
          AccessCity={allCitiesDetails}
          branches={ActiveBranches}
          BookingTypeArr={useCaseArray}
          handleDateChange={handleDateChange}
          deliverySlots={deliverySlots}
          setHomeDeliveryModalOpen={setHomeDeliveryModalOpen}
          availablePeriods={availablePeriods}
          renderTimeSlots={renderTimeSlots}
          setSlot={setSlot}
          Slot={slot}
          initialState={initialState}
          resetData={resetData}
          setshowVehicleCard={setshowVehicleCard}
          resetMultistepFormRef={resetMultistepFormRef}
          handleGetAvailableModels={handleGetAvailableModels}
          handlePackagesSelection={handlePackagesSelection}
          setIsCouponApplied={setIsCouponApplied}
          planType={planType}
        />
      ),
      validateFunction: validateBookingDetails,
    },
    {
      key: multiStepFormStepKeys[1].stepKey,
      step: multiStepFormStepKeys[1].displayName,
      isEnabledUser: true,
      render: (
        <Checkout
          fields={fields}
          setFields={setFields}
          setErrorFields={setErrorFields}
          errorFields={errorFields}
          planType={planType}
          callCalculateCharges={callCalculateCharges}
          getPaymentBreakUp={getPaymentBreakUp}
          getDiscountTooltip={getDiscountTooltip}
        />
      ),
      renderFooter: (
        <CheckoutFooter
          fields={fields}
          setOpenSideDrawer={setOpenSideDrawer}
          charges={charges}
          loading={loadingCalculateCharges}
        />
      ),
    },
  ];

  const PlanDetailsPayNowPopUp = () => {
    return (
      <Box>
        <Stack marginBottom={"20px"} marginTop={"20px"}>
          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors.text_secondary_gray}
            fontSize={"12px"}
            marginBottom={"5px"}
          >
            {rentalEn.manualBooking.OffersAndBenefits}
          </Typography>
          <Box className={styles.BoxRow} sx={{ gap: "10px" }}>
            <Input
              onChange={(e: any) => {
                e.preventDefault();
                setFields((prev: any) => ({
                  ...prev,
                  couponCode: e?.target?.value?.toUpperCase(),
                }));
              }}
              iconStart={ApplyCouponIcon}
              value={fields?.couponCode.toUpperCase()}
              placeholder={rentalEn?.manualBooking?.applyCoupons}
              width="250px"
              containerWidth="250px"
              disabled={isCouponApplied}
            />
            <CustomButton
              variant="outlined"
              label={
                isCouponApplied
                  ? rentalEn?.global?.remove
                  : rentalEn?.global?.apply
              }
              sx={{ padding: "4px 15px" }}
              onClick={handleApplyCouponClick}
              disabled={!fields?.couponCode}
              loading={loadingCalculateCharges}
            />
          </Box>
        </Stack>
        <Typography
          variant={typographyConstants.SUBHEADING}
          color={colors.text_secondary_gray}
          fontSize={"12px"}
        >
          {en.NewManualBooking.paymentDetails}:
        </Typography>
        <Box
          sx={{
            borderWidth: "1px solid",
            border: colors.text_secondary_gray,
            borderRadius: "12px",
            padding: "1px 0px",
            backgroundColor: colors.white,
            marginTop: "10px",
          }}
        >
          {getPaymentBreakUp()?.paymentBreakup}
          {charges?.totalDiscount !== 0 && (
            <SubscriptionItem
              title={rentalEn?.global?.discount}
              amountColor={colors?.secondary_green}
              amount={`- ${getFormattedINR(charges?.totalDiscount)}`}
              loadingCharges={loadingCalculateCharges}
              titleTooltip={getDiscountTooltip(getPaymentBreakUp()?.discounts)}
            />
          )}

          {/* Payable amount */}
          <Box sx={{ padding: "5px" }}>
            <TotalCharge
              title={en.NewManualBooking.payableAmount}
              amount={getFormattedINR(
                charges?.totalAmount - charges?.totalDiscount
              )}
              loadingCalculateCharges={loadingCalculateCharges}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  //success call baack for payments
  const sucessCallbackPayment = () => {
    // Navigate to Booking Management
    navigate(routesConstants.ALL_BOOKINGS);
  };

  // handle Create Booking in payment drawer
  const handlePaymentButtonClick = () => {
    if (isManualBookingCreated) {
      const payload = {
        data: {
          orderId: bookingCreatedDeatils?.orderDetails?.id,
          paymentFlow: fields?.selectedPaymentMode,
        },
      };
      dispatch(rentalActions?.paymentLinkGeneration(payload));
    } else {
      const deliveryDetails = {
        type: fields?.modeOfDelivery,
        slot: fields?.selectedTimeSlot,
        ...(fields?.modeOfDelivery ===
          tyesOfModeOfDelivery.CUSTOMER_LOCATION && {
          addressId: fields?.addressId,
        }),
      };

      const chargesProps: any = {
        modelName: fields?.selectedVehicle?.modelName,
        branchName: fields?.selectedBranch?.name,
        useCase: fields?.bookingType?.name,
        customerId: userDetails?.id,
        startDate: getTimestampAtStartOfDay(fields?.startDate),
        endDate: getTimestampAtEndOfDay(fields?.endDate),
        complementaryHelmet: fields?.complementaryHelmet,
        unlimitedSelected:
          fields?.selectedPlan?.key === planTypeKeys.UnlimitedPlan
            ? true
            : false,
        premiumSelected:
          fields?.selectedPlan?.key === planTypeKeys.PremiumPlan ? true : false,
        deliveryDetails: deliveryDetails,
        helmetSelection: fields?.helmets,
        couponCode: fields?.couponCode ? fields?.couponCode : undefined,
      };

      let payload = {
        createBookingPayload: {
          data: {
            modelName: fields?.selectedVehicle?.modelName,
            branchName: fields?.selectedBranch?.name,
            useCase: fields?.bookingType?.name,
            customerId: userDetails?.id,
            startDate: getTimestampAtStartOfDay(fields?.startDate),
            endDate: getTimestampAtEndOfDay(fields?.endDate),

            complementaryHelmet: fields?.complementaryHelmet,
            unlimitedSelected:
              fields?.selectedPlan?.key === planTypeKeys.UnlimitedPlan
                ? true
                : false,
            premiumSelected:
              fields?.selectedPlan?.key === planTypeKeys.PremiumPlan
                ? true
                : false,
            deliveryDetails: deliveryDetails,
            helmetSelection: fields?.helmets,
            couponCode: fields?.couponCode ? fields?.couponCode : undefined,
            //   {
            //     id: "string",
            //     code: "string",
            //   },
            // ],
          },
        },
        paymentLinkGenerationPayload: {
          paymentFlow: fields?.selectedPaymentMode,
        },
        amount: charges?.totalAmount - charges?.totalDiscount,
        sucessCallback: sucessCallbackPayment,
        lastCalculatedCharge: charges?.totalAmount - charges?.totalDiscount,
        calculateChargePayload: chargesProps,
      };

      dispatch(manualBookingActions?.createBooking(payload));
    }
  };
  const handlePaymentModalClose = () => {
    dispatch(
      rentalActions.clearReducerDataRentalCommon({
        isPaymentLinkGenerated: false,
        paymentLink: {},
        updatedStatus: "",
      })
    );
    dispatch(rentalActions.cancelPaymentStatus());
    navigate(`${routesConstants.BOOKING_DETAILS}/${bookingCreatedDeatils.id}`);
    clearFieldsData();
  };

  //hanlde paymnet change mode
  const handlePaymentModeChange = (event: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      selectedPaymentMode: event.target.value,
    }));
  };

  // Get image path Url
  let modelImageFilename = "";
  let modelImageType = "";
  availableModels?.length > 0 &&
    availableModels?.map((model: any, index: number) => {
      if (model?.modelName === fields?.selectedVehicle?.modelName)
        if (model?.images?.[0]?.type === "right") {
          modelImageFilename = model?.images?.[0]?.fileName;
          modelImageType = model?.images?.[0]?.type;
        }
      if (!modelImageFilename) {
        modelImageFilename = model?.images?.[0]?.fileName;
        modelImageType = model?.images?.[0]?.type;
      }
    });

  const imagePathUrl = modelImageFilename
    ? `${MITC_FORM_URL}/images/model/${fields?.selectedVehicle?.modelName}/${modelImageType}-${modelImageFilename}`
    : "";

  //navigate to customer details
  const navigateToCustomerManagement = () => {
    navigate(`${routesConstants.CUSTOMER_MANAGEMENT}/${userDetails?.id}`);
  };

  return (
    <Box sx={{ backgroundColor: "unset" }}>
      <Box className={styles.BoxColumn} gap="30px">
        <Box className={styles.BoxRow} gap={3}>
          {/* User Details Card */}
          {checkIsUserExist || showUserDetails ? (
            <TopCards
              avatar={getInitials(
                getFullName(userDetails?.firstName, userDetails?.lastName)
              )}
              userImage={userDetails?.profileImage}
              label={getFullName(userDetails.firstName, userDetails.lastName)}
              genderSection={getGenderLabelByEnum(userDetails?.gender)}
              phone={userDetails?.mobileNumber}
              email={userDetails?.email}
              clearFieldsData={clearFieldsData}
              isKycVerified={
                userDetails?.tags?.length > 0 &&
                userDetails.tags.includes(TAGS.KYC_VERIFIED)
              }
              img={EditIconBlack}
              navigateToCustomerManagement={navigateToCustomerManagement}
            />
          ) : (
            <></>
          )}
          {/* Vehicle Details Card  */}
          {showVehicleCard ? (
            <TopCards
              vehicleImg="vehicle"
              imagePathUrl={imagePathUrl}
              label={fields.selectedVehicle?.displayName}
              fields={fields}
            />
          ) : (
            <></>
          )}
        </Box>
        {checkIsUserExist || userRegistered ? (
          <MultiStepForm
            width={"225px"}
            steps={steps}
            footer={true}
            ref={resetMultistepFormRef}
            Styles={ManualBookingAdminStyles}
          />
        ) : (
          <UserDetails
            fields={fields}
            setFields={setFields}
            setErrorFields={setErrorFields}
            errorFields={errorFields}
            showUserData={showUserData}
            checkUserLoader={checkUserLoader}
            checkIsUserExist={checkIsUserExist}
            SearchUser={SearchUser}
            setClearFields={setClearFields}
            validateRegisterUserDetails={validateRegisterUserDetails}
          />
        )}
      </Box>

      <SideDrawer
        open={openSideDrawer}
        toggleDrawer={toggleDrawer}
        selectedPaymentMode={fields?.selectedPaymentMode}
        renderUI={PlanDetailsPayNowPopUp()}
        totalCharges={charges?.totalAmount}
        handlePaymentButtonClick={handlePaymentButtonClick}
        handlePaymentModalClose={handlePaymentModalClose}
        handlePaymentModeChange={handlePaymentModeChange}
        isManualBookingCreated={isManualBookingCreated}
      />

      {sendOTPModalOpen && (
        <SendOTPModal
          fields={fields}
          setOtp={setOtp}
          seconds={seconds}
          setSeconds={setSeconds}
          errorFields={errorFields}
          openModal={sendOTPModalOpen}
          setOpenModal={setSendOTPModalOpen}
          handleCloseSendOTPModal={handleCloseSendOTPModal}
          onClickResendOtp={onClickResendOtp}
          setFields={setFields}
        />
      )}

      {homeDeliveryModalOpen && (
        <HomeDeliveryModal
          openModal={homeDeliveryModalOpen}
          handleModalClose={handleCloseHomeDeliveryModal}
          setOpenModal={setHomeDeliveryModalOpen}
          userDetails={userDetails}
          modelDetails={modelDetails}
        />
      )}
    </Box>
  );
};

export default NewManualBooking;
