import { RotateRightSharp } from "@mui/icons-material";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../config/filterStorage";
import {
  modules,
  surgeFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { LocalStorage } from "../../../../constants/enum";
import { RootState } from "../../../../redux/reducers";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { AntSwitch } from "../../../../sharedComponents/molecules/MuiSwitch";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../../utils/helper";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  filterStorageKeys,
  GenericObject,
  surgeDateType,
} from "../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  fadedCalenderIcon,
  WarehouseIcon,
} from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import {
  rentalActions,
  surgeActions,
  vehicleActions,
} from "../../../redux/actions";
import { routesConstants } from "../../../utils/RoutesConstants";
import { paisaToRupee } from "../../../utils/helper";

const { tableHeaders, surgeManagement, global } = rentalEn;

// Initial state object for filter and state management
const initialState: GenericObject = {
  paginationModel: { page: 0, pageSize: 10 },
  city: { name: "", displayName: global?.allCities },
  branch: { name: "", displayName: global?.allHubs },
  model: { name: "", displayName: global?.allModels },
  dateType: { name: "", displayName: "" },
};

const SurgeList = () => {
  // rbac implementation
  const RbacHasAccess = {
    listSurge: RbacHelper.isAccessRightsProvided(
      modules.SURGE_MANAGEMENT,
      surgeFunctionalities.LIST_SURGE
    ),
    createSurge: RbacHelper.isAccessRightsProvided(
      modules.SURGE_MANAGEMENT,
      surgeFunctionalities.CREATE_SURGE
    ),
    editSurge: RbacHelper.isAccessRightsProvided(
      modules.SURGE_MANAGEMENT,
      surgeFunctionalities.EDIT_SURGE
    ),
    viewSurge: RbacHelper.isAccessRightsProvided(
      modules.SURGE_MANAGEMENT,
      surgeFunctionalities.VIEW_SURGE
    ),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux selector
  const {
    onLoad, // Loading state
    surgeListData, // Surge list data displayed in UI
    surgeListApiResponseData, // Full surge list data from API response
  } = useSelector((state: RootState) => state.surgeReducer);

  // Redux selector
  const {
    allCitiesDetails, // List of all cities available
    branches, // List of branches/hubs based on selected city
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  // Redux selector
  const { vehicleModelListData } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  // Redux selector
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  // Local state for managing filter fields and dialog state
  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [dialog, setDialog] = useState<GenericObject>({
    openDialog: false, // Flag to control dialog visibility
    title: "", // Title of the dialog
    body: "", // Body content of the dialog
    payloadData: {}, // Data payload passed to functions on dialog actions
  });

  // Ensure the rowCount value is stable during loading by memoizing it.
  // This prevents the rowCount from becoming undefined and resetting the page to zero.
  // Create a mutable reference to store the rowCount value.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    surgeListApiResponseData?.pagination?.total || 0
  );

  // Memoize the rowCount value to avoid unnecessary changes during loading.
  // Update the rowCountRef only if surgeListApiResponseData?.pagination?.total is defined.
  // Return the stable rowCount from the rowCountRef.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (surgeListApiResponseData?.pagination?.total !== undefined) {
      rowCountRef.current = surgeListApiResponseData?.pagination?.total;
    }
    return rowCountRef.current;
  }, [surgeListApiResponseData?.pagination?.total]);

  // Function to construct payload for fetching surge list data
  const constructSurgeListPayload = (fields: GenericObject): GenericObject => {
    const surgeListPayload: GenericObject = {
      page: fields?.paginationModel?.page + 1, // MUI fields grid page starts from zero, so add 1
      limit: fields?.paginationModel?.pageSize,
      city: fields?.city?.name,
      branch: fields?.branch?.name,
      model: fields?.model?.name,
      dateType: fields?.dateType?.name,
    };
    return surgeListPayload;
  };

  useEffect(() => {
    // Effect to fetch initial data and set filters from local storage
    const fetchFilterData = async () => {
      try {
        const data = await getFilterDataFromLocalStorage(
          filterStorageKeys?.SURGE_LIST_FILTER_DATA
        );
        if (data) {
          setFields((prev: any) => ({ ...prev, ...data }));

          // Fetch branches based on selected city data from local storage
          if (data?.city?.name) {
            dispatch(rentalActions.getAllBranches(data?.city));
          }

          // Fetch vehicle models based on selected city and branch data from local storage
          const vehiclePayload: GenericObject = {
            cities: data?.city?.name ? [data?.city?.name] : null,
            branches: data?.branch?.name ? [data?.branch?.name] : null,
          };
          if (data?.city?.name || data?.branch?.name) {
            dispatch(vehicleActions.fetchVehicleModelListData(vehiclePayload));
          } else {
            dispatch(vehicleActions.fetchVehicleModelListData());
          }

          // Construct payload and fetch surge list based on local storage data
          const payload: GenericObject = constructSurgeListPayload(data);
          dispatch(surgeActions.getSurgeList(payload));
        } else {
          // Fetch vehicle models (from the data available in authorize)
          dispatch(vehicleActions.fetchVehicleModelListData());

          // Fetch surge list even if no filters are selected
          dispatch(surgeActions.getSurgeList({ page: 1, limit: 10 }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Initial api calls on component mount
    fetchFilterData();
  }, []);

  // Columns configuration for MUI data grid
  const columns: GenericObject[] = [
    {
      field: "surgeName",
      headerName: tableHeaders?.surgeName,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "surgeType",
      headerName: tableHeaders?.surgeType,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {params?.row?.surgeType ===
          surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
            ? surgeManagement?.surgeTypes?.Increment
            : surgeManagement?.surgeTypes?.Multiplier ?? global?.NA}
        </span>
      ),
    },
    {
      field: "surgeValue",
      headerName: tableHeaders?.surgeBy,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {params?.row?.surgeType ===
          surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
            ? `₹ ${paisaToRupee(params?.row?.surgeValue)}`
            : `${params?.row?.surgeValue}x` ?? global?.NA}
        </span>
      ),
    },
    {
      field: "tempModelsDisplayName",
      headerName: tableHeaders?.vehicleModel,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cityDisplayName",
      headerName: tableHeaders?.city,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempBranchesDisplayName",
      headerName: tableHeaders?.hub,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempDurations",
      headerName: tableHeaders?.package,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempDateType",
      headerName: tableHeaders?.surgeDate,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      type: "actions",
      field: "surgeStatus",
      headerName: tableHeaders?.status,
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params: any) => {
        return [
          <Tooltip
            title={
              !params?.row?.isEditable
                ? surgeManagement?.YouCanNotUpdateSurgeStatus
                : null
            }
            arrow
          >
            <span>
              <AntSwitch
                checked={
                  params?.row?.isEditable &&
                  params?.row?.surgeStatus ===
                    surgeManagement?.surgeStatus?.ACTIVE
                    ? true
                    : false
                }
                disabled={!RbacHasAccess.editSurge || !params?.row?.isEditable}
                inputProps={{ "aria-label": "controlled" }}
                onChange={() => {
                  const surgeStatusPayload: any = {};
                  const tempSurgeStatus: any =
                    params?.row?.surgeStatus ===
                    surgeManagement?.surgeStatus?.ACTIVE
                      ? surgeManagement?.surgeStatus?.INACTIVE
                      : surgeManagement?.surgeStatus?.ACTIVE;
                  const payloadObj: any = {
                    surgeId: params?.row?.surgeId,
                    surgeStatus: tempSurgeStatus,
                  };
                  surgeStatusPayload.data = payloadObj;
                  if (params?.row?.isEditable) {
                    setDialog((prev: any) => ({
                      ...prev,
                      openDialog: true,
                      title: surgeManagement?.UpdateSurgeStatus,
                      body:
                        params?.row?.surgeStatus ===
                        surgeManagement?.surgeStatus?.ACTIVE
                          ? surgeManagement?.DeactivateSurgeStatusConfirmation
                          : surgeManagement?.ActivateSurgeStatusConfirmation,
                      payloadData: { ...surgeStatusPayload },
                    }));
                  }
                }}
              />
            </span>
          </Tooltip>,
        ];
      },
    },
  ];

  // Handler to update filter fields based on user selections
  const handleChange = (key: string, value: any) => {
    let data: GenericObject = {};
    if (key === "city") {
      // Reset branch, model, and dateType on city change
      data = {
        branch: { name: "", displayName: global?.allHubs },
        model: { name: "", displayName: global?.allModels },
        dateType: { name: "", displayName: global?.allSurgeTypes },
      };

      if (value?.name) {
        // Fetch branches based on selected city
        dispatch(rentalActions.getAllBranches(value));

        // Fetch vehicle models based on selected city
        const payload: GenericObject = {
          cities: [value?.name],
        };
        dispatch(vehicleActions.fetchVehicleModelListData(payload));
      }
    } else if (key === "branch") {
      // Reset model on hub change
      data = {
        model: { name: "", displayName: global?.allModels },
      };

      // Fetch vehicle models based on selected city and branch
      if (value?.name) {
        const payload: GenericObject = {
          cities: fields?.city?.name ? [fields?.city?.name] : null,
          branches: [value?.name],
        };
        dispatch(vehicleActions.fetchVehicleModelListData(payload));
      }
    }

    // Always update the state with the new key-value pair and additional data
    const newFields: GenericObject = {
      ...fields,
      [key]: value,
      ...data,
      paginationModel: initialState?.paginationModel, // Reset pagination on filter change
    };
    setFields(newFields);

    // Store updated data in local storage on user selections
    const storeData: GenericObject = {
      surgeListFilterData: { ...newFields },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Construct payload and Fetch surge list data
    const payload: GenericObject = constructSurgeListPayload(newFields);
    dispatch(surgeActions.getSurgeList(payload));
  };

  // Handler for pagination model changes
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    // Store updated data in local storage on user selections
    const storeData: GenericObject = {
      surgeListFilterData: {
        ...fields,
        paginationModel: { page: page, pageSize: pageSize },
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Construct payload and Fetch surge list data
    const payload: GenericObject = constructSurgeListPayload(fields);
    payload.page = page + 1;
    payload.limit = pageSize;
    dispatch(surgeActions.getSurgeList(payload));
  };

  // Handler for row click event in the data grid
  const handleRowClick: GridEventListener<"rowClick"> = (params: any) => {
    // Call the function here with the row data
    const { row } = params;
    RbacHasAccess.viewSurge &&
      navigate(`${routesConstants.CREATE_SURGE}/${row?.surgeId}`); // Navigate to the create-surge screen (View Mode) with the selected row's surgeId
  };

  // Handler for 'No' button click in confirmation dialog
  const onNoClickHandler = () => setDialog({ ...dialog, openDialog: false });

  // Handler for 'Yes' button click in confirmation dialog
  const onYesClickHandler = () => {
    setDialog({ ...dialog, openDialog: false }); // Close the confirmation dialog
    dispatch(surgeActions.updateSurgeStatus(dialog?.payloadData)); // Dispatch action to update surge status
  };

  // Handler to reset all filters
  const handleFilterReset = () => {
    setFields({ ...initialState });

    // Store initial state data in local storage reset filter button click
    const storeData: GenericObject = {
      surgeListFilterData: { ...initialState },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    dispatch(vehicleActions.fetchVehicleModelListData());

    // Fetch surge list even if no filters are selected
    dispatch(surgeActions.getSurgeList({ page: 1, limit: 10 }));
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Add New Surge Button Section */}
        <Box>
          {RbacHasAccess.createSurge && (
            <CustomButton
              label={surgeManagement?.AddNewSurge}
              variant="outlined"
              // navigate to create-surge screen (Create Mode) to add new surge
              onClick={() => {
                dispatch(
                  surgeActions.resetReducerStates({ surgeByIdData: {} })
                );
                navigate(routesConstants.CREATE_SURGE);
              }}
              sx={{ padding: "3px 22px" }}
            />
          )}
        </Box>
        {RbacHasAccess.listSurge && (
          <Tooltip title={global?.resetFilters} arrow>
            <RotateRightSharp
              fontSize="large"
              color="primary"
              sx={{ cursor: "pointer", fontSize: "25px" }}
              onClick={handleFilterReset}
            />
          </Tooltip>
        )}
      </Box>

      {/* Surge List Filter Dropdowns Section */}
      {RbacHasAccess.listSurge && (
        <Box sx={{ flexGrow: 1, alignItems: "center" }}>
          <Grid container spacing={1}>
            {/* City Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={CityInputIcon}
                placeholder={global?.selectCity}
                value={
                  isArrayNotEmpty(allCitiesDetails)
                    ? fields?.city?.displayName
                    : ""
                }
                choice={
                  isArrayNotEmpty(allCitiesDetails)
                    ? [
                        { name: "", displayName: global?.allCities },
                        ...allCitiesDetails,
                      ]
                    : []
                }
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("city", value);
                }}
                disabled={!isArrayNotEmpty(allCitiesDetails)}
              />
            </Grid>

            {/* Hub Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={WarehouseIcon}
                placeholder={
                  isArrayNotEmpty(branches)
                    ? global?.selectHub
                    : global?.allHubs
                }
                value={
                  isArrayNotEmpty(branches) ? fields?.branch?.displayName : ""
                }
                choice={
                  isArrayNotEmpty(branches)
                    ? [{ name: "", displayName: global?.allHubs }, ...branches]
                    : isArrayNotEmpty(userData?.authorizedBranches)
                    ? [
                        { name: "", displayName: global?.allHubs },
                        ...userData?.authorizedBranches,
                      ]
                    : []
                }
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("branch", value);
                }}
                disabled={
                  !isArrayNotEmpty(branches) ||
                  !isArrayNotEmpty(userData?.authorizedBranches) ||
                  !fields?.city?.name
                }
              />
            </Grid>

            {/* Vehicle Model Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={BikeIconFaded}
                placeholder={
                  isArrayNotEmpty(vehicleModelListData)
                    ? global?.selectModel
                    : global?.allModels
                }
                value={
                  isArrayNotEmpty(vehicleModelListData)
                    ? fields?.model?.displayName
                    : ""
                }
                choice={
                  isArrayNotEmpty(vehicleModelListData)
                    ? [
                        { name: "", displayName: global?.allModels },
                        ...vehicleModelListData,
                      ]
                    : []
                }
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("model", value);
                }}
                disabled={!isArrayNotEmpty(vehicleModelListData)}
              />
            </Grid>

            {/* Surge Type Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={fadedCalenderIcon}
                placeholder={global?.allSurgeTypes}
                value={fields?.dateType?.displayName}
                choice={surgeDateType}
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("dateType", value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Surge List MUi Data Grid Table Section */}
      {RbacHasAccess.listSurge && (
        <DataTable
          rows={surgeListData}
          columns={columns}
          rowCount={rowCount}
          paginationModel={fields?.paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          paginationMode="server"
          hideFooter={!isArrayNotEmpty(surgeListData)}
          onRowClick={handleRowClick}
          loading={onLoad}
        />
      )}

      {/* Surge Status Change Confirmation Modal */}
      <ResponsiveDialog
        title={dialog?.title}
        body={dialog?.body}
        open={dialog?.openDialog}
        handleClose={onNoClickHandler}
        handleYes={onYesClickHandler}
      />
    </Stack>
  );
};

export default SurgeList;
