import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import profileService from "../../services/profileService";
import * as actionTypes from "../actions/actionTypes";
import { decrypt, handleMessage } from "../../utils/helper";
import TokenService from "../../config/tokenService";
import { actionInterface } from "../../rentals-src/interfaces/commonInterface";
import rentalCommonServices from "../../rentals-src/redux/services/rentalCommonServices";

function* userProfile(action: any): any {
  const data = yield call(profileService.userProfile, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_PROFILE_SUCCEEDED,
      payload: {
        ...(decrypt(data?.data?.Result) || []),
      },
    });
  } else {
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_PROFILE_FAILED,
    });
  }
}

function* userProfileUpdate(action: any): any {
  const data = yield call(profileService.userProfile, action.payload);
  if (data?.status === 200) {
    const userData = data.data.data;

    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.SET_USER_PROFILE_DATA,
      payload: {
        userData: {
          ...userData,
        },
      },
    });

    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_REQUESTED,
      payload:
        userData?.authorizedBranches?.map((item: any) => {
          return item.name;
        }) ?? [],
    });
  } else {
    yield put({
      // type: actionTypes.AUTH_ACTIONS.USER_PROFILE_FAILED,
      type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_REQUESTED,
    });
    action.navigate("/");
  }
}

function* userLogout(action: any): any {
  try {
    const data = yield call(profileService.userLogout, action.payload.data);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_SUCCESS,
        payload: data?.data?.data,
      });
      TokenService.removeTokenCookie();
      localStorage.clear(); // clear token data from cookies on successful user logout
      action.payload.navigate("/");
    } else {
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_FAILED,
      });
    }
  } catch (err) {}
}

function* authorizedModel(action: actionInterface): any {
  try {
    const data = yield call(rentalCommonServices.getModelListByBranch, {
      branches: action.payload,
    });
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_FAILED,
    });
  }
}

function* profileSaga() {
  yield takeLatest(
    actionTypes.PROFILE_API_ACTION.USER_PROFILE_REQUESTED,
    userProfile
  );
  yield takeLatest(
    actionTypes.PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
    userProfileUpdate
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_REQUESTED,
    userLogout
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.GET_AUTHORIZE_MODEL_REQUESTED,
    authorizedModel
  );
}

export default profileSaga;
