import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { setAuthToken } from "../../config";
import { authServices } from "../../services";
import { handleMessage } from "../../utils/helper";
import * as actionTypes from "../actions/actionTypes";

function* userLogin(action: any): any {
  const data = yield call(authServices.userLogin, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_LOGIN_SUCCEEDED,
      payload: {
        ...data.data,
      },
    });
    setAuthToken(data.data.Token);
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_LOGIN_FAILED,
    });
  }
}

function* userSignup(action: any): any {
  const data = yield call(authServices.userSignUp, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_SUCCEEDED,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_FAILED,
    });
  }
}

function* logout(): any {
  setTimeout(() => {
    toastr.success("", "Logged Out!");
  }, 500);

  const data = yield call(authServices.userLogout);

  // if (data?.status === 200) {
  //   yield put({
  //     type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_SUCCEEDED,
  //     payload: {
  //       ...data.data,
  //     },
  //   });
  // } else if (data?.response?.status === 403) {
  //   yield put(actions.showToast({
  //     toastMessage: data?.response?.data?.Message,
  //     toastType : ToastType.error
  //   }));
  // } else {
  //   yield put({
  //     type: actionTypes.AUTH_ACTIONS.USER_SIGNUP_FAILED,
  //     payload: { error: data?.response?.data?.Message || "" },
  //     errorMessage: data?.response?.statusText || "",
  //   });
  // }
}

function* authSaga() {
  yield takeLatest(actionTypes.AUTH_ACTIONS.USER_LOGIN_REQUESTED, userLogin);
  yield takeLatest(actionTypes.AUTH_ACTIONS.USER_SIGNUP_REQUESTED, userSignup);
  yield takeLatest(actionTypes.AUTH_ACTIONS.LOGOUT, logout);
}

export default authSaga;
