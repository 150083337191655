import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../sagas";
import { rootReducer } from "../reducers";

let sagaMiddleware = createSagaMiddleware();
const logger = (store: any) => (next: any) => (action: any) => {
  let result = next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(sagaMiddleware, logger),
});
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;
// to get redux store all reducers
export const getStore = () => store.getState();
export default store;
