export enum colors {
  white = "#FFFFFF",
  black = "#000000",
  light_black = "rgb(36, 43, 53)",
  // THEME_BLUE = "#00353d",
  THEME_BLUE = "#053C3D",
  light_gray = "#F2F2F2",
  lightGrayF3 = "#F3F3F3",
  grayE4 = "#E4E4E4",
  grayC6 = "#C6C6C6",
  grayE6 = "#E6E6E6",
  dark_gray = "rgb(114, 114, 114)",
  bluish_gray = "rgb(61, 56, 85)",
  heading = "rgb(123, 130, 137)",
  gray = "#D7DADF",
  error = "#FF0000",
  optional = "#7b8289",
  heading_black = "rgb(41, 40, 37)",
  LIGHT_BLUE = "rgb(43, 199, 201)",
  light_green = "rgb(22, 188, 118)",
  primary = "#2BC7C9",
  THEME_Yellow = "#FCB52F",
  light = "#ebedef",
  heading_text = "#006063",
  heading_main_text = "#020C17",
  card_text = "#00353D",
  card_body_text = "#707070",
  card_body_text_second = "#021325",
  edit_icon_color = "#009A3D",
  card_border_color = "#DDDDDD80",
  card_box_shadow = "#2BC7C926",
  border_cell = "#DDDDDD",
  task_background_color = "#FBFBFB",
  chip_background_colorG = "#009B6614",
  chip_background_cgray = "#01328714",
  chip_text_colorG = "#009B66",
  chip_text_cgray = "#013287",
  // new changes
  THEME_BLUEX = "#09CACC",
  text_black = "#3C3C43",
  bg_btn_color = "#1BD8DA",
  text_gray = "#3C3C43CC",
  // FreedoX Theme
  THEME_FREEDOX = "#1bd8da",

  //  rentalRevamp
  theme_blue = "#09CACC",
  primary_aqua_blue = "#1BD8DA",
  gradient_aqua_blue = "linear-gradient(270.37deg, rgba(43, 199, 201, 0.04) -1.53%, rgba(27, 196, 125, 0.04) 68.84%, rgba(24, 160, 251, 0.04) 101.83%)",
  text_quatenary_black = "#B1B1B4",
  text_secondary_gray = "#636369",
  tertiary_black = "#8A8A8E",
  white_99 = "#FFFFFF99",
  primary_dark_blue3 = "#055455",
  secondary_light_gray = "#606268",
  link_Water = "#D0D5DD",
  light_gray1 = "#DADADA",
  primary_light_gray = "#01353DA3",
  primary_light_gray2 = "#01353D29",
  primary_dark_blue4 = "#094748",
  bg_secondary_blue = "#01353D0A",
  tertiary_gray = "#7D7C7C",
  primary_danger = "#FF3B30",
  secondary_green = "#1BC47D",
  primary_warning = "#FF7A00",
  bg_light_gray = "#F2F4F4",
  industrial_revolution_gray = "#737373",
  olive_yellow = "#B4B80B",

  // yor new colors
  table_success_bg_YOR = "#1BC47D14",
  table_secondary_bg_YOR = "#00000008",
  table_error_bg_YOR = "linear-gradient(90deg, rgba(255, 59, 48, 0.08) 0%, rgba(243, 117, 106, 0.08) 100%)",
  table_warning_bg_YOR = "#FFBF6029",
  table_info_bg_YOR = "#1BC47D14",

  // side drawer
  side_drawer_heading_bg_color = "#01353D",
  side_drawer_heading_text_color = "#fff",
  dashboard_wrapper_bg_color = "#E7F0F0",
  card_bg_color = "#F6F6F6",
  card_heading_bg_color = "linear-gradient(90deg, rgba(1, 53, 61, 0.16) 0%, rgba(43, 199, 201, 0.16) 100%)",
  dark_green_bg = "#043C3E",
  gray_cccccc = "#CCCCCC",
  // card_heading_text_color = "#fff",
  card_box_shadow_style = "0px 3.88px 19.42px 0px #0000001A",
  card_background_secondary = "#2B2A2A",
  primary_dark_blue2 = "#098082",
  whiteF7 = "#FEF7FF",
  sherpa_blue = "#053C3D",
  secondary_white = "#FEF7FF",
  whiteF9 = "#F6FBF9",
  secondary_gray2 = "#D6DEE0",
  box_shadow_black = "#0000001F",
  secondary_gray3 = "#49454F",
  card_border = "#00000014",
  card_border_bottom = "#00000029",
  full_calander_total = "#CAF6D7",
  full_calander_available = "#F4ECFF",
  full_calander_engaged = "#E7FAFB",
  full_calander_blocked = "#F3D7D7",
  primary_dark_tone1 = "#09AEAF",
  light_green2 = "#D4E4E452",
  primary_white_tone1 = "#EFF1F0",
}
