import { Avatar, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Fade } from "react-awesome-reveal";
import { checkIfImageExists } from "../../../freedo-x-src/utils/helper";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import {
  KycPendingBadge,
  MailIcon,
  MobileIcon,
  calendarIconVehicle,
  kycVerifiedBadge,
  vehicleCardImg,
} from "../../../rentals-src/constants/exportImages";
import { dateFormat, getFormattedINR } from "../../../rentals-src/utils/helper";
import { colors } from "../../../themes/colors";
import styles from "./TopCards.module.css";
import en from "../../../locale/en.json";

export default function TopCards(props: any) {
  return (
    <Box className={styles.cardContainer} sx={{ position: "relative" }}>
      <Fade>
        {/* KYC SECTION */}
        <Box onClick={props?.navigateToCustomerManagement}>
          {props?.isKycVerified === true ? (
            <img
              src={kycVerifiedBadge}
              alt=""
              className={styles.topCardsimg}
              style={{ cursor: "pointer" }}
            />
          ) : props?.isKycVerified === false ? (
            <img
              src={KycPendingBadge}
              alt=""
              className={styles.topCardsimg}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <></>
          )}
        </Box>
        {/* IMAGE SECTION */}
        <Box>
          {props?.vehicleImg ? (
            <img
              src={props?.imagePathUrl}
              className={styles.modelImageContainer}
              style={{ objectFit: "contain" }}
              onError={(e: any) => {
                e.target.onError = null;
                e.target.src = vehicleCardImg;
              }}
            />
          ) : null}
          {props.avatar ? (
            props.userImage !== undefined ? (
              // Render image if userImage is defined and not empty
              <img
                src={props.userImage}
                alt="User Avatar"
                style={{ width: "60px", height: "60px", borderRadius: "100%" }}
              />
            ) : (
              // Render Avatar component if userImage is defined but empty
              <Avatar
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  width: "60px",
                  height: "60px",
                }}
              >
                {/* Render initials or default text */}
                {props.avatar}
              </Avatar>
            )
          ) : null}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Tooltip title={props.label} placement="top">
              <Typography
                component="div"
                fontSize={15}
                onClick={props?.navigateToCustomerManagement}
                sx={{
                  width: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                {props.label}
              </Typography>
            </Tooltip>
            <Tooltip
              title={en.NewManualBooking.EditMobileNumber}
              placement="top"
            >
              <Box
                sx={{ cursor: "pointer", padding: "0px 5px" }}
                onClick={props?.clearFieldsData}
              >
                <Typography component="div" fontSize={15}>
                  {props?.img ? <img src={props?.img} /> : null}
                </Typography>
              </Box>
            </Tooltip>
          </Box>

          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            fontSize={10}
          >
            {/* DATE */}
            {props?.fields?.startDate ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <span>
                      <img src={calendarIconVehicle} alt="" />
                    </span>
                    <span>
                      {props?.fields?.startDate
                        ? dateFormat(props?.fields?.startDate)
                        : ""}
                    </span>
                  </Box>
                  <Box>---------</Box>
                  <Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <span>
                        <img src={calendarIconVehicle} alt="" />
                      </span>
                      <span>
                        {props?.fields?.endDate
                          ? dateFormat(props?.fields?.endDate)
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>

                <Typography
                  variant={typographyConstants.SUBHEADING}
                  color="#053C3D"
                >
                  {props?.fields?.selectedPackage === "1"
                    ? `${props?.fields?.selectedPackage} Day`
                    : props?.fields?.selectedPackage === "Custom"
                    ? `${props?.fields?.selectedDaysCustom} Days`
                    : `${props?.fields?.selectedPackage} Days`}
                </Typography>
              </Box>
            ) : null}

            {/* PRICE */}
            {props?.costPM ? (
              <Box
                style={{ fontSize: "0.8rem", color: colors.primary_aqua_blue }}
              >
                {getFormattedINR(props?.costPM)}/month
              </Box>
            ) : (
              <></>
            )}

            {/* GENDER */}
            {props.genderSection ? <>{props.genderSection}</> : null}
          </Typography>

          {/* USER DETAILS */}
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ display: "flex", gap: "10px" }}
            fontSize={10}
          >
            <Box className={styles.detailsBlock}>
              {props.phone ? (
                <>
                  <img src={MobileIcon} alt="" /> {props?.phone ?? ""}
                </>
              ) : null}
            </Box>
            <Box className={styles.detailsBlock}>
              {props.email ? (
                <>
                  <img src={MailIcon} alt="" /> {props?.email ?? ""}
                </>
              ) : null}
            </Box>
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
}
