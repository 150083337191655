import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../../../themes/colors";
import en from "../../../../../locale/en.json";
import AddIcon from "@mui/icons-material/Add";
import {
  FilledButtonX,
  OutlinedButtonX,
  OutlinedIconButtonX,
  StartIconLabelButtonX,
} from "../../../../components/muiButtonsX";
import SelectX from "../../../../components/InputFields/InputSelectX";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SellIcon from "@mui/icons-material/Sell";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ADD_CHARGES_ACTION_TYPE,
  DisplayChargesNameTypes,
} from "../../../../constantsX/YOR_BookingDetails";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { getFormattedINR } from "../../../../utils/helper";
import { useState } from "react";
import ResponsiveDialog from "../../../../../components/responsiveDialog";

const AddChargesSideModalUI = ({
  chargesCategoriesData,
  selectedAddMoreCharges,
  setSelectedAddMoreCharges,
  handleAddMoreChargesClick,
  handleDeleteAllAddMoreCharges,
  handleDeleteAddedCharge,
  handleAddMoreCategoriesSelect,
  handleAddMoreSubCategoriesSelect,
  handleAddMoreChargesSaveAll,
  handleAddMoreChargesPrice,
  addMoreErrorObj,
}: any) => {
  const [showChargeDeleteConfirmation, setShowChargeDeleteConfirmation] =
    useState(false);
  const [showChargeResetConfirmation, setShowChargeResetConfirmation] =
    useState(false);
  const [chargeSelectedForDelete, setChargeSelectedForDelete] = useState(0);

  const handleDeleteChargeModalClose = () => {
    setShowChargeDeleteConfirmation(false);
  };
  const handleResetChargeModalClose = () => {
    setShowChargeResetConfirmation(false);
  };

  const categories = chargesCategoriesData?.categories;
  const subCategories = chargesCategoriesData?.subCategories;
  const categoryDetails = chargesCategoriesData?.categoryDetails;

  // category options
  const selectCategoryOptions = categories?.map((item: any, index: number) => {
    return {
      name: item,
      displayName:
        DisplayChargesNameTypes[item as keyof typeof DisplayChargesNameTypes],
    };
  });

  // sub category options
  const selectSubCategoriesOptions = subCategories?.map(
    (item: any, index: number) => {
      return {
        name: item?.id,
        displayName: item?.name,
        chargeCategory: item?.type,
      };
    }
  );

  // total charges
  let totalCharges = 0;
  for (let item in selectedAddMoreCharges) {
    totalCharges += selectedAddMoreCharges[item]["price"];
  }

  return (
    <>
      <Stack>
        <Box
          sx={{
            padding: "10px",
            borderBottom: "1px solid",
            background:
              "linear-gradient(270.37deg, rgba(43, 199, 201, 0.24) -1.53%, rgba(27, 196, 125, 0.24) 68.84%, rgba(24, 160, 251, 0.24) 101.83%)",
          }}
        >
          <Typography
            variant="caption"
            color={colors.text_secondary_gray}
            sx={{ fontWeight: "500", fontSize: "1rem" }}
          >
            {en.YORBookingDetails.AddChargesTitle}
          </Typography>
        </Box>
        <Stack padding="10px" gap="3vh">
          {/* Top action buttons */}
          <Stack direction="row" justifyContent="space-between">
            <StartIconLabelButtonX
              label="Add"
              muiIcon={<AddIcon />}
              variant="outlined"
              onClick={handleAddMoreChargesClick}
              width="unset"
              minWidth="unset"
              position="relative"
            />
            {selectedAddMoreCharges.length > 0 && (
              <StartIconLabelButtonX
                label="Reset all"
                muiIcon={<RestartAltIcon />}
                variant="outlined"
                color="error"
                onClick={() => {
                  setShowChargeResetConfirmation(true);
                  // handleDeleteAllAddMoreCharges
                }}
                width="unset"
                minWidth="unset"
                position="relative"
              />
            )}
          </Stack>

          <Stack gap="2vh">
            {selectedAddMoreCharges &&
              selectedAddMoreCharges?.length > 0 &&
              selectedAddMoreCharges?.map((item: any, index: number) => {
                let selectedCategory = item?.category?.name
                  ? item?.category?.name
                  : "";
                let selectedSubCategory = item?.subCategory?.name
                  ? item?.subCategory?.name
                  : "";
                let availableSubCategoriesOptions = selectedCategory
                  ? selectSubCategoriesOptions.filter(
                      (item: any, index: number) =>
                        item?.chargeCategory === selectedCategory
                    )
                  : selectSubCategoriesOptions;
                return (
                  <>
                    <Stack
                      sx={{
                        background: "#FFFFFF",
                        border: "1px dashed #00000029",
                        padding: "1vw",
                        gap: "1vh",
                      }}
                    >
                      {/* Error section */}
                      {Object.keys(addMoreErrorObj).length > 0 &&
                        addMoreErrorObj[item?.chargeIndex] && (
                          <Typography
                            variant="caption"
                            style={{
                              color: "indianred",
                              textAlign: "right",
                              fontStyle: "italic",
                            }}
                          >
                            {en.errorMessages.addMoreChargeYOR}
                          </Typography>
                        )}

                      {/* Charge Type */}
                      <SelectX
                        required
                        muiIcon={<AccountBalanceWalletIcon />} 
                        placeholder={en.YORBookingDetails.ChargesCategoryTitle}
                        value={item?.category?.displayName}
                        choice={
                          selectCategoryOptions?.length > 0
                            ? selectCategoryOptions
                            : []
                        }
                        defaultKey={"displayName"}
                        sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
                        handleChange={(category: any) => {
                          handleAddMoreCategoriesSelect(
                            category,
                            item?.chargeIndex
                          );
                        }}
                      />

                      {/* Sub Category */}
                      <SelectX
                        required
                        muiIcon={<SellIcon />}
                        placeholder={
                          en.YORBookingDetails.ChargesSubCategoryTitle
                        }
                        value={item?.subCategory?.displayName}
                        choice={availableSubCategoriesOptions}
                        defaultKey={"displayName"}
                        handleChange={(subCategory: any) => {
                          handleAddMoreSubCategoriesSelect(
                            subCategory,
                            item?.chargeIndex,
                            subCategory?.name
                          );
                        }}
                      />
                      {/* Price */}
                      {/* Add debouncing */}
                      <TextField
                        id="standard-number"
                        label={`Price`}
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                        disabled={!Boolean(item?.isEditable)}
                        value={item?.price ?? ""}
                        onChange={(event: any) => {
                          handleAddMoreChargesPrice(
                            event.target.value,
                            item?.chargeIndex
                          );
                        }}
                      />

                      {/* delete individual charge */}
                      {item?.chargeIndex > 0 && (
                        <OutlinedIconButtonX
                          onClick={() => {
                            setChargeSelectedForDelete(item?.chargeIndex);
                            setShowChargeDeleteConfirmation(true);
                          }}
                          label="Delete X"
                          className="logoutButtonX"
                          border="error"
                          hoverStyle="error"
                          color="error"
                          style={{
                            width: "fit-content",
                            fontSize: "0.7rem",
                            position: "unset",
                            padding: "0px",
                          }}
                        />
                      )}
                    </Stack>
                  </>
                );
              })}

            {/* Total charge */}
            {totalCharges > 0 && (
              <Stack
                direction="row"
                justifyContent={"space-around"}
                sx={{
                  background: "#FFFFFF",
                  border: "1px dashed #00000029",
                  padding: "1vw",
                  gap: "1vh",
                }}
              >
                <Typography variant="caption">Total Amount</Typography>
                <Typography variant="caption">
                  {getFormattedINR(totalCharges * 100) ?? 0}
                </Typography>
              </Stack>
            )}

            {/* Submit Buttons */}
            <Stack direction="row" justifyContent={"space-around"}>
              <StartIconLabelButtonX
                label="Save Changes"
                variant="outlined"
                onClick={() =>
                  handleAddMoreChargesSaveAll(ADD_CHARGES_ACTION_TYPE.SAVE)
                }
                width="unset"
                minWidth="unset"
                position="relative"
              />
              <FilledButtonX
                label="Initiate Payment"
                variant="contained"
                onClick={() =>
                  handleAddMoreChargesSaveAll(
                    ADD_CHARGES_ACTION_TYPE.SAVE_AND_PAY
                  )
                }
                sx={{ color: "#fff" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* Delete single charge confirmation modal */}
      {showChargeDeleteConfirmation && (
        <ResponsiveDialog
          fullScreen={false}
          title={en.alertMessages.deleteItem}
          // titleColor="text-warning"
          maxWidth="md"
          handleClose={handleDeleteChargeModalClose}
          open={showChargeDeleteConfirmation}
          fullWidth="true"
          titleFontSize="1rem"
        >
          <Stack>
            <Stack gap="2vh">
              <Typography></Typography>
              <Stack direction="row" justifyContent="flex-end" gap="1vw">
                <OutlinedButtonX
                  label={"No"}
                  onClick={handleDeleteChargeModalClose}
                  color="secondary"
                />
                <OutlinedButtonX
                  label={"Yes"}
                  onClick={() => {
                    setShowChargeDeleteConfirmation(false);
                    if (chargeSelectedForDelete > 0) {
                      handleDeleteAddedCharge(chargeSelectedForDelete);
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </ResponsiveDialog>
      )}

      {/* Reset all charge confirmation modal */}
      {showChargeResetConfirmation && (
        <ResponsiveDialog
          fullScreen={false}
          title={en.alertMessages.resetAllItem}
          // titleColor="text-warning"
          maxWidth="md"
          handleClose={handleResetChargeModalClose}
          open={showChargeResetConfirmation}
          fullWidth="true"
          titleFontSize="1rem"
        >
          <Stack>
            <Stack gap="2vh">
              <Typography></Typography>
              <Stack direction="row" justifyContent="flex-end" gap="1vw">
                <OutlinedButtonX
                  label={"No"}
                  onClick={handleResetChargeModalClose}
                  color="secondary"
                />
                <OutlinedButtonX
                  label={"Yes"}
                  onClick={() => {
                    setShowChargeResetConfirmation(false);
                    handleDeleteAllAddMoreCharges();
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </ResponsiveDialog>
      )}
    </>
  );
};

export default AddChargesSideModalUI;
