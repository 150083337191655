import {
  Chip,
  Stack,
  Typography,
  createTheme,
  Box,
  ClickAwayListener,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
  Skeleton,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import en from "../../../locale/en.json";
import SideDrawer from "../../components/MultiStepForm/SideDrawer";
import SubscriptionItem from "../../components/MultiStepForm/subscriptionItem";
import { lastSeenIcon } from "../../constantsX/exportImagesX";
import { colors } from "../../themes/colors";
import { dateFormat, getFormattedINR } from "../../utils/helper";
import EnhancedTable from "../BookingList/ListTable";
import styles from "./bookingDetails.module.css";
import {Verified as VerifiedIcon} from "@mui/icons-material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  DisplayChargeTypes,
  DisplayChargeTypesKey,
  DisplayChargesNameTypes,
  DisplayPaymentStatusColorCode,
  DisplayPaymentStatusModes,
  OrderPaymentType,
  PaymentStatusModes,
} from "../../constantsX/YOR_BookingDetails";
import PaymentDetailsSideModalUI from "./sections/SideDrawerModalUI/PaymentDetailsSideModalUI";
import DataTable from "../../components/Template/DataTable/DataTable";
import InfoIcon from "@mui/icons-material/Info";
import ContentTriggerTooltip from "../../components/Molecule/ContentTriggerTooltip";

const PaymentDetails = ({
  paymentHistorySection,
  paymentHistoryLoader,
  generatePaymentLinkQRHandler,
  toggleRightDrawer,
  togglePaymentDrawer,
  showPaymentSideModal,
  setShowPaymentSideModal,
  paymentLinkQRData,
  paymentLinkQRLoader,
  getChargeBifurcationHandler,
  chargesBifurcationLoader,
  chargesBifurcation,
  DisplayPaymentHistoryModes,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const theme = createTheme();
  const initialState = {
    page: "1",
    pageSize: {
      name: "10 per page",
      value: "10",
    },
    search: "",
    sorting: "",
    status: {
      name: "All",
      value: "ALL",
    },
  };
  const [field, setField] = React.useState(initialState);
  // const [tootltipOpen, setTootltipOpen] = React.useState(false);
  const [tootltipOpen, setTootltipOpen] = React.useState<any>([]);

  const handleChargesTooltipClose = () => {
    setTootltipOpen([]);
    // setTootltipOpen(false);
  };

  const handleChargesTooltipOpen = (recordId: any) => {
    let tooltipArray = [recordId];
    setTootltipOpen(tooltipArray);
    // setTootltipOpen(true);
  };
  const ToottipTitle = ({ chargesBifurcation = [], recordId }: any) => {
    return (
      <>
        <Stack key={recordId} gap="1vh">
          <Box>
            <Typography variant="caption">Charges:</Typography>
          </Box>
          <Divider sx={{ background: "#09cacc", marginBottom: "1vh" }} />
          <Box>
            {chargesBifurcation &&
              chargesBifurcation?.length === 1 &&
              chargesBifurcation[0]?.details?.map((charge: any, index: any) => {
                return (
                  <Stack
                    direction="row"
                    gap="4px"
                    key={index}
                    justifyContent="space-between"
                  >
                    {charge?.installmentNumber && (
                      <Typography variant="caption">
                        Installment No - {charge?.installmentNumber}
                      </Typography>
                    )}
                    {charge?.chargeCategory && (
                      <>
                        <Stack
                          className="ChargesWrapper"
                          direction="row"
                          gap="3vw"
                          justifyContent="space-between"
                          width="100%"
                        >
                          {/* Title */}
                          <Stack className="ChargesTitle" gap="1vh">
                            <Typography variant="caption">
                              {
                                DisplayChargesNameTypes[
                                  charge?.chargeCategory as keyof typeof DisplayChargesNameTypes
                                ]
                              }
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                fontStyle: "italic",
                                fontSize: "0.6rem",
                                color: "#09cacc",
                              }}
                            >
                              {charge?.subCategory}
                            </Typography>
                          </Stack>
                          {/* Price */}
                          <Stack className="ChargesPrice">
                            <Typography variant="caption">
                              {getFormattedINR(charge?.amount)}
                            </Typography>
                          </Stack>
                        </Stack>
                        {index !== charge.length - 1 && <Divider />}
                      </>
                    )}
                  </Stack>
                );
              })}
            {chargesBifurcation &&
              chargesBifurcation?.length > 1 &&
              chargesBifurcation?.map((item: any, index: any) => {
                return (
                  item?.amount > 0 && (
                    <Stack
                      direction="row"
                      gap="4vw"
                      key={index}
                      justifyContent="space-between"
                    >
                      <Typography variant="caption">
                        {
                          DisplayChargeTypes[
                            item?.type as keyof typeof DisplayChargeTypes
                          ]
                        }
                      </Typography>
                      <Typography variant="caption">
                        {getFormattedINR(item?.amount)}
                      </Typography>
                    </Stack>
                  )
                );
              })}
          </Box>
        </Stack>
      </>
    );
  };
  const TriggerTooltipCharges = ({
    recordId,
    chargesBifurcation,
    handleTooltipOpen,
  }: any) => {
    const ChargesTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: "#f5f5f9",
        background: "transparent",
        backdropFilter: "blur(20px)",
        color: "rgba(0, 0, 0, 0.87)",
        // maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
    }));
    return (
      <ClickAwayListener onClickAway={handleChargesTooltipClose}>
        <div>
          <ChargesTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleChargesTooltipClose}
            open={tootltipOpen.includes(recordId)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            // title={ToottipTitle()}
            title={
              chargesBifurcationLoader ? (
                <Skeleton />
              ) : (
                <ToottipTitle
                  chargesBifurcation={chargesBifurcation}
                  recordId={recordId}
                />
              )
            }
            sx={{ zIndex: 0 }}
          >
            <InfoIcon
              onClick={handleTooltipOpen}
              sx={{ color: "#09cacc", fontSize: "medium" }}
            />
          </ChargesTooltip>
        </div>
      </ClickAwayListener>
    );
  };

  theme.typography.caption = {
    fontSize: "0.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: "2.4rem",
    // },
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const PaymentDetailsPopUpUI = () => {
    return (
      <Box>
        <Typography
          variant="caption"
          color={colors.text_secondary_gray}
          fontSize="12px"
          fontWeight="500"
          marginBottom="5px"
        >
          {en.BookingDetailsFreedoX.paymentDetails}:
        </Typography>
        <Box
          sx={{
            borderWidth: "1px solid",
            border: colors.text_secondary_gray,
            borderRadius: "12px",
            padding: "8px 0px",
            backgroundColor: "#fff",
          }}
        >
          <SubscriptionItem
            dashedBorder={false}
            title={"Traffic Voilation 1"}
            amount={getFormattedINR(30000)}
            fontSize="10px"
            showBorder={true}
          />
          <SubscriptionItem
            dashedBorder={false}
            title={"Traffic Voilation 2"}
            amount={getFormattedINR(100000)}
            fontSize="10px"
            showBorder={true}
          />
          <SubscriptionItem
            dashedBorder={false}
            title={"Loss of helmet "}
            amount={getFormattedINR(100000)}
            fontSize="10px"
            showBorder={true}
            description={`${`(Waived Off)`}`}
          />
          {/* Payable amount */}
          <Box sx={{ padding: "10px" }}>
            <Box className={styles.paymentDetailsPopUp}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontFamily: "Euclid Circular B",
                }}
              >
                {en.manualBookingFreedoX.PayableAmount}
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                >
                  {getFormattedINR(50000)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const paymentLinkQRComponent = (orderId: string) => {
    return (
      <>
        <Stack direction="row" gap="1vw">
          <LinkIcon
            sx={{ color: "#09CACC" }}
            onClick={(e) => {
              // toggleRightDrawer(),
              generatePaymentLinkQRHandler(
                OrderPaymentType.PAYMENT_LINK,
                orderId
              );
            }}
          />
          <QrCodeIcon
            sx={{ color: "#09CACC" }}
            onClick={(e) => {
              // toggleRightDrawer(),
              generatePaymentLinkQRHandler(OrderPaymentType.QR_CODE, orderId);
            }}
          />
        </Stack>
      </>
    );
  };

  // TABLE DATA BELOW
  const paymentRows = paymentHistorySection?.payments?.map((payment: any) => {
    return {
      IgnoreExtraData: payment,
      createdDate: payment?.createdAt,
      dateOfPayment: payment?.paymentDate,
      payableAmount: payment?.totalAmount,
      paymentMode:
        payment?.status === PaymentStatusModes.PENDING
          ? paymentLinkQRComponent(payment?.orderId)
          : (payment?.status === PaymentStatusModes.SUCCESS && payment?.paymentMode)
          ? DisplayPaymentHistoryModes[
              payment?.paymentMode as keyof typeof DisplayPaymentHistoryModes
            ]
          : "N/A",
      status: payment?.status,
    };
  });

  const getPaymentStatus = (status: string) => {
    let displayStatus = "";
    displayStatus =
      DisplayPaymentStatusModes[
        status as keyof typeof DisplayPaymentStatusModes
      ];
    let statusBlock = (
      <Typography
        sx={{
          // color: "red",
          color: `${
            DisplayPaymentStatusColorCode[
              status as keyof typeof DisplayPaymentStatusColorCode
            ]
          }`,
        }}
      >
        {displayStatus}
      </Typography>
    );

    return statusBlock;
  };

  const headCells = [
    {
      id: "createdDate",
      numeric: false,
      disablePadding: false,
      label: "Created Date",
      format: (createdDate: any) => {
        return dateFormat(createdDate);
      },
    },
    {
      id: "dateOfPayment",
      numeric: false,
      disablePadding: false,
      label: "Date Of Payment",
      format: (dateOfPayment: any) => {
        return dateOfPayment ? dateFormat(dateOfPayment) : "N/A";
      },
    },
    {
      id: "payableAmount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
      render: (payment: any) => {
        const amountToBePaid = getFormattedINR(payment?.payableAmount);
        return (
          <Stack direction="row" alignItems="center">
            <Typography>{amountToBePaid}</Typography>
            <TriggerTooltipCharges
              recordId={payment?.IgnoreExtraData?.id}
              chargesBifurcation={chargesBifurcation}
              handleTooltipOpen={(e: any) => {
                handleChargesTooltipOpen(payment?.IgnoreExtraData?.id);
                getChargeBifurcationHandler(e, payment?.IgnoreExtraData?.id);
              }}
            />
          </Stack>
        );
      },
    },
    {
      id: "paymentMode",
      numeric: false,
      disablePadding: false,
      label: "Payment Mode",
      /* format: (paymentMode: any) => {
        if (!paymentMode) paymentLinkQRComponent();
        return paymentMode;
      }, */
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      format: (status: string) => getPaymentStatus(status),
      // render: (status: string) => getPaymentStatus(status),
    },
  ];
  const initiateMandateHandler = () => {
    alert("generate payment link");
  };
  const handleFakeDel = () => {};

  const handlePaymentPageChange = (event: any) => {
  };

  // useEffect(() => {
  //   setField((prev) => ( {...prev, prev.page: paymentHistorySection?.pageData?.page, prev.page: paymentHistorySection?.pageData?.page }))
  // },[paymentHistorySection?.pageData]);
  return (
    <>
      {/* PAYMENT DRAWER */}
      <Fade>
        <SideDrawer
          open={showPaymentSideModal}
          setOpen={setShowPaymentSideModal}
          toggleDrawer={togglePaymentDrawer}
          renderUI={
            <PaymentDetailsSideModalUI
              paymentLinkQRData={paymentLinkQRData}
              paymentLinkQRLoader={paymentLinkQRLoader}
            />
          }
        ></SideDrawer>
      </Fade>

      {/* CONTENT */}
      {paymentHistoryLoader ? (
        <Skeleton />
      ) : (
        <Stack
          className="TransactionDetails_Wrapper"
          gap="10px"
          padding={"24px 24px 0px 24px"}
        >
          <Stack direction="row" gap="5px" alignItems="center">
            <Typography variant="caption">UPI Mandate :</Typography>
            {paymentHistorySection?.isMandateSuccess ? (
              <Chip
                label="Successful"
                color="success"
                icon={
                  <VerifiedIcon color="success" sx={{ fontSize: "1.2rem" }} />
                }
                sx={{ fontSize: "small" }}
              />
            ) : (
              <Chip
                label={paymentHistorySection?.displayMandateStatus}
                sx={{
                  background: paymentHistorySection?.mandateStatus
                    ? DisplayPaymentStatusColorCode[
                        paymentHistorySection?.mandateStatus as keyof typeof DisplayPaymentStatusColorCode
                      ]
                    : colors.text_black,
                  color: colors.white,
                }}
              />
            )}
          </Stack>

          {/* Payment History */}
          <Stack direction="row" gap="5px">
            <img src={lastSeenIcon} alt="Payment history icon" />
            <Typography variant="caption">Payment History:</Typography>
          </Stack>

          {/* ORDER / PAYMENT HISTORY TABLE */}
          <EnhancedTable
            columns={headCells}
            rows={paymentRows}
            count={paymentHistorySection?.pageData?.total}
            pages={field?.page}
            fields={field}
            pageSize={100}
            setFields={setField}
            disableOnclick={true}
            pageChangeHandler={handlePaymentPageChange}
            disablePagination={true}
          />
        </Stack>
      )}
    </>
  );
};

export { PaymentDetails };
