import { Box, Popover, Typography } from "@mui/material";
import React from "react";

interface Props {
  popupContent: JSX.Element;
  hoverContent: JSX.Element;
  open?: boolean;
  showPopOver?: boolean;
}

const CustomHoverPopover = (props: Props) => {
  const {
    hoverContent = <></>,
    popupContent = <></>,
    showPopOver = true,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {hoverContent}
      </Box>
      {showPopOver ? (
        <Popover
          id="mouse-over-popover"
          style={{
            pointerEvents: "none",
            borderRadius: "unset",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
         /*  sx={{
           background: "#fff" 
          }} */
        >
          {popupContent}
        </Popover>
      ) : null}
    </div>
  );
};

export default CustomHoverPopover;
