import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import React from "react";

const InputDatePicker = (props: any) => {
  const { textFieldStyle = {} } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        ref={props?.ref}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={props?.placeholder ? props.placeholder : ""}
        value={props?.value}
        onChange={props?.onChange}
        inputFormat={props?.inputFormat ?? "DD/MM/YYYY"}
        views={props?.views}
        renderInput={(params: any) => (
          <TextField
            {...params}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-input": {
                padding: props.padding ?? "14px 14px",
              },
              width: props?.width ?? "100%",
              ...textFieldStyle,
            }}
            onFocus={(e) => (e.target.readOnly = true)}
          />
        )}
        defaultCalendarMonth={props?.defaultValue}
        className="custom-react-datepicker"
        shouldDisableDate={props?.shouldDisableDate}
        maxDate={props?.maxDate}
        minDate={props?.minDate}
        readOnly={props?.readOnly}
        disabled={props?.disabled}
        PopperProps={{
          placement: props?.placement ? props?.placement : "top-start",
        }}
      />
    </LocalizationProvider>
  );
};

export default InputDatePicker;
