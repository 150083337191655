import { Modal, Box, Typography, Stack } from "@mui/material";
import { colors } from "../../themes/colors";
import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import yoren from "../../../locale/yor-en.json";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
};
export default function SendPaymentLinkModal(openModal: any, handleClose: any) {
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: colors.primary_dark_blue,
            borderRadius: "30px 30px 0px 0px",
            p: 4,
            color: "white",
          }}
        >
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Gif
          </Typography> */}
          <Typography sx={{ mt: 2, color: "#FFFFFFCC", fontSize: "14px" }}>
           {yoren.manualBookingFreedoX.PaymentLinkSent}
          </Typography>
          <Typography sx={{ mt: 0, color: "#FFFFFF99", fontSize: "10px" }}>
            The payment link has been sent to the user.
          </Typography>
        </Stack>
        <Stack alignItems="center" padding={5}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CircularWithValueLabel />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}  fontSize={14}>
            Redirecting you to booking Page
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
}


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={14}
        >{`${Math.round(props.value)}`}</Typography>
      </Box>
    </Box>
  );
}

function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(5);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 5));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
