import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Button2 } from "../../components/button";
import { Heading } from "../../components/typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import { actions } from "../../redux";
import { toastr } from "react-redux-toastr";
import { useEffect, useState } from "react";
import MultiSelectPicker from "../../components/multiSelectPicker";
import { DatePicker2 } from "../../components/datePicker";
import { ButtonActions } from "../../constants/userActions";
import { BasicSelect } from "../../components/dropDown";

interface DefaultProps {
  addRole?: boolean;
  editRole?: boolean;
  viewRole?: boolean;
}

function AddEditSlotPause(props: DefaultProps) {
  const dispatch = useDispatch();
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { slotsData, allCitiesData, branchesList, branches } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const { slotPauseById, navigateBack } = useSelector(
    (state: RootState) => state.slotPauseReducer
  );
  const navigate = useNavigate();
  let loc: any = useLocation();
  const {
    categoryManagement,
    global,
    vehicleManagement,
    slotPause,
    errorMessages,
  } = locale;
  const isMobile = useMediaQuery("(max-width:767px)");
  const [checked, setChecked] = useState<boolean[]>([false, false, false]);
  const [homeTimeSlots, setHomeTimeSlots] = useState<any>([]);
  const [pickupTimeSlots, setPickupTimeSlots] = useState<any>([]);
  const [selectedOptionCities, setSelectedOptionCities] = useState([]);
  const [selectedOptionBranches, setSelectedOptionBranches] = useState([]);
  const [selectedHomeTimeSlots, setSelectedHomeTimeSlots] = useState<any>([]);
  const [selectedPickupTimeSlots, setSelectedPickupTimeSlots] = useState<any>(
    []
  );
  const [selectedBranch, setSelectedBranch] = useState({
    branch_name: slotPause.SelectBranch,
    branch_id: 0,
    location_id: 0,
    city_id: 0,
  });
  const [city, setCity] = useState({ city_name: global.SelectCity, id: 0 });

  const [date, setDate] = useState<any>(new Date());
  const id = useParams();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    navigateBack && navigate(-1);
  }, [navigateBack]);

  useEffect(() => {
    setChecked([false, false, false]);
    dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    );

    dispatch(
      actions.getAllBranches({
        version2: true,
      })
    );

    dispatch(
      actions.getAllBranches({
        cityId: city.id,
      })
    );

    if (!props.addRole) {
      setTimeout(() => {
        dispatch(
          actions.getSlotPauseById({
            id: id?.id,
            branch_id: loc?.state?.id,
            option_id: loc?.state?.option_id
          })
        );
      }, 200);
    }
  }, [refresh]);



  useEffect(() => {
    if (!props.addRole) {
      dispatch(
        actions.getAllBranches({
          cityId: loc?.state?.address?.city_id,
        })
      );
    }

  }, [])

  useEffect(() => {
    if (slotsData) {
      const newFile = slotsData.map((file: any) => {
        return { ...file, checked: false };
      });
      setHomeTimeSlots(newFile);
      setPickupTimeSlots(newFile);
    }
  }, [slotsData]);

  const setAllCities = () => {
    let arr: any = [];
    if (allCitiesData?.length > 0) {
      allCitiesData.map((d: any) => {
        arr.push({
          label: d.city_name,
          value: d.id,
        });
      });
    }
    setSelectedOptionCities(arr);
  };

  useEffect(() => {
    if (selectedBranch?.branch_id) {
      dispatch(
        actions.timeSlots({
          branch_id: selectedBranch?.branch_id,
        })
      );
    }
  }, [selectedBranch]);

  useEffect(() => {
    let res: any = [];
    if (branches?.data?.length) {
      res = branches?.data?.filter((branch: any) => {
        return branch?.id === loc?.state?.id;
      });
    }


    if (!props.addRole && loc.state)
      setSelectedBranch({
        ...selectedBranch,
        city_id: res[0]?.address?.city_id,
        branch_name: loc?.state?.branch_name,
        branch_id: loc?.state?.id,
        location_id: res[0]?.preferred_location_id,
      });
    // setSelectedBranch({...selectedBranch,branch_name:e?.branch_name,branch_id:e?.id,location_id:e?.location?.id,city_id:res?.address?.city_id})
    // setSelectedBranch({...selectedBranch,city_id:res?.address?.city_id});
  }, [branches]);



  const setAllBranches = () => {
    let arr: any = [];
    if (branchesList?.length > 0) {
      branchesList.map((d: any) => {
        arr.push({
          label: d?.branch_name,
          value: d?.id,
        });
      });
    }
    setSelectedOptionBranches(arr);
  };

  const setAllPickupSlots = () => {
    let timeslots: any = [];
    if (slotsData?.length > 0) {
      slotsData?.forEach((d: any) => {
        timeslots.push({
          value: d?.slot_id,
          label: d?.timeSlot?.from_time + " - " + d?.timeSlot?.to_time,
        });
      });
    }
    setSelectedPickupTimeSlots(timeslots);
  };

  const setTimeSlots = (x: any, data: any) => {
    switch (x) {
      case "HomeTimeSlots":
        let slots: any = [];
        if (data?.length) {
          data?.map((d: any) => {
            slots?.push({
              value: d?.slot_id,
              label: d?.timeSlot?.fromTime + " - " + d?.timeSlot?.toTime,
            });
          });
        }
        setSelectedHomeTimeSlots(slots);
        break;
      case "PickupSlots":
        let slotsPickup: any = [];
        if (data?.length) {
          data?.map((d: any) => {
            if (d?.slot_id)
              slotsPickup?.push({
                value: d?.slot_id,
                label: d?.timeSlot?.fromTime + " - " + d?.timeSlot?.toTime,
              });
          });
        }
        setSelectedPickupTimeSlots(slotsPickup);
        break;
    }
  };

  const setAllHomeTimeSlots = () => {
    let timeslots: any = [];
    if (slotsData?.length > 0) {
      slotsData?.forEach((d: any) => {
        timeslots.push({
          value: d?.slot_id,
          label: d?.timeSlot?.from_time + " - " + d?.timeSlot?.to_time,
        });
      });
    }

    setSelectedHomeTimeSlots(timeslots);
  };
  useEffect(() => {
    if (slotPauseById) {
      setDate(slotPauseById[0]?.date_to_pause);
      var options: any = Array.from(slotPauseById);
      let d = [...checked];
      for (let i = 0; i < options?.length; i++) {
        if (options[i]?.option_id == 1) {
          d[0] = true;
        }
        if (options[i]?.option_id == 2) {
          d[1] = true;
          setTimeSlots("HomeTimeSlots", options[i]?.slot_pause_timeslots);
        }
        if (options[i]?.option_id == 3) {
          d[2] = true;
          setTimeSlots("PickupSlots", options[i]?.slot_pause_timeslots);
        }
      }
      setChecked(d);
    }
  }, [slotPauseById]);

  const handleChangePicker = (tag: any, value: any) => {
    switch (tag) {
      case "city":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            setAllCities();
          } else {
            setSelectedOptionCities(value);
          }
        } else {
          setSelectedOptionCities(value);
        }
        break;
      case "branch":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            setAllBranches();
          } else {
            setSelectedOptionBranches(value);
          }
        } else {
          setSelectedOptionBranches(value);
        }
        break;
      case "timeslots":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            setAllHomeTimeSlots();
          } else {
            setSelectedHomeTimeSlots(value);
          }
        } else {
          setSelectedHomeTimeSlots(value);
        }
        break;
      case "pickuptimeslots":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            setAllPickupSlots();
          } else {
            setSelectedPickupTimeSlots(value);
          }
        } else {
          setSelectedPickupTimeSlots(value);
        }
        break;

      default:
    }
  };

  const renderMultiPickerOptions = (tag: any) => {
    switch (tag) {
      case "TimeSlotsHome":
        let timeslots;
        timeslots = slotsData?.map((d: any) => ({
          value: d?.slot_id,
          label: d?.timeSlot?.from_time + " - " + d?.timeSlot?.to_time,
        }));
        return timeslots;
      case "City":
        let cities;
        cities = allCitiesData?.map((d: any) => ({
          value: d.id,
          label: d.city_name,
        }));
        cities.unshift({
          value: 0,
          label: global.All,
        });

        return cities;
      case "Branch":
        let branches;
        branches = branchesList?.map((d: any) => ({
          value: d?.id,
          label: d?.branch_name,
        }));
        branches.unshift({
          value: 0,
          label: global.All,
        });
        return branches;
      default:
        break;
    }
  };

  const handleChange = (i: any) => {
    let d = [...checked];
    d[i] = !d[i];
    setChecked(d);
  };

  const handleBranch = (e: any) => {
    setSelectedBranch({
      ...selectedBranch,
      branch_name: e?.branch_name,
      branch_id: e?.id,
      location_id: e?.location?.id,
      city_id: e?.address?.city?.id,
    });
  };

  const handleCity = (city: any) => {
    setCity(city);
    setSelectedBranch({
      branch_name: slotPause.SelectBranch,
      branch_id: 0,
      location_id: 0,
      city_id: 0,
    });
    if (city.id) {
      dispatch(
        actions.getAllBranches({
          cityId: city.id,
        })
      );
    }
  };

  const onSubmitSlot = () => {
    const optionData = [];

    if (!checked.some(Boolean)) {
      toastr.error("Error", errorMessages.OnePause);
      return;
    }

    if (checked[0]) {
      optionData.push({ option_id: 1, slot_ids: [], fullDayPause: true });
    } else {
      if (checked[1]) {
        const homeOption: any = {
          option_id: 2,
          slot_ids: selectedHomeTimeSlots.map(({ value }: any) => value),
          fullDayPause: false
        };
        homeOption.fullDayPause = false;
        optionData.push(homeOption);
      }

      if (checked[2]) {
        const pickupOption: any = {
          option_id: 3,
          slot_ids: selectedPickupTimeSlots.map(({ value }: any) => value),
          fullDayPause: false
        };
        optionData.push(pickupOption);
      }
    }

    const obj = {
      branch_id: selectedBranch?.branch_id,
      date_to_pause: date,
      option_data: optionData,
      city_id: selectedBranch?.city_id,
      location_id: selectedBranch?.location_id,
      source_id: 3,
    };



    if (props.editRole) {
      dispatch(actions.editSlotPause(obj));
    } else {
      dispatch(actions.addSlotPause(obj));
    }
  };

  const allNotSelected = !checked[0] && !checked[1] && !checked[2];

  return (
    <div className="container pd-20">
      <Heading
        title={
          props.viewRole
            ? slotPause.ViewSlotPause
            : props.editRole
              ? slotPause.EditSlotPause
              : slotPause.AddSlotPause
        }
        actionButtons={props.viewRole ? [ButtonActions.refresh] : []}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          }
        }}
      />

      <form>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2%",
            bgcolor: "background.paper",
            borderRadius: 1,
            // justifyContent: "space-between",
          }}
        >
          <Grid container direction="row">
            {props.addRole ? (
              <BasicSelect
                selected2={city["city_name"]}
                defaultKey={"city_name"}
                handleChange={handleCity}
                margin="5px"
                padding="1px"
                width={320}
                // allOption={{ city_name: "All", id: 0 }}
                heading={global.SelectCity}
                data={allCitiesData}
              />
            ) : null}
            <BasicSelect
              selected2={selectedBranch.branch_name}
              defaultKey={"branch_name"}
              handleChange={(e: any) => handleBranch(e)}
              margin="5px"
              readOnly={props.viewRole || props.editRole}
              padding="1px"
              width={320}
              placeholder={slotPause.SelectBranch}
              heading={slotPause.SelectBranch}
              data={branches?.data ?? []}
            />
          </Grid>
          <Grid style={{ marginTop: 15 }}>
            <DatePicker2
              value={date}
              // width={350}
              minDate={props.addRole ? new Date() : null}
              onChange={(val: any) => setDate(val)}
              readOnly={props.viewRole || props.editRole ? true : false}
              // setDateValueFunc={(val: any) => setDate(val)}
              margin="5px"
            />
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column">
          <FormGroup aria-label="position">
            <Grid
              style={{
                marginTop: "2%",
                border: "1px solid black",
                width: isMobile ? "320px" : "700px",
                padding: "4px",
              }}
            >
              <FormControlLabel
                value="Full Day Pause"
                disabled={props.viewRole === true ? true : false}
                control={<Checkbox checked={checked[0]} />}
                onChange={() => handleChange(0)}
                label={slotPause.FullDayPause}
                labelPlacement="end"
              />
            </Grid>
            {checked[0] === false ? (
              <>
                <Grid
                  style={{
                    marginTop: "2%",
                    border: "1px solid black",
                    width: isMobile ? "320px" : "700px",
                    padding: "4px",
                  }}
                >
                  <FormControlLabel
                    value="Home Delivery Paus"
                    control={<Checkbox checked={checked[1]} />}
                    disabled={props.viewRole === true ? true : false}
                    onChange={() => handleChange(1)}
                    label={slotPause.HomeDeliveryPause}
                    labelPlacement="end"
                  />
                  {checked[1] === true ? (
                    <Grid style={{}}>
                      <MultiSelectPicker
                        value={
                          props.viewRole
                            ? selectedHomeTimeSlots
                            : selectedHomeTimeSlots
                        }
                        isDisabled={props.viewRole ? true : false}
                        width="40%"
                        onChange={(event: any) =>
                          handleChangePicker("timeslots", event)
                        }
                        options={renderMultiPickerOptions("TimeSlotsHome")}
                        isMulti={true}
                        title={global.TimeSlots}
                        placeholder={global.TimeSlots}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    marginTop: "2%",
                    border: "1px solid black",
                    width: isMobile ? "320px" : "700px",
                    padding: "4px",
                  }}
                >
                  <FormControlLabel
                    value="Pick-up Pause"
                    disabled={props.viewRole === true ? true : false}
                    control={<Checkbox checked={checked[2]} />}
                    onChange={() => handleChange(2)}
                    label={slotPause.PickupPause}
                    labelPlacement="end"
                  />
                  {checked[2] === true ? (
                    <Grid style={{}}>
                      <MultiSelectPicker
                        value={
                          props.viewRole
                            ? selectedPickupTimeSlots
                            : selectedPickupTimeSlots
                        }
                        isDisabled={props.viewRole ? true : false}
                        width="40%"
                        onChange={(event: any) =>
                          handleChangePicker("pickuptimeslots", event)
                        }
                        options={renderMultiPickerOptions("TimeSlotsHome")}
                        isMulti={true}
                        title={global.TimeSlots}
                        placeholder={global.TimeSlots}
                      />
                      {/* {pickupTimeSlots.map((time: any, index: any) => (
                        <FormControlLabel
                          key={index}
                          value="end"
                          disabled={props.viewRole === true ? true : false}
                          control={
                            <Checkbox
                              color="primary"
                              checked={time.checked}
                              onClick={() => handlePickupChecked(time, index)}
                            />
                          }
                          label={time.time}
                          labelPlacement="end"
                        />
                      ))} */}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid></Grid>
              </>
            ) : null}
          </FormGroup>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
          <Button2
            onClick={() => navigate(-1)}
            title={global.Back}
            back={true}
            style={{ marginLeft: "15px" }}
          />
          {props.editRole || props.addRole ? (
            <Button2
              onClick={onSubmitSlot}
              title={global.SaveSlot}
              style={{ marginLeft: "15px" }}
              disabled={
                (props.addRole ? !city.id : false) ||
                !selectedBranch.branch_id ||
                allNotSelected
              }
            />
          ) : null}
        </Box>
      </form>
    </div>
  );
}
export default AddEditSlotPause;
