import { YOR_BOOKING_DETAILS_ACTIONS } from "../../actions/actionTypes";
import { bookingDetailsInterfaceYOR } from "../actionInterfaces";
interface YORBookingDetailsInterface {
  alerts: YORBookingEntries;
  customerDetailsSection: YORBookingEntries;
  bookingDetailsSection: YORBookingEntries;
  vehicleDetailsSection: YORBookingEntries;
  planDetailsSection: YORBookingEntries;
  bookingDetailsloader: boolean;
  paymentHistorySection: YORBookingEntries;
  paymentHistoryLoader: boolean;
  bookingStatus: string;
  availableVehicles: [];
  pendingPaymentStatus: any;
  isRideStarted: boolean;
  startRideLoader: boolean;
  paymentModesLoader: boolean;
  paymentModes: [];
  orderData: any;
  orderDataloader: boolean;
  paymentLinkQRData: YORBookingEntries;
  paymentLinkQRLoader: boolean;
  chargesBifurcationLoader: boolean;
  chargesBifurcation: [];
  isChargesWaivedOff: boolean;
  waivedOffLoader: boolean;
  saveAdditionalChargesLoader: boolean;
  savedCharges: [];
}
interface YORBookingEntries {
  [key: string]: any;
}

const initialState: YORBookingDetailsInterface = {
  alerts: {},
  customerDetailsSection: {},
  bookingDetailsSection: {},
  vehicleDetailsSection: {},
  planDetailsSection: {},
  bookingDetailsloader: false,
  paymentHistorySection: {
    isMandateSuccess: false,
    payments: [],
    pageData: {},
  },
  paymentHistoryLoader: false,
  bookingStatus: "",
  pendingPaymentStatus: {},
  availableVehicles: [],
  startRideLoader: false,
  isRideStarted: false,
  paymentModesLoader: false,
  paymentModes: [],
  orderData: {},
  orderDataloader: false,
  paymentLinkQRData: {},
  paymentLinkQRLoader: false,
  chargesBifurcationLoader: false,
  chargesBifurcation: [],
  isChargesWaivedOff: false,
  waivedOffLoader: false,
  saveAdditionalChargesLoader: false,
  savedCharges: [],
};

const YOR_bookingDetailsReducer = (
  state = initialState,
  action: bookingDetailsInterfaceYOR
) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_DETAILS_X_REQUESTED:
      return {
        ...state,
        bookingDetailsloader: true,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_DETAILS_X_SUCCESS:
      return {
        ...state,
        bookingDetailsloader: false,
        alerts: payload?.alerts ? payload?.alerts : state.alerts,
        customerDetailsSection: payload?.customerDetails
          ? payload?.customerDetails
          : state.customerDetailsSection,
        bookingDetailsSection: payload?.bookingDetails
          ? payload?.bookingDetails
          : state.bookingDetailsSection,
        vehicleDetailsSection: payload?.vehicleDetails
          ? payload?.vehicleDetails
          : state.vehicleDetailsSection,
        planDetailsSection: payload?.planDetails
          ? payload?.planDetails
          : state.planDetailsSection,
        bookingStatus: payload?.bookingDetails?.status
          ? payload?.bookingDetails?.status
          : state.bookingStatus,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_DETAILS_X_FAILED:
      return {
        ...state,
        bookingDetailsloader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_HISTORIES_X_REQUESTED:
      return {
        ...state,
        paymentHistoryLoader: true,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_HISTORIES_X_SUCCESS:
      return {
        ...state,
        paymentHistoryLoader: false,
        paymentHistorySection: payload,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_HISTORIES_X_FAILED:
      return {
        ...state,
        paymentHistoryLoader: false,
      };
    // NEW
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_STATUS_X_REQUESTED:
      return {
        ...state,
        isRideStarted: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_STATUS_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_STATUS_X_FAILED:
      return {
        ...state,
        bookingStatus: payload,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PENDING_PAYMENT_STATUS_X_SUCCESS:
      return {
        ...state,
        pendingPaymentStatus: payload.paymentPending,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PENDING_PAYMENT_STATUS_X_FAILED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_REQUESTED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_FAILED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CLOSE_YOR_BOOKING_X_REQUESTED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CLOSE_YOR_BOOKING_X_SUCCESS:
      return {
        ...state,
        bookingStatus: payload,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CLOSE_YOR_BOOKING_X_FAILED:
      return {
        ...state,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CLEAR_BOOKING_DETAILS:
      return { ...initialState };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_AVAILABLE_VEHICLE_LIST_REQUESTED:
      return { ...state, availableVehicles: initialState.availableVehicles };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_AVAILABLE_VEHICLE_LIST_SUCCESS:
      return { ...state, availableVehicles: payload?.vehicles };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_AVAILABLE_VEHICLE_LIST_FAILED:
      return { ...state, availableVehicles: initialState.availableVehicles };
    case YOR_BOOKING_DETAILS_ACTIONS.YOR_USER_START_RIDE_X_REQUESTED:
      return {
        ...state,
        startRideLoader: true,
        isRideStarted: false,
      };

    case YOR_BOOKING_DETAILS_ACTIONS.YOR_USER_START_RIDE_X_SUCCESS:
      return {
        ...state,
        startRideLoader: false,
        isRideStarted: true,
      };

    case YOR_BOOKING_DETAILS_ACTIONS.YOR_USER_START_RIDE_X_FAILED:
      return {
        ...state,
        startRideLoader: false,
        isRideStarted: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_MODES_REQUESTED:
      return {
        ...state,
        paymentModesLoader: true,
        paymentModes: initialState.paymentModes,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_MODES_SUCCESS:
      return {
        ...state,
        paymentModesLoader: false,
        paymentModes: payload.paymentModes,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_MODES_FAILED:
      return {
        ...state,
        startRideLoader: false,
        isRideStarted: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED:
      return {
        ...state,
        orderData: initialState?.orderData,
        orderDataloader: true,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        orderData: payload?.orderData,
        orderDataloader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED:
      return {
        ...state,
        orderData: initialState?.orderData,
        orderDataloader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: true,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_SUCCESS:
      return {
        ...state,
        paymentLinkQRData: payload,
        paymentLinkQRLoader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_FAILED:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CLEANUP_YOR_ORDER_AND_PAYMENT_DATA:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: initialState?.paymentLinkQRLoader,
        orderData: initialState?.orderData,
        orderDataloader: initialState?.paymentLinkQRLoader,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_ORDER_CHARGES_BIFURCATION_REQUESTED:
      return {
        ...state,
        chargesBifurcationLoader: true,
        chargesBifurcation: initialState?.chargesBifurcation,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_ORDER_CHARGES_BIFURCATION_SUCCESS:
      return {
        ...state,
        chargesBifurcationLoader: false,
        chargesBifurcation: payload?.charges,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_ORDER_CHARGES_BIFURCATION_FAILED:
      return {
        ...state,
        chargesBifurcationLoader: false,
        chargesBifurcation: initialState?.chargesBifurcation,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.BOOKING_WAIVED_OFF_CHARGES_REQUESTED:
      return { ...state, waivedOffLoader: true };
    case YOR_BOOKING_DETAILS_ACTIONS.BOOKING_WAIVED_OFF_CHARGES_SUCCESS:
      return { ...state, waivedOffLoader: false };
    case YOR_BOOKING_DETAILS_ACTIONS.BOOKING_WAIVED_OFF_CHARGES_FAILED:
      return { ...state, waivedOffLoader: false };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_BULK_ADDITIONAL_CHARGES_REQUESTED:
      return { ...state, 
        savedCharges: initialState?.savedCharges,
        saveAdditionalChargesLoader: true };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_BULK_ADDITIONAL_CHARGES_SUCCESS: 
      return {
        ...state,
        savedCharges: payload,
        saveAdditionalChargesLoader: false,
      };
    case YOR_BOOKING_DETAILS_ACTIONS.CREATE_BULK_ADDITIONAL_CHARGES_FAILED:
      return { ...state, 
        savedCharges: initialState?.savedCharges,
        saveAdditionalChargesLoader: false };
    default:
      return { ...state };
  }
};

export default YOR_bookingDetailsReducer;
