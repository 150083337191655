import { PLAN_MANAGEMENT_ACTIONS } from "../actionTypes";

// get rental plan list
export const getRentalPlanList = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_LOAD,
  payload,
});

// edit current plan locally in reducer
export const editRentalPlan = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.EDIT_CURRENT_RENTAL_PLAN,
  payload,
});

// get rental plan by id
export const getRentalPlanById = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_LOAD,
  payload,
});

// update rental plan
export const updateRentalPlan = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_LOAD,
  payload,
});

// create rental plan
export const createRentalPlan = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_LOAD,
  payload,
});

// update rental plan status (active/inactive)
export const updateRentalPlanStatus = (payload: any) => ({
  type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_LOAD,
  payload,
});

// clear rental plan list
export const clearRentalPlanList = () => {
  return {
    type: PLAN_MANAGEMENT_ACTIONS.CLEAR_CURRENT_RENTAL_PLAN_LIST,
  };
};

// clear rental plan error data
export const clearRentalPlanErrorData = () => {
  return {
    type: PLAN_MANAGEMENT_ACTIONS.CLEAR_RENTAL_PLAN_ERROR_DATA,
  };
};
