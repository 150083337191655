import { isArrayNotEmpty } from "../../../../utils/helper";
import {
  GenericObject,
  HELMET_DISPLAY_NAME,
  modificationType,
} from "../../../constants/constants";

import {
  getBookingType,
  getDateTimeFromTimeStamp,
  getFormattedINR,
} from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";
interface objInterface {
  [key: string]: any;
}

interface InitialState {
  bookingDetails: GenericObject;
  planSummary: GenericObject;
  paymentSummary: GenericObject;
  bookingListData: GenericObject;
  bookingStats: GenericObject;
  odometerHistory: GenericObject[];
  onLoad: boolean;
  documents: GenericObject[];
  cancelBookingLoader: boolean;
  isBookingCancelled: boolean;
  planAndAddonsDataLoader: boolean;
  planAndAddonsData: GenericObject;
  additionalChargesList: any;
  additionalChargesLoader: boolean;
  saveAdditionalChargesLoader: boolean;
  savedCharges: GenericObject[];
  startRideImageRefId: string;
  orderData: any;
  orderDataloader: boolean;
  paymentLinkQRData: objInterface;
  paymentLinkQRLoader: boolean;
  waivedOffLoader: boolean;
  waiveOffReasons: [];
  bookingVehiclesImages: GenericObject[];
  paymentOverviewData: GenericObject;
  paymentOverviewDataLoader: boolean;
  availableVehicleList: GenericObject[];
  rideDelayData: GenericObject;
  extensionAvailableDate: number;
  extensionAvailabilityLoader: boolean;
  bookingVehicleDetails: GenericObject;
  attachmentIds: string[];
  pendingCharges: GenericObject[];
  pendingChargesLoader: boolean;
  isPendingChargeSucess: boolean;
  endedRideData: GenericObject[];
  extensionPlansDetails: GenericObject;
  calculatedExtensionCharge: GenericObject;
  isPaymentSuccess: boolean;
  vehicleExtensions: [];
  isPaymentLinkGenerated: boolean;
  recentModificationHistoryData: Map<string, GenericObject>;
  recentModificationHistoryLoader: boolean;
  createExtensionData: GenericObject;
  createExtensionLoader: boolean;
  calculatedExtensionChargeLoader: boolean;
  bookingVehicleImageLoader: boolean;
  bookingDocsLoader: boolean;
  docUploadLinearProgress: number;
  imageLoader: boolean;
  docLoader: boolean;
  error404: any;
  getExtensionsLoader: boolean;
  modificationReasons: GenericObject;
  unassignVehicleLoader: boolean;
  customerBookingStats: GenericObject;
  pendingAndProcessingCharges: GenericObject[];
  avialabelBookingModel: GenericObject;
  bookingModifyLoading: boolean;
  completeRidePaymentLoader: boolean;
  pendingChargesBookingsList: GenericObject;
  isPendingChargesInPreviousBookings: boolean;
  deleteModificationLoader: boolean;
  planAddOns: GenericObject;
}

const initialState: InitialState = {
  bookingDetails: {},
  planSummary: {},
  paymentSummary: {},
  bookingListData: {},
  bookingStats: {},
  odometerHistory: [],
  onLoad: false,
  documents: [],
  cancelBookingLoader: false,
  isBookingCancelled: false,
  planAndAddonsDataLoader: false,
  planAndAddonsData: {},
  availableVehicleList: [],
  rideDelayData: {},
  additionalChargesList: {},
  additionalChargesLoader: false,
  saveAdditionalChargesLoader: false,
  savedCharges: [],
  bookingVehiclesImages: [],
  startRideImageRefId: "",
  orderData: {},
  orderDataloader: false,
  paymentLinkQRData: {},
  paymentLinkQRLoader: false,
  waivedOffLoader: false,
  waiveOffReasons: [],
  paymentOverviewData: {},
  paymentOverviewDataLoader: false,
  extensionAvailableDate: 0,
  extensionAvailabilityLoader: false,
  bookingVehicleDetails: {},
  attachmentIds: [],
  pendingChargesLoader: false,
  pendingCharges: [],
  pendingAndProcessingCharges: [],
  isPendingChargeSucess: false,
  endedRideData: [],
  extensionPlansDetails: {},
  calculatedExtensionCharge: {},
  isPaymentSuccess: false,
  vehicleExtensions: [],
  isPaymentLinkGenerated: false,
  recentModificationHistoryData: new Map<string, GenericObject>(),
  recentModificationHistoryLoader: false,
  createExtensionData: {},
  createExtensionLoader: false,
  calculatedExtensionChargeLoader: false,
  bookingVehicleImageLoader: false,
  bookingDocsLoader: false,
  docUploadLinearProgress: 0,
  imageLoader: false,
  docLoader: false,
  error404: {},
  getExtensionsLoader: false,
  unassignVehicleLoader: false,
  customerBookingStats: {},
  modificationReasons: {},
  avialabelBookingModel: {},
  bookingModifyLoading: false,
  completeRidePaymentLoader: false,
  pendingChargesBookingsList: [],
  isPendingChargesInPreviousBookings: false,
  deleteModificationLoader: false,
  planAddOns: {},
};

// get data from modify history api for helmet modifications
const getHelmetModifications = (key: string, data: GenericObject) => {
  if (key === "modifiedFrom") {
    let modifiedFrom: string = "";
    for (let i = 0; i < data?.modifiedFrom?.length; i++) {
      i === 0
        ? (modifiedFrom += `${
            HELMET_DISPLAY_NAME[
              data?.modifiedFrom[i]?.type as keyof typeof HELMET_DISPLAY_NAME
            ]
          } - ${data?.modifiedFrom[i]?.count}`)
        : (modifiedFrom += `,/${
            HELMET_DISPLAY_NAME[
              data?.modifiedFrom[i]?.type as keyof typeof HELMET_DISPLAY_NAME
            ]
          } ${data?.modifiedFrom[i]?.count}`);
    }
    return modifiedFrom;
  } else {
    let modifiedTo: string = "";
    for (let i = 0; i < data?.modifiedTo?.length; i++) {
      i === 0
        ? (modifiedTo += `${
            HELMET_DISPLAY_NAME[
              data?.modifiedTo[i]?.type as keyof typeof HELMET_DISPLAY_NAME
            ]
          } -${data?.modifiedTo[i]?.count}`)
        : (modifiedTo += `/${
            HELMET_DISPLAY_NAME[
              data?.modifiedTo[i]?.type as keyof typeof HELMET_DISPLAY_NAME
            ]
          } ${data?.modifiedTo[i]?.count}`);
    }
    return modifiedTo;
  }
};

// bifurgation of modification history data
const filterModificationHistoryData = (key: string, data: GenericObject) => {
  switch (data?.category) {
    case modificationType.BOOKING_DATE_MODIFICATION:
      return key === "modifiedFrom"
        ? getDateTimeFromTimeStamp(data?.modifiedFrom?.startDate, "date")
        : getDateTimeFromTimeStamp(data?.modifiedTo?.startDate, "date");

    case modificationType.PLAN_MODIFICATION:
      return key === "modifiedFrom"
        ? `${getDateTimeFromTimeStamp(
            data?.modifiedFrom?.startDate,
            "date"
          )} - ${getDateTimeFromTimeStamp(data?.modifiedFrom?.endDate, "date")}`
        : `${getDateTimeFromTimeStamp(
            data?.modifiedTo?.startDate,
            "date"
          )} - ${getDateTimeFromTimeStamp(data?.modifiedTo?.endDate, "date")}`;

    case modificationType.MODEL_MODIFICATION:
      return key === "modifiedFrom" ? data?.modifiedFrom : data?.modifiedTo;

    case modificationType.VEHICLE_MODIFICATION:
      return key === "modifiedFrom" ? data?.modifiedFrom : data?.modifiedTo;

    case modificationType.SLOTS_MODIFICATION:
      return key === "modifiedFrom"
        ? `${data?.modifiedFrom?.start} - ${data?.modifiedFrom?.end}`
        : `${data?.modifiedTo?.start} - ${data?.modifiedTo?.end}`;

    case modificationType.HELMET_MODIFICATION:
      return getHelmetModifications(key, data);

    case modificationType.RETURN_DELIVERY_MODIFICATION:
      return key === "modifiedFrom" ? data.modifiedFrom : data.modifiedTo;

    case modificationType.DELIVERY_MODIFICATION:
      return key === "modifiedFrom" ? data.modifiedFrom : data.modifiedTo;

    case modificationType.PLAN_VARIANT_MODIFICATION:
      return key === "modifiedFrom"
        ? getBookingType(
            data.modifiedFrom?.unlimited,
            data.modifiedFrom?.premium
          )
        : getBookingType(data.modifiedTo?.unlimited, data.modifiedTo?.premium);

    default:
      return "";
  }
};

// get pending and proccessing payment data
const getPendingProcessingCharges = (data: any) => {
  let arr: any = [];
  if (data?.charges?.length || data?.processingCharges?.length) {
    arr = [
      ...data.charges.map((charge: any) => {
        return {
          category: charge?.category,
          subCategory: charge?.subCategory,
          amount: charge?.amount,
          description: charge?.description,
          details: charge?.details,
        };
      }),
      ...data.processingCharges.map((charge: any) => ({
        category: charge?.type,
        subCategory: charge?.subType,
        amount: charge?.pendingAmount,
        status: charge?.status,
        name: charge?.name,
        description: charge?.description,
        details: charge?.details,
      })),
    ];
  }

  return arr;
};

const BookingManagementReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // cases to handle fail and error saga actions
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.BOOKING_MANAGEMENT_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.BOOKING_MANAGEMENT_ERROR:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .RENTALS_GET_BOOKING_DETAILS_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_REQUESTED:
      const pageData = state.bookingListData.pageData;
      // persist the page count
      return {
        ...state,
        bookingDetails: {},
        startRideImageRefId: "",
        bookingListData: { bookings: [], pageData },
        isBookingCancelled: false,
        onLoad: true,
        cancelBookingLoader: false,
        error404: {},
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_REQUESTED:
      return {
        ...state,
        onLoad: action?.payload?.downloadBookingListHandler ? false : true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .RENTALS_GET_BOOKING_DETAILS_FAILED:
      return {
        ...state,
        error404: payload,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .RENTALS_GET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload.bookingDetails,
        planAddOns: payload?.planAddOns,
        planSummary: payload.planSummary,
        paymentSummary: payload.paymentSummary,
        documents: payload.bookingDetails.documents,
        startRideImageRefId:
          payload.bookingDetails?.bookingVehicleDetails?.startRideImageRefId,
        onLoad: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload.bookingDetails,
        planSummary: payload.planSummary,
        paymentSummary: payload.paymentSummary,
        documents: payload.bookingDetails.documents,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_SUCCESS:
      if (!payload.downloadedData) {
        // don't store the data in redux if it is downloaded data
        return {
          ...state,
          bookingListData: payload.data,
          onLoad: false,
        };
      } else {
        return { ...state };
      }
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_SUCCESS:
      return { ...state, onLoad: false, bookingStats: payload.bookingStatsV2 };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DETAILS_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_FAILED:
      return {
        ...state,
        onLoad: false,
      };
    // -----------;

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_ODOMETER_HISTORY_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_ODOMETER_HISTORY_SUCCESS:
      return {
        ...state,
        odometerHistory: payload.histories,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_ODOMETER_HISTORY_FAILED:
      return {
        ...state,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .FETCH_AVAILABLE_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        availableVehicleList: payload.data,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .FETCH_AVAILABLE_VEHICLE_LIST_FAILED:
      return {
        ...state,
        availableVehicleList: [],
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CHECK_START_RIDE_DELAY_SUCCESS:
      return { ...state, rideDelayData: payload.data };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_DOC_SIGNED_URLS_REQUESTED:
      return {
        ...state,
        bookingDocsLoader: true,
        docUploadLinearProgress: 30,
        docLoader: payload?.filesData?.docUploadLoader ? true : false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_DOC_SIGNED_URLS_SUCCESS:
      return {
        ...state,
        bookingDocsLoader: true,
        docUploadLinearProgress: 60,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_DOC_SIGNED_URLS_FAILED:
      return {
        ...state,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.BOOKING_DOCUMENT_UPLOAD_FAILED:
      return {
        ...state,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: payload,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_FAILED:
      return {
        ...state,
        bookingDocsLoader: false,
        docLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_DOCUMENTS_REQUESTED:
      return {
        ...state,
        bookingDocsLoader: true,
        docLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.DELETE_BOOKING_DOCUMENTS_FAILED:
      return {
        ...state,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_REQUESTED:
      return {
        ...state,
        cancelBookingLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBookingLoader: false,
        isBookingCancelled: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_FAILED:
      return {
        ...state,
        cancelBookingLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_PLAN_ADDON_DETAILS_REQUESTED:
      return {
        ...state,
        planAndAddonsDataLoader: true,
        planAndAddonsData: {},
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PLAN_ADDON_DETAILS_SUCCESS:
      return {
        ...state,
        planAndAddonsDataLoader: false,
        planAndAddonsData: payload,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_MODIFICATION_HISTORY_REQUESTED:
      return {
        ...state,
        modificationHistoryData: [],
        modificationHistoryLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_MODIFICATION_HISTORY_SUCCESS:
      let array = payload?.map((item: GenericObject, index: number) => {
        const obj = {
          id: index,
          modifiedOn: getDateTimeFromTimeStamp(item?.modifiedOn, "date") ?? "",
          category: item?.category,
          status: item?.status,
          paymentType: item?.paymentType,
          amount: getFormattedINR(item?.amount),
          modifiedFrom: filterModificationHistoryData("modifiedFrom", item),
          modifiedTo: filterModificationHistoryData("modifiedTo", item),
          createdAt: getDateTimeFromTimeStamp(item?.createdAt, "date"),
        };
        return obj;
      });

      return {
        ...state,
        modificationHistoryData: isArrayNotEmpty(array) ? array : [],
        modificationHistoryLoader: false,
      };

    // additional charges
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_ADDITIONAL_CHARGES_REQUESTED:
      return {
        ...state,
        additionalChargesLoader: true,
        additionalChargesList: {},
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_ADDITIONAL_CHARGES_SUCCESS:
      return {
        ...state,
        additionalChargesLoader: false,
        additionalChargesList: payload,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_ADDITIONAL_CHARGES_FAILED:
      return {
        ...state,
        additionalChargesLoader: false,
        additionalChargesList: {},
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_REQUESTED:
      return {
        ...state,
        savedCharges: initialState?.savedCharges,
        saveAdditionalChargesLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_SUCCESS:
      return {
        ...state,
        savedCharges: payload,
        saveAdditionalChargesLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_FAILED:
      return {
        ...state,
        savedCharges: initialState?.savedCharges,
        saveAdditionalChargesLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_VEHICLE_IMAGES_REQUESTED:
      return {
        ...state,
        bookingVehiclesImages: [],
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_VEHICLE_IMAGES_SUCCESS:
      return {
        ...state,
        bookingVehiclesImages: payload,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_VEHICLE_IMAGES_FAILED:
      return {
        ...state,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED:
      return {
        ...state,
        orderData: initialState?.orderData,
        orderDataloader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        orderData: payload?.orderData,
        orderDataloader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_FAILED:
      return {
        ...state,
        orderData: initialState?.orderData,
        orderDataloader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: true,
        isPaymentLinkGenerated: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_SUCCESS:
      return {
        ...state,
        paymentLinkQRData: payload,
        paymentLinkQRLoader: false,
        isPaymentLinkGenerated: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_FAILED:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: false,
        isPaymentLinkGenerated: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CLEANUP_REVAMP_ORDER_AND_PAYMENT_DATA:
      return {
        ...state,
        paymentLinkQRData: initialState?.paymentLinkQRData,
        paymentLinkQRLoader: initialState?.paymentLinkQRLoader,
        orderData: initialState?.orderData,
        orderDataloader: initialState?.paymentLinkQRLoader,
        isPaymentSuccess: false,
        isPaymentLinkGenerated: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .WAIVE_OFF_BOOKING_CHARGES_REQUESTED:
      return { ...state, waivedOffLoader: true };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .WAIVE_OFF_BOOKING_CHARGES_SUCCESS:
      return { ...state, waivedOffLoader: false };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .WAIVE_OFF_BOOKING_CHARGES_FAILED:
      return { ...state, waivedOffLoader: false };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_WAIVE_OFF_REASONS_LIST_REVAMP_REQUESTED:
      return { ...state, waiveOffReasons: [] };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_WAIVE_OFF_REASONS_LIST_REVAMP_SUCCESS:
      return { ...state, waiveOffReasons: payload };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_WAIVE_OFF_REASONS_LIST_REVAMP_FAILED:
      return { ...state, waiveOffReasons: [] };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_PAYMENTS_OVERVIEW_LIST_REQUESTED:
      return {
        ...state,
        paymentOverviewData: {},
        paymentOverviewDataLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_PAYMENTS_OVERVIEW_LIST_SUCCESS:
      return {
        ...state,
        paymentOverviewData: payload,
        paymentOverviewDataLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_PAYMENTS_OVERVIEW_LIST_FAILED:
      return {
        ...state,
        paymentOverviewData: {},
        paymentOverviewDataLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_AVAILABILITY_DETAILS_REQUESTED:
      return {
        ...state,
        extensionAvailableDate: 0,
        extensionAvailabilityLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_AVAILABILITY_DETAILS_SUCCESS:
      return {
        ...state,
        extensionAvailableDate: payload,
        extensionAvailabilityLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_AVAILABILITY_DETAILS_FAILED:
      return {
        ...state,
        extensionAvailableDate: 0,
        extensionAvailabilityLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_SIGNED_URLS_REQUESTED:
      return {
        ...state,
        bookingVehicleImageLoader: true,
        imageLoader: payload?.filesData?.vehiclesImages ? true : false,
        docUploadLinearProgress: 30,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_SIGNED_URLS_SUCCESS:
      return {
        ...state,
        docUploadLinearProgress: 96,
        bookingVehicleImageLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_SIGNED_URLS_FAILED:
      return {
        ...state,
        bookingVehicleImageLoader: false,
        imageLoader: false,
        docUploadLinearProgress: 0,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.VEHICLE_IMAGES_UPLOAD_FAILED:
      return {
        ...state,
        imageLoader: false,
      };

    case actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_IMAGES_SUCCESS:
      return {
        ...state,
        bookingVehicleImageLoader: false,
        imageLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_REQUESTED:
      return {
        ...state,
        attachmentIds: [],
        bookingDocsLoader: true,
        docUploadLinearProgress: 89,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_SUCCESS:
      return {
        ...state,
        attachmentIds: payload,
        bookingDocsLoader: false,
        docUploadLinearProgress: 96,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_FAILED:
      return {
        ...state,
        bookingVehicleImageLoader: false,
        bookingDocsLoader: false,
        docLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.VEHICLE_IMAGES_UPLOAD_FAILED:
      return {
        bookingVehicleImageLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_REQUESTED:
      return {
        ...state,
        pendingCharges: {},
        pendingChargesLoader: true,
        isPendingChargeSucess: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_SUCCESS:
      return {
        ...state,
        pendingCharges: payload,
        pendingAndProcessingCharges: getPendingProcessingCharges(payload),
        pendingChargesLoader: false,
        isPendingChargeSucess: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_FAILED:
      return {
        ...state,
        pendingCharges: {},
        pendingChargesLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_REQUESTED:
      return {
        ...state,
        pendingChargesLoader: true,
        endedRideData: {},
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_SUCCESS:
      return {
        ...state,
        endedRideData: payload,
        pendingChargesLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_FAILED:
      return {
        ...state,
        pendingChargesLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_REQUESTED:
      return {
        ...state,
        pendingChargesLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_SUCCESS:
      return {
        ...state,
        pendingChargesLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_FAILED:
      return {
        ...state,
        pendingChargesLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_BOOKING_REDUCER_DATA:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.SET_PAYMENT_SUCCESS_LTR:
      return {
        ...state,
        isPaymentSuccess: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_PLANS_DETAILS_REQUESTED:
      return { ...state, extensionPlansDetails: {} };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_PLANS_DETAILS_SUCCESS:
      return { ...state, extensionPlansDetails: payload };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_PLANS_DETAILS_FAILED:
      return { ...state, extensionPlansDetails: {} };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CALCULATED_EXTENSION_CHARGES_REQUESTED:
      return {
        ...state,
        calculatedExtensionChargeLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CALCULATED_EXTENSION_CHARGES_SUCCESS:
      return {
        ...state,
        calculatedExtensionCharge: payload,
        calculatedExtensionChargeLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CALCULATED_EXTENSION_CHARGES_FAILED:
      return {
        ...state,
        calculatedExtensionChargeLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COUPON_APPLY_FAILED_EXTENSION:
      return {
        ...state,
        calculatedExtensionChargeLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_RECENT_MODIFICATION_HISTORY_REQUESTED:
      return {
        ...state,
        recentModificationHistoryLoader: true,
        recentModificationHistoryData: new Map(),
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_RECENT_MODIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        recentModificationHistoryLoader: false,
        recentModificationHistoryData: payload,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_RECENT_MODIFICATION_HISTORY_FAILED:
      return {
        ...state,
        recentModificationHistoryLoader: false,
        recentModificationHistoryData: new Map(),
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_BOOKING_EXTENSION_REQUESTED:
      return {
        ...state,
        createExtensionData: {},
        createExtensionLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_BOOKING_EXTENSION_SUCCESS:
      return {
        ...state,
        createExtensionData: payload,
        createExtensionLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CREATE_BOOKING_EXTENSION_FAILED:
      return {
        ...state,
        createExtensionData: {},
        createExtensionLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_EXTENSION_REQUESTED:
      return {
        ...state,
        vehicleExtensions: [],
        getExtensionsLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_EXTENSION_SUCCESS:
      const Extension = payload?.extensions?.map(
        (extension: any, index: number) => {
          return {
            ...extension,
            Index: `Extension ${payload?.extensions?.length - index}`,
          };
        }
      );
      return {
        ...state,
        vehicleExtensions: Extension,
        getExtensionsLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_EXTENSION_FAILED:
      return {
        ...state,
        getExtensionsLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.AVIALABLE_BOOKING_MODEL_FAILED:
      return {
        ...state,
        availableBookingModels: [],
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.AVIALABLE_BOOKING_MODEL_SUCCESS:
      return {
        ...state,
        availableBookingModels: payload,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_PLAN_MODIFICATION_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_ADDRESS_CHARGES_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_MODEL_MODIFICATION_CHARGES_REQUESTED:
      return {
        ...state,
        modifyBookingCharges: {},
        modifyBookingChargesLoader: true,
        bookingModifyLoading: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_PLAN_MODIFICATION_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_ADDRESS_CHARGES_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_MODEL_MODIFICATION_CHARGES_SUCCESS:
      return {
        ...state,
        modifyBookingCharges: payload,
        modifyBookingChargesLoader: false,
        bookingModifyLoading: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_PLAN_MODIFICATION_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_ADDRESS_CHARGES_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_MODEL_MODIFICATION_CHARGES_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_MODIFY_CHARGES:
      return {
        ...state,
        modifyBookingCharges: {},
        modifyBookingChargesLoader: false,
        bookingModifyLoading: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_REQUESTED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_REQUESTED:
      return {
        ...state,
        bookingModifyLoading: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_FAILED:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_FAILED:
      return {
        ...state,
        bookingModifyLoading: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_SUCCESS:
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_SUCCESS:
      return {
        ...state,
        bookingModifyLoading: false,
        modifiedSuccesfully: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_MODIFICATION_DATA:
      return {
        ...state,
        modifiedSuccesfully: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_MODIFICATION_REASONS_SUCCESS:
      if (payload.key === modificationType.PLAN_MODIFICATION) {
        return {
          ...state,
          planModificationReasons: payload.data,
        };
      }
      return {
        ...state,
        modificationReasons: payload.data,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_REQUESTED:
      return {
        ...state,
        unassignVehicleLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_SUCCESS:
      return {
        ...state,
        unassignVehicleLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_FAILED:
      return {
        ...state,
        unassignVehicleLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_EXTENSION_REQUESTED:
      return {
        ...state,
        getExtensionsLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_EXTENSION_SUCCESS:
      return {
        ...state,
        getExtensionsLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.DELETE_BOOKING_EXTENSION_FAILED:
      return {
        ...state,
        getExtensionsLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKINGS_STATS_V3_REQUESTED:
      return {
        ...state,
        customerBookingStats: {},
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKINGS_STATS_V3_SUCCESS:
      return {
        ...state,
        customerBookingStats: payload,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKINGS_STATS_V3_FAILED:
      return {
        ...state,
        customerBookingStats: {},
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_REQUESTED:
      return {
        ...state,
        completeRidePaymentLoader: true,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_SUCCESS:
      return {
        ...state,
        completeRidePaymentLoader: false,
      };
    case actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_FAILED:
      return {
        ...state,
        completeRidePaymentLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .SET_PENDING_CHARGES_BOOKINGS_START_RIDE:
      return {
        ...state,
        pendingChargesBookingsList: payload,
        isPendingChargesInPreviousBookings: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_MODIFICATION_REQUESTED:
      return {
        ...state,
        deleteModificationLoader: true,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_MODIFICATION_SUCCESS:
      return {
        ...state,
        deleteModificationLoader: false,
      };

    case actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_MODIFICATION_FAILED:
      return {
        ...state,
        deleteModificationLoader: false,
      };

    default:
      return { ...state };
  }
};
export default BookingManagementReducer;
