import { GenericObject } from "../../../constants/constants";
import { actionInterface } from "../../../interfaces/commonInterface";
import { RENTALS_IOT_MANAGEMENT_ACTION } from "../../actions/actionTypes";

interface initialState {
  deviceDetailsData: GenericObject;
  pingDeviceLoader: boolean;
  pingDeviceData: Array<Object>;
  iotList: GenericObject;
  addEditDeviceLoader: boolean;
  isDeviceAdded: boolean;
  vendorsList: Array<Object>;
  modelsList: Array<Object>;
  tableData: Array<Object>;
  isDeviceUpdated: boolean;
  vehicleIotDevices: Array<Object>;
  refreshIotDeviceList: boolean;
  devicePingHistory: { loading: boolean };
  iotBulkUploadData: {
    loading: boolean;
    validEntries: number;
    invalidEntries: Array<Object>;
  };
  vehicleIotOdometerData: {
    loading: boolean;
  };
  getIotListLoader: boolean;
  excelFormatRequestedLoader: boolean;
}

const initialState: initialState = {
  deviceDetailsData: {},
  pingDeviceLoader: false,
  pingDeviceData: [],
  iotList: {},
  addEditDeviceLoader: false,
  isDeviceAdded: false,
  vendorsList: [],
  modelsList: [],

  tableData: [],
  isDeviceUpdated: false,
  vehicleIotDevices: [],
  refreshIotDeviceList: false,
  devicePingHistory: {
    loading: true,
  },
  iotBulkUploadData: { loading: false, validEntries: 0, invalidEntries: [] },
  vehicleIotOdometerData: { loading: false },
  getIotListLoader: false,
  excelFormatRequestedLoader: false,
};

const rentalsIotManagementReducer = (
  state = initialState,
  action: actionInterface
) => {
  const { type, payload } = action;

  switch (type) {
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ADD_IOT_DEVICE_REQUESTED:
      return {
        ...state,
        addEditDeviceLoader: true,
        isDeviceAdded: false,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ADD_IOT_DEVICE_SUCCEEDED:
      return {
        ...state,
        addEditDeviceLoader: false,
        isDeviceAdded: true,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ADD_IOT_DEVICE_FAILED:
      return {
        ...state,
        addEditDeviceLoader: false,
        isDeviceAdded: false,
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_REQUESTED:
      return {
        ...state,
        iotList: { ...state.iotList, iotDetails: [] },
        isDeviceAdded: false,
        isDeviceUpdated: false,
        getIotListLoader: true, 
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_SUCCESS:
      return {
        ...state,
        iotList: { ...payload.iotListData },
        getIotListLoader: false,
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_FAILED:
      return {
        ...state,
        iotList: { ...payload.iotListData },
        getIotListLoader: false,
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_CLEAR_ALL_IOT_LIST:
      return {
        ...state,
        iotList: { loading: false },
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_VENDORS_REQUESTED:
      return { ...state, vendorsList: [] };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_VENDORS_SUCCESS:
      let vendors = payload?.vendorsList?.map((data: any) => ({
        ...data,
        displayName: data.name,
      }));
      return { ...state, vendorsList: vendors };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_ALL_IOT_VENDORS_FAILED:
      return { ...state, vendorsList: [] };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_DEVICE_DETAILS_REQUESTED:
      return { ...state, deviceDetailsData: {} };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        deviceDetailsData: payload?.deviceDetailsData,
        tableData: payload?.tableData,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_DEVICE_DETAILS_FAILED:
      return { ...state, deviceDetailsData: {} };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_REQUESTED:
      return { ...state, pingDeviceLoader: true, pingDeviceData: [] };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_SUCCESS:
      return {
        ...state,
        pingDeviceLoader: false,
        pingDeviceData: payload.pingDeviceData,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_FAILED:
      return { ...state, pingDeviceLoader: false, pingDeviceData: [] };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_CLEAR_DEVICE_PING:
      return { ...state, pingDeviceLoader: false, pingDeviceData: [] };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UPADTE_IOT_DETAILS_REQUESTED:
      return { ...state, isDeviceUpdated: false, addEditDeviceLoader: true };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UPADTE_IOT_DETAILS_SUCCESS:
      return { ...state, isDeviceUpdated: true, addEditDeviceLoader: false };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UPADTE_IOT_DETAILS_FAILED:
      return { ...state, isDeviceUpdated: false, addEditDeviceLoader: false };

    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ASSIGN_IOT_DEVICE_REQUESTED:
    //   return { ...state };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ASSIGN_IOT_DEVICE_FAILED:
    //   return { ...state };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_ASSIGN_IOT_DEVICE_SUCCESS:
    //   return { ...state };

    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_REFRESH_VEHICLE_IOT_DEVICE:
    //   return { ...state, refreshIotDeviceList: !state.refreshIotDeviceList };

    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED:
    //   return { ...state };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UNASSIGN_IOT_DEVICE_FAILED:
    //   return { ...state };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_UNASSIGN_IOT_DEVICE_SUCCESS:
    //   return { ...state };

    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_VEHICLE_IOT_DEVICES_REQUESTED:
    //   return { ...state };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_VEHICLE_IOT_DEVICES_SUCCESS:
    //   return { ...state, vehicleIotDevices: payload.data };
    // case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_VEHICLE_IOT_DEVICES_FAILED:
    //   return { ...state, vehicleIotDevices: [] };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_HISTORY_REQUESTED:
      return { ...state, devicePingHistory: { loading: true } };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_HISTORY_SUCCESS:
      return {
        ...state,
        devicePingHistory: { ...payload.data, loading: false },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_HISTORY_FAILED:
      return { ...state, devicePingHistory: { loading: false } };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_IOT_BULK_UPLOAD_REQUESTED:
      return {
        ...state,
        iotBulkUploadData: { loading: true },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_IOT_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        iotBulkUploadData: { ...payload, loading: false },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_IOT_BULK_UPLOAD_RESET:
      return {
        ...state,
        iotBulkUploadData: { loading: false },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_IOT_BULK_UPLOAD_FAILED:
      return {
        ...state,
        iotBulkUploadData: { ...payload, loading: false },
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_UPLOAD_FORMAT_REQUESTED:
      return {
        ...state,
        excelFormatRequestedLoader: true,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_UPLOAD_FORMAT_SUCCESS:
      return {
        ...state,
        excelFormatRequestedLoader: false,
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_UPLOAD_FORMAT_FAILED:
      return {
        ...state,
        excelFormatRequestedLoader: false,
      };

    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_ODOMETER_DETAILS_REQUESTED:
      return {
        ...state,
        vehicleIotOdometerData: { loading: true },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_ODOMETER_DETAILS_SUCCESS:
      return {
        ...state,
        vehicleIotOdometerData: { ...payload, loading: false },
      };
    case RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_GET_IOT_ODOMETER_DETAILS_FAILED:
      return {
        ...state,
        vehicleIotOdometerData: {
          ...state.vehicleIotOdometerData,
          loading: false,
        },
      };

    default:
      return { ...state };
  }
};

export default rentalsIotManagementReducer;
