import { BASE_URL, customAxios, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

// getOperatorLeadList
const getOperatorLeadList = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getOperatorLeadList}`;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// get Operator Lead details
const getOperatorLeadDetails = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).getOperatorLeadDetails}`;
    return customAxios.get(url).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

//save opreator details
const saveOperatorLeadDetails = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).saveOperatorDetails}`;
    return customAxios.put(url, payload.apiBody).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

//initiate e-sign process
const initiateESignOperator = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().initiateESign}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

//verify email
const verifyEmailOperator = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().verifyEmail}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

//approve lead
const approveLead = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).approveLead}`;
    return customAxios.put(url).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

//get operator list
const getOperatorList = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getOperatorList}`;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// get Operator details
const getOperatorDetails = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).getOperatorDetails}`;
    return customAxios.get(url).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// approve Operator document api
const approveRejectOperatorDocument = (payload: any) => {
  try {
    const { data = {}, operatorId = "" } = payload;
    let url = BASE_URL + getApiUrl(operatorId).approveRejectOperatorDocument;

    return customAxios.post(url, { data }).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// save Operator document api
const saveOperatorDocument = (payload: any) => {
  try {
    const { data = {}, id: operatorId = "" } = payload;
    let url = BASE_URL + getApiUrl(operatorId).saveOperatorDocument;
    return customAxios.post(url, { data }).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// submit Operator document api
const submitOperatorDocument = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).submitOperatorDocument}`;
    return customAxios.put(url).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// modify Operator config service
const modifyOperatorConfig = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().modifyOperatorConfig}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

const viewAgreement = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).viewAgreement}`;
    return customAxios.get(url).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

export default {
  getOperatorLeadList,
  getOperatorLeadDetails,
  saveOperatorLeadDetails,
  initiateESignOperator,
  verifyEmailOperator,
  approveLead,
  getOperatorList,
  getOperatorDetails,
  approveRejectOperatorDocument,
  saveOperatorDocument,
  submitOperatorDocument,
  modifyOperatorConfig,
  viewAgreement,
};
