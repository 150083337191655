import { toastr } from "react-redux-toastr";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { PROFILE_API_ACTION } from "../../../redux";
import { getStore } from "../../../redux/store";
import { handleMessage } from "../../../utils/helper";
import rentalEn from "../../locale/rental-en.json";
import * as actionTypes from "../actions/actionTypes";
import businessManagement from "../services/businessManagement";

const { toastTypes } = rentalEn;

//get City Details
function* getCityOverview(action: any): any {
  try {
    const data = yield call(businessManagement.getCityOverview, "");
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}
//warehouse Details
function* getWarehouseOverview(action: any): any {
  try {
    const data = yield call(
      businessManagement.getWarehouseOverview,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .GET_WAREHOUSE_OVERVIEW_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .GET_WAREHOUSE_OVERVIEW_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
        .GET_WAREHOUSE_OVERVIEW_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

//Get City List
function* getCityList(action: any): any {
  try {
    const data = yield call(businessManagement.getCityList, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}
//Save New City
function* saveNewCity(action: any): any {
  try {
    const data = yield call(businessManagement.saveNewCity, action.payload);
    if (data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_SUCCESS,
        payload: data,
      });
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_REQUESTED,
        payload: action.payload?.filters,
      });
      yield put({
        type: PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
      });
      toastr.success(
        "",
        "City Added successfully! you can now add warehouse for this city"
      );
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* updateCity(action: any): any {
  try {
    const data = yield call(businessManagement.updateCity, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_SUCCESS,
        payload: data.data.data,
      });
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_REQUESTED,
        payload: action.payload?.data?.filters,
      });
      yield put({
        type: PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
      });
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* getSignedUrl(action: any): any {
  try {
    const { onSuccess, file = {} } = action.payload;
    const data = yield call(
      businessManagement.generateSignedUrl,
      action.payload
    );

    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_SUCCESS,
        payload: data?.data?.data?.attachments[0] || {},
      });

      const imageFile = {
        url: data?.data.data.attachments[0].signedUrl,
        img: file,
        type: file?.type,
      };

      try {
        const imgData = yield call(
          businessManagement.uploadImgOnSthree,
          imageFile
        );
        if (imgData?.status === 200) {
          yield put({
            type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
              .IMAGE_UPLOADED_S3_SUCCESS,
          });
          onSuccess && onSuccess(data?.data?.data?.attachments[0], file);
        } else {
          yield put({
            type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
              .IMAGE_UPLOADED_S3_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
            .IMAGE_UPLOADED_S3_FAILED,
        });
      }
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* addPolygon(action: any): any {
  try {
    const data = yield call(
      businessManagement.addPolygon,
      action?.payload?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_ADD_POLYGON_SUCCESS,
      });
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_GET_POLYGONS_REQUESTED,
        payload: action.payload.branchId,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_ADD_POLYGON_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_FAILED,
    });
  }
}

function* editPolygon(action: any): any {
  try {
    const data = yield call(businessManagement.editPolygon, action?.payload);
    if (data?.status === 200 || data?.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_EDIT_POLYGON_SUCCESS,
      });
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_GET_POLYGONS_REQUESTED,
        payload: action.payload.branchId,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_EDIT_POLYGON_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_FAILED,
    });
  }
}

function* deletePolygon(action: any): any {
  try {
    const data = yield call(businessManagement.deletePolygon, action.payload);
    if (data?.status === 200 || data?.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_DELETE_POLYGON_SUCCESS,
      });
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_GET_POLYGONS_REQUESTED,
        payload: action.payload.branchId,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_DELETE_POLYGON_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
        .RENTALS_DELETE_POLYGON_FAILED,
    });
  }
}

function* getPolygon(action: any): any {
  try {
    const data = yield call(businessManagement.getPolygon, action.payload);
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_GET_POLYGONS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .RENTALS_GET_POLYGONS_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_FAILED,
    });
  }
}

function* createWarehouse(action: any): any {
  try {
    const data = yield call(
      businessManagement.createNewWarehouse,
      action.payload
    );
    if (data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_SUCCESS,
        payload: data.data.data,
      });
      yield put({
        type: PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
      });
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* getWarehouseList(action: any): any {
  try {
    const data = yield call(
      businessManagement.getWarehouseList,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .GET_WAREHOUSE_LIST_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* viewWarehouse(action: any): any {
  try {
    const data = yield call(
      businessManagement.viewWarehouseDetails,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* updateWarehouse(action: any): any {
  try {
    const data = yield call(businessManagement.updateWarehouse, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_SUCCESS,
        payload: data.data.data,
      });
      yield put({
        type: PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
      });
      if (action.payload?.filters) {
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
            .GET_WAREHOUSE_LIST_REQUESTED,
          payload: action.payload.filters,
        });
      }
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* getCityDetails(action: any): any {
  try {
    const data = yield call(businessManagement.getCityDetails, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// Add New User - Role Management
function* addNewUserSaga(action: any): any {
  try {
    const data = yield call(businessManagement.addNewUser, action?.payload);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_SUCCESS,
        payload: { ...data.data.data, name: action?.payload?.data?.name },
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback("add");
      }
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Get Roles List - Role Management
function* getRolesListSaga(action: any): any {
  try {
    const data = yield call(businessManagement.getRolesList);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_SUCCESS,
        payload: data.data.data || [],
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// handleChange - Role Management
function* handleChangeSaga(action: any): any {
  try {
    if (action?.payload?.key === "roleId") {
      const { key, value, uuid, initialAssignRoleState } = action?.payload;
      let tempVar: any = [];
      tempVar = initialAssignRoleState.map((item: any, i: number) =>
        item?.uuid === uuid
          ? {
              ...item,
              roleId: value,
              state: [],
              selectedState: [],
              city: [],
              selectedCity: [],
              branch: [],
              selectedBranch: [],
            }
          : item
      );
      // Update the roleId
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
        payload: {
          key: key,
          value: value,
          uuid,
          initialAssignRoleState: [...tempVar],
        },
      });
      // Make api call to get all states
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_STATES_REQUESTED,
        keyObj: {
          key: "state",
          uuid,
          initialAssignRoleState: [...tempVar],
        },
      });
    }
    // ***
    if (action?.payload?.key === "STATE") {
      const { key, value, uuid, initialAssignRoleState } = action?.payload;
      let tempVar: any = [];
      tempVar = initialAssignRoleState.map((item: any, i: number) =>
        item?.uuid === uuid
          ? {
              ...item,
              selectedState: value,
              city: [],
              selectedCity: [],
              branch: [],
              selectedBranch: [],
            }
          : item
      );
      // Update the selectedState
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
        payload: {
          key: key,
          value: value,
          uuid,
          initialAssignRoleState: [...tempVar],
        },
      });
      // Make api call to get all cities based on the selected states
      if (value?.length) {
        yield put({
          type: actionTypes.COMMON_ACTIONS
            .GET_CITIES_BY_MULTIPLE_STATES_REQUESTED,
          payload: { states: value },
          keyObj: {
            key: "city",
            uuid,
            initialAssignRoleState: [...tempVar],
          },
        });
      }
    }
    // ***
    if (action?.payload?.key === "CITY") {
      const { key, value, uuid, initialAssignRoleState } = action?.payload;
      let tempVar: any = [];
      tempVar = initialAssignRoleState.map((item: any, i: number) =>
        item?.uuid === uuid
          ? { ...item, selectedCity: value, branch: [], selectedBranch: [] }
          : item
      );
      // Update the selectedCity
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
        payload: {
          key: key,
          value: value,
          uuid,
          initialAssignRoleState: [...tempVar],
        },
      });
      // Make api call to get all hubs based on the selected cities
      if (value?.length) {
        yield put({
          type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_REQUESTED,
          payload: { cities: value },
          keyObj: {
            key: "hubs",
            uuid,
            initialAssignRoleState: [...tempVar],
          },
        });
      }
    }
    // ***
    if (action?.payload?.key === "BRANCH") {
      const { key, value, uuid, initialAssignRoleState } = action?.payload;
      let tempVar: any = [];
      tempVar = initialAssignRoleState.map((item: any, i: number) =>
        item?.uuid === uuid ? { ...item, selectedBranch: value } : item
      );
      // Update the selectedBranch
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
        payload: {
          key: key,
          value: value,
          uuid,
          initialAssignRoleState: [...tempVar],
        },
      });
    }
    // ***
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Add/ Edit/ Delete Role - Role Management
function* saveRoleSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.saveRole,
      action?.payload,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_SUCCESS,
        payload: data.data.data,
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback();
      }
      toastr.success(toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Get All Users List - Role Management
function* fetchAllUsersListSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.getAllUsersList,
      action?.payload
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .FETCH_ALL_USERS_LIST_SUCCESS,
        payload: data.data.data || [],
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .FETCH_ALL_USERS_LIST_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

//get helmet configuration list
function* getHelmetConfigList(action: any): any {
  try {
    const data = yield call(
      businessManagement.getHelmetConfigList,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .GET_HELMET_CONFIG_LIST_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .GET_HELMET_CONFIG_LIST_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
        .GET_HELMET_CONFIG_LIST_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// Fetch User Details by UserId - Role Management
function* fetchUserDetailsByUserIdSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.getUserDetailsByUserId,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .FETCH_USER_DETAILS_BY_USER_ID_SUCCESS,
        payload: data.data.data || {},
      });
      // Get all states from redux
      const state: any = yield select(
        (store) => store.rentalsCommonReducer.allStatesList
      );
      // Get all cities from redux
      const city: any = yield select(
        (store) => store.rentalsCommonReducer.citiesBasedOnMultiStates
      );
      // authorized branches from login data
      const branch: any =
        getStore().newAuthReducer?.userData?.authorizedBranches;

      // Function to fetch unique cities based on states array
      const getCities = (cityArr: any, statesArr: any) => {
        const stateIds: any = new Set(
          statesArr?.map((state: any) => state?.id)
        );
        const filteredCities: any = cityArr
          ?.filter((city: any) => stateIds.has(city?.state)) // Filter based on states array
          ?.map((city: any) => ({
            displayName: city.displayName,
            name: city.name,
          }));

        return filteredCities;
      };

      // Function to fetch unique branches based on states array
      const getBranches = (branchArr: any, citiesArr: any) => {
        const cityIds: any = new Set(citiesArr?.map((city: any) => city?.id));
        const filteredBranches: any = branchArr
          ?.filter((branch: any) => cityIds.has(branch?.city)) // Filter based on cities array
          ?.map((branch: any) => ({
            displayName: branch.displayName,
            name: branch.name,
          }));

        return filteredBranches;
      };

      const userDataByRole = data.data.data?.roles.map(
        (role: any, i: number) => {
          const {
            roleId,
            roleName,
            appliesTo,
            operatorIds,
            states,
            cities,
            branches,
          } = role || {};

          return {
            uuid: uuidv4(),
            operation: "update",
            isExisting: true,
            roleId: {
              _id: roleId,
              name: roleName,
              appliesTo: appliesTo,
            },
            operatorIds,
            state,
            selectedState: states.map((s: any) => s.id),
            city: getCities(city, states),
            selectedCity: cities.map((c: any) => c.id),
            branch: getBranches(branch, cities),
            selectedBranch: branches.map((b: any) => b.id),
          };
        }
      );
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .UPDATE_EXISTING_USER_ROLES,
        payload: userDataByRole || {},
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .FETCH_USER_DETAILS_BY_USER_ID_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
        .FETCH_USER_DETAILS_BY_USER_ID_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

//add helmet configuration
function* addHelmetConfig(action: any): any {
  try {
    const data = yield call(businessManagement.addHelmetConfig, action.payload);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_SUCCESS,
        payload: data.data.data,
      });
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

//update helmet configuration
function* updateHelmetConfig(action: any): any {
  try {
    const data = yield call(
      businessManagement.updateHelmetConfig,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .UPDATE_HELMET_CONFIG_SUCCESS,
        payload: data.data.data,
      });
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
          .UPDATE_HELMET_CONFIG_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

//get helmet configuration
function* getHelmetconfig(action: any): any {
  try {
    const data = yield call(businessManagement.getHelmetConfig, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_FAILED,
    });
    toastr.error("Error", handleMessage(error));
  }
}

// Block user - Role management
function* blockUserSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.blockUser,
      action?.payload,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_SUCCESS,
        payload: data.data.data,
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback();
      }
      toastr.success(toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_FAILED,
      });
      toastr.error(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Unblock user - Role management
function* unblockUserSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.unblockUser,
      action?.payload,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_SUCCESS,
        payload: data.data.data,
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback();
      }
      toastr.success(toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_FAILED,
      });
      toastr.error(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Delete user - Role management
function* deleteUserSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.deleteUser,
      action?.payload,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_SUCCESS,
        payload: data.data.data,
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback();
      }
      toastr.success(toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_FAILED,
      });
      toastr.error(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Update User - Role Management
function* updateUserSaga(action: any): any {
  try {
    const data = yield call(
      businessManagement.updateUser,
      action?.payload,
      action?.id
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_SUCCESS,
        payload: data.data.data,
      });
      // Callback function on API success
      if (typeof action?.apiSuccessCallback === "function") {
        action?.apiSuccessCallback();
      }
      toastr.success(toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// Get Block Reasons - Role Management
function* getBlockReasonsSaga(action: any): any {
  try {
    const data = yield call(businessManagement.getBlockReasons);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_FAILED,
      });
      toastr.info(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

function* newBusinessManagementSaga() {
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_REQUESTED,
    getCityList
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_REQUESTED,
    saveNewCity
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_REQUESTED,
    getCityOverview
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_OVERVIEW_REQUESTED,
    getWarehouseOverview
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_REQUESTED,
    updateCity
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_REQUESTED,
    getSignedUrl
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_REQUESTED,
    addPolygon
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_REQUESTED,
    editPolygon
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_DELETE_POLYGON_REQUESTED,
    deletePolygon
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_REQUESTED,
    getPolygon
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_REQUESTED,
    createWarehouse
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_REQUESTED,
    getWarehouseList
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_REQUESTED,
    viewWarehouse
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_REQUESTED,
    updateWarehouse
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_REQUESTED,
    getCityDetails
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_REQUESTED,
    addNewUserSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_REQUESTED,
    getRolesListSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_REQUESTED,
    handleChangeSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_REQUESTED,
    saveRoleSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_REQUESTED,
    fetchAllUsersListSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_LIST_REQUESTED,
    getHelmetConfigList
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_REQUESTED,
    addHelmetConfig
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_REQUESTED,
    updateHelmetConfig
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_REQUESTED,
    getHelmetconfig
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .FETCH_USER_DETAILS_BY_USER_ID_REQUESTED,
    fetchUserDetailsByUserIdSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_REQUESTED,
    blockUserSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_REQUESTED,
    unblockUserSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_REQUESTED,
    deleteUserSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_REQUESTED,
    updateUserSaga
  );
  yield takeLatest(
    actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_REQUESTED,
    getBlockReasonsSaga
  );
}

export default newBusinessManagementSaga;
