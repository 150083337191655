import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import { colors } from "../../../../../themes/colors";
import {
  OperatorConfigurations,
  typographyConstants,
} from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";

export const OperatorConfig = ({
  operatorConfig,
  handleConfigChange,
  customStyle,
  isDisabled,
  title,
  isMyAccount = false,
}: any) => {
  return (
    <>
      {Object.keys(operatorConfig).length > 0 ? (
        <Box
          sx={{
            backgroundColor: colors.bg_secondary_blue,
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors.THEME_BLUE}
          >
            {title
              ? title
              : rentalEn.operatorManagement.operatorFunctionalities}
          </Typography>
          {!isMyAccount && (
            <Typography
              variant={typographyConstants.BODY}
              color={colors.THEME_BLUE}
            >
              {rentalEn.operatorManagement.noteConfigFunctionality}
            </Typography>
          )}

          <Box sx={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              {Object.keys(operatorConfig)
                ?.sort((a, b) => a.localeCompare(b)) // for sorting keys
                ?.map((item: any, key: any) => {
                  if (typeof operatorConfig[item] === "boolean") {
                    return (
                      <Grid item xs={3} key={key} sx={{ paddingTop: "0px" }}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label={
                            OperatorConfigurations[
                              item as keyof typeof OperatorConfigurations
                            ]
                          }
                          disabled={isDisabled}
                          checked={operatorConfig[item]}
                          onChange={() => {
                            handleConfigChange(item);
                          }}
                          sx={{
                            ...customStyle,
                          }}
                        />
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
