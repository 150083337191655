import { Box, Stack, Typography } from "@mui/material";
import HeadingValueTypography from "../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import styles from "../bookingDetails.module.css";
import {
  convertTo12hrs,
  dateFormat,
  getDateTimeFromTimeStamp,
  getFormattedINR,
  paisaToRupee,
  toPascalCase,
} from "../../../../utils/helper";
import {
  BookingImagesTypes,
  BookingStatusEnum,
  BookingTypes,
  GenericObject,
  HELMET_DISPLAY_NAME,
  ModeOfDelivery,
  ModeOfPickup,
  PaymentFlow,
} from "../../../../constants/constants";
import { colors } from "../../../../../themes/colors";
import en from "../../../../locale/rental-en.json";
import { RupeeInputIcon } from "../../../../constants/exportImages";
import { hoursToDays } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import LinkIcon from "@mui/icons-material/Link";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { getDeliveryAddress } from "../../../../../utils/helper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBookingDetails } from "../../../../redux/actions/bookingManagementActions";

const BookingDetailStep = ({
  id,
  navigateBookingDetailsStep,
  onLoad,
  handlePaymentOptionClick,
}: any) => {
  const {
    bookingDetails = {},
    planSummary = {},
    paymentSummary = {},
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);
  // operator Config Data
  const {
    operatorConfigData: { allowedPaymentFlows = [] },
  }: any = useSelector((state: RootState) => state.rentalsCommonReducer);

  const dispatch = useDispatch();

  // array of payments mode ui for table
  const paymentsMode: GenericObject[] = [
    {
      mode: PaymentFlow.PAYMENT_LINK,
      ui: (
        <LinkIcon
          sx={{ color: colors.primary_dark_blue2, cursor: "pointer" }}
          onClick={() =>
            handlePaymentOptionClick(
              PaymentFlow.PAYMENT_LINK,
              bookingDetails?.orderDetails?.orderId
            )
          }
        />
      ),
    },
    {
      mode: PaymentFlow.QR_CODE,
      ui: (
        <QrCode2Icon
          sx={{ color: colors.primary_dark_blue2, cursor: "pointer" }}
          onClick={() =>
            handlePaymentOptionClick(
              PaymentFlow.QR_CODE,
              bookingDetails?.orderDetails?.orderId
            )
          }
        />
      ),
    },
    {
      mode: PaymentFlow.CASH,
      ui: (
        <Typography
          sx={{ color: colors.primary_dark_blue2, cursor: "pointer" }}
        >
          {en.bookingDetails.paymentFlowDisplay.CASH}
        </Typography>
      ),
    },
  ];

  const { currentExtensionDetails = {} } = planSummary;
  const HelmetCount = bookingDetails?.addOns?.map((item: any) => {
    return `${item?.count} ${HELMET_DISPLAY_NAME[item?.subType]} ${
      item?.isComplementary ? `(Complementary)` : ""
    }`;
  });

  let bookingDashboardArr = [
    {
      displayKey: en.bookingDetails.planSelected,
      color: colors.LIGHT_BLUE,
      visitStepData: {
        step: 2,
        value: `${planSummary?.duration ?? 0} ${
          planSummary?.duration > 1 ? "Days" : "Day"
        } (${toPascalCase(planSummary?.type) ?? ""})`,
      },
      clickHandler: () => navigateBookingDetailsStep("planAndPayments"),
    },
    {
      displayKey: en.bookingDetails.amoubtPaid,
      value: paymentSummary?.paid ? getFormattedINR(paymentSummary?.paid) : "0",
    },
  ];

  if (Object.keys(currentExtensionDetails)?.length) {
    bookingDashboardArr.splice(1, 0, {
      displayKey: en.bookingDetails.extendRide,
      color: colors.LIGHT_BLUE,
      visitStepData: {
        step: 2,
        value: `${currentExtensionDetails?.duration} ${
          currentExtensionDetails?.duration > 1 ? "Days" : "Day"
        } (${toPascalCase(currentExtensionDetails?.type) ?? ""})`,
      },
      clickHandler: () => navigateBookingDetailsStep("vehicleDetails"),
    });
  }

  if (
    paymentSummary?.remainingAmount ||
    paymentSummary?.remainingAmount === 0
  ) {
    bookingDashboardArr.push({
      displayKey: en.bookingDetails.remainingAmount,
      value: paymentSummary?.remainingAmount
        ? getFormattedINR(paymentSummary?.remainingAmount)
        : "0",
    });
  }

  if (
    bookingDetails?.totalDuration &&
    bookingDetails?.totalDuration !== planSummary?.duration
  ) {
    bookingDashboardArr.splice(2, 0, {
      displayKey: en.bookingDetails.totalBookingDuration,
      value: `${bookingDetails?.totalDuration} ${
        bookingDetails?.totalDuration > 1 ? "Days" : "Day"
      }`,
    });
  }

  let bookingdescriptionArr = [
    {
      displayKey: en.bookingDetails.bookingNumber,
      value: bookingDetails?.bookingNumber ?? "",
    },
    {
      displayKey: en.bookingDetails.vehicleModel,
      value: bookingDetails?.modelDetails?.modelDisplayName ?? "",
    },
    {
      displayKey: en.bookingDetails.city,
      value: bookingDetails?.branchDetails?.cityDisplayName ?? "",
    },
    {
      displayKey: en.bookingDetails.hub,
      value: bookingDetails?.branchDetails?.branchDisplayName ?? "",
    },
    {
      displayKey: en.bookingDetails.dateOfBooking,
      value: getDateTimeFromTimeStamp(bookingDetails?.bookingDate, "dateTime"),
    },
    {
      displayKey: en.bookingDetails.packagestart,
      value: dateFormat(bookingDetails?.startDate ?? ""),
    },
    {
      displayKey: en.bookingDetails.packageEnd,
      value: dateFormat(bookingDetails?.endDate ?? ""),
    },
    {
      displayKey: en.bookingDetails.modeOfDelivery,
      value: bookingDetails?.deliveryDetails?.type
        ? ModeOfDelivery[
            bookingDetails?.deliveryDetails?.type as keyof typeof ModeOfDelivery
          ]
        : "",
    },
    {
      displayKey: en.bookingDetails.modeOfPickup,
      value: bookingDetails?.returnDetails?.type
        ? ModeOfPickup[
            bookingDetails?.returnDetails?.type as keyof typeof ModeOfPickup
          ]
        : "",
    },
    {
      displayKey: en.bookingDetails.bookingtype,
      value: bookingDetails?.bookingType
        ? BookingTypes[bookingDetails?.bookingType as keyof typeof BookingTypes]
        : "",
    },
    {
      displayKey: en.bookingDetails.helmetCategory,
      value: bookingDetails?.addOns?.length ? HelmetCount.join(", ") : "N/A",
    },
    {
      displayKey: en.bookingDetails.selectedTimeSlot,
      value:
        convertTo12hrs(bookingDetails?.deliveryDetails?.slot?.start ?? "") +
        "-" +
        convertTo12hrs(bookingDetails?.deliveryDetails?.slot?.end),
    },
  ];

  if (bookingDetails?.returnDetails?.slot) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.endRideTimeSlot,
      value: `${
        convertTo12hrs(bookingDetails?.returnDetails?.slot.start ?? "") +
          "-" +
          convertTo12hrs(bookingDetails?.returnDetails?.slot.end) ?? "N/A"
      }`,
    });
  }

  if (bookingDetails?.actualStartDateTime) {
    bookingdescriptionArr.splice(7, 0, {
      displayKey: en.bookingDetails.actualStartDateTime,
      value: getDateTimeFromTimeStamp(
        bookingDetails?.actualStartDateTime,
        "dateTime"
      ),
    });
  }
  if (bookingDetails?.actualEndDateTime) {
    bookingdescriptionArr.splice(8, 0, {
      displayKey: en.bookingDetails.actualEndDateTime,
      value: getDateTimeFromTimeStamp(
        bookingDetails?.actualEndDateTime,
        "dateTime"
      ),
    });
  }

  if (bookingDetails?.deliveryDetails?.address) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.deliveryAddress,
      value:
        getDeliveryAddress(bookingDetails?.deliveryDetails?.address) ??
        en.global.NA,
    });
  }
  if (bookingDetails?.returnDetails?.type === "CUSTOMER_LOCATION") {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.pickUpAddress,
      value:
        getDeliveryAddress(bookingDetails?.returnDetails?.address) ??
        en.global.NA,
    });
  }
  if (bookingDetails?.delay) {
    const delayhoursToDays = hoursToDays(bookingDetails?.delay);
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.rideDelay,
      value: delayhoursToDays,
    });
  }
  if (bookingDetails?.lateStartDetails?.noOfGraceHours) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.graceHours,
      value: hoursToDays(bookingDetails?.lateStartDetails?.noOfGraceHours),
    });
  }
  if (bookingDetails?.lateStartDetails?.description) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.rideDelayReason,
      value: bookingDetails?.lateStartDetails?.description,
    });
  }

  if (bookingDetails?.cancellationDetails?.cancelledByUserName) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.cnacelledBy,
      value: bookingDetails?.cancellationDetails?.cancelledByUserName,
    });
  }

  if (bookingDetails?.cancellationDetails?.description) {
    bookingdescriptionArr.push({
      displayKey: en.bookingDetails.cancellationReason,
      value: bookingDetails?.cancellationDetails?.description,
    });
  }

  useEffect(() => {
    // get booking details & operator config on success
    dispatch(
      getBookingDetails({
        bookingId: id,
        getConfig: true,
        fetchVehicleImages: true,
        requiredImages: [BookingImagesTypes.START_RIDE],
      })
    );
  }, [id]);

  return (
    <Box>
      <Box className={styles.bookingDetailsBox}>
        {bookingDashboardArr?.length > 0 &&
          bookingDashboardArr?.map((data: any) => {
            return (
              <Box>
                <HeadingValueTypography
                  heading={data?.displayKey}
                  value={data?.value}
                  link={data?.link}
                  color={data?.color}
                  visitStepData={data?.visitStepData}
                  clickHandler={data?.clickHandler}
                  loading={onLoad}
                />
              </Box>
            );
          })}
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap="20px"
        padding="20px"
        className="detailDescription"
      >
        {bookingdescriptionArr.length > 0 &&
          bookingdescriptionArr?.map((data: any) => {
            return (
              <HeadingValueTypography
                heading={data?.displayKey}
                value={data?.value}
                link={data?.link}
                width="170px"
                loading={onLoad}
                //elementClassList={styles.flexedItems}
                //clickHandler={data?.clickHandler}
              />
            );
          })}
        {bookingDetails.status === BookingStatusEnum.PENDING &&
          bookingDetails?.orderDetails?.orderId && (
            <HeadingValueTypography
              heading={en.bookingDetails?.selectPaymentMode}
              value={
                <Box sx={{ display: "flex", gap: "6px" }}>
                  {paymentsMode.map((paymentsMode: any) => {
                    if (allowedPaymentFlows?.includes(paymentsMode?.mode))
                      return paymentsMode?.ui;
                  })}
                </Box>
              }
              width="170px"
              loading={onLoad}
            />
          )}
      </Stack>
    </Box>
  );
};
export default BookingDetailStep;
