import React from "react";

const NoIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="2.527"
        height="17.293"
        x="17.231"
        y="5.072"
        fill={props?.color ? props?.color : "#01353D"}
        rx="1.263"
        transform="rotate(45 17.231 5.072)"
      ></rect>
      <rect
        width="2.527"
        height="17.293"
        fill={props?.color ? props?.color : "#01353D"}
        rx="1.263"
        transform="scale(-1 1) rotate(45 -9.517 -5.66)"
      ></rect>
    </svg>
  );
};

export default NoIcon;
