import { ACTIVE_MODULE_X, MASTER_ACTIONS_X } from "../../actions/actionTypes";
import { masterXInterface } from "../actionInterfaces";

interface YORMasterEntries {
  [key: string]: any;
}
type MapInterface = Map<string, any>;
/* interface YORMasterReducerInterface {
  cityData: [],
  loading: false,
  selectedCity: string,
  branchData: [],
  modelList: [],
  activeModule: any,
  activeModuleLoading: boolean,
  cityMasterMap: MapInterface,
  branchMapData: MapInterface,
  modelMapData: MapInterface,
  chargesCategoriesData: any,
  chargesCategoriesLoader: boolean,
} */

// const initialState: YORMasterReducerInterface = {
const initialState: any = {
  cityData: [],
  loading: false,
  selectedCity: "",
  branchData: [],
  modelList: [],
  activeModule: {
    name: "Booking List",
    parent: {
      name: "",
      path: "",
      drawerKey: "booking",
    },
    activeRoute: "bookinglist",
  },
  activeModuleLoading: false,
  cityMasterMap: new Map(),
  branchMapData: new Map(),
  modelMapData: new Map(),
  modelsMapImages: new Map(),
  chargesCategoriesData: {},
  chargesCategoriesLoader: false,
};

const masterReducerX = (state = initialState, action: masterXInterface) => {
  // const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case MASTER_ACTIONS_X.GET_CITY_DATA_X_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case MASTER_ACTIONS_X.GET_CITY_DATA_X_SUCCESS:
      return {
        ...state,
        loading: false,
        cityData: payload?.cityData,
        cityMasterMap: payload?.cityMapData,
      };
    case MASTER_ACTIONS_X.GET_CITY_DATA_X_FAILED:
      return {
        ...state,
        loading: false,
      };

    case MASTER_ACTIONS_X.GET_BRANCH_DATA_X_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case MASTER_ACTIONS_X.GET_BRANCH_DATA_X_SUCCESS:
      return {
        ...state,
        branchData: payload.branches,
        loading: false,
      };
    case MASTER_ACTIONS_X.GET_BRANCH_DATA_X_FAILED:
      return {
        ...state,
        branchData: {},
        loading: false,
      };

    case MASTER_ACTIONS_X.GET_MODAL_DATA_X_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case MASTER_ACTIONS_X.GET_MODAL_DATA_X_SUCCESS:
      return {
        ...state,
        modelList: payload.modelList,
        loading: false,
      };
    case MASTER_ACTIONS_X.GET_MODAL_DATA_X_FAILED:
      return {
        ...state,
        loading: false,
      };
    case ACTIVE_MODULE_X.ADD_ACTIVE_MODULE:
      if (!payload) return { ...state };
      return {
        ...state,
        activeModuleLoading: true,
        activeModule: payload,
      };
      case ACTIVE_MODULE_X.CLEAR_ACTIVE_MODULE:
        return {
          ...state,
          activeModuleLoading: false,
          activeModule: initialState.activeModule,
        };
    case MASTER_ACTIONS_X.GET_ALL_MODELS_X_REQUESTED:
      return {
        ...state,
      };
    case MASTER_ACTIONS_X.GET_ALL_MODELS_X_SUCCESS:
      return {
        ...state,
        modelMapData: payload.modelsMapData,
        modelsMapImages: payload.modelsMapImages,
      };
    case MASTER_ACTIONS_X.GET_ALL_MODELS_X_FAILED:
      return { ...state };

    case MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_REQUESTED:
      return { ...state };
    case MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_SUCCESS:
      return {
        ...state,
        branchMapData: payload,
      };
    case MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_FAILED:
      return { ...state };
    case MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_REQUESTED:
      return {
        ...state,
        chargesCategoriesData: initialState.chargesCategoriesData,
        chargesCategoriesLoader: true,
      };
    case MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_SUCCESS:
      return {
        ...state,
        chargesCategoriesData: payload,
        chargesCategoriesLoader: false,
      };
    case MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_FAILED:
      return {
        ...state,
        chargesCategoriesLoader: false,
      };

    default:
      return { ...state };
  }
};

export default masterReducerX;
