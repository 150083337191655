import { Box } from "@mui/material";
import UploadDocuments from "./uploadDocuments";
import VehicleImagesCarousel from "./vehicleCarousel";
import { useState } from "react";
import { BookingStatusEnum } from "../../../../constants/constants";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  bookingFunctionalities,
  modules,
} from "../../../../../constants/RBACModuleEnums";

const BookingDetailsOutsideRender = ({
  documents,
  bookingId,
  imagesData,
  bookingStatus,
  startRideImageRefId,
  vehicleImagesData,
  vehicleImagesDataLoader,
  bookingVehicleImageLoader,
  bookingDocsLoader,
}: any) => {
  const RbacHasAccess = {
    viewVehicleImages: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.VIEW_VEHICLE_IMAGES
    ),
    viewDocuments: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.VIEW_DOCUMENTS
    ),
    addVehicleImages: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.ADD_VEHICLE_IMAGES
    ),
    uploadDocuments: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.UPLOAD_DOCUMENTS
    ),
  };
  const [imagePreviewData, setImagePreviewData] = useState({
    imageUrl: "",
    openPreview: false,
  });

  const closeModal = () => {
    setImagePreviewData((prev: any) => ({
      ...prev,
      imageUrl: "",
      openPreview: false,
    }));
  };
  const openModal = (url: string, name: string) => {
    setImagePreviewData((prev: any) => ({
      ...prev,
      imageUrl: url,
      openPreview: true,
    }));
  };

  return (
    <Box>
      {RbacHasAccess.viewVehicleImages &&
        bookingStatus !== BookingStatusEnum.PENDING &&
        bookingStatus !== BookingStatusEnum.UPCOMING && (
          <VehicleImagesCarousel
            bookingId={bookingId}
            imagesData={vehicleImagesData}
            status={bookingStatus}
            imagePreviewData={imagePreviewData}
            closeModal={closeModal}
            openModal={openModal}
            startRideImageRefId={startRideImageRefId}
            imageLoader={bookingVehicleImageLoader}
            // bookingStatus={bookingStatus}
            uploadAccess={RbacHasAccess.addVehicleImages}
          />
        )}
      {RbacHasAccess.viewDocuments &&
        bookingStatus !== BookingStatusEnum.COMPLETED &&
        bookingStatus !== BookingStatusEnum.CANCELLED && (
          <UploadDocuments
            documents={documents}
            bookingId={bookingId}
            bookingDocsLoader={bookingDocsLoader}
            uploadAccess={RbacHasAccess.uploadDocuments}
          />
        )}
    </Box>
  );
};
export default BookingDetailsOutsideRender;
