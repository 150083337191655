import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { CrossIconRound } from "../../../rentals-src/constants/exportImages";
import { colors } from "../../../themes/colors";
import { toPascalCase } from "../../../rentals-src/utils/helper";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import { ClearOutlined } from "@mui/icons-material";
import rentalEn from "../../../rentals-src/locale/rental-en.json";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  bgcolor: "background.paper",
  borderRadius: "24px",
  boxShadow: 24,
  pb: 4,
  paddingTop: "0px",
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  children?: React.ReactNode;
  containerStyle?: {};
  onOutsideClickClose?: boolean;
}

export default function CustomModal(props: Props) {
  const {
    isOpen,
    handleClose,
    title = "",
    containerStyle = {},
    onOutsideClickClose = true,
  } = props;

  return (
    <Box
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Modal
        style={{
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={onOutsideClickClose ? handleClose : () => {}}
        disableAutoFocus={true}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Box sx={{ ...style, ...containerStyle }}>
            <Box
              sx={{
                position: "relative",
                // bottom: "168px",
                top: "-46px",
                left: "46%",
              }}
            >
              <Tooltip title={rentalEn.global.close}>
                <Box
                  sx={{
                    position: "absolute",
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundColor: colors.light_gray1,
                    padding: "4px",
                  }}
                  onClick={handleClose}
                >
                  <ClearOutlined />
                </Box>
              </Tooltip>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "12px",
                backgroundColor: colors.THEME_BLUE,
                color: "white",
                borderTopLeftRadius: "24px",
                borderTopRightRadius: "24px",
                justifyContent: "center",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant={typographyConstants.HEADING}
                sx={{ fontWeight: "bold", color: colors.white }}
              >
                {toPascalCase(title)}
              </Typography>
            </Box>
            {props.children}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
