import moment from "moment";
import { GET_REPORTS } from "../../actions/actionTypes";
import { reportInterface } from "../actionInterfaces";

const initialState = {
  reportData: [],
  reportloading: true,
  allData: "",
  genericReportData: [],
  genericReportloading: true,
  reportsData: [],
  reportheadings: [],
  reportKeys: [],
  genericReportList: [],
};

const modifyData = (payload: any) => {
  let arr: any = [];
  payload?.data?.forEach((each: any) => {
    let obj: any = {};
    let keys = Array.isArray(payload?.keys) ? payload?.keys : [];

    each?.forEach((item: any, index: number) => {
      obj[keys?.[index]] =
        keys?.[index]?.includes("date") || keys?.[index]?.includes("_on")
          ? moment(item ?? moment()).format("DD/MM/YYYY")
          : item ?? "N/A";
    });
    arr.push(obj);
  });
  return arr;
};

const reportReducer = (state = initialState, action: reportInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case GET_REPORTS.GET_USER_REPORTS_REQUESTED:
      return {
        ...state,
        reportloading: true,
      };
    case GET_REPORTS.GET_USER_REPORTS_SUCCESS:
      return {
        ...state,
        reportsData: modifyData(payload.reportData),
        reportheadings: payload.reportData?.headings,
        reportKeys: payload.reportData?.keys,
        reportloading: false,
      };
    case GET_REPORTS.GET_USER_REPORTS_FAILED:
      return {
        ...state,
        reportloading: false,
      };

    case GET_REPORTS.GET_BOOKING_REPORTS_REQUESTED:
      return {
        ...state,
        reportloading: true,
      };

    case GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_REQUESTED:
      return {
        ...state,
        genericReportloading: true,
      };
    case GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        genericReportloading: false,
      };
    case GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_FAILED:
      return {
        ...state,
        genericReportloading: false,
      };
    case GET_REPORTS.GET_BOOKING_REPORTS_SUCCESS:
      return {
        ...state,
        reportData: payload.reportData,
        reportloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.GET_BOOKING_REPORTS_FAILED:
      return {
        ...state,
        reportloading: false,
      };

    case GET_REPORTS.GET_PAYMENT_REPORTS_REQUESTED:
      return {
        ...state,
        reportloading: true,
      };
    case GET_REPORTS.GET_PAYMENT_REPORTS_SUCCESS:
      return {
        ...state,
        reportData: payload.reportData,
        reportloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.GET_PAYMENT_REPORTS_FAILED:
      return {
        ...state,
        reportloading: false,
      };

    case GET_REPORTS.GET_GENERIC_REPORTS_REQUESTED:
      return {
        ...state,
        genericReportloading: true,
      };
    case GET_REPORTS.GET_GENERIC_REPORTS_SUCCESS:
      return {
        ...state,
        // genericReportData: payload.reportData,
        genericReportloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.GET_GENERIC_REPORTS_FAILED:
      return {
        ...state,
        genericReportloading: false,
      };

    case GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_REQUESTED:
      return {
        ...state,
        genericReportloading: true,
      };
    case GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_SUCCESS:
      return {
        ...state,
        reportData: payload.reportData,
        reportloading: false,
        allData: payload.allData,
      };
    case GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_FAILED:
      return {
        ...state,
        reportloading: false,
      };

    default:
      return { ...prevState };
  }
};

export default reportReducer;
