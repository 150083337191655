import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import TextAreaInput from "../../../../../sharedComponents/atoms/InputFields/TextAreaInput";
import OTPField from "../../../../../sharedComponents/atoms/OTPFeild";
import CustomModal from "../../../../../sharedComponents/molecules/Modals/CustomModal";
import {
  typographyConstants,
  VehicleStatus,
} from "../../../../constants/constants";
import { BikeIcon, OdometerIcon } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  assignVehicle,
  checkStartRideDelay,
  getAvailableVehiclesForBooking,
  getBookingDetails,
  startRide,
} from "../../../../redux/actions/bookingManagementActions";
import {
  clearPingData,
  getPingData,
} from "../../../../redux/actions/iotManagementActions";
import { isNumericString, istToTimestamp } from "../../../../utils/helper";
import IotInfoCard from "./iotInfoCard";
import { toastr } from "react-redux-toastr";
import { bookingManagementActions } from "../../../../redux/actions";
import { colors } from "../../../../../themes/colors";
import { useNavigate } from "react-router";
import { routesConstants } from "../../../../utils/RoutesConstants";

const initialState = {
  selectedVehicle: { registration: "", vehicleId: "" },
  odometerReading: "",
  remark: "",
  selectedDelayReason: {
    _id: "",
    description: "",
    category: "",
    details: { remarkRequired: false },
  },
  showOtp: false,
  assignedVehicle: { registration: "", vehicleId: "", startReading: "" },
};

const errorInitialState = {
  selectedVehicle: "",
  odometerReading: "",
  delayReason: "",
  remark: "",
};

const StartRideModal = (props: any) => {
  const { bookingDetails, isOpen, handleClose, isAssignVehicle } = props;
  const [fields, setFields] = useState(initialState);
  const [otp, setOtp] = useState(""); // OTP Component accept only setState method on change so we have to take seperate state for this
  const [errorFields, setErrorFields] = useState(errorInitialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    availableVehicleList = [],
    rideDelayData,
    isPendingChargesInPreviousBookings,
    pendingChargesBookingsList,
    onLoad,
  } = useSelector((state: RootState) => state.bookingManagementReducer);

  const { pingDeviceData, pingDeviceLoader } = useSelector(
    (state: RootState) => state.rentalsIotManagementReducer
  );

  // operator Config Data
  const { operatorConfigData }: any = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { isLateStart = false, reasonDetails = [] } = rideDelayData;
  const isDelayedReasonRequired = isLateStart
    ? fields?.selectedDelayReason?.details?.remarkRequired
      ? !fields.selectedDelayReason._id || !fields.remark
      : !fields.selectedDelayReason._id
    : false;

  // push assigned vehicle in vehicle list & sort them
  const updatedVehicleList = useCallback(() => {
    const vehicleList = [...availableVehicleList];
    if (fields?.assignedVehicle?.registration)
      vehicleList.push(fields?.assignedVehicle);

    const assignedVehicles = vehicleList.filter(
      (vehicle) => vehicle.status === VehicleStatus?.ASSIGNED
    );
    const unassignedVehicleList = vehicleList.filter(
      (vehicle) => vehicle.status !== VehicleStatus?.ASSIGNED
    );

    return [...unassignedVehicleList, ...assignedVehicles];
  }, [fields.assignedVehicle, availableVehicleList]);

  // fetch available vehicle & check whether ride is delayed or not & vehiclle is already assigned or not
  useEffect(() => {
    if (isOpen) {
      !isAssignVehicle &&
        dispatch(checkStartRideDelay({ bookingId: bookingDetails?.bookingId }));
      // check if vehicle is already assigned or not
      setFields((prevFields) => ({
        ...prevFields,
        assignedVehicle: bookingDetails?.bookingVehicleDetails,
      }));

      dispatch(
        getAvailableVehiclesForBooking({
          bookingId: bookingDetails.bookingId,
          isvehicleAssigned:
            !!bookingDetails?.bookingVehicleDetails?.registration,
        })
      );
    }
    return () => {
      dispatch(clearPingData());
      setFields(initialState);
      setOtp("");
    };
  }, [isOpen]);

  // set assigned vehicle on a booking to selected vehicle - this assigned vehicle is coming from the API
  useEffect(() => {
    if (fields?.assignedVehicle?.registration) {
      setFields({
        ...fields,
        selectedVehicle: fields.assignedVehicle,
        odometerReading: fields.assignedVehicle?.startReading,
      });
      if (operatorConfigData?.iotMandatoryOnStartRide)
        dispatch(
          getPingData({
            data: { data: { vehicleId: fields.assignedVehicle.vehicleId } },
          })
        );
    }
  }, [fields.assignedVehicle]);

  // handle input changes
  const handleChange = (field: string, value: any) => {
    if (field === "selectedVehicle") {
      dispatch(clearPingData());
      dispatch(getPingData({ data: { data: { vehicleId: value.vehicleId } } }));
    }
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  // assign vehicle on start ride
  const handleStartRide = () => {
    if (fields?.selectedVehicle?.registration && fields?.odometerReading) {
      dispatch(
        assignVehicle({
          data: {
            vehicleId: fields.selectedVehicle.vehicleId,
            odometerReading: parseInt(fields.odometerReading),
          },
          bookingId: bookingDetails.bookingId,
          delayId: isAssignVehicle
            ? true
            : fields.selectedDelayReason._id || false,
          onSuccess: () => {
            if (!isAssignVehicle) setFields({ ...fields, showOtp: true });
            else {
              toastr.success("", rentalEn.successMessage.vehicleAssignedSucess);
              dispatch(
                getBookingDetails({
                  bookingId: bookingDetails.bookingId,
                  getConfig: true,
                })
              );
              handleClose();
            }
          },
        })
      );
    }
    if (!fields.odometerReading)
      setErrorFields((prev) => {
        return {
          ...prev,
          odometerReading: rentalEn.errorMessages.requiredField,
        };
      });
  };

  // ping device assigned to vehicle
  const handlePingDevice = () => {
    dispatch(
      getPingData({
        data: { data: { vehicleId: fields.selectedVehicle.vehicleId } },
      })
    );
  };

  // start ride API on verify OTP
  const verifyOtp = () => {
    const payload: any = {
      startCode: otp,
      dateAndTime: istToTimestamp(new Date()),
    };

    if (fields.selectedDelayReason._id) {
      if (fields.remark) payload.remark = fields.remark;
      payload.delayId = fields.selectedDelayReason._id;
    }

    dispatch(
      startRide({
        data: { ...payload },
        bookingId: bookingDetails?.bookingId,
        onSuccess: () => {
          handleClose();
          dispatch(getBookingDetails({ bookingId: bookingDetails?.bookingId }));
        },
      })
    );
  };

  //on booking number  navigate to booking details
  const handleBookingNumberClick = (id: number | string) => {
    navigate(`${routesConstants.BOOKING_DETAILS}/${id}`);
    handleClose();
  };

  // disable assigned vehicles
  const isVehicleSelectable = (data: any) => {
    return data.status === VehicleStatus?.ASSIGNED;
  };

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        isAssignVehicle
          ? rentalEn.bookingDetails.assignVehicle
          : rentalEn.bookingManagement.startRide
      }
    >
      <Box
        sx={{
          //   padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack gap={"12px"}>
          {isPendingChargesInPreviousBookings ? (
            <Stack alignItems="center" padding="10px 50px">
              <Typography
                variant={typographyConstants.SUBHEADING}
                textAlign="center"
              >
                {pendingChargesBookingsList?.message}
              </Typography>
              <Stack gap="10px">
                {pendingChargesBookingsList?.data?.map(
                  (item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          padding: "10px",
                          display: "flex",
                          gap: "8px",
                        }}
                        textAlign="center"
                      >
                        <Typography variant={typographyConstants.BODY}>
                          Booking {index + 1} :
                        </Typography>
                        <Typography
                          variant={typographyConstants.BODY}
                          color={colors.THEME_BLUE}
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => handleBookingNumberClick(item?.id)}
                        >
                          {item.number}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Stack>
            </Stack>
          ) : !fields.showOtp ? (
            <>
              <CustomSelect
                required
                icon={BikeIcon}
                placeholder={rentalEn.bookingManagement.selectVehicle}
                value={fields?.selectedVehicle?.registration}
                choice={updatedVehicleList()}
                defaultKey={"registration"}
                isDisabled={isVehicleSelectable}
                customLabel={(data: any) => {
                  if (isVehicleSelectable(data))
                    return `${data?.registration} (${rentalEn.VehicleManagement.assigned})`;
                  return data?.registration;
                }}
                handleChange={(vehicle: any) => {
                  handleChange("selectedVehicle", vehicle);
                  setFields((prevFields) => ({
                    ...prevFields,
                    odometerReading: "",
                  }));
                }}
              />
              <Input
                placeholder={rentalEn?.bookingManagement?.odometerReading}
                value={fields?.odometerReading}
                iconStart={OdometerIcon}
                onChange={(e: any) => {
                  if (
                    isNumericString(e.target.value) &&
                    e.target.value.length < 8
                  )
                    handleChange("odometerReading", e.target.value);
                }}
                helperText={errorFields.odometerReading}
              />
              {isLateStart && !isAssignVehicle ? (
                <>
                  {/* need to add check to list only admin delay reasons */}
                  <CustomSelect
                    required
                    placeholder={
                      rentalEn.bookingManagement.selectRideDelayReason
                    }
                    value={fields.selectedDelayReason.description}
                    choice={reasonDetails}
                    defaultKey={"description"}
                    handleChange={(reason: any) => {
                      handleChange("selectedDelayReason", reason);
                    }}
                  />
                  {fields?.selectedDelayReason?.details?.remarkRequired ? (
                    <TextAreaInput
                      placeholder={
                        rentalEn?.bookingManagement?.enterRideDelayReason
                      }
                      multiline
                      minRows={3}
                      onChange={(e: any) => {
                        handleChange("remark", e.target.value);
                      }}
                    />
                  ) : null}
                </>
              ) : null}
              {operatorConfigData?.iotMandatoryOnStartRide && (
                <>
                  {fields?.selectedVehicle?.registration ? (
                    <IotInfoCard
                      handlePingDevice={handlePingDevice}
                      pingDeviceData={pingDeviceData}
                      loading={pingDeviceLoader}
                    />
                  ) : null}
                </>
              )}

              {!isAssignVehicle ? (
                <CustomButton
                  label={
                    isAssignVehicle
                      ? rentalEn?.bookingDetails?.assignVehicle
                      : rentalEn?.bookingManagement?.startRide
                  }
                  variant="contained"
                  onClick={handleStartRide}
                  disabled={
                    !fields?.selectedVehicle?.registration ||
                    !fields.odometerReading ||
                    !parseInt(fields.odometerReading) ||
                    isDelayedReasonRequired
                  }
                  sx={{ width: 1, marginTop: "4px" }}
                  loading={onLoad}
                />
              ) : (
                <CustomButton
                  label={rentalEn?.bookingDetails?.assignVehicle}
                  variant="contained"
                  onClick={handleStartRide}
                  disabled={
                    !fields?.selectedVehicle?.registration ||
                    !fields.odometerReading
                  }
                  sx={{ width: 1, marginTop: "4px" }}
                />
              )}
            </>
          ) : (
            <Stack gap={"18px"}>
              <Stack gap={"18px"}>
                <Typography
                  variant={typographyConstants.HEADING}
                  sx={{ padding: 0, alignSelf: "center" }}
                >
                  {rentalEn.bookingManagement.startRideCode}
                </Typography>
                <Typography
                  variant={typographyConstants.BODY}
                  sx={{ padding: 0, alignSelf: "center" }}
                >
                  Start ride code sent to{" "}
                  <b>{`+91${bookingDetails?.customerDetails?.mobileNumber}`}</b>
                </Typography>
              </Stack>
              <OTPField
                separator={<span> </span>}
                onChange={setOtp}
                value={otp}
                length={4}
              />
              <CustomButton
                label={rentalEn?.bookingManagement?.verifyOtp}
                variant="contained"
                onClick={verifyOtp}
                disabled={otp.length !== 4}
                sx={{ width: 1 }}
                loading={onLoad}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </CustomModal>
  );
};

export default StartRideModal;
