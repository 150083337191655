import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Button2 } from "../../components/button";
import { BasicDatePicker } from "../../components/datePicker";
import { BasicSelect } from "../../components/dropDown";
import { PieChart } from "../../components/PieChart";
import { SimpleChart } from "../../components/SimpleChart";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import { useEffect, useRef, useState } from "react";
import "./style.css";
import { actions } from "../../redux";
import MultiSelectPicker from "../../components/multiSelectPicker";
import { useParams } from "react-router-dom";
import moment from "moment";
import LineChart from "../../components/lineChart";
import { CSVLink } from "react-csv";
import { exportXl } from "../../utils/helper";
import DownloadButtons from "../../components/downloadButtons";

function CouponStats() {
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { allVehicleData } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const {
    couponUsesById,
    couponRevenueById,
    couponRevenueCSVDownloadById,
    couponRevenueExcelDownloadById,
    downloadRefresh,
  } = useSelector((state: RootState) => state.couponReducer);
  const dispatch = useDispatch();
  const params = useParams();
  const { viewStats, global } = locale;
  const [couponUsageData, setCouponUsageData] = useState({
    labels: "",
    datasets: [],
  });
  const [couponRevenueData, setCouponRevenueData] = useState<any>();
  const [value, setValue] = useState<string>("ModelWise");
  const [value1, setValue1] = useState<string>("All");
  const [selectedOptionModels, setSelectedOptionModels] = useState<any[]>([]);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const couponStatsData = [
    { label: viewStats.Custom, value: 1 },
    { label: viewStats.Monthly, value: 2 },
    { label: viewStats.Weekly, value: 3 },
  ];
  const [selectedCouponStatsType, setSelectedCouponStatsType] = useState({
    label: viewStats.Monthly,
    value: 2,
  });
  const [startDate, setStartDate] = useState<any>(
    moment().subtract(1, "months")
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [checked, setIsChecked] = useState([true, false]);
  useEffect(() => {

    dispatch(
      actions.vehicleData({
        type: "VehicleModel",
        id: 0,
      })
    );

    dispatch(
      actions.getCouponUsesById({
        id: params.id,
        model: [],
        monthly: 1,
        weekly: 0,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      actions.getCouponRevenueById({
        id: params.id,
        model: [],
        monthly: 1,
        weekly: 0,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);
  useEffect(() => {
    var data_example = couponUsesById?.data?.map((item: any) => {
      var dynamicColors = function () {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };
      var item1 = item;
      item1.backgroundColor = dynamicColors();
      return item1;
    });
    setCouponUsageData({
      labels: couponUsesById?.x_labels,
      datasets: data_example,
    });
  }, [couponUsesById]);
  useEffect(() => {
    var Data = {
      labels: [viewStats.TotalRevenue, viewStats.TotalDiscount],
      data: [
        parseFloat(couponRevenueById.total_revenue ?? 0).toFixed(2),
        parseFloat(couponRevenueById.total_discount ?? 0).toFixed(2),
      ],
    };

    setCouponRevenueData(Data);
  }, [couponRevenueById]);

  const search = () => {
    let model_ids: any = [];
    if (selectedOptionModels?.length > 0) {
      selectedOptionModels.map((i: any) => {
        model_ids.push(i.value);
      });
    }
    if (value === "ModelWise") {
      dispatch(
        actions.getCouponUsesById({
          id: params.id,
          model: model_ids,
          monthly: selectedCouponStatsType?.value == 2 ? 1 : 0,
          weekly: selectedCouponStatsType?.value == 3 ? 1 : 0,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        actions.getCouponRevenueById({
          id: params.id,
          model: model_ids,
          monthly: selectedCouponStatsType?.value == 2 ? 1 : 0,
          weekly: selectedCouponStatsType?.value == 3 ? 1 : 0,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        })
      );
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangePicker = (tag: any, value: any) => {
    switch (tag) {
      case "model":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            let arr: any = [];
            allVehicleData.map((d: any) => {
              arr.push({
                label: d?.model_name,
                value: d.id,
              });
            });
            setSelectedOptionModels(arr);
          } else {
            setSelectedOptionModels(value);
          }
        } else {
          setSelectedOptionModels(value);
        }
        break;
      default:
    }
  };
  const renderMultiPickerOptions = (tag: any) => {
    switch (tag) {
      case "Models":
        let vehicles;
        vehicles = allVehicleData?.map((d: any) => ({
          value: d.id,
          label: d.model_name,
        }));
        // vehicles.unshift({
        //   value: 0,
        //   label: viewStats.All,
        // });
        return vehicles;
      default:
        break;
    }
  };

  const downloadCall = () => {
    setTimeout(() => {
      let model_ids: any = [];
      if (selectedOptionModels?.length > 0) {
        selectedOptionModels.map((i: any) => {
          model_ids.push(i.value);
        });
      }
      dispatch(
        actions.getCouponRevenueDownloadById({
          id: params.id,
          model: model_ids,
          monthly: selectedCouponStatsType?.value == 2 ? 1 : 0,
          weekly: selectedCouponStatsType?.value == 3 ? 1 : 0,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          download_type: checked[0] ? 1 : 0,
        })
      );
    }, 250);
    csvLink?.current?.link?.click();
  };
  useEffect(() => {
    if (downloadRefresh) {
      if (checked[0] && couponRevenueCSVDownloadById) {
        csvLink?.current?.link?.click();
      } else if (couponRevenueExcelDownloadById?.length > 0) {
        exportXl(couponRevenueExcelDownloadById, "Coupon Reports");
      }
    }
  }, [couponRevenueCSVDownloadById, couponRevenueExcelDownloadById]);

  return (
    <div className={`container pd-20 ${isDesktopOrTablet ? "" : "pdb-25"}`}>
      <Heading title={global.Statistics} />

      <FormControl style={{ marginLeft: "15px" }}>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          onChange={handleRadioChange}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="ModelWise"
            control={<Radio />}
            label={viewStats.ModelWise}
            className="radio-button"
          />
          <FormControlLabel
            value="Revenue"
            control={<Radio />}
            label={global.Revenue}
          />
        </RadioGroup>
      </FormControl>

      <Box
        className={isDesktopOrTablet ? "mt-25" : "mt-5"}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          bgcolor: "background.paper",
          justifyContent: isDesktopOrTablet ? "start" : "center",
          marginLeft: "15px",
        }}
      >
        {/* <Input2
          width={300}
          select
          //   readOnly={props.viewRole ? true : false}
          placeholder={global.Unit}
          choice={couponStatsData}
          required={true}
          name="time"
          defaultKey="label"
          heading={"Select Type"}
          handleChange={(type: any) => setSelectedCouponStatsType(type)}
          value={selectedCouponStatsType["label"]}
        /> */}

        {/* <MultiSelectPicker
          value={selectedCouponStatsType}
          onChange={(type: any) => setSelectedCouponStatsType(type)}
          options={couponStatsData}
          isMulti={false}
          placeholder={viewStats.SelectType}
          menuPlacement="bottom"
        /> */}

        <BasicSelect
          heading={global.OrderBy}
          selected2={selectedCouponStatsType["label"]}
          defaultKey={"label"}
          placeholder={global.OrderBy}
          data={couponStatsData}
          width={isDesktopOrTablet ? "250px" : "320px"}
          style={{
            marginRight: "25px",
            marginTop: "20px",
            marginBottom: "30px",
          }}
          handleChange={(value: any) => {
            setSelectedCouponStatsType(value);
          }}
        />

        <BasicDatePicker
          small
          className={isDesktopOrTablet ? "mr-25 mt-20" : "mt-10"}
          dateValue={startDate}
          disabled={selectedCouponStatsType?.value != 1 ? true : false}
          setDateValueFunc={(val: any) => setStartDate(val)}
        />

        <BasicDatePicker
          small
          className={isDesktopOrTablet ? "mr-25 mt-20" : "mt-10"}
          dateValue={endDate}
          disabled={selectedCouponStatsType?.value != 1 ? true : false}
          setDateValueFunc={(val: any) => setEndDate(val)}
        />
        {/* <Grid style={{ marginTop: "50px" }} /> */}
        <MultiSelectPicker
          value={selectedOptionModels}
          onChange={(event: any) => handleChangePicker("model", event)}
          options={renderMultiPickerOptions("Models")}
          isMulti={true}
          title={viewStats.ApplicableModels}
          placeholder={viewStats.SelectModels}
          menuPlacement={"bottom"}
        />
        <Button2
          buttonContainerClass={`mt-25 ${isDesktopOrTablet ? "" : "search-button"
            }`}
          onClick={() => search()}
          title={global.Search}
        />
      </Box>

      {value === viewStats.ModelWisee ? (
        <>
          <Heading title={viewStats.ModelUtilization} className="mt-50" />
          <LineChart data={couponUsageData} />
        </>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            columns={3}
            className="mt-50"
          >
            <Grid item xs="auto" alignSelf="flex-start">
              <Heading title={viewStats.CouponStatistics} className="mt-50" />
            </Grid>
            <Grid item xs="auto" alignSelf="center">
              <PieChart
                labels={couponRevenueData.labels}
                data={couponRevenueData.data}
              />
            </Grid>
            <Grid item xs="auto" alignSelf="flex-start">
              <Typography
                style={{
                  fontFamily: "EuclidCircularB-Regular",
                  color: "rgb(123, 130, 137)",
                  fontSize: "12px",
                  paddingBottom: "2px",
                }}
                variant="body2"
              >
                {global.Stats}
              </Typography>
              <DownloadButtons
                data={
                  checked[0]
                    ? couponRevenueCSVDownloadById
                    : couponRevenueExcelDownloadById
                }
                filename={
                  checked[0] ? "CouponRevenue.csv" : "CouponRevenue.xlsx"
                }
                heading={viewStats.CouponStatistics}
                csvLink={csvLink}
                checked={checked}
                onClick={() => downloadCall()}
                setIsChecked={setIsChecked}
              />
            </Grid>
          </Grid>
          <SimpleChart
            heading={[global.Income, global.Amount]}
            labels={[viewStats.TotalRevenue, viewStats.TotalDiscount]}
            data={[
              parseFloat(couponRevenueById.total_revenue ?? 0).toFixed(2),
              parseFloat(couponRevenueById.total_discount ?? 0).toFixed(2),
            ]}
          />
        </>
      )}
    </div>
  );
}

export default CouponStats;
