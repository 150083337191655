import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { colors } from "../themes/colors";
import { Link } from "react-router-dom";

export default function BreadCrumbs(props: any) {
    const breadcrumb = [
        <Link
            style={{
                fontFamily: "EuclidCircularB-Regular",
                fontSize: "17px",
                color: colors.dark_gray,
            }}
            // underline="none"
            // variant="body1"
            key="1"
            color={colors.dark_gray}
            replace
            to={props?.path}
        >
            {props?.firstLabel}
        </Link>,
        <Typography
            style={{
                fontFamily: "EuclidCircularB-Regular",
                fontSize: "16px",
                fontWeight: "bold",
            }}
            variant="body1"
            key="2"
            color={colors.dark_gray}
        >
            {props?.secondLabel}
        </Typography>,
        <Typography
            style={{
                fontFamily: "EuclidCircularB-Regular",
                fontSize: "16px",
                fontWeight: "bold",
            }}
            variant="body1"
            key="2"
            color={colors.dark_gray}
        >
            {props?.thirdLabel}
        </Typography>,
    ];

    return (
        <nav aria-label="breadcrumb" style={{ marginTop: 5 }}>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link
                        key="1"
                        color={colors.dark_gray}
                        replace
                        to={props?.path}
                    >
                        {props?.firstLabel}
                    </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    {props?.secondLabel}
                </li>
            </ol>
        </nav>
    )

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumb}
            </Breadcrumbs>
        </Stack>
    );
}
