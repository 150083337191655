import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const VdAccordion = (props: any) => {
  const { bodyBackground = "#E2F5ED" } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
      }}
    >
      <Accordion
        sx={{
          // backgroundColor: "#F7F7F7",
          position: "relative",
          background: { bodyBackground },
          boxShadow: "none",
          border: "1px solid lightgray",
        }}
        // expanded={false}
        disabled={props?.disabled ?? false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ marginTop: "20px" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            borderRadius: "16px",
            background: "#fff",
            borderBottom:
              "1px solid lightgray" /* border: "1px solid lightgray" */,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0",
              padding: "10px 40px",
              height: "21px",
              borderRadius: "16px 0px",
              backgroundColor: "#053C3D",
              left: "0",
              display: "flex",
              justifyContent: "center",
              color: "#fff",
              fontSize: "12px",
              alignItems: "center",
              width: "190px",
            }}
          >
            {props?.title}
          </Box>{" "}
          <Box sx={{ marginTop: "20px", width: "100%" }}>
            {props.children?.length > 1
              ? props.children?.map((child: any) => {
                  if (child.key === "heading") {
                    return child;
                  }
                })
              : props.children}
          </Box>
          {/* {props.children}  */}
        </AccordionSummary>
        <AccordionDetails>
          {""}
          {props.children?.length > 1
            ? props.children?.map((child: any) => {
                if (child.key === "body") {
                  return child;
                }
              })
            : props.children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default VdAccordion;
