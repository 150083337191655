import { isLoggedIn } from './../../actions/newAuthActions/index';
import en from "../../../locale/en.json";
import {
  AUTH_ACTIONS,
  NEW_AUTH_ACTIONS,
  STATISTICS,
  CSV_DASHBOARD_ACTIONS,
} from "../../actions/actionTypes";
import {
  dashboardInterface,
  dashboardActionClickInterface,
} from "../actionInterfaces";
interface initialStateInterfaceViewCalendar {
  DashboardViewCalendarData: Array<any>;
  payload: any;
  loading: boolean;
  totalSummaryLoading: boolean;
  DashboardViewCalendarError: any;
  DashboardDisplayTableData: Array<any>;
  DashboardDisplayError: any;
  DashboardActionClickData: any;
  DashboardActionClickError: any;
  vehicleUtilizationDates: any;
  vehicleUtlizationData: any;
  paymentGraphData: any;
  revenueGraphData: any;
  paymentGraphDownloadData: any;
  paymentGraphExcelDownloadData: any;
  paymentGraphDateWiseExcelDownloadData: any;
  paymentGraphDateWiseDownloadData: any;
  revenueGraphExcelDownloadData: any;
  revenueGraphDownloadData: any;
  dashboardCsvDownload: any;
  dashboardCsvDownloadError: any;
  csvStaticLoader: boolean;
  downloadRefresh: boolean;
  downloadRefreshRevenue: boolean;
  allStatisticsData: any;
  allStatisticsDataDownload: any;
  isDownloadStats: boolean;
  isBookingApprovedRejected: boolean;
}

const initialState: initialStateInterfaceViewCalendar = {
  payload: {},
  loading: false,
  totalSummaryLoading: false,
  DashboardViewCalendarError: {},
  DashboardViewCalendarData: [],
  DashboardDisplayError: null,
  DashboardDisplayTableData: [],
  DashboardActionClickError: {},
  DashboardActionClickData: {},
  vehicleUtilizationDates: null,
  vehicleUtlizationData: null,
  paymentGraphData: null,
  revenueGraphData: null,
  paymentGraphDownloadData: null,
  paymentGraphExcelDownloadData: null,
  paymentGraphDateWiseExcelDownloadData: null,
  paymentGraphDateWiseDownloadData: null,
  revenueGraphExcelDownloadData: null,
  revenueGraphDownloadData: null,
  dashboardCsvDownload: null,
  dashboardCsvDownloadError: null,
  csvStaticLoader: false,
  downloadRefresh: false,
  downloadRefreshRevenue: false,
  allStatisticsData: null,
  allStatisticsDataDownload: [],
  isDownloadStats: false,
  isBookingApprovedRejected : false
};

const dashboardReducer = (
  state = initialState,
  action: dashboardInterface | dashboardActionClickInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case AUTH_ACTIONS.DASHBOARD_STATIC_REQUESTED:
      return {
        ...state,
        loading: true,
        totalSummaryLoading: true,
        
        

      };
    case AUTH_ACTIONS.DASHBOARD_STATIC_SUCCEEDED:
      return {
        ...state,
        dashboardStats: payload?.dashboardStats,
        loading: false,
        totalSummaryLoading: false,
       
        
      };
    case AUTH_ACTIONS.DASHBOARD_STATIC_FAILED:
      return {
        ...state,
        loading: false,
        totalSummaryLoading: false,
      };
    case AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_REQUESTED:
      return {
        ...state,
        DashboardViewCalendarError: null,
        loading: true,
      };
    case AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_SUCCEEDED:
      let tempDataOne = payload?.Result;
      state.DashboardViewCalendarData = tempDataOne as any;
      return {
        ...state,
        DashboardViewCalendarError: null,
        loading: false,
      };
    case AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_FAILED:
      return {
        ...state,
        DashboardViewCalendarData: [],
        // DashboardViewCalendarError: payload.error,
        loading: false,
      };

    case AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_REQUESTED:
      return {
        ...state,
        DashboardDisplayError: null,
        loading: true,
        csvStaticLoader: true,
        isBookingApprovedRejected :false,
        taskAssigned :false
      };
    case AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_SUCCEEDED:
      return {
        ...state,
        ...payload,
        totalItems: payload?.Result,
        DashboardDisplayTableData: payload?.Result?.data,
        DashboardDisplayError: null,
        loading: false,
        csvStaticLoader: false,
      };
    case AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_FAILED:
      return {
        ...state,
        DashboardDisplayTableData: [],
        // DashboardDisplayError: payload.error,
        loading: false,
        csvStaticLoader: false,
      };

    case CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_REQUESTED:
      return {
        ...state,
        dashboardCsvDownloadError: null,
        loading: true,
        csvStaticLoader: true,
      };
    case CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardCsvDownload: payload.itemData,
        dashboardCsvDownloadError: null,
        loading: false,
        csvStaticLoader: false,
      };
    case CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_FAILED:
      return {
        ...state,
        dashboardCsvDownload: "",
        // dashboardCsvDownloadError: payload.error,
        loading: false,
        csvStaticLoader: false,
      };

    case AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_REQUESTED:
      return {
        ...state,
        DashboardActionClickError: null,
        loading: true,
        isBookingApprovedRejected :false
      };
    case AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_SUCCEEDED:
      let tempData = payload.modefiedData;
      const array = prevState.DashboardDisplayTableData?.map((item, index) => {
        if (tempData?.id === item.id) {
          return { ...item, ...tempData };
        } else {
          return item;
        }
      });
      return {
        ...state,
        DashboardActionClickError: null,
        DashboardDisplayTableData: array,
        loading: false,
        isBookingApprovedRejected :true
      };
    case AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_FAILED:
      return {
        ...state,
        DashboardActionClickData: {},
        // DashboardActionClickError: payload.error,
        loading: false,
        isBookingApprovedRejected :false
      };
    case STATISTICS.VEHICLE_UTILIZATION_DATES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case STATISTICS.VEHICLE_UTILIZATION_DATES_SUCCESS:
      return {
        ...state,
        vehicleUtilizationDates: payload?.Result,
        loading: false,
      };
    case STATISTICS.VEHICLE_UTILIZATION_DATES_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        loading: false,
      };
    case STATISTICS.GET_VEHICLE_UTILIZATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case STATISTICS.GET_VEHICLE_UTILIZATION_SUCCESS:
      return {
        ...state,
        vehicleUtilizationData: setGraphsData("model", payload?.Result),
        loading: false,
      };
    case STATISTICS.GET_VEHICLE_UTILIZATION_FAILED:
      return {
        ...state,
        vehicleUtilizationData: [],
        loading: false,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_SUCCESS:
      return {
        ...state,
        paymentGraphData: setGraphsData("payment", payload?.Result),
        loading: false,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        paymentGraphData: [],
        loading: false,
      };

    case STATISTICS.GET_REVENUE_GRAPH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case STATISTICS.GET_REVENUE_GRAPH_SUCCESS:
      return {
        ...state,
        revenueGraphData: setGraphsData("revenue", payload?.Result),
        loading: false,
      };
    case STATISTICS.GET_REVENUE_GRAPH_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        revenueGraphData: [],
        loading: false,
      };

    case STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        downloadRefresh: false,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_SUCCESS:
      return {
        ...state,
        paymentGraphDownloadData: payload,
        paymentGraphExcelDownloadData: payload?.Result,
        loading: false,
        downloadRefresh: true,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        loading: false,
        downloadRefresh: false,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        downloadRefresh: false,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        paymentGraphDateWiseDownloadData: payload,
        paymentGraphDateWiseExcelDownloadData: payload?.Result,
        loading: false,
        downloadRefresh: true,
      };
    case STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        loading: false,
        downloadRefresh: false,
      };
    case STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        downloadRefreshRevenue: false,
      };
    case STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_SUCCESS:
      return {
        ...state,
        revenueGraphDownloadData: payload,
        revenueGraphExcelDownloadData: payload?.Result,
        loading: false,
        downloadRefreshRevenue: true,
      };
    case STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_FAILED:
      return {
        ...state,
        // errorMessage: payload?.errorMessage,
        loading: false,
        downloadRefreshRevenue: false,
      };
    case STATISTICS.GET_ALL_STATISTICS_DATA_FAILED:
      return {
        ...state,
        isDownloadStats: false,
      };
    case STATISTICS.GET_ALL_STATISTICS_DATA_REQUESTED:
      return {
        ...state,
        isDownloadStats: false,
      };
    case STATISTICS.GET_ALL_STATISTICS_DATA_SUCCESS:
      let obj: any = {};
      if (!payload?.isDownload) {
        obj["allStatisticsData"] = payload.allStatisticsData;
      } else {
        obj["allStatisticsDataDownload"] = payload?.allStatisticsData?.data;
        obj["isDownloadStats"] = true;
      }
      return {
        ...state,
        ...obj,
      };


      // case NEW_AUTH_ACTIONS.CHECK_ISLOGIN:
      //   return {
      //     ...state,
      //     isLoggedIn: false
      //   }

    default:
      return { ...prevState };
  }
};

const setGraphsData = (tag: any, data: any) => {
  switch (tag) {
    case "model":
      let labels: any = [];
      let graphData: any = [];
      for (let i = 0; i < data.length; i++) {
        labels.push(...Object.keys(data[i]));
        graphData.push(...Object.values(data[i]));
      }
      return { labels, graphData };
    case "payment":
      let data1: any = [];
      let lab1: any = [];
      for (let i = 0; i < data?.bookingPaymentByMode.length; i++) {
        lab1.push(...Object.keys(data?.bookingPaymentByMode[i]));
        data1.push(...Object.values(data?.bookingPaymentByMode[i]));
      }

      let data2: any = [];
      let lab2: any = [];
      for (let i = 0; i < data?.bookingPaymentByQrCode.length; i++) {
        lab2.push(...Object.keys(data?.bookingPaymentByQrCode[i]));
        data2.push(...Object.values(data?.bookingPaymentByQrCode[i]));
      }

      let data3: any = [];
      let lab3: any = [];
      for (let i = 0; i < data?.bookingPaymentByOnline.length; i++) {
        lab3.push(...Object.keys(data?.bookingPaymentByOnline[i]));
        data3.push(...Object.values(data?.bookingPaymentByOnline[i]));
      }

      let data4: any = [];
      let lab4: any = [];
      for (let i = 0; i < data?.bookingPaymentByOffline.length; i++) {
        lab4.push(...Object.keys(data?.bookingPaymentByOffline[i]));
        data4.push(...Object.values(data?.bookingPaymentByOffline[i]));
      }

      let title_heading = "Total Revenue";
      let total_sum = data1.reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
      return {
        data1,
        data2,
        data3,
        data4,
        lab1,
        lab2,
        lab3,
        lab4,
        title_heading,
        total_sum,
      };
    case "revenue":
      let dataPar1: any = [];
      let labPar1: any = [];
      for (let i = 0; i < data?.bookingAmountDetails.length; i++) {
        labPar1.push(...Object.keys(data?.bookingAmountDetails[i]));
        dataPar1.push(...Object.values(data?.bookingAmountDetails[i]));
      }

      // this.setState({
      //   partition_heading: labPar1[0],
      //   partition_amount: dataPar1[0],
      // });
      let partition_heading = labPar1[0];
      let partition_sum = dataPar1[0];
      labPar1.splice(0, 1);
      dataPar1.splice(0, 1);
      //Amount Paid
      let dataPar2: any = [];
      let labPar2: any = [];
      for (let i = 0; i < data?.amount_paid.length; i++) {
        labPar2.push(...Object.keys(data?.amount_paid[i]));
        dataPar2.push(...Object.values(data?.amount_paid[i]));
      }


      //Amount Left
      let dataPar3: any = [];
      let labPar3: any = [];
      for (let i = 0; i < data?.amount_pending.length; i++) {
        labPar3.push(...Object.keys(data?.amount_pending[i]));
        dataPar3.push(...Object.values(data?.amount_pending[i]));
      }


      //Extra headings
      let dataPar4: any = [];
      let labPar4: any = [];
      for (let i = 0; i < data?.total_amount.length; i++) {
        labPar4.push(...Object.keys(data?.total_amount[i]));
        dataPar4.push(...Object.values(data?.total_amount[i]));
      }

      let total_booking_amount = dataPar4[0];
      let total_extra_charge = dataPar4[1];
      return {
        labPar1,
        labPar4,
        labPar3,
        labPar2,
        dataPar1,
        dataPar2,
        dataPar3,
        dataPar4,
        total_booking_amount,
        total_extra_charge,
        partition_heading,
        partition_sum,
      };
    default:
  }
};

export default dashboardReducer;
