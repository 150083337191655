import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Stack, Typography, useMediaQuery } from "@mui/material";

interface loadingSpinder {
  loading?: boolean;
  size?: number;
  title?: string;
}

const Spinner = ({ loading, size, title }: loadingSpinder) => {
  const isDesktopOrTablet = useMediaQuery("max-width:767px");

  return (
    <div>
      {loading ? (
        <Stack marginTop={isDesktopOrTablet ? "40%" : "20%"} alignItems="center">
          <CircularProgress size={size}/>
          {title && <Typography>{title}</Typography>}
        </Stack>
      ) : null}
    </div>
  );
};

export default Spinner;