import { gql } from "@apollo/client";
import { client } from "../config";

const GET_DASHBOARD_STATS_QUERY = gql`
  query GetStats($statsType: [StatsType], $filters: Filters) {
    getStats(statsType: $statsType, filters: $filters) {
      BookingStats {
        refundedAmount
        revenueCollected
        completedBooking
        pendingBooking
        ongoingBooking
        upcomingBooking
        endedBooking
        cancelledBooking
        total
      }
      VehicleStatusStats {
        available
        underMaintenance
        engaged
        deleted
        blocked
        recovery
        total
      }
      UserStats {
        kycDone
        kycPending
        active
        total
      }
    }
  }
`;

const getGqlDashboardStatsService = async (payload: any) => {
  const results = await client.query({
    query: GET_DASHBOARD_STATS_QUERY,
    variables: {
      ...payload,
    },
  });
  return results;
};

const GET_STATICS_DETAILS_QUERY = gql`
  query GetStatDetails(
    $statDetailType: StatDetailType!
    $filters: Filters
    $downloadType: DownloadType
  ) {
    getStatDetails(
      statDetailType: $statDetailType
      filters: $filters
      downloadType: $downloadType
    ) {
      paidStats {
        offlinePaid
        onlinePaid
        totalAmountPaid
      }
      revenueStats {
        totalAmountPending
        totalAmountPaid
        totalAmount
      }
      error
      data
    }
  }
`;

const getGqlStatisticsService = async (payload: any) => {
  const results = await client.query({
    query: GET_STATICS_DETAILS_QUERY,
    variables: {
      ...payload,
    },
  });
  return results;
};

export default { getGqlDashboardStatsService, getGqlStatisticsService };
