import DoNotTouchOutlinedIcon from "@mui/icons-material/DoNotTouchOutlined";
import { Box, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import React from "react";
import {
  PaymentInitiatedIcon,
  PendingClockIcon,
  SuccessTick,
  WavingHandIcon,
} from "../../../../../freedo-x-src/constantsX/exportImagesX";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesDispalyStatus,
  BookingChargesStatus,
  BookingStatusEnum,
  DisplayChargesNameTypes,
  PaymentJourneyStatus,
  chargesNameTypes,
  typographyConstants,
} from "../../../../constants/constants";
import { getFormattedINR } from "../../../../utils/helper";
import CustomHoverPopover from "../../../../../sharedComponents/molecules/Popover/HoverPopover";
import en from "../../../../locale/rental-en.json";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export interface DetailsTypographyProps {
  heading: string;
  value: string;
  link?: string;
  loading?: boolean;
  render?: JSX.Element;
  clickHandler?: any;
  elementClassList?: string;
  redirectLink?: string;
  visitStepData?: {
    value: string;
    step: string | number;
  };
  valueStyle?: any;
  color?: string;
}

// Charges Sub Type Filter Choices
const chargesSubtypeFilterArr = [
  {
    name: "",
    displayName: "All",
  },
  {
    name: chargesNameTypes.TNC_VIOLATION,
    displayName: DisplayChargesNameTypes.TNC_VIOLATION,
  },
  {
    name: chargesNameTypes.TRAFFIC_RULE_VIOLATION,
    displayName: DisplayChargesNameTypes.TRAFFIC_RULE_VIOLATION,
  },
  {
    name: chargesNameTypes.OTHERS,
    displayName: DisplayChargesNameTypes.OTHERS,
  },
  {
    name: chargesNameTypes.PENALTY_CHARGES,
    displayName: DisplayChargesNameTypes.PENALTY_CHARGES,
  },
];

const columnStatusUI = (row: GridRenderCellParams): JSX.Element => {
  let rowData = row?.row;
  switch (rowData?.status) {
    case BookingChargesStatus.PAID:
      return (
        <>
          <img src={SuccessTick} height={"20px"} width={"auto"} />
          <Typography
            variant="caption"
            color={colors.secondary_green}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PAID}
          </Typography>
        </>
      );

    case BookingChargesStatus.PENDING:
      return (
        <>
          <img src={PendingClockIcon} width={"auto"} height={"23px"} />
          <Typography
            variant="caption"
            color={colors.primary_warning}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PENDING}
          </Typography>
        </>
      );

    case BookingChargesStatus.WAIVED_OFF:
      return (
        <>
          <img src={WavingHandIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontWeight={500}
            fontSize={"14px"}
          >
            {" "}
            {BookingChargesDispalyStatus.WAIVED_OFF}
          </Typography>
        </>
      );
    case BookingChargesStatus.PARTIALLY_PAID:
      return (
        <>
          <img src={WavingHandIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontWeight={500}
            fontSize={"14px"}
          >
            {" "}
            {BookingChargesDispalyStatus.PARTIALLY_PAID}
          </Typography>
        </>
      );

    case BookingChargesStatus.PROCESSING:
      return (
        <>
          <img src={PaymentInitiatedIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PROCESSING}
          </Typography>
        </>
      );
  }
  return <></>;
};

const ExtraChargesBody = ({
  additionalChargesList,
  setSelectedRows,
  selectedRows,
  setOpenSideDrawerWF,
  setOpenSideDrawerPS,
  calculateTotal,
  setCalculateTotal,
  calculatedRemainingAmount,
  setCalculatedRemainingAmount,
  chargesFilters,
  handleChargesFilterChange,
  handleModelOpen,
  handlePaginationModelChange,
  fields,
  bookingStatus,
  viewChargesListAccess,
  payExtraChargesAccess,
  waiveOffAccess,
}: any) => {
  const totalCharges = additionalChargesList?.totalChallans;
  const totalSelectedChargesAmount = calculateTotal ?? 0;
  const totalExtraCharges = additionalChargesList?.extraCharges;
  const totalChallanAmount = additionalChargesList?.challanAmount;
  const totalAdditionalCharge = additionalChargesList?.chargesAmount;
  const totalAmount = additionalChargesList?.totalAmount;
  const selectedTotalRemainingAmount = calculatedRemainingAmount ?? 0;
  const extraCharges = additionalChargesList?.charges ?? [];

  const { additionalChargesLoader }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const getRowId = (row: any) => {
    return row?._id;
  };

  // handle charges select
  const handleSelectedChargesRow = (row: any) => {
    let total: number = 0;
    let remainingAmount: number = 0;
    const selectedCharges =
      extraCharges &&
      extraCharges.length > 0 &&
      extraCharges.filter((charge: any) => {
        if (row.includes(charge.id)) {
          total += charge.amount;
          remainingAmount += charge.pendingAmount;
        }
        return row.includes(charge?.id);
      });
    setCalculatedRemainingAmount(remainingAmount);
    setCalculateTotal(total);
    setSelectedRows(selectedCharges);
  };

  const headCells = [
    {
      field: "chargeTypeId",
      headerName: "Charge Type",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        DisplayChargesNameTypes[
          row?.chargeTypeId as keyof typeof DisplayChargesNameTypes
        ],
    },
    {
      field: "subType",
      headerName: "Charge Subtype",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
    },
    {
      field: "amount",
      headerName: "Price",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box>
            {params.row.amount ? (
              <>
                {params?.row?.paidAmount ||
                params?.row?.waiveOffDetail?.amount ? (
                  <CustomHoverPopover
                    popupContent={
                      <Stack sx={{ padding: "16px", gap: "3px" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="150px"
                        >
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            Paid
                          </Typography>
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            {getFormattedINR(params?.row?.paidAmount) ??
                              getFormattedINR(0)}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            Waived Off
                          </Typography>
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            {getFormattedINR(
                              params?.row?.waiveOffDetail?.amount
                            ) ?? getFormattedINR(0)}
                          </Typography>
                        </Stack>
                      </Stack>
                    }
                    hoverContent={
                      <Box>
                        {!params.row.waiveOff && (
                          <DoNotTouchOutlinedIcon
                            sx={{ color: "red", fontSize: "1rem" }}
                          />
                        )}
                        <span className="table-cell-trucate">
                          {getFormattedINR(params.row.amount)}
                        </span>
                      </Box>
                    }
                  />
                ) : (
                  <>
                    {!params.row.waiveOff && (
                      <DoNotTouchOutlinedIcon
                        sx={{ color: "red", fontSize: "1rem" }}
                      />
                    )}
                    <span className="table-cell-trucate">
                      {getFormattedINR(params.row.amount)}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>N/A</>
            )}
          </Box>
        );
      },
    },
    {
      field: "remainingAmount",
      headerName: "Remaining Amount",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      valueGetter: (value: any, row: any) =>
        getFormattedINR(row?.remainingAmount) ?? getFormattedINR(0),
    },
    {
      field: "remark",
      headerName: "Remark",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      renderCell: (parms: GridRenderCellParams<any>) => columnStatusUI(parms),
    },
  ];

  // DATA TABLE ROWS
  const ExtraChargesRows =
    extraCharges?.length > 0
      ? extraCharges?.map((record: any, index: number) => {
          return {
            id: record?.id,
            waiveOff: record?.chargeTypeDetail?.waiveOff,
            chargeTypeId: record?.chargeTypeDetail?.type,
            subType: record?.chargeTypeDetail?.name,
            amount: record?.amount,
            remainingAmount: record?.pendingAmount,
            remark: record?.remarks ?? "N/A",
            status: record?.status,
            paidAmount: record?.paidAmount,
            pendingAmount: record?.pendingAmount,
            waiveOffDetail: record?.waiveOffDetail,
          };
        })
      : [];

  let rowCount = extraCharges?.length > 0 ? 100 : 0;

  return (
    <>
      {bookingStatus !== BookingStatusEnum.PENDING ? (
        <>
          <Stack gap="2vh">
            {/* Summary */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              padding="10px"
              sx={{
                background: "#E8F2F8",
                border: "1px solid #00000029",
                borderRadius: "8px",
              }}
            >
              <Stack width="25%">
                <Typography variant="caption">
                  {en.bookingDetails.additionalCharges.TOTAL_CHALLANS}
                </Typography>
                <Typography variant="caption">{totalCharges ?? 0}</Typography>
              </Stack>
              <Stack width="25%">
                <Typography variant="caption">
                  {en.bookingDetails.additionalCharges.CHALLAN_AMOUNT}
                </Typography>
                <Typography variant="caption">
                  {getFormattedINR(totalChallanAmount) ?? 0}
                </Typography>
              </Stack>
              <Stack width="25%">
                <Typography variant="caption">
                  {en.bookingDetails.additionalCharges.EXTRA_CHARGES}
                </Typography>
                <Typography variant="caption">
                  {totalExtraCharges ?? 0}
                </Typography>
              </Stack>

              <Stack width="25%">
                <Typography variant="caption">
                  {en.bookingDetails.additionalCharges.ADDITIONAL_CHARGES}
                </Typography>
                <Typography variant="caption">
                  {getFormattedINR(totalAdditionalCharge) ?? 0}
                </Typography>
              </Stack>
            </Stack>

            {/* Charges Filter */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack
                direction="row"
                width={"50%"}
                alignItems={"center"}
                gap={"1vw"}
              >
                <Typography variant="caption">
                  {en.bookingDetails.additionalCharges.EXTRA_CHARGES}
                </Typography>
                {viewChargesListAccess && (
                  <CustomSelect
                    required
                    value={chargesFilters?.category?.displayName ?? ""}
                    choice={chargesSubtypeFilterArr}
                    defaultKey={"displayName"}
                    handleChange={(catergoryType: any) => {
                      handleChargesFilterChange("category", catergoryType);
                    }}
                    width={"50%"}
                    hidePlaceholder={true}
                  />
                )}
              </Stack>
              {selectedTotalRemainingAmount > 0 && (
                <Stack direction="row" gap="5px">
                  <Typography variant="caption">Total charges:</Typography>
                  <Typography variant="caption">
                    {getFormattedINR(selectedTotalRemainingAmount) ?? 0}
                  </Typography>
                </Stack>
              )}
            </Stack>

            {/* Charges table */}
            <DataTable
              autoHeight
              checkboxSelection
              rows={ExtraChargesRows}
              columns={headCells}
              headerAlign={"center"}
              getRowId={(row: any) => row.id}
              isRowSelectable={(params: GridRowParams) =>
                params?.row?.status === PaymentJourneyStatus?.PENDING ||
                params?.row?.status === PaymentJourneyStatus?.PARTIALLY_PAID
              }
              onRowSelectionModelChange={(newRowSelectionModel: any) => {
                handleSelectedChargesRow(newRowSelectionModel);
              }}
              // rowCount={additionalChargesList?.pageData?.total ?? 0}
              rowCount={rowCount}
              total={10}
              paginationMode="server"
              paginationModel={chargesFilters?.paginationModel}
              onPaginationModelChange={(paginationObj: any) => {
                handleChargesFilterChange("paginationModel", paginationObj);
              }}
              hideFooter={true}
              loading={additionalChargesLoader}
              // loading={true}
              disableColumnMenu
              disableColumnSorting
              disableRowSelectionOnClick
              hasAccess={viewChargesListAccess}
            />

            {/* Action buttons */}
            <Box
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              {viewChargesListAccess && extraCharges?.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"0.5vw"}
                    fontSize={"1rem"}
                  >
                    <DoNotTouchOutlinedIcon
                      sx={{ color: "red", fontSize: "1rem" }}
                    />{" "}
                    : Non-waivable
                  </Stack>
                  <Stack
                    justifyContent={"flex-end"}
                    direction={"row"}
                    gap="10px"
                  >
                    {waiveOffAccess && (
                      <CustomButton
                        icon={WavingHandIcon}
                        label={"Waive Off"}
                        variant={"outlined"}
                        minWidth={"176px"}
                        disabled={!selectedRows?.length}
                        onClick={() => handleModelOpen("waivedOff")}
                      />
                    )}
                    {payExtraChargesAccess && (
                      <CustomButton
                        variant={"contained"}
                        label={"Pay Selected"}
                        width={"80px"}
                        disabled={!selectedRows?.length}
                        onClick={() => handleModelOpen("paySelected")}
                      />
                    )}
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
        </>
      ) : null}
    </>
  );
};

export default React.memo(ExtraChargesBody);
