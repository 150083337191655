import { useEffect, useState } from "react";
import { STATUS, cityStatus } from "../../../../constants/constants";
import {
  convertToTimeStamp,
  dateFormat,
  getTimestampAtStartOfDay,
  validate,
} from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import en from "../../../../locale/rental-en.json";
import { checkAlfa } from "../../../../../utils/regex";
import UploadCityImages from "./uploadCityImages";
import AddServiceableCities from "./addServiceableCities";
import InputDatePicker from "../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import { colors } from "../../../../../themes/colors";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import {
  saveNewCity,
  updateCity,
} from "../../../../redux/actions/businessManagementActions";
import { getCityByState } from "../../../../redux/actions/rentalCommonAction";
import CityDetailsLoader from "./CityDetailsLoader";

const AddNewCity = ({
  cityData,
  allStatesList,
  signedUrl,
  stateBasedCities,
  saveCityLoader,
  imageLoader,
  filters,
  cityDetailsLoader,
  editAccess,
}: any) => {
  const [cityFields, setCityFields] = useState<any>({
    state: { name: "", displayName: "" },
    cityName: "",
    attachmentId: [],
    serviceavleCities: [],
    launchDate: "",
    cityStatus: STATUS.ACTIVE,
  });
  const [errorFields, setErrorFields] = useState<any>({
    state: "",
    cityName: "",
    serviceavleCities: "",
    cityStatus: "",
  });
  const [rows, setRows] = useState<any>([]);
  const serviceableCity = rows?.map((item: any) => item.name);
  const [date, setDate] = useState<any>(new Date());
  const dispatch = useDispatch();
  //edit city
  useEffect(() => {
    if (Object.keys(cityData)?.length) {
      setCityFields({
        ...cityFields,
        state: { name: cityData.state, displayName: cityData.stateDisplayname },
        cityName: cityData.displayName,
        serviceavleCities: serviceableCity,
        launchDate: dateFormat(cityData.launchDate),
        cityStatus: cityData?.status,
      });
      setDate(new Date(cityData?.launchDate));
      setRows(cityData?.selectableCities);
      if (cityData?.selectableCities) {
        dispatch(
          getCityByState({ selectableCities: cityData?.selectableCities })
        );
      }
    }
  }, [cityData]);

  useEffect(() => {
    if (cityData?.selectableCities?.length) {
      rows?.map((item: any, index: any) => {
        if (Array.isArray(stateBasedCities[index])) {
          const cityArray = stateBasedCities.find(
            (cities: any) => rows[index].stateName == cities[0].state
          );
          rows[index].cities = cityArray;
        }
      });
    }
  }, [stateBasedCities]);

  const handleChange = (field: string, value: any) => {
    if (field == "launchDate") {
      setCityFields({ ...cityFields, [field]: dateFormat(value) });
      setDate(value);
    } else {
      setCityFields({ ...cityFields, [field]: value });
      setErrorFields({ ...errorFields, [field]: "" });
    }
  };
  //Deliverable cities validation
  const validateAddCities = (cities: any): boolean => {
    let data: any = cities;
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i].name === "") {
        flag = true;
        data[i].errorName = "required";
      }
      if (data[i].stateName === "") {
        flag = true;
        data[i].errorState = "required";
      }
    }
    setRows((prev: any) => [...data]);
    return flag;
  };

  //save new city
  const handleSaveCity = () => {
    let obj = {
      state: cityFields.state,
      cityName: cityFields.cityName.trim(),
      cityStatus: cityFields.cityStatus,
    };
    let objErr = {
      state: "",
      cityName: "",
      cityStatus: "",
    };
    let error = false;

    const validatedFields = validate(obj, objErr);

    const validateServiceableCities = validateAddCities(rows);

    if (validatedFields.error || validateServiceableCities) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });

      return error;
    }
    let payload: any = {
      data: {
        data: {
          displayName: cityFields?.cityName.trim(),
          state: cityFields?.state.name,
          selectableCities: serviceableCity,
          launchDate: getTimestampAtStartOfDay(date),
          status: cityFields?.cityStatus,
        },
      },
      filters: {
        page: filters.paginationModel.page + 1,
        limit: filters.paginationModel.pageSize,
        state: filters.states.name,
        status: filters.status.name,
      },
    };
    if (signedUrl.id) {
      payload.data.data["attachmentIds"] = [signedUrl.id];
    }
    dispatch(saveNewCity({ ...payload }));
    return error;
  };

  //update city
  const handleUpdateCity = () => {
    const validateServiceableCities = validateAddCities(rows);
    let error = false;

    if (validateServiceableCities) {
      error = true;
      return error;
    }
    let payload: any = {
      data: {
        data: {
          selectableCities: serviceableCity,
          status: cityFields?.cityStatus,
        },
      },
      filters: {
        page: filters.paginationModel.page + 1,
        limit: filters.paginationModel.pageSize,
        state: filters.states.name,
        status: filters.status.name,
      },
    };
    if (signedUrl.id) {
      payload.data.data["attachmentIds"] = [signedUrl.id];
    }
    if (cityFields.launchDate !== dateFormat(cityData.launchDate)) {
      payload.data.data["launchDate"] = getTimestampAtStartOfDay(date);
    }

    dispatch(
      updateCity({
        data: {
          ...payload,
        },
        name: cityData?.name,
      })
    );
  };
  return (
    <Box>
      {cityDetailsLoader ? (
        <CityDetailsLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <CustomSelect
            required
            value={cityFields.state.displayName}
            placeholder={en.NewBusinessManagement.selectState}
            choice={
              Object.keys(cityData)?.length ? [cityFields.state] : allStatesList
            }
            defaultKey={"displayName"}
            errormessage={errorFields.state}
            handleChange={(state: any) => {
              handleChange("state", state);
            }}
            readOnly={Object.keys(cityData)?.length}
          />
          <Input
            name="cityName"
            value={cityFields.cityName}
            placeholder={en.NewBusinessManagement.cityName}
            //label="Enter City Name"
            errormessage={errorFields.cityName}
            width="100%"
            onChange={(e: any) => {
              if (checkAlfa(e.target.value)) {
                handleChange("cityName", e.target.value);
              }
            }}
            inputProps={{ readOnly: Object.keys(cityData)?.length }}
          />
          <Box>
            <UploadCityImages
              cityData={cityData}
              imageLoader={imageLoader}
              editAccess={editAccess}
            />
          </Box>
          <Box>
            <AddServiceableCities
              states={allStatesList}
              stateBasedCities={stateBasedCities}
              rows={rows}
              setRows={setRows}
              editAccess={editAccess}
            />
          </Box>
          <InputDatePicker
            placeholder={en.NewBusinessManagement.launchDate}
            name="launchDate"
            value={date}
            required
            width="100%"
            height="20px"
            onChange={(date: any) => {
              handleChange("launchDate", date);
            }}
            shouldDisableDate={(day: any) => {
              //SHOULD DISABLE BEFORE DATE
              let disabledDate = new Date();
              disabledDate.setDate(disabledDate.getDate() - 1);

              //SHOULD DISABLE AFTER 6 MONTHS DATE

              return day.valueOf() < disabledDate.valueOf();
            }}
            small
            readOnly={!editAccess}
          />
          <Box sx={{ marginBottom: "40px" }}>
            <Typography
              color={colors.text_secondary_gray}
              fontSize="12px"
              fontWeight="500"
              marginBottom="5px"
            >
              {en.NewBusinessManagement.cityStatus}
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <CustomButton
                  variant={
                    cityFields?.cityStatus == cityStatus["active"]
                      ? "contained"
                      : "outlined"
                  }
                  label={en.NewBusinessManagement.active}
                  value={cityFields?.cityStatus}
                  minWidth="50px"
                  width="120px"
                  onClick={() =>
                    handleChange("cityStatus", cityStatus["active"])
                  }
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    width: "130px",
                  }}
                  disabled={!editAccess}
                />
              </Box>
              <Box>
                <CustomButton
                  variant={
                    cityFields?.cityStatus == cityStatus["inactive"]
                      ? "contained"
                      : "outlined"
                  }
                  label={en.NewBusinessManagement.inactive}
                  value={cityFields?.cityStatus}
                  minWidth="50px"
                  width="120px"
                  onClick={() =>
                    handleChange("cityStatus", cityStatus["inactive"])
                  }
                  style={{
                    height: "30px",
                    borderRadius: "30px",
                    width: "130px",
                  }}
                  disabled={!editAccess}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ position: "fixed", bottom: 10 }}>
            {((editAccess && Object.keys(cityData).length) ||
              !Object.keys(cityData).length) && (
              <CustomButton
                loading={saveCityLoader}
                variant="contained"
                label={
                  Object.keys(cityData).length
                    ? en.NewBusinessManagement.updateCity
                    : en.NewBusinessManagement.saveCity
                }
                style={{ height: "40px", borderRadius: "30px", width: "332px" }}
                onClick={() => {
                  if (Object.keys(cityData).length) {
                    handleUpdateCity();
                  } else {
                    handleSaveCity();
                  }
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default AddNewCity;
