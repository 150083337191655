import { customAxios } from "../config";

const getCouponById = (payload: any) => {
  let route = `/getcouponsbyid/`;
  if (payload.id) route += `${payload.id}`;
  // if (params.size && !isNaN(params.size)) route += `&size=${params.size}`;
  return customAxios.get(route).catch((ex) => ex);
};

const editCoupon = (payload: any) => {
  let route = `/editcoupons`;
  // if (params.size && !isNaN(params.size)) route += `&size=${params.size}`;
  return customAxios.put(route, { ...payload }).catch((ex) => ex);
};

const addCoupon = (payload: any) => {
  let route = `/createcoupon`;
  // if (params.size && !isNaN(params.size)) route += `&size=${params.size}`;
  return customAxios.post(route, { ...payload }).catch((ex) => ex);
};

const deleteCoupon = (payload: any) => {
  let route = `/changecouponstatus`;
  // if (params.size && !isNaN(params.size)) route += `&size=${params.size}`;
  return customAxios.put(route, { ...payload }).catch((ex) => ex);
};

const getCouponUsesById = (payload: any) => {
  let models = payload.model?.length > 0 ? JSON.stringify(payload.model) : null;
  let route = `/getCouponsUsesbyid/`;
  if (payload.id) route += `${payload.id}?`;
  if (models) route += `model=${models}`;
  if (payload.monthly && !isNaN(payload.monthly))
    route += `&monthly=${payload.monthly}`;
  if (payload.weekly && !isNaN(payload.weekly))
    route += `&weekly=${payload.weekly}`;
  if (payload.weekly == 0 && payload.monthly == 0) {
    route += payload.start_date ? `&start_date=${payload.start_date}` : "";
    route += payload.end_date ? `&end_date=${payload.end_date}` : "";
  }
  return customAxios.get(route).catch((ex) => ex);
};
const getCouponRevenueById = (payload: any) => {
  let models = payload.model?.length > 0 ? JSON.stringify(payload.model) : null;
  let route = `/getCouponRevenuebyid/`;
  if (payload.id) route += `${payload.id}?`;
  if (models) route += `model=${models}`;
  if (payload.monthly && !isNaN(payload.monthly))
    route += `&monthly=${payload.monthly}`;
  if (payload.weekly && !isNaN(payload.weekly))
    route += `&weekly=${payload.weekly}`;
  if (payload.weekly == 0 && payload.monthly == 0) {
    route += payload.start_date ? `&start_date=${payload.start_date}` : "";
    route += payload.end_date ? `&end_date=${payload.end_date}` : "";
  }
  route += payload.download_type
    ? `&download_type=${payload.download_type}`
    : "";
  return customAxios.get(route).catch((ex) => ex);
};
const getCouponRevenueDownloadById = (payload: any) => {
  let models = payload.model?.length > 0 ? JSON.stringify(payload.model) : null;
  let route = `/getCouponReportbyid/`;
  if (payload.id) route += `${payload.id}?`;
  if (models) route += `model=${models}`;
  if (payload.monthly && !isNaN(payload.monthly))
    route += `&monthly=${payload.monthly}`;
  if (payload.weekly && !isNaN(payload.weekly))
    route += `&weekly=${payload.weekly}`;
  if (payload.weekly == 0 && payload.monthly == 0) {
    route += payload.start_date ? `&start_date=${payload.start_date}` : "";
    route += payload.end_date ? `&end_date=${payload.end_date}` : "";
  }
  route += `&download_type=${payload.download_type}`;
  return customAxios.get(route).catch((ex) => ex);
};

export default {
  getCouponById,
  editCoupon,
  addCoupon,
  deleteCoupon,
  getCouponUsesById,
  getCouponRevenueById,
  getCouponRevenueDownloadById,
};
