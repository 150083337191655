// Need confirmation on this file
import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Box, Container, Stack, Tooltip } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import HeadingValueTypography from "../../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import SideDrawer from "../../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import { BREADCRUMBS_RENTALS } from "../../../../../constants/activeModule";
import {
  BLOCKED_REASON,
  LOB_TYPES,
  LobStatus,
  VehicleStatus,
  blockedReasonArray,
  blockedReasonDisplayedName,
  lobStatus,
  statusColorCode,
} from "../../../../../constants/constants";
import {
  BikeIcon,
  CityInputIcon,
  SearchIcon,
  WarehouseIcon,
  changeStatusIcon,
} from "../../../../../constants/exportImages";
import rentalEn from "../../../../../locale/rental-en.json";
import { rentalActions, vehicleActions } from "../../../../../redux/actions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../../redux/actions/rentalCommonAction";
import { resetFieldsExcept, toPascalCase } from "../../../../../utils/helper";
import styles from "./section.module.css";
import RbackHelper from "../../../../../../utils/helperRBAC";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../../constants/RBACModuleEnums";

const { isAccessRightsProvided } = RbackHelper;

interface displayNameObject {
  name: string;
  displayName: string;
}

interface initialState {
  selectedLobStatus: any;
  selectedSecondLobStatus: displayNameObject;
  paginationModel: { page: String | number; pageSize: String | number };
  selectedCity: displayNameObject;
  selectedBranch: displayNameObject;
  searchedVehicleNumber: "";
  selectedVehicleIds: [];
  blockedReason: displayNameObject;
}

const initialState: initialState = {
  selectedLobStatus: lobStatus[1],
  selectedSecondLobStatus: { name: "", displayName: "" },
  paginationModel: { page: 0, pageSize: 10 },
  selectedCity: { name: "", displayName: rentalEn.global.allCities },
  selectedBranch: { name: "", displayName: rentalEn.global.allHubs },
  searchedVehicleNumber: "",
  selectedVehicleIds: [],
  blockedReason: { name: "", displayName: "" },
};

export const VehicleStatusUpdate = () => {
  const dispatch = useDispatch();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isBulkStatusUpdateView: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_STATUS_CHANGE
    ),
    isBulkStatusUpdate: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_UPDATE_BULK_STATUS_CHANGE
    ),
  };

  const [fields, setFields] = useState({ ...initialState });
  const [open, setOpen] = useState(false);
  const [changeToLobStatus, setChangeToLobStatus] = useState([]);

  const {
    onLoad,
    vehiclesListData,
    vehiclesListApiResponseData,
    isVehicleUpdatedSucess,
    vehicleStatusUpdateLoader,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);
  const { allCitiesDetails, branches } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // render inititally
  useEffect(() => {
    getVehiclesList({
      page: fields?.paginationModel?.page,
      pageSize: fields?.paginationModel?.pageSize,
      lobStatus: fields?.selectedLobStatus?.name,
    });

    handleSubStatusChange(fields?.selectedLobStatus);

    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.BULK_ACTIONS_STATUS_CHANGE));

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // Memoize rowCount to keep it stable and avoid page reset.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    vehiclesListApiResponseData?.pageData?.totalCount || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (vehiclesListApiResponseData?.pageData?.totalCount !== undefined) {
      rowCountRef.current = vehiclesListApiResponseData?.pageData?.totalCount;
    }
    return rowCountRef.current;
  }, [vehiclesListApiResponseData?.pageData?.totalCount]);

  //   get lob status without All status
  const lobStatusWithoutAllStatus = lobStatus.filter(
    (status) => status.name !== "" && status.name !== LobStatus.ASSIGNED
  );

  //get vehicle list api
  const getVehiclesList = (props: any) => {
    const {
      page,
      pageSize,
      lobStatus,
      searchedVehicleNumber,
      cities,
      branches,
    } = props;
    const payload = {
      status: VehicleStatus.ACTIVE,
      page: page + 1,
      pageSize: pageSize,
      lobStatus: [lobStatus],
      search: searchedVehicleNumber && JSON.stringify(searchedVehicleNumber),
      cities: cities ? [cities] : null,
      branches: branches ? [branches] : null,
      lob: LOB_TYPES.LTR,
    };
    if (functionalitiesAccess.isBulkStatusUpdateView) {
      dispatch(vehicleActions.getVehiclesList(payload));
    }
  };

  // table colums
  const columns: any[] = [
    {
      field: "modelDisplayName",
      headerName: rentalEn?.tableHeaders?.modelName,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "tempRegistration",
      headerName: rentalEn?.tableHeaders?.vehicleNumber,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },

    {
      field: "cityDisplayName",
      headerName: rentalEn?.tableHeaders?.city,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "branchDisplayName",
      headerName: rentalEn?.tableHeaders?.hub,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "colourDisplayName",
      headerName: rentalEn.tableHeaders?.color,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "operatorId",
      headerName: rentalEn.tableHeaders?.addedBy,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span>
            {params?.row?.operatorId
              ? toPascalCase(params?.row?.operatorId)
              : rentalEn?.global?.NA}
          </span>
        );
      },
    },
    {
      field: "tempLobStatus",
      headerName: rentalEn?.tableHeaders?.status,
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: `${
                statusColorCode[
                  params?.row?.tempLobStatus as keyof typeof statusColorCode
                ]
              }`,
            }}
          >
            <Tooltip
              title={
                params?.row?.tempLobStatus === LobStatus?.BLOCKED
                  ? blockedReasonDisplayedName[
                      params?.row
                        ?.blockedReason as keyof typeof blockedReasonDisplayedName
                    ]
                  : ""
              }
              arrow
            >
              {params?.row?.tempLobStatus === rentalEn?.global?.NA
                ? params?.row?.tempLobStatus
                : params?.row?.tempLobStatus.toLowerCase()}
            </Tooltip>
          </span>
        );
      },
    },
  ];

  // handle the pagination of table
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    getVehiclesList({
      page: page,
      pageSize: pageSize,
      lobStatus: fields?.selectedLobStatus.name,
      search: fields?.searchedVehicleNumber,
      cities: fields?.selectedCity?.name,
      branches: fields?.selectedBranch?.name,
    });
  };

  // update initial state
  const handleChange = (key: string, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getVehiclesList(payload);
    }, 1000),
    []
  );

  // Handle the search input change
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value.toString();
    setFields((prev: any) => ({
      ...prev,
      searchedVehicleNumber: searchQuery,
    }));

    if (e.target.value?.length >= 2 || !e.target.value)
      debouncedSearch({
        page: fields?.paginationModel?.page,
        pageSize: fields?.paginationModel?.pageSize,
        lobStatus: fields.selectedLobStatus.name,
        cities: fields?.selectedCity?.name,
        searchedVehicleNumber: searchQuery,
        branches: fields?.selectedBranch?.name,
      });
  };

  // onclick save button (final)
  const handleSaveButtonClick = () => {
    let payload: any = {
      data: {
        data: {
          ids: fields?.selectedVehicleIds,
          status: fields?.selectedSecondLobStatus?.name,
        },
      },
    };

    if (fields?.selectedSecondLobStatus?.name === "") {
      toastr.error("", rentalEn.errorMessages.pleaseSelectTheStatus);
      return;
    }

    if (payload.data.data.status === LobStatus.BLOCKED) {
      if (fields?.blockedReason.name === "") {
        toastr.error("", rentalEn.errorMessages.pleaseSelectTheStatus);
        return;
      }
      // If BLOCKED then provide blockedReason
      payload.data.data.blockedReason = fields?.blockedReason?.name;
    }

    dispatch(vehicleActions.updateVehicleStatus(payload));
  };

  //toggle add city drawer
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // side drawer ui
  const DrawerUI = () => {
    const vehicleLength = fields?.selectedVehicleIds?.length;
    return (
      <Stack padding={"32px"} justifyContent={"space-between"} height="100%">
        <Stack gap="32px">
          <Box className={styles.statusChangeDrawer}>
            <HeadingValueTypography
              heading={rentalEn.VehicleManagement.currentVehicleStatus}
              value={fields?.selectedLobStatus?.displayName}
              color={`${
                statusColorCode[
                  fields?.selectedLobStatus
                    ?.name as keyof typeof statusColorCode
                ]
              }`}
            />
            <HeadingValueTypography
              heading={rentalEn.VehicleManagement.totalVehicles}
              value={vehicleLength.toString()}
            />
          </Box>
          <CustomSelect
            required
            icon={BikeIcon}
            placeholder={rentalEn.VehicleManagement.changeStatusTo}
            value={
              fields?.selectedLobStatus.name === LobStatus?.BLOCKED
                ? fields?.selectedSecondLobStatus?.displayName
                : fields?.blockedReason?.displayName
            }
            choice={changeToLobStatus[0]}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              if (value.name === LobStatus?.AVAILABLE)
                handleChange("selectedSecondLobStatus", value);
              else handleChange("blockedReason", value);
            }}
          />
        </Stack>
        <CustomButton
          variant="contained"
          label={rentalEn.global.update}
          onClick={() => {
            // toggleUpdateStatusDialog();
            handleSaveButtonClick();
          }}
          loading={vehicleStatusUpdateLoader}
          sx={{ width: "100%" }}
        />
      </Stack>
    );
  };

  // get sub status on the basis of fromTo Status
  const handleSubStatusChange = (status: any) => {
    const changeToLobStatus: any = [];
    if (status?.name === LobStatus?.ENGAGED) {
      handleChange("selectedSecondLobStatus", {
        name: LobStatus?.BLOCKED,
        displayName: "Blocked",
      });
      changeToLobStatus.push(
        blockedReasonArray.filter((reason) => {
          return reason.name === BLOCKED_REASON.UNDER_RECOVERY;
        })
      );
    } else if (status?.name === LobStatus?.AVAILABLE) {
      handleChange("selectedSecondLobStatus", {
        name: LobStatus?.BLOCKED,
        displayName: "Blocked",
      });
      changeToLobStatus.push(
        blockedReasonArray.filter((reason) => {
          return reason.name !== BLOCKED_REASON.UNDER_RECOVERY;
        })
      );
    } else if (status?.name === LobStatus?.BLOCKED) {
      handleChange(
        "selectedSecondLobStatus",
        initialState?.selectedSecondLobStatus
      );
      changeToLobStatus.push(
        lobStatus?.filter((lob) => lob.name === LobStatus?.AVAILABLE)
      );
    }
    setChangeToLobStatus(changeToLobStatus);
  };

  // On click reset the page
  const handleFilterReset = () => {
    getVehiclesList({
      page: 0,
      pageSize: 10,
      lobStatus: LobStatus.AVAILABLE,
    });
    setFields((prev: any) => ({
      ...prev,
      ...initialState,
    }));
    handleSubStatusChange(initialState?.selectedLobStatus);
    dispatch(rentalActions.clearHubsList());
  };

  //not working on top so moving it back here
  // when update Api call reset everything
  useEffect(() => {
    if (isVehicleUpdatedSucess) {
      handleFilterReset();
      setOpen(false);
    }
  }, [isVehicleUpdatedSucess]);

  return (
    <Stack gap="20px" marginBottom="60px">
      <Box className={styles.vehicleStatusWrapper}>
        <Input
          placeholder={rentalEn?.global?.searchVehicleNumber}
          variant="outlined"
          value={fields?.searchedVehicleNumber}
          iconStart={SearchIcon}
          inputProps={{ maxLength: 50 }}
          width={318}
          onChange={handleSearch}
          disabled={!functionalitiesAccess.isBulkStatusUpdateView}
        />
        {/* SELECT CITY */}
        <CustomSelect
          required
          icon={CityInputIcon}
          placeholder={rentalEn?.global?.selectCity}
          value={
            isArrayNotEmpty(allCitiesDetails)
              ? fields?.selectedCity?.displayName
              : ""
          }
          choice={
            isArrayNotEmpty(allCitiesDetails)
              ? [
                  { name: "", displayName: rentalEn?.global?.allCities },
                  ...allCitiesDetails,
                ]
              : []
          }
          defaultKey={"displayName"}
          handleChange={(city: any) => {
            handleChange("selectedCity", city);

            dispatch(rentalActions.getAllBranches({ name: city?.name }));
            getVehiclesList({
              page: fields?.paginationModel?.page,
              pageSize: fields?.paginationModel?.pageSize,
              lobStatus: fields?.selectedLobStatus?.name,
              cities: city?.name,
              searchedVehicleNumber: fields?.searchedVehicleNumber,
            });
            setFields((prev: any) => ({
              ...prev,
              selectedBranch: initialState?.selectedBranch,
            }));
          }}
          disabled={!functionalitiesAccess.isBulkStatusUpdateView}
        />
        {/* SELECT WAREHOUSE */}
        <CustomSelect
          required
          icon={WarehouseIcon}
          placeholder={
            isArrayNotEmpty(branches)
              ? rentalEn?.global?.selectHub
              : rentalEn?.global?.allHubs
          }
          value={
            isArrayNotEmpty(branches) ? fields?.selectedBranch?.displayName : ""
          }
          choice={
            isArrayNotEmpty(branches)
              ? [
                  { name: "", displayName: rentalEn?.global?.allHubs },
                  ...branches,
                ]
              : []
          }
          defaultKey={"displayName"}
          disabled={
            functionalitiesAccess.isBulkStatusUpdateView
              ? !fields.selectedCity.name || branches?.length === 0
              : true
          }
          handleChange={(branch: any) => {
            handleChange("selectedBranch", branch);
            getVehiclesList({
              page: fields?.paginationModel?.page,
              pageSize: fields?.paginationModel?.pageSize,
              lobStatus: fields?.selectedLobStatus?.name,
              cities: fields?.selectedCity?.name,
              branches: branch?.name,
              searchedVehicleNumber: fields?.searchedVehicleNumber,
            });
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomSelect
          required
          icon={changeStatusIcon}
          placeholder={rentalEn?.global?.selectStatus}
          value={fields?.selectedLobStatus?.displayName}
          choice={lobStatusWithoutAllStatus}
          defaultKey={"displayName"}
          handleChange={(value: any) => {
            handleChange("selectedLobStatus", value);
            handleSubStatusChange(value);

            getVehiclesList({
              page: fields?.paginationModel?.page,
              pageSize: fields?.paginationModel?.pageSize,
              lobStatus: value?.name,
              cities: fields?.selectedCity?.name,
              branches: fields?.selectedBranch?.name,
              searchedVehicleNumber: fields?.searchedVehicleNumber,
            });

            setFields((prev: any) => ({
              ...prev,
              selectedVehicleIds: initialState.selectedVehicleIds,
              blockedReason: initialState.blockedReason,
              // selectedSecondLobStatus: initialState.selectedSecondLobStatus,
            }));
          }}
          disabled={!functionalitiesAccess.isBulkStatusUpdateView}
          maxWidth={218}
        />
        {functionalitiesAccess.isBulkStatusUpdateView ? (
          <Tooltip title={rentalEn?.global?.resetFilters} arrow>
            <RotateRightSharpIcon
              fontSize="large"
              color="primary"
              sx={{ cursor: "pointer", width: "27px" }}
              onClick={handleFilterReset}
            />
          </Tooltip>
        ) : null}
      </Box>

      <DataTable
        columns={columns}
        rows={vehiclesListData}
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={fields.paginationModel}
        isRowSelectable={(params: GridRowParams) =>
          params?.row?.blockedReason !== BLOCKED_REASON?.UNDER_RECOVERY
        }
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          handleChange("selectedVehicleIds", newRowSelectionModel);
        }}
        onPaginationModelChange={(val: any) => {
          handlePaginationModelChange(val);
        }}
        checkboxSelection={functionalitiesAccess.isBulkStatusUpdate}
        hasAccess={functionalitiesAccess.isBulkStatusUpdateView}
      />

      <Container
        sx={{
          position: "fixed",
          bottom: 3,
          padding: 0,
        }}
        className={styles.containerPadding}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            width: "100%",
            height: "40px",
            backgroundColor: "#fbfbfb",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            variant="contained"
            label={rentalEn.VehicleManagement.changeStatus}
            onClick={() => {
              setOpen(true);
            }}
            loading={onLoad}
            disabled={
              functionalitiesAccess.isBulkStatusUpdate
                ? fields?.selectedVehicleIds?.length === 0
                : true
            }
            style={{ position: "fixed", right: "37px" }}
          />
        </Box>
      </Container>

      <SideDrawer
        open={open}
        heading={rentalEn.VehicleManagement.changeStatus}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        renderUI={<DrawerUI />}
      />
    </Stack>
  );
};
