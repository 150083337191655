import {
  Box,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
  FormLabel,
} from "@mui/material";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../components/breadCumbs";
import { Button2 } from "../../components/button";
import DenseTable from "../../components/DenseTable";
import { BasicSelect } from "../../components/dropDown";
import { Input1, Input2, Input4 } from "../../components/input";
import StickyHeaderTable from "../../components/stickeyHeader";
import { Heading } from "../../components/typography";
import { ButtonActions, UserActions } from "../../constants/userActions";
import { RootState } from "../../redux/reducers";
import { colors } from "../../themes/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilePicker from "../../components/filePicker";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "../../redux";
import { lengthValidate, textValidate, validate } from "../../utils/helper";
import { toastr } from "react-redux-toastr";
import { checkNumeric } from "../../utils/regex";

interface DefaultProps {
  addRole?: boolean;
  editRole?: boolean;
  viewRole?: boolean;
}

function AddEditModel(props: DefaultProps) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { categoryManagement, modelManagement, global, vehicleManagement } =
    locale;
  const navigate = useNavigate();
  const [frontImage, setFrontImage] = useState<any>({
    front_image: null,
    front_image_prev: null,
  });
  const [backImage, setBackImage] = useState<any>({
    back_image: null,
    back_image_prev: null,
  });
  const SourceDataId = [
    {
      id: 1,
      name: "Freedo",
    },
    {
      id: 2,
      name: "Vida",
    },
    {
      id: 3,
      name: "Aggregator",
    },
  ];

  const [downloadGuide, setDownloadGuide] = useState<any>();

  const [allImage, setAllImage] = useState<any>({
    showPreview: false,
    all_images: [],
    all_imgs: [],
  });

  const [leftImage, setLeftImage] = useState<any>({
    left_image: null,
    left_image_prev: null,
  });
  const [fileErrorMessage, setFileErrorMessage] = useState({
    download_guide: "",
    image: "",
  });
  const [imageName, setImageName] = useState<any>({
    image_name: null,
    image_name_prev: null,
  });
  const [rightImage, setRightImage] = useState<any>({
    right_image: null,
    right_image_prev: null,
  });
  const { modelById, addsuccess, editsuccess } = useSelector(
    (state: RootState) => state.masterReducer
  );
  const { vehicleTypes, vehicleCategoryList, emissionStandards, BrandList } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const modelId = useParams();
  const [fields, setFields] = useState({
    model_name: "",
    brand_name: "",
    // type: "",
    max_speed: "",
    // category: "",
    // emissionStandards: "",
    cubicCapicity: "",
    hp: "",
    consumption: "",
    averageEconomy: "",
    short_description: "",
    max_range: "",
    test_rides: "",
    brand: {
      id: 0,
      name: global.SourceName,
    },
    description: "",
    selectedVehicleType: {
      type_name: vehicleManagement.Type,
      id: 0,
    },
    selectedVehicleCategory: {
      category_name: vehicleManagement.Category,
      id: 0,
    },
    selectedVehicleEmission: {
      emission_name: vehicleManagement.SelectEmissionStandards,
      id: 0,
    },
  });

  const [errorFields, setErrorFields] = useState({
    model_name: "",
    brand_name: "",
    max_speed: "",
    cubicCapicity: "",
    hp: "",
    consumption: "",
    averageEconomy: "",
    short_description: "",
    description: "",
    selectedVehicleType: "",
    selectedVehicleCategory: "",
    selectedVehicleEmission: "",
  });

  const handleChange = (field: string, value: string) => {
    switch (field) {
      case "averageEconomy":
        setFields({
          ...fields,
          averageEconomy: lengthValidate(value, 10),
        });
        if (lengthValidate(value, 10) !== "")
          setErrorFields({ ...errorFields, averageEconomy: "" });
        else {
          setErrorFields({
            ...errorFields,
            averageEconomy: "The field is required",
          });
          return;
        }
        break;
      case "model_name":
        setFields({
          ...fields,
          model_name: textValidate(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, model_name: "" });
        } else {
          setErrorFields({
            ...errorFields,
            model_name: "The field is required",
          });
          return;
        }
        break;
      case "brand_name":
        setFields({
          ...fields,
          brand_name: textValidate(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, brand_name: "" });
        } else {
          setErrorFields({
            ...errorFields,
            brand_name: "The field is required",
          });
          return;
        }
        break;
      default:
        setFields({ ...fields, [field]: value });
        setErrorFields({ ...errorFields, [field]: "" });
    }
  };

  const validateForm = (e: SyntheticEvent) => {
    const cloneErrorFields: any = {
      ...errorFields,
    };
    let validation = validate(fields, cloneErrorFields);

    if (validation.error) {
      setErrorFields({ ...errorFields, ...validation.errorFields });
      return;
    }
    else onSubmit();
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const [overviewImages, setOverViewImages] = useState([]);
  const [detailViewImages, setDetailViewImages] = useState({
    left_image: "",
    right_image: "",
    front_image: "",
    back_image: "",
  });

  const [downloadDetails, setDownloadDetails] = useState({
    downloadPath: "",
    downloadFile: "",
  });

  const dispatch = useDispatch();
  // alert()
  useEffect(() => {
    if (props.editRole) {
      dispatch(
        actions.getModelById({
          id: modelId?.id,
        })
      );
    }
    dispatch(
      actions.editCityToggle({
        addsuccess: false,
        editsuccess: false,
      })
    );
    dispatch(
      actions.BrandData({
        type: "Brand",
        id: 0,
      })
    );
    dispatch(actions.vehicleTypes({ type: "VehicleType" }));
    dispatch(actions.vehicleCategories({ type: "VehicleCategory" }));
    dispatch(actions.getEmissionStandards({ type: "EmissionStandards" }));
  }, []);




  const onFileChange = (e: any, t: any) => {
    switch (t) {
      // case "image_name":
      //   setImageName({
      //     ...imageName,
      //     image_name: e.target.files[0],
      //     image_name_prev: URL.createObjectURL(e.target.files[0]),
      //   });
      //   break;
      case "left_image":
        setLeftImage({
          ...leftImage,
          left_image: e.target.files[0],
          left_image_prev: URL.createObjectURL(e.target.files[0]),
        });
        break;
      case "image_name":
        setRightImage({
          ...rightImage,
          right_image: e.target.files[0],
          right_image_prev: URL.createObjectURL(e.target.files[0]),
        });
        setFileErrorMessage({ ...fileErrorMessage, image: "" });
        break;
      case "front_image":
        setFrontImage({
          ...frontImage,
          front_image: e.target.files[0],
          front_image_prev: URL.createObjectURL(e.target.files[0]),
        });
        break;
      case "back_image":
        setBackImage({
          ...backImage,
          back_image: e.target.files[0],
          back_image_prev: URL.createObjectURL(e.target.files[0]),
        });
        break;
      case "download_guide_name":
        setDownloadGuide(e.target.files[0]);
        setFileErrorMessage({ ...fileErrorMessage, download_guide: "" });
        break;
      case "360_image":
        let new_img = URL.createObjectURL(e.target.files[0]);
        let arr = allImage.all_images;
        arr.push(e.target.files[0]);
        let newarr = allImage.all_imgs;
        newarr.push(new_img);
        setAllImage({
          ...allImage,
          showPreview: false,
          all_images: arr,
          all_imgs: newarr,
        });
        break;
      default:
        break;
    }
    // onsubmit();
  };
  const onSubmit = () => {
    if (!rightImage?.right_image || rightImage?.right_image === undefined) {
      setFileErrorMessage({
        ...fileErrorMessage,
        image: "This field should be a file",
      });
      return;
    }
    // if (!downloadGuide || downloadGuide === undefined) {
    //   setFileErrorMessage({
    //     ...fileErrorMessage,
    //     download_guide: "This field should be a file",
    //   });
    //   return;
    // }
    const formData = new FormData();
    if (props.editRole) formData.append("id", modelById.id.toString());

    formData.append("model_name", fields?.model_name);
    formData.append("brand_name", fields?.brand_name);
    formData.append("max_speed", fields?.max_speed);
    formData.append("cubic_capacity", fields?.cubicCapicity);
    formData.append(
      "vehicle_category_id",
      fields?.selectedVehicleCategory.id?.toString()
    );
    formData.append("hp", fields?.hp);
    formData.append(
      "vehicle_type_id",
      fields?.selectedVehicleType?.id?.toString()
    );
    formData.append("average_economy", fields?.averageEconomy);
    formData.append("max_range", fields?.max_range);
    formData.append("brand_id", fields?.brand?.id.toString());
    formData.append("test_rides", fields?.test_rides);
    formData.append("consumption", fields?.consumption);
    formData.append("description", fields?.description);
    formData.append("short_description", fields?.short_description);
    formData.append(
      "emission_standard_id",
      fields?.selectedVehicleEmission?.id?.toString()
    );
    if (
      backImage.back_image != null &&
      backImage.back_image != "" &&
      backImage.back_image_prev != null
    )
      formData.append("back_image", backImage.back_image);

    if (
      frontImage.front_image != null &&
      frontImage.front_image != "" &&
      frontImage.front_image_prev != null
    )
      formData.append("front_image", frontImage.front_image);

    if (
      rightImage.right_image != null &&
      rightImage.right_image != "" &&
      rightImage.right_image_prev != null
    )
      formData.append("image_name", rightImage.right_image);

    if (
      leftImage.left_image != null &&
      leftImage.left_image != "" &&
      leftImage.left_image_prev != null
    )
      formData.append("left_image", leftImage.left_image);

    formData.append("download_guide_name", downloadGuide);

    if (allImage.all_images.length != 0 && allImage.all_images.length >= 2) {
      allImage.all_images.forEach((item: any, i: any) => {
        formData.append("overview_images", item);
      });
    } else {
      if (allImage.all_images.length > 0) {
        toastr.error("Error", "Please select atleast two images");
      }
    }

    if (props.addRole) sendAddApiRequest(formData);
    else if (props.editRole) sendEditApiRequest(formData);
  };

  const sendEditApiRequest = (formData: any) => {
    dispatch(
      actions.editModel({
        formData: formData,
      })
    );
  };
  const sendAddApiRequest = (formData: any) => {
    dispatch(
      actions.addNewModel({
        formData: formData,
      })
    );
  };
  useEffect(() => {
    if ((props.editRole || props.viewRole) && modelById) {
      setFields({
        ...fields,
        averageEconomy: modelById?.average_economy,
        model_name: modelById?.model_name,
        brand_name: modelById?.brand_name,
        selectedVehicleType: {
          type_name: modelById?.vehicle_type_master?.type_name,
          id: modelById?.vehicle_type_master?.id,
        },

        selectedVehicleCategory: {
          category_name: modelById?.vehicle_category_master?.category_name,
          id: modelById?.vehicle_category_master?.id,
        },
        selectedVehicleEmission: {
          emission_name: modelById?.emission_standard?.emission_name,
          id: modelById?.emission_standard?.id,
        },
        cubicCapicity: modelById?.cubic_capacity,
        hp: modelById?.hp,
        max_speed: modelById?.max_speed,
        consumption: modelById?.consumption,
        short_description: modelById?.short_description,
        description: modelById?.description,
        max_range: modelById?.max_range,
        test_rides: modelById?.test_rides === true ? "1" : "0",
        brand: modelById?.brand
      });



      setOverViewImages(modelById?.vehicle_overview_path);
      setRightImage({
        ...rightImage,
        right_image: modelById?.vehicle_image_path + modelById?.image_name,
      });
      setDownloadGuide(
        modelById?.download_guide_name
      );
      setDetailViewImages({
        ...detailViewImages,
        left_image: modelById?.detail_view_images?.left_image,
        right_image: modelById?.detail_view_images?.right_image,
        front_image: modelById?.detail_view_images?.front_image,
        back_image: modelById?.detail_view_images?.back_image,
      });
      setDownloadDetails({
        ...downloadDetails,
        downloadPath: modelById?.download_guide_path,
        downloadFile: modelById?.download_guide_name,
      });
    }
  }, [modelById]);

  if (addsuccess || editsuccess) navigateBack();

  return (
    <div className="container pd-20">
      <Heading
        title={
          props.viewRole
            ? categoryManagement.ViewModel
            : modelManagement.StoreModel
        }
      />
      <BreadCrumbs
        path="/master-management/model-module"
        firstLabel={modelManagement.ModelManagement}
        secondLabel={
          props.viewRole
            ? categoryManagement.ViewModel
            : modelManagement.StoreModel
        }
      />
      <form>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2%",
            bgcolor: "background.paper",
            borderRadius: 1,
            // justifyContent: "space-between",
          }}
        >
          <Input2
            width={300}
            value={fields?.model_name}
            placeholder={global.ModelName}
            onChange={(model: any) =>
              handleChange("model_name", model.target.value)
            }
            required={true}
            errormessage={errorFields.model_name}
          />
          <Input2
            width={300}
            value={fields?.brand_name}
            placeholder={global.BrandName}
            onChange={(model: any) =>
              handleChange("brand_name", model.target.value)
            }
            errormessage={errorFields.brand_name}
            required={true}
          />
          {vehicleTypes ? (
            <Input2
              select
              width={300}
              placeholder={vehicleManagement.Type}
              heading={vehicleManagement.Type}
              value={fields?.selectedVehicleType["type_name"]}
              // defaultValue={global.Type}
              choice={vehicleTypes}
              defaultKey={"type_name"}
              handleChange={(model: any) =>
                handleChange("selectedVehicleType", model)
              }
              required={true}
              errormessage={errorFields.selectedVehicleType}
            />
          ) : null}
          <Input2
            width={300}
            select
            heading={vehicleManagement.Category}
            value={fields?.selectedVehicleCategory["category_name"]}
            defaultKey={"category_name"}
            placeholder={vehicleManagement.Category}
            choice={vehicleCategoryList}
            required={true}
            handleChange={(category: any) =>
              handleChange("selectedVehicleCategory", category)
            }
            errormessage={errorFields.selectedVehicleCategory}
          />
          <Input2
            width={300}
            select
            placeholder={vehicleManagement.EmissionStandards}
            heading={vehicleManagement.SelectEmissionStandards}
            choice={emissionStandards}
            value={fields?.selectedVehicleEmission["emission_name"]}
            defaultKey={"emission_name"}
            handleChange={(emission: any) =>
              handleChange("selectedVehicleEmission", emission)
            }
            errormessage={errorFields.selectedVehicleEmission}
            required={true}
          />
          <Input2
            width={300}
            value={fields?.cubicCapicity}
            placeholder={vehicleManagement.CubicCapacity}
            required={true}
            onChange={(emission: any) =>
              handleChange("cubicCapicity", emission.target.value)
            }
            errormessage={errorFields.cubicCapicity}
          />
          <Input2
            width={300}
            value={fields?.consumption}
            placeholder={vehicleManagement.Consumption}
            required={true}
            onChange={(emission: any) =>
              handleChange("consumption", emission.target.value)
            }
            errormessage={errorFields.consumption}
          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.max_speed}
            placeholder={global.MaxSpeed}
            required={true}
            onChange={(emission: any) =>
              handleChange("max_speed", emission.target.value)
            }
            errormessage={errorFields.max_speed}

          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.hp}
            placeholder={vehicleManagement.HP}
            required={true}
            onChange={(emission: any) =>
              handleChange("hp", emission.target.value)
            }
            errormessage={errorFields.hp}

          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.averageEconomy}
            placeholder={vehicleManagement.AverageEconomy}
            required={true}
            onChange={(emission: any) =>
              handleChange("averageEconomy", emission.target.value)
            }
            errormessage={errorFields.averageEconomy}

          // onChange={(e)=>{}}
          />
          <Input4
            placeholder={global.ShortDescription}
            readOnly={props.viewRole}
            defaultValue={fields?.short_description}
            required={true}
            onChange={(emission: any) =>
              handleChange("short_description", emission.target.value)
            }
            errormessage={errorFields.short_description}
          />
          <Input4
            placeholder={global.Description}
            readOnly={props.viewRole}
            defaultValue={fields?.description}
            required={true}
            onChange={(emission: any) =>
              handleChange("description", emission.target.value)
            }
            errormessage={errorFields.description}
          />

          {/* <TextareaAutosize
                         minRows={20}
                        maxRows={100}
                        aria-label="maximum height"
                        placeholder={global.ShortDescription}
                        defaultValue={props.viewRole || props.editRole ?"The Hero Pleasure Plus is arguably perfect for the urban commuter, who doesn’t need to compromise on the looks of his vehicle for a robust ride.Its Bold, Smart, Just like You.":''}
                        style={{ backgroundColor:'rgb(242, 242, 242)',minWidth: '320px',marginRight: '50px',border:'none' }}
                      />
                      <TextareaAutosize
                       minRows={20}
                        maxRows={100}
                        aria-label="maximum height"
                        placeholder={global.Description}
                        defaultValue={props.viewRole || props.editRole ?"The new Hero Pleasure Plus comes with a stylish exterior and a sturdy interior, so you can enjoy a trouble-free commute while looking classy at all times! The scooter is equipped with Hero’s Xsens technology which uses smart sensors to automatically detect different riding conditions. This, coupled with the air-cooled, four-stroke 110.9 cc engine, guarantees to make your ride pleasurable. Pleasure Plus keeps the rider’s comfort and convenience at the forefront, and it has a host of features that are a testament to this. To name a few, a mobile charging port for when you forget to put your phone on charge the previous night and have no time to waste to reach college, and a new analogue speedometer with a clean and refined look so you don’t get distracted while expertly maneuvering through jam-packed main roads to get to your office. These are only a few of the remarkable features that sets Pleasure Plus in a league of its own.":''}
                        style={{ backgroundColor:'rgb(242, 242, 242)',minWidth: '320px',marginRight: '50px',border:'none' }}
                      /> */}

          <Box display={"flex"}>
            <Input2
              width={300}
              value={fields?.max_range}
              placeholder={vehicleManagement.MaxRange}
              heading={vehicleManagement.MaxRange}
              // required={true}
              onChange={(e: any) => {
                checkNumeric(e.target.value) &&
                  handleChange("max_range", e.target.value);
              }}
            // errormessage={errorFields.averageEconomy}

            // onChange={(e)=>{}}
            />

            {/* <Grid>
  <Typography>
    Test Ride:
    <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
  </Typography>
</Grid> */}
            <Grid container direction="column">
              <FormLabel component="legend">Test Ride:</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                value={fields.test_rides}
                onChange={(e: any) => {
                  setFields({
                    ...fields,
                    test_rides: e.target.value
                  });
                }}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                    />
                  }
                  label={vehicleManagement.Yes}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                    />
                  }
                  label={vehicleManagement.No}
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          </Box>

          <div style={{ display: fields?.test_rides === "1" ? "" : "none" }}>
            <Input2
              width={300}
              select
              heading={global.SourceName}
              value={fields?.brand?.["name"]}
              defaultKey={"name"}
              placeholder={global.SourceName}
              choice={BrandList}
              handleChange={(brand: any) => handleChange("brand", brand)}

            />
          </div>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box display="flex" flexDirection="column">
            <FilePicker
              padding="2px"
              accept="image/png"
              errormessage={fileErrorMessage?.image}
              onChange={(e: any) => onFileChange(e, "image_name")}
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleRightImage}
            />
            {rightImage.right_image_prev ? (
              <img
                style={{ cursor: "pointer" }}
                src={rightImage.right_image_prev}
                width={60}
                height={60}
              />
            ) : null}
          </Box>

          <Box display="flex" flexDirection="column">
            <FilePicker
              accept="image/png"
              onChange={(e: any) => onFileChange(e, "left_image")}
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleLeftImage}
            />
            {leftImage.left_image_prev ? (
              <img
                style={{ cursor: "pointer" }}
                src={leftImage.left_image_prev}
                width={60}
                height={60}
              />
            ) : null}
          </Box>

          <Box display="flex" flexDirection="column">
            <FilePicker
              accept="image/png"
              onChange={(e: any) => onFileChange(e, "front_image")}
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleFrontImage}
            />
            {frontImage.front_image_prev ? (
              <img
                style={{ cursor: "pointer" }}
                src={frontImage.front_image_prev}
                width={60}
                height={60}
              />
            ) : null}
          </Box>
          <Box display="flex" flexDirection="column">
            <FilePicker
              accept="image/png"
              onChange={(e: any) => onFileChange(e, "back_image")}
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleBackImage}
            />
            {backImage.back_image_prev ? (
              <img
                style={{ cursor: "pointer" }}
                src={backImage.back_image_prev}
                width={60}
                height={60}
              />
            ) : null}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* <FilePicker
              errormessage={fileErrorMessage?.download_guide}
              padding="2px"
              accept="application/pdf"
              onChange={(e: any) => onFileChange(e, "download_guide_name")}
              justifyContent="flex-start"
              width="230px"
              label={global.DownloadGuide}
            /> */}
            {/* <FilePicker
              padding="2px"
              accept="image/png"
              onChange={(e: any) => onFileChange(e, "360_image")}
              justifyContent="flex-start"
              width="230px"
              label={global.Vehicle360Images}
            /> */}
            {allImage.all_imgs && allImage.all_imgs.length > 0 ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
              >
                {allImage.all_imgs.map((image: any) => {
                  return (
                    <img
                      style={{ cursor: "pointer" }}
                      src={image}
                      width={60}
                      height={60}
                    />
                  );
                })}
              </Box>
            ) : null}
          </Box>
          {props.editRole ? (
            <>
              {overviewImages && overviewImages.length > 0 ? (
                <Grid container direction="row" justifyContent="flex-end">
                  <Button2
                    title={global.Preview}
                    style={{ marginLeft: "15px" }}
                  />
                  <Button2
                    title={global.Reset}
                    style={{ marginLeft: "15px" }}
                  />
                  <Button2
                    title={global.Delete}
                    style={{ marginLeft: "15px" }}
                  />
                </Grid>
              ) : null}
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  marginTop="2%"
                  flexDirection="row"
                  style={{
                    height: "110px",
                    touchAction: "auto",
                    userSelect: "none",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                  }}
                >
                  {/*  <img
              style={{cursor:'pointer'}} style={{height:'100',width:'100'}} src={pleasurePlus1}/> */}
                  {overviewImages &&
                    overviewImages.map((image: any) => {
                      return <img style={{ cursor: "pointer" }} src={image} />;
                    })}
                </Box>
                <Box marginTop="2%" display="flex">
                  {/* <ModelView
                    marginTop="2%"
                    sideHeading="Model Guide"
                    modelName={fields?.model_name}
                    navigatePath={
                      downloadDetails.downloadPath +
                      downloadDetails.downloadFile
                    }
                  /> */}
                </Box>
              </Box>
            </>
          ) : null}
        </Box>

        <Box
          marginTop="2%"
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
        >
          {detailViewImages.right_image &&
            detailViewImages.right_image != "" ? (
            <Box display="flex" flexDirection="column">
              <Typography
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Right Image:
              </Typography>
              <img
                style={{ cursor: "pointer" }}
                width={180}
                onClick={() =>
                  window.open(
                    modelById?.vehicle_detail_view_path +
                    modelId?.id +
                    "/" +
                    detailViewImages.right_image,
                    "_blank"
                  )
                }
                height={180}
                src={
                  modelById?.vehicle_detail_view_path +
                  modelId?.id +
                  "/" +
                  detailViewImages.right_image
                }
              />
            </Box>
          ) : null}

          {detailViewImages.left_image && detailViewImages.left_image != "" ? (
            <Box display="flex" flexDirection="column">
              <Typography
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Left Image:
              </Typography>
              <img
                style={{ cursor: "pointer" }}
                width={180}
                onClick={() =>
                  window.open(
                    modelById?.vehicle_detail_view_path +
                    modelId?.id +
                    "/" +
                    detailViewImages.left_image,
                    "_blank"
                  )
                }
                height={180}
                src={
                  modelById?.vehicle_detail_view_path +
                  modelId?.id +
                  "/" +
                  detailViewImages.left_image
                }
              />
            </Box>
          ) : null}

          {detailViewImages.front_image &&
            detailViewImages.front_image != "" ? (
            <Box display="flex" flexDirection="column">
              <Typography
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Front Image:
              </Typography>
              <img
                onClick={() =>
                  window.open(
                    modelById?.vehicle_detail_view_path +
                    modelId?.id +
                    "/" +
                    detailViewImages.front_image,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
                width={180}
                height={180}
                src={
                  modelById?.vehicle_detail_view_path +
                  modelId?.id +
                  "/" +
                  detailViewImages.front_image
                }
              />
            </Box>
          ) : null}

          {detailViewImages.back_image && detailViewImages.back_image != "" ? (
            <Box display="flex" flexDirection="column">
              <Typography
                fontFamily="EuclidCircularB-Regular"
                fontSize={14}
                color={colors.dark_gray}
                variant="body2"
              >
                Back Image:
              </Typography>
              <img
                onClick={() =>
                  window.open(
                    modelById?.vehicle_detail_view_path +
                    modelId?.id +
                    "/" +
                    detailViewImages.back_image,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
                width={180}
                height={180}
                src={
                  modelById?.vehicle_detail_view_path +
                  modelId?.id +
                  "/" +
                  detailViewImages.back_image
                }
              />
            </Box>
          ) : null}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
          <Button2
            onClick={navigateBack}
            title={global.Back}
            back={true}
            style={{ marginLeft: "15px" }}
          />
          {props.editRole || props.addRole ? (
            <Button2
              onClick={validateForm}
              title={global.SaveModel}
              style={{ marginLeft: "15px" }}
            />
          ) : null}
        </Box>
      </form>
    </div>
  );
}

function ModelView(props: any) {
  const navigate = useNavigate();

  // alert(props.navigatePath)
  const navigateDownload = () => {
    window.open(props.navigatePath, "_blank");
    // navigate()
  };
  return (
    <Box display="flex">
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          width: "137px",
          padding: "13px",
        }}
      >
        <Typography>{props.sideHeading}</Typography>
      </Grid>
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          width: "426px",
          padding: "13px",
        }}
      >
        <Typography>{props.modelName}</Typography>
      </Grid>
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          textAlign: "center",
          width: "60px",
          padding: "13px",
        }}
      >
        <FontAwesomeIcon
          onClick={navigateDownload}
          style={{ cursor: "pointer" }}
          icon={faEye}
        />
      </Grid>
    </Box>
  );
}
export default AddEditModel;
