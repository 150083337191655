import { Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import PaymentLinkQRLoader from "../../../../../sharedComponents/molecules/SkeletonLoader/BookingManagement/PaymentLinkQRLoader";
import {
  RenderPaymentLink,
  RenderQRCode,
} from "../../../../../sharedComponents/templates/RenderPaymentMethods";
import {
  OrderPaymentType,
  typographyConstants,
} from "../../../../constants/constants";
import { SuccessLottie } from "../../../../constants/exportLottie";
import { getFormattedINR } from "../../../../utils/helper";

const PaymentDetailsSideModalUI = ({
  paymentLinkQRData,
  paymentLinkQRLoader,
  orderDataloader,
  isPaymentSuccess,
}: any) => {
  return (
    <>
      <Stack
        className="PaymentLinkQrWrapper"
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "3vh 5vh" }}
      >
        {orderDataloader || paymentLinkQRLoader ? (
          <PaymentLinkQRLoader />
        ) : isPaymentSuccess ? (
          <>
            <Stack alignItems={"center"}>
              <Lottie
                style={{ height: "200px", width: "200px" }}
                animationData={SuccessLottie}
                loop={false}
              />
              <Typography
                variant={typographyConstants.HEADING}
                sx={{ padding: "unset" }}
              >
                {getFormattedINR(paymentLinkQRData?.amount)}
              </Typography>

              <Typography
                variant={typographyConstants.HEADING}
                sx={{ padding: "unset" }}
              >
                Payment Successfull !
              </Typography>
            </Stack>
          </>
        ) : (
          <Stack alignItems="center" gap="4vh">
            {/* COD / CASH */}
            {paymentLinkQRData?.paymentFlow === OrderPaymentType.CASH && (
              <>
                <Stack alignItems={"center"}>
                  <Lottie
                    style={{ height: "200px", width: "200px" }}
                    animationData={SuccessLottie}
                    loop={false}
                  />
                  <Typography
                    variant={typographyConstants.HEADING}
                    sx={{ padding: "unset" }}
                  >
                    {getFormattedINR(paymentLinkQRData?.amount)}
                  </Typography>

                  <Typography
                    variant={typographyConstants.HEADING}
                    sx={{ padding: "unset" }}
                  >
                    Amount Paid in Cash
                  </Typography>
                </Stack>
              </>
            )}

            {/* PAYMENT LINK */}
            {paymentLinkQRData?.paymentFlow ===
              OrderPaymentType.PAYMENT_LINK && (
              <>
                <RenderPaymentLink
                  paymentLink={paymentLinkQRData}
                  amount={paymentLinkQRData?.amount ?? 0}
                  // tryAnotherPaymentMethod={() => {}}
                />
              </>
            )}

            {/* QR CODE */}
            {paymentLinkQRData?.paymentFlow === OrderPaymentType.QR_CODE && (
              <>
                <RenderQRCode
                  paymentLink={paymentLinkQRData}
                  totalCharges={paymentLinkQRData?.amount ?? 0}
                  // isPaymentLinkGenerated={isPaymentLinkGenerated}
                  // onSuccesTimerCompletion={onSuccessTimeCompletionPayment}
                />
              </>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default PaymentDetailsSideModalUI;
