import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SellIcon from "@mui/icons-material/Sell";
import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import en from "../../../../locale/rental-en.json";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { colors } from "../../../../../themes/colors";
import {
  ADD_CHARGES_ACTION_TYPE,
  AddChargesInputLength,
  DisplayChargesNameTypes,
  chargesCategoriesObj,
  typographyConstants,
} from "../../../../constants/constants";
import { getFormattedINR } from "../../../../utils/helper";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import {
  addChargesIcon,
  addRemarkIcon,
  deleteChargesIcon,
  deleteCross,
} from "../../../../constants/exportImages";
import ClearIcon from "@mui/icons-material/Clear";

const AddChargesSideModalUI = ({
  chargesCategoriesData,
  selectedAddMoreCharges,
  setSelectedAddMoreCharges,
  handleAddMoreChargesClick,
  handleDeleteAllAddMoreCharges,
  handleDeleteAddedCharge,
  handleAddMoreCategoriesSelect,
  handleAddMoreSubCategoriesSelect,
  handleAddMoreChargesSaveAll,
  handleAddMoreChargesPrice,
  addMoreErrorObj,
  handleChargesRemarkToggle,
  handleAddChargesRemarkChange,
}: any) => {
  const [showChargeDeleteConfirmation, setShowChargeDeleteConfirmation] =
    useState(false);
  const [showChargeResetConfirmation, setShowChargeResetConfirmation] =
    useState(false);
  const [chargeSelectedForDelete, setChargeSelectedForDelete] = useState(0);

  const handleDeleteChargeModalClose = () => {
    setShowChargeDeleteConfirmation(false);
  };
  const handleResetChargeModalClose = () => {
    setShowChargeResetConfirmation(false);
  };

  const categories = Object.keys(chargesCategoriesObj);
  const subCategories = chargesCategoriesData;
  const categoryDetails = chargesCategoriesData?.categoryDetails;
  // category options
  const selectCategoryOptions = categories?.map((item: any, index: number) => {
    return {
      name: item,
      displayName:
        DisplayChargesNameTypes[item as keyof typeof DisplayChargesNameTypes],
    };
  });
  // sub category options
  const selectSubCategoriesOptions = subCategories?.map(
    (item: any, index: number) => {
      return {
        name: item?.id,
        displayName: item?.name,
        chargeCategory: item?.type,
      };
    }
  );
  // total charges
  let totalCharges = 0;
  for (let item in selectedAddMoreCharges) {
    totalCharges += selectedAddMoreCharges[item]["price"];
  }

  let buttonWrapper = { width: "100%" };

  return (
    <>
      <Stack
        padding="10px"
        gap="3vh"
        height={"100%"}
        justifyContent={"space-between"}
      >
        <Stack gap="2vh">
          {/* Top action buttons */}
          <Stack direction="row" justifyContent="space-between">
            {/* Add */}
            <CustomButton
              label="Add"
              // startImageSrc={addChargesIcon}
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={handleAddMoreChargesClick}
              sx={{ padding: "0px 20px", borderRadius: "10px", gap: "0px" }}
            />

            {/* Delete all */}
            {selectedAddMoreCharges.length > 0 && (
              <CustomButton
                label="Delete all"
                startImageSrc={deleteChargesIcon}
                variant="outlined"
                color="error"
                onClick={() => {
                  setShowChargeResetConfirmation(true);
                }}
                sx={{
                  textTransform: "none",
                  border: "1.5px solid var(--Tags-red, #FF3B30)",
                  borderRadius: "10px",
                  color: "#FF3B30",
                }}
              />
            )}
          </Stack>

          {/* Charges Body */}
          <Stack gap="2vh">
            {selectedAddMoreCharges &&
              selectedAddMoreCharges?.length > 0 &&
              selectedAddMoreCharges?.map((item: any, index: number) => {
                let selectedCategory = item?.category?.name
                  ? item?.category?.name
                  : "";
                let selectedSubCategory = item?.subCategory?.name
                  ? item?.subCategory?.name
                  : "";
                let availableSubCategoriesOptions = selectedCategory
                  ? selectSubCategoriesOptions?.filter(
                      (item: any, index: number) =>
                        item?.chargeCategory === selectedCategory
                    )
                  : selectSubCategoriesOptions;
                return (
                  <>
                    <Stack
                      sx={{
                        background: "#FFFFFF",
                        border: "1px dashed #00000029",
                        padding: "1vw",
                        gap: "1vh",
                      }}
                    >
                      {/* Error section */}
                      {Object.keys(addMoreErrorObj).length > 0 &&
                        addMoreErrorObj[item?.chargeIndex] &&
                        addMoreErrorObj[item?.chargeIndex]["status"] && (
                          <>
                            <Typography
                              variant="caption"
                              style={{
                                color: "indianred",
                                textAlign: "right",
                                fontStyle: "italic",
                              }}
                            >
                              {addMoreErrorObj[item?.chargeIndex][
                                "remarkLength"
                              ]
                                ? en.errorMessages.addMoreChargeReasonLengthLTR
                                : addMoreErrorObj[item?.chargeIndex]["remark"]
                                ? en.errorMessages.addMoreChargeReasonLTR
                                : addMoreErrorObj[item?.chargeIndex]["price"]
                                ? en.errorMessages.addMoreChargePriceLTR
                                : en.errorMessages.addMoreChargeLTR}
                            </Typography>
                          </>
                        )}

                      {/* Charge Type */}
                      <CustomSelect
                        required
                        muiIcons={<AccountBalanceWalletIcon />}
                        placeholder={en.bookingDetails.ChargesCategoryTitle}
                        value={item?.category?.displayName}
                        choice={
                          selectCategoryOptions?.length > 0
                            ? selectCategoryOptions
                            : []
                        }
                        defaultKey={"displayName"}
                        sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
                        handleChange={(category: any) => {
                          handleAddMoreCategoriesSelect(
                            category,
                            item?.chargeIndex
                          );
                        }}
                      />

                      {/* Sub Category */}
                      <CustomSelect
                        required
                        muiIcons={<SellIcon />}
                        placeholder={en.bookingDetails.ChargesSubCategoryTitle}
                        value={item?.subCategory?.displayName}
                        choice={availableSubCategoriesOptions}
                        defaultKey={"displayName"}
                        handleChange={(subCategory: any) => {
                          handleAddMoreSubCategoriesSelect(
                            subCategory,
                            item?.chargeIndex,
                            subCategory?.name
                          );
                        }}
                        disabled={selectedCategory === ""}
                      />

                      {/* Price */}
                      <Typography>Price*</Typography>
                      <TextField
                        id="standard-number"
                        type="text"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                        disabled={!Boolean(item?.isEditable)}
                        value={item?.price ?? ""}
                        onChange={(event: any) => {
                          handleAddMoreChargesPrice(
                            event.target.value,
                            item?.chargeIndex
                          );
                        }}
                      />

                      {/* Remark */}
                      <Stack gap="4px">
                        {item?.showRemark ? (
                          <>
                            <CustomButton
                              label="Remove Remark"
                              startIcon={<DisabledByDefaultIcon />}
                              border="error"
                              hoverStyle="error"
                              color="error"
                              style={{
                                width: "fit-content",
                                fontSize: "10px",
                                padding: "4px 10px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                handleChargesRemarkToggle(item?.chargeIndex);
                              }}
                            />
                            <TextField
                              id="outlined-multiline-static"
                              // label="Reason"
                              multiline
                              rows={2}
                              placeholder={`Add remark`}
                              value={item?.remarks}
                              inputProps={{
                                maxLength:
                                  AddChargesInputLength.ADD_CHARGES_REMARK,
                              }}
                              onChange={(event: any) => {
                                handleAddChargesRemarkChange(
                                  event.target.value,
                                  item?.chargeIndex
                                );
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <CustomButton
                              label="Add Remark"
                              startImageSrc={addRemarkIcon}
                              style={{
                                width: "fit-content",
                                fontSize: "10px",
                                padding: "4px 10px",
                                textTransform: "capitalize",
                                gap: "0px",
                              }}
                              onClick={() => {
                                handleChargesRemarkToggle(item?.chargeIndex);
                              }}
                            />
                          </>
                        )}
                      </Stack>

                      <Divider
                        sx={{
                          border: "1px dashed #000000a8",
                        }}
                      />

                      {/* delete individual charge */}
                      {item?.chargeIndex > 0 && (
                        <CustomButton
                          onClick={() => {
                            setChargeSelectedForDelete(item?.chargeIndex);
                            setShowChargeDeleteConfirmation(true);
                          }}
                          label={"Delete"}
                          endImg={deleteCross}
                          className="logoutButtonX"
                          border="error"
                          hoverStyle="error"
                          color="error"
                          style={{
                            width: "fit-content",
                            fontSize: "10px",
                            // padding: "0px",
                            textTransform: "capitalize",
                          }}
                          sx={{
                            background:
                              "linear-gradient(90deg, rgba(255, 59, 48, 0.08) 0%, rgba(243, 117, 106, 0.08) 100%)",
                            color: "#FF8680",
                            [`& .MuiButton-endIcon`]: {
                              marginLeft: "4px",
                            },
                          }}
                        />
                      )}
                    </Stack>
                  </>
                );
              })}
          </Stack>
        </Stack>

        {/* Action Buttons */}
        <Stack>
          {/* Total charge */}
          {totalCharges > 0 && (
            <Stack
              direction="row"
              justifyContent={"space-around"}
              sx={{
                justifyContent: "space-between",
                padding: "1vh 4vw",
              }}
            >
              <Typography variant={typographyConstants.SUBHEADING}>
                Total Amount
              </Typography>
              <Typography variant={typographyConstants.SUBHEADING}>
                {getFormattedINR(totalCharges * 100) ?? 0}
              </Typography>
            </Stack>
          )}

          {/* Submit Buttons */}
          <Stack direction="row" justifyContent={"space-around"} gap="5px">
            <CustomButton
              label="Save for later"
              variant="outlined"
              onClick={() =>
                handleAddMoreChargesSaveAll(ADD_CHARGES_ACTION_TYPE.SAVE)
              }
              width="unset"
              minWidth="unset"
              sx={{ width: "100%", padding: "unset" }}
              wrapperStyles={buttonWrapper}
            />
            <CustomButton
              label="Proceed to pay"
              variant="contained"
              onClick={() =>
                handleAddMoreChargesSaveAll(
                  ADD_CHARGES_ACTION_TYPE.SAVE_AND_PAY
                )
              }
              sx={{
                color: "#fff",
                width: "100%",
                padding: "unset",
                // padding: "0 20px",
              }}
              wrapperStyles={buttonWrapper}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* Delete single charge confirmation modal */}
      {showChargeDeleteConfirmation && (
        <>
          <ResponsiveDialog
            title={en.alertMessages.deleteItem}
            open={showChargeDeleteConfirmation}
            handleClose={() => handleDeleteChargeModalClose()}
            handleYes={() => {
              setShowChargeDeleteConfirmation(false);
              if (chargeSelectedForDelete > 0) {
                handleDeleteAddedCharge(chargeSelectedForDelete);
              }
            }}
          />
        </>
      )}

      {/* Reset all charge confirmation modal */}
      {showChargeResetConfirmation && (
        <>
          <ResponsiveDialog
            title={en.alertMessages.resetAllItem}
            open={showChargeResetConfirmation}
            handleClose={() => handleResetChargeModalClose()}
            handleYes={() => {
              setShowChargeResetConfirmation(false);
              handleDeleteAllAddMoreCharges();
            }}
          />
        </>
      )}
    </>
  );
};

export default AddChargesSideModalUI;
