import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import SearchBar from "../../../../../../sharedComponents/atoms/InputFields/searchBar";
import CustomAccordion from "../../../../../../sharedComponents/molecules/Accordion/customAccordian";
import HeadingValueTypography from "../../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import SideDrawer from "../../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import {
  ChallanPaymentStatus,
  ChallanStatusColorCodes,
  ChallanStatusDisplay,
  typographyConstants,
  VehicleStatus,
} from "../../../../../constants/constants";
import { CheckMark } from "../../../../../constants/exportImages";
import { VmsChallanDetailsProps } from "../../../../../interfaces/vehicleInterface";
import en from "../../../../../locale/rental-en.json";
import { dateFormat, paisaToRupee } from "../../../../../utils/helper";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../../constants/RBACModuleEnums";
import { enCA } from "date-fns/esm/locale";
import RbackHelper from "../../../../../../utils/helperRBAC";
import { toastr } from "react-redux-toastr";

const { isAccessRightsProvided } = RbackHelper;

export const ChallanDetails = (props: VmsChallanDetailsProps) => {
  const { global, buttonLabels, VehicleManagement } = en;

  const {
    onclick,
    expand,
    challanData,
    loading,
    handlePaginationModel,
    pagination,
    getLiveChallan,
    liveChallanLoader,
    handleChallanLinking,
    sideDrawerChallan,
    toggleDrawerChallan,
    bookingListLoader,
    challanFields,
    onInputChange,
    bookingList,
    getBookingIds,
    onSelect,
    getSearchKey,
    linkChallanToBooking,
    vehicleLinkChallanLoader,
    downloadChallans,
    vehicleDetailsByIdData,
  } = props;

  // RBAC object for functionality
  const functionalitiesAccess = {
    isDownloadChallan: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_DOWNLOAD_CHALLAN
    ),
    isFetchChallanKycKart: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_FETCH_CHALLANS_KYC_CART
    ),
  };

  const { bookings = [] } = bookingList;

  const downloadSingleChallan = (url: string) => {
    if (!functionalitiesAccess.isDownloadChallan) {
      return;
    } else window.open(url);
  };

  // columns for challan details table
  const columns = [
    {
      field: "challanNumber",
      headerName: VehicleManagement.challanNumber,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.challanNumber}>
          <span>
            {params?.row?.challanNumber
              ? params?.row?.challanNumber
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
    {
      type: "actions",
      field: "challanLinking",
      headerName: VehicleManagement.challanLinking,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      getActions: (params: any) => {
        return [
          <>
            {params?.row?.status === ChallanPaymentStatus.PAID ||
            params.row.status === ChallanPaymentStatus.PARTIAL_PAID ||
            params.row.status === ChallanPaymentStatus.WAIVED_OFF ? (
              <>{global.NA}</>
            ) : (
              <Link
                underline="always"
                sx={{
                  fontWeight: "500",
                  color: colors.primary_dark_blue2,
                }}
                onClick={() => handleChallanLinking(params?.row)}
              >
                {params?.row?.status === ChallanPaymentStatus.LINKED
                  ? buttonLabels.unLink
                  : buttonLabels.link}
              </Link>
            )}
          </>,
        ];
      },
      flex: 1,
      resizable: false,
    },
    {
      field: "bookingId",
      headerName: VehicleManagement.bookingNo,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={
            params?.row?.associationDetails?.bookingNumber
              ? params?.row?.associationDetails?.bookingNumber
              : ""
          }
        >
          <span>
            {params?.row?.associationDetails?.bookingNumber
              ? params?.row?.associationDetails?.bookingNumber
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "amount",
      headerName: VehicleManagement.amount,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.offenseDetails}>
          <span>
            {params?.row?.amount
              ? paisaToRupee(params?.row?.amount)
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "modifiedAmount",
      headerName: VehicleManagement.paidAmount,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.totalPaidAmount
            ? paisaToRupee(params?.row?.totalPaidAmount)
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "date",
      headerName: global.date,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.challanDate ? (
            <Tooltip title={dateFormat(params?.row?.challanDate)}>
              {dateFormat(params?.row?.challanDate)}
            </Tooltip>
          ) : (
            global.NA
          )}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "state",
      headerName: global.state,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{params?.row?.state ? params?.row?.state : global.NA}</span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "paymentStatus",
      headerName: VehicleManagement.paymentStatus,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.status
            ? ChallanStatusDisplay[
                params?.row?.status as keyof typeof ChallanStatusDisplay
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "offenseDetails",
      headerName: global.offencedetails,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.offenseDetails ? (
            <Tooltip title={params?.row?.offenseDetails}>
              {params?.row?.offenseDetails}
            </Tooltip>
          ) : (
            global.NA
          )}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "accusedName",
      headerName: global.accusedName,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.accusedName ? (
            <Tooltip title={params?.row?.accusedName}>
              {params?.row?.accusedName}
            </Tooltip>
          ) : (
            global.NA
          )}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "challanStatus",
      headerName: VehicleManagement.challanStatus,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span
          style={{
            color:
              ChallanStatusColorCodes[
                params?.row
                  ?.challanStatus as keyof typeof ChallanStatusColorCodes
              ],
          }}
        >
          {params?.row?.challanStatus
            ? ChallanStatusDisplay[
                params?.row?.challanStatus as keyof typeof ChallanStatusDisplay
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      type: "actions",
      field: "download",
      headerName: global.download,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      getActions: (params: GridRenderCellParams<any>) => {
        return [
          <>
            <CustomIconButton
              disabled={!params?.row?.pdfUrl}
              children={<FileDownloadIcon />}
              onClick={() => downloadSingleChallan(params?.row?.pdf_Url)}
            />
          </>,
        ];
      },
      flex: 1,
      resizable: false,
    },
  ];

  return (
    <Box>
      {" "}
      <CustomAccordion
        expand={expand}
        renderAccordianTab={
          <Typography variant={typographyConstants.HEADING}>
            {VehicleManagement.challanDetails}
          </Typography>
        }
        renderAccordionBody={
          <Stack gap={"10px"}>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: "15px" }}
            >
              {challanData?.pageData?.totalCount > 1000 && (
                <CustomButton
                  variant={"outlined"}
                  label={buttonLabels.download}
                  onClick={downloadChallans}
                />
              )}
              {functionalitiesAccess.isFetchChallanKycKart ? (
                <>
                  {!(
                    vehicleDetailsByIdData?.lobStatus ===
                      VehicleStatus.INACTIVE ||
                    vehicleDetailsByIdData?.lobStatus === VehicleStatus.SOLD ||
                    vehicleDetailsByIdData?.lobStatus ===
                      VehicleStatus.PENDING_FOR_APPROVAL ||
                    vehicleDetailsByIdData?.lobStatus === VehicleStatus.REJECTED
                  ) && (
                    <Tooltip title={buttonLabels?.refresh} arrow>
                      <RotateRightSharpIcon
                        fontSize="large"
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={() => getLiveChallan()}
                      />
                    </Tooltip>
                  )}
                </>
              ) : null}
            </Box>
            <DataTable
              columns={columns}
              rows={challanData?.challanDetails}
              // hideFooter={true}
              disableColumnMenu
              disableColumnSorting
              headerAlign={"center"}
              getRowId={() => crypto.randomUUID()}
              rowHeight={45}
              loading={loading || liveChallanLoader}
              paginationMode="server"
              onPaginationModelChange={handlePaginationModel}
              rowCount={challanData?.pageData?.totalCount ?? 0}
              paginationModel={pagination?.challanPagination}
              hideColumnSeperator
            />
          </Stack>
        }
        onClick={onclick}
        customBodyStyle={{ padding: "12px" }}
      />
      <SideDrawer
        open={sideDrawerChallan}
        heading={VehicleManagement.linkChallan}
        toggleDrawer={toggleDrawerChallan}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              margin: "10px",
              padding: "10px",
            }}
          >
            <Box>
              <SearchBar
                searchLabel={en.VehicleManagement.searchBookingNumber}
                selectedValue={challanFields?.selectedField}
                onSelect={(val: any) => onSelect(val)}
                data={isArrayNotEmpty(bookings) ? bookings : []}
                onInputChange={(val: any) => onInputChange(val)}
                searchValue={challanFields?.searchField}
                getSearchKey={getSearchKey}
                loading={bookingListLoader}
              />
              {challanFields?.selectedField?.id && (
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "flex-start",
                    marginTop: "20px",
                    gap: "5px",
                  }}
                >
                  <img src={CheckMark} style={{ marginTop: "4px" }} />
                  <HeadingValueTypography
                    heading={VehicleManagement.bookingNo}
                    value={challanFields?.selectedField?.bookingNumber}
                  />
                </Box>
              )}
            </Box>

            <CustomButton
              label={VehicleManagement.linkChallan}
              variant={"contained"}
              sx={{ borderRadius: "5px", width: "335px" }}
              onClick={() =>
                linkChallanToBooking(
                  challanFields?.selectedField?.id,
                  challanFields?.challanData?.id
                )
              }
              loading={vehicleLinkChallanLoader}
              disabled={!challanFields?.selectedField?.bookingNumber}
            />
          </Stack>
        }
      />
    </Box>
  );
};
