import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import masterServices from "../../services/masterServices";
import { toastr } from "react-redux-toastr";
import { actionInterface } from "../../interfaces/commonInterfaces";
import { handleMessage } from "../../../utils/helper";

function* fetchAllCities(action: actionInterface): any {
  const data = yield call(masterServices.getAllCitiesX, action.payload);
  if (data?.status === 200) {
    let cityDataArr = data?.data?.data;
    const cityMapData = new Map();
    if (data?.data?.data) {
      data?.data?.data.map((item: any) =>
        cityMapData.set(item?.name, item?.displayName)
      );
    }
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_CITY_DATA_X_SUCCESS,
      payload: {
        cityData: cityDataArr || [],
        cityMapData: cityMapData,
      },
    });
  } else if (data?.status === 422) {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_CITY_DATA_X_FAILED,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_CITY_DATA_X_FAILED,
    });
  }
}

function* getBranchX(action: actionInterface): any {
  const data = yield call(masterServices.getBranchX, action.payload);
  if (data?.status === 200) {
    let obj: any = {};
    if (action?.payload?.common) {
      obj.branchesList = data?.data;
    } else {
      obj.branches = data?.data;
    }
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_BRANCH_DATA_X_SUCCESS,
      payload: { ...obj },
    });
  } else if (data?.status === 422) {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_BRANCH_DATA_X_FAILED,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_BRANCH_DATA_X_FAILED,
    });
  }
}

function* getModelX(action: actionInterface): any {
  try {
    const response = yield call(masterServices.getModelX, action.payload);

    let modelList: any = response.data.data;

    if (response?.status === 200 || response?.status === 201) {
      if (!modelList?.models?.length) {
        toastr.error("", "No Model Available for this Branch");
      }

      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_SUCCESS,
        payload: { modelList: modelList },
      });
    } else if (response?.status === 422) {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_FAILED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_FAILED,
    });
  }
}

function* getAllModels(action: any): any {
  try {
    const data = yield call(masterServices.getAllModels, action.payload);
    const modelsMapData = new Map();
    const modelsMapImages = new Map();
    if (data?.data?.data?.modelDetails) {
      data?.data?.data?.modelDetails.map((item: any) => {
        modelsMapData.set(item?.name, item?.displayName);
        modelsMapImages.set(item?.name, item?.images);
      });
    }
    const modelMapObj = {
      modelsMapData: modelsMapData,
      modelsMapImages: modelsMapImages,
    }
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_ALL_MODELS_X_SUCCESS,
        payload: modelMapObj,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_ALL_MODELS_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_ALL_MODELS_X_FAILED,
    });
  }
}

function* getAllWarehouse(action: any): any {
  try {
    const data = yield call(masterServices.getAllWarehouse);
    const branchMapData = new Map();
    if (data?.data?.data) {
      data?.data?.data.map((item: any) =>
        branchMapData.set(item?.name, item?.displayName)
      );
    }
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_SUCCESS,
        payload: branchMapData,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_FAILED,
    });
  }
}

function* getChargesCategoriesYOR(action: any): any {
  try {
    const response = yield call(masterServices.getChargesCategoriesYOR);

    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_FAILED,
    });
  }
}

function* masterSagaX() {
  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_CITY_DATA_X_REQUESTED,
    fetchAllCities
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_BRANCH_DATA_X_REQUESTED,
    getBranchX
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_REQUESTED,
    getModelX
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_ALL_MODELS_X_REQUESTED,
    getAllModels
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_REQUESTED,
    getAllWarehouse
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_REQUESTED,
    getChargesCategoriesYOR
  );
}

export default masterSagaX;
