import { CookieTypes, LocalStorage } from "../constants/enum";
import config from "./config.json";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import Cookies from "js-cookie";

const configEnv: any = config;

// use .env file to change the environment
const env = process.env.REACT_APP_NODE_ENV || "dev";
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
const BASE_URL = configEnv[env]["BASE_URL"];
const BASE_URL_UAT = configEnv[env]["BASE_URL_ADMIN"];
const BASE_URL_PAYMENTS = configEnv[env]["BASE_URL_PAYMENTS"];
const KYC_VERIFICATION_BASE_URL_MANUAL =
  configEnv[env]["KYC_VERIFICATION_BASE_URL_MANUAL"];
const KYC_VERIFICATION_BASE_URL_ADMIN =
  configEnv[env]["KYC_VERIFICATION_BASE_URL_ADMIN"];
const OPERATOR_URL = configEnv[env]["OPERATOR_URL"];
const MITC_FORM_URL = configEnv[env]["MITC_FORM_URL"];
const FREEDO_X_URL = configEnv[env]["FREEDO_X_URL"];
const FREEDO_BIZ_X_URL = configEnv[env]["FREEDO_BIZ_URL"];
const FREEDO_MUNIM_X_URL = configEnv[env]["FREEDO_MUNIM_X_URL"];
const FREEDO_IMAGE_URL = configEnv[env]["FREEDO_IMAGE_URL"];

const versioning1 = "v1/";
const versioning = "v2/";
const versioning3 = "v3/";
const admin = "admin/";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyD24N8phlHbRRcyDRLUh6QjZWdmLquMo0U";
const L_O_B = ["lob"];
const aliasFreedoLtr = "freedo-ltr/";
const aliasFreedoGrahak = "freedo-grahak/";
const aliasVms = "vms/";
const aliasFreedoBiz = "freedo-biz/";
const aliasFreedoMunim = "freedo-munim/";
const aliasTitanShield = "titan-shield/";
const aliasFreedoInsights = "freedo-insights/";

const API_URLS = {
  login: "loginV2",
  signup: "signup",
  profile: "titan-shield/authentication/v1/authorize",
  nonOcrVerification: "nonOcrVerification",
  dashboardStatic: "getTotalSummary",
  getFeedbacks: "getAllFeedbacks?",
  getTotalVehicleByBranch: "getTotalVehicleByBranch?",
  getLocationByCity: "getLocationByCity?",
  getAllBooking: "getAllbooking?",
  getAllLocation: "getAllLocation?",
  dashboardDisplayTabStatic: "getBokingListForDashboard",
  warehouseApproval: "warehouseApproval",
  getCalendarView: "getCalendarView",
  getbookingById: "getbookingById/",
  fetchIotData: "fetchIotData",
  uploadUserConsent: "uploadUserConsent/",
  waiveOff: "waiveOffV2",
  deleteExtracharge: "deleteExtracharge",
  getInvoiceById: "getInvoiceById",
  changeBookingStatusAdmin: "changeBookingStatusAdmin",
  VehicleRegNumberId: "vehicleManagement/VehicleRegNumberId?",
  VehicleRegNumberIdForBackdateBooking:
    "vehicleManagement/VehicleRegNumberIdForBackdateBooking?",
  vehicleModelList: "vehicleModelList?",
  getVehicleModelList: "getVehicleModelList?",
  getAvailableVehiclesForModel: "getAvailableVehiclesForModel?",
  changeVehicle: "changeVehicle",
  uploadOdometerReadings: "uploadOdometerReadings/",
  getPickUpLocation: "getPickUpLocation?",
  deleteLocation: "changeLocationStatus/",
  addLocation: "addLocation",
  getAllVehicle: "getAllVehicle?",
  getBranchSlotsForBooking: "getBranchSlotsForBooking?",
  getAvailablePackageForextend: "getAvailablePackageForextend?",
  getcoupons: "getcouponsV2",
  getActivecoupons: "getActivecoupons",
  extendRideAdmin: "/extendRide",
  userLogout: "titan-shield/authentication/v1/logout",
  getUserDataAll: "getAllUser?",
  checkRefundStatus: "checkRefundStatusAdmin/",
  getAdminUserById: "getAdminUserById",
  getUserByIdV2: "getUserByIdV2",
  editDeliveryAndHelmetOption: "editDeliveryAndHelmetOption",
  storeExtracharge: "storeExtrachargeV3",
  makePartialPayment: "makePartialPaymentV3",
  resendPaymentLink: "resendPaymentLinkV2",
  addRemark: "addRemark",
  getAllRemarks: "getAllRemarks?",
  startRide: "startRideV2?",
  startRideV4: "booking/startRideV4",
  getAllBranch: "getAllBranch?",
  getAllBranchV2: "getAllBranchV2?",
  storeVehicle: "storeVehicle",
  storedocument: "storedocument",
  operatorStoredocument: "public/operatorStoredocument",
  changeVehicleStatus: "changeVehicleStatus",
  getVehicleById: "getVehicleById/",
  getModelListById: "getModelListById?",
  changeUserStatus: "changeUserStatus",
  updateAdminUser: "updateAdminUser",
  getEarlyEndRideCharges: "getEarlyEndRideCharges/",
  prematureEndRide: "prematureEndRideV3",
  storeExtrachargeForEarlyEndRide: "storeExtrachargeForEarlyEndRideV3",
  resendLinkForExtracharge: "resendLinkForExtrachargeV2",
  getAllPayments: "getAllPayments?",
  getAllPackage: "getAllPackage?",
  deletePackage: "changePackageStatus/",
  addPackage: "addPackage/",
  editPackage: "editPackage",
  getAllRentalPlan: "getAllRentalPlan?",
  getAllExtraChargesOfModel: "getAllExtraChargesOfModel/",
  storeRentalPlan: "storeRentalPlan",
  getRentalPlanById: "getRentalPlanById/",
  getModelDetailsById: "getModelDetailsById/",
  getVehicleForBlocking: "getVehicleForBlocking",
  getBlockedVehicleList: "getBlockedVehicleList?",
  blockVehicle: "blockVehicle",
  unblockVehicle: "unblockVehicle",
  changeRentalPlanStatus: "changeRentalPlanStatus/",
  getVehicleUploadFormat: "getVehicleUploadFormat",
  uploadInvetoryFile: "uploadInvetoryFile",
  bulkUploadvehicles: "bulkUploadvehicles",
  getpausedslots: "getpausedslots",
  getpausedslotsV2: "getpausedslotsV2?",
  getpausedslotsV2_2: "getpausedslots?",
  addPauseslots: "pauseslots",
  editpausedslots: "editpausedslots",
  getpausedslotsbyDate: "getpausedslotsbyDate?",
  deletepausedslots: "deletePausedSlots",
  getBranchSlots: "getBranchSlots?",
  configureModulesForCities: "configureModulesForCities",
  configureModulesForBranch: "configureModulesForBranch",
  getUserReports: "getUserReports?",
  getBookingReports: "getBookingReports?",
  getPaymentReports: "getPaymentReports?",
  getPaymentReconciliationReports: "getBookingPaymentReports?",
  getAllAdminUser: "getAllAdminUser?",
  getBranchById: "getBranchById/",
  createBranchV2: "createBranchV2",
  createBranch: "createBranch",
  editBranch: "editBranch",
  updateBranchStatus: "updateBranchStatus/",
  getAllVehicleInventoryListByMonth: "getAllVehicleInventoryListByMonth?",
  getVehicleInventoryByDate: "vehicleManagement/getVehicleInventoryByDate?",
  getAdminUserByIdV2: "getAdminUserByIdV2/",
  createAdmin: "createAdmin",
  updateAdminUsers: "updateAdminUsers/",
  updateAdminUserStatus: "updateAdminUserStatus/",
  getAccessRights: "getMaster/access_right_category/0",
  updateAdminContact: "updateAdminContact/",
  revenueReport: "revenueReport?",
  genericReports: "business-reports/sendCsvFields/",
  downloadGenericReports: "business-reports/exportReport/",
  getAllCities: "getAllCitiesV2?",
  addCity: "addCity",
  getCityById: "getCityById/",
  editCity: "editCity",
  getAllCategory: "getAllVehicleCategoryV2?",
  addVehicleCategory: "addVehicleCategory",
  editVehicleCategory: "editVehicleCategory",
  getCategoryById: "getCategoryById/",
  deleteCategory: "deleteCategory/",
  getAllVehicleModel: "getAllVehicleModelV2?",
  addVehicleModel: "addVehicleModel",
  deleteModel: "deleteModel/",
  editVehicleModel: "editVehicleModel",
  getModelById: "getModelById/",
  removeOverviewImages: "removeOverviewImages/",
  checkUserExist: "checkUserExist",
  getAvailableVehicleFrLocation: "getAvailableVehicleFrLocation?",
  createManualBookingV2: "v1/manualBooking",
  getActiveCoupons: "getActiveCouponsV2",
  getCitiesByRealId: "getCitiesByRealId",
  getAllBranchModulesConfig: "getAllBranchModulesConfig?",
  changePlanPackageStatus: "changePlanPackageStatus",
  getAllActiveCancelRemarks: "getAllActiveCancelRemarks",
  getBookingCountByVehicleId: "getBookingCountByVehicleId",
  getAllCancelRemarks: "getAllCancelRemarks",
  getCancelRemarksById: "getCancelRemarksById",
  addCancelRemarks: "addCancelRemarks",
  updateCancelRemarks: "updateCancelRemarks",
  deleteCancelRemarks: "deleteCancelRemarks",
  sendOtpToEmergencyNumber: "sendOtpToEmergencyNumber",
  verifyOtpEmergencyNumber: "verifyOtpEmergencyNumber",
  verifyEmergencyNumberManually: "verifyEmergencyNumberManually",
  getAdvertisementById: "getAdvertisementById/",
  editAdvertisement: "editAdvertisement",
  addAdvertisement: "addAdvertisement",
  getFullDayPauseSlots: "getFullDayPauseSlots?",
  getBranchDeliveryCharges: "getBranchDeliveryCharges",
  getAllOperator: "getAllOperator?",
  findAllOperatorBuisnessType: "findAllOperatorBuisnessType",
  getAllDocumentTypes: "getAllDocumentTypes",
  StoreOperator: "StoreOperator",
  operatorEdit: "operatorEdit",
  generateUserName: "generateUserName",
  deleteOperator: "deleteOperator",
  approveOperator: "approveOperator",
  clearDLDetails: "clearDLDetails",
  editOperator: "operatorEdit",
  getOperatorById: "getOperatorById",
  getSourceId: "getSourceId?",
  saveOperator: "operatorEditV2",
  linkedAccount: "linked-account",
  stakeholder: "stakeholder",
  productConfiguration: "product-configuration",
  getVehicleForApproveReject: "getVehicleForApproveReject?",
  verifyVehicleByAdmin: "verifyVehicleByAdmin",
  getModelsByBranch: "getModelsByBranch?",
  getTaskList: "taskManagement/taskmasterlist?",
  getTaskDetailsById: "taskManagement/taskmasterlist",
  createTask: "taskManagement/taskmasterlist/createMasterTaskList",
  editTask: "taskManagement/taskmasterlist",
  deleteTask: "taskManagement/taskmasterlist/",
  getTaskListSequence: "taskManagement/warehouselist/warehouseId",
  getTaskWarehouseList: "taskManagement/warehouselist",
  editTaskSequence: "taskManagement/warehouselist",
  taskSuggestion: "taskManagement/taskmasterlist/task-suggestion",

  makePartialPay: "v1/makePartialPay",
  getAmountLeftExtraCharges: "v1/getAmountLeftExtraCharges",
  getbookingDetailsById: "v1/getbookingDetailsById",
  getPaymentBookingById: "v1/getPaymentBookingById",
  getChargesDetailByTid: "v1/getChargesDetailByTid",
  getExtendBookingDataById: "v1/getExtendBookingDataById",
  fileUpload: "v1/fileUpload",
  paymentModes: "v1/paymentModes",
  updateOdometerReadings: "v1/uploadOdometerReadings",
  getBookingExcessCharges: "getBookingExcessCharges",
  deleteExtendRide: "v1/deleteExtendRide",
  storeExcessCharge: "v1/storeExcessCharge",
  waiveOff1: "v1/waiveOff",
  deleteExtracharge1: "v1/deleteExtracharge",
  updateDeliveryAndHelmetOption: "v1/editDeliveryAndHelmetOption",
  refundPercentList: "v1/refund-percent-list",
  refund: "v1/refund",
  refundDetailsByTid: "v1/refundDetailsByTid",
  uploadOdometerReadings1: "v1/uploadOdometerReadings",
  storeExtrachargeApi: "v1/storeExtracharge",
  getTaskStatus: "taskManagement/subTasks/bookingId",
  getTaskSummary: "taskManagement/tasks/?bookingId",
  getUserList: "taskManagement/users/",
  postTaskAsignment: "taskManagement/tasks/createTask",
  generateInvoice: "getInvoiceByIdV2/bookingId/",

  forgotPassword: "public/forgotPassword/",
  setPassword: "public/setPassword",
  changePassword: "changePassword",
  linkVerification: "public/verifyKey",
  unlockAccount: "public/forgotPassword",
  verifyOTP: "freedo-biz/user/v1/login/verify-otp",
  loginV1: "user/v1/login",
  resendOtp: "freedo-biz/user/v1/login/resend-otp",

  generateMitc: "createMitcForm/",
  upgradeMitc: "createMitcFormForVehicleUpgrade/",
  mitcStatus: "checkMitcStatus",
  getStartRideDelay: "v1/getStartRideDelay",
  cancellationCharges: "cancellation-charges/",
  bulkUploadZipLambda: "bulkUploadLambda/",
  getAllActiveWaveoffRemarks: "getMaster/waive_off_remarks/0",
  odometerHistory: "getOdometerHistory/",
  getMasterConfig: "/public/getMaster/configs",
  editVehicleBranch: "editVehicleBranch",
  getAllTransferRequest: "getAllVehicleTransferRequests",
  getTransferRequestDetail: "getAllVehicleTransfer/",
  getChallanList: "getChallanDetailByVehicleId",
  searchBookingRecords: "searchBookingRecords/",
  challanLink: "challanLink",
  challanUnLink: "challanUnLink",
  getChallanStatus: "getChallanStatus/",
  getChallanSubCharges: "getChallanSubCharges/",
  modifyChallanSubCharges: "modifyChallanSubCharges",
  iotManagement: "vms/v1/iot?",
  vms: "vms/",
  iot: "iot/",
  addIotDevice: "device",
  getVendors: "vendor",
  logout: "titan-shield/authentication/v1/logout",
  newFeedBackURL: "dragon-buzz/feedback/v1/list?",
  feedBackById: "dragon-buzz/feedback/v1/getFeedbackById/",
  // getFeedbackByBookingId : "dragon-buzz/feedback/getFeedbackByBookingId/",
  getFeedbackByBookingId: "dragon-buzz/feedback/v1/list?bookingId",
  executiveFeedback: "dragon-buzz/feedback/v1/review",

  //Vehicle Image Upload
  getSignedUrls: "getSignedUrls",
  saveVehicleImages: "vehicle/saveImages",
  getVehicleImages: "booking/getAllUploadedImages",

  // commonAPIs
  getAllCitiesX: "city/v1",
  getBranchX: "branch/v1",
  getAllModelsX: "vms/model/v1",
  getMasterPlanDurations: "plan/v1/master",
  getAllStates: "state/v1",

  //freedoX
  addUserX: "customer/sendOtpForSignUp",
  plans: "plan/",
  getMatchingRentalPlans: "all?",
  getModel: "plan/v1/models",
  createManualBookingX: "booking/v1",
  calculateChargesX: "booking/v1/calculate/charges",
  getPlansX: "plan/v1/availables",
  getWarehouseX: "branch/v1",

  branch: "branch/",
  delivery: "delivery",

  // MANUALBOOKING
  getModelList: "freedo-ltr/search/v1",
  getSlotPauseList: "freedo-ltr/booking/v1/slot-pause",

  // surge management
  createSurge: "surge/v1",
  getSurgeList: "surge/v1/list",
  updateSurgeStatus: "surge/v1/status",
  getModelListByBranches: "search/v1/model-list",
  getSurgeById: "surge/v1",
  updateSurge: "surge/v1",

  //Business management
  deliveryBranch: "branch/v1/delivery",
  addDeliveryBranch: "branch/v2/delivery",

  // vehicle management
  getBookings: aliasFreedoLtr + "booking/v1",
  getBlockedVehicles: aliasVms + "vehicle/v1/blocked",
  getVehiclesList: aliasVms + "vehicle/v2",
  updateVehicleStatus: aliasVms + "vehicle/v1",
  addNewVehicleDetails: aliasVms + "vehicle/v1",
  approveRejectVehicle: aliasVms + "vehicle/v1/approval",
  getIotDevices: aliasVms + "iot/v2",
  assignIotDevice: aliasVms + "iot/v2/device/assign",
  fetchVehcileModelList: aliasVms + "model/v2",
  linkChallanVehicle: aliasVms + "vehicle/v1/linkChallan",
  unlinkChallanVehicle: aliasVms + "vehicle/v1/unlinkChallan",
  fetchVehicleColourList: aliasVms + "vehicle/v1/colours",
  fetchVehicleDetailsById: aliasVms + "vehicle/v2",
  editVehicleDetails: aliasVms + "vehicle/v1/edit",

  // iot management
  addIotDeviceRentals: aliasVms + "iot/v1/device",
  pingDevice: aliasVms + "iot/v1/ping",
  updateIotDeviceData: aliasVms + "iot/v1/update",
  vehicleIotDevices: "vehicle/iotDevice?",
  vehicleIotDevicesOdometer: "vehicle/iotDevice/odometerReading?",
  iotBulkUpload: aliasVms + "iot/v1/bulkUpload",
  iotBulkSampleSheetDownload: aliasVms + "iot/v1/sheet",
  unAssignIotDevice: aliasVms + "iot/v2/device/unAssign",
  deleteVehicleDocuments: aliasVms + `vehicle/document/v1`, // delete vehicle document routes
  addVehicleDocuments: aliasVms + `vehicle/document/v1`, // add vehicle document routes
  downloadVehicleDocument: aliasVms + `vehicle/document/v1`, // download vehicle document routes
};

// used to get the api url in revamped code (id is the dynamic id in the API url)
export const getApiUrl = (id = "", name = "") => {
  return {
    // authentication
    forgotPassword: aliasFreedoBiz + `user/v1/login/forgot-password`,
    changePassword: aliasFreedoBiz + `user/v1/change-password`,
    linkVerification: aliasFreedoBiz + `user/v1/link/verify`,
    setPassword: aliasFreedoBiz + `user/v1/public/set-password`,
    unlockAccount: aliasFreedoBiz + `user/v1/login/unlock-account`,

    // Plan management endpoints
    getDefaultPlan: aliasFreedoLtr + "plan/v1/default",
    getPlanList: aliasFreedoLtr + "plan/v1/list",
    getPlanById: aliasFreedoLtr + `plan/v1/${id}`,
    createAndUpdatePlan: aliasFreedoLtr + "plan/v1",
    activatePlan: aliasFreedoLtr + `plan/v1/activate/${id}`,
    deactivatePlan: aliasFreedoLtr + `plan/v1/deactivate/${id}`,

    // Surge management endpoints
    createSurge: aliasFreedoLtr + "surge/v1",
    getSurgeList: aliasFreedoLtr + "surge/v1/list",
    updateSurgeStatus: aliasFreedoLtr + "surge/v1/status",
    getModelListByBranches: aliasFreedoLtr + "search/v1/model-list",
    getSurgeById: aliasFreedoLtr + `surge/v1/${id}`,
    updateSurge: aliasFreedoLtr + "surge/v1",

    // freedo-grahak endPoints
    searchCustomer:
      aliasFreedoGrahak + `profile/${versioning1}search?mobileNumber=`,
    addAddress: aliasFreedoGrahak + `profile/${versioning1}address`,
    registerUser: aliasFreedoGrahak + `profile/v1`,
    verifyOTP: aliasFreedoGrahak + `profile/${versioning1}otp/verify`,
    resendOTP: aliasFreedoGrahak + `profile/${versioning1}otp/resend`,
    getCustomerAddressList:
      aliasFreedoGrahak + `profile/${versioning1}${id}/address`,
    getCustomerProfileDataURL:
      aliasFreedoGrahak + `profile/${versioning1}${id}`,
    getCustomerKycTrustScore:
      aliasFreedoGrahak + `trust-score/${versioning1}${id}`,
    blockUnblockCustomerURL: aliasFreedoGrahak + `profile/${versioning1}${id}`,
    deleteCustomerURL: aliasFreedoGrahak + `profile/${versioning1}${id}`,
    getCustomerEmergencyContactURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}${id}`,
    addEmergencyContactURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}`,
    updateEmergencyContactURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}`,
    sendEmergencyVerificationOtpURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}`,
    verifyEmergencyOtpURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}otp`,
    resendEmergencyOtpURL:
      aliasFreedoGrahak + `emergency-contact/${versioning1}otp/${id}`,

    /* ----------------- KYC ----------------------- */
    getIndividualKycDetailURL: aliasFreedoGrahak + `kyc/${versioning1}`,
    getKycSignedURL: aliasFreedoGrahak + `attachment/${versioning1}`,
    postDocumentsToS3URL: aliasFreedoGrahak + `kyc/${versioning1}${id}`,
    postDocumentsToS3URLOtherDocs: aliasFreedoGrahak + `kyc/${versioning}${id}`,
    putManualKycVerificationURL:
      aliasFreedoGrahak + `kyc/${versioning1}${id}/verify`,
    deleteCustomerKycURL: aliasFreedoGrahak + `kyc/${versioning1}${id}/delete`,
    unverifyCustomerKycURL:
      aliasFreedoGrahak + `kyc/${versioning1}${id}/unverify`,

    // Manual booking endpoints
    createBooking: aliasFreedoLtr + "booking/v1/",
    deliverySlots: "/slots?",

    // Master Endpoints
    getMasterPaymentModes: "master/v1/branch-config/payment/modes?",
    paymentLinkGeneration: "freedo-munim/payment/v1/",
    calculateCharges: aliasFreedoLtr + "booking/v1/calculate-charges",
    getCities: "city/v1?",
    getCityDetails: "city/v1/",
    getCancellationReasons:
      aliasFreedoLtr + "booking/v1/reasons/CANCELLATION_REASON",
    getBookingCancellationCharges:
      aliasFreedoLtr + `booking/v1/${id}/cancel/charges?`,
    getModelListByBranch: aliasFreedoLtr + "search/v1/model-list", // models list by branch
    getMasterChargesServiceURL:
      aliasFreedoLtr + `booking/${versioning1}charges`, // all charges type list
    getPaymentModes: `operator/${versioning1}config/branch/${id}`,
    getMasterModels: aliasVms + "model/v1",
    getMasterPlanDurations: aliasFreedoLtr + "plan/v1/master",
    getPaymentStatus: `freedo-munim/payment/v1/${id}`,
    getBranchDefaultConfig: aliasFreedoBiz + "branch/v1/config/",
    getCitiesBasedOnMultipleStates: aliasFreedoBiz + "city/v1", // Get cities based on multiple states
    getStatesFromPincode: aliasFreedoBiz + `state/v1/state-pincodes`,
    // business Management
    getCityList: "city/v2",
    getCityStatistics: "city/v1/overview",
    getWarehouseStatistics: "branch/v1/overview",
    getSignedUrl: "signed-url/v1",
    viewAddUpdateWarehouse: "branch/v1/",
    getWarehouseList: "branch/v2",
    getAddUpdateCity: "city/v1/",
    addNewUser: aliasFreedoBiz + "user/v1", // Add New User - Role Management
    getRolesList: aliasTitanShield + "user-management/v1/roles", // Get Roles List - Role Management
    saveRole: aliasFreedoBiz + `user/v1/${id}/role`, // Save Role - Role Management
    getAllUsersList: aliasFreedoBiz + "user/v1/list", // Get All Users - Role Management
    getHelmetConfigList: aliasFreedoLtr + "helmet/v1/list",
    addHelmetConfig: aliasFreedoLtr + "helmet/v1",
    getUpdateHelmetConfig: aliasFreedoLtr + `helmet/v1/${id}`,
    getUserDetailsByUserId: aliasFreedoBiz + `user/v1/${id}`, // Fetch User Details by UserId - Role Management
    blockUser: aliasFreedoBiz + `user/v1/${id}/block`, // Block user - Role Management
    unblockUser: aliasFreedoBiz + `user/v1/${id}/unblock`, // Unblock user - Role Management
    deleteUser: aliasFreedoBiz + `user/v1/${id}`, // Delete user - Role Management
    updateUser: aliasFreedoBiz + `user/v1/${id}`, // Update User - Role Management
    getBlockReasons: aliasFreedoBiz + "user/v1/reasons", // Get Block Reasons - Role Management

    // customer management endpoints
    getCustomerBookingStats: aliasFreedoLtr + "booking/v1/stats",
    getCustomerDashBoardStats: aliasFreedoGrahak + "profile/v1/stats",
    getCustomerBookingList: "freedo-ltr/booking/v1",

    //booking management endpoints
    getBookingDetails: aliasFreedoLtr + `booking/v1/${id}/details`,
    bookingList: aliasFreedoLtr + "booking/v1",
    bookingStats: aliasFreedoLtr + "booking/v2/stats",
    getPlansAddonDetails: aliasFreedoLtr + `booking/v1/${id}/plan-addons`,
    getModificationHistory: aliasFreedoLtr + `booking/v1/${id}/modifications`,
    getPaymentOverviewList: aliasFreedoLtr + `booking/v1/${id}/payments`,
    getAvailableVehicles: aliasFreedoLtr + `booking/v1/${id}/getVehicles`,
    assignVehicle: aliasFreedoLtr + `booking/v1/${id}/vehicle/assign`,
    checkDelay: aliasFreedoLtr + "booking/v1/check-delay",
    startRide: aliasFreedoLtr + `booking/v1/${id}/start-ride`,
    getVehicleOdometerHistory:
      aliasFreedoLtr + `booking/v1/${id}/odometer/history?page=1&pageSize=100`,
    getExtensionAvailability:
      aliasFreedoLtr + `booking/v1/${id}/extension/availability`,
    getPendingCharges:
      aliasFreedoLtr + `booking/${versioning1}${id}/pending-charges`,
    endRide: aliasFreedoLtr + `booking/${versioning1}${id}/end-ride`,
    completeRide: aliasFreedoLtr + `booking/${versioning1}${id}/complete-ride`,
    completeRidePayment:
      aliasFreedoLtr + `booking/${versioning1}${id}/complete-ride/payment`,
    getVehicleExtension:
      aliasFreedoLtr + `booking/v1/${id}/extensions?page=1&pageSize=100`,
    unassignVehcile: aliasFreedoLtr + `booking/v1/${id}/vehicle/unassign`,

    //Modify booking details
    calculateAddressCharges:
      aliasFreedoLtr + `booking/v1/${id}/calculate/address-addons`,
    calculateModelModificationCharges:
      aliasFreedoLtr + `booking/v1/${id}/calculate/model`,
    calculatePlanModificationCharges:
      aliasFreedoLtr + `booking/v1/${id}/calculate/plan`,
    modifyBookingPlan: aliasFreedoLtr + `booking/v1/${id}/modify/plan`,
    modifyAddressAddon:
      aliasFreedoLtr + `booking/v1/${id}/modify/address-addons`,
    modifyBookingModel: aliasFreedoLtr + `booking/v1/${id}/modify/model`,
    getExtensionPlans: aliasFreedoLtr + `booking/v1/${id}/extension/plan`,
    getCalculatedExtensionCharges:
      aliasFreedoLtr + `booking/v1/${id}/extension/calculate-charges`,
    getRecentModificationsHistory:
      aliasFreedoLtr + `booking/v1/${id}/recent-modifications`,
    getModificationReasons: aliasFreedoLtr + `booking/v1/reasons/${id}`,
    availableBookingModels:
      aliasFreedoLtr + `booking/v1/${id}/${name}/getVehicles`,

    createBookingExtension: aliasFreedoLtr + `booking/v1/${id}/extension`,
    deleteBookingExtension: aliasFreedoLtr + `booking/v1/${id}/extension/`,
    getCustomerBookingsStatsV3: aliasFreedoLtr + `booking/v3/stats`,

    //bookingMgt Upload Documents
    generateSignedUrls: aliasFreedoLtr + "attachment/v1",
    uploadBookingDocuments: aliasFreedoLtr + `booking/v1/${id}/documents`,

    // challans & Extras
    getAdditionalChargesURL:
      aliasFreedoLtr + `booking/${versioning1}${id}/charges`,
    saveAdditionalChargesURL:
      aliasFreedoLtr + `booking/${versioning1}${id}/additional-charges`,
    createOrderIdForChargesURL:
      aliasFreedoLtr + `booking/${versioning1}${id}/payment`,
    initiatePaymentForOrder: aliasFreedoMunim + `payment/${versioning1}`,
    waiveOffBookingChargesURL:
      aliasFreedoLtr + `booking/${versioning1}${id}/charges`,
    waiveOffReasonsURL: aliasFreedoLtr + `booking/${versioning1}reasons`,

    deleteBookingModification: aliasFreedoLtr + `booking/v1/${id}/modify`,

    // operator config by branch
    getOperatorConfigByBranchURL:
      aliasFreedoBiz + `operator/${versioning1}config/branch`,

    //bookingMgt Upload Vehicle images
    generateVehicleSignedUrl: aliasFreedoLtr + "booking/v1/generate-urls",
    uploadVehicleImages:
      aliasFreedoLtr + `booking/v1/${id}/vehicle-images/START_RIDE`,
    updateVehicleImages: aliasVms + `upload/v1/${id}/updateImage`,
    // iot management
    getIotDevices: aliasVms + "iot/v2",
    getIotDeviceDetails: aliasVms + `iot/v2/device/activity/${id}`,
    getIotVendors: aliasVms + "iot/v1/vendor",

    // Vehicle management endpoints
    getVehicleById: aliasVms + `vehicle/v2/${id}`,
    getChallanLive: aliasVms + "vehicle/v1/challanInfo",
    getChallanList: aliasVms + "vehicle/v1/challans",
    getAssignedIotDevices: aliasVms + `iot/v1/assignedDevices/${id}`,
    getPingHistory: aliasVms + "iot/v1/ping",
    getBookings: aliasFreedoLtr + "booking/v1",
    getBlockedVehicles: aliasVms + "vehicle/v1/blocked",
    getVehiclesList: aliasVms + "vehicle/v2",
    updateVehicleStatus: aliasVms + "vehicle/v1",
    addNewVehicleDetails: aliasVms + "vehicle/v1",
    approveRejectVehicle: aliasVms + "vehicle/v1/approval",
    assignIotDevice: aliasVms + "iot/v2/device/assign",
    fetchVehcileModelList: aliasVms + "model/v2",
    unAssignIotDevice: aliasVms + "iot/v2/device/unAssign",
    linkChallanVehicle: aliasVms + "vehicle/v1/linkChallan",
    unlinkChallanVehicle: aliasVms + "vehicle/v1/unlinkChallan",
    fetchVehicleColourList: aliasVms + "vehicle/v1/colours",
    cancelBooking: aliasFreedoLtr + `booking/v1/${id}/cancel`,
    editVehicleDetails: aliasVms + "vehicle/v1/edit",
    deleteVehicle: aliasVms + "vehicle/v1",
    getSignedUrlVehicle: aliasVms + "upload/v1", // generate signed url to upload vehicle documents
    getVehicleImages: aliasVms + `upload/v1/downloadVehicleImages/${id}`, // api to get vehicle documents on basic of vehicle ids
    uploadBulkUploadSheet: aliasVms + "vehicle/v1/bulk",
    bulkRequestInitiate: aliasVms + "vehicle/v1/bulk/initiate",
    getUplodedDocuments: aliasVms + `vehicle/document/v1`, // get vehicle document
    downloadExcelSheet: aliasVms + "vehicle/v1/bulk/sheet",

    // Inventory management endpoints
    getCalendarData: aliasFreedoLtr + "inventory/v1/calendar-view",
    getTranferVehicleRequest: aliasVms + "vehicle/v1/transfer/request",
    getAllVehicleTranfers: aliasVms + "vehicle/v1/transfer",

    // Payment management endpoints
    getPaymentList: aliasFreedoLtr + "payment/v1/payments",
    getRefundDetails:
      aliasFreedoLtr + `payment/v1/${id}/refund?page=1&size=100`,

    //get vehicle dashborad stats
    getVehicleStats: aliasVms + `vehicle/${versioning1}stats`,

    generateReport: aliasFreedoInsights + `reports/${versioning1}${id}`,

    //operator management
    getOperatorLeadList: aliasFreedoBiz + `operator/${versioning1}lead/list`,
    getOperatorLeadDetails:
      aliasFreedoBiz + `operator/${versioning1}lead/${id}`,
    saveOperatorDetails: aliasFreedoBiz + `operator/${versioning1}lead/${id}`,
    initiateESign: aliasFreedoBiz + `operator/${versioning1}e-sign`,
    verifyEmail: aliasFreedoBiz + `operator/${versioning1}verify-email`,
    approveLead: aliasFreedoBiz + `operator/${versioning1}lead/approve/${id}`,
    getOperatorList: aliasFreedoBiz + `operator/${versioning1}`,
    getOperatorDetails: aliasFreedoBiz + `operator/${versioning1}${id}`,
    approveRejectOperatorDocument:
      aliasFreedoBiz + `operator/${versioning1}kyc/approve/${id}`,
    saveOperatorDocument:
      aliasFreedoBiz + `operator/${versioning1}kyc/save/${id}`,
    submitOperatorDocument:
      aliasFreedoBiz + `operator/${versioning1}kyc/submit/${id}`,
    modifyOperatorConfig: aliasFreedoBiz + `operator/${versioning1}config`,
    viewAgreement: aliasFreedoBiz + `operator/${versioning1}agreement/${id}`,
  };
};

const GRAPH_API_URLS = {
  metaBaseReport: "metabase/reports",
};

export const httpLink = new HttpLink({
  uri: BASE_URL + GRAPH_API_URLS.metaBaseReport,
});

export const authLink = new ApolloLink((operation, forward) => {
  // const tokenData = localStorage.getItem(LocalStorage.token);
  const tokenData = Cookies.get(CookieTypes.TOKEN);

  const { token } = JSON.parse(tokenData ?? "");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  return forward(operation);
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ resultCaching: false }),
});

const getMasterData = (params: any) => {
  switch (params.type) {
    case "Country":
      return `getMaster/country/1`;
    case "State":
      return `getMaster/state/${params.id}`;
    case "City":
      return `getMaster/city/${params.id}`;
    case "CityStatus":
      return `getMaster/status_master_cities/0`;
    case "Advertisement":
      return `getMaster/advertisement/0`;
    case "Relations":
      return `getMaster/relations/0`;
    case "HomeDeliveryCities":
      return `getMaster/cities_delivery_charges/${params.id}`;
    case "HomeDeliveryCitiesNew":
      return `getHomeDeliveryCharges/${params.id}`;
    case "BusinessManagement":
      return `getMaster/modules_config_master/0`;
    case "VehicleModel":
      params.id = params.id && params.id !== 0 ? params.id : 0;
      return `getMaster/vehicle_model/${params.id}`;
    case "VehicleColor":
      return `getMaster/vehicle_color/0`;
    case "EmissionStandards":
      return `getMaster/emission_standards/0`;
    case "Area":
      return `/getMaster/area/${params.id}`;
    case "Location":
      return `/getMaster/location/${params.id}`;
    case "DocumentType":
      return `getMaster/documen_type_master/0`;
    case "Vehicle":
      return `getMaster/vehicle/0`;
    case "VehicleType":
      return `getMaster/vehicle_type/0`;
    case "VehicleCategory":
      return `getMaster/vehicle_category/0`;
    case "BookingStatus":
      return `getMaster/booking_status/${params.id}`;
    case "PaymentStatus":
      return `getMaster/payment_status/${params.id}`;
    case "UserStatus":
      return `getMaster/user_status_master/0`;
    case "VehicleStatus":
      return `getMaster/vehicle_status_master/0`;
    case "VehicleInvetoryStatus":
      return `getAllVehicleInventoryStatus`;
    case "GetPackages":
      return `getMaster/package_master/0?${
        params.id ? "city_id=" + params.id : ""
      }`;
    case "Type":
      return `getMaster/coupon_code_type/0`;
    case "Slots":
      return `getMaster/time_slots/0`;
    case "IotType":
      return `getMaster/iot_type_master/0`;
    case "VendorCode":
      return `getMaster/vendor_master/0`;
    case "QR":
      return `getMaster/qr_options_master/0`;
    case "ChargeCategory":
      return `getMaster/charge_category/0`;
    case "ChargeSubCategory":
      return `getMaster/charge_name/${params.id}`;
    case "CouponCodeType":
      return `getMaster/coupon_code_type/0`;
    case "Status":
      return `getMaster/status_master/0`;
    case "Aggregator":
      return `getMaster/aggregator/0`;
    case "Brand":
      return `getMaster/brands/0`;
    case "HelmetStatus":
      if (!params.id) {
        return `getMaster/helmet_category/0`;
      } else {
        return `getMaster/helmet_category/0?branch_id=${params.id}`;
      }
  }
};

export {
  BASE_URL,
  BASE_URL_UAT,
  FREEDO_X_URL,
  API_URLS,
  GRAPH_API_URLS,
  KYC_VERIFICATION_BASE_URL_MANUAL,
  KYC_VERIFICATION_BASE_URL_ADMIN,
  getMasterData,
  versioning1,
  versioning,
  YOUR_GOOGLE_MAPS_API_KEY,
  BASE_URL_PAYMENTS,
  OPERATOR_URL,
  MITC_FORM_URL,
  versioning3,
  admin,
  FREEDO_BIZ_X_URL,
  L_O_B,
  FREEDO_MUNIM_X_URL,
  FREEDO_IMAGE_URL,
  aliasFreedoLtr,
  GOOGLE_MAPS_API_KEY,
  aliasVms,
};
