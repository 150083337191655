import { toastr } from "react-redux-toastr";
import { Task } from "redux-saga";
import {
  call,
  cancel,
  put,
  cancelled,
  delay,
  take,
  takeLatest,
} from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import { actionInterface } from "../../interfaces/commonInterfaces";
import { YORBookingDetailsServices } from "../../services";
import * as actionTypes from "../actions/actionTypes";
import { BOOKING_STATUS_ENUM } from "../../constantsX/booking";
import { CUSTOMER_MANAGEMENT_ACTIONS } from "../../../rentals-src/redux";

function* getYORBookingDetails(action: actionInterface): any {
  if (action?.payload?.id) {
    try {
      const data = yield call(
        YORBookingDetailsServices.getYORBookingDetails,
        action.payload
      );
      if (data?.status === 200) {
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_DETAILS_X_SUCCESS,
          payload: data?.data?.data,
        });
        let branchName = data?.data?.data?.bookingDetails?.branchDetails?.name;
        if (branchName) {
          // GET PAYMENT MODES
          yield put({
            type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
              .GET_YOR_PAYMENT_MODES_REQUESTED,
            payload: { branchName: branchName },
          });
        }
        let bookingStatus = data?.data?.data?.bookingDetails?.status;
        if (bookingStatus && bookingStatus === BOOKING_STATUS_ENUM.CREATED) {
          yield put({
            type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
              .GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED,
            payload: action.payload,
          });
        }
        let custId = data?.data?.data?.customerDetails?.userId;
        if (custId) {
          yield put({
            type: CUSTOMER_MANAGEMENT_ACTIONS.GET_CUSTOMER_PROFILE_DATA_REQUESTED,
            payload: { id: custId },
          });
        }
      } else if (data?.ResponseStatusCode === 422) {
        toastr.warning("Alert", handleMessage(data));
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_DETAILS_X_FAILED,
          payload: null,
        });
      } else {
        toastr.error("", handleMessage(data));
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_DETAILS_X_FAILED,
          payload: null,
        });
      }
    } catch (err) {
      console.error("getYORBookingDetails Error -", err);
    }
  }
}

function* getYORPaymentHistories(action: actionInterface): any {
  if (action.payload.id) {
    try {
      const data = yield call(
        YORBookingDetailsServices.getYORPaymentHistory,
        action.payload
      );
      if (data?.status == 200 || data?.status == 201) {
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_PAYMENT_HISTORIES_X_SUCCESS,
          payload: data?.data?.data,
        });
        // toastr.success("", handleMessage(data));
      } else {
        toastr.error("", handleMessage(data));
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_PAYMENT_HISTORIES_X_FAILED,
        });
      }
    } catch (error) {
      toastr.error("", handleMessage(error));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_PAYMENT_HISTORIES_X_FAILED,
      });
    }
  }
}

function* startRide(action: any): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.startRideX,
      action.payload
    );
    if (response?.status === 200 || response?.status === 201) {
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .YOR_USER_START_RIDE_X_SUCCESS,
        payload: response,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .YOR_USER_START_RIDE_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .YOR_USER_START_RIDE_X_FAILED,
    });
  }
}

function* getAvailableVehicle(action: actionInterface): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.getAvailableVehiclesX,
      action.payload
    );

    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_AVAILABLE_VEHICLE_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_AVAILABLE_VEHICLE_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .GET_YOR_AVAILABLE_VEHICLE_LIST_FAILED,
    });
  }
}

function* getPaymentPendingX(action: actionInterface): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.getPaymentPendingX,
      action.payload
    );

    const paymentPending = response?.data?.data;

    if (response?.status == 200 || response?.status == 201) {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_PENDING_PAYMENT_STATUS_X_SUCCESS,
        payload: { paymentPending: paymentPending },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_PENDING_PAYMENT_STATUS_X_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .GET_YOR_PENDING_PAYMENT_STATUS_X_FAILED,
    });
  }
}

function* cancelBookingX(action: any): any {
  try {
    const bookingId = action.payload.bookingId;
    const data = yield call(
      YORBookingDetailsServices.cancelBookingX,
      bookingId
    );
    if (data?.status == 200) {
      if (
        action.payload.cancelActionType &&
        action.payload.cancelActionType === "CANCEL_THEN_CREATE_BOOKING"
      ) {
        const duplicateBookingModal = {
          show: false,
          bookingId: "",
        };
        // Reset Duplicate Booking Modal
        yield put({
          type: actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_X,
        });
        yield put({
          type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_REQUESTED_X,
          payload: action.payload.createBookingPayload,
        });
      } else {
        toastr.success("", handleMessage(data));

        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .CANCEL_YOR_BOOKING_X_SUCCESS,
          payload: data?.data?.data?.status,
        });
      }
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CANCEL_YOR_BOOKING_X_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_FAILED,
    });
  }
}

function* closeBookingX(action: any): any {
  try {
    const bookingId = action.payload.bookingId;
    const data = yield call(YORBookingDetailsServices.closeBookingX, bookingId);

    if (data?.status == 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CLOSE_YOR_BOOKING_X_SUCCESS,
        payload: data?.data?.data?.status,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
  }
}

function* getBookingStatus(action: any): any {
  while (true) {
    try {
      const data = yield call(
        YORBookingDetailsServices.getBookingStatus,
        action.payload
      );
      if (data?.status == 200) {
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_STATUS_X_SUCCESS,
          payload: data?.data?.data?.status,
        });
        if (
          data?.data?.data?.status == "BOOKED" ||
          data?.data?.data?.status == "ACTIVE" ||
          data?.data?.data?.status == "CANCELLED"
        ) {
          break;
        }
        yield delay(10000);
      } else {
        toastr.error("", handleMessage(data));
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_STATUS_X_FAILED,
        });
        break;
      }
    } catch (error) {
      toastr.error("", handleMessage(error));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_BOOKING_STATUS_X_FAILED,
      });
      break;
    } finally {
      if (yield cancelled()) break;
    }
  }
}

function* getPaymentModesYOR(action: any): any {
  try {
    // action.payload = {branchName="IN_UP_NOIDA_FR_SECTOR_63_NOIDA"}
    const response = yield call(
      YORBookingDetailsServices.getPaymentModesYOR,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const paymentModes = response?.data?.data?.paymentModes;

      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_PAYMENT_MODES_SUCCESS,
        payload: { paymentModes: paymentModes },
      });
    } else {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_PAYMENT_MODES_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .GET_YOR_PAYMENT_MODES_FAILED,
    });
  }
}

function* createOrderIdYOR(action: any): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.generateOrderIdYOR,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const orderData = response?.data?.data;

      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS,
        payload: { orderData: orderData },
      });
      if (orderData.id) {
        // QR/Link Payload
        const paymentPayload = {
          fetchBookingPayload: action.payload.fetchBookingPayload,
          data: {
            orderId: orderData.id,
            paymentFlow: action.payload.selectedPaymentMode,
          },
        };
        // GENERATE PAYMENT QR/LINK ON SUCCESS
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
          payload: paymentPayload,
        });
      }
    } else {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED,
    });
  }
}

function* generatePaymentLinkQRForOrder(action: any): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.generatePaymentLinkQRForOrder,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const orderData = response?.data?.data;
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_SUCCESS,
        payload: orderData,
      });

      // REFRESH BOOKING DETAIL SECTION ON SUCCESS
      /* if (action.payload.fetchBookingPayload) {
        yield put({
          type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
            .GET_YOR_BOOKING_DETAILS_X_REQUESTED,
          payload: action.payload.fetchBookingPayload,
        });
      } */
    } else {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_FAILED,
    });
  }
}

function* getChargesBifurcationForOrderYOR(action: any): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.getChargesBifurcationServiceYOR,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const chargesBifurcation = response?.data?.data;
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_ORDER_CHARGES_BIFURCATION_SUCCESS,
        payload: chargesBifurcation,
      });
    } else {
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_ORDER_CHARGES_BIFURCATION_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .GET_YOR_ORDER_CHARGES_BIFURCATION_FAILED,
    });
  }
}

function* waivedOffCharges(action: actionInterface): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.waivedOffCharges,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .BOOKING_WAIVED_OFF_CHARGES_SUCCESS,
      });

      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_BOOKING_DETAILS_X_REQUESTED,
        payload: action?.payload?.bookingParams,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .BOOKING_WAIVED_OFF_CHARGES_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));

    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .BOOKING_WAIVED_OFF_CHARGES_FAILED,
    });
  }
}

function* createBulkAdditionalCharges(action: any): any {
  try {
    const response = yield call(
      YORBookingDetailsServices.saveAdditionalchargesYOR,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      const savedCharges = response?.data?.data?.charges;

      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CREATE_BULK_ADDITIONAL_CHARGES_SUCCESS,
        payload: savedCharges,
      });

      // Refresh vehicle payload
      const allBookingSectionsParam = {
        id: action.payload.bookingId,
        keys: [3],
      };

      // Refresh vehicles section
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .GET_YOR_BOOKING_DETAILS_X_REQUESTED,
        payload: allBookingSectionsParam,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
          .CREATE_BULK_ADDITIONAL_CHARGES_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
        .CREATE_BULK_ADDITIONAL_CHARGES_FAILED,
    });
  }
}

function* YOR_BookingDetailsSaga() {
  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_DETAILS_X_REQUESTED,
    getYORBookingDetails
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GET_YOR_PAYMENT_HISTORIES_X_REQUESTED,
    getYORPaymentHistories
  );
  // NEW

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.YOR_USER_START_RIDE_X_REQUESTED,
    startRide
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GET_YOR_AVAILABLE_VEHICLE_LIST_REQUESTED,
    getAvailableVehicle
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED,
    getPaymentPendingX
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_REQUESTED,
    cancelBookingX
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CLOSE_YOR_BOOKING_X_REQUESTED,
    closeBookingX
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_MODES_REQUESTED,
    getPaymentModesYOR
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED,
    createOrderIdYOR
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
    generatePaymentLinkQRForOrder
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GET_YOR_ORDER_CHARGES_BIFURCATION_REQUESTED,
    getChargesBifurcationForOrderYOR
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .BOOKING_WAIVED_OFF_CHARGES_REQUESTED,
    waivedOffCharges
  );

  yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .CREATE_BULK_ADDITIONAL_CHARGES_REQUESTED,
    createBulkAdditionalCharges
  );

  const responseStatus: Task = yield takeLatest(
    actionTypes.YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_BOOKING_STATUS_X_REQUESTED,
    getBookingStatus
  );

  yield take(actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_STATUS_X_TASK);
  yield cancel(responseStatus);
}

export default YOR_BookingDetailsSaga;
