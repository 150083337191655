import { Box, CircularProgress } from "@mui/material";
import styles from "../../components.module.css";

interface IFullScreenLoader {
  loading: boolean;
  loaderSize?: number;
  color?: any; // "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit",
}

const FullScreenLoader = (props: IFullScreenLoader) => {
  return (
    <>
      {props?.loading ? (
        <Box className={styles.fullScreenLoaderStyle}>
          <CircularProgress size={props?.loaderSize ?? 80} color={props?.color ?? "primary"} />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FullScreenLoader;
