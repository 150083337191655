import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BGImg, CardIcon } from "../../constantsX/exportImagesX";
import { Grid } from "@mui/material";
import { colors } from "../../themes/colors";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BookingsCard({ icon,text,subText, bgImg }:any) {
  return (
    <Grid item xs={3}>
      <Card sx={{ minWidth: 230, height: "80px" }}>
      <CardContent sx={{ backgroundImage: `url(${bgImg})` }}>
        <Box display="flex" sx={{ height: "48px", gap: "16px" }}>
          <Box>
            <img src={icon} />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px", color: colors.text_secondary_gray }}>
              {text ?? ""}
            </Typography>
            <Typography>{subText ?? ""}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
    </Grid>
    
  );
}
