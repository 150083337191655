import { call, put, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import * as actionTypes from "../actions/actionTypes";
import operatorServices from "../services/operatorManagement";
import { handleMessage } from "../../../utils/helper";

function* getOperatorLeadList(action: any): any {
  try {
    const response = yield call(
      operatorServices.getOperatorLeadList,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_LIST_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_OPERATOR_LEAD_LIST_FAILED,
    });
  }
}

function* getOperatorLeadDetails(action: any): any {
  try {
    const response = yield call(
      operatorServices.getOperatorLeadDetails,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_DETAILS_FAILED,
        payload: response?.error,
      });

      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_OPERATOR_LEAD_DETAILS_FAILED,
    });
  }
}

function* saveOperatorLeadDetails(action: any): any {
  try {
    const response = yield call(
      operatorServices.saveOperatorLeadDetails,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SAVE_OPERATOR_LEAD_DETAILS_SUCCESS,
      });
      toastr.success("", handleMessage(response));
      //update to the saved lead details
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_DETAILS_REQUESTED,
        payload: {
          id: action.payload.id,
        },
      });

      if (action.payload.type === "approveLead") {
        yield put({
          type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
            .APPROVE_LEAD_OPERATOR_REQUESTED,
          payload: {
            data: {
              id: action.payload.id,
            },
            successCallback: action.payload.successCallback,
          },
        });
      }
      if (action.payload.type === "sendAggrement") {
        yield put({
          type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
            .INITIATE_ESIGN_OPERATOR_REQUESTED,
          payload: {
            data: {
              leadId: action.payload.id,
            },
            successCallback: action.payload.successCallback,
          },
        });
      }
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SAVE_OPERATOR_LEAD_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .SAVE_OPERATOR_LEAD_DETAILS_FAILED,
    });
  }
}

//initiate e sign for generate agreement
function* initiateESignsaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.initiateESignOperator,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .INITIATE_ESIGN_OPERATOR_SUCCESS,
      });
      toastr.success("", handleMessage(response));

      action.payload.successCallback && action.payload.successCallback();
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .INITIATE_ESIGN_OPERATOR_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .INITIATE_ESIGN_OPERATOR_FAILED,
    });
  }
}

//initiate e sign for generate agreement
function* verifyOperatorSaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.verifyEmailOperator,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .VERIFY_EMAIL_OPERATOR_SUCCESS,
      });
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_LEAD_DETAILS_REQUESTED,
        payload: {
          id: action.payload.data.leadId,
        },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .INITIATE_ESIGN_OPERATOR_FAILED,
      });
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .INITIATE_ESIGN_OPERATOR_FAILED,
    });
  }
}

//approve lead
function* approveLeadSaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.approveLead,
      action.payload.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .APPROVE_LEAD_OPERATOR_SUCCESS,
      });
      toastr.success("", handleMessage(response));
      action.payload.successCallback && action.payload.successCallback();
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .APPROVE_LEAD_OPERATOR_FAILED,
      });
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .APPROVE_LEAD_OPERATOR_FAILED,
    });
  }
}

//operator list saga
function* operatorListSaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.getOperatorList,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPEARATOR_LIST_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_FAILED,
    });
  }
}

//operator list saga
function* operatorDetailsSaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.getOperatorDetails,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_DETAILS_DETAILS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_DETAILS_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_OPERATOR_DETAILS_DETAILS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* modifyOperatorConfig(action: any): any {
  try {
    const response = yield call(
      operatorServices.modifyOperatorConfig,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .MODIFY_OPERATOR_CONFIG_SUCCESS,
        payload: response?.data?.data,
      });
      toastr.success("", handleMessage(response));
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .MODIFY_OPERATOR_CONFIG_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .MODIFY_OPERATOR_CONFIG_FAILED,
    });
  }
}

//operator KYC document approve and reject
function* approveRejectOperatorDocument(action: any): any {
  try {
    const response = yield call(
      operatorServices.approveRejectOperatorDocument,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .APPROVE_REJECT_OPERATOR_DOCUMENTS_SUCCESS,
        payload: response.data.data,
      });
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_DETAILS_DETAILS_REQUESTED,
        payload: {
          id: action.payload.operatorId,
        },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .APPROVE_REJECT_OPERATOR_DOCUMENTS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .APPROVE_REJECT_OPERATOR_DOCUMENTS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// save operator KYC document
function* saveOperatorDocument(action: any): any {
  try {
    const response = yield call(
      operatorServices.saveOperatorDocument,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SAVE_OPERATOR_DOCUMENTS_SUCCESS,
        payload: response.data.data,
      });
      toastr.success("", handleMessage(response));
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SAVE_OPERATOR_DOCUMENTS_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .SAVE_OPERATOR_DOCUMENTS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// submit operator document
function* submitOperatorDocument(action: any): any {
  try {
    const { savePayload } = action.payload;

    const response = yield call(
      operatorServices.submitOperatorDocument,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SUBMIT_OPERATOR_DOCUMENTS_SUCCESS,
        payload: response.data.data,
      });
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_OPERATOR_DETAILS_DETAILS_REQUESTED,
        payload: {
          id: action.payload.id,
        },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .SUBMIT_OPERATOR_DOCUMENTS_FAILED,
      });
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .SUBMIT_OPERATOR_DOCUMENTS_FAILED,
    });
  }
}

// view operator agreement
function* viewAgreementSaga(action: any): any {
  try {
    const response = yield call(
      operatorServices.viewAgreement,
      action.payload.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
          .GET_VIEW_AGREEMENT_SUCCESS,
        payload: response?.data?.data,
      });

      action.payload.successCallback && action.payload.successCallback();
    } else {
      yield put({
        type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_FAILED,
    });
  }
}

function* newOperatorManagementSaga() {
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LEAD_LIST_REQUESTED,
    getOperatorLeadList
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LEAD_DETAILS_REQUESTED,
    getOperatorLeadDetails
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_LEAD_DETAILS_REQUESTED,
    saveOperatorLeadDetails
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.INITIATE_ESIGN_OPERATOR_REQUESTED,
    initiateESignsaga
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.VERIFY_EMAIL_OPERATOR_REQUESTED,
    verifyOperatorSaga
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.APPROVE_LEAD_OPERATOR_REQUESTED,
    approveLeadSaga
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_REQUESTED,
    operatorListSaga
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_DETAILS_DETAILS_REQUESTED,
    operatorDetailsSaga
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_REJECT_OPERATOR_DOCUMENTS_REQUESTED,
    approveRejectOperatorDocument
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_DOCUMENTS_REQUESTED,
    saveOperatorDocument
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SUBMIT_OPERATOR_DOCUMENTS_REQUESTED,
    submitOperatorDocument
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.MODIFY_OPERATOR_CONFIG_REQUESTED,
    modifyOperatorConfig
  );

  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_REQUESTED,
    viewAgreementSaga
  );
}

export default newOperatorManagementSaga;
