import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import bookingManagementXServices from "../../services/bookingManagementXServices";
import * as actionTypes from "../actions/actionTypes";

function* fetchAllBookings(action: any): any {
  try {
    const data = yield call(
      bookingManagementXServices.getAllBookingsX,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
          .FETCH_BOOKING_LIST_X_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
          .FETCH_BOOKING_LIST_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
        .FETCH_BOOKING_LIST_X_FAILED,
    });
  }
}

function* getStatistics(action: any): any {
  try {
    const data = yield call(bookingManagementXServices.getStatistics);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
          .GET_BOOKING_STATISTICS_X_SUCCESS,
        payload: data?.data?.data?.statistics,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
          .GET_BOOKING_STATISTICS_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
        .GET_BOOKING_STATISTICS_X_FAILED,
    });
  }
}

function* allBookingSagaX() {
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS_X.FETCH_BOOKING_LIST_X_REQUESTED,
    fetchAllBookings
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS_X.GET_BOOKING_STATISTICS_X_REQUESTED,
    getStatistics
  );
}

export default allBookingSagaX;
