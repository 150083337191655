import { Box, Typography } from "@mui/material";
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import {
  GenericObject,
  GURUGRAM_LAT_LNG,
  LOB_TYPES,
  WAREHOUSE_DELIVERY_TYPES,
} from "../../../../constants/constants";
import {
  DeliverySetupFields,
  DeliverySetupProps,
} from "../../../../interfaces/bussinessManagement";
import en from "../../../../locale/rental-en.json";
import { actionsR } from "../../../../redux";
import DeliveryChargesModal from "./deliveryChargesModal";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  modules,
  warehouseFunctionalities,
} from "../../../../../constants/RBACModuleEnums";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../../../utils/RoutesConstants";

const initialState: DeliverySetupFields = {
  serviceableCity: { name: "", displayName: "", geoLocationData: {} },
  chargeType: { name: "", displayName: "" },
  polygonPrice: "",
  polygonName: "",
  polygonPath: [],
  filteredCities: [],
  filteredDeliveryCharges: [],
  chargesData: {},
};

interface Coordinates {
  lat: number;
  lng: number;
}

const initialStateCoordinates = {
  lat: GURUGRAM_LAT_LNG.LAT,
  lng: GURUGRAM_LAT_LNG.LNG,
};
const DeliverySetup = (props: DeliverySetupProps) => {
  // rbac implementation
  const RbacHasAccess = {
    addNewCharges: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.ADD_DELIVERY_CHARGES
    ),
    deliveryChargesList: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.DELIVERY_CHARGES_LIST
    ),
    editCharges: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.EDIT_DELIVERY_CHARGES
    ),
  };

  const dispatch = useDispatch();
  const { warehouseManagement, global } = en;
  const { branchId, warehouseData, editRole } = props;

  // state to handle all fields data
  const [fields, setFields] = useState<DeliverySetupFields>({
    ...initialState,
  });

  const [coordinates, setCoordinates] = useState<Coordinates>({
    ...initialStateCoordinates,
  });

  // state to handle conditional renders
  const [conditions, setConditions] = useState<any>({
    openModal: false,
    isEditCharges: false,
    showInfoWindow: {},
  });

  const {
    polygonsData,
    deliveryTypes,
    getDeliveryChargesLoader,
    warehouseDetail,
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);

  const { cityDetails } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { selectableCities: serviceableCity = [] } = cityDetails;
  const navigate = useNavigate();

  //handle all fields
  const handleChange = (key: string, value: any) => {
    setFields((prev: DeliverySetupFields) => ({ ...prev, [key]: value }));
  };

  // handle open delivery options modal
  const handleModalOpen = () => {
    setConditions((prev: any) => ({ ...prev, openModal: true }));
  };

  // handle close delivery options modal
  const handleModalClose = () => {
    setConditions((prev: any) => ({
      ...prev,
      openModal: false,
      isEditCharges: false,
      showInfoWindow: {},
    }));
    // setIsAddFencing(false);
    setFields((prev: DeliverySetupFields) => ({
      ...prev,
      ...initialState,
    }));
  };

  //handle add delivery charges
  const handleAddDeliveryCharges = () => {
    handleModalOpen();
    if (Object.keys(cityDetails)?.length) {
      let cityLength = checkCities();
      let deliveryChargesTypes: GenericObject[] = deliveryTypes;
      if (!cityLength) deliveryChargesTypes = [deliveryTypes[1]];
      setFields((prev: DeliverySetupFields) => ({
        ...prev,
        filteredDeliveryCharges: deliveryChargesTypes,
      }));
    }
  };

  //handle info window open close
  const handleInfoWindow = (id: string, value: boolean) => {
    setConditions((prev: any) => ({
      ...prev,
      showInfoWindow: { ...prev.showInfoWindow, [id]: value },
    }));
  };

  //delete delivery charges
  const deleteDeliveryCharges = () => {
    if (conditions?.isEditCharges) {
      dispatch(
        actionsR.businessActions.deletePolygon({
          geofencingId: fields?.chargesData?.geofencingId,
          branchId,
        })
      );
    }
  };

  //filters cities for delivery charges modal
  const filterCities = (
    chargeType: any = WAREHOUSE_DELIVERY_TYPES.POLYGON
  ): [] => {
    let obj = [
      {
        name: cityDetails.name,
        displayName: cityDetails.displayName,
        state: cityDetails?.state,
        stateDisplayname: cityDetails?.stateDisplayname,
      },
    ];

    let cities: any = [...obj, ...serviceableCity];

    if (chargeType === WAREHOUSE_DELIVERY_TYPES.CITY) {
      let cityNames: string[] = [];

      for (let i = 0; i < polygonsData?.length; i++) {
        if (polygonsData[i].type === WAREHOUSE_DELIVERY_TYPES.CITY)
          cityNames.push(polygonsData[i]?.geofencingData?.cityName);
      }

      let data: [] = cities.filter((data: any) => {
        if (!cityNames.includes(data.name)) return data;
      });

      cities = data;
    }
    setFields((prev: DeliverySetupFields) => ({
      ...prev,
      filteredCities: cities,
    }));
    return cities;
  };

  // check city for fixed delivery charges
  const checkCities = (): number => {
    let cityNames: string[] = [];
    let obj = [
      {
        name: cityDetails.name,
        displayName: cityDetails.displayName,
        state: cityDetails?.state,
        stateDisplayname: cityDetails?.stateDisplayname,
      },
    ];

    let cities: any = [...obj, ...serviceableCity];

    for (let i = 0; i < polygonsData?.length; i++) {
      if (polygonsData[i].type === WAREHOUSE_DELIVERY_TYPES.CITY)
        cityNames.push(polygonsData[i]?.geofencingData?.cityName);
    }

    let data: [] = cities.filter((data: any) => {
      if (!cityNames.includes(data.name)) return data;
    });

    cities = data;
    return cities?.length;
  };
  // returns unique row id for data grid rows
  const getRowsId = (row: any) => row?.geofencingId;

  //handle table row click
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    let cities = filterCities();
    const { row } = params;
    if (RbacHasAccess.editCharges) {
      setConditions((prev: any) => ({ ...prev, isEditCharges: true }));

      let chargeType: any = deliveryTypes?.filter(
        (data: any) => row.type === data.name
      )[0];

      let data: any = {
        chargeType: chargeType,
        serviceableCity: { name: "", displayName: "" },
        chargesData: row,
        polygonPrice: row?.deliveryAmount,
        filteredDeliveryCharges: deliveryTypes,
      };

      data.serviceableCity = cities?.filter(
        (data: any) => data.name === row.geofencingData?.cityName
      )[0];

      if (row.type === WAREHOUSE_DELIVERY_TYPES.POLYGON) {
        data["polygonName"] = row?.name;
        (data["polygonPrice"] = row?.deliveryAmount),
          (data["polygonPath"] = row?.geofencingData?.coordinates);
      }

      setFields((prev: DeliverySetupFields) => ({
        ...prev,
        ...data,
      }));
      handleModalOpen();
    }
  };

  // save edit polygon
  const savePolygon = () => {
    if (conditions?.isEditCharges) {
      let apiData = {
        apiBody: {
          data: {
            name: fields?.polygonName?.trim(),
            deliveryAmount: fields?.polygonPrice,
            geofencingData: {
              type: "Polygon",
              coordinates: fields?.polygonPath,
              cityName: fields.serviceableCity.name,
            },
          },
        },
        apiParams: {
          geofencingId: fields?.chargesData?.geofencingId,
        },

        branchId,
      };
      if (fields?.polygonPath?.length < 3) {
        toastr.error("", en.errorMessages.drawPolygonError);
        return;
      }
      dispatch(actionsR?.businessActions.editPolygon(apiData));
      handleModalClose();
    } else {
      let apiData = {
        payload: {
          data: {
            addGeofencingData: [
              {
                lob: LOB_TYPES.LTR,
                name: fields?.polygonName?.trim(),
                branchName: branchId,
                deliveryAmount: fields?.polygonPrice,
                type: WAREHOUSE_DELIVERY_TYPES.POLYGON,
                // cityName: warehouseData?.city?.name,
                geofencingData: {
                  type: "Polygon",
                  coordinates: fields?.polygonPath,
                  cityName: fields.serviceableCity.name,
                },
              },
            ],
          },
        },
        branchId,
      };

      dispatch(actionsR?.businessActions.addPolygon(apiData));
      handleModalClose();
    }
  };

  // save edit full city
  const saveFullCity = () => {
    if (conditions.isEditCharges) {
      let apiData = {
        apiBody: {
          data: {
            // lob: "LTR",
            name: fields?.chargesData?.name,
            // branchId: id?.id,
            deliveryAmount: fields?.polygonPrice,
            // type: polygonData.type,
            geofencingData: {
              cityName: fields?.chargesData?.geofencingData?.cityName,
            },
          },
        },
        apiParams: {
          geofencingId: fields?.chargesData?.geofencingId,
        },
        branchId,
      };

      dispatch(actionsR?.businessActions.editPolygon(apiData));
    } else {
      let apiData = {
        payload: {
          data: {
            addGeofencingData: [
              {
                lob: LOB_TYPES.LTR,
                name: fields.serviceableCity.displayName,
                branchName: branchId,
                deliveryAmount: fields?.polygonPrice,
                type: WAREHOUSE_DELIVERY_TYPES.CITY,
                // cityName: warehouseData?.city?.name,
                geofencingData: {
                  cityName: fields.serviceableCity.name,
                },
              },
            ],
          },
        },
        branchId,
      };
      dispatch(actionsR?.businessActions.addPolygon(apiData));
    }
    handleModalClose();
  };

  // resets polygon data
  const resetPolygonData = () => {
    if (conditions?.isEditCharges) {
      if (fields?.chargeType?.name === WAREHOUSE_DELIVERY_TYPES.POLYGON) {
        setFields((prev: DeliverySetupFields) => ({
          ...prev,
          // polygonPath: fields.chargesData?.geofencingData?.coordinates,
          polygonPath: [],
          polygonPrice: fields.chargesData?.deliveryAmount,
          polygonName: fields.chargesData?.name,
        }));
      } else {
        setFields((prev: DeliverySetupFields) => ({
          ...prev,
          fullCityPrice: fields.chargesData?.deliveryAmount,
        }));
      }
    } else {
      setFields((prev: DeliverySetupFields) => ({
        ...prev,
        polygonPath: [],
        polygonName: "",
        polygonPrice: "",
      }));
    }
  };

  // side effect for intial render
  useEffect(() => {
    if (props?.editRole) {
      dispatch(actionsR?.businessActions?.getPolygons(branchId));
    }
    RbacHasAccess.deliveryChargesList &&
      dispatch(actionsR?.rentalActions?.getCityDetails(warehouseDetail?.city));
  }, []);

  // Columns for data grid
  const columns: GridColDef[] = [
    {
      field: "cityName",
      headerName: global.city,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.geofencingData.cityDisplayName
            ? params?.row?.geofencingData.cityDisplayName
            : en.global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "name",
      headerName: global.name,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{params?.row?.name ? params?.row?.name : "NA"}</span>
      ),
      flex: 1,
    },
    {
      field: "chargeType",
      headerName: warehouseManagement.chargeType,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {
            warehouseManagement[
              params?.row?.type as keyof typeof warehouseManagement
            ]
          }
        </span>
      ),
      flex: 1,
    },
    {
      field: "deliveryAmount",
      headerClassName: "hideRightSeparator",
      headerName: warehouseManagement.deliveryAmount,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{params?.row?.deliveryAmount}</span>
      ),
      flex: 1,
    },
  ];

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "85%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
            {warehouseManagement.addDeliveryCharges}
          </Typography>
          {RbacHasAccess.addNewCharges && (
            <CustomButton
              variant={"outlined"}
              label={warehouseManagement?.addNewCharges}
              sx={{ borderRadius: "100px" }}
              onClick={() => {
                handleAddDeliveryCharges();
              }}
            />
          )}
        </Box>
        <Box>
          <DataTable
            columns={columns}
            rows={polygonsData}
            hideFooter={true}
            disableColumnMenu
            disableColumnSorting
            headerAlign={"center"}
            getRowId={getRowsId}
            rowHeight={45}
            onRowClick={handleRowClick}
            loading={getDeliveryChargesLoader}
            hideColumnSeperator
            disableRowSelectionOnClick
            disableColumnResize
            hasAccess={RbacHasAccess.deliveryChargesList}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <CustomButton
            variant={"outlined"}
            label={global?.save}
            sx={{ borderRadius: "100px" }}
            onClick={() => {
              navigate(routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT);
            }}
          />
        </Box>

        <DeliveryChargesModal
          open={conditions?.openModal}
          onClose={handleModalClose}
          fields={fields}
          setFields={setFields}
          handleChange={handleChange}
          filterCities={filterCities}
          resetPolygonData={resetPolygonData}
          deleteDeliveryCharges={deleteDeliveryCharges}
          editRole={editRole}
          conditions={conditions}
          savePolygon={savePolygon}
          handleInfoWindow={handleInfoWindow}
          saveFullCity={saveFullCity}
        />
      </Box>
    </Box>
  );
};
export default React.memo(DeliverySetup);
