import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import masterReducerX from "../../freedo-x-src/redux/reducers/masterReducerX";
import authReducer from "./authReducers";
import { default as commonReducer } from "./commonReducers/commonReducer";
import localeReducer from "./commonReducers/localeReducer";
import toastReducer from "./commonReducers/toastReducer";
import couponReducer from "./couponReducer";
import dashboardReducer from "./dashboardReducers";
import feedbackReducer from "./feedbackReducers";
import masterReducer from "./masterReducers";
import newAuthReducer from "./newAuthReducers";
import operatorReducer from "./operatorReducer";
import reportReducer from "./reportReducers";
import reportDownloadReducer from "./reportReducers/reportDownload";
import slotPauseReducer from "./slotPauseReducers";
import taskReducer from "./taskReducers";

import { freedoXReducers } from "../../freedo-x-src/redux/reducers";
import { rentalReducers } from "../../rentals-src/redux/reducers";
import themeReducer from "./commonReducers/themeReducer";

const appReducer = combineReducers({
  localeReducer,
  toastReducer,
  authReducer,
  feedbackReducer,
  dashboardReducer,
  commonReducer,
  couponReducer,
  toastr: toastrReducer,
  slotPauseReducer,
  reportReducer,
  reportDownloadReducer,
  masterReducer,
  operatorReducer,
  taskReducer,
  newAuthReducer,
  themeReducer,
  masterReducerX,
  ...freedoXReducers,
  ...rentalReducers,
});

export const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
