import { Box, LinearProgress, Typography } from "@mui/material";
import { colors } from "../../../../../themes/colors";
import rentalEn from "../../../../locale/rental-en.json";
import { circularLineIcon } from "../../../../constants/exportImages";
import { StyleObject } from "../sections/styleObject";

interface LinearProgressDocUpload {
  progress: number;
}

export const LinearProgressDocUpload = ({
  progress,
}: LinearProgressDocUpload) => {
  return (
    <Box
      sx={{
        ...StyleObject.imageUplodedContainer,
        display: "flex",
        alignItems: "center",
        padding: "40px 30px",
      }}
    >
      <Box>
        <Box style={{ display: "flex", gap: "4px" }}>
          <img src={circularLineIcon} width={"20px"} height="20px" />
          <Typography
            sx={{
              lineHeight: "20px",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {`${rentalEn.global.uploading}...`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
        <Typography
          sx={{
            lineHeight: "12px",
            fontSize: "12px",
            fontWeight: 500,
            color: colors.tertiary_black,
          }}
        >
          {`${progress}% of 100%`}
        </Typography>
        <LinearProgress
          value={progress}
          variant="determinate"
          sx={{ width: "145px", height: "5px", borderRadius: "5px" }}
        />
      </Box>
    </Box>
  );
};
