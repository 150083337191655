// This is the css style objects that will be provided to "sx" prop of the mui components.

import { colors } from "../../../../../../themes/colors";

export const StyleObject: any = {
  wrapperBox: {
    minHeight: "85dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tabView_wrapperBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tabStyle1: {
    width: "200px",
    padding: "5px 30px",
    borderRadius: "12px 0 0 12px",
  },
  tabStyle2: {
    width: "200px",
    padding: "5px 30px",
    borderRadius: "0 12px 12px 0",
  },
  updateRoleBox: {
    textAlign: "center",
    py: 1,
    borderRadius: "8px",
    border: "0.5px solid",
    height: 42,
  },
  ars_topBox: { flexGrow: 1, alignItems: "center", width: 9 / 10 },
  ars_headingTopBox: { flexGrow: 1, alignItems: "center", width: 9 / 10 },
  ars_headingRole: {
    textAlign: "center",
    py: 1,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    backgroundColor: colors?.dark_green_bg,
  },
  ars_headingState: {
    textAlign: "center",
    py: 1,
    backgroundColor: colors?.dark_green_bg,
  },
  ars_headingCity: {
    textAlign: "center",
    py: 1,
    backgroundColor: colors?.dark_green_bg,
  },
  ars_headingHub: {
    textAlign: "center",
    py: 1,
    backgroundColor: colors?.dark_green_bg,
  },
  ars_headingAction: {
    textAlign: "center",
    py: 1,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundColor: colors?.dark_green_bg,
  },
  ars_buttonWrapper: {
    flexGrow: 1,
    alignItems: "center",
    width: 9 / 10,
  },
};
