import {
  API_URLS,
  BASE_URL_PAYMENTS,
  BASE_URL_UAT,
  KYC_VERIFICATION_BASE_URL_ADMIN,
  customAxios,
  getMasterData,
  versioning,
} from "../config";
import { IDefaultRequestHeaders } from "../interfaces/commonInterface";
import CONSTANTS from "../locale/en.json";

const requestHeaders: IDefaultRequestHeaders | any = {
  "x-platform": CONSTANTS?.requestHeaders?.["x-platform"],
  "x-channel": CONSTANTS?.requestHeaders?.["x-channel"],
  "x-client-id": CONSTANTS?.requestHeaders?.["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent ?? "",
};

const commonService = (payload: any) =>
  customAxios
    .get(BASE_URL_UAT + getMasterData({ type: payload?.type, id: payload?.id }))
    .catch((ex) => ex);

const getCitiesByLocation = (payload: any) => {
  try {
    let url = API_URLS.getLocationByCity;
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      url += `&city_id=${payload.city_id}`;
    if (payload.subCompanyId != 0 && payload.subCompanyId)
      url += `&subCompanyId=${payload.subCompanyId}`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const nonOcrVerification = (payload: any) => {
  try {
    const url =
      KYC_VERIFICATION_BASE_URL_ADMIN + "dl/" + API_URLS.nonOcrVerification;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getBranchSlots = (payload: any) => {
  try {
    const url =
      versioning + API_URLS.getBranchSlots + `branch_id=${payload?.branch_id}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getCoupons = (payload: any) => {
  try {
    let url = API_URLS.getcoupons;
    if (payload?.orderBy?.length) {
      let order = "";
      payload?.orderBy?.forEach((each: string | any) => {
        order += each?.name + "-" + each?.value + ",";
      });
      url += `&order_by_clause=${order}`;
    }
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getActiveCoupons = (payload: any) => {
  try {
    let url = payload?.allCoupons
      ? API_URLS.getcoupons
      : API_URLS.getActiveCoupons;

    if (payload?.allCoupons) {
      if (payload?.page) url += `?page=${payload?.page}`;

      if (payload?.size && !isNaN(payload?.size))
        url += `&size=${payload?.size}`;

      if (payload?.orderBy?.length) {
        let order = "";
        payload?.orderBy?.forEach((each: string | any) => {
          order += each?.name + "-" + each?.value + ",";
        });
        url += `&order_by_clause=${order}`;
      }
    }
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getModelListById = (payload: any) => {
  try {
    let url = API_URLS.getModelListById;

    if (
      payload.vehicle_category_id &&
      !isNaN(payload.vehicle_category_id) &&
      payload.vehicle_category_id !== 0
    )
      url += `CategoryId=${payload.vehicle_category_id}`;
    if (payload.vehicle_type_id && !isNaN(payload.vehicle_type_id))
      url += `&TypeId=${payload.vehicle_type_id}&`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getAllBranches = (payload: any) => {
  try {
    let url = "";
    url += payload?.common
      ? API_URLS.getAllBranch
      : versioning + API_URLS.getAllBranchV2;
    if (payload.companyId && payload.companyId != 0)
      url += `&companyId=${payload.companyId}&`;

    if (payload.subCompanyId && payload.subCompanyId != 0)
      url += `&subCompanyId=${payload.subCompanyId}&`;

    if (payload.locationIds && payload.locationIds != 0)
      url += `&locationIds=[${payload.locationIds}]&`;

    if (payload.cityId && payload.cityId != 0)
      url += `&cityId=${payload.cityId}&`;
    if (payload?.page) url += `page=${payload?.page}`;

    if (payload?.size && !isNaN(payload?.size)) url += `&size=${payload?.size}`;
    // if (payload?.orderBy?.length > 0) {
    //   let order = "[";
    //   payload?.orderBy.forEach((item: any) => {
    //     order += '{"name": "' + item.name + '", "value": "' + item.value + '"}';
    //   });
    //   order += "]";
    //   url += `&order_by_clause=${order}&`;
    // }
    if (payload?.orderBy?.length) {
      let order = "";
      payload?.orderBy?.forEach((each: string | any) => {
        order += each?.name + "-" + each?.value + ",";
      });
      url += `&order_by_clause=${order}`;
    }
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const storeDocuments = (payload: any) => {
  try {
    let url = API_URLS.storedocument;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    return customAxios.post(url, payload, config).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const updateAdminContact = (payload: any) => {
  try {
    let url = API_URLS.updateAdminContact;
    if (payload.user_id) url += `${payload.user_id}`;
    return customAxios.put(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getCitiesByRealId = (payload: any) => {
  //admin/v2/getAllBranchModulesConfig

  try {
    let url = versioning + API_URLS.getAllBranchModulesConfig;
    if (payload?.city_id) url += `&city_id=${payload?.city_id}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }

  return;
  try {
    let url = API_URLS.getCitiesByRealId;
    return customAxios.get(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const qrPayments = (payload: any) => {
  try {
    let qrCodeId = payload?.qr_id;
    const url = BASE_URL_PAYMENTS + `fetchQrCodes?qrCodeId=${qrCodeId}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const fileUpload = (payload: any) => {
  try {
    let url = API_URLS.fileUpload;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  commonService,
  getCitiesByLocation,
  getCoupons,
  getActiveCoupons,
  getModelListById,
  getAllBranches,
  storeDocuments,
  updateAdminContact,
  getCitiesByRealId,
  getBranchSlots,
  nonOcrVerification,
  qrPayments,
  fileUpload,
};
