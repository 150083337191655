import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  children?: React.ReactNode;
  containerStyle?: {};
}

export default function CustomDailog(props: Props) {
  const { isOpen, handleClose, title = "", containerStyle = {} } = props;

  return (
    <div>
      <Modal
        style={{
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Box sx={{ ...style, ...containerStyle }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "12px",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold" }}
              >
                {title}
              </Typography>
              <a
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal1"
                style={{ position: "absolute", right: "16px", top: "16px" }}
                onClick={handleClose}
              />
            </Box>
            {props.children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
