import * as actionTypes from "../actionTypes";

interface SlotPauseInterface {
  page?: number | string;
  size?: number | string;
  order_by_clause?: any;
  orderBy?: any;
  id?: any;
  token?: any;
  date_to_pause?: any;
  city_ids?: any;
  option_data?: any;
  branch_id?: number;
  city_id?: number;
  location_id?: number;
}

export const getSlotPause = (payload: any) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_REQUESTED,
  payload,
});

export const getSlotPauseById = (payload: any) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_BY_ID_REQUESTED,
  payload,
});

export const deleteSlotPause = (payload: SlotPauseInterface) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.DELETE_SLOT_PAUSE_REQUESTED,
  payload,
});

export const addSlotPause = (payload: any) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.ADD_SLOT_PAUSE_REQUESTED,
  payload,
});

export const editSlotPause = (payload: any) => ({
  type: actionTypes.SLOT_PAUSE_ACTIONS.EDIT_SLOT_PAUSE_REQUESTED,
  payload,
});
