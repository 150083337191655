import React from "react";
import {
 Box,
 Button,
 Chip,
 Card,
 Divider,
 TextField,
 FormControl,
 FormControlLabel,
 Grid,
 Radio,
 RadioGroup,
 Typography,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 CircularProgress,
 useMediaQuery,
 Tooltip,
 Paper,
} from "@mui/material";
import "./styles.css";

const TimeLineHeading = () => {
 return (
  <div className="headingTimeLine">
   <div className="timeline">
    <Card
     sx={{
      borderRadius: "12px",
      border: "none",
      boxShadow: "none",
      position: "relative",
      justifyContent: "flex-end",
      paddingTop:"20px",
    //   marginLeft: "30px",
      overflow:"unset",
      width:"100%",
     }}
    >
     <Grid style={{display:"flex", gap:"10px",  marginLeft: "40px"}}>
      <Grid xs={12} sm={12} md={3} lg={2} className="timeh">
       <p className="headingtasktext">TIMELINE</p>
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={8} className="descriptionh">
       <p className="headingtasktext">TASK ACTION</p>
      </Grid>
      <Grid xs={12} sm={12} md={3} lg={2} className="chiph">
       <p className="headingtasktext">TASK DONE BY</p>
      </Grid>
     </Grid>
    </Card>
   </div>
  </div>
 );
};

export default TimeLineHeading;
