import { colors } from "../../../../../themes/colors";

export const StyleObject: any = {
  viewMoreText: {
    color: colors.theme_blue,
    fontSize: 13,
    fontWeight: "500",
    cursor: "pointer",
  },
};
