import React, { useRef, useState } from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { Button5 } from "./button";
import { colors } from "../themes/colors";
import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/reducers";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal, useMediaQuery } from "@mui/material";

function DownloadButtons(props: any) {
  // alert(props.customRadio2)
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { viewStats, global } = locale;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isPhone = useMediaQuery("(max-width:767px)");

  return (
    <div>
      <Button5
        style={
          props.style
            ? props.style
            : {
              width: "105%",
              marginTop: props.marginTop || "20px",
              borderRadius: "10px",
              padding: "15px",
              marginLeft: props.marginLeft,
            }
        }
        onClick={handleOpen}
      >
        <Typography
          style={{
            color: colors.white,
          }}
          variant="body2"
        >
          {props.buttonText || "Download"}
        </Typography>
      </Button5>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          className="mr-15"
          style={{
            width: isPhone ? "80%" : "40%",
            marginTop: props.isDesktopOrTablet ? "0px" : "20px",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: colors.white,
            padding: "5%",
            borderRadius: "6px",
          }}
        >
          <Typography
            style={{
              fontFamily: "EuclidCircularB-Regular",
              fontStyle: "italic",
              fontSize: "18px",
              marginBottom: "10px",
            }}
            variant="body2"
          >
            {props.heading}
          </Typography>
          {props?.disabledExcel ? null : (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.checked[0]}
                    disabled={props.disableCSV}
                    onChange={(event: any) =>
                      props.setIsChecked([
                        event.target.checked,
                        !props.checked[1],
                      ])
                    }
                  />
                }
                label="CSV"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={props.disableExcel}
                    checked={props.disableExcel ? false : props.checked[1]}
                    onChange={(event: any) =>
                      props.setIsChecked([
                        !props.checked[0],
                        event.target.checked,
                      ])
                    }
                  />
                }
                label="Excel"
              />
            </FormGroup>
          )}
          {props?.showRadioButtons ? (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group1"
              >
                <FormControlLabel
                  value={props?.customRadio1 || "All"}
                  control={
                    <Radio
                      checked={props?.radioChecked[0]}
                      onChange={(event: any) =>
                        props.setIsRadioChecked([
                          event.target.checked,
                          !props.radioChecked[1],
                        ])
                      }
                    />
                  }
                  label={viewStats.All}
                />
                <FormControlLabel
                  value={"Date-wise"}
                  control={
                    <Radio
                      checked={props?.radioChecked[1]}
                      onChange={(event: any) =>
                        props.setIsRadioChecked([
                          !props.radioChecked[0],
                          event.target.checked,
                        ])
                      }
                    />
                  }
                  label={props.customRadio2 || viewStats.DateWise}
                  style={{ paddingLeft: "20px" }}
                />
              </RadioGroup>
            </FormControl>
          ) : null}
          {/* <Button1 title={global.Download} sx={{ width: "100%", marginTop: "20px" }}>{global.Download}</Button1> */}

          <Button5
            style={{
              width: "100%",
              marginTop: "20px",
              borderRadius: "10px",
            }}
            onClick={props.onClick}
            disabled={props.disableExcel && !props.checked[0]}
          >
            <FontAwesomeIcon
              icon={props.checked[0] ? faFileCsv : faFileExcel}
              color={colors.white}
              fontSize={20}
            />
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "EuclidCircularB-SemiBold",
                color: colors.white,
                marginLeft: "10px",
              }}
            >
              {props.checked[0] && props.data?.length > 0 ? (
                <CSVLink
                  style={{
                    textDecoration: "none",
                    color: colors.white,
                  }}
                  className="hidden"
                  ref={props.csvLink}
                  data={props.data || {}}
                  filename={props.filename}
                  target="_blank"
                // separator="|"
                >
                  {global.Download}
                </CSVLink>
              ) : (
                <div>{global.Download}</div>
              )}
            </Typography>
          </Button5>
        </Grid>
      </Modal>
    </div>
  );
}

export default DownloadButtons;
