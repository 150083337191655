import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import OTPField from "../../../sharedComponents/atoms/OTPFeild";
import { typographyConstants } from "../../../rentals-src/constants/constants";
// import { formatSecondsToTime } from "../../../rentals-src/utils/helper";
import { colors } from "../../../themes/colors";
import en from "../../../locale/en.json";
import CountdownTimer from "../../atoms/CountdownTimer";
import rentalEn from "../../../rentals-src/locale/rental-en.json";
const OTPModalComponent = ({
  fields,
  openModal,
  setOpenModal,
  errorFieldsOtp,
  setOtp,
  otpValue,
  handleCloseSendOTPModal,
  ResendOtpClickHandler,
  setFields,
  handleOtpFieldChange,
  handleOtpModalSubmit,
  isOTPInvalid,
  isOtpResent,
  showResetButtonHandler,
  showResetOtpBtn,
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseSendOTPModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <Box sx={{ position: "absolute", right: "15px", top: "7px" }}>
          <IconButton onClick={handleCloseSendOTPModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack alignItems={"center"} gap="10px">
          <Stack alignItems={"center"}>
            <Typography variant={typographyConstants.HEADINGXL}>
              {rentalEn.global.otPVerification}
            </Typography>
            <Typography
              variant={typographyConstants.BODY}
              color={colors.secondary_light_gray}
            >
              {rentalEn.manualBooking.enterTheCode}
            </Typography>
            <Typography variant={typographyConstants.HEADING} padding={"0px"}>
              +91{fields?.mobileNumber}
            </Typography>
          </Stack>
          <Stack alignItems={"center"} gap="20px">
            {/* countdown component */}
            <CountdownTimer
              label="OTP Expires in"
              showResetButtonHandler={() => showResetButtonHandler()}
              isOtpResent={isOtpResent}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <OTPField
                separator={<span> </span>}
                value={otpValue}
                onChange={setOtp}
                length={6}
              />
              {(isOTPInvalid || errorFieldsOtp) && (
                <Typography variant="errorMessage1">
                  {errorFieldsOtp
                    ? errorFieldsOtp
                    : en.errorMessages.OtpInvalid}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={typographyConstants.BODY}
                  color={colors.secondary_light_gray}
                >
                  I didn't receive any code.{" "}
                </Typography>

                {/* Resend Otp */}
                <CustomButton
                  label={en.global.Resend}
                  onClick={(e: any) => ResendOtpClickHandler(e)}
                  disabled={!showResetOtpBtn}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      background: "none",
                    },
                    fontSize: "12px",
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
        <CustomButton
          variant="outlined"
          sx={{ width: "100%" }}
          label={en.global.Submit}
          onClick={() => handleOtpModalSubmit(otpValue)}
        />
      </Box>
    </Modal>
  );
};

export default OTPModalComponent;
