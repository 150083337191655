import { Card, Grid, Typography, useMediaQuery } from "@mui/material";

import { RootState } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../redux";

import { useEffect, useState } from "react";

import { colors } from "../../../themes/colors";

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RowGrid from "./component/RowGrid";
import TabButton from "./component/TabButton";
import {
  BookingStatusEnum,
  ModeOfDelivery,
  tyesOfModeOfDelivery,
} from "../../constants/constants";
import { getDeliveryAddress } from "../../../utils/helper";

interface IObjectKeys {
  [key: string]: string | number;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
}

const TabSummary = (props: any) => {
  const dispatch = useDispatch();

  const [currentMenu, setCurrentMenu] = useState<string>("Start Ride");
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const [tabName, setTabName] = useState<any>();
  const [active, setActive] = useState<number>(tabName || 0);
  const { tasksummaryById, taskData, taskUserListById } = useSelector(
    (state: RootState) => state.taskReducer
  );

  const [selecteddata, setSelectedData] = useState<any>({});

  const [tasksummarryinfo, setTaskSummaryInfo] = useState<any>([]);

  const inputAddressObj =
    props?.bookingData?.status === BookingStatusEnum.UPCOMING
      ? props?.bookingData?.deliveryDetails?.address
      : props?.bookingData?.returnDetails?.address;
  const displayFormattedAddress = getDeliveryAddress(inputAddressObj);

  const onChange = (e: any) => {
    setCurrentMenu(e);
    const filterData = tasksummaryById?.filter(
      (item: any) => item.taskType == e
    );
    setSelectedData(filterData || []);
    const taskSummarryData = filterData[filterData?.length - 1];
    setTaskSummaryInfo(taskSummarryData || {});
  };

  useEffect(() => {
    setActive(tabName || 0);
  }, [tabName]);
  const handleTabChange = (i: number) => {
    setActive(i);
    setTabName(i);
  };

  useEffect(() => {
    if (tasksummaryById?.length && currentMenu) {
      const summaryData =
        tasksummaryById?.filter((item: any) => item.taskType == currentMenu) ||
        {};

      setSelectedData(summaryData);

      const taskSummarryData = summaryData[summaryData?.length - 1];

      setTaskSummaryInfo(taskSummarryData || {});
    }
  }, [tasksummaryById, currentMenu]);

  useEffect(() => {
    if (props?.bookingId)
      dispatch(actions.getTasKSummary({ bookingId: props?.bookingId }));
  }, [props?.bookingId, taskData, currentMenu]);

  const openGoogleMaps = () => {
    // Replace with the actual address or coordinates you want to display on Google Maps
    // const address = tasksummarryinfo?.deliveryAddress;

    // const encodedAddress = encodeURIComponent(address);
    // const googleMapsUrl = `https://www.google.com/maps/place/${encodedAddress}`;

    // window.open(googleMapsUrl, "_blank");

    // const userLat = props?.bookingData?.delOptPick?.delivery_name == "Home Delivery" ? props?.bookingData?.homeDelPick?.latitude : props?.bookingData?.pick_up_location?.latitude
    // const userLng =  props?.bookingData?.delOptPick?.delivery_name == "Home Delivery" ? props?.bookingData?.homeDelPick?.longitude : props?.bookingData?.pick_up_location?.longitude
    const userLat =
      props?.bookingData?.deliveryDetails?.type ==
      tyesOfModeOfDelivery.CUSTOMER_LOCATION
        ? props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lat
        : props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lat;
    const userLng =
      props?.bookingData?.deliveryDetails?.type ==
      tyesOfModeOfDelivery.CUSTOMER_LOCATION
        ? props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lng
        : props?.bookingData?.deliveryDetails?.address?.locationData
            ?.coordinates?.lng;
    const mapsUrl = `https://www.google.com/maps/dir//${userLat},${userLng}`;

    // Open the URL in a new tab
    window.open(mapsUrl, "_blank");
  };

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        marginTop: "28px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sm={12}
        sx={{
          display: "flex",
          marginTop: "25px",
          marginBottom: "20px",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <TabButton
          tabName={"Start Ride"}
          displayName={"Start Ride"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Start Ride")}
        />
        <TabButton
          tabName={"Complete Ride"}
          displayName={"End Ride"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Complete Ride")}
        />
        <TabButton
          tabName={"Service"}
          displayName={"Service"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Service")}
        />
        <TabButton
          tabName={"Others"}
          displayName={"Others"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Others")}
        />
      </Grid>

      {selecteddata?.length ? (
        <Card
          sx={{
            width: "90%",
            margin: "auto",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "25px",
            marginBottom: "20px",
          }}
        >
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Task ID
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.heading_black}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?._id}
                </Typography>
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Booking Number
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.heading_black}
                  style={{ marginBottom: "2px" }}
                >
                  {props?.bookingData?.bookingNumber}
                </Typography>
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Delivery type
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.heading_black}
                  style={{ marginBottom: "2px" }}
                >
                  {/* {props?.bookingData?.mode_of_pick_up_id==1 ?props?.bookingData?.homeDelPick?.delivery_name: props?.bookingData?.delOptPick?.delivery_name} */}
                  {
                    ModeOfDelivery[
                      props?.bookingData?.deliveryDetails
                        ?.type as keyof typeof ModeOfDelivery
                    ]
                  }
                </Typography>
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  {
                    ModeOfDelivery[
                      props?.bookingData?.deliveryDetails
                        ?.type as keyof typeof ModeOfDelivery
                    ]
                  }
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.card_body_text}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?.deliveryAddress}
                </Typography>

                {props?.bookingData?.delOptPick?.delivery_name ==
                "Home Delivery" ? (
                  <div style={{ marginLeft: 8, marginTop: 2 }}>
                    <Typography
                      marginLeft={"0"}
                      fontFamily="EuclidCircularB-Regular"
                      fontSize={10}
                      color={colors.primary}
                      variant="body2"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={openGoogleMaps}
                    >
                      View in Maps{" "}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        color={colors.primary}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                  </div>
                ) : null}
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Vehicle model
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.card_body_text}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?.vehicleModelName}
                </Typography>
              </div>
            }
          />

          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Primary Agent
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.card_body_text}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?.primaryUser?.name}
                </Typography>
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Secondary Agent
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.card_body_text}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?.secondaryUser?.name}
                </Typography>
              </div>
            }
          />
          <RowGrid
            marginBottom={"15px"}
            colOne={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-Regular"
                  fontSize={14}
                  color={colors.dark_gray}
                  variant="body2"
                >
                  Remarks
                </Typography>
              </div>
            }
            colTwo={
              <div>
                <Typography
                  marginLeft={"0"}
                  fontFamily="EuclidCircularB-SemiBold"
                  fontSize={14}
                  color={colors.card_body_text}
                  style={{ marginBottom: "2px" }}
                >
                  {tasksummarryinfo?.remark}
                </Typography>
              </div>
            }
          />
        </Card>
      ) : (
        "No task assigned for this Ride"
      )}
    </Grid>
  );
};

export default TabSummary;
