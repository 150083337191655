import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button2 } from "../../components/button";

import { Input2, Input4 } from "../../components/input";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import { colors } from "../../themes/colors";
import { DatePicker2 } from "../../components/datePicker";
import { useEffect, useState } from "react";
import { actions } from "../../redux";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import MultiSelectPicker from "../../components/multiSelectPicker";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { textValidate, textValidate2, validate } from "../../utils/helper";
import { ButtonActions } from "../../constants/userActions";
import { checkAlfaNumeric, checkNumeric } from "../../utils/regex";
import Promotions from ".";
interface DefaultProps {
  addRole?: boolean;
  editRole?: boolean;
  viewRole?: boolean;
}

interface IObjectKeys {
  [key: string]: string | number | Array<number>;
}

interface PromotionFields extends IObjectKeys {
  id: number;
  name: string;
  code: string;
  description: string;
  terms_and_conditions: string;
  type_id: number | string;
  start_date: string;
  end_date: string;
  discount: number | string;
  unit: number | string;
  upto: number | string;
  max: number | string;
  city_ids: Array<number>;
  vehicle_model_ids: Array<number>;
  package_ids: Array<number>;
  status: number;
  apply_on_package: any;
  free_additonal_helmet: any;
  free_home_delivery: any;
}

function AddEditPromotions(props: DefaultProps) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { couponCodeTypeData, allCitiesData, allVehicleData, allPackagesData } =
    useSelector((state: RootState) => state.commonReducer);
  const { global, promotions } = locale;
  const couponCodeUnitData = [
    { unit_type: promotions.INR, id: 1 },
    { unit_type: promotions.Percentage, id: 2 },
  ];
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (!props.addRole) {
      dispatch(
        actions.getCouponById({
          id: params.id,
        })
      );
    }
    dispatch(
      actions.getCouponCodeType({
        type: "CouponCodeType",
        id: 0,
      })
    );
    dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    );
    dispatch(
      actions.vehicleData({
        type: "VehicleModel",
        id: 0,
      })
    );
    dispatch(
      actions.packagesData({
        type: "GetPackages",
        id: 0,
      })
    );
  }, [refresh]);
  const { couponDataById, addCouponsuccess, loading, editCouponLoading } =
    useSelector((state: RootState) => state.couponReducer);
  const [couponDetails, setcouponDetails] = useState<PromotionFields>({
    id: 0,
    name: "",
    code: "",
    description: "",
    terms_and_conditions: "",
    type_id: 0,
    start_date: "",
    end_date: "",
    discount: 0,
    unit: 0,
    upto: 0,
    max: 0,
    city_ids: [],
    vehicle_model_ids: [],
    package_ids: [],
    status: 0,
    apply_on_package: 0,
    free_additonal_helmet: 0,
    free_home_delivery: 0,
  });

  const [errorFields, setErrorFields] = useState({
    name: "",
    code: "",
    description: "",
    terms_and_conditions: "",
    type_id: "",
    start_date: "",
    end_date: "",
    discount: "",
    unit: "",
    upto: "",
    max: "",
  });

  const [selectedCouponType, setSelectedCouponType] = useState({
    coupon_type: promotions.SelectCouponType,
    id: 0,
  });

  const [selectedCouponUnit, setSelectedCouponUnit] = useState({
    unit_type: promotions.SelectUnit,
    id: 0,
  });

  const [selectedOptionCities, setSelectedOptionCities] = useState([]);
  const [selectedOptionModels, setSelectedOptionModels] = useState([]);
  const [selectedOptionPackages, setSelectedOptionPackages] = useState([]);
  useEffect(() => {
    if (props.editRole) {
      setcouponDetails({
        ...couponDetails,
        id: couponDataById?.id,
        name: couponDataById?.name,
        code: couponDataById?.code,
        description: couponDataById?.description,
        terms_and_conditions: couponDataById?.terms_and_conditions,
        start_date: couponDataById?.start_date,
        end_date: couponDataById?.end_date,
        discount: couponDataById?.discount,
        unit: couponDataById?.unit,
        upto: couponDataById?.upto,
        type_id: couponDataById?.coupon_code_type?.id,
        max: couponDataById?.max,
        status: couponDataById?.status,
        apply_on_package: couponDataById?.apply_on_package,
        free_additonal_helmet: couponDataById?.free_additonal_helmet,
        free_home_delivery: couponDataById?.free_home_delivery,
      });
    }
    if (props.viewRole || props.editRole) {
      if (couponDataById.coupon_code_type) {
        setSelectedCouponType(couponDataById?.coupon_code_type);
      }
      if (couponDataById.unit) {
        let item: any = couponCodeUnitData.filter(
          (item) => item.id === couponDataById.unit
        );
        setSelectedCouponUnit(item[0]);
      }
      setData("city");
      setData("model");
      setData("package");
    }
  }, [couponDataById, refresh]);

  // MultiSelectPicker Code
  if (addCouponsuccess) {
    navigate("/promotions");
  }


  const setAllData = (tag: any) => {
    switch (tag) {
      case "City":
        let cities;
        cities = allCitiesData?.map((d: any) => ({
          value: d.id,
          label: d.city_name,
        }));

        return cities;

      case "Models":
        let vehicles;
        vehicles = allVehicleData?.map((d: any) => ({
          value: d.id,
          label: d.model_name,
        }));

        return vehicles;

      case "Packages":
        let packages;
        packages = allPackagesData?.map((d: any) => ({
          value: d.id,
          label: d.package_name,
        }));
        return packages;
      default:
        break;
    }
  };

  const renderMultiPickerOptions = (tag: any) => {
    switch (tag) {
      case "City":
        let cities = setAllData("City");
        cities?.unshift({
          value: 0,
          label: "All",
        });
        return cities;

      case "Models":
        let vehicles;
        vehicles = setAllData("Models");
        vehicles?.unshift({
          value: 0,
          label: "All",
        });

        return vehicles;

      case "Packages":
        let packages;
        packages = setAllData("Packages");
        packages?.unshift({
          value: 0,
          label: "All",
        });
        return packages;
      default:
        break;
    }
  };
  const setData = (tag: any) => {
    let arr: any = [];
    switch (tag) {
      case "city":
        if (couponDataById?.coupon_code_cities?.length > 0 || props?.addRole) {
          couponDataById?.coupon_code_cities.map((d: any) => {
            arr.push({
              label: d.city.city_name,
              value: d.city.id,
            });
          });
        }
        setSelectedOptionCities(arr);
        break;
      case "model":
        if (
          couponDataById?.coupon_code_vehicle_models?.length > 0 ||
          props?.addRole
        ) {
          couponDataById?.coupon_code_vehicle_models.map((d: any) => {
            arr.push({
              label: d?.vehicle_model_master?.model_name,
              value: d.vehicle_model_id,
            });
          });
        }
        setSelectedOptionModels(arr);
        break;
      case "package":
        if (
          couponDataById?.coupon_code_vehicle_models?.length > 0 ||
          props?.addRole
        ) {
          couponDataById?.coupon_code_vehicle_models[0].coupon_code_packages.map(
            (d: any) => {
              arr.push({
                label: d?.package_master?.package_name,
                value: d.package_id,
              });
            }
          );
        }

        setSelectedOptionPackages(arr);
        break;
      default:
    }
  };
  const handleChangePicker = (tag: any, value: any) => {


    switch (tag) {
      case "city":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            let cities = setAllData("City");
            setSelectedOptionCities(cities);
          } else {
            setSelectedOptionCities(value);
          }
        } else {
          setSelectedOptionCities(value);
        }
        break;
      case "model":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            let vehicles = setAllData("Models");
            setSelectedOptionModels(vehicles);
          } else {
            setSelectedOptionModels(value);
          }
        } else {
          setSelectedOptionModels(value);
        }
        break;
      case "package":
        if (value?.length > 0) {
          if (value[value.length - 1].value == 0) {
            let packages = setAllData("Packages");
            setSelectedOptionPackages(packages);
          } else {
            setSelectedOptionPackages(value);
          }
        } else {
          setSelectedOptionPackages(value);
        }
        break;
      default:
    }
  };

  // End of MultiSelectPicker Code
  const handleChangeCheckbox = (tag: any) => {
    switch (tag) {
      case "Package":
        setcouponDetails({
          ...couponDetails,
          apply_on_package: couponDetails.apply_on_package == 1 ? 0 : 1,
        });
        break;
      case "Delivery":
        setcouponDetails({
          ...couponDetails,
          free_home_delivery: couponDetails.free_home_delivery == 1 ? 0 : 1,
        });
        break;
      case "Helmet":
        setcouponDetails({
          ...couponDetails,
          free_additonal_helmet:
            couponDetails.free_additonal_helmet == 1 ? 0 : 1,
        });
        break;
      default:
    }
  };

  const handleChange = (tag: any, value: any) => {
    switch (tag) {
      case "name":
        setcouponDetails({
          ...couponDetails,
          name: textValidate(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, name: "" });
        } else {
          setErrorFields({ ...errorFields, name: "The field is required" });
          return;
        }

        break;
      case "code":
        setcouponDetails({
          ...couponDetails,
          code: textValidate(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, code: "" });
        } else {
          setErrorFields({ ...errorFields, code: "The field is required" });
          return;
        }

        break;
      case "discount":
        setcouponDetails({
          ...couponDetails,
          discount: value,
        });
        setErrorFields({ ...errorFields, discount: "" });

        break;
      case "description":
        setcouponDetails({
          ...couponDetails,
          description: textValidate2(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, description: "" });
        } else {
          setErrorFields({
            ...errorFields,
            description: "The field is required",
          });
          return;
        }
        break;
      case "terms_and_conditions":
        setcouponDetails({
          ...couponDetails,
          terms_and_conditions: textValidate2(value),
        });
        if (textValidate(value) !== "") {
          setErrorFields({ ...errorFields, terms_and_conditions: "" });
        } else {
          setErrorFields({
            ...errorFields,
            terms_and_conditions: "The field is required",
          });
          return;
        }
        break;
      case "start_date":
        setcouponDetails({
          ...couponDetails,
          start_date: moment(value).format(),
        });
        setErrorFields({ ...errorFields, start_date: "" });
        break;
      case "end_date":
        setcouponDetails({
          ...couponDetails,
          end_date: moment(value).format(),
        });
        setErrorFields({ ...errorFields, end_date: "" });
        break;
      case "upto":
        setcouponDetails({
          ...couponDetails,
          upto: value,
        });
        setErrorFields({ ...errorFields, upto: "" });

        break;
      case "max_usage":
        setcouponDetails({
          ...couponDetails,
          max: value,
        });
        setErrorFields({ ...errorFields, max: "" });
        break;
      case "couponType":
        setSelectedCouponType(value);
        setcouponDetails({ ...couponDetails, type_id: value?.id });
        if (value?.id === 0) {
          setErrorFields({ ...errorFields, type_id: "This Field is required" });
          return;
        } else setErrorFields({ ...errorFields, type_id: "" });
        break;

      //1
      case "unit_type":
        setSelectedCouponUnit(value);
        setcouponDetails({ ...couponDetails, unit: value?.id });
        if (value?.id === 0) {
          setErrorFields({ ...errorFields, unit: "This Field is required" });
          return;
        } else setErrorFields({ ...errorFields, unit: "" });
        break;
      default:
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    let city_ids: any = [];

    const cloneErrorFields: any = {
      ...errorFields,
    };
    if (selectedCouponUnit.unit_type !== "Percentage") {
      delete cloneErrorFields.upto;
    }

    let validation = validate(couponDetails, cloneErrorFields);

    if (validation.error) {
      setErrorFields({ ...errorFields, ...validation.errorFields });
      return;
    }
    if (selectedOptionCities?.length > 0) {
      selectedOptionCities.map((i: any) => {
        city_ids.push(i?.value);
      });
    }
    if (city_ids.length < 1) {
      toastr.error("Error", "Please select the applicable cities");
      return;
    }
    let vehicle_model_ids: any = [];
    if (selectedOptionModels?.length > 0) {
      selectedOptionModels.map((i: any) => {
        vehicle_model_ids.push(i.value);
      });
    }
    if (vehicle_model_ids.length < 1) {
      toastr.error("Error", "Please select the applicable vehicles");
      return;
    }
    let package_ids: any = [];
    if (selectedOptionPackages?.length > 0) {
      selectedOptionPackages.map((i: any) => {
        package_ids.push(i.value);
      });
    }
    if (package_ids.length < 1) {
      toastr.error("Error", "Please select the applicable packages");
      return;
    }
    if (
      couponDetails.apply_on_package != 1 &&
      couponDetails.free_additonal_helmet != 1 &&
      couponDetails.free_home_delivery != 1
    ) {
      toastr.error("Error", "Please select the applicable category");
      return;
    }

    let payload = {
      name: couponDetails.name,
      code: couponDetails.code,
      description: couponDetails.description,
      terms_and_conditions: couponDetails.terms_and_conditions,
      type_id: selectedCouponType.id,
      start_date: couponDetails.start_date,
      end_date: couponDetails.end_date,
      discount: couponDetails.discount,
      unit: selectedCouponUnit.id,
      upto: couponDetails.upto,
      max: couponDetails.max,
      city_ids: city_ids,
      vehicle_model_ids: vehicle_model_ids,
      package_ids: package_ids,
      apply_on_package: couponDetails.apply_on_package,
      free_additonal_helmet: couponDetails.free_additonal_helmet,
      free_home_delivery: couponDetails.free_home_delivery,
    };
    if (props.editRole) {
      dispatch(
        actions.editCoupon({
          id: params.id,
          status: couponDetails.status,
          ...payload,
        })
      );
    } else {
      dispatch(actions.addCoupon(payload));
    }
  };
  return (
    <div className="container pd-20">
      <Heading
        title={
          props.viewRole ? promotions.ViewPromocode : promotions.AddPromocode
        }
        actionButtons={props.viewRole ? [ButtonActions.refresh] : []}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          }
        }}
      />

      <form>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2%",
            bgcolor: "background.paper",
            borderRadius: 1,
            // justifyContent: "space-between",
          }}
        >
          <Input2
            width={300}
            readOnly={props.viewRole ? true : false}
            // value={packageManagement.SelectType}
            placeholder={global.Name}
            errormessage={errorFields.name}
            value={props.viewRole ? couponDataById?.name : couponDetails.name}
            defaultValue={
              props.viewRole || props.editRole ? "Super Splendor" : ""
            }
            required={true}
            onChange={(e: any) => {
              checkAlfaNumeric(e.target.value) &&
                handleChange("name", e.target.value);
            }}
          />
          <Input2
            width={300}
            // value={packageManagement.SelectType}
            readOnly={props.viewRole ? true : false}
            placeholder={promotions.Code}
            errormessage={errorFields.code}
            value={props.viewRole ? couponDataById?.code : couponDetails.code}
            defaultValue={props.viewRole || props.editRole ? "Hero" : ""}
            required={true}
            onChange={(e: any) => {
              checkAlfaNumeric(e.target.value) &&
                handleChange("code", e.target.value);
            }}
          />
          <Input2
            width={300}
            select
            readOnly={props.viewRole ? true : false}
            placeholder={global.Type}
            choice={couponCodeTypeData}
            required={true}
            errormessage={errorFields.type_id}
            name="type"
            defaultKey="coupon_type"
            heading={promotions.SelectCouponType}
            handleChange={(type: any) => handleChange("couponType", type)}
            value={
              props.viewRole
                ? selectedCouponType["coupon_type"]
                : selectedCouponType["coupon_type"]
            }
          />

          <Input4
            width={300}
            errormessage={errorFields.description}
            readOnly={props.viewRole ? true : false}
            placeholder={global.Description}
            value={
              props.viewRole
                ? couponDataById?.description
                : couponDetails.description
            }
            required={true}
            onChange={(e: any) => handleChange("description", e.target.value)}
          />
          <Input4
            errormessage={errorFields.terms_and_conditions}
            width={300}
            readOnly={props.viewRole ? true : false}
            placeholder={global.TermsAndConditions}
            value={
              props.viewRole
                ? couponDataById?.terms_and_conditions
                : couponDetails.terms_and_conditions
            }
            required={true}
            onChange={(e: any) =>
              handleChange("terms_and_conditions", e.target.value)
            }
          />
          <Box width={300} display="flex" flexDirection="column">
            <Input2
              width={300}
              // value={packageManagement.SelectType}
              placeholder={global.Discount}
              // type="number"
              errormessage={errorFields.discount}
              readOnly={props.viewRole ? true : false}
              value={
                props.viewRole
                  ? couponDataById?.discount
                  : couponDetails.discount
              }
              required={true}
              // onChange={(e)=>{}}
              onChange={(e: any) => {
                checkNumeric(e.target.value) &&
                  handleChange("discount", e.target.value);
              }}
            />
            <Box display="flex" flexDirection="column"></Box>

            <Grid>
              <Typography>
                Apply For:
                <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-evenly">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={
                        props.viewRole
                          ? couponDataById.apply_on_package == 1
                            ? true
                            : false
                          : couponDetails.apply_on_package
                      }
                      onChange={() => handleChangeCheckbox("Package")}
                      disabled={props.viewRole ? true : false}
                    />
                  }
                  label={promotions.Package}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={
                        props.viewRole
                          ? couponDataById.free_home_delivery == 1
                            ? true
                            : false
                          : couponDetails.free_home_delivery
                      }
                      onChange={() => handleChangeCheckbox("Delivery")}
                      disabled={props.viewRole ? true : false}
                    />
                  }
                  label={promotions.HomeDelivery}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={
                        props.viewRole
                          ? couponDataById.free_additonal_helmet == 1
                            ? true
                            : false
                          : couponDetails.free_additonal_helmet
                      }
                      onChange={() => handleChangeCheckbox("Helmet")}
                      disabled={props.viewRole ? true : false}
                    />
                  }
                  label={promotions.Helmet}
                  labelPlacement="end"
                />
              </FormGroup>
            </Grid>
          </Box>
          <DatePicker2
            required={true}
            readOnly={props.viewRole ? true : false}
            style={{ marginRight: "50px" }}
            placeholderMargin="0 0 0 3%"
            errormessage={errorFields.start_date}
            placeholder={promotions.StartDate}
            value={
              props.viewRole
                ? couponDataById?.start_date
                : couponDetails.start_date
            }
            onChange={(e: any) => handleChange("start_date", e)}
          />
          <DatePicker2
            required={true}
            readOnly={props.viewRole ? true : false}
            style={{ marginRight: "50px" }}
            placeholderMargin="0 0 0 3%"
            errormessage={errorFields.end_date}
            placeholder={promotions.EndDate}
            value={
              props.viewRole ? couponDataById?.end_date : couponDetails.end_date
            }
            onChange={(e: any) => handleChange("end_date", e)}
          />
          <Input2
            width={300}
            select
            readOnly={props.viewRole ? true : false}
            placeholder={global.Unit}
            choice={couponCodeUnitData}
            required={true}
            name="unit"
            errormessage={errorFields.unit}
            defaultKey="unit_type"
            heading={promotions.SelectUnit}
            handleChange={(type: any) => handleChange("unit_type", type)}
            value={
              props.viewRole
                ? selectedCouponUnit["unit_type"]
                : selectedCouponUnit["unit_type"]
            }
          />
          <MultiSelectPicker
            value={props.viewRole ? selectedOptionCities : selectedOptionCities}
            isDisabled={props.viewRole ? true : false}
            onChange={(event: any) => handleChangePicker("city", event)}
            options={renderMultiPickerOptions("City")}
            isMulti={true}
            title={promotions.ApplicableCities}
            placeholder={promotions.SelectCities}
          />
          <MultiSelectPicker
            value={props.viewRole ? selectedOptionModels : selectedOptionModels}
            isDisabled={props.viewRole ? true : false}
            onChange={(event: any) => handleChangePicker("model", event)}
            options={renderMultiPickerOptions("Models")}
            isMulti={true}
            title={promotions.ApplicableModels}
            placeholder={promotions.SelectModels}
          />
          <MultiSelectPicker
            value={
              props.viewRole ? selectedOptionPackages : selectedOptionPackages
            }
            isDisabled={props.viewRole ? true : false}
            onChange={(event: any) => handleChangePicker("package", event)}
            options={renderMultiPickerOptions("Packages")}
            isMulti={true}
            title={promotions.ApplicablePackages}
            placeholder={promotions.SelectPackages}
          />
          {selectedCouponUnit.unit_type === "Percentage" ? (
            <Input2
              width={300}
              // value={packageManagement.SelectType}
              readOnly={props.viewRole ? true : false}
              placeholder={promotions.Upto}
              type="number"
              value={props.viewRole ? couponDataById?.upto : couponDetails.upto}
              required={true}
              errormessage={errorFields.upto}
              onChange={(e: any) => handleChange("upto", e.target.value)}
            />
          ) : null}
          <Input2
            width={300}
            // value={packageManagement.SelectType}
            readOnly={props.viewRole ? true : false}
            placeholder={promotions.MaxUsage}
            value={props.viewRole ? couponDataById?.max : couponDetails.max}
            required={true}
            errormessage={errorFields.max}
            // type="number"
            onChange={(e: any) => {
              checkNumeric(e.target.value) &&
                handleChange("max_usage", e.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
          <Button2
            title={global.Back}
            back={true}
            style={{ marginLeft: "15px" }}
            onClick={() => navigate(-1)}
          />
          {props.editRole || props.addRole ? (
            <Button2
              title={global.SaveCoupon}
              onClick={(e: any) => onSubmit(e)}
              style={{ marginLeft: "15px" }}
              loading={editCouponLoading}
            />
          ) : null}
        </Box>
      </form>
    </div>
  );
}
export default AddEditPromotions;
