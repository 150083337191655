import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import { pageNotFoundPngIcon } from "../../assets/exportImages";
import { RootState } from "../../redux/store";
const WrongUrl = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.authReducer
  );
  return (
    <Box
      sx={{
        marginTop: isMobile ? "40%" : "10%",
        marginLeft: isMobile ? "8%" : isAuthenticated ? "30%" : "35%",
      }}
    >
      <img src={pageNotFoundPngIcon} style={{ width: isMobile ? 300 : 400 }} />
    </Box>
  );
};

export default WrongUrl;
