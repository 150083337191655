import * as actionTypes from "../actionTypes";

export const forgotPassword = (payload: any) => ({
  type: actionTypes.NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_REQUESTED,
  payload,
});

export const loginV3 = (payload: any) => ({
  type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGIN_REQUESTED,
  payload,
});

export const resendOtp = (payload: any) => ({
  type: actionTypes.NEW_AUTH_ACTIONS.RESEND_OTP_REQUESTED,
  payload,
});

export const setPassword = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.USER_SET_PASSWORD_REQUESTED,
    payload,
  };
};

export const accountUnlock = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_REQUESTED,
    payload,
  };
};

export const multifactorAuthentication = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_REQUESTED,
    payload,
  };
};

export const redirectionLinkVerification = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_REQUESTED,
    payload,
  };
};

export const changePassword = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.CHANGE_PASSWORD_REQUESTED,
    payload,
  };
};

export const showMessagePopUp = (payload: any) => {
  return { type: actionTypes.NEW_AUTH_ACTIONS.SHOW_POPUP_MESSAGE, payload };
};

export const setUserNewData = (payload: any) => {
  return { type: actionTypes.NEW_AUTH_ACTIONS.SET_USER_NEW_DATA, payload };
};

export const setUserFeedoXData = (payload: any) => {
  return { type: actionTypes.NEW_AUTH_ACTIONS.SET_USER_FREEDO_X_DATA, payload };
};

export const setUserFeedoRentalsData = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.SET_USER_FREEDO_RENTALS_DATA,
    payload,
  };
};

export const setUserFreedoDomain = (payload: boolean) => {
  return { type: actionTypes.NEW_AUTH_ACTIONS.CHECK_FREEDO_DOMAIN, payload };
};

export const userLogout = (payload: any) => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGOUT_REQUESTED,
    payload,
  };
};

export const isPasswordChanged = () => {
  return { type: actionTypes.NEW_AUTH_ACTIONS.IS_USER_CHANGED_PASSWORD };
};

export const isLoggedIn = () => {
  return {
    type: actionTypes.NEW_AUTH_ACTIONS.CHECK_ISLOGIN,
  };
};

export const setProfileData = (navigate: any) => {
  return {
    type: actionTypes.PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
    navigate,
  };
};

export const setAuthReducerKeys = (payload: any) => ({
  type: actionTypes.NEW_AUTH_ACTIONS.SET_AUTH_REDUCER_KEY,
  payload,
});
