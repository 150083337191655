import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tablist, Tabs } from "../../../components/tabs";
import {
  dashboardFunctionality,
  modules,
} from "../../../constants/RBACModuleEnums";
import { RootState } from "../../../redux/store";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../sharedComponents/atoms/InputFields/InputSelect";
import BookingsCard from "../../../sharedComponents/templates/BookingCard";
import { DataTable } from "../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../utils/helper";
import RbackHelper from "../../../utils/helperRBAC";
import {
  BookingStatusColorCodes,
  BookingStatusEnum,
  DashboardQuickFilter,
  GenericObject,
  dateWithTimeFormat,
  downloadRowCount,
  typographyConstants,
} from "../../constants/constants";
import {
  ActiveUsersIcon,
  AvailableVehicleIcon,
  BikeIconFaded,
  CompletedBookingIcon,
  EngagedVehicleIcon,
  KycVerifyUsers,
  OtherssIcon,
  RevenueCollectedIcon,
  TotalBookingIcon,
  TotalUsers,
  TotalVehicleIcon,
} from "../../constants/exportImages";
import rentalEn from "../../locale/rental-en.json";
import { getBookingStatsV3 } from "../../redux/actions/bookingManagementActions";
import {
  downloadCustomerBookingList,
  getCustomerBookingList,
  getCustomerBookingStats,
  getCustomerDashBoardBookingStats,
} from "../../redux/actions/customerManagementActions";
import { getVehicleStats } from "../../redux/actions/vehicleManagement";
import { routesConstants } from "../../utils/RoutesConstants";
import {
  combineDateAndTimeSlot,
  formatTime,
  fromNowDateAndTime,
  getDateTimeFromTimeStamp,
  getFormattedINR,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  toPascalCase,
} from "../../utils/helper";

const { isAccessRightsProvided } = RbackHelper;

const { global, dashboard } = rentalEn;

export const Dashboard = () => {
  //  RBAC for access rights
  const functionalitiesAccess = {
    isViewStats: isAccessRightsProvided(
      modules.DASHBOARD,
      dashboardFunctionality.DASHBOARD_STATS
    ),
    isViewBookingList: isAccessRightsProvided(
      modules.DASHBOARD,
      dashboardFunctionality.DASHBOARD_BOOKING_LIST
    ),
    isDownloadBookingList: isAccessRightsProvided(
      modules.DASHBOARD,
      dashboardFunctionality.DASHBOARD_DOWNLOAD_BOOKING
    ),
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authorizeModel } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const {
    bookingListLoader,
    bookingList: { bookings: bookingsList = [], pageData },
    downloadBookingListLoader,
    dashboardStats,
    loading,
    bookingStats,
  } = useSelector((state: RootState) => state.customerManagementReducer);

  // data from booking management reducer
  const { customerBookingStats }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );

  // tab list array
  const tabList: Tablist[] = [
    {
      key: dashboard.tabsList.newBookingReceived,
      count: customerBookingStats.newBookingRecieved,
    },
    {
      key: dashboard.tabsList.scheduledBooking,
      count: customerBookingStats.scheduledBooking,
    },
    {
      key: dashboard.tabsList.nearToReturnBookings,
      count: customerBookingStats.nearToReturnBooking,
    },
    {
      key: dashboard.tabsList.notReturned,
      count: customerBookingStats.notReturned,
    },
    {
      key: dashboard.tabsList.returnRequestsReceived,
      count: customerBookingStats.returnRequestRecieved,
    },
  ];

  const { onLoad, vehicleStatsData } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  const [tableData, setTableData] = useState<GenericObject>({
    bookingDateFrom: new Date(),
    bookingDateTill: new Date(),
    customerSort: true,
    quickFilters: DashboardQuickFilter.NEW_BOOKING_RECIEVED,
    paginationModel: { page: 0, pageSize: 10 },
    selectedModel: {
      name: "",
      displayName: rentalEn?.global?.allModels,
    },
  });

  const deliveryType: GenericObject = {
    onPickup: {
      BRANCH: "Hub Pickup",
      CUSTOMER_LOCATION: "Home Delivery",
    },
    onDelivery: {
      BRANCH: "Hub dropoff",
      CUSTOMER_LOCATION: "Home Pickup",
    },
  };

  // Memoize rowCount to keep it stable and avoid page reset.
  const rowCountRef: React.MutableRefObject<any> = useRef(pageData?.total || 0);

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (pageData?.total !== undefined) {
      rowCountRef.current = pageData?.total;
    }
    return rowCountRef.current;
  }, [pageData?.total]);

  useEffect(() => {
    if (functionalitiesAccess.isViewBookingList) {
      const payload: any = {
        // bookingDateFrom: getTimestampAtStartOfDay(tableData.bookingDateFrom),
        // bookingDateTill: getTimestampAtEndOfDay(tableData.bookingDateTill),

        quickFilters: tableData.quickFilters,
        page: tableData?.paginationModel?.page + 1,
        pageSize: tableData?.paginationModel?.pageSize,
        model: tableData?.selectedModel?.name
          ? [tableData?.selectedModel?.name]
          : undefined,
      };
      dispatch(getCustomerBookingList(payload));
    }
  }, [tableData]);

  useEffect(() => {
    const statsPayload = {
      model: tableData?.selectedModel?.name
        ? [tableData?.selectedModel?.name]
        : null,
    };

    dispatch(getBookingStatsV3(statsPayload));
  }, [tableData.selectedModel]);

  // handle pagination of table
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setTableData((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));
  };

  // bookings table columns
  const tableColumns: any[] = [
    {
      field: "userName",
      headerName: "Customer Name",
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={`${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`}
        >
          <span>
            {params?.row?.customer?.firstName
              ? `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "bookingId",
      headerName: global.bookingNumber,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: any) => {
        return (
          <Tooltip
            title={
              params?.row?.bookingNumber?.length > 11
                ? params?.row?.bookingNumber
                : ""
            }
          >
            <span>
              {params?.row?.bookingNumber
                ? params?.row?.bookingNumber
                : global.NA}
            </span>
          </Tooltip>
        );
      },
      flex: 1,
    },
    {
      field: "modelName",
      headerName: global.vehicleModel,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.modelDisplayName}>
          <span>
            {params?.row?.modelDisplayName
              ? params?.row?.modelDisplayName
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: "city",
      headerName: global.city,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.cityDisplayName
            ? params?.row?.cityDisplayName
            : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "hub",
      headerName: global.hub,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip title={params?.row?.branchDisplayName}>
          <span>
            {params?.row?.branchDisplayName
              ? params?.row?.branchDisplayName
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Typography variant="caption">
            {fromNowDateAndTime(
              combineDateAndTimeSlot(
                params?.row?.startDate,
                params?.row?.deliveryDetails?.slot?.end
              )
            )}
          </Typography>
        );
      },
    },
    {
      field: "endDate",
      headerName: rentalEn.tableHeaders.endDate,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={`${moment(
              combineDateAndTimeSlot(
                params?.row?.startDate,
                params?.row?.returnDetails?.slot?.start
              )
            ).format(dateWithTimeFormat)} - ${formatTime(
              params?.row?.returnDetails?.slot?.end
            )}`}
          >
            <span>
              {getDateTimeFromTimeStamp(params?.row?.endDate, "date")}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "package",
      headerName: rentalEn.tableHeaders.package,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <span>
            {params?.row?.mainBookingPackageDuration
              ? params?.row?.mainBookingPackageDuration === 1
                ? `${params?.row?.mainBookingPackageDuration} Day`
                : `${params?.row?.mainBookingPackageDuration} Days`
              : rentalEn.global.NA}
          </span>
        );
      },
    },
    {
      field: "deliveryDetails.type",
      headerName: "Delivery Pickup",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: any): any => {
        const { row } = params;
        const userDeliveryType = params.row.deliveryDetails.type;
        let deliveryPickup: string = "";
        if (
          row.status === BookingStatusEnum.PENDING ||
          row.status === BookingStatusEnum.UPCOMING ||
          row.status === BookingStatusEnum.ONGOING
        ) {
          deliveryPickup = deliveryType?.onPickup[userDeliveryType] || "";
        } else {
          deliveryPickup = deliveryType.onDelivery[userDeliveryType] || "";
        }

        return (
          <Tooltip title={deliveryPickup}>
            <span>{deliveryPickup ? deliveryPickup : global.NA}</span>
          </Tooltip>
        );
      },
    },

    {
      field: "status",
      headerName: global.status,
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: any): any => {
        return (
          <span
            style={{
              color:
                BookingStatusColorCodes[
                  params?.row?.status as keyof typeof BookingStatusColorCodes
                ],
            }}
          >
            {params?.row?.status
              ? toPascalCase(params?.row?.status)
              : global.NA}
          </span>
        );
      },
    },
    {
      type: "actions",
      field: "action",
      headerName: global.action,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      getActions: (params: any) => {
        return [
          <span>
            {params?.row?.id ? (
              <Link
                onClick={() => {
                  navigate(
                    `${routesConstants.TASK_ASSIGNMENT}/${params?.row?.id}`
                  );
                }}
              >
                {"Assign"}
              </Link>
            ) : (
              global.NA
            )}
          </span>,
        ];
      },
      flex: 1,
    },
  ];

  // genarate unique row id
  const getRowId = (row: any) => {
    return row?.id;
  };

  // on row click navigate to booking details
  const handleRowClick = (params: GridRowParams) => {
    if (params?.id) {
      navigate(`${routesConstants.BOOKING_DETAILS}/${params?.id}`);
    }
  };

  const tabListApiKey = [
    DashboardQuickFilter.NEW_BOOKING_RECIEVED,
    DashboardQuickFilter.SCHEDULED_BOOKING,
    DashboardQuickFilter.NEAR_TO_RETURN_BOOKING,
    DashboardQuickFilter.NOT_RETURNED,
    DashboardQuickFilter.RETURN_REQUESTS_RECEIVED,
  ];

  // download excel sheet pf booking list
  const handleBookingsDownload = () => {
    const payload: any = {
      bookingDateFrom: getTimestampAtStartOfDay(tableData.bookingDateFrom),
      bookingDateTill: getTimestampAtEndOfDay(tableData.bookingDateTill),
      quickFilters: tableData.quickFilters,
      page: downloadRowCount.page,
      pageSize: downloadRowCount.pageSize,
    };
    if (functionalitiesAccess.isDownloadBookingList) {
      dispatch(downloadCustomerBookingList(payload));
    }
  };

  // call api for current date as sson as we land on page
  useEffect(() => {
    if (functionalitiesAccess.isViewStats) {
      dispatch(getCustomerDashBoardBookingStats({}));
      dispatch(getVehicleStats({}));
      dispatch(getCustomerBookingStats({}));
    }
  }, []);

  return (
    <Stack gap="40px">
      {functionalitiesAccess.isViewStats ? (
        <>
          {/* Stats section */}
          {isAccessRightsProvided(
            modules.DASHBOARD,
            dashboardFunctionality.VEHICLE_STATS
          ) && (
            <Stack>
              <Typography
                variant={typographyConstants.HEADINGXL}
                sx={{ fontWeight: "500" }}
              >
                {dashboard.vehicleStatistics}
              </Typography>
              <Stack
                direction="row"
                className="StatsSection"
                justifyContent={"space-between"}
                gap="5px"
              >
                <BookingsCard
                  icon={TotalVehicleIcon}
                  text={dashboard.totalVehicle}
                  subText={vehicleStatsData?.totalVehicles}
                  loading={onLoad}
                />
                <BookingsCard
                  icon={AvailableVehicleIcon}
                  text={dashboard.availableVehicle}
                  subText={vehicleStatsData?.availableVehicles}
                  loading={onLoad}
                />
                <BookingsCard
                  icon={EngagedVehicleIcon}
                  text={dashboard.engagedVehicle}
                  subText={vehicleStatsData?.engagedVehicles}
                  loading={onLoad}
                />
                <BookingsCard
                  icon={OtherssIcon}
                  text={dashboard.Others}
                  subText={
                    vehicleStatsData?.totalVehicles -
                    (vehicleStatsData?.availableVehicles +
                      vehicleStatsData?.engagedVehicles)
                  }
                  loading={onLoad}
                />
              </Stack>
            </Stack>
          )}

          {isAccessRightsProvided(
            modules.DASHBOARD,
            dashboardFunctionality.USER_STATS
          ) && (
            <Stack>
              {/* Stats section */}
              <Typography
                variant={typographyConstants.HEADINGXL}
                sx={{ fontWeight: "500" }}
              >
                {dashboard.userStatistics}
              </Typography>
              <Stack
                direction="row"
                className="StatsSection"
                justifyContent={"space-between"}
                gap="5px"
              >
                <BookingsCard
                  icon={TotalUsers}
                  text={dashboard?.totalUsers}
                  subText={dashboardStats?.total}
                  loading={loading}
                />
                <BookingsCard
                  icon={ActiveUsersIcon}
                  text={dashboard.activeUsers}
                  subText={dashboardStats?.active}
                  loading={loading}
                />
                <BookingsCard
                  icon={KycVerifyUsers}
                  text={dashboard.pendingKYC}
                  subText={dashboardStats?.total - dashboardStats?.kycVerified}
                  loading={loading}
                />
              </Stack>
            </Stack>
          )}

          {/* need confirmation on this */}
          {isAccessRightsProvided(
            modules.DASHBOARD,
            dashboardFunctionality.BOOKING_STATS
          ) && (
            <Stack>
              <Typography
                variant={typographyConstants.HEADINGXL}
                sx={{ fontWeight: "500" }}
              >
                {dashboard?.bookingStatistics}
              </Typography>
              <Stack
                direction="row"
                className="StatsSection"
                justifyContent={"space-between"}
                gap="5px"
              >
                <BookingsCard
                  icon={RevenueCollectedIcon}
                  text={dashboard.revenueColleted}
                  subText={getFormattedINR(bookingStats?.totalPaidAmount)}
                  loading={onLoad}
                />
                <BookingsCard
                  icon={TotalBookingIcon}
                  text={dashboard?.totalBooking}
                  subText={bookingStats?.totalBookings}
                  loading={onLoad}
                />
                <BookingsCard
                  icon={CompletedBookingIcon}
                  text={dashboard.completedBooking}
                  subText={bookingStats?.completedBookings || global.NA}
                  loading={onLoad}
                />
              </Stack>
            </Stack>
          )}
        </>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "10px",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {/* <Box sx={{ height: "46px" }}>
          <InputDatePicker
            required
            label
            value={
              tableData?.bookingDateFrom
                ? tableData.bookingDateFrom
                : new Date()
            }
            onChange={(date: any) => {
              docHandleDateChange(date);
            }}
            placeholder={dashboard.startDate}
            width={"200px"}
            disabled={!functionalitiesAccess.isViewBookingList}
          />
        </Box> */}

        <CustomSelect
          required
          icon={BikeIconFaded}
          placeholder={global.selectModel}
          value={
            isArrayNotEmpty(authorizeModel)
              ? tableData?.selectedModel?.displayName
              : ""
          }
          choice={
            isArrayNotEmpty(authorizeModel)
              ? [
                  { name: "", displayName: rentalEn?.global?.allModels },
                  ...authorizeModel,
                ]
              : []
          }
          defaultKey={"displayName"}
          handleChange={(model: any) => {
            setTableData((prev: any) => ({
              ...prev,
              selectedModel: model,
            }));
          }}
          sx={{ width: "300px" }}
          boxStyle={{ height: "42px" }}
          maxWidth="300px"
          disabled={!functionalitiesAccess.isViewBookingList}
        />

        {functionalitiesAccess.isDownloadBookingList ? (
          <CustomButton
            label={rentalEn?.buttonLabels.download}
            variant={"contained"}
            onClick={handleBookingsDownload}
            loading={downloadBookingListLoader}
            sx={{ height: "42px" }}
          />
        ) : null}

        {functionalitiesAccess.isViewBookingList ? (
          <Tooltip title={global.reset}>
            <RotateRightSharpIcon
              fontSize="large"
              color="primary"
              sx={{ cursor: "pointer", width: "27px" }}
              onClick={() => {
                setTableData((prev: any) => ({
                  ...prev,
                  bookingDateFrom: new Date(),
                  bookingDateTill: new Date(),
                  customerSort: true,
                  quickFilters: DashboardQuickFilter.NEW_BOOKING_RECIEVED,
                  paginationModel: { page: 0, pageSize: 10 },
                  selectedModel: {
                    name: "",
                    displayName: rentalEn?.global?.allModels,
                  },
                }));
              }}
            />
          </Tooltip>
        ) : null}
      </Box>
      <Stack gap={"8px"}>
        <Tabs
          width={"19%"}
          setActiveTab={(i: any) =>
            setTableData((prev: any) => ({
              ...prev,
              quickFilters: tabListApiKey[i],
            }))
          }
          tabsList={tabList}
          defaultActive={0}
          disabled={!functionalitiesAccess.isViewBookingList}
        />

        <DataTable
          autoHeight
          rows={bookingsList}
          columns={tableColumns}
          headerAlign={"center"}
          getRowId={getRowId}
          loading={bookingListLoader}
          hideColumnSeperator
          rowCount={rowCount || 0}
          paginationModel={tableData?.paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          onRowClick={handleRowClick}
          hasAccess={functionalitiesAccess.isViewBookingList}
        />
      </Stack>
    </Stack>
  );
};
