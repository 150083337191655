import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import en from "../../../locale/en.json";
import { RootState } from "../../../redux/store";
import { BookingDetailsStyles } from "../../components/MultiStepForm/StyleObjects";
import BookingDetailStep from "./BookingDetailStep";
import { PaymentDetails } from "./PaymentDetails";
import PlanDetails from "./PlanDetails";
import VehicleDetails from "./VehicleDetails";
import UserDetails from "./UserDetails";
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MultiStepForm from "../../components/MultiStepForm/multiStepForm";
import StartRideModal from "../../components/startRideModal";
import { StepsProps } from "../../interfaces/componentsInterfaces";
import {
  cancelBookingYOR,
  cleanUpOrderAndPaymentData,
  clearBookingDetails,
  closeBookingYOR,
  createOrderForBulkPaymentYOR,
  generatePaymentLinkQRForOrder,
  getAvailableVehicleListYOR,
  getBookingDetailsYOR,
  getBookingStatusYOR,
  getOrderChargesBifurcationYOR,
  getPaymentHistoryYOR,
  getPaymentPendingYOR,
  getpaymentModesYOR,
  saveBulkAdditionalCharges,
} from "../../redux/actions/YOR_BookingDetailsActions";
import { colors } from "../../themes/colors";

import PaymentDetailsSideModalUI from "./sections/SideDrawerModalUI/PaymentDetailsSideModalUI";
import AddChargesSideModalUI from "./sections/SideDrawerModalUI/AddChargesSideModalUI";
import {
  bookingStatusDisplayName,
  statusButtonColor,
} from "../../constantsX/booking";
import UploadDocumentsSection from "./UploadDocumentsSection";
import { addActiveModuleX, clearDocUploadRedux } from "../../redux/actions";
import { BREADCRUMBS_YOR } from "../../../rentals-src/constants/activeModule";
import SideDrawer from "../../components/MultiStepForm/SideDrawer";
import ResponsiveDialog from "../../../components/responsiveDialog";
import {
  ADD_CHARGES_ACTION_TYPE,
  DisplayChargesNameTypes,
  DisplayPaymentModes,
  OrderPaymentType,
  DisplayPaymentHistoryModes,
} from "../../constantsX/YOR_BookingDetails";
import SubscriptionItem from "../../components/MultiStepForm/subscriptionItem";

import styles from "./bookingDetails.module.css";
import {
  OutlinedButtonX,
  StartIconLabelButtonX,
} from "../../components/muiButtonsX";
import { getFormattedINR } from "../../utils/helper";
import BookingDetailLoader from "../../../sharedComponents/molecules/SkeletonLoader/BookingDetailLoader";
import UploadDocumentLoader from "../../../sharedComponents/molecules/SkeletonLoader/UploadDocumentLoader";

const BookingDetails = () => {
  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    modelMapData,
    modelsMapImages,
    branchMapData,
    cityMasterMap,
    chargesCategoriesData,
  }: any = useSelector((state: RootState) => state.masterReducerX);
  const {
    alerts,
    bookingStatus,
    customerDetailsSection,
    bookingDetailsSection,
    vehicleDetailsSection,
    planDetailsSection,
    bookingDetailsloader,
    paymentHistorySection,
    paymentHistoryLoader,
    availableVehicles,
    pendingPaymentStatus,
    isRideStarted,
    startRideLoader,
    paymentLinkQRData,
    paymentLinkQRLoader,
    chargesBifurcationLoader,
    chargesBifurcation,
    paymentModes,
    orderDataloader,
    orderData,
    savedCharges,
  } = useSelector((state: RootState) => state.YOR_bookingDetailsReducer);

  const [bookingPayloadKeys, setBookingPayloadKeys] = useState([1, 2, 3, 4]);

  const { docUploadSuccess, docDeleteSuccess } = useSelector(
    (state: RootState) => state.commonReducerX
  );
  interface objInterface {
    [key: string]: any;
  }
  const addMoreChargesInitialObj = {
    chargeIndex: 1,
    category: { name: "", displayName: "" },
    subCategory: { name: "", displayName: "" },
    price: 0, // Save price in paise
    isEditable: false,
  };
  const addMoreChargesInitialState = [addMoreChargesInitialObj];
  const addMoreErrorObjInitial = {
    1: { category: false, subCategory: false, price: false },
  };

  const [showStartRideModal, setShowStartRideModal] = useState(false);
  const [showCancelBookingModal, setshowCancelBookingModal] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showPaymentSideModal, setShowPaymentSideModal] = useState(false);
  const [showAddChargeModal, setShowAddChargeModal] = useState(false);
  const [selectedAddMoreCharges, setSelectedAddMoreCharges] = useState(
    addMoreChargesInitialState
  );
  const [addMoreErrorObj, setAddMoreErrorObj] = useState({});
  const [paymentMethod, setPaymentMethod] = useState<any>(
    OrderPaymentType.PAYMENT_LINK
  );
  const [showAddChargesPaymentModeUi, setShowAddChargesPaymentModeUi] =
    useState<any>(false);
  const [sideDrawerUIComponent, setSideDrawerUIComponent] =
    useState<JSX.Element | null>(null);
  const resetMultistepFormRef = useRef<any>(null);
  // Booking steps payload mapping
  const stepsPayload: any = {
    0: [1, 2, 3, 4],
    1: [3],
    2: [4],
    3: [5],
  };

  // Handle payment drawer toggle
  const togglePaymentDrawer = () => {
    setShowPaymentSideModal((showPaymentSideModal) => {
      if (paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0) {
        dispatch(getPaymentHistoryYOR(param));
        dispatch(cleanUpOrderAndPaymentData());
      }
      return !showPaymentSideModal;
    });
  };

  const toggleRightDrawer = () => {
    setShowSideModal((showSideModal) => !showSideModal);
    // Empty modal content if required
  };

  // Side drawer UI components
  const sideDrawerUI = {
    paymentDetails: (
      <PaymentDetailsSideModalUI
        paymentLinkQRData={paymentLinkQRData}
        paymentLinkQRLoader={paymentLinkQRLoader}
      />
    ),
    /* addAdditionalCharges: <AddChargesSideModalUI />,
    additionalChargesWaiveOff: <PaymentDetailsSideModalUI />,
    additionalPayNow: <PaymentDetailsSideModalUI />,
    planDetails: <PaymentDetailsSideModalUI />, */
  };

  // Generate Payment link or QR
  const generatePaymentLinkQRHandler = (type: string, orderId: string) => {
    setSideDrawerUIComponent(sideDrawerUI["paymentDetails"]);
    setShowPaymentSideModal(true);
    if (orderId) {
      const payload = {
        data: {
          orderId: orderId,
          paymentFlow: type,
        },
      };
      dispatch(generatePaymentLinkQRForOrder(payload));
    }
  };

  // Get order charges bifurcation
  const getChargeBifurcationHandler = (event: any, recordId: string) => {
    const payload = {
      bookingId: param.id,
      recordId: recordId,
    };
    dispatch(getOrderChargesBifurcationYOR(payload));
  };
  {
    /* <BookingDetailStep
          bookingDetailsSection={bookingDetailsSection}
          bookingDetailsloader={bookingDetailsloader}
          branchMapData={branchMapData}
          plan={planDetailsSection?.plan}
          bookingStatus={bookingStatus}
          pendingPaymentStatus={pendingPaymentStatus}
          multiStepRef={resetMultistepFormRef}
          resetMultistepFormRef={resetMultistepFormRef}
          cityMasterMap={cityMasterMap}
        /> */
  }
  // All booking detail steps
  const bookingDetailsSteps: StepsProps[] = [
    {
      key: "bookingDetails",
      step: en.BookingDetailsFreedoX.bookingDetails,
      render: bookingDetailsloader ? (
        <BookingDetailLoader />
      ) : (
        <BookingDetailStep
          bookingDetailsSection={bookingDetailsSection}
          bookingDetailsloader={bookingDetailsloader}
          branchMapData={branchMapData}
          plan={planDetailsSection?.plan}
          bookingStatus={bookingStatus}
          pendingPaymentStatus={pendingPaymentStatus}
          multiStepRef={resetMultistepFormRef}
          resetMultistepFormRef={resetMultistepFormRef}
          cityMasterMap={cityMasterMap}
        />
      ),
      outsideRender: bookingDetailsloader ? (
        <UploadDocumentLoader />
      ) : (
        <UploadDocumentsSection
          bookingId={bookingDetailsSection?.id}
          documents={bookingDetailsSection?.documents}
        />
      ),
      stepAlert: alerts?.booking,
    },
    {
      key: "vehicleDetails",
      step: en.BookingDetailsFreedoX.vehicleDetails,
      render: bookingDetailsloader ? (
        <BookingDetailLoader />
      ) : (
        <VehicleDetails
          bookingId={bookingDetailsSection?.id}
          vehicleDetailsSection={vehicleDetailsSection}
          bookingDetailsloader={bookingDetailsloader}
          modelMapData={modelMapData}
          modelsMapImages={modelsMapImages}
          bookingParams={{ ...param, keys: bookingPayloadKeys }}
          bookingStatus={bookingStatus}
        />
      ),
      stepAlert: alerts?.vehicle,
    },
    {
      key: "planDetails",
      step: en.BookingDetailsFreedoX.planDetails,
      render: bookingDetailsloader ? (
        <BookingDetailLoader />
      ) : (
        <PlanDetails
          planDetailsSection={planDetailsSection}
          bookingDetailsloader={bookingDetailsloader}
          modelMapData={modelMapData}
          paymentModes={paymentModes}
          orderDataloader={orderDataloader}
          orderData={orderData}
          paymentLinkQRData={paymentLinkQRData}
          paymentLinkQRLoader={paymentLinkQRLoader}
          generatePaymentLinkQRHandler={generatePaymentLinkQRHandler}
          bookingPayloadKeys={bookingPayloadKeys}
          bookingStatus={bookingStatus}
          bookingStartDate={bookingDetailsSection?.startDate}
        />
      ),
      stepAlert: alerts?.plan,
    },
    {
      key: "paymentDetails",
      step: en.BookingDetailsFreedoX.paymentDetails,
      render: paymentHistoryLoader ? (
        <BookingDetailLoader />
      ) : (
        <PaymentDetails
          paymentHistorySection={paymentHistorySection}
          paymentHistoryLoader={paymentHistoryLoader}
          generatePaymentLinkQRHandler={generatePaymentLinkQRHandler}
          togglePaymentDrawer={togglePaymentDrawer}
          // toggleRightDrawer={toggleRightDrawer}
          showPaymentSideModal={showPaymentSideModal}
          setShowPaymentSideModal={setShowPaymentSideModal}
          paymentLinkQRData={paymentLinkQRData}
          paymentLinkQRLoader={paymentLinkQRLoader}
          getChargeBifurcationHandler={getChargeBifurcationHandler}
          chargesBifurcationLoader={chargesBifurcationLoader}
          chargesBifurcation={chargesBifurcation}
          DisplayPaymentHistoryModes={DisplayPaymentHistoryModes}
        />
      ),
      stepAlert: alerts?.payment,
    },
  ];

  // Set active step payload data
  const getStepWiseDataList = (step: any) => {
    let sectionPayload = stepsPayload[step];
    setBookingPayloadKeys(sectionPayload);
  };

  // Handle cancel booking modal
  const handleCancelBookingOpen = () => {
    setshowCancelBookingModal(true);
  };

  // Handle close bookings
  const handleCancelBookingClose = () => {
    setshowCancelBookingModal(false);
  };

  // Handle cancel bookings
  const handleCancelBooking = () => {
    const cancelBookingPayload = {
      bookingId: param.id,
    };
    if (bookingDetailsSection?.status === "ACTIVE") {
      dispatch(closeBookingYOR(cancelBookingPayload));
    } else {
      dispatch(cancelBookingYOR(cancelBookingPayload));
    }
    handleCancelBookingClose();
    // Dispatch Get Details section
  };

  /* Handle Start ride button click */
  const handleOpenStartRideModal = () => {
    setShowStartRideModal(true);
  };

  // close start ride modal
  const handleCloseStartRideModal = () => {
    setShowStartRideModal(false);
  };

  // Get available vehicles for start ride
  const handleGetAvailableVehicles = () => {
    let payload = {
      branches: bookingDetailsSection?.branchDetails?.name,
      modelNames: vehicleDetailsSection?.modelName,
    };
    dispatch(getAvailableVehicleListYOR(payload));
  };

  // Add charge
  const handleAddChargeModalOpen = () => {
    setShowAddChargeModal(true);
  };

  // Handling more charge modal toggle
  const toggleAddChargeDrawer = () => {
    setShowAddChargeModal((isShown) => {
      if (isShown) {
        setSelectedAddMoreCharges(addMoreChargesInitialState);
        setAddMoreErrorObj({});
        showAddChargesPaymentModeUi && setShowAddChargesPaymentModeUi(false);
        if (paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0) {
          dispatch(cleanUpOrderAndPaymentData());
          const allBookingSectionsParam = {
            ...param,
            keys: [3],
          };
          dispatch(getBookingDetailsYOR(allBookingSectionsParam));
        }
      }
      return !isShown;
    });
  };

  // Add more charges
  const handleAddMoreChargesClick = () => {
    setSelectedAddMoreCharges((prev: any) => {
      let prevLength = prev.length;
      return [
        ...prev,
        {
          ...addMoreChargesInitialObj,
          chargeIndex: prev[prevLength - 1].chargeIndex + 1,
        },
      ];
    });
  };

  // Handle add more charge - "Category" change
  const handleAddMoreCategoriesSelect = (category: any, chargeIndex: any) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev?.map((item: any, index: number) => {
        if (item.chargeIndex === chargeIndex) {
          item.category = category;
        }
        return { ...item };
      });

      return updatedAddMoreState;
    });
  };

  // Handle add more charge - "Sub Category" change
  const handleAddMoreSubCategoriesSelect = (
    subCategory: any,
    chargeIndex: any,
    subCategoryId: string | number
  ) => {
    const selectedSubData = chargesCategoriesData?.subCategories?.filter(
      (item: any, index: number) => {
        return item.id === subCategoryId;
      }
    );
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev?.map((item: any, index: number) => {
        if (item.chargeIndex === chargeIndex) {
          item.subCategory = subCategory;
          item.price =
            selectedSubData.length > 0 ? selectedSubData[0]?.amount / 100 : 0;
          item.isEditable = selectedSubData[0]?.isEditable;
        }
        return { ...item };
      });

      return updatedAddMoreState;
    });
  };

  // Handle add more charge - Price change handler
  const handleAddMoreChargesPrice = (price: any, chargeIndex: number) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev;
      price = price.toString().replace(/^0+/, "");
      if (price >= 0 && price.toString().length < 8) {
        updatedAddMoreState = prev?.map((item: any, index: number) => {
          if (item.chargeIndex === chargeIndex) {
            item.price = Number(price);
          }
          return { ...item };
        });
      }
      return updatedAddMoreState;
    });
  };

  // Validate and save bulk charges
  const handleAddMoreChargesSaveAll = (actionType: string) => {
    const checkValidation = AddMoreChargesValidation();
    if (checkValidation) return false;

    const chargePayload = selectedAddMoreCharges.map((charge: any) => {
      return {
        chargeCategory: charge.category.name,
        amount: Number(charge.price) * 100,
        chargeId: charge.subCategory.name,
      };
    });

    // Payload for save charge API
    let AddChargesPayload = {
      bookingId: param.id,
      charges: chargePayload,
    };
    dispatch(saveBulkAdditionalCharges(AddChargesPayload));

    // Only save charges
    if (actionType === ADD_CHARGES_ACTION_TYPE.SAVE) {
      toggleAddChargeDrawer();
    }
    // Initiate payment
    if (actionType === ADD_CHARGES_ACTION_TYPE.SAVE_AND_PAY) {
      setShowAddChargesPaymentModeUi(true);
    }
  };

  // Create order & Initiate Payment
  const handleChargeOrderGenerationAndPayment = () => {
    // Called when Payment Mode Selected
    let additionalChargeIds =
      savedCharges &&
      savedCharges.length > 0 &&
      savedCharges.map((charges: any) => charges?._id);
    if (additionalChargeIds.length > 0) {
      let orderPayload = {
        fetchBookingPayload: {
          ...param,
          keys: bookingPayloadKeys,
        },
        bookingId: param.id,
        selectedPaymentMode: paymentMethod,
        additionalChargesIds: additionalChargeIds,
      };
      dispatch(createOrderForBulkPaymentYOR(orderPayload));
      setShowAddChargesPaymentModeUi(false);
    }
  };

  // Add more charges validation
  const AddMoreChargesValidation = () => {
    let errorFlag = false;

    let errorObj: any = {};
    selectedAddMoreCharges.map((charge: objInterface, index: number) => {
      if (
        charge?.category?.name === "" ||
        charge?.subCategory?.name === "" ||
        charge?.price === 0 ||
        !(charge?.price > 0) ||
        !charge?.price
      ) {
        errorObj[charge?.chargeIndex] = true;
        errorFlag = true;
      }
    });
    setAddMoreErrorObj(errorObj);
    return errorFlag;
  };

  // Reset Add More Charges
  const handleDeleteAllAddMoreCharges = () => {
    setSelectedAddMoreCharges([addMoreChargesInitialObj]);
    setAddMoreErrorObj({});
  };

  // Delete add more charge
  const handleDeleteAddedCharge = (chargeIndex: number) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState =
        prev.length > 1
          ? prev.filter((item: any) => item.chargeIndex !== chargeIndex)
          : addMoreChargesInitialState;
      return updatedAddMoreState;
    });
    setAddMoreErrorObj((prev: any) => {
      return { ...prev, chargeIndex: false };
    });
  };

  // Payment mode change
  const handlePaymentModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(event.target.value);
  };

  // Add more charges breakup & payment method UI
  const AddMoreChargesPaymentUi = ({ savedCharges }: any) => {
    return (
      <>
        {/* Heading */}
        <Box
          sx={{
            padding: "10px",
            borderBottom: "1px solid",
            background:
              "linear-gradient(270.37deg, rgba(43, 199, 201, 0.24) -1.53%, rgba(27, 196, 125, 0.24) 68.84%, rgba(24, 160, 251, 0.24) 101.83%)",
          }}
        >
          <Typography variant="caption" color={colors.text_secondary_gray}>
            {en.YORBookingDetails.AddChargesTitle}:
          </Typography>
        </Box>
        <Stack gap="2vh" padding="10px">
          {/* BREAKUP */}
          <Stack className="ChargesBreakup" borderRadius="12px">
            {/* <Stack padding="10px"> */}
            <Box
              padding="10px"
              sx={{ background: "lightgray", borderRadius: "10px 10px 0 0" }}
            >
              <Typography variant="caption">Added Charges</Typography>
            </Box>

            <Stack
              padding="10px"
              sx={{ borderRadius: "0px 0px 12px 12px", background: "#fff" }}
            >
              {savedCharges &&
                savedCharges.length > 0 &&
                savedCharges.map((charges: any, index: number) => {
                  const filteredSubCategory =
                    chargesCategoriesData?.subCategories?.filter(
                      (subCategory: any, index: number) => {
                        return subCategory.id === charges?.chargeId;
                      }
                    );

                  return (
                    <>
                      <SubscriptionItem
                        key={index}
                        dashedBorder={false}
                        title={
                          DisplayChargesNameTypes[
                            charges?.chargeCategory as keyof typeof DisplayChargesNameTypes
                          ]
                        }
                        amount={getFormattedINR(charges?.amount)}
                        fontSize="10px"
                        showBorder={
                          index === savedCharges?.length - 1 ? false : true
                        }
                        // titleColor={charges?.titleColor}
                        description={
                          filteredSubCategory.length > 0 &&
                          filteredSubCategory[0].name
                        }
                        // sx={{background: "#edebeb"}}
                      />
                    </>
                  );
                })}
            </Stack>
            {/* </Stack> */}
          </Stack>

          <Divider />

          {/* PAYMENT MODE */}
          <Box className="PaymentMode">
            <Typography variant="caption" color={colors.text_secondary_gray}>
              Select Payment Method:
            </Typography>
            <Box className={styles.drawerHighlightBox}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={paymentMethod}
                  onChange={handlePaymentModeChange}
                >
                  {paymentModes?.map((mode: any) => (
                    <FormControlLabel
                      sx={{ fontSize: "" }}
                      value={mode}
                      control={<Radio />}
                      label={
                        DisplayPaymentModes[
                          mode as keyof typeof DisplayPaymentModes
                        ]
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          {/* SUBMIT BUTTON */}
          <StartIconLabelButtonX
            label={`Generate ${
              DisplayPaymentModes[
                paymentMethod as keyof typeof DisplayPaymentModes
              ]
            }`}
            variant="contained"
            minWidth={"100%"}
            minHeight="26px"
            backgroundColor={colors.primary_aqua_blue}
            onClick={
              orderDataloader ? null : handleChargeOrderGenerationAndPayment
            }
            disabled={orderDataloader}
          />
        </Stack>
      </>
    );
  };

  /* FOR CLEAN UP FUNCTION */
  /*  useEffect(() => {
    return () => {
      dispatch(clearBookingDetails());
      dispatch(clearDocUploadRedux());
    };
  }, []); */

  /* For Doc upload */
  useEffect(() => {
    const allBookingSectionsParam = {
      ...param,
      keys: bookingPayloadKeys,
    };
    if (docUploadSuccess || docDeleteSuccess) {
      dispatch(getBookingDetailsYOR(allBookingSectionsParam));
    }
  }, [docUploadSuccess, docDeleteSuccess]);

  /* Booking details & Payment Status */
  useEffect(() => {
    const allBookingSectionsParam = {
      ...param,
      keys: bookingPayloadKeys,
    };
    /* Step-wise Booking Data */
    if (bookingPayloadKeys.includes(5)) {
      dispatch(getPaymentHistoryYOR(param));
    } else {
      dispatch(getBookingDetailsYOR(allBookingSectionsParam));
      dispatch(clearDocUploadRedux());
    }
    /* Check for payment success after boooking */
    if (searchParams.get("type") == "created") {
      dispatch(getBookingStatusYOR(param));
      // Clean order and payment Link/Qr
      dispatch(cleanUpOrderAndPaymentData());
    }
    // Clean order and payment Link/Qr
    dispatch(cleanUpOrderAndPaymentData());
  }, [bookingPayloadKeys]);

  /* Start ride */
  useEffect(() => {
    if (isRideStarted) {
      const allBookingSectionsParam = {
        ...param,
        keys: stepsPayload[0],
      };
      handleCloseStartRideModal();
      dispatch(getBookingDetailsYOR(allBookingSectionsParam));
    }
  }, [isRideStarted]);

  // ACTIVE SIDEBAR, BREADCRUMS & TITLE
  useEffect(() => {
    dispatch(addActiveModuleX(BREADCRUMBS_YOR.BOOKING_DETAILS));
  }, []);

  const CustomRenderComponent = sideDrawerUIComponent;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <UserDetails
        customerDetailsSection={customerDetailsSection}
        bookingDetailsloader={bookingDetailsloader}
        bookingStatus={bookingStatus}
        handleOpenStartRideModal={handleOpenStartRideModal}
        handleCancelBookingOpen={handleCancelBookingOpen}
        handleGetAvailableVehicles={handleGetAvailableVehicles}
        bookingPayloadKeys={stepsPayload[0]}
        handleAddChargeModalOpen={handleAddChargeModalOpen}
      />
      <MultiStepForm
        steps={bookingDetailsSteps}
        viewMode={true}
        Styles={BookingDetailsStyles}
        getStepWiseDataList={getStepWiseDataList}
        showStatus={true}
        showStatusloader={bookingDetailsloader}
        bookingStatus={
          bookingStatusDisplayName[
            bookingStatus as keyof typeof bookingStatusDisplayName
          ]
        }
        bookingStatusColor={
          statusButtonColor[bookingStatus as keyof typeof statusButtonColor]
        }
        ref={resetMultistepFormRef}
      />

      {/* Start ride modal */}
      {showStartRideModal && (
        <StartRideModal
          openModal={showStartRideModal}
          handleModalClose={handleCloseStartRideModal}
          startRideLoader={startRideLoader}
          availableVehicles={availableVehicles}
          bookingId={bookingDetailsSection?.id}
          isRideStart={isRideStarted}
        />
      )}

      {/* Cancel booking modal */}
      <ResponsiveDialog
        fullScreen={false}
        title="Are you sure you want to Cancel this Booking?"
        handleClose={handleCancelBookingClose}
        open={showCancelBookingModal}
        fullWidth="true"
        maxWidth="md"
      >
        <Button
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          autoFocus
          onClick={handleCancelBooking}
        >
          Yes
        </Button>
        <Button
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          onClick={handleCancelBookingClose}
          autoFocus
        >
          No
        </Button>
      </ResponsiveDialog>

      {/* Add charges Drawer */}
      <SideDrawer
        disablePadding={true}
        open={showAddChargeModal}
        setOpen={setShowPaymentSideModal}
        toggleDrawer={toggleAddChargeDrawer}
        renderUI={
          paymentLinkQRLoader ||
          (paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0) ? (
            <PaymentDetailsSideModalUI
              paymentLinkQRData={paymentLinkQRData}
              paymentLinkQRLoader={paymentLinkQRLoader}
            />
          ) : showAddChargesPaymentModeUi ? (
            <AddMoreChargesPaymentUi savedCharges={savedCharges} />
          ) : (
            <AddChargesSideModalUI
              chargesCategoriesData={chargesCategoriesData}
              selectedAddMoreCharges={selectedAddMoreCharges}
              setSelectedAddMoreCharges={setSelectedAddMoreCharges}
              handleAddMoreChargesClick={handleAddMoreChargesClick}
              handleDeleteAllAddMoreCharges={handleDeleteAllAddMoreCharges}
              handleDeleteAddedCharge={handleDeleteAddedCharge}
              handleAddMoreCategoriesSelect={handleAddMoreCategoriesSelect}
              handleAddMoreSubCategoriesSelect={
                handleAddMoreSubCategoriesSelect
              }
              handleAddMoreChargesSaveAll={handleAddMoreChargesSaveAll}
              handleAddMoreChargesPrice={handleAddMoreChargesPrice}
              addMoreErrorObj={addMoreErrorObj}
            />
          )
        }
        displayPaymentMethod={false}
      ></SideDrawer>
    </Box>
  );
};
export default BookingDetails;
