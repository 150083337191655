import * as actionTypes from "../actionTypes";


export const getTaskList = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASKLIST_REQUESTED,
    payload,
  });

  export const getTaskDetailsById = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_REQUESTED,
    payload,
  });


  export const createTask = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.CREATE_TASK_REQUESTED,
    payload,
  });
  export const editTask = (payload: any, id:any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_REQUESTED,
    payload,
    id
  });

  export const deleteTask = (payload:any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.DELETE_TASK_REQUESTED,
    payload,
  });

  export const getTaskSequenceList = (payload: any) => {
    return {
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_REQUESTED,
    payload,
  }};
  
  export const getTaskWarehouseList = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_WAREHOUSE_LIST_REQUESTED,
    payload,
  });


  export const editTaskSequence = (payload: any, id:any, taskType:any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_REQUESTED,
    payload,
    id, 
    taskType
  });

  export const getTasKStatus = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_REQUESTED,
    payload,
  });

  export const getTasKSummary = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_REQUESTED,
    payload,
  });
  export const clearTasKSummary = () => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.CLEAR_TASK_SUMMARY_DATA,
 
  });

  export const getTaskUserList = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_REQUESTED,
    payload,
  });


  export const postTaskAsignment = (payload: any) => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_REQUESTED,
    payload,
  });

  export const getTaskSuggestion = () => ({
    type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_REQUESTED,
  
  });
