import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Lottie from "lottie-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModifyBookingSummary } from "..";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";
import {
  DisplayPaymentModes,
  paymentModeIcons,
  typographyConstants,
} from "../../../../constants/constants";
import { SuccessLottie } from "../../../../constants/exportLottie";
import rentalEn from "../../../../locale/rental-en.json";
import {
  generatePaymentLinkQRForOrder,
  modifyAddressAddOnCharges,
  modifyModelUpgrade,
  modifyPlanAndSlotCharges,
} from "../../../../redux/actions/bookingManagementActions";
import {
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../../utils/helper";
import PaymentDetailsSideModalUI from "../../bookingDetails/components/PaymentDetailsSideModalUI";

const ModifyBookingSummarySideDrawer = ({
  togglePaymentModal,
  fields,
  getAddOnsPayload = () => {},
  getPlansPayload = () => {},
  getUpgradeVehiclePayload = () => {},
}: any) => {
  // plans data from master data
  const { operatorConfigData } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const [paymentMode, setPaymentMode] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const {
    bookingDetails = {},
    isPaymentLinkGenerated,
    paymentLinkQRLoader,
    paymentLinkQRData,
    bookingModifyLoading,
    modifyBookingChargesLoader,
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);

  const { modifyBookingCharges = {} }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const { amountToPay, charges } = modifyBookingCharges;

  const handlePaymentModeChange = (event: any) => {
    setPaymentMode(event.target.value);
  };

  // handle modifybooking payment
  const handlePayment = () => {
    if (
      fields.rideStartDate &&
      fields.rideEndDate &&
      fields.modificationReason?.id
    ) {
      paymentForSlotsAndPackage();
    } else if (fields?.selectedUpgradeVehicle?.displayName) {
      paymentForUpgradeVehicle();
    } else {
      paymentForAddOnsAndAddress();
    }
  };

  const paymentForAddOnsAndAddress = () => {
    // helmetPayload & address payload
    let payload = getAddOnsPayload && getAddOnsPayload();

    dispatch(
      modifyAddressAddOnCharges({
        data: { ...payload },
        bookingId: bookingDetails?.id,
        onSuccess: (data: any) => {
          if (data?.orderId) {
            const payload = {
              data: {
                orderId: data?.orderId || "",
                paymentFlow: paymentMode,
              },
            };
            dispatch(generatePaymentLinkQRForOrder(payload));
          } else {
            setIsSuccess(true);
          }
        },
      })
    );
  };

  const paymentForSlotsAndPackage = () => {
    if (bookingDetails?.id) {
      const payloadData = getPlansPayload();
      dispatch(
        modifyPlanAndSlotCharges({
          data: payloadData,
          bookingId: bookingDetails?.id,
          onSuccess: (data: any) => {
            if (data?.orderId) {
              const payload = {
                data: {
                  orderId: data?.orderId || "",
                  paymentFlow: paymentMode,
                },
              };
              dispatch(generatePaymentLinkQRForOrder(payload));
            } else {
              setIsSuccess(true);
            }
          },
        })
      );
    }
  };

  const paymentForUpgradeVehicle = () => {
    if (bookingDetails?.id) {
      const payloadData = getUpgradeVehiclePayload();
      dispatch(
        modifyModelUpgrade({
          data: payloadData,
          bookingId: bookingDetails?.id,
          onSuccess: (data: any) => {
            if (data?.orderId) {
              const payload = {
                data: {
                  orderId: data?.orderId || "",
                  paymentFlow: paymentMode,
                },
              };
              dispatch(generatePaymentLinkQRForOrder(payload));
            } else {
              setIsSuccess(true);
            }
          },
        })
      );
    }
  };

  if (isSuccess) return <SuccessScreen />;

  return (
    <>
      {isPaymentLinkGenerated ? (
        <PaymentDetailsSideModalUI
          paymentLinkQRData={paymentLinkQRData}
          paymentLinkQRLoader={paymentLinkQRLoader}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <>
            {" "}
            <Stack
              gap="15px"
              sx={{
                marginLeft: "20px",
                marginRight: "20px",
                marginTop: "10px",
              }}
            >
              <ModifyBookingSummary
                isPaymentScreen
                togglePaymentModal={togglePaymentModal}
              />
              {amountToPay ? (
                <Box>
                  <Typography
                    color={colors.text_secondary_gray}
                    fontSize="12px"
                    fontWeight="500"
                    marginBottom="12px"
                  >
                    {rentalEn.manualBooking.SelectPaymentMethod}:
                  </Typography>

                  <Box
                    sx={{
                      backgroundColor: colors.white,
                      borderRadius: "12px",
                      border: `1px solid ${colors.card_border_bottom}`,
                    }}
                  >
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={paymentMode}
                      onChange={(e: any) => handlePaymentModeChange(e)}
                    >
                      {operatorConfigData?.allowedPaymentFlows?.map(
                        (mode: string, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                borderBottom:
                                  index < 2
                                    ? `1px solid ${colors.light_green2}`
                                    : "",
                                padding: "2px 18px",
                              }}
                            >
                              <FormControlLabel
                                value={mode}
                                control={<Radio />}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    color: colors.text_secondary_gray,
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  },
                                }}
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <img src={paymentModeIcons[mode]} />
                                    <Typography
                                      sx={{
                                        fontWeight: 500,
                                        color: colors.text_black,
                                      }}
                                    >
                                      {
                                        DisplayPaymentModes[
                                          mode as keyof typeof DisplayPaymentModes
                                        ]
                                      }
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Box>
                          );
                        }
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
              ) : null}
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 20,
                left: 12,
                right: 12,
              }}
            >
              <CustomButton
                label={rentalEn.buttonLabels.proceed}
                variant="contained"
                loading={
                  modifyBookingChargesLoader ||
                  bookingModifyLoading ||
                  paymentLinkQRLoader
                }
                sx={{
                  width: "100%",
                }}
                disabled={!paymentMode && amountToPay}
                onClick={handlePayment}
              />
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

export default ModifyBookingSummarySideDrawer;

const SuccessScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30px",
        height: "100vh",
      }}
    >
      <Lottie
        style={{ height: "200px", width: "200px" }}
        animationData={SuccessLottie}
        loop={false}
      />
      <Typography
        variant={typographyConstants.HEADING}
        sx={{ marginTop: "24px" }}
      >
        {rentalEn.successMessage.bookingModifiedSuccessfully}
      </Typography>
    </Box>
  );
};
