import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import { toPascalCase } from "../../../rentals-src/utils/helper";
import styles from "../customcomponents.module.css";

interface CustomMultiSelectInterFace {
  defaultKey: string;
  hidePlaceholder?: boolean;
  displayNameKey: string;
  value?: any;
  choice?: any;
  handleChange: any;
  formHelperText?: string;
  errormessage?: string;
  topHeading?: string;
  isShowSelectAll?: boolean;
  selectAllText?: string;
  minWidth?: number;
  width?: string;
  maxWidth?: string;
  boxStyle?: any;
  heading?: string;
  disabled?: boolean;
  icon: any;
  muiIcons?: any;
  placeholder: string;
  [key: string]: any;
}

const CustomMultiSelect = (props: CustomMultiSelectInterFace) => {
  const {
    defaultKey,
    hidePlaceholder = false,
    displayNameKey = "displayName",
    value,
    choice,
    handleChange,
    formHelperText,
    errormessage,
    topHeading,
    isShowSelectAll = true,
    selectAllText = "Select All",
    placeholder,
    boxStyle,
    heading,
    width,
    maxWidth,
    minWidth,
    icon,
    muiIcons,
    ...rest
  } = props;

  const isAllSelected = Object?.keys(value)?.length === choice?.length;

  const handleSelect = (event: any) => {
    const value = event.target.value;
    if (isShowSelectAll && value[value?.length - 1] === "all") {
      const updatedValue = choice?.reduce((accum: any, item: any) => {
        accum.push(item?.[defaultKey]);
        return accum;
      }, []);
      handleChange(isAllSelected ? [] : updatedValue);
      return;
    }
    // Update the selected values
    handleChange(value);
  };
  return (
    <Box
      className={styles.BoxColumn}
      minWidth={minWidth}
      width={width || "100%"}
      maxWidth={maxWidth || "520px"}
      {...boxStyle}
    >
      {topHeading && (
        <Typography variant={typographyConstants.HEADING}>
          {topHeading}
        </Typography>
      )}
      <FormControl
        sx={{
          minHeight: "42px",
          height: "40px",
          maxHeight: "50px",
          width: "100%",
        }}
      >
        {heading && (
          <InputLabel
            id="multi-select-label"
            sx={{ fontSize: "12px", marginTop: "3px" }}
          >
            {heading}
          </InputLabel>
        )}
        <Select
          label={heading}
          value={value || []}
          multiple
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              {muiIcons ? muiIcons : <img src={icon} alt="icon" />}
            </InputAdornment>
          }
          sx={{
            "& .MuiButtonBase-root-MuiMenuItem-root li": {
              minHeight: "30px",
            },
            "& .MuiMenu-list li": {
              height: "42px !important",
            },
          }}
          onChange={handleSelect}
          renderValue={(selected: any) => {
            if (selected?.length === 0) {
              return <>{placeholder}</>;
            } else if (isAllSelected) {
              return selectAllText;
            }
            return (selected as any)
              .map((val: any) => {
                const item = choice.find(
                  (choice: any) => choice[defaultKey] === val
                );
                return item ? item[displayNameKey] : toPascalCase(val);
              })
              .join(", ");
          }}
          className={styles.select}
          {...rest}
        >
          {!hidePlaceholder && (
            <MenuItem disabled value={[]} className={styles.customMultiSelect}>
              <>{placeholder}</>
            </MenuItem>
          )}
          {isShowSelectAll && (
            <MenuItem value="all" className={styles.customMultiSelect}>
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    value?.length > 0 && value?.length < choice?.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={selectAllText} />
            </MenuItem>
          )}

          {choice?.map((data: any, index: number) => {
            const isSelected = (value || []).includes(data[defaultKey]);
            return (
              <MenuItem
                key={index}
                value={data[defaultKey]}
                className={styles.customMultiSelect}
              >
                <Checkbox checked={isSelected} />
                <ListItemText
                  primary={
                    data[displayNameKey] || toPascalCase(data[defaultKey])
                  }
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText>
        {errormessage && (
          <Typography
            variant={typographyConstants.ERRORMESSAGE}
            sx={{ pl: 1, pb: 1 }}
          >
            {errormessage}
          </Typography>
        )}
        {formHelperText && (
          <Typography color="drawerMenu.text.secondary" sx={{ pl: 1, pb: 1 }}>
            {formHelperText}
          </Typography>
        )}
      </FormHelperText>
    </Box>
  );
};
export default CustomMultiSelect;

// Usage Notes:
// 1. `choice` should be an array of objects with {name, displayName} or similar.
// 2. `value` should be an array of selected values (e.g., names).
// 3. `handleChange` should handle the array of selected values (e.g., names).
// 4. `displayNameKey` is the key for the display name in the choice objects (default is "displayName").
