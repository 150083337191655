import * as React from "react";
const Home = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke={props?.color}
      strokeOpacity={1.5}
      d="m6 14.667-.167-2.34a2.173 2.173 0 1 1 4.334 0L10 14.667"
    />
    <path
      stroke={props?.color}
      strokeLinejoin="round"
      strokeOpacity={1.5}
      d="M1.567 8.809c-.235-1.532-.353-2.297-.063-2.976.29-.679.932-1.143 2.216-2.072l.96-.694C6.28 1.91 7.078 1.333 8 1.333c.922 0 1.72.578 3.319 1.734l.96.694c1.285.929 1.927 1.393 2.217 2.072.29.679.171 1.444-.064 2.976l-.2 1.306c-.334 2.17-.501 3.256-1.28 3.904-.778.648-1.916.648-4.193.648H7.24c-2.276 0-3.414 0-4.193-.648-.779-.648-.945-1.733-1.279-3.904l-.2-1.306Z"
    />
  </svg>
);
export default Home;
