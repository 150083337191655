import { Box, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/store";
import SelectX from "../components/InputFields/InputSelectX";
import styles from "../components/components.module.css";
import { startRidePayloadX } from "../interfaces/bookingInterfaces";
import { StartRideModalProps } from "../interfaces/componentsInterfaces";
import { actionsX } from "../redux";
import { colors } from "../themes/colors";
import { checkNumericX, validate } from "../utils/helper";
import InputX from "./InputFields/InputX";
import { EndIconLabelButtonX } from "./muiButtonsX";
import { userStartRideYOR } from "../redux/actions/YOR_BookingDetailsActions";
import { useParams } from "react-router-dom";

const StartRideModal = (props: StartRideModalProps): JSX.Element => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: props?.isRideStart ? "#293241" : "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    // height: "350px",
  };
  const dispatch = useDispatch();
  const [fields, setFields] = useState<any>({
    selectedVehicle: { name: "", registration: "" },
    startOdometerValue: "",
  });
  const [errfields, setErrFields] = useState<any>({
    selectedVehicle: "",
    startOdometerValue: "",
  });
  const param = useParams();
  const bookingPayloadKeys = props.bookingPayloadKeys;

  const handleChange = (fields: string, value: string | object) => {
    setFields((prev: any) => ({ ...prev, [fields]: value }));
    setErrFields((prev: any) => ({ ...prev, [fields]: "" }));
  };

  const handleStartRide = () => {
    let validateFields = validate(fields, errfields);

    if (validateFields.error) {
      return setErrFields({ ...errfields, ...validateFields.errorFields });
    }
    let payload: startRidePayloadX = {
      data: {
        data: {
          vehicleId: fields?.selectedVehicle?.id,
          startOdometerValue: fields?.startOdometerValue,
        },
      },
      bookingId: props?.bookingId,
    };
    dispatch(userStartRideYOR(payload));
  };

  const startRideFormUI = () => (
    <Box>
      <Box
        sx={{
          width: "100%",
          backgroundImage:
            "linear-gradient(89.92deg, rgba(27, 216, 218, 0.24) 1.67%, rgba(27, 196, 166, 0.228) 123.92%)",
          padding: "13px 18px 13px 18px",
          borderRadius: "32px 32px 0px 0px",
          borderBottom: "1px solid  #1BD8DA",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>Start Ride Form</Typography>
      </Box>

      <Box
        sx={{
          padding: "30px 20px 30px 20px",
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <SelectX
          required
          choice={props?.availableVehicles}
          defaultKey={"registration"}
          placeholder={"Select vehicle"}
          value={fields?.selectedVehicle.registration}
          handleChange={(vehicle: object) =>
            handleChange("selectedVehicle", vehicle)
          }
          errormessage={errfields.selectedVehicle}
        />
        <InputX
          placeholder={"Start odometer reading"}
          width="100%"
          value={fields?.startOdometerValue}
          onChange={(e: any) =>
            checkNumericX(e.target.value) &&
            e.target.value.length < 8 &&
            handleChange("startOdometerValue", e.target.value)
          }
          errormessage={errfields.startOdometerValue}
        />
      </Box>
      <Box sx={{ padding: "30px 20px 15px 20px" }}>
        <EndIconLabelButtonX
          variant="contained"
          label="Start Ride"
          onClick={handleStartRide}
          loading={props?.startRideLoader}
          width="100%"
        />
      </Box>
    </Box>
  );

  const startRideSuccessUI = () => (
    <Box
      className={styles.BoxColumn}
      sx={{ alignItems: "center", gap: "10px" }}
    >
      {/* <StartIconLabelButtonX variant="outlined" label="View Details" /> */}
    </Box>
  );

  return (
    <Modal
      open={props.openModal}
      onClose={props.handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        {props?.isRideStart ? startRideSuccessUI() : startRideFormUI()}
      </Box>
    </Modal>
  );
};

export default StartRideModal;
