import { API_URLS, BASE_URL, customAxios } from "../config";

const getFeedbacks = (payload: any) => {
  try {
    let feedbackUrl = API_URLS.getFeedbacks;
    if (payload.page) feedbackUrl += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size))
      feedbackUrl += `&size=${payload.size}`;
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      feedbackUrl += `&city_id=${payload.city_id}`;
    if (
      payload.location_id != 0 &&
      payload.location_id &&
      !isNaN(payload.location_id)
    )
      feedbackUrl += `&location_id=${payload.location_id}`;

    return fetchFeedbacks(feedbackUrl, payload.token);
  } catch (ex) {
    return ex;
  }
};

const getLocationByCity = (payload: any) => {
  try {
    let route =  API_URLS.getLocationByCity;
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      route += `&city_id=${payload.city_id}`;
    if (payload.subCompanyId != 0 && payload.subCompanyId)
      route += `&subCompanyId=${payload.subCompanyId}`;

    return fetchLocations(route);
  } catch (err) {
    return err;
  }
};

const getFeedbackService = (payload: any) => {
  try {
    let feedbackUrl = BASE_URL + API_URLS.newFeedBackURL;
    if (payload.page) feedbackUrl += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size))
      feedbackUrl += `&size=${payload.size}`;
    if (payload.cityId != 0 && payload.cityId && !isNaN(payload.cityId))
      feedbackUrl += `&cityId=${payload.cityId}`;
    if (payload.branchId != 0 && payload.branchId && !isNaN(payload.branchId))
      feedbackUrl += `&branchId=${payload.branchId}`;
    if (payload.bookingId) feedbackUrl += `&bookingId=${payload.bookingId}`;
    if (payload.status) feedbackUrl += `&status=${payload.status}`;
    if (payload.lob?.id !==0) feedbackUrl += `&lob=${payload.lob?.name}`;
    if (payload.createdFrom) feedbackUrl += `&createdFrom=${payload.createdFrom}`;
    if (payload.createdTo) feedbackUrl += `&createdTo=${payload.createdTo}`;
    

    return fetchFeedbackService(feedbackUrl, payload.token);
  } catch (ex) {
    return ex;
  }
};



const getFeedbackById = (payload: any) => {
  try {
    let feedbackUrl = BASE_URL + API_URLS.feedBackById;
    if (payload.feedBackId) feedbackUrl += `${payload.feedBackId}`;
   return fetchFeedbackById(feedbackUrl, payload.token);
  } catch (ex) {
    return ex;
  }
};

const getFeedbackByBookingId = (payload: any) => {
  try {
    let feedbackUrl = BASE_URL + API_URLS.getFeedbackByBookingId;
    if (payload.bookingId) feedbackUrl += `=${payload.bookingId}`;
   return fetchFeedbackByBookingId(feedbackUrl, payload.token);
  } catch (ex) {
    return ex;
  }
};

const submitExecutiveFeedBack = (payload: any) => {
  try {
    let url =  BASE_URL + API_URLS.executiveFeedback;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const fetchFeedbacks = (route: any, token: any) =>
  customAxios.get(route).catch((ex) => ex);

  const fetchFeedbackService = (route: any, token: any) =>
  customAxios.get(route).catch((ex) => ex);

  const fetchFeedbackById = (route: any, token: any) =>
    customAxios.get(route).catch((ex) => ex);
  
  const fetchFeedbackByBookingId = (route: any, token: any) =>
    customAxios.get(route).catch((ex) => ex);



//static token passed
const fetchLocations = (route: any) => customAxios.get(route).catch((ex) => ex);

export default {
  getFeedbacks,
  getLocationByCity,
  getFeedbackService,
  getFeedbackById,
  getFeedbackByBookingId,
  submitExecutiveFeedBack
};
