import { Box, Modal, Typography } from "@mui/material";
import CustomAccordion from "../../../../../../sharedComponents/molecules/Accordion/customAccordian";
import en from "../../../../../locale/rental-en.json";
import {
  GenericObject,
  typographyConstants,
} from "../../../../../constants/constants";
import { useRef, useState } from "react";
import {
  ImagePreviewNotAvailable,
  LeftScrollIcon,
  RightScrollIcon,
} from "../../../../../constants/exportImages";
import Styles from "./section.module.css";
import { colors } from "../../../../../../themes/colors";

// style for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2rem",
};

export const VehicleImages = (props: any) => {
  const { VehicleManagement } = en;
  const {
    onclick,
    expand,
    imagesData,
    loading,
    imagePreviewData,
    closeModal,
    openModal,
  } = props;

  // refs
  const containerRef = useRef<any>(null);

  // scroll Home delivery Cards from icons
  const scrollHomeDeliveryCards = (value: any) => {
    containerRef.current.scrollBy({ left: value, behavior: "smooth" });
  };

  // image preview modal
  const imagePreviewModal = () => {
    return (
      <Modal
        style={{
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={imagePreviewData.openPreview}
        onClose={() => closeModal()}
        closeAfterTransition
      >
        <Box>
          <Box sx={{ ...style }}>
            <img
              style={{ height: "450px", width: "450px", borderRadius: "2rem" }}
              src={imagePreviewData.imageUrl}
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <CustomAccordion
      expand={expand}
      renderAccordianTab={
        <Typography variant={typographyConstants.HEADING}>
          {VehicleManagement.latestVehicleImages}
        </Typography>
      }
      renderAccordionBody={
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginBottom: "10px",
                cursor: "pointer",
                marginRight: "12px",
              }}
            >
              {imagesData.length > 9 ? (
                <>
                  <img
                    src={LeftScrollIcon}
                    onClick={() => scrollHomeDeliveryCards(-200)}
                  />
                  <img
                    src={RightScrollIcon}
                    onClick={() => scrollHomeDeliveryCards(200)}
                  />
                </>
              ) : null}
            </Box>
            <Box className={Styles.CardListContainer} ref={containerRef}>
              {imagesData?.length ? (
                <>
                  {" "}
                  {imagesData?.map((imageData: any) => (
                    <Box
                      className={Styles.imageContainer}
                      onClick={() => {
                        openModal(imageData?.signedUrl, imageData.fileName);
                      }}
                    >
                      <img
                        className={Styles.vehicleImage}
                        src={imageData?.signedUrl}
                        onError={(current: any) => {
                          current.onerror = null;
                          current.src = ImagePreviewNotAvailable;
                        }}
                      />
                      <Box className={Styles.overlay}>
                        <Typography
                          variant={typographyConstants.BODY}
                          color={colors.white}
                        >
                          {VehicleManagement.preview}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    sx={{
                      color: colors.text_black,
                      fontWeight: 500,
                    }}
                  >
                    {VehicleManagement.noVehicleImagesFound}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {imagePreviewData.openPreview && imagePreviewModal()}
        </Box>
      }
      onClick={onclick}
      customBodyStyle={{ padding: "12px" }}
    />
  );
};
