import React from "react";

export const MaleTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3105_9731)">
        <g clip-path="url(#clip1_3105_9731)">
          <g filter="url(#filter0_d_3105_9731)">
            <circle
              cx="8.27883"
              cy="9.56814"
              r="4.80422"
              stroke="currentColor"
              shape-rendering="crispEdges"
            />
          </g>
          <path
            d="M14.1473 2.8512L11.4105 5.58803M14.1473 2.8512L11.4105 2.7328M14.1473 2.8512L14.3405 5.58803"
            stroke="currentColor"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3105_9731"
          x="-1.02539"
          y="4.26392"
          width="18.6084"
          height="18.6084"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3105_9731"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3105_9731"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3105_9731">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.666992)"
          />
        </clipPath>
        <clipPath id="clip1_3105_9731">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FemaleTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3105_9738)">
        <g clip-path="url(#clip1_3105_9738)">
          <g filter="url(#filter0_d_3105_9738)">
            <circle
              cx="8.50026"
              cy="6.30763"
              r="4.27858"
              stroke="currentColor"
              shape-rendering="crispEdges"
            />
          </g>
          <path
            d="M8.55652 10.8303V14.4712M6.56543 12.5939H10.4339"
            stroke="currentColor"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3105_9738"
          x="-0.27832"
          y="1.52905"
          width="17.5576"
          height="17.5571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3105_9738"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3105_9738"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3105_9738">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_3105_9738">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OthersTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3105_9745)">
        <g clip-path="url(#clip1_3105_9745)">
          <g filter="url(#filter0_d_3105_9745)">
            <circle
              cx="8.33388"
              cy="5.06826"
              r="3.25966"
              stroke="currentColor"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_d_3105_9745)">
            <circle
              cx="8.33434"
              cy="12.7694"
              r="1.48766"
              stroke="currentColor"
              shape-rendering="crispEdges"
            />
          </g>
          <path
            d="M8.33398 8.78125V11.0252"
            stroke="currentColor"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3105_9745"
          x="0.574219"
          y="1.30859"
          width="15.5195"
          height="15.5193"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3105_9745"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3105_9745"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3105_9745"
          x="2.34668"
          y="10.7817"
          width="11.9756"
          height="11.9753"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3105_9745"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3105_9745"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3105_9745">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
        <clipPath id="clip1_3105_9745">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
