import { Box, Card, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import en from "../../../../locale/en.json";
import { colors } from "../../../../themes/colors";
import { typographyConstants } from "../../../constants/constants";
import {
  Home,
  OfficeIcon,
  OtherIcon,
  addressTickIcon,
} from "../../../constants/exportImages";
import styles from "./section.module.css";

const HomeDeliveryAddressCard = ({
  selectedAddress,
  address,
  onClick,
}: any) => {
  // Adress Icon
  const getAddressIcon = (type: any, color: any) => {
    switch (type) {
      case "HOME":
        return <Home color={color} />;
      case "OFFICE":
        return <OfficeIcon color={color} />;
      default:
        return <OtherIcon color={color} />;
    }
  };
  const iconColor = selectedAddress ? colors.white : colors.text_secondary_gray;

  return (
    <Fade>
      <Card
        className={`${styles.AddressCard} ${
          selectedAddress ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <Stack gap="8px" padding="8px 12px 12px 12px">
          <Box sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
            {getAddressIcon(address?.type, iconColor)}
            {address?.default && (
              <Box className={styles.defaultIcon}>
                <Typography
                  variant={typographyConstants.SMALL}
                  color={colors?.THEME_BLUE}
                  fontWeight={"600"}
                >
                  {en.NewManualBooking.Default}
                </Typography>
              </Box>
            )}
          </Box>
          <Tooltip title={address?.name}>
            <Typography
              variant={typographyConstants.SUBHEADING}
              fontWeight="400"
              sx={{
                maxWidth: "100%",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {address?.name}
            </Typography>
          </Tooltip>
          <Box>
            <Tooltip
              title={
                <>
                  {address?.addressLine1} {address?.addressLine1 ? "," : ""}{" "}
                  {address?.addressLine2}
                </>
              }
            >
              <Typography
                variant={typographyConstants.BODY}
                color={
                  selectedAddress ? colors.white_99 : colors.tertiary_black
                }
                sx={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {address?.addressLine1} {address?.addressLine1 ? "," : ""}{" "}
                {address?.addressLine2}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                <>
                  {" "}
                  {address?.landmark} {address?.landmark ? "," : ""}{" "}
                  {address?.pinCode}
                </>
              }
            >
              <Typography
                variant={typographyConstants.BODY}
                color={
                  selectedAddress ? colors.white_99 : colors.tertiary_black
                }
                sx={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {address?.landmark} {address?.landmark ? "," : ""}{" "}
                {address?.pinCode}
              </Typography>
            </Tooltip>
          </Box>
        </Stack>
        {selectedAddress && (
          <img
            src={addressTickIcon}
            alt="tick"
            style={{ position: "absolute", top: 0, right: "-1px" }}
          />
        )}
      </Card>
    </Fade>
  );
};

export default React.memo(HomeDeliveryAddressCard);
