import * as actionTypes from "../actionTypes";
//Get all Cities
export const getCityList = (payload?: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_REQUESTED,
  payload,
});

//Save New City
export const saveNewCity = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_REQUESTED,
  payload,
});

//update existing city
export const updateCity = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_REQUESTED,
  payload,
});

//Get City Details
export const getCityOverview = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_REQUESTED,
});

//Get Warehouse Details
export const getWarehouseOverview = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
    .GET_WAREHOUSE_OVERVIEW_REQUESTED,
});

//get signed url for city image
export const getSignedUrl = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_REQUESTED,
  payload,
});

//create warehouse
export const createWarehouse = (payload: any) => {
  return {
    type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_REQUESTED,
    payload,
  };
};

//View Warehouse
export const viewWarehouse = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_REQUESTED,
  payload,
});

//get warehouse list
export const getWarehouseList = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_REQUESTED,
  payload,
});

//update warehouse
export const updateWarehouse = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_REQUESTED,
  payload,
});

//CITY DETAILS
export const getCityDetails = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_REQUESTED,
  payload,
});

//clear city details
export const clearCityDetails = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CLEAR_CITY_DETAILS,
});

export const addPolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_REQUESTED,
  payload,
});

export const editPolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_REQUESTED,
  payload,
});

export const deletePolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
    .RENTALS_DELETE_POLYGON_REQUESTED,
  payload,
});

export const getPolygons = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_REQUESTED,
  payload,
});

export const addLocalPolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_LOCAL_POLYGON,
  payload,
});

export const editLocalPolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_LOCAL_POLYGON,
  payload,
});

export const deleteLocalPolygon = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_DELETE_LOCAL_POLYGON,
  payload,
});

export const clearStateByCities = () => ({
  type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_STATE_BASED_CITIES,
});

// Add New User - Role Management
export const addNewRoleUser = (
  payload: any,
  apiSuccessCallback?: Function
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_REQUESTED,
  payload,
  apiSuccessCallback,
});

// Get Roles List - Role Management
export const getRolesList = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_REQUESTED,
});

// handleChange - Role Management
export const handleChangeAction = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_REQUESTED,
  payload,
});

// Add New Role Row - Role Management
export const addNewRoleRowAction = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_ROW_REQUESTED,
});

// Delete Role Row - Role Management
export const deleteRoleRowAction = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_ROLE_ROW_REQUESTED,
  payload,
});

// Save Role Data - Role Management
export const saveRoleDataAction = (
  payload: any,
  id: string,
  apiSuccessCallback?: any
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_REQUESTED,
  payload,
  id,
  apiSuccessCallback,
});

// Fetch Role List - Role Management
export const fetchAllUsersListAction = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_REQUESTED,
  payload,
});

//get helmet configuration list
export const getHelmetConfigList = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
    .GET_HELMET_CONFIG_LIST_REQUESTED,
  payload,
});

//add helmet configuration
export const addHelmetConfig = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_REQUESTED,
  payload,
});

//update helmet configuration
export const updateHelmetConfig = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_REQUESTED,
  payload,
});

//get helmet configuration
export const getHelmetConfig = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_REQUESTED,
  payload,
});

//edit current helmet config
export const editCurrentHelmetConfig = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.EDIT_CURRENT_HELMET_CONFIG,
  payload,
});

// Fetch User Details by UserId - Role Management
export const fetchUserDetailsByUserIdAction = (id: string) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS
    .FETCH_USER_DETAILS_BY_USER_ID_REQUESTED,
  id,
});

// Block User - Role Management
export const blockUserAction = (
  payload: any,
  id: string,
  apiSuccessCallback?: Function
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_REQUESTED,
  payload,
  id,
  apiSuccessCallback,
});

// Unblock User - Role Management
export const unblockUserAction = (
  payload: any,
  id: string,
  apiSuccessCallback?: Function
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_REQUESTED,
  payload,
  id,
  apiSuccessCallback,
});

// Delete User - Role Management
export const deleteUserAction = (
  payload: any,
  id: string,
  apiSuccessCallback?: Function
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_REQUESTED,
  payload,
  id,
  apiSuccessCallback,
});

// Update User - Role Management
export const updateRoleUser = (
  payload: any,
  id: string | undefined,
  apiSuccessCallback?: Function
) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_REQUESTED,
  payload,
  id,
  apiSuccessCallback,
});

// Update User - Role Management
export const clearRoleRelatedData = (payload: any) => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CLEAR_DATA_REQUESTED,
  payload,
});

// Get Block Reasons - Role Management
export const getBlockedReasons = () => ({
  type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_REQUESTED,
});
