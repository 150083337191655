import { customAxios, BASE_URL, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

// if user doesnot exists the register the user
const registerCustomer = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().registerUser}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// verify OTP
const verifyCustomerOTP = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().verifyOTP}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Resend OTP
const resendCustomerOTP = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().resendOTP}/${payload?.token}`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Search whether the user existed or not
const searchCustomerService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().searchCustomer}${
      payload?.mobile_number
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
}; 
 
// get booking stats on customerScreen
const getCustomerBookingStats = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getCustomerBookingStats}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Search whether the user existed or not
const getProfileDataService = (payload: any) => {
  try {
    // {{base_url}}/freedo-grahak/profile/v1/1GUIQJAV0EF4?ipi=true
    let url = `${BASE_URL}${getApiUrl().getCustomerProfileDataURL}${
      payload?.id
    }?ipi=true`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get dashboard stats
const getCustomerDashBoardBookingStats = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getCustomerDashBoardStats}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Search whether the user existed or not
const getCustomerEmergencyContactService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getCustomerEmergencyContactURL}${
      payload?.id
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// execute get customer KYC trust score
const getCustomerKycTrustScoresService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getCustomerKycTrustScore}${
      payload?.id
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// add emergency service
const addEmergencyContactService = (payload: any) => {
  try {
    let customerId = payload.customerId;
    let emergencyPayload = {
      data: payload.data,
    };
    let url = `${BASE_URL}${getApiUrl().addEmergencyContactURL}${customerId}`;
    return customAxios.post(url, emergencyPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// add emergency service
const updateEmergencyContactService = (payload: any) => {
  try {
    let emergencyId = payload.emergencyId;
    let customerId = payload.customerId;
    let emergencyPayload = {
      data: payload.data,
    };
    let url = `${BASE_URL}${
      getApiUrl().updateEmergencyContactURL
    }${customerId}/${emergencyId}`;
    return customAxios.put(url, emergencyPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// block/unblock customer service
const blockUnblockCustomerService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).blockUnblockCustomerURL}/${
      payload.type
    }`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// delete customer service
const deleteCustomerService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.id).deleteCustomerURL}`;
    return customAxios.delete(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// execute get customer KYC trust score
const sentEmergencyOtpService = (payload: any) => {
  try {
    let customerId = payload.customerId;
    let emergencyCustomerId = payload.id;
    let url = `${BASE_URL}${
      getApiUrl().sendEmergencyVerificationOtpURL
    }${customerId}/${emergencyCustomerId}/otp`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get customer booking list
const getBookingList = (payload: any) => {
  try {
    // let url = "";
    // url = url + `${BASE_URL}${getApiUrl().getCustomerBookingList}`;
    // url += payload?.page ? `page=${payload?.page}` : "";
    // url += payload?.pageSize ? `&pageSize=${payload?.pageSize}` : "";
    // url += payload?.search ? `search=${payload?.search}` : "";
    // if (payload?.id) url = url + `&customerId=${payload?.id}`;
    // if (payload?.status) url = url + `&status=${payload?.status}`;
    // if (payload?.vehicleId) url = url + `&vehicleId=${payload?.vehicleId}`;

    const url: string = `${BASE_URL}${getApiUrl().getCustomerBookingList}`;
    const apiUrlWithParams: string = constructUrl(url, payload);

    return customAxios.get(apiUrlWithParams).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get customer booking list for download
const getBookingListForDownload = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getCustomerBookingList}`;
    const apiUrlWithParams: string = constructUrl(url, payload);

    return customAxios.get(apiUrlWithParams).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
// verify Emergency OTP
const verifyEmergencyOTPService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().verifyEmergencyOtpURL}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Resend Emergency contact OTP
const resendEmergencyContactOtpService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload?.token).resendEmergencyOtpURL}`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Resend Emergency contact OTP
const getIndividualKycDetailService = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl(payload?.kycId).getIndividualKycDetailURL
    }${payload?.id}/${payload?.kycId}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// verify customer kyc manually
const verifyCustomerKycManualService = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl(payload?.customerId).putManualKycVerificationURL
    }/${payload?.kycId}`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// delete customer kyc manually
const deleteCustomerKycService = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl(payload?.customerId).deleteCustomerKycURL
    }/${payload?.kycId}`;
    return customAxios.delete(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// unverify customer kyc manually
const unverifyCustomerKycService = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl(payload?.customerId).unverifyCustomerKycURL
    }/${payload?.kycId}`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  registerCustomer,
  verifyCustomerOTP,
  resendCustomerOTP,
  searchCustomerService,
  getProfileDataService,
  getCustomerEmergencyContactService,
  getCustomerKycTrustScoresService,
  addEmergencyContactService,
  updateEmergencyContactService,
  blockUnblockCustomerService,
  deleteCustomerService,
  getCustomerDashBoardBookingStats,
  getCustomerBookingStats,
  sentEmergencyOtpService,
  verifyEmergencyOTPService,
  resendEmergencyContactOtpService,
  getBookingList,
  getIndividualKycDetailService,
  verifyCustomerKycManualService,
  deleteCustomerKycService,
  unverifyCustomerKycService,
  getBookingListForDownload,
};
