import * as actionTypes from "../actionTypes";

const {
  GET_PAYMENT_LIST_REQUESTED,
  GET_PAYMENT_LIST_USING_PHONE_NUMBER_REQUESTED,
  GET_REFUND_DETAILS_REQUESTED,
} = actionTypes.PAYMENT_MANAGEMENT_ACTIONS;

// action to fetch payment list
export const getPaymentList = (payload: any, key?: string) => {
  return {
    type: GET_PAYMENT_LIST_REQUESTED,
    payload,
    key,
  };
};

// action to fetch payment list using phone number
export const getPaymentListUsingPhoneNumber = (
  payload: any,
  key?: string | undefined
) => {
  return {
    type: GET_PAYMENT_LIST_USING_PHONE_NUMBER_REQUESTED,
    payload,
    key,
  };
};

//action to fetch refund details
export const getrefundDetails = (payload: any) => {
  return {
    type: GET_REFUND_DETAILS_REQUESTED,
    payload,
  };
};
