import axios from "axios";
import {
  FREEDO_BIZ_X_URL,
  customAxios,
  getApiUrl,
  API_URLS,
  BASE_URL,
} from "../../../config";
import { constructUrl } from "../../utils/helper";

const getCityOverview = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getCityStatistics;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

const getWarehouseOverview = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getWarehouseStatistics;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};
const getCityList = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getCityList;
    const apiUrlWithParams: any = constructUrl(url, payload);

    return customAxios.get(apiUrlWithParams);
  } catch (ex) {
    return ex;
  }
};
const saveNewCity = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getAddUpdateCity;
    return customAxios.post(url, payload?.data);
  } catch (ex) {
    return ex;
  }
};
const updateCity = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL + `${getApiUrl().getAddUpdateCity}${payload.name}`;
    return customAxios.put(url, payload?.data?.data);
  } catch (ex) {
    return ex;
  }
};

const generateSignedUrl = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getSignedUrl;
    return customAxios.post(url, payload.data);
  } catch (ex) {
    return ex;
  }
};

const uploadImgOnSthree = (payload: any) => {
  const formData = new FormData();
  formData.append("", payload.img);
  const data = new Blob([payload.img], {
    type: payload.img.type,
  });
  try {
    let url = payload.url;
    //return axios.put(url,formData)
    return axios.put(url, data, {
      headers: {
        "Content-Type": payload.img.type,
      },
    });
  } catch (ex) {
    return ex;
  }
};

const addPolygon = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + API_URLS.addDeliveryBranch;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const editPolygon = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL +
      API_URLS.deliveryBranch +
      `/${payload?.apiParams?.geofencingId}`;
    return customAxios.put(url, payload?.apiBody).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const updateWarehouse = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL +
      `${getApiUrl().viewAddUpdateWarehouse}${payload.branchName}`;
    return customAxios.put(url, payload?.data);
  } catch (ex) {
    return ex;
  }
};

const getCityDetails = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL + `${getApiUrl().getAddUpdateCity}${payload.cityName}`;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

const deletePolygon = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL + API_URLS.deliveryBranch + `/${payload?.geofencingId}`;
    return customAxios.delete(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getPolygon = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + API_URLS.deliveryBranch + `/${payload}`; //-----> branch id
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getWarehouseList = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().getWarehouseList;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (ex) {
    return ex;
  }
};

const createNewWarehouse = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + getApiUrl().viewAddUpdateWarehouse;
    return customAxios.post(url, payload);
  } catch (ex) {
    return ex;
  }
};

const viewWarehouseDetails = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL + getApiUrl().viewAddUpdateWarehouse + payload.branch;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

// Add New User - Role Management
const addNewUser = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().addNewUser}`;
    return customAxios.post(url, payload);
  } catch (e) {
    return e;
  }
};

// Get Roles List - Role Management
const getRolesList = () => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getRolesList}`;
    return customAxios.get(url);
  } catch (e) {
    return e;
  }
};

// Save Role - Role Management
const saveRole = (payload: any, id: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(id).saveRole}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (e) {
    return e;
  }
};

// Get All Users List - Role Management
const getAllUsersList = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getAllUsersList}`;
    const urlWithParams: string = constructUrl(url, payload);
    return customAxios.get(urlWithParams);
  } catch (e) {
    return e;
  }
};

// Fetch User Details by UserId - Role Management
const getUserDetailsByUserId = (id: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(id).getUserDetailsByUserId}`;
    return customAxios.get(url);
  } catch (e) {
    return e;
  }
};
const getHelmetConfigList = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().getHelmetConfigList;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (ex) {
    return ex;
  }
};

const addHelmetConfig = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().addHelmetConfig;
    return customAxios.post(url, payload);
  } catch (ex) {
    return ex;
  }
};

const getHelmetConfig = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl(payload?.id).getUpdateHelmetConfig;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

const updateHelmetConfig = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl(payload.id).getUpdateHelmetConfig;
    return customAxios.put(url, payload?.data);
  } catch (ex) {
    return ex;
  }
};

// Block User - Role Management
const blockUser = (payload: any, id: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(id).blockUser;
    return customAxios.post(url, payload);
  } catch (e) {
    return e;
  }
};

// Unblock User - Role Management
const unblockUser = (payload: any, id: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(id).unblockUser;
    return customAxios.post(url, payload);
  } catch (e) {
    return e;
  }
};

// Delete User - Role Management
const deleteUser = (payload: any, id: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(id).deleteUser;
    return customAxios.delete(url, payload);
  } catch (e) {
    return e;
  }
};

// Update User - Role Management
const updateUser = (payload: any, id: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(id).updateUser}`;
    return customAxios.put(url, payload);
  } catch (e) {
    return e;
  }
};

// Get Block Reasons - Role Management
const getBlockReasons = () => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getBlockReasons}`;
    return customAxios.get(url);
  } catch (e) {
    return e;
  }
};

export default {
  getCityOverview,
  getWarehouseOverview,
  getCityList,
  saveNewCity,
  updateCity,
  getCityDetails,
  generateSignedUrl,
  uploadImgOnSthree,
  createNewWarehouse,
  getWarehouseList,
  updateWarehouse,
  viewWarehouseDetails,
  addPolygon,
  editPolygon,
  getPolygon,
  deletePolygon,
  addNewUser,
  getRolesList,
  saveRole,
  getAllUsersList,
  getHelmetConfigList,
  addHelmetConfig,
  getHelmetConfig,
  updateHelmetConfig,
  getUserDetailsByUserId,
  blockUser,
  unblockUser,
  deleteUser,
  updateUser,
  getBlockReasons,
};
