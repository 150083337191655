import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import {
  encrypt,
  encryptingText,
  matchSamePassword,
  validatePasswordPolicies,
} from "../../utils/helper";
import { AuthButton } from "./components/authButton";
import { AuthContainer } from "./components/authContainer";
import { ContainerMsg } from "./components/authContainerMsg";
import { AuthInputPassword } from "./components/authInputs";
import { ProgressBar } from "./components/authProgressBar";
import { PolicyBox } from "./components/policyBox";
interface IObjectKeys {
  [key: string]: string;
}

interface Itag extends IObjectKeys {
  tag: string;
  value: string;
}

interface Ifields {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
function ChangePassword() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { changePasswordLoader, isPasswordChanged } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fields, setFields] = useState<Ifields>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errFields, setErrFields] = useState<Ifields>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const [passComplexity, setPassComplexity] = useState<string>();

  // sate to store policy checks
  const [policyChecks, setPolicychecks] = useState({
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasRepeatedSequence: false,
    status: false,
  });

  // handle fields change
  const handleChange = (props: Itag): void => {
    if (props?.tag === "newPassword") {
      setFields({ ...fields, newPassword: props?.value });
      const passMatch = matchSamePassword(
        props?.value,
        fields?.confirmNewPassword
      );

      !passMatch?.status && fields?.confirmNewPassword != ""
        ? setErrFields({
            ...errFields,
            confirmNewPassword: locale.newAuth?.confirmPasswordErr,
          })
        : setErrFields({ ...errFields, confirmNewPassword: "" });

      const checkPolicy = validatePasswordPolicies(props?.value);
      checkPolicy?.status
        ? setPassComplexity(checkPolicy?.passwordComplexity)
        : setPassComplexity("");

      setPolicychecks({
        ...policyChecks,
        hasLowercase: checkPolicy?.policyChecks?.hasLowercase,
        hasUppercase: checkPolicy?.policyChecks?.hasUppercase,
        hasMinLength: checkPolicy?.policyChecks?.hasMinLength,
        hasNumber: checkPolicy?.policyChecks?.hasNumber,
        hasRepeatedSequence: checkPolicy?.policyChecks?.hasRepeatedSequence,
        hasSpecialCharacter: checkPolicy?.policyChecks?.hasSpecialCharacter,
        status: checkPolicy?.status,
      });
    } else if (props?.tag === "confirmNewPassword") {
      setFields({ ...fields, confirmNewPassword: props?.value });
      const passMatch = matchSamePassword(fields?.newPassword, props?.value);

      if (!passMatch.status) {
        setErrFields({
          ...errFields,
          confirmNewPassword: locale.newAuth?.confirmPasswordErr,
        });
      } else {
        setErrFields({ ...errFields, confirmNewPassword: "" });
      }
    } else {
      setFields({ ...fields, currentPassword: props?.value });
    }
  };

  // on click of cross icon of policy box
  const onClickCross = () => {
    setPolicychecks((prev: any) => ({ ...prev, status: true }));
  };

  // submit password changed
  const onSubmit = () => {
    dispatch(
      actions.changePassword({
        data: {
          currentPassword: encryptingText(fields.currentPassword),
          newPassword: encryptingText(fields.newPassword),
        },
      })
    );
  };

  // side efffect to navigate to previous tab after successfully changed password
  useEffect(() => {
    if (isPasswordChanged) {
      navigate(-1);
      dispatch(actions.isPasswordChanged());
    }
  }, [isPasswordChanged]);

  return (
    <>
      <div className="change-password">
        <AuthContainer
          heading={locale?.newAuth?.changePassword}
          componentFields={
            <>
              {policyChecks?.hasRepeatedSequence ? (
                <ContainerMsg message={locale?.newAuth?.noSequentialWords} />
              ) : (
                ""
              )}
              <AuthInputPassword
                value={fields?.currentPassword}
                label={locale?.newAuth?.currentPassword}
                placeholder={locale?.newAuth?.enterCurrentPassword}
                inputClassName={"form-control pw-input"}
                viewPassEye={"true"}
                eyeClassName={`fa fa-eye${showPassword ? "" : "-slash"}`}
                type={showPassword ? "text" : "password"}
                ariaHidden={!showPassword ? "true" : "false"}
                errorMessage={errFields?.currentPassword}
                onChange={(e: any) => {
                  handleChange({
                    tag: "currentPassword",
                    value: e?.target?.value,
                  });
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              />
              <AuthInputPassword
                value={fields?.newPassword}
                label={locale?.newAuth?.newPassword}
                placeholder={locale?.newAuth?.enterNewPassword}
                inputClassName={"form-control pw-input"}
                viewPassEye={"true"}
                eyeClassName={`fa fa-eye${showNewPassword ? "" : "-slash"}`}
                type={showNewPassword ? "text" : "password"}
                ariaHidden={!showPassword ? "true" : "false"}
                errorMessage={
                  /* policyChecks?.hasRepeatedSequence
                  ? locale?.newAuth?.noSequentialWords
                  : */ ""
                }
                onChange={(e: any) => {
                  handleChange({ tag: "newPassword", value: e?.target?.value });
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowNewPassword(!showNewPassword);
                }}
              />

              <ProgressBar passComplexity={passComplexity} />

              <AuthInputPassword
                type={"password"}
                value={fields?.confirmNewPassword}
                label={locale.newAuth?.confirmPassword}
                placeholder={locale.newAuth?.enterConfirmNewPassword}
                inputClassName={`form-control ${
                  !errFields?.confirmNewPassword ? "" : "error-input"
                }`}
                viewPassEye={"false"}
                errorMessage={errFields?.confirmNewPassword}
                onChange={(e: any) => {
                  handleChange({
                    tag: "confirmNewPassword",
                    value: e.target.value,
                  });
                }}
              />
              <AuthButton
                type="submit"
                title={locale.global?.Submit}
                loading={changePasswordLoader}
                /* className={`btn w-100 login-btn mt-3 ${
                  !errFields.confirmNewPassword &&
                  passComplexity != "" &&
                  fields.confirmNewPassword != "" &&
                  fields.currentPassword
                    ? ""
                    : "disable"
                }`} */
                disabled={
                  !fields.currentPassword ||
                  !fields.newPassword ||
                  !fields.confirmNewPassword ||
                  errFields.confirmNewPassword ||
                  !passComplexity
                }
                onClick={onSubmit}
              />
              {/* {showMessage ? (
                <MessagePopUp
                  className={success && !error ? "pw-sucess" : "pw-error"}
                  message={message}
                  onClick={() => {
                    dispatch(actions.showMessagePopUp({ showMessage: false }));
                  }}
                />
              ) : null} */}
            </>
          }
          policyBox={
            !policyChecks?.status ? (
              <PolicyBox
                policyChecks={policyChecks}
                onClickCross={onClickCross}
              />
            ) : (
              ""
            )
          }
        />
      </div>
    </>
  );
}

export default ChangePassword;
