import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  modules,
  roleManagementFunctionality,
} from "../../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import HeadingValueTypography from "../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import { colors } from "../../../../../themes/colors";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  statusColorCode,
  typographyConstants,
  UserStatus,
} from "../../../../constants/constants";
import { EditIconBlack } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { fetchUserDetailsByUserIdAction } from "../../../../redux/actions/businessManagementActions";
import { formatString, getNameInitials } from "../../../../utils/helper";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { StyleObject } from "./StyleObject";

const { isAccessRightsProvided } = RbackHelper;
const { global, roleManagement, buttonLabels } = rentalEn;

const ViewRoleUserDetails = (props: any) => {
  const { id: userIdParams } = useParams(); // Access the data passed in the route using the 'useParams' hook
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RBAC implementation
  const RbacHasAccess = {
    userList: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.ROLE_MANAGEMENT_LIST
    ),
    updateUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.UPDATE_USER
    ),
  };

  const { userDetailsByUserIdData, userDetailsByUserIdDataLoader } =
    useSelector((state: RootState) => state.newBusinessManagementReducer);

  useEffect(() => {
    if (userIdParams) {
      dispatch(fetchUserDetailsByUserIdAction(userIdParams)); // Fetch user details
    }
  }, [userIdParams]);

  return (
    <Box>
      {/* User Details Box */}
      <Stack
        sx={{
          backgroundColor: colors.bg_secondary_blue,
          width: 9 / 10,
          p: 2,
          mx: "auto",
        }}
      >
        {/* User Avatar & Name Section */}
        {RbacHasAccess?.userList && (
          <Stack spacing={1} width={1}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: 1,
              }}
            >
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                <Avatar
                  sx={{
                    bgcolor: colors?.dark_green_bg,
                    color: colors?.white,
                    fontSize: 12,
                    "&.MuiAvatar-root": { height: 30, width: 30 },
                  }}
                >
                  {Object.keys(userDetailsByUserIdData)?.length
                    ? getNameInitials(userDetailsByUserIdData?.name)
                    : global?.NA}
                </Avatar>
                <Typography variant={typographyConstants?.HEADING}>
                  {userDetailsByUserIdData?.name}
                </Typography>
              </Stack>

              {/* Edit User Button */}
              {RbacHasAccess?.updateUser &&
                userDetailsByUserIdData?.status === UserStatus?.ACTIVE && (
                  <CustomButton
                    variant={"text"}
                    label={buttonLabels.editUser}
                    startImageSrc={EditIconBlack}
                    sx={{
                      minWidth: 80,
                      padding: "0px 13px",
                      minHeight: "25px",
                    }}
                    onClick={() =>
                      navigate(`${routesConstants.ADD_ROLE}/${userIdParams}`)
                    }
                  />
                )}
            </Box>

            {/* User Details & Status Section */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: 1,
              }}
            >
              <HeadingValueTypography
                loading={userDetailsByUserIdDataLoader}
                heading={"Mobile Number"}
                value={userDetailsByUserIdData?.phone}
              />
              <HeadingValueTypography
                loading={userDetailsByUserIdDataLoader}
                heading={"Email Address"}
                value={userDetailsByUserIdData?.emailId}
                width={1 / 3}
              />
              <HeadingValueTypography
                loading={userDetailsByUserIdDataLoader}
                heading={"Status"}
                color={
                  statusColorCode[
                    userDetailsByUserIdData?.status as keyof typeof statusColorCode
                  ]
                }
                value={
                  userDetailsByUserIdData?.status
                    ? formatString(userDetailsByUserIdData?.status)
                    : global?.NA
                }
              />
            </Box>
          </Stack>
        )}
      </Stack>

      {/* User's Assigned Roles Section */}
      {RbacHasAccess?.userList && (
        <Stack spacing={2} pt={3} alignItems={"center"}>
          <Box
            sx={{
              flexGrow: 1,
              alignItems: "center",
              width: 9 / 10,
            }}
          >
            <Stack spacing={0.1} direction={"row"} my={1}>
              <Box width={1 / 4} sx={StyleObject?.urs_heading_role}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {roleManagement?.role}
                </Typography>
              </Box>
              <Box width={1 / 4} sx={StyleObject?.urs_heading_state}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.state}
                </Typography>
              </Box>
              <Box width={1 / 4} sx={StyleObject?.urs_heading_city}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.city}
                </Typography>
              </Box>
              <Box width={1 / 4} sx={StyleObject?.urs_heading_hub}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.hub}
                </Typography>
              </Box>
            </Stack>

            {/* *** */}

            {userDetailsByUserIdData?.roles?.length ? (
              userDetailsByUserIdData?.roles?.map((item: any) => {
                return (
                  <Stack spacing={0.1} direction={"row"} alignItems={"center"}>
                    {/* Role */}
                    <Box width={1 / 4} sx={StyleObject?.urs_data_role}>
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors?.black}
                      >
                        <Tooltip title={item?.roleName} arrow>
                          {item?.roleName?.length > 20
                            ? `${item?.roleName?.substring(
                                0,
                                20
                              )}${" "}${"..."}`
                            : item?.roleName}
                        </Tooltip>
                      </Typography>
                    </Box>

                    {/* State */}
                    <Box width={1 / 4} sx={StyleObject?.urs_data_state}>
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors?.black}
                      >
                        <Tooltip title={item?.modifiedStates} arrow>
                          {item?.modifiedStates?.length > 20
                            ? `${item?.modifiedStates?.substring(
                                0,
                                20
                              )}${" "}${"..."}`
                            : item?.modifiedStates}
                        </Tooltip>
                      </Typography>
                    </Box>

                    {/* City */}
                    <Box width={1 / 4} sx={StyleObject?.urs_data_city}>
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors?.black}
                      >
                        <Tooltip title={item?.modifiedCities} arrow>
                          {item?.modifiedCities?.length > 20
                            ? `${item?.modifiedCities?.substring(
                                0,
                                20
                              )}${" "}${"..."}`
                            : item?.modifiedCities}
                        </Tooltip>
                      </Typography>
                    </Box>

                    {/* Hub */}
                    <Box width={1 / 4} sx={StyleObject?.urs_data_hub}>
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors?.black}
                      >
                        <Tooltip title={item?.modifiedBranches} arrow>
                          {item?.modifiedBranches?.length > 20
                            ? `${item?.modifiedBranches?.substring(
                                0,
                                20
                              )}${" "}${"..."}`
                            : item?.modifiedBranches}
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Stack>
                );
              })
            ) : (
              <Box width={1} sx={StyleObject?.urs_noDataAvailable}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.black }}
                >
                  {roleManagement?.noRoleDataFound}
                </Typography>
              </Box>
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default ViewRoleUserDetails;
