import { Box, Checkbox, Radio, Stack, Typography } from "@mui/material";
import { isArray } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { storeFilterDataInLocalStorage } from "../../../../config/filterStorage";
import {
  modules,
  planFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { LocalStorage } from "../../../../constants/enum";
import { RootState } from "../../../../redux/reducers";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import VehicleDetailCard from "../../../../sharedComponents/molecules/vehicleDetailCard";
import ListTable from "../../../../sharedComponents/templates/Tables/MUITable";
import { isArrayNotEmpty } from "../../../../utils/helper";
import RbacHelper from "../../../../utils/helperRBAC";
import { removeLeadingZero } from "../../../../utils/regex";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import {
  HELMET_ICONS,
  HELMETS_DISPLAY_NAME,
  LOB_TYPES,
  typographyConstants,
} from "../../../constants/constants";
import { RupeeInputIcon } from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import { planManagementActions, rentalActions } from "../../../redux/actions";
import { clearRentalPlanErrorData } from "../../../redux/actions/planmanagement";
import {
  clearBranchDefaultConfig,
  clearBreadcrumbs,
  getBranchDefaultConfig,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../utils/RoutesConstants";
import { isNumericString } from "../../../utils/helper";
import { StyleObject } from "./StyleObjext";
import PlanMinimumPriceErrorModal from "./sections/PlanMinimumPriceErrorModal";

const AddEditRentalPlan = () => {
  // // rbac implementation
  const RbacHasAccess = {
    editPlans: RbacHelper.isAccessRightsProvided(
      modules.PLAN_MANAGEMENT,
      planFunctionalities.EDIT_PLAN
    ),
  };
  const {
    rentalPlanDetails,
    planDetailErrors,
    onLoad,
    addEditRentalPlanLoader,
  } = useSelector((state: RootState) => state.planManagementReducer);
  const { branchDefaultConfig } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { operatorConfigData }: any = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { operatorConfig = {} } = rentalPlanDetails;

  const { state } = useLocation() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: planId } = useParams();
  const cellStyle = StyleObject.cellStyle;
  const { isAlreadyExist = false } = state || {};

  useEffect(() => {
    if (
      !Object.keys(branchDefaultConfig)?.length &&
      Object.keys(rentalPlanDetails)?.length
    ) {
      dispatch(getBranchDefaultConfig(rentalPlanDetails?.branch));
      //GET BRANCHN CONFIG API
      const payload = {
        branch: rentalPlanDetails?.branch,
        data: {
          lob: LOB_TYPES.LTR,
        },
      };

      dispatch(rentalActions.getOperatorConfigByBranch(payload));
    }
  }, [rentalPlanDetails]);

  // fetch plan details if :id is there in route
  useEffect(() => {
    if (planId) {
      dispatch(planManagementActions.getRentalPlanById({ planId }));
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.PLAN_DETAIL_EDIT));
    } else if (isAlreadyExist) {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.PLAN_DETAIL_EDIT));
    } else {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.PLAN_DETAIL_ADD));
    }

    // navigate to add plan screen if data is not available
    if (!rentalPlanDetails.packages && !planId) {
      navigate(routesConstants.ADD_PLAN_MANAGEMENT);
    }
    return () => {
      dispatch(clearBreadcrumbs());
      dispatch(clearBranchDefaultConfig());
    };
  }, [planId]);

  const renderCellInput = ({ value, row, accessorKey }: any) => {
    return (
      <Input
        value={value ?? ""}
        width="90px"
        customStyle={StyleObject.customInputStyle}
        inputContainerStyle={StyleObject.inputContainerStyle}
        onChange={(e: any) => {
          e.preventDefault();
          let value = removeLeadingZero(e.target.value);

          if (value.length <= 7 && isNumericString(value)) {
            let updatedPackages = updatePackages(value ?? "", accessorKey, row);
            handleChange("packages", updatedPackages);
          }
        }}
        inputProps={{ readOnly: !RbacHasAccess.editPlans }}
        disabled={
          accessorKey === "security"
            ? !operatorConfigData?.securityDepositEnabled
            : false
        }
      />
    );
  };

  const renderCheckBoxInput = ({ value, row, accessorKey }: any) => {
    const isDisabled =
      accessorKey === "supportsPremium"
        ? !operatorConfig?.premiumKmMultiplier
        : !operatorConfig?.unlimitedKmMultiplier;

    return (
      <Checkbox
        checked={value}
        onChange={(e: any) => {
          e.preventDefault();
          let updatedPackages = updatePackages(!value, accessorKey, row);
          handleChange("packages", updatedPackages);
        }}
        sx={StyleObject.checkboxStyle}
        disabled={!RbacHasAccess.editPlans || isDisabled}
      />
    );
  };

  const columns = [
    {
      accessorKey: "duration",
      numeric: false,
      disablePadding: false,
      headerName: rentalEn.tableHeaders.planDuration,
      align: "center",
      cellStyle,
      customJsx: ({ value }: any) => {
        return (
          <div>
            {value} {value === 1 ? "Day" : "Days"}
          </div>
        );
      },
    },
    {
      accessorKey: "price",
      numeric: true,
      disablePadding: false,
      headerName: rentalEn.tableHeaders.price,
      align: "center",
      cellStyle,
      customJsx: renderCellInput,
    },
    {
      accessorKey: "kmLimit",
      numeric: true,
      disablePadding: false,
      headerName: rentalEn.tableHeaders.KmLimit,
      customJsx: renderCellInput,
      align: "center",
      cellStyle,
    },
    {
      accessorKey: "security",
      numeric: true,
      disablePadding: false,
      headerName: rentalEn.tableHeaders.securityCharges,
      cellStyle,
      align: "center",
      customJsx: renderCellInput,
    },
    {
      accessorKey: "supportsPremium",
      numeric: true,
      disablePadding: false,
      headerName: rentalEn.tableHeaders.premiumVariant,
      align: "center",
      cellStyle,
      customJsx: renderCheckBoxInput,
    },
    {
      accessorKey: "supportsUnlimited",
      numeric: true,
      align: "center",
      disablePadding: false,
      headerName: rentalEn.tableHeaders.unlimitedVariant,
      customJsx: renderCheckBoxInput,
      cellStyle,
    },
  ];

  const helmetColumns = [
    {
      accessorKey: "type",
      disablePadding: false,
      align: "center",
      headerName: rentalEn.tableHeaders.helmetTypes,
      cellStyle,
      customJsx: ({ row }: any) => {
        return (
          <div>
            {HELMETS_DISPLAY_NAME[row]}
            <img
              src={HELMET_ICONS[row]}
              style={{ marginLeft: "10px", height: "16px", width: "16px" }}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "price",
      headerName: rentalEn.tableHeaders.complementaryHelment,
      align: "center",
      cellStyle,
      customJsx: ({ row }: any) => {
        return (
          <Radio
            checked={
              isArray(rentalPlanDetails?.benefits) &&
              rentalPlanDetails?.benefits[0]?.data?.type === row
            }
            onChange={(e: any) => {
              e.preventDefault();
              handleComplementaryHelmetRadio(row);
            }}
            value={row}
            sx={{
              display: row === "KIDS" ? "none" : "",
              ...StyleObject.radioInputStyle,
            }}
            inputProps={{ "aria-label": "A" }}
            disabled={!RbacHasAccess.editPlans}
          />
        );
      },
    },
  ];

  // change in complementary helmet information
  const handleComplementaryHelmetRadio = (helmetType: string) => {
    let updatedRentalBenefits = [...rentalPlanDetails?.benefits];
    if (updatedRentalBenefits[0].data) {
      updatedRentalBenefits[0].data.type = helmetType;
    }
    handleChange("benefits", updatedRentalBenefits);
  };

  // change in local data
  const handleChange = (field: string, value: any) => {
    let updatedRentalPlanList = { ...rentalPlanDetails };
    updatedRentalPlanList[field] = value;
    dispatch(planManagementActions.editRentalPlan(updatedRentalPlanList));
  };

  // update package in local data
  const updatePackages = (
    value: string | boolean,
    key: string,
    singlePackage: any
  ) => {
    let updatedPackages = [...rentalPlanDetails?.packages];
    updatedPackages.forEach((item: any) => {
      if (item.duration === singlePackage.duration) {
        item[key] = value;
      }
    });
    return updatedPackages;
  };

  // manipulate payload according to API requirements
  const manipulatePayloadData = (rentalPlanData: any) => {
    let payloadData: any = {};
    payloadData.packages = rentalPlanData.packages.map(
      (packageInstance: any) => {
        const { perHourKms, ...rest } = packageInstance;
        return rest;
      }
    );
    payloadData.benefits = rentalPlanData.benefits;
    payloadData.excessHourCharges = rentalPlanData.excessHourCharges;
    payloadData.excessKmCharges = rentalPlanData.excessKmCharges;
    if (rentalPlanData.isDefault) {
      payloadData.isDefault = !rentalPlanData.isDefault;
      payloadData.model = rentalPlanData.model;
      payloadData.useCase = rentalPlanData.useCase;
      payloadData.branch = state?.selectedBranch
        ? state?.selectedBranch?.name
        : rentalPlanData?.branch;
    } else {
      payloadData.planId = rentalPlanData.planId;
    }
    return payloadData;
  };

  // validation for helmet price and package km limit
  const isValidPrices = (manipulatedData: any) => {
    let isValid: string[] = [];
    manipulatedData.packages.forEach((item: any) => {
      if ((item.kmLimit === "0" || !item.kmLimit) && item.price) {
        if (!isValid.includes(rentalEn.tableHeaders.KmLimits))
          isValid.push(rentalEn.tableHeaders.KmLimits);
      }
    });
    return isValid;
  };

  // create/update plan
  const handleSave = () => {
    const manipulatedData = manipulatePayloadData(rentalPlanDetails);
    const isValidPrice = isValidPrices(manipulatedData);
    if (isArrayNotEmpty(isValidPrice)) {
      const errorMessage = isValidPrice.join(", ");
      toastr.error("", `${errorMessage}${rentalEn.errorMessages.canNotbe}`);
      return;
    }
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      planListFilterData: {},
    });
    // if plan is default, create a new plan, else update the existing one
    if (rentalPlanDetails.isDefault) {
      dispatch(
        planManagementActions.createRentalPlan({
          data: manipulatedData,
          navigate: () => {
            navigate(routesConstants.PLAN_MANAGEMENT);
          },
        })
      );
    } else {
      dispatch(
        planManagementActions.updateRentalPlan({
          data: manipulatedData,
          navigate: () => {
            navigate(routesConstants.PLAN_MANAGEMENT);
          },
        })
      );
    }
  };

  const closeErrorModal = () => {
    dispatch(clearRentalPlanErrorData());
  };

  const swapKidsWithLast = (types: any) => {
    if (!Array.isArray(types) || types.length === 0) {
      return types;
    }
    const kidsIndex = types?.indexOf("KIDS");

    if (kidsIndex !== -1 && kidsIndex !== types?.length - 1) {
      const lastIndex = types?.length - 1;
      [types[kidsIndex], types[lastIndex]] = [
        types[lastIndex],
        types[kidsIndex],
      ];
    }

    return types;
  };

  return (
    <Stack gap="15px">
      <VehicleDetailCard vehicleData={rentalPlanDetails} stateData={state} />

      <ListTable
        columns={columns}
        rows={rentalPlanDetails?.packages || []}
        rowStyle={StyleObject.tableRowStyle}
        containerStyle={StyleObject.tableContainerStyle}
        loading={onLoad}
        hidePagination
        dense
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant={typographyConstants.SUBHEADING}
          style={{ marginRight: "20px", alignSelf: "center" }}
        >
          {rentalEn.planManagement.excessKmCharge} :{" "}
        </Typography>
        <Input
          value={rentalPlanDetails?.excessKmCharges || 0}
          // label={"yoren.manualBookingFreedoX.EnterNumber"}
          onChange={(e: any) => {
            e.preventDefault();
            let value = removeLeadingZero(e.target.value);

            if (value.length <= 7 && isNumericString(value)) {
              handleChange("excessKmCharges", value);
            }
          }}
          iconStart={RupeeInputIcon}
          placeholder={rentalEn.planManagement.excessKmCharge}
          width={200}
          inputProps={{ readOnly: !RbacHasAccess.editPlans }}
          // errormessage={"errorFields.mobileNumber"}
        />
        <Typography
          variant={typographyConstants.SUBHEADING}
          style={{
            marginLeft: "50px",
            marginRight: "20px",
            alignSelf: "center",
          }}
        >
          {rentalEn.planManagement.excessHoursCharge} :
        </Typography>
        <Input
          value={rentalPlanDetails?.excessHourCharges || 0}
          onChange={(e: any) => {
            e.preventDefault();
            const value = removeLeadingZero(e.target.value);

            if (value.length <= 7 && isNumericString(value)) {
              handleChange("excessHourCharges", value);
            }
          }}
          iconStart={RupeeInputIcon}
          width={200}
          placeholder={rentalEn.planManagement.excessHoursCharge}
          inputProps={{ readOnly: !RbacHasAccess.editPlans }}
        />
      </Box>
      {branchDefaultConfig?.helmetTypes &&
      branchDefaultConfig?.helmetTypes?.length ? (
        <>
          <Box>
            <Typography variant={typographyConstants.HEADING}>
              {rentalEn.planManagement.selectComplementaryHelmet} :
            </Typography>
          </Box>
          <ListTable
            columns={helmetColumns}
            rows={swapKidsWithLast(branchDefaultConfig?.helmetTypes)}
            loading={onLoad}
            rowStyle={StyleObject.tableRowStyle}
            containerStyle={StyleObject.tableContainerStyle}
            hidePagination
            dense
          />
        </>
      ) : null}

      {RbacHasAccess.editPlans && (
        <CustomButton
          label={rentalEn.buttonLabels.save}
          variant="contained"
          onClick={handleSave}
          sx={{ minWidth: "200px" }}
          loading={addEditRentalPlanLoader}
        />
      )}

      <PlanMinimumPriceErrorModal
        isOpen={!!planDetailErrors?.errorList?.length}
        handleClose={closeErrorModal}
        errorData={planDetailErrors}
      />
    </Stack>
  );
};

export default AddEditRentalPlan;
