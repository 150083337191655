function AuthButton(props: any) {
  return (
    <>
      <button
        type={props?.type}
        /* className={props?.className} */
        className={`btn w-100 login-btn mt-3 ${
          props.disabled ?? false ? "disable" : ""
        }`}
        onClick={(e) => {
          if (props?.loading) return;
          else if (props?.disabled) return;
          e.preventDefault();
          props?.onClick();
        }}
        disabled={props?.disabled}
      >
        {props?.loading ? (
          <div
            className="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          props?.title
        )}
      </button>
    </>
  );
}

export { AuthButton };
