import { RotateRightSharp } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import {
  modules,
  planFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { AntSwitch } from "../../../../sharedComponents/molecules/MuiSwitch";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../../utils/helper";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  GenericObject,
  STATUS,
  activeInactiveStatusArray,
  filterStorageKeys,
  useCaseArray,
} from "../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  FunctionalitiesIcon,
  StatusIcon,
  WarehouseIcon,
  changeStatusIcon,
} from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import { planManagementActions, rentalActions } from "../../../redux/actions";
import { fromNowDateAndTime } from "../../../utils/helper";
import { routesConstants } from "../../../utils/RoutesConstants";

const initialState = {
  selectedCity: {
    name: "",
    displayName: "",
    city_name: "",
  },
  selectedBranch: {
    name: "",
    displayName: "",
    city_name: "",
  },
  selectedLob: { name: "", displayName: rentalEn?.global?.allUseCases },
  selectedVehicle: { name: "", displayName: rentalEn?.global?.allModels },
  statusUpdate: false,
  paginationModel: { page: 0, pageSize: 10 },
  status: { name: "", displayName: rentalEn?.global?.allStatus },
};

const PlanManagement = () => {
  // // rbac implementation
  const RbacHasAccess = {
    listPlans: RbacHelper.isAccessRightsProvided(
      modules.PLAN_MANAGEMENT,
      planFunctionalities.LIST_PLANS
    ),
    addPlans: RbacHelper.isAccessRightsProvided(
      modules.PLAN_MANAGEMENT,
      planFunctionalities.ADD_PLAN
    ),
    viewPlans: RbacHelper.isAccessRightsProvided(
      modules.PLAN_MANAGEMENT,
      planFunctionalities.VIEW_PLAN
    ),
    editPlans: RbacHelper.isAccessRightsProvided(
      modules.PLAN_MANAGEMENT,
      planFunctionalities.EDIT_PLAN
    ),
  };
  const [fields, setFields] = useState({ ...initialState });
  const { allCitiesDetails, branches, modelListByBranch } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { rentalPlanListData, onLoad } = useSelector(
    (state: RootState) => state.planManagementReducer
  );
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dialogData, setDialogData] = useState({
    openDialog: false,
    status: "",
    planId: "",
    body: "",
  });

  const columns = [
    {
      field: "modelDisplayName",
      headerName: rentalEn.tableHeaders.vehicleModel,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "branchDisplayName",
      headerName: rentalEn.tableHeaders.hub,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "excessHourCharges",
      headerName: rentalEn.tableHeaders.excessHourCharges,
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return `₹ ${params.row.excessHourCharges}`;
      },
    },
    {
      field: "excessKmCharges",
      headerName: rentalEn.tableHeaders.excessKMCharges,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `₹ ${params.row.excessKmCharges}`;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={moment(params?.row?.updatedAt).format("DD/MM/YYYY, hh:mm A")}
          >
            <Typography variant="caption">
              {fromNowDateAndTime(params?.row?.updatedAt)}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: rentalEn.tableHeaders.planStatus,
      headerAlign: "center",
      type: "actions",
      flex: 1,
      align: "center",
      getActions: (params: GridRenderCellParams<any>) => {
        return [
          <AntSwitch
            checked={params.row.status === STATUS.ACTIVE}
            disabled={!RbacHasAccess.editPlans}
            onChange={(e) => {
              e.stopPropagation();
              toggleUpdateStatusDialog({
                ...params?.row,
                body:
                  params.row.status === STATUS.ACTIVE
                    ? rentalEn.planManagement.deactivatePlanStatusConfirmation
                    : rentalEn.planManagement.activatePlanStatusConfirmation,
              });
            }}
            sx={{ alignSelf: "center" }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    // Fetch data from local storage
    getFilterDataFromLocalStorage(filterStorageKeys.PLAN_LIST_FILTER_DATA).then(
      (data: any) => {
        // API call for models
        const { selectedBranch = "", selectedCity = "" } = data || {};
        const payload: GenericObject = {
          branches: selectedBranch?.name ? [selectedBranch?.name] : null,
          city: selectedCity?.name ? selectedCity?.name : null,
        };

        dispatch(rentalActions.getModelListByBranch(payload));
        if (data) {
          setFields((prev: any) => ({ ...prev, ...data }));
        } else {
          dispatch(
            planManagementActions.getRentalPlanList({
              data: {
                page: fields?.paginationModel?.page + 1, // mui data grid page starts from zero, hence adding +1 to it
                limit: fields?.paginationModel?.pageSize,
              },
            })
          );
        }
      }
    );

    return () => {
      dispatch(planManagementActions.clearRentalPlanList());
    };
  }, []);

  // clear planlist and selected branch if we change the selected city
  useEffect(() => {
    if (fields.selectedCity.name)
      dispatch(
        rentalActions.getAllBranches({ name: fields.selectedCity.name })
      );
    dispatch(planManagementActions.clearRentalPlanList());
  }, [fields.selectedCity.displayName]);

  //api to get all rental plans list on change of selected city, branch, and lob
  useEffect(() => {
    // Store filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      planListFilterData: { ...fields },
    });

    dispatch(
      planManagementActions.getRentalPlanList({
        data: {
          branch: fields.selectedBranch.name,
          model: fields.selectedVehicle.name,
          useCase: fields.selectedLob.name,
          page: fields?.paginationModel?.page + 1, // mui data grid page starts from zero, hence adding +1 to it
          limit: fields?.paginationModel?.pageSize,
          status: fields?.status?.name,
          city: fields.selectedCity.name,
        },
      })
    );
  }, [
    fields.selectedBranch.displayName,
    fields.selectedCity.displayName,
    fields.selectedLob.name,
    fields.selectedVehicle.displayName,
    fields.statusUpdate,
    fields?.paginationModel.page,
    fields?.paginationModel?.pageSize,
    fields?.status,
  ]);

  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));
  };

  const handleChange = (field: string, value: any) => {
    if (field === "selectedCity" && value.name !== fields.selectedCity.name) {
      setFields((prevState: any) => ({
        ...prevState,
        ...initialState,
        [field]: value,
        paginationModel: {
          page: 0,
          pageSize: fields?.paginationModel?.pageSize,
        },
      }));
      return;
    }

    if (field === "selectedBranch") {
      const { selectedCity } = fields;
      const payload: GenericObject = {
        branches: value?.name ? [value?.name] : null,
        city: selectedCity?.name ? selectedCity?.name : null,
      };
      // fetch only the selected branch vehicles
      dispatch(rentalActions.getModelListByBranch(payload));
      setFields((prevState: any) => ({
        ...prevState,
        [field]: value,
        selectedVehicle: initialState.selectedVehicle,
        paginationModel: {
          page: 0,
          pageSize: fields?.paginationModel?.pageSize,
        },
      }));
      return;
    }

    setFields((prevState: any) => ({
      ...prevState,
      [field]: value,
      paginationModel: { page: 0, pageSize: fields?.paginationModel?.pageSize },
    }));
  };

  // api call for activating and deactivating the plan
  const handleStatusChange = () => {
    const payloadData = {
      status:
        dialogData.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE,
      planId: dialogData.planId,
    };

    dispatch(
      planManagementActions.updateRentalPlanStatus({
        ...payloadData,
        updateSwitch: () => {
          // plan list will be updated on the change of status update
          handleChange("statusUpdate", !fields.statusUpdate);
        },
      })
    );
  };

  const getRowId = (row: any) => {
    return row?.planId;
  };

  const handleRowClick = (row: any) => {
    RbacHasAccess.viewPlans &&
      navigate(`${routesConstants.ADD_EDIT_PLAN_DETAIL}/${row.planId}`);
  };

  const toggleUpdateStatusDialog = (
    planData: any = {},
    updateStatus: boolean = false
  ) => {
    if (updateStatus) {
      handleStatusChange();
    }
    setDialogData((prev) => {
      return { ...prev, openDialog: !prev.openDialog, ...planData };
    });
  };

  const handleFilterReset = () => {
    setFields(initialState);
    // reset data in local storage
  };

  return (
    <Stack sx={{ backgroundColor: "unset", gap: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {RbacHasAccess.addPlans && (
            <CustomButton
              variant="outlined"
              onClick={() => {
                navigate(routesConstants.ADD_PLAN_MANAGEMENT);
              }}
              style={{ padding: "4px 22px" }}
            >
              {rentalEn.planManagement.addNewPlan}
            </CustomButton>
          )}
        </Box>
        {RbacHasAccess?.listPlans && (
          <Tooltip title={rentalEn?.global?.resetFilters} arrow>
            <RotateRightSharp
              fontSize="large"
              color="primary"
              sx={{ cursor: "pointer", fontSize: "25px" }}
              onClick={handleFilterReset}
            />
          </Tooltip>
        )}
      </Box>
      {RbacHasAccess?.listPlans && (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            // flexWrap: "wrap",
            gap: "12px",
          }}
        >
          {/* SELECT CITY */}
          <CustomSelect
            required
            icon={CityInputIcon}
            placeholder={
              fields.selectedCity.displayName
                ? rentalEn.global.SelectCity
                : rentalEn?.global?.allCities
            }
            value={fields.selectedCity.displayName}
            choice={allCitiesDetails}
            defaultKey={"displayName"}
            handleChange={(city: any) => {
              handleChange("selectedCity", city);
            }}
            // width={240}
          />
          {/* SELECT hub */}
          <CustomSelect
            required
            icon={WarehouseIcon}
            placeholder={
              fields.selectedBranch.displayName
                ? rentalEn.global.SelectBranch
                : rentalEn?.global?.allHubs
            }
            value={
              isArrayNotEmpty(branches) ? fields.selectedBranch.displayName : ""
            }
            choice={branches ?? []}
            defaultKey={"displayName"}
            disabled={
              !fields.selectedCity.displayName || branches?.length === 0
            }
            handleChange={(branch: any) => {
              handleChange("selectedBranch", branch);
            }}
            // width={240}
          />
          {/* SELECT VEHICLE */}
          <CustomSelect
            required
            icon={BikeIconFaded}
            placeholder={rentalEn.global.SelectVehicle}
            value={fields.selectedVehicle.displayName}
            choice={[
              { name: "", displayName: rentalEn?.global?.allModels },
              ...modelListByBranch,
            ]}
            defaultKey={"displayName"}
            handleChange={(city: any) => {
              handleChange("selectedVehicle", city);
            }}
            // width={240}
          />

          {/* SELECT PLAN OF BUSINESS */}
          <CustomSelect
            required
            icon={FunctionalitiesIcon}
            placeholder={rentalEn.global.selectPlanType}
            value={fields.selectedLob.displayName}
            choice={[
              { name: "", displayName: rentalEn?.global?.allUseCases },
              ...useCaseArray,
            ]}
            defaultKey={"displayName"}
            handleChange={(lobType: any) => {
              handleChange("selectedLob", lobType);
            }}
            // width={240}
          />
        </Box>
      )}
      {RbacHasAccess?.listPlans && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {/* SELECT STATUS OF PLAN */}
          <CustomSelect
            required
            icon={StatusIcon}
            placeholder={rentalEn.global.selectStatus}
            value={fields.status.displayName}
            choice={[
              { name: "", displayName: rentalEn?.global?.allStatus },
              ...activeInactiveStatusArray,
            ]}
            defaultKey={"displayName"}
            handleChange={(status: any) => {
              handleChange("status", status);
            }}
            width={190}
          />
        </Box>
      )}
      {RbacHasAccess.listPlans && (
        <DataTable
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnMenu
          autoHeight
          rows={rentalPlanListData?.planData}
          columns={columns}
          headerAlign={"center"}
          getRowId={getRowId}
          checkboxSelection={false}
          loading={onLoad}
          disableColumnSorting
          hideColumnSeperator
          rowCount={rentalPlanListData?.pagination?.total || 0}
          paginationModel={fields?.paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          onRowClick={(params: GridRowParams) => {
            handleRowClick(params.row);
          }}
        />
      )}
      {/* confirmation dialog on status change  */}
      <ResponsiveDialog
        title={rentalEn.planManagement.UpdatePlanStatus}
        body={dialogData.body}
        open={dialogData.openDialog}
        handleClose={() => toggleUpdateStatusDialog({}, false)}
        handleYes={() => toggleUpdateStatusDialog({}, true)}
      />
    </Stack>
  );
};

export default PlanManagement;
