import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import React from "react";
//@ts-ignore
const InputDatePicker = (props: any) => {
  const [open, setOpen] = React.useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={props?.placeholder}
        value={props?.value}
        onChange={props?.onChange}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "8px 14px",
              },
              width: "100%",
              maxWidth: "500px",
              minHeight: "38px",
              height: "5vh",
              maxHeight: "42px",
            }}
            onFocus={(e) => (e.target.readOnly = true)}
            onClick={() => setOpen(true)}
          />
        )}
        defaultCalendarMonth={props?.defaultValue}
        className="custom-react-datepicker"
        shouldDisableDate={props?.shouldDisableDate}
      />
    </LocalizationProvider>
  );
};

export default InputDatePicker;
