import { GenericObject } from "../../../constants/constants";
import * as actionTypes from "../actionTypes";

export const getOperatorLeadList = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_LIST_REQUESTED,
    payload,
  };
};

export const getOperatorLeadDetails = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_DETAILS_REQUESTED,
    payload,
  };
};

export const saveOperatorLeadDetails = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_LEAD_DETAILS_REQUESTED,
    payload,
  };
};

export const verifyEmailOperator = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .VERIFY_EMAIL_OPERATOR_REQUESTED,
    payload,
  };
};

export const initiateESignOperators = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .INITIATE_ESIGN_OPERATOR_REQUESTED,
    payload,
  };
};

export const approveLead = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_LEAD_OPERATOR_REQUESTED,
    payload,
  };
};

//GET OPERATOR LISTS
export const getOperatorList = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_REQUESTED,
    payload,
  };
};

//GET OPERATOR LISTS
export const getOperatorDetails = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_DETAILS_DETAILS_REQUESTED,
    payload,
  };
};

// clear operator details
export const clearOperatorDetails = () => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.CLEAR_OPERATOR_DETAILS,
  };
};

//approve or reject operator document
export const approveRejectOperatorDocument = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_REJECT_OPERATOR_DOCUMENTS_REQUESTED,
    payload,
  };
};

// Save operator documents
export const saveOperatorDocument = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_DOCUMENTS_REQUESTED,
    payload,
  };
};

// Submit operator documents
export const sumbitOperatorDocument = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SUBMIT_OPERATOR_DOCUMENTS_REQUESTED,
    payload,
  };
};

// modify operator config
export const modifyOperatorConfig = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .MODIFY_OPERATOR_CONFIG_REQUESTED,
    payload,
  };
};

export const viewAgreement = (payload: any) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_REQUESTED,
    payload,
  };
};
