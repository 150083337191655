import { Box, Modal, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { typographyConstants } from "../../../../../constants/constants";
import en from "../../../../../locale/rental-en.json";
import { StyledObject } from "../../styleObject";
const { IotManagement } = en;

const IotBulkUploadErrorModal = (props: any) => {
  const { handleModalClose, openModal, iotBulkUploadData } = props;
  const {
    validEntries = 0,
    invalidEntries = [],
    errors = [],
  } = iotBulkUploadData;

  //get unique row id
  const getRowId = (row: any) => (errors.length ? row.field : row.imeiNumber);

  // modal table columns
  const errorColumns: GridColDef[] = [
    {
      field: "field",
      headerName: errors.length
        ? IotManagement.ErrorFields
        : IotManagement.ImeiNumber,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{errors.length ? params.row.field : params.row.imeiNumber}</span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "message",
      headerName: IotManagement.ErrorMessage,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={errors.length ? params.row.message : params.row.errorMessage}
          >
            <span>
              {errors.length ? params.row.message : params.row.errorMessage}
            </span>
          </Tooltip>
        );
      },
      flex: 1,
      resizable: false,
    },
  ];
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={StyledObject.bulkUploadModalStyle}>
        <Typography variant={typographyConstants.HEADINGXL}>
          {IotManagement.IotUploadError}
        </Typography>
        <Stack sx={{ gap: "5px" }}>
          <Typography
            variant={typographyConstants.SUBHEADING}
          >{`${IotManagement.ValidEntries}: ${validEntries}`}</Typography>
          <DataTable
            columns={errorColumns}
            rows={errors.length ? errors : invalidEntries}
            hideFooter={true}
            disableColumnMenu
            disableColumnSorting
            headerAlign={"center"}
            getRowId={() => crypto.randomUUID()}
            sx={{
              width: "100%",
            }}
            rowHeight={45}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default IotBulkUploadErrorModal;
