import { API_URLS, BASE_URL, customAxios } from "../config";

//user reports
const getUserReports = (payload: any) => {
  try {
    let userReportUrl = API_URLS.getUserReports;
    if (payload.page) userReportUrl += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size))
      userReportUrl += `&size=${payload.size}`;

    userReportUrl += payload.FromDate ? `&FromDate=${payload.FromDate}&` : "";
    userReportUrl += payload.ToDate ? `ToDate=${payload.ToDate}` : "";
    if (
      payload.is_download != 0 &&
      payload.is_download &&
      !isNaN(payload.is_download)
    ) {
      userReportUrl += `&is_download=${payload.is_download}`;
    } else {
      userReportUrl += `&is_download=0`;
    }

    return fetchUserReports(userReportUrl);
  } catch (ex) {
    return ex;
  }
};

//booking reports
const getBookingReports = (payload: any) => {
  try {
    let route = API_URLS.getBookingReports;
    if (payload.page) route += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size)) route += `&size=${payload.size}`;
    route += payload.FromDate ? `&FromDate=${payload.FromDate}&` : "";
    route += payload.ToDate ? `ToDate=${payload.ToDate}` : "";
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      route += `&city_id=${payload.city_id}`;
    if (
      payload.is_download != 0 &&
      payload.is_download &&
      !isNaN(payload.is_download)
    ) {
      route += `&is_download=${payload.is_download}`;
    } else {
      route += `&is_download=0`;
    }
    return fetchBookingReports(route);
  } catch (ex) {
    return ex;
  }
};

const getPaymentReports = (payload: any) => {
  try {
    let route = API_URLS.getPaymentReports;
    if (payload.page) route += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size)) route += `&size=${payload.size}`;
    route += payload.FromDate ? `&FromDate=${payload.FromDate}&` : "";
    route += payload.ToDate ? `ToDate=${payload.ToDate}` : "";
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      route += `&city_id=${payload.city_id}`;
    if (
      payload.is_download != 0 &&
      payload.is_download &&
      !isNaN(payload.is_download)
    ) {
      route += `&is_download=${payload.is_download}`;
    } else {
      route += `&is_download=0`;
    }
    return fetchPaymentReports(route);
  } catch (ex) {
    return ex;
  }
};

const getPaymentReconciliationReports = (payload: any) => {
  try {
    let route = API_URLS.getPaymentReconciliationReports;
    if (payload.page) route += `page=${payload.page}`;
    if (payload.size && !isNaN(payload.size)) route += `&size=${payload.size}`;
    route += payload.FromDate ? `&FromDate=${payload.FromDate}&` : "";
    route += payload.ToDate ? `ToDate=${payload.ToDate}` : "";
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      route += `&city_id=${payload.city_id}`;
    if (
      payload.is_download != 0 &&
      payload.is_download &&
      !isNaN(payload.is_download)
    ) {
      route += `&is_download=${payload.is_download}`;
    } else {
      route += `&is_download=0`;
    }
    return fetchPaymentReconciliationReports(route);
  } catch (ex) {
    return ex;
  }
};
const getGenericReports = (payload: any) => {
  let route = BASE_URL + API_URLS.genericReports;
  return fetchGenericReports(route);
};

const downloadGenericReports = (payload: any) => {
  try {
    let url = BASE_URL + API_URLS.downloadGenericReports;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};
const getPendingReports = (payload: any) => {
  let route = API_URLS.revenueReport;

  route += payload.select_date ? `&select_date=${payload.select_date}&` : "";

  if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
    route += `&city_id=${payload.city_id}`;
  if (
    payload.is_download != 0 &&
    payload.is_download &&
    !isNaN(payload.is_download)
  ) {
    route += `&is_download=${payload.is_download}`;
  }
  return fetchPendingReports(route);
};

const fetchUserReports = (userReportUrl: any) =>
  customAxios.get(userReportUrl).catch((ex) => ex);

const fetchGenericReportsDownload = (route: any, payload: any) =>
  customAxios
    .post(route, {
      endDate: payload?.endDate,
      reportType: payload?.reportType,
      requiredCsvFields: payload?.requiredCsvFields,
      startDate: payload?.startDate,
    })
    .catch((ex) => ex);
const fetchBookingReports = (bookingReportUrl: any) =>
  customAxios.get(bookingReportUrl).catch((ex) => ex);
const fetchPaymentReports = (paymentReportUrl: any) =>
  customAxios.get(paymentReportUrl).catch((ex) => ex);
const fetchPaymentReconciliationReports = (
  fetchPaymentReconciliationReportsUrl: any
) => customAxios.get(fetchPaymentReconciliationReportsUrl).catch((ex) => ex);
const fetchPendingReports = (route: any) =>
  customAxios.get(route).catch((ex) => ex);
const fetchGenericReports = (route: any) =>
  customAxios.get(route).catch((ex) => ex);

export default {
  getUserReports,
  getBookingReports,
  getPaymentReports,
  getPaymentReconciliationReports,
  getPendingReports,
  getGenericReports,
  downloadGenericReports,
};
