import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse } from "@mui/material";
import { useState } from "react";
import { CustomIconButton } from "../../atoms/Buttons/CustomButtons";
import { GenericObject } from "../../../rentals-src/constants/constants";
interface CustomAccordion {
  expand: boolean;
  onClick: Function;
  renderAccordianTab: JSX.Element;
  renderAccordionBody?: JSX.Element;
  customTabStyle?: GenericObject;
  customBodyStyle?: GenericObject;
  disabled?: boolean;
}

/** 
 *  props detailing
@param {boolean} props.expand - To expand and collapse accordion
@param {Function} props.onclick - need to pass callback function to perform operations on onClick
@param {React.JSX} props.renderAccordianTab -pass ui for accordion tab
@param {React.JSX} props.renderAccordionBody -pass ui for accordion body 
@param {object} props.customTabStyle -custom style for accordion tab 
@param {object} props.customBodyStyle -custom style for accordion body 
*/

const CustomAccordion = (props: CustomAccordion) => {
  const {
    renderAccordionBody,
    renderAccordianTab,
    expand,
    onClick,
    customTabStyle = {},
    customBodyStyle = {},
    disabled = false,
    ...rest
  } = props;

  const handleExpansion = () => {
    onClick();
  };

  return (
    <Box
      sx={{
        width: 1,
        borderRadius: customTabStyle?.borderRadius
          ? customTabStyle?.borderRadius
          : 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#e7f0f0",
      }}
    >
      <Box
        sx={{
          width: 1,
          padding: "8px 14px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          boxShadow: 1,
          backgroundColor: disabled ? "#E5E5E5" : "#FFFFFF",
          cursor: disabled ? "no-drop" : "pointer",
          zIndex: 909,
          ...customTabStyle,
        }}
        onClick={disabled ? () => {} : handleExpansion}
      >
        <Box>
          <>{renderAccordianTab}</>
        </Box>
        <CustomIconButton
          children={
            expand ? (
              <KeyboardArrowUpIcon fontSize="inherit" color="primary" />
            ) : (
              <ExpandMoreIcon fontSize="inherit" color="primary" />
            )
          }
          disabled={disabled}
        />
      </Box>
      <Collapse
        mountOnEnter
        unmountOnExit
        orientation="vertical"
        timeout={"auto"}
        in={expand}
        sx={{
          transformOrigin: "0 0 0",
          transition: "height 1s ease-out, opacity 1s ease-out",
          backgroundColor: "#FFFFFF",
          marginTop: "-30px",
          ...customBodyStyle,
        }}
        {...rest}
      >
        <Box gap={"20px"} marginTop={"30px"}>
          {renderAccordionBody}
        </Box>
      </Collapse>
    </Box>
  );
};

// export default StyledCustomAccordion;
export default CustomAccordion;
