import { Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { KYCStatus } from "../../../../../constants/bookingContants";
import { RootState } from "../../../../../redux/store";
import { WrongUrl } from "../../../../../screens";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";
import {
  BookingEndSlotOptions,
  BusinessType,
  GenericObject,
  OperatorKycStatus,
} from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";
import { operationActions, rentalActions } from "../../../../redux/actions";
import { clearOperatorDetails } from "../../../../redux/actions/operatorManagementActions";
import { updateBreadcrumbs } from "../../../../redux/actions/rentalCommonAction";
import { formatString } from "../../../../utils/helper";
import { BasicDetails } from "../../leads/section/BasicDetails";
import { OperatorConfig } from "../../leads/section/operatorConfig";
import PreviewDigitalAgreement from "./PreviewDigitalAgreement";
import OperatorDocumentUpload from "./operatorKYCVerify";

interface InitalState {
  operatorConfig: GenericObject;
  uploadedDoc: any;
  kycValues: any;
  documentUploadLoader: any;
  openAgreementModal: boolean;
  isAddressModalOpen: boolean;
}
const initialState: InitalState = {
  operatorConfig: {},
  uploadedDoc: {},
  kycValues: {},
  documentUploadLoader: {},
  openAgreementModal: false,
  isAddressModalOpen: false,
};

export const ApprovedLeadDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { operatorManagement } = rentalEn;

  // data from operator management reducer
  const {
    operatorDetails,
    loading,
    modifyOperatorLoader = false,
  } = useSelector((state: RootState) => state.operatorManagementReducer);
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);
  const authorizedOperators = userData?.authorizedOperators || [];
  const operatorId = params?.id || authorizedOperators[0] || "";
  const {
    operatorConfigDetails = {},
    kycStatus,
    generatedAgreement,
    operatorAgreementStatus,
  } = operatorDetails;

  const [fields, setFields] = useState<any>({ ...initialState });
  // get operator detail
  useEffect(() => {
    dispatch(operationActions.getOperatorDetails({ id: operatorId }));
  }, [operatorId]);

  // called when operator details are fetched from API
  useEffect(() => {
    if (Object.keys(operatorConfigDetails).length) {
      setFields((prevState: any) => ({
        ...prevState,
        operatorConfig: operatorConfigDetails,
      }));
      // set screen header
      if (params?.id)
        dispatch(
          rentalActions.setScreenHeaderName({
            headerName: rentalEn.operatorManagement.operatorManagement,
          })
        );
    }
  }, [JSON.stringify(operatorDetails)]);

  useEffect(() => {
    // update breadcrumbs
    if (params?.id) {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OPERATOR_LIST));
    }

    return () => {
      handleChange("uploadedDoc", {});
      dispatch(clearOperatorDetails());
    };
  }, []);

  const handleConfigChange = (key: string) => {
    setFields((prevState: any) => {
      const newOperatorConfig = {
        ...prevState.operatorConfig,
        [key]: !prevState.operatorConfig[key],
      };
      return {
        ...prevState,
        operatorConfig: newOperatorConfig,
      };
    });
  };

  const handleChange = (key: string, value: any) => {
    if (!key) {
      setFields((prevState: any) => {
        return { ...prevState, ...value };
      });
      return;
    }

    setFields((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const handleSaveConfigDetails = () => {
    const payload = {
      data: {
        operatorId: operatorId,
        configuration: fields.operatorConfig,
      },
    };
    // save operatorConfigDetails to the server
    dispatch(operationActions.modifyOperatorConfig(payload));
  };

  // return string of all operator cities
  const getOperationalCities = useCallback(() => {
    if (!operatorDetails?.operationalCity) return null;
    let cities = operatorDetails?.operationalCity.reduce(
      (arr: string[], city: any) => {
        arr.push(city.displayName);
        return arr;
      },
      []
    );
    return cities.join(",");
  }, [JSON.stringify(operatorDetails?.operationalCity)]);

  let bookingdescriptionArr = [
    {
      displayKey: operatorManagement.mobileNumber,
      value: operatorDetails?.phone,
    },
    {
      displayKey: operatorManagement.email,
      value: operatorDetails?.email,
    },
    {
      displayKey: operatorManagement.preferredCity,
      value: operatorDetails?.preferredCity,
    },
    {
      displayKey: operatorManagement.businessType,
      value:
        operatorDetails?.businessDetails?.type === BusinessType.LLP ||
        operatorDetails?.businessDetails?.type === BusinessType.NGO
          ? operatorDetails?.businessDetails?.type
          : formatString(operatorDetails?.businessDetails?.type),
    },
    {
      displayKey: operatorManagement.revenueShare,
      value: `${operatorDetails?.operatorConfigDetails?.operatorSplitShare}%`,
    },
    {
      displayKey: operatorManagement.bookingHours,
      value: operatorDetails?.bookingHours,
    },

    {
      displayKey: operatorManagement.allowedBookingDuration,
      value:
        operatorDetails?.operatorConfigDetails?.allowedBookingDuration === 1
          ? `${operatorDetails?.operatorConfigDetails?.allowedBookingDuration} Day`
          : `${operatorDetails?.operatorConfigDetails?.allowedBookingDuration} Days`,
    },

    {
      displayKey: operatorManagement.bookingEndSlotType,
      value:
        BookingEndSlotOptions.find(
          (option: any) =>
            option.name ===
            operatorDetails?.operatorConfigDetails?.bookingEndSlotType
        )?.displayName || rentalEn.global.NA,
    },

    {
      displayKey: operatorManagement.operationalCities,
      value: getOperationalCities(),
    },
    {
      displayKey: operatorManagement.preferredCity,
      value: operatorDetails?.preferredCity?.[0] || "",
    },
  ];

  if (!loading && !Object.keys(operatorDetails).length) return <WrongUrl />;

  return (
    <Stack gap="20px">
      <BasicDetails
        loading={loading}
        bookingdescriptionArr={bookingdescriptionArr}
        operatorName={operatorDetails.name}
        isMyAccount={!params?.id}
      />
      <OperatorConfig
        operatorConfig={fields.operatorConfig}
        handleConfigChange={handleConfigChange}
        isDisabled={!params?.id}
        isMyAccount={!params?.id}
      />
      {params?.id ? (
        <CustomButton
          label={rentalEn.global.save}
          onClick={handleSaveConfigDetails}
          variant="contained"
          loading={modifyOperatorLoader}
          disabled={isEqual(
            fields?.operatorConfig,
            Object.keys(operatorConfigDetails)?.length && operatorConfigDetails
          )}
        />
      ) : null}
      {/* preview/upload kyc document */}
      <OperatorDocumentUpload
        fields={fields}
        handleChange={handleChange}
        isDisabled={!!params?.id}
        isMyAccount={!params?.id}
      />
      <PreviewDigitalAgreement
        fields={fields}
        handleChange={handleChange}
        aggreementData={{
          operatorAgreementStatus: OperatorKycStatus.VERIFIED,
          generatedAgreement,
        }}
      />
    </Stack>
  );
};
