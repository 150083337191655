import { theme } from "../../../../../themes";
import { colors } from "../../../../../themes/colors";

export const StyleObject: any = {
  muiTextFieldRootStyle: {
    width: 1,
    minWidth: "unset",
    maxWidth: "520px",
    minHeight: "40px",
    height: "40px",
    maxHeight: "40px",
  },
  dataGridStyle: {
    maxWidth: "100%",
    "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  noRowsOverlay_box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    p: 5,
  },
  spanStyle1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "7px",
  },
  vehicleDetailsUiStatus: {
    backgroundColor: colors.secondary_green,
    borderRadius: "16px",
    padding: "3px 14px ",
    colors: colors.white,
  },

  bulkUploadModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "10px 30px 30px 30px",
    maxHeight: "93%",
  },
};
