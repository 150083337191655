import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionItem from "../../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import { VariantButton } from "../../../components/muiButtonsX";
import { Heading } from "../../../../sharedComponents/typography";
import en from "../../../../locale/en.json";
import { RootState } from "../../../../redux/reducers";
import {
  BOOKING_CHARGES,
  BenefitTypes,
  HELMETS_ACTIONS_X,
  HELMETS_X,
  HELMETS_YOR,
} from "../../../constantsX/booking";
import {
  AddonsIcon,
  BenefitHelmetIcon,
  BenefitInsuranceIcon,
  BenefitServiceIcon,
  FullFaceHelmet,
  HalfFaceHelmet,
  KidsHelmet,
  PremiumHelmet,
} from "../../../constantsX/exportImagesX";
import { CheckoutProps } from "../../../interfaces/manualBookingInterfaces";
import { actionsX } from "../../../redux";
import { colors } from "../../../themes/colors";
import { getArraySum, getFormattedINR } from "../../../utils/helper";
import styles from "../ManualBooking.module.css";
import HelmetBadge from "../../../components/MultiStepForm/HelmetBadge";
import { TotalChargeLoader } from "../../../../sharedComponents/molecules/SkeletonLoader/CheckoutLoader";

const CheckOut = (props: CheckoutProps) => {
  const {
    selectedFields,
    bookingCharges,
    setFields,
    errorFields,
    setErrorFields,
  } = props;
  interface HELMETS_TYPES {
    [key: string]: number;
  }
  const initialExtraHelmet: HELMETS_TYPES = {
    premium: 0,
    fullFace: 0,
    halfFace: 0,
    kids: 0,
  };
  const [selectedExtraHelmet, setSelectedExtraHelmet] =
    useState(initialExtraHelmet);
  const dispatch = useDispatch();

  const arrBenefits = [
    {
      icon: BenefitServiceIcon,
      type: BenefitTypes.FREE_MAINTAINANCE,
      text: "Free first 3 servicing ",
    },
    {
      icon: BenefitHelmetIcon,
      type: BenefitTypes.FREE_HELMET,
      text: "Complementary 1st helmet",
    },
    {
      icon: BenefitInsuranceIcon,
      type: BenefitTypes.FREE_INSURANCE,
      text: "Insurance covered",
    },
  ];
  let planBenefits: any = [];
  planBenefits = selectedFields?.selectedPlan?.benefits
    ?.map((benefit: any) => {
      if (
        ((benefit?.type === BenefitTypes.FREE_MAINTAINANCE ||
          benefit?.type === BenefitTypes.FREE_INSURANCE) &&
          benefit?.data?.isActive) ||
        (benefit?.type === BenefitTypes.FREE_HELMET &&
          benefit?.data?.helmetType)
      ) {
        return benefit?.type;
      }
    })
    .filter((benefit: any) => benefit !== undefined);
  const isHelmetFree = planBenefits?.includes(BenefitTypes.FREE_HELMET)
    ? true
    : false;
  const [selectedOption, setSelectedOption] = useState(true);

  const handleComplimentarySelect = (option: boolean) => {
    setSelectedOption(option);
    setFields((prevState: any) => ({
      ...prevState,
      complementaryHelmet: option,
    }));
  };

  const additionalHelmets = [
    {
      icon: PremiumHelmet,
      type: HELMETS_X.premium,
      // type: HELMETS_YOR.premium,
      title: "Premium",
    },
    {
      icon: FullFaceHelmet,
      type: HELMETS_X.fullFace,
      // type: HELMETS_YOR.fullFace,
      title: "Full Face",
    },
    {
      icon: HalfFaceHelmet,
      // type: HELMETS_YOR.halfFace,
      type: HELMETS_X.halfFace,
      title: "Half Face",
    },
    {
      icon: KidsHelmet,
      // type: HELMETS_YOR.kids,
      type: HELMETS_X.kids,
      title: "Kids",
    },
  ];

  const extraHelmetHandler = (e: any, type: string, action: string) => {
    // let helmetCountArray = Object.values(selectedExtraHelmet);
    let helmetCountArray = Object.values(selectedFields.helmets);
    let helmetCount = getArraySum(helmetCountArray);
    if (action === HELMETS_ACTIONS_X.INCREMENT) {
      if (helmetCount < 3) {
        /* setSelectedExtraHelmet((prev: any) => {
          return {
            ...prev,
            [type]: prev[type] + 1,
          };
        }); */
        setFields((prevState: any) => ({
          ...prevState,
          helmets: {
            ...prevState.helmets,
            [type]: prevState.helmets[type] + 1,
          },
        }));
        if (errorFields?.selectedHelmet) {
          setErrorFields((prevState: any) => ({
            ...prevState,
            selectedHelmet: "",
          }));
        }
      } else {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmet: "* Max 3 helmets can be added",
        }));
      }
    }
    if (action === HELMETS_ACTIONS_X.DECREMENT) {
      /* setSelectedExtraHelmet((prev: any) => {
        return { ...prev, [type]: prev[type] ? prev[type] - 1 : prev[type] };
      }); */
      setFields((prevState: any) => ({
        ...prevState,
        helmets: {
          ...prevState.helmets,
          [type]: prevState.helmets[type]
            ? prevState.helmets[type] - 1
            : prevState.helmets[type],
        },
      }));
      if (errorFields?.selectedHelmet) {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmet: "",
        }));
      }
    }
  };

  useEffect(() => {
    const payload = {
      data: {
        planId: selectedFields?.selectedPlan?.id,
        helmets: selectedFields.helmets,
      },
    };
    if (selectedFields?.selectedPlan?.id) {
      dispatch(actionsX.getCalculatedCharges(payload));
    }
  }, [selectedFields.helmets]);
  // }, [selectedExtraHelmet]);
  const getRecurringPaymentDescription = (months: number) => {
    let description = "";
    description = months ? `Recurring for ${months} months` : ``;
    return description;
  };

  const { loadingCharges } = useSelector(
    (state: RootState) => state.manualBookingX
  );

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* left side */}
        <Stack gap="20px">
          {/* COMPLEMENTARY HELMET SECTION */}
          {planBenefits?.includes(BenefitTypes.FREE_HELMET) ? (
            <Box>
              <Heading
                title={en.manualBookingFreedoX.ComplementaryHelmet}
                fontSize={15}
              ></Heading>
              <Box sx={{ display: "flex", gap: "12px" }}>
                <VariantButton
                  label={en.manualBookingFreedoX.Yes}
                  onClick={() => handleComplimentarySelect(true)}
                  variant={selectedOption ? "contained" : "outlined"}
                />
                <VariantButton
                  label={en.manualBookingFreedoX.No}
                  variant={selectedOption ? "outlined" : "contained"}
                  onClick={() => handleComplimentarySelect(false)}
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}

          {/* ADDITIONAL HELMET */}
          <Box>
            <Heading
              title={en.manualBookingFreedoX.AdditionalHelmet}
              fontSize={15}
            />
            <Stack gap="2vh">
              <Box sx={{ display: "flex", gap: "1.2vw" }}>
                {additionalHelmets?.map((item) => {
                  return (
                    <HelmetBadge
                      title={item?.title}
                      icon={item.icon}
                      type={item.type}
                      clickHandler={extraHelmetHandler}
                      // helmetTypeCount={selectedExtraHelmet[item.type]}
                      helmetTypeCount={selectedFields.helmets[item.type]}
                    />
                  );
                })}
              </Box>
              <Box>
                {errorFields?.selectedHelmet && (
                  <Typography
                    className="error-message"
                    style={{ fontSize: "0.7rem" }}
                  >
                    {errorFields?.selectedHelmet}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Box>

          {/* Benefits section */}
          <Box>
            {planBenefits?.length > 0 && (
              <Heading
                title={en.manualBookingFreedoX.BenefitsYouGet}
                className="my-2 "
                fontSize={15}
              ></Heading>
            )}

            <Box sx={{ display: "flex", gap: "24px" }}>
              {arrBenefits
                .filter((benefitObj) => planBenefits?.includes(benefitObj.type))
                .map((item) => (
                  <Box
                    key={item?.type}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={item?.icon} />
                    <Typography
                      fontSize={10}
                      color={colors.primary_aqua_blue}
                      fontFamily="Euclid Circular B"
                    >
                      {" "}
                      {item?.text}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Stack>
        {/* PAYMENT BREAKUP */}
        <Box>
          <Box sx={{ width: "50%" }}>
            <Heading
              title={en.manualBookingFreedoX.PaymentBreakup}
              fontSize={15}
            ></Heading>
            <Box className={`${styles.BoxColumn} ${styles.paymentBreakup}`}>
              {bookingCharges?.charges?.map((charge: any) => {
                return (
                  <>
                    {/* MONTHLY FEE */}
                    {charge?.name == BOOKING_CHARGES.MONTHLY_FEE ? (
                      <SubscriptionItem
                        dashedBorder={true}
                        title={en.manualBookingFreedoX.MonthlyRentalFee}
                        description={getRecurringPaymentDescription(
                          bookingCharges?.durationInMonths
                        )}
                        amount={getFormattedINR(charge?.amount)}
                        showBorder={true}
                      />
                    ) : (
                      <></>
                    )}
                    {/* SUBSCRIPTION FEE   */}
                    {charge?.name === BOOKING_CHARGES.SUBSCRIPTION_FEE ? (
                      <SubscriptionItem
                        dashedBorder={true}
                        title={en.manualBookingFreedoX.FreedoXSubscription}
                        description="One time enrollment fee"
                        amount={getFormattedINR(charge?.amount)}
                        inclusiveTaxes={
                          en.manualBookingFreedoX.InclusiveAllTaxes
                        }
                        showBorder={true}
                      />
                    ) : (
                      <></>
                    )}
                    {/* ADDONS   */}
                    {charge?.name === BOOKING_CHARGES.ADD_ONS ? (
                      <SubscriptionItem
                        dashedBorder={true}
                        title={en.manualBookingFreedoX.Addons}
                        amount={getFormattedINR(charge?.amount)}
                        icon={AddonsIcon}
                        addOn={charge.amount > 0 ? charge : {}}
                        addOnCount={
                          charge.amount > 0 ? bookingCharges?.helmetDetails : {}
                        }
                        showBorder={true}
                      />
                    ) : (
                      <></>
                    )}
                    {charge?.name === BOOKING_CHARGES.ADD_ONS &&
                    charge?.discount ? (
                      <SubscriptionItem
                        dashedBorder={true}
                        title={en.manualBookingFreedoX.Discount}
                        amount={`- ${getFormattedINR(charge?.discount)}`}
                        showBorder={false}
                        discount="true"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
              {/* Total payable amount */}
              <TotalCharge
                title={en.manualBookingFreedoX.PayableAmount}
                amount={getFormattedINR(bookingCharges?.totalFee)}
                monthly={getFormattedINR(
                  bookingCharges?.charges
                    ? bookingCharges?.charges[0]?.amount
                    : 0
                )}
                recurringDuration={bookingCharges?.durationInMonths}
                loadingCharges={loadingCharges}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const TotalCharge = (props: any) => {
  const { loadingCharges } = useSelector(
    (state: RootState) => state.manualBookingX
  );
  const remainingDuration = props?.recurringDuration
    ? props.recurringDuration - 1
    : props?.recurringDuration;
  return (
    <>
      {loadingCharges ? (
        <TotalChargeLoader />
      ) : (
        <Box>
          <Box className={styles.totalChargeBox}>
            <Typography
              sx={{
                color: colors.primary_aqua_blue,
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Euclid Circular B",
              }}
            >
              {props.title}
            </Typography>
            <Box className={styles.amountBox}>
              <Typography
                sx={{ color: colors.primary_aqua_blue, fontSize: "16px" }}
              >
                {props.amount}
              </Typography>
            </Box>
          </Box>
          <Typography
            fontSize={12}
            color={colors.tertiary_black}
            fontFamily="Euclid Circular B"
          >
            {" "}
            * {props.monthly} will be deducted for the next {remainingDuration}{" "}
            months
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CheckOut;
