import { faBook, faHome } from "@fortawesome/free-solid-svg-icons";
import {
  DashboardScreen,
  ManualBookingScreen,
  BookingList,
  RentalPlans,
} from "../screens";
import {
  BookingModuleIcon,
  DashboardModuleIcon,
} from "../constantsX/exportImagesX";

import { LoginV3 } from "../../screens";
import BookingDetails from "../screens/NewBookingDetails";

export const DrawerRoutesYOR = [
  /* {
    path: "/x-dashboard",
    icon: faHome,
    key: "Dashboard",
    accessKey: "dashboard",
    img: DashboardModuleIcon,
  }, */
  {
    path: "/booking",
    icon: faBook,
    accessKey: "booking",
    key: "Bookings",
    img: BookingModuleIcon,
    types: [
      {
        path: "/new-booking",
        key: "Create Booking",
        accessKey: "manualBooking",
        img: BookingModuleIcon,
      },
      {
        path: "/booking-list",
        key: "All Bookings",
        accessKey: "bookinglist",
        img: BookingModuleIcon,
      },
    ],
  },
];

export const CustomRoutesYOR = [
  {
    path: "/",
    component: <LoginV3 />,
  },
  {
    path: "/x-dashboard",
    component: <DashboardScreen />,
  },
  {
    path: "/new-booking",
    component: <ManualBookingScreen accessKey={"manualBooking"} />,
    accessKey: "manualBooking",
  },
  {
    path: "/booking-list",
    component: <BookingList />,
  },
  {
    path: "/rental-plans",
    component: <RentalPlans />,
  },
  {
    path: "/booking-details/:id",
    component: <BookingDetails />,
  },
];
