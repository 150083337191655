import * as actionTypes from "../../actions/actionTypes";

interface InitialState {
  reportsDownloaded: boolean;
  loading: boolean;
}

const initialState: InitialState = {
  reportsDownloaded: false,
  loading: false,
};

const reportsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.REPORT_ACTIONS.GENERATE_REPOST_SUCCESS:
      return {
        ...state,
        reportsDownloaded: true,
        loading: false,
      };

    case actionTypes.REPORT_ACTIONS.GENERTE_REPORT_REQUESTED:
      return {
        ...state,
        loading: true,
        reportsDownloaded: false,
      };

    case actionTypes.REPORT_ACTIONS.GENERATE_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return { ...state };
  }
};

export default reportsReducer;
