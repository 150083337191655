import { Box, Modal, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import en from "../../../../../../locale/rental-en.json";
import { typographyConstants } from "../../../../../../constants/constants";
import { DataTable } from "../../../../../../../sharedComponents/templates/Tables/dataTable";
import { StyleObject } from "../../StyleObject";

const { IotManagement } = en;

const BulkUploadErrorModal = (props: any) => {
  const { handleModalClose, openModal, failedUploadBulksheetData } = props;

  // modal table columns
  const errorColumns: GridColDef[] = [
    {
      field: "field",
      headerName: failedUploadBulksheetData?.errors?.length
        ? IotManagement.ErrorFields
        : en?.VehicleManagement?.chassisNumber,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {failedUploadBulksheetData?.errors?.length
            ? params.row.field
            : params.row.chassisNumber}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "message",
      headerName: IotManagement.ErrorMessage,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={
            failedUploadBulksheetData?.errors?.length
              ? params.row.message
              : params.row.errorMessage
          }
        >
          <span>
            {failedUploadBulksheetData?.errors?.length
              ? params.row.message
              : params.row.errorMessage}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
  ];
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={StyleObject.bulkUploadModalStyle}>
        <Typography variant={typographyConstants.HEADINGXL}>
          {"Error"}
        </Typography>
        <Box
          sx={{
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Stack sx={{ gap: "5px" }}>
            <Typography
              variant={typographyConstants.SUBHEADING}
            >{`${IotManagement.ValidEntries}: ${failedUploadBulksheetData?.validEntries}`}</Typography>
            <DataTable
              columns={errorColumns}
              rows={
                failedUploadBulksheetData?.errors?.length
                  ? failedUploadBulksheetData?.errors
                  : failedUploadBulksheetData?.invalidEntries
              }
              hideFooter={true}
              disableColumnMenu
              disableColumnSorting
              headerAlign={"center"}
              getRowId={() => crypto.randomUUID()}
              sx={{
                width: "100%",
              }}
              rowHeight={40}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default BulkUploadErrorModal;
