import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../components/typography";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/breadCumbs";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import { colors } from "../../../themes/colors";
import { BookingTypes } from "../../../constants/enum";
import TabAssignment from "./TabAssignment";
import TabStatusTask from "./TabStatusTask";
import TabSummary from "./TabSummary";
import { getBookingDetails } from "../../redux/actions/bookingManagementActions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../redux/actions/rentalCommonAction";
import {
  BookingImagesTypes,
  BookingStatusEnum,
} from "../../constants/constants";
import { BREADCRUMBS_RENTALS } from "../../constants/activeModule";
interface IObjectKeys {
  [key: string]: string | number;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
  bookingId?: any | null;
}

const TaskAssignment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params: any = useParams();
  const { bookingDetails }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );

  const [open, setOpen] = useState<boolean>(false);
  const [taskCompleted, setTaskCompleted] = useState<boolean>(false);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  let booking_info: any = {}; // TODO
  const { taskAssigned } = useSelector((state: RootState) => state.taskReducer);
  const [tabName, setTabName] = useState<any>(0);
  const [active, setActive] = useState<number>(tabName || 0);
  const { taskManagement } = locale;

  useEffect(() => {
    setActive(tabName || 0);
  }, [tabName]);
  const handleTabChange = (i: number, each: any) => {
    setActive(i);
    setTabName(i);
  };

  useEffect(() => {
    // get booking details & operator config on success
    dispatch(
      getBookingDetails({
        bookingId: params.id,
        getConfig: true,
        fetchVehicleImages: true,
        requiredImages: [
          BookingImagesTypes.START_RIDE,
          BookingImagesTypes.END_RIDE,
          BookingImagesTypes.START_RIDE_ODOMETER,
          BookingImagesTypes.END_RIDE_ODOMETER,
        ],
      })
    );

    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.TASK_ASSIGNMENT(params.id)));

    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, []);

  useEffect(() => {
    if (taskAssigned) {
      setTabName(2);
      setActive(2);
    }
  }, [taskAssigned]);

  useEffect(() => {
    // 1 - pending, 2- upcoming, 3- ongoing, 4- ended, 5- completed, 6- cancelled
    if (
      bookingDetails?.status === BookingStatusEnum.UPCOMING ||
      bookingDetails?.status === BookingStatusEnum.ONGOING
    ) {
      setTabName(0);
      setActive(0);
    }
    // completed, ongoing
    if (bookingDetails?.status === BookingStatusEnum.COMPLETED) {
      setTabName(1);
      setActive(2);
    }
  }, [bookingDetails?.status]);
  const tabsList = ["TASK ASSIGNMENT", "STATUS", "TASK SUMMARY"];

  return (
    <Box>
      <Grid
        container
        justifyContent={"space-between"}
        style={{
          width: "100%",
          marginTop: 15,
          marginBottom: 15,
          marginLeft: 15,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          style={{
            fontSize: isDesktopOrTablet ? "18px" : "14px",
            fontFamily: "EuclidCircularB-Medium",
            color: "rgb(61, 56, 85)",
            fontStretch: "normal",
            marginBottom: 10,
          }}
        >
          {taskManagement.TaskAssignStatus}
        </Typography>
      </Grid>
      <Grid
        container
        xs={10}
        md={10}
        lg={10}
        sm={10}
        justifyContent={"space-between"}
        style={{
          // marginBottom: 15,
          margin: "auto",
          backgroundColor: "#Fff",
          borderRadius: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
            width: "100%",
            padding: isDesktopOrTablet ? "0px 10px" : "0px 0px",
          }}
        >
          <Grid style={{ width: `100%`, display: "flex" }}>
            {bookingDetails?.status === BookingStatusEnum.COMPLETED
              ? // || bookingDetails?.status === BookingStatusEnum.ONGOING
                tabsList.map((each, i) => {
                  if (each !== "TASK ASSIGNMENT") {
                    return (
                      <div
                        style={{
                          width: `100%`,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          key={i}
                          onClick={() => handleTabChange(i, each)}
                          className={`tabTask ${
                            active === i ? "tabTask-active" : ""
                          }`}
                          style={{ width: `60%` }}
                        >
                          <Typography
                            style={{
                              color: active === i ? colors.white : colors.black,
                              fontSize: isDesktopOrTablet ? "16px" : "12px",
                              fontFamily: "EuclidCircularB-SemiBold",
                              textAlign: "center",
                              wordBreak: "keep-all",
                            }}
                          >
                            {each}
                          </Typography>
                        </Grid>
                      </div>
                    );
                  }
                  return null;
                })
              : tabsList.map((each, i) => (
                  <div
                    style={{
                      width: `100%`,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      key={i}
                      onClick={() => handleTabChange(i, each)}
                      className={`tabTask ${
                        active === i ? "tabTask-active" : ""
                      }`}
                      style={{ width: `60%` }}
                    >
                      <Typography
                        style={{
                          color: active === i ? colors.white : colors.black,
                          fontSize: isDesktopOrTablet ? "16px" : "12px",
                          fontFamily: "EuclidCircularB-SemiBold",
                          textAlign: "center",
                          wordBreak: "keep-all",
                        }}
                      >
                        {each}
                      </Typography>
                    </Grid>
                  </div>
                ))}
          </Grid>
        </Box>
      </Grid>

      <Grid
        container
        xs={10}
        md={10}
        lg={10}
        sm={10}
        style={{
          marginBottom: 15,
          margin: "auto",
          backgroundColor: "#FBFBFB",
        }}
      >
        {tabName == 0 ? (
          <TabAssignment bookingId={params.id} bookingData={bookingDetails} />
        ) : null}
        {tabName == 1 ? (
          <TabStatusTask bookingId={params.id} bookingData={bookingDetails} />
        ) : null}
        {tabName == 2 ? (
          <TabSummary bookingId={params.id} bookingData={bookingDetails} />
        ) : null}
      </Grid>
    </Box>
  );
};

export default TaskAssignment;
