import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ImagePreviewNotAvailable } from "../../../assets/exportImages";
import styles from "../../components.module.css";
import { CustomButton } from "../../atoms/Buttons/CustomButtons";
import { downloadFile } from "../../../rentals-src/utils/helper";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  borderRadius: "2rem",
};

const ImagePreviewModal = (props: any) => {
  //   const [imgError, setImgError] = useState<boolean>(false);
  const { url = "", file } = props?.modalData;
  const imgSrc = file ? URL.createObjectURL(file) : url;

  // download the image
  const handleDownload = async () => {
    downloadFile(props.modalData);
  };

  const handleErr = () => props?.setImgError(true);

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <>
          <Box sx={style}>
            <Box>
              <img
                src={imgSrc}
                width={"auto"}
                height={"auto"}
                style={{
                  borderRadius: "2rem 2rem 0 0",
                  objectFit: "contain",
                  width: "550px",
                  maxHeight: "400px",
                }}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = ImagePreviewNotAvailable;
                  handleErr();
                }}
              ></img>
            </Box>
            <Box className={styles.imagePreviewModal}>
              {props?.modalData?.name}
            </Box>
            {props?.showDownloadButton && (
              <>
                {" "}
                {!props?.imgError && (
                  <Box className={styles.downloadButton}>
                    <CustomButton
                      variant="outlined"
                      sx={{
                        padding: "5px 10px !important",
                        position: "absolute",
                        bottom: "-75px",
                        left: "-5%",
                        color: "#fff",
                        borderColor: "#fff",
                      }}
                      label={"Download Image"}
                      onClick={handleDownload}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default ImagePreviewModal;
