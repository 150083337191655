import { Box, Checkbox, FormControlLabel, Stack, TextareaAutosize, Typography } from "@mui/material"
import { typographyConstants } from "../../../../constants/constants"
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBookingCancellationReasons, getCancellationCharges } from "../../../../redux/actions/rentalCommonAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import en from "../../../../locale/rental-en.json"
import { colors } from "../../../../../themes/colors";
import { cancelBooking } from "../../../../redux/actions/bookingManagementActions";
import { paisaToRupee } from "../../../../utils/helper";

const CancelBookingDrawer = ({bookingId}:any) =>{
    const dispatch =  useDispatch();
    const [ cancellationReasons, setCancellationReasons] = useState({
        id:"",
        description:"",
        remarks: "",
        details:{
          remarkRequired:false
        }
    });
    const [ checked, setChecked] = useState(false);
    const {bookingCancellationReasons,cancellationCharges} = useSelector((state:RootState)=>state.rentalsCommonReducer)
    const {cancelBookingLoader} = useSelector((state:RootState)=>state.bookingManagementReducer)

    useEffect(()=>{
        dispatch(getBookingCancellationReasons())
    },[])

    const handleCancelBooking = ()=>{
        let payload:any = {
          data:{
            data:{
              reasonId: cancellationReasons.id,
              refundAmount: cancellationCharges.refundAmount!== 0 ?cancellationCharges.refundAmount: 0
          }
          },
          bookingId: bookingId
        }
        if(cancellationReasons.details.remarkRequired){
            payload.data.data["remarks"] = cancellationReasons.remarks
        }
        dispatch(cancelBooking({...payload}))
    }
    return (
      <Stack sx={{ gap: "10px", padding: "20px" }}>
        <Box>
          <Typography variant={typographyConstants.SUBHEADING}>
            {en.bookingDetails.selectCancelreason}
          </Typography>
          <CustomSelect
            placeholder={en.bookingDetails.selectCancelreason}
            value={cancellationReasons.description}
            choice={bookingCancellationReasons}
            defaultKey={"description"}
            required
            handleChange={(reason: any) => {
              setCancellationReasons((prev: any) => ({
                ...prev,
                ...reason,
              }));
              setChecked(false);
              dispatch(
                getCancellationCharges({
                  id: bookingId,
                  reasonId: reason.id,
                })
              );
            }}
          />
          {Object.keys(cancellationCharges)?.length ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {cancellationCharges.deductCharges && (
                <Typography
                  variant={typographyConstants.ERRORMESSAGE}
                  color={colors.sherpa_blue}
                >
                  A penalty charges of Rs.{" "}
                  {cancellationCharges.cancellationCharge.amount !== 0
                    ? paisaToRupee(
                        cancellationCharges.cancellationCharge.amount
                      )
                    : 0}{" "}
                  will apply
                </Typography>
              )}
              <Typography
                variant={typographyConstants.ERRORMESSAGE}
                color={colors.sherpa_blue}
              >
                A refund amount of Rs.{" "}
                {cancellationCharges.refundAmount !== 0
                  ? paisaToRupee(cancellationCharges.refundAmount)
                  : 0}{" "}
                will be process
              </Typography>
            </Box>
          ) : null}
        </Box>
        {cancellationReasons.details.remarkRequired && (
          <Stack>
            <Typography>{en.bookingDetails.cancelReason}</Typography>
            <TextareaAutosize
              placeholder={en.bookingDetails.enterReason}
              style={{
                padding: "14px",
                borderRadius: "5px",
                boxShadow:
                  "1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A",
              }}
              onChange={(e: any) => {
                setCancellationReasons((prev: any) => ({
                  ...prev,
                  remarks: (e.target.value).trim(),
                }));
                setChecked(false);
              }}
              maxLength={120}
            />
          </Stack>
        )}
        <Box sx={{ position: "fixed", bottom: 10 }}>
          <Stack>
            <FormControlLabel
              //disabled={cancellationReasons.remarks==="" ? true : false}
              disabled={
                cancellationReasons.id === ""
                  ? true
                  : cancellationReasons.id === "OTHERS" &&
                    cancellationReasons.remarks === ""
                  ? true
                  : false
              }
              checked={checked}
              control={<Checkbox />}
              label={en.bookingDetails.cancelBookingConfirmation}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setChecked(true);
                } else {
                  setChecked(false);
                }
              }}
            />
            <CustomButton
              loading={cancelBookingLoader}
              variant="contained"
              label={en.bookingDetails.cancelBooking}
              style={{ height: "40px", borderRadius: "5px", width: "340px" }}
              onClick={handleCancelBooking}
              disabled={!checked}
            />
          </Stack>
        </Box>
      </Stack>
    );
}
export default CancelBookingDrawer