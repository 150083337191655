import axios from "axios";
import { FREEDO_X_URL } from "../../config";
import { customAxios } from "../../config/axiosConfig";
import { BookingX, Global } from "../constantsX/apiConstantsX";

export const getSignedUrlService = (payload: any) => {
  try {
    let url = FREEDO_X_URL + Global.attachment + Global.versioning1;
    return customAxios.post(url, payload?.requestdata).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const uploadDocumentService = (payload: any) => {
  try {
    const fileData = new Blob([payload?.file], { type: payload?.file?.type });
    return axios
      .put(payload.url, fileData, {
        headers: { "Content-Type": payload?.file.type },
      })
      .catch((ex) => {
        return ex;
      });
  } catch (ex) {
    return ex;
  }
};

export const moveAttachmentToS3 = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.bookingId}` +
      Global.documents;

    let requestBody = {
      data: {
        attachmentIds: payload?.attachmentsIds,
      },
    };
    return customAxios.post(url, requestBody).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const deleteDocumentService = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.bookingId}` +
      Global.documents;
    return customAxios.put(url, payload?.data).catch((ex) => ex);
  } catch (error) {}
};

export default {
  getSignedUrlService,
  uploadDocumentService,
  moveAttachmentToS3,
  deleteDocumentService,
};
