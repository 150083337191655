import newAuthServices from "../../services/newAuthService";
import * as actionTypes from "../actions/actionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utils/helper";
import { setAuthToken } from "../../config";

function* loginV3(action: any): any {
  try {
    const data = yield call(newAuthServices.loginV1, action.payload);
    if (data?.status === 200) {
      setAuthToken(data.data.data.accessToken ?? "");
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGIN_SUCCEEDED,
        payload: {
          userData: {
            ...data?.data?.data,
            xKey: data?.headers?.["x-key"],
            userName: action?.payload?.data?.userName,
          },
        },
      });
      if (!data?.data?.data.highPriority) {
        yield put({
          type: actionTypes.PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
        });
      }
    } else {
      toastr.error("", handleMessage(data?.response));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGIN_FAILED,
      });
    }
  } catch (ex: any) {
    toastr.error("", ex);
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGIN_FAILED,
    });
  }
}

function* multifactorAuthentication(action: any): any {
  try {
    const data = yield call(newAuthServices.multifactorAuth, action.payload);
    if (data?.status === 200) {
      setAuthToken(data?.data?.data?.accessToken ?? "");
      yield put({
        type: actionTypes.PROFILE_API_ACTION.FETCH_USER_PROFILE_DATA,
      });
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_SUCCEEDED,
        payload: {
          userData: {
            ...data?.data?.data,
            xKey: data?.headers?.["x-key"],
          },
        },
      });
    } else {
      toastr.error("", handleMessage(data.response));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_FAILED,
      });
    }
  } catch (ex) {
    console.error("multifactorAuthentication>", ex);
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_FAILED,
    });
  }
}

function* resendOtp(action: any): any {
  try {
    const data = yield call(newAuthServices.resendOtp, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.RESEND_OTP_SUCCEEDED,
        payload: data?.data?.data?.otpId,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.RESEND_OTP_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.RESEND_OTP_FAILED,
    });
  }
}

function* userSetPassword(action: any): any {
  try {
    const data = yield call(newAuthServices.setPassword, action.payload);

    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_SET_PASSWORD_SUCCEEDED,
        payload: {
          ...data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_SET_PASSWORD_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.USER_SET_PASSWORD_FAILED,
    });
  }
}

function* redirectionLinkVerification(action: any): any {
  try {
    const data = yield call(newAuthServices.linkVerification, action.payload);

    if (data?.status === 200) {
      toastr.success("Success", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS
          .REDIRECTION_LINK_VERIFICATION_SUCCEEDED,
        payload: {
          ...data.data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_FAILED,
    });
  }
}

function* userForgotPassword(action: any): any {
  try {
    const data = yield call(newAuthServices.userForgotPassword, action.payload);

    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_SUCCEEDED,
        payload: {
          ...data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data?.response));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_FAILED,
    });
  }
}

function* userUnlockAccount(action: any): any {
  try {
    const data = yield call(newAuthServices.unlockAccount, action.payload);

    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_SUCCEEDEED,
        payload: {
          ...data?.data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data?.response));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_FAILED,
    });
  }
}

function* userchangePassword(action: any): any {
  try {
    const data = yield call(newAuthServices.changePassword, action.payload);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.NEW_AUTH_ACTIONS.CHANGE_PASSWORD_SUCCEEDED,
        payload: {
          ...data?.data,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({ type: actionTypes.NEW_AUTH_ACTIONS.CHANGE_PASSWORD_FAILED });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({ type: actionTypes.NEW_AUTH_ACTIONS.CHANGE_PASSWORD_FAILED });
  }
}

function* newAuthSaga() {
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.USER_SET_PASSWORD_REQUESTED,
    userSetPassword
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.NEW_USER_LOGIN_REQUESTED,
    loginV3
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.USER_FORGOT_PASSWORD_REQUESTED,
    userForgotPassword
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.USER_ACCOUNT_UNLOCK_REQUESTED,
    userUnlockAccount
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.MULTIFACTOR_AUTHENTICATION_REQUESTED,
    multifactorAuthentication
  );
  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.REDIRECTION_LINK_VERIFICATION_REQUESTED,
    redirectionLinkVerification
  );

  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.CHANGE_PASSWORD_REQUESTED,
    userchangePassword
  );

  yield takeLatest(
    actionTypes.NEW_AUTH_ACTIONS.RESEND_OTP_REQUESTED,
    resendOtp
  );
}

export default newAuthSaga;
