import { storeData } from "../../../config/filterStorage";
import { LocalStorage } from "../../../constants/enum";
import { ACTIONS, AUTH_ACTIONS, } from "../../actions/actionTypes";
import { authInterface } from "../actionInterfaces";

const initialState = {
  payload: {},
  loading: false,
  loginError: null,
  logoutSuccess: false,
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  userData: {},
};

const authReducer = (state = initialState, action: authInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case AUTH_ACTIONS.USER_LOGIN_REQUESTED:
      return {
        ...state,
        loginError: null,
        loading: true,
      };

    case AUTH_ACTIONS.SET_USER_DATA:
      return {
        ...state,
        loginError: null,
        loading: false,
        userData: payload,
        isAuthenticated: true,
      };
    case AUTH_ACTIONS.SET_USER_TOKEN:
      return {
        ...state,
        token: payload?.token ?? "",
        refreshToken: payload?.refreshToken ?? "",
        ExpireTime: payload?.ExpireTime,
        xKey: payload?.["xKey"],
      };

    case AUTH_ACTIONS.USER_LOGIN_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        userData: payload.Result,
        loginError: null,
        loading: false,
        ExpireTime: payload?.ExpireTime,
        token: payload.Token,
        refreshToken: payload.RefreshToken,
      };
    case AUTH_ACTIONS.USER_LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        loginError: payload?.error,
        loading: false,
      };

    case AUTH_ACTIONS.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        userData: null,
        token: null,
        refreshToken: null,
      };

    case ACTIONS.AUTH_ISSUE:
      return {
        ...state,
        isAuthenticated: false,
        userData: {},
        token: null,
        // refreshToken: null,
      };

    default:
      return { ...prevState };
  }
};

export default authReducer;
