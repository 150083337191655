import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import { typographyConstants } from "../../../../constants/constants";
import {
  BikeIcon,
  CityInputIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { StyleObject } from "../StyleObject";

const SurgeSettings = (props: any) => {
  // props
  const {
    fields,
    allCitiesDetails,
    modelListByBranch,
    surgeByIdData,
    isEditing,
    branches,
    selectCityHandler,
    handleWarehouse,
    handlevehicleModel,
    showMore,
    viewMoreHandler,
  } = props;

  const theme = useTheme();
  const matchesMD: boolean = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLG: boolean = useMediaQuery(theme.breakpoints.up("lg"));

  const slicedBranches: any =
    isArrayNotEmpty(branches) && branches?.length > 4
      ? showMore?.showMoreHubs
        ? branches
        : matchesMD && !matchesLG
        ? branches?.slice(0, 6)
        : branches?.slice(0, 4)
      : branches;

  const slicedVehicleModels: any =
    isArrayNotEmpty(modelListByBranch) && modelListByBranch?.length > 4
      ? showMore?.showMoreModels
        ? modelListByBranch
        : matchesMD && !matchesLG
        ? modelListByBranch?.slice(0, 6)
        : modelListByBranch?.slice(0, 4)
      : modelListByBranch;

  const isShowMoreTextVisible = (arr: any[]): boolean => {
    return (
      isArrayNotEmpty(arr) &&
      ((matchesMD && !matchesLG && arr?.length > 6) ||
        (matchesMD && matchesLG && arr?.length > 4))
    );
  };

  return (
    <Box sx={StyleObject?.surgeSettings_wrapperBox}>
      {/* city section */}
      <Box sx={StyleObject?.surgeSettings_city_wrapperCard}>
        <CustomSelect
          required
          icon={CityInputIcon}
          placeholder={rentalEn?.global?.selectCity}
          value={fields?.cityDisplayName ?? ""}
          choice={allCitiesDetails || []}
          defaultKey={"displayName"}
          handleChange={(value: any) => {
            selectCityHandler(value);
          }}
          width={350}
          disabled={isEditing && surgeByIdData?.city}
        />

        {/* hub/branch section */}
        {fields?.cityDisplayName && (
          <Box sx={StyleObject?.surgeSettings_warehouse_wrapperCard}>
            <Box sx={StyleObject?.surgeSettings_warehouse_box1}>
              <Box sx={StyleObject?.surgeSettings_warehouse_box3}>
                <img src={WarehouseIcon} />
                <Typography
                  color={colors.white}
                  variant={typographyConstants.BODY}
                  fontSize="13px"
                >
                  {rentalEn?.global?.selectHub}
                </Typography>
              </Box>
              {isArrayNotEmpty(branches) && branches?.length > 1 && (
                <Typography
                  variant={typographyConstants.BODY}
                  sx={StyleObject?.selectAllText}
                  onClick={() => {
                    isEditing === undefined || isEditing
                      ? handleWarehouse(
                          {},
                          fields.branches.length === branches.length
                            ? rentalEn?.global?.clear
                            : rentalEn?.global?.all
                        )
                      : null;
                  }}
                >
                  {fields.branches.length === branches.length
                    ? rentalEn?.global?.unselectAll
                    : rentalEn?.global?.selectAll}
                </Typography>
              )}
            </Box>
            <Box sx={StyleObject?.surgeSettings_wareHouse_box2}>
              <Box sx={StyleObject?.surgeSettings_wareHouse_box2_In}>
                <Grid container alignItems={"center"} spacing={2}>
                  {slicedBranches?.map(
                    (warehouseInstance: any, index: number) => {
                      const isChecked: boolean = fields.branches.includes(
                        warehouseInstance?.name
                      );
                      return (
                        <Grid item sm={12} md={4} lg={3}>
                          <Tooltip
                            arrow
                            title={
                              warehouseInstance?.displayName?.length > 20
                                ? warehouseInstance?.displayName
                                : null
                            }
                          >
                            <FormControlLabel
                              key={index}
                              label={
                                warehouseInstance?.displayName?.length > 20
                                  ? `${warehouseInstance?.displayName?.substring(
                                      0,
                                      20
                                    )}
                                  ...`
                                  : warehouseInstance?.displayName
                              }
                              sx={{ width: 200 }}
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  onChange={(e: any) => {
                                    isEditing === undefined || isEditing
                                      ? handleWarehouse(warehouseInstance)
                                      : null;
                                  }}
                                />
                              }
                            />
                          </Tooltip>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Box>
              {isShowMoreTextVisible(branches) && (
                <Typography
                  variant={typographyConstants.BODY}
                  sx={StyleObject?.viewMoreText}
                  onClick={() =>
                    viewMoreHandler("showMoreHubs", !showMore?.showMoreHubs)
                  }
                >
                  {showMore?.showMoreHubs
                    ? rentalEn?.global?.showLess
                    : rentalEn?.global?.viewMore}
                </Typography>
              )}
            </Box>

            {fields?.city && !isArrayNotEmpty(branches) && (
              <Typography
                color={colors.dark_gray}
                variant={typographyConstants.BODY}
                sx={{ paddingLeft: "16px" }}
              >
                {rentalEn?.surgeManagement?.NoHubAvailable}
              </Typography>
            )}
          </Box>
        )}

        {/* vehicle model list section */}
        {isArrayNotEmpty(fields?.branches) && (
          <Box sx={StyleObject?.surgeSettings_vehicleModel_wrapperCard}>
            <Box sx={StyleObject?.surgeSettings_vehicleModel_box1}>
              <Box sx={StyleObject?.surgeSettings_vehicleModel_box3}>
                <img src={BikeIcon} />
                <Typography
                  color={colors.white}
                  variant={typographyConstants.BODY}
                >
                  {rentalEn?.global?.vehicleModel}
                </Typography>
              </Box>
              {isArrayNotEmpty(modelListByBranch) &&
                modelListByBranch?.length > 1 && (
                  <Typography
                    variant={typographyConstants.BODY}
                    sx={StyleObject?.selectAllText}
                    onClick={() => {
                      isEditing === undefined || isEditing
                        ? handlevehicleModel(
                            {},
                            fields?.models.length === modelListByBranch?.length
                              ? rentalEn?.global?.clear
                              : rentalEn?.global?.all
                          )
                        : null;
                    }}
                  >
                    {fields?.models?.length === modelListByBranch?.length
                      ? rentalEn?.global?.unselectAll
                      : rentalEn?.global?.selectAll}
                  </Typography>
                )}
            </Box>
            <Box sx={StyleObject?.surgeSettings_vehicleModel_box2}>
              <Box sx={StyleObject?.surgeSettings_vehicleModel_box2_In}>
                <Grid container alignItems={"center"} spacing={2}>
                  {slicedVehicleModels?.map(
                    (vehicleModelInstance: any, index: number) => {
                      const isChecked: boolean = fields.models.includes(
                        vehicleModelInstance?.name
                      );
                      return (
                        <Grid item sm={12} md={4} lg={3}>
                          <Tooltip
                            arrow
                            title={
                              vehicleModelInstance?.displayName?.length > 20
                                ? vehicleModelInstance?.displayName
                                : null
                            }
                          >
                            <FormControlLabel
                              key={String(index)}
                              // label={vehicleModelInstance?.displayName}
                              label={
                                vehicleModelInstance?.displayName?.length > 20
                                  ? `${vehicleModelInstance?.displayName?.substring(
                                      0,
                                      20
                                    )}
                                  ...`
                                  : vehicleModelInstance?.displayName
                              }
                              sx={{ width: 200 }}
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  onChange={(e: any) => {
                                    isEditing === undefined || isEditing
                                      ? handlevehicleModel(vehicleModelInstance)
                                      : null;
                                  }}
                                />
                              }
                            />
                          </Tooltip>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Box>
              {isShowMoreTextVisible(modelListByBranch) && (
                <Typography
                  variant={typographyConstants.BODY}
                  sx={StyleObject?.viewMoreText}
                  onClick={() =>
                    viewMoreHandler("showMoreModels", !showMore?.showMoreModels)
                  }
                >
                  {showMore?.showMoreModels
                    ? rentalEn?.global?.showLess
                    : rentalEn?.global?.viewMore}
                </Typography>
              )}
            </Box>

            {isArrayNotEmpty(fields?.branches) &&
              !isArrayNotEmpty(modelListByBranch) && (
                <Typography
                  color={colors.dark_gray}
                  variant={typographyConstants.BODY}
                  sx={{ paddingLeft: "16px" }}
                >
                  {rentalEn?.surgeManagement?.NoVehicleModelAvailable}
                </Typography>
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SurgeSettings;
