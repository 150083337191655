import { Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

import { SubscriptionLoader } from "../../molecules/SkeletonLoader/CheckoutLoader";
import { colors } from "../../../themes/colors";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import CustomizedTooltips from "../../atoms/Tooltips";

const SubscriptionItem = (props: any) => {
  return (
    <>
      {props?.loadingCharges ? (
        <SubscriptionLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            padding: props?.padding ? props?.padding : "6px 6px 8px 1px",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: props?.showBorder
              ? props.dashedBorder
                ? "1px dashed #ababab"
                : "1px solid #D4E4E452"
              : "",
          }}
        >
          <Stack>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Tooltip title={props?.titleTooltip} placement="top" arrow>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={
                      props?.titleColor
                        ? props?.titleColor
                        : colors.text_secondary_gray
                    }
                    sx={{ cursor: props?.titleTooltip ? "pointer" : "" }}
                  >
                    {props.title}
                  </Typography>
                </Tooltip>
                <Tooltip title={props?.surgeType} arrow>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={
                      props?.titleColor
                        ? props?.titleColor
                        : colors.text_secondary_gray
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {props?.surgeMultiplier ? props?.surgeMultiplier : <></>}
                  </Typography>
                </Tooltip>
              </Box>
              {/* {props?.date && (
                <Typography
                  sx={{
                    fontSize: "8px",
                    color: colors.text_secondary_gray,
                    fontFamily: "Euclid Circular B",
                    marginTop: "6px",
                  }}
                >
                  {`(${props?.date})`}
                </Typography>
              )} */}
              {/* Display icon or addOns */}
              {props?.addOns && props?.totalHelmetCount > 0 ? (
                <CustomizedTooltips
                  icon={props.icon}
                  content={props?.addOns}
                  helmetCharges={props?.helmetCharges}
                />
              ) : (
                props.icon && (
                  <img src={props.icon} width={24} height={24} alt="icon" />
                )
              )}
            </Box>
            {props.description && (
              <Typography
                variant={typographyConstants.BODYITALIC}
                color={
                  props?.subtitleColor
                    ? props?.subtitleColor
                    : colors.primary_aqua_blue
                }
              >
                {props.description}
              </Typography>
            )}
          </Stack>
          <Stack alignItems="end">
            <Typography
              variant={typographyConstants.SUBHEADING}
              color={
                props?.amountColor ? props?.amountColor : colors.text_black
              }
            >
              {props.amount}
            </Typography>
            {props.inclusiveTaxes && (
              <Typography
                variant={typographyConstants.BODYITALIC}
                color={colors.tertiary_black}
              >
                {props.inclusiveTaxes}
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default SubscriptionItem;
