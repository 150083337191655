import { Box, DialogActions } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { weekDays } from "../../../../constants/enum";
import { RootState } from "../../../../redux/reducers";
import CustomButtonGroup from "../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import FullScreenLoader from "../../../../sharedComponents/molecules/FullScreenLoader";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { isArrayNotEmpty, isObjectNotEmpty } from "../../../../utils/helper";
import {
  GenericObject,
  activeInactiveStatusArray,
} from "../../../constants/constants";
import { RoundedOne, RoundedTwo } from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import { rentalActions, surgeActions } from "../../../redux/actions";
import {
  isNumericTruthyUptoTwoDecimal,
  istToTimestamp,
  paisaToRupee,
  rupeeToPaisa,
} from "../../../utils/helper";
import { StyleObject } from "./StyleObject";
import SurgeConfiguration from "./sections/SurgeConfiguration";
import SurgeSettings from "./sections/SurgeSettings";
import ViewSurge from "./sections/ViewSurge";
import { colors } from "../../../../themes/colors";

const CreateSurge = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: surgeIdParams } = useParams(); // Access the data passed in the route using the 'useParams' hook

  // days of week
  const daysOfWeek: string[] = Object.values(weekDays);

  const deepCopy = (data: any) => {
    if (data) {
      return JSON.parse(JSON.stringify(data));
    } else return data;
  };

  const {
    allCitiesDetails, // list of all cities // lob='LTR'
    branches, // list of branches on the basis of city
    masterPlanDurationsData, // master plan durations data
    modelListByBranch, // this has the vehicle model list on the basis of either city or branches or both
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const {
    onLoad,
    surgeByIdData, // surge by id data
    addEditSurgeLoader,
  } = useSelector((state: RootState) => state.surgeReducer);

  // initial states
  const [fields, setFields] = useState<GenericObject>({
    city: "",
    cityDisplayName: "",
    branches: [],
    branchesDisplayName: [],
    models: [],
    modelsDisplayName: [],
    durations: [],
    dateType: "",
    surgeType: "",
    surgeValue: "",
    surgeName: "",
    daysOfWeek: [],
    surgeStatus: "",
    startDate: "",
    endDate: "",
    surgeId: "",
  });

  // initial error states
  const [errorFields, setErrorFields] = useState<GenericObject>({
    city: "",
    branches: "",
    models: "",
    durations: "",
    dateType: "",
    daysOfWeek: "",
    startEndDate: "",
    surgeType: "",
    surgeValue: "",
    surgeStatus: "",
    surgeName: "",
  });

  const [selectedTab, setSelectedTab] = useState<number>(0); // handle tab selection
  const [currentSection, setCurrentSection] = useState<any>(
    surgeIdParams ? "view" : "create"
  );
  const isEditing: any = surgeIdParams;

  // state to manage 'view more' and 'show less' button functionality
  const [showMore, setShowMore] = useState<GenericObject>({
    showMoreHubs: false,
    showMoreModels: false,
  });

  // state to manage the mui dialog
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // function to handle 'view more' and 'show less' button functionality
  const viewMoreHandler = (key: string, value: any) => {
    setShowMore({
      ...showMore,
      [key]: value,
    });
  };

  // Function to update surge details in state
  const updateSurgeDetails = (key: string, value: any) => {
    let errorMessage: string = "";

    // Switch statement to handle different keys
    switch (key) {
      // If the key is "surgeValue"
      case "surgeValue":
        let valueStr = value?.toString()?.replace(/^0+/, "");
        if (valueStr) {
          const [integerPart, decimalPart] = valueStr.split(".");
          if (decimalPart?.length) {
            const truncatedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
            setFields((prev: any) => ({ ...prev, [key]: truncatedValue }));
            errorMessage = isNumericTruthyUptoTwoDecimal(truncatedValue)
              ? ""
              : rentalEn?.surgeManagement?.EnterValidSurgeValue;
          } else {
            setFields((prev: any) => ({ ...prev, [key]: valueStr }));
            errorMessage = isNumericTruthyUptoTwoDecimal(valueStr)
              ? ""
              : rentalEn?.surgeManagement?.EnterValidSurgeValue;
          }
        } else {
          setFields((prev: any) => ({ ...prev, [key]: valueStr }));
          errorMessage = "";
        }
        break;

      // If the key is "surgeName"
      case "surgeName":
        if (!value && fields?.surgeName?.length === 1) {
          setFields({ ...fields, [key]: "" });
        } else if (/^[a-zA-Z0-9\s]*$/.test(value)) {
          // Update the state only if value is not empty
          if (value !== "") {
            setFields({ ...fields, [key]: value });
          }
        }
        break;

      // If the key is "branches"
      case "branches":
        setFields({ ...fields, models: [], [key]: value }); // reset vehicle model state on change of branch/hub
        break;

      // Default case (no specific action)
      default:
        setFields({ ...fields, [key]: value });
        break;
    }

    // Update the error state with the error message, if any
    setErrorFields({ ...errorFields, [key]: errorMessage });
  };

  useEffect(() => {
    // fetching master plan durations // packages // because this is independent of any params and will be needed for creating as well as editing the surge
    dispatch(rentalActions.getMasterPlanDurations());
    if (isEditing) {
      // edit // fetching surge details by id
      dispatch(surgeActions?.getSurgeById(surgeIdParams));
    }
  }, [surgeIdParams]);

  // calling branches api when city is available
  useEffect(() => {
    if (fields?.cityDisplayName) {
      if (!isEditing) {
        setFields({
          ...fields,
          branches: [],
          models: [],
        });
      }
      dispatch(
        rentalActions.getAllBranches({
          name: fields?.city,
          displayName: fields?.cityDisplayName,
        })
      );
    }
    //
    if (
      isObjectNotEmpty(surgeByIdData) &&
      !isArrayNotEmpty(surgeByIdData?.branches)
    ) {
      dispatch(
        rentalActions.getAllBranches({
          name: surgeByIdData?.city,
          displayName: surgeByIdData?.cityDisplayName,
        })
      );
    }
  }, [fields?.city, fields?.cityDisplayName, surgeByIdData]);

  // Vehicle model list api. this has to be called on branches change
  useEffect(() => {
    if (isArrayNotEmpty(fields?.branches) || fields?.city) {
      const payload: GenericObject = {
        branches: fields?.branches?.length ? fields?.branches : null,
        city: fields?.city,
      };
      dispatch(rentalActions.getModelListByBranch(payload));
    }
    //
    if (
      isObjectNotEmpty(surgeByIdData) &&
      !isArrayNotEmpty(surgeByIdData?.models)
    ) {
      const payload: GenericObject = {
        branches: surgeByIdData?.branches?.length
          ? surgeByIdData?.branches
          : null,
        city: surgeByIdData?.city,
      };
      dispatch(rentalActions.getModelListByBranch(payload));
    }
  }, [fields?.branches?.length, fields?.city, surgeByIdData]);

  // surge date type
  const handleSurgeDateType = (option: string) => {
    updateSurgeDetails("dateType", option);
  };
  // surge type
  const handleSurgeType = (option: string) => {
    updateSurgeDetails("surgeType", option);
  };

  // to handle weekdays
  const handleDaySelection = (item: string) => {
    let prevSelectedDays: any = deepCopy(fields?.daysOfWeek);
    if (prevSelectedDays.includes(item)) {
      prevSelectedDays = prevSelectedDays.filter(
        (prevInstant: string) => prevInstant !== item
      );
    } else {
      prevSelectedDays = [...prevSelectedDays, item];
    }
    updateSurgeDetails("daysOfWeek", prevSelectedDays);
  };

  // warehouse
  const handleWarehouse = (item: any, type: string = "") => {
    const itemName = item.name;
    let prevSelectedItems: any = fields.branches;
    if (type === rentalEn?.global?.all) {
      setShowMore({
        ...showMore,
        showMoreHubs: true,
      });
      prevSelectedItems = branches.map((item: any) => item.name);
    } else if (type === rentalEn?.global?.clear) {
      setShowMore({
        ...showMore,
        showMoreHubs: false,
      });
      prevSelectedItems = [];
    } else {
      if (prevSelectedItems.includes(itemName)) {
        prevSelectedItems = prevSelectedItems.filter(
          (name: string) => name !== itemName
        );
      } else {
        prevSelectedItems = [...prevSelectedItems, itemName];
      }
    }
    updateSurgeDetails("branches", prevSelectedItems);
  };

  // select vehicle model
  const handlevehicleModel = (item: any, type: string = "") => {
    const itemName = item.name;
    let prevSelectedItems: any = fields.models;
    if (type === rentalEn?.global?.all) {
      setShowMore({
        ...showMore,
        showMoreModels: true,
      });
      prevSelectedItems = modelListByBranch?.map((item: any) => item.name);
    } else if (type === rentalEn?.global?.clear) {
      setShowMore({
        ...showMore,
        showMoreModels: false,
      });
      prevSelectedItems = [];
    } else {
      if (prevSelectedItems.includes(itemName)) {
        prevSelectedItems = prevSelectedItems.filter(
          (name: string) => name !== itemName
        );
      } else {
        prevSelectedItems = [...prevSelectedItems, itemName];
      }
    }
    updateSurgeDetails("models", prevSelectedItems);
  };

  // function to select package
  const handlePackages = (item: any, type: string = "") => {
    const itemName = item;
    let prevSelectedItems: any = fields.durations;
    if (type === "all") {
      prevSelectedItems = masterPlanDurationsData.map((item: any) => item);
    } else if (type === "clear") {
      prevSelectedItems = [];
    } else {
      if (prevSelectedItems.includes(itemName)) {
        prevSelectedItems = prevSelectedItems.filter(
          (name: string) => name !== itemName
        );
      } else {
        prevSelectedItems = [...prevSelectedItems, itemName];
      }
    }
    updateSurgeDetails("durations", prevSelectedItems);
  };

  // function to select city
  const selectCityHandler = (item: any) => {
    setFields({
      ...fields,
      city: item?.name,
      cityDisplayName: item?.displayName,
    });
  };

  // common for create and edit // handle surge SPECIFIC date range selection
  const handleSpecificDateRangeSelection = (dateRange: any) => {
    let [startDateFromRangePicker, endDateFromRangePicker] = dateRange;
    setFields((prev: any) => ({
      ...prev,
      startDate: startDateFromRangePicker,
      endDate: endDateFromRangePicker,
    }));
  };

  // create // reset states based on the type
  const resetStatesByType = (type: any) => {
    if (type === "dateType") {
      setFields((prev: any) => ({
        ...prev,
        startDate: "",
        endDate: "",
        daysOfWeek: [],
      }));
    } else if (type === "surgeType") {
      setFields((prev: any) => ({
        ...prev,
        surgeValue: "",
      }));
      setErrorFields((prev: any) => ({
        ...errorFields,
        surgeValue: "",
      }));
    }
  };

  // validate 'Next' button
  const isNextButtonValid = (): boolean => {
    return isArrayNotEmpty(fields?.branches) && isArrayNotEmpty(fields?.models)
      ? true
      : false;
  };

  // validate 'Save' button
  const isSaveSurgeButtonDisabled = (): boolean => {
    // here // handle save surge button's enable-disable condition
    let hasError = false; // Initialize a variable to track if there's any error

    // Validate packages/durations
    if (!isArrayNotEmpty(fields?.durations)) {
      setErrorFields((prev: any) => ({
        ...prev,
        durations: rentalEn?.surgeManagement?.PleaseSelectPackage,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        durations: "",
      }));
    }

    // Validate surge dateType (validating the selection here)
    if (!fields?.dateType) {
      setErrorFields((prev: any) => ({
        ...prev,
        dateType: rentalEn?.surgeManagement?.PleaseSelectSurgeDateType,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        dateType: "",
      }));
    }

    // Validate surge dateType (validating the values here)
    if (
      fields?.dateType === rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
    ) {
      if (!fields?.startDate || !fields?.endDate) {
        setErrorFields((prev: any) => ({
          ...prev,
          startEndDate: rentalEn?.surgeManagement?.PleaseSelectStartAndEndDates,
        }));
        hasError = true; // Update hasError if there's an error
      } else {
        setErrorFields((prev: any) => ({
          ...prev,
          startEndDate: "",
        }));
      }
    } else {
      if (!isArrayNotEmpty(fields?.daysOfWeek)) {
        setErrorFields((prev: any) => ({
          ...prev,
          daysOfWeek: rentalEn?.surgeManagement?.PleaseSelectDaysOfWeek,
        }));
        hasError = true; // Update hasError if there's an error
      } else {
        setErrorFields((prev: any) => ({
          ...prev,
          daysOfWeek: "",
        }));
      }
    }

    // Validate surgeType (validating the selection here)
    if (!fields?.surgeType) {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeType: rentalEn?.surgeManagement?.PleaseSelectSurgeType,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeType: "",
      }));
    }

    // Validate surgeValue (validating the surge-value here)
    if (
      (fields?.surgeType ===
        rentalEn?.surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT &&
        (!fields?.surgeValue ||
          errorFields?.surgeValue ||
          fields?.surgeValue < 1)) ||
      (fields?.surgeType ===
        rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER &&
        (!fields?.surgeValue ||
          errorFields?.surgeValue ||
          fields?.surgeValue <= 1 ||
          fields?.surgeValue > 3))
    ) {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeValue: rentalEn?.surgeManagement?.EnterValidSurgeValue,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeValue: "",
      }));
    }

    // Validate selectedSurgeStatus
    if (!fields?.surgeStatus) {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeStatus: rentalEn?.surgeManagement?.PleaseSelectSurgeStatus,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeStatus: "",
      }));
    }

    // Validate surgeName
    if (!fields?.surgeName.trim() || errorFields?.surgeName) {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeName: rentalEn?.surgeManagement?.SurgeNameMustBeInProperEnglish,
      }));
      hasError = true; // Update hasError if there's an error
    } else {
      setErrorFields((prev: any) => ({
        ...prev,
        surgeName: "",
      }));
    }

    // Return boolean value based on whether there's an error
    return hasError;
  };

  const saveSurgeHandler = () => {
    let payloadObject: GenericObject = {};
    let payloadData: GenericObject = {};

    payloadData.city = fields?.city;
    payloadData.branches =
      fields?.branches?.length === branches?.length ? [] : fields?.branches;
    payloadData.models =
      fields?.models?.length === modelListByBranch?.length
        ? []
        : fields?.models;
    payloadData.durations =
      fields?.durations?.length === masterPlanDurationsData?.length
        ? []
        : fields?.durations;
    payloadData.dateType = fields?.dateType;
    if (
      fields?.dateType === rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
    ) {
      payloadData.startDate = moment(istToTimestamp(fields?.startDate))
        .startOf("day")
        .valueOf();
      payloadData.endDate = moment(istToTimestamp(fields?.endDate))
        .endOf("day")
        .valueOf();
    } else {
      payloadData.daysOfWeek = fields?.daysOfWeek;
    }
    payloadData.surgeType = fields?.surgeType;
    payloadData.surgeValue =
      fields?.surgeType !== rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
        ? rupeeToPaisa(fields?.surgeValue)
        : fields?.surgeValue;
    payloadData.surgeStatus = fields?.surgeStatus;
    payloadData.surgeName = fields?.surgeName.trim();

    // Creating the payload object as required for the api.
    payloadObject.data = payloadData;

    if (isEditing) {
      // update/edit surge action
      delete payloadObject.data.city;
      delete payloadObject.data.surgeStatus;
      delete payloadObject.data.dateType;
      payloadObject.data.surgeId = surgeByIdData?.surgeId;
      payloadObject = { navigate, payloadObject };
      if (!isSaveSurgeButtonDisabled()) {
        dispatch(surgeActions.updateSurge(payloadObject));
      }
    } else {
      // create surge action
      payloadObject = { navigate, payloadObject };
      if (!isSaveSurgeButtonDisabled()) {
        dispatch(surgeActions.createSurge(payloadObject));
      }
    }
  };

  const tabsArray: GenericObject[] = [
    {
      label: rentalEn?.surgeManagement?.SurgeSettins,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: null,
      tabStyle: { ...StyleObject?.createSurge_tabStyle1 },
      // startIcon: (
      //   <RoundedOne color={selectedTab === 0 ? colors?.white : colors?.black} />
      // ),
    },
    {
      label: rentalEn?.surgeManagement?.SurgeConfiguration,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: !isNextButtonValid(),
      tabStyle: { ...StyleObject?.createSurge_tabStyle2 },
      // startIcon: (
      //   <RoundedTwo
      //     color={
      //       selectedTab === 1
      //         ? colors?.white
      //         : isNextButtonValid()
      //         ? colors?.black
      //         : colors?.gray
      //     }
      //   />
      // ),
    },
  ];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    tabsArray[index].onClick(); // Execute onPress function for the selected tab
  };

  const handleEditButtonClick = (
    surgeByIdData: GenericObject,
    branches: GenericObject[],
    modelListByBranch: GenericObject[],
    masterPlanDurationsData: number[]
  ) => {
    setCurrentSection("create");
    setSelectedTab(0);
    // update fields with the existing surge data
    setFields(() => ({
      ...surgeByIdData,
      branches: isArrayNotEmpty(surgeByIdData?.branches)
        ? surgeByIdData?.branches
        : branches?.map((branch: any) => branch?.name),
      branchesDisplayName: isArrayNotEmpty(surgeByIdData?.branchesDisplayName)
        ? surgeByIdData?.branchesDisplayName
        : branches?.map((branch1: any) => branch1?.displayName),
      models: isArrayNotEmpty(surgeByIdData?.models)
        ? surgeByIdData?.models
        : modelListByBranch?.map((model: any) => model?.name),
      modelsDisplayName: isArrayNotEmpty(surgeByIdData?.modelsDisplayName)
        ? surgeByIdData?.modelsDisplayName
        : modelListByBranch?.map((model1: any) => model1?.displayName),
      durations: isArrayNotEmpty(surgeByIdData?.durations)
        ? surgeByIdData?.durations
        : masterPlanDurationsData,
      surgeValue:
        surgeByIdData?.surgeType !==
        rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
          ? paisaToRupee(surgeByIdData?.surgeValue)
          : surgeByIdData?.surgeValue,
    }));
  };

  return (
    <Box sx={StyleObject?.createSurge_wrapperBox}>
      <Box>
        {/* top tab view */}
        {currentSection === "create" && (
          <CustomButtonGroup
            handleTabChange={handleTabChange}
            tabsArray={tabsArray}
          />
        )}
        {/* tab's content view */}
        {currentSection === "create" && (
          <Box sx={StyleObject?.createSurge_tabView_wrapperBox}>
            {onLoad ? (
              <FullScreenLoader loading={onLoad} />
            ) : selectedTab === 0 ? (
              <SurgeSettings
                fields={fields}
                allCitiesDetails={allCitiesDetails}
                modelListByBranch={modelListByBranch}
                isEditing={isEditing}
                surgeByIdData={surgeByIdData}
                branches={branches}
                selectCityHandler={selectCityHandler}
                handleWarehouse={handleWarehouse}
                handlevehicleModel={handlevehicleModel}
                showMore={showMore}
                viewMoreHandler={viewMoreHandler}
              />
            ) : selectedTab === 1 ? (
              <SurgeConfiguration
                fields={fields}
                errorFields={errorFields}
                handleSpecificDateRangeSelection={
                  handleSpecificDateRangeSelection
                }
                activeInactiveStatusArray={activeInactiveStatusArray}
                isEditing={isEditing}
                surgeByIdData={surgeByIdData}
                masterPlanDurationsData={masterPlanDurationsData}
                handlePackages={handlePackages}
                handleSurgeType={handleSurgeType}
                updateSurgeDetails={updateSurgeDetails}
                daysOfWeek={daysOfWeek}
                handleDaySelection={handleDaySelection}
                handleSurgeDateType={handleSurgeDateType}
                resetStatesByType={resetStatesByType}
              />
            ) : null}
          </Box>
        )}
        {currentSection === "view" && (
          <Box sx={StyleObject?.createSurge_tabView_wrapperBox}>
            {onLoad ? (
              <FullScreenLoader loading={onLoad} />
            ) : (
              <ViewSurge
                surgeByIdData={surgeByIdData}
                handleEditButtonClick={handleEditButtonClick}
                branches={branches}
                modelListByBranch={modelListByBranch}
                masterPlanDurationsData={masterPlanDurationsData}
              />
            )}
          </Box>
        )}
      </Box>
      {/* bottom button view */}
      {currentSection === "create" && (
        <Box
          sx={{
            ...StyleObject?.createSurge_bottomButtonView_wrapperBox,
            justifyContent: "space-between",
          }}
        >
          {selectedTab === 1 && (
            <CustomButton
              label={rentalEn?.buttonLabels?.previous}
              variant="contained"
              sx={{ minWidth: 200 }}
              onClick={() => setSelectedTab(0)}
              // startIcon={<WestTwoToneIcon />}
            />
          )}
          {selectedTab === 0 && (
            <CustomButton
              label={rentalEn?.buttonLabels?.goBack}
              variant="contained"
              sx={{ minWidth: 200 }}
              onClick={() => setOpenDialog(true)}
              // startIcon={<WestTwoToneIcon />}
            />
          )}
          {selectedTab === 0 && (
            <CustomButton
              label={rentalEn?.buttonLabels?.next}
              variant={isNextButtonValid() ? "contained" : "outlined"}
              sx={{ minWidth: 200 }}
              onClick={() => setSelectedTab(1)}
              // endIcon={<EastTwoToneIcon />}
              disabled={!isNextButtonValid()}
            />
          )}
          {selectedTab === 1 && (
            <CustomButton
              label={rentalEn?.buttonLabels?.saveSurge}
              variant="contained"
              sx={{ minWidth: 200 }}
              onClick={saveSurgeHandler}
              loading={addEditSurgeLoader}
            />
          )}
        </Box>
      )}
      {
        <ResponsiveDialog
          body={rentalEn?.global?.areYouSureYouWantToGoBack}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          children={
            <DialogActions>
              <CustomButton
                label={rentalEn?.global?.no}
                variant="outlined"
                sx={{ minWidth: 150 }}
                onClick={() => setOpenDialog(false)} // on 'No' click, close the dialog
              />
              <CustomButton
                label={rentalEn?.global?.yes}
                variant="contained"
                sx={{ minWidth: 150 }}
                onClick={() => navigate(-1)} // on 'Yes' click, navigate to previous screen
              />
            </DialogActions>
          }
        />
      }
    </Box>
  );
};

export default CreateSurge;
