import { v4 as uuidv4 } from "uuid";
import { isArrayNotEmpty } from "../../../../utils/helper";
import {
  GenericObject,
  WAREHOUSE_DELIVERY_TYPES,
} from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
import { appendCommaSpace } from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";

const { global } = en;

const initialState = {
  warehouseOverview: {},
  cityOverview: {},
  cityList: {},
  cityListLoader: false,
  cityStatus: {},
  closeSideDrawer: false,
  signedUrl: [],
  warehouseList: {},
  warehouseListLoader: false,
  warehouseDetail: {},
  cityDetails: {},
  isWarehouseUpdated: false,
  warehouseCreated: false,
  saveCityLoader: false,
  warehouseScreenLoader: false,
  imageLoader: false,
  isPolygonAddedEdited: false,
  isPolygonDeleted: false,
  addPolygonLoader: false,
  editPolygonLoader: false,
  deletePolygonLoader: false,
  getPolygonLoader: false,
  polygonsData: [],
  deliveryTypes: [
    {
      name: WAREHOUSE_DELIVERY_TYPES.CITY,
      displayName: en.NewBusinessManagement.sameChargeCity,
    },
    {
      name: WAREHOUSE_DELIVERY_TYPES.POLYGON,
      displayName: en.NewBusinessManagement.specificChargesArea,
    },
  ],
  isWarehouseCreated: false,
  getDeliveryChargesLoader: false,
  disableWarehouseFields: false,
  cityDetailsLoader: false,
  addUserResponseData: {}, // add new user response - role management
  addUpdateUserLoader: false, // add/ update user loader - role management
  rolesListData: [], // roles list - role management
  rolesListDataLoader: false, // roles list loader - role management
  //
  initialAssignRoleState: [
    {
      roleId: { _id: "", name: "" }, // "OPERATIONS_MASTER",
      operatorIds: [], // Get it from the authorize api
      //
      state: [], // Holds api data
      selectedState: [], // Holds user state
      city: [], // Holds api data
      selectedCity: [], // Holds user city
      branch: [], // Holds api data
      selectedBranch: [], // Holds user branch
      uuid: uuidv4(),
    },
  ],
  apiResponseData: {},
  allUsersListApiResponseData: {}, // all users list - entire api response data - role management
  allUsersListData: [], // all users list - role management
  allUsersListLoader: false, // all user list loader - role management
  userDetailsByUserIdData: {}, // user details by userId - role management
  userDetailsByUserIdDataLoader: false, // user details by userId loader - role management
  helmetConfigList: {},
  helmetConfig: {},
  isHelmetConfigSaveOrUpdate: false,
  helmetSaveUpdateLoader: false,
  helmetTableLoader: false,
  screenLoader: false,
  blockUserSuccessData: {}, // Block User Response Data - Role Management
  blockUserLoader: false, // block user loader - role management
  unblockUserSuccessData: {}, // Unblock User Response Data - Role Management
  unblockUserLoader: false, // unblock user loader - role management
  deleteUserSuccessData: {}, // Delete User Response Data - Role Management
  deleteUserLoader: false, // delete user loader - role management
  updateUserResponse: {}, // update user response - role management
  userBlockReasonData: [], // block reasons - role mnagement
  userBlockReasonLoader: false, // block reasons loader - role mnagement
};

// Sample assign role object - Role Management
const newRoleRow: any = {
  roleId: { _id: "", name: "" },
  operatorIds: [],
  //
  state: [],
  selectedState: [],
  city: [],
  selectedCity: [],
  branch: [],
  selectedBranch: [],
  uuid: uuidv4(),
};

const newBusinessManagementReducer = (state = initialState, action: any) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_SUCCESS:
      return {
        ...state,
        cityOverview: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_OVERVIEW_FAILED:
      return {
        ...state,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .GET_WAREHOUSE_OVERVIEW_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_OVERVIEW_SUCCESS:
      return {
        ...state,
        warehouseOverview: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_OVERVIEW_FAILED:
      return {
        ...state,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_REQUESTED:
      return {
        ...state,
        cityListLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: payload,
        cityListLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_LIST_FAILED:
      return {
        ...state,
        cityListLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_REQUESTED:
      return {
        ...state,
        closeSideDrawer: false,
        saveCityLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_SUCCESS:
      return {
        ...state,
        closeSideDrawer: true,
        signedUrl: [],
        cityDetails: {},
        saveCityLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_NEW_CITY_FAILED:
      return {
        ...state,
        closeSideDrawer: false,
        signedUrl: [],
        saveCityLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_REQUESTED:
      return {
        ...state,
        closeSideDrawer: false,
        saveCityLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_SUCCESS:
      return {
        ...state,
        closeSideDrawer: true,
        signedUrl: [],
        cityDetails: {},
        saveCityLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_CITY_FAILED:
      return {
        ...state,
        closeSideDrawer: false,
        saveCityLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_REQUESTED:
      return {
        ...state,
        imageLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_SUCCESS:
      return {
        ...state,
        signedUrl: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_SIGNED_URL_FAILED:
      return {
        ...state,
        imageLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.IMAGE_UPLOADED_S3_SUCCESS:
      return {
        ...state,
        imageLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.IMAGE_UPLOADED_S3_FAILED:
      return {
        ...state,
        imageLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_REQUESTED:
      return {
        ...state,
        warehouseCreated: false,
        polygonsData: [],
        disableWarehouseFields: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseDetail: payload,
        warehouseCreated: true,
        disableWarehouseFields: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CREATE_WAREHOUSE_FAILED:
      return {
        ...state,
        warehouseCreated: false,
        disableWarehouseFields: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_REQUESTED:
      return {
        ...state,
        warehouseDetail: {},
        isWarehouseUpdated: false,
        warehouseCreated: false,
        warehouseListLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_REQUESTED:
      return { ...state, addPolygonLoader: true, isPolygonAddedEdited: false };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_SUCCESS:
      return { ...state, addPolygonLoader: false, isPolygonAddedEdited: true };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_ADD_POLYGON_FAILED:
      return { ...state, addPolygonLoader: false, isPolygonAddedEdited: false };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_REQUESTED:
      return { ...state, polygonsData: [], getDeliveryChargesLoader: true };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_SUCCESS:
      return {
        ...state,
        isPolygonAddedEdited: false,
        polygonsData: payload,
        getDeliveryChargesLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_GET_POLYGONS_FAILED:
      return {
        ...state,
        isPolygonAddedEdited: false,
        polygonsData: [],
        getDeliveryChargesLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .RENTALS_DELETE_POLYGON_REQUESTED:
      return { ...state };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_DELETE_POLYGON_FAILED:
      return { ...state };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_DELETE_POLYGON_SUCCESS:
      return { ...state };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_REQUESTED:
      return { ...state, isPolygonAddedEdited: false };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_SUCCESS:
      return { ...state, isPolygonAddedEdited: true };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.RENTALS_EDIT_POLYGON_FAILED:
      return { ...state, isPolygonAddedEdited: false };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_SUCCESS:
      return {
        ...state,
        warehouseList: payload,
        warehouseListLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_WAREHOUSE_LIST_FAILED:
      return {
        ...state,
        warehouseListLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_REQUESTED:
      return {
        ...state,
        isWarehouseUpdated: false,
        disableWarehouseFields: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        isWarehouseUpdated: true,
        disableWarehouseFields: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_BRANCH_FAILED:
      return {
        ...state,
        isWarehouseUpdated: false,
        disableWarehouseFields: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_REQUESTED:
      return {
        ...state,
        isWarehouseUpdated: false,
        warehouseScreenLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseDetail: payload,
        warehouseScreenLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.VIEW_WAREHOUSE_FAILED:
      return {
        ...state,
        warehouseScreenLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_REQUESTED:
      return {
        ...state,
        cityDetails: {},
        cityDetailsLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_SUCCESS:
      return {
        ...state,
        cityDetails: payload,
        cityDetailsLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_CITY_DETAILS_FAILED:
      return {
        ...state,
        cityDetailsLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CLEAR_CITY_DETAILS:
      return {
        ...state,
        cityDetails: {},
      };

    // Add New User - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_REQUESTED:
      return {
        ...state,
        addUserResponseData: {},
        addUpdateUserLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_SUCCESS:
      return {
        ...state,
        addUserResponseData: payload,
        addUpdateUserLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_USER_FAILED:
      return {
        ...state,
        addUserResponseData: {},
        addUpdateUserLoader: false,
      };

    // Get Roles List - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_REQUESTED:
      return {
        ...state,
        rolesListData: [],
        rolesListDataLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        rolesListData: payload,
        rolesListDataLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_ROLES_LIST_FAILED:
      return {
        ...state,
        rolesListData: [],
        rolesListDataLoader: false,
      };

    // handleChange - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_REQUESTED:
      let updatedData: any = [];
      if (payload?.key === "reset") {
        updatedData = [newRoleRow];
      }
      return {
        ...state,
        initialAssignRoleState:
          payload?.key === "reset"
            ? [...updatedData]
            : [...state?.initialAssignRoleState],
        addUserResponseData:
          payload?.key === "reset" ? {} : { ...state?.addUserResponseData },
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_EXISTING_USER_ROLES:
      return {
        ...state,
        initialAssignRoleState: [...payload],
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS:
      let updatedState: any = [];
      //
      if (payload?.key === "roleId") {
        updatedState = [...payload?.initialAssignRoleState];
      } else if (payload?.key === "state") {
        updatedState = payload?.initialAssignRoleState?.map(
          (item: any, i: number) =>
            item?.uuid === payload?.uuid
              ? {
                  ...item,
                  state: [...payload?.value],
                }
              : item
        );
      } else if (payload?.key === "STATE") {
        updatedState = [...payload?.initialAssignRoleState];
      } else if (payload?.key === "city") {
        updatedState = payload?.initialAssignRoleState?.map(
          (item: any, i: number) =>
            item?.uuid === payload?.uuid
              ? {
                  ...item,
                  city: [...payload?.value],
                }
              : item
        );
      } else if (payload?.key === "CITY") {
        updatedState = [...payload?.initialAssignRoleState];
      } else if (payload?.key === "hubs") {
        updatedState = payload?.initialAssignRoleState?.map(
          (item: any, i: number) =>
            item?.uuid === payload?.uuid
              ? {
                  ...item,
                  branch: [...payload?.value],
                }
              : item
        );
      } else if (payload?.key === "BRANCH") {
        updatedState = [...payload?.initialAssignRoleState];
      }
      //
      return {
        ...state,
        initialAssignRoleState: [...updatedState],
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_FAILED:
      return {
        ...state,
      };

    // Add New Role Row - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_NEW_ROLE_ROW_REQUESTED:
      //
      const getUniqueRolesList = (
        myArr: GenericObject[],
        roleArr: GenericObject[]
      ): GenericObject[] => {
        // Extract role IDs from myArr
        const roleIdsInMyArr: GenericObject[] = myArr
          .filter((item: any) => item?.roleId && item?.roleId?._id) // Ensure roleId exists
          .map((item: any) => item?.roleId?._id); // Extract the role IDs

        // Filter roleArr based on the extracted role IDs
        const filteredRoles: GenericObject[] = roleArr?.filter(
          (role: any) => !roleIdsInMyArr.includes(role?._id)
        );

        // Return the filtered roles
        return filteredRoles;
      };
      //
      let addedRoleRow: GenericObject[] = [
        ...state?.initialAssignRoleState,
        {
          ...newRoleRow,
          operation: "add",
          uuid: uuidv4(),
          rolesList: getUniqueRolesList(
            state?.initialAssignRoleState,
            state?.rolesListData
          ),
        },
      ];
      return {
        ...state,
        initialAssignRoleState: [...addedRoleRow],
      };

    // Delete Role Row - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_ROLE_ROW_REQUESTED:
      const updatedRoles = state?.initialAssignRoleState
        ?.map((role: any, i) => {
          if (payload === role.uuid) {
            if (role?.operation === "update")
              return { ...role, isDeleted: true, operation: "delete" };
            else return "delete";
          } else {
            return role;
          }
        })
        .filter((x) => x !== "delete");
      return {
        ...state,
        initialAssignRoleState: [...updatedRoles],
      };

    // Save Role Data - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.SAVE_ROLE_DATA_FAILED:
      return {
        ...state,
      };

    // Fetch User List - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_REQUESTED:
      return {
        ...state,
        allUsersListLoader: true,
        allUsersListApiResponseData: {},
        allUsersListData: [],
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_SUCCESS:
      //
      const modifyUserListData = (data: any) => {
        let tempUserListData: any = [];

        tempUserListData = data?.map((item: any) => {
          item.assignedRoles = isArrayNotEmpty(item?.roles)
            ? item?.roles?.map((role: any) => role?.roleName)
            : [];

          return item;
        });

        return tempUserListData;
      };
      //
      const modifiedUserListData = modifyUserListData(payload?.userData);
      return {
        ...state,
        allUsersListLoader: false,
        allUsersListApiResponseData: payload,
        allUsersListData: [...modifiedUserListData],
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.FETCH_ALL_USERS_LIST_FAILED:
      return {
        ...state,
        allUsersListLoader: false,
        allUsersListApiResponseData: {},
        allUsersListData: [],
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .GET_HELMET_CONFIG_LIST_REQUESTED:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: false,
        helmetTableLoader: true,
        helmetConfigList: {},
        helmetConfig: {},
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        helmetConfigList: payload,
        helmetTableLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_LIST_FAILED:
      return {
        ...state,
        helmetTableLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_REQUESTED:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: false,
        helmetSaveUpdateLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_SUCCESS:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: true,
        helmetSaveUpdateLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.ADD_HELMET_CONFIG_FAILED:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: false,
        helmetSaveUpdateLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_REQUESTED:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: false,
        helmetSaveUpdateLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_SUCCESS:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: true,
        helmetSaveUpdateLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_HELMET_CONFIG_FAILED:
      return {
        ...state,
        isHelmetConfigSaveOrUpdate: false,
        helmetSaveUpdateLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_REQUESTED:
      return {
        ...state,
        screenLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_SUCCESS:
      return {
        ...state,
        helmetConfig: payload,
        screenLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_HELMET_CONFIG_FAILED:
      return {
        ...state,
        screenLoader: false,
      };

    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.EDIT_CURRENT_HELMET_CONFIG:
      return {
        ...state,
        helmetConfig: payload ?? {},
      };

    // Fetch User Details by UserId - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .FETCH_USER_DETAILS_BY_USER_ID_REQUESTED:
      return {
        ...state,
        userDetailsByUserIdData: {},
        userDetailsByUserIdDataLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .FETCH_USER_DETAILS_BY_USER_ID_SUCCESS:
      //
      // Function to transform the data
      const transformData = (data: any) => {
        return {
          ...data,
          roles: data?.roles?.map((role: any) => ({
            ...role,
            modifiedBranches: role?.branches?.length
              ? appendCommaSpace(
                  role?.branches.map((branch: any) => branch.name)
                )
              : global?.NA,
            modifiedCities: role?.cities?.length
              ? appendCommaSpace(role?.cities.map((city: any) => city.name))
              : global?.NA,
            modifiedStates: role?.states?.length
              ? appendCommaSpace(role?.states?.map((state: any) => state.name))
              : global?.NA,
          })),
        };
      };
      const modifiedPayload: any = transformData(payload);
      //
      return {
        ...state,
        userDetailsByUserIdData: { ...modifiedPayload },
        userDetailsByUserIdDataLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS
      .FETCH_USER_DETAILS_BY_USER_ID_FAILED:
      return {
        ...state,
        userDetailsByUserIdData: {},
        userDetailsByUserIdDataLoader: false,
      };

    // Block User - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_REQUESTED:
      return {
        ...state,
        blockUserLoader: true,
        blockUserSuccessData: {},
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_SUCCESS:
      return {
        ...state,
        blockUserLoader: false,
        blockUserSuccessData: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.BLOCK_USER_FAILED:
      return {
        ...state,
        blockUserLoader: false,
        blockUserSuccessData: {},
      };

    // Unblock User - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_REQUESTED:
      return {
        ...state,
        unblockUserLoader: true,
        unblockUserSuccessData: {},
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        unblockUserLoader: false,
        unblockUserSuccessData: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UNBLOCK_USER_FAILED:
      return {
        ...state,
        unblockUserLoader: false,
        unblockUserSuccessData: {},
      };

    // Delete User - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_REQUESTED:
      return {
        ...state,
        deleteUserLoader: true,
        deleteUserSuccessData: {},
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoader: false,
        deleteUserSuccessData: payload,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.DELETE_USER_FAILED:
      return {
        ...state,
        deleteUserLoader: false,
        deleteUserSuccessData: {},
      };

    // Update User - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_REQUESTED:
      return {
        ...state,
        updateUserResponse: {},
        addUpdateUserLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserResponse: payload,
        addUpdateUserLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserResponse: {},
        addUpdateUserLoader: false,
      };

    // Clear Role Related Data - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.CLEAR_DATA_REQUESTED:
      return {
        ...state,
        ...payload,
      };

    // Get Block Reasons - Role Management
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_REQUESTED:
      return {
        ...state,
        userBlockReasonData: [],
        userBlockReasonLoader: true,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_SUCCESS:
      return {
        ...state,
        userBlockReasonData: payload,
        userBlockReasonLoader: false,
      };
    case actionTypes.BUSINESS_MANAGEMENT_ACTIONS.GET_BLOCK_REASONS_FAILED:
      return {
        ...state,
        userBlockReasonData: [],
        userBlockReasonLoader: false,
      };

    default:
      return { ...prevState };
  }
};

export default newBusinessManagementReducer;
