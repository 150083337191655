import "../newAuthCss/global.css";
import "../newAuthCss/layout.css";
import "../../../css/customcss/font.css";
import { AdminLogo } from "../../../assets/exportImages";
import en from "../../../locale/en.json";

function AuthContainer(props: any) {
  return (
    <div className="login-wrapper">
      <div className="container-fluid">
        <div className="login-box d-flex align-items-center">
          <div className="login-left">
            <a>
              <img src={AdminLogo} alt="" />
            </a>
            <div data-aos="fade-up">
              <h1>{en?.newAuth?.freedoAdmin}</h1>
              <p>{en?.newAuth?.freedoAdminTagline}</p>
            </div>

            <div className="copyright-txt">{en?.newAuth?.copyrights}</div>
          </div>
          <div className="login-right" data-aos="fade-right">
            <div className="crediential-card">
              <h2>{props?.heading}</h2>
              <form autoComplete="off">{props?.componentFields}</form>
            </div>
            {props.policyBox}
          </div>
          {props?.messagePopUp}
        </div>
      </div>
    </div>
  );
}

export { AuthContainer };
