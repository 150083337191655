import { Box, Skeleton, Stack } from "@mui/material";

const UploadDocumentLoader = () => {
  return (
    <Box>
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="20%"
        height="20vh"
      />
    </Box>
  );
};

export default UploadDocumentLoader;
