import { AuthContainer } from "./components/authContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { AuthInput } from "./components/authInputs";
import { AuthButton } from "./components/authButton";
import { MessagePopUp } from "./components/messagePopUp";
import { useState } from "react";
import { ContainerMsg } from "./components/authContainerMsg";
import { checkNumeric } from "../../utils/regex";
import { lengthValidate, mobileNumberValidate } from "../../utils/helper";

function MultifactorAuth() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const [field, setField] = useState<string>("");
  const [errfield, setErrfield] = useState<string>();
  const onChange = (e: any) => {
    const { value } = e.target;
    setField(lengthValidate(mobileNumberValidate(value), 6));
    value.length < 6
      ? setErrfield(locale?.newAuth?.enterOtpErr)
      : setErrfield("");
  };
  return (
    <>
      <AuthContainer
        heading={locale?.newAuth?.multifactorAuth}
        componentFields={
          <>
            <ContainerMsg />
            <AuthInput
              value={field}
              label={locale?.newAuth?.otp}
              placeholder={locale?.newAuth?.enterOtp}
              className={
                /* errorMessage != ""
                  ? "form-control error-input"
                  : */ "form-control"
              }
              onChange={(e: any) => onChange(e)}
              errorMessage={errfield}
            />
            <AuthButton
              type="submit"
              title={locale?.newAuth?.submitOtp}
              loading={"true"}
              className={
                errfield != ""
                  ? "btn w-100 login-btn disable mt-3"
                  : "btn w-100 login-btn mt-3"
              }
            />
          </>
        }
        messagePopUp={<MessagePopUp />}
      />
    </>
  );
}

export default MultifactorAuth;
