import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Chip,
  Fade,
  FormControlLabel,
  Slide,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { useDispatch } from "react-redux";
import {
  clearBreadcrumbs,
  getAllStates,
  getCityByState,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import moment from "moment";
import {
  getWarehouseList,
  updateWarehouse,
  viewWarehouse,
} from "../../../redux/actions/businessManagementActions";
import { routesConstants } from "../../../utils/RoutesConstants";
import {
  FREEDOOPERATORID,
  STATUS,
  activeInactiveStatusArray,
  cityStatus,
} from "../../../constants/constants";
import { convertTo12hrs, toPascalCase } from "../../../utils/helper";
import { decrypt } from "../../../../utils/helper";
import en from "../../../locale/rental-en.json";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  changeStatusIcon,
  CityInputIcon,
  stateIcon,
  WarehouseIcon,
} from "../../../constants/exportImages";
import { AntSwitch } from "../../../../sharedComponents/molecules/MuiSwitch";
import { colors } from "../../../../themes/colors";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  modules,
  planFunctionalities,
  warehouseFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";

const WarehouseManagement = (props: any) => {
  // rbac implementation
  const RbacHasAccess = {
    listWarehouse: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.LIST_WAREHOUSES
    ),
    addWarehouse: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.ADD_WAREHOUSE
    ),
    viewWarehouse: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.VIEW_WAREHOUSE
    ),
    editWarehouse: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.EDIT_WAREHOUSE
    ),
  };
  const navigate = useNavigate();
  const [filters, setFilters] = useState<any>({
    paginationModel: { page: 0, pageSize: 10 },
    states: {
      name: "",
      displayName: en.global.allState,
    },
    city: {
      name: "",
      displayName: en.global.allCity,
    },
    status: {
      name: "",
      displayName: en.global.allStatus,
    },
  });
  const [dialogData, setDialogData] = useState({
    openDialog: false,
    status: "",
    name: "",
    body: "",
  });
  const {
    allCitiesDetails,
    allStatesList = [],
    stateBasedCities = [],
  } = useSelector((state: RootState) => state.rentalsCommonReducer);
  const { shouldScreenRefresh } = useSelector(
    (state: RootState) => state.commonReducer
  );

  const { userData } = useSelector((state: RootState) => state.newAuthReducer);
  const authorizedOperators = userData?.authorizedOperators || [];
  const operatorId = authorizedOperators[0] || "";

  const isFreedoOperator = operatorId === FREEDOOPERATORID;

  const { warehouseList, isWarehouseUpdated, warehouseListLoader } =
    useSelector((state: RootState) => state.newBusinessManagementReducer);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    getFilterDataFromLocalStorage("warehouseListFilters").then((data: any) => {
      if (data) {
        let localdata = { ...data };
        localdata.paginationModel = {
          page: 0,
          pageSize: 10,
        };
        setFilters((prev: any) => ({
          ...prev,
          ...localdata,
        }));
        if (data?.states?.name) {
          dispatch(getCityByState(data.states.name));
        }
      }
    });
    dispatch(getAllStates());
    if (location?.state == "OVERVIEW_HUB") {
      dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OVERVIEW_HUB));
    }
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, [shouldScreenRefresh]);
  const getRowId = (row: any) => {
    return row?.name;
  };
  const handleFilterChange = (fields: string, value: any) => {
    setFilters((prevFields: any) => ({
      ...prevFields,
      [fields]: value,
      paginationModel: { page: 0, pageSize: filters.paginationModel?.pageSize },
    }));
    if (fields === "states") {
      if (value?.name) {
        dispatch(getCityByState(value?.name));
      } else {
        dispatch(getAllStates());
      }
      setFilters((prev: any) => ({
        ...prev,
        city: {
          name: "",
          displayName: "",
        },
      }));
    }
  };

  //get warehouse List with filters
  useEffect(() => {
    let data: any = {
      warehouseListFilters: {
        ...filters,
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);
    dispatch(
      getWarehouseList({
        page: filters.paginationModel.page + 1,
        limit: filters.paginationModel.pageSize,
        state: filters.states.name,
        city: filters.city.name,
        status: filters.status.name,
      })
    );
  }, [filters.status, filters.states, filters.city, filters.paginationModel]);

  const columns: any = [
    {
      field: "displayName",
      headerName: "Hub Name",
      headerAlign: "center",
      align: "center",
      flex: 2,
      headerClassName: "hideRightSeparator",
    },
    {
      field: "address",
      headerName: "Location",
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      valueFormatter: (data: any) => {
        return data?.addressLine1 ?? "N/A";
      },
    },
    {
      field: "cityDisplayName",
      headerAlign: "center",
      align: "center",
      headerName: "City",
      flex: 1,
      headerClassName: "hideRightSeparator",
    },
    {
      field: "launchDate",
      headerName: "Launch Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "hideRightSeparator",
      valueFormatter: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      field: "config",
      headerName: "Working Hours",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      headerClassName: "hideRightSeparator",
      valueFormatter: (data: any) => {
        return data?.operationalHours
          ? convertTo12hrs(data?.operationalHours?.start) +
              "-" +
              convertTo12hrs(data?.operationalHours?.end)
          : en.global.NA;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      type: "actions",
      headerClassName: "hideRightSeparator",
      getActions: (data: GridRenderCellParams<any>) => {
        return [
          <FormControlLabel
            value={data?.row?.status}
            control={
              <AntSwitch
                color="secondary"
                checked={data?.row.status == STATUS.ACTIVE ? true : false}
                onChange={(e) => {
                  e.stopPropagation();
                  toggleUpdateStatusDialog({
                    ...data?.row,
                    body:
                      data?.row.status === STATUS.ACTIVE
                        ? en.NewBusinessManagement.inactivateHubStatus
                        : en.NewBusinessManagement.activateHubStatus,
                  });
                }}
                disabled={!RbacHasAccess.editWarehouse || !isFreedoOperator}
              />
            }
            label={
              <Typography
                sx={{
                  color:
                    data?.row.status === STATUS.ACTIVE
                      ? colors.secondary_green
                      : colors.primary_danger,
                  padding: "5px",
                }}
              >
                {toPascalCase(data?.row.status)}
              </Typography>
            }
            labelPlacement="start"
          />,
        ];
      },
    },
  ];

  //Navigate on add warehouse
  const handleAddWarehouse = () => {
    navigate(routesConstants.ADD_BUSINESS_WAREHOUSE_MANAGEMENT, {
      state: location?.state,
    });
  };
  const handlePaginationModelChange = (paginationModel: any) => {
    const { page, pageSize } = paginationModel;

    setFilters((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));
  };

  const handleWarehouseStatus = () => {
    dispatch(
      updateWarehouse({
        data: {
          data: {
            status:
              dialogData.status == STATUS.ACTIVE
                ? STATUS.INACTIVE
                : STATUS.ACTIVE,
          },
        },
        filters: {
          page: filters.paginationModel.page + 1,
          limit: filters.paginationModel.pageSize,
          state: filters.states.name,
          city: filters.city.name,
          status: filters.status.name,
        },
        branchName: dialogData?.name,
      })
    );
  };

  const toggleUpdateStatusDialog = (
    planData: any = {},
    updateStatus: boolean = false
  ) => {
    if (updateStatus) {
      handleWarehouseStatus();
    }
    setDialogData((prev) => {
      return { ...prev, openDialog: !prev.openDialog, ...planData };
    });
  };

  const handleRowClick = (params: any, event: any) => {
    RbacHasAccess.viewWarehouse &&
      navigate(
        `${routesConstants.EDIT_BUSINESS_WAREHOUSE_MANAGEMENT}${params.id}`,
        { state: location?.state }
      );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            {RbacHasAccess.addWarehouse && (
              <CustomButton
                variant="outlined"
                label="Add Hub"
                onClick={handleAddWarehouse}
                style={{ height: "40px", borderRadius: "10px" }}
              />
            )}
          </Box>
          <Box>
            {RbacHasAccess?.listWarehouse && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ alignSelf: "center" }}>
                  {warehouseList?.pagination?.totalRecords ?? 0} Results
                </Typography>
                <Box>
                  <CustomSelect
                    icon={stateIcon}
                    width="160px"
                    placeholder={en.NewBusinessManagement.selectState}
                    value={filters.states.displayName}
                    choice={
                      allStatesList?.length
                        ? [
                            { name: "", displayName: en.global.allState },
                            ...allStatesList,
                          ]
                        : allStatesList
                    }
                    defaultKey={"displayName"}
                    required
                    handleChange={(state: any) => {
                      handleFilterChange("states", state);
                    }}
                  />
                </Box>
                <Box>
                  <CustomSelect
                    icon={CityInputIcon}
                    width="160px"
                    placeholder={en.NewBusinessManagement.selectCity}
                    value={
                      filters.city.displayName == ""
                        ? en.global.allCity
                        : filters.city.displayName
                    }
                    choice={[
                      { name: "", displayName: en.global.allCity },
                      ...stateBasedCities,
                    ]}
                    defaultKey={"displayName"}
                    required
                    handleChange={(city: any) => {
                      handleFilterChange("city", city);
                    }}
                    disabled={!stateBasedCities?.length}
                  />
                </Box>
                <Box>
                  <CustomSelect
                    icon={changeStatusIcon}
                    width="160px"
                    placeholder={en.NewBusinessManagement.selectStatus}
                    value={filters.status.displayName}
                    choice={[
                      { name: "", displayName: en.global.allStatus },
                      ...activeInactiveStatusArray,
                    ]}
                    defaultKey={"displayName"}
                    required
                    handleChange={(status: any) => {
                      handleFilterChange("status", status);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <DataTable
            columns={columns}
            rows={warehouseList?.branches}
            disableColumnMenu
            disableColumnSorting
            getRowId={getRowId}
            paginationMode="server"
            paginationModel={filters.paginationModel}
            onPaginationModelChange={(val: any) => {
              handlePaginationModelChange(val);
            }}
            rowCount={warehouseList?.pagination?.totalRecords}
            disableColumnResize={true}
            loading={warehouseListLoader}
            onRowClick={handleRowClick}
            hasAccess={RbacHasAccess.listWarehouse}
          />
        </Box>
      </Box>
      <ResponsiveDialog
        title={en.NewBusinessManagement.updateHubStatus}
        body={dialogData.body}
        open={dialogData.openDialog}
        handleClose={() => toggleUpdateStatusDialog({}, false)}
        handleYes={() => toggleUpdateStatusDialog({}, true)}
      />
    </>
  );
};

export default WarehouseManagement;
