import { Box, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import {
  ImagePreviewNotAvailable,
  LeftScrollIcon,
  RightScrollIcon,
} from "../../../../constants/exportImages";
import {
  BookingStatusEnum,
  typographyConstants,
} from "../../../../constants/constants";
import Styles from "./section.module.css";
import { colors } from "../../../../../themes/colors";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { generateVehicleSignedUrl } from "../../../../redux/actions/bookingManagementActions";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import en from "../../../../locale/rental-en.json";

interface VehicleImagesCarouselInterface {
  imagesData: any;
  bookingId: any;
  status: any;
  closeModal: any;
  imagePreviewData: any;
  openModal: any;
  startRideImageRefId: any;
  imageLoader: any;
  uploadAccess: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2rem",
};

interface generateSignedUrlPayload {
  requestdata: {
    data: {
      type: string;
      attachments: string[];
    };
  };
  filesData: {
    bookingId: string;
    files: object[];
    fileNames: string[];
    vehiclesImages: boolean;
  };
}

const VehicleImagesCarousel = ({
  imagesData = [],
  bookingId,
  status,
  closeModal,
  imagePreviewData,
  openModal,
  startRideImageRefId,
  imageLoader,
  uploadAccess,
}: VehicleImagesCarouselInterface) => {
  const containerRef = useRef<any>(null);
  const dispatch = useDispatch();
  const scrollHomeDeliveryCards = (value: any) => {
    containerRef.current.scrollBy({ left: value, behavior: "smooth" });
  };

  interface generateSignedUrlPayload {
    requestdata: {
      data: {
        type: string;
        attachments: string[];
      };
    };
    filesData: {
      bookingId: string;
      files: object[];
      fileNames: string[];
      vehiclesImages: boolean;
      startRideImageRefId?: any;
    };
  }
  const generateSignedUrl = (
    fileNames: string[],
    files: any,
    vehiclesImages: boolean
  ) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          type: "VEHICLE_IMAGES",
          attachments: fileNames,
        },
      },
      filesData: {
        bookingId: bookingId,
        files,
        fileNames,
        vehiclesImages,
        startRideImageRefId: startRideImageRefId,
      },
    };
    //dispatch(generateDocsSignedUrls(payload));
    dispatch(generateVehicleSignedUrl(payload));
  };

  const onDrop = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];
      let vehiclesImages = true;

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
      });

      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code === "too-many-files") {
          toastr.warning(
            "",
            `Maximum ${10 - imagesData?.length} files can be uploded`
          );
          return;
        } else if (item?.errors[0]?.code === "file-too-large") {
          toastr.warning("", en.errorMessages.imageSize);
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, vehiclesImages);
      } else {
        toastr.warning("", en?.errorMessages?.invalidFileFormat);
      }

      //setRejectedFile(rejectedFiles);
    },
    [bookingId, imagesData]
  );

  const maxFileUpload = (): number => {
    return imagesData?.length ? 10 - imagesData?.length : 10;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: maxFileUpload(),
    maxSize: 5000000,
    multiple: true,
    //noClick: uploadLoader,
    //noDrag: uploadLoader,
    disabled: !uploadAccess || imagesData?.length >= 10,
  });

  const imagePreviewModal = () => {
    return (
      <Modal
        style={{
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={imagePreviewData.openPreview}
        onClose={() => closeModal()}
        closeAfterTransition
      >
        <Box>
          <Box sx={{ ...style }}>
            <img
              style={{
                objectFit: "contain",
                height: "450px",
                //width: "450px",
                borderRadius: "2rem",
              }}
              src={imagePreviewData.imageUrl}
            />
          </Box>
        </Box>
      </Modal>
    );
  };
  
  return (
    <Box
      sx={{
        padding: "15px",
        borderRadius: "5px",
        marginTop: "20px",
        background: colors.bg_light_gray,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant={typographyConstants.HEADING}>
            Vehicle Images
          </Typography>
          {imagesData?.length > 9 ? (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <img
                  src={LeftScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(-200)}
                  width="30px"
                  height="30px"
                />
              </Box>
              <Box>
                <img
                  src={RightScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(200)}
                  width="30px"
                  height="30px"
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            //marginBottom: "10px",
            cursor: "pointer",
            //marginRight: "12px",
          }}
        ></Box>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid
            xs={
              status === BookingStatusEnum.ONGOING && imagesData?.length < 10
                ? 10.5
                : 12
            }
            className={Styles.CardListContainer}
            ref={containerRef}
            sx={{ padding: "10px", margin: "10px" }}
          >
            {imageLoader ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : imagesData?.length ? (
              imagesData?.map((imageData: any) => (
                <Box
                  className={Styles.imageContainer}
                  onClick={() => {
                    openModal(imageData?.signedUrl);
                  }}
                >
                  <img
                    className={Styles.vehicleImage}
                    src={imageData?.signedUrl}
                    onError={(current: any) => {
                      current.onerror = null;
                      current.src = ImagePreviewNotAvailable;
                    }}
                  />
                  <Box className={Styles.overlay}>
                    <Typography
                      variant={typographyConstants.BODY}
                      color={colors.white}
                    >
                      Preview
                      {/* {VehicleManagement.preview} */}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "50px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography variant={typographyConstants.SUBHEADING}>
                  {en.bookingDetails.noVehicleImagesUploaded}
                </Typography>
              </Box>
            )}
          </Grid>
          {status === BookingStatusEnum.ONGOING && imagesData?.length < 10 && (
            <Grid xs={1} sx={{ padding: "10px", margin: "auto" }}>
              <Box
                className={Styles.imageContainer}
                sx={{
                  border: "1px solid",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: !uploadAccess ? "not-allowed" : "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Box>
                  <AddIcon color="secondary" fontSize="medium" />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Typography variant={typographyConstants.BODY}>
          {imagesData?.length ?? 0}/10 images
        </Typography>
      </Box>
      {imagePreviewData.openPreview && imagePreviewModal()}
    </Box>
  );
};
export default VehicleImagesCarousel;
