import {
  Box,
  CircularProgress,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
//import en from "../../../locale/yor-en.json";
//import { RootState } from "../../../redux/reducers";
//import { actionsX } from "../../redux";
import ImagePreviewModal from "./imagePreviewModal";
// import {
//   UploadDocumentsSectionProps,
//   generateSignedUrlPayload,
// } from "../../interfaces/bookingDetailsInterfaces";
import { toastr } from "react-redux-toastr";
import styles from "../bookingDetails.module.css";
import {
  CrossIconRound,
  circularIcon,
  cloudIcon,
  currupt,
  imageIcon,
  pdfIcon,
} from "../../../../constants/exportImages";
import {
  deleteBookingDocuments,
  generateDocsSignedUrls,
} from "../../../../redux/actions/bookingManagementActions";
import en from "../../../../locale/rental-en.json";
import { colors } from "../../../../../themes/colors";
import { typographyConstants } from "../../../../constants/constants";
//import { DocumentUploadConstants } from "../../constantsX/YOR_BookingDetails";

const obj: any = {
  "file-invalid-type": "File type is not acceptable.",
  "file-too-large": "File size is too large.",
};

interface UploadDocumentsSectionProps {
  bookingId?: string;
  documents?: object[];
  bookingDocsLoader?: boolean;
  uploadAccess?: boolean;
}

interface generateSignedUrlPayload {
  requestdata: {
    data: {
      fileNames: string[];
    };
  };
  filesData: {
    bookingId: string;
    files: object[];
    fileNames: string[];
    docUploadLoader: boolean;
  };
}

const UploadDocuments = (props: UploadDocumentsSectionProps): any => {
  const {
    bookingId = "",
    documents = [],
    bookingDocsLoader,
    uploadAccess,
  } = props;
  const dispatch = useDispatch();
  const [rejectedFile, setRejectedFile] = useState<object[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<object>({});
  const [imgError, setImgError] = useState<boolean>(false);

  /* const { uploadLoader, signedUrlFailedFiles, linearProgressValue } =
    useSelector((state: RootState) => state.commonReducerX) */
  const generateSignedUrl = (
    fileNames: string[],
    files: any,
    docUploadLoader: boolean
  ) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        bookingId: bookingId,
        files,
        fileNames,
        docUploadLoader: docUploadLoader,
      },
    };
    dispatch(generateDocsSignedUrls(payload));
  };

  const onDrop = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];
      let docUploadLoader = true;

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
      });

      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code === "too-many-files") {
          toastr.warning(
            "",
            `Maximum ${10 - documents?.length} files can be uploded`
          );
          return;
        } else if (item?.errors[0]?.code === "file-too-large") {
          toastr.warning("", en.errorMessages.imageSize);
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, docUploadLoader);
      } else {
        toastr.warning("", en?.errorMessages?.invalidFileFormat);
      }

      setRejectedFile(rejectedFiles);
    },
    [bookingId, documents]
  );

  const maxFileUpload = (): number => {
    return documents?.length ? 10 - documents?.length : 10;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxFiles: maxFileUpload(),
    maxSize: 5000000,
    multiple: true,
    //noClick: uploadLoader,
    //noDrag: uploadLoader,
    disabled: !uploadAccess || documents?.length >= 10,
  });

  const navigateDocs = (name: string, extension: string, url: string) => {
    if (extension === "pdf") {
      window.open(url);
    } else {
      setOpenModal(true);
      let obj = {
        name: name,
        url: url,
      };
      setModalData(obj);
      setImgError(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const deleteDocument = (id: string, name: string) => {
    let docName: string[] = [];
    docName.push(`${id}-${name}`);

    let payload = {
      data: {
        data: {
          documents: [id],
        },
      },
      bookingId: bookingId,
    };
    dispatch(deleteBookingDocuments(payload));
    /* if (id && name) dispatch(actionsX.deleteDocument(payload)); */
  };

  return (
    <Box
      sx={{
        padding: "15px",
        borderRadius: "5px",
        marginTop: "20px",
        background: colors.bg_light_gray,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant={typographyConstants.HEADING}>
        Upload Documents
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        {documents?.length < 10 ? (
          <Fade>
            <Stack>
              {!documents ? (
                <Box
                  className={styles?.documentUploadBox}
                  sx={{ cursor: !uploadAccess ? "not-allowed" : "auto" }}
                  {...getRootProps()}
                >
                  <Stack>
                    <Box>
                      <Box sx={{ padding: "5px", gap: "10px" }}>
                        <Stack gap="10px" textAlign={"center"}>
                          <Box>
                            <img src={cloudIcon} width={"20px"} height="20px" />
                          </Box>

                          <LinearProgress
                            value={0}
                            variant="determinate"
                            sx={{ width: "115px" }}
                          />
                          <Box>
                            <Box style={{ display: "flex", gap: "4px" }}>
                              <img
                                src={circularIcon}
                                width={"20px"}
                                height="20px"
                              />
                              <Typography
                                sx={{
                                  lineHeight: "20px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                Uploading
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                lineHeight: "12px",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#A9ACB4",
                              }}
                            >
                              {`${0}% of 100%`}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <Box
                  className={styles?.documentUploadBox}
                  sx={{ cursor: !uploadAccess ? "not-allowed" : "pointer" }}
                  {...getRootProps()}
                >
                  <Stack>
                    <Box>
                      <Box sx={{ padding: "5px", gap: "10px" }}>
                        <input {...getInputProps()} />

                        <Stack textAlign={"center"}>
                          <Box sx={{ textAlign: "center" }}>
                            <img src={cloudIcon} width={"20px"} height="20px" />
                          </Box>
                          <Typography sx={{ margin: "10px" }}>
                            Drag & drop here
                          </Typography>
                          <Box sx={{ textAlign: "center" }}>
                            <AddBoxIcon color="secondary" />
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              )}
              <Box
                style={{ marginLeft: "14px", padding: "10px", width: "150px" }}
              >
                <ul
                  style={{
                    fontSize: "9px",
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <li>We accept .pdf, .jpeg, .jpg, .jfif, .png .</li>
                  <li>Upload upto 5mb of images.</li>
                  <li>Maximum 10 files can be uploaded</li>
                </ul>
              </Box>
            </Stack>
          </Fade>
        ) : null}
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "370px",
            gap: "15px",
            height: "120px",
          }}
        >
          {bookingDocsLoader ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            documents?.map((item: any) => (
              <Fade>
                <Box style={{ position: "relative" }}>
                  <Box
                    className={styles.docImgPreviewBox}
                    onClick={() =>
                      navigateDocs(item?.name, item?.mimeType, item?.url)
                    }
                  >
                    <img
                      src={item?.mimeType === "pdf" ? pdfIcon : imageIcon}
                      height={"20px"}
                      width={"20px"}
                      style={{ borderRadius: "8px" }}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = currupt;
                      }}
                    />
                  </Box>
                  <Box>
                    <Tooltip title={item?.name?.length > 12 ? item?.name : ""}>
                      <Typography
                        style={{
                          fontWeight: 400,
                          lineHeight: "10px",
                          fontSize: "8px",
                          width: "50px",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <img
                    className={styles.imgCrossIcon}
                    src={CrossIconRound}
                    onClick={() => deleteDocument(item?.id, item?.name)}
                  />
                </Box>
              </Fade>
            ))
          )}
        </Box>
      </Box>

      <ImagePreviewModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        modalData={modalData}
        imgError={imgError}
        setImgError={setImgError}
      />
    </Box>
  );
};
export default UploadDocuments;
