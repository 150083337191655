import { RotateRightSharp } from "@mui/icons-material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GridEventListener, GridRowParams } from "@mui/x-data-grid";
import { debounce } from "lodash";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import {
  modules,
  roleManagementFunctionality,
} from "../../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomMultiSelect from "../../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import {
  appliesTo,
  filterStorageKeys,
  GenericObject,
  statusColorCode,
  typographyConstants,
  UserStatus,
} from "../../../../constants/constants";
import {
  AllVendorsIcon,
  CityInputIcon,
  LocationIcon,
  SearchIcon,
  StatusIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  clearRoleRelatedData,
  deleteUserAction,
  fetchAllUsersListAction,
  getBlockedReasons,
  getRolesList,
  unblockUserAction,
} from "../../../../redux/actions/businessManagementActions";
import {
  getAllBranchesByCity,
  getAllStates,
  getCitiesBasedOnMultipleStates,
} from "../../../../redux/actions/rentalCommonAction";
import { appendCommaSpace, formatString } from "../../../../utils/helper";
import { routesConstants } from "../../../../utils/RoutesConstants";
import RbackHelper from "../.././../../../utils/helperRBAC";
import StatusChangeDrawer from "./sections/StatusChangeDrawer";

const { isAccessRightsProvided } = RbackHelper;

// Local Enum for Menu Options
enum LocalMenuOptions {
  EDIT = "Edit",
  DELETE = "Delete",
  BLOCK = "Block",
  UNBLOCK = "Unblock",
}

// Destructuring Constants
const { global, roleManagement, tableHeaders } = rentalEn;

// UserStatus Array - Role management
const userStatusArray: GenericObject[] = [
  { name: UserStatus?.ACTIVE, displayName: "Active" },
  { name: UserStatus?.INACTIVE, displayName: "Inactive" },
  { name: UserStatus?.DELETED, displayName: "Deleted" },
  { name: UserStatus?.BLOCKED, displayName: "Blocked" },
  { name: UserStatus?.LOCKED, displayName: "Locked" },
];

const initialState: GenericObject = {
  states: [],
  cities: [],
  branches: [],
  roles: [],
  status: { name: "", displayName: "" },
  paginationModel: { page: 0, pageSize: 10 },
  search: "",
};
const initialDrawerState: GenericObject = { rowData: {}, userId: "" };
const initialDialogState: GenericObject = {
  openDialog: false,
  rowData: {},
  actionKey: "",
};

const RoleDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RBAC Implementation
  const RbacHasAccess = {
    userList: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.ROLE_MANAGEMENT_LIST
    ),
    addNewUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.ADD_NEW_USER
    ),
    updateUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.UPDATE_USER
    ),
    blockUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.BLOCK_USER
    ),
    unblockUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.UNBLOCK_USER
    ),
    deleteUser: isAccessRightsProvided(
      modules.ROLE_MANAGEMENT,
      roleManagementFunctionality.DELETE_USER
    ),
  };

  const { allStatesList, citiesBasedOnMultiStates, hubsByMultipleCities } =
    useSelector((state: RootState) => state.rentalsCommonReducer);
  const {
    allUsersListData,
    allUsersListApiResponseData, // {}
    allUsersListLoader, // All users list loader
    rolesListData,
    blockUserLoader, // Block user loader
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);

  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerData, setDrawerData] = useState<GenericObject>({
    ...initialDrawerState,
  });
  const [dialogData, setDialogData] = useState<GenericObject>({
    ...initialDialogState,
  });

  // Function to construct payload for fetching data
  const constructPayload = (data: GenericObject): GenericObject => {
    const payload: GenericObject = {
      page: data?.paginationModel?.page + 1, // MUI data grid page starts from zero, so add 1
      limit: data?.paginationModel?.pageSize,
      states:
        allStatesList?.length === data?.states?.length
          ? null
          : data?.states?.length
          ? data?.states
          : null, // ["IN_UP","IN_GJ"]
      cities:
        citiesBasedOnMultiStates?.length === data?.cities?.length
          ? null
          : data?.cities?.length
          ? data?.cities
          : null, // ["IN_UP","IN_GJ"]
      branches:
        hubsByMultipleCities?.length === data?.branches?.length
          ? null
          : data?.branches?.length
          ? data?.branches
          : null, // ["IN_UP_NOIDA_NOIDA_63"]
      roles:
        rolesListData?.length === data?.roles?.length
          ? null
          : data?.roles?.length
          ? data?.roles
          : null, // ["SUPER_USER","CITY_HEAD"]
      status: data?.status?.name, // ACTIVE
      search: data?.search,
    };
    return payload;
  };

  // Function to fetch initial data and set filters from local storage
  const fetchFilterData = async () => {
    try {
      const data = await getFilterDataFromLocalStorage(
        filterStorageKeys?.ROLE_MANAGEMENT_FILTER_DATA
      );
      if (data) {
        delete data.search;
        setFields((prev: any) => ({ ...prev, ...data }));

        // Fetch cities based on selected states data from local storage
        if (data?.states?.length) {
          dispatch(getCitiesBasedOnMultipleStates({ states: data?.states }));
        }

        // Fetch branches based on selected cities data from local storage
        if (data?.cities?.length) {
          dispatch(getAllBranchesByCity({ cities: data?.cities }));
        }

        // Construct payload and fetch users list based on local storage data
        const payload: GenericObject = constructPayload(data);
        dispatch(fetchAllUsersListAction(payload));
      } else {
        // Fetch user list if local storage data is not available
        dispatch(fetchAllUsersListAction({ page: 1, limit: 10 }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Initial api calls on component mount
    if (RbacHasAccess?.userList) {
      dispatch(getRolesList());
      dispatch(getAllStates());
      fetchFilterData();
    }
  }, []);

  const addNewUserButtonHandler = () => {
    const payload: any = {
      updateUserResponse: {},
      addUserResponseData: {},
      userDetailsByUserIdData: {},
      initialAssignRoleState: [
        {
          roleId: { _id: "", name: "" },
          operatorIds: [],
          //
          state: [],
          selectedState: [],
          city: [],
          selectedCity: [],
          branch: [],
          selectedBranch: [],
          uuid: uuidv4(),
          operation: "add",
        },
      ],
    };
    dispatch(clearRoleRelatedData(payload));
    navigate(`${routesConstants.ADD_ROLE}`); // navigate to 'add new user' screen
  };

  const rowCountRef: React.MutableRefObject<any> = useRef(
    allUsersListApiResponseData?.pageData?.total || 0
  );

  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (allUsersListApiResponseData?.pageData?.total !== undefined) {
      rowCountRef.current = allUsersListApiResponseData?.pageData?.total;
    }
    return rowCountRef.current;
  }, [allUsersListApiResponseData?.pageData?.total]);

  // Handler for pagination model changes
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    // Store updated data in local storage on user selections
    const storeData: GenericObject = {
      roleManagementFilterData: {
        ...fields,
        paginationModel: { page: page, pageSize: pageSize },
      },
    };
    delete storeData.search;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Construct payload and fetch users list data
    const payload: GenericObject = constructPayload(fields);
    payload.page = page + 1;
    payload.limit = pageSize;
    dispatch(fetchAllUsersListAction(payload));
  };

  const MenuButtonComponent = (params: any) => {
    const { id, row } = params?.params;
    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handleMenuOptionClick = (id: any, option: any) => {
      if (option === LocalMenuOptions?.BLOCK) {
        dispatch(getBlockedReasons());
        setDrawerData({ rowData: { ...row }, userId: id });
        setOpenDrawer(true);
      } else if (option === LocalMenuOptions?.UNBLOCK) {
        setDialogData({
          ...dialogData,
          openDialog: true,
          rowData: { ...row },
          actionKey: LocalMenuOptions?.UNBLOCK,
        });
      } else if (option === LocalMenuOptions?.DELETE) {
        setDialogData({
          ...dialogData,
          openDialog: true,
          rowData: { ...row },
          actionKey: LocalMenuOptions?.DELETE,
        });
      } else if (option === LocalMenuOptions?.EDIT) {
        navigate(`${routesConstants.ADD_ROLE}/${row?.id}`);
      }
    };

    // Active status menu options
    const activeOptions: GenericObject[] = [
      {
        hasAccess: !!RbacHasAccess?.updateUser,
        displayName: LocalMenuOptions?.EDIT,
        icon: <EditIcon sx={{ fontSize: "12px" }} />,
      },
      {
        hasAccess: !!RbacHasAccess?.blockUser,
        displayName: LocalMenuOptions?.BLOCK,
        icon: <DoNotDisturbAltIcon sx={{ fontSize: "12px" }} />,
      },
    ];

    // Blocked status menu options
    const blockedOptions: GenericObject[] = [
      {
        hasAccess: !!RbacHasAccess?.unblockUser,
        displayName: LocalMenuOptions?.UNBLOCK,
        icon: <DoNotDisturbOffOutlinedIcon sx={{ fontSize: "12px" }} />,
      },
      {
        hasAccess: !!RbacHasAccess?.deleteUser,
        displayName: LocalMenuOptions?.DELETE,
        icon: <DeleteForeverOutlinedIcon sx={{ fontSize: "12px" }} />,
      },
    ];

    // Display menu options based on user status
    const options: GenericObject[] =
      row?.status === UserStatus?.ACTIVE ? activeOptions : blockedOptions;

    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            },
          }}
        >
          {options?.map((option: any, index: number) => (
            <MenuItem
              key={option?.displayName}
              onClick={() => handleMenuOptionClick(id, option?.displayName)}
              disabled={!option?.hasAccess}
              sx={{
                borderBottom: index === 0 ? "1px solid #0A555C" : "none",
                gap: 1,
              }}
            >
              {option?.icon} {option?.displayName}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  // Columns configuration for MUI data grid
  const columns: GenericObject[] = [
    {
      field: "name",
      headerName: tableHeaders?.name,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: tableHeaders?.mobileNo,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "emailId",
      headerName: tableHeaders?.emailAddress,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assignedRoles",
      headerName: tableHeaders?.rolesAssigned,
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRowParams) => (
        <Tooltip
          title={
            <Stack>
              {params?.row?.roles?.map((role: any) => {
                return (
                  <Stack>
                    {/* Role Name */}
                    <Typography
                      variant={typographyConstants?.HEADING}
                      color={colors?.white}
                    >
                      {roleManagement?.role}: {role?.roleName}
                    </Typography>
                    {/* State */}
                    <Typography
                      variant={typographyConstants?.BODY}
                      color={colors?.white}
                      component="li"
                    >
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors?.white}
                      >
                        {global?.state}
                      </Typography>
                      :{" "}
                      {isArrayNotEmpty(role?.states)
                        ? appendCommaSpace(
                            role?.states?.map((state: any) => state?.name)
                          )
                        : appliesTo?.COUNTRY
                        ? global?.allState
                        : global?.NA}
                    </Typography>
                    {/* City */}
                    <Typography
                      variant={typographyConstants?.BODY}
                      color={colors?.white}
                      component="li"
                    >
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors?.white}
                      >
                        {global?.city}
                      </Typography>
                      :{" "}
                      {isArrayNotEmpty(role?.cities)
                        ? appendCommaSpace(
                            role?.cities?.map((city: any) => city?.name)
                          )
                        : appliesTo?.COUNTRY
                        ? global?.allCity
                        : global?.NA}
                    </Typography>
                    {/* Hub */}
                    <Typography
                      variant={typographyConstants?.BODY}
                      color={colors?.white}
                      component="li"
                    >
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors?.white}
                      >
                        {global?.hub}
                      </Typography>
                      :{" "}
                      {isArrayNotEmpty(role?.branches)
                        ? appendCommaSpace(
                            role?.branches?.map((hub: any) => hub?.name)
                          )
                        : appliesTo?.COUNTRY
                        ? global?.allHubs
                        : global?.NA}
                    </Typography>
                    <Divider color={colors?.THEME_BLUE} />
                  </Stack>
                );
              })}
            </Stack>
          }
          arrow
        >
          <span>
            {isArrayNotEmpty(params?.row?.assignedRoles)
              ? appendCommaSpace(params?.row?.assignedRoles)
              : global?.NA}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: tableHeaders?.status,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRowParams) => (
        <span
          style={{
            // @ts-ignore
            color:
              statusColorCode[
                params?.row?.status as keyof typeof statusColorCode
              ],
          }}
        >
          {params?.row?.status ? formatString(params?.row?.status) : global?.NA}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: tableHeaders?.action,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "actions",
      getActions: (params: GridRowParams) =>
        params?.row?.status === UserStatus?.ACTIVE ||
        params?.row?.status === UserStatus?.BLOCKED
          ? [<MenuButtonComponent params={params} />]
          : [],
    },
  ];

  const handleChange = (key: string, value: any) => {
    let data: GenericObject = {};

    if (key === "roles") {
    }

    if (key === "status") {
    }

    if (key === "states") {
      data = { cities: [], branches: [] }; // Reset cities, and branches, on state change

      if (value?.length) {
        dispatch(getCitiesBasedOnMultipleStates({ states: value })); // Fetch cities based on selected states
      }
    }

    if (key === "cities") {
      data = { branches: [] }; // Reset branches, on city change

      if (value?.length) {
        dispatch(getAllBranchesByCity({ cities: value })); // Fetch branches based on selected cities
      }
    }

    // Always update the state with the new key-value pair and additional data
    const newFields: GenericObject = {
      ...fields,
      [key]: value,
      ...data,
      paginationModel: initialState?.paginationModel, // Reset pagination on filter change
    };
    setFields(newFields);

    // Store updated data in local storage on user selections
    const storeData: GenericObject = {
      roleManagementFilterData: { ...newFields },
    };
    delete storeData.search;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Construct payload and fetch users list data
    const payload: GenericObject = constructPayload(newFields);
    dispatch(fetchAllUsersListAction(payload));
  };

  // Handler for row click event in the data grid
  const handleRowClick: GridEventListener<"rowClick"> = (params: any) => {
    const { row } = params;
    if (row?.id) {
      navigate(`${routesConstants.VIEW_ROLE_USER_DETAILS}/${row?.id}`);
    }
  };

  // Handle user status update (block user) success
  const handleStatusUpdateSuccessCallback = () => {
    // Fetch updated user list
    const payload: any = {
      page: 1,
      limit: 10,
    };
    dispatch(fetchAllUsersListAction(payload));

    setOpenDrawer(false); // Close the side drawer
    setDrawerData({ ...initialDrawerState }); // Reset drawer state
    setDialogData({ ...initialDialogState }); // Reset dialog state
  };

  // Handle dialog's yes button click for different scenarios
  const yesHandler = () => {
    // Construct payload
    const payload: GenericObject = {};

    if (dialogData?.actionKey === LocalMenuOptions?.UNBLOCK) {
      // Make api request to unblock the user
      dispatch(
        unblockUserAction(
          payload,
          dialogData?.rowData?.id,
          handleStatusUpdateSuccessCallback
        )
      );
    } else if (dialogData?.actionKey === LocalMenuOptions?.DELETE) {
      // Make api request to delete the user
      dispatch(
        deleteUserAction(
          payload,
          dialogData?.rowData?.id,
          handleStatusUpdateSuccessCallback
        )
      );
    }
  };

  // Debounced search function using useCallback to memoize the function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string, fields: GenericObject) => {
      // Fetch updated user list
      const payload: GenericObject = constructPayload(fields);
      payload.search = searchQuery ? JSON.stringify(searchQuery) : "";
      dispatch(fetchAllUsersListAction(payload));
    }, 1000), // Debounce time set to 1000ms (1 second)
    []
  );

  // Handle search input change
  const handleSearch = (e: any) => {
    setFields((prev: any) => ({
      ...prev,
      paginationModel: initialState?.paginationModel,
      search: e.target.value,
    }));
    // Execute debounced search if length of search query is 3 or more
    if (e.target.value?.length >= 3 || !e.target.value) {
      debouncedSearch(e.target.value, {
        ...fields,
        paginationModel: initialState?.paginationModel,
        search: e.target.value,
      });
    }
  };

  // Handler to reset all filters
  const handleFilterReset = () => {
    setFields({ ...initialState });

    // Store initial state data in local storage reset filter button click
    const storeData: GenericObject = {
      roleManagementFilterData: { ...initialState },
    };
    delete storeData.search;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Make api call with the default values
    dispatch(fetchAllUsersListAction({ page: 1, limit: 10 }));
  };

  return (
    <Fragment>
      {
        <StatusChangeDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          toggleDrawer={(newOpen: boolean) => () => setOpenDrawer(newOpen)}
          drawerData={drawerData}
          handleStatusUpdateSuccessCallback={handleStatusUpdateSuccessCallback}
          loading={blockUserLoader}
        />
      }
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {/* Add New User Button */}
          {RbacHasAccess?.addNewUser && (
            <CustomButton
              label={roleManagement?.addNewUser}
              variant="outlined"
              sx={{ minWidth: 150, padding: "3px 16px" }}
              onClick={addNewUserButtonHandler}
            />
          )}

          {/* Reset Icon */}
          {RbacHasAccess?.userList && (
            <Tooltip title={global?.resetFilters} arrow>
              <RotateRightSharp
                fontSize="inherit"
                color="primary"
                sx={{ cursor: "pointer", fontSize: "35px" }}
                onClick={handleFilterReset}
              />
            </Tooltip>
          )}
        </Stack>

        {/* Filter Section */}
        {RbacHasAccess?.userList && (
          <Box sx={{ flexGrow: 1, alignItems: "center" }}>
            <Grid container spacing={1}>
              {/* State Dropdown */}
              <Grid item sm={12} md={4} lg={3}>
                <CustomMultiSelect
                  icon={LocationIcon}
                  placeholder={global?.selectState}
                  selectAllText={global.allState}
                  value={fields?.states}
                  handleChange={(value: any) => {
                    handleChange("states", value);
                  }}
                  choice={allStatesList}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={!isArrayNotEmpty(allStatesList)}
                />
              </Grid>

              {/* City Dropdown */}
              <Grid item sm={12} md={4} lg={3}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  placeholder={
                    !fields?.states?.length && !fields?.cities?.length
                      ? global.allCities
                      : global?.SelectCity
                  }
                  selectAllText={global.allCities}
                  value={fields?.cities}
                  handleChange={(value: any) => {
                    handleChange("cities", value);
                  }}
                  choice={citiesBasedOnMultiStates}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={
                    !isArrayNotEmpty(citiesBasedOnMultiStates) ||
                    !fields?.states?.length
                  }
                />
              </Grid>

              {/* Hubs Dropdown */}
              <Grid item sm={12} md={4} lg={3}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  placeholder={
                    !fields?.cities?.length && !fields?.branches?.length
                      ? global.AllHubs
                      : global?.SelectBranch
                  }
                  selectAllText={global.AllHubs}
                  value={fields?.branches}
                  handleChange={(value: any) => {
                    handleChange("branches", value);
                  }}
                  choice={hubsByMultipleCities}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={
                    !isArrayNotEmpty(hubsByMultipleCities) ||
                    !fields?.cities?.length
                  }
                />
              </Grid>

              {/* Roles Dropdown */}
              <Grid item sm={12} md={4} lg={3}>
                <CustomMultiSelect
                  icon={AllVendorsIcon}
                  placeholder={roleManagement?.selectRole}
                  selectAllText={global.allRoles}
                  value={fields?.roles}
                  handleChange={(value: any) => {
                    handleChange("roles", value);
                  }}
                  choice={rolesListData}
                  defaultKey="_id"
                  displayNameKey="name"
                  disabled={!isArrayNotEmpty(rolesListData)}
                />
              </Grid>

              {/* Status Dropdown */}
              <Grid item sm={12} md={4} lg={3}>
                <CustomSelect
                  required
                  icon={StatusIcon}
                  placeholder={global?.allStatus}
                  defaultKey={global?.displayName}
                  value={
                    fields?.status?.displayName
                      ? fields?.status?.displayName
                      : ""
                  }
                  choice={[
                    { name: "", displayName: global?.allStatus },
                    ...userStatusArray,
                  ]}
                  handleChange={(value: any) => handleChange("status", value)}
                  disabled={!isArrayNotEmpty(userStatusArray)}
                />
              </Grid>

              {/* Search Box */}
              <Grid item sm={12} md={4} lg={3}>
                <Input
                  placeholder={
                    roleManagement?.roleDashboardSearchBoxPlaceholder
                  }
                  variant="outlined"
                  iconStart={SearchIcon}
                  inputProps={{ maxLength: 150 }}
                  value={fields?.search}
                  onChange={(e: any) => handleSearch(e)}
                  disabled={false}
                  muiTextFieldRootStyle={{
                    width: 1,
                    minWidth: "unset",
                    maxWidth: "520px",
                    minHeight: "40px",
                    height: "40px",
                    maxHeight: "40px",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Mui Data Table */}
        {RbacHasAccess?.userList && (
          <DataTable
            rows={allUsersListData}
            columns={columns}
            rowCount={rowCount}
            paginationModel={fields?.paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            hideFooter={!isArrayNotEmpty(allUsersListData)}
            loading={allUsersListLoader}
            onRowClick={handleRowClick}
          />
        )}
      </Stack>

      {/* Confirmation Dialog For User Status Change  */}
      <ResponsiveDialog
        title={
          dialogData?.actionKey === LocalMenuOptions?.UNBLOCK
            ? roleManagement?.unblockUser
            : roleManagement?.deleteUser
        }
        body={
          dialogData?.actionKey === LocalMenuOptions?.UNBLOCK
            ? roleManagement?.areYouSureYouWantToUnblockThisUser
            : roleManagement?.areYouSureYouWantToDeleteThisUser
        }
        open={dialogData?.openDialog}
        handleClose={() => setDialogData({ ...initialDialogState })}
        handleYes={yesHandler}
      />
    </Fragment>
  );
};

export default RoleDashboard;
