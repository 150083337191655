import { FREEDO_IMAGE_URL } from "../config";

export { default as bannerPngIcon } from "./images/Banner.png";
export { default as avatarJpgIcon } from "./images/avatar.jpg";
export { default as avatarPngIcon } from "./images/avatar.png";
export { default as backgroundPngIcon } from "./images/background.png";
export { default as dashboardIconPngIcon } from "./images/dashboard_icon.png";
export { default as heroPngIcon } from "./images/hero.png";
export { default as logoPngIcon } from "./images/logo.png";
export { default as logoutPngIcon } from "./images/logout.png";
export { default as pageNotFoundPngIcon } from "./images/page_not_found.png";
export { default as PagenotfoundPngIcon } from "./images/pagenotfound.png";
export { default as sidebarIconPngIcon } from "./images/sidebar_icon.png";
export { default as selfieIcon } from "./images/Selfie.png";
export { default as PdfIcon } from "./images/pdf.png";
export { default as GreenIcon } from "./images/GreenIcon.png";
export { default as FinishIcon } from "./images/finishIcon.png";
export { default as ServicesIcon } from "./images/ServicesIcon.png";
export { default as OtherIcon } from "./images/OthersIcon.png";
export { default as EditIcon } from "./images/EditIcon.png";
export { default as RemoveIcon } from "./images/RemoveIcon.png";
export { default as halfFace } from "./images/half-face.png";
export { default as fullFace } from "./images/full-face.png";
export { default as premium } from "./images/premium.png";
export { default as VerifiedIcon } from "./images/verified.png";
export { default as NonVerifiedIcon } from "./images/NotVerified.png";
export { default as NoImageFound } from "./images/No-image-found.jpg";

// export { default as pleasurePlus } from "./images/PleasurePlus.jpg";
// export { default as pleasurePlus1 } from "./images/Pleasure110_1.png";

// export { default as pleasurePlus2 } from "./images/Pleasure110_2.png";

// export { default as pleasurePlus4 } from "./images/Pleasure110_4.png";
// export { default as pleasurePlus5 } from "./images/Pleasure110_5.png";
// export { default as pleasurePlus6 } from "./images/Pleasure110_6.png";
// export { default as pleasurePlus7 } from "./images/Pleasure110_7.png";
// export { default as pleasurePlus8 } from "./images/Pleasure110_8.png";
// export { default as pleasurePlus9 } from "./images/Pleasure110_9.png";

export { default as AdminLogo } from "./newAssets/images/admin-logo.png";
export { default as Cross } from "./newAssets/images/cross.png";
export { default as NotVerified } from "./newAssets/images/not_verified.png";
export { default as Checkmark } from "./newAssets/images/check-mark.png";
export { default as Modified } from "./images/modified.png";
export { default as cloudUploadIcon } from "./images/cloudUploadIcon.svg";
export const PremiumHelmet = FREEDO_IMAGE_URL + "yor-images/PremiumHelmet.svg";
export const FullFaceHelmet =
  FREEDO_IMAGE_URL + "yor-images/FullFaceHelmet.svg";
export const HalfFaceHelmet =
  FREEDO_IMAGE_URL + "yor-images/HalfFaceHelmet.svg";
export const KidsHelmet = FREEDO_IMAGE_URL + "yor-images/KidsHelmet.svg";

export { default as ImagePreviewNotAvailable } from "../assets/images/imgPreviewNotAvl.svg";
