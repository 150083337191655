import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { SubscriptionLoader } from "../../../sharedComponents/molecules/SkeletonLoader/CheckoutLoader";
import { colors } from "../../themes/colors";
import CustomizedTooltips from "../Tooltips";

const SubscriptionItem = (props: any) => {
  const { loadingCharges } = useSelector(
    (state: RootState) => state.manualBookingX
  );
  return (
    <>
      {loadingCharges ? (
        <SubscriptionLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            padding: props.padding ? props.padding : "5px 14px 8px 14px",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: props?.showBorder
              ? props.dashedBorder
                ? "1px dashed #ABABAB"
                : "1px solid #D4E4E452"
              : "",
            gap: props.gap ? props.gap : "unset",
          }}
        >
          <Stack>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: props?.titleColor
                    ? props?.titleColor
                    : colors.text_secondary_gray,
                  fontFamily: "Euclid Circular B",
                }}
              >
                {props.title}{" "}
              </Typography>
              {props?.date && (
                <Typography
                  sx={{
                    fontSize: "8px",
                    color: colors.text_secondary_gray,
                    fontFamily: "Euclid Circular B",
                    marginTop: "6px",
                  }}
                >
                  {`(${props?.date})`}
                </Typography>
              )}
              {/* ADDONS */}
              {props.icon && !props.addOn ? (
                <img src={props.icon} width={24} height={24} />
              ) : null}
              {props.addOn && Object.keys(props.addOn).length > 0 ? (
                <CustomizedTooltips
                  icon={props.icon}
                  content={props.addOn}
                  addOnCountObj={props.addOnCount}
                />
              ) : null}
            </Box>
            {props.description && (
              <Typography
                sx={{
                  color: colors.primary_aqua_blue,
                  fontSize: props?.fontSize ? props?.fontSize : "12px",
                  fontFamily: "Euclid Circular B",
                  fontStyle: "italic",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {props.description}
                {props?.descriptionStatusChip && props?.descriptionStatusChip}
              </Typography>
            )}
          </Stack>
          <Stack alignItems="end">
            <Typography
              sx={{
                fontSize: "14px",
                color: props?.amountColor
                  ? props?.amountColor
                  : colors.text_black,
                fontWeight: 500,
                fontFamily: "Euclid Circular B",
              }}
            >
              {props.amount}
            </Typography>
            {props.inclusiveTaxes && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: colors.tertiary_black,
                  fontStyle: "italic",
                  fontFamily: "Euclid Circular B",
                }}
              >
                {props.inclusiveTaxes}
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default SubscriptionItem;
