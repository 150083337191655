import { AuthContainer } from "./components/authContainer";
import "./newAuthCss/global.css";
import "./newAuthCss/layout.css";

import { ContainerMsg } from "./components/authContainerMsg";
import { AuthInput } from "./components/authInputs";
import { AuthButton } from "./components/authButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { MessagePopUp } from "./components/messagePopUp";
import { SyntheticEvent, useEffect, useState } from "react";

import { actions } from "../../redux";
import { AuthUsecases } from "../../constants/enum";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../rentals-src/utils/helper";

function ForgotPassword() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { forgotPasswordLoader, isForgotPasswordLinkSent } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [errfields, setErrFields] = useState<string>("");

  const dispatch = useDispatch();

  // navigate to login page if unlock account success
  useEffect(() => {
    if (isForgotPasswordLinkSent) setTimeout(() => navigate("/"), 3000);
  }, [isForgotPasswordLinkSent]);

  // handle change in input field
  const onChange = (e: any) => {
    const { value } = e.target;
    const validate = validateEmail(value);
    if (validate?.status || !value) {
      setErrFields("");
    } else {
      setErrFields(validate.error);
    }
    setUsername(value);
  };

  // validate username and api call to forgot password
  const validate = () => {
    if (username) {
      dispatch(
        actions.forgotPassword({
          data: {
            userName: username,
          },
        })
      );
    }
  };

  return (
    <AuthContainer
      heading={locale?.newAuth?.forgotPassword}
      componentFields={
        <>
          {isForgotPasswordLinkSent ? (
            <ContainerMsg message={locale?.newAuth?.forgotPassContMsg} />
          ) : null}
          <AuthInput
            label={locale?.newAuth?.emailUsername}
            placeholder={locale?.newAuth?.enterUsername}
            value={username}
            className={
              errfields != "" ? "form-control error-input" : "form-control"
            }
            errorMessage={errfields}
            onChange={onChange}
          />
          <AuthButton
            type="submit"
            title={locale?.newAuth?.sendLink}
            loading={forgotPasswordLoader}
            /*  className={
              username !== ""
                ? "btn w-100 login-btn mt-3"
                : "btn w-100 login-btn disable mt-3"
            } */
            disabled={!username || errfields}
            onClick={validate}
          />
          {/* {showMessage ? (
            <MessagePopUp
              className={success && !error ? "pw-sucess" : "pw-error"}
              message={message}
              onClick={() => {
                dispatch(actions.showMessagePopUp({ showMessage: false }));
              }}
            />
          ) : null} */}
        </>
      }
    />
  );
}

export default ForgotPassword;
