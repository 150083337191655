import { Box, CircularProgress } from "@mui/material";
import { Avatar, Badge, Chip } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import "./muiButtonsX.css";

const FilledButtonX = (props: any) => {
  return (
    <Button
      variant="contained"
      onClick={props.onclick}
      disabled={props.disabled || props.loading}
      {...props}
      textTransform="none"
    >
      {props.label}
    </Button>
  );
};

const OutlinedButtonX = (props: any) => {
  return (
    <Button
      variant="outlined"
      onClick={props.onclick}
      disabled={props.disabled || props.loading}
      className={props.className}
      sx={[
        props.hoverStyle && {
          "&:hover": {
            border: "1px solid #1bd8da",
          },
        },
        { textTransform: "none" },
        props.hoverStyle && { border: "1px solid #000" },
      ]}
      startIcon={props.icon && <img src={props.icon} />}
      {...props}
    >
      {props.label}
    </Button>
  );
};

const OutlinedIconButtonX = (props: any) => {
  return (
    <Button
      variant="outlined"
      onClick={props.onclick}
      disabled={props.disabled || props.loading}
      className={props.className}
      sx={[
        { textTransform: "none" },
        props.border === "error" && {
          border: "1px solid #ff3b30",
          color: "#ff3b30",
        },
        props.hoverStyle === "error" && {
          "&:hover": {
            borderRadius: "100px",
            border: "1px solid  #d32f2f",
            background: "linear-gradient(90deg, #d32f2f -1.26%, #F3756A 100%)",
            color: "#fff",
          },
        },
      ]}
      // startIcon={props.icon && <img src={props.icon} className="logoutIconX" />}
      startIcon={props.icon && <props.icon />}
      {...props}
    >
      {props.label}
    </Button>
  );
};

const TextButtonX = (props: any) => {
  return (
    <Button
      sx={{ textTransform: "none" }}
      onClick={props.onclick}
      disabled={props.disabled || props.loading}
    >
      {props.label}
    </Button>
  );
};

const StartIconLabelButtonX = (props: any) => {
  return (
    <Button
      variant={props.variant}
      onClick={props.onClick ? () => props.onClick : ""}
      startIcon={
        props.loading ? (
          <CircularProgress
            color={props.loaderColor || "inherit"}
            size={12}
            style={{ marginRight: 20 }}
          />
        ) : props.icon ? (
          <img src={props.icon} />
        ):  props.muiIcon
      }
      disabled={props.disabled || props.loading}
      sx={{
        borderRadius: "100px",
        fontSize: props?.fontSize ? props?.fontSize : "12px",
        width: props?.minWidth ? props?.minWidth : "13vw",
        minWidth: props?.minWidth ? props?.minWidth : "176px",
        textTransform: "none",
        minHeight: props?.minHeight ? props.minHeight : "36px",
        maxHeight: "38px",
        color: props.color ? props.color : props.variant != "contained" ? "#09CACC" : "#fff !important",
        position: props?.position ? props.position : "relative",
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "inherit",
        boxShadow: props.boxShadow ? props.boxShadow : "inherit",
        // color: props.variant != "contained" ? "#09CACC" : "inherit",
      }}
      {...props}
    >
      {props.label}
    </Button>
  );
};

const EndIconLabelButtonX = (props: any) => {
  return (
    <Button
      variant={props.variant}
      endIcon={<img src={props?.icon} />}
      onClick={props.onclick}
      disabled={props.disabled || props.loading}
      startIcon={
        props.loading ? (
          <CircularProgress
            color={props.loaderColor || "inherit"}
            size={12}
            style={{ marginRight: 20 }}
          />
        ) : (
          <></>
        )
      }
      sx={{
        borderRadius: "100px",
        fontSize: "12px",
        width: props?.width || "13vw",
        minWidth: props?.minWidth || "176px",
        textTransform: "none",
        minHeight: "36px",
        maxHeight: "38px",
        color: props.variant !== "outlined" ? "#fff" : "inherit",
        backgroundImage:
          "linear-gradient(90deg, #1bdbda 1.67%, rgba(27, 196, 166, 0.95) 100%, #FFF)",
        transition: "background-image 0.3s ease-in-out",
        "&:hover": {
          backgroundImage:
            "linear-gradient(276deg, #1bd8da 6.6%, rgba(27, 196, 166, 0.95) 100%, #FFF)",
        },
      }}
      {...props}
    >
      {props.label}
    </Button>
  );
};

const CartButtonInitial = (props: any) => {
  return (
    <Badge
      overlap="circular"
      color="secondary"
      badgeContent="+"
      onClick={props.onclick}
    >
      <Button
        variant={props.variant}
        startIcon={<props.icon />}
        onClick={props.onclick}
        disabled={props.disabled || props.loading}
        sx={{
          borderColor: "black",
          color: "black",
        }}
      >
        {props.label}
      </Button>
    </Badge>
  );
};


const VariantButton = (props: any) => {
  return (
    <Button
      size="small"
      variant={props.variant ? props.variant : "contained"}
      disabled={props.disabled}
      onClick={props.onClick}
      {...props}
      sx={{
        color: props.variant === "contained" ? "#fff" : "inherit",
        textTransform: "none",
        borderRadius: "16px",
      }}
    >
      {props.label}
    </Button>
  );
};

export {
  FilledButtonX,
  OutlinedButtonX,
  OutlinedIconButtonX,
  TextButtonX,
  StartIconLabelButtonX,
  EndIconLabelButtonX,
  CartButtonInitial,
  VariantButton,
};
