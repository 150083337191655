import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import SubscriptionItem from "../../../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import {
  PaymentFailedUI,
  PaymentSuccessUI,
  RenderCashUI,
  RenderPaymentLink,
  RenderQRCode,
} from "../../../../../sharedComponents/templates/RenderPaymentMethods";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesDispalyStatus,
  BookingPaymentStatus,
  DisplayPaymentModes,
  GenericObject,
  OrderPaymentType,
  paymentModeIcons,
  typographyConstants,
} from "../../../../constants/constants";
import { LeftArrowIcon } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  bookingManagementActions,
  rentalActions,
} from "../../../../redux/actions";
import { getFormattedINR, getPaymentTypeText } from "../../../../utils/helper";
import styles from "../bookingDetails.module.css";
import { completedRideInterface } from "../../../../interfaces/bookingManagement";
import { RootState } from "../../../../../redux/store";

//page level enums
const enum screenToogleKeys {
  PAYMENT_LINK_GENERATED = "PAYMENT_LINK_GENERATED",
  COMPLETE_RIDE_PROCCED_TO_PAY = "COMPLETE_RIDE_PROCCED_TO_PAY",
  PAYMENT_OPTIONS = "PAYMENT_OPTIONS",
}

const enum paymentTypes {
  PARTIAL_REFUND = "PARTIAL_REFUND",
  FULL_REFUND = "FULL_REFUND",
}

// complete ride side drawer ui
export const CompleteRideDrawerContent = (props: completedRideInterface) => {
  const dispatch = useDispatch();
  const { bookingDetails } = rentalEn;
  const { bookingChargesSubCategoriesDisplay } = bookingDetails;
  const {
    bookingVehicleDetails,
    pendingCharges,
    pendingAndProcessingCharges,
    pendingChargesLoader,
    bookingId,
    fields,
    setFields,
    orderData,
    toggleCompleteRideDrawer,
    completeRidePaymentLoader,
  } = props;

  const {
    operatorConfigData,
    isPaymentLinkGenerated,
    paymentLink,
    updatedStatus,
    paymentLoader,
  } = useSelector((state: RootState) => state.rentalsCommonReducer);
  let keyType: string = "";

  //hanlde paymnet change mode
  const handlePaymentModeChange = (event: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      selectedPaymentMode: event.target.value,
    }));
  };

  const [loadedComponent, setLoadedComponent] = useState<string>(
    screenToogleKeys.COMPLETE_RIDE_PROCCED_TO_PAY
  );

  //pending charges
  const availablePendingCharges = [
    {
      heading: rentalEn?.bookingManagement?.endOdometerReading,
      amount: `${bookingVehicleDetails?.bookingVehicleDetails?.currentReading} KM`,
      isAvailable: true,
      color: "",
    },
  ];
  // Security Deposit
  if (pendingCharges?.securityCharge || !pendingCharges?.charges?.length) {
    availablePendingCharges.push({
      heading: "Security Deposit",
      amount: pendingCharges?.securityCharge
        ? getFormattedINR(pendingCharges?.securityCharge?.amount)
        : getFormattedINR(0),
      isAvailable: true,
      color: colors?.secondary_green,
    });
  }
  // Remaining Charges
  if (pendingCharges?.charges?.length || !pendingCharges?.securityCharge) {
    if (pendingCharges?.totalAmount > 0) {
      availablePendingCharges.push({
        heading: rentalEn?.bookingManagement?.remainingCharges,
        amount: pendingCharges?.charges?.length
          ? getFormattedINR(pendingCharges?.totalAmount)
          : getFormattedINR(0),
        isAvailable: true,
        color: colors?.primary_danger,
      });
    }
  }

  // table columns
  const columns: any[] = [
    {
      type: "actions",
      field: "modelDisplayName",
      headerName: "Title",
      width: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.name}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography color={colors.black}>
                {`${
                  bookingChargesSubCategoriesDisplay[
                    params.row
                      .subCategory as keyof typeof bookingChargesSubCategoriesDisplay
                  ]
                }`}
              </Typography>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "tempRegistration",
      headerName: "Amount Left",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span style={{ fontSize: "12px", color: colors.black }}>
            {" "}
            {getFormattedINR(params.row.amount)}
          </span>
        );
      },
    },
  ];

  const sucessCallbackCompleteRide = () => {
    toggleCompleteRideDrawer(false);
    dispatch(bookingManagementActions.getBookingDetails({ bookingId }));
  };

  //handle button click
  const handleButtonClick = () => {
    if (keyType === paymentTypes.FULL_REFUND) {
      const payload = {
        data: { bookingId },
        successCallback: sucessCallbackCompleteRide,
      };
      dispatch(bookingManagementActions.completeRide(payload));
    } else if (keyType === paymentTypes.PARTIAL_REFUND) {
      setLoadedComponent(screenToogleKeys.PAYMENT_OPTIONS);
    }
  };

  //render the ui for payment types selected
  const renderContent = () => {
    if (updatedStatus === BookingPaymentStatus.SUCCESS) {
      return <PaymentSuccessUI paymentLink={paymentLink} />;
    } else if (updatedStatus === BookingPaymentStatus.FAILURE) {
      return <PaymentFailedUI paymentLink={paymentLink} />;
    } else {
      if (isPaymentLinkGenerated && paymentLink) {
        if (paymentLink.paymentFlow === OrderPaymentType.QR_CODE) {
          return paymentLink.pgDetails?.imageContent ? (
            <RenderQRCode
              paymentLink={paymentLink}
              totalCharges={paymentLink?.amount}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              isPaymentLinkGenerated={isPaymentLinkGenerated}
              onSuccesTimerCompletion={toggleCompleteRideDrawer}
            />
          ) : (
            <Stack gap="20px" alignItems="center">
              <RenderPaymentLink
                paymentLink={paymentLink}
                tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              />
            </Stack>
          );
        }
        if (paymentLink.paymentFlow === OrderPaymentType.PAYMENT_LINK) {
          return (
            <RenderPaymentLink
              paymentLink={paymentLink}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
        if (paymentLink.paymentFlow === OrderPaymentType.CASH) {
          return (
            <RenderCashUI
              paymentLink={paymentLink}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
      }
    }
    return renderPaymentOptions();
  };

  //try another payment method
  const tryAnotherPaymentMethod = () => {
    setLoadedComponent(screenToogleKeys.PAYMENT_OPTIONS);

    dispatch(
      rentalActions.clearReducerDataRentalCommon({
        isPaymentLinkGenerated: false,
        paymentLink: {},
        updatedStatus: "",
      })
    );
    dispatch(rentalActions.cancelPaymentStatus());
  };

  //handle paymentt and order id creation
  const handlePaymentClick = () => {
    if (Object.keys(orderData).length > 0) {
      const payload = {
        data: {
          orderId: orderData.id,
          paymentFlow: fields?.selectedPaymentMode,
        },
      };
      dispatch(rentalActions?.paymentLinkGeneration(payload));
    } else {
      let orderPayload: any = {
        bookingId,
        selectedPaymentMode: fields?.selectedPaymentMode,
      };

      dispatch(bookingManagementActions?.completeRidePayment(orderPayload));
    }
  };

  //render payment options
  const renderPaymentOptions = () => (
    <Stack gap="15px">
      <Box>
        <Typography
          color={colors.text_secondary_gray}
          fontSize="12px"
          fontWeight="500"
          marginBottom="5px"
        >
          {rentalEn.manualBooking.SelectPaymentMethod}:
        </Typography>

        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: "12px",
            border: "1px solid #00000029",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={fields?.selectedPaymentMode}
            onChange={(e: any) => handlePaymentModeChange(e)}
          >
            {operatorConfigData?.allowedPaymentFlows?.map(
              (mode: any, index: any) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      borderBottom: index < 2 ? "1px solid #D4E4E452" : "",
                      padding: "2px 18px",
                    }}
                  >
                    <FormControlLabel
                      value={mode}
                      control={<Radio />}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: colors.text_secondary_gray,
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginLeft: "10px",
                          }}
                        >
                          <img src={paymentModeIcons[mode]} />
                          <Typography
                            sx={{ fontWeight: 500, color: colors.text_black }}
                          >
                            {
                              DisplayPaymentModes[
                                mode as keyof typeof DisplayPaymentModes
                              ]
                            }
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                );
              }
            )}
          </RadioGroup>
        </Box>
      </Box>
    </Stack>
  );

  //get msgand button text
  const getPaymentChargeMsg = () => {
    if (
      pendingCharges?.securityCharge?.amount &&
      pendingCharges?.totalAmount === 0
    ) {
      keyType = paymentTypes.FULL_REFUND;
      return {
        key: paymentTypes.FULL_REFUND,
        buttonText: rentalEn?.buttonLabels?.completeRideInitiateRefund,
        message: "Customer will receive a full refund of the Security Deposit.",
        processingPayment: false,
      };
    } else if (
      pendingCharges?.securityCharge === undefined &&
      pendingCharges?.totalAmount !== 0
    ) {
      keyType = paymentTypes.PARTIAL_REFUND;
      return {
        key: paymentTypes.PARTIAL_REFUND,
        buttonText: rentalEn?.buttonLabels?.proceedToPay,
        message: `Customer have to pay remaining charges of ${
          pendingCharges?.totalAmount &&
          getFormattedINR(pendingCharges?.totalAmount)
        }.`,
        processingPayment: false,
      };
    } else if (
      !pendingCharges?.securityCharge &&
      pendingCharges?.totalAmount === 0
    ) {
      keyType = paymentTypes.FULL_REFUND;
      return {
        key: paymentTypes.FULL_REFUND,
        buttonText: rentalEn?.buttonLabels?.completeRide,
        message: `You do not have any dues or any security deposit.`,
        processingPayment: false,
      };
    } else if (
      pendingCharges?.securityCharge &&
      pendingCharges?.totalAmount &&
      pendingCharges?.totalAmount < pendingCharges?.securityCharge?.amount
    ) {
      keyType = paymentTypes.FULL_REFUND;
      return {
        key: paymentTypes.FULL_REFUND,
        buttonText: rentalEn?.buttonLabels?.completeRideInitiateRefund,
        message: `The due of ${
          pendingCharges?.totalAmount &&
          getFormattedINR(pendingCharges?.totalAmount)
        } will be adjusted from the security deposit of ${
          pendingCharges?.securityCharge?.amount &&
          getFormattedINR(pendingCharges?.securityCharge?.amount)
        } hence customer will receive a refund of ${
          pendingCharges?.securityCharge?.amount &&
          getFormattedINR(
            pendingCharges?.securityCharge?.amount - pendingCharges?.totalAmount
          )
        }.`,
        processingPayment: false,
      };
    } else if (
      pendingCharges?.securityCharge &&
      pendingCharges?.totalAmount &&
      pendingCharges?.totalAmount === pendingCharges?.securityCharge?.amount
    ) {
      keyType = paymentTypes.FULL_REFUND;
      return {
        key: paymentTypes.FULL_REFUND,
        buttonText: rentalEn?.buttonLabels?.completeRideInitiateRefund,
        message: `The due of ${
          pendingCharges?.totalAmount &&
          getFormattedINR(pendingCharges?.totalAmount)
        } will be adjusted from the Customer security deposit of ${
          pendingCharges?.securityCharge?.amount &&
          getFormattedINR(pendingCharges?.securityCharge?.amount)
        }.`,
        processingPayment: false,
      };
    } else if (
      pendingCharges?.securityCharge &&
      pendingCharges?.totalAmount &&
      pendingCharges?.totalAmount > pendingCharges?.securityCharge?.amount
    ) {
      keyType = paymentTypes.PARTIAL_REFUND;
      return {
        key: paymentTypes.PARTIAL_REFUND,
        buttonText: "Proceed To pay",
        message: `After deduction from security deposit, customer have to pay the remaining charges of ${
          pendingCharges?.securityCharge?.amount &&
          getFormattedINR(
            pendingCharges?.totalAmount - pendingCharges?.securityCharge?.amount
          )
        }.`,
        processingPayment: false,
      };
    } else if (
      pendingCharges?.charges?.length === 0 &&
      pendingCharges?.processingCharges?.length > 0
    ) {
      return {
        key: paymentTypes.FULL_REFUND,
        buttonText: rentalEn?.buttonLabels?.completeRide,
        message: rentalEn.alertMessages?.amountUnderProcessing,
        processingPayment: true,
      };
    }
    return {
      message: "",
    };
  };

  //complete ride ui
  const completeRideUI = () => {
    return (
      <Stack gap="20px" width="100%">
        <Stack
          sx={{
            backgroundColor: colors.primary_white_tone1,
            borderRadius: "12px",
          }}
        >
          {availablePendingCharges?.map((data: any) => {
            return (
              <>
                {data?.isAvailable && (
                  <SubscriptionItem
                    title={data?.heading}
                    amount={data?.amount}
                    padding="8px 16px"
                    amountColor={data?.color}
                  />
                )}
              </>
            );
          })}
        </Stack>

        {pendingAndProcessingCharges?.length ? (
          <DataTable
            columns={columns}
            rows={pendingAndProcessingCharges}
            getRowId={() => crypto.randomUUID()}
            columnHeaderHeight={45}
            hideFooter={true}
            disableColumnMenu
            disableColumnSorting
          />
        ) : (
          <></>
        )}

        {/* data table need to add  */}

        <Box sx={{ display: "flex", gap: "8px" }}>
          <InfoIcon sx={{ color: colors.sherpa_blue }} />
          <Typography>{getPaymentChargeMsg()?.message}</Typography>
        </Box>
      </Stack>
    );
  };

  //   procced to pay ui
  const proceedToPayUI = () => {
    return (
      <Stack width="100%" gap="20px">
        <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
          <Box
            className={styles?.backIcon_CompleteRide}
            onClick={() =>
              setLoadedComponent(screenToogleKeys.COMPLETE_RIDE_PROCCED_TO_PAY)
            }
          >
            <img src={LeftArrowIcon} />
          </Box>
          <Typography variant={typographyConstants.SUBHEADING}>
            {rentalEn?.bookingManagement?.chargeSummary}
          </Typography>
        </Box>
        <Stack
          sx={{
            border: "1px solid #0000001A",
            borderRadius: "12px",
            backgroundColor: colors?.primary_white_tone1,
          }}
        >
          {pendingAndProcessingCharges?.map((charge: any) => {
            return (
              <SubscriptionItem
                title={
                  bookingChargesSubCategoriesDisplay[
                    charge?.subCategory as keyof typeof bookingChargesSubCategoriesDisplay
                  ]
                }
                titleTooltip={charge?.name}
                description={charge?.description}
                amount={getFormattedINR(charge?.amount)}
                showBorder={true}
                padding="4px 16px"
              />
            );
          })}
          {pendingCharges?.securityCharge?.amount ? (
            <SubscriptionItem
              title={rentalEn?.bookingDetails?.securityDeposit}
              amount={`-${getFormattedINR(
                pendingCharges?.securityCharge?.amount
              )}`}
              amountColor={colors?.secondary_green}
              titleColor={colors?.secondary_green}
              padding="4px 16px"
            />
          ) : (
            <></>
          )}

          <Box className={styles.completeRidePaymentDetails}>
            <SubscriptionItem
              title={rentalEn?.bookingManagement.totalPayableAmount}
              amount={
                pendingCharges?.securityCharge?.amount
                  ? getFormattedINR(
                      pendingCharges?.totalAmount -
                        pendingCharges?.securityCharge?.amount
                    )
                  : getFormattedINR(pendingCharges?.totalAmount)
              }
              padding="4px 16px"
            />
          </Box>
        </Stack>
        {renderPaymentOptions()}
      </Stack>
    );
  };

  // render screen
  const arr: any = {
    COMPLETE_RIDE_PROCCED_TO_PAY: {
      key: screenToogleKeys.COMPLETE_RIDE_PROCCED_TO_PAY,
      component: completeRideUI(),
    },
    PAYMENT_OPTIONS: {
      key: screenToogleKeys.PAYMENT_OPTIONS,
      component: proceedToPayUI(),
    },

    PAYMENT_LINK_GENERATED: {
      key: screenToogleKeys?.PAYMENT_LINK_GENERATED,
      component: renderContent(),
    },
  };

  //payment link generated check
  useEffect(() => {
    if (isPaymentLinkGenerated)
      setLoadedComponent(screenToogleKeys?.PAYMENT_LINK_GENERATED);
  }, [isPaymentLinkGenerated]);

  return (
    <Stack padding={"14px"} gap={"14px"} alignItems={"center"}>
      {loadedComponent && arr[loadedComponent]?.component}

      {isPaymentLinkGenerated ? (
        <></>
      ) : (
        <Box className={styles?.buttonSideDrawer}>
          <CustomButton
            label={
              loadedComponent === screenToogleKeys.PAYMENT_OPTIONS
                ? getPaymentTypeText(fields?.selectedPaymentMode)
                : getPaymentChargeMsg()?.buttonText
            }
            variant="contained"
            style={{ width: "335px" }}
            onClick={
              loadedComponent === screenToogleKeys.PAYMENT_OPTIONS
                ? handlePaymentClick
                : handleButtonClick
            }
            disabled={
              getPaymentChargeMsg()?.processingPayment ||
              (loadedComponent === screenToogleKeys.PAYMENT_OPTIONS &&
                !fields?.selectedPaymentMode)
            }
            loading={
              pendingChargesLoader || completeRidePaymentLoader || paymentLoader
            }
          />
        </Box>
      )}
    </Stack>
  );
};
