import { Autocomplete, Box, TextField } from "@mui/material";
import { memo } from "react";
import styles from "../customcomponents.module.css";

const SearchBar = (props: any) => {
  const {
    onInputChange,
    searchLabel = "",
    data = [],
    selectedValue,
    searchValue,
    onSelect,
    getSearchKey,
    containerStyle = {},
    isLoading = false,
    searchPlaceholder,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        ...containerStyle,
      }}
    >
      <Autocomplete
        value={selectedValue}
        onChange={(event, newValue: any) => {
          onSelect(newValue);
        }}
        inputValue={searchValue}
        onInputChange={(event, newInputValue) => {
          onInputChange(newInputValue);
        }}
        id="controllable-states"
        getOptionLabel={getSearchKey}
        filterOptions={(x) => x} // override default searching
        options={data || []}
        loading={isLoading}
        className={styles.autocomplete}
        sx={{ width: props?.width ? props?.width : 300 }}
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            label={searchLabel}
            placeholder={searchPlaceholder}
            sx={{
              fieldset: {
                borderRadius: "8px",
                fontSize: "12px",
                minHeight: "40px",
                height: "48px",
                maxHeight: "50px",
                boxShadow:
                  "1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A",
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default memo(SearchBar);
