import { Box, Typography } from "@mui/material";
import { colors } from "../../../themes/colors";
import { TotalChargeLoader } from "../../molecules/SkeletonLoader/CheckoutLoader";
import styles from "../../components.module.css"
import { typographyConstants } from "../../../rentals-src/constants/constants";

const TotalCharge = (props: any) => {
  const remainingDuration = props?.recurringDuration
    ? props.recurringDuration - 1
    : props?.recurringDuration;
  return (
    <>
      {props?.loadingCharges ? (
        <TotalChargeLoader />
      ) : (
        <Box>
          <Box className={styles.totalChargeBox}>
            <Typography
              variant={typographyConstants.HEADING}
              color={colors.text_black}
            >
              {props.title}
            </Typography>
            <Box className={styles.amountBox}>
              <Typography
                variant={typographyConstants.HEADING}
                color= {colors.text_black}
              >
                {props.amount}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TotalCharge;
