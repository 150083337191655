import { API_URLS, customAxios, versioning } from "../config";

const dashboardService = (payload: any) =>
  customAxios
    .get(
      API_URLS.dashboardStatic +
        `?from_date=${payload.from_date}&end_date=${payload.end_date}&`
    )
    .catch((ex) => ex);

const dashboardDisplayTableService = (payload: any) => {
  let url = API_URLS.dashboardDisplayTabStatic;
  if (payload.page) url += `?page=${payload.page}`;
  if (payload.size && !isNaN(payload.size)) url += `&size=${payload.size}`;
  if (payload.date != 0 && payload.date) url += `&date=${payload.date}`;
  if (payload.flag != 0 && payload.flag) url += `&flag=${payload.flag}`;
  if (payload.model_id != 0 && payload.model_id)
    url += `&model_id=${payload.model_id}`;
  if (payload.payment_mode != 0 && payload.payment_mode)
    url += `&payment_mode=${payload.payment_mode}`;

  if (payload?.orderBy?.length > 0) {
    let order = "";
    payload?.orderBy?.forEach((each: string | any) => {
      order += each?.name + "-" + each?.value + ",";
    });
    url += `&order_by_clause=${order}&`;
  }
  if (
    payload.is_download != 0 &&
    payload.is_download &&
    !isNaN(payload.is_download)
  ) {
    url += `&is_download=${payload.is_download}`;
  } else {
    url += `&is_download=0`;
  }
  return customAxios.get(url).catch((ex) => ex);
};
const dashboardActionClickService = (payload: any) =>
  customAxios
    .put(versioning + API_URLS.warehouseApproval, payload)
    .catch((ex) => ex);

const dashboardViewCalendarService = (payload: any) =>
  customAxios.post(API_URLS.getCalendarView, payload).catch((ex) => ex);

const getVehicleUtilizationDates = (payload: any) => {
  let route = `/getVehicleUtilizationDates?`;
  route += payload.model_id ? `vehicle_model_id=${payload.model_id}&` : "";
  route += payload.city_id ? `city_id=${payload.city_id}&` : "";
  return customAxios.get(route).catch((ex) => ex);
};

const getVehicleUtilization = (payload: any) => {
  let route = `/getVehicleUtilization?`;
  route += payload.start_date ? `start_date=${payload.start_date}&` : "";
  route += payload.end_date ? `end_date=${payload.end_date}&` : "";
  route +=
    payload.model_id && payload.model_id > 0
      ? `vehicle_model_id=${payload.model_id}&`
      : "";
  route +=
    payload.city_id && payload.city_id > 0 ? `city_id=${payload.city_id}&` : "";
  route +=
    payload.branch_id && payload.branch_id > 0
      ? `branch_id=${payload.branch_id}&`
      : "";
  return customAxios.get(route).catch((ex) => ex);
};

const getPaymentGraph = (payload: any) => {
  // let route = `/api/admin/revenueGraph?`;
  let route = `/paymentsGraph?`;
  route += payload.start_date ? `start_date=${payload.start_date}&` : "";
  route += payload.end_date ? `end_date=${payload.end_date}&` : "";
  route +=
    payload.model_id && payload.model_id > 0
      ? `model_id=${payload.model_id}&`
      : "";
  route +=
    payload.city_id && payload.city_id > 0 ? `city_id=${payload.city_id}&` : "";
  route += payload.is_download ? `is_download=${payload.is_download}&` : "";
  route += payload.download_type
    ? `download_type=${payload.download_type}&`
    : "";
  // if (payload.download_type == 2) {
  //   return api.get(route, {responseType: 'arraybuffer'});
  // }
  return customAxios.get(route).catch((ex) => ex);
};

export const getPaymentGraphDateWise = (payload: any) => {
  // let route = `/api/admin/revenueGraph?`;
  let route = `/PaymentsWithDate?`;

  route += payload.start_date ? `start_date=${payload.start_date}&` : "";
  route += payload.end_date ? `end_date=${payload.end_date}&` : "";
  route +=
    payload.model_id && payload.model_id > 0
      ? `model_id=${payload.model_id}&`
      : "";
  route +=
    payload.city_id && payload.city_id > 0 ? `city_id=${payload.city_id}&` : "";
  route += payload.is_download ? `is_download=${payload.is_download}&` : "";
  route += payload.download_type
    ? `download_type=${payload.download_type}&`
    : "";
  return customAxios.get(route).catch((ex) => ex);
};
export const getRevenueGraph = (payload: any) => {
  // let route = `/api/admin/paymentPartitionGraph?`;
  let route = `/revenuePartitionGraph?`;
  route += payload.start_date ? `start_date=${payload.start_date}&` : "";
  route += payload.end_date ? `end_date=${payload.end_date}&` : "";
  route +=
    payload.model_id && payload.model_id > 0
      ? `model_id=${payload.model_id}&`
      : "";
  route +=
    payload.city_id && payload.city_id > 0 ? `city_id=${payload.city_id}&` : "";
  route += payload.is_download ? `is_download=${payload.is_download}&` : "";
  route += payload.download_type
    ? `download_type=${payload.download_type}&`
    : "";
  return customAxios.get(route).catch((ex) => ex);
};

export default {
  dashboardService,
  dashboardDisplayTableService,
  dashboardActionClickService,
  dashboardViewCalendarService,
  getVehicleUtilizationDates,
  getVehicleUtilization,
  getPaymentGraph,
  getRevenueGraph,
  getPaymentGraphDateWise,
};
