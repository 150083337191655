import * as actionTypes from "../actionTypes";
import {
  AddIotDevicePayload,
  AssignIOTDevicePayload,
  GetIotDetailsPayload,
  GetIotPingHistoryPayload,
  GetVehicleIOTDevicePayload,
  PingDevicePayload,
  UpdateIotDetailsPayload,
} from "../../../interfaces/iotManagementInterface";

// action to add new iot devices
export const addIotDevice = (payload: AddIotDevicePayload) => ({
  type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
    .RENTALS_ADD_IOT_DEVICE_REQUESTED,
  payload,
});

//action to get all iot device list
export const getAllIotList = (payload: any) => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_ALL_IOT_REQUESTED,
    payload,
  };
};

//action to clear all iot list in reducer
export const clearAllIotList = () => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_CLEAR_ALL_IOT_LIST,
  };
};

// action to get all vendor of iot
export const getAllVendors = () => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_ALL_IOT_VENDORS_REQUESTED,
  };
};

//action to get iot device details by iot id
export const getIotDeviceDetails = (payload: GetIotDetailsPayload) => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_DEVICE_DETAILS_REQUESTED,
    payload,
  };
};

// action to get ping status of vehicle
export const getPingData = (payload: PingDevicePayload) => ({
  type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_DEVICE_PING_REQUESTED,
  payload,
});

// action to clear ping data
export const clearPingData = () => ({
  type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION.RENTALS_CLEAR_DEVICE_PING,
});

//action to update iot device details
export const updateIotDeatils = (payload: UpdateIotDetailsPayload) => ({
  type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
    .RENTALS_UPADTE_IOT_DETAILS_REQUESTED,
  payload,
});

// action to get iot ping history
export const iotPingHistory = (payload: GetIotPingHistoryPayload) => ({
  type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
    .RENTALS_DEVICE_PING_HISTORY_REQUESTED,
  payload,
});

  //action to upload iot device in bulk
export const bulkUploadIot = (payload: any) => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_IOT_BULK_UPLOAD_REQUESTED,
    payload,
  };
};

//action to reset iot bulk upload data
export const resetIotBulkUpload = () => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_IOT_BULK_UPLOAD_RESET,
  };
};

// action to get iot upload format
export const getIotUploadFormat = () => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_UPLOAD_FORMAT_REQUESTED,
  };
};

// action to get iot odometer reading (need to remove if functionality dosen't exist)
export const getVehicleIotOdameter = (payload: any) => {
  return {
    type: actionTypes.RENTALS_IOT_MANAGEMENT_ACTION
      .RENTALS_GET_IOT_ODOMETER_DETAILS_REQUESTED,
    payload,
  };
};
