const planManagementRoutes = {
  PLAN: "/plan",
  PLAN_MANAGEMENT: "/plan-management",
  ADD_PLAN_MANAGEMENT: "/plan-management/add-plan",
  ADD_EDIT_PLAN_DETAIL: "/plan-management/add-edit-plan",
  ADD_EDIT_PLAN_WITH_ID_DETAIL: "/plan-management/add-edit-plan/:id",
};

const surgeRoutes = {
  SURGE_LIST: "/plan/surge-list",
  CREATE_SURGE: "/plan/create-surge",
  CREATE_SURGE_WITH_ID_DETAIL: "/plan/create-surge/:id",
};

const businessManagementRoutes = {
  BUSINESS_OVERVIEW: "/business-management/overview",
  BUSINESS_CITY_MANAGEMENT: "/business-management/city-management",
  BUSINESS_WAREHOUSE_MANAGEMENT: "/business-management/warehouse-management",
  EDIT_BUSINESS_WAREHOUSE_MANAGEMENT:
    "/business-management/warehouse-management/edit-warehouse/",
  ADD_BUSINESS_WAREHOUSE_MANAGEMENT:
    "/business-management/warehouse-management/add-warehouse",
  ROLE_MANAGEMENT: "/business-management/role-management",
  ADD_ROLE: "/business-management/add-role",
  HELMET_CONFIGURATION: "/business-management/helmet-configuration",
  ADD_HELMET_CONFIGURATION:
    "/business-management/helmet-configuration/add-helmet-configuration",
  EDIT_HELMET_CONFIGURATION:
    "/business-management/helmet-configuration/edit-helmet-configuration/",
  VIEW_ROLE_USER_DETAILS:
    "/business-management/view-role-user-details-by-userid",
  VIEW_ROLE_USER_DETAILS_BY_ID:
    "/business-management/view-role-user-details-by-userid/:id",
  ADD_ROLE_BY_ID: "/business-management/add-role/:id",
};

const taskManagementRoutes = {
  TASK_SEQUENCE: "/task-list-management/task-sequence",
  TASK_ASSIGNMENT: "/task-assignment",
  DRAG_TASK_SEQUENCE: "/drag-task-sequence",
};

const inventoryRoutes = {
  INVENTORY_DETAILS: "/inventory-details",
};

const manualBooking = {
  MANUAL_BOOKING: "/booking-management/new-booking",
};

const vehicleManagement = {
  ALL_VEHICLES: "/all-vehicles",
  VEHICLE_MANAGEMENT: "/vehicle-management",
  ADD_EDIT_VEHICLE: "/add-edit-vehicles",
  VIEW_VEHICLE: "/business-management/vehicle-management/",
  ADD_EDIT_VEHICLE_WITH_ID_DETAILS: "/add-edit-vehicles/:id",
  VEHICLE_STATUS_UPDATE: "/vehicle-status-update",
  APPROVE_REJECT_VEHICLE: "/approve-reject-vehicle",
  BULK_UPLOAD: "/bulk-upload",
  VEHICLE_TRANSFER: "/vehicle-transfer",
  VEHICLE_TRANSFER_HISTORY: "/vehicle-transfer-history",
};

const bookingManagement = {
  ALL_BOOKINGS: "/booking-management/all-booking",
  BOOKING_DETAILS: "/booking-management/booking-details",
  MODIFY_BOOKING_DETAILS: "/booking-management/modify-booking",
};

const iotManagement = {
  IOT_LISTING: "/iot-management-rentals/iot-listing",
  EDIT_IOT: "/iot-management-rentals/edit-view-iot",
  ADD_IOT: "/iot-managaement-rentals/add-iot",
  BULK_UPLOAD_IOT: "/iot-managaement-rentals/bulk-upload",
};

const customerManagement = {
  CUSTOMER_MANAGEMENT: "/customer-management",
  ADD_CUSTOMER: "/customer-management/add-customer",
  CUSTOMER_DETAIL: "/customer-management/:id",
};

const bookingManagementRoutes = {
  BOOKING_MANAGEMENT: "/booking-management",
};

const paymentManagementRoutes = {
  PAYMENT_MANAGEMENT: "/payment-management",
};

const operatorManagementRoutes = {
  OPERATOR_MANAGEMENT: "/operator-management",
  OPERATOR_MANAGEMENT_LEADS: "/operator-management/lead",
  OPERATOR_MANAGEMENT_LEAD_DETAILS: "/operator-management/lead",
  APPROVED_OPERATOR: "/operator-management/approved-operator",
  APPROVED_OPERATOR_DETAILS: "/operator-management/approved-operator/details",
  LEAD_DETAILS: "/operator-management/lead/details",
  ACCOUNT_DETAILS: "/account-details",
};
const authRoutes = {
  CHANGE_PASSWORD: "/change-password",
  SET_PASSWORD: "/set-password",
};

const dashboard = {
  DASHBOARD: "/dashboard",
};

const reports = {
  REPORTS: "/reports",
};

export const routesConstants = {
  ...planManagementRoutes,
  ...surgeRoutes,
  ...businessManagementRoutes,
  ...inventoryRoutes,
  ...manualBooking,
  ...vehicleManagement,
  ...bookingManagement,
  ...iotManagement,
  ...customerManagement,
  ...bookingManagementRoutes,
  ...paymentManagementRoutes,
  ...taskManagementRoutes,
  ...operatorManagementRoutes,
  ...authRoutes,
  ...dashboard,
  ...reports,
};
