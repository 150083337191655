import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import en from "../../../../locale/en.json";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import { GenderSelection } from "../../../../sharedComponents/templates/GenderSelection";
import { typographyConstants } from "../../../constants/constants";
import { MailIcon, MobileIcon } from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import { checkAlfa, checkPhone, checkWhiteSpace } from "../../../utils/regex";
import Styles from "./section.module.css";
import { UserDetailsInterface } from "../../../interfaces/manualBookingInterfaces";

const UserDetails = ({
  fields,
  setFields,
  setErrorFields,
  errorFields,
  checkUserLoader,
  showUserData,
  checkIsUserExist,
  SearchUser,
  setClearFields,
  validateRegisterUserDetails,
}: UserDetailsInterface) => {
  //if user doesnot exist show clear button
  useEffect(() => {
    if (showUserData && !checkIsUserExist) setClearFields(true);
  }, [checkIsUserExist, showUserData]);

  // handle state change
  const handleChange = useCallback(
    (field: string, value: any) => {
      setFields((prevState: any) => ({ ...prevState, [field]: value }));
      setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
      if (field === "firstName" || field === "lastName") {
        if (value.length < 3) {
          setErrorFields((prevState: any) => ({
            ...prevState,
            [field]: rentalEn?.errorMessages?.MustBeGreaterThan3,
          }));
        }
      }
    },
    [setFields, setErrorFields]
  );

  const CheckUserForm = () => {
    return (
      <Box width="590px">
        <form onSubmit={SearchUser}>
          <Typography variant={typographyConstants.HEADING}>
            {en.NewManualBooking.EnterUserDetails}
          </Typography>
          <Stack gap="2px">
            <Box className={Styles.checkUserForm}>
              <Stack>
                <Input
                  onChange={(e: any) => {
                    e.preventDefault();
                    if (
                      checkPhone(e.target.value) &&
                      e.target.value?.length < 11
                    ) {
                      handleChange("mobileNumber", e.target.value);
                    }
                  }}
                  iconStart={MobileIcon}
                  value={fields.mobileNumber}
                  placeholder={en.NewManualBooking.EnterNumber}
                  label={en.NewManualBooking.MobileNumber}
                  errormessage={errorFields.mobileNumber}
                  disabled={
                    checkIsUserExist || (showUserData && !checkIsUserExist)
                  }
                />
              </Stack>

              <CustomButton
                label={
                  showUserData && !checkIsUserExist
                    ? en.NewManualBooking.Clear
                    : en.NewManualBooking.Search
                }
                back={true}
                variant="outlined"
                disabled={fields.mobileNumber.length < 10}
                loading={checkUserLoader}
                onClick={SearchUser}
                sx={{ width: "150px", minHeight: "42px", maxHeight: "50px" }}
              />
            </Box>
            {showUserData && !checkIsUserExist && (
              <Typography
                variant={typographyConstants.BODY}
                fontSize={"10px"}
                marginLeft={"8px"}
              >
                {en.NewManualBooking.UserNotRegistered}
              </Typography>
            )}
          </Stack>
        </form>
      </Box>
    );
  };

  const RegisterUser = () => {
    return (
      <Stack gap="25px">
        <Stack gap="5px">
          <Box className={Styles.userDetails}>
            <Input
              value={fields.firstName}
              placeholder={en.NewManualBooking.EnterFirstName}
              label={en.NewManualBooking.EnterFirstName}
              errormessage={errorFields.firstName}
              containerWidth="100%"
              width="100%"
              onChange={(e: any) => {
                checkAlfa(e.target.value) &&
                  checkWhiteSpace(e.target.value) &&
                  e.target.value.length < 32 &&
                  handleChange("firstName", e.target.value);
              }}
            />
            <Input
              value={fields.lastName}
              placeholder={en.NewManualBooking.EnterLastName}
              label={en.NewManualBooking.EnterLastName}
              errormessage={errorFields.lastName}
              containerWidth="100%"
              width="100%"
              onChange={(e: any) => {
                checkAlfa(e.target.value) &&
                  e.target.value.length < 32 &&
                  checkWhiteSpace(e.target.value) &&
                  handleChange("lastName", e.target.value);
              }}
            />
            <Input
              iconStart={MailIcon}
              value={fields.email}
              placeholder={en.NewManualBooking.EnterEmail}
              label={en.NewManualBooking.EnterEmail}
              errormessage={errorFields.email}
              containerWidth="100%"
              width="100%"
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("email", e.target.value);
              }}
            />
          </Box>

          <GenderSelection
            handleChange={handleChange}
            fields={fields}
            errorFields={errorFields}
          />
        </Stack>
        <Box>
          <CustomButton
            label={en.NewManualBooking.SendOTP}
            variant="outlined"
            sx={{ width: "300px" }}
            onClick={validateRegisterUserDetails}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Stack gap={"25px"}>
      {CheckUserForm()}
      <Box className={Styles.BoxColumn}>
        {showUserData && !checkIsUserExist ? (
          <>
            <Typography variant={typographyConstants.HEADING}>
              {en.NewManualBooking.RegisterUser}
            </Typography>
            <Fade> {RegisterUser()}</Fade>
          </>
        ) : null}
      </Box>
    </Stack>
  );
};

export default UserDetails;
