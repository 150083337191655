import moment from "moment";
import { isArrayNotEmpty } from "../../../../utils/helper";
import { GenericObject } from "../../../constants/constants";
import rentalEn from "../../../locale/rental-en.json";
import { appendCommaSpace } from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";

interface initialState {
  onLoad: boolean;
  vehicleModelListByCityData: GenericObject[];
  createSurgeSuccessData: GenericObject;
  surgeListData: GenericObject[];
  surgeListApiResponseData: GenericObject;
  pageNumber: number;
  updatedSurgeStatus: GenericObject;
  surgeByIdData: GenericObject;
  updatedSurgeData: GenericObject;
  addEditSurgeLoader: boolean;
}

const initialState: initialState = {
  onLoad: false, // Indicates whether the page is currently loading
  vehicleModelListByCityData: [], // Stores vehicle model list data based on an array of cities
  createSurgeSuccessData: {}, // Stores data from API response upon successful creation of a surge
  surgeListData: [], // Stores data from the surge list API; example format: [{…}, {…}, {…}]
  surgeListApiResponseData: {}, // Stores the entire response from the surge list API, including pagination information; example format:  {pagination: {…}, surgeData: [{…}, {…}, {…}]}
  pageNumber: 1, // Current page number extracted from pagination object in surge list API response
  updatedSurgeStatus: {}, // Stores the API response data after updating surge status
  surgeByIdData: {}, // Stores API response data for a specific surge identified by ID
  updatedSurgeData: {}, // Stores updated surge data received from API
  addEditSurgeLoader: false, // Indicates whether the add/edit surge process is in progress
};

/**
 * Modifies the surge list data to meet requirements for MUI data grid display.
 * It adds unique `id`, formats date types, vehicle models, durations, and branches display names.
 * @param updatedSurgeListDataParam The array of surge list data to be modified.
 * @returns The modified surge list data with necessary transformations.
 */
const getModifiedSurgeListData = (updatedSurgeListDataParam: any[]) => {
  // Initialize temporary array to hold modified surgeListData
  let tempSurgeListData: any[] = [];

  // Check if updated surge list data array is not empty
  if (isArrayNotEmpty(updatedSurgeListDataParam)) {
    // Map through surgeListData to apply transformations
    tempSurgeListData = updatedSurgeListDataParam?.map(
      (item: any, index: number) => {
        item.id = item.surgeId; // apend an unique `id` for the mui data grid // Note: MUI: The data grid component requires all rows to have a unique `id` property.

        // Handle or modify weekdays/specific date type values
        if (
          item?.dateType === rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS
        ) {
          // Capitalize first letter and convert rest to lowercase for each day
          let tempArr: any = item.daysOfWeek.map((i: any) => {
            return i.charAt(0).toUpperCase() + i.slice(1).toLowerCase();
          });
          item.tempDateType = appendCommaSpace(tempArr);
        } else if (
          item?.dateType === rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
        ) {
          // Format specific date range as DD/MM/YYYY - DD/MM/YYYY
          item.tempDateType = [
            `${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
              item.endDate
            ).format("DD/MM/YYYY")}`,
          ];
        } else if (!item?.dateType) {
          // Set default value if dateType is not defined
          item.tempDateType = rentalEn?.global?.NA;
        }

        // Handle or modify vehicle model display name
        item.tempModelsDisplayName = item?.modelsDisplayName?.length
          ? appendCommaSpace(item?.modelsDisplayName)
          : rentalEn?.global?.All;

        // Handle or modify packages duration
        if (item?.durations?.length === 1 && item?.durations[0] === 1) {
          item.tempDurations = `${item.durations[0]} Day`;
        } else if (item?.durations?.length) {
          // Join durations with comma and space, then append ' Days'
          item.tempDurations =
            item?.durations?.map((i: any) => i.toString()).join(", ") + " Days";
        } else if (!item?.durations?.length) {
          // Set default value if durations array is empty
          item.tempDurations = rentalEn?.global?.All;
        }

        // Handle or modify branches/hubs display name
        item.tempBranchesDisplayName = item?.branchesDisplayName?.length
          ? appendCommaSpace(item?.branchesDisplayName)
          : rentalEn?.global?.All;

        // Return the modified item
        return item;
      }
    );
  }

  // Return the modified surge list data
  return tempSurgeListData;
};

/**
 * This function updates the surge list data based on the provided payload and current state.
 * It handles two scenarios: updating surgeStatus for a specific surgeId and replacing surgeList entirely.
 * @param payload The payload containing data to update surge list.
 * @param state The current state object containing surgeListData.
 * @returns An updated array of surge list data.
 */
const getUpdatedSurgeListData = (payload: any, state: any) => {
  // Initialize an empty array to hold updated surge list data
  let updatedSurgeListData: any[] = [];

  // Determine the update scenario based on payload
  switch (true) {
    // If the update is triggered from surgeStatus update
    case payload?.fromSurgeStatusUpdate:
      // Map through current surgeListData and update surgeStatus for the specific surgeId
      updatedSurgeListData = state?.surgeListData?.map((item: any) => {
        if (item.surgeId === payload?.updatedSurgeStatus?.surgeId) {
          // Update surgeStatus for matching surgeId
          return {
            ...item,
            surgeStatus: payload?.updatedSurgeStatus?.surgeStatus,
          };
        }
        // Return unchanged item for non-matching surgeIds
        return item;
      });
      break;

    // If the update is not from surgeStatus update, replace surgeList entirely
    case !payload?.fromSurgeStatusUpdate:
      // Replace updatedSurgeListData with new surgeListApiResponseData
      updatedSurgeListData = [...payload?.surgeListApiResponseData?.surgeData];
      break;
  }

  // Get modified surge list data using helper function
  const modifiedSurgeListData = getModifiedSurgeListData(updatedSurgeListData);

  // Return the updated & modified surge list data
  return [...modifiedSurgeListData];
};

// ***
const surgeReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    // Handle surge management failures and errors
    case actionTypes.SURGE_ACTIONS.SURGE_MANAGEMENT_FAIL:
    case actionTypes.SURGE_ACTIONS.SURGE_MANAGEMENT_ERROR:
      return {
        ...state,
        ...payload,
        onLoad: false,
      };

    // Request case for updating surge status
    case actionTypes.SURGE_ACTIONS.UPDATE_SURGE_STATUS_REQUESTED:
      return {
        ...state,
        onLoad: true,
      };

    // Request cases for creating, and updating surge
    case actionTypes.SURGE_ACTIONS.CREATE_SURGE_REQUESTED:
    case actionTypes.SURGE_ACTIONS.UPDATE_SURGE_REQUESTED:
      return {
        ...state,
        onLoad: true,
        addEditSurgeLoader: true,
      };

    // Handle successful creation of surge
    case actionTypes.SURGE_ACTIONS.CREATE_SURGE_SUCCESS:
      return {
        ...state,
        createSurgeSuccessData: payload?.createSurgeSuccessData,
        onLoad: false,
        addEditSurgeLoader: false,
      };

    // Request for getting surge list
    case actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_REQUESTED:
      return {
        ...state,
        surgeListApiResponseData: {},
        surgeListData: [],
        onLoad: true,
      };
    case actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_SUCCESS:
      // Update surge list data based on retrieved payload
      let newSurgeListData = getUpdatedSurgeListData(payload, state);
      return {
        ...state,
        surgeListApiResponseData: payload?.fromSurgeStatusUpdate
          ? state?.surgeListApiResponseData
          : payload?.surgeListApiResponseData,
        surgeListData: newSurgeListData,
        onLoad: false,
      };

    // Handle successful update of surge status
    case actionTypes.SURGE_ACTIONS.UPDATE_SURGE_STATUS_SUCCESS:
      return {
        ...state,
        updatedSurgeStatus: payload?.updatedSurgeStatus,
        onLoad: false,
      };

    // Request for getting surge details by ID
    case actionTypes.SURGE_ACTIONS.GET_SURGE_BY_ID_REQUESTED:
      return {
        ...state,
        surgeByIdData: {},
        onLoad: true,
      };
    case actionTypes.SURGE_ACTIONS.GET_SURGE_BY_ID_SUCCESS:
      return {
        ...state,
        surgeByIdData: payload?.surgeByIdData,
        onLoad: false,
      };

    // Handle successful update/edit of surge
    case actionTypes.SURGE_ACTIONS.UPDATE_SURGE_SUCCESS:
      return {
        ...state,
        updatedSurgeData: payload?.updatedSurgeData,
        onLoad: false,
        addEditSurgeLoader: false,
      };

    // Reset reducer state action
    case actionTypes.SURGE_ACTIONS.RESET_REDUCER_STATE:
      return {
        ...state,
        ...payload,
        onLoad: false,
      };

    // Default case: return current state
    default:
      return { ...state };
  }
};

export default surgeReducer;
