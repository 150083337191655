import { getStore } from "../../../redux/store";

export const bookingDetailsModel = (data: any) => {
  const bookingDetails = [
    { displayKey: "Booking ID", value: "ABG121IG" },
    { displayKey: "Payment Type", value: "Online" },
    { displayKey: "Subscription Start Date", value: "20/09/2018" },
    { displayKey: "Mode of Delivery", value: "Agent" },
    { displayKey: "Date of Booking", value: "20/09/2018" },
    { displayKey: "Payment Date", value: "20/09/2018" },
    { displayKey: "Subscription End date", value: "20/09/2018" },
    { displayKey: "Pickup Address", value: "Noida/Omkar-square, sector 18" },
    { displayKey: "Booking Type", value: "LTR" },
    { displayKey: "Warehouse", value: "Gurugram/Omkar-square, sector 18" },
  ];

  const bookingDetailsTop = [
    { displayKey: "Last Payment date", value: "20/09/2018" },
    { displayKey: "Next Payment date", value: "20/09/2018" },
    { displayKey: "Plan Selected", value: "Pleasent Plan" },
    { displayKey: "Challans", value: "Traffic signal violation" },
    { displayKey: "Last Payment date", value: "20/09/2018" },
    { displayKey: "Last Payment date", value: "20/09/2018" },
  ];

  return { bookingDetails, status: "CREATED", bookingDetailsTop };
};
