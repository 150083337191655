import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { colors } from "../../../../../themes/colors";
import { typographyConstants } from "../../../../constants/constants";
import styles from "./section.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const NameModal = ({
  customerId,
  nameTrustScore,
  namePayloadObj,
}: any) => {
  const { uploadLoader, signedUrlFailedFiles, linearProgressValue } =
    useSelector((state: RootState) => state.customerManagementReducer);

  const isPresent = nameTrustScore?.isPresent;
  const isVerified = nameTrustScore?.isVerified;
  const verificationStatus = isVerified ? "Verified" : "Not verified";
  const verificationMode = nameTrustScore?.verificationMode;
  const nameDetails = nameTrustScore?.details;
  const dlFirstName = nameDetails?.dlFirstName;
  const profileFirstName = nameDetails?.profileFirstName;

  return (
    <Stack className={styles.KYCModal_Wrapper}>
      <Stack gap="20px">
        {/* Aadhar Name */}
        <Stack
          sx={{
            backgroundColor: colors.bg_light_gray,
            borderRadius: "10px",
            border: "1px solid #E2E2E2",
          }}
        >
          <Box
            className={styles.selfie_View_Box}
            sx={{
              backgroundColor: "#053C3D",
              color: "#fff",
              borderRadius: "16px 0px",
              padding: "2px 20px",
            }}
          >
            <Typography
              variant={typographyConstants?.SUBHEADING}
              color={"#fff"}
            >
              First name match
            </Typography>{" "}
          </Box>

          <Stack sx={{ padding: "10px" }}>
            <Box className={styles.selfie_status_Box} alignItems={"center"}>
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={colors.tertiary_black}
              >
                Verified Status
              </Typography>
              <Stack direction="row" alignItems={"center"}>
                {isVerified ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.text_black}
                >
                  {verificationStatus}
                </Typography>
              </Stack>
            </Box>
            <Box
              className={styles.selfie_status_Box}
              sx={{
                border: "1px solid #B1B1B4",
                boxShadow: "1px 1px 2px 0px #00000021 inset",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={colors.tertiary_black}
              >
                {dlFirstName ?? "N/A"}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
