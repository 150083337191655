import * as actionTypes from "../actionTypes";

interface GetRentalPlanInterfaceX {
  modal: string;
  name: string;
}

export const getRentalPlansX = (payload: GetRentalPlanInterfaceX) => ({
  type: actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_REQUESTED,
  payload,
});

export const clearRentalPlansX = () => ({
  type: actionTypes.CLEAR_DATA_ACTIONS_TYPE_X.CLEAR_DATA_RENTAL_PLAN_X,
});
