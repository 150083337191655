import axios from "axios";

import {
  API_URLS,
  BASE_URL,
  BASE_URL_UAT,
  customAxios,
  FREEDO_BIZ_X_URL,
  getApiUrl,
} from "../config";
import { LoginFields } from "../screens/newAuthScreens";
import CONSTANTS from "../locale/en.json";
import { IDefaultRequestHeaders } from "../interfaces/commonInterface";

const requestHeaders: IDefaultRequestHeaders | any = {
  "x-platform": CONSTANTS?.requestHeaders?.["x-platform"],
  "x-channel": CONSTANTS?.requestHeaders?.["x-channel"],
  "x-client-id": CONSTANTS?.requestHeaders?.["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent ?? "",
};

// const userForgotPassword = (payload: any) => {
//   try {
//     return axios
//       .post(BASE_URL_UAT + API_URLS.forgotPassword, payload, {
//         headers: requestHeaders,
//       })
//       .catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

const userForgotPassword = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().forgotPassword;
    return axios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// const changePassword = (payload: any) => {
//   try {
//     return customAxios
//       .put(BASE_URL_UAT + API_URLS.changePassword, payload, {
//         headers: requestHeaders,
//       })
//       .catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

const changePassword = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().changePassword;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// const setPassword = (payload: any) => {
//   try {
//     return axios
//       .post(BASE_URL_UAT + API_URLS.setPassword, payload, {
//         headers: requestHeaders,
//       })
//       .catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

const setPassword = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().setPassword;
    return axios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// const unlockAccount = (payload: any) => {
//   try {
//     return axios
//       .post(BASE_URL_UAT + API_URLS.unlockAccount, payload, {
//         headers: requestHeaders,
//       })
//       .catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

const unlockAccount = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().unlockAccount;
    return axios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const multifactorAuth = (payload: any) => {
  const { referenceId, otp } = payload;
  const customPayload = {
    data: {
      otp: otp,
      referenceId: referenceId,
    },
  };
  try {
    return axios
      .post(BASE_URL + API_URLS.verifyOTP, customPayload, {
        headers: requestHeaders,
      })
      .catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const loginV1 = (payload: any) => {
  try {
    return axios
      .post(FREEDO_BIZ_X_URL + API_URLS.loginV1, payload, {
        headers: requestHeaders,
      })
      .catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// const linkVerification = (payload: any) => {
//   try {
//     return axios
//       .post(BASE_URL_UAT + API_URLS.linkVerification, payload, {
//         headers: requestHeaders,
//       })
//       .catch((ex) => ex);
//   } catch (ex) {
//     return ex;
//   }
// };

const linkVerification = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().linkVerification;
    return axios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const resendOtp = (payload: any) => {
  try {
    return axios
      .post(BASE_URL + API_URLS.resendOtp, payload, { headers: requestHeaders })
      .catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  userForgotPassword,
  changePassword,
  setPassword,
  multifactorAuth,
  loginV1,
  linkVerification,
  unlockAccount,
  resendOtp,
};
