import * as actionTypes from "../actionTypes";

interface genericObj {
  [key: string]: any;
}

// create new customer
export const registerCustomer = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_REQUESTED,
    payload,
  };
};

// verify OTP
export const verifyCustomerOTP = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_REQUESTED,
    payload,
  };
};

// Resend OTP
export const resendCustomerOTP = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_REQUESTED,
    payload,
  };
};

// reset customer otp
export const resetInvalidOtp = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_INVALID_OTP_FLAG,
  };
};

// Revert isReset value
export const revertIsResetFlag = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .REVERT_IS_RESENT_FLAG_REQUESTED,
  };
};

//search whether the customer exist or not
export const searchCustomerAction = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .SEARCH_CUSTOMER_DETAILS_REQUESTED,
    payload,
  };
};

// Dashboard stats
export const getCustomerDashBoardBookingStats = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_DASHBOARD_STATS_REQUESTED,
    payload,
  };
};

// get customer profile data
export const getCustomerProfileData = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_PROFILE_DATA_REQUESTED,
    payload,
  };
};

// get emergency info for customer
export const getEmergencyContactForCustomer = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
    payload,
  };
};

// get customer kyc trust score
export const getCustomerKycTrustScore = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED,
    payload,
  };
};
// add emergency contact details
export const addEmergencyContactDetails = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED,
    payload,
  };
};

// Booking stats
export const getCustomerBookingStats = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_STATS_REQUESTED,
    payload,
  };
};

// block customer
export const blockCustomer = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_REQUESTED,
    payload,
  };
};

// unblock customer
export const unblockCustomer = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_REQUESTED,
    payload,
  };
};

// delete customer
export const deletecustomer = (payload: genericObj) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_REQUESTED,
    payload,
  };
};

// verify emergency otp
export const verifyEmergencyContactOTP = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .VERIFY_EMERGENCY_OTP_REQUESTED,
    payload,
  };
};

// Resend OTP
export const resendEmergencyCustomerOTP = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESEND_EMERGENCY_OTP_REQUESTED,
    payload,
  };
};

// get customer booking list
export const getCustomerBookingList = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_LIST_REQUESTED,
    payload,
  };
};

// reset emergency otp flag
export const resetEmergencyOtpFlag = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_EMERGENCY_OTP_FLAG_REQUESTED,
  };
};
// reset emergency user
export const resetEmergencyDetails = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_EMERGENCY_DETAILS_REQUESTED,
  };
};

// get inidividual kyc detail
export const getIndividualKycDetails = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_INIDIVIDUAL_KYC_DETAILS_REQUESTED,
    payload,
  };
};

// clear inidividual kyc detail
export const clearIndividualKycDetails = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.CLEAR_INIDIVIDUAL_KYC_DETAILS,
  };
};

//1. Action to generate signed url for particular document
export const getKYCSignedUrl = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.GET_KYC_SIGNED_URLS_REQUESTED,
    payload,
  };
};

// move document to customer folder in S3
export const moveKYCDocumentToS3Folder = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .POST_KYC_DOCUMENT_IN_S3_REQUESTED,
    payload,
  };
};

// delete selfie
export const resetSelfieAttachmentId = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_SELFIE_ATTACHMENT_ID,
  };
};

// reset doc success flag
export const resetDocUploadSuccessFlag = () => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_DOC_UPLOAD_SUCCESS_FLAG,
  };
};

// manually verify Kyc
// {{base_url}}/freedo-grahak/kyc/v1/1JQRTHMFR37K/verify/1MENV3B1CHDS
export const manuallyVerifyKyc = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_REQUESTED,
    payload,
  };
};

// reset doc success flag
export const resetOtherDocsAttachmentId = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_OTHER_DOCS_ATTACHMENT_ID,
    payload,
  };
};

// reset cached kyc payload data from reducer
export const resetKycDetailsPayload = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_KYC_DETAILS_PAYLOAD_OBJECT,
    payload,
  };
};

// reset cached kyc payload data from reducer
export const resetOtherDocumentsKycDetailsPayload = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_OTHER_DOCS_ATTACHMENT_ID,
    payload,
  };
};

// clear kyc documents
export const deleteCustomerKycType = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_KYC_REQUESTED,
    payload,
  };
};

// unverify customer kyc
export const unverifyCustomerKyc = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .UNVERIFY_CUSTOMER_KYC_REQUESTED,
    payload,
  };
};

// action to down load customer booking liast
export const downloadCustomerBookingList = (payload: any) => {
  return {
    type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_REQUESTED,
    payload,
  };
};
