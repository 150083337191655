import { Button } from "@mui/material";
import { colors } from "../themes/colors";
import ResponsiveDialog from "./responsiveDialog";
import en from "../locale/en.json";
export const AlertDialogComponent = ({
  handleClose = () => {/**/},
  handleClickYes = () => {/**/},
  open = false,
  title = "",
}) => {
  return (
    <ResponsiveDialog
      titlestyle={{
        color: colors.dark_gray,
        fontFamily: "EuclidCircularB-Regular",
        fontSize: "18px",
      }}
      // maxWidth="md"
      fullScreen={false}
      agreeText="Yes"
      disAgreeText="No"
      title={title || en?.alertMessages?.deleteItem}
      handleClose={handleClose}
      open={open}
    >
      <Button
        style={{ margin: "0 auto", display: "flex", color: colors.black }}
        autoFocus
        onClick={handleClickYes}
      >
        Yes
      </Button>
      <Button
        style={{ margin: "0 auto", display: "flex", color: colors.black }}
        onClick={handleClose}
        autoFocus
      >
        No
      </Button>
    </ResponsiveDialog>
  );
};
