import React from "react";
import { Grid } from "@mui/material";

interface IObjectKeys {
 [key: string]: number | string | Date | boolean | null | any | undefined;
}

interface rowFields extends IObjectKeys {
 colOne: any;
 colTwo: any;
 marginBottom?: string | number | null;
}

const RowGrid = ({ colOne, colTwo, marginBottom }: rowFields) => {
 return (
  <div>
   <Grid
    sx={{
     padding: "0px",
     marginBottom: marginBottom || "3px",

     // backgroundColor: "rgb(249, 249, 249);",
    }}
    container
    direction="row"
    justifyContent="space-between"
   >
    <Grid item xs={6}>
     {colOne}
    </Grid>
    <Grid item xs={6}>
     {colTwo}
    </Grid>
   </Grid>
  </div>
 );
};

export default RowGrid;
