import { Box, Container, Stack, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../../../redux/store";
import CustomButtonGroup from "../../../../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../../../themes/colors";
import { BREADCRUMBS_RENTALS } from "../../../../../../constants/activeModule";
import {
  LobStatus,
  TypeOfTransfer,
  VehicleStatus,
  VehicleTranferTabs,
  lobArray,
  vehicleCategoryName,
} from "../../../../../../constants/constants";
import rentalEn from "../../../../../../locale/rental-en.json";
import { rentalActions, vehicleActions } from "../../../../../../redux/actions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../../../utils/RoutesConstants";
import { validateOnly } from "../../../../../../utils/helper";
import styles from "../section.module.css";
import { ReceivingDetails } from "./RecevingDetails";
import { SendingDetails } from "./SendingDetails";

interface displayNameObject {
  name: string;
  displayName: string;
}

interface initialStateInterface {
  paginationModel: { page: String | number; pageSize: String | number };
  selectedCity: displayNameObject;
  selectedBranch: displayNameObject;
  searchedVehicleNumber: String | number;
  selectedVehicleIds: any;
  selectedRecevingCity: displayNameObject;
  selectedReceivingBranch: displayNameObject;
  transferType: displayNameObject;
  selectedLob: displayNameObject;
}

const initialState: initialStateInterface = {
  paginationModel: { page: 0, pageSize: 10 },
  selectedCity: { name: "", displayName: "" },
  selectedBranch: { name: "", displayName: "" },
  searchedVehicleNumber: "",
  selectedVehicleIds: [],
  selectedRecevingCity: { name: "", displayName: "" },
  selectedReceivingBranch: { name: "", displayName: "" },
  transferType: { name: "", displayName: "" },
  selectedLob: { name: "", displayName: "" },
};

const errorInitialState = {
  selectedCity: "",
  selectedBranch: "",
  selectedRecevingCity: "",
  selectedReceivingBranch: "",
  selectedLob: "",
};

//get receving lob
let receivingLob: any[] = [];

//in progress
export const VehicleTranfer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fields, setFields] = useState({ ...initialState });
  const [errorFields, setErrorFields] = useState({ ...errorInitialState });
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [recevingDetailsValidation, setReceivingDetailsValidation] =
    useState(true);

  const [selectedTab, setSelectedTab] = useState<any>({
    index: 0,
    key: VehicleTranferTabs.SENDING_DETAILS,
  });

  const { allCitiesDetails, branches } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { onLoad, isTransferVehicleSuccess } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  //get vehicle listg api
  const getVehiclesList = (props: any) => {
    const { page, pageSize, searchedVehicleNumber, cities, branches, lob } =
      props;
    const payload = {
      status: VehicleStatus.ACTIVE,
      lobStatus: [LobStatus.AVAILABLE],
      page: page + 1,
      pageSize: pageSize,
      search: searchedVehicleNumber && JSON.stringify(searchedVehicleNumber),
      cities: cities ? [cities] : "",
      branches: branches ? [branches] : "",
      lob: lob,
    };
    dispatch(vehicleActions.getVehiclesList(payload));
  };

  const columns: any[] = [
    {
      field: "modelDisplayName",
      headerName: rentalEn?.tableHeaders?.modelName,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "tempRegistration",
      headerName: rentalEn?.tableHeaders?.vehicleNumber,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "category",
      headerName: rentalEn?.tableHeaders?.vehicleType,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span>
            {" "}
            {params?.row?.category
              ? vehicleCategoryName[
                  params?.row?.category as keyof typeof vehicleCategoryName
                ]
              : rentalEn?.global?.NA}
          </span>
        );
      },
    },
    {
      field: "colourDisplayName",
      headerName: rentalEn?.tableHeaders?.color,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "lob",
      headerName: rentalEn?.tableHeaders?.lob,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "cityDisplayName",
      headerName: rentalEn?.tableHeaders?.city,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },
    {
      field: "branchDisplayName",
      headerName: rentalEn?.tableHeaders?.hub,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
    },

    {
      field: "tempLobStatus",
      headerName: rentalEn?.tableHeaders?.status,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color:
                params?.row?.tempLobStatus === LobStatus?.AVAILABLE
                  ? colors?.light_green
                  : params?.row?.tempLobStatus === LobStatus?.BLOCKED
                  ? colors?.primary_danger
                  : colors?.text_black,
            }}
          >
            {params?.row?.tempLobStatus === rentalEn?.global?.NA
              ? params?.row?.tempLobStatus
              : params?.row?.tempLobStatus.toLowerCase()}
          </span>
        );
      },
    },
  ];

  // hangle table pagination
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    getVehiclesList({
      page: page,
      pageSize: pageSize,
      search: fields?.searchedVehicleNumber,
      cities: fields?.selectedCity?.name,
      branches: fields?.selectedBranch?.name,
      lob: fields?.selectedLob?.name,
    });
  };

  //Filter out lob status
  receivingLob =
    fields?.transferType?.name === TypeOfTransfer.LOB_TRANSFER
      ? lobArray?.filter((lob: any) => lob?.name !== fields.selectedLob?.name)
      : [];

  const handleChange = (key: string, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      [key]: "",
    }));
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getVehiclesList(payload);
    }, 1000),
    []
  );

  // handle search  on the basis of search vehicle number
  const handleSearch = (e: any) => {
    setFields((prev: any) => ({
      ...prev,
      searchedVehicleNumber: e.target.value,
    }));

    if (e.target.value?.length >= 2 || !e.target.value)
      debouncedSearch({
        page: fields?.paginationModel?.page,
        pageSize: fields?.paginationModel?.pageSize,
        cities: fields?.selectedCity?.name,
        searchedVehicleNumber: e.target.value,
        branches: fields?.selectedBranch?.name,
        lob: fields?.selectedLob?.name,
      });
  };

  // use to change top tabs (onClick)
  const handleTabChange = (index: number, key?: string) => {
    setSelectedTab((prev: any) => ({
      ...prev,
      index: index,
      key: key,
    }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      ...errorInitialState,
    }));
  };

  // Validate Booking Details
  const validateSendingDetails = () => {
    let error = false;
    const validateObj: any = [];

    if (fields?.transferType?.name === TypeOfTransfer.LOB_TRANSFER) {
      validateObj.push("selectedLob");
    } else {
      validateObj.push("selectedCity");
      validateObj.push("selectedBranch");
    }

    const validatedFields = validateOnly(fields, errorFields, validateObj);

    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });
      return error;
    }

    if (!fields.selectedVehicleIds.length) {
      error = true;
      toastr.error("", rentalEn?.errorMessages.selectTransferVehicles);
      return error;
    }

    setSelectedTab((prev: any) => ({
      ...prev,
      index: 1,
      key: VehicleTranferTabs.RECEVING_DETAILS,
    }));
    setReceivingDetailsValidation(false);

    return error;
  };

  // Validate Booking Details
  const validateRecevingDetails = () => {
    if (fields.transferType?.name === TypeOfTransfer.BRANCH_TRANSFER) {
      let error = false;
      const validatedFields = validateOnly(fields, errorFields, [
        "selectedReceivingBranch",
        "selectedRecevingCity",
      ]);

      if (validatedFields.error) {
        error = true;
        setErrorFields({ ...errorFields, ...validatedFields.errorFields });
        return error;
      }
    }
    const payload = {
      data: {
        vehicles: fields?.selectedVehicleIds,
        type: fields?.transferType?.name,
        details: {
          destinationBranchName: fields?.selectedReceivingBranch?.name
            ? fields?.selectedReceivingBranch?.name
            : undefined,
          destinationLob: receivingLob[0]?.name
            ? receivingLob[0]?.name
            : undefined,
        },
      },
    };
    dispatch(vehicleActions.transferVehicleRequest(payload));
    return false;
  };

  const handleFilterReset = () => {
    setFields((prev: any) => ({
      ...prev,
      ...initialState,
    }));
    getVehiclesList({
      page: fields?.paginationModel?.page,
      pageSize: fields?.paginationModel?.pageSize,
    });
    dispatch(rentalActions.clearHubsList());
    setReceivingDetailsValidation(true);
    setSelectedRows([]);
  };

  // tab array passed as argument in button group
  const tabArray = [
    {
      key: VehicleTranferTabs.SENDING_DETAILS,
      label: rentalEn?.VehicleManagement?.sendingDetails,
      tabValidation: false,
      selectedTab: selectedTab?.index,
      // startIcon: <LooksOneTwoToneIcon />,
    },
    {
      key: VehicleTranferTabs.RECEVING_DETAILS,
      label: rentalEn?.VehicleManagement?.receivingDetails,
      tabValidation: recevingDetailsValidation,
      selectedTab: selectedTab?.index,
      // startIcon: <LooksTwoTwoToneIcon />,
    },
  ];

  //initial render function
  useEffect(() => {
    getVehiclesList({
      page: fields?.paginationModel?.page,
      pageSize: fields?.paginationModel?.pageSize,
    });

    dispatch(
      updateBreadcrumbs(BREADCRUMBS_RENTALS.BULK_ACTIONS_VEHICLE_TRANSFER)
    );

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // validations if vehicle length 0
  useEffect(() => {
    if (!fields.selectedVehicleIds.length) setReceivingDetailsValidation(true);
  }, [fields.selectedVehicleIds.length]);

  //receving branches list based on selected branch
  const recevingBranchOptions = branches?.filter(
    (branch: any) => branch?.name !== fields?.selectedBranch?.name
  );

  // to load as per state value (selected tab)
  const componentLoad: any = {
    [VehicleTranferTabs.SENDING_DETAILS]: (
      <SendingDetails
        fields={fields}
        handleSearch={handleSearch}
        allCitiesDetails={allCitiesDetails}
        getVehiclesList={getVehiclesList}
        handleChange={handleChange}
        setFields={setFields}
        initialState={initialState}
        branches={branches}
        columns={columns}
        handlePaginationModelChange={handlePaginationModelChange}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setReceivingDetailsValidation={setReceivingDetailsValidation}
        errorFields={errorFields}
        handleFilterReset={handleFilterReset}
        // setReceivingLob={setReceivingLob}
      />
    ),
    [VehicleTranferTabs.RECEVING_DETAILS]: (
      <ReceivingDetails
        fields={fields}
        setFields={setFields}
        handleSearch={handleSearch}
        handleChange={handleChange}
        branches={recevingBranchOptions}
        columns={columns}
        selectedRows={selectedRows}
        errorFields={errorFields}
        receivingLob={receivingLob[0]}
        allCitiesDetails={allCitiesDetails}
        initialState={initialState}
      />
    ),
  };

  //on sucess navigate to history screen
  useEffect(() => {
    if (isTransferVehicleSuccess)
      setTimeout(() => {
        navigate(routesConstants.VEHICLE_TRANSFER_HISTORY);
      }, 1000);
  }, [isTransferVehicleSuccess]);

  return (
    <Stack gap="25px">
      <CustomButtonGroup
        tabsArray={tabArray}
        handleTabChange={handleTabChange}
      />
      {componentLoad[selectedTab.key]}

      <Container
        sx={{
          position: "fixed",
          bottom: 3,
          padding: 0,
        }}
        className={styles.containerPadding}
      >
        <Box component="div" className={styles.vehicleTranfer}>
          {selectedTab.index === 0 ? (
            <Box className={styles.buttonSendingDetails}>
              <CustomButton
                variant="contained"
                label={rentalEn.buttonLabels.next}
                onClick={validateSendingDetails}
                sx={{
                  minWidth: "200px",
                }}
                style={{ position: "fixed", right: "37px" }}
                disabled={
                  fields?.transferType?.name === TypeOfTransfer.BRANCH_TRANSFER
                    ? !fields?.selectedCity?.name ||
                      !fields?.selectedBranch?.name
                    : !fields?.selectedLob?.name
                }
              />
            </Box>
          ) : (
            <Box className={styles.buttonRecevingDetails}>
              <CustomButton
                variant="contained"
                label={rentalEn.buttonLabels.previous}
                sx={{ minWidth: "200px" }}
                onClick={() => {
                  setSelectedTab((prev: any) => ({
                    ...prev,
                    index: 0,
                    key: VehicleTranferTabs.SENDING_DETAILS,
                  }));
                  setErrorFields((prevState: any) => ({
                    ...prevState,
                    selectedReceivingBranch: "",
                    selectedRecevingCity: "",
                  }));
                }}
              />
              <CustomButton
                variant="contained"
                label={rentalEn.buttonLabels.confirmTransfer}
                onClick={validateRecevingDetails}
                sx={{ minWidth: "200px" }}
                loading={onLoad}
                style={{ position: "fixed", right: "37px" }}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Stack>
  );
};
