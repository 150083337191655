import {
  Avatar,
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "../../../../../themes/colors";
import { typographyConstants } from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";
import styles from "../index.module.css";
import HeadingValueTypography from "../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import {
  getFullName,
  getInitials,
  toPascalCase,
} from "../../../../utils/helper";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

export const BasicDetails = ({
  loading,
  bookingdescriptionArr,
  operatorName,
  isMyAccount,
  isEditIcon = false,
  handleClickEdit,
}: any) => {
  const { operatorManagement } = rentalEn;
  const navigate = useNavigate();

  // handle change password navigation
  const handleChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <Stack gap="20px">
      <Box className={styles.leadDetails}>
        {isMyAccount ? (
          <Box
            sx={{
              backgroundColor: colors.THEME_BLUE,
              borderRadius: "40px",
              height: "52px",
              padding: "5px 35px 5px 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "white",
                  color: colors.THEME_BLUE,
                  width: "38px",
                  height: "38px",
                  fontSize: "12px",
                  position: "unset",
                }}
              >
                {loading ? (
                  <Skeleton variant="circular" width={40} height={40} />
                ) : (
                  <>{getInitials(getFullName(operatorName))}</>
                )}
              </Avatar>
              <Typography
                variant={typographyConstants.BODY}
                color={colors.white}
                fontWeight="500"
              >
                {toPascalCase(operatorName)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Avatar
                sx={{
                  backgroundColor: colors.THEME_BLUE,
                  color: "white",
                  width: "45px",
                  height: "45px",
                }}
              >
                {/* Render initials or default text */}
                {loading ? (
                  <Skeleton sx={{ width: "45px", height: "45px" }} />
                ) : (
                  getInitials(getFullName(operatorName))
                )}
              </Avatar>
              <HeadingValueTypography
                heading={operatorManagement.operaterName}
                value={toPascalCase(operatorName)}
                color={colors.THEME_BLUE}
                loading={loading}
              />
            </Box>
            {isEditIcon ? (
              <Tooltip title="Edit Details">
                <EditIcon
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={handleClickEdit}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        )}

        {isMyAccount ? (
          <CustomButton
            // variant="contained"
            label={rentalEn.buttonLabels.changePassword}
            onClick={handleChangePassword}
          />
        ) : null}
      </Box>
      <Stack
        sx={{
          backgroundColor: colors.bg_secondary_blue,
          borderRadius: "8px",
          padding: "20px",
          gap: "20px",
        }}
      >
        <Typography
          variant={typographyConstants.SUBHEADING}
          color={colors.THEME_BLUE}
        >
          {operatorManagement.basicDetails}
        </Typography>
        <Box className={styles.basicDetailsBox}>
          {bookingdescriptionArr.length > 0 &&
            bookingdescriptionArr?.map((data: any) => {
              if (!data.value) return;
              return (
                <HeadingValueTypography
                  heading={data?.displayKey}
                  value={data?.value}
                  link={data?.link}
                  width="170px"
                  loading={loading}
                  color={colors.text_black}
                />
              );
            })}
        </Box>
      </Stack>
    </Stack>
  );
};
