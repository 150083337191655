import moment from "moment";
import { isArrayNotEmpty } from "../../../../utils/helper";
import { GenericObject } from "../../../constants/constants";
import rentalEn from "../../../locale/rental-en.json";
import { formatString, paisaToRupee } from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";

const ExcelJS = require("exceljs");
var saveAs = require("file-saver");
const { global, tableHeaders } = rentalEn;

interface initialState {
  onLoad: boolean;
  paymentListData: any;
  paymentListApiData: any;
  downloadButtonLoader: boolean;
  refundDetails: any;
}

const initialState: initialState = {
  onLoad: false, // Indicates whether the page is currently loading
  paymentListData: [],
  paymentListApiData: {},
  downloadButtonLoader: false,
  refundDetails: [],
};

// Handler for downloading the payment list data
const downloadPaymentListHandler = (paymentList1000: GenericObject[]) => {
  // Create a new Excel workbook instance
  const workBook = new ExcelJS.Workbook();

  // Add a worksheet named "PaymentDetails" to the workbook
  const workSheet = workBook?.addWorksheet("PaymentDetails");

  // Define columns for the worksheet
  workSheet.columns = [
    { header: tableHeaders?.bookingNo, key: "bookingNumber" },
    { header: tableHeaders?.transactionId, key: "transactionId" },
    { header: tableHeaders?.createdDate, key: "tempCreatedAt" },
    { header: tableHeaders?.userName, key: "customerName" },
    { header: tableHeaders?.amount, key: "amountInRupee" },
    { header: tableHeaders?.paymentType, key: "paymentType" },
    { header: tableHeaders?.paymentStatus, key: "status" },
    { header: tableHeaders?.paymentDate, key: "tempPaidOn" },
  ];

  // Add rows of vehicle data to the worksheet
  workSheet?.addRows(paymentList1000);

  // Write the workbook to a buffer and then create a Blob from the buffer
  workBook.xlsx.writeBuffer().then((buffer: any) =>
    // Save the Blob as a file named "PaymentManagementReport.xlsx"
    saveAs(new Blob([buffer]), `PaymentManagementReport.xlsx`)
  );
};

const getModifiedPaymentListData = (paymentListDataParam: any[]) => {
  // Initialize temporary array to hold modified payment list data
  let tempPaymentListData: any[] = [];

  if (isArrayNotEmpty(paymentListDataParam)) {
    // Map through payment list data to apply transformations
    tempPaymentListData = paymentListDataParam?.map(
      (item: any, index: number) => {
        item.transactionId =
          item?.paymentDetails?.paymentFlowDetails?.paymentId ?? global?.NA;
        item.amountInRupee = item?.amount
          ? `₹ ${paisaToRupee(item?.amount)}`
          : global?.NA;
        item.tempSubCategory = item?.charges[0]?.subCategory
          ? item?.charges[0]?.subCategory
          : global?.NA;
        item.paymentType = item?.paymentDetails?.paymentFlow
          ? formatString(item?.paymentDetails?.paymentFlow)
          : global?.NA;
        item.tempCreatedAt = item?.createdAt
          ? moment(item?.createdAt).format(global?.DDMMYYYY)
          : global?.NA;
        item.tempPaidOn = item?.paidOn
          ? moment(item?.paidOn).format(global?.DDMMYYYY)
          : global?.NA;

        // Return the modified item
        return item;
      }
    );
  }

  // Return the modified surge list data
  return tempPaymentListData;
};

const paymentManagementReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    // Request for getting payment list
    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_REQUESTED:
      return {
        ...state,
        paymentListData: action?.key ? [...state.paymentListData] : [],
        onLoad: action?.key ? false : true,
        downloadButtonLoader: action?.key ? true : false,
      };
    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_SUCCESS:
      if (payload?.key === "download") {
        downloadPaymentListHandler([
          ...getModifiedPaymentListData([
            ...payload?.paymentListData?.payments,
          ]),
        ]);
      }

      return {
        ...state,
        paymentListApiData: payload?.key
          ? state.paymentListData
          : payload?.paymentListData,
        paymentListData: payload?.key
          ? [...state.paymentListData]
          : [
              ...getModifiedPaymentListData([
                ...payload?.paymentListData?.payments,
              ]),
            ],
        onLoad: false,
        downloadButtonLoader: false,
      };
    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_PAYMENT_LIST_FAILED:
      return {
        ...state,
        paymentListData: payload?.key ? [...state.paymentListData] : [],
        onLoad: false,
        downloadButtonLoader: false,
      };

    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_REQUESTED:
      return {
        ...state,
        refundDetails: {},
      };
    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_SUCCESS:
      return {
        ...state,
        refundDetails: payload,
      };
    case actionTypes.PAYMENT_MANAGEMENT_ACTIONS.GET_REFUND_DETAILS_FAILED:
      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default paymentManagementReducer;
