import { Box, Skeleton, Stack } from "@mui/material";

const BookingDetailLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "0px 14px 8px 14px",
        justifyContent: "space-between",
      }}
    >
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="40vh"
      />
    </Box>
  );
};

export default BookingDetailLoader;
