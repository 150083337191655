import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { MITC_FORM_URL } from "../../../../../../config";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../../constants/RBACModuleEnums";
import {
  CustomButton,
  CustomChip,
  CustomIconButton,
} from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import ResponsiveDialog from "../../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import HeadingValueTypography from "../../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import SideDrawer from "../../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../../../themes/colors";
import RbackHelper from "../../../../../../utils/helperRBAC";
import {
  BLOCKED_REASON,
  GenericObject,
  LobStatus,
  statusColorCode,
  typographyConstants,
  VehicleStatus,
} from "../../../../../constants/constants";
import {
  ClockIcon,
  DefaultVehicleImage,
  DownloadIcon,
  EditIconBlack,
} from "../../../../../constants/exportImages";
import { VehicleDetailsUiProps } from "../../../../../interfaces/vehicleInterface";
import en from "../../../../../locale/rental-en.json";
import {
  formatString,
  getDateTimeFromTimeStamp,
} from "../../../../../utils/helper";

const { isAccessRightsProvided } = RbackHelper;

const { global, buttonLabels, VehicleManagement, errorMessages } = en;
const { vehicleDocumentType } = VehicleManagement;

const docTableHeader: String[] = [
  VehicleManagement.documentType,
  VehicleManagement.validFrom,
  VehicleManagement.validTo,
  buttonLabels.download,
];

export const VehicleDetailsUI = (props: VehicleDetailsUiProps) => {
  const { lobStatus } = VehicleManagement;
  const {
    vehicleDetails,
    vehicleDetailsLoader,
    openSideDrawer,
    editButtonClickHandler,
    dialogData,
    setDialogData,
    yesHandler,
    vehicleDocumentsDataLoader,
    docPageData,
    documentDetails,
    viewDocuments,
    handleDocTypeFilteredData,
    vehicleDocsTypeArray,
    handleFilterDrawerType,
    toggleDocumentHistoryDrawer,
    deleteVehicleDocument,
    downloadVehicleDocument,
    openDeleteDialogDocument,
    closeDeleteDialogDocument,
    deleteDocumentHandleYes,
    deleteDocumentDialog,
    deleteDocLoader,
  } = props;

  // RBAC object for functionality
  const functionalitiesAccess = {
    isEditVehicle: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.EDIT_VEHICLE
    ),
    isVehicleDocumentDownload: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.DOWNLOAD_VEHICLE_DOCUMENTS
    ),
    isDeleteVehicle: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.DELETE_VEHICLE
    ),
    isEditvehicleStatus: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.EDIT_VEHICLE_STATUS
    ),
    isViewDocumentHistory: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_DOCUMENT_HISTORY
    ),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {/* Vehicle Details Top Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant={typographyConstants.HEADING}
          sx={{
            color: colors.secondary_gray3,
          }}
        >
          {VehicleManagement.vehicleDetails}
        </Typography>

        {/* Vehicle Status Text */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip
            title={
              vehicleDetails?.lobStatus === LobStatus?.BLOCKED
                ? vehicleDetails?.blockedReason === BLOCKED_REASON?.RESERVED
                  ? en?.VehicleManagement?.reserved
                  : vehicleDetails?.blockedReason ===
                    BLOCKED_REASON?.UNDER_MAINTENANCE
                  ? en?.VehicleManagement?.underMaintenance
                  : vehicleDetails?.blockedReason ===
                    BLOCKED_REASON?.UNDER_RECOVERY
                  ? en?.VehicleManagement?.underRecovery
                  : null
                : null
            }
            arrow
          >
            <Typography
              sx={{
                backgroundColor:
                  vehicleDetails?.status === VehicleStatus?.ACTIVE
                    ? statusColorCode[
                        vehicleDetails.lobStatus as keyof typeof statusColorCode
                      ]
                    : statusColorCode[
                        vehicleDetails.status as keyof typeof statusColorCode
                      ],
                borderRadius: "16px",
                padding: "3px 14px ",
                color: colors.white,
              }}
              color={colors.white}
            >
              {vehicleDetails?.status === VehicleStatus?.ACTIVE
                ? formatString(vehicleDetails?.lobStatus)
                : formatString(vehicleDetails?.status)}
            </Typography>
          </Tooltip>

          {/* Vehicle Status Change Button */}
          {functionalitiesAccess.isEditvehicleStatus ? (
            <>
              {vehicleDetails?.status === VehicleStatus?.ACTIVE &&
                (vehicleDetails?.lobStatus === LobStatus?.AVAILABLE ||
                  vehicleDetails?.lobStatus === LobStatus?.BLOCKED ||
                  vehicleDetails?.lobStatus === LobStatus?.ENGAGED) &&
                vehicleDetails?.blockedReason !==
                  BLOCKED_REASON?.UNDER_RECOVERY && (
                  <CustomButton
                    label={VehicleManagement.change}
                    sx={{
                      color: colors.primary_dark_blue2,
                      "&:hover": {
                        background: "none",
                      },
                    }}
                    onClick={() => {
                      if (
                        vehicleDetails?.lobStatus === LobStatus?.AVAILABLE ||
                        vehicleDetails?.lobStatus === LobStatus?.ENGAGED
                      ) {
                        openSideDrawer();
                      } else {
                        setDialogData({
                          openDialog: true,
                          key: "changeVehicleStatus",
                        });
                      }
                    }}
                  />
                )}
            </>
          ) : null}

          {/* Edit Vehicle Details Button */}
          {functionalitiesAccess.isEditVehicle ? (
            <>
              {(vehicleDetails?.status === VehicleStatus?.ACTIVE ||
                vehicleDetails?.status === VehicleStatus?.REJECTED) && (
                <CustomButton
                  variant={"outlined"}
                  label={buttonLabels.edit}
                  startImageSrc={EditIconBlack}
                  sx={{ minWidth: 80, padding: "0px 13px", minHeight: "25px" }}
                  onClick={editButtonClickHandler}
                  disabled={!functionalitiesAccess.isEditVehicle}
                />
              )}
            </>
          ) : null}

          {/* Delete Vehicle Icon Button */}
          {functionalitiesAccess.isDeleteVehicle ? (
            <CustomIconButton
              onClick={() =>
                setDialogData({ openDialog: true, key: "deleteVehicle" })
              }
              children={<DeleteIcon color="error" />}
            />
          ) : null}
        </Box>
      </Box>

      {/* Vehicle Details Box */}
      <Stack
        sx={{
          backgroundColor: colors.bg_secondary_blue,
          padding: "24px",
          borderRadius: "8px",
        }}
        gap="24px"
      >
        {/* vehicle details */}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            style={{ width: "150px", height: "90px", objectFit: "contain" }}
            src={
              vehicleDetails?.modelImagesPath &&
              vehicleDetails?.modelImages?.length
                ? `${MITC_FORM_URL}/${vehicleDetails?.modelImagesPath}/${vehicleDetails?.modelImages[0]?.type}-${vehicleDetails?.modelImages[0]?.fileName}`
                : DefaultVehicleImage
            }
          />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <HeadingValueTypography
              loading={vehicleDetailsLoader}
              heading={VehicleManagement.vehicleModel}
              value={vehicleDetails?.modelDisplayName}
            />
            <HeadingValueTypography
              loading={vehicleDetailsLoader}
              heading={VehicleManagement.registrationNumber}
              value={vehicleDetails?.vehicleDetails?.registration}
            />
            <HeadingValueTypography
              loading={vehicleDetailsLoader}
              heading={VehicleManagement.chassisNumber}
              value={vehicleDetails?.vehicleDetails?.chassis}
              width={"160px"}
            />
            <HeadingValueTypography
              loading={vehicleDetailsLoader}
              heading={VehicleManagement.engineNumber}
              value={vehicleDetails?.vehicleDetails?.engine}
              width={"160px"}
            />
          </Box>
        </Box>

        {/* upload document sec */}
        <Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant={typographyConstants.HEADING}
              sx={{ color: colors.secondary_gray3 }}
            >
              {VehicleManagement?.uploadDocuments}
            </Typography>
            {functionalitiesAccess.isViewDocumentHistory ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => toggleDocumentHistoryDrawer()}
              >
                <img src={ClockIcon} />
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: colors.primary_dark_blue2,
                    fontSize: "13px",
                  }}
                >
                  {VehicleManagement.documentHistory}
                </Typography>
                {/*  <CustomButton
                label={VehicleManagement.documentHistory}
                startImageSrc={ClockIcon}
              /> */}
              </Box>
            ) : null}
          </Box>
          <Box display={"flex"} gap={"7px"} sx={{ flexWrap: "wrap" }}>
            {vehicleDocsTypeArray?.map((data: GenericObject) => (
              <CustomChip
                label={data.displayName}
                variant={"outlined"}
                sx={{
                  borderRadius: "8px",
                  padding: "15px 8px",
                  fontSize: "13px",
                  color: colors.tertiary_black,
                  cursor: "pointer",
                  backgroundColor: colors.secondary_white,
                  border:
                    viewDocuments?.docsFilter?.name === data.name
                      ? "2px solid " + colors.primary_dark_blue2
                      : "",
                }}
                onClick={() => {
                  handleDocTypeFilteredData(data);
                }}
              />
            ))}
          </Box>
          <Stack gap={"10px"}>
            <Box
              sx={{
                width: "80%",
                height: "auto",
                minHeight: "30px",
                backgroundColor: colors.secondary_gray2,
                borderRadius: "8px",
                marginTop: "25px",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={3}>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={colors.text_secondary_gray}
                    sx={{ fontSize: "12px" }}
                  >
                    {VehicleManagement.documentName}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={colors.text_secondary_gray}
                    sx={{ fontSize: "12px" }}
                  >
                    {VehicleManagement.validFrom}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={colors.text_secondary_gray}
                    sx={{ fontSize: "12px" }}
                  >
                    {VehicleManagement.validTo}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    color={colors.text_secondary_gray}
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      marginLeft: "17px",
                    }}
                  >
                    {VehicleManagement.uploadedFiles}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {deleteDocLoader ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "80%",
                    boxShadow: "0px 4px 10px 0px #0000000D",
                    padding: "10px",
                    alignItems: "center",
                    backgroundColor: colors.white,
                    borderRadius: "10px",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <>
                  {" "}
                  {viewDocuments.filteredData?.length ? (
                    <>
                      {viewDocuments?.filteredData?.map(
                        (document: GenericObject) => (
                          <Box
                            sx={{
                              display: "flex",
                              width: "80%",
                              boxShadow: "0px 4px 10px 0px #0000000D",
                              padding: "10px",
                              alignItems: "center",
                              backgroundColor: colors.white,
                              borderRadius: "10px",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ textAlign: "center" }}
                            >
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  {
                                    vehicleDocumentType[
                                      document?.type as keyof typeof vehicleDocumentType
                                    ]
                                  }
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  {getDateTimeFromTimeStamp(
                                    document.startDate,
                                    "date"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  {getDateTimeFromTimeStamp(
                                    document.expiryDate,
                                    "date"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Tooltip
                                        title={
                                          document.document?.length > 13
                                            ? document.document
                                            : ""
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            color: colors?.primary_dark_blue2,
                                            overflow: "hidden",
                                            width: "65px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {document.document}
                                        </Typography>
                                      </Tooltip>
                                    </Box>
                                    <Tooltip
                                      title={buttonLabels.delete}
                                      placement={"left"}
                                    >
                                      <CustomIconButton
                                        onClick={() =>
                                          openDeleteDialogDocument(document?.id)
                                        }
                                        children={
                                          <CloseIcon
                                            color="error"
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        }
                                      />
                                    </Tooltip>
                                    {functionalitiesAccess.isVehicleDocumentDownload ? (
                                      <Tooltip
                                        title={buttonLabels.download}
                                        placement={"right"}
                                      >
                                        <CustomIconButton
                                          children={
                                            <img
                                              src={DownloadIcon}
                                              onClick={() =>
                                                downloadVehicleDocument(
                                                  document?.id
                                                )
                                              }
                                            />
                                          }
                                          sx={{ marginLeft: "20px" }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        )
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        width: "80%",
                        height: "40px",
                        boxShadow: "0px 4px 10px 0px #0000000D",
                        padding: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: colors.white,
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        sx={{ color: colors.tertiary_black }}
                        variant={typographyConstants.SUBHEADING}
                      >
                        {errorMessages.noDataFound}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
            {/* <Box>

            </Box> */}
          </Stack>
        </Stack>
      </Stack>

      {/* Confirnmation Dialog For Deleteing The Vehicle  */}
      <ResponsiveDialog
        title={
          dialogData?.key === "deleteVehicle"
            ? global?.deleteVehicle
            : VehicleManagement?.changeStatus
        }
        body={
          dialogData?.key === "deleteVehicle"
            ? VehicleManagement?.areYouSureYouWantToDeleteThisVehicle
            : VehicleManagement?.updateVehicleStatusConfirmation
        }
        open={dialogData?.openDialog}
        handleClose={() => setDialogData({ openDialog: false, key: "" })}
        handleYes={yesHandler}
      />

      {/* Confirnmation Dialog For Deleteing The Vehicle document  */}
      <ResponsiveDialog
        title={VehicleManagement.deleteDocument}
        body={VehicleManagement.sureWantToDeleteDocument}
        open={deleteDocumentDialog?.open}
        handleClose={closeDeleteDialogDocument}
        handleYes={deleteDocumentHandleYes}
      />

      <SideDrawer
        open={viewDocuments?.openDrawer}
        heading={VehicleManagement.documentHistory}
        toggleDrawer={toggleDocumentHistoryDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <Stack sx={{ padding: "10px", gap: "10px" }}>
            <Box display={"flex"} gap={"5px"} sx={{ flexWrap: "wrap" }}>
              {vehicleDocsTypeArray?.map((data: GenericObject) => (
                <CustomChip
                  label={data.displayName}
                  variant={"outlined"}
                  sx={{
                    borderRadius: "8px",
                    padding: "15px 8px",
                    fontSize: "13px",
                    color: colors.tertiary_black,
                    cursor: "pointer",
                    backgroundColor: colors.secondary_white,
                    border:
                      viewDocuments?.drawerFilter?.name === data.name
                        ? "2px solid " + colors.primary_dark_blue2
                        : "",
                  }}
                  onClick={() => {
                    handleFilterDrawerType(data);
                  }}
                />
              ))}
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "auto",
                minHeight: "30px",
                backgroundColor: colors.secondary_gray2,
                borderRadius: "8px",
                marginTop: "25px",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "26px",
              }}
            >
              {docTableHeader?.map((item: String) => (
                <Typography
                  variant={typographyConstants.SUBHEADING}
                  color={colors.text_secondary_gray}
                  sx={{
                    fontSize: "12px",

                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {viewDocuments?.drawerFilterData?.length > 0 ? (
                <>
                  {viewDocuments?.drawerFilterData?.map(
                    (document: GenericObject) => (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          boxShadow: "0px 4px 10px 0px #0000000D",
                          padding: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: colors.white,
                          borderRadius: "10px",
                        }}
                      >
                        <Tooltip
                          title={
                            vehicleDocumentType[
                              document?.type as keyof typeof vehicleDocumentType
                            ]
                          }
                        >
                          <Typography
                            sx={{
                              overflow: "hidden",
                              width: "100px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                          >
                            {
                              vehicleDocumentType[
                                document?.type as keyof typeof vehicleDocumentType
                              ]
                            }
                          </Typography>
                        </Tooltip>

                        <Typography width={"25%"}>
                          {getDateTimeFromTimeStamp(document.startDate, "date")}
                        </Typography>
                        <Typography width={"25%"}>
                          {getDateTimeFromTimeStamp(
                            document.expiryDate,
                            "date"
                          )}
                        </Typography>

                        <Tooltip
                          title={
                            document.document?.length > 7
                              ? document.document
                              : ""
                          }
                        >
                          <Typography
                            sx={{
                              color: colors?.primary_dark_blue2,
                              overflow: "hidden",
                              width: "60px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontWeight: 500,
                            }}
                            variant={typographyConstants.BODY}
                            width={"25%"}
                            onClick={() =>
                              downloadVehicleDocument(document?.id)
                            }
                          >
                            {document.document}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "30px",
                    boxShadow: "0px 4px 10px 0px #0000000D",
                    padding: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.white,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{ color: colors.tertiary_black }}
                    variant={typographyConstants.BODY}
                  >
                    {errorMessages.noDataFound}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        }
      />
    </Box>
  );
};
