import { Box, Card, Stack, Typography } from "@mui/material";
import yoren from "../../../locale/yor-en.json";
import { BenefitTypes, BenefitTypesDisplay } from "../../constantsX/booking";
import { Tick } from "../../constantsX/exportImagesX";
import { PlanCardProps } from "../../interfaces/manualBookingInterfaces";
import { colors } from "../../themes/colors";
import { getFormattedINR } from "../../utils/helper";
import styles from "../../components/components.module.css";
import { Fade } from "react-awesome-reveal";

const PlanCard = ({ key, plan, onClick, selectedPlan }: PlanCardProps) => {
  return (
    <Fade>
      <Card
        key={key}
        className={`${styles.card} ${selectedPlan ? styles.selected : ""}`}
        onClick={onClick}
      >
        <Box className={styles.planCardBox}>
          <Box>
            <Stack gap="28px">
              <Typography
                fontSize={12}
                color={selectedPlan ? colors.white : colors.text_black}
                component="div"
                fontFamily="Euclid Circular B"
              >
                {plan?.displayName}
              </Typography>
              {/* benefits */}
              <Box className={styles.BoxColumn}>
                {plan?.benefits?.map((benefit: any, index: any) =>
                  ((benefit?.type === BenefitTypes.FREE_MAINTAINANCE ||
                    benefit?.type === BenefitTypes.FREE_INSURANCE) &&
                    benefit?.data?.isActive) ||
                  (benefit?.type === BenefitTypes.FREE_HELMET &&
                    benefit?.data?.helmetType) ? (
                    <ul
                      key={index}
                      style={{
                        fontSize: "9px",
                        fontFamily: "Euclid Circular B",
                      }}
                    >
                      • {BenefitTypesDisplay[benefit?.type]}
                    </ul>
                  ) : null
                )}
              </Box>
            </Stack>
          </Box>
          {/* price Subscription */}
          <Box>
            <Stack gap="10px">
              <Box>
                <PriceBox
                  title={yoren.manualBookingFreedoX.FreedoXSubscription}
                  amount={plan?.planCharges?.depositAmount}
                  selectedPlan={selectedPlan}
                  style={{
                    backgroundColor: "#FFFFFF0A",
                    color: colors.text_secondary_gray,
                  }}
                />
                <PriceBox
                  title={yoren.manualBookingFreedoX.MonthySubscription}
                  amount={plan?.planCharges?.monthlyCharges}
                  selectedPlan={selectedPlan}
                  style={{
                    backgroundColor: "#FFFFFF0F",
                    color: colors.text_secondary_gray,
                  }}
                />
                <PriceBox
                  title={yoren.manualBookingFreedoX.UpgradationFee}
                  amount={plan?.planCharges?.closureAmount}
                  selectedPlan={selectedPlan}
                  style={{
                    backgroundColor: "#FFFFFF0F",
                    color: colors.text_secondary_gray,
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
        {selectedPlan && (
          <img
            src={Tick}
            alt="tick"
            style={{ position: "absolute", top: 0, right: "-1px" }}
          />
        )}
      </Card>
    </Fade>
  );
};

const PriceBox = ({ title, amount, selectedPlan, style }: any) => {
  const amountInRupee = getFormattedINR(amount);
  return (
    <Box
      sx={{
        backgroundColor: selectedPlan ? { ...style } : "",
        padding: "4px 10px 4px 10px",
      }}
    >
      <Typography
        sx={{
          fontSize: style.fontSize ? style.fontSize : "12px",
          color: !selectedPlan ? style.color : "#FFFFFFCC",
        }}
        component="div"
      >
        {amountInRupee}
      </Typography>
      <Typography
        sx={{
          fontSize: "9px",
          color: selectedPlan ? "#FFFFFFCC" : colors.tertiary_black,
          fontWeight: style.fontWeight ? style.fontWeight : 400,
          fontStyle: style.fontStyle ? style.fontStyle : "",
          fontFamily: "Euclid Circular B",
        }}
        component="div"
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PlanCard;
