import { Box, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import en from "../../../locale/yor-en.json";
import { RootState } from "../../../redux/reducers";
import {
  CircularLineIcon,
  CloudUploadIcon,
  CrossRoundedIcon,
  CurruptIcon,
  ImageIcon,
  PDFIcon,
} from "../../constantsX/exportImagesX";
import { actionsX } from "../../redux";
import ImagePreviewModal from "./imagePreviewModal";
import {
  UploadDocumentsSectionProps,
  generateSignedUrlPayload,
} from "../../interfaces/bookingDetailsInterfaces";
import { toastr } from "react-redux-toastr";
import styles from "./bookingDetails.module.css";
import { DocumentUploadConstants } from "../../constantsX/YOR_BookingDetails";

const obj: any = {
  "file-invalid-type": "File type is not acceptable.",
  "file-too-large": "File size is too large.",
};

const UploadDocumentsSection = (props: UploadDocumentsSectionProps): any => {
  const { bookingId = "", documents = [] } = props;
  const dispatch = useDispatch();
  const [rejectedFile, setRejectedFile] = useState<object[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<object>({});
  const [imgError, setImgError] = useState<boolean>(false);

  const { uploadLoader, signedUrlFailedFiles, linearProgressValue } =
    useSelector((state: RootState) => state.commonReducerX);

  const generateSignedUrl = (fileNames: string[], files: any) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        bookingId: bookingId,
        files,
        fileNames,
      },
    };
    dispatch(actionsX.getSignedUrl(payload));
  };

  const onDrop = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
      });

      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning(
            "",
            `Maximum ${10 - documents?.length} files can be uploded`
          );
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files);
      }

      setRejectedFile(rejectedFiles);
    },
    [bookingId, documents]
  );

  const maxFileUpload = (): number => {
    return documents?.length ? 10 - documents?.length : 10;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxFiles: maxFileUpload(),
    maxSize: 5000000,
    multiple: true,
    noClick: uploadLoader,
    noDrag: uploadLoader,
    disabled: documents?.length >= 10,
  });

  const navigateDocs = (name: string, extension: string, url: string) => {
    if (extension === DocumentUploadConstants.PDF_FILE) {
      window.open(url);
    } else {
      setOpenModal(true);
      let obj = {
        name: name,
        url: url,
      };
      setModalData(obj);
      setImgError(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const deleteDocument = (id: string, name: string) => {
    let docName: string[] = [];
    docName.push(`${id}-${name}`);

    let payload = {
      data: {
        data: {
          documents: docName,
        },
      },
      bookingId: props?.bookingId,
    };
    if (id && name) dispatch(actionsX.deleteDocument(payload));
  };

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        {documents?.length < 10 ? (
          <Fade>
            <Stack>
              {uploadLoader ? (
                <Box className={styles?.documentUploadBox} {...getRootProps()}>
                  <Stack>
                    <Box>
                      <Box sx={{ padding: "5px", gap: "10px" }}>
                        <Stack gap="10px" textAlign={"center"}>
                          <Box>
                            <img
                              src={CloudUploadIcon}
                              width={"20px"}
                              height="20px"
                            />
                          </Box>

                          <LinearProgress
                            value={linearProgressValue}
                            variant="determinate"
                            sx={{ width: "115px" }}
                          />
                          <Box>
                            <Box style={{ display: "flex", gap: "4px" }}>
                              <img
                                src={CircularLineIcon}
                                width={"20px"}
                                height="20px"
                              />
                              <Typography
                                sx={{
                                  lineHeight: "20px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {en.BookingDetailsFreedoX.uploading}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                lineHeight: "12px",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#A9ACB4",
                              }}
                            >
                              {`${linearProgressValue}% of 100%`}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <Box className={styles?.documentUploadBox} {...getRootProps()}>
                  <Stack>
                    <Box>
                      <Box sx={{ padding: "5px", gap: "10px" }}>
                        <input {...getInputProps()} />

                        <Stack gap="10px" textAlign={"center"}>
                          <Box>
                            <img
                              src={CloudUploadIcon}
                              width={"20px"}
                              height="20px"
                            />
                          </Box>

                          <p style={{ fontSize: "9px" }}>
                            {en.BookingDetailsFreedoX.dragAndDrop}
                          </p>

                          <i
                            className="fa fa-plus-square fa-2x mt-1"
                            aria-hidden="true"
                            style={{ color: "#047857", cursor: "pointer" }}
                          ></i>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              )}
              <Box
                style={{ marginLeft: "14px", padding: "10px", width: "150px" }}
              >
                <ul
                  style={{
                    fontSize: "9px",
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <li>{en.BookingDetailsFreedoX.acceptableDoc}</li>
                  <li>{en.BookingDetailsFreedoX.acceptDocSize}</li>
                  <li>{en.BookingDetailsFreedoX.maxDocAccept}</li>
                </ul>
              </Box>
            </Stack>
          </Fade>
        ) : null}
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "370px",
            gap: "15px",
            height: "120px",
          }}
        >
          {documents?.map((item: any) => (
            <Fade>
              <Box style={{ position: "relative", cursor: "pointer" }}>
                <Box
                  className={styles.docImgPreviewBox}
                  onClick={() =>
                    navigateDocs(item?.name, item?.extension, item?.url)
                  }
                >
                  <img
                    src={item?.extension === ".pdf" ? PDFIcon : ImageIcon}
                    height={"20px"}
                    width={"20px"}
                    style={{ borderRadius: "8px" }}
                    onError={(e: any) => {
                      e.target.onerror = null;
                      e.target.src = CurruptIcon;
                    }}
                  />
                </Box>
                <Box>
                  <Tooltip title={item?.name.length > 12 ? item?.name : ""}>
                    <Typography
                      style={{
                        fontWeight: 400,
                        lineHeight: "10px",
                        fontSize: "8px",
                        width: "50px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Tooltip>
                </Box>

                <img
                  className={styles.imgCrossIcon}
                  src={CrossRoundedIcon}
                  onClick={() => deleteDocument(item?.id, item?.name)}
                />
              </Box>
            </Fade>
          ))}
        </Box>
      </Box>

      <Box style={{ marginLeft: "14px", padding: "10px", marginTop: "10px" }}>
        <ul
          style={{
            fontSize: "9px",
            color: "red",
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {rejectedFile?.map((item: any) => (
            <>
              <li>{`Can't upload ${item?.file?.name}, ${
                obj[item?.errors[0]?.code]
              }`}</li>
            </>
          ))}

          {signedUrlFailedFiles?.map((item: any) => (
            <>
              <li>
                <li>{`Failed to upload ${item?.fileName} server error`}</li>
              </li>
            </>
          ))}
        </ul>
      </Box>
      <ImagePreviewModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        modalData={modalData}
        imgError={imgError}
        setImgError={setImgError}
      />
    </>
  );
};
export default UploadDocumentsSection;
