import React from "react";
import CustomModal from "../../../../../components/CustomModal";
import { Typography } from "@mui/material";
import rentalEn from "../../../../locale/rental-en.json";
import { typographyConstants } from "../../../../constants/constants";
import { paisaToRupee } from "../../../../utils/helper";

interface componentPropInterface {
  errorData: any;
  isOpen: boolean;
  handleClose: () => void;
}

const PlanMinimumPriceErrorModal = (props: componentPropInterface) => {
  const { isOpen, handleClose, errorData } = props;
  const { errorList = [], errorMessage } = errorData;
  return (
    <CustomModal
      title={rentalEn.planManagement.planBasePriceList}
      isOpen={isOpen}
      handleClose={handleClose}
      containerStyle={{ width: 850 }}
    >
      <div>
        <Typography variant={typographyConstants.ERRORMESSAGE}>
          {errorMessage}
        </Typography>

        <div className="table-responsive border-top-0 my-1">
          <table className=" table table-bordered align-middle">
            <thead>
              <tr>
                <th scope="col" className="head-l-green text-center">
                  {rentalEn.tableHeaders.planDuration}
                </th>
                <th scope="col" className="head-l-red text-center">
                  {rentalEn.planManagement.planBasePrice}
                </th>
              </tr>
            </thead>
            {errorList?.map((item: any) => {
              return (
                <tbody>
                  <tr>
                    <td className="text-center">
                      {item?.duration > 1
                        ? `${item?.duration} Days`
                        : `${item?.duration} Day`}
                    </td>
                    <td className="text-center">
                      ₹ {paisaToRupee(item?.price)}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </CustomModal>
  );
};

export default PlanMinimumPriceErrorModal;
