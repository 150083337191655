import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { manualBookingService } from "../../services";
import { handleMessage } from "../../../utils/helper";
import * as actionTypes from "../actions/actionTypes";
import { CommonInterface } from "../reducers/actionInterfaces";
import {
  PAYMENT_FLOW,
  SUBSCRIPTION_METHOD,
  CALLBACK_URL,
} from "../../constantsX/booking";
import {
  OrderPaymentType,
  OrderTypes,
} from "../../constantsX/YOR_BookingDetails";

function* checkUserExistFreedoX(action: CommonInterface): any {
  const data = yield call(manualBookingService.checkUserExist, action.payload);

  if (data?.status === 200) {
    const response = data?.data;
    const searchResult = response?.data?.searchResult;
    yield put({
      type: actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_SUCCESS,
      payload: {
        checkUserDetails: searchResult && searchResult.length > 0 ? searchResult[0] : null,
      },
    });
  } else if (data?.ResponseStatusCode === 422) {
    toastr.warning("Alert", handleMessage(data));
    yield put({
      type: actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_FAILED,
      checkUserDetails: null,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_FAILED,
      checkUserDetails: null,
    });
  }
} 

function* addUserX(action: CommonInterface): any {
  try {
    const data = yield call(manualBookingService.addUserX, action.payload);
    if (data?.status == 201 || data?.status == 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING.ADD_USER_SUCCEEDED,
        payload: {},
      });
      toastr.success("", handleMessage(data));
    } else {
      toastr.error("", handleMessage(data));
      yield put({ type: actionTypes.MANUAL_BOOKING.ADD_USER_FAILED });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING.ADD_USER_FAILED,
    });
  }
}

function* createManualBookingX(action: CommonInterface): any {
  try {
    const response = yield call(
      manualBookingService.createManualBookingX,
      action.payload
    );
    const createManualBookingUser = response?.data?.data;
    let createMandatePayload = {
      data: {
        orderId: createManualBookingUser?.orderDetails?.id,
        paymentFlow: PAYMENT_FLOW.MANDATE_PAYMENT_LINK,
        callbackUrl: CALLBACK_URL.URL,
        subscriptionMethod: SUBSCRIPTION_METHOD.UPI,
      },
    };
    if (response?.status == 201 || response?.status == 200) {
      yield put({
        type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_SUCCESS_X,
        payload: {
          createManualBookingUser: createManualBookingUser,
        },
      });
      toastr.success("", handleMessage(response));

      /* 
        If orderDetails.type = MANDATE_REGISTRATION then call https://dev-api.freedo.rentals/freedo-munim/mandate/v1 to register mandate.
        Else if orderDetails.type = PAYMENT
      */

      if (createManualBookingUser?.orderDetails?.type === OrderTypes.PAYMENT) {
        
        if (createManualBookingUser?.orderDetails?.id) {
          const paymentPayload = {
            fetchBookingPayload: action.payload.fetchBookingPayload,
            data: {
              orderId: createManualBookingUser?.orderDetails?.id,
              paymentFlow: OrderPaymentType.PAYMENT_LINK,
            },
          };
          // GENERATE ONE TIME PAYMENT LINK
          yield put({
            type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
              .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
            payload: paymentPayload,
          });
        }
        
      } else {

        // Payment Mandate api call
        yield put({
          type: actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_REQUESTED,
          payload: createMandatePayload,
        });
      }
    } else if (
      response?.data?.error?.httpStatusCode == 422 ||
      response?.status == 400 ||
      response?.status == 422
    ) {
      if (response?.data?.error?.errorCode === "BOOKING_ALREADY_EXISTS") {
        // toastr.warning("Alert", handleMessage(response));
        yield put({
          type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_FAILED_X,
        });
        yield put({
          type: actionTypes.MANUAL_BOOKING.SET_DUPLICATE_BOOKING_MODAL_X,
          payload: {
            show: true,
            bookingId: response?.data?.error?.data?.id,
            status: response?.data?.error?.data?.status,
          },
        });
      }
    } else {
      toastr.info("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_FAILED_X,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_FAILED_X,
    });
  }
}

function* userPaymentMandateSagaX(action: CommonInterface): any {
  try {
    const response = yield call(
      manualBookingService.userPaymentMandateX,
      action.payload
    );
    if (response?.status == 201 || response?.status == 200) {
      yield put({
        type: actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_SUCCESS,
        payload: {
          mandateData: response?.data?.data,
        },
      });
      toastr.success("", handleMessage(response));
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_FAILED,
    });
  }
}

function* manualBookingSaga() {
  yield takeLatest(
    actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_REQUESTED,
    checkUserExistFreedoX
  );

  yield takeLatest(actionTypes.MANUAL_BOOKING.ADD_USER_REQUESTED, addUserX);
  yield takeLatest(
    actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_REQUESTED_X,
    createManualBookingX
  );
  yield takeLatest(
    actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_REQUESTED,
    userPaymentMandateSagaX
  );
}

export default manualBookingSaga;
