import { put, call, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { PLAN_MANAGEMENT_ACTIONS } from "../actions/actionTypes";
import { handleMessage } from "../../../utils/helper";
import planManagementServices from "../services/planmanagementServices";
import { STATUS } from "../../constants/constants";
import CONSTANTS from "../../locale/rental-en.json";
import { clearRentalPlanList } from "../actions/planmanagement";
import { isValidNumber, paisaToRupee, rupeeToPaisa } from "../../utils/helper";

// convert plan prices to rupees to display in UI
export function convertPlanPriceToRupees(planInstance: any): object {
  return {
    ...planInstance,
    excessHourCharges: planInstance.excessHourCharges
      ? paisaToRupee(planInstance.excessHourCharges)
      : 0,
    excessKmCharges: planInstance.excessKmCharges
      ? paisaToRupee(planInstance.excessKmCharges)
      : 0,
    packages: planInstance.packages.map((instance: any) => {
      return {
        ...instance,
        price: paisaToRupee(instance.price),
        security: instance.security ? paisaToRupee(instance.security) : 0,
        kmLimit: instance.kmLimit ? instance.kmLimit : 0,
      };
    }),
  };
}

// convert plan prices to paise for payload & remove plan package & helmet if price is null
export function convertPlanPriceToPaisa(planInstance: any): object {
  return {
    ...planInstance,
    excessHourCharges:
      isValidNumber(planInstance.excessHourCharges) &&
      planInstance.excessHourCharges !== ""
        ? rupeeToPaisa(planInstance?.excessHourCharges)
        : 0,
    excessKmCharges:
      isValidNumber(planInstance.excessKmCharges) &&
      planInstance.excessKmCharges !== ""
        ? rupeeToPaisa(planInstance?.excessKmCharges)
        : 0,
    packages: planInstance?.packages.reduce(
      (newPackage: any, instance: any) => {
        if (instance?.price !== 0 && isValidNumber(instance.price)) {
          newPackage.push({
            ...instance,
            price: isValidNumber(instance.price)
              ? rupeeToPaisa(instance.price)
              : instance.price,
            security: isValidNumber(instance?.security)
              ? rupeeToPaisa(instance?.security)
              : instance?.security || 0,
            kmLimit: instance.kmLimit ? instance.kmLimit : 1, // if we send it 0 then API will throw an error
          });
        }
        return newPackage;
      },
      []
    ),
  };
}

// convert plan list data price to rupees
export const convertPlanListDataPrice = (planList: any): any => {
  const updatedPlanData = planList?.planData?.map((plan: any) => {
    return convertPlanPriceToRupees(plan);
  });

  return { ...planList, planData: updatedPlanData };
};
// ------

////getting Rental Plan List in API
function* getRentalPlanListApiSaga(action: any): any {
  try {
    const result: any = yield call(
      planManagementServices.rentalPlanList,
      action.payload.data
    );
    const { showConfirmationModal, fetchPlanDetails } = action?.payload;
    if (result.status === 200) {
      const { planData }: any = result?.data?.data;

      // if fetchPlanDetails is true, then only store 1 plan in redux
      if (planData.length) {
        yield put({
          type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_SUCCESS,
          payload: {
            data: fetchPlanDetails
              ? convertPlanPriceToRupees(planData[0])
              : convertPlanListDataPrice(result?.data?.data),
            fetchPlanDetails: fetchPlanDetails,
          },
        });
        showConfirmationModal && showConfirmationModal();
      } else {
        // check default plan if plan is not available in plan list
        if (fetchPlanDetails) {
          yield put({
            type: PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_LOAD,
            payload: {
              ...action?.payload,
              fetchPlanDetails,
            },
          });
        } else {
          yield put({
            type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_SUCCESS,
            payload: {
              data: result?.data?.data,
            },
          });
        }
        // navigate to plan details page
        action.payload?.navigate && action.payload?.navigate();
      }
    } else {
      if (action.payload.fetchPlanDetails) {
        // check default plan if plan doesn't exist
        yield put({
          type: PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_LOAD,
          payload: {
            ...action?.payload,
            fetchPlanDetails: action.payload?.fetchPlanDetails,
          },
        });
      } else {
        yield put({
          type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_FAIL,
        });
        toastr.error("", handleMessage(result));
      }
    }
  } catch (error: any) {
    yield put({
      type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_FAIL,
    });
    toastr.error("", handleMessage(error));
  }
}

// fetch Default Plan
function* getDefaultPlanApiSaga(action: any): any {
  try {
    const result: any = yield call(
      planManagementServices.defaultPlanList,
      action.payload.data
    );
    if (result.status === 200) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_SUCCESS,
        payload: {
          data: convertPlanPriceToRupees(result?.data?.data),
        },
      });
      action?.payload?.navigate();
    } else {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_FAIL,
      });
      toastr.error("", handleMessage(result));
    }
  } catch (error: any) {
    yield put({
      type: PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_FAIL,
    });
    toastr.error("", handleMessage(error));
  }
}

// get Plan by id in API
function* getPlanByIdApiSaga(action: any): any {
  try {
    const result = yield call(planManagementServices.getPlanById, action);
    if (result.status === 200) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_SUCCESS,
        payload: convertPlanPriceToRupees(result?.data?.data),
      });
    } else {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_FAIL,
        error: result,
      });
      toastr.error("", handleMessage(result.data));
    }
  } catch (error: any) {
    yield put({
      type: PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_FAIL,
    });
    toastr.error("", handleMessage(error));
  }
}

// update Plan in API
function* updatePlanApiSaga(action: any): any {
  try {
    action.payload.data = convertPlanPriceToPaisa(action.payload.data);
    const result = yield call(planManagementServices.createUpdatePlan, action);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_SUCCESS,
        payload: result?.data,
      });
      yield put(clearRentalPlanList());
      action?.payload?.navigate();
      toastr.success(
        CONSTANTS?.toastTypes?.success,
        result?.data?.meta?.message
      );
    } else if (result?.error?.httpStatusCode === 422) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_FAIL,
        payload: result,
      });
    } else {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_FAIL,
        payload: result,
      });
      toastr.error("", handleMessage(result));
    }
  } catch (error: any) {
    yield put({
      type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_FAIL,
    });
    toastr.error("", handleMessage(error));
  }
}

// create Plan in API
function* createPlanApiSaga(action: any): any {
  try {
    action.payload.data = convertPlanPriceToPaisa(action.payload.data);
    const result = yield call(planManagementServices.createPlan, action);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_SUCCESS,
        payload: result?.data,
      });
      yield put(clearRentalPlanList());
      action?.payload?.navigate();
      toastr.success(
        CONSTANTS?.toastTypes?.success,
        result?.data?.meta?.message
      );
    } else {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_FAIL,
        payload: result,
      });
      toastr.error("", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_FAIL });
    toastr.error("", handleMessage(error));
  }
}

// update Plan status in API
function* updatePlanStatusApiSaga(action: any): any {
  try {
    const result =
      action.payload.status === STATUS.ACTIVE
        ? yield call(planManagementServices.activatePlan, action)
        : yield call(planManagementServices.deActivatePlan, action);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_SUCCESS,
        result: result?.result?.data,
        status: result?.status,
      });
      action.payload.updateSwitch();
    } else {
      yield put({
        type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_FAIL,
      });
      toastr.error("", handleMessage(result.result.data));
    }
  } catch (error: any) {
    yield put({
      type: PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_FAIL,
    });
    toastr.error("", handleMessage(error));
  }
}

export default function* planManagementSaga() {
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_LOAD,
    getRentalPlanListApiSaga
  );
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_LOAD,
    getDefaultPlanApiSaga
  );
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_LOAD,
    getPlanByIdApiSaga
  );
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_LOAD,
    updatePlanApiSaga
  );
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_LOAD,
    createPlanApiSaga
  );
  yield takeLatest(
    PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_LOAD,
    updatePlanStatusApiSaga
  );
}
