import { GOOGLE_MAPS_API_KEY } from "../config";

export enum UserRoles {
  All = "All",
  Company = "Company",
  SubCompany = "Sub-Company",
  Employee = "Employee",
}

export enum VehicleEmStandards {
  BS4 = "BS4",
  BS6 = "BS6",
}

export enum VehicleType {
  Bike = "Bike",
  Scooter = "Scooter",
}
export enum UserStatus {
  SelectStatus = "Select Status",
  All = "All",
  Deleted = "Deleted",
  Blocked = "Blocked",
  Active = "Active",
  InActive = "Inactive",
  Pending = "Pending",
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Cancelled = "Cancelled",
  Completed = "Completed",
  Requested = "Requested",
  Hidden = "Hidden",
}

export enum KYCStatus {
  KYCStatus = "KYC Status",
  All = "All",
  Verified = "Verified",
  Unverified = "Unverified",
}

export enum VehicleStatus {
  All = "All",
  Available = "Available",
  Engaged = "Engaged",
  InService = "InService",
  UnderMaintenance = "Under Maintenance",
  Blocked = "Blocked",
  Pending = "Pending",
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Completed = "Completed",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export enum Country {
  India = "India",
}

const statusMap = new Map();
statusMap.set(1, "Pending");
statusMap.set(2, "Upcoming");
statusMap.set(3, "Ongoing");
statusMap.set(4, "Ended");
statusMap.set(5, "Completed");
statusMap.set(6, "Cancelled");

export const API_KEY_MAP = "AIzaSyC9fP2BopC2GADep1fbIOCrLvGSjywhcUs" || ""; //new api key

export { statusMap };
