import { Box, Tooltip, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  bookingFunctionalities,
  modules,
} from "../../../../../../constants/RBACModuleEnums";
import CustomAccordion from "../../../../../../sharedComponents/molecules/Accordion/customAccordian";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../../themes/colors";
import RbackHelper from "../../../../../../utils/helperRBAC";
import {
  BOOKING_TYPES,
  BookingStatusColorCodes,
  BookingStatusDisplay,
  typographyConstants,
} from "../../../../../constants/constants";
import { ModifiedTag } from "../../../../../constants/exportImages";
import { VmsBookingDetailsProps } from "../../../../../interfaces/vehicleInterface";
import {
  default as en,
  default as rentalEn,
} from "../../../../../locale/rental-en.json";
import { dateFormat } from "../../../../../utils/helper";
import { routesConstants } from "../../../../../utils/RoutesConstants";

const { rbacMessages } = rentalEn;

const { isAccessRightsProvided } = RbackHelper;

export const BookingDetails = (props: VmsBookingDetailsProps) => {
  const navigate = useNavigate();
  const { global, buttonLabels, VehicleManagement } = en;
  const {
    onclick,
    expand,
    pagination,
    loading,
    bookingDetails,
    handlePaginationModel,
  } = props;

  // RBAC object for functionality
  const functionalitiesAccess = {
    isViewBooking: isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.BOOKING_DETAILS_TAB
    ),
  };

  const rowCountRef: React.MutableRefObject<any> = useRef(
    bookingDetails?.pageData?.total || 0
  );

  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (bookingDetails?.pageData?.total !== undefined) {
      rowCountRef.current = bookingDetails?.pageData?.total;
    }
    return rowCountRef.current;
  }, [bookingDetails?.pageData?.total]);

  // genarate unique row id
  const getRowId = (row: any) => {
    return row?.id;
  };

  // on row click navigate to booking details
  const handleRowClick = (params: GridRowParams) => {
    if (!functionalitiesAccess.isViewBooking) {
      return;
    } else if (params?.id) {
      navigate(`${routesConstants.BOOKING_DETAILS}/${params?.id}`);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: VehicleManagement.customerName,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => {
        const { row } = params;
        return (
          <Tooltip
            title={`${
              params?.row?.customer.firstName
                ? params?.row?.customer.firstName
                : ""
            } ${
              params?.row?.customer.lastName
                ? params?.row?.customer.lastName
                : ""
            }`}
            arrow
          >
            <Box component="span">
              {(row?.isBookingModified || row?.isModelModified) && (
                <img
                  src={ModifiedTag}
                  style={{ position: "absolute", left: 10 }}
                />
              )}
              <Typography
                color={colors?.black}
                variant={typographyConstants?.BODY}
                style={{
                  position: "relative",
                  top: 12,
                  fontSize: 14,
                }}
              >
                {`${
                  params?.row?.customer.firstName
                    ? params?.row?.customer.firstName
                    : ""
                } ${
                  params?.row?.customer.lastName
                    ? params?.row?.customer.lastName
                    : ""
                }`}
              </Typography>
            </Box>
          </Tooltip>
        );
      },
      flex: 1,
      resizable: false,
    },
    {
      field: "bookingNo",
      headerName: VehicleManagement.bookingNo,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={params?.row?.bookingNumber ? params?.row?.bookingNumber : ""}
        >
          <span>
            {params?.row?.bookingNumber
              ? params?.row?.bookingNumber
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "vehicleModel",
      headerName: VehicleManagement.vehicleModel,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.modelDisplayName
            ? params?.row?.modelDisplayName
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "form",
      headerName: VehicleManagement.form,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.startDate
            ? dateFormat(params?.row?.startDate)
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "to",
      headerName: VehicleManagement.to,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.endDate ? dateFormat(params?.row?.endDate) : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "bookingType",
      headerName: VehicleManagement.bookingType,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.createdByUserType
            ? BOOKING_TYPES[
                params?.row?.createdByUserType as keyof typeof BOOKING_TYPES
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "status",
      headerName: global.status,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span
          style={{
            color:
              BookingStatusColorCodes[
                params?.row?.status as keyof typeof BookingStatusColorCodes
              ],
          }}
        >
          {params?.row?.status
            ? BookingStatusDisplay[
                params?.row?.status as keyof typeof BookingStatusDisplay
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
  ];
  return (
    <CustomAccordion
      expand={expand}
      renderAccordianTab={
        <Typography variant={typographyConstants.HEADING}>
          {VehicleManagement.bookingDetails}
        </Typography>
      }
      renderAccordionBody={
        <DataTable
          columns={columns}
          rows={bookingDetails?.bookings}
          disableColumnMenu
          disableColumnSorting
          headerAlign={"center"}
          getRowId={getRowId}
          rowHeight={50}
          onRowClick={handleRowClick}
          loading={loading}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModel}
          rowCount={rowCount}
          paginationModel={pagination?.bookingPagination}
          page={pagination?.bookingPagination?.page}
          hideColumnSeperator
        />
      }
      onClick={onclick}
      customBodyStyle={{ padding: "12px" }}
    />
  );
};
