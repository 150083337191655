import { GenericObject } from "../../../constants/constants";
import * as actionTypes from "../../actions/actionTypes";

interface initialStateInterface {
  onLoad: boolean;
  rentalPlanListData: GenericObject;
  rentalPlanDetails: Object | null;
  planDetailErrors: GenericObject;
  addEditRentalPlanLoader: boolean;
}

const initialState: initialStateInterface = {
  onLoad: false,
  rentalPlanDetails: {},
  rentalPlanListData: { pagination: {} },
  planDetailErrors: {
    errorList: [],
    errorMessage: "",
  },
  addEditRentalPlanLoader: false,
};

function planManagementReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_LOAD:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_LOAD:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_LOAD:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_LOAD:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_LOAD:
      return {
        ...state,
        onLoad: true,
      };
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_LOAD:
      return {
        ...state,
        onLoad: true,
        rentalPlanDetails: {},
        rentalPlanListData: {
          pagination: state?.rentalPlanListData?.pagination || {},
        },
      };

    // Create rental plan
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_LOAD:
      return {
        ...state,
        onLoad: true,
        addEditRentalPlanLoader: true,
      };

    // Update rental plan
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_LOAD:
      return {
        ...state,
        onLoad: true,
        addEditRentalPlanLoader: true,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_SUCCESS:
      if (action.payload.fetchPlanDetails) {
        return {
          ...state,
          onLoad: false,
          rentalPlanDetails: action?.payload?.data,
        };
      }
      return {
        ...state,
        onLoad: false,
        rentalPlanDetails: {},
        rentalPlanListData: action.payload.data,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CLEAR_CURRENT_RENTAL_PLAN_LIST:
      return {
        ...state,
        rentalPlanListData: {},
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.EDIT_CURRENT_RENTAL_PLAN:
      return {
        ...state,
        rentalPlanDetails: action.payload,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        rentalPlanDetails: action.payload.data,
        onLoad: false,
      };
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        onLoad: false,
        rentalPlanDetails: action.payload,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_SUCCESS:
      return {
        ...state,
        onLoad: false,
        addEditRentalPlanLoader: false,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_SUCCESS:
      return {
        ...state,
        onLoad: false,
        addEditRentalPlanLoader: false,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        onLoad: false,
      };

    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_FAIL:
      return {
        ...state,
        onLoad: false,
        rentalPlanListData: {},
      };
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_FAIL:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CREATE_RENTAL_PLAN_FAIL:
      return {
        ...state,
        onLoad: false,
        planDetailErrors: {
          errorList: action?.payload?.error?.data.minimumErrorPackages,
          errorMessage: action?.payload?.error?.errorMessage,
        },
        addEditRentalPlanLoader: false,
      };
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.CLEAR_RENTAL_PLAN_ERROR_DATA: {
      return {
        ...state,
        onLoad: false,
        planDetailErrors: {
          errorList: [],
          errorMessage: "",
        },
      };
    }
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.UPDATE_RENTAL_PLAN_STATUS_FAIL:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_RENTAL_PLAN_BY_ID_FAIL:
    case actionTypes.PLAN_MANAGEMENT_ACTIONS.API_DEFAULT_PACKAGE_LIST_FAIL:
      return {
        ...state,
        onLoad: false,
      };
    default:
      return state;
  }
}

export default planManagementReducer;
