import { colors } from "../../../../../../themes/colors";

export const StyleObject: any = {
  iotCardOuterBoxShadow: {
    boxShadow: `4px 4px 8px 0px ${colors.box_shadow_black}`,
    borderRadius: "9px",
    margin: "20px 20px 0px 20px",
  },
  iotCardTopBox: {
    backgroundColor: colors.primary_light_gray2,
    padding: "10px",
    borderRadius: "9px 9px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iotCardStatus: {
    height: "26px",
    width: "90px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  vehicleDetailsUiTopBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  vehicleDetailsUiStatus: {
    width: "86px",
    height: "30px",
    backgroundColor: colors.secondary_green,
    borderRadius: "30px",
    textAlign: "center",
    padding: "6px 16px 8px 16px",
    color: "white",
  },
  vehicleDetailsDocUploadChip: {
    backgroundColor: "white",
    border: `1px solid ${colors.text_quatenary_black}`,
    borderRadius: "8px",
    padding: "16px 8px",
    minWidth: "67px",
    fontSize: "13px",
    color: colors.tertiary_black,
    cursor: "pointer",
    "&:active": {
      backgroundColor: colors.secondary_white,
      border: `2px solid ${colors.primary_dark_blue2}`,
    },
    vehicleDetailsDocUploadChipActive: {
      borderRadius: "8px",
      padding: "15px 8px",
      fontSize: "13px",
      color: colors.tertiary_black,
      cursor: "pointer",
      backgroundColor: colors.secondary_white,
      border: `2px solid ${colors.primary_dark_blue2}`,
    },
    vehicleDetailsDocUploadTableHeader: {
      width: "80%",
      height: "auto",
      minHeight: "30px",
      backgroundColor: colors.secondary_gray2,
      borderRadius: "8px",
      marginTop: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    challanDetailsSDBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      margin: "10px",
      padding: "10px",
    },
    viewVehicleBox: { display: "flex", flexDirection: "column", gap: "20px" },
  },
};
