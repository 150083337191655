import store from "../redux/store";

/////this helps return the boolean value for the fucntionality and modules if it is accesible for the current user
const RbacHelper: any = {
  /////this helps return the boolean value for the fucntionality and modules if it is accesible for the current user
  isAccessRightsProvided: (module?: string, functionality?: string) => {
    const authorizedModules =
      store.getState()?.newAuthReducer?.userData?.authorizedModules;
    if (authorizedModules?.length && module) {
      let hasAccess = authorizedModules?.some(
        (modules: any) => modules["module"] === module
      );
      if (hasAccess && functionality) {
        let moduleIndex = authorizedModules.findIndex(
          (modules: any) => modules["module"] === module
        );
        return authorizedModules[moduleIndex]?.functionalities.includes(
          functionality
        );
      } else {
        return hasAccess;
      }
    } else {
      return false;
    }
  },
  canAccessSideDrawer: (module: any) => {
    const authorizedDrawers =
      store.getState()?.newAuthReducer?.userData?.authorizedDrawers;
    return !!authorizedDrawers[module];
  },
};

export default RbacHelper;
