import {
  Close,
  CurrencyRupee,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import { typographyConstants } from "../../../../constants/constants";
import {
  SurgeIcon,
  fadedCalenderIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { toPascalCase } from "../../../../utils/helper";
import { StyleObject } from "../StyleObject";

const SurgeConfiguration = (props: any) => {
  // props
  const {
    fields,
    errorFields,
    handleSpecificDateRangeSelection,
    resetStatesByType,
    activeInactiveStatusArray,
    isEditing,
    surgeByIdData,
    masterPlanDurationsData,
    handlePackages,
    handleSurgeType,
    updateSurgeDetails,
    daysOfWeek,
    handleDaySelection,
    handleSurgeDateType,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={StyleObject?.surgeConfiguration_wrapperBox}>
      {/* surge package section */}
      <Box sx={StyleObject?.surgeConfiguration_surgePackage_wrapperCard}>
        <Box sx={StyleObject?.surgeConfiguration_surgePackage_box1}>
          <Box sx={StyleObject?.surgeConfiguration_surgePackage_box3}>
            <img src={SurgeIcon} />
            <Typography color={colors.white} variant={typographyConstants.BODY}>
              {rentalEn?.surgeManagement?.SelectSurgePackage}
            </Typography>
          </Box>
          {isArrayNotEmpty(masterPlanDurationsData) &&
            masterPlanDurationsData?.length > 1 && (
              <Typography
                variant={typographyConstants.BODY}
                sx={StyleObject?.selectAllText}
                onClick={() => {
                  isEditing === undefined || isEditing
                    ? handlePackages(
                        {},
                        fields?.durations?.length ===
                          masterPlanDurationsData?.length
                          ? rentalEn?.global?.clear
                          : rentalEn?.global?.all
                      )
                    : null;
                }}
              >
                {fields?.durations?.length === masterPlanDurationsData?.length
                  ? rentalEn?.global?.unselectAll
                  : rentalEn?.global?.selectAll}
              </Typography>
            )}
        </Box>
        <Box sx={StyleObject?.surgeConfiguration_surgePackage_box2}>
          {masterPlanDurationsData?.map(
            (packageInstance: any, index: number) => {
              const isChecked: boolean =
                fields?.durations.includes(packageInstance);
              return (
                <FormControlLabel
                  key={String(index)}
                  label={`${packageInstance} Day${
                    packageInstance !== 1 ? "s" : ""
                  }`}
                  sx={{ width: 120 }}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(e: any) => {
                        isEditing === undefined || isEditing
                          ? handlePackages(packageInstance)
                          : null;
                      }}
                    />
                  }
                />
              );
            }
          )}
        </Box>
        <Typography
          sx={{ pl: "13px" }}
          variant={typographyConstants.ERRORMESSAGE}
        >
          {errorFields?.durations ? errorFields?.durations : null}
        </Typography>
        {isArrayNotEmpty(fields?.durations) &&
          !isArrayNotEmpty(masterPlanDurationsData) && (
            <Typography color={colors.white} variant={typographyConstants.BODY}>
              {rentalEn?.surgeManagement?.NoPackageDataAvailable}
            </Typography>
          )}
      </Box>
      {/* surge date type section */}
      <Box sx={StyleObject?.surgeConfiguration_surgeDateType_wrapperCard}>
        <Box sx={StyleObject?.surgeConfiguration_surgeDateType_box2}>
          <img src={fadedCalenderIcon} />
          <Typography color={colors.white} variant={typographyConstants.BODY}>
            {rentalEn?.surgeManagement?.SurgeDateType}
          </Typography>
        </Box>
        <Box sx={StyleObject?.surgeConfiguration_surgeDateType_box1}>
          <FormControlLabel
            label={rentalEn?.surgeManagement?.SurgeDateTypes?.SpecificDays}
            sx={{ width: 150 }}
            control={
              <Radio
                checked={
                  fields?.dateType ===
                  rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS
                }
                onFocus={() =>
                  !isEditing ? resetStatesByType("dateType") : null
                } // reset surge date type state
                onChange={() => {
                  isEditing === undefined || isEditing
                    ? handleSurgeDateType(
                        rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS
                      )
                    : null; // set the selectedSurgeDateType value depending on which weekdays UI will be rendered
                }}
              />
            }
            aria-readonly={isEditing}
            disabled={
              isEditing &&
              surgeByIdData?.dateType !==
                rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS
                ? true
                : false
            }
          />
          <FormControlLabel
            label={rentalEn?.surgeManagement?.SurgeDateTypes?.SpecificDate}
            sx={{ width: 150 }}
            control={
              <Radio
                checked={
                  fields?.dateType ===
                  rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
                }
                onFocus={() =>
                  !isEditing ? resetStatesByType("dateType") : null
                } // reset surge date type state
                onChange={() => {
                  isEditing === undefined || isEditing
                    ? handleSurgeDateType(
                        rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
                      )
                    : null;
                }}
              />
            }
            aria-readonly={isEditing}
            disabled={
              isEditing &&
              fields?.dateType !==
                rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC
                ? true
                : false
            }
          />
        </Box>
        {fields?.dateType ===
          rentalEn?.surgeManagement?.SurgeDateTypes?.WEEKDAYS && (
          <>
            <Box sx={StyleObject?.surgeConfiguration_surgeDateType_weekdaysBox}>
              {daysOfWeek?.map((day: any, index: number) => {
                return (
                  <CustomButton
                    label={toPascalCase(day)}
                    variant={
                      fields?.daysOfWeek?.includes(day)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      isEditing === undefined || isEditing
                        ? handleDaySelection(day)
                        : null
                    }
                    sx={{ width: 100 }}
                  />
                );
              })}
            </Box>
            <Typography
              sx={{ pl: "13px" }}
              variant={typographyConstants.ERRORMESSAGE}
            >
              {errorFields?.daysOfWeek ? errorFields?.daysOfWeek : null}
            </Typography>
          </>
        )}
        {fields?.dateType ===
          rentalEn?.surgeManagement?.SurgeDateTypes?.SPECIFIC && (
          <Box sx={StyleObject?.surgeConfiguration_surgeDateType_specificBox}>
            <RangePicker
              dateFormat={"dd/MM/yyyy"}
              placeholderText={
                rentalEn?.surgeManagement?.SelectSurgeStartAndEndDate
              }
              selectsRange={true}
              startDate={fields?.startDate}
              endDate={fields?.endDate}
              onChange={(update: any) =>
                handleSpecificDateRangeSelection(update)
              }
              minDate={moment().toDate()}
              isClearable={false}
              icon={fadedCalenderIcon}
            />
            <Typography
              sx={{ pt: 2, pl: "13px" }}
              variant={typographyConstants.ERRORMESSAGE}
            >
              {errorFields?.startEndDate ? errorFields?.startEndDate : null}
            </Typography>
          </Box>
        )}
        <Typography
          sx={{ pl: "13px" }}
          variant={typographyConstants.ERRORMESSAGE}
        >
          {errorFields?.dateType ? errorFields?.dateType : null}
        </Typography>
      </Box>
      {/* surge type section */}
      <Box sx={StyleObject?.surgeConfiguration_surgeType_wrapperCard}>
        <Box sx={StyleObject?.surgeConfiguration_surgeType_box1}>
          <img src={SurgeIcon} />
          <Typography color={colors.white} variant={typographyConstants.BODY}>
            {rentalEn?.surgeManagement?.SelectSurgeType}
          </Typography>
        </Box>
        <Box sx={StyleObject?.surgeConfiguration_surgeType_incrementBox}>
          <FormControlLabel
            label={rentalEn?.surgeManagement?.surgeTypes?.Multiplier}
            sx={{ width: 150 }}
            control={
              <Radio
                checked={
                  fields?.surgeType ===
                  rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                }
                onFocus={() =>
                  !isEditing ? resetStatesByType("surgeType") : null
                } // reset surge type state
                onChange={() => {
                  isEditing === undefined || isEditing
                    ? handleSurgeType(
                        rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                      )
                    : null;
                }}
              />
            }
            aria-readonly={isEditing}
            disabled={
              isEditing &&
              fields?.surgeType !==
                rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                ? true
                : false
            }
          />
          <FormControlLabel
            label={rentalEn?.surgeManagement?.surgeTypes?.Increment}
            sx={{ width: 150 }}
            control={
              <Radio
                checked={
                  fields?.surgeType ===
                  rentalEn?.surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
                    ? true
                    : false
                }
                onFocus={() =>
                  !isEditing ? resetStatesByType("surgeType") : null
                } // reset surge type state
                onChange={() => {
                  isEditing === undefined || isEditing
                    ? handleSurgeType(
                        rentalEn?.surgeManagement?.surgeTypes
                          ?.INCREASE_BY_AMOUNT
                      )
                    : null;
                }}
              />
            }
            aria-readonly={isEditing}
            disabled={
              isEditing &&
              fields?.surgeType !==
                rentalEn?.surgeManagement?.surgeTypes?.INCREASE_BY_AMOUNT
                ? true
                : false
            }
          />
        </Box>
        <Typography
          sx={{ pl: "13px" }}
          variant={typographyConstants.ERRORMESSAGE}
        >
          {errorFields?.surgeType ? errorFields?.surgeType : null}
        </Typography>
        <Box sx={{ px: 2 }}>
          {fields?.surgeType && (
            <Input
              placeholder={
                fields?.surgeType ===
                rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                  ? rentalEn?.surgeManagement?.PriceMultiplierPlaceholder
                  : rentalEn?.surgeManagement?.EnterIncrementAmount
              }
              label={
                fields?.surgeType ===
                rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                  ? rentalEn?.surgeManagement?.PriceMultiplier
                  : rentalEn?.surgeManagement?.EnterIncrementAmount
              }
              helperText={
                errorFields?.surgeValue ? errorFields?.surgeValue : null
              }
              inputProps={{ maxLength: 7 }}
              value={fields?.surgeValue ?? ""}
              error={errorFields?.surgeValue ? true : false}
              startAdornment={
                fields?.surgeType ===
                rentalEn?.surgeManagement?.surgeTypes?.MULTIPLIER
                  ? Close
                  : CurrencyRupee
              }
              width={350}
              onChange={(e: any) => {
                updateSurgeDetails("surgeValue", e.target.value);
              }}
            />
          )}
        </Box>
      </Box>
      {/* surge status section */}
      <Box sx={StyleObject?.surgeConfiguration_surgeStatus_wrapperCard}>
        <Box sx={StyleObject?.surgeConfiguration_surgeStatus_box1}>
          <img src={SurgeIcon} />
          <Typography color={colors.white} variant={typographyConstants.BODY}>
            {rentalEn?.surgeManagement?.SelectSurgeStatus}
          </Typography>
        </Box>
        <Box sx={StyleObject?.surgeConfiguration_surgeStatus_box2}>
          {activeInactiveStatusArray?.map((status: any, index: number) => {
            return (
              <FormControlLabel
                key={String(index)}
                label={status?.displayName}
                sx={{ width: 150 }}
                control={
                  <Radio
                    checked={fields?.surgeStatus === status?.name}
                    onChange={() => {
                      isEditing === undefined || isEditing
                        ? updateSurgeDetails("surgeStatus", status?.name)
                        : null;
                    }}
                  />
                }
                aria-readonly={isEditing}
                disabled={
                  isEditing && fields?.surgeStatus !== status?.name
                    ? true
                    : false
                }
              />
            );
          })}
        </Box>
        <Typography
          sx={{ pl: "13px" }}
          variant={typographyConstants.ERRORMESSAGE}
        >
          {errorFields?.surgeStatus ? errorFields?.surgeStatus : null}
        </Typography>
      </Box>
      {/* surge name section */}
      <Box sx={StyleObject?.surgeConfiguration_surgeName_wrapperCard}>
        <Box sx={StyleObject?.surgeConfiguration_surgeName_box1}>
          <img src={SurgeIcon} />
          <Typography color={colors.white} variant={typographyConstants.BODY}>
            {rentalEn?.surgeManagement?.SaveThisSurge}
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          <Input
            placeholder={rentalEn?.surgeManagement?.NameThisSurge}
            label={rentalEn?.surgeManagement?.NameThisSurge}
            helperText={
              errorFields?.surgeName
                ? errorFields?.surgeName
                : rentalEn?.surgeManagement?.NameSurgeForEasierMaintainnce
            }
            value={fields?.surgeName}
            error={errorFields?.surgeName ? true : false}
            startAdornment={DriveFileRenameOutline}
            inputProps={{ maxLength: 50 }}
            width={350}
            onChange={(e: any) => {
              updateSurgeDetails("surgeName", e.target.value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SurgeConfiguration;
