import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { checkIfImageExists } from "../../../../freedo-x-src/utils/helper";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../themes/colors";
import {
  CustomerAction,
  CustomerActionDisplay,
  CustomerStatus,
  CustomerStatusDisplay,
  CustomerStatusDisplayColor,
  KycVerificationStatus,
  displayGenderEnums,
  typographyConstants,
  CustomerStatusDisplayCustomColor,
} from "../../../constants/constants";
import {
  FemaleIcon,
  MaleIcon,
  OthersIcon,
  calenderLineIcon,
  circularAddIcon,
  editEmergency,
  mailOpenIcon,
  phoneLineIcon,
} from "../../../constants/exportImages";
import { getDateTimeFromTimeStamp, getInitials } from "../../../utils/helper";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import en from "../../../locale/rental-en.json";
import RbackHelper from "../../../../utils/helperRBAC";
import {
  CustomerFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
interface CustomerProfileCardInterface {
  customerProfileData: any;
  customerEmergencyContacts: any;
  width?: string;
  addEmergencyClickHandler: any;
  handleBlockButtonClick: any;
  handleDeleteButtonClick: any;
  handleUnblockButtonClick: any;
  emergencyContactLoader: boolean;
  customerDetailsLoader: boolean;
  isFunctionalityBlocked?: boolean;
}
interface customerActionOptionsInterface {
  key: CustomerAction;
  displayName: string;
  actionhandler: any;
  isDisabled: boolean;
  resultanrStatus: string;
}

const CustomerProfileCard = ({
  customerProfileData,
  customerEmergencyContacts,
  width = "30%",
  addEmergencyClickHandler,
  handleBlockButtonClick,
  handleDeleteButtonClick,
  handleUnblockButtonClick,
  emergencyContactLoader,
  customerDetailsLoader,
  isFunctionalityBlocked,
}: CustomerProfileCardInterface) => {
  const customerFullName =
    customerProfileData?.firstName || customerProfileData?.lastName
      ? customerProfileData?.firstName + " " + customerProfileData?.lastName
      : "";

  // gender icons
  const displayGenderIcons = {
    MALE: MaleIcon,
    FEMALE: FemaleIcon,
    OTHERS: OthersIcon,
  };

  const isCustomerBlockAvailable = RbackHelper.isAccessRightsProvided(
    modules.CUSTOMER_MANAGEMENT,
    CustomerFunctionalities.BLOCK_CUSTOMER
  );
  const isCustomerDeleteAvailable = RbackHelper.isAccessRightsProvided(
    modules.CUSTOMER_MANAGEMENT,
    CustomerFunctionalities.DELETE_CUSTOMER
  );

  // action object for customer
  const customerActionOptions =
    customerProfileData?.status !== CustomerStatus.DELETED
      ? customerProfileData?.status === CustomerStatus.BLOCKED
        ? [
            {
              key: CustomerAction.UNBLOCK,
              displayName: CustomerActionDisplay.UNBLOCK,
              actionhandler: handleUnblockButtonClick,
              isDisabled:
                customerProfileData?.status === "ACTIVE" ? true : false,
              icon: <DoNotDisturbOffOutlinedIcon sx={{ fontSize: "16px" }} />,
            },
            {
              key: CustomerAction.DELETE,
              displayName: CustomerActionDisplay.DELETE,
              actionhandler: handleDeleteButtonClick,
              isDisabled:
                customerProfileData?.status === "INACTIVE" ? true : false,
              icon: <DeleteForeverOutlinedIcon sx={{ fontSize: "16px" }} />,
            },
          ]
        : [
            {
              key: CustomerAction.BLOCK,
              displayName: CustomerActionDisplay.BLOCK,
              actionhandler: handleBlockButtonClick,
              isDisabled:
                customerProfileData?.status === "BLOCKED" ? true : false,
              icon: <DoNotDisturbAltIcon sx={{ fontSize: "16px" }} />,
            },
            /*  {
              key: CustomerAction.DELETE,
              displayName: CustomerActionDisplay.DELETE,
              actionhandler: handleDeleteButtonClick,
              isDisabled:
                customerProfileData?.status === "INACTIVE" ? true : false,
              icon: <DeleteForeverOutlinedIcon sx={{ fontSize: "16px" }} />,
            }, */
          ]
      : [];

  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EmergencyContactLoader = () => {
    return (
      <Box>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="100%"
          height="5vh"
        />
      </Box>
    );
  };
  return (
    <>
      {customerDetailsLoader ? (
        <EmergencyContactLoader />
      ) : (
        <>
          <Stack
            // gap="3vh"
            sx={{
              background: colors.card_bg_color,
              padding: "10px",
              borderRadius: "4px",
              width: { width },
              justifyContent: "space-between",
              position: "relative",
              height: "240px",
            }}
          >
            <Stack
              className="ProfileAvatarAndAction"
              direction="row"
              sx={{ justifyContent: "space-between" }}
            >
              <Stack direction="row" gap="1vw">
                <Box>
                  <Badge
                    badgeContent=" "
                    variant="dot"
                    title={
                      CustomerStatusDisplay[
                        customerProfileData?.status as keyof typeof CustomerStatusDisplay
                      ]
                    }
                    /*  color={
                      CustomerStatusDisplayColor[
                        customerProfileData?.status as keyof typeof CustomerStatusDisplayColor
                      ]
                    } */
                    sx={{
                      [`.MuiBadge-dot`]: {
                        borderRadius: "5px",
                        height: "10px",
                        minWidth: "10px",
                        padding: "0",
                        top: "6px",
                        right: "8px",
                        backgroundColor:
                          CustomerStatusDisplayCustomColor[
                            customerProfileData?.status as keyof typeof CustomerStatusDisplayCustomColor
                          ],
                      },
                    }}
                  >
                    {customerProfileData?.profileImage &&
                    checkIfImageExists(customerProfileData?.profileImage) ? (
                      <Box
                        sx={{
                          backgroundSize: "contain",
                          backgroundImage: `url(${customerProfileData?.profileImage})`,
                          width: "45px",
                          height: "45px",
                          /* height: "7vh",
                          width: "3vw", */
                          borderRadius: "50%",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                        //  className={styles.modelImageContainer}
                      ></Box>
                    ) : (
                      <Avatar
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          width: "45px",
                          height: "45px",
                        }}
                      >
                        {customerFullName && getInitials(customerFullName)}
                      </Avatar>
                    )}
                  </Badge>
                </Box>

                {/* Name & Gender */}
                <Box>
                  <Typography
                    // variant="caption"
                    sx={{ padding: "unset" }}
                    variant={typographyConstants.HEADING}
                  >
                    {customerFullName ?? ""}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap="2px">
                    <img
                      src={
                        displayGenderIcons[
                          customerProfileData?.gender as keyof typeof displayGenderIcons
                        ]
                      }
                      style={{ height: "12px" }}
                    />
                    <Typography>
                      {
                        displayGenderEnums[
                          customerProfileData?.gender as keyof typeof displayGenderEnums
                        ]
                      }
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              {/* actions */}
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon
                    sx={{
                      color: "#09AEAF",
                    }}
                  />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      padding: "5px 5px !important",
                      borderRadius: "5px !important",
                    },
                  }}
                >
                  {customerActionOptions.map((option: any, index: number) => {
                    if (customerProfileData?.status) {
                      if (
                        !isCustomerBlockAvailable &&
                        option.key === CustomerAction.BLOCK
                      ) {
                        return null;
                      }
                      if (
                        !isCustomerDeleteAvailable &&
                        option.key === CustomerAction.DELETE
                      ) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={option.key}
                          onClick={() => option.actionhandler()}
                          disabled={option.isDisabled}
                          sx={{
                            cursor: option.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            borderBottom:
                              customerActionOptions?.length > 1 && index === 0
                                ? "1px solid #0A555C"
                                : "none",
                            gap: "4px",
                          }}
                        >
                          {option.icon} {option.displayName}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </Box>
            </Stack>
            <Stack className="ProfileDescription" gap="1vh">
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  justifyContent: "flex-start",
                  gap: "0.5vw",
                  alignItems: "center",
                }}
              >
                <img src={mailOpenIcon} style={{ height: "0.9rem" }} />{" "}
                <Typography variant="caption">
                  {customerProfileData?.email}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap="0.5vw">
                  <img src={phoneLineIcon} style={{ height: "0.9rem" }} />
                  <Typography variant="caption">
                    {customerProfileData?.mobileNumber}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap="0.5vw">
                  <img src={calenderLineIcon} style={{ height: "0.9rem" }} />
                  <Typography variant="caption">
                    {getDateTimeFromTimeStamp(
                      customerProfileData?.dateOfJoining,
                      "date"
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Divider
              sx={{
                background:
                  "linear-gradient(90deg, #F6F6F6 4.72%, #09AEAF 50%, #F6F6F6 93.72%)",
                height: "3px",
              }}
            />

            {/* Emergency section */}
            <Stack
              className="ProfileEmergency"
              justifyContent="space-between"
              gap="1vh"
            >
              <Stack direction="row" gap="0.5vw" alignItems="center">
                <Typography
                  variant={typographyConstants.HEADING}
                  sx={{ padding: "unset" }}
                >
                  Emergency Details
                </Typography>
                {customerEmergencyContacts &&
                customerEmergencyContacts.length > 0 &&
                customerEmergencyContacts[0].status ? (
                  customerEmergencyContacts[0].status ===
                  KycVerificationStatus.VERIFIED ? (
                    <>
                      <CheckCircleIcon color="success" height="1.2rem" />

                      {/* Edit Emergency */}
                      {/* {RbackHelper.isAccessRightsProvided(
                        modules.CUSTOMER_MANAGEMENT,
                        CustomerFunctionalities.EDIT_CUSTOMER_EMERGENCY_DETAILS
                      ) &&
                        !isFunctionalityBlocked && (
                          <>
                            <img
                              src={editEmergency}
                              alt="editEmergency"
                              onClick={addEmergencyClickHandler}
                              style={{
                                height: "2rem",
                                cursor: "pointer",
                              }}
                            />
                          </>
                        )} */}
                    </>
                  ) : (
                    <>
                      {RbackHelper.isAccessRightsProvided(
                        modules.CUSTOMER_MANAGEMENT,
                        CustomerFunctionalities.EDIT_CUSTOMER_EMERGENCY_DETAILS
                      ) &&
                        !isFunctionalityBlocked && (
                          <ErrorIcon
                            // color="error"
                            height="1.2rem"
                            onClick={
                              isFunctionalityBlocked
                                ? null
                                : addEmergencyClickHandler
                            }
                            sx={[
                              { cursor: "pointer" },
                              {
                                "&:hover": {
                                  transform: "scale(1.2, 1.2)",
                                },
                              },
                              { color: "#FF3B30" },
                            ]}
                          />
                        )}
                    </>
                  )
                ) : null}
              </Stack>

              {emergencyContactLoader ? (
                <EmergencyContactLoader />
              ) : customerEmergencyContacts &&
                customerEmergencyContacts?.length > 0 ? (
                <>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box width={"85%"} display={"flex"} gap={"2px"}>
                      <Typography
                        title={customerEmergencyContacts[0]?.name}
                        sx={{
                          maxWidth:
                            customerEmergencyContacts[0].status &&
                            customerEmergencyContacts[0].status ===
                              KycVerificationStatus.VERIFIED &&
                            !isFunctionalityBlocked
                              ? "60%"
                              : "60%",
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {customerEmergencyContacts[0]?.name}
                      </Typography>
                      <Typography>|</Typography>
                      <Typography>
                        {customerEmergencyContacts[0]?.mobileNumber}
                      </Typography>
                    </Box>
                    {/* Edit Emergency */}
                    {customerEmergencyContacts[0].status &&
                      customerEmergencyContacts[0].status ===
                        KycVerificationStatus.VERIFIED &&
                      !isFunctionalityBlocked && (
                        <Box width={"15%"}>
                          <img
                            src={editEmergency}
                            alt="editEmergency"
                            onClick={addEmergencyClickHandler}
                            style={{
                              height: "2rem",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      )}
                  </Stack>
                </>
              ) : (
                <>
                  {RbackHelper.isAccessRightsProvided(
                    modules.CUSTOMER_MANAGEMENT,
                    CustomerFunctionalities.EDIT_CUSTOMER_EMERGENCY_DETAILS
                  ) &&
                    !isFunctionalityBlocked && (
                      <CustomButton
                        label={en.CustomerManagement.AddEmergencyTitle}
                        onClick={addEmergencyClickHandler}
                        variant="text"
                        startImageSrc={circularAddIcon}
                        sx={[
                          {
                            textDecoration: "underline",
                            color: "#3dbebf",
                            padding: "1px 5px",
                            gap: "0px",
                          },
                          {
                            "&:hover": {
                              border: "none",
                              background: "none",
                              color: "#01353D !important",
                              textDecoration: "underline",
                            },
                          },
                        ]}
                      />
                    )}
                </>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default CustomerProfileCard;
