import bookingManagementReducer from "./bookingManagementReducer";
import newBusinessManagementReducer from "./businessManagementReducer";
import customerManagementReducer from "./customerManagementReducer";
import rentalsIotManagementReducer from "./iotManagementReducer";
import newManualBookingReducer from "./manualBookingReducer";
import paymentManagementReducer from "./paymentManagementReducer";
import planManagementReducer from "./planManagementReducer";
import rentalsCommonReducer from "./rentalsCommonReducer";
import surgeReducer from "./surgeReducer";
import rentalsVehicleManagementReducer from "./vehicleManagementReducer";
import reportsReducer from "./reportReducer";
import operatorManagementReducer from "./operatorManagementReducer";

export const rentalReducers = {
  newManualBookingReducer,
  rentalsCommonReducer,
  planManagementReducer,
  rentalsVehicleManagementReducer,
  newBusinessManagementReducer,
  surgeReducer,
  customerManagementReducer,
  bookingManagementReducer,
  rentalsIotManagementReducer,
  paymentManagementReducer,
  reportsReducer,
  operatorManagementReducer,
};
