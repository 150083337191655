import axios from "axios";
import {
  FREEDO_X_URL,
  customAxios,
  BASE_URL,
  API_URLS,
  getApiUrl,
} from "../../../config";
import { constructUrl } from "../../utils/helper";

// get all available  model list
const getAvailableModels = (payload: any) => {
  try {
    let url = `${BASE_URL}${API_URLS.getModelList}`;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (ex) {
    return ex;
  }
};

// calculate total charges of model and package selected
const calculateCharges = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().calculateCharges}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get slot pause list on which days warehouse is closed
const getSlotPause = (payload: any) => {
  try {
    let url =
      BASE_URL +
      `${API_URLS.getSlotPauseList}?branch=${payload?.branchName}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get details of model selected
const getModelDetails = (payload: any) => {
  try {
    let url =
      BASE_URL +
      `${API_URLS.getModelList}/${payload?.modelName}?startDate=${payload?.startDate}&endDate=${payload?.endDate}&useCase="${payload?.useCase}"&branches=["${payload?.branchName}"]`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Search whether the user existed or not
const searchCustomer = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().searchCustomer}${
      payload?.mobile_number
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// if user doesnot exists the register the user
const registerUser = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().registerUser}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// verify OTP
const verifyOTP = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().verifyOTP}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Resend OTP
const resendOTP = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().resendOTP}/${payload?.token}`;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Get Customer Profile
const getCustomerProfile = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().registerUser}/${
      payload?.customerId
    }?ipi=true`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Need to add adress id
const getdeliverySlots = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().createBooking}${payload.branchName}${
      getApiUrl().deliverySlots
    }deliveryType=${payload.deliveryType}&bookingStartDate=${
      payload.bookingStartDate
    }`;

    if (payload?.addressId) {
      url += `&addressId=${payload?.addressId}`;
    }
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

//Add Customer address (Home Delivery Address)
const addCustomerAddress = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().addAddress}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Get Customer Address List
const getCustomerAddressList = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl(payload?.customerId).getCustomerAddressList
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// create Booking
const createBooking = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().createBooking}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getAvailableModels,
  calculateCharges,
  getSlotPause,
  getModelDetails,
  searchCustomer,
  registerUser,
  verifyOTP,
  resendOTP,
  getCustomerProfile,
  getdeliverySlots,
  addCustomerAddress,
  getCustomerAddressList,
  createBooking,
};
