import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Badge, Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import en from "../../../../../locale/en.json";
import { RootState } from "../../../../../redux/reducers";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import InputDatePicker from "../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import FullScreenLoader from "../../../../../sharedComponents/molecules/FullScreenLoader";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import {
  GenericObject,
  LOB_TYPES,
  LobStatus,
  VehicleStatus,
  bookingStatusArray,
  filterStorageKeys,
  sorting,
  typographyConstants,
  useCaseArray,
} from "../../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  FunctionalitiesIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  manualBookingActions,
  rentalActions,
  vehicleActions,
} from "../../../../redux/actions";
import { routesConstants } from "../../../../utils/RoutesConstants";
import {
  CalDaysWithTimeStamp,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  istToTimestamp,
} from "../../../../utils/helper";
import { StyleObject } from "./StyleObject";
import CollapsibleSection from "./sections/CollapsibleSection";
import ModalsAvailable from "./sections/ModalsAvailable";
import RbackHelper from "../../../../../utils/helperRBAC";

import {
  modules,
  inventoryFunctionality,
  vehicleManagementFunctionalities,
  bookingFunctionalities,
} from "../../../../../constants/RBACModuleEnums";

const { isAccessRightsProvided } = RbackHelper;

// Destructuring constants
const { tableHeaders, global, VehicleManagement } = rentalEn;

// MUi data grid table columns
const bookingStatusColumns: any[] = [
  {
    field: "bookingNumber",
    headerName: tableHeaders?.bookingNumber,
    flex: 1.5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "bookingDate",
    headerName: tableHeaders?.bookingDate,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => (
      <span>
        {params?.row?.bookingDate
          ? moment(params?.row?.bookingDate).format(global?.DDMMYYYY)
          : global?.NA}
      </span>
    ),
  },
  {
    field: "modelDisplayName",
    headerName: tableHeaders?.vehicleModel,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "vehicleRegistrationNumber",
    headerName: tableHeaders?.vehicleNumber,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => (
      <span>{params?.row?.vehicleRegistrationNumber ?? global?.NA}</span>
    ),
  },
  {
    field: "cityDisplayName",
    headerName: tableHeaders?.city,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "branchDisplayName",
    headerName: tableHeaders?.hub,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "startDate",
    headerName: tableHeaders?.startDate,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => (
      <span>
        {params?.row?.startDate
          ? moment(params?.row?.startDate).format(global?.DDMMYYYY)
          : global?.NA}
      </span>
    ),
  },
  {
    field: "endDate",
    headerName: tableHeaders?.endDate,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => (
      <span>
        {params?.row?.endDate
          ? moment(params?.row?.endDate).format(global?.DDMMYYYY)
          : global?.NA}
      </span>
    ),
  },
  {
    field: "bufferEndDate",
    headerName: tableHeaders?.bufferEndDate,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => (
      <span>
        {params?.row?.bufferEndDate
          ? moment(params?.row?.bufferEndDate).format(global?.DDMMYYYY)
          : global?.NA}
      </span>
    ),
  },
];

// MUi data grid table columns
const blockedVehiclesColumns: any[] = [
  {
    field: "tempModelDisplayName",
    headerName: tableHeaders?.vehicleModel,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempVehicleNumber",
    headerName: tableHeaders?.vehicleNumber,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempCategory",
    headerName: tableHeaders?.category,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempCityDisplayName",
    headerName: tableHeaders?.city,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempHubDisplayName",
    headerName: tableHeaders?.hub,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempBlockedReason",
    headerName: tableHeaders?.blockReason,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

// MUi data grid table columns
const currentBlockedVehiclesColumns: GenericObject[] = [
  {
    field: "modelDisplayName",
    headerName: tableHeaders?.modelName,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempRegistration",
    headerName: tableHeaders?.vehicleNumber,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "utilization",
    headerName: tableHeaders?.utilizationPercent,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempUseCase",
    headerName: tableHeaders?.functionality,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cityDisplayName",
    headerName: tableHeaders?.city,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "branchDisplayName",
    headerName: tableHeaders?.freedoHub,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempOperatorId",
    headerName: tableHeaders?.addedBy,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "tempBlockedReason",
    headerName: tableHeaders?.blockReason,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

const InventoryDetails = (props: any) => {
  // RBAC object for functionality
  const functionalitiesAccess = {
    isVehicleAccess: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.LIST_VEHICLES
    ),
    isCreateBooking: isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.CREATE_BOOKING
    ),
  };

  const {} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Access the data passed in the route using the 'useNavigate' hook
  const state = location.state as any;

  // Redux selectors
  const {
    allCitiesDetails,
    branches,
    modelListByBranch,
    masterPlanDurationsData,
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  // Redux selectors
  const { availableModels } = useSelector(
    (state: RootState) => state.newManualBookingReducer
  );

  // Redux selectors
  const {
    onLoad,
    ongoingBookingsApiResponseData, // {}
    ongoingBookingsData, // []
    upcomingBookingsApiResponseData, // {}
    upcomingBookingsData, // []
    blockedVehiclesApiResponseData, // {}
    blockedVehiclesData, // []
    calendarData, // []
    vehiclesListData,
    vehiclesListApiResponseData,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  // Redux selectors
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  // initial state
  const initialState: GenericObject = {
    selectedCity: {
      name: "",
      displayName: global?.allCities,
    },
    selectedBranch: {
      name: "",
      displayName: global?.AllHubs,
    },
    selectedVehicle: {
      name: "",
      displayName: global?.AllModels,
    },
    selectedLob: {
      name: "",
      displayName: global?.allUseCases,
    },
    calendarDate: "", // custom calendar date
    ongoingBookingsPaginationModel: { page: 0, pageSize: 10 },
    upcomingBookingsPaginationModel: { page: 0, pageSize: 10 },
    blockedVehiclesPaginationModel: { page: 0, pageSize: 10 },
    currentBlockedVehiclesPaginationModel: { page: 0, pageSize: 10 },
    selectedPackage: 1,
  };
  // initial search state
  const initialSearchState: GenericObject = {
    ongoingBookingsSearchText: "",
    upcomingBookingsSearchText: "",
    blockedVehiclesSearchText: "",
  };

  const [fields, setFields] = useState<GenericObject>({
    ...initialState,
  });
  const [expand, setExpand] = useState<GenericObject>({
    expandOngoingBookings: false,
    expandUpcomingBookings: false,
    expandBlockedVehicles: false,
    expandCurrentBlockedVehicles: false,
  }); // state to handle the expansion of tables
  const [searchedText, setSearchedText] = useState<GenericObject>({
    ...initialSearchState,
  }); // state to store the searched text
  const [nbc, setNbc] = useState<number | any>(state?.eventData?.available); // nbc (new booking capacity)

  const checkDate = (dateString: any) => {
    // Parse the incoming date string with moment
    const inputDate = moment(new Date(dateString));

    // Get the current date (UTC) and start of the day
    const now = moment().utc();
    const startOfToday = now.startOf("day");

    // Check if the input date is today or in the future
    return inputDate.isSameOrAfter(startOfToday);
  };

  useEffect(() => {
    // scroll to top
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    const fetchData = async () => {
      try {
        // Fetch filter data from local storage
        const data = await getFilterDataFromLocalStorage(
          filterStorageKeys?.INVENTORY_FILTER_DATA
        );

        // Update component state based on filter data
        setFields((prev: any) => ({
          ...prev,
          ...data,
          calendarDate: data?.calendarDate ?? state?.clickedDate,
        }));

        const { selectedCity, selectedBranch, selectedVehicle, selectedLob } =
          data;

        // Call API to fetch branches based city data present in local storage
        if (selectedCity?.name) {
          dispatch(rentalActions.getAllBranches({ name: selectedCity?.name }));
        }

        // Fetch vehicle models
        if (selectedCity?.name || selectedBranch?.name) {
          const payload: GenericObject = {
            branches: selectedBranch?.name ? [selectedBranch?.name] : null,
            city: selectedCity?.name ? selectedCity?.name : null,
          };
          dispatch(rentalActions.getModelListByBranch(payload));
        } else {
          dispatch(rentalActions.getModelListByBranch());
        }

        // Prepare common payload for ongoing and upcoming bookings
        const commonPayload: GenericObject = {
          page: 1,
          pageSize: 10,
          cities: selectedCity?.name ? [selectedCity.name] : null,
          branches: selectedBranch?.name ? [selectedBranch.name] : null,
          model: selectedVehicle?.name ? [selectedVehicle.name] : null,
          useCase: selectedLob?.name,
          sortings: [sorting?.bookingDate_desc],
          calendarDateFrom: getTimestampAtStartOfDay(
            data?.calendarDate ?? state?.clickedDate
          ),
          calendarDateTill: getTimestampAtEndOfDay(
            data?.calendarDate ?? state?.clickedDate
          ),
        };

        // API calls for ongoing bookings
        dispatch(
          vehicleActions.getOngoingBookingsDetails({
            ...commonPayload,
            status: bookingStatusArray?.ONGOING,
          })
        );

        // API calls for upcoming bookings
        dispatch(
          vehicleActions.getUpcomingBookingsDetails({
            ...commonPayload,
            status: bookingStatusArray?.UPCOMING,
          })
        );

        // API call for blocked vehicles
        const blockedPayload: GenericObject = {
          page: 1,
          pageSize: 10,
          branches: selectedBranch?.name ? [selectedBranch.name] : null,
          modelNames: selectedVehicle?.name ? [selectedVehicle.name] : null,
          useCase: selectedLob?.name,
          sort: [sorting?.createdAt_desc],
          startDate: getTimestampAtStartOfDay(
            data?.calendarDate ?? state?.clickedDate
          ),
          endDate: getTimestampAtEndOfDay(
            data?.calendarDate ?? state?.clickedDate
          ),
        };
        dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));

        // API call for current-blocked vehicles
        const currentBlockedVehiclePayload: GenericObject = {
          page: 1,
          pageSize: 10,
          sort: [sorting?.createdAt_desc],
          cities: selectedCity?.name ? [selectedCity.name] : null,
          branches: selectedBranch?.name ? [selectedBranch.name] : null,
          modelNames: selectedVehicle?.name ? [selectedVehicle.name] : null,
          useCase: selectedLob?.name ? [selectedLob?.name] : null,
          lobStatus: [LobStatus?.BLOCKED],
          status: VehicleStatus?.ACTIVE,
          lob: LOB_TYPES?.LTR,
        };
        if (checkDate(data?.calendarDate ?? state?.clickedDate)) {
          dispatch(
            vehicleActions.getVehiclesList(currentBlockedVehiclePayload)
          );
        }

        if (data?.selectedBranch?.name) {
          handleGetAvailableModels({
            startDate: data?.calendarDate ?? state?.clickedDate,
            endDate: data?.calendarDate ?? state?.clickedDate,
            branchName: data?.selectedBranch?.name,
            useCase: data?.selectedLob?.name,
          });
        }

        // Fetch master plan durations
        dispatch(rentalActions.getMasterPlanDurations());
      } catch (error) {
        // Handle any errors here (e.g., logging, error state management)
        console.error(error);
      }
    };

    fetchData(); // Initial api calls on component mount
  }, [JSON.stringify(userData?.authorizedBranches)]);

  // Store data in local storage
  useEffect(() => {
    let data: any = {
      inventoryFilterData: {
        ...fields,
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);
    // *** api call to update 'nbc'
    let payload = {
      data: {
        startDate: getTimestampAtStartOfDay(fields?.calendarDate),
        endDate: getTimestampAtEndOfDay(fields?.calendarDate),
        cities: fields?.selectedCity.name,
        useCase: fields?.selectedLob?.name,
        branches: fields?.selectedBranch?.name,
        model: fields?.selectedVehicle.name,
      },
    };
    if (fields?.calendarDate) {
      dispatch(vehicleActions.getCalendarData(payload));
    }
  }, [
    fields?.selectedCity,
    fields?.selectedBranch,
    fields?.selectedVehicle,
    fields?.selectedLob,
    fields?.ongoingBookingsPaginationModel,
    fields?.upcomingBookingsPaginationModel,
    fields?.blockedVehiclesPaginationModel,
    fields?.currentBlockedVehiclesPaginationModel,
    fields?.calendarDate,
  ]);

  // api call to full calendar cell data api on basic of filters data
  useEffect(() => {
    if (calendarData[0]) {
      setNbc(() => calendarData[0]?.available);
    }
  }, [calendarData[0]]);

  // Function to update the states on the user's selection
  const handleChange = (key: string, value: any) => {
    // Initialize objects for holding temporary data and reset values
    let data: GenericObject = {};
    let paginationReset: GenericObject = {
      ongoingBookingsPaginationModel: {
        ...initialState?.ongoingBookingsPaginationModel,
      },
      upcomingBookingsPaginationModel: {
        ...initialState?.upcomingBookingsPaginationModel,
      },
      blockedVehiclesPaginationModel: {
        ...initialState?.blockedVehiclesPaginationModel,
      },
      currentBlockedVehiclesPaginationModel: {
        ...initialState?.currentBlockedVehiclesPaginationModel,
      },
    };
    let searchTextReset: GenericObject = { ...initialSearchState };

    // Initialize object for holding common data for the ongoing and upcoming bookings API call
    let ongoingUpcomingCommonPayload: GenericObject = {
      page: 1,
      pageSize: 10, // Default value that will be updated based on the api call
      cities: fields?.selectedCity?.name ? [fields?.selectedCity?.name] : null,
      branches: fields?.selectedBranch?.name
        ? [fields?.selectedBranch?.name]
        : null,
      model: fields?.selectedVehicle?.name
        ? [fields?.selectedVehicle?.name]
        : null,
      useCase: fields?.selectedLob?.name,
      status: "", // Default value that will be updated based on the api call
      sortings: [sorting?.bookingDate_desc],
      calendarDateFrom: getTimestampAtStartOfDay(fields?.calendarDate),
      calendarDateTill: getTimestampAtEndOfDay(fields?.calendarDate),
    };

    // Initialize object for holding common data for the blocked vehicles API call
    let blockedVehiclePayload: GenericObject = {
      page: 1,
      pageSize: fields?.blockedVehiclesPaginationModel?.pageSize,
      branches: fields?.selectedBranch?.name
        ? [fields?.selectedBranch?.name]
        : null,
      modelNames: fields?.selectedVehicle?.name
        ? [fields?.selectedVehicle?.name]
        : null,
      useCase: fields?.selectedLob?.name,
      sort: [sorting?.createdAt_desc],
      startDate: getTimestampAtStartOfDay(fields?.calendarDate),
      endDate: getTimestampAtEndOfDay(fields?.calendarDate),
    };

    // Initialize object for holding common data for the current-blocked vehicles API call
    let currentBlockedVehiclesPayload: GenericObject = {
      page: 1,
      pageSize: fields?.currentBlockedVehiclesPaginationModel?.pageSize,
      sort: [sorting?.createdAt_desc],
      cities: fields?.selectedCity?.name ? [fields?.selectedCity.name] : null,
      branches: fields?.selectedBranch?.name
        ? [fields?.selectedBranch.name]
        : null,
      modelNames: fields?.selectedVehicle?.name
        ? [fields?.selectedVehicle.name]
        : null,
      useCase: fields?.selectedLob?.name ? [fields?.selectedLob?.name] : null,
      lobStatus: [LobStatus?.BLOCKED],
      status: VehicleStatus?.ACTIVE,
      lob: LOB_TYPES?.LTR,
    };

    // Handle changes based on the key provided
    if (key === "selectedCity") {
      // If the selected key is 'selectedCity', update related state and perform API calls
      data = {
        selectedBranch: { name: "", displayName: global?.allHubs },
        selectedVehicle: { name: "", displayName: global?.allModels },
      };

      // Call API to fetch branches based on selected city
      if (value?.name) {
        dispatch(rentalActions.getAllBranches({ name: value.name }));
      } else {
        // If no city is selected, clear branch-related states
        dispatch(rentalActions.clearBranchesAndModels());
      }
      //
      // Ongoing bookings details api call // status: "Ongoing"
      const ongoingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
        cities: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.ONGOING,
      };
      delete ongoingPayload.branches;
      delete ongoingPayload.model;
      dispatch(vehicleActions.getOngoingBookingsDetails(ongoingPayload));
      //
      // Upcoming bookings details api call // status: "Upcoming"
      const upcomingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
        cities: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.UPCOMING,
      };
      delete upcomingPayload.branches;
      delete upcomingPayload.model;
      dispatch(vehicleActions.getUpcomingBookingsDetails(upcomingPayload));
      //
      // Blocked vehicles details api call
      const blockedPayload: GenericObject = {
        ...blockedVehiclePayload,
      };
      delete blockedPayload.branches;
      delete blockedPayload.modelNames;
      dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));
      //
      // Current blocked vehicles details api call
      const currentBlockedPayload: GenericObject = {
        ...currentBlockedVehiclesPayload,
        cities: value?.name ? [value?.name] : null,
      };
      if (checkDate(fields?.calendarDate)) {
        dispatch(vehicleActions.getVehiclesList(currentBlockedPayload));
      }
    } else if (key === "selectedBranch") {
      // If the selected key is 'selectedBranch', update related state
      data = {
        selectedVehicle: { name: "", displayName: global?.allModels },
      };
      //
      // Ongoing bookings details api call // status: "Ongoing"
      const ongoingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
        branches: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.ONGOING,
      };
      delete ongoingPayload.model;
      dispatch(vehicleActions.getOngoingBookingsDetails(ongoingPayload));
      //
      // Upcoming bookings details api call // status: "Upcoming"
      const upcomingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
        branches: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.UPCOMING,
      };
      delete upcomingPayload.model;
      dispatch(vehicleActions.getUpcomingBookingsDetails(upcomingPayload));
      //
      // Blocked vehicles details api call
      const blockedPayload: GenericObject = {
        ...blockedVehiclePayload,
        branches: value?.name ? [value?.name] : null,
      };
      delete blockedPayload.modelNames;
      dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));
      //
      // Current blocked vehicles details api call
      const currentBlockedPayload: GenericObject = {
        ...currentBlockedVehiclesPayload,
        branches: value?.name ? [value?.name] : null,
      };
      if (checkDate(fields?.calendarDate)) {
        dispatch(vehicleActions.getVehiclesList(currentBlockedPayload));
      }
    } else if (key === "selectedVehicle") {
      //
      // Ongoing bookings details api call // status: "Ongoing"
      const ongoingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
        model: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.ONGOING,
      };
      dispatch(vehicleActions.getOngoingBookingsDetails(ongoingPayload));
      //
      // Upcoming bookings details api call // status: "Upcoming"
      const upcomingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
        model: value?.name ? [value?.name] : null,
        status: bookingStatusArray?.UPCOMING,
      };
      dispatch(vehicleActions.getUpcomingBookingsDetails(upcomingPayload));
      //
      // Blocked vehicles details api call
      const blockedPayload: GenericObject = {
        ...blockedVehiclePayload,
        modelNames: value?.name ? [value?.name] : null,
      };
      dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));
      //
      // Current blocked vehicles details api call
      const currentBlockedPayload: GenericObject = {
        ...currentBlockedVehiclesPayload,
        modelNames: value?.name ? [value?.name] : null,
      };
      if (checkDate(fields?.calendarDate)) {
        dispatch(vehicleActions.getVehiclesList(currentBlockedPayload));
      }
    } else if (key === "selectedLob") {
      //
      // Ongoing bookings details api call // status: "Ongoing"
      const ongoingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
        useCase: value?.name,
        status: bookingStatusArray?.ONGOING,
      };
      dispatch(vehicleActions.getOngoingBookingsDetails(ongoingPayload));
      //
      // Upcoming bookings details api call // status: "Upcoming"
      const upcomingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
        useCase: value?.name,
        status: bookingStatusArray?.UPCOMING,
      };
      dispatch(vehicleActions.getUpcomingBookingsDetails(upcomingPayload));
      //
      // Blocked vehicles details api call
      const blockedPayload: GenericObject = {
        ...blockedVehiclePayload,
        useCase: value?.name,
      };
      dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));
    } else if (key === "calendarDate") {
      //
      // Ongoing bookings details api call // status: "Ongoing"
      const ongoingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
        status: bookingStatusArray?.ONGOING,
        calendarDateFrom: getTimestampAtStartOfDay(value),
        calendarDateTill: getTimestampAtEndOfDay(value),
      };
      dispatch(vehicleActions.getOngoingBookingsDetails(ongoingPayload));
      //
      // Upcoming bookings details api call // status: "Upcoming"
      const upcomingPayload: GenericObject = {
        ...ongoingUpcomingCommonPayload,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
        status: bookingStatusArray?.UPCOMING,
        calendarDateFrom: getTimestampAtStartOfDay(value),
        calendarDateTill: getTimestampAtEndOfDay(value),
      };
      dispatch(vehicleActions.getUpcomingBookingsDetails(upcomingPayload));
      //
      // Blocked vehicles details api call
      const blockedPayload: GenericObject = {
        ...blockedVehiclePayload,
        startDate: getTimestampAtStartOfDay(value),
        endDate: getTimestampAtEndOfDay(value),
      };
      dispatch(vehicleActions.getBlockedVehiclesDetails(blockedPayload));
    }

    // Update state with the new value, temporary data, and reset pagination values
    setFields((prev: any) => ({
      ...prev,
      [key]: value,
      ...data,
      ...paginationReset,
    }));

    // Reset search text fields
    setSearchedText((prev: any) => ({ ...searchTextReset }));

    // Make API call to fetch vehicle models if necessary
    if (key === "selectedCity" || key === "selectedBranch") {
      // Construct payload for API call based on selected city or branch
      let payload: GenericObject = {
        branches: key === "selectedBranch" && value?.name ? [value.name] : null,
        city:
          key === "selectedBranch"
            ? fields.selectedCity?.name
            : key === "selectedCity"
            ? value?.name
            : null,
      };
      // Check if selected value is valid for fetching models
      if (
        value.name &&
        value.name !== global?.allCities &&
        value.name !== global?.AllHubs
      ) {
        dispatch(rentalActions.getModelListByBranch(payload));
      }
    }
  };

  // Debounced search function using useCallback to memoize the function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string | any, key: string) => {
      // Define payload object for API call based on key
      const payload: GenericObject = {
        page: 1,
        pageSize:
          key === "upcomingBookingsSearchText"
            ? fields?.upcomingBookingsPaginationModel?.pageSize
            : key === "ongoingBookingsSearchText"
            ? fields?.ongoingBookingsPaginationModel?.pageSize
            : key === "blockedVehiclesSearchText"
            ? fields?.blockedVehiclesPaginationModel?.pageSize
            : 10,
        cities: fields?.selectedCity?.name
          ? [fields?.selectedCity?.name]
          : null,
        branches: fields?.selectedBranch?.name
          ? [fields?.selectedBranch?.name]
          : null,
        model: fields?.selectedVehicle?.name
          ? [fields?.selectedVehicle?.name]
          : null,
        useCase: fields?.selectedLob?.name,
        search: searchQuery,
        status:
          key === "upcomingBookingsSearchText"
            ? bookingStatusArray?.UPCOMING
            : bookingStatusArray?.ONGOING,
        sortings: [sorting?.bookingDate_desc],
        calendarDateFrom: getTimestampAtStartOfDay(fields?.calendarDate),
        calendarDateTill: getTimestampAtEndOfDay(fields?.calendarDate),
      };
      // Dispatch appropriate action based on key
      if (key === "upcomingBookingsSearchText") {
        dispatch(vehicleActions.getUpcomingBookingsDetails(payload));
      } else if (key === "ongoingBookingsSearchText") {
        dispatch(vehicleActions.getOngoingBookingsDetails(payload));
      } else if (key === "blockedVehiclesSearchText") {
        // For blocked vehicles, modify payload and dispatch action
        delete payload.cities;
        delete payload.status;
        delete payload.model;
        delete payload.sortings;
        delete payload.calendarDateFrom;
        delete payload.calendarDateTill;

        payload.modelNames = fields?.selectedVehicle?.name
          ? [fields?.selectedVehicle?.name]
          : null;
        payload.sort = [sorting?.createdAt_desc];
        payload.startDate = getTimestampAtStartOfDay(fields?.calendarDate);
        payload.endDate = getTimestampAtEndOfDay(fields?.calendarDate);

        dispatch(vehicleActions.getBlockedVehiclesDetails(payload));
      }
    }, 1000), // Debounce time set to 1000ms (1 second)
    [fields?.calendarDate] // Dependency array for useCallback
  );

  // Handle search input change
  const handleSearch = (e: any, key: string) => {
    // Update state with searched text
    setSearchedText((prev: any) => ({
      ...prev,
      [key]: e.target.value,
    }));
    // Execute debounced search if length of search query is 3 or more
    if (e.target.value?.length >= 3 || !e.target.value) {
      debouncedSearch(e.target.value, key);
    }
  };

  // Handle tables expansions here
  const handleExpansion = (key: string, value: boolean) => {
    setExpand((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getRowHeight = () => {
    // Return the desired height of the mui datagrid row
    return 50; // Adjust this value as needed
  };

  // Handle paginationModel changes here
  const handlePaginationModelChange = (key: string, paginationValue: any) => {
    // Extract page and pageSize from paginationValue
    const { page, pageSize } = paginationValue;

    // Update the state with the new pagination value
    setFields((prev: any) => ({
      ...prev,
      [key]: paginationValue,
    }));

    // Prepare payload based on the key for different API calls
    let payload: GenericObject = {
      page: page + 1, // MUI DataGrid page starts from zero, hence adding +1
      pageSize,
      cities: fields?.selectedCity?.name ? [fields?.selectedCity?.name] : null,
      branches: fields?.selectedBranch?.name
        ? [fields.selectedBranch.name]
        : null,
      model: fields?.selectedVehicle?.name
        ? [fields.selectedVehicle.name]
        : null,
      useCase: fields?.selectedLob?.name,
      search: "", // Default search value, will be overwritten based on key
      sortings: [sorting?.bookingDate_desc],
      calendarDateFrom: getTimestampAtStartOfDay(fields?.calendarDate),
      calendarDateTill: getTimestampAtEndOfDay(fields?.calendarDate),
    };

    // Determine which API call to make based on the key
    switch (key) {
      case "ongoingBookingsPaginationModel":
        // Ongoing bookings details API call
        payload = {
          ...payload,
          search: searchedText?.ongoingBookingsSearchText ?? "",
          status: bookingStatusArray?.ONGOING,
        };
        dispatch(vehicleActions.getOngoingBookingsDetails(payload));
        break;
      case "upcomingBookingsSearchText":
        // Upcoming bookings details API call
        payload = {
          ...payload,
          search: searchedText?.upcomingBookingsSearchText ?? "",
          status: bookingStatusArray?.UPCOMING,
        };
        dispatch(vehicleActions.getUpcomingBookingsDetails(payload));
        break;
      case "blockedVehiclesPaginationModel":
        // Blocked vehicles details API call
        payload = {
          ...payload,
          modelNames: fields?.selectedVehicle?.name
            ? [fields.selectedVehicle.name]
            : null,
          search: searchedText?.blockedVehiclesSearchText ?? "",
          sort: [sorting?.createdAt_desc],
          startDate: getTimestampAtStartOfDay(fields?.calendarDate),
          endDate: getTimestampAtEndOfDay(fields?.calendarDate),
        };
        delete payload.sortings;
        delete payload.calendarDateFrom;
        delete payload.calendarDateTill;
        delete payload.model;
        delete payload.cities;
        dispatch(vehicleActions.getBlockedVehiclesDetails(payload));
        break;
      case "currentBlockedVehiclesPaginationModel":
        // Current blocked vehicles details api call
        payload = {
          ...payload,
          modelNames: fields?.selectedVehicle?.name
            ? [fields.selectedVehicle.name]
            : null,
          sort: [sorting?.createdAt_desc],
          useCase: fields?.selectedLob?.name
            ? [fields?.selectedLob?.name]
            : null,
          lobStatus: [LobStatus?.BLOCKED],
          status: VehicleStatus?.ACTIVE,
          lob: LOB_TYPES?.LTR,
        };
        delete payload.sortings;
        delete payload.calendarDateFrom;
        delete payload.calendarDateTill;
        delete payload.model;
        dispatch(vehicleActions.getVehiclesList(payload));
        break;

      default:
        break;
    }
  };

  // Reset handler
  const handleFilterReset = () => {
    // payload for ongoing and upcoming bookings API call
    const ongoingUpcomingPayload: GenericObject = {
      page: 1,
      sortings: [sorting?.bookingDate_desc],
      calendarDateFrom: getTimestampAtStartOfDay(fields?.calendarDate),
      calendarDateTill: getTimestampAtEndOfDay(fields?.calendarDate),
    };
    dispatch(
      vehicleActions.getOngoingBookingsDetails({
        ...ongoingUpcomingPayload,
        status: bookingStatusArray?.ONGOING,
        pageSize: fields?.ongoingBookingsPaginationModel?.pageSize,
      })
    );
    dispatch(
      vehicleActions.getUpcomingBookingsDetails({
        ...ongoingUpcomingPayload,
        status: bookingStatusArray?.UPCOMING,
        pageSize: fields?.upcomingBookingsPaginationModel?.pageSize,
      })
    );
    // payload for blocked vehicles API call
    const blockedVehiclePayload: GenericObject = {
      page: 1,
      pageSize: fields?.blockedVehiclesPaginationModel?.pageSize,
      sort: [sorting?.createdAt_desc],
      startDate: getTimestampAtStartOfDay(fields?.calendarDate),
      endDate: getTimestampAtEndOfDay(fields?.calendarDate),
    };
    dispatch(vehicleActions.getBlockedVehiclesDetails(blockedVehiclePayload));
    // payload for current-blocked vehicles API call
    const currentBlockedVehiclePayload: GenericObject = {
      page: 1,
      pageSize: 10,
      sort: [sorting?.createdAt_desc],
      lobStatus: [LobStatus?.BLOCKED],
      status: VehicleStatus?.ACTIVE,
      lob: LOB_TYPES?.LTR,
    };
    if (checkDate(fields?.calendarDate)) {
      dispatch(vehicleActions.getVehiclesList(currentBlockedVehiclePayload));
    }
    //
    dispatch(rentalActions.clearBranchesAndModels()); // Clear branches and modelListByBranch state from reducer
    const tempDate: any = fields?.calendarDate
      ? fields?.calendarDate
      : state?.clickedDate;
    setFields(() => ({ ...initialState, calendarDate: tempDate }));
    setSearchedText(() => ({ ...initialSearchState }));
    dispatch(rentalActions.getModelListByBranch());
  };

  //hanle Book vehicle to redirect to manual booking
  const handleBookingVehicle = (data: any = {}) => {
    let obj: any = {};

    if (fields?.selectedLob?.name) {
      obj["selectedLob"] = fields?.selectedLob;
    } else {
      obj["selectedLob"] = useCaseArray[0];
    }
    if (fields?.selectedCity?.name) {
      obj["selectedCity"] = fields?.selectedCity;
    }
    if (fields?.selectedBranch?.name) {
      obj["selectedBranch"] = fields?.selectedBranch;
    }
    if (fields?.selectedVehicle?.name) {
      obj["selectedVehicle"] = fields?.selectedVehicle;
    }
    if (fields?.selectedPackage) {
      obj["selectedPackage"] = fields?.selectedPackage;
    }
    if (data?.modelName) {
      obj.selectedVehicle = data;
    }

    navigate(routesConstants.MANUAL_BOOKING, {
      state: { InventoryDetails: true, InventoryFeilds: obj },
    });
  };

  // get other available models by filter available models
  const getFilteredModal = () => {
    let availableFilteredModal: any[] = [];
    if (availableModels?.length > 0) {
      availableFilteredModal = availableModels?.filter((model: any) => {
        if (model?.modelName !== fields?.selectedVehicle?.name) return model;
      });
    }
    return availableFilteredModal;
  };

  // get available models
  const handleGetAvailableModels = (data: any) => {
    const payloadModels = {
      payload: {
        startDate: getTimestampAtStartOfDay(data.startDate),
        endDate: getTimestampAtEndOfDay(
          istToTimestamp(
            CalDaysWithTimeStamp(data?.endDate, fields?.selectedPackage)
          )
        ),
        branches: [data?.branchName],
        useCase: data?.useCase,
      },
      showError: false,
      isCallSlotPauseAPI: false,
    };

    dispatch(manualBookingActions.getAvailableModels(payloadModels));
  };

  //get row id
  const getRowIdOngoingSection = (row: any) => {
    return row?.id;
  };

  //get row id
  const getRowIdUpcomingSection = (row: any) => {
    return row?.id;
  };

  //on row click navigate to booking details
  const handleRowClickUpcomingSection = (params: any, event: any) => {
    navigate(`${routesConstants.BOOKING_DETAILS}/${params.id}`);
  };

  //on row click navigate to booking details
  const handleRowClickOngoingSection = (params: any, event: any) => {
    navigate(`${routesConstants.BOOKING_DETAILS}/${params.id}`);
  };

  //navigate to vehicle management
  const navigateToVehicleManagement = () => {
    navigate(`${routesConstants.ALL_VEHICLES}`);
  };

  // on page render call this when we again navigate back from manual booking
  // useEffect(() => {
  //   if (fields?.selectedBranch?.name) {
  //     handleGetAvailableModels({
  //       startDate: fields?.calendarDate,
  //       endDate: fields?.calendarDate,
  //       branchName: fields?.selectedBranch?.name,
  //       useCase: fields?.selectedLob?.name,
  //     });
  //   }
  // }, [fields?.selectedBranch?.name]);

  return (
    <Stack
      width={1}
      direction="column"
      spacing={2}
      sx={StyleObject?.inventoryDetails_topWrapper}
    >
      {/* Top Section */}
      <Box sx={StyleObject?.inventoryDetails_topSection_topBox}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant={typographyConstants.SUBHEADING}>
            {en?.vehicleManagement?.SelectedDate}
          </Typography>
          <InputDatePicker
            value={fields?.calendarDate}
            required
            width={150}
            padding={"10px 10px"}
            onChange={(date: any) => {
              handleChange("calendarDate", date);
              {
                fields?.selectedBranch?.displayName !== "All Hubs" &&
                  handleGetAvailableModels({
                    startDate: date,
                    endDate: date,
                    branchName: fields?.selectedBranch?.name,
                    useCase: fields?.selectedLob?.name,
                  });
              }
            }}
            shouldDisableDate={(day: any) => {
              //SHOULD DISABLE DATE BEFORE 1 MONTH
              let disabledDate: any = new Date();
              disabledDate.setDate(disabledDate.getDate() - 30);

              //SHOULD DISABLE DATE AFTER 6 MONTHS
              let maxDate: any = new Date();
              maxDate.setDate(maxDate.getDate() + 180);

              return (
                getTimestampAtStartOfDay(day) < disabledDate.valueOf() ||
                getTimestampAtStartOfDay(day) > maxDate.valueOf()
              );
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          {nbc > 0 ? (
            <Stack direction="row" spacing={2} alignItems="center">
              <Badge overlap="circular" color="secondary" variant="dot" />
              <Typography
                sx={StyleObject?.nbcTextStyle}
                variant={typographyConstants.SUBHEADING}
              >
                {en?.vehicleManagement?.NewBookingCapacity}: {nbc}
              </Typography>
              {functionalitiesAccess.isCreateBooking ? (
                <CustomButton
                  label={en?.manualBooking?.CreateBooking}
                  variant="outlined"
                  sx={StyleObject?.customButtonStyle}
                  onClick={handleBookingVehicle}
                />
              ) : null}
            </Stack>
          ) : (
            <CustomButton
              label={en?.global?.SoldOut}
              variant="outlined"
              sx={StyleObject?.customButtonStyle}
              disabled={true}
            />
          )}
        </Stack>
      </Box>

      {/* Filter Section */}
      <Grid container alignItems={"center"} spacing={"5px"}>
        {/* City Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            select
            icon={CityInputIcon}
            placeholder={global?.selectCity}
            value={
              isArrayNotEmpty(allCitiesDetails)
                ? fields?.selectedCity?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(allCitiesDetails)
                ? [
                    { name: "", displayName: global?.allCities },
                    ...allCitiesDetails,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(city: any) => {
              handleChange("selectedCity", city);
            }}
            disabled={!isArrayNotEmpty(allCitiesDetails)}
          />
        </Grid>

        {/* Hub Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={WarehouseIcon}
            placeholder={
              isArrayNotEmpty(branches) ? global?.selectHub : global?.allHubs
            }
            value={
              isArrayNotEmpty(branches)
                ? fields?.selectedBranch?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(branches)
                ? [{ name: "", displayName: global?.allHubs }, ...branches]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(branch: any) => {
              handleChange("selectedBranch", branch);
              {
                branch?.displayName !== global?.AllHubs &&
                  handleGetAvailableModels({
                    startDate: fields?.calendarDate,
                    endDate: getTimestampAtStartOfDay(fields?.calendarDate),
                    branchName: branch?.name,
                    useCase: fields?.selectedLob?.name,
                  });
              }
            }}
            disabled={
              !fields?.selectedCity?.displayName || !isArrayNotEmpty(branches)
            }
          />
        </Grid>

        {/* Model Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={BikeIconFaded}
            placeholder={
              isArrayNotEmpty(modelListByBranch)
                ? global?.selectModel
                : global?.allModels
            }
            value={
              isArrayNotEmpty(modelListByBranch)
                ? fields?.selectedVehicle?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(modelListByBranch)
                ? [
                    { name: "", displayName: global?.allModels },
                    ...modelListByBranch,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(model: any) => {
              handleChange("selectedVehicle", model);
            }}
            disabled={
              !fields?.selectedBranch?.displayName ||
              !isArrayNotEmpty(modelListByBranch)
            }
          />
        </Grid>

        {/* Use Case Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={FunctionalitiesIcon}
            placeholder={global?.selectUseCase}
            value={
              isArrayNotEmpty(useCaseArray)
                ? fields?.selectedLob?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(useCaseArray)
                ? [
                    { name: "", displayName: global?.allUseCases },
                    ...useCaseArray,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(type: any) => {
              handleChange("selectedLob", type);
            }}
          />
        </Grid>

        {/* Filter Reset Icon */}
        <Grid
          item
          sm={12}
          md={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Tooltip title={en?.global?.ResetFilters} arrow>
            <RotateRightSharpIcon
              fontSize="large"
              color="primary"
              sx={StyleObject?.cursorPointerStyle}
              onClick={handleFilterReset}
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* Ongoing Bookings Section */}
      <CollapsibleSection
        apiResponseData={ongoingBookingsApiResponseData}
        rowCount={ongoingBookingsApiResponseData?.pageData?.total}
        totalCount={ongoingBookingsApiResponseData?.pageData?.total}
        expand={expand?.expandOngoingBookings}
        getRowId={getRowIdOngoingSection}
        onRowClick={handleRowClickOngoingSection}
        searchedText={searchedText?.ongoingBookingsSearchText}
        tableRowData={ongoingBookingsData}
        tableColumnData={bookingStatusColumns}
        paginationModel={fields?.ongoingBookingsPaginationModel}
        getRowHeight={getRowHeight}
        handleSearch={(e: any) => handleSearch(e, "ongoingBookingsSearchText")}
        handleExpansion={() =>
          handleExpansion(
            "expandOngoingBookings",
            !expand?.expandOngoingBookings
          )
        }
        handlePaginationModelChange={(val: any) =>
          handlePaginationModelChange("ongoingBookingsPaginationModel", val)
        }
        collapsibleSectionHeading={VehicleManagement?.ongoingBookings}
        noRowsOverlayMessage={VehicleManagement?.noOngoingBookingsDataAvailable}
        searchBoxPlaceholder={
          VehicleManagement?.enterRegistrationNumberPlaceholder
        }
        headingColor={colors?.secondary_green}
      />

      {/* Upcoming Bookings Section */}
      <CollapsibleSection
        apiResponseData={upcomingBookingsApiResponseData}
        rowCount={upcomingBookingsApiResponseData?.pageData?.total}
        totalCount={upcomingBookingsApiResponseData?.pageData?.total}
        getRowId={getRowIdUpcomingSection}
        onRowClick={handleRowClickUpcomingSection}
        expand={expand?.expandUpcomingBookings}
        searchedText={searchedText?.upcomingBookingsSearchText}
        tableRowData={upcomingBookingsData}
        tableColumnData={bookingStatusColumns}
        paginationModel={fields?.upcomingBookingsPaginationModel}
        getRowHeight={getRowHeight}
        handleSearch={(e: any) => handleSearch(e, "upcomingBookingsSearchText")}
        handleExpansion={() =>
          handleExpansion(
            "expandUpcomingBookings",
            !expand?.expandUpcomingBookings
          )
        }
        handlePaginationModelChange={(val: any) =>
          handlePaginationModelChange("upcomingBookingsPaginationModel", val)
        }
        collapsibleSectionHeading={VehicleManagement?.upcomingBookings}
        noRowsOverlayMessage={
          VehicleManagement?.noUpcomingBookingsDataAvailable
        }
        searchBoxPlaceholder={
          VehicleManagement?.enterRegistrationNumberPlaceholder
        }
        headingColor={colors?.primary_warning}
      />

      {/* Current Blocked Vehicles Section */}
      {checkDate(fields?.calendarDate ?? state?.clickedDate) && (
        <CollapsibleSection
          apiResponseData={vehiclesListApiResponseData}
          rowCount={vehiclesListApiResponseData?.pageData?.totalCount}
          totalCount={vehiclesListApiResponseData?.pageData?.totalCount}
          expand={expand?.expandCurrentBlockedVehicles}
          tableRowData={vehiclesListData}
          tableColumnData={currentBlockedVehiclesColumns}
          paginationModel={fields?.currentBlockedVehiclesPaginationModel}
          getRowHeight={getRowHeight}
          handleExpansion={() =>
            handleExpansion(
              "expandCurrentBlockedVehicles",
              !expand?.expandCurrentBlockedVehicles
            )
          }
          handlePaginationModelChange={(val: any) =>
            handlePaginationModelChange(
              "currentBlockedVehiclesPaginationModel",
              val
            )
          }
          collapsibleSectionHeading={VehicleManagement?.currentBlockedVehicles}
          noRowsOverlayMessage={
            VehicleManagement?.noBlockedVehiclesDataAvailable
          }
          showSearchBox={false}
          headingColor={colors?.primary_danger}
        />
      )}

      {/* Blocked Vehicles Section */}
      <CollapsibleSection
        apiResponseData={blockedVehiclesApiResponseData}
        rowCount={blockedVehiclesApiResponseData?.pageData?.totalCount}
        totalCount={blockedVehiclesApiResponseData?.pageData?.totalCount}
        expand={expand?.expandBlockedVehicles}
        searchedText={searchedText?.blockedVehiclesSearchText}
        tableRowData={blockedVehiclesData}
        tableColumnData={blockedVehiclesColumns}
        paginationModel={fields?.blockedVehiclesPaginationModel}
        getRowHeight={getRowHeight}
        handleSearch={(e: any) => handleSearch(e, "blockedVehiclesSearchText")}
        handleExpansion={() =>
          handleExpansion(
            "expandBlockedVehicles",
            !expand?.expandBlockedVehicles
          )
        }
        handlePaginationModelChange={(val: any) =>
          handlePaginationModelChange("blockedVehiclesPaginationModel", val)
        }
        collapsibleSectionHeading={VehicleManagement?.blockedVehiclesHistory}
        noRowsOverlayMessage={VehicleManagement?.noBlockedVehiclesDataAvailable}
        searchBoxPlaceholder={VehicleManagement?.enterVehicleNumber}
        headingColor={colors?.primary_danger}
      />

      {/* Hyperlink Section - check access of vehicle management*/}
      {functionalitiesAccess.isVehicleAccess ? (
        <Typography
          variant={typographyConstants.HEADING}
          sx={StyleObject?.viewMoreInVehicleManagementHyperLinkStyle}
          onClick={navigateToVehicleManagement}
        >
          {en?.vehicleManagement?.ViewMoreInVehicleManagement}
        </Typography>
      ) : null}

      {nbc > 0 && fields?.selectedVehicle?.name && (
        <ModalsAvailable
          handleChange={handleChange}
          masterPlanDurationsData={masterPlanDurationsData}
          fields={fields}
          handleBookingVehicle={handleBookingVehicle}
          getFilteredModal={getFilteredModal}
        />
      )}

      {/* Loader */}
      <FullScreenLoader loading={onLoad} />
    </Stack>
  );
};

export default InventoryDetails;
