import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { colors } from "../../../themes/colors";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function ShortInfoCard({
  icon,
  text = "",
  subText = "",
  bgImg = "",
  key = 0,
  cardSelected = false,
  onClick = () => {},
}: any) {
  return (
    <Grid item xs={3} key={key}>
      <Card
        onClick={onClick}
        sx={{
          minWidth: 230,
          height: "80px",
          border: cardSelected ? "1px solid #2bc7c9" : "1px solid #e2e2e2",
          cursor: "pointer",
        }}
      >
        <CardContent sx={{ backgroundImage: `url(${bgImg})` }}>
          <Box display="flex" sx={{ height: "48px", gap: "16px" }}>
            <Box>
              <img src={icon} />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "14px", color: colors.text_secondary_gray }}
              >
                {text ?? ""}
              </Typography>
              <Typography>{subText ?? ""}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
