import * as actionTypes from "../actionTypes";

interface GetOperatorList {
  search?: string;
}

interface OperatorActionClickInterface {
  operatorId: number | null;
  status: number | null;

}

export const getOperatorList = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_REQUESTED,
  payload,
});

export const operatorChangeStatus = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
    .DELETE_OPERATOR_REQUESTED,
  payload,
});
export const addOperator = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.ADD_OPERATOR_REQUESTED,
  payload,
});

export const updateOperator = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.UPDATE_OPERATOR_REQUESTED,
  payload,
});

export const operatorActionClick = (
  payload: OperatorActionClickInterface
) => {
  return {
    type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_ACTION_CLICK_REQUESTED,
    payload,
  };
};


export const getBusinessType = () => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_REQUESTED,

});

export const getAllDocumentTypes = () => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_ALL_DOCUMENT_TYPES_REQUESTED,

});

export const operatorStoreDocuments = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_STORE_DOCUMENT_REQUESTED,
  payload,
});

export const getOperatorDetails = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_REQUESTED,
  payload,
});

export const editOperator = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_REQUESTED,
  payload,
});

export const saveOperator = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_REQUESTED,
  payload,
});

export const linkedAccount = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.LINKED_ACCOUNT_OPERATOR_REQUESTED,
  payload,
});

export const stakeHolder = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_REQUESTED,
  payload,
});

export const productConfiguration = (payload: any) => ({
  type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.PRODUCT_CONFIGURATION_OPERATOR_REQUESTED,
  payload,
});