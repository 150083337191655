import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { colors } from "../themes/colors";
import { UserStatus, VehicleStatus } from "../constants/bookingContants";
import { extraChargeStatusMap } from "../constants/enum";

function Status(props: any) {
  let chipBackgroundColor = "";
  let chipFontColor = "";
  switch (props.status) {
    case "Active":
    case "Ended":
    case "Completed":
    case 'AVAILABLE':
      chipBackgroundColor = colors.light_green;
      chipFontColor = "rgb(255,255,255)";
      break;
    case "Upcoming":
      chipBackgroundColor = "rgb(51, 153, 255)";
      chipFontColor = "rgb(255,255,255)";
      break;
    case "InActive":
    case "INACTIVE":
    case "Inactive":
    case "Cancelled":
    case "Blocked":

    case "Deleted":
    case "Requested":

    case 6:
      chipBackgroundColor = "rgb(219, 6, 6)";
      chipFontColor = "rgb(255,255,255)";
      break;
    case "Premature Ride":
      chipBackgroundColor = colors.THEME_Yellow;
      chipFontColor = "rgb(255,255,255)";
      break;
    case "Pending":
    case "Ongoing":
    case 1:
      chipBackgroundColor = "rgb(252, 181, 47)";
      // chipBackgroundColor = "rgb(255, 140, 0)";
      chipFontColor = "rgb(255,255,255)";
      break;
    case 3:
      chipBackgroundColor = "rgb(252, 181, 47)";
      // chipBackgroundColor = "rgb(255, 140, 0)";
      chipFontColor = "rgb(255,255,255)";
      break;
    case VehicleStatus.Engaged:
    case "ENGAGED":
      chipBackgroundColor = "#FAA725";
      chipFontColor = "rgb(255,255,255)";
      break;
    case VehicleStatus.UnderMaintenance:
      chipBackgroundColor = "#941920";
      chipFontColor = "rgb(255,255,255)";
      break;
    case "Ended":
      chipBackgroundColor = "#90EE90";
      chipFontColor = "rgb(255,255,255)";
      break;
    case "Completed":
    case VehicleStatus.Available:
      chipBackgroundColor = "#1EB263";
      chipFontColor = "rgb(255,255,255)";
      break;
    case VehicleStatus.InService:
      chipBackgroundColor = colors.THEME_BLUE;
      chipFontColor = "rgb(255,255,255)";
      break;
    case UserStatus.Hidden:
      chipBackgroundColor = "rgba(0, 0, 0, 0.2)";
      chipFontColor = "rgb(255,255,255)";
      break;
    case "New":
      chipBackgroundColor = "#FFA500";
      chipFontColor = "rgb(255,255,255)";
      break;

    default:
      chipBackgroundColor = "rgba(0, 0, 0, 0.08)";
  }
  return (
    <Chip
      style={{
        minWidth: "75px",
        backgroundColor: chipBackgroundColor,
        color: chipFontColor,
        fontSize: "12px",
        font: "inherit",
      }}
      label={props.status}
      size="small"
    />
  );
}


function Status1(props: any) {
  let className = "";
  switch (props.status) {
    case 0:
    case 1:
      className = "badge bg-warning";
      break;
    case 2:
      className = "badge bg-success";
      break;
    case 3:
    case 4:
      className = "badge bg-danger";
      break;
    default:
      className = "badge bg-success";
  }
  return (<span className={className} style={{ marginLeft: 15 }}>{extraChargeStatusMap.get(props?.status)}</span>)
}


export {
  Status,
  Status1
}