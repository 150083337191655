import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { colors } from "../themes/colors";
import "./components.css";


export interface Tablist {
  key: string;
  count: number;
}

interface TabsProps {
  defaultActive?: number;
  tabsList: Tablist[];
  width?: number | string;
  setActiveTab?: any;
  disabled?: boolean;
}

function Tabs({
  defaultActive,
  tabsList,
  width,
  setActiveTab,
  disabled = false,
}: TabsProps) {
  const [active, setActive] = useState<number>(defaultActive || 0);

  useEffect(() => {
    setActive(defaultActive || 0);
  }, [defaultActive]);

  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const handleTabChange = (i: number) => {
    if (!disabled) {
      setActive(i);
      setActiveTab(i);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        overflow: "hidden",
        padding: isDesktopOrTablet ? "7px 15px" : "0px 0px",
      }}
    >
      {tabsList.map((each, i) => (
        <>
          <Box
            key={i}
            onClick={() => handleTabChange(i)}
            className={`tab ${active === i ? "tab-active" : ""}`}
            style={{
              width: width || `23%`,
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: "50%",
                minWidth: "20px",
                minHeight: "20px",
                backgroundColor: active === i ? "lightGray" : colors.THEME_BLUE,
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                right: "-5px",
                top: "-7px",
                padding: each.count > 9 ? "0 5px" : "0",
              }}
            >
              <Typography
                sx={{
                  color: active === i ? colors.text_black : colors.white,
                  fontSize: each.count > 9 ? "11px" : "12px",
                  fontWeight: 400,
                }}
              >
                {each.count}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              className={active === i ? "tab-text" : ""}
              style={{
                fontSize: isDesktopOrTablet ? "16px" : "12px",
                fontWeight: "bold",
                fontFamily: "EuclidCircularB-SemiBold",
                fontStretch: "normal",
                textAlign: "center",
                wordBreak: "keep-all",
              }}
            >
              {each?.key}
            </Typography>
          </Box>
        </>
      ))}
    </Box>
  );
}

export { Tabs };
