//@ts-nocheck
import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  IconButton,
  Skeleton,
  Typography,
  Collapse,
  Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useSelector } from "react-redux";
import { colors } from "../../themes/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import FeedBackDetail from "./FeedBackDetail";

export default function DynamicTable({
  pageSetter = (page) => {
    /**/
  },
  rowsPerPageSetter = (rows) => {
    /**/
  },
  rows = [],
  actions = [],
  columns = [],
  totalItems = 0,
  size = 10,
  page = 0,
  resetSize = false,
  paddingAction = "",
  displayTable = {},
  loading = false,
  handleSort = (param) => {
    /**/
  },
  hidePagination = false,
  maxHeight = 0.7,
  showBrand = false,
  localPagination = false,
  width = "100%",
  extraData = {},
  rowStyle = {},
  setFeedBackId = (id) => {},
  userFeedBack,
  loading3,
  expandedRow = null,
  setExpandedRow = (row) => {}

}) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state) => state.localeReducer);

  const { global } = locale;
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const [pageNo, setPageNo] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(size);
  // const [expandedRow, setExpandedRow] = React.useState(null);
  const handleRowClick = (k) => {
    setExpandedRow(expandedRow === k ? null : k);
  };

  React.useEffect(() => {
    setRowsPerPage(size);
  }, [size]);

  React.useEffect(() => {
    setPageNo(page);
  }, [page]);

  React.useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const handleChangePage = (event, newPage) => {
    pageSetter(newPage);
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    rowsPerPageSetter(+event.target.value);
    setPageNo(0);
  };
  React.useEffect(() => {
    // alert()
    setPageNo(0);
    setRowsPerPage(10);
   
   
  }, [resetSize]);

 


  const handleRowsTest = (val) => {
    rowsPerPageSetter(val);
  };

  // const handleClick=(id)=>{
  //   setSortOrder(sortOrder.set(id,!sortOrder.get(id)));
  //   if(sortOrder.get(id)===true)
  //   setRowss(_.sortBy(rows,id));
  //   else
  //   setRowss(_.sortBy(rows,id).reverse());

  // }
  React.useEffect(() => {
    setRowsPerPage(size);
  }, [size]);
  // React.useEffect(() => {
  //   // alert()
  //   setPageNo(0);
  //   setRowsPerPage(10);
  // }, [resetSize]);

  return (
    <Grid style={{ maxWidth: screenSize.dynamicWidth - 25 }}>
      <Paper
        sx={{
          width: width,
          overflow: "hidden",
          marginTop: 1,
        }}
      >
        <TableContainer
          sx={{ maxHeight: screenSize.dynamicHeight * maxHeight }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minWidth: 650,
              "& .MuiTableRow-root:hover": {
                backgroundColor: "rgba(25, 189, 189,0.2)",
              },
            }}
          >
            <TableHead style={{ backgroundColor: "rgb(36, 43, 53)" }}>
              <TableRow>
                {rows && rows?.length === 0 ? (
                  <TableCell
                    key={1}
                    align={"left"}
                    style={{
                      minWidth: screenSize.dynamicWidth * 0.825,
                      backgroundColor: "rgb(36, 43, 53)",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: isDesktopOrTablet ? "15px" : "13px",
                      }}
                    >
                      Sr No.
                    </Typography>
                  </TableCell>
                ) : (
                  columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "rgb(36, 43, 53)",
                        position: column.id === "link" ? "sticky" : "",
                        right: column.id === "link" ? 0 : "auto",
                        zIndex: column.id === "link" ? 10 : "",
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          fontFamily: "EuclidCircularB-Medium",
                          fontSize: isDesktopOrTablet ? "15px" : "13px",
                        }}
                      >
                        {column.label}
                        &nbsp;
                        <span>
                          {column?.sortable ? (
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleSort({
                                  key: column.sortKey,
                                  direction: column.direction,
                                  item: index,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                //onClick={() => handleClick(column.id)}
                                size="xs"
                                icon={
                                  column?.direction == "desc"
                                    ? faArrowDownShortWide
                                    : faArrowUpShortWide
                                }
                                color="white"
                              />
                            </IconButton>
                          ) : null}
                        </span>
                      </Typography>
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
            {loading === true ? (
              <TableBody style={{ minWidth: 200 }}>
                {new Array(6).fill(1).map((eachData) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={Math.random()}
                    style={{ backgroundColor: colors.white }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={Math.random()}
                        // align={column.align}
                      >
                        <Skeleton
                          variant={"rounded"}
                          style={{
                            height: 30,
                            width: screenSize.dynamicWidth * 0.78,
                          }}
                          animation="wave"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : rows && rows?.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontFamily: "EuclidCircularB-Regular",
                }}
              >
                {global.NoDataFound}
              </div>
            ) : (
              <TableBody>
                {
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  (localPagination
                    ? rows.slice(
                        pageNo * rowsPerPage,
                        pageNo * rowsPerPage + rowsPerPage
                      )
                    : rows
                  )?.map((row, k) => {
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code || k}
                          style={{
                            // backgroundColor: colors.light_gray,
                            position: "relative",
                            cursor: "pointer",
                            ...rowStyle,
                          }}
                          onClick={() => {
                            setFeedBackId(row?._id);
                            handleRowClick(k);
                          }}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                style={{
                                  fontFamily: "EuclidCircularB-Regular",
                                  wordBreak: "break-all",
                                  backgroundColor: "white",
                                  position:
                                    column.id === "link" ? "sticky" : "",
                                  right: column.id === "link" ? 0 : "auto",
                                  zIndex: column.id === "link" ? 5 : "auto",
                                  fontSize: isDesktopOrTablet ? "13px" : "12px",
                                  display: column.id === "link" ? "flex" : "",
                                  justifyContent:
                                    column.id === "link" ? "center" : "",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {column?.customJson === true ? (
                                  <div>
                                    {column?.render(
                                      row,
                                      size * page + k + 1,
                                      extraData
                                    )}
                                  </div>
                                ) : column.expandable ? (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    {expandedRow === k ? (
                                      <KeyboardArrowUp />
                                    ) : (
                                      <KeyboardArrowDown />
                                    )}
                                  </IconButton>
                                ) : (
                                  <text style={{ wordBreak: "keep-all" }}>
                                    {value}
                                  </text>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              backgroundColor: "#F2F2F2",
                            }}
                            colSpan={columns.length}
                          >
                            <Collapse
                              in={expandedRow === k}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                {/* Render expandable content here */}
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  <FeedBackDetail
                                    loading3={loading3}
                                    userFeedBack={userFeedBack}
                                  />
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {row.details}
                                </Typography>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                }
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {hidePagination ? null : (
          <Grid
            container
            style={{ backgroundColor: colors.white }}
            alignItems="flex-end"
            justifyContent={"flex-end"}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={localPagination ? rows.length : totalItems}
              rowsPerPage={rowsPerPage}
              page={pageNo}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
        {/* <Grid container style={{ padding: '10px' , }} alignItems="flex-end" justifyContent={"flex-end"}>
          <Pagination count={10} color="primary" />
        </Grid> */}
      </Paper>
    </Grid>
  );
}

const styles = (theme) => ({
  tableRow: {
    "&:hover": {
      backgroundColor: "blue !important",
    },
  },
});
