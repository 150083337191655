import { Box, Skeleton, Stack, Typography } from "@mui/material";

const CustomerOtpLoader = () => {
  

  const wrapperStyles = {
    border: "1px solid #F9F9F9",
    margin: "20px",
    transform: "translate(0%, 20%)",
    background: "#F9F9F9",
  };

  const otpStyles = {
    width: "40px",
    height: "40px",
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding="20px"
      className="CustomerOtpLoaderWrapper"
      justifyContent={"space-between"}
      alignContent={"center"}
      sx={wrapperStyles}
      height={"300px"}
    >
      <Stack className="Heading" alignItems={"center"}>
        <Skeleton variant="text" width="50%" height={"40px"}></Skeleton>
        <Skeleton variant="text" width="70%"></Skeleton>
        <Skeleton variant="text" width="50%" height={"40px"}></Skeleton>
      </Stack>

      <Stack
        className="Content"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Skeleton variant="text" width="10%" height="40px">
          <Typography>.</Typography>
        </Skeleton>
        <Stack
          direction="row"
          className="OtpFields"
          gap="2px"
          justifyContent={"center"}
          width={"100%"}
        >
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
          <Skeleton variant="rectangular" sx={otpStyles}></Skeleton>
        </Stack>
        <Skeleton variant="text" width="60%"></Skeleton>
      </Stack>

      <Stack className="Action" alignItems={"center"}>
        <Skeleton variant="rectangular" width="80%" height={"30px"}></Skeleton>
      </Stack>
    </Box>
  );
};

export default CustomerOtpLoader;
