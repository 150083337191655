import { toastr } from "react-redux-toastr";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import { CommonService } from "../../services";
import * as actionTypes from "../actions/actionTypes";
import { actionInterface } from "../../interfaces/commonInterfaces";

function* getSignedUrl(action: actionInterface): any {
  try {
    const response = yield call(
      CommonService.getSignedUrlService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield delay(4000);

      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.PUT_ATTACHMENT_ON_SIGNED_URL,
        payload: {
          signedUrl: response?.data?.data?.files,
          filedata: action?.payload.filesData,
        },
      });

      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_REQUESTED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* callSignedUrl(url: any, filedata: any): any {
  try {
    let result = yield call(CommonService?.uploadDocumentService, {
      url: url?.signedUrl,
      file: filedata,
    });

    if (result.status === 200) {
      return url?.id;
    } else {
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS
          .PUT_ATTACHMENT_ON_SIGNED_URL_FAILED,
        payload: url,
      });
      return "err";
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* putAttachment(action: actionInterface): any {
  try {
    const { signedUrl, filedata } = action.payload;

    const data: [] = yield all(
      signedUrl?.map((url: any, index: number) => {
        return callSignedUrl(url, filedata?.files[index]);
      })
    );

    const response = data?.filter((data: any) => {
      if (data !== "err") return data;
    });

    if (response?.length > 0) {
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_REQUESTED,
        payload: {
          attachmentsIds: response,
          bookingId: filedata?.bookingId,
        },
      });
    } else {
      toastr.error("", "Document Upload Failed");
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* documentUploadS3(action: any): any {
  try {
    // yield delay(5000);
    const response = yield call(
      CommonService.moveAttachmentToS3,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_SUCCESS,
      });
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_SUCCESS,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_FAILED,
      });

      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_SUCCESS,
    });
  }
}

function* deleteDocument(action: actionInterface): any {
  try {
    const response = yield call(
      CommonService.deleteDocumentService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_SUCCESS,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_FAILED,
    });
  }
}
function* commonSagaX() {
  yield takeLatest(
    actionTypes.DOCUMENT_UPLOAD_ACTIONS.GET_SIGNED_URLS_ATTACHMENT,
    getSignedUrl
  );

  yield takeLatest(
    actionTypes.DOCUMENT_UPLOAD_ACTIONS.PUT_ATTACHMENT_ON_SIGNED_URL,
    putAttachment
  );

  yield takeLatest(
    actionTypes.DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_REQUESTED,
    documentUploadS3
  );

  yield takeLatest(
    actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_REQUESTED,
    deleteDocument
  );
}

export default commonSagaX;
