import { generateRandomString } from "../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";
import { KycTypes } from "../../../constants/constants";

/* interface kycDetailsObjInterface {
  [key: string]: any;
} */

/* interface initialState {
  uploadLoader: boolean;
  signedUrlFailedFiles: object[];
  linearProgressValue: number;
  docUploadSuccess: boolean;
}
const initialState: initialState = {
  uploadLoader: false,
  signedUrlFailedFiles: [],
  linearProgressValue: 0,
  docUploadSuccess: false,
}; */

interface kycDetailsObjInterface {
  SELFIE: any;
  DRIVING_LICENCE: any;
  AADHAAR: any;
  OTHER_DOCUMENT: any;
  Address: any;
  Name: any;
}
const kycInitialState: kycDetailsObjInterface = {
  SELFIE: {
    type: KycTypes.SELFIE,
    details: {
      verifyDigitally: true,
      selfieAttachmentId: "",
    },
  },
  DRIVING_LICENCE: {
    type: KycTypes.DRIVING_LICENCE,
    details: {
      ocr: true,
      frontImageAttachmentId: "",
      backImageAttachmentId: "",
      dlNumber: "",
      dob: "", // 27-11-1995
    },
  },
  AADHAAR: {
    type: KycTypes.AADHAAR,
    details: {
      verifyDigitally: false,
      callbackUrl: "",
      frontImageAttachmentId: "",
      backImageAttachmentId: "",
    },
  },
  OTHER_DOCUMENT: {
    type: KycTypes.OTHER_DOCUMENT,
    details: {
      VOTER_ID: {
        attachmentIds: [],
        documentType: "VOTER_ID",
      },
      BANK_DETAIL: {
        attachmentIds: [],
        documentType: "BANK_DETAIL",
      },
      ELECTRICITY_BILL: {
        attachmentIds: [],
        documentType: "ELECTRICITY_BILL",
      },
      RENT_BILL: {
        attachmentIds: [],
        documentType: "RENT_BILL",
      },
      PASSPORT: {
        attachmentIds: [],
        documentType: "PASSPORT",
      },
      OTHER: {
        attachmentIds: [],
        documentType: "OTHER",
      },
    },
  },
  Address: {},
  Name: {},
};
interface customerManagementReducerInterface {
  searchRandomValue: string;
  registeredCustomerDetails: any;
  isOtpSent: boolean;
  addCustomerLoader: boolean;
  isOTPMatched: boolean;
  isCustomerRegistered: boolean;
  isOTPInvalid: boolean;
  isOtpResent: boolean;
  dashboardStats: any;
  customerProfileData: any;
  customerDetailsLoader: boolean;
  customerEmergencyContacts: any;
  kycTrustScoreData: any;
  emergencyContactLoader: boolean;
  blockedCustomerLoader: boolean;
  unblockedCustomerLoader: boolean;
  deleteCustomerLoader: boolean;
  emergencyContactData: any;
  emergencyOtpData: any;
  isEmergencyOtpSent: boolean;
  isEmergencyOtpResent: boolean;
  isEmergencyOTPInvalid: boolean;
  isEmergencyOTPMatched: boolean;
  bookingStats: Object;
  bookingList: Object;
  bookingListLoader: boolean;
  kycDetailsPayloadObj: kycDetailsObjInterface;
  individualKycDetailsData: any;
  individualKycDetailsLoader: boolean;
  uploadLoader: boolean;
  linearProgressValue: number;
  docUploadSuccess: boolean;
  signedUrlFailedFiles: object[]; // remove
  trustScoreLoader: boolean;
  deleteKycLoader: boolean;
  unverifyKycLoader: boolean;
  kycModalLoader: boolean;
  closeEmergencyModal: boolean;
  downloadBookingListLoader: boolean;
  loading: boolean;
  checkUserLoader: boolean;
  verifyEmergencyOtpLoader: boolean;
}

const initialState: customerManagementReducerInterface = {
  searchRandomValue: "",
  registeredCustomerDetails: null,
  isOtpSent: false,
  addCustomerLoader: false,
  isOTPMatched: false,
  isCustomerRegistered: false,
  isOTPInvalid: false,
  isOtpResent: false,
  dashboardStats: "",
  customerProfileData: {},
  customerEmergencyContacts: [],
  customerDetailsLoader: false,
  kycTrustScoreData: {},
  emergencyContactLoader: false,
  blockedCustomerLoader: false,
  unblockedCustomerLoader: false,
  deleteCustomerLoader: false,
  emergencyContactData: {},
  emergencyOtpData: {},
  isEmergencyOtpSent: false,
  isEmergencyOtpResent: false,
  isEmergencyOTPInvalid: false,
  isEmergencyOTPMatched: false,
  bookingStats: {},
  bookingList: {},
  bookingListLoader: false,
  kycDetailsPayloadObj: kycInitialState,
  individualKycDetailsData: {},
  individualKycDetailsLoader: false,
  uploadLoader: false,
  linearProgressValue: 0,
  docUploadSuccess: false,
  signedUrlFailedFiles: [],
  trustScoreLoader: false,
  deleteKycLoader: false,
  unverifyKycLoader: false,
  kycModalLoader: false,
  closeEmergencyModal: false,
  downloadBookingListLoader: false,
  loading: false,
  checkUserLoader: false,
  verifyEmergencyOtpLoader: false
};

const customerManagementReducer = (state = initialState, action: any) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_REQUESTED:
      return {
        ...state,
        isOtpSent: false,
        addCustomerLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_SUCCESS:
      return {
        ...state,
        registeredCustomerDetails: payload,
        isOtpSent: true,
        addCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.REGISTER_CUSTOMER_FAILED:
      return {
        ...state,
        registeredCustomerDetails: null,
        addCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_REQUESTED:
      return {
        ...state,
        isOTPMatched: false,
        isOTPInvalid: false,
        isCustomerRegistered: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_SUCCESS:
      return {
        ...state,
        isOTPMatched: true,
        isCustomerRegistered: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_CUSTOMER_OTP_FAILED:
      return {
        ...state,
        isOTPMatched: false,
        isOTPInvalid: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_REQUESTED:
      return {
        ...state,
        isOtpResent: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_SUCCESS:
      return {
        ...state,
        isOtpResent: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_CUSTOMER_OTP_FAILED:
      return {
        ...state,
        isOtpResent: false,
      };
      case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_INVALID_OTP_FLAG:
      return {
        ...state,
        isOTPInvalid: false,
      };
      
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .REVERT_IS_RESENT_FLAG_REQUESTED:
      return {
        ...state,
        isOtpResent: false,
      };

    // search customer
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .SEARCH_CUSTOMER_DETAILS_REQUESTED:
      return {
        ...state,
        checkUserLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .SEARCH_CUSTOMER_DETAILS_SUCCESS:
      const randomString = generateRandomString(32, "#aA");
      return {
        ...state,
        checkUserLoader: false,
        customerProfileData: initialState.customerProfileData,
        customerEmergencyContacts: initialState.customerEmergencyContacts,
        emergencyOtpData: initialState.emergencyOtpData,
        isEmergencyOtpSent: initialState.isEmergencyOtpSent,
        searchRandomValue: randomString,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEARCH_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        checkUserLoader: false,
      };

    // customer profile data
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_PROFILE_DATA_REQUESTED:
      return {
        ...state,
        customerDetailsLoader: true,
        customerProfileData: prevState.customerProfileData,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        customerDetailsLoader: false,
        customerProfileData: payload,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_PROFILE_DATA_FAILED:
      return {
        ...state,
        customerDetailsLoader: false,
      };

    // emergency contact
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED:
      return {
        ...state,
        customerDetailsLoader: true,
        emergencyContactLoader: true,
        customerEmergencyContacts: initialState.customerEmergencyContacts,
        // closeEmergencyModal: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerDetailsLoader: false,
        emergencyContactLoader: false,
        customerEmergencyContacts: payload,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED:
      return {
        ...state,
        customerDetailsLoader: false,
        emergencyContactLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_REQUESTED:
      return {
        ...state,
        emergencyContactLoader: true,
        emergencyContactData: initialState.emergencyContactData,
        closeEmergencyModal: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        emergencyContactLoader: false,
        emergencyContactData: payload,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .ADD_EMERGENCY_CONTACT_FOR_CUSTOMER_FAILED:
      return {
        ...state,
        emergencyContactLoader: false,
        emergencyContactData: initialState.emergencyContactData,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEND_EMERGENCY_OTP_REQUESTED:
      return {
        ...state,
        emergencyContactLoader: true,
        emergencyOtpData: initialState.emergencyOtpData,
        isEmergencyOtpSent: initialState.isEmergencyOtpSent,
        isEmergencyOTPMatched: false,
        isEmergencyOtpResent: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEND_EMERGENCY_OTP_SUCCESS:
      return {
        ...state,
        emergencyContactLoader: false,
        emergencyOtpData: payload,
        isEmergencyOtpSent: true,
        isEmergencyOTPMatched: false,
        isEmergencyOtpResent: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.SEND_EMERGENCY_OTP_FAILED:
      return {
        ...state,
        // isEmergencyOtpSent: initialState.isEmergencyOtpSent,
        emergencyContactLoader: initialState.emergencyContactLoader,
        isEmergencyOtpSent: false,
        isEmergencyOTPMatched: false,
        // emergencyOtpData: initialState.emergencyOtpData
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_EMERGENCY_OTP_FLAG_REQUESTED:
      return {
        ...state,
        isEmergencyOtpSent: false,
        isEmergencyOtpResent: false,
        isEmergencyOTPMatched: false,
        isOTPInvalid: false,
        isEmergencyOTPInvalid: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_EMERGENCY_DETAILS_REQUESTED:
      return {
        ...state,
        isEmergencyOtpSent: false,
        isEmergencyOtpResent: false,
        isEmergencyOTPMatched: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_EMERGENCY_CONTACT_MODAL:
      return {
        ...state,
        closeEmergencyModal: true,
      };

    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_EMERGENCY_OTP_REQUESTED:
      return {
        ...state,
        isEmergencyOTPInvalid: false,
        isEmergencyOTPMatched: false,
        closeEmergencyModal: false,
        verifyEmergencyOtpLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_EMERGENCY_OTP_SUCCESS:
      return {
        ...state,
        isEmergencyOTPMatched: true,
        closeEmergencyModal: true,
        isEmergencyOtpSent: false,
        isEmergencyOtpResent: false,
        verifyEmergencyOtpLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.VERIFY_EMERGENCY_OTP_FAILED:
      return {
        ...state,
        isEmergencyOTPInvalid: true,
        isEmergencyOTPMatched: false,
        closeEmergencyModal: false,
        verifyEmergencyOtpLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_EMERGENCY_OTP_REQUESTED:
      return {
        ...state,
        isEmergencyOtpResent: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_EMERGENCY_OTP_SUCCESS:
      return {
        ...state,
        isEmergencyOtpResent: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESEND_EMERGENCY_OTP_FAILED:
      return {
        ...state,
        isEmergencyOtpResent: false,
      };

    // Kyc trust score
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_KYC_TRUST_SCORE_REQUESTED:
      return {
        ...state,
        // customerDetailsLoader: true,
        trustScoreLoader: true,
        kycTrustScoreData: initialState.kycTrustScoreData,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_KYC_TRUST_SCORE_SUCCESS:
      return {
        ...state,
        // customerDetailsLoader: false,
        trustScoreLoader: false,
        kycTrustScoreData: payload,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_KYC_TRUST_SCORE_FAILED:
      return {
        ...state,
        // customerDetailsLoader: false,
        trustScoreLoader: false,
        kycTrustScoreData: initialState.kycTrustScoreData,
      };

    // block, unblock, delete
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_REQUESTED:
      return {
        ...state,
        blockedCustomerLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_SUCCESS:
      return {
        ...state,
        blockedCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.BLOCK_CUSTOMER_FAILED:
      return {
        ...state,
        blockedCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_REQUESTED:
      return {
        ...state,
        unblockedCustomerLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_SUCCESS:
      return {
        ...state,
        unblockedCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNBLOCK_CUSTOMER_FAILED:
      return {
        ...state,
        unblockedCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_REQUESTED:
      return {
        ...state,
        deleteCustomerLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomerLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        deleteCustomerLoader: false,
      };

    // stats
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_DASHBOARD_STATS_REQUESTED:
      return {
        ...state,
        dashboardStats: "",
        loading: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboardStats: action.payload,
        loading: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_DASHBOARD_STATS_FAILED:
      return {
        ...state,
        dashboardStats: "",
        loading: false,
      };

    // booking stats
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_STATS_REQUESTED:
      return {
        ...state,
        bookingStats: "",
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_STATS_SUCCESS:
      return {
        ...state,
        bookingStats: action.payload,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_STATS_FAILED:
      return {
        ...state,
        bookingStats: "",
      };

    // booking list
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_LIST_REQUESTED:
      return {
        ...state,
        bookingList: [],
        bookingListLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKING_LIST_FAILED:
      return {
        ...state,
        bookingList: [],
        bookingListLoader: false,
      };

    // kyc detail
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_INIDIVIDUAL_KYC_DETAILS_REQUESTED:
      return {
        ...state,
        individualKycDetailsData: {},
        individualKycDetailsLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_INIDIVIDUAL_KYC_DETAILS_SUCCESS:
      return {
        ...state,
        individualKycDetailsData: payload,
        individualKycDetailsLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .GET_INIDIVIDUAL_KYC_DETAILS_FAILED:
      return {
        ...state,
        individualKycDetailsData: {},
        individualKycDetailsLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.CLEAR_INIDIVIDUAL_KYC_DETAILS:
      return {
        ...state,
        individualKycDetailsData: {},
        individualKycDetailsLoader: false,
      };

    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .RESET_KYC_DETAILS_PAYLOAD_OBJECT:
      return {
        ...state,
        kycDetailsPayloadObj: payload,
      };

    // kyc document upload
    // MANUALLY_VERIFY_KYC_REQUESTED
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_REQUESTED:
      return {
        ...state,
        kycModalLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_SUCCESS:
      return {
        ...state,
        kycModalLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.MANUALLY_VERIFY_KYC_FAILED:
      return {
        ...state,
        kycModalLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        linearProgressValue: 100,
        docUploadSuccess: false,
        kycDetailsPayloadObj: {
          ...prevState.kycDetailsPayloadObj,
          [payload.kycTypePayloadObj.type]: payload.kycTypePayloadObj,
        },
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_REQUESTED:
      return {
        ...state,
        uploadLoader: true,
        linearProgressValue: 30,
        docUploadSuccess: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        linearProgressValue: 100,
        docUploadSuccess: true,
        kycModalLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED:
      return { ...state, uploadLoader: false };

    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .POST_KYC_DOCUMENT_IN_S3_REQUESTED:
      return {
        ...state,
        linearProgressValue: 85,
        docUploadSuccess: false,
        kycModalLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .POST_KYC_DOCUMENT_IN_S3_SUCCESS:
      return { ...state, kycModalLoader: false };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.POST_KYC_DOCUMENT_IN_S3_FAILED:
      return { ...state, docUploadSuccess: false, kycModalLoader: false };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.GET_KYC_SIGNED_URLS_REQUESTED:
      return {
        ...state,
        signedUrlFailedFiles: [],
        uploadLoader: true,
        linearProgressValue: 10,
        docUploadSuccess: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_REQUESTED:
      return { ...state, linearProgressValue: 70 };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_FAILED:
      let arr = state.signedUrlFailedFiles;
      arr.push(payload);
      return { ...state, signedUrlFailedFiles: arr };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .CLEAR_KYC_DOCUMENT_UPLOAD_REDUX:
      return {
        ...state,
        docUploadSuccess: false,
        docDeleteSuccess: false,
        signedUrlFailedFiles: [],
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_SELFIE_ATTACHMENT_ID:
      return {
        ...state,
        kycDetailsPayloadObj: {
          ...prevState.kycDetailsPayloadObj,
          SELFIE: { ...kycInitialState.SELFIE },
        },
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_DOC_UPLOAD_SUCCESS_FLAG:
      return {
        ...state,
        docUploadSuccess: false,
      }; 
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.RESET_OTHER_DOCS_ATTACHMENT_ID:
      return {
        ...state,
        kycDetailsPayloadObj: {
          ...prevState.kycDetailsPayloadObj,
          OTHER_DOCUMENT: { ...kycInitialState.OTHER_DOCUMENT },
        },
      };

    // delete kyc
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_KYC_REQUESTED:
      return {
        ...state,
        kycModalLoader: true,
        deleteKycLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_KYC_SUCCESS:
      return {
        ...state,
        kycModalLoader: false,
        deleteKycLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.DELETE_CUSTOMER_KYC_FAILED:
      return {
        ...state,
        kycModalLoader: false,
        deleteKycLoader: false,
      };

    // unverify kyc
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .UNVERIFY_CUSTOMER_KYC_REQUESTED:
      return {
        ...state,
        unverifyKycLoader: true,
        kycModalLoader: true,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNVERIFY_CUSTOMER_KYC_SUCCESS:
      return {
        ...state,
        kycModalLoader: false,
        unverifyKycLoader: false,
      };
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.UNVERIFY_CUSTOMER_KYC_FAILED:
      return {
        ...state,
        kycModalLoader: false,
        unverifyKycLoader: false,
      };

    // download booking list
    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_REQUESTED:
      return {
        ...state,
        downloadBookingListLoader: true,
      };

    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_SUCCESS:
      return {
        ...state,
        downloadBookingListLoader: false,
      };

    case actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .DOWNLOAD_CUSTOMER_BOOKINGS_LIST_FAILED:
      return {
        ...state,
        downloadBookingListLoader: false,
      };

    default:
      return { ...prevState };
  }
};

export default customerManagementReducer;
