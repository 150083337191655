import { COUPONS_ACTIONS } from "../../actions/actionTypes";
import { couponInterface } from "../actionInterfaces";

const initialState = {
  payload: {},
  loading: true,
  couponDataById: [],
  couponUsesById: [],
  couponRevenueById: [],
  couponRevenueCSVDownloadById: [],
  couponRevenueExcelDownloadById: [],
  addCouponsuccess: false,
  editCouponLoading: false,
  downloadRefresh: false,
};
const couponReducer = (state = initialState, action: couponInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case COUPONS_ACTIONS.GET_COUPON_BY_ID_REQUESTED:
      return {
        ...state,
        addCouponsuccess: false,
        loading: true,
      };
    case COUPONS_ACTIONS.GET_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
        couponDataById: payload?.Result[0],
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPONS_REQUESTED:
      return {
        ...state,
        loading: true,
        addCouponsuccess: false,
      };
    case COUPONS_ACTIONS.EDIT_COUPON_REQUESTED:
      return {
        ...state,
        loading: true,
        editCouponLoading: true,
        addCouponsuccess: false,
      };
    case COUPONS_ACTIONS.EDIT_COUPON_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        editCouponLoading: false,
      };
    case COUPONS_ACTIONS.EDIT_COUPON_FAILED:
      return {
        ...state,
        loading: false,
        editCouponLoading: false,
      };
    case COUPONS_ACTIONS.ADD_COUPON_REQUESTED:
      return {
        ...state,
        loading: true,
        editCouponLoading: true,
      };
    case COUPONS_ACTIONS.ADD_COUPON_SUCCESS:
      return {
        ...state,
        ...payload,
        editCouponLoading: false,
        loading: false,
      };
    case COUPONS_ACTIONS.ADD_COUPON_FAILED:
      return {
        ...state,
        loading: false,
        editCouponLoading: false,
      };
    case COUPONS_ACTIONS.DELETE_COUPON_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case COUPONS_ACTIONS.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case COUPONS_ACTIONS.DELETE_COUPON_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_USES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case COUPONS_ACTIONS.GET_COUPON_USES_SUCCESS:
      return {
        ...state,
        ...payload,
        couponUsesById: payload?.Result,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_USES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_SUCCESS:
      return {
        ...state,
        ...payload,
        couponRevenueById: payload?.Result,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        downloadRefresh: false,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        couponRevenueCSVDownloadById: payload,
        couponRevenueExcelDownloadById: payload,
        loading: false,
        downloadRefresh: true,
      };
    case COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_FAILED:
      return {
        ...state,
        loading: false,
        downloadRefresh: false,
      };
    default:
      return { ...prevState };
  }
};

//return feedback table data

export default couponReducer;
