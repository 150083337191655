import RotateRightSharp from "@mui/icons-material/RotateRightSharp";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridEditBooleanCellProps,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { storeFilterDataInLocalStorage } from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import { RootState } from "../../../../redux/store";
import CustomMultiSelect from "../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../themes/colors";
import {
  GenericObject,
  OperatorKycStatus,
  OperatorLeadStatus,
  OperatorLeadStatusDisplayStatus,
  OperatorStatusColorCode,
  filterStorageKeys,
} from "../../../constants/constants";
import {
  CityInputIcon,
  SearchIcon,
  fadedCalenderIcon,
} from "../../../constants/exportImages";
import en from "../../../locale/rental-en.json";
import { operationActions } from "../../../redux/actions";
import { routesConstants } from "../../../utils/RoutesConstants";
import {
  getLocalData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  istToNormalDate,
  toPascalCase,
} from "../../../utils/helper";
import { checkWhiteSpace } from "../../../utils/regex";
import styles from "./index.module.css";

const OperatorStatusArr = [
  {
    name: OperatorLeadStatus?.VERIFIED,
    displayName: "Verified",
  },
  {
    name: OperatorLeadStatus?.UNVERIFIED,
    displayName: "Unverified",
  },
];

interface InitialState {
  leadInfo: string;
  city: any[];
  status: { name: string; displayName: string };
  startDate: string | any;
  endDate: string | any;
  paginationModelValue: {
    page: number;
    pageSize: number;
  };
}

const initialState: InitialState = {
  leadInfo: "",
  city: [],
  status: { name: "", displayName: "" },
  startDate: "",
  endDate: "",
  paginationModelValue: {
    page: 0,
    pageSize: 10,
  },
};

const OperatorManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allCitiesDetails = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { operatorLeadList, loading } = useSelector(
    (state: RootState) => state.operatorManagementReducer
  );

  const [fields, setFields] = useState({ ...initialState });

  //handle ranges
  const handleDateRange = (value: any) => {
    let [startDate, endDate] = value;
    setFields((prevState: any) => ({
      ...prevState,
      paginationModelValue: {
        page: 0,
        pageSize: 10,
      },
      startDate,
      endDate,
    }));

    getOperatorLeadList({
      leadInfo: fields.leadInfo,
      city: fields.city,
      status: fields.status,
      startDate: startDate,
      endDate: endDate,
      page: 0,
      pageSize: 10,
    });
  };

  const handleChange = (type: string, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [type]: value,
      paginationModelValue: {
        page: 0,
        pageSize: 10,
      },
    }));
  };

  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModelValue: { page: page, pageSize: pageSize },
    }));

    getOperatorLeadList({
      leadInfo: fields.leadInfo,
      city: fields.city,
      status: fields.status,
      startDate: fields?.startDate,
      endDate: fields?.endDate,
      page: page,
      pageSize: pageSize,
    });
  };

  //get row id for table
  const getRowId = (row: any) => {
    return row?.id;
  };

  // on table row click
  const handleRowClick = (params: GridRowParams) => {
    if (params?.row?.id) {
      navigate(
        `${routesConstants.OPERATOR_MANAGEMENT_LEAD_DETAILS}/${params.row.id}`
      );
    }
  };

  //booking list data list
  const columns = [
    {
      field: "name",
      headerName: en.tableHeaders.name,
      headerAlign: "center",
      flex: 0.7,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip title={toPascalCase(params.row.name)}>
            <span>{toPascalCase(params.row.name)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "city",
      headerName: en.tableHeaders.city,
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={
              <>
                {params.row.preferredCity
                  ?.slice(0, 2)
                  .map((city: any, index: number) => (
                    <span key={index}>
                      {city}
                      {index < params.row.preferredCity.length - 1 ? ", " : ""}
                    </span>
                  ))}
              </>
            }
          >
            <Box
              style={{
                width: "140px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.preferredCity
                ?.slice(0, 2)
                .map((city: any, index: number) => (
                  <span key={index}>
                    {city}
                    {index < params.row.preferredCity.length - 1 ? ", " : ""}
                  </span>
                ))}
              {params.row.preferredCity?.length > 2 && <span>...</span>}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: en.tableHeaders.mobileNo,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: any): any => {
        return <span>{params.row.phone}</span>;
      },
    },
    {
      field: "email",
      headerName: en.tableHeaders.emailAddress,
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params: any): any => {
        return (
          <Tooltip title={params.row.email} arrow>
            <span>{params.row.email}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "receivedOn",
      headerName: "Received On",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: any): any => {
        return <span>{istToNormalDate(params.row.receivedOn)}</span>;
      },
    },

    {
      field: "status",
      headerName: en.tableHeaders.leadStatus,
      cellClassName: "booking-status-cell",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        return (
          <Typography
            style={{
              color: colors.white,
              fontWeight: "500",
              alignSelf: "center",
              width: "88px",
              backgroundColor:
                OperatorStatusColorCode[
                  params?.row?.status as keyof typeof OperatorStatusColorCode
                ],
              borderRadius: "30px",
              padding: "6px 13px 6px 13px",
            }}
          >
            {params?.row?.status
              ? OperatorLeadStatusDisplayStatus[
                  params.row
                    .status as keyof typeof OperatorLeadStatusDisplayStatus
                ]
              : en.global.NA}
          </Typography>
        );
      },
    },
  ];

  //get operator lead list
  const getOperatorLeadList = (data: any) => {
    // Store filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.OPERATOR_MANAGEMENT_FILTER_DATA]: {
        ...initialState,
        ...data,
      },
    });

    //check if all city selected
    const isAllCitiesSelected = allCitiesDetails?.length === data?.city?.length;

    const payload: any = {
      page: data.page + 1,
      pageSize: data.pageSize,
      leadInfo: data.leadInfo ? `"${data.leadInfo}"` : undefined,
      status: data?.status?.name
        ? [data.status?.name]
        : [
            OperatorLeadStatus?.VERIFIED,
            OperatorLeadStatus?.AGREEMENT_GENERATED,
            OperatorLeadStatus?.AGREEMENT_VERIFIED,
          ],
      from: data?.startDate
        ? getTimestampAtStartOfDay(data.startDate)
        : undefined,
      till: data?.endDate ? getTimestampAtEndOfDay(data?.endDate) : undefined,
    };

    if (!isAllCitiesSelected && data?.city?.length > 0) {
      payload["city"] = data?.city;
    }

    if (data.startDate && !data.endDate) return;

    dispatch(operationActions.getOperatorLeadList(payload));
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getOperatorLeadList(payload);
    }, 1000),
    []
  );

  const handleReset = () => {
    setFields(initialState);
    // clear filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.OPERATOR_MANAGEMENT_FILTER_DATA]: { ...initialState },
    });
    handleInitialCities();
    getOperatorLeadList({
      leadInfo: "",
      status: "",
      startDate: "",
      endDate: "",
      page: 0,
      pageSize: 10,
    });
  };

  // handle initial cities
  const handleInitialCities = async () => {
    const tempData: InitialState = await getLocalData(
      filterStorageKeys.OPERATOR_MANAGEMENT_FILTER_DATA
    );

    if (allCitiesDetails?.length) {
      const arr: string[] = allCitiesDetails.map((e: any) => e.name);

      setFields((prev: any) => ({
        ...prev,
        city: tempData.city?.length ? tempData.city : arr,
      }));
    }
  };

  //initail call
  useEffect(() => {
    if (allCitiesDetails?.length) {
      const fetchData = async () => {
        // handleInitialCities();
        // Fetch filters from local storage
        const tempData: InitialState = await getLocalData(
          filterStorageKeys.OPERATOR_MANAGEMENT_FILTER_DATA
        );

        const payload = {
          page: operatorLeadList?.pagination?.page
            ? operatorLeadList?.pagination?.page
            : initialState?.paginationModelValue?.page,
          pageSize: operatorLeadList?.pagination?.pageSize
            ? operatorLeadList?.pagination?.pageSize
            : initialState?.paginationModelValue?.pageSize,
          leadInfo: tempData?.leadInfo
            ? `"${tempData.leadInfo}"`
            : initialState?.leadInfo,
          city:
            tempData?.city?.length === allCitiesDetails?.length
              ? undefined
              : tempData?.city,
          status: tempData?.status?.name
            ? [tempData.status.name]
            : [
                OperatorLeadStatus?.VERIFIED,
                OperatorLeadStatus?.AGREEMENT_GENERATED,
                OperatorLeadStatus?.AGREEMENT_VERIFIED,
              ],
          from:
            getTimestampAtStartOfDay(tempData?.startDate) ??
            initialState?.startDate,
          till:
            getTimestampAtStartOfDay(tempData?.endDate) ??
            initialState?.endDate,
        };

        dispatch(operationActions.getOperatorLeadList(payload));

        const arr: string[] = allCitiesDetails.map((e: any) => e.name);

        setFields((prev: GenericObject) => ({
          ...prev,
          ...tempData,
          city: tempData.city?.length ? tempData.city : arr,
          status: tempData?.status?.name
            ? tempData.status
            : initialState?.status,
          paginationModelValue: {
            page: operatorLeadList?.pagination?.page
              ? operatorLeadList?.pagination?.page - 1
              : initialState?.paginationModelValue?.page,
            pageSize: operatorLeadList?.pagination?.pageSize
              ? operatorLeadList?.pagination?.pageSize
              : initialState?.paginationModelValue?.pageSize,
          },
        }));
      };
      fetchData();
    }
  }, [allCitiesDetails]);

  return (
    <Stack gap="10px">
      <Grid container spacing={2} alignItems="center" marginBottom="30px">
        <Grid item sm={12} md={4} lg={3}>
          <Input
            onChange={(e: any) => {
              if (checkWhiteSpace(e.target.value))
                handleChange("leadInfo", e.target.value);
              if (e.target.value.length > 2 || e.target.value?.length === 0) {
                debouncedSearch({
                  leadInfo: e.target.value,
                  city: fields.city,
                  status: fields.status,
                  startDate: fields?.startDate,
                  endDate: fields?.endDate,
                  page: 0,
                  pageSize: 10,
                });
              }
            }}
            iconStart={SearchIcon}
            value={fields.leadInfo}
            placeholder={en.operatorManagement.enterNumberEmailAddress}
            muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <RangePicker
            icon={fadedCalenderIcon}
            placeholderText={en.global.selectDateRange}
            startDate={fields?.startDate ?? ""}
            endDate={fields?.endDate ?? ""}
            handleDateChange={(update: any) => {
              handleDateRange(update);
            }}
            isClearable={fields?.startDate && fields?.endDate}
            onClickOutside={(e: any) => {
              // handling if user clicks outside after selecing start date
              if (fields?.startDate && !fields.endDate) {
                setFields((prev: any) => ({
                  ...prev,
                  startDate: initialState.startDate,
                  endDate: initialState.endDate,
                }));
              }
            }}
            maxDate={new Date()}
          />
        </Grid>

        <Grid item sm={12} md={4} lg={3}>
          <CustomMultiSelect
            icon={CityInputIcon}
            placeholder={en.global.allCities}
            selectAllText={en.global.allCities}
            value={fields?.city}
            handleChange={(values: any) => {
              handleChange("city", values);
              getOperatorLeadList({
                leadInfo: fields.leadInfo,
                city: values,
                status: fields.status,
                startDate: fields?.startDate,
                endDate: fields?.endDate,
                page: 0,
                pageSize: 10,
              });
            }}
            choice={allCitiesDetails}
            defaultKey="name"
            displayNameKey="displayName"
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <Box className={styles.resetIconApprovedOperator}>
            <Tooltip title={en?.global?.resetFilters} arrow>
              <RotateRightSharp
                fontSize="large"
                color="primary"
                sx={{ cursor: "pointer", fontSize: "25px" }}
                onClick={handleReset}
              />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <DataTable
        autoHeight
        rows={operatorLeadList.leadListData}
        disableColumnMenu
        disableColumnSorting
        disableRowSelectionOnClick
        checkboxSelection={false}
        columns={columns}
        headerAlign={"center"}
        getRowId={getRowId}
        loading={loading}
        hideColumnSeperator
        rowCount={operatorLeadList?.pagination?.total || 0}
        paginationModel={fields?.paginationModelValue}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        sx={{
          width: "100%",
          "& .booking-status-cell ": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
    </Stack>
  );
};

export default OperatorManagement;
