import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";
import {
  dateWithTimeFormat,
  typographyConstants,
} from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";
import { toPascalCase } from "../../../../utils/helper";

const infoTitleStyle = {
  color: colors.secondary_light_gray,
  fontWeight: "bold",
};

const IotInfoCard = (props: any) => {
  const { handlePingDevice, pingDeviceData, loading } = props;

  return (
    <Box
      sx={{
        backgroundColor: colors.secondary_gray2,
        borderRadius: "14px",
        padding: "14px",
      }}
    >
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Typography variant={typographyConstants.SUBHEADING}>
            IOT Devices
          </Typography>
          <CustomButton
            label="Ping"
            variant={"outlined"}
            onClick={() => {
              handlePingDevice();
            }}
            loading={loading}
          />
        </Box>
      </Stack>
      {pingDeviceData?.map((data: any) => {
        return (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <Stack>
                <Typography
                  variant={typographyConstants.BODY}
                  sx={infoTitleStyle}
                >
                  IMEI Number:
                </Typography>
                <Typography variant={typographyConstants.BODY}>
                  {data.imeiNumber}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant={typographyConstants.BODY}
                  sx={infoTitleStyle}
                >
                  Status :
                </Typography>
                <Typography variant={typographyConstants.BODY}>
                  {toPascalCase(data.status) || rentalEn.global.NA}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant={typographyConstants.BODY}
                  sx={infoTitleStyle}
                >
                  Odometer Reading :
                </Typography>
                <Typography variant={typographyConstants.BODY}>
                  {data.odometerReading ?? rentalEn.global.NA}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant={typographyConstants.BODY}
                  sx={infoTitleStyle}
                >
                  Last Active :
                </Typography>
                <Typography variant={typographyConstants.BODY}>
                  {moment(data.lastActive).format(dateWithTimeFormat) ||
                    rentalEn.global.NA}
                </Typography>
              </Stack>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default IotInfoCard;
