import axios from "axios";
import { customAxios, BASE_URL, API_URLS, getApiUrl } from "../../../config";
import { generateSignedUrlDocType } from "../../constants/constants";

const getKycSignedUrlService = (
  payload: any,
  docType = generateSignedUrlDocType.KYC_DOCUMENT
) => {
  try {
    const signedPayload = {
      data: {
        attachments: payload?.filesData?.fileNames,
        // useCase: docType, // "KYC_DOCUMENT" | PROFILE_IMAGE
        type: docType,
      },
    };

    // let url = FREEDO_X_URL + Global.attachment + Global.versioning1;
    let url = `${BASE_URL}${getApiUrl().getKycSignedURL}`;
    return customAxios.post(url, signedPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const uploadDocumentService = (payload: any) => {
  try {
    const fileData = new Blob([payload?.file?.files[0]], {
      type: payload?.file?.files[0]?.type,
    });
    return axios
      .put(payload.url, fileData, {
        headers: { "Content-Type": payload?.file?.files[0]?.type },
      })
      .catch((ex) => {
        return ex;
      });
  } catch (ex) {
    return ex;
  }
};

const moveKycAttachmentToS3 = (payload: any) => {
  try {
    // send this from screen using template stored in reducer
    let requestBody = {
      data: payload.kycPayload,
    };

    let url = `${BASE_URL}${
      getApiUrl(payload.customerId).postDocumentsToS3URL
    }`;
    return customAxios.post(url, requestBody).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const moveKycAttachmentToS3OtherDoc = (payload: any) => {
  try {
    let requestBody = {
      data: payload.kycPayload,
    };
    let url = `${BASE_URL}${
      getApiUrl(payload.customerId).postDocumentsToS3URLOtherDocs
    }`;
    return customAxios.post(url, requestBody).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getKycSignedUrlService,
  uploadDocumentService,
  moveKycAttachmentToS3,
  moveKycAttachmentToS3OtherDoc,
};
