import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";
import rentalEn from "../../../../locale/rental-en.json";
import { updateBreadcrumbs } from "../../../../redux/actions/rentalCommonAction";
import { formatString } from "../../../../utils/helper";
import { BasicDetails } from "./BasicDetails";
import { OperatorConfig } from "./operatorConfig";
import OperatorDocumentUpload from "../../approvedLeads/section/operatorKYCVerify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { operationActions } from "../../../../redux/actions";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { BusinessType } from "../../../../constants/constants";

export const LeadDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  // data from operator management reducer
  const { operatorLeadDetails = {}, loading = false } = useSelector(
    (state: RootState) => state.operatorManagementReducer
  );

  const { duplicateFields = {} } = location?.state || {};
  const { operatorManagement } = rentalEn;

  //initial useeffect for lead details
  useEffect(() => {
    dispatch(operationActions.getOperatorLeadDetails({ id: params?.id }));
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OPERATOR_LEAD));
  }, []);

  // destructuring of keys
  const {
    operatorConfigurations = {},
    operationalCity = [],
    preferredCity = [],
    email = "",
    phone = "",
    businessType = "",
    name = "",
  } = operatorLeadDetails;

  //basic details
  let bookingdescriptionArr = [
    {
      displayKey: operatorManagement.mobileNumber,
      value: phone,
    },
    {
      displayKey: operatorManagement.email,
      value: email,
    },
    {
      displayKey: operatorManagement.preferredCity,
      value: preferredCity?.map((data: any, index: number) => {
        return `${data}  `;
      }),
    },
    {
      displayKey: operatorManagement.operationalCity,
      value: operationalCity?.map((data: any, index: number) => {
        return `${data?.displayName} `;
      }),
    },
    {
      displayKey: operatorManagement.businessType,
      value:
        operatorLeadDetails?.businessType === BusinessType.LLP ||
        operatorLeadDetails?.businessType === BusinessType.NGO
          ? businessType
          : formatString(businessType),
    },
    {
      displayKey: operatorManagement.revenueShare,
      value: `${operatorConfigurations?.operatorSplitShare}%`,
    },
    {
      displayKey: operatorManagement.bookingHours,
      value: duplicateFields?.slots?.displayName,
    },
  ];

  const handleClickEdit = () => {
    // navigate to edit page
    navigate(`${routesConstants.OPERATOR_MANAGEMENT_LEADS}/${params.id}`);
  };

  return (
    <Stack gap="20px">
      <BasicDetails
        bookingdescriptionArr={bookingdescriptionArr}
        operatorName={name}
        loading={loading}
        isEditIcon={true}
        handleClickEdit={handleClickEdit}
      />
      <OperatorConfig
        operatorConfig={operatorConfigurations}
        customStyle={{ pointerEvents: "none" }}
        isDisabled={true}
        title={rentalEn.operatorManagement.configureOperatorFunctionalities}
      />
      <OperatorDocumentUpload />
    </Stack>
  );
};
