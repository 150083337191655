import {
  Box,
  Button,
  Chip,
  Icon,
  CircularProgress,
  IconButton,
  Avatar,
  Badge,
} from "@mui/material";

export const CustomIconButton = (props: any) => {
  return <IconButton {...props}>{props.children}</IconButton>;
};

export const CustomLinkButton = (props: any) => {
  return;
};

// props :-
// startIcon = need to pass icon as component
// startImageSrc = need to pass image source

export const CustomButton = (props: any) => {
  const {
    label = "",
    disabled,
    loading,
    startIcon = null,
    startImageSrc = null,
    wrapperStyles = null,
    endImg = "",
    ...rest
  } = props;
  return (
    <Box sx={{ ...wrapperStyles }}>
      <Button
        startIcon={
          startIcon ? (
            startIcon
          ) : startImageSrc ? (
            <img src={startImageSrc} />
          ) : (
            ""
          )
        }
        endIcon={endImg ? <img src={endImg} alt={endImg} /> : ""}
        disabled={disabled || loading}
        {...rest}
      >
        {!props.loading ? (
          props.children || label
        ) : (
          <CircularProgress color={props.loaderColor || "inherit"} size={12} />
        )}
      </Button>
    </Box>
  );
};

// mui chip
export const CustomChip = (props: any) => {
  const {
    label = "",
    disabled = false,
    size = "small",
    color = "primary",
    customStyle = {},
    ...rest
  } = props;
  return (
    <Chip
      size={size}
      color={color}
      label={label}
      sx={{
        background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
        "&:hover": {
          background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
          color: "white",
          border: "none",
        },
        "&:active": {
          background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
          boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
          color: "white",
        },
        ...customStyle,
      }}
      disabled={disabled}
      {...rest}
    />
  );
};
