import {
  API_URLS,
  FREEDO_X_URL,
  customAxios,
  versioning,
  BASE_URL,
  getApiUrl,
} from "../../config";
import { BookingX, FreedoX } from "../constantsX/apiConstantsX";
import { CommonInterface } from "../redux/reducers/actionInterfaces";

/* const checkUserExist = (payload: CommonInterface) => {
  try {
    let url = API_URLS.checkUserExist;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
}; */

// Search whether the user existed or not -> new user search api
const checkUserExist = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().searchCustomer}${
      payload?.mobile_number
    }`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
}; 

const createManualBookingX = (payload: CommonInterface) => {
  try {
    let url = FREEDO_X_URL + FreedoX.createManualBookingX;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// new service to fetch manual booking time slots

const addUserX = (payload: CommonInterface) => {
  try {
    let url = BASE_URL + API_URLS.addUserX;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const userPaymentMandateX = (payload: CommonInterface) => {
  try {
    let url = BASE_URL + BookingX.userPaymentMandateX;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  checkUserExist,
  createManualBookingX,
  addUserX,
  userPaymentMandateX,
};
