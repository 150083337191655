import React from "react";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import rentalEn from "../../../../locale/rental-en.json";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";

const SubmitConfirmationDialog = (props: any) => {
  const {
    isOpen,
    toggleSubmitDialog,
    handleSubmitDoc,
    handleApproveRejectDoc,
  } = props;

  return (
    <ResponsiveDialog
      title={
        isOpen === "submit"
          ? rentalEn.alertMessages.submitKycDocuments
          : rentalEn.alertMessages.initiateKycDocuments
      }
      open={isOpen}
    >
      <CustomButton
        label={rentalEn?.global?.submit}
        sx={{ minWidth: "150px" }}
        variant="contained"
        onClick={isOpen === "submit" ? handleSubmitDoc : handleApproveRejectDoc}
      />
      <CustomButton
        label={rentalEn?.buttonLabels?.notNow}
        variant="outlined"
        sx={{ minWidth: "150px" }}
        onClick={() => toggleSubmitDialog("")}
      />
    </ResponsiveDialog>
  );
};

export default SubmitConfirmationDialog;
