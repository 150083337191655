import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { CustomIconButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import { typographyConstants } from "../../../../../constants/constants";
import { SearchIcon } from "../../../../../constants/exportImages";
import { hexToRgb } from "../../../../../utils/helper";
import { StyleObject } from "../StyleObject";

const CollapsibleSection = (props: any) => {
  // props destructuring
  const {
    apiResponseData,
    expand,
    searchedText,
    tableRowData,
    tableColumnData,
    paginationModel,
    getRowHeight,
    handleSearch,
    handleExpansion,
    handlePaginationModelChange,
    collapsibleSectionHeading,
    noRowsOverlayMessage,
    searchBoxPlaceholder,
    searchBoxLabel,
    totalCount,
    rowCount,
    headingColor,
    showSearchBox = true,
    onRowClick,
    getRowId,
    ...rest
  } = props;

  return (
    <Box sx={StyleObject?.collapsibleSectionWrapper}>
      <Box sx={StyleObject?.inventoryDetails_ongoingSection_box1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            variant={typographyConstants.HEADING}
            color={headingColor}
          >
            {collapsibleSectionHeading}
          </Typography>

          {totalCount > 0 && (
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: `rgba(${hexToRgb(headingColor)}, 0.16)`,
                padding: "4px 24px",
                borderRadius: "38px",
              }}
            >
              <Typography
                variant={typographyConstants.SUBHEADING}
                color={headingColor}
              >
                {totalCount}
              </Typography>
            </Box>
          )}
        </Stack>
        <CustomIconButton
          children={
            expand ? (
              <KeyboardArrowUpIcon fontSize="inherit" color="primary" />
            ) : (
              <ExpandMoreIcon fontSize="inherit" color="primary" />
            )
          }
          onClick={handleExpansion}
        />
      </Box>
      <Collapse
        mountOnEnter
        unmountOnExit
        orientation="vertical"
        timeout={"auto"}
        in={expand}
        sx={StyleObject?.collapseStyle}
      >
        {showSearchBox && (
          <Input
            placeholder={searchBoxPlaceholder}
            variant="outlined"
            label={searchBoxLabel}
            value={searchedText ?? ""}
            iconStart={SearchIcon}
            inputProps={{ maxLength: 50 }}
            sx={{ my: 1.5 }}
            muiTextFieldRootStyle={{ maxWidth: 350 }}
            onChange={handleSearch}
            disabled={!isArrayNotEmpty(tableRowData) && !searchedText}
          />
        )}
        <DataTable
          rows={tableRowData}
          columns={tableColumnData}
          rowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          paginationMode="server"
          hideFooter={!isArrayNotEmpty(tableRowData)}
          getRowHeight={getRowHeight}
          noRowsOverlayMessage={noRowsOverlayMessage}
          onRowClick={onRowClick}
          getRowId={getRowId}
        />
      </Collapse>
    </Box>
  );
};

export default CollapsibleSection;
