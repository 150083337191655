import { BASE_URL, customAxios, getApiUrl } from "../../../config";
import {
  GenericObject,
  LOB_TYPES,
  ReasonCategory,
} from "../../constants/constants";
import { constructUrl } from "../../utils/helper";

//get booking details
const getBookingDetails = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload?.bookingId).getBookingDetails;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

const getBookingStats = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().bookingStats;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

const getBookingList = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().bookingList;
    const apiUrlWithParams: any = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (err) {
    return err;
  }
};

//get odometer history
const getOdometerHistory = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload).getVehicleOdometerHistory;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

//get signed urls for document upload
const getDocsSignedUrls = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().generateSignedUrls;
    return customAxios.post(url, payload.requestdata);
  } catch (err) {
    return err;
  }
};

//upload booking documents
const uploadBookingDocuments = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.id).uploadBookingDocuments;
    return customAxios.post(url, payload.data);
  } catch (err) {
    return err;
  }
};

//get booking documents
const getBookingDocuments = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload).uploadBookingDocuments;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

//delete booking documents
const deleteBookingDocument = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).uploadBookingDocuments;
    return customAxios.delete(url, payload);
  } catch (err) {
    return err;
  }
};

//cancel booking
const cancelBooking = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).cancelBooking;
    return customAxios.delete(url, payload);
  } catch (err) {
    return err;
  }
};

// get plans and addons deatils service
const getPlanAndAddon = (payload: string) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload).getPlansAddonDetails;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// service to get modification history data
const getModificationHistory = (payload: string) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload).getModificationHistory;
    return customAxios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// get available vehicles
const getAvailableVehicles = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).getAvailableVehicles;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// check delay reasons
const checkStartRideDelayReasons = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().checkDelay;
    const apiUrlWithParams: any = constructUrl(url, payload);

    return customAxios.get(apiUrlWithParams);
  } catch (err) {
    return err;
  }
};

// assign a vehicle to a booking
const assignVehicle = (payload: any) => {
  try {
    const { data } = payload;
    const url = BASE_URL + getApiUrl(payload?.bookingId).assignVehicle;
    return customAxios.post(url, { data });
  } catch (err) {
    return err;
  }
};

// start ride for the booking
const startRide = (payload: any) => {
  try {
    const { data } = payload;
    const url = BASE_URL + getApiUrl(payload.bookingId).startRide;
    return customAxios.post(url, { data });
  } catch (err) {
    return err;
  }
};

const getAdditionalChargesService = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).getAdditionalChargesURL;
    const constructedURL = constructUrl(url, payload?.queryParamList);
    return customAxios.get(constructedURL);
  } catch (err) {
    return err;
  }
};

//get signed urls for vehicle images
const getVehicleSignedUrls = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().generateVehicleSignedUrl;
    return customAxios.post(url, payload.requestdata);
  } catch (err) {
    return err;
  }
};

const saveAdditionalChargesService = (payload: any) => {
  try {
    const chargesPayload = {
      data: {
        charges: payload?.charges,
      },
    };
    const url =
      BASE_URL + getApiUrl(payload.bookingId).saveAdditionalChargesURL;
    return customAxios.post(url, chargesPayload);
  } catch (err) {
    return err;
  }
};

//upload vehicle images
const uploadVehicleImages = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.id).uploadVehicleImages;
    return customAxios.post(url, payload.data);
  } catch (err) {
    return err;
  }
};

const updateVehicleImages = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.id).updateVehicleImages;
    return customAxios.put(url, payload.data);
  } catch (err) {
    return err;
  }
};

const getOperatorConfigByBranchService = (payload: any) => {
  try {
    const url =
      BASE_URL +
      getApiUrl().getOperatorConfigByBranchURL +
      "/" +
      payload.branch +
      "/?lob=" +
      LOB_TYPES.LTR;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

//get vehicle images
const getBookingVehicleImages = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload).uploadBookingDocuments;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// service to get payments overview list data
const getPaymentOverviewList = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).getPaymentOverviewList;
    const constructedURL = constructUrl(url, payload?.queryParamList);
    return customAxios.get(constructedURL).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// service to get extension availability information
const getExtensionAvailability = (payload: any) => {
  try {
    const url =
      BASE_URL + getApiUrl(payload.bookingId).getExtensionAvailability;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//get pending charges on odometer reading
const getPendingCharges = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).getPendingCharges;

    let constructedURL = "";

    if (
      payload?.queryParamList &&
      Object.keys(payload?.queryParamList).length
    ) {
      constructedURL = constructUrl(url, payload?.queryParamList);
    } else {
      constructedURL = url;
    }
    return customAxios.get(constructedURL);
  } catch (error) {
    return error;
  }
};

//end ongoing ride
const endRide = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).endRide;
    return customAxios.put(url, payload?.data);
  } catch (error) {
    return error;
  }
};

//complete ride
export const completeRide = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).completeRide;
    return customAxios.put(url);
  } catch (error) {
    return error;
  }
};

//complete ride payment
export const completeRidePayment = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).completeRidePayment;
    return customAxios.put(url);
  } catch (error) {
    return error;
  }
};

const generateOrderIdRevamp = (payload: any) => {
  try {
    const url =
      BASE_URL + getApiUrl(payload?.bookingId).createOrderIdForChargesURL;

    let orderPayload = {
      data: {},
    };

    if (payload?.additionalChargesIds) {
      orderPayload.data = {
        charges: payload?.additionalChargesIds,
      };
    }

    if (payload?.schedules) {
      orderPayload.data = {
        schedules: payload?.schedules,
      };
    }
    let constructedURL = url;
    if (payload.queryParamList) {
      constructedURL = constructUrl(url, payload?.queryParamList);
    }
    return customAxios.post(constructedURL, orderPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const generatePaymentLinkQRForOrder = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().initiatePaymentForOrder;

    const paymentPayload = { data: payload.data };
    return customAxios.post(url, paymentPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const waiveOffBookingChargesService = (payload: any) => {
  try {
    // {{local_url}}/freedo-ltr/booking/v1/1HU6UJL1G4JK/charges
    const url =
      BASE_URL + getApiUrl(payload?.bookingId).waiveOffBookingChargesURL;
    const paymentPayload = { data: payload.apiPayload.data };
    return customAxios.put(url, paymentPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const waiveOffReasonsService = (payload: any) => {
  try {
    const url =
      BASE_URL + getApiUrl().waiveOffReasonsURL + "/WAIVE_OFF_REASONS";
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get extensions plans details
export const getExtensionPlansDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL +
      getApiUrl(payload?.bookingId).getExtensionPlans +
      `/${payload?.endDate}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

// get extension charges calculated
export const getCalculatedExtensionCharges = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.bookingId).getCalculatedExtensionCharges;
    return customAxios.post(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// get recent modification history details
export const recentModificationsHistory = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.bookingId).getRecentModificationsHistory;
    return customAxios.get(url).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

// create booking extensions
export const createBookingExtension = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.bookingId).createBookingExtension;
    return customAxios.post(url, payload?.apiBody).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

export const getVehicleExtensionDetails = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload?.bookingId).getVehicleExtension;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// ----------Modify Booking Details --------------------
// calculate address charges on modify booking
const calculateAddressCharges = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).calculateAddressCharges;
    return customAxios.post(url, { data: payload.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// calculate model modification charges on modify booking
export const calculateModelModificationCharges = (payload: any) => {
  try {
    const url =
      BASE_URL + getApiUrl(payload.bookingId).calculateModelModificationCharges;
    return customAxios.post(url, { data: payload.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// calculate PLan modification charges on modify booking
const calculatePlanModificationCharges = (payload: any) => {
  try {
    const url =
      BASE_URL + getApiUrl(payload.bookingId).calculatePlanModificationCharges;
    return customAxios.post(url, { data: payload.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// calculate modify booking plan
const modifyBookingPlan = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).modifyBookingPlan;
    return customAxios.post(url, { data: payload.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// modify address addons
export const modifyAddressAddon = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).modifyAddressAddon;
    return customAxios.post(url, { data: payload.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// modify booking model in modify booking
const modifyBookingModel = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).modifyBookingModel;
    return customAxios.post(url, { data: payload.data.data }).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// get available vehicles registration no.
const availableBookingModels = (payload: any) => {
  try {
    const { bookingId, modelName } = payload;
    const url =
      BASE_URL + getApiUrl(bookingId, modelName).availableBookingModels;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const unassignVehicle = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.bookingId).unassignVehcile;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

//get modification reasons
const getModificationReasons = (payload: string) => {
  try {
    let url =
      BASE_URL +
      getApiUrl(payload || ReasonCategory.MODEL_MODIFICATION)
        .getModificationReasons;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};
// ----------

// delete booking extension
const deleteBookingExtension = (payload: GenericObject) => {
  try {
    const url =
      BASE_URL +
      getApiUrl(payload.bookingId).deleteBookingExtension +
      `${payload?.extensionId}`;
    return customAxios.delete(url).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

//get customer booking stats for dashboard v3
const getCustomerBookingStatsV3 = (payload: GenericObject) => {
  try {
    const url = BASE_URL + getApiUrl().getCustomerBookingsStatsV3;
    const constructedURL = constructUrl(url, payload);
    return customAxios.get(constructedURL).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

//service to delete booking modification
const deleteBookingModification = (payload: GenericObject) => {
  try {
    const url =
      BASE_URL + getApiUrl(payload?.bookingId).deleteBookingModification;
    return customAxios.delete(url).catch((ex) => ex);
  } catch (error) {
    return error;
  }
};

export default {
  getBookingDetails,
  getOdometerHistory,
  getDocsSignedUrls,
  uploadBookingDocuments,
  getBookingDocuments,
  deleteBookingDocument,
  cancelBooking,
  getBookingList,
  getBookingStats,
  getPlanAndAddon,
  getModificationHistory,
  getAvailableVehicles,
  assignVehicle,
  checkStartRideDelayReasons,
  startRide,
  getAdditionalChargesService,
  saveAdditionalChargesService,
  getOperatorConfigByBranchService,
  getVehicleSignedUrls,
  uploadVehicleImages,
  updateVehicleImages,
  getBookingVehicleImages,
  getPaymentOverviewList,
  getExtensionAvailability,
  getPendingCharges,
  endRide,
  completeRide,
  completeRidePayment,
  generateOrderIdRevamp,
  generatePaymentLinkQRForOrder,
  waiveOffBookingChargesService,
  waiveOffReasonsService,
  getExtensionPlansDetails,
  getCalculatedExtensionCharges,
  recentModificationsHistory,
  createBookingExtension,
  getVehicleExtensionDetails,
  calculateAddressCharges,
  modifyBookingModel,
  modifyAddressAddon,
  calculatePlanModificationCharges,
  getModificationReasons,
  modifyBookingPlan,
  calculateModelModificationCharges,
  unassignVehicle,
  deleteBookingExtension,
  availableBookingModels,
  getCustomerBookingStatsV3,
  deleteBookingModification,
};
