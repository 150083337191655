// This is the css style objects that will be provided to "sx" prop of the mui components.

export const StyleObject: any = {
  muiTextFieldRootStyle: {
    width: 1,
    minWidth: "unset",
    minHeight: "40px",
    height: "40px",
    maxHeight: "40px",
  },
};
