import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import en from "../../../../locale/en.json";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import { checkPhone } from "../../../../utils/regex";
import { MobileIcon } from "../../../constants/exportImages";
import { searchCustomerAction } from "../../../redux/actions/customerManagementActions";
import { validate } from "../../../utils/helper";

const SearchCustomer = ({searchLoader}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorInitialState = {
    mobileNumber: "",
  };
  const [searchedMobNo, setSearchedMobNo] = useState("");
  const [errorFields, setErrorFields] = useState(errorInitialState);

  // Handle each field change
  const genericFieldChangeHandler = useCallback((field: string, value: any) => {
    if (field === "mobileNumber") {
      const isValid = checkPhone(value);
      if (!isValid && value !== "") return false; // Accepting only number Or empty string
      if (value.length > 10) return false;
    }
    setSearchedMobNo(() => value);
  }, []);

  // search click handler
  const handleSearchUserClick = () => {
    let payloadObj = {
      mobileNumber: searchedMobNo,
    };
    // validate form
    const validatedFields = validate(payloadObj, errorInitialState);
    setErrorFields({ ...errorInitialState, ...validatedFields.errorFields });
    if (validatedFields.error === false) {
      dispatch(
        searchCustomerAction({
          mobile_number: searchedMobNo,
          navigate,
        })
      );
    }
  };

  return (
    <form
      onSubmit={(event: any) => {
        event.preventDefault();
        handleSearchUserClick();
      }}
    >
      <Stack
        className="SearchUser"
        direction="row"
        gap="2vw"
        alignItems="center"
      >
        <Input
          iconStart={MobileIcon}
          value={searchedMobNo}
          placeholder={en.CustomerManagement.SearchCustomerPlaceholder}
          label={en.CustomerManagement.SearchCustomerLabel}
          errormessage={errorFields.mobileNumber && errorFields.mobileNumber}
          width="20vw"
          onChange={(e: any) =>
            genericFieldChangeHandler("mobileNumber", e.target.value)
          }
        />
        <CustomButton
          label={"Search"}
          disabled={!(searchedMobNo.length === 10) || searchLoader}
          loading={searchLoader}
          variant="outlined"
          onClick={(event: any) => {
            event.preventDefault();
            handleSearchUserClick();
          }}
        />
      </Stack>
    </form>
  );
};

export default SearchCustomer;
