import { forwardRef, useState } from "react";
import {
  Grid,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
  makeStyles,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";

import "./components.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { colors } from "../themes/colors";

const Input1 = forwardRef((props: any, ref) => {
  const [hasFocus, setFocus] = useState<boolean>(false);
  return (
    <>
      <div
        className={`input-container ${props.errormessage ? "input-error" : ""}`}
      >
        <FontAwesomeIcon icon={props.icon} />
        <TextField
          className={`input ${hasFocus ? "input-focus" : ""}`}
          variant="standard"
          fullWidth
          size={props.size || "medium"}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          InputProps={{
            disableUnderline: true,
          }}
          inputRef={ref}
          {...props}
        />
      </div>
      {props.errormessage && (
        <Typography className="error-message">{props.errormessage}</Typography>
      )}
    </>
  );
});

const Input2 = forwardRef((props: any, ref) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [hasFocus, setFocus] = useState<boolean>(false);
  return (
    <div
      className={`input-wrapper ${props.containerClass}`}
      style={{ width: props.width || 320 }}
    >
      {props.placeholder && (
        <Typography
          style={{
            margin: props.placeholderMargin,
            fontFamily: "EuclidCircularB-Regular",
            color: "rgb(123, 130, 137)",
            fontSize: isMobile ? "10px" : "12px",
            paddingBottom: "2px",
          }}
          variant="body2"
        >
          {props.placeholder || props.heading}
          {props.required && (
            <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
          )}
          {props.isOptional && (
            <text style={{ color: colors.optional, fontSize: "11px", paddingLeft: "3px" }}>(Optional)</text>
          )}
        </Typography>
      )}
      <Grid
        container
        className={`input-container ${props.errormessage ? "input-error" : ""}`}
        style={{ ...props.containerStyle, width: props.width || 320 }}
      >
        <TextField
          className={`input-fix`} //${hasFocus ? 'input-focus' : ''}
          variant="standard"
          value={props.value}
          defaultValue={props.defaultValue || ""}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          disabled={props.disabled || false}
          sx={{
            width: props.width || 320,
            fontFamily: "EuclidCircularB-Regular",
            fontSize: "12px",
            height: "33px",
            color: "rgb(36, 43, 53)",
          }}
          fullWidth
          InputProps={{
            style: {
              fontFamily: "EuclidCircularB-Regular",
              fontSize: "12px",
              height: "33px",
              color: "rgb(36, 43, 53)",
            },
            disableUnderline: true,
            readOnly: props.readOnly === true ? true : false,
          }}
          inputRef={ref}
          {...props}
          autoComplete="new-password"
        >
          {props.defaultKey || props.heading ? (
            <MenuItem
              style={{ fontFamily: "EuclidCircularB-Regular" }}
              value={props.heading}
              onClick={() =>
                props.handleChange({ id: 0, [props.defaultKey]: props.heading })
              }
              disabled={true}
            >
              <em>{props.heading}</em>
            </MenuItem>
          ) : null}
          {props.choice?.map((data: any, id: number) => {
            return (  
              <MenuItem
                onClick={() => props.handleChange(data)}
                style={{ fontFamily: "EuclidCircularB-Regular" }}
                // key={props.defaultKey ? data["id"] : id}
                value={props.defaultKey ? _.get(data, props.defaultKey) : data}
              >
                {props.defaultKey
                  ? _.get(data, props.defaultKey) +
                    (props.multipleRenderKeys
                      ? (props?.seprator ?? ",") +
                        props.multipleRenderKeys.map(
                          (each: any) => data[each]
                        ) +
                        (props?.postOperator ?? "")
                      : props.customRenderKey
                      ? " (₹" + data[props.customRenderKey] + ")"
                      : "")
                  : data}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      {props.errormessage && (
        <Typography className="error-message" style={{ fontSize: 14 }}>
          {props.errormessage}
        </Typography>
      )}
    </div>
  );
});

const Input3 = forwardRef((props: any, ref) => {
  // const [hasFocus, setFocus] = useState<boolean>(false);
  return (
    <div
      style={{ marginTop: props.marginTop, height: props.height }}
      className=""
    >
      {props.customLabel ? (
        <Typography
          style={{
            fontFamily: "EuclidCircularB-Regular",
            color: "rgb(123, 130, 137)",
            marginLeft: "3%",
            fontSize: "12px",
          }}
          variant="body2"
        >
          {props.customLabel}
          {props.required && (
            <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
          )}
        </Typography>
      ) : null}
      <TextField
        style={{ margin: props.margin || "7px" }}
        // onFocus={() => setFocus(true)}
        // autoFocus={true}
        // onBlur={() => setFocus(false)}
        sx={{
          width: props.width || 230,
          // backgroundColor: colors.light_gray,
          height: props.height,
        }}
        autoComplete={"off"}
        className="inputRounded"
        variant={props.variant}
        value={props.value}
        size="small"
        InputProps={{
          // style:{fontFamily:'EuclidCircularB-Regular',fontSize:'12px',height:'33px',color:'rgb(36, 43, 53)'},
          readOnly: props.readOnly === true ? true : false,
          endAdornment: (
            <InputAdornment position="end">
              {props.searchIcon ? (
                <SearchIcon
                  onClick={props.handleSearchClick}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                ""
              )}
            </InputAdornment>
          ),
        }}
        inputRef={ref}
        {...props}
      />
      {props.errormessage && (
        <Typography className="error-message" style={{ fontSize: 14 }}>
          {props.errormessage}
        </Typography>
      )}
    </div>
  );
});

const Input4 = forwardRef((props: any, ref) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [hasFocus, setFocus] = useState<boolean>(false);
  return (
    <div
      className={`input-wrapper ${props.containerClass}`}
      style={{ width: props.width || 320 }}
    >
      <Typography
        style={{
          margin: props.placeholderMargin,
          fontFamily: "EuclidCircularB-Regular",
          color: "rgb(123, 130, 137)",
          fontSize: isMobile ? "10px" : "12px",
          paddingBottom: "2px",
        }}
        variant="body2"
      >
        {props.placeholder}
        {props.required && (
          <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
        )}
      </Typography>
      <TextareaAutosize
        minRows={props.minRows || 10}
        maxRows={props.maxRows || 40}
        readOnly={props.readOnly}
        aria-label="maximum height"
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        style={{
          resize: "none",
          backgroundColor: "rgb(242, 242, 242)",
          minWidth: props.width || "320px",
          marginRight: "50px",
          borderRadius: props.borderRadius,
          border: props.errormessage ? "1px solid rgb(244, 8, 8)" : "none",
        }}
        InputProps={{
          style: {
            fontFamily: "EuclidCircularB-Regular",
            fontSize: "12px",
            height: "33px",
            color: "rgb(36, 43, 53)",
          },
          disableUnderline: true,

          readOnly: props.readOnly === true ? true : false,
        }}
        {...props}
      />
      {props.errormessage && (
        <Typography className="error-message" style={{ fontSize: 14 }}>
          {props.errormessage}
        </Typography>
      )}
    </div>
  );
});

const Input5 = forwardRef((props: any, ref) => {
  const [hasFocus, setFocus] = useState<boolean>(false);

  return (
    <div
      style={{
        marginTop: props.marginTop,
        width: "90%",
      }}
    >
      <div
        style={{
          display: "flex",
          borderRadius: "20px",
          width: "100%",
          alignItems: "center",
          border: "1px solid #D3F2F2",
          backgroundColor: "#F3F8F8",
          boxShadow: "0px 6px 12px #2BC7C926",
          padding: "0px 10px"
        }}
      >
        {hasFocus ? null : (
          <div
            style={{
              width: "6%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: "#F3F8F8",
            }}
          >
            <FontAwesomeIcon
              icon={faSearch}
              color={colors.gray}
              style={{ width: "20px", height: "auto" }}
            />
          </div>
        )}
        <div style={{ width: hasFocus ? "100%" : "94%" }}>
          <TextField
            style={{ margin: props.margin || "7px" }}
            onFocus={() => setFocus(true)}
            autoFocus={false}
            onBlur={() => setFocus(false)}
            autoComplete={"off"}
            sx={{
              width: props.width || 230,
              height: 32,
              justifyContent: "center",

              backgroundColor: "#F3F8F8",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
            // className="inputRounded"
            variant="outlined"
            // variant={props.variant}
            value={props.value}
            size="small"
            InputProps={{
              // style:{fontFamily:'EuclidCircularB-Regular',fontSize:'12px',height:'33px',color:'rgb(36, 43, 53)'},
              readOnly: props.readOnly === true ? true : false,
              disableUnderline: true,
            }}
            inputRef={ref}
            {...props}
          />
        </div>
      </div>
      {props.errormessage && (
        <Typography
          variant="subtitle2"
          className="error-message"
          style={{ marginTop: "3px", marginLeft: "12px" }}
        >
          {props.errormessage}
        </Typography>
      )}
    </div>
  );
});


export { Input1, Input2, Input3, Input4, Input5 };
