import dashboardReducerX from "./dashboardReducersX";
import manualBookingX from "./manualBookingReducerX";
import rentalPlansReducerX from "./rentalPlansReducerX";
import bookingManagementReducersX from "./bookingManagementReducersX";
import bookingDetailsReducerX from "./bookingDetails"
import YOR_bookingDetailsReducer from "./YOR_BookingDetailsReducer";
import bookingListFiltersReducer from "./bookingListFiltersReducers";
import commonReducerX from "./commonReducerX";
export const freedoXReducers = {
  manualBookingX,
  dashboardReducerX,
  rentalPlansReducerX,
  bookingManagementReducersX,
  bookingDetailsReducerX,
  YOR_bookingDetailsReducer,
  bookingListFiltersReducer,
  commonReducerX,
};
