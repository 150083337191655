import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { GOOGLE_MAPS_API_KEY } from "../../config";
import en from "../../locale/en.json";
import {
  addressType,
  typographyConstants,
} from "../../rentals-src/constants/constants";
import rentalEn from "../../rentals-src/locale/rental-en.json";
import { manualBookingActions } from "../../rentals-src/redux/actions";
import { validateOnly } from "../../rentals-src/utils/helper";
import { checkAlfa, checkNumeric } from "../../rentals-src/utils/regex";
import { CustomButton } from "../atoms/Buttons/CustomButtons";
import Input from "../atoms/InputFields/Input";
import Styles from "../components.module.css";

const initialState = {
  id: null,
  customerName: "",
  address: "",
  landmark: "",
  zipcode: "",
  city: "",
  latitude: 0,
  longitude: 0,
  saveAs: "",
  isDefaultAddress: true,
  formattedAddress: "",
  directionsToReach: "",
  area: "",
  houseBlockNo: "",
};

const errorInitialState = {
  id: null,
  customerName: "",
  address: "",
  landmark: "",
  zipcode: "",
  city: "",
  latitude: "",
  longitude: "",
  saveAs: "",
  isDefaultAddress: "",
  formattedAddress: "",
  directionsToReach: "",
  area: "",
  houseBlockNo: "",
};

const HomeDeliveryModal = ({
  openModal,
  handleModalClose,
  setOpenModal,
  userDetails,
  modelDetails,
}: any): JSX.Element => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
  };

  // by default lat long for map
  const gurugramLatLong = {
    lat: 28.457523,
    lng: 77.026344,
  };

  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [stateError, setStateError] = useState(errorInitialState);
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat:
      modelDetails?.availability?.[0]?.branchDetails?.latitude ||
      gurugramLatLong?.lat,
    lng:
      modelDetails?.availability?.[0]?.branchDetails?.longitude ||
      gurugramLatLong?.lng,
  });
  const [showZipCode, setShowZipCode] = useState(false);

  // const ScriptLoaded = useLoadGoogleMaps(GOOGLE_MAPS_API_KEY);

  const handleChange = (field: string, value: any) => {
    setState((prevState: any) => ({ ...prevState, [field]: value }));
    setStateError((prevState: any) => ({ ...prevState, [field]: "" }));
    if (field === "customerName") {
      if (value.length < 3) {
        setStateError((prevState: any) => ({
          ...prevState,
          [field]: rentalEn?.errorMessages?.MustBeGreaterThan3,
        }));
      }
    }
  };

  const landmark = () => {
    return (
      <>
        <Input
          value={state.customerName}
          placeholder={rentalEn?.global.enterName}
          errormessage={stateError.customerName}
          onChange={(e: any) => {
            e.preventDefault();
            checkAlfa(e.target.value) &&
              e.target.value.length < 65 &&
              handleChange("customerName", e.target.value);
          }}
        />
        <Input
          value={state.houseBlockNo}
          placeholder={rentalEn.manualBooking.houseFlatBlock}
          errormessage={stateError.houseBlockNo}
          onChange={(e: any) => {
            e.preventDefault();
            handleChange("houseBlockNo", e.target.value);
          }}
        />
        <Input
          value={state.area}
          placeholder={rentalEn.manualBooking.apartmentRoadArea}
          errormessage={stateError.area}
          onChange={(e: any) => {
            e.preventDefault();
            handleChange("area", e.target.value);
          }}
        />
        <Input
          value={state.directionsToReach}
          placeholder={rentalEn.manualBooking.landmark}
          errormessage={stateError.directionsToReach}
          onChange={(e: any) => {
            e.preventDefault();
            handleChange("directionsToReach", e.target.value);
          }}
        />
        {showZipCode && (
          <Input
            value={state.zipcode}
            placeholder={rentalEn.manualBooking.enterZipcode}
            errormessage={stateError.zipcode}
            onChange={(e: any) => {
              e.preventDefault();
              checkNumeric(e.target.value) &&
                e.target.value.length <= 6 &&
                handleChange("zipcode", e.target.value);
            }}
          />
        )}
      </>
    );
  };

  const handleSelectAddressType = (value: any) => {
    setSelectedAddressType((prevSelected) =>
      prevSelected === value ? null : value
    );

    setState((prevState: any) => ({
      ...prevState,
      saveAs: value,
    }));
    setStateError((prevState: any) => ({
      ...prevState,
      saveAs: "",
    }));
  };

  const onMapClick = (event: any) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: parseFloat(newLat), lng: parseFloat(newLng) });
    getAddress(newLat, newLng);
  };

  const getAddress = (lat: any, lng: any) => {
    if (!window.google) {
      console.log("Loading");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results: any, status) => {
      if (status === "OK") {
        setMarkerPosition({ lat: lat, lng: lng });
        setState((prev: any) => ({
          ...prev,
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
          zipcode:
            results[0]?.address_components.find((addressComponent: any) =>
              addressComponent.types.includes("postal_code")
            )?.short_name ?? "",
          city: results[0]?.address_components.find((addressComponent: any) =>
            addressComponent.types.includes("administrative_area_level_3")
          )?.short_name,
          formattedAddress: results[0]?.formatted_address,
        }));
        if (
          !results[0]?.address_components.find((addressComponent: any) =>
            addressComponent.types.includes("postal_code")
          )?.short_name
        ) {
          setShowZipCode(true);
        }
      } else {
        console.log(`Geocoder failed due to: ${status}`);
      }
    });
  };

  const handleCustomerSaveAddress = () => {
    let error = false;

    if (!state?.saveAs) {
      toastr.warning(
        rentalEn?.toastTypes?.alert,
        rentalEn?.manualBooking?.pleaseSelectAddressTypebelow
      );
    }

    let optionalArr = showZipCode
      ? ["customerName", "houseBlockNo", "area", "saveAs", "zipcode"]
      : ["customerName", "houseBlockNo", "area", "saveAs"];

    const validatedFields = validateOnly(state, stateError, optionalArr);

    if (validatedFields.error) {
      error = true;
      setStateError({ ...stateError, ...validatedFields.errorFields });
      return error;
    }
    setOpenModal(false);

    const payload = {
      data: {
        type: state?.saveAs,
        addressLine1: state?.houseBlockNo,
        addressLine2: state?.area,
        landmark: state?.directionsToReach,
        customerId: userDetails?.id,
        pinCode: state?.zipcode,
        name: state?.customerName,
        default: state?.isDefaultAddress,
        locationData: {
          coordinates: {
            lng: state?.longitude,
            lat: state?.latitude,
          },
        },
      },
    };

    dispatch(manualBookingActions.addCustomerAddress(payload));
    return error;
  };

  useEffect(() => {
    if (openModal) {
      const initialLat =
        modelDetails?.availability?.[0]?.branchDetails?.latitude;
      const initialLng =
        modelDetails?.availability?.[0]?.branchDetails?.longitude;
      setMarkerPosition({
        lat: parseFloat(initialLat),
        lng: parseFloat(initialLng),
      });
      getAddress(initialLat, initialLng);
      // Reset state when modal is opened
      setState(initialState);
      setSelectedAddressType(null);
    }
  }, [openModal, modelDetails]);

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <>
        <Box sx={{ ...style }}>
          <GoogleMap
            mapContainerStyle={{
              height: "180px",
              width: "100%",
              borderRadius: "2rem 2rem 0rem 0rem",
            }}
            center={markerPosition}
            onClick={onMapClick}
            zoom={15}
          >
            <Marker
              position={markerPosition}
              draggable={true}
              onDragEnd={(e: any) => {
                const newLat = e?.latLng?.lat();
                const newLng = e?.latLng?.lng();
                setMarkerPosition({
                  lat: parseFloat(newLat),
                  lng: parseFloat(newLng),
                });
                getAddress(newLat, newLng);
              }}
            />
          </GoogleMap>

          <Box
            sx={{
              overflowY: "scroll",
              maxHeight: "68vh",
              scrollBehavior: "smooth",
              "::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            <Stack
              sx={{
                padding: "0px 20px 20px 20px",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={typographyConstants.HEADING}
                  padding={"16px 8px 0px 0px"}
                >
                  {en.NewManualBooking.AddDeliveryDetails}
                </Typography>
                {/* <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton> */}
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <ReactGoogleAutocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  className={Styles.autoCompleteInput}
                  options={{
                    types: ["(regions)"],
                  }}
                  placeholder={rentalEn.global.selectLocation}
                  value={state.formattedAddress}
                  onChange={(e: any) => {
                    setState((prev: any) => ({
                      ...prev,
                      formattedAddress: e.target.value,
                      zipcode: "",
                      houseBlockNo: "",
                      area: "",
                      directionsToReach: "",
                    }));
                    setShowZipCode(false);
                  }}
                  onPlaceSelected={(place: any) => {
                    if (place?.geometry?.location)
                      getAddress(
                        place?.geometry?.location?.lat(),
                        place?.geometry?.location?.lng()
                      );
                  }}
                />

                {landmark()}

                {/* Type of Address */}
                <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                  {addressType.map((item: any) => {
                    return (
                      <Chip
                        icon={
                          selectedAddressType === item.key
                            ? item.selectedIcon
                            : item.icon
                        }
                        key={item.key}
                        sx={{ width: "90px" }}
                        label={item.label}
                        color={
                          selectedAddressType === item.key
                            ? "primary"
                            : "default"
                        }
                        onClick={() => handleSelectAddressType(item.key)}
                      />
                    );
                  })}
                </Box>
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    sx={{ padding: "0px 10px" }}
                    defaultChecked
                  />
                }
                label={rentalEn.global.setAsDefault}
                onChange={(e: any) =>
                  handleChange("isDefaultAddress", e.target.checked)
                }
              />

              <CustomButton
                label={en.NewManualBooking.SaveAndProceed}
                variant="outlined"
                sx={{ width: "100%" }}
                disabled={false}
                onClick={handleCustomerSaveAddress}
              />
            </Stack>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default HomeDeliveryModal;
