import { Box, Skeleton, Stack } from "@mui/material";

const SubscriptionLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "0px 14px 8px 14px",
        justifyContent: "space-between",
        borderBottom: "1px dashed #ABABAB",
      }}
    >
      <Stack>
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width="150px"
            height="35px"
          />
        </Box>
      </Stack>
      <Stack alignItems="end">
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="60px"
          height="35px"
        />
      </Stack>
    </Box>
  );
};

const TotalChargeLoader = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          border: " 1px dashed #1BD8DA",
          padding: "5px 16px 5px 16px",
          borderRadius: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="150px"
          height="40px"
        />
        <Box>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width="150px"
            height="40px"
          />
        </Box>
      </Box>
    </Box>
  );
};
export { SubscriptionLoader, TotalChargeLoader };
