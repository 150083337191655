import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Tooltip } from "@mui/material";
import { GenericObject } from "../../rentals-src/constants/constants";
import en from "../../rentals-src/locale/rental-en.json";

interface ResetIcon {
  loading?: boolean;
  customStyle?: GenericObject;
  onClick?: Function;
  tooltipTitle?: string;
}
export const CustomResetIcon = (props: ResetIcon) => {
  const {
    loading,
    customStyle = {},
    onClick = () => {},
    tooltipTitle = "",
  } = props;
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : en.global.resetFilters} arrow>
      <Box onClick={() => onClick()}>
        <RestartAltIcon
          sx={{
            cursor: "pointer",
            animation: loading ? "spin 1s linear infinite" : "",
            ...customStyle,
          }}
        />
      </Box>
    </Tooltip>
  );
};
