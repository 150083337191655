import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomModal from "../../../../../sharedComponents/molecules/Modals/CustomModal";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import rentalEn from "../../../../locale/rental-en.json";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { typographyConstants } from "../../../../constants/constants";
import styles from "../index.module.css";

const SendAggrementModal = (props: any) => {
  const { isOpen, handleClose, onClickSendAggrement, loading, aggreementData } =
    props;

  // data from operator management reducer
  const { agreement } = useSelector(
    (state: RootState) => state.operatorManagementReducer
  );

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={rentalEn.operatorManagement.operatorAgreement}
      containerStyle={{
        top: "52%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          className={styles.generateAgreementContent}
          gap="12px"
          alignItems="center"
          padding="20px"
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                aggreementData?.generatedAgreement ||
                agreement?.generatedAgreement,
            }}
          />

          {onClickSendAggrement ? (
            <Box className={styles.sendAgreementButton}>
              <CustomButton
                label={rentalEn.operatorManagement.sendAggrement}
                variant="contained"
                onClick={onClickSendAggrement}
                loading={loading}
              />
            </Box>
          ) : null}
        </Stack>
      </Box>
    </CustomModal>
  );
};

export default SendAggrementModal;
