import { Stack, Tooltip, Typography, debounce } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../../../redux/store";
import { CustomButton } from "../../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../../sharedComponents/atoms/InputFields/Input";
import { DataTable } from "../../../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../../../themes/colors";
import { BREADCRUMBS_RENTALS } from "../../../../../../constants/activeModule";
import {
  TransferRequestStatusDisplayName,
  typographyConstants,
} from "../../../../../../constants/constants";
import rentalEn from "../../../../../../locale/rental-en.json";
import { vehicleActions } from "../../../../../../redux/actions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../../../utils/RoutesConstants";
import { istToNormalDate, toPascalCase } from "../../../../../../utils/helper";
import { TranferVehicleDetailModal } from "./TranferVehicleDetailModal";
import RbackHelper from "../../../../../../../utils/helperRBAC";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../../../constants/RBACModuleEnums";

const { isAccessRightsProvided } = RbackHelper;

interface initialState {
  searchByRequestId: String;
  paginationModel: { page: String | number; pageSize: String | number };
  selectedRowId: String | number;
}

const initialState: initialState = {
  searchByRequestId: "",
  paginationModel: { page: 0, pageSize: 10 },
  selectedRowId: "",
};

export const VehicleTransferhistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isVehicleTransfer: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_TRANSFER
    ),
    isVehicleUpdateTransfer: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_UPDATE_TRANSFER
    ),
    isViewTransferDetails: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_VIEW_TRANSFER_DETAILS
    ),
  };

  const [fields, setFields] = useState(initialState);
  const [rowClickModalOpen, setRowClickModalOpen] = useState(false);

  const {
    vehicleTransferRequestData,
    allTransferVehicleData,
    onLoad,
    onLoadTransferVehicleRequest,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  // Memoize rowCount to keep it stable and avoid page reset.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    vehicleTransferRequestData?.pageData?.totalCount || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (vehicleTransferRequestData?.pageData?.totalCount !== undefined) {
      rowCountRef.current = vehicleTransferRequestData?.pageData?.totalCount;
    }
    return rowCountRef.current;
  }, [vehicleTransferRequestData?.pageData?.totalCount]);

  // Columns for data grid
  const columns: GridColDef[] = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Request ID",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {params?.row?.id ? params?.row?.id : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "requestedVehicle",
      headerClassName: "super-app-theme--header",
      headerName: "Vehicle Requested",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.requestedVehicle
              ? params?.row?.requestedVehicle
              : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "processed",
      headerClassName: "super-app-theme--header",
      headerName: "Processed",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.processed ? params?.row?.processed : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "initiatedDate",
      headerClassName: "super-app-theme--header",
      headerName: "Requested On",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {params?.row?.initiatedDate
              ? istToNormalDate(params?.row?.initiatedDate)
              : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "createdByUserName",
      headerClassName: "super-app-theme--header",
      headerName: "Requested By",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {params?.row?.createdByUserName
              ? toPascalCase(params?.row?.createdByUserName)
              : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "hub",
      cellClassName: "hub-cell",
      headerName: "To Hub",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            <Tooltip
              title={
                params?.row?.branchDisplayName
                  ? params?.row?.branchDisplayName
                  : "NA"
              }
              arrow
            >
              {params?.row?.branchDisplayName
                ? params?.row?.branchDisplayName
                : "NA"}
            </Tooltip>
          </div>
        );
      },
      flex: 1,
      resizable: false,
    },
    {
      field: "status",
      cellClassName: "booking-status-cell",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              color: colors.primary_warning,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.status
              ? TransferRequestStatusDisplayName[
                  params?.row
                    ?.status as keyof typeof TransferRequestStatusDisplayName
                ]
              : "NA"}
          </div>
        );
      },
      flex: 1,
      resizable: false,
    },
  ];

  // get all tranfer vehicle api
  const getAllTranferVehicleRequests = (data: any) => {
    const payload = {
      search: data.searchByRequestId && JSON.stringify(data.searchByRequestId),
      page: data?.page + 1,
      pageSize: data?.pageSize,
    };

    if (functionalitiesAccess.isViewTransferDetails) {
      dispatch(vehicleActions.getAllTranferVehicleRequests(payload));
    }
  };

  // get transfer vehicle list on the particular id
  const getTranferVehicleList = (data: any) => {
    const payload = {
      transferRequestIds: [data.id],
      page: data.page + 1,
      pageSize: data?.pageSize,
    };
    dispatch(vehicleActions.getAllTransfer(payload));
  };

  // handle pagination
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    getAllTranferVehicleRequests({
      page: page,
      pageSize: pageSize,
    });
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getAllTranferVehicleRequests(payload);
    }, 1000),
    []
  );

  // handle seach by request id
  const handleSearchByRequestId = (e: any) => {
    setFields((prev: any) => ({
      ...prev,
      searchByRequestId: e.target.value,
    }));

    if (e.target.value?.length >= 2 || !e.target.value)
      debouncedSearch({
        page: fields?.paginationModel?.page,
        pageSize: fields?.paginationModel?.pageSize,
        searchByRequestId: e.target.value,
      });
  };

  // onclick row get the data of the row when clicked
  const handleRowClick = (rowData: any) => {
    setRowClickModalOpen(true);
    getTranferVehicleList({
      id: rowData?.id,
      page: 0,
      pageSize: 10,
    });
  };

  // close modal
  const handleModalClose = () => {
    setRowClickModalOpen(false);
  };

  // initial useEffect
  useEffect(() => {
    getAllTranferVehicleRequests({
      page: fields?.paginationModel?.page,
      pageSize: fields?.paginationModel?.pageSize,
    });

    dispatch(
      updateBreadcrumbs(
        BREADCRUMBS_RENTALS.BULK_ACTIONS_VEHICLE_TRANSFER_HISTORY
      )
    );

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  return (
    <Stack gap="24px">
      <CustomButton
        label={rentalEn.VehicleManagement.tranferVehicle}
        variant="outlined"
        onClick={() => navigate(routesConstants.VEHICLE_TRANSFER)}
        disabled={!functionalitiesAccess.isVehicleTransfer}
      />
      <Stack>
        {/* <Typography
          variant={typographyConstants.HEADING}
          color={colors.text_black}
        >
          {rentalEn.VehicleManagement.totalTransferredVehicle}
        </Typography> */}
        <Input
          placeholder={rentalEn.VehicleManagement.searchByRequestID}
          variant="outlined"
          value={fields?.searchByRequestId}
          inputProps={{ maxLength: 50 }}
          onChange={handleSearchByRequestId}
          disabled={!functionalitiesAccess.isViewTransferDetails}
          width={320}
        />
      </Stack>
      <DataTable
        columns={columns}
        rows={vehicleTransferRequestData?.requestDetails}
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={fields.paginationModel}
        onPaginationModelChange={(val: any) => {
          handlePaginationModelChange(val);
        }}
        hideColumnSeperator
        disableRowSelectionOnClick
        onRowClick={(params: GridRowParams) => {
          handleRowClick(params.row);
        }}
        loading={onLoadTransferVehicleRequest}
        hasAccess={functionalitiesAccess.isViewTransferDetails}
      />

      {rowClickModalOpen && allTransferVehicleData && (
        <TranferVehicleDetailModal
          handleModalClose={handleModalClose}
          openModal={rowClickModalOpen}
          allTransferVehicleData={allTransferVehicleData}
          getTranferVehicleList={getTranferVehicleList}
          loading={onLoad}
        />
      )}
    </Stack>
  );
};
