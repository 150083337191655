import { Box, Grid, Typography } from "@mui/material";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import InputDatePicker from "../../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import {
  lobArray,
  LobStatus,
  typographyConstants,
  useCaseArray,
  VehicleStatus,
} from "../../../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  FunctionalitiesIcon,
  RupeeInputIcon,
  WarehouseIcon,
  lobIcon,
} from "../../../../../constants/exportImages";
import rentalEn from "../../../../../locale/rental-en.json";
import { StyleObject } from "../StyleObject";

const VehicleDetails = (props: any) => {
  // props
  const {
    fields,
    allCitiesDetails,
    branches,
    modelData,
    handleChange,
    vehicleColourListData,
    isEditing,
    vehicleStatus,
    vehicleDetailsByIdData,
  } = props;

  const isEditable: boolean =
    isEditing && vehicleStatus !== VehicleStatus?.REJECTED;

  const isRegistrationNumberEditable: boolean =
    // isEditing &&
    vehicleDetailsByIdData?.lobStatus === undefined ||
    vehicleDetailsByIdData?.lobStatus === LobStatus?.AVAILABLE ||
    vehicleStatus === VehicleStatus?.REJECTED;

  return (
    <Box sx={StyleObject?.vehicleDetailsWrapperBox}>
      <Typography variant={typographyConstants.HEADING}>
        {rentalEn?.VehicleManagement?.basicVehicleDetails}
      </Typography>

      <Grid container alignItems={"center"} spacing={2}>
        {/* CITY */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={CityInputIcon}
            placeholder={`*${rentalEn?.global?.selectCity}`}
            value={fields?.selectedCity?.displayName}
            choice={allCitiesDetails || []}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedCity", value);
            }}
            disabled={isEditable || !isArrayNotEmpty(allCitiesDetails)}
          />
        </Grid>

        {/* HUB */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={WarehouseIcon}
            placeholder={`*${rentalEn?.global?.selectHub}`}
            value={
              isEditable || isArrayNotEmpty(branches)
                ? fields?.selectedBranch?.displayName
                : ""
            }
            choice={
              isEditable
                ? [{ ...fields?.selectedBranch }]
                : isArrayNotEmpty(branches)
                ? [...branches]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedBranch", value);
            }}
            disabled={
              isEditable ||
              !fields?.selectedCity?.name ||
              !isArrayNotEmpty(branches)
            }
          />
        </Grid>

        {/* MODEL */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={BikeIconFaded}
            placeholder={`*${rentalEn?.global?.vehicleModel}`}
            value={
              isEditable || isArrayNotEmpty(modelData)
                ? fields?.selectedModel?.displayName
                : ""
            }
            choice={
              isEditable
                ? [{ ...fields?.selectedModel }]
                : isArrayNotEmpty(modelData)
                ? [...modelData]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedModel", value);
            }}
            disabled={
              isEditable ||
              !fields?.selectedBranch?.name ||
              !isArrayNotEmpty(modelData)
            }
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={lobIcon}
            placeholder={`*${rentalEn?.global?.selectLob}`}
            value={fields?.selectedLob?.displayName}
            choice={lobArray || []}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedLob", value);
            }}
            disabled={isEditable}
          />
        </Grid>

        {/* USE CASE / FUNCTIONALITY */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={FunctionalitiesIcon}
            placeholder={`*${rentalEn?.global?.selectUseCase}`}
            value={
              isEditing || isArrayNotEmpty(useCaseArray)
                ? fields?.selectedUseCase?.displayName
                : ""
            }
            choice={
              isEditing
                ? [{ ...fields?.selectedUseCase }]
                : isArrayNotEmpty(useCaseArray)
                ? [...useCaseArray]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedUseCase", value);
            }}
            disabled={isEditable}
          />
        </Grid>

        {/* VEHICLE COLOR */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            placeholder={`*${rentalEn?.VehicleManagement?.selectColor}`}
            value={
              isEditable || isArrayNotEmpty(vehicleColourListData)
                ? fields?.selectedColour?.displayName
                : ""
            }
            choice={
              isEditable
                ? [{ ...fields?.selectedColour }]
                : isArrayNotEmpty(vehicleColourListData)
                ? [...vehicleColourListData]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              handleChange("selectedColour", value);
            }}
            disabled={isEditable}
          />
        </Grid>

        {/* CHASSIS NUMBER */}
        <Grid item sm={12} md={4}>
          <Input
            label={`*${rentalEn?.VehicleManagement?.chassisNumber}`}
            placeholder={`*${rentalEn?.VehicleManagement?.chassisNumber}`}
            variant="outlined"
            value={fields?.chassis}
            inputProps={{ maxLength: 17 }}
            onChange={(e: any) => handleChange("chassis", e.target.value)}
            muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            disabled={isEditable}
            customStyle={{ boxShadow: "unset", maxWidth: "520px" }}
          />
        </Grid>

        {/* ENGINE NUMBER */}
        <Grid item sm={12} md={4}>
          <Input
            label={`*${rentalEn?.VehicleManagement?.engineNumber}`}
            placeholder={`*${rentalEn?.VehicleManagement?.engineNumber}`}
            variant="outlined"
            value={fields?.engine}
            inputProps={{ maxLength: 17 }}
            onChange={(e: any) => handleChange("engine", e.target.value)}
            muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            disabled={isEditable}
            customStyle={{ boxShadow: "unset", maxWidth: "520px" }}
          />
        </Grid>

        {/* VEHICLE REGISTRATION NUMBER */}
        <Grid item sm={12} md={4}>
          <Input
            label={`*${rentalEn?.VehicleManagement?.registrationNumber}`}
            placeholder={`*${rentalEn?.VehicleManagement?.registrationNumber}`}
            variant="outlined"
            value={fields?.registration}
            inputProps={{ maxLength: 14 }}
            onChange={(e: any) => handleChange("registration", e.target.value)}
            muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            disabled={isEditing ? !isRegistrationNumberEditable : false}
            customStyle={{ boxShadow: "unset", maxWidth: "520px" }}
          />
        </Grid>

        {/* REGISTRATION DATE */}
        <Grid item sm={12} md={4}>
          <InputDatePicker
            required
            value={fields?.registrationDate ? fields?.registrationDate : null}
            onChange={(date: any) => {
              handleChange("registrationDate", date);
            }}
            placeholder={`*${rentalEn?.VehicleManagement?.registrationDate}`}
            maxDate={new Date()}
            disabled={isEditable}
            textFieldStyle={{ maxWidth: "520px" }}
          />
        </Grid>

        {/* MANUFACTURING YEAR */}
        <Grid item sm={12} md={4}>
          <InputDatePicker
            required
            views={["year"]}
            inputFormat="YYYY"
            value={fields?.manufacturingYear ? fields?.manufacturingYear : null}
            onChange={(date: any) => {
              handleChange("manufacturingYear", date);
            }}
            placeholder={`*${rentalEn?.VehicleManagement?.manufacturingYear}`}
            minDate={new Date(new Date().getFullYear() - 15, 1)}
            maxDate={new Date()}
            disabled={isEditable}
            textFieldStyle={{ maxWidth: "520px" }}
          />
        </Grid>

        {/* PURCHASE DATE */}
        <Grid item sm={12} md={4}>
          <InputDatePicker
            required
            value={fields?.purchaseDate ? fields?.purchaseDate : null}
            onChange={(date: any) => {
              handleChange("purchaseDate", date);
            }}
            placeholder={`*${rentalEn?.VehicleManagement?.purchaseDate}`}
            maxDate={new Date()}
            disabled={isEditable}
            textFieldStyle={{ maxWidth: "520px" }}
          />
        </Grid>

        {/* PRICE */}
        <Grid item sm={12} md={4}>
          <Input
            placeholder={rentalEn?.global?.price}
            variant="outlined"
            value={fields?.cost}
            inputProps={{ maxLength: 7 }}
            onChange={(e: any) => handleChange("cost", e.target.value)}
            iconStart={RupeeInputIcon}
            muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            disabled={isEditable}
            customStyle={{ boxShadow: "unset", maxWidth: "520px" }}
          />
        </Grid>
    
      </Grid>
    </Box>
  );
};

export default VehicleDetails;
