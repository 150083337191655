import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const FeedBackDetail = ({ userFeedBack }: any) => {
  const navigate = useNavigate();
  return (
    <>
      {userFeedBack !== null &&
      userFeedBack !== undefined &&
      Object.keys(userFeedBack).length > 0 ? (
        <div className=" mt-2">
        <Link to={`/new-booking-management/${userFeedBack?.lobMeta?.legacyBookingId}`}>
        
        <p
            style={{
              textAlign: "left",
              textDecoration: "underline",
              font: "normal normal normal 16px/47px Euclid Circular B",
              color: "#2BC7C9",
              opacity: 1,
            }}

            // onClick = {()=>navigate(`new-booking-management/${userFeedBack?.lobMeta?.legacyBookingId}`)}
          >
            Booking ID: {userFeedBack?.lobMeta?.legacyBookingId}
          </p></Link>
          <div className="row">
            <div>
              {userFeedBack?.basicAnswers?.overallRating<3 ? <h3 className="cust-likehead">What Went Wrong?</h3> : <h3 className="cust-likehead">What did customer like?</h3>}
              {userFeedBack?.tagAnswer.length ? (
                <ul className="tags-wrap" style={{ display: "flex" }}>
                  {userFeedBack?.tagAnswer?.map((item: string) => (
                    <li>{item}</li>
                  ))}
                </ul>
              ) : (
                <p> N/A</p>
              )}
            </div>
          </div>
          <div className="row review-box">
            <div style= {{width:"550px"}}>
              <div className="cust-review">
                <h4>Customer Review</h4>
                <p style={{ overflowWrap: "break-word",  wordWrap : "break-word"}}>
                  {userFeedBack?.basicAnswers?.customerReview ?? "N/A"}
                </p>
              </div>
            </div>
            <div style= {{width:"550px"}}>
              <div className="admin-review">
                <h4>Admin's Review</h4>
                <p style={{ overflowWrap: "break-word",  wordWrap : "break-word"}}>{userFeedBack?.basicAnswers?.executiveReview ?? "N/A"}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center" }}> Not Available</p>
      )}
    </>
  );
};

export default FeedBackDetail;
