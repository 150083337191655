interface commonInterface {
  [key: string]: string | number;
}

export enum BOOKING_DETAILS_TABS {
  BOOKING_DETAILS = 1,
  VEHICLE_DETAILS = 2,
  PLAN_DETAILS = 3,
  PAYMENT_DETAILS = 4,
  ALERTS = 5,
}

export enum ChargeTypes {
  ACCESSORY = "ACCESSORY",
  ADDITIONAL = "ADDITIONAL",
  MONTHLY_FEE = "MONTHLY_FEE",
  SUBSCRIPTION_FEE = "SUBSCRIPTION_FEE",
  ADD_ONS = "ADD_ONS",
  MANDATE_REGISTRATION = "MANDATE_REGISTRATION",
  BOOKING_CLOSURE_FEE = "BOOKING_CLOSURE_FEE",
  OTHER = "OTHER",
}

export enum DisplayChargeTypes {
  ACCESSORY = "Accessory",
  ADDITIONAL = "Additional",
  MONTHLY_FEE = "Monthly Installment ",
  SUBSCRIPTION_FEE = "Subscription Fee",
  ADD_ONS = "Add Ons",
  MANDATE_REGISTRATION = "Mandate Registration",
  BOOKING_CLOSURE_FEE = "Booking Closure Fee",
  OTHER = "Other",
}
export type DisplayChargeTypesKey = keyof typeof DisplayChargeTypes;

export enum PaymentModes {
  UPI = "UPI",
  NET_BANKING = "NET_BANKING",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  CARD = "CARD",
  WALLET = "WALLET",
  PAYLATER = "PAYLATER",
  EMI = "EMI",
}

export enum DisplayPaymentHistoryModes {
  UPI = "UPI",
  NET_BANKING = "Net Banking",
  CREDIT_CARD = "Credit Card",
  DEBIT_CARD = "Debit Card",
  CARD = "Card",
  WALLET = "Wallet",
  NETBANKING = "Net Banking",
  CREDITCARD = "Credit Card",
  DEBITCARD = "Debit Card",
  PAYLATER = "Pay Later",
  EMI = "EMI",
}

export enum PaymentJourneyStatus {
  COLLECTED = "COLLECTED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
}

export enum DisplayBookingType {
  IN_WAREHOUSE = "Warehouse",
  ONLINE = "Online",
}
export type DisplayBookingTypeKey = keyof typeof DisplayBookingType;

export enum DocumentUploadConstants {
  PDF_FILE = ".pdf",
  TO_MANY_FILES = "too-many-files",
}
export enum DisplayChargesNameTypes {
  VOILATION_TC = "Charges for voilation of T&Cs",
  VOILATION_CHARGES = "Voilation charges",
  OTHERS = "Others",
}

export enum OrderPaymentType {
  QR_CODE = "QR_CODE",
  PAYMENT_LINK = "PAYMENT_LINK",
}

export enum DisplayPaymentModes {
  QR_CODE = "QR code",
  PAYMENT_LINK = "Payment Link",
}
// For Payment Details - Order status for Payment
export enum PaymentStatusModes {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  PROCESSING = "PROCESSING",
}

export enum DisplayPaymentStatusModes {
  PENDING = "Pending",
  SUCCESS = "Success",
  FAILED = "Failed",
  CANCELLED = "Cancelled",
  EXPIRED = "Expired",
  PROCESSING = "Processing",
}

export enum DisplayPaymentStatusColorCode {
  PENDING = "#FF7A00",
  SUCCESS = "#1BC47D",
  FAILED = "#F3756A",
  CANCELLED = "#F3756A",
  EXPIRED = "#F3756A",
  PROCESSING = "#1bd8da",
}

export enum DisplayPaymentButtonLabel {
  DEFAULT = "Select Payment Method",
  QR_CODE = "Generate QR Code",
  PAYMENT_LINK = "Generate Payment Link",
}

export enum DisplayHelmetTypes {
  PREMIUM = "Premium",
  FULL_FACE = "Full face",
  HALF_FACE = "Half face",
  KIDS = "Kids",
}

export enum ADDITIONAL_CHARGES_STATUS {
  PENDING = "PENDING",
  PAID = "PAID",
  WAIVED_OFF = "WAIVED_OFF",
  DELETED = "DELETED",
  INITIATED = "INITIATED",
  COLLECTED = "COLLECTED",
}

export enum ADDITIONAL_CHARGES_DISPLAY_STATUS {
  PENDING = "Pending",
  PAID = "Paid",
  WAIVED_OFF = "Waived Off",
  DELETED = "Deleted",
  INITIATED = "Payment Initiated",
  COLLECTED = "Paid",
  PAYMENT_INITIATED = "Payment Initiated",
}

export enum PLAN_JOURNEY_DATAGRID_ROW_BACKGROUND {
  PENDING = "Secondary",
  SUCCESS = "Success",
  FAILED = "Error",
  CANCELLED = "Error",
  EXPIRED = "Secondary",
  PROCESSING = "Info",
  OVERDUE = "Warning",
  COLLECTED = "Success",
}

export enum ADD_CHARGES_ACTION_TYPE {
  SAVE = "SAVE",
  SAVE_AND_PAY = "SAVE_AND_PAY",
}

export const HelmetMapForCreateBooking = {
  premium: "PREMIUM",
  fullFace: "FULL_FACE",
  halfFace: "HALF_FACE",
  kids: "KIDS",
};

export const HelmetRevertMapForCreateBooking = {
  PREMIUM: "premium",
  FULL_FACE: "fullFace",
  HALF_FACE: "halfFace",
  KIDS: "kids",
};

export const HelmetTypesArray = ["PREMIUM", "FULL_FACE", "HALF_FACE", "KIDS"];

export enum OrderTypes {
  PAYMENT = "PAYMENT",
  MANDATE_REGISTRATION = "MANDATE_REGISTRATION",
  MANDATE_EXECUTION = "MANDATE_EXECUTION",
}

export enum DISPLAY_DELIVERY_MODES {
  PICKUP = "Pickup",
}

export enum GENDERS {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHERS = "OTHERS",
}

export enum displayGenderEnums {
  MALE = "Male",
  FEMALE = "Female",
  OTHERS = "Others",
}
