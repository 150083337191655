import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomerFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../redux/store";
import CustomButtonGroup from "../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import BookingsCard from "../../../../sharedComponents/templates/BookingCard";
import { colors } from "../../../../themes/colors";
import { debounce } from "../../../../utils/helper";
import RbackHelper from "../../../../utils/helperRBAC";
import { checkPhone } from "../../../../utils/regex";
import {
  GenericObject,
  typographyConstants,
} from "../../../constants/constants";
import {
  BlockedUsers,
  CardBackGround,
  KycVerifyUsers,
  TotalUsers,
} from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import {
  getCustomerDashBoardBookingStats,
  searchCustomerAction,
} from "../../../redux/actions/customerManagementActions";
import {
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  istToNormalDate,
  validate,
} from "../../../utils/helper";
import SearchCustomer from "../component/SearchCustomer";
import "./css/customerManagement.css";

const initialState = {
  selectedDate: new Date(),
  startDate: "",
  endDate: "",
};
let prevTab: number = 1;

const CustomerManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardStats, loading } = useSelector(
    (state: RootState) => state.customerManagementReducer
  );

  const { shouldScreenRefresh } = useSelector(
    (state: RootState) => state.commonReducer
  );

  const [searchValue, setSearchValue] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [addUserEnabled, setAddUserEnabled] = useState(false);

  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [searchedMobNo, setSearchedMobNo] = useState("");
  const [fields, setFields] = useState<GenericObject>({
    ...initialState,
  });

  const [duplicateDate, setDuplicateDate] = useState<GenericObject>({
    startDate: "",
    endDate: "",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const errorInitialState = {
    mobileNumber: "",
  };
  const [errorFields, setErrorFields] = useState(errorInitialState);

  const getSearchedUserList = (searchValue: number) => {};

  const debouncedSearchUser = useCallback(
    debounce((searchValue: string) => {
      getSearchedUserList(Number(searchValue));
    }, 1000),
    []
  );

  // outside click ref
  const wrapperRef = useRef(null);

  //outside click handler
  function useOutsideAlerter(ref: any, callback: Function) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();

          if (
            fields.startDate &&
            !fields.endDate &&
            duplicateDate.startDate &&
            duplicateDate.endDate
          ) {
            setFields((prev: any) => ({
              ...prev,
              startDate: duplicateDate.startDate,
              endDate: duplicateDate.endDate,
            }));
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, fields]);
  }

  const handleSearchUserClick = () => {
    let payloadObj = {
      mobileNumber: searchedMobNo,
    };
    const validatedFields = validate(payloadObj, errorInitialState);
    setErrorFields({ ...errorInitialState, ...validatedFields.errorFields });
    if (validatedFields.error === false) {
      dispatch(
        searchCustomerAction({
          mobile_number: searchedMobNo,
          navigate,
        })
      );
    }
  };

  const tabsArray: any = [
    {
      label: rentalEn?.global?.All,
      selectedTab: selectedTab,
      tabValidation: null,
      tabStyle: {},
    },
    {
      label: rentalEn?.global?.today,
      selectedTab: selectedTab,
      tabStyle: {},
    },
    {
      label: rentalEn?.global?.customDate,
      selectedTab: selectedTab,
      tabStyle: {},
    },
  ];

  const handleTabChange = (
    index: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    prevTab = selectedTab;
    if (index === 1) {
      setFields((prev: any) => ({
        ...prev,
        selectedDate: initialState?.selectedDate,
      }));
      dispatch(
        getCustomerDashBoardBookingStats({
          startDate: getTimestampAtStartOfDay(initialState?.selectedDate),
        })
      );
    } else if (index === 0) {
      dispatch(
        getCustomerDashBoardBookingStats({
          // startDate: istToTimestamp(initialState?.selectedDate),
        })
      );
    } else if (index === 2) {
      setShowCalendar(true);
    }
    setSelectedTab(index);
  };

  const handleRegisterUserFieldChange = useCallback(
    (field: string, value: any) => {
      if (field === "mobileNumber") {
        const isValid = checkPhone(value);
        if (!isValid && value !== "") return false;
        if (value.length > 10) return false;
      }
      setSearchedMobNo(() => value);
    },
    []
  );

  //handle date change
  const handleDateChange = (dateRange: any) => {
    let [startDateFromRangePicker, endDateFromRangePicker] = dateRange;

    setFields((prev: any) => ({
      ...prev,
      startDate: startDateFromRangePicker,
      endDate: endDateFromRangePicker,
    }));

    if (startDateFromRangePicker && endDateFromRangePicker) {
      const payload = {
        startDate: getTimestampAtStartOfDay(startDateFromRangePicker),
        endDate: getTimestampAtEndOfDay(endDateFromRangePicker),
      };

      setDuplicateDate((prev: any) => ({
        ...prev,
        startDate: startDateFromRangePicker,
        endDate: endDateFromRangePicker,
      }));

      dispatch(getCustomerDashBoardBookingStats(payload));
      handleCloseCalendar();
    }
  };

  // handle Close calender
  const handleCloseCalendar = () => {
    setShowCalendar(false);
    if (!fields.startDate && !fields.endDate) {
      setSelectedTab(prevTab);
    }
  };

  //handle outside click
  useOutsideAlerter(wrapperRef, handleCloseCalendar);

  // call api for current date as sson as we land on page
  useEffect(() => {
    dispatch(
      getCustomerDashBoardBookingStats({
        startDate: getTimestampAtStartOfDay(fields?.selectedDate),
      })
    );
  }, [shouldScreenRefresh]);

  return (
    <>
      <Stack className="ContentWrapper" gap="5vh">
        <Stack
          className="TopSection"
          direction="row"
          justifyContent="space-between"
        >
          {/* search customer component */}
          {RbackHelper.isAccessRightsProvided(
            modules.CUSTOMER_MANAGEMENT,
            CustomerFunctionalities.VIEW_CUSTOMER_DETAILS,
            CustomerFunctionalities.SEARCH_CUSTOMER_DETAILS
          ) && <SearchCustomer />}

          {/* add user */}
          {RbackHelper.isAccessRightsProvided(
            modules.CUSTOMER_MANAGEMENT,
            CustomerFunctionalities.ADD_CUSTOMER
          ) && (
            <CustomButton
              label={rentalEn.CustomerManagement.AddCustomer}
              disabled={false}
              loading={false}
              variant="contained"
              onClick={() => {
                navigate("/customer-management/add-customer", {
                  replace: true,
                });
              }}
            />
          )}
        </Stack>

        {RbackHelper.isAccessRightsProvided(
          modules.CUSTOMER_MANAGEMENT,
          CustomerFunctionalities.VIEW_CUSTOMER_MANAGEMENT_STATS
        ) && (
          <>
            <Stack
              className="ContentSection"
              justifyContent={"space-between"}
              gap="5vh"
            >
              <Box position={"relative"}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" gap="4px">
                    <Typography
                      variant={typographyConstants.HEADING}
                      color={colors.text_black}
                    >
                      {rentalEn?.global?.showData}
                    </Typography>
                    <Stack direction="row" className="FilterSection">
                      <CustomButtonGroup
                        handleTabChange={handleTabChange}
                        tabsArray={tabsArray}
                      />
                    </Stack>
                  </Box>
                  {selectedTab === 1 && (
                    <Typography
                      variant={typographyConstants.SUBHEADING}
                      color={colors.text_secondary_gray}
                    >
                      {istToNormalDate(fields?.selectedDate)}
                    </Typography>
                  )}
                  {selectedTab === 2 &&
                    fields?.startDate &&
                    fields?.endDate && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant={typographyConstants.SUBHEADING}
                          color={colors.text_black}
                        >
                          {fields?.startDate ? (
                            `${istToNormalDate(fields?.startDate)} `
                          ) : (
                            <></>
                          )}
                        </Typography>
                        <Typography color={colors.text_secondary_gray}>
                          to
                        </Typography>
                        <Typography
                          variant={typographyConstants.SUBHEADING}
                          color={colors.text_black}
                        >
                          {fields?.endDate ? (
                            istToNormalDate(fields?.endDate)
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Box>
                    )}
                </Box>

                {showCalendar && (
                  <Box ref={wrapperRef}>
                    <Box
                      sx={{ position: "absolute", right: "45%", top: "10%" }}
                    >
                      <RangePicker
                        fields={fields}
                        onChange={(update: any) => {
                          handleDateChange(update);
                        }}
                        // minDate={!moment().toDate()}
                        maxDate={moment().toDate()}
                        selectsRange
                        inline
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Stats section */}
              <Stack
                direction="row"
                className="StatsSection"
                justifyContent={"space-between"}
                gap="5px"
              >
                <BookingsCard
                  icon={TotalUsers}
                  text={rentalEn?.CustomerManagement?.totalUsers}
                  subText={dashboardStats?.total}
                  bgImg={CardBackGround}
                  loading={loading}
                />
                <BookingsCard
                  icon={TotalUsers}
                  text={rentalEn?.CustomerManagement?.revenueGivingUsers}
                  subText={dashboardStats?.ltrRevenueGivingCustomer}
                  bgImg={CardBackGround}
                  loading={loading}
                />
                <BookingsCard
                  icon={KycVerifyUsers}
                  text={rentalEn?.CustomerManagement?.KYCVerifiedUsers}
                  subText={dashboardStats?.kycVerified}
                  bgImg={CardBackGround}
                  loading={loading}
                />
                <BookingsCard
                  icon={BlockedUsers}
                  text={rentalEn?.CustomerManagement?.blockedUsers}
                  subText={dashboardStats?.blocked}
                  bgImg={CardBackGround}
                  loading={loading}
                />
              </Stack>

              {/* Chart Section */}
              {/* <Stack className="Chart-section">
            <GridLineChart />
          </Stack> */}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default CustomerManagement;
