import { Box, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LooksOneTwoToneIcon from "@mui/icons-material/LooksOneTwoTone";
import LooksTwoTwoToneIcon from "@mui/icons-material/LooksTwoTwoTone";

import CustomButtonGroup from "../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import {
  Fields,
  FieldsKeys,
  SelectedTab,
} from "../../../interfaces/bussinessManagement";

import DeliverySetup from "./sections/deliverySetup";
import styles from "./sections/section.module.css";
import WarehouseSetup from "./sections/warehouseSetup";
import { RootState } from "../../../../redux/reducers";

import {
  clearBreadcrumbs,
  getAllCities,
  getAllStates,
  getCityByState,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import {
  convertToTimeStamp,
  dateFormat,
  getTimestampAtStartOfDay,
  timeToDateObject,
  validate,
} from "../../../utils/helper";
import {
  createWarehouse,
  updateWarehouse,
  viewWarehouse,
} from "../../../redux/actions/businessManagementActions";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import en from "../../../locale/rental-en.json";
import {
  BUSINESS_TAB_ARRAY_KEYS,
  FREEDOOPERATORID,
  LOB_TYPES,
  STATUS,
} from "../../../constants/constants";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  modules,
  warehouseFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import { routesConstants } from "../../../utils/RoutesConstants";
import { storeData } from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import { rentalActions } from "../../../redux/actions";
const gurugramLatLong = {
  lat: 28.457523,
  lng: 77.026344,
};
const AddEditViewWarehouse = (props: any) => {
  // rbac implementation
  const RbacHasAccess = {
    editWarehouse: RbacHelper.isAccessRightsProvided(
      modules.WAREHOUSE_MANAGEMENT,
      warehouseFunctionalities.EDIT_WAREHOUSE
    ),
  };
  const [fields, setFields] = useState<any>({
    states: {
      name: "",
      displayName: "",
    },
    city: {
      name: "",
      displayName: "",
    },
    branchName: "",
    address1: "",
    address2: "",
    latitude: 0,
    longitude: 0,
    zipcode: "",
    formattedAddress: "",
    landmark: "",
    launchDate: "",
    status: "",
    startTime: new Date().setHours(9, 0),
    endTime: new Date().setHours(18, 0),
    helmetTypes: [],
    homeDeliveryAvailable: false,
    hubMapName: "",
  });

  const [duplicateFields, setDuplicateFields] = useState<any>({});
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(true);
  const [markerPosition, setMarkerPosition] = useState<any>({
    lat: gurugramLatLong?.lat,
    lng: gurugramLatLong?.lng,
  });
  const { allCitiesDetails, allStatesList, stateBasedCities } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const {
    warehouseDetail,
    warehouseCreated,
    isWarehouseUpdated,
    warehouseScreenLoader,
    disableWarehouseFields,
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  const { operatorConfigData }: any = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const authorizedOperators = userData?.authorizedOperators || [];
  const operatorId = authorizedOperators[0] || "";

  const isFreedoOperator = operatorId === FREEDOOPERATORID;

  useEffect(() => {
    dispatch(getAllStates());
    window.scrollTo(0, 0);
    if (props.editRole) {
      dispatch(viewWarehouse({ branch: params.id }));
      !RbacHasAccess.editWarehouse && setHasEditAccess(false);
    }
    if (props.editRole || Object.keys(warehouseDetail)?.length) {
      setButtonDisabled(true);
    }
    if (params?.id) {
      if (location?.state === "OVERVIEW_HUB") {
        dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OVERVIEW_ADD_HUB));
      } else {
        dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.EDIT_HUB));
      }
    } else {
      if (location?.state === "OVERVIEW_HUB") {
        dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OVERVIEW_ADD_HUB));
      } else {
        dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.ADD_HUB));
      }
    }
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, []);
  useEffect(() => {
    if (Object.keys(warehouseDetail)?.length) {
      //GET BRANCH CONFIG API
      const payload = {
        branch: warehouseDetail?.name,
        data: {
          lob: LOB_TYPES.LTR,
        },
      };

      dispatch(rentalActions.getOperatorConfigByBranch(payload));

      setFields({
        ...fields,
        states: {
          name: warehouseDetail?.state,
          displayName: warehouseDetail?.stateDisplayName,
        },
        city: {
          name: warehouseDetail?.city,
          displayName: warehouseDetail?.cityDisplayName,
        },
        branchName: warehouseDetail?.displayName,
        branch: {
          name: warehouseDetail?.name,
          displayName: warehouseDetail?.displayName,
        },
        address1:
          warehouseDetail?.address?.addressLine1 &&
          warehouseDetail?.address?.addressLine1,
        address2:
          (warehouseDetail?.address?.addressLine2 &&
            warehouseDetail?.address?.addressLine2) ??
          "",
        landmark:
          (warehouseDetail?.address?.landmark &&
            warehouseDetail?.address?.landmark) ??
          "",
        formattedAddress:
          warehouseDetail?.address?.locationData?.formattedAddress,
        latitude: warehouseDetail?.address?.locationData?.coordinates?.lat,
        longitude: warehouseDetail?.address?.locationData?.coordinates?.lng,
        zipcode: warehouseDetail?.address?.pinCode,
        launchDate: dateFormat(warehouseDetail?.launchDate),
        status: warehouseDetail?.status,
        startTime: warehouseDetail?.config?.operationalHours?.start
          ? timeToDateObject(warehouseDetail?.config?.operationalHours?.start)
          : fields.startTime,
        endTime: warehouseDetail?.config?.operationalHours?.end
          ? timeToDateObject(warehouseDetail?.config?.operationalHours?.end)
          : fields.endTime,
        helmetTypes: warehouseDetail?.config?.helmetTypes ?? [],
        homeDeliveryAvailable: warehouseDetail?.config?.homeDeliveryAvailable,
        hubMapName: warehouseDetail?.hubMapName,
      });
      setMarkerPosition({
        lat: warehouseDetail?.address?.locationData?.coordinates?.lat ?? 0,
        lng: warehouseDetail?.address?.locationData?.coordinates?.lng ?? 0,
      });
      setDate(new Date(warehouseDetail?.launchDate));
      setDuplicateFields({
        ...duplicateFields,
        states: {
          name: warehouseDetail?.state,
          displayName: warehouseDetail?.stateDisplayName,
        },
        city: {
          name: warehouseDetail?.city,
          displayName: warehouseDetail?.cityDisplayName,
        },
        branchName: warehouseDetail?.displayName,
        address1:
          warehouseDetail?.address?.addressLine1 &&
          warehouseDetail?.address?.addressLine1,
        address2:
          (warehouseDetail?.address?.addressLine2 &&
            warehouseDetail?.address?.addressLine2) ??
          "",
        landmark:
          (warehouseDetail?.landmark && warehouseDetail?.landmark) ?? "",
        formattedAddress:
          warehouseDetail?.address?.locationData?.formattedAddress,
        latitude: warehouseDetail?.address?.locationData?.coordinates?.lat,
        longitude: warehouseDetail?.address?.locationData?.coordinates?.lng,
        zipcode: warehouseDetail?.address?.pinCode,
        launchDate: dateFormat(warehouseDetail?.launchDate),
        status: warehouseDetail?.status,
        startTime: warehouseDetail?.config?.operationalHours?.start
          ? timeToDateObject(warehouseDetail?.config?.operationalHours?.start)
          : fields.startTime,
        endTime: warehouseDetail?.config?.operationalHours?.end
          ? timeToDateObject(warehouseDetail?.config?.operationalHours?.end)
          : fields.endTime,
        helmetTypes: warehouseDetail?.config?.helmetTypes ?? [],
        homeDeliveryAvailable: warehouseDetail?.config?.homeDeliveryAvailable,
        hubMapName: warehouseDetail?.hubMapName,
      });
    }
    const cityObject = allCitiesDetails?.find(
      (item: any) => item?.name === warehouseDetail?.city
    );
    if (cityObject) {
      setDisabledDate(cityObject.launchDate);
    }
  }, [warehouseDetail]);

  const [errorFields, setErrorFields] = useState<any>({
    states: "",
    city: "",
    branchName: "",
    address1: "",
    address2: "",
    latitude: "",
    longitude: "",
    zipcode: "",
    startTime: "",
    endTime: "",
    formattedAddress: "",
    hubMapName: "",
  });

  const [date, setDate] = useState<any>(new Date());
  const [disabledDate, setDisabledDate] = useState(
    getTimestampAtStartOfDay(new Date())
  );

  const handleFields = (field: any, value: any) => {
    if (field == "launchDate") {
      setFields({ ...fields, [field]: dateFormat(value) });
      setDate(value);
    } else if (field == "city") {
      setFields((prevState: any) => ({
        ...prevState,
        [field]: value,
        launchDate: value.launchDate,
      }));
      setDate(value.launchDate);
      setDisabledDate(value.launchDate);
      setErrorFields({ ...errorFields, [field]: "" });
    } else if (field == "startTime") {
      let time = moment(new Date(value));
      if (time.isBefore(new Date(fields?.endTime)))
        setFields((prevState: any) => ({ ...prevState, ["startTime"]: value }));
      else toastr.warning("", en.NewBusinessManagement.startTimeError);
    } else if (field == "endTime") {
      let time = moment(new Date(value));
      if (time.isAfter(new Date(fields?.startTime)))
        setFields((prevState: any) => ({ ...prevState, ["endTime"]: value }));
      else toastr.warning("", en.NewBusinessManagement.endTimeError);
    } else {
      setFields((prevState: any) => ({
        ...prevState,
        [field]: value,
      }));
      setErrorFields({ ...errorFields, [field]: "" });
    }
    if (field == "states") {
      if (value?.name) {
        dispatch(getCityByState(value?.name));
        setDate(new Date());
        setDisabledDate(getTimestampAtStartOfDay(new Date()));
      } else {
        dispatch(getAllStates());
      }

      setFields((prev: any) => ({
        ...prev,
        city: {
          name: "",
          displayName: "",
        },
      }));
    }
    if (JSON.stringify(fields) != JSON.stringify(duplicateFields)) {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (warehouseCreated || isWarehouseUpdated) {
      handleTabChange(1, BUSINESS_TAB_ARRAY_KEYS.DELIVERY_SETUP);
    }
  }, [warehouseCreated, isWarehouseUpdated]);

  //create Warehouse
  const handleSaveWarehouse = () => {
    let obj = {
      states: fields.states,
      city: fields.city,
      branchName: fields.branchName.trim(),
      address1: fields.address1.trim(),
      address2: fields.address2.trim(),
      latitude: fields.latitude,
      longitude: fields.longitude,
      zipcode: fields.zipcode,
      formattedAddress: fields.formattedAddress.trim(),
      hubMapName: fields?.hubMapName.trim(),
    };
    let objErr = {
      states: "",
      city: "",
      branchName: "",
      address1: "",
      address2: "",
      latitude: "",
      longitude: "",
      zipcode: "",
      formattedAddress: "",
      hubMapName: "",
    };
    let error = false;

    const validatedFields = validate(obj, objErr);
    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });

      return error;
    }

    let payload = {
      data: {
        displayName: fields.branchName.trim(),
        city: fields.city.name,
        status: isFreedoOperator
          ? STATUS.ACTIVE
          : warehouseDetail?.status
          ? warehouseDetail?.status
          : STATUS.INACTIVE,
        address: {
          addressLine1: fields.address1.trim(),
          addressLine2: fields.address2.trim(),
          pinCode: fields.zipcode,
          locationData: {
            coordinates: {
              lat: fields.latitude,
              lng: fields.longitude,
            },
          },
        },
        launchDate: getTimestampAtStartOfDay(date),
        config: {
          operationalHours: {
            start: dayjs(fields.startTime).format("HH:mm"),
            end: dayjs(fields.endTime).format("HH:mm"),
          },
          helmetTypes: fields.helmetTypes,
          homeDeliveryAvailable: fields.homeDeliveryAvailable,
        },
        hubMapName: fields.hubMapName,
      },
    };

    if (fields.landmark.trim()) {
      payload.data.address["landmark" as keyof typeof payload.data.address] =
        fields.landmark.trim();
    }
    dispatch(createWarehouse({ ...payload }));
  };

  //edit warehouse
  const handleUpdateWarehouse = () => {
    let obj = {
      branchName: fields.branchName.trim(),
      formattedAddress: fields.formattedAddress.trim(),
      address1: fields.address1.trim(),
      address2: fields.address2.trim(),
      zipcode: fields.zipcode,
      hubMapName: fields.hubMapName ? fields.hubMapName.trim() : "",
    };

    let objErr = {
      branchName: "",
      formattedAddress: "",
      address1: "",
      address2: "",
      zipcode: "",
      hubMapName: "",
    };
    let error = false;

    const validatedFields = validate(obj, objErr);
    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });

      return error;
    }

    let payload = {
      data: {
        displayName: fields.branchName.trim(),
        address: {
          addressLine1: fields.address1.trim(),
          addressLine2: fields.address2.trim(),
          pinCode: fields.zipcode,
          locationData: {
            coordinates: {
              lat: fields.latitude,
              lng: fields.longitude,
            },
          },
        },
        launchDate: getTimestampAtStartOfDay(date),
        status: fields?.status,
        config: {
          operationalHours: {
            start: dayjs(fields.startTime).format("HH:mm"),
            end: dayjs(fields.endTime).format("HH:mm"),
          },
          helmetTypes: fields.helmetTypes,
          homeDeliveryAvailable: fields.homeDeliveryAvailable,
        },
        hubMapName: fields.hubMapName,
      },
    };
    if (fields.landmark.trim()) {
      payload.data.address["landmark" as keyof typeof payload.data.address] =
        fields.landmark.trim();
    }

    dispatch(
      updateWarehouse({
        data: {
          ...payload,
        },
        branchName: warehouseDetail?.name,
      })
    );
  };

  const [selectedTab, setSelectedTab] = useState<any>({
    index: 0,
    key: "warehouseSetup",
  });

  // use to change top tabs (onClick)
  const handleTabChange = (index: number, key?: string) => {
    setSelectedTab((prev: SelectedTab) => ({
      ...prev,
      index: index,
      key: key,
    }));
  };

  // const handleTabChange = (index: number, key?: string) => {
  //   setSelectedTab((prev: any) => ({ ...prev, index: index, key: key }));
  // };

  // tab array passed as argument in button group
  const tabArray = [
    {
      key: BUSINESS_TAB_ARRAY_KEYS.WAREHOUSE_SETUP,
      label: "Hub Setup",
      tabValidation: false,
      selectedTab: selectedTab?.index,
      startIcon: <LooksOneTwoToneIcon />,
    },
    {
      key: BUSINESS_TAB_ARRAY_KEYS.DELIVERY_SETUP,
      label: "Delivery Setup",
      tabValidation: Object.keys(warehouseDetail)?.length ? false : true,
      selectedTab: selectedTab?.index,
      startIcon: <LooksTwoTwoToneIcon />,
    },
  ];

  // to load as per state value (selected tab)
  const componentLoad: any = {
    [BUSINESS_TAB_ARRAY_KEYS.WAREHOUSE_SETUP]: (
      <WarehouseSetup
        states={allStatesList}
        cities={stateBasedCities}
        fields={fields}
        setFields={setFields}
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        date={date}
        setDate={setDate}
        handleFields={handleFields}
        handleSaveWarehouse={handleSaveWarehouse}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        editRole={props.editRole}
        warehouseDetail={warehouseDetail}
        warehouseScreenLoader={warehouseScreenLoader}
        disableWarehouseFields={disableWarehouseFields}
        setButtonDisabled={setButtonDisabled}
        disabledDate={disabledDate}
        hasEditAccess={hasEditAccess}
      />
    ),
    [BUSINESS_TAB_ARRAY_KEYS.DELIVERY_SETUP]: (
      <DeliverySetup
        addRole={props?.addRole}
        editRole={props?.editRole}
        warehouseData={fields}
        branchId={props?.addRole ? warehouseDetail?.name : params?.id}
      />
    ),
  };

  const handleNavigateTaskSequence = () => {
    let data = {
      branch: {
        name: fields.branch.name,
        displayName: fields.branch.displayName,
      },
      city: {
        name: fields.city.name,
        displayName: fields.city.displayName,
      },
    };
    storeData(LocalStorage.Task_ManagementData, data);
    navigate(routesConstants.TASK_SEQUENCE, {
      state: {
        warehouseBranch: {
          name: fields.branch.name,
          displayName: fields.branch.displayName,
        },
        warehouseCity: {
          name: fields.city.name,
          displayName: fields.city.displayName,
        },
        edit: true,
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Stack direction="row" justifyContent={"center"} alignItems={"center"}>
        <CustomButtonGroup
          tabsArray={tabArray}
          handleTabChange={handleTabChange}
          wrapperBoxStyle={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
          }}
          sx={{
            width: "250px",
          }}
        />
        {operatorConfigData?.taskManagementMandatory ? (
          <CustomButton
            label={en.warehouseManagement.taskSequence}
            disabled={false}
            loading={false}
            variant="contained"
            wrapperStyles={{ width: "20%" }}
            onClick={() => {
              handleNavigateTaskSequence();
            }}
          />
        ) : (
          <></>
        )}
      </Stack>

      {componentLoad[selectedTab.key]}
      {selectedTab.index === 0 && (
        <Container
          // sx={Styles.containerMultistep}
          // className={styles.containerPadding}
          sx={{
            position: "fixed",
            bottom: 3,
            padding: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              width: "100%",
              height: "40px",
              backgroundColor: "#FBFBFB",
            }}
          >
            <CustomButton
              variant="contained"
              label={en.NewBusinessManagement.saveAndNext}
              // icon={Right}
              onClick={() => {
                if (Object.keys(warehouseDetail)?.length) {
                  handleUpdateWarehouse();
                } else {
                  handleSaveWarehouse();
                }
              }}
              loading={disableWarehouseFields}
              style={{ position: "fixed", right: "37px" }}
              disabled={buttonDisabled}
            />
          </Box>
        </Container>
      )}
    </Box>
  );
};
export default AddEditViewWarehouse;
