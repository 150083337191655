import * as actionTypes from "../../actions/actionTypes";
import { rentalPlanXInterface } from "../actionInterfaces";

const initialState = {
  payload: {},
  errorMessages: "",
  loading: false,
  rentalPlans: {},
  durationInMonths: [],
};

const rentalPlansReducerX = (
  state = initialState,
  action: rentalPlanXInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_SUCCESS:
      return {
        ...state,
        rentalPlans: payload.rentalPlans,
        durationInMonths: payload.durationInMonths,
        loading: false,
      };
    case actionTypes.RENTAL_PLANS_X_ACTIONS.GET_RENTAL_PLANS_X_FAILED:
      return {
        ...state,
        rentalPlans:[],
        loading: false,
      };
    case actionTypes.CLEAR_DATA_ACTIONS_TYPE_X.CLEAR_DATA_RENTAL_PLAN_X:
      return {
        ...state,
        rentalPlans: {},
        durationInMonths: [],
      };
    default:
      return { ...prevState };
  }
};

export default rentalPlansReducerX;
