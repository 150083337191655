import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import { RootState } from "../../../../../redux/reducers";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";
import {
  LOB_TYPES,
  filterStorageKeys,
  typographyConstants,
  useCaseArray,
} from "../../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  FunctionalitiesIcon,
  PlanManagementIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import {
  planManagementActions,
  rentalActions,
} from "../../../../redux/actions";
import {
  clearBreadcrumbs,
  getBranchDefaultConfig,
  updateBreadcrumbs,
} from "../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../utils/RoutesConstants";

const initialState = {
  selectedBranch: {
    name: "",
    displayName: "",
    city_name: "",
  },
  selectedCity: {
    name: "",
    displayName: "",
    city_name: "",
  },
  selectedLob: {
    name: "",
    displayName: "",
  },
  selectedVehicle: {
    id: 0,
    name: "",
    displayName: "",
  },
};

const SelectPLanCategory = () => {
  const [fields, setFields] = useState({ ...initialState });
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const { allCitiesDetails, branches, modelListByBranch } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.ADD_PLAN));

    // Fetch data from local storage
    getFilterDataFromLocalStorage(filterStorageKeys.ADD_PLAN_FILTER_DATA).then(
      (data: any) => {
        if (data) {
          setFields((prev: any) => ({ ...prev, ...data }));
        }
      }
    );

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // update branch on city change
  useEffect(() => {
    dispatch(rentalActions.getAllBranches({ name: fields.selectedCity.name }));
  }, [fields.selectedCity.name]);

  const handleChange = (field: string, value: any) => {
    setFields((prevState: any) => ({ ...prevState, [field]: value }));
    if (field === "selectedCity") {
      setFields((prevState: any) => ({
        ...prevState,
        selectedBranch: initialState.selectedBranch,
        selectedVehicle: initialState.selectedVehicle,
      }));
      dispatch(rentalActions.getAllBranches({ name: value.name }));
    }

    if (field === "selectedBranch") {
      const { selectedCity } = fields;
      const payload = {
        branches: value?.name ? [value?.name] : null,
        city: selectedCity?.name ? selectedCity?.name : null,
      };
      // fetch only the selected branch vehicles
      dispatch(rentalActions.getModelListByBranch(payload));
      setFields((prevState: any) => ({
        ...prevState,
        [field]: value,
        selectedVehicle: initialState.selectedVehicle,
      }));
      return;
    }
  };

  const navigatetoPlanDetails = (isAlreadyExist = false): any => {
    navigate(routesConstants.ADD_EDIT_PLAN_DETAIL, {
      state: { ...fields, isAlreadyExist: isAlreadyExist },
    });
  };

  const handleContinue = () => {
    const { selectedBranch, selectedLob, selectedVehicle } = fields;

    dispatch(
      planManagementActions.getRentalPlanList({
        data: {
          branch: selectedBranch.name,
          model: selectedVehicle.name,
          useCase: selectedLob.name,
        },
        navigate: navigatetoPlanDetails,
        fetchPlanDetails: true,
        showConfirmationModal: toggleConfirmationModal,
      })
    );
    dispatch(getBranchDefaultConfig(selectedBranch?.name));

    // get config api call
    const payload = {
      branch: selectedBranch?.name,
      data: {
        lob: LOB_TYPES.LTR,
      },
    };

    dispatch(rentalActions.getOperatorConfigByBranch(payload));

    // store filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.ADD_PLAN_FILTER_DATA]: { ...fields },
    });
    return;
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal((showConfirmationModal) => !showConfirmationModal);
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "20px",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* <Box sx={{ display: "flex" }}> */}
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <img src={PlanManagementIcon} />
        <Typography variant={typographyConstants.SUBHEADING} fontSize="14px">
          {rentalEn.planManagement.selectPlanManagement}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          // flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {/* SELECT CITY */}
        <CustomSelect
          required
          icon={CityInputIcon}
          placeholder={rentalEn.global.SelectCity}
          value={fields.selectedCity.displayName}
          choice={allCitiesDetails}
          defaultKey={"displayName"}
          handleChange={(city: any) => {
            handleChange("selectedCity", city);
          }}
          // width={240}
        />
        {/* SELECT HUB */}
        <CustomSelect
          required
          icon={WarehouseIcon}
          placeholder={rentalEn.global.SelectBranch}
          value={
            isArrayNotEmpty(branches) ? fields.selectedBranch.displayName : ""
          }
          choice={branches ?? []}
          defaultKey={"displayName"}
          disabled={!fields.selectedCity.displayName || branches?.length === 0}
          handleChange={(branch: any) => {
            handleChange("selectedBranch", branch);
          }}
          // width={240}
        />
        {/* SELECT MODEL */}
        <CustomSelect
          required
          icon={BikeIconFaded}
          placeholder={rentalEn.global.SelectVehicle}
          value={fields.selectedVehicle.displayName}
          choice={modelListByBranch}
          defaultKey={"displayName"}
          disabled={
            !fields.selectedBranch.displayName ||
            modelListByBranch?.length === 0
          }
          handleChange={(city: any) => {
            handleChange("selectedVehicle", city);
          }}
          // width={240}
        />

        {/* SELECT PLAN OF BUSINESS */}
        <CustomSelect
          required
          icon={FunctionalitiesIcon}
          placeholder={rentalEn.global.selectUseCase}
          value={fields.selectedLob.displayName}
          choice={useCaseArray}
          defaultKey={"displayName"}
          handleChange={(lobType: any) => {
            handleChange("selectedLob", lobType);
          }}
          // width={240}
        />
      </Box>
      <Box>
        <CustomButton
          label={rentalEn.planManagement.continue}
          variant="contained"
          onClick={handleContinue}
          disabled={
            !fields.selectedBranch.displayName ||
            !fields.selectedVehicle.displayName ||
            !fields.selectedLob.name
          }
        />
      </Box>
      {/* confirnmation dialog on status change  */}
      <ResponsiveDialog
        title={rentalEn.planManagement.planAlreadyExist}
        body={rentalEn.planManagement.updateExistingPlanConfirmation}
        open={showConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleYes={() => navigatetoPlanDetails(true)}
      />
    </Box>
    // </Box>
  );
};

export default SelectPLanCategory;
