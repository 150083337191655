import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { RootState } from "../../../../../redux/reducers";
import { cloudUploadIcon } from "../../../../../assets/exportImages";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import {
  DocumentUploadConstants,
  KycTypes,
  OtherDocumentDisplayTypes,
  OtherDocumentTypes,
  typographyConstants,
} from "../../../../constants/constants";
import {
  DefaultSelfieImg,
  DeleteIcon,
  GreenTickIcon,
  TooltipIcon,
  UploadFileIcon,
  imageDelete,
  NotesCheck,
  alertNotes,
} from "../../../../constants/exportImages";
import { colors } from "../../../../../themes/colors";
import styles from "./section.module.css";
import { Bull } from "../../../../../sharedComponents/atoms/Bull/Bull";
import {
  getKYCSignedUrl,
  resetOtherDocsAttachmentId,
} from "../../../../redux/actions/customerManagementActions";
import { toastr } from "react-redux-toastr";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TaskOutlined as TaskOutlinedIcon } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { BootstrapTooltip } from "../../component/KycTrustScoreChips";
import rentalEn from "../../../../locale/rental-en.json";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

interface previewInterface {
  VOTER_ID: {
    name: string;
    url: string;
  };
  BANK_DETAIL: {
    name: string;
    url: string;
  };
  ELECTRICITY_BILL: {
    name: string;
    url: string;
  };
  RENT_BILL: {
    name: string;
    url: string;
  };
  PASSPORT: {
    name: string;
    url: string;
  };
  OTHER: {
    name: string;
    url: string;
  };
}
const initialPreview: previewInterface = {
  VOTER_ID: {
    name: "",
    url: "",
  },
  BANK_DETAIL: {
    name: "",
    url: "",
  },
  ELECTRICITY_BILL: {
    name: "",
    url: "",
  },
  RENT_BILL: {
    name: "",
    url: "",
  },
  PASSPORT: {
    name: "",
    url: "",
  },
  OTHER: {
    name: "",
    url: "",
  },
};

interface generateSignedUrlPayload {
  requestdata: {
    data: {
      fileNames: string[];
    };
  };
  filesData: {
    customerId: string;
    files: object[];
    fileNames: string[];
  };
  kycPayloadObj: any;
  kycSubType: string;
}

const initialOtherDocsUploading: any = {
  VOTER_ID: false,
  BANK_DETAIL: false,
  ELECTRICITY_BILL: false,
  RENT_BILL: false,
  PASSPORT: false,
  OTHER: false,
};
interface notesStatusInterface {
  isModified: boolean;
  upload: boolean;
  format: boolean;
  size: boolean;
}
const initialNotesStatus: notesStatusInterface = {
  isModified: false,
  upload: false,
  format: false,
  size: false,
};

export const OthersDocumentModal = ({
  customerId,
  otherDocsTrustScore,
  otherPayloadObj,
  individualKycDetailsData,
  individualKycDetailsLoader,
  handleSaveAndVerifyUploadToS3,
  OtherDocuments,
  handleDocsPreviewNavigation,
  fetchOtherDocTypesDetails,
  handleDeleteCustomerKyc,
  isFunctionalityBlocked,
  isKycDeletePermissionEnabled,
}: any) => {
  const dispatch = useDispatch();
  const { uploadLoader, signedUrlFailedFiles, linearProgressValue } =
    useSelector((state: RootState) => state.customerManagementReducer);

  const [otherDocsPreview, setOtherDocsPreview] = useState(initialPreview);
  const [otherDocsUploading, setOtherDocsUploading] = useState(
    initialOtherDocsUploading
  );
  const [expanded, setExpanded] = useState<string | false>("");
  const [openDeleteOtherDocsConfirmation, setOpenDeleteOtherDocsConfirmation] =
    useState("");
  const [notesStatus, setNotesStatus] = useState(initialNotesStatus);

  let isPreviewPresent = false;
  if (
    (otherDocsPreview["VOTER_ID"]["name"] &&
      otherDocsPreview["VOTER_ID"]["url"]) ||
    (otherDocsPreview["BANK_DETAIL"]["name"] &&
      otherDocsPreview["BANK_DETAIL"]["url"]) ||
    (otherDocsPreview["ELECTRICITY_BILL"]["name"] &&
      otherDocsPreview["ELECTRICITY_BILL"]["url"]) ||
    (otherDocsPreview["RENT_BILL"]["name"] &&
      otherDocsPreview["RENT_BILL"]["url"]) ||
    (otherDocsPreview["PASSPORT"]["name"] &&
      otherDocsPreview["PASSPORT"]["url"]) ||
    (otherDocsPreview["OTHER"]["name"] && otherDocsPreview["OTHER"]["url"])
  ) {
    isPreviewPresent = true;
  }
  // handle Other docs delete toggle
  const toggleDeleteOtherConfirmation = () => {
    setOpenDeleteOtherDocsConfirmation("");
  };
  const handleSettingDocsPreview = (
    OtherType: any,
    url: any,
    fileName: any
  ) => {
    setOtherDocsPreview((prev: any) => {
      return {
        ...prev,
        [OtherDocumentTypes[OtherType as keyof typeof OtherDocumentTypes]]: {
          url: url,
          name: fileName,
        },
      };
    });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const dropZoneSetting = {
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    maxSize: 5000000,
    multiple: false,
    noClick: uploadLoader,
    noDrag: uploadLoader,
    disabled: isFunctionalityBlocked,
  };
  const isKycPresent = otherDocsTrustScore?.isPresent;
  const kycIdDetailsArr = otherDocsTrustScore?.details
    ? otherDocsTrustScore?.details
    : [];
  const kycIdDetailsArrLength = kycIdDetailsArr.length;
  const OtherDocumentsLength = OtherDocuments.length;

  const OtherDocumentDetails = individualKycDetailsData?.details;
  const OtherDocumentDetailsFiles =
    OtherDocumentDetails?.files.length > 0
      ? OtherDocumentDetails?.files[0]
      : {};

  let showAttachment = false;
  showAttachment =
    individualKycDetailsData && otherDocsTrustScore?.isPresent ? true : false;

  // Initiate selfie upload : generate signed url
  const generateSignedUrl = (
    fileNames: string[],
    files: any,
    documentType: string
  ) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        customerId: customerId,
        files,
        fileNames,
      },
      kycPayloadObj: otherPayloadObj,
      kycSubType: documentType,
    };
    dispatch(getKYCSignedUrl(payload));
  };

  // Handle voter id
  const onDropVoterId = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(OtherDocumentTypes.VOTER_ID, url, file.name);
        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.VOTER_ID]: {
              url: URL.createObjectURL(file),
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, OtherDocumentTypes.VOTER_ID);
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Handle bank detail
  const onDropBankDetail = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(
            OtherDocumentTypes.BANK_DETAIL,
            url,
            file.name
          );
        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.BANK_DETAIL]: {
              url: URL.createObjectURL(file),
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, OtherDocumentTypes.BANK_DETAIL);
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Handle on drop electricity bill
  const onDropElectricityBill = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(
            OtherDocumentTypes.ELECTRICITY_BILL,
            url,
            file.name
          );
        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.ELECTRICITY_BILL]: {
              url: URL.createObjectURL(file),
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(
          fileNames,
          files,
          OtherDocumentTypes.ELECTRICITY_BILL
        );
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Handle on drop rent bill
  const onDropRentBill = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(
            OtherDocumentTypes.RENT_BILL,
            url,
            file.name
          );
        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.RENT_BILL]: {
              url: URL.createObjectURL(file),
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, OtherDocumentTypes.RENT_BILL);
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Handle on drop passport
  const onDropPassport = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(OtherDocumentTypes.PASSPORT, url, file.name);

        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.PASSPORT]: {
              url: URL.createObjectURL(file),
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, OtherDocumentTypes.PASSPORT);
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Handle on Drop Other documents
  const onDropOther = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        let fileExtension = file.name.split(".").pop();
        let url = "";
        if (
          ["PDF", "pdf"].includes(fileExtension)
            ? (url = "pdf")
            : (url = URL.createObjectURL(file))
        )
          handleSettingDocsPreview(OtherDocumentTypes.OTHER, url, file.name);
        /* setOtherDocsPreview((prev: any) => {
          return {
            ...prev,
            [OtherDocumentTypes.OTHER]: {
              url: url,
              name: file.name,
            },
          };
        }); */
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorOtherDocs
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);

          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, OtherDocumentTypes.OTHER);
      }
      // setRejectedFile(rejectedFiles);
    },
    []
  );

  // Voter Id Dropzone
  const {
    getRootProps: getRootPropsVoterId,
    getInputProps: getInputPropsVoterId,
  } = useDropzone({
    onDrop: onDropVoterId,
    ...dropZoneSetting,
  });

  // Bank Detail Dropzone
  const {
    getRootProps: getRootPropsBankDetail,
    getInputProps: getInputPropsBankDetail,
  } = useDropzone({
    onDrop: onDropBankDetail,
    ...dropZoneSetting,
  });

  // Electricity Bill Dropzone
  const {
    getRootProps: getRootPropsElectricityBill,
    getInputProps: getInputPropsElectricityBill,
  } = useDropzone({
    onDrop: onDropElectricityBill,
    ...dropZoneSetting,
  });

  //
  // Rent Bill Dropzone
  const {
    getRootProps: getRootPropsRentBill,
    getInputProps: getInputPropsRentBill,
  } = useDropzone({
    onDrop: onDropRentBill,
    ...dropZoneSetting,
  });

  // Passport Dropzone
  const {
    getRootProps: getRootPropsPassport,
    getInputProps: getInputPropsPassport,
  } = useDropzone({
    onDrop: onDropPassport,
    ...dropZoneSetting,
  });

  // Other Dropzone
  const { getRootProps: getRootPropsOther, getInputProps: getInputPropsOther } =
    useDropzone({
      onDrop: onDropOther,
      ...dropZoneSetting,
    });

  // delete preview and attachment id
  const handleDeleteOtherDocs = (type: string) => {
    // reset preview
    setOtherDocsPreview((prev) => ({
      ...prev,
      [type]:
        initialPreview[
          OtherDocumentTypes[type as keyof typeof OtherDocumentTypes]
        ],
    }));

    // delete attachmentId from reducer
    const otherDocPayloadObj = otherPayloadObj;
    otherDocPayloadObj["details"][type]["attachmentIds"] = [];
    const resetOtherDocPayload = {
      OtherDocType: { type },
    };
    dispatch(resetOtherDocsAttachmentId(otherDocPayloadObj));
  };

  useEffect(() => {
    if (
      (otherPayloadObj?.details?.VOTER_ID?.attachmentIds?.length > 0 &&
        otherDocsPreview.VOTER_ID.url) ||
      (otherPayloadObj?.details?.BANK_DETAIL?.attachmentIds?.length > 0 &&
        otherDocsPreview.BANK_DETAIL.url) ||
      (otherPayloadObj?.details?.ELECTRICITY_BILL?.attachmentIds?.length > 0 &&
        otherDocsPreview.ELECTRICITY_BILL.url) ||
      (otherPayloadObj?.details?.RENT_BILL?.attachmentIds?.length > 0 &&
        otherDocsPreview.RENT_BILL.url) ||
      (otherPayloadObj?.details?.PASSPORT?.attachmentIds?.length > 0 &&
        otherDocsPreview.PASSPORT.url) ||
      (otherPayloadObj?.details?.OTHER?.attachmentIds?.length > 0 &&
        otherDocsPreview.OTHER.url)
    ) {
      setNotesStatus((prev: any) => ({
        ...prev,
        isModified: true,
        format: true,
        size: true,
      }));
    }
  }, [
    otherPayloadObj?.details?.VOTER_ID?.attachmentIds?.length,
    otherPayloadObj?.details?.BANK_DETAIL?.attachmentIds?.length,
    otherPayloadObj?.details?.ELECTRICITY_BILL?.attachmentIds?.length,
    otherPayloadObj?.details?.RENT_BILL?.attachmentIds?.length,
    otherPayloadObj?.details?.PASSPORT?.attachmentIds?.length,
    otherPayloadObj?.details?.OTHER?.attachmentIds?.length,
  ]);

  return (
    <>
      <Stack
        className={styles.KYCModal_Wrapper}
        sx={{
          // height: "auto",
          // gap: "1vh",
          justifyContent: "space-between",
          height: "-webkit-fill-available",
          gap: "10px",
        }}
      >
        <Stack sx={{ justifyContent: "space-between" }}>
          {/* map all document types in other document */}
          {OtherDocuments.map((type: string, index: number) => {
            let rootprops, inputProps;
            if (type === OtherDocumentTypes.VOTER_ID) {
              rootprops = { ...getRootPropsVoterId() };
              inputProps = { ...getInputPropsVoterId() };
            }
            if (type === OtherDocumentTypes.BANK_DETAIL) {
              rootprops = { ...getRootPropsBankDetail() };
              inputProps = { ...getInputPropsBankDetail() };
            }
            if (type === OtherDocumentTypes.ELECTRICITY_BILL) {
              rootprops = { ...getRootPropsElectricityBill() };
              inputProps = { ...getInputPropsElectricityBill() };
            }
            if (type === OtherDocumentTypes.RENT_BILL) {
              rootprops = { ...getRootPropsRentBill() };
              inputProps = { ...getInputPropsRentBill() };
            }
            if (type === OtherDocumentTypes.PASSPORT) {
              rootprops = { ...getRootPropsPassport() };
              inputProps = { ...getInputPropsPassport() };
            }
            if (type === OtherDocumentTypes.OTHER) {
              rootprops = { ...getRootPropsOther() };
              inputProps = { ...getInputPropsOther() };
            }
            const previewName =
              otherDocsPreview[
                OtherDocumentTypes[type as keyof typeof OtherDocumentTypes]
              ]["name"];
            const previewUrl =
              otherDocsPreview[
                OtherDocumentTypes[type as keyof typeof OtherDocumentTypes]
              ]["url"];
            let isPresentDocTypeFlag = false;
            let docKycId = "";
            kycIdDetailsArr.map((item: any) => {
              if (item.documentType === type) {
                isPresentDocTypeFlag = true;
                docKycId = item?.kycId;
                return true;
              }
              // return false;
            });
            let fileExtension =
              isPresentDocTypeFlag && OtherDocumentDetailsFiles?.fileName
                ? OtherDocumentDetailsFiles?.fileName.split(".").pop()
                : "";
            /* if(previewName && previewUrl ){
              setIsDocUploaded(true);
            } */
            return (
              <>
                {isKycPresent && isPresentDocTypeFlag ? (
                  <>
                    {/* -------------------- confirmation modals ------------------------------------ */}
                    {openDeleteOtherDocsConfirmation &&
                      openDeleteOtherDocsConfirmation === docKycId && (
                        <>
                          <ResponsiveDialog
                            title={
                              rentalEn.alertMessages.deleteOtherTypeKyc +
                              OtherDocumentDisplayTypes[
                                type as keyof typeof OtherDocumentDisplayTypes
                              ] +
                              "?"
                            }
                            open={openDeleteOtherDocsConfirmation === docKycId}
                            handleClose={() => toggleDeleteOtherConfirmation()}
                            handleYes={() =>
                              handleDeleteCustomerKyc(
                                KycTypes.OTHER_DOCUMENT,
                                docKycId
                              )
                            }
                          />
                        </>
                      )}
                    <Accordion
                      sx={{
                        borderBottom: "0.85px solid #00000014",
                        backgroundColor: "unset",
                        boxShadow: "none",
                        position: "unset",
                      }}
                      expanded={expanded === type}
                      onChange={handleChange(type)}
                    >
                      <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={[
                          {
                            alignItems: "center",
                            padding: "unset",
                            justifyContent: "center",
                          },
                        ]}
                        onClick={() =>
                          fetchOtherDocTypesDetails(type, docKycId)
                        }
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          width="100%"
                        >
                          <Stack direction={"row"} alignItems={"center"}>
                            <TaskOutlinedIcon
                              sx={{ fontSize: "35px", color: "#95979a" }}
                            />
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              {
                                OtherDocumentDisplayTypes[
                                  type as keyof typeof OtherDocumentDisplayTypes
                                ]
                              }
                            </Typography>
                          </Stack>

                          {!isFunctionalityBlocked &&
                            isKycDeletePermissionEnabled && (
                              <Stack
                                alignItems={"flex-end"}
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  setOpenDeleteOtherDocsConfirmation(docKycId);
                                }}
                              >
                                <DeleteOutlineOutlinedIcon
                                  /* onClick={(event: any) => {
                                    event.stopPropagation();
                                  }} */
                                  sx={{
                                    color: "indianred",
                                    cursor: "pointer",
                                  }}
                                />
                              </Stack>
                            )}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className={styles.OtherDocView_Wrapper}
                          key={type}
                          sx={{ flexDirection: "column", alignItems: "center" }}
                        >
                          {["PDF", "pdf"].includes(fileExtension) ? (
                            <PictureAsPdfIcon
                              onClick={() =>
                                handleDocsPreviewNavigation(
                                  OtherDocumentDetailsFiles?.fileName,
                                  fileExtension,
                                  OtherDocumentDetailsFiles?.signedUrl
                                )
                              }
                            />
                          ) : (
                            <Box
                              sx={{
                                background: `url(${OtherDocumentDetailsFiles?.signedUrl})`,
                                objectFit: "contain",
                                width: "100%",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                height: "100px",
                                cursor: "pointer",
                              }}
                              // name: string,extension: string,url: string
                              onClick={() =>
                                handleDocsPreviewNavigation(
                                  OtherDocumentDetailsFiles?.fileName,
                                  fileExtension,
                                  OtherDocumentDetailsFiles?.signedUrl
                                )
                              }
                            ></Box>
                          )}

                          <Typography
                            variant={typographyConstants?.SUBHEADING}
                            color={colors.text_secondary_gray}
                          >
                            {OtherDocumentDetailsFiles?.fileName}
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    {/* Upload Document :- Handling 3 phases Upload, Uploading, Preview  */}
                    {otherDocsUploading[type] && uploadLoader ? (
                      <>
                        {/* Uploading phase */}
                        <Stack
                          className={styles.OtheDocImgUpload_Wrapper}
                          key={type}
                          {...rootprops}
                        >
                          <Stack
                            direction="row"
                            gap="4px"
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            width={"100%"}
                          >
                            {/* <input {...inputProps} /> */}
                            <img src={UploadFileIcon} alt="" />
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              {
                                otherDocsPreview[
                                  OtherDocumentTypes[
                                    type as keyof typeof OtherDocumentTypes
                                  ]
                                ]["name"]
                              }
                            </Typography>
                          </Stack>
                          <LinearProgress
                            value={linearProgressValue}
                            variant="determinate"
                            sx={{ width: "100%" }}
                          />
                        </Stack>
                        {otherDocsUploading[type]} {uploadLoader}
                      </>
                    ) : (
                      <>
                        {/* Preview Phase */}
                        {previewName && previewUrl ? (
                          <>
                            <Box
                              className={styles.OtheDocImgUpload_Wrapper}
                              key={type}
                              {...rootprops}
                              sx={{ cursor: "pointer" }}
                            >
                              <Stack
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                width={"100%"}
                                onClick={() =>
                                  setOtherDocsUploading({
                                    ...initialOtherDocsUploading,
                                    [type]: true,
                                  })
                                }
                              >
                                <Stack
                                  direction="row"
                                  alignItems={"center"}
                                  gap="5px"
                                >
                                  <input {...inputProps} />
                                  {["PDF", "pdf"].includes(previewUrl) ? (
                                    <PictureAsPdfIcon
                                      height="40px"
                                      width="40px"
                                    />
                                  ) : (
                                    <img
                                      src={previewUrl}
                                      alt=""
                                      height="40px"
                                      width="40px"
                                    />
                                  )}
                                  <BootstrapTooltip title={previewName}>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.text_secondary_gray}
                                      sx={{
                                        width: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {previewName}
                                    </Typography>
                                  </BootstrapTooltip>
                                </Stack>

                                {!isFunctionalityBlocked && (
                                  <img
                                    src={imageDelete}
                                    alt="delete attachmnet"
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      setOpenDeleteOtherDocsConfirmation(type);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </Stack>
                            </Box>
                            {/* -------------------- confirmation modals ------------------------------------ */}
                            {openDeleteOtherDocsConfirmation &&
                              openDeleteOtherDocsConfirmation === type && (
                                <>
                                  <ResponsiveDialog
                                    title={
                                      rentalEn.alertMessages
                                        .deleteOtherTypeKyc +
                                      OtherDocumentDisplayTypes[
                                        type as keyof typeof OtherDocumentDisplayTypes
                                      ] +
                                      " preview ?"
                                    }
                                    open={
                                      openDeleteOtherDocsConfirmation === type
                                    }
                                    handleClose={() =>
                                      toggleDeleteOtherConfirmation()
                                    }
                                    handleYes={() =>
                                      handleDeleteOtherDocs(type)
                                    }
                                  />
                                </>
                              )}
                          </>
                        ) : (
                          <>
                            {/* Default Upload Phase */}
                            <Box
                              className={styles.OtheDocImgUpload_Wrapper}
                              key={type}
                              {...rootprops}
                              sx={{ cursor: "pointer" }}
                            >
                              <Box
                                onClick={() =>
                                  setOtherDocsUploading({
                                    ...initialOtherDocsUploading,
                                    [type]: true,
                                  })
                                }
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "4px",
                                  alignItems: "center",
                                }}
                              >
                                <input {...inputProps} />
                                <img src={UploadFileIcon} alt="" />
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.text_secondary_gray}
                                >
                                  {
                                    OtherDocumentDisplayTypes[
                                      type as keyof typeof OtherDocumentDisplayTypes
                                    ]
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
          })}

          {/* Notes */}
          {!(
            showAttachment === true && OtherDocumentDetailsFiles.length > 0
          ) && (
            <Stack>
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={"#292D32"}
              >
                Notes:
              </Typography>
              <Box>
                <Stack direction="row" gap="5px" alignItems={"center"}>
                  {notesStatus?.isModified ? (
                    <>
                      {notesStatus?.format ? (
                        <img
                          src={NotesCheck}
                          alt={"Checked"}
                          title={"Accepted"}
                          style={{ height: "1rem" }}
                        />
                      ) : (
                        <img
                          src={alertNotes}
                          alt={"Checked"}
                          title={"Accepted"}
                          style={{ height: "1rem" }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Box>
                        <FiberManualRecordIcon
                          sx={{
                            height: "0.5rem",
                            color: colors.tertiary_black,
                            width: "unset",
                          }}
                        />
                      </Box>
                    </>
                  )}
                  <Typography
                    variant={typographyConstants?.BODY}
                    color={colors.tertiary_black}
                  >
                    {" "}
                    Supported Formats: Jpeg, Png, jpg or pdf.
                  </Typography>
                </Stack>
                <Stack direction="row" gap="5px" alignItems={"center"}>
                  {notesStatus?.isModified ? (
                    <>
                      {notesStatus?.size ? (
                        <img
                          src={NotesCheck}
                          alt={"Checked"}
                          title={"Accepted"}
                          style={{ height: "1rem" }}
                        />
                      ) : (
                        <img
                          src={alertNotes}
                          alt={"Checked"}
                          title={"Accepted"}
                          style={{ height: "1rem" }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Box>
                        <FiberManualRecordIcon
                          sx={{
                            height: "0.5rem",
                            color: colors.tertiary_black,
                            width: "unset",
                          }}
                        />
                      </Box>
                    </>
                  )}
                  <Typography
                    variant={typographyConstants?.BODY}
                    color={colors.tertiary_black}
                  >
                    {" "}
                    File size should be less than 5 MB.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          )}
        </Stack>

        {/* Action Button */}
        {kycIdDetailsArrLength !== OtherDocumentsLength &&
          !isFunctionalityBlocked && (
            <Box>
              <CustomButton
                variant="contained"
                sx={{ width: "100%" }}
                label={"Save & verify now"}
                onClick={() =>
                  handleSaveAndVerifyUploadToS3(KycTypes.OTHER_DOCUMENT)
                }
                disabled={!isPreviewPresent || linearProgressValue < 100 || uploadLoader}
              />
            </Box>
          )}
      </Stack>
    </>
  );
};
