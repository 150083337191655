import { call, put, takeLatest } from "redux-saga/effects";
import TokenService from "../../config/tokenService";
import { actions } from "..";
import en from "../../locale/en.json";
import feedbackServices from "../../services/feedbackServices";
import * as actionTypes from "../actions/actionTypes";
import { ToastType } from "../../constants/userActions";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utils/helper";

function* fetchFeedbacks(action: any): any {
  const data = yield call(feedbackServices.getFeedbacks, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKSFETCH_SUCCESS,
      payload: {
        ...data.data,
        current_city: action.payload.current_city,
        current_location: action.payload.current_location,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKSFETCH_FAILED,
    });
  }
}

function* getFeedbacks(action: any): any {
  const data = yield call(feedbackServices.getFeedbackService, action.payload);
  if (data?.status === 200) {

    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_DATA_SUCCESS,
      payload: {
      newFeedBacksData : data?.data?.Result?.feedbacks ?? [],
      totalItems : data?.data?.Result?.total,
      
      }, 
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_DATA_FAILED,
    });
  }
}


function* getFeedbackById(action: any): any {
  const data = yield call(feedbackServices.getFeedbackById, action.payload);
  if (data?.status === 200) {

    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_SUCCESS,
      payload: {
      feedBacksById : data?.data?.Result
      
      
      }, 
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_FAILED,
    });
  }
}


function* getFeedbackByBookingId(action: any): any {
  const data = yield call(feedbackServices.getFeedbackByBookingId, action.payload);
  if (data?.status === 200) {

    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_SUCCESS,
      payload: {
      feedBacksByBookingId : data?.data?.Result?.feedbacks ?? []
      
      
      }, 
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_FAILED,
    });
  }
}


function* submitExecutiveFeedBack(action: any): any {
  try {
    const data = yield call(
      feedbackServices.submitExecutiveFeedBack,
      action.payload
    );
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.FEEDBACK_ACTIONS
          .EXECUTIVE_FEEDBACK_SUCCESS,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.FEEDBACK_ACTIONS
          .EXECUTIVE_FEEDBACK_FAILED,
      });
    }
  } catch (ex: any) {
    toastr.error("", ex);
    yield put({
      type: actionTypes.FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_FAILED,
    });
  }
}
function* feedbackSaga() {
  yield takeLatest(
    actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_REQUESTED,
    fetchFeedbacks
  );
  yield takeLatest(
    actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_DATA_REQUESTED,
    getFeedbacks
  );
  yield takeLatest(
    actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_REQUESTED,
    getFeedbackById
  );
  yield takeLatest(
    actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_REQUESTED,
    getFeedbackByBookingId
  );
  yield takeLatest(
    actionTypes.FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_REQUESTED,
    submitExecutiveFeedBack
  );
}

export default feedbackSaga;
