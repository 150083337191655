import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../css/customcss/font.css";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import {
  decryptingText,
  encrypt,
  encryptingText,
  matchSamePassword,
  validatePasswordPolicies,
} from "../../utils/helper";
import { AuthButton } from "./components/authButton";
import { AuthContainer } from "./components/authContainer";
import { ContainerMsg } from "./components/authContainerMsg";
import { AuthInputPassword } from "./components/authInputs";
import { ProgressBar } from "./components/authProgressBar";
import { PolicyBox } from "./components/policyBox";
import "./newAuthCss/global.css";
import "./newAuthCss/layout.css";

interface IObjectKeys {
  [key: string]: string;
}

interface Itag extends IObjectKeys {
  tag: string;
  value: string;
}

interface Ifields extends IObjectKeys {
  password: string;
  confirmPassword: string;
}

function SetPassword() {
  const { locale } = useSelector((state: RootState) => state?.localeReducer);
  const { setPasswordLoader, isNewPasswordSet, linkVerifyData } = useSelector(
    (state: RootState) => state?.newAuthReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  /* const encryptedLink = link?.search?.split("?")[1]; */
  const { encryptedLink, useCase }: any = location?.state;

  const [fields, setFields] = useState<Ifields>({
    password: "",
    confirmPassword: "",
  });
  const [errFields, setErrFields] = useState<Ifields>({
    password: "",
    confirmPassword: "",
  });

  const [policyChecks, setPolicychecks] = useState({
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasRepeatedSequence: false,
    status: false,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [passComplexity, setPassComplexity] = useState<string>();

  const handleChange = (props: Itag): void => {
    let checkPolicy: any;
    if (props?.tag === "password") {
      setFields({ ...fields, password: props?.value });

      const passMatch = matchSamePassword(
        props?.value,
        fields?.confirmPassword
      );

      !passMatch?.status && fields?.confirmPassword != ""
        ? setErrFields({
            ...errFields,
            confirmPassword: locale?.newAuth?.confirmPasswordErr,
          })
        : setErrFields({ ...errFields, confirmPassword: "" });

      checkPolicy = validatePasswordPolicies(props?.value);

      checkPolicy?.status
        ? setPassComplexity(checkPolicy?.passwordComplexity)
        : setPassComplexity("");

      if (checkPolicy?.hasRepeatedSequence) {
        setErrFields({
          ...errFields,
          password: locale?.newAuth?.noSequentialWords,
        });
      }
      setPolicychecks({
        ...policyChecks,
        hasLowercase: checkPolicy?.policyChecks?.hasLowercase,
        hasUppercase: checkPolicy?.policyChecks?.hasUppercase,
        hasMinLength: checkPolicy?.policyChecks?.hasMinLength,
        hasNumber: checkPolicy?.policyChecks?.hasNumber,
        hasRepeatedSequence: checkPolicy?.policyChecks?.hasRepeatedSequence,
        hasSpecialCharacter: checkPolicy?.policyChecks?.hasSpecialCharacter,
        status: checkPolicy?.status,
      });
    } else if (props?.tag === "confirmPassword") {
      setFields({ ...fields, confirmPassword: props?.value });

      const passMatch = matchSamePassword(fields?.password, props?.value);

      if (!passMatch.status) {
        setErrFields({
          ...errFields,
          confirmPassword: locale?.newAuth?.confirmPasswordErr,
        });
      } else {
        setErrFields({ ...errFields, confirmPassword: "" });
      }
    }
  };

  const validate = () => {
    if (fields?.password && fields?.confirmPassword) {
      onSubmit();
    }
  };

  const onClickCross = () => {
    setPolicychecks((prev: any) => ({ ...prev, status: true }));
  };

  const onSubmit = () => {
    dispatch(
      actions.setPassword({
        data: {
          encData: encryptedLink,
          password: encryptingText(fields.password),
          useCase,
        },
      })
    );
  };

  useEffect(() => {
    if (isNewPasswordSet) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [isNewPasswordSet]);

  return (
    <>
      <AuthContainer
        heading={locale?.newAuth?.setPasswordHeading}
        componentFields={
          <>
            {policyChecks?.hasRepeatedSequence ? (
              <ContainerMsg message={locale?.newAuth?.noSequentialWords} />
            ) : (
              ""
            )}
            <h3 className="uname-fd">{`${locale?.newAuth?.helloMsg}${linkVerifyData?.name}`}</h3>

            <AuthInputPassword
              value={fields?.password}
              label={locale?.Auth?.password}
              placeholder={locale?.newAuth?.enterPassword}
              inputClassName={"form-control pw-input"}
              viewPassEye={"true"}
              eyeClassName={`fa fa-eye${showPassword ? "" : "-slash"}`}
              type={showPassword ? "text" : "password"}
              ariaHidden={!showPassword ? "true" : "false"}
              errorMessage={errFields?.password}
              onChange={(e: any) => {
                handleChange({ tag: "password", value: e?.target?.value });
              }}
              onClick={(e: any) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            />

            <ProgressBar passComplexity={passComplexity} />

            <AuthInputPassword
              type={"password"}
              value={fields?.confirmPassword}
              label={locale?.newAuth?.confirmPassword}
              placeholder={locale?.newAuth?.enterConfirmPassword}
              inputClassName={`form-control ${
                !errFields?.confirmPassword ? "" : "error-input"
              }`}
              viewPassEye={"false"}
              errorMessage={errFields?.confirmPassword}
              onChange={(e: any) => {
                handleChange({
                  tag: "confirmPassword",
                  value: e.target.value,
                });
              }}
            />
            <AuthButton
              type="submit"
              title={locale?.global?.Submit}
              loading={setPasswordLoader}
              /* className={`btn w-100 login-btn mt-3 ${
                !errFields.confirmPassword &&
                passComplexity != "" &&
                fields.confirmPassword != ""
                  ? ""
                  : "disable"
              }`} */
              disabled={
                !fields?.password ||
                !fields?.confirmPassword ||
                !passComplexity ||
                errFields?.confirmPassword
              }
              onClick={validate}
            />
            {/* {showMessage ? (
              <MessagePopUp
                className={success && !error ? "pw-sucess" : "pw-error"}
                message={message}
                onClick={() => {
                  dispatch(actions.showMessagePopUp({ showMessage: false }));
                }}
              />
            ) : null} */}

            <div className="unlock-account">
              {" "}
              <Link to={`/`}>{locale?.newAuth?.visitLogin}</Link>{" "}
            </div>
          </>
        }
        policyBox={
          !policyChecks?.status ? (
            <PolicyBox
              policyChecks={policyChecks}
              onClickCross={onClickCross}
            />
          ) : (
            ""
          )
        }
      />
    </>
  );
}

export default SetPassword;
