import {
  Box,
  CircularProgress,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef } from "react";
import { colors } from "../../../../../themes/colors";
import en from "../../../../locale/rental-en.json";
import En from "../../../../locale/rental-en.json";
import { useDispatch } from "react-redux";
import { getSignedUrl } from "../../../../redux/actions/businessManagementActions";
import { MITC_FORM_URL } from "../../../../../config";
import { toastr } from "react-redux-toastr";

const UploadCityImages = (props: any) => {
  const inputRef = useRef<any>(null);
  const [imgPreview, setImgPreview] = useState<any>("");
  const allowedImageFormats = ['image/jpeg','image/jpg','image/jfif','image/png'];
  const dispatch = useDispatch();
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
  const fileType = fileObj.type;
  // Check if the file is an image
  if (!fileObj || !allowedImageFormats.includes(fileType)) {
    if(!allowedImageFormats?.includes(fileType)) {
      toastr.warning("",En.errorMessages.imageType)
      return;
    }
    return;
  }
    if (fileObj?.size >= 5000000) {
      toastr.warning("", En.errorMessages.imageSize);
    }
    else {
      setImgPreview(fileObj);
    }
    // 👇️ Reset file input
    event.target.value = null;
  };

  useEffect(() => {
    if (imgPreview) {
      dispatch(
        getSignedUrl({
          data: {
            data: {
              module: "CITY",
              files: [
                {
                  fileName: imgPreview.name,
                  type: "THUMBNAIL",
                },
              ],
            },
          },
          file: imgPreview,
        })
      );
    }
  }, [imgPreview]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {props.imageLoader ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "solid",
              width: "150px",
              height: "133px",
              borderRadius: "5px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              border:
                imgPreview || props.cityData?.images?.length
                  ? "solid"
                  : "dotted",
              width: "150px",
              height: "133px",
              borderRadius: "5px",
              color: colors.link_Water,
              display: "flex",
              padding:
                imgPreview || props.cityData?.images?.length ? "0" : "10px",
              backgroundImage: imgPreview
                ? `url(${URL.createObjectURL(imgPreview)})`
                : props.cityData?.images?.length
                ? `url('${MITC_FORM_URL}/${props?.cityData?.images[0]?.filePath}')`
                : "",
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor:
                !props.editAccess && Object.keys(props?.cityData)?.length
                  ? "not-allowed"
                  : "auto",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <Box sx={{ textAlign: "center" }}>
                {imgPreview || props.cityData?.images?.length ? (
                  ""
                ) : (
                  <CloudUploadIcon />
                )}
              </Box>
              <Box sx={{ textAlign: "center" }}>
                {imgPreview || props.cityData?.images?.length ? (
                  ""
                ) : (
                  <>
                    <Typography sx={{ margin: "10px" }}>
                      {en.NewBusinessManagement.uploadCityImage}
                    </Typography>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  textAlign:
                    imgPreview || props.cityData?.images?.length
                      ? "end"
                      : "center",
                }}
              >
                {imgPreview || props.cityData?.images?.length ? (
                  props.editAccess ? (
                    <CloudUploadIcon
                      color="secondary"
                      onClick={handleClick}
                      sx={{ margin: "5px" }}
                    />
                  ) : null
                ) : (
                  <AddBoxIcon color="secondary" onClick={handleClick} />
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/jfif"
                  onChange={handleFileChange}
                  disabled={
                    !props.editAccess && Object.keys(props?.cityData)?.length
                      ? true
                      : false
                  }
                />
              </Box>
            </Stack>
          </Box>
        )}
        <Box>
          <Stack>
            <List component="ul">
              <ListItem sx={{ fontSize: "0.7rem" }}>
                {en.NewBusinessManagement.acceptImageTypes}
              </ListItem>
              <ListItem sx={{ fontSize: "0.7rem" }}>
                {en.NewBusinessManagement.uploadImageSize}
              </ListItem>
            </List>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(UploadCityImages);
