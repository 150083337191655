import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { colors } from "../../../../themes/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEye,
  faAngleDown,
  faAngleUp,
  faEyeDropper,
  faEyeLowVision,
  faFaceRollingEyes,
  faBullseye,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GreenIcon,
  FinishIcon,
  ServicesIcon,
  OtherIcon,
  RemoveIcon,
} from "../../../../assets/exportImages";

interface CardProps {
  item?: any;
  subtaskLoading: boolean;
  handleTabChange?: () => void;
  OnDelete?: () => void;
  disableDeleteOperation?: boolean;
}

const CardPopup = ({ item }: any) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      key={item.id}
      sx={{
        borderRadius: "12px",
        position: "relative",
        justifyContent: "flex-end",
      }}
      className={`dragDrop-card-Ride`}
    >
      <Typography
        style={{
          color: colors.heading_main_text,
          fontSize: "14px",
          fontFamily: "EuclidCircularB-SemiBold",
          marginBottom: 4,
        }}
      >
        {item.taskName}
      </Typography>
      <div
        style={{
          marginBottom: 10,
          textAlign: "justify",
          width: "96%",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          display: "flex",
          minHeight: 40,
        }}
      >
        <Typography
          variant="body1"
          component="div"
          className={expanded ? "block-ellipsis" : ""}
          sx={{
            textAlign: "justify",
          }}
          style={{
            color: colors.card_body_text,
            fontSize: "12px",
            fontFamily: "EuclidCircularB-Regular",
            width: "98%",
          }}
        >
          {item.taskDescription}
        </Typography>
        <FontAwesomeIcon
          onClick={handleExpandClick}
          icon={expanded ? faAngleDown : faAngleUp}
          color={colors.card_body_text}
          style={{ width: 14, height: 14 }}
        />
      </div>

      <Typography
        style={{
          color: colors.card_body_text_second,
          fontSize: "12px",
          fontFamily: "EuclidCircularB-Regular",
          marginBottom: 5,
        }}
      >
        <span
          style={{
            color: colors.card_body_text_second,
            fontSize: "12px",
            fontFamily: "EuclidCircularB-Medium",
            marginBottom: 2,
          }}
        >
          SLA :
        </span>{" "}
        {item.sla} min
      </Typography>
      <Typography
        style={{
          color: colors.heading_main_text,
          fontSize: "14px",
          fontFamily: "EuclidCircularB-Medium",
          marginBottom: 10,
        }}
      >
        Verification by
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          gap: 4,
          marginTop: 10,
        }}
      >
        {/* {item.chip?.map((item: any, index: number) => (
          <Chip
            key={index}
            style={{
              minWidth: "80px",
              backgroundColor:
                item == "Image"
                  ? colors.chip_background_colorG
                  : colors.chip_background_cgray,
              color:
                item == "Image"
                  ? colors.chip_text_colorG
                  : colors.chip_text_cgray,
              marginRight: 5,
              fontSize: "12px",
              fontFamily: "EuclidCircularB-Medium",
            }}
            label={item}
            size="small"
          />
        ))} */}

        {item?.imageRequired ? (
          <Chip
            style={{
              minWidth: "80px",
              backgroundColor: colors.chip_background_cgray,
              color: colors.chip_text_cgray,
              marginRight: 5,
              fontSize: "12px",
              fontFamily: "EuclidCircularB-Medium",
            }}
            label="Image"
            size="small"
          />
        ) : null}

        {item?.remarkRequired ? (
          <Chip
            style={{
              minWidth: "80px",
              backgroundColor: colors.chip_background_colorG,
              color: colors.chip_text_colorG,
              marginRight: 5,
              fontSize: "12px",
              fontFamily: "EuclidCircularB-Medium",
            }}
            label="Remark"
            size="small"
          />
        ) : null}
      </div>
    </Card>
  );
};

const RideCard = ({
  item,
  OnDelete,
  subtaskLoading,
  disableDeleteOperation = false,
}: CardProps) => {
  const [showBox, setShowBox] = useState<boolean>(false);

  return (
    <div>
      {showBox ? (
        <div
          className="CardPopupStyle"
          onMouseOver={() => setShowBox(true)}
          onMouseOut={() => setShowBox(false)}
        >
          <CardPopup item={item} />
        </div>
      ) : null}

      <Grid
        style={{
          marginTop: 3,
          marginBottom: 14,
          width: `90%`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: 18 }}>
          <Tooltip title="Click to rearrange the task" placement="top-start">
            <div>
              <FontAwesomeIcon icon={faBars} color={colors.dark_gray} />
            </div>
          </Tooltip>
        </div>
        <Grid
          key={item.id}
          style={{
            width: "100%",
            border: `1px solid ${colors.border_cell}`,
            borderRadius: "8px",
            height: "35px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: colors.card_body_text,
              fontSize: "14px",
              fontFamily: "EuclidCircularB-Medium",
              fontStretch: "normal",
              textAlign: "center",
              wordBreak: "keep-all",
            }}
          >
            {item.taskName}
          </Typography>
          <div
            // onClick={() => setShowBox((value) => !value)}
            onMouseOver={() => setShowBox(true)}
            onMouseOut={() => setShowBox(false)}
            style={{
              width: "25px",
              height: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faEye}
              color={showBox ? colors.primary : colors.dark_gray}
            />
          </div>
        </Grid>
        {!disableDeleteOperation && (
          <div style={{ marginLeft: 18 }} onClick={OnDelete}>
            <img
              src={RemoveIcon}
              style={{ height: "18px", width: "18px", cursor: "pointer" }}
            />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default RideCard;
