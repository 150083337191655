import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { colors } from "../themes/colors";

export default function ResponsiveDialog(props: any) {
  return (
    <div>
      <Dialog
        maxWidth={props.maxWidth || "xs"}
        fullScreen={props.fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          style={{ fontSize: "16px", ...props.titlestyle, textAlign: "center" }}
          id="responsive-dialog-title"
        >
          {props.title}
        </DialogTitle>
        {props.hideDivider ? null : <Divider style={{ width: "100%" }} />}
        {props.body ? (
          <DialogContent>
            <DialogContentText>{props.body}</DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>{props.children}</DialogActions>
      </Dialog>
    </div>
  );
}
