import * as React from "react";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { colors } from "../themes/colors";
import { Grid, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";

interface BasicDatePickerInterface {
  small?: boolean;
  className?: string;
}

function DatePicker1({ className }: BasicDatePickerInterface) {
  const [value, setValue] = React.useState<Date | null>(new Date());

  return (
    <div className={`date-picker ${className}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          views={["day", "month", "year"]}
          inputFormat="dd.MM.yyyy"
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <input
                ref={inputRef}
                {...inputProps}
                style={{ borderWidth: 0, backgroundColor: colors.light_gray }}
              />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
interface BasicDatePickerInterface {
  small?: boolean;
  className?: string;
  margin?: string;
  width?: string | number;
  dateValue?: any;
  setDateValueFunc?: any;
  label?: any;
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
}

function BasicDatePicker(dateParams: any) {
  const {
    small,
    className,
    margin,
    dateValue,
    width,
    setDateValueFunc,
    disabled,
    placeholder,
  } = dateParams;
  return (
    <Box
      style={{ margin: margin }}
      className={`date-picker-container ${className}`}
    >
      {placeholder && (
        <Typography
          style={{
            fontFamily: "EuclidCircularB-Regular",
            color: "rgb(123, 130, 137)",
            // marginLeft: "3%",
            marginBottom: "2%",
            fontSize: "12px",
          }}
          variant="body2"
        >
          {placeholder}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          // label="Date"
          value={dateValue}
          onChange={setDateValueFunc}
          // views={["year", "month", "day"]}
          inputFormat="dd-MM-yyyy"
          disabled={disabled ? disabled : false}
          {...dateParams}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{
                width: small ? width : "320px",
                backgroundColor: colors.light_gray,
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

function DatePicker2(props: any) {
  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <Grid
      sx={{
        // display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "space-between",
        marginLeft: props.marginLeft || "0px",
      }}
    >
      <Typography
        style={{
          alignSelf: props.alignSelf,
          margin: props.placeholderMargin,
          fontFamily: "EuclidCircularB-Regular",
          color: "rgb(123, 130, 137)",
          fontSize: isMobile ? "10px" : "12px",
          paddingBottom: "4px",
        }}
        variant="body2"
      >
        {props.placeholder}
        {props.required && (
          <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
        )}
      </Typography>

      <div
        className="date-picker"
        style={{
          width: props.width || 230,
          ...props.style,
          border: props.errormessage ? "1px solid rgb(244, 8, 8)" : "none",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            minDate={props.minDate}
            value={props.value}
            readOnly={props.readOnly}
            onChange={props.onChange}
            inputFormat={props.inputFormat ?? "dd/MM/yyyy"}
            shouldDisableDate={props.shouldDisableDate ?? false}
            components={
              props.hideIcon
                ? {
                  OpenPickerIcon: "a",
                }
                : {}
            }
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box sx={{ display: "flex", alignItems: "center", width: "210px" }}>
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{
                    width: "231px",
                    borderWidth: 0,
                    backgroundColor: colors.light_gray,
                  }}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
      </div>
      {props.errormessage && (
        <Typography className="error-message" style={{ fontSize: 14 }}>
          {props.errormessage}
        </Typography>
      )}
    </Grid>
  );
}

function BasicDatePickerTask(dateParams: any) {
  const {
    small,
    className,
    margin,
    dateValue,
    width,
    setDateValueFunc,
    disabled,
    placeholder,
  } = dateParams;
  return (
    <div
      style={{ margin: margin }}
      className={`date-picker-container ${className}`}
    >
      {placeholder && (
        <Typography
          style={{
            fontFamily: "EuclidCircularB-Regular",
            color: "rgb(123, 130, 137)",
            // marginLeft: "3%",
            marginBottom: "2%",
            fontSize: "12px",
          }}
          variant="body2"
        >
          {placeholder}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          // label="Date"
          value={dateValue}
          onChange={setDateValueFunc}
          // views={["year", "month", "day"]}
          inputFormat="dd-MMMM-yyyy"
          disabled={disabled ? disabled : false}
          {...dateParams}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{
                width: small ? width : "320px",
                backgroundColor: colors.light_gray,
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
function BasicTimePickerTask(dateParams: any) {
  const {
    small,
    className,
    margin,
    timeValue,
    width,
    setTimeValueFunc,
    disabled,
    placeholder,
    timeAmPm,
  } = dateParams;
  return (
    <div
      style={{ margin: margin }}
      className={`date-picker-container ${className}`}
    >
      {placeholder && (
        <Typography
          style={{
            fontFamily: "EuclidCircularB-Regular",
            color: "rgb(123, 130, 137)",
            // marginLeft: "3%",
            marginBottom: "2%",
            fontSize: "12px",
          }}
          variant="body2"
        >
          {placeholder}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <TimePicker
            // label="Date"
            ampm={timeAmPm}
            value={timeValue}
            mask="__:__"
            onChange={setTimeValueFunc}
            disabled={disabled ? disabled : false}
            {...dateParams}
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{
                  width: small ? width : "320px",
                  backgroundColor: colors.light_gray,
                }}
                {...params}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
}

export {
  DatePicker1,
  DatePicker2,
  BasicDatePicker,
  BasicDatePickerTask,
  BasicTimePickerTask,
};
