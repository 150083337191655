import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import {
  odometerHistoryKeys,
  RideTypes,
  typographyConstants,
} from "../../../../constants/constants";
import { colors } from "../../../../../themes/colors";
import { dateFormat } from "../../../../utils/helper";
import en from "../../../../locale/rental-en.json";

const OdometerHistory = ({ odometerHistory }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "15px",
      }}
    >
      {odometerHistory?.length ? (
        odometerHistory?.map((item: any) => {
          return (
            <Card
              onClick={() => {}}
              sx={{ border: `1px solid ${colors.card_border}` }}
            >
              <CardHeader
                sx={{
                  height: "45px",
                  padding: "10px",
                  borderBottom: `1px solid ${colors.card_border_bottom}`,
                }}
                titleTypographyProps={{
                  variant: typographyConstants.SUBHEADING,
                }}
                title={item?.registration}
                action={
                  <Box sx={{ display: "flex", gap: "5px", padding: "5px" }}>
                    <Chip
                      label={RideTypes[item?.type as keyof typeof RideTypes]}
                      sx={{
                        padding: "4px",
                        height: "25px",
                        color: colors.white,
                        backgroundColor: colors.LIGHT_BLUE,
                      }}
                    />
                  </Box>
                }
              />
              <CardContent sx={{ padding: "10px" }}>
                <Grid container spacing={2}>
                  {odometerHistoryKeys?.map((key: any) => {
                    return (
                      <Grid item xs={6} sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            color: colors.text_secondary_gray,
                            width: "85px",
                          }}
                        >
                          {key.displayName}
                        </Typography>
                        <Typography>
                          {key?.name === "startDate" || key?.name === "endDate"
                            ? dateFormat(item[key?.name]) ?? en.global.NA
                            : item[key?.name] ?? en.global.NA}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Typography
          variant={typographyConstants.SUBHEADING}
          sx={{ alignSelf: "center" }}
        >
          {en.bookingDetails.noOdometerHistoryFound}
        </Typography>
      )}
    </Box>
  );
};
export default OdometerHistory;
