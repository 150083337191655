import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routesConstants } from "../../rentals-src/utils/RoutesConstants";
import ResponsiveDialog from "./ResponsiveDialog/responsiveDialog";
import rentalEn from "../../rentals-src/locale/rental-en.json";
import { colors } from "../../themes/colors";
import {
  EditIconComponent,
  vehicleCardImg,
} from "../../rentals-src/constants/exportImages";
import { typographyConstants } from "../../rentals-src/constants/constants";
import {
  generateVehicleSrc,
  toPascalCase,
} from "../../rentals-src/utils/helper";
import { isArray } from "lodash";

const VehicleDetailCard = (props: any) => {
  const {
    modelDisplayName = "",
    branchDisplayName = "",
    model,
    useCase = "",
    imagePath = "",
    images: vehicleImages = [] as any[],
  } = props.vehicleData;
  const { stateData } = props;
  const { id: planId } = useParams();
  const navigate = useNavigate();
  const [openDialog, setDialog] = useState(false);

  const imagePathUrl = useCallback(
    () => generateVehicleSrc(imagePath, [...vehicleImages]),
    [imagePath, vehicleImages.length]
  );

  const toggleDialog = () => {
    setDialog(!openDialog);
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: colors?.white,
          maxWidth: "355px",
          display: "flex",
          border: "1px solid #2BC7C9",
          borderRadius: "12px",
          // height: "112px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}
          >
            {imagePathUrl() && isArray(vehicleImages) ? (
              <Box
                sx={{
                  backgroundSize: "contain",
                  backgroundImage: `url(${imagePathUrl()})`,
                  width: "100%",
                  height: "75px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "1vw",
                }}
              ></Box>
            ) : (
              <Box
                sx={{
                  backgroundImage: `url(${vehicleCardImg})`,
                  width: "100%",
                  height: "75px",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "1vw",
                }}
              ></Box>
            )}
            <Typography
              variant={typographyConstants.HEADINGXL}
              sx={{ alignSelf: "flex-end", width: "100%" }}
            >
              {modelDisplayName}{" "}
            </Typography>
            <Box>
              <Box
                sx={{
                  backgroundColor: "#2BC7C9",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  visibility: planId ? "hidden" : "visible",
                }}
                onClick={toggleDialog}
              >
                {" "}
                <EditIconComponent color={colors?.white} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={typographyConstants.BODY}>
              {branchDisplayName
                ? `${branchDisplayName} | `
                : stateData?.selectedBranch?.displayName}
              {toPascalCase(useCase)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      {/* confirmation dialog on discard changes  */}
      <ResponsiveDialog
        title={rentalEn.global.confirmation}
        body={rentalEn.global.areYouSure}
        open={openDialog}
        handleClose={toggleDialog}
        handleYes={() => {
          toggleDialog();
          navigate(routesConstants.ADD_PLAN_MANAGEMENT);
        }}
      />
    </>
  );
};

export default VehicleDetailCard;
