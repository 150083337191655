import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { handleMessage } from "../../utils/helper";
import { toastr } from "react-redux-toastr";
import taskServices from "../../services/taskServices";

function* getTaskList(action: any): any {
  const data = yield call(taskServices.getTaskList, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASKLIST_SUCCESS,
      payload: {
        taskList: data?.data?.Result?.tasks,
        allTasks: data?.data?.Result?.tasks,
        totalCount: data?.data?.Result?.totalCount,
        currentPage: data?.data?.Result?.currentPage,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASKLIST_FAILED,
    });
  }
}

function* getTaskDetailsById(action: any): any {
  const data = yield call(taskServices.getTaskDetailsById, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_FAILED,
    });
  }
}

function* createTask(action: any): any {
  const data = yield call(taskServices.createTask, action.payload);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.CREATE_TASK_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.CREATE_TASK_FAILED,
    });
  }
}

function* editTask(action: any): any {
  const data = yield call(taskServices.editTask, action.payload, action.id);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));

    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_FAILED,
    });
  }
}

function* deleteTask(action: any): any {
  const data = yield call(taskServices.deleteTask, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.DELETE_TASK_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.DELETE_TASK_FAILED,
    });
  }
}

function* getTaskListSequence(action: any): any {
  const data = yield call(taskServices.getTaskListSequence, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_SUCCESS,
      payload: {
        data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_FAILED,
    });
  }
}

function* getTaskWarehouseList(action: any): any {
  const data = yield call(taskServices.getTaskWarehouseList, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS
        .TASK_SEQUENCE_WAREHOUSE_LIST_SUCCESS,
      payload: {
        getTaskListById: data?.data?.masterTaskIds,
      },
    });
  } else {
    // toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS
        .TASK_SEQUENCE_WAREHOUSE_LIST_FAILED,
    });
  }
}

function* editTaskSequence(action: any): any {
  const data = yield call(
    taskServices.editTaskSequence,
    action.payload,
    action.id,
    action.taskType
  );
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_FAILED,
    });
  }
}

//   function* getTaskStatus(action: any): any {
//  try{
//     const data = yield call(
//       taskServices.getTaskStatus,
//       action.payload
//     );
//     if (data?.status === 200) {
//       yield put({
//         type: actionTypes.TASKMANAGEMENT_ACTIONS
//           .TASK_STATUS_SUCCESS,
//         payload: {
//         getTaskStatusById:data?.data
//         },
//       });
//     }else if (data?.status === 404) {
//       toastr.error('', data?.message);
//       yield put({
//         type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_FAILED,
//         payload: {
//           getTaskStatusById: data?.data,
//         },
//       });
//     }
//     else {
//       toastr.error("", data?.error);
//       yield put({
//         type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_FAILED,
//       });
//     }
//   }
//   catch (error) {
//     toastr.error('', 'An error occurred.');
//     yield put({
//       type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED,
//     });
//   }
// }

function* getTaskStatus(action: any): any {
  try {
    const data = yield call(taskServices.getTaskStatus, action.payload);

    if (data?.status === 200) {
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_SUCCESS,
        payload: {
          getTaskStatusById: data?.data,
        },
      });
    } else if (data?.status === 422) {
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_SUCCESS,
        payload: {
          getTaskStatusById: [],
        },
      });
    } else if (data?.status === 404) {
      // toastr.error('', data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_FAILED,
        payload: {
          getTaskStatusById: data?.data,
        },
      });
    } else {
      // toastr.error('', handleMessage(data));
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("Error", "An error occurred while processing your request.");
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED,
    });
  }
}

function* getTaskSummary(action: any): any {
  try {
    const data = yield call(taskServices.getTaskSummary, action.payload);

    if (data?.status === 200) {
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_SUCCESS,
        payload: {
          getTaskSummaryById: data?.data,
        },
      });
    } else if (data?.status === 422) {
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_SUCCESS,
        payload: {
          getTaskSummaryById: [],
        },
      });
    } else if (data?.status === 404) {
      // toastr.error('', data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED,
        payload: {
          getTaskSummaryById: [],
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", "An error occurred.");
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED,
    });
  }
}
function* getTaskUserList(action: any): any {
  const data = yield call(taskServices.getUserList, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_SUCCESS,
      payload: {
        getUserListById: data?.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_FAILED,
    });
  }
}

function* postTaskAsignment(action: any): any {
  try {
    const data = yield call(taskServices.postTaskAsignment, action.payload);

    if (data?.status === 201) {
      toastr.success("", data?.data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_SUCCESS,
        payload: {
          postTaskAsignment: data?.data,
        },
      });
    } else if (data?.status === 422) {
      toastr.error("", data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED,
      });
    } else if (data?.status === 400) {
      toastr.error("", data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED,
      });
    } else if (data?.status === 401) {
      toastr.error("", data?.message);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED,
      });
    } else {
      toastr.error("", data);
      yield put({
        type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", "error  found");
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED,
    });
  }
}

function* getTaskSuggestion(action: any): any {
  const data = yield call(taskServices.getTaskSuggestion, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_SUCCESS,
      payload: {
        taskSuggestion: data?.data?.Result?.tasks,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_FAILED,
    });
  }
}

function* taskSaga() {
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASKLIST_REQUESTED,
    getTaskList
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_REQUESTED,
    getTaskDetailsById
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.CREATE_TASK_REQUESTED,
    createTask
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_REQUESTED,
    editTask
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.DELETE_TASK_REQUESTED,
    deleteTask
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_REQUESTED,
    getTaskListSequence
  );
  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_WAREHOUSE_LIST_REQUESTED,
    getTaskWarehouseList
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_REQUESTED,
    editTaskSequence
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_STATUS_REQUESTED,
    getTaskStatus
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_REQUESTED,
    getTaskSummary
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_REQUESTED,
    getTaskUserList
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_REQUESTED,
    postTaskAsignment
  );

  yield takeLatest(
    actionTypes.TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_REQUESTED,
    getTaskSuggestion
  );
}

export default taskSaga;
