import FullCalendar from "@fullcalendar/react";
import dayGridYear from "@fullcalendar/daygrid";
import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment, { Moment } from "moment";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import en from "../../../../../locale/en.json";
import { RootState } from "../../../../../redux/reducers";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import FullScreenLoader from "../../../../../sharedComponents/molecules/FullScreenLoader";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  CALANDER_VIEW_DATA_DISPLAY,
  filterStorageKeys,
  GenericObject,
  typographyConstants,
  useCaseArray,
} from "../../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  DownArrowIcon,
  FunctionalitiesIcon,
  LeftArrowIcon,
  RightArrowIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import {
  CalendarDates,
  IFullCalendarHeader,
} from "../../../../interfaces/vehicleInterface";
import rentalEn from "../../../../locale/rental-en.json";
import {
  manualBookingActions,
  rentalActions,
  vehicleActions,
} from "../../../../redux/actions";
import { routesConstants } from "../../../../utils/RoutesConstants";
import {
  calculateFutureDate,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../../utils/helper";
import { StyleObject } from "./StyleObject";
import styles from "./inventory.module.css";

import {
  inventoryFunctionality,
  modules,
} from "../../../../../constants/RBACModuleEnums";

const { isAccessRightsProvided } = RbackHelper;

const calenderDataDisplayColor: any = {
  total: colors.full_calander_total,
  available: colors?.full_calander_available,
  blocked: colors?.full_calander_blocked,
  engaged: colors?.full_calander_engaged,
};

const initialState: GenericObject = {
  selectedCity: {
    name: "",
    displayName: rentalEn?.global?.allCities,
  },
  selectedBranch: {
    name: "",
    displayName: rentalEn?.global?.allHubs,
  },
  selectedVehicle: {
    name: "",
    displayName: rentalEn?.global?.allModels,
  },
  selectedLob: {
    name: "",
    displayName: rentalEn?.global?.allUseCases,
  },
};

const FullCalendarHeader = ({
  fullCalendarRef,
  calendarDates,
}: IFullCalendarHeader) => {
  // state contains all header data
  const [headerData, setHeaderData] = useState({
    midDate: "",
    minDate: moment(),
    maxDate: moment(),
    calendarTitle: "",
  });

  // state to toggle month picker
  const [openMonthCalendar, setOpenMonthCalendar] = useState<boolean>(false);

  // find months diffrence between two dates
  const monthDiffrence = (dateFrom: Date, dateTo: Date) =>
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear()) +
    1;

  // handle header button clicks
  const handleArrowClick = (event: "prev" | "next" | "today") => {
    const handler: any = fullCalendarRef?.current?.getApi();
    const d1 = new Date();
    const d2 = new Date(handler?.getCurrentData().currentDate);
    let monthDiff = monthDiffrence(d1, d2);

    if (handler && event === "prev" && monthDiff >= 0) handler?.prev();
    else if (handler && event === "next" && monthDiff <= 5) handler?.next();
    else if (handler && event === "today") handler?.today();
  };

  // mid-date picker
  const datePickerMonth = (): Date => {
    const startDate = new Date(calendarDates.startDate);
    const endDate = new Date(calendarDates.endDate);
    var middate = new Date((startDate.getTime() + endDate.getTime()) / 2);
    return middate;
  };

  // future date picker (maxDate)
  const disableFutureMonths = (): Date => {
    var now = new Date().getTime();
    var fiveDaysFromNow = now + 1000 * 60 * 60 * 24 * 150;
    var dateDaysFromNow = new Date(fiveDaysFromNow);
    return dateDaysFromNow;
  };

  // past date picker (mindate)
  const disablePastMonths = (): Date => {
    var now = new Date().getTime();
    var fiveDaysFromNow = now - 1000 * 60 * 60 * 24 * 60;
    var dateDaysFromNow = new Date(fiveDaysFromNow);
    return dateDaysFromNow;
  };

  useEffect(() => {
    setHeaderData((prev: any) => ({
      ...prev,
      minDate: disablePastMonths(),
      maxDate: disableFutureMonths(),
      midDate: datePickerMonth(),
    }));
  }, [calendarDates]);

  useEffect(() => {
    const handler = fullCalendarRef?.current?.getApi();
    if (handler)
      setHeaderData((prev: any) => ({
        ...prev,
        calendarTitle: handler?.getCurrentData().viewTitle,
      }));
  }, [fullCalendarRef]);

  return (
    <Box className={styles.fullCalendarHeader}>
      <Box sx={StyleObject?.fullCalendarHeader_box1}>
        <Typography sx={StyleObject?.calendarTitle_typography}>
          {headerData?.calendarTitle}
        </Typography>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={en?.global?.Month}
              open={openMonthCalendar}
              onClose={() => {
                setOpenMonthCalendar(false);
              }}
              openTo="month"
              views={["year", "month"]}
              onChange={(value: Moment | null): void => {
                fullCalendarRef?.current?.getApi().gotoDate(value?.toDate());
              }}
              value={headerData?.midDate}
              renderInput={(params) => {
                return (
                  <Tooltip title={en?.global?.SelectMonth}>
                    <img
                      src={DownArrowIcon}
                      style={StyleObject?.cursorPointerStyle}
                      onClick={() => {
                        setOpenMonthCalendar(true);
                      }}
                    />
                  </Tooltip>
                );
              }}
              maxDate={headerData?.maxDate}
              minDate={headerData?.minDate}
              className={styles.calendarMonths}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box className={styles.fullCalendarHeaderEnd}>
        <Box
          sx={StyleObject?.fullCalendarHeader_box2}
          onClick={() => handleArrowClick("today")}
        >
          <Typography sx={{ fontWeight: 500 }}>{en?.global?.Today}</Typography>
        </Box>

        <Box
          sx={StyleObject?.fullCalendarHeader_box3}
          onClick={() => handleArrowClick("prev")}
        >
          <Tooltip title={en?.global?.Previous}>
            <img src={LeftArrowIcon} />
          </Tooltip>
        </Box>

        <Box
          sx={StyleObject?.fullCalendarHeader_box3}
          onClick={() => handleArrowClick("next")}
        >
          <Tooltip title={en?.global?.Next}>
            <img src={RightArrowIcon} />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

const InventoryDashboard = (props: any) => {
  // RBAC object for functionality
  const functionalitiesAccess = {
    isViewInventoryDetails: isAccessRightsProvided(
      modules.INVENTORY_MANAGEMENT,
      inventoryFunctionality.VIEW_INVENTORY_DETAILS
    ),
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Ref to handle full calendar
  const fullCalendarRef = createRef<FullCalendar>();

  const { allCitiesDetails, branches, modelListByBranch } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const { calendarData, calenderDataLoader = false } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  // state to store filters data
  const [fields, setFields] = useState<GenericObject>({ ...initialState });

  // state to store start and end date
  const [calendarDates, setCalendarDates] = useState<CalendarDates>({
    startDate: "",
    endDate: "",
  });

  // State to store modified calendar data
  const [modifiedCalendarData, setModifiedCalendarData] = useState<
    GenericObject[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      // Clear branches and modelListByBranch state from reducer
      dispatch(rentalActions.clearBranchesAndModels());

      // Fetch data from local storage
      const data = await getFilterDataFromLocalStorage(
        filterStorageKeys?.INVENTORY_FILTER_DATA
      );
      if (data) {
        // Update fields state
        setFields((prevFields: GenericObject) => ({
          ...prevFields,
          selectedCity: data?.selectedCity,
          selectedBranch: data?.selectedBranch,
          selectedVehicle: data?.selectedVehicle,
          selectedLob: data?.selectedLob,
        }));
        const { selectedCity, selectedBranch } = data;
        // API call for branches
        if (selectedCity?.name) {
          dispatch(rentalActions.getAllBranches({ name: selectedCity.name }));
        }
        // API call for models
        const payload: GenericObject = {
          branches: selectedBranch?.name ? [selectedBranch.name] : null,
          city: selectedCity?.name,
        };
        if (selectedBranch?.name || selectedCity?.name) {
          dispatch(rentalActions.getModelListByBranch(payload));
        } else {
          dispatch(rentalActions.getModelListByBranch());
        }
      } else {
        // Fetch models based on authorized branches data
        dispatch(rentalActions.getModelListByBranch());
      }
    };

    fetchData(); // Initial api calls on component mount
  }, []);

  // Store data in local storage
  useEffect(() => {
    let data: GenericObject = {
      inventoryFilterData: {
        ...fields,
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);
  }, [
    fields?.selectedCity,
    fields?.selectedBranch,
    fields?.selectedVehicle,
    fields?.selectedLob,
  ]);

  // handle data and call apis accordingly
  const handleChange = (key: string, value: any) => {
    // Prepare a data object to update multiple fields at once
    let updateFields: GenericObject = {};

    // Handle different cases based on the key
    if (key === "selectedCity") {
      // Reset branch and vehicle model state
      updateFields = {
        selectedBranch: { name: "", displayName: rentalEn?.global?.allHubs },
        selectedVehicle: { name: "", displayName: rentalEn?.global?.allModels },
        // selectedLob: { name: "", displayName: rentalEn?.global?.allUseCases },
      };

      // Call branches API if selectedCity is available
      if (value?.name) {
        dispatch(rentalActions.getAllBranches({ name: value.name }));
      } else {
        // Clear branches and modelListByBranch state from reducer
        dispatch(rentalActions.clearBranchesAndModels());
      }
    } else if (key === "selectedBranch") {
      // Reset vehicle model state
      updateFields = {
        selectedVehicle: { name: "", displayName: rentalEn?.global?.allModels },
      };
    }

    // Always update the state with the new key-value pair and additional data
    setFields((prevState: GenericObject) => ({
      ...prevState,
      [key]: value,
      ...updateFields,
    }));

    // Make API call for models if necessary
    if (key === "selectedCity" || key === "selectedBranch") {
      // Construct payload for API call based on selected city or branch
      let payload: GenericObject = {
        branches: key === "selectedBranch" && value?.name ? [value.name] : null,
        city:
          key === "selectedBranch"
            ? fields.selectedCity?.name
            : key === "selectedCity"
            ? value?.name
            : null,
      };

      // Check if selected value is valid for fetching models
      if (
        value.name &&
        (value.name !== rentalEn?.global?.allCities ||
          value.name !== rentalEn?.global?.allHubs)
      ) {
        dispatch(rentalActions.getModelListByBranch(payload));
      }
    }
  };

  // get dates from full calendar (start and end)
  const getDates = (dateInfo: any) => {
    let endDate = new Date(dateInfo.end);
    endDate.setDate(endDate.getDate() - 1);

    setCalendarDates((prev: CalendarDates) => ({
      ...prev,
      startDate: getTimestampAtStartOfDay(dateInfo?.start),
      endDate: getTimestampAtEndOfDay(endDate),
    }));
  };

  // function return day cell event content UI and Data
  const eventContentUI = (eventInfo: any): JSX.Element => {
    const data: any = eventInfo?.event?._def?.extendedProps;
    const tempData: any = { ...data };
    delete tempData.utilization;
    const tempArr: any = Object.keys(tempData);
    const index = tempArr?.indexOf("available"); // Find the index of 'available'
    // If 'available' exists in the array, remove it and then push it to the end
    if (index !== -1) {
      tempArr?.splice(index, 1); // Remove 'available' from current position
      tempArr?.push("available"); // Push 'available' to the end of the array
    }
    return (
      <Box sx={StyleObject?.InventoryManagement_box5}>
        {tempArr?.map((item: any, index: number) => {
          return (
            <Box
              key={String(index)}
              sx={{
                ...StyleObject?.InventoryManagement_getDates_eventContentUI_mapStyle,
                backgroundColor:
                  calenderDataDisplayColor[item as keyof typeof tempData],
                marginTop: item === "available" ? "5px" : "",
                boxShadow:
                  item !== "available" ? " 0px 0px 4px 0px #0000003D" : "",
              }}
            >
              <Typography
                variant={typographyConstants.BODY}
                sx={{
                  ...StyleObject?.InventoryManagement_getDates_eventContentUI_box1,
                  fontSize: item === "available" ? "10px" : "10px",
                }}
              >
                {CALANDER_VIEW_DATA_DISPLAY[item as keyof typeof tempData]}
              </Typography>
              <Typography
                variant={typographyConstants.BODY}
                sx={{ fontSize: item === "available" ? "11px" : "10px" }}
              >
                {item === "available"
                  ? tempData[item] <= 0
                    ? en?.global?.SoldOut
                    : tempData[item]
                  : tempData[item]}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  // function returns week header data and UI
  const dayHeaderContent = (dayInfo: any): JSX.Element => {
    return (
      <Box className={styles.dayHeaderContent}>
        <Typography fontWeight={500} fontSize={"16px"}>
          {dayInfo.text}
        </Typography>
      </Box>
    );
  };

  // function return data cell date UI and related data
  const dayCellContent = (dateInfo: any): JSX.Element => {
    let tempIsDisabled: boolean = false;

    const tempClickedDate: Moment | any = moment(
      dateInfo?.date,
      "ddd MMM DD YYYY HH:mm:ss ZZ"
    ); // Format clicked date
    const today: Moment | any = moment(); // Today's date

    const sixtyDaysBefore: Moment | any = moment(today).subtract(60, "days"); // Calculate 60 days before today
    const oneHundredEightyDaysAfter: Moment | any = moment(today).add(
      180,
      "days"
    ); // Calculate 180 days after today

    // Check if clicked date is within the range
    if (
      tempClickedDate.isBetween(
        sixtyDaysBefore,
        oneHundredEightyDaysAfter,
        null,
        "[]"
      )
    ) {
      // Handle case where date is within range
      tempIsDisabled = false;
    } else {
      // Handle case where date is outside range (optional)
      tempIsDisabled = true;
    }

    return (
      <Box
        sx={{
          color: tempIsDisabled ? colors?.grayC6 : colors?.text_black,
          fontSize: "14px",
          fontWeight: "500",
        }}
        className="background-todayDate"
      >
        {dateInfo.dayNumberText}
      </Box>
    );
  };

  // handle filters reset
  const handleFilterReset = () => {
    dispatch(rentalActions.getModelListByBranch());
    dispatch(rentalActions.clearBranchesAndModels()); // Clear branches and modelListByBranch state from reducer
    setFields({ ...initialState }); // set fields to it's initial state.
  };

  const addDummyElements = (array: GenericObject[], requiredLength: number) => {
    const currentLength: number = array?.length;
    const currentDate: Moment | any = moment();
    const sixtyDaysBefore: Moment | any = currentDate
      .clone()
      .subtract(60, "days");
    const oneHundredEightyDaysAfter: Moment | any = currentDate
      .clone()
      .add(180, "days");
    //
    const startDate: EpochTimeStamp | any = calendarDates?.startDate;
    const endDate: EpochTimeStamp | any = calendarDates?.endDate;
    //
    const generateDummyData = (date: any) => ({
      total: rentalEn?.global?.NA,
      engaged: rentalEn?.global?.NA,
      blocked: rentalEn?.global?.NA,
      available: rentalEn?.global?.NA,
      utilization: rentalEn?.global?.NA,
      date: date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });
    //
    const fillDates = (
      apiResponse: GenericObject[],
      startDate: EpochTimeStamp | any,
      endDate: EpochTimeStamp | any
    ) => {
      const formattedStartDate: Moment | any = moment(startDate);
      const formattedEndDate: Moment | any = moment(endDate);

      // Generate a list of all dates between startDate and endDate
      const dateList: any = [];
      let currentDate: Moment | any = formattedStartDate.clone();
      while (currentDate.isSameOrBefore(formattedEndDate)) {
        dateList.push(currentDate.clone().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        currentDate.add(1, "day");
      }

      // Create a map of the API response for quick lookup
      const apiResponseMap: any = new Map(
        apiResponse.map((item: any) => [item.date, item])
      );

      // Fill in the missing dates with dummy data
      const filledData: GenericObject[] = dateList.map((date: any) => {
        if (apiResponseMap.has(date)) {
          return apiResponseMap.get(date);
        } else {
          return generateDummyData(moment(date));
        }
      });

      return filledData;
    };
    const result: GenericObject[] = fillDates(array, startDate, endDate);

    // Check if item's date is in between 60 days before today and 180 days after today
    result?.map((item: any) => {
      const itemDate: Moment | any = moment(item?.date);
      if (
        !itemDate.isBetween(
          sixtyDaysBefore,
          oneHundredEightyDaysAfter,
          null, // undefined
          "[]" // "[" indicates inclusion of a value.
        )
      ) {
        // Update key's value to "NA" for old items
        return {
          ...item,
          total: rentalEn?.global?.NA,
          engaged: rentalEn?.global?.NA,
          blocked: rentalEn?.global?.NA,
          available: rentalEn?.global?.NA,
          utilization: rentalEn?.global?.NA,
        };
      } else {
        return item; // Return original item if no update is needed
      }
    });

    return [...result];
  };

  const apiSuccessFunction = (apiDataArray: GenericObject[]) => {
    // Add dummy elements to ensure calendarData has a length of 42
    const dataArrayWithDummy: GenericObject[] = addDummyElements(
      apiDataArray,
      42
    );
    setModifiedCalendarData(dataArrayWithDummy);
  };

  // side effects to call full calendar cell data api on basic of filters data
  useEffect(() => {
    if (calendarDates?.startDate && calendarDates?.endDate) {
      const payload: GenericObject = {
        data: {
          startDate: calendarDates?.startDate,
          endDate: calendarDates?.endDate,
          cities: fields?.selectedCity.name,
          useCase: fields?.selectedLob?.name,
          branches: fields?.selectedBranch?.name,
          model: fields?.selectedVehicle.name,
        },
        apiSuccessFunction: apiSuccessFunction,
      };
      dispatch(vehicleActions.getCalendarData(payload));
    }
  }, [fields, calendarDates]);

  const handleEventClick = (clickInfo: any) => {
    // check rbac access of inventory details
    if (!functionalitiesAccess.isViewInventoryDetails) {
      return;
    }

    const eventData: GenericObject = clickInfo?.event?._def?.extendedProps; // additional data associated with the event
    const clickedDate: Date | any = clickInfo?.event?._instance?.range?.start; // Clicked date

    const tempClickedDate: Moment | any = moment(
      clickedDate,
      "ddd MMM DD YYYY HH:mm:ss ZZ"
    ); // Format clicked date
    const today: Moment | any = moment(); // Today's date

    const sixtyDaysBefore: Moment | any = moment(today).subtract(60, "days"); // Calculate 60 days before today
    const oneHundredEightyDaysAfter: Moment | any = moment(today).add(
      180,
      "days"
    ); // Calculate 180 days after today

    if (fields?.selectedBranch?.name) {
      const payloadModels: GenericObject = {
        payload: {
          startDate: getTimestampAtStartOfDay(clickedDate),
          endDate: getTimestampAtStartOfDay(
            calculateFutureDate(clickedDate, 1)
          ),
          branches: [fields?.selectedBranch?.name],
          useCase: fields?.selectedLob?.name,
        },
        showError: false,
      };

      dispatch(manualBookingActions.getAvailableModels(payloadModels));
    }

    // Check if clicked date is within the range
    if (
      tempClickedDate.isBetween(
        sixtyDaysBefore,
        oneHundredEightyDaysAfter,
        null,
        "[]"
      ) &&
      eventData?.total !== rentalEn?.global?.NA
    ) {
      // Navigation logic or action here
      const navigationData: GenericObject = {
        eventData: eventData,
        clickedDate: clickedDate,
      };
      navigate(routesConstants.INVENTORY_DETAILS, { state: navigationData });
    } else {
      // Handle case where date is outside range (optional)
    }
  };

  return (
    <Box sx={StyleObject?.InventoryManagement_box1}>
      {/* Filter Section */}
      <Grid container alignItems={"center"} spacing={"5px"}>
        {/* City Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            select
            icon={CityInputIcon}
            placeholder={rentalEn?.global?.selectCity}
            value={
              isArrayNotEmpty(allCitiesDetails)
                ? fields?.selectedCity?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(allCitiesDetails)
                ? [
                    { name: "", displayName: rentalEn?.global?.allCities },
                    ...allCitiesDetails,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(val: any) => {
              handleChange("selectedCity", val);
            }}
            disabled={!isArrayNotEmpty(allCitiesDetails)}
          />
        </Grid>

        {/* Branch Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={WarehouseIcon}
            placeholder={
              isArrayNotEmpty(branches)
                ? rentalEn?.global?.selectHub
                : rentalEn?.global?.allHubs
            }
            value={
              isArrayNotEmpty(branches)
                ? fields?.selectedBranch?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(branches)
                ? [
                    { name: "", displayName: rentalEn?.global?.allHubs },
                    ...branches,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(val: any) => {
              handleChange("selectedBranch", val);
            }}
            disabled={
              !fields?.selectedCity?.displayName || !isArrayNotEmpty(branches)
            }
          />
        </Grid>

        {/* Model Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={BikeIconFaded}
            placeholder={
              isArrayNotEmpty(modelListByBranch)
                ? rentalEn?.global?.selectModel
                : rentalEn?.global?.allModels
            }
            value={
              isArrayNotEmpty(modelListByBranch)
                ? fields?.selectedVehicle?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(modelListByBranch)
                ? [
                    { name: "", displayName: rentalEn?.global?.allModels },
                    ...modelListByBranch,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(val: any) => {
              handleChange("selectedVehicle", val);
            }}
            disabled={!isArrayNotEmpty(modelListByBranch)}
          />
        </Grid>

        {/* Use Case Dropdown */}
        <Grid item sm={12} md={4}>
          <CustomSelect
            required
            icon={FunctionalitiesIcon}
            placeholder={rentalEn?.global?.selectUseCase}
            value={
              isArrayNotEmpty(useCaseArray)
                ? fields?.selectedLob?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(useCaseArray)
                ? [
                    { name: "", displayName: rentalEn?.global?.allUseCases },
                    ...useCaseArray,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(val: any) => {
              handleChange("selectedLob", val);
            }}
          />
        </Grid>

        {/* Filter Reset Icon */}
        <Grid
          item
          sm={12}
          md={8}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Tooltip title={en?.global?.ResetFilters} arrow>
            <RotateRightSharpIcon
              fontSize="large"
              color="primary"
              sx={StyleObject?.cursorPointerStyle}
              onClick={handleFilterReset}
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* Calendar Section */}
      <Box>
        <FullCalendarHeader
          fullCalendarRef={fullCalendarRef}
          calendarDates={calendarDates}
        />
        <FullCalendar
          ref={fullCalendarRef}
          plugins={[dayGridYear]}
          initialView="dayGridMonth"
          weekends={true}
          events={modifiedCalendarData}
          displayEventTime={false}
          datesSet={(dateInfo: any) => getDates(dateInfo)}
          eventContent={eventContentUI}
          dayHeaderFormat={{ weekday: "long" }}
          dayCellContent={dayCellContent}
          headerToolbar={false}
          dayHeaderContent={dayHeaderContent}
          height={"auto"}
          eventClick={handleEventClick} // attach the event click handler
        />
      </Box>

      {/* Loader */}
      <FullScreenLoader loading={calenderDataLoader} />
    </Box>
  );
};

export default InventoryDashboard;
