interface commonInterface {
  [key: string]: string;
}

export enum BenefitTypes {
  FREE_INSURANCE = "FREE_INSURANCE",
  FREE_MAINTAINANCE = "FREE_MAINTAINANCE",
  FREE_HELMET = "FREE_HELMET",
}
/* 
export enum BookingStatus {
  CREATED = "CREATED",
  PENDING = "PENDING",
  BOOKED = "BOOKED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
} */

export enum SUBSCRIPTION_METHOD {
  UPI = "upi",
}
export enum PAYMENT_FLOW {
  MANDATE_PAYMENT_LINK = "MANDATE_PAYMENT_LINK",
}

export enum BOOKING_STATUS {
  Pending = "Pending Rides",
  Upcoming = "Upcoming Rides",
  Ongoing = "Ongoing Rides",
  Ended = "Ended Rides",
  Cancelled = "Cancelled Rides",
  Completed = "Completed Rides",
}

export enum BOOKING_CHARGES {
  MONTHLY_FEE = "MONTHLY_FEE",
  SUBSCRIPTION_FEE = "SUBSCRIPTION_FEE",
  ADD_ONS = "ADD_ONS",
}
interface HELMETS_TYPES {
  [key: string]: string;
}

export enum CALLBACK_URL {
  URL = "https://callbackURL.com",
}

export const HELMETS_X: HELMETS_TYPES = {
  premium: "premium",
  fullFace: "fullFace",
  halfFace: "halfFace",
  kids: "kids",
};

export const HELMETS_YOR: HELMETS_TYPES = {
  premium: "PREMIUM",
  fullFace: "FULL_FACE",
  halfFace: "HALF_FACE",
  kids: "KIDS",
};

export enum HELMETS_ACTIONS_X {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}
interface HELMETS_DISPLAY_NAME {
  [key: string]: string;
}
export const StatDisplayStatus: commonInterface = {
  BOOKED: "Upcoming Rides",
  ACTIVE: "Ongoing Rides",
  CLOSED: "Completed Rides",
  CANCELLED: "Cancelled Rides",
};
export const bookingStatusDisplayName: commonInterface = {
  CREATED: "Pending",
  PENDING: "Pending",
  BOOKED: "Upcoming",
  ACTIVE: "Ongoing",
  CANCELLED: "Cancelled",
  CLOSED: "Completed",
};

export enum BOOKING_STATUS_ENUM {
  CREATED = "CREATED",
  PENDING = "PENDING",
  BOOKED = "BOOKED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
}

export const createdByDisplayName: commonInterface = {
  EXECUTIVE: "Executive",
  CUSTOMER: "Customer",
};
export const statusButtonColor: commonInterface = {
  CREATED: "gray",
  PENDING: "gray",
  BOOKED: "#FF7A00",
  ACTIVE: "#1BC47D",
  CANCELLED: "#FF3B30",
  CLOSED: "#1BD8DA",
};

export const HELMETS_DISPLAY_NAME: HELMETS_DISPLAY_NAME = {
  premium: "Premium Helmet",
  fullFace: "Full face Helmet",
  halfFace: "Half face Helmet",
  kids: "Kids Helmet",
};

export const BenefitTypesDisplay: any = {
  FREE_INSURANCE: "Insurance covered",
  FREE_MAINTAINANCE: "Free First 3 servicing",
  FREE_HELMET: "Complementary helmet",
};

export enum DUPLICATE_BOOKING_MODAL_X {
  TITLE = "Customer has an Active Booking",
  CONFIRMATION = "Cancel existing booking?",
  CHECK_BOOKING_MSG = "View existing booking - ",
}

export enum CANCEL_BOOKING_X_TYPES {
  CANCEL_THEN_CREATE_BOOKING = "CANCEL_THEN_CREATE_BOOKING",
  CANCEL_BOOKING = "CANCEL_BOOKING",
}
