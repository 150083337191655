import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BarChart } from "../../components/BarChart";
import { Button2 } from "../../components/button";
import { BasicDatePicker } from "../../components/datePicker";
import { BasicSelect } from "../../components/dropDown";
import { PieChart } from "../../components/PieChart";
import { SimpleChart } from "../../components/SimpleChart";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import { actions } from "../../redux";
import moment from "moment";
import "./style.css";
import DownloadButtons from "../../components/downloadButtons";
import { CSVLink } from "react-csv";
import { exportXl } from "../../utils/helper";

function ViewStats(_porps: any) {
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { viewStats, global } = locale;

  const [value, setValue] = useState<string>("ModelWise");

  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const { allVehicleData, allCitiesData } = useSelector(
    (state: RootState) => state.commonReducer
  );

  const [selectedOptionModels, setSelectedOptionModels] = useState({
    label: global.SelectModel,
    value: 0,
  });
  const [selectedOptionCity, setSelectedOptionCity] = useState({
    label: global.SelectCity,
    value: 0,
  });

  const {
    vehicleUtilizationDates,
    vehicleUtilizationData,
    paymentGraphData,
    revenueGraphData,
    allStatisticsDataDownload,
    allStatisticsData,
    isDownloadStats,
  } = useSelector((state: RootState) => state.dashboardReducer);

  useEffect(() => {
    if (allStatisticsData && value === "Payment") {
      setCurrentPaymentData([
        [global.Offline, global.Online],
        [
          (allStatisticsData?.paidStats?.offlinePaid || 0).toFixed(2),
          (allStatisticsData?.paidStats?.onlinePaid || 0).toFixed(2),
        ],
      ]);
      setTotalAmount(
        (allStatisticsData?.paidStats?.totalAmountPaid || 0).toFixed(2)
      );
    }
  }, [allStatisticsData]);

  useEffect(() => {
    if (allStatisticsData && value === "Revenue") {
      setCurrentPaymentData([
        [global.TotalBookingAmount, global.AmountPending],
        [
          (allStatisticsData?.revenueStats?.totalAmountPaid || 0).toFixed(2),
          (allStatisticsData?.revenueStats?.totalAmountPending || 0).toFixed(2),
        ],
      ]);
      setTotalAmount(
        (allStatisticsData?.revenueStats?.totalAmount || 0).toFixed(2)
      );
    }
  }, [allStatisticsData]);

  const [startDate, setStartDate] = useState<any>(
    moment(new Date("2022-01-12")).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [checked, setIsChecked] = useState([true, false]);
  const [radioChecked, setIsRadioChecked] = useState([true, false]);
  const [currentPaymentData, setCurrentPaymentData] = useState<any>([]);
  const [heading, setHeading] = useState<any>("Total Revenue");
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    // handleValueChange(event.target.value);
    setIsChecked([true, false]);
  };

  const renderMultiPickerOptions = (tag: any) => {
    switch (tag) {
      case "Models":
        let vehicles;
        vehicles = allVehicleData?.map((d: any) => ({
          value: d.id,
          label: d.model_name,
        }));
        vehicles.unshift({
          value: 0,
          label: "All",
        });
        return vehicles;
      case "Cities":
        let cities;
        cities = allCitiesData?.map((d: any) => ({
          value: d.id,
          label: d.city_name,
        }));
        cities.unshift({
          value: 0,
          label: "All",
        });
        return cities;
      default:
        break;
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.vehicleData({
        type: "VehicleModel",
        id: 0,
      })
    );
    dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    );
    dispatch(
      actions.getVehicleUtilizationDates({
        model_id: selectedOptionModels?.value,
        city_id: selectedOptionCity?.value,
      })
    );
  }, []);

  useEffect(() => {
    if (vehicleUtilizationDates) {
      setStartDate(vehicleUtilizationDates?.start_date);
      setEndDate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
      dispatch(
        actions.getVehicleUtilization({
          model_id: selectedOptionModels?.value,
          city_id: selectedOptionCity?.value,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        })
      );
    }
  }, [vehicleUtilizationDates]);

  useEffect(() => {
    handleValueChange(value);
  }, [
    selectedOptionCity?.label,
    selectedOptionModels?.label,
    startDate,
    endDate,
    value,
  ]);

  const handleValueChange = (value: string, isDownload?: boolean) => {
    if (value === "Payment" || value === "Revenue") {
      // setStartDate(moment(new Date()).subtract(30, "days"));
      // setEndDate(moment().add(1, "days"));
      if (value === "Payment" || value === "Revenue") {
        value === "Payment"
          ? setHeading("Total Payment")
          : setHeading("Total Revenue");

        let filterObj: any = {};
        let downloadObj: any = {};

        if (selectedOptionModels?.value) {
          filterObj["vehicle_model_name"] = selectedOptionModels?.label;
        }
        if (selectedOptionCity?.value) {
          filterObj["city"] = selectedOptionCity?.label;
        }

        if (isDownload && radioChecked[0]) {
        } else {
          filterObj["startDate"] = moment(new Date())
            .subtract(30, "days")
            .format("YYYY-MM-DD");

          filterObj["endDate"] = moment(new Date())
            .add(1, "days")
            .format("YYYY-MM-DD");
        }

        if (isDownload) {
          downloadObj["downloadType"] = checked[0] ? "csv" : "xlsx";

          setdownloadFlag(true);
        }

        dispatch(
          actions.getAllStatisticsData({
            statDetailType:
              value === "Payment" ? "Payment_Stats" : "Revenue_Stats",
            filters: {
              ...filterObj,
            },
            ...downloadObj,
          })
        );
      }
    } else {
      // setStartDate(vehicleUtilizationDates?.start_date);
      // setEndDate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
      dispatch(
        actions.getVehicleUtilization({
          model_id: selectedOptionModels?.value,
          city_id: selectedOptionCity?.value,
          start_date: moment(vehicleUtilizationDates?.start_date).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
        })
      );
    }
  };

  const pieOptions = {
    onClick: (_e: any, _element: any) => {
      // if (currentPaymentData[0]?.length > 0) {
      //   if (currentPaymentData[0][element[0].index] == "Online") {
      //     setCurrentPaymentData([
      //       paymentGraphData.lab3,
      //       paymentGraphData.data3,
      //     ]);
      //     setHeading("Online Revenue");
      //     setTotalAmount(
      //       paymentGraphData.data3.reduce(function (a: any, b: any) {
      //         return a + b;
      //       }, 0)
      //     );
      //   } else if (currentPaymentData[0][element[0].index] == "Offline") {
      //     setCurrentPaymentData([
      //       paymentGraphData.lab4,
      //       paymentGraphData.data4,
      //     ]);
      //     setHeading("Offline Revenue");
      //     setTotalAmount(
      //       paymentGraphData.data4.reduce(function (a: any, b: any) {
      //         return a + b;
      //       }, 0)
      //     );
      //   } else if (currentPaymentData[0][element[0].index] == "QrPayments") {
      //     setCurrentPaymentData([
      //       paymentGraphData.lab2,
      //       paymentGraphData.data2,
      //     ]);
      //     setHeading("QR Payments");
      //     setTotalAmount(
      //       paymentGraphData.data2.reduce(function (a: any, b: any) {
      //         return a + b;
      //       }, 0)
      //     );
      //   } else if (
      //     paymentGraphData.lab2.includes(
      //       currentPaymentData[0][element[0].index]
      //     )
      //   ) {
      //     setCurrentPaymentData([
      //       paymentGraphData.lab4,
      //       paymentGraphData.data4,
      //     ]);
      //     setHeading("Offline Revenue");
      //     setTotalAmount(
      //       paymentGraphData.data4.reduce(function (a: any, b: any) {
      //         return a + b;
      //       }, 0)
      //     );
      //   }
      // }
    },
  };
  const [downloadFlag, setdownloadFlag] = useState<boolean>(false);
  useEffect(() => {
    if (downloadFlag && isDownloadStats) {
      if (checked[0]) {
        csvLink?.current?.link?.click();
      } else {
        exportXl(allStatisticsDataDownload[0], value);
      }
      setdownloadFlag(false);
    }
  }, [allStatisticsDataDownload]);

  // useEffect(() => {
  //   if (downloadRefresh) {
  //     if (checked[0] && paymentGraphDownloadData) {
  //       csvLink?.current?.link?.click();
  //     } else if (paymentGraphExcelDownloadData?.length > 0) {
  //       exportXl(paymentGraphExcelDownloadData, "Payment Statistics");
  //     }
  //   }
  // }, [paymentGraphDownloadData, paymentGraphExcelDownloadData]);

  // useEffect(() => {
  //   if (downloadRefreshRevenue) {
  //     if (checked[0] && revenueGraphDownloadData) {
  //       csvLink?.current?.link?.click();
  //     } else if (revenueGraphExcelDownloadData?.length > 0) {
  //       exportXl(revenueGraphExcelDownloadData, "Revenue Statistics");
  //     }
  //   }
  // }, [revenueGraphDownloadData, revenueGraphExcelDownloadData]);
  // useEffect(() => {
  //   if (radioChecked[0] && downloadRefresh) {
  //     csvLink?.current?.link?.click();
  //   } else if (paymentGraphDateWiseExcelDownloadData?.length > 0) {
  //     exportXl(paymentGraphDateWiseExcelDownloadData, "DateWise Payments");
  //   }
  // }, [paymentGraphDateWiseDownloadData, paymentGraphDateWiseExcelDownloadData]);

  return (
    <div
      className={`custom-container pd-25 ${isDesktopOrTablet ? "" : "pdb-25"}`}
    >
      <Heading title={global.Statistics} />

      <FormControl style={{ marginLeft: "15px" }}>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          onChange={handleRadioChange}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="ModelWise"
            control={<Radio />}
            label={viewStats.ModelWise}
            className="radio-button"
          />
          <FormControlLabel
            value="Payment"
            control={<Radio />}
            label={global.Payment}
            className="radio-button"
          />
          <FormControlLabel
            value="Revenue"
            control={<Radio />}
            label={global.Revenue}
          />
        </RadioGroup>
      </FormControl>

      <Box
        className={isDesktopOrTablet ? "mt-25" : "mt-5"}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isDesktopOrTablet ? "start" : "center",
          marginLeft: "15px",
        }}
      >
        {value === "ModelWise" ? (
          <BasicDatePicker
            small
            className={isDesktopOrTablet ? "mr-25 mt-20" : "mt-10"}
            dateValue={startDate}
            minDate={new Date(startDate)}
            setDateValueFunc={(val: any) => setStartDate(val)}
          />
        ) : (
          <BasicDatePicker
            small
            className={isDesktopOrTablet ? "mr-25 mt-20" : "mt-10"}
            dateValue={startDate}
            setDateValueFunc={(val: any) => setStartDate(val)}
          />
        )}
        <BasicDatePicker
          small
          className={isDesktopOrTablet ? "mr-25 mt-20" : "mt-10"}
          dateValue={endDate}
          setDateValueFunc={(val: any) => setEndDate(val)}
          maxDate={
            value === "ModelWise"
              ? new Date(moment().subtract(1, "days").format("YYYY-MM-DD"))
              : new Date(moment().add(1, "days").format("YYYY-MM-DD"))
          }
        />
        <BasicSelect
          selected2={selectedOptionCity["label"]}
          handleChange={(city: any) => setSelectedOptionCity(city)}
          margin={isDesktopOrTablet ? "20px 25px 0px 0px" : "10px 0px 0px 0px"}
          padding="1px"
          width={225}
          defaultKey={"label"}
          heading={global.SelectCity}
          data={renderMultiPickerOptions("Cities")}
        />
        <BasicSelect
          selected2={selectedOptionModels["label"]}
          handleChange={(model: any) => setSelectedOptionModels(model)}
          margin={isDesktopOrTablet ? "20px 25px 0px 0px" : "10px 0px 0px 0px"}
          padding="1px"
          width={225}
          defaultKey={"label"}
          heading={global.SelectModel}
          data={renderMultiPickerOptions("Models")}
        />
        <Button2
          buttonContainerClass={`mt-23 ${
            isDesktopOrTablet ? "" : "search-button"
          }`}
          onClick={() => {
            handleValueChange(value);
          }}
          title={global.Search}
        />
      </Box>
      {value === "ModelWise" ? (
        <React.Fragment>
          {vehicleUtilizationData?.graphData != null &&
          vehicleUtilizationData?.graphData?.length > 0 ? (
            <>
              <Heading title={viewStats.ModelUtilization} className="mt-50" />
              <BarChart
                labels={vehicleUtilizationData?.labels}
                data={vehicleUtilizationData?.graphData}
              />
              <SimpleChart
                heading={[viewStats.VehicleModel, viewStats.Utilization]}
                labels={vehicleUtilizationData?.labels}
                data={vehicleUtilizationData?.graphData}
              />
            </>
          ) : null}
        </React.Fragment>
      ) : (
        <>
          <Grid
            container
            justifyContent={"center"}
            style={{ width: "100%", marginTop: "5rem" }}
          >
            <Grid style={{ marginRight: "1rem" }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                style={{
                  fontSize: "21px",
                  fontFamily: "EuclidCircularB-SemiBold",
                  color: "rgb(61, 56, 85)",
                }}
              >
                {`${heading} : ₹${totalAmount}`}
              </Typography>

              {value === "Revenue" ? (
                <>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                      fontSize: "14px",
                      fontFamily: "EuclidCircularB-Regular",
                      color: "rgb(61, 56, 85)",
                    }}
                  >
                    {global.TotalPaidAmount} : ₹
                    {parseFloat(
                      allStatisticsData?.revenueStats?.totalAmountPaid ?? 0
                    ).toFixed(2)}
                  </Typography>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{
                      fontSize: "14px",
                      fontFamily: "EuclidCircularB-Regular",
                      color: "rgb(61, 56, 85)",
                    }}
                  >
                    {global.TotalPendingAmount} : ₹
                    {parseFloat(
                      allStatisticsData?.revenueStats?.totalAmountPending ?? 0
                    ).toFixed(2)}
                  </Typography>
                </>
              ) : null}
            </Grid>
            {/* {!isDesktopOrTablet ? (
              <Grid>
                <Button2
                  title={global.Reset}
                  onClick={() => resetData()}
                  className="ml-20"
                />
              </Grid>
            ) : null} */}

            {currentPaymentData != null && currentPaymentData?.length > 0 ? (
              <Grid style={{ height: "40%" }}>
                {/* {value === "Payment" ? ( */}
                <PieChart
                  labels={currentPaymentData[0]}
                  data={currentPaymentData[1]}
                  pieOptions={pieOptions}
                />
                {/* ) : value === "Revenue" &&
                  currentRevenueData != null &&
                  currentRevenueData?.length > 0 ? (
                  <PieChart
                    labels={currentRevenueData[0]}
                    data={currentRevenueData[1]}
                    pieOptions={partitionPieOptions}
                  />
                ) : null} */}
              </Grid>
            ) : null}
            {/* {isDesktopOrTablet ? (
              <Button2
                title={global.Reset}
                onClick={() => resetData()}
                className="ml-100"
              />
            ) : null} */}
            <DownloadButtons
              data={
                allStatisticsDataDownload[0]
                // value == "Payment"
                //   ? radioChecked[0]
                //     ? paymentGraphDownloadData
                //     : paymentGraphDateWiseDownloadData
                //   : revenueGraphDownloadData
              }
              filename={
                value == "Payment"
                  ? radioChecked[0]
                    ? "Payment Statistics.csv"
                    : "DateWise Payments.csv"
                  : "Revenue Statistics.csv"
              }
              heading={"Statistics"}
              csvLink={csvLink}
              checked={checked}
              radioChecked={radioChecked}
              setIsRadioChecked={setIsRadioChecked}
              style={{
                width: "100%",
                marginLeft: "20px",
                borderRadius: "10px",
                padding: "15px",
              }}
              showRadioButtons={value == "Payment" ? true : false}
              onClick={() => {
                if (!downloadFlag) {
                  handleValueChange(value, true);
                }
              }}
              setIsChecked={setIsChecked}
            />
          </Grid>
          <SimpleChart
            heading={
              value === "ModelWise"
                ? [viewStats.VehicleModel, viewStats.Utilization]
                : [global.Payment, global.Amount]
            }
            labels={currentPaymentData[0]}
            data={currentPaymentData[1]}
          />
        </>
      )}
    </div>
  );
}

export default ViewStats;
