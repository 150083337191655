import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { MITC_FORM_URL } from "../../../config";
import { RootState } from "../../../redux/store";
import {
  EndIconLabelButtonX,
  StartIconLabelButtonX,
} from "../../components/muiButtonsX";
import SideDrawer from "../../components/MultiStepForm/SideDrawer";
import HeadingValueTypography from "../../components/MultiStepForm/headingValueTypography";
import SubscriptionItem from "../../components/MultiStepForm/subscriptionItem";
// import DataTable from "../../components/Template/DataTable/DataTable";
import Styles from "../../components/components.module.css";
import VdAccordion from "../../components/vdAccordion";
import {
  ADDITIONAL_CHARGES_DISPLAY_STATUS,
  ADDITIONAL_CHARGES_STATUS,
  DisplayChargesNameTypes,
  DisplayPaymentButtonLabel,
  DisplayPaymentModes,
  OrderPaymentType,
  PaymentJourneyStatus,
} from "../../constantsX/YOR_BookingDetails";
import { BOOKING_STATUS_ENUM } from "../../constantsX/booking";
import {
  BikeIcon,
  ExtraChargesIcon,
  PaymentInitiatedIcon,
  PendingClockIcon,
  SuccessTick,
  WavingHandIcon,
  odometerIcon,
} from "../../constantsX/exportImagesX";
import { DetailsTypographyProps } from "../../interfaces/componentsInterfaces";
import { actionsX } from "../../redux";
import {
  cleanUpOrderAndPaymentData,
  createOrderForBulkPaymentYOR,
  getBookingDetailsYOR,
} from "../../redux/actions";
import { colors } from "../../themes/colors";
import { checkIfImageExists, getFormattedINR } from "../../utils/helper";
import styles from "./bookingDetails.module.css";
import { DataTable } from "../../../sharedComponents/templates/Tables/dataTable";
const MyVehicle: any = ({ vehicleData, modelMapData }: any) => {
  const modelDisplayName = modelMapData.has(vehicleData?.modelName)
    ? modelMapData.get(vehicleData?.modelName)
    : vehicleData?.modelName;
  const odometerReading = vehicleData?.vehicles[0]?.startOdometerValue
    ? vehicleData?.vehicles[0]?.startOdometerValue
    : "N/A";
  return (
    <Stack direction="row" gap="20px" alignItems="center">
      <Stack direction="row" gap="20px" alignItems="center">
        <Box className={styles.iconWrapper}>
          <img src={BikeIcon} />
        </Box>
        <Box>
          <Typography
            className={styles.bookingDetailsValue}
            sx={{ fontWeight: 500 }}
          >
            {modelDisplayName}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" gap="20px" alignItems="center">
        <Box className={styles.iconWrapper}>
          <img src={odometerIcon} />
        </Box>
        <Box>
          <HeadingValueTypography
            heading={"Odometer Reading :"}
            value={odometerReading}
          />
        </Box>
      </Stack>
    </Stack>
    // </Box>
  );
};

const MyVehicleBody: any = (props: any) => {
  const resistrationNo = props?.vehicleData?.vehicleRegistrationDetails
    ?.registration
    ? props?.vehicleData?.vehicleRegistrationDetails?.registration
    : "N/A";
  const chassisNo = props?.vehicleData?.vehicleRegistrationDetails?.chassis
    ? props?.vehicleData?.vehicleRegistrationDetails?.chassis
    : "N/A";
  const modelsMapImages = props?.modelsMapImages;

  const modelImagesArray = modelsMapImages.has(
    props?.vehicleDetailsSection?.modelName
  )
    ? modelsMapImages.get(props?.vehicleDetailsSection?.modelName)
    : [];
  let modelImageFilename = "";
  modelImagesArray.length > 0 &&
    modelImagesArray.map((item: any, index: number) => {
      if (item?.type === "right") {
        modelImageFilename = item?.fileName;
      }
      if (!modelImageFilename) {
        modelImageFilename = item?.fileName;
      }
    });

  const imagePathUrl = modelImageFilename
    ? `${MITC_FORM_URL}/images/model/${props?.vehicleDetailsSection?.modelName}/${modelImageFilename}`
    : "";
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "2vw" }}>
      <Box>
        {/* Format URL to show image */}
        {/* "https://dev.freedo.rentals/images/model/HM_SPLENDOR/front_download.jpg" */}
        {/* https://dev.freedo.rentals/images/model/HM_GLAMOUR/hero-glamour.jpg */}
        {imagePathUrl && checkIfImageExists(imagePathUrl) ? (
          <img
            className={styles.modalImage}
            src={imagePathUrl}
            alt="Model Image"
            title="Model Image"
          />
        ) : (
          <img src={BikeIcon} alt="Model Icon" title="Model Icon" />
        )}
      </Box>
      <HeadingValueTypography
        heading={"Vehicle number: "}
        value={resistrationNo}
      />
      <HeadingValueTypography heading={"Chassis Number: "} value={chassisNo} />
    </Box>
  );
};

const ExtraCharges: any = ({ extraCharges }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Box className={styles.iconWrapper}>
        <img src={ExtraChargesIcon} />
      </Box>
      <DetailsTypography
        heading={"Pending Charges: "}
        value={getFormattedINR(extraCharges?.totalPendingAmount)}
        valueStyle={{ fontSize: "0.7rem", color: "#FF3B30" }}
      />
      <DetailsTypography
        heading={"Extra Violations: "}
        value={extraCharges?.totalViolation}
        valueStyle={{ fontSize: "0.7rem" }}
      />
    </Box>
  );
};

const columnStatusUI = (row: GridRenderCellParams): JSX.Element => {
  let rowData = row?.row;
  switch (rowData?.status) {
    case ADDITIONAL_CHARGES_STATUS.COLLECTED:
      return (
        <>
          <img src={SuccessTick} height={"20px"} width={"auto"} />
          <Typography
            variant="caption"
            color={colors.secondary_green}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {ADDITIONAL_CHARGES_DISPLAY_STATUS.COLLECTED}
          </Typography>
        </>
      );

    case ADDITIONAL_CHARGES_STATUS.PENDING:
      return (
        <>
          <img src={PendingClockIcon} width={"auto"} height={"23px"} />
          <Typography
            variant="caption"
            color={colors.primary_warning}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {ADDITIONAL_CHARGES_DISPLAY_STATUS.PENDING}
          </Typography>
        </>
      );

    case ADDITIONAL_CHARGES_STATUS.WAIVED_OFF:
      return (
        <>
          <img src={WavingHandIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontWeight={500}
            fontSize={"14px"}
          >
            {" "}
            {ADDITIONAL_CHARGES_DISPLAY_STATUS.WAIVED_OFF}
          </Typography>
        </>
      );

    case ADDITIONAL_CHARGES_STATUS.INITIATED:
      return (
        <>
          <img src={PaymentInitiatedIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {ADDITIONAL_CHARGES_DISPLAY_STATUS.INITIATED}
          </Typography>
        </>
      );
  }
  return <></>;
};

const headCells = [
  {
    field: "id",
    headerName: "Charge Id",
    numeric: false,
    disablePadding: false,
    label: "Charge Type",
    resizable: false,
    headerAlign: "center",
    align: "center",
    minWidth: 150,
    maxWidth: 250,
    valueGetter: (value: any, row: any) => row?._id,
  },
  {
    field: "chargeType",
    headerName: "Charge Type",
    numeric: false,
    disablePadding: false,
    label: "Charge Type",
    resizable: false,
    headerAlign: "center",
    align: "center",
    minWidth: 150,
    maxWidth: 250,
    valueGetter: (value: any, row: any) =>
      DisplayChargesNameTypes[
        row?.chargeCategory as keyof typeof DisplayChargesNameTypes
      ],
  },
  {
    field: "subType",
    headerName: "Subtype",
    numeric: false,
    disablePadding: false,
    label: "Sub Type",
    resizable: false,
    headerAlign: "center",
    align: "center",
    minWidth: 150,
    maxWidth: 250,
    valueGetter: (value: any, row: any) => row?.subCategoryDetails?.name,
  },
  {
    field: "price",
    headerName: "Price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    minWidth: 100,
    maxWidth: 200,
    // valueGetter: (value: any, row: any) => getFormattedINR(row?.amount),
    resizable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (parms: GridRenderCellParams<any>) => (
      <Typography
        variant="caption"
        color={colors.secondary_green_dark}
        fontWeight={400}
      >
        {getFormattedINR(parms?.row?.amount)}
      </Typography>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    resizable: false,
    headerAlign: "center",
    align: "center",
    minWidth: 250,
    maxWidth: 300,
    renderCell: (parms: GridRenderCellParams<any>) => columnStatusUI(parms),
  },
];

// Extra Charges Table
const ExtraChargesBody: any = ({
  extraCharges,
  setSelectedRows,
  selectedRows,
  setOpenSideDrawerWF,
  setOpenSideDrawerPS,
  setCalculateTotal,
}: any) => {
  const getRowId = (row: any) => {
    return row?._id;
  };

  const handleModelOpen = (purpose: string) => {
    if (purpose === "waivedOff") setOpenSideDrawerWF(true);
    else if (purpose === "paySelected") setOpenSideDrawerPS(true);
  };

  const handleSelectedRow = (row: any) => {
    let total: number = 0;
    let arr = row?.map((row: string, index: number) => {
      let ind = extraCharges?.charges.findIndex(
        (data: any) => data._id === row
      );

      total += extraCharges?.charges[ind]?.amount;
      return extraCharges?.charges[ind];
    });

    setCalculateTotal(total);
    setSelectedRows(arr);
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Stack direction="row" gap="1vw" margin="15px 10px 10px 0px">
          <Typography variant="caption" sx={{ fontSize: "14px" }}>
            Total charges:
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "13px" }}>
            {getFormattedINR(extraCharges?.totalCharges)}
          </Typography>
        </Stack>
      </Stack>

      <DataTable 
        rows={extraCharges?.charges}
        columns={headCells}
        headerAlign={"center"}
        disableColumnSorting
        disableColumnMenu
        checkboxSelection
        getRowId={getRowId}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        isRowSelectable={(params: GridRowParams) =>
          params?.row?.status === PaymentJourneyStatus?.PENDING
        }
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          handleSelectedRow(newRowSelectionModel);
        }}
        hideFooter={true}
      />
      <Box
        style={{
          gap: "10px",
          display: "flex",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <StartIconLabelButtonX
          icon={WavingHandIcon}
          label={"Waived Off"}
          variant={"outlined"}
          minWidth={"176px"}
          disabled={!selectedRows?.length}
          onClick={() => handleModelOpen("waivedOff")}
        />

        <EndIconLabelButtonX
          variant={"contained"}
          label={"Pay Selected"}
          width={"80px"}
          disabled={!selectedRows?.length}
          onClick={() => handleModelOpen("paySelected")}
        />
      </Box>
    </Stack>
  );
};

const DetailsTypography = (props: DetailsTypographyProps) => {
  return (
    <Box>
      <Typography
        variant="caption"
        color={colors.text_secondary_gray}
      >
        {props?.heading}
      </Typography>
      <Typography
        sx={props?.valueStyle}
        color={colors.text_black}
      >
        {props?.value}
        {props?.link ? (
          <Typography
            className={styles.bookingDetailsValue}
            color={colors.THEME_BLUEX}
          >
            {props.link}
          </Typography>
        ) : (
          ""
        )}
      </Typography>
    </Box>
  );
};
const AccordianBadge = ({ title }: any) => {
  return (
    <Box sx={{ backgroundColor: "green" }}>
      <Typography variant="caption" className="hehe">
        {title}hehe
      </Typography>
    </Box>
  );
};

const WaivedOffModal = (props: any) => {
  return (
    <>
      <Box
        sx={{
          borderWidth: "1px solid",
          border: colors.text_secondary_gray,
          borderRadius: "12px",
          padding: "1px 0px",
          backgroundColor: "#EFF1F0",
        }}
      >
        {/* heading */}
        <Box
          sx={{
            backgroundColor: "#E4E4E4",
            borderRadius: "12px 12px 0px 0px",
            padding: "9px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: "#636369",
              lineHeight: "19px",
              fontSize: "14px",
            }}
          >
            Charges Summary
          </Typography>
        </Box>
        {props?.selectedRow?.map((item: any, index: any) => (
          <SubscriptionItem
            dashedBorder={false}
            title={
              DisplayChargesNameTypes[
                item?.chargeCategory as keyof typeof DisplayChargesNameTypes
              ]
            }
            amount={getFormattedINR(item?.amount)}
            fontSize="10px"
            showBorder={true}
            description={item?.subCategoryDetails?.name}
          />
        ))}

        <Box className={Styles.btnPopup}>
          {" "}
          <EndIconLabelButtonX
            variant="contained"
            label="Waive Off"
            minWidth="320px"
            onClick={props?.handleWaivedOffCharge}
            loading={props?.waivedOffLoader}
          />
        </Box>
      </Box>

      {/* Wave off reasons */}
      {/* <Box>
        <SelectX
          width="100%"
          placeholder={"Select Wave Off Reason"}
          value={"pageSize?.name"}
          // choice={"pageChoice"}
          defaultKey={"name"}
          required
          icon={WavingHandIcon}
          // handleChange={handlePageSizeChange}
        />
      </Box> */}
    </>
  );
};

const PaymentSelectedModal = (props: any) => {
  return (
    <>
      {Object.keys(props?.paymentLinkQRData)?.length ? (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "10px",
              color: "#636369",
            }}
          >
            Payment Summary :
          </Typography>
          <Box
            sx={{
              borderWidth: "1px solid",
              border: colors.text_secondary_gray,
              borderRadius: "12px",
              padding: "1px 0px",
              backgroundColor: "#fff",
            }}
          >
            {/* heading */}

            {props?.selectedRow?.map((item: any, index: any) => {
              return (
                <SubscriptionItem
                  dashedBorder={false}
                  title={
                    DisplayChargesNameTypes[
                      item?.chargeCategory as keyof typeof DisplayChargesNameTypes
                    ]
                  }
                  amount={getFormattedINR(item?.amount)}
                  fontSize="10px"
                  showBorder={true}
                  description={item?.subCategoryDetails?.name}
                />
              );
            })}

            <SubscriptionItem
              dashedBorder={false}
              title={"Total"}
              amount={getFormattedINR(props?.calculateTotal)}
              fontSize="10px"
              showBorder={true}
            />
          </Box>

          {props?.paymentLinkQRData?.paymentFlow ===
          OrderPaymentType.PAYMENT_LINK ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                borderWidth: "1px solid",
                border: colors.text_secondary_gray,
                borderRadius: "12px",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "10px",
                  color: "#636369",
                }}
              >
                Payment Link :
              </Typography>

              <a
                href={props?.paymentLinkQRData?.pgDetails?.link}
                target="_blank"
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "10px",
                    color: "blue",
                  }}
                >
                  {props?.paymentLinkQRData?.pgDetails?.link}
                </Typography>
              </a>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography variant="caption" color={colors.text_secondary_gray}>
                <QrCodeScannerIcon color="primary" /> Scan to Pay Now
              </Typography>
              {props?.paymentLinkQRData?.pgDetails?.imageContent ? (
                /* ONLY QR CODE */
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    //  maxWidth: "100%", width: "100%"
                  }}
                  value={props?.paymentLinkQRData?.pgDetails?.imageContent}
                  viewBox={`0 0 256 256`}
                />
              ) : (
                /* FULL QR SECTION */
                <img
                  src={props?.paymentLinkQRData?.pgDetails?.link}
                  alt={props?.paymentLinkQRData?.pgDetails?.id}
                />
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "10px",
              color: "#636369",
            }}
          >
            Payments Summary :
          </Typography>
          <Box
            sx={{
              borderWidth: "1px solid",
              border: colors.text_secondary_gray,
              borderRadius: "12px",
              padding: "1px 0px",
              backgroundColor: "#fff",
            }}
          >
            {/* heading */}

            {props?.selectedRow?.map((item: any, index: any) => (
              <SubscriptionItem
                dashedBorder={false}
                title={
                  DisplayChargesNameTypes[
                    item?.chargeCategory as keyof typeof DisplayChargesNameTypes
                  ]
                }
                amount={getFormattedINR(item?.amount)}
                fontSize="10px"
                showBorder={true}
                description={item?.subCategoryDetails?.name}
              />
            ))}

            <SubscriptionItem
              dashedBorder={false}
              title={"Total"}
              amount={getFormattedINR(props?.calculateTotal)}
              fontSize="10px"
              showBorder={true}
            />
          </Box>

          <Box>
            <Typography
              color={colors.text_secondary_gray}
              fontSize="12px"
              fontWeight="500"
              marginBottom="5px"
            >
              Select Payment Method :
            </Typography>
            <Box
              sx={{
                borderWidth: "1px solid",
                border: colors.text_secondary_gray,
                borderRadius: "12px",
                padding: "1px 0px",
                backgroundColor: "#fff",
              }}
              border={colors.text_secondary_gray}
            >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={props?.selectedPayment}
                onChange={(e: any) => props?.handlePaymentModes(e)}
              >
                {props?.paymentModes.map((method: any, index: any) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderBottom:
                          index < props?.paymentModes.length
                            ? "1px solid #D4E4E452"
                            : "",
                        padding: "2px 18px",
                      }}
                    >
                      <FormControlLabel
                        value={method}
                        control={<Radio />}
                        label={
                          DisplayPaymentModes[
                            method as keyof typeof DisplayPaymentModes
                          ]
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: colors.text_secondary_gray,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </RadioGroup>
            </Box>
            <Box className={Styles.btnPopup}>
              {" "}
              <EndIconLabelButtonX
                variant="contained"
                label={
                  DisplayPaymentButtonLabel[
                    props?.selectedPayment as keyof typeof DisplayPaymentButtonLabel
                  ]
                }
                minWidth="320px"
                onClick={props?.generateOrder}
                loading={props?.waivedOffLoader}
                disabled={props?.selectedPayment === "DEFAULT"}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

const VehicleDetails = ({
  vehicleDetailsSection,
  bookingDetailsloader,
  modelMapData,
  modelsMapImages,
  bookingId,
  bookingParams,
}: any) => {
  const [selectedRows, setSelectedRows] = useState<object[]>([]);
  const [openSideDrawerPS, setOpenSideDrawerPS] = useState<boolean>(false);
  const [openSideDrawerWF, setOpenSideDrawerWF] = useState<boolean>(false);
  const [sideDrawerData, setSideDrawerData] = useState<object[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<string>("DEFAULT");
  const [calculateTotal, setCalculateTotal] = useState<number>(0);
  const dispatch = useDispatch();

  const {
    waivedOffLoader,
    paymentModes,
    paymentLinkQRData,
    paymentLinkQRLoader,
    bookingStatus,
  } = useSelector((state: RootState) => state.YOR_bookingDetailsReducer);

  const handleModalClosePS = () => {
    setOpenSideDrawerPS(false);
    dispatch(getBookingDetailsYOR(bookingParams));
    dispatch(cleanUpOrderAndPaymentData());
  };
  const handleModalCloseWF = () => {
    setOpenSideDrawerWF(false);
    // dispatch(cleanUpOrderAndPaymentData());
  };

  const handleWaivedOffCharge = () => {
    if (selectedRows?.length > 0) {
      let ids: string[] = selectedRows?.map((row: any) => row?._id);

      const payload = {
        bookingParams,
        apiPayload: {
          data: {
            chargesIds: ids,
          },
        },
      };
      dispatch(actionsX.bookingWaveOffCharges(payload));
      handleModalCloseWF();
    }
  };

  const handlePaymentModes = (e: any) => setSelectedPayment(e.target?.value);

  const generateOrder = () => {
    if (selectedPayment === "PAYMENT_LINK" || selectedPayment === "QR_CODE") {
      let ids: string[] = selectedRows?.map((row: any) => row?._id);
      let orderPayload = {
        additionalChargesIds: ids,
        selectedPaymentMode: selectedPayment,
        bookingId: bookingParams?.id,
      };
      dispatch(createOrderForBulkPaymentYOR(orderPayload));
    }
  };

  return (
    <Box padding={"24px 24px 32px 24px"}>
      {/* VEHICLE */}
      <VdAccordion title="My Vehicle">
        <MyVehicle
          key={"heading"}
          vehicleData={vehicleDetailsSection}
          modelMapData={modelMapData}
        />
        <MyVehicleBody
          key={"body"}
          vehicleDetailsSection={vehicleDetailsSection}
          vehicleData={
            vehicleDetailsSection?.vehicles?.length > 0
              ? vehicleDetailsSection?.vehicles[0]
              : []
          }
          modelsMapImages={modelsMapImages}
        />
      </VdAccordion>

      {/* EXTRA CHARGES */}
      {vehicleDetailsSection?.additionalCharges?.charges?.length > 0 &&
      (bookingStatus === BOOKING_STATUS_ENUM.ACTIVE ||
        bookingStatus === BOOKING_STATUS_ENUM.CANCELLED ||
        bookingStatus === BOOKING_STATUS_ENUM.CLOSED) ? (
        <>
          <VdAccordion title="Extra Charges">
            {/* <AccordianBadge title="Extra Charges" /> */}
            <ExtraCharges
              key={"heading"}
              extraCharges={vehicleDetailsSection?.additionalCharges}
            />
            <ExtraChargesBody
              key={"body"}
              extraCharges={vehicleDetailsSection?.additionalCharges}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setOpenSideDrawerPS={setOpenSideDrawerPS}
              setOpenSideDrawerWF={setOpenSideDrawerWF}
              calculateTotal={calculateTotal}
              setCalculateTotal={setCalculateTotal}
            />
          </VdAccordion>
        </>
      ) : null}

      <SideDrawer
        open={openSideDrawerWF}
        toggleDrawer={handleModalCloseWF}
        heading={"Waived Off Charges"}
        renderUI={
          <WaivedOffModal
            selectedRow={selectedRows}
            handleWaivedOffCharge={handleWaivedOffCharge}
            waivedOffLoader={waivedOffLoader}
          />
        }
      />

      <SideDrawer
        open={openSideDrawerPS}
        toggleDrawer={handleModalClosePS}
        heading={"Additional Charges Payment"}
        renderUI={
          <PaymentSelectedModal
            selectedRow={selectedRows}
            paymentModes={paymentModes}
            handlePaymentModes={handlePaymentModes}
            selectedPayment={selectedPayment}
            generateOrder={generateOrder}
            paymentLinkQRLoader={paymentLinkQRLoader}
            paymentLinkQRData={paymentLinkQRData}
            calculateTotal={calculateTotal}
          />
        }
      />
    </Box>
  );
};
export default VehicleDetails;
