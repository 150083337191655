import { Box, Fade, Modal, Tooltip, Typography } from "@mui/material";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { CrossIconRound } from "../../../../constants/exportImages";

import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";

import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_KEY_MAP } from "../../../../../constants/bookingContants";
import { RootState } from "../../../../../redux/reducers";
import { checkAlfaNumeric, checkNumeric } from "../../../../../utils/regex";
import {
  GURUGRAM_LAT_LNG,
  WAREHOUSE_DELIVERY_TYPES,
} from "../../../../constants/constants";
import {
  DeliveryChargesModalInterface,
  DeliverySetupFields,
} from "../../../../interfaces/bussinessManagement";
import en from "../../../../locale/rental-en.json";
import styles from "./section.module.css";
import { Height } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  left: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "16px 0px",
  borderRadius: "24px",
  transform: "translate(-50%)",
};

const DeliveryChargesModal = (props: DeliveryChargesModalInterface) => {
  const {
    handleChange,
    fields,
    setFields,
    filterCities,
    resetPolygonData,
    deleteDeliveryCharges,
    onClose,
    conditions,
    savePolygon,
    handleInfoWindow,
    open,
    saveFullCity,
    editRole,
  } = props;

  const { warehouseManagement, global } = en;

  const { showInfoWindow, isEditCharges } = conditions;

  const { deliveryTypes, polygonsData } = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );
  const { allCitiesDetails, allStatesList } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const dispatch = useDispatch();

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any>([]);

  // Sets polygonPath with new edited lat lng
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      // props?.setPath(nextPath);
      setFields((prev: DeliverySetupFields) => ({
        ...prev,
        polygonPath: nextPath,
      }));
    }
  }, [fields?.polygonPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon: any) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  // Handle map click event
  const onMapClick = useCallback(
    (e: any) => {
      const clickedLatLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };

      // If there are no polygons yet or the last polygon has 5 points, create a new polygon
      if (fields?.polygonPath?.length === 0) {
        if (fields?.polygonPath?.length != 0) {
          const resetConfirmed = window.confirm(
            "You have reached the maximum number of points. Do you want to create a new polygon?"
          );
          if (resetConfirmed) {
            setFields((prev: DeliverySetupFields) => ({
              ...prev,
              polygonPath: [...prev.polygonPath, clickedLatLng],
            }));
          }
        } else {
          setFields((prev: DeliverySetupFields) => ({
            ...prev,
            polygonPath: [...prev.polygonPath, clickedLatLng],
          }));
        }
      } else {
        // Add clicked coordinates to the last polygon
        // props?.setPath((prevPath: any) =>
        //   prevPath?.map((item: any, index: any) => {
        //     return index === prevPath.length - 1
        //       ? [...item, clickedLatLng]
        //       : item;
        //   })
        // );
        setFields((prev: DeliverySetupFields) => ({
          ...prev,
          polygonPath: [...prev.polygonPath, clickedLatLng],
        }));
      }
    },
    [fields?.polygonPath]
  );

  //return modal top UI
  const ModalTopUI = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "14px",
        padding: "0px 16px",
      }}
    >
      <CustomSelect
        width="100%"
        placeholder={warehouseManagement.chargeType}
        value={fields?.chargeType?.displayName}
        choice={fields?.filteredDeliveryCharges}
        defaultKey={"displayName"}
        required
        handleChange={(chargeType: any) => {
          handleChange("chargeType", chargeType);
          filterCities(chargeType?.name);
          setFields((prev: DeliverySetupFields) => ({
            ...prev,
            serviceableCity: { name: "", displayName: "" },
          }));
        }}
        disabled={props?.editRole && conditions.isEditCharges}
      />

      {fields?.chargeType?.name && (
        <CustomSelect
          width="100%"
          placeholder={warehouseManagement?.serviceableCity}
          value={fields?.serviceableCity?.displayName}
          choice={fields.filteredCities}
          defaultKey={"displayName"}
          required
          handleChange={(serviceableCity: any) => {
            handleChange("serviceableCity", serviceableCity);
          }}
          disabled={props?.editRole && conditions?.isEditCharges}
        />
      )}
    </Box>
  );

  //return view polygons UI
  const ViewPolygonsUI = (data: any, index: number) => {
    const { geofencingId, geofencingData, name, deliveryAmount = "" } = data;
    const { coordinates } = geofencingData;
    return (
      <>
        <Polygon
          key={index}
          path={coordinates}
          options={{
            fillColor: "yellow",
            fillOpacity: 0.4,
            strokeColor: "#d35400",
            strokeOpacity: 0.8,
            strokeWeight: 3,
          }}
          onMouseOver={() => handleInfoWindow(geofencingId, true)}
          onMouseOut={() => handleInfoWindow(geofencingId, false)}
        />

        {showInfoWindow[geofencingId] && (
          <InfoWindow
            key={index}
            position={{
              lat: coordinates[0]?.lat,
              lng: coordinates[0]?.lng,
            }}
          >
            <Box
              className="infowindow"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box>{"Polygon Name :" + name}</Box>
              <Box>{"Polygon Price :" + deliveryAmount}</Box>
            </Box>
          </InfoWindow>
        )}
      </>
    );
  };

  // get lat lng in edit mode
  const getCoordinatesEdit = () => {
    return {
      lat: fields.polygonPath?.length
        ? fields.polygonPath[0]?.lat
        : fields.serviceableCity?.geoLocationData?.cordinates?.lat
        ? fields.serviceableCity?.geoLocationData?.cordinates?.lat
        : GURUGRAM_LAT_LNG.LAT,
      lng: fields.polygonPath?.length
        ? fields.polygonPath[0]?.lng
        : fields.serviceableCity?.geoLocationData?.cordinates?.lng
        ? fields.serviceableCity?.geoLocationData?.cordinates?.lng
        : GURUGRAM_LAT_LNG.LNG,
    };
  };

  //get coordinates in add mode
  const getCoordinatesAdd = () => {
    return {
      lat: fields.polygonPath?.length
        ? fields.polygonPath[0]?.lat
        : fields.serviceableCity?.geoLocationData?.cordinates.lat
        ? fields.serviceableCity?.geoLocationData?.cordinates?.lat
        : GURUGRAM_LAT_LNG.LAT,
      lng: fields.polygonPath?.length
        ? fields.polygonPath[0]?.lng
        : fields.serviceableCity?.geoLocationData?.cordinates?.lng
        ? fields.serviceableCity?.geoLocationData?.cordinates.lng
        : GURUGRAM_LAT_LNG.LNG,
    };
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY_MAP,
    libraries: ["geometry", "drawing"],
  });

  const checkEditData = (): boolean => {
    const fieldsEmpty =
      fields.polygonPrice === "" || fields?.polygonName?.trim() === "";

    const fieldsDiffrenceName =
      fields?.polygonName === fields?.chargesData?.name;

    const fieldsDiffrencePrice =
      parseInt(fields?.polygonPrice) ===
      parseInt(fields?.chargesData?.deliveryAmount);

    const coordinateDiffrence =
      JSON.stringify(fields?.polygonPath) ===
      JSON.stringify(fields?.chargesData?.geofencingData?.coordinates);

    if (fieldsEmpty) return true;

    return fieldsDiffrenceName && fieldsDiffrencePrice && coordinateDiffrence;
  };

  // returns create polygon for specific regions UI
  const CreatePolygonUI = () => {
    return (
      <>
        <Fade in={true}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "14px",
              padding: "0px 16px",
            }}
          >
            <Box>
              <div className={styles.App}>
                {isLoaded && (
                  <GoogleMap
                    mapContainerClassName={styles.AppMap}
                    center={
                      isEditCharges ? getCoordinatesEdit() : getCoordinatesAdd()
                    }
                    zoom={12}
                    // version="weekly"///////////
                    onClick={onMapClick}
                    // options={{
                    //   restriction: {
                    //     latLngBounds: IndiaBounds,
                    //     strictBounds: true,
                    //   },
                    // }}
                  >
                    <>
                      {polygonsData?.map((item: any, index: number) => {
                        const { type, geofencingId } = item;

                        if (
                          type === WAREHOUSE_DELIVERY_TYPES.POLYGON &&
                          geofencingId !== fields?.chargesData?.geofencingId
                        ) {
                          return ViewPolygonsUI(item, index);
                        }
                      })}

                      <Polygon
                        // Make the Polygon editable / draggable
                        editable
                        //draggable
                        path={fields?.polygonPath}
                        // Event used when manipulating and adding points
                        onMouseUp={onEdit}
                        // Event used when dragging the whole Polygon
                        // onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          fillColor: "#2bc7c9",
                          strokeOpacity: 0.8,
                          strokeWeight: 3,
                          strokeColor: "#2bc7c9",
                        }}
                      />
                    </>
                  </GoogleMap>
                )}
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                {warehouseManagement.nameAndSetPrice}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <CustomButton
                  variant={"text"}
                  startIcon={
                    <RotateLeftIcon
                      sx={{ marginRight: "-15px", height: "20px" }}
                    />
                  }
                  label={global.reset}
                  style={{
                    height: "23px",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                  onClick={() => resetPolygonData()}
                />

                {isEditCharges && (
                  <CustomButton
                    variant={"text"}
                    startIcon={
                      <DeleteIcon
                        sx={{ marginRight: "-15px", height: "20px" }}
                      />
                    }
                    label={global.delete}
                    style={{
                      height: "23px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      deleteDeliveryCharges();
                      onClose();
                    }}
                    // disabled={!isEditCharges}
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "14px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Input
                  placeholder={warehouseManagement.polygonName}
                  value={fields.polygonName}
                  required
                  onChange={(e: any) => {
                    checkAlfaNumeric(e?.target.value) &&
                      e.target.value.length < 31 &&
                      handleChange("polygonName", e.target.value);
                  }}
                  width="260px"
                />
              </Box>
              <Box>
                {" "}
                <Input
                  placeholder={warehouseManagement.setPrice}
                  value={fields?.polygonPrice}
                  required
                  onChange={(e: any) => {
                    e.preventDefault();
                    let price: any = e.target.value;
                    price = price.toString().replace(/^0+/, "");
                    checkNumeric(price) &&
                      price?.length < 6 &&
                      handleChange("polygonPrice", price);
                  }}
                  width="100%"
                />
              </Box>
            </Box>

            <Box>
              <CustomButton
                variant="contained"
                label={global.save}
                style={{
                  height: "40px",
                  borderRadius: "30px",
                  width: "100%",
                }}
                disabled={
                  isEditCharges
                    ? checkEditData()
                    : !(
                        parseInt(fields?.polygonPrice) > 0 &&
                        fields.polygonName &&
                        fields.polygonPath?.length > 2 &&
                        fields.serviceableCity?.name &&
                        fields?.polygonName?.trim() !== ""
                      )
                }
                onClick={() => {
                  savePolygon();
                }}
              />
            </Box>
          </Box>
        </Fade>
      </>
    );
  };

  // returns create full city  UI
  const CreateFullCityUI = () => {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid #00000029",
            height: "1px",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "14px",
            justifyContent: "space-between",
            padding: "0px 16px",
            alignItems: "center",
          }}
        >
          <Box>
            <Input
              value={fields.polygonPrice}
              placeholder={warehouseManagement?.setPrice}
              onChange={(e: any) => {
                e.preventDefault();
                let price: any = e.target.value;
                price = price.toString().replace(/^0+/, "");
                checkNumeric(price) &&
                  price?.length < 6 &&
                  handleChange("polygonPrice", price);
              }}
              width={"148px"}
            />
          </Box>

          <CustomButton
            variant="contained"
            label={global.save}
            style={{
              borderRadius: "30px",
              width: isEditCharges ? "165px" : "200px",
              height: "100%",
            }}
            disabled={
              isEditCharges
                ? !(
                    parseInt(fields?.polygonPrice) !==
                      fields?.chargesData?.deliveryAmount &&
                    fields.polygonPrice !== ""
                  )
                : !(
                    parseInt(fields?.polygonPrice) > 0 &&
                    fields?.serviceableCity?.name !== ""
                  )
            }
            onClick={() => {
              saveFullCity();
            }}
          />

          {isEditCharges && (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#B1B1B4",
                width: "40px",
                height: "100%",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomIconButton
                // disabled={!isEditCharges}
                onClick={() => {
                  deleteDeliveryCharges();
                  onClose();
                }}
                children={<DeleteIcon />}
              />
            </Box>
          )}
        </Box>
      </>
    );
  };

  // main return
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "relative",
              // bottom: "168px",
              top: "-57px",
              left: "44%",
            }}
          >
            <Tooltip title={global.close}>
              <img
                src={CrossIconRound}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                }}
                onClick={() => props.onClose()}
              />
            </Tooltip>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "14px",
              width: "420px",
              // padding: "10px",
              justifyContent: "center",
              //   alignItems: "center",
            }}
          >
            <ModalTopUI />

            {fields?.chargeType?.name === WAREHOUSE_DELIVERY_TYPES.CITY &&
              fields?.serviceableCity?.name &&
              CreateFullCityUI()}

            {fields?.chargeType?.name === WAREHOUSE_DELIVERY_TYPES.POLYGON &&
              fields?.serviceableCity?.name &&
              CreatePolygonUI()}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default DeliveryChargesModal;
