import { Box, Skeleton, Stack } from "@mui/material";

const DLModalLoader = () => {
  return (
    <Stack
      gap="5px"
      padding={"10px"}
      height="-webkit-fill-available"
      justifyContent={"space-between"}
      alignItems={"space-between"}
    >
      <Stack gap="10px">
        <Stack direction={"row"} gap="2px">
          <Skeleton
            variant="rectangular"
            sx={{ transform: "none !important" }}
            height={"20vh"}
            width="50%"
          />
          <Skeleton
            variant="rectangular"
            sx={{ transform: "none !important" }}
            height={"20vh"}
            width="50%"
          />
        </Stack>
        <Skeleton
          variant="rectangular"
          sx={{ transform: "none !important" }}
          height={"20vh"}
          width="100%"
        />
      </Stack>
      <Skeleton
        variant="rectangular"
        sx={{ transform: "none !important" }}
        height={"5vh"}
        width="100%"
      />
    </Stack>
  );
};

export default DLModalLoader;
