import { Box, Stack, Tooltip, Typography } from "@mui/material";
import HeadingValueTypography from "../../../../../../sharedComponents/templates/MultiStepForm/headingValueTypography";
import { colors } from "../../../../../../themes/colors";
import {
  DISPLAY_STATUS,
  STATUS,
  typographyConstants,
} from "../../../../../constants/constants";
import en from "../../../../../locale/rental-en.json";
import { getDateTimeFromTimeStamp } from "../../../../../utils/helper";
import { StyleObject } from "./StylesObject";

export const PingHistoryCards = (data: any) => {
  const { location, address, lastActive, status, odometerReading } = data?.data;
  const { global, buttonLabels, VehicleManagement } = en;

  return (
    <Box sx={StyleObject.iotCardOuterBoxShadow}>
      <Box>
        <Box sx={StyleObject.iotCardTopBox}>
          <Box
            sx={{
              backgroundColor:
                status === STATUS.ACTIVE
                  ? colors.secondary_green
                  : colors.primary_danger,
              ...StyleObject.iotCardStatus,
            }}
          >
            <Typography sx={{ fontWeight: 500, color: colors.white }}>
              {DISPLAY_STATUS[status as keyof typeof DISPLAY_STATUS]}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: colors.text_black,
              }}
            >
              {VehicleManagement.lastActive}
            </Typography>

            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "13px",
                color: colors.card_background_secondary,
              }}
            >
              {`${getDateTimeFromTimeStamp(
                lastActive,
                "date"
              )}, ${getDateTimeFromTimeStamp(lastActive, "hours")}`}
            </Typography>
          </Box>
        </Box>
        <Stack
          sx={{
            padding: "10px",
            gap: "10px 0px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <HeadingValueTypography
              heading={global.odometerReading}
              value={`${
                odometerReading ? odometerReading + " " + global.km : global.NA
              } `}
            />
            <HeadingValueTypography
              heading={global.coordinates}
              value={`${location?.latitude}, ${location?.longitude}`}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack>
              <Typography
                variant={typographyConstants.SUBHEADING}
                color={colors.text_secondary_gray}
                sx={{ fontSize: "11px" }}
              >
                {`${global.address} :`}
              </Typography>

              <Typography
                variant={typographyConstants.HEADING}
                sx={{
                  padding: "0px",
                  fontSize: "14px",
                  width: "310px",
                }}
              >
                {address}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
