import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { colors } from "../themes/colors";
import { ar } from "date-fns/locale";

interface BasicSelectInterface {
 heading: string;
 data: Array<any>;
 style?: any;
 width?: string | number;
 variant?: any;
 placeholder?: string;
 className?: string;
 padding?: string;
 margin?: string;
 placeholderMargin?: string | number;
 selected2?: any;
 handleChange?: any;
 // defaultKey?: string;
 showLocationName?: boolean;
 allOption?: any;
 disabled?: boolean;
 readOnly?: boolean;
}

type BasicSelectInterface1 = BasicSelectInterface & { defaultKey?: string };

function BasicSelect({
 handleChange,
 heading,
 data,
 style,
 readOnly,
 width,
 variant,
 margin,
 className,
 padding,
 selected2,
 defaultKey,
 allOption,
 disabled,
}: BasicSelectInterface1) {
 return (
  <div className={`${className}`}>
   <FormControl
    sx={[
     {
      margin: margin || "",
      minWidth: width,
      padding: padding || "",
      // backgroundColor: colors.light_gray,
     },
     { ...style },
    ]}
    size="small"
   >
    <Select
     value={selected2}
     readOnly={readOnly}
     disabled={disabled}
     // onChange={selectionChange}
     displayEmpty
     style={{ fontFamily: "EuclidCircularB-Regular" }}
     inputProps={{ "aria-label": "Without label" }}
     MenuProps={{
      style: {
       maxHeight: "300px",
      },
     }}
    >
     <MenuItem
      style={{ fontFamily: "EuclidCircularB-Regular" }}
      value={heading}
     >
      <em>{heading}</em>
     </MenuItem>
     {allOption && defaultKey ? (
      <MenuItem
       onClick={() => handleChange(allOption)}
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       value={"All"}
      >
       {allOption[defaultKey]}
      </MenuItem>
     ) : null}

     {data?.map((data, id) => (
      <MenuItem
       onClick={() => handleChange(data, id)}
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       key={defaultKey ? data["id"] : id}
       value={defaultKey ? data[defaultKey] : data}
      >
       {defaultKey ? data[defaultKey] : data}
      </MenuItem>
     ))}
    </Select>
   </FormControl>
  </div>
 );
}

function TaskManagementSelect({
 handleChange,
 heading,
 data,
 style,
 readOnly,
 width,
 variant,
 margin,
 className,
 padding,
 selected2,
 defaultKey,
 allOption,
 disabled,
}: BasicSelectInterface1) {
 return (
  <div className={`${className}`}>
   <FormControl
    sx={[
     {
      margin: margin || "",
      minWidth: width,
      padding: padding || "",
      backgroundColor: colors.light_gray,
      // backgroundColor: "#F3F8F8",
      borderRadius: 20,
     },
     { ...style },
    ]}
    size="small"
   >
    <Select
     value={selected2}
     readOnly={readOnly}
     disabled={disabled}
     className="custom-select-taskManagement"
     sx={{
      boxShadow: "none",

      ".MuiOutlinedInput-notchedOutline": {
       border: "1px solid #D3F2F2",
       borderRadius: 10,
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
       border: "1px solid #D3F2F2",
       borderRadius: 10,
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
       border: "1px solid #D3F2F2",
       borderRadius: 10,
      },
     }}
     // onChange={selectionChange}
     displayEmpty
     style={{
      fontFamily: "EuclidCircularB-Regular",
      color: colors.heading_text,
     }}
     inputProps={{ "aria-label": "Without label" }}
     MenuProps={{
      style: {
       maxHeight: "300px",
      },
     }}
    >
     <MenuItem
      style={{ fontFamily: "EuclidCircularB-Regular" }}
      value={heading}
     >
      <em>{heading}</em>
     </MenuItem>
     {allOption && defaultKey ? (
      <MenuItem
       onClick={() => handleChange(allOption)}
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       value={"All"}
      >
       {allOption[defaultKey]}
      </MenuItem>
     ) : null}

     {data?.map((data, id) => (
      <MenuItem
       onClick={() => handleChange(data)}
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       key={defaultKey ? data["id"] : id}
       value={defaultKey ? data[defaultKey] : data}
      >
       {defaultKey ? data[defaultKey] : data}
      </MenuItem>
     ))}
    </Select>
   </FormControl>
  </div>
 );
}

function TaskManagementSelectRentals({
  handleChange,
  heading,
  data,
  style,
  readOnly,
  width,
  variant,
  margin,
  className,
  padding,
  selected2,
  defaultKey,
  allOption,
  disabled,
 }: BasicSelectInterface1) {
  return (
   <div className={`${className}`}>
    <FormControl
     sx={[
      {
       margin: margin || "",
       minWidth: width,
       padding: padding || "",
       backgroundColor: colors.light_gray,
       // backgroundColor: "#F3F8F8",
       borderRadius: 20,
      },
      { ...style },
     ]}
     size="small"
    >
     <Select
      value={selected2}
      readOnly={readOnly}
      disabled={disabled}
      className="custom-select-taskManagement"
      sx={{
       boxShadow: "none",
 
       ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D3F2F2",
        borderRadius: 10,
       },
       "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D3F2F2",
        borderRadius: 10,
       },
       "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D3F2F2",
        borderRadius: 10,
       },
      }}
      // onChange={selectionChange}
      displayEmpty
      style={{
       fontFamily: "EuclidCircularB-Regular",
       color: colors.heading_text,
      }}
      inputProps={{ "aria-label": "Without label" }}
      MenuProps={{
       style: {
        maxHeight: "300px",
       },
      }}
     >
      <MenuItem
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       value={heading}
      >
       <em>{heading}</em>
      </MenuItem>
      {allOption && defaultKey ? (
       <MenuItem
        onClick={() => handleChange(allOption)}
        style={{ fontFamily: "EuclidCircularB-Regular" }}
        value={"All"}
       >
        {allOption[defaultKey]}
       </MenuItem>
      ) : null}
 
      {data?.map((data, id) => (
       <MenuItem
        onClick={() => handleChange(data)}
        style={{ fontFamily: "EuclidCircularB-Regular" }}
        key={defaultKey ? data["name"] : name}
        value={defaultKey ? data[defaultKey] : data}
       >
        {defaultKey ? data[defaultKey] : data}
       </MenuItem>
      ))}
     </Select>
    </FormControl>
   </div>
  );
 }

function BasicSelect2({
 heading,
 data,
 style,
 width,
 margin,
 placeholderMargin,
 variant,
 placeholder,
}: BasicSelectInterface) {
 const [selected, setSelected] = React.useState("");
 const handleChange = (event: SelectChangeEvent) => {
  setSelected(event.target.value);
 };

 return (
  <Box
   sx={{
    margin: margin || "",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
   }}
  >
   <Typography
    style={{
     margin: placeholderMargin,
     fontFamily: "EuclidCircularB-Regular",
     color: "rgb(123, 130, 137)",
     fontSize: "10px",
    }}
    variant="body2"
   >
    {placeholder}
   </Typography>

   <FormControl
    sx={[
     { minWidth: width || 320, backgroundColor: colors.light_gray },
     { ...style },
    ]}
    size="small"
   >
    <Select
     // variant={variant || 'outlined'}

     disableUnderline
     value={selected}
     onChange={handleChange}
     // displayEmpty
     inputProps={{ "aria-label": "Without label" }}
    >
     <MenuItem value="">
      <em>{heading}</em>
     </MenuItem>
     {data?.map((data) => (
      <MenuItem value={data}>{data}</MenuItem>
     ))}
    </Select>
   </FormControl>
  </Box>
 );
}

function BasicSelect3({
 handleChange,
 showLocationName,
 heading,
 data,
 style,
 width,
 variant,
 margin,
 className,
 padding,
 selected2,
 defaultKey,
 placeholder,
 placeholderMargin,
 disabled,
 readOnly,
}: BasicSelectInterface1) {
 return (
  <div className={`${className}`}>
   <Typography
    style={{
     margin: placeholderMargin,
     fontFamily: "EuclidCircularB-Regular",
     color: "rgb(123, 130, 137)",
     fontSize: "10px",
    }}
    variant="body2"
   >
    {placeholder}
   </Typography>
   <FormControl
    sx={[
     {
      margin: margin || "",
      minWidth: width,
      padding: padding || "",
      backgroundColor: colors.light_gray,
     },
     { ...style },
    ]}
    size="small"
   >
    <Select
     value={selected2}
     // onChange={selectionChange}
     displayEmpty
     readOnly={readOnly}
     style={{ fontFamily: "EuclidCircularB-Regular" }}
     inputProps={{ "aria-label": "Without label" }}
     MenuProps={{
      style: {
       maxHeight: "300px",
      },
     }}
     disabled={!!disabled}
    >
     <MenuItem
      style={{ fontFamily: "EuclidCircularB-Regular" }}
      value={heading}
     >
      <em>{heading}</em>
     </MenuItem>

     {data?.map((data, id) => (
      <MenuItem
       onClick={() => handleChange(data)}
       style={{ fontFamily: "EuclidCircularB-Regular" }}
       key={defaultKey ? data["id"] : id}
       value={
        defaultKey
         ? data[defaultKey]
         : showLocationName
         ? data.location.address.address1
         : data
       }
      >
       {defaultKey
        ? data[defaultKey]
        : `${data.location.address.address1},${data.location.address.address2},${data.location.address.city.city_name},${data.location.address.zip},${data.location.address.state.state_name}`}
      </MenuItem>
     ))}
    </Select>
   </FormControl>
  </div>
 );
}

function SelectSearch({
 data,
 heading,
 margin,
 width,
 padding,
 style,
 handleChange,
 selected2,
 defaultKey,
}: any) {
 return (
  <Autocomplete
   disablePortal
   id="combo-box-demo"
   options={data}
   sx={[
    {
     margin: margin || "",
     minWidth: width,
     padding: padding || "",
     backgroundColor: colors.light_gray,
    },
    { ...style },
   ]}
   value={selected2}
   getOptionLabel={(option) => option[defaultKey]}
   size="small"
   renderInput={(params) => (
    <TextField
     {...params}
     inputProps={{
      ...params.inputProps,
      autoComplete: "new-password", // disable autocomplete and autofill
     }}
    />
   )}
   onChange={(e: any, newValue: any) => {
    handleChange(newValue);
   }}
  />
 );
}
export {
 BasicSelect,
 BasicSelect2,
 BasicSelect3,
 SelectSearch,
 TaskManagementSelect,
 TaskManagementSelectRentals
};
