import axios from "axios";
import { API_URLS, BASE_URL_UAT, customAxios } from "../config";
import CONSTANTS from '../locale/en.json';
import { IDefaultRequestHeaders } from "../interfaces/commonInterface";

const requestHeaders: IDefaultRequestHeaders | any = {
  "x-platform": CONSTANTS?.requestHeaders?.["x-platform"],
  "x-channel": CONSTANTS?.requestHeaders?.["x-channel"],
  "x-client-id": CONSTANTS?.requestHeaders?.["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent ?? "",
}

const userLogin = (payload: any) =>
  axios.post(BASE_URL_UAT + API_URLS.login, payload, { headers: { ...requestHeaders } }).catch((ex) => ex);

const userSignUp = (payload: any) =>
  customAxios.post(API_URLS.signup, payload, { headers: { ...requestHeaders } }).catch((ex) => ex);

const userLogout = () =>
  customAxios.post(API_URLS.userLogout, {}, { headers: { ...requestHeaders } }).catch((ex) => ex);

export default {
  userLogin,
  userSignUp,
  userLogout,
};
