function ContainerMsg(props : any): any {
  return (
    <>
      <p className="fpw-text">
        {props?.message}
      </p>
    </>
  );
}

export {ContainerMsg};
