export enum Global {
  versioning1 = "/v1",
  globalSearch = "search",
  master = "master/",
  versioningV1 = "v1/",
  documents = "/documents",
  attachment = "attachment",
}
export enum BookingX {
  startRide = "/start-ride",
  booking = "booking",
  vehicles = "/vehicles",
  userPaymentMandateX = "freedo-munim/mandate/v1",
  cancelBookingX = "/cancel",
  closeBookingX = "/close",
  bookingList = "/list",
  statistics = "/statistics",
  bookingStatus = "/status",
}

export enum FreedoX {
  addUserX = "customer/sendOtpForSignUp",
  plans = "plan/",
  getMatchingRentalPlans = "all?",
  getAllCitiesX = "city/v1",
  getBranchX = "branch/v1",
  getModel = "plan/v1/models",
  createManualBookingX = "booking/v1",
  getBookingListX = "booking/v1/list",
  calculateChargesX = "booking/v1/calculate/charges",
  getPlansX = "plan/v1/availables",
  paymentPendingX = "payment/pending",
}

export enum YORBookingDetails {
  YORbookingDetails = "details",
  YORPaymentHistory = "payments",
  YORPaymentModes = "branch-config/payment/modes",
  YORBranchNameParam = "?branchName=",
  YORCreateOrderEndpoint = "payment",
  YORPaymentLinkEndpoint = "payment",
  YORWaivedOffCharges = "/charges",
  YORAddAdditionalCharge = "additional-charge"
}

export enum YORMasterEndpoints {
  YORChargeCategories = "charges",
}