import { Box, Tooltip, Typography } from "@mui/material";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import {
  CityInputIcon,
  stateIcon,
  WarehouseIcon,
} from "../../../constants/exportImages";
import en from "../../../locale/rental-en.json";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../../utils/RoutesConstants";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHelmetConfigList } from "../../../redux/actions/businessManagementActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import {
  getAllBranchesByCity,
  getAllStates,
  getCityByState,
} from "../../../redux/actions/rentalCommonAction";
import { rentalActions } from "../../../redux/actions";
import { colors } from "../../../../themes/colors";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constants/enum";
import { RotateRightSharp } from "@mui/icons-material";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  helmetConfigurationFunctionality,
  modules,
} from "../../../../constants/RBACModuleEnums";

const HelmetConfiguration = () => {
  const RbacHasAccess = {
    listHelmetConfiguration: RbacHelper.isAccessRightsProvided(
      modules.HELMET_CONFIGURATION,
      helmetConfigurationFunctionality.LIST_HELMET_CONFIGURATION
    ),
    addHelmetConfiguration: RbacHelper.isAccessRightsProvided(
      modules.HELMET_CONFIGURATION,
      helmetConfigurationFunctionality.ADD_HELMET_CONFIGURATION
    ),
    viewHelmetConfiguration: RbacHelper.isAccessRightsProvided(
      modules.HELMET_CONFIGURATION,
      helmetConfigurationFunctionality.VIEW_HELMET_CONFIGURATION
    ),
  };
  const columns: any = [
    {
      field: "stateDisplayName",
      headerName: en.global.state,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
    },
    {
      field: "cityDisplayName",
      headerName: en.global.city,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
    },
    {
      field: "branchDisplayName",
      headerName: en.global.hub,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
    },
    {
      field: "premiumHelmet",
      headerName: en.global.premiumHelmet,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <>
            {data?.row?.isPricingSet?.PREMIUM ? (
              <span style={{ color: colors.secondary_green }}>Done</span>
            ) : (
              <span style={{ color: colors.primary_warning }}>Not set</span>
            )}
          </>
        );
      },
    },
    {
      field: "fullFaceHelmet",
      headerName: en.global.fullFaceHelmet,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <>
            {data?.row?.isPricingSet?.FULL_FACE ? (
              <span style={{ color: colors.secondary_green }}>Done</span>
            ) : (
              <span style={{ color: colors.primary_warning }}>Not set</span>
            )}
          </>
        );
      },
    },
    {
      field: "halfFaceHelmet",
      headerName: en.global.halfFaceHelmet,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <>
            {data?.row?.isPricingSet?.HALF_FACE ? (
              <span style={{ color: colors.secondary_green }}>Done</span>
            ) : (
              <span style={{ color: colors.primary_warning }}>Not set</span>
            )}
          </>
        );
      },
    },
    {
      field: "kidsHelmet",
      headerName: en.global.kidsHelmet,
      headerAlign: "center",
      align: "center",
      headerClassName: "hideRightSeparator",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <>
            {data?.row?.isPricingSet?.KIDS ? (
              <span style={{ color: colors.secondary_green }}>Done</span>
            ) : (
              <span style={{ color: colors.primary_warning }}>Not set</span>
            )}
          </>
        );
      },
    },
  ];

  const [filters, setFilters] = useState({
    paginationModel: { page: 0, pageSize: 10 },
    states: {
      name: "",
      displayName: en.global.allState,
    },
    city: {
      name: "",
      displayName: en.global.allCities,
    },
    hub: {
      name: "",
      displayName: en.global.allHubs,
    },
  });
  const { helmetConfigList, helmetTableLoader } = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );
  const { allStatesList, stateBasedCities, branches } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { shouldScreenRefresh } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //set helmetConfig Filter data from local storage
  useEffect(() => {
    let data: any = {
      helmetConfigurationFilters: {
        ...filters,
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);
    getFilterDataFromLocalStorage("helmetConfigurationFilters").then(
      (data: any) => {
        if (data) {
          let localdata = { ...data };
          localdata.paginationModel = {
            page: 0,
            pageSize: 10,
          };
          setFilters((prev: any) => ({
            ...prev,
            ...localdata,
          }));
          if (data?.states?.name) {
            dispatch(getCityByState(data.states.name));
          }
          if (data?.city?.name) {
            dispatch(rentalActions.getAllBranches({ name: data?.city?.name }));
          }
        }
      }
    );
    dispatch(getAllStates());
  }, [shouldScreenRefresh]);

  //get filtered Helmet Config List
  useEffect(() => {
    let data: any = {
      helmetConfigurationFilters: {
        ...filters,
      },
    };
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);
    {
      RbacHasAccess.listHelmetConfiguration &&
        dispatch(
          getHelmetConfigList({
            page: filters.paginationModel.page + 1,
            limit: filters.paginationModel.pageSize,
            city: filters.city.name,
            branch: filters.hub.name,
          })
        );
    }
  }, [filters.hub, filters.city, filters.paginationModel]);

  //navigate on add helmet
  const handleHelmetPriceClick = () => {
    navigate(routesConstants.ADD_HELMET_CONFIGURATION);
  };

  //handle pagination
  const handlePaginationModelChange = (paginationModel: any) => {
    const { page, pageSize } = paginationModel;

    setFilters((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));
  };

  //set Filters
  const handleFilterChange = (fields: string, value: any) => {
    setFilters((prevFields: any) => ({
      ...prevFields,
      [fields]: value,
    }));
    if (fields === "states") {
      dispatch(getCityByState(value?.name));
      if (filters?.city?.name) {
        setFilters((prev: any) => ({
          ...prev,
          city: {
            name: "",
            displayName: en.global.allCities,
          },
        }));
      }
      if (filters?.hub?.name) {
        setFilters((prev: any) => ({
          ...prev,
          hub: {
            name: "",
            displayName: en.global.allHubs,
          },
        }));
      }
    }
    if (fields === "city") {
      dispatch(rentalActions.getAllBranches({ name: value.name }));
      if (filters?.hub?.name) {
        setFilters((prev: any) => ({
          ...prev,
          hub: {
            name: "",
            displayName: en.global.allHubs,
          },
        }));
      }
    }
  };

  const handleRowClick = (params: any, event: any) => {
    {
      RbacHasAccess?.viewHelmetConfiguration &&
        navigate(`${routesConstants.EDIT_HELMET_CONFIGURATION}${params?.id}`);
    }
  };

  const handleFilterReset = () => {
    setFilters((prev: any) => ({
      paginationModel: { page: 0, pageSize: 10 },
      states: {
        name: "",
        displayName: en.global.allState,
      },
      city: {
        name: "",
        displayName: en.global.allCities,
      },
      hub: {
        name: "",
        displayName: en.global.allHubs,
      },
    }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          {RbacHasAccess.addHelmetConfiguration && (
            <CustomButton
              variant="outlined"
              label={en.NewBusinessManagement.newHelmetPrice}
              onClick={() => {
                handleHelmetPriceClick();
              }}
              style={{ height: "40px", borderRadius: "10px" }}
            />
          )}
        </Box>
        <Box>
          {RbacHasAccess.listHelmetConfiguration && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <CustomSelect
                  icon={stateIcon}
                  width="160px"
                  placeholder={en.NewBusinessManagement.selectState}
                  value={filters.states.displayName}
                  choice={[
                    {
                      name: "",
                      displayName: en.global.allState,
                    },
                    ...allStatesList,
                  ]}
                  defaultKey={"displayName"}
                  required
                  handleChange={(state: any) => {
                    handleFilterChange("states", state);
                  }}
                />
              </Box>
              <Box>
                <CustomSelect
                  icon={CityInputIcon}
                  width="160px"
                  placeholder={en.global.selectCity}
                  value={
                    filters.city.displayName == ""
                      ? en.global.allCity
                      : filters.city.displayName
                  }
                  choice={[
                    {
                      name: "",
                      displayName: en.global.allCities,
                    },
                    ...stateBasedCities,
                  ]}
                  defaultKey={"displayName"}
                  required
                  handleChange={(city: any) => {
                    handleFilterChange("city", city);
                  }}
                  disabled={!stateBasedCities?.length || !filters.states.name}
                />
              </Box>
              <Box>
                <CustomSelect
                  icon={WarehouseIcon}
                  width="160px"
                  placeholder={en.global.selectHub}
                  value={
                    filters.hub.displayName == ""
                      ? en.global.allHubs
                      : filters.hub.displayName
                  }
                  choice={[
                    {
                      name: "",
                      displayName: en.global.allHubs,
                    },
                    ...branches,
                  ]}
                  defaultKey={"displayName"}
                  required
                  handleChange={(hub: any) => {
                    handleFilterChange("hub", hub);
                  }}
                  disabled={!branches?.length || !filters?.city?.name}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={en.global.resetFilters} arrow>
                  <RotateRightSharp
                    fontSize="large"
                    color="primary"
                    sx={{ cursor: "pointer", fontSize: "25px" }}
                    onClick={() => {
                      if (filters?.states?.name) {
                        handleFilterReset();
                      }
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <DataTable
          columns={columns}
          rows={helmetConfigList?.helmetConfigData}
          disableColumnMenu
          disableColumnSorting
          getRowId={(row: any) => row.id}
          rowHeight={75}
          paginationMode="server"
          paginationModel={filters.paginationModel}
          onPaginationModelChange={(val: any) => {
            handlePaginationModelChange(val);
          }}
          rowCount={helmetConfigList?.pagination?.total}
          disableColumnResize={true}
          disableColumnSelector={true}
          loading={helmetTableLoader}
          onRowClick={handleRowClick}
          hasAccess={RbacHasAccess.listHelmetConfiguration}
        />
      </Box>
    </Box>
  );
};
export default HelmetConfiguration;
