import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { colors } from "../../../../../themes/colors";
import { KycVerificationModeDisplayName, typographyConstants } from "../../../../constants/constants";
import styles from "./section.module.css";

export const AddressModal = ({
  customerId,
  addressTrustScore,
  namePayloadObj,
}: any) => {
  const { uploadLoader, signedUrlFailedFiles, linearProgressValue } =
    useSelector((state: RootState) => state.customerManagementReducer);

  const isPresent = addressTrustScore?.isPresent;
  const isVerified = addressTrustScore?.isVerified;
  const verificationStatus = isVerified ? "Verified" : "Not Verified";
  const verificationMode = addressTrustScore?.verificationMode;
  const addressDetails = addressTrustScore?.details;
  const aadharAddress  = addressDetails?.maskedAadhaarAddress?.address ? addressDetails?.maskedAadhaarAddress?.address : "N/A";
  const dlAddress = addressDetails?.maskedDlAddress?.address ? addressDetails?.maskedDlAddress?.address : "N/A";

  return ( 
    <Stack className={styles.KYCModal_Wrapper}>
      <Stack gap="20px">
        {/* Address From Aadhar */}
        <Stack
          sx={{
            backgroundColor: colors.bg_light_gray,
            borderRadius: "10px",
            border: "1px solid #E2E2E2",
          }}
        >
          <Box
            className={styles.selfie_View_Box}
            sx={{
              backgroundColor: "#053C3D",
              color: "#fff",
              borderRadius: "16px 0px",
              padding: "2px 20px",
            }}
          >
            <Typography
              variant={typographyConstants?.SUBHEADING}
              color={"#fff"}
            >
              Address from Aadhar
            </Typography>{" "}
          </Box>

          <Stack sx={{ padding: "10px" }}>
            <Box className={styles.selfie_status_Box}>
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={colors.tertiary_black}
              >
                {aadharAddress}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Address From DL */}
        <Stack
          sx={{
            backgroundColor: colors.bg_light_gray,
            borderRadius: "10px",
            border: "1px solid #E2E2E2",
          }}
        >
          <Box
            className={styles.selfie_View_Box}
            sx={{
              backgroundColor: "#053C3D",
              color: "#fff",
              borderRadius: "16px 0px",
              padding: "2px 20px",
            }}
          >
            <Typography
              variant={typographyConstants?.SUBHEADING}
              color={"#fff"}
            >
              Address from DL
            </Typography>{" "}
          </Box>

          <Stack sx={{ padding: "10px" }}>
            <Box className={styles.selfie_status_Box}>
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={colors.tertiary_black}
              >
                {dlAddress ?? "N/A"}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Verification Block */}
        <Stack gap="20px">
          <Stack
            sx={{
              backgroundColor: colors.bg_light_gray,
              borderRadius: "10px",
            }}
          >
            <Box className={styles.selfie_View_Box}>
              {" "}
              <Typography
                variant={typographyConstants?.SUBHEADING}
                color={colors.text_black}
              >
                Verification Details
              </Typography>{" "}
            </Box>
            {/* Mode */}
            <Stack>
              <Box className={styles.selfie_status_Box}>
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.tertiary_black}
                >
                  Mode
                </Typography>
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.text_black}
                >
                  {KycVerificationModeDisplayName[verificationMode as keyof typeof KycVerificationModeDisplayName]}
                </Typography>
              </Box>
            </Stack>
            {/* Status */}
            <Stack>
              <Box className={styles.selfie_status_Box}>
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.tertiary_black}
                >
                  Status
                </Typography>
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.text_black}
                >
                  {verificationStatus}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
