import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadCumbs";
import { Button2 } from "../../components/button";
import { BasicDatePicker } from "../../components/datePicker";
import { Input2, Input4 } from "../../components/input";
import { Heading } from "../../components/typography";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";

interface DefaultProps {
  addRole?: boolean;
  editRole?: boolean;
}

function AddEditAdvertisement(props: DefaultProps) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const [date, setDate] = useState<any>(new Date());
  const {
    categoryById,
    addsuccess,
    editsuccess,
    advertisementById,
    advertisementByIdLoading,
  } = useSelector((state: RootState) => state.masterReducer);
  const { statusList } = useSelector((state: RootState) => state.commonReducer);
  const { categoryManagement, global, cityManagement } = locale;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [category, setCategory] = useState("");
  const advertisementParams = useParams();
  const [selectedStatus, setSelectedStatus] = useState({
    status_name: global.SelectStatus,
    id: 0,
  });

  useEffect(() => {
    if (props?.editRole) {
      dispatch(
        actions.getAdvertisementById({
          adv_id: advertisementParams?.id,
        })
      );
    }
    dispatch(
      actions.getStatus({
        type: "Status",
        id: 0,
      })
    );
    dispatch(
      actions.editCityToggle({
        addsuccess: false,
        editsuccess: false,
      })
    );
  }, []);

  useEffect(() => {
    if (advertisementById && props.editRole) {
      setCategory(advertisementById?.adv_txt);
      setSelectedStatus({
        ...selectedStatus,
        status_name: advertisementById?.status === 1 ? "Active" : "Inactive",
        id: advertisementById?.status,
      });
      setDate(moment(advertisementById?.release_day));
    }
  }, [advertisementById]);

  const handleCategory = (e: any) => {
    setCategory(e.target.value);
  };

  const navigateBack = () => {
    navigate("/master-management/advertisements");
  };

  const handleStatus = (status: any) => {
    setSelectedStatus(status);
  };
  const validate = () => {
    onSubmit();
  };

  const onSubmit = () => {
    if (props.addRole) {
      dispatch(
        actions.addAdvertisement({
          release_day: moment(date).format("DD-MM-YYYY"),
          status: selectedStatus?.id,
          adv_txt: category,
        })
      );
    } else if (props.editRole) {
      dispatch(
        actions.editAdvertisement({
          adv_id: advertisementParams?.id,
          release_day: moment(date).format("DD-MM-YYYY"),
          status: selectedStatus?.id,
          adv_txt: category,
        })
      );
    }
  };

  if (addsuccess || editsuccess) navigate("/master-management/advertisements");

  return (
    <div className="container pd-20">
      <Heading title={global.Advertisement} />
      <BreadCrumbs
        path="/master-management/advertisements"
        firstLabel={global.Advertisement}
        secondLabel={
          props.addRole ? global.AddAdvertisement : global.EditAdvertisement
        }
      />
      <form>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2%",
            bgcolor: "background.paper",
            borderRadius: 1,
            // justifyContent: "space-between",
          }}
        >
          <Input4
            id="category_name"
            value={category}
            borderRadius="5px"
            required={true}
            defaultValue={category}
            placeholder={global.Advertisement}
            onChange={(e: any) => handleCategory(e)}
          />

          <Input2
            select
            // value={packageManagement.SelectType}
            placeholder={global.Status}
            choice={statusList}
            required={true}
            name="status"
            defaultKey="status_name"
            heading={global.SelectStatus}
            handleChange={(status: any) => handleStatus(status)}
            // onChange={handleChange}
            value={selectedStatus["status_name"]}

            // onChange={(e)=>{}}
          />
          <Box display="flex">
            {/* <DatePicker2
              value={date}
              width={300}
              required
              placeholder="Date"
              inputFormat={"dd-MM-yyyy"}
              minDate={new Date()}
              // onChange={(val: any) => setDate(val)}
              setDateValueFunc={(val: any) => setDate(val)}
              margin="5px"
            /> */}
            <BasicDatePicker
              dateValue={date}
              width={300}
              placeholder="Date"
              setDateValueFunc={(val: any) => setDate(val)}
              small
              className="mr-10"
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
          <Button2
            title={global.Back}
            back={true}
            onClick={navigateBack}
            style={{ marginLeft: "15px" }}
          />
          <Button2
            disabled={
              category === "" ||
              category === undefined ||
              selectedStatus?.status_name === global.SelectStatus
            }
            onClick={validate}
            title={global.SaveAdvertisement}
            style={{ marginLeft: "15px" }}
          />
        </Box>
      </form>
    </div>
  );
}
export default AddEditAdvertisement;
