import { Stack, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import {
  MobileIcon,
  attachmentIcon,
  userIcon,
} from "../../../constants/exportImages";
import en from "../../../../locale/en.json";
import rentalEn from "../../../locale/rental-en.json";
import { checkPhone } from "../../../../utils/regex";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import {
  EmergencyContactRelationShip,
  DisplayEmergencyContactRelationShip,
  EmergencyContactStatus,
  typographyConstants,
} from "../../../constants/constants";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../themes/colors";

interface AddEmergencyModalUiInterface {
  customerEmergencyContacts: any;
  emergencyUserFields: any;
  setEmergencyUserFields: any;
  errorEmergencyFields: any;
  setErrorEmergencyFields: any;
  handleEmergencyUserFieldChange: any;
  handleSaveAndVerifyEmergencyContactClick: any;
}
const AddEmergencyModalUi = ({
  customerId,
  customerEmergencyContacts,
  emergencyUserFields,
  setEmergencyUserFields,
  errorEmergencyFields,
  setErrorEmergencyFields,
  handleEmergencyUserFieldChange,
  handleSaveAndVerifyEmergencyContactClick,
  emergencyContactLoader,
}: any) => {
  const emergencyContactId = customerEmergencyContacts[0]?.id ?? "";
  // Relation Type Array
  const relationTypeArr = [
    {
      name: EmergencyContactRelationShip.FAMILY,
      displayName: DisplayEmergencyContactRelationShip.FAMILY,
    },
    {
      name: EmergencyContactRelationShip.FRIEND,
      displayName: DisplayEmergencyContactRelationShip.FRIEND,
    },
    {
      name: EmergencyContactRelationShip.COLLEAGUE,
      displayName: DisplayEmergencyContactRelationShip.COLLEAGUE,
    },
  ];

  const enum addEmergencyActionsType {
    SAVE = "SAVE",
    UPDATE = "UPDATE",
  }

  return (
    <Stack
      sx={{ padding: "20px 10px 5px" }}
      justifyContent="space-between"
      height="100%"
    >
      <Stack className="FieldsWrapper" gap="4vh">
        {/* Contact No */}
        <Input
          iconStart={MobileIcon}
          value={emergencyUserFields.mobileNumber}
          placeholder={en.CustomerManagement.EmergencyContactNo}
          label={en.CustomerManagement.EmergencyContactNo}
          errormessage={
            errorEmergencyFields.mobileNumber &&
            errorEmergencyFields.mobileNumber
          }
          width="100%"
          onChange={(e: any) =>
            handleEmergencyUserFieldChange("mobileNumber", e.target.value)
          }
        />

        {/* User Name */}
        <Input
          iconStart={userIcon}
          value={emergencyUserFields.name}
          placeholder={en.CustomerManagement.EmergencyUsername}
          label={en.CustomerManagement.EmergencyUsername}
          errormessage={
            errorEmergencyFields.name && en.errorMessages.requiredField
          }
          width="100%"
          onChange={(e: any) =>
            handleEmergencyUserFieldChange("name", e.target.value)
          }
        />

        {/* Relation */}
        <CustomSelect
          required
          icon={attachmentIcon}
          placeholder={en.CustomerManagement.EmergencyRelation}
          value={emergencyUserFields.relationship.displayName}
          choice={relationTypeArr}
          defaultKey={"displayName"}
          errormessage={errorEmergencyFields.relationship}
          handleChange={(e: any) =>
            handleEmergencyUserFieldChange("relationship", e)
          }
        />
      </Stack>

      <Stack
        className="ActionWrapper"
        justifyContent="space-between"
        alignItems="center"
        gap="3vh"
      >
        {customerEmergencyContacts.length > 0 && customerEmergencyContacts[0]?.status === EmergencyContactStatus.UNVERIFIED && (
          <Typography color={colors.primary_warning} variant={typographyConstants.ERRORMESSAGE}>{rentalEn.CustomerManagement.EmergencyUnverifiedAlert}</Typography>
        )}
        <Stack
          justifyContent="center"
          alignItems="space-between"
          direction="row"
          width="100%"
        >
          <CustomButton
            variant="contained"
            label="Save & Verify"
            onClick={() =>
              handleSaveAndVerifyEmergencyContactClick(emergencyContactId)
            }
            disabled={emergencyContactLoader}
            loading={emergencyContactLoader}
            wrapperStyles={{ width: "100%" }}
            sx={{ width: "100%" }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddEmergencyModalUi;
