import { Button, CircularProgress, useMediaQuery, Box } from "@mui/material";
import { Button1Props } from "../interfaces/componentsProps";
import { colors } from "../themes/colors";
import "./components.css";
function Button1(props: Button1Props) {
  return (
    <Button
      sx={{
        marginTop: "30px",
        borderRadius: props.borderRadius ? "10%" : null,
      }}
      fullWidth
      variant="contained"
      {...props}
    >
      {props.loading ? (
        <>
          <CircularProgress color="inherit" size={18} style={{ marginRight: 20 }} />
          {props.title}
        </>
      ) : (
        props.title
      )}
    </Button>
  );
}
function Button2(props: Button1Props) {
  const matches = useMediaQuery("(min-width:767px)");
  return (
    <Box className={`${props.className} ${props.buttonContainerClass}`}>
      <Button
        style={props.style}
        size="small"
        variant="contained"
        onClick={() => props.onClick}
        sx={{
          borderRadius: props.borderRadius || "10px",
          textTransform: "none",
          height: matches ? "35px" : "32px",
          backgroundColor: props.back ? colors.light_black : colors.THEME_BLUE,
          "&.Mui-disabled": {
            backgroundColor: props.loading
              ? props.back
                ? colors.light_black
                : colors.THEME_BLUE
              : colors.gray,
            color: colors.white,
          },
        }}
        {...props}
        disabled={props.disabled || props.loading}
      >
        {props.loading ? (
          <>
            <CircularProgress
              color={props.loaderColor || "inherit"}
              size={18}
              style={{ marginRight: 20 }}
            />
            {props.title}
          </>
        ) : (
          props.title
        )}
      </Button>
    </Box>
  );
}

function Button3(props: Button1Props) {
  const matches = useMediaQuery("(min-width:767px)");

  return (
    <Button
      size="small"
      variant="contained"
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        borderRadius: props.borderRadius || "10px",
        textTransform: "none",
        height: matches ? "40px" : "35px",
        backgroundColor: props.colorb,
      }}
      {...props}
    >
      {props.title}
    </Button>
  );
}

function Button4(props: Button1Props) {
  const matches = useMediaQuery("(min-width:767px)");

  return (
    <Button
      size="small"
      variant="contained"
      disabled={props.disabled}
      onClick={() => props.statechanger(props.value)}
      sx={{
        minWidth: matches ? props.minwidth || "" : "25px",
        padding: "20px",
        borderRadius: "10px",
        textTransform: "none",
        height: matches ? "75px" : "50px",
        backgroundColor: props.state === props.value ? props.colorb : "transparent",
        color: props.state === props.value ? "white" : "black",
        boxShadow:
          props.boxshadow ||
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      }}
      {...props}
    >
      {props.title}
    </Button>
  );
}

function Button5(props: Button1Props) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  return (
    <Button
      size="small"
      variant="contained"
      onClick={props.onClick}
      {...props}
      sx={{
        borderRadius: "10px",
        textTransform: "none",
        height: isDesktopOrTablet ? "35px" : "32px",
        backgroundColor: props.back ? colors.black : colors.THEME_BLUE,
        ...props.sx,
      }}
    >
      {props.children}
    </Button>
  );
}

function Button6(props: Button1Props) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  // alert(props.title);
  return (
    <div className={`${props.className} ${props.buttonContainerClass}`}>
      <Button
        size="large"
        onClick={props.onClick}
        {...props}
        sx={{
          borderRadius: "10px",
          padding: "8px",
          margin: "10px",
          width: isDesktopOrTablet ? "164px" : "125px",
          border:
            props.title === "Verify"
              ? "1px solid rgb(43, 199, 201)"
              : "1px solid rgb(219, 6, 6)",
          color: props.title === "Verify" ? colors.LIGHT_BLUE : "rgb(219, 6, 6)",
          textTransform: "none",
          height: isDesktopOrTablet ? "35px" : "32px",
        }}
      >
        {props.title}
      </Button>
    </div>
  );
}
function Button7(props: any) {
  return (
    <div className="text-center">
      <div className="mt-3 d-flex justify-content-center">
        <button disabled={props.disabled}   style={props.style}    data-bs-dismiss={props?.dataDismiss} aria-label = {props.arialabel} className={`${props?.className ?? 'btn btn-admin'} ${props.disabled ? 'disable-btn' : ''}`} onClick={() => !props.loading && props.onClick()}>{props.title}
          {props.loading ? 
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            style={{marginLeft:10}}
          ></span> : null}
        </button>
      </div>
    </div>
  )
}

function Button8(props: any) {
  return (<>
    <button className={props?.className ?? "btn rounded-pill btn-secondary btn-sm"}
      data-bs-target={`#proceed` + props.id}
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      id={props.id}
    >{props.title}</button>
    <div className="modal fade" id={`proceed` + props.id} aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center">
            Are you sure you want to proceed?
          </div>
          <div className="d-flex justify-content-center p-2">
            <div className="btn btn-secondary mx-2" data-bs-dismiss="modal"> No</div>
            <div className="btn btn-admin" data-bs-dismiss="modal" onClick={props.onClick}> Yes</div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}




export { Button1, Button2, Button3, Button4, Button5, Button6, Button7, Button8 };
