import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import bookingManagement from "../services/bookingManagement";
import { toastr } from "react-redux-toastr";
import { handleMessage, isArrayNotEmpty } from "../../../utils/helper";
import rentalEn from "../../locale/rental-en.json";
import customerManagement from "../services/customerManagement";
import { actionInterface } from "../../interfaces/commonInterface";
import {
  BookingImagesTypes,
  GenericObject,
  OrderPaymentType,
  paymentStatusDurationPayload,
  planTypeKeys,
} from "../../constants/constants";
import { LOB_TYPES } from "../../constants/constants";
import { getStore } from "../../../redux/store";
import {
  calculatePlanAndSlotCharges,
  calculateUpgradeModelCharges as calculateUpgradeModelChargesAction,
  calculateAddressAddOnCharges as calculateAddressAddOnChargesAction,
} from "../actions/bookingManagementActions";

/**
 * Handles failure scenario by dispatching a failure action and displaying an info toastr.
 * @param {any} failMsg - Optional failure message.
 * @param {GenericObject} failPayload - Optional payload for failure action.
 */
function* failSaga(failMsg?: any, failPayload?: GenericObject) {
  yield put({
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.BOOKING_MANAGEMENT_FAILED,
    payload: failPayload || {},
  });
}

/**
 * Handles error scenario by dispatching an error action and displaying an error toastr.
 * @param {any} errorMsg - Optional error message.
 * @param {GenericObject} errorPayload - Optional payload for error action.
 */
function* errorSaga(errorMsg?: any, errorPayload?: GenericObject) {
  yield put({
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.BOOKING_MANAGEMENT_ERROR,
    payload: errorPayload || {},
  });
  toastr.error(
    rentalEn?.toastTypes?.error,
    handleMessage(errorMsg) || rentalEn?.errorMessages?.unknownError
  );
}

// get booking details
function* getBookingDetails(action: any): any {
  const {
    getConfig = false,
    onSuccess,
    fetchVehicleImages = false,
    requiredImages = [],
  } = action.payload;
  try {
    const data = yield call(
      bookingManagement.getBookingDetails,
      action.payload
    );
    const bookingDetails = data?.data?.data?.bookingDetails;
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .RENTALS_GET_BOOKING_DETAILS_SUCCESS,
        payload: data?.data.data,
      });

      // get operator configuration by branch

      if (getConfig && bookingDetails?.branchDetails?.branchName) {
        yield put({
          type: actionTypes.COMMON_ACTIONS
            .GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED,
          payload: {
            branch: bookingDetails?.branchDetails?.branchName,
            data: {
              lob: LOB_TYPES.LTR,
            },
          },
        });
      }

      // Fetch Booking Images operations below
      if (fetchVehicleImages) {
        // Start ride images
        if (
          requiredImages.includes(BookingImagesTypes.START_RIDE) &&
          bookingDetails?.bookingVehicleDetails?.startRideImageRefId
        ) {
          let getVehiclePayload = {
            imageRefId:
              bookingDetails?.bookingVehicleDetails?.startRideImageRefId,
            type: "startRideImageRefId",
          };
          yield put({
            type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
              .GET_VEHICLE_IMAGES_REQUESTED,
            payload: getVehiclePayload,
          });
        }

        // End ride images
        if (
          requiredImages.includes(BookingImagesTypes.END_RIDE) &&
          bookingDetails?.bookingVehicleDetails?.endRideImageRefId
        ) {
          let getVehiclePayload = {
            imageRefId:
              bookingDetails?.bookingVehicleDetails?.endRideImageRefId,
            type: "endRideImageRefId",
          };
          yield put({
            type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
              .GET_VEHICLE_END_RIDE_IMAGES_REQUESTED,
            payload: getVehiclePayload,
          });
        }

        // Start Odometer Reading images
        if (
          requiredImages.includes(BookingImagesTypes.START_RIDE_ODOMETER) &&
          bookingDetails?.bookingVehicleDetails?.startOdometerReadingRefId
        ) {
          let getVehiclePayload = {
            imageRefId:
              bookingDetails?.bookingVehicleDetails?.startOdometerReadingRefId,
            type: "startOdometerReadingRefId",
          };
          yield put({
            type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
              .GET_VEHICLE_START_ODOMETER_READING_REQUESTED,
            payload: getVehiclePayload,
          });
        }

        // End Odometer Reading images
        if (
          requiredImages.includes(BookingImagesTypes.END_RIDE_ODOMETER) &&
          bookingDetails?.bookingVehicleDetails?.endOdometerReadingRefId
        ) {
          let getVehiclePayload = {
            imageRefId:
              bookingDetails?.bookingVehicleDetails?.endOdometerReadingRefId,
            type: "endOdometerReadingRefId",
          };
          yield put({
            type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
              .GET_VEHICLE_END_ODOMETER_READING_REQUESTED,
            payload: getVehiclePayload,
          });
        }
      }

      onSuccess && onSuccess(bookingDetails);
      // toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .RENTALS_GET_BOOKING_DETAILS_FAILED,
        payload: data.error,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .RENTALS_GET_BOOKING_DETAILS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}
//get booking list
function* getBookingList(action: any): any {
  try {
    const { downloadBookingListHandler, ...restPayload } = action.payload;
    const data = yield call(bookingManagement.getBookingList, restPayload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_SUCCESS,
        payload: {
          data: data?.data.data,
          downloadedData: downloadBookingListHandler ? true : false,
        },
      });
      downloadBookingListHandler &&
        downloadBookingListHandler(
          isArrayNotEmpty(data?.data?.data?.bookings)
            ? data?.data?.data?.bookings
            : []
        );
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}
//get odometer history
function* getOdometerHistory(action: any): any {
  try {
    const data = yield call(
      bookingManagement.getOdometerHistory,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_VEHICLE_ODOMETER_HISTORY_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_VEHICLE_ODOMETER_HISTORY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_VEHICLE_ODOMETER_HISTORY_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//get signed urls for booking documents
function* getDocsSignedUrls(action: any): any {
  try {
    const urls = yield call(
      bookingManagement.getDocsSignedUrls,
      action.payload
    );
    const urlsData: any = {
      filesData: action.payload.filesData,
      isUploadDocumentBreak: action?.payload?.isUploadDocumentBreak,
      sucessCallback: action?.payload?.sucessCallback,
    };

    if (urls.status === 200) {
      urlsData["urls"] = urls.data.data.files;
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_DOC_SIGNED_URLS_SUCCESS,
      });
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_REQUESTED,
        payload: urlsData,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_DOC_SIGNED_URLS_FAILED,
      });
      toastr.error("", handleMessage(urls));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_BOOKING_DOC_SIGNED_URLS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//put attachment on signed urls
function* putAttachmentOnSignedUrls(action: any): any {
  try {
    const {
      urls,
      filesData,
      isUploadDocumentBreak = false,
      sucessCallback = () => {},
    } = action.payload;
    let attachments: any = [];
    let payload: any = {
      id: filesData.bookingId,
      data: {
        data: {
          attachmentIds: attachments,
        },
      },
    };
    const tasks = urls?.map((url: any, index: number) => {
      attachments.push(url.id);
      let binaryFile = new Blob([filesData.files[index]], {
        type: filesData.files[index].type,
      });
      return call(fetch, url?.signedUrl, { method: "PUT", body: binaryFile });
    });

    const results = yield all(tasks);

    if (results?.length) {
      //upload document break
      if (isUploadDocumentBreak) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_SUCCESS,
          payload: attachments ?? [],
        });
        sucessCallback(filesData);
        return;
      }

      const data = yield call(
        bookingManagement.uploadBookingDocuments,
        payload
      );
      if (data.status === 201) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .BOOKING_DOCUMENT_UPLOAD_SUCCESS,
        });
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .GET_BOOKING_DOCUMENTS_REQUESTED,
          payload: payload.id,
        });
      } else {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .BOOKING_DOCUMENT_UPLOAD_FAILED,
        });
        toastr.error("", handleMessage(data));
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .BOOKING_DOCUMENT_UPLOAD_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

//get booking documents
function* getBookingDocuments(action: any): any {
  try {
    const data = yield call(
      bookingManagement.getBookingDocuments,
      action.payload
    );
    if (data.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_DOCUMENTS_SUCCESS,
        payload: data?.data?.data?.documents,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_DOCUMENTS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//delete booking documents
function* deleteBookingDocuments(action: any): any {
  try {
    const data = yield call(
      bookingManagement.deleteBookingDocument,
      action.payload
    );
    if (data.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_DOCUMENTS_REQUESTED,
        payload: action.payload.bookingId,
      });
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_DOCUMENTS_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_DOCUMENTS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .DELETE_BOOKING_DOCUMENTS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//cancel booking
function* cancelBooking(action: any): any {
  try {
    const data = yield call(bookingManagement.cancelBooking, action.payload);
    if (data.status === 201 || data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
        payload: { bookingId: action.payload.bookingId },
      });
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_SUCCESS,
        payload: data,
      });
      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// get today newbookings stat
function* getBookingStatistics(action: any): any {
  try {
    const data = yield call(bookingManagement.getBookingStats, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// get additional charges - challans & Extras
function* getAdditionalCharges(action: any): any {
  try {
    const data = yield call(
      bookingManagement.getAdditionalChargesService,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_ADDITIONAL_CHARGES_SUCCESS,
        payload: data?.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_ADDITIONAL_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_ADDITIONAL_CHARGES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//get signed urls for vehicle images
function* getVehicleSignedUrls(action: any): any {
  try {
    const { sucessCallback, uploadOnly = false } = action.payload;
    const urls = yield call(
      bookingManagement.getVehicleSignedUrls,
      action.payload
    );
    const urlsData: any = {
      filesData: action.payload.filesData,
    };
    if (urls.status === 200) {
      urlsData["urls"] = urls.data.data;
      if (action?.payload?.sucessCallback) {
        urlsData["sucessCallback"] = action.payload.sucessCallback;
      }
      if (action?.payload?.isUploadDocumentBreak) {
        urlsData["isUploadDocumentBreak"] =
          action.payload.isUploadDocumentBreak;
      }

      urlsData.uploadOnly = uploadOnly;

      if (uploadOnly) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .GET_VEHICLE_SIGNED_URLS_SUCCESS,
          payload: action.payload,
        });
        sucessCallback && sucessCallback(urls?.data?.data);
      }
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .PUT_VEHICLE_IMAGES_ON_SIGNED_URLS_REQUESTED,
        payload: urlsData,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_VEHICLE_SIGNED_URLS_FAILED,
      });
      toastr.error("", handleMessage(urls));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_VEHICLE_SIGNED_URLS_FAILED,
    });
  }
}

// get booking detials using user phone number
function* bookingListWithPhoneNumber(action: any): any {
  try {
    const { userPhoneNumber, ...restPayload } = action.payload;
    const response = yield call(customerManagement.searchCustomerService, {
      mobile_number: userPhoneNumber,
    });
    const userDetails = response?.data?.data.searchResult;
    if (
      (response.status === 200 || response.status === 201) &&
      userDetails.length > 0
    ) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_REQUESTED,
        payload: { customerId: userDetails[0].id, ...restPayload },
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_BOOKING_LIST_USING_PHONE_FAILED,
      });
      // if user not registered ,clear  the booking list
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_BOOKING_REDUCER_DATA,
        payload: {
          bookingListData: {},
        },
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_BOOKING_LIST_USING_PHONE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// saga to get plan and addons data
function* getPlansAndAddons(action: actionInterface): any {
  try {
    const data = yield call(bookingManagement.getPlanAndAddon, action.payload);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_PLAN_ADDON_DETAILS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      const failPayload = {
        planAndAddonsData: {},
        planAndAddonsDataLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    const errorPayload = {
      planAndAddonsData: {},
      planAndAddonsDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// saga to get modification history data\
function* getModificationHistory(action: actionInterface): any {
  try {
    const data = yield call(
      bookingManagement.getModificationHistory,
      action?.payload
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_MODIFICATION_HISTORY_SUCCESS,
        payload: data?.data?.data?.details,
      });
    } else {
      const failPayload = {
        modificationHistoryData: [],
        modificationHistoryLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    const errorPayload = {
      modificationHistoryData: [],
      modificationHistoryLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// fetch available vehicle for the booking
function* fetchAvailableVehicleList(action: any): any {
  try {
    const data = yield call(
      bookingManagement.getAvailableVehicles,
      action.payload
    );
    if (data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .FETCH_AVAILABLE_VEHICLE_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .FETCH_AVAILABLE_VEHICLE_LIST_FAILED,
      });
      if (!action.payload.isvehicleAssigned) {
        toastr.error("", handleMessage(data));
      }
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .FETCH_AVAILABLE_VEHICLE_LIST_FAILED,
    });
    if (!action.payload.isvehicleAssigned) {
      toastr.error("", handleMessage(err));
    }
  }
}

// saga to fetch delay reasons for a booking
function* startRideDelayReasons(action: any): any {
  try {
    const data = yield call(
      bookingManagement.checkStartRideDelayReasons,
      action.payload
    );
    if (data.status === 200) {
      const filteredReasonDetails = data?.data?.data?.reasonDetails?.filter(
        (item: any) => {
          return item?.applicableFor?.includes("ADMIN");
        }
      );
      if (filteredReasonDetails) {
        data.data.data.reasonDetails = filteredReasonDetails;
      }
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CHECK_START_RIDE_DELAY_SUCCESS,
        payload: data.data,
      });
      if (!data?.data?.data?.isLateStart && action?.onSuccess) {
        action?.onSuccess();
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CHECK_START_RIDE_DELAY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CHECK_START_RIDE_DELAY_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// start a ride
function* startRide(action: any): any {
  try {
    const response = yield call(bookingManagement.startRide, action.payload);
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_SUCCESS,
        payload: response,
      });
      action?.payload?.onSuccess();
      toastr.success("", handleMessage(response));
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_FAILED,
      });
      if (
        response?.error?.errorCode === "PENDING_CHARGES_IN_PREVIOUS_BOOKINGS" &&
        response?.error?.httpStatusCode === 422
      ) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .SET_PENDING_CHARGES_BOOKINGS_START_RIDE,
          payload: {
            message: response?.error?.errorMessage,
            data: response?.error?.data?.bookings,
          },
        });
      } else {
        toastr.error("", handleMessage(response));
      }
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* assignVehicle(action: any): any {
  try {
    const data = yield call(bookingManagement.assignVehicle, action.payload);
    const { delayId, bookingId } = action.payload;
    if (data.status === 201 || data.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_SUCCESS,
        payload: data.data.data,
      });
      if (delayId === false) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .CHECK_START_RIDE_DELAY_REQUESTED,
          payload: { bookingId },
          onSuccess: action?.payload?.onSuccess,
        });
      } else {
        action?.payload?.onSuccess();
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// create bulk additional charges
function* createBulkAdditionalChargesRevamp(action: any): any {
  try {
    const response = yield call(
      bookingManagement.saveAdditionalChargesService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      const savedCharges = response?.data?.data?.charges;

      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_SUCCESS,
        payload: savedCharges,
      });

      // Refresh vehicle payload
      const allBookingSectionsParam = {
        bookingId: action.payload.bookingId,
        queryParamList: {},
      };

      // Refresh vechile tab charges (challans and extras) section
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_ADDITIONAL_CHARGES_REQUESTED,
        payload: allBookingSectionsParam,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_FAILED,
    });
  }
}

//put vehicle attachment on singed urls
function* putVehicleImagesOnSignedUrls(action: any): any {
  try {
    const {
      urls,
      filesData,
      isUploadDocumentBreak = false,
      sucessCallback = () => {},
      uploadOnly,
    } = action.payload;
    let attachments: any = [];
    let payload: any = {
      id: filesData.bookingId,
      data: {
        data: {
          attachmentIds: attachments,
        },
      },
    };

    let updatePayload: any = {
      id: filesData.bookingId,
      data: {
        data: {
          update: {
            attachmentIds: attachments,
          },
        },
      },
    };
    const tasks = urls?.map((url: any, index: number) => {
      attachments.push(url.id);
      let binaryFile = new Blob([filesData.files[index]], {
        type: filesData.files[index].type,
      });
      return call(fetch, url?.signedUrl, { method: "PUT", body: binaryFile });
    });

    const results = yield all(tasks);

    if (uploadOnly) {
      // no need to hit vehicle APIs
      return;
    }
    if (results?.length) {
      //upload document break
      if (isUploadDocumentBreak) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_SUCCESS,
          payload: attachments ?? [],
        });
        sucessCallback(filesData);
        return;
      }
      const data = filesData?.startRideImageRefId
        ? yield call(bookingManagement.updateVehicleImages, updatePayload)
        : yield call(bookingManagement.uploadVehicleImages, payload);
      if (data.status === 200) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
          payload: {
            bookingId: filesData.bookingId,
            fetchVehicleImages: true,
            requiredImages: [BookingImagesTypes.START_RIDE],
          },
        });
      } else {
        toastr.error("", handleMessage(data)),
          yield put({
            type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
              .VEHICLE_IMAGES_UPLOAD_FAILED,
          });
      }
    }
  } catch (error) {
    toastr.error("", handleMessage(error)),
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .VEHICLE_IMAGES_UPLOAD_FAILED,
      });
  }
}

function* createOrderIdForBulkCharges(action: any): any {
  try {
    const response = yield call(
      bookingManagement.generateOrderIdRevamp,
      action.payload
    );
    if (response?.status == 200 || response?.status == 201) {
      const orderData = response?.data?.data;

      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS,
        payload: { orderData: orderData },
      });
      if (orderData.id) {
        // QR/Link Payload
        const paymentPayload = {
          fetchBookingPayload: action.payload.fetchBookingPayload,
          data: {
            orderId: orderData.id,
            paymentFlow: action.payload.selectedPaymentMode,
          },
          sucessCallbackPayment: action.payload.sucessCallbackPayment,
        };

        if (action.payload?.paymentCommonAction) {
          yield put({
            type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED,
            payload: paymentPayload,
          });
        } else {
          // GENERATE PAYMENT QR/LINK ON SUCCESS
          yield put({
            type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
              .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
            payload: paymentPayload,
          });
        }
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_FAILED,
    });
  }
}
// complete ride payment
function* completeRidePayment(action: any): any {
  try {
    const response = yield call(
      bookingManagement.completeRidePayment,
      action.payload
    );
    if (response?.status == 200 || response?.status == 201) {
      const orderData = response?.data?.data;

      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .COMPLETE_RIDE_PAYMENT_SUCCESS,
        payload: { orderData: orderData },
      });
      if (orderData.id) {
        // QR/Link Payload
        const paymentPayload = {
          fetchBookingPayload: action.payload.fetchBookingPayload,
          data: {
            orderId: orderData.id,
            paymentFlow: action.payload.selectedPaymentMode,
          },
          sucessCallbackPayment: action.payload.sucessCallbackPayment,
        };

        yield put({
          type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED,
          payload: paymentPayload,
        });
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .COMPLETE_RIDE_PAYMENT_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_FAILED,
    });
  }
}

// get booking overview list
function* getPaymentOverview(action: actionInterface): any {
  try {
    const data = yield call(
      bookingManagement.getPaymentOverviewList,
      action.payload
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_PAYMENTS_OVERVIEW_LIST_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_PAYMENTS_OVERVIEW_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_PAYMENTS_OVERVIEW_LIST_FAILED,
    });
  }
}

// function for
// This will be Generic - initiate payment & generate link/QR
function* generatePaymentLinkQRForOrder(action: any): any {
  try {
    const response = yield call(
      bookingManagement.generatePaymentLinkQRForOrder,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const paymentData = response?.data?.data;
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_SUCCESS,
        payload: paymentData,
      });
      const EnquiryPayload = {
        data: { id: paymentData?.paymentId },
        counter: paymentStatusDurationPayload.counter,
        delayDuration: paymentStatusDurationPayload?.delayDuration,
        sucessCallback: action?.payload?.sucessCallbackPayment,
      };

      // Payment enquiry for QR code
      if (action.payload.data.paymentFlow === OrderPaymentType.QR_CODE) {
        yield put({
          type: actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_REQUESTED,
          payload: EnquiryPayload,
        });
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_FAILED,
    });
  }
}

// Waive off charges
function* waiveOffBookingCharges(action: any): any {
  try {
    const response = yield call(
      bookingManagement.waiveOffBookingChargesService,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const orderData = response?.data?.data;
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .WAIVE_OFF_BOOKING_CHARGES_SUCCESS,
        payload: orderData,
      });
      toastr.success("", handleMessage(response));

      // Refresh charges on success
      if (action.payload.fetchChargesParam) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .GET_ADDITIONAL_CHARGES_REQUESTED,
          payload: action.payload.fetchChargesParam,
        });
      }
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .WAIVE_OFF_BOOKING_CHARGES_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .WAIVE_OFF_BOOKING_CHARGES_FAILED,
    });
  }
}

// waive off reasons
function* getWaiveOffChargesReasons(action: any): any {
  try {
    const response = yield call(
      bookingManagement.waiveOffReasonsService,
      action.payload
    );

    if (response?.status == 200 || response?.status == 201) {
      const reasonArray = response?.data?.data?.reasons;
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_WAIVE_OFF_REASONS_LIST_REVAMP_SUCCESS,
        payload: reasonArray,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_WAIVE_OFF_REASONS_LIST_REVAMP_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_WAIVE_OFF_REASONS_LIST_REVAMP_FAILED,
    });
  }
}

//get pending charges when end ride
function* getPendingChargesSaga(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagement.getPendingCharges,
      action?.payload
    );
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_PENDING_CHARGES_SUCCESS,
        payload: response?.data?.data,
      });

      action?.payload?.onSuccess && action?.payload?.onSuccess();
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//end ongoing ride
function* endRideSaga(action: any): any {
  try {
    const response = yield call(bookingManagement.endRide, action.payload);
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.sucessCallback && action?.payload?.sucessCallback();
      toastr.success("", handleMessage(response));
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//complete  ride
function* completeRideSaga(action: any): any {
  try {
    const response = yield call(
      bookingManagement.completeRide,
      action.payload?.data
    );
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.successCallback && action?.payload?.successCallback();
      toastr.success("", handleMessage(response));
    } else if (response?.error?.httpStatusCode === 422) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_FAILED,
      });
      toastr.info("", handleMessage(response));
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// get booking extension availability
function* getExtensionAvailabilityDetails(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagement.getExtensionAvailability,
      action?.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_EXTENSION_AVAILABILITY_DETAILS_SUCCESS,
        payload: response?.data?.data?.availableTillDate,
      });

      if (response?.data?.data?.availableTillDate) action?.payload?.onSuccess();
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_EXTENSION_AVAILABILITY_DETAILS_FAILED,
      });
      toastr.error("", rentalEn.errorMessages.extensionNotAvailable);
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_EXTENSION_AVAILABILITY_DETAILS_FAILED,
    });
  }
}

// get extension plans
function* getExtensionPlans(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagement.getExtensionPlansDetails,
      action?.payload
    );
    if (response.status === 200 || response.status === 201) {
      const responseData = response?.data?.data;
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_EXTENSION_PLANS_DETAILS_SUCCESS,
        payload: responseData,
      });

      // callback call after api success
      action.payload?.successCallback &&
        action.payload?.successCallback({
          key: planTypeKeys.RegularPlan,
          heading: rentalEn.bookingDetails.regularPlan,
          amount: responseData?.amount - responseData.discount || 0,
          kmLimit: responseData.kmLimit || 0,
          isAvailable: true,
        });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_EXTENSION_PLANS_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_EXTENSION_PLANS_DETAILS_FAILED,
    });
  }
}

// get calculated extension charges
function* calculateExtensionCharge(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagement.getCalculatedExtensionCharges,
      action?.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_CALCULATED_EXTENSION_CHARGES_SUCCESS,
        payload: response?.data?.data,
      });

      if (action.payload?.couponSuccessCallback)
        action.payload.couponSuccessCallback();
    } else {
      if (action.payload?.apiBody?.data?.couponCode) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .COUPON_APPLY_FAILED_EXTENSION,
        });
      } else {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .GET_CALCULATED_EXTENSION_CHARGES_FAILED,
        });
      }
      toastr.error("", handleMessage(response));
    }
    return response;
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_CALCULATED_EXTENSION_CHARGES_FAILED,
    });
  }
}

// get Recent Modifications History
function* getRecentModificationsHistory(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagement.recentModificationsHistory,
      action?.payload
    );
    if (response.status === 200 || response.status === 201) {
      let tempObj = new Map();
      response?.data?.data?.details?.map((obj: any) => {
        tempObj.set(obj.category, obj);
      });
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_RECENT_MODIFICATION_HISTORY_SUCCESS,
        payload: tempObj,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_RECENT_MODIFICATION_HISTORY_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_RECENT_MODIFICATION_HISTORY_FAILED,
    });
  }
}

// create booking extension
function* createBookingExtension(action: actionInterface): any {
  const { lastCalculatedAmount, sucessCallbackPayment } = action?.payload;
  try {
    // call calculate extension charge api to check any difference
    // in calculated amount during create extension

    const extensionCharge = yield calculateExtensionCharge({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_CALCULATED_EXTENSION_CHARGES_REQUESTED,
      payload: action?.payload?.calculateExtensionpayload,
    });

    const { status, data: currentChargeData } = extensionCharge;

    const currentCalculatedCharge =
      currentChargeData?.data?.totalAmount -
      currentChargeData?.data?.totalDiscount;

    if (
      (status === 200 || status === 201) &&
      currentCalculatedCharge === action?.payload?.lastCalculatedAmount
    ) {
      // call to create booking extension
      const response = yield call(
        bookingManagement.createBookingExtension,
        action?.payload
      );
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .CREATE_BOOKING_EXTENSION_SUCCESS,
          payload: response?.data?.data,
        });

        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
          payload: { bookingId: action.payload.bookingId },
        });

        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .GET_VEHICLE_EXTENSION_REQUESTED,
          payload: { bookingId: action.payload.bookingId },
        });
        if (lastCalculatedAmount !== 0) {
          const orderData = response?.data?.data?.orderDetails;
          if (orderData.id) {
            // QR/Link Payload
            const paymentPayload = {
              // fetchBookingPayload: action.payload.fetchBookingPayload,
              data: {
                orderId: orderData.id,
                paymentFlow: action.payload?.selectedPaymentMode,
              },
              sucessCallbackPayment: sucessCallbackPayment,
            };
            // GENERATE PAYMENT QR/LINK ON SUCCESS
            yield put({
              type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
                .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
              payload: paymentPayload,
            });
          }
        } else {
          sucessCallbackPayment && sucessCallbackPayment();
        }
      } else {
        yield put({
          type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
            .CREATE_BOOKING_EXTENSION_FAILED,
        });
        toastr.error("", handleMessage(response));
      }
    } else if (
      currentCalculatedCharge !== action?.payload?.lastCalculatedAmount
    ) {
      toastr.warning("", rentalEn.errorMessages.priceBreakupPleaseReview);
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CREATE_BOOKING_EXTENSION_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CREATE_BOOKING_EXTENSION_FAILED,
    });
  }
}

// calculate the address and addon charges
function* calculateAddressAddOnCharges(action: any): any {
  try {
    const response = yield call(
      bookingManagement.calculateAddressCharges,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_ADDRESS_CHARGES_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_ADDRESS_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CALCULATE_ADDRESS_CHARGES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate the address and addon charges
function* modifyAddressAddOnCharges(action: any): any {
  try {
    const { data: calculateChargesResponse } = yield call(
      bookingManagement.calculateAddressCharges,
      action.payload
    );
    const { modifyBookingCharges } = getStore().bookingManagementReducer;

    // check if there is change in amount on modify booking
    if (
      calculateChargesResponse?.data?.amountToPay !==
      modifyBookingCharges?.amountToPay
    ) {
      yield put(calculateAddressAddOnChargesAction(action?.payload));
      toastr.info("", rentalEn?.successMessage?.reviewPriceBreakup);
      return;
    }
    const response = yield call(
      bookingManagement.modifyAddressAddon,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .MODIFY_ADDRESS_ADDON_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.onSuccess &&
        action?.payload?.onSuccess(response?.data?.data);
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .MODIFY_ADDRESS_ADDON_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate the address and addon charges
function* calculatePlanAndSlotChargesSaga(action: any): any {
  try {
    const response = yield call(
      bookingManagement.calculatePlanModificationCharges,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_PLAN_MODIFICATION_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_PLAN_MODIFICATION_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CALCULATE_PLAN_MODIFICATION_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate the address and addon charges
function* modifyPlanAndSlot(action: any): any {
  try {
    const { data: calculatePlanResponse } = yield call(
      bookingManagement.calculatePlanModificationCharges,
      action.payload
    );
    const { modifyBookingCharges } = getStore().bookingManagementReducer;

    // check if there is change in amount on modify booking
    if (
      calculatePlanResponse?.data?.amountToPay !==
      modifyBookingCharges?.amountToPay
    ) {
      yield put(calculatePlanAndSlotCharges(action?.payload));
      toastr.info("", rentalEn?.successMessage?.reviewPriceBreakup);
      return;
    }
    const response = yield call(
      bookingManagement.modifyBookingPlan,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .MODIFY_BOOKING_PLAN_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.onSuccess &&
        action?.payload?.onSuccess(response?.data?.data);
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate the address and addon charges
function* getModificationReasons(action: any): any {
  try {
    const response = yield call(
      bookingManagement.getModificationReasons,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_MODIFICATION_REASONS_SUCCESS,
        payload: { data: response?.data?.data, key: action.payload },
      });
      action?.payload?.onSuccess &&
        action?.payload?.onSuccess(response?.data?.data);
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_MODIFICATION_REASONS_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_MODIFICATION_REASONS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate upgrade model charges
function* calculateUpgradeModelCharges(action: any): any {
  try {
    const response = yield call(
      bookingManagement.calculateModelModificationCharges,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_MODEL_MODIFICATION_CHARGES_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .CALCULATE_MODEL_MODIFICATION_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .CALCULATE_MODEL_MODIFICATION_CHARGES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// calculate upgrade model charges
function* modifyUpgradeModel(action: any): any {
  try {
    const { data: calculatePlanResponse } = yield call(
      bookingManagement.calculateModelModificationCharges,
      action.payload.data
    );
    const { modifyBookingCharges } = getStore().bookingManagementReducer;

    // check if there is change in amount on modify booking
    if (
      calculatePlanResponse?.data?.amountToPay !==
      modifyBookingCharges?.amountToPay
    ) {
      yield put(calculateUpgradeModelChargesAction(action?.payload.data));
      toastr.info("", rentalEn?.successMessage?.reviewPriceBreakup);
      return;
    }
    const response = yield call(
      bookingManagement.modifyBookingModel,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .MODIFY_BOOKING_MODEL_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.onSuccess &&
        action?.payload?.onSuccess(response?.data?.data);
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .MODIFY_BOOKING_MODEL_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}
// calculate upgrade model charges
function* avialableBookingModel(action: any): any {
  try {
    const response = yield call(
      bookingManagement.availableBookingModels,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .AVIALABLE_BOOKING_MODEL_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .AVIALABLE_BOOKING_MODEL_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .AVIALABLE_BOOKING_MODEL_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//get Extension details
function* getVehicleExtension(action: any): any {
  try {
    const response = yield call(
      bookingManagement.getVehicleExtensionDetails,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_VEHICLE_EXTENSION_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_VEHICLE_EXTENSION_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_EXTENSION_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//Unassign vehicle
function* bookingUnassignedVehicle(action: any): any {
  try {
    const response = yield call(
      bookingManagement.unassignVehicle,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_SUCCESS,
      });
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
        payload: { bookingId: action.payload.bookingId },
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//delete Extension details
function* deleteBookingExtension(action: any): any {
  try {
    const response = yield call(
      bookingManagement.deleteBookingExtension,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_EXTENSION_SUCCESS,
      });

      if (action.payload?.successCallback) action.payload.successCallback();
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_EXTENSION_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .DELETE_BOOKING_EXTENSION_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//delete Extension details
function* getCustomerBookingStatsv3(action: any): any {
  try {
    const response = yield call(
      bookingManagement.getCustomerBookingStatsV3,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKINGS_STATS_V3_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .GET_CUSTOMER_BOOKINGS_STATS_V3_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .GET_CUSTOMER_BOOKINGS_STATS_V3_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//delete modification
function* deleteBookingModification(action: any): any {
  try {
    const response = yield call(
      bookingManagement.deleteBookingModification,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_MODIFICATION_SUCCESS,
      });

      if (action.payload?.successCallback) action.payload.successCallback();
    } else {
      yield put({
        type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
          .DELETE_BOOKING_MODIFICATION_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    yield put({
      type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
        .DELETE_BOOKING_MODIFICATION_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* bookingManagementSaga() {
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
    getBookingDetails
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_REQUESTED,
    getBookingList
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_LIST_USING_PHONE_REQUESTED,
    bookingListWithPhoneNumber
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_ODOMETER_HISTORY_REQUESTED,
    getOdometerHistory
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_REQUESTED,
    getBookingStatistics
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_BOOKING_DOC_SIGNED_URLS_REQUESTED,
    getDocsSignedUrls
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .PUT_BOOKING_DOC_ATTACHMENT_ON_SIGNED_URLS_REQUESTED,
    putAttachmentOnSignedUrls
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_REQUESTED,
    getBookingDocuments
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.DELETE_BOOKING_DOCUMENTS_REQUESTED,
    deleteBookingDocuments
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_REQUESTED,
    cancelBooking
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PLAN_ADDON_DETAILS_REQUESTED,
    getPlansAndAddons
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_MODIFICATION_HISTORY_REQUESTED,
    getModificationHistory
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_REQUESTED,
    assignVehicle
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.CHECK_START_RIDE_DELAY_REQUESTED,
    startRideDelayReasons
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_REQUESTED,
    startRide
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_ADDITIONAL_CHARGES_REQUESTED,
    getAdditionalCharges
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_SIGNED_URLS_REQUESTED,
    getVehicleSignedUrls
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .PUT_VEHICLE_IMAGES_ON_SIGNED_URLS_REQUESTED,
    putVehicleImagesOnSignedUrls
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_REQUESTED,
    createBulkAdditionalChargesRevamp
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED,
    createOrderIdForBulkCharges
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_REQUESTED,
    completeRidePayment
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
    generatePaymentLinkQRForOrder
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.WAIVE_OFF_BOOKING_CHARGES_REQUESTED,
    waiveOffBookingCharges
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_WAIVE_OFF_REASONS_LIST_REVAMP_REQUESTED,
    getWaiveOffChargesReasons
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PAYMENTS_OVERVIEW_LIST_REQUESTED,
    getPaymentOverview
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_AVAILABILITY_DETAILS_REQUESTED,
    getExtensionAvailabilityDetails
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_REQUESTED,
    getPendingChargesSaga
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_REQUESTED,
    endRideSaga
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_REQUESTED,
    completeRideSaga
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .FETCH_AVAILABLE_VEHICLE_LIST_REQUESTED,
    fetchAvailableVehicleList
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_EXTENSION_PLANS_DETAILS_REQUESTED,
    getExtensionPlans
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CALCULATED_EXTENSION_CHARGES_REQUESTED,
    calculateExtensionCharge
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_RECENT_MODIFICATION_HISTORY_REQUESTED,
    getRecentModificationsHistory
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.CREATE_BOOKING_EXTENSION_REQUESTED,
    createBookingExtension
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_VEHICLE_EXTENSION_REQUESTED,
    getVehicleExtension
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.CALCULATE_ADDRESS_CHARGES_REQUESTED,
    calculateAddressAddOnCharges
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_REQUESTED,
    modifyAddressAddOnCharges
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_PLAN_MODIFICATION_REQUESTED,
    calculatePlanAndSlotChargesSaga
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_REQUESTED,
    modifyPlanAndSlot
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_MODIFICATION_REASONS_REQUESTED,
    getModificationReasons
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_MODEL_MODIFICATION_CHARGES_REQUESTED,
    calculateUpgradeModelCharges
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_REQUESTED,
    modifyUpgradeModel
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.AVIALABLE_BOOKING_MODEL_REQUESTED,
    avialableBookingModel
  );
  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_REQUESTED,
    bookingUnassignedVehicle
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS.DELETE_BOOKING_EXTENSION_REQUESTED,
    deleteBookingExtension
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_CUSTOMER_BOOKINGS_STATS_V3_REQUESTED,
    getCustomerBookingStatsv3
  );

  yield takeLatest(
    actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .DELETE_BOOKING_MODIFICATION_REQUESTED,
    deleteBookingModification
  );
}
export default bookingManagementSaga;
