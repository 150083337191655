import { GenericObject } from "../../../constants/constants";
import * as actionTypes from "../../actions/actionTypes";

// where is interface for this ?

const initialState = {
  rentalsCommonLoader: false,
  allCitiesDetails: [],
  branches: [],
  modelData: [],
  modelloading: false,
  masterPlanDurationsData: [],
  modelListByBranch: [],
  masterPaymentModeData: [],
  isGetBranches: false,
  allStatesList: [],
  stateBasedCities: [],
  cityDetails: {},
  screenHeaderName: "Dashboard",
  bookingCancellationReasons: [],
  cancellationCharges: {},
  chargesTypesArr: [],
  operatorConfigData: {},
  isPaymentLinkGenerated: false,
  paymentLoader: false,
  paymentLink: [],
  updatedStatus: "",
  hubsByMultipleCities: [], // Hubs based on multiple cities
  branchDefaultConfig: {},
  branchListByCities: [],
  citiesBasedOnMultiStates: [], // Cities based on multiple states
  advanceFiltersHubs: [],
  advanceFiltersModel: [],
  savedOperatorConfigData: new Map<string, GenericObject>(),
  pincodeDistrictLoader: false,
};

const {
  RENTALS_GET_CITY_DETAILS_REQUESTED,
  RENTALS_GET_CITY_DETAILS_SUCCESS,
  RENTALS_GET_CITY_DETAILS_FAILED,
  SET_SCREEN_HEADER_NAME,
} = actionTypes.COMMON_ACTIONS;

const rentalsCommonReducer = (state = initialState, action: any) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_SUCCESS:
      return {
        ...state,
        allCitiesDetails: payload?.cityData,
        rentalsCommonLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_FAILED:
      return {
        ...state,
        allCitiesDetails: [],
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
        isGetBranches: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_SUCCESS:
      return {
        ...state,
        branches: payload?.branches?.data,
        rentalsCommonLoader: false,
        isGetBranches: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_FAILED:
      return {
        ...state,
        branches: [],
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_MODEL_DATA_REQUESTED:
      return {
        ...state,
        modelloading: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_MODEL_DATA_SUCCESS:
      return {
        ...state,
        modelData: payload.modelData,
        modelloading: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_MODEL_DATA_FAILED:
      return {
        ...state,
        modelloading: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_SUCCESS:
      return {
        ...state,
        masterPlanDurationsData: payload.masterPlanDurationsData,
        rentalsCommonLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_FAILED:
      return {
        ...state,
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_ALL_STATES_REQUESTED:
      return {
        ...state,
        stateBasedCities: [],
      };
    case actionTypes.COMMON_ACTIONS.GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        allStatesList: payload?.stateData,
      };
    case actionTypes.COMMON_ACTIONS.GET_ALL_STATES_FAILED:
      return {
        ...state,
      };

    case actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_REQUESTED:
      if (action?.advanceFilterData) {
        return {
          ...state,
          advanceFiltersModel: [],
        };
      } else
        return {
          ...state,
          modelListByBranch: [],
        };

    case actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_SUCCESS:
      if (action?.advanceFilterData) {
        return {
          ...state,
          advanceFiltersModel: payload,
        };
      } else
        return {
          ...state,
          modelListByBranch: payload,
          advanceFiltersModel: payload,
        };
    case actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_FAILED:
      if (action?.advanceFilterData) {
        return {
          ...state,
          advanceFiltersModel: [],
        };
      } else
        return {
          ...state,
          modelListByBranch: [],
        };

    case actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        masterPaymentModeData: payload.masterPaymentModesData,
        rentalsCommonLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_FAILED:
      return {
        ...state,
        rentalsCommonLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_REQUESTED:
      return {
        ...state,
        stateBasedCities: [],
      };
    case actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_SUCCESS:
      return {
        ...state,
        stateBasedCities: payload,
      };
    case actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_FAILED:
      return {
        ...state,
      };
    case RENTALS_GET_CITY_DETAILS_REQUESTED:
      return {
        ...state,
        cityDetails: {},
      };
    case RENTALS_GET_CITY_DETAILS_SUCCESS:
      return {
        ...state,
        cityDetails: payload,
      };
    case RENTALS_GET_CITY_DETAILS_FAILED:
      return {
        ...state,
        cityDetails: {},
      };

    case SET_SCREEN_HEADER_NAME:
      return {
        ...state,
        screenHeaderName: payload.headerName,
      };

    case actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_SUCCESS:
      return {
        ...state,
        bookingCancellationReasons: payload,
      };
    case actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_FAILED:
      return {
        ...state,
      };

    case actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_SUCCESS:
      return {
        ...state,
        cancellationCharges: payload,
      };
    case actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_FAILED:
      return {
        ...state,
      };
    case actionTypes.COMMON_ACTIONS.CLEAR_CANCELLATION_CHARGES:
      return {
        ...state,
        cancellationCharges: {},
      };

    // clear branches and modelListByBranch
    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_BRANCHES_AND_MODELS:
      return {
        ...state,
        branches: [],
      };

    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_STATE_BASED_CITIES:
      return {
        ...state,
        stateBasedCities: [],
      };

    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_HUBS_LIST_DATA:
      return {
        ...state,
        branches: [],
      };

    // Get all charges type list
    case actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_REQUESTED:
      return {
        ...state,
        chargesTypesArr: initialState.chargesTypesArr,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_SUCCESS:
      return {
        ...state,
        chargesTypesArr: payload,
      };
    case actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_FAILED:
      return {
        ...state,
        chargesTypesArr: initialState.chargesTypesArr,
      };
    case actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_SUCCESS:
      return {
        ...state,
        operatorConfigData: payload,
        rentalsCommonLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_FAILED:
      return {
        ...state,
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_REQUESTED:
      return {
        ...state,
        rentalsCommonLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatusData: payload,
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_FAILED:
      return {
        ...state,
        rentalsCommonLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED:
      return {
        ...state,
        paymentLoader: true,
        paymentLink: null,
        isPaymentLinkGenerated: false,
      };
    case actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_SUCCESS:
      return {
        ...state,
        paymentLink: payload,
        paymentLoader: false,
        isPaymentLinkGenerated: true,
      };
    case actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_FAILED:
      return {
        ...state,
        paymentLink: null,
        paymentLoader: false,
        isPaymentLinkGenerated: false,
      };
    case actionTypes.COMMON_ACTIONS.TOOGLE_PAYMENT_VIEW:
      return {
        ...state,
        isPaymentLinkGenerated: !state.isPaymentLinkGenerated,
      };

    case actionTypes.COMMON_ACTIONS.GET_UPDATED_PAYMENT_STATUS:
      return {
        ...state,
        updatedStatus: payload,
      };

    case actionTypes.COMMON_ACTIONS.RENTAL_COMMOM_CLEAR_REDUCER_DATA:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_SUCCESS:
      return {
        ...state,
        branchDefaultConfig: payload,
      };
    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_FAILED:
      return {
        ...state,
      };

    case actionTypes.COMMON_ACTIONS.CLEAR_BRANCH_DEFAULT_CONFIG:
      return {
        ...state,
        branchDefaultConfig: {},
      };

    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_REQUESTED:
      if (action.advanceFilterData) {
        return {
          ...state,
          advanceFiltersHubs: [],
        };
      } else
        return {
          ...state,
          branchesByCities: [],
          hubsByMultipleCities: [],
        };

    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_SUCCESS:
      if (payload.advanceFilterData) {
        return {
          ...state,
          advanceFiltersHubs: payload?.authoriseBranches,
        };
      } else
        return {
          ...state,
          branchesByCities: payload?.branchData,
          hubsByMultipleCities: payload?.hubsByMultipleCities,
          authoriseBranches: payload?.authoriseBranches,
          advanceFiltersHubs: payload?.authoriseBranches,
        };

    case actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_FAILED:
      if (action.advanceFilterData) {
        return {
          ...state,
          advanceFiltersHubs: [],
        };
      } else
        return {
          ...state,
          hubsByMultipleCities: [],
        };

    // Fetch cities on the basis of multiple states
    case actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_REQUESTED:
      return {
        ...state,
        citiesBasedOnMultiStates: [],
      };
    case actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_SUCCESS:
      return {
        ...state,
        citiesBasedOnMultiStates: payload?.cityData,
      };
    case actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_FAILED:
      return {
        ...state,
        citiesBasedOnMultiStates: [],
      };

    case actionTypes.COMMON_ACTIONS.SET_BOOKING_REDUCER_DATA:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.COMMON_ACTIONS.SAVE_OPERATOR_CONFIG_RESPONSE:
      return {
        ...state,
        savedOperatorConfigData: payload,
      };

    // Fetch states and dictrict on the basis of multiple pincode
    case actionTypes.COMMON_ACTIONS
      .GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED:
      return {
        ...state,
        dictrictBasedOnPincode: [],
        pincodeDistrictLoader: true,
      };
    case actionTypes.COMMON_ACTIONS.GET_STATES_AND_DISTRICT_BY_PINCODE_SUCCESS:
      return {
        ...state,
        dictrictBasedOnPincode: payload.data,
        pincodeDistrictLoader: false,
      };
    case actionTypes.COMMON_ACTIONS.GET_STATES_AND_DISTRICT_BY_PINCODE_FAILED:
      return {
        ...state,
        dictrictBasedOnPincode: [],
        pincodeDistrictLoader: false,
      };

    case actionTypes.COMMON_ACTIONS.GET_STATES_AND_DISTRICT_BY_PINCODE_CLEAR:
      return {
        ...state,
        dictrictBasedOnPincode: [],
        pincodeDistrictLoader: false,
      };

    default:
      return { ...prevState };
  }
};

export default rentalsCommonReducer;
