import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { cloudUploadIcon } from "../../../../../assets/exportImages";
import { RootState } from "../../../../../redux/reducers";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import InputDatePicker from "../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import DLModalLoader from "../../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/DLModalLoader";
import { colors } from "../../../../../themes/colors";
import {
  DocumentUploadConstants,
  KycFilePlacementType,
  KycFilePlacementTypeDisplayName,
  KycTypes,
  KycVerificationMode,
  KycVerificationModeDisplayName,
  KycVerificationStatus,
  KycVerificationStatusDisplayName,
  typographyConstants,
} from "../../../../constants/constants";
import {
  NotesCheck,
  alertNotes,
  circularLineIcon,
  deleteKyc,
  notesIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { getKYCSignedUrl } from "../../../../redux/actions/customerManagementActions";
import styles from "./section.module.css";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { useNavigate } from "react-router-dom";

type DropEvent =
  | React.DragEvent<HTMLElement>
  | React.ChangeEvent<HTMLInputElement>
  | DragEvent
  | Event;
interface previewInterface {
  frontImage: {
    name: string;
    url: string;
  };
  backImage: {
    name: string;
    url: string;
  };
}
const dlFileTypes = ["FRONT", "BACK"];

export const DLTrustScoreModal = ({
  customerId,
  dlTrustScore,
  dlPayloadObj,
  individualKycDetailsData,
  individualKycDetailsLoader,
  handleSaveAndVerifyUploadToS3,
  dlOcrFields,
  handleDLOcrFieldsChange,
  handleOcrToggle,
  handleManualKycVerification,
  handleDeleteCustomerKyc,
  handleUnverifyCustomerKyc,
  isFunctionalityBlocked,
  isKycDeletePermissionEnabled,
  handleDocsPreviewNavigation,
  toogleKycDrawer,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    uploadLoader,
    signedUrlFailedFiles,
    linearProgressValue,
    kycModalLoader,
  } = useSelector((state: RootState) => state.customerManagementReducer);

  const initialPreview: previewInterface = {
    frontImage: {
      name: "",
      url: "",
    },
    backImage: {
      name: "",
      url: "",
    },
  };
  const initialDlUploading: any = {
    FRONT: false,
    BACK: false,
  };
  interface notesStatusInterface {
    isModified: boolean;
    upload: boolean;
    format: boolean;
    size: boolean;
  }
  const initialNotesStatus: notesStatusInterface = {
    isModified: false,
    upload: false,
    format: false,
    size: false,
  };
  const [dlPreview, setDlPreview] = useState(initialPreview);
  const [dlUploading, setDlUploading] = useState(initialDlUploading);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openUnverifyConfirmation, setOpenUnverifyConfirmation] =
    useState(false);
  const [notesStatus, setNotesStatus] = useState(initialNotesStatus);

  let isPreviewPresent = false;
  if (
    (dlPreview["frontImage"]["name"] && dlPreview["frontImage"]["url"]) ||
    (dlPreview["backImage"]["name"] && dlPreview["backImage"]["url"])
  ) {
    isPreviewPresent = true;
  }

  const kycId = dlTrustScore?.kycId;
  const dlDetails = individualKycDetailsData?.details
    ? individualKycDetailsData?.details
    : {};
  const dlDetailsLength = Object.keys(dlDetails).length;
  const verificationDetail = individualKycDetailsData?.verificationDetail
    ? individualKycDetailsData?.verificationDetail
    : {};
  const verificationDetailLength = Object.keys(verificationDetail).length;

  // const isPresent = dlTrustScore?.isPresent;
  // const isVerified = dlTrustScore?.isVerified;
  const isPresent = verificationDetailLength > 0 ? true : false;
  const isVerified =
    verificationDetail?.verificationStatus === KycVerificationStatus.VERIFIED
      ? true
      : false;

  const dlDetailsFiles =
    dlDetailsLength > 0 && dlDetails?.files.length > 0
      ? dlDetails?.files[0]
      : [];
  const isView = isPresent;

  const dropZoneSetting = {
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    maxSize: 5000000,
    multiple: false,
    noClick: uploadLoader,
    noDrag: uploadLoader,
    disabled:
      (dlDetailsLength > 0 && dlDetails?.files.length > 0) ||
      uploadLoader ||
      isFunctionalityBlocked,
  };

  // toggle delete confirmation
  const toggleDeleteDlConfirmation = () => {
    setOpenDeleteConfirmation((prev) => !prev);
  };
  // toggle unverify confirmation
  const toggleUnverifyDlConfirmation = () => {
    setOpenUnverifyConfirmation((prev) => !prev);
  };

  const handleDeleteConfirmation = () => {};
  /* ----------KYC Logic ------------------- */
  interface generateSignedUrlPayload {
    requestdata: {
      data: {
        fileNames: string[];
      };
    };
    filesData: {
      customerId: string;
      files: object[];
      fileNames: string[];
    };
    kycPayloadObj: any;
    kycSubType: string;
  }

  // Initiate DL upload (Front/Back) : generate signed url
  const generateSignedUrl = (
    fileNames: string[],
    files: any,
    dlType: string
  ) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        customerId: customerId,
        files,
        fileNames,
      },
      kycPayloadObj: dlPayloadObj,
      kycSubType: dlType,
    };

    dispatch(getKYCSignedUrl(payload));
  };

  /* ------------- DL front image ----------------------- */
  const onDropDLFront = useCallback(
    (acceptedFiles: object[], fileRejections: object[], event: DropEvent) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        setDlPreview((prev: any) => {
          return {
            ...prev,
            frontImage: { url: URL.createObjectURL(file), name: file.name },
          };
        });
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorDlAadhaar
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, "frontImageAttachmentId");
      }

      // setRejectedFile(rejectedFiles);
    },
    []
  );

  const {
    getRootProps: getRootPropsDLFront,
    getInputProps: getInputPropsDLFront,
  } = useDropzone({
    onDrop: onDropDLFront,
    ...dropZoneSetting,
  });

  /* ------------- DL back image ----------------------- */

  const onDropDLBack = useCallback(
    (acceptedFiles: object[], fileRejections: object[], event: DropEvent) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        setDlPreview((prev: any) => {
          return {
            ...prev,
            backImage: { url: URL.createObjectURL(file), name: file.name },
          };
        });
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorDlAadhaar
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, "backImageAttachmentId");
      }

      // setRejectedFile(rejectedFiles);
    },
    []
  );

  const {
    getRootProps: getRootPropsDlBack,
    getInputProps: getInputPropsDlBack,
  } = useDropzone({
    onDrop: onDropDLBack,
    ...dropZoneSetting,
  });

  const dlStyles = {
    objectFit: "contain",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    height: "100px",
  };
  const dlFrontSx =
    (dlDetailsLength > 0 && dlDetails?.files.length > 0) ||
    (dlPreview.frontImage.url && linearProgressValue === 100)
      ? {
          background:
            dlDetailsLength > 0 && dlDetails?.files.length > 0
              ? `url(${dlDetails.files[0].signedUrl})`
              : dlPreview.frontImage.url && linearProgressValue === 100
              ? `url(${dlPreview.frontImage.url})`
              : `none`,
          ...dlStyles,
        }
      : { cursor: "pointer" };
  const dlBackSx =
    (dlDetailsLength > 0 && dlDetails?.files.length > 0) ||
    (dlPreview.backImage.url && linearProgressValue === 100)
      ? {
          background:
            dlDetailsLength > 0 && dlDetails?.files.length > 0
              ? `url(${dlDetails.files[0].signedUrl})`
              : dlPreview.backImage.url && linearProgressValue === 100
              ? `url(${dlPreview.backImage.url})`
              : `none`,
          ...dlStyles,
        }
      : { cursor: "pointer" };

  useEffect(() => {
    if (
      (dlPayloadObj?.details?.frontImageAttachmentId &&
        dlPreview.frontImage.url) ||
      (dlPayloadObj?.details?.backImageAttachmentId && dlPreview.backImage.url)
    ) {
      let dlUploaded = false;
      dlUploaded =
        dlPayloadObj?.details?.frontImageAttachmentId &&
        dlPreview.frontImage.url &&
        dlPayloadObj?.details?.backImageAttachmentId &&
        dlPreview.backImage.url;
      setNotesStatus((prev: any) => ({
        ...prev,
        isModified: true,
        format: true,
        size: true,
        upload: dlUploaded ? true : false,
      }));
    }
  }, [
    dlPayloadObj?.details?.frontImageAttachmentId,
    dlPayloadObj?.details?.backImageAttachmentId,
  ]);

  const dlFiles = dlDetails?.files;

  return (
    <>
      {kycModalLoader || individualKycDetailsLoader ? (
        <DLModalLoader />
      ) : (
        <>
          {/* Delete Aadhar */}
          {!isFunctionalityBlocked &&
            isPresent &&
            isKycDeletePermissionEnabled && (
              <>
                <Stack alignItems={"flex-end"} padding={"0.5vw"}>
                  <img
                    src={deleteKyc}
                    alt="delete kyc icon"
                    onClick={() => toggleDeleteDlConfirmation()}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </>
            )}

          <Stack className={styles.KYCModal_Wrapper}>
            <Stack className="contentWrapper" gap="10px">
              {/* DL files */}
              <Stack gap="20px" direction="row">
                {/* Phases - View, Upload, Uploading, Preview  */}
                {isPresent ? (
                  <>
                    {/* View phase */}
                    <Box width="100%">
                      {dlDetailsLength > 0 && dlFiles.length > 0 ? (
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <Stack width={"100%"} sx={{ cursor: "pointer" }}>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Front Image
                            </Typography>
                            <Box
                              sx={{
                                background: `url(${dlDetails?.files[1]?.signedUrl})`,
                                ...dlStyles,
                              }}
                              onClick={() =>
                                handleDocsPreviewNavigation(
                                  dlDetails?.files[1]?.fileName,
                                  ["img", "jpg", "png"],
                                  dlDetails?.files[1]?.signedUrl
                                )
                              }
                            ></Box>
                          </Stack>
                          <Stack width={"100%"} sx={{ cursor: "pointer" }}>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Back Image
                            </Typography>
                            <Box
                              sx={{
                                background: `url(${dlDetails?.files[0]?.signedUrl})`,
                                ...dlStyles,
                              }}
                              onClick={() =>
                                handleDocsPreviewNavigation(
                                  dlDetails?.files[0]?.fileName,
                                  ["img", "jpg", "png"],
                                  dlDetails?.files[0]?.signedUrl
                                )
                              }
                            ></Box>
                          </Stack>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    {/* Upload, Uploading, Preview */}
                    {dlFileTypes.map((fileType: string, index: number) => {
                      let rootprops, inputProps, dlStyleSx;
                      let dlPreviewUrl;
                      let dlUploadingObj = {};
                      let allowUploading = true;
                      if (
                        (dlUploading[KycFilePlacementType.FRONT] ||
                          dlUploading[KycFilePlacementType.BACK]) &&
                        uploadLoader
                      ) {
                        allowUploading = false;
                      }
                      if (fileType === KycFilePlacementType.FRONT) {
                        rootprops = { ...getRootPropsDLFront() };
                        inputProps = { ...getInputPropsDLFront() };
                        dlStyleSx = dlFrontSx;
                        dlPreviewUrl = dlPreview.frontImage.url;
                        dlUploadingObj = {
                          [KycFilePlacementType.FRONT]: true,
                          [KycFilePlacementType.BACK]: false,
                        };
                      }
                      if (fileType === KycFilePlacementType.BACK) {
                        rootprops = { ...getRootPropsDlBack() };
                        inputProps = { ...getInputPropsDlBack() };
                        dlStyleSx = dlBackSx;
                        dlPreviewUrl = dlPreview.backImage.url;
                        dlUploadingObj = {
                          [KycFilePlacementType.FRONT]: false,
                          [KycFilePlacementType.BACK]: true,
                        };
                      }
                      return (
                        <>
                          <Stack width="100%">
                            {/* File Type label */}
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              {
                                KycFilePlacementTypeDisplayName[
                                  fileType as keyof typeof KycFilePlacementTypeDisplayName
                                ]
                              }{" "}
                              Image
                            </Typography>

                            {/* Uploading phase */}
                            {dlUploading[
                              KycFilePlacementType[
                                fileType as keyof typeof KycFilePlacementType
                              ]
                            ] && uploadLoader ? (
                              <>
                                <Stack
                                  className={styles?.documentUploadBox}
                                  {...rootprops}
                                  height="-webkit-fill-available"
                                  justifyContent={"center"}
                                >
                                  <Box sx={{ padding: "5px", gap: "10px" }}>
                                    <Stack gap="10px" textAlign={"center"}>
                                      <Box>
                                        <img
                                          src={cloudUploadIcon}
                                          width={"20px"}
                                          height="20px"
                                        />
                                      </Box>

                                      <LinearProgress
                                        value={linearProgressValue}
                                        variant="determinate"
                                        sx={{ width: "115px" }}
                                      />
                                      <Box>
                                        <Box
                                          style={{
                                            display: "flex",
                                            gap: "4px",
                                          }}
                                        >
                                          <img
                                            src={circularLineIcon}
                                            width={"20px"}
                                            height="20px"
                                          />
                                          <Typography
                                            sx={{
                                              lineHeight: "20px",
                                              fontSize: "16px",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {"Uploading..."}
                                          </Typography>
                                        </Box>

                                        <Typography
                                          sx={{
                                            lineHeight: "12px",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            color: "#A9ACB4",
                                          }}
                                        >
                                          {`${linearProgressValue}% of 100%`}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Box>
                                </Stack>
                              </>
                            ) : dlPreviewUrl ? (
                              <>
                                {/* Preview phase */}
                                <Stack
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (allowUploading) {
                                      setDlUploading({
                                        ...dlUploadingObj,
                                      });
                                    }
                                  }}
                                >
                                  <Box {...rootprops}>
                                    <Box
                                      sx={{
                                        background: `url(${dlPreviewUrl})`,
                                        objectFit: "contain",
                                        width: "100%",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "left",
                                        height: "145px",
                                        border: "dashed #d0d5dd",
                                      }}
                                    ></Box>

                                    <input {...inputProps} />
                                  </Box>
                                  <Box textAlign={"center"}>
                                    <img
                                      src={cloudUploadIcon}
                                      width={"20px"}
                                      height="20px"
                                    />
                                  </Box>
                                </Stack>
                              </>
                            ) : (
                              <>
                                {/* Upload phase */}
                                <Stack
                                  onClick={() => {
                                    if (allowUploading) {
                                      setDlUploading({
                                        ...dlUploadingObj,
                                      });
                                    }
                                  }}
                                >
                                  <Box
                                    className={styles?.documentUploadBox}
                                    sx={[
                                      ...(Array.isArray(dlStyleSx)
                                        ? dlStyleSx
                                        : [dlStyleSx]),
                                    ]}
                                    {...rootprops}
                                  >
                                    <Stack gap="10px" textAlign={"center"}>
                                      <Box>
                                        <img
                                          src={cloudUploadIcon}
                                          width={"20px"}
                                          height="20px"
                                        />
                                      </Box>

                                      <Typography>
                                        {
                                          rentalEn.CustomerManagement
                                            .kycUploadNotes
                                        }
                                      </Typography>
                                      <input {...inputProps} />
                                      <i
                                        className="fa fa-plus-square fa-2x mt-1"
                                        aria-hidden="true"
                                        style={{
                                          color: "#047857",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Stack>
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </Stack>
                        </>
                      );
                    })}
                  </>
                )}
              </Stack>

              {/* ---------------------- OCR section ------------------------------------ */}
              <Stack>
                {!isPresent && (
                  <>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Non OCR"
                      checked={dlOcrFields.ocr}
                      onClick={(e: any) => {
                        handleOcrToggle(e, e.target.value);
                      }}
                    />
                    <Stack gap={"10px"}>
                      {/* DL number */}
                      {dlOcrFields.ocr && (
                        <>
                          <Input
                            value={dlOcrFields.dlNumber}
                            placeholder={"DL-1420110012345"}
                            iconStart={notesIcon}
                            width="100%"
                            onChange={(e: any) => {
                              handleDLOcrFieldsChange(
                                "dlNumber",
                                e.target.value
                              );
                            }}
                            error={
                              dlOcrFields.error && dlOcrFields.error.dlNumber
                                ? typographyConstants.ERRORMESSAGE
                                : null
                            }
                            errormessage={
                              dlOcrFields.error && dlOcrFields.error.dlNumber
                                ? dlOcrFields.error.dlNumber
                                : ""
                            }
                          />
                          {/* error block */}
                          {/*  {dlOcrFields.error && dlOcrFields.error.dlNumber && (
                          <Typography
                            variant={typographyConstants.ERRORMESSAGE}
                            textAlign={"right"}
                          >
                            {dlOcrFields.error.dlNumber}
                          </Typography>
                        )} */}
                        </>
                      )}

                      {/* DOB */}
                      {dlOcrFields.ocr && (
                        <Stack>
                          <InputDatePicker
                            value={dlOcrFields.inputDob}
                            required
                            inputFormat={"DD/MM/YYYY"}
                            // label={"DOB"}
                            placeholder={"DOB"}
                            onChange={(date: any) => {
                              handleDLOcrFieldsChange("dob", date);
                            }}
                            maxDate={new Date()}
                          />
                          {/* error block */}
                          {dlOcrFields.error && dlOcrFields.error.dob && (
                            <Typography
                              variant={typographyConstants.ERRORMESSAGE}
                              sx={{ marginLeft: "8px" }}
                            >
                              {dlOcrFields.error.dob}
                            </Typography>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>

              {/* Notes & Verification details */}
              <Stack>
                {isPresent ? (
                  <>
                    {isView &&
                      dlDetailsLength > 0 &&
                      verificationDetailLength > 0 && (
                        <Stack gap="20px">
                          {/* status box */}
                          <Stack
                            sx={{
                              backgroundColor: colors.bg_light_gray,
                              borderRadius: "10px",
                            }}
                          >
                            <Box className={styles.selfie_View_Box}>
                              {" "}
                              <Typography
                                variant={typographyConstants?.SUBHEADING}
                                color={colors.text_black}
                              >
                                Verification Details
                              </Typography>{" "}
                            </Box>

                            <Stack>
                              {(!dlDetails?.ocr ||
                                dlDetails?.dlDetails?.dlNumber) && (
                                <>
                                  <Box className={styles.selfie_status_Box}>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.tertiary_black}
                                    >
                                      DL Number:
                                    </Typography>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.text_black}
                                    >
                                      {dlDetails?.dlDetails?.dlNumber ?? "N/A"}
                                    </Typography>
                                  </Box>
                                  <Box className={styles.selfie_status_Box}>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.tertiary_black}
                                    >
                                      DOB :
                                    </Typography>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.text_black}
                                    >
                                      {dlDetails?.dlDetails?.dob ?? "N/A"}
                                    </Typography>
                                  </Box>
                                </>
                              )}

                              <Box className={styles.selfie_status_Box}>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.tertiary_black}
                                >
                                  Mode:
                                </Typography>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.text_black}
                                >
                                  {
                                    KycVerificationModeDisplayName[
                                      verificationDetail?.verificationMode as keyof typeof KycVerificationModeDisplayName
                                    ]
                                  }
                                </Typography>
                              </Box>
                              <Box
                                className={styles.selfie_status_Box}
                                gap={"5px"}
                              >
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.tertiary_black}
                                >
                                  Status:
                                </Typography>
                                {individualKycDetailsData?.status ===
                                KycVerificationStatus.FAILURE ? (
                                  <Typography
                                    variant={typographyConstants?.SUBHEADING}
                                    color={colors.error}
                                  >
                                    {
                                      KycVerificationStatusDisplayName[
                                        individualKycDetailsData?.status as keyof typeof KycVerificationStatusDisplayName
                                      ]
                                    }{" "}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant={typographyConstants?.SUBHEADING}
                                    color={colors.text_black}
                                  >
                                    {
                                      KycVerificationStatusDisplayName[
                                        verificationDetail?.verificationStatus as keyof typeof KycVerificationStatusDisplayName
                                      ]
                                    }
                                  </Typography>
                                )}
                              </Box>
                              {/* Failure reason */}
                              {individualKycDetailsData?.status ===
                                KycVerificationStatus.FAILURE && (
                                <>
                                  <Box className={styles.selfie_status_Box}>
                                    <Typography
                                      variant={typographyConstants?.SUBHEADING}
                                      color={colors.tertiary_black}
                                    >
                                      Reason:
                                    </Typography>
                                    <Stack justifyContent={"flex-end"}>
                                      <Typography
                                        variant={
                                          typographyConstants?.SUBHEADING
                                        }
                                        color={colors.error}
                                      >
                                        {
                                          individualKycDetailsData?.failureReason
                                        }
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            `${routesConstants.CUSTOMER_MANAGEMENT}/${individualKycDetailsData?.duplicateDlCustomerId}`
                                          );
                                          toogleKycDrawer();
                                        }}
                                      >
                                        <Typography
                                          variant={
                                            typographyConstants?.SUBHEADING
                                          }
                                          sx={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            color: colors.THEME_BLUE,
                                            textAlign: "end",
                                          }}
                                        >
                                          {
                                            individualKycDetailsData?.duplicateDlCustomerId
                                          }
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Box>
                                </>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                  </>
                ) : (
                  <>
                    <Stack>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={"#292D32"}
                      >
                        Notes:
                      </Typography>
                      <Box>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.upload ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            Upload Front and Back images of Driving License
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.format ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            Supported Formats: Jpeg, Png and jpg.
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.size ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            File size should be less than 5 MB.
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>

            {/* Action Button */}
            <Box display={"flex"} justifyContent={"space-evenly"}>
              {!isPresent ? (
                <>
                  {!isFunctionalityBlocked && (
                    <CustomButton
                      variant="contained"
                      sx={{ width: "100%", padding: "0 20px" }}
                      label={"Digitally Verify"}
                      onClick={() =>
                        handleSaveAndVerifyUploadToS3(KycTypes.DRIVING_LICENCE)
                      }
                      loading={kycModalLoader}
                      disabled={
                        !dlPayloadObj?.details?.frontImageAttachmentId ||
                        !dlPayloadObj.details?.backImageAttachmentId ||
                        kycModalLoader ||
                        !dlPreview.frontImage.url ||
                        !dlPreview.backImage.url ||
                        !isPreviewPresent
                      }
                    />
                  )}
                </>
              ) : verificationDetailLength > 0 &&
                verificationDetail?.verificationStatus ===
                  KycVerificationStatus.UNVERIFIED &&
                verificationDetail?.verificationStatus !==
                  KycVerificationStatus.FAILURE ? (
                <>
                  {!isFunctionalityBlocked && (
                    <CustomButton
                      variant="contained"
                      sx={{ width: "100%" }}
                      label={`Manually Verify`}
                      onClick={() =>
                        handleManualKycVerification(
                          KycTypes.DRIVING_LICENCE,
                          kycId
                        )
                      }
                      disabled={
                        !kycId ||
                        (verificationDetail?.verificationStatus !==
                          KycVerificationStatus.FAILURE &&
                          verificationDetail?.verificationStatus !==
                            KycVerificationStatus.UNVERIFIED)
                      }
                    />
                  )}
                </>
              ) : isPresent ? (
                <>
                  {!isVerified &&
                    (verificationDetail?.verificationStatus ===
                      KycVerificationStatus.FAILURE ||
                      verificationDetail?.verificationStatus ===
                        KycVerificationStatus.UNVERIFIED) && (
                      <Stack gap="5px">
                        {!isFunctionalityBlocked && (
                          <CustomButton
                            variant="outlined"
                            sx={{ width: "100%" }}
                            label={"Manually Verify"}
                            onClick={() =>
                              handleManualKycVerification(
                                KycTypes.DRIVING_LICENCE,
                                kycId
                              )
                            }
                            disabled={
                              verificationDetail?.verificationStatus !==
                              KycVerificationStatus.FAILURE
                            }
                          />
                        )}
                      </Stack>
                    )}
                  {/* Unverify Documents */}
                  {!isFunctionalityBlocked &&
                    isVerified &&
                    verificationDetail?.verificationMode !==
                      KycVerificationMode.DIGITAL && (
                      <CustomButton
                        variant="outlined"
                        sx={{ width: "100%", padding: "0 20px" }}
                        label={"Unverify"}
                        onClick={() => toggleUnverifyDlConfirmation()}
                        wrapperStyles={{ width: "100%" }}
                        /* disabled={
                    !kycId || verificationDetail?.verificationStatus !==  KycVerificationStatus.FAILURE
                  } */
                      />
                    )}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Stack>
        </>
      )}

      {/* kyc delete confirmation */}
      {openDeleteConfirmation && (
        <>
          {/* confirnmation dialog on status change  */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.deleteDLKyc}
            open={openDeleteConfirmation}
            handleClose={() => toggleDeleteDlConfirmation()}
            handleYes={() =>
              handleDeleteCustomerKyc(KycTypes.DRIVING_LICENCE, kycId)
            }
          />
        </>
      )}
      {/* kyc unverify confirmation */}
      {openUnverifyConfirmation && (
        <>
          {/* confirnmation dialog on status change */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.unverifyDLKyc}
            open={openUnverifyConfirmation}
            handleClose={() => toggleUnverifyDlConfirmation()}
            handleYes={() =>
              handleUnverifyCustomerKyc(KycTypes.DRIVING_LICENCE, kycId)
            }
          />
        </>
      )}
    </>
  );
};
