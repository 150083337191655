import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import en from "../../../../locale/en.json";
import { RootState } from "../../../../redux/reducers";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import { checkPhone } from "../../../../utils/regex";
import { GENDERS, typographyConstants } from "../../../constants/constants";
import {
  FemaleIcon,
  MailIcon,
  MaleIcon,
  MobileIcon,
  OthersIcon,
} from "../../../constants/exportImages";
import {
  registerCustomer,
  verifyCustomerOTP,
  resendCustomerOTP,
  resetInvalidOtp,
} from "../../../redux/actions/customerManagementActions";
import {
  isAlphaNumericString,
  validate,
  isAlphaNumericWithSpacesString,
  isAlphabetWithSpacesString,
} from "../../../utils/helper";
import Styles from "../Overview/css/customerManagement.module.css";
import OTPModalComponent from "../../../../sharedComponents/molecules/Modals/OTPModal";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import RbackHelper from "../../../../utils/helperRBAC";
import {
  CustomerFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
import { AccessDenied } from "../../../../screens";
import {
  MaleTypeIcon,
  FemaleTypeIcon,
  OthersTypeIcon,
} from "../../../assests/svgComponents/genderIcons";
import { colors } from "../../../../themes/colors";
import { GenderSelection } from "../../../../sharedComponents/templates/GenderSelection";
const RegisterUserComponent = () => {
  const initialState = {
    mobileNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: GENDERS.MALE,
    otp: "",
  };
  const errorInitialState = {
    mobileNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    otp: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isOtpSent,
    isOTPInvalid,
    isOTPMatched,
    addCustomerLoader,
    registeredCustomerDetails,
    isOtpResent,
  } = useSelector((state: RootState) => state.customerManagementReducer);
  // Add user
  const [registerUserFields, setRegisterUserFields] = useState({
    ...initialState,
  });
  const [errorFields, setErrorFields] = useState(errorInitialState);
  const [sendOTPModalOpen, setSendOTPModalOpen] = useState<Boolean>(false);
  const [otp, setOtp] = useState("");
  const [showResetOtpBtn, setShowResetOtpBtn] = useState(false);

  const genderOptions = [
    {
      key: GENDERS.MALE,
      label: en.NewManualBooking.Male,
      style: { borderRadius: "20px 0px 0px 20px" },
      startIcon: <MaleTypeIcon />,
    },
    {
      key: GENDERS.FEMALE,
      label: en.NewManualBooking.Female,
      style: {},
      startIcon: <FemaleTypeIcon />,
    },
    {
      key: GENDERS.OTHERS,
      label: en.NewManualBooking.Others,
      style: { borderRadius: "0px 20px 20px 0px" },
      startIcon: <OthersTypeIcon />,
    },
  ];

  const setClearRegisterFields = () => {
    setRegisterUserFields(initialState);
  };

  // Handle send otp click
  const validateRegisterUserDetails = () => {
    let payloadObj = {
      mobileNumber: registerUserFields?.mobileNumber,
      email: registerUserFields.email,
      firstName: registerUserFields.firstName,
      lastName: registerUserFields.lastName,
      gender: registerUserFields.gender,
    };
    // validate form
    const validatedFields = validate(payloadObj, errorInitialState);
    setErrorFields({ ...errorInitialState, ...validatedFields.errorFields });

    if (validatedFields.error === false) {
      const payload = {
        data: payloadObj,
      };
      dispatch(registerCustomer(payload));

      setSendOTPModalOpen(true);
    }
  };

  // Handle each field change
  const handleRegisterUserFieldChange = useCallback(
    (field: string, value: any) => {
      let payloadObj = {
        [field]: value,
      };
      /*  let payloadObj = {
        mobileNumber:
          field === "mobileNumber" ? value : registerUserFields?.mobileNumber,
        email: field === "email" ? value : registerUserFields.email,
        firstName: field === "firstName" ? value : registerUserFields.firstName,
        lastName: field === "lastName" ? value : registerUserFields.lastName,
        gender: field === "gender" ? value : registerUserFields.gender,
      }; */
      // validate form
      const validatedFields = validate(payloadObj, errorInitialState);
      setErrorFields((prev: any) => {
        return { ...prev, ...validatedFields.errorFields };
      });

      if (field === "mobileNumber") {
        const isValid = checkPhone(value);
        if (!isValid && value !== "") return false; // Accepting only number Or empty string
        if (value.length > 10) return false;
      }

      if (field === "firstName" || field === "lastName") {
        if (value && !isAlphabetWithSpacesString(value)) return false;
        if (value.length > 30) return false;
      }

      // set local state
      setRegisterUserFields((prevState: any) => {
        if (field === "firstName" || field === "lastName") {
          let prevLength = prevState[field]?.length;
          let valueLength = prevState[field]?.length;

          if (
            prevLength &&
            /\s/.test(prevState[field][prevLength - 1]) &&
            /\s/.test(value[valueLength])
          )
            return {
              ...prevState,
            };
        }
        return {
          ...prevState,
          [field]: prevState[field] ? value : value.trim(),
        };
      });
    },
    [
      registerUserFields?.mobileNumber,
      registerUserFields.email,
      registerUserFields.firstName,
      registerUserFields.lastName,
      registerUserFields.gender,
    ]
  );

  // Handle otp modal close
  const handleCloseSendOTPModal = () => {
    setSendOTPModalOpen(false);
    setRegisterUserFields((prevState: any) => ({
      ...prevState,
      otp: "",
    }));
    setErrorFields(errorInitialState);
    setOtp("");
    // dispatch to reset isInvalidOtp
    dispatch(resetInvalidOtp());
  };

  // Handle otp change
  const handleOtpFieldChange = () => {
    setRegisterUserFields((prevState: any) => ({
      ...prevState,
      otp: "",
    }));
  };

  const showResetButtonHandler = () => {
    setShowResetOtpBtn(true);
  };

  // Handle otp submit
  const handleOtpModalSubmit = (otpValue: any) => {
    if (!otpValue || otpValue.length < 6) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        otp: en.errorMessages.OtpError,
      }));
    } else {
      setErrorFields((prevState: any) => ({
        ...prevState,
        otp: "",
      }));
      const payload = {
        navigate,
        data: {
          token: registeredCustomerDetails?.token,
          otp: otpValue,
          customerId: registeredCustomerDetails?.customerId,
        },
      };

      // verify otp api
      dispatch(verifyCustomerOTP(payload));
    }
  };

  // Resend otp click handler
  const resendOtpClickHandler = (e: any) => {
    e.preventDefault();
    dispatch(
      resendCustomerOTP({
        token: registeredCustomerDetails?.token,
      })
    );
    // reset otp error field
    setErrorFields((prevState: any) => ({
      ...prevState,
      otp: "",
    }));
    // reset otp
    setOtp("");
    setShowResetOtpBtn(false);
  };

  // screen level lifecycle
  useEffect(() => {
    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.CUSTOMER_ADD));
    // cleanup
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, []);

  return (
    <>
      {RbackHelper.isAccessRightsProvided(
        modules.CUSTOMER_MANAGEMENT,
        CustomerFunctionalities.ADD_CUSTOMER
      ) ? (
        <>
          <Stack gap="30px">
            <Stack gap="32px">
              <Stack gap="4vh" width="70%">
                <Typography variant={typographyConstants.HEADINGXL}>
                  Add Customer
                </Typography>
                <Box className={Styles.userDetails}>
                  {/* Mobile number */}
                  <Box width="50%">
                    <Input
                      iconStart={MobileIcon}
                      value={registerUserFields.mobileNumber}
                      placeholder={
                        en.CustomerManagement.SearchCustomerPlaceholder
                      }
                      label={en.CustomerManagement.SearchCustomerLabel}
                      errormessage={
                        errorFields.mobileNumber &&
                        en.errorMessages.requiredField
                      }
                      width="100%"
                      onChange={(e: any) =>
                        handleRegisterUserFieldChange(
                          "mobileNumber",
                          e.target.value
                        )
                      }
                    />
                  </Box>

                  {/* Email */}
                  <Box width="50%">
                    <Input
                      iconStart={MailIcon}
                      value={registerUserFields.email}
                      placeholder={en.NewManualBooking.EnterEmail}
                      label={en.NewManualBooking.EnterEmail}
                      errormessage={errorFields.email && errorFields.email}
                      sx={{ width: "33%" }}
                      width="100%"
                      onChange={(e: any) =>
                        handleRegisterUserFieldChange("email", e.target.value)
                      }
                    />
                  </Box>
                </Box>
              </Stack>
              <Stack gap="4vh" width={"70%"}>
                <Box className={Styles.userDetails}>
                  {/* Firstname */}
                  <Box width="50%">
                    <Input
                      value={registerUserFields.firstName}
                      placeholder={en.NewManualBooking.EnterFirstName}
                      label={en.NewManualBooking.EnterFirstName}
                      errormessage={
                        errorFields.firstName && errorFields.firstName
                      }
                      width="100%"
                      sx={{ width: "33%" }}
                      onChange={(e: any) =>
                        handleRegisterUserFieldChange(
                          "firstName",
                          e.target.value
                        )
                      }
                    />
                  </Box>

                  {/* Lastname */}
                  <Box width="50%">
                    <Input
                      value={registerUserFields.lastName}
                      placeholder={en.NewManualBooking.EnterLastName}
                      label={en.NewManualBooking.EnterLastName}
                      errormessage={
                        errorFields.lastName && errorFields.lastName
                      }
                      width="100%"
                      sx={{ width: "33%" }}
                      onChange={(e: any) =>
                        handleRegisterUserFieldChange(
                          "lastName",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                </Box>
              </Stack>

              {/* Gender */}
              <GenderSelection
                handleChange={
                  handleRegisterUserFieldChange
                }
                fields={registerUserFields}
                errorFields={errorFields}
              />
            </Stack>

            {/* Send OTP */}
            <Box>
              <CustomButton
                label={en.CustomerManagement.SendOTP}
                variant="outlined"
                sx={{ width: "300px" }}
                onClick={validateRegisterUserDetails}
                disabled={addCustomerLoader}
              />
            </Box>
          </Stack>

          {/* OTP modal */}
          {isOtpSent && sendOTPModalOpen && (
            <OTPModalComponent
              fields={registerUserFields}
              setOtp={setOtp}
              otpValue={otp}
              errorFieldsOtp={errorFields?.otp}
              openModal={sendOTPModalOpen}
              setOpenModal={setSendOTPModalOpen}
              handleCloseSendOTPModal={handleCloseSendOTPModal}
              handleOtpModalSubmit={handleOtpModalSubmit}
              isOTPInvalid={isOTPInvalid}
              ResendOtpClickHandler={resendOtpClickHandler}
              isOtpResent={isOtpResent}
              showResetOtpBtn={showResetOtpBtn}
              showResetButtonHandler={showResetButtonHandler}
            />
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default RegisterUserComponent;
