import { DOCUMENT_UPLOAD_ACTIONS } from "../../actions/actionTypes";
interface initialState {
  uploadLoader: boolean;
  signedUrlFailedFiles: object[];
  linearProgressValue: number;
  docUploadSuccess: boolean;
  docDeleteSuccess: boolean;
}
const initialState: initialState = {
  uploadLoader: false,
  signedUrlFailedFiles: [],
  linearProgressValue: 0,
  docUploadSuccess: false,
  docDeleteSuccess: false,
};
const commonReducerX = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_REQUESTED:
      return {
        ...state,
        uploadLoader: true,
        linearProgressValue: 30,
        docUploadSuccess: false,
      };
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        linearProgressValue: 100,
        docUploadSuccess: true,
      };
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_UPLOAD_FAILED:
      return { ...state, uploadLoader: false };
    case DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_REQUESTED:
      return { ...state, linearProgressValue: 85, docUploadSuccess: false };
    case DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_SUCCESS:
      return { ...state, docUploadSuccess: true, linearProgressValue: 95 };
    case DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_FAILED:
      return { ...state, docUploadSuccess: false };
    case DOCUMENT_UPLOAD_ACTIONS.GET_SIGNED_URLS_ATTACHMENT:
      return {
        ...state,
        signedUrlFailedFiles: [],
        uploadLoader: true,
        linearProgressValue: 10,
        docUploadSuccess: false,
      };
    case DOCUMENT_UPLOAD_ACTIONS.PUT_ATTACHMENT_ON_SIGNED_URL:
      return { ...state, linearProgressValue: 70 };
    case DOCUMENT_UPLOAD_ACTIONS.PUT_ATTACHMENT_ON_SIGNED_URL_FAILED:
      let arr = state.signedUrlFailedFiles;
      arr.push(payload);
      return { ...state, signedUrlFailedFiles: arr };
    case DOCUMENT_UPLOAD_ACTIONS.CLEAR_DOCUMENT_UPLOAD_REDUX:
      return {
        ...state,
        docUploadSuccess: false,
        docDeleteSuccess: false,
        signedUrlFailedFiles: [],
      };
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_REQUESTED:
      return { ...state, docDeleteSuccess: false };
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_SUCCESS:
      return { ...state, docDeleteSuccess: true };
    case DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_FAILED:
      return { ...state, docDeleteSuccess: false };
    default:
      return { ...state };
  }
};

export default commonReducerX;
