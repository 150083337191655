import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomMultiSelect from "../../../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { colors } from "../../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import {
  appliesTo,
  typographyConstants,
} from "../../../../../constants/constants";
import {
  AllVendorsIcon,
  CityInputIcon,
  LocationIcon,
  WarehouseIcon,
} from "../../../../../constants/exportImages";
import rentalEn from "../../../../../locale/rental-en.json";
import {
  addNewRoleRowAction,
  deleteRoleRowAction,
  handleChangeAction,
} from "../../../../../redux/actions/businessManagementActions";
import { StyleObject } from "./StyleObject";

const { roleManagement, buttonLabels, global } = rentalEn;

const AssignRolesSection = (props: any) => {
  const { setSelectedTab, handleAssignRole, validateData } = props;
  const dispatch = useDispatch();

  const {
    initialAssignRoleState,
    rolesListData,
    userDetailsByUserIdDataLoader,
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);

  return (
    <Stack spacing={2} pt={3} alignItems={"center"}>
      {userDetailsByUserIdDataLoader ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={StyleObject?.ars_topBox}>
            {/* Add New Role Button */}
            <CustomButton
              label={roleManagement?.addNewRole}
              variant="outlined"
              onClick={() => {
                dispatch(addNewRoleRowAction());
              }}
              sx={{ padding: "3px 22px" }}
              wrapperStyles={{ alignSelf: "flex-start" }}
            />
          </Box>

          <Box sx={StyleObject?.ars_headingTopBox}>
            <Stack spacing={0.1} direction={"row"} my={1}>
              <Box width={23 / 100} sx={StyleObject?.ars_headingRole}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {roleManagement?.role}
                </Typography>
              </Box>
              <Box width={23 / 100} sx={StyleObject?.ars_headingState}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.state}
                </Typography>
              </Box>
              <Box width={23 / 100} sx={StyleObject?.ars_headingCity}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.city}
                </Typography>
              </Box>
              <Box width={23 / 100} sx={StyleObject?.ars_headingHub}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.hub}
                </Typography>
              </Box>
              <Box width={8 / 100} sx={StyleObject?.ars_headingAction}>
                <Typography
                  variant={typographyConstants?.HEADING}
                  sx={{ color: colors?.white }}
                >
                  {global?.action}
                </Typography>
              </Box>
            </Stack>

            {/* *** */}

            {initialAssignRoleState
              ?.filter((role: any) => role.operation !== "delete")
              .map((item: any, index: number) => {
                return (
                  <Stack spacing={0.1} direction={"row"} key={index}>
                    {/* Role */}
                    {item?.operation !== "update" ? (
                      <Box width={23 / 100}>
                        <CustomSelect
                          required
                          icon={AllVendorsIcon}
                          placeholder={roleManagement?.selectRole}
                          defaultKey={"name"}
                          value={
                            item?.isExisting
                              ? item?.roleId?.name
                              : isArrayNotEmpty(item?.rolesList)
                              ? item?.roleId?.name
                              : ""
                          }
                          choice={
                            item?.rolesList && isArrayNotEmpty(item?.rolesList)
                              ? item?.rolesList
                              : initialAssignRoleState?.length === 1 &&
                                item?.operation === "add" &&
                                isArrayNotEmpty(rolesListData)
                              ? rolesListData
                              : []
                          }
                          handleChange={(value: any) => {
                            const payload: any = {
                              key: "roleId",
                              value: value,
                              uuid: item?.uuid,
                              initialAssignRoleState: initialAssignRoleState,
                            };
                            dispatch(handleChangeAction(payload));
                          }}
                          disabled={item?.isExisting}
                        />
                      </Box>
                    ) : (
                      <Box width={23 / 100} sx={StyleObject?.updateRoleBox}>
                        <Typography
                          variant={typographyConstants?.BODY}
                          color={colors?.black}
                        >
                          <Tooltip title={item?.roleId?.name} arrow>
                            {item?.roleId?.name?.length > 20
                              ? `${item?.roleId?.name?.substring(
                                  0,
                                  20
                                )}${" "}${"..."}`
                              : item?.roleId?.name}
                          </Tooltip>
                        </Typography>
                      </Box>
                    )}

                    {/* State */}
                    {item?.roleId?.appliesTo === appliesTo?.COUNTRY ||
                    item?.roleId?.appliesTo === appliesTo?.STATE ||
                    item?.roleId?.appliesTo === appliesTo?.CITY ||
                    item?.roleId?.appliesTo === appliesTo?.BRANCH ? (
                      <Box width={23 / 100}>
                        <CustomMultiSelect
                          icon={LocationIcon}
                          placeholder={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? global?.allState
                              : global?.selectState
                          }
                          selectAllText={global.allState}
                          value={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? [global?.allState]
                              : item?.selectedState
                          }
                          choice={
                            isArrayNotEmpty(item?.state) ? item?.state : []
                          }
                          defaultKey="name"
                          displayNameKey="displayName"
                          handleChange={(value: any) => {
                            const payload: any = {
                              key: "STATE",
                              value: value,
                              uuid: item?.uuid,
                              initialAssignRoleState: initialAssignRoleState,
                            };
                            dispatch(handleChangeAction(payload));
                          }}
                          disabled={
                            item?.operation === "update" &&
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? true
                              : item?.operation === "update"
                              ? false
                              : !isArrayNotEmpty(item?.state) ||
                                !item?.roleId?.name ||
                                item?.roleId?.appliesTo === appliesTo?.COUNTRY
                          }
                        />
                      </Box>
                    ) : (
                      <Box width={23 / 100}></Box>
                    )}

                    {/* City */}
                    {item?.roleId?.appliesTo === appliesTo?.COUNTRY ||
                    item?.roleId?.appliesTo === appliesTo?.CITY ||
                    item?.roleId?.appliesTo === appliesTo?.BRANCH ? (
                      <Box width={23 / 100}>
                        <CustomMultiSelect
                          icon={CityInputIcon}
                          placeholder={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? global?.allCities
                              : global?.selectCity
                          }
                          selectAllText={global.allCities}
                          value={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? [global?.allCities]
                              : item?.selectedCity
                          }
                          choice={isArrayNotEmpty(item?.city) ? item?.city : []}
                          defaultKey="name"
                          displayNameKey="displayName"
                          handleChange={(value: any) => {
                            const payload: any = {
                              key: "CITY",
                              value: value,
                              uuid: item?.uuid,
                              initialAssignRoleState: initialAssignRoleState,
                            };
                            dispatch(handleChangeAction(payload));
                          }}
                          disabled={
                            item?.operation === "update" &&
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? true
                              : item?.operation === "update"
                              ? false
                              : !isArrayNotEmpty(item?.city) ||
                                !item?.selectedState?.length ||
                                item?.roleId?.appliesTo === appliesTo?.COUNTRY
                          }
                        />
                      </Box>
                    ) : (
                      <Box width={23 / 100}></Box>
                    )}

                    {/* Hub */}
                    {item?.roleId?.appliesTo === appliesTo?.COUNTRY ||
                    item?.roleId?.appliesTo === appliesTo?.BRANCH ? (
                      <Box width={23 / 100}>
                        <CustomMultiSelect
                          icon={WarehouseIcon}
                          placeholder={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? global?.allHubs
                              : global?.selectHub
                          }
                          selectAllText={global.AllHubs}
                          value={
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? [global?.allHubs]
                              : item?.selectedBranch
                          }
                          choice={
                            isArrayNotEmpty(item?.branch) ? item?.branch : []
                          }
                          defaultKey="name"
                          displayNameKey="displayName"
                          handleChange={(value: any) => {
                            const payload: any = {
                              key: "BRANCH",
                              value: value,
                              uuid: item?.uuid,
                              initialAssignRoleState: initialAssignRoleState,
                            };
                            dispatch(handleChangeAction(payload));
                          }}
                          disabled={
                            item?.operation === "update" &&
                            item?.roleId?.appliesTo === appliesTo?.COUNTRY
                              ? true
                              : item?.operation === "update"
                              ? false
                              : !isArrayNotEmpty(item?.branch) ||
                                !item?.selectedCity?.length ||
                                item?.roleId?.appliesTo === appliesTo?.COUNTRY
                          }
                        />
                      </Box>
                    ) : (
                      <Box width={23 / 100}></Box>
                    )}

                    {/* Delete Row Button */}
                    {item?.operation === "update" ||
                    initialAssignRoleState?.length > 1 ? (
                      <Box
                        width={8 / 100}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CustomIconButton
                          onClick={() => {
                            dispatch(deleteRoleRowAction(item?.uuid));
                          }}
                          children={<DeleteIcon color="error" />}
                        />
                      </Box>
                    ) : null}
                  </Stack>
                );
              })}
          </Box>

          <Box sx={StyleObject?.ars_buttonWrapper}>
            <Stack spacing={1} direction={"row"} justifyContent={"center"}>
              {/* Previous Button */}
              <Box>
                <CustomButton
                  label={buttonLabels?.previous}
                  variant="outlined"
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                  sx={{ padding: "3px 22px", minWidth: 200 }}
                  wrapperStyles={{ alignSelf: "flex-start" }}
                />
              </Box>

              {/* Assign Role Button */}
              <Box>
                <CustomButton
                  label={roleManagement?.saveUser}
                  variant="outlined"
                  onClick={() => handleAssignRole(initialAssignRoleState)}
                  sx={{ padding: "3px 22px", minWidth: 200 }}
                  wrapperStyles={{ alignSelf: "flex-start" }}
                  disabled={!validateData(initialAssignRoleState)}
                />
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default AssignRolesSection;
