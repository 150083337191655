import { FEEDBACK_ACTIONS } from "../../actions/actionTypes";
import { feedbackInterface, cityInterface } from "../actionInterfaces";

const initialState = {
  payload: {},
  loading: true,
  feedbackData: null,
  errorMessage: "",
  loading2 : false,
  newFeedBackArray : [],
  totalItems : 0,
  userFeedBack: {},
  loading3 : false,
  userFeedBackByBookingId : [],
  loading4 :false,
  feedbackSubmitted :false
};


const feedbackReducer = (state = initialState, action: feedbackInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case FEEDBACK_ACTIONS.FEEDBACKS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FEEDBACK_ACTIONS.FEEDBACKSFETCH_SUCCESS:
      return {
        ...state,
        feedbackData: payload?.Result,
        loading: false,
      };
    case FEEDBACK_ACTIONS.FEEDBACKSFETCH_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading: false,
      };

      case FEEDBACK_ACTIONS.FEEDBACKS_DATA_REQUESTED:
        return {
          ...state,
          loading: true,
        };
      case FEEDBACK_ACTIONS.FEEDBACKS_DATA_SUCCESS:
        return {
          ...state,
          newFeedBackArray: payload?.newFeedBacksData,
          loading: false,
          totalItems : payload?.totalItems
        };
      case FEEDBACK_ACTIONS.FEEDBACKS_DATA_FAILED:
        return {
          ...state,
          loading: false,
        };

        case FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_REQUESTED:
          return {
            ...state,
            loading3 :true
           
          };
        case FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_SUCCESS:
          return {
            ...state,
            loading3 :false,
            userFeedBack: payload?.feedBacksById,
           
          };
        case FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_FAILED:
          return {
            ...state,
            loading3 :false,
            userFeedBack : {}
          };

          case FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_REQUESTED:
            return {
              ...state,
              loading3 :true
             
            };
          case FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_SUCCESS:
            return {
              ...state,
              loading3 :false,
              userFeedBackByBookingId: payload?.feedBacksByBookingId,
             
            };
          case FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_FAILED:
            return {
              ...state,
              loading3 :false,
              userFeedBackByBookingId : []
            };

            case FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_REQUESTED:
              return {
                ...state,
                loading4 :true

               
              };
            case FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_SUCCESS:
              return {
                ...state,
                loading4 :false,
                feedbackSubmitted: true,
               
              };
            case FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_FAILED:
              return {
                ...state,
                loading4 :false,
                feedbackSubmitted: false,
              };
              case FEEDBACK_ACTIONS.CLEAR_EXECUTIVE_FEEDBACK:
                return {
                  ...state,
                  loading4 :false,
                  feedbackSubmitted: false,
                };
                case FEEDBACK_ACTIONS.CLEAR_BOOKING_FEEDBACK:
                  return {
                    ...state,
                    loading3 :false,
              userFeedBackByBookingId : []
                  };
        
      
    
  

    default:
      return { ...prevState };
  }
};

//return feedback table data

export default feedbackReducer;
