import {
  getAvailableVehicleListPayload,
  startRidePayloadX,
} from "../../../interfaces/bookingInterfaces";
import * as actionTypes from "../actionTypes";

export const fetchBookingList = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X.FETCH_BOOKING_LIST_X_REQUESTED,
  payload,
});
export const getBookingStatistics = () => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS_X
    .GET_BOOKING_STATISTICS_X_REQUESTED,
});

export const userStartRideX = (payload: startRidePayloadX) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_REQUESTED,
  payload,
});

export const getAvailableVehicleList = (
  payload: getAvailableVehicleListPayload
) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X
    .GET_AVAILABLE_VEHICLE_LIST_REQUESTED,
  payload,
});

export const getBookingDetailsX = (payload: any) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_REQUESTED,
  payload,
});

export const getBookingStatus = (payload: any) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_STATUS_X_REQUESTED,
  payload,
});

export const getPaymentPendingX = (payload: any) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X
    .GET_PENDING_PAYMENT_STATUS_X_REQUESTED,
  payload,
});

export const cancelStatusTask = () => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_STATUS_X_TASK,
});

export const cancelBookingX = (payload: any) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_REQUESTED,
  payload,
});

export const closeBookingX = (payload: any) => ({
  type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CLOSE_BOOKING_X_REQUESTED,
  payload
});

// Booking list 

export const updatePageX = (payload: any) => ({
  type: actionTypes.BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_PAGE_X,
  payload
});

export const updatePageSizeX = (payload: any) => ({
  type: actionTypes.BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_PAGE_SIZE_X,
  payload
});

export const updateStatusX = (payload: any) => ({
  type: actionTypes.BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_STATUS_X,
  payload
});

export const updateSearchX = (payload: any) => ({
  type: actionTypes.BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_SEARCH_X,
  payload
});

export const updateSortingX = (payload: any) => ({
  type: actionTypes.BOOKING_TABLE_FILTERS_X_ACTIONS.UPDATE_SORTING_X,
  payload
});

