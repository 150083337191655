import "../newAuthCss/global.css";
import "../newAuthCss/layout.css";
import "../../../css/customcss/font.css";
import { PasswordComplexity } from "../../../constants/enum";
import en from "../../../locale/en.json";
function ProgressBar(props: any) {
  
  return (
    <>
      {props.passComplexity === PasswordComplexity.WEEK_PASSWORD ? (
        <>
          <div className="row align-items-center pw-status mb-1">
            <div className="col-md-10">
              <div
                className="progress"
                role="progressbar"
                aria-label="Danger example"
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-danger"
                  style={{ width: "25%" }}
                ></div>
              </div>
            </div>
            <div className="col-md-2">
              <p className="text-end wepw">{en.newAuth.week}</p>
            </div>
          </div>
        </>
      ) : null}

      {props.passComplexity == PasswordComplexity.MEDIUM_PASSWORD ? (
        <>
          <div className="row align-items-center pw-status mb-1">
            <div className="col-md-10">
              <div
                className="progress"
                role="progressbar"
                aria-label="Warning example"
                aria-valuenow={75}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-warning"
                  style={{ width: "50%" }}
                ></div>
              </div>
            </div>
            <div className="col-md-2">
              <p className="text-end gdpw">{en.newAuth.medium}</p>
            </div>
          </div>
        </>
      ) : null}

      {props.passComplexity === PasswordComplexity.STRONG_PASSWORD ? (
        <>
          <div className="row align-items-center pw-status mb-1">
            <div className="col-md-10">
              <div
                className="progress"
                role="progressbar"
                aria-label="Info example"
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-info"
                  style={{ width: "100%" }}
                ></div>
              </div>
            </div>
            <div className="col-md-2">
              <p className="text-end stpw">{en.newAuth.strong}</p>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
export { ProgressBar };
