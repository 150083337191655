import { API_URLS, BASE_URL, customAxios, versioning } from "../config";


const getTaskList = (payload: any) => {
    try {
      let url = BASE_URL+ API_URLS.getTaskList;
      if (payload?.search) url += `search=${payload?.search}&`;
      if (payload?.page) url += `page=${payload?.page}&`;
      if (payload?.size) url += `size=${payload?.size}`;
  
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };



  const getTaskDetailsById = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getTaskDetailsById;
      url += `/${payload?.taskId}`;
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const getTaskListSequence = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getTaskListSequence;
      url += `/${payload?.warehouseId}`; 
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const getTaskWarehouseList = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getTaskWarehouseList;

      if(payload?.taskType){
        url +=`/${payload?.taskType}`
      }
      if (payload?.warehouseId!==0){
        url += `/${payload?.warehouseId}`;
      }
      
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const createTask = (payload: any) => {
    try {
      let url = BASE_URL +  API_URLS.createTask;
      return customAxios.post(url, payload).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const editTask = (payload: any, _id:any) => {
    try {
      let url = BASE_URL +  API_URLS.editTask;
      url += `/${_id}`;
      return customAxios.put(url, payload).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };


  const editTaskSequence = (payload: any, id:any, taskType:any) => {
    try {
      let url = BASE_URL +  API_URLS.editTaskSequence;
      url += `/${id}`;
      url+= `/${taskType}`
      return customAxios.put(url, payload).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const deleteTask = (payload: any) => {
    try {
      let url =  BASE_URL + API_URLS.deleteTask;
      if (payload.id) url += `${payload.id}`;
      return customAxios.delete(url, payload).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const getTaskStatus = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getTaskStatus;
      url += `/${payload?.bookingId}`;
      return customAxios.get(url).catch((ex) => ex);
      
    } catch (ex) {
      return ex;
    }
  };

  const getTaskSummary = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getTaskSummary;
      url += `=${payload?.bookingId}`;
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };

  const getUserList = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.getUserList;
      url += `?branchId=${payload?.branchId}`;
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };



  const postTaskAsignment = (payload: any) => {
    try {
      let url = BASE_URL + API_URLS.postTaskAsignment;
      url += ""
      return customAxios.post(url,payload).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };


  const getTaskSuggestion = (payload: any) => {
    try {
      let url = BASE_URL+ API_URLS.taskSuggestion;
     
  
      return customAxios.get(url).catch((ex) => ex);
    } catch (ex) {
      return ex;
    }
  };
  export default {
    getTaskList,
    getTaskDetailsById,
    createTask,
    editTask,
    deleteTask,
    getTaskListSequence,
    getTaskWarehouseList,
    editTaskSequence,
    getTaskStatus,
    getTaskSummary,
    getUserList,
    postTaskAsignment,
    getTaskSuggestion
  }