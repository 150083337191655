import { GenericObject } from "../../../constants/constants";
import * as actionTypes from "../../actions/actionTypes";

interface OperatorManagementState {
  operatorLeadList: GenericObject;
  loading: boolean;
  operatorLeadDetails: GenericObject;
  operatorList: GenericObject;
  operatorDetails: GenericObject;
  agreement: GenericObject;
  isSavingDocument: boolean;
  isSubmitingDocument: boolean;
  isCompletingKycAction: boolean;
  modifyOperatorLoader: boolean;
  errorOperator404: GenericObject;
  eSignLoader: boolean;
  emailLoader: boolean;
  saveOperatorLoader: boolean;
}
const initialState: OperatorManagementState = {
  operatorLeadList: {},
  loading: false,
  operatorLeadDetails: {},
  operatorList: {},
  operatorDetails: {},
  agreement: {},
  isSavingDocument: false,
  isSubmitingDocument: false,
  isCompletingKycAction: false,
  modifyOperatorLoader: false,
  errorOperator404: {},
  eSignLoader: false,
  emailLoader: false,
  saveOperatorLoader: false,
};

const newOperatorManagement = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LEAD_LIST_SUCCESS:
      return {
        ...state,
        operatorLeadList: payload,
        operatorLeadDetails: {},
        loading: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LEAD_LIST_FAILED:
      return {
        ...state,
        loading: false,

        operatorLeadDetails: {},
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_DETAILS_REQUESTED:
      return {
        ...state,
        operatorLeadDetails: {},
        errorOperator404: {},
        // loading: true, // removed because of bug
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        operatorLeadDetails: payload,
        errorOperator404: {},
        loading: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_LEAD_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        errorOperator404: payload,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_LEAD_DETAILS_REQUESTED:
      return {
        ...state,
        saveOperatorLoader: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        saveOperatorLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_LEAD_DETAILS_FAILED:
      return {
        ...state,
        saveOperatorLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .INITIATE_ESIGN_OPERATOR_REQUESTED:
      return {
        ...state,
        eSignLoader: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .INITIATE_ESIGN_OPERATOR_SUCCESS:
      return {
        ...state,
        eSignLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.INITIATE_ESIGN_OPERATOR_FAILED:
      return {
        ...state,
        eSignLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .VERIFY_EMAIL_OPERATOR_REQUESTED:
      return {
        ...state,
        emailLoader: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.VERIFY_EMAIL_OPERATOR_SUCCESS:
      return {
        ...state,
        emailLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.VERIFY_EMAIL_OPERATOR_FAILED:
      return {
        ...state,
        emailLoader: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_LEAD_OPERATOR_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.APPROVE_LEAD_OPERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.APPROVE_LEAD_OPERATOR_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        operatorList: payload,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPEARATOR_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_DOCUMENTS_REQUESTED:
      return {
        ...state,
        isSavingDocument: true,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SUBMIT_OPERATOR_DOCUMENTS_REQUESTED:
      return {
        ...state,
        isSubmitingDocument: true,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SUBMIT_OPERATOR_DOCUMENTS_SUCCESS:
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SUBMIT_OPERATOR_DOCUMENTS_FAILED:
      return {
        ...state,
        isSubmitingDocument: false,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_REJECT_OPERATOR_DOCUMENTS_REQUESTED:
      return {
        ...state,
        isCompletingKycAction: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_REJECT_OPERATOR_DOCUMENTS_SUCCESS:
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .APPROVE_REJECT_OPERATOR_DOCUMENTS_FAILED:
      return {
        ...state,
        isCompletingKycAction: false,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_DETAILS_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_DETAILS_DETAILS_SUCCESS:
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .SAVE_OPERATOR_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSavingDocument: false,
        operatorDetails: payload,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.CLEAR_OPERATOR_DETAILS:
      return {
        ...state,
        loading: false,
        operatorDetails: {},
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .GET_OPERATOR_DETAILS_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_DOCUMENTS_FAILED:
      return {
        ...state,
        isSubmitingDocument: false,
        isSavingDocument: false,
      };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .MODIFY_OPERATOR_CONFIG_REQUESTED:
      return { ...state, modifyOperatorLoader: true };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.MODIFY_OPERATOR_CONFIG_SUCCESS:
      const updatedConfigData = {
        ...state?.operatorDetails,
        operatorConfigDetails: payload?.configuration,
      };
      return {
        ...state,
        modifyOperatorLoader: false,
        operatorDetails: updatedConfigData,
      };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.MODIFY_OPERATOR_CONFIG_FAILED:
      return { ...state, modifyOperatorLoader: false };
    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_REQUESTED:
      return { ...state, loading: true };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_SUCCESS:
      return { ...state, loading: false, agreement: payload };

    case actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_VIEW_AGREEMENT_FAILED:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};

export default newOperatorManagement;
