export interface DefaultProps {
  accessKey: string;
}

export enum AccessTypes {
  update = "edit",
  delete = "delete",
  add = "add",
  view = "view",
  edit = "edit",
}

export interface IDefaultRequestHeaders {
  "x-platform": string;
  "x-channel": string;
  "x-client-id": string;
  "x-bn": string | any;
  "user-agent": string;
  "requestfrom": string;
  "platform": string;
}
