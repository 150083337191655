import { Box, Card, Stack, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import MuiAccordion from "../../../../../sharedComponents/molecules/Accordion/MuiAccordion";
import { colors } from "../../../../../themes/colors";
import {
  BookingStatusEnum,
  GenericObject,
  typographyConstants,
  uploadError,
  vehicleImageUploadType,
  VehicleStatus,
} from "../../../../constants/constants";
import {
  AddDocIcon,
  AllVendorsIcon,
  BikeIconFaded,
  DeleteIcon,
  GreenTickIcon,
  OdometerIcon,
  UploadFileIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { manualBookingActions } from "../../../../redux/actions";
import {
  calculateUpgradeModelCharges,
  clearModifyCharges,
  clearModifyData,
  clearReducerKeys,
  generateDocsSignedUrls,
  generateVehicleSignedUrl,
  getAvailableBookingModels,
  getModificationReasons,
} from "../../../../redux/actions/bookingManagementActions";
import {
  formatString,
  getFormattedINR,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../../utils/helper";
import { checkNumeric } from "../../../../utils/regex";
import { LinearProgressDocUpload } from "../../bookingDetails/components/linearProgress";
import ModifyBookingSummarySideDrawer from "./ModifyBookingSummarySideDrawer";
import UploadVehicleImages from "./UploadModifyVehicleImages";

const errorInitialState = {
  odometerEndReading: "",
  odometerStartReading: "",
  selectedUpgradeVehicle: "",
};

export const UpgradeVehicle = ({
  fields,
  handleChange,
  toggleUpgradeVehicleModal,
  setFields,
}: any) => {
  const {
    bookingDetails = {},
    modificationReasons,
    docUploadLinearProgress,
    bookingVehicleImageLoader,
    modifyBookingChargesLoader,
    modifyBookingCharges,
    availableBookingModels = [],
    bookingModifyLoading,
    paymentLinkQRLoader,
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);

  const { availableModels } = useSelector(
    (state: RootState) => state.newManualBookingReducer
  );
  const dispatch = useDispatch();
  const [errFields, setErrFields] = useState(errorInitialState);
  const [odometerImgLoad, setOdometerImgLoad] = useState(false);
  const [expandVehicleAccordian, setVehicleAccordian] =
    useState<boolean>(false);
  const {
    bookingVehicleDetails = {},
    id: bookingId,
    status: bookingStatus,
  } = bookingDetails;

  // on drop image in dropzone in end ride drawrr
  const onDropStartOdometerReadingImg = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      let files: GenericObject = acceptedFiles[0];
      let fileNames: string = acceptedFiles[0]?.name;
      // let rejectedFiles: object[] = [];

      let error: boolean = false;
      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code) {
          error = true;
          toastr.warning(
            "",
            uploadError[item.errors[0]?.code] ??
              rentalEn?.errorMessages?.SomethingWentWrong
          );
          return;
        }
      });

      if (error) return;
      setOdometerImgLoad(true);
      getSignedUrl(files, fileNames, "startOdometerImgFileName");
    },
    []
  );
  // on drop image in dropzone in end ride drawrr
  const onDropEndOdometerReadingImg = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      let files: any = acceptedFiles[0];
      let fileNames: string = acceptedFiles[0]?.name;
      // let rejectedFiles: object[] = [];

      let error: boolean = false;
      fileRejections.map((item: any) => {
        if (item?.errors[0]?.code) {
          error = true;
          toastr.warning(
            "",
            uploadError[item.errors[0]?.code] ??
              rentalEn?.errorMessages?.SomethingWentWrong
          );
          return;
        }
      });

      if (error) return;
      getSignedUrl(files, fileNames, "endOdometerImgFileName");
    },
    []
  );

  // check if proceed is disabled or not
  const isProceedDisabled = () => {
    if (bookingStatus === BookingStatusEnum.ONGOING) {
      return (
        !fields?.odometerEndReading ||
        !fields?.endOdometerImgFileName ||
        !fields?.upgradeVehicleRegNo?.vehicleId ||
        !fields?.odometerStartReading ||
        !fields?.startOdometerImgFileName ||
        !fields?.vehicleModificationReason?.description ||
        !fields.newVehicleImg?.length ||
        !fields.oldVehicleImg?.length
      );
    } else if (bookingStatus === BookingStatusEnum.UPCOMING)
      return (
        !fields?.selectedUpgradeVehicle?.modelName ||
        !fields?.vehicleModificationReason?.description
      );
  };

  // for Image  dropzone in end ride odometer upload
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropEndOdometerReadingImg,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  // for Image  dropzone in start ride drawer
  const {
    getRootProps: getStartOdometerRootProps,
    getInputProps: getStartOdometerInputProps,
    isDragActive: startOdometerDragActive,
  } = useDropzone({
    onDrop: onDropStartOdometerReadingImg,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  useEffect(() => {
    getAvailableModels();
    dispatch(getModificationReasons());
    return () => {
      dispatch(clearModifyCharges());
      setFields((prev: any) => ({
        ...prev,
        isEndOdometerImgUploaded: false,
        isStartOdometerImgUploaded: false,
        startOdometerImgFileName: "",
        endOdometerImgFileName: "",
        odometerEndReading: "",
        odometerStartReading: "",
        fileName: "",
        vehicleModificationReason: { description: "" },
        selectedUpgradeVehicle: { displayName: "" },
        upgradeVehicleRegNo: {},
        oldVehicleImg: [],
        newVehicleImg: [],
        oldVehicleImgSignedUrl: [],
        newVehicleImgSignedUrl: [],
      }));
      dispatch(clearReducerKeys({ attachmentIds: [] }));
      dispatch(clearModifyData());
    };
  }, []);

  // handle selected vehicle from dropdown
  const getAvailableModels = () => {
    const payloadModels = {
      payload: {
        startDate:
          bookingStatus === BookingStatusEnum.ONGOING
            ? getTimestampAtStartOfDay(new Date())
            : getTimestampAtStartOfDay(bookingDetails?.startDate),
        endDate: getTimestampAtEndOfDay(bookingDetails?.endDate),
        branches: [bookingDetails?.branchDetails?.branchName],
        useCase: bookingDetails?.useCase,
      },
      showError: true,
    };

    dispatch(manualBookingActions.getAvailableModels(payloadModels));
  };

  const toggleVehicleAccordian = () => {
    setVehicleAccordian((prev: any) => !prev);
  };

  // on change of odometer reading in booking details
  const onEndOdometerReadingChange = (value: string) => {
    if (value <= bookingVehicleDetails?.currentReading && value !== "")
      setErrFields((prev: any) => ({
        ...prev,
        odometerReading: `${rentalEn.errorMessages.odometerReadingError} ${
          bookingVehicleDetails?.currentReading +
          " " +
          rentalEn?.global.km.toLowerCase()
        }`,
      }));
    else
      setErrFields((prev: any) => ({
        ...prev,
        odometerEndReading: "",
      }));

    handleChange("odometerEndReading", value);
  };

  //sucess callback function when image upload is successful end ride odometer
  const sucessCallbackSignedUrl = (
    fileName: string,
    key: string,
    signedUrls: GenericObject
  ) => {
    let updatedOdometerData = {};
    if (key !== "startOdometerImgFileName") {
      updatedOdometerData = {
        isEndOdometerImgUploaded: true,
        fileName,
        attachmentId: signedUrls[0]?.id,
      };
    } else {
      updatedOdometerData = {
        isStartOdometerImgUploaded: true,
        fileName,
        attachmentId: signedUrls[0]?.id,
      };
    }

    setFields((prev: any) => ({
      ...prev,
      [key !== "startOdometerImgFileName"
        ? "isEndOdometerImgUploaded"
        : "isStartOdometerImgUploaded"]: true,
      [key]: fileName,
      [key !== "startOdometerImgFileName"
        ? "oldVehicleOdometerData"
        : "newVehicleOdometerData"]: updatedOdometerData,
    }));
    setOdometerImgLoad(false);
  };

  //get signed url for extend ride drawer
  const getSignedUrl = (file: any, fileName: string, key: string) => {
    const payload: any = {
      requestdata: {
        data: {
          type: vehicleImageUploadType.ODOMETER_IMAGES,
          attachments: [fileName],
        },
      },
      filesData: {
        bookingId: bookingId,
        files: [file],
        fileNames: [fileName],
      },
      sucessCallback: (signedUrls: any) => {
        sucessCallbackSignedUrl(fileName, key, signedUrls);
      },
      uploadOnly: true,
    };
    dispatch(generateVehicleSignedUrl(payload));
  };

  // on Imgae click delete in end ride drawer
  const onClickImageDelete = (value: string) => {
    setFields((prev: any) => ({
      ...prev,
      [value === "endOdometerImgFileName"
        ? "isEndOdometerImgUploaded"
        : "isStartOdometerImgUploaded"]: false,
      [value]: "",
    }));
    dispatch(clearReducerKeys({ attachmentIds: [] }));
  };

  const handleUpgradeVehicle = () => {
    if (
      bookingStatus === BookingStatusEnum.ENDED ||
      bookingStatus === BookingStatusEnum.COMPLETED ||
      bookingStatus === BookingStatusEnum.CANCELLED
    ) {
      toastr.error(
        "",
        rentalEn?.errorMessages?.CannotUpgradeVehicleInThisStatus
      );
      return;
    }

    if (
      bookingStatus === BookingStatusEnum.ONGOING &&
      (!fields.odometerEndReading ||
        fields.odometerEndReading === "" ||
        !checkNumeric(fields.odometerEndReading) ||
        fields.odometerEndReading < bookingVehicleDetails.currentReading)
    ) {
      setErrFields((prev: any) => ({
        ...prev,
        odometerEndReading: `${rentalEn?.errorMessages.odometerReadingError} start reading`,
      }));
      return;
    }

    return calculateUpgradeVehicle();
  };

  // calculate upgrade vehicle
  const calculateUpgradeVehicle = () => {
    const payloadata = getUpgradeVehiclePayload();
    dispatch(calculateUpgradeModelCharges(payloadata));
  };

  const getUpgradeVehiclePayload = () => {
    const payload: any = {
      data: {
        name: fields?.selectedUpgradeVehicle?.modelName,
        reasonId: fields?.vehicleModificationReason?.id,
      },
      bookingId,
    };
    if (bookingStatus === BookingStatusEnum.ONGOING) {
      payload.data.vehicle = {
        old: {
          odoMeterDetails: {
            endOdoMeterReading: fields?.odometerEndReading,
            image: fields?.oldVehicleOdometerData?.attachmentId,
          },
        },
        new: {
          id: fields?.upgradeVehicleRegNo?.vehicleId,
          odoMeterDetails: {
            startOdoMeterReading: fields?.odometerStartReading,
            image: fields?.newVehicleOdometerData?.attachmentId,
          },
        },
      };
      if (fields?.oldVehicleImgSignedUrl?.length > 0) {
        const vehicleImages = fields?.oldVehicleImgSignedUrl.reduce(
          (accum: any, item: any) => {
            accum.push(item?.id);
            return accum;
          },
          []
        );
        payload.data.vehicle.old.vehicleImages = vehicleImages;
      }
      if (fields?.newVehicleImgSignedUrl?.length > 0) {
        const vehicleImages = fields?.newVehicleImgSignedUrl.reduce(
          (accum: any, item: any) => {
            accum.push(item?.id);
            return accum;
          },
          []
        );
        payload.data.vehicle.new.vehicleImages = vehicleImages;
      }
    }
    return payload;
  };

  const getVehicleUpgradeDropDown = () => {
    return (
      <CustomSelect
        required
        icon={BikeIconFaded}
        placeholder={rentalEn.bookingManagement.selectVehicleModel}
        value={fields?.selectedUpgradeVehicle?.displayName || ""}
        choice={availableModels}
        defaultKey={"displayName"}
        customRenderKey="planDetails"
        skipData={(data: any) => {
          if (bookingStatus === BookingStatusEnum.UPCOMING)
            return data?.displayName === bookingVehicleDetails.displayName;
          else return false;
        }}
        errormessage={errFields.selectedUpgradeVehicle}
        handleChange={(modal: any) => {
          handleChange("selectedUpgradeVehicle", modal);
          const payload = {
            modelName: modal.modelName,
            bookingId,
          };
          handleChange("upgradeVehicleRegNo", "");
          bookingStatus === BookingStatusEnum.ONGOING &&
            dispatch(getAvailableBookingModels(payload));
        }}
      />
    );
  };

  // get updated available vehicle list
  const getUpdatedAvailableModels = useCallback(() => {
    const vehicleList = [...availableBookingModels];

    const assignedVehiclesList = vehicleList.filter(
      (vehicle) => vehicle.status === VehicleStatus?.ASSIGNED
    );
    const unassignedVehicleList = vehicleList.filter(
      (vehicle) => vehicle.status !== VehicleStatus?.ASSIGNED
    );

    return [...unassignedVehicleList, ...assignedVehiclesList];
  }, [availableBookingModels]);

  // disable assigned vehicles
  const isVehicleSelectable = (data: any) => {
    return data.status === VehicleStatus?.ASSIGNED;
  };

  if (modifyBookingCharges?.charges)
    return (
      <ModifyBookingSummarySideDrawer
        fields={fields}
        handleChange={handleChange}
        togglePaymentModal={toggleUpgradeVehicleModal}
        getUpgradeVehiclePayload={getUpgradeVehiclePayload}
      />
    );

  return (
    <Box
      sx={{
        padding: "18px",
        paddingTop: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "-webkit-fill-available",
      }}
    >
      <Stack>
        <Card>
          <Box
            sx={{
              padding: "10px",
              backgroundColor: colors.light,
            }}
          >
            <Typography variant={typographyConstants.SUBHEADING}>
              {rentalEn?.bookingDetails?.currentVehicleDetails}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: colors.bg_light_gray,
              padding: "10px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography>{rentalEn.bookingDetails.vehicleModel} :</Typography>
              <Typography>{rentalEn.bookingDetails.vehicleNumber} :</Typography>
              <Typography>
                {rentalEn.bookingDetails.odometerStartReading} :
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography>
                {formatString(bookingVehicleDetails.displayName) ||
                  rentalEn.global.NA}
              </Typography>
              <Typography>
                {formatString(bookingVehicleDetails.registration) ||
                  rentalEn.global.NA}
              </Typography>
              <Typography>
                {bookingVehicleDetails.currentReading || rentalEn.global.NA}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ padding: "10px" }}>
            {bookingStatus === BookingStatusEnum.ONGOING ? (
              <Box>
                <Typography>
                  {rentalEn?.bookingDetails?.odometerEndReading} :
                </Typography>
                <Input
                  width={"100%"}
                  iconStart={OdometerIcon}
                  placeholder={rentalEn?.bookingDetails?.enterOdometerReading}
                  value={fields.odometerEndReading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    let reading: any = e.target.value;
                    reading = reading.toString().replace(/^0+/, "");

                    if (checkNumeric(reading) && reading?.length < 9) {
                      onEndOdometerReadingChange(reading);
                    }
                  }}
                  errormessage={errFields?.odometerEndReading}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: colors.tertiary_black,
                    marginTop: "16px",
                    marginBottom: "8px",
                  }}
                >{`${rentalEn?.bookingDetails?.uploadEndReadingImageMandatory}:`}</Typography>
                {!fields.isEndOdometerImgUploaded ? (
                  <>
                    {bookingVehicleImageLoader ? (
                      <LinearProgressDocUpload
                        progress={docUploadLinearProgress}
                      />
                    ) : (
                      <Box
                        {...getRootProps()}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "80px",
                          width: "80px",
                          border: `2px dotted ${colors.text_quatenary_black}`,
                          borderRadius: "16px",
                          cursor: "pointer",
                        }}
                      >
                        <input {...getInputProps()} />
                        <img src={AddDocIcon} />
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: colors.card_border,
                      borderRadius: "17px",
                      padding: "24px",
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "22px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box display={"flex"} gap="20px" alignItems={"center"}>
                      <img src={UploadFileIcon} alt="" />
                      <Stack gap="10px">
                        <Typography
                          variant={typographyConstants?.SUBHEADING}
                          color={colors.text_secondary_gray}
                          sx={{
                            width: "150px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {fields?.oldVehicleOdometerData?.fileName}
                        </Typography>
                        <Box
                          display="flex"
                          gap="20px"
                          justifyContent={"flex-end"}
                        >
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.text_secondary_gray}
                          >
                            <img src={GreenTickIcon} />{" "}
                            {rentalEn.global?.uploaded}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <img
                      src={DeleteIcon}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        onClickImageDelete("endOdometerImgFileName")
                      }
                    />
                  </Box>
                )}

                {/*  Old vehicle image upload */}
                <UploadVehicleImages
                  bookingData={bookingDetails}
                  imagesData={fields?.oldVehicleImg || []}
                  status={bookingStatus}
                  imageLoader={bookingVehicleImageLoader}
                  title={rentalEn.bookingManagement.oldVehicleImages}
                  imagePreviewData={undefined}
                  onImageUpload={(signedUrl: any, data: any) => {
                    setFields((prev: any) => ({
                      ...prev,
                      oldVehicleImg: [...(prev?.oldVehicleImg || []), ...data],
                      oldVehicleImgSignedUrl: signedUrl,
                    }));
                  }}
                />
              </Box>
            ) : (
              getVehicleUpgradeDropDown()
            )}
            <CustomSelect
              required
              width={"100%"}
              icon={AllVendorsIcon}
              placeholder={rentalEn.global.SelectReason}
              value={fields?.vehicleModificationReason?.description || ""}
              topHeading={rentalEn.bookingManagement.modificationReasons}
              defaultKey={"description"}
              choice={modificationReasons?.reasons ?? []}
              handleChange={(reason: any) => {
                handleChange("vehicleModificationReason", reason);
              }}
            />
          </Box>
        </Card>
        {/* New Vehicle Detail */}
        {bookingStatus === BookingStatusEnum.ONGOING ? (
          <MuiAccordion
            accordianTitle={"Enter New Vehicle Details"}
            isExpanded={expandVehicleAccordian}
            onExpand={toggleVehicleAccordian}
            containerStyle={{ marginTop: "24px", marginBottom: "24px" }}
            // isDisabled={!!fields.disabledAccordians[key]}
          >
            <Box>
              {getVehicleUpgradeDropDown()}
              <CustomSelect
                required
                width={"100%"}
                icon={BikeIconFaded}
                placeholder={
                  rentalEn?.bookingDetails?.vehicleRegistrationNumber
                }
                value={fields?.upgradeVehicleRegNo?.registration || ""}
                defaultKey={"registration"}
                disabled={!fields?.selectedUpgradeVehicle?.displayName}
                isDisabled={isVehicleSelectable}
                choice={getUpdatedAvailableModels() ?? []}
                customLabel={(data: any) => {
                  if (isVehicleSelectable(data))
                    return `${data?.registration} (${rentalEn.VehicleManagement.assigned})`;
                  return data?.registration;
                }}
                handleChange={(reason: any) => {
                  handleChange("upgradeVehicleRegNo", reason);
                }}
                sx={{ marginTop: "8px" }}
              />
              <Input
                width={"100%"}
                iconStart={OdometerIcon}
                placeholder={rentalEn?.bookingDetails?.odometerStartReading}
                value={fields.odometerStartReading}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  let reading: any = e.target.value;
                  reading = reading.toString().replace(/^0+/, "");
                  checkNumeric(reading) &&
                    reading?.length < 7 &&
                    handleChange("odometerStartReading", reading);
                }}
                sx={{ marginTop: "8px" }}
                errormessage={errFields?.odometerStartReading}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: colors.tertiary_black,
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >{`${rentalEn?.bookingDetails?.uploadStartReadingImageMandatory} :`}</Typography>
              {!fields.isStartOdometerImgUploaded ? (
                <>
                  {odometerImgLoad ? (
                    <LinearProgressDocUpload
                      progress={docUploadLinearProgress}
                    />
                  ) : (
                    <Box
                      {...getStartOdometerRootProps()}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80px",
                        width: "80px",
                        border: `2px dotted ${colors.text_quatenary_black}`,
                        borderRadius: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getStartOdometerInputProps()} />
                      <img src={AddDocIcon} />
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    backgroundColor: colors.card_border,
                    borderRadius: "17.48px",
                    padding: "24px",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "22px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box display={"flex"} gap="20px" alignItems={"center"}>
                    <img src={UploadFileIcon} alt="" />
                    <Stack gap="10px">
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.text_secondary_gray}
                        sx={{
                          width: "150px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {fields?.newVehicleOdometerData?.fileName}
                      </Typography>
                      <Box
                        display="flex"
                        gap="20px"
                        justifyContent={"flex-end"}
                      >
                        <Typography
                          variant={typographyConstants?.BODY}
                          color={colors.text_secondary_gray}
                        >
                          <img src={GreenTickIcon} />{" "}
                          {rentalEn.global?.uploaded}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <img
                    src={DeleteIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      onClickImageDelete("startOdometerImgFileName")
                    }
                  />
                </Box>
              )}
              {/* New Vehicle Images */}
              <UploadVehicleImages
                bookingData={bookingDetails}
                imagesData={fields.newVehicleImg || []}
                status={bookingStatus}
                imageLoader={bookingVehicleImageLoader}
                title={rentalEn.bookingManagement.newVehicleImages}
                imagePreviewData={undefined}
                onImageUpload={(signedUrl: any, data: any) =>
                  setFields((prev: any) => ({
                    ...prev,
                    newVehicleImg: [...(prev.newVehicleImg || []), ...data],
                    newVehicleImgSignedUrl: signedUrl,
                  }))
                }
              />
            </Box>
          </MuiAccordion>
        ) : null}
      </Stack>

      <Box>
        <CustomButton
          label={rentalEn.buttonLabels.proceed}
          variant="contained"
          sx={{ width: "100%" }}
          loading={
            modifyBookingChargesLoader ||
            bookingModifyLoading ||
            paymentLinkQRLoader
          }
          onClick={handleUpgradeVehicle}
          disabled={isProceedDisabled()}
        />
      </Box>
    </Box>
  );
};
