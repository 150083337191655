import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styles from "../components.module.css";

const SelectX = (props: any) => {
  const handleSelect = (data: any) => {
    props.handleChange(data);
  };



  return (
    <Box
      className={styles.BoxColumn}
      width={props?.width ? props?.width : "100%"}
      maxWidth={"520px"}
    >
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        {props?.heading && (
          <InputLabel
            id="demo-simple-select-label"
            sx={{ fontSize: "16px", left: "6px" }}
          >
            {props.heading}
          </InputLabel>
        )}

        <Select
          label={props.heading}
          value={props.value}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              {props.muiIcon ? props.muiIcon : <img src={props.icon} />}
            </InputAdornment>
          }
          className={styles.select}
          {...props}
        >
          <MenuItem value={""}>
            <em>{props.placeholder}</em>
          </MenuItem>
          {/* dropdown values */}
          {props?.choice?.map((data: any, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleSelect(data)}
                value={props.defaultKey ? data[props.defaultKey] : data}
              >
                {props.defaultKey ? data[props.defaultKey] : data}
              </MenuItem>
            );
          })}
          
        </Select>
      </FormControl>
      <FormHelperText>
        {props.errormessage && (
          <Typography className="error-message" style={{ fontSize: "9px" }}>
            {props.errormessage}
          </Typography>
        )}
      </FormHelperText>
    </Box>
  );
};
export default SelectX;
