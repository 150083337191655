import { toastr } from "react-redux-toastr";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { handleMessage, isArrayNotEmpty } from "../../../utils/helper";
import { GenericObject } from "../../constants/constants";
import { actionInterface } from "../../interfaces/commonInterface";
import rentalEn from "../../locale/rental-en.json";
import * as actionTypes from "../actions/actionTypes";
import vehicleManagementServices from "../services/vehicleManagementServices";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../utils/RoutesConstants";
import kycDocumentsService from "../services/kycDocumentsService";
import { saveAs } from "file-saver";
import { getStore } from "../../../redux/store";

/**
 * Handles failure scenario by dispatching a failure action and displaying an info toastr.
 * @param {any} failMsg - Optional failure message.
 * @param {GenericObject} failPayload - Optional payload for failure action.
 */
function* failSaga(failMsg?: any, failPayload?: GenericObject) {
  yield put({
    type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.VEHICLE_MANAGEMENT_FAIL,
    payload: failPayload || {},
  });
  toastr.info(
    rentalEn?.toastTypes?.info,
    handleMessage(failMsg) || rentalEn?.errorMessages?.unknownFailure
  );
}

/**
 * Handles error scenario by dispatching an error action and displaying an error toastr.
 * @param {any} errorMsg - Optional error message.
 * @param {GenericObject} errorPayload - Optional payload for error action.
 */
function* errorSaga(errorMsg?: any, errorPayload?: GenericObject) {
  yield put({
    type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.VEHICLE_MANAGEMENT_ERROR,
    payload: errorPayload || {},
  });
  toastr.error(
    rentalEn?.toastTypes?.error,
    handleMessage(errorMsg) || rentalEn?.errorMessages?.unknownError
  );
}

// Saga function to fetch calendar data.
function* getCalendarData(action: actionInterface): any {
  try {
    const response = yield call(
      vehicleManagementServices.getCalendarData,
      action?.payload?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_INVENTORY_CALENDAR_VIEW_DATA_SUCCESS,
        payload: response.data.data,
      });
      // Callback function on API success
      if (typeof action?.payload?.apiSuccessFunction === "function") {
        action?.payload?.apiSuccessFunction(response.data.data);
      }
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_INVENTORY_CALENDAR_VIEW_DATA_FAILDED,
      });
    }
  } catch (ex) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_INVENTORY_CALENDAR_VIEW_DATA_FAILDED,
    });
  }
}

// Saga function to fetch ongoing bookings details.
function* getBookingsDetailsSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getOngoingBookingsDetails,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ONGOING_BOOKINGS_DETAILS_SUCCESS,
        payload: {
          ongoingBookingsData: isArrayNotEmpty(data?.data?.data?.bookings)
            ? data?.data?.data?.bookings
            : [],
          ongoingBookingsApiResponseData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        ongoingBookingsData: [],
        ongoingBookingsApiResponseData: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      ongoingBookingsData: [],
      ongoingBookingsApiResponseData: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to fetch upcoming bookings details.
function* getUpcomingBookingsDetailsSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getUpcomingBookingsDetails,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_UPCOMING_BOOKINGS_DETAILS_SUCCESS,
        payload: {
          upcomingBookingsData: isArrayNotEmpty(data?.data?.data?.bookings)
            ? data?.data?.data?.bookings
            : [],
          upcomingBookingsApiResponseData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        upcomingBookingsData: [],
        upcomingBookingsApiResponseData: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      upcomingBookingsData: [],
      upcomingBookingsApiResponseData: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to fetch blocked vehicles details.
function* getBlockedVehiclesDetailsSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getBlockedVehiclesDetails,
      action?.payload
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_BLOCKED_VEHICLES_DETAILS_SUCCESS,
        payload: {
          blockedVehiclesData: isArrayNotEmpty(
            data?.data?.data?.blockedVehicleDetails
          )
            ? data?.data?.data?.blockedVehicleDetails
            : [],
          blockedVehiclesApiResponseData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        blockedVehiclesData: [],
        blockedVehiclesApiResponseData: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      blockedVehiclesData: [],
      blockedVehiclesApiResponseData: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to fetch vehicles list.
function* getVehiclesListSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehiclesList,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLES_LIST_SUCCESS,
        payload: {
          vehiclesListData: isArrayNotEmpty(data?.data?.data?.vehicleDetails)
            ? data?.data?.data?.vehicleDetails
            : [],
          vehiclesListApiResponseData: data?.data?.data || {},
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehiclesListData: [],
        vehiclesListApiResponseData: {},
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehiclesListData: [],
      vehiclesListApiResponseData: {},
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to update vehicle available/block status.
function* updateVehicleStatusSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.updateVehicleStatus,
      action?.payload?.data
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .UPDATE_VEHICLE_STATUS_SUCCESS,
        payload: {
          vehicleStatusUpdateData: data?.data?.data || {},
        },
      });
      // Callback function on API success
      if (typeof action?.payload?.apiSuccessFunction === "function") {
        action?.payload?.apiSuccessFunction();
      }
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleStatusUpdateData: {},
        vehicleStatusUpdateLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleStatusUpdateData: {},
      vehicleStatusUpdateLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to add new vehicle details and handle success or failure scenarios.
function* addNewVehicleDetailsSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.addNewVehicleDetails,
      action?.payload?.data
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .ADD_NEW_VEHICLE_DETAILS_SUCCESS,
        payload: {
          addNewVehicleDetailsData: data?.data?.data || {},
        },
      });
      // Callback function on API success
      if (typeof action?.payload?.apiSuccessFunction === "function") {
        action?.payload?.apiSuccessFunction(data?.data?.data || {}, "add");
      }
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        addNewVehicleDetailsData: {},
        addEditVehicleDetailsLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      addNewVehicleDetailsData: {},
      addEditVehicleDetailsLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Get vehicle details by passing vehicle id
function* getVehicleByIdSaga(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleById,
      action?.payload?.data
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_DETAILS_BY_ID_SUCCESS,
        payload: data?.data?.data,
      });
      // Callback function on api success
      if (typeof action?.payload?.apiSuccessFunction === "function") {
        action?.payload?.apiSuccessFunction(data?.data?.data || {});
      }
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_DETAILS_BY_ID_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_VEHICLE_DETAILS_BY_ID_FAILED,
    });
  }
}

// get live challan from kyc kart api
function* getChallanLive(action: actionInterface): any {
  try {
    const { page, pageSize, vehicleId } = action.payload;

    //payload for get live kyc kart challans api
    const getLiveChallansPayload = {
      vehicleId: vehicleId,
    };

    //payload for get db challans api
    const getChallansPayload = {
      vehicleIds: JSON.stringify([vehicleId]),
      page,
      pageSize,
    };

    const data = yield call(
      vehicleManagementServices.getLiveChallan,
      getLiveChallansPayload
    );

    let result: any;

    if (data?.status === 200) {
      result = yield call(
        vehicleManagementServices.getChallans,
        getChallansPayload
      );
    }

    if (result.status === 200 || result.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_LIVE_CHALLAN_LIST_SUCCESS,
        payload: result?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_LIVE_CHALLAN_LIST_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_LIVE_CHALLAN_LIST_FAILED,
    });
  }
}

// get challans from db
function* getChallansList(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.getChallans,
      action?.payload?.challanListPayload
    );

    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_RENTALS_CHALLAN_LIST_SUCCESS,
        payload: data?.data?.data,
      });

      // if(action.payload?.downloadChallans)
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_RENTALS_CHALLAN_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_RENTALS_CHALLAN_LIST_FAILED,
    });
  }
}

// get assigned iot devices to particular vehicle
function* getAssignedIotDevices(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.getAssignedIotDevices,
      action.payload
    );
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ASSIGNED_IOT_DEVICES_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ASSIGNED_IOT_DEVICES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_ASSIGNED_IOT_DEVICES_FAILED,
    });
  }
}

function* unassignedIotDevices(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.unAssigneIotDevices,
      action.payload.unassignPayload
    );

    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .RENTALS_UNASSIGN_IOT_DEVICE_SUCCESS,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ASSIGNED_IOT_DEVICES_REQUESTED,
        payload: action?.payload?.getIotPayload,
      });
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .RENTALS_UNASSIGN_IOT_DEVICE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .RENTALS_UNASSIGN_IOT_DEVICE_FAILED,
    });
  }
}

// Saga function to update vehicle approve/reject status and handle success or failure scenarios.
function* updateVehicleApproveRejectStatusSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.approveRejectVehicle,
      action?.payload.approveRejectPayload
    );
    if (data?.status === 200 || data?.status === 201) {
      action?.payload?.successCallback();
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .UPDATE_APPROVE_REJECT_VEHICLE_SUCCESS,
        // payload: {
        //   vehicleApproveRejectStatusUpdateData: data?.data?.data || {},
        // },
      });

      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_BLOCKED_VEHICLES_DETAILS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    // Handling exceptions
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_BLOCKED_VEHICLES_DETAILS_FAILED,
    });
  }
}

function* linkChallans(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.linkChallans,
      action?.payload?.linkPayload
    );

    if (data?.status === 200 || data?.status === 201) {
      toastr?.success("", handleMessage(data));

      // handles close side drawer in challan linking on api success
      action.payload.successCallback();

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_SUCCESS,
        payload: data?.data?.data,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_RENTALS_CHALLAN_LIST_REQUESTED,
        payload: action?.payload?.getChallanPayload,
      });
    } else {
      toastr?.error("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_FAILED,
      });
    }
  } catch (error) {
    toastr?.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_FAILED,
    });
  }
}

function* unlinkChallans(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.unlinkChallans,
      action?.payload?.unlinkPayload
    );
    if (data?.status === 200 || data?.status === 201) {
      toastr?.success("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_SUCCESS,
        payload: data?.data?.data,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_RENTALS_CHALLAN_LIST_REQUESTED,
        payload: action?.payload?.getChallanPayload,
      });
    } else {
      toastr?.error("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_FAILED,
      });
    }
  } catch (error) {
    toastr?.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_FAILED,
    });
  }
}

function* getPingHistory(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.pingHistory,
      action?.payload?.apiBody
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes?.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes?.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes?.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_FAILED,
    });
  }
}

// Saga function to fetch IoT devices.
function* getIotDevicesSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getIotDevices,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_IOT_DEVICES_SUCCESS,
        payload: {
          iotDetails: isArrayNotEmpty(data?.data?.data?.iotDetails)
            ? data?.data?.data?.iotDetails
            : [],
          iotType: action?.payload?.type[0],
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        iotDetails: [],
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      iotDetails: [],
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to assign IoT device and handle success or failure scenarios.
function* assignIotDeviceSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.assignIotDevice,
      action?.payload?.data
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ASSIGN_IOT_DEVICE_SUCCESS,
        payload: {
          assignedIotData: data?.data?.data || {},
          assignedIotType: data?.data?.data?.deviceType,
        },
      });
      // Callback function on API success
      const { apiResponseCallback } = action?.payload;
      apiResponseCallback(data, rentalEn?.global?.apiSuccessKey);
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        assignedIotData: {},
      };
      // Callback function on API failure
      const { apiResponseCallback } = action?.payload;
      apiResponseCallback({}, rentalEn?.global?.apiFailureKey);
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      assignedIotData: {},
    };
    // Callback function on API error
    const { apiResponseCallback } = action?.payload;
    apiResponseCallback({}, rentalEn?.global?.apiFailureKey);
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to fetch vehicle model list.
function* fetchVehicleModelListSaga(action: any): any {
  try {
    // Get authorized branches data from Redux store.
    const authorizedBranchesData: string[] =
      getStore().newAuthReducer?.userData?.authorizedBranches?.map(
        (branch: any) => branch?.name
      );

    const data = yield call(
      vehicleManagementServices.fetchVehicleModelListData,
      action?.payload ? action?.payload : { branches: authorizedBranchesData }
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .FETCH_VEHICLE_MODEL_LIST_DATA_SUCCESS,
        payload: {
          vehicleModelListData: isArrayNotEmpty(data?.data?.data?.models)
            ? data?.data?.data?.models
            : [],
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleModelListData: [],
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleModelListData: [],
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// get all tranfer vehicle list  saga
function* getAllVehicleTranferList(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getAllTransferVehicleList,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ALL_TRANFER_VEHICLE_SUCCESS,
        payload: {
          allTransferVehicleData: data?.data?.data || {},
        },
      });

      // toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_ALL_TRANFER_VEHICLE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    // Handling exceptions
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_ALL_TRANFER_VEHICLE_FAILED,
    });
  }
}

// get all tranfer vehicle list  saga
function* getVehicleTranferRequestList(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getTranferVehicleList,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_TRANSFER_REQUEST_SUCCESS,
        payload: {
          vehicleTransferRequestData: data?.data?.data || {},
        },
      });

      // toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_TRANSFER_REQUEST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    // Handling exceptions
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .GET_VEHICLE_TRANSFER_REQUEST_FAILED,
    });
  }
}

// Tranfer Vehicle request
function* transferVehicleRequestSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.transferVehicleRequest,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .TRANSFER_VEHICLE_REQUEST_SUCCESS,
      });

      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      toastr.error("", handleMessage(data));
      // Handling non-200 status codes
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .TRANFER_VEHICLE_REQUEST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    // Handling exceptions
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .TRANFER_VEHICLE_REQUEST_FAILED,
    });
  }
}

// Saga function to fetch vehicle colour list.
function* fetchVehicleColourListSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.fetchVehicleColourlListData,
      action
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_COLOURS_SUCCESS,
        payload: {
          vehicleColourListData: isArrayNotEmpty(data?.data?.data?.colours)
            ? data?.data?.data?.colours
            : [],
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleColourListData: [],
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleColourListData: [],
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to edit/update vehicle details.
function* editVehicleDetailsSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.editVehicleDetails,
      action?.payload?.data
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .EDIT_VEHICLE_DETAILS_SUCCESS,
        payload: {},
      });
      // Callback function on api success
      if (typeof action?.payload?.apiSuccessFunction === "function") {
        action?.payload?.apiSuccessFunction(data?.data?.data || {}, "edit");
      }
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = { addEditVehicleDetailsLoader: false };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = { addEditVehicleDetailsLoader: false };
    yield call(errorSaga, error, errorPayload);
  }
}

// Saga function to fetch vehicles list for downloading on vehicle management dashboard.
function* getDownloadableVehicleListSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehiclesList,
      action?.payload?.data
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_SUCCESS,
        payload: {
          VehicleListDownloadableData: isArrayNotEmpty(
            data?.data?.data?.vehicleDetails
          )
            ? data?.data?.data?.vehicleDetails
            : [],
        },
      });
      // Callback function on API success
      action?.payload?.downloadVehicleListResponseCallback(
        isArrayNotEmpty(data?.data?.data?.vehicleDetails)
          ? data?.data?.data?.vehicleDetails
          : []
      );
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        VehicleListDownloadableData: [],
        vehicleDataDownloadLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      VehicleListDownloadableData: [],
      vehicleDataDownloadLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// get vehicle signed url
function* getVehicleSignedUrl(action: actionInterface): any {
  try {
    const response = yield call(
      vehicleManagementServices.getVehicleSignedUrlService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      //yield delay(4000);

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED,
        payload: {
          signedUrlResponse: response?.data?.data?.attachments[0],
          filedata: action?.payload.filesData,
          successCallback: action?.payload?.successCallback,
        },
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .VEHICLE_DOCUMENT_UPLOAD_REQUESTED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .VEHICLE_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .VEHICLE_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

// put attachment on signed url
function* putVehicleAttachment(action: actionInterface): any {
  try {
    const {
      signedUrlResponse,
      filedata,
      successCallback = () => {},
    } = action.payload;

    let result = yield call(vehicleManagementServices?.uploadDocumentService, {
      url: signedUrlResponse?.signedUrl,
      file: filedata?.files,
    });

    if (result.status === 200) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_SUCCESS,
        payload: {
          attachmentId: signedUrlResponse?.id,
          type: filedata?.rowData?.selectedDocType?.name,
          startDate: filedata?.rowData?.validFrom,
          expiryDate: filedata?.rowData?.validTo,
          index: filedata?.index,
          signedUrl: signedUrlResponse?.signedUrl,
        },
      });

      // success callback
      successCallback(
        signedUrlResponse?.id,
        filedata?.index,
        filedata?.files,
        signedUrlResponse?.signedUrl
      );
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_FAILED,
        payload: signedUrlResponse,
      });
      toastr.error("", "Document Upload Failed");
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .VEHICLE_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .VEHICLE_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* vehicleDocumentUploadS3(action: any): any {
  try {
    let response: any;

    response = yield call(
      kycDocumentsService.moveKycAttachmentToS3OtherDoc,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .VEHICLE_DOCUMENT_UPLOAD_SUCCESS,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .POST_DOCUMENT_IN_S3_VEHICLE_SUCCESS,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .POST_DOCUMENT_IN_S3_VEHICLE_FAILED,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .VEHICLE_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .VEHICLE_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

// Saga function to delete vehicle.
function* deleteVehicleSaga(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.deleteVehicle,
      action?.payload?.data
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_SUCCESS,
        payload: {},
      });
      // Callback function on api success
      action?.payload?.navigate(routesConstants?.ALL_VEHICLES, {
        replace: true,
      });
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      // Handling non-200 status codes
      const failPayload: any = {};
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {};
    yield call(errorSaga, error, errorPayload);
  }
}

// saga to get vehicle images
function* getVehicleIamages(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleImagesService,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_IMAGES_SUCCESS,
        payload: {
          data: data?.data?.data,
          type: action?.payload?.type,
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleImagesDocsData: [],
        vehicleImagesDataLoader: false,
      };

      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleImagesDocsData: [],
      vehicleImagesDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// get vehicle end ride images
function* getVehicleEndRideImages(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleImagesService,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_END_RIDE_IMAGES_SUCCESS,
        payload: {
          data: data?.data?.data,
          type: action?.payload?.type,
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleImagesDocsData: [],
        vehicleImagesDataLoader: false,
      };

      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleImagesDocsData: [],
      vehicleImagesDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}
// get vehicle start odometer reading images
function* getVehicleStartOdometerImages(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleImagesService,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_START_ODOMETER_READING_SUCCESS,
        payload: {
          data: data?.data?.data,
          type: action?.payload?.type,
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleImagesDocsData: [],
        vehicleImagesDataLoader: false,
      };

      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleImagesDocsData: [],
      vehicleImagesDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}
// get vehicle end ododmeter reading images
function* getVehicleEndOdometerImages(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleImagesService,
      action?.payload
    );
    if (data?.status === 200 || data?.status === 201) {

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_END_ODOMETER_READING_SUCCESS,
        payload: {
          data: data?.data?.data,
          type: action?.payload?.type,
        },
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleImagesDocsData: [],
        vehicleImagesDataLoader: false,
      };

      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleImagesDocsData: [],
      vehicleImagesDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}
  

// get vehicle documents
function* getVehicleDocuments(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.getVehicleDocuments,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_DOCUMENT_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        vehicleDocumentsData: {},
        vehicleDocumentsDataLoader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {
      vehicleDocumentsData: {},
      vehicleDocumentsDataLoader: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

// add vehicle documents
function* addVehicleDocuments(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.addVehicleDocuments,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      action?.payload?.successCallback();

      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .ADD_VEHICLE_DOCUMENTS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      // Handling non-200 status codes
      const failPayload: any = {
        docUploadS3Loader: false,
      };
      yield call(failSaga, data, failPayload);
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_VEHICLE_DOCUMENTS_FAILED,
    });
  }
}

// delete vehicle documents
function* deleteVehicleDocuments(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.deleteVehicleDocuments,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      toastr.success("", handleMessage(data));
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DELETE_VEHICLE_DOCUMENTS_SUCCESS,
        payload: data?.data?.data,
      });

      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .GET_VEHICLE_DOCUMENT_REQUESTED,
        payload: action.payload?.getDocumentParams,
      });

      toastr.success("", handleMessage(data));
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DELETE_VEHICLE_DOCUMENTS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    // Handling exceptions
    const errorPayload: any = {};
    yield call(errorSaga, error, errorPayload);
  }
}

// download vehicle documents
function* downloadVehicleDocuments(action: actionInterface): any {
  try {
    const data = yield call(
      vehicleManagementServices.downloadVehicleDocument,
      action.payload
    );
    data?.data?.data?.signedUrl && saveAs(data?.data?.data?.signedUrl);

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DOWNLOAD_VEHICLE_DOCUMENTS_SUCCESS,
        payload: data?.data?.data,
      });
    } else
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DOWNLOAD_VEHICLE_DOCUMENTS_FAILED,
      });
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .DOWNLOAD_VEHICLE_DOCUMENTS_FAILED,
    });
  }
}
// saga to download excel sheet
function* downloadBulkUploadExcelSheetSaga(): any {
  try {
    const data = yield call(
      vehicleManagementServices.downloadExcelSheetBulkUpload
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_SUCCESS,
        payload: {
          bulkUploadDownload: isArrayNotEmpty(data?.data) ? data?.data : [],
        },
      });
      const url = URL.createObjectURL(new Blob([data.data]));

      data.data && saveAs(url, "bulkUpload.xlsx");
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_FAILED,
    });
  }
}

//add bulk reuest sheet
function* uploadBulkUploadSheetSaga(action: any): any {
  try {
    const response = yield call(
      vehicleManagementServices.uploadBulkUploadSheet,
      action.payload
    );

    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .UPLOAD_BULK_UPLOAD_SHEET_SUCCESS,
        payload: {
          bulkUploadSheet: response?.data?.data ?? {},
        },
      });

      // success call back to get document upload and if doc not present call to bulk upload request initiate
      const requestId: string = response?.data?.data?.requestId;
      if (requestId && action?.payload?.data?.vehicleDocumentData?.length)
        action.payload.successCallback();
      else {
        yield put({
          type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
            .BULK_REQUEST_INITIATE_REQUESTED,
          payload: {
            data: {
              requestId,
            },
            successCallback: action.payload.bulkInitiateSucessCallback,
          },
        });
      }
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .UPLOAD_BULK_UPLOAD_SHEET_FAILED,
        payload: { uploadSheetError: response?.error?.data },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
        .UPLOAD_BULK_UPLOAD_SHEET_FAILED,
    });
  }
}

//Bulk request Initiated
function* bulkRequestInitiate(action: any): any {
  try {
    const data = yield call(
      vehicleManagementServices.bulkRequestInitiate,
      action.payload
    );

    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .BULK_REQUEST_INITIATE_SUCCESS,
      });
      action?.payload?.successCallback();
      toastr.success(rentalEn?.toastTypes?.success, handleMessage(data));
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS
          .BULK_REQUEST_INITIATE_FAILED,
      });
      toastr.error(rentalEn?.toastTypes?.error, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.BULK_REQUEST_INITIATE_FAILED,
    });
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
  }
}

//Vehicle stats requested
function* vehicleStatsSaga(action: any): any {
  try {
    const response = yield call(
      vehicleManagementServices.getVehicleStats,
      action.payload
    );

    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_SUCCESS,
        payload: response?.data?.data,
      });
      action?.payload?.successCallback && action?.payload?.successCallback();
    } else {
      yield put({
        type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_FAILED,
      });
      toastr.error(rentalEn?.toastTypes?.error, handleMessage(response));
    }
  } catch (error) {
    yield put({
      type: actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_FAILED,
    });
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
  }
}

function* rentalsVehicleManagementSaga() {
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED,
    getCalendarData
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_ONGOING_BOOKINGS_DETAILS_REQUESTED,
    getBookingsDetailsSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED,
    getUpcomingBookingsDetailsSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_BLOCKED_VEHICLES_DETAILS_REQUESTED,
    getBlockedVehiclesDetailsSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLES_LIST_REQUESTED,
    getVehiclesListSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPDATE_VEHICLE_STATUS_REQUESTED,
    updateVehicleStatusSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_NEW_VEHICLE_DETAILS_REQUESTED,
    addNewVehicleDetailsSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_DETAILS_BY_ID_REQUESTED,
    getVehicleByIdSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_LIVE_CHALLAN_LIST_REQUESTED,
    getChallanLive
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_RENTALS_CHALLAN_LIST_REQUESTED,
    getChallansList
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ASSIGNED_IOT_DEVICES_REQUESTED,
    getAssignedIotDevices
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED,
    unassignedIotDevices
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED,
    updateVehicleApproveRejectStatusSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.LINK_CHALLANS_REQUESTED,
    linkChallans
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UNLINK_CHALLANS_REQUESTED,
    unlinkChallans
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_PING_HISTORY_REQUESTED,
    getPingHistory
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_IOT_DEVICES_REQUESTED,
    getIotDevicesSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ASSIGN_IOT_DEVICE_REQUESTED,
    assignIotDeviceSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_TRANSFER_VEHICLE_REQUESTED,
    getAllVehicleTranferList
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .GET_VEHICLE_TRANSFER_REQUEST_REQUESTED,
    getVehicleTranferRequestList
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_ALL_TRANSFER_VEHICLE_REQUESTED,
    getAllVehicleTranferList
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED,
    fetchVehicleModelListSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.TRANFER_VEHICLE_REQUEST_REQUESTED,
    transferVehicleRequestSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_COLOURS_REQUESTED,
    fetchVehicleColourListSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.EDIT_VEHICLE_DETAILS_REQUESTED,
    editVehicleDetailsSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED,
    getDownloadableVehicleListSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_SIGNED_URLS_VEHICLE_REQUESTED,
    getVehicleSignedUrl
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .PUT_ATTACHMENT_ON_SIGNED_URL_VEHICLE_REQUESTED,
    putVehicleAttachment
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .POST_DOCUMENT_IN_S3_VEHICLE_REQUESTED,
    vehicleDocumentUploadS3
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_REQUESTED,
    deleteVehicleSaga
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_IMAGES_REQUESTED,
    getVehicleIamages
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_END_RIDE_IMAGES_REQUESTED,
    getVehicleEndRideImages
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_START_ODOMETER_READING_REQUESTED,
    getVehicleStartOdometerImages
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_END_ODOMETER_READING_REQUESTED,
    getVehicleEndOdometerImages
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_DOCUMENT_REQUESTED,
    getVehicleDocuments
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.ADD_VEHICLE_DOCUMENTS_REQUESTED,
    addVehicleDocuments
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DELETE_VEHICLE_DOCUMENTS_REQUESTED,
    deleteVehicleDocuments
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED,
    downloadVehicleDocuments
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS
      .DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED,
    downloadBulkUploadExcelSheetSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.UPLOAD_BULK_UPLOAD_SHEET_REQUESTED,
    uploadBulkUploadSheetSaga
  );

  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.BULK_REQUEST_INITIATE_REQUESTED,
    bulkRequestInitiate
  );
  yield takeLatest(
    actionTypes.VEHICLE_MANAGEMENT_ACTIONS.GET_VEHICLE_STATS_REQUESTED,
    vehicleStatsSaga
  );
}

export default rentalsVehicleManagementSaga;
