import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { handleMessage } from "../../../utils/helper";
import { toastr } from "react-redux-toastr";
import reportService from "../services/reportService";

// api call to get all vendors list of iot devices
function* generateReportSaga(action: any): any {
  try {
    const data = yield call(reportService.generateReport, action.payload);
    if (data.status === 200 || data.status === 201) {
      yield put({
        type: actionTypes.REPORT_ACTIONS.GENERATE_REPOST_SUCCESS,
        payload: data?.data?.data,
      });
      toastr.success("", handleMessage(data));
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.REPORT_ACTIONS.GENERATE_PAYMENT_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));

    yield put({
      type: actionTypes.REPORT_ACTIONS.GENERATE_PAYMENT_FAILED,
    });
  }
}

function* rentalReportSaga() {
  yield takeLatest(
    actionTypes.REPORT_ACTIONS.GENERTE_REPORT_REQUESTED,
    generateReportSaga
  );
}

export default rentalReportSaga;
