import { theme } from "../../../freedo-x-src/themes";

const CreateBookingStyles = {
  stepperStyles: {
    "& .MuiStepConnector-line": { display: "none" },
    "& .MuiStepLabel-iconContainer": { display: "none" },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 3,
    padding: "0px",
  },

  renderUI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  renderUIBox: {
    width: "100%",
    background: theme.palette.background.default,
    borderRadius: "8px",
    padding: "5px 32px 32px 40px",
  },
};

const BookingDetailsStyles = {
  stepperStyles: {
    "& .MuiStepConnector-line": { display: "none" },
    "& .MuiStepLabel-iconContainer": { display: "none" },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 3,
    padding: "0px",
  },

  renderUI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  renderUIBox: {
    width: "100%",
    background: theme.palette.background.default,
    borderRadius: "8px",
    // padding: "24px 24px 32px 24px",
  },
};

const ManualBookingAdminStyles = {
  stepperStyles: {
    "& .MuiStepConnector-line": { display: "none" },
    "& .MuiStepLabel-iconContainer": { display: "none" },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 1,
    padding: "0px",
  },

  renderUI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  renderUIBox: {
    width: "100%",
    background: "#F2F4F4",
    borderRadius: "8px",
    padding: "5px 32px 32px 40px",
  },
};
export { CreateBookingStyles, BookingDetailsStyles, ManualBookingAdminStyles };
