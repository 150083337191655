export enum ACTIONS {
  LOCALE = "LOCALE",
  SHOW_TOAST = "SHOW_TOAST",
  HIDE_TOAST = "HIDE_TOAST",

  SERVER_ERROR = "SERVER_ERROR",
  AUTH_ISSUE = "AUTH_ISSUE",
}

export enum AUTH_ACTIONS {
  USER_LOGIN_REQUESTED = "USER_LOGIN_REQUESTED",
  USER_LOGIN_SUCCEEDED = "USER_LOGIN_SUCCEEDED",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",

  LOGOUT = "LOGOUT",

  SET_USER_DATA = "SET_USER_DATA",
  SET_USER_TOKEN = "SET_USER_TOKEN",

  USER_SIGNUP_REQUESTED = "USER_SIGNUP_REQUESTED",
  USER_SIGNUP_SUCCEEDED = "USER_SIGNUP_SUCCEEDED",
  USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED",

  DASHBOARD_GET_STATE_DATA = "DASHBOARD_GET_STATE_DATA",
  DASHBOARD_STATIC_REQUESTED = "DASHBOARD_STATIC_REQUESTED",
  DASHBOARD_STATIC_FAILED = "DASHBOARD_STATIC_FAILED",
  DASHBOARD_STATIC_SUCCEEDED = "DASHBOARD_STATIC_SUCCEEDED",

  DASHBOARD_DISPLAY_TABLE_REQUESTED = "DASHBOARD_DISPLAY_TABLE_REQUESTED",
  DASHBOARD_DISPLAY_TABLE_SUCCEEDED = "DASHBOARD_DISPLAY_TABLE_SUCCEEDED",
  DASHBOARD_DISPLAY_TABLE_FAILED = "DASHBOARD_DISPLAY_TABLE_FAILED",

  DASHBOARD_ACTION_CLICK_REQUESTED = "DASHBOARD_ACTION_CLICK_REQUESTED",
  DASHBOARD_ACTION_CLICK_SUCCEEDED = "DASHBOARD_ACTION_CLICK_SUCCEEDED",
  DASHBOARD_ACTION_CLICK_FAILED = "DASHBOARD_ACTION_CLICK_FAILED",

  DASHBOARD_VIEW_CALENDAR_REQUESTED = "DASHBOARD_VIEW_CALENDAR_REQUESTED",
  DASHBOARD_VIEW_CALENDAR_SUCCEEDED = "DASHBOARD_VIEW_CALENDAR_SUCCEEDED",
  DASHBOARD_VIEW_CALENDAR_FAILED = "DASHBOARD_VIEW_CALENDAR_FAILED",

  USER_ALL_DATA_REQUESTED = "USER_ALL_DATA_REQUESTED",
  USER_ALL_DATA_SUCCEEDED = "USER_ALL_DATA_SUCCEEDED",
  USER_ALL_DATA_FAILED = "USER_ALL_DATA_FAILED",
}

export enum MANUAL_BOOKING {
  GET_AGGREGATOR_REQUESTED = "GET_AGGREGATOR_REQUESTED",
  GET_AGGREGATOR_SUCCESS = "GET_AGGREGATOR_SUCCESS",
  GET_AGGREGATOR_FAILED = "GET_AGGREGATOR_FAILED",

  CHECK_USER_EXIST_FREEDO_X_REQUESTED = "CHECK_USER_EXIST_FREEDO_X_REQUESTED",
  CHECK_USER_EXIST_FREEDO_X_SUCCESS = "CHECK_USER_EXIST_FREEDO_X_SUCCESS",
  CHECK_USER_EXIST_FREEDO_X_FAILED = "CHECK_USER_EXIST_FREEDO_X_FAILED",

  CHECK_USER_EXIST_SUCCESS = "CHECK_USER_EXIST_SUCCESS",
  CHECK_USER_EXIST_FAILED = "CHECK_USER_EXIST_FAILED",

  CHANGE_USER_EXIST_STATUS = "CHANGE_USER_EXIST_STATUS",

  CREATE_MANUAL_BOOKING_REQUESTED_X = "CREATE_MANUAL_BOOKING_REQUESTED_X",
  CREATE_MANUAL_BOOKING_SUCCESS_X = "CREATE_MANUAL_BOOKING_SUCCESS_X",
  CREATE_MANUAL_BOOKING_FAILED_X = "CREATE_MANUAL_BOOKING_FAILED_X",

  REFRESH_MANUAL_BOOKING = "REFRESH_MANUAL_BOOKING",

  ADD_USER_REQUESTED = "ADD_USER_REQUESTED",
  ADD_USER_SUCCEEDED = "ADD_USER_SUCCEEDED",
  ADD_USER_FAILED = "ADD_USER_FAILED",

  SET_SELECTED_FIELDS_X = "SET_SELECTED_FIELDS_X",

  USER_PAYMENT_MANDATE_X_REQUESTED = "USER_PAYMENT_MANDATE_X_REQUESTED",
  USER_PAYMENT_MANDATE_X_SUCCESS = "USER_PAYMENT_MANDATE_X_SUCCESS",
  USER_PAYMENT_MANDATE_X_FAILED = "USER_PAYMENT_MANDATE_X_FAILED",

  SET_DUPLICATE_BOOKING_MODAL_X = "SET_DUPLICATE_BOOKING_MODAL_X",
  CLEAR_DUPLICATE_BOOKING_MODAL_REQUESTED_X = "CLEAR_DUPLICATE_BOOKING_MODAL_REQUESTED_X",
  CLEAR_DUPLICATE_BOOKING_MODAL_X = "CLEAR_DUPLICATE_BOOKING_MODAL_X",
}

export enum NEW_AUTH_ACTIONS {
  NEW_USER_LOGIN_REQUESTED = "NEW_USER_LOGIN_REQUESTED",
  NEW_USER_LOGIN_SUCCEEDED = "NEW_USER_LOGIN_SUCCEEDED",
  NEW_USER_LOGIN_FAILED = "NEW_USER_LOGIN_FAILED",

  NEW_USER_LOGOUT = "NEW_USER_LOGOUT",

  SET_USER_NEW_DATA = "SET_USER_NEW_DATA",
  SET_USER_FREEDO_X_DATA = "SET_USER_FREEDO_X_DATA",
  SET_USER_FREEDO_RENTALS_DATA = "SET_USER_FREEDO_RENTALS_DATA",

  USER_FORGOT_PASSWORD_REQUESTED = "USER_FORGOT_PASSWORD_REQUESTED",
  USER_FORGOT_PASSWORD_SUCCEEDED = "USER_FORGOT_PASSWORD_SUCCEEDED",
  USER_FORGOT_PASSWORD_FAILED = "USER_FORGOT_PASSWORD_FAILED",

  RESEND_OTP_REQUESTED = "RESEND_OTP_REQUESTED",
  RESEND_OTP_SUCCEEDED = "RESEND_OTP_SUCCEEDED",
  RESEND_OTP_FAILED = "RESEND_OTP_FAILED",
}

export enum RENTAL_PLANS_X_ACTIONS {
  GET_RENTAL_PLANS_X_REQUESTED = "GET_RENTAL_PLANS_X_REQUESTED",
  GET_RENTAL_PLANS_X_SUCCESS = "GET_RENTAL_PLANS_X_SUCCESS",
  GET_RENTAL_PLANS_X_FAILED = "GET_RENTAL_PLANS_X_FAILED",

  GET_RENTAL_PLAN_BY_ID_X_REQUESTED = "GET_RENTAL_PLAN_BY_ID_X_REQUESTED",
  GET_RENTAL_PLAN_BY_ID_X_SUCCESS = "GET_RENTAL_PLAN_BY_ID_X_SUCCESS",
  GET_RENTAL_PLAN_BY_ID_X_FAILED = "GET_RENTAL_PLAN_BY_ID_X_FAILED",

  ADD_RENTAL_PLAN_X_REQUESTED = "ADD_RENTAL_PLAN_X_REQUESTED",
  ADD_RENTAL_PLAN_X_SUCCESS = "ADD_RENTAL_PLAN_X_SUCCESS",
  ADD_RENTAL_PLAN_X_FAILED = "ADD_RENTAL_PLAN_X_FAILED",

  TOGGLE_ADD_EDIT_X_SUCCESS = "TOGGLE_ADD_EDIT_X_SUCCESS",

  EDIT_RENTAL_PLAN_X_REQUESTED = "EDIT_RENTAL_PLAN_X_REQUESTED",
  EDIT_RENTAL_PLAN_X_SUCCESS = "EDIT_RENTAL_PLAN_X_SUCCESS",
  EDIT_RENTAL_PLAN_X_FAILED = "EDIT_RENTAL_PLAN_X_FAILED",
}

export enum MASTER_ACTIONS_X {
  GET_CITY_DATA_X_REQUESTED = "GET_CITY_DATA_X_REQUESTED",
  GET_CITY_DATA_X_SUCCESS = "GET_CITY_DATA_X_SUCCESS",
  GET_CITY_DATA_X_FAILED = "GET_CITY_DATA_X_FAILED",

  GET_BRANCH_DATA_X_REQUESTED = "GET_BRANCH_DATA_X_REQUESTED",
  GET_BRANCH_DATA_X_SUCCESS = "GET_BRANCH_DATA_X_SUCCESS",
  GET_BRANCH_DATA_X_FAILED = "GET_BRANCH_DATA_X_FAILED",

  GET_MODAL_DATA_X_REQUESTED = "GET_MODAL_DATA_X_REQUESTED",
  GET_MODAL_DATA_X_SUCCESS = "GET_MODAL_DATA_X_SUCCESS",
  GET_MODAL_DATA_X_FAILED = "GET_MODAL_DATA_X_FAILED",

  GET_ALL_MODELS_X_REQUESTED = "GET_ALL_MODELS_X_REQUESTED",
  GET_ALL_MODELS_X_SUCCESS = "GET_ALL_MODELS_X_SUCCESS",
  GET_ALL_MODELS_X_FAILED = "GET_ALL_MODELS_X_FAILED",

  GET_ALL_WAREHOUSES_X_REQUESTED = "GET_ALL_WAREHOUSES_X_REQUESTED",
  GET_ALL_WAREHOUSES_X_SUCCESS = "GET_ALL_WAREHOUSES_X_SUCCESS",
  GET_ALL_WAREHOUSES_X_FAILED = "GET_ALL_WAREHOUSES_X_FAILED",

  GET_YOR_CHARGES_CATEGORIES_REQUESTED = "GET_YOR_CHARGES_CATEGORIES_REQUESTED",
  GET_YOR_CHARGES_CATEGORIES_SUCCESS = "GET_YOR_CHARGES_CATEGORIES_SUCCESS",
  GET_YOR_CHARGES_CATEGORIES_FAILED = "GET_YOR_CHARGES_CATEGORIES_FAILED",
}

export enum BOOKING_DETAILS_ACTIONS_X {
  USER_START_RIDE_X_REQUESTED = "USER_START_RIDE_X_REQUESTED",
  USER_START_RIDE_X_SUCCESS = "USER_START_RIDE_X_SUCCESS",
  USER_START_RIDE_X_FAILED = "USER_START_RIDE_X_FAILED",

  GET_AVAILABLE_VEHICLE_LIST_REQUESTED = "GET_AVAILABLE_VEHICLE_LIST_REQUESTED",
  GET_AVAILABLE_VEHICLE_LIST_SUCCESS = "GET_AVAILABLE_VEHICLE_LIST_SUCCESS",
  GET_AVAILABLE_VEHICLE_LIST_FAILED = "GET_AVAILABLE_VEHICLE_LIST_FAILED",

  GET_BOOKING_DETAILS_X_REQUESTED = "GET_BOOKING_DETAILS_X_REQUESTED",
  GET_BOOKING_DETAILS_X_SUCCESS = "GET_BOOKING_DETAILS_X_SUCCESS",
  GET_BOOKING_DETAILS_X_FAILED = "GET_BOOKING_DETAILS_X_FAILED",

  GET_BOOKING_STATUS_X_REQUESTED = "GET_BOOKING_STATUS_X_REQUESTED",
  GET_BOOKING_STATUS_X_SUCCESS = "GET_BOOKING_STATUS_X_SUCCESS",
  GET_BOOKING_STATUS_X_FAILED = "GET_BOOKING_STATUS_X_FAILED",

  CANCEL_STATUS_X_TASK = "CANCEL_STATUS_X_TASK",

  GET_PENDING_PAYMENT_STATUS_X_REQUESTED = "GET_PENDING_PAYMENT_STATUS_X_REQUESTED",
  GET_PENDING_PAYMENT_STATUS_X_SUCCESS = "GET_PENDING_PAYMENT_STATUS_X_SUCCESS",
  GET_PENDING_PAYMENT_STATUS_X_FAILED = "GET_PENDING_PAYMENT_STATUS_X_FAILED",

  CANCEL_BOOKING_X_REQUESTED = "CANCEL_BOOKING_X_REQUESTED",
  CANCEL_BOOKING_X_SUCCESS = "CANCEL_BOOKING_X_SUCCESS",
  CANCEL_BOOKING_X_FAILED = "CANCEL_BOOKING_X_FAILED",

  CLOSE_BOOKING_X_REQUESTED = "CLOSE_BOOKING_X_REQUESTED",
  CLOSE_BOOKING_X_SUCCESS = "CLOSE_BOOKING_X_SUCCESS",
  CLOSE_BOOKING_X_FAILED = "CLOSE_BOOKING_X_FAILED",
}

export enum BOOKING_MANAGEMENT_ACTIONS_X {
  FETCH_BOOKING_LIST_X_REQUESTED = "FETCH_BOOKING_LIST_X_REQUESTED",
  FETCH_BOOKING_LIST_X_SUCCESS = "FETCH_BOOKING_LIST_X_SUCCESS",
  FETCH_BOOKING_LIST_X_FAILED = "FETCH_BOOKING_LIST_X_FAILED",

  GET_BOOKING_STATISTICS_X_REQUESTED = "GET_BOOKING_STATISTICS_X_REQUESTED",
  GET_BOOKING_STATISTICS_X_SUCCESS = "GET_BOOKING_STATISTICS_X_SUCCESS",
  GET_BOOKING_STATISTICS_X_FAILED = "GET_BOOKING_STATISTICS_X_FAILED",
}

export enum BOOKING_TABLE_FILTERS_X_ACTIONS {
  UPDATE_PAGE_X = "UPDATE_PAGE_X",
  UPDATE_PAGE_SIZE_X = "UPDATE_PAGE_SIZE_X",
  UPDATE_STATUS_X = "UPDATE_STATUS_X",
  UPDATE_SEARCH_X = "UPDATE_SEARCH_X",
  UPDATE_SORTING_X = "UPDATE_SORTING_X",
}

export enum BOOKING_CHARGES_X_ACTIONS {
  FETCH_CHARGES_X_REQUESTED = "FETCH_CHARGES_X_REQUESTED",
  FETCH_CHARGES_X_SUCCESS = "FETCH_CHARGES_X_SUCCESS",
  FETCH_CHARGES_X_FAILED = "FETCH_CHARGES_X_FAILED",
}

export enum BOOKING_CHARGES {
  MONTHLY_FEE = "",
  SUBSCRIPTION_FEE = "",
  ADD_ONS = "",
}

export enum HELMET_DETAILS {
  superPremium = "superPremium",
  fullFace = "fullFace",
  premium = "premium",
  halfFace = "halfFace",
  kids = "kids",
}

export enum ACTIVE_MODULE_X {
  ADD_ACTIVE_MODULE = "ADD_ACTIVE_MODULE",
  CLEAR_ACTIVE_MODULE = "CLEAR_ACTIVE_MODULE",
}

export enum CLEAR_DATA_ACTIONS_TYPE_X {
  CLEAR_DATA_RENTAL_PLAN_X = "CLEAR_DATA_RENTAL_PLAN_X",
  ClEAR_CHECK_USER_DEATILS_X = "CLEAR_DATA_USER_X",
}

export enum YOR_BOOKING_DETAILS_ACTIONS {
  GET_YOR_BOOKING_DETAILS_X_REQUESTED = "GET_YOR_BOOKING_DETAILS_X_REQUESTED",
  GET_YOR_BOOKING_DETAILS_X_SUCCESS = "GET_YOR_BOOKING_DETAILS_X_SUCCESS",
  GET_YOR_BOOKING_DETAILS_X_FAILED = "GET_YOR_BOOKING_DETAILS_X_FAILED",

  GET_YOR_PAYMENT_HISTORIES_X_REQUESTED = "GET_YOR_PAYMENT_HISTORIES_X_REQUESTED",
  GET_YOR_PAYMENT_HISTORIES_X_SUCCESS = "GET_YOR_PAYMENT_HISTORIES_X_SUCCESS",
  GET_YOR_PAYMENT_HISTORIES_X_FAILED = "GET_YOR_PAYMENT_HISTORIES_X_FAILED",

  CANCEL_YOR_BOOKING_X_REQUESTED = "CANCEL_YOR_BOOKING_X_REQUESTED",
  CANCEL_YOR_BOOKING_X_SUCCESS = "CANCEL_YOR_BOOKING_X_SUCCESS",
  CANCEL_YOR_BOOKING_X_FAILED = "CANCEL_YOR_BOOKING_X_FAILED",

  CLOSE_YOR_BOOKING_X_REQUESTED = "CLOSE_YOR_BOOKING_X_REQUESTED",
  CLOSE_YOR_BOOKING_X_SUCCESS = "CLOSE_YOR_BOOKING_X_SUCCESS",
  CLOSE_YOR_BOOKING_X_FAILED = "CLOSE_YOR_BOOKING_X_FAILED",

  GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED = "GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED",
  GET_YOR_PENDING_PAYMENT_STATUS_X_SUCCESS = "GET_YOR_PENDING_PAYMENT_STATUS_X_SUCCESS",
  GET_YOR_PENDING_PAYMENT_STATUS_X_FAILED = "GET_YOR_PENDING_PAYMENT_STATUS_X_FAILED",

  GET_YOR_BOOKING_STATUS_X_REQUESTED = "GET_YOR_BOOKING_STATUS_X_REQUESTED",
  GET_YOR_BOOKING_STATUS_X_SUCCESS = "GET_YOR_BOOKING_STATUS_X_SUCCESS",
  GET_YOR_BOOKING_STATUS_X_FAILED = "GET_YOR_BOOKING_STATUS_X_FAILED",

  YOR_USER_START_RIDE_X_REQUESTED = "YOR_USER_START_RIDE_X_REQUESTED",
  YOR_USER_START_RIDE_X_SUCCESS = "YOR_USER_START_RIDE_X_SUCCESS",
  YOR_USER_START_RIDE_X_FAILED = "YOR_USER_START_RIDE_X_FAILED",

  GET_YOR_AVAILABLE_VEHICLE_LIST_REQUESTED = "GET_YOR_AVAILABLE_VEHICLE_LIST_REQUESTED",
  GET_YOR_AVAILABLE_VEHICLE_LIST_SUCCESS = "GET_YOR_AVAILABLE_VEHICLE_LIST_SUCCESS",
  GET_YOR_AVAILABLE_VEHICLE_LIST_FAILED = "GET_YOR_AVAILABLE_VEHICLE_LIST_FAILED",

  GET_YOR_PAYMENT_MODES_REQUESTED = "GET_YOR_PAYMENT_MODES_REQUESTED",
  GET_YOR_PAYMENT_MODES_SUCCESS = "GET_YOR_PAYMENT_MODES_SUCCESS",
  GET_YOR_PAYMENT_MODES_FAILED = "GET_YOR_PAYMENT_MODES_FAILED",

  CANCEL_YOR_STATUS_X_TASK = "CANCEL_YOR_STATUS_X_TASK",

  CLEAR_BOOKING_DETAILS = "CLEAR_BOOKING_DETAILS",
  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED",
  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_SUCCESS",
  CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED = "CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_FAILED",

  GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED = "GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED",
  GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_SUCCESS = "GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_SUCCESS",
  GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_FAILED = "GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_FAILED",

  CLEANUP_YOR_ORDER_AND_PAYMENT_DATA = "CLEANUP_YOR_ORDER_AND_PAYMENT_DATA",

  GET_YOR_ORDER_CHARGES_BIFURCATION_REQUESTED = "GET_YOR_ORDER_CHARGES_BIFURCATION_REQUESTED",
  GET_YOR_ORDER_CHARGES_BIFURCATION_SUCCESS = "GET_YOR_ORDER_CHARGES_BIFURCATION_SUCCESS",
  GET_YOR_ORDER_CHARGES_BIFURCATION_FAILED = "GET_YOR_ORDER_CHARGES_BIFURCATION_FAILED",

  BOOKING_WAIVED_OFF_CHARGES_REQUESTED = "BOOKING_WAIVED_OFF_CHARGES_REQUESTED",
  BOOKING_WAIVED_OFF_CHARGES_SUCCESS = "BOOKING_WAIVED_OFF_CHARGES_SUCCESS",
  BOOKING_WAIVED_OFF_CHARGES_FAILED = "BOOKING_WAIVED_OFF_CHARGES_FAILED",

  CREATE_BULK_ADDITIONAL_CHARGES_REQUESTED = "CREATE_BULK_ADDITIONAL_CHARGES_REQUESTED",
  CREATE_BULK_ADDITIONAL_CHARGES_SUCCESS = "CREATE_BULK_ADDITIONAL_CHARGES_SUCCESS",
  CREATE_BULK_ADDITIONAL_CHARGES_FAILED = "CREATE_BULK_ADDITIONAL_CHARGES_FAILED",
}

export enum DOCUMENT_UPLOAD_ACTIONS {
  GET_SIGNED_URLS_ATTACHMENT = "GET_SIGNED_URLS_ATTACHMENT",

  PUT_ATTACHMENT_ON_SIGNED_URL = "PUT_ATTACHMENT_ON_SIGNED_URL",
  PUT_ATTACHMENT_ON_SIGNED_URL_FAILED = "PUT_ATTACHMENT_ON_SIGNED_URL_FAILED",

  POST_DOCUMENT_IN_S3_REQUESTED = "POST_DOCUMENT_IN_S3_REQUESTED",
  POST_DOCUMENT_IN_S3_SUCCESS = "POST_DOCUMENT_IN_S3_SUCCESS",
  POST_DOCUMENT_IN_S3_FAILED = "POST_DOCUMENT_IN_S3_FAILED",

  DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS",
  DOCUMENT_UPLOAD_FAILED = "DOCUMENT_UPLOAD_FAILED",
  DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED",

  DOCUMENT_DELETE_REQUESTED = "DOCUMENT_DELETE_REQUESTED",
  DOCUMENT_DELETE_SUCCESS = "DOCUMENT_DELETE_SUCCESS",
  DOCUMENT_DELETE_FAILED = "DOCUMENT_DELETE_FAILED",

  CLEAR_DOCUMENT_UPLOAD_REDUX = "CLEAR_DOCUMENT_UPLOAD_REDUX",
}
