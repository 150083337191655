
import { THEME_ACTIONS } from '../../actions/actionTypes';
import { themeInterface } from '../actionInterfaces';
const initialState = {
    mode: "light"
}

const themeReducer = (state=initialState, action: themeInterface) => {
    const prevState = { ...state }
    const { type, payload } = action
    
    switch(type){
        case THEME_ACTIONS.SET_THEME_MODE: {
            return {
                ...prevState,
                mode: payload
            }
        }
        default: return { ...prevState }
    }
}

export default themeReducer;