import React from "react";
import Select from "react-select";
import { Box, Typography } from "@mui/material";
import { colors } from "../themes/colors";
function MultiSelectPicker(props: any) {
  return (
    <Box
      sx={{
        width: props?.fullwidth ? "100%": "30%",
        borderRadius: 6,
        borderColor: "#ebe9e9",
        marginRight: "2%",
        marginTop: props.title ? 0 : "2%",
      }}
    >
      {props.title ? (
        <Typography>
          <text
            style={{
              color: colors.dark_gray,
              fontSize: "12px",
              fontFamily: "EuclidCircularB-Regular",
              paddingBottom: "2px",
            }}
          >
            {props.title}
          </text>
          <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
        </Typography>
      ) : null}
      <Select
        closeMenuOnSelect={false}
        placeholder={props.placeholder}
        value={props.value}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        isClearable
        menuPlacement={props.menuPlacement ? props.menuPlacement : "top"}
        onChange={props.onChange}
        options={props.options}
      />

      <Typography><text style={{ color: colors.error, fontSize: "14px" }}> {props.errorMsg} </text> </Typography>
    </Box>
  );
}

export default MultiSelectPicker;
