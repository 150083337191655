import { BASE_URL, customAxios, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

// api call for rental plan list
const rentalPlanList = (payload: any) => {
  try {
    const { model, branch, useCase } = payload;
    let mainUrl = `${BASE_URL}${getApiUrl().getPlanList}`;

    const apiUrlWithParams: any = constructUrl(mainUrl, payload);

    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for getting plan data by Plan Id
const getPlanById = ({ payload }: any) => {
  try {
    let mainUrl = `${BASE_URL}${getApiUrl(payload.planId).getPlanById}`;
    return customAxios.get(mainUrl).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for to get default plan
const defaultPlanList = (payload: any) => {
  try {
    const { model, useCase } = payload;
    let mainUrl = `${BASE_URL}${
      getApiUrl().getDefaultPlan
    }?model=${model}&useCase=${useCase}`;
    return customAxios.get(mainUrl).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for create Plan
const createPlan = ({ payload }: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().createAndUpdatePlan}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for update Plan
const createUpdatePlan = ({ payload }: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().createAndUpdatePlan}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for activate Plan
const activatePlan = ({ payload }: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload.planId).activatePlan}`;
    return customAxios.put(url, {}).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// api call for deActivate Plan
const deActivatePlan = ({ payload }: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl(payload.planId).deactivatePlan}`;
    return customAxios.put(url, {}).catch((e) => e);
  } catch (err) {
    return err;
  }
};

export default {
  rentalPlanList,
  getPlanById,
  defaultPlanList,
  createPlan,
  createUpdatePlan,
  activatePlan,
  deActivatePlan,
};
