import {
  FullFaceHelmet,
  HalfFaceHelmet,
  KidsHelmet,
  PremiumHelmet,
} from "../../assets/exportImages";
import {
  AddonsIcon,
  FreedoXbackground,
} from "../../freedo-x-src/constantsX/exportImagesX";
import { colors } from "../../themes/colors";
import { NameDisplayNameInterface } from "../interfaces/iotManagementInterface";
import {
  FreedoRentalsbackground,
  Home,
  Others,
  PaymentLinkIcon,
  QrCodeIcon,
  SurgeIcon,
  Work,
} from "./exportImages";
import rentalEn from "../locale/rental-en.json";

const { CustomerManagement, paymentManagement: paymentManagementLocale } =
  rentalEn;
const { displayBookingStatus } = CustomerManagement;

// Define a type with an index signature // Valid for either 'empty object' or 'object with dynamic key-value pairs'
export type GenericObject = {
  [key: string]: string | number | any;
};

// Define a generic type with dynamic key-value pairs
export type GenericType<T = any> = {
  // Define some predefined properties
  name: string;
  displayName: string;
} & {
  // Define an index signature to allow additional dynamic key-value pairs
  [key: string]: T;
};

export const HELMETS_TYPES: GenericObject = {
  premium: "PREMIUM",
  fullFace: "FULL_FACE",
  halfFace: "HALF_FACE",
  kids: "KIDS",
};

export enum STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum DISPLAY_STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum HELMETS_ACTIONS {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

export enum LOB_TYPES {
  LTR = "LTR",
  R2O = "R2O",
}

export enum USE_CASE_TYPES {
  RENTALS = "RENTALS",
  ETR = "ETR",
}

export const HELMET_ICONS: any = {
  PREMIUM: PremiumHelmet,
  FULL_FACE: FullFaceHelmet,
  HALF_FACE: HalfFaceHelmet,
  KIDS: KidsHelmet,
};

export const HELMET_DISPLAY_NAME: any = {
  PREMIUM: "Premium",
  FULL_FACE: "Full Face",
  HALF_FACE: "Half Face",
  KIDS: "Kids",
};

export const BUSINESS_TYPE = [USE_CASE_TYPES.RENTALS, USE_CASE_TYPES.ETR];

export enum typographyConstants {
  HEADINGXL = "XLheading",
  HEADING = "heading",
  HEADING_NO_PADDING = "headingNoPadding",
  SUBHEADING = "subheading",
  BODYITALIC = "body1Italic",
  BODY = "body1",
  ERRORMESSAGE = "errorMessage1",
  BODYSMALL = "smallText",
  SMALL = "extraSmall",
}

export enum GENDERS {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHERS = "OTHERS",
}

export enum displayGenderEnums {
  MALE = "Male",
  FEMALE = "Female",
  OTHERS = "Others",
}

export const useCaseArray: any[] = [
  {
    name: USE_CASE_TYPES?.RENTALS,
    displayName: "Rentals",
  },
  {
    name: USE_CASE_TYPES?.ETR,
    displayName: "ETR",
  },
];

export const lobArray: any[] = [
  {
    name: LOB_TYPES?.LTR,
    displayName: "LTR",
  },
  {
    name: LOB_TYPES?.R2O,
    displayName: "R2O",
  },
];

export const surgeDateType: GenericObject[] = [
  {
    name: "",
    displayName: "All Surge Types",
  },
  {
    name: "WEEKDAYS",
    displayName: "Specific Days",
  },
  {
    name: "SPECIFIC",
    displayName: "Specific Date",
  },
];

export const activeInactiveStatusArray: GenericObject[] = [
  {
    name: STATUS?.ACTIVE,
    displayName: "Active",
  },
  {
    name: STATUS?.INACTIVE,
    displayName: "Inactive",
  },
];

export enum ChargesTypeKeys {
  BOOKING_CHARGES = "BOOKING_CHARGES",
  SURGE_CHARGES = "SURGE_CHARGES",
  SECURITY_CHARGES = "SECURITY_CHARGES",
  DELIVERY_CHARGES = "DELIVERY_CHARGES",
  HELMET_CHARGES = "HELMET_CHARGES",
}

export const ChargesType: GenericObject = {
  BOOKING_CHARGES: "Package Charges",
  SURGE_CHARGES: "Surge Applied",
  SECURITY_CHARGES: "Security Charges",
  DELIVERY_CHARGES: "Delivery Charges",
  HELMET_CHARGES: "Add-ons",
};

export const ChargeIcons: GenericObject = {
  SURGE_CHARGES: SurgeIcon,
  HELMET_CHARGES: AddonsIcon,
};

export const SlotTimings: GenericObject = {
  Morning: "Morning",
  Evening: "Evening",
  AfterNoon: "Afternoon",
};

export const cityStatus: GenericObject = {
  active: "ACTIVE",
  inactive: "INACTIVE",
};

export const HELMETS_DISPLAY_NAME: GenericObject = {
  PREMIUM: "Premium Helmet",
  FULL_FACE: "Full Face Helmet",
  HALF_FACE: "Half Face Helmet",
  KIDS: "Kids Helmet",
};

export const HELMETS_NAME: GenericObject = {
  PREMIUM: "Premium",
  FULL_FACE: "Full face",
  HALF_FACE: "Half face",
  KIDS: "Kids",
};

export const ADDRESSTYPE: GenericObject = {
  HOME: "Home",
  OFFICE: "Office",
  OTHER: "Other",
};

// seacrh user tags verification enums
export const TAGS: GenericObject = {
  KYC_VERIFIED: "KYC_VERIFIED",
  LTR_CUSTOMER: "LTR_CUSTOMER",
  REGISTERED: "REGISTERED",
};

// inventory constants
export const CALANDER_VIEW_DATA_DISPLAY: GenericObject | any = {
  total: "Total Vehicles",
  blocked: "Blocked Vehicles",
  engaged: "Total Bookings",
  available: "NBC",
};

export enum CALANDER_VIEW_DATA {
  total = "total",
  blocked = "blocked",
  engaged = "engaged",
  available = "available",
}

export enum WAREHOUSE_DELIVERY_TYPES {
  CITY = "CITY",
  POLYGON = "POLYGON",
}
export const CityOverview: any[] = [
  {
    name: "total",
    displayName: "Total Cities:",
  },
  {
    name: "active",
    displayName: "Active:",
  },
  {
    name: "inActive",
    displayName: "Inactive:",
  },
  {
    name: "upcoming",
    displayName: "Upcoming:",
  },
];

export const BranchOverview: any[] = [
  {
    name: "total",
    displayName: "Total Hub:",
  },
  {
    name: "active",
    displayName: "Active:",
  },
  {
    name: "inActive",
    displayName: "Inactive:",
  },
  {
    name: "upcoming",
    displayName: "Upcoming:",
  },
];

export const bookingStatusArray: GenericObject = {
  UPCOMING: "UPCOMING",
  ONGOING: "ONGOING",
};

export const sorting: GenericObject = {
  startDate_asc: "startDate_asc",
  startDate_desc: "startDate_desc",
  bookingDate_asc: "bookingDate_asc",
  bookingDate_desc: "bookingDate_desc",
  createdAt_asc: "createdAt_asc",
  createdAt_desc: "createdAt_desc",
};

export enum EmergencyContactRelationShip {
  FAMILY = "FAMILY",
  COLLEAGUE = "COLLEAGUE",
  FRIEND = "FRIEND",
}

export enum DisplayEmergencyContactRelationShip {
  FAMILY = "Family",
  COLLEAGUE = "Colleague",
  FRIEND = "Friend",
}

export enum EmergencyContactStatus {
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
}

export enum KycVerificationMode {
  DIGITAL = "DIGITAL",
  MANUAL = "MANUAL",
  NONE = "NONE",
}
export enum KycVerificationModeName {
  DIGITAL = "Digitally Verified",
  MANUAL = "Manually Verified",
  NONE = "None",
}
export enum KycVerificationModeDisplayName {
  DIGITAL = "Digital",
  MANUAL = "Manual",
  NONE = "None",
}
export enum KycVerificationStatus {
  VERIFIED = "VERIFIED",
  PENDING = "PENDING",
  UNVERIFIED = "UNVERIFIED",
  FAILURE = "FAILURE",
}
export enum KycVerificationStatusDisplayName {
  VERIFIED = "Verified",
  PENDING = "Pending",
  UNVERIFIED = "Unverified",
  FAILURE = "Failure",
}
export enum KycVerificationStatusDisplayColor {
  UNVERIFIED = "info",
  VERIFIED = "success",
  PENDING = "warning",
  FAILURE = "error",
}
export enum KycFilePlacementType {
  FRONT = "FRONT",
  BACK = "BACK",
}
export enum KycFilePlacementTypeDisplayName {
  FRONT = "Front",
  BACK = "Back",
}
export enum CustomerStatus {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DELETED = "DELETED",
}

export enum CustomerStatusDisplay {
  CREATED = "Created",
  ACTIVE = "Active",
  BLOCKED = "Blocked",
  DELETED = "Deleted",
}

export enum CustomerStatusDisplayColor {
  CREATED = "info",
  ACTIVE = "success",
  BLOCKED = "warning",
  DELETED = "error",
}

export enum CustomerStatusDisplayCustomColor {
  CREATED = "#6CF172",
  ACTIVE = "#6CF172",
  BLOCKED = "#FF7A00",
  DELETED = "#FF3B30",
}

export enum CustomerAction {
  BLOCK = "BLOCK",
  UNBLOCK = "UNBLOCK",
  DELETE = "DELETE",
}
export enum CustomerActionDisplay {
  BLOCK = "Block",
  UNBLOCK = "Unblock",
  DELETE = "Delete",
}

export const tableRowsPerPage: number[] = [10, 25, 50, 75, 100];

export enum BlockUnblockCustomerPayload {
  BLOCK = "BLOCKED",
  UNBLOCK = "ACTIVE",
}
export const appBackgroundImage = {
  freedoX: FreedoXbackground,
  freedoRentals: FreedoRentalsbackground,
};

export enum BookingStatusEnum {
  PENDING = "PENDING",
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  ENDED = "ENDED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export enum BookingStatusDisplay {
  ONGOING = "Ongoing",
  UPCOMING = "Upcoming",
  ENDED = "Ended",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  PENDING = "Pending",
}

export enum BookingStatusColorCodes {
  PENDING = "#FF7A00",
  UPCOMING = "#FF7A00",
  COMPLETED = "#1BD8DA",
  ONGOING = "#1BC47D",
  ENDED = "#FF3B30",
  CANCELLED = "#FF3B30",
}

export enum LOB_CONSTANT {
  LTR = "LTR",
  ETR = "ETR",
}

export enum BUSINESS_TAB_ARRAY_KEYS {
  WAREHOUSE_SETUP = "warehouseSetup",
  DELIVERY_SETUP = "deliverySetup",
}

export enum GURUGRAM_LAT_LNG {
  LAT = 28.4595,
  LNG = 77.0266,
}

export const BOOKING_DETAILS: GenericObject = {
  REGULAR: "Regular",
  CUSTOMER_LOCATION: "Home Delivery",
  CUSTOMER: "Customer",
};
export enum ModeOfDelivery {
  CUSTOMER_LOCATION = "Home Delivery",
  BRANCH = "Hub Pickup",
}

export enum ModeOfPickup {
  CUSTOMER_LOCATION = "Home Pickup",
  BRANCH = "Hub Dropoff",
}

export enum BookingTypes {
  CUSTOMER = "Online",
  ADMIN = "Offline",
}
export enum TwoWheelerName {
  SCOOTER = "SCOOTER",
  MOTORCYCLE = "MOTORCYCLE",
}

export const vehicleCategory: GenericObject[] = [
  {
    name: "",
    displayName: "All Categories",
  },
  {
    name: TwoWheelerName?.MOTORCYCLE,
    displayName: "Bike",
  },
  {
    name: TwoWheelerName?.SCOOTER,
    displayName: "Scooter",
  },
];

export enum vehicleCategoryName {
  MOTORCYCLE = "Bike",
  SCOOTER = "Scooter",
}

export enum LobStatus {
  AVAILABLE = "AVAILABLE",
  ENGAGED = "ENGAGED",
  BLOCKED = "BLOCKED",
  ASSIGNED = "ASSIGNED",
}

export const lobStatus: GenericObject[] = [
  {
    name: "",
    displayName: "All Status",
  },
  {
    name: LobStatus?.AVAILABLE,
    displayName: "Available",
  },
  {
    name: LobStatus?.ENGAGED,
    displayName: "Engaged",
  },
  {
    name: LobStatus?.ASSIGNED,
    displayName: "Assigned",
  },
  {
    name: LobStatus?.BLOCKED,
    displayName: "Blocked",
  },
];

export enum BLOCKED_REASON {
  UNDER_MAINTENANCE = "UNDER_MAINTENANCE",
  RESERVED = "RESERVED",
  UNDER_RECOVERY = "UNDER_RECOVERY",
}

export const blockedReasonArray: GenericObject[] = [
  {
    name: BLOCKED_REASON?.UNDER_MAINTENANCE,
    displayName: "Under Maintenance",
  },
  {
    name: BLOCKED_REASON?.RESERVED,
    displayName: "Reserved",
  },
  {
    name: BLOCKED_REASON?.UNDER_RECOVERY,
    displayName: "Under Recovery",
  },
];

export enum blockedReasonDisplayedName {
  UNDER_MAINTENANCE = "Under Maintenance",
  RESERVED = "Reserved",
  UNDER_RECOVERY = "Under Recovery",
}

export enum DocumentUploadConstants {
  PDF_FILE = ".pdf",
  TO_MANY_FILES = "too-many-files",
  FILE_TOO_LARGE = "file-too-large",
  FILE_INVALID_TYPE = "file-invalid-type",
}

// types for kyc document upload
export enum KycTypes {
  SELFIE = "SELFIE",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  AADHAAR = "AADHAAR",
  OTHER_DOCUMENT = "OTHER_DOCUMENT",
}

// Other kyc document types
export enum OtherDocumentTypes {
  VOTER_ID = "VOTER_ID",
  BANK_DETAIL = "BANK_DETAIL",
  ELECTRICITY_BILL = "ELECTRICITY_BILL",
  RENT_BILL = "RENT_BILL",
  PASSPORT = "PASSPORT",
  OTHER = "OTHER",
}

// Display name for other kyc document
export enum OtherDocumentDisplayTypes {
  VOTER_ID = "Voter ID (Image and Pdf only)",
  BANK_DETAIL = "Bank Detail (Image and Pdf only)",
  ELECTRICITY_BILL = "Electricity Bill (Image and Pdf only)",
  RENT_BILL = "Rent Bill (Image and Pdf only)",
  PASSPORT = "Passport ID (Image and Pdf only)",
  OTHER = "Other Documents (Image and Pdf only)",
}

// Generate signed url cases
export enum generateSignedUrlDocType {
  KYC_DOCUMENT = "KYC_DOCUMENT",
  PROFILE_IMAGE = "PROFILE_IMAGE",
}

export enum VehicleDocumentType {
  VEHICLE_DOCUMENT = "VEHICLE_DOCUMENT",
  REGISTRATION_CERTIFICATE = "REGISTRATION_CERTIFICATE",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  PUC = "PUC",
  FITNESS_CERTIFICATE = "FITNESS_CERTIFICATE",
  INSURANCE = "INSURANCE",
  OTHERS = "OTHERS",
}

export const vehicleDocumentTypeArray: GenericObject = [
  {
    name: VehicleDocumentType?.REGISTRATION_CERTIFICATE,
    displayName: "Registration Certificate",
  },

  {
    name: VehicleDocumentType?.PUC,
    displayName: "PUC Certificate",
  },
  {
    name: VehicleDocumentType?.FITNESS_CERTIFICATE,
    displayName: "Fitness Certificate",
  },
  {
    name: VehicleDocumentType?.INSURANCE,
    displayName: "Insurance Certificate",
  },
];

export const vehicleColors: GenericObject[] = [
  {
    name: "RED",
    displayName: "Red",
  },
  {
    name: "GREEN",
    displayName: "Green",
  },
  {
    name: "BLACK",
    displayName: "Black",
  },
];

export enum ChallanStatus {
  PENDING = "PENDING",
  CONTESTED = "CONTESTED",
  DISPOSED = "DISPOSED",
  LINKED = "LINKED",
}

export enum ChallanStatusColorCodes {
  PENDING = "#FF7A00",
  CONTESTED = "#09CACC",
  DISPOSED = "blue",
  LINKED = "green",
}

export enum ChallanStatusDisplay {
  PENDING = "Pending",
  CONTESTED = "Contested",
  DISPOSED = "Disposed",
  LINKED = "Linked",
  UNLINKED = "Unlinked",
  PAID = "Paid",
  PARTIAL_PAID = "Partial Paid",
  WAIVED_OFF = "Waived Off",
}

export enum ChallanPaymentStatus {
  LINKED = "LINKED",
  UNLINKED = "UNLINKED",
  PAID = "PAID",
  PARTIAL_PAID = "PARTIAL_PAID",
  WAIVED_OFF = "WAIVED_OFF",
}

export enum BOOKING_TYPES {
  CUSTOMER = "Online",
  ADMIN = "Offline",
}

export enum IotDeviceTypesDisplay {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
}

export enum iotDeviceTypes {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum IotDeviceTypes {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum SIDEDRAWER_PAGINATION {
  PAGE_SIZE = 10,
}

export enum iotStatus {
  AVAILABLE = "AVAILABLE",
  INACTIVE = "INACTIVE",
  ENGAGED = "ENGAGED",
}

export const dateWithTimeFormat = "DD/MM/YYYY, hh:mm A";

export const timeFormat = "LT";

export const dateFormat = "dd/MM/yyyy";

// enum for initial pagination page and pageSize
export enum initialPaginationTable {
  page = 1,
  pageSize = 10,
}

// enum for download row counts
export enum downloadRowCount {
  pageSize = 1000,
  page = 1,
}

export const iotStatusArray: NameDisplayNameInterface[] = [
  { name: iotStatus.AVAILABLE, displayName: "Available" },
  { name: iotStatus.INACTIVE, displayName: "Inactive" },
  { name: iotStatus.ENGAGED, displayName: "Engaged" },
];

export const iotDeviceTypesArray: NameDisplayNameInterface[] = [
  { name: iotDeviceTypes.PRIMARY, displayName: "Primary" },
  { name: iotDeviceTypes.SECONDARY, displayName: "Secondary" },
];
export enum iotInactiveReason {
  STOLEN = "STOLEN",
  LOST = "LOST",
  UNDERMAINTENANCE = "UNDERMAINTENANCE",
}
export const iotInactiveReasonsArray: NameDisplayNameInterface[] = [
  { name: iotInactiveReason.STOLEN, displayName: "Stolen" },
  { name: iotInactiveReason.LOST, displayName: "Lost" },
  {
    name: iotInactiveReason.UNDERMAINTENANCE,
    displayName: "Under Maintenance",
  },
];

export enum tyesOfModeOfDelivery {
  BRANCH = "BRANCH",
  CUSTOMER_LOCATION = "CUSTOMER_LOCATION",
}

export enum complementaryHelmet {
  COMPLEMENTARY_HELMET = "COMPLEMENTARY_HELMET",
}

export enum planTypeKeys {
  RegularPlan = "RegularPlan",
  PremiumPlan = "PremiumPlan",
  UnlimitedPlan = "UnlimitedPlan",
}

export enum RideTypes {
  START_RIDE = "Start Ride",
  EXTENSION_START = "Extension",
  END_RIDE = "End Ride",
  EXTENSION_END = "Extension End",
  VEHICLE_CHANGE = "Vehicle Change",
}

export const multiStepFormStepKeys: GenericObject = [
  {
    stepKey: "BookingDetails",
    displayName: "Booking Details",
  },
  {
    stepKey: "Checkout",
    displayName: "Checkout",
  },
];

export const odometerHistoryKeys: GenericObject = [
  {
    name: "startDate",
    displayName: "Start Date:",
  },
  {
    name: "endDate",
    displayName: "End Date:",
  },
  {
    name: "startReading",
    displayName: "Start Reading: ",
  },
  {
    name: "endReading",
    displayName: "End Reading: ",
  },
];

// storage keys used for storing filter data in local storage
export enum filterStorageKeys {
  ADD_PLAN_FILTER_DATA = "addPlanFilterData",
  PLAN_LIST_FILTER_DATA = "planListFilterData",
  SURGE_LIST_FILTER_DATA = "surgeListFilterData",
  INVENTORY_FILTER_DATA = "inventoryFilterData",
  ALL_VEHICLES_DASHBOARD = "allVehiclesDashboard",
  IOT_MANAGEMENT_FILTER_DATA = "iotManagementFilterData",
  BOOKING_LIST_FILTER_DATA = "bookingListFilterData",
  PAYMENT_MANAGEMENT_FILTER_DATA = "paymentManagementFilterData",
  ROLE_MANAGEMENT_FILTER_DATA = "roleManagementFilterData",
  OPERATOR_MANAGEMENT_FILTER_DATA = "operatorManagementFilterData",
  APPROVED_OPERATOR_MANAGEMENT_FILTER_DATA = "approvedOperatorManagementFilterData",
}

export enum TransferRequestStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
}

export enum TransferRequestStatusDisplayName {
  PENDING = "Pending",
  PROCESSING = "Processing",
  COMPLETED = "Completed",
}

export enum TransferStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN-PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum VehicleTranferTabs {
  SENDING_DETAILS = "SENDING_DETAILS",
  RECEVING_DETAILS = "RECEVING_DETAILS",
}

export enum TypeOfTransfer {
  LOB_TRANSFER = "LOB_TRANSFER",
  BRANCH_TRANSFER = "BRANCH_TRANSFER",
}

export const transferType: GenericObject[] = [
  {
    name: TypeOfTransfer?.LOB_TRANSFER,
    displayName: "LTR/R2O Transfer",
  },
  {
    name: TypeOfTransfer?.BRANCH_TRANSFER,
    displayName: "Branch Transfer",
  },
];

//types of address
export const addressType: GenericObject[] = [
  {
    key: "HOME",
    label: "Home",
    icon: <Home color={colors.text_secondary_gray} />,
    selectedIcon: <Home color={colors.white} />,
  },
  {
    key: "OFFICE",
    label: "Office",
    icon: <Work color={colors.text_secondary_gray} />,
    selectedIcon: <Work color={colors.white} />,
  },
  {
    key: "OTHER",
    label: "Other",
    icon: <Others color={colors.text_secondary_gray} />,
    selectedIcon: <Others color={colors.white} />,
  },
];

export enum fileFormats {
  IOT_BULK_UPLOAD_FORMAT = "Format.xlsx",
}

export enum excelFileFormatAcceptable {
  EXCEL_FORMAT = ".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
}

// constant for upload file formats
export enum filetypesFormat {
  PDF = "application/pdf",
  IMAGE_JPG = "image/png",
  IMAGE_PNG = "image/png",
  IMAGE_JPEG = "image/jpeg",
}

// max file size to upload in kb (5MB)
export const maxFileSize: number = 5000000;

// All status code need to add in statusColorCode
export enum statusColorCode {
  AVAILABLE = colors?.light_green,
  ENGAGED = colors?.primary,
  BLOCKED = colors?.primary_danger,
  ASSIGNED = colors?.heading_text,
  ACTIVE = colors?.secondary_green,
  INACTIVE = colors?.primary_danger,
  PENDING_FOR_APPROVAL = colors?.primary_warning,
  REJECTED = colors?.primary_danger,
  PENDING = colors?.primary_warning,
  PROCESSING = colors?.primary_warning,
  SUCCESS = colors?.light_green,
  FAILURE = colors?.error,
  FAILED = colors?.error,
  CANCELLED = colors.primary,
  DELIVERY_MODIFICATION = colors?.light_green,
  RETURN_DELIVERY_MODIFICATION = colors?.light_green,
  HELMET_MODIFICATION = colors?.light_green,
  MODEL_MODIFICATION = colors?.light_green,
  VEHICLE_MODIFICATION = colors?.light_green,
  BOOKING_DATE_MODIFICATION = colors?.light_green,
  SLOTS_MODIFICATION = colors?.light_green,
  PLAN_MODIFICATION = colors?.light_green,
  PLAN_VARIANT_MODIFICATION = colors?.light_green,
  UPCOMING = colors?.primary_warning,
  ENDED = colors?.primary_danger,
  DELETED = colors?.industrial_revolution_gray,
  LOCKED = colors?.olive_yellow,
}

// Vehicle status
export enum VehicleStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SOLD = "SOLD",
  PENDING_FOR_APPROVAL = "PENDING_FOR_APPROVAL",
  REJECTED = "REJECTED",
  ASSIGNED = "ASSIGNED",
}

/* ----------- Booking Management Constants ---------------- */
// OrderPaymentType
export enum OrderPaymentType {
  QR_CODE = "QR_CODE",
  PAYMENT_LINK = "PAYMENT_LINK",
  CASH = "CASH",
}

export enum DisplayPaymentModes {
  QR_CODE = "QR code",
  PAYMENT_LINK = "Payment Link",
  CASH = "Cash",
  RAZORPAY_CHECKOUT = "Customer pay",
}

export const paymentModeIcons: GenericObject = {
  QR_CODE: QrCodeIcon,
  PAYMENT_LINK: PaymentLinkIcon,
};

export enum ADD_CHARGES_ACTION_TYPE {
  SAVE = "SAVE",
  SAVE_AND_PAY = "SAVE_AND_PAY",
}

export enum DisplayChargesNameTypes {
  TNC_VIOLATION = "Charges for violation of T&Cs",
  TRAFFIC_RULE_VIOLATION = "Traffic rule violation",
  OTHERS = "Others",
  PENALTY_CHARGES = "Penalty Charges",
  /* VOILATION_TC = "Charges for voilation of T&Cs",
  VOILATION_CHARGES = "Voilation charges",
  OTHERS = "Others", */
}

export enum chargesNameTypes {
  TNC_VIOLATION = "TNC_VIOLATION",
  TRAFFIC_RULE_VIOLATION = "TRAFFIC_RULE_VIOLATION",
  OTHERS = "OTHERS",
  PENALTY_CHARGES = "PENALTY_CHARGES",
  /* VOILATION_TC = "VOILATION_TC",
  VOILATION_CHARGES = "VOILATION_CHARGES",
  OTHERS = "OTHERS", */
}

export const chargesCategoriesObj: GenericObject = {
  TNC_VIOLATION: "TNC_VIOLATION",
  TRAFFIC_RULE_VIOLATION: "TRAFFIC_RULE_VIOLATION",
  OTHERS: "OTHERS",
  /* VOILATION_TC: "VOILATION_TC",
  VOILATION_CHARGES: "VOILATION_CHARGES",
  OTHERS: "OTHERS", */
};

export enum PaymentJourneyStatus {
  COLLECTED = "COLLECTED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum ADDITIONAL_CHARGES_STATUS {
  PENDING = "PENDING",
  PAID = "PAID",
  WAIVED_OFF = "WAIVED_OFF",
  DELETED = "DELETED",
  INITIATED = "INITIATED",
  COLLECTED = "COLLECTED",
}

export enum ADDITIONAL_CHARGES_DISPLAY_STATUS {
  PENDING = "Pending",
  PAID = "Paid",
  WAIVED_OFF = "Waived Off",
  DELETED = "Deleted",
  INITIATED = "Payment Initiated",
  COLLECTED = "Paid",
  PAYMENT_INITIATED = "Payment Initiated",
}

// Additional charges reason
export enum ReasonCategory {
  CANCELLATION_REASON = "CANCELLATION_REASON",
  LATE_START_RIDE_REASON = "LATE_START_RIDE_REASON",
  MODEL_MODIFICATION = "MODEL_MODIFICATION",
  WAIVE_OFF_REASONS = "WAIVE_OFF_REASONS",
}

// Challans & Extra Payment status
export enum BookingChargesStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  DELETED = "DELETED",
  WAIVED_OFF = "WAIVED_OFF",
}

export enum BookingChargesDispalyStatus {
  PENDING = "Pending",
  PROCESSING = "Proccessing",
  PAID = "Paid",
  PARTIALLY_PAID = "Partially paid",
  DELETED = "Deleted",
  WAIVED_OFF = "Waived Off",
}

export enum documentStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

// Vehicle Lob Status
export const newLobStatusArray: GenericObject[] = [
  {
    name: "",
    displayName: "All Status",
  },
  {
    name: LobStatus?.AVAILABLE,
    displayName: "Available",
  },
  {
    name: LobStatus?.ENGAGED,
    displayName: "Engaged",
  },
  {
    name: LobStatus?.ASSIGNED,
    displayName: "Assigned",
  },
  {
    name: LobStatus?.BLOCKED,
    displayName: "Blocked",
  },
  {
    name: VehicleStatus?.INACTIVE,
    displayName: "Inactive",
  },
  {
    name: VehicleStatus?.SOLD,
    displayName: "Sold",
  },
  {
    name: VehicleStatus?.PENDING_FOR_APPROVAL,
    displayName: "Pending",
  },
  {
    name: VehicleStatus?.REJECTED,
    displayName: "Rejected",
  },
];

export enum modificationType {
  DELIVERY_MODIFICATION = "DELIVERY_MODIFICATION",
  RETURN_DELIVERY_MODIFICATION = "RETURN_DELIVERY_MODIFICATION",
  HELMET_MODIFICATION = "HELMET_MODIFICATION",
  MODEL_MODIFICATION = "MODEL_MODIFICATION",
  VEHICLE_MODIFICATION = "VEHICLE_MODIFICATION",
  BOOKING_DATE_MODIFICATION = "BOOKING_DATE_MODIFICATION",
  SLOTS_MODIFICATION = "SLOTS_MODIFICATION",
  PLAN_MODIFICATION = "PLAN_MODIFICATION",
  PLAN_VARIANT_MODIFICATION = "PLAN_VARIANT_MODIFICATION",
}

export enum AddChargesInputLength {
  ADD_CHARGES_REMARK = 120,
}

export enum TypesOfFile {
  excel = "excel",
  zip = "zip",
}

export enum SurgefactorType {
  MULTIPLIER = "MULTIPLIER",
  INCREASE_BY_AMOUNT = "INCREASE_BY_AMOUNT",
}

export enum BookingPaymentStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELLED = "CANCELLED",
  INITIATED = "INITIATED",
}

export enum PaymentFlow {
  RAZORPAY_CHECKOUT = "RAZORPAY_CHECKOUT",
  QR_CODE = "QR_CODE",
  PAYMENT_LINK = "PAYMENT_LINK",
  MANDATE_PAYMENT_LINK = "MANDATE_PAYMENT_LINK",
  MANDATE_EXECUTION = "MANDATE_EXECUTION",
  CASH = "CASH",
}

export enum BookingPlanType {
  MAIN = "MAIN",
  EXTENSION = "EXTENSION",
}

export enum BookingChargesCategories {
  PLAN_CHARGES = "PLAN_CHARGES",
  ACCESSORY_CHARGES = "ACCESSORY_CHARGES",
  ADDITIONAL_CHARGES = "ADDITIONAL_CHARGES",
  MODIFICATION_CHARGES = "MODIFICATION_CHARGES",
  PENALTY_CHARGES = "PENALTY_CHARGES",
  OTHER_CHARGES = "OTHER_CHARGES",
}

export enum BookingChargesSubCategories {
  BOOKING_CHARGES = "BOOKING_CHARGES",
  EXTENSION_CHARGES = "EXTENSION_CHARGES",
  HELMET_CHARGES = "HELMET_CHARGES",
  SURGE_CHARGES = "SURGE_CHARGES",
  CONVENIENCE_CHARGES = "CONVENIENCE_CHARGES",
  DELIVERY_CHARGES = "DELIVERY_CHARGES",
  RETURN_DELIVERY_CHARGES = "RETURN_DELIVERY_CHARGES",
  HOME_DELIVERY_CHARGES = "HOME_DELIVERY_CHARGES",
  PICKUP_DELIVERY_CHARGES = "PICKUP_DELIVERY_CHARGES",
  TNC_VIOLATION = "TNC_VIOLATION",
  TRAFFIC_RULE_VIOLATION = "TRAFFIC_RULE_VIOLATION",
  EXCESS_HOUR_CHARGES = "EXCESS_HOUR_CHARGES",
  EXCESS_KM_CHARGES = "EXCESS_KM_CHARGES",
  SECURITY_CHARGES = "SECURITY_CHARGES",
  OTHERS = "OTHERS",
  CANCELLATION_CHARGES = "CANCELLATION_CHARGES",
  MODIFICATION_CHARGE = "MODIFICATION_CHARGE",
  MODEL_MODIFICATION = "MODEL_MODIFICATION",
  BOOKING_DATE_MODIFICATION = "BOOKING_DATE_MODIFICATION",
  SLOT_MODIFICATION = "SLOT_MODIFICATION",
  BOOKING_SLOT_MODIFICATION = "BOOKING_SLOT_MODIFICATION",
  LATE_EXTENSION_PENALTY = "LATE_EXTENSION_PENALTY",
  BOOKING_VARIANT_MODIFICATION = "BOOKING_VARIANT_MODIFICATION",
  MODIFICATION_POLICY_CHARGE = "MODIFICATION_POLICY_CHARGE",
  PENALTY_CHARGES = "PENALTY_CHARGES",
}
export enum BookingChargesSubCategoriesDisplayName {
  MANUAL_BOOKING_CHARGES = "Package charges",
  BOOKING_CHARGES = "Package charges",
  EXTENSION_CHARGES = "Extension charges",
  HELMET_CHARGES = "Helmet charges",
  SURGE_CHARGES = "Surge charges",
  CONVENIENCE_CHARGES = "Convenience charges",
  DELIVERY_CHARGES = "Delivery charges",
  RETURN_DELIVERY_CHARGES = "Return delivery charges",
  HOME_DELIVERY_CHARGES = "Home delivery charges",
  PICKUP_DELIVERY_CHARGES = "Pickup delivery charges",
  TNC_VIOLATION = "TNC vioaltion",
  TRAFFIC_RULE_VIOLATION = "Traffic rule violation",
  EXCESS_HOUR_CHARGES = "Excess hour charges",
  EXCESS_KM_CHARGES = "Excess km charges",
  SECURITY_CHARGES = "Security charges",
  OTHERS = "Others",
  CANCELLATION_CHARGES = "Cancellation charges",
  MODIFICATION_CHARGE = "Modificatiuon charge",
  MODEL_MODIFICATION = "Model modification",
  BOOKING_DATE_MODIFICATION = "Booking date modification",
  BOOKING_SLOT_MODIFICATION = "Booking slot modification charges",
  SLOT_MODIFICATION = "Booking slot modification",
  LATE_EXTENSION_PENALTY = "Late extension penalty",
  PLAN_VARIANT_MODIFICATION = "Plan variant modification",
  BOOKING_VARIANT_MODIFICATION = "Plan variant modification",
  MODIFICATION_POLICY_CHARGE = "Duration modification charges ",
  PENALTY_CHARGES = "Penalty charges",
}

export enum BookingRefundTypes {
  BOOKING_CANCELLATION = "Booking Cancellation",
}

// duation and how many times you want to call (counter)
export enum paymentStatusDurationPayload {
  counter = 21,
  delayDuration = 5000,
}

// enums for dashboard quick filters
export enum DashboardQuickFilter {
  NEW_BOOKING_RECIEVED = "NEW_BOOKING_RECIEVED",
  SCHEDULED_BOOKING = "SCHEDULED_BOOKING",
  NEAR_TO_RETURN_BOOKING = "NEAR_TO_RETURN_BOOKING",
  NOT_RETURNED = "NOT_RETURNED",
  RETURN_REQUESTS_RECEIVED = "RETURN_REQUESTS_RECEIVED",
}

// enums for plan and payments charge type
export enum BookingPaymentType {
  MAIN = "MAIN",
  EXTENSION = "EXTENSION",
  MODIFICATION = "MODIFICATION",
  ADDITIONAL_CHARGES = "ADDITIONAL_CHARGES",
}

// enum for appliesTo - role management
export enum appliesTo {
  BRANCH = "BRANCH",
  CITY = "CITY",
  STATE = "STATE",
  COUNTRY = "COUNTRY",
}
export enum BookingExtensionStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  DELETED = "DELETED",
  CANCELLED = "CANCELLED",
}

export const StatusArray = [
  {
    name: "ALL",
    displayName: "All Status",
  },
  {
    name: BookingStatusEnum.PENDING,
    displayName: displayBookingStatus.PENDING,
  },
  {
    name: BookingStatusEnum.UPCOMING,
    displayName: displayBookingStatus.UPCOMING,
  },
  {
    name: BookingStatusEnum.ONGOING,
    displayName: displayBookingStatus.ONGOING,
  },
  {
    name: BookingStatusEnum.ENDED,
    displayName: displayBookingStatus.ENDED,
  },

  {
    name: BookingStatusEnum.COMPLETED,
    displayName: displayBookingStatus.COMPLETED,
  },

  {
    name: BookingStatusEnum.CANCELLED,
    displayName: displayBookingStatus.CANCELLED,
  },
];

export enum ReportType {
  BOOKING = "BOOKING",
  EXTENSION = "EXTENSION",
  CHALLAN = "CHALLAN",
  CANCELLATION_AND_REFUND = "CANCELLATION_AND_REFUND",
  USER = "USER",
  PAYMENT_LTR = "PAYMENT_LTR",
  FEEDBACK = "FEEDBACK",
  EXTRA_CHARGES = "EXTRA_CHARGES",
  VEHICLE_CHANGE = "VEHICLE_CHANGE",
}

// enum for UserStatus - Role management
export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = " DELETED",
  BLOCKED = "BLOCKED",
  LOCKED = "LOCKED",
}

// file upload errors
export const uploadError: GenericObject = {
  "file-invalid-type": "Select valid file format",
  "file-too-large": "Select file less than 5MB",
  "too-many-files": "To many Files ",
};
export const emailIdLength: number = 50;
export const mobileNoLength: number = 10;
export const userNameLength: number = 50;
export const panNumberLength: number = 10;
export const getNumberLength: number = 50;
export const accountNumberLength: number = 25;
export const ifscLength: number = 11;
export const gstNumberLength: number = 15;

export enum bookingQuickFilters {
  START_RIDES_TODAY = "START_RIDES_TODAY",
  END_RIDES_TODAY = "END_RIDES_TODAY",
  CANCELED_RIDES_TODAY = "CANCELED_RIDES_TODAY",
  NEW_BOOKINGS_TODAY = "NEW_BOOKINGS_TODAY",
}

export enum TaskAssignmentStatusTabsDisplayName {
  START_RIDE = "Start Ride",
  COMPLETE_RIDE = "Complete Ride",
  SERVICE = "Service",
  OTHERS = "Others",
}

// Used for fetching images on booking detail success
export enum BookingImagesTypes {
  START_RIDE = "START_RIDE",
  END_RIDE = "END_RIDE",
  START_RIDE_ODOMETER = "START_RIDE_ODOMETER",
  END_RIDE_ODOMETER = "END_RIDE_ODOMETER",
  VEHICLE_EXTENSION = "VEHICLE_EXTENSION",
}
export enum vehicleImageUploadType {
  MODEL_IMAGES = "MODEL_IMAGES",
  VEHICLE_IMAGES = "VEHICLE_IMAGES",
  VEHICLE_DOCUMENT = "VEHICLE_DOCUMENT",
  ODOMETER_IMAGES = "ODOMETER_IMAGES",
}

export enum discountsTypes {
  PROMOTION = "PROMOTION",
  COUPON_CODE = "COUPON_CODE",
}

export enum OperatorLeadStatus {
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
  CONVERTED = "CONVERTED",
  AGREEMENT_GENERATED = "AGREEMENT_GENERATED",
  AGREEMENT_VERIFIED = "AGREEMENT_VERIFIED",
}

export enum OperatorLeadStatusDisplayStatus {
  VERIFIED = "New",
  UNVERIFIED = "Unverified",
  CONVERTED = "Converted",
  AGREEMENT_GENERATED = "In Process",
  AGREEMENT_VERIFIED = "Pending",
}

export enum BusinessType {
  LLP = "LLP",
  NGO = "NGO",
  INDIVIDUAL = "INDIVIDUAL",
  PARTNERSHIP = "PARTNERSHIP",
  PROPRIETORSHIP = "PROPRIETORSHIP",
  PUBLIC_LIMITED = "PUBLIC_LIMITED",
  PRIVATE_LIMITED = "PRIVATE_LIMITED",
  TRUST = "TRUST",
  SOCIETY = "SOCIETY",
  NOT_YET_REGISTERED = "NOT_YET_REGISTERED",
  EDUCATIONAL_INSTITUTES = "EDUCATIONAL_INSTITUTES",
}

export enum BookingEndSlotType {
  SAME_AS_START_SLOT = "sameAsStartSlot",
  START_OF_THE_DAY = "startOfTheDay",
  END_OF_THE_DAY = "endOfTheDay",
}

export enum OperatorStatusColorCode {
  VERIFIED = "#1abc9c",
  UNVERIFIED = "#f1c40f",
  CONVERTED = "#2ecc71",
  PENDING = "#FF7A00",
  AGREEMENT_GENERATED = "#f1c40f",
  AGREEMENT_VERIFIED = "#FF7A00",
}

export enum OperatorConfigurations {
  kycOnStartRideEnabled = "Customer KYC",
  codAllowed = "Cash On Delivery",
  emergencyContactVerificationOnStartRide = "Emergency Contact Verified",
  securityDepositEnabled = "Security Deposit",
  couponCodeEnabled = "Coupon Code",
  taskManagementMandatory = "Task Management",
  iotMandatoryOnStartRide = "IOT Ping on Start Ride",
}

export enum operatorDocumentUploadTypes {
  stakeHoldersPanDetails = "STAKEHOLDER_PAN_CARD",
  bankAccountDetails = "BANK_ACCOUNT_PROOF",
  businessGstDetails = "GST_REGISTRATION_CERTIFICATE",
  businessPanDetails = "BUSINESS_PAN_CARD",
}

export enum AuthUsecases {
  ForgotPassword = "forgot-password",
  UnlockAccount = "unlock-account",
  PasswordExpire = "password-expire",
  FirstLogin = "first-login",
}

export enum authApiUsecases {
  FIRST_LOGIN = "FIRST_LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  UNLOCK_ACCOUNT = "UNLOCK_ACCOUNT",
}

export enum OperatorKycStatus {
  PENDING = "PENDING",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}

export enum OperatorKycStatusDisplayValue {
  PENDING = "Pending",
  UNVERIFIED = "Initiated",
  VERIFIED = "Verified",
  REJECTED = "Rejected",
  DELETED = "Deleted",
}

export enum OperatorKycStatusColor {
  PENDING = "#FF7A00",
  UNVERIFIED = "#f1c40f",
  VERIFIED = "#1BC47D",
  REJECTED = "rgb(114, 114, 114)",
  DELETED = "#FF3B30",
}

export const FREEDOOPERATORID = "FREEDO";

export enum ApprovalOptions {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
export enum vmsLob {
  LTR = "LTR",
  R2O = "R2O",
}

export const BookingEndSlotOptions = [
  {
    name: BookingEndSlotType.SAME_AS_START_SLOT,
    displayName: rentalEn.operatorManagement.sameAsStartSlot,
  },
  {
    name: BookingEndSlotType.START_OF_THE_DAY,
    displayName: rentalEn.operatorManagement.nextDay9Am,
  },
  {
    name: BookingEndSlotType.END_OF_THE_DAY,
    displayName: rentalEn.operatorManagement.sameDay9Pm,
  },
];

export enum TotalBookingsOpt {
  GT = "GT",
  LT = "LT",
  GTEQ = "GTEQ",
  LTEQ = "LTEQ",
  EQ = "EQ",
}

// Payment status array for dropdown list
export const paymentStatusArray: GenericObject[] = [
  {
    name: BookingPaymentStatus?.SUCCESS,
    displayName: paymentManagementLocale?.success,
  },
  {
    name: BookingPaymentStatus?.FAILURE,
    displayName: paymentManagementLocale?.failure,
  },
  {
    name: BookingPaymentStatus?.PENDING,
    displayName: paymentManagementLocale?.pending,
  },
  {
    name: BookingPaymentStatus?.CANCELLED,
    displayName: paymentManagementLocale?.cancelled,
  },
  {
    name: BookingPaymentStatus?.INITIATED,
    displayName: paymentManagementLocale?.initiated,
  },
];

export enum AccountMIDStatus {
  PENDING = "Verification Pending",
  PROCESSING = "Processing",
  ACTIVE = "Activated ",
  INACTIVE = "Not Activated",
  FAILED = "Failed",
  DELETED = "Deleted",
}
