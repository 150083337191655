import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useSelector } from "react-redux";
import { colors } from "../themes/colors";
import { ActionView } from "./actionView";
import { StatusActive } from "./kycStatus";
import { Status } from "./status";
import { UserActions } from "../constants/userActions";

export default function StickyHeaderTable({
  pageSetter = (page) => {},
  rowsPerPageSetter = (rows) => {},
  rows = [],
  actions = [],
  columns = [],
  totalItems = 0,
  size = 10,
  page = 0,
  resetSize = false,
  paddingAction = "",
  displayTable = {},
  loading = false,
  onClickAction = (type, id, vehicleId, allData) => {},
  onActionShow = (type, id) => {},
  handleSort = (param) => {},
  hidePagination = false,
  maxHeight = 0.7,
  showBrand = false,
  localPagination = false,
  width = "100%",
  extraData = {},
  rowStyle = {},
  widthOffset = 25,
}) {
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state) => state.localeReducer);

  const { dashboard, global } = locale;
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const [pageNo, setPageNo] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(size);

  React.useEffect(() => {
    setRowsPerPage(size);
  }, [size]);

  React.useEffect(() => {
    setPageNo(page);
  }, [page]);

  React.useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const handleChangePage = (event, newPage) => {
    pageSetter(newPage);
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    rowsPerPageSetter(+event.target.value);
    setPageNo(0);
  };
  React.useEffect(() => {
    // alert()
    setPageNo(0);
    setRowsPerPage(10);
  }, [resetSize]);

  const handleRowsTest = (val) => {
    rowsPerPageSetter(val);
  };

  // const handleClick=(id)=>{
  //   setSortOrder(sortOrder.set(id,!sortOrder.get(id)));
  //   if(sortOrder.get(id)===true)
  //   setRowss(_.sortBy(rows,id));
  //   else
  //   setRowss(_.sortBy(rows,id).reverse());

  // }
  React.useEffect(() => {
    setRowsPerPage(size);
  }, [size]);
  React.useEffect(() => {
    setPageNo(0);
    setRowsPerPage(10);
  }, [resetSize]);

  return (
    <Grid
    // style={{ maxWidth: `calc(100% - ${widthOffset}px)` }}
    // style={{ maxWidth: screenSize.dynamicWidth - 25 }}
    >
      <Paper
        sx={{
          width: width,
          overflow: "hidden",
          marginTop: 1,
        }}
      >
        <TableContainer
          // sx={{ maxHeight: screenSize.dynamicHeight * maxHeight }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minWidth: 650,
              "& .MuiTableRow-root:hover": {
                backgroundColor: "rgba(25, 189, 189,0.2)",
              },
            }}
          >
            <TableHead style={{ backgroundColor: "rgb(36, 43, 53)" }}>
              <TableRow>
                {/* {rows && rows?.length === 0 ? (
                  <TableCell
                    key={1}
                    align={"left"}
                    style={{
                      // minWidth: screenSize.dynamicWidth * 0.825,
                      backgroundColor: "rgb(36, 43, 53)",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: isDesktopOrTablet ? "15px" : "13px",
                      }}
                    >
                      Sr No.
                    </Typography>
                  </TableCell>
                ) : ( */}
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "rgb(36, 43, 53)",
                      position: column.id === "link" ? "sticky" : "",
                      right: column.id === "link" ? 0 : "auto",
                      zIndex: column.id === "link" ? 10 : "",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: isDesktopOrTablet ? "15px" : "13px",
                      }}
                    >
                      {column.label}
                      &nbsp;
                      <span>
                        {column?.sortable ? (
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleSort({
                                key: column.sortKey,
                                direction: column.direction,
                                item: index,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              //onClick={() => handleClick(column.id)}
                              size="xs"
                              icon={
                                column?.direction == "desc"
                                  ? faArrowDownShortWide
                                  : faArrowUpShortWide
                              }
                              color="white"
                            />
                          </IconButton>
                        ) : null}
                      </span>
                    </Typography>
                  </TableCell>
                ))}
                {/* )} */}
              </TableRow>
            </TableHead>
            {loading === true ? (
              <TableBody
              // style={{ minWidth: 200 }}
              >
                {new Array(6).fill(1).map((eachData) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={Math.random()}
                    style={{ backgroundColor: colors.white }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={Math.random()}
                        // align={column.align}
                      >
                        <Skeleton
                          variant={"rounded"}
                          style={{
                            height: 30,
                            // width: screenSize.dynamicWidth * 0.78,
                          }}
                          animation="wave"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : rows && rows?.length === 0 ? (
              <>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      <CustomNoResultsOverlay />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  (localPagination
                    ? rows.slice(
                        pageNo * rowsPerPage,
                        pageNo * rowsPerPage + rowsPerPage
                      )
                    : rows
                  )?.map((row, k) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code || k}
                        style={{
                          // backgroundColor: colors.light_gray,
                          position: "relative",
                          ...rowStyle,
                        }}
                        // onClick={(e) => {
                        // e.stopPropagation()
                        // onRowClick(row.id, row);
                        // }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell
                              style={{
                                width: 120,
                                maxWidth: 120,
                                overflow: "hidden",
                                fontFamily: "EuclidCircularB-Regular",
                                wordBreak: "break-all",
                                backgroundColor: "white",
                                position: column.id === "link" ? "sticky" : "",
                                right: column.id === "link" ? 0 : "auto",
                                zIndex: column.id === "link" ? 5 : "auto",
                                fontSize: isDesktopOrTablet ? "13px" : "12px",
                                display: column.id === "link" ? "flex" : "",
                                justifyContent:
                                  column.id === "link" ? "center" : "",
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {column?.customJson === true ? (
                                <div>
                                  {column?.render(
                                    row,
                                    size * page + k + 1,
                                    extraData
                                  )}
                                </div>
                              ) : column?.id === "action" ? (
                                <ActionView
                                  onClickAction={(type) => {
                                    onClickAction(
                                      type,
                                      row.id,
                                      row.vehicleId,
                                      row
                                    );
                                  }}
                                  padding={paddingAction}
                                  // actions={actions}
                                  actions={
                                    displayTable.flag ==
                                      "new_booking_received" ||
                                    displayTable.flag == "scheduled_bookings"
                                      ? row.action
                                      : row.priority === 0
                                      ? actions
                                      : null
                                    // Added above line to hide actions if task has priority i.e if it is mandatory.
                                  }
                                  // actions={row.action}
                                />
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "status" ||
                                column.label === global.Status ? (
                                <Status status={value} />
                              ) : column.id === "kycStatus" ? (
                                <StatusActive kycStatus={value} />
                              ) : (
                                <Tooltip title={value} arrow>
                                  <text style={{ wordBreak: "keep-all" }}>
                                    {value}
                                  </text>
                                </Tooltip>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* Pagination */}
        {hidePagination || (rows && rows?.length === 0) ? null : (
          <Grid
            container
            style={{ backgroundColor: colors.white }}
            alignItems="flex-end"
            justifyContent={"flex-end"}
          >
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={localPagination ? rows.length : totalItems}
              rowsPerPage={rowsPerPage}
              page={pageNo}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
        {/* <Grid container style={{ padding: '10px' , }} alignItems="flex-end" justifyContent={"flex-end"}>
          <Pagination count={10} color="primary" />
        </Grid> */}
      </Paper>
    </Grid>
  );
}

const styles = (theme) => ({
  tableRow: {
    "&:hover": {
      backgroundColor: "blue !important",
    },
  },
});

const StyledGridOverlay = styled("div")((theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  marginTop: "30px",
  "& .no-results-primary": {
    fill: "#707070",
  },
  "& .no-results-secondary": {
    fill: "#707070",
  },
}));

function CustomNoResultsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 523 299"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-results-primary"
          d="M262 20c-63.513 0-115 51.487-115 115s51.487 115 115 115 115-51.487 115-115S325.513 20 262 20ZM127 135C127 60.442 187.442 0 262 0c74.558 0 135 60.442 135 135 0 74.558-60.442 135-135 135-74.558 0-135-60.442-135-135Z"
        />
        <path
          className="no-results-primary"
          d="M348.929 224.929c3.905-3.905 10.237-3.905 14.142 0l56.569 56.568c3.905 3.906 3.905 10.237 0 14.143-3.906 3.905-10.237 3.905-14.143 0l-56.568-56.569c-3.905-3.905-3.905-10.237 0-14.142ZM212.929 85.929c3.905-3.905 10.237-3.905 14.142 0l84.853 84.853c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0l-84.853-84.853c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-results-primary"
          d="M212.929 185.071c-3.905-3.905-3.905-10.237 0-14.142l84.853-84.853c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-84.853 84.853c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-results-secondary"
          d="M0 43c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 53 0 48.523 0 43ZM0 89c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 99 0 94.523 0 89ZM0 135c0-5.523 4.477-10 10-10h74c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 181c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 227c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM523 227c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10ZM523 181c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 135c0 5.523-4.477 10-10 10h-74c-5.523 0-10-4.477-10-10s4.477-10 10-10h74c5.523 0 10 4.477 10 10ZM523 89c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 43c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No results found</Box>
    </StyledGridOverlay>
  );
}
