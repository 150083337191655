import { toastr } from "react-redux-toastr";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import kycDocumentsService from "../services/kycDocumentsService";
import * as actionTypes from "../actions/actionTypes";
import { KycTypes } from "../../constants/constants";
import { getKycPayloadTypes } from "../../utils/kycHelper";

interface actionInterface {
  type: string;
  payload?: any;
}
// get signed url for doc upload
function* getKycSignedUrl(action: actionInterface): any {
  try {
    const response = yield call(
      kycDocumentsService.getKycSignedUrlService,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_REQUESTED,
        payload: {
          signedUrlResponse: response?.data?.data[0],
          filedata: action?.payload.filesData,
          kycPayloadObj: action?.payload.kycPayloadObj,
          kycSubType: action?.payload.kycSubType,
        },
      });

      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .KYC_DOCUMENT_UPLOAD_REQUESTED,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .KYC_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* putKycAttachment(action: actionInterface): any {
  try {
    const { signedUrlResponse, filedata, kycType, kycPayloadObj, kycSubType } =
      action.payload;
    let result = yield call(kycDocumentsService?.uploadDocumentService, {
      url: signedUrlResponse?.signedUrl,
      file: filedata,
    });

    if (result.status === 200) {
      // use helper to get payload
      // let kycTypePayloadObj = getKycPayloadTypes(kycType.type);

      // SELFIE
      if (kycPayloadObj.type === KycTypes.SELFIE) {
        kycPayloadObj.details.selfieAttachmentId = signedUrlResponse?.id;
      }

      // DRIVING_LICENCE
      if (kycPayloadObj.type === KycTypes.DRIVING_LICENCE) {
        if (kycSubType === "frontImageAttachmentId") {
          kycPayloadObj.details.frontImageAttachmentId = signedUrlResponse?.id;
        }
        if (kycSubType === "backImageAttachmentId") {
          kycPayloadObj.details.backImageAttachmentId = signedUrlResponse?.id;
        }
      }

      // AADHAAR
      if (kycPayloadObj.type === KycTypes.AADHAAR) {
        if (kycSubType === "frontImageAttachmentId") {
          kycPayloadObj.details.frontImageAttachmentId = signedUrlResponse?.id;
        }
        if (kycSubType === "backImageAttachmentId") {
          kycPayloadObj.details.backImageAttachmentId = signedUrlResponse?.id;
        }
      }

      // OTHER_DOCUMENT
      if (kycPayloadObj.type === KycTypes.OTHER_DOCUMENT) {
        if (kycSubType === "ELECTRICITY_BILL") {
          kycPayloadObj.details["ELECTRICITY_BILL"].attachmentIds = [
            signedUrlResponse?.id,
          ];
        }
        if (kycSubType === "VOTER_ID") {
          kycPayloadObj.details["VOTER_ID"]["attachmentIds"] = [
            signedUrlResponse?.id,
          ];
        }
        if (kycSubType === "BANK_DETAIL") {
          kycPayloadObj.details["BANK_DETAIL"]["attachmentIds"] = [
            signedUrlResponse?.id,
          ];
        }
        if (kycSubType === "RENT_BILL") {
          kycPayloadObj.details["RENT_BILL"]["attachmentIds"] = [
            signedUrlResponse?.id,
          ];
        }
        if (kycSubType === "PASSPORT") {
          kycPayloadObj.details["PASSPORT"]["attachmentIds"] = [
            signedUrlResponse?.id,
          ];
        }
        if (kycSubType === "OTHER") {
          kycPayloadObj.details["OTHER"]["attachmentIds"] = [
            signedUrlResponse?.id,
          ];
        }
      }

      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_SUCCESS,
        payload: {
          attachmentsIds: signedUrlResponse?.id,
          customerId: filedata?.customerId,
          kycTypePayloadObj: kycPayloadObj,
        },
      });
    } else {
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_FAILED,
        payload: signedUrlResponse,
      });
      toastr.error("", "Document Upload Failed");
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .KYC_DOCUMENT_UPLOAD_FAILED,
      });
    }

    /* const response = data?.filter((data: any) => {
      if (data !== "err") return data;
    }); */
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

function* kycDocumentUploadS3(action: any): any {
  try {
    let response;
    if (action.payload.kycPayload.type === KycTypes.OTHER_DOCUMENT) {
      response = yield call(
        kycDocumentsService.moveKycAttachmentToS3OtherDoc,
        action.payload
      );
    } else {
      response = yield call(
        kycDocumentsService.moveKycAttachmentToS3,
        action.payload
      );
    }

    const trustScorePayload = { id: action.payload.customerId };

    if (response.status === 200 || response.status === 201) {
      if(action.payload.kycPayload.type !== KycTypes.AADHAAR) {
        toastr.success("", handleMessage(response));
      }
      let responseData = response.data.data;

       yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .POST_KYC_DOCUMENT_IN_S3_SUCCESS,
      }); 
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .KYC_DOCUMENT_UPLOAD_SUCCESS,
      });

      // Aadhar - Redirect to digilocker if verifyDigitally is true
      if(action.payload.kycPayload.type === KycTypes.AADHAAR && action?.payload?.kycPayload?.details?.verifyDigitally) {
        let redirectToUrl = responseData?.details?.url;
        if(redirectToUrl) window.open(redirectToUrl, '_blank');
      }

      yield delay(2000);
      
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .POST_KYC_DOCUMENT_IN_S3_FAILED,
      });

      yield put({
        type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
          .KYC_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    // In Discussion to call reset kycPayloadObj in failure and ask for document reupload
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.KYC_DOCUMENT_UPLOAD_SUCCESS,
    });
  }
}

function* kycDocumentsSaga() {
  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.GET_KYC_SIGNED_URLS_REQUESTED,
    getKycSignedUrl
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS
      .PUT_KYC_ATTACHMENT_ON_SIGNED_URL_REQUESTED,
    putKycAttachment
  );

  yield takeLatest(
    actionTypes.CUSTOMER_MANAGEMENT_ACTIONS.POST_KYC_DOCUMENT_IN_S3_REQUESTED,
    kycDocumentUploadS3
  );
}

export default kycDocumentsSaga; 
