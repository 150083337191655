import { Stack } from "@mui/material";
import { GenerateReport } from "./section/GenerateReport";

export const Reports = (props: any) => {
  return (
    <Stack>
      <GenerateReport />
    </Stack>
  );
};
