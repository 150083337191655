import { toastr } from "react-redux-toastr";
import { Task } from "redux-saga";
import {
  call,
  cancel,
  cancelled,
  delay,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import { actionInterface } from "../../interfaces/commonInterfaces";
import bookingManagementXServices from "../../services/bookingManagementXServices";
import * as actionTypes from "../actions/actionTypes";
import { bookingDetailsModel } from "../models/bookingDeatilsModels";

function* getBookingDetails(action: any): any {
  try {
    const data = yield call(
      bookingManagementXServices.getBookingDetails,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_BOOKING_DETAILS_X_SUCCESS,
        payload: bookingDetailsModel(data?.data?.data),
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_BOOKING_DETAILS_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_FAILED,
    });
  }
}

function* getBookingStatus(action: any): any {
  while (true) {
    try {
      const data = yield call(
        bookingManagementXServices.getBookingStatus,
        action.payload
      );
      if (data?.status == 200) {
        yield put({
          type: actionTypes.BOOKING_DETAILS_ACTIONS_X
            .GET_BOOKING_STATUS_X_SUCCESS,
          payload: data?.data?.data?.status,
        });
        if (
          data?.data?.data?.status == "BOOKED" ||
          data?.data?.data?.status == "ACTIVE" ||
          data?.data?.data?.status == "CANCELLED"
        ) {
          break;
        }
        yield delay(10000);
      } else {
        toastr.error("", handleMessage(data));
        yield put({
          type: actionTypes.BOOKING_DETAILS_ACTIONS_X
            .GET_BOOKING_STATUS_X_FAILED,
        });
        break;
      }
    } catch (error) {
      toastr.error("", handleMessage(error));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_STATUS_X_FAILED,
      });
      break;
    } finally {
      if (yield cancelled()) break;
    }
  }
}

function* startRide(action: any): any {
  try {
    const response = yield call(
      bookingManagementXServices.startRideX,
      action.payload
    );
    if (response?.status === 200 || response?.status === 201) {
      toastr.success("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_SUCCESS,
        payload: response,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_FAILED,
    });
  }
}

function* getAvailableVehicle(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagementXServices.getAvailableVehiclesX,
      action.payload
    );

    if (response?.status === 200 || response?.status === 201) {
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_AVAILABLE_VEHICLE_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_AVAILABLE_VEHICLE_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.BOOKING_DETAILS_ACTIONS_X
        .GET_AVAILABLE_VEHICLE_LIST_FAILED,
    });
  }
}

function* getPaymentPendingX(action: actionInterface): any {
  try {
    const response = yield call(
      bookingManagementXServices.getPaymentPendingX,
      action.payload
    );

    const paymentPending = response?.data?.data;

    if (response?.status == 200 || response?.status == 201) {
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_PENDING_PAYMENT_STATUS_X_SUCCESS,
        payload: { paymentPending: paymentPending },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X
          .GET_PENDING_PAYMENT_STATUS_X_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_DETAILS_ACTIONS_X
        .GET_PENDING_PAYMENT_STATUS_X_FAILED,
    });
  }
}

function* cancelBookingX(action: any): any {
  try {
    const bookingId = action.payload.bookingId;
    const data = yield call(
      bookingManagementXServices.cancelBookingX,
      bookingId
    );
    if (data?.status == 200) {
      if (
        action.payload.cancelActionType &&
        action.payload.cancelActionType === "CANCEL_THEN_CREATE_BOOKING"
      ) {
        const duplicateBookingModal = {
          show: false,
          bookingId: "",
        };
        // Reset Duplicate Booking Modal
        yield put({
          type: actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_X,
        });
        yield put({
          type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_REQUESTED_X,
          payload: action.payload.createBookingPayload,
        });
      } else {
        toastr.success("", handleMessage(data));

        yield put({
          type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_SUCCESS,
          payload: data?.data?.data?.status,
        });
      }
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_FAILED,
      });
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
    yield put({
      type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_FAILED,
    });
  }
}

function* closeBookingX(action: any): any {
  try {
    const bookingId = action.payload.bookingId;
    const data = yield call(
      bookingManagementXServices.closeBookingX,
      bookingId
    );

    if (data?.status == 200) {
      yield put({
        type: actionTypes.BOOKING_DETAILS_ACTIONS_X.CLOSE_BOOKING_X_SUCCESS,
        payload: data?.data?.data?.status,
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (ex) {
    toastr.error("", handleMessage(ex));
  }
}

function* allBookingSagaX() {
  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.USER_START_RIDE_X_REQUESTED,
    startRide
  );

  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_AVAILABLE_VEHICLE_LIST_REQUESTED,
    getAvailableVehicle
  );

  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_DETAILS_X_REQUESTED,
    getBookingDetails
  );
  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X
      .GET_PENDING_PAYMENT_STATUS_X_REQUESTED,
    getPaymentPendingX 
  );

  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_BOOKING_X_REQUESTED,
    cancelBookingX
  );

  yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.CLOSE_BOOKING_X_REQUESTED,
    closeBookingX
  );
  const responseStatus: Task = yield takeLatest(
    actionTypes.BOOKING_DETAILS_ACTIONS_X.GET_BOOKING_STATUS_X_REQUESTED,
    getBookingStatus
  );

  //const responseStatus: Task = yield take(getBookingStatus);

  yield take(actionTypes.BOOKING_DETAILS_ACTIONS_X.CANCEL_STATUS_X_TASK);
  yield cancel(responseStatus);
}

export default allBookingSagaX;
