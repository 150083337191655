import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { cloudUploadIcon } from "../../../../../assets/exportImages";
import { RootState } from "../../../../../redux/reducers";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import KycUploadLoader from "../../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/KycUploadLoader";
import { colors } from "../../../../../themes/colors";
import {
  DocumentUploadConstants,
  KycTypes,
  KycVerificationMode,
  KycVerificationModeDisplayName,
  KycVerificationStatus,
  KycVerificationStatusDisplayName,
  typographyConstants,
} from "../../../../constants/constants";
import {
  NotesCheck,
  alertNotes,
  circularLineIcon,
  deleteKyc,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { getKYCSignedUrl } from "../../../../redux/actions/customerManagementActions";
import styles from "./section.module.css";
interface generateSignedUrlPayload {
  requestdata: {
    data: {
      fileNames: string[];
    };
  };
  filesData: {
    customerId: string;
    files: object[];
    fileNames: string[];
  };
  kycPayloadObj: any;
  kycSubType: string;
}
interface previewInterface {
  frontImage: {
    name: string;
    url: string;
  };
  backImage: {
    name: string;
    url: string;
  };
}

export const AdhaarModal = ({
  customerId,
  aadhaarTrustScore,
  aadhaarPayloadObj,
  individualKycDetailsData,
  individualKycDetailsLoader,
  handleSaveAndVerifyUploadToS3,
  handleManualKycVerification,
  handleDocsPreviewNavigation,
  handleDeleteCustomerKyc,
  handleUnverifyCustomerKyc,
  isFunctionalityBlocked,
  isKycDeletePermissionEnabled,
}: any) => {
  const dispatch = useDispatch();
  const {
    uploadLoader,
    signedUrlFailedFiles,
    linearProgressValue,
    kycModalLoader,
  } = useSelector((state: RootState) => state.customerManagementReducer);

  const initialPreview: previewInterface = {
    frontImage: {
      name: "",
      url: "",
    },
    backImage: {
      name: "",
      url: "",
    },
  };

  const initialAadharUploading: any = {
    front: false,
    back: false,
  };
  interface notesStatusInterface {
    isModified: boolean;
    upload: boolean;
    format: boolean;
    size: boolean;
  }
  const initialNotesStatus: notesStatusInterface = {
    isModified: false,
    upload: false,
    format: false,
    size: false,
  };
  const [aadharPreview, setAadharPreview] = useState(initialPreview);
  const [aadharUploading, setAadharUploading] = useState(
    initialAadharUploading
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openUnverifyConfirmation, setOpenUnverifyConfirmation] =
    useState(false);
  const [notesStatus, setNotesStatus] = useState(initialNotesStatus);

  const isPresent = aadhaarTrustScore?.isPresent;
  const isVerified = aadhaarTrustScore?.isVerified;
  const kycId = aadhaarTrustScore?.kycId;
  const verificationMode = aadhaarTrustScore?.verificationMode;

  const aadharDetails = individualKycDetailsData?.details
    ? individualKycDetailsData?.details
    : {};

  let isPreviewPresent = false;
  if (
    (aadharPreview["frontImage"]["name"] &&
      aadharPreview["frontImage"]["url"]) ||
    (aadharPreview["backImage"]["name"] && aadharPreview["backImage"]["url"])
  ) {
    isPreviewPresent = true;
  }

  // Aadhaar Data
  const aadharData = aadharDetails?.aadhaarDetail
    ? aadharDetails?.aadhaarDetail
    : aadharDetails?.aadhaarDetail;
  const aadharDataLength = Object.keys(aadharDetails).length;

  const aadharDetailsArr = Object.keys(aadharDetails);
  const aadharDetailsLength = aadharDetailsArr.length;
  const verificationDetail = individualKycDetailsData?.verificationDetail
    ? individualKycDetailsData?.verificationDetail
    : {};
  const verificationDetailArr = Object.keys(verificationDetail);
  const verificationDetailLength = verificationDetailArr.length;
  const aadharFileExists =
    aadharDetailsLength > 0 &&
    aadharDetails?.files &&
    aadharDetails?.files.length > 0
      ? true
      : false;
  const aadharDetailsFiles =
    aadharDetailsLength > 0 &&
    aadharDetails?.files &&
    aadharDetails?.files.length > 0
      ? aadharDetails?.files[0]
      : [];
  const isView = isPresent;

  const dropZoneSetting = {
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    maxSize: 5000000,
    multiple: false,
    noClick: uploadLoader,
    noDrag: uploadLoader,
    disabled: uploadLoader || isFunctionalityBlocked,
  };

  // toggle delete confirmation
  const toggleDeleteAadharConfirmation = () => {
    setOpenDeleteConfirmation((prev) => !prev);
  };
  // toggle unverify confirmation
  const toggleUnverifyAadhaarConfirmation = () => {
    setOpenUnverifyConfirmation((prev) => !prev);
  };

  /* KYC Upload logic */

  // Initiate Aadhar upload (Front/Back) : generate signed url
  const generateSignedUrl = (
    fileNames: string[],
    files: any,
    aadharType: string
  ) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        customerId: customerId,
        files,
        fileNames,
      },
      kycPayloadObj: aadhaarPayloadObj,
      kycSubType: aadharType,
    };

    dispatch(getKYCSignedUrl(payload));
  };

  /* ------------- Aadhar front image ----------------------- */
  const onDropAadharFront = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        setAadharPreview((prev: any) => {
          return {
            ...prev,
            frontImage: { url: URL.createObjectURL(file), name: file.name },
          };
        });
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorDlAadhaar
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, "frontImageAttachmentId");
      }
    },
    []
  );

  const {
    getRootProps: getRootPropsAadharFront,
    getInputProps: getInputPropsAadharFront,
  } = useDropzone({
    onDrop: onDropAadharFront,
    ...dropZoneSetting,
  });

  /* ------------- Aadhar back image ----------------------- */
  const onDropAadharBack = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        setAadharPreview((prev: any) => {
          return {
            ...prev,
            backImage: { url: URL.createObjectURL(file), name: file.name },
          };
        });
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorDlAadhaar
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files, "backImageAttachmentId");
      }
    },
    []
  );

  const {
    getRootProps: getRootPropsAadharBack,
    getInputProps: getInputPropsAadharBack,
  } = useDropzone({
    onDrop: onDropAadharBack,
    ...dropZoneSetting,
  });
  /* -------------------------------------------------------- */

  const dlStyles = {
    objectFit: "contain",
    width: "100%",
    // width: "100px",
    // backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    // height: "90px",
  };
  const dlFrontSx =
    (aadharDetailsLength > 0 &&
      aadharDetails?.files &&
      aadharDetails?.files.length > 0) ||
    (aadharPreview.frontImage.url && linearProgressValue === 100)
      ? {
          background:
            aadharDetailsLength > 0 &&
            aadharDetails?.files &&
            aadharDetails?.files.length > 0
              ? `url(${aadharDetails.files[0].signedUrl})`
              : aadharPreview.frontImage.url && linearProgressValue === 100
              ? `url(${aadharPreview.frontImage.url})`
              : `none`,
          height: "130px",
          ...dlStyles,
        }
      : { cursor: "pointer" };
  const dlBackSx =
    (aadharDetailsLength > 0 &&
      aadharDetails?.files &&
      aadharDetails?.files.length > 0) ||
    (aadharPreview.backImage.url && linearProgressValue === 100)
      ? {
          background:
            aadharDetailsLength > 0 &&
            aadharDetails?.files &&
            aadharDetails?.files.length > 0
              ? `url(${aadharDetails.files[0].signedUrl})`
              : aadharPreview.backImage.url && linearProgressValue === 100
              ? `url(${aadharPreview.backImage.url})`
              : `none`,
          height: "130px",
          ...dlStyles,
        }
      : { cursor: "pointer" };

  useEffect(() => {
    if (
      (aadhaarPayloadObj?.details?.frontImageAttachmentId &&
        aadharPreview.frontImage.url) ||
      (aadhaarPayloadObj?.details?.backImageAttachmentId &&
        aadharPreview.backImage.url)
    ) {
      let aadhaarUploaded = false;
      aadhaarUploaded =
        aadhaarPayloadObj?.details?.frontImageAttachmentId &&
        aadharPreview.frontImage.url &&
        aadhaarPayloadObj?.details?.backImageAttachmentId &&
        aadharPreview.backImage.url;
      setNotesStatus((prev: any) => ({
        ...prev,
        isModified: true,
        format: true,
        size: true,
        upload: aadhaarUploaded ? true : false,
      }));
    }
  }, [
    aadhaarPayloadObj?.details?.frontImageAttachmentId,
    aadhaarPayloadObj?.details?.backImageAttachmentId,
  ]);

  return (
    <>
      <Stack className={styles.KYCModal_Wrapper}>
        {kycModalLoader || individualKycDetailsLoader ? (
          <KycUploadLoader />
        ) : (
          <>
            {!isFunctionalityBlocked &&
              isPresent &&
              isKycDeletePermissionEnabled && (
                <>
                  {/* Delete Aadhar */}
                  <Stack alignItems={"flex-end"} padding={"0.5vw"}>
                    <img
                      src={deleteKyc}
                      alt="Delete KYC Icon"
                      onClick={() => toggleDeleteAadharConfirmation()}
                      style={{ cursor: "pointer" }}
                    />
                  </Stack>
                </>
              )}
            <Stack
              gap="20px"
              justifyContent={"space-between"}
              height="100%"
              sx={{ cursor: "pointer" }}
            >
              {isPresent ? (
                <>
                  <Stack className="contentWrapper" gap="10px">
                    {aadharDetailsLength > 0 &&
                    aadharDetails?.files &&
                    aadharDetails?.files.length > 0 ? (
                      <>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap="20px"
                        >
                          {/* <Stack gap="5px"> */}
                          {aadharDetails?.files.map((item: any) => {
                            return (
                              <>
                                {/* Front Image */}
                                <Stack width={"100%"}>
                                  <Typography
                                    variant={typographyConstants?.SUBHEADING}
                                    color={colors.text_secondary_gray}
                                  >
                                    {item?.imageView === "FRONT"
                                      ? "Front Image"
                                      : "Back Image"}
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: item?.signedUrl
                                        ? `url(${item?.signedUrl})`
                                        : `url(${aadharPreview.backImage.url})`,
                                      height: "100px",
                                      ...dlStyles,
                                    }}
                                    onClick={() =>
                                      handleDocsPreviewNavigation(
                                        item?.fileName,
                                        ["img", "jpg", "png"],
                                        item?.signedUrl
                                      )
                                    }
                                  ></Box>
                                </Stack>
                              </>
                            );
                          })}
                          {/* </Stack> */}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* -------------- Details Table section ----------------------------------- */}
                    <Stack gap="20px">
                      <Stack
                        sx={{
                          backgroundColor: colors.bg_light_gray,
                          borderRadius: "10px",
                        }}
                      >
                        <Box className={styles.selfie_View_Box}>
                          {" "}
                          <Typography
                            variant={typographyConstants?.SUBHEADING}
                            color={colors.text_black}
                          >
                            Verification Details
                          </Typography>{" "}
                        </Box>

                        <Stack>
                          {aadharDataLength > 0 && (
                            <>
                              {/* Aadhaar number */}
                              <Box className={styles.selfie_status_Box}>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.tertiary_black}
                                >
                                  Aadhaar Number:
                                </Typography>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.text_black}
                                >
                                  {aadharData?.uid ? aadharData?.uid : "N/A"}
                                </Typography>
                              </Box>

                              {/* dob */}
                              <Box className={styles.selfie_status_Box}>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.tertiary_black}
                                >
                                  DOB :
                                </Typography>
                                <Typography
                                  variant={typographyConstants?.SUBHEADING}
                                  color={colors.text_black}
                                >
                                  {aadharData?.dob ? aadharData?.dob : "N/A"}
                                </Typography>
                              </Box>
                            </>
                          )}

                          <Box className={styles.selfie_status_Box}>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.tertiary_black}
                            >
                              Mode:
                            </Typography>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_black}
                            >
                              {verificationDetail?.verificationMode
                                ? KycVerificationModeDisplayName[
                                    verificationDetail?.verificationMode as keyof typeof KycVerificationModeDisplayName
                                  ]
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box className={styles.selfie_status_Box}>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.tertiary_black}
                            >
                              Status:
                            </Typography>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_black}
                            >
                              {verificationDetail?.verificationStatus
                                ? KycVerificationStatusDisplayName[
                                    verificationDetail?.verificationStatus as keyof typeof KycVerificationStatusDisplayName
                                  ]
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  {/* ---------------------- Action Buttons ------------------------------- */}
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    gap="5px"
                  >
                    {/* Manual Verification */}
                    {verificationDetailLength > 0 &&
                    verificationDetail?.verificationStatus !==
                      KycVerificationStatus.VERIFIED &&
                    aadharFileExists ? (
                      <>
                        {!isFunctionalityBlocked && (
                          <CustomButton
                            variant="contained"
                            sx={{ width: "100%", padding: "0px 20px" }}
                            label={"Manually Verify"}
                            onClick={() =>
                              handleManualKycVerification(
                                KycTypes.AADHAAR,
                                kycId
                              )
                            }
                            disabled={
                              !kycId ||
                              verificationDetail?.verificationStatus ===
                                KycVerificationStatus.VERIFIED
                            }
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Unverify Aadhaar */}
                    {isVerified &&
                      verificationDetail?.verificationStatus ===
                        KycVerificationStatus.VERIFIED &&
                      !isFunctionalityBlocked &&
                      verificationDetail?.verificationMode !==
                        KycVerificationMode.DIGITAL && (
                        <CustomButton
                          variant="outlined"
                          sx={{ width: "100%", padding: "0px 20px" }}
                          wrapperStyles={{ width: "50%" }}
                          label={"Unverify"}
                          onClick={() => toggleUnverifyAadhaarConfirmation()}
                          disabled={
                            !kycId ||
                            verificationDetail?.verificationStatus !==
                              KycVerificationStatus.VERIFIED
                          }
                        />
                      )}

                    {/* Digitally verify - redirect to digilocker - show always till not verified digitally */}
                    {verificationMode !== KycVerificationMode.DIGITAL &&
                      !isFunctionalityBlocked && (
                        <CustomButton
                          variant="outlined"
                          sx={{ width: "100%", padding: "0px 20px" }}
                          wrapperStyles={{ width: "50%" }}
                          label={"Digitally Verify"}
                          onClick={() =>
                            handleSaveAndVerifyUploadToS3(
                              KycTypes.AADHAAR,
                              true
                            )
                          }
                          // disabled={!isPreviewPresent}
                        />
                      )}
                  </Box>

                  {/* ---------------------- Action Buttons ends ------------------------------- */}
                </>
              ) : (
                <>
                  <Stack className="contentWrapper" gap="10px">
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="20px"
                    >
                      {/* ---------------------------- Aadhaar Front ------------------------------- */}
                      <Stack
                        gap="5px"
                        {...getRootPropsAadharFront()}
                        width={"100%"}
                      >
                        {uploadLoader && aadharUploading.front ? (
                          <>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Front Image
                            </Typography>
                            <Stack
                              className={styles?.documentUploadBox}
                              {...getRootPropsAadharFront()}
                              justifyContent={"center"}
                            >
                              <Box sx={{ padding: "5px", gap: "10px" }}>
                                <Stack gap="10px" textAlign={"center"}>
                                  <Box>
                                    <img
                                      src={cloudUploadIcon}
                                      width={"20px"}
                                      height="20px"
                                    />
                                  </Box>

                                  <LinearProgress
                                    value={linearProgressValue}
                                    variant="determinate"
                                    sx={{ width: "115px" }}
                                  />
                                  <Box>
                                    <Box
                                      style={{ display: "flex", gap: "4px" }}
                                    >
                                      <img
                                        src={circularLineIcon}
                                        width={"20px"}
                                        height="20px"
                                      />
                                      <Typography
                                        sx={{
                                          lineHeight: "20px",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {"Uploading..."}
                                      </Typography>
                                    </Box>

                                    <Typography
                                      sx={{
                                        lineHeight: "12px",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        color: "#A9ACB4",
                                      }}
                                    >
                                      {`${linearProgressValue}% of 100%`}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            </Stack>
                          </>
                        ) : aadharPreview.frontImage.url ? (
                          <Stack
                            onClick={() => {
                              if (
                                (aadharUploading?.front ||
                                  aadharUploading?.back) &&
                                uploadLoader
                              ) {
                              } else {
                                setAadharUploading({
                                  back: false,
                                  front: true,
                                });
                              }
                            }}
                          >
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Front Image
                            </Typography>
                            <Box width="100%">
                              <Box
                                sx={{
                                  background: `url(${aadharPreview.frontImage.url})`,
                                  objectFit: "contain",
                                  width: "100%",
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "left",
                                  height: "145px",
                                  border: "dashed #d0d5dd",
                                }}
                              ></Box>
                              <input {...getInputPropsAadharFront()} />
                            </Box>
                            <Box textAlign={"center"}>
                              <img
                                src={cloudUploadIcon}
                                width={"20px"}
                                height="20px"
                              />
                            </Box>
                          </Stack>
                        ) : (
                          <Stack
                            onClick={() => {
                              if (
                                uploadLoader &&
                                (aadharUploading?.front ||
                                  aadharUploading?.back)
                              ) {
                              } else {
                                setAadharUploading({
                                  back: false,
                                  front: true,
                                });
                              }
                            }}
                          >
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Front Image
                            </Typography>
                            <Box
                              className={
                                (aadharDetailsLength > 0 &&
                                  aadharDetails?.files &&
                                  aadharDetails?.files.length > 0) ||
                                (aadharPreview.frontImage.url &&
                                  linearProgressValue === 100)
                                  ? ""
                                  : styles?.documentUploadBox
                              }
                              sx={[
                                ...(Array.isArray(dlFrontSx)
                                  ? dlFrontSx
                                  : [dlFrontSx]),
                              ]}
                              width="100%"
                              // className={styles?.documentUploadBox}
                            >
                              <Stack gap="10px" textAlign={"center"}>
                                <Box>
                                  <input {...getInputPropsAadharFront()} />
                                  <img
                                    src={cloudUploadIcon}
                                    width={"20px"}
                                    height="20px"
                                  />
                                </Box>
                                <Typography>
                                  {rentalEn.CustomerManagement.kycUploadNotes}
                                </Typography>
                                <i
                                  className="fa fa-plus-square fa-2x mt-1"
                                  aria-hidden="true"
                                  style={{
                                    color: "#047857",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </Stack>
                            </Box>
                          </Stack>
                        )}
                      </Stack>

                      {/* ---------------------------- Aadhaar Back ------------------------------- */}
                      <Stack
                        gap="5px"
                        {...getRootPropsAadharBack()}
                        width={"100%"}
                      >
                        {uploadLoader && aadharUploading.back ? (
                          <>
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Back Image
                            </Typography>
                            <Stack
                              className={styles?.documentUploadBox}
                              {...getRootPropsAadharBack()}
                              justifyContent={"center"}
                            >
                              <Box sx={{ padding: "5px", gap: "10px" }}>
                                <Stack gap="10px" textAlign={"center"}>
                                  <Box>
                                    <img
                                      src={cloudUploadIcon}
                                      width={"20px"}
                                      height="20px"
                                    />
                                  </Box>

                                  <LinearProgress
                                    value={linearProgressValue}
                                    variant="determinate"
                                    sx={{ width: "115px" }}
                                  />
                                  <Box>
                                    <Box
                                      style={{ display: "flex", gap: "4px" }}
                                    >
                                      <img
                                        src={circularLineIcon}
                                        width={"20px"}
                                        height="20px"
                                      />
                                      <Typography
                                        sx={{
                                          lineHeight: "20px",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {"Uploading..."}
                                      </Typography>
                                    </Box>

                                    <Typography
                                      sx={{
                                        lineHeight: "12px",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        color: "#A9ACB4",
                                      }}
                                    >
                                      {`${linearProgressValue}% of 100%`}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            </Stack>
                          </>
                        ) : aadharPreview.backImage.url ? (
                          <Stack
                            onClick={() => {
                              if (
                                (aadharUploading?.front ||
                                  aadharUploading?.back) &&
                                uploadLoader
                              ) {
                              } else {
                                setAadharUploading({
                                  front: false,
                                  back: true,
                                });
                              }
                            }}
                          >
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Back Image
                            </Typography>
                            <Box>
                              <Box
                                sx={{
                                  background: `url(${aadharPreview.backImage.url})`,
                                  objectFit: "contain",
                                  width: "100%",
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "left",
                                  height: "145px",
                                  border: "dashed #d0d5dd",
                                }}
                              ></Box>

                              <input {...getInputPropsAadharBack()} />
                              {!aadharPreview.backImage.url && (
                                <Stack gap="10px" textAlign={"center"}>
                                  <Box>
                                    <input {...getInputPropsAadharBack()} />
                                    <img
                                      src={cloudUploadIcon}
                                      width={"20px"}
                                      height="20px"
                                    />
                                  </Box>
                                  <Typography>
                                    {rentalEn.CustomerManagement.kycUploadNotes}
                                  </Typography>
                                  <i
                                    className="fa fa-plus-square fa-2x mt-1"
                                    aria-hidden="true"
                                    style={{
                                      color: "#047857",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </Stack>
                              )}
                            </Box>
                            {aadharPreview.backImage.url && (
                              <Box textAlign={"center"}>
                                <img
                                  src={cloudUploadIcon}
                                  width={"20px"}
                                  height="20px"
                                />
                              </Box>
                            )}
                          </Stack>
                        ) : (
                          <Stack
                            onClick={() => {
                              if (
                                (aadharUploading?.front ||
                                  aadharUploading?.back) &&
                                uploadLoader
                              ) {
                              } else {
                                setAadharUploading({
                                  front: false,
                                  back: true,
                                });
                              }
                            }}
                          >
                            <Typography
                              variant={typographyConstants?.SUBHEADING}
                              color={colors.text_secondary_gray}
                            >
                              Back Image
                            </Typography>
                            <Box
                              className={
                                (aadharDetailsLength > 0 &&
                                  aadharDetails?.files &&
                                  aadharDetails?.files.length > 0) ||
                                (aadharPreview.backImage.url &&
                                  linearProgressValue === 100)
                                  ? ""
                                  : styles?.documentUploadBox
                              }
                              sx={[
                                ...(Array.isArray(dlBackSx)
                                  ? dlBackSx
                                  : [dlBackSx]),
                              ]}
                              // className={styles?.documentUploadBox}
                            >
                              <Stack gap="10px" textAlign={"center"}>
                                <Box>
                                  <input {...getInputPropsAadharBack()} />
                                  <img
                                    src={cloudUploadIcon}
                                    width={"20px"}
                                    height="20px"
                                  />
                                </Box>
                                <Typography>
                                  choose the files you want to upload or drag &
                                  drop
                                </Typography>
                                <i
                                  className="fa fa-plus-square fa-2x mt-1"
                                  aria-hidden="true"
                                  style={{
                                    color: "#047857",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </Stack>
                            </Box>
                          </Stack>
                        )}
                      </Stack>
                    </Box>

                    {/* ---------------------------- Notes Section -------------------------------- */}
                    <Stack>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={"#292D32"}
                      >
                        Notes:
                      </Typography>
                      <Box>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.upload ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            Upload Front and Back images of Aadhaar
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.format ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            Supported Formats: Jpeg, Png and jpg.
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap="5px" alignItems={"center"}>
                          {notesStatus?.isModified ? (
                            <>
                              {notesStatus?.size ? (
                                <img
                                  src={NotesCheck}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={alertNotes}
                                  alt={"Checked"}
                                  title={"Accepted"}
                                  style={{ height: "1rem" }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: "0.5rem",
                                    color: colors.tertiary_black,
                                    width: "unset",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <Typography
                            variant={typographyConstants?.BODY}
                            color={colors.tertiary_black}
                          >
                            File size should be less than 5 MB.
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>

                  {/* ---------------------------Action Buttons ------------------------------- */}
                  <Box display={"flex"} justifyContent={"space-evenly"}>
                    {!isFunctionalityBlocked && (
                      <>
                        {/* Redirects to digilocker with response url */}
                        <CustomButton
                          variant="outlined"
                          sx={{ width: "100%" }}
                          label={"Digitally Verify"}
                          onClick={() =>
                            handleSaveAndVerifyUploadToS3(
                              KycTypes.AADHAAR,
                              true
                            )
                          }
                        />
                        {/* adds attachments to customer KYC */}
                        <CustomButton
                          variant="contained"
                          sx={{
                            width: "100%",
                            // padding: "0 20px"
                          }}
                          label={"Save"}
                          onClick={() =>
                            handleSaveAndVerifyUploadToS3(KycTypes.AADHAAR)
                          }
                          disabled={
                            !aadhaarPayloadObj?.details
                              ?.frontImageAttachmentId ||
                            !aadhaarPayloadObj.details?.backImageAttachmentId ||
                            uploadLoader ||
                            !isPreviewPresent
                          }
                        />
                      </>
                    )}
                  </Box>
                  {/* ---------------------------Action Buttons end ------------------------------- */}
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>

      {/* kyc delete confirmation */}
      {openDeleteConfirmation && (
        <>
          {/* confirnmation dialog on status change  */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.deleteAadhaarKyc}
            open={openDeleteConfirmation}
            handleClose={() => toggleDeleteAadharConfirmation()}
            handleYes={() => handleDeleteCustomerKyc(KycTypes.AADHAAR, kycId)}
          />
        </>
      )}

      {/* Unverify confirmation */}
      {openUnverifyConfirmation && (
        <>
          {/* confirnmation dialog on status change  */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.unverifyAadhaarKyc}
            open={openUnverifyConfirmation}
            handleClose={() => toggleUnverifyAadhaarConfirmation()}
            handleYes={() => handleUnverifyCustomerKyc(KycTypes.AADHAAR, kycId)}
          />
        </>
      )}
    </>
  );
};
