import { Box, Skeleton, Stack } from "@mui/material";

const KycUploadLoader = () => {
  return (
    <Stack
      direction="row"
      gap="5px"
      padding={"10px"}
      sx={{
        height: "-webkit-fill-available",
      }}
    >
     {/*  <Skeleton
        variant="circular"
        sx={{ transform: "none !important", height: "-webkit-fill-available" }}
        width="30%"
      /> */}
      <Skeleton
        variant="rectangular"
        sx={{ transform: "none !important", height: "-webkit-fill-available" }}
        width="100%"
      />
    </Stack>
  );
};

export default KycUploadLoader;
