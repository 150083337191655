import * as React from "react";
const Work = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 10c0-.233 0-.349.026-.444a.75.75 0 0 1 .53-.53C8.65 9 8.767 9 9 9h1c.232 0 .349 0 .444.026a.75.75 0 0 1 .53.53C11 9.65 11 9.767 11 10v.5a1.5 1.5 0 0 1-3 0V10Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M10.925 10.125h.887c.963 0 1.445 0 1.855-.087 1.187-.251 2.155-1.022 2.578-2.054.147-.356.206-.787.326-1.65.045-.323.067-.485.046-.618-.063-.385-.372-.701-.788-.805-.143-.036-.324-.036-.686-.036H3.857c-.362 0-.543 0-.686.036-.416.104-.725.42-.788.805-.021.133.001.295.046.618.12.863.18 1.294.326 1.65.423 1.032 1.391 1.803 2.578 2.054.41.087.892.087 1.855.087h.887"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.125 8.625v1.5c0 2.828 0 4.243.83 5.121.83.879 2.165.879 4.837.879h1.416c2.672 0 4.007 0 4.837-.879.83-.878.83-2.293.83-5.121v-1.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m12.125 4.875-.058-.265c-.289-1.32-.433-1.98-.777-2.357-.344-.378-.8-.378-1.713-.378h-.154c-.913 0-1.37 0-1.713.378-.344.377-.488 1.037-.777 2.357l-.058.265"
    />
  </svg>
);
export default Work;
