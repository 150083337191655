import {
  Box,
  Chip,
  Container,
  Skeleton,
  SliderThumb,
  Stack,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LeftBlueIcon, Right } from "../../constantsX/exportImagesX";
import { MultistepProps } from "../../interfaces/componentsInterfaces";
import { theme } from "../../themes";
import {
  EndIconLabelButtonX,
  FilledButtonX,
  StartIconLabelButtonX,
  VariantButton,
} from "../muiButtonsX";
import styles from "../components.module.css";
//Style CSS

/* const Styles = {
  stepperStyles: {
    "& .MuiStepConnector-line": { display: "none" },
    "& .MuiStepLabel-iconContainer": { display: "none" },
  },
  containerMultistep: {
    position: "fixed",
    bottom: 0,
    padding:"0px" ,
  },

  renderUI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  renderUIBox: {
    width: "100%",
    background: theme.palette.background.default,
    borderRadius: "8px",
    padding: "5px 32px 32px 40px",
  },
}; */

const MultiStepForm = forwardRef((props: MultistepProps, ref): JSX.Element => {
  const {
    steps,
    footer,
    viewMode,
    Styles,
    getStepWiseDataList = () => {},
  } = props;
  const { checkIsUserExist } = useSelector(
    (state: RootState) => state.manualBookingX
  );
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [stepDisabled, setStepDisabled] = useState<boolean[]>(
    Array(steps?.length).fill(true)
  );

  if (!viewMode) stepDisabled[0] = false;

  // Styles
  const stepStyles = (isActive: any, index: number) => ({
    background: isActive ? theme.palette.background.default : "",
    borderBottom: isActive ? "none" : "",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: "9px 7px 9px 7px",
    display: "flex",
    justifyContent: "center",
    color: !stepDisabled[index] ? theme.palette.primary.light : "",
    cursor: stepDisabled[index] ? "not-allowed" : "cursor",
  });
  const stepButtonStyles = (index: number) => ({
    "& .MuiStepLabel-label": {
      fontSize: "14px",
      color: !stepDisabled[index] ? theme.palette.primary.light : "",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: viewMode
        ? theme.palette.primary.light
        : !stepDisabled[index]
        ? theme.palette.primary.light
        : "",
      fontSize: "14px",
      lineHeight: "24px",
    },
  });

  // Handle current step
  const handleStep = (step: any) => () => {
    setActiveStep(step);
    getStepWiseDataList(step);
  };

  const handleNext = () => {
    let error = steps[activeStep]?.validateFunction();
    if (activeStep === steps?.length - 1) {
      return;
    }
    if (!error) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      let arrDisabled = [...stepDisabled];
      arrDisabled[activeStep + 1] = false;
      setStepDisabled(arrDisabled);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const nextStepsDisabled = () => {
    if (activeStep <= steps.length - 1) {
      let disabledArray = Array(steps.length).fill(false);
      for (let i = activeStep + 1; i < steps.length; i++) {
        disabledArray[i] = true;
      }
      setStepDisabled(disabledArray);
    }
  };

  const visitStep = (step: string) => {
    let activeStep: number = steps?.findIndex(
      (steps: any) => steps?.key === step
    );

    setActiveStep(activeStep);
  };

  const onClickStep = () => {};

  useImperativeHandle(ref, () => {
    return { nextStepsDisabled, visitStep };
  });

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ width: "500px" }}>
          {/* Heading */}
          <Stepper nonLinear activeStep={activeStep} sx={Styles.stepperStyles}>
            {steps?.map((item: any, index: number) => (
              <Step key={item.key} sx={stepStyles(activeStep === index, index)}>
                <StepButton
                  sx={stepButtonStyles(index)}
                  onClick={handleStep(index)}
                  disabled={viewMode ? false : stepDisabled[index]}
                >
                  <Box className={styles?.BoxRow} alignItems="center" gap="5px">
                    {item?.stepAlert ? (
                      <Box className={styles?.badgeBlink}> </Box>
                    ) : (
                      <></>
                    )}
                    {item.step}
                  </Box>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {props?.showStatusloader ? (
          <Skeleton />
        ) : (
          props.showStatus && (
            <Chip
              label={props?.bookingStatus}
              sx={{
                fontSize: "small",
                color: "#fff",
                background: props?.bookingStatusColor,
              }}
            />
          )
        )}
      </Stack>

      {/* UI component */}
      <React.Fragment>
        <Box sx={Styles.renderUI}>
          <Box sx={Styles.renderUIBox}>
            <>{steps[activeStep]?.render}</>
          </Box>
        </Box>
      </React.Fragment>

      <Box sx={Styles.outsideRender}>{steps[activeStep]?.outsideRender}</Box>
      {/* footer */}
      {footer && checkIsUserExist ? (
        <Container
          sx={Styles.containerMultistep}
          className={styles.containerPadding}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              width: "100%",
              height: "40px",
              backgroundColor: "#fbfbfb",
            }}
          >
            {/* PREVIOUS BTN */}
            {activeStep === 0 ? null : (
              <StartIconLabelButtonX
                variant="outlined"
                label="Previous"
                icon={LeftBlueIcon}
                onClick={handleBack}
              ></StartIconLabelButtonX>
            )}
            {steps[activeStep]?.renderFooter ? (
              steps[activeStep]?.renderFooter
            ) : (
              <EndIconLabelButtonX
                variant="contained"
                label="Next"
                icon={Right}
                onClick={handleNext}
                style={{ position: "fixed", right: "37px" }}
              ></EndIconLabelButtonX>
            )}
          </Box>
        </Container>
      ) : null}
    </Box>
  );
});
export default MultiStepForm;
