import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Heading } from "../../../components/typography";
import { ButtonActions } from "../../../constants/userActions";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";
// import Status from "../../../components/status";
import { getData, storeData } from "../../../config/filterStorage";
import { LocalStorage } from "../../../constants/enum";
import { colors } from "../../../themes/colors";
import TableView from "./component/TableView";

import { Stack } from "@mui/system";
import {
  FinishIcon,
  GreenIcon,
  OtherIcon,
  ServicesIcon,
} from "../../../assets/exportImages";
import BreadCrumbs from "../../../components/breadCumbs";
import CustomSelect from "../../../sharedComponents/atoms/InputFields/InputSelect";
import { rentalActions } from "../../redux/actions";
import { getCityDetails } from "../../redux/actions/businessManagementActions";
import { BREADCRUMBS_RENTALS } from "../../constants/activeModule";
import { updateBreadcrumbs } from "../../redux/actions/rentalCommonAction";

interface IObjectKeys {
  [key: string]: string | number;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
}

const TaskSequence = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();

  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const { locale } = useSelector((state: RootState) => state.localeReducer);

  /*   const { allCitiesData,
    //  branches 
    } = useSelector(
    (state: RootState) => state.commonReducer
  ); */

  const {
    allCitiesDetails,
    branches,
    masterPlanDurationsData = [],
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const { taskSequence, taskloader } = useSelector(
    (state: RootState) => state.taskReducer
  );

  const { roleManagement, global, taskManagement } = locale;
  const [selectStartRide, setSelectStartRide] = useState<any>({
    activeTab: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [warehouseSelect, setWarehouseSelect] = useState<any>({
    name: "",
    displayName: "",
  });
  const [warehouseSelectCity, setWarehouseSelectCity] = useState<any>({
    name: "",
    displayName: "",
  });

  const OthersList = [
    {
      id: 1,
      name: "Vehicle updating",
      sla: "02 mins",
    },
    {
      id: 2,
      name: "Vehicle image",
      sla: "02 mins",
    },
  ];

  const handleBranch = (branch: any) => {
    setWarehouseSelect({
      // branch_name: branch?.branch_name,
      // branch_id: branch?.id,
      name: branch?.name,
      displayName: branch?.displayName,
    });
  };

  const handleCity = (city: any) => {
    setWarehouseSelectCity({
      name: city.name,
      displayName: city?.displayName,
    });

    setWarehouseSelect({
      name: "",
      displayName: "Select Warehouse",
    });
    if (city.name) {
      dispatch(rentalActions.getAllBranches({ name: city.name }));
    }
  };

  // Fetch localstorage data
  useEffect(() => {
    getData(LocalStorage.Task_ManagementData).then((data: any) => {
      let dataArr = data ? Object.keys(data) : [];
      let dataLength = dataArr.length;
      const taskData = data && dataLength > 0 ? JSON.parse(data) : {};

      if (taskData) {
        let obj: any = taskData?.city;
        if (taskData?.city) {
          setWarehouseSelectCity({
            name: taskData?.city?.name,
            displayName: taskData?.city?.displayName,
          });
        } else {
          setWarehouseSelectCity({
            name: "",
            displayName: "Select Warehouse City",
          });
        }

        // setWarehouseSelectCity(obj);
        dispatch(rentalActions.getAllBranches({ name: obj?.name }));

        if (taskData?.branch) {
          setWarehouseSelect(taskData?.branch);
        } else {
          setWarehouseSelect({
            name: "",
            displayName: "Select Warehouse",
          });
        }
        dispatch(
          getCityDetails({
            cityName: obj?.name,
          })
        );
      }
    });

    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.TASK_SEQUENCE));
    /*  dispatch(
      actions.cityData({
        type: "City",
        id: 0,
      })
    ); */
  }, []);

  // sets localstorage data
  useEffect(() => {
    // Check if both warehouseBranch and warehouseCity are defined
    let data = {
      branch: warehouseSelect,
      city: warehouseSelectCity,
    };
    storeData(LocalStorage.Task_ManagementData, data);
  }, [warehouseSelectCity, warehouseSelect]);

  // get task sequence data
  useEffect(() => {
    if (refresh || warehouseSelect?.name) {
      dispatch(
        actions.getTaskSequenceList({ warehouseId: warehouseSelect?.name })
      );
    }
  }, [warehouseSelect, refresh]);

  return (
    <Box>
      <Stack>
        <Heading
          title={""}
          actionButtons={[ButtonActions.refresh]}
          onClickButton={(type: ButtonActions) => {
            if (type === ButtonActions.refresh) {
              setRefresh(!refresh);
            }
          }}
        />
        {/* <BreadCrumbs
          path={`/business-management/warehouse-management`}
          firstLabel={taskManagement.Hub}
          secondLabel={taskManagement.TaskSequenceSmall}
        /> */}
      </Stack>
      <Box
        style={{
          marginBottom: 10,
          marginTop: 14,
          width: "90%",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {/* Select City */}
        <CustomSelect
          required
          heading={"Select City"}
          // muiIcons={<AccountBalanceWalletIcon />}
          placeholder={"Select City"}
          value={warehouseSelectCity?.displayName}
          // value={allCitiesDetails?.displayName}
          choice={allCitiesDetails?.length > 0 ? allCitiesDetails : []}
          defaultKey={"displayName"}
          sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
          handleChange={(city: any) => {
            handleCity(city);
            // handleCity(category, item?.chargeIndex);
          }}
        />

        {/* Select Warehouse */}
        <CustomSelect
          required
          heading={"Select Hub"}
          placeholder={"Select Hub"}
          value={warehouseSelect?.displayName}
          // value={branches?.displayName}
          choice={branches?.length > 0 ? branches : []}
          defaultKey={"displayName"}
          sx={{ boxShadow: "-19px -7px 32px 0px #3333330A" }}
          handleChange={(branch: any) => {
            handleBranch(branch);
            // handleCity(category, item?.chargeIndex);
          }}
        />
      </Box>

      {warehouseSelect.name == "Select Warehouse" ? (
        <div style={{ marginTop: "15%", width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              style={{
                fontFamily: "EuclidCircularB-Medium",
                fontSize: "16px",
                textAlign: "center",
              }}
              color={colors.heading_main_text}
              key="2"
              sx={{
                marginBottom: 5,
              }}
            >
              {taskManagement.SelectWarehouseDisplay} <br />
              {taskManagement.For}
              <br />
              {taskManagement.StartRideEnd}
            </Typography>
          </Box>
        </div>
      ) : (
        <div style={{ marginTop: "5%", width: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            {taskloader ? (
              <div
                style={{
                  position: "absolute",
                  top: "120%",
                  left: "40%",
                  height: "0px",
                }}
              >
                <CircularProgress
                  color="inherit"
                  size={50}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            ) : (
              <Grid container spacing={4}>
                <TableView
                  imgIcon={GreenIcon}
                  taskName={taskManagement.StartRide}
                  OnEdit={() =>
                    navigate(
                      {
                        pathname: "/drag-task-sequence/" + "1",
                      },
                      {
                        state: {
                          warehouseSelect,
                          warehouseSelectCity,
                          edit: true,
                        },
                      }
                    )
                  }
                  itemList={taskSequence?.startList ?? []}
                />
                <TableView
                  imgIcon={FinishIcon}
                  taskName={taskManagement.CompleteRide}
                  OnEdit={() =>
                    navigate(
                      {
                        pathname: "/drag-task-sequence/" + "2",
                      },
                      {
                        state: {
                          warehouseSelect,
                          warehouseSelectCity,
                        },
                      }
                    )
                  }
                  itemList={taskSequence?.endList ?? []}
                />
                <TableView
                  imgIcon={ServicesIcon}
                  taskName={taskManagement.Service}
                  OnEdit={() =>
                    navigate(
                      {
                        pathname: "/drag-task-sequence/" + "3",
                      },
                      {
                        state: {
                          warehouseSelect,
                          warehouseSelectCity,
                        },
                      }
                    )
                  }
                  itemList={taskSequence?.servicesList ?? []}
                />
                <TableView
                  imgIcon={OtherIcon}
                  taskName={taskManagement.Others}
                  OnEdit={() =>
                    navigate(
                      {
                        pathname: "/drag-task-sequence/" + "4",
                      },
                      {
                        state: {
                          warehouseSelect,
                          warehouseSelectCity,
                        },
                      }
                    )
                  }
                  itemList={taskSequence?.othersList ?? []}
                />
              </Grid>
            )}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default TaskSequence;
