import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { storeData } from "../../../../config/filterStorage";
import {
  bookingFunctionalities,
  modules,
  vehicleManagementFunctionalities,
} from "../../../../constants/RBACModuleEnums";
import { LocalStorage } from "../../../../constants/enum";
import { RootState } from "../../../../redux/reducers";
import { WrongUrl } from "../../../../screens";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import PaymentLinkQRLoader from "../../../../sharedComponents/molecules/SkeletonLoader/BookingManagement/PaymentLinkQRLoader";
import { BookingDetailsStyles } from "../../../../sharedComponents/templates/MultiStepForm/StyleObjects";
import MultiStepForm, {
  StepsProps,
} from "../../../../sharedComponents/templates/MultiStepForm/multiStepForm";
import SubscriptionItem from "../../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import {
  RenderPaymentLink,
  RenderQRCode,
} from "../../../../sharedComponents/templates/RenderPaymentMethods";
import SideDrawer from "../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../themes/colors";
import RbacHelper from "../../../../utils/helperRBAC";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import {
  ADD_CHARGES_ACTION_TYPE,
  BookingPaymentType,
  DisplayChargesNameTypes,
  DisplayPaymentModes,
  OrderPaymentType,
  PaymentFlow,
  chargesNameTypes
} from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
import {
  bookingManagementActions,
  rentalActions,
  vehicleActions,
} from "../../../redux/actions";
import {
  cleanUpOrderAndPaymentData,
  // getOperatorConfigByBranch,
  createBulkAdditionalCharges,
  createOrderForBulkPaymentRevamp,
  getAdditionalCharges,
  getBookingDetails,
  getVehicleExtension,
  setPaymentSuccess
} from "../../../redux/actions/bookingManagementActions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../utils/RoutesConstants";
import { getFormattedINR, toPascalCase } from "../../../utils/helper";
import styles from "./bookingDetails.module.css";
import AddChargesSideModalUI from "./components/AddChargesSideModalUI";
import PaymentDetailsSideModalUI from "./components/PaymentDetailsSideModalUI";
import BookingDetailStep from "./sections/bookingDetailStep";
import BookingDetailsOutsideRender from "./sections/bookingDetailsStepOutsideRender";
import ModifyBooking from "./sections/modifyBooking";
import PlanAndPayments from "./sections/planAndPayments";
import UserDetails from "./sections/userDetails";
import VehicleDetails from "./sections/vehicleDetails";

// enums for booking steps keys
enum bookingStep {
  PLANS_AND_PAYMENTS = "planAndPayments",
  MODIFY_BOOKING = "modifyBooking",
  VEHICLE_DETAILS = "vehicleDetails",
  BOOKING_DETAILS = "bookingDetails",
}
interface objInterface {
  [key: string]: any;
}

const BookingDetails = (props: any) => {
  const RbacHasAccess: any = {
    bookingDetails: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.BOOKING_DETAILS_TAB
    ),
    vehicleDetails: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.VEHICLE_DETAILS_TAB
    ),
    planAndPayments: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.PLAN_AND_PAYMENTS_TAB
    ),
    modifyBooking: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.MODIFY_BOOKINGS_TAB
    ),
    viewVehicleDetails: RbacHelper.isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VIEW_VEHICLE
    ),
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const {
    bookingDetails = {},
    paymentSummary = {},
    planSummary = {},
    documents,
    isBookingCancelled,
    cancelBookingLoader,
    planAndAddonsData,
    planAndAddonsDataLoader,
    modificationHistoryData,
    modificationHistoryLoader,
    paymentLinkQRData,
    paymentLinkQRLoader,
    paymentModes,
    orderDataloader,
    savedCharges,
    additionalChargesLoader,
    additionalChargesList,
    bookingVehiclesImages,
    startRideImageRefId,
    paymentOverviewData,
    paymentOverviewDataLoader,
    waiveOffReasons,
    isPaymentSuccess,
    onLoad,
    // returns data in map data structure
    recentModificationHistoryData = new Map(),
    recentModificationHistoryLoader,
    bookingVehicleDetails,
    vehicleExtensions,
    bookingVehicleImageLoader,
    bookingDocsLoader,
    imageLoader,
    docLoader,
    error404,
    unassignVehicleLoader,
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);

  const { vehicleImagesData, vehicleImagesDataLoader } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  // operator Config Data
  const { operatorConfigData }: any = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const {
    chargesTypesArr,
    isPaymentLinkGenerated,
    paymentLink,
    paymentLoader,
    rentalsCommonLoader,
  }: any = useSelector((state: RootState) => state.rentalsCommonReducer);

  // state to toggle modification history table
  const [modifyHistorySwitch, setModifyHistorySwitch] =
    useState<boolean>(false);

  // handle toggle of modify history table ant switch
  const toggleModificationSwitch = () => {
    if (modifyHistorySwitch) {
      setModifyHistorySwitch(false);
    } else {
      setModifyHistorySwitch(true);
      getModificationHistory();
    }
  };

  // get modification history table data\
  const getModificationHistory = () => {
    dispatch(bookingManagementActions.getModificationHitory(id));
  };

  const addMoreChargesInitialObj = {
    chargeIndex: 1,
    category: { name: "", displayName: "" },
    subCategory: { name: "", displayName: "" },
    price: 0, // Save price in paise
    isEditable: false,
    showRemark: false,
    remarks: "",
    chargeTypeId: "",
  };
  const chargesFiltersInitial = {
    paginationModel: {
      // page: additionalChargesList?.pageData?.page
      //   ? Number(additionalChargesList?.pageData?.page) - 1
      //   : 0,
      page: 1,
      // pageSize: additionalChargesList?.pageData?.pageSize ?? 10,
      pageSize: 100,
    },
    category: { name: "", displayName: "All" },
  };
  const defaultPaymentFlow = OrderPaymentType.PAYMENT_LINK;
  const addMoreChargesInitialState = [addMoreChargesInitialObj];
  const [showAddChargeModal, setShowAddChargeModal] = useState(false);
  const [selectedAddMoreCharges, setSelectedAddMoreCharges] = useState(
    addMoreChargesInitialState
  );
  const [addMoreErrorObj, setAddMoreErrorObj] = useState({});
  const [showAddChargesPaymentModeUi, setShowAddChargesPaymentModeUi] =
    useState<any>(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(defaultPaymentFlow);
  const [chargesFilters, setChargesFilters] = useState(chargesFiltersInitial);

  type ExpandAccordionInterface = {
    [key: string]: boolean;
  };
  const initialAcoordianInterface: ExpandAccordionInterface = {
    challanExtras: false,
  };
  // state to control open and close of all accordion in VMS view vehicle
  const [expandAccordian, setExpandAccordian] =
    useState<ExpandAccordionInterface>(initialAcoordianInterface);

  const bookingStatus = bookingDetails?.status;
  const allowedPaymentFlows = operatorConfigData?.allowedPaymentFlows;

  const resetMultistepFormRef = useRef<any>(null);

  // state to handle payment side drawer of payment overview table
  const [paymentDrawer, setPaymentDrawer] = useState({
    open: false,
    paymentFlow: "",
    chargeType: "",
  });

  // flag to check link copied or not
  const [isCopied, setIsCopied] = useState(false);

  // charges table filters
  const handleChargesFilterChange = (filterType: any, filterValue: any) => {
    if (filterType === "category") {
      setChargesFilters((prev: any) => ({
        ...prev,
        category: { ...filterValue },
      }));
    }

    if (filterType === "paginationModel") {
      setChargesFilters((prev: any) => {
        return {
          ...prev,
          paginationModel: {
            page: filterValue?.page,
            pageSize: filterValue?.pageSize,
          },
        };
      });
    }

    const category = filterValue?.name ? filterValue?.name : null;
    let queryParamList: any = {
      /*  page: filterValue?.page
        ? filterValue?.page + 1
        : chargesFilters?.paginationModel?.page + 1,
      pageSize: filterValue?.pageSize
        ? filterValue?.pageSize
        : chargesFilters?.paginationModel?.pageSize, */
      page: 1,
      pageSize: 100,
    };
    if (category) {
      queryParamList["category"] = category;
    }

    // getting old state
    const getChargesPayload = {
      bookingId: params.id,
      queryParamList,
    };
    dispatch(getAdditionalCharges(getChargesPayload));
  };

  // handle remark toggle
  const handleChargesRemarkToggle = (chargeIndex: any) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev?.map((item: any, index: number) => {
        if (item.chargeIndex === chargeIndex) {
          if (item.showRemark) {
            item.remarks = "";
          }
          item.showRemark = !item.showRemark;
        }
        return { ...item };
      });

      return updatedAddMoreState;
    });
    AddMoreChargesValidation();
  };

  // Add charge
  const handleAddChargeModalOpen = () => {
    setShowAddChargeModal(true);
  };

  // callback on payment success - add only for QR but works for all payment methods
  const sucessCallbackPayment = () => {
    // update state for success & success UI in modal
    dispatch(setPaymentSuccess());
  };

  // Handling more charge modal toggle
  const toggleAddChargeDrawer: any = () => {
    setShowAddChargeModal((isShown) => {
      if (isShown) {
        setSelectedAddMoreCharges(addMoreChargesInitialState);
        setAddMoreErrorObj({});
        setPaymentMethod(defaultPaymentFlow);

        showAddChargesPaymentModeUi && setShowAddChargesPaymentModeUi(false);
        if (
          isPaymentSuccess ||
          (paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0)
        ) {
          dispatch(cleanUpOrderAndPaymentData());
          // refresh charges
          dispatch(
            getAdditionalCharges({
              bookingId: params.id,
              queryParamList: { page: 1, pageSize: 100 },
            })
          );
        }
      }
      return !isShown;
    });
  };

  const chargesCategoriesData: any = [];
  // Create order & Initiate Payment
  const handleChargeOrderGenerationAndPayment = () => {
    // Called when Payment Mode Selected
    let additionalChargeIds =
      savedCharges &&
      savedCharges?.length > 0 &&
      savedCharges.map((charges: any) => {
        return {
          id: charges?.id,
          amount: charges?.amount,
        };
        // charges?.id
      });
    if (additionalChargeIds.length > 0) {
      let orderPayload = {
        bookingId: params.id,
        selectedPaymentMode: paymentMethod,
        additionalChargesIds: additionalChargeIds,
        sucessCallbackPayment: sucessCallbackPayment,
      };

      dispatch(createOrderForBulkPaymentRevamp(orderPayload));
      setShowAddChargesPaymentModeUi(false);
    }
  };

  // Add more charges
  const handleAddMoreChargesClick = () => {
    setSelectedAddMoreCharges((prev: any) => {
      let prevLength = prev.length;
      return [
        ...prev,
        {
          ...addMoreChargesInitialObj,
          chargeIndex: prev[prevLength - 1].chargeIndex + 1,
        },
      ];
    });
  };

  // Handle add more charge - "Category" change
  const handleAddMoreCategoriesSelect = (category: any, chargeIndex: any) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev?.map((item: any, index: number) => {
        if (item.chargeIndex === chargeIndex) {
          item.category = category;
          item.subCategory = { ...addMoreChargesInitialObj.subCategory };
          item.remarks = addMoreChargesInitialObj.remarks;
          item.showRemark = addMoreChargesInitialObj.showRemark;
        }
        return { ...item };
      });

      return updatedAddMoreState;
    });
    AddMoreChargesValidation();
  };

  // Handle add more charge - "Sub Category" change
  const handleAddMoreSubCategoriesSelect = (
    subCategory: any,
    chargeIndex: any,
    subCategoryId: string | number
  ) => {
    const selectedSubData = chargesTypesArr?.filter(
      (item: any, index: number) => {
        return item.id === subCategoryId;
      }
    );

    // return false;
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev?.map((item: any, index: number) => {
        if (item.chargeIndex === chargeIndex) {
          item.subCategory = subCategory;
          item.price =
            selectedSubData.length > 0 ? selectedSubData[0]?.amount / 100 : 0;
          item.isEditable = selectedSubData[0]?.isEditable;
        }
        return { ...item };
      });

      return updatedAddMoreState;
    });
    AddMoreChargesValidation();
  };

  // Handle add more charge - Price change handler
  const handleAddMoreChargesPrice = (price: any, chargeIndex: number) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev;
      price = price.toString().replace(/^0+/, "");
      if (price >= 0 && price.toString().length < 8) {
        updatedAddMoreState = prev?.map((item: any, index: number) => {
          if (item.chargeIndex === chargeIndex) {
            item.price = Number(price);
          }
          return { ...item };
        });
      }
      return updatedAddMoreState;
    });
  };

  // handle remark change - Add more charges
  const handleAddChargesRemarkChange = (
    remarks: string,
    chargeIndex: number
  ) => {
    // category===chargesNameTypes.OTHERS
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState = prev;
      if (remarks && remarks.length > 0 && remarks.length <= 120) {
        updatedAddMoreState = prev?.map((item: any, index: number) => {
          // for avoiding adjacent whitespace
          if (item.chargeIndex === chargeIndex) {
            let prevLength = item.remarks?.length;
            let remarksLength = remarks.length;
            if (
              prevLength &&
              /\s/.test(remarks[remarksLength - 1]) &&
              /\s/.test(remarks[remarksLength - 2])
            ) {
              item.remarks = item.remarks;
            } else {
              item.remarks =
                remarksLength === 1 && /\s/.test(remarks)
                  ? remarks.trim()
                  : remarks;
            }
          }
          return { ...item };
        });
      }

      return updatedAddMoreState;
    });
    AddMoreChargesValidation();
  };

  // Reset Add More Charges
  const handleDeleteAllAddMoreCharges = () => {
    setSelectedAddMoreCharges([addMoreChargesInitialObj]);
    setAddMoreErrorObj({});
  };

  // Delete add more charge
  const handleDeleteAddedCharge = (chargeIndex: number) => {
    setSelectedAddMoreCharges((prev: any) => {
      let updatedAddMoreState =
        prev.length > 1
          ? prev.filter((item: any) => item.chargeIndex !== chargeIndex)
          : addMoreChargesInitialState;
      return updatedAddMoreState;
    });
    setAddMoreErrorObj((prev: any) => {
      return { ...prev, chargeIndex: { status: false, remark: false } };
    });
  };

  // Add more charges validation
  const AddMoreChargesValidation = () => {
    let errorFlag = false;

    let errorObj: any = {};
    selectedAddMoreCharges.map((charge: objInterface, index: number) => {
      errorObj[charge?.chargeIndex] = {
        status: false,
        price: false,
        remark: false,
        remarkLength: false,
      };

      if (charge?.category?.name === "" || charge?.subCategory?.name === "") {
        errorObj[charge?.chargeIndex] = { status: true };
        errorFlag = true;
      } else if (
        !charge?.price ||
        charge?.price === 0 ||
        !(charge?.price >= 1)
      ) {
        errorFlag = true;
        errorObj[charge?.chargeIndex] = { status: true, price: true };
      } else if (charge?.category?.name === chargesNameTypes.OTHERS) {
        if (charge.remarks === "") {
          errorFlag = true;
          errorObj[charge?.chargeIndex] = { status: true, remark: true };
        }

        if (charge.remarks.length > 120) {
          errorFlag = true;
          errorObj[charge?.chargeIndex] = {
            status: true,
            remark: false,
            remarkLength: true,
          };
        }
      }
    });
    setAddMoreErrorObj(errorObj);
    return errorFlag;
  };

  // Validate and save bulk charges
  const handleAddMoreChargesSaveAll = (actionType: string) => {
    const checkValidation = AddMoreChargesValidation();
    if (checkValidation) return false;

    // payload
    const chargePayload = selectedAddMoreCharges.map((charge: any) => {
      const eachChargePayload: any = {};
      eachChargePayload["chargeTypeId"] = charge.subCategory.name;
      eachChargePayload["amount"] = Number(charge.price) * 100;
      if (charge.showRemark && charge.remarks) {
        eachChargePayload["remarks"] = charge.showRemark ? charge.remarks : "";
      }
      return eachChargePayload;
    });

    // Payload for save charge API
    let AddChargesPayload = {
      bookingId: params.id,
      charges: chargePayload,
    };

    dispatch(createBulkAdditionalCharges(AddChargesPayload));
    navigateBookingDetailsStep("vehicleDetails");

    // Only save charges
    if (actionType === ADD_CHARGES_ACTION_TYPE.SAVE) {
      toggleAddChargeDrawer();
    }
    // Initiate payment
    if (actionType === ADD_CHARGES_ACTION_TYPE.SAVE_AND_PAY) {
      setShowAddChargesPaymentModeUi(true);
    }
  };

  // Payment mode change
  const handlePaymentModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(event.target.value);
  };

  // get payment overview data
  const getPaymentOverviewData = (props: any) => {
    const { page, pageSize } = props;
    const payload = {
      bookingId: id,
      queryParamList: {
        page: page + 1,
        pageSize,
      },
    };
    dispatch(bookingManagementActions.getPaymentOverviewList(payload));
  };

  // navigate to customer details
  const navigateToCustomerManagement = () => {
    navigate(
      `${routesConstants.CUSTOMER_MANAGEMENT}/${bookingDetails?.customerDetails?.id}`
    );
  };

  //navigate to vehicle details
  const navigateToVehicleDetails = (id: any) => {
    RbacHasAccess.viewVehicleDetails &&
      navigate(`${routesConstants.VIEW_VEHICLE}${id}`);
  };

  // Add more charges breakup & payment method UI
  const AddMoreChargesPaymentUi = ({
    savedCharges,
    rentalsCommonLoader,
    allowedPaymentFlows,
  }: any) => {
    return (
      <>
        <Stack
          padding="10px"
          justifyContent={"space-between"}
          height={"-webkit-fill-available"}
        >
          <Stack gap={"2vh"}>
            {/* BREAKUP */}
            <Stack className="ChargesBreakup" borderRadius="12px">
              <Box
                padding="10px"
                sx={{ background: "#E4E4E4", borderRadius: "10px 10px 0 0" }}
              >
                <Typography variant="caption">Added Charges</Typography>
              </Box>

              <Stack
                padding="10px"
                sx={{
                  borderRadius: "0px 0px 12px 12px",
                  background: "#EFF1F0",
                }}
              >
                {savedCharges &&
                  savedCharges?.length > 0 &&
                  savedCharges.map((charges: any, index: number) => {
                    const chargeDetails = charges?.chargeTypeDetail;
                    return (
                      <>
                        <SubscriptionItem
                          key={index}
                          dashedBorder={false}
                          title={
                            DisplayChargesNameTypes[
                              chargeDetails?.type as keyof typeof DisplayChargesNameTypes
                            ]
                          }
                          amount={getFormattedINR(charges?.amount)}
                          fontSize="10px"
                          showBorder={
                            index === savedCharges?.length - 1 ? false : true
                          }
                          description={chargeDetails?.name ?? "N/A"}
                        />
                      </>
                    );
                  })}
              </Stack>
            </Stack>

            {/* PAYMENT MODE */}
            <Box className="PaymentMode">
              <Typography variant="caption" color={colors.text_secondary_gray}>
                Select Payment Method:
              </Typography>
              <Box className={styles.drawerHighlightBox}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={paymentMethod}
                    onChange={handlePaymentModeChange}
                  >
                    {/* {paymentModes?.map((mode: any) => ( */}
                    {allowedPaymentFlows?.map((mode: any) => (
                      <FormControlLabel
                        sx={{ fontSize: "" }}
                        value={mode}
                        control={<Radio />}
                        label={
                          DisplayPaymentModes[
                            mode as keyof typeof DisplayPaymentModes
                          ]
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Stack>

          {/* SUBMIT BUTTON */}
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CustomButton
              label={`Generate ${
                DisplayPaymentModes[
                  paymentMethod as keyof typeof DisplayPaymentModes
                ]
              }`}
              variant="contained"
              minWidth={"100%"}
              minHeight="26px"
              backgroundColor={colors.primary_aqua_blue}
              onClick={
                orderDataloader ? null : handleChargeOrderGenerationAndPayment
              }
              disabled={orderDataloader}
            />
          </Stack>
        </Stack>
      </>
    );
  };

  // handle payment option click in payment overview table (payment mode)
  const handlePaymentOptionClick = (
    paymentType: string,
    orderId: string,
    chargeType: string = ""
  ): void => {
    const payload = {
      data: {
        orderId: orderId,
        paymentFlow: paymentType,
      },
    };

    dispatch(rentalActions.paymentLinkGeneration(payload));
    setPaymentDrawer((prev: any) => ({
      ...prev,
      paymentFlow: paymentType,
      open: true,
      chargeType,
    }));
  };
  ``;
  // close side drawer close of payment overview table
  const closeSideDrawerPayment = () => {
    if (paymentDrawer?.chargeType === BookingPaymentType.EXTENSION) {
      dispatch(bookingManagementActions.getVehicleExtension({ bookingId: id }));
    }
    dispatch(
      rentalActions.clearReducerDataRentalCommon({
        isPaymentLinkGenerated: false,
        paymentLink: {},
        updatedStatus: "",
      })
    );
    dispatch(bookingManagementActions.cleanUpOrderAndPaymentData());
    dispatch(rentalActions.cancelPaymentStatus());
    setPaymentDrawer((prev: any) => ({
      ...prev,
      open: false,
      chargeType: "",
    }));
  };

  const accordianClickHandler = (type: any) => {
    setExpandAccordian((prev: any) => {
      return { ...prev, [type]: !prev[type] };
    });
  };
  // vist to any step of booking details need to pass steps key
  const navigateBookingDetailsStep = (step: string) => {
    if (step)
      resetMultistepFormRef.current &&
        resetMultistepFormRef.current.visitStep(step);
  };

  //handle time completion of qr code
  const onSuccessTimeCompletionPayment = () => {
    setPaymentDrawer((prev: any) => ({
      ...prev,
      open: false,
      chargeType: "",
    }));
    dispatch(
      rentalActions.clearReducerDataRentalCommon({
        isPaymentLinkGenerated: false,
        paymentLink: {},
        updatedStatus: "",
      })
    );
    dispatch(bookingManagementActions.cleanUpOrderAndPaymentData());
    dispatch(rentalActions.cancelPaymentStatus());
  };

  //refresh booking details
  const refreshBookingDetails = () => {
    // get booking details & operator config on success
    dispatch(getBookingDetails({ bookingId: id, getConfig: true }));

    // get additional charges (challans & Extras)
    dispatch(
      getAdditionalCharges({
        bookingId: id,
        queryParamList: { page: 1, pageSize: 100 },
      })
    );

    if (
      resetMultistepFormRef?.current &&
      resetMultistepFormRef?.current?.getActiveStep() ===
        bookingStep.PLANS_AND_PAYMENTS
    ) {
      getPaymentOverviewData({
        page: 0,
        pageSize: 10,
      });
      dispatch(bookingManagementActions.getPlanAndAddOnDetails(id));
    }
    if (
      resetMultistepFormRef?.current &&
      resetMultistepFormRef?.current?.getActiveStep() ===
        bookingStep.VEHICLE_DETAILS
    ) {
      dispatch(getVehicleExtension({ bookingId: id }));
    }
  };

  const bookingDetailsSteps: StepsProps[] = [
    {
      key: "bookingDetails",
      step: en.bookingDetails.bookingInfo,
      render: (
        <BookingDetailStep
          id={id}
          onLoad={onLoad}
          navigateBookingDetailsStep={navigateBookingDetailsStep}
          handlePaymentOptionClick={handlePaymentOptionClick}
        />
      ),
      outsideRender: (
        <BookingDetailsOutsideRender
          documents={documents}
          bookingId={id}
          imagesData={bookingDetails?.bookingDetails?.images}
          bookingStatus={bookingDetails?.status}
          startRideImageRefId={startRideImageRefId}
          bookingVehicleDetails={bookingVehicleDetails}
          vehicleImagesData={vehicleImagesData}
          vehicleImagesDataLoader={vehicleImagesDataLoader}
          bookingVehicleImageLoader={imageLoader}
          bookingDocsLoader={docLoader}
        />
      ),
    },
    {
      key: "vehicleDetails",
      step: en.bookingDetails.vehicleDetails,
      render: (
        <VehicleDetails
          expandAccordian={expandAccordian}
          accordianClickHandler={accordianClickHandler}
          bookingId={id}
          bookingStatus={bookingStatus}
          additionalChargesLoader={additionalChargesLoader}
          additionalChargesList={additionalChargesList}
          waiveOffReasons={waiveOffReasons}
          allowedPaymentFlows={allowedPaymentFlows}
          orderDataloader={orderDataloader}
          paymentLinkQRData={paymentLinkQRData}
          paymentLinkQRLoader={paymentLinkQRLoader}
          handleChargesFilterChange={handleChargesFilterChange}
          chargesFilters={chargesFilters}
          bookingVehicleDetails={bookingDetails?.bookingVehicleDetails}
          vehicleExtensions={vehicleExtensions}
          unassignVehicleLoader={unassignVehicleLoader}
          navigateToVehicleDetails={navigateToVehicleDetails}
        />
      ),
      stepAlert: additionalChargesList?.pendingChargesAmount,
    },
    {
      key: "planAndPayments",
      step: en.bookingDetails.planAndPayments,
      render: (
        <PlanAndPayments
          bookingId={id ?? ""}
          planAndAddonsData={planAndAddonsData}
          planAndAddonsDataLoader={planAndAddonsDataLoader}
          paymentOverviewDataLoader={paymentOverviewDataLoader}
          paymentOverviewData={paymentOverviewData}
          handlePaymentOptionClick={handlePaymentOptionClick}
          navigateBookingDetailsStep={navigateBookingDetailsStep}
          getPaymentOverviewData={getPaymentOverviewData}
        />
      ),
    },
    {
      key: "modifyBooking",
      step: en.bookingDetails.modifyBooking,
      render: (
        <ModifyBooking
          toggleModificationSwitch={toggleModificationSwitch}
          modifyHistorySwitch={modifyHistorySwitch}
          modificationHistoryLoader={modificationHistoryLoader}
          modificationHistoryData={modificationHistoryData}
          bookingId={id}
          setModifyHistorySwitch={setModifyHistorySwitch}
          recentModificationHistoryLoader={recentModificationHistoryLoader}
          recentModificationHistoryData={recentModificationHistoryData}
        />
      ),
      // callback on click of step
      // onClick: () => {
      //   if (id) dispatch(bookingManagementActions.getPlanAndAddOnDetails(id));
      // },
    },
  ];

  const handleNavigateTaskAssignment = () => {
    let data = {
      branch: {
        name: bookingDetails?.branchDetails?.branchName,
        displayName: bookingDetails?.branchDetails?.branchDisplayName,
      },
      city: {
        name: bookingDetails?.branchDetails?.city,
        displayName: bookingDetails?.branchDetails?.cityDisplayName,
      },
    };
    storeData(LocalStorage.Task_ManagementData, data);
    // navigate(`/task-assignment/${bookingId}`)

    navigate(`${routesConstants.TASK_ASSIGNMENT}/${id}`, {
      state: {
        warehouseBranch: {
          name: bookingDetails?.branchDetails?.branchName,
          displayName: bookingDetails?.branchDetails?.branchDisplayName,
        },
        warehouseCity: {
          name: bookingDetails?.branchDetails?.city,
          displayName: bookingDetails?.branchDetails?.cityDisplayName,
        },
        edit: true,
      },
    });
  };

  let ref = useRef<any>();
  /* page level lifecycle */
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.BOOKING_DETAILS));

    return () => {
      dispatch(vehicleActions.clearVehiclImages());
      dispatch(clearBreadcrumbs());
    };
  }, []);

  useEffect(() => {
    // get booking details & operator config on success
    dispatch(
      getBookingDetails({
        bookingId: id,
        getConfig: true,
        fetchVehicleImages: true,
      })
    );
  }, [id]);

  const navigateToStepHandler = () => {
    resetMultistepFormRef.current &&
      resetMultistepFormRef.current.visitStep("vehicleDetails");
  };

  return error404?.errorCode === "BOOKING-404" ? (
    <WrongUrl />
  ) : (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <UserDetails
          bookingDetails={bookingDetails}
          bookingId={id}
          cancelBookingLoader={cancelBookingLoader}
          handleAddChargeModalOpen={handleAddChargeModalOpen}
          toggleAddChargeDrawer={toggleAddChargeDrawer}
          navigateToStepHandler={navigateToStepHandler}
          navigateToCustomerManagement={navigateToCustomerManagement}
          handleNavigateTaskAssignment={handleNavigateTaskAssignment}
          refreshBookingDetails={refreshBookingDetails}
        />

        <MultiStepForm
          steps={bookingDetailsSteps?.filter(
            (step: any) => RbacHasAccess[step.key]
          )}
          viewMode={true}
          Styles={BookingDetailsStyles}
          showStatus={
            (bookingDetailsSteps?.filter(
              (step: any) => RbacHasAccess[step.key]
            )).length
              ? true
              : false
          }
          bookingStatus={toPascalCase(bookingDetails.status)}
          ref={resetMultistepFormRef}
        />
      </Box>

      {/* ------------------------------------------ Content end ---------------------------------------------------------- */}

      {/* Add charges Drawer */}
      <SideDrawer
        disablePadding={true}
        open={showAddChargeModal}
        toggleDrawer={toggleAddChargeDrawer}
        heading={"Additional Charges"}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        renderUI={
          paymentLinkQRLoader || orderDataloader ? (
            <PaymentLinkQRLoader />
          ) : paymentLinkQRData && Object.keys(paymentLinkQRData).length > 0 ? (
            <PaymentDetailsSideModalUI
              paymentLinkQRData={paymentLinkQRData}
              paymentLinkQRLoader={paymentLinkQRLoader}
              orderDataloader={orderDataloader}
              isPaymentSuccess={isPaymentSuccess}
            />
          ) : showAddChargesPaymentModeUi ? (
            <AddMoreChargesPaymentUi
              savedCharges={savedCharges}
              rentalsCommonLoader={rentalsCommonLoader}
              allowedPaymentFlows={allowedPaymentFlows}
            />
          ) : (
            <AddChargesSideModalUI
              // chargesCategoriesData={chargesCategoriesData}
              chargesCategoriesData={chargesTypesArr}
              selectedAddMoreCharges={selectedAddMoreCharges}
              setSelectedAddMoreCharges={setSelectedAddMoreCharges}
              handleAddMoreChargesClick={handleAddMoreChargesClick}
              handleDeleteAllAddMoreCharges={handleDeleteAllAddMoreCharges}
              handleDeleteAddedCharge={handleDeleteAddedCharge}
              handleAddMoreCategoriesSelect={handleAddMoreCategoriesSelect}
              handleAddMoreSubCategoriesSelect={
                handleAddMoreSubCategoriesSelect
              }
              handleAddMoreChargesSaveAll={handleAddMoreChargesSaveAll}
              handleAddMoreChargesPrice={handleAddMoreChargesPrice}
              addMoreErrorObj={addMoreErrorObj}
              handleChargesRemarkToggle={handleChargesRemarkToggle}
              handleAddChargesRemarkChange={handleAddChargesRemarkChange}
            />
          )
        }
        // displayPaymentMethod={false}
      ></SideDrawer>

      {/* payment side drawer plans and payments */}
      <SideDrawer
        open={paymentDrawer?.open}
        heading={en.bookingDetails.payments}
        toggleDrawer={closeSideDrawerPayment}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          paymentLoader ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : paymentDrawer?.paymentFlow === PaymentFlow.PAYMENT_LINK ? (
            <Stack gap="20px" alignItems="center">
              <RenderPaymentLink
                paymentLink={paymentLink}
                tryAnotherPaymentMethod={() => {}}
              />
            </Stack>
          ) : (
            <RenderQRCode
              paymentLink={paymentLink}
              totalCharges={paymentLink?.amount}
              isPaymentLinkGenerated={isPaymentLinkGenerated}
              onSuccesTimerCompletion={onSuccessTimeCompletionPayment}
            />
          )
        }
      />
    </>
  );
};
export default BookingDetails;
