import React from "react";
import { TaskAssignData } from "./data/TaskAssignData";
import {
  Box,
  Button,
  Chip,
  Card,
  Divider,
  TextField,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  useMediaQuery,
  Tooltip,
  Paper,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { BasicSelect } from "../../../components/dropDown";
import { Input1, Input2 } from "../../../components/input";
import StickyHeaderTable from "../../../components/stickeyHeader";
import { Heading } from "../../../components/typography";
import { ButtonActions, UserActions } from "../../../constants/userActions";
import { UserRoles, UserStatus } from "../../../constants/bookingContants";
import BreadCrumbs from "../../../components/breadCumbs";
import { RootState } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";

import { toastr } from "react-redux-toastr";
import { useEffect, useState } from "react";
// import Status from "../../../components/status";
import { Input3 } from "../../../components/input";
import { getData, storeData } from "../../../config/filterStorage";
import { LocalStorage, UserTypes } from "../../../constants/enum";
import ResponsiveDialog from "../../../components/responsiveDialog";
import { colors } from "../../../themes/colors";
import { Actions } from "../../../components/actions";
import { checkAccess, checkSubsidiary } from "../../../utils/helper";
import { AccessTypes } from "../../../interfaces/commonInterface";
import {
  Button1,
  Button2,
  Button3,
  Button4,
  Button5,
  Button6,
} from "../../../components/button";
import {
  faUser,
  faLock,
  faPlusMinus,
  faCartPlus,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../../redux";
import Modal from "@mui/material/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RowGrid from "./component/RowGrid";
import TabButton from "./component/TabButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimeLineComponent from "./component/TimeLineComponent";
import "./component/styles.css";
import TimeLineHeading from "./component/TimeLineHeading";

interface IObjectKeys {
  [key: string]: string | number | any | null;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
  bookingId?: any;
}

const TabStatusTask = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentMenu, setCurrentMenu] = useState<string>("Start Ride");

  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const { locale } = useSelector((state: RootState) => state.localeReducer);

  const [tabName, setTabName] = useState<any>();
  const [active, setActive] = useState<number>(tabName || 0);
  const { taskstatusById } = useSelector(
    (state: RootState) => state.taskReducer
  );
  const {
    vehicleImagesData,
    vehicleEndRideImagesData,
    startOdometerReadingImagesData,
    endOdometerReadingImagesData,
    vehicleImagesDataLoader,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);

  const [selecteddata, setSelectedData] = useState([]);
  const { roleManagement, global, taskManagement } = locale;
  useEffect(() => {
    setSelectedData([]);
  }, [taskstatusById, props?.bookingId]);

  const onChange = (e: any) => {
    setCurrentMenu(e);
    const filterData = taskstatusById?.filter(
      (item: any) => item.taskType == e
    );
    setSelectedData(filterData || []);
  };

  useEffect(() => {
    setActive(tabName || 0);
  }, [tabName]);
  const handleTabChange = (i: number) => {
    setActive(i);
    setTabName(i);
  };

  useEffect(() => {
    if (taskstatusById?.length > 0 && currentMenu) {
      const statusData = taskstatusById?.filter(
        (item: any) => item.taskType == currentMenu
      );
      setSelectedData(statusData);
    }
  }, [taskstatusById, currentMenu, props?.bookingId]);

  useEffect(() => {
    if (props?.bookingId)
      dispatch(actions.getTasKStatus({ bookingId: props?.bookingId }));
  }, [props?.bookingId, currentMenu]);

  const tabsList = ["TASK ASSIGNMENT", "STATUS", "TASK SUMMARY"];

  useEffect(() => {
    let completedTasksCount = 0;
    let totalTasksCount = selecteddata?.length;

    selecteddata.forEach((task: any) => {
      if (
        task.subTaskStatus &&
        task.subTaskStatus.status === "Completed" &&
        task.updatedBy
      ) {
        completedTasksCount++;
      }
    });

    setCompletedTasks(completedTasksCount);
    setTotalTasks(totalTasksCount);
  }, [selecteddata]);

  const dataTimeLine = [
    { time: "09:00 AM", description: "Task 1", doneBy: "John Doe" },
    { time: "11:30 AM", description: "Task 2", doneBy: "Jane Smith" },
    { time: "02:15 PM", description: "Task 3", doneBy: "Bob Johnson" },
  ];

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        marginTop: "25px",
        overflow: "hidden",
        backgroundColor: "#fff",
        borderRadius: "8px",
        width: "100%",
        padding: "25px",
      }}
    >
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sm={12}
        sx={{
          display: "flex",
          marginTop: "25px",
          marginBottom: "12px",
          overflow: "hidden",
          justifyContent: "start",
        }}
      >
        <TabButton
          tabName={"Start Ride"}
          displayName={"Start Ride"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Start Ride")}
        />
        <TabButton
          tabName={"Complete Ride"}
          displayName={"End Ride"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Complete Ride")}
        />
        <TabButton
          tabName={"Service"}
          displayName={"Service"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Service")}
        />
        <TabButton
          tabName={"Others"}
          displayName={"Others"}
          CurrentTab={currentMenu}
          onClick={() => onChange("Others")}
        />
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={3}
        lg={2}
        className="timeh"
        style={{ paddingTop: "10px" }}
      >
        <p className="headingtasktext">
          TOTAL SUBTASK COMPLETED : {completedTasks} / {selecteddata?.length}{" "}
        </p>
      </Grid>
      {/* <p> {completedTasks} / {selecteddata.length}</p> */}

      {currentMenu == "Start Ride" ? (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "12px",
          }}
        >
          {selecteddata?.length ? (
            <div className="timeLinebody">
              <TimeLineHeading />
              <TimeLineComponent
                data={selecteddata}
                vehicleImagesData={vehicleImagesData}
                startOdometerReadingImagesData={startOdometerReadingImagesData}
              />
            </div>
          ) : (
            "No Task Found For Start Ride"
          )}
        </Grid>
      ) : null}

      {currentMenu == "Complete Ride" ? (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "12px",
          }}
        >
          {selecteddata?.length ? (
            <div className="timeLinebody">
              <TimeLineHeading />
              <TimeLineComponent
                data={selecteddata}
                vehicleEndRideImagesData={vehicleEndRideImagesData}
                endOdometerReadingImagesData={endOdometerReadingImagesData}
              />
            </div>
          ) : (
            "No Task Found For End Ride"
          )}
        </Grid>
      ) : null}
      {currentMenu == "Service" ? (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "12px",
          }}
        >
          {selecteddata?.length ? (
            <div className="timeLinebody">
              <TimeLineHeading />
              <TimeLineComponent data={selecteddata} />
            </div>
          ) : (
            "No Task Found For Services"
          )}
        </Grid>
      ) : null}
      {currentMenu == "Others" ? (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "12px",
          }}
        >
          {selecteddata?.length ? (
            <div className="timeLinebody">
              <TimeLineHeading />
              <TimeLineComponent data={selecteddata} />
            </div>
          ) : (
            "No Task Found For Others"
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TabStatusTask;
