import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { uploadError } from "../../rentals-src/constants/constants";
import { toastr } from "react-redux-toastr";
import rentalEn from "../../locale/en.json";

const CustomUpload = (props: any) => {
  const {
    id = "",
    customStyle = {},
    onUploadSuccess,
    onUpload,
    maxFiles,
    maxFileSize = 5000000,
    disabled,
    multiple = false,
    acceptedDocTypes = {},
    ...rest
  } = props;

  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.error("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });

    let error: boolean = false;
    fileRejections.map((item: any) => {
      if (item?.errors[0]?.code) {
        error = true;
        toastr.warning(
          "",
          uploadError[item.errors[0]?.code] ??
            rentalEn?.errorMessages?.SomethingWentWrong
        );
        return;
      }
    });

    if (error) return;

    onUpload(acceptedFiles, id);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      ...acceptedDocTypes,
    },
    maxSize: maxFileSize,
    multiple,
    noDrag: false,
    noClick: disabled,
  });

  return (
    <Box key={id} {...getRootProps()} sx={customStyle}>
      <input {...getInputProps()} />
      {props.children}
    </Box>
  );
};

export default CustomUpload;
