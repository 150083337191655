import { Cross } from "../../../assets/exportImages";

function PolicyBox(props: any) {
  return (
    <>
      <div className="login-right">
        <div className="pw-polic-card">
          <div className="box arrow-right">
            <h6 className="mb-2">Freedo Password Policy</h6>
            <img
              src={Cross}
              alt=""
              className="close-icon-pw"
              onClick={props?.onClickCross}
            />
            <ul>
              <li
                className={
                  props?.policyChecks?.hasMinLength ? "pwpolicyactive" : ""
                }
              >
                Must be contain <span> 8 characters. </span>
              </li>

              <li
                className={
                  props?.policyChecks?.hasUppercase ? "pwpolicyactive" : ""
                }
              >
                Must be contain <span> 1 uppercase letter. </span>
              </li>
              <li
                className={
                  props?.policyChecks?.hasLowercase ? "pwpolicyactive" : ""
                }
              >
                Must be contain <span> 1 lowercase letter .</span>
              </li>

              <li
                className={
                  props?.policyChecks?.hasNumber ? "pwpolicyactive" : ""
                }
              >
                Must be contain <span> 1 number </span>
              </li>
              <li
                className={
                  props?.policyChecks?.hasSpecialCharacter
                    ? "pwpolicyactive"
                    : ""
                }
              >
                Must be contain <span> 1 symbol . </span>
              </li>
            </ul>
            <h6 className="mt-3 mb-2">Notes:</h6>

            <ul>
              <li>Password must not contain the Name of user.</li>
              <li>
                Password must not contain the username of the credentials( for
                example password for freedogurugram in the password and password
                for Ashish must not contain Ashish in the password.)
              </li>
              <li>
                {" "}
                Password must not include repetitive or sequential words ('aaa',
                1234,abcd, 111aaabbb')
              </li>
              <li> Password must not contain previous 3 created password. </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export { PolicyBox };
