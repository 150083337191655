import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import LinkIcon from "@mui/icons-material/Link";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { typographyConstants } from "../../rentals-src/constants/constants";
import {
  CheckedImg,
  FailPaymentIcon,
} from "../../rentals-src/constants/exportImages";
import en from "../../rentals-src/locale/rental-en.json";
import { getFormattedINR } from "../../rentals-src/utils/helper";
import { colors } from "../../themes/colors";
import Lottie from "lottie-react";
import { SuccessLottie } from "../../rentals-src/constants/exportLottie";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

interface RenderPaymentLinkInterface {
  paymentLink: any;
  tryAnotherPaymentMethod?: Function;
  amount?: number;
}
// payment link ui
export const RenderPaymentLink = ({
  paymentLink,
  tryAnotherPaymentMethod,
  amount = 0,
}: RenderPaymentLinkInterface) => {
  // on click Link redirect to next page
  const redirectLinkHandler = (link: any) => {
    if (link) {
      window.open(paymentLink?.pgDetails?.link, "_blank");
    }
  };
  // copy link
  const [isCopied, setIsCopied] = useState<boolean>(false);
  return (
    <Stack gap="20px" alignItems="center">
      <Stack alignItems={"center"}>
        {/* <img
          src={CheckedImg}
          alt="Check Icon"
          width="120px"
          height="120px"
          style={{ padding: "5px 0px" }}
        /> */}
        <Lottie
          style={{ height: "100px", width: "100px" }}
          animationData={SuccessLottie}
          loop={false}
        />
        {amount > 0 && (
          <Typography
            variant={typographyConstants.HEADING}
            sx={{ padding: "unset" }}
          >
            {getFormattedINR(amount)}
          </Typography>
        )}

        <Typography
          variant={typographyConstants.HEADING}
          sx={{ padding: "unset", textAlign: "center" }}
        >
          Customer Pay Link Generated Successfully
        </Typography>

        <Typography
          variant={typographyConstants.BODY}
          color={colors.text_secondary_gray}
        >
          Customer can Pay through the Freedo mobile app
        </Typography>
      </Stack>

      {/* link & copy */}
      <Stack direction="row" gap="1vw">
        <Typography
          sx={{ cursor: "pointer", color: "#09CACC" }}
          onClick={(e) => redirectLinkHandler(paymentLink?.pgDetails?.link)}
        >
          {paymentLink?.pgDetails?.link}
        </Typography>
        <Typography
          title={!isCopied ? "Click to copy" : "copied"}
          sx={{ cursor: "pointer" }}
        >
          {!isCopied ? (
            <ContentCopyIcon
              sx={{ fontSize: "1rem" }}
              onClick={(e) => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
                return navigator.clipboard.writeText(
                  paymentLink?.pgDetails?.link
                );
              }}
            />
          ) : (
            <ContentCopyTwoToneIcon sx={{ fontSize: "1rem" }} />
          )}
        </Typography>
      </Stack>
      {/* label */}
      <Typography
        variant="caption"
        color={colors.text_secondary_gray}
        onClick={() =>
          navigator.clipboard.writeText(paymentLink?.pgDetails?.link)
        }
        sx={{ cursor: "pointer" }}
        title={en?.global?.clickToCopy}
      >
        <LinkIcon color="primary" /> {en?.global?.clickToPayNow}
      </Typography>
      {tryAnotherPaymentMethod ? (
        <Stack alignItems="center">
          <Typography color={colors.text_secondary_gray}>
            {en.manualBooking.HavingIssues}
          </Typography>
          <Box
            onClick={() => tryAnotherPaymentMethod()}
            sx={{ cursor: "pointer" }}
          >
            <Typography
              variant={typographyConstants.BODY}
              fontWeight="500"
              color={colors.THEME_BLUE}
            >
              {en.manualBooking.TryAnotherPaymentMethod}
            </Typography>
          </Box>
        </Stack>
      ) : null}
    </Stack>
  );
};

/* interface RenderPaymentLinkInterface {
  tryAnotherPaymentMethod?: Function;
  totalCharges?: number;
  paymentLink: any;
  isPaymentLinkGenerated: boolean;
  onSuccesTimerCompletion: Function;
} */

export const RenderQRCode = ({
  tryAnotherPaymentMethod,
  totalCharges,
  paymentLink,
  isPaymentLinkGenerated,
  onSuccesTimerCompletion,
}: any) => {
  const [progress, setProgress] = useState(0);

  // show progress of timer
  useEffect(() => {
    if (isPaymentLinkGenerated) {
      const progresstimer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 0.33
        );
      }, 1000);
      return () => {
        clearInterval(progresstimer);
      };
    }
  }, [isPaymentLinkGenerated]);

  return (
    <Stack gap="20px" alignItems="center">
      <Typography variant={typographyConstants.HEADINGXL} fontSize="32px">
        {getFormattedINR(totalCharges)}
      </Typography>
      <Typography variant="caption" color={colors.text_secondary_gray}>
        <QrCodeScannerIcon color="primary" /> Scan to Pay Now
      </Typography>
      {paymentLink.pgDetails?.imageContent ? (
        <QRCode
          size={256}
          style={{ height: "auto" }}
          value={paymentLink.pgDetails?.imageContent}
          viewBox="0 0 200 200"
        />
      ) : (
        <></>
      )}

      {tryAnotherPaymentMethod ? (
        <Stack alignItems="center">
          <Typography color={colors.text_secondary_gray}>
            {en.manualBooking.HavingIssues}
          </Typography>
          <Box onClick={tryAnotherPaymentMethod} sx={{ cursor: "pointer" }}>
            <Typography
              variant={typographyConstants.BODY}
              fontWeight="500"
              color={colors.THEME_BLUE}
            >
              {en.manualBooking.TryAnotherPaymentMethod}
            </Typography>
          </Box>
        </Stack>
      ) : null}
      {onSuccesTimerCompletion ? (
        <Stack alignItems="center">
          {/* <Typography>{en.manualBooking.RedirectingToViewBookingIn} </Typography> */}
          <CircularProgressWithLabel
            value={progress}
            onSuccesTimerCompletion={onSuccesTimerCompletion}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

// progress bar of 1:00 minutes
function CircularProgressWithLabel({ onSuccesTimerCompletion }: any) {
  const [secondsLeft, setSecondsLeft] = useState<any>(120);
  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds: any) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          onSuccesTimerCompletion();
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={(secondsLeft / 60) * 100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${formatTime(secondsLeft)}`}</Typography>
      </Box>
    </Box>
  );
}

//on sucees payment ui
export const PaymentSuccessUI = ({ paymentLink }: any) => {
  return (
    <Stack alignItems={"center"}>
      <img
        src={CheckedImg}
        alt="Check Icon"
        width="120px"
        height="120px"
        style={{ padding: "5px 0px" }}
      />
      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        {getFormattedINR(paymentLink?.amount)}
      </Typography>

      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        Payment successfully captured !
      </Typography>
    </Stack>
  );
};

export const RenderCashUI = ({ paymentLink, tryAnotherPaymentMethod }: any) => {
  return (
    <Stack alignItems={"center"}>
      <img
        src={CheckedImg}
        alt="Check Icon"
        width="120px"
        height="120px"
        style={{ padding: "5px 0px" }}
      />
      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        {getFormattedINR(paymentLink?.amount)}
      </Typography>

      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        Amount Paid in Cash
      </Typography>
    </Stack>
  );
};

//on sucees payment ui
export const PaymentFailedUI = ({ paymentLink }: any) => {
  return (
    <Stack alignItems={"center"}>
      <img
        src={FailPaymentIcon}
        alt="Check Icon"
        width="120px"
        height="120px"
        style={{ padding: "5px 0px" }}
      />
      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        {getFormattedINR(paymentLink?.amount)}
      </Typography>

      <Typography
        variant={typographyConstants.HEADING}
        sx={{ padding: "unset" }}
      >
        Payment failed at this moment !
      </Typography>
    </Stack>
  );
};
