import { getBranchXPayload } from "../../../interfaces/manualBookingInterfaces";
import * as actionTypes from "../actionTypes";

export const fetchAllCitiesX=()=>{
    return {
      type:actionTypes.MASTER_ACTIONS_X.GET_CITY_DATA_X_REQUESTED,
    }
  }

export const getBranchX=(payload:getBranchXPayload)=>{
  return {
    type:actionTypes.MASTER_ACTIONS_X.GET_BRANCH_DATA_X_REQUESTED,
    payload
  }
}

export const getModelX=(payload:any)=>{
  return {
    type:actionTypes.MASTER_ACTIONS_X.GET_MODAL_DATA_X_REQUESTED,
    payload
  }
}

export const addActiveModuleX = (payload: any) => {
  return {
    type:actionTypes.ACTIVE_MODULE_X.ADD_ACTIVE_MODULE,
    payload
  }
}

export const getAllModelsX = () => {
  return {
    type:actionTypes.MASTER_ACTIONS_X.GET_ALL_MODELS_X_REQUESTED
  }
}

export const getAllWarehouseNameX = () => {
  return {
    type:actionTypes.MASTER_ACTIONS_X.GET_ALL_WAREHOUSES_X_REQUESTED,
  }
}

export const getChargesCategories = () => {
  return {
    type:actionTypes.MASTER_ACTIONS_X.GET_YOR_CHARGES_CATEGORIES_REQUESTED,
  }
}