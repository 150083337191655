import { ACTIONS } from "../actionTypes";
import * as actionTypes from "../actionTypes";

export const indicatorAction = (payload: boolean) => ({
  type: ACTIONS.LOCALE,
  payload,
});

export const showToast = (payload: any) => ({
  type: actionTypes.ACTIONS.SHOW_TOAST,
  payload,
});
export const hideToast = (payload: any) => ({
  type: actionTypes.ACTIONS.HIDE_TOAST,
  payload,
});

export interface GetModelListById {
  CategoryId?: number;
  TypeId?: number;
}

export const getAggregator = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING.GET_AGGREGATOR_REQUESTED,
    payload,
  };
};

//------------------------------------------------------------------------------
/* actions for uploading documents to AWS s3 bucket */

//1. Action to generate signed url for particular document
export const getSignedUrl = (payload: any) => ({
  type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.GET_SIGNED_URLS_ATTACHMENT,
  payload,
});

// 2. Action to upload documents to signed url
export const uploadDocumentSignedUrl = (payload: any) => ({
  type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.PUT_ATTACHMENT_ON_SIGNED_URL,
  payload,
});

//3. Action to move documents to s3 bucket
export const uploadDocument = (payload: any) => {
  return {
    type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.POST_DOCUMENT_IN_S3_REQUESTED,
    payload,
  };
};

export const deleteDocument = (payload: any) => ({
  type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.DOCUMENT_DELETE_REQUESTED,
  payload,
});

export const clearDocUploadRedux = () => ({
  type: actionTypes.DOCUMENT_UPLOAD_ACTIONS.CLEAR_DOCUMENT_UPLOAD_REDUX,
});
//-----------------------------------------------------------------------
