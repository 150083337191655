import axios from "axios";
import { API_URLS, FREEDO_X_URL, FREEDO_MUNIM_X_URL } from "../../config";
import { customAxios } from "../../config/axiosConfig";
import {
  BookingX,
  FreedoX,
  Global,
  YORBookingDetails,
} from "../constantsX/apiConstantsX";
import { getAvailableVehicleListPayload } from "../interfaces/bookingInterfaces";

const getYORBookingDetails = (payload: any) => {
  try {
    let queryParams = payload?.keys ? payload?.keys : [];
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.id}/` +
      YORBookingDetails.YORbookingDetails +
      `?keys=[${queryParams}]`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getYORPaymentHistory = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.id}/` +
      YORBookingDetails.YORPaymentHistory +
      `?page=1&pageSize=100`;

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getBookingStatus = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.id}` +
      BookingX.bookingStatus;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const startRideX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.bookingId}` +
      BookingX.startRide;
    return customAxios.post(url, payload.data).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

//fetch available vehicle for start ride
const getAvailableVehiclesX = (payload: getAvailableVehicleListPayload) => {
  try {
    let url =
      FREEDO_X_URL +
      Global.globalSearch +
      Global.versioning1 +
      BookingX.vehicles;
    url += `?branches=["${payload.branches}"]`;
    url += `&modelNames=["${payload.modelNames}"]`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getPaymentPendingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.id}/` +
      FreedoX.paymentPendingX;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getPaymentModesYOR = (payload: any) => {
  // https://dev-api.freedo.rentals/freedo-r2o/master/v1/branch-config/payment/modes?branchName=IN_UP_NOIDA_FR_SECTOR_63_NOIDA
  try {
    let queryParams = payload?.branchName;

    let url =
      FREEDO_X_URL +
      Global.master +
      Global.versioningV1 +
      YORBookingDetails.YORPaymentModes +
      YORBookingDetails.YORBranchNameParam +
      queryParams;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const cancelBookingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload}` +
      BookingX.cancelBookingX;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const closeBookingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload}` +
      BookingX.closeBookingX;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const generateOrderIdYOR = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.bookingId}/` + // bookingId
      YORBookingDetails.YORCreateOrderEndpoint;

    let orderPayload = {
      data: {},
    };

    if (payload?.additionalChargesIds) {
      orderPayload.data = {
        additionalChargesIds: payload?.additionalChargesIds,
      };
    }

    if (payload?.schedules) {
      orderPayload.data = {
        schedules: payload?.schedules,
      };
    }
    return customAxios.post(url, orderPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const generatePaymentLinkQRForOrder = (payload: any) => {
  try {
    let url =
      FREEDO_MUNIM_X_URL +
      YORBookingDetails.YORPaymentLinkEndpoint +
      Global.versioning1;
    const paymentPayload = { data: payload.data };
    return customAxios.post(url, paymentPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getChargesBifurcationServiceYOR = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.bookingId}/` + // bookingId
      YORBookingDetails.YORPaymentLinkEndpoint +
      `/${payload.recordId}`; // Order record's id
    Global.versioning1;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const waivedOffCharges = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.bookingParams?.id}` +
      YORBookingDetails?.YORWaivedOffCharges;
    return customAxios.put(url, payload?.apiPayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const saveAdditionalchargesYOR = (payload: any) => {
  try {
    const chargePayload = {
      data: {
        charges: payload.charges,
      },
    };
    // {{Base_url_dev}}/freedo-r2o/booking/v1/1FOUI318HXJ4/additional-charge
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.bookingId}/` +
      YORBookingDetails?.YORAddAdditionalCharge;
    return customAxios.post(url, chargePayload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getYORBookingDetails,
  getYORPaymentHistory,
  getBookingStatus,
  startRideX,
  getAvailableVehiclesX,
  getPaymentPendingX,
  cancelBookingX,
  closeBookingX,
  getPaymentModesYOR,
  generateOrderIdYOR,
  generatePaymentLinkQRForOrder,
  getChargesBifurcationServiceYOR,
  waivedOffCharges,
  saveAdditionalchargesYOR,
};
