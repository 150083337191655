import axios from "axios";
import { API_URLS, BASE_URL, customAxios, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

const getCalendarData = (payload: any) => {
  try {
    let url: string = BASE_URL + getApiUrl().getCalendarData;
    url += payload?.startDate ? "?startDate=" + payload.startDate : "";
    url += payload?.branches ? `&branches=["${payload?.branches}"]` : "";
    url += payload?.cities ? `&cities=["${payload?.cities}"]` : "";
    url += payload?.model ? `&model=["${payload?.model}"]` : "";
    url += payload?.useCase ? `&useCase=${payload?.useCase}` : "";
    url += payload?.endDate ? "&endDate=" + payload?.endDate : "";

    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// Fetch ongoing bookings details based on the provided dynamic parameters.
const getOngoingBookingsDetails = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getBookings}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch upcoming bookings details based on the provided dynamic parameters.
const getUpcomingBookingsDetails = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getBookings}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch blocked vehicles details based on the provided dynamic parameters.
const getBlockedVehiclesDetails = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getBlockedVehicles}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch vehicles list based on the provided dynamic parameters. (For vehcile management dashboard)
const getVehiclesList = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getVehiclesList}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Updates the available/blocked status of a vehicle based on the provided payload.
const updateVehicleStatus = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().updateVehicleStatus}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Adds new vehicle details based on the provided payload. (Vehicle details section)
const addNewVehicleDetails = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().addNewVehicleDetails}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Approves or rejects vehicle based on the provided payload.
const approveRejectVehicle = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().approveRejectVehicle}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Get vehicle details by vehicle id
const getVehicleById = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl(payload?.id).getVehicleById}`;
    return customAxios.get(url).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// get live challans from kyc kart api
const getLiveChallan = (payload: any) => {
  try {
    const url = `${BASE_URL}${getApiUrl().getChallanLive}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// get challans from DB
const getChallans = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getChallanList}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// get assigned IOT device
const getAssignedIotDevices = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${
      getApiUrl(payload?.vehicleId).getAssignedIotDevices
    }`;
    return customAxios.get(url).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// unassign IOT device
const unAssigneIotDevices = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().unAssignIotDevice}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// api to link challan to particular booking
const linkChallans = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().linkChallanVehicle}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// api to link challan to particular booking
const unlinkChallans = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().unlinkChallanVehicle}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// api to get ping history of vehicle
// deviceIds == should be array
const pingHistory = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl().getPingHistory
    }?deviceIds=${JSON.stringify(payload?.deviceIds)}`;
    url += payload?.page ? `&page=${payload?.page}` : "";
    url += payload?.pageSize ? `&pageSize=${payload?.pageSize}` : "";
    return customAxios.get(url).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// Fetch IoT devices based on the provided dynamic parameters.
const getIotDevices = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getIotDevices}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Assigns an IoT device based on the provided payload.
const assignIotDevice = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().assignIotDevice}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// get tranfer vehilce list
const getAllTransferVehicleList = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getAllVehicleTranfers}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// tranfer vehicle post api
const transferVehicleRequest = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getAllVehicleTranfers}`;
    return customAxios.post(apiUrl, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

//get tranfer vehicle list request
const getTranferVehicleList = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().getTranferVehicleRequest}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch vehicle model list data based on the provided dynamic parameters.
const fetchVehicleModelListData = (payload: any) => {
  try {
    const apiUrl: string = `${BASE_URL}${getApiUrl().fetchVehcileModelList}`;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch vehicle colour list data.
const fetchVehicleColourlListData = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().fetchVehicleColourList}`;
    return customAxios.get(url).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Edit vehicle details based on the provided payload. (Vehicle details section)
const editVehicleDetails = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().editVehicleDetails}`;
    return customAxios.put(url, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Delete vehicle based on the provided payload.
const deleteVehicle = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().deleteVehicle}`;
    return customAxios.delete(url, { data: { ...payload } }).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// service to get signed url
const getVehicleSignedUrlService = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().getSignedUrlVehicle}`;
    return customAxios.post(url, payload.requestdata).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

// upload document on signed url
const uploadDocumentService = (payload: any) => {
  try {
    // const fileData = new Blob([payload?.file], { type: payload?.file?.type });
    const fileData = new Blob([payload?.file], {
      type: payload?.file?.type,
    });

    return axios
      .put(payload.url, fileData, {
        headers: { "Content-Type": payload?.file.type },
      })
      .catch((ex) => {
        return ex;
      });
  } catch (ex) {
    return ex;
  }
};

// service to get vehicle images
const getVehicleImagesService = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${
      getApiUrl(payload.imageRefId).getVehicleImages
    }`;

    return customAxios.get(url).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// get vehicle documents(
const getVehicleDocuments = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getUplodedDocuments}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//delete vehicle documents
const deleteVehicleDocuments = (payload: any): any => {
  try {
    const url: string = `${BASE_URL}${API_URLS.deleteVehicleDocuments}`;
    return customAxios.delete(url, payload?.deleteBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// add vehicle documents
const addVehicleDocuments = (payload: any): any => {
  try {
    const url: string = `${BASE_URL}${API_URLS.addVehicleDocuments}/${payload.apiParams}`;
    return customAxios.post(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// download vehicle documents
const downloadVehicleDocument = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${API_URLS.downloadVehicleDocument}/${payload.params}`;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

const downloadExcelSheetBulkUpload = () => {
  try {
    const url: string = `${BASE_URL}${getApiUrl()?.downloadExcelSheet}`;
    return customAxios.get(url, { responseType: "blob" }).catch((e) => e);
  } catch (error) {
    return error;
  }
};

//upload excel sheet
const uploadBulkUploadSheet = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl()?.uploadBulkUploadSheet}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (error) {
    return error;
  }
};

// Bulk request initiate
const bulkRequestInitiate = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl()?.bulkRequestInitiate}`;
    return customAxios.post(url, payload).catch((e) => e);
  } catch (error) {
    return error;
  }
};

//get dash board vehicle stats
const getVehicleStats = (payload: any) => {
  try {
    const url: string = `${BASE_URL}${getApiUrl().getVehicleStats}`;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (error) {
    return error;
  }
};

export default {
  getCalendarData,
  getOngoingBookingsDetails,
  getUpcomingBookingsDetails,
  getBlockedVehiclesDetails,
  getVehiclesList,
  updateVehicleStatus,
  addNewVehicleDetails,
  getVehicleById,
  getLiveChallan,
  getChallans,
  getAssignedIotDevices,
  unAssigneIotDevices,
  approveRejectVehicle,
  linkChallans,
  unlinkChallans,
  pingHistory,
  getIotDevices,
  assignIotDevice,
  getAllTransferVehicleList,
  getTranferVehicleList,
  fetchVehicleModelListData,
  transferVehicleRequest,
  fetchVehicleColourlListData,
  editVehicleDetails,
  deleteVehicle,
  getVehicleSignedUrlService,
  getVehicleImagesService,
  uploadDocumentService,
  getVehicleDocuments,
  deleteVehicleDocuments,
  addVehicleDocuments,
  downloadVehicleDocument,
  downloadExcelSheetBulkUpload,
  uploadBulkUploadSheet,
  bulkRequestInitiate,
  getVehicleStats
};
