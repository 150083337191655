import DoNotTouchOutlinedIcon from "@mui/icons-material/DoNotTouchOutlined";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  styled,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VdAccordion from "../../../../../freedo-x-src/components/vdAccordion";
import { DisplayPaymentButtonLabel } from "../../../../../freedo-x-src/constantsX/YOR_BookingDetails";
import {
  ExtraChargesIcon,
  PaymentInitiatedIcon,
  PendingClockIcon,
  SuccessTick,
  WavingHandIcon,
} from "../../../../../freedo-x-src/constantsX/exportImagesX";
import { RootState } from "../../../../../redux/reducers";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import SubscriptionItem from "../../../../../sharedComponents/templates/MultiStepForm/subscriptionItem";
import SideDrawer from "../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import {
  AddChargesInputLength,
  BookingChargesDispalyStatus,
  BookingChargesStatus,
  BookingExtensionStatus,
  BookingStatusEnum,
  DisplayChargesNameTypes,
  DisplayPaymentModes,
  GenericObject,
  HELMET_DISPLAY_NAME,
  PaymentJourneyStatus,
  chargesNameTypes,
  statusColorCode,
  typographyConstants,
} from "../../../../constants/constants";
import {
  BikeIcon,
  OdometerIcon,
  vehicleIotsIcon,
} from "../../../../constants/exportImages";
import {
  cleanUpOrderAndPaymentData,
  createOrderForBulkPaymentRevamp,
  deleteBookingExtension,
  getAdditionalCharges,
  getBookingDetails,
  getOdometerHistory,
  getVehicleExtension,
  setPaymentSuccess,
  waiveOffChargesRevamp,
} from "../../../../redux/actions/bookingManagementActions";
import {
  amountConvertToPaise,
  amountConvertToRupee,
  dateFormat,
  getFormattedINR,
  toPascalCase,
} from "../../../../utils/helper";
import styles from "../bookingDetails.module.css";
import OdometerHistory from "./odometerHistory";
import PaymentLinkQRLoader from "../../../../../sharedComponents/molecules/SkeletonLoader/BookingManagement/PaymentLinkQRLoader";
import en from "../../../../locale/rental-en.json";
import PaymentDetailsSideModalUI from "../components/PaymentDetailsSideModalUI";

import { GridColDef } from "@mui/x-data-grid";

import StartRideModal from "./StartRideModal";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { getPingData } from "../../../../redux/actions/iotManagementActions";
import CustomAccordion from "../../../../../sharedComponents/molecules/Accordion/customAccordian";
import ExtraChargesBody from "./ExtraChargesTable";
import CustomHoverPopover from "../../../../../sharedComponents/molecules/Popover/HoverPopover";
import { bookingManagementActions } from "../../../../redux/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExtendRideDialog } from "../../../../interfaces/bookingManagement";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  bookingFunctionalities,
  iotManagementFunctionality,
  modules,
} from "../../../../../constants/RBACModuleEnums";

export interface DetailsTypographyProps {
  heading: string;
  value: string;
  link?: string;
  loading?: boolean;
  render?: JSX.Element;
  clickHandler?: any;
  elementClassList?: string;
  redirectLink?: string;
  visitStepData?: {
    value: string;
    step: string | number;
  };
  valueStyle?: any;
  color?: string;
  clickhandler?: any;
}

// Charges Sub Type Filter Choices
const chargesSubtypeFilterArr = [
  {
    name: "",
    displayName: "All",
  },
  {
    name: chargesNameTypes.TNC_VIOLATION,
    displayName: DisplayChargesNameTypes.TNC_VIOLATION,
  },
  {
    name: chargesNameTypes.TRAFFIC_RULE_VIOLATION,
    displayName: DisplayChargesNameTypes.TRAFFIC_RULE_VIOLATION,
  },
  {
    name: chargesNameTypes.OTHERS,
    displayName: DisplayChargesNameTypes.OTHERS,
  },
  {
    name: chargesNameTypes.PENALTY_CHARGES,
    displayName: DisplayChargesNameTypes.PENALTY_CHARGES,
  },
];

const DetailsTypography: any = (props: DetailsTypographyProps) => {
  return (
    <Box>
      <Typography variant="caption" color={colors.text_secondary_gray}>
        {props?.heading}
      </Typography>
      <Box sx={{ display: "flex" }}>
        {props?.value && (
          <Tooltip title={props?.value?.length > 18 ? props.value : ""}>
            <Typography sx={props?.valueStyle} color={colors.text_black}>
              {props?.value}
            </Typography>
          </Tooltip>
        )}

        {props?.link && (
          <Tooltip title={props?.link?.length > 15 ? props.link : ""}>
            <Typography
              className={styles.bookingDetailsValue}
              color={props?.color ? props.color : colors.theme_blue}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={props?.clickhandler}
            >
              {props.link}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const MyVehicle = ({
  toggleDrawer,
  isAssignModalopen,
  toggleAssignModal,
  vehicleData,
  bookingData,
  unassignVehicleLoader,
}: any) => {
  const RbacHasAccess = {
    assignUnassignVehicle: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.ASSIGN_UNASSIGN_VEHICLE
    ),
    odmeterHistory: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.ODOMETER_HISTORY
    ),
  };

  const dispatch = useDispatch();
  const { bookingId, bookingStatus } = bookingData;

  return (
    <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" gap="20px" alignItems="center">
        <Stack direction="row" gap="20px" alignItems="center">
          <Box className={styles.iconWrapper}>
            <img src={BikeIcon} />
          </Box>
          <Box>
            <DetailsTypography
              heading={`${en.bookingDetails.vehicleModel}: `}
              value={vehicleData?.displayName ?? en.global.NA}
            />
          </Box>
        </Stack>
        <Stack direction="row" gap="20px" alignItems="center">
          <Box>
            <Typography color={colors.text_secondary_gray}>
              Odometer Reading:
            </Typography>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography color={colors.text_black}>
                {vehicleData?.currentReading ?? en.global.NA}
              </Typography>
              {RbacHasAccess.odmeterHistory && vehicleData?.vehicleId && (
                <Box
                  sx={{
                    border: `1px solid ${colors.card_border_bottom}`,
                    borderRadius: "5px",
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(getOdometerHistory(bookingId));
                    toggleDrawer();
                  }}
                >
                  <img src={OdometerIcon} />
                </Box>
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Box marginRight={"15px"}>
        {bookingStatus === BookingStatusEnum.UPCOMING ? (
          <Box>
            {RbacHasAccess.assignUnassignVehicle && (
              <CustomButton
                label={
                  vehicleData?.vehicleId
                    ? en.bookingDetails.unassignVehicle
                    : en.bookingDetails.assignVehicle
                }
                loading={unassignVehicleLoader}
                variant="contained"
                onClick={(e: any) => {
                  e.stopPropagation();
                  vehicleData?.vehicleId
                    ? dispatch(
                        bookingManagementActions?.bookingUnassignedVehicle({
                          bookingId: bookingId,
                        })
                      )
                    : toggleAssignModal(true);
                }}
                sx={{
                  padding: "8px 13px",
                  minWidth: "137px",
                  background: colors.THEME_BLUE,
                  "&:hover": {
                    background: colors.THEME_BLUE,
                  },
                  "&:active": {
                    background: colors.THEME_BLUE,
                  },
                }}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Stack>
  );
};

const MyVehicleBody = ({ vehicleData, navigateToVehicleDetails }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        margin: "10px 0px",
      }}
    >
      <Box className={styles.iconWrapper}>
        <img src={BikeIcon} />
      </Box>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Box>
          <DetailsTypography
            heading={`${en.bookingDetails.vehicleNumber}: `}
            link={vehicleData?.registration ?? en.global.NA}
            clickhandler={() => {
              vehicleData?.registration &&
                navigateToVehicleDetails(vehicleData?.vehicleId);
            }}
            color={vehicleData?.registration ? colors.LIGHT_BLUE : colors.black}
          />
        </Box>
        <Box>
          <DetailsTypography
            heading={`${en.bookingDetails.chassisNo}: `}
            value={vehicleData?.chassis ?? en.global.NA}
          />
        </Box>
      </Box>
    </Box>
  );
};

const columnStatusUI = (row: GridRenderCellParams): JSX.Element => {
  let rowData = row?.row;
  switch (rowData?.status) {
    case BookingChargesStatus.PAID:
      return (
        <>
          <img src={SuccessTick} height={"20px"} width={"auto"} />
          <Typography
            variant="caption"
            color={colors.secondary_green}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PAID}
          </Typography>
        </>
      );

    case BookingChargesStatus.PENDING:
      return (
        <>
          <img src={PendingClockIcon} width={"auto"} height={"23px"} />
          <Typography
            variant="caption"
            color={colors.primary_warning}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PENDING}
          </Typography>
        </>
      );

    case BookingChargesStatus.WAIVED_OFF:
      return (
        <>
          <img src={WavingHandIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontWeight={500}
            fontSize={"14px"}
          >
            {" "}
            {BookingChargesDispalyStatus.WAIVED_OFF}
          </Typography>
        </>
      );
    case BookingChargesStatus.PARTIALLY_PAID:
      return (
        <>
          <img src={WavingHandIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontWeight={500}
            fontSize={"14px"}
          >
            {" "}
            {BookingChargesDispalyStatus.PARTIALLY_PAID}
          </Typography>
        </>
      );

    case BookingChargesStatus.PROCESSING:
      return (
        <>
          <img src={PaymentInitiatedIcon} />
          <Typography
            variant="caption"
            color={colors.primary_dark_blue2}
            fontSize={"14px"}
            fontWeight={500}
          >
            {" "}
            {BookingChargesDispalyStatus.PROCESSING}
          </Typography>
        </>
      );
  }
  return <></>;
};

const ExtraCharges: any = ({ additionalChargesList }: any) => {
  const pendingChargesAmount = additionalChargesList?.pendingChargesAmount ?? 0;
  const extraViolations = additionalChargesList?.voilations ?? 0;
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Box className={styles.iconWrapper}>
        <img src={ExtraChargesIcon} />
      </Box>
      <DetailsTypography
        heading={en.bookingDetails.additionalCharges.PENDING_CHARGES}
        value={
          pendingChargesAmount
            ? getFormattedINR(pendingChargesAmount)
            : getFormattedINR(0)
        }
        valueStyle={{ fontSize: "0.7rem", color: "#FF3B30" }}
      />
      <DetailsTypography
        heading={en.bookingDetails.additionalCharges.TOTAL_VIOLATIONS}
        value={extraViolations ? extraViolations : 0}
        valueStyle={{ fontSize: "0.7rem" }}
      />
    </Box>
  );
};

// Waive off charges Modal UI
const WaivedOffModal = ({
  selectedRow,
  handleWaivedOffCharge,
  waivedOffLoader,
  waiveOffReasons,
  selectedReason,
  handleWaiveOffReasonsSelect,
  handleWaiveOffInputReasonChange,
  toggleWaiveOffConfirmationModal,
  handleWaiveOffChargesValidation,
  waiveOffErrors,
}: any) => {
  // sub category options
  const waiveOffReasonsOptions = waiveOffReasons?.map(
    (item: any, index: number) => {
      return {
        name: item?.id,
        displayName: item?.description,
        reasonDetails: item?.details,
      };
    }
  );
  const isRemarkRequired = selectedReason?.reason?.details?.remarkRequired;
  return (
    <>
      <Stack
        padding="10px"
        justifyContent={"space-between"}
        height={"-webkit-fill-available"}
        gap="10px"
      >
        <Stack gap="5vh">
          {/* Summary */}
          <Box
            sx={{
              borderWidth: "1px solid",
              border: colors.text_secondary_gray,
              borderRadius: "15px",
              // padding: "10px",
              // padding: "1px 0px",
              backgroundColor: "#EFF1F0",
            }}
          >
            {/* heading */}
            <Box
              sx={{
                backgroundColor: "#E4E4E4",
                borderRadius: "12px 12px 0px 0px",
                padding: "9px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#636369",
                  lineHeight: "19px",
                  fontSize: "14px",
                }}
              >
                Charges Summary
              </Typography>
            </Box>
            <Box padding={"10px"}>
              {selectedRow &&
                selectedRow?.length > 0 &&
                selectedRow?.map((item: any, index: any) => (
                  <SubscriptionItem
                    dashedBorder={false}
                    title={
                      DisplayChargesNameTypes[
                        item?.chargeTypeDetail
                          ?.type as keyof typeof DisplayChargesNameTypes
                      ]
                    }
                    amount={getFormattedINR(item?.pendingAmount)}
                    fontSize="10px"
                    showBorder={true}
                    description={item?.chargeTypeDetail?.name ?? "N/A"}
                  />
                ))}
            </Box>
          </Box>

          {/* Wave off reasons */}
          <Stack>
            {/* Error block */}
            {(waiveOffErrors?.reason || waiveOffErrors?.inputReason) && (
              <Box>
                <Typography
                  variant="caption"
                  style={{
                    color: "indianred",
                    textAlign: "right",
                    fontStyle: "italic",
                  }}
                >
                  {waiveOffErrors?.reason
                    ? en.errorMessages.WaiveOffReason
                    : waiveOffErrors?.inputReason
                    ? en.errorMessages.WaiveOffReasonDescription
                    : ""}
                </Typography>
              </Box>
            )}

            <CustomSelect
              required
              topHeading={en.bookingDetails.WaiveOffReasonLabel}
              // hidePlaceholder={true}
              placeholder={en.bookingDetails.WaiveOffReasonPlaceholder}
              value={selectedReason?.reason?.displayName}
              choice={
                waiveOffReasonsOptions?.length > 0 ? waiveOffReasonsOptions : []
              }
              defaultKey={"displayName"}
              handleChange={(reason: any) => {
                handleWaiveOffReasonsSelect(reason);
              }}
              autofocus={false}
            />

            {/* Remark */}
            <Stack paddingTop={"5px"}>
              {selectedReason?.reason?.displayName && (
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={2}
                  placeholder={`Add remark`}
                  // value={selectedReason?.inputRemark}
                  value={selectedReason?.inputReason}
                  inputProps={{
                    maxLength: AddChargesInputLength.ADD_CHARGES_REMARK,
                  }}
                  onChange={(event: any) => {
                    handleWaiveOffInputReasonChange(event.target.value);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        {/* Action Button */}
        <Stack justifyContent={"center"} alignItems={"center"}>
          <CustomButton
            variant="contained"
            label="Waive Off"
            minWidth="320px"
            onClick={handleWaiveOffChargesValidation}
            loading={waivedOffLoader}
          />
        </Stack>
      </Stack>
    </>
  );
};

// Pay all charges modal UI
const PaymentSelectedModal = ({
  selectedRows,
  paymentModes,
  handlePaymentModes,
  selectedPayment,
  generateOrder,
  paymentLinkQRLoader,
  paymentLinkQRData,
  calculateTotal,
  handleSettingPartialPay,
  selectedPartialPayCharges,
  allowedPaymentFlows,
  orderDataloader,
}: any) => {
  return (
    <>
      {paymentLinkQRLoader ? (
        <PaymentLinkQRLoader />
      ) : paymentLinkQRData && Object.keys(paymentLinkQRData)?.length ? (
        <PaymentDetailsSideModalUI
          paymentLinkQRData={paymentLinkQRData}
          paymentLinkQRLoader={paymentLinkQRLoader}
          orderDataloader={orderDataloader}
        />
      ) : (
        <Stack
          padding="10px"
          justifyContent={"space-between"}
          height={"-webkit-fill-available"}
        >
          <Stack gap="5vh">
            {/* Summary */}
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr
                  className="PartialPayHeader"
                  style={{ background: "#01353D29" }}
                >
                  <th
                    className={styles.customHtmlTableTdTh}
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    Charge type
                  </th>
                  <th className={styles.customHtmlTableTdTh}>Amount left</th>
                  <th className={styles.customHtmlTableTdTh}>Pay</th>
                </tr>
              </thead>

              <tbody className="PartialPayBody">
                {selectedRows?.length > 0 &&
                  selectedRows?.map((record: any, index: number) => {
                    return (
                      <tr>
                        <td
                          title={record?.chargeTypeDetail?.name}
                          className={styles.customHtmlTableTdTh}
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                          }}
                        >
                          {record?.chargeTypeDetail?.name}
                        </td>
                        <td className={styles.customHtmlTableTdTh}>
                          {getFormattedINR(record?.pendingAmount)}
                        </td>
                        <td className={styles.customHtmlTableTdTh}>
                          {record?.chargeTypeDetail?.partialPay && (
                            <>
                              <TextField
                                id="standard-number"
                                type="text"
                                required
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="standard"
                                disabled={!record?.chargeTypeDetail?.partialPay}
                                value={
                                  Object.hasOwn(
                                    selectedPartialPayCharges,
                                    `${record?.id}`
                                  )
                                    ? selectedPartialPayCharges[record?.id]
                                    : record?.pendingAmount
                                    ? amountConvertToRupee(
                                        record?.pendingAmount
                                      )
                                    : ""
                                }
                                onChange={(event: any) => {
                                  handleSettingPartialPay(
                                    {
                                      [record?.id]: Number(event.target.value),
                                    },
                                    event.target.value,
                                    record
                                  );
                                }}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/* Payment Methods */}
            <Box>
              <Typography
                color={colors.text_secondary_gray}
                fontSize="12px"
                fontWeight="500"
                marginBottom="5px"
              >
                Select Payment Method :
              </Typography>
              <Box
                sx={{
                  borderWidth: "1px solid",
                  border: colors.text_secondary_gray,
                  borderRadius: "12px",
                  padding: "1px 0px",
                  backgroundColor: "#fff",
                }}
                border={colors.text_secondary_gray}
              >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={selectedPayment}
                  onChange={(e: any) => handlePaymentModes(e)}
                >
                  {allowedPaymentFlows.map((method: any, index: any) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          borderBottom:
                            index < allowedPaymentFlows.length
                              ? "1px solid #D4E4E452"
                              : "",
                          padding: "2px 18px",
                        }}
                      >
                        <FormControlLabel
                          value={method}
                          control={<Radio />}
                          label={
                            DisplayPaymentModes[
                              method as keyof typeof DisplayPaymentModes
                            ]
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              color: colors.text_secondary_gray,
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                          }}
                        />
                      </Box>
                    );
                  })}
                </RadioGroup>
              </Box>
            </Box>
          </Stack>

          {/* Action Button */}
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CustomButton
              variant="contained"
              label={
                DisplayPaymentButtonLabel[
                  selectedPayment as keyof typeof DisplayPaymentButtonLabel
                ]
              }
              minWidth="320px"
              onClick={generateOrder}
              // loading={waivedOffLoader}
              disabled={
                paymentLinkQRLoader ||
                orderDataloader ||
                selectedPayment === "DEFAULT"
              }
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

//vehiclle IOT's UI
const VehicleIot: any = ({
  pingDeviceData,
  vehicleId,
  pingDeviceLoader,
  devicePingAccess,
}: any) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Box className={styles.iconWrapper}>
          <img src={vehicleIotsIcon} />
        </Box>
        <DetailsTypography
          heading={`${en.bookingDetails.status}: `}
          value={
            pingDeviceData?.length
              ? toPascalCase(pingDeviceData?.[0].status)
              : en.global.NA
          }
          valueStyle={{
            fontSize: "0.7rem",
            color: pingDeviceData?.length
              ? statusColorCode[
                  pingDeviceData[0].status as keyof typeof statusColorCode
                ]
              : "",
          }}
        />
      </Box>
      <Box sx={{ marginRight: "20px" }}>
        {devicePingAccess && (
          <CustomButton
            label={en.bookingDetails.ping}
            variant="outlined"
            loading={pingDeviceLoader}
            onClick={(e: any) => {
              e.stopPropagation();
              if (vehicleId?.vehicleId) {
                dispatch(
                  getPingData({
                    data: { data: { vehicleId: vehicleId?.vehicleId } },
                  })
                );
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

//vehicle IOT's body
const VehicleIotBody = ({ pingDeviceData, pingDeviceLoader }: any) => {
  return pingDeviceLoader ? (
    <Skeleton sx={{ height: "100px" }} />
  ) : pingDeviceData?.length ? (
    pingDeviceData?.map((item: any, index: any) => {
      return (
        <Box sx={{ borderBottom: `1px solid ${colors.card_border_bottom}` }}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.deviceType}: `}
                  value={toPascalCase(item?.deviceType) ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.imeiNumber}: `}
                  value={item?.imeiNumber ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.odometerReading}: `}
                  value={item?.odometerReading ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.status}: `}
                  value={toPascalCase(item?.status) ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.lat}: `}
                  value={item?.location?.latitude ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.long}: `}
                  value={item?.location?.longitude ?? en.global.NA}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography>Address</Typography>
                <Tooltip
                  title={item?.address?.length > 10 ? item?.address : ""}
                >
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    {item?.address}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <DetailsTypography
                  heading={`${en.bookingDetails.lastActive}: `}
                  value={dateFormat(item?.lastActive, true) ?? en.global.NA}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    })
  ) : (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.deviceType}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.imeiNumber}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.odometerReading}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.status}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.lat}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.long}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.address}: `}
            value={en.global.NA}
          />
        </Grid>
        <Grid item xs={2}>
          <DetailsTypography
            heading={`${en.bookingDetails.lastActive}: `}
            value={en.global.NA}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

//Extended Rides UI
const ExtendedRides: any = ({ vehicleExtensions }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Box className={styles.iconWrapper}>
        <img src={BikeIcon} />
      </Box>
      <DetailsTypography
        heading={`${en.bookingDetails.totalExtension}: `}
        value={vehicleExtensions?.length ?? 0}
      />
      <DetailsTypography
        heading={`${en.bookingDetails.currentStatus}: `}
        value={toPascalCase(vehicleExtensions[0]?.status) ?? en.global.NA}
      />
    </Box>
  );
};

//Extended Rides Body
const ExtendedRidesBody: any = ({
  vehicleExtensions,
  setDialogData,
  getExtensionsLoader,
  extensionListAccess,
}: any) => {
  const getRowId = (row: any) => {
    return row?.extensionId;
  };
  const columns: any = [
    {
      field: "Extension",
      headerName: "Extension",
      headerAlign: "center",
      align: "center",
      flex: 1.1,
      renderCell: (data: any) => {
        return data?.row?.Index;
      },
    },
    {
      field: "package",
      headerName: "Package",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <Tooltip
            title={
              data?.row?.duration
                ? `${data?.row?.duration} Days (${toPascalCase(
                    data?.row?.package
                  )})`
                : en.global.NA
            }
          >
            {data?.row?.duration ? (
              <span>
                {data?.row?.duration} {data?.row?.duration > 1 ? "Days" : "Day"}{" "}
                ({toPascalCase(data?.row?.package)})
              </span>
            ) : (
              <>{en.global.NA}</>
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "extensionDate",
      headerName: "Extend Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (date: any) => dateFormat(date),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (date: any) => dateFormat(date),
    },
    {
      field: "endDate",
      headerName: "End Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (date: any) => dateFormat(date),
    },
    {
      field: "addOns",
      headerName: "Add Ons",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data: any) => {
        const HelmetCount: any = data?.formattedValue?.map((item: any) => {
          return `${item?.count} ${HELMET_DISPLAY_NAME[item?.subType]} ${
            item?.isComplementary ? `(${en.global.complementary})` : ""
          }`;
        });
        return (
          <Tooltip
            title={data?.formattedValue?.length && HelmetCount.join(", ")}
          >
            {data?.formattedValue?.length
              ? HelmetCount?.join(", ")
              : en.global.NA}
          </Tooltip>
        );
      },
    },
    {
      field: "paymentDetails",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (data: any) =>
        Object.keys(data)?.length ? getFormattedINR(data?.amount) : 0,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <span
            style={{
              color: `${
                statusColorCode[
                  data?.row?.status as keyof typeof statusColorCode
                ]
              }`,
            }}
          >
            {data?.row?.status ? toPascalCase(data?.row?.status) : en.global.NA}
          </span>
        );
      },
    },
    {
      field: "mode",
      headerName: "Payment Mode",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data: any) => {
        return (
          <Tooltip
            title={
              data?.row?.paymentDetails?.details?.paymentFlow?.length > 8
                ? DisplayPaymentModes[
                    data?.row?.paymentDetails?.details
                      ?.paymentFlow as keyof typeof DisplayPaymentModes
                  ]
                : en.global.NA
            }
          >
            {data?.row?.paymentDetails?.details?.paymentFlow ? (
              <span>
                {
                  DisplayPaymentModes[
                    data?.row?.paymentDetails?.details
                      ?.paymentFlow as keyof typeof DisplayPaymentModes
                  ]
                }
              </span>
            ) : (
              <span> {en.global.NA}</span>
            )}
          </Tooltip>
        );
      },
    },
    {
      type: "actions",
      field: "action",
      headerName: en.global.action,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      getActions: (params: any) => {
        const status: string = params.row.status;
        return [
          <CustomIconButton
            onClick={() =>
              setDialogData((prev: ExtendRideDialog) => ({
                ...prev,
                openDialog: true,
                key: params.row?.extensionId,
              }))
            }
            children={
              <DeleteIcon
                color={
                  status === BookingExtensionStatus.PENDING
                    ? "error"
                    : "disabled"
                }
              />
            }
            disabled={status !== BookingExtensionStatus.PENDING}
          />,
        ];
      },
      flex: 1,
    },
  ];
  return (
    <Box sx={{ padding: "10px" }}>
      <DataTable
        rows={vehicleExtensions}
        columns={columns}
        headerAlign={"center"}
        getRowId={(row: any) => row.extensionId}
        hideFooterPagination={true}
        paginationModel={{ page: 0, pageSize: 100 }}
        loading={getExtensionsLoader}
        hasAccess={extensionListAccess}
      />
    </Box>
  );
};

//ui of arrordion tab in case of pending and upcoming booking
const AccordionTabUi = ({ key, status }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Typography
        variant={typographyConstants.HEADING}
        sx={{
          marginLeft: "5%",
          fontWeight: 500,
          color: colors.text_quatenary_black,
        }}
      >
        {BookingStatusEnum.CANCELLED === status
          ? en.errorMessages.rideHasCancelled
          : en.errorMessages.rideNotStartyet}
      </Typography>{" "}
    </Box>
  );
};

const VehicleDetails = ({
  bookingId,
  bookingStatus,
  additionalChargesList,
  waivedOffLoader,
  paymentModes,
  paymentLinkQRData,
  paymentLinkQRLoader,
  waiveOffReasons,
  allowedPaymentFlows,
  orderDataloader,
  handleChargesFilterChange,
  chargesFilters,
  bookingVehicleDetails,
  vehicleExtensions,
  expandAccordian,
  accordianClickHandler,
  getExtensionsLoader,
  unassignVehicleLoader,
  navigateToVehicleDetails,
}: any) => {
  const RbacHasAccess = {
    extensionListAccess: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.EXTENSION_HISTORY
    ),
    devicePingAccess: RbacHelper.isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.DEVICE_PING
    ),
    viewChargesListAccess: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.EXTRA_CHARGES_LIST
    ),
    waiveOffAccess: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.WAIVE_OFF_CHARGES
    ),
    payExtraChargesAccess: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.PAY_EXTRA_CHARGES
    ),
  };
  const dispatch = useDispatch();
  const { odometerHistory, additionalChargesLoader } = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const { pingDeviceData, pingDeviceLoader } = useSelector(
    (state: RootState) => state.rentalsIotManagementReducer
  );

  /*  const chargesFiltersInitial = {
    paginationModel: {
      page: additionalChargesList?.pageData?.page ?? 0,
      pageSize: additionalChargesList?.pageData?.pageSize ?? 10,
    },
    category: { name: "", displayName: "All" },
  }; */
  const selectedReasonInitial = {
    reason: {
      displayName: "",
      name: "",
      reasonDetails: {
        remarkRequired: false,
        isBusinessReason: false,
      },
    },
    inputReason: "",
  };

  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<object[]>([]);
  const [selectedPartialPayCharges, setSelectedPartialPayCharges] =
    useState<any>({});
  const [openSideDrawerPS, setOpenSideDrawerPS] = useState<boolean>(false);
  const [openSideDrawerWF, setOpenSideDrawerWF] = useState<boolean>(false);
  const [sideDrawerData, setSideDrawerData] = useState<object[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<string>("DEFAULT");
  const [calculateTotal, setCalculateTotal] = useState<number>(0);
  const [calculatedRemainingAmount, setCalculatedRemainingAmount] =
    useState<number>(0);

  const [isAssignModalOpen, setAssignModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState(selectedReasonInitial);
  const [showWaiveOffConfirmationModal, setShowWaiveOffConfirmationModal] =
    useState(false);
  const [waiveOffErrors, setWaiveOffErrors] = useState({
    reason: false,
    inputReason: false,
  });

  // state to handle the responsive dialog
  const [dialogData, setDialogData] = useState<ExtendRideDialog>({
    openDialog: false,
    key: "",
  });

  const chargesDataArr = additionalChargesList?.charges ?? [];

  useEffect(() => {
    if (bookingVehicleDetails?.vehicleId) {
      RbacHasAccess.devicePingAccess &&
        dispatch(
          getPingData({
            data: {
              data: { vehicleId: bookingVehicleDetails?.vehicleId },
            },
            showToastr: true,
          })
        );
    }

    // get additional charges (challans & Extras)
    RbacHasAccess.viewChargesListAccess &&
      dispatch(
        getAdditionalCharges({
          bookingId: bookingId,
          queryParamList: { page: 1, pageSize: 100 },
        })
      );

    //getExtension
    RbacHasAccess.extensionListAccess &&
      dispatch(getVehicleExtension({ bookingId: bookingId }));
  }, []);

  const handleModalClosePS = () => {
    setOpenSideDrawerPS((prev: boolean) => {
      if (prev) {
        setSelectedPartialPayCharges({});
        setSelectedPayment("DEFAULT");
        dispatch(
          getAdditionalCharges({
            bookingId: bookingId,
            queryParamList: { page: 1, pageSize: 100 },
          })
        );
      }
      return false;
    });
    dispatch(cleanUpOrderAndPaymentData());
  };
  const handleModalCloseWF = () => {
    setOpenSideDrawerWF((prev: any) => {
      if (prev) {
        dispatch(
          getAdditionalCharges({
            bookingId: bookingId,
            queryParamList: { page: 1, pageSize: 100 },
          })
        );
      }
      return !prev;
    });
    // dispatch(cleanUpOrderAndPaymentData());
  };

  // waive off submit action handler
  const handleWaivedOffCharge = () => {
    toggleWaiveOffConfirmationModal();
    const isRemarkRequired =
      selectedReason?.reason?.reasonDetails?.remarkRequired;
    if (selectedRows?.length > 0) {
      let ids: string[] = selectedRows?.map((row: any) => row?.id);
      let waiveOffPayload: any = {
        chargeIds: ids,
        reasonId: selectedReason?.reason?.name,
      };
      if (selectedReason?.inputReason) {
        waiveOffPayload["remarks"] = selectedReason?.inputReason;
      }

      const payload = {
        bookingId,
        fetchChargesParam: {
          bookingId: bookingId,
          queryParamList: { page: 1, pageSize: 100 },
        },
        apiPayload: {
          data: waiveOffPayload,
        },
      };
      dispatch(waiveOffChargesRevamp(payload));
      handleModalCloseWF();
    }
  };

  const handlePaymentModes = (e: any) => setSelectedPayment(e.target?.value);

  const sucessCallbackPayAllCharges = () => {
    dispatch(setPaymentSuccess());
  };

  // Pay selected - Generate Order & Initiate Payment
  const generateOrder = () => {
    let additionalChargeIds = selectedRows?.map((row: any) => {
      return {
        id: row?.id,
        amount: Object.hasOwn(selectedPartialPayCharges, row?.id)
          ? amountConvertToPaise(Number(selectedPartialPayCharges[row?.id]))
          : row?.pendingAmount,
      };
    });
    let orderPayload = {
      bookingId: bookingId,
      selectedPaymentMode: selectedPayment,
      additionalChargesIds: additionalChargeIds,
      sucessCallback: sucessCallbackPayAllCharges,
    };
    dispatch(createOrderForBulkPaymentRevamp(orderPayload));
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // handle waiveOff / pay selected modal open
  const handleChargesModelOpen = (purpose: string) => {
    if (purpose === "waivedOff") {
      let isWaivable = selectedRows.some(
        (row: any, index: number) => row?.chargeTypeDetail?.waiveOff === false
      );
      if (!isWaivable) {
        setSelectedReason((prev: any) => ({
          ...selectedReasonInitial,
        }));
        setWaiveOffErrors({ reason: false, inputReason: false });
        setOpenSideDrawerWF(true);
      }
    } else if (purpose === "paySelected") {
      setOpenSideDrawerPS(true);
    }
  };

  // handle waive of reason change
  const handleWaiveOffReasonsSelect = (reason: any) => {
    setSelectedReason((prev: any) => ({
      ...prev,
      reason: { ...reason, reasonDetails: { ...reason.reasonDetails } },
    }));
  };

  // handle waive off reason textfield change
  const handleWaiveOffInputReasonChange = (inputReason: string) => {
    setSelectedReason((prev: any) => {
      let prevLength = prev.inputReason?.length;
      let inputReasonLength = inputReason.length;
      let resultantObj = { ...prev };
      if (
        prevLength &&
        /\s/.test(inputReason[inputReasonLength - 1]) &&
        /\s/.test(inputReason[inputReasonLength - 2])
      ) {
        resultantObj.inputReason = prev.inputReason;
      } else {
        resultantObj.inputReason =
          inputReasonLength === 1 && /\s/.test(resultantObj)
            ? inputReason.trim()
            : inputReason;
      }
      return {
        ...resultantObj,
      };
    });
  };

  // waive off validation
  const handleWaiveOffChargesValidation = () => {
    const isRemarkRequired =
      selectedReason?.reason?.reasonDetails?.remarkRequired;

    if (!selectedReason.reason.name || selectedReason.reason.name === "") {
      setWaiveOffErrors({
        reason: true,
        inputReason: false,
      });
      return false;
    }
    if (
      isRemarkRequired &&
      (!selectedReason?.inputReason || selectedReason?.inputReason === "")
    ) {
      setWaiveOffErrors({
        reason: false,
        inputReason: true,
      });
      return false;
    }
    toggleWaiveOffConfirmationModal();
  };

  // confirmation modal toggle - waive off
  const toggleWaiveOffConfirmationModal = () => {
    setShowWaiveOffConfirmationModal((prev: any) => !prev);
  };

  // handle partial pay
  const handleSettingPartialPay = (
    chargeObj: any,
    partialAmount: any,
    record: any
  ) => {
    // Rule - partial amount should be Natural number, not greater than charge amount.
    partialAmount = partialAmount.toString().replace(/^0+/, "");
    if (
      Number(partialAmount) >= 0 &&
      Number(partialAmount) <=
        Number(amountConvertToRupee(record?.pendingAmount))
    ) {
      if (Number(partialAmount) === 0) {
        setSelectedPartialPayCharges((prev: any) => {
          let updatedAddMoreState = prev;
          if (Object.hasOwn(prev, record?.id)) {
            return {
              ...prev,
              [record?.id]: partialAmount,
            };
          }
          return updatedAddMoreState;
        });
      } else {
        setSelectedPartialPayCharges((prev: any) => {
          return { ...prev, ...chargeObj };
        });
      }
    }
  };

  // delete extension success callback
  const successCallback = () => {
    setDialogData((prev: ExtendRideDialog) => ({
      ...prev,
      key: "",
      openDialog: false,
    }));
    //getExtension
    dispatch(getVehicleExtension({ bookingId: bookingId }));

    // get booking details
    dispatch(getBookingDetails({ bookingId }));
  };

  //handle yes of delete extension
  const handleConfirmation = () => {
    if (dialogData.key) {
      dispatch(
        deleteBookingExtension({
          bookingId,
          extensionId: dialogData.key,
          successCallback,
        })
      );
    }
  };

  return (
    <>
      {/* --------------------------------- Content start ---------------------------------------------- */}

      <VdAccordion title="My Vehicle">
        <MyVehicle
          key={"heading"}
          toggleDrawer={toggleDrawer}
          open={open}
          bookingData={{ bookingId, bookingStatus }}
          toggleAssignModal={(val: boolean) => setAssignModal(val)}
          isAssignModalopen={isAssignModalOpen}
          vehicleData={bookingVehicleDetails}
          unassignVehicleLoader={unassignVehicleLoader}
        />
        <MyVehicleBody
          key={"body"}
          vehicleData={bookingVehicleDetails}
          navigateToVehicleDetails={navigateToVehicleDetails}
        />
      </VdAccordion>

      {/* ------------- Additional charges (challans & Extras) ---------------------- */}
      <VdAccordion
        title="Challans & Extras"
        bodyBackground={"#F6F6F6"}
        disabled={
          bookingStatus === BookingStatusEnum.PENDING ||
          bookingStatus === BookingStatusEnum.UPCOMING ||
          bookingStatus === BookingStatusEnum.CANCELLED
        }
      >
        {bookingStatus === BookingStatusEnum.PENDING ||
        bookingStatus === BookingStatusEnum.UPCOMING ||
        bookingStatus === BookingStatusEnum.CANCELLED ? (
          <AccordionTabUi key={"heading"} status={bookingStatus} />
        ) : (
          <ExtraCharges
            key={"heading"}
            additionalChargesList={additionalChargesList}
          />
        )}

        <ExtraChargesBody
          key={"body"}
          additionalChargesList={additionalChargesList}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setOpenSideDrawerPS={setOpenSideDrawerPS}
          setOpenSideDrawerWF={setOpenSideDrawerWF}
          calculateTotal={calculateTotal}
          setCalculateTotal={setCalculateTotal}
          calculatedRemainingAmount={calculatedRemainingAmount}
          setCalculatedRemainingAmount={setCalculatedRemainingAmount}
          chargesFilters={chargesFilters}
          handleChargesFilterChange={handleChargesFilterChange}
          handleModelOpen={handleChargesModelOpen}
          bookingStatus={bookingStatus}
          viewChargesListAccess={RbacHasAccess.viewChargesListAccess}
          waiveOffAccess={RbacHasAccess.waiveOffAccess}
          payExtraChargesAccess={RbacHasAccess.payExtraChargesAccess}
          additionalChargesLoader={additionalChargesLoader}
        />
      </VdAccordion>

      {/* ------------- Vehicle IOT ---------------------- */}
      <VdAccordion
        title="Vehicle IOT's"
        disabled={
          bookingStatus === BookingStatusEnum.PENDING ||
          bookingStatus === BookingStatusEnum.UPCOMING ||
          (bookingStatus === BookingStatusEnum.CANCELLED &&
            !bookingVehicleDetails?.vehicleId)
        }
      >
        {bookingStatus === BookingStatusEnum.PENDING ||
        bookingStatus === BookingStatusEnum.UPCOMING ||
        (bookingStatus === BookingStatusEnum.CANCELLED &&
          !bookingVehicleDetails?.vehicleId) ? (
          <AccordionTabUi key={"heading"} status={bookingStatus} />
        ) : (
          <VehicleIot
            key={"heading"}
            vehicleId={bookingVehicleDetails}
            pingDeviceData={pingDeviceData}
            pingDeviceLoader={pingDeviceLoader}
            devicePingAccess={RbacHasAccess.devicePingAccess}
          />
        )}
        <VehicleIotBody
          key={"body"}
          pingDeviceData={pingDeviceData}
          pingDeviceLoader={pingDeviceLoader}
        />
      </VdAccordion>

      {/* ------------- Extended Rides ---------------------- */}
      <VdAccordion
        title="Extended Rides"
        disabled={
          bookingStatus === BookingStatusEnum.PENDING ||
          bookingStatus === BookingStatusEnum.UPCOMING ||
          bookingStatus === BookingStatusEnum.CANCELLED
        }
      >
        {bookingStatus === BookingStatusEnum.PENDING ||
        bookingStatus === BookingStatusEnum.UPCOMING ||
        bookingStatus === BookingStatusEnum.CANCELLED ? (
          <AccordionTabUi key={"heading"} status={bookingStatus} />
        ) : (
          <ExtendedRides
            key={"heading"}
            vehicleExtensions={vehicleExtensions}
          />
        )}
        <ExtendedRidesBody
          key={"body"}
          vehicleExtensions={vehicleExtensions}
          setDialogData={setDialogData}
          getExtensionsLoader={getExtensionsLoader}
          extensionListAccess={RbacHasAccess.extensionListAccess}
        />
      </VdAccordion>

      {/* --------------------------------- Content end---------------------------------------------- */}

      <SideDrawer
        open={open}
        heading={"Odometer History"}
        //setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        // headerPadding={"1vw 1.5vw"}
        renderUI={
          <OdometerHistory id={bookingId} odometerHistory={odometerHistory} />
        }
      />
      {/* ------------ waive off charges modal ---------------- */}
      <SideDrawer
        open={openSideDrawerWF}
        toggleDrawer={handleModalCloseWF}
        heading={"Waived Off Charges"}
        disablePadding={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        renderUI={
          <WaivedOffModal
            selectedRow={selectedRows}
            handleWaivedOffCharge={handleWaivedOffCharge}
            waivedOffLoader={waivedOffLoader}
            waiveOffReasons={waiveOffReasons}
            selectedReason={selectedReason}
            handleWaiveOffReasonsSelect={handleWaiveOffReasonsSelect}
            handleWaiveOffInputReasonChange={handleWaiveOffInputReasonChange}
            toggleWaiveOffConfirmationModal={toggleWaiveOffConfirmationModal}
            handleWaiveOffChargesValidation={handleWaiveOffChargesValidation}
            waiveOffErrors={waiveOffErrors}
          />
        }
      />
      {/* ------------ Pay all charges modal ------------------ */}
      <SideDrawer
        open={openSideDrawerPS}
        toggleDrawer={handleModalClosePS}
        heading={"Additional Charges Payment"}
        disablePadding={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        renderUI={
          <PaymentSelectedModal
            selectedRows={selectedRows}
            paymentModes={paymentModes}
            handlePaymentModes={handlePaymentModes}
            selectedPayment={selectedPayment}
            generateOrder={generateOrder}
            paymentLinkQRLoader={paymentLinkQRLoader}
            paymentLinkQRData={paymentLinkQRData}
            calculateTotal={calculateTotal}
            handleSettingPartialPay={handleSettingPartialPay}
            selectedPartialPayCharges={selectedPartialPayCharges}
            allowedPaymentFlows={allowedPaymentFlows}
            orderDataloader={orderDataloader}
          />
        }
      />
      {/* Waive Off confirmation modal */}
      <ResponsiveDialog
        title={en.alertMessages.waiveOffConfirmation}
        open={showWaiveOffConfirmationModal}
        handleClose={() => toggleWaiveOffConfirmationModal()}
        handleYes={() => handleWaivedOffCharge()}
      />

      {/* Confirnmation Dialog For Deleteing The extension  */}
      <ResponsiveDialog
        title={en.bookingDetails.deleteExtension}
        body={en.alertMessages.deleteExtensionConfirmation}
        open={dialogData?.openDialog}
        handleClose={() => setDialogData({ openDialog: false, key: "" })}
        handleYes={handleConfirmation}
      />
      {/* assign vehicle  */}
      <StartRideModal
        isOpen={isAssignModalOpen}
        bookingDetails={{ bookingId }}
        handleClose={(val: boolean) => setAssignModal(!isAssignModalOpen)}
        isAssignVehicle={true}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      />
    </>
  );
};
export default VehicleDetails;
