import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import BookingData from "../../services/calculateChargesX";
import { toastr } from "react-redux-toastr";

function* fetchBookingCharges(action: any): any {
  try {
    /* const dummyData = {
      data: {
        planId: "16270F5KWFSW",
        helmets: {
          premium: 1,
          fullFace: 3,
          halfFace: 1,
          kids: 0,
        },
      },
    }; */
    const data = yield call(BookingData.calculateChargesX, action.payload);
    // const data = yield call(BookingData.calculateChargesX, dummyData);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_SUCCESS,
        payload: {
          bookingCharges: data?.data || {},
        },
      });
    } else {
      toastr.error("", "Failed to get Booking Charges");
      yield put({
        type: actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_FAILED,
      });
    }
  } catch (ex) {
    console.error(ex);
  }
}

function* calculateChargesX() {
  yield takeLatest(
    actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_REQUESTED,
    fetchBookingCharges
  );
}
export default calculateChargesX;
