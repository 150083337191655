import { FileDownloadOutlined, RotateRightSharp } from "@mui/icons-material";
import { Box, Chip, Grid, Stack, Tooltip } from "@mui/material";
import {
  GridEditBooleanCellProps,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { storeFilterDataInLocalStorage } from "../../../../config/filterStorage";
import {
  bookingFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
import { LocalStorage } from "../../../../constants/enum";
import { BGImg } from "../../../../freedo-x-src/constantsX/exportImagesX";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomMultiSelect from "../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import Input from "../../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import SideDrawer from "../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { DataTable } from "../../../../sharedComponents/templates/Tables/dataTable";
import ShortInfoCard from "../../../../sharedComponents/templates/TopCards/ShortInfoCard";
import { isArrayNotEmpty } from "../../../../utils/helper";
import RbacHelper from "../../../../utils/helperRBAC";
import {
  BookingStatusColorCodes,
  BookingStatusDisplay,
  BookingStatusEnum,
  GenericObject,
  bookingQuickFilters,
  dateWithTimeFormat,
  downloadRowCount,
  filterStorageKeys,
} from "../../../constants/constants";
import {
  BikeIconFaded,
  CityInputIcon,
  SearchIcon,
  StatusIcon,
  WarehouseIcon,
  cancelledRide,
  completedRide,
  fadedCalenderIcon,
  ongoingRide,
  upcomingRide,
} from "../../../constants/exportImages";
import rentalEn from "../../../locale/rental-en.json";
import {
  bookingListUsingPhoneNumber,
  getBookingList,
  getBookingStatistics,
} from "../../../redux/actions/bookingManagementActions";
import {
  clearReducerDataRentalCommon,
  getAllBranchesByCity,
  getModelListByBranch,
  setBookingReducerData,
} from "../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../utils/RoutesConstants";
import {
  combineDateAndTimeSlot,
  dateFormat,
  downloadExcelSheet,
  formatTime,
  fromNowDateAndTime,
  getDateTimeFromTimeStamp,
  getDeliveryPickup,
  getLocalData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  toPascalCase,
} from "../../../utils/helper";
import { checkAlfaNumeric, checkPhone } from "../../../utils/regex";
import AdvanceFilter from "./sections/advanceFilter";
import { Fade } from "react-awesome-reveal";
import { colors } from "../../../../themes/colors";
import en from "../../../locale/rental-en.json";

//interface for booking list filters
interface InitialState {
  // paginationModel: { page: number; pageSize: number };
  pageSize: number;
  page: number;
  cities: GenericObject[];
  branches: GenericObject[];
  model: GenericObject[];
  status: any;
  bookingDateFrom: any;
  bookingDateTill: any;
  search: string;
  userPhoneNumber: string;
  advanceFilter: {
    checkBoxCheck: {
      rideDatesStart: boolean;
      rideDatesEnd: boolean;
    };
    rideDatesEnd: { startDate: string; endDate: string };
    rideDatesStart: { startDate: string; endDate: string };
  };
  quickFilter: string;
}

//initail state for booking list filters
const initialState: InitialState = {
  pageSize: 10,
  page: 0,
  cities: [],
  branches: [],
  model: [],
  status: rentalEn?.global?.allStatus,
  bookingDateFrom: "",
  bookingDateTill: "",
  search: "",
  userPhoneNumber: "",
  advanceFilter: {
    checkBoxCheck: {
      rideDatesStart: false,
      rideDatesEnd: false,
    },
    rideDatesStart: {
      startDate: "",
      endDate: "",
    },
    rideDatesEnd: {
      startDate: "",
      endDate: "",
    },
  },
  quickFilter: "",
};

const Rides = [
  {
    status: "BOOKED",
    displayName: "New Bookings Today",
    icon: upcomingRide,
    key: "daysNewBookingStats",
    filterKey: bookingQuickFilters.NEW_BOOKINGS_TODAY,
  },
  {
    status: "ACTIVE",
    displayName: "Start Rides Today",
    icon: ongoingRide,
    key: "daysStartRideStats",
    filterKey: bookingQuickFilters.START_RIDES_TODAY,
  },
  {
    status: "CLOSED",
    displayName: "End Rides Today",
    icon: completedRide,
    key: "daysEndRideStats",
    filterKey: bookingQuickFilters.END_RIDES_TODAY,
  },
  {
    status: "CANCELLED",
    displayName: "Cancelled Bookings Today",
    icon: cancelledRide,
    key: "daysCancelledBookingStats",
    filterKey: bookingQuickFilters.CANCELED_RIDES_TODAY,
  },
];

const deliveryType: GenericObject = {
  onPickup: {
    BRANCH: "Hub Pickup",
    CUSTOMER_LOCATION: "Home Delivery",
  },
  onDelivery: {
    BRANCH: "Hub dropoff",
    CUSTOMER_LOCATION: "Home Pickup",
  },
};

const BookingManagement = (props: any) => {
  const RbacHasAccess = {
    listBooking: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.LIST_BOOKINGS
    ),
    viewBooking: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.BOOKING_DETAILS_TAB
    ),
    downloadBookingList: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.DOWNLOAD_BOOKING_LIST
    ),
    bookingStats: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.VIEW_BOOKING_STATS
    ),
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [flipped, setFlipped] = useState<boolean>(false);
  const [advanceFilterFields, setAdvanceFilterFields] = useState<GenericObject>(
    { ...initialState }
  );

  //adv filter modal toggle
  const [isAdvanceFilter, setIsAdvanceFilter] = useState<boolean>(false);

  // to handle reset filter (quick filters)
  const [dialogData, setDialogData] = useState<GenericObject>({
    open: false,
    quickFilter: "",
  });

  const {
    modelListByBranch = [], // this has the vehicle model list based on branch
    allCitiesDetails = [],
    authoriseBranches = [],
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const { bookingListData, bookingStats, onLoad } = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );

  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  let FilterdBookingsData: any = bookingListData?.bookings?.length
    ? bookingListData?.bookings?.map((eachBooking: any, index: number) => {
        if (
          (eachBooking.status === BookingStatusEnum.UPCOMING &&
            moment(eachBooking.startDate).format("DD/MM/YYYY") ===
              new Date().toLocaleDateString()) ||
          (eachBooking.status === BookingStatusEnum.ONGOING &&
            moment(eachBooking.endDate).format("DD/MM/YYYY") ===
              new Date().toLocaleDateString())
        ) {
          return { ...eachBooking, localState: flipped };
        } else {
          return { ...eachBooking };
        }
      })
    : bookingListData?.bookings;

  //booking list data list
  const bookingColumns = [
    {
      field: "customerName",
      headerName: rentalEn.tableHeaders.customerName,
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={`${params.row.customer.firstName} ${params.row.customer.lastName}`}
          >
            <span>{`${params.row.customer.firstName} ${params.row.customer.lastName}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "bookingNumber",
      headerName: rentalEn.tableHeaders.bookingNumber,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "modelDisplayName",
      headerName: rentalEn.tableHeaders.vehicleModel,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridEditBooleanCellProps): any => {
        return (
          <span>
            {params?.row?.modelDisplayName
              ? toPascalCase(params?.row?.modelDisplayName)
              : rentalEn.global.NA}
          </span>
        );
      },
    },
    {
      field: "cityDisplayName",
      headerName: rentalEn.tableHeaders.city,
      headerAlign: "center",
      align: "center",
      flex: 0.8,
    },
    {
      field: "branchDisplayName",
      headerName: rentalEn.tableHeaders.hub,
      headerAlign: "center",
      align: "center",
      flex: 1.2,
    },
    {
      field: "startDate",
      headerName: rentalEn.tableHeaders.startDate,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={`${moment(
              combineDateAndTimeSlot(
                params?.row?.startDate,
                params?.row?.deliveryDetails?.slot?.start
              )
            ).format(dateWithTimeFormat)} - ${formatTime(
              params?.row?.deliveryDetails?.slot?.end
            )}`}
          >
            <span>
              {fromNowDateAndTime(
                combineDateAndTimeSlot(
                  params?.row?.startDate,
                  params?.row?.deliveryDetails?.slot?.end
                )
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "endDate",
      headerName: rentalEn.tableHeaders.endDate,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip
            title={`${moment(
              combineDateAndTimeSlot(
                params?.row?.endDate,
                params?.row?.returnDetails?.slot?.start
              )
            ).format(dateWithTimeFormat)} - ${formatTime(
              params?.row?.returnDetails?.slot?.end
            )}`}
          >
            <span>{dateFormat(params?.row?.endDate)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "package",
      headerName: rentalEn.tableHeaders.package,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <span>
            {params?.row?.mainBookingPackageDuration
              ? params?.row?.mainBookingPackageDuration === 1
                ? `${params?.row?.mainBookingPackageDuration} Day`
                : `${params?.row?.mainBookingPackageDuration} Days`
              : rentalEn.global.NA}
          </span>
        );
      },
    },
    {
      field: "deliveryDetails",
      headerName: rentalEn.tableHeaders.deliveryPickup,
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        const { row } = params;
        const userDeliveryType = params.row.deliveryDetails.type;
        if (
          row.status === BookingStatusEnum.PENDING ||
          row.status === BookingStatusEnum.UPCOMING ||
          row.status === BookingStatusEnum.ONGOING
        ) {
          return deliveryType?.onPickup[userDeliveryType] || "";
        } else {
          return deliveryType.onDelivery[userDeliveryType] || "";
        }
      },
    },

    {
      field: "status",
      headerName: rentalEn.tableHeaders.status,
      headerAlign: "center",
      flex: 1.2,
      align: "center",
      type: "actions",
      renderCell: (params: GridEditBooleanCellProps) => {
        return <StatusCell data={params} />;
      },
    },
  ];

  const StatusCell = (params: any) => {
    return (params?.data?.row?.status === BookingStatusEnum.UPCOMING &&
      moment(params?.data?.row?.startDate).format("DD/MM/YYYY") ==
        new Date().toLocaleDateString()) ||
      (params?.data?.row?.status === BookingStatusEnum.ONGOING &&
        moment(params?.data?.row?.endDate).format("DD/MM/YYYY") ==
          new Date().toLocaleDateString()) ? (
      <Box>
        {params?.data?.row?.localState ? (
          <Chip
            className="front"
            label={
              <Fade direction="down" triggerOnce={false}>
                {
                  BookingStatusDisplay[
                    params?.data?.row
                      ?.status as keyof typeof BookingStatusDisplay
                  ]
                }
              </Fade>
            }
            sx={{
              backgroundColor: "unset",
              color:
                BookingStatusColorCodes[
                  params?.data?.row
                    ?.status as keyof typeof BookingStatusColorCodes
                ],
              height: "23px",
              width: "100px",
              padding: "8px, 12px, 8px, 12px",
            }}
          />
        ) : (
          <Chip
            className="back"
            label={
              <Fade direction="up" triggerOnce={false}>
                {params?.data?.row?.status === BookingStatusEnum.UPCOMING
                  ? en.bookingManagement.startsToday
                  : en.bookingManagement.endsToday}
              </Fade>
            }
            sx={{
              backgroundColor: "unset",
              color:
                params?.data?.row?.status === BookingStatusEnum.UPCOMING
                  ? colors.sherpa_blue
                  : colors.primary_danger,
              height: "23px",
              width: "100px",
              padding: "8px, 12px, 8px, 12px",
            }}
          />
        )}
      </Box>
    ) : (
      <Chip
        label={
          BookingStatusDisplay[
            params?.data?.row?.status as keyof typeof BookingStatusDisplay
          ]
        }
        sx={{
          backgroundColor: "unset",
          color:
            BookingStatusColorCodes[
              params?.data?.row?.status as keyof typeof BookingStatusColorCodes
            ],
          height: "23px",
          width: "100px",
          padding: "8px, 12px, 8px, 12px",
        }}
      />
    );
  };
  // Callback handler for downloading the booking list data on the booking management dashboard
  const downloadBookingListHandler = (vehicleList1000: GenericObject[]) => {
    // modify data from api for download excel
    const modifiedArray: GenericObject[] = vehicleList1000?.map(
      (booking: GenericObject) => {
        let modifiedObject: GenericObject = {
          customerName: `${booking?.customer?.firstName} ${booking?.customer?.lastName}`,
          package:
            booking.mainBookingPackageDuration === 1
              ? `${booking.mainBookingPackageDuration} Day`
              : `${booking.mainBookingPackageDuration} Days`,
          endDate: getDateTimeFromTimeStamp(booking.endDate, "date"),
          bookingNumber: booking?.bookingNumber,
          modelDisplayName: booking?.modelDisplayName,
          cityDisplayName: booking?.cityDisplayName,
          branchDisplayName: booking?.branchDisplayName,
          startDate: getDateTimeFromTimeStamp(booking?.startDate, "date"),
          deliveryDetails: getDeliveryPickup(booking),
          status: toPascalCase(booking?.status),
        };

        return modifiedObject;
      }
    );
    const excelColumn = bookingColumns.reduce((accum: any, column) => {
      accum.push({ header: column.headerName, key: column.field, width: 15 });
      return accum;
    }, []);

    // function to download excel sheet
    downloadExcelSheet(
      excelColumn,
      modifiedArray,
      "bookings",
      "BookingListReport"
    );
  };

  //get row id for table
  const getRowId = (row: any) => {
    return row?.id;
  };

  // Function to handle downloading vehicle report
  const handleDownload = () => {
    handleBookingListApi(
      {
        ...fields,
      },
      true
    );
  };
  //get all models on basis of authorized model
  const getAllModels = () => {
    dispatch(
      getModelListByBranch(
        {
          branches:
            userData?.authorizedBranches?.map((item: any) => {
              return item.name;
            }) ?? [],
        },
        true
      )
    );
  };

  //booking list api call
  const handleBookingListApi = (data: any, isDownload: boolean = false) => {
    // Store filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.BOOKING_LIST_FILTER_DATA]: {
        ...initialState,
        ...data,
      },
    });

    const isAllCitiesSelected =
      data?.cities?.length === allCitiesDetails?.length;
    const isAllModelsSelected =
      data?.model?.length === modelListByBranch?.length;
    const isAllBranchesSelected =
      data?.branches?.length === authoriseBranches?.length;

    let payload: any = {
      status: data.status !== initialState.status ? data.status : "",
      bookingDateFrom: getTimestampAtStartOfDay(data?.bookingDateFrom),
      bookingDateTill: getTimestampAtEndOfDay(data?.bookingDateTill),
      page: data.page + 1,
      pageSize: data.pageSize,
      search: data?.search, //booking number
      quickFilters: dialogData?.quickFilter
        ? dialogData.quickFilter
        : undefined,
    };

    if (!isAllCitiesSelected && data?.cities?.length > 0) {
      payload["cities"] = data?.cities;
    }
    if (!isAllModelsSelected && data?.model?.length > 0) {
      payload["model"] = data?.model;
    }
    if (!isAllBranchesSelected && data?.branches?.length > 0) {
      payload["branches"] = data?.branches;
    }

    if (
      data?.advanceFilter?.rideDatesStart?.startDate &&
      data?.advanceFilter?.rideDatesStart?.endDate
    ) {
      payload["startDateFrom"] = getTimestampAtStartOfDay(
        data?.advanceFilter?.rideDatesStart?.startDate
      );
      payload["startDateTill"] = getTimestampAtEndOfDay(
        data?.advanceFilter?.rideDatesStart?.endDate
      );
    }

    if (
      data?.advanceFilter?.rideDatesEnd?.startDate &&
      data?.advanceFilter?.rideDatesEnd?.endDate
    ) {
      payload["endDateFrom"] = getTimestampAtStartOfDay(
        data?.advanceFilter?.rideDatesEnd?.startDate
      );
      payload["endDateTill"] = getTimestampAtEndOfDay(
        data?.advanceFilter?.rideDatesEnd?.endDate
      );
    }

    if (isDownload) {
      payload["downloadBookingListHandler"] = downloadBookingListHandler;
      payload["page"] = downloadRowCount.page;
      payload["pageSize"] = downloadRowCount.pageSize;
    }

    if (data.bookingDateFrom && !data.bookingDateTill) return;

    if (data.userPhoneNumber?.length && data.userPhoneNumber?.length === 10) {
      payload.userPhoneNumber = data.userPhoneNumber;

      // in case of phone number search this will be dispatched
      dispatch(bookingListUsingPhoneNumber(payload));
    } else {
      dispatch(getBookingList(payload));
    }
  };

  // on table row click
  const handleRowClick = (params: GridRowParams) => {
    RbacHasAccess.viewBooking &&
      navigate(`${routesConstants.BOOKING_DETAILS}/${params.row.id}`);
  };

  // handle date range selection for booking date
  const handleSpecificDateRangeSelection = (dateRange: any) => {
    let [startDateFromRangePicker, endDateFromRangePicker] = dateRange;

    setFields((prev: any) => ({
      ...prev,
      bookingDateFrom: startDateFromRangePicker,
      bookingDateTill: endDateFromRangePicker,
    }));

    handleBookingListApi({
      status: fields?.status,
      bookingDateFrom: startDateFromRangePicker,
      bookingDateTill: endDateFromRangePicker,
      page: initialState?.page,
      pageSize: initialState?.pageSize,
      cities: fields?.cities,
      branches: fields?.branches,
      model: fields?.model,
      search: fields?.search,
      userPhoneNumber: fields?.userPhoneNumber,
      quickFilters: false,
      advanceFilter: fields?.advanceFilter,
    });

    setDialogData((prevState: any) => ({
      ...prevState,
      quickFilter: "",
    }));
  };

  //date range in advance filter
  const handleAdvanceFilterBookingDateRange = (dateRange: any) => {
    let [startDateFromRangePicker, endDateFromRangePicker] = dateRange;
    setAdvanceFilterFields((prev: any) => ({
      ...prev,
      bookingDateFrom: startDateFromRangePicker,
      bookingDateTill: endDateFromRangePicker,
    }));
  };

  // handle changes in fields
  const handleChange = (key: string, value: any) => {
    // let data: GenericObject = {};
    setFields((prevState: any) => ({
      ...prevState,
      [key]: value,
      page: initialState?.page,
      pageSize: initialState?.pageSize,
    }));
    setDialogData((prevState: any) => ({
      ...prevState,
      quickFilter: "",
    }));
  };

  //pagination for data table
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize, ...rest } = fields;

    handleBookingListApi({
      page: paginationValue?.page,
      pageSize: paginationValue.pageSize,
      quickFilters: true,
      ...rest,
    });

    setFields((prev: any) => ({
      ...prev,
      page: paginationValue?.page,
      pageSize: paginationValue.pageSize,
    }));
  };

  //filter reset
  const handleFilterReset = () => {
    setFields((prev: GenericObject) => ({
      ...prev,
      ...initialState,
    }));

    setDialogData({
      open: false,
      quickFilter: "",
    });
    // clear filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.BOOKING_LIST_FILTER_DATA]: { ...initialState },
    });

    handleInitialCities();
    handleBookingListApi({
      page: initialState.page,
      pageSize: initialState.pageSize,
    });
  };

  //on apply advance filter from adv. filter modal
  const onApplyAdvanceFilter = () => {
    setFields({ ...advanceFilterFields });
    handleBookingListApi({ ...advanceFilterFields });
    setIsAdvanceFilter(false);
    setDialogData((prevState: any) => ({
      ...prevState,
      quickFilter: "",
    }));
    setAdvanceFilterFields({ ...initialState });
  };

  // handle city change
  const handleInitialCities = async () => {
    const tempData: InitialState = await getLocalData(
      filterStorageKeys.BOOKING_LIST_FILTER_DATA
    );
    if (allCitiesDetails?.length) {
      const arr: string[] = allCitiesDetails.map((e: any) => e.name);

      setFields((prev: any) => ({
        ...prev,
        cities: tempData.cities?.length ? tempData.cities : arr,
      }));

      dispatch(
        getAllBranchesByCity({
          cities: tempData.cities?.length ? tempData.cities : arr,
        })
      );
    }
  };

  //handle branches change
  const handleInitialBranches = async () => {
    try {
      const tempData: InitialState = await getLocalData(
        filterStorageKeys.BOOKING_LIST_FILTER_DATA
      );
      if (authoriseBranches.length) {
        const arr: string[] = authoriseBranches.map((e: any) => e.name);
        setFields((prev: any) => ({
          ...prev,
          branches: tempData?.branches?.length ? tempData.branches : arr,
        }));
        dispatch(
          getModelListByBranch({
            branches: tempData?.branches?.length ? tempData.branches : arr,
          })
        );
      }
    } catch (err) {
      console.error("Error fetching branches", err);
    }
  };

  // handle model change
  const handleInitalModels = async () => {
    const tempData: InitialState = await getLocalData(
      filterStorageKeys.BOOKING_LIST_FILTER_DATA
    );
    try {
      if (modelListByBranch.length) {
        const arr: string[] = modelListByBranch.map((e: any) => e.name);
        setFields((prev: any) => ({
          ...prev,
          model: tempData?.model?.length ? tempData.model : arr,
        }));
      } else {
        setFields((prev: any) => ({ ...prev, model: [] }));
      }
    } catch (err) {
      console.error("Error fetching models", err);
    }
  };

  const quickFilterCall = (quickFilters: string) => {
    let payload: GenericObject = {
      page: 1,
      pageSize: 10,
      quickFilters,
    };

    dispatch(getBookingList(payload));
  };

  // yes handler dialog
  const filterDialogYesHandler = () => {
    quickFilterCall(dialogData?.quickFilter);
    setDialogData((prev: any) => ({ ...prev, open: false }));
    setFields((prev: GenericObject) => ({
      ...prev,
      ...initialState,
    }));

    // clear filter in local storage
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
      [filterStorageKeys.BOOKING_LIST_FILTER_DATA]: { ...initialState },
    });
  };

  // handle quick filter click
  const handleQuickFilter = async (quickFilter: string) => {
    const localData: InitialState = await getLocalData(
      filterStorageKeys.BOOKING_LIST_FILTER_DATA
    );
    if (quickFilter === dialogData.quickFilter) {
      setDialogData((prev: GenericObject) => ({
        ...prev,
        quickFilter: "",
      }));
      handleBookingListApi({
        page: initialState.page,
        pageSize: initialState.pageSize,
      });
      return;
    }
    if (JSON.stringify(localData) !== JSON.stringify(initialState)) {
      setDialogData((prev: GenericObject) => ({
        ...prev,
        open: true,
        quickFilter: quickFilter,
      }));
    } else {
      setDialogData((prev: GenericObject) => ({
        ...prev,
        quickFilter,
      }));
      quickFilterCall(quickFilter);
    }
  };

  // set data to advance filter reducer
  const initialDataAdvanceFilter = () => {
    if (authoriseBranches?.length) {
      dispatch(
        setBookingReducerData({
          advanceFiltersHubs: authoriseBranches,
        })
      );
    }
    if (modelListByBranch?.length) {
      dispatch(
        setBookingReducerData({
          advanceFiltersModel: modelListByBranch,
        })
      );
    }
  };

  //toogle adv filter dwarer
  const toggleDrawer = () => {
    if (isAdvanceFilter) {
      setAdvanceFilterFields({ ...initialState });
      initialDataAdvanceFilter();
    }
    setIsAdvanceFilter(!isAdvanceFilter);
  };

  // cities set in state initially
  useEffect(() => {
    handleInitialCities();
  }, [allCitiesDetails]);

  //branches set in state initially
  useEffect(() => {
    handleInitialBranches();
  }, [authoriseBranches]);

  //model set in state initially
  useEffect(() => {
    handleInitalModels();
  }, [modelListByBranch]);

  //initail call
  useEffect(() => {
    const fetchData = async () => {
      RbacHasAccess.bookingStats && dispatch(getBookingStatistics());
      handleInitialCities();
      // Fetch filters from local storage
      const tempData: InitialState = await getLocalData(
        filterStorageKeys.BOOKING_LIST_FILTER_DATA
      );

      const payload = {
        status: tempData?.status ?? rentalEn?.global?.allStatus,
        page: bookingListData?.pageData?.page
          ? bookingListData?.pageData?.page - 1
          : initialState?.page,
        pageSize: bookingListData?.pageData?.pageSize
          ? bookingListData?.pageData?.pageSize
          : initialState?.pageSize,
        branches: tempData?.branches || initialState?.branches,
        model: tempData?.model || initialState?.model,
        bookingDateFrom: tempData?.bookingDateFrom ?? "",
        bookingDateTill: tempData?.bookingDateTill ?? "",
        search: tempData?.search ?? "",
        userPhoneNumber: tempData?.userPhoneNumber ?? "",
        cities: tempData?.cities || initialState?.cities,
        advanceFilter: tempData?.advanceFilter || initialState?.advanceFilter,
      };

      handleBookingListApi(payload);

      setFields((prev: GenericObject) => ({
        ...prev,
        ...tempData,
        page: bookingListData?.pageData?.page
          ? bookingListData?.pageData?.page - 1
          : initialState?.page,
        pageSize: bookingListData?.pageData?.pageSize
          ? bookingListData?.pageData?.pageSize
          : initialState?.pageSize,
      }));
    };

    fetchData();
    const interval = setInterval(() => {
      setFlipped((prevFlipped) => !prevFlipped);
    }, 3000);

    return () => {
      dispatch(
        setBookingReducerData({ modelListByBranch: [], authoriseBranches: [] })
      );
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "10px",
          marginBottom: "24px",
        }}
      >
        <>
          {RbacHasAccess?.bookingStats &&
            Rides?.map((item: any, index: number) => {
              return (
                <ShortInfoCard
                  key={index}
                  icon={item.icon}
                  text={item?.displayName}
                  subText={bookingStats?.[item.key] || 0}
                  bgImg={BGImg}
                  onClick={() => handleQuickFilter(item.filterKey)}
                  cardSelected={
                    dialogData.quickFilter === item.filterKey ? true : false
                  }
                />
              );
            })}
        </>
      </Grid>
      {/* Filter dropdoem */}
      {RbacHasAccess?.listBooking ? (
        <Stack gap="20px" marginBottom="20px">
          <Box sx={{ flexGrow: 1, alignItems: "center" }}>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item sm={12} md={2.5} lg={2.5}>
                <CustomMultiSelect
                  icon={CityInputIcon}
                  placeholder={rentalEn.global.allCities}
                  value={fields?.cities}
                  selectAllText={rentalEn.global.allCities}
                  handleChange={(values: any) => {
                    handleChange("cities", values);

                    if (values.length === 0) {
                      setFields((prevState: any) => ({
                        ...prevState,
                        branches: [],
                      }));
                      getAllModels();
                    } else {
                      dispatch(
                        getAllBranchesByCity({
                          cities: values,
                        })
                      );
                    }
                    handleBookingListApi({
                      status: fields?.status,
                      bookingDateFrom: fields?.bookingDateFrom,
                      bookingDateTill: fields.bookingDateTill,
                      page: initialState?.page,
                      pageSize: initialState?.pageSize,
                      cities: values,
                      search: fields?.search,
                      userPhoneNumber: fields?.userPhoneNumber,
                      quickFilters: false,
                      advanceFilter: fields?.advanceFilter,
                    });
                  }}
                  choice={allCitiesDetails}
                  defaultKey="name"
                  displayNameKey="displayName"
                />
              </Grid>
              <Grid item sm={12} md={2.5} lg={2.5}>
                <CustomMultiSelect
                  icon={WarehouseIcon}
                  placeholder={rentalEn.global.allHubs}
                  selectAllText={rentalEn.global.allHubs}
                  value={fields?.branches}
                  handleChange={(values: any) => {
                    handleChange("branches", values);
                    if (values.length === 0) {
                      getAllModels();
                    } else {
                      dispatch(
                        getModelListByBranch({
                          branches: values,
                        })
                      );
                    }
                    handleBookingListApi({
                      status: fields?.status,
                      bookingDateFrom: fields?.bookingDateFrom,
                      bookingDateTill: fields.bookingDateTill,
                      page: initialState?.page,
                      pageSize: initialState?.pageSize,
                      cities: fields?.cities,
                      branches: values,
                      search: fields?.search,
                      userPhoneNumber: fields?.userPhoneNumber,
                      quickFilters: false,
                      advanceFilter: fields?.advanceFilter,
                    });
                  }}
                  choice={authoriseBranches}
                  defaultKey="name"
                  displayNameKey="displayName"
                  // disabled={fields?.cities?.length === 0}
                />
              </Grid>
              <Grid item sm={12} md={3} lg={3}>
                <CustomMultiSelect
                  icon={BikeIconFaded}
                  placeholder={rentalEn.global.allModels}
                  selectAllText={rentalEn.global.allModels}
                  value={fields?.model}
                  handleChange={(values: any) => {
                    handleChange("model", values);
                    if (values?.length)
                      handleBookingListApi({
                        status: fields?.status,
                        bookingDateFrom: fields?.bookingDateFrom,
                        bookingDateTill: fields.bookingDateTill,
                        page: initialState?.page,
                        pageSize: initialState?.pageSize,
                        cities: fields?.cities,
                        branches: fields?.branches,
                        model: values,
                        search: fields?.search,
                        userPhoneNumber: fields?.userPhoneNumber,
                        quickFilters: false,
                        advanceFilter: fields?.advanceFilter,
                      });
                  }}
                  choice={modelListByBranch}
                  defaultKey="name"
                  displayNameKey="displayName"
                  disabled={!isArrayNotEmpty(modelListByBranch)}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* SELECT STATUS OF PLAN */}
                <CustomSelect
                  required
                  icon={StatusIcon}
                  placeholder={rentalEn.global.rideStatus}
                  value={fields.status}
                  choice={[
                    rentalEn?.global?.allStatus,
                    ...Object.keys(BookingStatusEnum),
                  ]}
                  handleChange={(status: any) => {
                    handleChange("status", status);
                    handleBookingListApi({
                      status: status,
                      bookingDateFrom: fields?.bookingDateFrom,
                      bookingDateTill: fields.bookingDateTill,
                      page: initialState?.page,
                      pageSize: initialState?.pageSize,
                      cities: fields?.cities,
                      branches: fields?.branches,
                      model: fields?.model,
                      search: fields?.search,
                      userPhoneNumber: fields?.userPhoneNumber,
                      quickFilters: false,
                      advanceFilter: fields?.advanceFilter,
                    });
                  }}
                  width={220}
                />
                <Tooltip title={rentalEn?.global?.resetFilters} arrow>
                  <RotateRightSharp
                    fontSize="large"
                    color="primary"
                    sx={{ cursor: "pointer", fontSize: "25px" }}
                    onClick={handleFilterReset}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1, alignItems: "center" }}>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid item sm={12} md={3} lg={3}>
                <RangePicker
                  placeholderText={
                    rentalEn?.bookingManagement?.selectBookingStartAndEndDate
                  }
                  selectsRange={true}
                  startDate={fields?.bookingDateFrom ?? ""}
                  endDate={fields?.bookingDateTill ?? ""}
                  onChange={(update: any) =>
                    handleSpecificDateRangeSelection(update)
                  }
                  onClickOutside={(e: any) => {
                    // handling if user clicks outside after selecing start date
                    if (fields.bookingDateFrom && !fields.bookingDateTill) {
                      // clear filter in local storage
                      storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, {
                        [filterStorageKeys.BOOKING_LIST_FILTER_DATA]: {
                          ...fields,
                          bookingDateFrom: initialState.bookingDateFrom,
                          bookingDateTill: initialState.bookingDateTill,
                        },
                      });
                      setFields((prev: any) => ({
                        ...prev,
                        bookingDateFrom: initialState.bookingDateFrom,
                        bookingDateTill: initialState.bookingDateTill,
                      }));
                    }
                  }}
                  isClearable={
                    fields?.bookingDateFrom && fields.bookingDateTill
                  }
                  icon={fadedCalenderIcon}
                  popperPlacement="right"
                  popperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10], // Adjust as needed
                        },
                      },
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 2,
                        },
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item sm={12} md={2.5} lg={2.5}>
                <Input
                  placeholder={rentalEn?.bookingManagement?.bookingNumber}
                  variant="outlined"
                  value={fields?.search}
                  iconStart={SearchIcon}
                  onChange={(e: any) => {
                    handleChange("search", e.target.value);
                    if (
                      (checkAlfaNumeric(e.target.value) &&
                        e.target.value.length >= 3) ||
                      e.target.value.length === 0
                    ) {
                      handleBookingListApi({
                        status: fields?.status,
                        bookingDateFrom: fields?.bookingDateFrom,
                        bookingDateTill: fields.bookingDateTill,
                        page: initialState?.page,
                        pageSize: initialState?.pageSize,
                        cities: fields?.cities,
                        branches: fields?.branches,
                        model: fields?.model,
                        search: e.target.value,
                        userPhoneNumber: fields?.userPhoneNumber,
                        quickFilters: false,
                        advanceFilter: fields?.advanceFilter,
                      });
                    }
                  }}
                  muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
                />
              </Grid>
              <Grid item sm={12} md={3} lg={3}>
                <Input
                  placeholder={rentalEn?.bookingManagement?.customerPhoneNumber}
                  variant="outlined"
                  value={fields?.userPhoneNumber}
                  iconStart={SearchIcon}
                  // here
                  onChange={(e: any) => {
                    checkPhone(e.target.value) &&
                      e.target.value?.length < 11 &&
                      handleChange("userPhoneNumber", e.target.value);

                    if (
                      e.target.value?.length === 10 ||
                      e.target.value?.length === 0
                    ) {
                      handleBookingListApi({
                        status: fields?.status,
                        bookingDateFrom: fields?.bookingDateFrom,
                        bookingDateTill: fields.bookingDateTill,
                        page: initialState?.page,
                        pageSize: initialState?.pageSize,
                        cities: fields?.cities,
                        branches: fields?.branches,
                        model: fields?.model,
                        search: fields?.search,
                        userPhoneNumber: e.target.value,
                        quickFilters: false,
                        advanceFilter: fields?.advanceFilter,
                      });
                    }
                  }}
                  muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={3.5}
                lg={3.5}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <CustomButton
                  label={rentalEn?.buttonLabels?.advanceFilters}
                  onClick={toggleDrawer}
                />

                <CustomButton
                  label={rentalEn?.global?.download}
                  onClick={handleDownload}
                  loading={false}
                  startIcon={<FileDownloadOutlined />}
                  sx={{ gap: "0px" }}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      ) : null}

      <DataTable
        autoHeight
        rows={FilterdBookingsData}
        columns={bookingColumns}
        headerAlign={"center"}
        getRowId={getRowId}
        loading={onLoad}
        hideColumnSeperator
        rowCount={bookingListData?.pageData?.total || 0}
        paginationModel={{ page: fields.page, pageSize: fields?.pageSize }}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        hasAccess={RbacHasAccess?.listBooking}
      />
      <SideDrawer
        open={isAdvanceFilter}
        heading={rentalEn.bookingManagement.advanceFilter}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <AdvanceFilter
            fields={fields}
            setFields={setFields}
            advanceFilterFields={advanceFilterFields}
            setAdvanceFilterFields={setAdvanceFilterFields}
            toggleDrawer={toggleDrawer}
            handleAdvanceFilterBookingDateRange={
              handleAdvanceFilterBookingDateRange
            }
            onApplyFilter={onApplyAdvanceFilter}
            initialState={initialState}
            getAllModels={getAllModels}
          />
        }
      />
      <ResponsiveDialog
        title={rentalEn.bookingDetails.removeFilters}
        body={rentalEn.bookingDetails.sureWantToRemoveFilters}
        open={dialogData.open}
        handleClose={() =>
          setDialogData((prev: any) => ({
            ...prev,
            open: false,
            quickFilter: "",
          }))
        }
        handleYes={filterDialogYesHandler}
        titleFontSize={"14px"}
        titlestyle={{ padding: "12px 0px" }}
      />
    </Box>
  );
};
export default BookingManagement;
