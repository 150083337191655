import moment from "moment";
import en from "../../../locale/en.json";
import { MASTER_ACTIONS } from "../../actions/actionTypes";
import { masterInterface, paymentInterface } from "../actionInterfaces";

const initialState: any = {
  cityData: [],
  advertisementData: [],
  categoryData: [],
  modelData: [],
  cityloading: true,
  categoryloading: true,
  advertisementByIdLoading: false,
  modelloading: true,
  totalItems: 0,
  addsuccess: false,
  cityById: {},
  categoryById: {},
  modelById: {},
  cityStatusData: [],
  editsuccess: false,
  cityByIdLoading: true,
  categoryByIdLoading: true,
  modelByIdLoading: true,
  getstatussuccess: false,
  helmetConfigData:[],
  helmetConfigStatus:false
};

const masterReducer = (state = initialState, action: masterInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case MASTER_ACTIONS.GET_CITY_DATA_REQUESTED:
      return {
        ...state,
        cityloading: true,
      };
    case MASTER_ACTIONS.GET_CITY_DATA_SUCCESS:
      return {
        ...state,
        cityData: payload.cityData,
        cityloading: false,
        totalItems: payload.cityData.length,
      };
    case MASTER_ACTIONS.GET_CITY_DATA_FAILED:
      return {
        ...state,
        cityloading: false,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_REQUESTED:
      return {
        ...state,
        advertisementloading: true,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_SUCCESS:
      return {
        ...state,
        advertisementData: payload.advertisementData,
        advertisementloading: false,
        totalItems: payload.advertisementData?.length,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_FAILED:
      return {
        ...state,
        advertisementloading: false,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_REQUESTED:
      return {
        ...state,
        advertisementByIdLoading: true,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        advertisementById: payload.modelData,
        advertisementByIdLoading: false,
      };
    case MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_FAILED:
      return {
        ...state,
        advertisementByIdLoading: false,
      };
    case MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_REQUESTED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_SUCCESS:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_FAILED:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_REQUESTED:
      return {
        ...state,
        editsuccess: false,
      };
    case MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_SUCCESS:
      return {
        ...state,
        editsuccess: true,
      };
    case MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_FAILED:
      return {
        ...state,
        editsuccess: true,
      };

    case MASTER_ACTIONS.GET_CITY_STATUS_LIST_REQUESTED:
      return {
        ...state,
        getstatussuccess: false,
      };
    case MASTER_ACTIONS.GET_CITY_STATUS_LIST_SUCCESS:
      return {
        ...state,
        cityStatusData: payload?.cityStatusData ?? [],
        getstatussuccess: true,
      };
    case MASTER_ACTIONS.GET_CITY_STATUS_LIST_FAILED:
      return {
        ...state,
        getstatussuccess: false,
      };




      case MASTER_ACTIONS.GET_HELMET_CONFIG_REQUESTED:
        return {
          ...state,
          helmetConfigStatus: false,
        };
      case MASTER_ACTIONS.GET_HELMET_CONFIG_SUCCESS:
        return {
          ...state,
          helmetConfigData: payload.helmetStatusData ?? [],
          helmetConfigStatus: true,
        };
      case MASTER_ACTIONS.GET_HELMET_CONFIG_FAILED:
        return {
          ...state,
          helmetConfigStatus: false,
        };

    case MASTER_ACTIONS.ADD_CITY_DATA_REQUESTED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.ADD_CITY_DATA_SUCCESS:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.ADD_CITY_DATA_FAILED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.GET_CITY_BY_ID_REQUESTED:
      return {
        ...state,
        cityByIdLoading: false,
      };
    case MASTER_ACTIONS.GET_CITY_BY_ID_SUCCESS:
      return {
        ...state,
        cityById: payload.cityById,
        cityByIdLoading: true,
      };
    case MASTER_ACTIONS.GET_CITY_BY_ID_FAILED:
      return {
        ...state,
        cityByIdLoading: true,
      };
    case MASTER_ACTIONS.EDIT_CITY_DATA_REQUESTED:
      return {
        ...state,
        editsuccess: false,
      };
    case MASTER_ACTIONS.EDIT_CITY_DATA_SUCCESS:
      return {
        ...state,
        editsuccess: true,
      };
    case MASTER_ACTIONS.EDIT_CITY_DATA_FAILED:
      return {
        ...state,
        editsuccess: true,
      };

    //category started
    case MASTER_ACTIONS.GET_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        categoryloading: true,
      };
    case MASTER_ACTIONS.GET_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        categoryData: payload.categoryData,
        categoryloading: false,
        totalItems: payload.categoryData.length,
      };
    case MASTER_ACTIONS.GET_CATEGORY_DATA_FAILED:
      return {
        ...state,
        categoryloading: false,
      };
    case MASTER_ACTIONS.ADD_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.ADD_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.ADD_CATEGORY_DATA_FAILED:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.GET_CATEGORY_BY_ID_REQUESTED:
      return {
        ...state,
        categoryByIdLoading: false,
      };
    case MASTER_ACTIONS.GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        categoryById: payload.categoryById,
        categoryByIdLoading: true,
      };
    case MASTER_ACTIONS.GET_CATEGORY_BY_ID_FAILED:
      return {
        ...state,
        categoryByIdLoading: true,
      };
    case MASTER_ACTIONS.EDIT_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        editsuccess: false,
      };
    case MASTER_ACTIONS.EDIT_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        editsuccess: true,
      };
    case MASTER_ACTIONS.EDIT_CATEGORY_DATA_FAILED:
      return {
        ...state,
        editsuccess: true,
      };

    case MASTER_ACTIONS.TOGGLE_ADD_EDIT:
      return {
        addsuccess: false,
        editsuccess: false,
      };

    case MASTER_ACTIONS.DELETE_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        deleteloading: true,
      };
    case MASTER_ACTIONS.DELETE_CATEGORY_DATA_SUCCESS:
      // alert()
      // let updatedcategoryData = state.categoryData.filter((categoryData: any) => {
      //   return categoryData.id != payload.deletedDataId;
      // });
      return {
        ...state,
        ...payload,
        deleteloading: false,
        // categoryData: updatedcategoryData,
      };
    case MASTER_ACTIONS.DELETE_CATEGORY_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        deleteloading: true,
      };

    //model started

    case MASTER_ACTIONS.GET_MODEL_DATA_REQUESTED:
      return {
        ...state,
        modelloading: true,
      };
    case MASTER_ACTIONS.GET_MODEL_DATA_SUCCESS:
      return {
        ...state,
        modelData: payload.modelData,
        modelloading: false,
      };
    case MASTER_ACTIONS.GET_MODEL_DATA_FAILED:
      return {
        ...state,
        modelloading: false,
      };
    case MASTER_ACTIONS.ADD_MODEL_DATA_REQUESTED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.ADD_MODEL_DATA_SUCCESS:
      return {
        ...state,
        addsuccess: true,
      };
    case MASTER_ACTIONS.ADD_MODEL_DATA_FAILED:
      return {
        ...state,
        addsuccess: false,
      };
    case MASTER_ACTIONS.GET_MODEL_BY_ID_REQUESTED:
      return {
        ...state,
        modelByIdLoading: false,
      };
    case MASTER_ACTIONS.GET_MODEL_BY_ID_SUCCESS:
      return {
        ...state,
        modelById: payload.modelById,
        modelByIdLoading: true,
      };
    case MASTER_ACTIONS.GET_MODEL_BY_ID_FAILED:
      return {
        ...state,
        modelByIdLoading: true,
      };
    case MASTER_ACTIONS.EDIT_MODEL_DATA_REQUESTED:
      return {
        ...state,
        editsuccess: false,
      };
    case MASTER_ACTIONS.EDIT_MODEL_DATA_SUCCESS:
      return {
        ...state,
        editsuccess: true,
      };
    case MASTER_ACTIONS.EDIT_MODEL_DATA_FAILED:
      return {
        ...state,
        editsuccess: true,
      };
    case MASTER_ACTIONS.DELETE_MODEL_DATA_REQUESTED:
      return {
        ...state,
        deleteloading: true,
      };
    case MASTER_ACTIONS.DELETE_MODEL_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        deleteloading: false,
        // categoryData: updatedcategoryData,
      };
    case MASTER_ACTIONS.DELETE_MODEL_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        deleteloading: true,
      };

    default:
      return { ...prevState };
  }
};

export default masterReducer;
