import { Stack, Typography, Box, ButtonGroup, Button } from "@mui/material";
import {
  GENDERS,
  typographyConstants,
} from "../../rentals-src/constants/constants";
import en from "../../locale/en.json";
import {
  FemaleIcon,
  MaleIcon,
  OthersIcon,
} from "../../rentals-src/constants/exportImages";
import { colors } from "../../themes/colors";
import Styles from "../components.module.css";
import {
  FemaleTypeIcon,
  MaleTypeIcon,
  OthersTypeIcon,
} from "../../rentals-src/assests/svgComponents/genderIcons";

//gender selection [male,female, others]
//pass initlal gender state and handle change

const genderOptions = [
  {
    key: GENDERS.MALE,
    label: en.NewManualBooking.Male,
    style: { borderRadius: "20px 0px 0px 20px" },
    startIcon: <MaleTypeIcon />,
  },
  {
    key: GENDERS.FEMALE,
    label: en.NewManualBooking.Female,
    style: {},
    startIcon: <FemaleTypeIcon />,
  },
  {
    key: GENDERS.OTHERS,
    label: en.NewManualBooking.Others,
    style: { borderRadius: "0px 20px 20px 0px" },
    startIcon: <OthersTypeIcon />,
  },
];
export const GenderSelection = (props: any) => {
  const { fields, errorFields, handleChange } = props;

  return (
    <Stack>
      <Typography variant={typographyConstants.HEADING}>
        {en.NewManualBooking.SelectGender}
      </Typography>
      <Box>
        <ButtonGroup
          aria-label="Basic button group"
          disableElevation
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: 0,
            },
          }}
        >
          {genderOptions.map(({ key, style, label, startIcon }) => (
            <Button
              key={key}
              variant={fields?.gender === key ? "contained" : "outlined"}
              onClick={() => handleChange("gender", key)}
              className={Styles.buttonGroup}
              style={style}
              startIcon={startIcon}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      {errorFields?.gender && (
        <Typography variant="errorMessage1" sx={{ marginLeft: "8px" }}>
          {errorFields.gender}
        </Typography>
      )}
    </Stack>
  );
};
