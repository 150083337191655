import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import forge from "node-forge";
import { BASE_URL } from "../config/apiUrls";
import { CookieTypes } from "../constants/enum";
import CONSTANTS from "../locale/en.json";
import { actions } from "../redux";
import store from "../redux/store/index";
import { defaultHeaders, setAuthToken } from "./axiosConfig";

const getLocalRefreshToken: any = () => {
  const token = JSON.parse(localStorage.getItem("token") || "");
  return token?.refreshToken;
};
const getLocalAccessToken: any = () => {
  const token = JSON.parse(localStorage.getItem("token") || "");
  return token?.accessToken;
};
const updateLocalAccessToken: any = (NewToken: any) => {
  let token = JSON.parse(localStorage.getItem("token") || "");
  token.accessToken = token;
  localStorage.setItem("token", JSON.stringify(token) || "");
};
const getToken: any = () => {
  return localStorage.getItem("token");
};
const setToken: any = (token: any) => {
  localStorage.setItem("token", token);
};
const removeUser: any = () => {
  localStorage.removeItem("token");
};

const getTokenCookie: any = () => {
  return Cookies.get(CookieTypes.TOKEN);
};

const setTokenCookie: any = (token: any) => {
  Cookies.set(CookieTypes.TOKEN, token);
};

const removeTokenCookie: any = () => {
  Cookies.remove(CookieTypes.TOKEN, { path: "/" });
};

const getHttpChallenge = (
  xKey: string,
  clientId: string,
  platform: string,
  channel: string
) => {
  // x-http-challenge
  const hashTextFrontend: any = `${clientId}:${platform}:${channel}`;
  const mdTextFrontend: any = forge.md.sha512.create();
  mdTextFrontend.update(hashTextFrontend);
  const hashValueFrontend: any = mdTextFrontend.digest().toHex();
  const userChallengeFrontend: any = `${new Date().getTime()}::${hashValueFrontend}`;
  const decodedPublicKey: any = forge.util.decode64(xKey);
  const publicKeyFrontend: any = forge.pki.publicKeyFromPem(decodedPublicKey);
  const encryptedFrontend: any = publicKeyFrontend.encrypt(
    userChallengeFrontend,
    "RSA-OAEP"
  );
  // Encode the encrypted text in Base64
  const base64EncodedFrontend: any = forge.util.encode64(encryptedFrontend);
  return base64EncodedFrontend;
};

const saveTokens = (data: any) => {
  const tokenDetails = {
    ExpireTime: data?.data?.data?.expiry ?? "",
    token: data?.data?.data?.accessToken ?? "",
    refreshToken: data?.data?.data?.refreshToken ?? "",
    xKey: data?.headers?.["x-key"],
  };
  // populate in cookies
  Cookies.set(
    CookieTypes.TOKEN,
    JSON.stringify({
      ...tokenDetails,
    }),
    { expires: 365 }
  );

  store.dispatch(
    actions.setUserToken({
      ...tokenDetails,
    })
  );
};

const isAccessTokenExpired = (): boolean => {
  // Get the token from cookies
  const tokenFromCookieObj: string | undefined | any = Cookies.get("token");
  if (!tokenFromCookieObj) {
    // Handle case where token is not present
    return true;
  }
  const parsedCookieToken = JSON.parse(tokenFromCookieObj);
  const currentTime = moment();
  const expiryTime = moment(parsedCookieToken?.ExpireTime * 1000);
  // Check if the access token is expired or about to expire
  return expiryTime.diff(currentTime, "seconds") < 10;
};

const getAccessToken = async () => {
  const tokenCookieObj: any = Cookies.get("token");
  const parsedTokenCookieObj: any = JSON.parse(tokenCookieObj);

  const clientId: string = CONSTANTS?.requestHeaders["x-client-id"];
  const platform: string = CONSTANTS?.requestHeaders["x-platform"];
  const channel: string = CONSTANTS?.requestHeaders["x-channel"];
  const grantType: string = CONSTANTS?.requestHeaders["grantType"];
  const refreshToken: string | any = parsedTokenCookieObj?.refreshToken;
  const xKey: string | any = parsedTokenCookieObj?.xKey;

  let url: string = BASE_URL + "titan-shield/authentication/v1/token?";
  url += refreshToken ? `refresh_token=${refreshToken}&` : "";
  url += grantType ? `grant_type=${grantType}` : "";

  const xHttpChallenge: string = getHttpChallenge(
    xKey,
    clientId,
    platform,
    channel
  ); // get x-http-challenge

  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: url, // `https://qa-api.freedo.rentals/titan-shield/authentication/v1/token?refresh_token=${refreshToken}&grant_type=refresh`,
    headers: {
      ...defaultHeaders,
      "x-http-challenge": xHttpChallenge,
    },
  };

  try {
    const tokenResponse = await axios.request(config); // request new token
    saveTokens(tokenResponse); // save new tokens
    setAuthToken(tokenResponse?.data?.data?.accessToken); // Set the new access token in the request header
    return tokenResponse?.data?.data?.accessToken; // return new access token
  } catch (error) {
    console.error("Error getting access token", error);
  }
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getToken,
  setToken,
  removeUser,
  getTokenCookie,
  setTokenCookie,
  removeTokenCookie,
  getHttpChallenge,
  saveTokens,
  isAccessTokenExpired,
  getAccessToken,
};
export default TokenService;
