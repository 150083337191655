import {
  Box,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import CustomButtonGroup from "../../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import HomeDeliveryModal from "../../../../../sharedComponents/templates/HomeDeliveryModal";
import HelmetBadge from "../../../../../sharedComponents/templates/MultiStepForm/HelmetBadge";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesSubCategories,
  BookingStatusEnum,
  HELMETS_ACTIONS,
  HELMETS_TYPES,
  modificationType,
  tyesOfModeOfDelivery,
  typographyConstants,
} from "../../../../constants/constants";
import {
  fadedCalenderIcon,
  FullFaceHelmet,
  HalfFaceHelmet,
  KidsHelmet,
  LeftScrollIcon,
  PlusIcon,
  PremiumHelmet,
  RightScrollIcon,
  SearchIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { getCustomerAddressList } from "../../../../redux/actions/manualBooking";
import {
  calculateFutureDate,
  checkDaySlotFromTimeSlot,
  dateFormat,
  formatString,
  formatTime,
  getArraySum,
} from "../../../../utils/helper";
import HomeDeliveryAddressCard from "../../../manualBooking/section/HomeDeliveryAddressCard";
import Styles from "./section.module.css";
import moment from "moment";

const additionalHelmets = [
  {
    icon: PremiumHelmet,
    type: HELMETS_TYPES.premium,
    // type: HELMETS_YOR.premium,
    title: "Premium",
  },
  {
    icon: FullFaceHelmet,
    type: HELMETS_TYPES.fullFace,
    // type: HELMETS_YOR.fullFace,
    title: "Full Face",
  },
  {
    icon: HalfFaceHelmet,
    // type: HELMETS_YOR.halfFace,
    type: HELMETS_TYPES.halfFace,
    title: "Half Face",
  },
  {
    icon: KidsHelmet,
    // type: HELMETS_YOR.kids,
    type: HELMETS_TYPES.kids,
    title: "Kids",
  },
];

export const ModifyAdditionalHelmet = ({
  fields,
  handleChange,
  setFields,
}: any) => {
  const [errorFields, setErrorFields] = useState<any>();
  const dispatch = useDispatch<any>();
  const { bookingDetails = {} }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const { branchDefaultConfig } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const { addOns = [] } = bookingDetails;

  // fetchAddedHelmet from booking details
  const fetchAddedHelmet = () => {
    return addOns?.reduce((acc: any, addon: any) => {
      if (addon.accessoryType === "HELMET") {
        if (addon.isComplementary) return acc;
        acc[addon.subType] = addon.count;
      }
      return acc;
    }, {});
  };

  const extraHelmetHandler = (e: any, type: string, action: string) => {
    let helmetError = "";
    const alreadyAddedHelmet = fetchAddedHelmet();
    const addedHelmets = Object.keys(fields.selectedHelmets).length
      ? { ...fields.selectedHelmets }
      : { ...alreadyAddedHelmet, ...fields.selectedHelmets };

    let helmetCountArray = Object.values(addedHelmets);
    let helmetCount = getArraySum(helmetCountArray);

    if (action === HELMETS_ACTIONS.INCREMENT) {
      if (helmetCount < 3) {
        setFields((prevState: any) => ({
          ...prevState,
          selectedHelmets: {
            ...addedHelmets,
            [type]: (addedHelmets[type] ?? 0) + 1,
          },
          disabledAccordians: {
            vehicle: true,
            package: true,
          },
        }));
        if (errorFields?.selectedHelmets) {
          helmetError = "";
        }
      } else {
        helmetError = "* Max 3 helmets can be added";
      }

      setErrorFields((prevState: any) => ({
        ...prevState,
        selectedHelmets: helmetError,
      }));
    }
    if (action === HELMETS_ACTIONS.DECREMENT) {
      // check if user try to remove already added helmet
      if (addedHelmets[type] - 1 < alreadyAddedHelmet[type]) {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmets: "Already added helmets cannot be removed.",
        }));
        return;
      }
      setFields((prevState: any) => ({
        ...prevState,
        selectedHelmets: {
          ...addedHelmets,
          [type]: addedHelmets[type] ? addedHelmets[type] - 1 : 0,
        },
        disabledAccordians: {
          vehicle: true,
          package: true,
        },
      }));
      if (errorFields?.selectedHelmets) {
        setErrorFields((prevState: any) => ({
          ...prevState,
          selectedHelmets: "",
        }));
      }
    }
  };

  return (
    <Box>
      <Typography
        variant={typographyConstants.BODY}
        sx={{ color: colors.text_quatenary_black, marginBottom: "5px" }}
      >
        {rentalEn.bookingManagement.additionalHelmet}
      </Typography>
      <Stack gap="2vh">
        <Box sx={{ display: "flex", gap: "1.2vw", flexWrap: "wrap" }}>
          {additionalHelmets?.map((item) => {
            const helmetTypes = branchDefaultConfig?.helmetTypes;
            let alreadyBookedHelemts: any = {};
            // data will be set in state only when user changes the helemt
            if (!Object.keys(fields.selectedHelmets).length) {
              alreadyBookedHelemts = fetchAddedHelmet();
            }
            // return null;
            // if check for helmet present in banch config api
            if (helmetTypes?.includes(item.type))
              return (
                <HelmetBadge
                  title={item?.title}
                  icon={item.icon}
                  type={item.type}
                  clickHandler={extraHelmetHandler}
                  helmetTypeCount={
                    fields.selectedHelmets[item.type]
                      ? fields.selectedHelmets[item.type]
                      : alreadyBookedHelemts[item.type] ?? 0
                  }
                />
              );
          })}
        </Box>
        <Box>
          {errorFields?.selectedHelmets && (
            <Typography variant={typographyConstants.ERRORMESSAGE}>
              {errorFields?.selectedHelmets}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            borderWidth: 0,
            borderLeftWidth: "3px",
            borderStyle: "solid",

            backgroundColor: colors.card_border_color,
          }}
        >
          <Typography
            variant={typographyConstants.BODY}
            sx={{
              color: colors.text_secondary_gray,
              marginBottom: "5px",
              backgroundColor: colors.card_border_color,
              paddingLeft: "18px",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            {rentalEn.bookingManagement.currentHelmetDetails}
          </Typography>
          {addOns.map((addOn: any) => {
            if (addOn.accessoryType !== "HELMET") return null;
            return (
              <Typography
                variant={typographyConstants.BODY}
                sx={{
                  color: colors.text_quatenary_black,
                  marginBottom: "5px",
                  paddingLeft: "18px",
                }}
              >
                {formatString(addOn.subType)} : {addOn.count}{" "}
                {addOn?.isComplementary
                  ? addOn.count > 1
                    ? "( One helmet is complementary )"
                    : "( complementary )"
                  : ""}
              </Typography>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

export const ModifyPackageAndSlots = ({
  fields,
  selectedHelmets,
  handleChange,
  setFields,
}: any) => {
  const { deliverySlots, slotPauseDates } = useSelector(
    (state: RootState) => state.newManualBookingReducer
  );
  const { masterPlanDurationsData = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const {
    bookingDetails = {},
    planModificationReasons,
    planSummary = {},
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);
  const dispatch = useDispatch<any>();
  const { deliveryDetails = {} } = bookingDetails;

  const prevRideEndDate: any = useRef(fields.endDate);

  const isModifyPackagesAndSlotDisabled = planSummary?.modifications?.includes(
    modificationType?.BOOKING_DATE_MODIFICATION ||
      modificationType?.PLAN_MODIFICATION ||
      modificationType?.SLOTS_MODIFICATION
  );
  // Whether the Slot is available or not
  const availablePeriods = [
    { label: rentalEn.global.morning, slots: deliverySlots.morning },
    { label: rentalEn.global.afterNoon, slots: deliverySlots.afternoon },
    { label: rentalEn.global.evening, slots: deliverySlots.evening },
  ].filter((period) => period.slots?.length > 0);

  // Render Time Slots
  const renderTimeSlots = (slot: any) => {
    if (slot === rentalEn?.global.morning) return deliverySlots.morning;
    if (slot === rentalEn?.global.afterNoon) return deliverySlots.afternoon;
    if (slot === rentalEn?.global.evening) return deliverySlots.evening;
    return [];
  };

  useEffect(() => {
    if (bookingDetails?.id) {
      setDataOnLoad();
    }
  }, [Object.keys(deliverySlots).length, bookingDetails?.id]);

  //handle time selection of home delivery
  const handleDeliveryTimeSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange("slots", (event.target as HTMLInputElement).value);
  };

  // Start & end date handler
  const handleSpecificDateRangeSelection = (dateRange: any) => {
    let [rideStartDate, rideEndDate] = dateRange;

    const dateObj: any = {
      rideStartDate,
      rideEndDate,
    };
    // set package on the basis of start and end date
    if (fields?.selectedPackage === rentalEn.manualBooking.custom) {
      const diffDays = dayjs(rideEndDate).diff(dayjs(rideStartDate), "days");
      const isInMasterPlans = masterPlanDurationsData.includes(diffDays);
      dateObj["selectedPackage"] = isInMasterPlans
        ? diffDays
        : rentalEn.manualBooking.custom;
      dateObj["selectedDaysCustom"] = diffDays;
    } else if (
      rideStartDate &&
      fields?.selectedPackage !== rentalEn.manualBooking.custom &&
      !rideEndDate
    ) {
      dateObj["rideEndDate"] = calculateFutureDate(
        rideStartDate,
        fields?.selectedPackage
      );
    }
    setFields((prev: any) => ({
      ...prev,
      ...dateObj,
    }));
  };

  // update package and ride dates based on package
  const handlePackagesSelection = (data: any) => {
    if (data === rentalEn.manualBooking.custom) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPackage: rentalEn.manualBooking.custom,
        rideStartDate: null,
        rideEndDate: null,
      }));
    } else if (fields?.rideStartDate === null) {
      setFields((prev: any) => ({
        ...prev,
        rideStartDate: new Date(),
        rideEndDate: calculateFutureDate(new Date(), data),
      }));
    } else {
      setFields((prev: any) => ({
        ...prev,
        rideEndDate: calculateFutureDate(fields?.rideStartDate, data),
      }));
    }
  };

  const setDataOnLoad = () => {
    const formatedSlotOfBooking = checkDaySlotFromTimeSlot(
      bookingDetails?.deliveryDetails?.slot
    );
    handleChange("slots", formatedSlotOfBooking);
    // default select package on the basis of start and end Date
    const diffDays = dayjs(bookingDetails?.endDate).diff(
      dayjs(bookingDetails?.startDate),
      "days"
    );
    const isInMasterPlans = masterPlanDurationsData.includes(diffDays);
    handleChange(
      "selectedPackage",
      isInMasterPlans ? diffDays : rentalEn.manualBooking.custom
    );
  };

  return (
    <Box>
      <Typography
        variant={typographyConstants.BODY}
        sx={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {rentalEn.bookingManagement?.rideStartDate}
      </Typography>
      <RangePicker
        dateFormat={"dd/MM/YYYY"}
        placeholderText={
          rentalEn?.bookingManagement?.selectBookingStartAndEndDate
        }
        selectsRange={true}
        minDate={new Date()}
        maxDate={moment(new Date()).add(90, "d").format()}
        sx={{ width: "55%" }}
        startDate={fields?.rideStartDate}
        endDate={fields?.rideEndDate}
        slotPauseDates={slotPauseDates}
        onChange={(update: any) => handleSpecificDateRangeSelection(update)}
        disabled={
          bookingDetails?.status === BookingStatusEnum.ONGOING ||
          isModifyPackagesAndSlotDisabled
        }
        onClickOutside={(e: any) => {
          // handling if user clicks outside after selecing start date
          if (fields?.rideStartDate && !fields.rideEndDate) {
            setFields((prev: any) => ({
              ...prev,
              rideEndDate:
                prevRideEndDate.current ||
                fields.rideStartDate.setDate(new Date().getDate()) + 1,
            }));
          }
        }}
        isClearable={false}
        icon={fadedCalenderIcon}
      />

      <Box>
        <Box
          sx={{
            backgroundColor: colors.white,
            padding: "20px",
            paddingLeft: "0px",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant={typographyConstants.HEADING_NO_PADDING}>
            {bookingDetails?.status === BookingStatusEnum.UPCOMING
              ? rentalEn.bookingManagement.selectPickupTime
              : rentalEn.bookingManagement.selectDropoffTime}
          </Typography>
          <FormControl>
            {availablePeriods?.length === 0 ? (
              <Typography color="grey">
                {rentalEn.manualBooking.noSlotsAvailable}
              </Typography>
            ) : (
              <>
                <Box display="flex" flexDirection="row" gap="12px">
                  <RadioGroup
                    row
                    name="controlled-radio-buttons-group"
                    value={fields.slots}
                    onChange={handleDeliveryTimeSelect}
                  >
                    {availablePeriods?.map((period: any) => (
                      <FormControlLabel
                        key={period.label}
                        value={period.label}
                        control={<Radio />}
                        label={period.label}
                        disabled={isModifyPackagesAndSlotDisabled}
                      />
                    ))}
                  </RadioGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {renderTimeSlots(fields.slots)?.map(
                    (item: { start: any; end: any }, index: number) => {
                      const isSelected =
                        (fields?.selectedTimeSlot?.start === item?.start &&
                          fields?.selectedTimeSlot?.end === item?.end) ||
                        (deliveryDetails?.slot?.start === item?.start &&
                          deliveryDetails?.slot?.end === item?.end &&
                          !fields?.selectedTimeSlot);
                      return (
                        <Chip
                          key={index}
                          label={`${formatTime(item.start)} - ${formatTime(
                            item.end
                          )}`}
                          color={isSelected ? "primary" : "default"}
                          style={{ borderRadius: "8px" }}
                          onClick={() => {
                            const selectedTime = {
                              start: item.start,
                              end: item.end,
                            };
                            handleChange("selectedTimeSlot", selectedTime);
                          }}
                          disabled={isModifyPackagesAndSlotDisabled}
                        />
                      );
                    }
                  )}
                </Box>
              </>
            )}
          </FormControl>
        </Box>
      </Box>
      <Typography
        sx={{
          backgroundColor: colors.card_border_color,
          paddingLeft: "12px",
          paddingTop: "2px",
          paddingBottom: "2px",
          borderWidth: 0,
          borderLeftWidth: "3px",
          borderStyle: "solid",
        }}
      >
        <span style={{ color: colors.industrial_revolution_gray }}>
          {rentalEn.bookingManagement.currentStartEndDate} :
        </span>{" "}
        {dateFormat(bookingDetails?.startDate)}
        {" - "} {dateFormat(bookingDetails?.endDate)}
      </Typography>
      <Typography
        sx={{
          backgroundColor: colors.card_border_color,
          paddingLeft: "12px",
          paddingTop: "2px",
          paddingBottom: "2px",
          borderWidth: 0,
          borderLeftWidth: "3px",
          borderStyle: "solid",
        }}
      >
        <span style={{ color: colors.industrial_revolution_gray }}>
          Current Slot :{" "}
        </span>
        {`${formatTime(deliveryDetails?.slot?.start)} - ${formatTime(
          deliveryDetails?.slot?.end
        )}`}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CustomSelect
          required
          icon={fadedCalenderIcon}
          placeholder={rentalEn.global.selectPackage}
          value={fields?.selectedPackage}
          topHeading={rentalEn.global.packageDuration}
          skipData={(data: any) => {
            // hide all packages less than current package
            const diffDays = dayjs(bookingDetails?.endDate).diff(
              dayjs(bookingDetails?.startDate),
              "days"
            );
            return data < diffDays;
          }}
          customLabel={(data: any) => {
            return (
              <div>
                {data} {data === 1 ? ` day` : ` days`}
              </div>
            );
          }}
          choice={masterPlanDurationsData}
          disabled={
            bookingDetails?.status === BookingStatusEnum.ONGOING ||
            isModifyPackagesAndSlotDisabled
          }
          handleChange={(days: any) => {
            handleChange("selectedPackage", days);
            handlePackagesSelection(days);
          }}
          width={240}
          sx={{ marginRight: "24px" }}
        />
        <CustomSelect
          required
          icon={fadedCalenderIcon}
          placeholder={rentalEn.global.SelectReason}
          value={fields?.modificationReason?.description || ""}
          topHeading={rentalEn.bookingManagement.modificationReasons}
          defaultKey={"description"}
          choice={planModificationReasons?.reasons ?? []}
          // disabled={!fields?.selectedBranch?.name}
          handleChange={(reason: any) => {
            handleChange("modificationReason", reason);
          }}
          disabled={isModifyPackagesAndSlotDisabled}
          width={240}
        />
      </Box>
    </Box>
  );
};

export const ModifyPickupAndDropOff = ({
  fields,
  setFields,
  handleChange,
}: any) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const { bookingDetails = {} }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const {
    customerDetails = {},
    id: bookingId,
    deliveryDetails = {},
    returnDetails = {},
    status = "",
  } = bookingDetails;
  useEffect(() => {
    if (bookingId) {
      dispatch(
        getCustomerAddressList({
          customerId: customerDetails?.id,
        })
      );
      if (status === BookingStatusEnum.UPCOMING) {
        setSelectedTab((prev: any) => {
          return deliveryDetails.type === tyesOfModeOfDelivery.BRANCH ? 0 : 1;
        });
        handleChange("modeOfDelivery", deliveryDetails.type);
      } else {
        setSelectedTab((prev: any) => {
          return returnDetails.type === tyesOfModeOfDelivery.BRANCH ? 0 : 1;
        });
        handleChange("modeOfDelivery", returnDetails.type);
      }
    }
  }, [bookingId]);

  const tabArray = [
    {
      key: "SelfPickup",
      label:
        bookingDetails?.status === BookingStatusEnum.UPCOMING
          ? rentalEn?.bookingManagement?.selfPickup
          : rentalEn?.bookingManagement?.selfDropOff,
      selectedTab: selectedTab,
    },
    {
      key: "HomeDelivery",
      label:
        bookingDetails?.status === BookingStatusEnum.UPCOMING
          ? rentalEn?.bookingManagement?.homeDelivery
          : rentalEn?.bookingManagement?.homePickup,
      selectedTab: selectedTab,
    },
  ];
  const handleTabChange = () => {
    setSelectedTab((prev: any) => {
      return prev === 0 ? 1 : 0;
    });
    setFields((prev: any) => ({
      ...prev,
      modeOfDelivery:
        selectedTab === 1
          ? tyesOfModeOfDelivery.BRANCH
          : tyesOfModeOfDelivery.CUSTOMER_LOCATION,
      addressId: selectedTab === 0 ? "" : fields.addressId || "",
    }));
  };

  return (
    <Box>
      <Typography variant={typographyConstants.HEADING_NO_PADDING}>
        {status === BookingStatusEnum.UPCOMING
          ? rentalEn?.bookingManagement?.deliveryOptions
          : rentalEn?.bookingManagement?.dropOffOptions}
      </Typography>
      <CustomButtonGroup
        tabsArray={tabArray}
        handleTabChange={handleTabChange}
      />

      {selectedTab === 0 ? (
        <SelfPickupComponent bookingDetails={bookingDetails} />
      ) : (
        <SelectHomeDeliveryComponent
          fields={fields}
          handleChange={handleChange}
          bookingDetails={bookingDetails}
        />
      )}
    </Box>
  );
};

// self pickup card component
const SelfPickupComponent = ({ bookingDetails }: any) => {
  const { branchDetails = {}, status } = bookingDetails;

  return (
    <Box>
      <Typography variant={typographyConstants.HEADING}>
        {status === BookingStatusEnum.UPCOMING
          ? rentalEn.bookingManagement.pickupLocation
          : rentalEn.bookingManagement.dropOffLocation}
      </Typography>
      <Box marginTop={"10px"}>
        <Card
          sx={{
            width: "300px",
            padding: "12px",
            display: "flex",
            borderRadius: "8px",
            flexDirection: "column",
            border: "1.5px solid #01353d",
          }}
        >
          <Box>
            <img src={WarehouseIcon} alt="wareHouseIcon" />
          </Box>
          <Stack gap="5px">
            <Typography
              variant={typographyConstants.SUBHEADING}
              color={colors.text_secondary_gray}
            >
              {branchDetails?.displayName ?? ""}
            </Typography>
            <Stack>
              <Typography
                variant={typographyConstants.BODY}
                color={colors.tertiary_black}
              >
                {branchDetails?.address?.addressLine1 ?? ""}
              </Typography>
              <Typography
                variant={typographyConstants.BODY}
                color={colors.tertiary_black}
              >
                {`${branchDetails?.address?.cityDisplayName ?? ""} , ${
                  branchDetails?.address?.stateDisplayName ?? ""
                }`}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};

// home delivery card and add address component
const SelectHomeDeliveryComponent = ({
  fields,
  handleChange,
  bookingDetails,
}: any) => {
  const containerRef = useRef<any>();
  const { availableModels, slotPauseDates, modelDetails, customerAddressList } =
    useSelector((state: RootState) => state.newManualBookingReducer);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const {
    customerDetails = {},
    deliveryDetails,
    returnDetails,
    id: bookingId,
    status,
  } = bookingDetails;

  const toggleAddAddressModal = () => {
    setAddAddressModal((prev: any) => !prev);
  };

  // sorted Adress on basis on default address
  const sortedAddresses = [...(customerAddressList?.addresses || [])].sort(
    (a, b) => b.default - a.default
  );

  // scroll Home delivery Cards from icons
  const scrollHomeDeliveryCards = (value: any) => {
    containerRef.current.scrollBy({ left: value, behavior: "smooth" });
  };

  const isAddressSelected = (value: any) => {
    if (!fields.addressId && status === BookingStatusEnum.UPCOMING)
      return deliveryDetails?.address?.id === value.id;
    if (!fields.addressId && status === BookingStatusEnum.ONGOING)
      return returnDetails?.address?.id === value.id;
    return fields?.addressId === value.id;
  };

  return (
    <Stack gap="10px" sx={{ overflow: "hidden" }}>
      <Box>
        {sortedAddresses.length > 0 ? (
          // Address Selection
          <Box className={Styles.addressSelection}>
            <Typography variant={typographyConstants.HEADING}>
              {rentalEn.bookingManagement.SelectAddress}
            </Typography>

            <CustomButton
              startImageSrc={PlusIcon}
              label={rentalEn.bookingManagement.NewAddress}
              variant="outlined"
              sx={{
                padding: "0px 26px",
                display: "flex",
              }}
              onClick={toggleAddAddressModal}
            />
          </Box>
        ) : (
          <Stack>
            {/* Render Input */}
            <Typography variant={typographyConstants.HEADING}>
              {rentalEn.bookingManagement.EnterDeliveryLocation}
            </Typography>
            <Input
              icon={SearchIcon}
              value={fields?.selectedDeliveryLocation}
              placeholder={rentalEn.bookingManagement.SearchForStreetArea}
              onClick={toggleAddAddressModal}
            />
          </Stack>
        )}
      </Box>

      <Box>
        {sortedAddresses.length > 3 && (
          <Box className={Styles.scrollBox}>
            <img
              src={LeftScrollIcon}
              onClick={() => scrollHomeDeliveryCards(-200)}
            />
            <img
              src={RightScrollIcon}
              onClick={() => scrollHomeDeliveryCards(200)}
            />
          </Box>
        )}
        <Box className={Styles.CardListContainer} ref={containerRef}>
          {customerAddressList?.isLoading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            sortedAddresses.map((address: any, index: any) => (
              <HomeDeliveryAddressCard
                key={address.id}
                selectedAddress={isAddressSelected(address)}
                address={address}
                onClick={() => handleChange("addressId", address.id)}
              />
            ))
          )}
        </Box>
      </Box>

      {/* Add Address modal */}
      <HomeDeliveryModal
        openModal={addAddressModal}
        handleModalClose={toggleAddAddressModal}
        setOpenModal={setAddAddressModal}
        userDetails={{ id: customerDetails?.id }}
      />
    </Stack>
  );
};
