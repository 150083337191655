import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { colors } from "../themes/colors";

interface TabsProps {
  defaultActive?: number;
  tabsList: Array<string>;
  width?: number | string;
  setActiveTab?: any;
}
function SelectTabs({
  defaultActive,
  tabsList,
  width,
  setActiveTab,
}: TabsProps) {
  const [active, setActive] = useState<number>(defaultActive || 0);
  useEffect(() => {
    setActive(defaultActive || 0);
  }, [defaultActive]);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  const handleTabChange = (i: number) => {
    setActive(i);
    setActiveTab(i);
  };
  return (
    <Box
      sx={{
        // display: "flex",
        // bgcolor: 'red',
        // flexWrap: 'wrap',
        // justifyContent: "space-between",
        marginTop: "20px",
        overflow: "hidden",
        padding: isDesktopOrTablet ? "0px 15px" : "0px 0px",
      }}
    >
      {tabsList.map((each: any, i) => (
        <Grid
          key={i}
          onClick={() => handleTabChange(i)}
          // className={`tabSelect ${active === i ? "tabSelect-active" : ""}`}
          className={`tabSelect`}
          style={{ width: width || `23%`, marginTop: 3, marginBottom: 10 }}
        >
          <Tooltip title={each?.taskName} arrow>
            <Typography
              variant="h5"
              style={{
                color: colors.card_body_text,
                fontSize: isDesktopOrTablet ? "14px" : "12px",
                fontFamily: "EuclidCircularB-Regular",
                fontStretch: "normal",
                textAlign: "left",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "60%",
              }}
            >
              {each.taskName}
            </Typography>
          </Tooltip>
          <Typography
            variant="h5"
            style={{
              color: colors.card_body_text_second,
              fontSize: isDesktopOrTablet ? "14px" : "12px",
              fontFamily: "EuclidCircularB-Regular",
              fontStretch: "normal",
              textAlign: "right",
              wordBreak: "keep-all",
              width: "40%",
            }}
          >
            <FontAwesomeIcon icon={faClock} color={colors.card_body_text} />{" "}
            {each.sla} min
          </Typography>
        </Grid>
      ))}
    </Box>
  );
}

export { SelectTabs };
