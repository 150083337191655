import { Box, Skeleton, Stack } from "@mui/material";

const SelfieModalLoader = () => {
  return (
    <Stack
      gap="5px"
      padding={"10px"}
      height="-webkit-fill-available"
      justifyContent={"space-between"}
      alignItems={"space-between"}
    >
      <Stack gap="5px">
        {/* For attachments */}
        <Skeleton
          variant="rectangular"
          sx={{ transform: "none !important" }}
          width="100%"
          height={"20vh"}
        />
        <Skeleton
          variant="rectangular"
          sx={{ transform: "none !important" }}
          width="100%"
          height={"20vh"}
        />
      </Stack>

      {/* Button */}
      <Skeleton
        variant="rectangular"
        sx={{ transform: "none !important" }}
        width="100%"
      />
    </Stack>
  );
};

export default SelfieModalLoader;
