import * as actionTypes from "../actionTypes";

interface CheckUserInterface {
  mobile_number: string;
}

interface ChangeCheckUserInterface {
  checkUserDetails: any;
}

interface GetModelLocationList {
  start_date?: string;
  vehicle_model_id?: string | number;
  end_date?: string;
  city_id?: string | number;
  back_date_booking?: any;
  source_id?: string | number;
}

export const checkUserExistFreedoX = (payload: CheckUserInterface) => ({
  type: actionTypes.MANUAL_BOOKING.CHECK_USER_EXIST_FREEDO_X_REQUESTED,
  payload,
});

export const changeUserExist = (payload: ChangeCheckUserInterface) => ({
  type: actionTypes.MANUAL_BOOKING.CHANGE_USER_EXIST_STATUS,
  payload,
});

export const refreshManualBooking = () => ({
  type: actionTypes.MANUAL_BOOKING.REFRESH_MANUAL_BOOKING,
});

export const addUserX = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING.ADD_USER_REQUESTED,
  payload,
});

export const setUserSelectedFieldsX = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING.SET_SELECTED_FIELDS_X,
    payload,
  };
};

export const getCalculatedCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_CHARGES_X_ACTIONS.FETCH_CHARGES_X_REQUESTED,
    payload,
  };
};

export const createManualBookingX = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING.CREATE_MANUAL_BOOKING_REQUESTED_X,
  payload,
});

export const clearCheckUserDetailsX=()=>({
  type:actionTypes.CLEAR_DATA_ACTIONS_TYPE_X.ClEAR_CHECK_USER_DEATILS_X,
})

export const userPaymentMandateX=(payload:any)=>({
  type:actionTypes.MANUAL_BOOKING.USER_PAYMENT_MANDATE_X_REQUESTED,
  payload
})

export const cancelDuplicateManualBookingX = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_REQUESTED_X,
  payload,
});

export const closeDuplicateBookingModalX=(payload?:any)=>({
  type:actionTypes.MANUAL_BOOKING.CLEAR_DUPLICATE_BOOKING_MODAL_X,
  payload
})