import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../../../redux/reducers";
import StaticCircularProgress from "../../../../sharedComponents/atoms/ProgessBar/StaticCircularProgress";
import ImagePreviewModal from "../../../../sharedComponents/molecules/Modals/ImagePreviewModal";
import ResponsiveConfirmationDialog from "../../../../sharedComponents/molecules/Modals/ResponsiveConfirmationDialog";
import CustomerDetailsLoader from "../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/CustomerDetailsLoader";
import KycUploadLoader from "../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/KycUploadLoader";
import SideDrawer from "../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../themes/colors";
import {
  BookingStatusEnum,
  CustomerStatus,
  DisplayEmergencyContactRelationShip,
  KycTypes,
  StatusArray,
  typographyConstants,
} from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
import {
  addEmergencyContactDetails,
  blockCustomer,
  clearIndividualKycDetails,
  deleteCustomerKycType,
  deletecustomer,
  getCustomerBookingList,
  getCustomerBookingStats,
  getCustomerKycTrustScore,
  getCustomerProfileData,
  getEmergencyContactForCustomer,
  getIndividualKycDetails,
  manuallyVerifyKyc,
  moveKYCDocumentToS3Folder,
  resendEmergencyCustomerOTP,
  resetDocUploadSuccessFlag,
  resetEmergencyOtpFlag,
  resetKycDetailsPayload,
  unblockCustomer,
  unverifyCustomerKyc,
  verifyEmergencyContactOTP,
} from "../../../redux/actions/customerManagementActions";
import {
  dateFormat,
  isAlphabetWithSpacesString,
  validate,
} from "../../../utils/helper";
import { checkAlfaNumeric, checkPhone } from "../../../utils/regex";
import AddEmergencyModalUi from "../component/AddEmergencyModalUi";
import AddEmergencyOtpUi from "../component/AddEmergencyOtpUi";
import CustomerProfileCard from "../component/CustomerProfileCard";
import KycTrustScoreChips from "../component/KycTrustScoreChips";
import SearchCustomer from "../component/SearchCustomer";
import { AdhaarModal } from "./section/AadharModal";
import { AddressModal } from "./section/AddressModal";
import { BookingDetails } from "./section/BookingDetails";
import { DLTrustScoreModal } from "./section/DLModal";
import { NameModal } from "./section/NameModal";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import {
  tooltipAnimation,
  dlChipIcon,
  aadharChipIcon,
  otherDocsChipIcon,
  addressChipIcon,
  selfieChipIcon,
} from "../../../constants/exportImages";
import styles from "./section/section.module.css";
import ResponsiveDialog from "../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import { OthersDocumentModal } from "./section/OtherDocumentsModal";
import { SelfieTrustScoreModal } from "./section/SelfieTrustScoreModal";
import RbackHelper from "../../../../utils/helperRBAC";
import {
  CustomerFunctionalities,
  modules,
} from "../../../../constants/RBACModuleEnums";
import { AccessDenied, WrongUrl } from "../../../../screens";
import { toastr } from "react-redux-toastr";
import CustomerOtpLoader from "../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/CustomerOtpLoader";

interface genericObj {
  [key: string]: any;
}
interface EmergencyUserFields {
  mobileNumber: string | number;
  name: string;
  relationship: genericObj;
}
interface EmergencyUserErrorFields {
  mobileNumber: string | number;
  name: string;
  relationship: string;
  otp: string;
}
interface dlOcrFieldsType {
  dlNumber: string;
  dob: string;
  // inputDob: Date;
  inputDob: Date | string;
  ocr: boolean;
  error: any;
}
enum KycModalsDrawers {
  SELFIE = "selfieTrustScore",
  DRIVING_LICENCE = "dlTrustScore",
  OTHER_DOCUMENT = "otherDocsTrustScore",
  AADHAAR = "aadhaarTrustScore",
  nameTrustScore = "nameTrustScore",
  addressTrustScore = "addressTrustScore",
}
const OtherDocuments = [
  "VOTER_ID",
  "BANK_DETAIL",
  "ELECTRICITY_BILL",
  "RENT_BILL",
  "PASSPORT",
  "OTHER",
];
const initialStateEmergencyUser: EmergencyUserFields = {
  mobileNumber: "",
  name: "",
  relationship: {
    name: "",
    displayName: "",
  },
};
const errorInitialStateEmergencyUser: EmergencyUserErrorFields = {
  mobileNumber: "",
  name: "",
  relationship: "",
  otp: "",
};
const dlOcrFieldsInitial: dlOcrFieldsType = {
  dlNumber: "",
  dob: "",
  // inputDob: new Date(),
  inputDob: "",
  ocr: false,
  error: {
    dlNumber: "",
    dob: "",
  },
};
const dlOcrFieldsErrorInitial: any = {
  dob: "",
  dlNumber: "",
};
const pdfExtensions = ["PDF", "pdf"];

const { CustomerManagement } = en;
const { displayBookingStatus } = CustomerManagement;

const CustomerDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // destructered data from reducer
  const {
    searchRandomValue,
    customerDetailsLoader,
    customerProfileData,
    customerEmergencyContacts,
    kycTrustScoreData,
    blockedCustomerLoader,
    unblockedCustomerLoader,
    deleteCustomerLoader,
    emergencyOtpData,
    isEmergencyOtpSent,
    isEmergencyOtpResent,
    isEmergencyOTPInvalid,
    isEmergencyOTPMatched,
    emergencyContactLoader,
    bookingStats,
    bookingList,
    bookingListLoader,
    kycDetailsPayloadObj,
    individualKycDetailsData,
    individualKycDetailsLoader,
    docUploadSuccess,
    trustScoreLoader,
    deleteKycLoader,
    unverifyKycLoader,
    kycModalLoader,
    closeEmergencyModal,
    checkUserLoader,
    verifyEmergencyOtpLoader,
  } = useSelector((state: RootState) => state.customerManagementReducer);

  // page level state
  const [dlOcrFields, setOcrFields] = useState(dlOcrFieldsInitial);
  const [dlOcrErrorFields, setDlOcrErrorFields] = useState(
    dlOcrFieldsErrorInitial
  );
  const [emergencyUserFields, setEmergencyUserFields] = useState(
    initialStateEmergencyUser
  );
  const [errorEmergencyFields, setErrorEmergencyFields] = useState(
    errorInitialStateEmergencyUser
  );
  const [showAddEmergencyDrawer, setShowAddEmergencyDrawer] = useState(false);
  const [showBlockConfirmationModal, setShowBlockConfirmationModal] =
    useState(false);
  const [showUnblockConfirmationModal, setShowUnblockConfirmationModal] =
    useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showEmergencyOTPModal, setShowEmergencyOTPModal] = useState(false);
  const [emergencyOtp, setEmergencyOtp] = useState("");
  const [showEmergencyResetOtpBtn, setShowEmergencyResetOtpBtn] =
    useState(false);
  const [tableFilters, setTableFilters] = useState({
    paginationModel: { page: 0, pageSize: 10 },
    status: StatusArray[0],
  });

  const [openKYCModal, setOpenKYCModal] = useState({
    selfieTrustScore: false,
    dlTrustScore: false,
    nameTrustScore: false,
    otherDocsTrustScore: false,
    addressTrustScore: false,
    aadhaarTrustScore: false,
  });
  // for preview modal
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [previewModalData, setPreviewModalData] = useState<object>({});
  const [imgPreviewError, setImgPreviewError] = useState<boolean>(false);

  // For Future use - RBAC
  const customerStatus = customerProfileData?.status;
  const isCustomerDeleted =
    customerProfileData?.status === CustomerStatus.DELETED;
  const isCustomerBlocked =
    customerProfileData?.status === CustomerStatus.BLOCKED;
  const isFunctionalityBlocked = isCustomerDeleted || isCustomerBlocked;
  const isKycDeletePermissionEnabled = RbackHelper.isAccessRightsProvided(
    modules.CUSTOMER_MANAGEMENT,
    CustomerFunctionalities.DELETE_USER_KYC
  );

  if (isCustomerDeleted) {
    navigate("/customer-management", { replace: true });
  }

  const handlePreviewModalClose = () => {
    setOpenPreviewModal(false);
  };
  const handleSettingPreviewModalData = (data: any) => {
    setPreviewModalData(data);
  };
  const handleDocsPreviewNavigation = (
    name: string,
    extension: string,
    url: string
  ) => {
    // if (extension === DocumentUploadConstants.PDF_FILE) {
    if (pdfExtensions.includes(extension)) {
      window.open(url);
    } else {
      setOpenPreviewModal(true);
      let obj = {
        name: name,
        url: url,
      };
      setPreviewModalData(obj);
      setImgPreviewError(false);
    }
  };

  // fetch details of each doc's kycId in Other docs modal
  const fetchOtherDocTypesDetails = (type: string, kycId: any) => {
    if (kycId) {
      let payload = {
        id: params.id,
        kycId: kycId,
      };
      dispatch(getIndividualKycDetails(payload));
    } else {
      dispatch(clearIndividualKycDetails());
    }
  };

  // get individual KYC details before opening drawer
  const initiateKycTrustClickHandler = (
    field: string,
    kycId: number | string = ""
  ) => {
    // return false
    // get kyc detail
    if (kycId) {
      let payload = {
        id: params.id,
        kycId: kycId,
      };
      dispatch(getIndividualKycDetails(payload));
    } else {
      dispatch(clearIndividualKycDetails());
      // dispatch(resetKycDetailsPayload());
      let refreshKycDetailsPayloadObj = kycDetailsPayloadObj;
      OtherDocuments.map((otherDocType: string) => {
        refreshKycDetailsPayloadObj[KycTypes.OTHER_DOCUMENT]["details"][
          otherDocType
        ]["attachmentIds"] = [];
      });
      // dispatch(resetOtherDocumentsKycDetailsPayload(refreshKycDetailsPayloadObj));
      dispatch(resetKycDetailsPayload(refreshKycDetailsPayloadObj));
    }
    if (field === "dlTrustScore") {
      setOcrFields(dlOcrFieldsInitial);
    }
    setOpenKYCModal((prevState) => ({ ...prevState, [field]: true }));
  };

  // show reset button
  const showEmergencyResetButtonHandler = () => {
    setShowEmergencyResetOtpBtn(true);
  };

  // toggle emergency drawer
  const toggleAddEmergencyDrawer = () => {
    setShowAddEmergencyDrawer((prev: boolean) => {
      if (prev) {
        setErrorEmergencyFields({
          mobileNumber: "",
          name: "",
          relationship: "",
          otp: "",
        });
        setEmergencyOtp("");
      }
      dispatch(resetEmergencyOtpFlag());
      return !prev;
    });
  };

  // Handle emergency each field change
  const handleEmergencyUserFieldChange = useCallback(
    (field: string, value: any) => {
      if (field === "mobileNumber") {
        const isValid = checkPhone(value);
        if (!isValid && value !== "") return false; // Accepting only number Or empty string
        if (value.length > 10) return false;
      }
      if (field === "name") {
        const isNameValid = isAlphabetWithSpacesString(value);
        if (!isNameValid && value !== "") return false; // Accepting only alphabet Or empty string
        if (value.length > 30) return false;
      }
      setEmergencyUserFields((prevState: any) => {
        if (field === "name") {
          let prevLength = prevState[field]?.length;
          let valueLength = prevState[field]?.length;

          if (
            prevLength &&
            /\s/.test(prevState[field][prevLength - 1]) &&
            /\s/.test(value[valueLength])
          )
            return {
              ...prevState,
            };
        }
        return {
          ...prevState,
          [field]: prevState[field] ? value : value.trim(),
        };
      });
    },
    []
  );

  // toggle Block confirmation modal
  const toggleBlockConfirmationModal = () => {
    setShowUnblockConfirmationModal(false);
    setShowDeleteConfirmationModal(false);
    setShowBlockConfirmationModal((prev) => !prev);
  };

  // toggle Unblock confirmation modal
  const toggleUnblockConfirmationModal = () => {
    setShowBlockConfirmationModal(false);
    setShowDeleteConfirmationModal(false);
    setShowUnblockConfirmationModal((prev) => !prev);
  };

  // toggle Delete confirmation modal
  const toggleDeleteConfirmationModal = () => {
    setShowBlockConfirmationModal(false);
    setShowUnblockConfirmationModal(false);
    setShowDeleteConfirmationModal((prev) => !prev);
  };

  // handle Add emergency contact button click
  const addEmergencyClickHandler = () => {
    setShowAddEmergencyDrawer(true);
  };

  // handle Emergency Otp change
  const handleEmergencyOtpChange = (e: any) => {
    // setEmergencyOtp(e.target.value);
  };

  // Handle Save and verify button click
  const handleSaveAndVerifyEmergencyContactClick = (
    emergencyId: string = ""
  ) => {
    let payloadObj = {
      mobileNumber: emergencyUserFields?.mobileNumber,
      name: emergencyUserFields.name,
      relationship: emergencyUserFields.relationship.name,
    };
    // validate form
    const validatedFields = validate(
      payloadObj,
      errorInitialStateEmergencyUser
    );
    setErrorEmergencyFields({
      ...errorInitialStateEmergencyUser,
      ...validatedFields.errorFields,
    });

    if (!emergencyUserFields.relationship.name) {
      /*  setErrorFields(() => {
        ...errorInitialStateEmergencyUser,
        ...validatedFields.errorFields,
      }); */
    }

    if (validatedFields.error === false) {
      const payload = {
        customerId: params.id,
        emergencyId: emergencyId,
        data: payloadObj,
        existingMobileNumber:
          customerEmergencyContacts.length > 0
            ? customerEmergencyContacts[0].mobileNumber
            : "",
        emergencyStatus:
          customerEmergencyContacts.length > 0
            ? customerEmergencyContacts[0].status
            : "",
      };
      dispatch(addEmergencyContactDetails(payload));

      // setShowEmergencyOTPModal(true);
    }
  };

  // close emergency otp modal
  const handleCloseEmergencyOTPModal = () => {
    setShowEmergencyOTPModal(false);
  };

  // verify Otp - submit emergency otp modal
  const handleEmergencyOtpModalSubmit = (otpValue: any) => {
    if (!otpValue || otpValue.length < 6) {
      setErrorEmergencyFields((prevState: any) => ({
        ...prevState,
        otp: otpValue
          ? en.errorMessages.OtpError
          : en.errorMessages.OtpEmptyError,
      }));
      return false;
    } else {
      setErrorEmergencyFields((prevState: any) => ({
        ...prevState,
        otp: "",
      }));
      const payload = {
        data: {
          token: emergencyOtpData?.token,
          otp: otpValue,
        },
        customerId: params.id,
      };

      // verify emergency otp api
      dispatch(verifyEmergencyContactOTP(payload));
    }
  };

  // Resend otp click handler
  const resendEmergencyOtpClickHandler = (e: any) => {
    e.preventDefault();
    dispatch(
      resendEmergencyCustomerOTP({
        token: emergencyOtpData?.token,
        emergencyContactId: emergencyOtpData?.contactId, // emergency contact id
      })
    );
    // reset otp error field
    setErrorEmergencyFields((prevState: any) => ({
      ...prevState,
      otp: "",
    }));
    // reset otp
    setEmergencyOtp("");
    setShowEmergencyResetOtpBtn(false);
  };

  // below functions shows confirmation modal for customer action
  const handleBlockButtonClick = () => {
    toggleBlockConfirmationModal();
  };
  const handleDeleteButtonClick = () => {
    toggleDeleteConfirmationModal();
  };
  const handleUnblockButtonClick = () => {
    toggleUnblockConfirmationModal();
  };

  // handle block customer confirmation
  const handleBlockCustomerConfirmation = () => {
    dispatch(
      blockCustomer({
        id: params.id,
      })
    );
    toggleBlockConfirmationModal();
  };

  // handle unblock customer confirmation
  const handleUnblockCustomerConfirmation = () => {
    dispatch(
      unblockCustomer({
        id: params.id,
      })
    );
    toggleUnblockConfirmationModal();
  };

  // handle delete customer confirmation
  const handleDeleteCustomerConfirmation = () => {
    dispatch(
      deletecustomer({
        id: params.id,
        navigate,
      })
    );
    toggleDeleteConfirmationModal();

    // toggleAddEmergencyDrawer();
  };

  // handle DL Ocr fields change
  const handleDLOcrFieldsChange = (field: string, value: any) => {
    if (field === "dob") {
      // value = dateFormat(value);
      setOcrFields((prev: any) => ({
        ...prev,
        [field]: dateFormat(value),
        inputDob: new Date(value),
        // inputDob: dateFormat(value),
      }));
      // setDate(value);
    }
    if (field === "dlNumber") {
      if (value.length > 21) {
        return false;
      } else {
        setOcrFields((prev: any) => ({ ...prev, [field]: value }));
      }
    }
    // setOcrFields((prev: any) => ({ ...prev, [field]: value }));
  };

  const checkDLOcrValidationError = () => {
    let errorFlag = false;
    if (!dlOcrFields.ocr) {
      return false;
    }
    if (!dlOcrFields.dlNumber) {
      setOcrFields((prev: any) => ({
        ...prev,
        error: {
          ...prev.error,
          dlNumber: en.errorMessages.OcrDL,
        },
      }));
      errorFlag = true;
      // return true;
    }
    if (!dlOcrFields.dob) {
      setOcrFields((prev: any) => ({
        ...prev,
        error: { ...prev.error, dob: en.errorMessages.OcrDob },
      }));
      errorFlag = true;
      // return true;
    }
    return errorFlag;
    setOcrFields(dlOcrFieldsInitial);
  };

  // File upload to S3
  const handleSaveAndVerifyUploadToS3 = (
    Kyctype: string,
    verifyDigitally: boolean = false
  ) => {
    let moveS3KycPayload = {};
    if (Kyctype === KycTypes.DRIVING_LICENCE) {
      let dlkycPayload = kycDetailsPayloadObj[Kyctype];
      if (dlOcrFields.ocr) {
        dlkycPayload.details.ocr = !dlOcrFields.ocr;
        dlkycPayload.details.dlNumber = dlOcrFields.dlNumber;
        dlkycPayload.details.dob = dlOcrFields.dob;
      } else {
        dlkycPayload.details.ocr = !dlOcrFields.ocr;
        delete dlkycPayload.details.dlNumber;
        delete dlkycPayload.details.dob;
      }
      if (dlOcrFields.ocr) {
        if (checkDLOcrValidationError()) {
          return false;
        }
      }
      moveS3KycPayload = {
        customerId: params.id,
        kycPayload: dlkycPayload,
      };
    } else if (Kyctype === KycTypes.AADHAAR) {
      let aadharkycPayload = kycDetailsPayloadObj[Kyctype];
      if (verifyDigitally) {
        aadharkycPayload.details.callbackUrl = window.location.href;
        aadharkycPayload.details.verifyDigitally = verifyDigitally;
        delete aadharkycPayload.details.frontImageAttachmentId;
        delete aadharkycPayload.details.backImageAttachmentId;
      } else {
        aadharkycPayload.details.verifyDigitally = verifyDigitally;
        delete aadharkycPayload.details.callbackUrl;
      }
      moveS3KycPayload = {
        customerId: params.id,
        kycPayload: aadharkycPayload,
      };
    } else {
      moveS3KycPayload = {
        customerId: params.id,
        kycPayload:
          kycDetailsPayloadObj[KycTypes[Kyctype as keyof typeof KycTypes]],
      };
    }
    dispatch(moveKYCDocumentToS3Folder(moveS3KycPayload));

    setTimeout(() => {
      toggleKYCDrawer(
        KycModalsDrawers[Kyctype as keyof typeof KycModalsDrawers]
      );
    });
  };

  // Handle Clear/delete customer kyc
  const handleDeleteCustomerKyc = (Kyctype: string, kycId: string) => {
    if (Kyctype && kycId) {
      const deleteKycPayload = {
        customerId: params.id,
        kycId: kycId,
        Kyctype: Kyctype,
      };
      dispatch(deleteCustomerKycType(deleteKycPayload));

      // Avoid modal close in case of OTHER_DOCUMENT
      if (Kyctype !== KycTypes.OTHER_DOCUMENT) {
        setTimeout(() => {
          toggleKYCDrawer(
            KycModalsDrawers[Kyctype as keyof typeof KycModalsDrawers]
          );
        });
      }
    } else {
    }
  };

  // Handle unverify customer kyc
  const handleUnverifyCustomerKyc = (Kyctype: string, kycId: string) => {
    if (Kyctype && kycId) {
      const unverifyKycPayload = {
        customerId: params.id,
        kycId: kycId,
        Kyctype: Kyctype,
      };
      dispatch(unverifyCustomerKyc(unverifyKycPayload));
      setTimeout(() => {
        toggleKYCDrawer(
          KycModalsDrawers[Kyctype as keyof typeof KycModalsDrawers]
        );
      });
    }
  };

  // Handle manual verification of kyc
  const handleManualKycVerification = (Kyctype: string, kycId: string) => {
    if (Kyctype && kycId) {
      const manualVerificationPayload = {
        customerId: params.id,
        kycId: kycId,
        Kyctype: Kyctype,
      };
      dispatch(manuallyVerifyKyc(manualVerificationPayload));
      setTimeout(() => {
        toggleKYCDrawer(
          KycModalsDrawers[Kyctype as keyof typeof KycModalsDrawers]
        );
      });
    }
  };

  // File upload to S3 for Other documents
  const handleSaveAndVerifyUploadToS3OtherDocs = (Kyctype: string) => {
    let OtherDocumentObj = kycDetailsPayloadObj.OTHER_DOCUMENT;
    let detailsArr: any = [];
    let payload = OtherDocuments.map((otherDoctype: any) => {
      let attachmentArr =
        OtherDocumentObj["details"][otherDoctype]["attachmentIds"];
      if (attachmentArr.length > 0) {
        detailsArr.push(OtherDocumentObj["details"][otherDoctype]);
      }
    });
    let otherDocKycPayload = {
      type: Kyctype,
      details: detailsArr,
    };
    let moveS3KycPayload = {
      customerId: params.id,
      kycPayload: otherDocKycPayload,
    };
    dispatch(moveKYCDocumentToS3Folder(moveS3KycPayload));
    setTimeout(() => {
      toggleKYCDrawer(
        KycModalsDrawers[Kyctype as keyof typeof KycModalsDrawers]
      );
    });
  };

  // Trust scores
  const totalTrustScore = kycTrustScoreData?.trustScore ?? 0;
  const selfieTrustScore =
    kycTrustScoreData?.trustScoreFactor?.selfieTrustScore ?? {};
  const dlTrustScore = kycTrustScoreData?.trustScoreFactor?.dlTrustScore ?? {};
  const aadhaarTrustScore =
    kycTrustScoreData?.trustScoreFactor?.aadhaarTrustScore ?? {};
  const addressTrustScore =
    kycTrustScoreData?.trustScoreFactor?.addressTrustScore ?? {};
  const otherDocsTrustScore =
    kycTrustScoreData?.trustScoreFactor?.otherDocsTrustScore ?? {};
  const nameTrustScore =
    kycTrustScoreData?.trustScoreFactor?.nameTrustScore ?? {};

  const handlePaginationModelChange = (val: any) => {
    const { page, pageSize } = val;
    setTableFilters((prevState: any) => ({
      ...prevState,
      paginationModel: val,
    }));
    dispatch(
      getCustomerBookingList({
        page: page + 1,
        pageSize: pageSize,
        customerId: params.id,
        customerSort: true,
      })
    );
  };

  const handleStatusFilterChange = (status: any) => {
    setTableFilters((prevState: any) => ({
      ...prevState,
      status: status,
    }));
    dispatch(
      getCustomerBookingList({
        page: tableFilters.paginationModel?.page + 1,
        pageSize: tableFilters?.paginationModel?.pageSize,
        customerId: params.id,
        status: status.name !== "ALL" ? status.name : "",
        customerSort: true,
      })
    );
  };

  const toggleKYCDrawer = (field: string) => {
    setOpenKYCModal((prevState) => {
      if (!prevState.dlTrustScore && field === "dlTrustScore") {
        setOcrFields(dlOcrFieldsInitial);
      }
      return {
        ...prevState,
        [field]: false,
      };
    });
  };

  const handleBackButtonClick = () => {
    dispatch(resetEmergencyOtpFlag());
    setEmergencyOtp("");
  };

  // DL
  const handleOcrToggle = (event: any) => {
    setOcrFields((prev: any) => {
      return { ...prev, ocr: !prev.ocr };
    });
  };

  const fetchCustomerKycTrustScore = () => {
    // get kyc trust score
    dispatch(
      getCustomerKycTrustScore({
        id: params.id,
      })
    );
  };

  // page level lifecycle
  useEffect(() => {
    if (
      RbackHelper.isAccessRightsProvided(
        modules.CUSTOMER_MANAGEMENT,
        CustomerFunctionalities.VIEW_CUSTOMER_DETAILS
      )
    ) {
      // get profile data
      dispatch(
        getCustomerProfileData({
          id: params.id,
        })
      );
      // get emergency contact
      dispatch(
        getEmergencyContactForCustomer({
          id: params.id,
        })
      );
      // get kyc trust score
      dispatch(
        getCustomerKycTrustScore({
          id: params.id,
        })
      );
      //
      dispatch(
        getCustomerBookingStats({
          customerId: params.id,
        })
      );

      dispatch(
        getCustomerBookingList({
          page: 1,
          pageSize: 10,
          customerId: params.id,
          customerSort: true,
        })
      );
    }

    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.CUSTOMER_DETAILS));

    // show toastr if KYCKART denied
    if (
      searchParams.get("status") === "PENDING" ||
      searchParams.get("verificationStatus") === "PENDING"
    ) {
      toastr.warning(en.alertMessages.deniedAadharKyckart, "");
    }

    // cleanup
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, [searchRandomValue, params.id]);

  // close emergency drawer if otp verified
  useEffect(() => {
    if (isEmergencyOTPMatched || closeEmergencyModal) {
      setShowEmergencyOTPModal(false);
      setShowEmergencyResetOtpBtn(false);
      setShowAddEmergencyDrawer(false);
    }
  }, [isEmergencyOTPMatched, closeEmergencyModal]);

  // this is used for pre-filling existing emergency data
  useEffect(() => {
    setEmergencyUserFields((prev) => {
      return {
        // ...prev,
        mobileNumber: customerEmergencyContacts[0]?.mobileNumber
          ? customerEmergencyContacts[0]?.mobileNumber
          : initialStateEmergencyUser.mobileNumber,
        name: customerEmergencyContacts[0]?.name
          ? customerEmergencyContacts[0]?.name
          : initialStateEmergencyUser.name,
        relationship: {
          name: customerEmergencyContacts[0]?.relationship
            ? customerEmergencyContacts[0]?.relationship
            : initialStateEmergencyUser?.relationship?.name,
          displayName: customerEmergencyContacts[0]?.relationship
            ? DisplayEmergencyContactRelationShip[
                customerEmergencyContacts[0]
                  .relationship as keyof typeof DisplayEmergencyContactRelationShip
              ]
            : initialStateEmergencyUser.relationship.displayName,
        },
      };
    });
  }, [customerEmergencyContacts.length]);

  // for refreshing trust score
  useEffect(() => {
    if (docUploadSuccess) {
      dispatch(
        getCustomerKycTrustScore({
          id: params.id,
        })
      );
      dispatch(resetDocUploadSuccessFlag());
    }
  }, [docUploadSuccess]);

  return (
    <>
      {RbackHelper.isAccessRightsProvided(
        modules.CUSTOMER_MANAGEMENT,
        CustomerFunctionalities.VIEW_CUSTOMER_DETAILS
      ) ? (
        customerProfileData?.id ? (
          <>
            <Stack className="ContentWrapper" gap="5vh">
              {/* search customer component */}
              {RbackHelper.isAccessRightsProvided(
                modules.CUSTOMER_MANAGEMENT,
                CustomerFunctionalities.VIEW_CUSTOMER_DETAILS
              ) && (
                <Stack
                  className="TopSection"
                  direction="row"
                  justifyContent="flex-start"
                >
                  <SearchCustomer searchLoader={checkUserLoader} />
                </Stack>
              )}

              {/* screen loader logic */}
              {customerDetailsLoader ? (
                <CustomerDetailsLoader />
              ) : (
                <>
                  {/* Customer Dashboard */}
                  <Stack
                    className="CustomerDetailSection"
                    direction="row"
                    sx={{
                      background: colors.dashboard_wrapper_bg_color,
                      padding: "10px",
                      borderRadius: "4px",
                      boxShadow: "0px 3.88px 19.42px 0px #0000001A",
                    }}
                    gap="0.5vw"
                  >
                    {/* customer card */}
                    <CustomerProfileCard
                      customerProfileData={customerProfileData}
                      customerEmergencyContacts={customerEmergencyContacts}
                      addEmergencyClickHandler={addEmergencyClickHandler}
                      handleBlockButtonClick={handleBlockButtonClick}
                      handleDeleteButtonClick={handleDeleteButtonClick}
                      handleUnblockButtonClick={handleUnblockButtonClick}
                      emergencyContactLoader={emergencyContactLoader}
                      customerDetailsLoader={customerDetailsLoader}
                      isFunctionalityBlocked={isFunctionalityBlocked}
                    />

                    {/* kyc section */}
                    <Stack
                      className="KycSection"
                      sx={{
                        background: colors.card_bg_color,
                        borderRadius: "4px",
                        width: "70%",
                        boxShadow: colors.card_box_shadow_style,
                        height: "240px",
                      }}
                    >
                      {/* Heading */}
                      <Stack
                        className="kycTopHeading"
                        direction="row"
                        sx={{
                          background: colors.card_heading_bg_color,
                          // padding: "2vh",
                          borderRadius: "4px 4px 0 0",
                        }}
                      >
                        <Box width="30%" padding={"2vh"}>
                          <Typography
                            variant={typographyConstants.HEADING_NO_PADDING}
                          >
                            KYC Trust Score
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            border: "0.97px solid #00000014",
                            backgroundColor: "unset",
                          }}
                        />
                        <Stack
                          width="70%"
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          padding={"2vh"}
                        >
                          <Typography
                            variant={typographyConstants.HEADING_NO_PADDING}
                          >
                            KYC Details
                          </Typography>
                          {RbackHelper.isAccessRightsProvided(
                            modules.CUSTOMER_MANAGEMENT,
                            CustomerFunctionalities.VIEW_CUSTOMER_KYC
                          ) && (
                            <CustomIconButton
                              onClick={fetchCustomerKycTrustScore}
                              sx={{ padding: "0" }}
                            >
                              {trustScoreLoader ? (
                                <>
                                  <span className="loaderSpinner"></span>
                                </>
                              ) : (
                                <RefreshIcon />
                              )}
                            </CustomIconButton>
                          )}
                        </Stack>
                      </Stack>

                      {/* Details */}
                      {RbackHelper.isAccessRightsProvided(
                        modules.CUSTOMER_MANAGEMENT,
                        CustomerFunctionalities.VIEW_CUSTOMER_KYC
                      ) ? (
                        <>
                          {trustScoreLoader || kycModalLoader ? (
                            <KycUploadLoader />
                          ) : (
                            <>
                              <Stack
                                className="kycBottomSection"
                                direction="row"
                                sx={{ padding: "10px 0", height: "100%" }}
                              >
                                {/* Total Trust Score */}
                                <Stack
                                  className="kycTrustScore"
                                  width="30%"
                                  sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // gap: "5vh",
                                  }}
                                >
                                  <StaticCircularProgress
                                    percentage={totalTrustScore}
                                  />
                                </Stack>

                                <Divider
                                  sx={{
                                    border: "0.97px solid #00000014",
                                    backgroundColor: "unset",
                                  }}
                                />

                                {/* Each kyc section */}
                                {kycTrustScoreData?.trustScoreFactor && (
                                  <Stack
                                    className="eachKycSections"
                                    direction="row"
                                    gap="1vw"
                                    flexWrap="wrap"
                                    width="70%"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    {/* Selfie */}
                                    <KycTrustScoreChips
                                      trustScore={selfieTrustScore}
                                      initiateClickHandler={() =>
                                        initiateKycTrustClickHandler(
                                          "selfieTrustScore",
                                          selfieTrustScore?.kycId
                                        )
                                      }
                                      label={en.CustomerManagement.Selfie}
                                      chipIcon={
                                        <img
                                          src={selfieChipIcon}
                                          alt="selfieChipIcon"
                                        />
                                      }
                                    />
                                    {/* DL */}
                                    <KycTrustScoreChips
                                      trustScore={dlTrustScore}
                                      initiateClickHandler={() =>
                                        initiateKycTrustClickHandler(
                                          "dlTrustScore",
                                          dlTrustScore?.kycId
                                        )
                                      }
                                      label={en.CustomerManagement.DL}
                                      chipIcon={
                                        <img
                                          src={dlChipIcon}
                                          alt="dlChipIcon"
                                        />
                                      }
                                    />
                                    {/* Aadhar */}
                                    <KycTrustScoreChips
                                      trustScore={aadhaarTrustScore}
                                      initiateClickHandler={() =>
                                        initiateKycTrustClickHandler(
                                          "aadhaarTrustScore",
                                          aadhaarTrustScore?.kycId
                                        )
                                      }
                                      chipIcon={
                                        <img
                                          src={aadharChipIcon}
                                          alt="aadharChipIcon"
                                        />
                                      }
                                      label={en.CustomerManagement.Aadhaar}
                                    />

                                    {/* Address */}
                                    <KycTrustScoreChips
                                      trustScore={addressTrustScore}
                                      initiateClickHandler={() => {
                                        addressTrustScore?.isVerified ||
                                        addressTrustScore?.isPresent
                                          ? initiateKycTrustClickHandler(
                                              "addressTrustScore"
                                            )
                                          : null;
                                      }}
                                      label={en.CustomerManagement.Address}
                                      chipIcon={
                                        <img
                                          src={addressChipIcon}
                                          alt="addressChipIcon"
                                        />
                                      }
                                      isEditable={false}
                                      cursorStyle={
                                        addressTrustScore?.isPresent
                                          ? "pointer"
                                          : "auto"
                                      }
                                    />
                                    {/* Name */}
                                    <KycTrustScoreChips
                                      // trustScore={nameTrustScore}
                                      trustScore={nameTrustScore}
                                      initiateClickHandler={() => {
                                        nameTrustScore?.isVerified ||
                                        addressTrustScore?.isPresent
                                          ? initiateKycTrustClickHandler(
                                              "nameTrustScore"
                                            )
                                          : null;
                                      }}
                                      label={en.CustomerManagement.Name}
                                      // showChipLabel={false}
                                      chipIcon={
                                        <img
                                          src={selfieChipIcon}
                                          alt="selfieChipIcon"
                                        />
                                      }
                                      isEditable={false}
                                      cursorStyle={
                                        addressTrustScore?.isPresent
                                          ? "pointer"
                                          : "auto"
                                      }
                                    />
                                    {/* Other */}
                                    <KycTrustScoreChips
                                      trustScore={otherDocsTrustScore}
                                      initiateClickHandler={() =>
                                        initiateKycTrustClickHandler(
                                          "otherDocsTrustScore"
                                        )
                                      }
                                      label={en.CustomerManagement.Other}
                                      chipIcon={
                                        <img
                                          src={otherDocsChipIcon}
                                          alt="otherDocsChipIcon"
                                        />
                                      }
                                      showChipLabel={false}
                                    />
                                  </Stack>
                                )}
                              </Stack>

                              {/* Notes */}
                              <Stack
                                className="kycNotesSection"
                                direction="row"
                                sx={{
                                  alignItems: "center",
                                  // padding: "7px 0",
                                  height: "100%",
                                }}
                              >
                                <Stack
                                  justifyContent={"flex-start"}
                                  sx={{ width: "30%" }}
                                >
                                  <Typography
                                    variant={typographyConstants.BODYSMALL}
                                    padding="0 1vw"
                                  >
                                    {en.CustomerManagement.KycScoreNotes}
                                  </Typography>
                                </Stack>
                                <Divider
                                  sx={{
                                    border: "0.97px solid #00000014",
                                    backgroundColor: "unset",
                                  }}
                                />
                                <Stack
                                  justifyContent={"flex-end"}
                                  width={"70%"}
                                  flexDirection={"row"}
                                >
                                  <Typography
                                    variant={typographyConstants.BODYSMALL}
                                    padding="0 1vw"
                                  >
                                    <img
                                      src={tooltipAnimation}
                                      alt="tooltip animation"
                                    />
                                    {en.CustomerManagement.kycSectionNotes}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </>
                          )}
                        </>
                      ) : (
                        <AccessDenied />
                      )}
                    </Stack>
                  </Stack>

                  {RbackHelper.isAccessRightsProvided(
                    modules.CUSTOMER_MANAGEMENT,
                    CustomerFunctionalities.VIEW_CUSTOMER_BOOKING
                  ) && (
                    <BookingDetails
                      bookingStats={bookingStats}
                      bookingList={bookingList}
                      handlePaginationModelChange={handlePaginationModelChange}
                      setTableFilters={setTableFilters}
                      tableFilters={tableFilters}
                      handleStatusFilterChange={handleStatusFilterChange}
                      bookingListLoader={bookingListLoader}
                      StatusArray={StatusArray}
                    />
                  )}
                </>
              )}
            </Stack>
          </>
        ) : customerDetailsLoader ||
          emergencyContactLoader ||
          trustScoreLoader ||
          bookingListLoader ? (
          <></>
        ) : (
          <>
            <WrongUrl />
          </>
        )
      ) : (
        <AccessDenied />
      )}

      {/* Add Emergency Drawer */}
      <SideDrawer
        heading={en.CustomerManagement.AddEmergencyTitle}
        disablePadding={true}
        open={showAddEmergencyDrawer}
        toggleDrawer={toggleAddEmergencyDrawer}
        disableEscapeKeyDown
        disableBackdropClick
        renderUI={
          emergencyContactLoader || verifyEmergencyOtpLoader ? (
            <CustomerOtpLoader />
          ) : isEmergencyOtpSent ? (
            <AddEmergencyOtpUi
              customerId={params.id}
              fields={emergencyUserFields}
              setOtp={setEmergencyOtp}
              otpValue={emergencyOtp}
              errorFieldsOtp={errorEmergencyFields?.otp}
              openModal={showEmergencyOTPModal}
              handleOtpModalSubmit={handleEmergencyOtpModalSubmit}
              isOTPInvalid={isEmergencyOTPInvalid}
              ResendOtpClickHandler={resendEmergencyOtpClickHandler}
              isOtpResent={isEmergencyOtpResent}
              showResetOtpBtn={showEmergencyResetOtpBtn}
              showResetButtonHandler={showEmergencyResetButtonHandler}
              handleBackButtonClick={handleBackButtonClick}
              customerEmergencyContacts={customerEmergencyContacts}
              verifyEmergencyOtpLoader={verifyEmergencyOtpLoader}
            />
          ) : (
            <AddEmergencyModalUi
              customerId={params.id}
              customerEmergencyContacts={customerEmergencyContacts}
              emergencyUserFields={emergencyUserFields}
              setEmergencyUserFields={setEmergencyUserFields}
              errorEmergencyFields={errorEmergencyFields}
              setErrorEmergencyFields={setErrorEmergencyFields}
              handleEmergencyUserFieldChange={handleEmergencyUserFieldChange}
              handleSaveAndVerifyEmergencyContactClick={
                handleSaveAndVerifyEmergencyContactClick
              }
              emergencyContactLoader={emergencyContactLoader}
            />
          )
        }
      ></SideDrawer>

      {/* ---------------------- Confirmation modals start ------------------------------ */}

      {/* Block Confirmation modal */}
      <ResponsiveDialog
        title={en.alertMessages.blockCustomer}
        open={showBlockConfirmationModal}
        handleClose={() => toggleBlockConfirmationModal()}
        handleYes={() => handleBlockCustomerConfirmation()}
      />

      {/* Unblock Confirmation modal */}
      <ResponsiveDialog
        title={en.alertMessages.unblockCustomer}
        open={showUnblockConfirmationModal}
        handleClose={() => toggleUnblockConfirmationModal()}
        handleYes={() => handleUnblockCustomerConfirmation()}
      />

      {/* Delete Confirmation modal */}
      <ResponsiveDialog
        title={en.alertMessages.deleteCustomer}
        open={showDeleteConfirmationModal}
        handleClose={() => toggleDeleteConfirmationModal()}
        handleYes={() => handleDeleteCustomerConfirmation()}
      />

      {/* ---------------------- Confirmation modals end ------------------------------ */}

      {/* ---------------------- KYC Modals start ------------------------------------- */}

      {openKYCModal.selfieTrustScore && (
        <SideDrawer
          heading="Upload a Selfie"
          disablePadding={true}
          open={openKYCModal.selfieTrustScore}
          toggleDrawer={() => toggleKYCDrawer("selfieTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <SelfieTrustScoreModal
              customerId={params.id}
              selfieTrustScore={selfieTrustScore}
              selfiePayloadObj={kycDetailsPayloadObj.SELFIE}
              individualKycDetailsData={individualKycDetailsData}
              individualKycDetailsLoader={individualKycDetailsLoader}
              handleSaveAndVerifyUploadToS3={handleSaveAndVerifyUploadToS3}
              handleManualKycVerification={handleManualKycVerification}
              handleDeleteCustomerKyc={handleDeleteCustomerKyc}
              handleUnverifyCustomerKyc={handleUnverifyCustomerKyc}
              handleDocsPreviewNavigation={handleDocsPreviewNavigation}
              isFunctionalityBlocked={isFunctionalityBlocked}
              isKycDeletePermissionEnabled={isKycDeletePermissionEnabled}
            />
          }
        />
      )}

      {openKYCModal.dlTrustScore && (
        <SideDrawer
          heading="Upload Driving License"
          disablePadding={true}
          open={openKYCModal.dlTrustScore}
          toggleDrawer={() => toggleKYCDrawer("dlTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <DLTrustScoreModal
              customerId={params.id}
              dlTrustScore={dlTrustScore}
              dlPayloadObj={kycDetailsPayloadObj.DRIVING_LICENCE}
              individualKycDetailsData={individualKycDetailsData}
              individualKycDetailsLoader={individualKycDetailsLoader}
              handleSaveAndVerifyUploadToS3={handleSaveAndVerifyUploadToS3}
              dlOcrFields={dlOcrFields}
              handleDLOcrFieldsChange={handleDLOcrFieldsChange}
              handleOcrToggle={handleOcrToggle}
              handleManualKycVerification={handleManualKycVerification}
              handleDeleteCustomerKyc={handleDeleteCustomerKyc}
              handleUnverifyCustomerKyc={handleUnverifyCustomerKyc}
              isFunctionalityBlocked={isFunctionalityBlocked}
              isKycDeletePermissionEnabled={isKycDeletePermissionEnabled}
              handleDocsPreviewNavigation={handleDocsPreviewNavigation}
              toogleKycDrawer={() => toggleKYCDrawer("dlTrustScore")}
            />
          }
        />
      )}

      {openKYCModal.aadhaarTrustScore && (
        <SideDrawer
          heading="Upload Aadhaar"
          disablePadding={true}
          open={openKYCModal.aadhaarTrustScore}
          toggleDrawer={() => toggleKYCDrawer("aadhaarTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <AdhaarModal
              customerId={params.id}
              aadhaarTrustScore={aadhaarTrustScore}
              aadhaarPayloadObj={kycDetailsPayloadObj.AADHAAR}
              individualKycDetailsData={individualKycDetailsData}
              individualKycDetailsLoader={individualKycDetailsLoader}
              handleSaveAndVerifyUploadToS3={handleSaveAndVerifyUploadToS3}
              handleManualKycVerification={handleManualKycVerification}
              handleDocsPreviewNavigation={handleDocsPreviewNavigation}
              handleDeleteCustomerKyc={handleDeleteCustomerKyc}
              handleUnverifyCustomerKyc={handleUnverifyCustomerKyc}
              isFunctionalityBlocked={isFunctionalityBlocked}
              isKycDeletePermissionEnabled={isKycDeletePermissionEnabled}
            />
          }
        />
      )}

      {openKYCModal.otherDocsTrustScore && (
        <SideDrawer
          heading="Other Documents"
          disablePadding={true}
          open={openKYCModal.otherDocsTrustScore}
          toggleDrawer={() => toggleKYCDrawer("otherDocsTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <OthersDocumentModal
              customerId={params.id}
              otherDocsTrustScore={otherDocsTrustScore}
              otherPayloadObj={kycDetailsPayloadObj.OTHER_DOCUMENT}
              individualKycDetailsData={individualKycDetailsData}
              individualKycDetailsLoader={individualKycDetailsLoader}
              handleSaveAndVerifyUploadToS3={
                handleSaveAndVerifyUploadToS3OtherDocs
              }
              OtherDocuments={OtherDocuments}
              handleDocsPreviewNavigation={handleDocsPreviewNavigation}
              fetchOtherDocTypesDetails={fetchOtherDocTypesDetails}
              handleDeleteCustomerKyc={handleDeleteCustomerKyc}
              isFunctionalityBlocked={isFunctionalityBlocked}
              isKycDeletePermissionEnabled={isKycDeletePermissionEnabled}
            />
          }
        />
      )}

      {openKYCModal.nameTrustScore && (
        <SideDrawer
          heading="First Name"
          disablePadding={true}
          open={openKYCModal.nameTrustScore}
          toggleDrawer={() => toggleKYCDrawer("nameTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <NameModal
              customerId={params.id}
              nameTrustScore={nameTrustScore}
              namePayloadObj={kycDetailsPayloadObj.Name}
              isFunctionalityBlocked={isFunctionalityBlocked}
            />
          }
        />
      )}

      {openKYCModal.addressTrustScore && (
        <SideDrawer
          heading="Address"
          disablePadding={true}
          open={openKYCModal.addressTrustScore}
          toggleDrawer={() => toggleKYCDrawer("addressTrustScore")}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          renderUI={
            <AddressModal
              customerId={params.id}
              addressTrustScore={addressTrustScore}
              addressPayloadObj={kycDetailsPayloadObj.Address}
              isFunctionalityBlocked={isFunctionalityBlocked}
            />
          }
        />
      )}

      {/* Image Preview modal */}
      <ImagePreviewModal
        openModal={openPreviewModal}
        handleModalClose={handlePreviewModalClose}
        modalData={previewModalData}
        imgError={imgPreviewError}
        setImgError={setImgPreviewError}
        showDownloadButton={openKYCModal.otherDocsTrustScore}
      />
      {/* ----------------------- KYC Modals end -------------------------------------- */}
    </>
  );
};

export default CustomerDetails;
