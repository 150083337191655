import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import {
  filterStorageKeys,
  initialPaginationTable,
  iotDeviceTypesArray,
  iotStatusArray,
} from "../../../../constants/constants";
import {
  CityInputIcon,
  AllVendorsIcon,
  BikeIconFaded,
  DeviceTypeIcon,
  LocationIcon,
  StatusIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import {
  GetIOTDevicePayload,
  IotFilters,
} from "../../../../interfaces/iotManagementInterface";
import en from "../../../../locale/rental-en.json";
import { iotActions, rentalActions } from "../../../../redux/actions";
import { checkAlfaNumeric, checkWhiteSpace } from "../../../../utils/regex";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { StyledObject } from "../styleObject";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  modules,
  iotManagementFunctionality,
} from "../../../../../constants/RBACModuleEnums";

const { isAccessRightsProvided } = RbackHelper;

enum iotStatusColors {
  AVAILABLE = "#1BC47D",
  INACTIVE = "red",
  ENGAGED = "#098082",
}

// locale data
const {
  IotManagement: { DisplayDeviceStatus, DisplayDeviceType, ...IotLocaleData },
  global,
  buttonLabels,
} = en;

// initial values for filters
const initialState: IotFilters = {
  selectedCity: {
    name: "",
    displayName: global.AllCities,
  },
  selectedBranch: {
    name: "",
    displayName: global.AllBranches,
  },
  selectedDeviceType: {
    name: "",
    displayName: global.AllDeviceTypes,
  },
  selectedIOTStatus: {
    name: "",
    displayName: global.AllStatus,
  },
  selectedIotModel: {
    displayName: global.AllModels,
    name: "",
  },
  selectedVendor: {
    _id: 0,
    name: "",
    models: [],
    displayName: global.AllVendors,
  },
  searchById: "",
  page: 0,
  pageSize: 10,
};

const IotDashboard = () => {
  // references
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isViewIotList: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.IOT_MANAGEMENT_LIST
    ),
    isAddIot: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.ADD_DEVICE
    ),
    isBulkUploadIot: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.DEVICE_BULK_ACTIONS
    ),
    isViewIotDetails: isAccessRightsProvided(
      modules.IOT_MANAGEMENT,
      iotManagementFunctionality.VIEW_DEVICE
    ),
  };

  // iot data from iot management reducer
  const {
    iotList: { iotDetails, pagination: paginationData },
    vendorsList,
    getIotListLoader,
  } = useSelector((state: RootState) => state.rentalsIotManagementReducer);

  // master data from commo reducer
  const { allCitiesDetails = [], branches } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // state to store filters
  const [filters, setFilters] = useState<IotFilters>({ ...initialState });

  // get local storage data
  const getLocalStorageData = () => {
    return getFilterDataFromLocalStorage(
      filterStorageKeys.IOT_MANAGEMENT_FILTER_DATA
    );
  };

  // initial state data load
  useEffect(() => {
    async function getData() {
      dispatch(iotActions.getAllVendors());

      const localStorageData = (await getLocalStorageData()) ?? {};
      if (Object?.keys(localStorageData)?.length) {
        let localdata: IotFilters = { ...localStorageData };
        setFilters((prev: IotFilters) => ({ ...prev, ...localdata }));
        getIotList({ ...localdata, page: 0, pageSize: 10 });
        if (localStorageData.selectedCity.name)
          getBranches(localStorageData.selectedCity.name);
      } else getIotList({ ...initialState });
    }
    getData();
  }, []);

  // side effect for load city and branches data from local storage by calling api and filtering api data
  useEffect(() => {
    if (
      branches.length &&
      allCitiesDetails.length &&
      filters.selectedCity?.name &&
      filters.selectedBranch?.name
    ) {
      let data: IotFilters = { ...filters };

      data.selectedBranch = branches?.filter(
        (data: any) => data.name === filters.selectedBranch.name
      )[0];

      setFilters((prev: IotFilters) => ({ ...prev, ...data }));
    }
  }, [branches]);

  // get api  call for iot listing
  const getIotList = (data: IotFilters) => {
    const { page, pageSize, ...rest } = data;

    let payload: GetIOTDevicePayload = {
      status: data.selectedIOTStatus?.name
        ? [data.selectedIOTStatus?.name]
        : "",
      type: data.selectedDeviceType?.name
        ? [data.selectedDeviceType?.name]
        : "",
      page: data.page ? data?.page + 1 : initialPaginationTable?.page,
      pageSize: data.pageSize,
      branches: data.selectedBranch?.name ? [data.selectedBranch?.name] : "",
      search: data.searchById ? JSON.stringify(data.searchById) : "",
      vendorName: data.selectedVendor?.name ? [data.selectedVendor?.name] : "",
      modelName: data.selectedIotModel?.name
        ? [data.selectedIotModel?.name]
        : "",
      cityName: data.selectedCity.name ? [data.selectedCity.name] : "",
    };

    // store filters data in local storage
    storeFilterDataInLocalStorage(LocalStorage.Filter_Data, {
      iotManagementFilterData: { ...rest },
    });

    if (functionalitiesAccess.isViewIotList) {
      dispatch(iotActions.getAllIotList(payload));
    }
  };

  // returns unique id to table
  const getRowId = (row: any) => row.id;

  // set all fields data for filters
  const handleChange = (field: string, value: any) => {
    let filtersData: any = { ...filters };
    filtersData[field] = value;
    if (field === "selectedCity") {
      filtersData["selectedBranch"] = initialState?.selectedBranch;
    }
    if (field === "paginationModel") {
      filtersData["page"] = value.page;
      filtersData["pageSize"] = value.pageSize;
    }
    setFilters((prev: IotFilters) => ({
      ...prev,
      ...filtersData,
      page: 0,
      pageSize: 10,
    }));
  };

  // reset all filters
  const resetFilters = () => {
    setFilters({ ...initialState });

    // set filters initial state on reset
    storeFilterDataInLocalStorage(LocalStorage.Filter_Data, {
      [filterStorageKeys.IOT_MANAGEMENT_FILTER_DATA]: { ...initialState },
    });

    // call get iot after reseting filters data
    getIotList(initialState);
  };

  // navigate to add iot device (onclick add iot button)
  const addIotDevice = () => navigate(routesConstants.ADD_IOT);

  // navigate to bulk upload iot device (onclick bulk upload button)
  const bulkUploadIot = () => navigate(routesConstants.BULK_UPLOAD_IOT);

  //handle search
  const handleSearch = (data: IotFilters) => {
    debounceLoadData(data);
  };

  // debounce call get iot api
  const debounceLoadData = useCallback(
    debounce((data: IotFilters) => {
      if (data?.searchById?.length >= 3 || !data?.searchById) getIotList(data);
    }, 1000),
    [
      filters.selectedIOTStatus,
      filters.page,
      filters.pageSize,
      filters.selectedDeviceType,
      filters.selectedBranch,
      filters.selectedVendor.name,
      filters.selectedIotModel,
      filters.selectedIotModel.name,
      filters.selectedCity,
    ]
  );

  //on row click navigate to edit or view device
  const handleRowClick = (row: any) => {
    if (!functionalitiesAccess.isViewIotDetails) {
      return;
    } else navigate(`${routesConstants.EDIT_IOT}/${row?.id}`);
  };

  // api call to get all branches data according to city selection
  const getBranches = (cityName: string) =>
    dispatch(rentalActions.getAllBranches({ name: cityName }));

  // on pagination change
  const onPaginationChange = (value: any) => {
    let payload: IotFilters = { ...filters };
    payload.page = value.page;
    payload.pageSize = value.pageSize;

    getIotList(payload);

    setFilters((prev: IotFilters) => ({
      ...prev,
      page: value.page,
      pageSize: value.pageSize,
    }));
  };

  //columns for table
  const columns: GridColDef[] = [
    {
      field: "modelName",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.ModelName,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.iotModelDisplayName
            ? params?.row?.iotModelDisplayName
            : en.global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "vendorName",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.VendorName,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.vendorName ? params?.row?.vendorName : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "type",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.Type,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.type
            ? DisplayDeviceType[
                params?.row?.type as keyof typeof DisplayDeviceType
              ]
            : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "phoneNumber",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.PhoneNumber,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.phoneNumber ? params?.row?.phoneNumber : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "imeiNumber",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.ImeiNumber,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.imeiNumber ? params?.row?.imeiNumber : global.NA}
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "status",
      cellClassName: "booking-status-cell",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.Status,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Typography
          style={{
            color: colors.white,
            fontWeight: "500",
            alignSelf: "center",
            width: "88px",
            backgroundColor:
              iotStatusColors[
                params?.row?.status as keyof typeof iotStatusColors
              ],
            borderRadius: "30px",
            padding: "6px 13px 6px 13px",
          }}
        >
          {params?.row?.status
            ? DisplayDeviceStatus[
                params?.row?.status as keyof typeof DisplayDeviceStatus
              ]
            : global.NA}
        </Typography>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "branchName",
      headerClassName: "hideRightSeparator",
      headerName: IotLocaleData.BranchName,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Tooltip
          title={
            params?.row?.branchDisplayName?.length > 24
              ? params?.row?.branchDisplayName
              : ""
          }
        >
          <span>
            {params?.row?.branchDisplayName
              ? params?.row?.branchDisplayName
              : global.NA}
          </span>
        </Tooltip>
      ),
      flex: 1,
      resizable: false,
    },
  ];

  return (
    <Stack sx={{ gap: "30px", flexWrap: "wrap" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          alignItems: "center",
        }}
      >
        {functionalitiesAccess.isAddIot ? (
          <CustomButton
            label={buttonLabels?.addIot}
            variant={"outlined"}
            onClick={addIotDevice}
            sx={{ padding: "0px 16px", minHeight: "35px" }}
          />
        ) : null}
        {functionalitiesAccess.isBulkUploadIot ? (
          <CustomButton
            label={buttonLabels?.bulkUpload}
            variant={"outlined"}
            onClick={bulkUploadIot}
            sx={{ padding: "0px 16px", minHeight: "35px" }}
          />
        ) : null}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CustomSelect
            maxWidth={"310px"}
            maxHeight={"50px"}
            icon={CityInputIcon}
            placeholder={global.selectCity}
            choice={
              isArrayNotEmpty(allCitiesDetails)
                ? [
                    { name: "", displayName: global.AllCities },
                    ...allCitiesDetails,
                  ]
                : [{ name: "", displayName: global.AllCities }]
            }
            value={filters?.selectedCity?.displayName}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedCity: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedCity", value);

              value?.name && getBranches(value.name);
            }}
            defaultKey={"displayName"}
            disabled={!functionalitiesAccess.isViewIotList}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            icon={WarehouseIcon}
            maxWidth={"310px"}
            placeholder={global.selectHub}
            choice={
              isArrayNotEmpty(branches)
                ? [{ name: "", displayName: global.AllBranches }, ...branches]
                : [{ name: "", displayName: global.AllBranches }]
            }
            value={filters.selectedBranch?.displayName}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedBranch: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedBranch", value);
            }}
            defaultKey={"displayName"}
            disabled={
              functionalitiesAccess.isViewIotList
                ? !filters?.selectedCity?.name
                : true
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            placeholder={global.selectDevice}
            icon={DeviceTypeIcon}
            choice={
              isArrayNotEmpty(iotDeviceTypesArray)
                ? [
                    { name: "", displayName: global.AllDeviceTypes },
                    ...iotDeviceTypesArray,
                  ]
                : []
            }
            maxWidth={"310px"}
            value={filters?.selectedDeviceType?.displayName}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedDeviceType: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedDeviceType", value);
            }}
            disabled={!functionalitiesAccess.isViewIotList}
          />
        </Grid>
        <Grid item xs={3}>
          {/* need to add displayName */}
          <CustomSelect
            placeholder={IotLocaleData?.SelectVendor}
            icon={AllVendorsIcon}
            value={filters?.selectedVendor?.displayName}
            maxWidth={"310px"}
            choice={
              isArrayNotEmpty(vendorsList)
                ? [{ name: "", displayName: global.AllVendors }, ...vendorsList]
                : [{ name: "", displayName: global.AllVendors }]
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedVendor: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedVendor", value);
            }}
            disabled={!functionalitiesAccess.isViewIotList}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            placeholder={global.selectModel}
            // icon={BikeIconFaded}
            maxWidth={"310px"}
            choice={
              isArrayNotEmpty(filters?.selectedVendor?.models)
                ? [
                    { name: "", displayName: global.AllModels },
                    ...filters?.selectedVendor?.models,
                  ]
                : [{ name: "", displayName: global.AllModels }]
            }
            value={filters?.selectedIotModel?.displayName}
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedIotModel: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedIotModel", value);
            }}
            disabled={
              functionalitiesAccess.isViewIotList
                ? !filters.selectedVendor.name
                : true
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            maxWidth={"310px"}
            icon={StatusIcon}
            placeholder={IotLocaleData.SearchByStatus}
            value={filters?.selectedIOTStatus?.displayName}
            choice={
              isArrayNotEmpty(iotStatusArray)
                ? [
                    { name: "", displayName: global.AllStatus },
                    ...iotStatusArray,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(value: any) => {
              let payload: IotFilters = {
                ...filters,
                selectedIOTStatus: value,
                page: 0,
                pageSize: 10,
              };

              getIotList(payload);

              handleChange("selectedIOTStatus", value);
            }}
            disabled={!functionalitiesAccess.isViewIotList}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            width={"234px"}
            placeholder={IotLocaleData.SearchByImei}
            onChange={(e: any) => {
              let payload: IotFilters = { ...filters };

              if (
                checkWhiteSpace(e.target.value) &&
                checkAlfaNumeric(e.target.value)
              ) {
                handleChange("searchById", e.target.value);
              }
              payload.searchById = e.target.value;
              handleSearch(payload);
            }}
            value={filters.searchById}
            disabled={!functionalitiesAccess.isViewIotList}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {functionalitiesAccess.isViewIotList ? (
            <Tooltip title={buttonLabels.resetFilters}>
              <RotateRightSharpIcon
                color="primary"
                sx={{ cursor: "pointer", fontSize: "27px" }}
                onClick={resetFilters}
              />
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <Box>
        <DataTable
          columns={columns}
          rows={iotDetails}
          disableColumnMenu
          disableColumnSorting
          disableRowSelectionOnClick
          headerAlign={"center"}
          getRowId={getRowId}
          rowHeight={45}
          onRowClick={(params: GridRowParams) => handleRowClick(params.row)}
          loading={getIotListLoader}
          paginationMode="server"
          paginationModel={{
            page: filters?.page ?? initialPaginationTable?.page,
            pageSize: filters?.pageSize ?? initialPaginationTable?.pageSize,
          }}
          onPaginationModelChange={onPaginationChange}
          rowCount={paginationData?.totalCount ?? 0}
          hasAccess={functionalitiesAccess.isViewIotList}
          sx={{
            width: "100%",
            "& .booking-status-cell ": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        />
      </Box>
    </Stack>
  );
};
export default IotDashboard;
