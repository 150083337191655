import {
  API_URLS,
  BASE_URL,
  FREEDO_BIZ_X_URL,
  FREEDO_X_URL,
  aliasFreedoLtr,
  customAxios,
  getApiUrl,
} from "../../../config";
import { constructUrl } from "../../utils/helper";

// Fetch all cities list optionally paginated.
const getAllCities = (payload: any) => {
  try {
    let cityUrl = FREEDO_BIZ_X_URL + API_URLS.getAllCitiesX;
    if (payload?.page) cityUrl += `page=${payload?.page}`;

    if (payload?.size && !isNaN(payload?.size))
      cityUrl += `&size=${payload?.size}`;
    return customAxios.get(cityUrl).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch branches based on the provided city name.
const getBranch = (payload: any) => {
  try {
    let branchUrl = "";
    if (payload.name)
      branchUrl =
        FREEDO_BIZ_X_URL + API_URLS.getBranchX + `?city=${payload.name}`;
    else branchUrl = FREEDO_BIZ_X_URL + API_URLS.getBranchX;
    return customAxios.get(branchUrl, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch branches based on the provided multiple cities.
const getBranchesbyMultipleCities = (payload: any) => {
  try {
    let apiUrl = FREEDO_BIZ_X_URL + API_URLS.getBranchX;
    const apiUrlWithParams: any = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams, payload).catch((e) => e);
  } catch (err) {
    return err;
  }
};

// Fetch all available vehicle models.
const getAllModels = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().getMasterModels;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// Fetch master plan durations.
const getMasterPlanDurations = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().getMasterPlanDurations;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// Fetch models list filtered by branch.
const getModelListByBranch = (payload: any) => {
  try {
    let apiUrl: any = BASE_URL + getApiUrl().getModelListByBranch;
    const apiUrlWithParams: any = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (err) {
    return err;
  }
};

// Fetch all states list.
const getAllStates = (payload: any) => {
  try {
    let url = FREEDO_BIZ_X_URL + API_URLS.getAllStates;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// Fetch master payment modes filtered by branch name.
const getMasterPaymentModes = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      `${getApiUrl().getMasterPaymentModes}branchName=${payload.branchName}`;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// Fetch cities filtered by state or selectable cities array.
const getCityByState = async (payload: any) => {
  try {
    if (payload?.selectableCities) {
      let CitiesArray: any = [];
      const promises = payload.selectableCities.map(
        (item: any, index: number) => {
          return customAxios
            .get(
              FREEDO_BIZ_X_URL +
                `${getApiUrl().getCities}state=${item.stateName}`
            )
            .then((response: any) => {
              CitiesArray.push(response.data.data);
            })
            .catch((error) => {
              return;
            });
        }
      );
      await Promise.all(promises);
      const data = { status: 200, cityArray: CitiesArray };
      return data;
    } else {
      let url = FREEDO_BIZ_X_URL + `${getApiUrl().getCities}state=${payload}`;
      return customAxios.get(url);
    }
  } catch (err) {
    return err;
  }
};

// fetch city details based on the provided payload.
const getCityDetails = (payload: string) => {
  try {
    let url = FREEDO_BIZ_X_URL + `${getApiUrl().getCityDetails + payload}`;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

//get cancellation reasons
const getCancellationReasons = (payload: string) => {
  try {
    let url = BASE_URL + getApiUrl().getCancellationReasons;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

//get booking cancellation charges
const getBookingCancellationCharges = (payload: any) => {
  try {
    let url =
      BASE_URL +
      `${getApiUrl(payload.id).getBookingCancellationCharges}reasonId=${
        payload?.reasonId
      }`;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

// get all charges
const getMasterChargesService = () => {
  try {
    let url = BASE_URL + `${getApiUrl().getMasterChargesServiceURL}`;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};
//get payment modes by operator url
const getOperatorConfigByBranch = (payload: any) => {
  try {
    let url =
      FREEDO_BIZ_X_URL + `${getApiUrl(payload?.branch).getPaymentModes}`;
    const apiUrlWithParams: string = constructUrl(url, payload?.data);
    return customAxios.get(apiUrlWithParams);
  } catch (ex) {
    return ex;
  }
};

//get payment status
const getPaymentStatus = (payload: any) => {
  try {
    let url = BASE_URL + `${getApiUrl(payload.id).getPaymentStatus}`;
    return customAxios.get(url);
  } catch (ex) {
    return ex;
  }
};

// Generate payment Link or qr code
const paymentLinkGeneration = (payload: any) => {
  try {
    let url = `${BASE_URL}${getApiUrl().paymentLinkGeneration}`;
    return customAxios.post(url, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getbranchDefaultConfig = (payload: any) => {
  try {
    let url = `${BASE_URL}${
      getApiUrl().getBranchDefaultConfig
    }${payload}?lob=LTR`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getCitiesBasedOnMultipleStates = (payload: any) => {
  try {
    const apiUrl: string =
      BASE_URL + getApiUrl().getCitiesBasedOnMultipleStates;
    const apiUrlWithParams: string = constructUrl(apiUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (e) {
    return e;
  }
};

// Fetch states based on pincode.
const getStatesFromPincode = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().getStatesFromPincode;
    const apiUrlWithParams: any = constructUrl(url, payload);

    return customAxios.get(apiUrlWithParams);
  } catch (ex) {
    return ex;
  }
};

export default {
  getAllCities,
  getBranch,
  getAllModels,
  getMasterPlanDurations,
  getModelListByBranch,
  getAllStates,
  getMasterPaymentModes,
  getCityByState,
  getCityDetails,
  getBranchesbyMultipleCities,
  getCancellationReasons,
  getBookingCancellationCharges,
  getMasterChargesService,
  getOperatorConfigByBranch,
  getPaymentStatus,
  paymentLinkGeneration,
  getbranchDefaultConfig,
  getCitiesBasedOnMultipleStates,
  getStatesFromPincode,
};
