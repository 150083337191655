import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { CustomButton } from "./CustomButtons";
import { colors } from "../../../themes/colors";

// // example props:

// state to handle the selectedButton or tab behavior // default is 0, i.e., 1st button/tab is selected
// const [selectedTab, setSelectedTab] = useState<number | any>(0);

// this array of object is where each object contains the variables/functions associated with the each tab/button
// const tabsArray: any = [
//     {
//         label: "One", // *text that will be displayed within the tab/button*
//         onClick: () => null, // *onClick function for the selected tab/button*
//         selectedTab: selectedTab, // *state variable for the selected tab/button*
//         tabValidation: null, // *validation function for the enabling/disabling of the tab/button*
//         tabStyle: {}, // i.e., tabStyle={minWidth: "150px",} // this contains the custom styles for the respective tab/button
//         startIcon: <LooksOneRoundedIcon />, // start icon that will be passed to each tab/button
//         endIcon: <LooksOneRoundedIcon />, // end icon that will be passed to each tab/button
//     },
//     {
//         label: "Two",
//         onClick: () => null,
//         selectedTab: selectedTab,
//         tabValidation: tabValidation(),
//         tabStyle: {},
//         startIcon: <LooksOneRoundedIcon />,
//         endIcon: <LooksOneRoundedIcon />,
//     },
//     {
//         label: "Three",
//         onClick: () => null,
//         selectedTab: selectedTab,
//         tabStyle: {},
//         startIcon: <LooksOneRoundedIcon />,
//         endIcon: <LooksOneRoundedIcon />,
//     },
// ];

// this is the function that will be executed on the tab/button selection
// const handleTabChange = (index: number) => {
//     setSelectedTab(index);
//     tabsArray[index].onClick(); // Execute onPress function for the selected tab
// };

const CustomButtonGroup = (props: any) => {
  // destructuring props
  const {
    handleTabChange,
    tabsArray,
    wrapperBoxStyle = {}, // style object for the wrapper Box // i.e., wrapperBoxStyle={minWidth: "150px",}
    isTabClickable = true, // Key to handle the click on tabs
    ...rest
  } = props;

  return (
    <Box
      sx={
        Object.keys(wrapperBoxStyle).length &&
        wrapperBoxStyle.constructor === Object
          ? wrapperBoxStyle
          : {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }
      }
    >
      <ButtonGroup variant="outlined" aria-label="Basic button group">
        {tabsArray.map((tab: any, index: number) => {
          const { SvgComponent = "" } = tab;
          return (
            <CustomButton
              key={index}
              label={tab?.label ?? index}
              disabled={tab?.tabValidation ?? false}
              variant={tab?.selectedTab === index ? "contained" : "outlined"}
              sx={{
                background: tab?.selectedTab === index ? colors.THEME_BLUE : "",
                "&:hover": {
                  background: colors.THEME_BLUE,
                },
                "&:active": {
                  background: colors.THEME_BLUE,
                },
                ...tab?.tabStyle,
              }}
              onClick={() =>
                isTabClickable ? handleTabChange(index, tab?.key) : null
              }
              startIcon={tab?.startIcon}
              endIcon={tab?.endIcon}
              {...rest}
            >
              {SvgComponent ? (
                <SvgComponent
                  color={
                    !tab?.tabValidation
                      ? tab?.selectedTab !== index
                        ? colors.THEME_BLUE
                        : colors.white
                      : colors.gray_cccccc
                  }
                />
              ) : null}
              {tab?.label}
            </CustomButton>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

export default CustomButtonGroup;
