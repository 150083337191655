import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
  calendarIconVehicle,
  vehicleCardImg,
  MobileIcon,
  MailIcon,
  kycVerifiedBadge,
  KycPendingBadge,
} from "../../../constantsX/exportImagesX";
import styles from "./TopCards.module.css";
import { checkIfImageExists, getFormattedINR } from "../../../utils/helper";
import { colors } from "../../../themes/colors";
import { Fade } from "react-awesome-reveal";
import { TextButtonX } from "../../../components/muiButtonsX";

export default function TopCards(props: any) {
  const theme = useTheme();

  let endDate = new Date(props?.fields?.startDate);

  return (
    <Box className={styles.cardContainer} sx={{ position: "relative" }}>
      <Fade>
        {/* KYC SECTION */}
        {props?.isKycVerified === 1 ? (
          <img src={kycVerifiedBadge} alt="" className={styles.topCardsimg} />
        ) : props?.isKycVerified === 0 ? (
          <img src={KycPendingBadge} alt="" className={styles.topCardsimg} />
        ) : (
          <></>
        )}
        
        {/* IMAGE SECTION */}
        {/* https://dev.freedo.rentals/images/model/HM_SPLENDOR/front_download.jpg */}
        <Box>
          {props?.img ? (
            props?.imagePathUrl && checkIfImageExists(props?.imagePathUrl) ? (
              <Box
                sx={{
                  backgroundSize: "cover",
                  backgroundImage: `url(${props?.imagePathUrl})`,
                }}
                className={styles.modelImageContainer}
              ></Box>
            ) : (
              <Box
                className={styles.modelImageContainer}
                sx={{
                  backgroundSize: "auto",
                  backgroundImage: `url(${vehicleCardImg})`,
                }}
              ></Box>
            )
          ) : null}
          {props?.avatar ? (
            <Avatar
              sx={{
                backgroundColor: "black",
                color: "white",
                width: "60px",
                height: "60px",
              }}
            >
              {props?.avatar}
            </Avatar>
          ) : null}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography component="div" fontSize={15}>
            {props.label}{" "}
            {props.onclickObj ? (
              <TextButtonX
                label={props?.onclickObj?.label}
                onclick={props?.onclickObj?.action}
              />
            ) : (
              <></>
            )}
          </Typography>

          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            fontSize={10}
          >
            {/* DATE */}
            {props?.fields?.startDate ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <span>
                    <img src={calendarIconVehicle} alt="" />
                  </span>
                  <span>
                    {props?.fields?.startDate ? props?.fields?.startDate : ""}
                  </span>
                </Box>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <span> Duration - </span>
                  <span>{props?.fields?.selectedDuration} Months</span>
                </Box>{" "}
              </Box>
            ) : null}

            {/* PRICE */}
            {props?.costPM ? (
              <Box
                style={{ fontSize: "0.8rem", color: colors.primary_aqua_blue }}
              >
                {getFormattedINR(props?.costPM)}/month
              </Box>
            ) : (
              <></>
            )}

            {/* GENDER */}
            {props.genderSection ? <>{props.genderSection}</> : null}
          </Typography>

          {/* USER DETAILS */}
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ display: "flex", gap: "10px" }}
            fontSize={10}
          >
            <Box className={styles.detailsBlock}>
              {props.phone ? (
                <>
                  <img src={MobileIcon} alt="" /> {props?.phone ?? ""}
                </>
              ) : null}
            </Box>
            <Box className={styles.detailsBlock}>
              {props.email ? (
                <>
                  <img src={MailIcon} alt="" /> {props?.email ?? ""}
                </>
              ) : null}
            </Box>
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
}
