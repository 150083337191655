import { Provider } from "react-redux";
import MainRouter from "./route";

import "./css/customcss/newGlobal.css";
import "./css/global.css";
import "./css/responsive.css";

import { QueryClient, QueryClientProvider } from "react-query";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";

const queryClient = new QueryClient();
function App() {
  const options = {
    okText: "Yes",
    cancelText: "No",
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ReduxToastr
            preventDuplicates
            confirmOptions={options}
            position="top-right"
            newestOnTop={true}
            progressBar
          />
          <MainRouter />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
