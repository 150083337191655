import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { NotVerified, Checkmark } from "../../assets/exportImages";
import Spinner from "../../components/Spinner";
import { AuthUsecases } from "../../constants/enum";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { AuthContainer } from "./components/authContainer";

import "./newAuthCss/global.css";
import "./newAuthCss/layout.css";
import { routesConstants } from "../../rentals-src/utils/RoutesConstants";
import { authApiUsecases } from "../../rentals-src/constants/constants";

const apiUsecase: any = {
  [AuthUsecases.FirstLogin]: authApiUsecases.FIRST_LOGIN,
  [AuthUsecases.ForgotPassword]: authApiUsecases.FORGOT_PASSWORD,
  [AuthUsecases.UnlockAccount]: authApiUsecases.UNLOCK_ACCOUNT,
};

function LinkVerification() {
  const {
    isLinkVerified,
    linkVerifyLoader,
    linkUseCase,
    linkVerificationFailed,
    linkVerifyData,
  } = useSelector((state: RootState) => state.newAuthReducer);

  const { locale } = useSelector((state: RootState) => state.localeReducer);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const encryptedLink: string = location?.search?.split("?encData=")[1];
  const useCase = location?.pathname?.split("/")[1];

  useEffect(() => {
    if (useCase && encryptedLink) {
      dispatch(
        actions.redirectionLinkVerification({
          data: {
            encData: encryptedLink,
            useCase: apiUsecase[useCase],
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      (useCase === AuthUsecases?.FirstLogin ||
        useCase === AuthUsecases?.ForgotPassword ||
        useCase === AuthUsecases?.PasswordExpire ||
        (useCase === AuthUsecases.UnlockAccount &&
          linkVerifyData?.isPasswordRequired)) &&
      isLinkVerified
    ) {
      setTimeout(() => {
        navigate(routesConstants.SET_PASSWORD, {
          state: { encryptedLink, useCase: apiUsecase[useCase] },
        });
      }, 3000);
    } else if (useCase === AuthUsecases.UnlockAccount && isLinkVerified) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [isLinkVerified]);

  return (
    <>
      <AuthContainer
        componentFields={
          <>
            <div>
              <Spinner loading={linkVerifyLoader} size={60} />
            </div>

            <div className="unlock-account">
              {" "}
              <a href="unlock-account.html"> </a>{" "}
            </div>
            <div className="unlock-account">
              {" "}
              <a href="unlock-account.html"> </a>{" "}
            </div>

            <div className="unlock-account">
              {" "}
              <div>
                {linkVerificationFailed ? (
                  <>
                    <img src={NotVerified}></img>{" "}
                    <h2>{locale?.newAuth?.verificationFailed}</h2>
                    <h5>{locale?.newAuth?.tryAgain}</h5>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="unlock-account">
              {" "}
              <div>
                {isLinkVerified ? (
                  <>
                    <img
                      src={Checkmark}
                      style={{ height: "80px", width: "80px" }}
                    ></img>{" "}
                    <h2>{locale?.newAuth?.verifiedSuccess}</h2>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="unlock-account">
              {" "}
              <a href="unlock-account.html"> </a>{" "}
            </div>
            <div className="unlock-account">
              {" "}
              <a href="unlock-account.html"> </a>{" "}
            </div>
          </>
        }
      />
    </>
  );
}

export default LinkVerification;
