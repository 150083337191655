import { CommonInterface } from "../actionInterfaces";
import { TASKMANAGEMENT_ACTIONS } from "../../actions/actionTypes";

const initialState = {
  taskloader: false,
  taskList: [],
  alltaskList:[],
  taskDetailsById : {},
  isTaskCreated : false,
  loading: false,
  isTaskEdited:false,
  isTaskDeleted:false,
  taskSequence:{},
  tasklistById:[],
  taskstatusById:[],
  tasksummaryById:[],
  taskUserListById:[],
  taskData:{},
  subtaskLoading :false,
  isWarehousetaskAdded :false, 
  taskAssigned :false,
  taskSuggestion:[]


};

// const separateTaskLists = (array:any):any => {

//   const startList :any = [];
//   const endList :any = [];
//   const servicesList :any = [];
//   const othersList :any = [];

//   array.forEach((obj:any) => {
//     if (obj.taskType === "Start Ride") {
//       startList.push(...obj.masterTaskIds);
//     } else if (obj.taskType === "End Ride") {
//       endList.push(...obj.masterTaskIds);
//     }
//     else if (obj.taskType === "Service") {
//       endList.push(...obj.masterTaskIds);
//     }
//     else if (obj.taskType === "Other") {
//       endList.push(...obj.masterTaskIds);
//     }
//   });

//   return { startList, endList, servicesList, othersList };
// };



const separateTaskLists = (array: any): any => {
  return array.reduce(
    (result:any, obj:any) => {
      const { taskType, masterTaskIds } = obj;

      if (taskType === "Start Ride") {
        result.startList.push(...masterTaskIds);
      } else if (taskType === "Complete Ride") {
        result.endList.push(...masterTaskIds);
      } else if (taskType === "Service") {
        result.servicesList.push(...masterTaskIds);
      } else {
        result.othersList.push(...masterTaskIds);
      }

      return result;
    },
    {
      startList: [],
      endList: [],
      servicesList: [],
      othersList: [],
    }
  );
};

const taskReducer = (state = initialState, action: any) :any=> {
  const prevState = { ...state };
  const { type, payload } = action;

  
  switch (type) { 
    case TASKMANAGEMENT_ACTIONS.TASKLIST_REQUESTED:
      return {
        ...prevState,
        taskloader: true,
        isTaskCreated: false,
        isTaskEdited:false,
        isTaskDeleted: false,
      };
    case TASKMANAGEMENT_ACTIONS.TASKLIST_SUCCESS:
      return {
        ...prevState,
        taskloader: false,
        taskList: payload?.taskList??[],
        // alltaskList : payload.currentPage===1 ? payload.taskList: [...state.alltaskList, ...payload?.allTasks],
        alltaskList : payload.taskList,
        totalCount : payload?.totalCount??0,
      };
    case TASKMANAGEMENT_ACTIONS.TASKLIST_FAILED:
      return {
        ...prevState,
        taskloader: false,
        taskList: [],
      };


      
      case TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_REQUESTED:
      return {
        ...prevState,
        taskloader: true,
      };
    case TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_SUCCESS:
      return {
        ...prevState,
        taskloader: false,
        taskDetailsById:payload
      };
    case TASKMANAGEMENT_ACTIONS.TASK_DETAIL_BY_ID_FAILED:
      return {
        ...prevState,
        taskloader: false,
        taskList: {},
      };

      case TASKMANAGEMENT_ACTIONS.CREATE_TASK_REQUESTED:
        return {
          ...state,
          loading: true,
        };
      case TASKMANAGEMENT_ACTIONS.CREATE_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          isTaskCreated: true,
        };
      case TASKMANAGEMENT_ACTIONS.CREATE_TASK_FAILED:
        return {
          ...state,
          loading: false,
        };
   

        case TASKMANAGEMENT_ACTIONS.EDIT_TASK_REQUESTED:
        return {
          ...state,
          loading: true,
        };
      case TASKMANAGEMENT_ACTIONS.EDIT_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          isTaskEdited: true,
        };
      case TASKMANAGEMENT_ACTIONS.EDIT_TASK_FAILED:
        return {
          ...state,
          loading: false,
        };


        case TASKMANAGEMENT_ACTIONS.DELETE_TASK_REQUESTED:
        return {
          ...state,
          loading: true,
        };
      case TASKMANAGEMENT_ACTIONS.DELETE_TASK_SUCCESS:
        return {
          ...state,
          loading: false,
          isTaskDeleted: true,
        };
      case TASKMANAGEMENT_ACTIONS.DELETE_TASK_FAILED:
        return {
          ...state,
          loading: false,
        };


        case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_WAREHOUSE_LIST_REQUESTED:
        return {
          ...state,
          subtaskLoading : true,
        };
      case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_WAREHOUSE_LIST_SUCCESS:
        return {
          ...state,
          subtaskLoading: false,
          tasklistById:payload?.getTaskListById,
        };
      case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_WAREHOUSE_LIST_FAILED:
        return {
          ...state,
          subtaskLoading: false,
          tasklistById :[]
        };
   

        case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_REQUESTED:
          return {
            ...prevState,
            taskloader: true,
            isWarehousetaskAdded:false 
            
          };
        case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_SUCCESS:
          return {
            ...prevState,
            taskloader: false,
            taskSequence :  separateTaskLists(payload?.data?.data)
            
          };
        case TASKMANAGEMENT_ACTIONS.TASK_SEQUENCE_LIST_FAILED:
          return {
            ...prevState,
            taskloader: false,
            taskSequence: {},
          };


          case TASKMANAGEMENT_ACTIONS.TASK_STATUS_REQUESTED:
            return {
              ...state,
              loading: true,
            };
          case TASKMANAGEMENT_ACTIONS.TASK_STATUS_SUCCESS:
            return {
              ...state,
              loading: false,
              taskstatusById:payload?.getTaskStatusById,
            };
          case TASKMANAGEMENT_ACTIONS.TASK_STATUS_FAILED:
            return {
              ...state,
              loading: false,
              taskstatusById:[]
            };

            case TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_REQUESTED:
              return {
                ...state,
                loading: true,
                taskAssigned :false
              };
            case TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_SUCCESS:
              return {
                ...state,
                loading: false,
                tasksummaryById: payload?.getTaskSummaryById,
              };
            case TASKMANAGEMENT_ACTIONS.TASK_SUMMARY_FAILED:
              return {
                ...state,
                loading: false,
                tasksummaryById : []
              };
              case TASKMANAGEMENT_ACTIONS.CLEAR_TASK_SUMMARY_DATA:
                return {
                  ...state,
                  loading: false,
                  tasksummaryById : []
                };
  

              case TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_REQUESTED:
                return {
                  ...state,
                  loading: true,
                };
              case TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  taskUserListById:payload?.getUserListById,
                };
              case TASKMANAGEMENT_ACTIONS.TASK_USER_LIST_FAILED:
                return {
                  ...state,
                  loading: false,
                };


                case TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_REQUESTED:
                  return {
                    ...state,
                    loading: true,
                    taskAssigned : false
                  };
                case TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_SUCCESS:
                    
                  return {
                    ...state,
                    loading: false,
                    taskData:payload?.postTaskAsignment,
                    taskAssigned :true
                  };
                case TASKMANAGEMENT_ACTIONS.USER_TASK_ASSIGN_FAILED:
                  return {
                    ...state,
                    loading: false,
                    taskAssigned : false
                   
                  };


                  case TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_REQUESTED:
                    return {
                      ...state,
                      isWarehousetaskAdded: false,
                      loading: true,
                    };
                  case TASKMANAGEMENT_ACTIONS.EDIT_TASK_SEQUENCE_SUCCESS:
                      
                    return {
                      ...state,
                      isWarehousetaskAdded:true,
                      loading:false,
                    };
                  case TASKMANAGEMENT_ACTIONS. EDIT_TASK_SEQUENCE_FAILED:
                    return {
                      ...state,
                      isWarehousetaskAdded: false,
                      loading:false,
                     
                    };

                    case TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_REQUESTED:
                      return {
                        ...prevState,
                       
                      };
                    case TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_SUCCESS:
                      return {
                        ...prevState,
                        taskSuggestion : payload?.taskSuggestion ?? []
                        
                      };
                    case TASKMANAGEMENT_ACTIONS.TASK_SUGGESTION_FAILED:
                      return {
                        ...prevState,
                        taskSuggestion : []
                        
                      };
                
      default:
        return { ...prevState };
  }
};

export default taskReducer;
