import * as actionTypes from "../actionTypes";

interface MasterInterface {
  page?: number | string;
  size?: number | string;
  city_name?: number | string;
  header?: any;
  city_icon_image?: any;
  city_coming_image?: any;
  body_text?: any;
  body_text_2?: any;
  id?: any;
  city_code?: any;
  country_id?: number | string;
  state_id?: number | string;
  status?: number | string;
  city_id?: number | string;
  addsuccess?: boolean;
  editsuccess?: boolean;
  category_name?: any;
  category_id?: any;
  formData?: any;
  type?: any;
  release_day?: any;
  adv_txt?: any;
}

export const getCityData = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_CITY_DATA_REQUESTED,
  payload,
});

export const getAdvertisementData = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_DATA_REQUESTED,
  payload,
});

export const getAdvertisementById = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_REQUESTED,
  payload,
});

export const addAdvertisement = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_REQUESTED,
  payload,
});

export const editAdvertisement = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_REQUESTED,
  payload,
});

export const addNewCity = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.ADD_CITY_DATA_REQUESTED,
  payload,
});

export const getCityById = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_CITY_BY_ID_REQUESTED,
  payload,
});

//category actions
export const editCityToggle = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.TOGGLE_ADD_EDIT,
  payload,
});

export const getCategoryData = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_DATA_REQUESTED,
  payload,
});

export const editCategory = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.EDIT_CATEGORY_DATA_REQUESTED,
  payload,
});

export const deleteCategory = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.DELETE_CATEGORY_DATA_REQUESTED,
  payload,
});

export const editCity = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.EDIT_CITY_DATA_REQUESTED,
  payload,
});

export const getCityStatus = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.GET_CITY_STATUS_LIST_REQUESTED,
  payload,
});
export const getHelmetStatus = (payload: any) => ({
  type: actionTypes.MASTER_ACTIONS.GET_HELMET_CONFIG_REQUESTED,
  payload,
});
export const getCategoryById = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_BY_ID_REQUESTED,
  payload,
});
export const addNewCategory = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.ADD_CATEGORY_DATA_REQUESTED,
  payload,
});

//model actions

export const addNewModel = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_REQUESTED,
  payload,
});

export const getModelData = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_MODEL_DATA_REQUESTED,
  payload,
});

export const editModel = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.EDIT_MODEL_DATA_REQUESTED,
  payload,
});

export const deleteModel = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.DELETE_MODEL_DATA_REQUESTED,
  payload,
});

export const getModelById = (payload: MasterInterface) => ({
  type: actionTypes.MASTER_ACTIONS.GET_MODEL_BY_ID_REQUESTED,
  payload,
});
