import { Typography, Skeleton, Tooltip, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { colors } from "../../../freedo-x-src/themes/colors";
import { DetailsTypographyProps } from "../../../interfaces/components";
import styles from "./multistepForm.module.css";
import { typographyConstants } from "../../../rentals-src/constants/constants";

const HeadingValueTypography = (props: DetailsTypographyProps) => {
  const { color = "", loading = false, heading = "", value = "" } = props;
  return (
    <Stack
      width={props?.width ? props?.width : "140px"}
      className={props.elementClassList}
    >
      <Typography
        variant={typographyConstants.SUBHEADING}
        color={colors.text_secondary_gray}
        sx={{ fontSize: "11px" }}
      >
        {heading + " :"}
      </Typography>
      {loading ? (
        <Skeleton />
      ) : (
        <Box sx={{ display: "flex" }}>
          {value && (
            <Tooltip
              title={
                typeof props?.value === "string" && props?.value?.length > 18
                  ? value
                  : ""
              }
            >
              <Typography
                sx={{
                  padding: "0px",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                color={color ? color : colors.text_black}
              >
                {(value &&
                  (typeof props?.value === "string"
                    ? value.toString()
                    : value)) ||
                  ""}
              </Typography>
            </Tooltip>
          )}

          {props?.link && (
            <Tooltip title={props?.link?.length > 15 ? props.link : ""}>
              <Typography
                className={styles.bookingDetailsValue}
                color={colors.THEME_BLUEX}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={props?.clickhandler}
              >
                {props.link}
              </Typography>
            </Tooltip>
          )}
          {props?.visitStepData && (
            <Tooltip
              title={
                props?.visitStepData?.value?.length > 20
                  ? props?.visitStepData?.value
                  : ""
              }
            >
              <Typography
                className={styles.bookingDetailsValue}
                color={color ? color : colors.THEME_BLUEX}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => props?.clickHandler(props?.visitStepData?.step)}
              >
                {props?.visitStepData?.value}
              </Typography>
            </Tooltip>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default HeadingValueTypography;
