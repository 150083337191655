import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Checkbox, Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePageX, updateSortingX } from "../../redux/actions";
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  setFields: any;
  columns: any;
  showCheckBox: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setFields,
    columns,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        {props.showCheckBox ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}
        {columns?.map((headCell: any) => (
          <TableCell
            key={headCell?.id}
            align="left"
            //padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell?.id)}
              hideSortIcon={
                headCell.id == "bookingDate" || headCell.id == "startDate"
                  ? false
                  : true
              }
              sx={{ color: "#636369" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export default function EnhancedTable({
  columns,
  rows,
  extraData,
  setFields,
  fields,
  count,
  loading,
  pages,
  pageSize,
  setPageNo,
  pageNo,
  disableOnclick = false,
  showCheckBox = false,
  tableHeader,
  pageChangeHandler,
  disablePagination = false,
}: // selected = [],
// setSelected
any) {
  const [order, setOrder] = React.useState<any>("desc");
  const [orderBy, setOrderBy] = React.useState<any>("bookingDate");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    if (property === "bookingDate" || property === "startDate") {
      const isAsc = order === "desc";
      setOrder(isAsc ? "asc" : "desc");
      //setFields({ ...fields, sorting: property + "_" + order });
      dispatch(updateSortingX(property + "_" + order));

      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    // setFields({ ...fields, page: newPage });
    dispatch(updatePageX(newPage));
    //setPageNo(newPage);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleBookingDetails = (bookingId: any) => {
    navigate("/booking-details/" + bookingId);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  /* const visibleRows = React?.useMemo(
    () =>
      stableSort(props?.bookings, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  ); */

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar rowsPerPage={rowsPerPage}/> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            {!tableHeader && (
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                setFields={setFields}
                columns={columns}
                showCheckBox={showCheckBox}
              />
            )}
            {loading ? (
              <TableBody style={{ minWidth: 200 }}>
                {new Array(6).fill(1).map((eachData) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={Math.random()}
                    style={{ backgroundColor: "white" }}
                  >
                    {columns?.map((column: any) => (
                      <TableCell
                        key={Math.random()}
                        // align={column.align}
                      >
                        <Skeleton
                          variant={"rounded"}
                          style={{
                            height: 30,
                            //width: screenSize.dynamicWidth * 0.78,
                          }}
                          animation="wave"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : rows && rows?.length === 0 ? (
              <TableCell colSpan={columns?.length} align="center">
                No Data Found
              </TableCell>
            ) : (
              <TableBody>
                {rows?.length
                  ? rows?.map((row: any, index: number) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          aria-checked={isItemSelected}
                          // key={row.id}
                          selected={isItemSelected}
                          hover={!row?.bgColor}
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          // key={row?.installmentNumber}
                          sx={{
                            cursor: "pointer",
                            fontSize: "13px",
                            align: "left",
                            backgroundColor: row?.bgColor ? row?.bgColor : "",
                          }}
                          onClick={() => {
                            disableOnclick
                              ? null
                              : handleBookingDetails(row?._id);
                          }}
                          // disabled={disableOnclick}
                        >
                          {showCheckBox && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )}

                          {columns?.map((column: any) => {
                            const value = row[column.id];
                            return !column?.IgnoreExtraData ? (
                              <TableCell
                                component="th"
                                //id={labelId}
                                scope="row"
                                padding="normal"
                                align="left"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#3C3C43",
                                  padding: column?.disablePadding
                                    ? "0"
                                    : "normal",
                                }}
                                key={column.id}
                              >
                                {column?.format
                                  ? column?.format(value, extraData)
                                  : column?.render
                                  ? column?.render(row, extraData)
                                  : value}
                                {/* {value} */}
                              </TableCell>
                            ) : null;
                          })}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={pageSize?.value ? pageSize.value : pageSize}
            // count={10}
            // rowsPerPage={5}
            //page={pageNo}
            // page={(pageNo=="0")?0:page!=pageNo?pageNo:page}
            page={page}
            // page={0}
            onPageChange={handleChangePage}
            /* onPageChange={(e) => {
            handleChangePage(e, 2), pageChangeHandler(e)
          }} */
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}
