import CloseIcon from "@mui/icons-material/Close";
import { Divider, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./responsiveDialog.css";
import { CustomButton } from "../../atoms/Buttons/CustomButtons";
import CONSTANTS from "../../../rentals-src/locale/rental-en.json";

const defaultActions = (
  onYesClickHandler: Function,
  onNoClickHandler: Function,
  loading: boolean
) => (
  <>
    <CustomButton
      label={CONSTANTS?.global?.No}
      variant="outlined"
      sx={{ minWidth: "150px" }}
      onClick={onNoClickHandler}
    />
    <CustomButton
      label={CONSTANTS?.global?.Yes}
      variant="contained"
      sx={{ minWidth: "150px" }}
      onClick={onYesClickHandler}
      loading={loading}
    />
  </>
);

export default function ResponsiveDialog(props: any) {
  const { handleClose, handleYes, loading } = props;

  const handleNo = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth={props.maxWidth || "xs"}
        fullScreen={props.fullScreen}
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason && reason === "backdropClick") return false;
          if (reason && reason === "escapeKeyDown") return false;
          props.handleClose;
        }}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: "20px 20px 5px" }}
      >
        {/* <Stack direction="row" justifyContent="flex-end">
          <CloseIcon
            sx={{ color: "lightgray", fontSize: "1.2rem", cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </Stack> */}
        <Stack
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "-53px",
            left: "45%",
            background: "#636369",
            fontSize: "larger",
            borderRadius: "50%",
            color: "#fff",
            width: "46.67px",
            height: "46.67px",
            border: "1.5px 0px 0px 0px",
            opacity: "0px",
          }}
        >
          <CloseIcon
            sx={{ color: "#fff", fontSize: "1.2rem", cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </Stack>
        <DialogTitle
          className={props.titleColor}
          style={{
            fontSize: props?.titleFontSize || "16px",
            padding: "unset",
            ...props.titlestyle,
            textAlign: props?.titletextAlign ? props?.titletextAlign : "left",
          }}
          id="responsive-dialog-title"
        >
          {props.title}
        </DialogTitle>
        {props.hideDivider ? null : <Divider style={{ width: "100%" }} />}
        {props.body ? (
          <DialogContent sx={{ padding: "10px 10px 10px 0px" }}>
            <DialogContentText>{props.body}</DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions
          sx={{ justifyContent: "space-evenly", marginTop: "10px" }}
        >
          {props.children
            ? props.children
            : defaultActions(handleYes, handleNo, loading)}
        </DialogActions>
      </Dialog>
    </>
  );
}
