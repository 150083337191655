import React, { useState } from "react";
import {
 Box,
 Button,
 Card,
 Divider,
 TextField,
 FormControl,
 FormControlLabel,
 Grid,
 Radio,
 RadioGroup,
 Typography,
 CircularProgress,
 useMediaQuery,
 Tooltip,
 IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { colors } from "../../../../themes/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 faAngleDown,
 faAngleUp,
 faPlugCirclePlus,
 faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

interface CardProps {
 id?: number;
 taskName?: string;
 reading?: string;
 sla?: any;
 imageRequired?: boolean;
 remarkRequired?: boolean;
 doubleClick?: any;
}

const CardView = ({
 id,
 taskName,
 reading,
 sla,
 imageRequired,
 remarkRequired,

 doubleClick,
}: CardProps) => {
 const [expanded, setExpanded] = useState<boolean>(true);
 const handleExpandClick = () => {
  setExpanded(!expanded);
 };

 return (
  <Card
   onDoubleClick={doubleClick}
   key={id}
   sx={{
    borderRadius: "12px",
    position: "relative",
    justifyContent: "flex-end",
   
   }}
   className={`dragDrop-card`}
  >
   <div
    style={{
     alignItems: "flex-end",
     justifyContent: "flex-end",
     display: "flex",
     width: "96%",
    }}
   >
    {" "}
    <Tooltip title="Click to add item" placement="top-start">
     <div>
      <FontAwesomeIcon
       onClick={doubleClick}
       icon={faPlusCircle}
       color={colors.chip_text_colorG}
       style={{ width: 20, height: 20 }}
      />
     </div>
    </Tooltip>
   </div>
   <Typography
    style={{
     color: colors.heading_main_text,
     fontSize: "14px",
     fontFamily: "EuclidCircularB-SemiBold",
     marginBottom: 2,
    }}
   >
    {taskName}
   </Typography>
   <div
    style={{
     marginBottom: 10,
     textAlign: "justify",
     width: "94%",
     alignItems: "flex-end",
     display: "flex",
     minHeight: 40,
    }}
   >
    <Typography
     variant="body1"
     component="div"
     className={expanded ? "block-ellipsis" : ""}
     sx={{
      textAlign: "justify",
     }}
     style={{
      color: colors.card_body_text,
      fontSize: "12px",
      fontFamily: "EuclidCircularB-Regular",
      width: "98%",
     }}
    >
     {reading}
    </Typography>
    <FontAwesomeIcon
     onClick={handleExpandClick}
     icon={expanded ? faAngleDown : faAngleUp}
     color={colors.card_body_text}
     style={{ width: 14, height: 14 }}
    />
   </div>

   <Typography
    style={{
     color: colors.card_body_text_second,
     fontSize: "12px",
     fontFamily: "EuclidCircularB-Regular",
     marginBottom: 12,
    }}
   >
    <span
     style={{
      color: colors.card_body_text_second,
      fontSize: "12px",
      fontFamily: "EuclidCircularB-Medium",
      marginBottom: 2,
     }}
    >
     SLA :
    </span>{" "}
    {sla}
   </Typography>
   <Typography
    style={{
     color: colors.heading_main_text,
     fontSize: "14px",
     fontFamily: "EuclidCircularB-Medium",
     marginBottom: 10,
    }}
   >
    Verification by
   </Typography>
   <div
    style={{
     display: "flex",
     flexDirection: "row",
     alignContent: "space-between",
     gap: 4,
     marginTop: 10,
    }}
   >
<div style ={{display:"flex", justifyContent:"center", gap:"8px"}}>

{imageRequired ? (
       <Chip
      
       style={{
        minWidth: "80px",
        backgroundColor: colors.chip_background_colorG,
        
        color: colors.chip_text_colorG,
        marginRight: 5,
        fontSize: "12px",
        fontFamily: "EuclidCircularB-Medium",
       }}
       label= "Image"
       size="small"
      />
      ) : null}
      {remarkRequired ? (
      <Chip
      
      style={{
       minWidth: "80px",
       backgroundColor:colors.chip_background_cgray,
       color: colors.chip_text_cgray,
       marginRight: 5,
       fontSize: "12px",
       fontFamily: "EuclidCircularB-Medium",
      }}
      label="Remark"
      size="small"
     />
      ) : null}
      </div>



    {/* {chip?.map((item, index): any => (
     <Chip
      key={index}
      style={{
       minWidth: "80px",
       backgroundColor:
        item == "Image"
         ? colors.chip_background_colorG
         : colors.chip_background_cgray,
       color:
        item == "Image" ? colors.chip_text_colorG : colors.chip_text_cgray,
       marginRight: 5,
       fontSize: "12px",
       fontFamily: "EuclidCircularB-Medium",
      }}
      label={item}
      size="small"
     />
    ))} */}
   </div>
  </Card>
 );
};

export default CardView;
