import {
  faEye,
  faEdit,
  faTrash,
  faCheck,
  faCalendarDays,
  faXmark,
  faSignal,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserActions } from "../constants/userActions";
import { colors } from "../themes/colors";
import "./components.css";

function ActionView(props: any) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="row"
      justifyContent={props.justifyContent || "flex-end"}
      style={{ justifyContent: "center" }}
    >
      {props?.actions?.map((action: any) => {
        if (action === UserActions.view)
          return (
            <Tooltip title={action} placement="top-start">
              <div style={{ cursor: "pointer" }} id="view-action">
                <FontAwesomeIcon
                  icon={faEye}
                  color={colors.black}
                  onClick={(e) => props.onClickAction(action, e)}
                />
              </div>
            </Tooltip>
          );
        else if (
          action === UserActions.editVehicle ||
          action === UserActions.edit ||
          action === UserActions.update
        )
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id={
                  action === UserActions.editVehicle
                    ? "edit-vehicle-action"
                    : action === UserActions.edit
                    ? "edit-action"
                    : "update-action"
                }
              >
                <FontAwesomeIcon icon={faEdit} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.delete)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="delete-action"
              >
                <FontAwesomeIcon icon={faTrash} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.approve)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="approve-action"
              >
                <FontAwesomeIcon icon={faCheck} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.reject)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="reject-action"
              >
                <FontAwesomeIcon icon={faXmark} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.viewCalendar)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="view-calendar-action"
              >
                <FontAwesomeIcon icon={faCalendarDays} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.stats)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="stats-action"
              >
                <FontAwesomeIcon icon={faSignal} color={colors.black} />
              </div>
            </Tooltip>
          );
        else if (action === UserActions.view)
          return (
            <Tooltip title={action} placement="top-start">
              <div
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => props.onClickAction(action)}
                id="detail-action"
              >
                <FontAwesomeIcon icon={faCircleInfo} color={colors.black} />
              </div>
            </Tooltip>
          );
      })}
    </Grid>
  );
}

export { ActionView };
