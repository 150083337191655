import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import commonSaga from "./commonSaga";
import dashboardSaga from "./dashboardSaga";
import feedbackSaga from "./feedbackSaga";

import couponSaga from "./couponSaga";
import reportDownloadSaga from "./reportDownloadSaga";
import reportSaga from "./reportSaga";
import slotPauseSaga from "./slotPauseSaga";

import masterSaga from "./masterSaga";
import OperatorSaga from "./operatorSaga";
import profileSaga from "./profileSaga";
import taskSaga from "./taskSaga";

import {
  YOR_BookingDetailsSaga,
  allBookingSagaX,
  bookingDetailsX,
  calculateChargesX,
  commonSagaX,
  manualBookingSagaX,
  masterSagaX,
  rentalPlansSagaX,
} from "../../freedo-x-src/redux/sagas";
/* import newManualBookingSaga from "../../rentals-src/redux/sagas/manualBookingsaga";
import rentalCommonSaga from "../../rentals-src/redux/sagas/rentalCommonSaga";
import newBusinessManagementSaga from "../../rentals-src/redux/sagas/businessManagementSaga"; */
import {
  RentalsVehicleManagementServices,
  // newManualBookingSaga,
  // rentalCommonSaga,
  // newBusinessManagementSaga,
  customerManagementSaga,
  // surgeSaga,
  // RentalsVehicleManagementServices
  kycDocumentsSaga,
  operatorManagementSaga,
  rentalReportSaga,
  rentalsIotManagementSaga,
} from "../../rentals-src/redux/sagas";
import bookingManagementSaga from "../../rentals-src/redux/sagas/bookingManagementSaga";
import newBusinessManagementSaga from "../../rentals-src/redux/sagas/businessManagementSaga";
import newManualBookingSaga from "../../rentals-src/redux/sagas/manualBookingsaga";
import paymentManagementSaga from "../../rentals-src/redux/sagas/paymentManagementSaga";
import planManagementSaga from "../../rentals-src/redux/sagas/planManagementSaga";
import rentalCommonSaga from "../../rentals-src/redux/sagas/rentalCommonSaga";
import surgeSaga from "../../rentals-src/redux/sagas/surgeSaga";
import newAuthSaga from "./newAuthSaga";

export function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    feedbackSaga(),
    commonSaga(),
    couponSaga(),
    reportSaga(),
    reportDownloadSaga(),
    masterSaga(),
    OperatorSaga(),
    taskSaga(),
    newAuthSaga(),
    profileSaga(),
    manualBookingSagaX(),
    rentalPlansSagaX(),
    masterSagaX(),
    allBookingSagaX(),
    calculateChargesX(),
    bookingDetailsX(),
    YOR_BookingDetailsSaga(),
    newManualBookingSaga(),
    rentalCommonSaga(),
    newBusinessManagementSaga(),
    commonSagaX(),
    planManagementSaga(),
    customerManagementSaga(),
    RentalsVehicleManagementServices(),
    surgeSaga(),
    kycDocumentsSaga(),
    rentalsIotManagementSaga(),
    bookingManagementSaga(),
    paymentManagementSaga(),
    rentalReportSaga(),
    operatorManagementSaga(),
  ]);
}
