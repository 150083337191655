import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonActions,
  UserActions,
  PackageManagementRoutes,
} from "../../constants/userActions";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import StickyHeaderTable from "../../components/stickeyHeader";
import { UserStatus } from "../../constants/bookingContants";
import { Status } from "../../components/status";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux";
import ResponsiveDialog from "../../components/responsiveDialog";
import { colors } from "../../themes/colors";

function ModelManagement() {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { modelManagement, categoryManagement, global, alertMessages } = locale;
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(-1);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const { modelData, categoryloading, modelloading, deleteloading } =
    useSelector((state: RootState) => state.masterReducer);

  const handlePage = (page: any) => {
    setPage(page);
  };

  const handleRows = (row: any) => {
    setSize(row);
  };

  const columns = [
    { id: "model_name", label: global.Model, minWidth: 70 },
    {
      id: "status",
      label: global.Status,
      minWidth: 170,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return <Status status={item?.status_master?.status_name} />;
      },
    },

    {
      id: "action",
      label: global.Action,
      minWidth: 170,
      align: "center",
    },
  ];
  const handleClickOpen = (id: any) => {
    // alert(id)
    // return;
    setOpen(true);
    setDeleteId(id);
    return;
  };
  useEffect(() => {
    if (deleteloading === false) dispatch(actions.getModelData({
      page: page + 1,
      size: size,
    }));
  }, [deleteloading]);
  const handleDelete = () => {
    dispatch(
      actions.deleteModel({
        id: Number(deleteId),
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(
      actions.getModelData({
        page: page + 1,
        size: size,
      })
    );
  }, [reset, refresh, page, size]);

  return (
    <div className="container pd-20">
      <ResponsiveDialog
        titlestyle={{
          color: "rgb(123, 130, 137)",
          fontFamily: "EuclidCircularB-Regular",
          fontSize: "18px",
        }}
        maxWidth="md"
        fullScreen={false}
        agreeText="Yes"
        disAgreeText="No"
        title={alertMessages.deleteItem}
        handleClose={handleClose}
        open={open}
      >
        <Button
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          autoFocus
          onClick={handleDelete}
        >
          Yes
        </Button>
        <Button
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          onClick={handleClose}
          autoFocus
        >
          No
        </Button>
      </ResponsiveDialog>

      <Heading
        title={modelManagement.ModelManagement}
        actionButtons={[
          ButtonActions.refresh,
          ButtonActions.reset,
          ButtonActions.addModel,
        ]}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.addModel) {
            navigate("add-model");
          } else if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          } else if (type === ButtonActions.reset) {
            setReset(!reset);
            setPage(0);
            setSize(10);
          }
        }}
      />
      <StickyHeaderTable
        totalItems={modelData?.totalItems}
        paddingAction="4px"
        rows={modelData?.data}
        loading={modelloading}
        actions={[UserActions.view, UserActions.update, UserActions.delete]}
        columns={columns}
        page={page}
        size={size}
        pageSetter={handlePage}
        rowsPerPageSetter={handleRows}
        onClickAction={(
          type: string,
          id: number | string,
          vehicleId,
          allData
        ) => {
          if (type === UserActions.view) {
            navigate("view-model/" + allData?.id);
          } else if (type === UserActions.update) {
            navigate("edit-model/" + allData?.id);
          } else if (type === UserActions.delete) handleClickOpen(allData?.id);
        }}
      />
    </div>
  );
}

export default ModelManagement;
