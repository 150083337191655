import { routesConstants } from "../utils/RoutesConstants";

interface ACTIVE_MODULE {
  [key: string]: string;
}

export const BREADCRUMBS_YOR = {
  BOOKING_DETAILS: {
    name: "Booking Details",
    parent: {
      name: "All Bookings",
      path: "/booking-list",
      drawerKey: "booking",
    },
    activeRoute: "bookinglist",
    selfPath: "booking-details",
  },
  BOOKING_LIST: {
    name: "Booking List",
    parent: {
      name: "",
      path: "",
      drawerKey: "booking",
    },
    activeRoute: "bookinglist",
    selfPath: "booking-list",
  },
  BOOKING_CREATE: {
    name: "Create Booking",
    parent: {
      name: "",
      path: "",
      drawerKey: "booking",
    },
    activeRoute: "manualBooking",
    selfPath: "new-booking",
  },
};

export const BREADCRUMBS_RENTALS = {
  ADD_PLAN: {
    name: "Add Plan",
    parent: {
      name: "Plan List",
      path: routesConstants.PLAN_MANAGEMENT,
      drawerKey: "booking",
    },
    activeRoute: "bookinglist",
  },
  BULK_ACTIONS_BULK_UPLOAD: {
    name: "Bulk Upload",
    parent: {
      name: "Vehicle List",
      path: routesConstants.ALL_VEHICLES,
      drawerKey: "booking",
    },
  },
  BULK_ACTIONS_APPROVE_REJECT_VEHICLE: {
    name: "Approve & Reject Vehicle",
    parent: {
      name: "Vehicle List",
      path: routesConstants.ALL_VEHICLES,
      drawerKey: "booking",
    },
  },
  BULK_ACTIONS_VEHICLE_TRANSFER_HISTORY: {
    name: "Vehicle Transfer List",
    parent: {
      name: "Vehicle List",
      path: routesConstants.ALL_VEHICLES,
      drawerKey: "booking",
    },
  },
  BULK_ACTIONS_STATUS_CHANGE: {
    name: "Vehicle Status Change",
    parent: {
      name: "Vehicle List",
      path: routesConstants.ALL_VEHICLES,
      drawerKey: "booking",
    },
  },
  BULK_ACTIONS_VEHICLE_TRANSFER: {
    name: "Sending & Receiving Details",
    parent: {
      name: "Vehicle Transfer List",
      path: routesConstants.VEHICLE_TRANSFER_HISTORY,
      drawerKey: "booking",
    },
  },
  PLAN_DETAIL_EDIT: [
    {
      name: "Plan List",
      path: routesConstants.PLAN_MANAGEMENT,
    },
    {
      name: "Edit Plan",
      path: routesConstants.ADD_EDIT_PLAN_DETAIL,
      active: "true",
    },
  ],
  PLAN_DETAIL_ADD: [
    {
      name: "Plan List",
      path: routesConstants.PLAN_MANAGEMENT,
    },
    {
      name: "Add Plan",
      path: routesConstants.ADD_EDIT_PLAN_DETAIL,
      active: "true",
    },
  ],
  CUSTOMER_ADD: [
    {
      name: "Customer Management",
      path: routesConstants.CUSTOMER_MANAGEMENT,
    },
    {
      name: "Add Customer",
      path: routesConstants.ADD_CUSTOMER,
      active: true,
    },
  ],
  CUSTOMER_DETAILS: [
    {
      name: "Customer Management",
      path: routesConstants.CUSTOMER_MANAGEMENT,
    },
    {
      name: "Customer Details",
      path: routesConstants.CUSTOMER_DETAIL,
      active: true,
    },
  ],
  CUSTOMER_OVERVIEW: [],
  BOOKING_DETAILS: [
    {
      name: "Booking List",
      path: routesConstants.ALL_BOOKINGS,
    },
    {
      name: "Booking Details",
      path: routesConstants.BOOKING_DETAILS,
      active: true,
    },
  ],
  ADD_HUB: [
    {
      name: "Hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    },
    {
      name: "Add Hub",
      path: routesConstants.ADD_BUSINESS_WAREHOUSE_MANAGEMENT,
      active: true,
    },
  ],
  EDIT_HUB: [
    {
      name: "Hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    },
    {
      name: "Edit Hub",
      path: routesConstants.EDIT_BUSINESS_WAREHOUSE_MANAGEMENT,
      active: true,
    },
  ],
  ADD_HELMET_CONFIGURATION: [
    {
      name: "Helmet Configuration",
      path: routesConstants.HELMET_CONFIGURATION,
    },
    {
      name: "Add Helmet Configuration",
      path: routesConstants.ADD_HELMET_CONFIGURATION,
      active: true,
    },
  ],
  EDIT_HELMET_CONFIGURATION: [
    {
      name: "Helmet Configuration",
      path: routesConstants.HELMET_CONFIGURATION,
    },
    {
      name: "Edit Helmet Configuration",
      path: routesConstants.EDIT_HELMET_CONFIGURATION,
      active: true,
    },
  ],
  OVERVIEW_CITY: [
    {
      name: "Overview",
      path: routesConstants.BUSINESS_OVERVIEW,
    },
    {
      name: "City",
      path: routesConstants.BUSINESS_CITY_MANAGEMENT,
      active: true,
    },
  ],
  OVERVIEW_HUB: [
    {
      name: "Overview",
      path: routesConstants.BUSINESS_OVERVIEW,
    },
    {
      name: "hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
      active: true,
    },
  ],
  OVERVIEW_ADD_HUB: [
    {
      name: "Overview",
      path: routesConstants.BUSINESS_OVERVIEW,
    },
    {
      name: "hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    },
    {
      name: "Add Hub",
      path: routesConstants.ADD_BUSINESS_WAREHOUSE_MANAGEMENT,
      active: true,
    },
  ],
  OVERVIEW_EDIT_HUB: [
    {
      name: "Overview",
      path: routesConstants.BUSINESS_OVERVIEW,
    },
    {
      name: "hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    },
    {
      name: "Edit Hub",
      path: routesConstants.EDIT_BUSINESS_WAREHOUSE_MANAGEMENT,
      active: true,
    },
  ],
  IOT_BULK_UPLOAD: [
    {
      name: "IOT List",
      path: routesConstants.IOT_LISTING,
    },
    {
      name: "Bulk Upload IOT",
      path: routesConstants.BULK_UPLOAD_IOT,
      active: true,
    },
  ],
  IOT_ADD_DEVICE: [
    {
      name: "IOT List",
      path: routesConstants.IOT_LISTING,
    },
    {
      name: "Add IOT Device",
      path: routesConstants.ADD_IOT,
      active: true,
    },
  ],
  IOT_EDIT_DEVICE: [
    {
      name: "IOT List",
      path: routesConstants.IOT_LISTING,
    },
    {
      name: "Edit IOT Device",
      path: routesConstants.EDIT_IOT,
      active: true,
    },
  ],
  VIEW_VEHICLE_DETAILS: [
    {
      name: "Vehicle List",
      path: routesConstants.ALL_VEHICLES,
    },
    {
      name: "View Vehicle Details",
      path: routesConstants.VIEW_VEHICLE,
      active: true,
    },
  ],
  DRAG_TASK_SEQUENCE: (id: number) => [
    {
      name: "Task Sequence",
      path: `${routesConstants.TASK_SEQUENCE}`,
    },
    {
      name: "Task Assignment",
      path: routesConstants.TASK_ASSIGNMENT,
      active: true,
    },
  ],
  TASK_ASSIGNMENT: (id: number) => [
    {
      name: "Booking Details",
      path: `${routesConstants.BOOKING_DETAILS}/${id}`,
    },
    {
      name: "Task Assignment",
      path: routesConstants.TASK_ASSIGNMENT,
      active: true,
    },
  ],
  TASK_SEQUENCE: [
    {
      name: "Hub",
      path: routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
    },
    {
      name: "Task Sequence",
      path: routesConstants.TASK_ASSIGNMENT,
      active: true,
    },
  ],
  MODIFY_BOOKING: (id: number | string) => [
    {
      name: "Booking List",
      path: routesConstants.ALL_BOOKINGS,
    },
    {
      name: "Booking Details",
      path: `${routesConstants.BOOKING_DETAILS}/${id}`,
    },
    {
      name: "Modify Booking",
      path: routesConstants.TASK_ASSIGNMENT,
      active: true,
    },
  ],
  OPERATOR_LEAD: [
    {
      name: "Lead List",
      path: routesConstants.OPERATOR_MANAGEMENT_LEADS,
    },
    {
      name: "Lead Details",
      path: routesConstants.OPERATOR_MANAGEMENT_LEAD_DETAILS,
      active: true,
    },
  ],
  OPERATOR_LIST: [
    {
      name: "Operator List",
      path: routesConstants.APPROVED_OPERATOR,
    },
    {
      name: "Operator Details",
      path: routesConstants.APPROVED_OPERATOR_DETAILS,
      active: true,
    },
  ],
};
