import { alpha, createTheme, useMediaQuery } from "@mui/material";
import { green } from "@mui/material/colors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { colors } from "../../themes/colors";

const freedoXLightMode = {
  primary: {
    // main: colors.THEME_BLUE,
    main: colors.THEME_FREEDOX,
    // contrastText: colors.white,
    contrastText: colors.black,
    // light:colors.THEME_BLUEX,
  },
  secondary: {
    main: colors.text_gray,
  },
  badge: {
    main: colors.bg_btn_color,
    seconday: "#1BC4A6",
  },
  background: {
    paper: colors.white,
    default: colors.white,
    success: colors.table_success_bg_YOR,
    secondary: colors.table_secondary_bg_YOR,
    warning: colors.table_warning_bg_YOR,
    error: colors.table_error_bg_YOR,
    info: colors.table_info_bg_YOR,
  },
  drawerMenu: {
    background: {
      main: colors.white,
    },
    text: {
      main: colors.black,
      secondary: colors.text_gray,
    },
  },
  text: {
    primary: colors.text_black,
    secondary: colors.text_gray,
  },
  tableDataGrid: {
    success: colors.table_success_bg_YOR,
    secondary: colors.table_secondary_bg_YOR,
    warning: colors.table_warning_bg_YOR,
    error: colors.table_error_bg_YOR,
    info: colors.table_info_bg_YOR,
  },
};
const freedoXDarkMode = {
  primary: {
    main: colors.THEME_BLUE,
    contrastText: colors.white,
  },
  secondary: {
    main: green[500],
  },
  badge: {
    main: colors.bg_btn_color,
  },
  background: {
    paper: colors.black,
    default: colors.black,
  },
  drawerMenu: {
    background: {
      main: colors.black,
    },
    text: {
      main: colors.white,
    },
  },
};

const rentalsLightMode = {
  primary: {
    main: colors.THEME_BLUE,
    contrastText: colors.white,
  },
  secondary: {
    main: green[500],
  },
  badge: {
    main: colors.bg_btn_color,
  },
  background: {
    paper: colors.white,
    default: colors.THEME_BLUE,
  },
  drawerMenu: {
    background: {
      main: colors.black,
    },
    text: {
      primary: colors.text_black,
      secondary: colors.text_gray,
    },
  },
};
const rentalsDarkMode = {
  primary: {
    main: colors.THEME_BLUE,
    contrastText: colors.white,
  },
  secondary: {
    main: green[500],
  },
  badge: {
    main: colors.bg_btn_color,
  },
  background: {
    paper: colors.black,
    default: colors.THEME_BLUE,
  },
  drawerMenu: {
    background: {
      main: colors.black,
    },
    text: {
      main: colors.white,
    },
  },
};
const typographyObj = {
  fontFamily: "Euclid Circular B, Helvetica, Arial, sans-serif",
};
const rentalsTypography = {
  fontFamily: "Euclid Circular B, Helvetica, Arial, sans-serif",
  XLheading: {
    lineHeight: 1.6,
    fontSize: 20,
    fontWeight: 500,
    color: "#182035",
    padding: "12px 0px",
  },
  heading: {
    lineHeight: 1.6,
    fontSize: 15,
    fontWeight: 500,
    color: colors.text_black,
    padding: "12px 0px",
  },
  headingNoPadding: {
    lineHeight: 1.6,
    fontSize: 15,
    fontWeight: 500,
    color: colors.text_black,
  },
  subheading: {
    lineHeight: 1.6,
    fontSize: 14,
    fontWeight: 500,
  },
  body1Italic: {
    lineHeight: 1.6,
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: 500,
  },
  body1: {
    lineHeight: 1.6,
    fontSize: 12,
    fontWeight: 400,
  },
  errorMessage1: {
    fontWeight: 400,
    color: "#d02f2f", // mui red
    fontSize: 12,
  },
  smallText: {
    lineHeight: "10px",
    fontSize: 10,
    fontWeight: 400,
  },
  extraSmall: {
    lineHeight: "12px",
    fontSize: 8,
    fontWeight: 400,
  },
};
const freedoXTypography = {
  fontFamily: "Euclid Circular B, Helvetica, Arial, sans-serif",
};

const rentalsComponent = {
  MuiButton: {
    variants: [
      {
        props: { variant: "contained", color: "primary" },
        style: {
          textTransform: "none",
          border: "none",
          background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
          color: "#fff",
          borderRadius: 10,
          minHeight: "40px",
          maxHeight: "50px",
          fontSize: "14px",
          transition: "background-image 0.3s ease-in-out",
          padding: "7px 45px",
          gap: 8,
          boxShadow: "unset",
          "&:hover": {
            background: "#01353D",
          },
          "&:active": {
            background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
            boxShadow: "inset 0px 0px 20px rgba(238, 238, 238, 0.25)",
          },
          "&.Mui-disabled": {
            background: "#eaeaea",
            color: "#c0c0c0",
          },
        },
      },
      {
        props: { variant: "outlined", color: "primary" },
        style: {
          textTransform: "none",
          alignSelf: "stretch",
          padding: "7px 45px",
          background: "linear-gradient(0deg,  0%,  100%)",
          borderWidth: "1.50px",
          borderRadius: 10,
          minHeight: "40px",
          maxHeight: "50px",
          gap: 8,
          boxShadow: "unset",
          display: "inline-flex",
          // transition: "background-image 0.3s ease-in-out",
          "&:hover": {
            background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
            color: "white",
            borderWidth: "1.50px",
            borderColor: "transparent",
            borderLeftColor: colors.primary,
            borderRightColor: "#01353D",
          },
          "&:active": {
            background: colors.THEME_BLUE,
            boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
            color: "white",
            border: "none",
          },
        },
      },
      {
        props: { variant: "outlined", color: "error" },
        style: {
          textTransform: "none",
          alignSelf: "stretch",
          padding: "7px 45px",
          borderWidth: "1.50px",
          borderRadius: 10,
          minHeight: "40px",
          maxHeight: "50px",
          gap: 8,
          boxShadow: "unset",
          display: "inline-flex",
          border: `1px solid ${colors.primary_danger}`,
          color: colors.primary_danger,
          "&:hover": {
            color: "white",
            border: "1px solid  #d32f2f",
            background: "linear-gradient(90deg, #d32f2f -1.26%, #F3756A 100%)",
          },
        },
      },
      {
        props: { variant: "text", color: "primary" },
        style: {
          textTransform: "none",
          alignSelf: "stretch",
          padding: "8px 14px",
          borderRadius: 10,
          justifyContent: "center",
          alignItems: "center",
          gap: 8,
          boxShadow: "unset",
          display: "inline-flex",
          background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 45%)",
          "-webkit-text-fill-color": "transparent",
          "-webkit-background-clip": "text",
          "&:hover": {
            alignSelf: "stretch",
            textColor: "black", //
            "-webkit-text-fill-color": "#01353D",
            "-webkit-background-clip": "text",
            background:
              "linear-gradient(90deg, rgba(1, 53, 61, 0.24) 0%, rgba(43, 199, 201, 0.24) 100%)",
          },
          "&:active": {
            alignSelf: "stretch",
            background:
              "linear-gradient(0deg,  0%,  100%), linear-gradient(90deg, rgba(1, 53, 61, 0.24) 0%, rgba(43, 199, 201, 0.24) 60%)",
            boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
          },
        },
      },
    ],
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        ".MuiDataGrid-columnHeader": {
          color: colors.white,
          "& .MuiCheckbox-root svg": {
            color: "white",
          },
          "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
            {
              color: "white",
            },
        },
        "&::-webkit-scrollbar": {
          height: "1px",
          backgroundColor: "#f5f5f5",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "6px",
          backgroundColor: "#ccc",
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "6px",
          backgroundColor: "#f5f5f5",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
      },
    },
  },
};
export function useThemeMode() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { isFreedoX } = useSelector((state: RootState) => state.newAuthReducer);
  const { mode } = useSelector((state: RootState) => state.themeReducer);

  const lightMode = isFreedoX ? freedoXLightMode : rentalsLightMode;
  const darkMode = isFreedoX ? freedoXDarkMode : rentalsDarkMode;
  const typography = isFreedoX ? freedoXTypography : rentalsTypography;
  const components = isFreedoX ? {} : rentalsComponent;

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          ...(mode === "light" ? lightMode : darkMode),
        },
        shape: { borderRadius: 16 },
        ...typographyObj,
        typography: { ...typography },
        components: {
          ...components,
        },
      }),
    [mode, isFreedoX]
  );

  return theme;
}
