import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Skeleton, Stack } from "@mui/material";
import { colors } from "../../themes/colors";
import { typographyConstants } from "../../rentals-src/constants/constants";
import { CardBackGround } from "../../rentals-src/constants/exportImages";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BookingsCard({
  icon,
  text,
  subText,
  bgImg,
  loading,
}: any) {
  return (
    <Box
      sx={{
        minWidth: 230,
        width: "100%",
        border: "1px solid #00000014",
        boxShadow: "0px 0px 4px 0px #00000014",
        borderRadius: "16px",
        backgroundImage: bgImg ? `url(${bgImg})` : `url(${CardBackGround})`,
        backgroundRepeat: "repeat",
        padding: "15px 10px",
        objectFit: "cover",
      }}
    >
      <Box display="flex" sx={{ gap: "16px" }}>
        <Box>
          <img src={icon} />
        </Box>
        <Stack>
          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors.text_secondary_gray}
          >
            {text ?? ""}
          </Typography>
          <Typography
            variant={typographyConstants.HEADINGXL}
            color="drawerMenu.text.primary"
            sx={{ padding: "0px" }}
          >
            {loading ? (
              <Skeleton animation="wave" width={50} height={30} />
            ) : (
              subText ?? "N/A"
            )}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
