import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { CustomButton } from "../../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../../../themes/colors";
import { typographyConstants } from "../../../../../../constants/constants";
import styles from "../section.module.css";
import rentalEn from "../../../../../../locale/rental-en.json";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 4,
  padding: "0px 0px 10px 0px ",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  borderRadius: "16px",
};

export default function BulkUploadZipModal({
  openZipModal,
  handleCloseZipModal,
  onClickZipUpload = () => {},
}: any) {
  return (
    <div>
      <Modal
        disableAutoFocus
        open={openZipModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              backgroundColor: colors.THEME_BLUE,
            }}
            className={styles.bulkUploadErrorModal}
          >
            <Typography
              variant={typographyConstants.HEADING}
              color={colors.white}
              sx={{ fontSize: "14px" }}
            >
              {rentalEn?.VehicleManagement?.uploadZipFile}
            </Typography>
            <IconButton onClick={handleCloseZipModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={styles.bulkUploadDesc}>
            <Typography variant={typographyConstants.BODY}>
              {rentalEn.VehicleManagement?.clickbelowUploadRelevantZipFile}
            </Typography>
            <CustomButton
              label={rentalEn?.buttonLabels?.uploadFile}
              variant="contained"
              sx={{
                padding: "16px 30px",
                fontSize: "12px",
                maxHeight: "34px",
                height: "34px",
                minHeight: "25px",
              }}
              onClick={() => {
                onClickZipUpload();
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
