import { Box, Grid, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomSelect from "../../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { DataTable } from "../../../../../../../sharedComponents/templates/Tables/dataTable";
import {
  TypeOfTransfer,
  typographyConstants,
} from "../../../../../../constants/constants";
import {
  CityInputIcon,
  WarehouseIcon,
} from "../../../../../../constants/exportImages";
import rentalEn from "../../../../../../locale/rental-en.json";
import { rentalActions } from "../../../../../../redux/actions";

//in progress
export const ReceivingDetails = (props: any) => {
  const dispatch = useDispatch();
  const {
    handleChange,
    allCitiesDetails,
    fields,
    branches,
    initialState,
    setFields,
    selectedRows,
    columns,
    errorFields,
    receivingLob,
  } = props;

  return (
    <Stack gap="25px">
      <Stack sx={{ backgroundColor: "#01353D0A", padding: "10px 24px 24px" }}>
        <Typography variant={typographyConstants.HEADING}>
          {fields?.transferType?.name === TypeOfTransfer.BRANCH_TRANSFER ? (
            rentalEn.VehicleManagement.selectRecevingCityWarehouse
          ) : (
            <Box gap="10px">
              {rentalEn.VehicleManagement.selectTransferLob}{" "}
              {receivingLob?.displayName}
            </Box>
          )}
        </Typography>
        <Grid container spacing={2}>
          {fields?.transferType?.name === TypeOfTransfer.BRANCH_TRANSFER ? (
            <>
              <Grid item xs={12} md={3}>
                {/* SELECT CITY */}
                <CustomSelect
                  required
                  icon={CityInputIcon}
                  placeholder={rentalEn.global.SelectCity}
                  value={fields?.selectedRecevingCity?.displayName}
                  choice={allCitiesDetails}
                  defaultKey={"displayName"}
                  errormessage={errorFields.selectedRecevingCity}
                  handleChange={(city: any) => {
                    handleChange("selectedRecevingCity", city);
                    dispatch(
                      rentalActions.getAllBranches({ name: city?.name })
                    );
                    setFields((prev: any) => ({
                      ...prev,
                      selectedReceivingBranch:
                        initialState.selectedReceivingBranch,
                    }));
                  }}
                  maxWidth={"300px"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {/* SELECT WAREHOUSE */}
                <CustomSelect
                  required
                  icon={WarehouseIcon}
                  placeholder={rentalEn.global.selectHub}
                  value={fields?.selectedReceivingBranch?.displayName}
                  choice={branches}
                  defaultKey={"displayName"}
                  errormessage={errorFields.selectedReceivingBranch}
                  disabled={
                    !fields.selectedRecevingCity.displayName ||
                    branches?.length === 0
                  }
                  handleChange={(branch: any) => {
                    handleChange("selectedReceivingBranch", branch);
                  }}
                  maxWidth={"300px"}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Stack>
      <Stack sx={{ marginBottom: "60px" }}>
        <Typography variant={typographyConstants.HEADING}>
          {rentalEn.VehicleManagement.transferVehicleSummary}
        </Typography>

        <DataTable
          columns={columns}
          rows={selectedRows}
          paginationMode="server"
          hideFooter
        />
      </Stack>
    </Stack>
  );
};
