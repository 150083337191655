import { API_URLS, customAxios } from "../config";

const getAllCities = (payload: any) => {
  try {
    let cityUrl = API_URLS.getAllCities;
    if (payload?.page) cityUrl += `page=${payload?.page}`;

    if (payload?.size && !isNaN(payload?.size))
      cityUrl += `&size=${payload?.size}`;
    return fetchAllCities(cityUrl);
  } catch (ex) {
    return ex;
  }
};

const getAllCategories = (payload: any) => {
  try {
    let cityUrl = API_URLS.getAllCategory;
    if (payload?.page) cityUrl += `page=${payload?.page}`;

    if (payload?.size && !isNaN(payload?.size))
      cityUrl += `&size=${payload?.size}`;
    return fetchAllCategories(cityUrl);
  } catch (ex) {
    return ex;
  }
};

const getAllModels = (payload: any) => {
  try {
    let cityUrl = API_URLS.getAllVehicleModel;
    if (payload?.page) cityUrl += `page=${payload?.page}`;

    if (payload?.size && !isNaN(payload?.size))
      cityUrl += `&size=${payload?.size}`;
    return fetchAllModels(cityUrl);
  } catch (ex) {
    return ex;
  }
};

const getCityById = (payload: any) => {
  try {
    let route = API_URLS.getCityById;
    if (payload.city_id != 0 && payload.city_id && !isNaN(payload.city_id))
      route += `${payload.city_id}`;
    return fetchCityById(route);
  } catch (ex) {
    return ex;
  }
};

const getAdvertisementById = (payload: any) => {
  try {
    let route = API_URLS.getAdvertisementById;
    if (payload.adv_id != 0 && payload.adv_id && !isNaN(payload.adv_id))
      route += `${payload.adv_id}`;

    return fetchAdvertisementById(route);
  } catch (ex) {
    return ex;
  }
};

const getModelById = (payload: any) => {
  try {
    let route = API_URLS.getModelById;
    route += `${payload?.id}`;

    return fetchModelById(route);
  } catch (ex) {
    return ex;
  }
};

const getCategoryById = (payload: any) => {
  try {
    let route = API_URLS.getCategoryById;
    if (
      payload.category_id != 0 &&
      payload.category_id &&
      !isNaN(payload.category_id)
    )
      route += `${payload.category_id}`;

    return fetchCategoryById(route);
  } catch (ex) {
    return ex;
  }
};

const editCity = (payload: any) => {
  try {
    let cityUrl = API_URLS.editCity;
    return updateCity(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const editAdvertisement = (payload: any) => {
  try {
    let advUrl = API_URLS.editAdvertisement;
    return updateAdvertisement(advUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const editCategory = (payload: any) => {
  try {
    let cityUrl = API_URLS.editVehicleCategory;
    return updateCategory(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const editModel = (payload: any) => {
  try {
    let cityUrl = API_URLS.editVehicleModel;
    return updateModel(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const updateCity = (route: any, payload: any) =>
  customAxios.put(route, payload.formData).catch((ex) => ex);

const updateAdvertisement = (route: any, payload: any) =>
  customAxios.put(route, payload).catch((ex) => ex);

const updateCategory = (route: any, payload: any) =>
  customAxios
    .put(route, {
      id: payload?.id,
      category_name: payload?.category_name,
    })
    .catch((ex) => ex);

const updateModel = (route: any, payload: any) =>
  customAxios.put(route, payload.formData).catch((ex) => ex);

const addCity = (payload: any) => {
  try {
    let cityUrl = API_URLS.addCity;
    return addNewCity(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const addAdvertisement = (payload: any) => {
  try {
    let advUrl = API_URLS.addAdvertisement;
    return addNewAdvertisement(advUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const addCategory = (payload: any) => {
  try {
    let cityUrl = API_URLS.addVehicleCategory;
    return addVehicleCategory(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const addModel = (payload: any) => {
  try {
    // for(var key of payload.formData.entries())
    let cityUrl = API_URLS.addVehicleModel;
    return addVehicleModel(cityUrl, payload);
  } catch (ex) {
    return ex;
  }
};

const addNewCity = (route: any, payload: any) =>
  customAxios.post(route, payload.formData).catch((ex) => ex);

const addNewAdvertisement = (route: any, payload: any) =>
  customAxios.post(route, payload).catch((ex) => ex);

const addVehicleCategory = (route: any, payload: any) =>
  customAxios
    .post(route, {
      category_name: payload?.category_name,
    })
    .catch((ex) => ex);

const addVehicleModel = (route: any, payload: any) =>
  customAxios.post(route, payload.formData).catch((ex) => ex);

const deleteCategory = (payload: any) => {
  try {
    let route = API_URLS.deleteCategory;
    if (payload?.id != 0 && payload?.id && !isNaN(payload?.id))
      route += `${payload?.id}`;
    return deleteVehicleCategory(route, payload);
  } catch (ex) {
    return ex;
  }
};

const deleteVehicleCategory = (route: any, payload: any) =>
  customAxios
    .put(route, {
      id: payload?.id,
    })
    .catch((ex) => ex);

const deleteModel = (payload: any) => {
  try {
    let route = API_URLS.deleteModel;
    if (payload?.id != 0 && payload?.id && !isNaN(payload?.id))
      route += `${payload?.id}`;
    return deleteVehicleCategory(route, payload);
  } catch (ex) {
    return ex;
  }
};

const deleteVehicleModel = (route: any, payload: any) =>
  customAxios
    .put(route, {
      id: payload?.id,
    })
    .catch((ex) => ex);

const fetchAllCities = (route: any) => customAxios.get(route).catch((ex) => ex);
const fetchAllCategories = (route: any) =>
  customAxios.get(route).catch((ex) => ex);
const fetchAllModels = (route: any) => customAxios.get(route).catch((ex) => ex);

const fetchCityById = (route: any) => customAxios.get(route).catch((ex) => ex);
const fetchAdvertisementById = (route: any) =>
  customAxios.get(route).catch((ex) => ex);

const fetchModelById = (route: any) => customAxios.get(route).catch((ex) => ex);
const fetchCategoryById = (route: any) =>
  customAxios.get(route).catch((ex) => ex);

export default {
  getAllCities,
  addCity,
  getCityById,
  editCity,
  getAllCategories,
  getCategoryById,
  addCategory,
  editCategory,
  deleteCategory,
  getAllModels,
  getModelById,
  editModel,
  addModel,
  deleteModel,
  getAdvertisementById,
  editAdvertisement,
  addAdvertisement,
};
