import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import DetailsCard from "./Sections/detailsCard";
import {
  getCityOverview,
  getWarehouseOverview,
} from "../../../redux/actions/businessManagementActions";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../../utils/RoutesConstants";
import { cityIcon, WarehouseIcon } from "../../../constants/exportImages";
import { BranchOverview, CityOverview } from "../../../constants/constants";
import en from "../../../locale/rental-en.json";
const BusinessOverview = () => {
  const { cityOverview, warehouseOverview } = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCityOverview());
    dispatch(getWarehouseOverview());
  }, []);
  const handleClick = (value: any, state: any) => {
    navigate(value, { state: state });
  };
  return (
    <>
      <Grid container spacing={2}>
        <DetailsCard
          icon={cityIcon}
          title={en.NewBusinessManagement.cityDetails}
          keys={CityOverview}
          values={cityOverview}
          handleClick={() =>
            handleClick(
              routesConstants.BUSINESS_CITY_MANAGEMENT,
              "OVERVIEW_CITY"
            )
          }
        />
        <DetailsCard
          icon={WarehouseIcon}
          title={en.NewBusinessManagement.warehouseDetails}
          keys={BranchOverview}
          values={warehouseOverview}
          handleClick={() =>
            handleClick(
              routesConstants.BUSINESS_WAREHOUSE_MANAGEMENT,
              "OVERVIEW_HUB"
            )
          }
        />
      </Grid>
    </>
  );
};
export default BusinessOverview;
