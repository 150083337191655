import * as actionTypes from "../actionTypes";

interface CouponInterface {
  page?: number | string;
  size?: number | string;
  orderBy?: any;
  id?: any;
  token?: any;
  couponId?: any;
  name?: any;
  code?: any;
  description?: any;
  terms_and_conditions?: any;
  type_id?: any;
  start_date?: any;
  end_date?: any;
  discount?: any;
  unit?: any;
  upto?: any;
  max?: any;
  status?: any;
  city_ids?: any;
  vehicle_model_ids?: any;
  package_ids?: any;
  apply_on_package?: any;
  free_additonal_helmet?: any;
  free_home_delivery?: any;
  monthly?: any;
  weekly?: any;
  download_type?: any;
  model?: any;
}

export const getCouponById = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPON_BY_ID_REQUESTED,
  payload,
});

export const deleteCoupon = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.DELETE_COUPON_REQUESTED,
  payload,
});

export const addCoupon = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.ADD_COUPON_REQUESTED,
  payload,
});

export const editCoupon = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.EDIT_COUPON_REQUESTED,
  payload,
});

export const getCouponUsesById = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPON_USES_REQUESTED,
  payload,
});
export const getCouponRevenueById = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_REQUESTED,
  payload,
});
export const getCouponRevenueDownloadById = (payload: CouponInterface) => ({
  type: actionTypes.COUPONS_ACTIONS.GET_COUPON_REVENUE_DOWNLOAD_REQUESTED,
  payload,
});
