import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { SLOT_PAUSE_ACTIONS } from "../../actions/actionTypes";
import { slotPauseInterface } from "../actionInterfaces";
import { Grid } from "@mui/material";

const initialState = {
  payload: {},
  loading: true,
  slotPauseData: null,
  slotPauseDataUpdated: null,
  errorMessage: "",
  deleted: false,
  slotPauseById: null,
  navigateBack: false,
  deleteSlotRefresh: false
};

const slotPauseReducer = (state = initialState, action: slotPauseInterface) => {
  const prevState = { ...state };
  const { type, payload } = action;

  function filterData(bookingData: any) {
    let bookingFilteredData: any = [];
    let i = 0;
    if (bookingData?.length > 0) {
      bookingData.map((f: any, index: any) => {
        let res = {
          srNo: index + 1,
          id: f?.id ? f?.id : 0,
          date_to_pause: f?.date_to_pause,
          timeSlots: f?.slot_pause_option_values.map(
            (item: any, indexs: any) => {
              var dd: any = [];
              var pp: any = [];
              if (item.slot_pause_option.id == 1) {
                return "Full Day Pause";
                // <Text
                //   key={indexs + 1}
                //   style={[{ color: "#808080 " }, { ...TableContentText }]}
                // >
                //   {"Full Day Pause"}
                // </Text>
              } else {
                if (
                  item.slot_pause_option.id == 2 &&
                  item.slot_pause_timeslots.length > 0
                ) {
                  item.slot_pause_timeslots.map((itemm: any, indexx: any) => {
                    dd.push(
                      itemm.start_time.substring(0, 2) +
                      itemm.start_time.substring(6) +
                      "-" +
                      itemm.end_time.substring(0, 2) +
                      itemm.end_time.substring(6)
                    );
                  });
                }
                if (
                  item.slot_pause_option.id == 3 &&
                  item.slot_pause_timeslots.length > 0
                ) {
                  item.slot_pause_timeslots.map((itemm: any, indexx: any) => {
                    pp.push(
                      itemm.start_time.substring(0, 2) +
                      itemm.start_time.substring(6) +
                      "-" +
                      itemm.end_time.substring(0, 2) +
                      itemm.end_time.substring(6)
                    );
                  });
                }
                return dd.length > 0 && pp.length > 0
                  ? dd.slice(0, 0) +
                  "HomeDelivery(" +
                  dd.slice(0, dd.length) +
                  ") " +
                  pp.slice(0, 0) +
                  "Pickup(" +
                  pp.slice(0, pp.length) +
                  ") "
                  : dd.length > 0 && pp.length == 0
                    ? dd.slice(0, 0) +
                    "HomeDelivery(" +
                    dd.slice(0, dd.length) +
                    ") "
                    : pp.length > 0 && dd.length == 0
                      ? pp.slice(0, 0) + "Pickup(" + pp.slice(0, pp.length) + ") "
                      : null;
              }
            }
          ),
          cities: f.slot_pause_option_values.map((item: any, indexr: any) => {
            var cities: any = [];
            if (indexr == 0) {
              item.slot_pause_cities.map((itemm: any, indexx: any) => {
                if (itemm.city != null) {
                  cities.push(itemm.city.city_name);
                }
              });
              return cities.length > 1
                ? `${cities.slice(0, -1).join(", ")} & ${cities[cities.length - 1]
                }`
                : cities;
            }
          }),
          status: getStatusFromId(
            f?.slot_pause_option_values[0].status_master.id
          ),
          actions: <Grid />,
        };
        bookingFilteredData.push(res);
      });

      return bookingFilteredData;
    }
    return bookingFilteredData;
  }

  switch (type) {
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_REQUESTED:
      return {
        ...state,
        loading: true,
        slotPauseById: null,
        navigateBack: false,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_SUCCESS:
      return {
        ...state,
        ...payload,
        totalItems: payload?.Result?.totalItems,
        // slotPauseDataUpdated:payload?.Result?.data,
        slotPauseData: payload?.Result?.data,
        loading: false,
        navigateBack: false,
        deleteSlotRefresh: false
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading: false,
        navigateBack: false,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_BY_ID_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        slotPauseById: payload?.Result,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_PAUSE_BY_ID_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading: false,
      };

    case SLOT_PAUSE_ACTIONS.GET_SLOT_BY_BRANCH_PAUSE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_BY_BRANCH_PAUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        slotsByBranch: payload?.Result,
      };
    case SLOT_PAUSE_ACTIONS.GET_SLOT_BY_BRANCH_PAUSE_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading: false,
      };

    case SLOT_PAUSE_ACTIONS.ADD_SLOT_PAUSE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SLOT_PAUSE_ACTIONS.ADD_SLOT_PAUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        navigateBack: true,
      };
    case SLOT_PAUSE_ACTIONS.ADD_SLOT_PAUSE_FAILED:
      return {
        ...state,
        errorMessage: payload?.errorMessage,
        loading: false,
      };

    case SLOT_PAUSE_ACTIONS.DELETE_SLOT_PAUSE_REQUESTED:
      return {
        ...state,
        loading: true,
        deleteSlotRefresh: false
      };
    case SLOT_PAUSE_ACTIONS.DELETE_SLOT_PAUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        deleteSlotRefresh: true

      };
    case SLOT_PAUSE_ACTIONS.DELETE_SLOT_PAUSE_FAILED:
      return {
        ...state,
        loading: false,
        deleteSlotRefresh: false

      };

    default:
      return { ...prevState };
  }
};
function getStatusFromId(id: number) {
  if (id === 1) return "Active";
  else if (id === 2) return "InActive";

  // else.....
}
export default slotPauseReducer;
