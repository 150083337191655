import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { getFormattedINR } from "../../freedo-x-src/utils/helper";
import { Box } from "@mui/material";
import { HELMETS_DISPLAY_NAME } from "../../freedo-x-src/constantsX/booking";
import { colors } from "../../freedo-x-src/themes/colors";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const CustomizedTooltips = (props: any) => {
  let helmetBreakups = props?.content?.breakUp?.helmets;
  let helmetCount = props?.addOnCountObj;
  let helmetTypes = Object.keys(props?.addOnCountObj);
  return (
    <div>
      <HtmlTooltip
        title={
          <>
            <Box sx={{ width: 200 }}>
              <Box color="inherit">
                <b>Addons</b>
              </Box>
              {helmetTypes?.map((addOnType: string) => (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ display: "flex", gap: "2px" }}>
                    {/* {HELMETS_DISPLAY_NAME[addOnType]} */}
                    {HELMETS_DISPLAY_NAME[addOnType]}
                    <span style={{ color: colors.primary_aqua_blue }}>
                      X {helmetCount[addOnType]}
                    </span>
                  </span>
                  <span>{getFormattedINR(helmetBreakups[addOnType])}</span>
                </Box>
              ))}
            </Box>
          </>
        }
      >
        <img src={props?.icon} alt="" width={24} height={24} />
      </HtmlTooltip>
    </div>
  );
};

export default CustomizedTooltips;
