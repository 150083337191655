import axios from "axios";

import {
  API_URLS,
  BASE_URL_UAT,
  versioning1,
  admin,
  customAxios,
  BASE_URL,
} from "../config";

const userProfile = (payload: any) =>
  customAxios
    .post(BASE_URL + API_URLS.profile, payload)
    .catch((ex) => ex);

const userLogout = (payload: any) =>{
  const url = BASE_URL + API_URLS.userLogout
  return customAxios.post(url,payload).catch((ex) => ex);}

export default { userProfile, userLogout };
