import { ToastType } from '../../../constants/userActions';
import en from '../../../locale/en.json'
import { ACTIONS } from '../../actions/actionTypes';
import { snackBarInterface } from '../actionInterfaces';

const initialState = {
    showToast: false,
    toastMessage: '',
    toastType: ToastType.success,
}

const toastReducer = (state = initialState, action: snackBarInterface) => {

    const prevState = { ...state }
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.SHOW_TOAST:
            return { ...prevState, showToast: true, toastMessage: payload.toastMessage ,toastType : payload.toastType }
        case ACTIONS.HIDE_TOAST:
            return { ...prevState, showToast: false}
        default: return { ...prevState }
    }
}

export default toastReducer
