import { Verified as VerifiedIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import CustomButtonGroup from "../../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import CustomModal from "../../../../../sharedComponents/molecules/Modals/CustomModal";
import { colors } from "../../../../../themes/colors";
import {
  appliesTo,
  GenericObject,
  typographyConstants,
} from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";
import {
  addNewRoleUser,
  fetchUserDetailsByUserIdAction,
  getRolesList,
  handleChangeAction,
  saveRoleDataAction,
  updateRoleUser,
} from "../../../../redux/actions/businessManagementActions";
import {
  getAllBranchesByCity,
  getAllStates,
  getCitiesBasedOnMultipleStates,
} from "../../../../redux/actions/rentalCommonAction";
import { validateEmail } from "../../../../utils/helper";
import { checkAlfa, checkPhone } from "../../../../utils/regex";
import { routesConstants } from "../../../../utils/RoutesConstants";
import AddUserSection from "./sections/AddUserSection";
import AssignRolesSection from "./sections/AssignRolesSection";
import { StyleObject } from "./sections/StyleObject";

const { roleManagement } = rentalEn;

const initialAddUserState: GenericObject = {
  name: "",
  emailId: "",
  phone: "",
};

const AddEditRole = () => {
  const { id: userIdParams } = useParams(); // Access the data passed in the route using the 'useParams' hook
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isEditing: boolean = userIdParams ? true : false;

  const { addUserResponseData, userDetailsByUserIdData } = useSelector(
    (state: RootState) => state.newBusinessManagementReducer
  );
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);
  const { allStatesList, citiesBasedOnMultiStates, hubsByMultipleCities } =
    useSelector((state: RootState) => state.rentalsCommonReducer);

  const [selectedTab, setSelectedTab] = useState<number>(0); // handle tab selection
  const [fields, setFields] = useState<GenericObject>({
    ...initialAddUserState,
  });
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getAllStates());
  }, []);

  useEffect(() => {
    if (isEditing && allStatesList?.length) {
      // Fetch cities based on states
      let tempStateArr: string[] = allStatesList?.map(
        (state: any) => state?.name
      );
      if (allStatesList?.length) {
        dispatch(getCitiesBasedOnMultipleStates({ states: tempStateArr }));
      }
    }
  }, [isEditing, JSON?.stringify(allStatesList)]);

  useEffect(() => {
    if (isEditing && citiesBasedOnMultiStates?.length) {
      // Fetch hubs based on cities
      let tempCityArr: string[] = citiesBasedOnMultiStates?.map(
        (city: any) => city?.name
      );
      if (citiesBasedOnMultiStates?.length) {
        dispatch(getAllBranchesByCity({ cities: tempCityArr }));
      }
    }
  }, [isEditing, JSON?.stringify(citiesBasedOnMultiStates)]);

  useEffect(() => {
    if (userIdParams) {
      // Fetch user details by userId
      dispatch(fetchUserDetailsByUserIdAction(userIdParams));
    }
  }, [userIdParams]);

  useEffect(() => {
    if (Object.keys(userDetailsByUserIdData).length) {
      // Update the fields with the user details
      setFields({
        name: userDetailsByUserIdData?.name,
        emailId: userDetailsByUserIdData?.emailId,
        phone: userDetailsByUserIdData?.phone,
      });
    }
  }, [JSON.stringify(userDetailsByUserIdData)]);

  const handleTabChange = (index: number) => {
    if (userIdParams) {
      // Fetch user details by userId
      dispatch(fetchUserDetailsByUserIdAction(userIdParams));
    }
    setSelectedTab(index);
    tabsArray[index].onClick(); // Execute onPress function for the selected tab
  };

  const onChangeHandler = (key: string, value: any) => {
    // Object to accumulate fields that need updating together
    let updateFields: GenericObject = {};

    // Determine action based on the provided key
    switch (key) {
      case "name":
        if (checkAlfa(value)) {
          updateFields[key] = value;
        }
        break;

      case "emailId":
        updateFields[key] = value;
        break;

      case "phone":
        if (checkPhone(value)) {
          updateFields[key] = value;
        }
        break;

      default:
        break;
    }

    // Merge all updates including current key-value pair with previous state
    setFields({ ...fields, ...updateFields });
  };

  const isNextButtonValid = (): boolean => {
    const { name, emailId, phone } = fields;
    return (
      name?.trim() && validateEmail(emailId)?.status && phone?.length === 10
    );
  };

  const addUpdateUserApiSuccessCallback = (key?: string | undefined) => {
    if (key === "add") {
      setOpen(true);
    }
    // On success of add/ update user, fetch roles-list data to show in the next tab
    dispatch(getRolesList());
    setSelectedTab(1);

    if (isEditing && userIdParams) {
      dispatch(fetchUserDetailsByUserIdAction(userIdParams)); // Make user details api call here to get the updated data
    }
  };

  const addUpdateUserHandler = (key: string) => {
    if (key === "add-user") {
      const payload: GenericObject = {
        data: {
          name: fields?.name.trim(),
          emailId: fields?.emailId.trim(),
          phone: fields?.phone,
        },
      };
      dispatch(addNewRoleUser(payload, addUpdateUserApiSuccessCallback));
    } else if (key === "update-user") {
      const payload: GenericObject = {
        data: { name: fields?.name.trim() },
      };
      const userId: string = userIdParams || addUserResponseData?.userId;
      dispatch(
        updateRoleUser(payload, userId, addUpdateUserApiSuccessCallback)
      );
    }
  };

  const saveRoleApiSuccessCallbackFunction = () => {
    const payload: any = {
      key: "reset",
    };
    dispatch(handleChangeAction(payload));
    navigate(`${routesConstants.ROLE_MANAGEMENT}`);
  };

  // Construct payload for user role assignment
  const constructPayload = (data: any) => {
    const payload: GenericObject = {
      data: {
        deleteRoles: [],
        updateRoles: [],
        addRoles: [],
      },
    };

    let tempArr: any = data?.map((item: any, i: number) => {
      let tempItem: any = {};

      tempItem.roleId = item?.roleId?._id;
      tempItem.operatorIds = userData?.authorizedOperators;

      tempItem.locationData = [];

      if (item?.roleId?.appliesTo === appliesTo?.COUNTRY) {
        tempItem.locationData.push({
          type: appliesTo?.COUNTRY,
          names: [roleManagement?.IN],
        });
      }

      if (item?.roleId?.appliesTo === appliesTo?.STATE) {
        tempItem.locationData.push({
          type: appliesTo?.STATE,
          names: [...item?.selectedState],
        });
      }

      if (item?.roleId?.appliesTo === appliesTo?.CITY) {
        tempItem.locationData.push({
          type: appliesTo?.CITY,
          names: [...item?.selectedCity],
        });
      }

      if (item?.roleId?.appliesTo === appliesTo?.CITY) {
        tempItem.locationData.push({
          type: appliesTo?.CITY,
          names: [...item?.selectedCity],
        });
      }

      if (item?.roleId?.appliesTo === appliesTo?.BRANCH) {
        if (item?.selectedBranch?.length) {
          tempItem.locationData.push({
            type: appliesTo?.BRANCH,
            names: [...item?.selectedBranch],
          });
        }
      }

      switch (item.operation) {
        case "add":
          payload.data.addRoles.push(tempItem);
          break;
        case "delete":
          payload.data.deleteRoles.push(tempItem);
          break;
        case "update":
          payload.data.updateRoles.push(tempItem);
          break;

        default:
          break;
      }
    });

    return payload;
  };

  // It validates the Roles data and returns 'true' if it is valid
  const validateData = (data: any): boolean => {
    return data?.every((item: any) => {
      const roleId: string = item?.roleId?._id;
      const appliesTo: string = item?.roleId?.appliesTo;

      // If roleId._id is not present, we skip validation for this item
      if (!roleId) {
        return false;
      }

      switch (appliesTo) {
        case "BRANCH":
          // Check if selectedBranch, selectedCity, & selectedState have at least 1 length
          return (
            item?.selectedBranch?.length > 0 &&
            item?.selectedCity?.length > 0 &&
            item?.selectedState?.length > 0
          );

        case "CITY":
          // Check if selectedCity & selectedState have at least 1 length
          return (
            item?.selectedCity?.length > 0 && item?.selectedState?.length > 0
          );

        case "STATE":
          // Check if selectedState has at least 1 length
          return item?.selectedState?.length > 0;

        case "COUNTRY":
          // If operation is "add", Check if selectedBranch, selectedCity, & selectedState must be empty,
          // otherwise Check if selectedBranch, selectedCity, & selectedState have at least 1 length for "update" and "delete" operations
          if (item?.operation === "add") {
            return (
              item?.selectedBranch?.length === 0 &&
              item?.selectedCity?.length === 0 &&
              item?.selectedState?.length === 0
            );
          } else {
            return (
              item?.selectedBranch?.length > 0 &&
              item?.selectedCity?.length > 0 &&
              item?.selectedState?.length > 0
            );
          }

        default:
          return false; // Invalid appliesTo value
      }
    });
  };

  const handleAssignRole = (data: any) => {
    const payload: GenericObject = constructPayload(data);
    const id: string = isEditing
      ? userIdParams
      : addUserResponseData?.userId
      ? addUserResponseData?.userId
      : addUserResponseData?.userId;
    const apiSuccessCallback: Function = saveRoleApiSuccessCallbackFunction;
    dispatch(saveRoleDataAction(payload, id, apiSuccessCallback));
  };

  const tabsArray: GenericObject[] = [
    {
      label:
        isEditing || addUserResponseData?.userId
          ? roleManagement?.updateUser
          : roleManagement?.addUser,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: null,
      tabStyle: { ...StyleObject?.tabStyle1 },
    },
    {
      label: isEditing
        ? roleManagement?.editRoles
        : roleManagement?.assignRoles,
      onClick: () => null,
      selectedTab: selectedTab,
      // tabValidation: !isNextButtonValid(),
      tabValidation: isEditing
        ? false
        : isNextButtonValid() && addUserResponseData?.userId
        ? false
        : true,
      tabStyle: { ...StyleObject?.tabStyle2 },
    },
  ];

  return (
    <Box sx={StyleObject?.wrapperBox}>
      <Box>
        {/* top tab view */}
        {
          <CustomButtonGroup
            handleTabChange={handleTabChange}
            tabsArray={tabsArray}
            setSelectedTab={setSelectedTab}
          />
        }

        {/* tab's content view */}
        <Box sx={StyleObject?.tabView_wrapperBox}>
          {selectedTab === 0 ? (
            <AddUserSection
              fields={fields}
              onChangeHandler={onChangeHandler}
              addUpdateUserHandler={addUpdateUserHandler}
              isNextButtonValid={isNextButtonValid}
              isEditing={isEditing}
            />
          ) : selectedTab === 1 ? (
            <AssignRolesSection
              setSelectedTab={setSelectedTab}
              handleAssignRole={handleAssignRole}
              isEditing={isEditing}
              validateData={validateData}
            />
          ) : null}
        </Box>
        {
          <CustomModal
            isOpen={open}
            handleClose={handleClose}
            title={roleManagement?.userAddedSuccessfully}
          >
            <Stack alignItems={"center"}>
              <VerifiedIcon sx={{ color: colors?.light_green, my: 2 }} />
              <Typography variant={typographyConstants?.BODY}>
                {roleManagement?.anEmailWithTheLoginCredentialIsSentToTheUser}
              </Typography>
            </Stack>
          </CustomModal>
        }
      </Box>
    </Box>
  );
};

export default AddEditRole;
