import * as actionTypes from "../actionTypes";
import {
  getAvailableVehicleListPayload,
  startRidePayloadX,
} from "../../../interfaces/bookingInterfaces";

export const getBookingDetailsYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .GET_YOR_BOOKING_DETAILS_X_REQUESTED,
  payload,
});

export const getPaymentHistoryYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .GET_YOR_PAYMENT_HISTORIES_X_REQUESTED,
  payload,
});

export const userStartRideYOR = (payload: startRidePayloadX) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.YOR_USER_START_RIDE_X_REQUESTED,
  payload,
});

export const getAvailableVehicleListYOR = (
  payload: getAvailableVehicleListPayload
) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .GET_YOR_AVAILABLE_VEHICLE_LIST_REQUESTED,
  payload,
});

export const getBookingStatusYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .GET_YOR_BOOKING_STATUS_X_REQUESTED,
  payload,
});

export const getPaymentPendingYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .GET_YOR_PENDING_PAYMENT_STATUS_X_REQUESTED,
  payload,
});

export const cancelStatusTaskYOR = () => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_STATUS_X_TASK,
});

export const cancelBookingYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CANCEL_YOR_BOOKING_X_REQUESTED,
  payload,
});

export const closeBookingYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CLOSE_YOR_BOOKING_X_REQUESTED,
  payload,
});

export const clearBookingDetails = () => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.CLEAR_BOOKING_DETAILS,
});

export const getpaymentModesYOR = (payload: string) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS.GET_YOR_PAYMENT_MODES_REQUESTED,
  payload,
});

export const createOrderForBulkPaymentYOR = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .CREATE_YOR_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED,
  payload,
});

export const generatePaymentLinkQRForOrder = (payload: any) => {
  return {
    type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GENERATE_YOR_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
    payload,
  };
};

export const getOrderChargesBifurcationYOR = (payload: any) => {
  return {
    type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
      .GET_YOR_ORDER_CHARGES_BIFURCATION_REQUESTED,
    payload,
  };
};

export const bookingWaveOffCharges = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .BOOKING_WAIVED_OFF_CHARGES_REQUESTED,
  payload,
});

export const cleanUpOrderAndPaymentData = () => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .CLEANUP_YOR_ORDER_AND_PAYMENT_DATA,
});

export const saveBulkAdditionalCharges = (payload: any) => ({
  type: actionTypes.YOR_BOOKING_DETAILS_ACTIONS
    .CREATE_BULK_ADDITIONAL_CHARGES_REQUESTED,
  payload,
});
