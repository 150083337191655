import { Box, Skeleton, Stack } from "@mui/material";

const CityDetailsLoader = () => {
  return (
    <Stack sx={{padding:"20px"}}>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width="100%"
          height="55px"
        />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="55px"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="150px"
        height="160px"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="55px"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="55px"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="100%"
        height="55px"
      />
    </Stack>
  );
};

export default CityDetailsLoader;
