import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import OTPField from "../../../../sharedComponents/atoms/OTPFeild";
import { typographyConstants } from "../../../constants/constants";
import { formatSecondsToTime } from "../../../utils/helper";
import { colors } from "../../../../themes/colors";
import en from "../../../../locale/en.json";
import styles from "./section.module.css";
import rentalEn from "../../../locale/rental-en.json";

const SendOTPModal = ({
  fields,
  openModal,
  setSeconds,
  seconds,
  setOpenModal,
  errorFields,
  setOtp,
  handleCloseSendOTPModal,
  onClickResendOtp,
  setFields,
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "25px",
    gap: "10px",
  };

  useEffect(() => {
    if (openModal && seconds === 0) {
      setSeconds(120); // Set your initial timer value here
    }
  }, [seconds, openModal]);

  useEffect(() => {
    if (seconds === 0) {
      setOpenModal(false);
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds: number) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  // onClick Close icon Close the modal
  const handleCloseSendOTPModalWithoutValidation = () => {
    setOpenModal(false);
    setSeconds(120); // Reset your initial timer value here
    setOtp("");
    setFields((prevState: any) => ({ ...prevState, otpass: "" }));
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseSendOTPModalWithoutValidation}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <Box sx={{ position: "absolute", right: "15px", top: "7px" }}>
          <IconButton onClick={handleCloseSendOTPModalWithoutValidation}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack alignItems={"center"}>
          <Stack alignItems={"center"}>
            <Typography variant={typographyConstants.HEADINGXL}>
              {rentalEn.global.otPVerification}
            </Typography>
            <Typography 
              variant={typographyConstants.BODY}
              color={colors.secondary_light_gray}
            >
              {rentalEn.manualBooking.enterTheCode}{" "}
            </Typography>
            <Typography variant={typographyConstants.HEADING} padding={"0px"}>
              +91 {fields?.mobileNumber}
            </Typography>
          </Stack>
          <Stack alignItems={"center"} gap="10px">
            <Typography
              variant={typographyConstants.HEADING}
              color={colors.primary_dark_blue3}
            >
              <>
                <span>
                  {rentalEn.manualBooking.otpExpiresIn}{" "}
                  {formatSecondsToTime(seconds)}
                </span>
              </>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <OTPField
                separator={<span> </span>}
                value={fields.otpass}
                onChange={setOtp}
                length={6}
              />
              {errorFields?.otpass && (
                <Typography variant="errorMessage1" fontSize={"12px"}>
                  {errorFields.otpass}
                </Typography>
              )}
              <Box className={styles.resendOTPButton}>
                <Typography
                  variant={typographyConstants.BODY}
                  color={colors.secondary_light_gray}
                >
                  {rentalEn.manualBooking.didntReceiveCode}{" "}
                </Typography>

                <Box
                  sx={{}}
                  onClick={seconds <= 90 ? onClickResendOtp : undefined}
                >
                  <Typography
                    variant={typographyConstants.BODY}
                    fontWeight={"600"}
                    color={
                      seconds > 30 ? colors.secondary_light_gray : "inherit"
                    }
                    sx={{ cursor: seconds <= 90 ? "pointer" : "not-allowed" }}
                  >
                    {en.global.Resend}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
        <CustomButton
          variant="outlined"
          sx={{ width: "100%" }}
          label={en.global.Submit}
          onClick={handleCloseSendOTPModal}
        />
      </Box>
    </Modal>
  );
};

export default SendOTPModal;
