import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ApplyCouponIcon } from "../../../../../freedo-x-src/constantsX/exportImagesX";
import { generateSignedUrlPayload } from "../../../../../freedo-x-src/interfaces/bookingDetailsInterfaces";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import InputDatePicker from "../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import CustomAccordion from "../../../../../sharedComponents/molecules/Accordion/customAccordian";
import CustomHoverPopover from "../../../../../sharedComponents/molecules/Popover/HoverPopover";
import HelmetBadge from "../../../../../sharedComponents/templates/MultiStepForm/HelmetBadge";
import {
  PaymentSuccessUI,
  RenderCashUI,
  RenderPaymentLink,
  RenderQRCode,
} from "../../../../../sharedComponents/templates/RenderPaymentMethods";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesCategories,
  BookingChargesSubCategories,
  discountsTypes,
  DisplayPaymentModes,
  GenericObject,
  HELMET_DISPLAY_NAME,
  HELMET_ICONS,
  HELMETS_ACTIONS,
  HELMETS_TYPES,
  OrderPaymentType,
  paymentModeIcons,
  planTypeKeys,
  typographyConstants,
  uploadError,
} from "../../../../constants/constants";
import {
  AddDocIcon,
  BenefitsCard,
  circularLineIcon,
  DeleteIcon,
  fadedCalenderIcon,
  GreenTickIcon,
  LeftArrowIcon,
  NoIcon,
  OdometerIcon,
  UploadFileIcon,
  YesIcon,
} from "../../../../constants/exportImages";
import {
  AdditionalHelmetUiProps,
  BookingExtensionProps,
  CalculateExtensionPayload,
  CreateExtensionPayload,
  ExapandAccordion,
  ExtendRideErrFields,
  ExtensionPlanTypes,
  ExtensionRideFields,
  ExtraCharges,
  GetExtensionPlansPayload,
  HelmetSelection,
  OdometerReadingUiProps,
  PacakageDetailsUiProps,
  PaymentBreakupUiProps,
} from "../../../../interfaces/bookingManagement";
import rentalEn from "../../../../locale/rental-en.json";
import {
  bookingManagementActions,
  rentalActions,
} from "../../../../redux/actions";
import {
  amountConvertToRupee,
  calculateFutureDate,
  getArraySum,
  getFormattedINR,
  getPaymentTypeText,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../../utils/helper";
import { checkNumeric } from "../../../../utils/regex";
import { PlanType } from "../../../manualBooking/section/PlansCard";
import styles from "../bookingDetails.module.css";
import { StyleObject } from "./styleObject";
import { LinearProgressDocUpload } from "../components/linearProgress";

// locale data
const {
  bookingDetails: bookingDetailsLocale,
  manualBooking: manualBookingLocale,
  global,
  errorMessages,
} = rentalEn;

// enums to toggle screen
enum ComponentMapping {
  EXTEND_RIDE_SCREEN = "EXTEND_RIDE_SCREEN",
  PAYMENT_OPTIONS_SCREEN = "PAYMENT_OPTIONS_SCREEN",
  PAYMENT_MODE_SCREEN = "PAYMENT_MODE_SCREEN",
  PAYMENT_SUCCESS_SCREEN = "PAYMENT_SUCCESS_SCREEN",
}

const {
  bookingChargesCategoriesDisplay,
  bookingChargesSubCategoriesDisplay,
  discountTypesDisplay,
} = bookingDetailsLocale;

const discountsTypeArray: string[] = [
  discountsTypes.PROMOTION,
  discountsTypes.COUPON_CODE,
];

const Bullet = (
  <Box
    component="span"
    sx={{
      mx: "2px",
      transform: "scale(1)",
      color: colors.text_secondary_gray,
      fontSize: "16px",
    }}
  >
    •
  </Box>
);

//odometer reading ui
const OdometerReadingUi = ({
  onChange,
  errFields,
  fields,
  getRootProps,
  getInputProps,
  onClickImageDelete,
  bookingDocsLoader,
  docUploadLinearProgress,
  loading,
  bookingDetails,
}: OdometerReadingUiProps) => {
  return (
    <Box>
      <Box sx={StyleObject.odometerReadingUiContainer}>
        <Typography
          variant={typographyConstants.BODY}
          sx={{ color: colors.tertiary_black, fontWeight: 500 }}
        >
          {`${bookingDetailsLocale.startReading} :`}
        </Typography>
        {loading ? (
          <Skeleton width="20px" height="15px" animation="wave" />
        ) : (
          <Typography
            variant={typographyConstants.BODY}
            sx={{ color: colors.text_black, fontWeight: 400 }}
          >
            {bookingDetails?.bookingVehicleDetails?.currentReading
              ? `${bookingDetails?.bookingVehicleDetails?.currentReading} ${global.km}`
              : global.NA}
          </Typography>
        )}
      </Box>
      <Stack sx={{ padding: "10px", gap: "5px" }}>
        <Typography
          sx={{ fontWeight: 500, color: colors.tertiary_black }}
        >{`${bookingDetailsLocale?.odometerEndReading} :`}</Typography>
        <Input
          width={"100%"}
          iconStart={OdometerIcon}
          placeholder={bookingDetailsLocale?.enterOdometerReading}
          value={fields.odometerReading}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            let reading: any = e.target.value;
            reading = reading.toString().replace(/^0+/, "");
            checkNumeric(reading) && reading?.length < 7 && onChange(reading);
          }}
          errormessage={errFields?.odometerReading}
        />

        <Typography
          sx={{ fontWeight: 500, color: colors.tertiary_black }}
        >{`${bookingDetailsLocale?.uploadEndReadingImage} :`}</Typography>

        {!fields.isImgUploaded ? (
          <>
            {bookingDocsLoader ? (
              <LinearProgressDocUpload progress={docUploadLinearProgress} />
            ) : (
              <Box {...getRootProps()} sx={StyleObject.imageUploadContainer}>
                <input {...getInputProps()} />
                <img src={AddDocIcon} />
              </Box>
            )}
          </>
        ) : (
          <Box sx={StyleObject.imageUplodedContainer}>
            <Box display={"flex"} gap="20px" alignItems={"center"}>
              <img src={UploadFileIcon} alt="" />
              <Stack gap="10px">
                <Typography
                  variant={typographyConstants?.SUBHEADING}
                  color={colors.text_secondary_gray}
                  sx={{
                    width: "150px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {fields?.fileName}
                </Typography>
                <Box display="flex" gap="20px" justifyContent={"flex-end"}>
                  <Typography
                    variant={typographyConstants?.BODY}
                    color={colors.text_secondary_gray}
                  >
                    <img src={GreenTickIcon} /> {global?.uploaded}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <img
              src={DeleteIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onClickImageDelete()}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

// pacakage details ui
const PacakageDetailsUi = ({
  fields,
  handleDateChange,
  handlepackageChange,
  bookingDetails,
  extensionPlansDetails,
  handlePlanSelection,
  planTypeArray = [],
  extensionAvailableDate,
}: PacakageDetailsUiProps) => {
  // plans data from master data
  const { masterPlanDurationsData = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  return (
    <Stack sx={{ padding: "10px", gap: "8px" }}>
      <Stack>
        <Typography
          sx={{ fontWeight: 500, color: colors.tertiary_black }}
        >{`${bookingDetailsLocale?.selectNewPackage} :`}</Typography>
        <CustomSelect
          required
          icon={fadedCalenderIcon}
          placeholder={global.selectPackage}
          value={fields.selectedPackage}
          customLabel={(data: number) => {
            return (
              <div>
                {data} {data === 1 ? ` day` : ` days`}
              </div>
            );
          }}
          choice={masterPlanDurationsData}
          handleChange={(days: any) => {
            handlepackageChange(days);
          }}
        />
      </Stack>
      <InputDatePicker
        value={fields.endDate}
        onChange={(date: Date) => {
          handleDateChange(date);
        }}
        placeholder={bookingDetailsLocale.extensionEndDate}
        minDate={
          new Date(getTimestampAtEndOfDay(bookingDetails?.endDate) + 1)
          // getTimestampAtEndOfDay(bookingDetails?.endDate) >=
          // getTimestampAtEndOfDay(new Date())
          //   ? new Date(getTimestampAtEndOfDay(bookingDetails?.endDate) + 1)
          //   : new Date()
        }
        maxDate={new Date(getTimestampAtEndOfDay(extensionAvailableDate))}
        placement={"left-start"}
      />

      {Object.keys(extensionPlansDetails).length > 0 && (
        <Stack gap={"4px"}>
          <Typography
            sx={{ fontWeight: 500, color: colors.tertiary_black }}
          >{`${bookingDetailsLocale?.selectPlanVariant} :`}</Typography>
          <Fade>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              {planTypeArray.map((plan: ExtensionPlanTypes, index: number) => {
                return (
                  <PlanType
                    key={index}
                    plan={plan}
                    selectedPlan={fields?.selectedPlan}
                    onPlanSelect={() => handlePlanSelection(plan)}
                    width={"160px"}
                  />
                );
              })}
            </Box>
          </Fade>
        </Stack>
      )}
    </Stack>
  );
};

// additional helmet  ui
const AdditionalHelmetUi = ({
  extensionPlansDetails,
  fields,
  extraHelmetHandler,
  errFields,
  handleComplementaryHelmet,
  setComplementaryHelmet,
}: AdditionalHelmetUiProps) => {
  return (
    <Stack padding={"14px"} gap={"16px"}>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {extensionPlansDetails?.helmetDetails?.map((item: GenericObject) => {
          if (item.isComplementary) {
            return null;
          } else
            return (
              <HelmetBadge
                title={HELMET_DISPLAY_NAME[item?.type]}
                icon={HELMET_ICONS[item.type]}
                type={item.type}
                clickHandler={extraHelmetHandler}
                helmetTypeCount={
                  fields?.helmetSelection[
                    item.type as keyof typeof fields.helmetSelection
                  ]
                }
                width={"130px"}
              />
            );
        })}
      </Box>
      {errFields?.selectedHelmet && (
        <Typography variant={typographyConstants.ERRORMESSAGE}>
          {errFields.selectedHelmet}
        </Typography>
      )}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{ padding: "0px" }}
          variant={typographyConstants.SUBHEADING}
        >
          {`${bookingDetailsLocale.complementaryHelmet} ${
            fields.hasComplementary
              ? "(" + fields.complementaryHelmetType + ")"
              : ""
          }`}
        </Typography>

        <Box sx={{ display: "flex", gap: "14px", marginTop: "10px" }}>
          <Box
            className={`${
              fields?.isComplementary
                ? styles.yesNoIconsSelected
                : styles.yesNoIcons
            } `}
            onClick={() => handleComplementaryHelmet(true)}
          >
            <YesIcon
              color={fields?.isComplementary === true ? colors.white : ""}
            />
          </Box>
          <Box
            className={`${
              fields.isComplementary
                ? styles.yesNoIcons
                : styles.yesNoIconsSelected
            } `}
            onClick={() => handleComplementaryHelmet(false)}
          >
            <NoIcon color={fields.isComplementary ? "" : colors.white} />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

// payment breakup ui
const PaymentBreakupUi = ({
  calculatedExtensionCharge,
  fields,
  expand,
  handleExpansion,
  getOrderSummary,
  getSubCategories,
}: PaymentBreakupUiProps): JSX.Element => {
  const { orderSummary, discounts } = getOrderSummary();
  return (
    <Stack
      className={styles?.paymentBreakupExtendRide}
      sx={{
        width: "100%",
        marginBottom: "60px",
      }}
    >
      {expand?.orderSummary ? (
        <Fade direction="up" duration={700}>
          {orderSummary?.map((item: any, index: number) => {
            return (
              <CustomHoverPopover
                popupContent={
                  <Stack sx={{ padding: "16px", gap: "3px" }}>
                    {item.subCategory?.map((charge: any, index: number) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "3px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >{`${index + 1}. `}</Typography>
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            {getSubCategories(charge?.category, charge?.excess)}
                          </Typography>
                          <Typography
                            variant={typographyConstants.SUBHEADING}
                            color={colors.text_black}
                            sx={{ padding: "0px" }}
                          >
                            {getFormattedINR(charge?.amount)}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Stack>
                }
                hoverContent={
                  <Box
                    sx={{
                      ...StyleObject.viewMoreContainer,
                      borderRadius: index ? "0px" : "10px 10px 0px 0px",
                    }}
                  >
                    <Stack>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color:
                            item.category ===
                              BookingChargesCategories.ADDITIONAL_CHARGES ||
                            item.category ===
                              BookingChargesCategories.PENALTY_CHARGES
                              ? colors.primary_danger
                              : colors.text_black,
                          fontSize: "14px",
                        }}
                        variant={typographyConstants.BODY}
                      >
                        {item.category === BookingChargesCategories.PLAN_CHARGES
                          ? `${fields.selectedPackage} ${
                              fields.selectedPackage === 1
                                ? "Day package"
                                : "Days package"
                            } (${fields?.selectedPlan?.heading})`
                          : bookingChargesCategoriesDisplay[
                              item.category as keyof typeof bookingChargesCategoriesDisplay
                            ]}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color:
                          item.category ===
                            BookingChargesCategories.ADDITIONAL_CHARGES ||
                          item.category ===
                            BookingChargesCategories.PENALTY_CHARGES
                            ? colors.primary_danger
                            : colors.text_black,
                        padding: "0px",
                      }}
                      variant={typographyConstants.SUBHEADING}
                    >
                      {getFormattedINR(item?.totalAmount)}
                    </Typography>
                  </Box>
                }
              />
            );
          })}
          {calculatedExtensionCharge?.totalDiscount > 0 && (
            <CustomHoverPopover
              popupContent={
                <Stack sx={{ padding: "16px", gap: "3px" }}>
                  {discountsTypeArray?.map((type: string, index: number) => {
                    let discount = discounts?.get(type);

                    // return coupon code string
                    const getCoupon = (): string => `(${discount?.code})`;

                    if (discount?.amount)
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "3px",
                            alignItems: "center",
                          }}
                        >
                          {Bullet}
                          <Typography
                            variant={typographyConstants.BODY}
                            color={colors.text_secondary_gray}
                            sx={{ fontWeight: 500 }}
                          >
                            {`${
                              discountTypesDisplay[
                                type as keyof typeof discountTypesDisplay
                              ]
                            } ${
                              type === discountsTypes.COUPON_CODE
                                ? getCoupon()
                                : ""
                            } :`}
                          </Typography>
                          <Typography
                            variant={typographyConstants.SUBHEADING}
                            color={colors.text_black}
                            sx={{ padding: "0px" }}
                          >
                            {getFormattedINR(discount?.amount)}
                          </Typography>
                        </Box>
                      );
                  })}
                </Stack>
              }
              hoverContent={
                <Box
                  sx={{
                    ...StyleObject.viewMoreContainer,
                    borderRadius: "0px",
                  }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: colors.secondary_green,
                        fontSize: "14px",
                      }}
                      variant={typographyConstants.BODY}
                    >
                      {global.discount}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: colors.secondary_green,
                      padding: "0px",
                    }}
                    variant={typographyConstants.SUBHEADING}
                  >
                    {`- ${getFormattedINR(
                      calculatedExtensionCharge?.totalDiscount
                    )}`}
                  </Typography>
                </Box>
              }
            />
          )}
        </Fade>
      ) : null}

      <Box
        sx={{
          display: "flex",
          width: "359px",
          boxShadow: "0px 0px 1.5px 0px #00000040",
          border: "1px solid #00000029",
          borderRadius: expand.orderSummary ? "0px 0px 10px 10px" : "10px",
          padding: "12px 40px 12px 24px",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: colors.white,
        }}
      >
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Box
            onClick={() => {
              handleExpansion("orderSummary", !expand.orderSummary);
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                color: colors.primary_dark_blue2,
                cursor: "pointer",
              }}
              variant={typographyConstants.BODY}
            >
              {expand.orderSummary
                ? bookingDetailsLocale.viewLess
                : bookingDetailsLocale.viewMore}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: 500,
              color: colors.text_black,
              padding: "0px",
            }}
            variant={typographyConstants.SUBHEADING}
          >
            {bookingDetailsLocale.orderSummary}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 500,
            color: colors.text_black,
            padding: "0px",
          }}
          variant={typographyConstants.HEADING}
        >
          {getFormattedINR(
            calculatedExtensionCharge?.totalAmount -
              calculatedExtensionCharge?.totalDiscount
          )}
        </Typography>
      </Box>
    </Stack>
  );
};

// main ride extension component
const BookingExtension = (props: BookingExtensionProps): JSX.Element => {
  const { bookingId = "", toggleExtendRideDrawer = () => {} } = props;

  const dispatch = useDispatch();

  // data from booking management reducer
  const {
    extensionAvailableDate,
    bookingDetails,
    extensionPlansDetails = {},
    calculatedExtensionCharge = {},
    isPaymentSuccess,
    isPaymentLinkGenerated,
    paymentLinkQRData,
    createExtensionData,
    attachmentIds,
    calculatedExtensionChargeLoader,
    bookingDocsLoader,
    docUploadLinearProgress,
    loading,
    pendingChargesLoader,
    paymentLinkQRLoader,
    createExtensionLoader,
    planAddOns,
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);

  // plans data from master data
  const { masterPlanDurationsData = [], operatorConfigData } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // state to control open and close of all accordion in VMS view vehicle
  const [expand, setExpand] = useState<ExapandAccordion>({
    odometerReading: true,
    pacakageDetails: false,
    additionalHelmet: false,
    orderSummary: false,
  });

  // state to store complete ride data
  const [fields, setFields] = useState<ExtensionRideFields>({
    odometerReading: "",
    selectedPackage: "",
    startDate: "",
    endDate: null,
    selectedPlan: {},
    premiumSelected: false,
    unlimitedSelected: false,
    regularSelected: false,
    helmetSelection: {
      HALF_FACE: 0,
      FULL_FACE: 0,
      PREMIUM: 0,
      KIDS: 0,
    },
    selectedPaymentMode: "",
    isImgUploaded: false,
    fileName: "",
    couponCode: "",
    isCouponApplied: false,
    isComplementary: false,
    hasComplementary: false,
    complementaryHelmetType: "",
  });

  // state to store complete ride fields errors
  const [errFields, setErrFields] = useState<ExtendRideErrFields>({
    odometerReading: "",
    selectedHelmet: "",
  });

  // state to handle screen
  const [componentMount, setComponentMount] = useState<string>(
    ComponentMapping.EXTEND_RIDE_SCREEN
  );

  // extra hr and km charges
  const extraCharges: ExtraCharges[] = [
    {
      heading: rentalEn.manualBooking.excessKmCharges,
      value: planAddOns?.planDetails?.excessKmCharge,
      type: global.km,
    },
    {
      heading: rentalEn.manualBooking.excessHourCharges,
      value: planAddOns?.planDetails?.excessHrCharge,
      type: "Hour",
    },
  ];

  // plan type array for plan details accordion
  const planTypeArray: ExtensionPlanTypes[] = [
    {
      key: planTypeKeys.RegularPlan,
      heading: bookingDetailsLocale.regularPlan,
      amount:
        extensionPlansDetails?.amount - extensionPlansDetails.discount || 0,
      kmLimit: extensionPlansDetails.kmLimit || 0,
      isAvailable: true,
    },
    {
      key: planTypeKeys.PremiumPlan,
      heading: bookingDetailsLocale.premiumPlan,
      amount: extensionPlansDetails?.premium?.price || 0,
      kmLimit: extensionPlansDetails.premium?.kmLimit || 0,
      isAvailable: extensionPlansDetails?.premium?.available ?? false,
    },
    {
      key: planTypeKeys?.UnlimitedPlan,
      heading: bookingDetailsLocale.unlimitedPlan,
      amount: extensionPlansDetails?.unlimited?.price || 0,
      kmLimit: extensionPlansDetails?.unlimited?.kmLimit || 0,
      isAvailable: extensionPlansDetails?.unlimited?.available ?? false,
    },
  ];

  // side effect for initial data
  useEffect(() => {
    // get master pacakage data
    dispatch(rentalActions.getMasterPlanDurations("AddCustomField"));
  }, []);

  // side effect called when endate date change in pacakage details
  useEffect(() => {
    if (fields.endDate) {
      getExtensionplans(bookingId, getTimestampAtEndOfDay(fields.endDate));
    }
  }, [fields.endDate]);

  // side effect to set helmet data from api
  useEffect(() => {
    if (extensionPlansDetails?.helmetDetails?.length) {
      let obj: HelmetSelection = {
        HALF_FACE: 0,
        FULL_FACE: 0,
        PREMIUM: 0,
        KIDS: 0,
      };
      for (let i = 0; i < extensionPlansDetails?.helmetDetails?.length; i++) {
        // condition to pass helmet if there is complementary
        if (extensionPlansDetails?.helmetDetails[i].isComplementary) continue;

        obj[extensionPlansDetails?.helmetDetails[i].type as keyof typeof obj] =
          extensionPlansDetails?.helmetDetails[i].count;
      }

      const complementryHelmet = extensionPlansDetails?.helmetDetails?.filter(
        (item: any) => item?.isComplementary === true
      );

      setFields((prev: ExtensionRideFields) => ({
        ...prev,
        couponCode: "",
        isCouponApplied: false,
        helmetSelection: obj,
        isComplementary: complementryHelmet?.length ? true : false,
        hasComplementary: complementryHelmet?.length ? true : false,
        complementaryHelmetType: complementryHelmet?.length
          ? HELMET_DISPLAY_NAME[
              complementryHelmet[0].type as keyof typeof HELMET_DISPLAY_NAME
            ]
          : "",
      }));
    }
  }, [extensionPlansDetails]);

  // side effect to calculate extension plan charges
  useEffect(() => {
    if (
      Object.keys(fields.selectedPlan).length &&
      fields.endDate &&
      fields.odometerReading >
        bookingDetails?.bookingVehicleDetails?.currentReading
    ) {
      calculateExtensionCharges(fields);
    }
  }, [
    fields.selectedPlan,
    fields.odometerReading,
    fields.endDate,
    fields.helmetSelection,
  ]);

  // side effect to mount payment mode screen
  useEffect(() => {
    if (isPaymentLinkGenerated)
      setComponentMount(ComponentMapping?.PAYMENT_MODE_SCREEN);
  }, [isPaymentLinkGenerated]);

  // Handle accordion expansions here (also handles other accordion expansions and close)
  const handleExpansion = (key: string, value: boolean) => {
    let tempObj: ExapandAccordion = { ...expand };

    for (let i in tempObj) {
      if (key === i) tempObj[i as keyof typeof tempObj] = value;
      else tempObj[i as keyof typeof tempObj] = false;
    }
    setExpand((prev: ExapandAccordion) => ({
      ...prev,
      ...tempObj,
    }));
  };

  // on change of odometer reading in booking details
  const onOdometerReadingChange = (value: string) => {
    if (
      value <= bookingDetails?.bookingVehicleDetails?.currentReading &&
      value !== ""
    )
      setErrFields((prev: ExtendRideErrFields) => ({
        ...prev,
        odometerReading: `${errorMessages.odometerReadingError} ${
          bookingDetails?.bookingVehicleDetails?.currentReading +
          " " +
          global.km.toLowerCase()
        }`,
      }));
    else
      setErrFields((prev: ExtendRideErrFields) => ({
        ...prev,
        odometerReading: "",
      }));

    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      couponCode: "",
      isCouponApplied: false,
      odometerReading: value,
    }));
  };

  // callback function to be called when successfully fetching of extensions plans
  const extensionPlansOnSuccess = (defaultPlan: ExtensionPlanTypes) => {
    handlePlanSelection(defaultPlan);
  };

  //api call to get extensions package details
  const getExtensionplans = (bookingId: string, endTimeStamp: string) => {
    const payload: GetExtensionPlansPayload = {
      bookingId,
      endDate: endTimeStamp,
      successCallback: extensionPlansOnSuccess,
    };
    dispatch(bookingManagementActions.getExtensionplansDetails(payload));
  };

  // handle date  in pacakage details accordion
  const handleDateChange = (date: Date) => {
    const diffDays: number = dayjs(getTimestampAtEndOfDay(date)).diff(
      dayjs(getTimestampAtEndOfDay(bookingDetails.endDate)),
      "days"
    );
    // getTimestampAtEndOfDay(bookingDetails?.endDate) >=
    // getTimestampAtEndOfDay(new Date())
    //   ? dayjs(getTimestampAtEndOfDay(date)).diff(
    //       dayjs(getTimestampAtEndOfDay(bookingDetails.endDate)),
    //       "days"
    //     )
    //   : dayjs(getTimestampAtEndOfDay(date)).diff(
    //       dayjs(getTimestampAtEndOfDay(new Date())),
    //       "days"
    //     );

    const isInMasterPlans: boolean = masterPlanDurationsData.includes(diffDays);

    if (isInMasterPlans) {
      setFields((prev: ExtensionRideFields) => ({
        ...prev,
        couponCode: "",
        isCouponApplied: false,
        selectedPackage: diffDays,
        endDate: date,
      }));
    } else {
      setFields((prev: ExtensionRideFields) => ({
        ...prev,
        couponCode: "",
        isCouponApplied: false,
        selectedPackage: manualBookingLocale.custom,
        endDate: date,
      }));
    }
  };

  // handle package changes in package details accordion
  const handlepackageChange = (selectedPackage: string) => {
    if (selectedPackage === rentalEn.manualBooking.custom) {
      setFields((prevState: ExtensionRideFields) => ({
        ...prevState,
        couponCode: "",
        isCouponApplied: false,
        selectedPackage: rentalEn.manualBooking.custom,
        startDate: null,
        endDate: null,
      }));
    } else {
      const futureDate: any = getTimestampAtEndOfDay(
        calculateFutureDate(bookingDetails.endDate, selectedPackage)
      );
      // getTimestampAtEndOfDay(bookingDetails?.endDate) >=
      // getTimestampAtEndOfDay(new Date())
      //   ? getTimestampAtEndOfDay(
      //       calculateFutureDate(bookingDetails.endDate, selectedPackage)
      //     )
      //   : getTimestampAtStartOfDay(
      //       calculateFutureDate(new Date(), selectedPackage)
      //     );

      if (futureDate <= getTimestampAtEndOfDay(extensionAvailableDate)) {
        setFields((prev: ExtensionRideFields) => ({
          ...prev,
          couponCode: "",
          isCouponApplied: false,
          endDate: new Date(getTimestampAtEndOfDay(futureDate)),
          selectedPackage,
        }));
      } else {
        toastr.warning("Alert", errorMessages.packageSelectError);
        return;
      }
    }
  };

  // handle pacakage selection change
  const handlePlanSelection = (plans: GenericObject): void => {
    let tempObj: any = {
      selectedPlan: plans,
    };
    if (planTypeKeys.RegularPlan === plans.key) {
      tempObj["regularSelected"] = true;
      tempObj["unlimitedSelected"] = false;
      tempObj["premiumSelected"] = false;
    } else if (planTypeKeys.UnlimitedPlan === plans.key) {
      tempObj["unlimitedSelected"] = true;
      tempObj["premiumSelected"] = false;
      tempObj["regularSelected"] = false;
    } else {
      tempObj["unlimitedSelected"] = false;
      tempObj["premiumSelected"] = true;
      tempObj["regularSelected"] = false;
    }
    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      couponCode: "",
      isCouponApplied: false,
      ...tempObj,
    }));
  };

  // handles additional helmet selection
  const extraHelmetHandler = (e: any, type: string, action: string) => {
    let helmetCountArray = Object.values(fields.helmetSelection);
    let helmetCount = getArraySum(helmetCountArray);
    if (action === HELMETS_ACTIONS.INCREMENT) {
      if (helmetCount < 3) {
        setFields((prevState: ExtensionRideFields) => ({
          ...prevState,
          couponCode: "",
          isCouponApplied: false,
          helmetSelection: {
            ...prevState.helmetSelection,
            [type]:
              prevState.helmetSelection[
                type as keyof typeof prevState.helmetSelection
              ] + 1,
          },
        }));
        if (errFields?.selectedHelmet) {
          setErrFields((prevState: ExtendRideErrFields) => ({
            ...prevState,
            selectedHelmet: "",
          }));
        }
      } else {
        setErrFields((prevState: ExtendRideErrFields) => ({
          ...prevState,
          selectedHelmet: `* ${errorMessages.maxThreeHelmetApplicable}`,
        }));
      }
    }
    if (action === HELMETS_ACTIONS.DECREMENT) {
      setFields((prevState: ExtensionRideFields) => ({
        ...prevState,
        couponCode: "",
        isCouponApplied: false,
        helmetSelection: {
          ...prevState.helmetSelection,
          [type]: prevState.helmetSelection[
            type as keyof typeof prevState.helmetSelection
          ]
            ? prevState.helmetSelection[
                type as keyof typeof prevState.helmetSelection
              ] - 1
            : prevState.helmetSelection[
                type as keyof typeof prevState.helmetSelection
              ],
        },
      }));
      if (errFields?.selectedHelmet) {
        setErrFields((prevState: ExtendRideErrFields) => ({
          ...prevState,
          selectedHelmet: "",
        }));
      }
    }
  };

  // check proceed button disabled or not
  const isDisabledButton = (): boolean => {
    if (componentMount === ComponentMapping.EXTEND_RIDE_SCREEN) {
      if (
        fields.selectedPackage &&
        fields?.endDate &&
        fields.odometerReading >
          bookingDetails?.bookingVehicleDetails?.currentReading &&
        Object.keys(fields.selectedPlan).length &&
        Object.keys(calculatedExtensionCharge).length
      )
        return false;
      else return true;
    } else if (componentMount === ComponentMapping.PAYMENT_OPTIONS_SCREEN) {
      if (fields.selectedPaymentMode) return false;
      else return true;
    } else return false;
  };

  // success callback in creation of order id
  const successCallbackOrderId = (data: any) => {
    setComponentMount(ComponentMapping.PAYMENT_MODE_SCREEN);
    dispatch(bookingManagementActions.setPaymentSuccess());
  };

  // on click submit button
  const onClickButton = () => {
    if (componentMount === ComponentMapping.EXTEND_RIDE_SCREEN) {
      setComponentMount(ComponentMapping.PAYMENT_OPTIONS_SCREEN);
    } else if (componentMount === ComponentMapping.PAYMENT_OPTIONS_SCREEN) {
      createBookingExtension();
    } else if (componentMount === ComponentMapping.PAYMENT_MODE_SCREEN) {
    }
  };

  //hanlde paymnet change mode
  const handlePaymentModeChange = (event: any) => {
    setFields((prevState: ExtensionRideFields) => ({
      ...prevState,
      selectedPaymentMode: event.target.value,
    }));
  };

  // get charge summary array
  const getOrderSummary = (): GenericObject => {
    let orderSummary: GenericObject[] = [];

    // handle discount
    let discounts = new Map<string, GenericObject>();
    discounts.set(discountsTypes.PROMOTION, { amount: 0, code: "" });
    discounts.set(discountsTypes.COUPON_CODE, { amount: 0, code: "" });

    for (let i = 0; i < calculatedExtensionCharge?.charges?.length; i++) {
      let currentItem = calculatedExtensionCharge?.charges[i];
      let summary = orderSummary?.filter(
        (item: any) => item.category === currentItem.category
      );

      let tempObj: any = {
        category: "",
        totalAmount: 0,
        subCategory: [],
      };

      if (summary.length) {
        summary[0].category = currentItem.category;
        summary[0].totalAmount = summary[0]?.totalAmount + currentItem.amount;

        if (
          currentItem.subCategory ===
          BookingChargesSubCategories.EXCESS_KM_CHARGES
        ) {
          summary[0].subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
            excess:
              currentItem.details?.kmUtilized - currentItem.details?.kmLimit,
          });
        } else if (
          currentItem.subCategory ===
          BookingChargesSubCategories.EXCESS_HOUR_CHARGES
        ) {
          summary[0].subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
            excessData:
              currentItem.details?.hrUtilized - currentItem.details?.hrLimit,
          });
        } else {
          summary[0].subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
          });
        }
      } else {
        tempObj.category = currentItem.category;
        tempObj.totalAmount = currentItem.amount;

        if (
          currentItem.subCategory ===
          BookingChargesSubCategories.EXCESS_KM_CHARGES
        ) {
          tempObj.subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
            excess:
              currentItem.details?.kmUtilized - currentItem.details?.kmLimit,
          });
        } else if (
          currentItem.subCategory ===
          BookingChargesSubCategories.EXCESS_HOUR_CHARGES
        ) {
          tempObj.subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
            excessData:
              currentItem.details?.hrUtilized - currentItem.details?.hrLimit,
          });
        } else {
          tempObj.subCategory.push({
            category: currentItem.subCategory,
            amount: currentItem.amount,
          });
        }
        orderSummary.push(tempObj);
      }

      if (currentItem?.couponCode?.length) {
        for (let i = 0; i < currentItem.couponCode?.length; i++) {
          discounts.set(currentItem?.couponCode[i]?.type, {
            amount:
              currentItem.couponCode[i]?.discount +
              discounts.get(currentItem?.couponCode[i]?.type)?.amount,
            code: currentItem.couponCode[i]?.code,
          });
        }
      }
    }
    return { orderSummary, discounts };
  };

  // create booking extension and generate order id
  const createBookingExtension = () => {
    // calculate extension payload
    const calculateExtensionpayload: CalculateExtensionPayload = {
      apiBody: {
        data: {
          odometerReading: parseInt(fields.odometerReading),
          endDate: parseInt(getTimestampAtEndOfDay(fields.endDate)),
          unlimitedSelected: fields.unlimitedSelected,
          premiumSelected: fields.premiumSelected,
          helmetSelection: fields.helmetSelection,
          couponCode: fields.couponCode ? fields?.couponCode : undefined,
        },
      },
      bookingId,
    };

    if (createExtensionData?.orderDetails?.id) {
      const payload = {
        data: {
          orderId: createExtensionData?.orderDetails?.id,
          paymentFlow: fields?.selectedPaymentMode,
        },
      };
      dispatch(
        bookingManagementActions?.generatePaymentLinkQRForOrder(payload)
      );
    } else {
      let payload: CreateExtensionPayload = {
        apiBody: {
          data: {
            odometerReading: parseInt(fields.odometerReading),
            endDate: parseInt(getTimestampAtEndOfDay(fields.endDate)),
            unlimitedSelected: fields.unlimitedSelected,
            premiumSelected: fields.premiumSelected,
            helmetSelection: fields.helmetSelection,
            imageIds: attachmentIds.length > 0 ? attachmentIds : undefined,
            complementaryHelmet: fields.isComplementary,
            couponCode: fields.couponCode ? fields.couponCode : undefined,
          },
        },
        selectedPaymentMode: fields.selectedPaymentMode,
        bookingId,
        amount:
          calculatedExtensionCharge?.totalAmount -
          calculatedExtensionCharge?.totalDiscount,
        sucessCallbackPayment: successCallbackOrderId,
        lastCalculatedAmount:
          calculatedExtensionCharge?.totalAmount -
          calculatedExtensionCharge?.totalDiscount,
        calculateExtensionpayload,
      };

      dispatch(bookingManagementActions.createBookingExtension(payload));
    }
  };
  //try another payment method
  const tryAnotherPaymentMethod = () => {
    setComponentMount(ComponentMapping.PAYMENT_OPTIONS_SCREEN);
    const payload = {
      isPaymentLinkGenerated: false,
    };
    dispatch(bookingManagementActions.clearReducerKeys(payload));

    dispatch(rentalActions.cancelPaymentStatus());
  };

  //render payment options
  const renderPaymentOptions = () => (
    <Stack gap="15px">
      <Box>
        <Typography
          color={colors.text_secondary_gray}
          fontSize="12px"
          fontWeight="500"
          marginBottom="5px"
        >
          {rentalEn.manualBooking.SelectPaymentMethod}:
        </Typography>

        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: "12px",
            border: "1px solid #00000029",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={fields?.selectedPaymentMode}
            onChange={(e: any) => handlePaymentModeChange(e)}
          >
            {operatorConfigData?.allowedPaymentFlows?.map(
              (mode: any, index: any) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      borderBottom: index < 2 ? "1px solid #D4E4E452" : "",
                      padding: "2px 18px",
                    }}
                  >
                    <FormControlLabel
                      value={mode}
                      control={<Radio />}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: colors.text_secondary_gray,
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginLeft: "10px",
                          }}
                        >
                          <img src={paymentModeIcons[mode]} />
                          <Typography
                            sx={{ fontWeight: 500, color: colors.text_black }}
                          >
                            {
                              DisplayPaymentModes[
                                mode as keyof typeof DisplayPaymentModes
                              ]
                            }
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                );
              }
            )}
          </RadioGroup>
        </Box>
      </Box>
    </Stack>
  );

  //render the ui for payment types selected
  const renderContent = () => {
    if (isPaymentSuccess) {
      return <PaymentSuccessUI paymentLink={paymentLinkQRData} />;
    } else {
      if (isPaymentLinkGenerated && paymentLinkQRData) {
        if (paymentLinkQRData.paymentFlow === OrderPaymentType.QR_CODE) {
          return paymentLinkQRData.pgDetails?.imageContent ? (
            <RenderQRCode
              paymentLink={paymentLinkQRData}
              totalCharges={paymentLinkQRData?.amount}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              isPaymentLinkGenerated={isPaymentLinkGenerated}
              onSuccesTimerCompletion={toggleExtendRideDrawer}
            />
          ) : (
            <Stack gap="20px" alignItems="center">
              <RenderPaymentLink
                paymentLink={paymentLinkQRData}
                tryAnotherPaymentMethod={tryAnotherPaymentMethod}
              />
            </Stack>
          );
        }
        if (paymentLinkQRData.paymentFlow === OrderPaymentType.PAYMENT_LINK) {
          return (
            <RenderPaymentLink
              paymentLink={paymentLinkQRData}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
        if (paymentLinkQRData.paymentFlow === OrderPaymentType.CASH) {
          return (
            <RenderCashUI
              paymentLink={paymentLinkQRData}
              tryAnotherPaymentMethod={tryAnotherPaymentMethod}
            />
          );
        }
      }
    }
    return renderPaymentOptions();
  };

  //sucess callback function when image upload is successful end ride drawer
  const sucessCallbackSignedUrl = (filesData: GenericObject) => {
    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      isImgUploaded: true,
      fileName: filesData?.fileNames[0],
    }));
  };

  //get signed url for extend ride drawer
  const getSignedUrl = (file: any, fileName: string) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames: [fileName],
        },
      },
      filesData: {
        bookingId: bookingId,
        files: [file],
        fileNames: [fileName],
      },
      isUploadDocumentBreak: true,
      sucessCallback: sucessCallbackSignedUrl,
    };
    dispatch(bookingManagementActions.generateDocsSignedUrls(payload));
  };

  // on Imgae click delete in end ride drawer
  const onClickImageDelete = () => {
    setFields((prev: any) => ({ ...prev, isImgUploaded: false, fileName: "" }));
    dispatch(bookingManagementActions.clearReducerKeys({ attachmentIds: [] }));
  };

  // on drop image in dropzone in end ride drawrr
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    let files: GenericObject = acceptedFiles[0];
    let fileNames: string = acceptedFiles[0]?.name;
    // let rejectedFiles: object[] = [];

    let error: boolean = false;
    fileRejections.map((item: any) => {
      if (item?.errors[0]?.code) {
        error = true;
        toastr.warning(
          "",
          uploadError[item.errors[0]?.code] ??
            rentalEn?.errorMessages?.SomethingWentWrong
        );
        return;
      }
    });

    if (error) return;
    getSignedUrl(files, fileNames);
  }, []);

  // for Image  dropzone in end ride drawer
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },

    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    noClick: false,
  });

  //success call for coupon applied
  const couponAppliedSuccess = () => {
    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      isCouponApplied: true,
    }));
    toastr.success("", rentalEn?.errorMessages?.couponAppliedSuccessfully);
  };

  // get calculated extension charges
  const calculateExtensionCharges = (data: GenericObject) => {
    const {
      odometerReading,
      endDate,
      unlimitedSelected,
      helmetSelection,
      premiumSelected,
      couponCode = "",
    } = data;
    const payload: CalculateExtensionPayload = {
      apiBody: {
        data: {
          odometerReading: parseInt(odometerReading),
          endDate: parseInt(getTimestampAtEndOfDay(endDate)),
          unlimitedSelected,
          premiumSelected,
          helmetSelection,
          couponCode: couponCode ? couponCode : undefined,
        },
      },
      bookingId,
      couponSuccessCallback: couponCode ? couponAppliedSuccess : undefined,
    };

    dispatch(
      bookingManagementActions.calculateBookingsExtensionCharges(payload)
    );
  };

  // on click applyand remove coupon
  const onClickCoupon = () => {
    let tempObj: any = {
      odometerReading: fields.odometerReading,
      endDate: fields.endDate,
      unlimitedSelected: fields.unlimitedSelected,
      premiumSelected: fields.premiumSelected,
      helmetSelection: fields.helmetSelection,
    };

    if (fields.isCouponApplied) {
      setFields((prev: ExtensionRideFields) => ({
        ...prev,
        isCouponApplied: false,
        couponCode: "",
      }));
      calculateExtensionCharges(tempObj);
    } else {
      if (fields.couponCode) tempObj["couponCode"] = fields.couponCode;
      calculateExtensionCharges(tempObj);
    }
  };

  // set complementary helmet
  const setComplementaryHelmet = () => {
    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      isComplementary: true,
    }));
  };

  // handle complementary helmet
  const handleComplementaryHelmet = (isComplementary: boolean) => {
    setFields((prev: ExtensionRideFields) => ({
      ...prev,
      isComplementary,
    }));
  };

  // returns sub categories bifurgations of the payment summary
  const getSubCategories = (type: string, data?: number): string => {
    if (BookingChargesSubCategories.EXCESS_KM_CHARGES === type) {
      return `${bookingDetailsLocale.excessKms} (${getFormattedINR(
        planAddOns?.planDetails?.excessKmCharge
      )}/KM x ${data} KM) :`;
    } else if (BookingChargesSubCategories.EXCESS_HOUR_CHARGES === type) {
      return `${bookingDetailsLocale.excessHr} (${getFormattedINR(
        planAddOns?.planDetails?.excessHrCharge
      )}/Hr x ${data} Hr) :`;
    } else
      return `${
        bookingChargesSubCategoriesDisplay[
          type as keyof typeof bookingChargesSubCategoriesDisplay
        ]
      } :`;
  };

  // extend ride screen ui
  const ExtendRideScreen = () => {
    return (
      <Fade>
        {" "}
        <Box>
          <Stack
            sx={{
              gap: "10px",
              padding: "10px",
              marginBottom:
                Object.keys(extensionPlansDetails).length > 0
                  ? "120px"
                  : "50px",
            }}
          >
            {/* odometer reading accordion */}
            <CustomAccordion
              expand={expand.odometerReading}
              onClick={() => {
                handleExpansion("odometerReading", !expand.odometerReading);
              }}
              renderAccordianTab={
                <Box>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    sx={{ padding: "0px" }}
                  >
                    {bookingDetailsLocale.odometerReading}
                  </Typography>
                </Box>
              }
              renderAccordionBody={
                <OdometerReadingUi
                  onChange={onOdometerReadingChange}
                  errFields={errFields}
                  fields={fields}
                  getInputProps={getInputProps}
                  getRootProps={getRootProps}
                  onClickImageDelete={onClickImageDelete}
                  bookingDocsLoader={bookingDocsLoader}
                  docUploadLinearProgress={docUploadLinearProgress}
                  loading={loading}
                  bookingDetails={bookingDetails}
                />
              }
              customTabStyle={{
                borderRadius: expand.odometerReading
                  ? "16px 16px 0px 0px"
                  : "45px",
                maxHeight: "45px",
              }}
              customBodyStyle={{ borderRadius: "0px 0px 14px 14px" }}
            />

            {/* Pacakage details accordion */}
            <CustomAccordion
              expand={expand.pacakageDetails}
              onClick={() => {
                handleExpansion("pacakageDetails", !expand.pacakageDetails);
              }}
              renderAccordianTab={
                <Box>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    sx={{ padding: "0px" }}
                  >
                    {bookingDetailsLocale.pacakageDetails}
                  </Typography>
                </Box>
              }
              renderAccordionBody={
                <PacakageDetailsUi
                  fields={fields}
                  handlepackageChange={handlepackageChange}
                  handleDateChange={handleDateChange}
                  bookingDetails={bookingDetails}
                  extensionAvailableDate={extensionAvailableDate}
                  extensionPlansDetails={extensionPlansDetails}
                  handlePlanSelection={handlePlanSelection}
                  planTypeArray={planTypeArray}
                />
              }
              customTabStyle={{
                borderRadius: expand.pacakageDetails
                  ? "16px 16px 0px 0px"
                  : "45px",
                maxHeight: "45px",
              }}
              customBodyStyle={{ borderRadius: "0px 0px 14px 14px" }}
              disabled={
                fields.odometerReading >
                bookingDetails?.bookingVehicleDetails?.currentReading
                  ? false
                  : true
              }
            />

            {/* Additional helmet accordion */}
            <CustomAccordion
              expand={expand.additionalHelmet}
              onClick={() => {
                handleExpansion("additionalHelmet", !expand.additionalHelmet);
              }}
              renderAccordianTab={
                <Box>
                  <Typography
                    variant={typographyConstants.SUBHEADING}
                    sx={{ padding: "0px" }}
                  >
                    {bookingDetailsLocale.helmet}
                  </Typography>
                </Box>
              }
              renderAccordionBody={
                <AdditionalHelmetUi
                  fields={fields}
                  extensionPlansDetails={extensionPlansDetails}
                  extraHelmetHandler={extraHelmetHandler}
                  errFields={errFields}
                  setComplementaryHelmet={setComplementaryHelmet}
                  handleComplementaryHelmet={handleComplementaryHelmet}
                />
              }
              customTabStyle={{
                borderRadius: expand.additionalHelmet
                  ? "16px 16px 0px 0px"
                  : "45px",
                maxHeight: "45px",
              }}
              customBodyStyle={{ borderRadius: "0px 0px 14px 14px" }}
              disabled={Object.keys(fields.selectedPlan).length ? false : true}
            />

            {/* Excess hm and hr card */}
            {Object.keys(extensionPlansDetails).length > 0 ? (
              <Box
                sx={{
                  backgroundColor: "#01353d14",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <Box sx={StyleObject.excessChargeHrKmContainer}>
                  {extraCharges?.map(
                    (extraCharges: ExtraCharges, index: number) => {
                      return (
                        <Stack>
                          <Typography
                            variant={typographyConstants.SUBHEADING}
                            color={colors.tertiary_black}
                          >
                            {extraCharges?.heading}
                          </Typography>
                          <Typography
                            variant={typographyConstants.HEADING}
                            padding={"0px"}
                            color={colors.text_black}
                          >
                            {getFormattedINR(extraCharges?.value)}/
                            {extraCharges?.type}
                          </Typography>
                        </Stack>
                      );
                    }
                  )}
                </Box>
                <img src={BenefitsCard} />
              </Box>
            ) : null}
          </Stack>

          {/* payment breakup */}
          {Object.keys(calculatedExtensionCharge).length > 0 ? (
            <PaymentBreakupUi
              calculatedExtensionCharge={calculatedExtensionCharge}
              fields={fields}
              expand={expand}
              handleExpansion={handleExpansion}
              getOrderSummary={getOrderSummary}
              getSubCategories={getSubCategories}
            />
          ) : null}
        </Box>
      </Fade>
    );
  };

  // payments option ui
  const PaymentOptionsUi = () => {
    const { orderSummary, discounts } = getOrderSummary();

    return (
      <Stack sx={{ padding: "10px", gap: "12px", marginBottom: "50px" }}>
        <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
          <Box
            className={styles?.backIcon_CompleteRide}
            onClick={() =>
              setComponentMount(ComponentMapping.EXTEND_RIDE_SCREEN)
            }
          >
            <img src={LeftArrowIcon} />
          </Box>
          <Typography variant={typographyConstants.SUBHEADING}>
            {bookingDetailsLocale.orderSummary}
          </Typography>
        </Box>
        <Box>
          {Object.keys(calculatedExtensionCharge).length > 0 && (
            <Fade>
              {orderSummary?.map((item: any, index: number) => {
                return (
                  <CustomHoverPopover
                    popupContent={
                      <Stack sx={{ padding: "16px", gap: "3px" }}>
                        {item.subCategory?.map((charge: any, index: number) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "3px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant={typographyConstants.BODY}
                                color={colors.text_secondary_gray}
                                sx={{ fontWeight: 500 }}
                              >{`${index + 1}. `}</Typography>
                              <Typography
                                variant={typographyConstants.BODY}
                                color={colors.text_secondary_gray}
                                sx={{ fontWeight: 500 }}
                              >
                                {getSubCategories(
                                  charge?.category,
                                  charge?.excess
                                )}
                              </Typography>
                              <Typography
                                variant={typographyConstants.SUBHEADING}
                                color={colors.text_black}
                                sx={{ padding: "0px" }}
                              >
                                {getFormattedINR(charge?.amount)}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Stack>
                    }
                    hoverContent={
                      <Box
                        sx={{
                          ...StyleObject.orderSummaryContainer,
                          borderRadius: index ? "0px" : "10px 10px 0px 0px",
                        }}
                      >
                        <Stack>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              color:
                                item.category ===
                                  BookingChargesCategories.ADDITIONAL_CHARGES ||
                                item.category ===
                                  BookingChargesCategories.PENALTY_CHARGES
                                  ? colors.primary_danger
                                  : colors.text_black,
                              fontSize: "14px",
                            }}
                            variant={typographyConstants.BODY}
                          >
                            {item.category ===
                            BookingChargesCategories.PLAN_CHARGES
                              ? `${fields.selectedPackage} ${
                                  fields.selectedPackage === 1
                                    ? "Day package"
                                    : "Days package"
                                } (${fields?.selectedPlan?.heading})`
                              : bookingChargesCategoriesDisplay[
                                  item.category as keyof typeof bookingChargesCategoriesDisplay
                                ]}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color:
                              item.category ===
                                BookingChargesCategories.ADDITIONAL_CHARGES ||
                              item.category ===
                                BookingChargesCategories.PENALTY_CHARGES
                                ? colors.primary_danger
                                : colors.text_black,
                            padding: "0px",
                          }}
                          variant={typographyConstants.SUBHEADING}
                        >
                          {getFormattedINR(item?.totalAmount)}
                        </Typography>
                      </Box>
                    }
                    // showPopOver={
                    //   item.category !== BookingChargesCategories.PLAN_CHARGES
                    // }
                  />
                );
              })}
              {calculatedExtensionCharge?.totalDiscount > 0 && (
                <CustomHoverPopover
                  popupContent={
                    <Stack sx={{ padding: "16px", gap: "3px" }}>
                      {discountsTypeArray?.map(
                        (type: string, index: number) => {
                          let discount = discounts?.get(type);

                          // return coupon code string
                          const getCoupon = (): string => `(${discount?.code})`;

                          if (discount?.amount)
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "3px",
                                  alignItems: "center",
                                }}
                              >
                                {Bullet}
                                <Typography
                                  variant={typographyConstants.BODY}
                                  color={colors.text_secondary_gray}
                                  sx={{ fontWeight: 500 }}
                                >
                                  {`${
                                    discountTypesDisplay[
                                      type as keyof typeof discountTypesDisplay
                                    ]
                                  } ${
                                    type === discountsTypes.COUPON_CODE
                                      ? getCoupon()
                                      : ""
                                  } :`}
                                </Typography>
                                <Typography
                                  variant={typographyConstants.SUBHEADING}
                                  color={colors.text_black}
                                  sx={{ padding: "0px" }}
                                >
                                  {getFormattedINR(discount?.amount)}
                                </Typography>
                              </Box>
                            );
                        }
                      )}
                    </Stack>
                  }
                  hoverContent={
                    <Box
                      sx={{
                        ...StyleObject.orderSummaryTotalContainer,
                        borderRadius: "0px",
                      }}
                    >
                      <Stack>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: colors.secondary_green,
                            fontSize: "14px",
                            padding: "0px",
                          }}
                          variant={typographyConstants.SUBHEADING}
                        >
                          {global.discount}
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: colors.secondary_green,
                          padding: "0px",
                        }}
                        variant={typographyConstants.SUBHEADING}
                      >
                        {`- ${getFormattedINR(
                          calculatedExtensionCharge?.totalDiscount
                        )}`}
                      </Typography>
                    </Box>
                  }
                />
              )}
              <Box sx={StyleObject.orderSummaryTotalContainer}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: colors.text_black,
                      fontSize: "14px",
                      padding: "0px",
                    }}
                    variant={typographyConstants.SUBHEADING}
                  >
                    {global.total}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: colors.text_black,
                    padding: "0px",
                  }}
                  variant={typographyConstants.SUBHEADING}
                >
                  {getFormattedINR(
                    calculatedExtensionCharge?.totalAmount -
                      calculatedExtensionCharge?.totalDiscount
                  )}
                </Typography>
              </Box>
            </Fade>
          )}
        </Box>
        <Stack marginBottom={"20px"} marginTop={"15px"}>
          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors.text_secondary_gray}
            fontSize={"12px"}
            marginBottom={"5px"}
          >
            {rentalEn.manualBooking.OffersAndBenefits}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              width: "100%",
            }}
          >
            <Input
              onChange={(e: any) => {
                e.preventDefault();
                setFields((prev: ExtensionRideFields) => ({
                  ...prev,
                  couponCode: e?.target?.value?.toUpperCase(),
                }));
              }}
              iconStart={ApplyCouponIcon}
              value={fields?.couponCode.toUpperCase()}
              placeholder={rentalEn?.manualBooking?.applyCoupons}
              width="250px"
              containerWidth="250px"
              disabled={fields.isCouponApplied}
            />
            <CustomButton
              variant="outlined"
              label={
                fields.isCouponApplied
                  ? rentalEn?.global?.remove
                  : rentalEn?.global?.apply
              }
              sx={{ padding: "4px 15px" }}
              onClick={onClickCoupon}
              disabled={!fields?.couponCode}
              loading={calculatedExtensionChargeLoader}
            />
          </Box>
        </Stack>
        <Stack gap="15px">
          <Box>
            <Typography
              color={colors.text_secondary_gray}
              fontSize="12px"
              fontWeight="500"
              marginBottom="5px"
            >
              {rentalEn.manualBooking.SelectPaymentMethod}:
            </Typography>

            <Box
              sx={{
                backgroundColor: colors.white,
                borderRadius: "12px",
                border: "1px solid #00000029",
              }}
            >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={fields?.selectedPaymentMode}
                onChange={(e: any) => handlePaymentModeChange(e)}
              >
                {operatorConfigData?.allowedPaymentFlows?.map(
                  (mode: string, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          borderBottom: index < 2 ? "1px solid #D4E4E452" : "",
                          padding: "2px 18px",
                        }}
                      >
                        <FormControlLabel
                          value={mode}
                          control={<Radio />}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              color: colors.text_secondary_gray,
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                          }}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                marginLeft: "10px",
                              }}
                            >
                              <img src={paymentModeIcons[mode]} />
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  color: colors.text_black,
                                }}
                              >
                                {
                                  DisplayPaymentModes[
                                    mode as keyof typeof DisplayPaymentModes
                                  ]
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    );
                  }
                )}
              </RadioGroup>
            </Box>
          </Box>
        </Stack>
      </Stack>
    );
  };

  // mapping of component
  const componentMapping: any = {
    [ComponentMapping.EXTEND_RIDE_SCREEN]: {
      key: ComponentMapping.EXTEND_RIDE_SCREEN,
      component: ExtendRideScreen(),
      buttonLabel: "Proceed",
    },
    [ComponentMapping.PAYMENT_OPTIONS_SCREEN]: {
      key: ComponentMapping.PAYMENT_OPTIONS_SCREEN,
      component: PaymentOptionsUi(),
      buttonLabel: getPaymentTypeText(fields.selectedPaymentMode),
    },

    [ComponentMapping.PAYMENT_MODE_SCREEN]: {
      key: ComponentMapping?.PAYMENT_MODE_SCREEN,
      component: renderContent(),
    },
  };

  // main return
  return (
    <Box>
      {componentMapping[componentMount].component}
      {/* Button */}
      {(componentMount === ComponentMapping.PAYMENT_OPTIONS_SCREEN ||
        componentMount === ComponentMapping.EXTEND_RIDE_SCREEN) && (
        <Box
          className={styles?.buttonSideDrawer}
          sx={{ width: "100%", marginLeft: "10px" }}
        >
          <CustomButton
            label={componentMapping[componentMount]?.buttonLabel}
            variant="outlined"
            style={{ width: "348px" }}
            onClick={() => {
              onClickButton();
            }}
            disabled={isDisabledButton()}
            loading={paymentLinkQRLoader || createExtensionLoader}
          />
        </Box>
      )}
    </Box>
  );
};
export default BookingExtension;
