import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import { AppLoaderLottie } from "../../rentals-src/constants/exportLottie";

const AppLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie
        style={{ height: "250px", width: "250px" }}
        animationData={AppLoaderLottie}
        loop
      />
    </Box>
  );
};

export default AppLoader;
