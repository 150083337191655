import * as actionTypes from "../actionTypes";

export const clearCheckUserDetails = () => ({
  type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_CHECK_USER_DETAILS,
});

// Clear Verify OTP State from reducer
export const clearVerifyOtp = () => ({
  type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_VERIFY_OTP,
});

export const searchUser = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_USER_REQUESTED,
  payload,
});

export const getAvailableModels = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_REQUESTED,
  payload,
});

export const calculateCharges = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_REQUESTED,
  payload,
});

export const getSlotPause = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_REQUESTED,
  payload,
});

export const getModelDetails = (payload: any) => ({
  type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_REQUESTED,
  payload,
});

//search whether the customer exist or not
export const searchCustomer = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_REQUESTED,
    payload,
  };
};

// if the user doesnot exists then register user
export const registerUser = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_REQUESTED,
    payload,
  };
};

// Verify OTP
export const verifyOTP = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_REQUESTED,
    payload,
  };
};

// Resend OTP
export const resendOTP = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_REQUESTED,
    payload,
  };
};

// Get Customer Profile
export const getCustomerProfile = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_REQUESTED,
    payload,
  };
};

// get delivery SLots
export const getDeliverySlots = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_REQUESTED,
    payload,
  };
};

// Add Customer Address
export const addCustomerAddress = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_REQUESTED,
    payload,
  };
};

// Get Customer Address List
export const getCustomerAddressList = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS
      .GET_CUSTOMER_ADDRESS_LIST_REQUESTED,
    payload,
  };
};

// create Booking
export const createBooking = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_REQUESTED,
    payload,
  };
};

//clear data reducer
export const clearReducerData = (payload: any) => {
  return {
    type: actionTypes.MANUAL_BOOKING_ACTIONS.RENTAL_CLEAR_REDUCER_DATA,
    payload,
  };
};
