import { toastr } from "react-redux-toastr";
import { isArrayNotEmpty } from "../../../../utils/helper";
import * as actionTypes from "../../actions/actionTypes";

interface manualBookingReducerInterface {
  userDetails: Object | null; // details of user who is already registered
  checkUserLoader: boolean; // check user loader
  availableModels: any; // getting list of available models
  slotPauseDates: any; //  list of pause dates
  modelDetails: Object | string; // details of selected model
  showUserData: boolean | null; // check if user data is shown or not
  checkIsUserExist: boolean | null; // check if user exist or not
  registerUserDetails: Object | null; // details of user who is registered
  isOTPMatch: boolean | null; // check if OTP is matched or not
  userRegistered: boolean | null; // check if user is registered or not
  deliverySlots: any; // list of delivery slots
  // customerAddress: any; // add customer address
  customerAddressList: any; // list of customer addresses
  charges: any; // getting charges for selected model and time slot
  isManualBookingCreated: boolean; // check if booking is created or not
  bookingCreatedDeatils: Object | null; // details of created booking
  isGetModels: boolean; // check if models are getting or not
  paymentLoader: boolean; // check if payment loader is running or not
  loadingCalculateCharges: boolean; // check if charges are getting or not
  isGetDeliverySlots: boolean; // check if delivery slots are getting or not
  isUserBlocked: boolean; // check if user is blocked
  loading: boolean;
}

const initialState: manualBookingReducerInterface = {
  userDetails: "",
  checkUserLoader: false,
  availableModels: null,
  slotPauseDates: null,
  modelDetails: "",
  loading: false,
  showUserData: null,
  checkIsUserExist: null,
  registerUserDetails: null,
  isOTPMatch: null,
  userRegistered: null,
  deliverySlots: "",
  // customerAddress: [],
  customerAddressList: [],
  charges: [],
  paymentLoader: false,
  isManualBookingCreated: false,
  bookingCreatedDeatils: [],
  isGetModels: false,
  loadingCalculateCharges: false,
  isGetDeliverySlots: false,
  isUserBlocked: false,
};

const newManualBookingReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_CHECK_USER_DETAILS:
      return {
        ...state,
        ...initialState,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_REQUESTED:
      return {
        ...state,
        checkUserLoader: true,
        showUserData: false,
        checkIsUserExist: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_FAILED:
      return {
        ...state,
        checkUserLoader: false,
        userDetails: "",
        checkIsUserExist: false,
        showUserData: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_SUCCESS:
      return {
        ...state,
        availableModels: isArrayNotEmpty(payload?.availableModels)
          ? payload?.availableModels
          : [],
        isGetModels: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_FAILED:
      return {
        ...state,
        availableModels: null,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_REQUESTED:
      return {
        ...state,
        loadingCalculateCharges: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_SUCCESS:
      return {
        ...state,
        charges: payload,
        loadingCalculateCharges: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_FAILED:
      return {
        ...state,
        // charges: null,
        loadingCalculateCharges: false,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_SUCCESS:
      return {
        ...state,
        slotPauseDates: payload?.slotPauseDates,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_FAILED:
      return {
        ...state,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_SUCCESS:
      return {
        ...state,
        modelDetails: payload,
        loading: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_FAILED:
      return {
        ...state,
        modelDetails: "",
        loading: false,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerUserDetails: payload,
        otpSent: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_FAILED:
      return {
        ...state,
        registerUserDetails: null,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isOTPMatch: true,
        userRegistered: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_FAILED:
      return {
        ...state,
        isOTPMatch: false,
      };

    case actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_VERIFY_OTP:
      return {
        ...state,
        isOTPMatch: false,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_FAILED:
      return {
        ...state,
      };

    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_REQUESTED:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        userDetails: payload,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_FAILED:
      return {
        ...state,
        userDetails: null,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_REQUESTED:
      return {
        ...state,
        isGetDeliverySlots: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_SUCCESS:
      return {
        ...state,
        deliverySlots: payload,
        isGetDeliverySlots: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_FAILED:
      return {
        ...state,
        deliverySlots: "",
        isGetDeliverySlots: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_REQUESTED:
      return {
        ...state,
        customerAddressList: { ...state.customerAddressList, isLoading: true },
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_FAILED:
      return {
        ...state,
        customerAddressList: { ...state.customerAddressList, isLoading: false },
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_ADDRESS_LIST_REQUESTED:
      return {
        ...state,
        customerAddressList: { ...state.customerAddressList, isLoading: true },
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        customerAddressList: { ...payload, isLoading: false },
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_ADDRESS_LIST_FAILED:
      return {
        ...state,
        customerAddressList: { addresses: [], isLoading: false },
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_REQUESTED:
      return {
        ...state,
        paymentLoader: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        bookingCreatedDeatils: payload,
        paymentLoader: false,
        isManualBookingCreated: true,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_FAILED:
      return {
        ...state,
        bookingCreatedDeatils: null,
        paymentLoader: false,
        isManualBookingCreated: false,
      };
    case actionTypes.MANUAL_BOOKING_ACTIONS.RENTAL_CLEAR_REDUCER_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return { ...state };
  }
};

export default newManualBookingReducer;
