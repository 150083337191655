import { Box, Modal, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { DataTable } from "../../../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../../../themes/colors";
import { typographyConstants } from "../../../../../../constants/constants";
import { CrossIconRound } from "../../../../../../constants/exportImages";
import rentalEn from "../../../../../../locale/rental-en.json";
import styles from "../section.module.css";

export const TranferVehicleDetailModal = ({
  openModal,
  handleModalClose,
  allTransferVehicleData,
  getTranferVehicleList,
  loading,
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    bgcolor: "background.paper",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    // padding: "20px",
  };

  const [fields, setFields] = useState({
    paginationModel: { page: 0, pageSize: 10 },
  });
  // Columns for data grid
  const columns: GridColDef[] = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: rentalEn?.tableHeaders?.vehicleNumber,
      headerAlign: "center",
      align: "center",
      // // width: 120,
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {params?.row?.vehicleNumber ? params?.row?.vehicleNumber : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "modelName",
      headerClassName: "super-app-theme--header",
      headerName: rentalEn?.tableHeaders?.modelName,
      headerAlign: "center",
      // width: 100,
      align: "center",
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.modelName ? params?.row?.modelName : "NA"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "branchDisplayName",
      cellClassName: "hub-cell",
      headerName: "From Hub",
      headerAlign: "center",
      // width: 100,
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            <Tooltip
              title={
                params?.row?.branchDisplayName
                  ? params?.row?.branchDisplayName
                  : "NA"
              }
              arrow
            >
              {params?.row?.branchDisplayName
                ? params?.row?.branchDisplayName
                : "NA"}
            </Tooltip>
          </div>
        );
      },
      flex: 1,
      resizable: false,
    },

    {
      field: "status",
      cellClassName: "booking-status-cell",
      headerName: rentalEn?.tableHeaders?.status,
      headerAlign: "center",
      // width: 100,
      align: "center",
      renderCell: (params: any) => {
        return <> {params?.row?.status ? params?.row?.status : "NA"}</>;
      },
      flex: 1,
      resizable: false,
    },

    {
      field: "remark",
      cellClassName: "booking-status-cell",
      headerName: "Remark",
      headerAlign: "center",
      // width: 120,
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {" "}
            <Tooltip
              title={params?.row?.remark ? params?.row?.remark : "NA"}
              arrow
            >
              {params?.row?.remark ? params?.row?.remark : "NA"}
            </Tooltip>
          </>
        );
      },
      flex: 1,
      resizable: false,
    },
  ];

  //
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    getTranferVehicleList({
      page: page,
      pageSize: pageSize,
    });
  };

  //get id of row for selection model
  const getRowId = (row: any) => {
    return row?.vehicleNumber;
  };

  return (
    <>
      {" "}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <>
          <Box sx={{ ...style }}>
            <Box
              sx={{
                position: "relative",
                // bottom: "168px",
                top: "-57px",
                left: "44%",
              }}
            >
              <img
                src={CrossIconRound}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                }}
                onClick={handleModalClose}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: colors.THEME_BLUE,
              }}
              className={styles.bulkUploadErrorModal}
            >
              <Typography
                variant={typographyConstants.HEADING}
                color={colors.white}
                sx={{ fontSize: "14px" }}
              >
                {rentalEn?.VehicleManagement?.vehicleTransferDetails}
              </Typography>
            </Box>
            <Box sx={{ padding: "20px" }}>
              <DataTable
                columns={columns}
                rows={allTransferVehicleData?.transferDetails}
                paginationMode="server"
                rowCount={allTransferVehicleData?.pageData?.totalCount ?? 0}
                paginationModel={fields.paginationModel}
                onPaginationModelChange={(val: any) => {
                  handlePaginationModelChange(val);
                }}
                getRowId={getRowId}
                hideColumnSeperator
                loading={loading}
                // loading={onLoad}
              />
            </Box>
          </Box>
        </>
      </Modal>
    </>
  );
};
