import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import masterServices from "../../services/masterServices";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../utils/helper";
import { commonServices } from "../../services";

function* fetchAllModels(action: any): any {
  const data = yield call(masterServices.getAllModels, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_MODEL_DATA_SUCCESS,
      payload: {
        modelData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}

function* fetchAllCategories(action: any): any {
  const data = yield call(masterServices.getAllCategories, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_DATA_SUCCESS,
      payload: {
        categoryData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_DATA_FAILED,
    });
  }
}

function* fetchAllCities(action: any): any {
  const data = yield call(masterServices.getAllCities, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_DATA_SUCCESS,
      payload: {
        cityData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_DATA_FAILED,
    });
  }
}

function* fetchCityStatusList(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_STATUS_LIST_SUCCESS,
      payload: {
        cityStatusData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_STATUS_LIST_FAILED,
    });
  }
}

function* fetchHelmetList(action: any): any {
  const data = yield call(commonServices.commonService, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_HELMET_CONFIG_SUCCESS,
      payload: {
        helmetStatusData: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_HELMET_CONFIG_FAILED,
    });
  }
}

function* addCity(action: any): any {
  const data = yield call(masterServices.addCity, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_CITY_DATA_SUCCESS,
      payload: {
        cityData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_CITY_DATA_FAILED,
    });
  }
}

function* addCategory(action: any): any {
  const data = yield call(masterServices.addCategory, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_CATEGORY_DATA_SUCCESS,
      payload: {
        categoryData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_CATEGORY_DATA_FAILED,
    });
  }
}
function* editAdvertisement(action: any): any {
  const data = yield call(masterServices.editAdvertisement, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));

    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_SUCCESS,
      payload: {
        modelData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_FAILED,
    });
  }
}
function* getAdvertisementById(action: any): any {
  const data = yield call(masterServices.getAdvertisementById, action.payload);
  if (data?.status === 200) {
    // toastr.success("", handleMessage(data));

    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_SUCCESS,
      payload: {
        modelData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_FAILED,
    });
  }
}
function* addAdvertisement(action: any): any {
  const data = yield call(masterServices.addAdvertisement, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));

    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_SUCCESS,
      payload: {
        modelData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_FAILED,
    });
  }
}

function* addModels(action: any): any {
  const data = yield call(masterServices.addModel, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));

    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_SUCCESS,
      payload: {
        modelData: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_FAILED,
    });
  }
}

function* getCityById(action: any): any {
  const data = yield call(masterServices.getCityById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_BY_ID_SUCCESS,
      payload: {
        cityById: data?.data?.Result || [],
        editsuccess: true,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CITY_BY_ID_FAILED,
    });
  }
}

function* getModelById(action: any): any {
  const data = yield call(masterServices.getModelById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_MODEL_BY_ID_SUCCESS,
      payload: {
        modelById: data?.data?.Result || [],
        editsuccess: true,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_MODEL_BY_ID_FAILED,
    });
  }
}

function* getCategoryById(action: any): any {
  const data = yield call(masterServices.getCategoryById, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_BY_ID_SUCCESS,
      payload: {
        categoryById: data?.data?.Result || [],
        editsuccess: true,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.GET_CATEGORY_BY_ID_FAILED,
    });
  }
}

function* editCity(action: any): any {
  const data = yield call(masterServices.editCity, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_CITY_DATA_SUCCESS,
      payload: {
        cityById: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_CITY_DATA_SUCCESS,
    });
  }
}

function* editModel(action: any): any {
  const data = yield call(masterServices.editModel, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));

    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_MODEL_DATA_SUCCESS,
      payload: {
        modelById: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_MODEL_DATA_FAILED,
    });
  }
}

function* editCategory(action: any): any {
  const data = yield call(masterServices.editCategory, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_CATEGORY_DATA_SUCCESS,
      payload: {
        cityById: data?.data?.Result || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.EDIT_CATEGORY_DATA_FAILED,
    });
  }
}

function* deleteModel(action: any): any {
  const data = yield call(masterServices.deleteModel, action.payload);

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.DELETE_MODEL_DATA_SUCCESS,
      payload: {
        deletedDataId: action.payload.id,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.DELETE_MODEL_DATA_FAILED,
      payload: {
        deletedDataId: action.payload.id,
      },
    });
  }
}

function* deleteCategory(action: any): any {
  const data = yield call(masterServices.deleteCategory, action.payload);
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.DELETE_CATEGORY_DATA_SUCCESS,
      payload: {
        deletedDataId: action.payload.id,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.MASTER_ACTIONS.DELETE_CATEGORY_DATA_FAILED,
    });
  }
}

function* masterSaga() {
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_CITY_DATA_REQUESTED,
    fetchAllCities
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_CITY_STATUS_LIST_REQUESTED,
    fetchCityStatusList
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_HELMET_CONFIG_REQUESTED,
    fetchHelmetList
  );
  yield takeLatest(actionTypes.MASTER_ACTIONS.ADD_CITY_DATA_REQUESTED, addCity);
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_CITY_BY_ID_REQUESTED,
    getCityById
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.EDIT_CITY_DATA_REQUESTED,
    editCity
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_CATEGORY_DATA_REQUESTED,
    fetchAllCategories
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.ADD_CATEGORY_DATA_REQUESTED,
    addCategory
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_CATEGORY_BY_ID_REQUESTED,
    getCategoryById
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.EDIT_CATEGORY_DATA_REQUESTED,
    editCategory
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.DELETE_CATEGORY_DATA_REQUESTED,
    deleteCategory
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_MODEL_DATA_REQUESTED,
    fetchAllModels
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.ADD_MODEL_DATA_REQUESTED,
    addModels
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_MODEL_BY_ID_REQUESTED,
    getModelById
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.EDIT_MODEL_DATA_REQUESTED,
    editModel
  );

  yield takeLatest(
    actionTypes.MASTER_ACTIONS.DELETE_MODEL_DATA_REQUESTED,
    deleteModel
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.ADD_ADVERTISEMENT_DATA_REQUESTED,
    addAdvertisement
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.EDIT_ADVERTISEMENT_DATA_REQUESTED,
    editAdvertisement
  );
  yield takeLatest(
    actionTypes.MASTER_ACTIONS.GET_ADVERTISEMENT_BY_ID_REQUESTED,
    getAdvertisementById
  );
}

export default masterSaga;
