import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import OTPField from "../../../../sharedComponents/atoms/OTPFeild";
import { typographyConstants } from "../../../../rentals-src/constants/constants";
import { colors } from "../../../../themes/colors";
import en from "../../../../locale/en.json";
import CountdownTimer from "../../../../sharedComponents/atoms/CountdownTimer";
import CustomerOtpLoader from "../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/CustomerOtpLoader";

const AddEmergencyOtpUi = ({
  customerId,
  fields,
  errorFieldsOtp,
  setOtp,
  otpValue,
  handleCloseSendOTPModal,
  ResendOtpClickHandler,
  handleOtpModalSubmit,
  isOTPInvalid,
  isOtpResent,
  showResetButtonHandler,
  showResetOtpBtn,
  handleBackButtonClick,
  verifyEmergencyOtpLoader,
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    // bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  };
  return (
    <>
      {verifyEmergencyOtpLoader ? (
        <CustomerOtpLoader />
      ) : (
        <Box sx={style}>
          <Stack alignItems={"center"}>
            <Stack alignItems={"center"}>
              <Typography variant={typographyConstants.HEADINGXL}>
                OTP Verification
              </Typography>
              <Typography
                variant={typographyConstants.BODY}
                color={colors.secondary_light_gray}
              >
                Enter the code from sms we sent to
              </Typography>
              <Typography variant={typographyConstants.HEADING} padding={"0px"}>
                +91{fields?.mobileNumber}
              </Typography>
            </Stack>
            <Stack alignItems={"center"} gap="10px">
              {/* countdown component */}
              <CountdownTimer
                label="OTP Expires in"
                showResetButtonHandler={() => showResetButtonHandler()}
                isOtpResent={isOtpResent}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <OTPField
                  separator={<span> </span>}
                  value={otpValue}
                  onChange={setOtp}
                  length={6}
                />

                {/* OTP error block */}
                {(isOTPInvalid || errorFieldsOtp) && (
                  <Typography variant="errorMessage1">
                    {errorFieldsOtp
                      ? errorFieldsOtp
                      : en.errorMessages.OtpInvalid}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant={typographyConstants.BODY}
                    color={colors.secondary_light_gray}
                  >
                    I didn't receive any code.{" "}
                  </Typography>

                  {/* Resend Otp */}
                  <CustomButton
                    label={en.global.Resend}
                    onClick={(e: any) => ResendOtpClickHandler(e)}
                    disabled={!showResetOtpBtn}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        background: "none",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <CustomButton
              variant="outlined"
              sx={{ width: "100%" }}
              label={"Back"}
              onClick={handleBackButtonClick}
            />
            <CustomButton
              variant="outlined"
              sx={{ width: "100%" }}
              label={en.global.Submit}
              onClick={() => handleOtpModalSubmit(otpValue)}
              disabled={verifyEmergencyOtpLoader}
              loading={verifyEmergencyOtpLoader}
            />
          </Stack>
        </Box>
      )}
    </>
  );
};

export default AddEmergencyOtpUi;
