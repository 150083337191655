import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { revertIsResetFlag } from "../../../rentals-src/redux/actions/customerManagementActions";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import { colors } from "../../../themes/colors";
interface CountdownTimer {
  label: string;
  showResetButtonHandler: any;
  timeInSeconds?: number;
  showResetAfterSeconds?: number;
  isOtpResent?: boolean;
}
const CountdownTimer = ({
  label,
  showResetButtonHandler,
  timeInSeconds = 120,
  showResetAfterSeconds = 90,
  isOtpResent = false,
}: CountdownTimer) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(timeInSeconds);

  useEffect(() => {
    if (isOtpResent) {
      setTime(timeInSeconds);
      dispatch(revertIsResetFlag());
    }
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === timeInSeconds - showResetAfterSeconds) {
          showResetButtonHandler();
        }
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    // cleanup
    return () => {
      clearInterval(timer);
    };
  }, [isOtpResent]);

  return (
    <Box>
      <Typography
        variant={typographyConstants.HEADING}
        color={colors.primary_dark_blue3}
      >
        <span>
          {label} {`${Math.floor(time / 60)}`.padStart(2, "0")}:
          {`${time % 60}`.padStart(2, "0")}
        </span>
      </Typography>
    </Box>
  );
};
export default CountdownTimer;
