import { Typography, Skeleton, Tooltip, Box } from "@mui/material";
import { Stack } from "@mui/system";
import styles from "../components.module.css";
import { DetailsTypographyProps } from "../../interfaces/componentsInterfaces";
import { colors } from "../../themes/colors";

const HeadingValueTypography = (props: DetailsTypographyProps) => {
  return (
    <Stack width={"140px"} className={props.elementClassList}>
      <Typography
        className={styles.bookingDetailsHeading}
        color={colors.text_secondary_gray}
      >
        {props?.heading}
      </Typography>
      {props?.loading ? (
        <Skeleton />
      ) : (
        <Box sx={{ display: "flex" }}>
          {props?.value && (
            <Tooltip title={props?.value?.length > 20 ? props.value : ""}>
              <Typography
                className={styles.bookingDetailsValue}
                color={props.color ? props.color : colors.text_black}
                sx={props?.valueStyle}
              >
                {props?.value}
              </Typography>
            </Tooltip>
          )}

          {props?.link && (
            <Tooltip title={props?.link?.length > 20 ? props.link : ""}>
              <Typography
                className={styles.bookingDetailsValue}
                color={colors.THEME_BLUEX}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => props?.clickHandler(props.link)}
              >
                {props.link}
              </Typography>
            </Tooltip>
          )}

          {props?.visitStepData && (
            <Tooltip
              title={
                props?.visitStepData?.value?.length > 20
                  ? props?.visitStepData?.value
                  : ""
              }
            >
              <Typography
                className={styles.bookingDetailsValue}
                color={props.color ? props.color : colors.THEME_BLUEX}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => props?.clickHandler(props?.visitStepData?.step)}
              >
                {props?.visitStepData?.value}
              </Typography>
            </Tooltip>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default HeadingValueTypography;
