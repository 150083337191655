import { colors } from "../../../../themes/colors";

export const StyleObject: any = {
  cellStyle: { padding: 0, border: `1px solid ${colors.card_border_color}` },
  customInputStyle: { height: "26px", minHeight: "unset", borderRadius: "6px" },
  inputContainerStyle: {
    "&.MuiTextField-root": {
      "& .MuiInputLabel-outlined": {
        color: "primary.main",
      },
      "& .MuiInputBase-inputSizeSmall": {
        color: "primary.main",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiFormHelperText-contained": {
        color: "error.main",
        margin: 0,
      },
      "&:hover fieldset": {
        borderColor: "grey",
      },
      "& .MuiInput-underline:hover:before": {
        border: "none !important",
      },
    },
  },
  checkboxStyle: {
    alignSelf: "center",
    "&.MuiCheckbox-root": {
      padding: 0,
    },
  },
  radioInputStyle: {
    "&.MuiRadio-root": {
      padding: 0,
    },
  },
  tableRowStyle: {
    height: "36px",
  },
  tableContainerStyle: {
    overflowX: "unset",
  },
};
