import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.THEME_Yellow,
      contrastText: colors.white,
      light: colors.THEME_BLUEX,
    },
    secondary: {
      main: green[500],
      dark: "linear-gradient(270.37deg, rgba(43, 199, 201, 0.24) -1.53%, rgba(27, 196, 125, 0.24) 68.84%, rgba(24, 160, 251, 0.24) 101.83%)",
    },
    background: {
      paper: colors.primary_aqua_blue,
      // default:
      //   "linear-gradient(270.37deg, rgba(43, 199, 201, 0.04) -1.53%, rgba(27, 196, 125, 0.04) 68.84%, rgba(24, 160, 251, 0.04) 101.83%)",
      default: "#F2F4F4"
    },
    text: {
      primary: colors.text_black,
      secondary: colors.text_gray,
    },
    action: {
      hover: "#1BC4A6F2",
    },
  },
  typography: {
    fontSize: 14,
    subtitle1: {
      fontSize: "10px",
    },
  },
});

export { theme };
