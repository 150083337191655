import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import SearchBar from "../../../../../../sharedComponents/atoms/InputFields/searchBar";
import {
  GenericObject,
  iotDeviceTypes,
  typographyConstants,
} from "../../../../../constants/constants";
import rentalEn from "../../../../../locale/rental-en.json";
import { StyleObject } from "../StyleObject";
import { isObjectNotEmpty } from "../../../../../../utils/helper";

/**
 * Component for managing and assigning IoT devices.
 * Provides a user interface for selecting, assigning, and viewing primary and secondary IoT devices.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.onLoad - Indicates if data is currently being loaded.
 * @param {Function} props.assignIotDeviceHandler - Function to handle the assignment of IoT devices.
 * @param {Function} props.onSelect - Function to handle the selection of an IoT device.
 * @param {GenericObject} props.selectedIot - Currently selected IoT devices (primary and secondary).
 * @param {Function} props.onInputChange - Function to handle changes in search input.
 * @param {GenericObject} props.searchQuery - State object for search queries.
 * @param {GenericObject} props.assignedIotDataPrimary - Data for the assigned primary IoT device.
 * @param {GenericObject} props.assignedIotDataSecondary - Data for the assigned secondary IoT device.
 * @param {GenericObject} props.iotDetailsObject - Detailed information of IoT devices.
 */

interface AssignIotProps {
  onLoad: boolean;
  assignIotDeviceHandler: (device: any, type: string) => void;
  onSelect: (value: any, type: string) => void;
  selectedIot: GenericObject;
  onInputChange: (value: any, type: string) => void;
  searchQuery: GenericObject;
  assignedIotDataPrimary: GenericObject;
  assignedIotDataSecondary: GenericObject;
  iotDetailsObject: GenericObject;
}

const AssignIot = (props: AssignIotProps) => {
  // Destructuring props for easier access
  const {
    onLoad,
    assignIotDeviceHandler,
    onSelect,
    selectedIot,
    onInputChange,
    searchQuery,
    assignedIotDataPrimary,
    assignedIotDataSecondary,
    iotDetailsObject,
  } = props;

  return (
    <Box display={"flex"} flexDirection={"column"} gap="20px">
      {/* PRIMARY IOT DEVICE */}
      <Stack>
        <Typography variant={typographyConstants?.HEADING}>
          {rentalEn?.VehicleManagement?.primaryIotDetails}
        </Typography>

        <Grid container spacing={1}>
          {/* Search bar component */}
          <Grid item sm={12} md={4} lg={4}>
            <SearchBar
              searchPlaceholder={
                isObjectNotEmpty(assignedIotDataPrimary)
                  ? assignedIotDataPrimary?.imeiNumber
                  : rentalEn?.VehicleManagement?.searchIotDevice
              }
              selectedValue={selectedIot?.selectedIotPrimary}
              data={iotDetailsObject[iotDeviceTypes?.PRIMARY]}
              onSelect={(val: any) => onSelect(val, iotDeviceTypes?.PRIMARY)}
              onInputChange={(val: any) =>
                onInputChange(val, iotDeviceTypes?.PRIMARY)
              }
              searchValue={searchQuery?.primarySearchValue}
              getSearchKey={(data: any) => data?.imeiNumber}
              isLoading={onLoad}
              disabled={isObjectNotEmpty(assignedIotDataPrimary) ? true : false}
            />
          </Grid>

          {/* Display input fields when search returns exactly one IoT device or forced to show */}
          {(searchQuery?.primarySearchValue &&
            iotDetailsObject[iotDeviceTypes?.PRIMARY]?.length === 1) ||
          isObjectNotEmpty(assignedIotDataPrimary) ? (
            <Fragment>
              {/* Device ID input field */}
              <Grid item sm={12} md={4} lg={3}>
                <Input
                  label={`*${rentalEn?.VehicleManagement?.deviceID}`}
                  placeholder={`*${rentalEn?.VehicleManagement?.deviceID}`}
                  variant="outlined"
                  value={
                    isObjectNotEmpty(assignedIotDataPrimary)
                      ? assignedIotDataPrimary?.id ||
                        assignedIotDataPrimary?.deviceId
                      : iotDetailsObject[iotDeviceTypes?.PRIMARY][0]?.id
                  }
                  inputProps={{ maxLength: 17 }}
                  onChange={() => null}
                  disabled={true}
                  muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
                />
              </Grid>

              {/* IMEI number input field */}
              <Grid item sm={12} md={4} lg={3}>
                <Input
                  label={`*${rentalEn?.IotManagement?.PhoneNumber}`}
                  placeholder={`*${rentalEn?.IotManagement?.PhoneNumber}`}
                  variant="outlined"
                  value={
                    isObjectNotEmpty(assignedIotDataPrimary)
                      ? assignedIotDataPrimary?.phoneNumber
                      : iotDetailsObject[iotDeviceTypes?.PRIMARY][0]
                          ?.phoneNumber
                  }
                  inputProps={{ maxLength: 17 }}
                  onChange={() => null}
                  disabled={true}
                  muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
                />
              </Grid>

              {/* Assign IoT device button */}
              <Grid item sm={12} md={4} lg={2}>
                <CustomButton
                  label={
                    isObjectNotEmpty(assignedIotDataPrimary)
                      ? rentalEn?.VehicleManagement?.assigned
                      : rentalEn?.VehicleManagement?.assign
                  }
                  variant={"outlined"}
                  onClick={() =>
                    assignIotDeviceHandler(
                      iotDetailsObject[iotDeviceTypes?.PRIMARY][0],
                      iotDeviceTypes?.PRIMARY
                    )
                  }
                  disabled={isObjectNotEmpty(assignedIotDataPrimary)}
                  width="100%"
                />
              </Grid>
            </Fragment>
          ) : null}
        </Grid>
      </Stack>

      {/* SECONDARY IOT DEVICE */}
      <Stack>
        <Typography variant={typographyConstants?.HEADING}>
          {rentalEn?.VehicleManagement?.secondaryIotDetails}
        </Typography>

        <Grid container spacing={1}>
          {/* Search bar component */}
          <Grid item sm={12} md={4} lg={4}>
            <SearchBar
              searchPlaceholder={
                isObjectNotEmpty(assignedIotDataSecondary)
                  ? rentalEn?.VehicleManagement?.secondaryIotDetails
                  : rentalEn?.VehicleManagement?.searchIotDevice
              }
              selectedValue={selectedIot?.selectedIotSecondary}
              data={iotDetailsObject[iotDeviceTypes?.SECONDARY]}
              onSelect={(val: any) => onSelect(val, iotDeviceTypes?.SECONDARY)}
              onInputChange={(val: any) =>
                onInputChange(val, iotDeviceTypes?.SECONDARY)
              }
              searchValue={searchQuery?.secondarySearchValue}
              getSearchKey={(data: any) => data?.imeiNumber}
              isLoading={onLoad}
              disabled={
                isObjectNotEmpty(assignedIotDataSecondary) ? true : false
              }
            />
          </Grid>

          {/* Display input fields when search returns exactly one IoT device or forced to show */}
          {(searchQuery?.secondarySearchValue &&
            iotDetailsObject[iotDeviceTypes?.SECONDARY]?.length === 1) ||
          isObjectNotEmpty(assignedIotDataSecondary) ? (
            <Fragment>
              {/* Device ID input field */}
              <Grid item sm={12} md={4} lg={3}>
                <Input
                  label={`*${rentalEn?.VehicleManagement?.deviceID}`}
                  placeholder={`*${rentalEn?.VehicleManagement?.deviceID}`}
                  variant="outlined"
                  value={
                    isObjectNotEmpty(assignedIotDataSecondary)
                      ? assignedIotDataSecondary?.id ||
                        assignedIotDataSecondary?.deviceId
                      : iotDetailsObject[iotDeviceTypes?.SECONDARY][0]?.id
                  }
                  inputProps={{ maxLength: 17 }}
                  onChange={() => null}
                  disabled={true}
                  muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
                />
              </Grid>

              {/* IMEI number input field */}
              <Grid item sm={12} md={4} lg={3}>
                <Input
                  label={`*${rentalEn?.IotManagement?.PhoneNumber}`}
                  placeholder={`*${rentalEn?.IotManagement?.PhoneNumber}`}
                  variant="outlined"
                  value={
                    isObjectNotEmpty(assignedIotDataSecondary)
                      ? assignedIotDataSecondary?.phoneNumber
                      : iotDetailsObject[iotDeviceTypes?.SECONDARY][0]
                          ?.phoneNumber
                  }
                  inputProps={{ maxLength: 17 }}
                  onChange={() => null}
                  disabled={true}
                  muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
                />
              </Grid>

              {/* Assign IoT device button */}
              <Grid item sm={12} md={4} lg={2}>
                <CustomButton
                  label={
                    isObjectNotEmpty(assignedIotDataSecondary)
                      ? rentalEn?.VehicleManagement?.assigned
                      : rentalEn?.VehicleManagement?.assign
                  }
                  variant={"outlined"}
                  onClick={() =>
                    assignIotDeviceHandler(
                      iotDetailsObject[iotDeviceTypes?.SECONDARY][0],
                      iotDeviceTypes?.SECONDARY
                    )
                  }
                  disabled={isObjectNotEmpty(assignedIotDataSecondary)}
                />
              </Grid>
            </Fragment>
          ) : null}
        </Grid>
      </Stack>
    </Box>
  );
};

export default AssignIot;
