import { Box, Stack } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../../redux/store";
import VehicleStatusChangeDrawer from "../../../../../sharedComponents/templates/SideDrawer/VehicleStatusChangeDrawer";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  ChallanPaymentStatus,
  documentStatus,
  GenericObject,
  initialPaginationTable,
  LOB_TYPES,
  LobStatus,
  SIDEDRAWER_PAGINATION,
  VehicleDocumentType,
} from "../../../../constants/constants";
import {
  ChallanFields,
  DeleteDocumentDialog,
  ExpandAccordion,
  Pagination,
  PaginationModel,
} from "../../../../interfaces/vehicleInterface";
import en from "../../../../locale/rental-en.json";
import { vehicleActions } from "../../../../redux/actions";
import { getCustomerBookingList } from "../../../../redux/actions/customerManagementActions";
import {
  getPingHistory,
  linkChallan,
  unlinkChallan,
} from "../../../../redux/actions/vehicleManagement";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { BookingDetails } from "./Sections/bookingDetails";
import { ChallanDetails } from "./Sections/challanDetails";
import { IotDetails } from "./Sections/iotDetails";
import { VehicleDetailsUI } from "./Sections/vehicleDetails";
import { VehicleImages } from "./Sections/vehicleImages";
import { toastr } from "react-redux-toastr";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";

const { isAccessRightsProvided } = RbackHelper;

// destructuring of locale data
const { VehicleManagement } = en;
const { vehicleDocumentType } = VehicleManagement;

const initialFields = {
  bookingNumber: "",
};

const vehicleDocsTypeArray: GenericObject[] = [
  {
    name: VehicleDocumentType.REGISTRATION_CERTIFICATE,
    displayName: vehicleDocumentType.REGISTRATION_CERTIFICATE,
  },
  {
    name: VehicleDocumentType.PUC,
    displayName: vehicleDocumentType.PUC,
  },
  {
    name: VehicleDocumentType.FITNESS_CERTIFICATE,
    displayName: vehicleDocumentType.FITNESS_CERTIFICATE,
  },
  {
    name: VehicleDocumentType.INSURANCE,
    displayName: vehicleDocumentType.INSURANCE,
  },
  {
    name: VehicleDocumentType.OTHERS,
    displayName: vehicleDocumentType.OTHERS,
  },
];

const VehicleDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isViewVehicleBooking: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VIEW_VEHICLE_BOOKING
    ),
    isViewVehicleIotDetails: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_DETAILS_IOT
    ),
    isVehicleDetailsImages: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_DETAILS_IMAGES
    ),
    isFetchChallanKycKart: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_FETCH_CHALLANS_KYC_CART
    ),
    isVehicleLinkUnlinkChallan: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_LINK_UNLINK_CHALLAN
    ),
    isVehicleDownloadChallan: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_DOWNLOAD_CHALLAN
    ),
    isAssignIotDevice: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.ASSIGN_VEHICLE_IOT_DEVICE
    ),
    isIotPingHistory: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_IOT_PING_HISTORY
    ),

    // isViewVehicleChallans: isAccessRightsProvided(
    //   modules.VEHICLE_MANAGEMENT,
    //   vehicleManagementFunctionalities.VIEW
    // ),
  };

  const {
    vehicleDetailsLoader,
    vehicleDetailsByIdData,
    challanListLoader,
    challanData,
    getAssignedIotLoader,
    assignedIotVehicleData,
    liveChallanLoader,
    pingHistoryData,
    pingHistoryLoader,
    vehicleImagesDataLoader,
    vehicleImagesData,
    unassignIotLoader,
    vehicleLinkChallanLoader,
    vehicleDocumentsData: { documentDetails = [], pageData: docPageData = {} },
    vehicleDocumentsDataLoader,
    deleteDocLoader = false,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  const { bookingList, bookingListLoader } = useSelector(
    (state: RootState) => state.customerManagementReducer
  );

  // state to control open and close of all accordion in VMS view vehicle
  const [expand, setExpand] = useState<ExpandAccordion>({
    bookingDetails: false,
    challanDetails: false,
    iotDetails: false,
    vehicleImages: false,
  });

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  // both state is to open and close respective side drawer
  const [sideDrawerIot, setSideDrawerIot] = useState<boolean>(false);
  const [sideDrawerChallan, setSideDrawerChallan] = useState<boolean>(false);

  // state to store data of challan side drawer fields
  const [challanFields, setChallanFields] = useState<ChallanFields>({
    searchField: "",
    selectedField: initialFields,
    challanData: "",
  });

  // state to store table pagination (challan, booking, ping history)
  const [pagination, setPagination] = useState<Pagination>({
    challanPagination: { page: 1, pageSize: 10 },
    bookingPagination: { page: 0, pageSize: 10 },
    pingHistoryPagination: {
      page: 1,
      pageSize: SIDEDRAWER_PAGINATION.PAGE_SIZE,
    },
  });

  // state to handle the responsive dialog
  const [dialogData, setDialogData] = useState<GenericObject>({
    openDialog: false,
    key: "",
  });

  // state to store and handle image
  const [imagePreviewData, setImagePreviewData] = useState<GenericObject>({
    imageUrl: "",
    imgName: "",
    openPreview: false,
  });

  // state to store booking data for challan booking id search
  const [bookingListData, setBookingListData] = useState<GenericObject>({});

  // state to handle and store view vehicle documents and documend history side drawer
  const [viewDocuments, setViewDocuments] = useState<GenericObject>({
    docsFilter: {},
    filteredData: [],
    drawerFilter: {},
    drawerFilterData: [],
    openDrawer: false,
  });

  // state to handle delete document dialog
  const [deleteDocumentDialog, setDeleteDocumentDialog] =
    useState<DeleteDocumentDialog>({
      open: false,
      key: "",
    });

  useEffect(() => {
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.VIEW_VEHICLE_DETAILS));
    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
      dispatch(
        vehicleActions.clearVehicleReducerKey({ vehicleDocumentsData: {} })
      );
    };
  }, []);

  // side-effect to call vehicle details api initially
  useEffect(() => {
    if (params?.id) {
      const payload: GenericObject = {
        data: { id: params?.id },
        status: [documentStatus.ACTIVE],
      };
      dispatch(vehicleActions.getVehicleDetailsById(payload));
      getVehicleDocs(params?.id);
    }
  }, [params?.id]);

  // side effect to update booking list data for search booking id in challan details
  useEffect(() => {
    if (bookingList?.bookings?.length && sideDrawerChallan)
      setBookingListData(bookingList);
  }, [bookingList]);

  // side effect to set initial view doc filter
  useEffect(() => {
    if (isArrayNotEmpty(documentDetails)) {
      const data: GenericObject = viewDocuments.docsFilter?.name
        ? viewDocuments.docsFilter
        : vehicleDocsTypeArray[0];
      handleDocTypeFilteredData(data);
    }
  }, [documentDetails]);

  // Handle accordion expansions here
  const handleExpansion = (key: string, value: boolean) => {
    setExpand((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  // booking list API call
  const getBookingDetailsList = (page: number, pageSize: number) => {
    dispatch(
      getCustomerBookingList({
        page: page + 1,
        pageSize: pageSize,
        vehicleId: params.id,
      })
    );
  };

  // search for booking id
  const getBookingIds = (search: string) => {
    dispatch(
      getCustomerBookingList({
        search,
        vehicleId: params.id,
      })
    );
  };

  //calls on select of option in search of challan side drawer
  const onSelect = (value: any) => {
    setChallanFields((prev: ChallanFields) => ({
      ...prev,
      selectedField: value,
    }));
  };

  // get search key from api response
  const getSearchKey = (data: any) => {
    return data?.bookingNumber;
  };

  // calls when side drawer search box changes value
  const onInputChange = (newInputValue: string) => {
    if (!newInputValue || newInputValue === "undefined")
      setChallanFields((prev: ChallanFields) => ({ ...prev, searchField: "" }));
    else {
      setChallanFields((prev: ChallanFields) => ({
        ...prev,
        searchField: newInputValue,
      }));

      debounceLoadData(newInputValue);
    }
  };

  // get booking id with debounce
  const debounceLoadData = useCallback(
    debounce((searchValue: string) => {
      if (searchValue?.length >= 3) getBookingIds(searchValue);
    }, 1000),
    []
  );

  // challan list API call
  const getChallanList = (page: number, pageSize: number) => {
    let payload = {
      challanListPayload: {
        vehicleIds: JSON.stringify([params.id]),
        page: page ? page : page + 1,
        pageSize,
      },
    };
    dispatch(vehicleActions.getChallansList(payload));
  };

  // call to kyc cart api to fetch updated challan
  const getLiveChallan = () => {
    let payload = {
      vehicleId: params.id,
      page: initialPaginationTable.page,
      pageSize: initialPaginationTable.pageSize,
    };
    dispatch(vehicleActions.getLiveChallansList(payload));
  };

  // vehicle images list API call
  const getVehicleImages = () => {
    let payload: GenericObject = {
      imageRefId: vehicleDetailsByIdData.vehicleImagesRefId,
    };
    dispatch(vehicleActions.getVehicleImages(payload));
  };

  // iot details list API call
  const getIotDetails = () => {
    dispatch(
      vehicleActions.getAssignedIotDevices({
        vehicleId: params.id,
      })
    );
  };

  // api call to unassign iot device
  const unassignIotDevice = (data: any) => {
    if (!functionalitiesAccess.isAssignIotDevice) {
      return;
    } else {
      let payload = {
        unassignPayload: {
          data: {
            vehicleId: data?.vehicleId,
            deviceType: data?.deviceType,
            deviceId: data?.deviceId,
          },
        },
        getIotPayload: {
          vehicleId: params.id,
        },
      };
      dispatch(vehicleActions.unassignIotDevice(payload));
    }
  };

  // Handles pagination of bookings and challan data table
  const handlePagination = (key: string, paginationModel: PaginationModel) => {
    const { page, pageSize } = paginationModel;
    setPagination((prev: Pagination) => ({
      ...prev,
      [key]: { page: page, pageSize: pageSize },
    }));

    if (key === "challanPagination") getChallanList(page, pageSize);
    if (key === "bookingPagination") getBookingDetailsList(page, pageSize);
  };

  //call to ping history api
  const getPingHistoryData = (
    deviceId: string,
    page: number,
    pageSize: number,
    showMoreClick = false
  ) => {
    let payload = {
      apiBody: {
        deviceIds: [deviceId],
        page,
        pageSize,
      },
      showMoreClick,
    };
    dispatch(getPingHistory(payload));
  };

  // on click show more call to get ping history api
  const onClickShowMore = () => {
    const deviceId = pingHistoryData?.filter?.deviceIds[0];
    const page = pagination.pingHistoryPagination.page;
    const pageSize =
      pagination.pingHistoryPagination.pageSize +
      SIDEDRAWER_PAGINATION.PAGE_SIZE;
    const showMoreClick: boolean = true;
    getPingHistoryData(deviceId, page, pageSize, showMoreClick);

    setPagination((prev: Pagination) => ({
      ...prev,
      pingHistoryPagination: {
        page: pagination.pingHistoryPagination.page,
        pageSize:
          pagination.pingHistoryPagination.pageSize +
          SIDEDRAWER_PAGINATION.PAGE_SIZE,
      },
    }));
  };

  //toggle ping history drawer
  const toggleDrawer = (data: any) => {
    if (!functionalitiesAccess.isIotPingHistory) {
      return;
    } else {
      if (!sideDrawerIot) {
        getPingHistoryData(
          data?.deviceId,
          pagination.pingHistoryPagination.page,
          pagination.pingHistoryPagination.pageSize
        );
      } else {
        setPagination((prev: Pagination) => ({
          ...prev,
          pingHistoryPagination: {
            page: 1,
            pageSize: SIDEDRAWER_PAGINATION.PAGE_SIZE,
          },
        }));
      }
      setSideDrawerIot(!sideDrawerIot);
    }
  };

  //toggle challan list drawer
  const toggleDrawerChallan = (data: any) => {
    if (sideDrawerChallan) {
      setChallanFields((prev: ChallanFields) => ({
        ...prev,
        searchField: "",
        challanData: "",
        selectedField: initialFields,
      }));
      setBookingListData({});
    } else {
      setChallanFields((prev: ChallanFields) => ({
        ...prev,
        challanData: data,
      }));
    }
    setSideDrawerChallan(!sideDrawerChallan);
  };

  //api call to link challan to particular booking
  const linkChallanToBooking = (bookingId: string, challanId: string) => {
    let payload = {
      linkPayload: {
        data: {
          bookingId,
          challanId,
          lob: LOB_TYPES.LTR,
        },
      },
      getChallanPayload: {
        challanListPayload: {
          vehicleIds: JSON.stringify([params.id]),
          page: pagination?.challanPagination?.page,
          pageSize: pagination?.challanPagination?.pageSize,
        },
      },
      // handles close side drawer in challan linking on api success.
      successCallback: toggleDrawerChallan,
    };
    dispatch(linkChallan(payload));
  };

  //api call to unlink challan from particular booking
  const unlinkVehicleChallan = (bookingId: string, challanId: string) => {
    let payload = {
      unlinkPayload: {
        data: {
          bookingId,
          challanId,
        },
      },
      getChallanPayload: {
        challanListPayload: {
          vehicleIds: JSON.stringify([params.id]),
          page: pagination?.challanPagination?.page,
          pageSize: pagination?.challanPagination?.pageSize,
        },
      },
    };
    dispatch(unlinkChallan(payload));
  };

  // handle challan linking in challan details
  const handleChallanLinking = (data: any) => {
    if (!functionalitiesAccess.isVehicleLinkUnlinkChallan) {
      return;
    } else {
      if (data?.status === ChallanPaymentStatus.LINKED) {
        unlinkVehicleChallan(data?.associationDetails?.bookingId, data?.id);
      } else if (data?.status === ChallanPaymentStatus.UNLINKED) {
        toggleDrawerChallan(data);
      }
    }
  };

  // open side drawer vehicle status change
  const openSideDrawer = () => setOpenDrawer(true);

  // open image preview modal in vehicle images accordion
  const openModal = (url: string, name: string) => {
    setImagePreviewData((prev: any) => ({
      ...prev,
      imageUrl: url,
      imgName: name,
      openPreview: true,
    }));
  };

  // close image preview modal in vehicle images accordion
  const closeModal = () => {
    setImagePreviewData((prev: any) => ({
      ...prev,
      imageUrl: "",
      imgName: "",
      openPreview: false,
    }));
  };

  // Edit button click handler
  const editButtonClickHandler = () => {
    navigate(`${routesConstants.ADD_EDIT_VEHICLE}/${params?.id}`);
  };

  // Vehicle status update success handler
  const handleVehicleStatusUpdateSuccess = () => {
    const payload: GenericObject = {
      data: { id: params?.id },
    };
    dispatch(vehicleActions.getVehicleDetailsById(payload));
    setOpenDrawer(false);
  };

  // Handle dialog's yes button click
  const yesHandler = () => {
    switch (true) {
      case dialogData?.key === "deleteVehicle":
        // Construct payload
        const deletePayload: GenericObject = {
          data: { data: { ids: [params?.id] } },
          navigate: navigate,
        };
        // Delete vehicle API call
        dispatch(vehicleActions.deleteVehicle(deletePayload));
        setDialogData({ openDialog: false, key: "" }); // Close the dialog box
        break;

      case dialogData?.key === "changeVehicleStatus":
        // Construct payload
        const changeStatusPayload: GenericObject = {};
        changeStatusPayload.data = {
          data: {
            ids: [vehicleDetailsByIdData?.id],
            status: LobStatus?.AVAILABLE,
          },
        };
        changeStatusPayload.apiSuccessFunction =
          handleVehicleStatusUpdateSuccess;
        // Make api call
        dispatch(vehicleActions.updateVehicleStatus(changeStatusPayload));
        setDialogData({ openDialog: false, key: "" });
        break;

      default:
        setDialogData({ openDialog: false, key: "" });
        break;
    }
  };

  // handle download data of challans above 1000
  const downloadChallans = () => {
    let payload = {
      challanListPayload: {
        vehicleIds: JSON.stringify([params.id]),
        page: initialPaginationTable.page,
        pageSize: 1000,
      },
      downloadChallans: true,
    };
    dispatch(vehicleActions.getChallansList(payload));
  };

  // get vehicle documentation
  const getVehicleDocs = (vehicleId: string) => {
    const getDocPayload = {
      vehicleIds: JSON.stringify([vehicleId]),
    };
    dispatch(vehicleActions.getVehicleDocuments(getDocPayload));
  };

  // handle filtered data for docs type
  const handleDocTypeFilteredData = (type: GenericObject) => {
    const data: GenericObject[] = documentDetails?.filter(
      (data: GenericObject) => {
        if (
          data.type === type.name &&
          data.status === documentStatus.ACTIVE &&
          !data.isExpired
        )
          return data;
      }
    );
    setViewDocuments((prev: GenericObject) => ({
      ...prev,
      filteredData: data,
      docsFilter: { ...type },
    }));
  };

  // handle filtered data for view document side drawer docs type
  const handleFilterDrawerType = (type: GenericObject) => {
    const data: GenericObject[] = documentDetails?.filter(
      (data: GenericObject) => {
        if (data.type === type.name && data?.status !== documentStatus.DELETED)
          return data;
      }
    );
    setViewDocuments((prev: GenericObject) => ({
      ...prev,
      drawerFilterData: data,
      drawerFilter: { ...type },
    }));
  };

  // toggle document history side drawer
  const toggleDocumentHistoryDrawer = () => {
    const data: GenericObject = vehicleDocsTypeArray[0];

    // select first type of document  on drawer open
    if (!viewDocuments.openDrawer) {
      handleFilterDrawerType(data);
    }
    setViewDocuments((prev: GenericObject) => ({
      ...prev,
      openDrawer: !viewDocuments.openDrawer,
    }));
  };

  // delete vehicle document
  const deleteVehicleDocument = (key: string) => {
    const payload: any = {
      deleteBody: {
        data: {
          data: {
            documentIds: [key],
          },
        },
      },
      getDocumentParams: {
        vehicleIds: JSON.stringify([params?.id]),
      },
    };
    dispatch(vehicleActions.deleteVehicleDocuments(payload));
  };

  // download vehicle document
  const downloadVehicleDocument = (docId: string) => {
    const payload: GenericObject = {
      params: `${params.id}/${docId}`,
    };
    dispatch(vehicleActions.downloadVehicleDocuments(payload));
  };

  // open vehicle document delete dialog
  const openDeleteDialogDocument = (docId: string) => {
    setDeleteDocumentDialog((prev: DeleteDocumentDialog) => ({
      ...prev,
      open: true,
      key: docId,
    }));
  };

  // close vehicle document delete dialog
  const closeDeleteDialogDocument = () => {
    setDeleteDocumentDialog((prev: DeleteDocumentDialog) => ({
      ...prev,
      open: false,
      key: "",
    }));
  };

  // yes handler of delete document dialog box
  const deleteDocumentHandleYes = () => {
    deleteVehicleDocument(deleteDocumentDialog?.key);
    setDeleteDocumentDialog((prev: DeleteDocumentDialog) => ({
      ...prev,
      open: false,
      key: "",
    }));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <VehicleDetailsUI
          vehicleDetails={vehicleDetailsByIdData}
          vehicleDetailsLoader={vehicleDetailsLoader}
          openSideDrawer={openSideDrawer}
          editButtonClickHandler={editButtonClickHandler}
          dialogData={dialogData}
          setDialogData={setDialogData}
          yesHandler={yesHandler}
          vehicleDocumentsDataLoader={vehicleDocumentsDataLoader}
          docPageData={docPageData}
          documentDetails={documentDetails}
          viewDocuments={viewDocuments}
          handleDocTypeFilteredData={handleDocTypeFilteredData}
          vehicleDocsTypeArray={vehicleDocsTypeArray}
          handleFilterDrawerType={handleFilterDrawerType}
          toggleDocumentHistoryDrawer={toggleDocumentHistoryDrawer}
          downloadVehicleDocument={downloadVehicleDocument}
          openDeleteDialogDocument={openDeleteDialogDocument}
          closeDeleteDialogDocument={closeDeleteDialogDocument}
          deleteDocumentHandleYes={deleteDocumentHandleYes}
          deleteDocumentDialog={deleteDocumentDialog}
          deleteDocLoader={deleteDocLoader}
        />
        <Stack gap={"20px"}>
          {functionalitiesAccess.isViewVehicleBooking ? (
            <BookingDetails
              expand={expand.bookingDetails}
              onclick={() => {
                handleExpansion("bookingDetails", !expand?.bookingDetails);
                if (!expand.bookingDetails) {
                  getBookingDetailsList(0, 10);
                }
              }}
              handlePaginationModel={(val: PaginationModel) => {
                handlePagination("bookingPagination", val);
              }}
              bookingDetails={bookingList}
              loading={bookingListLoader}
              pagination={pagination}
            />
          ) : null}

          <ChallanDetails
            expand={expand.challanDetails}
            onclick={() => {
              handleExpansion("challanDetails", !expand?.challanDetails);
              if (!expand.challanDetails) getChallanList(1, 10);
            }}
            loading={challanListLoader}
            vehicleLinkChallanLoader={vehicleLinkChallanLoader}
            challanData={challanData}
            handlePaginationModel={(val: PaginationModel) => {
              handlePagination("challanPagination", val);
            }}
            pagination={pagination}
            getLiveChallan={getLiveChallan}
            liveChallanLoader={liveChallanLoader}
            handleChallanLinking={handleChallanLinking}
            toggleDrawerChallan={toggleDrawerChallan}
            sideDrawerChallan={sideDrawerChallan}
            getSearchKey={getSearchKey}
            onSelect={onSelect}
            getBookingIds={getBookingIds}
            bookingList={bookingList}
            onInputChange={onInputChange}
            challanFields={challanFields}
            bookingListLoader={bookingListLoader}
            linkChallanToBooking={linkChallanToBooking}
            downloadChallans={downloadChallans}
            vehicleDetailsByIdData={vehicleDetailsByIdData}
          />

          {functionalitiesAccess.isViewVehicleIotDetails ? (
            <IotDetails
              expand={expand.iotDetails}
              onclick={() => {
                handleExpansion("iotDetails", !expand?.iotDetails);
                if (!expand.iotDetails) getIotDetails();
              }}
              iotData={assignedIotVehicleData}
              loading={unassignIotLoader}
              unassignIotDevice={unassignIotDevice}
              pingHistoryLoader={pingHistoryLoader}
              pingHistoryData={pingHistoryData}
              toggleDrawer={toggleDrawer}
              sideDrawerIot={sideDrawerIot}
              onClickShowMore={onClickShowMore}
              pagination={pagination}
            />
          ) : null}

          {functionalitiesAccess.isVehicleDetailsImages ? (
            <VehicleImages
              expand={expand.vehicleImages}
              onclick={() => {
                handleExpansion("vehicleImages", !expand?.vehicleImages);
                if (!expand.vehicleImages) getVehicleImages();
              }}
              vehicleDetails={vehicleDetailsByIdData}
              loading={vehicleImagesDataLoader}
              imagesData={vehicleImagesData}
              closeModal={closeModal}
              openModal={openModal}
              imagePreviewData={imagePreviewData}
            />
          ) : (
            <></>
          )}

          <VehicleStatusChangeDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            toggleDrawer={(newOpen: boolean) => () => setOpenDrawer(newOpen)}
            entireRowData={vehicleDetailsByIdData}
            handleVehicleStatusUpdateSuccess={handleVehicleStatusUpdateSuccess}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default VehicleDetails;
