export enum colors {
  white = "#FFFFFF",
  black = "#000000",
  light_black = "rgb(36, 43, 53)",
  THEME_BLUE = "#00353d",
  light_gray = "#F2F2F2",
  dark_gray = "rgb(114, 114, 114)",
  bluish_gray = "rgb(61, 56, 85)",
  heading = "rgb(123, 130, 137)",
  gray = "#D7DADF",
  error = "#FF0000",
  heading_black = "rgb(41, 40, 37)",
  LIGHT_BLUE = "rgb(43, 199, 201)",
  light_green = "rgb(22, 188, 118)",
  primary = "#2BC7C9",
  THEME_Yellow = "#FCB52F",
  light = "#ebedef",
  heading_text = "#006063",
  heading_main_text = "#020C17",
  card_text = "#00353D",
  card_body_text = "#707070",
  card_body_text_second = "#021325",
  edit_icon_color = "#009A3D",
  card_border_color = "#DDDDDD80",
  card_box_shadow = "#2BC7C926",
  border_cell = "#DDDDDD",
  task_background_color = "#FBFBFB",
  chip_background_colorG = "#009B6614",
  chip_background_cgray = "#01328714",
  chip_text_colorG = "#009B66",
  chip_text_cgray = "#013287",
  THEME_BLUEX = "#09CACC",
  text_black = "#3C3C43",
  text_gray = "#3C3C43CC",
  text_secondary_gray = "#636369",
  // freedox
  text_quatenary_black = "#B1B1B4",
  primary_dark_blue = "#053C3D",
  primary_aqua_blue = "#1BD8DA",
  tertiary_black = "#8A8A8E",
  secondary_aqua_blue = "#1BD8DA52",
  primary_green = "#1BC47D14",
  secondary_green = "#1BC47D",
  primary_warning = "#FF7A00",
  primary_danger = "#FF3B30",
  text_light_gray = "#B1B1B4",
  primary_dark_blue2 = "#098082",
  secondary_green_dark = "#088651",
}
