import { theme } from "../../../../../themes";

export const StyleObject: any = {
  chipStyle: {
    background: "linear-gradient(90deg, #01353D 0%, #2BC7C9 60%)",
    "&:hover": {
      background: "linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      color: "white",
      border: "none",
    },
    "&:active": {
      background: " linear-gradient(90deg, #2BC7C9 0%, #01353D 45%)",
      boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
      color: "white",
    },
  },
  noRowsOverlay_box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    p: 5,
  },
  inventoryDetails_topSection_topBox: {
    width: 1,
    my: 2,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  customButtonStyle: {
    padding: "3px 20px",
  },
  inventoryDetails_filterSection_topBox: {
    width: 1,
    my: 2,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  collapsibleSectionWrapper: {
    width: 1,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px 0px #0000000D",
  },
  inventoryDetails_ongoingSection_box1: {
    width: 1,
    padding: "16px 24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #0000000D",
    backgroundColor: "#FFFFFF",
  },
  avatarStyle: {
    bgcolor: "#e9e9e9",
    border: "1px solid black",
    width: 50,
    height: 50,
  },
  collapseStyle: {
    padding: "16px 24px",
    transformOrigin: "0 0 0",
    transition: "height 1s ease-out, opacity 1s ease-out",
  },
  inventoryDetails_upcomingSection_topBox: {
    width: 1,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e7f0f0",
  },
  inventoryDetails_upcomingSection_box1: {
    width: 1,
    padding: "16px 24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #0000000D",
    backgroundColor: "#FFFFFF",
  },
  inventoryDetails_blockedVehicleSection_topBox: {
    width: 1,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e7f0f0",
  },
  inventoryDetails_blockedVehicleSection_box1: {
    width: 1,
    padding: "16px 24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #0000000D",
    backgroundColor: "#FFFFFF",
  },
  cursorPointerStyle: {
    cursor: "pointer",
  },
  dataGridStyle: {
    maxWidth: "100%",
    "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  viewMoreInVehicleManagementHyperLinkStyle: {
    cursor: "pointer",
    textAlign: "center",
    background: "-webkit-linear-gradient(left, #29c7c9, #044148)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  nbcTextStyle: {
    background: "-webkit-linear-gradient(left, #29c7c9, #044148)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
};
