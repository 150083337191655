import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { handleMessage } from "../../../utils/helper";
import rentalEn from "../../locale/rental-en.json";
import { categorizeSlots } from "../../utils/helper";
import * as actionTypes from "../actions/actionTypes";
import manualBookingService from "../services/manualBooking";
import { CustomerStatus } from "../../constants/constants";

// search if the user exists or not
function* searchCustomer(action: any): any {
  try {
    const response = yield call(
      manualBookingService.searchCustomer,
      action.payload
    );

    const userDetails = response?.data?.data.searchResult;
    let actionPayload: any;
    if (
      (response.status === 200 || response.status === 201) &&
      userDetails.length > 0
    ) {
      if (userDetails[0]?.status === CustomerStatus.BLOCKED) {
        toastr.error("", rentalEn?.errorMessages?.customerBlocked);
        actionPayload = {
          checkUserLoader: false,
          userDetails: "",
          checkIsUserExist: false,
          showUserData: false,
        };
      } else {
        actionPayload = {
          checkUserLoader: false,
          userDetails: userDetails,
          checkIsUserExist: true,
          showUserData: true,
        };
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS
            .GET_CUSTOMER_PROFILE_REQUESTED,
          payload: { customerId: userDetails?.[0]?.id },
        });
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS
            .GET_CUSTOMER_ADDRESS_LIST_REQUESTED,
          payload: { customerId: userDetails?.[0]?.id },
        });
      }

      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_SUCCESS,
        payload: {
          ...actionPayload,
        },
      });
    } else {
      // toastr.error("", "User Not Registered");
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_FAILED,
      });
    }
  } catch (ex) {
    return ex;
  }
}

// get all available  model list
function* getAvailableModels(action: any): any {
  const { isCallSlotPauseAPI = true } = action.payload;
  try {
    const response = yield call(
      manualBookingService.getAvailableModels,
      action.payload.payload
    );

    const modelLength = response?.data?.data?.availableModels?.length || 0;

    if (response.status === 200 || response.status === 201) {
      if (modelLength > 0) {
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_SUCCESS,
          payload: response?.data?.data,
        });

        const currentDate = new Date();
        let payloadGetSlotPause = {
          startDate: currentDate.setDate(currentDate.getDate() - 30),
          endDate: currentDate.setDate(currentDate.getDate() + 180),
          branchName: action?.payload?.payload?.branches[0],
        };
        if (isCallSlotPauseAPI) {
          yield put({
            type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_REQUESTED,
            payload: payloadGetSlotPause,
          });
        }
      } else {
        action?.payload?.showError &&
          toastr.info("", rentalEn.errorMessages.noModelAvailable);
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_FAILED,
        });
      }
    } else {
      action?.payload?.showError && toastr.info("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_FAILED,
    });
  }
}

// get details of model selected
function* getModelDetails(action: any): any {
  try {
    const response = yield call(
      manualBookingService.getModelDetails,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_FAILED,
    });
  }
}

// calculate total charges of model and package selected
function* calculateCharges(action: any): any {
  try {
    const response = yield call(
      manualBookingService.calculateCharges,
      action.payload.payload
    );
    if (response.status === 200 || response.status == 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_SUCCESS,
        payload: response?.data?.data,
      });

      //call in case : coupon was successful
      if (action?.payload?.couponAppliedSucess) {
        action?.payload?.couponAppliedSucess();
      }
    } else {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
    return response;
  } catch (error: any) {
    // toastr.error("", error);
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_FAILED,
    });
  }
}

// get slot pause list on which days warehouse is closed
function* getSlotPause(action: any): any {
  try {
    const response = yield call(
      manualBookingService.getSlotPause,
      action.payload
    );

    if (response.status === 200 || response.status == 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (error: any) {
    toastr.error("", error);
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_FAILED,
    });
  }
}

// Add The User
function* registerUser(action: any): any {
  try {
    const response = yield call(
      manualBookingService.registerUser,
      action.payload
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_FAILED,
    });
  }
}

// Verify Otp
function* verifyOTP(action: any): any {
  try {
    const response = yield call(manualBookingService.verifyOTP, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_FAILED,
    });
  }
}

// Resend OTP
function* resendOTP(action: any): any {
  try {
    const response = yield call(manualBookingService.resendOTP, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_FAILED,
    });
  }
}

// Get Customer Profile
function* getCustomerProfile(action: any): any {
  try {
    const response = yield call(
      manualBookingService.getCustomerProfile,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_FAILED,
    });
  }
}

// Get Delivery Slots
function* getDeliverySlots(action: any): any {
  try {
    const response = yield call(
      manualBookingService.getdeliverySlots,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      const categorizedSlots = categorizeSlots(response?.data?.data?.slots);
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_SUCCESS,
        payload: categorizedSlots,
      });
    } else {
      // toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_FAILED,
    });
  }
}

// Add Customer address Home Delivery
function* addCustomerAddress(action: any): any {
  try {
    const response = yield call(
      manualBookingService.addCustomerAddress,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_SUCCESS,
        payload: response?.data?.data,
      });
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS
          .GET_CUSTOMER_ADDRESS_LIST_REQUESTED,
        payload: { customerId: response?.data?.data?.customerId },
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_FAILED,
    });
  }
}

// Get Customer Address List
function* getCustomerAddressList(action: any): any {
  try {
    const response = yield call(
      manualBookingService.getCustomerAddressList,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS
          .GET_CUSTOMER_ADDRESS_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS
          .GET_CUSTOMER_ADDRESS_LIST_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_ADDRESS_LIST_FAILED,
    });
  }
}

// create Booking
function* createManualBooking(action: any): any {
  try {
    const {
      createBookingPayload,
      paymentLinkGenerationPayload,
      sucessCallback,
      calculateChargePayload,
      lastCalculatedCharge,
      amount,
    } = action?.payload;

    const calculateCharge = yield calculateCharges({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_REQUESTED,
      payload: {
        payload: {
          data: { ...calculateChargePayload },
        },
      },
    });

    const { status, data: calculateChargeData } = calculateCharge;

    const currentCalculateCharge =
      calculateChargeData?.data?.totalAmount -
      calculateChargeData?.data?.totalDiscount;

    if (
      (status === 200 || status === 201) &&
      lastCalculatedCharge === currentCalculateCharge
    ) {
      const response = yield call(
        manualBookingService.createBooking,
        createBookingPayload
      );

      const payload = {
        data: {
          ...paymentLinkGenerationPayload,
          orderId: response?.data?.data?.orderDetails?.id,
        },
        sucessCallback,
      };

      if (response.status === 200 || response.status === 201) {
        toastr.success("", handleMessage(response));
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS,
          payload: response?.data?.data,
        });

        if (amount !== 0) {
          yield put({
            type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED,
            payload,
          });
        } else {
          sucessCallback && sucessCallback();
        }
      } else {
        toastr.error("", handleMessage(response));
        yield put({
          type: actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_FAILED,
        });
      }
    } else if (lastCalculatedCharge !== currentCalculateCharge) {
      toastr.warning("", rentalEn.errorMessages.priceBreakupPleaseReview);
      yield put({
        type: actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_FAILED,
    });
  }
}

function* newManualBookingSaga() {
  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.SEARCH_CUSTOMER_REQUESTED,
    searchCustomer
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_AVAILABLE_MODELS_REQUESTED,
    getAvailableModels
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.CALCULATE_CHARGES_REQUESTED,
    calculateCharges
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_SLOT_PAUSED_REQUESTED,
    getSlotPause
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_MODEL_DETAILS_REQUESTED,
    getModelDetails
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.REGISTER_USER_REQUESTED,
    registerUser
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.VERIFY_OTP_REQUESTED,
    verifyOTP
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.RESEND_OTP_RENTAL_REQUESTED,
    resendOTP
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_PROFILE_REQUESTED,
    getCustomerProfile
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_DELIVERY_SLOTS_REQUESTED,
    getDeliverySlots
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.ADD_CUSTOMER_ADDRESS_REQUESTED,
    addCustomerAddress
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.GET_CUSTOMER_ADDRESS_LIST_REQUESTED,
    getCustomerAddressList
  );

  yield takeLatest(
    actionTypes.MANUAL_BOOKING_ACTIONS.CREATE_BOOKING_REQUESTED,
    createManualBooking
  );
}

export default newManualBookingSaga;
