import { Box, Divider, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BLOCKED_REASON,
  blockedReasonArray,
  GenericObject,
  GenericType,
  LobStatus,
  typographyConstants,
} from "../../../rentals-src/constants/constants";
import { LocationIcon } from "../../../rentals-src/constants/exportImages";
import rentalEn from "../../../rentals-src/locale/rental-en.json";
import { vehicleActions } from "../../../rentals-src/redux/actions";
import { flattenObject } from "../../../rentals-src/utils/helper";
import { colors } from "../../../themes/colors";
import { isArrayNotEmpty } from "../../../utils/helper";
import { CustomButton } from "../../atoms/Buttons/CustomButtons";
import CustomSelect from "../../atoms/InputFields/InputSelect";
import SideDrawer from "./SideDrawer";

const { VehicleManagement, global } = rentalEn;

// Constants for mapping card content
const array1: string[] = [
  global?.model,
  global?.vehicleNumber,
  global?.city,
  global?.hub,
];
const array2: string[] = [
  "modelDisplayName",
  "vehicleDetails.registration",
  "cityDisplayName",
  "branchDisplayName",
];

const VehicleStatusChangeDrawer = (props: any) => {
  const dispatch = useDispatch();

  // Destructuring props with default values
  const {
    toggleDrawer, // Toggles the drawer open or closed // i.e., toggleDrawer={(newOpen: boolean) => () => setOpenDrawer(newOpen)}
    openDrawer = false, // State variable for drawer open/close
    drawerHeading = VehicleManagement?.changeVehicleStatus, // Heading of the drawer
    cardHeading = VehicleManagement?.vehicleDetails, // Heading of the card inside the drawer
    bottomButtonLabel = VehicleManagement?.confirmStatusChange, // Label for the bottom button
    width = "100%", // Width of the dropdown
    entireRowData, // Selected row data
    handleVehicleStatusUpdateSuccess, // Callback function on API success
    loading = false, // Loader for button
  } = props;

  // State variables
  const [cardContentArray, setCardContentArray] = useState<GenericObject[]>([]);
  const [blockReason, setBlockReason] = useState<GenericType>({
    name: "",
    displayName: "",
  });
  const [modifiedBlockedReasonArray, setModifiedBlockedReasonArray] = useState<
    GenericObject[]
  >([]);

  // Function to flatten and map object data
  const getCardContentArrayData = (
    obj: GenericObject,
    array1: string[],
    array2: string[]
  ): GenericObject[] => {
    const result: GenericObject[] = [];
    const flattenedObj: GenericObject = flattenObject(obj);

    array1.forEach((key: string, index: number) => {
      const value: string = flattenedObj[array2[index]] ?? global?.NA;
      const newObj: GenericObject = {};
      newObj[key] = value;
      result.push(newObj);
    });

    return result;
  };

  // Effect to update card content array on data change
  useEffect(() => {
    // Perform any actions needed on component mount
    let tempCardContentArray: GenericObject[] = getCardContentArrayData(
      entireRowData,
      array1,
      array2
    );
    setCardContentArray([...tempCardContentArray]);
    // Modify blockedReasonArray
    if (entireRowData?.lobStatus === LobStatus?.ENGAGED) {
      let tempArray: GenericObject[] = blockedReasonArray?.filter(
        (item: any) => item?.name === BLOCKED_REASON?.UNDER_RECOVERY
      );
      setModifiedBlockedReasonArray([...tempArray]);
    } else if (entireRowData?.lobStatus === LobStatus?.AVAILABLE) {
      let tempArray: GenericObject[] = blockedReasonArray?.filter(
        (item: any) => item?.name !== BLOCKED_REASON?.UNDER_RECOVERY
      );
      setModifiedBlockedReasonArray([...tempArray]);
    } else {
      setModifiedBlockedReasonArray([...blockedReasonArray]);
    }
    //
    return () => {
      // Cleanup function
      setBlockReason({ name: "", displayName: "" });
      setCardContentArray([]);
    };
  }, [JSON.stringify(entireRowData), openDrawer]);

  // Handler for dropdown change
  const handleDropdownChange = (value: any) => setBlockReason({ ...value });

  // Handler for vehicle status change
  const vehicleStatusChangeHandler = () => {
    // Construct payload
    const payload: GenericObject = {};
    payload.data = {
      data: {
        ids: [entireRowData?.id],
        status: LobStatus?.BLOCKED, // If BLOCKED then provide blockedReason
        blockedReason: blockReason?.name,
      },
    };
    payload.apiSuccessFunction = handleVehicleStatusUpdateSuccess;
    // Dispatch the action to change the vehicle status
    dispatch(vehicleActions.updateVehicleStatus(payload));
  };

  return (
    <SideDrawer
      open={openDrawer}
      heading={drawerHeading}
      toggleDrawer={toggleDrawer(false)}
      disablePadding={true}
      headerPadding={"13px 15px"}
      headingVariant={typographyConstants?.HEADINGXL}
      width={400}
      renderUI={
        <Stack sx={StyleObj?.topStack} role="presentation">
          <Stack spacing={3} width={1}>
            {/* Main content inside the drawer */}
            <Stack sx={StyleObj?.mainContent_topBox}>
              {/* Blocked reason dropdown */}
              <CustomSelect
                required
                icon={LocationIcon}
                placeholder={VehicleManagement?.selectBlockingReason}
                value={blockReason?.displayName || ""}
                choice={modifiedBlockedReasonArray}
                defaultKey={global?.displayName}
                handleChange={(val: any) => handleDropdownChange(val)}
                width={width}
              />

              {/* Vehicle details card */}
              <Box sx={StyleObj?.vehicleDetailsCard_topBox}>
                <Box sx={StyleObj?.vehicleDetailsCard_box2}>
                  <Typography variant={typographyConstants?.HEADING}>
                    {cardHeading}
                  </Typography>
                </Box>
                <Divider />

                {/* Mapping through cardContentArray to display vehicle details */}
                <Box sx={{ px: 2 }}>
                  {/* {cardContentArray?.map((item: any, index: number) => { */}
                  {isArrayNotEmpty(cardContentArray)
                    ? cardContentArray?.map((item: any, index: number) => {
                        // Extract the key and value from each item object
                        const key = Object.keys(item)[0];
                        const value = item[key];

                        return (
                          <Fragment key={index}>
                            <Stack sx={StyleObj?.cardContent_topStack}>
                              <Typography
                                variant={typographyConstants?.SUBHEADING}
                              >
                                {key}
                              </Typography>
                              <Typography
                                variant={typographyConstants?.HEADING}
                              >
                                {value}
                              </Typography>
                            </Stack>
                            <Divider />
                          </Fragment>
                        );
                      })
                    : null}
                </Box>
              </Box>
            </Stack>
          </Stack>

          {/* Bottom button for confirming status change */}
          <CustomButton
            label={bottomButtonLabel}
            variant="outlined"
            sx={StyleObj?.customButtonStyle}
            onClick={vehicleStatusChangeHandler}
            disabled={!blockReason?.name} // Button disabled if dropdownValue is empty
            loading={loading}
            wrapperStyles={{ width: "100%" }}
          />
        </Stack>
      }
    />
  );
};

export default VehicleStatusChangeDrawer;

// Style (css) object for 'sx' prop of the MUi
const StyleObj: GenericObject = {
  topStack: {
    justifyContent: "space-between",
    alignItems: "center",
    height: 1, // Full height of the drawer
    padding: "12px 10px",
  },
  mainContent_topBox: {
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
    padding: "12px 10px",
  },
  vehicleDetailsCard_topBox: {
    width: "100%",
    borderRadius: 1,
    border: `1px solid ${colors?.gray_cccccc}`,
    boxShadow: 1,
  },
  vehicleDetailsCard_box2: {
    p: 2,
    borderRadius: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: colors?.grayE6,
    backgroundColor: colors?.grayE4,
  },
  cardContent_topStack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customButtonStyle: { width: "100%", alignSelf: "center" },
};
