import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import CustomMultiSelect from "../../../../../sharedComponents/atoms/InputFields/CustomMultiSelect";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { CustomResetIcon } from "../../../../../sharedComponents/atoms/reset";
import { colors } from "../../../../../themes/colors";
import { BREADCRUMBS_RENTALS } from "../../../../constants/activeModule";
import {
  BookingEndSlotOptions,
  BusinessType,
  GenericObject,
  cityStatus,
  typographyConstants,
} from "../../../../constants/constants";
import { CityInputIcon, StatusIcon } from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { operationActions, rentalActions } from "../../../../redux/actions";
import {
  getAllStates,
  updateBreadcrumbs,
} from "../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../utils/RoutesConstants";
import {
  checkLeadingZero,
  isValidDecimalNumber,
  toPascalCase,
  validateOnly,
} from "../../../../utils/helper";
import { checkWhiteSpace } from "../../../../utils/regex";
import { OperatorConfig } from "./operatorConfig";
import SendAggrementModal from "./sendAggrementModal";
import WrongUrl from "../../../../../screens/redirectionPages/wrongurl";
import PreviewDigitalAgreement from "../../approvedLeads/section/PreviewDigitalAgreement";

const BusinessTypeOptions = Object?.values(BusinessType)?.map(
  (businessType: any) => {
    if (
      businessType === BusinessType.LLP ||
      businessType === BusinessType.NGO
    ) {
      return {
        name: businessType,
        displayName: businessType,
      };
    }
    return {
      name: businessType,
      displayName: toPascalCase(businessType.replace(/_/g, " ")),
    };
  }
);

enum statusCheck {
  VERIFIED = "VERIFIED",
  PENDING = "PENDING",
  UNVERIFIED = "UNVERIFIED",
}

interface InitalState {
  businessType: any;
  share: string;
  slots: any;
  operationalCities: any[];
  operatorConfig: GenericObject;
}

const initialState: InitalState = {
  businessType: {
    name: "",
    displayName: "",
  },
  share: "",
  slots: {
    name: "",
    displayName: "",
  },
  operationalCities: [],
  operatorConfig: {},
};

const errorInitialState = {
  businessType: "",
  share: "",
  slots: "",
  operationalCities: "",
};

const OperatorLeadDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  // data from operator management reducer
  const {
    operatorLeadDetails = {},
    loading = false,
    emailLoader = false,
    errorOperator404,
    eSignLoader = false,
    saveOperatorLoader = false,
  } = useSelector((state: RootState) => state.operatorManagementReducer);

  // destructuring of keys
  const {
    operatorConfigurations = {},
    address = {},
    operatorAgreementStatus,
    generatedAgreement,
  } = operatorLeadDetails;

  // master data from common reducer
  const { allCitiesDetails = [] } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // state to store operator fields
  const [fields, setFields] = useState({ ...initialState });
  const [duplicateFields, setDuplicateFields] = useState({ ...initialState });

  // satate to handle confirmation checkbox
  const [isAgree, setIsAgree] = useState<boolean>(false);

  // state to store error messages of fields
  const [errorFields, setErrorFields] = useState({ ...errorInitialState });

  // state to handle address modal toggle
  const [addAddressModal, setAddAddressModal] = useState<boolean>(false);

  const [openAggreementModal, setOpenAggrementModal] = useState<boolean>(false);

  // initial useeffect (get states , get operator lead details , breadcrumbs)
  useEffect(() => {
    dispatch(operationActions.getOperatorLeadDetails({ id: params?.id }));
    // update breadcrumbs
    dispatch(updateBreadcrumbs(BREADCRUMBS_RENTALS.OPERATOR_LEAD));
    dispatch(getAllStates());
  }, []);

  //updating the state with api data
  useEffect(() => {
    let fieldsObj = {};

    if (Object.keys(operatorConfigurations).length) {
      const slots = BookingEndSlotOptions.find(
        (option: any) =>
          option.name === operatorConfigurations.bookingEndSlotType
      );

      fieldsObj = {
        share: `${operatorConfigurations.operatorSplitShare}%`,
        slots: slots || {},
        operationalCities:
          operatorLeadDetails?.operationalCity.map((e: any) => {
            return e.name;
          }) ?? [],
        operatorConfig: operatorConfigurations,
        businessType: operatorLeadDetails?.businessType
          ? {
              name: operatorLeadDetails?.businessType,
              displayName:
                operatorLeadDetails?.businessType === BusinessType.LLP ||
                operatorLeadDetails?.businessType === BusinessType.NGO
                  ? operatorLeadDetails?.businessType
                  : toPascalCase(
                      operatorLeadDetails?.businessType.replace(/_/g, " ")
                    ),
            }
          : initialState.businessType,
      };

      setFields((prevState: any) => ({
        ...prevState,
        ...fieldsObj,
      }));

      setDuplicateFields((prevState: any) => ({
        ...prevState,
        ...fieldsObj,
      }));

      // update screen header
      dispatch(
        rentalActions.setScreenHeaderName({
          headerName: rentalEn.operatorManagement.operatorManagement,
        })
      );
    }
  }, [operatorConfigurations]);

  // handle change in operator fields
  const handleChange = (type: string, value: any) => {
    setDuplicateFields((prevState: any) => ({
      ...prevState,
      [type]: value,
    }));
    setErrorFields((prevState: any) => ({
      ...prevState,
      [type]: "",
    }));
  };

  //handle config change
  const handleConfigChange = (key: string) => {
    setDuplicateFields((prevState: any) => {
      const newOperatorConfig = {
        ...prevState.operatorConfig,
        [key]: !prevState.operatorConfig[key],
      };
      return {
        ...prevState,
        operatorConfig: newOperatorConfig,
      };
    });
  };

  // validate operator fields
  const validateOperatorFields = (): boolean => {
    let error = false;

    let optionalArr = ["businessType", "share"];

    const validatedFields = validateOnly(
      duplicateFields,
      errorFields,
      optionalArr
    );

    if (validatedFields.error) {
      error = true;
      setErrorFields((prevState: any) => ({
        ...prevState,
        ...validatedFields.errorFields,
      }));
    }

    if (duplicateFields?.share === "0%" || duplicateFields?.share === "0.%") {
      error = true;
      setErrorFields((prevState: any) => ({
        ...prevState,
        share: rentalEn.errorMessages.mustBeGreaterThan0,
      }));
    }

    if (!duplicateFields.operationalCities.length) {
      error = true;
      setErrorFields((prevState: any) => ({
        ...prevState,
        operationalCities: rentalEn.errorMessages.requiredField,
      }));
    }
    return error;
  };

  // approve lead success callback
  const approveLeadSuccessCallBack = () => {
    navigate(`${routesConstants.APPROVED_OPERATOR}`);
  };

  //save operator
  const handleSaveOperator = (type: string) => {
    const payload = {
      id: params?.id,
      apiBody: {
        data: {
          businessType: duplicateFields?.businessType?.name
            ? duplicateFields?.businessType?.name
            : undefined,
          operatorConfigurations: {
            ...duplicateFields?.operatorConfig,
            operatorSplitShare: duplicateFields.share
              ? parseFloat(duplicateFields.share.replace("%", ""))
              : undefined,
            bookingEndSlotType: duplicateFields?.slots?.name,
          },
          operationalCity: duplicateFields?.operationalCities?.length
            ? duplicateFields?.operationalCities
            : undefined,
        },
      },
      type,
      successCallback:
        type === "sendAggrement"
          ? initiateESignSuccessCallBack
          : type === "approveLead"
          ? approveLeadSuccessCallBack
          : () => {},
    };

    dispatch(operationActions.saveOperatorLeadDetails(payload));
  };

  // toggle modal
  const handleSendAggrementModalClose = () => {
    setOpenAggrementModal(false);
  };

  //initiate e - sign callback
  const initiateESignSuccessCallBack = () => {
    if (params?.id) {
      navigate(`${routesConstants.LEAD_DETAILS}/${params?.id}`, {
        state: {
          duplicateFields,
        },
      });
    }
  };

  //on click send agreement
  const onClickSendAggrement = () => {
    if (!isEqual(fields, duplicateFields)) {
      handleSaveOperator("sendAggrement");
    } else {
      const payload = {
        data: {
          leadId: params?.id,
        },
        successCallback: initiateESignSuccessCallBack,
      };
      dispatch(operationActions.initiateESignOperators(payload));
    }
  };

  //handle on click generate agreemnet
  const handleOnClickGenerateAgreement = () => {
    const validate: boolean = validateOperatorFields();

    if (validate) return;

    if (operatorAgreementStatus === statusCheck.VERIFIED) {
      if (!isEqual(fields, duplicateFields)) {
        handleSaveOperator("approveLead");
      } else {
        const payload = {
          data: {
            id: params?.id,
          },
          successCallback: approveLeadSuccessCallBack,
        };
        dispatch(operationActions.approveLead(payload));
      }

      //need to redirect to listing page apporve operators
    } else {
      const payload = {
        data: {
          id: params?.id,
        },
        successCallback: setOpenAggrementModal(true),
      };
      dispatch(operationActions.viewAgreement(payload));
    }
  };

  //get background color for backgroud of opertor email status
  const getBackgroundColor = () => {
    if (operatorLeadDetails?.emailVerificationStatus === statusCheck.VERIFIED) {
      return colors.secondary_green;
    } else if (
      operatorLeadDetails?.emailVerificationStatus === statusCheck.UNVERIFIED
    ) {
      return colors.THEME_BLUE;
    } else {
      return colors.primary_warning;
    }
  };

  //send email verification link
  const handleSendVerificationLink = () => {
    const payload = {
      data: {
        leadId: params.id,
      },
    };
    dispatch(operationActions.verifyEmailOperator(payload));
  };

  return (
    <>
      {errorOperator404?.errorCode === "OPERATOR-999" ? (
        <WrongUrl />
      ) : (
        <Stack gap="20px">
          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors.THEME_BLUE}
          >
            {rentalEn.operatorManagement.basicDetails}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4} lg={4}>
              <Input
                value={toPascalCase(operatorLeadDetails?.name)}
                label={`${rentalEn.operatorManagement.operaterName}`}
                width="318px"
                readOnly={true}
                focused={true}
                customInputProps={{ readOnly: true }}
                muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
              />
            </Grid>

            <Grid item sm={12} md={4} lg={4}>
              <Input
                value={toPascalCase(operatorLeadDetails?.phone)}
                label={rentalEn.operatorManagement.mobileNumber}
                width="318px"
                readOnly={true}
                focused={true}
                muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
                customInputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Input
                value={operatorLeadDetails?.email}
                label={rentalEn.operatorManagement.email}
                width="318px"
                readOnly={true}
                focused={true}
                endAdornmentComponent={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        minWidth: "65px",
                        color: colors.white,
                        fontWeight: "500",
                        alignSelf: "center",
                        textAlign: "center",
                        backgroundColor: getBackgroundColor(),
                        borderRadius: "30px",
                        padding: "6px 16px 6px 16px",
                        cursor:
                          operatorLeadDetails?.emailVerificationStatus ===
                          statusCheck.UNVERIFIED
                            ? "pointer"
                            : "none",
                      }}
                      onClick={() => {
                        if (
                          operatorLeadDetails?.emailVerificationStatus ===
                          statusCheck.UNVERIFIED
                        ) {
                          handleSendVerificationLink();
                        }
                      }}
                    >
                      {emailLoader ? (
                        <CircularProgress color="inherit" size="15px" />
                      ) : operatorLeadDetails?.emailVerificationStatus ===
                        statusCheck.UNVERIFIED ? (
                        rentalEn.operatorManagement.verify
                      ) : operatorLeadDetails?.emailVerificationStatus ===
                        statusCheck.VERIFIED ? (
                        rentalEn.operatorManagement.verified
                      ) : (
                        rentalEn.operatorManagement.pending
                      )}
                    </Typography>
                    {operatorLeadDetails?.emailVerificationStatus ===
                    statusCheck.PENDING ? (
                      <CustomResetIcon
                        tooltipTitle="Resend Email"
                        loading={loading}
                        customStyle={{ fontSize: "25px" }}
                        onClick={handleSendVerificationLink}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                }
                customInputProps={{ readOnly: true }}
                muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4} lg={4}>
              <Input
                value={operatorLeadDetails.preferredCity
                  ?.slice(0, 2)
                  .map((city: any, index: number) => {
                    return ` ${toPascalCase(city)} `;
                  })}
                label={rentalEn.operatorManagement.preferredCity}
                width="318px"
                readOnly={true}
                focused={true}
                customInputProps={{ readOnly: true }}
                muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
              />
            </Grid>

            <Grid item sm={12} md={4} lg={4}>
              <CustomMultiSelect
                icon={CityInputIcon}
                selectAllText={rentalEn.global.allCities}
                placeholder={
                  rentalEn.operatorManagement.selectOperationalCities
                }
                value={duplicateFields?.operationalCities}
                handleChange={(values: any) => {
                  handleChange("operationalCities", values);
                }}
                choice={allCitiesDetails?.filter(
                  (city: GenericObject) => city?.status === cityStatus.active
                )}
                defaultKey="name"
                displayNameKey="displayName"
                errormessage={errorFields.operationalCities}
                sx={{
                  "& .MuiButtonBase-root-MuiMenuItem-root li": {
                    minHeight: "30px",
                  },
                  "& .MuiMenu-list li": {
                    height: "42px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #053C3D",
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <CustomSelect
                required
                icon={StatusIcon}
                placeholder={rentalEn.operatorManagement.selectBusinessType}
                value={duplicateFields.businessType?.displayName}
                choice={BusinessTypeOptions}
                defaultKey={"displayName"}
                handleChange={(value: any) => {
                  handleChange("businessType", value);
                }}
                errormessage={errorFields.businessType}
                sx={{
                  "& .MuiButtonBase-root-MuiMenuItem-root li": {
                    minHeight: "30px",
                  },
                  "& .MuiMenu-list li": {
                    height: "42px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #053C3D",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4} lg={4}>
              <Input
                value={`${duplicateFields?.share}`}
                placeholder={`${rentalEn.operatorManagement.operatorRevenueShare}`}
                label={rentalEn.operatorManagement.operatorRevenueShare}
                width="318px"
                readOnly={true}
                focused={true}
                onChange={(e: any) => {
                  let price: any = e.target.value;

                  if (
                    price === "" ||
                    (checkWhiteSpace(e.target.value) &&
                      isValidDecimalNumber(price) &&
                      price <= 100 &&
                      checkLeadingZero(price, 1))
                  )
                    handleChange("share", price);
                }}
                onBlur={() => {
                  if (
                    parseFloat(duplicateFields.share) >= 0 &&
                    !duplicateFields.share.includes("%")
                  )
                    setDuplicateFields((prev: any) => ({
                      ...prev,
                      share: `${prev.share}%`,
                    }));
                }}
                onFocus={() => {
                  if (
                    parseFloat(duplicateFields.share) >= 0 &&
                    duplicateFields.share.endsWith("%")
                  )
                    setDuplicateFields((prev: any) => ({
                      ...prev,
                      share: `${prev.share.slice(0, -1)}`,
                    }));
                }}
                muiTextFieldRootStyle={{ width: 1, minWidth: "unset" }}
                errormessage={errorFields.share}
              />
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <CustomSelect
                required
                icon={StatusIcon}
                placeholder={rentalEn.operatorManagement.bookingHours}
                value={duplicateFields.slots?.displayName}
                choice={BookingEndSlotOptions}
                defaultKey={"displayName"}
                handleChange={(value: any) => {
                  handleChange("slots", value);
                }}
                sx={{
                  "& .MuiButtonBase-root-MuiMenuItem-root li": {
                    minHeight: "30px",
                  },
                  "& .MuiMenu-list li": {
                    height: "42px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #053C3D",
                  },
                }}
              />
            </Grid>
          </Grid>

          <OperatorConfig
            operatorConfig={duplicateFields.operatorConfig}
            handleConfigChange={handleConfigChange}
            title={rentalEn.operatorManagement.configureOperatorFunctionalities}
          />

          <FormControlLabel
            control={<Checkbox />}
            label="All the details provided above are correct and match the operator details"
            checked={isAgree}
            onClick={(e: any) => {
              setIsAgree(!isAgree);
            }}
          />
          {operatorLeadDetails?.emailVerificationStatus ===
            statusCheck.UNVERIFIED ||
          operatorLeadDetails?.emailVerificationStatus ===
            statusCheck.PENDING ? (
            <Box sx={{ display: "flex", marginTop: "-10px" }}>
              <Typography
                variant={typographyConstants.BODY}
                fontWeight="500"
                marginRight="5px"
              >
                {rentalEn.operatorManagement.note}
              </Typography>
              <Typography variant={typographyConstants.BODY}>
                {rentalEn.operatorManagement.noteOperator}
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <CustomButton
              label={rentalEn.operatorManagement.saveForLater}
              variant="outlined"
              onClick={handleSaveOperator}
              disabled={isEqual(fields, duplicateFields)}
              sx={{ minWidth: "200px" }}
              loading={saveOperatorLoader}
            />
            <CustomButton
              label={
                operatorAgreementStatus === statusCheck.VERIFIED
                  ? rentalEn.global.approve
                  : rentalEn.operatorManagement.generateAgreement
              }
              variant="contained"
              loading={loading}
              onClick={handleOnClickGenerateAgreement}
              disabled={
                operatorLeadDetails?.emailVerificationStatus ===
                  statusCheck.UNVERIFIED ||
                operatorLeadDetails?.emailVerificationStatus ===
                  statusCheck.PENDING ||
                !isAgree
              }
              sx={{ minWidth: "200px" }}
            />
          </Box>

          <SendAggrementModal
            isOpen={openAggreementModal}
            handleClose={handleSendAggrementModalClose}
            onClickSendAggrement={onClickSendAggrement}
            loading={eSignLoader}
          />
          {generatedAgreement ? (
            <PreviewDigitalAgreement
              fields={duplicateFields}
              handleChange={handleChange}
              aggreementData={{
                operatorAgreementStatus,
                generatedAgreement,
              }}
            />
          ) : null}
        </Stack>
      )}
    </>
  );
};

export default OperatorLeadDetails;
