import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { colors } from "../../../themes/colors";
import styles from "../customcomponents.module.css";
import { removeTrailingSpaces } from "../../../rentals-src/utils/helper";

const Input = forwardRef((props: any, ref) => {
  const [focused, setFocused] = React.useState(false);
  const {
    onIconClick,
    customStyle = {},
    inputContainerStyle = {},
    startAdornment: StartIcon,
    fontSize = "inherit",
    containerWidth = "",
    onChange,
    isRemoveTrailingSpaces = false,
    muiTextFieldRootStyle = {},
    customInputProps = {},
    endAdornmentComponent,
    ...rest
  } = props || {};

  return (
    <Box
      className={styles.BoxColumn}
      sx={props?.containerStyle}
      width={props?.containerWidth}
    >
      <Box
        sx={{
          "& .MuiTextField-root": {
            minWidth: props.width ? props.width : "350px",
            width: props.width ? props.width : "400px",
            ...muiTextFieldRootStyle,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: colors.THEME_BLUE,
            fontSize: "12px",
            top: "1px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "1px",
            margin: "1px",
          },
        }}
      >
        <TextField
          id="outlined-search"
          label={props.label}
          type="text"
          autoComplete={props.autoComplete ? "on" : "off"}
          InputProps={{
            sx: {
              borderRadius: "8px",
              fontSize: "12px",
              minHeight: "42px",
              height: "40px",
              maxHeight: "50px",
              ...customStyle,
            },

            startAdornment: (
              <InputAdornment position="start">
                {StartIcon ? <StartIcon /> : <img src={props.iconStart} />}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {endAdornmentComponent ? (
                  endAdornmentComponent
                ) : (
                  <img src={props.icon} onClick={onIconClick} />
                )}
              </InputAdornment>
            ),
            ...customInputProps,
          }}
          sx={{
            color: "rgba(60, 60, 67, 0.4)",
            borderRadius: "8px",
            // remove upper and lower icon in case of type number
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            ...inputContainerStyle,
          }}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          focused={focused}
          inputRef={ref}
          error={props?.error ?? false}
          onChange={(e: any) => {
            // remove trailing spaces
            if (isRemoveTrailingSpaces) {
              e.target.value = removeTrailingSpaces(e.target.value);
            }

            onChange(e);
          }}
          {...rest}
        />
      </Box>
      <Box>
        {props.errormessage && (
          <Typography variant="errorMessage1" marginLeft="8px">
            {props.errormessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default Input;
