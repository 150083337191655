import {
  API_URLS,
  customAxios,
  FREEDO_BIZ_X_URL,
  FREEDO_X_URL,
} from "../../config";

const getAvailableRentalPlansX = (payload: any) => {
  try {
    let planUrl =
      FREEDO_X_URL +
      API_URLS.getPlansX +
      `?branchName=${payload.name}&modelName=${payload.modal}`;
    return customAxios.get(planUrl, payload).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getAvailableRentalPlansX,
};
