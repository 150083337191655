import en from '../../../locale/en.json'
import { ACTIONS } from '../../actions/actionTypes';
import { localeInterface } from '../actionInterfaces';

const initialState = {
    locale: en
}

const localeReducer = (state = initialState, action: localeInterface) => {
    const prevState = { ...state }
    const { type, payload } = action

    switch (type) {
        case ACTIONS.LOCALE: {
            if (payload === 'en') {
                return {
                    ...prevState,
                    locale: en
                }
            }
            else return { ...prevState }
        }
        default: return { ...prevState }
    }
}

export default localeReducer