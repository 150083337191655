import { gql } from "@apollo/client";
import { client } from "../config";

const GET_REPORT_QUERY = gql`
  query Query(
    $reportType: ReportType!
    $downloadType: DownloadType
    $filters: Filters
  ) {
    getReports(
      reportType: $reportType
      downloadType: $downloadType
      filters: $filters
    ) {
      keys
      headings
      data
    }
  }
`;

const getGqlReportsService = async (payload: any) => {
  const results = await client.query({
    query: GET_REPORT_QUERY,
    variables: {
      ...payload,
    },
  });
  return results;
};

const GET_GENERIC_REPORT_QUERY = gql`
  query Query($genericReportType: GenericReportType!) {
    getGenericReports(genericReportType: $genericReportType) {
      keys
      headings
    }
  }
`;

const DOWNLOAD_GENERIC_REPORT_QUERY = gql`
  query Query(
    $genericReportType: GenericReportType!
    $selectedKeys: [String]
    $downloadType: DownloadType
    $filters: Filters
  ) {
    getGenericReports(
      genericReportType: $genericReportType
      selectedKeys: $selectedKeys
      downloadType: $downloadType
      filters: $filters
    ) {
      data
    }
  }
`;

const downloadGqlGenericReportsService = async (data: any) => {
  const results = await client.query({
    query:
      data?.type === "request"
        ? GET_GENERIC_REPORT_QUERY
        : DOWNLOAD_GENERIC_REPORT_QUERY,
    variables: {
      ...data?.payload,
    },
  });
  return results;
};

const GET_GENERIC_REPORT_LIST = gql`
  query Query {
    getGenericReportsDescription {
      name
      description
      reportName
      title
    }
  }
`;

const getGqlReportsListService = async () => {
  const results = await client.query({
    query: GET_GENERIC_REPORT_LIST,
    variables: {},
  });
  return results;
};

export default {
  getGqlReportsService,
  downloadGqlGenericReportsService,
  getGqlReportsListService,
};
