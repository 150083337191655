import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { StartIconLabelButtonX } from "../../components/muiButtonsX";
import { getInitials } from "../../utils/helper";
import { colors } from "../../../themes/colors";
import { BOOKING_STATUS_ENUM } from "../../constantsX/booking";
import styles from "./bookingDetails.module.css";
import { Fade } from "react-awesome-reveal";
import { RootState } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import en from "../../../rentals-src/locale/rental-en.json";
import { TAGS } from "../../../rentals-src/constants/constants";

const UserDetails: any = ({
  customerDetailsSection,
  bookingDetailsloader,
  bookingStatus,
  handleOpenStartRideModal,
  handleCancelBookingOpen,
  handleGetAvailableVehicles,
  handleAddChargeModalOpen,
}: any) => {
  const [expand, setExpand] = useState(false);

  const { customerProfileData } = useSelector(
    (state: RootState) => state.customerManagementReducer
  );
  /* Expand User Detail */
  const handleChange = (e: any) => {
    setExpand((prevExpand) => !prevExpand);
  };
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {/* USER DETAIL */}
      <Box
        sx={{
          backgroundColor: colors.THEME_BLUEX,
          borderRadius: "40px",
          padding: "5px 10px 5px 10px",
          transition: "width 1s",
          transitionTimingFunction: "linear",
          transitionDelay: "1s",
          display: "inline-block",
          "&:hover": { width: "auto" },
        }}
        onMouseOver={handleChange}
        onMouseOut={handleChange}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Fade>
            <Box>
              <Avatar
                sx={{
                  backgroundColor: "white",
                  color: colors.THEME_BLUEX,
                  width: "35px",
                  height: "35px",
                  fontSize: "12px",
                  position: "unset",
                }}
              >
                {getInitials(customerDetailsSection?.name)}
              </Avatar>
            </Box>
            <Stack>
              <Typography color={colors.white} variant="caption">
                {customerDetailsSection?.firstName ?? ""}
              </Typography>
              <Typography color={colors.white} variant="caption">
                {customerDetailsSection?.lastName ?? ""}
              </Typography>
            </Stack>
            {expand && (
              <Fade>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      className={styles.bookingDetailsValue}
                      color={colors.white}
                    >
                      {customerDetailsSection?.phone}
                    </Typography>
                    <Typography
                      className={styles.bookingDetailsValue}
                      color={colors.white}
                      sx={{ width: "auto" }}
                    >
                      {customerDetailsSection?.email}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={styles.bookingDetailsValue}
                      color={colors.white}
                    >
                      KYC
                    </Typography>
                    <Typography
                      className={styles.bookingDetailsValue}
                      color={colors.white}
                    >
                      {customerProfileData?.tags?.includes(TAGS.KYC_VERIFIED)
                        ? en.bookingDetails.verified
                        : en.bookingDetails.notVerified}
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            )}
          </Fade>
        </Box>
      </Box>

      {/* Buttons */}
      <Stack direction="row" gap="1vw">
        {/* Start Ride */}
        {bookingStatus === "BOOKED" && (
          <StartIconLabelButtonX
            variant="outlined"
            label="Start Ride"
            position="relative"
            onClick={() => {
              handleGetAvailableVehicles(), handleOpenStartRideModal();
            }}
          ></StartIconLabelButtonX>
        )}

        {/* Add Charge */}
        {bookingStatus === BOOKING_STATUS_ENUM.ACTIVE && (
          <StartIconLabelButtonX
            variant="outlined"
            label={"Add Charge"}
            position="relative"
            onClick={handleAddChargeModalOpen}
          />
        )}

        {/* 
          RULE -
            1. Close booking - ACTIVE  
            2. Cancel booking - not CANCELLED or CLOSED
          CASE  -
            1. If production - Close booking not allowed
            2. not production - Cancel & Close allowed
        */}
        {process.env.REACT_APP_NODE_ENV === "production" &&
        bookingStatus === "ACTIVE" ? (
          <></>
        ) : !(bookingStatus === "CANCELLED" || bookingStatus === "CLOSED") ? (
          <StartIconLabelButtonX
            variant="outlined"
            label={
              bookingStatus === "ACTIVE"
                ? "Cancel Subscription"
                : "Cancel Booking"
            }
            style={{ color: "red" }}
            position="relative"
            color="warning"
            onClick={handleCancelBookingOpen}
          />
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};
export default UserDetails;
