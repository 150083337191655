import { Stack, Typography, Skeleton } from "@mui/material";
import { getFormattedINR } from "../../../utils/helper";
import { colors } from "../../../../themes/colors";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import en from "../../../../locale/en.json";
import { typographyConstants } from "../../../constants/constants";
import rentalEn from "../../../locale/rental-en.json";

export const CheckoutFooter = (props: any) => {
  return (
    <Stack
      direction="row"
      spacing={4}
      style={{ alignItems: "center", position: "fixed", right: "37px" }}
    >
      {/* PAYMENT BRIEF */}
      <Stack direction="row" gap={2} alignItems="end">
        <Stack alignItems="end">
          <Typography sx={{ fontWeight: "600" }}>
            {`${props?.fields?.selectedPlan?.heading} Selected`}
          </Typography>
          {props?.loading ? (
            <Skeleton width="20px" height="15px" animation="wave" />
          ) : (
            <Typography sx={{ fontWeight: "600" }}>
              {props?.fields?.selectedPackage === "1"
                ? `${props?.fields?.selectedPackage} Day`
                : props?.fields?.selectedPackage ===
                  rentalEn.manualBooking.custom
                ? `${props?.fields?.selectedDaysCustom} Days`
                : `${props?.fields?.selectedPackage} Days`}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="end">
          <Typography sx={{ fontWeight: "600" }}>
            {getFormattedINR(props?.charges?.totalAmount)}
          </Typography>
          <Typography
            variant={typographyConstants.BODYITALIC}
            color={colors.tertiary_black}
          >
            {en.NewManualBooking.InclusiveAllTaxes}
          </Typography>
        </Stack>
      </Stack>
      <CustomButton
        variant="contained"
        sx={{ minWidth: "200px" }}
        label={en.NewManualBooking.ProceedToPay}
        onClick={() => props?.setOpenSideDrawer(true)}
        // disabled={createManualBookingLoader}
      />
    </Stack>
  );
};
