export enum ACTIONS {
  LOCALE = "LOCALE",
  SHOW_TOAST = "SHOW_TOAST",
  HIDE_TOAST = "HIDE_TOAST",

  SERVER_ERROR = "SERVER_ERROR",
  AUTH_ISSUE = "AUTH_ISSUE",
}

export enum THEME_ACTIONS {
  SET_THEME_MODE = "SET_THEME_MODE",
}

export enum AUTH_ACTIONS {
  USER_LOGIN_REQUESTED = "USER_LOGIN_REQUESTED",
  USER_LOGIN_SUCCEEDED = "USER_LOGIN_SUCCEEDED",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",

  LOGOUT = "LOGOUT",

  USER_PROFILE_REQUESTED = "USER_PROFILE_REQUESTED",
  USER_PROFILE_SUCCEEDED = "USER_PROFILE_SUCCEEDED",
  USER_PROFILE_FAILED = "USER_PROFILE_FAILED",

  SET_USER_DATA = "SET_USER_DATA",
  SET_USER_TOKEN = "SET_USER_TOKEN",

  USER_SIGNUP_REQUESTED = "USER_SIGNUP_REQUESTED",
  USER_SIGNUP_SUCCEEDED = "USER_SIGNUP_SUCCEEDED",
  USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED",
  DASHBOARD_STATIC_REQUESTED = "DASHBOARD_STATIC_REQUESTED",
  DASHBOARD_STATIC_FAILED = "DASHBOARD_STATIC_FAILED",
  DASHBOARD_STATIC_SUCCEEDED = "DASHBOARD_STATIC_SUCCEEDED",

  DASHBOARD_DISPLAY_TABLE_REQUESTED = "DASHBOARD_DISPLAY_TABLE_REQUESTED",
  DASHBOARD_DISPLAY_TABLE_SUCCEEDED = "DASHBOARD_DISPLAY_TABLE_SUCCEEDED",
  DASHBOARD_DISPLAY_TABLE_FAILED = "DASHBOARD_DISPLAY_TABLE_FAILED",

  DASHBOARD_ACTION_CLICK_REQUESTED = "DASHBOARD_ACTION_CLICK_REQUESTED",
  DASHBOARD_ACTION_CLICK_SUCCEEDED = "DASHBOARD_ACTION_CLICK_SUCCEEDED",
  DASHBOARD_ACTION_CLICK_FAILED = "DASHBOARD_ACTION_CLICK_FAILED",

  DASHBOARD_VIEW_CALENDAR_REQUESTED = "DASHBOARD_VIEW_CALENDAR_REQUESTED",
  DASHBOARD_VIEW_CALENDAR_SUCCEEDED = "DASHBOARD_VIEW_CALENDAR_SUCCEEDED",
  DASHBOARD_VIEW_CALENDAR_FAILED = "DASHBOARD_VIEW_CALENDAR_FAILED",

  USER_ALL_DATA_REQUESTED = "USER_ALL_DATA_REQUESTED",
  USER_ALL_DATA_SUCCEEDED = "USER_ALL_DATA_SUCCEEDED",
  USER_ALL_DATA_FAILED = "USER_ALL_DATA_FAILED",
}

export enum STATISTICS {
  VEHICLE_UTILIZATION_DATES_REQUESTED = "VEHICLE_UTILIZATION_DATES_REQUESTED",
  VEHICLE_UTILIZATION_DATES_SUCCESS = "VEHICLE_UTILIZATION_DATES_SUCCESS",
  VEHICLE_UTILIZATION_DATES_FAILED = "VEHICLE_UTILIZATION_DATES_FAILED",
  GET_VEHICLE_UTILIZATION_REQUESTED = "GET_VEHICLE_UTILIZATION_REQUESTED",
  GET_VEHICLE_UTILIZATION_SUCCESS = "GET_VEHICLE_UTILIZATION_SUCCESS",
  GET_VEHICLE_UTILIZATION_FAILED = "GET_VEHICLE_UTILIZATION_FAILED",
  GET_PAYMENT_GRAPH_REQUESTED = "GET_PAYMENT_GRAPH_REQUESTED",
  GET_PAYMENT_GRAPH_SUCCESS = "GET_PAYMENT_GRAPH_SUCCESS",
  GET_PAYMENT_GRAPH_FAILED = "GET_PAYMENT_GRAPH_FAILED",
  GET_REVENUE_GRAPH_REQUESTED = "GET_REVENUE_GRAPH_REQUESTED",
  GET_REVENUE_GRAPH_SUCCESS = "GET_REVENUE_GRAPH_SUCCESS",
  GET_REVENUE_GRAPH_FAILED = "GET_REVENUE_GRAPH_FAILED",

  GET_PAYMENT_GRAPH_DOWNLOAD_REQUESTED = "GET_PAYMENT_GRAPH_DOWNLOAD_REQUESTED",
  GET_PAYMENT_GRAPH_DOWNLOAD_SUCCESS = "GET_PAYMENT_GRAPH_DOWNLOAD_SUCCESS",
  GET_PAYMENT_GRAPH_DOWNLOAD_FAILED = "GET_PAYMENT_GRAPH_DOWNLOAD_FAILED",
  GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_REQUESTED = "GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_REQUESTED",
  GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_SUCCESS = "GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_SUCCESS",
  GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_FAILED = "GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_FAILED",
  GET_REVENUE_GRAPH_DOWNLOAD_REQUESTED = "GET_REVENUE_GRAPH_DOWNLOAD_REQUESTED",
  GET_REVENUE_GRAPH_DOWNLOAD_SUCCESS = "GET_REVENUE_GRAPH_DOWNLOAD_SUCCESS",
  GET_REVENUE_GRAPH_DOWNLOAD_FAILED = "GET_REVENUE_GRAPH_DOWNLOAD_FAILED",

  GET_ALL_STATISTICS_DATA_REQUESTED = "GET_ALL_STATISTICS_DATA_REQUESTED",
  GET_ALL_STATISTICS_DATA_SUCCESS = "GET_ALL_STATISTICS_DATA_SUCCESS",
  GET_ALL_STATISTICS_DATA_FAILED = "GET_ALL_STATISTICS_DATA_FAILED",
}

export enum FEEDBACK_ACTIONS {
  FEEDBACKS_REQUESTED = "FEEDBACKS_REQUESTED",
  FEEDBACKSFETCH_SUCCESS = "FEEDBACKSFETCH_SUCCESS",
  FEEDBACKSFETCH_FAILED = "FEEDBACKSFETCH_FAILED",

  FEEDBACKS_DATA_REQUESTED = "FEEDBACKS_DATA_REQUESTED",
  FEEDBACKS_DATA_SUCCESS = "FEEDBACKS_DATA_SUCCESS",
  FEEDBACKS_DATA_FAILED = "FEEDBACKS_DATA_FAILED",

  GET_FEEDBACKBY_ID_REQUESTED = "GET_FEEDBACKBY_ID_REQUESTED",
  GET_FEEDBACKBY_ID_SUCCESS = "GET_FEEDBACKBY_ID_SUCCESS",
  GET_FEEDBACKBY_ID_FAILED = "GET_FEEDBACKBY_ID_FAILED",

  GET_FEEDBACKBY_BOOKING_ID_REQUESTED = "GET_FEEDBACKBY_BOOKING_ID_REQUESTED",
  GET_FEEDBACKBY_BOOKING_ID_SUCCESS = "GET_FEEDBACKBY_BOOKING_ID_SUCCESS",
  GET_FEEDBACKBY_BOOKING_ID_FAILED = "GET_FEEDBACKBY_BOOKING_ID_FAILED",

  EXECUTIVE_FEEDBACK_REQUESTED = "EXECUTIVE_FEEDBACK_REQUESTED",
  EXECUTIVE_FEEDBACK_SUCCESS = "EXECUTIVE_FEEDBACK_SUCCESS",
  EXECUTIVE_FEEDBACK_FAILED = "EXECUTIVE_FEEDBACK_FAILED",

  CLEAR_EXECUTIVE_FEEDBACK = "CLEAR_EXECUTIVE_FEEDBACK",
  CLEAR_BOOKING_FEEDBACK = "CLEAR_BOOKING_FEEDBACK",
}

export enum LOCATION_ACTIONS {
  LOCATION_DATA_REQUESTED = "LOCATION_DATA_REQUESTED",
  LOCATION_DATA_SUCCESS = "LOCATION_DATA_SUCCESS",
  LOCATION_DATA_FAILED = "LOCATION_DATA_FAILED",

  EDIT_LOCATION_DATA_REQUESTED = "EDIT_LOCATION_DATA_REQUESTED",
  EDIT_LOCATION_DATA_SUCCESS = "EDIT_LOCATION_DATA_SUCCESS",
  EDIT_LOCATION_DATA_FAILED = "EDIT_LOCATION_DATA_FAILED",
}

export enum CITY_ACTIONS {
  CITYDATA_REQUESTED = "CITYDATA_REQUESTED",
  CITYDATA_SUCCESS = "CITYDATA_SUCCESS",
  CITYDATA_FAILED = "CITYDATA_FAILED",

  GET_CITIES_BY_STATE_REQUESTED = "GET_CITIES_BY_STATE_REQUESTED",
  GET_CITIES_BY_STATE_SUCCESS = "GET_CITIES_BY_STATE_SUCCESS",
  GET_CITIES_BY_STATE_FAILED = "GET_CITIES_BY_STATE_FAILED",
}
export enum BRAND_ACTIONS {
  BRANDDATA_REQUESTED = "BRANDDATA_REQUESTED",
  BRANDDATA_SUCCESS = "BRANDDATA_SUCCESS",
  BRANDDATA_FAILED = "BRANDDATA_FAILED",
}

export enum VEHICLE_MANAGEMENT_ACTIONS {
  GET_VEHICLE_TYPES_REQUESTED = "GET_VEHICLE_TYPES_REQUESTED",
  GET_VEHICLE_TYPES_SUCCESS = "GET_VEHICLE_TYPES_SUCCESS",
  GET_VEHICLE_TYPES_FAILED = "GET_VEHICLE_TYPES_FAILED",

  GET_VEHICLE_STATUS_REQUESTED = "GET_VEHICLE_STATUS_REQUESTED",
  GET_VEHICLE_STATUS_SUCCESS = "GET_VEHICLE_STATUS_SUCCESS",
  GET_VEHICLE_STATUS_FAILED = "GET_VEHICLE_STATUS_FAILED",

  GET_VEHICLE_BY_ID_REQUESTED = "GET_VEHICLE_BY_ID_REQUESTED",
  GET_VEHICLE_BY_ID_SUCCESS = "GET_VEHICLE_BY_ID_SUCCESS",
  GET_VEHICLE_BY_ID_FAILED = "GET_VEHICLE_BY_ID_FAILED",

  GET_MODEL_LIST_BY_ID_REQUESTED = "GET_MODEL_LIST_BY_ID_REQUESTED",
  GET_MODEL_LIST_BY_ID_SUCCESS = "GET_MODEL_LIST_BY_ID_SUCCESS",
  GET_MODEL_LIST_BY_ID_FAILED = "GET_MODEL_LIST_BY_ID_FAILED",

  GET_VEHICLE_COLORS_REQUESTED = "GET_VEHICLE_COLORS_REQUESTED",
  GET_VEHICLE_COLORS_SUCCESS = "GET_VEHICLE_COLORS_SUCCESS",
  GET_VEHICLE_COLORS_FAILED = "GET_VEHICLE_COLORS_FAILED",

  GET_EMISSION_STANDARDS_REQUESTED = "GET_EMISSION_STANDARDS_REQUESTED",
  GET_EMISSION_STANDARDS_SUCCESS = "GET_EMISSION_STANDARDS_SUCCESS",
  GET_EMISSION_STANDARDS_FAILED = "GET_EMISSION_STANDARDS_FAILED",

  GET_ALL_BRANCHES_REQUESTED = "GET_ALL_BRANCHES_REQUESTED",
  GET_ALL_BRANCHES_SUCCESS = "GET_ALL_BRANCHES_SUCCESS",
  GET_ALL_BRANCHES_FAILED = "GET_ALL_BRANCHES_FAILED",
  GET_ALL_BRANCHES_RESET = "GET_ALL_BRANCHES_RESET",

  GET_ALL_VENDOR_CODE_REQUESTED = "GET_ALL_VENDOR_CODE_REQUESTED",
  GET_ALL_VENDOR_CODE_SUCCESS = "GET_ALL_VENDOR_CODE_SUCCESS",
  GET_ALL_VENDOR_CODE_FAILED = "GET_ALL_VENDOR_CODE_FAILED",

  GET_ALL_IOT_TYPE_REQUESTED = "GET_ALL_IOT_TYPE_REQUESTED",
  GET_ALL_IOT_TYPE_SUCCESS = "GET_ALL_IOT_TYPE_SUCCESS",
  GET_ALL_IOT_TYPE_FAILED = "GET_ALL_IOT_TYPE_FAILED",

  STORE_VEHICLE_REQUESTED = "STORE_VEHICLE_REQUESTED",
  STORE_VEHICLE_SUCCESS = "STORE_VEHICLE_SUCCESS",
  STORE_VEHICLE_FAILED = "STORE_VEHICLE_FAILED",

  STORE_DOCUMENT_REQUESTED = "STORE_DOCUMENT_REQUESTED",
  STORE_DOCUMENT_SUCCESS = "STORE_DOCUMENT_SUCCESS",
  STORE_DOCUMENT_FAILED = "STORE_DOCUMENT_FAILED",

  GET_VEHICLE_CATEGORIES_REQUESTED = "GET_VEHICLE_CATEGORIES_REQUESTED",
  GET_VEHICLE_CATEGORIES_SUCCESS = "GET_VEHICLE_CATEGORIES_SUCCESS",
  GET_VEHICLE_CATEGORIES_FAILED = "GET_VEHICLE_CATEGORIES_FAILED",

  CHANGE_VEHICLE_STATUS_REQUESTED = "CHANGE_VEHICLE_STATUS_REQUESTED",
  CHANGE_VEHICLE_STATUS_SUCCESS = "CHANGE_VEHICLE_STATUS_SUCCESS",
  CHANGE_VEHICLE_STATUS_FAILED = "CHANGE_VEHICLE_STATUS_FAILED",
  CHANGE_VEHICLE_STATUS_RESET = "CHANGE_VEHICLE_STATUS_RESET",

  GET_VEHICLE_LIST_FOR_BLOCKING_REQUESTED = "GET_VEHICLE_LIST_FOR_BLOCKING_REQUESTED",
  GET_VEHICLE_LIST_FOR_BLOCKING_SUCCESS = "GET_VEHICLE_LIST_FOR_BLOCKING_SUCCESS",
  GET_VEHICLE_LIST_FOR_BLOCKING_FAILED = "GET_VEHICLE_LIST_FOR_BLOCKING_FAILED",

  GET_BLOCKED_VEHICLE_LIST_REQUESTED = "GET_BLOCKED_VEHICLE_LIST_REQUESTED",
  GET_BLOCKED_VEHICLE_LIST_SUCCESS = "GET_BLOCKED_VEHICLE_LIST_SUCCESS",
  GET_BLOCKED_VEHICLE_LIST_FAILED = "GET_BLOCKED_VEHICLE_LIST_FAILED",

  BLOCK_VEHICLE_REQUESTED = "BLOCK_VEHICLE_REQUESTED",
  BLOCK_VEHICLE_SUCCESS = "BLOCK_VEHICLE_SUCCESS",
  BLOCK_VEHICLE_FAILED = "BLOCK_VEHICLE_FAILED",

  UNBLOCK_VEHICLE_REQUESTED = "UNBLOCK_VEHICLE_REQUESTED",
  UNBLOCK_VEHICLE_SUCCESS = "UNBLOCK_VEHICLE_SUCCESS",
  UNBLOCK_VEHICLE_FAILED = "UNBLOCK_VEHICLE_FAILED",

  GET_VEHICLE_UPLOAD_FORMAT_REQUESTED = "GET_VEHICLE_UPLOAD_FORMAT_REQUESTED",
  GET_VEHICLE_UPLOAD_FORMAT_SUCCESS = "GET_VEHICLE_UPLOAD_FORMAT_SUCCESS",
  GET_VEHICLE_UPLOAD_FORMAT_FAILED = "GET_VEHICLE_UPLOAD_FORMAT_FAILED",

  UPLOAD_INVETORY_FILE_REQUESTED = "UPLOAD_INVETORY_FILE_REQUESTED",
  UPLOAD_INVETORY_FILE_SUCCESS = "UPLOAD_INVETORY_FILE_SUCCESS",
  UPLOAD_INVETORY_FILE_FAILED = "UPLOAD_INVETORY_FILE_FAILED",

  BULK_UPLOAD_REQUESTED = "BULK_UPLOAD_REQUESTED",
  BULK_UPLOAD_SUCCESS = "BULK_UPLOAD_SUCCESS",
  BULK_UPLOAD_FAILED = "BULK_UPLOAD_FAILED",
  BULK_UPLOAD_RESET = "BULK_UPLOAD_RESET",

  BULK_UPLOAD_LAMBDA_REQUESTED = "BULK_UPLOAD_LAMBDA_REQUESTED",
  BULK_UPLOAD_LAMBDA_SUCCESS = "BULK_UPLOAD_LAMBDA_SUCCESS",
  BULK_UPLOAD_LAMBDA_FAILED = "BULK_UPLOAD_LAMBDA_FAILED",

  CLEAR_BLOCK_STATUS = "CLEAR_BLOCK_STATUS",

  GET_MODEL_DETAILS_BY_ID_REQUESTED = "GET_MODEL_DETAILS_BY_ID_REQUESTED",
  GET_MODEL_DETAILS_BY_ID_SUCCESS = "GET_MODEL_DETAILS_BY_ID_SUCCESS",
  GET_MODEL_DETAILS_BY_ID_FAILED = "GET_MODEL_DETAILS_BY_ID_FAILED",

  GET_VEHICLE_FOR_APPROVE_REJECT_REQUESTED = "GET_VEHICLE_FOR_APPROVE_REJECT_REQUESTED",
  GET_VEHICLE_FOR_APPROVE_REJECT_SUCCESS = "GET_VEHICLE_FOR_APPROVE_REJECT_SUCCESS",
  GET_VEHICLE_FOR_APPROVE_REJECT_FAILED = "GET_VEHICLE_FOR_APPROVE_REJECT_FAILED",

  VEHICLE_APPROVE_REJECT_REQUESTED = "VEHICLE_APPROVE_REJECT_REQUESTED",
  VEHICLE_APPROVE_REJECT_SUCCESS = "VEHICLE_APPROVE_REJECT_SUCCESS",
  VEHICLE_APPROVE_REJECT_FAILED = "VEHICLE_APPROVE_REJECT_FAILED",

  MASTER_CONFIG_REQUESTED = "MASTER_CONFIG_REQUESTED",
  MASTER_CONFIG_SUCCESS = "MASTER_CONFIG_SUCCESS",
  MASTER_CONFIG_FAILED = "MASTER_CONFIG_FAILED",

  CHANGE_VEHICLE_BRANCH_REQUESTED = "CHANGE_VEHICLE_BRANCH_REQUESTED",
  CHANGE_VEHICLE_BRANCH_SUCCESS = "CHANGE_VEHICLE_BRANCH_SUCCESS",
  CHANGE_VEHICLE_BRANCH_FAILED = "CHANGE_VEHICLE_BRANCH_FAILED",
  CHANGE_VEHICLE_BRANCH_RESET = "CHANGE_VEHICLE_BRANCH_RESET",

  TRANSFER_BRANCH_LIST_REQUESTED = "TRANSFER_BRANCH_LIST_REQUESTED",
  TRANSFER_BRANCH_LIST_SUCCESS = "TRANSFER_BRANCH_LIST_SUCCESS",
  TRANSFER_BRANCH_LIST_FAILED = "TRANSFER_BRANCH_LIST_FAILED",

  GET_TRANSFER_HISTORY_LIST_REQUESTED = "GET_TRANSFER_HISTORY_LIST_REQUESTED",
  GET_TRANSFER_HISTORY_LIST_SUCCESS = "GET_TRANSFER_HISTORY_LIST_SUCCESS",
  GET_TRANSFER_HISTORY_LIST_FAILED = "GET_TRANSFER_HISTORY_LIST_FAILED",

  GET_TRANSFER_REQUEST_DETAIL_REQUESTED = "GET_TRANSFER_REQUEST_DETAIL_REQUESTED",
  GET_TRANSFER_REQUEST_DETAIL_SUCCESS = "GET_TRANSFER_REQUEST_DETAIL_SUCCESS",
  GET_TRANSFER_REQUEST_DETAIL_FAILED = "GET_TRANSFER_REQUEST_DETAIL_FAILED",

  GET_CHALLAN_LIST_REQUESTED = "GET_CHALLAN_LIST_REQUESTED",
  GET_CHALLAN_LIST_SUCCESS = "GET_CHALLAN_LIST_SUCCESS",
  GET_CHALLAN_LIST_FAILED = "GET_CHALLAN_LIST_FAILED",

  GET_BOOKING_SEARCH_REQUESTED = "GET_BOOKING_SEARCH_REQUESTED",
  GET_BOOKING_SEARCH_SUCCESS = "GET_BOOKING_SEARCH_SUCCESS",
  GET_BOOKING_SEARCH_FAILED = "GET_BOOKING_SEARCH_FAILED",

  LINK_CHALLAN_REQUESTED = "LINK_CHALLAN_REQUESTED",
  LINK_CHALLAN_SUCCESS = "LINK_CHALLAN_SUCCESS",
  LINK_CHALLAN_FAILED = "LINK_CHALLAN_FAILED",

  UNLINK_CHALLAN_REQUESTED = "UNLINK_CHALLAN_REQUESTED",
  UNLINK_CHALLAN_SUCCESS = "UNLINK_CHALLAN_SUCCESS",
  UNLINK_CHALLAN_FAILED = "UNLINK_CHALLAN_FAILED",

  CHALLAN_STATUS_REQUESTED = "CHALLAN_STATUS_REQUESTED",
  CHALLAN_STATUS_SUCCESS = "CHALLAN_STATUS_SUCCESS",
  CHALLAN_STATUS_FAILED = "CHALLAN_STATUS_FAILED",

  CHALLAN_LIST_CLEAR = "CHALLAN_LIST_CLEAR",
}

export enum VEHICLE_CATEGORY_LIST {
  VEHICLE_CATEGORY_LIST_REQUESTED = "VEHICLE_CATEGORY_LIST_REQUESTED",
  VEHICLE_CATEGORY_LIST_SUCCESS = "VEHICLE_CATEGORY_LIST_SUCCESS",
  VEHICLE_CATEGORY_LIST_FAILED = "VEHICLE_CATEGORY_LIST_FAILED",
}

export enum COUNTRYDATA_ACTIONS {
  COUNTRYDATA_REQUESTED = "COUNTRYDATA_REQUESTED",
  COUNTRYDATA_SUCCESS = "COUNTRYDATA_SUCCESS",
  COUNTRYDATA_FAILED = "COUNTRYDATA_FAILED",
}

export enum STATEDATA_ACTIONS {
  STATEDATA_REQUESTED = "STATEDATA_REQUESTED",
  STATEDATA_SUCCESS = "STATEDATA_SUCCESS",
  STATEDATA_FAILED = "STATEDATA_FAILED",
}

export enum DELETEDATA_ACTIONS {
  DELETEDATA_REQUESTED = "DELETEDATA_REQUESTED",
  DELETEDATA_SUCCESS = "DELETEDATA_SUCCESS",
  DELETEDATA_FAILED = "DELETEDATA_FAILED",
}

export enum PACKAGES_ACTIONS {
  PACKAGES_DATA_REQUESTED = "PACKAGES_DATA_REQUESTED",
  PACKAGES_DATA_SUCCESS = "PACKAGES_DATA_SUCCESS",
  PACKAGES_DATA_FAILED = "PACKAGES_DATA_FAILED",
}

export enum VEHICLE_ACTIONS {
  VEHICLEDATA_REQUESTED = "VEHICLEDATA_REQUESTED",
  VEHICLEDATA_SUCCESS = "VEHICLEDATA_SUCCESS",
  VEHICLEDATA_FAILED = "VEHICLEDATA_FAILED",
}

export enum COUPONS_ACTIONS {
  GET_COUPONS_REQUESTED = "GET_COUPONS_REQUESTED",
  GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS",
  GET_COUPONS_FAILED = "GET_COUPONS_FAILED",
  GET_COUPON_BY_ID_REQUESTED = "GET_COUPON_BY_ID_REQUESTED",
  GET_COUPON_BY_ID_SUCCESS = "GET_COUPON_BY_ID_SUCCESS",
  GET_COUPON_BY_ID_FAILED = "GET_COUPON_BY_ID_FAILED",
  ADD_COUPON_REQUESTED = "ADD_COUPON_REQUESTED",
  ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS",
  ADD_COUPON_FAILED = "ADD_COUPON_FAILED",
  EDIT_COUPON_REQUESTED = "EDIT_COUPON_REQUESTED",
  EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS",
  EDIT_COUPON_FAILED = "EDIT_COUPON_FAILED",
  DELETE_COUPON_REQUESTED = "DELETE_COUPON_REQUESTED",
  DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_FAILED = "DELETE_COUPON_FAILED",
  GET_COUPON_CODE_TYPE_REQUESTED = "GET_COUPON_CODE_TYPE_REQUESTED",
  GET_COUPON_CODE_TYPE_SUCCESS = "GET_COUPON_CODE_TYPE_SUCCESS",
  GET_COUPON_CODE_TYPE_FAILED = "GET_COUPON_CODE_TYPE_FAILED",
  GET_COUPON_USES_REQUESTED = "GET_COUPON_USES_REQUESTED",
  GET_COUPON_USES_SUCCESS = "GET_COUPON_USES_SUCCESS",
  GET_COUPON_USES_FAILED = "GET_COUPON_USES_FAILED",
  GET_COUPON_REVENUE_REQUESTED = "GET_COUPON_REVENUE_REQUESTED",
  GET_COUPON_REVENUE_SUCCESS = "GET_COUPON_REVENUE_SUCCESS",
  GET_COUPON_REVENUE_FAILED = "GET_COUPON_REVENUE_FAILED",
  GET_COUPON_REVENUE_DOWNLOAD_REQUESTED = "GET_COUPON_REVENUE_DOWNLOAD_REQUESTED",
  GET_COUPON_REVENUE_DOWNLOAD_SUCCESS = "GET_COUPON_REVENUE_DOWNLOAD_SUCCESS",
  GET_COUPON_REVENUE_DOWNLOAD_FAILED = "GET_COUPON_REVENUE_DOWNLOAD_FAILED",
}

export enum SLOT_PAUSE_ACTIONS {
  GET_SLOT_PAUSE_REQUESTED = "GET_SLOT_PAUSE_REQUESTED",
  GET_SLOT_PAUSE_SUCCESS = "GET_SLOT_PAUSE_SUCCESS",
  GET_SLOT_PAUSE_FAILED = "GET_SLOT_PAUSE_FAILED",

  GET_SLOT_BY_BRANCH_PAUSE_REQUESTED = "GET_SLOT_BY_BRANCH_PAUSE_REQUESTED",
  GET_SLOT_BY_BRANCH_PAUSE_SUCCESS = "GET_SLOT_BY_BRANCH_PAUSE_SUCCESS",
  GET_SLOT_BY_BRANCH_PAUSE_FAILED = "GET_SLOT_BY_BRANCH_PAUSE_FAILED",

  GET_ALL_SLOT_PAUSE_REQUESTED = "GET_ALL_SLOT_PAUSE_REQUESTED",
  GET_ALL_SLOT_PAUSE_SUCCESS = "GET_ALL_SLOT_PAUSE_SUCCESS",
  GET_ALL_SLOT_PAUSE_FAILED = "GET_ALL_SLOT_PAUSE_FAILED",

  GET_SLOT_PAUSE_BY_ID_REQUESTED = "GET_SLOT_PAUSE_BY_ID_REQUESTED",
  GET_SLOT_PAUSE_BY_ID_SUCCESS = "GET_SLOT_PAUSE_BY_ID_SUCCESS",
  GET_SLOT_PAUSE_BY_ID_FAILED = "GET_SLOT_PAUSE_BY_ID_FAILED",

  ADD_SLOT_PAUSE_REQUESTED = "ADD_SLOT_PAUSE_REQUESTED",
  ADD_SLOT_PAUSE_SUCCESS = "ADD_SLOT_PAUSE_SUCCESS",
  ADD_SLOT_PAUSE_FAILED = "ADD_SLOT_PAUSE_FAILED",

  EDIT_SLOT_PAUSE_REQUESTED = "EDIT_SLOT_PAUSE_REQUESTED",
  EDIT_SLOT_PAUSE_SUCCESS = "EDIT_SLOT_PAUSE_SUCCESS",
  EDIT_SLOT_PAUSE_FAILED = "EDIT_SLOT_PAUSE_FAILED",

  DELETE_SLOT_PAUSE_REQUESTED = "DELETE_SLOT_PAUSE_REQUESTED",
  DELETE_SLOT_PAUSE_SUCCESS = "DELETE_SLOT_PAUSE_SUCCESS",
  DELETE_SLOT_PAUSE_FAILED = "DELETE_SLOT_PAUSE_FAILED",
}

export enum STATUS_LIST_ACTIONS {
  GET_STATUS_LIST_REQUESTED = "GET_STATUS_LIST_REQUESTED",
  GET_STATUS_LIST_SUCCESS = "GET_STATUS_LIST_SUCCESS",
  GET_STATUS_LIST_FAILED = "GET_STATUS_LIST_FAILED",
}
export enum GET_REPORTS {
  RESET_DOWNLOAD_STATUS = "RESET_DOWNLOAD_STATUS",

  GET_USER_REPORTS_REQUESTED = "GET_USER_REPORTS_REQUESTED",
  GET_USER_REPORTS_SUCCESS = "GET_USER_REPORTS_SUCCESS",
  GET_USER_REPORTS_FAILED = "GET_USER_REPORTS_FAILED",

  GET_BOOKING_REPORTS_REQUESTED = "GET_BOOKING_REPORTS_REQUESTED",
  GET_BOOKING_REPORTS_SUCCESS = "GET_BOOKING_REPORTS_SUCCESS",
  GET_BOOKING_REPORTS_FAILED = "GET_BOOKING_REPORTS_FAILED",

  GET_PAYMENT_REPORTS_REQUESTED = "GET_PAYMENT_REPORTS_REQUESTED",
  GET_PAYMENT_REPORTS_SUCCESS = "GET_PAYMENT_REPORTS_SUCCESS",
  GET_PAYMENT_REPORTS_FAILED = "GET_PAYMENT_REPORTS_FAILED",

  GET_GENERIC_REPORTS_REQUESTED = "GET_GENERIC_REPORTS_REQUESTED",
  GET_GENERIC_REPORTS_SUCCESS = "GET_GENERIC_REPORTS_SUCCESS",
  GET_GENERIC_REPORTS_FAILED = "GET_GENERIC_REPORTS_FAILED",

  RESET_ALLDATA = "RESET_ALLDATA",
  RESET_USER_REPORT_DATA = "RESET_USER_REPORT_DATA",
  RESET_PAYMENT_REPORT_DATA = "RESET_PAYMENT_REPORT_DATA",
  RESET_BOOKING_REPORT_DATA = "RESET_BOOKING_REPORT_DATA",

  GETPAYMENTRECONCILIATION_REPORTS_REQUESTED = "GETPAYMENTRECONCILIATION_REPORTS_REQUESTED",
  GETPAYMENTRECONCILIATION_REPORTS_SUCCESS = "GETPAYMENTRECONCILIATION_REPORTS_SUCCESS",
  GETPAYMENTRECONCILIATION_REPORTS_FAILED = "GETPAYMENTRECONCILIATION_REPORTS_FAILED",

  DOWNLOAD_USER_REPORTS_REQUESTED = "DOWNLOAD_USER_REPORTS_REQUESTED",
  DOWNLOAD_USER_REPORTS_SUCCESS = "DOWNLOAD_USER_REPORTS_SUCCESS",
  DOWNLOAD_USER_REPORTS_FAILED = "DOWNLOAD_USER_REPORTS_FAILED",

  DOWNLOAD_BOOKING_REPORTS_REQUESTED = "DOWNLOAD_BOOKING_REPORTS_REQUESTED",
  DOWNLOAD_BOOKING_REPORTS_SUCCESS = "DOWNLOAD_BOOKING_REPORTS_SUCCESS",
  DOWNLOAD_BOOKING_REPORTS_FAILED = "DOWNLOAD_BOOKING_REPORTS_FAILED",

  DOWNLOAD_PAYMENT_REPORTS_REQUESTED = "DOWNLOAD_PAYMENT_REPORTS_REQUESTED",
  DOWNLOAD_PAYMENT_REPORTS_SUCCESS = "DOWNLOAD_PAYMENT_REPORTS_SUCCESS",
  DOWNLOAD_PAYMENT_REPORTS_FAILED = "DOWNLOAD_PAYMENT_REPORTS_FAILED",

  DOWNLOAD_PAYMENTRECON_REPORTS_REQUESTED = "DOWNLOAD_PAYMENTRECON_REPORTS_REQUESTED",
  DOWNLOAD_PAYMENTRECON_REPORTS_SUCCESS = "DOWNLOAD_PAYMENTRECON_REPORTS_SUCCESS",
  DOWNLOAD_PAYMENTRECON_REPORTS_FAILED = "DOWNLOAD_PAYMENTRECON_REPORTS_FAILED",

  DOWNLOAD_REVENUE_REPORTS_REQUESTED = "DOWNLOAD_REVENUE_REPORTS_REQUESTED",
  DOWNLOAD_REVENUE_REPORTS_SUCCESS = "DOWNLOAD_REVENUE_REPORTS_SUCCESS",
  DOWNLOAD_REVENUE_REPORTS_FAILED = "DOWNLOAD_REVENUE_REPORTS_FAILED",

  DOWNLOAD_GENERIC_REPORTS_REQUESTED = "DOWNLOAD_GENERIC_REPORTS_REQUESTED",
  DOWNLOAD_GENERIC_REPORTS_SUCCESS = "DOWNLOAD_GENERIC_REPORTS_SUCCESS",
  DOWNLOAD_GENERIC_REPORTS_FAILED = "DOWNLOAD_GENERIC_REPORTS_FAILED",

  GET_ALL_GENERIC_REPORT_KEYS_REQUESTED = "GET_ALL_GENERIC_REPORT_KEYS_REQUESTED",
  GET_ALL_GENERIC_REPORT_KEYS_SUCCESS = "GET_ALL_GENERIC_REPORT_KEYS_SUCCESS",
  GET_ALL_GENERIC_REPORT_KEYS_FAILURE = "GET_ALL_GENERIC_REPORT_KEYS_FAILURE",

  GET_ALL_GENERIC_REPORT_LIST_REQUESTED = "GET_ALL_GENERIC_REPORT_LIST_REQUESTED",
  GET_ALL_GENERIC_REPORT_LIST_SUCCESS = "GET_ALL_GENERIC_REPORT_LIST_SUCCESS",
  GET_ALL_GENERIC_REPORT_LIST_FAILED = "GET_ALL_GENERIC_REPORT_LIST_FAILED",
}

export enum MASTER_ACTIONS {
  GET_HELMET_CONFIG_REQUESTED = "GET_HELMET_CONFIG_REQUESTED",
  GET_HELMET_CONFIG_SUCCESS = "GET_HELMET_CONFIG_SUCCESS",
  GET_HELMET_CONFIG_FAILED = "GET_HELMET_CONFIG_FAILED",

  GET_CITY_DATA_REQUESTED = "GET_CITY_DATA_REQUESTED",
  GET_CITY_DATA_SUCCESS = "GET_CITY_DATA_SUCCESS",
  GET_CITY_DATA_FAILED = "GET_CITY_DATA_FAILED",

  GET_CITY_STATUS_LIST_REQUESTED = "GET_CITY_STATUS_LIST_REQUESTED",
  GET_CITY_STATUS_LIST_SUCCESS = "GET_CITY_STATUS_LIST_SUCCESS",
  GET_CITY_STATUS_LIST_FAILED = "GET_CITY_STATUS_LIST_FAILED",

  GET_ADVERTISEMENT_DATA_REQUESTED = "GET_ADVERTISEMENT_DATA_REQUESTED",
  GET_ADVERTISEMENT_DATA_SUCCESS = "GET_ADVERTISEMENT_DATA_SUCCESS",
  GET_ADVERTISEMENT_DATA_FAILED = "GET_ADVERTISEMENT_DATA_FAILED",
  GET_ADVERTISEMENT_BY_ID_REQUESTED = "GET_ADVERTISEMENT_BY_ID_REQUESTED",
  GET_ADVERTISEMENT_BY_ID_SUCCESS = "GET_ADVERTISEMENT_BY_ID_SUCCESS",
  GET_ADVERTISEMENT_BY_ID_FAILED = "GET_ADVERTISEMENT_BY_ID_FAILED",
  ADD_ADVERTISEMENT_DATA_REQUESTED = "ADD_ADVERTISEMENT_DATA_REQUESTED",
  ADD_ADVERTISEMENT_DATA_SUCCESS = "ADD_ADVERTISEMENT_DATA_SUCCESS",
  ADD_ADVERTISEMENT_DATA_FAILED = "ADD_ADVERTISEMENT_DATA_FAILED",

  EDIT_ADVERTISEMENT_DATA_REQUESTED = "EDIT_ADVERTISEMENT_DATA_REQUESTED",
  EDIT_ADVERTISEMENT_DATA_SUCCESS = "EDIT_ADVERTISEMENT_DATA_SUCCESS",
  EDIT_ADVERTISEMENT_DATA_FAILED = "EDIT_ADVERTISEMENT_DATA_FAILED",

  GET_CITY_BY_ID_REQUESTED = "GET_CITY_BY_ID_REQUESTED",
  GET_CITY_BY_ID_SUCCESS = "GET_CITY_BY_ID_SUCCESS",
  GET_CITY_BY_ID_FAILED = "GET_CITY_BY_ID_FAILED",

  GET_CATEGORY_BY_ID_REQUESTED = "GET_CATEGORY_BY_ID_REQUESTED",
  GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS",
  GET_CATEGORY_BY_ID_FAILED = "GET_CATEGORY_BY_ID_FAILED",

  GET_MODEL_BY_ID_REQUESTED = "GET_MODEL_BY_ID_REQUESTED",
  GET_MODEL_BY_ID_SUCCESS = "GET_MODEL_BY_ID_SUCCESS",
  GET_MODEL_BY_ID_FAILED = "GET_MODEL_BY_ID_FAILED",

  GET_CATEGORY_DATA_REQUESTED = "GET_CATEGORY_DATA_REQUESTED",
  GET_CATEGORY_DATA_SUCCESS = "GET_CATEGORY_DATA_SUCCESS",
  GET_CATEGORY_DATA_FAILED = "GET_CATEGORY_DATA_FAILED",

  GET_MODEL_DATA_REQUESTED = "GET_MODEL_DATA_REQUESTED",
  GET_MODEL_DATA_SUCCESS = "GET_MODEL_DATA_SUCCESS",
  GET_MODEL_DATA_FAILED = "GET_MODEL_DATA_FAILED",

  ADD_CITY_DATA_REQUESTED = "ADD_CITY_DATA_REQUESTED",
  ADD_CITY_DATA_SUCCESS = "ADD_CITY_DATA_SUCCESS",
  ADD_CITY_DATA_FAILED = "ADD_CITY_DATA_FAILED",

  EDIT_CITY_DATA_REQUESTED = "EDIT_CITY_DATA_REQUESTED",
  EDIT_CITY_DATA_SUCCESS = "EDIT_CITY_DATA_SUCCESS",
  EDIT_CITY_DATA_FAILED = "EDIT_CITY_DATA_FAILED",

  DELETE_CITY_DATA_REQUESTED = "DELETE_CITY_DATA_REQUESTED",
  DELETE_CITY_DATA_SUCCESS = "DELETE_CITY_DATA_SUCCESS",
  DELETE_CITY_DATA_FAILED = "DELETE_CITY_DATA_FAILED",

  ADD_CATEGORY_DATA_REQUESTED = "ADD_CATEGORY_DATA_REQUESTED",
  ADD_CATEGORY_DATA_SUCCESS = "ADD_CATEGORY_DATA_SUCCESS",
  ADD_CATEGORY_DATA_FAILED = "ADD_CATEGORY_DATA_FAILED",

  EDIT_CATEGORY_DATA_REQUESTED = "EDIT_CATEGORY_DATA_REQUESTED",
  EDIT_CATEGORY_DATA_SUCCESS = "EDIT_CATEGORY_DATA_SUCCESS",
  EDIT_CATEGORY_DATA_FAILED = "EDIT_CATEGORY_DATA_FAILED",

  DELETE_CATEGORY_DATA_REQUESTED = "DELETE_CATEGORY_DATA_REQUESTED",
  DELETE_CATEGORY_DATA_SUCCESS = "DELETE_CATEGORY_DATA_SUCCESS",
  DELETE_CATEGORY_DATA_FAILED = "DELETE_CATEGORY_DATA_FAILED",

  ADD_MODEL_DATA_REQUESTED = "ADD_MODEL_DATA_REQUESTED",
  ADD_MODEL_DATA_SUCCESS = "ADD_MODEL_DATA_SUCCESS",
  ADD_MODEL_DATA_FAILED = "ADD_MODEL_DATA_FAILED",

  EDIT_MODEL_DATA_REQUESTED = "EDIT_MODEL_DATA_REQUESTED",
  EDIT_MODEL_DATA_SUCCESS = "EDIT_MODEL_DATA_SUCCESS",
  EDIT_MODEL_DATA_FAILED = "EDIT_MODEL_DATA_FAILED",

  DELETE_MODEL_DATA_REQUESTED = "DELETE_MODEL_DATA_REQUESTED",
  DELETE_MODEL_DATA_SUCCESS = "DELETE_MODEL_DATA_SUCCESS",
  DELETE_MODEL_DATA_FAILED = "DELETE_MODEL_DATA_FAILED",

  TOGGLE_ADD_EDIT = "TOGGLE_ADD_EDIT",

  REFRESH_DRAWER_SCREEN_DATA = "REFRESH_DRAWER_SCREEN_DATA",
}

export enum CSV_DASHBOARD_ACTIONS {
  CSV_DASHBOARD_REQUESTED = "CSV_DASHBOARD_REQUESTED",
  CSV_DASHBOARD_SUCCESS = "CSV_DASHBOARD_SUCCESS",
  CSV_DASHBOARD_FAILED = "CSV_DASHBOARD_FAILED",
}

export enum OPERATOR_MANAGEMENT_ACTIONS {
  GET_OPERATOR_LIST_REQUESTED = "GET_OPERATOR_LIST_REQUESTED",
  GET_OPERATOR_LIST_SUCCESS = "GET_OPERATOR_LIST_SUCCESS",
  GET_OPERATOR_LIST_FAILED = "GET_OPERATOR_LIST_FAILED",

  DELETE_OPERATOR_REQUESTED = "DELETE_OPERATOR_REQUESTED",
  DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS",
  DELETE_OPERATOR_FAILED = " DELETE_OPERATOR_FAILED",

  ADD_OPERATOR_REQUESTED = "ADD_OPERATOR_REQUESTED",
  ADD_OPERATOR_SUCCESS = "ADD_OPERATOR_SUCCESS",
  ADD_OPERATOR_FAILED = "ADD_OPERATOR_FAILED",

  UPDATE_OPERATOR_REQUESTED = "UPDATE_OPERATOR_REQUESTED",
  UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS",
  UPDATE_OPERATOR_FAILED = "UPDATE_OPERATOR_FAILED",
  OPERATOR_ACTION_CLICK_REQUESTED = "OPERATOR_ACTION_CLICK_REQUESTED",
  OPERATOR_ACTION_CLICK_SUCCESS = "OPERATOR_ACTION_CLICK_SUCCESS",
  OPERATOR_ACTION_CLICK_FAILED = "OPERATOR_ACTION_CLICK_FAILED",

  GET_BUSINESS_TYPE_REQUESTED = "GET_BUSINESS_TYPE_REQUESTED",
  GET_BUSINESS_TYPE_SUCCCESS = "GET_BUSINESS_TYPE_SUCCCESS",
  GET_BUSINESS_TYPE_FAILED = "GET_BUSINESS_TYPE_FAILED",

  GET_ALL_DOCUMENT_TYPES_REQUESTED = "GET_ALL_DOCUMENT_TYPES_REQUESTED",
  GET_ALL_DOCUMENT_TYPES_SUCCESS = "GET_ALL_DOCUMENT_TYPES_SUCCESS",
  GET_ALL_DOCUMENT_TYPES_FAILED = "GET_ALL_DOCUMENT_TYPES_FAILED",

  OPERATOR_STORE_DOCUMENT_REQUESTED = "OPERATOR_STORE_DOCUMENT_REQUESTED",
  OPERATOR_STORE_DOCUMENT_SUCCESS = "OPERATOR_STORE_DOCUMENT_SUCCESS",
  OPERATOR_STORE_DOCUMENT_FAILED = "OPERATOR_STORE_DOCUMENT_FAILED",

  EDIT_OPERATOR_REQUESTED = "EDIT_OPERATOR_REQUESTED",
  EDIT_OPERATOR_SUCCESS = "EDIT_OPERATOR_SUCCESS",
  EDIT_OPERATOR_FAILED = "EDIT_OPERATOR_FAILED",

  GET_OPERATOR_DETAILS_REQUESTED = "GET_OPERATOR_DETAILS_REQUESTED",
  GET_OPERATOR_DETAILS_SUCCESS = "GET_OPERATOR_DETAILS_SUCCESS",
  GET_OPERATOR_DETAILS_FAILED = "GET_OPERATOR_DETAILS_FAILED",

  SAVE_OPERATOR_REQUESTED = "SAVE_OPERATOR_REQUESTED",
  SAVE_OPERATOR_SUCCESS = "SAVE_OPERATOR_SUCCESS",
  SAVE_OPERATOR_FAILED = "SAVE_OPERATOR_FAILED",

  LINKED_ACCOUNT_OPERATOR_REQUESTED = "LINKED_ACCOUNT_OPERATOR_REQUESTED",
  LINKED_ACCOUNT_OPERATOR_SUCCESS = "LINKED_ACCOUNT_OPERATOR_SUCCESS",
  LINKED_ACCOUNT_OPERATOR_FAILED = "LINKED_ACCOUNT_OPERATOR_FAILED",

  STAKEHOLDER_OPERATOR_REQUESTED = "STAKEHOLDER_OPERATOR_REQUESTED",
  STAKEHOLDER_OPERATOR_SUCCESS = "STAKEHOLDER_OPERATOR_SUCCESS",
  STAKEHOLDER_OPERATOR_FAILED = "STAKEHOLDER_OPERATOR_FAILED",

  PRODUCT_CONFIGURATION_OPERATOR_REQUESTED = "PRODUCT_CONFIGURATION_OPERATOR_REQUESTED",
  PRODUCT_CONFIGURATION_OPERATOR_SUCCESS = "PRODUCT_CONFIGURATION_OPERATOR_SUCCESS",
  PRODUCT_CONFIGURATION_OPERATOR_FAILED = "PRODUCT_CONFIGURATION_OPERATOR_FAILED",
}

export enum TASKMANAGEMENT_ACTIONS {
  TASKLIST_REQUESTED = "TASKLIST_REQUESTED",
  TASKLIST_SUCCESS = "TASKLIST_SUCCESS",
  TASKLIST_FAILED = "TASKLIST_FAILED",

  TASK_DETAIL_BY_ID_REQUESTED = "TASK_DETAIL_BY_ID_REQUESTED",
  TASK_DETAIL_BY_ID_SUCCESS = "TASK_DETAIL_BY_ID_SUCCESS",
  TASK_DETAIL_BY_ID_FAILED = "TASK_DETAIL_BY_ID_FAILED",

  CREATE_TASK_REQUESTED = "CREATE_TASK_REQUESTED",
  CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILED = "CREATE_TASK_FAILED",

  EDIT_TASK_REQUESTED = "EDIT_TASK_REQUESTED",
  EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILED = "EDIT_TASK_FAILED",

  DELETE_TASK_REQUESTED = "DELETE_TASK_REQUESTED",
  DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILED = "DELETE_TASK_FAILED",

  TASK_SEQUENCE_LIST_REQUESTED = "TASK_SEQUENCE_LIST_REQUESTED",
  TASK_SEQUENCE_LIST_SUCCESS = "TASK_SEQUENCE_LIST_SUCCESS",
  TASK_SEQUENCE_LIST_FAILED = "TASK_SEQUENCE_LIST_FAILED",

  TASK_SEQUENCE_WAREHOUSE_LIST_REQUESTED = "TASK_SEQUENCE_WAREHOUSE_LIST_REQUESTED",
  TASK_SEQUENCE_WAREHOUSE_LIST_SUCCESS = "TASK_SEQUENCE_WAREHOUSE_LIST_SUCCESS",
  TASK_SEQUENCE_WAREHOUSE_LIST_FAILED = "TASK_SEQUENCE_WAREHOUSE_LIST_FAILED",

  EDIT_TASK_SEQUENCE_REQUESTED = "EDIT_TASK_SEQUENCE_REQUESTED",
  EDIT_TASK_SEQUENCE_SUCCESS = "EDIT_TASK_SEQUENCE_SUCCESS",
  EDIT_TASK_SEQUENCE_FAILED = "EDIT_TASK_SEQUENCE_FAILED",

  TASK_STATUS_REQUESTED = "TASK_STATUS_REQUESTED",
  TASK_STATUS_SUCCESS = "TASK_STATUS_SUCCESS",
  TASK_STATUS_FAILED = "TASK_STATUS_FAILED",

  TASK_SUMMARY_REQUESTED = "TASK_SUMMARY_REQUESTED",
  TASK_SUMMARY_SUCCESS = "TASK_SUMMARY_SUCCESS",
  TASK_SUMMARY_FAILED = "TASK_SUMMARY_FAILED",

  TASK_USER_LIST_REQUESTED = "TASK_USER_LIST_REQUESTED",
  TASK_USER_LIST_SUCCESS = "TASK_USER_LIST_SUCCESS",
  TASK_USER_LIST_FAILED = "TASK_USER_LIST_FAILED",

  USER_TASK_ASSIGN_REQUESTED = "USER_TASK_ASSIGN_REQUESTED",
  USER_TASK_ASSIGN_SUCCESS = "USER_TASK_ASSIGN_SUCCESS",
  USER_TASK_ASSIGN_FAILED = "USER_TASK_ASSIGN_FAILED",

  TASK_SUGGESTION_REQUESTED = "TASK_SUGGESTION_REQUESTED",
  TASK_SUGGESTION_SUCCESS = "TASK_SUGGESTION_SUCCESS",
  TASK_SUGGESTION_FAILED = "TASK_SUGGESTION_FAILED",

  CLEAR_TASK_SUMMARY_DATA = "CLEAR_TASK_SUMMARY_DATA",
}

export enum NEW_AUTH_ACTIONS {
  NEW_USER_LOGIN_REQUESTED = "NEW_USER_LOGIN_REQUESTED",
  NEW_USER_LOGIN_SUCCEEDED = "NEW_USER_LOGIN_SUCCEEDED",
  NEW_USER_LOGIN_FAILED = "NEW_USER_LOGIN_FAILED",

  NEW_USER_LOGOUT = "NEW_USER_LOGOUT",
  NEW_USER_LOGOUT_REQUESTED = "NEW_USER_LOGOUT_REQUESTED",
  NEW_USER_LOGOUT_SUCCESS = "NEW_USER_LOGOUT_SUCCESS",
  NEW_USER_LOGOUT_FAILED = "NEW_USER_LOGOUT_FAILED",

  USER_PROFILE_REQUESTED = "USER_PROFILE_REQUESTED",
  USER_PROFILE_SUCCEEDED = "USER_PROFILE_SUCCEEDED",
  USER_PROFILE_FAILED = "USER_PROFILE_FAILED",

  SET_USER_FREEDO_X_DATA = "SET_USER_FREEDO_X_DATA",
  SET_USER_FREEDO_RENTALS_DATA = "SET_USER_FREEDO_RENTALS_DATA",
  CHECK_FREEDO_DOMAIN = "CHECK_FREEDO_DOMAIN",

  SET_USER_NEW_DATA = "SET_USER_NEW_DATA",
  SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA",
  USER_FORGOT_PASSWORD_REQUESTED = "USER_FORGOT_PASSWORD_REQUESTED",
  USER_FORGOT_PASSWORD_SUCCEEDED = "USER_FORGOT_PASSWORD_SUCCEEDED",
  USER_FORGOT_PASSWORD_FAILED = "USER_FORGOT_PASSWORD_FAILED",

  //---> in case of password expiry 60 days
  CHANGE_PASSWORD_REQUESTED = "USER_CHANGE_PASSWORD_REQUESTED",
  CHANGE_PASSWORD_SUCCEEDED = "USER_CHANGE_PASSWORD_SUCCEEDED",
  CHANGE_PASSWORD_FAILED = "USER_CHANGE_PASSWORD_FAILED",

  USER_SET_PASSWORD_REQUESTED = "USER_SET_PASSWORD_REQUESTED",
  USER_SET_PASSWORD_SUCCEEDED = "USER_SET_PASSWORD_SUCCEEDED",
  USER_SET_PASSWORD_FAILED = "USER_SET_PASSWORD_FAILED",

  MULTIFACTOR_AUTHENTICATION_REQUESTED = "MULTIFACTOR_AUTHENTICATION_REQUESTED",
  MULTIFACTOR_AUTHENTICATION_SUCCEEDED = "MULTIFACTOR_AUTHENTICATION_SUCCEED",
  MULTIFACTOR_AUTHENTICATION_FAILED = "MULTIFACTOR_AUTHENTICATION_FAILED",

  USER_ACCOUNT_UNLOCK_REQUESTED = "USER_ACCOUNT_UNLOCK_REQUESTED",
  USER_ACCOUNT_UNLOCK_SUCCEEDEED = "USER_ACCOUNT_UNLOCK_SUCCEED",
  USER_ACCOUNT_UNLOCK_FAILED = "USER_ACCOUNT_UNLOCK_FAILED",

  REDIRECTION_LINK_VERIFICATION_REQUESTED = "REDIRECTION_LINK_VERIFICATION_REQUESTED",
  REDIRECTION_LINK_VERIFICATION_SUCCEEDED = "REDIRECTION_LINK_VERIFICATION_SUCCEEDED",
  REDIRECTION_LINK_VERIFICATION_FAILED = "REDIRECTION_LINK_VERIFICATION_FAILED",

  SHOW_POPUP_MESSAGE = "SHOW_POPUP_MESSAGE",

  IS_USER_CHANGED_PASSWORD = "IS_USER_CHANGED_PASSWORD",

  CHECK_ISLOGIN = "CHECK_ISLOGIN",

  RESEND_OTP_REQUESTED = "RESEND_OTP_REQUESTED",
  RESEND_OTP_SUCCEEDED = "RESEND_OTP_SUCCEEDED",
  RESEND_OTP_FAILED = "RESEND_OTP_FAILED",

  GET_AUTHORIZE_MODEL_REQUESTED = "GET_AUTHORIZE_MODEL_REQUESTED",
  GET_AUTHORIZE_MODEL_SUCCESS = "GET_AUTHORIZE_MODEL_SUCCESS",
  GET_AUTHORIZE_MODEL_FAILED = "GET_AUTHORIZE_MODEL_FAILED",

  SET_AUTH_REDUCER_KEY = "SET_AUTH_REDUCER_KEY",
}

export enum PROFILE_API_ACTION {
  USER_PROFILE_REQUESTED = "USER_PROFILE_REQUESTED",
  USER_PROFILE_SUCCEEDED = "USER_PROFILE_SUCCEEDED",
  USER_PROFILE_FAILED = "USER_PROFILE_FAILED",
  FETCH_USER_PROFILE_DATA = "FETCH_USER_PROFILE_DATA",
}
