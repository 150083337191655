import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { Box, debounce, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import ResponsiveDialog from "../../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import { DataTable } from "../../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../../utils/helper";
import { BREADCRUMBS_RENTALS } from "../../../../../constants/activeModule";
import {
  LOB_TYPES,
  vehicleCategoryName,
  statusColorCode,
  VehicleStatus,
} from "../../../../../constants/constants";
import {
  CityInputIcon,
  SearchIcon,
  WarehouseIcon,
} from "../../../../../constants/exportImages";
import rentalEn from "../../../../../locale/rental-en.json";
import { rentalActions, vehicleActions } from "../../../../../redux/actions";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../../../../redux/actions/rentalCommonAction";
import { routesConstants } from "../../../../../utils/RoutesConstants";
import styles from "./section.module.css";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../../constants/RBACModuleEnums";
import RbackHelper from "../../../../../../utils/helperRBAC";
import { toastr } from "react-redux-toastr";

const { isAccessRightsProvided } = RbackHelper;

const initialState = {
  paginationModel: { page: 0, pageSize: 10 },
  selectedCity: { name: "", displayName: rentalEn.global.allCities },
  selectedBranch: { name: "", displayName: rentalEn.global.allHubs },
  searchedVehicleNumber: "",
  selectedVehicleIds: [],
  updateType: "",
};

export const ApproveRejectVehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // RBAC object for functionality
  const functionalitiesAccess = {
    isBulkApproveRejectUpdate: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_UPDATE_BULK_APPROVE_REJECT
    ),
    isBulkApproveRejectView: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_APPROVE_REJECT
    ),
    isViewVehicle: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VIEW_VEHICLE
    ),
  };

  const [fields, setFields] = useState({ ...initialState });

  const { onLoad, vehiclesListData, vehiclesListApiResponseData } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );
  const { allCitiesDetails, branches, modelData } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );
  const [dialogData, setDialogData] = useState({
    openDialog: false,
    status: "",
    planId: "",
  });

  //initail render with vehicle list and breadcrumbs
  useEffect(() => {
    getVehiclesList({
      page: fields?.paginationModel?.page,
      pageSize: fields?.paginationModel?.pageSize,
    });

    dispatch(
      updateBreadcrumbs(BREADCRUMBS_RENTALS.BULK_ACTIONS_APPROVE_REJECT_VEHICLE)
    );

    return () => {
      dispatch(clearBreadcrumbs()); // clear the breadcrumbs so that it will not be visible in the main screen
    };
  }, []);

  // Memoize rowCount to keep it stable and avoid page reset.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    vehiclesListApiResponseData?.pageData?.totalCount || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (vehiclesListApiResponseData?.pageData?.totalCount !== undefined) {
      rowCountRef.current = vehiclesListApiResponseData?.pageData?.totalCount;
    }
    return rowCountRef.current;
  }, [vehiclesListApiResponseData?.pageData?.totalCount]);

  //get vehicle list api
  const getVehiclesList = (props: any) => {
    const { page, pageSize, searchedVehicleNumber, cities, branches } = props;
    const payload = {
      status: VehicleStatus.PENDING_FOR_APPROVAL,
      page: page + 1,
      pageSize: pageSize,
      search: searchedVehicleNumber && JSON.stringify(searchedVehicleNumber),
      cities: cities ? [cities] : "",
      branches: branches ? [branches] : "",
    };
    if (functionalitiesAccess.isBulkApproveRejectView)
      dispatch(vehicleActions.getVehiclesList(payload));
  };

  // navigate to vehicle details page
  const navigateVehicleDetails = (id: number) => {
    if (!functionalitiesAccess.isViewVehicle) {
      toastr.warning("", rentalEn.rbacMessages.noAccessVehicleDetails);
      return;
    } else {
      navigate(`${routesConstants.VIEW_VEHICLE}${id}`);
    }
  };

  // table columns
  const columns: any[] = [
    {
      type: "actions",
      field: "modelDisplayName",
      headerName: rentalEn?.tableHeaders?.modelName,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      getActions: (params: any) => {
        return [
          <span onClick={() => navigateVehicleDetails(params?.row?.id)}>
            <Link>{params?.row?.modelDisplayName}</Link>
          </span>,
        ];
      },
    },
    {
      field: "tempRegistration",
      headerName: rentalEn?.tableHeaders?.vehicleNumber,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
    },
    {
      field: "category",
      headerName: rentalEn?.tableHeaders?.vehicleType,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span>
            {" "}
            {params?.row?.category
              ? vehicleCategoryName[
                  params?.row?.category as keyof typeof vehicleCategoryName
                ]
              : rentalEn?.global?.NA}
          </span>
        );
      },
    },
    {
      field: "colourDisplayName",
      headerName: rentalEn?.tableHeaders?.color,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
    },
    {
      field: "cityDisplayName",
      headerName: rentalEn?.tableHeaders?.city,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
    },
    {
      field: "branchDisplayName",
      headerName: rentalEn?.tableHeaders?.hub,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
    },
    {
      field: "lob",
      headerName: rentalEn?.tableHeaders?.lob,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
    },

    {
      field: "tempLobStatus",
      headerName: rentalEn?.tableHeaders?.status,
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              // @ts-ignore,
              color:
                params?.row?.status === VehicleStatus?.ACTIVE
                  ? statusColorCode[
                      params?.row?.lobStatus as keyof typeof statusColorCode
                    ]
                  : statusColorCode[
                      params?.row?.status as keyof typeof statusColorCode
                    ],
            }}
          >
            {params?.row?.tempLobStatus === rentalEn?.global?.NA
              ? params?.row?.tempLobStatus
              : rentalEn.CustomerManagement.displayBookingStatus.PENDING}
          </span>
        );
      },
    },
  ];

  // hangle table pagination
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    getVehiclesList({
      page: page,
      pageSize: pageSize,
      search: fields?.searchedVehicleNumber,
      cities: fields?.selectedCity?.name,
      branches: fields?.selectedBranch?.name,
    });
  };

  const handleChange = (key: string, value: any) => {
    setFields((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((payload: any) => {
      getVehiclesList(payload);
    }, 1000),
    []
  );

  // handle search  on the basis of search vehicle number
  const handleSearch = (e: any) => {
    setFields((prev: any) => ({
      ...prev,
      searchedVehicleNumber: e.target.value,
    }));

    if (e.target.value?.length >= 2 || !e.target.value)
      debouncedSearch({
        page: fields?.paginationModel?.page,
        pageSize: fields?.paginationModel?.pageSize,
        cities: fields?.selectedCity?.name,
        searchedVehicleNumber: e.target.value,
        branches: fields?.selectedBranch?.name,
      });
  };

  // navigate to all vehicle screen
  const navigateToAllVehiclesScreen = () => {
    setTimeout(() => {
      navigate(routesConstants.ALL_VEHICLES);
    }, 1000);

    setDialogData((prev) => {
      return { ...prev, openDialog: !prev.openDialog };
    });
  };

  // handle functionality on save button click
  const handleSaveButtonClick = () => {
    let payload = {
      approveRejectPayload: {
        data: {
          vehicleIds: fields?.selectedVehicleIds,
          decision: fields?.updateType,
        },
      },
      successCallback: navigateToAllVehiclesScreen,
    };

    dispatch(vehicleActions.updateApproveRejectvehicle(payload));
  };

  //toggle update status dialog
  const toggleUpdateStatusDialog = (
    type: any = "",
    updateStatus: boolean = false
  ) => {
    setDialogData((prev) => {
      return { ...prev, openDialog: !prev.openDialog, ...type };
    });
  };

  // on click reset , reset the all fields
  const handleFilterReset = () => {
    setFields({ ...initialState });
    getVehiclesList({
      page: 0,
      pageSize: 10,
    });
    dispatch(rentalActions.clearHubsList());
  };

  return (
    <>
      <Stack gap="16px" marginBottom="60px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color={colors.text_secondary_gray}>
            {
              rentalEn?.VehicleManagement
                ?.operatorUploadVehiclesneedsAcceptedbeforeListing
            }
          </Typography>
          {functionalitiesAccess.isBulkApproveRejectView ? (
            <Tooltip title={rentalEn?.global?.resetFilters} arrow>
              <RotateRightSharpIcon
                fontSize="large"
                color="primary"
                sx={{ cursor: "pointer", width: "27px" }}
                onClick={handleFilterReset}
              />
            </Tooltip>
          ) : null}
        </Box>

        <Box className={styles.vehicleStatusWrapper}>
          <Input
            placeholder={rentalEn?.global?.searchVehicleNumber}
            variant="outlined"
            value={fields?.searchedVehicleNumber}
            iconStart={SearchIcon}
            inputProps={{ maxLength: 50 }}
            onChange={handleSearch}
            disabled={!functionalitiesAccess.isBulkApproveRejectView}
            width={320}
          />
          {/* SELECT CITY */}
          <CustomSelect
            required
            icon={CityInputIcon}
            value={
              isArrayNotEmpty(allCitiesDetails)
                ? fields?.selectedCity?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(allCitiesDetails)
                ? [
                    { name: "", displayName: rentalEn?.global?.allCities },
                    ...allCitiesDetails,
                  ]
                : []
            }
            defaultKey={"displayName"}
            handleChange={(city: any) => {
              handleChange("selectedCity", city);
              getVehiclesList({
                page: fields?.paginationModel?.page,
                pageSize: fields?.paginationModel?.pageSize,
                cities: city?.name,
                searchedVehicleNumber: fields?.searchedVehicleNumber,
              });
              dispatch(rentalActions.getAllBranches({ name: city?.name }));
              setFields((prev: any) => ({
                ...prev,
                selectedBranch: initialState.selectedBranch,
              }));
            }}
            disabled={!functionalitiesAccess.isBulkApproveRejectView}
          />
          {/* SELECT WAREHOUSE */}
          <CustomSelect
            required
            icon={WarehouseIcon}
            placeholder={
              isArrayNotEmpty(branches)
                ? rentalEn?.global?.selectHub
                : rentalEn?.global?.allHubs
            }
            value={
              isArrayNotEmpty(branches)
                ? fields?.selectedBranch?.displayName
                : ""
            }
            choice={
              isArrayNotEmpty(branches)
                ? [
                    { name: "", displayName: rentalEn?.global?.allHubs },
                    ...branches,
                  ]
                : []
            }
            defaultKey={"displayName"}
            disabled={
              functionalitiesAccess.isBulkApproveRejectView
                ? !fields.selectedCity.name || branches?.length === 0
                : true
            }
            handleChange={(branch: any) => {
              handleChange("selectedBranch", branch);
              getVehiclesList({
                page: fields?.paginationModel?.page,
                pageSize: fields?.paginationModel?.pageSize,
                cities: fields?.selectedCity?.name,
                branches: branch?.name,
                searchedVehicleNumber: fields?.searchedVehicleNumber,
              });
            }}
          />
        </Box>
        <DataTable
          columns={columns}
          rows={vehiclesListData}
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={fields.paginationModel}
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            handleChange("selectedVehicleIds", newRowSelectionModel);
          }}
          onRowClick={(params: any) => navigateVehicleDetails(params?.row?.id)}
          onPaginationModelChange={(val: any) => {
            handlePaginationModelChange(val);
          }}
          checkboxSelection={functionalitiesAccess.isBulkApproveRejectUpdate}
          loading={onLoad}
          hasAccess={functionalitiesAccess.isBulkApproveRejectView}
        />

        {/* confirnmation dialog on status change  */}
        <ResponsiveDialog
          title={`Are you sure you want to ${fields?.updateType.toLocaleLowerCase()} the selected vehicles ?`}
          // body={"Are you sure you want to update status?"}
          open={dialogData.openDialog}
          handleClose={() => toggleUpdateStatusDialog("", false)}
          handleYes={() => handleSaveButtonClick()}
          titletextAlign={"center"}
          titleFontSize={"14px"}
          titlestyle={{ padding: "12px 0px" }}
          loading={onLoad}
        />
      </Stack>

      <Box
        sx={{ position: "fixed", bottom: "3px", width: "100%", left: "10%" }}
      >
        <Box className={styles.approveRejectButtons}>
          <CustomButton
            variant="outlined"
            label={rentalEn?.global?.reject}
            onClick={() => {
              toggleUpdateStatusDialog();
              setFields((prev: any) => ({
                ...prev,
                updateType: "REJECT",
              }));
            }}
            disabled={
              functionalitiesAccess.isBulkApproveRejectUpdate
                ? fields?.selectedVehicleIds?.length === 0
                : true
            }
            sx={{ minWidth: "240px" }}
          />
          <CustomButton
            variant="contained"
            label={rentalEn?.global?.approve}
            onClick={() => {
              toggleUpdateStatusDialog();
              setFields((prev: any) => ({
                ...prev,
                updateType: "APPROVE",
              }));
            }}
            disabled={
              functionalitiesAccess.isBulkApproveRejectUpdate
                ? fields?.selectedVehicleIds?.length === 0
                : true
            }
            sx={{ minWidth: "240px" }}
          />
        </Box>
      </Box>
    </>
  );
};
