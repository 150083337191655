import { GenericObject } from "../../../constants/constants";
import { CalculateExtensionPayload } from "../../../interfaces/bookingManagement";
import * as actionTypes from "../actionTypes";

export const getBookingStatistics = () => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_STATS_REQUESTED,
});

export const getBookingDetails = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .RENTALS_GET_BOOKING_DETAILS_REQUESTED,
  payload,
});

export const getBookingList = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_LIST_REQUESTED,
  payload,
});

export const getOdometerHistory = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_VEHICLE_ODOMETER_HISTORY_REQUESTED,
  payload,
});

export const generateDocsSignedUrls = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_BOOKING_DOC_SIGNED_URLS_REQUESTED,
  payload,
});

export const getBookingDocuments = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_BOOKING_DOCUMENTS_REQUESTED,
  payload,
});

export const deleteBookingDocuments = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .DELETE_BOOKING_DOCUMENTS_REQUESTED,
  payload,
});

export const cancelBooking = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CANCEL_BOOKING_REQUESTED,
  payload,
});

// get additional charges
export const getAdditionalCharges = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_ADDITIONAL_CHARGES_REQUESTED,
  payload,
});

export const generateVehicleSignedUrl = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_VEHICLE_SIGNED_URLS_REQUESTED,
  payload,
});

export const getBookingVehicleImages = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_BOOKING_VEHICLE_IMAGES_REQUESTED,
  payload,
});

export const bookingListUsingPhoneNumber = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_BOOKING_LIST_USING_PHONE_REQUESTED,
  payload,
});

// action to get plan and add on details
export const getPlanAndAddOnDetails = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PLAN_ADDON_DETAILS_REQUESTED,
  payload,
});

// action to get modification hitory
export const getModificationHitory = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_MODIFICATION_HISTORY_REQUESTED,
  payload,
});

// get vehicles available for booking
export const getAvailableVehiclesForBooking = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .FETCH_AVAILABLE_VEHICLE_LIST_REQUESTED,
  payload,
});

// assign vehicle to a booking
export const assignVehicle = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.ASSIGN_VEHICLE_REQUESTED,
  payload,
});

// check if booking start ride is delayed or not
export const checkStartRideDelay = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CHECK_START_RIDE_DELAY_REQUESTED,
  payload,
});

// start a ride
export const startRide = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.START_RIDE_REQUESTED,
  payload,
});

// create bulk additional charges
export const createBulkAdditionalCharges = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .SAVE_BULK_ADDITIONAL_CHARGES_REVAMP_REQUESTED,
  payload,
});

/* export const getOperatorConfigByBranch = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED,
  payload,
}); */

export const createOrderForBulkPaymentRevamp = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .CREATE_REVAMP_ORDER_ID_FOR_BULK_PAYMENT_REQUESTED,
  payload,
});

export const cleanUpOrderAndPaymentData = () => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .CLEANUP_REVAMP_ORDER_AND_PAYMENT_DATA,
});

export const waiveOffChargesRevamp = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .WAIVE_OFF_BOOKING_CHARGES_REQUESTED,
  payload,
});

// get waive off reasons
export const getWaiveOffReasonsListRevamp = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_WAIVE_OFF_REASONS_LIST_REVAMP_REQUESTED,
    payload,
  };
};
// get payment overview list data
export const getPaymentOverviewList = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_PAYMENTS_OVERVIEW_LIST_REQUESTED,
  payload,
});

//get extension details for booking
export const getBookingExtensionDetails = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_EXTENSION_AVAILABILITY_DETAILS_REQUESTED,
  payload,
});

//get pending charges in end ride modal
export const getPendingCharges = (payload: GenericObject) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.GET_PENDING_CHARGES_REQUESTED,
  payload,
});

//end ongoing booking
export const endRide = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.END_RIDE_REQUESTED,
  payload,
});

//complete booking
export const completeRide = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_REQUESTED,
  payload,
});

export const completeRidePayment = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.COMPLETE_RIDE_PAYMENT_REQUESTED,
  payload,
});

//clear any reducer data
export const clearReducerKeys = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_BOOKING_REDUCER_DATA,
  payload,
});

// ACTION TO GET EXTENSION PLAN DETAILS
export const getExtensionplansDetails = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_EXTENSION_PLANS_DETAILS_REQUESTED,
  payload,
});

// Action to calculate the bookings extension charges
export const calculateBookingsExtensionCharges = (
  payload: CalculateExtensionPayload
) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_CALCULATED_EXTENSION_CHARGES_REQUESTED,
  payload,
});

// set payment success
export const setPaymentSuccess = () => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.SET_PAYMENT_SUCCESS_LTR,
  };
};

//Get vehicle Extension
export const getVehicleExtension = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GET_VEHICLE_EXTENSION_REQUESTED,
    payload,
  };
};

//generate payment link
export const generatePaymentLinkQRForOrder = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .GENERATE_REVAMP_PAYMENT_LINKQR_FOR_ORDER_REQUESTED,
    payload,
  };
};

// get recent modification history
export const getRecentModificationHistory = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_RECENT_MODIFICATION_HISTORY_REQUESTED,
  payload,
});

// action to  create a extension
export const createBookingExtension = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .CREATE_BOOKING_EXTENSION_REQUESTED,
  payload,
});

// action to  get modification reasons
export const getModificationReasons = (payload = "") => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_MODIFICATION_REASONS_REQUESTED,
  payload,
});

// calculate  adress and addon charges
export const calculateAddressAddOnCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_ADDRESS_CHARGES_REQUESTED,
    payload,
  };
};

// clear modify calculated charges
export const clearModifyCharges = () => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_MODIFY_CHARGES,
  };
};

// modify adress and addon charges api call
export const modifyAddressAddOnCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_ADDRESS_ADDON_REQUESTED,
    payload,
  };
};

// calculate  adress and addon charges
export const calculatePlanAndSlotCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_PLAN_MODIFICATION_REQUESTED,
    payload,
  };
};

// modify adress and addon charges api call
export const modifyPlanAndSlotCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_PLAN_REQUESTED,
    payload,
  };
};

// modify adress and addon charges api call
export const calculateUpgradeModelCharges = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .CALCULATE_MODEL_MODIFICATION_CHARGES_REQUESTED,
    payload,
  };
};

// modify adress and addon charges api call
export const modifyModelUpgrade = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.MODIFY_BOOKING_MODEL_REQUESTED,
    payload,
  };
};

// clear modify data on cleanup
export const clearModifyData = () => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.CLEAR_MODIFICATION_DATA,
  };
};

// modify adress and addon charges api call
export const getAvailableBookingModels = (payload: any) => {
  return {
    type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
      .AVIALABLE_BOOKING_MODEL_REQUESTED,
    payload,
  };
};

//action for Unassign vehicle
export const bookingUnassignedVehicle = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS.UNASSIGN_VEHICLE_REQUESTED,
  payload,
});

// action to delete a booking extension
export const deleteBookingExtension = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .DELETE_BOOKING_EXTENSION_REQUESTED,
  payload,
});

// action to get booking stats v3 for counts in dashboard quick filters
export const getBookingStatsV3 = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .GET_CUSTOMER_BOOKINGS_STATS_V3_REQUESTED,
  payload,
});

// ACTION TO DELETE MODIFICATION
export const deleteModification = (payload: any) => ({
  type: actionTypes.BOOKING_MANAGEMENT_ACTIONS
    .DELETE_BOOKING_MODIFICATION_REQUESTED,
  payload,
});
