import { Box, Stack, Tooltip, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import Input from "../../../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { DataTable } from "../../../../../../../sharedComponents/templates/Tables/dataTable";
import {
  TypeOfTransfer,
  lobArray,
  transferType,
  typographyConstants,
} from "../../../../../../constants/constants";
import {
  CityInputIcon,
  SearchIcon,
  WarehouseIcon,
  lobIcon,
} from "../../../../../../constants/exportImages";
import rentalEn from "../../../../../../locale/rental-en.json";
import { rentalActions } from "../../../../../../redux/actions";
import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import { useMemo, useRef } from "react";

export const SendingDetails = (props: any) => {
  const dispatch = useDispatch();
  const {
    handleChange,
    getVehiclesList,
    allCitiesDetails,
    fields,
    handleSearch,
    branches,
    initialState,
    setFields,
    handlePaginationModelChange,
    columns,
    data,
    selectedRows,
    setSelectedRows,
    errorFields,
    setReceivingDetailsValidation,
    handleFilterReset,
  } = props;

  const { onLoad, vehiclesListData, vehiclesListApiResponseData } = useSelector(
    (state: RootState) => state.rentalsVehicleManagementReducer
  );

  const handleSelectedRow = (data: string[]) => {
    let rows = vehiclesListData?.filter((item: any) => {
      if (data.includes(item.id)) return item;
    });
    setSelectedRows(rows);
  };

  //row count ref
  const rowCountRef: React.MutableRefObject<any> = useRef(
    vehiclesListApiResponseData?.pagination?.total || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (vehiclesListApiResponseData?.pageData?.totalCount !== undefined) {
      rowCountRef.current = vehiclesListApiResponseData?.pageData?.totalCount;
    }
    return rowCountRef.current;
  }, [vehiclesListApiResponseData?.pageData?.totalCount]);

  return (
    <Stack gap="25px">
      <Stack sx={{ backgroundColor: "#01353D0A", padding: "10px 24px 24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant={typographyConstants.HEADING}>
            {rentalEn.VehicleManagement.selectVehicleDetails}
          </Typography>
          <Tooltip title={rentalEn?.global?.resetFilters} arrow>
            <RotateRightSharpIcon
              fontSize="large"
              color="primary"
              sx={{ cursor: "pointer", width: "27px" }}
              onClick={handleFilterReset}
            />
          </Tooltip>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <CustomSelect
              required
              icon={lobIcon}
              placeholder={rentalEn.VehicleManagement.selectTransferType}
              value={fields?.transferType?.displayName}
              choice={transferType}
              defaultKey={"displayName"}
              errormessage={errorFields.transferType}
              handleChange={(branch: any) => {
                handleChange("transferType", branch);
                getVehiclesList({
                  page: fields?.paginationModel?.page,
                  pageSize: fields?.paginationModel?.pageSize,
                  search: fields?.searchedVehicleNumber,
                });

                setFields((prev: any) => ({
                  ...prev,
                  selectedCity: initialState.selectedCity,
                  selectedBranch: initialState.selectedBranch,
                  selectedLob: initialState.selectedLob,
                }));

                setSelectedRows([]);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              placeholder={rentalEn?.global?.vehicleNumber}
              variant="outlined"
              value={fields?.searchedVehicleNumber}
              iconStart={SearchIcon}
              inputProps={{ maxLength: 50 }}
              onChange={handleSearch}
              disabled={false}
              width={222}
            />
          </Grid>

          {fields?.transferType?.name === TypeOfTransfer.BRANCH_TRANSFER ? (
            <>
              <Grid item xs={12} md={3}>
                {/* SELECT CITY */}
                <CustomSelect
                  required
                  icon={CityInputIcon}
                  placeholder={rentalEn.VehicleManagement.selectTransferCity}
                  value={fields?.selectedCity?.displayName}
                  choice={allCitiesDetails}
                  defaultKey={"displayName"}
                  errormessage={errorFields.selectedCity}
                  handleChange={(city: any) => {
                    handleChange("selectedCity", city);
                    setReceivingDetailsValidation(true);
                    getVehiclesList({
                      page: fields?.paginationModel?.page,
                      pageSize: fields?.paginationModel?.pageSize,
                      cities: city?.name,
                      searchedVehicleNumber: fields?.searchedVehicleNumber,
                      lob: fields?.selectedLob?.name,
                    });
                    dispatch(
                      rentalActions.getAllBranches({ name: city?.name })
                    );
                    setFields((prev: any) => ({
                      ...prev,
                      selectedBranch: initialState.selectedBranch,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {/* SELECT WAREHOUSE */}
                <CustomSelect
                  required
                  icon={WarehouseIcon}
                  placeholder={rentalEn.VehicleManagement.selectTransferHub}
                  value={fields?.selectedBranch?.displayName}
                  choice={branches}
                  defaultKey={"displayName"}
                  errormessage={errorFields.selectedBranch}
                  disabled={
                    !fields.selectedCity.displayName || branches?.length === 0
                  }
                  handleChange={(branch: any) => {
                    handleChange("selectedBranch", branch);
                    getVehiclesList({
                      page: fields?.paginationModel?.page,
                      pageSize: fields?.paginationModel?.pageSize,
                      cities: fields?.selectedCity?.name,
                      branches: branch?.name,
                      lob: fields?.selectedLob?.name,
                      searchedVehicleNumber: fields?.searchedVehicleNumber,
                    });
                  }}
                />
              </Grid>
            </>
          ) : fields?.transferType.name === TypeOfTransfer.LOB_TRANSFER ? (
            <Grid item xs={12} md={3}>
              <CustomSelect
                required
                icon={lobIcon}
                placeholder={`*${rentalEn?.global?.selectLob}`}
                value={fields?.selectedLob?.displayName}
                choice={lobArray || []}
                defaultKey={"displayName"}
                errormessage={errorFields.selectedLob}
                handleChange={(value: any) => {
                  // setReceivingLob(value?.name);
                  handleChange("selectedLob", value);
                  getVehiclesList({
                    page: fields?.paginationModel?.page,
                    pageSize: fields?.paginationModel?.pageSize,
                    searchedVehicleNumber: fields?.searchedVehicleNumber,
                    lob: value?.name,
                  });
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Stack>
      <Stack sx={{ marginBottom: "60px" }}>
        {(fields?.selectedCity?.name && fields?.selectedBranch?.name) ||
        fields?.selectedLob?.name ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={typographyConstants.HEADING}>
              Select Transferable Vehicles
            </Typography>
            <Typography variant={typographyConstants.HEADING}>
              Selected Vehicles : {fields?.selectedVehicleIds?.length ?? 0}
            </Typography>
          </Box>
        ) : (
          <></>
        )}

        <DataTable
          columns={columns}
          rows={vehiclesListData}
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={fields.paginationModel}
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            handleChange("selectedVehicleIds", newRowSelectionModel);
            handleSelectedRow(newRowSelectionModel);
          }}
          onPaginationModelChange={(val: any) => {
            handlePaginationModelChange(val);
          }}
          onSelectionModelChange={(ids: any) => {
            const selectedIDs = new Set(ids);
            const selectedRows = data.rows.filter((row: any) =>
              selectedIDs.has(row.id)
            );

            setSelectedRows(selectedRows);
          }}
          rowSelectionModel={selectedRows?.reduce((acc: string[], row: any) => {
            acc.push(row.id);
            return acc;
          }, [])}
          checkboxSelection={
            (fields?.selectedCity?.name && fields?.selectedBranch?.name) ||
            fields?.selectedLob?.name
              ? true
              : false
          }
          loading={onLoad}
        />
      </Stack>
    </Stack>
  );
};
