import { FileDownloadOutlined } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RotateRightSharpIcon from "@mui/icons-material/RotateRightSharp";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid";
import { debounce } from "lodash";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../../../config/filterStorage";
import { LocalStorage } from "../../../../../constants/enum";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../../redux/reducers";
import {
  CustomButton,
  CustomIconButton,
} from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { AntSwitch } from "../../../../../sharedComponents/molecules/MuiSwitch";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import VehicleStatusChangeDrawer from "../../../../../sharedComponents/templates/SideDrawer/VehicleStatusChangeDrawer";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../../utils/helper";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  BLOCKED_REASON,
  filterStorageKeys,
  GenericObject,
  LobStatus,
  newLobStatusArray,
  sorting,
  statusColorCode,
  typographyConstants,
  useCaseArray,
  vehicleCategory,
  VehicleStatus,
  vmsLob,
} from "../../../../constants/constants";
import {
  ApproveRejectIcon,
  BikeIconFaded,
  BulkUploadIcon,
  ChangeStatusIcon,
  CityInputIcon,
  FunctionalitiesIcon,
  SearchIcon,
  StatusIcon,
  TransferVehicleIcon,
  VehicleTypeIcon,
  WarehouseIcon,
} from "../../../../constants/exportImages";
import rentalEn from "../../../../locale/rental-en.json";
import { rentalActions, vehicleActions } from "../../../../redux/actions";
import { formatString, REGEX } from "../../../../utils/helper";
import { routesConstants } from "../../../../utils/RoutesConstants";
import { StyleObject } from "./StyleObject";

const { isAccessRightsProvided } = RbackHelper;

const ExcelJS = require("exceljs");
var saveAs = require("file-saver");

const AllVehicles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { global, tableHeaders, VehicleManagement } = rentalEn;

  // RBAC object for functionality
  const functionalitiesAccess = {
    isVehicleBulkActions: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_BULK_ACTIONS
    ),
    isViewVehicle: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VIEW_VEHICLE
    ),
    isViewVehicleList: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.LIST_VEHICLES
    ),
    isEditvehicleStatus: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.EDIT_VEHICLE_STATUS
    ),
    isAddVehicle: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.ADD_VEHICLE
    ),
    isDownloadVehicleList: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.DOWNLOAD_VEHICLE_LIST
    ),
    isVehicleBulkUpload: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_BULK_UPLOAD
    ),
    isVehicleTransfer: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_TRANSFER
    ),
    isBulkApproveRejectView: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_APPROVE_REJECT
    ),
    isBulkStatusUpdateView: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_VIEW_BULK_STATUS_CHANGE
    ),
  };

  const {
    allCitiesDetails, // list of all cities
    branches, // list of hubs on the basis of city
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  const {
    onLoad,
    vehicleStatusUpdateLoader, // Loader for vehicle (available/blocked) status update
    vehicleDataDownloadLoader, // Loader for vehicle data download on vehicle management dashboard
    vehiclesListData,
    vehiclesListApiResponseData,
    vehicleModelListData,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);
  const { userData } = useSelector((state: RootState) => state.newAuthReducer);

  const initialState: GenericObject = {
    selectedCity: { name: "", displayName: global?.allCities },
    selectedBranch: { name: "", displayName: global?.allHubs },
    selectedVehicle: { name: "", displayName: global?.allModels },
    selectedCategory: {
      name: "",
      displayName: global?.allCategories,
    }, // SCOOTER/MOTORCYCLE
    selectedUseCase: { name: "", displayName: global?.allUseCases }, // RENTALS/ETR
    selectedLobStatus: { name: "", displayName: global?.allStatus }, // AVAILABLE/ENGAGED/BLOCKED/ASSIGNED
    paginationModel: { page: 0, pageSize: 10 },
    searchedVehicleNumber: "",
    selectedLob: { name: vmsLob?.LTR, displayName: vmsLob?.LTR },
  };

  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State to manage the anchor element for the menu
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerData, setDrawerData] = useState<GenericObject>({});
  const [dialogData, setDialogData] = useState<GenericObject>({
    openDialog: false,
    rowData: {},
  });

  // Memoize rowCount to keep it stable and avoid page reset.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    vehiclesListApiResponseData?.pageData?.totalCount || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (vehiclesListApiResponseData?.pageData?.totalCount !== undefined) {
      rowCountRef.current = vehiclesListApiResponseData?.pageData?.totalCount;
    }
    return rowCountRef.current;
  }, [vehiclesListApiResponseData?.pageData?.totalCount]);

  // Function to construct payload for fetching vehicle list data
  const constructVehicleListPayload = (
    fields: GenericObject
  ): GenericObject => {
    const vehicleListPayload: GenericObject = {
      page: fields?.paginationModel?.page + 1, // mui data grid page starts from zero, hence adding +1 to it
      pageSize: fields?.paginationModel?.pageSize,
      sort: [sorting?.createdAt_desc],
      cities: fields?.selectedCity?.name ? [fields?.selectedCity?.name] : null,
      branches: fields?.selectedBranch?.name
        ? [fields?.selectedBranch?.name]
        : null,
      modelNames: fields?.selectedVehicle?.name
        ? [fields?.selectedVehicle?.name]
        : null,
      useCase: fields?.selectedUseCase?.name
        ? [fields?.selectedUseCase?.name]
        : null,
      category: fields?.selectedCategory?.name
        ? [fields?.selectedCategory?.name]
        : null,
      lobStatus: Object.values(LobStatus)?.includes(
        fields?.selectedLobStatus?.name
      )
        ? [fields?.selectedLobStatus?.name]
        : null,
      search: fields?.searchedVehicleNumber
        ? JSON.stringify(fields?.searchedVehicleNumber)
        : "",
      status: Object.values(LobStatus)?.includes(
        fields?.selectedLobStatus?.name
      )
        ? VehicleStatus?.ACTIVE
        : fields?.selectedLobStatus?.name,
      // lob: LOB_TYPES?.LTR,
      lob: fields?.selectedLob?.name,
    };
    return vehicleListPayload;
  };

  useEffect(() => {
    // Function to fetch filter data from local storage
    const fetchFilterData = async () => {
      try {
        const data = await getFilterDataFromLocalStorage(
          filterStorageKeys?.ALL_VEHICLES_DASHBOARD
        );
        if (data) {
          setFields((prev: any) => ({ ...prev, ...data }));

          // Fetch branches list
          if (data?.selectedCity?.name) {
            dispatch(
              rentalActions.getAllBranches({ name: data?.selectedCity?.name })
            );
          }

          // Fetch vehicle models (from the data available in local storage)
          const newPayload: GenericObject = {
            cities: data?.selectedCity?.name
              ? [data?.selectedCity?.name]
              : null,
            branches: data?.selectedBranch?.name
              ? [data?.selectedBranch?.name]
              : null,
            types: data?.selectedCategory?.name
              ? data?.selectedCategory?.name
              : null,
          };
          if (
            data?.selectedCity?.name ||
            data?.selectedBranch?.name ||
            data?.selectedCategory?.name
          ) {
            dispatch(vehicleActions.fetchVehicleModelListData(newPayload));
          } else {
            dispatch(vehicleActions.fetchVehicleModelListData());
          }

          // Fetch vehicles list
          if (functionalitiesAccess.isViewVehicleList) {
            const payload: GenericObject = constructVehicleListPayload(data);
            payload.page = data?.paginationModel?.page + 1 || 1;
            dispatch(vehicleActions.getVehiclesList(payload));
          }
        } else {
          // Fetch the vehicle models
          dispatch(vehicleActions.fetchVehicleModelListData());

          // Fetch vehicles list with the default parameters
          const payload: GenericObject = constructVehicleListPayload(fields);
          payload.page = 1;
          dispatch(vehicleActions.getVehiclesList(payload));
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Initial api calls on component mount
    fetchFilterData();
  }, []);

  // MUi data grid table columns
  const columns: GenericObject[] = [
    {
      field: "modelDisplayName",
      headerName: tableHeaders?.modelName,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempRegistration",
      headerName: tableHeaders?.vehicleNumber,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "utilization",
      headerName: tableHeaders?.utilizationPercent,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempUseCase",
      headerName: tableHeaders?.functionality,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cityDisplayName",
      headerName: tableHeaders?.city,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "branchDisplayName",
      headerName: tableHeaders?.freedoHub,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempOperatorId",
      headerName: tableHeaders?.addedBy,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      type: "actions",
      field: "tempLobStatus",
      headerName: tableHeaders?.status,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params: any) => {
        return [
          <Tooltip
            title={
              params?.row?.tempLobStatus === LobStatus?.BLOCKED
                ? formatString(params?.row?.blockedReason)
                : null
            }
            arrow
          >
            <Box component="span" sx={StyleObject?.spanStyle1}>
              <Box
                component="span"
                sx={{
                  color:
                    params?.row?.status === VehicleStatus?.ACTIVE
                      ? statusColorCode[
                          params?.row.lobStatus as keyof typeof statusColorCode
                        ]
                      : statusColorCode[
                          params?.row.status as keyof typeof statusColorCode
                        ],
                }}
              >
                {params?.row?.tempLobStatus ===
                VehicleStatus?.PENDING_FOR_APPROVAL
                  ? formatString(params?.row?.tempLobStatus?.substring(0, 7))
                  : formatString(params?.row?.tempLobStatus)}
              </Box>
              {functionalitiesAccess.isEditvehicleStatus ? (
                <>
                  {(params?.row?.tempLobStatus === LobStatus?.AVAILABLE ||
                    params?.row?.tempLobStatus === LobStatus?.BLOCKED ||
                    params?.row?.tempLobStatus === LobStatus?.ENGAGED) &&
                    params?.row?.blockedReason !==
                      BLOCKED_REASON?.UNDER_RECOVERY && (
                      <AntSwitch
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          params?.row?.tempLobStatus === LobStatus?.AVAILABLE ||
                          params?.row?.tempLobStatus === LobStatus?.ENGAGED
                            ? true
                            : false
                        }
                        onChange={() => vehicleStatusSwitchHandler(params?.row)}
                        //@ts-ignore
                        color={
                          statusColorCode[
                            params?.row
                              .tempLobStatus as keyof typeof statusColorCode
                          ]
                        }
                      />
                    )}
                </>
              ) : null}
            </Box>
          </Tooltip>,
        ];
      },
    },
  ];

  // handle data and call apis accordingly
  const handleChange = (key: string, value: any) => {
    // Prepare a data object to update multiple fields at once
    let updateFields: GenericObject = {};

    // Handle different cases based on the key
    if (key === "selectedCity") {
      // Reset branch and vehicle model state
      updateFields = {
        selectedBranch: initialState?.selectedBranch,
        selectedVehicle: initialState?.selectedVehicle,
        selectedCategory: initialState?.selectedCategory,
      };

      // Call branches API if selectedCity is available
      if (value?.name) {
        dispatch(rentalActions.getAllBranches({ name: value.name }));
      } else {
        dispatch(rentalActions.clearBranchesAndModels()); // Clear branches and modelListByBranch state from reducer
      }
    } else if (key === "selectedBranch") {
      // Reset vehicle model state
      updateFields = {
        selectedVehicle: initialState?.selectedVehicle,
      };
    } else if (key === "selectedCategory") {
      // Reset vehicle model state
      updateFields = {
        selectedVehicle: initialState?.selectedVehicle,
      };
    }

    // Always update the state with the new key-value pair and additional data
    const newFields: GenericObject = {
      ...fields,
      [key]: value,
      paginationModel: initialState?.paginationModel,
      ...updateFields,
    };
    setFields(newFields);

    // Store updated data in local storage on user selections
    const data: GenericObject = {
      allVehiclesDashboard: { ...newFields },
    };
    delete data.allVehiclesDashboard.searchedVehicleNumber;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);

    // Construct payload and Fetch vehicles list data
    if (functionalitiesAccess.isViewVehicleList) {
      const payload: GenericObject = constructVehicleListPayload(newFields);
      dispatch(vehicleActions.getVehiclesList(payload));
    }

    // Make API call for models if necessary
    const keyArray: string[] = [
      "selectedCity",
      "selectedBranch",
      "selectedCategory",
    ];
    if (keyArray?.includes(key)) {
      // Construct payload for API call based on selected city or branch or types
      let payload: GenericObject = {
        cities:
          key === "selectedCity" && value?.name
            ? [value?.name]
            : newFields?.selectedCity?.name
            ? [newFields?.selectedCity?.name]
            : null,
        branches:
          key === "selectedBranch" && value?.name
            ? [value.name]
            : newFields?.selectedBranch?.name
            ? [newFields?.selectedBranch?.name]
            : null,
        types:
          key === "selectedCategory" && value?.name
            ? value?.name
            : newFields?.selectedCategory?.name
            ? newFields?.selectedCategory?.name
            : null,
      };

      // Fetch vehicle models
      dispatch(vehicleActions.fetchVehicleModelListData(payload));
    }
  };

  // Event handler to handle click on the button to open the menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Event handler to handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle paginationModel changes here
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: GenericObject) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    // Store updated data in local storage on pagination change
    const data: GenericObject = {
      allVehiclesDashboard: {
        ...fields,
        paginationModel: { page: page, pageSize: pageSize },
      },
    };
    delete data.allVehiclesDashboard.searchedVehicleNumber;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);

    // Fetch vehicles list
    if (functionalitiesAccess.isViewVehicleList) {
      const payload: GenericObject = constructVehicleListPayload(fields);
      payload.page = page + 1;
      payload.pageSize = pageSize;
      dispatch(vehicleActions.getVehiclesList(payload));
    }
  };

  // handle filter-reset here
  const handleFilterReset = () => {
    dispatch(rentalActions.clearBranchesAndModels()); // Clear branches and modelListByBranch state from reducer
    // dispatch(vehicleActions.clearReducerStates()); // Clear vehicleModelListData state from reducer
    setFields({ ...initialState }); // Set fields to it's initial state

    // Store initial state data in local storage
    const data: GenericObject = {
      allVehiclesDashboard: { ...initialState },
    };
    delete data.allVehiclesDashboard.searchedVehicleNumber;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, data);

    dispatch(vehicleActions.fetchVehicleModelListData());

    // Construct payload and fetch vehicle list data based on intitial state
    if (functionalitiesAccess.isViewVehicleList) {
      const payload: GenericObject = constructVehicleListPayload(initialState);
      dispatch(vehicleActions.getVehiclesList(payload));
    }
  };

  // Event handler for vehicle status switch
  const vehicleStatusSwitchHandler = (selectedRowData: any) => {
    if (
      selectedRowData?.tempLobStatus === LobStatus?.AVAILABLE ||
      selectedRowData?.tempLobStatus === LobStatus?.ENGAGED
    ) {
      setDrawerData({ ...selectedRowData });
      setOpenDrawer(true);
    } else {
      // make api call here for the vehicle status change // for BLOCKED status
      const data: GenericObject = {
        ids: [selectedRowData?.id],
        status: LobStatus?.AVAILABLE,
      };
      setDialogData({ ...dialogData, openDialog: true, rowData: data });
    }
  };

  // Debounced search function using useCallback to memoize the function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string, fields: GenericObject) => {
      // Fetch vehicles list
      if (functionalitiesAccess.isViewVehicleList) {
        const payload: GenericObject = constructVehicleListPayload(fields);
        payload.search = searchQuery ? JSON.stringify(searchQuery) : "";
        dispatch(vehicleActions.getVehiclesList(payload));
      }
    }, 1000), // Debounce time set to 1000ms (1 second)
    [fields?.searchedVehicleNumber]
  );

  // Handle search input change
  const handleSearch = (e: any) => {
    // Validate alphanumeric input before updating state & searching
    if (REGEX?.strictAlphaNumeric?.test(e.target.value)) {
      // Update state with valid searched text
      setFields((prev: any) => ({
        ...prev,
        paginationModel: initialState?.paginationModel,
        searchedVehicleNumber: e.target.value,
      }));
      // Execute debounced search if length of search query is 2 or more
      if (e.target.value?.length >= 2 || !e.target.value) {
        debouncedSearch(e.target.value, {
          ...fields,
          paginationModel: initialState?.paginationModel,
          searchedVehicleNumber: e.target.value,
        });
      }
    }
  };

  // Handle vehicle status update (block to available) success
  const handleVehicleStatusUpdateSuccess = () => {
    // Fetch vehicles list
    if (functionalitiesAccess.isViewVehicleList) {
      const payload: GenericObject = constructVehicleListPayload(fields);
      dispatch(vehicleActions.getVehiclesList(payload));
    }

    setOpenDrawer(false); // Close the side drawer
  };

  // Handle dialog's yes button click
  const yesHandler = () => {
    // Construct payload
    const payload: GenericObject = {};
    payload.data = { data: dialogData?.rowData };
    payload.apiSuccessFunction = handleVehicleStatusUpdateSuccess;
    // Make api call
    dispatch(vehicleActions.updateVehicleStatus(payload));
    setDialogData({ ...dialogData, openDialog: false });
  };

  // Callback handler for downloading the vehicle list data on the vehicle management dashboard
  const downloadVehicleListHandler = (vehicleList1000: GenericObject[]) => {
    // Create a new Excel workbook instance
    const workBook = new ExcelJS.Workbook();

    // Add a worksheet named "VehicleDetails" to the workbook
    const workSheet = workBook?.addWorksheet("VehicleDetails");

    // Define columns for the worksheet
    workSheet.columns = [
      { header: tableHeaders?.vehicleId, key: "id" },
      { header: tableHeaders?.modelName, key: "modelDisplayName" },
      { header: tableHeaders?.vehicleNumber, key: "tempRegistration" },
      { header: tableHeaders?.utilizationPercent, key: "utilization" },
      { header: tableHeaders?.functionality, key: "useCase" },
      { header: tableHeaders?.city, key: "cityDisplayName" },
      { header: tableHeaders?.freedoHub, key: "branchDisplayName" },
      { header: tableHeaders?.addedBy, key: "operatorId" },
      { header: tableHeaders?.status, key: "tempLobStatus" },
      { header: tableHeaders?.blockReason, key: "tempBlockedReason" },
    ];

    // Add rows of vehicle data to the worksheet
    workSheet?.addRows(vehicleList1000);

    // Write the workbook to a buffer and then create a Blob from the buffer
    workBook.xlsx.writeBuffer().then((buffer: any) =>
      // Save the Blob as a file named "VehicleManagementReport.xlsx"
      saveAs(new Blob([buffer]), `VehicleManagementReport.xlsx`)
    );
  };

  // Function to handle downloading vehicle report
  const handleDownload = () => {
    const payload: GenericObject = {};
    payload.data = constructVehicleListPayload(fields);
    payload.data.search =
      fields?.searchedVehicleNumber?.length >= 2 &&
      fields?.searchedVehicleNumber;
    payload.data.page = 1;
    payload.data.pageSize = 1000;
    payload.downloadVehicleListResponseCallback = downloadVehicleListHandler;

    // Fetch vehicles list (for downloading on the vehicle management dashboard)
    dispatch(vehicleActions.getDownloadableVehicleListData(payload));
  };

  // Handler for row click event in the data grid
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (!functionalitiesAccess.isViewVehicle) {
      return;
    }
    const { row } = params;
    navigate(`${routesConstants.VIEW_VEHICLE}${row.id}`);
  };

  // Handle add new vehicle button click
  const addNewVehicleButtonHandler = () => {
    dispatch(vehicleActions.clearReducerStates()); // Clear add new vehicle details data state from reducer before navigation
    navigate(routesConstants?.ADD_EDIT_VEHICLE); // Navigate to add new vehicle page
  };

  const BulkActionArr = [
    {
      img: BulkUploadIcon,
      label: global?.bulkUpload,
      link: routesConstants.BULK_UPLOAD,
      hasAccess: functionalitiesAccess.isVehicleBulkUpload,
    },
    {
      img: ApproveRejectIcon,
      label: VehicleManagement?.approveAndReject,
      link: routesConstants.APPROVE_REJECT_VEHICLE,
      hasAccess: functionalitiesAccess.isBulkApproveRejectView,
    },
    {
      img: ChangeStatusIcon,
      label: VehicleManagement?.changeStatus,
      link: routesConstants.VEHICLE_STATUS_UPDATE,
      hasAccess: functionalitiesAccess.isBulkStatusUpdateView,
    },
    {
      img: TransferVehicleIcon,
      label: VehicleManagement?.tranferVehicle,
      link: routesConstants.VEHICLE_TRANSFER_HISTORY,
      hasAccess: functionalitiesAccess.isVehicleTransfer,
    },
  ];

  return (
    <Fragment>
      {
        <VehicleStatusChangeDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          toggleDrawer={(newOpen: boolean) => () => setOpenDrawer(newOpen)}
          entireRowData={drawerData}
          handleVehicleStatusUpdateSuccess={handleVehicleStatusUpdateSuccess}
          loading={vehicleStatusUpdateLoader}
        />
      }
      <Stack direction="column" spacing={2}>
        {/* Top Button Section */}
        <Stack
          direction={["column", "row"]}
          justifyContent="space-between"
          alignItems={["flex-start", "center"]}
          rowGap={1}
        >
          {/* Add New Vehicle with RBAC*/}
          {functionalitiesAccess.isAddVehicle ? (
            <CustomButton
              label={VehicleManagement?.addNewVehicle}
              variant="outlined"
              sx={{ minWidth: 150, padding: "3px 16px" }}
              onClick={addNewVehicleButtonHandler}
              disabled={!functionalitiesAccess.isAddVehicle}
            />
          ) : (
            <Box></Box>
          )}

          {/* Icon-Button that triggers opening the menu */}
          {functionalitiesAccess.isVehicleBulkActions ? (
            <CustomIconButton
              children={<MoreVertIcon />}
              sx={{ backgroundColor: "#cdd6d8" }}
              aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              onClick={handleClick}
              disabled={!functionalitiesAccess.isVehicleBulkActions}
            />
          ) : null}

          {/* Menu component that appears when the button is clicked */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)} // Boolean state to determine if the menu is open based on anchorEl}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* Menu items that appear inside the Menu */}
            {BulkActionArr?.map((action: any) => {
              return (
                <>
                  {action?.hasAccess ? (
                    <MenuItem
                      key={action.label}
                      onClick={() => navigate(action.link)}
                    >
                      <Grid sx={{ display: "flex", gap: "10px" }}>
                        <Grid item xs={4}>
                          <img src={action.img} style={{ width: "18px" }} />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            variant={typographyConstants.SUBHEADING}
                            sx={{
                              padding: "0px",
                              color: colors?.primary_dark_blue2,
                            }}
                          >
                            {action.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </Menu>
        </Stack>

        {/* Filter/Search/Download Section */}
        <Box sx={{ flexGrow: 1, alignItems: "center" }}>
          <Grid container spacing={1}>
            {/* City Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={CityInputIcon}
                placeholder={global?.selectCity}
                value={
                  isArrayNotEmpty(allCitiesDetails)
                    ? fields?.selectedCity?.displayName
                    : ""
                }
                choice={
                  isArrayNotEmpty(allCitiesDetails)
                    ? [
                        { name: "", displayName: global?.allCities },
                        ...allCitiesDetails,
                      ]
                    : []
                }
                handleChange={(value: any) => {
                  handleChange("selectedCity", value);
                }}
                disabled={
                  functionalitiesAccess.isViewVehicleList
                    ? !isArrayNotEmpty(allCitiesDetails)
                    : true
                }
                defaultKey={global?.displayName}
              />
            </Grid>

            {/* Hub Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={WarehouseIcon}
                placeholder={
                  isArrayNotEmpty(branches)
                    ? global?.selectHub
                    : global?.allHubs
                }
                value={
                  isArrayNotEmpty(branches)
                    ? fields?.selectedBranch?.displayName
                    : ""
                }
                choice={
                  isArrayNotEmpty(branches)
                    ? [{ name: "", displayName: global?.allHubs }, ...branches]
                    : []
                }
                handleChange={(value: any) => {
                  handleChange("selectedBranch", value);
                }}
                disabled={
                  functionalitiesAccess.isViewVehicleList
                    ? !fields?.selectedCity?.name || !isArrayNotEmpty(branches)
                    : true
                }
                defaultKey={global?.displayName}
              />
            </Grid>

            {/* Category Dropdowwn */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={VehicleTypeIcon}
                placeholder={global?.selectCategory}
                value={fields?.selectedCategory?.displayName}
                choice={vehicleCategory}
                handleChange={(value: any) => {
                  handleChange("selectedCategory", value);
                }}
                defaultKey={global?.displayName}
                disabled={!functionalitiesAccess.isViewVehicleList}
              />
            </Grid>

            {/* Model Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={BikeIconFaded}
                placeholder={
                  isArrayNotEmpty(vehicleModelListData)
                    ? global?.selectModel
                    : global?.allModels
                }
                value={
                  isArrayNotEmpty(vehicleModelListData)
                    ? fields?.selectedVehicle?.displayName
                    : ""
                }
                choice={
                  isArrayNotEmpty(vehicleModelListData)
                    ? [
                        { name: "", displayName: global?.allModels },
                        ...vehicleModelListData,
                      ]
                    : []
                }
                handleChange={(value: any) => {
                  handleChange("selectedVehicle", value);
                }}
                defaultKey={global?.displayName}
                disabled={
                  functionalitiesAccess.isViewVehicleList
                    ? !isArrayNotEmpty(vehicleModelListData)
                    : true
                }
              />
            </Grid>

            {/* UseCase / Functionality Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={FunctionalitiesIcon}
                placeholder={global?.selectUseCase}
                value={fields?.selectedUseCase?.displayName}
                choice={[
                  { name: "", displayName: global?.allUseCases },
                  ...useCaseArray,
                ]}
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("selectedUseCase", value);
                }}
                disabled={!functionalitiesAccess.isViewVehicleList}
              />
            </Grid>

            {/* Registration/ Vehicle Number Search Box */}
            <Grid item sm={12} md={4} lg={3}>
              <Input
                placeholder={global?.vehicleNumber}
                variant="outlined"
                value={fields?.searchedVehicleNumber}
                iconStart={SearchIcon}
                inputProps={{ maxLength: 14 }}
                onChange={(e: any) => handleSearch(e)}
                disabled={!functionalitiesAccess.isViewVehicleList}
                muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
              />
            </Grid>

            {/* Status Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={StatusIcon}
                placeholder={global?.selectStatus}
                value={fields?.selectedLobStatus?.displayName}
                choice={newLobStatusArray}
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("selectedLobStatus", value);
                }}
                disabled={!functionalitiesAccess.isViewVehicleList}
              />
            </Grid>

            {/* LOB Dropdown */}
            <Grid item sm={12} md={4} lg={3}>
              <CustomSelect
                required
                icon={StatusIcon}
                value={fields?.selectedLob?.displayName}
                choice={[
                  { name: vmsLob?.LTR, displayName: vmsLob?.LTR },
                  { name: vmsLob?.R2O, displayName: vmsLob?.R2O },
                ]}
                defaultKey={global?.displayName}
                handleChange={(value: any) => {
                  handleChange("selectedLob", value);
                }}
                disabled={!functionalitiesAccess.isViewVehicleList}
              />
            </Grid>

            {/* Download Button */}
            <Grid
              item
              sm={12}
              display="flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap="5px"
            >
              {functionalitiesAccess.isDownloadVehicleList ? (
                <CustomButton
                  label={rentalEn?.global?.download}
                  onClick={handleDownload}
                  startIcon={<FileDownloadOutlined />}
                  loading={vehicleDataDownloadLoader}
                  sx={{ minWidth: 130 }}
                />
              ) : null}

              {functionalitiesAccess.isViewVehicleList ? (
                <Tooltip title={global?.resetFilters} arrow>
                  <RotateRightSharpIcon
                    fontSize="large"
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={handleFilterReset}
                  />
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
        </Box>

        {/* MUi Data Grid Table Section */}
        <DataTable
          rows={vehiclesListData}
          columns={columns}
          rowCount={rowCount}
          paginationModel={fields?.paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          paginationMode="server"
          hideFooter={!isArrayNotEmpty(vehiclesListData)}
          onRowClick={handleRowClick}
          loading={onLoad}
          hasAccess={functionalitiesAccess.isViewVehicleList}
        />
      </Stack>

      {/* Confirnmation Dialog For Vehicle Status Change  */}
      <ResponsiveDialog
        title={VehicleManagement?.updateVehicleStatus}
        body={VehicleManagement?.updateVehicleStatusConfirmation}
        open={dialogData?.openDialog}
        handleClose={() => setDialogData({ openDialog: false, rowData: {} })}
        handleYes={yesHandler}
      />
    </Fragment>
  );
};

export default AllVehicles;
