import * as actionTypes from "../actionTypes";

// action to create new surge
export const createSurge = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.CREATE_SURGE_REQUESTED,
    payload,
  };
};

// action to get the surge list depending on the various params
export const getSurgeList = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.GET_SURGE_LIST_REQUESTED,
    payload,
  };
};

// action to update surge status // to make active or inactive
export const updateSurgeStatus = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.UPDATE_SURGE_STATUS_REQUESTED,
    payload,
  };
};

// action to get surge details by the surge id
export const getSurgeById = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.GET_SURGE_BY_ID_REQUESTED,
    payload,
  };
};

// action to update/edit the surge
export const updateSurge = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.UPDATE_SURGE_REQUESTED,
    payload,
  };
};

// action to reset reducer
export const resetReducerStates = (payload: any) => {
  return {
    type: actionTypes.SURGE_ACTIONS.RESET_REDUCER_STATE,
    payload,
  };
};
