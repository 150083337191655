import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import { GoogleMap, Marker } from "@react-google-maps/api";
import InputDatePicker from "../../../../../sharedComponents/atoms/InputFields/InputDatePicker";
import { useEffect, useState } from "react";
import {
  CityInputIcon,
  FullFaceHelmet,
  HalfFaceHelmet,
  KidsHelmet,
  PremiumHelmet,
  stateIcon,
} from "../../../../constants/exportImages";
import {
  HELMETS_TYPES,
  STATUS,
  typographyConstants,
} from "../../../../constants/constants";
import { checkAlfaNumeric, checkNumeric } from "../../../../../utils/regex";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import { colors } from "../../../../../themes/colors";
import en from "../../../../locale/rental-en.json";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { deepCopy } from "../../../../../utils/helper";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Styles from "./section.module.css";
import { GOOGLE_MAPS_API_KEY } from "../../../../../config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  convertToTimeStamp,
  dateFormat,
  timeToDateObject,
  validate,
} from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { createWarehouse } from "../../../../redux/actions/businessManagementActions";
import FullScreenLoader from "../../../../../sharedComponents/molecules/FullScreenLoader";

const additionalHelmets = [
  {
    icon: PremiumHelmet,
    type: HELMETS_TYPES.premium,
    title: "Premium",
  },
  {
    icon: FullFaceHelmet,
    type: HELMETS_TYPES.fullFace,
    title: "Full Face",
  },
  {
    icon: HalfFaceHelmet,
    type: HELMETS_TYPES.halfFace,
    title: "Half Face",
  },
  {
    icon: KidsHelmet,
    type: HELMETS_TYPES.kids,
    title: "Kids",
  },
];
const WarehouseSetup = ({
  states,
  cities,
  fields,
  setFields,
  errorFields,
  setErrorFields,
  date,
  handleFields,
  markerPosition,
  setMarkerPosition,
  editRole,
  warehouseDetail,
  warehouseScreenLoader,
  disableWarehouseFields,
  setButtonDisabled,
  disabledDate,
  hasEditAccess,
}: any) => {
  const [showPinCode, setShowPinCode] = useState<boolean>(false);
  const handleHelemetTypes = (type: any) => {
    let prevSelectedHelmet: any = deepCopy(fields?.helmetTypes);
    if (prevSelectedHelmet.includes(type)) {
      prevSelectedHelmet = prevSelectedHelmet.filter(
        (prevInstant: string) => prevInstant !== type
      );
    } else {
      prevSelectedHelmet = [...prevSelectedHelmet, type];
    }
    handleFields("helmetTypes", prevSelectedHelmet);
  };
  const onMapClick = (event: any) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: parseFloat(newLat), lng: parseFloat(newLng) });
    getAddress(newLat, newLng);
  };

  const getAddress = (lat: any, lng: any) => {
    if (!window.google) {
      console.log("Loading");
      return;
    }
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results: any, status) => {
      if (status === "OK") {
        setMarkerPosition({ lat: lat, lng: lng });
        setFields((prev: any) => {
          return {
            ...prev,
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
            zipcode: results[0]?.address_components.find(
              (addressComponent: any) =>
                addressComponent.types.includes("postal_code")
            )?.short_name,
            formattedAddress: results[0]?.formatted_address,
          };
        });
        if (
          !results[0]?.address_components.find((addressComponent: any) =>
            addressComponent.types.includes("postal_code")
          )?.short_name
        ) {
          setShowPinCode(true);
          setFields((prev: any) => {
            return {
              ...prev,
              zipcode: "",
            };
          });
        } else {
          setShowPinCode(false);
        }
        setButtonDisabled(false);
        setErrorFields({
          ...errorFields,
          formattedAddress: "",
          latitude: "",
          longitude: "",
        });
      } else {
        console.log(`Geocoder failed due to: ${status}`);
      }
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginBottom: "50px",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CustomSelect
              icon={stateIcon}
              placeholder={en.NewBusinessManagement.selectState}
              value={fields?.states.displayName}
              choice={
                Object.keys(warehouseDetail)?.length ? [fields?.states] : states
              }
              defaultKey={"displayName"}
              errormessage={errorFields.states}
              required
              handleChange={(state: any) => {
                handleFields("states", state);
              }}
              readOnly={
                Object.keys(warehouseDetail)?.length || disableWarehouseFields
              }
            />
          </Grid>
          <Grid item xs={3}>
            <CustomSelect
              icon={CityInputIcon}
              value={fields?.city?.displayName}
              placeholder={en.NewBusinessManagement.selectCity}
              choice={
                Object.keys(warehouseDetail)?.length ? [fields?.city] : cities
              }
              defaultKey={"displayName"}
              errormessage={errorFields.city}
              required
              handleChange={(city: any) => {
                handleFields("city", city);
              }}
              disabled={
                (!cities?.length && !Object.keys(warehouseDetail)?.length) ||
                disableWarehouseFields
              }
              readOnly={Object.keys(warehouseDetail)?.length}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name="cityName"
              value={fields.branchName}
              placeholder={en.NewBusinessManagement.branchName}
              //label="Type Branch Name"
              errormessage={errorFields.branchName}
              width="100%"
              onChange={(e: any) => {
                if (checkAlfaNumeric(e.target.value))
                  handleFields("branchName", e.target.value);
              }}
              disabled={disableWarehouseFields}
              inputProps={{ readOnly: !hasEditAccess }}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={fields.hubMapName}
              placeholder={en.NewBusinessManagement.hubMapName}
              errormessage={errorFields.hubMapName}
              width="100%"
              onChange={(e: any) => {
                if (
                  checkAlfaNumeric(e.target.value) &&
                  e.target.value.length <= 17
                )
                  handleFields("hubMapName", e.target.value);
              }}
              disabled={disableWarehouseFields}
              inputProps={{ readOnly: !hasEditAccess }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginLeft: "20px" }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            className={Styles.hubShadowEffect}
            sx={{
              marginTop: "20px",
              padding: "20px",
            }}
          >
            <Stack sx={{ gap: "20px" }}>
              <GoogleMap
                mapContainerStyle={{
                  height: "250px",
                  width: "100%",
                  //borderRadius: "2rem 2rem 0rem 0rem",
                }}
                center={markerPosition}
                onClick={onMapClick}
                zoom={15}
              >
                <Marker
                  position={markerPosition}
                  draggable={
                    !hasEditAccess || disableWarehouseFields ? false : true
                  }
                  onDragEnd={(e: any) => {
                    const newLat = e?.latLng?.lat();
                    const newLng = e?.latLng?.lng();
                    setMarkerPosition({
                      lat: parseFloat(newLat),
                      lng: parseFloat(newLng),
                    });
                    getAddress(newLat, newLng);
                  }}
                />
              </GoogleMap>
              <Box>
                <ReactGoogleAutocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  className={Styles.autoCompleteInput}
                  options={{
                    types: ["(regions)"],
                  }}
                  placeholder="Select Location"
                  value={fields.formattedAddress}
                  onChange={(e: any) => {
                    setFields({
                      ...fields,
                      formattedAddress: e.target.value,
                      latitude: "",
                      longitude: "",
                    });
                    setShowPinCode(false);
                    setErrorFields({
                      ...errorFields,
                      latitude: "required",
                      longitude: "required",
                    });
                    setButtonDisabled(false);
                  }}
                  onPlaceSelected={(place: any) => {
                    if (place?.geometry?.location)
                      getAddress(
                        place?.geometry?.location?.lat(),
                        place?.geometry?.location?.lng()
                      );
                  }}
                  disabled={!hasEditAccess || disableWarehouseFields}
                />
                {errorFields?.formattedAddress ? (
                  <Typography
                    variant={typographyConstants.ERRORMESSAGE}
                    marginLeft="8px"
                  >
                    {en.errorMessages.requiredField}
                  </Typography>
                ) : errorFields?.latitude || errorFields?.longitude ? (
                  <Typography
                    variant={typographyConstants.ERRORMESSAGE}
                    marginLeft="8px"
                  >
                    {en.errorMessages.validAddress}
                  </Typography>
                ) : null}
              </Box>
              <Input
                name="cityName"
                value={fields.address1}
                placeholder={"Address Line 1"}
                label="Address Line 1"
                errormessage={errorFields.address1}
                width="100%"
                onChange={(e: any) => {
                  handleFields("address1", e.target.value);
                }}
                disabled={disableWarehouseFields}
                inputProps={{ readOnly: !hasEditAccess }}
              />
              <Input
                value={fields.address2}
                placeholder={"Address Line 2"}
                label="Address Line 2"
                errormessage={errorFields.address2}
                width="100%"
                onChange={(e: any) => {
                  handleFields("address2", e.target.value);
                }}
                disabled={disableWarehouseFields}
                inputProps={{ readOnly: !hasEditAccess }}
              />
              <Input
                value={fields.landmark}
                placeholder={en.NewBusinessManagement.enterLandmark}
                label={en.NewBusinessManagement.enterLandmark}
                width="100%"
                onChange={(e: any) => {
                  handleFields("landmark", e.target.value);
                }}
                disabled={disableWarehouseFields}
                inputProps={{ readOnly: !hasEditAccess }}
              />
              {showPinCode && (
                <Input
                  value={fields?.zipcode}
                  placeholder={en.NewBusinessManagement.enterPincode}
                  label={en.NewBusinessManagement.enterPincode}
                  errormessage={errorFields.zipcode}
                  width="100%"
                  onKeyPress={(e: any) => {
                    if (!checkNumeric(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e: any) => {
                    if (checkNumeric(e.target.value)) {
                      if (e.target.value.length <= 6) {
                        handleFields("zipcode", e.target.value);
                      }
                    }
                  }}
                  disabled={disableWarehouseFields}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                className={Styles.hubShadowEffect}
                sx={{
                  display: "flex",
                  gap: "30px",
                  padding: "15px",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Box>
                    <Typography
                      color={colors.text_secondary_gray}
                      fontSize="12px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      {en.NewBusinessManagement.warehouseStartTime}
                    </Typography>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        value={fields?.startTime}
                        onChange={(newValue) => {
                          handleFields("startTime", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            {...params}
                          />
                        )}
                        disabled={disableWarehouseFields}
                        readOnly={!hasEditAccess}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Box>
                    <Typography
                      color={colors.text_secondary_gray}
                      fontSize="12px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      {en.NewBusinessManagement.warehouseEndTime}
                    </Typography>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        value={fields?.endTime}
                        onChange={(newValue) => {
                          handleFields("endTime", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            {...params}
                          />
                        )}
                        disabled={disableWarehouseFields}
                        readOnly={!hasEditAccess}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
              <Box
                className={Styles.hubShadowEffect}
                sx={{
                  padding: "10px",
                }}
              >
                <Typography
                  color={colors.text_secondary_gray}
                  fontSize="12px"
                  fontWeight="500"
                  marginBottom="10px"
                >
                  {en.NewBusinessManagement.selectHelmetType}
                </Typography>
                <Box>
                  <Grid container spacing={0.5}>
                    {additionalHelmets?.map((item) => {
                      return (
                        <Grid item xs={3}>
                          <CustomButton
                            variant={
                              fields.helmetTypes.includes(item.type)
                                ? "contained"
                                : "outlined"
                            }
                            startImageSrc={item.icon}
                            label={item.title}
                            onClick={() => {
                              handleHelemetTypes(item.type);
                            }}
                            //value={}
                            style={{
                              fontSize: "12px",
                              width: "-webkit-fill-available",
                              padding: "0",
                              borderRadius: "20px",
                            }}
                            disabled={!hasEditAccess || disableWarehouseFields}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
              <Box
                className={Styles.hubShadowEffect}
                sx={{
                  padding: "10px",
                }}
              >
                <Typography
                  color={colors.text_secondary_gray}
                  fontSize="12px"
                  fontWeight="500"
                >
                  {en.NewBusinessManagement.homeDeliveryAvailable}
                </Typography>
                <Box sx={{ padding: "0px 10px" }}>
                  <Grid container spacing={0.5}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={fields.homeDeliveryAvailable}
                        onChange={(e: any) => {
                          var value = e.target.value === "true";
                          handleFields("homeDeliveryAvailable", value);
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          disabled={!hasEditAccess}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          disabled={!hasEditAccess}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Box>
              </Box>
              <Box
                className={Styles.hubShadowEffect}
                sx={{
                  padding: "10px",
                }}
              >
                <Typography
                  color={colors.text_secondary_gray}
                  fontSize="12px"
                  fontWeight="500"
                  marginBottom="10px"
                >
                  {en.NewBusinessManagement.selectLaunchDate}
                </Typography>
                <InputDatePicker
                  placeholder="Select Launch Date"
                  name="launchDate"
                  value={date}
                  required
                  width="100%"
                  onChange={(date: any) => {
                    handleFields("launchDate", date);
                  }}
                  shouldDisableDate={(day: any) => {
                    //SHOULD DISABLE BEFORE DATE
                    let disableFromOrUpto = new Date();
                    if (disabledDate) {
                      disableFromOrUpto = new Date(disabledDate);
                    }
                    disableFromOrUpto.setDate(disableFromOrUpto.getDate());

                    //SHOULD DISABLE AFTER 6 MONTHS DATE

                    return day.valueOf() < disableFromOrUpto.valueOf();
                  }}
                  readOnly={!hasEditAccess || disableWarehouseFields}
                  small
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <FullScreenLoader loading={warehouseScreenLoader} />
    </Box>
  );
};
export default WarehouseSetup;
