import { toastr } from "react-redux-toastr";
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import { getStore } from "../../../redux/store";
import { handleMessage, isArrayNotEmpty } from "../../../utils/helper";
import {
  BookingPaymentStatus,
  GenericObject,
  OrderPaymentType,
  paymentStatusDurationPayload,
} from "../../constants/constants";
import { actionInterface } from "../../interfaces/commonInterface";
import rentalEn from "../../locale/rental-en.json";
import * as actionTypes from "../actions/actionTypes";
import rentalCommonServices from "../services/rentalCommonServices";

const getAuthorizeCities = (
  cityList: GenericObject[],
  authorisedCities: string[]
) => {
  const authoriseCities = cityList?.filter((city: any) => {
    return authorisedCities?.includes(city.name);
  });
  return authoriseCities;
};

const getAuthorizeBranches = (
  branchList: GenericObject[],
  authorizedBranches: string[]
) => {
  const authoriseBranches = branchList?.filter((branch: any) => {
    const findBranch = authorizedBranches?.find(
      (item: any) => item.name === branch.name
    );
    if (findBranch) {
      return findBranch;
    }
  });
  return authoriseBranches;
};

// save opeartor object
const getSaveOperatorData = (
  branchName: string,
  dataObj: GenericObject,
  existedMap: any
) => {
  let map = new Map<string, GenericObject>();

  map = existedMap;
  if (!existedMap?.has(branchName)) map.set(branchName, dataObj);

  return map;
};

const { toastTypes } = rentalEn;

function* getAllCities(action: actionInterface): any {
  try {
    const data = yield call(rentalCommonServices.getAllCities, action.payload);
    // getting authorized cities from login data
    const authorizedCitiesData: Array<string> =
      getStore()?.newAuthReducer?.userData?.authorizedCities;

    // getting city list
    const cityListData: Array<object> = isArrayNotEmpty(data.data?.data)
      ? data?.data?.data
      : [];

    // filtering data for RBAC
    const filterCities: Array<object> = cityListData.filter(
      (cityListData: any) => authorizedCitiesData?.includes(cityListData?.name)
    );

    if (data?.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_SUCCESS,
        payload: {
          cityData: filterCities || [],
        },
      });
    } else if (data?.status === 422) {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_FAILED,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_FAILED,
    });
  }
}

function* getBranch(action: actionInterface): any {
  try {
    const data = yield call(rentalCommonServices.getBranch, action.payload);
    // authorized branches from login data
    const authorizedBranchesData =
      getStore().newAuthReducer?.userData?.authorizedBranches;

    // all branch list data
    const branchListData = isArrayNotEmpty(data?.data?.data)
      ? data?.data?.data
      : [];

    // extracting 'name' from the branch list data
    const branchesSet: Set<string> = new Set(
      branchListData.map((branch: { name: string }) => branch?.name)
    );

    // filtering only authorized branches
    const filterBranches = authorizedBranchesData.filter((branch: any) =>
      branchesSet.has(branch?.name)
    );
    if (data?.status === 200) {
      let obj: any = {};
      if (action?.payload?.common) {
        obj.branchesList = { data: filterBranches };
      } else {
        obj.branches = { data: filterBranches };
      }
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_SUCCESS,
        payload: { ...obj },
      });
    } else if (data?.status === 422) {
      toastr.info("info", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_FAILED,
      });
    } else {
      toastr.info("info", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_FAILED,
      });
    }
  } catch (error) {
    toastr.error("error", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_FAILED,
    });
  }
}

// get all the warehouses on the basis of the cities
function* getBranchesbyMultipleCities(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getBranchesbyMultipleCities,
      action.payload
    );
    // authorized branches from login data
    const authorizedBranchesData =
      getStore().newAuthReducer?.userData?.authorizedBranches;

    // all branch list data
    const branchListData = isArrayNotEmpty(data?.data?.data)
      ? data?.data?.data
      : [];

    // extracting 'name' from the branch list data
    const branchesSet: Set<string> = new Set(
      branchListData.map((branch: { name: string }) => branch?.name)
    );

    // filtering only authorized branches
    const filterBranches = authorizedBranchesData.filter((branch: any) =>
      branchesSet.has(branch?.name)
    );
    if (data?.status === 200) {
      let obj: any = {};
      if (action?.payload?.common) {
        obj.branchesList = { data: filterBranches };
      } else {
        obj.branches = { data: filterBranches };
      }
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_SUCCESS,
        // payload: { ...obj },
        payload: {
          branchData: data?.data?.data || [],
          advanceFilterData: action.advanceFilterData ? true : false,
          authoriseBranches: getAuthorizeBranches(
            data?.data?.data || [],
            getStore().newAuthReducer.userData.authorizedBranches
          ),
          hubsByMultipleCities: filterBranches || [],
        },
      });
      //
      if (action?.keyObj?.key === "hubs") {
        action.keyObj.value = filterBranches || [];
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
          payload: action?.keyObj,
        });
      }
      //
    } else if (data?.status === 422) {
      toastr.info("info", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_FAILED,
      });
    } else {
      toastr.info("info", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("error", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_FAILED,
    });
  }
}
// fetch all vehicle models
function* fetchAllModels(action: any): any {
  const data = yield call(rentalCommonServices.getAllModels, action.payload);
  if (data?.status === 200) {
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_MODEL_DATA_SUCCESS,
      payload: {
        modelData: data?.data?.data?.modelDetails || [],
      },
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}

// fetch master plan durations // {{base_url}}/freedo-ltr/plan/v1/master
function* fetchMasterPlanDurations(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getMasterPlanDurations,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      const masterPlanDuration = isArrayNotEmpty(data?.data?.data)
        ? data?.data?.data
        : [];

      if (action.payload) {
        masterPlanDuration.push("Custom");
      }
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_SUCCESS,
        payload: {
          masterPlanDurationsData: masterPlanDuration,
        },
      });
    } else {
      toastr.info(rentalEn?.toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_FAILED,
    });
  }
}

function* getModelListByBranch(action: any): any {
  try {
    // Get authorized branches data from Redux store.
    const authorizedBranchesData: string[] =
      getStore().newAuthReducer?.userData?.authorizedBranches?.map(
        (branch: any) => branch?.name
      );

    const response = yield call(
      rentalCommonServices.getModelListByBranch,
      action?.payload ? action?.payload : { branches: authorizedBranchesData }
    );

    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_SUCCESS,
        payload: response?.data?.data,
        advanceFilterData: action?.advanceFilterData ? true : false,
      });
    } else {
      toastr.info("info", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_FAILED,
        advanceFilterData: action?.advanceFilterData ? true : false,
      });
    }
  } catch (error) {
    toastr.error("error", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_FAILED,
      advanceFilterData: action?.advanceFilterData ? true : false,
    });
  }
}

function* getAllStates(action: any): any {
  try {
    const data = yield call(rentalCommonServices.getAllStates, action.payload);
    //
    // getting authorized states from login data
    const authorizedStatesData: Array<string> =
      getStore()?.newAuthReducer?.userData?.authorizedStates;

    // getting states list
    const stateListData: Array<object> = isArrayNotEmpty(data.data?.data)
      ? data?.data?.data
      : [];

    // filtering data for RBAC
    const filterStates: Array<object> = stateListData?.filter(
      (stateListData: any) =>
        authorizedStatesData?.includes(stateListData?.name)
    );
    //
    if (data?.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_STATES_SUCCESS,
        payload: {
          stateData: filterStates || [],
        },
      });
      //
      if (action?.keyObj?.key === "state") {
        action.keyObj.value = filterStates || [];
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
          payload: action?.keyObj,
        });
      }
      //
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_ALL_STATES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_ALL_STATES_FAILED,
    });
  }
}
// fetch the payment Modes
function* getMasterPaymentModes(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getMasterPaymentModes,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_SUCCESS,
        payload: {
          masterPaymentModesData: data?.data?.data || [],
        },
      });
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_FAILED,
    });
  }
}

function* getCityByState(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getCityByState,
      action.payload
    );
    // getting authorized cities from login data
    const authorizedCitiesData: Array<string> =
      getStore()?.newAuthReducer?.userData?.authorizedCities;

    // getting city list
    const cityListData: Array<object> = isArrayNotEmpty(data.data?.data)
      ? data?.data?.data
      : [];

    // filtering data for RBAC
    const filterCities: Array<object> = cityListData.filter(
      (cityListData: any) => authorizedCitiesData?.includes(cityListData?.name)
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_SUCCESS,
        payload: data?.cityArray ? data.cityArray : filterCities,
      });
      //
      if (action?.keyObj?.key === "city") {
        action.keyObj.value = data?.cityArray ? data.cityArray : filterCities;
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
          payload: action?.keyObj,
        });
      }
      //
    } else {
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_FAILED,
    });
  }
}

function* getCityDetails(action: actionInterface): any {
  try {
    const response = yield call(
      rentalCommonServices.getCityDetails,
      action.payload
    );

    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.RENTALS_GET_CITY_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS.RENTALS_GET_CITY_DETAILS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.RENTALS_GET_CITY_DETAILS_FAILED,
    });
  }
}
//get cancellation reasons
function* getCancellationReasons(action: actionInterface): any {
  try {
    const response = yield call(
      rentalCommonServices.getCancellationReasons,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS
          .GET_BOOKING_CANCELLATION_REASONS_SUCCESS,
        payload: response.data.data.reasons,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS
          .GET_BOOKING_CANCELLATION_REASONS_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_FAILED,
    });
  }
}

//get cancellation charges
function* getCancellationCharges(action: actionInterface): any {
  try {
    const response = yield call(
      rentalCommonServices.getBookingCancellationCharges,
      action.payload
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_SUCCESS,
        payload: response.data.data,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_FAILED,
    });
  }
}

// get master list of charges types
function* getMasterChargesTypeList(action: actionInterface): any {
  try {
    const response = yield call(rentalCommonServices.getMasterChargesService);
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_SUCCESS,
        payload: response.data.data.names,
      });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_FAILED,
      });
    }
  } catch (error) {
    // toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_FAILED,
    });
  }
}

// fetch the payment Modes
function* getOperatorConfigByBranch(action: any): any {
  try {
    const branchName = action.payload.branch;

    const savedOperatorData =
      getStore()?.rentalsCommonReducer?.savedOperatorConfigData;

    if (!savedOperatorData?.has(branchName)) {
      const data = yield call(
        rentalCommonServices.getOperatorConfigByBranch,
        action.payload
      );

      if (data?.status === 200 || data?.status === 201) {
        yield put({
          type: actionTypes.COMMON_ACTIONS
            .GET_OPERATOR_CONFIG_BY_BRANCH_SUCCESS,
          payload: data?.data?.data,
        });

        const operatorData = getSaveOperatorData(
          action?.payload?.branch,
          data?.data?.data,
          savedOperatorData
        );

        yield put({
          type: actionTypes.COMMON_ACTIONS.SAVE_OPERATOR_CONFIG_RESPONSE,
          payload: operatorData,
        });
      } else {
        toastr.error("", handleMessage(data));
        yield put({
          type: actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_FAILED,
        });
      }
    } else {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_SUCCESS,
        payload: savedOperatorData?.get(branchName),
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_FAILED,
    });
  }
}

// Generate payment Link or qr code
function* PaymentLinkGeneration(action: any): any {
  try {
    const response = yield call(
      rentalCommonServices.paymentLinkGeneration,
      action.payload
    );
    if (response.status === 200 || response.status === 201) {
      toastr.success("", handleMessage(response));
      const paymentLinkGenerationData = response?.data?.data;

      yield put({
        type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_SUCCESS,
        payload: paymentLinkGenerationData,
      });

      if (paymentLinkGenerationData.paymentFlow === OrderPaymentType.QR_CODE)
        yield put({
          type: actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_REQUESTED,
          payload: {
            data: { id: paymentLinkGenerationData?.paymentId },
            counter: paymentStatusDurationPayload.counter,
            delayDuration: paymentStatusDurationPayload?.delayDuration,
            sucessCallback: action?.payload?.sucessCallback,
          },
        });
    } else {
      toastr.error("", handleMessage(response));
      yield put({
        type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_FAILED,
    });
  }
}

//get payment status saga
//example payload for payment status:
// payload: {
//   data: { id: paymentLinkGenerationData?.paymentId }, // data send to api call
//   counter: paymentStatusDurationPayload.counter, // how many times you want to call this task
//   delayDuration: paymentStatusDurationPayload?.delayDuration, // delay duration (5s,10s,....)
//   sucessCallback: action?.payload?.sucessCallback, // functionality you want on sucess
// },
function* getPaymentStatusSaga(action: any): any {
  try {
    let response: any;
    let counter: number = 1;
    do {
      response = yield call(
        rentalCommonServices.getPaymentStatus,
        action.payload.data
      );
      yield delay(action?.payload.delayDuration);

      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_UPDATED_PAYMENT_STATUS,
        payload: response?.data?.data?.status,
      });
      if (
        response?.data?.data?.status === BookingPaymentStatus.CANCELLED ||
        counter >= action?.payload.counter
      ) {
        action?.payload?.sucessCallback && action?.payload?.sucessCallback();

        yield put({
          type: actionTypes.COMMON_ACTIONS.CANCEL_PAYMENT_STATUS_SAGA,
        });
        break;
      }

      counter += 1;
    } while (response?.data?.data?.status === BookingPaymentStatus.PENDING);

    if (response?.status === 201) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_SUCCESS,
        payload: response?.data?.data || [],
      });
    } else {
      toastr.error("", handleMessage(response));
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      console.error("Task was cancelled");
    }
  }
}

//call check payament status and cancel the task acc. to our  requiremnet
function* checkPaymentStatusSaga(): any {
  while (true) {
    const paymentRequestedAction = yield take(
      actionTypes.COMMON_ACTIONS.GET_PAYMENT_STATUS_REQUESTED
    );
    const task = yield fork(getPaymentStatusSaga, paymentRequestedAction);
    yield take(actionTypes.COMMON_ACTIONS.CANCEL_PAYMENT_STATUS_SAGA);
    yield cancel(task);
  }
}

//get branch default configuration
function* getBranchDefaultConfig(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getbranchDefaultConfig,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_FAILED,
    });
  }
}

// Get cities based on multiple states
function* getCitiesBasedOnMultipleStatesSaga(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getCitiesBasedOnMultipleStates,
      action.payload
    );
    //
    // getting authorized cities from login data
    const authorizedCitiesData: Array<string> =
      getStore()?.newAuthReducer?.userData?.authorizedCities;

    // getting city list
    const cityListData: Array<object> = isArrayNotEmpty(data.data?.data)
      ? data?.data?.data
      : [];

    // filtering data for RBAC
    const filterCities: Array<object> = cityListData.filter(
      (cityListData: any) => authorizedCitiesData?.includes(cityListData?.name)
    );
    //
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_SUCCESS,
        // payload: data?.data?.data || [],
        payload: { cityData: filterCities || [] },
      });
      //
      if (action?.keyObj?.key === "city") {
        // action.keyObj.value = data?.data?.data || [];
        action.keyObj.value = filterCities || [];
        yield put({
          type: actionTypes.BUSINESS_MANAGEMENT_ACTIONS.HANDLE_CHANGE_SUCCESS,
          payload: action?.keyObj,
        });
      }
      //
    } else {
      yield put({
        type: actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_FAILED,
      });
      toastr.error(toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_FAILED,
    });
    toastr.error(toastTypes?.error, handleMessage(error));
  }
}

// get states from pincode
function* getStatesFromPincode(action: any): any {
  try {
    const data = yield call(
      rentalCommonServices.getStatesFromPincode,
      action.payload
    );

    if (data?.status === 200) {
      yield put({
        type: actionTypes.COMMON_ACTIONS
          .GET_STATES_AND_DISTRICT_BY_PINCODE_SUCCESS,
        payload: {
          data: data?.data?.data?.details || [],
        },
      });
      if (!data?.data?.data?.details.length)
        toastr.error("", handleMessage(rentalEn.errorMessages.validZipcode));
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.COMMON_ACTIONS
          .GET_STATES_AND_DISTRICT_BY_PINCODE_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.COMMON_ACTIONS
        .GET_STATES_AND_DISTRICT_BY_PINCODE_FAILED,
    });
  }
}

function* rentalCommonSaga() {
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_ALL_CITIES_REQUESTED,
    getAllCities
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_REQUESTED,
    getBranch
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_REQUESTED,
    getBranchesbyMultipleCities
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_MODEL_DATA_REQUESTED,
    fetchAllModels
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_MASTER_PLAN_DURATIONS_REQUESTED,
    fetchMasterPlanDurations
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_MODEL_LIST_BY_BRANCH_REQUESTED,
    getModelListByBranch
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_ALL_STATES_REQUESTED,
    getAllStates
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_MASTER_PAYMENT_MODE_REQUESTED,
    getMasterPaymentModes
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_CITY_BY_STATE_REQUESTED,
    getCityByState
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.RENTALS_GET_CITY_DETAILS_REQUESTED,
    getCityDetails
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_REQUESTED,
    getCancellationReasons
  );
  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_REQUESTED,
    getCancellationCharges
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_REQUESTED,
    getMasterChargesTypeList
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED,
    getOperatorConfigByBranch
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED,
    PaymentLinkGeneration
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_REQUESTED,
    getBranchDefaultConfig
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_REQUESTED,
    getCitiesBasedOnMultipleStatesSaga
  );

  yield takeLatest(
    actionTypes.COMMON_ACTIONS.GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED,
    getStatesFromPincode
  );

  yield fork(checkPaymentStatusSaga);
}

export default rentalCommonSaga;
