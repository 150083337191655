import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//import { OutlinedButtonX } from "../../components/muiButtonsX";
//import { ImagePreviewNotAvailable } from "../../constantsX/exportImagesX";
import styles from "../bookingDetails.module.css";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  borderRadius: "2rem",
};

const ImagePreviewModal = (props: any) => {
  //   const [imgError, setImgError] = useState<boolean>(false);
  const handleDownload = async () => {
    const image = await fetch(props?.modalData?.url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = props?.modalData?.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleErr = () => props?.setImgError(true);

  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <>
          <Box sx={style}>
            <Box>
              <img
                src={props.modalData.url}
                width={"auto"}
                height={"auto"}
                style={{
                  borderRadius: "2rem 2rem 0 0",
                  objectFit: "contain",
                  width: "550px",
                  maxHeight: "400px",
                }}
                onError={(e: any) => {
                  e.target.onerror = null;
                  //e.target.src = ImagePreviewNotAvailable;
                  handleErr();
                }}
              ></img>
            </Box>
            <Box className={styles.imagePreviewModal}>
              {props.modalData.name}
            </Box>
            {!props?.imgError && (
              <Box className={styles.downloadButton}>
                <CustomButton
                  variant="outlined"
                  label={"Download Image"}
                  sx={{
                    height: "27px",
                    position: "absolute",
                    bottom: "-75px",
                    left: "-13%",
                    color: colors.white,
                    background: colors.THEME_BLUE,
                    "&:active": {
                      background: colors.THEME_BLUE,
                    },
                  }}
                  onClick={handleDownload}
                />
              </Box>
            )}
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default ImagePreviewModal;
