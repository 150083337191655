//// do add modules which are used in this app as a separte idenetity where the functionalities are independet in every modules
export enum modules {
  BOOKING_MANAGEMENT = "BOOKING_MANAGEMENT",
  CUSTOMER_MANAGEMENT = "CUSTOMER_MANAGEMENT",
  VEHICLE_MANAGEMENT = "VEHICLE_MANAGEMENT",
  ATTENDANCE_MANAGEMENT = "ATTENDANCE_MANAGEMENT",
  PLAN_MANAGEMENT = "PLAN_MANAGEMENT",
  PAYMENT_MANAGEMENT = "PAYMENT_MANAGEMENT",
  STATISTICS = "STATISTICS",
  TASK_MANAGEMENT = "TASK_MANAGEMENT",
  WAREHOUSE_MANAGEMENT = "WAREHOUSE_MANAGEMENT",
  IOT_MANAGEMENT = "IOT_MANAGEMENT",
  DASHBOARD = "DASHBOARD",
  SURGE_MANAGEMENT = "SURGE_MANAGEMENT",
  CITY_MANAGEMENT = "CITY_MANAGEMENT",
  HELMET_CONFIGURATION = "HELMET_CONFIGURATION",
  INVENTORY_MANAGEMENT = "INVENTORY_MANAGEMENT",
  ROLE_MANAGEMENT = "ROLE_MANAGEMENT",
  OPERATOR_MANAGEMENT = "OPERATOR_MANAGEMENT",
  OPERATOR_MY_ACCOUNT = "OPERATOR_MY_ACCOUNT",
  REPORTS = "REPORT_MANAGEMENT",
  MY_PROFILE = "MY_PROFILE",
}

////do add functionalities that exists in  Booking Management and Create Booking
export enum bookingFunctionalities {
  LIST_BOOKINGS = "LIST_BOOKINGS",
  START_RIDE = "START_RIDE",
  CREATE_BOOKING = "CREATE_BOOKING",
  DOWNLOAD_BOOKING_LIST = "DOWNLOAD_BOOKING_LIST",
  VIEW_BOOKING_STATS = "VIEW_BOOKING_STATS",
  BOOKING_DETAILS_TAB = "BOOKING_DETAILS_TAB",
  VEHICLE_DETAILS_TAB = "VEHICLE_DETAILS_TAB",
  PLAN_AND_PAYMENTS_TAB = "PLAN_AND_PAYMENTS_TAB",
  MODIFY_BOOKINGS_TAB = "MODIFY_BOOKINGS_TAB",
  MODIFY_BOOKING = "MODIFY_BOOKING",
  EXTEND_RIDE = "EXTEND_RIDE",
  ADD_CHARGES = "ADD_CHARGES",
  END_RIDE = "END_RIDE",
  CANCEL_BOOKINGS = "CANCEL_BOOKINGS",
  VIEW_VEHICLE_IMAGES = "VIEW_VEHICLE_IMAGES",
  ADD_VEHICLE_IMAGES = "ADD_VEHICLE_IMAGES",
  UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS",
  VIEW_DOCUMENTS = "VIEW_DOCUMENTS",
  COMPLETE_RIDE = "COMPLETE_RIDE",
  ASSIGN_UNASSIGN_VEHICLE = "ASSIGN_UNASSIGN_VEHICLE",
  ODOMETER_HISTORY = "ODOMETER_HISTORY",
  EXTENSION_HISTORY = "EXTENSION_HISTORY",
  EXTRA_CHARGES_LIST = "EXTRA_CHARGES_LIST",
  WAIVE_OFF_CHARGES = "WAIVE_OFF_CHARGES",
  PAY_EXTRA_CHARGES = "PAY_EXTRA_CHARGES",
  PAYMENT_OVERVIEW = "PAYMENT_OVERVIEW",
  PLAN_AND_ADD_ONS_DETAILS = "PLAN_AND_ADD_ONS_DETAILS",
  MODIFY_BOOKING_VEHICLE = "MODIFY_BOOKING_VEHICLE",
  MODIFY_BOOKING_ADD_ON = "MODIFY_BOOKING_ADD_ON",
  MODIFY_BOOKING_PACKAGE_SLOTS = "MODIFY_BOOKING_PACKAGE_SLOTS",
  MODIFY_BOOKING_DELIVERY_MODE = "MODIFY_BOOKING_DELIVERY_MODE",
}

export enum cityManagementFunctionalities {
  LIST_CITIES = "LIST_CITIES",
  VIEW_CITY = "VIEW_CITY",
  ADD_CITY = "ADD_CITY",
  EDIT_CITY = "EDIT_CITY",
}

export enum surgeFunctionalities {
  LIST_SURGE = "LIST_SURGE",
  CREATE_SURGE = "CREATE_SURGE",
  EDIT_SURGE = "EDIT_SURGE",
  VIEW_SURGE = "VIEW_SURGE",
}

//////do add functionalities that exists in Customer Management
export enum CustomerFunctionalities {
  LIST_CUSTOMERS = "LIST_CUSTOMER",
  VIEW_CUSTOMER_MANAGEMENT_STATS = "VIEW_CUSTOMER_MANAGEMENT_STATS",
  VIEW_CUSTOMER_DETAILS = "VIEW_CUSTOMER_DETAILS",
  ADD_CUSTOMER = "ADD_CUSTOMER",
  EDIT_CUSTOMER = "EDIT_CUSTOMER",
  DELETE_CUSTOMER = "DELETE_CUSTOMER",
  BLOCK_CUSTOMER = "BLOCK_CUSTOMER",
  VIEW_CUSTOMER_BOOKING = "VIEW_CUSTOMER_BOOKING",
  EDIT_CUSTOMER_INFO = "EDIT_CUSTOMER_INFO",
  EDIT_CUSTOMER_EMERGENCY_DETAILS = "EDIT_CUSTOMER_EMERGENCY_DETAILS",
  VIEW_CUSTOMER_KYC = "VIEW_CUSTOMER_KYC",
  ADD_CUSTOMER_KYC = "ADD_CUSTOMER_KYC",
  DELETE_USER_KYC = "DELETE_USER_KYC",
  VIEW_KYC_DETAILS = "VIEW_KYC_DETAILS",
  SEARCH_CUSTOMER_DETAILS = "SEARCH_CUSTOMER_DETAILS",
}

//////do add functionalities that exists in attendance module
export enum attendanceFunctionalities {
  MARK_ATTENDANCE = "MARK_ATTENDANCE",
  ATTENDANCE_DETAILS = "ATTENDANCE_DETAILS",
}

//////do add functionalities that exists in Plan and Surge Management
export enum planFunctionalities {
  LIST_PLANS = "LIST_PLANS",
  ADD_PLAN = "ADD_PLAN",
  VIEW_PLAN = "VIEW_PLAN",
  EDIT_PLAN = "EDIT_PLAN",
}

//////do add functionalities that exists in Payment Management
export enum paymentFunctionalities {
  LIST_PAYMENTS = "LIST_PAYMENTS",
  VIEW_BOOKING = "VIEW_BOOKING",
}

//////do add functionalities that exists in stats
export enum statisticsFunctionalities {
  LIST_STATISTICS = "LIST_STATISTICS",
}

//////do add functionalities that exists in Task Management
export enum taskFunctionalities {
  LIST_TAKS = "LIST_TAKS",
  ADD_TASK = "ADD_TASK",
  EDIT_TASK = "EDIT_TASK",
  DELETE_TASK = "DELETE_TASK",
}

//////do add functionalities that exists in Warehouse or say Hub Management
export enum warehouseFunctionalities {
  LIST_WAREHOUSES = "LIST_WAREHOUSES",
  VIEW_WAREHOUSE = "VIEW_WAREHOUSE",
  ADD_WAREHOUSE = "ADD_WAREHOUSE",
  EDIT_WAREHOUSE = "EDIT_WAREHOUSE",
  DELIVERY_CHARGES_LIST = "DELIVERY_CHARGES_LIST",
  ADD_DELIVERY_CHARGES = "ADD_DELIVERY_CHARGES",
  EDIT_DELIVERY_CHARGES = "EDIT_DELIVERY_CHARGES",
}

// functionalities of VEHICLE_MANAGEMENT module
export enum vehicleManagementFunctionalities {
  EDIT_VEHICLE = "EDIT_VEHICLE",
  VEHICLE_BULK_ACTIONS = "VEHICLE_BULK_ACTIONS",
  VIEW_VEHICLE = "VIEW_VEHICLE",
  LIST_VEHICLES = "LIST_VEHICLES",
  DOWNLOAD_VEHICLE_DOCUMENTS = "DOWNLOAD_VEHICLE_DOCUMENTS",
  VIEW_VEHICLE_CALENDER = "VIEW_VEHICLE_CALENDER",
  VIEW_VEHICLE_BOOKING = "VIEW_VEHICLE_BOOKING",
  DELETE_VEHICLE = "DELETE_VEHICLE",
  EDIT_VEHICLE_STATUS = "EDIT_VEHICLE_STATUS",
  ASSIGN_VEHICLE_IOT_DEVICE = "ASSIGN_VEHICLE_IOT_DEVICE",
  VEHICLE_DOWNLOAD_CHALLAN = "VEHICLE_DOWNLOAD_CHALLAN",
  ADD_VEHICLE = "ADD_VEHICLE",
  VEHICLE_LINK_UNLINK_CHALLAN = "VEHICLE_LINK_UNLINK_CHALLAN",
  DOWNLOAD_VEHICLE_LIST = "DOWNLOAD_VEHICLE_LIST",
  VEHICLE_BULK_UPLOAD = "VEHICLE_BULK_UPLOAD",
  VEHICLE_TRANSFER = "VEHICLE_TRANSFER",
  VEHICLE_UPDATE_TRANSFER = "VEHICLE_UPDATE_TRANSFER",
  VEHICLE_VIEW_TRANSFER_DETAILS = "VEHICLE_VIEW_TRANSFER_DETAILS",
  VEHICLE_UPDATE_BULK_APPROVE_REJECT = "VEHICLE_UPDATE_BULK_APPROVE_REJECT",
  VEHICLE_VIEW_BULK_APPROVE_REJECT = "VEHICLE_VIEW_BULK_APPROVE_REJECT",
  VEHICLE_VIEW_BULK_STATUS_CHANGE = "VEHICLE_VIEW_BULK_STATUS_CHANGE",
  VEHICLE_UPDATE_BULK_STATUS_CHANGE = "VEHICLE_UPDATE_BULK_STATUS_CHANGE",
  VEHICLE_DOCUMENT_HISTORY = "VEHICLE_DOCUMENT_HISTORY",
  VEHICLE_DETAILS_IOT = "VEHICLE_DETAILS_IOT",
  VEHICLE_FETCH_CHALLANS_KYC_CART = "VEHICLE_FETCH_CHALLANS_KYC_CART",
  VEHICLE_IOT_PING_HISTORY = "VEHICLE_IOT_PING_HISTORY",
  VEHICLE_DETAILS_IMAGES = "VEHICLE_DETAILS_IMAGES",
}

// functionalities of IOT_MANAGEMENT module
export enum iotManagementFunctionality {
  IOT_MANAGEMENT_LIST = "IOT_MANAGEMENT_LIST",
  ADD_DEVICE = "ADD_DEVICE",
  DEVICE_BULK_ACTIONS = "DEVICE_BULK_ACTIONS",
  VIEW_DEVICE = "VIEW_DEVICE",
  EDIT_DEVICE = "EDIT_DEVICE",
  DEVICE_PING = "DEVICE_PING",
}

// functionalities of DASHBOARD module
export enum dashboardFunctionality {
  DASHBOARD_STATS = "DASHBOARD_STATS",
  DASHBOARD_BOOKING_LIST = "DASHBOARD_BOOKING_LIST",
  DASHBOARD_DOWNLOAD_BOOKING = "DASHBOARD_DOWNLOAD_BOOKING",
  MARK_ATTENDANCE = "MARK_ATTENDANCE",
  ATTENDANCE_DETAILS = "ATTENDANCE_DETAILS",
  BOOKING_STATS = "BOOKING_STATS",
  VEHICLE_STATS = "VEHICLE_STATS",
  USER_STATS = "USER_STATS",
}

//functionality of helmetConfiguration module
export enum helmetConfigurationFunctionality {
  LIST_HELMET_CONFIGURATION = "LIST_HELMET_CONFIGURATION",
  ADD_HELMET_CONFIGURATION = "ADD_HELMET_CONFIGURATION",
  VIEW_HELMET_CONFIGURATION = "VIEW_HELMET_CONFIGURATION",
  EDIT_HELMET_CONFIGURATION = "EDIT_HELMET_CONFIGURATION",
}

// functionalities of Inventory module
export enum inventoryFunctionality {
  LIST_ALL_VEHICLE_INVENTORY = "LIST_ALL_VEHICLE_INVENTORY",
  VIEW_SPECIFIC_DATE_INVENTORY = "VIEW_SPECIFIC_DATE_INVENTORY",
  VIEW_VEHICLE_INVENTORY = "VIEW_VEHICLE_INVENTORY",
  VIEW_INVENTORY_DETAILS = "VIEW_INVENTORY_DETAILS",
  VIEW_VEHICLES_IN_INVENTORY_DETAILS = "VIEW_VEHICLES_IN_INVENTORY_DETAILS",
}

// Functionalities of role management module
export enum roleManagementFunctionality {
  ROLE_MANAGEMENT_LIST = "ROLE_MANAGEMENT_LIST",
  ADD_NEW_USER = "ADD_NEW_USER",
  UPDATE_USER = "UPDATE_USER",
  BLOCK_USER = "BLOCK_USER",
  UNBLOCK_USER = "UNBLOCK_USER",
  DELETE_USER = "DELETE_USER",
}

export enum operatorFunctionality {
  OPERATOR_MY_ACCOUNT_INFO = "OPERATOR_MY_ACCOUNT_INFO",
}

export enum myProfileFunctionality {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

export enum reportsFunctionality {
  GENERATE_EXTRA_CHARGES_REPORT = "GENERATE_EXTRA_CHARGES_REPORT",
  GENERATE_VEHICLE_MODEL_CHANGE_REPORT = "GENERATE_VEHICLE_MODEL_CHANGE_REPORT",
  GENERATE_PAYMENT_REPORT = "GENERATE_PAYMENT_REPORT",
  GENERATE_CHALLAN_REPORT = "GENERATE_CHALLAN_REPORT",
  GENERATE_EXTENSION_REPORT = "GENERATE_EXTENSION_REPORT",
  GENERATE_CANCELLATION_AND_REFUND_REPORT = "GENERATE_CANCELLATION_AND_REFUND_REPORT",
  GENERATE_BOOKING_REPORT = "GENERATE_BOOKING_REPORT",
  GENERATE_USER_REPORT = "GENERATE_USER_REPORT",
}
