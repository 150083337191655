import "../newAuthCss/global.css";
import "../newAuthCss/layout.css";
import "../../../css/customcss/font.css";

function AuthInput(props: any) {
  return (
    <>
      <div>
        <label className={"form-label"}>{props?.label}</label>
        <input
          type={props.type}
          placeholder={props?.placeholder}
          className={props?.className}
          onChange={props?.onChange}
          value={props?.value}
          id={props?.id}
          disabled={props?.disabled}
          autoComplete={"off"}
        ></input>
      </div>
      {props?.errorMessage != "" ? (
        <div className="error-message">{props?.errorMessage}</div>
      ) : null}
    </>
  );
}

function AuthInputPassword(props: any) {
  return (
    <div>
      <label className="form-label">{props.label}</label>
      <div className={"input-group mb-2"}>
        <input
          type={props.type}
          value={props.value}
          placeholder={props?.placeholder}
          className={props?.inputClassName}
          onChange={props?.onChange}
          disabled={props?.disabled}
          id={props?.id}
          autoComplete="new-password"
          onPaste={(e)=>{
            e.preventDefault();
            return  false;
          }}
          onCopy={(e)=>{
            e.preventDefault();
            return false;
          }}

          onCut={(e)=>{
            e.preventDefault();
            return false;
          }}
        />
        {props?.viewPassEye === "true" ? (
          <a className="input-group-text" id="basic-addon2">
            <i
              className={props?.eyeClassName}
              aria-hidden={props.ariaHidden}
              onClick={props.onClick}
            />
          </a>
        ) : null}
      </div>
      {props?.errorMessage != "" ? (
        <div className="error-message">{props?.errorMessage}</div>
      ) : null}
    </div>
  );
}

export { AuthInputPassword, AuthInput };
