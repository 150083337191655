import { FREEDO_X_URL } from "../../config";
import { customAxios } from "../../config/axiosConfig";
import { BookingX, FreedoX, Global } from "../constantsX/apiConstantsX";
import { getAvailableVehicleListPayload } from "../interfaces/bookingInterfaces";

const getAllBookingsX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      BookingX.bookingList;
    url += payload?.page ? `?page=${payload?.page}` : "";
    url += payload?.pageSize
      ? `${payload.page ? "&" : "?"}pageSize=${payload?.pageSize}`
      : "";
    url += payload?.search ? `&search=${payload?.search}` : "";
    url +=
      payload?.status === "ALL"
        ? ""
        : payload?.status
        ? `&status=${payload?.status}`
        : "";
    url += payload?.sorting ? `&sortings=["${payload?.sorting}"]` : "";
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getStatistics = () => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      BookingX.statistics;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const getBookingDetails = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL + BookingX.booking + Global.versioning1 + `/${payload.id}`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

const startRideX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload.bookingId}` +
      BookingX.startRide;
    return customAxios.post(url, payload.data).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

//fetch available vehicle for start ride
const getAvailableVehiclesX = (payload: getAvailableVehicleListPayload) => {
  try {
    let url =
      FREEDO_X_URL +
      Global.globalSearch +
      Global.versioning1 +
      BookingX.vehicles;
    url += `?branches=["${payload.branches}"]`;
    url += `&modelNames=["${payload.modelNames}"]`;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getBookingStatus = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.id}` +
      BookingX.bookingStatus;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const getPaymentPendingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload?.id}/` +
      FreedoX.paymentPendingX;
    return customAxios.get(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const cancelBookingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload}` +
      BookingX.cancelBookingX;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export const closeBookingX = (payload: any) => {
  try {
    let url =
      FREEDO_X_URL +
      BookingX.booking +
      Global.versioning1 +
      `/${payload}` +
      BookingX.closeBookingX;
    return customAxios.put(url).catch((ex) => ex);
  } catch (ex) {
    return ex;
  }
};

export default {
  getAllBookingsX,
  getStatistics,
  getBookingDetails,
  getBookingStatus,
  startRideX,
  getAvailableVehiclesX,
  getPaymentPendingX,
  cancelBookingX,
  closeBookingX,
};
