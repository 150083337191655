import { Box, Card, Stack, Typography } from "@mui/material";
import { MITC_FORM_URL } from "../../../../../../config";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { colors } from "../../../../../../themes/colors";
import { typographyConstants } from "../../../../../constants/constants";
import { dummyBike } from "../../../../../constants/exportImages";
import {
  checkIfImageExists,
  getFormattedINR,
} from "../../../../../utils/helper";
import styles from "./section.module.css";

const CardVehicleModal = (props: any) => {
  const { handleBookingVehicle, item, index } = props;

  // Get image path Url
  let modelImageFilename = "";

  if (item?.modelName)
    if (item?.images?.[0]?.type === "right") {
      modelImageFilename = item?.images?.[0]?.fileName;
    }
  if (!modelImageFilename) {
    modelImageFilename = item?.images?.[0]?.fileName;
  }

  const imagePathUrl = modelImageFilename
    ? `${MITC_FORM_URL}/images/model/${item?.modelName}/${item?.images?.[0].type}-${modelImageFilename}`
    : "";

  return (
    <Card className={styles.vehicleCardMain}>
      <Box>
        {imagePathUrl ? (
          <img
            src={imagePathUrl}
            alt="vehicle img"
            width="208.64px"
            height="133.75px"
            onError={(e: any) => {
              e.target.onError = null;
              e.target.src = dummyBike;
            }}
          />
        ) : (
          <img
            src={dummyBike}
            alt="vehicle img"
            width="208.64px"
            height="133.75px"
          />
        )}
      </Box>
      <Stack width="100%" alignItems="center" gap="24px">
        <Box className={styles.vehicleCardContent}>
          <Stack gap="15px">
            <Stack>
              <Typography
                variant={typographyConstants.SUBHEADING}
                color="#FF3300"
              >
                {item?.manufacturerName}
              </Typography>

              <Typography
                variant={typographyConstants.SUBHEADING}
                color="#0B132B"
              >
                {item?.displayName}
              </Typography>
            </Stack>

            <Box display={"flex"} alignItems={"center"} gap={"4px"}>
              <Typography
                variant={typographyConstants.SUBHEADING}
                color="#1C2541"
              >
                {getFormattedINR(item?.planDetails?.perDayPrice)}/-
              </Typography>
              <Typography variant={typographyConstants.BODY} color="#727272">
                per day
              </Typography>
            </Box>

            {item?.planDetails?.securityDepositRequired ? (
              ""
            ) : (
              <Typography
                variant={typographyConstants.BODY}
                color={colors?.THEME_BLUE}
              >
                {" "}
                Zero deposit fee
              </Typography>
            )}
          </Stack>

          <Typography
            variant={typographyConstants.SUBHEADING}
            color={colors?.THEME_BLUE}
          >
            {" "}
            {getFormattedINR(item?.planDetails?.discountedAmount)}/-
          </Typography>
        </Box>
        <Box sx={{ position: "absolute", bottom: "16px" }}>
          <CustomButton
            label={"Book Vehicle"}
            variant="contained"
            onClick={() => handleBookingVehicle(item)}
          />
        </Box>
      </Stack>
    </Card>
  );
};
export default CardVehicleModal;
