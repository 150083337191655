import * as React from "react";
import {
  Box,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import en from "../../../locale/en.json";
import styles from "../components.module.css";
import { ApplyCouponIcon } from "../../constantsX/exportImagesX";
import { colors } from "../../themes/colors";
import InputX from "../InputFields/InputX";
import { OrderPaymentType } from "../../constantsX/YOR_BookingDetails";
import CloseIcon from "@mui/icons-material/Close";

const SideDrawer = (props: any) => {
  const paymentMethod = ["Payment Link", "QR Code", "Mandate"];
  const [PaymentMethod, setPaymentMethod] = React.useState("Payment Link");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };
  return (
    <Drawer
      anchor="right"
      open={props?.open}
      onClose={props?.toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: "380px",
          background: "#F6FBF9",
          padding: !props.disablePadding ? "12px 26px 34px 26px" : "unset",
          display: "flex",
          justifyContent: "space-between",
        },
      }}
    >
      
      <Box sx={{ position: "absolute", right: "0", top: "0" }} onClick={props?.toggleDrawer}>
        <CloseIcon />
      </Box>
      <Box className={styles.BoxColumn} gap="20px">
        {/* UI render */}
        {props?.heading && (
          <Box
            sx={{
              height: "50px",
              width: "100%",
            }}
          >
            {props?.heading}
          </Box>
        )}
        {props?.renderUI}
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
