import { toastr } from "react-redux-toastr";
import { call, put, takeLatest } from "redux-saga/effects";
import { dashboardGQLService, dashboardService } from "../../services";
import { handleMessage } from "../../utils/helper";
import * as actionTypes from "../actions/actionTypes";

function* dashboardApiSaga(action: any): any {
  try {
    const data = yield call(
      dashboardGQLService.getGqlDashboardStatsService,
      action.payload
    );
    if (data) {
      yield put({
        type: actionTypes.AUTH_ACTIONS.DASHBOARD_STATIC_SUCCEEDED,
        payload: {
          dashboardStats: data?.data?.getStats ?? {},
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.AUTH_ACTIONS.DASHBOARD_STATIC_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* dashboardDisplayTableSaga(action: any): any {
  const data = yield call(
    dashboardService.dashboardDisplayTableService,
    action.payload
  );
  if (data?.status === 200) {
    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_SUCCEEDED,
      payload: {
        ...data.data,
      },
    });
  } else {
    // toastr.error("", handleMessage(data));

    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_FAILED,
    });
  }
}
function* dashboardCsvDownloadSaga(action: any): any {
  const data = yield call(
    dashboardService.dashboardDisplayTableService,
    action.payload
  );
  if (data?.status === 200) {
    yield put({
      type: actionTypes.CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_SUCCESS,
      payload: {
        itemData: data?.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_FAILED,
    });
  }
}
function* dashboardActionClickSaga(action: any): any {
  const data = yield call(
    dashboardService.dashboardActionClickService,
    action.payload
  );
  if (data?.status === 200) {
    let obj: any = {};
    toastr.success("", handleMessage(data));
    if (action?.payload?.rejected) {
      obj.id = data.data?.Result?.bookingData?.id;
      obj.warehouse_status = data.data?.Result?.bookingData?.warehouse_status;
    } else {
      obj.id = data.data.Result.id;
      obj.warehouse_status = data.data.Result.warehouse_status;
    }

    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_SUCCEEDED,
      payload: {
        modefiedData: {
          ...obj,
        },
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_FAILED,
    });
  }
}
function* dashboardViewCalendarSaga(action: any): any {
  const data = yield call(
    dashboardService.dashboardViewCalendarService,
    action.payload
  );

  if (data?.status === 200) {
    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_SUCCEEDED,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_FAILED,
    });
  }
}

function* fetchVehicleUtilizationDates(action: any): any {
  const data = yield call(
    dashboardService.getVehicleUtilizationDates,
    action.payload
  );
  if (data?.status === 200) {
    yield put({
      type: actionTypes.STATISTICS.VEHICLE_UTILIZATION_DATES_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.STATISTICS.VEHICLE_UTILIZATION_DATES_FAILED,
    });
  }
}
function* fetchVehicleUtilization(action: any): any {
  const data = yield call(
    dashboardService.getVehicleUtilization,
    action.payload
  );

  if (data?.status === 200) {
    yield put({
      type: actionTypes.STATISTICS.GET_VEHICLE_UTILIZATION_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.STATISTICS.GET_VEHICLE_UTILIZATION_FAILED,
    });
  }
}

function* fetchPaymentGraph(action: any): any {
  const data = yield call(dashboardService.getPaymentGraph, action.payload);

  if (data?.status === 200) {
    if (action.payload?.is_download === 1) {
      let customData = data.data;
      if (action?.payload?.download_type === 1) {
        customData = customData.replace(/"/g, "");
      }
      yield put({
        type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_SUCCESS,
        payload: customData,
      });
    } else {
      yield put({
        type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_SUCCESS,
        payload: {
          ...data.data,
        },
      });
    }
  } else {
    if (action.payload?.is_download === 1) {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_FAILED,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_FAILED,
      });
    }
  }
}

function* fetchPaymentGraphDateWise(action: any): any {
  const data = yield call(
    dashboardService.getPaymentGraphDateWise,
    action.payload
  );
  if (data?.status === 200) {
    let customData = data?.data ?? "";
    if (action?.payload?.download_type === 1) {
      customData = customData.replace(/"/g, "");
    }
    yield put({
      type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_SUCCESS,
      payload: customData,
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_FAILED,
    });
  }
}

function* fetchRevenueGraph(action: any): any {
  const data = yield call(dashboardService.getRevenueGraph, action.payload);
  if (data?.status === 200) {
    if (action.payload?.is_download === 1) {
      yield put({
        type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_SUCCESS,
        payload: {
          ...data.data,
        },
      });
    }
  } else {
    if (action.payload?.is_download === 1) {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_FAILED,
      });
    } else {
      toastr.error("", handleMessage(data));

      yield put({
        type: actionTypes.STATISTICS.GET_REVENUE_GRAPH_FAILED,
      });
    }
  }
}

function* getAllStatisticsData(action: any): any {
  try {
    const data = yield call(
      dashboardGQLService.getGqlStatisticsService,
      action.payload
    );
    if (data) {
      yield put({
        type: actionTypes.STATISTICS.GET_ALL_STATISTICS_DATA_SUCCESS,
        payload: {
          allStatisticsData: data?.data?.getStatDetails ?? {},
          isDownload: !!action?.payload?.downloadType,
        },
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.STATISTICS.GET_ALL_STATISTICS_DATA_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
  }
}

function* dashboardSaga() {
  yield takeLatest(
    actionTypes.AUTH_ACTIONS.DASHBOARD_STATIC_REQUESTED,
    dashboardApiSaga
  );
  yield takeLatest(
    actionTypes.AUTH_ACTIONS.DASHBOARD_DISPLAY_TABLE_REQUESTED,
    dashboardDisplayTableSaga
  );
  yield takeLatest(
    actionTypes.CSV_DASHBOARD_ACTIONS.CSV_DASHBOARD_REQUESTED,
    dashboardCsvDownloadSaga
  );
  yield takeLatest(
    actionTypes.AUTH_ACTIONS.DASHBOARD_ACTION_CLICK_REQUESTED,
    dashboardActionClickSaga
  );
  yield takeLatest(
    actionTypes.AUTH_ACTIONS.DASHBOARD_VIEW_CALENDAR_REQUESTED,
    dashboardViewCalendarSaga
  );
  yield takeLatest(
    actionTypes.STATISTICS.VEHICLE_UTILIZATION_DATES_REQUESTED,
    fetchVehicleUtilizationDates
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_VEHICLE_UTILIZATION_REQUESTED,
    fetchVehicleUtilization
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_PAYMENT_GRAPH_REQUESTED,
    fetchPaymentGraph
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_REVENUE_GRAPH_REQUESTED,
    fetchRevenueGraph
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DOWNLOAD_REQUESTED,
    fetchPaymentGraph
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_REVENUE_GRAPH_DOWNLOAD_REQUESTED,
    fetchRevenueGraph
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_PAYMENT_GRAPH_DATEWISE_DOWNLOAD_REQUESTED,
    fetchPaymentGraphDateWise
  );
  yield takeLatest(
    actionTypes.STATISTICS.GET_ALL_STATISTICS_DATA_REQUESTED,
    getAllStatisticsData
  );
}

export default dashboardSaga;
