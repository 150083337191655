import { BASE_URL, customAxios, getApiUrl } from "../../../config";
import { constructUrl } from "../../utils/helper";

// api call for rental plan list
const getPaymentList = (payload: any) => {
  try {
    let mainUrl: string = `${BASE_URL}${getApiUrl().getPaymentList}`;
    const apiUrlWithParams: string = constructUrl(mainUrl, payload);
    return customAxios.get(apiUrlWithParams).catch((e) => e);
  } catch (err) {
    return err;
  }
};

//spi call for refund details
const getRefundDetails = (payload: any) => {
  try {
    let url: string = `${BASE_URL}${getApiUrl(payload).getRefundDetails}`;
    return customAxios.get(url).catch((e) => e);
  } catch (err) {
    return err;
  }
};

export default {
  getPaymentList,
  getRefundDetails,
};
