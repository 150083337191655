import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Link, Popover, Skeleton, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { colors } from "../../../../../themes/colors";
import {
  BookingPaymentStatus,
  GenericObject,
  HELMET_DISPLAY_NAME,
  initialPaginationTable,
  LOB_TYPES,
  PaymentFlow,
  statusColorCode,
  typographyConstants,
} from "../../../../constants/constants";
import { PlansAndPayments } from "../../../../interfaces/bookingManagement";
import en from "../../../../locale/rental-en.json";
import {
  getDateTimeFromTimeStamp,
  getFormattedINR,
  paisaToRupee,
} from "../../../../utils/helper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  bookingManagementActions,
  rentalActions,
} from "../../../../redux/actions";
import CustomHoverPopover from "../../../../../sharedComponents/molecules/Popover/HoverPopover";
import { StyleObject } from "./styleObject";
import LinkIcon from "@mui/icons-material/Link";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import RbacHelper from "../../../../../utils/helperRBAC";
import {
  bookingFunctionalities,
  modules,
} from "../../../../../constants/RBACModuleEnums";
import { getrefundDetails } from "../../../../redux/actions/paymentManagementActions";
import SideDrawer from "../../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import RefundDetailsDrawer from "../../../paymentManagement/sections/refundDetailsDrawer";

const { bookingDetails, global } = en;
const {
  paymentStatusDisplay,
  paymentFlowDisplay,
  bookingPlanTypeDisplay,
  bookingChargesCategoriesDisplay,
  bookingChargesSubCategoriesDisplay,
  bookingPaymentType,
} = bookingDetails;

const initialState = {
  page: 0,
  pageSize: 10,
};
const PlanAndPayments = (props: PlansAndPayments) => {
  const {
    bookingId,
    planAndAddonsDataLoader,
    planAndAddonsData,
    paymentOverviewDataLoader,
    paymentOverviewData,
    handlePaymentOptionClick,
    navigateBookingDetailsStep,
    getPaymentOverviewData,
  } = props;

  const RbacHasAccess = {
    paymentOverview: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.PAYMENT_OVERVIEW
    ),
    planAndAddOnsDetails: RbacHelper.isAccessRightsProvided(
      modules.BOOKING_MANAGEMENT,
      bookingFunctionalities.PLAN_AND_ADD_ONS_DETAILS
    ),
  };

  // reference
  const dispatch = useDispatch();

  // operator Config Data
  const {
    operatorConfigData: { allowedPaymentFlows = [] },
  }: any = useSelector((state: RootState) => state.rentalsCommonReducer);

  // Redux selector
  const { refundDetails } = useSelector(
    (state: RootState) => state.paymentManagementReducer
  );

  const {
    planDetails = {},
    paymentBreakUp = {},
    addOns = [],
  } = planAndAddonsData;
  // state to store pagination of payment overview
  const [paymentOverviewPagination, setPaymentOverviewPagination] = useState({
    ...initialState,
  });

  const [toogleRefundDrawer, setToggleRefundDrawer] = useState<boolean>(false);

  // initial side effect
  useEffect(() => {
    RbacHasAccess.planAndAddOnsDetails &&
      dispatch(bookingManagementActions.getPlanAndAddOnDetails(bookingId));
    RbacHasAccess.paymentOverview &&
      getPaymentOverviewData({
        page: initialState?.page,
        pageSize: initialState?.pageSize,
      });
  }, []);

  // plan details cards array
  const planDetailsCardArray = [
    {
      key: bookingDetails?.packageSelected,
      value: planDetails?.duration
        ? planDetails?.duration === 1
          ? `${planDetails?.duration} Day`
          : `${planDetails?.duration} Days`
        : global?.NA,
    },
    {
      key: bookingDetails?.pacakageKilometers,
      value: planDetails?.kmLimit ? `${planDetails?.kmLimit} Km` : global.NA,
    },
    {
      key: bookingDetails?.excessKMCharges,
      value: planDetails?.kmLimit
        ? `${getFormattedINR(planDetails?.excessKmCharge)} /KM`
        : global.NA,
    },
    {
      key: bookingDetails?.excessHrCharges,
      value: planDetails?.excessHrCharge
        ? `${getFormattedINR(planDetails?.excessHrCharge)} /Hour`
        : global.NA,
    },
  ];

  // payment breakup card array
  const paymentBreakupCardArray = [
    {
      key: bookingDetails?.amountPaid,
      value: paymentBreakUp.paid
        ? `${getFormattedINR(paymentBreakUp.paid)}`
        : getFormattedINR(paymentBreakUp.paid),
      keySx: { fontWeight: 400, color: colors.text_black },
    },
    {
      key: bookingDetails?.securityDeposit,
      value: paymentBreakUp?.security
        ? `${getFormattedINR(paymentBreakUp?.security)}`
        : getFormattedINR(paymentBreakUp?.security),
      keySx: { fontWeight: 400, color: colors.text_black },
    },
    {
      key: bookingDetails?.amountRemaining,
      keyLink: paymentBreakUp?.remaining ? true : false,
      value: paymentBreakUp?.remaining
        ? `${
            getFormattedINR(paymentBreakUp?.remaining)
              ? getFormattedINR(paymentBreakUp?.remaining)
              : 0
          }`
        : getFormattedINR(paymentBreakUp?.remaining),
      keySx: { fontWeight: 400, color: colors.text_black },
      onClick: () => navigateBookingDetailsStep("vehicleDetails"),
    },
    {
      key: bookingDetails?.totalAmount,
      value: paymentBreakUp?.total
        ? `${getFormattedINR(paymentBreakUp?.total)}`
        : getFormattedINR(paymentBreakUp?.total),
      keySx: { fontWeight: 400, color: colors.text_black },
    },
  ];

  const rowCountRef: React.MutableRefObject<any> = useRef(
    paymentOverviewData?.total || 0
  );

  // Update rowCountRef if totalCount is defined, and return stable rowCount.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (paymentOverviewData?.total !== undefined) {
      rowCountRef.current = paymentOverviewData.total;
    }
    return rowCountRef.current;
  }, [paymentOverviewData.total]);

  // addons card array
  const addonCardsDetailsArray: GenericObject[] = addOns?.map(
    (item: GenericObject) => {
      let obj: GenericObject = {
        key: HELMET_DISPLAY_NAME[
          item.subType as keyof typeof HELMET_DISPLAY_NAME
        ],
        value:
          item?.count < 10 && item?.count !== 0
            ? `0${item.count}`
            : item?.count,
        description: item.isComplementary
          ? bookingDetails.complementaryHelment
          : "",
        keySx: { fontWeight: 400, color: colors.text_black, fontSize: "12px" },
      };
      return obj;
    }
  );

  // colums for payment overview data table
  const columns: any[] = [
    {
      field: "createdDate",
      headerName: bookingDetails.createdDate,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.createdAt
            ? getDateTimeFromTimeStamp(params?.row?.createdAt, "date")
            : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      field: "dateOfPayment",
      headerName: bookingDetails.dateOfPayment,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.paidOn
            ? getDateTimeFromTimeStamp(params?.row?.paidOn, "dateTime")
            : global.NA}
        </span>
      ),
      flex: 1.2,
    },
    {
      field: "chargeType",
      headerName: bookingDetails.chargeType,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>
          <CustomHoverPopover
            popupContent={
              <Stack sx={{ padding: "16px", gap: "3px" }}>
                {params.row?.charges?.map((charge: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant={typographyConstants.BODY}
                        color={colors.text_secondary_gray}
                        sx={{ fontWeight: 500 }}
                      >{`${index + 1}. `}</Typography>
                      <Typography
                        variant={typographyConstants.BODY}
                        color={colors.text_secondary_gray}
                        sx={{ fontWeight: 500 }}
                      >{`${
                        bookingChargesSubCategoriesDisplay[
                          charge?.subCategory as keyof typeof bookingChargesSubCategoriesDisplay
                        ]
                      } :`}</Typography>
                      <Typography
                        variant={typographyConstants.SUBHEADING}
                        color={colors.text_black}
                        sx={{ padding: "0px" }}
                      >
                        {getFormattedINR(charge?.amount)}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            }
            hoverContent={
              <Box>
                {`${
                  bookingPaymentType[
                    params.row?.type as keyof typeof bookingPaymentType
                  ]
                }`}
              </Box>
            }
          />
        </Box>
      ),
      flex: 1,
    },
    {
      field: "amountPaid",
      headerName: bookingDetails.amountPaid,
      headerAlign: "center",
      headerClassName: "hideRightSeparator",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params?.row?.amount
            ? `${getFormattedINR(params?.row?.amount)}`
            : global.NA}
        </span>
      ),
      flex: 1,
    },
    {
      type: "actions",
      field: "paymentMode",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.paymentMode,
      headerAlign: "center",
      align: "center",
      flex: 1,
      getActions: (params: any) => {
        // array of payments mode ui for table
        const paymentsMode: GenericObject[] = [
          {
            mode: PaymentFlow.PAYMENT_LINK,
            ui: (
              <LinkIcon
                sx={{ color: colors.primary_dark_blue2 }}
                onClick={() =>
                  handlePaymentOptionClick(
                    PaymentFlow.PAYMENT_LINK,
                    params.row?.orderId,
                    params.row?.type
                  )
                }
              />
            ),
          },
          {
            mode: PaymentFlow.QR_CODE,
            ui: (
              <QrCode2Icon
                sx={{ color: colors.primary_dark_blue2 }}
                onClick={() =>
                  handlePaymentOptionClick(
                    PaymentFlow.QR_CODE,
                    params.row?.orderId,
                    params.row?.type
                  )
                }
              />
            ),
          },
          {
            mode: PaymentFlow.CASH,
            ui: <Typography>{paymentFlowDisplay.CASH}</Typography>,
          },
        ];

        return [
          <>
            {params?.row?.status === BookingPaymentStatus.PENDING ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {paymentsMode?.map((payment: any) => {
                  if (allowedPaymentFlows?.includes(payment?.mode))
                    return payment?.ui;
                })}
              </Box>
            ) : (
              <Box sx={{ fontWeight: 400, color: colors.text_black }}>
                {params?.row?.paymentDetails?.paymentFlow
                  ? paymentFlowDisplay[
                      params?.row?.paymentDetails
                        ?.paymentFlow as keyof typeof paymentFlowDisplay
                    ]
                  : global.NA}
              </Box>
            )}
          </>,
        ];
      },
    },
    {
      field: "status",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.status,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box
          sx={{
            fontWeight: 500,
            color:
              statusColorCode[
                params?.row?.status as keyof typeof statusColorCode
              ],
          }}
        >
          {params?.row?.status
            ? paymentStatusDisplay[
                params?.row?.status as keyof typeof paymentStatusDisplay
              ]
            : global.NA}
        </Box>
      ),
      flex: 1,
    },
    {
      field: "amountRefunded",
      headerClassName: "hideRightSeparator",
      headerName: bookingDetails.amountRefunded,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <div>
          {params.row.refundAdjustmentIds.length > 0 ? (
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: colors.theme_blue,
              }}
              onClick={() => {
                dispatch(getrefundDetails(params.row.id));
                setToggleRefundDrawer(true);
              }}
            >
              {getFormattedINR(params?.row?.refundAmount)}
            </span>
          ) : (
            getFormattedINR(params?.row?.refundAmount)
          )}
        </div>
      ),
      flex: 1,
    },
  ];

  // handle pagination of payment overview
  const handlePaymentOverviewPagination = (paginationValue: any): void => {
    const { page, pageSize } = paginationValue;
    setPaymentOverviewPagination((prev: any) => ({
      ...prev,
      page,
      pageSize,
    }));
    getPaymentOverviewData({ page, pageSize });
  };

  //toggle Drawer
  const toggleDrawer = () => {
    setToggleRefundDrawer(!toogleRefundDrawer);
  };

  return (
    <Stack sx={{ padding: "20px", gap: "20px" }}>
      {RbacHasAccess.planAndAddOnsDetails && (
        <Box sx={StyleObject.plansAndPaymentsContainer}>
          {/* plan details card */}

          <CardsPlans
            contentArray={planDetailsCardArray}
            heading={bookingDetails.planDetails}
            minheight={"172px"}
            status={
              bookingPlanTypeDisplay[
                planDetails?.type as keyof typeof bookingPlanTypeDisplay
              ]
            }
            loading={planAndAddonsDataLoader}
          />

          {/* payment breakup card */}

          <CardsPlans
            contentArray={paymentBreakupCardArray}
            heading={bookingDetails.paymentBreakup}
            minheight={"172px"}
            loading={planAndAddonsDataLoader}
          />

          {/* addons card */}

          {addOns.length > 0 && (
            <CardsPlans
              contentArray={addonCardsDetailsArray}
              heading={bookingDetails.addons}
              minheight={"172px"}
              loading={planAndAddonsDataLoader}
            />
          )}
        </Box>
      )}
      <Stack gap={"10px"}>
        <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
          <AccessTimeIcon
            sx={{
              color: colors.text_secondary_gray,
              height: "16px",
              width: "16px",
            }}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: colors.text_secondary_gray,
            }}
          >
            {bookingDetails?.paymentOverview}
          </Typography>
        </Box>

        <DataTable
          columns={columns}
          rows={paymentOverviewData?.payments ?? []}
          disableColumnMenu
          disableColumnSorting
          disableRowSelectionOnClick
          headerAlign={"center"}
          loading={paymentOverviewDataLoader}
          paginationMode="server"
          paginationModel={paymentOverviewPagination}
          onPaginationModelChange={(value: any) => {
            handlePaymentOverviewPagination(value);
          }}
          rowCount={rowCount}
          hasAccess={RbacHasAccess.paymentOverview}
        />
      </Stack>

      <SideDrawer
        open={toogleRefundDrawer}
        heading={en.paymentManagement.refundDetails}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={<RefundDetailsDrawer refundDetails={refundDetails} />}
      />
    </Stack>
  );
};

const CardsPlans = (props: any) => {
  const {
    contentArray = [],
    heading = "",
    status = "",
    maxHeight = "auto",
    minheight = "auto",
    loading = false,
  } = props;

  return (
    <Box sx={{ maxHeight: maxHeight, ...StyleObject.plansCardcontainer }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 16px 8px 16px",
          alignItems: "center",
          boxShadow: "0px 4px 16px 0px #00000014",
          zIndex: 999,
          minHeight: "40px",
        }}
      >
        <Typography
          variant={typographyConstants.BODY}
          color={colors.text_secondary_gray}
          sx={{ fontWeight: 500 }}
        >
          {heading}
        </Typography>

        {status && (
          <Box
            sx={{
              padding: "3px 10px",
              backgroundColor: colors.sherpa_blue,
              borderRadius: "20px",
            }}
          >
            <Typography sx={{ color: colors.white, fontWeight: 500 }}>
              {status}
            </Typography>
          </Box>
        )}
      </Box>
      {/* content start */}
      {contentArray?.map((item: any, index: number) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 16px 6px 16px",
            height: "auto",
            backgroundColor:
              index % 2 === 0 ? colors.white : colors.bg_secondary_blue,
            width: "100%",
            minWidth: "290px",
          }}
        >
          {loading ? (
            <Skeleton width={"90px"} animation="wave" />
          ) : (
            <Stack gap={"3px"}>
              {item?.keyLink ? (
                <Link
                  sx={{ color: colors.primary_danger, cursor: "pointer" }}
                  onClick={() => {
                    item?.onClick();
                  }}
                >
                  <Typography
                    variant={typographyConstants.BODY}
                    color={colors.tertiary_black}
                    sx={{ color: colors.primary_danger, cursor: "pointer" }}
                  >
                    {item?.key}
                  </Typography>
                </Link>
              ) : (
                <Typography
                  variant={typographyConstants.BODY}
                  color={colors.tertiary_black}
                  sx={{ ...item?.keySx }}
                >
                  {item?.key}
                </Typography>
              )}
              {item?.description && (
                <Typography
                  variant={typographyConstants.BODYITALIC}
                  color={colors.primary_aqua_blue}
                >
                  {item?.description}
                </Typography>
              )}
            </Stack>
          )}
          {loading ? (
            <Skeleton width={"40px"} animation="wave" />
          ) : (
            <Typography
              variant={typographyConstants.SUBHEADING}
              color={colors.text_black}
              sx={{ padding: "0px", ...item?.valueSx }}
            >
              {item?.value}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default PlanAndPayments;
