import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Key, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import en from "../../../../locale/en.json";
import { RootState } from "../../../../redux/reducers";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import RangePicker from "../../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomSelect from "../../../../sharedComponents/atoms/InputFields/InputSelect";
import { colors } from "../../../../themes/colors";
import { isArrayNotEmpty } from "../../../../utils/helper";
import {
  tyesOfModeOfDelivery,
  typographyConstants,
} from "../../../constants/constants";
import {
  BikeIconFaded,
  BookingTypeIcon,
  CityInputIcon,
  LeftScrollIcon,
  PlusIcon,
  RightScrollIcon,
  WarehouseIcon,
  fadedCalenderIcon,
} from "../../../constants/exportImages";
import { BookingDetailsInterface } from "../../../interfaces/manualBookingInterfaces";
import rentalEn from "../../../locale/rental-en.json";
import { manualBookingActions, rentalActions } from "../../../redux/actions";
import {
  calculateFutureDate,
  formatTime,
  getFormattedINR,
  getTimestampAtStartOfDay,
} from "../../../utils/helper";
import HomeDeliveryAddressCard from "./HomeDeliveryAddressCard";
import Styles from "./section.module.css";

const BookingDetails = ({
  fields,
  setFields,
  errorFields,
  setErrorFields,
  masterPlanDurationsData,
  AccessCity,
  branches,
  BookingTypeArr,
  handleDateChange,
  setHomeDeliveryModalOpen,
  availablePeriods,
  renderTimeSlots,
  setSlot,
  Slot,
  setshowVehicleCard,
  resetMultistepFormRef,
  resetData,
  initialState,
  handleGetAvailableModels,
  handlePackagesSelection,
  setIsCouponApplied,
  planType,
}: BookingDetailsInterface) => {
  const dispatch = useDispatch();
  const {
    availableModels,
    slotPauseDates,
    modelDetails,
    customerAddressList,
    loading,
  } = useSelector((state: RootState) => state.newManualBookingReducer);
  const containerRef = useRef<any>(null);

  const deliveryModes = modelDetails?.availability?.[0]?.config?.deliveryModes;
  const branchDetails = modelDetails?.availability?.[0]?.branchDetails;
  const currentDate: any = getTimestampAtStartOfDay(new Date());
  const launchDate: any = getTimestampAtStartOfDay(
    new Date(fields?.selectedBranch?.launchDate)
  );

  // sorted Adress on basis on default address
  const sortedAddresses = [...(customerAddressList?.addresses || [])].sort(
    (a, b) => b.default - a.default
  );

  // select first Home delivery address by default
  useEffect(() => {
    if (
      sortedAddresses.length > 0 &&
      fields?.modeOfDelivery === tyesOfModeOfDelivery.CUSTOMER_LOCATION
    ) {
      setFields((prevState: any) => ({
        ...prevState,
        addressId: sortedAddresses[0]?.id,
      }));
    }
  }, [customerAddressList, fields?.modeOfDelivery]);

  // get branches list
  useEffect(() => {
    if (fields?.selectedCity)
      dispatch(rentalActions.getAllBranches(fields?.selectedCity));
  }, [fields?.selectedCity]);

  //set values
  const handleChange = (field: string, value: any) => {
    setFields((prevState: any) => ({ ...prevState, [field]: value }));
    setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
  };

  //handle time selection of home delivery
  const handleDeliveryTimeSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSlot((event.target as HTMLInputElement).value);
  };

  // scroll Home delivery Cards from icons
  const scrollHomeDeliveryCards = (value: any) => {
    containerRef.current.scrollBy({ left: value, behavior: "smooth" });
  };

  //get min date on the basis of launch date
  const getMinDate = () => {
    if (launchDate > currentDate) return launchDate;
    else {
      const diffTime = Math.abs(currentDate - launchDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return moment()
        .subtract(diffDays > 30 ? 30 : diffDays, "days")
        .toDate();
    }
  };

  // get max  date on the basis of launch date
  const getMaxDate = () => {
    if (fields?.selectedPackage === rentalEn.manualBooking.custom) {
      let disableddate = getTimestampAtStartOfDay(new Date());
      let days = 180;

      if (fields?.startDate) {
        disableddate = fields?.startDate;
        days = 90;
      }

      return calculateFutureDate(disableddate, days);
    }
    if (fields?.startDate)
      return calculateFutureDate(
        launchDate > currentDate ? launchDate : new Date(),
        180
      );
  };

  const SelectTimeSlot = () => {
    return (
      <Box className={Styles.pickUpTime}>
        <Typography variant={typographyConstants.HEADING}>
          {en.NewManualBooking.SelectPickupTime}
        </Typography>
        <FormControl>
          {availablePeriods?.length === 0 ? (
            <Typography color="grey">
              {rentalEn.manualBooking.noSlotsAvailable}
            </Typography>
          ) : (
            <Box display="flex" flexDirection="row" gap="12px">
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={Slot}
                onChange={handleDeliveryTimeSelect}
              >
                {availablePeriods?.map((period: any) => (
                  <FormControlLabel
                    key={period.label}
                    value={period.label}
                    control={<Radio />}
                    label={period.label}
                  />
                ))}
              </RadioGroup>
              <Box className={Styles.timeSlot}>
                {renderTimeSlots(Slot)?.map(
                  (
                    item: { start: any; end: any },
                    index: Key | null | undefined
                  ) => {
                    const isSelected =
                      fields?.selectedTimeSlot?.start === item.start &&
                      fields?.selectedTimeSlot?.end === item.end;

                    return (
                      <Chip
                        key={index}
                        label={`${formatTime(item.start)} - ${formatTime(
                          item.end
                        )}`}
                        color={isSelected ? "primary" : "default"}
                        style={{ borderRadius: "8px" }}
                        onClick={() => {
                          resetMultistepFormRef.current &&
                            resetMultistepFormRef.current.nextStepsDisabled();
                          const selectedTime = {
                            start: item.start,
                            end: item.end,
                          };
                          handleChange("selectedTimeSlot", selectedTime);
                        }}
                      />
                    );
                  }
                )}
              </Box>
            </Box>
          )}
        </FormControl>
      </Box>
    );
  };

  const BookingDetailsFields = () => {
    return (
      <Stack gap="10px">
        <Box className={Styles.userDetails}>
          <CustomSelect
            required
            icon={BookingTypeIcon}
            placeholder={en.NewManualBooking.SelectBookingType}
            value={fields?.bookingType?.displayName}
            topHeading={en.NewManualBooking.SelectBookingType}
            choice={BookingTypeArr}
            defaultKey={"displayName"}
            errormessage={errorFields.bookingType}
            handleChange={(bookingType: any) => {
              handleChange("bookingType", bookingType);
              resetData(["bookingType", "selectedCity"]);
            }}
          />

          {/* Select City */}
          <CustomSelect
            required
            icon={CityInputIcon}
            topHeading={en.NewManualBooking.CityDetails}
            placeholder={en.NewManualBooking.SelectCity}
            value={fields?.selectedCity?.displayName}
            choice={isArrayNotEmpty(AccessCity) ? AccessCity : []}
            defaultKey={"displayName"}
            errormessage={errorFields.selectedCity}
            handleChange={(city: any) => {
              handleChange("selectedCity", city);
              resetData(["bookingType", "selectedCity"]);
            }}
          />

          {/* Select Branch */}
          <CustomSelect
            required
            icon={WarehouseIcon}
            topHeading={en.NewManualBooking.WarehouseDetails}
            placeholder={en.NewManualBooking.SelectWarehouse}
            value={fields.selectedBranch.displayName}
            choice={isArrayNotEmpty(branches) ? branches : []}
            defaultKey={"displayName"}
            errormessage={errorFields.selectedBranch}
            disabled={!fields?.selectedCity?.name}
            handleChange={(warehouse: any) => {
              handleChange("selectedBranch", warehouse);
              handleGetAvailableModels({
                startDate: fields?.startDate,
                endDate: fields?.endDate,
                branchName: warehouse?.name,
                useCase: fields?.bookingType?.name,
              });

              setFields((prevState: any) => ({
                ...prevState,
                selectedVehicle: initialState.selectedVehicle,
              }));
              resetData(["bookingType", "selectedCity", "selectedBranch"]);
            }}
          />
        </Box>
        <Box className={Styles.userDetails}>
          {/* Select package Duration */}
          <CustomSelect
            required
            icon={fadedCalenderIcon}
            placeholder={en.manualBooking.SelectPackage}
            value={fields.selectedPackage}
            topHeading={en.NewManualBooking.PackageDetails}
            customLabel={(data: any) => {
              return (
                <div>
                  {data} {data === 1 ? ` day` : ` days`}
                </div>
              );
            }}
            choice={masterPlanDurationsData}
            errormessage={errorFields.selectedPackage}
            disabled={!fields?.selectedBranch?.name}
            handleChange={(days: any) => {
              handleChange("selectedPackage", days);
              setFields((prevState: any) => ({
                ...prevState,
                selectedVehicle: initialState.selectedVehicle,
              }));
              handlePackagesSelection(days);
            }}
          />

          <RangePicker
            icon={fadedCalenderIcon}
            heading={en.NewManualBooking.RideDuration}
            fields={fields}
            slotPauseDates={slotPauseDates}
            isClearable={false}
            onChange={(update: any) => {
              handleDateChange(update);
            }}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            disabled={!fields?.selectedBranch?.name}
            onClickOutside={(e: any) => {
              // handling if user clicks outside after selecing start date
              if (fields?.startDate && !fields?.endDate) {
                setFields((prev: any) => ({
                  ...prev,
                  startDate: new Date(),
                  endDate: calculateFutureDate(new Date(), 1),
                  selectedPackage: "1",
                }));
              }
            }}
          />

          <CustomSelect
            required
            icon={BikeIconFaded}
            placeholder={en.NewManualBooking.SelectVehicleModel}
            topHeading={en.NewManualBooking.SelectVehicleModel}
            value={fields?.selectedVehicle?.displayName}
            choice={availableModels}
            defaultKey={"displayName"}
            customRenderKey="planDetails"
            customLabel={(data: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{data.displayName}</div>
                  <div style={{ marginLeft: "auto" }}>
                    {getFormattedINR(data.planDetails.amount)}
                  </div>
                </div>
              );
            }}
            disabled={
              fields?.selectedBranch?.name === "" ||
              (!fields?.startDate && !fields?.endDate)
            }
            errormessage={errorFields.selectedVehicle}
            handleChange={(modal: any) => {
              handleChange("selectedVehicle", modal);
              const payload = {
                startDate: getTimestampAtStartOfDay(fields?.startDate),
                endDate: getTimestampAtStartOfDay(fields?.endDate),
                branchName: fields?.selectedBranch?.name,
                useCase: fields?.bookingType?.name,
                modelName: modal.modelName,
              };
              dispatch(manualBookingActions?.getModelDetails(payload));
              setFields((prevState: any) => ({
                ...prevState,
                modeOfDelivery: tyesOfModeOfDelivery.BRANCH,
                couponCode: initialState.couponCode,
                selectedPlan: planType[0],
                helmets: initialState.helmets,
              }));
              setIsCouponApplied(false);
            }}
          />
        </Box>
      </Stack>
    );
  };

  const SelectedHomeDelivery = () => {
    return (
      <Fade>
        <Stack gap="10px">
          <Box>
            {sortedAddresses.length > 0 ? (
              // Address Selection
              <Box className={Styles.addressSelection}>
                <Typography variant={typographyConstants.HEADING}>
                  {en.NewManualBooking.SelectAddress}
                </Typography>

                <CustomButton
                  startImageSrc={PlusIcon}
                  label={en.NewManualBooking.NewAddress}
                  variant="outlined"
                  sx={{
                    padding: "0px 26px",
                    display: "flex",
                  }}
                  onClick={() => setHomeDeliveryModalOpen(true)}
                />
              </Box>
            ) : (
              <Stack>
                {/* Render Input */}
                <Typography variant={typographyConstants.HEADING}>
                  {en.NewManualBooking.EnterDeliveryLocation}
                </Typography>

                <CustomButton
                  startImageSrc={PlusIcon}
                  label={en.NewManualBooking.AddAddress}
                  variant="outlined"
                  sx={{
                    padding: "0px 26px",
                    display: "flex",
                    width: "300px",
                  }}
                  onClick={() => setHomeDeliveryModalOpen(true)}
                />
              </Stack>
            )}
          </Box>

          <Box>
            {sortedAddresses.length > 4 && (
              <Box className={Styles.scrollBox}>
                <img
                  src={LeftScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(-200)}
                />
                <img
                  src={RightScrollIcon}
                  onClick={() => scrollHomeDeliveryCards(200)}
                />
              </Box>
            )}
            <Box className={Styles.CardListContainer} ref={containerRef}>
              {sortedAddresses.map((address: any, index: any) => (
                <HomeDeliveryAddressCard
                  key={address.id}
                  selectedAddress={fields?.addressId === address.id}
                  address={address}
                  onClick={() => handleChange("addressId", address.id)}
                />
              ))}
            </Box>
          </Box>
        </Stack>
      </Fade>
    );
  };

  return (
    <Box className={Styles.deliveryDetails}>
      {BookingDetailsFields()}

      {/* Select Home Delivery or Pickup */}
      {fields?.selectedVehicle.modelName && (
        <>
          <Stack gap="15px">
            <Stack>
              <Typography variant={typographyConstants.HEADING}>
                {en.NewManualBooking.ModeOfDelivery}
              </Typography>
              <ButtonGroup aria-label="Delivery type button group">
                {deliveryModes?.map((type: any, index: number) => (
                  <Button
                    key={type}
                    variant={
                      fields?.modeOfDelivery === type ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setshowVehicleCard(false);
                      setSlot(false);
                      setFields((prevState: any) => ({
                        ...prevState,
                        selectedTimeSlot: {},
                      }));
                      resetMultistepFormRef.current &&
                        resetMultistepFormRef.current.nextStepsDisabled();
                      handleChange("modeOfDelivery", type);
                    }}
                    className={Styles.buttonGroupDeliveryType}
                    sx={{
                      borderRadius:
                        deliveryModes.length === 1
                          ? "20px"
                          : index === 0
                          ? "20px 0px 0px 20px"
                          : index === deliveryModes.length - 1
                          ? "0px 20px 20px 0px"
                          : "0px",
                      padding: "0px 10px",
                      background:
                        fields?.modeOfDelivery === type
                          ? colors.THEME_BLUE
                          : "",
                      "&:hover": {
                        background: colors.THEME_BLUE,
                      },
                      "&:active": {
                        background: colors.THEME_BLUE,
                      },
                    }}
                    disabled={!deliveryModes.includes(type)}
                  >
                    {type === tyesOfModeOfDelivery.BRANCH
                      ? en.NewManualBooking.PickUp
                      : en.NewManualBooking.HomeDelivery}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
            {fields?.modeOfDelivery === tyesOfModeOfDelivery.BRANCH && (
              <Fade>
                <Box>
                  <Typography variant={typographyConstants.HEADING}>
                    {en.NewManualBooking.PickupLocation}
                  </Typography>

                  <Box marginTop={"10px"}>
                    <Card className={Styles.BranchDetailsCard}>
                      <Box>
                        <img src={WarehouseIcon} alt="wareHouseIcon" />
                      </Box>
                      <Stack gap="5px">
                        {loading ? (
                          <Skeleton
                            width="100px"
                            height="25px"
                            animation="wave"
                          />
                        ) : (
                          <Typography
                            variant={typographyConstants.SUBHEADING}
                            color={colors.text_secondary_gray}
                          >
                            {branchDetails?.displayName ?? ""}
                          </Typography>
                        )}

                        <Stack>
                          {loading ? (
                            <Skeleton
                              width="150px"
                              height="20px"
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              variant={typographyConstants.BODY}
                              color={colors.tertiary_black}
                            >
                              {branchDetails?.address?.addressLine1 ?? ""}
                            </Typography>
                          )}
                          {loading ? (
                            <Skeleton
                              width="150px"
                              height="20px"
                              animation="wave"
                            />
                          ) : (
                            <Typography
                              variant={typographyConstants.BODY}
                              color={colors.tertiary_black}
                            >
                              {`${
                                branchDetails?.address?.cityDisplayName ?? ""
                              } , ${
                                branchDetails?.address?.stateDisplayName ?? ""
                              }`}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Card>
                  </Box>
                </Box>
              </Fade>
            )}
            {fields?.modeOfDelivery ===
              tyesOfModeOfDelivery.CUSTOMER_LOCATION && SelectedHomeDelivery()}
          </Stack>

          {SelectTimeSlot()}
        </>
      )}
    </Box>
  );
};

export default BookingDetails;
