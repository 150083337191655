export enum UserActions {
  view = "View",
  editVehicle = "Edit Vehicle",
  edit = "Edit",
  update = "Update",
  detail = "Detail",
  delete = "Delete",
  approve = "Approve",
  reject = "Reject",
  viewCalendar = "View Calendar",
  signal = "Signal",
  stats = "stats",
  linkAccount = "Link Account",
}

export enum ButtonActions {
  refresh = "Refresh",
  edit = "Edit",
  addModel = "Add Model",
  delete = "Delete",
  addSlots = "Add Slots",
  calendarView = "Calendar View",
  listView = "List View",
  addRole = "Add Role",
  reset = "Reset",
  addPackage = "Add Package",
  addPromotion = "Add Promotion",
  download = "Download",
  AddVehicle = "Add Vehicle",
  AddIOTDevice = "Add IOT Device",
  BulkUpload = "Bulk Upload",
  BlockVehicle = "Block",
  StatusChange = "Status Change",
  TransferVehicles = "Transfer Vehicles",
  ApproveBlockVehicle = "Approve/Reject",
  addLocation = "Add Location",
  addBranch = "Add Branch",
  UnBlockVehicle = "UnBlock",
  CSVDownload = "Download Data",
  BulkUpdate = "Bulk Actions",
  Downloads = "Downloads",
  AddCity = "Add City",
  AddCategory = "Add Category",
  AddNewPackage = "Add New Package",
  CsvExcelDownload = "CSV Download",
  AddReason = "Add Reason",
  AddAdvertisement = "Add Advertisement",
  AddOperator = "Add Operator",
  CreateTask = "Create Task",
  NewBookingManagement = "New Booking Management",
  TransferHistory = "Request History",
  Back = "Back",
  CreateFreedoXBooking = "Create FreedoX Booking",
  AddManualUser = "Add User",
}

export enum PackageManagementRoutes {
  view = "/package-management/view-extra-packages/:id",
  add = "/package-management/add-extra-packages",
  edit = "/package-management/edit-extra-packages/:id",
}
export enum PromotionRoutes {
  view = "view-promotions/:id",
  add = "add-promotions",
  edit = "edit-promotions/:id",
}

export enum SlotPauseRoutes {
  view = "/slotPause/viewSlotPause/",
  edit = "/slotPause/editSlotPause/",
  add = "/slotPause/addSlotPause",
}

export enum LocationManagementRoutes {
  view = "/view-location/:id",
  add = "/location-management/add-location",
  edit = "/edit-location/:id",
}

export enum CancelReasonRoutes {
  view = "/view-reason/:id",
  add = "/cancel-reasons/add-reason",
  edit = "/edit-reason/:id",
}

export enum ToastType {
  success = "success",
  info = "info",
  error = "error",
  warning = "warning",
}

export const userData = {
  accessRight: {
    userManagement: { view: true, update: true, delete: true },
    inventory: { view: true },
    rentalPlans: { view: true, add: true, update: true, delete: true },
    packageManagement: { view: true, add: true, update: true, delete: true },
    bookingManagement: { view: true, add: true, update: true },
    locationManagement: { view: true, add: true, update: true, delete: true },
    promotions: { view: true, add: true, update: true, delete: true },
    slotPause: { view: true, add: true, update: true, delete: true },
    vehicleManagement: { view: true, add: true, update: true, delete: true },
    paymentManagement: { view: true },
    reports: {
      view: true,
      add: true,
      update: true,
      delete: true,
      userReports: { view: true, add: true, update: true, delete: true },
      bookingReports: { view: true, add: true, update: true, delete: true },
      paymentReports: { view: true, add: true, update: true, delete: true },
    },
    masterManagement: {
      view: true,
      add: true,
      update: true,
      delete: true,
      cityManagement: { view: true, add: true, update: true, delete: true },
      categoryManagement: { view: true, add: true, update: true, delete: true },
      modelManagement: { view: true, add: true, update: true, delete: true },
    },
    businessManagement: { view: true, update: true },

    //new changes
    roleManagement: {
      view: false,
      add: false,
      update: false,
      delete: false,
    },
    statistics: {
      view: false,
      add: false,
      update: false,
      delete: false,
    },
  },
  viewUrls: [
    "user-management",
    "view-user",
    "inventory-management",
    "view-inventory-bydate",
    "vehicle-management",
    "view-vehicle",
    "rental-plans",
    "view-package",
    "package-management",
    "view-extra-packages",
    "booking-management",
    "view-booking",
    "location-management",
    "view-location",
    "branch-management",
    "view-branch",
    "promotions",
    "view-promotions",
    "slotPause",
    "viewSlotPause",
    "payment-management",
    "view-booking",
    "model-module",
    "view-model",
    "feedback-management",
    "new-booking",
    "user-reports",
    "booking-reports",
    "payment-reports",
    "city-module",
    "category-module",
    "business-management",
    "settings",
    "view-reason",
  ],
  addUrls: [
    "add-model",
    "add-vehicle",
    "add-package",
    "add-extra-packages",
    "add-location",
    "add-branch",
    "add-promotions",
    "addSlotPause",
    "add-city",
    "add-category",
    "add-reason",
  ],
  updateUrls: [
    "edit-model",
    "edit-user",
    "edit-vehicle",
    "edit-package",
    "edit-extra-packages",
    "edit-location",
    "edit-branch",
    "edit-promotions",
    "editSlotPause",
    "edit-city",
    "edit-category",
    "edit-reason",
  ],
  deleteUrls: [],
  _id: "627be26c0694c4f90a0f19a2",
  name: "anshi",
  userName: "admin",
  emailId: "",
  mobileNumber: "0000000001",
  password: "$2a$10$nq2jrnPSjAi1SLRv9nHb6.P.zC95NZ9/ivxh0oL0ADjrUR.z9ErR2",
  userType: "MASTER",
  isMaster: true,
  locationIds: [],
  branchIds: [],
  businessType: ["LTR"],
  urls: [],
  status: 1,
  createdBy: "627bc622ed6ff7d8d33d67da",
  lastActivity: "2022-09-16T06:06:26.119Z",
  loginAttempts: 0,
  createdAt: "2022-05-11T16:21:00.687Z",
  updatedAt: "2022-09-16T06:06:26.120Z",
  __v: 0,
  blockUntilTime: null,
  secondaryEmails: [],
  secondaryNumbers: [],

  //new changes
};
