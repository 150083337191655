export const DragDataJson = [
 {
  id: 1,
  taskName: "Odometer reading One",
  reading:
   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  sla: "2 mins",
  imageRequired : true,
  reviewRequired: true,
 },
 {
  id: 2,
  taskName: "Vehicle Image One",
  reading:
   "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  sla: "2 mins",
  imageRequired : true,
  reviewRequired: true,
 },
 {
  id: 3,
  taskName: "Vehicle Sanitation One",
  reading:
   "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  sla: "2 mins",
  imageRequired : true,
  reviewRequired: true,
 },
 {
  id: 4,
  taskName: "Delivery One",
  reading:
   "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  sla: "2 mins",
  imageRequired : true,
  reviewRequired: true,
 },
 {
  id: 5,
  taskName: "Helmet One",
  reading:
   "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  sla: "2 mins",
  imageRequired : true,
  reviewRequired: true,
 }
]
