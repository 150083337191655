import { Box, CircularProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../../sharedComponents/atoms/InputFields/Input";
import {
  emailIdLength,
  mobileNoLength,
  userNameLength,
} from "../../../../../constants/constants";
import rentalEn from "../../../../../locale/rental-en.json";

const { roleManagement } = rentalEn;

const AddUserSection = (props: any) => {
  const {
    fields,
    onChangeHandler,
    addUpdateUserHandler,
    isNextButtonValid,
    isEditing,
  } = props;

  const {
    addUserResponseData,
    addUpdateUserLoader,
    userDetailsByUserIdData,
    userDetailsByUserIdDataLoader,
  } = useSelector((state: RootState) => state.newBusinessManagementReducer);

  return (
    <Stack spacing={3} pt={3} alignItems={"center"}>
      {userDetailsByUserIdDataLoader ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Name Field */}
          <Input
            placeholder={roleManagement?.enterName}
            label={roleManagement?.enterName}
            value={fields?.name}
            // error={true}
            inputProps={{ maxLength: userNameLength }}
            width={400}
            onChange={(e: any) => {
              onChangeHandler("name", e.target.value);
            }}
          />

          {/* Mobile Number Field */}
          <Input
            placeholder={roleManagement?.mobileNumber}
            label={roleManagement?.mobileNumber}
            value={fields?.phone}
            // error={true}
            inputProps={{ maxLength: mobileNoLength }}
            width={400}
            onChange={(e: any) => {
              onChangeHandler("phone", e.target.value);
            }}
            disabled={isEditing || addUserResponseData?.userId}
          />

          {/* Email Id Field */}
          <Input
            placeholder={roleManagement?.emailAddress}
            label={roleManagement?.emailAddress}
            value={fields?.emailId}
            // error={true}
            inputProps={{ maxLength: emailIdLength }}
            width={400}
            onChange={(e: any) => {
              onChangeHandler("emailId", e.target.value);
            }}
            disabled={isEditing || addUserResponseData?.userId}
          />

          {/* Add User Button */}
          <CustomButton
            label={
              isEditing || addUserResponseData?.userId
                ? roleManagement?.updateUser
                : roleManagement?.addUser
            }
            variant={"contained"}
            sx={{ width: 400 }}
            onClick={() =>
              addUpdateUserHandler(
                isEditing || addUserResponseData?.userId
                  ? "update-user"
                  : "add-user"
              )
            }
            disabled={
              !isNextButtonValid() ||
              JSON.stringify(fields?.name.trim()) ===
                JSON.stringify(userDetailsByUserIdData?.name) ||
              JSON.stringify(fields?.name.trim()) ===
                JSON.stringify(addUserResponseData?.name)
            }
            loading={addUpdateUserLoader}
          />
        </>
      )}
    </Stack>
  );
};

export default AddUserSection;
