import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../components/breadCumbs";
import { Button2 } from "../../components/button";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input2, Input4 } from "../../components/input";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import { colors } from "../../themes/colors";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilePicker from "../../components/filePicker";
import { actions } from "../../redux";

interface DefaultProps {
  addRole?: boolean;
  editRole?: boolean;
  viewRole?: boolean;
}

function ViewModel(props: DefaultProps) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { modelById } = useSelector((state: RootState) => state.masterReducer);
  const { vehicleTypes, vehicleCategoryList, emissionStandards, BrandList } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const { modelManagement, categoryManagement, global, vehicleManagement } =
    locale;
  const modelId = useParams();
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate("/master-management/model-module");
  };

  const [fields, setFields] = useState({
    model_name: "",
    brand_name: "",
    type: "",
    max_speed: "",
    max_range: "",
    test_rides: "",
    brand: {
      id: 0,
      name: global.SourceName,
    },
    category: "",
    emissionStandards: "",
    cubicCapicity: "",
    hp: "",
    consumption: "",
    averageEconomy: "",
    short_description: "",
    description: "",
    model_image_path: "",
    model_images: [],
    model_30_image: "",
    image_name: "",
    download_guide_name: "",
    download_guide_path: "",
    model_30_image_path: [],
  });

  const dispatch = useDispatch();
  // alert()
  useEffect(() => {
    dispatch(
      actions.getModelById({
        id: modelId?.id,
      })
    );
    dispatch(
      actions.BrandData({
        type: "Brand",
        id: 0,
      })
    );
    dispatch(actions.vehicleTypes({ type: "VehicleType" }));
    dispatch(actions.vehicleCategories({ type: "VehicleCategory" }));
    dispatch(actions.getEmissionStandards({ type: "EmissionStandards" }));
  }, []);

  useEffect(() => {
    setFields({
      ...fields,
      averageEconomy: modelById?.average_economy,
      model_name: modelById?.model_name,
      brand_name: modelById?.brand_name,
      type: modelById?.vehicle_type_master?.type_name,
      category: modelById?.vehicle_category_master?.category_name,
      emissionStandards: modelById?.emission_standard?.emission_name,
      cubicCapicity: modelById?.cubic_capacity,
      hp: modelById?.hp,
      max_speed: modelById?.max_speed,
      consumption: modelById?.consumption,
      short_description: modelById?.short_description,
      description: modelById?.description,
      model_image_path: modelById?.vehicle_image_path,
      image_name: modelById?.image_name,
      download_guide_name: modelById?.download_guide_name,
      download_guide_path: modelById?.download_guide_path,
      model_30_image_path: modelById?.vehicle_overview_path,
      model_images: modelById?.image_detail_view_array,
      max_range: modelById?.max_range,
      test_rides: modelById?.test_rides === true ? "1" : "0",
      brand: modelById?.brand
    });
  }, [modelById]);
  
  return (
    <div className="container pd-20">
      <Heading
        title={
          props.viewRole
            ? categoryManagement.ViewModel
            : modelManagement.StoreModel
        }
      />
      <BreadCrumbs
        path="/master-management/model-module"
        firstLabel={modelManagement.ModelManagement}
        secondLabel={
          props.viewRole
            ? categoryManagement.ViewModel
            : modelManagement.StoreModel
        }
      />
      <form>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2%",
            bgcolor: "background.paper",
            borderRadius: 1,
            // justifyContent: "space-between",
          }}
        >
          <Input2
            width={300}
            value={fields?.model_name}
            placeholder={global.ModelName}
            readOnly={true}
            required={true}
          />
          <Input2
            width={300}
            value={fields?.brand_name}
            placeholder={global.BrandName}
            readOnly={true}
            required={true}
          />
          {vehicleTypes ? (
            <Input2
              select
              width={300}
              placeholder={vehicleManagement.Type}
              heading={vehicleManagement.Type}
              defaultKey={"type_name"}
              value={fields?.type}
              choice={vehicleTypes}
              readOnly={true}
              required={true}
            />
          ) : null}
          <Input2
            width={300}
            select
            heading={vehicleManagement.Category}
            value={fields?.category}
            defaultKey={"category_name"}
            placeholder={vehicleManagement.Category}
            choice={vehicleCategoryList}
            required={true}
            readOnly={true}
          />
          <Input2
            width={300}
            select
            placeholder={vehicleManagement.EmissionStandards}
            heading={vehicleManagement.EmissionStandards}
            choice={emissionStandards}
            value={fields?.emissionStandards}
            defaultKey={"emission_name"}
            required={true}
            readOnly={true}
          />
          <Input2
            width={300}
            value={fields?.cubicCapicity}
            placeholder={vehicleManagement.CubicCapacity}
            readOnly={true}
            required={true}
          />
          <Input2
            width={300}
            value={fields?.consumption}
            placeholder={vehicleManagement.Consumption}
            readOnly={true}
            required={true}
          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.max_speed}
            placeholder={global.MaxSpeed}
            readOnly={true}
            required={true}
          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.hp}
            placeholder={vehicleManagement.HP}
            required={true}
            readOnly={true}
          // onChange={(e)=>{}}
          />
          <Input2
            width={300}
            value={fields?.averageEconomy}
            placeholder={vehicleManagement.AverageEconomy}
            readOnly={true}
            required={true}
          // onChange={(e)=>{}}
          />
          <Input4
            placeholder={global.ShortDescription}
            readOnly={props.viewRole}
            defaultValue={fields?.short_description}
            required={true}
          />
          <Input4
            placeholder={global.Description}
            readOnly={props.viewRole}
            defaultValue={fields?.description}
            required={true}
          />



          <Box display={"flex"}>
            <Input2
              width={300}
              value={fields?.max_range}
              readOnly={true}
              placeholder={vehicleManagement.MaxRange}
              heading={vehicleManagement.MaxRange}
            // required={true}
            // onChange={(e: any) => {
            //   checkNumeric(e.target.value) &&
            //     handleChange("max_range", e.target.value);
            // }}
            // errormessage={errorFields.averageEconomy}

            // onChange={(e)=>{}}
            />

            {/* <Grid>
  <Typography>
    Test Ride:
    <text style={{ color: colors.error, fontSize: "14px" }}>*</text>
  </Typography>
</Grid> */}
            <Grid container direction="column">
              <FormLabel component="legend">Test Ride:</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                value={fields.test_rides}
                onChange={(e: any) => {
                  setFields({
                    ...fields,
                    test_rides: e.target.value
                  });
                }}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                    />
                  }
                  label={vehicleManagement.Yes}
                  labelPlacement="end"
                  disabled
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                    />
                  }
                  label={vehicleManagement.No}
                  labelPlacement="end"
                  disabled
                />
              </RadioGroup>
            </Grid>
          </Box>

          <div style={{ display: fields?.test_rides === "1" ? "" : "none" }}>
            <Input2
              width={300}
              select
              heading={global.SourceName}
              value={fields?.brand?.["name"]}
              defaultKey={"name"}
              placeholder={global.SourceName}
              choice={BrandList}
              readOnly={true}


            />
          </div>

          {/* <TextareaAutosize
                         minRows={20}
                        maxRows={100}
                        aria-label="maximum height"
                        placeholder={global.ShortDescription}
                        defaultValue={props.viewRole || props.editRole ?"The Hero Pleasure Plus is arguably perfect for the urban commuter, who doesn’t need to compromise on the looks of his vehicle for a robust ride.Its Bold, Smart, Just like You.":''}
                        style={{ backgroundColor:'rgb(242, 242, 242)',minWidth: '320px',marginRight: '50px',border:'none' }}
                      />
                      <TextareaAutosize
                       minRows={20}
                        maxRows={100}
                        aria-label="maximum height"
                        placeholder={global.Description}
                        defaultValue={props.viewRole || props.editRole ?"The new Hero Pleasure Plus comes with a stylish exterior and a sturdy interior, so you can enjoy a trouble-free commute while looking classy at all times! The scooter is equipped with Hero’s Xsens technology which uses smart sensors to automatically detect different riding conditions. This, coupled with the air-cooled, four-stroke 110.9 cc engine, guarantees to make your ride pleasurable. Pleasure Plus keeps the rider’s comfort and convenience at the forefront, and it has a host of features that are a testament to this. To name a few, a mobile charging port for when you forget to put your phone on charge the previous night and have no time to waste to reach college, and a new analogue speedometer with a clean and refined look so you don’t get distracted while expertly maneuvering through jam-packed main roads to get to your office. These are only a few of the remarkable features that sets Pleasure Plus in a league of its own.":''}
                        style={{ backgroundColor:'rgb(242, 242, 242)',minWidth: '320px',marginRight: '50px',border:'none' }}
                      /> */}
        </Box>
        {props.viewRole ? (
          <>
            {/* <ModelView
              navigatePath={
                fields?.download_guide_path + fields?.download_guide_name
              }
              sideHeading="Model Guide"
              modelName={fields?.model_name}
            /> */}
            <Box style={{ marginTop: "2%" }}>
              <Typography
                fontFamily="EuclidCircularB-Medium"
                fontSize={16}
                color={colors.dark_gray}
                style={{ marginLeft: "20px" }}
                align="left"
                variant="body2"
              >
                Model Image:
              </Typography>
              <Grid
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "30px",
                }}
              >
                <img
                  alt=""
                  onClick={() =>
                    window.open(
                      fields?.model_image_path + fields?.image_name,
                      "_blank"
                    )
                  }
                  width={300}
                  height={200}
                  src={fields?.model_image_path + fields?.image_name}
                />
              </Grid>
            </Box>
            {/* {fields?.model_30_image_path?.length>0? */}
            {/* <Box style={{ marginTop: "2%" }}>
              <Typography
                fontFamily="EuclidCircularB-Medium"
                fontSize={16}
                color={colors.dark_gray}
                style={{ marginLeft: "20px" }}
                align="left"
                variant="body2"
              >
                Model 360 Degree Image:
              </Typography>

              <Grid sx={{ textAlign: "center" }}>
                <React360Viewer
                  imagesBaseUrl={
                    "https://freedo-backend-static.s3.ap-south-1.amazonaws.com/public/uploads/ModelImages/model-detail-view/68/"
                  }
                  imagesCount={4}
                  imagesFiletype=".png"
                  mouseDragSpeed={20}
                  imageFilenamePrefix={"imgg"}
                />
              </Grid>
            </Box> */}
            {/* :null} */}
          </>
        ) : props.addRole || props.editRole ? (
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <FilePicker
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleRightImage}
            />
            <FilePicker
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleLeftImage}
            />
            <FilePicker
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleFrontImage}
            />
            <FilePicker
              padding="2px"
              justifyContent="flex-start"
              width="230px"
              label={global.VehicleBackImage}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FilePicker
                padding="2px"
                justifyContent="flex-start"
                width="230px"
                label={global.DownloadGuide}
              />
              <FilePicker
                padding="2px"
                justifyContent="flex-start"
                width="230px"
                label={global.Vehicle360Images}
              />
            </Box>
            {props.editRole ? (
              <>
                <Grid container direction="row" justifyContent="flex-end">
                  <Button2
                    title={global.Preview}
                    style={{ marginLeft: "15px" }}
                  />
                  <Button2
                    title={global.Reset}
                    style={{ marginLeft: "15px" }}
                  />
                  <Button2
                    title={global.Delete}
                    style={{ marginLeft: "15px" }}
                  />
                </Grid>
                <Box
                  display="flex"
                  marginTop="2%"
                  flexDirection="row"
                  style={{
                    height: "110px",
                    touchAction: "auto",
                    userSelect: "none",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                  }}
                >
                  {/* <img style={{height:'100',width:'100'}} src={pleasurePlus1}/> */}

                  {/* <img src={pleasurePlus1} />

                  <img src={pleasurePlus2} />
                  <img src={pleasurePlus4} />

                  <img src={pleasurePlus5} />

                  <img src={pleasurePlus6} />

                  <img src={pleasurePlus7} />

                  <img src={pleasurePlus8} />

                  <img src={pleasurePlus9} />

                  <img src={pleasurePlus1} />

                  <img src={pleasurePlus1} /> */}
                </Box>
                {/* <ModelView
                  navigatePath={
                    fields?.download_guide_path + fields?.download_guide_name
                  }
                  sideHeading="Model Guide"
                  modelName="Pleasure Plus"
                /> */}
              </>
            ) : null}
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
          <Button2
            title={global.Back}
            back={true}
            onClick={navigateBack}
            style={{ marginLeft: "15px" }}
          />
          {props.editRole || props.addRole ? (
            <Button2 title={global.SaveModel} style={{ marginLeft: "15px" }} />
          ) : null}
        </Box>
      </form>
    </div>
  );
}

function ModelView(props: any) {
  
  // alert(props.navigatePath)
  const navigateDownload = () => {
    window.open(props.navigatePath, "_blank");
    // navigate()
  };
  return (
    <Box display="flex">
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          width: "137px",
          padding: "13px",
        }}
      >
        <Typography>{props.sideHeading}</Typography>
      </Grid>
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          width: "426px",
          padding: "13px",
        }}
      >
        <Typography>{props.modelName}</Typography>
      </Grid>
      <Grid
        style={{
          border: "1px solid rgb(223, 225, 229)",
          textAlign: "center",
          width: "60px",
          padding: "13px",
        }}
      >
        <FontAwesomeIcon
          onClick={navigateDownload}
          style={{ cursor: "pointer" }}
          icon={faEye}
        />
      </Grid>
    </Box>
  );
}
export default ViewModel;
