import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { cloudUploadIcon } from "../../../../../assets/exportImages";
import { RootState } from "../../../../../redux/reducers";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import ResponsiveDialog from "../../../../../sharedComponents/molecules/ResponsiveDialog/responsiveDialog";
import SelfieModalLoader from "../../../../../sharedComponents/molecules/SkeletonLoader/CustomerManagement/SelfieModalLoader";
import { colors } from "../../../../../themes/colors";
import {
  DocumentUploadConstants,
  KycTypes,
  KycVerificationModeDisplayName,
  KycVerificationStatusDisplayName,
  typographyConstants,
} from "../../../../constants/constants";
import {
  DefaultSelfieImg,
  NotesCheck,
  alertNotes,
  circularLineIcon,
  deleteKyc,
  imageDelete,
} from "../../../../constants/exportImages";
import {
  default as en,
  default as rentalEn,
} from "../../../../locale/rental-en.json";
import {
  getKYCSignedUrl,
  resetSelfieAttachmentId,
} from "../../../../redux/actions/customerManagementActions";
import { BootstrapTooltip } from "../../component/KycTrustScoreChips";
import styles from "./section.module.css";

export const SelfieTrustScoreModal = ({
  customerId,
  selfieTrustScore,
  selfiePayloadObj,
  individualKycDetailsData,
  individualKycDetailsLoader,
  handleSaveAndVerifyUploadToS3,
  handleManualKycVerification,
  handleDeleteCustomerKyc,
  handleUnverifyCustomerKyc,
  isFunctionalityBlocked,
  isKycDeletePermissionEnabled,
  handleDocsPreviewNavigation,
}: any) => {
  const dispatch = useDispatch();
  interface previewInterface {
    name: string;
    url: string;
  }
  const initialPreview: previewInterface = {
    name: "",
    url: "",
  };
  interface notesStatusInterface {
    isModified: boolean;
    upload: boolean;
    format: boolean;
    size: boolean;
  }
  const initialNotesStatus: notesStatusInterface = {
    isModified: false,
    upload: false,
    format: false,
    size: false,
  };
  const [selfiePreview, setSelfiePreview] = useState(initialPreview);
  const {
    uploadLoader,
    signedUrlFailedFiles,
    linearProgressValue,
    kycModalLoader,
  } = useSelector((state: RootState) => state.customerManagementReducer);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openDeletePreviewConfirmation, setOpenDeletePreviewConfirmation] =
    useState(false);
  const [notesStatus, setNotesStatus] = useState(initialNotesStatus);

  const isPresent = selfieTrustScore?.isPresent;
  const isVerified = selfieTrustScore?.isVerified;
  const verificationMode = selfieTrustScore?.verificationMode;
  const verificationStatus = selfieTrustScore?.isVerified;
  const kycId = selfieTrustScore?.kycId;
  const selfieDetails = individualKycDetailsData?.details;
  const selfieDetailsFiles =
    selfieDetails?.files.length > 0 ? selfieDetails?.files[0] : [];
  interface generateSignedUrlPayload {
    requestdata: {
      data: {
        fileNames: string[];
      };
    };
    filesData: {
      customerId: string;
      files: object[];
      fileNames: string[];
    };
    kycPayloadObj: any;
    kycSubType: string;
  }

  // toggle delete selfie attachment confirmation
  const toggleDeleteSelfieConfirmation = () => {
    setOpenDeleteConfirmation((prev) => !prev);
  };

  // toggle delte delete selfie preview confirmation
  const toggleDeleteSelfiePreviewConfirmation = () => {
    setOpenDeletePreviewConfirmation((prev) => !prev);
  };

  // Initiate selfie upload : generate signed url
  const generateSignedUrl = (fileNames: string[], files: any) => {
    const payload: generateSignedUrlPayload = {
      requestdata: {
        data: {
          fileNames,
        },
      },
      filesData: {
        customerId: customerId,
        files,
        fileNames,
      },
      kycPayloadObj: selfiePayloadObj,
      kycSubType: "",
    };
    dispatch(getKYCSignedUrl(payload));
  };

  const onDrop = useCallback(
    (acceptedFiles: object[], fileRejections: object[]) => {
      let files: object[] = [];
      let fileNames: string[] = [];
      let rejectedFiles: object[] = [];

      acceptedFiles?.map((file: any) => {
        files.push(file);
        fileNames.push(file.name);
        setSelfiePreview(() => {
          return { url: URL.createObjectURL(file), name: file.name };
        });
      });

      fileRejections.map((item: any) => {
        if (
          item?.errors[0]?.code === DocumentUploadConstants.FILE_INVALID_TYPE
        ) {
          toastr.warning(
            "Invalid File Type",
            rentalEn.CustomerManagement.FileInvalidErrorDlAadhaar
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            format: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.FILE_TOO_LARGE) {
          toastr.warning(
            "File too large",
            rentalEn.CustomerManagement.FileTooLargeError
          );
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        }
        if (item?.errors[0]?.code === DocumentUploadConstants.TO_MANY_FILES) {
          toastr.warning("Too many files", item?.errors[0]?.message);
          setNotesStatus((prev: any) => ({
            ...prev,
            isModified: true,
            size: false,
          }));
          return;
        } else rejectedFiles.push(item);
      });

      if (fileNames.length > 0) {
        generateSignedUrl(fileNames, files);
      }
    },
    []
  );

  const maxFileUpload = (): number => {
    return 1;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: maxFileUpload(),
    maxSize: 5000000,
    multiple: false,
    noClick: uploadLoader,
    noDrag: uploadLoader,
    disabled: selfieDetails?.files.length > 0 || isFunctionalityBlocked,
  });

  const resetNotesStatus = () => {
    setNotesStatus(initialNotesStatus);
  };

  // delete preview and attachment id
  const handleDeleteSelfieImage = () => {
    setSelfiePreview({ name: "", url: "" });
    toggleDeleteSelfiePreviewConfirmation();
    resetNotesStatus();

    // delete attachmentId from reducer
    dispatch(resetSelfieAttachmentId());
  };

  const isView = selfieTrustScore?.isPresent;

  useEffect(() => {
    if (selfiePayloadObj?.details?.selfieAttachmentId && selfiePreview.url) {
      setNotesStatus((prev: any) => ({
        ...prev,
        isModified: true,
        format: true,
        size: true,
        upload: true,
      }));
    }
  }, [selfiePayloadObj?.details?.selfieAttachmentId]);

  return (
    <>
      {kycModalLoader || individualKycDetailsLoader ? (
        <SelfieModalLoader />
      ) : (
        <>
          {isPresent &&
            !isFunctionalityBlocked &&
            isKycDeletePermissionEnabled && (
              <>
                {/* Delete Selfie */}
                <Stack alignItems={"flex-end"} padding={"0.5vw"}>
                  <img
                    src={deleteKyc}
                    alt="delete kyc icon"
                    onClick={() => toggleDeleteSelfieConfirmation()}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </>
            )}
          <Stack className={styles.KYCModal_Wrapper}>
            <Stack gap="20px">
              {/* Selfie Upload Box */}
              {uploadLoader ? (
                <Box className={styles?.documentUploadBox} {...getRootProps()}>
                  <Stack sx={{ width: "100%" }}>
                    <Box sx={{ padding: "5px", gap: "10px" }}>
                      <Stack gap="10px" textAlign={"center"}>
                        <Box>
                          <img
                            src={cloudUploadIcon}
                            width={"20px"}
                            height="20px"
                          />
                        </Box>

                        <LinearProgress
                          value={linearProgressValue}
                          variant="determinate"
                          // sx={{ width: "115px" }}
                        />
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Stack gap="4px" direction={"row"}>
                            <img
                              src={circularLineIcon}
                              width={"20px"}
                              height="20px"
                            />
                            <Typography
                              sx={{
                                lineHeight: "20px",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {"Uploading..."}
                            </Typography>
                          </Stack>
                          <Stack alignItems={"flex-end"}>
                            <Typography
                              sx={{
                                lineHeight: "12px",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#A9ACB4",
                              }}
                            >
                              {`${linearProgressValue}% of 100%`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <Stack
                  direction="row"
                  className={`${styles?.selfieUpload_Box}`}
                  sx={{
                    background:
                      "linear-gradient(90deg, rgba(8, 78, 85, 0.08) 0%, rgba(43, 199, 201, 0.08) 100%)",
                    alignitems: "center",
                    cursor: "pointer",
                  }}
                  {...getRootProps()}
                >
                  <Box className="SelfieAttachment">
                    {selfieDetails?.files.length > 0 ? (
                      <img
                        src={selfieDetails.files[0].signedUrl}
                        style={{
                          height: "80px",
                          objectFit: "cover",
                          width: "80px",
                          borderRadius: "50%",
                        }}
                        onClick={() =>
                          handleDocsPreviewNavigation(
                            selfieDetails.files[0]?.fileName,
                            ["img", "jpg", "png"],
                            selfieDetails.files[0].signedUrl
                          )
                        }
                      />
                    ) : selfiePreview.url && linearProgressValue === 100 ? (
                      <img
                        // key={selfiePreview}
                        src={selfiePreview.url}
                        style={{
                          height: "80px",
                          objectFit: "cover",
                          width: "80px",
                          borderRadius: "50%",
                        }}
                        onClick={() =>
                          handleDocsPreviewNavigation(
                            selfiePreview?.name,
                            ["img", "jpg", "png"],
                            selfiePreview.url
                          )
                        }
                      />
                    ) : (
                      <img
                        src={DefaultSelfieImg}
                        style={{
                          height: "80px",
                          objectFit: "cover",
                          width: "80px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Box>

                  {linearProgressValue === 100 && selfiePreview?.url ? (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <BootstrapTooltip title={selfiePreview.name}>
                        <Typography
                          variant={typographyConstants?.HEADING}
                          sx={{
                            width: "12vw",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {selfiePreview.name ?? "N/A"}
                        </Typography>
                      </BootstrapTooltip>

                      <img
                        src={imageDelete}
                        alt="delete image"
                        onClick={(event: any) => {
                          event.preventDefault();
                          event.stopPropagation();
                          toggleDeleteSelfiePreviewConfirmation();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      gap="6px"
                      alignItems={"center"}
                      width={"100%"}
                    >
                      {!isView && (
                        <>
                          <input {...getInputProps()} />
                          <img
                            src={cloudUploadIcon}
                            style={{ width: "20px", height: "20px" }}
                          />
                        </>
                      )}
                      <Typography variant={typographyConstants?.HEADING}>
                        {isView
                          ? selfieDetails.files.length > 0
                            ? selfieDetails.files[0].fileName
                            : "Name.png"
                          : en.CustomerManagement.KycSelfieUploadLabel}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              )}

              {/* Notes & verification */}
              {isView ? (
                <Stack gap="20px">
                  {/* status box */}
                  <Stack
                    sx={{
                      backgroundColor: colors.bg_light_gray,
                      borderRadius: "10px",
                    }}
                  >
                    <Box className={styles.selfie_View_Box}>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.text_black}
                      >
                        Verification Details
                      </Typography>{" "}
                    </Box>
                    <Box className={styles.selfie_status_Box}>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.tertiary_black}
                      >
                        Mode:
                      </Typography>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.text_black}
                      >
                        {verificationMode
                          ? KycVerificationModeDisplayName[
                              verificationMode as keyof typeof KycVerificationModeDisplayName
                            ]
                          : "N/A"}
                        {/* {verificationMode ?? "N/A"} */}
                      </Typography>
                    </Box>
                    <Box className={styles.selfie_status_Box}>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.tertiary_black}
                      >
                        Status:
                      </Typography>
                      <Typography
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.text_black}
                      >
                        {verificationStatus
                          ? KycVerificationStatusDisplayName.VERIFIED
                          : KycVerificationStatusDisplayName.UNVERIFIED}
                      </Typography>
                    </Box>
                  </Stack>

                  {/* For Future Use */}
                  {/* <Box className={styles.selfie_viewRequest}>
                    <img src={TooltipIcon} style={{ marginTop: "4px" }} />
                    <Box>
                      <Typography 
                        variant={typographyConstants?.SUBHEADING}
                        color={colors.secondary_light_gray}
                      >
                        You are not allow to see the details, you need initiate
                        the request.
                      </Typography>
                      <Typography
                        variant={typographyConstants.SUBHEADING}
                        color="#09AEAF"
                        sx={{ cursor: "pointer" }}
                      >
                        {" Send Request"}
                      </Typography>
                    </Box>
                  </Box> */}
                </Stack>
              ) : (
                <Stack gap="12px">
                  <Typography
                    variant={typographyConstants?.SUBHEADING}
                    color={"#292D32"}
                  >
                    Notes:
                  </Typography>
                  <Box>
                    <Stack direction="row" gap="5px" alignItems={"center"}>
                      {notesStatus?.isModified ? (
                        <>
                          {notesStatus?.upload ? (
                            <img
                              src={NotesCheck}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          ) : (
                            <img
                              src={alertNotes}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Box>
                            <FiberManualRecordIcon
                              sx={{
                                height: "0.5rem",
                                color: colors.tertiary_black,
                                width: "unset",
                              }}
                            />
                          </Box>
                        </>
                      )}
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors.tertiary_black}
                      >
                        Upload your selfie
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap="5px" alignItems={"center"}>
                      {notesStatus?.isModified ? (
                        <>
                          {notesStatus?.format ? (
                            <img
                              src={NotesCheck}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          ) : (
                            <img
                              src={alertNotes}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Box>
                            <FiberManualRecordIcon
                              sx={{
                                height: "0.5rem",
                                color: colors.tertiary_black,
                                width: "unset",
                              }}
                            />
                          </Box>
                        </>
                      )}
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors.tertiary_black}
                      >
                        Supported Formats: Jpeg, Png and jpg.
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap="5px" alignItems={"center"}>
                      {notesStatus?.isModified ? (
                        <>
                          {notesStatus?.size ? (
                            <img
                              src={NotesCheck}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          ) : (
                            <img
                              src={alertNotes}
                              alt={"Checked"}
                              title={"Accepted"}
                              style={{ height: "1rem" }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Box>
                            <FiberManualRecordIcon
                              sx={{
                                height: "0.5rem",
                                color: colors.tertiary_black,
                                width: "unset",
                              }}
                            />
                          </Box>
                        </>
                      )}
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors.tertiary_black}
                      >
                        File size should be less than 5 MB.
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Stack>

            {/* Upload Action & Notes */}
            {!isFunctionalityBlocked && !isView && (
              <Stack gap="5px">
                <CustomButton
                  variant="outlined"
                  sx={{ width: "100%" }}
                  label={"Save & verify now"}
                  onClick={() => handleSaveAndVerifyUploadToS3(KycTypes.SELFIE)}
                  disabled={
                    !selfiePayloadObj?.details?.selfieAttachmentId ||
                    linearProgressValue !== 100 ||
                    !selfiePreview.url
                  }
                />
              </Stack>
            )}
          </Stack>
        </>
      )}

      {openDeleteConfirmation && (
        <>
          {/* confirnmation dialog on status change  */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.deleteSelfieKyc}
            open={openDeleteConfirmation}
            handleClose={() => toggleDeleteSelfieConfirmation()}
            handleYes={() => handleDeleteCustomerKyc(KycTypes.SELFIE, kycId)}
          />
        </>
      )}

      {openDeletePreviewConfirmation && (
        <>
          {/* confirnmation dialog on status change  */}
          <ResponsiveDialog
            title={rentalEn.alertMessages.deleteSelfieKyc}
            open={openDeletePreviewConfirmation}
            handleClose={() => toggleDeleteSelfiePreviewConfirmation()}
            handleYes={() => handleDeleteSelfieImage()}
          />
        </>
      )}
    </>
  );
};
