import { GenericObject } from "../../../constants/constants";
import * as actionTypes from "../actionTypes";

const {
  GET_BRANCH_DATA_REQUESTED,
  GET_ALL_CITIES_REQUESTED,
  SET_SCREEN_HEADER_NAME,
  GET_MODEL_LIST_BY_BRANCH_REQUESTED,
  GET_MASTER_PLAN_DURATIONS_REQUESTED,
  GET_MODEL_DATA_REQUESTED,
  GET_MASTER_PAYMENT_MODE_REQUESTED,
  GET_ALL_STATES_REQUESTED,
  RENTALS_GET_CITY_DETAILS_REQUESTED,
  GET_CITY_BY_STATE_REQUESTED,
  GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED,
  CLEAR_ACTIVE_MODULE,
  ADD_ACTIVE_MODULE,
  GET_PAYMENT_STATUS_REQUESTED,
  CANCEL_PAYMENT_STATUS_SAGA,
  GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED,
  GET_STATES_AND_DISTRICT_BY_PINCODE_CLEAR,
} = actionTypes.COMMON_ACTIONS;

// set screen header name
export const setScreenHeaderName = (payload: any) => ({
  type: SET_SCREEN_HEADER_NAME,
  payload,
});

export const getAllCities = () => {
  return {
    type: GET_ALL_CITIES_REQUESTED,
  };
};

export const getAllBranches = (payload: any) => {
  return {
    type: GET_BRANCH_DATA_REQUESTED,
    payload,
  };
};

export const getAllBranchesByCity = (
  payload: any,
  advanceFilterData: boolean = false
) => {
  return {
    type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DATA_BY_CITIES_REQUESTED,
    payload,
    advanceFilterData,
  };
};

export const getAllVehicleModels = () => {
  return {
    type: GET_MODEL_DATA_REQUESTED,
  };
};

// Get plans available
export const getMasterPlanDurations = (payload = "") => {
  return {
    type: GET_MASTER_PLAN_DURATIONS_REQUESTED,
    payload,
  };
};

// Get model list by branch
export const getModelListByBranch = (
  payload?: any | undefined,
  advanceFilterData: boolean = false
) => ({
  type: GET_MODEL_LIST_BY_BRANCH_REQUESTED,
  payload,
  advanceFilterData,
});

export const getAllStates = () => {
  return {
    type: GET_ALL_STATES_REQUESTED,
  };
};

export const getStatesAndDistrictFromPincode = (payload: any) => {
  return {
    type: GET_STATES_AND_DISTRICT_BY_PINCODE_REQUESTED,
    payload,
  };
};

// clear pincode district data
export const clearStatesAndDistrictFromPincode = () => {
  return {
    type: GET_STATES_AND_DISTRICT_BY_PINCODE_CLEAR,
  };
};

// Get payment on basis on Branch
export const getMasterPaymentModes = (payload: any) => {
  return {
    type: GET_MASTER_PAYMENT_MODE_REQUESTED,
    payload,
  };
};

//get cities on the basis of state
export const getCityByState = (payload?: any) => {
  return {
    type: GET_CITY_BY_STATE_REQUESTED,
    payload,
  };
};

export const getCityDetails = (payload: string) => ({
  type: RENTALS_GET_CITY_DETAILS_REQUESTED,
  payload,
});

export const clearBranchesAndModels = () => {
  return {
    type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_BRANCHES_AND_MODELS,
  };
};

// set breadcrumbs
export const updateBreadcrumbs = (payload: any) => {
  return {
    type: ADD_ACTIVE_MODULE,
    payload,
  };
};

//clear breadcrumbs so it won't visible on main screen
export const clearBreadcrumbs = () => {
  return {
    type: CLEAR_ACTIVE_MODULE,
  };
};

//get booking cancellation reasons
export const getBookingCancellationReasons = () => {
  return {
    type: actionTypes.COMMON_ACTIONS.GET_BOOKING_CANCELLATION_REASONS_REQUESTED,
  };
};

//get cancellation charges
export const getCancellationCharges = (payload: any) => {
  return {
    type: actionTypes.COMMON_ACTIONS.GET_CANCELLATION_CHARGES_REQUESTED,
    payload,
  };
};

//clear cancellation charges so old charges is not visible
export const clearCancellationCharges = () => {
  return {
    type: actionTypes.COMMON_ACTIONS.CLEAR_CANCELLATION_CHARGES,
  };
};
//clear hubs list on reset data
export const clearHubsList = () => {
  return {
    type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_HUBS_LIST_DATA,
  };
};

// Master - get charges list
export const getMasterChargesList = () => ({
  type: actionTypes.COMMON_ACTIONS.GET_MASTER_CHARGES_LIST_REQUESTED,
});

//get payment modes on the basis of operator
export const getOperatorConfigByBranch = (payload: any) => {
  return {
    type: GET_OPERATOR_CONFIG_BY_BRANCH_REQUESTED,
    payload,
  };
};

//get payment status on the basis of id
export const getPaymentStatus = (payload: any) => {
  return {
    type: GET_PAYMENT_STATUS_REQUESTED,
    payload,
  };
};

//cancel payment status saga
export const cancelPaymentStatus = () => {
  return {
    type: CANCEL_PAYMENT_STATUS_SAGA,
  };
};

// Generate payment Link or qr code
export const paymentLinkGeneration = (payload: any) => {
  return {
    type: actionTypes.COMMON_ACTIONS.PAYMENT_LINK_GENERATED_REQUESTED,
    payload,
  };
};

// toggle the payment view modal
export const togglePaymentView = () => {
  return {
    type: actionTypes.COMMON_ACTIONS.TOOGLE_PAYMENT_VIEW,
  };
};

//for clear reducer keys
export const clearReducerDataRentalCommon = (payload: GenericObject) => {
  return {
    type: actionTypes.COMMON_ACTIONS.RENTAL_COMMOM_CLEAR_REDUCER_DATA,
    payload,
  };
};

export const getBranchDefaultConfig = (payload: any) => ({
  type: actionTypes.COMMON_ACTIONS.GET_BRANCH_DEFAULT_CONFIG_REQUESTED,
  payload,
});

//clear the branch default config
export const clearBranchDefaultConfig = () => ({
  type: actionTypes.COMMON_ACTIONS.CLEAR_BRANCH_DEFAULT_CONFIG,
});

// Fetch cities based on multiple states
export const getCitiesBasedOnMultipleStates = (payload: any) => ({
  type: actionTypes.COMMON_ACTIONS.GET_CITIES_BY_MULTIPLE_STATES_REQUESTED,
  payload,
});

export const setBookingReducerData = (payload: any) => {
  return {
    type: actionTypes.COMMON_ACTIONS.SET_BOOKING_REDUCER_DATA,
    payload,
  };
};
