import { RotateRightSharp } from "@mui/icons-material";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridEditBooleanCellProps,
  GridEventListener,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFilterDataFromLocalStorage,
  storeFilterDataInLocalStorage,
} from "../../../config/filterStorage";
import { LocalStorage } from "../../../constants/enum";
import { RootState } from "../../../redux/store";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../sharedComponents/atoms/InputFields/Input";
import RangePicker from "../../../sharedComponents/atoms/InputFields/InputRangePicker/InputRangeDatePicker";
import CustomSelect from "../../../sharedComponents/atoms/InputFields/InputSelect";
import { DataTable } from "../../../sharedComponents/templates/Tables/dataTable";
import { isArrayNotEmpty } from "../../../utils/helper";
import {
  BookingChargesSubCategories,
  BookingPaymentStatus,
  BookingStatusDisplay,
  BookingStatusEnum,
  filterStorageKeys,
  GenericObject,
  statusColorCode,
  typographyConstants,
} from "../../constants/constants";
import {
  CityInputIcon,
  fadedCalenderIcon,
  SearchIcon,
  WarehouseIcon,
} from "../../constants/exportImages";
import rentalEn from "../../locale/rental-en.json";
import { paymentManagementActions, rentalActions } from "../../redux/actions";
import {
  formatString,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  REGEX,
} from "../../utils/helper";
import { StyleObject } from "./StyleObject";
import { routesConstants } from "../../utils/RoutesConstants";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { colors } from "../../../themes/colors";
import SideDrawer from "../../../sharedComponents/templates/SideDrawer/SideDrawer";
import en from "../../locale/rental-en.json";
import RefundDetailsDrawer from "./sections/refundDetailsDrawer";
import { getrefundDetails } from "../../redux/actions/paymentManagementActions";

const { global, paymentManagement, tableHeaders } = rentalEn;

// Booking status array
const bookingStatusArray: GenericObject[] = [
  {
    name: "",
    displayName: paymentManagement?.allBookingStatus,
  },
  {
    name: BookingStatusEnum?.PENDING,
    displayName: BookingStatusDisplay?.PENDING,
  },
  {
    name: BookingStatusEnum?.UPCOMING,
    displayName: BookingStatusDisplay?.UPCOMING,
  },
  {
    name: BookingStatusEnum?.ONGOING,
    displayName: BookingStatusDisplay?.ONGOING,
  },
  {
    name: BookingStatusEnum?.ENDED,
    displayName: BookingStatusDisplay?.ENDED,
  },
  {
    name: BookingStatusEnum?.COMPLETED,
    displayName: BookingStatusDisplay?.COMPLETED,
  },
  {
    name: BookingStatusEnum?.CANCELLED,
    displayName: BookingStatusDisplay?.CANCELLED,
  },
];

// Payment status array
const paymentStatusArray: GenericObject[] = [
  {
    name: "",
    displayName: paymentManagement?.allPaymentStatus,
  },
  {
    name: BookingPaymentStatus?.SUCCESS,
    displayName: paymentManagement?.success,
  },
  {
    name: BookingPaymentStatus?.FAILURE,
    displayName: paymentManagement?.failure,
  },
  {
    name: BookingPaymentStatus?.PENDING,
    displayName: paymentManagement?.pending,
  },
  {
    name: BookingPaymentStatus?.CANCELLED,
    displayName: paymentManagement?.cancelled,
  },
];

// Initial state object for filter and state management
const initialState: GenericObject = {
  city: { name: "", displayName: global?.allCities },
  branches: { name: "", displayName: global?.allHubs },
  bookingStatus: { name: "", displayName: paymentManagement?.allBookingStatus }, // booking status
  status: { name: "", displayName: paymentManagement?.allPaymentStatus }, // payment status
  startDateTime: "", // start date
  endDateTime: "", // end date
  search: "", // booking number/ booking id/ order id
  userPhoneNumber: "",
  paginationModel: { page: 0, pageSize: 10 },
};

const PaymentManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux selector
  const {
    allCitiesDetails, // List of all cities available
    branches, // List of branches/hubs based on selected city
  } = useSelector((state: RootState) => state.rentalsCommonReducer);

  // Redux selector
  const {
    onLoad,
    paymentListData,
    paymentListApiData,
    downloadButtonLoader,
    refundDetails,
  } = useSelector((state: RootState) => state.paymentManagementReducer);

  // States to manage data
  const [fields, setFields] = useState<GenericObject>({ ...initialState });
  const [open, setOpen] = useState(false);

  // Ensure the rowCount value is stable during loading by memoizing it.
  // This prevents the rowCount from becoming undefined and resetting the page to zero.
  // Create a mutable reference to store the rowCount value.
  const rowCountRef: React.MutableRefObject<any> = useRef(
    paymentListApiData?.total || 0
  );

  // Memoize the rowCount value to avoid unnecessary changes during loading.
  // Update the rowCountRef only if surgeListApiResponseData?.pagination?.total is defined.
  // Return the stable rowCount from the rowCountRef.
  const rowCount: React.MutableRefObject<any> = useMemo(() => {
    if (paymentListApiData?.total !== undefined) {
      rowCountRef.current = paymentListApiData?.total;
    }
    return rowCountRef.current;
  }, [paymentListApiData?.total]);

  // Function to construct payload for fetching data
  const constructPayload = (fields: GenericObject): GenericObject => {
    const payload: GenericObject = {
      page: fields?.paginationModel?.page + 1, // MUI fields grid page starts from zero, so add 1
      pageSize: fields?.paginationModel?.pageSize,
      city: fields?.city?.name,
      branches: fields?.branches?.name ? [fields?.branches?.name] : null,
      bookingStatus: fields?.bookingStatus?.name,
      status: fields?.status?.name,
      startDateTime: fields?.startDateTime
        ? getTimestampAtStartOfDay(fields?.startDateTime)
        : "",
      endDateTime: fields?.endDateTime
        ? getTimestampAtEndOfDay(fields?.endDateTime)
        : "",
      search: fields?.search,
      userPhoneNumber: fields?.userPhoneNumber ? fields?.userPhoneNumber : "",
    };
    return payload;
  };

  // Effect to fetch initial data and set filters from local storage
  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const data = await getFilterDataFromLocalStorage(
          filterStorageKeys?.PAYMENT_MANAGEMENT_FILTER_DATA
        );
        if (data) {
          delete data.search;
          setFields((prev: any) => ({ ...prev, ...data }));

          // Fetch branches based on selected city data from local storage
          if (data?.city?.name) {
            dispatch(rentalActions.getAllBranches(data?.city));
          }

          // Construct payload and fetch payment list based on local storage data if user phone number is available
          if (data?.userPhoneNumber?.length === 10) {
            const payload: GenericObject = constructPayload(data);
            payload.userPhoneNumber = data?.userPhoneNumber;
            dispatch(
              paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
            );
            return;
          }

          // Construct payload and fetch payment list based on local storage data
          const payload: GenericObject = constructPayload(data);
          delete payload.userPhoneNumber;
          dispatch(paymentManagementActions.getPaymentList(payload));
        } else {
          const payload: GenericObject = {
            page: 1,
            pageSize: 10,
          };
          dispatch(paymentManagementActions.getPaymentList(payload));
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Initial api calls on component mount
    fetchFilterData();
  }, []);

  // Handler to update filter fields based on user selections
  const handleChange = (key: string, value: any) => {
    let data: GenericObject = {};

    if (key === "city") {
      // Reset branch, model, and dateType on city change
      data = {
        branches: { name: "", displayName: global?.allHubs },
      };

      // Fetch branches based on selected city data
      if (value?.name) {
        dispatch(rentalActions.getAllBranches(value));
      }
    }

    // Always update the state with the new key-value pair and additional data
    const newFields: GenericObject = {
      ...fields,
      [key]: value,
      ...data,
      paginationModel: initialState?.paginationModel, // Reset pagination on filter change
    };
    setFields(newFields);

    // Store updated data in local storage on user selections
    const storeData: GenericObject = {
      paymentManagementFilterData: { ...newFields },
    };
    delete storeData.search;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Construct payload and Fetch payment list data
    const payload: GenericObject = constructPayload(newFields);

    if (fields?.userPhoneNumber?.length === 10) {
      dispatch(
        paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
      );
    } else {
      delete payload.userPhoneNumber;
      dispatch(paymentManagementActions.getPaymentList(payload));
    }
  };

  // Start & end date handler
  const handleSpecificDateRangeSelection = (dateRange: any) => {
    let [startDateFromRangePicker, endDateFromRangePicker] = dateRange;
    setFields((prev: any) => ({
      ...prev,
      startDateTime: startDateFromRangePicker,
      endDateTime: endDateFromRangePicker,
    }));
    const newFields: GenericObject = {
      ...fields,
      startDateTime: getTimestampAtStartOfDay(startDateFromRangePicker),
      endDateTime: getTimestampAtEndOfDay(endDateFromRangePicker),
    };

    if (
      startDateFromRangePicker &&
      endDateFromRangePicker &&
      fields?.userPhoneNumber?.length === 10
    ) {
      const payload: GenericObject = constructPayload(newFields);
      payload.userPhoneNumber = fields?.userPhoneNumber;
      dispatch(
        paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
      );
    } else if (startDateFromRangePicker && endDateFromRangePicker) {
      const payload: GenericObject = constructPayload(newFields);
      delete payload.userPhoneNumber;
      dispatch(paymentManagementActions.getPaymentList(payload));
    }
  };

  // Columns configuration for MUI data grid
  const columns: GenericObject[] = [
    {
      field: "bookingNumber",
      headerName: tableHeaders?.bookingNo,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "transactionId",
      headerName: tableHeaders?.transactionId,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tempCreatedAt",
      headerName: tableHeaders?.createdDate,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: tableHeaders?.userName,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amountInRupee",
      headerName: tableHeaders?.amount,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <Tooltip
          title={formatString(
            BookingChargesSubCategories[
              params?.row
                ?.tempSubCategory as keyof typeof BookingChargesSubCategories
            ]
          )}
          arrow
        >
          <span>{params?.row?.amountInRupee}</span>
        </Tooltip>
      ),
    },
    {
      field: "paymentType",
      headerName: tableHeaders?.paymentType,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: tableHeaders?.paymentStatus,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridEditBooleanCellProps): any => {
        return (
          <span
            style={{
              // @ts-ignore
              color:
                statusColorCode[
                  params?.row?.status as keyof typeof statusColorCode
                ],
            }}
          >
            {params?.row?.status
              ? formatString(params?.row?.status)
              : global?.NA}
          </span>
        );
      },
    },
    {
      field: "tempPaidOn",
      headerName: tableHeaders?.paymentDate,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "refundDetails",
      headerName: tableHeaders?.refundDetails,
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "actions",
      getActions: (data: GridRenderCellParams<any>) => {
        return [
          <VisibilityIcon
            sx={{ color: colors.dark_gray }}
            onClick={() => {
              dispatch(getrefundDetails(data.id));
              toggleDrawer(data.id);
            }}
          />,
        ];
      },
    },
  ];

  // Handler for pagination model changes
  const handlePaginationModelChange = (paginationValue: any) => {
    const { page, pageSize } = paginationValue;
    setFields((prev: any) => ({
      ...prev,
      paginationModel: { page: page, pageSize: pageSize },
    }));

    if (fields?.userPhoneNumber?.length === 10) {
      // Store updated user phone number in local storage on user search
      const storeData: GenericObject = {
        paymentManagementFilterData: {
          ...fields,
          paginationModel: { page: page, pageSize: pageSize },
        },
      };
      delete storeData.search;
      storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

      // Construct payload and Fetch payment list data
      const payload: GenericObject = constructPayload(fields);
      payload.userPhoneNumber = fields?.userPhoneNumber;
      payload.page = page + 1;
      payload.pageSize = pageSize;
      dispatch(
        paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
      );
    } else {
      // Store updated data in local storage on user selections
      const storeData: GenericObject = {
        paymentManagementFilterData: {
          ...fields,
          paginationModel: { page: page, pageSize: pageSize },
        },
      };
      storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

      // Construct payload and Fetch payment list data
      const payload: GenericObject = constructPayload(fields);
      delete payload.userPhoneNumber;
      payload.page = page + 1;
      payload.pageSize = pageSize;
      dispatch(paymentManagementActions.getPaymentList(payload));
    }
  };

  // Handler for row click event in the data grid
  const handleRowClick: GridEventListener<"rowClick"> = (params: any) => {
    const { row } = params;
    navigate(`${routesConstants?.BOOKING_DETAILS}/${row?.bookingId}`);
  };

  // Debounced search function using useCallback to memoize the function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string, fields: GenericObject) => {
      if (fields?.userPhoneNumber?.length === 10) {
        const payload: GenericObject = constructPayload(fields);
        payload.search = searchQuery ? JSON.stringify(searchQuery) : "";
        payload.userPhoneNumber = fields?.userPhoneNumber;
        dispatch(
          paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
        );
      } else {
        const payload: GenericObject = constructPayload(fields);
        payload.search = searchQuery ? JSON.stringify(searchQuery) : "";
        dispatch(paymentManagementActions.getPaymentList(payload));
      }
    }, 1000), // Debounce time set to 1000ms (1 second)
    [fields?.search]
  );

  // Handle search input change
  const handleSearch = (e: any) => {
    // Validate alphanumeric input before updating state & searching
    if (REGEX?.strictAlphaNumericWithUnderscore?.test(e.target.value)) {
      // Update state with valid searched text
      setFields((prev: any) => ({
        ...prev,
        paginationModel: initialState?.paginationModel,
        search: e.target.value,
      }));
      // Execute debounced search if length of search query is 2 or more
      if (e.target.value?.length >= 3 || !e.target.value) {
        debouncedSearch(e.target.value, {
          ...fields,
          paginationModel: initialState?.paginationModel,
          search: e.target.value,
        });
      }
    }
  };

  //toggle Drawer
  const toggleDrawer = (id?: any) => {
    setOpen(!open);
  };

  // Function to handle the phone number search
  const handlePhoneNumberSearch = (e: any) => {
    setFields((prev: any) => ({ ...prev, userPhoneNumber: e.target.value }));

    if (e.target.value?.length === 10) {
      // Store updated user phone number in local storage on user search
      const storeData: GenericObject = {
        paymentManagementFilterData: {
          ...fields,
          userPhoneNumber: e.target.value,
        },
      };
      storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

      // Construct payload and Fetch payment list data
      const payload: GenericObject = constructPayload(fields);
      payload.userPhoneNumber = e.target.value;
      dispatch(
        paymentManagementActions.getPaymentListUsingPhoneNumber(payload)
      );
    } else if (e.target.value?.length === 0) {
      // Store updated data in local storage on user selections
      const storeData: GenericObject = {
        paymentManagementFilterData: { ...fields, userPhoneNumber: "" },
      };
      storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

      // Construct payload and fetch payment list data
      const payload: GenericObject = constructPayload(fields);
      delete payload.userPhoneNumber;
      dispatch(paymentManagementActions.getPaymentList(payload));
    }
  };

  // Function to handle downloading payment report
  const handleDownload = () => {
    const payload = constructPayload(fields);
    payload.page = 1;
    payload.pageSize = 1000;
    if (fields?.userPhoneNumber?.length === 10) {
      payload.userPhoneNumber = fields?.userPhoneNumber;
      dispatch(
        paymentManagementActions.getPaymentListUsingPhoneNumber(
          payload,
          "download"
        )
      );
    } else {
      dispatch(paymentManagementActions.getPaymentList(payload, "download"));
    }
  };

  // Handler to reset all filters
  const handleFilterReset = () => {
    setFields({ ...initialState });

    // Store initial state data in local storage reset filter button click
    const storeData: GenericObject = {
      paymentManagementFilterData: { ...initialState },
    };
    delete storeData.search;
    storeFilterDataInLocalStorage(LocalStorage?.Filter_Data, storeData);

    // Make api call with the default values
    const payload: GenericObject = {
      page: 1,
      pageSize: 10,
    };
    dispatch(paymentManagementActions.getPaymentList(payload));
  };

  return (
    <Stack spacing={2}>
      {/* Top Heading Section */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Tooltip title={global?.resetFilters} arrow>
            <RotateRightSharp
              fontSize="inherit"
              color="primary"
              sx={{ cursor: "pointer", fontSize: "25px" }}
              onClick={handleFilterReset}
            />
          </Tooltip>
          <CustomButton
            label={global?.download}
            variant="contained"
            sx={{ padding: "3px 22px", minWidth: 112 }}
            onClick={handleDownload}
            loading={downloadButtonLoader}
          />
        </Stack>
      </Stack>

      {/* Filter Section */}
      <Box sx={{ flexGrow: 1, alignItems: "center" }}>
        <Grid container spacing={1}>
          {/* City Dropdown */}
          <Grid item sm={12} md={4} lg={3}>
            <CustomSelect
              required
              icon={CityInputIcon}
              placeholder={global?.selectCity}
              value={fields?.city?.displayName ? fields?.city?.displayName : ""}
              choice={
                isArrayNotEmpty(allCitiesDetails)
                  ? [
                      { name: "", displayName: global?.allCities },
                      ...allCitiesDetails,
                    ]
                  : []
              }
              defaultKey={global?.displayName}
              handleChange={(value: any) => {
                handleChange("city", value);
              }}
              disabled={!isArrayNotEmpty(allCitiesDetails)}
            />
          </Grid>

          {/* Hub Dropdown */}
          <Grid item sm={12} md={4} lg={3}>
            <CustomSelect
              required
              icon={WarehouseIcon}
              placeholder={global?.selectHub}
              value={
                fields?.branches?.displayName
                  ? fields?.branches?.displayName
                  : ""
              }
              choice={[{ name: "", displayName: global?.allHubs }, ...branches]}
              defaultKey={global?.displayName}
              handleChange={(value: any) => {
                handleChange("branches", value);
              }}
              disabled={!fields?.city?.name || !isArrayNotEmpty(branches)}
            />
          </Grid>

          {/* Booking Status Dropdown */}
          {/* <Grid item sm={12} md={4} lg={3}>
            <CustomSelect
              required
              icon={WarehouseIcon}
              placeholder={paymentManagement?.selectBookingStatus}
              value={
                fields?.bookingStatus?.displayName
                  ? fields?.bookingStatus?.displayName
                  : ""
              }
              choice={bookingStatusArray}
              defaultKey={global?.displayName}
              handleChange={(value: any) => {
                handleChange("bookingStatus", value);
              }}
              disabled={!isArrayNotEmpty(bookingStatusArray)}
            />
          </Grid> */}

          {/* Payment Status Dropdown */}
          <Grid item sm={12} md={4} lg={3}>
            <CustomSelect
              required
              icon={WarehouseIcon}
              placeholder={paymentManagement?.selectPaymentStatus}
              value={
                fields?.status?.displayName ? fields?.status?.displayName : ""
              }
              choice={paymentStatusArray}
              defaultKey={global?.displayName}
              handleChange={(value: any) => {
                handleChange("status", value);
              }}
              disabled={!isArrayNotEmpty(paymentStatusArray)}
            />
          </Grid>

          {/* Start and End Date Range Picker */}
          <Grid item sm={12} md={4} lg={3}>
            <RangePicker
              selectsRange={true}
              placeholderText={paymentManagement?.selectStartAndEndDate}
              startDate={fields?.startDateTime}
              endDate={fields?.endDateTime}
              onChange={(update: any) =>
                handleSpecificDateRangeSelection(update)
              }
              maxDate={moment().toDate()}
              isClearable={false}
              icon={fadedCalenderIcon}
            />
          </Grid>

          {/* Search Box (transaction id) */}
          <Grid item sm={12} md={4} lg={3}>
            <Input
              placeholder={paymentManagement?.searchByBookingNumber}
              variant="outlined"
              value={fields?.search}
              iconStart={SearchIcon}
              inputProps={{ maxLength: 50 }}
              onChange={handleSearch}
              disabled={false}
              muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            />
          </Grid>

          {/* Search Box (customer phone number) */}
          <Grid item sm={12} md={4} lg={3}>
            <Input
              placeholder={paymentManagement?.customerPhoneNumber}
              variant="outlined"
              value={fields?.userPhoneNumber}
              iconStart={SearchIcon}
              inputProps={{ maxLength: 10 }}
              onChange={handlePhoneNumberSearch}
              disabled={false}
              muiTextFieldRootStyle={StyleObject?.muiTextFieldRootStyle}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Payment List MUi Data Grid Table Section */}
      <DataTable
        rows={paymentListData}
        columns={columns}
        rowCount={rowCount}
        paginationModel={fields?.paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        hideFooter={!isArrayNotEmpty(paymentListData)}
        onRowClick={handleRowClick}
        loading={onLoad}
      />
      <SideDrawer
        open={open}
        heading={en.paymentManagement.refundDetails}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={<RefundDetailsDrawer refundDetails={refundDetails} />}
      />
    </Stack>
  );
};

export default PaymentManagement;
