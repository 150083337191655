import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import Input from "../../../../../sharedComponents/atoms/InputFields/Input";
import { colors } from "../../../../../themes/colors";
import {
  BookingChargesSubCategories,
  GenericObject,
  typographyConstants,
} from "../../../../constants/constants";
import {
  AddDocIcon,
  DeleteIcon,
  GreenTickIcon,
  UploadFileIcon,
} from "../../../../constants/exportImages";
import { EndRideDrawerInterface } from "../../../../interfaces/bookingManagement";
import en from "../../../../locale/rental-en.json";
import { checkNumeric, checkWhiteSpace } from "../../../../utils/regex";
import styles from "../bookingDetails.module.css";
import { LinearProgressDocUpload } from "../components/linearProgress";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getFormattedINR } from "../../../../utils/helper";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";

// end ride side drawer ui
export const EndRideDrawerContent = ({
  bookingVehicleDetails,
  loading,
  errorFields,
  fields,
  handleOdometerReading,
  getRootProps,
  getInputProps,
  onClickImageDelete,
  handleButtonClick,
  isPendingChargeSucess,
  pendingChargesLoader,
  totalPendingCharges,
  bookingDocsLoader,
  docUploadLinearProgress,
  handleNavigateTaskAssignment,
  pendingAndProcessingCharges,
}: EndRideDrawerInterface) => {
  const { bookingDetails } = en;
  const { bookingChargesSubCategoriesDisplay } = bookingDetails;

  const { tasksummaryById } = useSelector(
    (state: RootState) => state.taskReducer
  );

  const getTooltipText = (data: GenericObject): string | JSX.Element => {
    if (data?.name) {
      return data?.name;
    }
    if (data?.subCategory === BookingChargesSubCategories.EXCESS_KM_CHARGES) {
      return (
        <Box>
          <Typography color={colors.white}>
            Excess Kilometers : {data.details.kmUtilized - data.details.kmLimit}{" "}
            km
          </Typography>
          <Typography color={colors.white}>
            Excess per Km charge :{" "} 
              {getFormattedINR(data?.amount / ( parseInt(data.details.kmUtilized) - parseInt(data.details.kmLimit)))}
          </Typography>
        </Box>
      );
    }
    if (data?.subCategory === BookingChargesSubCategories.EXCESS_HOUR_CHARGES) {
      return (
        <Box>
          <Typography color={colors.white}>
            Excess Hours : {data.details.excessHours} Hr
          </Typography>
          <Typography color={colors.white}>
            Excess per Hr charge :{" "}
            {getFormattedINR(
              parseInt(data?.amount) / parseInt(data.details.excessHours)
            )}
          </Typography>
        </Box>
      );
    }
    return "";
  };

  // table columns
  const columns: any[] = [
    {
      type: "actions",
      field: "modelDisplayName",
      headerName: "Title",
      width: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={getTooltipText(params.row)} arrow>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography color={colors.black}>
                {`${
                  bookingChargesSubCategoriesDisplay[
                    params.row
                      .subCategory as keyof typeof bookingChargesSubCategoriesDisplay
                  ]
                }`}
              </Typography>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "tempRegistration",
      headerName: "Amount Left",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      renderCell: (params: any) => {
        return (
          <span style={{ fontSize: "12px", color: colors.black }}>
            {" "}
            {getFormattedINR(params.row.amount)}
          </span>
        );
      },
    },
  ];
  return (
    <Stack height={"100%"}>
      <Stack padding={"15px"} zIndex={9999} gap={"15px"} marginBottom={"44px"}>
        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: "0px 12px 12px 12px",
          }}
        >
          <Box className={styles.EndRide_StartReading}>
            <Typography
              sx={{ fontWeight: 500, color: colors.tertiary_black }}
              variant={typographyConstants.BODY}
            >{`${en.bookingDetails.startReading} :`}</Typography>
            {loading ? (
              <Skeleton width="50px" height="30px" animation="wave" />
            ) : (
              <Typography
                sx={{ fontWeight: 400, color: colors.text_black }}
                variant={typographyConstants.BODY}
              >
                {`${bookingVehicleDetails?.bookingVehicleDetails?.currentReading} KM`}
              </Typography>
            )}
          </Box>
          <Stack sx={{ padding: "16px", gap: "14px" }}>
            <Stack gap="2px">
              <Input
                startAdornment={SpeedIcon}
                placeholder={en.bookingDetails.enterOdometerReading}
                value={fields?.odometerReading}
                required
                onChange={(e: any) => {
                  const value = e.target.value;
                  // Check if the first character is not '0'
                  if (
                    value === "" ||
                    (value[0] !== "0" &&
                      checkNumeric(value) &&
                      checkWhiteSpace(value) &&
                      e.target.value.length < 8)
                  ) {
                    handleOdometerReading(value);
                  }
                }}
                width="100%"
              />

              {errorFields.odometerReading && (
                <Typography
                  color={colors.primary_danger}
                  variant={typographyConstants.BODY}
                  fontSize={"10px"}
                  marginLeft="10px"
                >
                  {errorFields?.odometerReading}
                </Typography>
              )}
            </Stack>
            <Typography
              sx={{ fontWeight: 500, color: colors.tertiary_black }}
              variant={typographyConstants.BODY}
            >
              {en.bookingDetails.uploadEndOdometerImage}
            </Typography>
            {!fields?.isImgUploaded ? (
              <>
                {bookingDocsLoader ? (
                  <LinearProgressDocUpload progress={docUploadLinearProgress} />
                ) : (
                  <Box {...getRootProps()} className={styles.ImageUploadUi}>
                    <input {...getInputProps()} />
                    <img src={AddDocIcon} />
                  </Box>
                )}
              </>
            ) : (
              //   <Fade>
              <Box className={styles.fileUploadIN}>
                <Box display={"flex"} gap="20px" alignItems={"center"}>
                  <img src={UploadFileIcon} alt="" />
                  <Stack gap="10px">
                    <Typography
                      variant={typographyConstants?.SUBHEADING}
                      color={colors.text_secondary_gray}
                      sx={{
                        width: "150px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {fields?.fileName}
                    </Typography>
                    <Box display="flex" gap="20px" justifyContent={"flex-end"}>
                      <Typography
                        variant={typographyConstants?.BODY}
                        color={colors.text_secondary_gray}
                      >
                        <img src={GreenTickIcon} /> {en?.global?.uploaded}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <img
                  src={DeleteIcon}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickImageDelete()}
                />
              </Box>
              //   </Fade>
            )}
          </Stack>
        </Box>

        {/* //pending charges UI */}

        {pendingAndProcessingCharges?.length && isPendingChargeSucess ? (
          <DataTable
            columns={columns}
            rows={pendingAndProcessingCharges}
            getRowId={() => crypto.randomUUID()}
            columnHeaderHeight={45}
            hideFooter={true}
            disableColumnMenu
            disableColumnSorting
          />
        ) : (
          <></>
        )}

        {/* only ui need to discuss */}
        <Stack sx={{ backgroundColor: colors.white, borderRadius: "12px" }}>
          <Box
            padding={"16px"}
            boxShadow={"0px 1px 8px 0px #00000014"}
            borderRadius={"12px 12px 0px 0px"}
          >
            <Typography
              variant={typographyConstants.BODY}
              sx={{ color: colors.text_black, fontWeight: 400 }}
            >
              {tasksummaryById && tasksummaryById.length > 0
                ? en.bookingDetails.endRideTask.PresentAgentNote
                : en.bookingDetails.endRideTask.AbsentAgentNote}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant={typographyConstants.BODY}
                sx={{ fontWeight: 500, color: colors.tertiary_black }}
              >
                {en.bookingDetails.endRideTask.PrimaryAgent}
              </Typography>
              <Typography
                variant={typographyConstants.BODY}
                sx={{ fontWeight: 400, color: colors.text_black }}
              >
                {tasksummaryById && tasksummaryById.length > 0
                  ? tasksummaryById[0]?.primaryUser?.name
                  : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={typographyConstants.BODY}
                sx={{ fontWeight: 500, color: colors.tertiary_black }}
              >
                {en.bookingDetails.endRideTask.SecondaryAgent}
              </Typography>
              <Typography
                variant={typographyConstants.BODY}
                sx={{ fontWeight: 400, color: colors.text_black }}
              >
                {tasksummaryById && tasksummaryById.length > 0
                  ? tasksummaryById[0]?.secondaryUser?.name
                  : "N/A"}
              </Typography>
            </Box>
            <Typography
              variant={typographyConstants.BODY}
              sx={{
                color: colors.primary_dark_blue2,
                fontWeight: 500,
                cursor: "pointer",
              }}
              onClick={() => {
                handleNavigateTaskAssignment();
              }}
            >
              {tasksummaryById && tasksummaryById.length > 0
                ? en.bookingDetails.endRideTask.ChangeAgent
                : en.bookingDetails.endRideTask.AssignAgent}
            </Typography>
          </Box>
        </Stack>

        <Stack
          sx={{
            backgroundColor: colors.white,
            borderRadius: "12px",
            marginBottom: "50px",
          }}
        >
          {isPendingChargeSucess ? (
            <Box
              padding={"16px"}
              boxShadow={"0px 1px 8px 0px #00000014"}
              borderRadius={"12px"}
              borderBottom="1px solid #00000014"
            >
              <Typography
                variant={typographyConstants.BODY}
                sx={{ color: colors.text_black, fontWeight: 400 }}
              >
                {en?.bookingManagement?.totalCharges} : {totalPendingCharges}
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          {/* need to remove for now */}
          {/* <Box className={styles.endRideCheckChallan}>
            <Typography
              variant={typographyConstants.BODY}
              sx={{ color: colors.text_black, fontWeight: 400 }}
            >
              {en?.bookingManagement?.checkForChallan}
            </Typography>
            <Box
              onClick={() => {
                navigateToStepHandler();
                toggleEndRideDrawer();
              }}
            >
              <Typography
                variant={typographyConstants.BODY}
                sx={{
                  color: colors.primary_dark_blue2,
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {en?.global?.check}
              </Typography>
            </Box>
          </Box> */}
        </Stack>

        {/* end ride success
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "120px",
                height: "120px",
                backgroundColor: "#ECECEC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "200px",
              }}
            >
              <img src={Flags} />
            </Box>
            <Typography
              variant={typographyConstants.HEADING}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: colors.text_black,
              }}
            >
              Your ride has ended successfully !
            </Typography>
  
            <Typography
              variant={typographyConstants.SUBHEADING}
              sx={{
                color: colors.text_secondary_gray,
                textAlign: "center",
              }}
            >
              Clear your remaining charges before completing the ride
            </Typography>
          </Box> */}
      </Stack>

      <Box className={styles?.buttonSideDrawer}>
        <CustomButton
          label={
            isPendingChargeSucess
              ? en?.bookingManagement?.endRide
              : en?.bookingManagement?.calculatePendingCharges
          }
          variant="contained"
          style={{ width: "330px", marginLeft: "14px" }}
          onClick={handleButtonClick}
          loading={pendingChargesLoader}
          disabled={!fields?.odometerReading || errorFields?.odometerReading}
        />
      </Box>
    </Stack>
  );
};
