import { feedbackInterface } from "../../reducers/actionInterfaces";
import * as actionTypes from "../actionTypes";

interface FeedbackInterface {
  page: number | string;
  size: number | string;
  city_id?: number;
  location?: any;
  location_id?: number;
  token?: any;
  current_city?: any;
  current_location?: any;
}

export const feedback = (payload: FeedbackInterface) => ({
  type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_REQUESTED,
  payload,
});

export const locationData = (payload: any) => ({
  type: actionTypes.LOCATION_ACTIONS.LOCATION_DATA_REQUESTED,
  payload,
});

export const getFeedbacks = (payload: any) => ({
  type: actionTypes.FEEDBACK_ACTIONS.FEEDBACKS_DATA_REQUESTED,
  payload,
});

export const getFeedbackById = (payload: any) => ({
  type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_ID_REQUESTED,
  payload,
});

export const getFeedbackByBookingId = (payload: any) => ({
  type: actionTypes.FEEDBACK_ACTIONS.GET_FEEDBACKBY_BOOKING_ID_REQUESTED,
  payload,
});

export const submitExecutiveFeedBack = (payload: any) => ({
    type: actionTypes.FEEDBACK_ACTIONS.EXECUTIVE_FEEDBACK_REQUESTED,
    payload,
  });

  export const clearExecutiveFeedBack = () => ({
    type: actionTypes.FEEDBACK_ACTIONS.CLEAR_EXECUTIVE_FEEDBACK,

  });

  export const clearBookingFeedback = () => ({
    type: actionTypes.FEEDBACK_ACTIONS.CLEAR_BOOKING_FEEDBACK,

  });