import { call, put, takeLatest } from "redux-saga/effects";
import { operatorServices } from "../../services";
import * as actionTypes from "../actions/actionTypes";
import { handleMessage } from "../../utils/helper";
import { toastr } from "react-redux-toastr";
import operatorManagementServices from "../../services/operatorManagementServices";

function* getOperatorList(action: any): any {
  const data = yield call(operatorServices.getOperatorList, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_SUCCESS,
      payload: {
        operatorList: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_FAILED,
    });
  }
}
function* getBusinessTypeList(action: any): any {
  const data = yield call(operatorServices.getBusinessTypeList, action.payload);


  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_SUCCCESS,
      payload: {
        businessTypeList: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_FAILED,
    });
  }
}

function* getAllDocumentTypes(action: any): any {
  const data = yield call(operatorServices.getAllDocumentTypes, action.payload);


  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_ALL_DOCUMENT_TYPES_SUCCESS,
      payload: {
        getAllDocumentTypeList: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_ALL_DOCUMENT_TYPES_FAILED,
    });
  }
}

function* deleteOperator(action: any): any {
  const data = yield call(operatorServices.deleteOperator, action.payload);

  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.DELETE_OPERATOR_SUCCESS,
    });
    toastr.success("", handleMessage(data));
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.DELETE_OPERATOR_FAILED,
    });
  }
}

function* operatorActionClickSaga(action: any): any {
  const data = yield call(
    operatorManagementServices.operatorActionClickService,
    action.payload
  );
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));

    // let obj: any = {};
    // if (action?.payload?.status===2) {
    //   obj.id = data.data?.Result?.bookingData?.id;
    //   obj.warehouse_status = data.data?.Result?.bookingData?.warehouse_status;
    // } else {
    //   obj.id = data.data.Result.id;
    //   obj.warehouse_status = data.data.Result.warehouse_status;
    // }

    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .OPERATOR_ACTION_CLICK_SUCCESS,
      payload: {
        OperatorStatusData: data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .OPERATOR_ACTION_CLICK_FAILED,
    });
  }
}

function* operatorStoreDocuments(action: any): any {
  const data = yield call(
    operatorManagementServices.operatorstoreDocuments,
    action.payload
  );

  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .OPERATOR_STORE_DOCUMENT_SUCCESS,
      payload: {
        docData: data?.data?.Result,
      },
    });
    toastr.success("", handleMessage(data?.data?.Message[0]));
  } else {
    toastr.error("", handleMessage(data?.data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .OPERATOR_STORE_DOCUMENT_FAILED,
    });
  }
}

function* editOperator(action: any): any {
  const data = yield call(
    operatorManagementServices.editOperator,
    action.payload
  );

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_FAILED,
    });
  }
}

function* saveOperator(action: any): any {
  const data = yield call(
    operatorManagementServices.saveOperatorService,
    action.payload
  );

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_FAILED,
    });
  }
}

function* getOperatorDetails(action: any): any {
  const data = yield call(
    operatorManagementServices.getOperatorDetails,
    action.payload
  );

  if (data?.status === 200) {
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .GET_OPERATOR_DETAILS_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_FAILED,
    });
  }
}

function* linkedAccount(action: any): any {
  const data = yield call(
    operatorManagementServices.linkedAccount,
    action.payload
  );
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .LINKED_ACCOUNT_OPERATOR_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .LINKED_ACCOUNT_OPERATOR_FAILED,
    });
  }
}

function* stakeholder(action: any): any {
  const data = yield call(
    operatorManagementServices.stakeholder,
    action.payload
  );

  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .STAKEHOLDER_OPERATOR_SUCCESS,
      payload: {
        ...data.data,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_FAILED,
    });
  }
}

function* productConfiguration(action: any): any {
  const data = yield call(
    operatorManagementServices.productConfiguration,
    action.payload
  );
  if (data?.status === 200) {
    toastr.success("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .PRODUCT_CONFIGURATION_OPERATOR_SUCCESS,
      payload: {
        productConfigure: data?.data?.Result,
      },
    });
  } else {
    toastr.error("", handleMessage(data));
    yield put({
      type: actionTypes.OPERATOR_MANAGEMENT_ACTIONS
        .PRODUCT_CONFIGURATION_OPERATOR_FAILED,
    });
  }
}

function* OperatorSaga() {
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_LIST_REQUESTED,
    getOperatorList
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.DELETE_OPERATOR_REQUESTED,
    deleteOperator
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_ACTION_CLICK_REQUESTED,
    operatorActionClickSaga
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_BUSINESS_TYPE_REQUESTED,
    getBusinessTypeList
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_ALL_DOCUMENT_TYPES_REQUESTED,
    getAllDocumentTypes
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.OPERATOR_STORE_DOCUMENT_REQUESTED,
    operatorStoreDocuments
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.EDIT_OPERATOR_REQUESTED,
    editOperator
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.GET_OPERATOR_DETAILS_REQUESTED,
    getOperatorDetails
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.SAVE_OPERATOR_REQUESTED,
    saveOperator
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.LINKED_ACCOUNT_OPERATOR_REQUESTED,
    linkedAccount
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS.STAKEHOLDER_OPERATOR_REQUESTED,
    stakeholder
  );
  yield takeLatest(
    actionTypes.OPERATOR_MANAGEMENT_ACTIONS
      .PRODUCT_CONFIGURATION_OPERATOR_REQUESTED,
    productConfiguration
  );
}

export default OperatorSaga;
