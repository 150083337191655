import { useState, useEffect } from "react";
import {
  ButtonActions,
  UserActions,
  PromotionRoutes,
} from "../../constants/userActions";
import { Heading } from "../../components/typography";
import { RootState } from "../../redux/reducers";
import StickyHeaderTable from "../../components/stickeyHeader";
import { Status } from "../../components/status";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { AlertDialogComponent } from "../../components/AlertDialog";
import { AccessTypes, DefaultProps } from "../../interfaces/commonInterface";
import { checkAccess, checkActionAccess } from "../../utils/helper";
import moment from "moment";
import { getData, storeData } from "../../config/filterStorage";
import { LocalStorage } from "../../constants/enum";
import { UserStatus } from "../../constants/bookingContants";

function Promotions(props: DefaultProps) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeCoupon, setActiveCoupon] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const handlePage = (page: number) => {
    setPage(page);
  };
  const handleRows = (row: number) => {
    setSize(row);
  };
  const { promotions, global } = locale;
  const { cityloading, allCoupons } = useSelector(
    (state: RootState) => state.commonReducer
  );
  const columns = [
    {
      id: "name",
      label: global.Name,
      minWidth: 120,
      sortable: true,
      sortKey: "name",
      direction: "asc",
    },
    {
      id: "code",
      label: global.Code,
      minWidth: 120,
      sortable: true,
      sortKey: "code",
      direction: "asc",
    },
    {
      id: "type",
      label: global.Type,
      minWidth: 100,
      sortable: true,
      sortKey: "coupon_type",
      direction: "asc",
      customJson: true,
      render: (item: any) => {
        return (
          <text style={{ wordBreak: "keep-all" }}>
            {item?.coupon_code_type?.coupon_type}
          </text>
        );
      },
    },
    {
      id: "start_date",
      label: global.StartDate,
      minWidth: 120,
      customJson: true,
      render: (item: any) => {
        return (
          <div>
            {moment(item?.start_date, "YYYY-MM-DD").format("DD-MM-YYYY") ||
              null}
          </div>
        );
      },
    },
    {
      id: "end_date",
      label: global.EndDate,
      minWidth: 120,
      customJson: true,
      render: (item: any) => {
        return (
          <div>
            {moment(item?.end_date, "YYYY-MM-DD").format("DD-MM-YYYY") || null}
          </div>
        );
      },
    },
    {
      id: "discount",
      label: global.Discount,
      minWidth: 50,
      customJson: true,
      render: (item: any) => {
        return (
          <div>
            {item?.unit === 1 ? item.discount + " INR" : item.discount + " %"}
          </div>
        );
      },
    },
    {
      id: "status",
      label: global.Status,
      showSorting: true,
      minWidth: 120,
      sortable: true,
      sortKey: "status",
      direction: "asc",
      align: "center",
      customJson: true,
      render: (item: any) => {
        return (
          <div>
            <Status
              status={
                item.status === 1
                  ? UserStatus.Active
                  : item.status === 4
                  ? UserStatus.Hidden
                  : UserStatus.InActive
              }
            />
          </div>
        );
      },
    },
    {
      id: "action",
      label: global.Action,
      minWidth: 170,
      align: "center",
    },
  ];
  const [orderBy, setOrderBy] = useState<any>([]);
  const [mainColumns, setMainColumns] = useState<any>(columns);
  const sortData = async (param: any) => {
    let headerDetails: any = mainColumns;
    headerDetails[param.item].direction =
      headerDetails[param.item]?.direction === "asc" ? "desc" : "asc";
    setMainColumns(headerDetails);
    setOrderBy([
      {
        name: param.key,
        value: param.direction,
      },
    ]);
  };

  useEffect(() => {
    getData(LocalStorage.Promotion).then((data: any) => {
      const PromotionData = JSON.parse(data || "");
      if (PromotionData) {
        setSize(PromotionData?.size || 10);
        setPage(PromotionData?.page || 0);
      }
    });
  }, []);

  useEffect(() => {
    let data = {
      page: page,
      size: size,
    };
    storeData(LocalStorage.Promotion, data);
  }, [page, size]);

  const deleteCoupon = (id: any, status: number) => {
    dispatch(
      actions.deleteCoupon({
        id: id,
        status: status,
      })
    );
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.getCoupons({
        id: 0,
        orderBy: orderBy,
        allCoupons: true,
        page: page + 1,
        size: size,
      })
    );
  }, [orderBy, refresh, page, size]);

  return (
    <div className="container pd-20">
      <Heading
        title={promotions.Promotions}
        actionButtons={[
          ButtonActions.refresh,
          checkAccess(
            props.accessKey,
            AccessTypes.add,
            ButtonActions.addPromotion
          ),
        ]}
        onClickButton={(type: ButtonActions) => {
          if (type === ButtonActions.addPromotion) {
            navigate(PromotionRoutes.add);
          } else if (type === ButtonActions.refresh) {
            setRefresh(!refresh);
          }
        }}
      />
      <StickyHeaderTable
        pageSetter={handlePage}
        rowsPerPageSetter={handleRows}
        paddingAction="4px"
        rows={allCoupons?.data}
        handleSort={sortData}
        page={page}
        size={size}
        totalItems={allCoupons?.totalItems}
        // hidePagination={true}
        columns={mainColumns}
        loading={cityloading}
        actions={checkActionAccess(props.accessKey, [
          UserActions.view,
          UserActions.update,
          UserActions.delete,
          UserActions.stats,
        ])}
        onClickAction={(type: UserActions, id, vehicleId, allData) => {
          if (type === UserActions.view) {
            navigate("/promotions/view-promotions/" + allData.id);
          } else if (type === UserActions.update) {
            navigate("/promotions/edit-promotions/" + allData.id);
          } else if (type === UserActions.delete) {
            setOpenDialog(true);
            setActiveCoupon(allData.id);
          } else if (type === UserActions.stats) {
            navigate("/promotions/view-promotions-stats/" + allData.id);
          }
        }}
      />
      <AlertDialogComponent
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleClickYes={() => {
          setOpenDialog(false);
          deleteCoupon(activeCoupon, 3);
          setTimeout(() => {
            setRefresh(!refresh);
          }, 200);
        }}
      />
    </div>
  );
}

export default Promotions;
