import * as actionTypes from "../actionTypes";

// Destructuring actions
const {
  GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED,
  GET_ONGOING_BOOKINGS_DETAILS_REQUESTED,
  GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED,
  GET_BLOCKED_VEHICLES_DETAILS_REQUESTED,
  GET_VEHICLES_LIST_REQUESTED,
  UPDATE_VEHICLE_STATUS_REQUESTED,
  ADD_NEW_VEHICLE_DETAILS_REQUESTED,
  GET_VEHICLE_DETAILS_BY_ID_REQUESTED,
  GET_RENTALS_CHALLAN_LIST_REQUESTED,
  GET_LIVE_CHALLAN_LIST_REQUESTED,
  GET_ASSIGNED_IOT_DEVICES_REQUESTED,
  RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED,
  UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED,
  LINK_CHALLANS_REQUESTED,
  UNLINK_CHALLANS_REQUESTED,
  GET_PING_HISTORY_REQUESTED,
  GET_IOT_DEVICES_REQUESTED,
  ASSIGN_IOT_DEVICE_REQUESTED,
  FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED,
  GET_ALL_TRANSFER_VEHICLE_REQUESTED,
  GET_VEHICLE_TRANSFER_REQUEST_REQUESTED,
  TRANFER_VEHICLE_REQUEST_REQUESTED,
  GET_VEHICLE_COLOURS_REQUESTED,
  EDIT_VEHICLE_DETAILS_REQUESTED,
  DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED,
  DELETE_VEHICLE_REQUESTED,
  GET_VEHICLE_DOCUMENT_REQUESTED,
  POST_DOCUMENT_IN_S3_VEHICLE_REQUESTED,
  GET_SIGNED_URLS_VEHICLE_REQUESTED,
  GET_VEHICLE_IMAGES_REQUESTED,
  DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED,
  UPLOAD_BULK_UPLOAD_SHEET_REQUESTED,
  BULK_REQUEST_INITIATE_REQUESTED,
  CLEAR_ATTACHMENT_ID_BULK_UPLOAD,
  ADD_VEHICLE_DOCUMENTS_REQUESTED,
  DELETE_VEHICLE_DOCUMENTS_REQUESTED,
  DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED,
  CLEAR_VEHICLE_ATTACHMENT_DATA,
  REMOVE_ATTACHMENT_DATA,
  DELETE_DOC_UPLODED_SIGNED_URL,
  GET_VEHICLE_STATS_REQUESTED,
  CLEAR_VEHICLE_IMAGES_DATA,
  CLEAR_VEHICLE_REDUCER_KEY,
  CLEAR_IOT_SECTION,
} = actionTypes.VEHICLE_MANAGEMENT_ACTIONS;

// Fetch calendar data
export const getCalendarData = (payload: any) => ({
  type: GET_INVENTORY_CALENDAR_VIEW_DATA_REQUESTED,
  payload,
});

// Fetch ongoing bookings details
export const getOngoingBookingsDetails = (payload: any) => {
  return {
    type: GET_ONGOING_BOOKINGS_DETAILS_REQUESTED,
    payload,
  };
};

// Fetch upcoming bookings details
export const getUpcomingBookingsDetails = (payload: any) => {
  return {
    type: GET_UPCOMING_BOOKINGS_DETAILS_REQUESTED,
    payload,
  };
};

// Fetch blocked vehicles details
export const getBlockedVehiclesDetails = (payload: any) => {
  return {
    type: GET_BLOCKED_VEHICLES_DETAILS_REQUESTED,
    payload,
  };
};

// Fetch vehicles list (for dashboard)
export const getVehiclesList = (payload: any) => {
  return {
    type: GET_VEHICLES_LIST_REQUESTED,
    payload,
  };
};

// Update vehicle status
export const updateVehicleStatus = (payload: any) => {
  return {
    type: UPDATE_VEHICLE_STATUS_REQUESTED,
    payload,
  };
};

// Add new vehicle details
export const addNewVehicleDetails = (payload: any) => {
  return {
    type: ADD_NEW_VEHICLE_DETAILS_REQUESTED,
    payload,
  };
};

// Get vechile details by id
export const getVehicleDetailsById = (payload: any) => ({
  type: GET_VEHICLE_DETAILS_BY_ID_REQUESTED,
  payload,
});

//kyc kart api call for challan
export const getLiveChallansList = (payload: any) => ({
  type: GET_LIVE_CHALLAN_LIST_REQUESTED,
  payload,
});

//get challan list by vehicle registration no
export const getChallansList = (payload: any) => ({
  type: GET_RENTALS_CHALLAN_LIST_REQUESTED,
  payload,
});

//get iot device list assigned to vehicle
export const getAssignedIotDevices = (payload: any) => ({
  type: GET_ASSIGNED_IOT_DEVICES_REQUESTED,
  payload,
});

// unassign iot device from vehicle
export const unassignIotDevice = (payload: any) => ({
  type: RENTALS_UNASSIGN_IOT_DEVICE_REQUESTED,
  payload,
});

// update approve or reject vehicle status
export const updateApproveRejectvehicle = (payload: any) => {
  return {
    type: UPDATE_APPROVE_REJECT_VEHICLE_REQUESTED,
    payload,
  };
};

// to link challans to vehicle
export const linkChallan = (payload: any) => ({
  type: LINK_CHALLANS_REQUESTED,
  payload,
});

// to unlink challans of vehicle
export const unlinkChallan = (payload: any) => ({
  type: UNLINK_CHALLANS_REQUESTED,
  payload,
});

// action to get ping history of vehicle
export const getPingHistory = (payload: any) => ({
  type: GET_PING_HISTORY_REQUESTED,
  payload,
});

// Fetch IOT devices
export const getIotDevices = (payload: any) => {
  return {
    type: GET_IOT_DEVICES_REQUESTED,
    payload,
  };
};

// Assign IOT device
export const assignIotDevice = (payload: any) => {
  return {
    type: ASSIGN_IOT_DEVICE_REQUESTED,
    payload,
  };
};

// Fetch vehicle model list data based on city, hub, and vehicle category (i.e., motorcycle/ scooter)
export const fetchVehicleModelListData = (payload?: any | undefined) => {
  return {
    type: FETCH_VEHICLE_MODEL_LIST_DATA_REQUESTED,
    payload,
  };
};

// get all tranfer vehicle list
export const getAllTransfer = (payload: any) => {
  return {
    type: GET_ALL_TRANSFER_VEHICLE_REQUESTED,
    payload,
  };
};

// get list of all tranfer request initiated
export const getAllTranferVehicleRequests = (payload: any) => {
  return {
    type: GET_VEHICLE_TRANSFER_REQUEST_REQUESTED,
    payload,
  };
};

// Clear fetched vehicle model list data
export const clearReducerStates = () => {
  return {
    type: actionTypes.CLEAR_DATA_ACTIONS_TYPE.CLEAR_REDUCER_DATA,
  };
};

// transfer vehicle request
export const transferVehicleRequest = (payload: any) => {
  return {
    type: TRANFER_VEHICLE_REQUEST_REQUESTED,
    payload,
  };
};

// Fetch vehicle colours list data
export const getVehicleColoursListData = () => {
  return {
    type: GET_VEHICLE_COLOURS_REQUESTED,
  };
};

// Update vehicle details
export const editVehicleDetails = (payload: any) => {
  return {
    type: EDIT_VEHICLE_DETAILS_REQUESTED,
    payload,
  };
};

// Fetch vehicles list (on dashboard for downloading the data)
export const getDownloadableVehicleListData = (payload: any) => {
  return {
    type: DOWNLOAD_VEHICLE_LIST_DATA_ON_DASHBOARD_REQUESTED,
    payload,
  };
};

// Delete vehicle
export const deleteVehicle = (payload: any) => {
  return {
    type: DELETE_VEHICLE_REQUESTED,
    payload,
  };
};

// Clear add new vehicle details data from reducer
export const clearAddNewVehicleDetailsData = () => {
  return {
    type: actionTypes.CLEAR_DATA_ACTIONS_TYPE
      .CLEAR_ADD_NEW_VEHICLE_DETAILS_DATA,
  };
};

// Generates signed url to upload documents
export const getKYCSignedUrl = (payload: any) => {
  return {
    type: GET_SIGNED_URLS_VEHICLE_REQUESTED,
    payload,
  };
};

// move document to customer folder in S3
export const moveKYCDocumentToS3Folder = (payload: any) => {
  return {
    type: POST_DOCUMENT_IN_S3_VEHICLE_REQUESTED,
    payload,
  };
};

// action to get vehicle images
export const getVehicleImages = (payload: any) => ({
  type: GET_VEHICLE_IMAGES_REQUESTED,
  payload,
});

//action to clear vehicle images
export const clearVehiclImages = () => ({
  type: CLEAR_VEHICLE_IMAGES_DATA,
});

// action to get vehicle documents
export const getVehicleDocuments = (payload: any) => ({
  type: GET_VEHICLE_DOCUMENT_REQUESTED,
  payload,
});

// action to add vehicle documents
export const addVehicleDocuments = (payload: any) => ({
  type: ADD_VEHICLE_DOCUMENTS_REQUESTED,
  payload,
});

// action to delete vehicle documents
export const deleteVehicleDocuments = (payload: any) => ({
  type: DELETE_VEHICLE_DOCUMENTS_REQUESTED,
  payload,
});

// action to download vehicle documents
export const downloadVehicleDocuments = (payload: any) => ({
  type: DOWNLOAD_VEHICLE_DOCUMENTS_REQUESTED,
  payload,
});

// action to clear attachment ids in reducer
export const clearAttachmentIds = () => ({
  type: CLEAR_VEHICLE_ATTACHMENT_DATA,
});

// download the excel sheet in bulk upload
export const downloadExcelSheetBulkUpload = () => ({
  type: DOWNLOAD_BULK_UPLOAD_EXCEL_SHEET_REQUESTED,
});

//uplad bulk upload sheet (add bulk request)
export const uploadBulkUploadSheet = (payload: any) => ({
  type: UPLOAD_BULK_UPLOAD_SHEET_REQUESTED,
  payload,
});

//Bulk Request Initiated
export const bulkRequestInitiate = (payload: any) => ({
  type: BULK_REQUEST_INITIATE_REQUESTED,
  payload,
});

//clear attachment id on delete
export const clearAttachmentIdBulkUpload = (payload: any) => ({
  type: CLEAR_ATTACHMENT_ID_BULK_UPLOAD,
  payload,
});

// remove attachment ids in reducer at index
export const removeAttachmentIds = (payload: any) => ({
  type: REMOVE_ATTACHMENT_DATA,
  payload,
});

//action to delete attachment or doc uploded on signed url
export const deleteDocSignedUrl = (payload: any) => ({
  type: DELETE_DOC_UPLODED_SIGNED_URL,
  payload,
});

//get vehicle stats
export const getVehicleStats = (payload: any) => ({
  type: GET_VEHICLE_STATS_REQUESTED,
  payload,
});

// clear reducer key
export const clearVehicleReducerKey = (payload: any) => ({
  type: CLEAR_VEHICLE_REDUCER_KEY,
  payload,
});

// Clear iot section
export const clearIotSection = () => ({
  type: CLEAR_IOT_SECTION,
});
