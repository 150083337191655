import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";

interface SimpleChartInterface {
  data: Array<any>;
  heading: Array<string>;
  labels: Array<any>;
}
function SimpleChart({ data, heading, labels }: SimpleChartInterface) {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");
  return (
    <Grid
      sx={{ width: "100%", marginTop: "2rem", paddingBottom: "3rem" }}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid sx={{ width: isDesktopOrTablet ? "60%" : "80%" }}>
        <Grid
          sx={{ width: "100%" }}
          container
          display={"flex"}
          justifyContent={"center"}
        >
          <Typography
            sx={{ fontFamily: "EuclidCircularB-SemiBold", fontSize: "21px" }}
            variant="h6"
          >
            {locale.global.ChartData}
          </Typography>
        </Grid>
        <Grid container direction={"row"} sx={{ width: "100%" }}>
          <Grid className="inner-box bg-theme">
            <Typography
              sx={{
                fontFamily: "EuclidCircularB-SemiBold",
                fontSize: "16px",
                color: "white",
              }}
              variant="h6"
            >
              {heading[0]}
            </Typography>
          </Grid>
          <Grid className="inner-box bg-theme">
            <Typography
              sx={{
                fontFamily: "EuclidCircularB-SemiBold",
                fontSize: "16px",
                color: "white",
              }}
              variant="h6"
            >
              {heading[1]}
            </Typography>
          </Grid>
        </Grid>

        {labels?.map((each: any, index: any) => {
          return (
            <Grid container direction={"row"} sx={{ width: "100%" }}>
              <Grid className="inner-box">
                <Typography
                  sx={{
                    fontFamily: "EuclidCircularB-SemiBold",
                    fontSize: "14px",
                  }}
                  variant="h6"
                >
                  {each}
                </Typography>
              </Grid>
              <Grid className="inner-box">
                <Typography
                  sx={{
                    fontFamily: "EuclidCircularB-SemiBold",
                    fontSize: "14px",
                  }}
                  variant="h6"
                >
                  {data[index] ? data[index] : 0}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
export { SimpleChart };
