import { RotateRightSharp } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atoms/Buttons/CustomButtons";
import { HtmlTooltip } from "../../../../sharedComponents/atoms/Tooltips";
import MuiAccordion from "../../../../sharedComponents/molecules/Accordion/MuiAccordion";
import SideDrawer from "../../../../sharedComponents/templates/SideDrawer/SideDrawer";
import { colors } from "../../../../themes/colors";
import { BREADCRUMBS_RENTALS } from "../../../constants/activeModule";
import {
  BookingChargesSubCategories,
  BookingChargesSubCategoriesDisplayName,
  BookingStatusEnum,
  ChargeIcons,
  modificationType,
  SurgefactorType,
  tyesOfModeOfDelivery,
  typographyConstants,
} from "../../../constants/constants";
import rentalEn from "../../../locale/rental-en.json";
import { manualBookingActions } from "../../../redux/actions";
import {
  calculateAddressAddOnCharges,
  calculatePlanAndSlotCharges,
  cleanUpOrderAndPaymentData,
  clearModifyCharges,
  getBookingDetails,
  getModificationReasons,
} from "../../../redux/actions/bookingManagementActions";
import { getSlotPause } from "../../../redux/actions/manualBooking";
import {
  clearReducerDataRentalCommon,
  getBranchDefaultConfig,
  getMasterPlanDurations,
  updateBreadcrumbs,
} from "../../../redux/actions/rentalCommonAction";
import {
  formatString,
  getFormattedINR,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  istToTimestamp,
} from "../../../utils/helper";
import { routesConstants } from "../../../utils/RoutesConstants";
import {
  ModifyAdditionalHelmet,
  ModifyPackageAndSlots,
  ModifyPickupAndDropOff,
} from "./sections/modifyBookingAccordian";
import ModifyBookingSummarySideDrawer from "./sections/ModifyBookingSummarySideDrawer";
import { UpgradeVehicle } from "./sections/UpgradeVehicleSideDrawer";

const initialState = {
  selectedPackage: "",
  addressId: "",
  selectedHelmets: {},
  openPaymentModal: false,
  openUpgradeVehicleModal: false,
  disabledAccordians: {},

  // used in upgrade Vehicle
  isEndOdometerImgUploaded: false,
  isStartOdometerImgUploaded: false,
  odometerEndReading: "",
  odometerStartReading: "",
  fileName: "",
  vehicleModificationReason: { description: "" },
  selectedUpgradeVehicle: { displayName: "" },
  upgradeVehicleRegNo: {},
  oldVehicleImg: [],
  newVehicleImg: [],
};

// const RbacHasAccess: any = {
//   bookingDetails: RbacHelper.isAccessRightsProvided(
//     modules.BOOKING_MANAGEMENT,
//     bookingFunctionalities.BOOKING_DETAILS_TAB
//   ),
//   vehicleDetails: RbacHelper.isAccessRightsProvided(
//     modules.BOOKING_MANAGEMENT,
//     bookingFunctionalities.VEHICLE_DETAILS_TAB
//   ),
//   planAndPayments: RbacHelper.isAccessRightsProvided(
//     modules.BOOKING_MANAGEMENT,
//     bookingFunctionalities.PLAN_AND_PAYMENTS_TAB
//   ),
//   modifyBooking: RbacHelper.isAccessRightsProvided(
//     modules.BOOKING_MANAGEMENT,
//     bookingFunctionalities.MODIFY_BOOKINGS_TAB
//   ),
// };

export const ModifyBookingDetails = () => {
  const [fields, setFields] = useState<any>(initialState);
  const [expandedAccordian, setExpandedAccordian] = useState<any>({});
  const { id: bookingId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // data from booking management reducer
  const {
    bookingDetails = {},
    isPaymentLinkGenerated,
    modifiedSuccesfully,
  }: any = useSelector((state: RootState) => state.bookingManagementReducer);

  const accordianFields = [
    {
      title: "Modify Vehicle",
      body: () => <Box sx={{ marginTop: "50px", marginBottom: "50px" }}></Box>,
      onRowClick: () => {},
      key: "vehicle",
    },
    {
      title: "Modify Duration",
      body: () => (
        <ModifyPackageAndSlots
          fields={fields}
          handleChange={handleChange}
          setFields={setFields}
        />
      ),
      onRowClick: () => {},
      key: "package",
    },
    {
      title: "Modify Add Ons",
      body: () => (
        <ModifyAdditionalHelmet
          fields={fields}
          handleChange={handleChange}
          setFields={setFields}
        />
      ),
      onRowClick: () => {},
      key: "addOns",
    },

    {
      title:
        !bookingDetails.status ||
        bookingDetails.status === BookingStatusEnum.UPCOMING
          ? "Modify Pick Up"
          : "Modify Drop Off",
      body: () => (
        <ModifyPickupAndDropOff
          fields={fields}
          setFields={setFields}
          handleChange={handleChange}
        />
      ),
      onRowClick: () => {},
      key: "pickup",
    },
  ];

  useEffect(() => {
    if (bookingId) {
      dispatch(
        getBookingDetails({
          bookingId,
          getConfig: true,
          onSuccess: setBookingDataintoState,
        })
      ); // booking Details
      dispatch(getMasterPlanDurations("AddCustomField")); // package durations
      dispatch(getModificationReasons(modificationType.PLAN_MODIFICATION)); // fetch modification reasons
      dispatch(
        updateBreadcrumbs(BREADCRUMBS_RENTALS.MODIFY_BOOKING(bookingId))
      );
    }
    return () => {
      dispatch(clearModifyCharges());
    };
  }, [bookingId]);

  // get delivery slots on the basis of start date
  useEffect(() => {
    const { deliveryDetails } = bookingDetails;
    if (deliveryDetails?.type && bookingDetails?.branchDetails?.branchName) {
      dispatch(
        manualBookingActions.getDeliverySlots({
          deliveryType: deliveryDetails?.type,
          branchName: bookingDetails?.branchDetails?.branchName,
          bookingStartDate:
            istToTimestamp(fields.rideStartDate) ?? bookingDetails?.startDate,
        })
      );
    }
  }, [fields.rideStartDate]);

  // calculate charges on addOn and address change
  useEffect(() => {
    if (bookingDetails?.id) {
      // helmetPayload
      const payloadData: any = getAddOnsPayload();
      if (Object.keys(payloadData).length) {
        setFields((prev: any) => ({
          ...prev,
          disabledAccordians: {
            vehicle: true,
            package: true,
          },
        }));
        dispatch(
          calculateAddressAddOnCharges({
            data: payloadData,
            bookingId: bookingDetails?.id,
          })
        );
      } else {
        dispatch(clearModifyCharges());
      }
    }
  }, [
    JSON.stringify(fields.selectedHelmets),
    fields.addressId,
    fields.modeOfDelivery,
  ]);

  // calculate charges on plan and slot change
  useEffect(() => {
    if (bookingDetails?.id) {
      const payloadData = getPlansPayload();
      if (payloadData && Object.keys(payloadData).length) {
        // calculate plan and slot changes
        dispatch(
          calculatePlanAndSlotCharges({
            data: payloadData,
            bookingId: bookingDetails?.id,
          })
        );
        setFields((prev: any) => ({
          ...prev,
          disabledAccordians: {
            vehicle: true,
            pickup: true,
            addOns: true,
          },
        }));
      }
    }
  }, [
    fields?.selectedTimeSlot,
    fields?.rideEndDate,
    fields?.modificationReason?.id,
  ]);

  // set booking data into state
  const setBookingDataintoState = (bookingData: any) => {
    if (bookingData) {
      setFields((prev: any) => ({
        ...prev,
        selectedTimeSlot: bookingDetails?.deliveryDetails?.slot,
        rideStartDate: bookingData?.startDate,
        rideEndDate: bookingData?.endDate,
      }));

      // get branch config data , helemts available
      dispatch(getBranchDefaultConfig(bookingData?.branchDetails?.branchName));
      const currentDate = new Date();
      let payloadGetSlotPause = {
        startDate: currentDate.setDate(currentDate.getDate() - 30),
        endDate: currentDate.setDate(currentDate.getDate() + 180),
        branchName: bookingData?.branchDetails?.branchName,
      };
      dispatch(getSlotPause(payloadGetSlotPause));
    }
  };

  // compare previous and new revisions of booking duration
  const durationShallowCompare = () => {
    return (
      bookingDetails.startDate === istToTimestamp(fields.rideStartDate) &&
      bookingDetails.endDate === getTimestampAtEndOfDay(fields.rideEndDate) &&
      (isEqual(bookingDetails.deliveryDetails?.slot, fields.selectedTimeSlot) ||
        !fields.selectedTimeSlot)
    );
  };

  // get addons and address payload
  const getAddOnsPayload = () => {
    const payloadData: any = {};
    const addressPayloadEnum: any = {
      ONGOING: "return",
      UPCOMING: "delivery",
    };
    // convert helemt state data to array of helemts
    let payloadHelemtData = Object.keys(fields.selectedHelmets).reduce(
      (acc: any, helemtKey: any) => {
        if (fields.selectedHelmets[helemtKey]) {
          let data = { type: "", count: "" };
          data.type = helemtKey;
          data.count = fields.selectedHelmets[helemtKey];
          acc.push(data);
        }
        return acc;
      },
      []
    );

    const filteredAddons = payloadHelemtData?.filter((requestAddon: any) => {
      const addons = bookingDetails.addOns.find(
        (bookingAddon: any) =>
          bookingAddon.subType === requestAddon.type &&
          bookingAddon.count === requestAddon.count &&
          !bookingAddon.isComplementary
      );
      return !addons;
    });

    if (filteredAddons?.length) {
      payloadData.addOns = filteredAddons;
    }

    // check if data requested is same as existing
    const checkForDeliveryAndReturnPayload = () => {
      if (bookingDetails.status === BookingStatusEnum.UPCOMING) {
        // if booking is in upcoming then check for delivery
        return (
          (fields?.addressId &&
            fields?.addressId !==
              bookingDetails?.deliveryDetails?.address?.id) ||
          (fields?.modeOfDelivery !== bookingDetails?.deliveryDetails?.type &&
            fields?.modeOfDelivery === tyesOfModeOfDelivery.BRANCH)
        );
      } else {
        // if booking is in onGoing then check for returnDetails
        return (
          (fields?.addressId &&
            fields?.addressId !== bookingDetails?.returnDetails?.address?.id) ||
          (fields?.modeOfDelivery !== bookingDetails?.returnDetails?.type &&
            fields?.modeOfDelivery === tyesOfModeOfDelivery.BRANCH)
        );
      }
    };

    // fields?.addressId !== bookingDetails?.returnDetails?.address?.id

    // add payload only if previous booking address id and changed addressId is different or modeOfDelivery is different
    if (checkForDeliveryAndReturnPayload()) {
      if (fields?.modeOfDelivery === tyesOfModeOfDelivery.BRANCH) {
        payloadData[addressPayloadEnum[bookingDetails?.status]] = {
          type: fields.modeOfDelivery,
        };
      } else {
        payloadData[addressPayloadEnum[bookingDetails?.status]] = {
          type: fields.modeOfDelivery,
          addressId: fields.addressId,
        };
      }
    }
    return payloadData;
  };

  // plan and time slot payload
  const getPlansPayload = () => {
    const { selectedTimeSlot, rideStartDate, rideEndDate, modificationReason } =
      fields;
    if (!rideStartDate || !rideEndDate || !modificationReason?.id) return;

    if (durationShallowCompare()) {
      dispatch(clearModifyCharges());
      return;
    }
    // if rideStartDate and rideEndDate is not present in state then pass it from bookingDetails
    let payloadData: any = {
      startDate:
        getTimestampAtStartOfDay(rideStartDate) ??
        getTimestampAtStartOfDay(bookingDetails?.startDate),
      endDate:
        getTimestampAtEndOfDay(rideEndDate) ??
        getTimestampAtEndOfDay(bookingDetails?.endDate),
      reasonId: modificationReason?.id,
    };

    if (
      selectedTimeSlot &&
      !isEqual(bookingDetails.deliveryDetails?.slot, fields.selectedTimeSlot)
    )
      payloadData.slot = selectedTimeSlot;

    return payloadData;
  };

  // on accordian click
  const rowClick = (key: string) => {
    if (key === "vehicle") {
      setFields((prev: any) => ({
        ...prev,
        openUpgradeVehicleModal: !prev.openUpgradeVehicleModal,
      }));
      return;
    }

    setExpandedAccordian({
      [key]: !expandedAccordian[key],
    });
  };

  const handleChange = (key: string, value: any) => {
    setFields((prev: any) => ({ ...prev, [key]: value }));
  };

  const togglePaymentModal = () => {
    handleChange("openPaymentModal", !fields.openPaymentModal);
    if (isPaymentLinkGenerated) {
      navigate(`${routesConstants.BOOKING_DETAILS}/${bookingId}`);
      dispatch(
        clearReducerDataRentalCommon({
          isPaymentLinkGenerated: false,
          paymentLink: {},
          updatedStatus: "",
        })
      );
      dispatch(cleanUpOrderAndPaymentData());
    }
  };

  // toggle upgrade vehicle modal
  const toggleUpgradeVehicleModal = () => {
    if (isPaymentLinkGenerated || modifiedSuccesfully) {
      navigate(`${routesConstants.BOOKING_DETAILS}/${bookingId}`);
      dispatch(
        clearReducerDataRentalCommon({
          isPaymentLinkGenerated: false,
          paymentLink: {},
          updatedStatus: "",
        })
      );
      dispatch(cleanUpOrderAndPaymentData());
    }
    setFields((prev: any) => ({
      ...prev,
      openUpgradeVehicleModal: !prev.openUpgradeVehicleModal,
    }));
  };

  // reset accordian data
  const handleFilterReset = () => {
    setFields((prev: any) => ({
      ...prev,
      ...initialState,
      selectedPackage: prev.selectedPackage,
      modificationReason: {},
    }));
    // close all accordians
    setExpandedAccordian({});
    dispatch(clearModifyCharges());
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <Box sx={{ width: "55%" }}>
        <Box>
          {accordianFields.map((accordian) => {
            const { title, key, body } = accordian;
            const isDisabled =
              ((key === "addOns" || key === "package") &&
                bookingDetails.status === BookingStatusEnum.ONGOING) ||
              !bookingDetails.status;
            return (
              <MuiAccordion
                accordianTitle={title}
                isExpanded={!!expandedAccordian[key]}
                onExpand={() => rowClick(key)}
                isDisabled={!!fields.disabledAccordians[key] || isDisabled}
                containerStyle={{ margin: "10px" }}
                titleStyle={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                {body()}
              </MuiAccordion>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ width: "45%" }}>
        <Tooltip title={rentalEn?.global?.reset} arrow>
          <RotateRightSharp
            fontSize="large"
            color="primary"
            sx={{ cursor: "pointer", fontSize: "25px", float: "right" }}
            onClick={handleFilterReset}
          />
        </Tooltip>
        <ModifyBookingSummary togglePaymentModal={togglePaymentModal} />
      </Box>
      {/* Modify Booking Side Drawer */}
      <SideDrawer
        open={fields.openPaymentModal}
        toggleDrawer={togglePaymentModal}
        disableBackdropClick={!!isPaymentLinkGenerated || modifiedSuccesfully}
        disableEscapeKeyDown={!!isPaymentLinkGenerated || modifiedSuccesfully}
        heading={rentalEn.bookingDetails.modificationDetails}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <ModifyBookingSummarySideDrawer
            fields={fields}
            handleChange={handleChange}
            togglePaymentModal={togglePaymentModal}
            getAddOnsPayload={getAddOnsPayload}
            getPlansPayload={getPlansPayload}
          />
        }
      />
      {/* Upgrade Vehicle Side Drawer */}
      <SideDrawer
        open={fields.openUpgradeVehicleModal}
        toggleDrawer={toggleUpgradeVehicleModal}
        disableBackdropClick={!!isPaymentLinkGenerated || modifiedSuccesfully}
        disableEscapeKeyDown={!!isPaymentLinkGenerated || modifiedSuccesfully}
        heading={rentalEn.bookingDetails.modificationDetails}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <UpgradeVehicle
            fields={fields}
            handleChange={handleChange}
            setFields={setFields}
            toggleUpgradeVehicleModal={toggleUpgradeVehicleModal}
          />
        }
      />
    </Box>
  );
};

// modify summary shows charges information
export const ModifyBookingSummary = (props: any) => {
  const { togglePaymentModal, isPaymentScreen } = props;
  const { modifyBookingCharges = {} }: any = useSelector(
    (state: RootState) => state.bookingManagementReducer
  );
  const { amountToPay, charges, addOnDetails, surgeDetails } =
    modifyBookingCharges;
  const modifyCharges = [
    ...(charges || []),
    { subCategory: "Total", amount: modifyBookingCharges?.amountToPay },
  ];

  if ((!amountToPay && amountToPay !== 0) || !charges || !charges?.length)
    return null;
  return (
    <Box>
      <Typography variant={typographyConstants.SUBHEADING}>
        {rentalEn.bookingManagement.summary}
      </Typography>
      <Box
        sx={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "8px",
          marginTop: "10px",
          borderColor: colors.link_Water,
        }}
      >
        {modifyCharges?.map((item: any, index: number) => {
          const { subCategory } = item;
          const isHelmetSubcategory = subCategory?.includes(
            BookingChargesSubCategories.HELMET_CHARGES
          );
          const isSurgeSubcategory = subCategory?.includes(
            BookingChargesSubCategories.SURGE_CHARGES
          );

          return (
            <Box
              sx={{
                borderStyle: "solid",
                borderColor: colors.link_Water,
                borderWidth:
                  modifyCharges.length - 1 === index
                    ? "0px"
                    : "0px 0px 1px 0px",
                padding: "16px 17px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant={typographyConstants.SUBHEADING}
                sx={{
                  fontWeight:
                    modifyCharges.length - 1 !== index ? "400" : "550",
                }}
              >
                {subCategory === "Total"
                  ? subCategory
                  : BookingChargesSubCategoriesDisplayName[
                      subCategory as keyof typeof BookingChargesSubCategoriesDisplayName
                    ] || rentalEn.global.others}
                {/* tooltip for surge charges */}
                {isSurgeSubcategory ? (
                  <>
                    <Tooltip
                      arrow
                      title={<Box>{formatString(surgeDetails?.type)}</Box>}
                    >
                      <span style={{ color: colors.optional }}>
                        {surgeDetails.type ===
                        SurgefactorType.INCREASE_BY_AMOUNT
                          ? ` (+${getFormattedINR(surgeDetails.value)}) `
                          : ` (x${surgeDetails.value}) `}
                        <img src={ChargeIcons["SURGE_CHARGES"]} />
                      </span>
                    </Tooltip>
                  </>
                ) : null}

                {/* Addons breakup in case of Helmet charges */}
                {isHelmetSubcategory ? (
                  <HtmlTooltip
                    title={
                      <Box sx={{ width: 200 }}>
                        {isHelmetSubcategory ? (
                          <>
                            <Box color="inherit">
                              <b>{rentalEn.bookingDetails.addons}</b>
                            </Box>
                            {addOnDetails?.map((value: any, key: number) => (
                              <li key={key}>
                                {formatString(value?.type)}: {value.count} X{" "}
                                {getFormattedINR(value?.price)}
                              </li>
                            ))}
                          </>
                        ) : null}
                      </Box>
                    }
                  >
                    <img
                      src={ChargeIcons["HELMET_CHARGES"]}
                      style={{ marginLeft: "8px" }}
                      alt=""
                    />
                  </HtmlTooltip>
                ) : null}
              </Typography>
              <Typography
                variant={typographyConstants.SUBHEADING}
                sx={{
                  fontWeight:
                    modifyCharges.length - 1 !== index ? "400" : "550",
                }}
              >
                {getFormattedINR(item.amount)}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {!isPaymentScreen ? (
        <CustomButton
          variant="contained"
          label={rentalEn.buttonLabels.proceed}
          sx={{ marginTop: "30px", width: "100%" }}
          onClick={togglePaymentModal}
        />
      ) : null}
    </Box>
  );
};
