import * as actionTypes from "../actionTypes";

interface ReportInterface {
  page?: number | string;
  size?: number | string;
  city_id?: any;
  ToDate?: any;
  FromDate?: any;
  order_by_clause?: any;
  is_download?: number;
  select_date?: any;
  startDate?: any;
  endDate?: any;
  reportType?: any;
  allData?: any;
  requiredCsvFields?: any;
  cityId?: number;
  requests?: Array<string>;
  filters?: any;
}

export const getUserReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GET_USER_REPORTS_REQUESTED,
  payload,
});

export const getBookingReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GET_BOOKING_REPORTS_REQUESTED,
  payload,
});

export const getPaymentReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GET_PAYMENT_REPORTS_REQUESTED,
  payload,
});

export const resetAllData = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.RESET_ALLDATA,
  payload,
});
export const resetUserData = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.RESET_USER_REPORT_DATA,
  payload,
});

export const resetPaymentData = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.RESET_PAYMENT_REPORT_DATA,
  payload,
});

export const resetBookingData = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.RESET_BOOKING_REPORT_DATA,
  payload,
});

export const getGenericReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GET_GENERIC_REPORTS_REQUESTED,
  payload,
});

export const getPaymentReconciliationReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GETPAYMENTRECONCILIATION_REPORTS_REQUESTED,
  payload,
});

export const downloadUserReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_USER_REPORTS_REQUESTED,
  payload,
});

export const downloadBookingReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_BOOKING_REPORTS_REQUESTED,
  payload,
});

export const downloadPaymentReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENT_REPORTS_REQUESTED,
  payload,
});

export const downloadPaymentReconciliationReports = (
  payload: ReportInterface
) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_PAYMENTRECON_REPORTS_REQUESTED,
  payload,
});

export const downloadRevenueReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_REVENUE_REPORTS_REQUESTED,
  payload,
});

export const downloadGenericReports = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.DOWNLOAD_GENERIC_REPORTS_REQUESTED,
  payload,
});

export const getAllGenericReportKeys = (payload: ReportInterface) => ({
  type: actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_KEYS_REQUESTED,
  payload,
});

export const getAllGenericReportList = () => ({
  type: actionTypes.GET_REPORTS.GET_ALL_GENERIC_REPORT_LIST_REQUESTED,
});

export const clearDownloadStatus = () => {
  return {
    type: actionTypes.GET_REPORTS.RESET_DOWNLOAD_STATUS,
  };
};
