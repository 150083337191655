import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { logoPngIcon } from "../../../assets/exportImages";
import { RootState } from "../../../redux/reducers";

import { useSelector } from "react-redux";
import { actions } from "../../../redux/index";
import {
  AppSwitcher,
  BikeLogo,
  NotificationBell,
  SettingsIcon,
} from "../../../freedo-x-src/constantsX/exportImagesX";
import "./FreedoXLayout.css";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HistoryIcon from "@mui/icons-material/History";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { storeData } from "../../../config/filterStorage";
import { AccessDenied, WrongUrl } from "../../../screens";
import ResponsiveDialog from "../ResponsiveDialog/responsiveDialog";
import {
  fetchAllCitiesX,
  getAllModelsX,
  getAllWarehouseNameX,
  getChargesCategories,
} from "../../../freedo-x-src/redux/actions";
import { colors } from "../../../freedo-x-src/themes/colors";
import { getLoggedInDateTime } from "../../../freedo-x-src/utils/helper";
import { OutlinedIconButtonX } from "../../../freedo-x-src/components/muiButtonsX";
// const drawerWidth = navigator.userAgent.includes("Mobile") ? 275 : "17vw";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { isArray } from "lodash";
import { logoutSessionType } from "../../../constants/enum";
import { refreshDrawerScreenData } from "../../../redux/actions";
import { typographyConstants } from "../../../rentals-src/constants/constants";
import { rentalActions } from "../../../rentals-src/redux/actions";
import { getWaiveOffReasonsListRevamp } from "../../../rentals-src/redux/actions/bookingManagementActions";
import {
  clearBreadcrumbs,
  getAllCities,
  getMasterChargesList,
} from "../../../rentals-src/redux/actions/rentalCommonAction";
import { routesConstants } from "../../../rentals-src/utils/RoutesConstants";
import { CustomButton } from "../../atoms/Buttons/CustomButtons";
import RbackHelper from "../../../utils/helperRBAC";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AppLoader from "../AppLoader";
import { getFullName, getInitials } from "../../../rentals-src/utils/helper";

interface SettingMenuErr {
  label: string;
  link: string;
  icon?: JSX.Element;
}

const drawerWidth = 275;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// array for setting menu
const settingMenuErr: SettingMenuErr[] = [
  {
    label: "Change Password",
    link: routesConstants.CHANGE_PASSWORD,
    icon: <LockOpenIcon sx={{ color: colors?.primary_dark_blue2 }} />,
  },
];

export default function MiniDrawer(props: any) {
  const theme = useTheme();
  const { drawerRoutes, appRoutes } = props;
  const [open, setOpen] = useState(true);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { userData, isFreedoX } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  let booking_info: any = {};
  const { screenHeaderName } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  const [appSwitch, setappSwitch] = useState<null | HTMLElement>(null);
  const [settingEl, setsettingEl] = useState<null | HTMLElement>(null);
  const [profileEl, setProfileEl] = useState<null | HTMLElement>(null);
  const [toggleProfileEl, settoggleProfileEl] = useState<boolean>(false);
  const [isActiveModuleExpanded, setIsActiveModuleExpanded] = useState<any>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showNestedDrawer, setShowNestedDrawer] = useState<any>({});
  const { activeModule } = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { isAuthenticated, isLoggedIn, isUserDataSet } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const handleAppSwitch = (event: React.MouseEvent<HTMLElement>) => {
    setappSwitch(event.currentTarget);
  };
  const handleAppSwitchClose = () => {
    setappSwitch(null);
  };
  const handleSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setsettingEl(event.currentTarget);
  };
  const handleSettingClose = () => {
    setsettingEl(null);
  };
  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileEl(event.currentTarget);
    settoggleProfileEl(!toggleProfileEl);
  };
  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleRentalSwitch = () => {
    handleAppSwitchClose();
    navigate("/dashboard", { replace: true });
    dispatch(actions.setUserFeedoRentalsData({}));
    storeData("isFreedoX", false);
  };
  const handleFreedoXSwitch = () => {
    handleAppSwitchClose();
    navigate("/booking-list", { replace: true });
    dispatch(actions.setUserFeedoXData({}));
    storeData("isFreedoX", true);
  };
  const handleChangePassword = () => {
    handleSettingClose();
    navigate("/change-password");
  };
  const handleLogoutModalClose = () => {
    setOpenLogoutModal(false);
  };
  const handleLogOut = () => {
    handleLogoutModalClose();
    dispatch(
      actions.userLogout({
        data: {
          data: {
            sessionType: logoutSessionType.CURRENT,
          },
        },
        navigate,
      })
    );
  };
  const handleLogoutOpenDialog = () => {
    setOpenLogoutModal(true);
  };
  const handleClickOutside = () => {
    if (toggleProfileEl) settoggleProfileEl(false);
  };

  const setActiveScreenHeader = (headerName: string) => {
    dispatch(rentalActions.setScreenHeaderName({ headerName }));
  };

  // Sidebar Parent Menu - Handle Default Behaviour
  // useEffect(() => {
  //   let parentRoute = {};
  //   if (activeModule.parent.drawerKey) {
  //     parentRoute = {
  //       [activeModule.parent.drawerKey]: !![activeModule.parent.drawerKey],
  //     };
  //   }
  //   setShowNestedDrawer((prev: any) => ({
  //     ...prev,
  //     ...parentRoute,
  //   }));
  // }, [activeModule.parent.drawerKey]);

  /* Fetch Models & Branch */
  useEffect(() => {
    if (isUserDataSet) {
      if (isFreedoX) {
        dispatch(getAllModelsX());
        dispatch(getAllWarehouseNameX());
        dispatch(fetchAllCitiesX());
        dispatch(getChargesCategories());
      } else {
        //  all cities API - used in admin rental
        dispatch(getAllCities());
        dispatch(getMasterChargesList());
        // fetch all vehicle models
        dispatch(rentalActions.getAllVehicleModels());
        // waive off reasons
        dispatch(getWaiveOffReasonsListRevamp({}));
      }
    }
  }, [isUserDataSet, isFreedoX]);

  useEffect(() => {
    setScreenHeaderNameOnLoad();
    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, [location?.pathname]);

  const setScreenHeaderNameOnLoad = () => {
    let isActiveInChildRoute: any;
    const activeRoute = drawerRoutes.find((route: any) => {
      if (route.types) {
        isActiveInChildRoute = route.types.find((childRoute: any) => {
          return location.pathname.includes(childRoute.path);
        });
      }
      if (isActiveInChildRoute) return true;
      return location.pathname.includes(route.path);
    });

    // check if active route header exists in child routes
    if (isActiveInChildRoute) {
      return setActiveScreenHeader(isActiveInChildRoute.key);
    }
    if (activeRoute?.key) return setActiveScreenHeader(activeRoute.key);

    // check in custom routes if active route is not present in side navbar
    const activeRouteInAppRoutes = appRoutes.find((route: any) => {
      if (route.path !== "/") return location.pathname.includes(route.path);
      return false;
    });
    if (activeRouteInAppRoutes)
      return setActiveScreenHeader(activeRouteInAppRoutes.key);
  };

  const checkAccessRightsForNestedDrawer: any = (nestedDrawers: any) => {
    if (!isArray(nestedDrawers)) return false;
    // return true if the nested drawers have access
    return nestedDrawers.some((nestedDrawer: any) => {
      if (RbackHelper.canAccessSideDrawer(nestedDrawer?.moduleKey)) {
        return true;
      }
      return checkAccessRightsForNestedDrawer(nestedDrawer.types);
    });
  };

  if (!isUserDataSet) {
    return <AppLoader />;
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }} onClick={handleClickOutside}>
      <CssBaseline />

      {/* LOGOUT MODAL */}
      <ResponsiveDialog
        fullScreen={false}
        title="Are you sure you want to Logout?"
        handleClose={handleLogoutModalClose}
        open={openLogoutModal}
        fullWidth="true"
        maxWidth="md"
      >
        <CustomButton
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          autoFocus
          onClick={handleLogOut}
        >
          Yes
        </CustomButton>
        <CustomButton
          style={{ margin: "0 auto", display: "flex", color: colors.black }}
          onClick={handleLogoutModalClose}
          autoFocus
        >
          No
        </CustomButton>
      </ResponsiveDialog>

      {/* HEADER  */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "unset",
          boxShadow: "none",
          zIndex: "999",
          // backdropFilter: "blur(8px)",
        }}
      >
        <Toolbar
          sx={[
            {
              backdropFilter: "blur(8px)",
              position: "relative",
            },
            !open && {
              left: "64px",
              width: "calc(100% - 64px)",
            },
            // !open && { left: "64px", width: "calc(100% - 64px)" },
          ]}
        >
          {/* DRAWER TOGGLE BUTTON */}
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              zIndex: "999",
              background: !isFreedoX
                ? `#053C3D !important`
                : `linear-gradient(90deg, #1bd8da 1.67%, rgba(27, 196, 166, 0.95) 123.92%)`,
              ...(!open && { marginLeft: "20px" }),
            }}
            className={
              open
                ? "DrawerToggleBtnExpanded DrawerToggleButton"
                : "DrawerToggleBtnCollapsed DrawerToggleButton"
            }
          >
            <MenuIcon
              className="drawerToggleBarIcon"
              style={{
                color: "#fff",
                width: "0.9em",
                height: "0.9em",
              }}
            />
            {open ? (
              <KeyboardBackspaceIcon
                className="hoverDrawerToggleIcon"
                style={{ color: "#fff", width: "0.9em", height: "0.9em" }}
              />
            ) : (
              <ArrowRightAltIcon
                className="hoverDrawerToggleIcon"
                style={{ color: "#fff", width: "0.9em", height: "0.9em" }}
              />
            )}
          </IconButton>

          {/* MODULE NAME & BREADCRUMS */}
          <Typography
            variant={isFreedoX ? "h6" : typographyConstants.HEADINGXL}
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {screenHeaderName}
            {activeModule?.parent?.name ? (
              <>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(activeModule?.parent?.path)}
                    style={{ color: "#09CACC" }}
                  >
                    {activeModule?.parent?.name ?? ""}
                  </Link>
                  <Typography color="text.primary">
                    {" "}
                    {activeModule?.name ?? ""}
                  </Typography>
                </Breadcrumbs>
              </>
            ) : (
              <></>
            )}
            {/* new breadcrumbs component */}
            {activeModule && isArray(activeModule) ? (
              <Breadcrumbs aria-label="breadcrumb">
                {activeModule.map((module) => {
                  if (!module.active)
                    return (
                      <Link
                        underline="hover"
                        color="inherit"
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(module?.path)}
                        style={{ color: "#09CACC" }}
                      >
                        {module?.name ?? ""}
                      </Link>
                    );
                  return (
                    <Typography color="text.primary">
                      {" "}
                      {module?.name ?? ""}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
            ) : null}
          </Typography>

          {/* RIGHT BUTTONS */}
          <Box className="headerIconsContainer">
            <IconButton className="headerIcons" title="Notifications">
              <img src={NotificationBell} alt="NotificationBell" />
            </IconButton>

            <IconButton
              aria-label="User Settings"
              aria-controls="settings-menu"
              aria-haspopup="true"
              onClick={handleSettingsMenu}
              className="headerIcons"
              title="Settings"
            >
              <img src={SettingsIcon} alt="Settings Icon" />
            </IconButton>

            <Menu
              id="settings-menu"
              anchorEl={settingEl}
              open={Boolean(settingEl)} // Boolean state to determine if the menu is open based on anchorEl}
              onClose={handleSettingClose}
              sx={{ marginTop: "7px" }}
            >
              {/* Menu items that appear inside the Menu */}
              {settingMenuErr?.map((action: any) => {
                return (
                  <>
                    {
                      <MenuItem
                        key={action?.label}
                        onClick={() => {
                          navigate(action.link);
                          handleSettingClose();
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={4}>
                            {action?.icon}
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              variant={typographyConstants.SUBHEADING}
                              sx={{
                                padding: "0px",
                                color: colors?.primary_dark_blue2,
                              }}
                            >
                              {action.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    }
                  </>
                );
              })}
            </Menu>

            <IconButton
              aria-label="Application Switcher"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleAppSwitch}
              className="headerIcons"
              title="App Switcher"
            >
              <img src={AppSwitcher} alt="App Switcher Icon" />
            </IconButton>

            <IconButton
              aria-label="Profile Switcher"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              className="headerIcons"
              title="Profile"
            >
              <Avatar
                sx={{
                  width: "25px",
                  height: "25px",
                  textTransform: "uppercase",
                  fontSize: "1.5rem",
                  backgroundColor: "unset",
                  color: "#000",
                }}
              ></Avatar>
            </IconButton>

            {/* APP SWITCHER DROPDOWN */}
            <Menu
              id="menu-appbar"
              anchorEl={appSwitch}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(appSwitch)}
              onClose={handleAppSwitchClose}
              sx={{
                display: "flex",
                padding: "20px",
                gap: "10px",
                cursor: "pointer",
              }}
              className="AppSwitcher"
            >
              {userData?.authorizedLobs?.includes("LTR") && (
                <MenuItem
                  onClick={handleRentalSwitch}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <IconButton>
                    <Avatar sx={{ color: "#fff", background: "#000" }}>
                      R
                    </Avatar>
                  </IconButton>
                  Rentals
                </MenuItem>
              )}
              {userData?.authorizedLobs?.includes("YOR") && (
                <MenuItem
                  onClick={handleFreedoXSwitch}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <IconButton>
                    <Avatar sx={{ color: "#fff", background: "#000" }}>
                      Y
                    </Avatar>
                  </IconButton>
                  YOR
                </MenuItem>
              )}
            </Menu>

            {/* Settings Dropdown */}
            {/* <Menu
              id="settings-menu"
              anchorEl={settingEl}
              keepMounted
              open={Boolean(settingEl)}
              onClose={handleSettingClose}
              sx={{
                display: "flex",
                padding: "20px",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <MenuItem
                onClick={handleChangePassword}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                Change Password
              </MenuItem>
            </Menu> */}

            {/* PROFILE DROPDOWN */}
            {toggleProfileEl ? (
              <Box id="profile-menu" className="profileDropdownXCustom">
                <Stack className="DetailWrapper" gap="10px">
                  <Stack className="UserDetailWrapperCustom" gap="10px">
                    <Stack className="ProfileAvatar" direction="row" gap="5px">
                      <Avatar
                        sx={{
                          width: "35px",
                          height: "35px",
                          textTransform: "uppercase",
                          fontSize: "20px",
                          backgroundColor: "#000",
                          color: "#fff",
                        }}
                      >
                        {getInitials(getFullName(userData?.userDetails?.name))}
                      </Avatar>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        fontSize="20px"
                        color="black"
                        title={userData?.userDetails?.name}
                      >
                        Hi, {userData?.userDetails?.name}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      className="ProfileLastSeenCustom"
                      gap="5vh"
                    >
                      <Stack direction="row" gap="3px">
                        <HistoryIcon
                          style={{
                            width: "16px",
                            height: "16px",
                            marginTop: "2px",
                            color: "black",
                          }}
                        />
                        <Typography
                          variant="caption"
                          fontSize="12px"
                          color="black"
                        >
                          Last Seen
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="caption"
                          fontSize="12px"
                          color="black"
                        >
                          {userData?.lastActivity
                            ? getLoggedInDateTime(
                                userData?.lastActivity,
                                "date"
                              )
                            : getLoggedInDateTime(new Date(), "date")}
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize="12px"
                          color="black"
                        >
                          {userData?.lastActivity
                            ? getLoggedInDateTime(
                                userData?.lastActivity,
                                "hours"
                              )
                            : getLoggedInDateTime(new Date(), "hours")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack className="ProfileActionWrapperCustom" gap={"10px"}>
                    <Stack>
                      <OutlinedIconButtonX
                        icon={PowerSettingsNewIcon}
                        onClick={handleLogoutOpenDialog}
                        label="Logout"
                        className="logoutButtonX"
                        border="error"
                        hoverStyle="error"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>

      {/* SIDE DRAWER */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{ zIndex: "9" }}
        PaperProps={{
          sx: {
            backgroundColor: "unset",
            padding: "0 10px",
          },
        }}
      >
        <DrawerHeader className="brandLogoContainer" sx={{ margin: "8px" }}>
          {open ? (
            <img
              className="brandLogo"
              src={logoPngIcon}
              alt="Freedo Bike logo"
            />
          ) : (
            <img
              className="brandLogo"
              src={BikeLogo}
              alt="Freedo Bike logo"
              style={{ margin: "auto", position: "absolute", left: "15px" }}
            />
          )}
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          {drawerRoutes.map((route: any, index: number) => {
            const Icon = route?.icon;
            const ImgComp = route?.imgComp;
            if (
              !isFreedoX &&
              !RbackHelper.canAccessSideDrawer(route.moduleKey) &&
              !isArray(route.types)
            )
              return null;

            // check if the child/nested drawer have access to the user or not
            if (
              !isFreedoX &&
              isArray(route.types) &&
              !checkAccessRightsForNestedDrawer(route.types)
            )
              return null;

            return (
              <>
                <ListItem
                  key={route.key}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                  className={
                    // activeModule?.parent?.drawerKey?.toLowerCase() ===
                    // route.key.toLowerCase()
                    // check which module is active
                    location.pathname
                      .toLocaleLowerCase()
                      ?.includes(route.path.toLocaleLowerCase())
                      ? isFreedoX
                        ? "activeParentModuleYOR "
                        : "activeParentModuleDrawerLTR "
                      : ""
                  }
                  onClick={(e) => {
                    if (route?.types) {
                      setShowNestedDrawer((prev: any) => ({
                        ...prev,
                        [route.key]: !prev[route.key],
                      }));
                    } else {
                      setActiveScreenHeader(route.key);
                      navigate(route.path);
                      // dispatch page level data refresh logic
                      dispatch(refreshDrawerScreenData());
                    }
                    setIsActiveModuleExpanded({
                      ...isActiveModuleExpanded,
                      [route.path]: !isActiveModuleExpanded[route.path],
                    });
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {ImgComp ? (
                        <ImgComp
                          currentColor={
                            location.pathname
                              .toLocaleLowerCase()
                              ?.includes(route.path.toLocaleLowerCase())
                              ? "#ffffff"
                              : ""
                          }
                        />
                      ) : null}
                      {route.img ? <img src={route.img} alt="" /> : ""}
                    </ListItemIcon>
                    <ListItemText
                      primary={route.key}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {/* Caret Icon */}
                    {open && route.types ? (
                      !isActiveModuleExpanded[route.path] ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )
                    ) : (
                      <></>
                    )}
                  </ListItemButton>
                </ListItem>
                {route.types && showNestedDrawer[route.key] ? (
                  <>
                    {/* add logic to display block */}
                    <Box sx={{ paddingLeft: "50px", display: "block" }}>
                      {route.types.map((childItem: any) => {
                        if (
                          !isFreedoX &&
                          !RbackHelper.isAccessRightsProvided(
                            childItem.moduleKey
                          )
                        )
                          return null;
                        return (
                          <ListItemButton
                            key={childItem?.key}
                            onClick={(e) => {
                              // activeModulehandler(childItem);
                              setActiveScreenHeader(childItem.key);
                              navigate(childItem?.path);
                              dispatch(refreshDrawerScreenData());
                            }}
                            sx={{ py: 0, minHeight: 32, display: "block" }}
                          >
                            <ListItemText
                              primary={childItem?.key}
                              // primary={childItem?.accessKey}
                              primaryTypographyProps={{
                                color:
                                  // activeModule?.activeRoute ==
                                  // childItem?.accessKey
                                  // check which child item is selected
                                  location.pathname
                                    .toLocaleLowerCase()
                                    ?.includes(
                                      childItem.path.toLocaleLowerCase()
                                    )
                                    ? "#053C3D"
                                    : "#8A8A8E",
                              }}
                            />
                          </ListItemButton>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}
          {/* <DatePicker label="name" name="startDate" /> */}
        </List>
      </Drawer>

      {/* CONTENT */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, padding: "24px 10px !important" }}
      >
        <DrawerHeader />

        {props?.routes}
        <Routes>
          {/* {isFreedoX ? ( */}
          {appRoutes.map((route: any, i: number) => {
            // if (route?.stausAllowed?.indexOf(booking_info?.status) === -1) {
            //   return (
            //     <Route
            //       key={i}
            //       path={route.path}
            //       element={<WarningComponent />}
            //     />
            //   );
            // }
            return (
              <Route
                key={i}
                path={route.path}
                element={
                  isFreedoX ||
                  RbackHelper.isAccessRightsProvided(
                    route.moduleKey,
                    route.functionalityKey
                  ) ? (
                    route.component
                  ) : (
                    <AccessDenied moduleKey={route?.moduleKey} />
                  )
                }
              />
            );
          })}
          {/* ) : (
            <></>
          )} */}
          <Route path="*" element={<WrongUrl />} />
        </Routes>
      </Box>
    </Box>
  );
}
