import { Box, Stack, Typography } from "@mui/material";
import {
  BookingStatusEnum,
  BookingStatusColorCodes,
  typographyConstants,
  statusColorCode,
} from "../../../../constants/constants";
import { colors } from "../../../../../themes/colors";
import BookingsCard from "../../../../../sharedComponents/templates/BookingCard";
import {
  CardBackGround,
  TotalBookingsIcon,
  TotalKmDrivenIcon,
  TotalPaymentsIcon,
} from "../../../../constants/exportImages";
import { DataTable } from "../../../../../sharedComponents/templates/Tables/dataTable";
import { GridColDef } from "@mui/x-data-grid";
import { getFormattedINR, istToNormalDate } from "../../../../utils/helper";
import CustomSelect from "../../../../../sharedComponents/atoms/InputFields/InputSelect";
import en from "../../../../locale/rental-en.json";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "../../../../utils/RoutesConstants";

export const BookingDetails = ({
  bookingStats,
  bookingList,
  handlePaginationModelChange,
  tableFilters,
  handleStatusFilterChange,
  bookingListLoader,
  StatusArray,
}: any) => {
  const { CustomerManagement } = en;
  const { displayBookingStatus } = CustomerManagement;
  const navigate = useNavigate();

  // Columns for data grid
  const columns: GridColDef[] = [
    {
      field: "bookingNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Booking Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {params?.row?.bookingNumber ? params?.row?.bookingNumber : "N/A"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: en?.global?.vehicleModel,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.modelDisplayName
              ? params?.row?.modelDisplayName
              : "N/A"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "vehicleRegistrationNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Vehicle Booked",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            {params?.row?.vehicleRegistrationNumber
              ? params?.row?.vehicleRegistrationNumber
              : "N/A"}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {istToNormalDate(params?.row?.startDate)}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <span>
          {" "}
          <div
            style={{
              color: colors.text_black,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {istToNormalDate(params?.row?.endDate)}
          </div>
        </span>
      ),
      flex: 1,
      resizable: false,
    },
    {
      field: "status",
      cellClassName: "booking-status-cell",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <Typography
            style={{
              color: "#FFFFFF",
              fontWeight: "500",
              alignSelf: "center",
              width: "94px",
              //@ts-ignore
              backgroundColor:
                BookingStatusColorCodes[
                  params?.row?.status as keyof typeof BookingStatusColorCodes
                ],
              borderRadius: "30px",
              padding: "8px 16px 8px 16px",
            }}
          >
            {
              displayBookingStatus[
                params?.row?.status as keyof typeof displayBookingStatus
              ]
            }
          </Typography>
        );
      },
      flex: 1,
      resizable: false,
    },
  ];

  //get row id
  const getRowId = (row: any) => {
    return row?.id;
  };

  //on row click navigate to booking details
  const handleRowClick = (params: any, event: any) => {
    navigate(`${routesConstants.BOOKING_DETAILS}/${params.id}`);
  };

  return (
    <Stack gap="20px">
      <Box>
        <Typography
          variant={typographyConstants.HEADING}
          color={colors.text_black}
        >
          {CustomerManagement?.bookingDetails}
        </Typography>
      </Box>

      <Stack
        direction="row"
        className="StatsSection"
        justifyContent={"space-between"}
        gap="12px"
      >
        <BookingsCard
          icon={TotalBookingsIcon}
          text={CustomerManagement?.totalBookingDone}
          subText={bookingStats?.totalBookings}
          bgImg={CardBackGround}
        />
        <BookingsCard
          icon={TotalKmDrivenIcon}
          text={CustomerManagement?.totalKmDriven}
          subText={bookingStats?.totalDistanceTravelled}
          bgImg={CardBackGround}
        />
        <BookingsCard
          icon={TotalPaymentsIcon}
          text={CustomerManagement?.totalPaymentDone}
          subText={getFormattedINR(bookingStats?.totalPaidAmount)}
          bgImg={CardBackGround}
        />
      </Stack>

      <Stack gap="20px">
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={typographyConstants.HEADING}
            color={colors.text_black}
          >
            List of Bookings
          </Typography>{" "}
          <CustomSelect
            width="160px"
            placeholder={"Status"}
            value={tableFilters?.status.displayName}
            choice={StatusArray}
            defaultKey={"displayName"}
            required
            handleChange={(status: any) => {
              handleStatusFilterChange(status);
            }}
          />
        </Box>
        <DataTable
          columns={columns}
          rows={bookingList?.bookings}
          disableColumnMenu
          disableColumnSorting
          headerAlign={"center"}
          getRowId={getRowId}
          sx={{
            width: "100%",
            "& .booking-status-cell ": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          onPaginationModelChange={(val: any) => {
            handlePaginationModelChange(val);
          }}
          // rowHeight={50}
          paginationModel={tableFilters.paginationModel}
          paginationMode="server"
          rowCount={bookingList?.pageData?.total}
          onRowClick={handleRowClick}
          loading={bookingListLoader}
        />
      </Stack>
    </Stack>
  );
};
