import { Box, Container, Stack } from "@mui/material";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";
import {
  modules,
  vehicleManagementFunctionalities,
} from "../../../../../constants/RBACModuleEnums";
import { RootState } from "../../../../../redux/reducers";
import CustomButtonGroup from "../../../../../sharedComponents/atoms/Buttons/CustomButtonGroup";
import { CustomButton } from "../../../../../sharedComponents/atoms/Buttons/CustomButtons";
import RbackHelper from "../../../../../utils/helperRBAC";
import {
  GenericObject,
  USE_CASE_TYPES,
  VehicleDocumentType,
  VehicleStatus,
  documentStatus,
  filetypesFormat,
  iotDeviceTypes,
  iotStatus,
  maxFileSize
} from "../../../../constants/constants";
import rentalEn from "../../../../locale/rental-en.json";
import { rentalActions, vehicleActions } from "../../../../redux/actions";
import { routesConstants } from "../../../../utils/RoutesConstants";
import {
  REGEX,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  istToTimestamp,
  paisaToRupee,
  rupeeToPaisa,
  validateFile
} from "../../../../utils/helper";
import style from "../vehicle.module.css";
import { StyleObject } from "./StyleObject";
import AssignIot from "./sections/AssignIot";
import VehicleDetails from "./sections/VehicleDetails";
import VehicleDocuments from "./sections/VehicleDocuments";
const { isAccessRightsProvided } = RbackHelper;

export enum docUploadDates {
  VALID_FORM = "validFrom",
  VALID_TO = "validTo",
}

// Initial state for selected IOT device
const iotInitialState: GenericObject = {
  _id: "", // vehicleId
  type: "", // "PRIMARY"/ "SECONDARY"
  status: "", // "AVAILABLE"
  imeiNumber: "", // i.e., "867440063871255"
};

// Initial state for form fields
const initialState: GenericObject = {
  selectedCity: { name: "", displayName: "" },
  selectedBranch: { name: "", displayName: "" },
  selectedModel: { name: "", displayName: "" },
  selectedLob: { name: "", displayName: "" }, // Default to "LTR"
  selectedUseCase: { name: "", displayName: "" },
  selectedColour: { name: "", displayName: "" },
  cost: "", // Optional
  registration: "",
  chassis: "",
  engine: "",
  purchaseDate: "",
  manufacturingYear: "",
  registrationDate: "", // Optional
};

// inital object data to add new document
const initialDocObject: GenericObject = {
  selectedDocType: { name: "", displayName: "" },
  validFrom: "",
  validTo: "",
  uploadedDoc: "",
  type: VehicleDocumentType.OTHERS,
  attachmentId: "",
  signedUrl: "",
};

// initial doc upload array
const initialDocUploadArray: GenericObject[] = [
  {
    selectedDocType: {
      name: VehicleDocumentType.REGISTRATION_CERTIFICATE,
      displayName: rentalEn.VehicleManagement.RC,
    },
    validFrom: "",
    validTo: "",
    uploadedDoc: "",
    attachmentId: "",
    signedUrl: "",
    type: VehicleDocumentType.REGISTRATION_CERTIFICATE,
  },
  {
    selectedDocType: {
      name: VehicleDocumentType.PUC,
      displayName: rentalEn.VehicleManagement.PUC,
    },
    validFrom: "",
    validTo: "",
    uploadedDoc: "",
    attachmentId: "",
    signedUrl: "",
    type: VehicleDocumentType.PUC,
  },
  {
    selectedDocType: {
      name: VehicleDocumentType.INSURANCE,
      displayName: rentalEn.VehicleManagement.Insurance,
    },
    validFrom: "",
    validTo: "",
    uploadedDoc: "",
    attachmentId: "",
    signedUrl: "",
    type: VehicleDocumentType.INSURANCE,
  },
];

const AddNewVehicle = (props: any) => {
  // RBAC object for functionality
  const functionalitiesAccess = {
    isUpdateApproveReject: isAccessRightsProvided(
      modules.VEHICLE_MANAGEMENT,
      vehicleManagementFunctionalities.VEHICLE_UPDATE_BULK_APPROVE_REJECT
    ),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: vehicleIdParams } = useParams(); // Access the data passed in the route using the 'useParams' hook
  const isEditing: any = vehicleIdParams;
  const {
    VehicleManagement: { vehicleDocumentType },
  } = rentalEn;

  // Redux state selector
  const { allCitiesDetails, branches, modelData } = useSelector(
    (state: RootState) => state.rentalsCommonReducer
  );

  // Redux state selector
  const {
    onLoad,
    addNewVehicleDetailsData,
    assignedIotDataPrimary,
    assignedIotDataSecondary,
    vehicleColourListData,
    vehicleDetailsByIdData,
    assignedIotVehicleData,
    vehicleAttachmentsData = [],
    vehicleDocumentsData: { documentDetails = [], pageData: docPageData = {} },
    iotDetailsObject,
    getSignedUrlLoader,
    docUploadS3Loader,
    addEditVehicleDetailsLoader,
  } = useSelector((state: RootState) => state.rentalsVehicleManagementReducer);

  // State variables
  const [fields, setFields] = useState<GenericObject>({ ...initialState }); // Form fields state
  const [selectedTab, setSelectedTab] = useState<number>(0); // Selected tab index
  const [isTabValid, setIsTabValid] = useState<GenericObject>({
    documentTab: false, // Document tab validation state
    iotTab: false, // IOT tab validation state
  });
  const [searchQuery, setSearchQuery] = useState<GenericObject>({
    primarySearchValue: "",
    secondarySearchValue: "",
  }); // Search query for IOT devices
  const [selectedIot, setSelectedIot] = useState<GenericObject>({
    selectedIotPrimary: iotInitialState,
    selectedIotSecondary: iotInitialState,
  }); // Selected IOT devices
  const [vehicleStatus, setVehicleStatus] = useState<string>(""); // Holds vehicle 'status'

  const [documentsData, setDocumentsData] = useState<GenericObject[]>([
    {
      selectedDocType: {
        name: VehicleDocumentType.REGISTRATION_CERTIFICATE,
        displayName: rentalEn.VehicleManagement.RC,
      },
      validFrom: "",
      validTo: "",
      uploadedDoc: "",
      attachmentId: "",
      signedUrl: "",
      type: VehicleDocumentType.REGISTRATION_CERTIFICATE,
    },
    {
      selectedDocType: {
        name: VehicleDocumentType.PUC,
        displayName: rentalEn.VehicleManagement.PUC,
      },
      validFrom: "",
      validTo: "",
      uploadedDoc: "",
      attachmentId: "",
      signedUrl: "",
      type: VehicleDocumentType.PUC,
    },
    {
      selectedDocType: {
        name: VehicleDocumentType.INSURANCE,
        displayName: rentalEn.VehicleManagement.Insurance,
      },
      validFrom: "",
      validTo: "",
      uploadedDoc: "",
      attachmentId: "",
      signedUrl: "",
      type: VehicleDocumentType.INSURANCE,
    },
  ]);

  // Handler to update the fields in case of editing // This is called on get vehicle by id api success
  const apiSuccessHandler = (data: GenericObject) => {
    // Destructuring
    const {
      cityName,
      cityDisplayName,
      branchName,
      branchDisplayName,
      modelName,
      modelDisplayName,
      useCase,
      cost,
      colour,
      colourDisplayName,
      purchaseDate,
      lob,
    } = data;

    // Setting fields state here with the fetched data
    setFields({
      selectedCity: { name: cityName, displayName: cityDisplayName },
      selectedBranch: { name: branchName, displayName: branchDisplayName },
      selectedModel: { name: modelName, displayName: modelDisplayName },
      selectedLob: { name: lob, displayName: lob },
      selectedUseCase: {
        name: useCase,
        displayName:
          useCase === USE_CASE_TYPES?.RENTALS
            ? rentalEn?.global?.rentals
            : rentalEn?.global?.etr,
      },
      selectedColour: { name: colour, displayName: colourDisplayName },
      cost: cost && paisaToRupee(cost), // Optional
      registration: data?.vehicleDetails?.registration?.toUpperCase() ?? "",
      chassis: data?.vehicleDetails?.chassis?.toUpperCase(),
      engine: data?.vehicleDetails?.engine?.toUpperCase(),
      purchaseDate: new Date(purchaseDate),
      manufacturingYear: data?.vehicleDetails?.manufacturingYear
        ? new Date(data?.vehicleDetails?.manufacturingYear, 0)
        : "",
      registrationDate: data?.vehicleDetails?.registrationDate
        ? new Date(data?.vehicleDetails?.registrationDate)
        : "", // Optional
    });

    // Call branch & model api here for the rejected case // Fetch additional data based on changed conditions
    if (data?.cityName && data?.status === VehicleStatus?.REJECTED) {
      dispatch(rentalActions.getAllBranches({ name: data?.cityName })); // Fetch hubs based on selected city
    }

    // Clear IoT related states here
    setSearchQuery({ primarySearchValue: "", secondarySearchValue: "" });

    // Store vehicle status
    setVehicleStatus(data?.status);
  };

  useEffect(() => {
    // Fetch vehicle colours list on component mount
    dispatch(vehicleActions.getVehicleColoursListData());

    // Fetch vehicle details by id in case of editing
    if (isEditing) {
      const payload: GenericObject = {
        data: { id: vehicleIdParams },
        apiSuccessFunction: apiSuccessHandler,
      };
      dispatch(
        vehicleActions.getAssignedIotDevices({ vehicleId: vehicleIdParams })
      );
      dispatch(vehicleActions.getVehicleDetailsById(payload));

      const getDocPayload = {
        vehicleIds: JSON.stringify([vehicleIdParams]),
        status: [documentStatus.ACTIVE],
        isExpired: "false",
      };
      dispatch(vehicleActions.getVehicleDocuments(getDocPayload));
    } else {
      dispatch(vehicleActions.clearIotSection());
    }

    return () => {
      setDocumentsData([]);
      dispatch(vehicleActions.clearAttachmentIds());
    };
  }, []);

  // Function to fetch IoT devices list based on type and search text
  const getIOTList = (text: string, deviceType: string) => {
    dispatch(
      vehicleActions.getIotDevices({
        status: [iotStatus?.AVAILABLE],
        type: [deviceType],
        search: JSON.stringify(text),
        page: 1,
        pageSize: 100,
      })
    );
  };

  // Debounced function to fetch IOT list based on search value
  const debounceLoadData = useCallback(
    debounce((searchValue: string, deviceType: string) => {
      getIOTList(searchValue, deviceType);
    }, 1000),
    []
  );

  // Handler for selecting an IOT device
  const onSelect = (value: any, key: string) => {
    if (key === iotDeviceTypes?.PRIMARY) {
      setSelectedIot({ ...selectedIot, selectedIotPrimary: value });
    } else {
      setSelectedIot({ ...selectedIot, selectedIotSecondary: value });
    }
  };

  // Handler for input change in the IOT search field
  const onInputChange = (newInputValue: string | any, key: string) => {
    if (!newInputValue || newInputValue === "undefined") {
      setSearchQuery({ primarySearchValue: "", secondarySearchValue: "" });
    } else {
      key === iotDeviceTypes?.PRIMARY
        ? setSearchQuery((prev: any) => ({
            ...prev,
            primarySearchValue: newInputValue,
          }))
        : setSearchQuery((prev: any) => ({
            ...prev,
            secondarySearchValue: newInputValue,
          }));
      if (newInputValue?.length >= 3) {
        debounceLoadData(newInputValue, key);
      }
    }
  };

  // Callback function for handling API response after assigning an IOT device
  const assignIotApiSuccessFunction = (data: any, key: string) => {
    switch (key) {
      case rentalEn?.global?.apiSuccessKey:
        // Handle API success scenario
        break;

      case rentalEn?.global?.apiFailureKey:
        // Handle API failure scenario
        break;
    }
  };

  // API call to assign an IOT device
  const assignIotDeviceHandler = (iotData: GenericObject, type: string) => {
    // Constructing payload object to send to API
    const payload: GenericObject = {
      // Main data object containing required fields
      data: {
        data: {
          vehicleId: isEditing ? vehicleIdParams : addNewVehicleDetailsData?.id, // retrieve vehicle id from pramas or reducer
          deviceId: iotData?.id,
          deviceType: type, // "PRIMARY"/ "SECONDARY"
        },
      },
      // Callback function to handle API success & failure
      apiResponseCallback: assignIotApiSuccessFunction,
    };

    // Dispatch the action to assign IOT device using the constructed payload
    dispatch(vehicleActions.assignIotDevice(payload));
  };

  // Handler function for field changes triggering relevant actions
  const handleChange = (key: string, value: any) => {
    // Object to accumulate fields that need updating together
    let updateFields: GenericObject = {};

    // Determine action based on the provided key
    switch (key) {
      case "selectedCity":
        // Reset selectedBranch and selectedModel when city changes
        updateFields = {
          selectedBranch: initialState?.selectedBranch,
          selectedModel: initialState?.selectedModel,
        };
        break;

      case "selectedBranch":
        // Reset selectedModel when branch changes
        updateFields = { selectedModel: initialState?.selectedModel }; // need to check
        break;

      // Handle specific fields that require validation
      case "registration":
      case "chassis":
      case "engine":
        // Validate alphanumeric input before updating state
        if (REGEX?.strictAlphaNumeric?.test(value)) {
          updateFields[key] = value; // Update state for valid input
        }
        break;

      case "cost":
        // Validate numeric input before updating state
        if (REGEX?.numeric?.test(value)) {
          updateFields[key] = value; // Update state for valid input
        }
        break;

      default:
        break;
    }

    // Update state with accumulated fields based on key
    const keyArray: string[] = ["registration", "chassis", "engine", "cost"];
    if (keyArray?.includes(key)) {
      // Merge accumulated updates with previous state
      setFields({ ...fields, ...updateFields });
    } else {
      // Merge all updates including current key-value pair with previous state
      setFields({ ...fields, ...updateFields, [key]: value });
    }

    // Fetch additional data based on changed conditions
    if (key === "selectedCity" && value?.name) {
      // Dispatch action to fetch branches based on selected city
      dispatch(rentalActions.getAllBranches({ name: value.name }));
    }
  };

  // Validation for 'Save & Next' button in vehicle details section
  const isAddVehicleBottomButtonValid = (): boolean => {
    // Check if the cost is zero and exit early if it is
    if (fields?.cost && !Number(fields?.cost)) {
      return false;
    }

    // Validate that all required fields are present
    return fields?.selectedCity?.name &&
      fields?.selectedBranch?.name &&
      fields?.selectedModel?.name &&
      fields?.selectedColour?.name &&
      fields?.selectedLob?.name &&
      fields?.selectedUseCase?.name &&
      fields?.chassis &&
      fields?.engine &&
      fields?.manufacturingYear &&
      fields?.purchaseDate &&
      fields?.registration
      ? true
      : false;
  };

  // Callback function on successful API call for adding vehicle details
  const addEditVehicleApiSuccessHandler = (
    data: GenericObject,
    key?: string
  ) => {
    const payload: GenericObject = {
      data: { id: vehicleIdParams ?? key === "add" ? data?.id : data?._id },
    };
    dispatch(vehicleActions.getVehicleDetailsById(payload));
    //
    setIsTabValid({ ...isTabValid, documentTab: true });
    setSelectedTab(1);
    setVehicleStatus(data?.status); // Store vehicle status
  };

  // API call to add new vehicle details
  const addNewVehicleDetailsHandler = () => {
    // Constructing payload object to send to API
    const payload: GenericObject = {
      // Main data object containing required fields
      data: {
        data: {
          branchName: fields?.selectedBranch?.name,
          modelName: fields?.selectedModel?.name,
          colour: fields?.selectedColour?.name,
          purchaseDate: istToTimestamp(new Date(fields?.purchaseDate)),
          lob: fields?.selectedLob?.name,
          useCase: fields?.selectedUseCase?.name,
          vehicleDetails: {
            chassis: fields?.chassis?.toUpperCase(),
            engine: fields?.engine?.toUpperCase(),
            manufacturingYear: new Date(
              fields?.manufacturingYear
            ).getFullYear(),
            registration: fields?.registration?.toUpperCase(),
          },
        },
      },
      // Callback function to handle API success
      apiSuccessFunction: addEditVehicleApiSuccessHandler,
    };

    if (Number(fields?.registration) === 0) {
      toastr?.warning(
        rentalEn?.toastTypes?.alert,
        rentalEn?.errorMessages?.zeroRegistrationError
      );
      return;
    } else if (Number(fields?.engine) === 0) {
      toastr?.warning(
        rentalEn?.toastTypes?.alert,
        rentalEn?.errorMessages?.zeroEngineError
      );
      return;
    } else if (Number(fields?.chassis) === 0) {
      toastr?.warning(
        rentalEn?.toastTypes?.alert,
        rentalEn?.errorMessages?.zeroChassisError
      );
      return;
    }

    switch (true) {
      case !!isEditing &&
        (vehicleDetailsByIdData?.status === VehicleStatus?.ACTIVE ||
          vehicleDetailsByIdData?.status ===
            VehicleStatus?.PENDING_FOR_APPROVAL):
        payload.data.data = {};
        payload.data.data.vehicleId = vehicleIdParams;
        payload.data.data.updateFields = {
          vehicleDetails: {
            registration: fields.registration,
          },
        };
        payload.apiSuccessFunction = addEditVehicleApiSuccessHandler;
        if (
          JSON.stringify(fields?.registration) ===
          JSON.stringify(vehicleDetailsByIdData?.vehicleDetails?.registration)
        ) {
          // Do not call the API, switch to the document tab
          setIsTabValid({ ...isTabValid, documentTab: true });
          setSelectedTab(1);
        } else {
          dispatch(vehicleActions.editVehicleDetails(payload));
        }
        break;

      case !!isEditing &&
        vehicleDetailsByIdData?.status === VehicleStatus?.REJECTED:
        const rejectedPayload: GenericObject = {};
        rejectedPayload.data = {};
        rejectedPayload.data.data = {};
        rejectedPayload.data.data.vehicleId = vehicleIdParams;
        rejectedPayload.data.data.updateFields = { ...payload?.data?.data };
        // Include optional fields if provided in 'fields' object
        if (fields?.cost) {
          // Convert cost from rupees to paisa and add to payload if available
          rejectedPayload.data.data.updateFields.cost = rupeeToPaisa(
            fields.cost
          );
        }
        if (fields?.registrationDate) {
          // Convert registration date to timestamp and add to vehicle details if available
          rejectedPayload.data.data.updateFields.vehicleDetails.registrationDate =
            istToTimestamp(new Date(fields.registrationDate));
        }
        rejectedPayload.apiSuccessFunction = addEditVehicleApiSuccessHandler;
        //
        const tempPayload: GenericObject = {
          branchName: vehicleDetailsByIdData?.branchName,
          modelName: vehicleDetailsByIdData?.modelName,
          colour: vehicleDetailsByIdData?.colour,
          purchaseDate: vehicleDetailsByIdData?.purchaseDate,
          lob: vehicleDetailsByIdData?.lob,
          useCase: vehicleDetailsByIdData?.useCase,
          vehicleDetails: {
            chassis: vehicleDetailsByIdData?.vehicleDetails?.chassis,
            engine: vehicleDetailsByIdData?.vehicleDetails?.engine,
            manufacturingYear:
              vehicleDetailsByIdData?.vehicleDetails?.manufacturingYear,
            registration: vehicleDetailsByIdData?.vehicleDetails?.registration,
            registrationDate:
              vehicleDetailsByIdData?.vehicleDetails?.registrationDate,
          },
          cost: vehicleDetailsByIdData?.cost,
        };
        //
        if (
          JSON.stringify(tempPayload) ===
          JSON.stringify(rejectedPayload?.data?.data?.updateFields)
        ) {
          setSelectedTab(1);
        } else {
          // Edit 'REJECTED' status vehicle
          dispatch(vehicleActions.editVehicleDetails(rejectedPayload));
        }
        break;

      case !!!isEditing:
        // Include optional fields if provided in 'fields' object
        if (fields?.cost) {
          // Convert cost from rupees to paisa and add to payload if available
          payload.data.data.cost = rupeeToPaisa(fields.cost);
        }
        if (fields?.registrationDate) {
          // Convert registration date to timestamp and add to vehicle details if available
          payload.data.data.vehicleDetails.registrationDate = istToTimestamp(
            new Date(fields.registrationDate)
          );
        }
        // Dispatch the action to add new vehicle details using the constructed payload
        if (addNewVehicleDetailsData?.id) {
          const newPayload: GenericObject = {};
          newPayload.data = {};
          newPayload.data.data = {};
          newPayload.data.data.vehicleId = addNewVehicleDetailsData?.id;
          newPayload.data.data.updateFields = { ...payload?.data?.data };
          newPayload.apiSuccessFunction = addEditVehicleApiSuccessHandler;
          //
          const tempPayload: GenericObject = {
            branchName: addNewVehicleDetailsData?.branchName,
            modelName: addNewVehicleDetailsData?.modelName,
            colour: addNewVehicleDetailsData?.colour,
            purchaseDate: istToTimestamp(
              new Date(addNewVehicleDetailsData?.purchaseDate)
            ),
            lob: addNewVehicleDetailsData?.lob,
            useCase: addNewVehicleDetailsData?.useCase,
            vehicleDetails: {
              chassis: addNewVehicleDetailsData?.vehicleDetails?.chassis,
              engine: addNewVehicleDetailsData?.vehicleDetails?.engine,
              manufacturingYear:
                addNewVehicleDetailsData?.vehicleDetails?.manufacturingYear,
              registration:
                addNewVehicleDetailsData?.vehicleDetails?.registration,
              registrationDate: istToTimestamp(
                new Date(
                  addNewVehicleDetailsData?.vehicleDetails?.registrationDate
                )
              ),
            },
            cost: addNewVehicleDetailsData?.cost,
          };
          //
          if (
            JSON.stringify(tempPayload) ===
            JSON.stringify(newPayload?.data?.data?.updateFields)
          ) {
            setSelectedTab(1);
          } else {
            // Edit newly added vehicle in the same flow
            dispatch(vehicleActions.editVehicleDetails(newPayload));
          }
        } else {
          // Add new vehicle details
          dispatch(vehicleActions.addNewVehicleDetails(payload));
        }
        break;

      default:
        break;
    }
  };

  // Array defining tabs for custom group button
  const tabsArray: GenericObject[] = [
    {
      label: rentalEn?.VehicleManagement?.vehicleDetails,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: null,
      tabStyle: { ...StyleObject?.tabStyle1 },
    },
    {
      label: rentalEn?.VehicleManagement?.documents,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: !(isEditing || addNewVehicleDetailsData?.id),
      tabStyle: { ...StyleObject?.tabStyle1 },
    },
    {
      label: rentalEn?.VehicleManagement?.assignIOT,
      onClick: () => null,
      selectedTab: selectedTab,
      tabValidation: !(isEditing || addNewVehicleDetailsData?.id),
      tabStyle: { ...StyleObject?.tabStyle1 },
    },
  ];

  // Handler for changing active tab
  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    tabsArray[index].onClick(); // Execute onPress function for the selected tab
  };

  // add new doc tab in doc section
  const addDocButtonHandler = () => {
    let array: GenericObject[] = documentsData;
    let obj = { ...initialDocObject };
    array.push(obj);
    setDocumentsData([...array]);
  };

  // handle date change in document upload section
  const docHandleDateChange = (type: string, date: Date, index: number) => {
    let array = documentsData;
    if (type === docUploadDates.VALID_FORM) {
      array[index].validFrom = date;
      array[index].validTo = "";
      setDocumentsData((prev: any) => [...array]);
    } else {
      array[index].validTo = date;
      setDocumentsData((prev: any) => [...array]);
    }
  };

  // handle document upload in doc upload section
  const docHandleDocumentUpload = (data: GenericObject) => {
    const validate = validateFile(
      data.file,
      [filetypesFormat.PDF],
      maxFileSize
    );
    if (validate?.error) {
      validate?.reason
        ? toastr.warning(rentalEn?.toastTypes?.alert, validate?.reason)
        : null;
      return;
    }

    let payload = {
      requestdata: {
        data: {
          attachments: [data.file.name],
          type: VehicleDocumentType.VEHICLE_DOCUMENT,
        },
      },
      filesData: {
        files: data.file,
        fileName: data.file.name,
        rowData: data.rowData,
        index: data.uploadIndex,
      },
      successCallback: docUploadUrlSuccess,
    };

    // setDocumentsData((prev: GenericObject) => [...stateData]);
    dispatch(vehicleActions.getKYCSignedUrl(payload));
  };

  // handle document document type change in document upload section
  const docHandleDocumentTypeChange = (value: GenericObject, index: number) => {
    let arr: GenericObject[] = documentsData;
    arr[index].selectedDocType = value;
    setDocumentsData((prev: GenericObject) => [...arr]);
  };

  // callback function called if doc upload success and navigate to next tab
  const docUploadSuccess = () => {
    let arr: GenericObject[] = [...initialDocUploadArray];

    setIsTabValid((prev: any) => ({ ...prev, iotTab: true }));
    setSelectedTab(2);
    const getDocPayload = {
      vehicleIds: isEditing
        ? JSON.stringify([vehicleIdParams])
        : JSON.stringify([addNewVehicleDetailsData?.id]),
      status: [documentStatus.ACTIVE],
      isExpired: "false",
    };

    setDocumentsData((prev: GenericObject[]) => [...arr]);

    let payload = {
      vehicleAttachmentsData: [],
    };

    dispatch(vehicleActions.deleteDocSignedUrl(payload));
    dispatch(vehicleActions.getVehicleDocuments(getDocPayload));
  };

  // upload vehicle document to s3
  const uploadDocumentsS3 = () => {
    if (vehicleAttachmentsData?.length > 0) {
      let apiData: GenericObject[] = vehicleAttachmentsData?.map(
        (item: GenericObject) => {
          return {
            type: item.type,
            attachmentId: item.attachmentId,
            startDate: getTimestampAtStartOfDay(item.startDate),
            expiryDate: getTimestampAtEndOfDay(item.expiryDate),
          };
        }
      );
      let payload: GenericObject = {
        apiBody: {
          data: {
            documentDetails: apiData,
          },
        },
        apiParams: isEditing ? vehicleIdParams : addNewVehicleDetailsData?.id,
        successCallback: docUploadSuccess,
      };

      if (apiData?.length)
        dispatch(vehicleActions.addVehicleDocuments(payload));
    } else {
      setIsTabValid((prev: any) => ({ ...prev, iotTab: true }));
      setSelectedTab(2);
    }
  };

  // delete doc row
  const deleteDocRow = (data: GenericObject, index: number) => {
    let arr: GenericObject[] = documentsData;
    let attachmentsArray: GenericObject[] = vehicleAttachmentsData;

    arr.splice(index, 1);
    setDocumentsData((prev: GenericObject[]) => [...arr]);

    const getIndex: number = attachmentsArray.findIndex(function (item) {
      return item?.attachmentId === data?.attachmentId;
    });

    attachmentsArray.splice(getIndex, 1);

    let payload = {
      vehicleAttachmentsData: attachmentsArray,
    };

    dispatch(vehicleActions.deleteDocSignedUrl(payload));
  };

  // download vehicle document
  const downloadVehicleDocument = (docId: string) => {
    const payload: GenericObject = {
      params: `${vehicleIdParams}/${docId}`,
    };
    dispatch(vehicleActions.downloadVehicleDocuments(payload));
  };

  // success callback function to be called when document has been uploaded on signed url
  const docUploadUrlSuccess = (
    attachmentId: string,
    index: number,
    files: File,
    signedUrl: string
  ) => {
    let data: GenericObject[] = documentsData;
    data[index].attachmentId = attachmentId;
    data[index].uploadedDoc = files;
    data[index].signedUrl = signedUrl;
    setDocumentsData((prev: GenericObject) => [...data]);
  };

  // delete documents uploded on signed url
  const deleteDocumentSignedUrl = (docData: GenericObject, index: number) => {
    let attachmentsArray: GenericObject[] = vehicleAttachmentsData;
    let docDataArray: GenericObject[] = [...documentsData];

    docDataArray[index].uploadedDoc = "";

    const getIndex: number = attachmentsArray.findIndex(function (item) {
      return item?.attachmentId === docData?.attachmentId;
    });

    attachmentsArray.splice(getIndex, 1);

    let payload = {
      vehicleAttachmentsData: attachmentsArray,
    };

    setDocumentsData(docDataArray);
    dispatch(vehicleActions.deleteDocSignedUrl(payload));
  };

  // download uploaded documents on signed url
  const downloadDocumentsSignedUrl = (data: GenericObject): void => {
    data.signedUrl && saveAs(data.uploadedDoc);
  };

  return (
    <Box sx={StyleObject?.wrapperView}>
      <Stack spacing={3}>
        <CustomButtonGroup
          handleTabChange={handleTabChange}
          isTabClickable={true}
          tabsArray={tabsArray}
        />

        {/* Render section based on selected tab */}
        {selectedTab === 0 ? (
          <VehicleDetails
            fields={fields}
            allCitiesDetails={allCitiesDetails}
            branches={branches}
            modelData={modelData}
            vehicleColourListData={vehicleColourListData}
            handleChange={handleChange}
            isEditing={isEditing}
            vehicleStatus={vehicleDetailsByIdData?.status}
            vehicleDetailsByIdData={vehicleDetailsByIdData}
          />
        ) : selectedTab === 1 ? (
          <VehicleDocuments
            documentsData={documentsData}
            addDocButtonHandler={addDocButtonHandler}
            docHandleDocumentUpload={docHandleDocumentUpload}
            docHandleDateChange={docHandleDateChange}
            docHandleDocumentTypeChange={docHandleDocumentTypeChange}
            deleteDocRow={deleteDocRow}
            isEditing={isEditing}
            downloadVehicleDocument={downloadVehicleDocument}
            getSignedUrlLoader={getSignedUrlLoader}
            deleteDocumentSignedUrl={deleteDocumentSignedUrl}
            downloadDocumentsSignedUrl={downloadDocumentsSignedUrl}
            docUploadS3Loader={docUploadS3Loader}
            vehicleIds={
              isEditing ? vehicleIdParams : addNewVehicleDetailsData?.id
            }
            setDocumentsData={setDocumentsData}
            initialDocUploadArray={initialDocUploadArray}
          />
        ) : selectedTab === 2 ? (
          <AssignIot
            onLoad={onLoad}
            assignIotDeviceHandler={assignIotDeviceHandler}
            selectedIot={selectedIot}
            onSelect={onSelect}
            onInputChange={onInputChange}
            searchQuery={searchQuery}
            assignedIotDataPrimary={assignedIotDataPrimary}
            assignedIotDataSecondary={assignedIotDataSecondary}
            iotDetailsObject={iotDetailsObject}
          />
        ) : null}
      </Stack>

      {/* Bottom button section */}
      <Container
        sx={StyleObject.containerMultistep}
        className={style.containerPadding}
      >
        <Box sx={StyleObject?.bottomButtonView_wrapperBox}>
          {/* Left Side Bottom Button */}
          <CustomButton
            label={rentalEn?.buttonLabels?.previous}
            variant="outlined"
            sx={{ minWidth: 200 }}
            onClick={() => {
              selectedTab === 0
                ? navigate(-1)
                : selectedTab === 1
                ? setSelectedTab(0)
                : selectedTab === 2
                ? setSelectedTab(1)
                : null;
            }}
          />
          {/* Right Side Bottom Button */}
          <CustomButton
            label={
              selectedTab === 0 || selectedTab === 1
                ? rentalEn?.buttonLabels?.saveAndNext // Save & Next button label
                : rentalEn?.buttonLabels?.save // Save button label
            }
            variant="contained"
            sx={{
              position: "fixed",
              right: "37px",
              minWidth: 200,
            }}
            onClick={() => {
              selectedTab === 0
                ? addNewVehicleDetailsHandler() // Call API to add/edit vehicle details
                : selectedTab === 1
                ? uploadDocumentsS3() // ToDo: Implement document upload section's bottom button functionality
                : selectedTab === 2 &&
                  vehicleStatus === VehicleStatus?.PENDING_FOR_APPROVAL
                ? functionalitiesAccess.isUpdateApproveReject
                  ? navigate(routesConstants?.APPROVE_REJECT_VEHICLE)
                  : navigate(routesConstants?.ALL_VEHICLES)
                : navigate(routesConstants?.ALL_VEHICLES);
            }}
            disabled={
              selectedTab === 0
                ? !isAddVehicleBottomButtonValid() // Disable button based on section validation
                : false ?? false
            }
            loading={docUploadS3Loader || addEditVehicleDetailsLoader}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AddNewVehicle;
