import { Box } from "@mui/material";
import { SyntheticEvent, memo, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import yoren from "../../../../locale/yor-en.json";
import { RootState } from "../../../../redux/store";
import SelectX from "../../../components/InputFields/InputSelectX";
import InputX from "../../../components/InputFields/InputX";
import { Heading } from "../../../../sharedComponents/typography";
import {
  LocationIcon,
  MobileIcon,
  SearchIcon,
  SearchIconDisabled,
  WarehouseIcon,
} from "../../../constantsX/exportImagesX";
import { UserLocationProps } from "../../../interfaces/manualBookingInterfaces";
import { actionsX } from "../../../redux";
import { checkPhone } from "../../../utils/regex";
import styles from "../ManualBooking.module.css";
import { StartIconLabelButtonX } from "../../../components/muiButtonsX";
import { searchCustomerAction } from "../../../../rentals-src/redux/actions/customerManagementActions";

const LocationDetails = (props: UserLocationProps) => {
  const dispatch = useDispatch();
  const {
    setFields,
    fields,
    errorFields,
    setErrorFields,
    clearFieldsData,
    AccessCity,
  } = props;

  const { checkIsUserExist } = useSelector(
    (state: RootState) => state.manualBookingX
  );
  const { branchData } = useSelector(
    (state: RootState) => state.masterReducerX
  );
  const { checkUserLoader } = useSelector(
    (state: RootState) => state.manualBookingX
  );

  const [clearFields, setClearFields] = useState(false);

  // search if the user exist
  const SearchUser = (event: SyntheticEvent) => {
    event.preventDefault();
    if (fields.mobileNumber.length < 10) {
      setErrorFields({
        ...errorFields,
        mobileNumber: yoren?.errorMessages?.mobileError,
      });
      return;
    }
    if (clearFields) {
      setClearFields(false);
      clearFieldsData();
    } else {
      checkUserExist();
      
      setFields((prevState: any) => ({
        ...prevState,
      }));
    }
  };

  const checkUserExist = () => {
    dispatch(
      actionsX?.checkUserExistFreedoX({
        mobile_number: fields.mobileNumber,
      })
    );
  };

  //CITY AND BRANCH CHNAGE
  const handleChange = (field: string, value: any) => {
    setFields((prevState: any) => ({ ...prevState, [field]: value }));
    setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
    if (field === "selectedBranch") {
      dispatch(actionsX.getModelX({ name: value.name }));
    }
  };

  //CHECK USER EXISTS
  useEffect(() => {
    if (checkIsUserExist) {
      setClearFields(true);
    }
  }, [checkIsUserExist]);

  return (
    <Box className={` ${styles.BoxColumn} ${styles.gap}`} width="590px">
      {/* User search */}
      <Box>
        <form onSubmit={SearchUser}>
          {/* Enter mobile number in multi step form user details */}

          <Heading
            title={yoren.manualBookingFreedoX.EnterUserDetails}
            className="w-auto"
            fontSize={15}
          />
          <Box className={`${styles.BoxRow} ${styles.gap}`}>
            <InputX
              onChange={(e: any) => {
                e.preventDefault();
                checkPhone(e.target.value) &&
                  e.target.value?.length < 11 &&
                  handleChange("mobileNumber", e.target.value);
              }}
              iconStart={MobileIcon}
              value={fields.mobileNumber}
              placeholder={yoren.manualBookingFreedoX.MobileNumber}
              label={yoren.manualBookingFreedoX.EnterNumber}
              errormessage={errorFields.mobileNumber}
              disabled={checkIsUserExist}
            />

            <StartIconLabelButtonX
              label={
                clearFields
                  ? yoren.manualBookingFreedoX.Clear
                  : yoren.manualBookingFreedoX.Search
              }
              back={true}
              icon={
                !clearFields
                  ? fields.mobileNumber.length < 10
                    ? SearchIconDisabled
                    : SearchIcon
                  : ""
              }
              variant="outlined"
              disabled={fields.mobileNumber.length < 10}
              loading={checkUserLoader}
              onClick={SearchUser}
            />
          </Box>
        </form>
      </Box>

      {/* LOCATION DETAILS */}
      <Box className={styles.BoxColumn}>
        {checkIsUserExist ? (
          <Fade>
            <Heading
              title={yoren.manualBookingFreedoX.LocationDetails}
              className="w-auto"
              fontSize={15}
            />
            <Box className={styles.BoxRow} gap="12px">
              {/* SELECT CITY */}
              <SelectX
                required
                icon={LocationIcon}
                placeholder={yoren.manualBookingFreedoX.SelectCity}
                value={fields.selectedCity.displayName}
                choice={AccessCity}
                defaultKey={"displayName"}
                errormessage={errorFields.selectedCity}
                handleChange={(city: any) => {
                  handleChange("selectedCity", city);
                  setErrorFields((prevState: any) => ({ ...prevState, ["selectedBranch"]: "" }));
                }}
              />

              {/* Select Branch */}
              <SelectX
                required
                icon={WarehouseIcon}
                placeholder={yoren.manualBookingFreedoX.SelectBranch}
                value={fields.selectedBranch.displayName}
                choice={branchData?.data ?? []}
                defaultKey={"displayName"}
                disabled={
                  !fields.selectedCity.name || branchData?.data?.length === 0
                }
                handleChange={(branch: any) => {
                  handleChange("selectedBranch", branch);
                }}
                errormessage={errorFields.selectedBranch}
              />
            </Box>
          </Fade>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default memo(LocationDetails);
