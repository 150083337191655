import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
// import StickyHeaderTable from "../../components/stickeyHeader";
import StickyHeaderTable from "../../../components/stickeyHeader";
import { Heading } from "../../../components/typography";
import { ButtonActions, UserActions } from "../../../constants/userActions";
import { actions } from "../../../redux";
import { RootState } from "../../../redux/reducers";
// import Status from "../../components/status";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@mui/material/Modal";
import BreadCrumbs from "../../../components/breadCumbs";
import { Button1, Button2 } from "../../../components/button";
import { Input3, Input5 } from "../../../components/input";
import { AccessTypes, DefaultProps } from "../../../interfaces/commonInterface";
import { colors } from "../../../themes/colors";
import {
  checkAccess,
  checkActionAccess,
  validate,
} from "../../../utils/helper";
import { checkNumeric } from "../../utils/regex";
import {
  clearBreadcrumbs,
  updateBreadcrumbs,
} from "../../redux/actions/rentalCommonAction";
import { BREADCRUMBS_RENTALS } from "../../constants/activeModule";
import { CustomButton } from "../../../sharedComponents/atoms/Buttons/CustomButtons";
import { isAlphaNumericWithSpacesString, isAlphabetWithSpacesString } from "../../utils/helper";

interface IObjectKeys {
  [key: string]: string | number;
}
interface CreateTaskFields extends IObjectKeys {
  taskName: string;
  taskDescription: string;
}

const TaskManagement = (props: DefaultProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [_id, setId] = useState<any>(null);
  const [title, setTitle] = useState<any>("");
  const [taskCompleted, setTaskCompleted] = useState<boolean>(false);
  const isDesktopOrTablet = useMediaQuery("(min-width:767px)");

  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const {
    taskList,
    taskDetailsById,
    taskloader,
    isTaskCreated,
    isTaskEdited,
    loading,
    totalCount,
    isTaskDeleted,
  } = useSelector((state: RootState) => state.taskReducer);

  const [searchTask, setSearchTask] = useState<String>("");
  const [timeCounter, setTimeCounter] = useState<number>(10);
  const [validationError, setValidationError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const handlePage = (page: number) => {
    setPage(page);
  };
  const handleRows = (row: number) => {
    setPage(0);
    setSize(row);
  };

  const modifiedTaskData: any =
    taskList &&
    taskList?.map((obj: any) => {
      const { _id, ...rest } = obj;
      return { id: _id, ...rest };
    });

  const initialState = {
    taskName: "",
    taskDescription: "",
    imageRequired: false,
    remarkRequired: false,
    notificationToCustomer: "true",
    category: [],
  };

  const [fields, setFields] = useState<any>({ ...initialState });

  const [errorFields, setErrorFields] = useState<any>({
    taskName: "",
    taskDescription: "",
    sla: "",
  });
  const { roleManagement, global, taskManagement } = locale;

  const onChange = (e: any) => {
    setSearchTask(e.target.value);
  };

  const handleChange = (field: string, value: any) => {
    if (field === "taskName" || field === "taskDescription") {
      if (value && !isAlphaNumericWithSpacesString(value)) return false;
      if (value.length > 30) return false;
    }
    setFields((prevState: any) => {
      if (field === "taskName" || field === "taskDescription") {
        let prevLength = prevState[field]?.length;
        let valueLength = prevState[field]?.length;

        if (
          prevLength &&
          /\s/.test(prevState[field][prevLength - 1]) &&
          /\s/.test(value[valueLength])
        )
          return {
            ...prevState,
          };
      }
      return {
        ...prevState,
        [field]: prevState[field] ? value : (field === "taskName" || field === "taskDescription") ? value.trim() : value,
      };

      // return { ...prevState, [field]: value };
    });
    setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
    setVerificationError("");
  };

  const handleCategory = (event: any): any => {
    const { value, checked } = event.target;
    const { category, ...rest } = fields;
    let updatedValues = [...category];

    if (checked === true) {
      updatedValues.push(value);
    } else {
      updatedValues = updatedValues.filter((val: any) => val !== value);
    }

    // const updatedFields = { ...fields };
    // updatedFields.category =updatedValues
    // setFields(updatedFields);

    setFields({ ...fields, category: updatedValues });

    setValidationError("");
  };

  const isCategorySelected = (): any => {
    let error = false;
    if (fields?.category.length === 0) {
      error = true;
      setValidationError("Please select at least one option.");
    } else {
      error = false;
      setValidationError("");
    }
    return error;
  };

  const isVerificationSelected = (): any => {
    let error = false;
    if (!fields?.imageRequired && !fields?.remarkRequired) {
      error = true;
      setVerificationError("Please select at least one option.");
    } else if (fields?.imageRequired || fields?.remarkRequired) {
      error = false;
      setVerificationError("");
    }
    return error;
  };

  // useEffect(() => {
  //   dispatch(
  //     actions.getTaskList({ page: page + 1, size})
  //   );
  // }, []);

  const validateForm = (e: SyntheticEvent) => {
    e.preventDefault();
    const customFields: any = {
      taskName: fields.taskName,
      taskDescription: fields.taskDescription,
      sla: timeCounter,
    };

    let validation: any = validate(customFields, errorFields);
    const categorySelected = isCategorySelected();
    const verificationSelected = isVerificationSelected();

    if (validation.error) {
      setErrorFields({ ...errorFields, ...validation.errorFields });
    } else {
      if (!categorySelected && !verificationSelected) {
        onSubmitData();
      }
    }
  };
  const onSubmitData = () => {
    if (title === "Create Task") {
      dispatch(
        actions.createTask({
          taskName: fields?.taskName,
          taskDescription: fields?.taskDescription,
          sla: timeCounter,
          imageRequired: fields?.imageRequired,
          remarkRequired: fields?.remarkRequired,
          notificationToCustomer: fields?.notificationToCustomer,
          categoryType: fields?.category,
          priority: 0,
        })
      );
    }
    if (title === "Edit Task") {
      dispatch(
        actions.editTask(
          {
            taskName: fields?.taskName,
            taskDescription: fields?.taskDescription,
            sla: timeCounter,
            imageRequired: fields?.imageRequired,
            remarkRequired: fields?.remarkRequired,
            notificationToCustomer: fields?.notificationToCustomer,
            categoryType: fields?.category,
          },
          _id
        )
      );
      // setTaskCompleted(true)
      // setFields({ ...initialState });
    }
  };

  useEffect(() => {
    setFields({ ...initialState });
    setTimeCounter(10);
  }, []);

  //  useEffect(() => {
  //   if (isTaskCreated || isTaskEdited || isTaskDeleted) {
  //    dispatch(actions.getTaskList({ page: page + 1, size }));
  //    setTaskCompleted((value) => !value);
  //    setFields({ ...initialState });
  //    setTimeCounter(10);
  //   }
  //  }, [isTaskCreated, isTaskEdited,isTaskDeleted]);
  useEffect(() => {
    if (isTaskCreated || isTaskEdited) {
      dispatch(actions.getTaskList({ page: page + 1, size }));
      setTaskCompleted((value) => !value);
      setFields({ ...initialState });
      setTimeCounter(10);
    } else if (isTaskDeleted) {
      dispatch(actions.getTaskList({ page: page + 1, size }));
      setFields({ ...initialState });
      setTimeCounter(10);
    }
  }, [isTaskCreated, isTaskEdited, isTaskDeleted]);

  useEffect(() => {
    if (searchTask) {
      dispatch(actions.getTaskList({ search: searchTask }));
    } else if (!searchTask) {
      dispatch(actions.getTaskList({ page: page + 1, size }));
    }
  }, [searchTask, page, size, refresh]);

  useEffect(() => {
    if (taskDetailsById && title === "Edit Task") {
      setFields((prev: any) => ({
        ...prev,
        taskName: taskDetailsById?.taskName,
        taskDescription: taskDetailsById?.taskDescription,
        imageRequired: taskDetailsById?.imageRequired,
        remarkRequired: taskDetailsById?.remarkRequired,
        notificationToCustomer: taskDetailsById?.notificationToCustomer,
        category: taskDetailsById?.categoryType,
      }));
      setTimeCounter(taskDetailsById?.sla);
      setId(taskDetailsById?._id);
    }
  }, [taskDetailsById]);

  const columns = [
    {
      id: "taskName",
      label: taskManagement.TaskName,
      minWidth: 50,
      maxWidth: 50,
      customJson: true,
      render: (item: any) => {
        return (
          <Tooltip title={item?.taskName?.length > 20 ? item?.taskName : ""}>
            <Typography
              sx={{
                width: "100px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {item?.taskName ?? ""}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "taskDescription",
      label: taskManagement.TaskDescription,
      minWidth: 50,
      maxWidth: 50,
      customJson: true,
      render: (item: any) => {
        return (
          <Tooltip
            title={
              item?.taskDescription?.length > 20 ? item?.taskDescription : ""
            }
          >
            <Typography
              sx={{
                width: "100px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {item?.taskDescription ?? ""}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sla",
      label: taskManagement.SLA,
      minWidth: 120,
      align: "center",
      customJson: true,

      render: (item: any) => {
        return `${item.sla} min`;
      },
    },
    {
      id: "Verification",
      label: taskManagement.Verification,
      minWidth: 120,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            {item.imageRequired === true ? (
              <Chip
                style={{
                  minWidth: "80px",
                  backgroundColor: colors.chip_background_colorG,
                  color: colors.chip_text_colorG,
                  marginRight: 5,
                  fontSize: "12px",
                  fontFamily: "EuclidCircularB-Medium",
                }}
                label={"Image"}
                size="small"
              />
            ) : null}
            {item.remarkRequired ? (
              <Chip
                style={{
                  minWidth: "80px",
                  backgroundColor: colors.chip_background_cgray,
                  color: colors.chip_text_cgray,
                  marginRight: 5,
                  fontSize: "12px",
                  fontFamily: "EuclidCircularB-Medium",
                }}
                label="Remark"
                size="small"
              />
            ) : null}
          </div>
        );
      },
    },
    {
      id: "notificationToCustomer",
      label: taskManagement.CustomerNotification,
      minWidth: 120,
      align: "center",
      customJson: true,
      render: (item: any) => {
        return <div>{item.notificationToCustomer ? "Yes" : "No"}</div>;
      },
    },
    {
      id: "action",
      label: taskManagement.Action,
      minWidth: 100,
      render: () => {
        return <></>;
      },
      // customJson: true,
      // render: (item: any) => {
      //  return (
      //   <Actions
      //    actions={[
      //     checkAccess(props.accessKey, AccessTypes.view, {
      //      type: UserActions.view,
      //      onClick: () => navigate("view-role/" + item._id),
      //     }),
      //     checkSubsidiary(userData?.userType, item?.userType)
      //      ? checkAccess(props.accessKey, "update", {
      //         type: UserActions.update,
      //         onClick: () => navigate("edit-role/" + item._id),
      //        })
      //      : null,
      //     checkSubsidiary(userData?.userType, item?.userType)
      //      ? checkAccess(props.accessKey, AccessTypes.delete, {
      //         type: UserActions.delete,
      //         onClick: () => handleClickOpen(item._id),
      //        })
      //      : null,
      //    ]}
      //   />
      //  );
      // },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setTaskCompleted(false);
    setFields({ ...initialState });
    setTimeCounter(10);

    setErrorFields({
      taskName: "",
      taskDescription: "",
      sla: "",
    });
    setValidationError("");
    setVerificationError("");
  };

  const handleCounter = (type: any) => {
    if (type === "decrement") {
      if (timeCounter > 0) {
        setTimeCounter((prev): any => prev - 1);
        setErrorFields((prevState: any) => ({ ...prevState, sla: "" }));
      }
    }
    if (type === "increment") {
      if (timeCounter < 600) {
        setTimeCounter((prev): any => prev + 1);
        setErrorFields((prevState: any) => ({ ...prevState, sla: "" }));
      }
    }
  };

  const deleteTask = (id: any) => {
    const toastrConfirmOptions = {
      onOk: async () => {
        dispatch(
          actions.deleteTask({
            id: id,
          })
        );
      },
      onCancel: () => console.log("CANCEL: clicked"),
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        style: { background: "red !important", color: "black !important" },
      }, // Red background color for "Yes" button
      cancelButtonProps: {
        style: { background: "red !important", color: "black !important" },
      },
    };
    toastr.confirm(
      "Are you sure you want to delete this Task?",
      toastrConfirmOptions
    );
  };

  return (
    <Box>
      {[
        checkAccess(props.accessKey, AccessTypes.add, ButtonActions.CreateTask),
      ].map((each) => {
        if (each)
          return (
            <Button2
              style={{
                marginBottom: 20,
                // marginTop: 20,
                width: "200px",
              }}
              borderRadius={"48px"}
              title={ButtonActions.CreateTask}
              onClick={() => {
                setOpen(true);
                setTitle("Create Task");
              }}
              disabled={false}
            />
          );
      })}

      {/* SEARCH TASK */}
      <div style={{ width: "40%", marginBottom: 24 }}>
        <Input5
          onChange={onChange}
          value={searchTask}
          placeholder={global.SearchTaskName}
          width={320}
        />
      </div>
      {/* <Input3
    onChange={onChange}
    value={searchTask}
    placeholder={global.SearchTaskName}
    width={320}
   /> */}
      <Box>
        {/* TABLE */}
        <StickyHeaderTable
          rows={modifiedTaskData}
          columns={columns}
          maxHeight={0.65}
          widthOffset={275}
          loading={taskloader}
          pageSetter={handlePage}
          rowsPerPageSetter={handleRows}
          totalItems={totalCount}
          // localPagination
          /* actions={checkActionAccess(props.accessKey, [
            UserActions.update,
            UserActions.delete,
          ])} */
          actions={[UserActions.update, UserActions.delete]}
          onClickAction={(type: string, id: number | string) => {
            if (type === UserActions.update) {
              //   navigate("edit-branch/" + id);
              setTaskCompleted(false);
              if (taskDetailsById) {
                setTimeout(() => {
                  setOpen(true);
                }, 500);
              }
              setTitle("Edit Task");

              dispatch(actions.getTaskDetailsById({ taskId: id }));
            } else if (type === UserActions.view) {
              navigate("view-branch/" + id);
            } else if (type === UserActions.delete) {
              deleteTask(id);
            }
          }}
        />
      </Box>

      {/* Create Task Modal */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {taskCompleted == false ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isDesktopOrTablet ? "40%" : "90%",
                // bgcolor: "background.paper",
                p: 6,
                display: "flex",
                flexDirection: "column",
                border: "1px solid #707070",
                background:
                  "transparent linear-gradient(125deg, #FFFFFF 0%, #FEFEFE 28%, #FBFBFB 71%, #F8F8F8 100%) 0% 0% no-repeat padding-box",
                borderRadius: "40px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                style={{
                  fontFamily: "EuclidCircularB-Medium",
                  fontSize: "20px",
                }}
                color={colors.dark_gray}
                key="2"
                sx={{
                  marginBottom: 5,
                  // textAlign: "center"
                }}
              >
                {title}                
              </Typography>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.TaskName}{" "}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input3
                      onChange={(e: any) => {
                        handleChange("taskName", e.target.value);
                      }}
                      value={fields?.taskName}
                      placeholder={"Task Name"}
                      width={"100%"}
                      errormessage={errorFields.taskName}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.TaskDescription}{" "}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input3
                      onChange={(e: any) => {
                        handleChange("taskDescription", e.target.value);
                      }}
                      value={fields?.taskDescription}
                      placeholder={"Task Description"}
                      width={"100%"}
                      errormessage={errorFields.taskDescription}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.SLA}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 5,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        color={colors.heading}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCounter("decrement")}
                      />
                      <Input3
                        // onChange={onChange}
                        value={timeCounter}
                        placeholder={"10"}
                        disabled={false}
                        width={80}
                        errormessage={errorFields.sla}
                        required={true}
                        style={{
                          fontSize: "14px",
                          marginLeft: 5,
                          marginRight: 5,
                          fontFamily: "EuclidCircularB-Medium",
                        }}
                        onChange={(e: any) => {
                          checkNumeric(e.target.value) &&
                            e.target.value <= 600 &&
                            setTimeCounter(Number(e.target.value));
                          setErrorFields((prevState: any) => ({
                            ...prevState,
                            sla: "",
                          }));
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={colors.heading}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCounter("increment")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.Verification}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                    >
                      <Checkbox
                        color="primary"
                        disabled={false}
                        checked={fields?.imageRequired}
                        onChange={(e: any) =>
                          handleChange("imageRequired", e.target.checked)
                        }
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: 14,
                          textAlign: "left",
                          color: "#727272",
                          fontFamily: "EuclidCircularB-Medium",
                          marginLeft: "1%",
                        }}
                      >
                        Image
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                    >
                      <Checkbox
                        color="primary"
                        disabled={false}
                        checked={fields?.remarkRequired}
                        onChange={(e: any) =>
                          handleChange("remarkRequired", e.target.checked)
                        }
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: 14,
                          textAlign: "left",
                          color: "#727272",
                          fontFamily: "EuclidCircularB-Medium",
                          marginLeft: "1%",
                        }}
                      >
                        Remarks
                      </Typography>
                    </Box>
                  </Grid>

                  {Boolean(verificationError) ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "14px",
                        display: "flex",
                        marginLeft: "270px",
                      }}
                    >
                      {verificationError}
                    </div>
                  ) : null}
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.CustomerNotification}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Cash"
                      name="radio-buttons-group"
                      value={fields?.notificationToCustomer}
                      row
                      //  onChange={(e: any) => {
                      //   setRadioValue(e.target.value);
                      //  }}

                      onChange={(e: any) => {
                        setFields({
                          ...fields,
                          notificationToCustomer: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "EuclidCircularB-Medium",
                        fontSize: "16px",
                      }}
                      color={colors.dark_gray}
                    >
                      {taskManagement.Suggested}{" "}
                      <text style={{ color: colors.error, fontSize: "14px" }}>
                        *
                      </text>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={isDesktopOrTablet ? "row" : "column"}
                    >
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          flexDirection={"row"}
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            disabled={false}
                            value="Start Ride"
                            // checked={false}
                            checked={fields?.category.includes("Start Ride")}
                            onChange={handleCategory}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: "#727272",
                              fontFamily: "EuclidCircularB-Medium",
                              marginLeft: "1%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Start ride
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          flexDirection={"row"}
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            disabled={false}
                            value="Complete Ride"
                            checked={fields?.category.includes("Complete Ride")}
                            onChange={handleCategory}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: "#727272",
                              fontFamily: "EuclidCircularB-Medium",
                              marginLeft: "1%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            End ride
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={isDesktopOrTablet ? "row" : "column"}
                    >
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          flexDirection={"row"}
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            disabled={false}
                            value="Service"
                            checked={fields?.category.includes("Service")}
                            onChange={handleCategory}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: "#727272",
                              fontFamily: "EuclidCircularB-Medium",
                              marginLeft: "1%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Service
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          flexDirection={"row"}
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            disabled={false}
                            value="Others"
                            checked={fields?.category.includes("Others")}
                            onChange={handleCategory}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: "#727272",
                              fontFamily: "EuclidCircularB-Medium",
                              marginLeft: "1%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Others
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <FormHelperText error={Boolean(validationError)}>
        {validationError}
      </FormHelperText> */}

                  {Boolean(validationError) ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "14px",
                        display: "flex",
                        marginLeft: "270px",
                      }}
                    >
                      {validationError}
                    </div>
                  ) : null}
                </Grid>
              </div>
              <Box
                sx={{ display: "flex", justifyContent: "center", p: 1, m: 1 }}
              >
                <div style={{ width: "50%" }}>
                  <CustomButton
                    label={global.Submit}
                    variant={"contained"}
                    onClick={validateForm}
                    loading={loading}
                    disabled={false}
                    sx={{ width: "100%" }}
                  />
                </div>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isDesktopOrTablet ? "40%" : "90%",
                bgcolor: "background.paper",
                p: 10,
                display: "flex",
                flexDirection: "column",
                border: "1px solid #707070",
                background:
                  "transparent linear-gradient(125deg, #FFFFFF 0%, #FEFEFE 28%, #FBFBFB 71%, #F8F8F8 100%) 0% 0% no-repeat padding-box",
                borderRadius: "40px",
                // borderRadius: "1px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                style={{
                  fontFamily: "EuclidCircularB-Medium",
                  fontSize: "24px",
                  alignSelf: "center",
                  marginBottom: 10,
                }}
                color={colors.black}
                key="2"
                sx={{
                  marginBottom: 5,
                }}
              >
                {title === "Create Task"
                  ? taskManagement.TaskCreatedSucess
                  : taskManagement.TaskEditedSucess}
              </Typography>
              {/* <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{
                    fontFamily: "EuclidCircularB-Medium",
                    fontSize: "18px",
                    alignSelf: "center",
                    marginBottom: 80,
                    }}
                    color={colors.dark_gray}
                    key="2"
                    sx={{
                    marginBottom: 5,
                    }}
                  >
                    {taskManagement.TaskName} : {fields?.taskName}
                  </Typography> 
              */}
              {checkAccess(
                props.accessKey,
                AccessTypes.add,
                ButtonActions.CreateTask
              ) ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // p: 1,
                    // m: 1,
                  }}
                >
                  <div>
                    <Button1
                      // className=""
                      style={{
                        marginBottom: 5,
                        marginTop: 10,
                        whiteSpace: "nowrap",
                      }}
                      title={taskManagement.AddNewTask}
                      onClick={() => {
                        setTaskCompleted((value) => !value);
                        setTitle("Create Task");
                      }}
                      disabled={false}
                    />
                  </div>
                </Box>
              ) : null}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    marginBottom: 10,
                    marginTop: 20,
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                >
                  <a
                    onClick={() => {
                      setOpen((value) => !value);
                      setTaskCompleted((value) => !value);
                    }}
                    // disabled={false}
                  >
                    Close
                  </a>
                </div>
              </Box>
            </Box>
          )}
        </Modal>
      </div>
    </Box>
  );
};

export default TaskManagement;
