import React from "react";
import {
 Box,
 Button,
 Chip,
 Card,
 Divider,
 TextField,
 FormControl,
 FormControlLabel,
 Grid,
 Radio,
 RadioGroup,
 Typography,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 CircularProgress,
 useMediaQuery,
 Tooltip,
 Paper,
} from "@mui/material";
import "./styles.css";
import moment from "moment";
import { TimeLineData } from "../data/TimelineData";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../../redux";
import { RootState } from "../../../../redux/reducers";
import { useEffect, useState } from "react";
import { TaskAssignmentStatusTabsDisplayName } from "../../../constants/constants";
const TimeLineComponent = ({data, vehicleImagesData, vehicleEndRideImagesData, startOdometerReadingImagesData, endOdometerReadingImagesData}:any) => {
 return (
  <div className="timeline">
   {data.map((item:any, index:number) => {

    return (
    <div key={index}>
     <div className="dot-row">
      <div className="dot-container">
       <div className="dot"></div>
      </div>
      <Card
    
       sx={{
        borderRadius: "12px",
        border: "none",
        boxShadow: "none",
        position: "relative",
        justifyContent: "flex-end",
        padding: "20px",
        marginLeft: "10px",
        width:"100%",
        overflow: "unset"
       }}
      >
       <Grid style ={{display:"flex", gap:"20px"}} >
        <Grid xs={12} sm={12} md={3} lg={2} className="time">
         <p className="timeText">{item?.isUpdated ? moment(item?.updatedAt).format("hh:mm A") : "N/A"}</p>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={8} className="description">
         <p className="descTitle">{item?.taskName}</p>
         <p className="descText">{item?.subTaskStatus?.remark}</p>
         {item.subTaskStatus.images && (
          <p className="imageContainerHeadingText">Images/Pictures</p>
         )}
         {item.taskType === TaskAssignmentStatusTabsDisplayName.START_RIDE && item?.taskName === "Vehicle Image Upload" && vehicleImagesData?.length > 0 && vehicleImagesData.map((item:any, index:any) => (
           <div className="image-container">
            <img src={item?.signedUrl} alt="Small Image" className="small-image" />
            <div className="overlay">
             <img src={item?.signedUrl} alt="Big Image" className="big-image" />
            </div>
           </div>
          ))}
          {item.taskType === TaskAssignmentStatusTabsDisplayName.START_RIDE && item?.taskName === "Assign Vehicle and Odometer Reading" && startOdometerReadingImagesData?.length > 0 && startOdometerReadingImagesData.map((item:any, index:any) => (
           <div className="image-container">
            <img src={item?.signedUrl} alt="Small Image" className="small-image" />
            <div className="overlay">
             <img src={item?.signedUrl} alt="Big Image" className="big-image" />
            </div>
           </div>
          ))}
          {item.taskType === TaskAssignmentStatusTabsDisplayName.COMPLETE_RIDE && item?.taskName === "End Vehicle Image Upload" && vehicleEndRideImagesData?.length > 0 && vehicleEndRideImagesData.map((item:any, index:any) => (
           <div className="image-container">
            <img src={item?.signedUrl} alt="Small Image" className="small-image" />
            <div className="overlay">
             <img src={item?.signedUrl} alt="Big Image" className="big-image" />
            </div>
           </div>
          ))}
           {item.taskType === TaskAssignmentStatusTabsDisplayName.COMPLETE_RIDE && item?.taskName === "End Odometer Reading with Image" && endOdometerReadingImagesData?.length > 0 && endOdometerReadingImagesData.map((item:any, index:any) => (
           <div className="image-container">
            <img src={item?.signedUrl} alt="Small Image" className="small-image" />
            <div className="overlay">
             <img src={item?.signedUrl} alt="Big Image" className="big-image" />
            </div>
           </div>
          ))}
         {item.subTaskStatus.images &&
          item.subTaskStatus.images.map((item:any, index:any) => (
           <div className="image-container">
            <img src={item} alt="Small Image" className="small-image" />
            <div className="overlay">
             <img src={item} alt="Big Image" className="big-image" />
            </div>
           </div>
          ))}
        </Grid>
        <Grid xs={12} sm={12} md={3} lg={2} className="chip">
         {item?.updatedBy?.name && (
          <div className="chipButtonPrimary">{item?.updatedBy?.name}</div>
         )}
         {item?.updatedBy?.userType && (
        <div className="chipButtonSecondary">{(item?.updatedBy?.userType?.split("")[0]).toUpperCase()}</div>
         )}
        </Grid>
       </Grid>
      </Card>
     </div>
     <div className="connector"></div>
    </div>
   )})}
  </div>
 );
};

export default TimeLineComponent;
