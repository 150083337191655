export const StyleObject: any = {
  fullCalendarHeader_box1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  calendarTitle_typography: {
    fontWeight: 500,
    fontSize: "16px",
  },
  fullCalendarHeader_box2: {
    backgroundColor: "#F2F2F2",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "60px",
    height: "30px",
  },
  fullCalendarHeader_box3: {
    backgroundColor: "#F2F2F2",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "35px",
    height: "35px",
  },
  InventoryManagement_box5: {
    width: "100%",
    padding: "6px",
  },
  InventoryManagement_getDates_eventContentUI_mapStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2px 9px",
    cursor: "pointer",
  },
  InventoryManagement_getDates_eventContentUI_box1: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  InventoryManagement_box1: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  InventoryManagement_box2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cursorPointerStyle: {
    cursor: "pointer",
  },
};
